import React, { Component } from 'react'
import ScrollView from 'devextreme-react/scroll-view';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { Popup, Form } from 'devextreme-react';
import FormModalPenerimaanPemesanan from '../penerimanaan/penerimaanPemesanan';
import { sdmUnit, unit } from 'dataSource/lookup';
import FormModalEditTagihanPembelian from './tagihanPembelian';

class FormModalTagihanPembelian extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
            popupVisible:false
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField:"noPemesanan",
                        label:{
                            text:"No Pemesanan"
                        },
                    },
                    {
                        dataField:"jumlahPemesanan",
                        label:{
                            text:"Jumlah Pemesanan"
                        },
                    },
                    {
                        dataField:"jumlahPenerimaan",
                        label:{
                            text:"Jumlah Penerimaan"
                        },
                    },
                    {
                        dataField:"totalUnitDiteriman",
                        label:{
                            text:"Total Unit Diterima"
                        },
                    },
                    {
                        dataField:"sisaJumlahUnit",
                        label:{
                            text:"Sisa Jumlah Unit"
                        },
                    },
                    {
                        itemType: 'group',
                        caption: 'Informasi Pengajuan Pengadaan',
                        items: [
                            {
                                dataField: 'tanggalPengajuan',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Pengajuan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    width: '100%',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'tanggalPengadaan',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Pengadaan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    width: '100%',
                                    readOnly: true
                                }
                            },
                            {
                                dataField:"paymentMethod",
                                label:{
                                    text:"Payment Method",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    placeholder: '',
                                    dataSource: [
                                        {
                                            id: 1,
                                            value: 'Aset'
                                        },
                                        {
                                            id: 2,
                                            value: 'Barang'
                                        },
                                        {
                                            id: 3,
                                            value: 'Jasa'
                                        },
                                    ],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                            {
                                dataField:"unitId",
                                label:{
                                    text:"Unit Kerja",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    placeholder: '',
                                    dataSource: sdmUnit(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'unitName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                            {
                                dataField:"jenisAset",
                                label:{
                                    text:"Jenis Aset",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    placeholder: '',
                                    dataSource: [
                                        {
                                            id: 1,
                                            value: 'Jenis 1'
                                        },
                                        {
                                            id: 2,
                                            value: 'Jenis 2'
                                        },
                                        {
                                            id: 3,
                                            value: 'Jenis 3'
                                        },
                                    ],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                            {
                                dataField:"tipeAset",
                                label:{
                                    text:"Tipe Aset",
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField:"jumlahPengadaan",
                                label:{
                                    text:"Jumlah Pengadaan",
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:'#,##0',
                                    readOnly: true
                                }
                            },
                            {
                                dataField:"spesifikasi",
                                label:{
                                    text:"Spesifikasi",
                                },
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    
                    {
                        itemType: 'group',
                        caption: 'Informasi Supplier',
                        items: [
                            {
                                dataField: 'kodeSupplier',
                                label: {
                                    alignment: 'left',
                                    text: 'Kode Supplier'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'namaSupplier',
                                label: {
                                    alignment: 'left',
                                    text: 'Nama Supplier'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'alamat',
                                label: {
                                    alignment: 'left',
                                    text: 'Alamat'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'contactPerson',
                                label: {
                                    alignment: 'left',
                                    text: 'Contact Person'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'email',
                                label: {
                                    alignment: 'left',
                                    text: 'Email'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField:"noTelp",
                                label:{
                                    text:"No Telp",
                                },
                                editorType:'dxNumberBox',
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField:"maxPembelianTahun",
                                label:{
                                    text:"Max Pembelian / Tahun",
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:'#,##0',
                                    readOnly: true
                                }
                            },
                            {
                                dataField:"totalPembelianTahunBerjalan",
                                label:{
                                    text:"Total Pembelian Tahun Berjalan",
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:'#,##0',
                                    readOnly: true
                                }
                            },
                            {
                                dataField:"statusSupplier",
                                label:{
                                    text:"Status Supplier",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    placeholder: '',
                                    dataSource: [
                                        {
                                            id: 1,
                                            value: 'Status 1'
                                        },
                                        {
                                            id: 2,
                                            value: 'Status 2'
                                        },
                                    ],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                        ]
                    }
                ]
            }
        ]

        this.columnsPenerimaan = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPenerimaanPemesanan(e)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.kirim(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField:'noPenerimaan',
                caption:'No Penerimaan'
            },
            {
                dataField:'tanggalDiterima',
                caption:'Tanggal Diterima'
            },
            {
                dataField:'jumlah',
                caption:'Jumlah'
            },
            {
                dataField:'noDokumen',
                caption:'No Dokumen'
            },
            {
                dataField:'diterimaOleh',
                caption:'Diterima Oleh'
            },
        ]

        this.columnsTagihan = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalTagihanPembelian(e)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.kirim(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField:'noTagihan',
                caption:'No Tagihan'
            },
            {
                dataField:'tanggalTagihan',
                caption:'Tanggal Tagihan'
            },
            {
                dataField:'jatuhTempo',
                caption:'Jatuh Tempo'
            },
            {
                dataField:'jumlahUnit',
                caption:'Jumlah Unit'
            },
            {
                dataField:'harga',
                caption:'Harga'
            },
            {
                dataField:'discount',
                caption:'Discount'
            },
            {
                dataField:'tagihanBersih',
                caption:'Tagihan Bersih'
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Kirim",
                onClick: () => {
                    console.log('success')
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: () => {
                    console.log('success')
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
        this.showModalPenerimaanPemesananRef = React.createRef()
        this.showModalTagihanPembelianRef = React.createRef()
    }

    retrieveData = (data, type) => {
        this.setState({
            DataMaster:data
        })
    }

    showModalPenerimaanPemesanan = (e) => {
        if (e.row) {
            this.showModalPenerimaanPemesananRef.current.retrieveData(e.row.data)
        }
        this.showModalPenerimaanPemesananRef.current.show()
    }

    showModalTagihanPembelian = (e) => {
        if (e.row) {
            this.showModalTagihanPembelianRef.current.retrieveData(e.row.data)
        }
        this.showModalTagihanPembelianRef.current.show()
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    loadDummyData = () => {
        var data = [
            {
                id:1
            }
        ]
        
        return data
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Dokumen Tagihan Pembelian'} 
                minWidth={700}
                minHeight={500}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            colCount={2}
                            id={'formPotongan'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation = 'left'
                        />
                        </div>
                        <div className="col-md-6">
                            <h5>Penerimaan Pemesanan</h5>
                        <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='daftar-penagjuan-pengadaan'
                        insertAPI='daftar-penagjuan-pengadaan'
                        updateAPI='daftar-penagjuan-pengadaan'
                        deleteAPI='daftar-penagjuan-pengadaan'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource={true}
                        ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Penerimaan Pembelian"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Penerimaan Pembelian'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columnsPenerimaan} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'400'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalPenerimaanPemesanan 
                        ref={this.showModalPenerimaanPemesananRef}
                        store={this.props.store}
                    />
                    </div>
                        <div className="col-md-6">
                            <h5>Tagihan</h5>
                        <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='daftar-penagjuan-pengadaan'
                        insertAPI='daftar-penagjuan-pengadaan'
                        updateAPI='daftar-penagjuan-pengadaan'
                        deleteAPI='daftar-penagjuan-pengadaan'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource={true}
                        ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Penerimaan Pembelian"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Penerimaan Pembelian'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columnsTagihan} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'400'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalEditTagihanPembelian 
                        ref={this.showModalTagihanPembelianRef}
                        store={this.props.store}
                    />
                    </div>
                </div>
            </ScrollView>
            </Popup>
        )
    }
}

export default FormModalTagihanPembelian