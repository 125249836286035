import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { gender, banks, kjpp } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import DetailPortofolioProperti from 'components/pages/modal/investasi/portfolio/balance-position/detail/detailProperti'
import UpdateNilaiWajar from 'components/pages/modal/investasi/portfolio/balance-position/detail/updateNilaiWajar'

class DataGridProperti extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'tanggalPembelian'
            },
            {
                name: 'nilaiPembelian',
                column: 'nilaiPembelian',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'appraisalValue',
                column: 'appraisalValue',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'plAmount',
                column: 'plAmount',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'komposisi',
                column: 'komposisi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        onClick: (e) => {
                            this.showDetail(e.row.data)
                        }
                    },
                    {
                        text: 'Update Nilai Wajar',
                        hint: 'Update Nilai Wajar',
                        onClick: async (e) => {
                            if (e.row) {
                                this.showModalUpdateNilaiWajar(e.row.data)
                            }
                        },
                    },
                ]
                
            },
            {
                dataField: 'no.',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Properti',
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Properti',
            },
            {
                dataField: 'balanceDate',
                caption: 'Tanggal Pembelian',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'avgPrice',
                caption: 'Rata Rata Harga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'quantity',
                caption: 'Jumlah Unit',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'nilaiPembelian',
                caption: 'Nilai Pembelian',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'valuasiTerakhir',
                caption: 'Valuasi Terakhir',
                alignment: 'center',
                columns: [
                    // {
                    //     dataField: 'priceDate',
                    //     caption: 'Tanggal',
                    //     dataType: 'date',
                    //     format: 'dd MMM yyyy'
                    // },
                    {
                        dataField: 'appraisalYear',
                        caption: 'Tahun',
                    },
                    {
                        dataField: 'appraisalKjppName',
                        caption: 'KJPP',
                        // lookup: {
                        //     dataSource: kjpp(this.props.store),
                        //     valueExpr: 'id',
                        //     displayExpr: 'kjppName'
                        // }
                    },
                    // {
                    //     dataField: 'kjppId',
                    //     caption: 'KJPP',
                    //     lookup: {
                    //         dataSource: kjpp(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'kjppName'
                    //     }
                    // },
                    {
                        dataField: 'appraisalValue',
                        caption: 'Nilai Wajar',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    // {
                    //     dataField: 'nilaiWajar',
                    //     caption: 'Nilai Wajar',
                    //     alignment: 'right',
                    //     dataType: 'number',
                    //     format: '#,##0.00'
                    // },
                ]
            },
            {
                dataField: 'plPercent',
                caption: 'Unrealized P/L(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'plAmount',            
                caption: 'Unrealized P/L (Rp)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'komposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'decimal',
            },
        ]
        this.modalRef = React.createRef()
        this.showModalUpdateNilaiWajarRef = React.createRef()
    }
    showModalUpdateNilaiWajar = async(data) => {
        let getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments?instrumentCode.equals=${data.instrumentCode}&size=9999`, 'GET');
        
        if (data) {
            this.showModalUpdateNilaiWajarRef.current.show()
            this.showModalUpdateNilaiWajarRef.current.retrieveData(data, 'avg', getInstrument[0].id)
        }
    }
    showDetail = async (data) => {
        if (data) {
            var dataEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-instrumentId/${data.id}`, 'GET')
            var dataBalanceBuyDate = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/get-list-detail-properti/${data.id}/${dataEfekBalance[0].productId}/${data.kpdId}`, 'GET')
            var result = []
            for (var detail of dataBalanceBuyDate) {
                var customData = {
                    id: detail.id,
                    tipe: detail.tipe,
                    keterangan: detail.keterangan,
                    buyDate: detail.buyDate,
                    quantity: detail.quantity,
                    buyPrice: detail.buyPrice,
                    nilaiPerolehan: detail.nilaiPerolehan,
                    bookValue: detail.bookValue,
                    priceDate: detail.priceDate,
                    price: detail.price,
                    nilaiMtm: detail.nilaiMtm,
                    selisih: detail.selisih,
                }
                result.push(customData)
            }
            this.modalRef.current.show(result)
        }
    }
    // loadData = async () => {
    //     try {
    //         let data = [];
    //         let getEfekBalanceAvg = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/instrument-portofolio-properti?productId=${this.props.productId}`, 'GET');
    //         let getInstruments = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments?size=9999`, 'GET');

    //         for (let value of getEfekBalanceAvg) {
    //             console.log(value);

    //             let filterData = getInstruments.filter(v => v.instrumentCode === value.instrumentCode);
    //         }

    //         return getEfekBalanceAvg;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // } 
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    loadAPI={`efek-balance-avgs/instrument-portofolio-properti?productId=${this.props.productId}`}
                    insertAPI='propertis'
                    updateAPI='propertis'
                    deleteAPI='propertis'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource={true}
                    // ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Portofolio Properti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Properti'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    height={'calc(100vh - 336px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <DetailPortofolioProperti 
                    ref={this.modalRef}
                    store={this.props.store}
                />
                <UpdateNilaiWajar
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    ref={this.showModalUpdateNilaiWajarRef}
                />
            </React.Fragment>
        )
    }
}

export default DataGridProperti
