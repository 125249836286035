import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatUploadFileData } from 'plugin/helper'

class FormModalTanggalSerahTerima extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
            fileUploadReceipt:[],

        }

        this.form = [
            {
                dataField:'tglSerahTerima',
                label:{
                    text:'Tanggal Serah Terima'
                },
                editorType:'dxDateBox',
                editorOptions:{
                    displayFormat:'dd MMM yyyy'
                }
            },
            {
                itemType: "group",
                name:"dari",
                caption:"Dari",
                items:[
                    {
                        dataField:'unitKerja',
                        label:{
                            text:'Unit Kerja'
                        }
                    },
                    {
                        dataField:'karyawan',
                        label:{
                            text:'Karyawan'
                        }
                    },
                ]
            },
            {
                itemType: "group",
                name:"kepada",
                caption:"Kepada",
                items:[
                    {
                        dataField:'unitKerja',
                        label:{
                            text:'Unit Kerja'
                        }
                    },
                    {
                        dataField:'karyawan',
                        label:{
                            text:'Karyawan'
                        }
                    },
                ]
            },
            {
                dataField:'keterangan',
                label:{
                    text:'Keterangan'
                },
                editorType:'dxTextArea'
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}Name`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    submitData = () => {
    
    }

    updateData = () => {
    
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Serah Terima Aset'} 
                width={600}
                height={500}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
                <FileUploader
                    accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                    uploadMode={'useForm'}
                    onValueChanged={this.onUploadStarted.bind(this)} 
                    labelText="Upload File Disini"
                    showFileList={false}
                    name={'file'}
                    value = {this.state.fileUploadReceipt}
                    disabled={false }
                    ref={this.fileUploderRef}
                />
                <div className="row">
                    <label className="col-4">File Name:</label>
                    <label className="col-8">{ this.state.DataMaster.fileName}</label>
                </div>
            </Popup>
        )
    }
}

export default FormModalTanggalSerahTerima 