import React, { Component } from 'react'
import { Form } from 'devextreme-react/form'
import { FileUploader,Popup } from 'devextreme-react'
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { karyawan, SKTypeHRD } from 'dataSource/lookup';
import { formatUploadFileData, formatDate, download, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class FormSuratKeputusanKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fileUploadReceipt:[],
            popupVisible:false,
            DataSK: {},
            type:'tambah'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.uploadGajiRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
            
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
            
        ];

        this.formSuratKeputusan = [
            {
                dataField:"skNo",
                label:{
                    text:"No. SK"
                }
            },
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Karyawan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                },
            },
            {
                dataField:"skTypeId",
                label:{
                    text:"Jenis SK"
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: SKTypeHRD(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'typeName', // provides display values
                },
            },
            {
                dataField:"skDate",
                label:{
                    text:"Tanggal SK"
                },
                editorType:"dxDateBox",
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick:true,
                    useMaskBehavior:true
                }
            },
            {
                dataField:"efektifDate",
                label:{
                    text:"Tanggal Efektif SK"
                },
                editorType:"dxDateBox",
                editorOptions:{
                    displayFormat:"dd MMM yyyy",
                    openOnFieldClick:true,
                    useMaskBehavior:true
                }
            },
            // {
            //     name: 'balanceGroup',
            //     itemType: "group",
            //     colCount: 2,
            //     colSpan: 2,
            //     items: [
                    {
                        dataField:"perubahanGaji",
                        label:{
                            text:"Perubahan Gaji"
                        },
                        editorType:"dxCheckBox",
                        editorOptions:{
                            onValueChanged: (e) => {
                                if(e.value) {
                                    this.uploadGajiRef.current.instance.option('readOnly', true)
                                } else {
                                    this.uploadGajiRef.current.instance.option('readOnly', false)
                                }
                                // this.uploadGajiRef.current.instance.repaint()
                                // if (e.value) {
                                //     var options = this.formRef.current.instance.itemOption('updateGaji')
        
                                //     options.editorOptions.disabled = false
                                    
                                //     this.formRef.current.instance.itemOption('updateGaji',options)
                                // }else{
                                //     var options = this.formRef.current.instance.itemOption('updateGaji')
        
                                //     options.editorOptions.disabled = true
                                    
                                //     this.formRef.current.instance.itemOption('updateGaji',options)
                                // }
                            }
                        }
                    },
                    // {
                    //     dataField: 'updateGaji',
                    //     label:{
                    //         visible:false,
                    //     },
                    //     editorType: 'dxButton',
                    //     editorOptions: {
                    //         disabled:true,
                    //         text: 'Update Gaji',
                    //         onClick: (e) => {
                    //             console.log('searcing..')
                    //         }
                    //     }
                    // }
            //     ]
            // }
        ]
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            DataSK: {},
            type:'tambah'
        })
    }

    fieldReadOnly = (data) => {
        this.setState({
            type : data
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataSK = { ...this.state.DataSK };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataSK[`${fieldName}Name`] = files.name;
            DataSK[fieldName] = formattedFileData.base64data;
            DataSK[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataSK: DataSK
            });
        };

        fr.readAsDataURL(files);
        }
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var loadAPI = `karyawan-sks`
        var data = {...this.state.DataSK}
        var dataSK = {
            karyawanId:data.karyawanId,
            efektifDate:formatDate(data.efektifDate),
            file:data.file,
            fileContentType:data.fileContentType,
            fileName:data.fileName,
            updateGaji: data.updateGaji,
            updateGajiContentType: data.updateGajiContentType,
            updateGajiName: data.updateGajiName,
            skDate:formatDate(data.skDate),
            skNo:data.skNo,
            skTypeId:data.skTypeId,
            perubahanGaji: data.perubahanGaji
        }
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: dataSK,
        })

        if (response) {
            notify({ message: "Sukses Input SK Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            this.props.forceRefresh()
            this.hide()
        }
    }

    updateData = async () => {
        var loadAPI = `karyawan-sks`
        var data = {...this.state.DataSK}
        var dataSK = {
            id:data.id,
            karyawanId:data.karyawanId,
            efektifDate:formatDate(data.efektifDate),
            file:data.file,
            fileContentType:data.fileContentType,
            fileName:data.fileName,
            updateGaji: data.updateGaji,
            updateGajiContentType: data.updateGajiContentType,
            updateGajiName: data.updateGajiName,
            skDate:formatDate(data.skDate),
            skNo:data.skNo,
            skTypeId:data.skTypeId,
            perubahanGaji: data.perubahanGaji
        }
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
            values: dataSK,
            key:dataSK.id
        },dataSK)

        if (response) {
            notify({ message: "Sukses Update SK Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            this.props.forceRefresh()
            this.hide()
        }
    }
    
    retrieveData = async(data,type = 'tambah') => {   
        var loadAPI = `karyawan-sks/${data.karyawanSkId}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')
        getData.perubahanGaji = false
        
        this.setState({
            DataSK: getData,
            type : type
        })
    }

    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            // var extension = mime.extension(this.state.DataSK[`${fileName}ContentType`])
            // console.log(extension)
            var src = imageSource(this.state.DataSK[`${fileName}ContentType`],this.state.DataSK[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataSK[`${fileName}ContentType`],this.state.DataSK[`${fileName}`]),this.state.DataSK[`${fileName}Name`])
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Surat Keputusan Karyawan"}
                width={"40vw"}
                height={"55vh"}
                toolbarItems={this.state.type === "tambah" ? this.PopupToolbarItem : this.state.type === "ubah" ? this.PopupToolbarItem2 : '' }
                >
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formSuratKeputusan'}
                        formData={this.state.DataSK}
                        items={this.formSuratKeputusan}
                        labelLocation = 'left'
                        readOnly={this.state.type === "lihat" ? true : false }
                    />
                    <div className='row my-3'>
                        <div className='col-md-6'>
                            <FileUploader
                                accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                                uploadMode={'useForm'}
                                onValueChanged={this.onUploadStarted.bind(this)} 
                                labelText={this.state.DataSK.updateGajiName ? this.state.DataSK.updateGajiName : "Upload File Disini"}
                                selectButtonText="UPDATE GAJI"
                                showFileList={false}
                                name={'updateGaji'}
                                disabled={this.state.type === "lihat" ? true : false }
                                value = {this.state.fileUploadReceipt}
                                ref={this.uploadGajiRef}
                            />
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile('updateGaji')} readOnly={`${this.state.DataSK.perubahanGaji === true ? 'readOnly' : ''}`} className={`btn btn-light btn-sm border rounded ${this.state.DataSK.updateGaji ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                            <button onClick={() => this.downloadFile('updateGaji')} readOnly={`${this.state.DataSK.perubahanGaji === true ? 'readOnly' : ''}`} className={`btn btn-light btn-sm border rounded ${this.state.DataSK.updateGaji ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Download
                            </button>
                        </div>
                        
                        <div className="col-md-6">
                            <FileUploader
                                accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                                uploadMode={'useForm'}
                                onValueChanged={this.onUploadStarted.bind(this)} 
                                labelText={this.state.DataSK.fileName || "Upload File Disini"}
                                showFileList={false}
                                name={'file'}
                                disabled={this.state.type === "lihat" ? true : false }
                                value = {this.state.fileUploadReceipt}
                                ref={this.fileUploderRef}
                            />
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.DataSK.file ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                            <button onClick={() => this.downloadFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.DataSK.file ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Download
                            </button>
                        </div>
                    </div>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormSuratKeputusanKaryawan