import React, { Component } from 'react'
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'

import { addURL } from '../../redux/actions/url'
import { sessionToken, sessionRole, sessionUser } from '../../redux/actions/session'
import { showLoading } from '../../redux/actions/loading'
import { getParam } from '../../redux/actions/gblparam'
import axiosException from "../../plugin/exceptionmessage"


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            hidden: true
        };
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        this.login()

        e.preventDefault();
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden })
    }

    login = async () => {
        let store = this.props.store;
        try {
            store.dispatch(showLoading(true))
            let validationLogin = await axios.get(process.env.REACT_APP_BACKEND + `account/checkPasswordIsReset/${this.state.username}`, {
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
        
            if(validationLogin.data === true){
                store.dispatch(showLoading(false))
                window.location.href = '/createNewPassword'
            }else{
                let loginResponse = await axios.post(process.env.REACT_APP_BACKEND + 'authenticate', this.state, {
                    headers: {
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                
                store.dispatch(sessionToken(loginResponse.data.id_token))
                await this.getGlobalParam(loginResponse.data.id_token)
                // store.dispatch(sessionToken(this.state.username))
    
                let userData = await axios.get(process.env.REACT_APP_BACKEND + 'account', {
                    headers: {
                        Authorization: 'Bearer ' + loginResponse.data.id_token //the token is a variable which holds the token
                    }
                })
                store.dispatch(sessionRole(userData.data.sysGroups))
                store.dispatch(sessionUser(userData.data.login))
                store.dispatch(showLoading(false))
            }
        } catch (error) {
            confirmAlert({
                title: 'Login Failed',
                message: axiosException(error),
                buttons: [
                    {
                        label: 'Close',
                    },
                ]
            });
            store.dispatch(showLoading(false))
        }

    }

    getGlobalParam = async (token) => {
        let store = this.props.store;

        let gblParamData = await axios.get(process.env.REACT_APP_BACKEND + 'global-parameters', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        })

        store.dispatch(getParam(gblParamData.data))
    }

    render() {
        const { username, password, hidden } = this.state;

        return (
            <div>
                <div className="container-fluid d-flex justify-content-center align-items-center" style={{ minHeight: 'calc(100vh)', background: 'url("/assets/img/login-bg.png") no-repeat', backgroundSize: 'cover' }}>
                    <div className="login-clean">
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="illustration">
                                <img src="/assets/img/logo.png" alt="App Logo"></img>
                            </div>
                            <div className="text-center mb-4">
                                <span className="font-weight-bold" style={{fontSize: '1.2rem'}}>Dana  Pensiun Digital System</span>
                            </div>
                            <div className="form-group mx-2 text-justify">
                                <span style={{fontSize: '0.8rem'}}>Silahkan <span className="font-weight-bold">Masuk</span> menggunakan akun Anda untuk melanjutkan</span>
                            </div>
                            <div className="form-group mb-3 mx-2">
                                <input className="form-control form-control-sm" type="text" name="username" value={username} onChange={this.handleChange} placeholder="User ID" />
                            </div>
                            <div className="form-group position-relative mb-5 mx-2">
                                <input className="form-transparent form-control form-control-sm" type={hidden ? "password" : "text"} name="password" value={password} onChange={this.handleChange} placeholder="Kata Sandi"/>
                                <i className={`${hidden ? "far fa-eye-slash" : "far fa-eye"} reveal-password`} onClick={this.toggleShow.bind(this)} />
                            </div>
                            <div className="form-group  mx-2">
                                <button className="btn btn-green text-white btn-lg btn-block btn-sm" type="submit">Masuk</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard