import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import React, { Component } from 'react'
import { Popup } from 'devextreme-react'
import { tipeShift } from 'dataSource/lookup'

class ModalJadwalShift extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
        }

        this.dataGridRef = React.createRef()

        this.columns = [
            {
                dataField: 'id',
                caption: "No.",
                cellRender: (data) => {
                  return data.rowIndex + 1
                },
            },
            {
                dataField: 'karyawanName',
                caption: "Karyawan",
            },
            {
                dataField: 'shiftType',
                caption: "Shift",
                lookup:{
                    dataSource: tipeShift(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaShift', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'tanggalShift',
                caption: "Tanggal Shift",
                dataType:'date',
                format:'dd MMM yyyy'
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.idShift = 0
    }

    retrieveData = async (data) => {
        this.idShift = data.karyawanShiftId
        this.dataGridRef.current.forceRefresh(true)
        
        // this.setState({
        //     DataShift: response[0],
        // })
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.idShift = 0
        this.setState({
            popupVisible: false,
            DataMaster: {},
        })
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-shifts/get-jadwal/${this.idShift}`, 'GET')

        return response
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled= {true}
                showTitle={true}
                title={"Jadwal Shift Karyawan"}
                width={"40vw"}
                height={"70vh"}
                toolbarItems={this.PopupToolbarItem}
                >

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-karyawan-shifts?size=9999'
                    insertAPI='karyawan-shifts'
                    updateAPI='karyawan-shifts'
                    deleteAPI='karyawan-shifts' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    keyField={'tanggalShift'}
                    height={535}
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Shift Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                </Popup>
            
        )
    }
}

export default ModalJadwalShift