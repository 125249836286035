import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { needSettleDepositoPerpanjangan, currency, banks, productDefault, kpds, rekeningBank } from 'dataSource/lookup';
import { formatUploadFileData } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';


class ModalActionPerpanjanganSettlementTransaksiDeposito extends Component {
    constructor(props) {
        super(props)
        this.pph = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-DEP') : null
        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPerpanjanganRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        if (this.validate()) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'KONFIRMASI',
                        items: [
                            {
                                dataField: 'referensiNo',
                                label: {
                                    text: 'No Konfirmasi Bank'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'bankId',
                                label: {
                                    text: 'Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'productId',
                                label: {
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'productCode',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'pengelola',
                                label: {
                                    text: 'Pengelolaan'
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    items: ['Internal', 'KPD'],
                                    layout: 'horizontal',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'kpdId',
                                label: {
                                    text: 'Tipe'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kpds(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'noKontrak',
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BILYET PERPANJANGAN',
                        items: [
                            {
                                dataField: 'bilyetNo',
                                label: {
                                    text: 'No. Bilyet'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'bilyetNo',
                                    displayExpr: 'bilyetNo',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: async (e) => {
                                        var data = e.selectedItem
                                        var diffTime = []
                                        var diffDays = []
                                        var result = 0
                                        var tenor = []
                                         
                                        if (data) {
                                            this.formRef.current.instance.getEditor('orderCode').option('value', data.orderCode)
                                            this.formRef.current.instance.getEditor('nominalAccruedBunga').option('value', data.acruedInterest)
                                            this.formRef.current.instance.getEditor('nominalBunga').option('value', data.acruedInterest)
                                            this.formRef.current.instance.getEditor('nominalDeposito').option('value', data.amountOrder)
                                            this.formRef.current.instance.getEditor('tanggalPerpanjangan').option('value', data.rolloverDate)
                                            this.formRef.current.instance.getEditor('receiveRekbankId').option('value', data.rekBankId)
                                            this.formRef.current.instance.getEditor('currencyId').option('value', data.currencyId)
                                            this.formRef.current.instance.getEditor('bunga').option('value', data.rate)
                                            this.formRef.current.instance.getEditor('pph').option('value', 0)
                                            this.setState({
                                                maturityDate: data.maturityDate,
                                                efekBalanceAvgId: data.efekBalanceAvgId
                                            })

                                            // var date1 = new Date('12/31/2021')
                                            // var date2 = new Date('01/01/2021')
                                            // diffTime = Math.abs(date1 - date2)
                                            // diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                            // var date3 = new Date(data.maturityDate)
                                            // var date4 = new Date(data.balanceDate)
                                            // let diffTime2 = Math.abs(date3 - date4)
                                            // tenor = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24))
                                            // var dayBases = diffDays + 1
                                            // result = (data.amountOrder * (data.rate / 100)) * (tenor / dayBases)
                                            this.formRef.current.instance.getEditor('bungaJatuhTempo').option('value', data.interestMaturityDate)
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'orderCode',
                                label: {
                                    text: 'No Order Pencairan'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'bunga',
                                label: {
                                    text: 'Bunga (%)'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'bungaJatuhTempo',
                                label: {
                                    text: 'Bunga Jatuh Tempo'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        this.formRef.current.instance.getEditor('pph').option('value', (this.pph.intValue / 100) * val)
                                        this.formRef.current.instance.getEditor('nominalBungaBersih').option('value', val - ((this.pph.intValue / 100) * val))
                                    }
                                }
                            },
                            {
                                dataField: 'nominalAccruedBunga',
                                label: {
                                    text: 'Nominal Accrued Bunga'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'nominalDeposito',
                                label: {
                                    text: 'Nominal Deposito'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                dataField: 'nominalBunga',
                                label: {
                                    text: 'Nominal Bunga'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                }
                            },
                            {
                                dataField: 'pph',
                                label: {
                                    text: 'PPH'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        var bungaJatuhTempo = this.formRef.current.instance.getEditor('bungaJatuhTempo').option('value')
                                        this.formRef.current.instance.getEditor('nominalBungaBersih').option('value', bungaJatuhTempo - val)
                                    }
                                }
                            },
                            {
                                dataField: 'nominalBungaBersih',
                                label: {
                                    text: 'Nominal Bunga Bersih'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    text: 'Mata Uang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyCode',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true,
                                    onSelectionChanged: (e) => {
                                        var data = e.selectedItem
                                        if (data) {
                                            this.formRef.current.instance.getEditor('kurs').option('value', data.rate)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'kurs',
                                label: {
                                    text: 'Kurs'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'CATATAN',
                        items: [
                            {
                                dataField: 'catatan',
                                label: {
                                    visible: false
                                },
                                editorType: 'dxTextArea',
                                editorOptions: {
                                    height: '80'
                                }
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'PERPANJANGAN',
                        items: [
                            {
                                dataField: 'tanggalPerpanjangan',
                                label: {
                                    text: 'Tanggal Perpanjangan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'rolloverTypeId',
                                label: {
                                    text: 'Tipe Perpanjangan',
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    items: ['Pokok + Bunga', 'Pokok Saja', 'Sebagian'],
                                    layout: 'horizontal',
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        var nominalPerpanjangan = this.formRef.current.instance.getEditor('nominalPerpanjangan')
                                        var nominalTransfer = this.formRef.current.instance.getEditor('nominalTransfer')
                                        var nominalDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                        var nominalBungaBersih = this.formRef.current.instance.getEditor('nominalBungaBersih').option('value')
                                        var feeBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        if (val == 'Pokok + Bunga') {
                                            nominalPerpanjangan.option('value', nominalDeposito + nominalBungaBersih)
                                            nominalTransfer.option('value', 0)
                                            nominalPerpanjangan.option('readOnly', true)
                                        } else if (val == 'Pokok Saja') {
                                            nominalPerpanjangan.option('value', nominalDeposito)
                                            nominalTransfer.option('value', nominalBungaBersih - feeBank)
                                            nominalPerpanjangan.option('readOnly', true)
                                        } else {
                                            nominalPerpanjangan.option('value', 0)
                                            nominalTransfer.option('value', 0)
                                            nominalPerpanjangan.option('readOnly', false)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'nominalPerpanjangan',
                                label: {
                                    text: 'Nominal Perpanjangan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        var rolloverType = this.formRef.current.instance.getEditor('rolloverTypeId').option('value')
                                        var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        var nominalTransfer = this.formRef.current.instance.getEditor('nominalTransfer')
                                        var nominalDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                        var nominalBungaBersih = this.formRef.current.instance.getEditor('nominalBungaBersih').option('value')
                                        if (rolloverType == 'Sebagian') {
                                            nominalTransfer.option('value', nominalDeposito + nominalBungaBersih - val - biayaBank)
                                        }
                                    }
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please fill Nominal'
                                    }
                                ]
                            },
                            {
                                dataField: 'nominalTransfer',
                                label: {
                                    text: 'Nominal Transfer'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please fill Transfer Nominal'
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BILYET BARU',
                        items: [
                            {
                                dataField: 'bilyetType',
                                label: {
                                    text: 'Bilyet'
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    items: ['Bilyet Lama', 'Baru'],
                                    layout: 'horizontal',
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        if (val == 'Bilyet Lama') {
                                            this.formRef.current.instance.getEditor('newBilyetNo').option('readOnly', true)
                                            this.formRef.current.instance.getEditor('newBilyetNo').option('value', this.formRef.current.instance.getEditor('bilyetNo').option('value'))
                                        } else {
                                            this.formRef.current.instance.getEditor('newBilyetNo').option('readOnly', false)
                                            this.formRef.current.instance.getEditor('newBilyetNo').option('value', null)
                                        }
                                    }
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please choose Bilyet Type'
                                    }
                                ]
                            },
                            {
                                dataField: 'newBilyetNo',
                                label: {
                                    text: 'No Bilyet Baru'
                                }
                            },
                            {
                                dataField: 'ratePerpanjangan',
                                label: {
                                    text: 'Rate Perpanjangan (%)'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                itemType: 'group',
                                colCount: 2,
                                items: [
                                    {
                                        dataField: 'tenor',
                                        label: {
                                            text: 'Tenor'
                                        },
                                        editorType: 'dxNumberBox',
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: "Please fill tenor"
                                            }
                                        ]
                                    },
                                    {
                                        dataField: 'tenorTypeId',
                                        label: {
                                            visible: false
                                        },
                                        editorType: 'dxRadioGroup',
                                        editorOptions: {
                                            layout: 'horizontal',
                                            items: ['HARI', 'BULAN'],
                                            onValueChanged: (e) => {
                                                var val = e.value
                                                var tenor = this.formRef.current.instance.getEditor('tenor').option('value')
                                                var date = []
                                                var result = []
                                                if (val == 'BULAN') {
                                                    date = new Date(this.state.maturityDate).getMonth();
                                                    result = new Date(this.state.maturityDate).setMonth(date + tenor)
                                                } else {
                                                    date = new Date(this.state.maturityDate).getTime();
                                                    result = date + (tenor * 24 * 3600 * 1000)
                                                }
                                                var jatuhTempo = new Date(result)
                                                this.formRef.current.instance.getEditor('maturityDate').option('value', jatuhTempo)
                                            }
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: "Please choose tenor type"
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                dataField: 'maturityDate',
                                label: {
                                    text: 'Tanggal Jatuh tempo'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    readOnly: true
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BANK',
                        items: [
                            {
                                dataField: 'receiveRekbankId',
                                label: {
                                    text: 'Rekening Pencairan/Bunga'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    },
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    text: 'Biaya Bank/RTGS'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        var rolloverType = this.formRef.current.instance.getEditor('rolloverTypeId').option('value')
                                        var nominalPerpanjangan = this.formRef.current.instance.getEditor('nominalPerpanjangan').option('value')
                                        var nominalTransfer = this.formRef.current.instance.getEditor('nominalTransfer')
                                        var nominalDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                        var nominalBungaBersih = this.formRef.current.instance.getEditor('nominalBungaBersih').option('value')
                                        if (rolloverType == 'Sebagian' || rolloverType == 'Pokok Saja') {
                                            nominalTransfer.option('value', nominalDeposito + nominalBungaBersih - nominalPerpanjangan - val)
                                        }
                                    }
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please fill Fee Bank'
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'DOKUMEN',
                        items: [
                            {
                                dataField: "fileKonfirmasi",
                                label: {
                                    text: "File Konfirmasi",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    }
                ]
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                bankId: data.bankId,
                kpdId: data.kpdId,
                referensiNo: data.referensiNo,
                productId: data.productId,
                pengelola: data.pengelola,
                orderCode: data.orderCode,
                tenor: data.tenor,
                tenorTypeId: data.tenorTypeId,
                ratePerpanjangan: data.ratePerpanjangan,
                biayaBank: data.biayaBank,
                bilyetNo: data.bilyetNo,
                bilyetType: data.bilyetType == 0 ? 'Bilyet Lama' : 'Baru',
                newBilyetNo: data.newBilyetNo,
                maturityDate: data.maturityDate,
                nominalPerpanjangan: data.nominalPerpanjangan,
                nominalAccruedBunga: data.nominalAccruedBunga,
                bunga: data.bunga,
                pph: data.pph,
                nominalBungaBersih: data.nominalBungaBersih,
                efekBalanceAvgId: data.efekBalanceAvgId,
                kurs: data.kurs,
                catatan: data.catatan,
                nominalBunga: data.nominalBunga,
                nominalDeposito: data.nominalDeposito,
                nominalTransfer: data.nominalTransfer,
                receiveRekbankId: data.receiveRekbankId,
                tanggalPerpanjangan: data.tanggalPerpanjangan,
                rolloverTypeId: data.rolloverTypeId == 0 ? 'Pokok + Bunga' : data.rolloverTypeId == 1 ? 'Pokok Saja' : 'Sebagian'
            }
        });
        var selectBilyetNo = this.formRef.current.instance.getEditor('bilyetNo')
        selectBilyetNo.option('dataSource', needSettleDepositoPerpanjangan(this.props.store, data.bankId, data.kpdId, data.productId))
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        var data = this.state.dataMaster
        this.props.submitDetailPerpanjanganData({
            id: data.id,
            bankId: data.bankId,
            kpdId: data.kpdId,
            referensiNo: data.referensiNo,
            productId: data.productId,
            pengelola: data.pengelola,
            orderCode: data.orderCode,
            tenor: data.tenor,
            tenorTypeId: data.tenorTypeId,
            ratePerpanjangan: data.ratePerpanjangan,
            biayaBank: data.biayaBank,
            bilyetNo: data.bilyetNo,
            bilyetType: data.bilyetType == 'Baru' ? 1 : 0,
            newBilyetNo: data.newBilyetNo,
            maturityDate: data.maturityDate,
            nominalPerpanjangan: data.nominalPerpanjangan,
            nominalAccruedBunga: data.nominalAccruedBunga,
            bunga: data.bunga,
            pph: data.pph,
            nominalBungaBersih: data.nominalBungaBersih,
            efekBalanceAvgId: this.state.efekBalanceAvgId,
            kurs: data.kurs,
            catatan: data.catatan,
            nominalBunga: data.nominalBunga,
            nominalDeposito: data.nominalDeposito,
            nominalTransfer: data.nominalTransfer,
            receiveRekbankId: data.receiveRekbankId,
            tanggalPerpanjangan: data.tanggalPerpanjangan,
            rolloverTypeId: data.rolloverTypeId == 'Pokok + Bunga' ? 0 : data.rolloverTypeId == 'Pokok Saja' ? 1 : 2,
        })

        this.hide()
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }
    retrieveData = async (data) => {
        this.setState({
            dataMaster: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Perpanjangan Deposito'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                ref={this.formRef}
                                formData={this.state.dataMaster}
                                items={this.items}
                                scrollingEnabled={true}
                                height={'calc(70vh - 60px)'}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalActionPerpanjanganSettlementTransaksiDeposito
