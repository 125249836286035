import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { coa } from 'dataSource/lookup';
import uuidv4 from 'uuid/v4'
import httpRequest from 'plugin/httprequest';
import { download, imageSource } from 'plugin/helper';
import ModalPreviewPdf from '../../kepesertaan/compPeserta/previewPdf';

class ViewFileAccounting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
        };
        this.formRef = React.createRef()
        this.journalDetailRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.dataJurnal = []
    }

    getToolbarItem = () => {
        return [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Close",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];
    }

    show = (fileInvoice, fileInstruksi) => {
        console.log(fileInvoice, fileInstruksi)
        this.setState({
            fileInvoice: fileInvoice.fileBase64,
            fileInvoiceContentType: fileInvoice.fileBase64ContentType,
            fileInvoiceName: fileInvoice.fileName,
            fileInstruksi: fileInstruksi.fileBase64,
            fileInstruksiContentType: fileInstruksi.fileBase64ContentType,
            fileInstruksiName: fileInstruksi.fileName,
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    previewFile = async (fileBase64, fileBase64ContentType) => {
        var src = imageSource(fileBase64ContentType, fileBase64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    downloadFile = (fileBase64, fileBase64ContentType, fileName) => {
        let name = fileName.split('.')
        download(imageSource(fileBase64ContentType,fileBase64),name[0],name[1])
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"File Transaksi"}
                    width={"70vw"}
                    height={"50vh"}
                    toolbarItems={this.getToolbarItem()}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <h3>FILE TAGIHAN</h3>
                                <h6>{this.state.fileInvoiceName}</h6>
                                <button onClick={() => this.previewFile(this.state.fileInvoice, this.state.fileInvoiceContentType)} className={`btn btn-light btn-sm border rounded ${this.state.fileInvoice ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka
                                </button>
                                <button onClick={() => this.downloadFile(this.state.fileInvoice, this.state.fileInvoiceContentType, this.state.fileInvoiceName)} className={`btn btn-light btn-sm border rounded ${this.state.fileInvoice ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Unduh
                                </button>
                            </div>
                            <div className="col-md-6 text-center">
                                <h3>FILE INSTRUKSI</h3>
                                <h6>{this.state.fileInstruksiName}</h6>
                                <button onClick={() => this.previewFile(this.state.fileInstruksi, this.state.fileInstruksiContentType)} className={`btn btn-light btn-sm border rounded ${this.state.fileInstruksi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka
                                </button>
                                <button onClick={() => this.downloadFile(this.state.fileInstruksi, this.state.fileInstruksiContentType, this.state.fileInstruksiName)} className={`btn btn-light btn-sm border rounded ${this.state.fileInstruksi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Unduh
                                </button>
                            </div>
                        </div>
                    </div>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </React.Fragment>

        );
    }
}
export default ViewFileAccounting