import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { ScrollView } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class ModalMatrik extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.datagridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.columns = [
            {
                dataField: 'outputRiskGrade',
                caption: 'Output Risk Grade',
            },
            {
                dataField: '1',
                caption: '0 ≤ x ≤ Rp. 100 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '2',
                caption: 'Rp. 100 M < x ≤ Rp. 200 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '3',
                caption: 'Rp. 200 M < x ≤ Rp. 300 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '4',
                caption: 'Rp. 300 M < x ≤ Rp. 400 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '5',
                caption: 'x ≥ Rp. 400 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
        ]
        this.dictionary = [
            {
                coordinate: [
                    [
                        1, 1
                    ],
                    [
                        1, 2
                    ],
                    [
                        2, 1
                    ],
                    [
                        3, 1
                    ]
                ],
                color: '#73c86e'
            },
            {
                coordinate: [
                    [
                        5, 1
                    ],
                    [
                        4, 1
                    ],
                    [
                        4, 2
                    ],
                    [
                        3, 2
                    ],
                    [
                        3, 3
                    ],
                    [
                        2, 2
                    ],
                    [
                        2, 3
                    ],
                    [
                        1, 3
                    ],
                    [
                        1, 4
                    ],
                ],
                color: '#fff208'
            },
            {
                coordinate: [
                    [
                        5, 2
                    ],
                    [
                        5, 3
                    ],
                    [
                        4, 3
                    ],
                    [
                        4, 4
                    ],
                    [
                        3, 4
                    ],
                    [
                        2, 4
                    ],
                    [
                        2, 5
                    ],
                    [
                        1, 5
                    ],
                ],
                color: '#fec000'
            },
            {
                coordinate: [
                    [
                        5, 4
                    ],
                    [
                        5, 5
                    ],
                    [
                        4, 5
                    ],
                    [
                        3, 5
                    ]
                ],
                color: '#ff0808'
            },
        ]
    }
    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: data
        })
        this.getData()
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }
    getData = async () => {
        let dataMatrix = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-matrik-resikos?size=9999`);
        dataMatrix = dataMatrix.filter(value => value.matrikx && value.matriky)

        var formatedData = []
        for (const matrix of dataMatrix) {
            const formatedDataFoundIndex = formatedData.findIndex(value => value.x === matrix.matrikx && value.y === matrix.matriky)

            if (formatedDataFoundIndex !== -1) {
                formatedData[formatedDataFoundIndex].description.push(`${matrix.categoryName}-${matrix.subName}`)
            } else {
                formatedData.push(
                    {
                        x: matrix.matrikx,
                        y: matrix.matriky,
                        description: [
                            `${matrix.categoryName}: ${matrix.subName}`
                        ]
                    }
                )
            }
        }

        const lookup = [
            'Low Risk - Score Risk 0 - 2,00',
            'Medium Risk - Score Risk 2,01 - 4,00',
            'Medium High Risk - Score Risk 4,01 - 6,00',
            'High Risk - Score Risk 6,01 - 8,00',
            'Very High Risk - Score Risk 8,01 - 10',
        ]

        let result = []

        for (let y = 0; y < 5; y++) {
            let xValues = []
            for (let x = 0; x < 5; x++) {
                const matrixFound = formatedData.find(value => value.x === x && value.y === y)
                if (matrixFound) {
                    xValues[x] = matrixFound.description
                } else {
                    xValues[x] = []
                }
            }
            result.push({
                id: y + 1,
                outputRiskGrade: lookup[y],
                1: xValues[0].join('\n'),
                2: xValues[1].join('\n'),
                3: xValues[2].join('\n'),
                4: xValues[3].join('\n'),
                5: xValues[4].join('\n'),
            })
        }

        return result
    }
    generateBgColor = (e) => {
        const y = e.key
        const x = parseInt(e.column.dataField)
        return this.dictionary.find(value => value.coordinate.find(coor => coor[0] === y && coor[1] === x))
    }

    onCellPrepared = (e) => {
        if (e.rowType == 'data') {
            const dictFound = this.generateBgColor(e)
            if (dictFound) {
                e.cellElement.style.backgroundColor = dictFound.color
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'90vw'}
                    height="90vh"
                    title={`Lihat Matrik Risiko`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <DevExpressDataGrid
                                            loadAPI='user'
                                            insertAPI='user'
                                            updateAPI='user'
                                            deleteAPI='user'

                                            backendserver={process.env.REACT_APP_BACKEND_ACC}

                                            useArraySource={true}
                                            ArraySourceData={this.getData}

                                            allowAdding={false}
                                            allowDeleting={false}
                                            allowUpdating={false}
                                            exportExcel={false}
                                            exportFileName={"Matrikulasi Risiko"}
                                            allowExportSelectedData={false}
                                            selection={"none"}

                                            showBorders={true}

                                            paging={false}
                                            defaultPageSize={10}

                                            height={'calc(100vh - 532px)'}

                                            //bagian konfigurasi popup saat insert dan edit record
                                            popupTitle={'Matrikulasi Risiko'}
                                            popupWidth={700} //masukan dalam ukuran pixel
                                            popupHeight={500} //masukkan dalam ukuran pixel

                                            popupFormLabelLocation='left' //accepted value = top, left, right
                                            popupFormMinColWidth={300} // minimum lebar kolom
                                            popupFormColCount={3} //jumlah kolom pada form

                                            //akhir bagian konfigurasi popup

                                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                            FilterRow={false} // set false untuk mematikan fitur filter

                                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                            SummaryConfiguration={this.summary}

                                            onCellPrepared={this.onCellPrepared}

                                            //contoh konfigurasi kolom
                                            //this.columns = [{
                                            //    dataField: 'kolom1',
                                            //    caption: 'Ini Kolom 1'
                                            //}, {
                                            //    dataField: 'kolom2',
                                            //    caption: 'Ini Kolom 2'
                                            //}]
                                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                            store={this.props.store} // jangan di edit edit
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalMatrik