import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import uuidv4  from 'uuid/v4'
import { banks, companyBank } from 'dataSource/lookup';

class BankInstruction extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {}
    }

    this.dataGridRef = React.createRef()
    // this.modalDetailProsesIuranRef = React.createRef()
    // this.modalGenerateProsesIuranRef = React.createRef()

    this.filterItem = [
      {
        dataField: "tanggal1",
        label: {
          text: "Dari",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        dataField: "tanggal2",
        label: {
          text: "Sampai",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            this.filterSubmit()
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Detail',
            hint: 'Detail',
            cssClass: 'text-success',
            onClick: (e) => {
              // this.showModalDetail(e.row.data)
            }
          },
          {
            text: 'Proses',
            hint: 'Proses',
            cssClass: 'text-info',
            onClick: (e) => {
              // this.prosesData(e.row.data.id)
            }
          },
        ],
      },
      {
        dataField: 'generated',
        caption: 'Generated',
        type: 'boolean',
        alignment: 'center'
      },
      {
        dataField: 'instructionNo',
        caption: 'No Instruksi',
      },
      {
        dataField: 'entryDate',
        caption: 'Tanggal Entri',
        type : 'date',
        format: 'dd MMM yyyy'
      },
      {
        dataField: 'description',
        caption: 'Deskripsi',
      },
      {
        dataField: 'reffType',
        caption: 'Reff Transaksi',
        lookup: {
          dataSource: [
            {
              id: 'IN',
              value: 'Investasi'
            },
            {
              id: 'HR',
              value: 'HRD & Umum'
            },
            {
              id: 'KP',
              value: 'Kepesertaan'
            },
            {
              id: 'CV',
              value: 'Cash Voucher'
            },
          ],
          valueExpr: 'id',
          displayExpr: 'value',
        }
      },
      {
        dataField: 'reffNo',
        caption: 'Reff Transaksi',
      },
      {
        dataField: 'inout',
        caption: 'Masuk/Keluar',
        lookup: {
            dataSource: [
                {
                    id: 'I',
                    value: 'Masuk'
                },
                {
                    id: 'O',
                    value: 'Keluar'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
      },
      {
        dataField: 'companyBankId',
        caption: 'Bank Perusahaan',
        // groupIndex: 0,
        lookup: {
          dataSource: companyBank(this.props.store),
          valueExpr: 'id',
          displayExpr: 'value',
        },
        calculateCellValue: (e) => {
            return `${e.companyBankReff}-${e.companyBankId}`
        }
      },
      {
        dataField: 'clientBankId',
        caption: 'Bank Tujuan / Pengirim',
        lookup: {
          dataSource: banks(this.props.store),
          valueExpr: 'id',
          displayExpr: 'bankName',
        }
      },
      // {
      //   dataField: 'clientBank',
      //   caption: 'Nama Bank Tujuan / Pengirim',
      // },
      {
        dataField: 'clientBankNo',
        caption: 'Rek. Bank Tujuan / Pengirim',
      },
      {
        dataField: 'currencyId',
        caption: 'Mata Uang',
      },
      {
        dataField: 'amount',
        caption: 'Nominal Transfer',
        format: '#,##0.00'
      },
      {
        dataField: 'charges',
        caption: 'Biaya',
        format: '#,##0.00'
      },
      {
        dataField: 'netAmount',
        caption: 'Nominal Bersih',
        format: '#,##0.00'
      },
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
          location: 'after',
          widget: 'dxButton',
          options: {
              icon: 'download',
              text: 'Generate Instruksi',
              onClick: (e) => {
                
              },
          }
      }
    )
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Bank Instruction</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='bank-instructions'
          insertAPI='bank-instructions'
          updateAPI='bank-instructions'
          deleteAPI='bank-instructions'

          backendserver={process.env.REACT_APP_BACKEND_ACC}

          remoteOperations = {true}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Bank Instruction"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}
          // grouping={true}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Bank Instruction'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          summaryTotalItem={this.sumDebit}
          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />

      </div>
    )
  }
}

export default BankInstruction