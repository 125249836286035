import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { showLoading } from 'redux/actions/loading'
import { getAllRIwayatPeserta, mpType, pesertaById, pesertas, product } from 'dataSource/lookup'
import { Form, Button } from 'devextreme-react'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultDate, PrintElem, generateExcelFile, formatNumber, formatDate, formatNumberAum } from 'plugin/helper'
import DropDownBox from 'devextreme-react/drop-down-box';
import { alert } from 'devextreme/ui/dialog'
import httpRequest from 'plugin/httprequest';
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import DaftarTransaksiBonus from 'components/pages/sdm-umum/payroll/bonusTahunan/daftarTransaksiBonus'

class ReportMpDetailRiwayatMpPeserta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formFilter: {},
            src: ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.dropDownBoxRef = React.createRef()

        this.namaPeserta = ''
        this.pesertaId = 0
        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    // {
                    //     dataField: 'pesertaId',
                    //     label: {
                    //         text: 'Peserta',
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         valueExpr: "pesertaId",
                    //         searchEnabled: true,
                    //         deferRendering: false,
                    //         displayExpr: (item) => {
                    //             return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                    //         },
                    //         dataSource: getAllRIwayatPeserta(this.props.store),
                    //     }
                    // },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    // {
                    //     dataField: 'mpTypeId',
                    //     label: {
                    //         text: 'Tipe Manfaat Pensiun',
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: mpType(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'mpName'
                    //     }
                    // },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'greaterPaymentDate',
                                label: {
                                    text: 'Tanggal Pembayaran',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: 'lessPaymentDate',
                                label: {
                                    text: 'S/D',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        var filter = this.state.formFilter
        var pesertaId = this.pesertaId
        var fullName = this.namaPeserta.fullName
        
        var loadAPI = `view-manfaat-pensiun-riwayat-pesertas?sort=paymentDate%2Casc&size=9999&`
        if(fullName){
            // loadAPI = loadAPI+`pesertaId.in=${pesertaId}&`
            loadAPI = loadAPI+`pesertaId.in=${pesertaId}&`
        }else{
            await alert('Pilih Peserta Terlebih Dahulu!', 'Validasi !')
            return false
        }
        let store = this.props.store
        store.dispatch(showLoading(true))
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${pesertaId}`, 'GET')
        var getBankAll = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET')
        var getMpTypeAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types`, 'GET')
        var getProductAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var jenisPenerimaAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`   
            try {
                var productName = getProductAll.find(val => val.id == filter.productId).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.mpTypeId) {
            loadAPI = loadAPI+`mpTypeId.in=${filter.mpTypeId}&`
            try {
                var mpTypeName = getMpTypeAll.find(val => val.id == filter.mpTypeId).mpName
            } catch (error) {
                var mpTypeName = '-'
            }
        } else {
            var mpTypeName = 'Semua'
        }

        if(filter.greaterPaymentDate){
            loadAPI = loadAPI+`paymentDate.greaterThanOrEqual=${formatDate(filter.greaterPaymentDate)}&`
        }

        if(filter.lessPaymentDate){
            loadAPI = loadAPI+`paymentDate.lessThanOrEqual=${formatDate(filter.lessPaymentDate)}&`
        }

        var getMpRiwayat = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var totalMpNominal = []
        // var totalNominalDitunda = []
        var totalReturNominal = []

        for(var value of getMpRiwayat){
            var getMpType = getMpTypeAll.find(val => val.id == value.mpTypeId)
            if(getMpType){
                var mpType = getMpType.mpName
            }else{
                var mpType = '-'
            }
            value.mpTypeId = mpType

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            var getProduct = getProductAll.find(val => val.id == value.productId)
            if(getProduct){
                var product = getProduct.productCode
            }else{
                var product = '-'
            }
            value.productId = product

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            value.mpRekeningName = value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName

            totalMpNominal.push(value.mpNominal)
            // totalNominalDitunda = totalNominalDitunda + value.nominalDitunda
            totalReturNominal.push(value.returNominal)
        }
        getMpRiwayat = getMpRiwayat.map(value => {
            value.mpNominal = (value.mpNominal ? value.mpNominal : 0)
            // value.nominalDitunda = (value.nominalDitunda ? value.nominalDitunda : 0)
            value.returNominal = (value.returNominal ? value.returNominal : 0)
            value.paymentDate = value.paymentDate ? formatDefaultDate(value.paymentDate) : '-'

            return value
        })

        if(totalMpNominal.length !== 0 && totalReturNominal.length !== 0) {
            var summaryMpNomal = totalMpNominal.reduce((a, b) => a + b, 0);
            var summaaryReturNominal = totalReturNominal.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/riwayat-mp-peserta',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpTypeId: mpTypeName,
                nik: getPeserta.nik,
                fullName: getPeserta.fullName,
                totalMpNominal : (summaryMpNomal),
                // totalNominalDitunda : (totalNominalDitunda),
                totalReturNominal : (summaaryReturNominal),
                dataRiwayat : getMpRiwayat,
                tanggalPembayaran : filter.greaterPaymentDate && filter.lessPaymentDate ? (filter.greaterPaymentDate ? formatDefaultDate(filter.greaterPaymentDate) : "~") + ' S/D ' + (filter.lessPaymentDate ? formatDefaultDate(filter.lessPaymentDate) : "~") : 'Semua',
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))
        this.setState({
            src : responseFile
        })
    }

    print = async() => {
        var filter = this.state.formFilter
        var pesertaId = this.pesertaId
        var fullName = this.namaPeserta.fullName
        
        var loadAPI = `view-manfaat-pensiun-riwayat-pesertas?sort=paymentDate%2Casc&size=9999&`
        if(fullName){
            // loadAPI = loadAPI+`pesertaId.in=${pesertaId}&`
            loadAPI = loadAPI+`pesertaId.in=${pesertaId}&`
        }else{
            await alert('Pilih Peserta Terlebih Dahulu!', 'Validasi !')
            return false
        }
        let store = this.props.store
        store.dispatch(showLoading(true))
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${pesertaId}`, 'GET')
        var getBankAll = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET')
        var getMpTypeAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types`, 'GET')
        var getProductAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var jenisPenerimaAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`   
            try {
                var productName = getProductAll.find(val => val.id == filter.productId).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.mpTypeId) {
            loadAPI = loadAPI+`mpTypeId.in=${filter.mpTypeId}&`
            try {
                var mpTypeName = getMpTypeAll.find(val => val.id == filter.mpTypeId).mpName
            } catch (error) {
                var mpTypeName = '-'
            }
        } else {
            var mpTypeName = 'Semua'
        }

        if(filter.greaterPaymentDate){
            loadAPI = loadAPI+`paymentDate.greaterThanOrEqual=${formatDate(filter.greaterPaymentDate)}&`
        }

        if(filter.lessPaymentDate){
            loadAPI = loadAPI+`paymentDate.lessThanOrEqual=${formatDate(filter.lessPaymentDate)}&`
        }

        var getMpRiwayat = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var totalMpNominal = []
        // var totalNominalDitunda = []
        var totalReturNominal = []

        for(var value of getMpRiwayat){
            var getMpType = getMpTypeAll.find(val => val.id == value.mpTypeId)
            if(getMpType){
                var mpType = getMpType.mpName
            }else{
                var mpType = '-'
            }
            value.mpTypeId = mpType

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            var getProduct = getProductAll.find(val => val.id == value.productId)
            if(getProduct){
                var product = getProduct.productCode
            }else{
                var product = '-'
            }
            value.productId = product

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            value.mpRekeningName = value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName

            totalMpNominal.push(value.mpNominal)
            // totalNominalDitunda = totalNominalDitunda + value.nominalDitunda
            totalReturNominal.push(value.returNominal)
        }
        getMpRiwayat = getMpRiwayat.map(value => {
            value.mpNominal = (value.mpNominal ? value.mpNominal : 0)
            // value.nominalDitunda = (value.nominalDitunda ? value.nominalDitunda : 0)
            value.returNominal = (value.returNominal ? value.returNominal : 0)
            value.paymentDate = value.paymentDate ? formatDefaultDate(value.paymentDate) : '-'

            return value
        })

        if(totalMpNominal.length !== 0 && totalReturNominal.length !== 0) {
            var summaryMpNomal = totalMpNominal.reduce((a, b) => a + b, 0);
            var summaaryReturNominal = totalReturNominal.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/riwayat-mp-peserta',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpTypeId: mpTypeName,
                nik: getPeserta.nik,
                fullName: getPeserta.fullName,
                totalMpNominal : (summaryMpNomal),
                // totalNominalDitunda : (totalNominalDitunda),
                totalReturNominal : (summaaryReturNominal),
                dataRiwayat : getMpRiwayat,
                tanggalPembayaran : filter.greaterPaymentDate && filter.lessPaymentDate ? (filter.greaterPaymentDate ? formatDefaultDate(filter.greaterPaymentDate) : "~") + ' S/D ' + (filter.lessPaymentDate ? formatDefaultDate(filter.lessPaymentDate) : "~") : 'Semua',
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'RIWAYAT MANFAAT PENSIUN PESERTA'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'RIWAYAT MANFAAT PENSIUN PESERTA')
        store.dispatch(showLoading(false))
        return responseFile
    }

    exportExcel = async() => {
        var data = []
        var filter = this.state.formFilter
        var pesertaId = this.pesertaId
        var fullName = this.namaPeserta.fullName
        
        var loadAPI = `view-manfaat-pensiun-riwayat-pesertas?sort=paymentDate%2Casc&size=9999&`
        if(fullName){
            // loadAPI = loadAPI+`pesertaId.in=${pesertaId}&`
            loadAPI = loadAPI+`pesertaId.in=${pesertaId}&`
        }else{
            await alert('Pilih Peserta Terlebih Dahulu!', 'Validasi !')
            return false
        }
        let store = this.props.store
        store.dispatch(showLoading(true))
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${pesertaId}`, 'GET')
        var getBankAll = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET')
        var getMpTypeAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types`, 'GET')
        var getProductAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var jenisPenerimaAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`   
            try {
                var productName = getProductAll.find(val => val.id == filter.productId).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.mpTypeId) {
            loadAPI = loadAPI+`mpTypeId.in=${filter.mpTypeId}&`
            try {
                var mpTypeName = getMpTypeAll.find(val => val.id == filter.mpTypeId).mpName
            } catch (error) {
                var mpTypeName = '-'
            }
        } else {
            var mpTypeName = 'Semua'
        }

        if(filter.greaterPaymentDate){
            loadAPI = loadAPI+`paymentDate.greaterThanOrEqual=${formatDate(filter.greaterPaymentDate)}&`
        }

        if(filter.lessPaymentDate){
            loadAPI = loadAPI+`paymentDate.lessThanOrEqual=${formatDate(filter.lessPaymentDate)}&`
        }

        var getMpRiwayat = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var totalMpNominal = []
        // var totalNominalDitunda = []
        var totalReturNominal = []

        var index = 1
        for(var value of getMpRiwayat){
            var getMpType = getMpTypeAll.find(val => val.id == value.mpTypeId)
            if(getMpType){
                var mpType = getMpType.mpName
            }else{
                var mpType = '-'
            }
            value.mpTypeId = mpType

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            var getProduct = getProductAll.find(val => val.id == value.productId)
            if(getProduct){
                var product = getProduct.productCode
            }else{
                var product = '-'
            }
            value.productId = product

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            totalMpNominal.push(value.mpNominal)
            // totalNominalDitunda = totalNominalDitunda + value.nominalDitunda
            totalReturNominal.push(value.returNominal)

            var dataValue = {
                id: index++,
                // productId: value.productId,
                paymentDate: value.paymentDate ? formatDefaultDate(value.paymentDate) : '-',
                mpTypeId: value.mpTypeId,
                // penerimaName: value.penerimaName,
                // manfaatPensiunPenerimaTypeId: value.manfaatPensiunPenerimaTypeId,
                mpRekeningName: value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName,
                tipePenerima: value.tipePenerima,
                mpRekeningNo: value.mpRekeningNo,
                mpRekeningBankId: value.mpRekeningBankId,
                mpNominal: value.mpNominal ? value.mpNominal : 0,
                // nominalDitunda: value.nominalDitunda ? formatNumber(value.nominalDitunda) : 0,
                returNominal: value.returNominal ? value.returNominal : 0,
                // returDescription: value.returDescription
            }

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        if(totalMpNominal.length !== 0 && totalReturNominal.length !== 0) {
            var summaryMpNomal = totalMpNominal.reduce((a, b) => a + b, 0);
            var summaaryReturNominal = totalReturNominal.reduce((a, b) => a + b, 0);
        }

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('RIWAYAT MANFAAT PENSIUN PESERTA');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A1',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                // {name: 'Produk'},
                {name: 'Tanggal Pembayaran'},
                {name: 'Tipe Manfaat Pensiun'},
                // {name: 'Nama Penerima'},
                // {name: 'Relasi Penerima'},
                {name: 'Nama Rekening Penerima'},
                {name: 'Jenis Penerima'},
                {name: 'Nomor Rekening Penerima'},
                {name: 'Bank Penerima'},
                {name: 'MP\r\n(Rp)'},
                // {name: 'Nominal Ditunda'},
                {name: 'Retur\r\n(Rp)'},
                // {name: 'Keterangan Retur'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                }))
            }

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BRIWAYAT MANFAAT PENSIUN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BRIWAYAT MANFAAT PENSIUN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BRIWAYAT MANFAAT PENSIUN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // worksheet.getCell('A3').value = 'Kode'
            // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = ': RIWAYAT MANFAAT PENSIUN'

            // worksheet.getCell('A5').value = 'Tanggal Laporan'
            // worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A7').value = 'NIK'
            // worksheet.getCell('B7').value = `: ${getPeserta.nik}`
            
            // worksheet.getCell('A8').value = 'Nama Peserta'
            // worksheet.getCell('B8').value = `: ${getPeserta.fullName}`

            // worksheet.getCell('A9').value = 'Produk'
            // worksheet.getCell('B9').value = `: ${productName}`

            // worksheet.getCell('A10').value = 'Tipe Manfaat Pensiun'
            // worksheet.getCell('B10').value = `: ${mpTypeName}`

            // worksheet.getCell('A11').value = 'Tanggal Pembayaran'
            // worksheet.getCell('B11').value = `: ${filter.greaterPaymentDate ? formatDefaultDate(filter.greaterPaymentDate) : 'Semua'} S/D ${filter.lessPaymentDate ? formatDefaultDate(filter.lessPaymentDate) : 'Semua'}`

            worksheet.mergeCells(`A${lastTableRowNum + 1}:G${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'right'};
            worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

            worksheet.getCell(`H${lastTableRowNum + 1}`).value = (summaryMpNomal)
            worksheet.getCell(`H${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`I${lastTableRowNum + 1}`).value = (summaaryReturNominal)
            worksheet.getCell(`I${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            // worksheet.getCell('J1').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('K1').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('L1').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('M1').alignment = {vertical: 'middle', horizontal: 'center'};

            for(var i = 2; i <= data.length + 1; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = 30;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = 30;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = 30;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = 30;
            Data8.numFmt = '#,##0.00';

            const Data9 = worksheet.getColumn(9);
            Data9.width = 30;
            Data9.numFmt = '#,##0.00';
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `RIWAYAT MANFAAT PENSIUN PESERTA.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"mpTypeId", 
        //         to:"Tipe Manfaat Pensiun"
        //     },
        //     {
        //         from:"paymentDate", 
        //         to:"Tanggal Transfer"
        //     },
        //     {
        //         from:"penerimaName", 
        //         to:"Nama Penerima"
        //     },
        //     {
        //         from:"manfaatPensiunPenerimaTypeId", 
        //         to:"Relasi Penerima"
        //     },
        //     {
        //         from:"mpRekeningNo", 
        //         to:"Nomor Rekening Penerima"
        //     },
        //     {
        //         from:"mpRekeningName", 
        //         to:"Nama Rekening Penerima"
        //     },
        //     {
        //         from:"mpRekeningBankId", 
        //         to:"Bank Penerima"
        //     },
        //     {
        //         from:"mpNominal", 
        //         to:"Nominal MP"
        //     },
        //     {
        //         from:"nominalDitunda", 
        //         to:"Nominal Ditunda"
        //     },
        //     {
        //         from:"returNominal", 
        //         to:"Nominal Retur"
        //     },
        //     {
        //         from:"returDescription", 
        //         to:"Keterangan Retur"
        //     }
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'RIWAYAT MANFAAT PENSIUN'
        //     },
        //     {
        //         A: 'Tanggal Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'NIK',
        //         B: null,
        //         C: ':',
        //         D: getPeserta.nik
        //     },
        //     {
        //         A: 'Nama Peserta',
        //         B: null,
        //         C: ':',
        //         D: getPeserta.fullName
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     }
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getMpRiwayat){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_RIWAYAT_MANFAAT_PENSIUN')
    }

    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI= {'view-pesertas?statusPeserta.notEquals=1%2C2%2C3'}
                insertAPI='pesertas'
                updateAPI='pesertas'
                deleteAPI='pesertas'

                backendserver={process.env.REACT_APP_BACKEND_CORE}
                
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value
        this.pesertaId = fieldValue[0] || fieldValue
        // this.setState({
        //     [fieldName]: fieldValue
        // })
    }

    onSelectionChanged = (e) => {
        this.pesertaId = e.selectedRowKeys[0]
        this.namaPeserta = e.selectedRowsData[0]
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Riwayat Manfaat Pensiun Peserta</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div style={{marginBottom: "5px"}}>
                            <DropDownBox
                                ref={this.dropDownBoxRef}
                                valueExpr="id"
                                deferRendering={false}
                                placeholder = {'Pilih Peserta'}
                                displayExpr={(item) => {
                                    return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                }}
                                showClearButton={true}
                                dataSource={pesertaById(this.props.store)}
                                onValueChanged={this.onValueChanged}
                                contentRender={this.renderDatagrid}
                            />
                        </div>
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.formRef}
                            formData={this.state.formFilter}
                            items={this.formItem}
                            labelLocation={"left"}
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportMpDetailRiwayatMpPeserta