import React, { Component } from 'react'
import { currency, banks, orders, product, productDefault, pejabat} from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import FormInstruksiMiAktif from 'components/pages/modal/investasi/instruksi/mi/index'
import { confirmAlert } from 'react-confirm-alert'
import { formatDefaultFullDate, formatNumber, numberToIndo, PrintElem } from 'plugin/helper'
import reportRequest from 'plugin/reportRequest'

class InstruksiMiAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.dataGridInstruksiMiAktifRef = React.createRef()
        this.formPenempatanRef = React.createRef()
        this.rekeningMi = []
        this.getBank = []
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalPenempatan(e.row.data)
                        }
                    },
                    {
                        text: 'Cetak Instruksi Pembayaran',
                        hint: 'Cetak Instruksi Pembayaran',
                        onClick: (e) => {
                            this.cetakInstruksiPembayaran(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin mengirim instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.sendInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin membatalkan instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.cancelInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                        }
                    },
                ]
            },
            {
                dataField: 'instruksiId',
                caption: 'ID',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'miName',
                caption: 'Manager Investasi',
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiNo',
                        caption: 'Nomor'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'totalSubscription',
                caption: 'Penempatan',
                alignment: 'right',
            },
            {
                dataField: 'totalRedemption',
                caption: 'Pelepasan',
                alignment: 'right',
            },
            {
                dataField: 'totalSwitching',
                caption: 'Pengalihan',
                alignment: 'right',
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var getRekMi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-mis', 'GET')
        var getBankData = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET')
        this.rekeningMi = getRekMi
        this.getBank = getBankData
    }
    cetakInstruksiPembayaran = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-rd/${data.instruksiId}`, 'GET')
        if (detail.orderInstruksiDTO.orderTypeId == 222) {
            confirmAlert({
                title: 'Gagal',
                message: 'Tidak tersedia untuk tipe transaksi penjualan',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
            // notify({ message: 'Tidak tersedia untuk tipe transaksi penjualan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        } else {
            var bankId = this.rekeningMi.find(val => val.id == detail.orderInstruksiDTO.sourceRekMiId).bankId
            var amountOnString = numberToIndo(data.totalAmountOrder) + ' Rupiah'
            var no = 0
            var result = []
            for (var dataRd of detail.orderRdInfos) {
                var reksadana = {
                    no: ++no,
                    totalAmountOrder: formatNumber(dataRd.orderRdDTO.amountOrder),
                    uraian: "Penempatan " + dataRd.instrumentDTO.instrumentName
                }
                result.push(reksadana)
            }
            var data = {
                template: {
                    'shortid': 'BJlx7rXLKd',
                    'recipe': 'html'
                },
                data: {
                    "instruksiNo": data.instruksiNo,
                    "placementDate": formatDefaultFullDate(data.instruksiDate),
                    "bankName": data.miName,
                    "amountOnString": amountOnString,
                    "totalAmountOrder": formatNumber(data.totalAmountOrder),
                    "rekNo": this.rekeningMi.find(val => val.id == detail.orderInstruksiDTO.sourceRekMiId).rekNo,
                    "rekName": this.rekeningMi.find(val => val.id == detail.orderInstruksiDTO.sourceRekMiId).rekName,
                    "cabang": this.rekeningMi.find(val => val.id == detail.orderInstruksiDTO.sourceRekMiId).cabang,
                    "bankNameRek": this.getBank.find(val => val.id == bankId).bankName,
                    "reksadana": result
                },
                options: {
                    reportName: "PAYMENT-INSTRUCTION-REKSADANA"
                }
            }

            var print = await reportRequest(this.props.store, data)
            PrintElem(print.data)
            return print
        }
    }
    showModalPenempatan = async (data) => {
        var instruksiId = data.instruksiId
        var loadAPI = `order-instruksi-rd/${instruksiId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderInstruksiDTO
        var dataDataGrid = response.orderRdInfos
        var productId = this.rekeningMi.find(val => val.id == dataMaster.sourceRekMiId).productId
        var result = []
        for (var dataDetail of dataDataGrid) {
            var rdData = dataDetail.orderRdDTO
            var dataOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `orders/${rdData.orderId}`, 'GET')
            var dataHp = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `manager-investasis`, 'GET')
            var detail = {
                id: rdData.id,
                orderId: rdData.orderId,
                orderCode: dataOrder.orderCode,
                instrumentId: rdData.instrumentId,
                orderTypeId: rdData.orderTypeId,
                currencyId: rdData.currencyId,
                amountOrder: rdData.amountOrder,
                unitPersen: rdData.unitPersen,
                noTelp: dataHp.noTelp
            }
            result.push(detail)
        }
        var customGetData = {
            ...dataMaster,
            ...data,
            dataRd: result,
            product: productId
        }
        this.formPenempatanRef.current.show(customGetData)
    }
    sendInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        var responseDetailInstruksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-rd/${instruksiId}`, 'GET')
        var fileInstruksi = responseDetailInstruksi.orderInstruksiDTO.fileInstruksi
        if (data.pejabat1 || data.pejabat2 != null) {
            if (fileInstruksi != null) {
                if (instruksiId) {
                    try {
                        var insertAPI = 'order-rds/send-instruksi-rd'
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                            values: [
                                instruksiId
                            ]
                        })
                        notify({ message: 'Pengiriman Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.forceRefresh()
                        return response
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } else {
                notify({ message: 'Mohon lengkapi file instruksi terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        } else {
            notify({ message: 'Mohon lengkapi data terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }
    cancelInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        if (instruksiId) {
            try {
                var insertAPI = 'order-rds/batal-instruksi-rd'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: [
                        instruksiId
                    ]
                })
                notify({ message: 'Pembatalan Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    forceRefresh = () => {
        this.dataGridInstruksiMiAktifRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Manager Investasi</h2>
                <DevExpressDataGrid
                    keyField='instruksiId'
                    ref={this.dataGridInstruksiMiAktifRef}
                    loadAPI='order-instruksi-rd/list?status=OPEN'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instruksi MI"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi MI'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormInstruksiMiAktif
                    ref={this.formPenempatanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default InstruksiMiAktif