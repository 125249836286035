import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import { provinces, cities, currency, managerInvestasis, product, instruments, productDefault, rdNeedConfirmByMi } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'


class ModalSettlementPengalihanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataPembelian: {},
            dataMaster: {},
            actionType: null
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.switchingRef = React.createRef()
        this.masterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validateSubs()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]   

        this.dataMaster = [
            {
                dataField: 'referensiNo',
                label: {
                    text: 'No Konfirmasi',
                },
                editorOptions: {
                    onValueChanged: (e) => {
                        this.setState({
                            dataMaster: {
                                referensiNo: e.value
                            }
                        })
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confrimation number"
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose product"
                    }
                ]
            },
            {
                dataField: 'orderDate',
                label: {
                    text: 'Tanggal Order',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose order date"
                    }
                ]
            },
            {
                dataField: 'miId',
                label: {
                    text: 'Manager Investasi',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName',
                    searchEnabled: true,
                    onValueChanged: (e) => {
                        var productId = this.state.dataMaster.productId
                        var orderDate = formatDate(this.state.dataMaster.orderDate)
                        var miId = this.state.dataMaster.miId
                        var selectOrderId = this.subscriptionRef.current.instance.getEditor('orderId')
                        selectOrderId.option('dataSource', rdNeedConfirmByMi(this.props.store, miId, orderDate, productId))
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Manager investasi"
                    }
                ]
            },
        ]

        this.TabDataSource = [
            {
                'id': 0,
                text: 'Pengalihan'
            },
        ]

        this.itemSwitching = [
            {
                dataField: 'noOrder',
                label: {
                    alignment: 'left',
                    text: 'No Order'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'pengelolaan',
                label: {
                    alignment: 'left',
                    text: 'Pengelolaan'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    layout: 'horizontal',
                    items: ['Internal', 'KPD']
                }
            },
            {
                dataField: 'kpd',
                label: {
                    alignment: 'left',
                    text: 'KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: []
                }
            },
            {
                dataField: 'produkDapenId',
                label: {
                    alignment: 'left',
                    text: 'Produk Dapen'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'penerbitReksadanaId',
                label: {
                    alignment: 'left',
                    text: 'Penerbit Reksadana'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'produkReksadana',
                label: {
                    alignment: 'left',
                    text: 'Produk Reksadana'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instruments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName',
                }
            },
            {
                dataField: 'tanggalTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'tanggalPembayaran',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'tanggalSettle',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Settle'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'd MMM yyyy'
                }
            },
            {
                dataField: 'nominal',
                label: {
                    alignment: 'left',
                    text: 'Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'unit',
                label: {
                    alignment: 'left',
                    text: 'Unit'
                },
            },
            {
                dataField: 'Fee',
                label: {
                    alignment: 'left',
                    text: 'Fee'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'totalNominal',
                label: {
                    alignment: 'left',
                    text: 'total Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'currencyName', // provides display values
                },
            },
            {
                dataField: 'rateCurrency',
                label: {
                    alignment: 'left',
                    text: 'Rate Currency'
                },
            },
            {
                dataField: 'fundManager',
                label: {
                    alignment: 'left',
                    text: 'Fund Manager'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: []
                }
            },
            {
                dataField: 'produkTujuan',
                label: {
                    alignment: 'left',
                    text: 'Produk Tujuan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instruments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }
    validateSubs = () => {
        var formValidation = this.subscriptionRef.current.instance.validate();
        formValidation = this.masterRef.current.instance.validate();
        return formValidation.isValid
    }
    submitData = async() => {
        var refNo = this.state.dataMaster.referensiNo
        var dataSubmit = {
            referensiNo: refNo,
            ...this.state.dataPembelian,
            kpdId: 1,
            paymentRekBankId: 1,
        }
         
        if(dataSubmit){
            try {
                var insertAPI = 'order-rds/konfirmasi-pembelian-reksadana'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: dataSubmit
                })
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async(type,data) => {    
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Reksadana'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formDataMaster'}
                        ref={this.masterRef}
                        formData={this.state.dataMaster}
                        items={this.dataMaster}
                        labelLocation={"left"}
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id="tabindex-0" className={'mt-2 col-md-12 text-center' } >
                            <div className="row">
                                <div className="col-md-12" style={{overflowY: 'auto', height: 'calc(60vh - 200px)'}}>
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        ref={this.switchingRef}
                                        formData={this.state.dataPengalihan}
                                        items={this.itemSwitching}
                                        scrollingEnabled={true}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalSettlementPengalihanReksadana