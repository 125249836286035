import React, { Component } from 'react'
import Popup from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { banks, branch, jabatan, sdmUnit } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

class ViewPenerimaThr extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }

        this.DataMaster = [
            {
                itemType:"group",
                colCount:1,
                items:[
                    {
                        dataField:"nik",
                        label:{
                            text:"NIK",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"fullName",
                        label:{
                            text:"Nama",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"jabatanId",
                        label:{
                            text:"Jabatan",
                            alignment:"left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'jabatanName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true
                        }
                    },
                    {
                        dataField:"unitId",
                        label:{
                            text:"Unit",
                            alignment:"left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true
                        }
                    },
                    {
                        dataField:"branchId",
                        label:{
                            text:"Cabang",
                            alignment:"left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: branch(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'branchName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true
                        }
                    },
                    {
                        dataField:"gajiRekNo",
                        label:{
                            text:"No. Rekening",
                            alignment:"left"
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:"gajiRekName",
                        label:{
                            text:"Nama Rekening",
                            alignment:"left"
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:"gajiRekBankId",
                        label:{
                            text:"Bank",
                            alignment:"left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true
                        }
                    },
                ]
            },
            {
                itemType:"group",
                colCount:1,
                caption:"THR",
                items:[
                    {
                        dataField:"nominalThr",
                        label:{
                            text:"THR",
                            alignment:"left"
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:"pph",
                        label:{
                            text:"Pajak",
                            alignment:"left"
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:"nominalBersih",
                        label:{
                            text:"THR Bersih",
                            alignment:"left"
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];
    }

    submitData = () => {

    }

    retrieveData = async (data) => {
        var DataMaster = {}
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawans', 'GET')
        getKaryawan = getKaryawan.filter(value => value.fullName == data.fullName)
        DataMaster.nik = data.nik
        DataMaster.fullName = data.fullName
        DataMaster.jabatanId = data.jabatanId
        DataMaster.nominalThr = data.nominalThr
        DataMaster.unitId = data.unitId
        DataMaster.pph = data.pph
        DataMaster.branchId = getKaryawan[0].branchId
        DataMaster.gajiRekNo = getKaryawan[0].gajiRekNo
        DataMaster.gajiRekName = getKaryawan[0].gajiRekName
        DataMaster.gajiRekBankId = getKaryawan[0].gajiRekBankId
        this.setState({
            DataMaster:DataMaster
        })
    }

    show = () => {
        this.setState({
            popupVisible:true
        })
    }

    hide = () => {
        this.setState({
            popupVisible:false
        })
    }

    render() {
        return (
           <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Penerima THR'} 
                width={550}
                heigth={650}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <Form
                    colCount={1}
                    id={'formFilter'}
                    formData={this.state.DataMaster}
                    items={this.DataMaster}
                    labelLocation={"left"}
                />
            </Popup>
        )
    }
}

export default ViewPenerimaThr