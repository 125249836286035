import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import React, { Component } from 'react'
import { Form, ScrollView, Popup, FileUploader } from 'devextreme-react';
import { karyawan, programs, tipeTransaksiSdmUmum , tipeCuti, periodeCuti,tunjanganHrd,provinces,citiesFilter,cities} from 'dataSource/lookup';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import { formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import ArrayStore from 'devextreme/data/array_store';
import { showLoading } from 'redux/actions/loading';

class FormModalPembatalanCuti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            DataDocument: {},
            InfoCuti: {},
            uploadedData: [],
            maxDayPeriod: 0,
            type: 'add'
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.formInfoCutiRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.dataTgl = []
        this.readOnly = false
        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.form = [
            {
                itemType: 'group',
                caption:'PEMBATALAN CUTI',
                items: [

                    {
                        dataField: 'programId',
                        label: {
                            alignment: 'left',
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            }
                        },
                    },
                    {
                        dataField: "karyawanId",
                        label: {
                            text: "Karyawan"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: function (item) {
                                return item && "NIK : " + item.nik + " - " + item.fullName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                var val = e.value
                                if (val) {
                                    var response = await httpRequest(
                                        process.env.REACT_APP_BACKEND_HRD,
                                        this.props.store,
                                        `karyawan-cutis/get-by-karyawanId/${val}`,
                                        'GET'
                                    )
                                    // response = response.filter(value => value.statusId == 3 || value.statusId == 5)
                                    response = response.filter(value => value.statusId == 1 || value.statusId == 2 || value.statusId == 3 || value.statusId == 4)
                                    this.formRef.current.instance.getEditor('karyawanCutiId').option('dataSource', response)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'karyawanCutiId',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.pengajuanNo
                            },
                            onValueChanged: async (e) => {
                                if (this.props.type == 'add') {
                                    if(e.value) {
                                        try {
                                            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cuti-detils/findAllByKaryawanCutiId/${e.value}`, 'GET');
                                            var result = []
                                            for (let value of response) {
                                                if (value.tanggal_1 && value.tanggal_2) {
                                                    var tgl = {
                                                        id: value.id,
                                                        tglAwal: value.tanggal_1,
                                                        tglAkhir: value.tanggal_2,
                                                        jumlahHari: value.hari,
                                                        statusId: 1,
                                                        karyawanCutiDetilId: value.id
                                                    }
                                                    result.push(tgl);
                                                } else {
                                                    result.push();
                                                }
                                            }
                                            this.dataTgl = result
                                            this.dataGridRef.current.forceRefresh(true)
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    }
                                }
                            },
                            onSelectionChanged:(e) => {
                                if (e.selectedItem) {
                                    e.selectedItem.tanggalPengajuan = e.selectedItem.pengajuanTanggal
                                    e.selectedItem.tglPembayaran = e.selectedItem.paymentDate
                                    e.selectedItem.catatan = e.selectedItem.keterangan
                                    e.selectedItem.tglInterval = e.selectedItem.tanggalInterval
                                    e.selectedItem.tglAwal = e.selectedItem.tanggalAwal
                                    e.selectedItem.tglAkhir = e.selectedItem.tanggalAkhir
                                    e.selectedItem.lokasiCuti = e.selectedItem.lokasiDalamNegeri
                                    e.selectedItem.jumlahHariCuti = e.selectedItem.hari
                                    this.setState({
                                        InfoCuti:e.selectedItem
                                    })
                                }
                            }
                        }
                    },
                    {
                        dataField: 'tglPembatalan',
                        label: {
                            text: 'Tanggal Pembatalan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy',
                            min: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                        }
                    },
                    {
                        dataField: 'noPembatalan',
                        label: {
                            text: 'No. Pembatalan'
                        },
                    },
                    {
                        dataField: 'biayaPemanggilan',
                        label: {
                            text: 'Biaya Pemanggilan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: true,
                                    value: 'Yes'
                                },
                                {
                                    id: false,
                                    value: 'No'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            onValueChanged: (e) => {
                                let val = e.value
                                if (val == false) {
                                    this.formRef.current.instance.getEditor('nominalBiaya').option('readOnly', true)
                                    this.formRef.current.instance.getEditor('tglPembayaran').option('readOnly', true)
                                    this.formRef.current.instance.getEditor('transactionTypeId').option('readOnly', true)
                                } else {
                                    this.formRef.current.instance.getEditor('nominalBiaya').option('readOnly', false)
                                    this.formRef.current.instance.getEditor('tglPembayaran').option('readOnly', false)
                                    this.formRef.current.instance.getEditor('transactionTypeId').option('readOnly', false)
                                }
                            },
                        }
                    },
                    {
                        dataField: 'nominalBiaya',
                        label: {
                            text: 'Nominal Biaya'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'tglPembayaran',
                        label: {
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy',
                            min: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                        }
                    },
                    {
                        dataField: 'transactionTypeId',
                        label: {
                            text: 'Tipe Transaksi Biaya'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'transactionTypeName',
                            searchEnabled: true
                        }
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            text: 'Keterangan'
                        },
                        editorType: 'dxTextArea'
                    },
                ]
            },
        ]

        this.formEdit = [
            {
                itemType: 'group',
                caption:'PEMBATALAN CUTI',
                items: [

                    {
                        dataField: 'programId',
                        label: {
                            alignment: 'left',
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            }
                        },
                    },
                    {
                        dataField: "karyawanId",
                        label: {
                            text: "Karyawan"
                        },
                        // editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: [],
                            // valueExpr: 'id', // contains the same values as the "statusId" field provides
                            // displayExpr: function (item) {
                            //     return item && "NIK : " + item.nik + " - " + item.fullName;
                            // },
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'karyawanCutiId',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: function (item) {
                                return item && "NIK : " + item.nik + " - " + item.fullName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    this.activeDateKaryawan = e.selectedItem.workingDate
                                    if (this.props.type == 'add') {
                                        this.formMasterRef.current.instance.updateData({ jabatanId: e.selectedItem.jabatanId, unitId: e.selectedItem.unitId, periodeCutiId: null, cutiTypeId: null, sisaCuti: 0 })
                                    } else {
                                        this.formMasterRef.current.instance.updateData({ jabatanId: e.selectedItem.jabatanId, unitId: e.selectedItem.unitId })
                                    }
                                }
                            },
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tglPembatalan',
                        label: {
                            text: 'Tanggal Pembatalan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy',
                            min: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                        }
                    },
                    {
                        dataField: 'noPembatalan',
                        label: {
                            text: 'No. Pembatalan'
                        },
                    },
                    {
                        dataField: 'biayaPemanggilan',
                        label: {
                            text: 'Biaya Pemanggilan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: true,
                                    value: 'Yes'
                                },
                                {
                                    id: false,
                                    value: 'No'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        }
                    },
                    {
                        dataField: 'nominalBiaya',
                        label: {
                            text: 'Nominal Biaya'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'tglPembayaran',
                        label: {
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy',
                            min: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                        }
                    },
                    {
                        dataField: 'transactionTypeId',
                        label: {
                            text: 'Tipe Transaksi Biaya'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'transactionTypeName',
                            searchEnabled: true
                        }
                    },
                    {
                        dataField: 'catatan',
                        label: {
                            text: 'Keterangan'
                        },
                        editorType: 'dxTextArea'
                    },
                ]
            },
        ]

        this.infoCuti = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'INFO KARYAWAN CUTI',
                        items: [
                            {
                                dataField: 'tanggalPengajuan',
                                label: {
                                    text: 'Tanggal Pengajuan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: 'periodeCutiId',
                                label: {
                                    text: 'Periode Cuti'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: periodeCuti(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'namaPeriodeCuti', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField: 'cutiTypeId',
                                label: {
                                    text: 'Tipe Cuti'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: tipeCuti(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'cutiName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'TUNJANGAN CUTI',
                        items: [
                            {
                                dataField: 'tunjanganCuti',
                                label: {
                                    text: 'Tunjangan Cuti'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: true,
                                            value: 'Yes'
                                        },
                                        {
                                            id: false,
                                            value: 'No'
                                        },
                                    ],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                }
                            },
                            {
                                dataField: 'tunjanganTypeId',
                                label: {
                                    text: 'Tipe Tunjangan'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: tunjanganHrd(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'tunjanganName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField: 'tglPembayaran',
                                label: {
                                    text: 'Tanggal Pembayaran'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy',
                                }
                            },
                            {
                                dataField: 'catatan',
                                label: {
                                    text: 'Catatan'
                                },
                                editorType: 'dxTextArea'
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'TANGGAL CUTI',
                        items: [
                            {
                                dataField: 'tglInterval',
                                label: {
                                    text: 'Tipe Tanggal'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: true,
                                            value: 'Tanggal Interval'
                                        },
                                        {
                                            id: false,
                                            value: 'Tanggal Spesifik'
                                        },
                                    ],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                                visible: false
                            },
                            {
                                dataField: 'tglAwal',
                                label: {
                                    text: 'Tanggal Dari'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy',
                                },
                                visible: false
                            },
                            {
                                dataField: 'tglAkhir',
                                label: {
                                    text: 'Tanggal Sampai'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy',
                                },
                                visible: false
                            },
                            {
                                dataField: 'jumlahHariCuti',
                                label: {
                                    text: 'Jumlah Hari'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var jumlahHariCuti = e.value
                                        this.formInfoCutiRef.current.instance.updateData('sisaCuti', this.state.maxDayPeriod - jumlahHariCuti)
                                    }
                                },
                                visible: false
                            },
                            {
                                dataField: 'lokasiCuti',
                                label: {
                                    text: 'Lokasi Cuti'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: true,
                                            value: 'Dalam Negeri'
                                        },
                                        {
                                            id: false,
                                            value: 'Luar Negeri'
                                        },
                                    ],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                }
                            },
                            {
                                dataField: 'provinceId',
                                label: {
                                    alignment: 'left',
                                    text: 'Provinsi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: provinces(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'provinceName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField: 'cityId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kota'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: cities(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'cityName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField: 'alamat',
                                label: {
                                    alignment: 'left',
                                    text: 'Alamat'
                                },
                            },
                            {
                                dataField: 'noTelp',
                                label: {
                                    alignment: 'left',
                                    text: 'No.Telp'
                                },
                                editorType:"dxTextBox",
                                editorOptions:{
                                    maxLength:12
                                },
                                validationRules: [
                                    {
                                        type: 'pattern',
                                        pattern: /^\(?([0-9]{0,12})\)?$/,
                                        message: 'No Telepon tidak valid karena mengandung huruf'
                                    },
                                ]
                            },
                        ]
                    }
                ]
            },
        ]

        // this.columns = [
        //     {
        //         type: 'buttons',
        //         buttons: [
        //             'edit',
        //             {
        //                 text: 'Batal',
        //                 hint: 'Batal',
        //                 onClick: (e) => {
        //                     this.batalCuti(e.row.data)
        //                 }
        //             },
        //         ],
        //     },
        //     {
        //         dataField: 'tglSpesifik',
        //         caption: 'Tanggal Cuti',
        //         dataType: "date",
        //         format: 'dd MMM yyyy'
        //     },
        //     {
        //         dataField: 'statusId',
        //         caption: 'Status',
        //         lookup: {
        //             dataSource: [
        //                 {
        //                     id: 1,
        //                     value: 'Done'
        //                 },
        //                 {
        //                     id: 3,
        //                     value: 'Cancel'
        //                 }
        //             ],
        //             valueExpr: 'id',
        //             displayExpr: 'value'
        //         }
        //     },
        // ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            this.batalCuti(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'tglAwal',
                caption: 'Tanggal Dari',
                dataType: 'date',
                format: "dd MMM yyyy",
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'tglAkhir',
                caption: 'Tanggal Sampai',
                dataType: 'date',
                format: "dd MMM yyyy",
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'jumlahHari',
                label: {
                    text: 'Jumlah Hari'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    readOnly: true,
                    onValueChanged: (e) => {
                        var jumlahHariCuti = e.value
                        // this.jumlahHariCuti = e.value
                        // if (!this.disabledEvent) {
                        //     this.formMasterRef.current.instance.updateData('sisaCuti', this.getSisaCuti[0].jumlah_sisa - jumlahHariCuti)
                        // }
                    }
                }
            },
            {
            dataField: 'statusId',
            caption: 'Status',
            lookup: {
                dataSource: [
                    {
                        id: 1,
                        value: 'Done'
                    },
                    {
                        id: 3,
                        value: 'Cancel'
                    },
                    {
                        id: 4,
                        value: 'Aktif'
                    },
                    {
                        id: 5,
                        value: 'Batal'
                    },
                    {
                        id: 6,
                        value: 'Batal'
                    }
                ],
                valueExpr: 'id',
                displayExpr: 'value'
            }
        },
        ]


        this.fileUploderRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
    }

    cleanDataGrid = () => {
        var data = this.dataGridRef.current.getDataSource()
        var deleteData = []
        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            this.dataGridRef.current.push(deleteData)
        }
    }

    generateNoPembatalan = async () => {
        var generateNo = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `generate-no-automatic/KaryawanCuti/pengajuanNo/BATALCUTI`,
            'POST', { values: {} }
        )

        this.formRef.current.instance.updateData('noPembatalan', generateNo)
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocument };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}File`] = formattedFileData.base64data;
                DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;
                this.setState({
                    DataDocument: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    karyawanByProgram = async (programId) => {
        let arrKar = [];
        // let loadAPI = `view-karyawan-cutis?statusId.in=1&statusId.in=2&statusId.in=3`;
        let loadAPI = `view-karyawan-cutis?statusId.in=4`;
        let getViewKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        for (let valueKar of getViewKaryawan) {
            let loadAPIKaryawan = `karyawans/${valueKar.karyawanId}`;
            let getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIKaryawan, 'GET')

            arrKar.push(getKaryawan);
        }

        const uniqueData = arrKar.filter((obj, index, arr) => {
            return obj.programId === programId && index === arr.findIndex((t) => t.id === obj.id)
        });
        let dataSource = new ArrayStore({
            data: uniqueData,
            key: 'id'
        })
        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }

    retrieveData = async (dataMaster, dataInfoMaster, resultTgl, dataDokumen, type) => {
        this.readOnly = type
        this.karyawanCutiId = dataMaster.id;
        this.periodeCutiId = dataMaster.periodeCutiId;
        this.disabledEvent = true
        this.dataTgl = resultTgl
        // data.noPembatalan = data.pembatalanNo
        // data.tglPembatalan = data.pembatalanTanggal
        // data.nominalBiaya = data.pembatalanBiaya
        // data.id = data.karyawanCutiId

        let getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${dataMaster.karyawanId}`, 'GET');

        var cutiType = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `cuti-types/${dataMaster.cutiTypeId}`,
            'GET'
        )

        let biayaPemanggilan = dataInfoMaster.biayaPemanggilan 
        if(type === 'edit') {
            if(biayaPemanggilan == false) {
                var dataMasterSpesifik = {
                    "id": dataMaster.id,
                    "programId": dataMaster.programId,
                    "karyawanId": "NIK: " + getKaryawan.nik + " - " + getKaryawan.fullName,
                    // "karyawanId": dataMaster.karyawanId,
                    "karyawanCutiId": dataMaster.noPengajuan,
                    "tglPembatalan": dataInfoMaster.pembatalanTanggal,
                    "noPembatalan": dataInfoMaster.pembatalanNo,
                    "biayaPemanggilan": dataInfoMaster.biayaPemanggilan,
                    // "nominalBiaya": dataInfoMaster.pembatalanBiaya,
                    // "tglPembayaran": dataMaster.tglPembayaran,
                    // "transactionTypeId": dataInfoMaster.transactionTypeId,
                    "catatan": dataMaster.catatan
                }
            } else {
                var dataMasterSpesifik = {
                    "id": dataMaster.id,
                    "programId": dataMaster.programId,
                    "karyawanId": "NIK: " + getKaryawan.nik + " - " + getKaryawan.fullName,
                    // "karyawanId": dataMaster.karyawanId,
                    "karyawanCutiId": dataMaster.noPengajuan,
                    "tglPembatalan": dataInfoMaster.pembatalanTanggal,
                    "noPembatalan": dataInfoMaster.pembatalanNo,
                    "biayaPemanggilan": dataInfoMaster.biayaPemanggilan,
                    "nominalBiaya": dataInfoMaster.pembatalanBiaya,
                    "tglPembayaran": dataMaster.tglPembayaran,
                    "transactionTypeId": dataInfoMaster.transactionTypeId,
                    "catatan": dataMaster.catatan
                }
            }
        }

        let dataInfoCuti = {
            "tanggalPengajuan": dataMaster.tanggalPengajuan,
            "periodeCutiId": dataMaster.periodeCutiId,
            "cutiTypeId": dataMaster.cutiTypeId,
            "tunjanganCuti": dataMaster.tunjanganCuti,
            "tunjanganTypeId": dataMaster.tunjanganTypeId,
            "tglPembayaran": dataMaster.tglPembayaran,
            "catatan": dataInfoMaster.keterangan,
            "tglInterval": dataMaster.tglInterval,
            "tglAwal": dataMaster.tglAwal,
            "tglAkhir": dataMaster.tglAkhir,
            "jumlahHariCuti": dataMaster.jumlahHariCuti,
            "lokasiCuti": dataMaster.lokasiCuti,
            "provinceId": dataMaster.provinceId,
            "cityId": dataMaster.cityId,
            "alamat": dataMaster.alamat,
            "noTelp": dataMaster.noTelp
        }

        if (dataMaster.tglInterval === true) {
            this.setState({
                maxDayPeriod: cutiType.maxHariPeriod,
                DataMaster: dataMasterSpesifik,
                InfoCuti: dataInfoCuti,
                type: type
            })
        } else {
            this.setState({
                DataMaster: dataMasterSpesifik,
                InfoCuti: dataInfoCuti,
                DataDocument: dataDokumen,
                type: type
            })
        }
    }

    previewFile = async (state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    submitData = async() => {
        var dataMaster = this.state.DataMaster
        var dokumen = this.state.DataDocument
        var dataTgl = this.dataGridRef.current.getDataSource()
        var tglSpesifik = []
        for(let value of dataTgl) {
            let dataValue = {
                ...value,
                cancel: value.statusId === 3 ? true : false
            }

            tglSpesifik.push(dataValue)
        }

        var submitData = {
            ...dataMaster,
            ...dokumen,
            listTglSpesifik: tglSpesifik,
        }
        this.props.store.dispatch(showLoading(true))
        if(submitData){
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_HRD,
                    this.props.store,
                    `simpan-pembatalan-karyawan-cuti`,
                    'POST',
                    {values: submitData}
                )
                if(response){
                    this.props.store.dispatch(showLoading(false))
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: `Sukses mengajukan Pembatalan Cuti!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    batalCuti = (data) => {
        if(data.statusId == 1){
            this.dataGridRef.current.push([{
                type: "update",
                data: {
                    tglSpesifik: data.tglSpesifik,
                    statusId: 3
                },
                key: data.id
            }])
        }else if(data.statusId == 3){
            this.dataGridRef.current.push([{
                type: "update",
                data: {
                    tglSpesifik: data.tglSpesifik,
                    statusId: 1
                },
                key: data.id
            }])
        }
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
            DataDocument: {},
            InfoCuti: {}
        })
        this.cleanDataGrid()
    }

    loadDummyData = () => {
        return this.dataTgl
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Pembatalan Cuti"}
                width={"70vw"}
                height={"90vh"}
                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                        <div className='row'>
                            <div className='col-lg'>
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    formData={this.state.DataMaster}
                                    items={this.props.type === 'add' ? this.form : this.formEdit}
                                    labelLocation='left'
                                />
                            </div>
                            <div className='col-lg'>
                                <Form
                                    ref={this.formInfoCutiRef}
                                    colCount={1}
                                    formData={this.state.InfoCuti}
                                    items={this.infoCuti}
                                    labelLocation='left'
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload File"
                            showFileList={false}
                            name={"dokumen"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-8">{this.state.DataDocument.dokumenName}</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument, 'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                        <div className="mt-3">
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                loadAPI='Kinerja'
                                insertAPI='Kinerja'
                                updateAPI='Kinerja'
                                deleteAPI='Kinerja'

                                backendserver={process.env.REACT_APP_BACKEND_HRD}
                                useArraySource={true}
                                ArraySourceData={this.loadDummyData}
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Evaluasi Kinerja"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}
                                height={400}
                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Evaluasi Kinerja'}
                                popupWidth={450} //masukan dalam ukuran pixel
                                popupHeight={330} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default FormModalPembatalanCuti