import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import { addURL } from 'redux/actions/url';
import FormModalEditInstrumen from 'components/pages/modal/investasi/pengaturan/akun-instrumen/editInstrumen';

class AkunInstrumen extends Component {
  constructor(props) {
    super(props)

    this.showModalEditIntrumenRef = React.createRef()
    this.dataGridRef = React.createRef()

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Edit',
            hint: 'Edit',
            cssClass: 'text-success',
            onClick: (e) => {
              this.showModalEditIntrumen(e)
            }
          },
        ],
      },
      {
        dataField: 'subName',
        caption: 'Instrumen',
      },
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  } 

  showModalEditIntrumen = (e) => {
    if (e.row) {
        this.showModalEditIntrumenRef.current.retrieveData(e.row.data)
    }
    this.showModalEditIntrumenRef.current.show()
  }
  
  forceRefresh = () => {
    this.dataGridRef.current.forceRefresh(true)
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Akun Instrumen</h2>

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='instrument-category-subs'
          insertAPI='instrument-category-subs'
          updateAPI='instrument-category-subs'
          deleteAPI='instrument-category-subs'

          backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Akun Instrumen"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Akun Instrumen'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        <FormModalEditInstrumen 
            ref={this.showModalEditIntrumenRef}
            store={this.props.store}
            forceRefresh={this.forceRefresh}
        />

      </div>
    )
  }
}

export default AkunInstrumen