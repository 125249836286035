import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';

class FormModalTipeCuti extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }

        this.form = [
            {
                dataField:"cutiCode",
                label:{
                    text:"Kode"
                }
            },
            {
                dataField:"cutiName",
                label:{
                    text:"Tipe Cuti"
                },
            },
            {
                dataField:"cutiDitetapkan",
                label:{
                    text:"Cuti Di Tetapkan",
                },
                editorType:"dxSelectBox",
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"maxHariPeriode",
                label:{
                    text:"Max Hari Periode (Hari)",
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    step:0
                } 
            },
            {
                dataField:"uangCuti",
                label:{
                    text:"Uang Cuti",
                },
                editorType:"dxSelectBox",
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged:(e) => {
                        if (e.value) {
                            this.formRef.current.instance.getEditor('uangCutiPercent').option('readOnly',false)
                        }else{
                            this.formRef.current.instance.getEditor('uangCutiPercent').option('readOnly',true)
                            this.formRef.current.instance.updateData({uangCutiPercent:0})
                        }
                    }
                },
            },
            {
                dataField:"uangCutiPercent",
                label:{
                    text:"Prosentase Gapok + Tunjangan Tetap (%)",
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    readOnly:false,
                    format: "#,##0",
                    step:0,
                }
            },
            {
                dataField: 'cutiTahunan',
                dataType:'boolean',
                label: {
                    text: 'Cuti Tahunan',
                    // alignment: 'left',
                    // location: 'left'
                },
                editorType: 'dxCheckBox',
            },
            {
                dataField:"akumulasiCuti",
                label:{
                    text:"Akumulasi Cuti",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }, 
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.newData = true
        this.formRef = React.createRef()
    }

    retrieveData = (data) => {  
        this.setState({
            DataMaster:data
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => { 
        this.setState({
            DataMaster:{},
            popupVisible: false
        })
    }

    submitData = async () => {
        try {
            var loadAPI = 'cuti-types'
            var text = 'Sukses Input Tipe Cuti'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"POST",{
                values: this.state.DataMaster
            });

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    updateData = async () => {
        try {
            var loadAPI = 'cuti-types'
            var text = 'Sukses Input Tipe Cuti'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"PUT",{
                values: this.state.DataMaster,
                key:this.state.DataMaster
            },this.state.DataMaster);

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Tipe Cuti'} 
                width={700}
                height={500}
                toolbarItems={this.props.action == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
            </Popup>
        )
    }
}

export default FormModalTipeCuti