import React, { Component } from 'react'
import { Form, Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import { participants, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDate, formatDefaultDate, PrintElem, generateExcelFile, formatNumber, capitalizeFirstLetter } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';

const bulan = [
    {
        id: 1,
        namaBulan: 'Januari'
    },
    {
        id: 2,
        namaBulan: 'Februari'
    },
    {
        id: 3,
        namaBulan: 'Maret'
    },
    {
        id: 4,
        namaBulan: 'April'
    },
    {
        id: 5,
        namaBulan: 'Mei'
    },
    {
        id: 6,
        namaBulan: 'Juni'
    },
    {
        id: 7,
        namaBulan: 'Juli'
    },
    {
        id: 8,
        namaBulan: 'Agustus'
    },
    {
        id: 9,
        namaBulan: 'September'
    },
    {
        id: 10,
        namaBulan: 'Oktober'
    },
    {
        id: 11,
        namaBulan: 'November'
    },
    {
        id: 12,
        namaBulan: 'Desember'
    },
]

class ReportPesertaPemberhentian extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formFilter: {},
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'greaterPemberhentianDate',
                                label: {
                                    text: 'Tanggal Pemberhentian',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: 'lessPemberhentianDate',
                                label: {
                                    text: 'S/D',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async () => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-view-laporan-pemberhentians?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `products`, 'GET')

        if (filter.participantId) {
            loadAPI = loadAPI + `participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        } else {
            var participantName = 'Semua'
        }

        if (filter.productId) {
            loadAPI = loadAPI + `productId.in=${filter.productId}&`
        }
        var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'

        // Tanggal Pemberhentian
        if (filter.greaterPemberhentianDate) {
            loadAPI = loadAPI + `tglPemberhentian.greaterThanOrEqual=${formatDate(filter.greaterPemberhentianDate)}&`
        }

        if (filter.lessPemberhentianDate) {
            loadAPI = loadAPI + `tglPemberhentian.lessThanOrEqual=${formatDate(filter.lessPemberhentianDate)}&`
        }
        var getPesertaPemberhentian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI.slice(0, -1), 'GET')
        // var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'kepersertaan-products?size=9999', 'GET')

        var arrQtySaldoIuran = []
        var arrQtyBunga = []
        var arrQtyPengembalian = []
        var arrQtyPhdp = []
        for (var value of getPesertaPemberhentian) {
            // var month = bulan.find(val => val.id == value.mkBulan)
            // value.mkBulan = month ? month.namaBulan : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'
            value.saldoIuran = value.saldoIuran ? value.saldoIuran : 0
            value.bunga = value.bunga ? value.bunga : 0
            value.nominalPemberhentian = value.nominalPemberhentian ? value.nominalPemberhentian : 0
            value.phdp = value.phdp ? value.phdp : 0

            // var arrKp = getKp.find(val => val.pesertaId === value.pesertaId)
            // value.nsPeriode = arrKp ? arrKp.nsPeriode : 0

            value.nsPeriode = value.nsPeriode ? value.nsPeriode : 0

            value.birthDate = value.birthDate ? formatDefaultDate(value.birthDate) : '-'
            value.tglPemberhentian = value.tglPemberhentian ? formatDefaultDate(value.tglPemberhentian) : '-'
            value.pensiunDate = value.pensiunDate ? formatDefaultDate(value.pensiunDate) : '-'

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName

            let totalSaldoIuran = value.saldoIuran ? value.saldoIuran : 0
            let totalBunga = value.bunga ? value.bunga : 0
            let totalPemberhentian = value.nominalPemberhentian ? value.nominalPemberhentian : 0
            let totalPhdp = value.phdp ? value.phdp : 0

            arrQtySaldoIuran.push(totalSaldoIuran)
            arrQtyBunga.push(totalBunga)
            arrQtyPengembalian.push(totalPemberhentian)
            arrQtyPhdp.push(totalPhdp)
        }

        if (arrQtySaldoIuran.length !== 0 && arrQtyBunga.length !== 0 && arrQtyPengembalian !== 0 && arrQtyPhdp !==0) {
            var qtyIuran = arrQtySaldoIuran.reduce((a, b) => a + b)
            var qtyBunga = arrQtyBunga.reduce((a, b) => a + b)
            var qtyPengembalian = arrQtyPengembalian.reduce((a, b) => a + b)
            var qtyPhdp = arrQtyPhdp.reduce((a, b) => a + b)
        }

        // var qtyIuran = arrQtySaldoIuran.reduce((a, b) => a + b)
        // var qtyBunga = arrQtyBunga.reduce((a, b) => a + b)
        // var qtyPengembalian = arrQtyPengembalian.reduce((a, b) => a + b)

        var data = {
            template: {
                name: '/dapen/kepesertaan/report/peserta/peserta-pemberhentian',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                tanggalBerhenti: filter.lessPemberhentianDate && filter.greaterPemberhentianDate ? (filter.greaterPemberhentianDate ? formatDefaultDate(filter.greaterPemberhentianDate) : "~") + ' S/D ' + (filter.lessPemberhentianDate ? formatDefaultDate(filter.lessPemberhentianDate) : "~") : 'Semua',
                dataPeserta: getPesertaPemberhentian,
                qtyIuran: qtyIuran,
                qtyBunga: qtyBunga,
                qtyPengembalian: qtyPengembalian,
                qtyPhdp: qtyPhdp,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,

            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))

        this.setState({
            src: responseFile
        })
    }

    print = async () => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-view-laporan-pemberhentians?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `products`, 'GET')

        if (filter.participantId) {
            loadAPI = loadAPI + `participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        } else {
            var participantName = 'Semua'
        }

        if (filter.productId) {
            loadAPI = loadAPI + `productId.in=${filter.productId}&`
        }
        var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'

        // Tanggal Pemberhentian
        if (filter.greaterPemberhentianDate) {
            loadAPI = loadAPI + `tglPemberhentian.greaterThanOrEqual=${formatDate(filter.greaterPemberhentianDate)}&`
        }

        if (filter.lessPemberhentianDate) {
            loadAPI = loadAPI + `tglPemberhentian.lessThanOrEqual=${formatDate(filter.lessPemberhentianDate)}&`
        }
        var getPesertaPemberhentian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI.slice(0, -1), 'GET')
        // var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'kepersertaan-products?size=9999', 'GET')

        var arrQtySaldoIuran = []
        var arrQtyBunga = []
        var arrQtyPengembalian = []
        var arrQtyPhdp = []
        for (var value of getPesertaPemberhentian) {
            // var month = bulan.find(val => val.id == value.mkBulan)
            // value.mkBulan = month ? month.namaBulan : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'
            value.saldoIuran = value.saldoIuran ? value.saldoIuran : 0
            value.bunga = value.bunga ? value.bunga : 0
            value.nominalPemberhentian = value.nominalPemberhentian ? value.nominalPemberhentian : 0
            value.phdp = value.phdp ? value.phdp : 0

            // var arrKp = getKp.find(val => val.pesertaId === value.pesertaId)
            // value.nsPeriode = arrKp ? arrKp.nsPeriode : 0

            value.nsPeriode = value.nsPeriode ? value.nsPeriode : 0

            value.birthDate = value.birthDate ? formatDefaultDate(value.birthDate) : '-'
            value.tglPemberhentian = value.tglPemberhentian ? formatDefaultDate(value.tglPemberhentian) : '-'
            value.pensiunDate = value.pensiunDate ? formatDefaultDate(value.pensiunDate) : '-'

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName

            let totalSaldoIuran = value.saldoIuran ? value.saldoIuran : 0
            let totalBunga = value.bunga ? value.bunga : 0
            let totalPemberhentian = value.nominalPemberhentian ? value.nominalPemberhentian : 0
            let totalPhdp = value.phdp ? value.phdp : 0

            arrQtySaldoIuran.push(totalSaldoIuran)
            arrQtyBunga.push(totalBunga)
            arrQtyPengembalian.push(totalPemberhentian)
            arrQtyPhdp.push(totalPhdp)
        }

        if (arrQtySaldoIuran.length !== 0 && arrQtyBunga.length !== 0 && arrQtyPengembalian !== 0 && arrQtyPhdp !==0) {
            var qtyIuran = arrQtySaldoIuran.reduce((a, b) => a + b)
            var qtyBunga = arrQtyBunga.reduce((a, b) => a + b)
            var qtyPengembalian = arrQtyPengembalian.reduce((a, b) => a + b)
            var qtyPhdp = arrQtyPhdp.reduce((a, b) => a + b)
        }

        var data = {
            template: {
                name: '/dapen/kepesertaan/report/peserta/peserta-pemberhentian',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                tanggalBerhenti: filter.lessPemberhentianDate && filter.greaterPemberhentianDate ? (filter.greaterPemberhentianDate ? formatDefaultDate(filter.greaterPemberhentianDate) : "~") + ' S/D ' + (filter.lessPemberhentianDate ? formatDefaultDate(filter.lessPemberhentianDate) : "~") : 'Semua',
                dataPeserta: getPesertaPemberhentian,
                qtyIuran: qtyIuran,
                qtyBunga: qtyBunga,
                qtyPengembalian: qtyPengembalian,
                qtyPhdp: qtyPhdp,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'DAFTAR PEMBERHENTIAN PESERTA'
            }
        }
        // var response = await reportRequest(this.props.store, data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'DAFTAR PEMBERHENTIAN PESERTA')
        store.dispatch(showLoading(false))

        return responseFile
    }

    exportExcel = async () => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-view-laporan-pemberhentians?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `products`, 'GET')

        if (filter.participantId) {
            loadAPI = loadAPI + `participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        } else {
            var participantName = 'Semua'
        }

        if (filter.productId) {
            loadAPI = loadAPI + `productId.in=${filter.productId}&`
        }
        var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'

        // Tanggal Pemberhentian
        if (filter.greaterPemberhentianDate) {
            loadAPI = loadAPI + `tglPemberhentian.greaterThanOrEqual=${formatDate(filter.greaterPemberhentianDate)}&`
        }

        if (filter.lessPemberhentianDate) {
            loadAPI = loadAPI + `tglPemberhentian.lessThanOrEqual=${formatDate(filter.lessPemberhentianDate)}&`
        }
        var getPesertaPemberhentian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI.slice(0, -1), 'GET')
        // var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'kepersertaan-products?size=9999', 'GET')

        var index = 1

        var arrQtySaldoIuran = []
        var arrQtyBunga = []
        var arrQtyPengembalian = []
        var arrQtyPhdp = []
        for (var value of getPesertaPemberhentian) {
            // var month = bulan.find(val => val.id == value.mkBulan)
            // value.mkBulan = month ? month.namaBulan : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            // var arrKp = getKp.find(val => val.pesertaId === value.pesertaId)
            // value.nsPeriode = arrKp ? arrKp.nsPeriode : 0

            let totalSaldoIuran = value.saldoIuran ? value.saldoIuran : 0
            let totalBunga = value.bunga ? value.bunga : 0
            let totalPemberhentian = value.nominalPemberhentian ? value.nominalPemberhentian : 0
            let totalPhdp = value.phdp ? value.phdp : 0

            arrQtySaldoIuran.push(totalSaldoIuran)
            arrQtyBunga.push(totalBunga)
            arrQtyPengembalian.push(totalPemberhentian)
            arrQtyPhdp.push(totalPhdp)

            var dataValue = {
                id: index++,
                nik: value.nik,
                fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                birthDate: value.birthDate ? formatDefaultDate(value.birthDate) : '-',
                tglPemberhentian: value.tglPemberhentian ? formatDefaultDate(value.tglPemberhentian) : '-',
                pensiunDate: value.pensiunDate ? formatDefaultDate(value.pensiunDate) : '-',
                mkTahun: value.mkTahun,
                mkBulan: value.mkBulan,
                usia: value.usia,
                productId: value.productId,
                cardNo: value.cardNo,
                nsPeriode: value.nsPeriode ? value.nsPeriode : 0,
                saldoIuran: value.saldoIuran ? (value.saldoIuran) : 0,
                phdp: value.phdp ? (value.phdp) : 0,
                bunga: value.bunga ? (value.bunga) : 0,
                nominalPemberhentian: value.nominalPemberhentian ? (value.nominalPemberhentian) : 0,
            }

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        if (arrQtySaldoIuran.length !== 0 && arrQtyBunga.length !== 0 && arrQtyPengembalian !== 0 && arrQtyPhdp !==0) {
            var qtyIuran = arrQtySaldoIuran.reduce((a, b) => a + b)
            var qtyBunga = arrQtyBunga.reduce((a, b) => a + b)
            var qtyPengembalian = arrQtyPengembalian.reduce((a, b) => a + b)
            var qtyPhdp = arrQtyPhdp.reduce((a, b) => a + b)
        }

        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR PEMBERHENTIAN PESERTA');
            worksheet.addTable({
                name: 'MyTable',
                ref: 'A1',
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                    // border: {
                    //     top: {style:'thin'},
                    //     left: {style:'thin'},
                    //     bottom: {style:'thin'},
                    //     right: {style:'thin'}
                    // }
                },
                columns: [
                    { name: 'No' },
                    { name: 'NIK' },
                    { name: 'Nama' },
                    { name: 'Tanggal Lahir' },
                    { name: 'Tanggal Berhenti' },
                    { name: 'Tanggal Pensiun' },
                    { name: 'MK Tahun' },
                    { name: 'MK Bulan' },
                    { name: 'Usia Sekarang' },
                    { name: 'Produk' },
                    { name: 'Card No' },
                    { name: 'Periode' },
                    { name: 'Jumlah Iuran\r\n(Rp)' },
                    { name: 'PHDP\r\n(Rp)' },
                    { name: 'Bunga / Pengembangan\r\n(Rp)' },
                    { name: 'Total Pengembalian\r\n(Rp)' },
                ],
                rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }
                }))
            }

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BDAFTAR PEMBERHENTIAN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BDAFTAR PEMBERHENTIAN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BDAFTAR PEMBERHENTIAN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // // worksheet.getCell('A3').value = 'Kode'
            // // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = ': DAFTAR PEMBERHENTIAN PESERTA'

            // worksheet.getCell('A5').value = 'Laporan'
            // worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A7').value = 'Pemberi Kerja'
            // worksheet.getCell('B7').value = `: ${participantName}`

            // worksheet.getCell('A8').value = 'Produk'
            // worksheet.getCell('B8').value = `: ${productName}`

            // worksheet.getCell('A9').value = 'Tanggal Berhenti'
            // worksheet.getCell('B9').value = `: ${filter.lessPemberhentianDate && filter.greaterPemberhentianDate ? (filter.greaterPemberhentianDate ? formatDefaultDate(filter.greaterPemberhentianDate) : "~") + ' S/D ' + (filter.lessPemberhentianDate ? formatDefaultDate(filter.lessPemberhentianDate) : "~") : 'Semua'}`

            // worksheet.getCell('A10').value = 'Tanggal Pensiun'
            // worksheet.getCell('B10').value = `: ${filter.lessPensiunDate && filter.greaterPensiunDate ? (filter.greaterPensiunDate ? formatDefaultDate(filter.greaterPensiunDate) : "~") + ' S/D ' + (filter.lessPensiunDate ? formatDefaultDate(filter.lessPensiunDate) : "~") : 'Semua'}`

            worksheet.mergeCells(`A${lastTableRowNum + 1}:L${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`L${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

            worksheet.getCell(`M${lastTableRowNum + 1}`).value = (qtyIuran)
            worksheet.getCell(`M${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`M${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`N${lastTableRowNum + 1}`).value = (qtyPhdp)
            worksheet.getCell(`N${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`N${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`O${lastTableRowNum + 1}`).value = (qtyBunga)
            worksheet.getCell(`O${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`O${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`P${lastTableRowNum + 1}`).value = (qtyPengembalian)
            worksheet.getCell(`P${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`P${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('H1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('I1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('J1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('K1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('L1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('M1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('N1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('O1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('P1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

            for (var i = 2; i <= data.length + 1; i++) {
                worksheet.getCell('A' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('B' + i).alignment = { vertical: 'middle', horizontal: 'left' };
                worksheet.getCell('C' + i).alignment = { vertical: 'middle', horizontal: 'left' };
                worksheet.getCell('D' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('E' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('F' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('G' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('H' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('I' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('J' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('K' + i).alignment = { vertical: 'middle', horizontal: 'left' };
                worksheet.getCell('L' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('M' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('N' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('O' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('P' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            }

            var WidthColums = 20;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 40;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = 30;
            Data13.numFmt = '#,##0.00';

            const Data14 = worksheet.getColumn(14);
            Data14.width = 30;
            Data14.numFmt = '#,##0.00';

            const Data15 = worksheet.getColumn(15);
            Data15.width = 30;
            Data15.numFmt = '#,##0.00';

            const Data16 = worksheet.getColumn(16);
            Data16.width = 30;
            Data16.numFmt = '#,##0.00';

            await workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR PEMBERHENTIAN PESERTA.xlsx`
                );
            });
        } catch (e) {
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"nik", 
        //         to:"NIK"
        //     },
        //     {
        //         from:"fullName", 
        //         to:"Nama"
        //     },
        //     {
        //         from:"birthDate", 
        //         to:"Tanggal Lahir"
        //     },
        //     {
        //         from:"tglPemberhentian", 
        //         to:"Tanggal Berhenti"
        //     },
        //     {
        //         from:"pensiunDate", 
        //         to:"Tanggal Pensiun"
        //     },
        //     {
        //         from:"mkTahun", 
        //         to:"MK Tahun"
        //     },
        //     {
        //         from:"mkBulan", 
        //         to:"MK Bulan"
        //     },
        //     {
        //         from:"usia", 
        //         to:"Usia Sekarang"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"cardNo", 
        //         to:"Card No"
        //     },
        //     {
        //         from:"saldoIuran", 
        //         to:"Jumlah Iuran"
        //     },
        //     {
        //         from:"bunga", 
        //         to:"Bunga"
        //     },
        //     {
        //         from:"nominalPemberhentian", 
        //         to:"Total Pengembalian"
        //     },
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]

        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'DAFTAR PENSIUN DITUNDA'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Pemberi Kerja',
        //         B: null,
        //         C: ':',
        //         D: participantName
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        //     {
        //         A: 'Tanggal Berhenti',
        //         B: null,
        //         C: ':',
        //         D: filter.lessPemberhentianDate && filter.greaterPemberhentianDate ? (filter.greaterPemberhentianDate ? formatDefaultDate(filter.greaterPemberhentianDate) : "~") + 'S/D' + (filter.lessPemberhentianDate ? formatDefaultDate(filter.lessPemberhentianDate) : "~") : 'Semua'
        //     },
        //     {
        //         A: 'Tanggal Pensiun',
        //         B: null,
        //         C: ':',
        //         D: filter.lessPensiunDate && filter.greaterPensiunDate ? (filter.greaterPensiunDate ? formatDefaultDate(filter.greaterPensiunDate) : "~") + 'S/D' + (filter.lessPensiunDate ? formatDefaultDate(filter.lessPensiunDate) : "~") : 'Semua'
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []

        // for(var InstructionData of getPesertaPemberhentian){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_PEMBERHENTIAN_PESERTA')
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Daftar Pemberhentian Peserta </h4>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.formFilter}
                            items={this.filterItem}
                            labelLocation='left'
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{ height: "calc(100vh - 415px)" }} title={'ea'} />
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPesertaPemberhentian