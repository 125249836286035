import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, departments, fixedAssetGroups, coa, depreciationMethod } from "dataSource/lookup";

class ModalEditFixedAsset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {}
        };

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.columns = [
            {
                itemType: 'group',
                colCount: 2,
                colSpan: 2,
                items: [
                    {
                        dataField: 'fixedAssetCode',
                        label: {
                            alignment: 'left',
                            text: 'Aset'
                        },
                        editorOptions: {
                            placeholder: 'Kode Aset Tetap',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'fixedAssetName',
                        label: {
                            alignment: 'left',
                            visible: false
                        },
                        editorOptions: {
                            placeholder: 'Nama Aset Tetap'
                        }
                    },
                    {
                        dataField: 'fixedAssetGroupId',
                        label: {
                            alignment: 'left',
                            text: 'Grup'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: fixedAssetGroups(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'assetGroupCode',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                var assetGroup = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `fixed-asset-groups/${e.value}`, 'GET')

                                var fixedAssetCode = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `fixed-asset/generateFixedAssetCode/${e.value}`, 'GET')

                                this.formRef.current.instance.updateData({
                                    assetGroupName: assetGroup.assetGroupName,
                                    accumDepreciationCoaId: assetGroup.accumDepreciationCoaId,
                                    assetCoaId: assetGroup.assetCoaId,
                                    departmentId: assetGroup.departmentId,
                                    depreciationCoaId: assetGroup.depreciationCoaId,
                                    depreciationMethod: assetGroup.depreciationMethod,
                                    economicLife: assetGroup.economicLife,
                                    fixedAssetCode: fixedAssetCode
                                })
                            }
                        }
                    },
                    {
                        dataField: 'assetGroupName',
                        label: {
                            visible: false
                        },
                        editorOptions: {
                            readOnly: true,
                            placeholder: 'Nama Grup'
                        }
                    },
                    {
                        dataField: 'fixedAssetDesc',
                        label: {
                            alignment: "left",
                            text: "Deskripsi Aset",
                        },
                        editorType: "dxTextArea",
                        editorOptions: {
                            height: '100px'
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'departmentId',
                        label: {
                            alignment: 'left',
                            text: 'Departemen'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: departments(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'departmentCode',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                var departmentName = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `departments/${e.value}`, 'GET')
                                this.formRef.current.instance.updateData('departmentName', departmentName.departmentName)
                            }
                        }
                    },
                    {
                        dataField: 'departmentName',
                        label: {
                            visible: false
                        },
                        editorOptions: {
                            readOnly: true,
                            placeholder: 'Nama Departemen'
                        }
                    },
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'purchaseDate',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Pembelian'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    onValueChanged: (e) => {
                                        this.formRef.current.instance.updateData({
                                            calcDate: e.value,
                                        })
                                    },
                                    max: this.systemDate ? new Date(this.systemDate.dateValue) : null
                                }
                            },
                            {
                                dataField: 'buyingPrice',
                                label: {
                                    alignment: 'left',
                                    text: 'Harga Beli'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                    onValueChanged: (e) => {
                                        const { formData } = this.state

                                        if (formData.depreciationMethod === 'S') {
                                            const { economicLife, salvage, buyingPrice } = formData
                                            this.formRef.current.instance.updateData({
                                                monthlyDepreciation: (e.value - salvage) / economicLife
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'salvage',
                                label: {
                                    alignment: 'left',
                                    text: 'Residu'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                    onValueChanged: (e) => {
                                        const { formData } = this.state

                                        if (formData.depreciationMethod === 'S') {
                                            const { economicLife, salvage, buyingPrice } = formData
                                            this.formRef.current.instance.updateData({
                                                monthlyDepreciation: (buyingPrice - e.value) / economicLife
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'economicLife',
                                label: {
                                    alignment: 'left',
                                    text: 'Umur Ekonomis (Bulan)'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                    onValueChanged: (e) => {
                                        const { formData } = this.state

                                        if (formData.depreciationMethod === 'S') {
                                            const { economicLife, salvage, buyingPrice } = formData
                                            this.formRef.current.instance.updateData({
                                                monthlyDepreciation: (buyingPrice - salvage) / e.value,
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'location',
                                label: {
                                    alignment: 'left',
                                    text: 'Lokasi'
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'accumDepreciation',
                                label: {
                                    alignment: 'left',
                                    text: 'Akum. Penyusutan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                }
                            },
                            {
                                dataField: 'accumDepreciationYtd',
                                label: {
                                    alignment: 'left',
                                    text: 'Akum. Penyusutan Tahun Berjalan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                }
                            },
                            {
                                dataField: 'calcDate',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Mulai Perhitungan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    max: this.systemDate ? new Date(this.systemDate.dateValue) : null
                                }
                            },
                            {
                                dataField: 'bookValue',
                                label: {
                                    alignment: 'left',
                                    text: 'Nilai Buku'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                }
                            },
                            {
                                dataField: 'monthlyDepreciation',
                                label: {
                                    alignment: 'left',
                                    text: 'Penyusutan Bulanan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                }
                            },
                        ]
                    },
                    {
                        dataField: 'depreciationMethod',
                        label: {
                            alignment: 'left',
                            text: 'Metode Penyusutan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: depreciationMethod(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            value: 'depreciationMethod',
                            onValueChanged: (e) => {
                                console.log(e)

                            }
                        },
                        colSpan: 2
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Detail Informasi Aset',
                colSpan: 2,
                colCount: 2,
                items: [
                    {
                        dataField: 'brand',
                        label: {
                            alignment: 'left',
                            text: 'Brand / Merk'
                        },
                    },
                    {
                        dataField: 'certificateNo',
                        label: {
                            alignment: 'left',
                            text: 'No Sertifikat / Plat'
                        },
                    },
                    {
                        dataField: 'serialNo',
                        label: {
                            alignment: 'left',
                            text: 'No Seri'
                        },
                    },
                    {
                        dataField: 'type',
                        label: {
                            alignment: 'left',
                            text: 'Tipe'
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Konfigrasi COA',
                colSpan: 2,
                items: [
                    {
                        dataField: 'assetCoaId',
                        label: {
                            alignment: 'left',
                            text: 'Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            wrapItemText: true
                        }
                    },
                    {
                        dataField: 'accumDepreciationCoaId',
                        label: {
                            alignment: 'left',
                            text: 'Akum. Penyusutan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            wrapItemText: true
                        }
                    },
                    {
                        dataField: 'depreciationCoaId',
                        label: {
                            alignment: 'left',
                            text: 'Beban Penyusutan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            wrapItemText: true
                        }
                    },
                ]
            }
        ];


        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.formRef = React.createRef()

        this.type = 'add'
    }


    retrieveData = async (data) => {
        this.setState({
            formData: data
        })

        this.type = 'edit'
    }
    submitData = async () => {
        var dataMaster = this.state.formData
        dataMaster.status = 'A'
        if (dataMaster) {
            try {
                if (this.type == 'add') {
                    var insertAPI = 'fixed-assets'
                    dataMaster.baseAmount = dataMaster.buyingPrice
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, insertAPI, 'POST', {
                        values: dataMaster
                    })
                    if (response) {
                        notify({ message: 'Data berhasil ditambahkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.props.forceRefresh()
                        this.hide()
                    }
                } else {
                    var updateAPI = 'fixed-assets'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, updateAPI, 'PUT', {
                        key: dataMaster.id,
                        values: dataMaster
                    }, dataMaster)
                    if (response) {
                        notify({ message: 'Data ' + response.fixedAssetName + ' berhasil diubah!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.props.forceRefresh()
                        this.hide()
                    }
                }
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
            formData: {}
        });

        this.type = 'add'
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };


    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Form Fixed Asset"}
                    width={"70vw"}
                    height={"70vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form
                        colCount={2}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.columns}
                        ref={this.formRef}
                        scrollingEnabled={true}
                        labelLocation="left"
                    />

                </Popup>
            </React.Fragment>

        );
    }
}

export default ModalEditFixedAsset;