import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form, Button, FileUploader } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, banks, pejabat, jabatan } from "dataSource/lookup";
import DataGridDetailInstruksiPenempatan from "./datagrid/penempatan";
import DataGridDetailInstruksiPelepasan from "./datagrid/pelepasan";
import DataGridDetailInstruksiPerpanjangan from "./datagrid/perpanjangan";
import reportRequest from "plugin/reportRequest";
import { formatUploadFileData, imageSource } from "plugin/helper";
import ScrollView from "devextreme-react/scroll-view";
import ModalPreviewPdf from "components/pages/modal/kepesertaan/compPeserta/previewPdf";

class FormInstruksiBankAktif extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            DataMaster: {},
            dataDeposito: {},
            fileUploadReceipt: [],
            fileInstruksi: {},
            fileInstruksiContentType: {},
        };
        this.subCategory = []
        this.noOrder = []
        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'orderInstruksiId',
                        label: {
                            text: 'ID'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'transactionName',
                        label: {
                            text: 'Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instruksiNo',
                        label: {
                            text: 'Nomor Instruksi'
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'instruksiDate',
                        label: {
                            text: 'Tanggal Instruksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'orderDate',
                        label: {
                            text: 'Tanggal Order'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'pejabat1',
                        label: {
                            text: 'Pejabat 1'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                            searchEnabled: true,
                            onValueChanged: async(e) => {
                                var val = e.value
                                if(val){
                                    let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${val}`, 'GET')
                                    this.formRef.current.instance.getEditor('jabatan1').option('value', response.jabatanId)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Pejabat 1 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'jabatan1',
                        label: {
                            text: 'Jabatan 1'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'jabatanName',
                            readOnly: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Jabatan 1 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'pejabat2',
                        label: {
                            text: 'Pejabat 2'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                            searchEnabled: true,
                            onValueChanged: async(e) => {
                                var val = e.value
                                if(val){
                                    let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${val}`, 'GET')
                                    this.formRef.current.instance.getEditor('jabatan2').option('value', response.jabatanId)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Pejabat 2 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'jabatan2',
                        label: {
                            text: 'Jabatan 2'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'jabatanName',
                            readOnly: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Jabatan 2 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'kajian',
                        label: {
                            text: 'Catatan Instruksi'
                        },
                        editorType: 'dxTextArea',
                    },
                ]
            },
        ]
        this.formFile = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: "file",
                        label: {
                            text: "File Instruksi",
                            alignment: 'left'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                ]
            }
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Simpan Perubahan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.updateData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.formRef = React.createRef()
        this.formFileRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Instruksi`]: formattedFileData.base64data,
                    [`${fieldName}InstruksiContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    updateData = async () => {
        var dataMaster = this.state.DataMaster
        var submitData = {
            ...dataMaster,
            fileInstruksi: this.state.fileInstruksi,
            fileInstruksiContentType: this.state.fileInstruksiContentType,
            fileName: this.state.fileName,
        }
        if (submitData) {
            try {
                var insertAPI = 'order-depositos/update-instruksi-deposito'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Perubahan berhasil disimpan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    show = (data) => {
        this.setState({
            popupVisible: true,
            DataMaster: {
                bankId: data.bankId,
                instruksiNo: data.instruksiNo || '<AUTO>',
                pejabat1: parseInt(data.pejabat1),
                pejabat2: parseInt(data.pejabat2),
                orderInstruksiId: data.instruksiId,
                transactionName: data.transactionName,
                instruksiDate: data.instruksiDate,
                orderDate: data.dataDeposito[0].orderDate,
                kajian: data.kajian,
            },
            dataDeposito: [
                ...data.dataDeposito,
            ],
            type: data.transactionName,
            fileInstruksi: data.fileInstruksi,
            fileInstruksiContentType: data.fileInstruksiContentType
        })
    };

    hide = () => {
        this.setState({
            DataMaster: {},
            dataDeposito: {},
            popupVisible: false,
        });
    }
    previewFile = async() => {
        var src = imageSource(this.state.fileInstruksiContentType, this.state.fileInstruksi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Form Instruksi Bank"}
                    width={'90vw'}
                    height={'90vh'}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-md-6 mb-3">
                                    <Form
                                        colCount={1}
                                        id={"formMaster"}
                                        formData={this.state.DataMaster}
                                        items={this.dataMaster}
                                        ref={this.formRef}
                                        labelLocation="left"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={"formFile"}
                                        items={this.formFile}
                                        ref={this.formFileRef}
                                        labelLocation="left"
                                    />
                                    {/* <img src={imageSource(this.state.fileInstruksiContentType, this.state.fileInstruksi)} style={{ maxHeight: 'calc(100% - 75px)', maxWidth: '100%' }} /> */}
                                    <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileInstruksi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Buka File
                                    </button>
                                </div>
                                {
                                    this.state.type == 'Placement Deposito' ?
                                        <DataGridDetailInstruksiPenempatan data={this.state.dataDeposito} /> : this.state.type == 'Withdraw Deposito' ?
                                            <DataGridDetailInstruksiPelepasan data={this.state.dataDeposito} /> : this.state.type == 'Rollover Deposito' ?
                                                <DataGridDetailInstruksiPerpanjangan data={this.state.dataDeposito} /> : null
                                }
                            </div>
                            <ModalPreviewPdf 
                                ref={this.modalPreviewPdfRef}
                                store={this.props.store}
                            />
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }
}

export default FormInstruksiBankAktif