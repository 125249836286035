import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import { anggaranSubType, sdmUnit } from 'dataSource/lookup'
import notify from 'devextreme/ui/notify'
import { confirm } from 'devextreme/ui/dialog'

class FormModalMataAnggaran extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            newData: true
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.isNew = false

        this.formItems = [
            {
                dataField: 'anggaranCode',
                label: {
                    alignment: "left",
                    text: "Kode Anggaran"
                }
            },
            {
                dataField: 'anggaranName',
                label: {
                    alignment: "left",
                    text: "Nama Anggaran"
                }
            },
            {
                dataField: 'anggaranSubTypeId',
                label: {
                    alignment: "left",
                    text: "Sub Tipe Anggaran"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: anggaranSubType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'anggaranSubName',
                }
            },
            {
                dataField: 'unitId',
                label: {
                    alignment: "left",
                    text: "Unit Kerja"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                }
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.submitData();
                    }
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.updateData()
                    }
                },
                toolbar: "bottom"
            },
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = async(e) => {
        var result

        if (this.state.popupVisible === true && !this.isNew) {
            if (typeof e.cancel === 'boolean') {
                e.cancel = true
            }
            var result = await confirm('Apakah anda yakin untuk membatalkan input data ini?', 'Konfirmasi')
        } else {
            result = true
        }
        if (result) {
            if(!this.isNew) {
                if (typeof e.cancel === 'boolean') {
                    e.cancel = false
                }
            }

            this.setState({
                popupVisible: false,
                dataMaster: {},
            })
            this.props.forceRefresh(true)
        }
    }

    retrieveData = async (data) => {
        this.setState({
            dataMaster: data
        })
    }

    submitData = async () => {
        this.isNew = false
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'staticdata-wf/anggaran-items', 'POST', {
                values: data
            });

            if (response) {
                this.isNew = true
                this.hide();
                await notify({ message: 'Berhasil tambah data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            await notify({ message: `Terjadi kesalahan input ${error}`, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            console.log(error);
        }
    }

    updateData = async () => {
        this.isNew = false
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'staticdata-wf/anggaran-items', 'PUT', {
                key: data.id,
                values: data
            }, data);

            if (response) {
                this.isNew = true
                this.hide();
                await notify({ message: 'Berhasil ubah data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            await notify({ message: `Terjadi kesalahan input ${error}`, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            console.log(error);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={this.props.actionType === 'add' ? 'Form Mata Anggaran' : 'Form Edit Mata Anggaran'}
                width={"30vw"}
                height={"auto"}
                toolbarItems={this.props.actionType === 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                <Form
                    ref={this.formRef}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.formItems}
                    labelLocation='left'
                />
            </Popup>
        )
    }
}

export default FormModalMataAnggaran