import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';

class KlaimManfaatPensiun extends Component {
    constructor(props){
        super(props)
        this.state = {
            filterData : {}
        }

        this.columns = [
            {
                dataField: 'tanggalKalim',
                caption: 'Tanggal Klaim',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'noKlaim',
                caption: 'No.Klaim',
            },
            {
                dataField: 'noDapen',
                caption: 'No.Dapen',
            },
            {
                dataField: 'nama',
                caption: 'Nama',
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'tanggalKepesertaan',
                caption: 'Tanggal Kepesertaan',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'statusKlaim',
                caption: 'Status Klaim',
            },
            
        ]

        this.filterItem = [
            {
                dataField: 'date',
                label: {
                    text: 'Tanggal',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            // {
            //     itemType:"button",
            //     buttonOptions:{
            //         text:"Submit",
            //         type:"default",
            //         cssClass:"bg-danger",
            //         onClick:() => {
            //             this.submitData()
            //         },
            //     },
            //     horizontalAlignment:"left"
            // },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 


    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 
    
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Klaim Manfaat Pensiun</h2>

                <Form
                    colCount={3}
                    id={'form'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                    scrollingEnabled={true}
                    ref={this.formRef}
                />

                <DevExpressDataGrid
                    loadAPI='klaim-manfaat-pensiun'
                    insertAPI='klaim-manfaat-pensiun'
                    updateAPI='klaim-manfaat-pensiun'
                    deleteAPI='klaim-manfaat-pensiun' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Klaim Manfaat Kepesertaan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Klaim Manfaat Pensiun'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    height = {'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default KlaimManfaatPensiun
