import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import httpRequest from 'plugin/httprequest';
import { ScrollView } from 'devextreme-react';
import { participants } from 'dataSource/lookup';
import FormDataResume from './comp/resume';
import FormDataPesertaAktifBaru from './comp/peserta-aktif-baru';
import FormDataPesertaPengkinianData from './comp/pengkinian-data';
import FormDataRegistrasiPensiun from './comp/registrasi-pensiun';

class ModalKonfirmasiIntegrasiData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            pesertaId: '',
            DataMaster : {},
            actionType: 'add',
            pesertaAktifBaru:[],
            pengkinianData:[],
            registrasiPensiun:[]
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        
        this.formMasterRef = React.createRef()
        this.formDataResumeRef = React.createRef()
        this.formDataPesertaAktifBaruRef = React.createRef()
        this.formDataPesertaPengkinianDataRef = React.createRef()
        this.formDataRegistrasiPensiunRef = React.createRef()

        this.popupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options:{
            //         'text': 'Simpan',
            //         onClick: async() => {
            //             console.log('ea')
            //         }
            //     },
            //     toolbar: 'bottom'
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: () => {
                        this.hide()
                    } 
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Resume'
            },
            {
                id: 1,
                text: 'Peserta Aktif Baru'
            },
            {
                id: 2,
                text: 'Pengkinian Data'
            },
            {
                id: 3,
                text: 'Registrasi Pensiun'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'fileName',
                label: {
                    alignment: 'left',
                    text: 'Nama File'
                },
            },
            {
                dataField: 'participantId',
                label: {
                    alignment: 'left',
                    text: 'Pemberi Kerja'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'participantName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'fileDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal File'
                },
                editorType: "dxDateBox",
                editorOptions:{
                    displayFormat: 'dd MMM yyyy'
                }
            },
        ]
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster:data
        })
    }

    getPesertaAktifBaru = async (data) => {
        try {
            var loadApi = `h-2-h-file-dtls/get-peserta-aktif-baru/${data.id}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'GET')
            
            if (response) {
                this.setState({
                    pesertaAktifBaru:response
                })
                this.formDataPesertaAktifBaruRef.current.forceRefresh(true)
            }
        } catch (e) {
            console.log(e)
        }
       
    }

    getPengkinianData = async (data) => {
        try {
            var loadApi = `h-2-h-file-dtls/get-peserta-pengkinian-data/${data.id}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'GET')
            if (response) {
                this.setState({
                    pengkinianData:response
                })
                this.formDataPesertaPengkinianDataRef.current.forceRefresh(true)
            }
        } catch (e) {
            console.log(e)
        }
        
    }

    getRegistrasiPensiun = async (data) => {
        try {
            var loadApi = `h-2-h-file-dtls/get-peserta-registrasi-pensiun/${data.id}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'GET')
            if (response) {
                this.setState({
                    registrasiPensiun:response
                })
            }
        } catch (e) {
            console.log(e)
        }
        
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            pesertaId: '',
            DataMaster : {},
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabIndexModalKonfirmasi${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabIndexModalKonfirmasi${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Data'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row" style={{height: 'calc(90vh - 325px)'}}>
                        <div className="col-md-12">
                            <Form
                                ref={this.formMasterRef}
                                colCount={3}
                                id={'formDataMaster'}
                                formData={this.state.DataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                readOnly={this.state.actionType == 'add' ? false : false}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <ScrollView width='100%' height='100%'>
                            <div id={`tabIndexModalKonfirmasi${this.props.actionType}-0`} className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataResume store={this.props.store} actionType={this.props.actionType} ref={this.formDataResumeRef} data={this.state.DataMaster}/>
                            </div>
                            <div id={`tabIndexModalKonfirmasi${this.props.actionType}-1`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataPesertaAktifBaru store={this.props.store} actionType={this.props.actionType} ref={this.formDataPesertaAktifBaruRef} data={this.state.pesertaAktifBaru} />
                            </div>
                            <div id={`tabIndexModalKonfirmasi${this.props.actionType}-2`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataPesertaPengkinianData store={this.props.store} actionType={this.props.actionType} ref={this.formDataPesertaPengkinianDataRef} data={this.state.pengkinianData}/>
                            </div>
                            <div id={`tabIndexModalKonfirmasi${this.props.actionType}-3`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataRegistrasiPensiun store={this.props.store} actionType={this.props.actionType} ref={this.formDataRegistrasiPensiunRef} data={this.state.registrasiPensiun}/>
                            </div>
                        </ScrollView>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalKonfirmasiIntegrasiData
