import React, { Component } from 'react'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";
import { Popup, Form} from 'devextreme-react';
import { formatDate } from 'plugin/helper';

class FormKonfirmasiSubmitManagementFee extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: this.kirimKonfirmasi.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.DataMaster = [
            {
                dataField: 'noInstruksi',
                label: {
                    alignment: 'left',
                    text: 'No Instruksi'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'namaProduk',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'tglPembayaran',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true
                },
            },
            {
                dataField: 'keterangan',
                label: {
                    alignment: 'left',
                    text: 'Keterangan'
                },
            },
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.idData = 0
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
        })
        this.idData = 0
    }

    retrieveData = (data) => {
        console.log(data);
        this.idData = data.id
        this.setState({
            DataMaster:data
        })
    }

    kirimKonfirmasi = async(e) => {
        try {
            var DataMaster = this.state.DataMaster
            var loadApi = `bank-transactions/tagihan-biaya-management-fee-konfirmasi-debet/${this.idData}/${formatDate(DataMaster.tglPembayaran)}/${DataMaster.keterangan}`
            var response =   await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "PUT",{
                    key:this.idData,
                    values: {}
                },
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: 'Terjadi Kesalahan System', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Tagihan Biaya Management Fee'}
                width={'40vw'}
                height={'40vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
            <div className="container-fluid">
                <Form
                    ref={this.formMasterRef}
                    colCount={1}
                    id={'formDataMaster'}
                    formData={this.state.DataMaster}
                    items={this.DataMaster}
                    scrollingEnabled={true}
                    labelLocation={"left"}
                />
            </div>
            </Popup>
        )
    }
}


export default FormKonfirmasiSubmitManagementFee