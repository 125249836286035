import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { programs, projectSdm, supplierSdm } from 'dataSource/lookup';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify'

class FormModalPenutupanProyek extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            dasarPengajuan: {},
            DataDocumentSK: {},
            DataDocumentBast: {}
        }

        this.form = [
            {
                itemType: 'group',
                colCount: 1,
                items: [
                    {
                        dataField: "programId",
                        label: {
                            text: "Program",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                            readOnly: true,
                            onValueChanged: (e) => {
                                this.projectByProgram(e.value)
                            }
                        }
                    },
                    {
                        dataField: 'projectId',
                        label: {
                            text: 'Kode/Nama Proyek'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'projectCode',
                            searchEnabled: true,
                            readOnly: true,
                            onSelectionChanged: async (e) => {
                                var slctd = e.selectedItem
                                if (slctd) {
                                    if (slctd.supplierId) {
                                        var supplier = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `suppliers/${slctd.supplierId}`, 'GET')
                                    }
                                    this.formRef.current.instance.updateData({
                                        noPenutupan: slctd.penutupanNo === null ? 'PRJCLS-' + Math.floor(Math.random() * 100000) : slctd.penutupanNo,

                                        jenisProyek: slctd.projectType,
                                        kodeProyek: slctd.projectCode,
                                        namaProyek: slctd.projectName,
                                        nilaiProyek: slctd.projectNominal,

                                        kodePelaksana: supplier.kodeSupplier,
                                        namaPelaksana: supplier.namaSupplier,
                                        alamatPelaksana: supplier.alamat,
                                        noTelp: supplier.noTelp,
                                        email: supplier.email,

                                        downPayment: slctd.dpAmount,
                                        terminPayment: slctd.terminPembayaran,
                                    })
                                }
                            }
                        }
                    },
                    {
                        dataField: "noPenutupan",
                        label: {
                            text: "No Penutupan",
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "penutupanSkNo",
                        label: {
                            text: "No Sk Penutupan Proyek",
                        },
                    },
                    {
                        dataField: "tanggalSk",
                        label: {
                            text: "Tanggal Sk"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "tanggalPetupan",
                        label: {
                            text: "Tanggal Penutupan"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'catatanPenutupan',
                        label: {
                            alignment: 'left',
                            text: 'Catatan'
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {

                        }
                    },
                    {
                        dataField: "tanggalPerolehanAsset",
                        label: {
                            text: "Tanggal Perolehan Asset"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "unitAset",
                        label: {
                            text: "Unit Asset"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0
                        }
                    },
                    {
                        dataField: "fileSk",
                        label: {
                            text: "File SK",
                            alignment: 'left'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: this.state.DataDocumentSK.fileName || "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStartedSk.bind(this),
                        },
                    },
                    {
                        dataField: "fileBastPenutupan",
                        label: {
                            text: "File BAST Penutupan",
                            alignment: 'left'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: this.state.DataDocumentBast.fileName || "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStartedBast.bind(this),
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 1,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Informasi Pelaksana',
                        items: [
                            {
                                dataField: "kodePelaksana",
                                label: {
                                    text: "Kode Pelaksana"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "namaPelaksana",
                                label: {
                                    text: "Nama Pelaksana"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "alamatPelaksana",
                                label: {
                                    text: "Alamat Pelaksana"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "noTelp",
                                label: {
                                    text: "No. Telp"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "noFax",
                                label: {
                                    text: "No. Fax"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "email",
                                label: {
                                    text: "Email"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Informasi Proyek',
                        items: [
                            {
                                dataField: "jenisProyek",
                                label: {
                                    text: "Jenis Proyek"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: '1',
                                            value: 'Pembangunan'
                                        },
                                        {
                                            id: '2',
                                            value: 'Renovasi'
                                        },
                                        {
                                            id: '3',
                                            value: 'Pengadaan'
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: "kodeProyek",
                                label: {
                                    text: "Kode Proyek"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "namaProyek",
                                label: {
                                    text: "Nama Proyek"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "nilaiProyek",
                                label: {
                                    text: "Nilai Proyek"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "termin",
                                label: {
                                    text: "Termin"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "tanggalMulai",
                                label: {
                                    text: "Tenggal Mulai"
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "tanggalAkhir",
                                label: {
                                    text: "Tenggal Akhir"
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Informasi Pembayaran',
                        items: [
                            {
                                dataField: "downPayment",
                                label: {
                                    text: "Down Payment"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "terminPayment",
                                label: {
                                    text: "Termin Payment"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "nominalTotalTermin",
                                label: {
                                    text: "Nominal Total Termin"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },

                        ]
                    }
                ]
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
    }

    onUploadStartedSk = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocumentSK = { ...this.state.DataDocumentSK };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocumentSK[`${fieldName}Name`] = files.name;
                DataDocumentSK[`${fieldName}`] = formattedFileData.base64data;
                DataDocumentSK[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataDocumentSK: DataDocumentSK
                });
            };

            fr.readAsDataURL(files);
        }
    }

    onUploadStartedBast = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocumentBast = { ...this.state.DataDocumentBast };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocumentBast[`${fieldName}Name`] = files.name;
                DataDocumentBast[`${fieldName}`] = formattedFileData.base64data;
                DataDocumentBast[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataDocumentBast: DataDocumentBast
                });
            };

            fr.readAsDataURL(files);
        }
    }
    projectByProgram = async (programId) => {
        let loadAPI = `projects`
        var getProject = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        let filtered = getProject.filter(val => val.programId === programId)

        let dataSource = new ArrayStore({
            data: filtered,
            key: 'id'
        });

        this.formRef.current.instance.getEditor('projectId').option('dataSource', dataSource)
    }
    previewFile = async (state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    retrieveData = (data) => {
        data.projectId = data.id
        data.programId = data.programId
        this.setState({
            DataMaster: data,
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.setState({
            DataMaster: {},
            dasarPengajuan: {},
            popupVisible: false
        })
    }

    submitData = async () => {
        var data = this.state.DataMaster
        var submitData = {
            catatanPenutupan: data.catatanPenutupan,
            fileBastPenutupan: this.state.DataDocumentBast.fileBastPenutupan,
            fileSk: this.state.DataDocumentSK.fileSk,
            noPenutupan: data.noPenutupan,
            penutupanSkNo: data.penutupanSkNo,
            projectId: data.projectId,
            tanggalPerolehanAsset: formatDate(data.tanggalPerolehanAsset),
            tanggalPetupan: formatDate(data.tanggalPetupan),
            tanggalSk: formatDate(data.tanggalSk),
            unitAset: data.unitAset
        }
        if (submitData) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects-penutupan', 'POST', { values: submitData })
                if (response) {
                    let status = {
                        id: data.projectId,
                        statusId: 3
                    }
                    console.log(status)
                    var oldData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `projects/${status.id}`, 'GET')
                    if(oldData){
                        try {
                            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects', 'PUT', { values: status, key: status.id }, oldData)
                            this.hide()
                            this.props.forceRefresh()
                            notify({ message: 'Sukses Simpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        } catch (e) {
                            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    }
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    updateData = () => {

    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Penutupan Proyek'}
                width={"150vh"}
                height={"80vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <Form
                        ref={this.formRef}
                        colCount={2}
                        id={'formPotongan'}
                        formData={this.state.DataMaster}
                        items={this.form}
                        labelLocation='left'
                    />

                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalPenutupanProyek