import React, { Component } from 'react'
import Popup from 'devextreme-react/popup'
import Form from 'devextreme-react/form'

class ViewDaftarTransaksiPenggajian extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }
        
        this.rapelColumns = [
            {
                dataField:"tunjangan",
                caption:"Tunjangan",
            },
            {
                dataField:"nominalTunjangan",
                caption:"Nominal Tunjangan",
            },
        ]

        this.totalLemburColumns = [
            {
                dataField:"tanggal",
                caption:"Tanggal",
            },
            {
                dataField:"nominalLembur",
                caption:"Nominal Lembur",
            },
        ]

        this.totalCicilanPinjamanColumns = [
            {
                dataField:"cicilan",
                caption:"Cicilan",
            },
            {
                dataField:"nominalCicilan",
                caption:"Nominal Cicilan",
            },
        ]

        this.IuranDapenColumns = [
            {
                dataField:"iuran",
                caption:"Iuran",
            },
            {
                dataField:"nominalIuran",
                caption:"Nominal Iuran",
            },
        ]

        this.totalPotonganLainnyaColumns = [
            {
                dataField:"potongan",
                caption:"Potongan",
            },
            {
                dataField:"nominalPotongan",
                caption:"Nominal Potongan",
            },
        ]

        this.DataMaster = [
            {
                itemType:"group",
                colCount:1,
                items:[
                    {
                        dataField:"nik",
                        label:{
                            text:"NIK",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"nama",
                        label:{
                            text:"Nama",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"jabatan",
                        label:{
                            text:"Jabatan",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"unit",
                        label:{
                            text:"Unit",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"gajiBersih",
                        label:{
                            text:"Gaji Bersih",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        itemType:"group",
                        name:"gaji",
                        caption:"Gaji",
                        items:[
                            {
                                dataField:"gajiPokok",
                                label:{
                                    text:"Gaji Pokok",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"totalTunjangan",
                                label:{
                                    text:"Total Tunjangan",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"rapel",
                                label:{
                                    text:"Rapel",
                                    location:"top"
                                },
                                editorType:"dxDataGrid",
                                editorOptions:{
                                    columns: this.rapelColumns,
                                    showBorders: true,
                                    selection:{
                                        mode:"multiple",
                                        showCheckBoxesMode: 'always',
                                        allowSelectAll:true,
                                        selectAllMode: "allPages"
                                    },
                                    filterRow:{
                                        showOperationChooser:true,
                                        visible:true
                                    }
                                }
                            },
                        ]
                    },
                    {
                        itemType:"group",
                        name:"lembur",
                        caption:"Lembur",
                        items:[
                            {
                                dataField:"totalLembur",
                                label:{
                                    text:"Total Lembur",
                                    location:"top"
                                },
                                editorType:"dxDataGrid",
                                editorOptions:{
                                    columns: this.totalLemburColumns,
                                    showBorders: true,
                                    selection:{
                                        mode:"multiple",
                                        showCheckBoxesMode: 'always',
                                        allowSelectAll:true,
                                        selectAllMode: "allPages"
                                    },
                                    filterRow:{
                                        showOperationChooser:true,
                                        visible:true
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            {
                itemType:"group",
                colCount:1,
                items:[
                    {
                        itemType:"group",
                        name:"potongan",
                        caption:"Potongan",
                        items:[
                            {
                                dataField:"totalCicilanPinjaman",
                                label:{
                                    text:"Total Cicilan Pinjaman",
                                    location:"top"
                                },
                                editorType:"dxDataGrid",
                                editorOptions:{
                                    columns: this.totalCicilanPinjamanColumns,
                                    showBorders: true,
                                    selection:{
                                        mode:"multiple",
                                        showCheckBoxesMode: 'always',
                                        allowSelectAll:true,
                                        selectAllMode: "allPages"
                                    },
                                    filterRow:{
                                        showOperationChooser:true,
                                        visible:true
                                    }
                                }
                            },
                            {
                                dataField:"totalIuranDapen",
                                label:{
                                    text:"Total Iuran Dapen",
                                    location:"top"
                                },
                                editorType:"dxDataGrid",
                                editorOptions:{
                                    columns: this.IuranDapenColumns,
                                    showBorders: true,
                                    selection:{
                                        mode:"multiple",
                                        showCheckBoxesMode: 'always',
                                        allowSelectAll:true,
                                        selectAllMode: "allPages"
                                    },
                                    filterRow:{
                                        showOperationChooser:true,
                                        visible:true
                                    }
                                }
                            },
                            {
                                dataField:"pajak",
                                label:{
                                    text:"Pajak",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"totalPotonganLainnya",
                                label:{
                                    text:"Total Potongan Lainnya",
                                    location:"top"
                                },
                                editorType:"dxDataGrid",
                                editorOptions:{
                                    columns: this.totalPotonganLainnyaColumns,
                                    showBorders: true,
                                    selection:{
                                        mode:"multiple",
                                        showCheckBoxesMode: 'always',
                                        allowSelectAll:true,
                                        selectAllMode: "allPages"
                                    },
                                    filterRow:{
                                        showOperationChooser:true,
                                        visible:true
                                    }
                                }
                            },
                        ]
                    }
                    
                ]
            }

        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];
    }

    submitData = () => {

    }

    show = () => {
        this.setState({
            popupVisible:true
        })
    }

    hide = () => {
        this.setState({
            popupVisible:false
        })
    }

    render() {
        return (
            
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Transaksi Penggajian'} 
                    width={1300}
                    heigth={650}

                    ref={this.PopupRef}

                    toolbarItems={this.PopupToolbarItem}
                >
                    <div style={{height:"calc(70vh - 40px)"}}>
                        <Form
                            colCount={2}
                            id={'formFilter'}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation={"left"}
                            scrollingEnabled={true}
                        />
                    </div>
                </Popup>
        )
    }
}

export default ViewDaftarTransaksiPenggajian