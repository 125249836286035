import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { banks, instrumentSubCategoryDeposito, currency, rekeningBank, fundManager, deposito, productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormSubInstrumentPelepasanDeposito from './formInstrument';
import { formatDefaultDate, inputToTaskInvestasi } from 'plugin/helper';


class FormPengajuanPelepasanDeposito extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            kajian: "",
            kajianContentType: 'text/html',
            dataPembelian: {},
        }

        this.prevTabIndex = 0
        this.dataPenjualan = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.dataGridDetailPelepasanRef = React.createRef()
        this.modalInstrumentSahamRef = React.createRef()
        this.kajianPengajuanRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('draft')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('kirim')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' || this.props.actionType == 'add' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id: 1,
                text: 'Instrumen Pengajuan'
            },
            {
                id: 2,
                text: 'Kajian Pengajuan'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                type: 'empty',
                colSpan: 2
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                }
            },
            {
                dataField: 'tanggalPelepasan',
                label: {
                    text: 'Tanggal Order'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please fill order date'
                    }
                ]
            },
            {
                dataField: 'fundManagerId',
                label: {
                    text: 'Fund Manager',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fundManagerName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Fund Manager'
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                onValueChanged: (e) => {
                    this.setState({
                        dataMaster: {
                            productId: e.value
                        }
                    })
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Product'
                    }
                ]
            },
            {
                dataField: 'kategori',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'jenisTransaksi',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
        ]
        this.toolbarItem = [
            'background', 'bold', 'color', 'italic', 'link', 'image', 'strike', 'subscript', 'superscript', 'underline', 'blockquote', 'header'
            , 'increaseIndent', 'decreaseIndent', 'orderedList', 'bulletList', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeBlock'
            , 'variable', 'separator', 'undo', 'redo', 'clear', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft',
            'insertColumnRight', 'deleteColumn'
        ]
    }
    getDataGridColumnConfiguration = (actionType) => {
        var columnConfiguration = [
            {
                dataField: 'instrumentName',
                caption: 'Deposito',
                lookup: this.props.actionType == 'view' ? {
                    dataSource: deposito(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                } : null
            },
            {
                dataField: 'bilyetNo',
                caption: 'No. Bilyet'
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName'
                }
            },
            {
                dataField: 'kurs',
                caption: 'Kurs',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            // {
            //     dataField: 'nilaiDeposito',
            //     caption: 'Nilai Deposito',
            //     dataType: 'number',
            //     format: '#,##0.00',
            //     alignment: 'right'
            // },
            {
                dataField: 'rate',
                caption: 'Rate (%)',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'tanggalPelepasan',
                caption: 'Tanggal Pelepasan',
                dataType: 'date',
                format: 'd MMM yyyy'
            },
            {
                dataField: 'bungaPelepasan',
                caption: 'Nominal Bunga',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'avgPrice',
                caption: 'Nilai Pelepasan',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'maturityDate',
                caption: 'Jatuh Tempo',
                dataType: 'date',
                format: 'd MMM yyyy',
            },
            {
                dataField: 'rekbankId',
                caption: 'Rekening Penerima',
                lookup: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'rekName',
                }
            }
        ]

        if (actionType === 'view') {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                data.noPengajuan = this.state.dataMaster.noPengajuan
                                data.productId = this.state.dataMaster.productId
                                this.modalInstrumentSahamRef.current.show()
                                this.modalInstrumentSahamRef.current.retrieveData(data)
                            }
                        },
                    ]
                },
            )
            return columnConfiguration
        } else {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                console.log(data);
                                data.noPengajuan = this.state.dataMaster.noPengajuan
                                data.productId = this.state.dataMaster.productId
                                this.modalInstrumentSahamRef.current.show()
                                this.modalInstrumentSahamRef.current.retrieveData(data)
                            }
                        },
                        {
                            text: 'Hapus',
                            hint: 'Hapus',
                            onClick: (e) => {
                                if (this.props.actionType == 'view') {
                                    notify({ message: 'Sorry, you cant remove data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1500);
                                } else {
                                    this.deleteDetailPelepasanData(e.row.key)
                                }
                            }
                        },
                    ]
                },
            )

            return columnConfiguration
        }
        return columnConfiguration
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });

    }

    retrieveData = (dataMaster, dataPenjualan, kajian) => {
        if (this.props.actionType === 'add') {
            dataMaster = dataMaster || {
                noPengajuan: '<AUTO>',
                tanggalPengajuan: new Date(this.sysDate.dateValue),
                kategori: 'Deposito',
                jenisTransaksi: 'Pelepasan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPenjualan: dataPenjualan,
                kajian: kajian,
            });
        } else {
            this.dataPenjualan = dataPenjualan || []
            if (dataMaster) {
                dataMaster.jenisTransaksi = 'Pelepasan'
            }
            dataMaster = dataMaster || {
                noPengajuan: '<AUTO>',
                tanggalPengajuan: new Date(this.sysDate.dateValue),
                kategori: 'Deposito',
                jenisTransaksi: 'Pelepasan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPenjualan: dataPenjualan,
                kajian: kajian,
            });
            this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
            this.dataGridDetailPelepasanRef.current.forceRefresh(true)
        }
        // if(dataMaster && dataPenjualan && kajian){
        //     this.dataPenjualan = dataPenjualan || []
        //     if (dataMaster) {
        //         dataMaster.jenisTransaksi = 'Pelepasan'
        //     }
        //     dataMaster = dataMaster || {
        //         noPengajuan: '<AUTO>',
        //         tanggalPengajuan: new Date(this.sysDate.dateValue),
        //         kategori: 'Deposito',
        //         jenisTransaksi: 'Pelepasan'
        //     }

        //     this.setState({
        //         dataMaster: dataMaster,
        //         dataPenjualan: dataPenjualan,
        //         kajian: kajian,
        //     });
        //     this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
        //     this.dataGridDetailPelepasanRef.current.forceRefresh(true)
        // }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async (type) => {
        var dataDataGrid = this.dataGridDetailPelepasanRef.current.getDataSource()
        var dataMaster = this.state.dataMaster
        var kajian = btoa(this.state.kajian)
        var kajianType = this.state.kajianContentType

        if (type == 'draft') {
            var draft = true
        }
        var customSubmitData = {
            ...dataMaster,
            instrumentDepositoList: dataDataGrid,
            draft: draft,
            kajian: kajian,
            kajianContentType: kajianType,
            requestOrderId: dataMaster.actionType == 'edit' ? dataMaster.requestId : null
        }
        if (customSubmitData) {
            try {
                var insertAPI = 'order-depositos/pengajuan-pelepasan-deposito'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: customSubmitData
                })

                if (type == 'kirim') {
                    try {
                        await inputToTaskInvestasi(this.props.store, customSubmitData.requestOrderId, customSubmitData.orderTypeId)
                    } catch (e) {
                        console.log(e);
                        notify({ message: "Terjadi kesalahan saat input data ke task", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }

                }

                notify({ message: 'Pengajuan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    loadDetailPelepasan = () => {
        return this.dataPenjualan
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindexPelepasanDeposito-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexPelepasanDeposito-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) => {
        this.setState({
            kajian: e.value
        })
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Instrument Pelepasan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.showModalInstrumentPelepasan()
                        }
                    },
                },
                visible: this.props.actionType == 'view' ? false : true
            }
        )
    }

    showModalInstrumentPelepasan = () => {
        this.modalInstrumentSahamRef.current.show()
        this.modalInstrumentSahamRef.current.retrieveData({
            noPengajuan: this.state.dataMaster.noPengajuan,
            productId: this.state.dataMaster.productId,
            tanggalPelepasan: this.state.dataMaster.tanggalPelepasan
        })
    }

    submitDetailPelepasanData = (data) => {
        if (data.id) {
            this.dataGridDetailPelepasanRef.current.push([{
                type: "update",
                data: {
                    noPengajuan: data.noPengajuan,
                    instrumentCode: data.instrumentCode,
                    instrumentName: data.instrumentName,
                    tanggalPelepasan: data.tanggalPelepasan,
                    bankId: data.bankId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    avgPrice: data.avgPrice,
                    efekBalanceAvgId: data.efekBalanceAvgId,
                    bilyetNo: data.bilyetNo,
                    rate: data.rate,
                    bungaPelepasan: data.bungaPelepasan,
                    maturityDate: data.maturityDate,
                    rekbankId: data.rekbankId,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    placementDate: data.placementDate,
                    kpdId: 1
                },
                key: data.id
            }])
        } else {
            this.dataGridDetailPelepasanRef.current.push([{
                type: "insert",
                data: {
                    noPengajuan: data.noPengajuan,
                    instrumentCode: data.instrumentCode,
                    instrumentName: data.instrumentName,
                    tanggalPelepasan: data.tanggalPelepasan,
                    bankId: data.bankId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    avgPrice: data.avgPrice,
                    efekBalanceAvgId: data.efekBalanceAvgId,
                    bilyetNo: data.bilyetNo,
                    rate: data.rate,
                    bungaPelepasan: data.bungaPelepasan,
                    maturityDate: data.maturityDate,
                    rekbankId: data.rekbankId,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    placementDate: data.placementDate,
                    kpdId: 1
                }
            }])
        }
    }
    deleteDetailPelepasanData = (key) => {
        this.dataGridDetailPelepasanRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    render() {
        let datagridElem
        if(this.state.popupVisible){
            datagridElem = <DevExpressDataGrid
                                ref={this.dataGridDetailPelepasanRef}
                                loadAPI='kpds'
                                insertAPI='kpds'
                                updateAPI='kpds'
                                deleteAPI='kpds'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                useArraySource={true}
                                ArraySourceData={this.loadDetailPelepasan}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Instrument"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                editingMode={'cell'}

                                paging={true}
                                defaultPageSize={10}
                                height={'calc(100vh - 380px)'}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Instrument'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.getDataGridColumnConfiguration(this.props.actionType)} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
        }else{
            datagridElem = null
        }
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Usulan Pelepasan Investasi Deposito'}
                    minWidth={'90vh'}
                    minHeight={'90vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={3}
                                    id={'formMaster'}
                                    ref={this.formMasterRef}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={false}
                                    labelLocation={"left"}
                                />
                            </div>
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                            <div id="tabindexPelepasanDeposito-0" className={' col-md-12'}>
                                <div className="row">
                                    <div className="col-md-12">
                                        {datagridElem}
                                    </div>
                                </div>
                            </div>
                            <div id="tabindexPelepasanDeposito-1" className={'d-none col-md-12'}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <HtmlEditor
                                            ref={this.kajianPengajuanRef}
                                            height="calc(100vh - 380px)"
                                            value={this.state.kajian}
                                            valueType={this.state.kajianContentType}
                                            name={'kajian'}
                                            onValueChanged={this.onValueChanged}
                                            readOnly={this.props.actionType == 'view' ? true : false}
                                        >
                                            <MediaResizing enabled={true} />
                                            <Toolbar>
                                                {
                                                    this.toolbarItem.map((toolbarItem) => {
                                                        return (
                                                            <Item
                                                                formatName={toolbarItem}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Toolbar>
                                        </HtmlEditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
                <FormSubInstrumentPelepasanDeposito
                    ref={this.modalInstrumentSahamRef}
                    store={this.props.store}
                    submitDetailPelepasanData={this.submitDetailPelepasanData}
                    type={this.props.actionType}
                />
            </React.Fragment>
        )
    }
}

export default FormPengajuanPelepasanDeposito