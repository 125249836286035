import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { corpactType, efekBalanceAvgSaham, instrumentSaham, kpds, productDefault, rekBankByProduct, rekeningBank } from 'dataSource/lookup'
import ScrollView from 'devextreme-react/scroll-view'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import ArrayStore from 'devextreme/data/array_store';
import { formatUploadFileData, imageSource } from 'plugin/helper'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import { confirm } from 'devextreme/ui/dialog';

class ModalCorporateActionRightIssue extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    visible: this.props.action == 'kirim' ? false : true,
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitFile()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'corporate-action',
                caption: 'Corporate Action',
                items: [
                    {
                        dataField: 'noKonfirmasi',
                        label: {
                            alignment: 'left',
                            text: 'No Konfirmasi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'corpactTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Corporate Action'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: corpactType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'typeName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            onValueChanged: async (e) => {
                                var selectSaham = this.formRef.current.instance.getEditor('instrumentId')
                                var selectKpd = this.formRef.current.instance.getEditor('kpdId')
                                var dataKpd = await this.kpdByProduct(this.props.store, e.value)
                                selectKpd.option('dataSource', dataKpd)
                                var dataSaham = await this.efekBalanceAvgSaham(this.props.store, e.value, selectKpd.option('value'))
                                selectSaham.option('dataSource', dataSaham)
                            },
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Mohon memilih produk terlebih dahulu'
                            }
                        ]
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            alignment: 'left',
                            text: 'Pengelola'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            onValueChanged: async (e) => {
                                var productId = this.formRef.current.instance.getEditor('productId').option('value')
                                var selectSaham = this.formRef.current.instance.getEditor('instrumentId')
                                var dataSaham = await this.efekBalanceAvgSaham(this.props.store, productId, e.value)
                                selectSaham.option('dataSource', dataSaham)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Mohon memilih kpd terlebih dahulu'
                            }
                        ]
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            alignment: 'left',
                            text: 'Saham'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'instrumentId',
                            searchEnabled: true,
                            displayExpr: (e) => {
                                return e && e.instrumentCode + ' - ' + e.instrumentName
                            },
                            onSelectionChanged: (e) => {
                                var data = e.selectedItem
                                if(data){
                                    this.formRef.current.instance.updateData('kodeSahamRight', data.instrumentCode + '-R')
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Saham tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'kodeSahamRight',
                        label: {
                            text: 'Kode Saham Right'
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'tglRecording',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Recording'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'tglCumRight',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Cum Right'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'tglExRight',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Ex Right'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'tglSubscription',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Subscription'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'hargaSubscription',
                        label: {
                            alignment: 'left',
                            text: 'Harga Subscription'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Ratio',
                items: [
                    {
                        dataField: 'tiapJumlah',
                        label: {
                            alignment: 'left',
                            text: 'Tiap Jumlah'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            step: 0,
                            onValueChanged: (e) => {
                                var qtyRecording = this.formRef.current.instance.getEditor('qtyRecording').option('value')
                                var tiapJml = e.value
                                var jmlRight = this.formRef.current.instance.getEditor('jumlahSahamRight').option('value')
                                if (qtyRecording) {
                                    var qty = qtyRecording / tiapJml
                                    var result = qty * jmlRight
                                    this.formRef.current.instance.updateData('qtyRight', result)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'range',
                                min: 1,
                                message: 'Rasio tidak bisa lebih sedikit dari 1'
                            },
                        ]
                    },
                    {
                        dataField: 'jumlahSahamRight',
                        label: {
                            alignment: 'left',
                            text: 'Jumlah Saham Right'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            step: 0,
                            onValueChanged: (e) => {
                                var qtyRecording = this.formRef.current.instance.getEditor('qtyRecording').option('value')
                                var tiapJml = this.formRef.current.instance.getEditor('tiapJumlah').option('value')
                                var jmlRight = e.value
                                if (qtyRecording) {
                                    var qty = qtyRecording / tiapJml
                                    var result = qty * jmlRight
                                    this.formRef.current.instance.updateData('qtyRight', result)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'range',
                                min: 1,
                                message: 'Rasio tidak bisa lebih sedikit dari 1'
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'stockDeviden',
                caption: 'Right Issue',
                items: [
                    {
                        dataField: 'qtyRecording',
                        label: {
                            alignment: 'left',
                            text: 'Kuantitas Recording'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            onValueChanged: (e) => {
                                var qtyRecording = e.value
                                var tiapJml = this.formRef.current.instance.getEditor('tiapJumlah').option('value')
                                var jmlRight = this.formRef.current.instance.getEditor('jumlahSahamRight').option('value')
                                if (qtyRecording) {
                                    var qty = qtyRecording / tiapJml
                                    var result = qty * jmlRight
                                    this.formRef.current.instance.updateData('qtyRight', result)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Kuantitas recording tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'qtyRight',
                        label: {
                            alignment: 'left',
                            text: 'Kuantitas Right'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            readOnly: true,
                        },
                        validationRules: [
                            {
                                type: 'range',
                                min: 1,
                                message: 'Transaksi dapat dilakukan jika Kuantitas lebih dari 1, silahkan cek kembali Instrument terpilih'
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Dokumen',
                items: [
                    {
                        dataField: "documentName",
                        label: {
                            text: "Nama Dokumen"
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "file",
                        label: {
                            text: "File Right Issue",
                            alignment: 'left'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                ]
            }
        ]
    }
    show = async (data) => {
        if (this.props.action == 'add') {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `bank-transactions/generate-no-konfirmasi/CASH_DEVIDEN`,
                "POST", {
                values: {}
            }
            );
            data.noKonfirmasi = response
        }
        var file = {}
        if (data.fileRightId) {
            file = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `file-investasis/${data.fileRightId}`,
                "GET",
            );
        }
        this.setState({
            fileBase64: file.fileBase64,
            fileBase64ContentType: file.fileBase64ContentType,
            fileName: file.fileName,
            dataMaster: {
                documentName: file.fileName,
                ...data,
            },
            popupVisible: true,
        });
    }
    kpdByProduct = async (store, productId) => {
        let loadAPI = `kpds?productId.in=${productId}`
        var getKpd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getKpd,
            key: 'id'
        });

        return dataSource
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    efekBalanceAvgSaham = async (store, productId, kpdId) => {
        let loadAPI = `efek-balance-avgs/all-instrument-stok?kdpId=${kpdId}&productId=${productId}`
        var getSaham = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getSaham,
            key: 'instrumentId'
        });

        return dataSource
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Base64`]: formattedFileData.base64data,
                    [`${fieldName}Base64ContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }
            fr.readAsDataURL(files)
            this.formRef.current.instance.updateData('documentName', files.name)
        }
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            action: ''
        })
    }
    previewFile = async () => {
        var src = imageSource(this.state.fileBase64ContentType, this.state.fileBase64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    submitFile = async (e) => {
        let result = await confirm('Simpan data ini?', 'Konfirmasi')
        var file = {
            fileBase64: this.state.fileBase64,
            fileBase64ContentType: this.state.fileBase64ContentType,
            fileName: this.state.fileName,
        }
        if (result) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    "file-investasis",
                    "POST", {
                    values: file
                }
                );

                if (response) {
                    await this.submitData(response.id)
                } else {
                    notify({ message: `Terjadi Kesalahan System , Saat Upload File ( ${e} )`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (e) {
                console.log(e)
                notify({ message: `Terjadi Kesalahan System ( ${e} )`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    submitData = async (fileRightId) => {
        var dataSubmit = this.state.dataMaster
        dataSubmit.fileRightId = fileRightId
        var message = this.props.action == 'add' ? 'Tambah Data Right berhasil!' : 'Ubah Data Right berhasil'
        if (dataSubmit) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    'corpact-transactions/transaksi-right-issue-create-update',
                    'POST',
                    { values: dataSubmit }
                )
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: message, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={this.props.action == 'kirim' ? 'Detail Right Issue' : this.props.action == 'edit' ? 'Ubah Right Issue' : 'Tambah Right Issue'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    ref={this.formRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                    readOnly={this.state.dataMaster.status == 'KIRIM' ? true : false}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileBase64 ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                            </div>
                        </div>
                        <ModalPreviewPdf
                            ref={this.modalPreviewPdfRef}
                            store={this.props.store}
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalCorporateActionRightIssue