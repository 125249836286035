import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { banks} from 'dataSource/lookup';
import ModalActionRapel from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/rapel/index';
import { alert } from 'devextreme/ui/dialog'
import { confirmAlert } from 'react-confirm-alert'
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';

class RapelPembayaranMp  extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {}, 
            getUrl: `view-pembayaran-rapels`
        }

        this.dataGridRef = React.createRef()
        this.modalEditRapelRef = React.createRef()
        this.modalLihatRapelRef = React.createRef()
        this.modalTambahRapelRef = React.createRef()

        this.filterItem = [
            // {
            //     dataField: 'produk',
            //     label: {
            //         text: 'Produk',
            //         alignment: "left",
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         dataSource: product(this.props.store),
            //         valueExpr: "id", // contains the same values as the "statusId" field provides
            //         displayExpr: "productCode", // provides display values
            //         searchEnabled: true,
            //         deferRendering: false,
            //         width: '100%',
            //         onValueChanged: (data) => {
            //             this.dataGridRef.current.forceRefresh()
            //         }
            //     },
            //     colSpan: 3
            // },
            {
                dataField: 'rapelPeriodeBulanDari',
                label : {
                    text: 'Tanggal Rapel',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    placeholder: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'rapelPeriodeBulanSampai',
                label : {
                    text: 'S/D',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    placeholder: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Filter',
                    onClick: (e) => {
                        var filter = this.state.formFilter
                        var periodeAwal = filter.rapelPeriodeBulanDari
                        var periodeAkhir = filter.rapelPeriodeBulanSampai
                        this.setState({
                            getUrl: periodeAwal && periodeAkhir ? `view-pembayaran-rapels?rapelDate.greaterThanOrEqual=${formatDate(periodeAwal)}&rapelDate.lessThanOrEqual=${formatDate(periodeAkhir)}` : 'view-pembayaran-rapels'
                        })
                    }
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    // 'edit',
                    {
                        text: 'Edit',
                        cssClass: 'text-success',
                        onClick : async (e) => {
                            let data = e.row.data
                            let status = data.statusMpBulanan
                            if(status === 'Selesai') {
                                await alert('Data yang sudah selesai tidak bisa di edit!', 'Informasi');
                            } else {
                                this.showModalEdit(data)
                            }
                        }
                    },
                    {
                        text: 'Lihat',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalLihat(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        cssClass: 'text-info',
                        onClick : async(e) => {
                            let rapelTypeId = e.row.data.rapelTypeId;
                            if (rapelTypeId === 1) {
                                await alert('Fitur kirim hanya berlaku untuk Rapel Terpisah!', 'Informasi');
                            } else {
                                await this.kirim(e.row.data.pembayaranRapelId)
                            }
                        }
                    },
                    {
                        text: 'Hapus',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            let data = e.row.data
                            let status = data.statusMpBulanan
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: async() => {
                                            if(status === 'Selesai') {
                                                await alert('Data yang sudah selesai tidak bisa di hapus!', 'Informasi');
                                            } else {
                                                this.deleteAPI(data)
                                            }
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    }
                    // {
                    //     text: 'Konfirmasi Bank',
                    //     cssClass: 'text-info',
                    //     onClick : (e) => {
                    //         console.log('Konfirmasi Bank')
                    //     }
                    // },
                ],
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'penerimaName',
                caption: 'Nama Penerima',
            },
            {
                dataField: 'rapelTypeId',
                caption: 'Jenis Rapel',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Rapel dibayar bersama MP Bulanan'
                        },
                        {
                            id: 2,
                            value: 'Rapel terpisah'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'transactionPeriodeBulan',
                caption: 'Transaksi Bulan',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Januari'
                        },
                        {
                            id: 2,
                            value: 'Februari'
                        },
                        {
                            id: 3,
                            value: 'Maret'
                        },
                        {
                            id: 4,
                            value: 'April'
                        },
                        {
                            id: 5,
                            value: 'Mei'
                        },
                        {
                            id: 6,
                            value: 'Juni'
                        },
                        {
                            id: 7,
                            value: 'Juli'
                        },
                        {
                            id: 8,
                            value: 'Agustus'
                        },
                        {
                            id: 9,
                            value: 'September'
                        },
                        {
                            id: 10,
                            value: 'Oktober'
                        },
                        {
                            id: 11,
                            value: 'November'
                        },
                        {
                            id: 12,
                            value: 'Desember'
                        },

                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'transactionPeriodeTahun',
                caption: 'Transaksi tahun'
            },
            {
                dataField: 'transaksiMp',
                caption: 'Transaksi MP',
                alignment:'center',
                columns: [
                    {
                        dataField: 'rapelPeriodeBulanDari',
                        caption: 'Periode Dari',
                        dataType: 'date',
                        format: 'MMM yyyy'
                    },
                    {
                        dataField: 'rapelPeriodeBulanSampai',
                        caption: 'Periode Sampai',
                        dataType: 'date',
                        format: 'MMM yyyy'
                    },
                    {
                        dataField: 'nominalRapelTotal',
                        caption: 'Total Nominal Rapel',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'mpRekeningName',
                        caption: 'Nama Rekening',
                    },
                    {
                        dataField: 'mpRekeningNo',
                        caption: 'No Rekening',
                    },
                    {
                        dataField: 'mpRekeningBankId',
                        caption: 'Bank Rekening',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'initialName'
                        }
                    },
                ]
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField: 'statusMpBulanan',
                caption: 'Status',
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                paymentDate : '2020-07-16',
                bank : 'BANK BCA',
            }
        ]
        this.currentData = JSON.parse(JSON.stringify(dummyData))
        return dummyData
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-rapels`, 'DELETE', data.pembayaranRapelId);

            await alert('Data Berhasil Dihapus !', 'Success')
            this.forceRefresh()
        } catch (error) {
            await alert(error,'Failed')
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Rapel',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            }
        )
    }

    showModalEdit = (data) => {
        this.modalEditRapelRef.current.show()
        this.modalEditRapelRef.current.retrieveData(data)
    }

    showModalLihat = (data) => {
        this.modalLihatRapelRef.current.show();
        this.modalLihatRapelRef.current.retrieveData(data);
    }

    showModalInsert = () => {
        this.modalTambahRapelRef.current.show()
    }

    kirim = async(pembayaranRapelId) => {
        try {
            var postAPI = `pembayaran-rapels/transaksiRapel/${pembayaranRapelId}`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
                values: {
                    pembayaranRapelId : pembayaranRapelId
                }
            })

            await alert('Data Berhasil Dikirim !','Success')
            this.forceRefresh()
        } catch (e) {
            await alert(e,'Failed')
        }
    }
    insertData = async(data) => {
        try {
            data.transactionPeriodeBulan = new Date(data.periodePembayaran).getMonth() + 1;
            data.transactionPeriodeTahun = new Date(data.periodePembayaran).getFullYear()

            // let loadAPI = `pengalihan-mps/getValidasiPotRapel/1/${data.transactionPeriodeBulan}/${data.transactionPeriodeTahun}/${data.kepersertaanProductId}`;
            // let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');

            var postAPI = 'pembayaran-rapels'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
                values: data
            })

            await alert('Data Berhasil Disimpan !','Success')
            this.forceRefresh()   

            // if (response[0].statusDescription !== null) {
            //     let result = await confirm(response[0].statusDescription, 'Konfirmasi');

            //     if (result) {
            //         var postAPI = 'pembayaran-rapels'
            //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
            //             values: data
            //         })

            //         await alert('Data Berhasil Disimpan !','Success')
            //         this.forceRefresh()   
            //     } 
            // } else {
            //         var postAPI = 'pembayaran-rapels'
            //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
            //             values: data
            //         })
        
            //         await alert('Data Berhasil Disimpan !','Success')
            //         this.forceRefresh()        
            // }
            // if (response.length !== 0) {
            //     let result = await confirm(response[0].statusDescription, 'Konfirmasi');

            //     if (result) {
            //         var postAPI = 'pembayaran-rapels'
            //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
            //             values: data
            //         })
        
            //         await alert('Data Berhasil Disimpan !','Success')
            //         this.forceRefresh()       
            //     }
            // }
        } catch (e) {
            await alert(e,'Failed')
        }
    }

    updateData = async(data) => {
        var loadAPI = `pembayaran-rapels/${data.id}`
        var getRapel = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        data.transactionPeriodeBulan = data.periodePembayaran ? new Date(data.periodePembayaran).getMonth() + 1 : data.transactionPeriodeBulan
        data.transactionPeriodeTahun = data.periodePembayaran ? new Date(data.periodePembayaran).getFullYear() : data.transactionPeriodeBulan
        try {

        var updateAPI = 'pembayaran-rapels'
        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
            key : data.id,
            values: data
        }, getRapel)

        await alert('Data Berhasil Diubah !','Success')
        this.forceRefresh()
            // let loadAPI = `pengalihan-mps/getValidasiPotRapel/1/${data.transactionPeriodeBulan}/${data.transactionPeriodeTahun}/${data.kepersertaanProductId}`;
            // let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');

            // if (response[0].statusDescription !== 0) {
            //     let result = await confirm(response[0].statusDescription, 'Konfirmasi');

            //     if (result) {
            //         var updateAPI = 'pembayaran-rapels'
            //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
            //             key : data.id,
            //             values: data
            //         }, getRapel)
        
            //         await alert('Data Berhasil Diubah !','Success')
            //         this.forceRefresh()
            //     }
            // } else {
            //     var updateAPI = 'pembayaran-rapels'
            //     await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
            //         key : data.id,
            //         values: data
            //     }, getRapel)
    
            //     await alert('Data Berhasil Diubah !','Success')
            //     this.forceRefresh()
            // }
        } catch (e) {
            await alert(e,'Failed')
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Rapel</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    // loadAPI='view-pembayaran-rapels'
                    loadAPI={this.state.getUrl}
                    insertAPI='pembayaran-rapels'
                    updateAPI='pembayaran-rapels'
                    deleteAPI='pembayaran-rapels' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    keyField = {'pembayaranRapelId'}
                    remoteOperations = {true}
                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Ditunda"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalActionRapel
                    ref={this.modalTambahRapelRef}
                    store={this.props.store}
                    actionType = {'add'}
                    submitData = {this.insertData}
                />
                <ModalActionRapel
                    ref={this.modalEditRapelRef}
                    store={this.props.store}
                    actionType = {'edit'}
                    submitData = {this.updateData}
                />
                 <ModalActionRapel
                    ref={this.modalLihatRapelRef}
                    store={this.props.store}
                    actionType = {'lihat'}
                    // submitData = {this.updateData}
                />
            </div>
        )
    }
}

export default RapelPembayaranMp    