import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instrumentCategorySubs, banks } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDefaultFullDate, formatNumber, numberWithCommas, thCommas, summaryValue } from 'plugin/helper'
import { reportFile } from 'plugin/reportRequest'
import notify from 'devextreme/ui/notify';
import store from 'redux/config'
import Excel from 'exceljs/dist/exceljs';
import { showLoading } from 'redux/actions/loading'
import { saveAs } from 'file-saver'

class DataGridDailyBalanceSaham extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'tanggalPenempatan'
            },
            {
                name: 'quantity',
                column: 'quantity',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiPenempatan',
                column: 'nilaiPenempatan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiSekarang',
                column: 'nilaiSekarang',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name : 'unrealizedPlPersen',
                showInColumn: 'unrealizedPlPersen',
                summaryType: 'custom',
                valueFormat: '#,##0.00',
                displayFormat: '{0}',
            },
            {
                name: 'unrealizedPlNominal',
                column: 'unrealizedPlNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                dataField: 'no.',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Instrument',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'tanggalPenempatan',
                caption: 'Tanggal Penempatan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'quantity',
                caption: 'Quantity',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'priceAverage',
                caption: 'Harga Perolehan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiPenempatan',
                caption: 'Nilai Perolehan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'price',
                caption: 'Harga Pasar',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiSekarang',
                caption: 'Nilai Sekarang',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'unrealizedPlPersen',
                caption: 'Unrealized P/L (%)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'unrealizedPlNominal',
                caption: 'Unrealized Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]
        this.dataGridRef = React.createRef();
        this.selectedRowsData = [];
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.print()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.exportExcel();
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            }
        )
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    customCalculate = (options) => {
        if (options.name === 'unrealizedPlPersen') {
            if (options.summaryProcess === 'start') {
                options.totalValue = {
                    
                };
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue = {
                    totalUnrealizedPlNominal : (options.totalValue.totalUnrealizedPlNominal || 0) + options.value.unrealizedPlNominal,
                    totalNilaiPenempatan : (options.totalValue.totalNilaiPenempatan || 0) + options.value.nilaiPenempatan,
                }
            } else if (options.summaryProcess === 'finalize') {
                options.totalValue = (options.totalValue.totalUnrealizedPlNominal / options.totalValue.totalNilaiPenempatan) * 100
            }
        }
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = this.selectedRowsData;
        let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');
        let arrQuantity = [];
        let arrPriceAverage = [];
        let arrNilaiPenempatan = [];
        let arrTotalPrice = [];
        let arrTotalNilaiSekarang = [];
        let arrTotalPlPerc = [];
        let arrTotalPlNom = [];
        store.dispatch(showLoading(true));

        for (let value of response) {
            arrQuantity.push(value.quantity);
            arrPriceAverage.push(value.priceAverage);
            arrNilaiPenempatan.push(value.nilaiPenempatan);
            arrTotalPrice.push(value.price);
            arrTotalNilaiSekarang.push(value.nilaiSekarang);
            arrTotalPlPerc.push(value.unrealizedPlPersen);
            arrTotalPlNom.push(value.unrealizedPlNominal);
            try {
                let dataValue = {
                    code: value.instrumentCode ? value.instrumentCode : '-',
                    name: value.instrumentName ? value.instrumentName : '-',
                    tanggalPenempatan: value.tanggalPenempatan ? formatDefaultFullDate(value.tanggalPenempatan) : '-',
                    quantity: value.quantity,
                    priceAverage: value.priceAverage,
                    nilaiPenempatan: value.nilaiPenempatan,
                    price: value.price,
                    nilaiSekarang: value.nilaiSekarang,
                    unrealizedPlPersen: value.unrealizedPlPersen,
                    unrealizedPlNominal: value.unrealizedPlNominal
                }
                const objectValue = Object.values(dataValue);
                data.push(objectValue)

                var totalQuantity = arrQuantity.reduce((a, b) => a + b, 0);
                var totalPriceAverage = arrPriceAverage.reduce((a, b) => a + b, 0);
                var totalNilaiPenempatan = arrNilaiPenempatan.reduce((a, b) => a + b, 0);
                var totalPrice = arrTotalPrice.reduce((a, b) => a + b, 0);
                var totalNilaiSekarang = arrTotalNilaiSekarang.reduce((a, b) => a + b, 0);
                var totalPlPerc = arrTotalPlPerc.reduce((a, b) => a + b, 0);
                var totalPlNom = arrTotalPlNom.reduce((a, b) => a + b, 0);
            } catch (error) {
                console.log(error);
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }

        let totalUnrealizedPer = (totalPlNom / totalNilaiPenempatan) * 100

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('DAILY BALANCE PORTFOLIO SAHAM');
        worksheet.addTable({
            name: 'MyTable',
            ref: `A${firstTableRowNum}`,
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
            },
            columns: [
                { name: 'Nama Instrument' },
                { name: 'Kode Instrument' },
                { name: 'Tanggal Penempatan' },
                { name: 'Quantity' },
                { name: 'Harga Perolehan' },
                { name: 'Nilai Perolehan' },
                { name: 'Harga Pasar' },
                { name: 'Nilai Sekarang' },
                { name: 'Unrealized P/L (%)' },
                { name: 'Unrealized Nominal' },
            ],
            rows: data
        });

        let lastRowNum = worksheet.lastRow.number;
        const lastTableRowNum = lastRowNum;

        for (let i = firstTableRowNum; i <= lastTableRowNum; i++) {
            const row = worksheet.getRow(i);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
            }))
        }

        for (let i = 9; i <= 10000; i++) {
            worksheet.getCell('A' + i).alignment = { vertical: 'middle', horizontal: 'left' };
            // worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell('D' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('E' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('F' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('G' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('H' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('I' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('J' + i).alignment = { vertical: 'middle', horizontal: 'right' };
        }

        worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
        worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E2').font = { size: 18, bold: true }

        worksheet.getCell('E3').value = 'DAILY BALANCE PORTFOLIO SAHAM';
        worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E3').font = { size: 18, bold: true }

        worksheet.getCell('E4').value = `KPD ${this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName.toUpperCase() : '-'}`;
        worksheet.getCell('E4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E4').font = { size: 18, bold: true }

        worksheet.getCell('E5').value = `TANGGAL LAPORAN ${this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-'}`;
        worksheet.getCell('E5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E5').font = { size: 18, bold: true }

        worksheet.mergeCells(`A${lastTableRowNum + 1}:C${lastTableRowNum + 1}`);
        worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total';
        worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'center' };
        worksheet.getCell(`A${lastTableRowNum + 1}`).font = { size: 12, bold: true }
        worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`D${lastTableRowNum + 1}`).value = totalQuantity;
        worksheet.getCell(`D${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`E${lastTableRowNum + 1}`).value = totalPriceAverage;
        worksheet.getCell(`E${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`F${lastTableRowNum + 1}`).value = totalNilaiPenempatan;
        worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`G${lastTableRowNum + 1}`).value = totalPrice;
        worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`H${lastTableRowNum + 1}`).value = totalNilaiSekarang;
        worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`I${lastTableRowNum + 1}`).value = totalUnrealizedPer;
        worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`J${lastTableRowNum + 1}`).value = totalPlNom;
        worksheet.getCell(`J${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        var WidthColums = 20;
        var WidthColums2 = 35;

        const Data1 = worksheet.getColumn(1);
        Data1.width = WidthColums;

        const Data2 = worksheet.getColumn(2);
        Data2.width = WidthColums2;

        const Data3 = worksheet.getColumn(3);
        Data3.width = WidthColums2;

        const Data4 = worksheet.getColumn(4);
        Data4.numFmt = '#,##0.00';
        Data4.width = WidthColums;

        const Data5 = worksheet.getColumn(5);
        Data5.numFmt = '#,##0.00'; 
        Data5.width = WidthColums;

        const Data6 = worksheet.getColumn(6);
        Data6.numFmt = '#,##0.00';
        Data6.width = WidthColums;

        const Data7 = worksheet.getColumn(7);
        Data7.numFmt = '#,##0.00';
        Data7.width = WidthColums;

        const Data8 = worksheet.getColumn(8);
        Data8.numFmt = '#,##0.00';
        Data8.width = WidthColums;

        const Data9 = worksheet.getColumn(9);
        Data9.numFmt = '#,##0.00';
        Data9.width = WidthColums;

        const Data10 = worksheet.getColumn(10);
        Data10.numFmt = '#,##0.00';
        Data10.width = WidthColums;

        await workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                'Daily_balance_portfolio_saham.xlsx'
            );
        });

        store.dispatch(showLoading(false));
    }
    print = async () => {
        try {
            // let loadAPI = `view-portofolio-daily-balances?balanceDate.equals=${this.props.stateData.balanceDate}&categoryId.in=1&kpdId.in=${this.props.stateData.kpdId}&productCode.in=${this.props.stateData.productCode}&size=9999`;
            // let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
            let response = this.selectedRowsData;

            let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');

            let transaksiDetil = [];
            if (response.length !== 0) {
                let arrQuantity = [];
                let arrPriceAverage = [];
                let arrNilaiPenempatan = [];
                let arrTotalPrice = [];
                let arrTotalNilaiSekarang = [];
                let arrTotalPlPerc = [];
                let arrTotalPlNom = [];

                for (let data of response) {
                    arrQuantity.push(data.quantity);
                    arrPriceAverage.push(data.priceAverage);
                    arrNilaiPenempatan.push(data.nilaiPenempatan);
                    arrTotalPrice.push(data.price);
                    arrTotalNilaiSekarang.push(data.nilaiSekarang);
                    arrTotalPlPerc.push(data.unrealizedPlPersen);
                    arrTotalPlNom.push(data.unrealizedPlNominal);
                    let dataResult = {
                        code: data.instrumentCode ? data.instrumentCode : '-',
                        name: data.instrumentName ? data.instrumentName : '-',
                        tanggalPenempatan: data.tanggalPenempatan ? formatDefaultFullDate(data.tanggalPenempatan) : '-',
                        quantity: data.quantity ? data.quantity : 0,
                        priceAverage: data.priceAverage ? data.priceAverage : 0,
                        nilaiPenempatan: data.nilaiPenempatan ? data.nilaiPenempatan : 0,
                        price: data.price ? data.price : 0,
                        nilaiSekarang: data.nilaiSekarang ? data.nilaiSekarang : 0,
                        unrealizedPlPersen: data.unrealizedPlPersen ? data.unrealizedPlPersen : 0,
                        unrealizedPlNominal: data.unrealizedPlNominal ? data.unrealizedPlNominal : 0
                    }

                    transaksiDetil.push(dataResult);

                    var totalQuantity = arrQuantity.reduce((a, b) => a + b, 0);
                    var totalPriceAverage = arrPriceAverage.reduce((a, b) => a + b, 0);
                    var totalNilaiPenempatan = arrNilaiPenempatan.reduce((a, b) => a + b, 0);
                    var totalPrice = arrTotalPrice.reduce((a, b) => a + b, 0);
                    var totalNilaiSekarang = arrTotalNilaiSekarang.reduce((a, b) => a + b, 0);
                    var totalPlPerc = arrTotalPlPerc.reduce((a, b) => a + b, 0);
                    var totalPlNom = arrTotalPlNom.reduce((a, b) => a + b, 0);

                }

                let totalUnrealizedPer = (totalPlNom / totalNilaiPenempatan) * 100

                reportFile({
                    template: { 'shortid': 'LAyP1Ya' },
                    data: {
                        "productCode": this.props.stateData.productCode,
                        "kpdName": this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName : '-',
                        "reportDate": this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-',
                        "categoryName": this.props.tabName ? this.props.tabName : '-',
                        "dailyBalanceDetail": transaksiDetil,
                        "totalQuantity": totalQuantity,
                        "totalPriceAverage": totalPriceAverage,
                        "totalNilaiPenempatan": totalNilaiPenempatan,
                        "totalPrice": totalPrice,
                        "totalNilaiSekarang": totalNilaiSekarang,
                        "totalPlPerc": totalUnrealizedPer,
                        "totalPlNom": totalPlNom
                    },
                    options: {
                        reportName: "Daily Balance Portofolio Saham"
                    }
                }, true, 'Daily Balance Portofolio Saham.pdf')
            }
        } catch (error) {
            console.log(error); 
        }
    }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`view-portofolio-daily-balances?balanceDate.equals=${this.props.stateData.balanceDate}&categoryId.in=1&kpdId.in=${this.props.stateData.kpdId}&productCode.in=${this.props.stateData.productCode}&size=9999`}
                    insertAPI='efek-balance-avgs'
                    updateAPI='efek-balance-avgs'
                    deleteAPI='efek-balance-avgs'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={false}
                    exportFileName={"Portofolio Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    showPageSizeSelector={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Saham'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    calculateCustomSummary={this.customCalculate}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    height={'calc(100vh - 367px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridDailyBalanceSaham