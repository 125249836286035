import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { broker, stock } from 'dataSource/lookup';

class DetailPelepasanSaham extends Component {
    constructor(props) {
        super(props)
        this.dataSource = new CustomStore({
            load: async (loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `order-requests-stok-dtls/${requestId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                return response
            },
            key: 'orderStokId',
            insert: (values) => {
            },
            update: (key, values) => {

            },
            remove: (key) => {

            }
        })

        this.columns = [
            {
                dataField: 'instrumentCode',
                caption: 'Instrumen',
                cellRender: (e) => {
                    var item = e.data
                    return item.instrumentCode+" - "+item.instrumentName
                }
            },
            {
                dataField: 'quantityOrder',
                caption: 'Kuantitas (Lembar)',
                format: '#,##0',
                alignment: 'right',
            },
            {
                dataField: 'brokerCode',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'brokerCode',
                    displayExpr: function (item) {
                        return item && item.brokerCode + " - " + item.brokerName;
                    },
                }
            },
            {
                dataField: 'priceType',
                caption: 'Harga',
            },
            {
                dataField: 'priceMin',
                caption: 'Harga Min',
                format: '#,##0.00',
                width: '100px',
                alignment: 'right',
            },
            {
                dataField: 'priceMax',
                caption: 'Harga Max',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
            },
        ]
    }

    render() {
        return (
            <DataGrid
                id={"successDataGrid"}
                showBorders={false}
                dataSource={this.dataSource}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                // height={100}
                showScrollbar={"always"}
            // columns={this.columns}
            >
                {
                    this.columns.map((column) => {
                        return <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            lookup={column.lookup}
                            cellRender={column.cellRender}
                            alignment={column.alignment || 'left'}
                            cssClass={column.cssClass}
                            format={column.format}
                            dataType={column.dataType}
                            width={column.width}
                            type={column.type}
                            buttons={column.buttons}
                            editorType={column.editorType}
                            editorOptions={column.editorOptions}
                            visible={column.visible}
                            sortOrder={column.sortOrder}
                            allowEditing={column.allowEditing}
                            calculateCellValue={column.calculateCellValue}
                        />
                    })
                }
                {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPelepasanSaham