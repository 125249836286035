import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { FileUploader, Box, ScrollView } from 'devextreme-react';
import { Item } from 'devextreme-react/accordion';
import httpRequest from 'plugin/httprequest';

class PembayaranMpSekaligusUploadSk extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.formItem = [
            {
                dataField: 'skNo',
                label: {
                    alignment: 'left',
                    text: 'No SK'
                },
            },
            {
                dataField: 'skDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal SK'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
                
            },
            // {
            //     dataField: 'jenisSk',
            //     label: {
            //         alignment: 'left',
            //         text: 'Jenis SK'
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //     dataSource: [
            //         {
            //             id: 1,
            //             value: 'Jenis 1'
            //         },
            //         {
            //             id: 2,
            //             value: 'Jenis 2'
            //         },
            //     ],
            //     valueExpr: "id", // contains the same values as the "statusId" field provides
            //     displayExpr: "value", // provides display values
            //     searchEnabled: true,
            //     deferRendering: false,
            //     width: '100%',
            //     }
            // },
            // {
            //     dataField: 'tanggalEfektif',
            //     label: {
            //         alignment: 'left',
            //         text: 'Tanggal Efektif'
            //     },
            //     editorType: 'dxDateBox',
            //     editorOptions: {
            //         useMaskBehavior: true,
            //         width: '100%',
            //     }
                
            // },
            
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async() => {
                        await this.submitData()
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
    }

    retrieveData = async(data) => {
        var loadAPI = 'pembayaran-mps'
        var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + data.pembayaranMpId, 'GET')
        
        this.setState({
            dataMaster: getPembayaranMp,
        })
    }

    submitData = async() => {
        var data = this.state.dataMaster

        var dataUpdate = {
            'skNo' : data.skNo,
            'skDate': data.skDate,
            'skFile': data.skFile,
            'skFileContentType': data.skFileContentType,
            'skFileName': data.skFileName,
            'skFileExtension': data.skFileExtension,
        }
        
        var loadAPI = 'pembayaran-mps'
        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
            key: data.id,
            values: dataUpdate
        },data)

        this.hide()
        let type = "success";
        let text = "Data Berhasil Di Unggah!";

        notify({message: text,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},type,500);
        this.props.forceRefresh()
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}`] : formattedFileData.base64data,
                        [`${fieldName}ContentType`] : formattedFileData.mime,
                        [`${fieldName}Name`]: formatNameAndExtention.name,
                        [`${fieldName}Extension`]: formatNameAndExtention.extention,
                    }
                }))
            }
            fr.readAsDataURL(files)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`]),this.state.dataMaster[`${fileName}Name`],this.state.dataMaster[`${fileName}Extension`])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Surat Keputusan Pembayaran'} 
                minWidth={500}
                minHeight={500}
                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                            />
                        </div>
                        <div className="col-md-12">
                            <Box 
                                direction={'row'}
                                width={'100%'}
                            >
                                <Item ratio={1}>
                                    <FileUploader 
                                        accept={'image/png, image/jpeg'} 
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)} 
                                        labelText="Upload File Here"
                                        showFileList={false}
                                        name={'skFile'}
                                    />
                                    <button onClick={() => this.downloadFile('skFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.skFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Download
                                    </button>
                                    <div className="text-center mt-5">
                                        <img className={`${this.state.dataMaster.skFile || this.state.dataMaster.skFileContentType ? '' : 'd-none'}`} src={this.state.dataMaster.skFile ? imageSource(this.state.dataMaster.skFileContentType, this.state.dataMaster.skFile) : ''} alt="Image View" width="30%"/>
                                    </div>
                                </Item>
                            </Box>
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default PembayaranMpSekaligusUploadSk