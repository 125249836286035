import React, { Component } from 'react'
import { Popup, ScrollView, Form } from 'devextreme-react'
import { akunInvestasi, efekAkunInvestasi } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';

import notify from 'devextreme/ui/notify';

class FormModalEditInstrumen extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupToolbarItem = [
            {
              widget: 'dxButton',
              location: 'after',
              options: {
                'text': 'Simpan',
                onClick: this.submitData.bind(this)
              },
              toolbar: 'bottom'
            },
            {
              widget: 'dxButton',
              location: 'after',
              options: {
                'text': 'Tutup',
                onClick: this.hide.bind(this)
              },
              toolbar: 'bottom'
            }
        ]

        this.formItems = [
            {
                itemType: 'group',
                items:[
                    {
                        dataField:'subName',
                        label:{
                            text:'Instrumen',
                            alignment:'left'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        itemType: 'group',
                        caption: 'AKUN ASET',
                        items:[
                            {
                                dataField:"akunId",
                                label:{
                                    text:"Investasi",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"mtmGlAkunId",
                                label:{
                                    text:"MTM",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"plAkunId",
                                label:{
                                    text:"Profil/Loss",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"unreliazedPlAkunId",
                                label:{
                                    text:"Unrealized PL",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"acrInstAkunId",
                                label:{
                                    text:"Accrued Pendapatan",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"amrAkunId",
                                label:{
                                    text:"Depresiasi",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"apresiasiAkunId",
                                label:{
                                    text:"Apresiasi",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"pendapatanAkunId",
                                label:{
                                    text:"Pendapatan Investasi",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"akunKpdId",
                                label:{
                                    text:"Penempatan KPD",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'AKUN EFEK',
                        items:[
                            {
                                dataField:"akunEfekBebasId",
                                label:{
                                    text:"Efek Bebas",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: efekAkunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"akunEfekJaminanId",
                                label:{
                                    text:"Jaminan",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: efekAkunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"akunEfekBankId",
                                label:{
                                    text:"Efek Bank",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: efekAkunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"akunEfekBaeId",
                                label:{
                                    text:"BAE",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: efekAkunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"akunEfekLppId",
                                label:{
                                    text:"LPP",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: efekAkunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"efekSerahLkpAkunId",
                                label:{
                                    text:"Serah LKP",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: efekAkunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"efekTerimaLkpAkunId",
                                label:{
                                    text:"Terima LKP",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: efekAkunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items:[
                    {
                        itemType: 'group',
                        caption: 'AKUN BIAYA',
                        items:[
                            {
                                dataField:"biayaBankAkunId",
                                label:{
                                    text:"Biaya Bank",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"komisiAkunId",
                                label:{
                                    text:"Komisi",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"levyAkunId",
                                label:{
                                    text:"Levy",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"biayaLainAkunId",
                                label:{
                                    text:"Biaya Lain Lain",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'AKUN AR/AP',
                        items:[
                            {
                                dataField:"arAkunId",
                                label:{
                                    text:"Akun AR",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"apAkunId",
                                label:{
                                    text:"Akun AP",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'AKUN PAJAK',
                        items:[
                            {
                                dataField:"vatAkunId",
                                label:{
                                    text:"PPN (vat)",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"ppnBmAkunId",
                                label:{
                                    text:"PPN BM",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"pphAkunId",
                                label:{
                                    text:"PPH",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"pbbAkunId",
                                label:{
                                    text:"PBB",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField:"whtAkunId",
                                label:{
                                    text:"WHT",
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: akunInvestasi(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'akunName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                ]
            },
        ]
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster:data
        })
    }

    submitData = async () => {
        try {
            var loadApi = `instrument-category-subs`
            var type = 'success'
            var text = 'Sukses Edit Instumen'
            var DataMaster = {...this.state.DataMaster}
            DataMaster.biayaInvestasiAkunId = DataMaster.biayaLainAkunId
            DataMaster.biayaNotarisAkunId = DataMaster.biayaLainAkunId
            DataMaster.biayaNotarisAkunId = DataMaster.biayaLainAkunId
            DataMaster.whtAkunId = DataMaster.pphAkunId
            
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadApi, 'PUT', {
                values: DataMaster,
                key:DataMaster.id
            },DataMaster)

            if (response) {
                notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                this.hide()
            }
        } catch (e) {
            console.log(e)
            notify({ message: "Something When Wrong's", width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }

    show() {
        this.setState({
          popupVisible: true,
        });
      }
    
    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
        });
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Edit Instrumen'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.DataMaster}
                                items={this.formItems}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalEditInstrumen