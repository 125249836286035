import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import { karyawanSk,jabatan,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, SKType, currency, SKTypeHRD, dasarNilai, tipePotongan } from 'dataSource/lookup';
import ModalEditGajiTunjanganPotongan from './editGajiTunjanganPotongan';
import ModalEditGajiPotongan from './editGajiPotongan';
import httpRequest from 'plugin/httprequest';
import FormModalPenyesuaianPotongan from '../gaji/formPotongan';
import FormModalPenyesuaianTunjangan from '../gaji/formTunjangan';

class GajiDaftarKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible:false,
            DataKaryawan:{
                currencyId: 1001
            }
        }

        this.dataTunjangan = []
        this.dataPotongan = []

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.prevTabIndex = 0
        this.PopupRef = React.createRef()
        this.showModalEditGajiTunjanganRef = React.createRef()
        this.showModalEditGajiPotonganRef = React.createRef()
        this.dataGridGajiTunjanganRef = React.createRef()
        this.dataGridGajiPotonganRef = React.createRef()
        this.showModalSetNominalPotonganRef = React.createRef()
        this.showModalSetNominalTunjanganRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.TabDataSource = [
            {
                id: 0,
                text: 'Daftar Tunjangan dan Gaji Pokok'
            },
            {
                id: 1,
                text: 'Daftar Potongan'
            },
        ] 

        this.dataKaryawan = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField:'nikPanjang',
                        label:{
                            alignment:"left",
                            text:'NIK'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:'fullName',
                        label:{
                            alignment:"left",
                            text:'Nama Karyawan'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'jabatanId',
                        label: {
                            text: 'Jabatan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            readOnly:true,
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                        },
                    },
                    {
                        dataField: 'gradeId',
                        label: {
                            alignment: 'left',
                            text: 'Grade'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly:true,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formRef.current.instance.updateData(
                                        {
                                            gajiMin:e.selectedItem.gajiMin,
                                            gajiMid:e.selectedItem.gajiMid,
                                            gajiMax:e.selectedItem.gajiMax,
                                        }
                                    )
                                }
                            },
                            dataSource: grade(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'gradeCode', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            
                        },
                    },
                    {
                        dataField: 'skKerjaSalaryId',
                        label: {
                            alignment: 'left',
                            text: 'No SK'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'skNo', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formRef.current.instance.updateData(
                                        {
                                            skId:e.selectedItem.id,
                                            skTypeId:e.selectedItem.skTypeId,
                                            skDate:e.selectedItem.skDate,
                                            efektifDate:e.selectedItem.skDate,
                                        }
                                    )
                                }
                            },
                        },
                    },
                    {
                        dataField:'gajiPokok',
                        label:{
                            alignment:"left",
                            text:'Gaji Pokok Saat Ini'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            readOnly:true,
                            format:'#,##0.00'
                        }
                    },
                    {
                        dataField:'phdp',
                        label:{
                            alignment:"left",
                            text:'PHDP Saat Ini'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            readOnly:true,
                            format:'#,##0.00'
                        }
                    },
                    {
                        dataField:'gajiPokokTunjanganTetap',
                        label:{
                            alignment:"left",
                            text:'Total Tunjangan Tetap'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            readOnly:true,
                            format:'#,##0.00'
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Currency'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // readOnly:true,
                            dataSource: currency(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'currencyName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField:'upahDasarBaru',
                        label:{
                            alignment:"left",
                            text:'Upah Dasar Baru'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0.00',
                            onValueChanged:(e) => {
                                var gajiPokok = e.value + this.state.DataKaryawan.intensifBaru
                                this.formRef.current.instance.updateData({gajiPokokBaru:gajiPokok})
                            }
                        }
                    },
                    {
                        dataField:'intensifBaru',
                        label:{
                            alignment:"left",
                            text:'Intensif Baru'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0.00',
                            onValueChanged:(e) => {
                                var gajiPokok = this.state.DataKaryawan.upahDasarBaru + e.value
                                this.formRef.current.instance.updateData({gajiPokokBaru:gajiPokok})
                            }
                        }
                    },
                    {
                        dataField:'gajiPokokBaru',
                        label:{
                            alignment:"left",
                            text:'Gaji Pokok Baru'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0.00',
                            readOnly:true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Gaji pokok Baru tidak boleh kosong'
                            },
                            {
                                type:'range',
                                min:1,
                                message: 'Gaji pokok Baru tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField:'phdpBaru',
                        label:{
                            alignment:"left",
                            text:'PHDP Baru'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0.00'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption:'Informasi Grade',
                        items: [
                            {
                                dataField: 'gradeId',
                                label: {
                                    alignment: 'left',
                                    text: 'Grade'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly:true,
                                    dataSource: grade(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'gradeCode', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField:'gajiMin',
                                label:{
                                    alignment:"left",
                                    text:'Minimum'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    readOnly:true,
                                    format:'#,##0.00'
                                }
                            },
                            {
                                dataField:'gajiMid',
                                label:{
                                    alignment:"left",
                                    text:'Midle'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    readOnly:true,
                                    format:'#,##0.00'
                                }
                            },
                            {
                                dataField:'gajiMax',
                                label:{
                                    alignment:"left",
                                    text:'Maximum'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    readOnly:true,
                                    format:'#,##0.00'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption:'Informasi SK',
                        items: [
                            {
                                dataField: 'skId',
                                label: {
                                    alignment: 'left',
                                    text: 'No SK'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly:true,
                                    dataSource: karyawanSk(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'skNo', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField: 'skTypeId',
                                label: {
                                    alignment: 'left',
                                    text: 'SK Tipe'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly:true,
                                    dataSource: SKTypeHRD(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'typeName' 
                                }
                            },
                            {
                                dataField: 'skDate',
                                label: {
                                    alignment: 'left',
                                    text: 'SK Tanggal',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    readOnly:true,
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'efektifDate',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Berlaku',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    readOnly:true,
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                        ]
                    },
                ]
            }
        ]

        this.columnTunjangan = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Set Nominal',
                        hint: 'Set Nominal',
                        onClick: (e) => {
                            if (e.row.data.dasarNilai == 'Nominal') {
                                this.showModalSetNominalTunjangan(e)
                            }else{
                                notify({ message: 'Tidak Dapat di Set Nominal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        }
                    },
                ]
            },
            {
                dataField:"idsTunjanganType",
                caption:"Tunjangan ID", 
            },
            {
                dataField: 'groupName',
                caption: 'Group',
            },
            {
                dataField: 'tunjanganName',
                caption: 'Tunjangan',
            },
            {
                dataField: 'dasarNilai',
                caption: 'Dasar Nilai',
            },
            {
                dataField: 'persen',
                caption: 'Persen (%)',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'nilaiTunjangan',
                caption: 'Nominal',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'tunjanganTetap',
                caption: 'Tunjangan Tetap',
            },
        ]

        this.columnPinjaman = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Set Nominal',
                        hint: 'Set Nominal',
                        onClick: (e) => {
                            if (e.row.data.dasarNilai == 'Nominal') {
                                this.showModalSetNominalPotongan(e)
                                console.log(e);
                            }else{
                                notify({ message: 'Tidak Dapat di Set Nominal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                            // this.showModalEdit(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField:"ids",
                caption:"Potongan ID",
            },
            {
                dataField: 'potonganTypeId',
                caption: 'Tipe Potongan',
                lookup: {
                    dataSource: tipePotongan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'potonganCode'
                }
            },
            {
                dataField: 'groupName',
                caption: 'Group',
            },
            {
                dataField:"potonganName",
                caption:"Potongan", 
            },
            {
                dataField: 'dasarNilai',
                caption: 'Dasar Nilai',
            },
            {
                dataField: 'persen',
                caption: 'Persen (%)',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'nilaiPotongan',
                caption: 'Nominal',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'potonganAmount',
                caption: 'Potongan Amount',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
        ]

        this.sumGaji = [
            {
                showInColumn: 'dasarNilai',
                displayFormat: 'TOTAL'
            },
            {
                column: 'nominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominal',
                displayFormat: '{0}'
            },
        ]

        this.sumPotongan = [
            {
                showInColumn: 'dasarNilai',
                displayFormat: 'TOTAL'
            },
            {
                column: 'nominalPot',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalPot',
                displayFormat: '{0}'
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.dataTunjangan = []
        this.dataPotongan = []
        this.dataGridGajiTunjanganRef.current.forceRefresh(true)
        this.dataGridGajiPotonganRef.current.forceRefresh(true)
        this.setState({
            popupVisible: false,
            DataKaryawan:{
                currencyId: 1001
            }
        })
    }

    getKaryawanSk = async () => {
        var getKaryawanSks = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-sks/getAllByKaryawanId/${this.state.DataKaryawan.id}`, 'GET')
        getKaryawanSks = getKaryawanSks.filter(value => value.skTypeId == 1)
        
        
        this.formRef.current.instance.getEditor('skKerjaSalaryId').option('dataSource', getKaryawanSks)
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async() => {
        try {
            var loadAPI = `edit-tunjangan-and-potongan`
            var DataKaryawan = {...this.state.DataKaryawan}
            var data = {}

            var dataListTunjangan = this.dataGridGajiTunjanganRef.current.getDataSource()
            var dataListPotongan = this.dataGridGajiPotonganRef.current.getDataSource()
            var listTunjangan = []
            var listPotongan = []
            
            for(var listDataTunj of dataListTunjangan){
                listDataTunj.karyawanId = DataKaryawan.id
                listDataTunj.nominal = listDataTunj.nilaiTunjangan
                listDataTunj.tunjanganTypeId = listDataTunj.idsTunjanganType
                listTunjangan.push(listDataTunj)
            }

            for(var listDataPot of dataListPotongan){
                listDataPot.karyawanId = DataKaryawan.id
                listDataPot.nominal = listDataPot.nilaiPotongan
                listDataPot.potonganTypeId = listDataPot.ids
                listPotongan.push(listDataPot)
            }
            data.currencyId = DataKaryawan.gajiCurrencyId
            data.gajiPokok = DataKaryawan.gajiPokokBaru
            data.totalIuranPensiun = DataKaryawan.iuranPensiun
            data.totalTunjanganTetap = 0
            data.nik = DataKaryawan.nik
            data.karyawanName = DataKaryawan.fullName
            data.jabatanId = DataKaryawan.jabatanId
            data.gradeId = DataKaryawan.gradeId
            data.nominalGajiPokok = DataKaryawan.gajiPokokBaru
            data.gajiBruto = 0
            data.gajiBrutoPajak = 0
            data.pphTetap = 0
            data.ptkpNominal = 0
            data.statusKepegawaianId = DataKaryawan.statusKepegawaianId
            data.totalIuran = DataKaryawan.iuranPensiun
            data.karyawanId = DataKaryawan.id
            data.tunjanganDetailList = listTunjangan
            data.potonganDetailList = listPotongan
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
                values: data,
            })

            if (response) {
                this.hide()
                notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    retrieveData = async(data) => {
        this.setState({
            DataKaryawan: data,
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindexGaji-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexGaji-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    // TUNJANGAN
    showModalSetNominalTunjangan = (e) => {
        var data = {...this.state.DataKaryawan}
        var DataMaster = {}

        DataMaster.nik = data.nik
        DataMaster.karyawanId = data.id
        DataMaster.nominalGajiPokok = data.gajiPokokBaru
        if (e.row) {
            this.showModalSetNominalTunjanganRef.current.retrieveData(e.row.data, DataMaster)
        }
        this.showModalSetNominalTunjanganRef.current.show()
    }

    onToolbarPreparingTunj = (e) => {
        e.toolbarOptions.items.unshift(
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'refresh',
            //         text:'Penyesuaian',
            //         onClick: (e) => {
            //             var formValidation = this.validate()
            //             if (formValidation) {
            //                 this.penyesuaianTunjangan()
            //             }
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    text:'Load Tunjangan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.loadTunjangan(e)
                        }
                    },
                }
            },
        )
    }

    showModalEditGajiTunjangan = (e) => {
        if (e.row) {
            this.showModalEditGajiTunjanganRef.current.retrieveData(e.row.data)
        }
        this.showModalEditGajiTunjanganRef.current.show()
    }

    loadTunjangan = async(e) => {
        var DataMaster = this.state.DataKaryawan
        
        var postAPI = `list-tunjangans/getPenyesuaianTunjanganByKarywanIdAndGajiPokokAndTunjanganTetapAndStatusKepegawaianId/${DataMaster.id}/${DataMaster.gajiPokokBaru}/${0}/${DataMaster.statusKepegawaianId}`
        var getListTunjangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'GET')
        this.dataTunjangan = getListTunjangan

        this.dataGridGajiTunjanganRef.current.forceRefresh(true)
    }

    forceRefreshTunjangan = () => {
        this.dataGridGajiTunjanganRef.current.forceRefresh()
    }

    loadDataTunjangan = () => {
        return this.dataTunjangan
    }

    getDataSourceTunj = (data) => {
        try{
            var key = data.idsTunjanganType

            if(key){
                this.dataGridGajiTunjanganRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.dataGridGajiTunjanganRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    // POTONGAN

    showModalSetNominalPotongan = (e) => {
        var data = {...this.state.DataKaryawan}
        var DataMaster = {}

        DataMaster.nik = data.nik
        DataMaster.karyawanId = data.id
        DataMaster.nominalGajiPokok = data.gajiPokokBaru
        if (e.row) {
            this.showModalSetNominalPotonganRef.current.retrieveData(e.row.data, DataMaster)
        }
        this.showModalSetNominalPotonganRef.current.show()
    }

    showModalEditGajiPotongan = (e) => {
        if (e.row) {
            this.showModalEditGajiPotonganRef.current.retrieveData(e.row.data)
        }
        this.showModalEditGajiPotonganRef.current.show()
    }

    onToolbarPreparingPot = (e) => {
        e.toolbarOptions.items.unshift(
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'refresh',
            //         text:'Penyesuaian',
            //         onClick: (e) => {
            //             // this.showModalSetNominalPotongan(e)
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    text:'Load Potongan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.loadPotongan(e)
                        }
                    },
                }
            }
        )
    }

    loadPotongan = async () => {
        var DataMaster = this.state.DataKaryawan
         
        var postAPI = `list-potongan/getPenyesuaianPotonganByKaryawanIdAndstatusKepegawaianId/${DataMaster.id}/${DataMaster.statusKepegawaianId}`
        var getListPotongan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'GET')
        this.dataPotongan = getListPotongan

        this.dataGridGajiPotonganRef.current.forceRefresh(true)
    }

    forceRefreshPotongan = () => {
        this.dataGridGajiPotonganRef.current.forceRefresh()
    }

    loadDataPotongan = () => {
        return this.dataPotongan
    }

    getDataSourcePot = (data) => {
        try{
            var key = data.ids

            if(key){
                this.dataGridGajiPotonganRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Potongan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.dataGridGajiPotonganRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Potongan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Data Gaji Karyawan"}
                width={"75vw"}
                height={"95vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{height: '100%'}}>
                        <div className="my-3">
                            <Form
                                ref={this.formRef}
                                colCount={2}
                                id={'formPemberiKerja'}
                                formData={this.state.DataKaryawan}
                                items={this.dataKaryawan}
                                labelLocation={'left'}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                    <div id="tabindexGaji-0" className={' mt-2 col-md-12 text-center' }>
                        <DevExpressDataGrid
                            ref={this.dataGridGajiTunjanganRef}
                            loadAPI='gajis'
                            insertAPI='gajis'
                            updateAPI='gajis'
                            deleteAPI='gajis' 

                            backendserver={process.env.REACT_APP_BACKEND_ACC}

                            useArraySource={true}
                            ArraySourceData={this.loadDataTunjangan}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            keyField={'idsTunjanganType'}
                            exportExcel={false}
                            exportFileName={"Jurnal Manual"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Jurnal Manual Data'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            height={320}

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columnTunjangan} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}
                            onToolbarPreparing = {this.onToolbarPreparingTunj}
                            // summaryTotalItem={this.sumGaji}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                        <FormModalPenyesuaianTunjangan
                            ref={this.showModalSetNominalTunjanganRef}
                            store={this.props.store}
                            getDataSourceTunj = {this.getDataSourceTunj}
                        />
                    </div>
                    <div id="tabindexGaji-1" className={'d-none mt-2 col-md-12 text-center' }>
                        <DevExpressDataGrid
                            ref={this.dataGridGajiPotonganRef}
                            loadAPI='pinjamans'
                            insertAPI='pinjamans'
                            updateAPI='pinjamans'
                            deleteAPI='pinjamans' 

                            backendserver={process.env.REACT_APP_BACKEND_ACC}

                            useArraySource={true}
                            ArraySourceData={this.loadDataPotongan}

                            keyField={'ids'}
                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={false}
                            exportFileName={"Jurnal Manual"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Jurnal Manual Data'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            height={320}

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columnPinjaman} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}
                            onToolbarPreparing = {this.onToolbarPreparingPot}

                            // summaryTotalItem={this.sumPotongan}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                        <FormModalPenyesuaianPotongan
                            ref={this.showModalSetNominalPotonganRef}
                            store={this.props.store}
                            getDataSourcePot={this.getDataSourcePot}
                        />
                    </div>
                </div>
            </div>
            </ScrollView>
        </Popup>
        )
    }
}

export default GajiDaftarKaryawan
