import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { programs } from "dataSource/lookup";
import { showLoading } from "redux/actions/loading";

class ChooseProgramAset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {}
        };

        this.columns = [
            {
                dataField: 'programId',
                label: {
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
        ];


        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.formRef = React.createRef()
    }

    submitData = async () => {
        this.props.getProgramId(this.state.formData.programId)
        this.hide()
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };


    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Pilih Program"}
                    width={"30vw"}
                    height={"20vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form
                        colCount={1}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.columns}
                        ref={this.formRef}
                        scrollingEnabled={true}
                        labelLocation="left"
                    />

                </Popup>
            </React.Fragment>

        );
    }
}

export default ChooseProgramAset;