import React, { Component } from 'react'
import { currency, banks, orders, product, instrumentCategorySubs } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import uuidv4 from 'uuid/v4'
import DetailPenempatanSbn from './detail'
import { data } from 'dataSource/json/auctionBuybackCash'
import FormPengajuanPenempatanSbn from 'components/pages/modal/investasi/pengajuan/sbn/penempatan/form'
import httpRequest from 'plugin/httprequest'
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify'
import { compactNumber, formatDefaultFullDate, formatNumber, formatNumberAum, inputToTaskInvestasi, numberToIndo, PrintElem, stripHtml } from 'plugin/helper'
import reportRequest, { reportFile } from 'plugin/reportRequest'

class PengajuanPenempatanSbn extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridSbnRef = React.createRef()
        this.formPengajuanPenempatanSbnRef = React.createRef()
        this.formPengajuanPenempatanSbnEditRef = React.createRef()
        this.formPersetujuanPenempatanSbnRef = React.createRef()
        this.currentData = []
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                this.showModalEdit(data, 'edit')
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Cetak',
                        onClick: async (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Belum terkirim!',
                                    message: 'Data belum terkirim tidak bisa cetak surat!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakSurat(data)
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Batalkan draft',
                                    message: 'Apakah anda yakin ingin membatalkan draft ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'requestNo',
                caption: 'Nomor Pengajuan',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.requestNo == null) {
                        return 'DRAFT'
                    } else {
                        return data.requestNo
                    }
                }
            },
            {
                dataField: 'requestDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'orderDate',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'productCode',
                caption: 'Produk',
            },
            {
                dataField: 'categoryName',
                caption: 'Kategori',
            },
            {
                dataField: 'totalInstrument',
                caption: 'Jumlah Instrument',
                alignment: 'right'
            },
            {
                dataField: 'totalFaceValue',
                caption: 'Nilai Penempatan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Persetujuan',
                lookup: {
                    dataSource: [
                        {
                            id: 'DRAFT',
                            value: 'Tersimpan'
                        },
                        {
                            id: 'INPROGRESS',
                            value: 'Sedang proses persetujuan'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
        this.instrumentData = []
        this.brokerData = []
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments?size=999`, 'GET')
        var getBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `brokers`, 'GET')
        this.instrumentData = getInstrument
        this.brokerData = getBroker
    }
    cetakSurat = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-obligasi/${data.requestId}`, 'GET')
        var dirKeuangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/6`, 'GET')
        var dirOperasional = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/79506`, 'GET')
        var dirut = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5`, 'GET')
        var komite = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5351`, 'GET')
        let transaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-transaction-types/${response.orderObligasiInfos[0].orderDTO.orderTypeId}`, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = stripHtml(atob(dataMaster.kajian)) == 'undefined' ? ' ' : stripHtml(atob(dataMaster.kajian))

        if (dirKeuangan.length > 0) {
            dirKeuangan = dirKeuangan[0]
        } else {
            dirKeuangan = {}
        }

        if (dirOperasional.length > 0) {
            dirOperasional = dirOperasional[0]
        } else {
            dirOperasional = {}
        }

        if (dirut.length > 0) {
            dirut = dirut[0]
        } else {
            dirut = {}
        }

        if (komite.length > 0) {
            komite = komite[0]
        } else {
            komite = {}
        }

        var dataSbn = []
        var dataJumlah = []
        var no = 0

        for (var sbn of response.orderObligasiInfos) {
            var sbnInfo = {
                no: ++no,
                instrument: sbn.instrumentObligasiInfo.categoryName,
                instrumentCode: sbn.instrumentObligasiInfo.instrumentCode,
                hargaBeli: sbn.orderSbnBondSukukDTO.price,
                rate: sbn.instrumentObligasiInfo.rate,
                accruedInterest: sbn.orderSbnBondSukukDTO.accruedInterest,
                jumlahNilai: compactNumber(sbn.orderSbnBondSukukDTO.faceValue),
            }
            dataSbn.push(sbnInfo)
            dataJumlah.push(sbn.orderSbnBondSukukDTO.faceValue)
        }
        var data = {
            template: {
                'shortid': 'NKy6g~M',
                'recipe': 'chrome-pdf'
            },
            data: {
                "requestNo": data.requestNo,
                "orderDate": formatDefaultFullDate(dataMaster.requestDate),
                "transactionName": transaction.transactionName,
                "instrumentCode": response.orderObligasiInfos[0].instrumentObligasiInfo.instrumentCode,
                "brokerName": this.brokerData.find(val => val.id == response.orderObligasiInfos[0].orderSbnBondSukukDTO.brokerId).brokerName,
                "dataSbn": dataSbn,
                "totalJumlahNilai": compactNumber(dataJumlah.reduce((a, b) => a + b, 0)),
                "kajian": kajian,
                "dirKeuangan": {
                    "fullName": dirKeuangan.fullName,
                    "jabatan": 'Direktur Keuangan'
                },
                "dirOperasional": {
                    "fullName": dirOperasional.fullName,
                    "jabatan": 'Direktur Operasional'
                },
                "dirut": {
                    "fullName": dirut.fullName,
                    "jabatan": 'Direktur Utama'
                },
                "dirInvest": {
                    "fullName": komite.fullName,
                    "jabatan": 'Direktur Investasi'
                }
            },
            options: {
                reportName: "UPTI-" + data.requestNo
            }
        }
        reportFile(data, true, `UPTI-${dataMaster.requestNo}`)
        // var print = await reportRequest(this.props.store, data)
        // PrintElem(print.data)
        // return print
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Penempatan',
                    onClick: (e) => {
                        this.showModalInsert('add')
                    },
                }
            }
        )
    }
    kirimData = async (data) => {
        var loadAPI = `order-requests-obligasi/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = dataMaster.kajian
        var content = dataMaster.kajianContentType
        var dataDetail = []
        data = {
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalPembelian: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            requestOrderId: dataMaster.id,
            requestNo: '<AUTO>',
            kajian: kajian,
            kajianContentType: content,
            categoryName: 'SBN/SBSN',
            draft: false,
        }

        for (var obligasi of response.orderObligasiInfos) {
            var obligasiInfo = {
                id: obligasi.orderSbnBondSukukDTO.id,
                bankId: obligasi.orderSbnBondSukukDTO.bankId,
                brokerId: obligasi.orderSbnBondSukukDTO.brokerId,
                currencyId: obligasi.orderSbnBondSukukDTO.currencyId,
                currencyRate: obligasi.orderSbnBondSukukDTO.currencyRate,
                faceValue: obligasi.orderSbnBondSukukDTO.faceValue,
                accruedInterest: obligasi.orderSbnBondSukukDTO.accruedInterest,
                htm: obligasi.orderSbnBondSukukDTO.htm,
                instSubCategoryId: obligasi.orderDTO.categorySubId,
                institusiOrderId: obligasi.orderSbnBondSukukDTO.institusiOrderId,
                instrumentId: obligasi.orderSbnBondSukukDTO.instrumentId,
                kpdId: 1,
                orderId: obligasi.orderDTO.id,
                price: obligasi.orderSbnBondSukukDTO.price,
                rekBankId: obligasi.orderSbnBondSukukDTO.rekBankId,
                tanggalPembelian: obligasi.orderDTO.orderDate,
            }
            dataDetail.push(obligasiInfo)
        }
        var submitData = {
            ...data,
            instrumentObligasiList: dataDetail
        }

        try {
            let orderRequestId = response.orderObligasiInfos[0].orderDTO.orderRequestId
            let orderTypeId = response.orderObligasiInfos[0].orderDTO.orderTypeId

            await inputToTaskInvestasi(this.props.store, orderRequestId, orderTypeId)
        } catch (error) {
            console.log(error);
        }

        if (submitData) {
            try {
                var insertAPI = 'order-sbns/pengajuan-penempatan-sbn'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Pengiriman berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    showModalInsert = (type) => {
        if(type === 'add') {
            this.formPengajuanPenempatanSbnRef.current.show()
            this.formPengajuanPenempatanSbnRef.current.retrieveData()
        }
    }
    showModalEdit = async (data, type) => {
        var loadAPI = `order-requests-obligasi/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = atob(dataMaster.kajian)
        var dataDetail = []
        data = {
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalPembelian: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            orderTypeId: response.orderObligasiInfos[0].orderDTO.orderTypeId,
            requestId: dataMaster.id,
            noPengajuan: '<AUTO>',
            kategori: 'SBN/SBSN',
            actionType: 'edit'
        }

        for (var obligasi of response.orderObligasiInfos) {
            var obligasiInfo = {
                id: obligasi.orderSbnBondSukukDTO.id,
                bankId: obligasi.orderSbnBondSukukDTO.bankId,
                brokerId: obligasi.orderSbnBondSukukDTO.brokerId,
                currencyId: obligasi.orderSbnBondSukukDTO.currencyId,
                currencyRate: obligasi.orderSbnBondSukukDTO.currencyRate,
                faceValue: obligasi.orderSbnBondSukukDTO.faceValue,
                accruedInterest: obligasi.orderSbnBondSukukDTO.accruedInterest,
                htm: obligasi.orderSbnBondSukukDTO.htm,
                instSubCategoryId: obligasi.orderDTO.categorySubId,
                institusiOrderId: obligasi.orderSbnBondSukukDTO.institusiOrderId,
                instrumentId: obligasi.orderSbnBondSukukDTO.instrumentId,
                kpdId: 1,
                orderId: obligasi.orderDTO.id,
                price: obligasi.orderSbnBondSukukDTO.price,
                rekBankId: obligasi.orderSbnBondSukukDTO.rekBankId,
                tanggalPembelian: obligasi.orderDTO.orderDate,
                ratingId: obligasi.instrumentObligasiInfo.ratingId,
                maturityDate: obligasi.instrumentObligasiInfo.maturityDate,
                rate: obligasi.instrumentObligasiInfo.rate,
            }
            dataDetail.push(obligasiInfo)
        }
        if(type === 'edit') {
            this.formPengajuanPenempatanSbnEditRef.current.show()
            this.formPengajuanPenempatanSbnEditRef.current.retrieveData(data, dataDetail, kajian)
        }
    }
    forceRefresh = () => {
        this.DataGridSbnRef.current.forceRefresh(true)
    }
    cancelData = async (data) => {
        var reqId = data.requestId
        if (reqId) {
            try {
                var insertAPI = `order-request-batal?orderRequestId=${reqId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: {}
                })
                notify({ message: 'Pembatalan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            }
        }
    }
    showModalView = (data) => {
        var dataMaster = { ...data }
        var dataPembelian = dataMaster.detail
        delete dataMaster.detail

        this.formPengajuanPenempatanSbnRef.current.show(dataMaster, dataPembelian)
    }
    customDetailElem = (data) => {
        return (
            <DetailPenempatanSbn detailData={data.requestId} store={this.props.store} />
        )
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Usulan Penempatan Investasi SBN</h2>
                <DevExpressDataGrid
                    keyField='requestId'
                    ref={this.DataGridSbnRef}
                    loadAPI='order-requests-sbn-penempatan/list?status=DRAFT&status=INPROGRESS'
                    insertAPI='order-requests'
                    updateAPI='order-requests'
                    deleteAPI='order-requests'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"SBN"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'SBN'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPenempatanSbn
                    ref={this.formPengajuanPenempatanSbnRef}
                    store={this.props.store}
                    actionType={'add'}
                    forceRefresh={this.forceRefresh}
                />
                <FormPengajuanPenempatanSbn
                    ref={this.formPengajuanPenempatanSbnEditRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PengajuanPenempatanSbn