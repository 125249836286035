import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { product } from 'dataSource/lookup';
import { FileUploader, ScrollView } from 'devextreme-react';
import { download, formatDefaultMonthYear, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import ModalPreviewImage from '../../compPeserta/previewImage';
import ModalPreviewPdf from '../../compPeserta/previewPdf';
import { alert } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class ModalEditMpBerkala extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            formData: {
                skFileName: '',
                skFile: '',
                skFileContentType: ''
            },
            getUrl: `view-kenaikan-mp-berkala-detils?kenaikanBerkalaMpId.in=0`
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                },
            },
            {
                dataField: 'pengajuanDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    readOnly: true
                }
            }, 
            {
                dataField: 'kenaikanPeriode',
                label: {
                    alignment: 'left',
                    text: 'Kenaikan Periode'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    displayFormat: 'MMM yyyy',
                    calendarOptions: {
                        maxZoomLevel: 'year'
                    },
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: true
                }
            },
            {
                dataField: 'persenKenaikan',
                label: {
                    alignment: 'left',
                    text: 'Persentase Kenaikan'
                },
                // editorType: 'dxNumberBox',
                editorOptions: {
                    // format: '#,##0',
                    readOnly: true,
                } 
            },
            {
                dataField: 'skNo',
                label: {
                    alignment: 'left',
                    text: 'No SK Pengkinian',
                },
                editorOptions: {
                    readOnly: true
                }
            },
        ]

        this.detailColumns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'namaPeserta',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'namaPenerima',
                caption: 'Nama Penerima',
            },
            {
                dataField: 'mpBulananSebelumKenaikan',
                caption: 'MP Bulanan Sebelum Kenaikan',
                alignment: 'right',
                format: '#,##0.00'
            },
            // {
            //     dataField: 'mpBulananPesertaSebelum',
            //     caption: 'Mp Bulanan Peserta Sebelum Kenaikan',
            //     alignment: 'right',
            //     format: "#,##0",
            // },
            // {
            //     dataField: 'mpBulananJadudSebelum',
            //     caption: 'Mp Bulanan Jadud Sebelum Kenaikan',
            //     alignment: 'right',
            //     format: "#,##0",
            // },
            // {
            //     dataField: 'mpBulananAnakSebelum',
            //     caption: 'Mp Bulanan Anak Sebelum Kenaikan',
            //     alignment: 'right',
            //     format: "#,##0",
            // },
            {
                dataField: 'mpSetelahKenaikan',
                caption: 'Mp Bulanan Setelah Kenaikan',
                alignment: 'right',
                format: '#,##0.00'
            },
            // {
            //     dataField: 'mpBulananPeserta',
            //     caption: 'Mp Bulanan Peserta',
            //     alignment: 'right',
            //     format: "#,##0",
            // },
            // {
            //     dataField: 'mpBulananJadudSesudah',
            //     caption: 'Mp Bulanan Jadud Sesudah Kenaikan',
            //     alignment: 'right',
            //     format: "#,##0",
            // },
            // {
            //     dataField: 'mpBulananAnakSesudah',
            //     caption: 'Mp Bulanan Anak Sesudah Kenaikan',
            //     alignment: 'right',
            //     format: "#,##0",
            // }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,         
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    formData: {
                        ...prevState.formData,
                        [`${fieldName}Name`]: files.name,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime
                    }
                }))


            }
            
            fr.readAsDataURL(files)
        }
    }

    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            var src = imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    retrieveData = (data) => {
        let persenKenaikan = data.persenKenaikan;
        let intValue = parseInt(persenKenaikan);

        let formattedPercentage = (intValue / 100).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 });
        
        data.persenKenaikan = formattedPercentage
        data.kenaikanPeriode = data.periodeTahun + '-' + data.periodeBulan
        data.kenaikanPeriodeText = formatDefaultMonthYear(new Date(data.kenaikanPeriode))
        this.setState({
            formData: data,
            getUrl: `view-kenaikan-mp-berkala-detils?kenaikanBerkalaMpId.in=${data.id}`
        })
    }
    downloadFile = (fileName) => {
        download(imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`]),'Sk-File')
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Persentase Kenaikan MP Berkala'} 
                minWidth={500}
                minHeight={300}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height="100&" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataEditMpBerkala'}
                                formData={this.state.formData}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation = 'left'
                                readOnly = {this.props.actionType == 'view' ? true : false}
                            />
                        </div>
                        {/* <div className="col-md-6 mt-5">
                            <FileUploader
                                uploadMode={'useForm'}
                                onValueChanged={this.onUploadStarted.bind(this)} 
                                labelText={this.state.formData.skFileName  || 'File Dokumen SK'}
                                showFileList={false}
                                name={'skFile'}
                                disabled = {this.props.actionType == 'view' ? true : false}
                            />
                        </div> */}
                        <div className="text-right mt-5">
                            <button onClick={() => this.previewFile('skFile')} className={`btn btn-light btn-sm border rounded ${this.state.formData.skFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                            <button onClick={() => this.downloadFile('skFile')} className={`btn btn-light btn-sm border rounded ${this.state.formData.skFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Download
                            </button>
                        </div>
                        <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='pembayaran-mp-detils'
                                updateAPI='pembayaran-mp-detils'
                                deleteAPI='pembayaran-mp-detils' 
                                
                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}
                                
                                remoteOperations={true}
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={`Kenaikan MP Berkala Periode ${this.state.formData.kenaikanPeriodeText}`}
                                allowExportSelectedData={true}
                                // selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={`Kenaikan MP Berkala Periode ${this.state.formData.kenaikanPeriodeText}`}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter
                                height={'calc(65vh - 150px)'}
                                
                                ColumnConfiguration={this.detailColumns} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        );
    }
}


export default ModalEditMpBerkala