import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { alert, confirm } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { asetKategoriSdm, programs, programSdm, sdmSupplier, subAsetKategoriSdm, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalUmumPembelianPemesanan from 'components/pages/modal/sdm-umum/umum/pembelian/pemesanan/index';
import { showLoading } from "redux/actions/loading";
import { formatDefaultFullDate, formatNumberAum } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class UmumPembelianPemesanan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                dataField: 'noPembelian',
                caption: 'Nomor Pemesanan'
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                }
            },
            {
                dataField: 'namaAset',
                caption: 'Nama Aset'
            },
            {
                dataField: 'tanggalPembelian',
                caption: 'Tanggal Pemesanan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'supplierId',
                caption: 'Supplier',
                lookup: {
                    dataSource: sdmSupplier(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: (item) => {
                        return item && item.kodeSupplier + ' - ' + item.namaSupplier
                    }, // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'type',
                caption: 'Tipe',
                lookup: {
                    dataSource: [
                        {
                            id: 'B',
                            value: 'Pembelian'
                        },
                        {
                            id: 'C',
                            value: 'Batal Pembelian'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'O',
                            value: 'Open'
                        },
                        {
                            id: 'P',
                            value: 'Proses'
                        },
                        {
                            id: 'S',
                            value: 'Sukses'
                        },
                        {
                            id: 'C',
                            value: 'Dibatalkan'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'asetBarangJasa',
                caption: 'Aset/Barang/Jasa',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'jumlah',
                        caption: 'Jumlah',
                        type: 'number',
                        format: '#,##0'
                    },
                    {
                        dataField: 'harga',
                        caption: 'Harga/Unit',
                        type: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'ppn',
                        caption: 'PPN',
                        type: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'biayaTransaksi',
                        caption: 'Biaya Transaksi',
                        type: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'total',
                caption: 'Total',
                type: 'number',
                format: '#,##0.00'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        if (this.props.type === 'pemesanan') {
            this.columns = [
                {
                    type: 'buttons',
                    buttons: [
                        {
                            text: 'Lihat',
                            hint: 'Lihat',
                            visible: true,
                            disabled: true,
                            cssClass: 'text-primary',
                            onClick: (e) => {
                                this.showModal(e.row.data, 'view')
                            },
                        },
                        {
                            text: 'Ubah',
                            hint: 'Ubah',
                            visible: true,
                            disabled: true,
                            cssClass: 'text-primary',
                            onClick: async (e) => {
                                if (e.row.data.status == "P") {
                                    await alert('Data sedang dalam proses !', 'Error')
                                } else {
                                    this.showModal(e.row.data, 'edit')
                                }
                            },
                        },
                        {
                            text: 'Batalkan',
                            hint: 'Batalkan',
                            visible: true,
                            disabled: true,
                            cssClass: 'text-primary',
                            onClick: async (e) => {
                                if (e.row.data.status == "P") {
                                    await alert('Data sedang dalam proses !', 'Error')
                                } else {
                                    this.batal(e.row.data)
                                }
                            },
                        },
                        {
                            text: 'Kirim Instruksi Bayar',
                            hint: 'Kirim Instruksi Bayar',
                            cssClass: 'text-success',
                            onClick: (e) => {
                                this.kirim(e.row.data)
                            }
                        },
                    ],
                },
                ...this.columns
            ]
        } else {
            this.columns = [
                {
                    type: 'buttons',
                    buttons: [
                        {
                            text: 'Lihat',
                            hint: 'Lihat',
                            cssClass: 'text-primary',
                            onClick: (e) => {
                                this.showModal(e.row.data, 'view')
                            }
                        },
                    ],
                },
                ...this.columns
            ]
        }
    }

    showModal = (data, type) => {
        this.modalRef.current.show(type)
        if (type !== 'add') {
            this.modalRef.current.retrieveData(data)
        }
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-asset-pembelians?size=9999');

        for (let value of response){
            let dataProgramId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `programs/${value.programId}`, 'GET');

            let dataValue = {
                noPembelian: value.noPembelian,
                programId: dataProgramId.program,
                namaAset: value.namaAset,
                tanggalPembelian: value.tanggalPembelian ? formatDefaultFullDate(value.tanggalPembelian) : '',
                supplierId: value.supplierId,
                type: value.type === 'B' ? 'Pembelian' : value.type === 'C' ? 'Batal Pembelian' : '',
                status: value.status === 'O' ? 'Open' : value.status === 'P' ? 'Proses' : value.status === 'S' ? 'Sukses' : value.status === 'C' ? 'Dibatalkan' : '',
                jumlah: value.jumlah ? value.jumlah : 0,
                harga: value.harga ? formatNumberAum(value.harga) : 0,
                ppn: value.ppn ? formatNumberAum(value.ppn) : 0,
                biayaTransaksi: value.biayaTransaksi ? formatNumberAum(value.biayaTransaksi) : 0,
                total: value.total ? formatNumberAum(value.total) : 0 
            };

            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }
        
        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR PEMBELIAN PEMESANAN');
            worksheet.addTable({
              name: 'MyTable',
              ref: `A${firstTableRowNum}`,
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'Nama Pemesanan'},
                {name: 'Program'},
                {name: 'Nama Aset'},
                {name: 'Tanggal Pemesanan'},
                {name: 'Supplier'},
                {name: 'Tipe'},
                {name: 'Status'},
                {name: 'Jumlah'},
                {name: 'Harga/Unit'},
                {name: 'PPN'},
                {name: 'Biaya Transaksi'},
                {name: 'Total'}
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'DAFTAR RIWAYAT PEMBELIAN PEMESANAN';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 20;
            var WidthColums2 = 35;
                
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums2;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums2;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums2;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR_RIWAYAT_PEMBELIAN_PEMESANAN.xlsx`
                );
            });
        } catch (error) {
            console.log(error);
        }
    }

    onToolbarPreparing = (e) => {
        if (this.props.type === 'pemesanan') {
            e.toolbarOptions.items.unshift(
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'plus',
                        onClick: (e) => {
                            this.showModal({}, 'add')
                        }
                    }
                }
            )
        }
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        ) 
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    kirim = async (data) => {
        if (data.status !== 'O') {
            alert('Hanya dapat mengirim instruksi bayar untuk data dengan status Open!', 'Error')
            return null
        }

        const result = await confirm('Kirim instruksi pembayaran ke finance?', 'Confirmation')

        if (result) {
            try {
                this.props.store.dispatch(showLoading(true))
                const apiKirimFinance = `asset-pembelians/kirimFinance`
                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, apiKirimFinance, 'POST', {
                    values: {
                        id: data.id
                    }
                })

                const updatePemesanan = `asset-pembelians`
                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, updatePemesanan, 'PUT', {
                    values: {
                        status: 'P'
                    }
                }, data)

                const loadAPIPembelianBayar = `asset-pembelian-bayars?assetPembelianId.equals=${data.id}&size=9999`
                var dataPembelianBayar = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIPembelianBayar, 'GET')
                for (const pembelianBayar of dataPembelianBayar) {
                    const updatebayar = `asset-pembelian-bayars`
                    await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, updatebayar, 'PUT', {
                        values: {
                            status: 'P'
                        }
                    }, pembelianBayar)
                }

                this.props.store.dispatch(showLoading(false))
                this.forceRefresh()
                // await alert('Data berhasil dikirim !', 'Information')
                let info = await alert('Data berhasil dikirim !', 'Information')

                if (info) {
                    let loadAPI = `view-pengajuan-pembayaran-umums?statusId.in=2&size=9999`;
                    let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET');

                    for (let value of response) {
                        let str = value.noPengajuan;
                        if (str.substring(0, 3) === 'PBA') {
                            if (value.noPengajuan === data.noPembelian) {
                                let loadAPI = `view-pengajuan-pembayaran-umums?noPengajuan.equals=${value.noPengajuan}`;
                                let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET');
                
                                for (let valueI of response) {
                                    let updateData = valueI.keterangan;
                                    updateData = data.namaAset;
                                    const updateKetUmum = `pembayaran-umums`;
                                    await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, updateKetUmum, 'PUT', {
                                        values: {
                                            keterangan: updateData
                                        }
                                    }, valueI);
                                }
                            }
                        }
                    }
                }
            } catch (e) {
                console.log(e)
                this.props.store.dispatch(showLoading(false))
                await alert(e, 'Error')
            }
        }
    }

    batal = async (data) => {
        if (data.status !== 'O') {
            alert('Hanya dapat membatalkan instruksi bayar untuk data dengan status Open!', 'Error')
            return null
        }
        try {
            this.props.store.dispatch(showLoading(true))

            const getAllAssetPembelianBayar = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pembelian-bayars/getByAssetPembelianId/${data.id}`, 'GET')
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pembelians`, 'PUT', {
                values: {
                    status: 'C'
                }
            }, data)


            for (const pembelianBayar of getAllAssetPembelianBayar) {
                const updatebayar = `asset-pembelian-bayars`
                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, updatebayar, 'PUT', {
                    values: {
                        status: 'C'
                    }
                }, pembelianBayar)
            }
            this.props.store.dispatch(showLoading(false))
            this.forceRefresh()
            await alert('Data berhasil dibatalkan !', 'Information')
        } catch (e) {
            console.log(e)
            this.props.store.dispatch(showLoading(false))
            await alert(e, 'Error')
        }
    }

    render() {
        let MenuTitle
        if (this.props.type === 'pemesanan') {
            MenuTitle = 'Daftar Pembelian Pemesanan'
        } else {
            MenuTitle = 'Daftar Riwayat Pembelian Pemesanan'
        }

        return (
            <div className="container-fluid">
                <h3 className="main-title">{MenuTitle}</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='view-asset-pembelians?size=9999&status.in=O&status.in=P&status.in=S&status.in=C'
                        insertAPI='asset-pembelians'
                        updateAPI='asset-pembelians'
                        deleteAPI='asset-pembelians'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Daftar Pemesanan Pembelian"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Pemesanan Pembelian'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ModalUmumPembelianPemesanan
                    ref={this.modalRef}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default UmumPembelianPemesanan
