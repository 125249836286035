import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import ModalKeluargaPengkinianAktifRegistrasi from './actionDataKeluarga'
import UploadKeluarga from './uploadKeluarga'
import { alert,confirm } from 'devextreme/ui/dialog';
import { hubunganKeluarga } from 'dataSource/lookup'
import { formatDate, formatDefaultDate } from 'plugin/helper'

var date = new Date().toISOString()

class DataGridKeluarga extends Component {

    constructor(props) {
        super(props)

        this.state = {
            formData: []
        }

        this.dataGridKeluargaRef = React.createRef()
        this.popupModalKeluargaRegistrasiAktifRef = React.createRef()
        this.popupUploadKeluarga = React.createRef()

        this.currentData = []
        this.data = []
        this.loadDummyData = []

        this.pesertaId = 0
    }

    reloadData = async (pesertaId, data) => {
        this.pesertaId = pesertaId
        this.data = data
        this.currentData = data
        this.dataGridKeluargaRef.current.forceRefresh(true)
    }

    retrieveData = async () => {
        if (this.currentData.length !== 0) {
            try {
                this.currentData = JSON.parse(JSON.stringify(this.data))
                // return this.currentData;
                let result = []
                let dataFilter = this.data.filter(value => value.ahliWaris === null);
                for(let data of this.currentData){
                    data.ahliWaris = data.ahliWaris === true ? true : false
                    result.push(data)
                }
                return result
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }

        return []
    }

    passDataToParent = () => {
        var data = {
            dataKeluarga: this.dataGridKeluargaRef.current.getDataSource(),
            deletedDataKeluarga: this.dataGridKeluargaRef.current.getDeletedIndex(),
            currentDataKeluarga: this.currentData,
        }
        return data
    }

    showModalKeluarga = (data = {}, actionType) => {
        this.popupModalKeluargaRegistrasiAktifRef.current.show()
        this.popupModalKeluargaRegistrasiAktifRef.current.retrieveData(data, actionType)
    }

    showModalUpload = () => {
        this.popupUploadKeluarga.current.show()
    }

    editDataKeluarga = async (data) => {
        var dataKeluarga = this.dataGridKeluargaRef.current.getDataSource()
        var findAhliWaris = dataKeluarga.find(val => val.ahliWaris == true)
        // var dataBirthDate = data.birthDate;
        // dataBirthDate.setHours(0, 0, 0, 0);
        // const formattedDate = dataBirthDate.toISOString().split('T')[0];
        // if (!findAhliWaris || (findAhliWaris && !data.ahliWaris)) {
        try {
            var key = data.id
            // if (this.dataGridKeluargaRef.current.getDataSource().length === 0) {
            //     data.ahliWaris = true
            // } else {
            //     data.ahliWaris = false
            // }

            if (key) {
                this.dataGridKeluargaRef.current.push([
                    {
                        type: 'update',
                        data: {
                            ...data,
                            birthDate: data.birthDate ? formatDate(data.birthDate) : '-'
                        },
                        key: key
                    }
                ])
            } else {
                this.dataGridKeluargaRef.current.push([
                    {
                        type: 'insert',
                        data: {
                            ...data,
                            birthDate: data.birthDate ? formatDate(data.birthDate) : '-'
                        }
                    }
                ])
            }


            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'

            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        } catch (e) {
            console.log(e)
        }
        // } else {
        //     notify({ message: 'Ahli Waris hanya boleh 1 keluarga', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        // }
    }

    deleteDataKeluarga =  async(data) => {
        var dataKeluarga = this.dataGridKeluargaRef.current.getDataSource()

        if (data.ahliWaris === true) {
            await alert('Data tidak bisa dihapus, data di set sebagai penerima MP', 'Informasi!');
            return null;        
        } else {
            this.dataGridKeluargaRef.current.push([
                {
                    type: 'remove',
                    key: data.id
                }
            ])
            // if (dataKeluarga.length > 0) {
            //     dataKeluarga[0].ahliWaris = true
            //     this.dataGridKeluargaRef.current.push([
            //         {
            //             type: 'update',
            //             data: dataKeluarga[0],
            //             key: dataKeluarga[0].id
            //         }
            //     ])
            // }
            // if (dataKeluarga.length > 0 && data.ahliWaris !== true) {
            //     // dataKeluarga[0].ahliWaris = true
            //     this.dataGridKeluargaRef.current.push([
            //         {
            //             type: 'update',
            //             data: data,
            //             key: data.id
            //         }
            //     ])
            // }
        }

        // if (data.ahliWaris !== true) {
        //     this.dataGridKeluargaRef.current.push([
        //         {
        //             type: 'remove',
        //             key: data.id
        //         }
        //     ])
        //     if (dataKeluarga.length > 0) {
        //         dataKeluarga[0].ahliWaris = true
        //         this.dataGridKeluargaRef.current.push([
        //             {
        //                 type: 'update',
        //                 data: dataKeluarga[0],
        //                 key: dataKeluarga[0].id
        //             }
        //         ])
        //     }
        // } else {
        //     await alert('Data tidak bisa dihapus, Keluarga di set sebagai penerima MP', 'Informasi!');
        //     return null;
        // }
    }

    setPenerimaMP = async(data) => {
        var dataKeluarga = this.dataGridKeluargaRef.current.getDataSource()
        var penerimaMPCurrent = dataKeluarga.find(value => value.ahliWaris)
        if (penerimaMPCurrent) {
            penerimaMPCurrent.ahliWaris = false

            var penerimaMPNew = data
            penerimaMPNew.ahliWaris = true

            this.dataGridKeluargaRef.current.push([
                {
                    type: 'update',
                    data: penerimaMPCurrent,
                    key: penerimaMPCurrent.id
                },
                {
                    type: 'update',
                    data: penerimaMPNew,
                    key: penerimaMPNew.id
                }
            ])

            let dataArr = this.dataGridKeluargaRef.current.getDataSource();

            for (let value of dataArr) {
                if (value.id === penerimaMPCurrent.id){
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'peserta-families', 'PUT', {
                        values: penerimaMPCurrent,
                        key: penerimaMPCurrent.id
                    }, penerimaMPCurrent);
                } else {
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'peserta-families', 'PUT', {
                        values: penerimaMPNew,
                        key: penerimaMPNew.id
                    }, penerimaMPNew);
                }
            }
            return null
        }else {
            data.ahliWaris = true
            this.dataGridKeluargaRef.current.push([
                {
                    type: 'update',
                    data: data,
                    key: data.id
                }
            ])
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'peserta-families', 'PUT', {
                values: data,
                key: data.id
            }, data);
            // dataKeluarga[0].ahliWaris = true
            // this.dataGridKeluargaRef.current.push([
            //     {
            //         type: 'update',
            //         data: dataKeluarga[0],
            //         key: dataKeluarga[0].id
            //     }
            // ])
        }
        this.forceRefresh();
    }

    getDatagridColumnConfiguration = () => {
        var columnConfiguration = [
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'relationCode',
                caption: 'Hubungan Keluarga',
                lookup: {
                    dataSource: hubunganKeluarga(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'noKk',
                caption: 'No KK',
            },
            {
                dataField: 'ktp',
                caption: 'No KTP',
            },
            {
                dataField: 'noBpjs',
                caption: 'No BPJS',
            },
            {
                dataField: 'statusSekolah',
                caption: 'Status Pendidikan',
            },
            {
                dataField: 'ahliWaris',
                caption: 'Status Penerima MP',
            }
        ]
        if (this.props.actionType === 'edit') {
            columnConfiguration.unshift({
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            this.showModalKeluarga(e.row.data, 'edit')
                        },
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: async (e) => {
                            const Result = await confirm('Apakah anda ingin menghapus data ini?', 'Konfirmasi');
                            if (Result) {
                                this.deleteDataKeluarga(e.row.data)
                            }
                        },
                    },
                    // {
                    //     text: 'Set Status Penerima MP',
                    //     hint: 'Set Status Penerima MP',
                    //     onClick: (e) => {
                    //         this.setPenerimaMP(e.row.data)
                    //     },
                    // },
                ],
            })
        } else if (this.props.actionType === 'view') {
            columnConfiguration.unshift({
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        onClick: (e) => {
                            this.showModalKeluarga(e.row.data, 'view')
                        },
                    }
                ],
            })
        } else {
            columnConfiguration.unshift({
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        onClick: (e) => {
                            this.showModalKeluarga(e.row.data, 'view')
                        },
                    },
                    {
                        text: 'Set Status Penerima MP',
                        hint: 'Set Status Penerima MP',
                        onClick: (e) => {
                            this.setPenerimaMP(e.row.data)
                        },
                    },
                ],
            })
        }

        return columnConfiguration
    }

    onToolbarPreparingKeluarga = (e) => {
        if (this.props.actionType === 'edit') {
            e.toolbarOptions.items.unshift(
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'plus',
                        onClick: (e) => {
                            this.showModalKeluarga({}, 'add');
                        }
                    }
                }
            )
        }

        // if (this.props.actionType !== 'view') {
        //     e.toolbarOptions.items.unshift(
        //         {
        //             location: 'after',
        //             widget: 'dxButton',
        //             options: {
        //                 icon: 'plus',
        //                 onClick: (e) => {
        //                     this.showModalKeluarga({}, 'add')
        //                 }
        //             },
        //         },
        //         // {
        //         //     location: 'after',
        //         //     widget: 'dxButton',
        //         //     options: {
        //         //         icon: 'upload',
        //         //         text: 'Upload',
        //         //         onClick: (e) => {
        //         //             this.showModalUpload()
        //         //         }
        //         //     }
        //         // },
        //     )
        // }
    }

    removeState = () => {
        this.currentData = []
        this.loadDummyData = []

        this.setState({
            formData: []
        })
    }

    forceRefresh = () => {
        // let data = this.dataGridKeluargaRef.current.getDataSource();
        // let dataFilter = data.filter(val => val.ahliWaris === null);
        
        // for (let value of dataFilter) {
        //     let dataAhliWaris = value.ahliWaris;
        
        //     dataAhliWaris = false;

        //     this.dataGridKeluargaRef.current.push([
        //         {
        //             type: 'update',
        //             data: dataAhliWaris,
        //             key: dataFilter.id
        //         }
        //     ])
        //     console.log(value);
        // }
        // let filterData = dataKeluarga.filter(val => val.ahliWaris === null);
        // console.log(filterData);
        this.dataGridKeluargaRef.current.forceRefresh(true)
    }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridKeluargaRef}
                    loadAPI={`peserta-families/${this.pesertaId}`}
                    insertAPI='peserta-families'
                    updateAPI='peserta-families'
                    deleteAPI='peserta-families'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.retrieveData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Data Keluarga"}

                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Keluarga'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.getDatagridColumnConfiguration()} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparingKeluarga}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalKeluargaPengkinianAktifRegistrasi
                    store={this.props.store}
                    actionType={this.props.actionType}
                    forceRefresh={this.forceRefresh}
                    editDataKeluarga={this.editDataKeluarga}
                    ref={this.popupModalKeluargaRegistrasiAktifRef}
                />
                <UploadKeluarga
                    store={this.props.store}
                    editDataKeluarga={this.editDataKeluarga}
                    ref={this.popupUploadKeluarga}
                />
            </React.Fragment>
        )
    }
}

export default DataGridKeluarga