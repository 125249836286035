import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { pembayaranUmumStatus, programs, programSdm, sdmUnit, unit } from 'dataSource/lookup';
import RPUListDetailModal from 'components/pages/modal/sdm-umum/pembayaran-umum/riwayat-pembayaran-umum/detail';
import RPUListDetailTransaksiModal from 'components/pages/modal/sdm-umum/pembayaran-umum/riwayat-pembayaran-umum/transaksi/detail-transaksi';
import PPUVerifikasiManagerModal from 'components/pages/modal/sdm-umum/pengajuan-pembayaran-umum/verifikasi-manager/index';
import { formatDefaultFullDate, formatNumber, formatNumberAum } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class PPRiwayatPembayaranUmum extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()
        this.modalRef = React.createRef()
        this.modalTransaksiRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Lihat Detail',
                        hint: 'Lihat Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                    {
                        text: 'Lihat Transaksi',
                        hint: 'Lihat Transaksi',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalTransaksi(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: "id",
                caption: "No.",
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: "noPengajuan",
                caption: "No Pengajuan",
            },
            {
                dataField: "statusId",
                caption: "Status",
                lookup: {
                    dataSource: pembayaranUmumStatus(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: "rejectedNote",
                caption: "Catatan Reject",
            },
            {
                dataField: "programId",
                caption: "Program",
                lookup: {
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: "totalRejected",
                caption: "Total Detail Rejected",
            },
            {
                dataField: "tanggalPengajuan",
                caption: "Tanggal Pengajuan",
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: "nominalDetil",
                caption: "Total Nominal Terkirim",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: "totalNominalPengajuan",
                caption: "Total Nominal Pengajuan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalUangMuka",
                caption: "Total Uang Muka",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalCashOut",
                caption: "Total Cash Out",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalPph",
                caption: "Total PPH",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalPotongan",
                caption: "Total Potongan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalId",
                caption: "Jumlah transaksi",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "keterangan",
                caption: "Keterangan",
            },
            {
                dataField: 'cashOut',
                caption: 'Cash Out',
                alignment: 'center',
                columns: [
                    {
                        dataField: "sisaPembayaran",
                        caption: "Sisa Pembayaran",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "nominalCashOut",
                        caption: "Nominal Cash Out",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "nominalDigunakan",
                        caption: "Nominal Digunakan",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "sisaCash",
                        caption: "Sisa Cash",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                ]
            },
            {
                dataField: 'persetujuan',
                caption: 'Persetujuan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'unit',
                        caption: 'Unit Kerja',
                        lookup: {
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'requestBy',
                        caption: 'Dibuat Oleh'
                    },
                    {
                        dataField: 'managerVerifiedBy',
                        caption: 'Verifikasi Manager'
                    },
                    {
                        dataField: 'sdmVerifiedBy',
                        caption: 'Verifikasi SDMU'
                    },
                    {
                        dataField: 'directorApproveBy',
                        caption: 'Pengurus'
                    },
                ]
            },
            {
                dataField: 'catatanNote',
                caption: 'Catatan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'managerVerifiedNote',
                        caption: 'Verifikasi Manager'
                    },
                    {
                        dataField: 'sdmVerifiedNote',
                        caption: 'Verifikasi SDMU'
                    },
                    {
                        dataField: 'directorApproveNote',
                        caption: 'Pengurus'
                    },
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (data) => {
        this.modalRef.current.show()
        if (data) {
            this.modalRef.current.retrieveData(data)
        }
    }

    showModalTransaksi = (data) => {
        this.modalTransaksiRef.current.show()
        if (data) {
            this.modalTransaksiRef.current.retrieveData(data)
        }
    }
    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'plus',
    //                 onClick: () => {
    //                     this.showModal()
    //                 }
    //             }
    //         },
    //     )
    // }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        ) 
    }

    exportExcel = async() => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'view-pengajuan-pembayaran-umums?size=9999');

        for (let value of response){
            let idStatus = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `pembayaran-umum-statuses/${value.statusId}`);
            let programsId = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `programs/${value.programId}`);
            let unitData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `units?size=9999`);
            let dataValue = {
                noPengajuan: value.noPengajuan ? value.noPengajuan : 0,
                statusId: idStatus.statusName ? idStatus.statusName : '-',
                rejectedNote: value.rejectedNote ? value.rejectedNote : 0,
                programId: programsId.program,
                totalRejected: value.totalRejected ? value.totalRejected : '-',
                tanggalPengajuan: value.tanggalPengajuan ? formatDefaultFullDate(value.tanggalPengajuan) : '-',
                nominalDetil: value.nominalDetil ? formatNumberAum(value.nominalDetil) : 0,
                totalNominalPengajuan: value.totalNominalPengajuan ? formatNumberAum(value.totalNominalPengajuan) : 0,
                totalUangMuka: value.totalUangMuka ? formatNumberAum(value.totalUangMuka) : 0,
                totalCashOut: value.totalCashOut ? formatNumberAum(value.totalCashOut) : 0,
                totalPph: value.totalPph ? formatNumberAum(value.totalPph) : 0,
                totalPotongan: value.totalPotongan ? formatNumberAum(value.totalPotongan) : 0,
                totalId: value.totalId ? value.totalId : 0,
                keterangan: value.keterangan ? value.keterangan : '-',
                sisaPembayaran: value.sisaPembayaran ? formatNumberAum(value.sisaPembayaran) : 0,
                nominalCashOut: value.nominalCashOut ? formatNumberAum(value.nominalCashOut) : 0,
                nominalDigunakan: value.nominalDigunakan ? formatNumberAum(value.nominalDigunakan) : 0,
                sisaCash: value.sisaCash ? formatNumberAum(value.sisaCash) : 0,
                unitId: value.unitId ? unitData.find(val => val.id === value.unitId).unitName : '-',
                requestBy: value.requestBy,
                managerVerifiedBy: value.managerVerifiedBy, 
                sdmVerifiedBy: value.sdmVerifiedBy,
                directorApproveBy: value.directorApproveBy,
                managerVerifiedNote: value.managerVerifiedNote,
                sdmVerifiedNote: value.sdmVerifiedNote,
                directorApproveNote: value.directorApproveNote,
            }
            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }
        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('RIWAYAT PEMBAYARAN UMUM');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,   
                },
                columns: [
                    {name: 'No Pengajuan'},
                    {name: 'Status'},
                    {name: 'Catatan Reject'},
                    {name: 'Program'},
                    {name: 'Total Detail Rejected'},
                    {name: 'Tanggal Pengajuan'},
                    {name: 'Total Nominal Terkirim'},
                    {name: 'Total Nominal Pengajuan'},
                    {name: 'Total Uang Muka'},
                    {name: 'Total Cash Out'},
                    {name: 'Total PPH'},
                    {name: 'Total Potongan'},
                    {name: 'Jumlah Transaksi'},
                    {name: 'Keterangan'},
                    {name: 'Sisa Pembayaran'},
                    {name: 'Nominal Cash Out'},
                    {name: 'Nominal Digunakan'},
                    {name: 'Sisa Cash'},
                    {name: 'Unit Kerja'},
                    {name: 'Dibuat Oleh'},
                    {name: 'Verifikasi Manager'},
                    {name: 'Verifikasi SDMU'},
                    {name: 'Pengurus'},
                    {name: 'Verifikasi Manager '},
                    {name: 'Verifikasi SDMU '},
                    {name: 'Pengurus '},
                    {name: 'Status'},
                ],
                rows: data
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                // worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('N'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('O'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('P'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('Q'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('R'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'RIWAYAT PEMBAYARAN UMUM';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums; 

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums2;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums2;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums2;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;

            const Data14 = worksheet.getColumn(14);
            Data14.width = 65;

            const Data15 = worksheet.getColumn(15);
            Data15.width = WidthColums;

            const Data16 = worksheet.getColumn(16);
            Data16.width = WidthColums;

            const Data17 = worksheet.getColumn(17);
            Data17.width = WidthColums;

            const Data18 = worksheet.getColumn(18);
            Data18.width = WidthColums;

            const Data19 = worksheet.getColumn(19);
            Data19.width = WidthColums;

            const Data20 = worksheet.getColumn(20);
            Data20.width = WidthColums;

            const Data21 = worksheet.getColumn(21);
            Data21.width = WidthColums;

            const Data22 = worksheet.getColumn(22);
            Data22.width = WidthColums;

            const Data23 = worksheet.getColumn(23);
            Data23.width = WidthColums;

            const Data24 = worksheet.getColumn(24);
            Data24.width = WidthColums;

            const Data25 = worksheet.getColumn(25);
            Data25.width = WidthColums;

            const Data26 = worksheet.getColumn(26);
            Data26.width = WidthColums;

            const Data27 = worksheet.getColumn(27);
            Data27.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `RIWAYAT_PEMBAYARAN_UMUM.xlsx`
                );
            });
        } catch (error) {
            
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Riwayat Pembayaran Umum</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-pengajuan-pembayaran-umums?size=9999'
                    insertAPI='pembayarans'
                    updateAPI='pembayarans'
                    deleteAPI='pembayarans'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    remoteOperations={false}
                    // useArraySource = {true}
                    // ArraySourceData = {[{id:1}]}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Riwayat Pembayaran Umum"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Riwayat Pembayaran Umum'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                {/* <RPUListDetailModal
                    ref={this.modalRef}
                    store={this.props.store}
                /> */}
                <PPUVerifikasiManagerModal
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    from={"riwayatPu"}
                // showModalCatatan = {this.showModalCatatan}
                />
                <RPUListDetailTransaksiModal
                    ref={this.modalTransaksiRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}


export default PPRiwayatPembayaranUmum