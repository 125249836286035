import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormPenugasanPerjalananDinas from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/penugasan/form';
import TiketPenugasan from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/penugasan/tiket';
import TiketHotel from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/penugasan/tiketHotel';
import BiayaPenugasanPerjalananDinas from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/penugasan/biaya';
import { cities, jabatan, jabatanGolongan, karyawan,programs, sdmUnit, sppdBiaya, sppdType, statusSppd} from 'dataSource/lookup';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { alert, confirm } from 'devextreme/ui/dialog';

class KepegawaianPenugasan extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{}
        }
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.modalBayarRef = React.createRef()
        this.modalTiketRef = React.createRef()
        this.modalTiketHotelRef = React.createRef()
        this.modalFileRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()

        this.penugasanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        onClick : async (e) => {
                            console.log(e.row.data.statusId)
                            if(e.row.data.statusId === 2) {
                                await alert('Data ini tidak bisa diubah', 'Pemberitahuan');
                            } else {
                                this.showModalEdit(e)
                            }
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        onClick : (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: async () => {
                                            if (e.row.data.statusId === 2) {
                                                await alert('Data ini sedang terkirim dan tidak bisa dihapus', 'Pemberitahuan');
                                            } else if (e.row.data.statusId === 3) {
                                                await alert('Data ini berstatus Paid dan tidak bisa dihapus', 'Pemberitahuan');
                                            } else {
                                                this.hapusData(e.row.data)
                                            }
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                            
                        }
                    },
                    // {
                    //     text : 'File',
                    //     hint : 'File',
                    //     onClick : (e) => {
                    //         console.log("new")
                    //     }
                    // },
                    {
                        text : 'Tiket Transportasi',
                        hint : 'Tiket Transportasi',
                        onClick : (e) => {
                            this.showModalTiket(e.row.data)
                        }
                    },
                    {
                        text : 'Tiket Penginapan',
                        hint : 'Tiket Penginapan',
                        onClick : (e) => {
                            this.showModalTiketHotel(e.row.data)
                        }
                    },
                    {
                        text : 'Biaya Perjalanan',
                        hint : 'Biaya Perjalanan',
                        onClick : (e) => {
                            this.showModalBiaya(e.row.data)
                        }
                    },
                    
                    {
                        text : 'Kirim',
                        hint : 'Kirim',
                        onClick : async(e) => {
                            let tiketPerjalanan = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,`karyawan-sppd-tikets/get-by-karyawanSppdId/${e.row.data.id}`,"GET");
                            let tiketHotel = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-sppd-hotels/get-by-karyawanSppdId/${e.row.data.id}`, 'GET');
                            let biayaPerjalanan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-sppd-biayas/get-by-karyawanSppdId/${e.row.data.id}`, 'GET');

                            if (e.row.data.statusId === 2){
                                await alert("Data ini sudah terkirim dan tidak bisa dikirim",'Pemberitahuan');
                                return null;
                            } else if (tiketPerjalanan.length === 0) {
                                await alert("Data tidak bisa terkirim, Tiket perjalanan dinas belum terisi", 'Pemberitahuan');
                                return null;
                            } else if (tiketHotel.length === 0) {
                                await alert("Data tidak bisa terikirim, Tiket Hotel belum terisi", 'Pemberitahuan');
                                return null;
                            } else if (biayaPerjalanan.length === 0) {
                                await alert("Data tidak bisa terikirim, biaya perjalanan dinas belum terisi", 'Pemberitahuan');
                                return null;
                            } else {
                                this.kirim(e.row.data);
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup:{
                dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'karyawanId',
                caption: 'Nama Lengkap',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'jabatanGolonganId',
                caption: 'Golongan Jabatan',
                lookup:{
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaJagol', // provides display values
                }
            },
            {
                dataField: 'unitId',
                caption: 'Unit',
                lookup:{
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                },
                visible: false
            },
            {
                dataField: 'perjalananDinas',
                caption: 'Perjalanan Dinas',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'noPenugasan',
                        caption: 'No. Penugasan'
                    },
                    {
                        dataField: 'sppdTypeId',
                        caption: 'Jenis Perjalanan',
                        lookup:{
                            dataSource: sppdType(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'sppdName', // provides display values
                        }
                    },
                    {
                        dataField: 'cityId',
                        caption: 'Kota',
                        lookup:{
                            dataSource: cities(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'cityName', // provides display values
                        }
                    },
                    {
                        dataField: 'hari',
                        caption: 'Hari'
                    },
                    {
                        dataField: 'tanggalAwal',
                        caption: 'Tanggal Awal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tanggalAkhir',
                        caption: 'Tanggal Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup:{
                    dataSource: statusSppd(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                }
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalInsert(e)
                    },
                }
            }
        )
    }
    
    showModalInsert = () => {
        this.modalInsertRef.current.show()
        this.modalInsertRef.current.generateNoPenugasan()
    }
    
    showModalEdit = (e) => {
        if (e.row) {
            this.modalEditRef.current.retrieveData(e.row.data)
        }
        this.modalEditRef.current.show()
    }
    
    showModalTiket = (data) => {
        this.modalTiketRef.current.show()
        this.modalTiketRef.current.retrieveData(data)
    }
    showModalTiketHotel = (data) => {
        this.modalTiketHotelRef.current.show()
        this.modalTiketHotelRef.current.retrieveData(data)
    }
    
    showModalBiaya = (data) => {
        this.modalBayarRef.current.show()
        this.modalBayarRef.current.retrieveData(data)
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    hapusData = async (data) => {
        try {
            var deleteAPI = `delete-perjalanan-dinas`;
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, deleteAPI, 'DELETE', data.id);

            this.forceRefresh()
            var text = `Data berhasil dihapus!`
            var type = 'Success'

            await alert(text, type)
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
        
    }
    
    filterSubmit = () => {
    
    }

    kirim = async(data) => {
        try {
            var getkaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByUsername/${this.props.store.getState().sessionUser}`, 'GET')

            var postAPI = 'kirim-perjalanan-dinas'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,postAPI,"POST",{
                    values: {
                        karyawanSppdId: data.id,
                        unitId: getkaryawan.unitId
                    }
                });

            if (response) {
                let karyawanSppd = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-sppds/${data.id}`, 'GET');
                karyawanSppd.statusId = 2;

                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, "karyawan-sppds", 'PUT', {
                    key: karyawanSppd.id,
                    values: karyawanSppd
                }, karyawanSppd)
                this.forceRefresh()
                notify({ message: 'Sukses Kirim Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Penugasan Perjalanan Dinas</h3>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='karyawan-sppds?size=9999'
                    insertAPI='karyawan-sppds'
                    updateAPI='karyawan-sppds'
                    deleteAPI='karyawan-sppds' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    // useArraySource = {true}
                    // ArraySourceData= {this.loadDummyDataPengajuan}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Penugasan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.penugasanColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPenugasanPerjalananDinas
                    forceRefresh={this.forceRefresh}
                    ref={this.modalInsertRef}
                    store={this.props.store}
                    action={'add'}
                />
                <FormPenugasanPerjalananDinas
                    forceRefresh={this.forceRefresh}
                    ref={this.modalEditRef}
                    store={this.props.store}
                    action={'edit'}
                />
                <TiketPenugasan
                    ref={this.modalTiketRef}
                    store={this.props.store}
                />
                <TiketHotel
                    ref={this.modalTiketHotelRef}
                    store={this.props.store}
                />
                <BiayaPenugasanPerjalananDinas
                    ref={this.modalBayarRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default KepegawaianPenugasan