import { months, product } from "dataSource/lookup";
import React, { Component } from "react";
import Popup from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { Form, Button, DataGrid, FileUploader, CheckBox } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import XLSX from 'xlsx';
import { ab2str, yyyymmddToDate, formatNumber, cleanQuote, ddmmyyyyToDate, formatDate } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from "components/inheritComponent/devextremeTab";

class FormGenerateModalPhdpNs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploadedData: [],
            successData: [],
            formData: {},
            popupVisible: false,
        }

        this.prevTabIndex = 0

        this.TabDataSource = [
            {
                id: 0,
                text: "Data Sesuai"
            },
            {
                id: 1,
                text: "Data Tidak Sesuai"
            },
        ];

        this.PopupToolbarItem = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Simpan Data',
                onClick: this.submitData.bind(this)
            },
            toolbar: 'bottom'
        }, {
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Batal',
                onClick: this.hide.bind(this)
            },
            toolbar: 'bottom'
        }]

        this.formItem = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'Nomor Pengajuan',
                    alignment: 'left',
                    location: 'left'
                }
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location: 'left',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    displayExpr: function (item) {
                        return item && item.productCode + " - " + item.productName;
                    },
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'rapelTypeId',
                label: {
                    text: 'Jenis Rapel',
                    alignment: "left",
                    location: 'left',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Rapel dibayar bersama MP Bulanan'
                        },
                        {
                            id: 2,
                            value: 'Rapel terpisah'
                        },
                        {
                            id: 3,
                            value: 'PHDP'
                        },
                        {
                            id: 4,
                            value: 'NS'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%'
                }
            },
            {
                dataField: 'tanggalEfektifPerubahan',
                label: {
                    text: 'Tanggal Efektif Perubahan',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'tanggalPerhitungan',
                label: {
                    text: 'Tanggal Perhitungan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'tanggalPembayaran',
                label: {
                    text: 'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField:"periodeBulan",
                label:{
                    text:"Periode Bulan",
                    alignment:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'periodeTahun',
                label: {
                    alignment: 'left',
                    text: 'Periode Tahun'
                },
                editorType: 'dxNumberBox',
                // editorOptions: {
                //     readOnly: true
                // }
            },
            {
                dataField:"keterangan",
                label:{
                    text:"Keterangan",
                },
                editorType:'dxTextArea',
                editorOptions:{
                    height: '100px'
                },
            },
        ]

        this.columns = [
            {
                dataField: 'id',
                caption: 'NO',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'nama',
                caption: 'Nama'
            }, 
            {
                dataField: 'phdp',
                caption: 'PHDP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'phdpLama',
                        caption: 'Lama'
                    },
                    {
                        dataField: 'phdpBaru',
                        caption: 'Baru'
                    },
                ]
            },
            {
                dataField: 'periodeNs',
                caption: 'Periode NS',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'periodeNsLama',
                        caption: 'Lama'
                    },
                    {
                        dataField: 'periodeNsBaru',
                        caption: 'Baru'
                    },
                ]
            },
            {
                dataField: 'nilaiMpBaru',
                caption: 'Nilai Mp Baru'
            },
            {
                dataField: 'rapel',
                caption: 'Rapel',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'rapelNilaiMp',
                        caption: 'Nilai MP'
                    },
                    {
                        dataField: 'rapelPotonganIuran',
                        caption: 'Potongan Iuran'
                    },
                    {
                        dataField: 'rapelPajak',
                        caption: 'Pajak'
                    },
                    {
                        dataField: 'rapelMpTransfer',
                        caption: 'MP Transfer'
                    }
                ]
            },
            {
                dataField: 'rekeningTransfer',
                caption: 'Rekening Transfer',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'rekNomor',
                        caption: 'Nomor Rekening'
                    },
                    {
                        dataField: 'rekName',
                        caption: 'Nama Rekening'
                    },
                    {
                        dataField: 'rekBankCabang',
                        caption: 'Bank / Cabang'
                    },
                    {
                        dataField: 'paymentRekBankId',
                        caption: 'Bank Pembayar'
                    }
                ]
            }
        ]

        this.formRef = React.createRef();
        this.dataGridSesuaiRef = React.createRef();
        this.dataGridTdkSesuaiRef = React.createRef();
        this.successData = []
        this.errorData = []
    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            uploadedData: [],
            successData: [],
            formData: {}
        })
        this.successData = [];
        this.forceRefresh();
    }

    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    forceRefresh = () => {
        this.dataGridSesuaiRef.current.forceRefresh(true);
        this.dataGridTdkSesuaiRef.current.forceRefresh(true);
    }

    onUploadStarted = (e) => {
        if (e.value.length > 0) {
            this.props.store.dispatch(showLoading(true))
        }
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if(fileType === 'xls' || fileType === 'xlsx' ){
                    var workbook = XLSX.read(dataArrayBuffer, {type: 'array'});
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)
                    
                    data = dataJSON.map(value => Object.values(value))
                }

                if(fileType === 'csv'){
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                       data = data.filter(value => 
                            {
                                regex.lastIndex = 0
                                return regex.test(value)
                            }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)
                            
                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)
    
                                var splitRes = splitRes.map(splitVal => splitVal.trim())
        
                                var splitRes = splitRes.map(splitVal => {
                                    if(String(splitVal)){
                                        return String(splitVal)
                                    }else{
                                        return splitVal
                                    }
                                })
    
                                return splitRes
                            }
                        })
                    }else{
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []
                var errorMessage = []

                for(var uploadedData of data){
                    if(Array.isArray(uploadedData)){
                        errorMessage = []
                        if (uploadedData.length !== 15){
                            errorMessage.push('Format File Salah');

                            errorData.push({
                                errorMessage: errorMessage,
                                no: uploadedData[0],
                                nik: uploadedData[1],
                                nama: uploadedData[2],
                                phdpLama: uploadedData[3],
                                phdpBaru: uploadedData[4],
                                periodeNsLama: uploadedData[5],
                                periodeNsBaru: uploadedData[6],
                                rapelNilaiMp: uploadedData[7],
                                nilaiMpBaru: uploadedData[8],
                                rapelPotonganIuran: uploadedData[9],
                                rapelPajak: uploadedData[10],
                                rapelMpTransfer: uploadedData[11],
                                rekNomor: uploadedData[12],
                                rekName: uploadedData[13],
                                rekBankCabang: uploadedData[14],
                                paymentRekBankId: uploadedData[15]
                            })
                            continue;
                        }
                        errorMessage = []
            
                        // if(uploadedData.length !== 13){
                        //     let type = "error";
                        //     let text = "Format Data Masuk";

                        //     notify(
                        //         { message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600
                        //     );
                        // }
                        
                        try {
                            successData.push({
                                no: uploadedData[0],
                                nik: uploadedData[1],
                                nama: uploadedData[2],
                                phdpLama: uploadedData[3],
                                phdpBaru: uploadedData[4],
                                periodeNsLama: uploadedData[5],
                                periodeNsBaru: uploadedData[6],
                                rapelNilaiMp: uploadedData[7],
                                nilaiMpBaru: uploadedData[8],
                                rapelPotonganIuran: uploadedData[9],
                                rapelPajak: uploadedData[10],
                                rapelMpTransfer: uploadedData[11],
                                rekNomor: uploadedData[12],
                                rekName: uploadedData[13],
                                rekBankCabang: uploadedData[14],
                                paymentRekBankId: uploadedData[15]
                            })
                            continue;   
                        } catch (error) {
                            this.props.store.dispatch(showLoading(true))
                            errorData.push({
                                errorMessage: errorMessage,
                                no: uploadedData[0],
                                nik: uploadedData[1],
                                nama: uploadedData[2],
                                phdpLama: uploadedData[3],
                                phdpBaru: uploadedData[4],
                                periodeNsLama: uploadedData[5],
                                periodeNsBaru: uploadedData[6],
                                rapelNilaiMp: uploadedData[7],
                                nilaiMpBaru: uploadedData[8],
                                rapelPotonganIuran: uploadedData[9],
                                rapelPajak: uploadedData[10],
                                rapelMpTransfer: uploadedData[11],
                                rekNomor: uploadedData[12],
                                rekName: uploadedData[13],
                                rekBankCabang: uploadedData[14],
                                paymentRekBankId: uploadedData[15]
                            })
                            continue;
                        }
                    }
                }

                this.successData = successData
                this.errorData = errorData
                this.forceRefresh()

                if(errorData.length > 0){
                    let type = "error";
                    let text = "There are some upload data that failed to upload, please check failed upload data tab";
    
                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                }
                
                this.props.store.dispatch(showLoading(false))
            }
            reader.readAsArrayBuffer(files);
        }  
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    submitData = async() => {
        let data = this.successData;
        let dataMaster = this.state.formData
        let arrData = [];

        // for (let value of data) {
        //     arrData.push(value);
        // }

        this.props.store.dispatch(showLoading(true));
        if (data.length > 0) {
            try {
                let dataResponse = {
                    keterangan: dataMaster.keterangan,
                    noPengajuan: dataMaster.noPengajuan,
                    periodeBulan: dataMaster.periodeBulan,
                    periodeTahun: dataMaster.periodeTahun,
                    rapelPhdpNsDetilDTOS: this.successData,
                    productId: dataMaster.productId,
                    rapelTypeId: dataMaster.rapelTypeId,
                    tanggalEfektifPerubahan: dataMaster.tanggalEfektifPerubahan,
                    tanggalPerhitungan: dataMaster.tanggalPerhitungan,
                    tanggalPengajuan: dataMaster.tanggalPengajuan,
                    tanggalPembayaran: dataMaster.tanggalPembayaran,
                }
    
                let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, "rapel-phdp-ns/simpan", 'POST', {
                    values: dataResponse
                })
                if (response) {
                    this.props.store.dispatch(showLoading(false));
                    notify({ message: 'Sukses!', width: 'AUTO', position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.props.forceRefresh()
                    this.hide()
                }
            } catch (error) {
                console.log(error);
                this.props.store.dispatch(showLoading(false));
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        } else {
            let type = "error";
            let text = "Harus upload file";
            notify(
                {
                    message: text,
                    width: "AUTO",
                    shading: true,
                    position: { at: "center", my: "center", of: window }
                },
                type,
                2000
            );
        }
        
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                      className={'popup'}
                      visible={this.state.popupVisible}
                      onHiding={this.hide}
                      dragEnabled={true}
                      resizeEnabled={true}
                      showTitle={true}
                      title={'Form Generate Rapel Perubahan PHDP & NS'}
                      minWidth={'70vh'}
                      minHeight={'50vh'}
  
                      ref={this.PopupRef}
  
                      toolbarItems={this.PopupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form 
                                        colCount={1}
                                        id={'form'}
                                        formData={this.state.formData}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation={'left'}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-4">
                                <div style={{ width: "350px" }}>
                                <FileUploader
                                        accept={'.txt,.xls,.xlsx,.csv'}
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)}
                                        labelText="Upload File"
                                        showFileList={false}
                                        name={'reksadanaAsset'}

                                        value={this.state.uploadedData}

                                        ref={this.fileUploderRef}
                                    />
                                </div>
                            </div>
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                            <div id="tabindex-0" className={'mt-2 text-centet'}>
                                <DevExpressDataGrid
                                        ref={this.dataGridSesuaiRef}
                                        keyField={'nik'}
                                        loadAPI=''
                                        insertAPI=''
                                        updateAPI=''
                                        deleteAPI=''

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.retrieveDataSuccess}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={false}
                                        exportFileName={"Data Sukses"}

                                        allowExportSelectedData={false}

                                        showBorders={true}

                                        paging={false}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Data Sukses'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                        // SummaryConfiguration={this.summary}
                                        summaryTotalItem={this.summary}
                                        // onToolbarPreparing = {this.onToolbarPreparing}

                                        height={'calc(70vh - 175px)'}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                            </div>
                            <div id="tabindex-1" className="d-none mt-2 text-center">
                            <DevExpressDataGrid
                                        ref={this.dataGridTdkSesuaiRef}
                                        keyField={'nik'}
                                        loadAPI=''
                                        insertAPI=''
                                        updateAPI=''
                                        deleteAPI=''

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.retrieveDataFailed}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={false}
                                        exportFileName={"Data Sukses"}

                                        allowExportSelectedData={false}

                                        showBorders={true}

                                        paging={false}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Data Sukses'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                        // SummaryConfiguration={this.summary}
                                        summaryTotalItem={this.summary}
                                        // onToolbarPreparing = {this.onToolbarPreparing}

                                        height={'calc(70vh - 175px)'}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormGenerateModalPhdpNs