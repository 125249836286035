import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate } from 'plugin/helper'
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, ValueAxis } from 'devextreme-react/chart';
import httpRequest from 'plugin/httprequest'
import { transform } from '@babel/core'
import { showLoading } from 'redux/actions/loading'

class LaporanRoiPerKategori extends Component {
    constructor(props) {
        super(props)

        this.modalKajianRef = React.createRef()
        this.modalInstrumentRef = React.createRef()
        this.modalKomiteRef = React.createRef()
        this.dgRef = React.createRef()

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.state = {
            filterData: {
                productId: 1,
                tanggal: formatDate(this.date)
            },
            dataSource: []
        }

        this.series = [
            { valueField: "komposisi", name: "Komposisi" },
            { valueField: "roi", name: "ROI" },
        ]
        this.dataSource = []

        this.filterItem = [
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.productCode + ' - ' + e.productName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (e) => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            }
                        }
                    },
                    {
                        dataField: 'tanggal',
                        label: {
                            text: 'Tanggal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            // width: '38%',
                            readOnly: false
                        },
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Memuat Data",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                // this.setState({
                                //     filterData: this.state.filterData
                                // })
                                this.filterData()
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    filterData = async () => {
        this.props.store.dispatch(showLoading(true))
        // var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-roi-per-instruments?productId.in=${this.state.filterData.productId}`, 'GET')
        var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-headers/get-roi-kategori-investasi/${this.state.filterData.productId}/${this.state.filterData.tanggal}`, 'GET')
        this.props.store.dispatch(showLoading(false))
        this.setState({ dataSource: response })
    }
    // handleChange = async (e) => {
    //     this.props.store.dispatch(showLoading(true))
    //     // var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-roi-per-instruments?productId.in=${this.state.filterData.productId}`, 'GET')
    //     var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-headers/get-roi-kategori-investasi/${this.state.filterData.productId}/${this.state.filterData.tanggal}`, 'GET')
    //     var data = response
    //     this.dataSource = data
    //     this.props.store.dispatch(showLoading(false))
    // }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">ROI Per Kategori</h2>
                <div className="row">
                    <div className="col-md-7 mb-4">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '25px' }} >
                <Chart id="chart"
                    dataSource={this.state.dataSource}
                    series={this.series}
                    // onOptionChanged={this.handleChange}
                    size={{ height: 400 }}
                >
                <ValueAxis 
                    visualRange={{ startValue: 0, endValue: 80 }}
                    maxValue={70} 
                    tickInterval={10}
                />  
                    <CommonSeriesSettings
                        // argumentField="categoryName"
                        argumentField="description"
                        type="bar"
                        hoverMode="allArgumentPoints"
                    >
                        <Label visible={true} format = {{ type: "fixedPoint", precision: 0 }} 
                        backgroundColor="transparent"
                        font={{size: 10, color: '#000000' }}
                        customizeText = { 
                            // function() { return " (" + this.percent * 100 + "%)"; }
                            // function() { return this.valueText + " (" + (this.percent * 100).toFixed(2) + "%)"; }
                            function() { return (this.value * 1).toFixed(2) + "%" }
                        }
                        // position="outside"
                        verticalOffset={10} 
                        // topIndent={20} 
                        // style={{ transform: 'translateY(-5px)' }}
                        />
                    </CommonSeriesSettings>
                    <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                </Chart>
                </div>
            </div>
        )
    }
}

export default LaporanRoiPerKategori