import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { dokumenType } from 'dataSource/lookup'
import ModalActionDokumen from './actionDataDokumen'
import { download, imageSource } from 'plugin/helper'
import ModalPreviewImage from './previewImage'

var date = new Date().toISOString()

class DataGridDokumen extends Component {
    
    constructor(props){
        super(props)

        this.state = {
            formData: []
        }

        this.dataGridDokumenRef = React.createRef()
        this.popupModalDokumenRef = React.createRef()
        this.popupModalPreviewImageRef = React.createRef()

        this.currentData = []
        this.data = []
        this.loadDummyData = []

        this.pesertaId = 0
    }

    reloadData = async(pesertaId) => {
        this.pesertaId = pesertaId
        var loadAPI = `dokumen-pesertas/getByPesertaId/${pesertaId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
        if(response.length > 0){
            this.currentData = response
        }

        this.forceRefresh()
    }

    retrieveData = async() => {
        return this.currentData
    }

    passDataToParent = () => {
        var data = {
            dataDokumen : this.dataGridDokumenRef.current.getDataSource(),
            deletedDataDokumen : this.dataGridDokumenRef.current.getDeletedIndex(),
            currentDataDokumen : this.currentData,
        }
        return data
    }

    showModalDokumen = (data = {}) => {
        var dataCurrent = this.dataGridDokumenRef.current.getDataSource()
        this.popupModalDokumenRef.current.show()
        this.popupModalDokumenRef.current.retrieveData(data,dataCurrent.length)
    }

    editDataDokumen = async (data) => {
        try{
            var key = data.id
            if(key){
                this.dataGridDokumenRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
            }else{
                this.dataGridDokumenRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
            }
            
            
            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'

            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }catch(e){
            console.log(e)
        }
    }

    deleteDataDokumen = (data) => {
        this.dataGridDokumenRef.current.push([
            {
                type: 'remove',
                key : data.id
            }
        ])
    }

    getDatagridColumnConfiguration = (actionType) => {
        var columnConfiguration = [
            {
                dataField: 'dokumenNo',
                caption: 'No Dokumen',
            },
            {
                dataField: 'dokumenTypeId',
                caption: 'Tipe Dokumen',
                lookup: {
                    dataSource: dokumenType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'dokumenTypeName',
                }
            },
            {
                dataField: 'dokumenDate',
                caption: 'Tanggal Dokumen',
                dataType:"date",
                format: 'dd MMM yyyy'
            },
        ]

        columnConfiguration.unshift({
            type: 'buttons',
            buttons: [
                {
                    text : 'Download',
                    hint : 'Download',
                    onClick : (e) => {
                        this.downloadFile(e.row.data)
                    },
                },
                {
                    text : 'Preview',
                    hint : 'Preview',
                    onClick : (e) => {
                        this.previewFile(e.row.data)
                    },
                },
                {
                    text : 'Edit',
                    hint : 'Edit',
                    onClick : (e) => {
                        this.showModalDokumen(e.row.data)
                    },
                },
            ],
        })

        // if(actionType == 'view'){
        //     columnConfiguration.unshift({
        //         type: 'buttons',
        //         buttons: [
        //             {
        //                 text : 'Edit',
        //                 hint : 'Edit',
        //                 onClick : (e) => {
        //                     this.showModalDokumen(e.row.data)
        //                 },
        //             },
        //         ],
        //     })
        // }

        return columnConfiguration
    }

    onToolbarPreparingDokumen = (e) => {
        if(this.props.actionType !== 'view'){
                e.toolbarOptions.items.unshift(
                    {
                        location: 'after',
                        widget: 'dxButton',
                        options: {
                        icon: 'plus',
                        text: 'Tambah Dokumen',
                        onClick: (e) => {
                            this.showModalDokumen({})
                        }
                    }
                }
            )
        }
    }

    removeState = () => {
        this.currentData = []
        this.loadDummyData = []

        this.setState({
            formData: []
        })
    }
    
    forceRefresh = () => {
        this.dataGridDokumenRef.current.forceRefresh(true)
    }
    
    downloadFile = (dokumen) => {
        download(imageSource(dokumen.dokumenFileContentType,dokumen.dokumenFile),dokumen.dokumenFileName,dokumen.dokumenFileName)
    }

    previewFile = (dokumen) => {
        var data = {
            file : dokumen.dokumenFile,
            fileContentType : dokumen.dokumenFileContentType
        }
        this.popupModalPreviewImageRef.current.show()
        this.popupModalPreviewImageRef.current.retrieveData(data)

    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridDokumenRef}
                    loadAPI={`dokumen-pesertas`}
                    insertAPI='dokumen-pesertas'
                    updateAPI='dokumen-pesertas'
                    deleteAPI='dokumen-pesertas' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData = {this.retrieveData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Data Dokumen"}

                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Dokumen'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.getDatagridColumnConfiguration(this.props.actionType)} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing = {this.onToolbarPreparingDokumen}

                    height = {'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalActionDokumen
                    store = {this.props.store}
                    editDataDokumen = {this.editDataDokumen}
                    ref = {this.popupModalDokumenRef}
                />
                <ModalPreviewImage
                    store = {this.props.store}
                    ref = {this.popupModalPreviewImageRef}
                />
            </React.Fragment>
        )
    }
}

export default DataGridDokumen