import React, { Component } from 'react'
import Tabs from 'devextreme-react/tabs'

export default class DevextremeTab extends Component {
    constructor(props){
        super(props)
        
        this.state = {
            selectedTabIndex: props.selectedIndex || 0,
        } 
    }

    onTabSelectionChanged= (args) =>{
        if(args.name === 'selectedIndex') {
            this.setState({
                selectedTabIndex: args.value
            })

            if(typeof this.props.onOptionChanged === 'function'){
                this.props.onOptionChanged(args)
            }
        }
    }
    render() {
        return (
            <Tabs
                dataSource={this.props.dataSource}
                selectedIndex={this.state.selectedTabIndex}
                onOptionChanged={this.onTabSelectionChanged}
                items={this.props.items}
            />
        )
    }
}
