
import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { kasUmum, programSdm, statusPengisianKasUmumSdm, statusPengisianKasUmumSdm2 } from 'dataSource/lookup';
import FormModalEditPengisianKasUmum from 'components/pages/modal/sdm-umum/umum/kas-umum/pengisian-kas-umum/edit';
import FormModalKonfirmasiPengisianKasUmum from 'components/pages/modal/sdm-umum/umum/kas-umum/pengisian-kas-umum/konfirmasi';
import httpRequest from 'plugin/httprequest'
import { reportFile } from 'plugin/reportRequest'
import { formatDefaultFullDate } from 'plugin/helper'
import { confirmAlert } from 'react-confirm-alert'
import { alert, confirm } from 'devextreme/ui/dialog';
 
class PengisianKasUmum extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            // if(e.row.data.statusId !== 1){
                            //     await alert('Data ini sedang berproses!', 'Informasi')
                            // }else{                            
                            // }

                            if (e.row.data.statusId == 2){
                                await alert("Data Tidak Bisa Di Edit, Silahkan Pilih Lihat Untuk Membuka Data",'Gagal')
                            } else if (e.row.data.statusId == 3){
                                await alert("Data ini sedang aktif dan tidak bisa diedit", "Gagal");
                            } else if (e.row.data.statusId == 4){
                                await alert("Data ini sedang dibatalkan dan tidak bisa diedit", "Gagal");
                            } else if (e.row.data.statusId == 5){
                                await alert("Data ini berstatus selesai dan tidak bisa diedit", "Gagal");
                            } else {
                                this.showModalView(e.row.data, 'edit')
                            }
                        }
                    },
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalView(e.row.data, 'view')
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            // var kskUrl = 'kas-bank-transactions/getByMultipleStatusIdAndKasUmumIdAndKasUmumId/2%2C3/' + e.row.data.kasUmumId
                            // var getKsk = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, kskUrl, 'GET')

                            if (e.row.data.statusId == 2){
                                await alert("Data ini sedang di proses dan tidak bisa dikirim",'Gagal');
                            } else if (e.row.data.statusId == 3){
                                await alert("Data ini sedang aktif dan tidak bisa dikirim", "Gagal");
                            } else if (e.row.data.statusId == 4){
                                await alert("Data ini sedang dibatalkan dan tidak bisa dikirim", "Gagal");
                            } else if (e.row.data.statusId == 5){
                                await alert("Data ini berstatus selesai dan tidak bisa dikirim", "Gagal");
                            } else {
                                var konfirm = await confirm('Apakah anda yakin ingin mengirim data ini ?', 'Konfirmasi')
                                if (konfirm) {
                                    this.kirim(e.row.data)
                                }   
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data.statusId == 2) {
                                await alert("Data ini sedang di proses dan tidak bisa batal",'Gagal');
                            } else if (e.row.data.statusId == 3) {
                                await alert("Data ini sedang di aktif dan tidak bisa batal",'Gagal')
                            } else if (e.row.data.statusId == 4){
                                await alert("Data ini sedang dibatalkan dan tidak bisa dibatalkan", "Gagal");
                            } else if (e.row.data.statusId == 5){
                                await alert("Data ini berstatus selesai dan tidak bisa dibatalkan", "Gagal");
                            } else {
                                var konfirm = await confirm('Apakah anda yakin ingin membatalkan data ini ?', 'Konfirmasi')
                                if (konfirm) {
                                    this.batal(e.row.data)
                                }
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField: 'transactionNo',
                caption: 'No Transaksi'
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program'
                }
            },
            {
                dataField: 'kasUmumId',
                caption: 'Kas Umum',
                lookup: {
                    dataSource: kasUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaKas'
                }
            },
            {
                dataField: 'pengajuan',
                caption: 'Pengajuan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'requestDate',
                        caption: 'Tanggal Pengajuan',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'requestAmount',
                        caption: 'Nominal Pengajuan',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'description',
                        caption: 'Keterangan Pengajuan'
                    }
                ]
            },
            {
                dataField: 'penerimaan',
                caption: 'Konfirmasi Penerimaan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'receivedDate',
                        caption: 'Tanggal Diterima',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'receivedAmount',
                        caption: 'Nominal Diterima',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'kasUmumId',
                caption: 'Kas Umum',
                lookup: {
                    dataSource: kasUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaKas'
                }
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusPengisianKasUmumSdm(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value'
                }
            },
        ]

        this.showModalViewRef = React.createRef()
        this.showModalKonfirmasiRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalView = async (data, type) => {
        try {
            var loadAPIKasBank = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/${data.id}`);
        } catch (error) {
            console.log(error);
        }
        if (type !== 'add') {
           await this.showModalViewRef.current.retrieveData(loadAPIKasBank, type)
        }
        this.showModalViewRef.current.show()
        // this.showModalViewRef.current.getTotalPengisian(data)
    }

    showModalKonfirmasi = (data) => {
        if (data) {
            this.showModalKonfirmasiRef.current.retrieveData(data)
        }
        this.showModalKonfirmasiRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Pengajuan Pengisian',
                    onClick: () => {
                        this.showModalView({}, 'add')
                    }
                }
            },
        )
    }

    printLaporan = async (data) => {
        var responseMaster = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getResumeReportPenanggungJawab/${data.id}`, 'GET')
        var responseDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getRealisasiReportPenanggungJawab/${data.id}`, 'GET')
        let detail = []
        var no = 1
        for (var dataDetail of responseDetail) {
            var result = {
                no: no++,
                keterangan: dataDetail.keterangan,
                jumAmount: dataDetail.jumAmount,
            }
            detail.push(result)
        }
        if (responseMaster !== null) {
            var data = {
                template: {
                    'shortid': 'r1lH9b-iiY',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "tanggalTerima": responseMaster.tanggalTerima != null ? formatDefaultFullDate(responseMaster.tanggalTerima) : '',
                    "jumlahPembayaran": responseMaster.jumlahPembayaran,
                    "nominalTerima": responseMaster.nominalTerima,
                    "pengambalianTanggal": responseMaster.pengambalianTanggal != null ? formatDefaultFullDate(responseMaster.pengambalianTanggal) : '',
                    "pengembalianAmount": responseMaster.pengembalianAmount,
                    "saldoAkhir": responseMaster.saldoAkhir,
                    "dataPengisian": detail,
                },
            }
            reportFile(data, true, `LAPORAN-PERTANGGUNG-JAWABAN.pdf`)
        } else {
            confirmAlert({
                title: 'Tidak ditemukan!',
                message: 'Data tidak ditemukan',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    batal = async (data) => {
        try {
            var updateData = data
            updateData.statusId = 4
            var upadateAPI = 'kas-bank-transactions'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, upadateAPI, 'PUT', {
                key: data.id,
                values: updateData
            }, data)

            var text = 'Data Berhasil Dibatalkan!'
            await alert(text, 'Berhasil!')
            this.forceRefresh()
        } catch (e) {
            console.log(e)
            await alert(e, 'Gagal!')
        }
    }

    kirim = async (data) => {
        try {
            // var tanggalPengajuan = (new Date(data.transactionDate)).getTimezoneOffset() * 60000; //offset in milliseconds
            // tanggalPengajuan = (new Date(new Date(data.transactionDate) - tanggalPengajuan)).toISOString();

            var tanggalPengajuan = (new Date(data.requestDate)).getTimezoneOffset() * 60000; //offset in milliseconds
            tanggalPengajuan = (new Date(new Date(data.requestDate) - tanggalPengajuan)).toISOString();

            var tanggalPenerimaan = (new Date(data.receivedDate)).getTimezoneOffset() * 60000; //offset in milliseconds
            tanggalPenerimaan = (new Date(new Date(data.receivedDate) - tanggalPenerimaan)).toISOString();

            // var karyawanUrl = 'karyawans/getByUsername/' + this.props.store.getState().sessionUser
            // var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, karyawanUrl, 'GET')

            // var entitiUrl = 'entitis/findByTypeAndReff/KR/{reff}?reff='+getKaryawan.id
            // var entitiUrl = `entitis/findByTypeAndReffByPath/KR/${getKaryawan.id}`
            // var getEntiti = await httpRequest(process.env.REACT_+APP_BACKEND_ACC, this.props.store, entitiUrl, 'GET')

            var postData = {
                "id": data.id,
                "kasUmumId": data.kasUmumId,
                "keteranganPenggunaan": data.description,
                "noPengajuan": data.transactionNo,
                // "nominalPenerimaan": data.receivedAmount,
                "nominalPengajuan": data.requestAmount,
                "programId": data.programId,
                "rekeningBankId": data.rekBankId,
                // "tanggalPenerimaan": tanggalPenerimaan,
                "tanggalPengajuan": tanggalPengajuan,
                "username": this.props.store.getState().sessionUser,
                // "entityId" : getEntiti.id
            }

            var postAPI = 'kas-umum-receives-konfirmasi'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'POST', {
                values: postData
            })

            var getKasBankId = `kas-bank-transactions/getByKasUmumId/${data.kasUmumId}`;
            var resKasBankId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, getKasBankId, 'GET');
            
            for (let value of resKasBankId){
                if (data.transactionNo === value.transactionNo) {
                    var insertAPITask = `pembayaran-umums/taskPengajuanKasUmum/${value.id}`
                }
            }

            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, insertAPITask, 'POST', {
                values: {}
            });


            var text = 'Data Berhasil Dikirim!'
            await alert(text, 'Berhasil!')

            this.forceRefresh()
        } catch (e) {
            await alert(e, 'Gagal!')
        }
    }

    loadData = async () => {
        let responseAPI = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-bank-transactions/getKuRiwayatPengisianByFunction/1', 'GET');
        let data = [];

        for (let value of responseAPI) {
            let dataValue = {
                "id": value,
                "description": value.description,
                "program": value.program,
                "request_date": value.request_date,
                // "status": value.status,
                "transaction_no": value.transaction_no,
                "nama_kas": value.nama_kas,
                "request_amount": value.request_amount,
                "transaction_amount": value.transaction_amount,
                "rek_name": value.rek_name,
                "status_ku": value.status_ku,
                "transaction_date": value.transaction_date,
                "kas_umum_id": value.kas_umum_id
            }

            data.push(dataValue);
        }

        data = data.filter(val => val.status_ku === 'OPEN' || val.status_ku === 'KIRIM');

        return data;
    }
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pengajuan Pengisian Kas Umum</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='kas-bank-transactions/getByMultipleStatusId/1%2C2?size=9999'
                    insertAPI='kas-bank-transactions'
                    updateAPI='kas-bank-transactions'
                    deleteAPI='kas-bank-transactions'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    // remoteOperations={true}
                    // useArraySource = {true}
                    // ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengisian Kas Umum"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengisian Kas Umum'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    onToolbarPreparing={this.onToolbarPreparing}
                    // SummaryConfiguration={this.summary}

                    height={'calc(90vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalEditPengisianKasUmum
                    ref={this.showModalViewRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalKonfirmasiPengisianKasUmum
                    ref={this.showModalKonfirmasiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PengisianKasUmum