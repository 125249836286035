import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react';
import { dasarNilai, tipeTransaksiSdmUmum,payrollKategoriSub, potonganGroups } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormModalPotongan extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{
                faktorPph21:true,
                status:1,
                nominal:0,
                persenGapok:0,
                persenGajiTunjangan:0
                
            },
            popupVisible:false,
        }

        this.formRef = React.createRef()

        this.formPotongan = [
            {
                dataField:"potonganCode",
                label:{
                    text:"Kode"
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField:"potonganName",
                label:{
                    text:"Nama Potongan"
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField:"transactionTypeId",
                label:{
                    text:"Tipe Transaksi",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'transactionTypeName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField:"persenGapok",
                label:{
                    text:"Presentase Gaji Pokok",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00 %',
                    step:0,
                }
            },
            {
                dataField:"nominal",
                label:{
                    text:"Nominal Potongan",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:"faktorPph21",
                label:{
                    text:"Faktor PPH 21",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField:"persenGajiTunjangan",
                label:{
                    text:"Presentase Gaji Pokok + Tunjangan",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00 %',
                    step:0,
                }
            },
            {
                dataField:"dasarNilaiId",
                label:{
                    text:"Dasar Nilai",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: dasarNilai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dasarNilai', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField:"potonganPinjaman",
                label:{
                    text:"Potongan Pinjaman",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField:"payrollCategorySubId",
                label:{
                    text:"Payroll Kategori",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: payrollKategoriSub(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categorySubName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField:"status",
                label:{
                    text:"Status",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Aktif'
                        },
                        {
                            id: false,
                            value: 'Non Aktif'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField: 'potonganGroupId',
                label: {
                    text: 'Potongan Group'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: potonganGroups(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'groupName',
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: async () => {
                    var validate = this.validate()

                    if(validate) {
                        this.submitData()
                    }
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: async () => {
                    var validate = this.validate()

                    if(validate) {
                        this.updateData()
                    }
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.newData = true
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster:data
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster:{
                faktorPph21:true,
                status:1,
                nominal:0,
                persenGapok:0,
                persenGajiTunjangan:0
            },
            popupVisible: false
        })
        this.props.forceRefresh();
    }

    validate = () => {
        var formValidate = this.formRef.current.instance.validate()
        return formValidate.isValid
    }

    submitData = async () => {
        try { 
            var loadAPI = 'potongan-types'
            var text = 'Sukses Input Potongan'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"POST",{
                values: this.state.DataMaster
            });

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    updateData = async () => {
        try {
            var loadAPI = 'potongan-types'
            var text = 'Sukses Update Potongan'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"PUT",{
                values: this.state.DataMaster,
                key:this.state.DataMaster
            },this.state.DataMaster);

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={'Potongan'} 
                width={700}
                height={600}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <Form
                colCount={1}
                id={'formPotongan'}
                formData={this.state.DataMaster}
                items={this.formPotongan}
                labelLocation = 'left'
                ref={this.formRef}
            />
            </Popup>
        )
    }
}

export default FormModalPotongan