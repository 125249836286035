import React, { Component } from 'react'
import { Form } from "devextreme-react";
import {alert,confirm} from "devextreme/ui/dialog";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import ModalDetailCashFlowConfig from 'components/pages/modal/global-setting/modalDetailCashFlowConfig'
import httpRequest from 'plugin/httprequest'
import ModalCashFlowConfig from 'components/pages/modal/global-setting/modalCashFlowConfig'
import { reportHeaderFilter } from 'dataSource/lookup';
import ModalAddReportHeader from 'components/pages/modal/global-setting/modalAddReportHeader';
import { showLoading } from 'redux/actions/loading';

class CashFlowConfig extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{
                reportHeaderId: 0
            }
        }

        this.formItem = [
            {
                dataField: 'reportType',
                label: {
                    text: 'Jenis Laporan',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'OJK',
                            value: 'Laporan OJK'
                        },
                        {
                            id: 'LK',
                            value: 'Laporan Keuangan'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    onValueChanged : async (e) => {
                        var reportHeaderOption = this.formRef.current.instance.getEditor('reportHeaderId')
                        var dataReportType = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-headers/getAllReportType/${e.value}`)
                        if(e.value){
                            reportHeaderOption.option('dataSource',dataReportType)
                        }
                    }
                }
            }, 
            {
                dataField: 'reportHeaderId',
                label: {
                    text: 'Nama Laporan',
                    alignment: "left",
                    location: "left"
                },
                colSpan: 2,
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.reportCode + ' - ' + item.reportName
                    }, // provides display values
                }
            }, 
            {
                itemType: 'group',
                colSpan: 3,
                colCount: 5,
                alignItemLabels: false,
                items: [
                    {
                        itemType: "button",
                        buttonOptions: {
                          text: "Filter",
                          // type:"default",
                          elementAttr: { class: "bg-dapen-default" },
                          onClick: () => {
                            this.dataGridRef.current.forceRefresh(true)
                          },
                          width: '100%'
                        },
                        horizontalAlignment: "left"
                    },
                    {
                        itemType: "button",
                        colSpan: 2,
                        buttonOptions: {
                          text: "Tambah Laporan Baru",
                          // type:"default",
                          elementAttr: { class: "bg-success" },
                          onClick: () => {
                            this.modalAddReportHeaderRef.current.show()
                          },
                          width: '100%'
                        },
                        horizontalAlignment: "left"
                    },
                    {
                        itemType: "button",
                        colSpan: 2,
                        buttonOptions: {
                          text: "Hapus Laporan",
                          // type:"default",
                          elementAttr: { class: "bg-danger text-white" },
                          onClick: async () => {
                            if(this.state.DataMaster.reportHeaderId){
                                const result = await confirm('Apa anda yakin untuk menghapus mapping laporan ini?','Korfirmasi')
                                if(result){
                                    this.deleteHeader(this.state.DataMaster.reportHeaderId)
                                    alert('Mapping laporan berhasil di hapus!','Info')
                                }
                            }else{
                                alert('Harap pilih Jenis Laporan dan Nama Laporan terlebih dahulu!','Informasi')
                            }
                          },
                          width: '100%'
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ];

        this.columns = [
            {
                type: "buttons",
                width: 140,
                buttons: [
                    {
                        text: "Edit",
                        hint: "Edit",
                        icon: "edit",
                        onClick:(e) => {
                            this.modalCashFlowConfigRef.current.show(this.state.DataMaster.reportHeaderId)
                            this.modalCashFlowConfigRef.current.retriveData(e.row.data)
                        }
                    },
                    {
                        text: "Hapus",
                        hint: "Hapus",
                        icon: "delete",
                        onClick: async (e) => {
                            const result = await confirm('Apa anda yakin untuk menghapus data mapping laporan ini?','Konfirmasi')
                            if(result){
                                await this.deleteHeaderDetail(e.row.data)
                                alert('Mapping laporan berhasil di hapus!','Info')
                            }
                        }
                    },
                    {
                        text: "Ubah Detail Konfigurasi",
                        hint: "Ubah Detail Konfigurasi",
                        icon: "edit",
                        onClick:this.showModalDetailCashFlowConfig.bind(this)
                    },
                ]
            },
            {
                dataField: 'description',
                caption: 'Deskripsi'
            }, 
            {
                dataField: 'seqNo',
                caption: 'Urutan'
            }, 
            {
                dataField: 'beginningValue',
                caption: 'Nilai Awal',
                format: '#,##0.00;(#,##0.00);'
            }, 
            {
                dataField: 'parentName',
                caption: 'Induk',
            }, 
        ] 

        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalCashFlowConfigRef = React.createRef()
        this.modalDetailCashFlowConfigRef = React.createRef()
        this.modalAddReportHeaderRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalDetailCashFlowConfig = (e) => {
        this.modalDetailCashFlowConfigRef.current.show(this.state.DataMaster.reportHeaderId)
        this.modalDetailCashFlowConfigRef.current.retriveData(e.row.data)
    }

    reloadHeaderLookup = async (reportType) => {
        if(this.state.DataMaster.reportType === reportType){
            var reportHeaderOption = this.formRef.current.instance.getEditor('reportHeaderId')
            var dataReportType = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-headers/getAllReportType/${reportType}`)
            if(reportType){
                reportHeaderOption.option('dataSource',dataReportType)
            }
        }
    }

    loadData = async () => {
        var headerId = this.state.DataMaster.reportHeaderId

        var dataDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-header-details/getAllByReportHeaderId/${headerId}`)
        dataDetail = dataDetail.map(value => {
            const parent = dataDetail.find(valueParent => valueParent.id === value.parentId)
            if(parent){
                value.parentName = parent.description
            }
            return value
        })
        dataDetail = dataDetail.sort((a,b) => {
            if(a.seqNo > b.seqNo){
                return 1
            }

            if(a.seqNo === b.seqNo){
                return 0
            }

            if(a.seqNo < b.seqNo){
                return -1
            }
        })
        return dataDetail
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  onClick: (e) => {
                      if(this.state.DataMaster.reportHeaderId){
                          this.modalCashFlowConfigRef.current.show(this.state.DataMaster.reportHeaderId)
                      }else{
                        alert('Harap pilih Jenis Laporan dan Nama Laporan terlebih dahulu!','Warning')
                      }
                  },
              }
          }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    deleteHeader = async (reportHeaderId) => {
        try{
            this.props.store.dispatch(showLoading(true))
            var dataReportHeaderDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-header-details/getAllByReportHeaderId/${reportHeaderId}`)

            for(const reportHeaderDetail of dataReportHeaderDetail){
                var dataReportCOA = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-coas/getAllByReportHeaderDetailsId/${reportHeaderDetail.id}`)
                for(const reportCoa of dataReportCOA){
                    await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'report-coas','DELETE',reportCoa.id)    
                }
                await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'report-header-details','DELETE',reportHeaderDetail.id)    
            }

            await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'report-headers','DELETE',reportHeaderId)

            this.reloadHeaderLookup(this.state.DataMaster.reportType)

            this.props.store.dispatch(showLoading(false))
        }catch(e){
            console.log(e)
            alert('Terjadi Kesalahan Sistem!','Error')
            this.props.store.dispatch(showLoading(false))
        }
    }

    deleteHeaderDetail = async (data) => {
        try{
            this.props.store.dispatch(showLoading(true))
            var dataReportCOA = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-coas/getAllByReportHeaderDetailsId/${data.id}`)
            for(const reportCoa of dataReportCOA){
                await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'report-coas','DELETE',reportCoa.id)    
            }
            await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'report-header-details','DELETE',data.id)

            this.forceRefresh()
            this.props.store.dispatch(showLoading(false))
        }catch(e){
            console.log(e)
            alert('Terjadi Kesalahan Sistem!','Error')
            this.props.store.dispatch(showLoading(false))
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfigurasi Mapping Laporan Akuntansi</h2>

                <Form
                    colCount={3}
                    id={"formMaster"}
                    formData={this.state.DataMaster}
                    items={this.formItem}
                    ref={this.formRef}
                    width={"50%"}
                />

                <DevExpressDataGrid
                ref={this.dataGridRef}
                    loadAPI='cash-flow-configs'
                    insertAPI='cash-flow-configs'
                    updateAPI='cash-flow-configs'
                    deleteAPI='cash-flow-configs' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    remoteOperations = {false}

                    useArraySource = {true}
                    ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Cash Flow Config"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Konfigurasi Arus Kas'}
                    popupWidth={400} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter
                    onToolbarPreparing = {this.onToolbarPreparing}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalCashFlowConfig store={this.props.store} ref={this.modalCashFlowConfigRef} forceRefresh={this.forceRefresh}/>
                <ModalDetailCashFlowConfig store = {this.props.store} ref={this.modalDetailCashFlowConfigRef} />
                <ModalAddReportHeader store = {this.props.store} ref={this.modalAddReportHeaderRef} reloadHeaderLookup={this.reloadHeaderLookup}/>
            </div>
        )
    }
}

export default CashFlowConfig
