import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'

class ModalActionKategoriAsetAlokasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: console.log('simpan')
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'kategori',
                caption: 'KATEGORI',
                items: [
                    {
                        dataField: 'kategori',
                        label: {
                            alignment: 'left',
                            text: 'Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'kategoriName', // provides display values
                        },
                    },
                    {
                        dataField: 'subKategori',
                        label: {
                            alignment: 'left',
                            text: 'Sub Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'subKategori', // provides display values
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'alokasi',
                caption: 'ALOKASI',
                items: [
                    {
                        dataField: 'alokasi',
                        label: {
                            alignment: 'left',
                            text: 'Alokasi'
                        },
                    },
                    {
                        dataField: 'notifikasiMinus',
                        label: {
                            alignment: 'left',
                            text: 'Notifikasi Minus (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                        }
                    },
                    {
                        dataField: 'notifikasiPlus',
                        label: {
                            alignment: 'left',
                            text: 'Notifikasi Plus (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'catatan',
                caption: 'CATATAN',
                alignItemLabels: 'left',
                items: [
                    {
                        dataField: 'catatan',
                        label: {
                            alignment: 'left',
                            text: 'Catatan',
                            visible: false
                        },
                        editorType: 'dxTextArea'
                    },
                ]
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    

    retrieveData = async(type, data = null) => {    
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    loadDummyData = () => {
        var dummy = [
            {
                id: 1,
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
            {
                id: 2,
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
            {
                id: 3,
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
        ]


        return dummy
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Kategori Aset'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalActionKategoriAsetAlokasi