import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormShiftKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/shift/form';
import { jabatan, karyawan, months, tipeShift } from 'dataSource/lookup';
import ModalJadwalShift from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/shift/jadwal';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';

class ShiftKaryawan extends Component {
    constructor(props){
        super(props)
        this.state ={

        }
        this.modalShiftRef = React.createRef()
        this.modalJadwalRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalShift(e)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.hapus(e.row.data)
                        }
                    },
                    {
                        text: 'Jadwal',
                        hint: 'Jadwal',
                        onClick: (e) => {
                            this.showModalJadwal(e)
                        }
                    },
                ]
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'karyawanId',
                caption: 'Karyawan',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr:"fullName",
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'jabatanId',
                caption: 'Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            
            {
                dataField: 'shiftTypeId',
                caption: 'Shift',
                lookup: {
                    dataSource: tipeShift(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaShift', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'rotasi',
                caption: 'Rotasi',
                dataType:"boolean"
            },
            {
                dataField: 'rotasis',
                caption: 'Rotasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'rotasiType',
                        caption: 'Tipe Rotasi',
                        lookup:{
                            dataSource: [
                                {
                                    id:"1",
                                    value:'Harian'
                                },
                                {
                                    id:"2",
                                    value:'Mingguan'
                                },
                                {
                                    id:"3",
                                    value:'Bulanan'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'rotasiPeriode',
                        caption: 'Rotasi Periode',     
                    },
                    {
                        dataField: 'shiftTypeId1',
                        caption: 'Shift 1',
                        lookup: {
                            dataSource: tipeShift(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'namaShift', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'shiftTypeId2',
                        caption: 'Shift 2',
                        lookup: {
                            dataSource: tipeShift(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'namaShift', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'tanggalAwal',
                        caption: 'Awal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tanggalAkhir',
                        caption: 'Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalShift = (e) => {
        if (e.row) {
            this.modalShiftRef.current.retrieveData(e.row.data)
            this.modalShiftRef.current.newData(false)
        }
        this.modalShiftRef.current.show()
    }

    showModalJadwal = async (e) => {
        if (e.row.data.rotasi) {
            this.modalJadwalRef.current.retrieveData(e.row.data)
            this.modalJadwalRef.current.show()
        } else {
            notify({ message: 'Karyawan tidak memiliki jadwal rotasi', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'warning', 1000);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalShift(e)
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    hapus = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'karyawan-shifts', 'DELETE', data.karyawanShiftId)
            this.forceRefresh()
            notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',600);
            
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }


    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Shift Karyawan</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-karyawan-shifts?size=9999'
                    insertAPI='karyawan-shifts'
                    updateAPI='karyawan-shifts'
                    deleteAPI='karyawan-shifts' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Shift Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormShiftKaryawan
                    ref={this.modalShiftRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalJadwalShift 
                    ref={this.modalJadwalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default ShiftKaryawan