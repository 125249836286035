import React, { Component } from 'react'
import { Popup,Form } from 'devextreme-react'
import { asetKategoriSdm, months, subAsetKategoriSdm } from 'dataSource/lookup'
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormModalRegistrasiAset extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }

        this.form = [
            {
                itemType : 'group',
                items : [
                    {
                        dataField:'assetPengadaanId',
                        label:{
                            text:'No Pengadaan'
                        },
                        editorOptions: {
                            readOnly : true
                        }
                    },
                    {
                        dataField:'assetPenerimaanId',
                        label:{
                            text:'No Penerimaan'
                        },
                        editorOptions: {
                            readOnly : true
                        }
                    },
                    {
                        dataField:'tanggalPerolehan',
                        label:{
                            text:'Tanggal Perolehan'
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField:'assetCategoryId',
                        label:{
                            text:'Kategori'
                        },
                        editorType : 'dxSelectBox',
                        editorOptions : {
                            dataSource: asetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName',
                            readOnly: true
                        }
                    },
                    {
                        dataField:'assetCategorySubId',
                        label:{
                            text:'Sub Kategori'
                        },
                        editorType : 'dxSelectBox',
                        editorOptions : {
                            dataSource: subAsetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categorySubName',
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType : 'group',
                caption : 'IDENTIFIKASI ASET',
                items : [
                    {
                        dataField:'registrasiNo',
                        label:{
                            text:'No Registrasi'
                        }
                    },
                    {
                        dataField:'assetKode',
                        label:{
                            text:'Kode Aset'
                        },
                        editorOptions: {
                            readOnly : true
                        }
                    },
                    {
                        dataField:'assetName',
                        label:{
                            text:'Nama Aset'
                        }
                    },
                    {
                        dataField:'merk',
                        label:{
                            text:'Merk'
                        }
                    },
                    {
                        dataField:'spesifikasi',
                        label:{
                            text:'Spesifikasi'
                        }
                    },
                    {
                        dataField:'locationUnitId',
                        label:{
                            text:'Unit'
                        }
                    },
                    {
                        dataField:'locationKaryawanId',
                        label:{
                            text:'Karyawan'
                        }
                    },
                    {
                        dataField:'barcodeNumber',
                        label:{
                            text:'Bar Number'
                        }
                    },
                    {
                        dataField:'generateBarcode',
                        label:{
                            text:'Bar Code'
                        },
                        editorType: 'dxButton',
                        editorOptions: {
                            text: 'Generate Barcode',
                            onClick: (e) => {
                                console.log('proses')
                            }
                        }
                    },
                ]
            },
            {
                itemType : 'empty',
                colSpan: 2
            },
            {
                itemType : 'group',
                caption : 'PENYUSUTAN ASET',
                items : [
                    {
                        dataField:'amortisasiJenis',
                        label:{
                            text:'Jenis Amortisasi'
                        },
                        editorType : 'dxSelectBox',
                        editorOptions : {
                            dataSource: [
                                {
                                    id: 'GARIS_LURUS',
                                    value : 'Garis Lurus'
                                },
                                {
                                    id: 'DOUBLE_DECLINING',
                                    value : 'Double Declining'
                                },
                                {
                                    id: 'SUM_OF_YEAR',
                                    value : 'Sum of Year'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                        }
                    },
                    {
                        dataField:'amortisasiNominal',
                        label:{
                            text:'Amortisasi Nominal / Periode'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField:'amortisasiPersen',
                        label:{
                            text:'Amortisasi Persen / Periode'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00 %",
                            readOnly: true
                        },
                    },
                    {
                        dataField:'amortisasiStartDate',
                        label:{
                            text:'Tanggal Mulai Penyusutan'
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField:'amortisasiEndDate',
                        label:{
                            text:'Tanggal Akhir Penyusutan'
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.formRef.current.instance.updateData(data)
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    updateData = async () => {
        const {DataMaster} = this.state

        try{
            const response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,`assets`,'PUT',{values: DataMaster},DataMaster)

            if (response) {
                this.props.forceRefresh()
                this.hide()
                notify({ message: "Data Aset Berhasil Diubah!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Registrasi Aset'} 
                minWidth={700}
                minHeight={500}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{height: '100%'}}>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formPotongan'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation = 'left'
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalRegistrasiAset