import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { product, rekBank } from 'dataSource/lookup'
import { Form, Button } from 'devextreme-react'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultDate,formatDefaultMonthYear, PrintElem, generateExcelFile, formatNumber } from 'plugin/helper'
import httpRequest from 'plugin/httprequest';
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading'

const bulan = [
    {
        id : 1,
        namaBulan: 'Januari'
    },
    {
        id : 2,
        namaBulan: 'Februari'
    },
    {
        id : 3,
        namaBulan: 'Maret'
    },
    {
        id : 4,
        namaBulan: 'April'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Juni'
    },
    {
        id : 7,
        namaBulan: 'Juli'
    },
    {
        id : 8,
        namaBulan: 'Agustus'
    },
    {
        id : 9,
        namaBulan: 'September'
    },
    {
        id : 10,
        namaBulan: 'Oktober'
    },
    {
        id : 11,
        namaBulan: 'November'
    },
    {
        id : 12,
        namaBulan: 'Desember'
    }
]
class ReportMpDetailBulanan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formFilter: {},
            src: ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'periode',
                        label: {
                            text: 'Periode'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'MMM yyyy',
                            placeholder : 'Bulan/Tahun',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        dataField: 'paymentRekbankName',
                        label: {
                            text: 'Bank Pembayar',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'MANDIRI'
                                },
                                {
                                    id: 2,
                                    value: 'BRI'
                                },
                                {
                                    id: 3,
                                    value: 'BTPN'
                                },
                                {
                                    id: 4,
                                    value: 'BUKOPIN'
                                },
                                {
                                    id: 5,
                                    value: 'Mandiri (Bank Lain)'
                                },
                                {
                                    id: 6,
                                    value: 'Semua'
                                },
                            ],
                            valueExpr: 'value',
                            displayExpr: 'value'
                        }
                    },
                    // {
                    //     dataField: 'status',
                    //     label: {
                    //         text: 'Status',
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: [
                    //             {
                    //                 id: 1,
                    //                 value: 'Semua'
                    //             },
                    //             {
                    //                 id: 2,
                    //                 value: 'Ditunda'
                    //             },
                    //             {
                    //                 id: 3,
                    //                 value: 'Retur'
                    //             },
                    //         ],
                    //         valueExpr: 'id',
                    //         displayExpr: 'value'
                    //     }
                    // },
                    {
                        itemType: 'empty'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-detils?`
        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.paymentRekbankName) {
            if(filter.paymentRekbankName === 'Semua') {
                loadAPI = loadAPI
            } else {
                loadAPI = loadAPI+`paymentRekbankName.in=${filter.paymentRekbankName}&`
            }
        }

        // if(filter.status){
        //     if(filter.status == 1){
        //         var statusName = 'Semua'
        //     }else if(filter.status == 2){
        //         var statusName = 'Ditunda'
        //     }else{
        //         var statusName = 'Retur'
        //     }
        // }else{
        //     var statusName = 'Semua'
        // }
        
        // PERIODE
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        }

        var getMpDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var getPaymentRekBankAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `rekbanks`, 'GET')
        var getBankAll = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET')
        var jenisPenerimaAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET')

        var summaryNominalMp = 0
        var summaryNominalDitunda = 0
        var summaryNominalRetur = 0
        for(var value of getMpDetail){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'

            var getPaymentRekBank = getPaymentRekBankAll.find(val => val.id == value.paymentRekbankId)
            if(getPaymentRekBank){
                value.paymentRekbankId = getPaymentRekBank.rekName
                value.paymentRekbankNo = getPaymentRekBank.rekNo
            }else{
                value.paymentRekbankId = '-'
                value.paymentRekbankNo = '-'
            }

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
            value.mpRekeningName = value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName

            summaryNominalMp = summaryNominalMp + (value.nominalMp || 0)
            summaryNominalDitunda = summaryNominalDitunda + (value.nominalDitunda || 0)
            summaryNominalRetur = summaryNominalRetur + (value.nominalRetur || 0)
        }

        getMpDetail = getMpDetail.map(value => {
            value.nominalMp = (value.nominalMp)
            value.nominalDitunda = (value.nominalDitunda)
            value.nominalRetur = (value.nominalRetur)

            return value
        })
        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/detail-mp-bulanan',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                bankPembayar: filter.paymentRekbankName ? filter.paymentRekbankName : 'Semua',
                // status: statusName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                dataPeserta : getMpDetail,
                summaryNominalMp: (summaryNominalMp),
                summaryNominalDitunda: (summaryNominalDitunda),
                summaryNominalRetur: (summaryNominalRetur),
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))
        this.setState({
            src : responseFile
        })
    }

    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-detils?`
        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.paymentRekbankName) {
            if(filter.paymentRekbankName === 'Semua') {
                loadAPI = loadAPI
            } else {
                loadAPI = loadAPI+`paymentRekbankName.in=${filter.paymentRekbankName}&`
            }
        }

        // if(filter.status){
        //     if(filter.status == 1){
        //         var statusName = 'Semua'
        //     }else if(filter.status == 2){
        //         var statusName = 'Ditunda'
        //     }else{
        //         var statusName = 'Retur'
        //     }
        // }else{
        //     var statusName = 'Semua'
        // }
        // PERIODE
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        }

        var getMpDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var getPaymentRekBankAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `rekbanks`, 'GET')
        var getBankAll = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET'))
        var jenisPenerimaAll = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))

        var summaryNominalMp = 0
        var summaryNominalDitunda = 0
        var summaryNominalRetur = 0
        for(var value of getMpDetail){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'

            var getPaymentRekBank = getPaymentRekBankAll.find(val => val.id == value.paymentRekbankId)
            if(getPaymentRekBank){
                value.paymentRekbankId = getPaymentRekBank.rekName
                value.paymentRekbankNo = getPaymentRekBank.rekNo
            }else{
                value.paymentRekbankId = '-'
                value.paymentRekbankNo = '-'
            }

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
            value.mpRekeningName = value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName

            summaryNominalMp = summaryNominalMp + (value.nominalMp || 0)
            summaryNominalDitunda = summaryNominalDitunda + (value.nominalDitunda || 0)
            summaryNominalRetur = summaryNominalRetur + (value.nominalRetur || 0)
        }
        getMpDetail = getMpDetail.map(value => {
            value.nominalMp = (value.nominalMp)
            value.nominalDitunda = (value.nominalDitunda)
            value.nominalRetur = (value.nominalRetur)

            return value
        })
        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/detail-mp-bulanan',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                bankPembayar: filter.paymentRekbankName ? filter.paymentRekbankName : 'Semua',
                // status: statusName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                dataPeserta : getMpDetail,
                summaryNominalMp: (summaryNominalMp),
                summaryNominalDitunda: (summaryNominalDitunda),
                summaryNominalRetur: (summaryNominalRetur),
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'DETAIL MANFAAT PENSIUN BULANAN'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'DETAIL MANFAAT PENSIUN BULANAN')
        store.dispatch(showLoading(false))
        return responseFile
    }

    exportExcel = async() => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-detils?`
        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.paymentRekbankName) {
            if(filter.paymentRekbankName === 'Semua') {
                loadAPI = loadAPI
            } else {
                loadAPI = loadAPI+`paymentRekbankName.in=${filter.paymentRekbankName}&`
            }
        }

        // if(filter.status){
        //     if(filter.status == 1){
        //         var statusName = 'Semua'
        //     }else if(filter.status == 2){
        //         var statusName = 'Ditunda'
        //     }else{
        //         var statusName = 'Retur'
        //     }
        // }else{
        //     var statusName = 'Semua'
        // }
        // PERIODE
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        }

        var getMpDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var getPaymentRekBankAll = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `rekbanks`, 'GET')
        var getBankAll = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET'))
        var jenisPenerimaAll = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))

        var index = 1
        var summaryNominalMp = 0
        for(var value of getMpDetail){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'

            var getPaymentRekBank = getPaymentRekBankAll.find(val => val.id == value.paymentRekbankId)
            if(getPaymentRekBank){
                value.paymentRekbankId = getPaymentRekBank.rekName
                value.paymentRekbankNo = getPaymentRekBank.rekNo
            }else{
                value.paymentRekbankId = '-'
                value.paymentRekbankNo = '-'
            }

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            summaryNominalMp = summaryNominalMp + (value.nominalMp || 0)

            var dataValue = {
                id: index++,
                nik: value.nik,
                fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                mpRekeningName: value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName,
                mpRekeningNo: value.mpRekeningNo,
                mpRekeningBankId: value.mpRekeningBankId,
                nominalMp: value.nominalMp ? (value.nominalMp) : '0',
            }

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DETAIL MANFAAT PENSIUN BULANAN');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A1',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                {name: 'NIK'},
                {name: 'Nama Peserta'},
                {name: 'Nama Rekening Penerima'},
                {name: 'Nomor Rekening Penerima'},
                {name: 'Bank Penerima'},
                {name: 'Nominal Pembayaran\r\n(Rp)'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }
                }))
            }

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BDETAIL MANFAAT PENSIUN BULANAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BDETAIL MANFAAT PENSIUN BULANAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BDETAIL MANFAAT PENSIUN BULANAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // worksheet.getCell('A3').value = 'Kode'
            // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = ': RESUME MANFAAT PENSIUN'

            // worksheet.getCell('A5').value = 'Tanggal Laporan'
            // worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A7').value = 'Periode'
            // worksheet.getCell('B7').value = `: ${filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua'}`
            
            // worksheet.getCell('A8').value = 'Produk'
            // worksheet.getCell('B8').value = `: ${productName}`

            // worksheet.getCell('A9').value = 'Bank Pembayar'
            // worksheet.getCell('B9').value = `: ${filter.paymentRekbankName ? filter.paymentRekbankName : 'Semua'}`

            worksheet.mergeCells(`A${lastTableRowNum + 1}:F${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'}
            worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' }};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }};

            worksheet.getCell(`G${lastTableRowNum + 1}`).value = (summaryNominalMp)
            worksheet.getCell(`G${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }};

            worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            for(var i = 2; i <= data.length + 1; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 30;

            const Data4 = worksheet.getColumn(4);
            Data4.width = 30;

            const Data5 = worksheet.getColumn(5);
            Data5.width = 30;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = 30;
            Data7.numFmt = '#,##0.00';
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DETAIL MANFAAT PENSIUN BULANAN.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"nik", 
        //         to:"NIK"
        //     },
        //     {
        //         from:"fullName", 
        //         to:"Nama Lengkap"
        //     },
        //     {
        //         from:"penerimaName", 
        //         to:"Nama Penerima"
        //     },
        //     {
        //         from:"manfaatPensiunPenerimaTypeId", 
        //         to:"Relasi Penerima"
        //     },
        //     {
        //         from:"paymentRekbankNo", 
        //         to:"Rekening Pembayar"
        //     },
        //     {
        //         from:"paymentRekbankId", 
        //         to:"Bank Pembayar"
        //     },
        //     {
        //         from:"mpRekeningNo", 
        //         to:"Nomor Rekening Penerima"
        //     },
        //     {
        //         from:"mpRekeningName", 
        //         to:"Nama Rekening Penerima"
        //     },
        //     {
        //         from:"mpRekeningBankId", 
        //         to:"Bank Penerima"
        //     },
        //     {
        //         from:"nominalMp", 
        //         to:"Nominal MP"
        //     },
        //     {
        //         from:"nominalDitunda", 
        //         to:"Nominal Ditunda"
        //     },
        //     {
        //         from:"nominalRetur", 
        //         to:"Nominal Retur"
        //     },
        //     {
        //         from:"returDescription", 
        //         to:"Keterangan Retur"
        //     }
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'RESUME MANFAAT PENSIUN'
        //     },
        //     {
        //         A: 'Tanggal Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Periode',
        //         B: null,
        //         C: ':',
        //         D: filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        //     {
        //         A: 'Status',
        //         B: null,
        //         C: ':',
        //         D: statusName
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getMpDetail){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_DETAIL_MANFAAT_PENSIUN')
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Detail Manfaat Pensiun Bulanan</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.formRef}
                            formData={this.state.formFilter}
                            items={this.formItem}
                            labelLocation={"left"}
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportMpDetailBulanan