import React, { Component } from 'react'
import { Popup, ScrollView, Form, FileUploader } from 'devextreme-react'
import { productDefault, custodies, rekeningBank, banks, currency, kpds, months, pejabat} from 'dataSource/lookup'
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage'

class ModalBiayaSinvest extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                ppn: 0,
                kurs: 1,
                currencyId: 1001
            },
            DataDocumentIns: {},
            DataDocumentTag: {},
            uploadedDataTagihan: [],
            uploadedDataInstruksi: []
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.fileUploderRef = React.createRef()

        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.idBiaya = 0

        this.popupToolbarItem1 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submit.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Ubah',
                    onClick: this.update.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                caption: 'INSTRUKSI',
                items: [
                    {
                        dataField: 'noInstruksi',
                        label: {
                            alignment: 'left',
                            text: 'No Instruksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tglInstruksi',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Instruksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Tanggal Instruksi tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'tglDebet',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Debet'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Tanggal Debet tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    var dataKpd = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `kpds`,
                                        "GET"
                                    );

                                    var filterByProduct = dataKpd.filter(value => value.productId === e.selectedItem.id)
                                    var options = this.formMasterRef.current.instance.getEditor('kpdId')
                                    options.option('dataSource', filterByProduct)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Produk tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pembayaranRekNo',
                        label: {
                            alignment: 'left',
                            text: 'No. Rek S-Invest'
                        },
                        // validationRules: [
                        //     {
                        //         type: 'required',
                        //         message: 'Nomor Rekening tidak boleh kosong'
                        //     }
                        // ]
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            alignment: 'left',
                            text: 'KPD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: kpds(this.props.store),
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'KPD tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'custodyId',
                        label: {
                            alignment: 'left',
                            text: 'Bank S-Invest'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'custodyName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Bank S-Invest tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'TAGIHAN',
                items: [
                    {
                        dataField: 'noTagihan',
                        label: {
                            alignment: 'left',
                            text: 'No Tagihan'
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Produk tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'tglTagihan',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Tagihan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Tanggal Tagihan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'periodeTahun',
                        label: {
                            alignment: 'left',
                            text: 'Periode Tahun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Periode Tahun tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'periodeBulan',
                        label: {
                            alignment: 'left',
                            text: 'Periode Bulan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Periode Bulan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'nominalTagihan',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Tagihan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                if (e.value) {
                                    var ppn = this.formMasterRef.current.instance.getEditor('ppn').option('value')
                                    var totalTagihan = e.value + ppn
                                    this.formMasterRef.current.instance.updateData({ totalTagihan: totalTagihan })
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Nominal Tagihan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'ppn',
                        label: {
                            alignment: 'left',
                            text: 'PPN (10 %)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nomTag = this.formMasterRef.current.instance.getEditor('nominalTagihan').option('value')
                                var totalTagihan = nomTag + e.value
                                this.formMasterRef.current.instance.updateData({ totalTagihan: totalTagihan })
                            }
                        },
                        // validationRules: [
                        //     {
                        //         type: 'required',
                        //         messsage: 'Nominal Tagihan tidak boleh kosong'
                        //     }
                        // ]
                    },
                    {
                        dataField: 'totalTagihan',
                        label: {
                            alignment: 'left',
                            text: 'Total Tagihan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                            step: 0
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Total Tagihan tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'REKENING',
                items: [
                    {
                        dataField: 'rekDebetId',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Debet'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.rekNo + ' - ' + e.keterangan
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData({ bankId: e.selectedItem.bankId })
                                }
                            }
                            // readOnly: true,
                        },
                        // validationRules: [
                        //     {
                        //         type: 'required',
                        //         messsage: 'Periode Bulan tidak boleh kosong'
                        //     }
                        // ]
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            alignment: 'left',
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                            readOnly: true
                        },
                        // validationRules: [
                        //     {
                        //         type: 'required',
                        //         messsage: 'Periode Bulan tidak boleh kosong'
                        //     }
                        // ]
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                            readOnly: true
                        },
                        // validationRules: [
                        //     {
                        //         type: 'required',
                        //         messsage: 'Periode Bulan tidak boleh kosong'
                        //     }
                        // ]
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            alignment: 'left',
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                            step: 0
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Kurs tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'DOKUMEN',
                items: [
                    {
                        dataField: 'pejabat1',
                        label: {
                            alignment: 'left',
                            text: 'Pejabat 1'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Pejabat 1 tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pejabat2',
                        label: {
                            alignment: 'left',
                            text: 'Pejabat 2'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Pejabat 2 tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
        ]
    }

    onUploadInstruksi = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocumentIns };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}Base64`] = formattedFileData.base64data;
                DataDocument[`${fieldName}Base64ContentType`] = formattedFileData.mime;
                this.setState({
                    DataDocumentIns: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    onUploadTagihan = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocumentTag };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}Base64`] = formattedFileData.base64data;
                DataDocument[`${fieldName}Base64ContentType`] = formattedFileData.mime;
                this.setState({
                    DataDocumentTag: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    previewFile = async (state, fileName) => {
        var src = imageSource(state[`${fileName}Base64ContentType`], state[`${fileName}Base64`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    generateNoKonf = async (isNew) => {
        if (isNew) {
            var DataMaster = { ...this.state.dataMaster }
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `bank-transactions/generate-no-konfirmasi/BIAYA_SINVEST`,
                "POST", {
                values: {}
            }
            );

            DataMaster.noInstruksi = response
            this.setState({
                dataMaster: DataMaster
            })
        }
    }

    retrieveData = async (data) => {
        var DataMaster = { ...this.state.dataMaster }
        this.idBiaya = data.id
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `bank-transactions/${data.id}`,
            "GET",
        );
        DataMaster.noInstruksi = response.instruksiNo
        DataMaster.tglInstruksi = response.instruksiDate
        DataMaster.tglDebet = response.paymentDate
        DataMaster.productId = response.productId
        DataMaster.kpdId = response.kpdId
        DataMaster.noTagihan = response.invoiceNo
        DataMaster.tglTagihan = response.invoiceDate
        DataMaster.periodeTahun = response.periodeYear
        DataMaster.periodeBulan = response.periodeMonth
        DataMaster.nominalTagihan = response.nominalTransaksi
        DataMaster.ppn = response.ppn
        DataMaster.totalTagihan = response.nominalBersih
        DataMaster.rekDebetId = response.paymentRekBankId
        DataMaster.currencyId = response.currencyId
        DataMaster.kurs = response.currencyRate
        DataMaster.pejabat1 = parseInt(response.pejabat1)
        DataMaster.pejabat2 = parseInt(response.pejabat2)
        DataMaster.custodyId = response.custodyId
        DataMaster.pembayaranRekNo = response.pembayaranRekNo

        this.setState({
            dataMaster: DataMaster
        })

        await this.retrieveFile(response.fileInstruksiId, response.fileInvoiceId)
    }

    retrieveFile = async (fileInstruksiId, fileInvoiceId) => {
        try {
            var responseInst = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `file-investasis/${fileInstruksiId}`,
                "GET",
            );
            var responseTag = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `file-investasis/${fileInvoiceId}`,
                "GET",
            );

            var DataDocumentIns = { ...this.state.DataDocumentIns };
            var DataDocumentTag = { ...this.state.DataDocumentTag };
            if (responseTag && responseInst) {
                DataDocumentTag[`id`] = responseTag.id;
                DataDocumentTag[`fileName`] = responseTag.fileName;
                DataDocumentTag[`fileBase64`] = responseTag.fileBase64;
                DataDocumentTag[`fileBase64ContentType`] = responseTag.fileBase64ContentType;

                DataDocumentIns[`id`] = responseInst.id;
                DataDocumentIns[`fileName`] = responseInst.fileName;
                DataDocumentIns[`fileBase64`] = responseInst.fileBase64;
                DataDocumentIns[`fileBase64ContentType`] = responseInst.fileBase64ContentType;
                this.setState({
                    DataDocumentIns: DataDocumentIns,
                    DataDocumentTag: DataDocumentTag
                });
            }

        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {
                kurs: 1,
                currencyId: 1001
            },
            typeData: {}
        })
    }

    submit = async () => {
        try {
            var responseIns = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "POST", {
                values: this.state.DataDocumentIns
            }
            );

            var responseTag = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "POST", {
                values: this.state.DataDocumentTag
            }
            );

            if (responseIns && responseTag) {
                await this.submitDataBiaya(responseIns.id, responseTag.id)
            } else {
                notify({ message: 'Terjadi Kesalahan System , Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    submitDataBiaya = async (fileInstruksiId, fileTagihanId) => {
        try {
            var loadAPI = 'bank-transactions/tagihan-biaya-sinvest-create'
            var dataSubmit = { ...this.state.dataMaster }
            dataSubmit.fileInstruksiId = fileInstruksiId
            dataSubmit.fileTagihanId = fileTagihanId
            dataSubmit.tglDebet = formatDate(dataSubmit.tglDebet)
            dataSubmit.tglInstruksi = formatDate(dataSubmit.tglInstruksi)
            dataSubmit.tglTagihan = formatDate(dataSubmit.tglTagihan)
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadAPI,
                "POST", {
                values: dataSubmit
            }
            );
            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    update = async () => {
        try {
            var responseIns = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "PUT", {
                values: this.state.DataDocumentIns,
                key: this.state.DataDocumentIns.id
            }, this.state.DataDocumentIns
            );

            var responseTag = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "PUT", {
                values: this.state.DataDocumentTag,
                key: this.state.DataDocumentTag.id
            }, this.state.DataDocumentTag
            );

            if (responseIns && responseTag) {
                await this.updateDataBiaya(responseIns.id, responseTag.id)
            } else {
                notify({ message: 'Terjadi Kesalahan System , Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    updateDataBiaya = async (fileInstruksiId, fileTagihanId) => {
        try {
            var loadAPI = 'bank-transactions/tagihan-biaya-sinvest-create'
            var dataSubmit = { ...this.state.dataMaster }
            dataSubmit.id = this.idBiaya
            dataSubmit.fileInstruksiId = fileInstruksiId
            dataSubmit.fileTagihanId = fileTagihanId
            dataSubmit.tglDebet = formatDate(dataSubmit.tglDebet)
            dataSubmit.tglInstruksi = formatDate(dataSubmit.tglInstruksi)
            dataSubmit.tglTagihan = formatDate(dataSubmit.tglTagihan)
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadAPI,
                "POST", {
                values: dataSubmit
            }
            );
            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Tagihan Biaya Bank S-Invest'}
                width={'40vw'}
                height={'75vh'}

                ref={this.PopupRef}

                toolbarItems={this.props.action == 'edit' ? this.popupToolbarItem2 : this.popupToolbarItem1}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.dataMaster}
                            items={this.dataMaster}
                            scrollingEnabled={true}
                            labelLocation={"left"}
                            readOnly={this.props.action == 'view' ? true : false}
                        />

                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadInstruksi.bind(this)}
                            labelText="Upload File Instruksi"
                            showFileList={false}
                            name={"file"}
                            value={this.state.uploadedDataInstruksi}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-8">{this.state.DataDocumentIns.fileName}</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocumentIns, 'file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocumentIns.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>

                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadTagihan.bind(this)}
                            labelText="Upload File Tagihan"
                            showFileList={false}
                            name={"file"}
                            value={this.state.uploadedDataTagihan}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-8">{this.state.DataDocumentTag.fileName}</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocumentTag, 'file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocumentTag.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default ModalBiayaSinvest