import React, { Component } from 'react'
import DevExpressDataGrid from '../../../../inheritComponent/devexpressdatagrid'

class DataGridReksadana extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                dataField: 'tanggal',
                caption: 'Tanggal',
                dataType: 'date',  
                format: 'dd MMM yyyy H:m:s',
                sortOrder: 'desc'
            },
            {
                dataField: 'kodeObligasi',
                caption: 'Kode Obligasi',
            },
            {
                dataField: 'namaObligasi',
                caption: 'Nama Obligasi',
            },
            {
                dataField: 'nominal',
                caption: 'Nominal',
                format: '#,##0.00'
            },
            {
                dataField: 'harga',
                caption: 'Harga',
                format: '#,##0%'
            },
            {
                dataField: 'proceed',
                caption: 'Proceed',
                format: '#,##0.00'
            },
            {
                dataField: 'maturityDate',
                caption: 'Maturity Date',
                dataType: 'date',  
                format: 'dd MMM yyyy',
                sortOrder: 'desc'
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    loadAPI='portfolio-pasar-modal-reksadanas'
                    insertAPI='portfolio-pasar-modal-reksadanas'
                    updateAPI='portfolio-pasar-modal-reksadanas'
                    deleteAPI='portfolio-pasar-modal-reksadanas' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Portofolio Pasar Modal Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portfolio Pasar Modal Reksadana'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridReksadana
