import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { programs, sdmUnit } from 'dataSource/lookup';
import { Form } from "devextreme-react";
import ModalEditorAnggaran from 'components/pages/modal/sdm-umum/anggaran/editor';
import httpRequest from 'plugin/httprequest';
import { confirm, alert } from "devextreme/ui/dialog"

class AnggaranUnit extends Component {
    constructor(props){
        super(props)
        this.state = {
            filterData : {
              programId: null,
              unitId: null,
              tahun: null,
            }
        }

        this.columns = [
            
            {
                dataField: 'tahun',
                caption: 'Tahun Anggaran',
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'anggaranSubName',
                caption: 'Sub Tipe Anggaran',
            },
            {
                dataField: 'anggaranCode',
                caption: 'Kode Anggaran',
            },
            {
                dataField: 'anggaranName',
                caption: 'Nama Anggaran',
            },
            {
                dataField: 'unitId',
                caption: 'Unit',
                lookup: {
                  dataSource: sdmUnit(this.props.store),
                  valueExpr: 'id',
                  displayExpr: 'unitName',
                }
            },
            {
                dataField: this.props.tipeProses === 'input' ? 'nominalRequest' : this.props.stepProses === 'REQ' ? 'nominalEfektif' : 'nominalRevised',
                caption: 'Nominal Tahun',
                format: '#,##0.00;(#,##0.00);'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                  dataSource: [
                    {
                      id: "1",
                      value: "Open"
                    },
                    {
                      id: "2",
                      value: "Request"
                    },
                    {
                      id: "3",
                      value: "Verified"
                    },
                    {
                      id: "4",
                      value: "Approved"
                    },
                    {
                      id: "5",
                      value: "Request Revisi"
                    },
                    {
                      id: "6",
                      value: "Verified Revisi"
                    },
                    {
                      id: "7",
                      value: "Approved Revisi"
                    },
                    {
                      id: "9",
                      value: "Non Aktif"
                    },
                  ],
                  valueExpr: 'id',
                  displayExpr: 'value'
                }
            },
        ]

        this.filterItem = [
            {
              dataField: "programId",
              label: {
                text: "Program",
              },
              editorType: "dxSelectBox",
              editorOptions: {
                dataSource: programs(this.props.store),
                displayExpr: 'program',
                valueExpr: 'id',
                searchEnabled: true,
                deferRendering: false,
              }
            },
            {
              dataField: "unitId",
              label: {
                text: "Unit",
              },
              editorType: "dxSelectBox",
              editorOptions: {
                dataSource: sdmUnit(this.props.store),
                valueExpr: 'id',
                displayExpr: 'unitName',
                searchEnabled: true,
                deferRendering: false,
              }
            },
            {
                dataField: "tahun",
                label: {
                  text: "Tahun",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    readOnly : true
                }
              },
            {
              itemType: "button",
              buttonOptions: {
                text: "Filter",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: () => {
                  this.datagridRef.current.forceRefresh(true)
                },
              },
              horizontalAlignment: "left"
            },
          ]

        this.anggaranId = null
        this.tahun = null

        this.datagridRef = React.createRef()
        this.modalEditorRef = React.createRef()
        this.formFilterRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    componentDidMount = () => {
      this.setTahun()
    }

    loadData = async () => {
      if(this.anggaranId){
        const response = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-details/getByAnggaranId/${this.anggaranId}`)
        const subTipeAnggaranData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-sub-types?size=9999`)
        const anggaranItemData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-items?size=9999`)

        let statusFilter = []
        if(this.props.tipeProses === 'input' && this.props.stepProses === 'VER'){
          statusFilter = ['2']
        }
        if(this.props.tipeProses === 'input' && this.props.stepProses === 'APP'){
          statusFilter = ['3']
        }
        if(this.props.tipeProses === 'perubahan' && this.props.stepProses === 'VER'){
          statusFilter = ['5']
        }
        if(this.props.tipeProses === 'perubahan' && this.props.stepProses === 'APP'){
          statusFilter = ['6']
        }

        return response.filter(value => statusFilter.includes(value.status)).map(value =>{
          value.tahun = this.tahun
          const anggaranItem = anggaranItemData.find(valueItem => valueItem.id === value.anggaranItemId)
          const subTipeAnggaran = subTipeAnggaranData.find(valueSubTipe => valueSubTipe.id === anggaranItem.anggaranSubTypeId)
          value.anggaranCode = anggaranItem.anggaranCode
          value.anggaranName = anggaranItem.anggaranName
          value.anggaranSubName = subTipeAnggaran.anggaranSubName
  
          return value
        })
      }

      if((!this.state.filterData.programId || !this.state.filterData.anggaranId || !this.state.filterData.unitId)){
        return []
      }
      if(this.props.stepProses === 'REQ'){
        const response = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-details/getByAnggaranIdAndUnitId/${this.state.filterData.anggaranId}/${this.state.filterData.unitId}`)
        const subTipeAnggaranData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-sub-types?size=9999`)
        const anggaranItemData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-items?size=9999`)
        let statusFilter = []
        if(this.props.tipeProses === 'input'){
          statusFilter = ['1']
        }
        if(this.props.tipeProses === 'perubahan'){
          statusFilter = ['4']
        }
        return response.filter(value => value.programId === this.state.filterData.programId && statusFilter.includes(value.status)).map(value =>{
          value.tahun = this.state.filterData.tahun
          const anggaranItem = anggaranItemData.find(valueItem => valueItem.id === value.anggaranItemId)
          const subTipeAnggaran = subTipeAnggaranData.find(valueSubTipe => valueSubTipe.id === anggaranItem.anggaranSubTypeId)
          value.anggaranCode = anggaranItem.anggaranCode
          value.anggaranName = anggaranItem.anggaranName
          value.anggaranSubName = subTipeAnggaran.anggaranSubName
  
          return value
        })
      }
    }

    forceRefresh = () => {
      this.datagridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        if(this.props.stepProses === 'REQ' && this.props.tipeProses === 'input'){
            e.toolbarOptions.items.unshift(
              {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                      icon: 'add',
                      onClick: (e) => {
                        if(!this.state.filterData.programId){
                          alert('Harap pilih program terlebih dahulu','Error')
                        }else if(!this.state.filterData.unitId){
                          alert('Harap pilih unit terlebih dahulu','Error')
                        }else{
                          this.modalEditorRef.current.show()
                          this.modalEditorRef.current.retrieveData({
                            programId: this.state.filterData.programId,
                            unitId: this.state.filterData.unitId,
                            tahun: this.state.filterData.tahun,
                            anggaranId: this.state.filterData.anggaranId
                          })
                        }
                      },
                  }
              }
            )
        }
    }

    setTahun = async () => {
      var anggaran = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggarans?size=9999`)

      if(anggaran){
        anggaran = anggaran.find(value => value.status === 'A')
  
        if(this.formFilterRef.current){
          this.formFilterRef.current.instance.updateData({tahun: anggaran.tahun,anggaranId:anggaran.id})
        }
  
        if(this.props.stepProses !== 'REQ'){
          this.anggaranId = anggaran.id
          this.tahun = anggaran.tahun
          this.forceRefresh()
        }
      }

    }

    sendRequest = async (data) => {
      const result = await confirm('Apakah anda ingin mengirim data anggaran ini?','Confirmation')
                                
      if(result){
        await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-details`,'PUT',{
          values: {
            status : '2'
          }
        },data)
  
        this.forceRefresh()
  
        alert('Data Anggaran berhasil di kirim','Success')
      }
    }

    render() {
        let stepProses,tipeProses,column
        switch(this.props.stepProses){
            case 'REQ' : stepProses = 'Pengajuan'
                        break;
            case 'VER' : stepProses = 'Verifikasi'
                        break;
            case 'APP' : stepProses = 'Persetujuan'
                        break;
            default : stepProses = ''
                        break
        }

        switch(this.props.tipeProses){
            case 'input' : tipeProses = "Anggaran Unit"
                            break;
            case 'perubahan' : tipeProses = "Perubahan Anggaran"
                            break;
            default : tipeProses = ''
                        break
        }

        let formFilter
        if(this.props.stepProses === 'REQ'){
            formFilter = <Form
            ref={this.formFilterRef}
            colCount={2}
            id={'formFilter'}
            formData={this.state.filterData}
            items={this.filterItem}
            labelLocation={'left'}
          />
        }

        if(this.props.stepProses === 'REQ' && this.props.tipeProses === 'input'){
          this.columns.unshift({
            type: 'buttons',
            buttons: [
                {
                    text : 'Edit',
                    hint : 'Edit',
                    onClick : (e) => {
                        if(e.row.data.status !== '1'){
                          alert('Hanya bisa merubah data anggaran dengan status Open','Error')
  
                          return null
                        }
                        this.modalEditorRef.current.show()
                        this.modalEditorRef.current.retrieveData(e.row.data)
                    }
                },
                {
                    text : 'Kirim Request',
                    hint : 'Kirim Request',
                    onClick : (e) => {
                        if(e.row.data.status !== '1'){
                          alert('Hanya bisa mengirim data anggaran dengan status Open','Error')
  
                          return null
                        }
                      this.sendRequest(e.row.data)
                    }
                },
            ],
          })
        }else if(this.props.stepProses === 'REQ' && this.props.tipeProses === 'perubahan'){
          this.columns.unshift({
            type: 'buttons',
            buttons: [
                {
                    text : 'Buka',
                    hint : 'Buka',
                    onClick : (e) => {
                        if(e.row.data.status !== '4'){
                          alert('Hanya bisa merubah data anggaran dengan status Approved','Error')
  
                          return null
                        }
                        this.modalEditorRef.current.show()
                        this.modalEditorRef.current.retrieveData(e.row.data)
                    }
                },
            ],
          })
        }else{
          this.columns.unshift({
            type: 'buttons',
            buttons: [
              {
                text : 'Buka',
                hint : 'Buka',
                onClick : (e) => {
                  if(e.row.data.status !== '2' && this.props.stepProses === 'VER' && this.props.tipeProses === 'input'){
                    alert('Hanya bisa mengirim data anggaran dengan status Request','Error')

                    return null
                  }
                  if(e.row.data.status !== '3' && this.props.stepProses === 'APP'  && this.props.tipeProses === 'input'){
                    alert('Hanya bisa mengirim data anggaran dengan status Verified','Error')

                    return null
                  }
                  if(e.row.data.status !== '5' && this.props.stepProses === 'VER' && this.props.tipeProses === 'perubahan'){
                    alert('Hanya bisa mengirim data anggaran dengan status Request Revisi','Error')

                    return null
                  }
                  if(e.row.data.status !== '6' && this.props.stepProses === 'APP'  && this.props.tipeProses === 'perubahan'){
                    alert('Hanya bisa mengirim data anggaran dengan status Verified Revisi','Error')

                    return null
                  }
                  this.modalEditorRef.current.show()
                  this.modalEditorRef.current.retrieveData(e.row.data)
                }
              }
            ]
          })
        }

        return (
            <div className="container-fluid">
                <h2 className="main-title">{stepProses} {tipeProses}</h2>

                {formFilter}

                <DevExpressDataGrid
                    ref= {this.datagridRef}
                    loadAPI='anggarans'
                    insertAPI='anggarans'
                    updateAPI='anggarans'
                    deleteAPI='anggarans' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useArraySource = {true}
                    ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={`${stepProses} ${tipeProses}`}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    onInitNewRow = {this.onInitNewRow}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={`${stepProses} ${tipeProses} Data`}
                    popupWidth={'70vw'} //masukan dalam ukuran pixel
                    popupHeight={'400px'} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini\

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalEditorAnggaran ref={this.modalEditorRef} store={this.props.store} forceRefresh={this.forceRefresh} stepProses={this.props.stepProses} tipeProses = {this.props.tipeProses}/>
            </div>
        )
    }
}

export default AnggaranUnit
