import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { Form } from 'devextreme-react';
import { confirmAlert } from 'react-confirm-alert'

import { alert } from 'devextreme/ui/dialog';
import { findLastDayOfMonth, formatDate } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';

class GenerateJurnalPenutup extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        // this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);
        // this.nextMonth = this.date.setMonth(this.date.getMonth() + 1)

        this.state = {
            DataMaster: {
                valueDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            }
        }

        this.columns = [
            {
                caption: 'Log Proses Generate Jurnal Penutup',
                aligment: 'left',
                columns: [
                    {
                        dataField: 'proses',
                        caption: 'Proses',
                    },
                    {
                        dataField: 'lastClosingDate',
                        caption: 'Tanggal Proses Terakhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'closingDate',
                        caption: 'Tanggal Proses',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'statusId',
                        caption: 'Status',
                        dataType: 'boolean',
                    },
                ]
            },
        ]

        this.filter = [
            {
                dataField: 'valueDate',
                label: {
                    text: 'Bulan Generate Saat Ini'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'monthAndYear',
                    calendarOptions: {
                        maxZoomLevel: 'year',
                        minZoomLevel: 'century',
                    },
                    useMaskBehavior: true,
                    openOnFieldClick: true
                }
            },
            // {
            //     dataField: 'nextDate',
            //     label: {
            //         text: 'Tanggal Generate Berikutnya'
            //     },
            //     editorType: 'dxDateBox',
            //     editorOptions: {
            //         displayFormat: 'dd MMM yyyy',
            //         useMaskBehavior: true,
            //         openOnFieldClick: true,
            //         readOnly: true
            //     },
            // },
            // {
            //     itemType: "button",
            //     buttonOptions: {
            //         text: "Filter",
            //         // type:"default",
            //         elementAttr: { class: "bg-dapen-default" },
            //         onClick: () => {
            //             this.filterSubmit()
            //         },
            //     },
            //     horizontalAlignment: "left"
            // },
        ]

        this.datagridRef = React.createRef()
        this.lastDayOfMonth = new Date()
        this.lastDayOfNextMonth = new Date()

    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Proses',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: "Apakah Anda Yakin Ingin Melalukan Proses Ini?",
                            buttons: [
                                {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.prosesGenerate()
                                    }
                                },
                                {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                },
                            ]
                        });
                    }
                },
            }
        )
    }

    prosesGenerate = async () => {
        try {
            this.props.store.dispatch(showLoading(true))

            var valueDate = this.state.DataMaster.valueDate
            var month = valueDate.getMonth()
            var year = valueDate.getFullYear()
            this.lastDayOfMonth = new Date(year, month + 1, 0)
            this.lastDayOfNextMonth = new Date(year, month + 2, 0)
            let data = [
                {
                    bulan: valueDate.getMonth() + 1,
                    modulId: 2,
                    prosesId: 2,
                    systemDate: formatDate(this.lastDayOfMonth),
                    tahun: valueDate.getFullYear()
                }
            ]
            var getBySetJurnalPenutup = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-flow-configs/getBySetJurnalPenutup/${formatDate(this.lastDayOfMonth)}`, 'GET')
            var getGlobalDate = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/CBDATE`, 'GET')
            getGlobalDate.dateValue = formatDate(this.lastDayOfNextMonth)
            if (getBySetJurnalPenutup) {
                let loadAPI = `closings/saveToClosing`
                await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPI, 'POST', {
                    values: data
                })

                let putAPIGlobal = `global-parameters`
                await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, putAPIGlobal, 'PUT', {
                    values: getGlobalDate,
                    key: getGlobalDate.id
                }, getGlobalDate)
                this.props.store.dispatch(showLoading(false))
                await alert("Proses Generate Jurnal Penutup berhasil dilakukan", 'Sukses')
                this.forceRefresh()

            }
        } catch (e) {
            console.log(e);
            this.props.store.dispatch(showLoading(false))
            await alert("Proses Generate Jurnal Penutup gagal dilakukan", 'Gagal')

        }

    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var datas = []
        var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/closingDaily/${formatDate(this.lastDayOfMonth)}/2/2`, 'GET')

        for (let data of response) {
            data.proses = 'Generate Jurnal Penutup'
            datas.push(data)
        }
        return datas
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Generate Jurnal Penutup</h3>
                <Form
                    colCount={4}
                    id={'form'}
                    formData={this.state.DataMaster}
                    items={this.filter}
                    labelLocation={'left'}
                />
                <DevExpressDataGrid
                    ref={this.datagridRef}
                    loadAPI='journal-headers'
                    insertAPI='journal-headers'
                    updateAPI='journal-headers'
                    deleteAPI='journal-headers'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Generate Jurnal Penutup"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Jurnal Voucher Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 245px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default GenerateJurnalPenutup