import React, { Component } from 'react';
import { Popup,ScrollView,Form,FileUploader } from 'devextreme-react'
import { banks, jabatan, programs, sdmUnit, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest' 
import notify from "devextreme/ui/notify";

import { confirmAlert } from 'react-confirm-alert'

class FormModalBiayaRekruitmen extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{
                metodePembayaran:1,
                pengajuanNominal:0,
                ppn:0,
                pph:0,
                biayaLain:0,
                potongan:0
            },
            DataDocument:{},
            uploadedData:[],
        }

        
        this.modalPreviewPdfRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.rekruitmenId = 0
        this.DataMaster = [
            {
                itemType:'group',
                colCount:2,
                items:[
                    {
                        itemType:'group',
                        caption:'Transaksi Biaya',
                        items:[
                                {
                                    dataField:"transactionTypeId",
                                    label:{
                                        text:"Tipe Transaksi"
                                    },
                                    editorType: 'dxSelectBox',
                                    editorOptions: {
                                        dataSource: tipeTransaksiSdmUmum(this.props.store),
                                        valueExpr: 'id',
                                        displayExpr: 'transactionTypeName',
                                        searchEnabled: true,
                                        deferRendering: false,
                                        onValueChanged: async (e) => {
                                            if (e.value) {
                                                var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'transaction-type-subs?size=9999', 'GET')
                                                data = data.filter(value => value.transactionTypeId === e.value)
            
                                                this.formMasterRef.current.instance.getEditor('transactionTypeSubId').option('dataSource',data)
                                            }
                                        }
                                    }
                                },
                                {
                                    dataField:"transactionTypeSubId",
                                    label:{
                                        text:"Sub Tipe Transaksi"
                                    },
                                    editorType: 'dxSelectBox',
                                    editorOptions:{
                                        dataSource: [],
                                        valueExpr: 'id', // contains the same values as the "statusId" field provides
                                        displayExpr: 'tipeSubName', // provides display values
                                        searchEnabled: true,
                                        deferRendering: false,
                                    },
                                },
                                {
                                    dataField: 'pengajuanNominal',
                                    label : {
                                        alignment: 'left',
                                        text: 'Nominal Pengajuan'
                                    },
                                    editorType: "dxNumberBox",
                                    editorOptions: {
                                        format: "#,##0.00",
                                        onValueChanged:(e) => {
                                            var dataMaster = {...this.state.DataMaster}
                                            dataMaster.pengajuanNominalNett = e.value + dataMaster.ppn - dataMaster.pph + dataMaster.biayaLain - dataMaster.potongan
                                            this.formMasterRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                                        },
                                    },
                                },
                                {
                                    dataField: 'ppn',
                                    label : {
                                        alignment: 'left',
                                        text: 'PPN'
                                    },
                                    editorType: "dxNumberBox",
                                    editorOptions: {
                                        format: "#,##0.00",
                                        onValueChanged:(e) => {
                                            var dataMaster = {...this.state.DataMaster}
                                            dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + e.value - dataMaster.pph + dataMaster.biayaLain - dataMaster.potongan
                                            this.formMasterRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                                        }
                                    },
                                },
                                {
                                    dataField: 'pph',
                                    label : {
                                        alignment: 'left',
                                        text: 'PPH'
                                    },
                                    editorType: "dxNumberBox",
                                    editorOptions: {
                                        format: "#,##0.00",
                                        onValueChanged:(e) => {
                                            var dataMaster = {...this.state.DataMaster}
                                            dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + dataMaster.ppn - e.value  + dataMaster.biayaLain - dataMaster.potongan
                                            this.formMasterRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                                        }
                                    },
                                },
                                {
                                    dataField: 'biayaLain',
                                    label : {
                                        alignment: 'left',
                                        text: 'Biaya Lain'
                                    },
                                    editorType: "dxNumberBox",
                                    editorOptions: {
                                        format: "#,##0.00",
                                        onValueChanged:(e) => {
                                            var dataMaster = {...this.state.DataMaster}
                                            dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + dataMaster.ppn - dataMaster.pph + e.value - dataMaster.potongan
                                            this.formMasterRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                                        }
                                    },
                                },
                                {
                                    dataField: 'potongan',
                                    label : {
                                        alignment: 'left',
                                        text: 'Potongan'
                                    },
                                    editorType: "dxNumberBox",
                                    editorOptions: {
                                        format: "#,##0.00",
                                        onValueChanged:(e) => {
                                            var dataMaster = {...this.state.DataMaster}
                                            dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + dataMaster.ppn - dataMaster.pph + dataMaster.biayaLain - e.value
                                            this.formMasterRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                                        }
                                    },
                                },
                                {
                                    dataField: 'pengajuanNominalNett',
                                    label : {
                                        alignment: 'left',
                                        text: 'Nominal Transfer'
                                    },
                                    editorType: "dxNumberBox",
                                    editorOptions: {
                                        format: "#,##0.00",
                                        readOnly:true,
                                    },
                                },
                                {
                                    dataField: 'dpAmount',
                                    label : {
                                        alignment: 'left',
                                        text: 'Uang Muka'
                                    },
                                    editorType: "dxNumberBox",
                                    editorOptions: {
                                        format: "#,##0.00"
                                    },
                                },
                                {
                                    dataField:"keterangan",
                                    label:{
                                        text:"Keterangan"
                                    }
                                },
                                {
                                    dataField:"tanggalPengajuan",
                                    label:{
                                        text:"Tanggal Pengajuan"
                                    },
                                    editorType: 'dxDateBox',
                                    editorOptions:{
                                        displayFormat: 'dd MMM yyyy',
                                        step: 0
                                    }
                                },
                                {
                                    dataField:"tanggalPembayaran",
                                    label:{
                                        text:"Tanggal Pembayaran"
                                    },
                                    editorType: 'dxDateBox',
                                    editorOptions:{
                                        displayFormat: 'dd MMM yyyy',
                                        step: 0
                                    }
                                },{
                                    dataField: 'entitiId',
                                    label: {
                                        text: 'Pembayaran Kepada',
                                        alignment: "left",
                                    },
                                    editorType: 'dxSelectBox',
                                    editorOptions: {
                                        dataSource: [],
                                        valueExpr: 'id',
                                        displayExpr: 'entityName', // provides display values
                                        searchEnabled: true,
                                        deferRendering: false,
                                    }
                                },
                            ]
                    },
                    {
                        itemType:'group',
                        items:[
                            {
                                itemType:'group',
                                caption:'Metode Pembayaran',
                                items:[
                                    {
                                        dataField:'metodePembayaran',
                                        label:{
                                            text:'Metode Pembayaran'
                                        },
                                        editorType:'dxSelectBox',
                                        editorOptions:{
                                            dataSource: [
                                                {
                                                    id:1,
                                                    value:'Transfer'
                                                },
                                                {
                                                    id:2,
                                                    value:'Cash'
                                                },
                                                {
                                                    id:3,
                                                    value:'Cash Out'
                                                },
                                            ],
                                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                                            displayExpr: 'value', // provides display values
                                            onValueChanged:(e) => {
                                                if (e.value == 1) {
                                                    this.formMasterRef.current.instance.updateData({transfer:true,cash:false,cashOut:0})
                                                    this.formMasterRef.current.instance.getEditor('cashOut').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekNo').option('readOnly',false)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekName').option('readOnly',false)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekBankId').option('readOnly',false)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',false)
                                                    // this.getEntityId(this.entitiId)
                                                }else if(e.value == 2){
                                                    this.formMasterRef.current.instance.updateData({
                                                        transfer:false,
                                                        cash:true,
                                                        cashOut:0,
                                                        tujuanRekNo:null,
                                                        tujuanRekName:null,
                                                        tujuanRekBankId:null,
                                                        tujuanRekCabang:null
                                                    })
                                                    this.formMasterRef.current.instance.getEditor('cashOut').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekNo').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekName').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekBankId').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',true)
                                                }else{
                                                    this.formMasterRef.current.instance.updateData({
                                                        transfer:false,
                                                        cash:false,
                                                        tujuanRekNo:null,
                                                        tujuanRekName:null,
                                                        tujuanRekBankId:null,
                                                        tujuanRekCabang:null
                                                    })
                                                    this.formMasterRef.current.instance.getEditor('cashOut').option('readOnly',false)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekNo').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekName').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekBankId').option('readOnly',true)
                                                    this.formMasterRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',true)
                                                }
                                            }
                                        }
                                    },
                                    {
                                        dataField: 'cashOut',
                                        label: {
                                            alignment: 'left',
                                            text: 'Cash Out'
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            format: "#,##0.00",
                                            readOnly:true
                                        },
                                    },
                                ]
                            },
                            {
                                itemType:'group',
                                caption:'Rekening Tujuan',
                                items:[
                                    {
                                        dataField:"tujuanRekNo",
                                        label:{
                                            text:"Nomor Rekening"
                                        }
                                    },
                                    {
                                        dataField:"tujuanRekName",
                                        label:{
                                            text:"Nama Rekening"
                                        }
                                    },
                                    {
                                        dataField:"tujuanRekBankId",
                                        label:{
                                            text:"Bank"
                                        },
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: banks(this.props.store),
                                            valueExpr: 'id',
                                            displayExpr: 'bankName',
                                            searchEnabled: true,
                                            deferRendering: false,
                                            openOnFieldClick: true,
                                        },
                                    },
                                    {
                                        dataField:"tujuanRekCabang",
                                        label:{
                                            text:"Cabang"
                                        }
                                    },
                                ]
                            },
                        ]
                    },
                    
                ]
            }
            
            
            
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
    }

    show() { 
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.rekruitmenId = 0
        this.setState({
            popupVisible: false,
            DataMaster:{
                metodePembayaran:1,
                pengajuanNominal:0,
                ppn:0,
                pph:0,
                biayaLain:0,
                potongan:0,
            },
            DataDocument:{},
            uploadedData:[],
        })
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`],state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    getRekruitmenId = (rekruitmenId) => {
        this.rekruitmenId = rekruitmenId
    }

    retrieveData = async(data) => {
        this.setState({
            DataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    getEntity = async () => {
        var paramEntity = ['P3']
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllMultipleByType/${paramEntity.join()}`, 'GET')
        this.formMasterRef.current.instance.getEditor('entitiId').option('dataSource', data)
    }

    submitData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.rekurtmenId = this.rekruitmenId
            var loadApi = 'rekurtmen-biayas'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Input Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);  
        }
    }

    render() {
        return (
        <React.Fragment>
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Detail Biaya"}
                width={"60vw"}
                height={"70vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                    <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation = 'left'
                        />
                        {/* Ijazah */}
                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="File Dokumen"
                            showFileList={false}
                            name={"dokumen"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-4">{ this.state.DataDocument.dokumenName}</label>
                        </div>
                        <div className="col-md-4 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument,'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                    </div>
                    </ScrollView>
                </Popup>
                
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormModalBiayaRekruitmen
