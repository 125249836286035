import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Form } from 'devextreme-react'
import { addURL } from 'redux/actions/url'
import FormModalRegistrasiAset from 'components/pages/modal/sdm-umum/umum/aset/registrasiAset'
import FormModalSerahTerimaAset from 'components/pages/modal/sdm-umum/umum/aset/serahTerima'

class SerahTerimaAset extends Component {
    constructor(props){
        super(props)

        this.state = {
            filter:{}
        }

        this.filterItem = [
            {
                dataField:'tahunPengadaan',
                caption:'Tahun Pengadaan'
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Serah Terima',
                        hint: 'Serah Terima',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalSerahTerima(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField: 'noRegistrasi',
                caption: 'No Registrasi',
            },
            {
                dataField: 'barCode',
                caption: 'Bar Code',
            },
            {
                dataField: 'noPengadaan',
                caption: 'No Pengadaan',
            },
            {
                dataField: 'tanggalPengadaaan',
                caption: 'Tanggal Pengadaan',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField: 'aset',
                caption: 'Aset',
                alignment:'center',
                columns:[
                    {
                        dataField:'groupAset',
                        caption:'Group Aset'
                    },
                    {
                        dataField:'jenisAset',
                        caption:'Jenis Aset'
                    },
                    {
                        dataField:'tipeAset',
                        caption:'Tipe Aset'
                    },
                    {
                        dataField:'spesifkasiAset',
                        caption:'Spesifikasi Aset'
                    },
                ]
            },
            {
                dataField: 'penempatan',
                caption: 'Penempatan',
                alignment:'center',
                columns:[
                    {
                        dataField:'unitKerja',
                        caption:'Unit Kerja'
                    },
                    {
                        dataField:'karyawan',
                        caption:'Karyawan'
                    },
                ]
            },
        ]

        this.showModalSerahTerimaRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalSerahTerima = (e) => {
        if (e.row) {
            this.showModalSerahTerimaRef.current.retrievedata(e.row.data)
        }
        this.showModalSerahTerimaRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalDaftarAset(e)
                    },
                }
            }
        )
    }

    loadDummyData = () => {
        var data = [
            {
                id:1,
                noPengadaan:"0001",
                groupAset:"Komputer"
            },
            {
                id:2,
                noPengadaan:"0002",
                groupAset:"Komputer"
            },
            {
                id:3,
                noPengadaan:"0002",
                groupAset:"Komputer"
            },
        ]

        return data
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Aset</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.filter}
                    items={this.filterItem}
                    labelLocation={'left'}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='daftar-penagjuan-pengadaan'
                        insertAPI='daftar-penagjuan-pengadaan'
                        updateAPI='daftar-penagjuan-pengadaan'
                        deleteAPI='daftar-penagjuan-pengadaan'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource={true}
                        ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Aset"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Aset'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        // onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalSerahTerimaAset 
                        ref={this.showModalSerahTerimaRef}
                        store={this.props.store}
                    />
                </div>
            </div>
        )
    }
}

export default SerahTerimaAset