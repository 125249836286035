import React, { Component } from "react";
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import { confirm,alert } from 'devextreme/ui/dialog';
import { showLoading } from 'redux/actions/loading';
import { months, participants, rekBank } from "dataSource/lookup";

class ProsesPembulatan extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            popUpVisible: false,
            dataMaster: {
                participantId: null,
                tahun: null,
                bulan: null,
                nominalIuran: null,
                receiveAmount: null,
                receiveOutstanding: null,
                tanggalProses: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.iuranId = 0
        this.dataIuran = [];

        this.formMaster = [
            {
                dataField:'participantId',
                label:{
                    text: 'Pemberi Kerja',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    placeholder: ' ',
                    dataSource: participants(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "participantName", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                }
            },
            {
                dataField:'tahun',
                label:{
                    text: 'Periode Tahun',
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField:'bulan',
                label:{
                    text: 'Periode Bulan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    placeholder: ' ',
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    readOnly: true
                }
            },
            {
                // dataField:'receiveOutstanding',
                dataField: 'nominalIuran',
                label:{
                    text: 'Total Iuran',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly : true
                },
            },
            {
                dataField:'receiveAmount',
                label:{
                    text: 'Iuran Telah diterima',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly : true
                },
            },
            {
                dataField:'receiveOutstanding',
                label:{
                    text: 'Iuran Belum diterima',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly : true
                },
            },
            {
                dataField: 'tanggalProses',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Proses'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: async() => {
                        var result = await confirm('Apakah anda ingin proses data ini?', 'Konfirmasi')
                        if(result){
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    show = () => {
        this.setState({
            popUpVisible: true,
        });
    }
    
    hide() {
        this.setState({
            popUpVisible: false,
        });
    }

    retrieveData = (data) => {
        this.setState({
            dataMaster: {
                participantId: data.participantId,
                tahun: data.tahun,
                bulan: data.bulan,
                nominalIuran: data.nominalIuran,
                receiveAmount: data.receiveAmount,
                receiveOutstanding: data.receiveOutstanding,
                tanggalProses: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        })
        this.iuranId = data.id
        this.dataIuran = data;
    }

    submitData = async () => {
        try {
            let data = this.state.dataMaster;
            let loadAPI = `iurans/arsip-piutang-iuran`;
            let updateData = this.dataIuran;

            let prosesData = {
                iuranId: this.iuranId,
                tanggal: data.tanggalProses
            }
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: prosesData
            })

            if (response) {
                updateData.statusPiutangId = 4;
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'iurans', 'PUT', {
                    key: this.iuranId,
                    values: updateData
                }, updateData);

                const message = 'Proses pembulatan berhasil!';
                const title = 'Berhasil!';
                await alert(message, title)
                this.hide();
                this.props.forceRefresh();
            }
        } catch (error) {
            await alert(error, 'Gagal')
            console.log(error);
        }
    }

    render() {
        return (
                <Popup      
                className={'popup'}
                visible={this.state.popUpVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Proses Pembulatan Iuran'} 
                width={"30vw"}
                height={"40vh"}
                ref={this.PopupRef}
                toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView height="100%" width="100%">
                        <div className="container-fluid">
                            <Form
                                colCount={1}
                                id={'formProsesPembulatan'}
                                formData={this.state.dataMaster}
                                items={this.formMaster}
                                labelLocation = {'left'}
                            >
                            </Form>
                        </div>
                    </ScrollView>
                </Popup>
        )
    }
}

export default ProsesPembulatan