import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { instrumentEtf, broker, currency, rekeningBank, managerInvestasis, instrumentsCategoryEtf } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import { alert } from 'devextreme/ui/dialog';


class FormSubInstrumentPenempatanEtf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {}
        }

        this.prevTabIndex = 0
        this.productId = 0
        this.instSubCategoryId = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                name: 'dataetf',
                items: [
                    {
                        dataField: 'requestNo',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            text: 'Instrument'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentEtf(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.instrumentCode + ' - ' + e.instrumentName
                            },
                            openOnFieldClick: true,
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                console.log(instrument)
                                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${e.value}`, 'GET')
                                this.instSubCategoryId = instrument.categorySubId
                                this.formMasterRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)

                                this.formDetailRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)
                                this.formDetailRef.current.instance.getEditor('instSubCategoryId').option('value', instrument.categorySubId)
                                this.formDetailRef.current.instance.getEditor('miId').option('value', instrument.managerInvestasiId)
                                this.formDetailRef.current.instance.updateData('nabTerakhir', instrument.price)
                                this.formDetailRef.current.instance.updateData('isinCode', instrument.isinCode)
                                this.formDetailRef.current.instance.updateData('tanggalHargaTerakhir', instrument.priceDate)
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose instrument"
                            }
                        ]
                    },
                    {
                        dataField: 'tanggalOrder',
                        label: {
                            text: 'Tanggal Order'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'amountOrder',
                        label: {
                            text: 'Nominal Penempatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Amount cannot empty!"
                            }
                        ]
                    },
                    {
                        dataField: 'priceType',
                        label: {
                            text: 'Harga'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['FIXED', 'RANGE', 'BEST PRICE'],
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose Price Type"
                            }
                        ]
                    },
                    {
                        dataField: 'minOrdPrice',
                        label: {
                            text: 'Harga Minimum'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Minimum Price cannot empty!"
                            }
                        ]
                    },
                    {
                        dataField: 'maxOrdPrice',
                        label: {
                            text: 'Harga Maximum'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Maximum Price cannot empty!"
                            }
                        ]
                    },
                    {
                        dataField: 'brokerId',
                        label: {
                            text: 'Broker'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: broker(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'brokerName',
                            openOnFieldClick: true,
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose broker"
                            }
                        ]
                    },
                    {
                        dataField: 'market',
                        label: {
                            text: 'Pasar'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['PRIMER', 'SEKUNDER'],
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose market type"
                            }
                        ]
                    },
                    {
                        dataField: 'brokerCommission',
                        label: {
                            text: 'Fee (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var fee = e.value
                                var nom = this.formMasterRef.current.instance.getEditor('amountOrder').option('value')
                                var result = nom * fee / 100
                                this.formMasterRef.current.instance.updateData('feeNom', result)
                            }
                        },
                    },
                    {
                        dataField: 'feeNom',
                        label: {
                            text: 'Nominal Fee'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            onValueChanged: async (e) => {
                                var currData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${e.value}`, 'GET')
                                this.formMasterRef.current.instance.updateData('kurs', currData.rate)
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose currency"
                            }
                        ]
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'rekbankId',
                        label: {
                            text: 'Rekening Pencairan (Pokok/Bunga)',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.rekNo + ' - ' + item.rekName
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose disbursement account"
                            }
                        ]
                    },
                ]
            }
        ]
        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI ETF',
                items: [
                    {
                        dataField: 'miId',
                        label: {
                            text: 'Penerbit ETF'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'miName'
                        }
                    },
                    {
                        dataField: 'instSubCategoryId',
                        label: {
                            text: 'Jenis ETF'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentsCategoryEtf(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName'
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName'
                        }
                    },
                    {
                        dataField: 'isinCode',
                        label: {
                            text: 'ISIN CODE'
                        }
                    },
                    {
                        dataField: 'nabTerakhir',
                        label: {
                            text: 'NAB Terakhir'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'tanggalHargaTerakhir',
                        label: {
                            text: 'Tanggal Harga Terakhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                        }
                    },
                    // {
                    //     dataField: 'jenisEtf',
                    //     label: {
                    //         text: 'jenisEtf'
                    //     },
                    // },
                    // {
                    //     dataField: 'aum',
                    //     label: {
                    //         text: 'AUM'
                    //     },
                    // },
                    // {
                    //     dataField: 'unitBeredar',
                    //     label: {
                    //         text: 'Unit Beredar'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja1Month',
                    //     label: {
                    //         text: 'Kinerja 1 Bulan'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja3Month',
                    //     label: {
                    //         text: 'Kinerja 3 Bulan'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja1Year',
                    //     label: {
                    //         text: 'Kinerja 1 Tahun'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja2Year',
                    //     label: {
                    //         text: 'Kinerja 2 Tahun'
                    //     },
                    // },
                ]
            },
            // {
            //     itemType: 'group',
            //     caption: 'INFORMASI HARGA TERAKHIR',
            //     items: [
            //         {
            //             dataField: 'nabPeluncuran',
            //             label: {
            //                 text: 'NAB Peluncuran'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'nabTertinggi',
            //             label: {
            //                 text: 'NAB Tertinggi'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'nabTerakhir',
            //             label: {
            //                 text: 'NAB Terakhir'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //     ]
            // },
            // {
            //     itemType: 'group',
            //     caption: 'INFORMASI PORTOFOLIO',
            //     items: [
            //         {
            //             dataField: 'nilaiPenempatanInstrument',
            //             label: {
            //                 text: 'Nilai Penempatan Instrument'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'komposisiInstrument',
            //             label: {
            //                 text: 'Komposisi Instrument'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'komposisiEmiten',
            //             label: {
            //                 text: 'Komposisi emiten'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //     ]
            // }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show  () {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data) => {
        if(data){
            this.productId = data.productId
            var instrument = {}
            if (data.instrumentId) {
                instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`)
            }
            this.setState({
                data: {
                    id: data.id,
                    requestNo: data.requestNo,
                    instrumentId: data.instrumentId,
                    miId: data.miId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nabTerakhir: data.nabTerakhir,
                    isinCode: data.isinCode,
                    tanggalHargaTerakhir: data.tanggalHargaTerakhir,
                    amountOrder: data.amountOrder,
                    feeNom: data.feeNom,
                    brokerId: data.brokerId,
                    market: data.market,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    rekbankId: data.rekbankId,
                    brokerCommission: data.brokerCommission,
                    priceType: data.priceType,
                    instSubCategoryId: data.instSubCategoryId,
                    tanggalOrder: data.tanggalOrder,
                },
                dataDetail: {
                    currencyId: instrument.currencyId,
                    instSubCategoryId: instrument.categorySubId,
                    miId: instrument.managerInvestasiId,
                    nabTerakhir: instrument.price,
                    isinCode: instrument.isinCode,
                    tanggalHargaTerakhir: instrument.priceDate
                },
            });

        }
    }

    hide() {
        this.productId = 0
        this.instSubCategoryId = 0
        this.setState({
            popupVisible: false,
        })
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    submitData = async () => {
        var data = this.state.data
        var detail = this.state.dataDetail
        let validateBatasan = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-validasi-pengajuan-inv/${this.productId}/${this.instSubCategoryId}/${data.amountOrder}`, 'GET')
        if (validateBatasan.length > 0) {
            if (validateBatasan[0].potensiselisih >= 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")

                this.props.submitDetailPenempatanData({
                    id: data.id,
                    requestNo: data.requestNo,
                    instrumentId: data.instrumentId,
                    miId: detail.miId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nabTerakhir: detail.nabTerakhir,
                    isinCode: detail.isinCode,
                    tanggalHargaTerakhir: detail.tanggalHargaTerakhir,
                    amountOrder: data.amountOrder,
                    feeNom: data.feeNom,
                    brokerId: data.brokerId,
                    market: data.market,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    rekbankId: data.rekbankId,
                    brokerCommission: data.brokerCommission,
                    priceType: data.priceType.replace(" ", "_"),
                    instSubCategoryId: detail.instSubCategoryId,
                    tanggalOrder: data.tanggalOrder,
                    kpdId: 1
                })

                this.hide()
            } else if (validateBatasan[0].potensiselisih < 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")
            } else {
                await alert("Terdapat Kesalahan Data", "Informasi")
            }
        } else {
            await alert("Batasan Resiko Belum di Setting", "Informasi")
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Penempatan Investasi ETF'}
                width={'90vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form
                                            colCount={1}
                                            id={'formMaster'}
                                            ref={this.formMasterRef}
                                            formData={this.state.data}
                                            items={this.formItem}
                                            scrollingEnabled={false}
                                            labelLocation={"left"}
                                            readOnly={this.props.type == 'view' ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Form
                                            colCount={1}
                                            id={'formMaster1'}
                                            ref={this.formDetailRef}
                                            formData={this.state.dataDetail}
                                            items={this.formDetailItem}
                                            scrollingEnabled={false}
                                            labelLocation={"left"}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormSubInstrumentPenempatanEtf