import React, { Component } from 'react'
import { Form, ProgressBar } from "devextreme-react";
import httpRequest from 'plugin/httprequest';
import { showLoading } from "redux/actions/loading";
import { getSystemDate, formatDate } from 'plugin/helper';

class GenerateCoaPositionDaily extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formFilter: {
                startDate: getSystemDate(props.store),
                endDate: getSystemDate(props.store),
            },
            currentCount: 0,
            recordCount: 0,
        }

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Mulai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Proses",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.process()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
    }

    process = async () => {
        this.props.store.dispatch(showLoading(true))
        try {
            const { startDate, endDate } = this.state.formFilter
            const diffTime = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24)
            this.setState({
                recordCount: diffTime
            })
            var currentDate = new Date(startDate)

            for (let i = 0; i <= diffTime; i++) {
                await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-positions/dailyBalanceProcess/${formatDate(currentDate)}`)
                this.setState({
                    currentCount: i
                })
                currentDate.setDate(currentDate.getDate() + 1)
            }
        } catch (e) {
            console.log(e);
            this.props.store.dispatch(showLoading(false))
        }
        this.props.store.dispatch(showLoading(false))
    }

    statusFormat = (value) => {
        return `Memproses Data : ${this.state.currentCount} / ${this.state.recordCount} (${Math.round(value * 10000) / 100}%)`
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Generate Coa Position Daily</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />
                <ProgressBar
                    min={0}
                    max={this.state.recordCount}
                    statusFormat={this.statusFormat}
                    value={this.state.currentCount}
                />
            </div>
        )
    }
}

export default GenerateCoaPositionDaily 