import React, { Component } from 'react'
import {Popup, Form} from 'devextreme-react'
import { dasarNilai, tipeTransaksiSdmUmum } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormModalGrade extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{
                faktorPPH21:true
            }
        }

        this.form = [
            {
                dataField:"gradeCode",
                label:{
                    text:"Grade Kode"
                }
            },
            {
                dataField:"gradeName",
                label:{
                    text:"Grade Name"
                }
            },
            {
                dataField:"range",
                label:{
                    text:"Range (%)"
                },
                editorType:'dxNumberBox',
                editorOptions: {
                    format: "#0.##'%'",
                    placeholder: '0.00 %',
                }
            },
            {
                dataField:"gajiMid",
                label:{
                    text:"Mid Point"
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                    onValueChanged: (e) => {
                        var DataMaster = {...this.state.DataMaster}
                        
                        DataMaster.gajiMin = e.value - (e.value*DataMaster.range/100)
                        DataMaster.gajiMax = e.value + (e.value*DataMaster.range/100)
                        
                        this.formRef.current.instance.updateData(DataMaster)
                    }
                }
            },
            {
                dataField:"gajiMin",
                label:{
                    text:"Min Point"
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    readOnly:true,
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:"gajiMax",
                label:{
                    text:"Max Point"
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    readOnly:true,
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:"desc",
                label:{
                    text:"Keterangan"
                },
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster:data
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    } 
    
    hide = () => { 
        this.newData = true
        this.setState({
            DataMaster:{
                faktorPPH21:true
            },
            popupVisible: false
        })

        this.props.forceRefresh()
    }

    submitData = async () => {
        try {
            var loadAPI = 'grades'
            var text = 'Sukses Input Grade'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"POST",{
                values: this.state.DataMaster
            });

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    updateData = async () => {
        try {
            var loadAPI = 'grades'
            var text = 'Sukses Ubah Grade'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"PUT",{
                values: this.state.DataMaster,
                key:this.state.DataMaster
            },this.state.DataMaster);

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Grade'} 
                width={700}
                height={500}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <Form
                ref={this.formRef}
                colCount={1}
                id={'formPotongan'}
                formData={this.state.DataMaster}
                items={this.form}
                labelLocation = 'left'
            />
            </Popup>
        )
    }
}

export default FormModalGrade