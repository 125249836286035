import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, currency, kpds, managerInvestasis, productDefault, rekeningBank } from 'dataSource/lookup';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import ScrollView from 'devextreme-react/scroll-view';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class ModalRolloverMaturity extends Component {
    constructor(props) {
        super(props)
        this.taxDep = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-DEP') : null

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: [],
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'KONFIRMASI',
                        items: [
                            {
                                dataField: 'efekBalanceId',
                                label: {
                                    text: 'ID Balance',
                                    alignment: 'left'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'noKonfirmasi',
                                label: {
                                    text: 'No Konfirmasi Bank',
                                    alignment: 'left'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'bankId',
                                label: {
                                    text: 'Bank',
                                    alignment: 'left'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'productId',
                                label: {
                                    text: 'Produk',
                                    alignment: 'left'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'productCode',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'kpdId',
                                label: {
                                    text: 'No KPD',
                                    alignment: 'left'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kpds(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'noKontrak',
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BANK',
                        items: [
                            {
                                dataField: 'rekBankId',
                                label: {
                                    text: 'Rekening Pencairan/Bunga',
                                    alignment: 'left',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    },
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    text: 'Biaya Bank/RTGS',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var rollType = this.formRef.current.instance.getEditor('typeRollover').option('value')
                                        var nominalDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                        var nominalBungaBersih = this.formRef.current.instance.getEditor('nominalBungaBersih').option('value')
                                        var nominalPerpanjangan = this.formRef.current.instance.getEditor('nominalPerpanjangan').option('value')
                                        var biayaBank = e.value
                                        if (rollType == 'Sebagian') {
                                            this.formRef.current.instance.updateData('nominalTransfer', nominalDeposito + nominalBungaBersih - nominalPerpanjangan - biayaBank)
                                        }
                                    }
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'DOKUMEN',
                        items: [
                            {
                                dataField: 'file',
                                label: {
                                    text: 'File Konfirmasi',
                                    alignment: 'left',
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'CATATAN',
                        items: [
                            {
                                dataField: 'catatan',
                                label: {
                                    text: 'Catatan',
                                    alignment: 'left',
                                },
                                editorType: 'dxTextArea',
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'BILYET LAMA',
                        items: [
                            {
                                dataField: 'noBilyetLama',
                                label: {
                                    text: 'No Bilyet',
                                    alignment: 'left',
                                },
                                editorOptions: {
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'tglPerpanjangan',
                                label: {
                                    text: 'Tanggal Perpanjangan',
                                    alignment: 'left',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                },
                            },
                            {
                                dataField: 'bungaPercent',
                                label: {
                                    text: 'Bunga (%)',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'nominalAccruedBunga',
                                label: {
                                    text: 'Nominal Accrued Bunga',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'nominalDeposito',
                                label: {
                                    text: 'Nominal Deposito',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var rollType = this.formRef.current.instance.getEditor('typeRollover').option('value')
                                        var nominalDeposito = e.value
                                        var nominalBungaBersih = this.formRef.current.instance.getEditor('nominalBungaBersih').option('value')
                                        var nominalPerpanjangan = this.formRef.current.instance.getEditor('nominalPerpanjangan').option('value')
                                        var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        if (rollType == 'Sebagian') {
                                            this.formRef.current.instance.updateData('nominalTransfer', nominalDeposito + nominalBungaBersih - nominalPerpanjangan - biayaBank)
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'nominalBunga',
                                label: {
                                    text: 'Nominal Bunga',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        // var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                        this.formRef.current.instance.updateData('pph', (this.taxDep.intValue / 100) * e.value)
                                        this.formRef.current.instance.updateData('nominalBungaBersih', e.value - ((this.taxDep.intValue / 100) * e.value))
                                    }
                                },
                            },
                            {
                                dataField: 'pph',
                                label: {
                                    text: 'PPH',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                },
                            },
                            {
                                dataField: 'nominalBungaBersih',
                                label: {
                                    text: 'Nominal Bunga Bersih',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var rollType = this.formRef.current.instance.getEditor('typeRollover').option('value')
                                        var nominalDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                        var nominalBungaBersih = e.value
                                        var nominalPerpanjangan = this.formRef.current.instance.getEditor('nominalPerpanjangan').option('value')
                                        var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        if (rollType == 'Sebagian') {
                                            this.formRef.current.instance.updateData('nominalTransfer', nominalDeposito + nominalBungaBersih - nominalPerpanjangan - biayaBank)
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    text: 'Mata Uang',
                                    alignment: 'left'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyName',
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'currencyRate',
                                label: {
                                    text: 'Kurs',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'PERPANJANGAN',
                        items: [
                            {
                                dataField: 'typeRollover',
                                label: {
                                    text: 'Tipe Perpanjangan',
                                    alignment: 'left',
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: "horizontal",
                                    items: [
                                        'Pokok Saja',
                                        'Pokok + Bunga',
                                        'Sebagian',
                                    ],
                                    value: 'Sebagian',
                                    onValueChanged: (e) => {
                                        var rollType = e.value
                                        var nominalDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                        var nominalBungaBersih = this.formRef.current.instance.getEditor('nominalBungaBersih').option('value')
                                        var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        if (rollType == 'Pokok Saja') {
                                            this.formRef.current.instance.getEditor('nominalPerpanjangan').option('readOnly', true)
                                            this.formRef.current.instance.updateData({ nominalPerpanjangan: nominalDeposito, nominalTransfer: nominalBungaBersih })
                                        } else if (rollType == 'Pokok + Bunga') {
                                            this.formRef.current.instance.getEditor('nominalPerpanjangan').option('readOnly', true)
                                            this.formRef.current.instance.updateData({ nominalPerpanjangan: nominalDeposito + nominalBungaBersih, nominalTransfer: 0 })
                                        } else if (rollType == 'Sebagian') {
                                            this.formRef.current.instance.getEditor('nominalPerpanjangan').option('readOnly', false)
                                            this.formRef.current.instance.updateData({ nominalPerpanjangan: 0, nominalTransfer: nominalDeposito + nominalBungaBersih - biayaBank })
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'nominalPerpanjangan',
                                label: {
                                    text: 'Nominal Perpanjangan',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var rollType = this.formRef.current.instance.getEditor('typeRollover').option('value')
                                        var nominalDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                        var nominalBungaBersih = this.formRef.current.instance.getEditor('nominalBungaBersih').option('value')
                                        var nominalPerpanjangan = e.value
                                        var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        if (rollType == 'Sebagian') {
                                            this.formRef.current.instance.updateData('nominalTransfer', nominalDeposito + nominalBungaBersih - nominalPerpanjangan - biayaBank)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'nominalTransfer',
                                label: {
                                    text: 'Nominal Ditransfer',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BILYET BARU',
                        items: [
                            {
                                dataField: 'bilyetBaru',
                                label: {
                                    text: 'Bilyet',
                                    alignment: 'left',
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: "horizontal",
                                    items: [
                                        'Bilyet Lama',
                                        'Baru',
                                    ],
                                    onValueChanged: (e) => {
                                        if (e.value == 'Baru') {
                                            this.formRef.current.instance.getEditor('noBilyetBaru').option('readOnly', false)
                                            this.formRef.current.instance.getEditor('noBilyetBaru').option('value', null)
                                        } else if (e.value == 'Bilyet Lama') {
                                            this.formRef.current.instance.getEditor('noBilyetBaru').option('readOnly', true)
                                            this.formRef.current.instance.getEditor('noBilyetBaru').option('value', this.state.dataMaster.bilyetNo)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'noBilyetBaru',
                                label: {
                                    text: 'Bilyet Baru',
                                    alignment: 'left',
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'ratePerpanjangan',
                                label: {
                                    text: 'Rate Perpanjangan (%)',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                dataField: 'tenorPerpanjangan',
                                label: {
                                    text: 'Tenor Perpanjangan',
                                    alignment: 'left',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var tglPerpanjangan = this.formRef.current.instance.getEditor('tglPerpanjangan').option('value')
                                        var tenorPerpanjangan = e.value
                                        var tenorType = this.formRef.current.instance.getEditor('tenorType').option('value')
                                        var result = []
                                        var date = []
                                        var jatuhTempo
                                        if (tenorType == 'BULAN') {
                                            date = new Date(tglPerpanjangan).getMonth();
                                            result = new Date(tglPerpanjangan).setMonth(date + tenorPerpanjangan)
                                            jatuhTempo = new Date(result)
                                        } else if (tenorType == 'HARI') {
                                            date = new Date(tglPerpanjangan).getTime();
                                            result = date + (tenorPerpanjangan * 24 * 3600 * 1000)
                                            jatuhTempo = new Date(result)
                                        }
                                        this.formRef.current.instance.updateData('tglJatuhTempoBaru', jatuhTempo)
                                    }
                                }
                            },
                            {
                                dataField: 'tenorType',
                                label: {
                                    text: 'Tipe Tenor',
                                    alignment: 'left',
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: "horizontal",
                                    items: [
                                        'HARI',
                                        'BULAN',
                                    ],
                                    onValueChanged: (e) => {
                                        var tglPerpanjangan = this.formRef.current.instance.getEditor('tglPerpanjangan').option('value')
                                        var tenorPerpanjangan = this.formRef.current.instance.getEditor('tenorPerpanjangan').option('value')
                                        var tenorType = e.value
                                        var result = []
                                        var date = []
                                        var jatuhTempo
                                        if (tenorType == 'BULAN') {
                                            date = new Date(tglPerpanjangan).getMonth();
                                            result = new Date(tglPerpanjangan).setMonth(date + tenorPerpanjangan)
                                            jatuhTempo = new Date(result)
                                        } else if (tenorType == 'HARI') {
                                            date = new Date(tglPerpanjangan).getTime();
                                            result = date + (tenorPerpanjangan * 24 * 3600 * 1000)
                                            jatuhTempo = new Date(result)
                                        }
                                        this.formRef.current.instance.updateData('tglJatuhTempoBaru', jatuhTempo)
                                    }
                                }
                            },
                            {
                                dataField: 'tglJatuhTempoBaru',
                                label: {
                                    text: 'Tanggal Jatuh Tempo Baru',
                                    alignment: 'left',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                        ]
                    }
                ]
            }
        ]
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Base64`]: formattedFileData.base64data,
                    [`${fieldName}Base64ContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    countNetAmount = (deposito, bunga, bank, pph) => {
        var result = deposito + bunga - bank - pph
        this.formRef.current.instance.updateData('nominalPencairanBersih', result)
    }
    submitData = async () => {
        var uploadFile = {
            fileName: this.state.fileName,
            fileBase64: this.state.fileBase64,
            fileBase64ContentType: this.state.fileBase64ContentType,
        }
        try {
            var responseFile = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "POST", {
                values: uploadFile
            }
            )
            if (responseFile) {
                await this.submitDataBreak(responseFile.id)
            } else {
                notify({ message: 'Terjadi Kesalahan System , Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    submitDataBreak = async (fileKonfirmasiId) => {
        var data = this.state.dataMaster
        var dataMaster = {
            bankId: data.bankId,
            biayaBank: data.biayaBank == null ? 0 : data.biayaBank,
            bilyetBaru: data.bilyetBaru == 'Baru' ? true : false,
            bungaPercent: data.bungaPercent,
            catatan: data.catatan,
            currencyId: data.currencyId,
            currencyRate: data.currencyRate,
            efekBalanceId: data.efekBalanceId,
            fileKonfirmasiId: fileKonfirmasiId,
            kpdId: data.kpdId,
            noBilyetBaru: data.noBilyetBaru,
            noBilyetLama: data.noBilyetLama,
            noKonfirmasi: data.noKkonfirmasi,
            nominalAccruedBunga: data.nominalAccruedBunga,
            nominalBunga: data.nominalBunga,
            nominalBungaBersih: data.nominalBungaBersih,
            nominalDeposito: data.nominalDeposito,
            nominalPerpanjangan: data.nominalPerpanjangan,
            nominalTransfer: data.nominalTransfer == null ? 0 : data.nominalTransfer,
            pph: data.pph,
            productId: data.productId,
            ratePerpanjangan: data.ratePerpanjangan,
            rekBankId: data.rekBankId,
            tenorPerpanjangan: data.tenorPerpanjangan,
            tenorType: data.tenorType,
            tglJatuhTempoBaru: formatDate(data.tglJatuhTempoBaru),
            tglPerpanjangan: formatDate(data.tglPerpanjangan),
            typeRollover: data.typeRollover == 'Pokok + Bunga' ? 2 : data.typeRollover == 'Pokok Saja' ? 1 : 3
        }
        if (dataMaster) {
            try {
                var loadAPI = 'efek-balance-avgs/deposito-rollover'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, "PUT", {
                    values: dataMaster,
                    key: dataMaster.efekBalanceId
                }, dataMaster
                )
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                console.log(e)
                notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    show = async (data) => {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: data
        });
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: [],
        })
    }
    previewFile = async() => {
        var src = imageSource(this.state.fileBase64ContentType, this.state.fileBase64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Break Deposito'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={' mt-2 col-md-12'}>
                                <Form
                                    colCount={2}
                                    id={"formMaster"}
                                    formData={this.state.dataMaster}
                                    items={this.formItem}
                                    ref={this.formRef}
                                    labelLocation="left"
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileBase64 ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                            </div>
                        </div>
                    </div>
                    <ModalPreviewPdf 
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalRolloverMaturity