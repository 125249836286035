import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, departments, fixedAssetGroups, coa, depreciationMethod } from "dataSource/lookup";
import uuidv4  from 'uuid/v4'
import DevExtremeTreeList from "components/inheritComponent/devextremetreelist";
import { showLoading } from "redux/actions/loading";

class ModalUserAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {}
        };

        this.formItem = [
            {
                dataField: 'groupCode',
                label: {
                    alignment: 'left',
                    text: 'Kode Grup'
                }
            },
            {
                dataField: 'groupName',
                label: {
                    alignment: 'left',
                    text: 'Nama Grup'
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                  'edit',
                  {
                    text: 'Aktifkan Menu Anak Dibawahnya',
                    hint: 'Aktifkan Menu Anak Dibawahnya',
                    cssClass: 'text-success',
                    onClick: (e) => {
                        this.enableChild(e.row.key)
                    }
                  },
                  {
                    text: 'Non Aktifkan Menu Anak Dibawahnya',
                    hint: 'Non Aktifkan Menu Anak Dibawahnya',
                    cssClass: 'text-success',
                    onClick: (e) => {
                        this.disableChild(e.row.key)
                    }
                  },
                ],
              },
            {
                dataField: 'menuCode',
                caption: 'Kode Menu',
                allowEditing: false,
            },
            {
                dataField: 'menuName',
                caption: 'Nama Menu',
                allowEditing: false,
            },
            {
                dataField: 'enabled',
                caption: 'Aktif',
                dataType : 'boolean'
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.groupId = 0

        this.formRef = React.createRef()
        this.treelistRef = React.createRef()

        this.dataTreelist = []
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'todo',
                    text: 'Aktifkan Semua Menu',
                    onClick: (e) => {
                        this.enableAll()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'remove',
                    text: 'Non Aktifkan Semua Menu',
                    onClick: (e) => {
                        this.disableAll()
                    },
                }
            }
        )
    }


    retrieveData = async (data) => {
        this.setState({
            formData: data
        })

        this.groupId = data.id

        var menus = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'sys-menus')
    
        var groupMenus = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `sys-group-menus`)

        groupMenus = groupMenus.filter(value => value.sysGroupId === this.groupId)

        this.dataTreelist  = menus.map(value => {
            var groupMenu = groupMenus.find(groupValue => groupValue.sysMenuId === value.id)

            return {
                id: groupMenu ? groupMenu.id : uuidv4(),
                sysGroupId : this.groupId,
                sysMenuId : value.id,
                enabled :  groupMenu ? groupMenu.enabled : false,
                menuCode : value.menuCode,
                menuName : value.menuName,
                parentCode : value.parentCode,
                level : value.level
            }
        })

        this.treelistRef.current.forceRefresh(true)
    }

    show = () => {
        this.setState({
            popupVisible: true
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    loadData = () => {
        return this.dataTreelist
    }

    enableChild = (parentMenuCode) => {
        var data = this.treelistRef.current.getDataSource()

        var parentChanges = {
            type: "update", 
            data: {
                enabled: true
            },
            key: parentMenuCode
        }

        var child = data.filter(data => data.menuCode.includes(`${parentMenuCode}-`)).map(value => {
            return {
                type: "update", 
                data: {
                    enabled: true
                },
                key: value.menuCode
            }
        })

        var changes = [parentChanges,...child]
        
        this.treelistRef.current.push(changes)
    }

    disableChild = (parentMenuCode) => {
        var data = this.treelistRef.current.getDataSource()

        var parentChanges = {
            type: "update", 
            data: {
                enabled: false
            },
            key: parentMenuCode
        }

        var child = data.filter(data => data.menuCode.includes(`${parentMenuCode}-`)).map(value => {
            return {
                type: "update", 
                data: {
                    enabled: true
                },
                key: value.menuCode
            }
        })

        var changes = [parentChanges,...child]
        
        this.treelistRef.current.push(changes)
    }

    enableAll = () => {
        var data = this.treelistRef.current.getDataSource()

        var changes = data.map(value => {
            return {
                type: "update", 
                data: {
                    enabled: true
                },
                key: value.menuCode
            }
        })

        this.treelistRef.current.push(changes)
    }

    disableAll = () => {
        var data = this.treelistRef.current.getDataSource()

        var changes = data.map(value => {
            return {
                type: "update", 
                data: {
                    enabled: false
                },
                key: value.menuCode
            }
        })

        this.treelistRef.current.push(changes)
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        var datas = this.treelistRef.current.getDataSource()

        for(var data of datas){
            delete data.parentCode
            delete data.level
            delete data.menuCode
            delete data.menuName

            if(typeof data.id === 'string'){
                delete data.id
                await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'sys-group-menus','POST',{
                    values: data
                })
            }else{
                await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, 'sys-group-menus', 'PUT', {
                    values: {
                        enabled: data.enabled
                    },
                    key: data.id
                }, data)
            }
        }

        this.props.store.dispatch(showLoading(false))

        this.hide()
    }

    onRowUpdating = (e) => {
        var data = this.treelistRef.current.getDataSource()

        //update parent
        var currentRowData = e.oldData

        var currentMenuCodeSegments = currentRowData.parentCode ? currentRowData.parentCode.split('-') : []
        // currentMenuCodeSegments.pop()

        var menuSameLevelEnabled = data.filter(value => value.parentCode === currentRowData.parentCode && value.menuCode !== currentRowData.menuCode && value.enabled)

        var parentUpdate = e.newData.enabled

        if(menuSameLevelEnabled.length > 0 && !parentUpdate){
            parentUpdate = true
        }

        var parentMenuCode = ''
        var parent = currentMenuCodeSegments.map((value,index) => {
            parentMenuCode = parentMenuCode + (index === 0 ? '' : '-') +value

            return {
                type: "update", 
                data: {
                    enabled : parentUpdate
                },
                key: parentMenuCode
            }
        })

        //update child
        var child = data.filter(data => data.menuCode.includes(`${e.key}-`)).map(value => {
            return {
                type: "update", 
                data: e.newData,
                key: value.menuCode
            }
        })

        var changes = [...parent,...child]
        
        this.treelistRef.current.push(changes)
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"User Access"}
                    width={"70vw"}
                    height={"70vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form 
                        colCount={2}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.formItem}
                        ref={this.formRef}
                        labelLocation="left"
                        readOnly = {true}
                    />
                    
                    <DevExtremeTreeList
                        ref = {this.treelistRef}

                        keyField= {'menuCode'}
                        parentIdExpr = {'parentCode'}

                        loadAPI='sys-menus'
                        insertAPI='sys-menus'
                        updateAPI='sys-menus'
                        deleteAPI='sys-menus'

                        backendserver={process.env.REACT_APP_BACKEND}

                        useNotify = {false}

                        useArraySource = {true}
                        ArraySourceData = {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}

                        editingMode = 'cell'

                        onRowUpdating = {this.onRowUpdating}

                        exportExcel={false}
                        exportFileName={"User Group"}
                        allowExportSelectedData={false}
                        selection={'none'}
                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Menu'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        height = {'calc(70vh - 150px)'}

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </Popup>
            </React.Fragment>

        );
    }
}

export default ModalUserAccess;