import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { confirm } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'
import httpRequest from 'plugin/httprequest'

class TipeDokumen extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Hapus',
                        onClick : async (e) => {
                            let data = e.row.data
                            let result = await confirm("Apakah anda yakin ingin menghapus data ini?", "Konfirmasi");

                            if (result) {
                                this.delete(data)
                            }
                        }
                    },
                ],
            },
            {
                dataField:"dokumenCode",
                caption:"Kode"
            },
            {
                dataField:"dokumenName",
                caption:"Tipe Dokumen" 
            },
        ]

        this.dataGridRef = React.createRef()
    }

    delete = async (data) => {
        let id = data.id
        if(id) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'dokumen-types', 'DELETE', id)
                this.forceRefresh()
                notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success', 1000);
            } catch (e) {
                notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error', 1000);
            }
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true);
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Tipe Dokumen</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='dokumen-types'
                    insertAPI='dokumen-types'
                    updateAPI='dokumen-types'
                    deleteAPI='dokumen-types' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"Tipe Dokumen"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Dokumen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}


export default TipeDokumen