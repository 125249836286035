import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalProsesDaftarUlang from './actionProsesDaftarUlang';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';

class FormDaftarUlangKonvensional extends Component {
    constructor(props) {
        super(props)

        this.state = {
            getUrl: 'daftar-ulang-detils/getByDaftarUlangId/0',
            daftarUlangId : 0
        }

        this.columnConfiguration = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        onClick: (e) => {
                            this.showModalProsesKonvensional(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'tanggalDiterima',
                caption: 'Tanggal Diterima',
                dataType: 'date',  
                format: 'dd MMM yyyy',
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'tanggalProses',
                caption: 'Tanggal Proses',
                dataType: 'date',  
                format: 'dd MMM yyyy',
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
        ]

        this.modalProsesKonvensionalDaftarUlangRef = React.createRef()
        this.dataGridKonvensionalRef = React.createRef()

        this.currentData = []
    }

    passDataToParent = () => {
        var data = {
            pesertaId : this.modalProsesKonvensionalDaftarUlangRef.current.pesertaId,
            dataKonvensional: this.dataGridKonvensionalRef.current.getDataSource(),
            currentDataKonvensional: this.currentData,
            deletedDataKonvensional: this.dataGridKonvensionalRef.current.getDeletedIndex(),
        }
        return data
    }

    removeState = () => {
        this.setState({
            getUrl: 'daftar-ulang-detils/getByDaftarUlangId/0'
        })
    }

    reloadData = (daftarUlangId) => {
        this.setState({
            getUrl : `daftar-ulang-detils/getByDaftarUlangId/${daftarUlangId}`,
            daftarUlangId : daftarUlangId
        })

    }

    showModalProsesKonvensional = (data = {}) => {
        this.modalProsesKonvensionalDaftarUlangRef.current.retrieveData(data, 'konvensional')
        this.modalProsesKonvensionalDaftarUlangRef.current.show()
    }

    submitData = async(data) => {
        try {
            var dataPost = {
                pesertaId : this.modalProsesKonvensionalDaftarUlangRef.current.pesertaId,
                daftarUlangId: this.state.daftarUlangId,
                ...data}
            var insertAPI = 'daftar-ulang-detils/konvensional'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                values: dataPost
            })

            if (response) {
                var loadPesertaId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/${data.pesertaId}`, 'GET');

                if (loadPesertaId) {
                    var loadPesertaNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/getByNik/${loadPesertaId.nik}`, 'GET');
                    var updateDataPeserta = loadPesertaNik;

                    updateDataPeserta.statusPeserta = "4";

                    var updateAPI = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'pesertas', 'PUT', {
                        key: loadPesertaNik.id,
                        values: updateDataPeserta
                    }, updateDataPeserta)

                    console.log(updateAPI);
                }
            }

            var message = 'Data berhasil di proses!'
            var resalert = await alert(message, 'Berhasil!')
            if(resalert){
                this.forceRefresh()
            }
        } catch (e) {
            console.log(e)
            await alert(e, 'Gagal!')
        }
    }
    editData = async (data) => {
        try {
            var key = data.id

            if (key) {
                this.dataGridKonvensionalRef.current.push([
                    {
                        type: 'update',
                        data: data,
                        key: key
                    }
                ])
            } else {
                this.dataGridKonvensionalRef.current.push([
                    {
                        type: 'insert',
                        data: data
                    }
                ])
            }
            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'

            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        } catch (e) {
            console.log(e)
        }
    }

    deleteData = (data) => {
        this.dataGridKonvensionalRef.current.push([
            {
                type: 'remove',
                key: data.id
            }
        ])
    }

    forceRefresh = () => {
        this.dataGridKonvensionalRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Data',
                    onClick: (e) => {
                        this.showModalProsesKonvensional()
                    }
                }
            }
        )
    }

    retrieveDataGrid = async() => {
        try {
            var loadAPI = `daftar-ulang-detils/getByDaftarUlangId/${this.state.daftarUlangId}`
            var getData  = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            
            return getData
        } catch (error) {
            return []
        }
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridKonvensionalRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='daftar-ulang-detils'
                    updateAPI='daftar-ulang-detils'
                    deleteAPI='daftar-ulang-detils'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {true}
                    // useArraySource = {true}
                    // ArraySourceData = {this.retrieveDataGrid}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle = {`Data Konvensional`}
                    popupWidth = {500} //masukan dalam ukuran pixel
                    popupHeight = {300} //masukkan dalam ukuran pixel
                    

                    popupFormLabelLocation = 'left' //accepted value = top, left, right
                    popupFormMinColWidth = {300} // minimum lebar kolom
                    popupFormColCount = {1} //jumlah kolom pada form
                    
                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columnConfiguration} // taruh konfigurasi kolom disini
                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalProsesDaftarUlang
                    store={this.props.store}
                    ref={this.modalProsesKonvensionalDaftarUlangRef}
                    editData = {this.editData}
                    submitData = {(nikPeserta) => this.submitData(nikPeserta)}
                />
            </React.Fragment>
        )
    }
}

export default FormDaftarUlangKonvensional
