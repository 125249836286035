import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import httpRequest from "plugin/httprequest";
import { coa } from "dataSource/lookup";
import DevExpressDataGrid from "components/inheritComponent/devexpressdatagrid";
import ViewJournalVoucher from "../journalVoucher/view";

class ModalGeneralLedger extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      formData: {}
    };

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Detail',
            hint: 'Detail',
            cssClass: 'text-success',
            onClick: (e) => {
              this.showModalView(e.row.data.journalId)
            }
          },
        ],
      },
      {
        dataField: 'coaId',
        caption: 'Chart of Account',
        groupIndex: 0,
        lookup: {
          dataSource: coa(this.props.store),
          valueExpr: 'id',
          displayExpr: function (item) {
            return item && item.coaCode + " - " + item.coaName;
          },
        }
      },
      {
        dataField: 'valueDate',
        caption: 'Tanggal',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right',
      },
      {
        dataField: 'journalCode',
        caption: 'Journal Code',
      },
      {
        dataField: 'reffCode',
        caption: 'Reff',
      },
      {
        dataField: 'journalDesc',
        caption: 'Description',
      },
      {
        dataField: 'debet',
        caption: 'Debet',
        format: "#,##0.00",
        alignment: 'right'
      },
      {
        dataField: 'credit',
        caption: 'Credit',
        format: "#,##0.00",
        alignment: 'right'
      },
      {
        dataField: 'balance',
        caption: 'Balance',
        format: "#,##0.00",
        alignment: 'right',
      },

    ]

    this.sumDebit = [
      {
        column: 'valueDate',
        summaryType: 'count',
        name: 'valueDate',
        displayFormat: 'Total',
        showInGroupFooter: true,
        alignByColumn: true
      },
      {
        column: 'debet',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'debet',
        displayFormat: '{0}',
        showInGroupFooter: true,
        alignByColumn: true
      },
      {
        column: 'credit',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'credit',
        displayFormat: '{0}',
        showInGroupFooter: true,
        alignByColumn: true
      },
    ]


    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Tutup",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.coaId = null

    this.dataGridRef = React.createRef()
    this.popupModalJournalVoucherRef = React.createRef()
  }

  show = (coaId, period) => {
    this.setState({
      popupVisible: true,
    });
    if (Array.isArray(coaId)) {
      this.coaIds = coaId
    } else {
      this.coaIds = [coaId]
    }
    this.period = period

    this.dataGridRef.current.forceRefresh(true)
  };

  hide = () => {
    this.setState({
      popupVisible: false,
      formData: {}
    });
  };

  loadData = async () => {
    var data = []
    if (this.coaIds.length > 0 && this.period) {
      var selectedDate = new Date(this.period);
      const selectedMonth = selectedDate.getMonth()
      const selectedYear = selectedDate.getFullYear();
      selectedDate = new Date(selectedYear, selectedMonth + 1, 0);
      var lastMonthDate = new Date(selectedYear, selectedMonth, 0)

      data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'accounting-report/general-ledger-by-function', 'POST', {
        values: {
          coaIds: this.coaIds,
          endDate: selectedDate,
          startDate: lastMonthDate
        }
      })

      var beginningBalance = data.filter(value => !value.valueDate)
      for (var coa of this.coaIds) {
        if (!beginningBalance.find(value => value.coaId === coa.id)) {
          data.push({
            amount: null,
            coaId: coa,
            credit: 0,
            currencyId: null,
            dealExrate: null,
            debet: 0,
            departmentId: null,
            id: 0,
            journalTypeId: null,
            journalCode: null,
            journalDesc: "Beginning Balance",
            journalId: null,
            reffCode: null,
            reffId: null,
            valueDate: null,
          })
        }
      }

      data = data.sort((a, b) => {
        if (a.coaId < b.coaId) {
          return -1
        }

        if (a.coaId > b.coaId) {
          return 1
        }

        if (a.valueDate < b.valueDate) {
          return -1
        }

        if (a.valueDate > b.valueDate) {
          return 1
        }

        if (a.journalId < b.journalId) {
          return -1
        }

        if (a.journalId > b.journalId) {
          return 1
        }

        return 0
      })

      var coaBalance = []

      data = data.map((value, index) => {
        value.id = index
        var currentCoaBalanceIndex = coaBalance.findIndex(valueBalance => valueBalance.coaId === value.coaId)
        if (currentCoaBalanceIndex === -1) {
          coaBalance.push({
            coaId: value.coaId,
            currentBalance: value.debet - value.credit
          })
          value.balance = value.debet - value.credit
        } else {
          var newCurrentBalance = coaBalance[currentCoaBalanceIndex].currentBalance + (value.debet - value.credit)
          coaBalance[currentCoaBalanceIndex].currentBalance = newCurrentBalance
          value.balance = newCurrentBalance
        }
        return value
      })
    }

    return data
  }

  showModalView = async (journalId) => {
    var dataJournalHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/${journalId}`)
    this.popupModalJournalVoucherRef.current.retrieveData(dataJournalHeader)
    await this.popupModalJournalVoucherRef.current.loadDataDetailAndExrate(journalId)
    this.popupModalJournalVoucherRef.current.show()
  }


  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={true}
          resizeEnabled={true}
          showTitle={true}
          title={"General Ledger"}
          width={"70vw"}
          height={"70vh"}
          toolbarItems={this.PopupToolbarItem}
        >
          <DevExpressDataGrid
            ref={this.dataGridRef}
            loadAPI='ledger'
            insertAPI='ledger'
            updateAPI='ledger'
            deleteAPI='ledger'

            backendserver={process.env.REACT_APP_BACKEND_CORE}

            useArraySource={true}
            ArraySourceData={this.loadData}

            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}

            exportExcel={true}
            exportFileName={"General Ledger"}
            allowExportSelectedData={false}
            selection={"none"}

            showBorders={true}

            paging={false}
            defaultPageSize={10}
            grouping={true}

            height={'calc(70vh - 120px)'}

            //bagian konfigurasi popup saat insert dan edit record
            popupTitle={'General Ledger'}
            popupWidth={700} //masukan dalam ukuran pixel
            popupHeight={500} //masukkan dalam ukuran pixel

            popupFormLabelLocation='left' //accepted value = top, left, right
            popupFormMinColWidth={300} // minimum lebar kolom
            popupFormColCount={3} //jumlah kolom pada form

            //akhir bagian konfigurasi popup

            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

            FilterRow={true} // set false untuk mematikan fitur filter

            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
            SummaryConfiguration={this.summary}

            groupSummary={this.sumDebit}

            // onToolbarPreparing={this.onToolbarPreparing}

            //contoh konfigurasi kolom
            //this.columns = [{
            //    dataField: 'kolom1',
            //    caption: 'Ini Kolom 1'
            //}, {
            //    dataField: 'kolom2',
            //    caption: 'Ini Kolom 2'
            //}]
            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

            store={this.props.store} // jangan di edit edit
          />
        </Popup>
        <ViewJournalVoucher
          ref={this.popupModalJournalVoucherRef}
          store={this.props.store}
          type={'view'}
        />
      </React.Fragment>
    );
  }
}

export default ModalGeneralLedger