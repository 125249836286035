import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalPembayaranTunjanganLain from 'components/pages/modal/sdm-umum/payroll/tunjangan-lain/form';
import { months, statusPembayaranRekapGaji } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';

import { confirmAlert } from 'react-confirm-alert'
import { alert } from 'devextreme/ui/dialog'

import notify from "devextreme/ui/notify";
import FormDaftarKaryawanPenerima from 'components/pages/modal/sdm-umum/payroll/tunjangan-lain/daftarKaryawanPenerima';
import { formatDefaultFullDate, formatDefaultMonthYear, formatNumberAum } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class PembayaranTunjanganLain extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        onClick : (e) => {
                            this.showModalPembayaranTunjanganLainEdit(e.row.data)
                        }
                    },
                    {
                        text : 'Daftar Karyawan Penerima',
                        hint : 'Daftar Karyawan Penerima',
                        onClick : (e) => {
                            this.showModalKaryawanPenerima(e.row.data)
                        }
                    },
                    {
                        text : 'Kirim',
                        hint : 'Kirim',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.kirim(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text : 'Batal',
                        hint : 'Batal',
                        onClick : async (e) => {
                            let data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteAPI(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ],
            },
            {
                dataField:'tunjanganName',
                caption:'Tunjangan'
            },
            {
                dataField:'keterangan',
                caption:'Keterangan'
            },
            {
                dataField:'dasarPerhitungan',
                caption:'Dasar Perhitungan',
                lookup: {
                    dataSource: [
                        {
                            id:1,
                            value:'% Gaji Pokok'
                        },
                        {
                            id:2,
                            value:'% Gaji + Tunjangan'
                        },
                        {
                            id:3,
                            value:'Nominal'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField:'jumlahKaryawan',
                caption:'Jumlah Karyawan'
            },
            {
                dataField:'besaran',
                caption:'Besaran Tunjangan',
                columns:[
                    {
                        dataField:'nilaiPersenGapok',
                        caption:'% Gaji',
                    },
                    {
                        dataField:'nilaiPersenGapokTunjangan',
                        caption:'% Gaji + Tunjangan',
                    },
                    {
                        dataField:'nilaiNominal',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField:'totalNominalTunjanganLain',
                caption:'Total Nominal Tunjangan Lain',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:'penerima',
                caption:'Penerima',
                lookup: {
                    dataSource: [
                        {
                            id:1,
                            value:'Semua'
                        },
                        {
                            id:2,
                            value:'Personal'
                        },
                    ], 
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField:'tanggalPengajuan',
                caption:'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'periode',
                caption:'Periode Gaji',
                columns:[
                    {
                        dataField:'bulanDari',
                        caption:'Dari Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                           
                    },
                    {
                        dataField:'tahunDari',
                        caption:'Dari Tahun',
                    },
                ]
            },
            {
                dataField:'statusId',
                caption:'Status',
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.showModalPembayaranTunjanganLainAddRef = React.createRef()
        this.showModalPembayaranTunjanganLainEditRef = React.createRef()
        this.showModalKaryawanPenerimaRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.dataKaryawanPenerima = {}
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-tunjangan-lains/tolak-pengajuan`, 'DELETE', data.id);

            notify({ message: "Berhasil batal pengajuan!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            this.forceRefresh()
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    kirim = async (data) => {
        try {
            var loadApi = `karyawayan-tunjangan-lains/kirimTunjanganLain/${data.id}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: {},
            })
            if (response) {
                this.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    showModalKaryawanPenerima = (data) => {
        if (data) {
            this.showModalKaryawanPenerimaRef.current.retrieveData(data)
        }
        this.showModalKaryawanPenerimaRef.current.show()
    }

    showModalPembayaranTunjanganLainAdd = () => {
        this.showModalPembayaranTunjanganLainAddRef.current.getListTunjanganType()
        this.showModalPembayaranTunjanganLainAddRef.current.getNoPengajuan()
        this.showModalPembayaranTunjanganLainAddRef.current.show()
    }

    showModalPembayaranTunjanganLainEdit = (data) => {
        this.showModalPembayaranTunjanganLainEditRef.current.retrieveData(data)
        this.showModalPembayaranTunjanganLainEditRef.current.getListTunjanganType()
        this.showModalPembayaranTunjanganLainEditRef.current.show()
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPembayaranTunjanganLainAdd(e)
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        )
    }

    exportExcel = async() => {
        let data = [];
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'view-tunjangan-lains','GET');
        const firstTableRowNum = 8;

        for (let value of response){
            console.log(value);
            let dataValue = {
                tunjanganName: value.tunjanganName,
                keterangan: value.keterangan,
                dasarPerhitungan: value.dasarPerhitungan === 1 ? '% Gaji Pokok' : value.dasarPerhitungan === 2 ? '% Gaji + Tunjangan' : 3 ? 'Nominal' : '-',
                jumlahKaryawan: value.jumlahKaryawan,
                nilaiPersenGapok: value.nilaiPersenGapok ? formatNumberAum(value.nilaiPersenGapok) : 0,
                nilaiPersenGapokTunjangan: value.nilaiPersenGapokTunjangan ? formatNumberAum(value.nilaiPersenGapokTunjangan) : 0,
                nilaiNominal: value.nilaiNominal ? formatNumberAum(value.nilaiNominal) : 0,
                totalNominalTunjanganLain: value.totalNominalTunjanganLain ? formatNumberAum(value.totalNominalTunjanganLain) : 0,
                penerima: value.penerima ===  1 ? 'Semua' : value.penerima === 2 ? 'Personal' : '',
                tanggalPengajuan: value.tanggalPengajuan ? formatDefaultFullDate(value.tanggalPengajuan) : '-',
                bulanDari: value.bulanDari === 1 ? 'Januari' : value.bulanDari === 2 ? 'Februari' : value.bulanDari === 3 ? 'Maret' : value.bulanDari === 4 ? 'April' : value.bulanDari === 5 ? 'Mei' : value.bulanDari === '6' ? 'Juni' : value.bulanDari === 7 ? 'Juli' : value.bulanDari === 8 ? 'Agustus' : value.bulanDari === 9 ? 'September' : value.bulanDari === 10 ? 'Oktober' : value.bulanDari === 11 ? 'November' : value.bulanDari === 12 ? 'Desember' : '',
                tahunDari: value.tahunDari,
                statusId: value.statusId === 1 ? 'Pengajuan' : value.statusId === 2 ? 'Telah disetujui' : value.statusId === 3 ? 'Ditolak' : ''
            };
            const ObjectValue = Object.values(dataValue);
            data.push(ObjectValue); 
        }

        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('Pengajuan Tunjangan Lain');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,   
                },
                columns: [
                    {name: 'Tunjangan'},
                    {name: 'Keterangan'},
                    {name: 'Dasar Penghitung'},
                    {name: 'Jumlah Karyawan'},
                    {name: '%Gaji'},
                    {name: '%Gaji + Tunjangan'},
                    {name: 'Nilai Nominal'},
                    {name: 'Total Nominal Tunjangan Lain'},
                    {name: 'Penerima'},
                    {name: 'Tanggal Pengajuan'},
                    {name: 'Dari Bulan'},
                    {name: 'Dari Tahun'},
                    {name: 'Status'},
                ],
                rows: data
            });
            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('D3').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D3').font = {size: 18, bold: true}
            
            worksheet.getCell('D4').value = 'PENGAJUAN TUNJANGAN LAIN';
            worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D4').font = {size: 18, bold: true}

            // worksheet.getColumn('E').alignment = {vertical: 'middle', horizontal: 'right'};
            // worksheet.getColumn('F').alignment = {vertical: 'middle', horizontal: 'right'};
            // worksheet.getColumn('G').alignment = {vertical: 'middle', horizontal: 'right'};
            // worksheet.getColumn('H').alignment = {vertical: 'middle', horizontal: 'right'};

            const WidthColums = 30;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;
            
            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_PENGAJUAN_TUNJANGAN_LAIN.xlsx`
                )
            })
        } catch (error) {
            console.log(error);
        }
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'view-tunjangan-lains','GET')
        
        // response = response.filter(value => value.statusId == 1)

        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Tunjangan Lain</h3>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='regist-pengkinian-pengajuan-aktifs'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Pengajuan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2', 
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPembayaranTunjanganLain 
                    ref={this.showModalPembayaranTunjanganLainAddRef}
                    store={this.props.store}
                    from={'pengajuan'}
                    action = {'add'}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalPembayaranTunjanganLain 
                    ref={this.showModalPembayaranTunjanganLainEditRef}
                    store={this.props.store}
                    from={'pengajuan'}
                    action = {'edit'}
                    forceRefresh={this.forceRefresh}
                />
                <FormDaftarKaryawanPenerima 
                    ref={this.showModalKaryawanPenerimaRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'tunjanganLain'}
                />
            </div>
        )
    }
}

export default PembayaranTunjanganLain