import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { ScrollView } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import { currency, product } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class JurnalLaporanTransaksiInvestasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
        }

        this.popupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options: {
            //         'text': 'Simpan',
            //         onClick: this.submitData.bind(this)
            //     },
            //     toolbar: 'bottom'
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.summary = [
            {
                displayFormat: 'Total :',
                showInColumn: 'description'
            },
            {
                name: 'db',
                column: 'db',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'cr',
                column: 'cr',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                dataField: 'akun',
                caption: 'Akun'
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            },
            {
                dataField: 'currencyRate',
                caption: 'Kurs',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'description',
                caption: 'Keterangan'
            },
            {
                dataField: 'db',
                caption: 'Debit',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'cr',
                caption: 'Kredit',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
        ]
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster: data,
        })
    }

    show(data) {
        this.setState({
            dataJurnal: {
                jurnal: data
            },
            popupVisible: true,
        })
        this.dataGridRef.current.forceRefresh(true)
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    loadData = () => {
        return this.state.dataJurnal.jurnal
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'75vw'}
                    height="65vh"
                    title={`Jurnal Transaksi`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    {/* <div style={{padding:"2%"}}> */}
                    <ScrollView width='100%' height='100%'>
                        <div className="container-fluid">
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                loadAPI='-'
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_HRD}

                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Jurnal Transaksi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Transaksi Mark To Market'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}
                                summaryTotalItem={this.summary}
                                onToolbarPreparing={this.onToolbarPreparing}

                                // height={'calc(100vh - 500px)'}
                                height={'350px'}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </ScrollView>
                    {/* </div> */}
                </Popup>
            </React.Fragment>
        )
    }
}

export default JurnalLaporanTransaksiInvestasi