import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatUploadFileData } from 'plugin/helper'
import ScrollView from 'devextreme-react/scroll-view';
import Box, { Item } from 'devextreme-react/box';

class FormModalEditTagihanPembelian extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
            fileUploadReceipt:[],
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField:'noPenerimaan',
                                label:{
                                    text:'No Penerimaan'
                                }
                            },
                            {
                                dataField:'noPemesanan',
                                label:{
                                    text:'No Pemesanan'
                                }
                            },
                            {
                                dataField:'noTagihan',
                                label:{
                                    text:'No Tagihan'
                                }
                            },
                            {
                                dataField:'noPenerimaan',
                                label:{
                                    text:'No Penerimaan'
                                }
                            },
                            {
                                dataField:'tanggalTagihan',
                                label:{
                                    text:'Tanggal Tagihan'
                                }
                            },
                            {
                                dataField:'tanggalJatuhTempo',
                                label:{
                                    text:'Tanggal Jatuh Tempo'
                                }
                            },
                            {
                                dataField:'jumlahUnit',
                                label:{
                                    text:'Jumlah Unit'
                                }
                            },
                            {
                                dataField:'hargaPerUnit',
                                label:{
                                    text:'Harga Per Unit'
                                }
                            },
                            {
                                dataField:'jumlahNominal',
                                label:{
                                    text:'Jumlah Nominal'
                                }
                            },
                            {
                                dataField:'discontAmount',
                                label:{
                                    text:'Discount % - Amount'
                                }
                            },
                            {
                                dataField:'jumlahTagihan',
                                label:{
                                    text:'Jumlah Tagihan'
                                }
                            },
                            {
                                dataField:'PPH2321',
                                label:{
                                    text:'PPH 23/21'
                                }
                            },
                            {
                                dataField:'jumlahTagihanBersih',
                                label:{
                                    text:'Jumlah Tagihan Bersih'
                                }
                            },
                            {
                                dataField:'mataUang',
                                label:{
                                    text:'Mata Uang'
                                }
                            },
                            {
                                dataField:'Kurs',
                                label:{
                                    text:'Kurs'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name:'bilyet-giro',
                        caption:'Bilyet Giro',
                        items: [
                            {
                                dataField:'nomorBilyet',
                                label:{
                                    text:'Nomor'
                                }
                            },
                            {
                                dataField:'atasNamaBilyet',
                                label:{
                                    text:'atasNama'
                                }
                            },
                            {
                                dataField:'bankBilyet',
                                label:{
                                    text:'Bank/Cabang'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name:'bank-transfer',
                        caption:'Bank Transfer',
                        items: [
                            {
                                dataField:'nomorBank',
                                label:{
                                    text:'Nomor'
                                }
                            },
                            {
                                dataField:'atasNamaBank',
                                label:{
                                    text:'atasNama'
                                }
                            },
                            {
                                dataField:'bankTransfer',
                                label:{
                                    text:'Bank/Cabang'
                                }
                            },
                        ]
                    },
                ]
            },
            
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}Name`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    submitData = () => {
    
    }

    updateData = () => {
    
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Edit Tagihan'} 
                width={"100vh"}
                height={"75vh"}
                toolbarItems={this.props.from == 'riwayat' ? [] : this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
            <div className='container-fluid'>
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
                <Box direction={"row"} width={"100%"}>
                    <Item ratio={1}>
                        <FileUploader
                            accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                            uploadMode={'useForm'}
                            onValueChanged={this.onUploadStarted.bind(this)} 
                            labelText="File Invoice"
                            showFileList={false}
                            name={'file'}
                            value = {this.state.fileUploadReceipt}
                            disabled={false }
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-4">File Name:</label>
                            <label className="col-8">{ this.state.DataMaster.fileName}</label>
                        </div>
                    </Item>
                    <Item ratio={1}>
                        <FileUploader
                            accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                            uploadMode={'useForm'}
                            onValueChanged={this.onUploadStarted.bind(this)} 
                            labelText="File Dokumen Penerimaan"
                            showFileList={false}
                            name={'file'}
                            value = {this.state.fileUploadReceipt}
                            disabled={false }
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-4">File Name:</label>
                            <label className="col-8">{ this.state.DataMaster.fileName}</label>
                        </div>
                    </Item>
                </Box>
                </div>
            </ScrollView>
            </Popup>
        )
    }
}

export default FormModalEditTagihanPembelian
