import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { Box, FileUploader } from 'devextreme-react';
import { Item } from 'devextreme-react/accordion';
import { banks, mpPenerimaType, pesertaById, pesertaFamiliesAll, product, rekBank, tipePembayaranMp } from 'dataSource/lookup';
import { formatDate, formatUploadFileData, formatUploadFileNameAndExtention, imageSource, pembulatan } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import { ScrollView } from 'devextreme-react';
import DropDownBox from 'devextreme-react/drop-down-box';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { alert } from 'devextreme/ui/dialog';

class ModalRegistrasiUpdatePengalihanMp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            isOpened: false,
            dataPengalihan: {},
            dataPeserta: {},
            tahun: 0
        }

        this.pesertaId = 0
        this.buttonClick = 0
        this.dataKeluargaByPesertaId = []

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formPengalihanRef = React.createRef()
        this.formPesertaRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => await this.submitData(),
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    // onClick: this.hide.bind(this)
                    onClick: () => this.hide(),
                },
                toolbar: 'bottom'
            }
        ]

        this.PopupViewToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataPeserta = [
            {
                dataField: 'pesertaId',
                colSpan: 5,
                label: {
                    text: 'Peserta',
                    visible: false
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: pesertaById(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function(item) {
                        // "item" can be null
                        return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                    },
                    readOnly: true,
                }
            }
        ]

        this.dataPengalihan = [
            // {
            //     itemType: 'group',
            //     items: [
            //         {
            //             dataField: 'pesertaId',
            //             colSpan: 5,
            //             label: {
            //                 text: 'Peserta'
            //             },
            //             editorType: 'dxSelectBox',
            //             editorOptions: {
            //                 dataSource: pesertaById(this.props.store),
            //                 valueExpr: 'id',
            //                 displayExpr: function(item) {
            //                     // "item" can be null
            //                     return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
            //                 },
            //                 searchEnabled: true,
            //                 deferRendering: false,
            //                 readOnly: this.props.actionType === 'edit' ? true : false,
            //             },
            //             visible: this.props.actionType === 'edit' ? true : false,
            //             validationRules: [
            //                 {
            //                     type: "required",
            //                     message: "Periode harus diisi"
            //                 }
            //             ]
            //         },
            //     ]
            // },
            {
                itemType: 'group',
                caption: 'Pengalihan',
                name: 'pengalihan',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                itemType: 'group',
                                colCount: 6,
                                items: [
                                    {
                                        dataField: 'periode',
                                        colSpan: 6,
                                        label: {
                                            text: 'Periode',
                                        },
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: [],
                                            valueExpr: 'tahun',
                                            displayExpr: 'tahun',
                                            searchEnabled: true,
                                            deferRendering: false,
                                            width: '100%',
                                            onValueChanged: (data) => {
                                                if (data.value) {
                                                    this.setState({
                                                        tahun: data.value
                                                    })
                                                }
                                            }
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: "Periode harus diisi"
                                            }
                                        ]
                                    },
                                    {
                                        dataField: 'productId',
                                        colSpan: 5,
                                        label: {
                                            text: 'Produk'
                                        },
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: product(this.props.store),
                                            valueExpr: 'id',
                                            displayExpr: 'productCode',
                                            searchEnabled: true,
                                            deferRendering: false
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: "Produk harus diisi"
                                            }
                                        ]
                                    },
                                    {
                                        dataField: 'proses',
                                        label: { visible: false },
                                        editorType: 'dxButton',
                                        editorOptions: {
                                            disabled: this.props.actionType == 'add' ? false : true,
                                            // disabled: false,
                                            text: 'Proses',
                                            onClick: async (e) => {
                                                if (this.state.dataPengalihan) {
                                                    await this.filterData()
                                                    this.buttonClick = 1
                                                } else {
                                                    this.buttonClick = 0
                                                    var message = 'Isi data terlebih dahulu!'
                                                    let result = await alert(message, 'Informasi')
                                                    if (result) {
                                                        // console.log('icikiwir')
                                                    }
                                                    return false
                                                }
                                            }
                                        }
                                    }
                                ]
                            },
                            {
                                dataField: 'tanggalMeninggal',
                                label: {
                                    text: 'Tanggal Meninggal'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    onValueChanged: (e) => {
                                        var value = e.value
                                        var date = new Date(value)
                                        var BulanNilaiMpBaru = date.setMonth(date.getMonth() + 5)
                                        var tanggalNilaiMpBaru = new Date(BulanNilaiMpBaru).setDate(1)
                                        var data = this.state.dataPengalihan
                                        data.tanggalNilaiMpBaru = new Date(tanggalNilaiMpBaru)
                                        this.formPengalihanRef.current.instance.updateData(data)
                                    }
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Tanggal Meninggal harus diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'tanggalPengalihan',
                                label: {
                                    text: 'Tanggal Pengalihan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Tanggal Pengalihan harus diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'noSkPengalihan',
                                label: {
                                    text: 'No SK Pengalihan'
                                },
                            },
                            {
                                dataField: 'tanggalSkPengalihan',
                                label: {
                                    text: 'Tanggal SK Pengalihan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                }
                            },
                            {
                                dataField: 'tanggalNilaiMpBaru',
                                name: 'tanggalNilaiMpBaru',
                                label: {
                                    text: 'Tanggal Nilai MP Baru'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Tanggal Nilai MP Baru harus diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'keterangan',
                                label: {
                                    text: 'Alasan Pengalihan'
                                },
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                name: 'perhitungan',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Penerima Baru',
                        name: 'penerimaBaru',
                        items: [
                            {
                                dataField: 'newMpPenerimaTypeId',
                                label: { text: 'Tipe Penerima' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: mpPenerimaType(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'penerimaName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: async(data) => {
                                        if(this.props.actionType === 'add'){
                                            var value = data.value

                                            var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`
                                            var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKeluarga, 'GET')

                                            var dataAkun = this.state.dataPengalihan
                                            var dataKeluarga = getKeluarga
                                            var penerimaFamilyId = this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId')
                                            if (value == 1) {
                                                penerimaFamilyId.editorOptions.readOnly = true
                                                penerimaFamilyId.editorOptions.placeholder = ""
                                                penerimaFamilyId.editorOptions.dataSource = []
                                                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
                                            } else if (value == 2) {
                                                var dataKeluargaSI = dataKeluarga.filter(val => val.relationCode == 'SI')
                                                penerimaFamilyId.editorOptions.readOnly = false
                                                penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                                                penerimaFamilyId.editorOptions.dataSource = dataKeluargaSI
                                                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
                                            } else if (value == 3) {
                                                var dataKeluargaAK = dataKeluarga.filter(val => val.relationCode == 'AK')
                                                penerimaFamilyId.editorOptions.readOnly = false
                                                penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                                                penerimaFamilyId.editorOptions.dataSource = dataKeluargaAK
                                                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
                                            } else {
                                                penerimaFamilyId.editorOptions.readOnly = false
                                                penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                                                penerimaFamilyId.editorOptions.dataSource = dataKeluarga
                                                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
                                            }
                                        }
                                    }
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Field ini harus diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'newFamilyId',
                                name: 'newFamilyId',
                                label: { text: 'Nama Penerima' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'fullName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: async(e) => {
                                        let data = e.selectedItem;                                        ;
                                        if (data) {
                                            let getAllPesertaFamilies = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`, 'GET');
                                            for (let value of getAllPesertaFamilies) {
                                                if (value.id === data.id) {
                                                    if (value.ahliWaris !== true) {
                                                        await alert(`Anggota keluarga a/n ${value.fullName} belum di set sebagai penerima MP`, 'Informasi');
                                                        return null;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'newMpPaymentTypeId',
                                label: { text: 'Tipe Pembayaran' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: tipePembayaranMp(this.props.store, true),
                                    valueExpr: 'id',
                                    displayExpr: 'typeName',
                                    searchEnabled: true,
                                    deferRendering: false
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Field ini harus diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'newMpBulanan',
                                label: { text: 'MP Bulanan' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulananRound',
                                label: { text: 'MP Bulanan Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligus',
                                label: { text: 'MP Sekaligus' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligusRound',
                                label: { text: 'MP Sekaligus Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulanan80',
                                label: { text: 'MP Bulanan 80' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulanan80Round',
                                label: { text: 'MP Bulanan 80 Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligus20',
                                label: { text: 'MP Sekaligus 20' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligus20Round',
                                label: { text: 'MP Sekaligus 20 Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulananJadud',
                                label: { text: 'MP Bulanan Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulananJadud80',
                                label: { text: 'MP Bulanan 80 Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulananJadud80Round',
                                label: { text: 'MP Bulanan 80 Janda/Duda Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligusJadud',
                                label: { text: 'MP Sekaligus Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligusJadud20',
                                label: { text: 'MP Sekaligus 20 Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligusJadud20Round',
                                label: { text: 'MP Sekaligus 20 Janda/Duda Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulananAnak',
                                label: { text: 'MP Bulanan Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulananAnak80',
                                label: { text: 'MP Bulanan 80 Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    step: 0
                                }
                            },
                            {
                                dataField: 'newMpBulananAnak80Round',
                                label: { text: 'MP Bulanan 80 Anak Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligusAnak',
                                label: { text: 'MP Sekaligus Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligusAnak20',
                                label: { text: 'MP Sekaligus 20 Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'newMpSekaligusAnak20Round',
                                label: { text: 'MP Sekaligus 20 Anak Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'newMpRekeningNo',
                                label: { text: 'Rekening No' }
                            },
                            {
                                dataField: 'newMpRekeningName',
                                label: { text: 'Rekening Nama' }
                            },
                            {
                                dataField: 'newMpRekeningBankId',
                                label: { text: 'Bank' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'initialName',
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            },
                            {
                                dataField: 'newMpRekeningCabang',
                                label: { text: 'Rekening Cabang' }
                            },
                            {
                                dataField: 'newPaymentRekbankId',
                                label: { text: 'Rekening Pembayaran MP' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'rekName',
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Penerima Sebelumnya',
                        items: [
                            {
                                dataField: 'lastMpPenerimaTypeId',
                                label: { text: 'Tipe Penerima' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly: true,
                                    dataSource: mpPenerimaType(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'penerimaName',
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            },
                            {
                                dataField: 'lastPenerimaName',
                                label: { text: 'Nama Penerima' },
                                // editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly: true,
                                    // dataSource: pesertaFamiliesAll(this.props.store,true),
                                    // valueExpr: 'id',
                                    // displayExpr: 'fullName',
                                    // searchEnabled: true,
                                    // deferRendering: false
                                }
                            },
                            {
                                dataField: 'lastMpPaymentTypeId',
                                label: { text: 'Tipe Pembayaran' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly: true,
                                    dataSource: tipePembayaranMp(this.props.store, true),
                                    valueExpr: 'id',
                                    displayExpr: 'typeName',
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            },
                            {
                                dataField: 'lastMpBulanan',
                                label: { text: 'MP Bulanan' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulananRound',
                                label: { text: 'MP Bulanan Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligus',
                                label: { text: 'MP Sekaligus' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligusRound',
                                label: { text: 'MP Sekaligus Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulanan80',
                                label: { text: 'MP Bulanan 80' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulanan80Round',
                                label: { text: 'MP Bulanan 80 Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligus20',
                                label: { text: 'MP Sekaligus 20' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligus20Round',
                                label: { text: 'MP Sekaligus 20 Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulananJadud',
                                label: { text: 'MP Bulanan Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulananJadud80',
                                label: { text: 'MP Bulanan 80 Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulananJadud80Round',
                                label: { text: 'MP Bulanan 80 Janda/Duda Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligusJadud',
                                label: { text: 'MP Sekaligus Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligusJadud20',
                                label: { text: 'MP Sekaligus 20 Janda/Duda' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligusJadud20Round',
                                label: { text: 'MP Sekaligus 20 Janda/Duda Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulananAnak',
                                label: { text: 'MP Bulanan Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulananAnak80',
                                label: { text: 'MP Bulanan 80 Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpBulananAnak80Round',
                                label: { text: 'MP Bulanan 80 Anak Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligusAnak',
                                label: { text: 'MP Sekaligus Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligusAnak20',
                                label: { text: 'MP Sekaligus 20 Anak' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpSekaligusAnak20Round',
                                label: { text: 'MP Sekaligus 20 Anak Bulat' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    format: '#,##0.00',
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpRekeningNo',
                                label: { text: 'Rekening No' },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    readOnly: true,
                                    // step: 0
                                }
                            },
                            {
                                dataField: 'lastMpRekeningName',
                                label: { text: 'Rekening Nama' },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'lastMpRekeningBankId',
                                label: { text: 'Bank' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly: true,
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'initialName',
                                    searchEnabled: true,
                                    deferRendering: false
                                },
                            },
                            {
                                dataField: 'lastMpRekeningCabang',
                                label: { text: 'Rekening Cabang' },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'lastPaymentRekbankId',
                                label: { text: 'Rekening Pembayaran MP' },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    readOnly: true,
                                    dataSource: rekBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'rekName',
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            },
                        ]
                    },
                ]
            },
        ]

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show(data) {
        let ns = []
        for(let val of data) {
            ns.push({
                tahun: val
            })
        }
        this.formPengalihanRef.current.instance.getEditor('periode').option('dataSource', ns);
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide = () => {
        if(this.props.actionType === 'add'){
            this.buttonClick = 0
            this.pesertaId = 0
            this.setState({
                popupVisible: false,
                dataPengalihan: {},
            });
        
            this.dropDownBoxRef.current.instance.option('value', "")
        }else{
            this.buttonClick = 0
            this.pesertaId = 0

            this.setState({
                popupVisible: false,
                dataPengalihan: {},
                dataPeserta: {}
            });
        }
    }

    onValueChanged = (e) => {
        var fieldValue = e.value
        this.pesertaId = fieldValue[0] || fieldValue
    }

    submitData = async () => {
        var pesertaId
        if(this.props.actionType === 'add'){
            pesertaId = this.pesertaId
        }else{
            pesertaId = this.state.dataPeserta.pesertaId
        }

        let dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/${pesertaId}`, 'GET')
        var dataKP = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${pesertaId}`, 'GET')
        let penerimaId = this.formPengalihanRef.current.instance.getEditor('newFamilyId').option('value');
        console.log(penerimaId);
        
        if(dataKP.length > 0){
            dataKP = dataKP[0]
        }
        var dataSubmit = { ...this.state.dataPengalihan }

        var submitData = {
            ...dataSubmit,
            nik: dataPeserta.nik,
            kepesertaanProductId: dataKP.id
        }

        let getAllPesertaFamilies = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`, 'GET');

        for (let value of getAllPesertaFamilies) {
            if (value.id === penerimaId) {
                if (value.ahliWaris === false) {
                    await alert(`Anggota keluarga a/n ${value.fullName} belum di set sebagai penerima MP`, 'Data Tidak Bisa Disimpan');
                    return null;
                }
            }
        }

        if (this.props.actionType === 'edit') {
            this.buttonClick = 1
        }

        if (this.buttonClick == 0) {
            await alert("Silahkan lakukan proses terlebih dahulu", 'Informasi')
            return null;
        } else {

            if (this.props.actionType === 'add') {
                await this.props.submitData(submitData)
            } else {
                await this.props.updateData(submitData)
            }
            this.buttonClick = 0
            this.hide()
        }   
    }

    retrieveData = async (data) => {
        var loadAPIPengalihan = `peserta-pensiuns/loadDataPengalihanMp/${data.id}`
        var getPengalihan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPengalihan, 'GET')

        let pesertaData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/${data.pesertaId}`, 'GET')
        var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${data.pesertaId}`, 'GET')
        if(getKp.length > 0){
            getKp = getKp[0]
        }

        var penerimaName
        var penerimaId
        if (getKp.mpPenerimaTypeId == 1) {
            penerimaName = pesertaData.fullName
            penerimaId = pesertaData.id
        } else {
            var loadAPIFamily = `peserta-families/${getKp.mpPenerimaPesertaFamilyTypeId}`
            var getFamily = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'GET')
            penerimaName = getFamily.fullName
            penerimaId = getFamily.id
        }

        if(getPengalihan.length > 0){
            getPengalihan = getPengalihan[0]

            var dataPengalihan = {
                id: data.id,
                status: data.status,
                periode: getPengalihan.periode,
                pesertaId: data.pesertaId,
                productId: getPengalihan.produk,
                tanggalMeninggal: getPengalihan.p_tanggal_meninggal,
                tanggalPengalihan: getPengalihan.p_tanggal_pengalihan,
                noSkPengalihan: getPengalihan.p_no_sk_pengalihan,
                tanggalSkPengalihan: getPengalihan.p_tanggal_sk_pengalihan,
                tanggalNilaiMpBaru: getPengalihan.tanggal_nilai_mp_baru,
                keterangan: getPengalihan.alasan_pengalihan,
                newMpPenerimaTypeId: getPengalihan.p_new_tipe_penerima,
                newFamilyId: getPengalihan.p_new_family_id,
                newMpPaymentTypeId: getPengalihan.p_new_tipe_pembayaran,
                newMpBulanan: getPengalihan.p_new_mp_bulanan,
                newMpBulananRound: getPengalihan.p_new_mp_bulanan_bulat,
                newMpSekaligus: getPengalihan.p_new_mp_sekaligus,
                newMpSekaligusRound: getPengalihan.p_new_mp_sekaligus_bulat,
                newMpBulanan80: getPengalihan.p_new_mp_bulanan_80,
                newMpBulanan80Round: getPengalihan.p_new_mp_bulanan_80_bulat,
                newMpSekaligus20: getPengalihan.p_new_mp_sekaligus_20,
                newMpSekaligus20Round: getPengalihan.p_new_mp_sekaligus_20_bulat,
                newMpBulananJadud: getPengalihan.p_new_mp_bulanan_jadud,
                newMpBulananJadud80: getPengalihan.p_new_mp_bulanan_80_jadud,
                newMpBulananJadud80Round: getPengalihan.p_new_mp_bulanan_80_jadud_bulat,
                newMpSekaligusJadud: getPengalihan.p_new_mp_sekaligus_jadud,
                newMpSekaligusJadud20: getPengalihan.p_new_mp_sekaligus_20_jadud,
                newMpSekaligusJadud20Round: getPengalihan.p_new_mp_sekaligus_20_jadud_bulat,
                newMpBulananAnak: getPengalihan.p_new_mp_bulanan_anak,
                newMpBulananAnak80: getPengalihan.p_new_mp_bulanan_80_anak,
                newMpBulananAnak80Round: getPengalihan.p_new_mp_bulanan_80_anak_bulat,
                newMpSekaligusAnak: getPengalihan.p_new_mp_sekaligus_anak,
                newMpSekaligusAnak20: getPengalihan.p_new_mp_sekaligus_20_anak,
                newMpSekaligusAnak20Round: getPengalihan.p_new_mp_sekaligus_20_anak_bulat,
                newMpRekeningNo: getPengalihan.p_new_rekening_no,
                newMpRekeningName: getPengalihan.p_new_rekening_nama,
                newMpRekeningBankId: getPengalihan.p_new_bank,
                newMpRekeningCabang: getPengalihan.p_new_rekening_cabang,
                newPaymentRekbankId: getPengalihan.p_new_rekening_pembayaran_mp,
                lastMpPenerimaTypeId: getPengalihan.p_last_tipe_penerima,
                lastPenerimaName: penerimaName,
                lastMpPaymentTypeId: getPengalihan.p_last_tipe_pembayaran,
                lastMpBulanan: getPengalihan.p_last_mp_bulanan,
                lastMpBulananRound: getPengalihan.p_last_mp_bulanan_bulat,
                lastMpSekaligus: getPengalihan.p_last_mp_sekaligus,
                lastMpSekaligusRound: getPengalihan.p_last_mp_sekaligus_bulat,
                lastMpBulanan80: getPengalihan.p_last_mp_bulanan_80,
                lastMpBulanan80Round: getPengalihan.p_last_mp_bulanan_80_bulat,
                lastMpSekaligus20: getPengalihan.p_last_mp_sekaligus_20,
                lastMpSekaligus20Round: getPengalihan.p_last_mp_sekaligus_20_bulat,
                lastMpBulananJadud: getPengalihan.p_last_mp_bulanan_jadud,
                lastMpBulananJadud80: getPengalihan.p_last_mp_bulanan_80_jadud,
                lastMpBulananJadud80Round: getPengalihan.p_last_mp_bulanan_80_jadud_bulat,
                lastMpSekaligusJadud: getPengalihan.p_last_mp_sekaligus_jadud,
                lastMpSekaligusJadud20: getPengalihan.p_last_mp_sekaligus_20_jadud,
                lastMpSekaligusJadud20Round: getPengalihan.p_last_mp_sekaligus_20_jadud_bulat,
                lastMpBulananAnak: getPengalihan.p_last_mp_bulanan_anak,
                lastMpBulananAnak80: getPengalihan.p_last_mp_bulanan_80_anak,
                lastMpBulananAnak80Round: getPengalihan.p_last_mp_bulanan_80_anak_bulat,
                lastMpSekaligusAnak: getPengalihan.p_last_mp_sekaligus_anak,
                lastMpSekaligusAnak20: getPengalihan.p_last_mp_sekaligus_20_anak,
                lastMpSekaligusAnak20Round: getPengalihan.p_last_mp_sekaligus_20_anak_bulat,
                lastMpRekeningNo: getPengalihan.p_last_rekening_no,
                lastMpRekeningName: getPengalihan.p_last_rekening_nama,
                lastMpRekeningBankId: getPengalihan.p_last_bank,
                lastMpRekeningCabang: getPengalihan.p_last_rekening_cabang,
                lastPaymentRekbankId: getPengalihan.p_last_rekening_pembayaran_mp,
                lastFamilyId: getPengalihan.p_last_family_id
            }

            var dataPeserta = {
                pesertaId: data.pesertaId,
            }

            var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${data.pesertaId}`, 'GET')

            var dataKeluarga = getKeluarga
            var penerimaFamilyId = this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId')
            if (getPengalihan.p_new_tipe_penerima == 1) {
                penerimaFamilyId.editorOptions.readOnly = true
                penerimaFamilyId.editorOptions.placeholder = ""
                penerimaFamilyId.editorOptions.dataSource = []
                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
            } else if (getPengalihan.p_new_tipe_penerima == 2) {
                var dataKeluargaSI = dataKeluarga.filter(val => val.relationCode == 'SI')
                penerimaFamilyId.editorOptions.readOnly = false
                penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                penerimaFamilyId.editorOptions.dataSource = dataKeluargaSI
                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
            } else if (getPengalihan.p_new_tipe_penerima == 3) {
                var dataKeluargaAK = dataKeluarga.filter(val => val.relationCode == 'AK')
                penerimaFamilyId.editorOptions.readOnly = false
                penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                penerimaFamilyId.editorOptions.dataSource = dataKeluargaAK
                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
            } else {
                penerimaFamilyId.editorOptions.readOnly = false
                penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                penerimaFamilyId.editorOptions.dataSource = dataKeluarga
                this.formPengalihanRef.current.instance.itemOption('perhitungan.penerimaBaru.newFamilyId', penerimaFamilyId)
            }

            this.formPengalihanRef.current.instance.updateData(dataPengalihan)
            this.formPesertaRef.current.instance.updateData(dataPeserta)
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention
            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)

                this.setState(prevState => ({
                    dataPengalihan: {
                        ...prevState.dataPengalihan,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                        [`${fieldName}Name`]: formattedFileNameAndExtention.name,
                        webExtentionFile: formattedFileNameAndExtention.extention,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }

    filterData = async () => {
        var data = this.state.dataPengalihan
        let dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/${this.pesertaId}`, 'GET')
        var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${this.pesertaId}`, 'GET')

        let getPengalihanPenerimaSebelum = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pengalihan-mps/pengalihanGetPenerimaSebelum/${dataPeserta.nik}`, 'GET');
        console.log(getPengalihanPenerimaSebelum);

        if(getKp.length > 0){
            getKp = getKp[0]
        }

        try{
            var penerimaName
            var penerimaId 
            if (getKp.mpPenerimaTypeId == 1) {
                penerimaName = dataPeserta.fullName
                penerimaId = dataPeserta.id
            } else {
                // var loadAPIFamily = `peserta-families/${getKp.mpPenerimaPesertaFamilyTypeId}`
                // var getFamily = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'GET')

                // penerimaName = getFamily.fullName
                // penerimaId = getFamily.id

                penerimaName = getPengalihanPenerimaSebelum.namaPenerima;
                penerimaId = data.newFamilyId
            }

            let loadAPI = `peserta-pensiuns/calculatePensiunByFunction/2/${data.productId}/${dataPeserta.nik}/${data.periode}/4/${formatDate(data.tanggalPengalihan)}/${data.newMpPenerimaTypeId}/${data.newFamilyId ? data.newFamilyId : 0}/${data.newMpPaymentTypeId}/false/${formatDate(data.tanggalMeninggal)}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

            // let responsePengalihanPenerimaSebelum = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pengalihan-mps/pengalihanGetPenerimaSebelum/${dataPeserta.nik}`, 'GET');

            var dataPengalihan = {}

            if(data.newMpPenerimaTypeId === 2){
                dataPengalihan = {
                    newMpBulananJadud: response[0].mp_bulanan_100_ori ? response[0].mp_bulanan_100_ori : 0,
                    newMpSekaligusJadud: response[0].mp_sekaligus_100_ori ? response[0].mp_sekaligus_100_ori : 0,
                    newMpBulananJadud80: response[0].mp_bulanan_80_ori ? response[0].mp_bulanan_80_ori : 0,
                    newMpBulananJadud80Round: response[0].mp_bulanan_80 ? response[0].mp_bulanan_80 : 0,
                    newMpSekaligusJadud20: response[0].mp_sekaligus_20_ori ? response[0].mp_sekaligus_20_ori : 0,
                    newMpSekaligusJadud20Round: response[0].mp_sekaligus_20 ? response[0].mp_sekaligus_20 : 0,
                    newMpBulananAnak: 0,
                    newMpBulananAnak80: 0,
                    newMpBulananAnak80Round: 0,
                    newMpSekaligusAnak: 0,
                    newMpSekaligusAnak20: 0,
                    newMpSekaligusAnak20Round: 0,
                }
            }else if(data.newMpPenerimaTypeId === 3){
                dataPengalihan = {
                    newMpBulananJadud: 0,
                    newMpBulananRound: 0,
                    newMpSekaligusJadud: 0,
                    newMpSekaligusRound: 0,
                    newMpBulananJadud80: 0,
                    newMpBulananJadud80Round: 0,
                    newMpSekaligusJadud20: 0,
                    newMpSekaligusJadud20Round: 0,
                    newMpBulananAnak: response[0].mp_bulanan_100_ori ? response[0].mp_bulanan_100_ori : 0,
                    newMpSekaligusAnak: response[0].mp_sekaligus_100_ori ? response[0].mp_sekaligus_100_ori : 0,
                    newMpBulananAnak80: response[0].mp_bulanan_80_ori ? response[0].mp_bulanan_80_ori : 0,
                    newMpBulananAnak80Round: response[0].mp_bulanan_80 ? response[0].mp_bulanan_80 : 0,
                    newMpSekaligusAnak20: response[0].mp_sekaligus_20_ori ? response[0].mp_sekaligus_20_ori : 0,
                    newMpSekaligusAnak20Round: response[0].mp_sekaligus_20 ? response[0].mp_sekaligus_20 : 0,
                }
            }

            var dataResult = {
                ...dataPengalihan,
                newPenerimaName: response[0].nama_penerima_mp,
                newMpBulanan: response[0].mp_bulanan_100_ori ? response[0].mp_bulanan_100_ori : 0,
                newMpBulananRound: response[0].mp_bulanan_100 ? response[0].mp_bulanan_100 : 0,
                newMpSekaligus: response[0].mp_sekaligus_100_ori ? response[0].mp_sekaligus_100_ori : 0,
                newMpSekaligusRound: response[0].mp_sekaligus_100 ? response[0].mp_sekaligus_100 : 0,
                newMpBulanan80: response[0].mp_bulanan_80_ori ? response[0].mp_bulanan_80_ori : 0,
                newMpBulanan80Round: response[0].mp_bulanan_80 ? response[0].mp_bulanan_80 : 0,
                newMpSekaligus20: response[0].mp_sekaligus_20_ori ? response[0].mp_sekaligus_20_ori : 0,
                newMpSekaligus20Round: response[0].mp_sekaligus_20 ? response[0].mp_sekaligus_20 : 0,
                newMpRekeningNo: getKp.mpRekeningNo,
                newMpRekeningName: getKp.mpRekeningName,
                newMpRekeningCabang: getKp.mpRekeningCabang,
                newMpRekeningBankId: getKp.mpRekeningBankId,
                newPaymentRekbankId: getKp.paymentRekbankId,

                // LAST
                kepersertaanProductId: getPengalihanPenerimaSebelum.kepesertaanProductId,
                lastMpBulananPeserta: getPengalihanPenerimaSebelum.mpBulanan || 0,
                
                lastMpBulananAnak: getPengalihanPenerimaSebelum.mpBulananAnak || 0,
                // lastMpBulananAnak80: getKp.mpBulananAnak ? getKp.mpBulananAnak * 80 / 100 : 0,
                lastMpBulananAnak80: getPengalihanPenerimaSebelum.mpBulanan80Anak || 0,
                // lastMpBulananAnak80Round: getKp.mpBulananAnak ? pembulatan(getKp.mpBulananAnak * 80 / 100, 2) : 0,
                lastMpBulananAnak80Round: getPengalihanPenerimaSebelum.mpBulanan80AnakBulat,
                
                lastMpBulananJadud: getPengalihanPenerimaSebelum.mpBulananJandaDuda || 0,
                // lastMpBulananJadud80: getKp.mpBulananJadud ? getKp.mpBulananJadud * 80 / 100 : 0,
                lastMpBulananJadud80: getPengalihanPenerimaSebelum.mpBulanan80JandaDuda || 0,
                // lastMpBulananJadud80Round: getKp.mpBulananJadud ? pembulatan(getKp.mpBulananJadud * 80 / 100, 2) : 0,
                lastMpBulananJadud80Round: getPengalihanPenerimaSebelum.mpBulanan80JandaDudaBulat,

                lastMpSekaligusPeserta: getPengalihanPenerimaSebelum.mpSekaligus || 0,

                lastMpSekaligusAnak: getPengalihanPenerimaSebelum.mpSekaligusAnak || 0,
                // lastMpSekaligusAnak20: getKp.mpSekaligusAnak ? getKp.mpSekaligusAnak * 20 / 100 : 0,
                lastMpSekaligusAnak20: getPengalihanPenerimaSebelum.mpSekaligus20Anak || 0,
                // lastMpSekaligusAnak20Round: getKp.mpSekaligusAnak ? pembulatan(getKp.mpSekaligusAnak * 20 / 100, 2) : 0,
                lastMpSekaligusAnak20Round: getPengalihanPenerimaSebelum.mpSekaligus20AnakBulat,

                lastMpSekaligusJadud: getPengalihanPenerimaSebelum.mpSekaligusJandaDuda || 0,
                // lastMpSekaligusJadud20: getKp.mpSekaligusJadud ? getKp.mpSekaligusJadud * 20 / 100 : 0,
                lastMpSekaligusJadud20: getPengalihanPenerimaSebelum.mpSekaligus20JandaDuda || 0,
                // lastMpSekaligusJadud20Round: getKp.mpSekaligusJadud ? pembulatan(getKp.mpSekaligusJadud * 20 / 100, 2) : 0,
                lastMpSekaligusJadud20Round: getPengalihanPenerimaSebelum.mpSekaligus20JandaDudaBulat || 0,

                lastMpBulanan: getPengalihanPenerimaSebelum.mpBulanan || 0,
                // lastMpBulananRound: getKp.mpBulanan ? pembulatan(getKp.mpBulanan, 2) : 0,
                lastMpBulananRound: getPengalihanPenerimaSebelum.mpBulananBulat || 0,

                lastMpSekaligus: getPengalihanPenerimaSebelum.mpSekaligus || 0,
                // lastMpSekaligusRound: getKp.mpSekaligus ? pembulatan(getKp.mpSekaligus, 2) : 0,
                lastMpSekaligusRound: getPengalihanPenerimaSebelum.mpSekaligusBulat || 0,

                // lastMpBulanan80: getKp.mpBulanan * 80 / 100 || 0,
                lastMpBulanan80: getPengalihanPenerimaSebelum.mpBulanan80 || 0,
                // lastMpBulanan80Round: getKp.mpBulanan ? pembulatan(getKp.mpBulanan * 80 / 100, 2) : 0,
                lastMpBulanan80Round: getPengalihanPenerimaSebelum.mpBulanan80Bulat || 0,

                // lastMpSekaligus20: getKp.mpSekaligus * 20 / 100 || 0,
                lastMpSekaligus20: getPengalihanPenerimaSebelum.mpSekaligus20 || 0,
                // lastMpSekaligus20Round: getKp.mpSekaligus ? pembulatan(getKp.mpSekaligus * 20 / 100, 2) : 0,
                lastMpSekaligus20Round: getPengalihanPenerimaSebelum.mpSekaligus20Bulat || 0,

                lastMpRekeningNo: getPengalihanPenerimaSebelum.rekeningNo,
                lastMpRekeningName: getPengalihanPenerimaSebelum.rekeningNama,
                lastMpRekeningCabang: getPengalihanPenerimaSebelum.rekeningCabang,
                lastMpRekeningBankId: parseInt(getPengalihanPenerimaSebelum.bank),
                lastPaymentRekbankId: parseInt(getPengalihanPenerimaSebelum.rekeningPembayaranMp),

                lastMpPenerimaTypeId: parseInt(getPengalihanPenerimaSebelum.tipePenerima),
                lastMpPaymentTypeId: parseInt(getPengalihanPenerimaSebelum.tipePembayaran),
                lastPenerimaName: getPengalihanPenerimaSebelum.namaPenerima,
                lastFamilyId: penerimaId
            }
            this.formPengalihanRef.current.instance.updateData(dataResult)

            var text = 'Data berhasil di proses!'
            var type = 'success'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        }catch(e){
            console.log(e)
            notify({ message: 'Data gagal di proses!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
        
        // if (data.tanggalPengalihan && data.productId && this.pesertaId) {
        //     try {
                // var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`
                // var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKeluarga, 'GET')

                // this.dataKeluargaByPesertaId = getKeluarga

        //         var loadAPISimpleKp = `kepersertaan-products/getAllByPesertaIdAndProductId/${this.pesertaId}/${data.productId}`
        //         var getSimpleKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPISimpleKp, 'GET')

        //         var getKp = { ...data }
        //         if (getSimpleKp.length !== 0) {
        //             var loadAPIKp = `kepersertaan-products/${getSimpleKp[0].ids}`
        //             var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKp, 'GET')

        //             var loadAPINewPerhitungan = `peserta-pensiuns/pengalihanMp/${this.pesertaId}/${data.productId}/${formatDate(data.tanggalPengalihan)}/${formatDate(data.tanggalMeninggal)}/${this.state.tahun}`
        //             var getNewPerhitungan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPINewPerhitungan, 'GET')
        //             if (getKp.mpPenerimaTypeId == 1) {
        //                 var loadAPIPeserta = `pesertas/${getSimpleKp[0].pesertaIds}`
        //                 var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta, 'GET')

        //                 var penerimaName = getPeserta.fullName
        //                 var penerimaId = getPeserta.id
        //             } else {
        //                 var loadAPIFamily = `peserta-families/${getKp.mpPenerimaPesertaFamilyTypeId}`
        //                 var getFamily = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'GET')

        //                 var penerimaName = getFamily.fullName
        //                 var penerimaId = getFamily.id
        //             }

        //             // LAST
        //             getKp.kepersertaanProductId = getKp.id
        //             getKp.lastMpBulananPeserta = getKp.mpBulananPeserta || 0

        //             getKp.lastMpBulananAnak = getKp.mpBulananAnak || 0
        //             getKp.lastMpBulananAnak80 = getKp.lastMpBulananAnak ? getKp.lastMpBulananAnak * 80 / 100 : 0
        //             getKp.lastMpBulananAnak80Round = getKp.lastMpBulananAnak80 ? pembulatan(getKp.lastMpBulananAnak80, 2) : 0

        //             getKp.lastMpBulananJadud = getKp.mpBulananJadud || 0
        //             getKp.lastMpBulananJadud80 = getKp.lastMpBulananJadud ? getKp.lastMpBulananJadud * 80 / 100 : 0
        //             getKp.lastMpBulananJadud80Round = getKp.lastMpBulananJadud80 ? pembulatan(getKp.lastMpBulananJadud80, 2) : 0

        //             getKp.lastMpSekaligusPeserta = getKp.mpSekaligusPeserta || 0

        //             getKp.lastMpSekaligusAnak = getKp.mpSekaligusAnak || 0
        //             getKp.lastMpSekaligusAnak20 = getKp.lastMpSekaligusAnak ? getKp.lastMpSekaligusAnak * 20 / 100 : 0
        //             getKp.lastMpSekaligusAnak20Round = getKp.lastMpSekaligusAnak20 ? pembulatan(getKp.lastMpSekaligusAnak20, 2) : 0

        //             getKp.lastMpSekaligusJadud = getKp.mpSekaligusJadud || 0
        //             getKp.lastMpSekaligusJadud20 = getKp.lastMpSekaligusJadud ? getKp.lastMpSekaligusJadud * 20 / 100 : 0
        //             getKp.lastMpSekaligusJadud20Round = getKp.lastMpSekaligusJadud20 ? pembulatan(getKp.lastMpSekaligusJadud20, 2) : 0

        //             getKp.lastMpBulanan = getKp.mpBulananPeserta || 0
        //             getKp.lastMpBulananRound = getKp.lastMpBulanan ? pembulatan(getKp.lastMpBulanan, 2) : 0
        //             getKp.lastMpSekaligus = getKp.mpSekaligus || 0
        //             getKp.lastMpSekaligusRound = getKp.lastMpSekaligus ? pembulatan(getKp.lastMpSekaligus, 2) : 0
        //             getKp.lastMpBulanan80 = getKp.lastMpBulanan * 80 / 100 || 0
        //             getKp.lastMpBulanan80Round = getKp.lastMpBulanan80 ? pembulatan(getKp.lastMpBulanan80, 2) : 0
        //             getKp.lastMpSekaligus20 = getKp.lastMpSekaligus * 20 / 100 || 0
        //             getKp.lastMpSekaligus20Round = getKp.lastMpSekaligus20 ? pembulatan(getKp.lastMpSekaligus20, 2) : 0

        //             getKp.lastMpRekeningNo = getKp.mpRekeningNo
        //             getKp.lastMpRekeningName = getKp.mpRekeningName
        //             getKp.lastMpRekeningCabang = getKp.mpRekeningCabang
        //             getKp.lastMpRekeningBankId = getKp.mpRekeningBankId
        //             getKp.lastPaymentRekbankId = getKp.paymentRekbankId

        //             getKp.lastMpPenerimaTypeId = getKp.mpPenerimaTypeId
        //             getKp.lastMpPaymentTypeId = getKp.mpPaymentTypeId
        //             getKp.lastPenerimaName = penerimaName
        //             getKp.lastFamilyId = penerimaId

        //             // NEW
        //             getKp.newMpBulananPeserta = getNewPerhitungan.mpBulananPeserta || 0

        //             getKp.newMpBulananAnak = getNewPerhitungan.mpBulananAnak || 0
        //             getKp.newMpBulananAnak80 = getKp.newMpBulananAnak ? getKp.newMpBulananAnak * 80 / 100 : 0
        //             getKp.newMpBulananAnak80Round = getKp.newMpBulananAnak80 ? pembulatan(getKp.newMpBulananAnak80, 2) : 0

        //             getKp.newMpBulananJadud = getNewPerhitungan.mpBulananJadud || 0
        //             getKp.newMpBulananJadud80 = getKp.newMpBulananJadud ? getKp.newMpBulananJadud * 80 / 100 : 0
        //             getKp.newMpBulananJadud80Round = getKp.newMpBulananJadud80 ? pembulatan(getKp.newMpBulananJadud80, 2) : 0

        //             getKp.newMpSekaligusPeserta = getNewPerhitungan.mpSekaligusPeserta || 0

        //             getKp.newMpSekaligusAnak = getNewPerhitungan.mpSekaligusAnak || 0
        //             getKp.newMpSekaligusAnak20 = getKp.newMpSekaligusAnak ? getKp.newMpSekaligusAnak * 20 / 100 : 0
        //             getKp.newMpSekaligusAnak20Round = getKp.newMpSekaligusAnak20 ? pembulatan(getKp.newMpSekaligusAnak20, 2) : 0

        //             getKp.newMpSekaligusJadud = getNewPerhitungan.mpSekaligusJadud || 0
        //             getKp.newMpSekaligusJadud20 = getKp.newMpSekaligusJadud ? getKp.newMpSekaligusJadud * 20 / 100 : 0
        //             getKp.newMpSekaligusJadud20Round = getKp.newMpSekaligusJadud20 ? pembulatan(getKp.newMpSekaligusJadud20, 2) : 0

        //             getKp.newMpBulanan = getNewPerhitungan.mpBulanan || 0
        //             getKp.newMpBulananRound = getKp.newMpBulanan ? pembulatan(getKp.newMpBulanan, 2) : 0
        //             getKp.newMpSekaligus = getNewPerhitungan.mpSekaligus || 0
        //             getKp.newMpSekaligusRound = getKp.newMpSekaligus ? pembulatan(getKp.newMpSekaligus, 2) : 0
        //             getKp.newMpBulanan80 = getKp.newMpBulanan * 80 / 100 || 0
        //             getKp.newMpBulanan80Round = getKp.newMpBulanan80 ? pembulatan(getKp.newMpBulanan80, 2) : 0
        //             getKp.newMpSekaligus20 = getKp.newMpSekaligus * 20 / 100 || 0
        //             getKp.newMpSekaligus20Round = getKp.newMpSekaligus20 ? pembulatan(getKp.newMpSekaligus20, 2) : 0
        //         }
        //         // var loadAPIPerhitunganPengalihan = `peserta-pensiuns/pengalihanMp/${this.pesertaId}/${data.productId}/${formatDate(data.tanggalPengalihan)}`
        //         // var getPerhitunganPengalihan = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPerhitunganPengalihan, 'GET')

        //         this.formPengalihanRef.current.instance.itemOption('pengalihan.tanggalMeninggal', 'readOnly', true)
        //         this.formPengalihanRef.current.instance.itemOption('pengalihan.productId', 'readOnly', true)
        //         this.formPengalihanRef.current.instance.itemOption('pengalihan.tanggalPengalihan', 'readOnly', true)
        //         this.formPengalihanRef.current.instance.updateData(getKp)

        //         var text = 'Data berhasil di proses!'
        //         var type = 'success'

        //         notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        //         return false
        //     } catch (e) {
        //         console.log(e)
        //         notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        //     }
        // } else {
        //     var message = 'Isi data peserta,tanggal pengalihan, dan produk terlebih dahulu!'
        //     let result = await alert(message, 'Informasi')
        //     if (result) {
        //     }
        //     return false
        // }
        // console.log(getPerhitunganPengalihan)
    }

    onSelectionChanged = (e) => {
        this.pesertaId = e.selectedRowKeys[0]
        this.dropDownBoxRef.current.instance.option('value', e.selectedRowKeys)

        if (e.selectedRowKeys.length > 0) {
            this.setState({
                isOpened: false
            })
        }

        if (e.currentDeselectedRowKeys.length > 0) {
            this.setState({
                dataPengalihan: {}
            })
        }
    }

    onDropdownOptionChanged = (e) => {
        if (e.name === 'opened') {
            this.setState({
                isOpened: e.value,
            });
        }
    }

    renderDatagrid = (e) => {
        return (
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI={'view-pesertas?statusPeserta.in=4'}
                insertAPI='pesertas'
                updateAPI='pesertas'
                deleteAPI='pesertas'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengalihan Mp"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations={true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengalihan Mp'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged={this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengalihan MP'}
                minWidth={1000}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.props.actionType == 'view' ? this.PopupViewToolbarItem : this.PopupToolbarItem}
            >
                <ScrollView height='100%' width='100%'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Peserta</label>
                                    <div className="col-sm-10">
                                        <DropDownBox
                                            ref={this.dropDownBoxRef}
                                            valueExpr="id"
                                            opened={this.state.isOpened}
                                            deferRendering={false}
                                            displayExpr={(item) => {
                                                return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                            }}
                                            showClearButton={true}
                                            dataSource={pesertaById(this.props.store)}
                                            onValueChanged={this.onValueChanged}
                                            contentRender={this.renderDatagrid}
                                            onOptionChanged={this.onDropdownOptionChanged}
                                            visible={this.props.actionType === 'add' ? true : false}
                                        />
                                        <Form
                                            colCount={1}
                                            ref={this.formPesertaRef}
                                            id={'formDataPeserta'}
                                            formData={this.state.dataPeserta}
                                            items={this.dataPeserta}
                                            labelLocation="left"
                                            visible={this.props.actionType === 'add' ? false : true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    ref={this.formPengalihanRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataPengalihan}
                                    items={this.dataPengalihan}
                                    labelLocation="left"
                                    readOnly={this.props.actionType == 'view' ? true : false}
                                />
                            </div>
                            <div className="col-md-12 text-center">
                                <font className="font-weight-bold" style={{ fontSize: '15px' }}>Data Dokumen</font>
                                <hr />
                            </div>
                            <div className="col-md-6">
                                <Box
                                    direction={'row'}
                                    width={'100%'}
                                >
                                    <Item ratio={1}>
                                        <FileUploader
                                            accept={'image/png, image/jpeg, image/png'}
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e)}
                                            labelText={this.state.dataPengalihan.fileKtpPenerimaMpName || 'Lampiran Foto KTP Suami/Istri untuk Status Kawin atau Photo Surat Cerai'}
                                            showFileList={false}
                                            name={'fileKtpPenerimaMp'}
                                        />
                                        <img src={imageSource(this.state.dataPengalihan.fileKtpPenerimaMpContentType, this.state.dataPengalihan.fileKtpPenerimaMp)} width="20%" />
                                    </Item>
                                </Box>
                                <Box
                                    direction={'row'}
                                    width={'100%'}
                                >
                                    <Item ratio={1}>
                                        <FileUploader
                                            accept={'image/png, image/jpeg, image/png'}
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e)}
                                            labelText={this.state.dataPengalihan.fileKkPenerimaMpName || 'Lampiran Foto Kartu Keluarga'}
                                            showFileList={false}
                                            name={'fileKkPenerimaMp'}
                                        />
                                        <img src={imageSource(this.state.dataPengalihan.fileKkPenerimaMpContentType, this.state.dataPengalihan.fileKkPenerimaMp)} width="20%" />
                                    </Item>
                                </Box>
                            </div>
                            <div className="col-md-6">
                                <Box
                                    direction={'row'}
                                    width={'100%'}
                                >
                                    <Item ratio={1}>
                                        <FileUploader
                                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e)}
                                            labelText={this.state.dataPengalihan.marriedFileName || 'Lampiran Foto KTP Suami/Istri untuk Status Kawin atau Photo Surat Cerai'}
                                            showFileList={false}
                                            name={'marriedFile'}
                                        />
                                    </Item>
                                </Box>
                                <Box
                                    direction={'row'}
                                    width={'100%'}
                                >
                                    <Item ratio={1}>
                                        <FileUploader
                                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e)}
                                            labelText={this.state.dataPengalihan.fileKkName || 'Lampiran Foto Kartu Keluarga'}
                                            showFileList={false}
                                            name={'fileKk'}
                                        />
                                    </Item>
                                </Box>
                            </div>
                        </div>

                    </div>
                </ScrollView>
            </Popup>
            </React.Fragment>
        );
    }
}


export default ModalRegistrasiUpdatePengalihanMp