import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import FormModalPengajuanProyek from 'components/pages/modal/sdm-umum/umum/proyek/pengajuanProyek';
import { asetKategoriSdm, assetSdm, subAsetKategoriSdm } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'

class PengajuanProyek extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalPengajuanProyek(e)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.sendPengajuan(e.row.data);
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField: 'pengajuanNo',
                caption: 'No Pengajuan'
            },
            {
                dataField: 'projectType',
                caption: 'Jenis Proyek',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Pembangunan',
                        },
                        {
                            id: 2,
                            value: 'Renovasi',
                        },
                        {
                            id: 3,
                            value: 'Pengadaan',
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'projectCode',
                caption: 'Kode Proyek'
            },
            {
                dataField: 'projectName',
                caption: 'Nama Proyek'
            },
            {
                dataField: 'aset',
                caption: 'Aset',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'renovAssetId',
                        caption: 'No Registrasi Aset',
                        lookup: {
                            dataSource: assetSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'registrasiNo'
                        }
                    },
                    {
                        dataField: 'renovAssetId',
                        caption: 'Nama Aset',
                        lookup: {
                            dataSource: assetSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'assetName'
                        }
                    },
                    {
                        dataField: 'groupAset',
                        caption: 'Group Aset'
                    },
                    {
                        dataField: 'assetCategoryId',
                        caption: 'Jenis Aset',
                        lookup: {
                            dataSource: asetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName'
                        }
                    },
                    {
                        dataField: 'assetCategorySubId',
                        caption: 'Tipe Aset',
                        lookup: {
                            dataSource: subAsetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categorySubName'
                        }
                    },
                    {
                        dataField: 'asetSpesifikasi',
                        caption: 'Spesifikasi Aset'
                    },
                ]
            },
            {
                dataField: 'biaya',
                caption: 'Biaya',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'projectNominal',
                        caption: 'Nilai Proyek',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'amortisasiPeriode',
                        caption: 'Bulan Amr'
                    },
                ]
            },
            {
                dataField: 'estimasiWaktu',
                caption: 'Estimasi Waktu (Bulan)',
                calculateCellValue: (e) => {
                    var tglAwal = e.tanggalAwalPekerjaan
                    var tglAkhir = e.tanggalAkhirPekerjaan
                    tglAwal = new Date(tglAwal).getTime()
                    var timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000
                    var daysCount = Math.floor(timeDiff / (86400))
                    return Math.floor(daysCount / 30)
                }
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: "Pengajuan"
                        },
                        {
                            id: 2,
                            value: "Kontrak"
                        },
                        {
                            id: 3,
                            value: "Ditutup"
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            }
        ]

        this.showModalPengajuanProyekRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengajuanProyek = (e) => {
        if (e.row) {
            this.showModalPengajuanProyekRef.current.retrieveData(e.row.data)
        }
        this.showModalPengajuanProyekRef.current.show()
    }

    sendPengajuan = async(data) => {
        var submitData = {
            statusId: 2,
            id: data.id,
        }
        if(submitData){
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects', 'PUT', {values: submitData, key: data.id}, data)
                this.forceRefresh()
                notify({ message: 'Data berhasil terkirim!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPengajuanProyek(e)
                    }
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Proyek</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='projects/getByStatusId/1'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Proyek"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Proyek'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPengajuanProyek
                    ref={this.showModalPengajuanProyekRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PengajuanProyek