import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react';
import { asetKategoriSdm, oustandingPembelian, programSdm, sdmSupplier, sdmUnit, statusAktifNonAktif, subAsetKategoriSdm } from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog';
import { FileUploader, ScrollView } from 'devextreme-react';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import { showLoading } from "redux/actions/loading";
class ModalUmumPembelianPemesanan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {},
            popupVisible: false,
            isOpened: false,
        }

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.datagridPembayaranRef = React.createRef()
        this.datagridNoPemesananRef = React.createRef()
        this.dropDownBoxRef = React.createRef()
        this.dropDownBoxValidationRef = React.createRef()

        this.ppn = 0
        this.ppnValue = 0;
        this.type = '';
        this.typeValue = '';
        this.deleteData = [];
        this.editData = [];
        this.dataType = [];
        this.assetPembelianId = 0;

        this.formPemesanan = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: "status",
                        label: {
                            text: "Status"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'S',
                                    value: 'Selesai'
                                },
                                {
                                    id: 'P',
                                    value: 'Dalam Proses'
                                },
                                {
                                    id: 'O',
                                    value: 'Open'
                                },
                                {
                                    id: 'C',
                                    value: 'Dibatalkan'
                                }
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value'
                        },
                        visible: false
                    },
                    {
                        dataField: "programId",
                        label: {
                            text: "Program",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programSdm(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        validationRules: [
                            {
                                type: 'required',
                            },
                        ]
                    },
                    {
                        dataField: "type",
                        label: {
                            text: "Type"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'B',
                                    value: 'Pembelian'
                                },
                                {
                                    id: 'C',
                                    value: 'Batal Pembelian'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value',
                            onValueChanged: async (e) => {
                                let typeId = e.value;
                                this.formRef.current.instance.beginUpdate()
                                this.formRef.current.instance.itemOption('tanggalPembelian', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('supplierId', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('harga', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('amount', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('ppn', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('biayaTransaksi', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('total', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('informasiSupplier', 'visible', e.value === 'B')
                                this.formRef.current.instance.itemOption('includePPn', 'visible', e.value === 'B')
                                this.formRef.current.instance.endUpdate()

                                this.formRef.current.instance.updateData({
                                    tanggalPembelian: null,
                                    supplierId: null,
                                    harga: null,
                                    jumlah: null,
                                    amount: null,
                                    ppn: null,
                                    biayaTransaksi: null,
                                    total: null,
                                    supplier: {
                                        kodeSupplier: null,
                                        namaSupplier: null,
                                        alamat: null,
                                        contactPerson: null,
                                        email: null,
                                        noTelp: null,
                                        maxPembelianTahun: null,
                                        totalPembelian: null,
                                        statusSupplier: null
                                    }
                                })

                                this.datagridPembayaranRef.current.forceRefresh(true)

                                if (e.value === 'B') {
                                    document.getElementById('list-metode-pembayaran').classList.remove('d-none')
                                    document.getElementById('upload-invoice').classList.remove('d-none')
                                } else {
                                    document.getElementById('list-metode-pembayaran').classList.add('d-none')
                                    document.getElementById('upload-invoice').classList.add('d-none')
                                }

                                if (typeId === 'B') {
                                    let data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'suppliers?size=99999', 'GET');

                                    this.formRef.current.instance.getEditor('supplierId').option('dataSource', data);
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                            },
                        ]
                    },
                    {
                        dataField: 'tanggalPembelian',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pemesanan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                            },
                        ],
                        visible: false
                    },
                    {
                        dataField: 'supplierId',
                        label: {
                            alignment: 'left',
                            text: 'Supplier'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: sdmSupplier(this.props.store),
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: (item) => {
                                return item && item.kodeSupplier + ' - ' + item.namaSupplier
                            }, // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formRef.current.instance.updateData({
                                        supplier: e.selectedItem
                                    })
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                            },
                        ],
                        visible: false
                    },
                    {
                        dataField: "harga",
                        label: {
                            text: "Harga per Unit",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData({
                                    harga: e.value,
                                    amount: e.value * (this.state.DataMaster.jumlah || 0),
                                    ppn: this.state.DataMaster.includePpn ? 0 : (e.value * (this.state.DataMaster.jumlah || 0)) * this.ppn,
                                    total: (e.value * (this.state.DataMaster.jumlah || 0)) + ((e.value * (this.state.DataMaster.jumlah || 0)) * this.ppn) + (this.state.DataMaster.biayaTransaksi || 0)
                                })
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                            },
                            {
                                type: 'range',
                                min: 1,
                                message: 'must be more than 0'
                            },
                        ],
                        visible: false
                    },
                    {
                        dataField: "jumlahSisa",
                        label: {
                            text: "Sisa yang Dapat Dibeli",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        },
                    },
                    {
                        dataField: "jumlah",
                        label: {
                            text: "Jumlah",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData({
                                    jumlah: e.value,
                                    amount: e.value * (this.state.DataMaster.harga || 0),
                                    ppn: this.state.DataMaster.includePpn ? 0 : (e.value * (this.state.DataMaster.harga || 0)) * this.ppn,
                                    total: (e.value * (this.state.DataMaster.harga || 0)) + ((e.value * (this.state.DataMaster.harga || 0)) * this.ppn) + (this.state.DataMaster.biayaTransaksi || 0)
                                })
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                            },
                            {
                                type: 'range',
                                min: 1,
                                message: 'must be more than 0'
                            },
                            {
                                type: 'custom',
                                validationCallback: (e) => {
                                    if (this.state.DataMaster.id) {
                                        return true
                                    }
                                    return e.value <= this.state.DataMaster.jumlahSisa
                                },
                                message: 'Exceed the quantity of the remaining purchases'
                            }
                        ],
                    },
                    {
                        dataField: "amount",
                        label: {
                            text: "Total",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        dataField: "includePPn",
                        label: {
                            text: "Include PPn",
                        },
                        editorType: 'dxCheckBox',
                        editorOptions: {
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData({
                                    ppn: e.value ? 0 : (this.state.DataMaster.jumlah * (this.state.DataMaster.harga || 0)) * this.ppn,
                                    total: !e.value ? this.state.DataMaster.amount + this.state.DataMaster.amount * this.ppn + this.state.DataMaster.biayaTransaksi : this.state.DataMaster.amount
                                })

                                if (e.value) {
                                    this.formRef.current.instance.itemOption('ppn', 'validationRules')
                                } else {
                                    if (e.previousValue === true) {
                                        return null;
                                    } else {
                                        this.formRef.current.instance.itemOption('ppn', 'validationRules', [
                                            {
                                                type: 'required',
                                            },
                                            {
                                                type: 'range',
                                                min: 1,
                                                message: 'must be more than 0'
                                            },
                                        ])
                                    }
                                }
                            }
                        }
                    },
                    {
                        dataField: "ppn",
                        label: {
                            text: "PPn",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            onValueChanged: (e) => {
                                if (e.value) {
                                    let ppnValue = this.ppnValue;
                                    if (this.type === 'edit' || this.type === 'view') {
                                        this.formRef.current.instance.updateData({
                                            includePPn: ppnValue <= 0 ? true : false
                                        })   
                                    }
                                }
                            },
                            format: '#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        dataField: "biayaTransaksi",
                        label: {
                            text: "Biaya Transaksi",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData({
                                    biayaTransaksi: e.value,
                                    total: (this.state.DataMaster.amount || 0) + (this.state.DataMaster.ppn || 0) + e.value
                                })
                            }
                        },
                        visible: false
                    },
                    {
                        dataField: "total",
                        label: {
                            text: "Total Bayar",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Informasi Pengajuan Pengadaan',
                items: [
                    {
                        dataField: 'pengadaan.pengajuanDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            width: '100%',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'pengadaan.pengadaanDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pengadaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: "pengadaan.namaAset",
                        label: {
                            text: "Merek",
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "pengadaan.assetKategoriId",
                        label: {
                            text: "Kategori Aset",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: '',
                            dataSource: asetKategoriSdm(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'categoryName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: "pengadaan.assetKategoriSubId",
                        label: {
                            text: "Sub Kategori Aset",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: '',
                            dataSource: subAsetKategoriSdm(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'categorySubName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: "pengadaan.unitId",
                        label: {
                            text: "Unit Kerja",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: '',
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: "pengadaan.jumlah",
                        label: {
                            text: "Jumlah Pengadaan",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: "pengadaan.hargaPerkiraan",
                        label: {
                            text: "Harga Perkiraan",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: "pengadaan.merek",
                        label: {
                            text: "Merk",
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "pengadaan.ketSpesifikasi",
                        label: {
                            text: "Spesifikasi",
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {
                            height: '100px',
                            readOnly: true
                        },
                    },
                ]
            },
            {
                name: 'informasiSupplier',
                itemType: 'group',
                caption: 'Informasi Supplier',
                items: [
                    {
                        dataField: 'supplier.kodeSupplier',
                        label: {
                            alignment: 'left',
                            text: 'Kode Supplier'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.namaSupplier',
                        label: {
                            alignment: 'left',
                            text: 'Nama Supplier'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.alamat',
                        label: {
                            alignment: 'left',
                            text: 'Alamat'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.contactPerson',
                        label: {
                            alignment: 'left',
                            text: 'Contact Person'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.email',
                        label: {
                            alignment: 'left',
                            text: 'Email'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "supplier.noTelp",
                        label: {
                            text: "No Telp",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        dataField: "supplier.maxPembelianTahun",
                        label: {
                            text: "Max Pembelian / Tahun",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: "supplier.totalPembelian",
                        label: {
                            text: "Total Pembelian Tahun Berjalan",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: "supplier.statusSupplier",
                        label: {
                            text: "Status Supplier",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: '',
                            dataSource: statusAktifNonAktif(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                ],
                visible: false
            }
        ]

        this.pembayaranDefaultValue = [
            {
                dataField: 'status',
                value: 'O'
            }
        ]

        this.pembayaranColumn = [
            {
                dataField: 'jenisPembayaran',
                caption: 'Jenis Pembayaran',
                lookup: {
                    dataSource: [
                        {
                            id: 'T',
                            value: 'Tunai'
                        },
                        {
                            id: 'K',
                            value: 'Kredit'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'T',
                            value: 'Tunai'
                        },
                        {
                            id: 'K',
                            value: 'Kredit'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                setCellValue: (newData, value, currentRowData) => {
                    newData.jenisPembayaran = value

                    const systemDate = this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE')
                    if (newData.jenisPembayaran === 'T') {
                        newData.tanggalJatuhTempo = new Date(systemDate.dateValue)
                    } else {
                        newData.tanggalJatuhTempo = null
                    }
                },
                // validationRules: [
                //     {
                //         type: 'required'
                //     },
                //     {
                //         type: 'custom',
                //         validationCallback: (e) => {
                //             if (e.value === 'K') {
                //                 return true
                //             }
                //             const dataPembayaran = this.datagridPembayaranRef.current.getDataSource()

                //             const pembayaranTunai = dataPembayaran.filter(value => value.jenisPembayaran === 'T')
                //             return pembayaranTunai.length === 0
                //         },
                //         message: 'Metode pembayaran tunai hanya bisa dipilih 1 kali'
                //     }
                // ]
            },
            {
                dataField: 'tanggalJatuhTempo',
                dataType: 'date',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Jatuh Tempo'
                },
                format: 'dd MMM yyyy',
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'nominal',
                label: {
                    alignment: 'left',
                    text: 'Nominal'
                },
                format: '#,##0',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            // {
            //     type: 'buttons',
            //     buttons: [
            //         {
            //             text: 'Edit',
            //             hint: 'Edit',
            //             icon: "edit",
            //             cssClass: "text-info",
            //             onClick: () => {

            //             }
            //         },
            //         {
            //             text: 'Delete',
            //             hint: 'Delete',
            //             icon: "trash",
            //             cssClass: "text-info",
            //             onClick: () => {

            //             }
            //         }
            //     ],
            // },
            {
                dataField: 'status',
                label: {
                    alignment: 'left',
                    text: 'Nominal'
                },
                lookup: {
                    dataSource: [
                        {
                            id: 'O',
                            value: 'Open'
                        },
                        {
                            id: 'P',
                            value: 'Proses Finance'
                        },
                        {
                            id: 'S',
                            value: 'Settled'
                        },
                        {
                            id: 'C',
                            value: 'Dibatalkan'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'O',
                            value: 'Open'
                        },
                        {
                            id: 'P',
                            value: 'Proses Finance'
                        },
                        {
                            id: 'S',
                            value: 'Settled'
                        },
                        {
                            id: 'C',
                            value: 'Dibatalkan'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                visible: false
            },
        ]

        this.summary = [
            {
                column: 'nominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominal',
                displayFormat: '{0}'
            },
        ]
    }

    componentDidMount = () => {
        const getPPNPercent = async () => {
            const response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/PPN`)

            this.ppn = response.intValue / 100
        }

        getPPNPercent()
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)

                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        // [`${fieldName}Name`]: files.name,
                        // [`${fieldName}Extension`]: formatNameAndExtention.extention,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))

            }

            fr.readAsDataURL(files)
        }
    }

    // onToolbarPreparingPemesanan = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'refresh',
    //                 onClick: (e) => {
    //                     this.datagridNoPemesananRef.current.forceRefresh(true)
    //                 }
    //             }
    //         }
    //     )
    // }

    selectNoPengadaan = async (e) => {
        if (e.selectedRowKeys.length > 0) {
            const selectedData = e.selectedRowsData.find(value => value.id === e.selectedRowKeys[0])
            const dataPengadaan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pengadaans/${e.selectedRowKeys[0]}`)

            this.setState({
                DataMaster: {
                    assetPengadaanId: e.selectedRowKeys[0],
                    pengadaan: dataPengadaan,
                    jumlahSisa: dataPengadaan.jumlah - selectedData.jumlahDibeli
                },
                isOpened: false
            })
        }
    }

    dataGridRender = (e, props) => {
        // const value = e.component.option('value');

        // const loadData = async () => {
        //     const dataSource = e.component.getDataSource()

        //     const data = await dataSource.load()

        //     this.datagridNoPemesananRef.current.forceRefresh(true);

        //     return data
        // }

        const columns = [
            {
                dataField: 'noPengadaan',
                caption: 'Nomor Pengadaan'
            },
            {
                dataField: 'pengadaanDate',
                caption: 'Tanggal Pengadaan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'namaAset',
                caption: 'Nama Aset',
            },
            {
                dataField: 'unitId',
                caption: 'Unit Kerja',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                }
            },
            {
                dataField: 'jumlah',
                caption: 'Jumlah',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'jumlahDibeli',
                caption: 'Jumlah yang Telah Dibeli',
                dataType: 'number',
                format: '#,##0'
            },
        ]

        return (
            <DevExpressDataGrid
                ref={this.datagridNoPemesananRef}
                loadAPI='view-outstanding-pembelians?size=9999'
                insertAPI='asset-transactions'
                updateAPI='asset-transactions'
                deleteAPI='asset-transactions'

                backendserver={process.env.REACT_APP_BACKEND_HRD}

                // useArraySource={true}
                // ArraySourceData={loadData}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Daftar Pemesanan Pembelian"}
                allowExportSelectedData={false}
                selection={"single"}

                showBorders={true}

                paging={true}
                defaultPageSize={10}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Daftar Pemesanan Pembelian'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={columns} // taruh konfigurasi kolom disini
                onSelectionChanged={this.selectNoPengadaan}
                onToolbarPreparing={this.onToolbarPreparingPemesanan}
                // SummaryConfiguration={this.summary}
                height="300px"

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        );
    }

    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Information')
        if (result) {
            var src = imageSource(this.state.DataMaster[`${fileName}ContentType`], this.state.DataMaster[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataMaster[`${fileName}ContentType`], this.state.DataMaster[`${fileName}`]), this.state.DataMaster[`${fileName}Name`])
    }

    submitData = async () => {
        const validateResult = this.formRef.current.instance.validate()
        const validateNoPengadaan = this.dropDownBoxValidationRef.current.instance.validate()

        if (!validateResult.isValid || !validateNoPengadaan.isValid) {
            return null
        }

        let data = { ...this.state.DataMaster }
        let dataPembayaran = []
        if (data.type === 'B') {
            dataPembayaran = this.datagridPembayaranRef.current.getDataSource()
            let totalBayar = dataPembayaran.map(value => value.nominal).reduce((a, b) => (a + b), 0)
            if (data.total !== totalBayar) {
                await alert('Total nominal metode pembayaran tidak sama dengan total bayar!', 'Error')
                return null
            }
        }

        try {
            this.props.store.dispatch(showLoading(true))

            const dataSubmit = {
                "id": 0,
                "assetPengadaanId": data.assetPengadaanId,
                "biayaTransaksi": data.biayaTransaksi,
                "harga": data.harga,
                "invoice": data.invoice,
                "invoiceContentType": data.invoiceContentType,
                "jumlah": data.jumlah,
                "noPembelian": data.noPembelian,
                "ppn": data.ppn,
                "programId": data.programId,
                "status": data.status,
                "supplierId": data.supplierId,
                "tanggalPembelian": data.tanggalPembelian,
                "total": data.total,
                "type": data.type
            }

            var responsePembelian = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pembelians/create', 'POST', {
                values: dataSubmit
            })

            for (const pembayaran of dataPembayaran) {
                delete pembayaran.id
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pembelian-bayars', 'POST', {
                    values: {
                        ...pembayaran,
                        status: 'O',
                        assetPembelianId: responsePembelian.id
                    }
                })
            }
            this.props.store.dispatch(showLoading(false))

            await alert('Data berhasil disimpan !', 'Information')
            this.hide()
            this.props.forceRefresh()
        } catch (e) {
            console.log(e)
            this.props.store.dispatch(showLoading(false))
            await alert(e, 'Error')
        }
    }

    updateData = async () => {
        const validateResult = this.formRef.current.instance.validate()
        const validateNoPengadaan = this.dropDownBoxValidationRef.current.instance.validate()

        if (!validateResult.isValid || !validateNoPengadaan.isValid) {
            return null
        }

        let data = { ...this.state.DataMaster }
        let dataPembayaran = []
        if (data.type === 'B') {
            dataPembayaran = this.datagridPembayaranRef.current.getDataSource()
            let totalBayar = dataPembayaran.map(value => value.nominal).reduce((a, b) => (a + b), 0)
            if (data.total !== totalBayar) {
                await alert('Total nominal metode pembayaran tidak sama dengan total bayar!', 'Error')
                return null
            }
        }

        try {
            this.props.store.dispatch(showLoading(true))

            const dataSubmit = {
                "id": data.id,
                "assetPengadaanId": data.assetPengadaanId,
                "biayaTransaksi": data.biayaTransaksi,
                "harga": data.harga,
                "invoice": data.invoice,
                "invoiceContentType": data.invoiceContentType,
                "jumlah": data.jumlah,
                "noPembelian": data.noPembelian,
                "ppn": data.ppn,
                "programId": data.programId,
                "status": data.status,
                "supplierId": data.supplierId,
                "tanggalPembelian": data.tanggalPembelian,
                "total": data.total,
                "type": data.type
            }

            var responsePembelian = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pembelians/create', 'POST', {
                values: dataSubmit,
            })

            this.assetPembelianId = responsePembelian.id
            
            for (const pembayaran of dataPembayaran) {
                if (typeof pembayaran.id === "string") {
                    const pembayaranId = { ...pembayaran };
                    delete pembayaranId.id
                    this.addDataAPI(pembayaranId, this.assetPembelianId);
                } else {
                    this.editDataAPI(pembayaran, this.assetPembelianId);
                }

            }

            let resultMap = this.deleteData.map(value => value);
            let index = resultMap.indexOf(40);
            if (index > -1) {
                resultMap.splice(index, 1);
            }
            for (let deleteData of resultMap) {
                await this.deleteDataAPI(deleteData)
            }
            while(this.deleteData.length > 0) {
                this.deleteData.pop();
            }
            
            this.props.store.dispatch(showLoading(false))

            await alert('Data berhasil diubah !', 'Information')
            this.hide()
            this.props.forceRefresh()
        } catch (e) {
            console.log(e)
            this.props.store.dispatch(showLoading(false))
            await alert(e, 'Error')
        }
    }

    retrieveData = async (data) => {
        this.ppnValue = data.ppn;
        var loadAPIPembelian = 'asset-pembelians/' + data.id
        var dataPembelian = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIPembelian, 'GET')

        var loadAPIPengadaan = `asset-pengadaans/${dataPembelian.assetPengadaanId}`
        var dataPengadaan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIPengadaan, 'GET')

        var dataSupplier = {}
        if (data.supplierId) {
            var loadAPISupplier = 'suppliers/' + (data.supplierId || 0)
            dataSupplier = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPISupplier, 'GET')
        }

        this.setState({
            DataMaster: {
                ...dataPembelian,
                pengadaan: dataPengadaan,
                supplier: dataSupplier
            }
        })
        this.formRef.current.instance.updateData({
            ...dataPembelian,
            pengadaan: dataPengadaan,
            supplier: dataSupplier
        })

        this.datagridPembayaranRef.current.forceRefresh(true)
    }

    show = (type) => {
        this.type = type
        this.setState({
            popupVisible: true,
        })

        if (type === 'add') {
            this.PopupRef.current.instance.option('toolbarItems', [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan",
                        onClick: () => {
                            this.submitData()
                        }
                    },
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Batal",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                },
            ])

            this.datagridPembayaranRef.current.updateEditor({
                allowAdding: true,
                allowUpdating: true,
                allowDeleting: true,
            })
            this.datagridPembayaranRef.current.DataGrid.columnOption('status', 'visible', false)
            this.dropDownBoxRef.current.instance.option('readOnly', false)
            this.formRef.current.instance.option('readOnly', false)
            this.formRef.current.instance.itemOption('status', 'visible', false)
            this.formRef.current.instance.itemOption('jumlahSisa', 'visible', true)
        } else if (type === 'edit') {
            this.PopupRef.current.instance.option('toolbarItems', [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan",
                        onClick: () => {
                            this.updateData()
                        }
                    },
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Tutup",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                },
            ])
            this.dropDownBoxRef.current.instance.option('readOnly', false)
            this.datagridPembayaranRef.current.updateEditor({
                allowAdding: true,
                allowUpdating: true,
                allowDeleting: true
            })

            this.datagridPembayaranRef.current.DataGrid.columnOption('status', 'visible', false)
            this.formRef.current.instance.option('readOnly', false)
            this.formRef.current.instance.itemOption('status', 'visible', false)
            this.formRef.current.instance.itemOption('jumlahSisa', 'visible', false)
        } else {
            this.PopupRef.current.instance.option('toolbarItems', [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Tutup",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                },
            ])
            this.dropDownBoxRef.current.instance.option('readOnly', true)
            this.datagridPembayaranRef.current.updateEditor({
                allowAdding: false,
                allowUpdating: false,
                allowDeleting: false,
            })

            this.datagridPembayaranRef.current.DataGrid.columnOption('status', 'visible', true)
            this.formRef.current.instance.option('readOnly', true)
            this.formRef.current.instance.itemOption('status', 'visible', true)
            this.formRef.current.instance.itemOption('jumlahSisa', 'visible', false)
        }

    }

    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    onDropdownOptionChanged = (e) => {
        if (e.name === 'opened') {
            this.setState({
                isOpened: e.value,
            });
        }
    }

    loadDataPembayaran = async () => {
        if (this.state.DataMaster.id) {
            var loadAPIPembelianBayar = `asset-pembelian-bayars?assetPembelianId.equals=${this.state.DataMaster.id}&size=9999`
            var dataPembelianBayar = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIPembelianBayar, 'GET')

            return dataPembelianBayar
        } else {
            return []
        }
    }

    onInitNewRow = async (e) => {
        if (this.pembayaranDefaultValue !== null && this.pembayaranDefaultValue !== undefined) {
            this.pembayaranDefaultValue.forEach((element, index) => {
                e.data[element.dataField] = element.value
            })
        }

        const dataPembayaran = this.datagridPembayaranRef.current.getDataSource()
        let totalBayar = dataPembayaran.map(value => value.nominal).reduce((a, b) => (a + b), 0)
        e.data.nominal = this.state.DataMaster.total - totalBayar
    }

    onInitUpdateRow = async (e) => {
        let assetId = e.key;
        if (assetId) {
            this.editData.push(assetId)
        }
        this.datagridPembayaranRef.current.push(this.editData)
     }

    onInitRemoveRow = async (e) => {
        let assetId = e.key;
        if (assetId) {
            this.deleteData.push(assetId)
        }
        this.datagridPembayaranRef.current.push(this.deleteData)
    }

    deleteDataAPI = async (data) => {
        try {
            let assetId = data;

            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pembelian-bayars`, 'DELETE', assetId);
        } catch (error) {
            console.log(error);
        }
    }

    addDataAPI = async (data, assetPembelianId) => {
        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pembelian-bayars', 'POST', {
                    values: {
                        ...data,
                        status: 'O',
                        assetPembelianId: assetPembelianId
                    }
        })
    }

    editDataAPI = async (data, assetPembelianId) => {
        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pembelian-bayars', 'PUT', {
            values: {
                ...data,
                status: 'O',
                assetPembelianId: assetPembelianId
            },
            key: data.id
        },
            {
                tanggalJatuhTempo: data.tanggalJatuhTempo,
                status: 'O',
                assetPembelianId: assetPembelianId
            })
    }

    displayExpr = (item) => {
        return item && item.noPengadaan + ' - ' + item.namaAset;
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Pembelian Pemesanan'}
                minWidth={700}
                minHeight={500}
                ref={this.PopupRef}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className='row mb-2'>
                                    <div className='col-12 d-flex flex-row align-items-center'>
                                        <div style={{ width: '138px' }}>
                                            <span style={{ color: 'rgba(117,117,117,.87)' }}>No Pemesanan/Nama Asset *</span>
                                        </div>
                                        <DropDownBox
                                            ref={this.dropDownBoxRef}
                                            value={this.state.DataMaster.assetPengadaanId}
                                            opened={this.state.isOpened}
                                            valueExpr="id"
                                            displayExpr={this.displayExpr}
                                            deferRendering={false}
                                            showClearButton={true}
                                            dataSource={oustandingPembelian(this.props.store)}
                                            onOptionChanged={this.onDropdownOptionChanged}
                                            contentRender={(e) => this.dataGridRender(e, this.props)}
                                            placeholder="Pilih No Pengadaan"
                                            width="calc(100% - 128px)"
                                        >
                                            <Validator
                                                ref={this.dropDownBoxValidationRef}>
                                                <RequiredRule />
                                            </Validator>
                                        </DropDownBox>
                                    </div>
                                </div>
                                <Form
                                    ref={this.formRef}
                                    colCount={3}
                                    id={'formPemesanan'}
                                    formData={this.state.DataMaster}
                                    items={this.formPemesanan}
                                    labelLocation='left'
                                />
                            </div>
                            <div className="col-md-6  d-none" id="list-metode-pembayaran">
                                <span className='font-weight-bold'>List Metode Pembayaran</span>
                                <DevExpressDataGrid
                                    ref={this.datagridPembayaranRef}

                                    loadAPI='view-pemesanan-lists?statusId.in=1'
                                    insertAPI='asset-transactions'
                                    updateAPI='asset-transactions'
                                    deleteAPI='asset-transactions'

                                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataPembayaran}

                                    // allowAdding={true}
                                    // allowDeleting={true}
                                    // allowUpdating={true}

                                    exportExcel={false}
                                    exportFileName={"Daftar Pemesanan Pembelian"}
                                    allowExportSelectedData={false}
                                    selection={"none"}

                                    editingMode={'row'}
                                    menuRightClick={false}

                                    showBorders={true}

                                    columnDefaultValue={this.pembayaranDefaultValue}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Daftar Pembayaran Pembelian Aset'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.pembayaranColumn} // taruh konfigurasi kolom disini
                                    summaryTotalItem={this.summary}
                                    height="300px"

                                    onInitNewRow={this.onInitNewRow}
                                    onRowUpdated={this.onInitUpdateRow}
                                    onRowRemoved={this.onInitRemoveRow}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <div className="col-md-6 d-none" id="upload-invoice">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={'File Invoice'}
                                    showFileList={false}
                                    name={'invoice'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.dokumen ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.dokumen ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalUmumPembelianPemesanan