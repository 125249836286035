import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager, currency, emitens, reksadana, rekeningMi, instrumentReksadana, productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import ModalInstrumentReksadana from './modalInstrumentReksadana';
import { inputToTaskInvestasi } from 'plugin/helper';


class FormPengajuanPelepasanReksadana extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            kajian: "",
            kajianContentType: 'text/html',
            dataPenjualan: {},
        }

        this.prevTabIndex = 0
        this.dataPenjualan = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridDetailPelepasanRef = React.createRef()
        this.modalInstrumentReksadanaRef = React.createRef()
        this.kajianPengajuanRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData('draft')
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: () => {
                        this.submitData('kirim')
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' || this.props.actionType == 'add' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                type: 'empty',
                colSpan: 2
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                }
            },
            {
                dataField: 'tanggalPenjualan',
                label: {
                    text: 'Tanggal Order'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                }
            },
            {
                dataField: 'fundManagerId',
                label: {
                    text: 'Fund Manager',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fundManagerName',
                    searchEnabled: true,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Fund Manager'
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
            },
            {
                dataField: 'instrument',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'jenisTransaksi',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
        ]

        this.detailPelepasan = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            console.log(data);
                            data.noPengajuan = this.state.dataMaster.noPengajuan
                            if(data) {
                                this.modalInstrumentReksadanaRef.current.show()
                                this.modalInstrumentReksadanaRef.current.retrieveData(data)
                                // this.modalInstrumentReksadanaRef.current.getInstrument(data)
                            }
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDetailPelepasanData(e.row.key)
                        }
                    },
                ]
            },
            {
                dataField: 'instrumentId',
                caption: 'Instrumen Code',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode',
                }
            },
            {
                dataField: 'instrumentId',
                caption: 'Instrumen Name',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName',
                }
            },
            {
                dataField: 'miId',
                caption: 'Penerbit Reksadana',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                }
            },
            {
                dataField: 'unitOrder',
                caption: 'Unit Portofolio',
                format: '#,##0.0000',
                alignment: 'right',
            },
            {
                dataField: 'nabTerakhir',
                caption: 'NAB Terakhir',
                format: '#,##0.0000',
                alignment: 'right',
            },
            {
                dataField: 'portofolioPelepasan',
                caption: 'Portofolio Pelepasan',
            },
            {
                dataField: 'unitPersen',
                caption: 'Persentase Pelepasan',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'amountVal',
                caption: 'Nominal Pelepasan (NAB Terakhir)',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'rekMiId',
                caption: 'Rekening Bayar',
                lookup: {
                    dataSource: rekeningMi(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'rekName'
                }
            },
        ]
        this.note = [
            {
                itemType: 'group',
                caption: 'Catatan',
                items: [
                    {
                        dataField: 'catatan',
                        label: {
                            alignment: 'left',
                            text: 'Catatan'
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {
                            height: '190'
                        }
                    }
                ]
            }
        ]

        this.TabDataSource = [
            {
                'id': 0,
                text: 'Instrument Pengajuan'
            },
            {
                'id': 1,
                text: 'Kajian Pengajuan'
            },
        ]

        this.toolbarItem = [
            'background', 'bold', 'color', 'italic', 'link', 'image', 'strike', 'subscript', 'superscript', 'underline', 'blockquote', 'header'
            , 'increaseIndent', 'decreaseIndent', 'orderedList', 'bulletList', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeBlock'
            , 'variable', 'separator', 'undo', 'redo', 'clear', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft',
            'insertColumnRight', 'deleteColumn'
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
        });

    }

    retrieveData = (dataMaster, dataPenjualan, kajian) => {
        if(this.props.actionType === 'add') {
            dataMaster = dataMaster || {
                noPengajuan: '<AUTO>',
                tanggalPengajuan: new Date(this.sysDate.dateValue),
                instrument: 'Reksadana',
                jenisTransaksi: 'Pelepasan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPenjualan: dataPenjualan,
            });
        } else {
            if(dataMaster) {
                let dataArr = [];
                for (let value of dataPenjualan) {
                    let dataValue = {
                        ...value,
                        unitPersen: value.amountVal / (value.unitOrder * value.nabTerakhir) * 100
                    }
                    dataArr.push(dataValue);
                }
                this.dataPenjualan = dataArr || []
                dataMaster = dataMaster || {
                    noPengajuan: '<AUTO>',
                    tanggalPengajuan: new Date(this.sysDate.dateValue),
                    instrument: 'Reksadana',
                    jenisTransaksi: 'Pelepasan'
                }
            } 

            this.setState({
                dataMaster: dataMaster,
                dataPenjualan: dataPenjualan,
            });

            this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
            this.dataGridDetailPelepasanRef.current.forceRefresh(true)
        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async (type) => {
        var dataDataGrid = this.dataGridDetailPelepasanRef.current.getDataSource()
        var kajian = btoa(this.state.kajian)
        var kajianType = this.state.kajianContentType
        var draft = type == 'draft' ? true : false
        var dataMaster = this.state.dataMaster
        var customSubmitData = {
            ...dataMaster,
            draft,
            kajian: kajian,
            kajianContentType: kajianType,
            instrumentRdList: dataDataGrid,
            requestOrderId: dataMaster.actionType == 'edit' ? dataMaster.requestId : null
        }

        if (dataDataGrid.length == 0) {
            notify({ message: 'Harap isi data pengajuan terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        } else {
            if (customSubmitData) {
                try {
                    var insertAPI = 'order-rds/pengajuan-penjualan-rd'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: customSubmitData
                    })

                    if (type == 'kirim') {
                        try {
                            await inputToTaskInvestasi(this.props.store, customSubmitData.requestOrderId, customSubmitData.orderTypeId)
                        } catch (e) {
                            console.log(e);
                            notify({ message: "Terjadi kesalahan saat input data ke task", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }

                    }
                    notify({ message: 'Pengajuan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.hide()
                    this.props.forceRefresh()
                    return response
                } catch (e) {
                    console.log(e)
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }
        }
    }

    loadDetailPelepasan = () => {
        return this.dataPenjualan
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindexPelepasanRd-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexPelepasanRd-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) => {
        this.setState({
            kajian: e.value
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Instrument Pelepasan',
                    onClick: (e) => {
                        this.showModalInstrumentPelepasan()
                    },
                },
                visible: this.props.actionType == 'view' ? false : true
            }
        )
    }

    showModalInstrumentPelepasan = () => {
        this.modalInstrumentReksadanaRef.current.show()
        this.modalInstrumentReksadanaRef.current.retrieveData({
            noPengajuan: this.state.dataMaster.noPengajuan,
            productId: this.state.dataMaster.productId,
        })
    }

    submitDetailPelepasanData = (data) => {
        if (data.id) {
            this.dataGridDetailPelepasanRef.current.push([{
                type: "update",
                data: {
                    instrumentId: data.instrumentId,
                    productId: data.productId,
                    portofolioPelepasan: data.portofolioPelepasan,
                    presentaseVal: data.presentaseVal,
                    amountVal: data.amountVal,
                    feePercent: data.feePercent,
                    unitPersen: data.unitPersen,
                    fee: data.fee,
                    kurs: data.kurs,
                    currencyId: data.currencyId,
                    rekMiId: data.rekMiId,
                    totalPelepasan: data.totalPelepasan,
                    redeemUnitType: data.redeemUnitType,
                    unitPorto: data.unitPorto,
                    unitOrder: data.unitOrder,
                    nabPerolehan: data.nabPerolehan,
                    nilaiPerolehan: data.nilaiPerolehan,
                    nabTerakhir: data.nabTerakhir,
                    miId: data.miId,
                    kpdId: data.kpdId,
                    unit: data.unit,
                },
                key: data.id
            }])
        } else {
            this.dataGridDetailPelepasanRef.current.push([{
                type: "insert",
                data: {
                    instrumentId: data.instrumentId,
                    productId: data.productId,
                    portofolioPelepasan: data.portofolioPelepasan,
                    presentaseVal: data.presentaseVal,
                    amountVal: data.amountVal,
                    feePercent: data.feePercent,
                    unitPersen: data.unitPersen,
                    fee: data.fee,
                    kurs: data.kurs,
                    currencyId: data.currencyId,
                    rekMiId: data.rekMiId,
                    totalPelepasan: data.totalPelepasan,
                    redeemUnitType: data.redeemUnitType,
                    unitPorto: data.unitPorto,
                    unitOrder: data.unitOrder,
                    nabPerolehan: data.nabPerolehan,
                    nilaiPerolehan: data.nilaiPerolehan,
                    nabTerakhir: data.nabTerakhir,
                    miId: data.miId,
                    kpdId: data.kpdId,
                    unit: data.unit,
                }
            }])
        }
    }

    deleteDetailPelepasanData = (key) => {
        this.dataGridDetailPelepasanRef.current.push([{
            type: "remove",
            key: key
        }])
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Form Usulan Pelepasan Investasi Reksadana`}
                    minWidth={'90vw'}
                    height="90vh"

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={3}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation={"left"}
                                />
                            </div>
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                            <div id="tabindexPelepasanRd-0" className={`col-md-12`} style={{ height: "calc(100vh - 380px)", overflowY: "auto" }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <DevExpressDataGrid
                                            ref={this.dataGridDetailPelepasanRef}
                                            loadAPI='brokers'
                                            insertAPI='brokers'
                                            updateAPI='brokers'
                                            deleteAPI='brokers'

                                            useArraySource={true}
                                            ArraySourceData={this.loadDetailPelepasan}

                                            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                            allowAdding={false}
                                            allowDeleting={false}
                                            allowUpdating={false}

                                            exportExcel={false}
                                            exportFileName={"Broker"}
                                            allowExportSelectedData={true}
                                            selection={"multiple"}

                                            showBorders={true}

                                            editingMode={'cell'}

                                            paging={true}
                                            defaultPageSize={10}

                                            //bagian konfigurasi popup saat insert dan edit record
                                            popupTitle={'Broker'}
                                            popupWidth={400} //masukan dalam ukuran pixel
                                            popupHeight={200} //masukkan dalam ukuran pixel

                                            popupFormLabelLocation='left' //accepted value = top, left, right
                                            popupFormMinColWidth={300} // minimum lebar kolom
                                            popupFormColCount={1} //jumlah kolom pada form

                                            //akhir bagian konfigurasi popup
                                            height={'calc(100vh - 380px)'}
                                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                            FilterRow={true} // set false untuk mematikan fitur filter

                                            ColumnConfiguration={this.detailPelepasan} // taruh konfigurasi kolom disini
                                            // SummaryConfiguration={this.summary}

                                            onToolbarPreparing={this.onToolbarPreparing}

                                            //contoh konfigurasi kolom
                                            //this.columns = [{
                                            //    dataField: 'kolom1',
                                            //    caption: 'Ini Kolom 1'
                                            //}, {
                                            //    dataField: 'kolom2',
                                            //    caption: 'Ini Kolom 2'
                                            //}]
                                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                            store={this.props.store} // jangan di edit edit
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="tabindexPelepasanRd-1" className={`col-md-12 d-none`} style={{ height: "calc(100vh - 380px)", overflowY: "auto" }}>
                                <div className="col-md-12">
                                    <HtmlEditor
                                        ref={this.kajianPengajuanRef}
                                        height="calc(100vh - 380px)"
                                        value={this.state.kajian}
                                        valueType={this.state.kajianContentType}
                                        name={'kajian'}
                                        onValueChanged={this.onValueChanged}
                                        readOnly={this.props.actionType == 'view' ? true : false}
                                    >
                                        <MediaResizing enabled={true} />
                                        <Toolbar>
                                            {
                                                this.toolbarItem.map((toolbarItem) => {
                                                    return (
                                                        <Item
                                                            formatName={toolbarItem}
                                                        />
                                                    )
                                                })
                                            }
                                        </Toolbar>
                                    </HtmlEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
                <ModalInstrumentReksadana store={this.props.store} ref={this.modalInstrumentReksadanaRef} submitDetailPelepasanData={this.submitDetailPelepasanData} type={this.props.actionType} />
            </React.Fragment>
        )
    }
}

export default FormPengajuanPelepasanReksadana
