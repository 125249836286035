import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { instrumentEtf, broker, currency, rekeningBank, managerInvestasis, instrumentsCategoryEtf, availableInstrumentEtf, instruments } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';


class FormSubInstrumentPelepasanEtf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {}
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                name: 'dataetf',
                items: [
                    {
                        dataField: 'requestNo',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            text: 'Instrument'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: [],
                            dataSource: availableInstrumentEtf(this.props.store, 1),
                            valueExpr: 'instrumentId',
                            displayExpr: (e) => {
                                return e && e.instrumentCode + ' - ' + e.instrumentName
                            },
                            openOnFieldClick: true,
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${e.value}`, 'GET')
                                var dataEfek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-instrumentId/${e.value}`, 'GET')
                                this.formMasterRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)
                                var price = dataEfek[0].avgPrice !== null ? dataEfek[0].avgPrice : 0

                                this.formDetailRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)
                                this.formDetailRef.current.instance.getEditor('instSubCategoryId').option('value', instrument.categorySubId)
                                this.formDetailRef.current.instance.getEditor('miId').option('value', instrument.managerInvestasiId)
                                this.formDetailRef.current.instance.updateData('nabTerakhir', instrument.price)
                                this.formDetailRef.current.instance.updateData('rate', instrument.rate)
                                this.formDetailRef.current.instance.updateData('isinCode', instrument.isinCode)
                                this.formDetailRef.current.instance.updateData('tanggalHargaTerakhir', instrument.priceDate)

                                // this.formDetailRef.current.instance.getEditor('nilaiPelepasanInstrument').option('value', dataEfek[0].faceValue)
                                // this.formDetailRef.current.instance.getEditor('kuantitas').option('value', dataEfek[0].quantity)
                                this.formDetailRef.current.instance.getEditor('jumlahUnit').option('value', dataEfek[0].quantity)
                                this.formDetailRef.current.instance.getEditor('nabPerolehan').option('value', price)
                                this.formDetailRef.current.instance.getEditor('nilaiPerolehan').option('value', dataEfek[0].quantity * price)
                                // this.formDetailRef.current.instance.getEditor('nilaiKeuntungan').option('value', (dataEfek[0].quantity * price) - dataEfek[0].faceValue)
                                // this.formDetailRef.current.instance.getEditor('prosentaseKeuntungan').option('value', (((dataEfek[0].quantity * price) - dataEfek[0].faceValue) / dataEfek[0].faceValue) * 100)
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose instrument"
                            }
                        ]
                    },
                    {
                        dataField: 'tanggalOrder',
                        label: {
                            text: 'Tanggal Order'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'useMethod',
                        label: {
                            text: 'Pelepasan Berdasarkan'
                        },
                        editorType: "dxRadioGroup",
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['Unit', 'Nominal'],
                            onValueChanged: async (e) => {
                                var val = e.value
                                var unit = this.formMasterRef.current.instance.getEditor('amountUnit')
                                var nominal = this.formMasterRef.current.instance.getEditor('amountOrder')
                                if (val) {
                                    if (val == 'Unit') {
                                        unit.option('value', 0)
                                        nominal.option('value', 0)
                                        unit.option('readOnly', false)
                                        nominal.option('readOnly', true)
                                    } else if (val == 'Nominal') {
                                        unit.option('value', 0)
                                        nominal.option('value', 0)
                                        unit.option('readOnly', true)
                                        nominal.option('readOnly', false)
                                    }
                                }
                            }
                        },
                    },
                    {
                        dataField: 'amountUnit',
                        label: {
                            text: 'Unit'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'custom',
                                validationCallback: (e) => {
                                    var quantity = this.formDetailRef.current.instance.getEditor('jumlahUnit').option('value')
                                    if (quantity > 0) {
                                        if (e.value > quantity) {
                                            return false
                                        }
                                        return true
                                    }
                                    return true
                                },
                                message: 'Unit Pelepasan tidak boleh lebih dari yang dimiliki'
                            }
                        ]
                    },
                    {
                        dataField: 'amountOrder',
                        label: {
                            text: 'Nominal Pelepasan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'custom',
                                validationCallback: (e) => {
                                    var nilaiPerolehan = this.formDetailRef.current.instance.getEditor('nilaiPerolehan').option('value')
                                    if (nilaiPerolehan > 0) {
                                        if (e.value > nilaiPerolehan) {
                                            return false
                                        }
                                        return true
                                    }
                                    return true
                                },
                                message: 'Nilai Pelepasan tidak boleh lebih dari yang dimiliki'
                            }
                        ]
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        dataField: 'priceType',
                        label: {
                            text: 'Harga'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['FIXED', 'RANGE', 'BEST PRICE'],
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose Price Type"
                            }
                        ]
                    },
                    {
                        dataField: 'minOrdPrice',
                        label: {
                            text: 'Harga Minimum'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Minimum Price cannot empty!"
                            }
                        ]
                    },
                    {
                        dataField: 'maxOrdPrice',
                        label: {
                            text: 'Harga Maximum'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Maximum Price cannot empty!"
                            }
                        ]
                    },
                    {
                        dataField: 'brokerId',
                        label: {
                            text: 'Broker'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: broker(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'brokerName',
                            openOnFieldClick: true,
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose broker"
                            }
                        ]
                    },
                    {
                        dataField: 'market',
                        label: {
                            text: 'Pasar'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['PRIMER', 'SEKUNDER'],
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose market type"
                            }
                        ]
                    },
                    {
                        dataField: 'brokerCommission',
                        label: {
                            text: 'Fee (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var fee = e.value
                                var nom = this.formMasterRef.current.instance.getEditor('amountOrder').option('value')
                                var result = nom * fee / 100
                                this.formMasterRef.current.instance.updateData('feeNom', result)
                            }
                        },
                    },
                    {
                        dataField: 'feeNom',
                        label: {
                            text: 'Nominal Fee'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            onValueChanged: async (e) => {
                                var currData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${e.value}`, 'GET')
                                this.formMasterRef.current.instance.updateData('kurs', currData.rate)
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose currency"
                            }
                        ]
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'rekbankId',
                        label: {
                            text: 'Rekening Pencairan (Pokok/Bunga)',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.rekNo + ' - ' + item.rekName
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose disbursement account"
                            }
                        ]
                    },
                ]
            }
        ]
        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI ETF',
                items: [
                    {
                        dataField: 'miId',
                        label: {
                            text: 'Penerbit ETF'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'miName'
                        }
                    },
                    {
                        dataField: 'instSubCategoryId',
                        label: {
                            text: 'Kategori ETF'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentsCategoryEtf(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName'
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName'
                        }
                    },
                    // {
                    //     dataField: 'jenisEtf',
                    //     label: {
                    //         text: 'jenisEtf'
                    //     },
                    // },
                    {
                        dataField: 'rate',
                        label: {
                            text: 'Rating'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'isinCode',
                        label: {
                            text: 'ISIN CODE'
                        }
                    },
                    {
                        dataField: 'nabTerakhir',
                        label: {
                            text: 'NAB Terakhir'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'tanggalHargaTerakhir',
                        label: {
                            text: 'Tanggal Harga Terakhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                        }
                    },
                    // {
                    //     dataField: 'aum',
                    //     label: {
                    //         text: 'AUM'
                    //     },
                    // },
                    // {
                    //     dataField: 'unitBeredar',
                    //     label: {
                    //         text: 'Unit Beredar'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja1Month',
                    //     label: {
                    //         text: 'Kinerja 1 Bulan'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja3Month',
                    //     label: {
                    //         text: 'Kinerja 3 Bulan'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja1Year',
                    //     label: {
                    //         text: 'Kinerja 1 Tahun'
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja2Year',
                    //     label: {
                    //         text: 'Kinerja 2 Tahun'
                    //     },
                    // },
                ]
            },
            // {
            //     itemType: 'group',
            //     caption: 'INFORMASI HARGA TERAKHIR',
            //     items: [
            //         {
            //             dataField: 'nabPeluncuran',
            //             label: {
            //                 text: 'NAB Peluncuran'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'nabTertinggi',
            //             label: {
            //                 text: 'NAB Tertinggi'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'nabTerakhir',
            //             label: {
            //                 text: 'NAB Terakhir'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: '#,##0.00'
            //             }
            //         },
            //     ]
            // },
            {
                itemType: 'group',
                caption: 'INFORMASI PORTOFOLIO',
                items: [
                    // {
                    //     dataField: 'nilaiPelepasanInstrument',
                    //     label: {
                    //         text: 'Nilai Pelepasan Instrument'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0.00'
                    //     }
                    // },
                    // {
                    //     dataField: 'kuantitas',
                    //     label: {
                    //         text: 'Kuantitas'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0.0000'
                    //     }
                    // },
                    {
                        dataField: 'jumlahUnit',
                        label: {
                            text: 'Jumlah Unit'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'nabPerolehan',
                        label: {
                            text: 'NAB Perolehan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000'
                        }
                    },
                    {
                        dataField: 'nilaiPerolehan',
                        label: {
                            text: 'Nilai Perolehan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    // {
                    //     dataField: 'nilaiKeuntungan',
                    //     label: {
                    //         text: 'Nilai Keuntungan'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0.00'
                    //     }
                    // },
                    // {
                    //     dataField: 'prosentaseKeuntungan',
                    //     label: {
                    //         text: 'Prosentase Keuntungan'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0'
                    //     }
                    // },
                    // {
                    //     dataField: 'komposisiInstrument',
                    //     label: {
                    //         text: 'Komposisi Instrument'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0.00'
                    //     }
                    // },
                    // {
                    //     dataField: 'komposisiEmiten',
                    //     label: {
                    //         text: 'Komposisi Emiten'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0.00'
                    //     }
                    // },
                ]
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show () {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data) => {
        if(data){
            var instrument = [], dataEfek = [], price = 0
            if (data.instrumentId) {
                instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`, 'GET')
                dataEfek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-instrumentId/${data.instrumentId}`, 'GET')
                price = dataEfek[0].avgPrice !== null ? dataEfek[0].avgPrice : 0
            }
            this.setState({
                data: {
                    id: data.id,
                    requestNo: data.requestNo,
                    instrumentId: data.instrumentId,
                    miId: data.miId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nabTerakhir: dataEfek.nabTerakhir,
                    amountOrder: data.amountOrder,
                    feeNom: data.feeNom,
                    brokerId: data.brokerId,
                    market: data.market,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    rekbankId: data.rekbankId,
                    brokerCommission: data.brokerCommission,
                    priceType: data.priceType,
                    instSubCategoryId: data.instSubCategoryId,
                    tanggalOrder: data.tanggalOrder,
                    productId: data.productId,
                    amountUnit: data.amountUnit,
                    useMethod: data.useMethod,
                },
                dataDetail: {
                    currencyId: instrument.currencyId,
                    instSubCategoryId: instrument.categorySubId,
                    miId: instrument.managerInvestasiId,
                    nabTerakhir: instrument.price,
                    // nilaiPelepasanInstrument: dataEfek[0] == undefined ? null : dataEfek[0].faceValue,
                    jumlahUnit: dataEfek[0] == undefined ? null : dataEfek[0].quantity,
                    nabPerolehan: price,
                    nilaiPerolehan: dataEfek[0] == undefined ? null : dataEfek[0].quantity * price,
                    // nilaiKeuntungan: dataEfek[0] == undefined ? null : (dataEfek[0].quantity * price) - dataEfek[0].faceValue,
                    // prosentaseKeuntungan: dataEfek[0] == undefined ? null : ((dataEfek[0].quantity * price) - dataEfek[0].faceValue) / dataEfek[0].faceValue * 100,
                },
            });
            // var selectInstrumentId = this.formMasterRef.current.instance.getEditor('instrumentId')
            // selectInstrumentId.option('dataSource', availableInstrumentEtf(this.props.store, this.state.data.productId))

        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    submitData = () => {
        var data = this.state.data
        var detail = this.state.dataDetail
        this.props.submitDetailPelepasanData({
            id: data.id,
            requestNo: data.requestNo,
            instrumentId: data.instrumentId,
            miId: detail.miId,
            currencyId: data.currencyId,
            kurs: data.kurs,
            nabTerakhir: detail.nabTerakhir,
            amountOrder: data.amountOrder,
            feeNom: data.feeNom,
            brokerId: data.brokerId,
            market: data.market,
            minOrdPrice: data.minOrdPrice,
            maxOrdPrice: data.maxOrdPrice,
            rekbankId: data.rekbankId,
            brokerCommission: data.brokerCommission,
            priceType: data.priceType.replace(" ", "_"),
            instSubCategoryId: detail.instSubCategoryId,
            tanggalOrder: data.tanggalOrder,
            productId: data.productId,
            amountUnit: data.amountUnit,
            useMethod: data.useMethod,
            kpdId: 1
        })

        this.hide()
    }

    getInstrument = async (data) => {

    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Pelepasan Investasi ETF'}
                width={'90vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form
                                            colCount={1}
                                            id={'formMaster'}
                                            ref={this.formMasterRef}
                                            formData={this.state.data}
                                            items={this.formItem}
                                            scrollingEnabled={false}
                                            labelLocation={"left"}
                                            readOnly={this.props.type == 'view' ? true : false}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Form
                                            colCount={1}
                                            id={'formMaster1'}
                                            ref={this.formDetailRef}
                                            formData={this.state.dataDetail}
                                            items={this.formDetailItem}
                                            scrollingEnabled={false}
                                            labelLocation={"left"}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormSubInstrumentPelepasanEtf