import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalTunjanganLain from 'components/pages/modal/sdm-umum/pengaturan/tunjangan-lain/form'
import { dasarNilai } from 'dataSource/lookup'
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';

class TunjanganLain extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalTunjanganLain(e)
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick : () => {
                                            this.delete(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ],
            },
            {
                dataField:"kodeTunjangan",
                caption:"Kode"
            },
            {
                dataField:"namaTunjangan",
                caption:"Tunjangan Lain"
            },
            {
                dataField:"periodeTunjanganPertahun",
                caption:"Periode Tunjangan (Tahun)"
            },
            {
                dataField:"dasarNilaiTunjangan",
                caption:"Dasar NIlai Tunjangan",
                lookup:{
                    dataSource: dasarNilai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dasarNilai', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"dasarNilaiTunjangan",
                caption:"Dasar NIlai Tunjangan",
                alignment:"center",
                columns:[
                    {
                        dataField:"presentasiGajiPokok",
                        caption:"Presentasi Gaji Pokok",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00 %'
                    },
                    {
                        dataField:"presentasiTakeHomePay",
                        caption:"Presentasi Take Home Pay",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00 %'
                    },
                    {
                        dataField:"nominalTunjangan",
                        caption:"Nominal",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                ]
            },
            {
                dataField:"status",
                caption:"Status",
                dataType:'boolean'
            },
        ]

        this.showModalTunjanganLainRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.selectedRowKeys = []
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalTunjanganLain = (e) => {
        if (e.row) {
            this.showModalTunjanganLainRef.current.retrieveData(e.row.data)
        }
        this.showModalTunjanganLainRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'trash',
                    text: 'hapus',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin ingin menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: () => {
                                        this.selectedDelete(e)
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ]
                        })
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus', 
                    onClick: (e) => {
                        this.showModalTunjanganLain(e)
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    delete = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'tunjangan-lains', 'DELETE', data.id)
            this.forceRefresh()
            notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }

    selectedDelete = async () => {
        var errorMessage = []
        if (this.selectedRowKeys.length === 0) {
            notify({message: "Silahkan pilih yang akan di hapus",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',3000);
            return null
        }
        
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'tunjangan-lains', 'DELETE', this.selectedRowKeys[i])
            } catch (e){
                console.log(e);
                errorMessage.push('Sebagian data tidak bisa dihapus, karena sudah digunakan')
            }

        }
        
        if (errorMessage.length > 0) {
            notify({message: errorMessage.join('\n'),width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
            this.forceRefresh()
            return
        }

        this.forceRefresh()
        notify({message: 'Data Berhasil Dihapus',width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }

    loadData = async () => {
        let response = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `tunjangan-lains?size=9999`,
            'GET'
        )

        if (response.length > 0) {
            var dataResult = []
            for (var data of response) {
                data.periodeTunjanganPertahun = data.periodeTunjanganPertahun.toString();
                data.nominalTunjanganText = data.nominalTunjangan === null ? '' : Math.floor(data.nominalTunjangan);
                dataResult.push(data)
            }

            return dataResult
        } else {
            return []
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Tunjangan Lain</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='tunjangan-lains'
                    insertAPI='tunjangan-lains'
                    updateAPI='tunjangan-lains'
                    deleteAPI='tunjangan-lains' 

                    remoteOperations = {false}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Tunjangan Lain"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tunjangan Lain'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    
                    onSelectionChanged = {this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalTunjanganLain 
                    ref={this.showModalTunjanganLainRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default TunjanganLain