import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import notify from 'devextreme/ui/notify'

class ModalProsesTransaksiDitunda extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Submit',
                    onClick: this.submitData
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataMaster = [
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama'
                },
            },
            {
                itemType: 'empty'
            },
            {
                dataField: 'phdpLama',
                label: {
                    alignment: 'left',
                    text: 'PHDP Lama'
                },
                editorOptions: {
                    displayFormat: '#,##0.00',
                },
            },
            {
                dataField: 'phdpBaru',
                label: {
                    alignment: 'left',
                    text: 'PHDP Baru'
                },
                editorOptions: {
                    displayFormat: '#,##0.00',
                },
            },
            {
                dataField: 'totalIuranLama',
                label: {
                    alignment: 'left',
                    text: 'Total Iuran Lama'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    displayFormat: '#,##0.00',
                },
            },
            {
                dataField: 'totalIuranBaru',
                label: {
                    alignment: 'left',
                    text: 'Total Iuran Baru'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    displayFormat: '#,##0.00',
                },
            },
            
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        });
    }

    submitData = async() => {
        this.props.forceRefresh()
        this.hide()
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    retrieveData = async(viewData, data = null) => {   
        this.setState({
            dataMaster: data,
            viewData : viewData
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Proses Transaksi Ditunda'} 
                width={600}
                height={400}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <Form
                    colCount={1}
                    id={'formDataMaster'}
                    formData={this.state.dataMaster}
                    items={this.dataMaster}
                    scrollingEnabled={true}
                    labelLocation = 'left'
                />
            </Popup>
        );
    }
}


export default ModalProsesTransaksiDitunda