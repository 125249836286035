import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from 'devextreme/ui/notify';
import {  banks, entity, programs, sdmUnit, tipeTransaksiSdmUmum, unit } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ScrollView from 'devextreme-react/scroll-view';
import PPUVerifikasiSdmActionDetailModal from './actionDetail';
import PPUVerifikasiSdmActionDokumenModal from './actionDokumen';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { download, imageSource } from 'plugin/helper';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { alert } from 'devextreme/ui/dialog';

class PPUVerifikasiSdmModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            newData:true
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.readOnly = ''
        this.PopupRef = React.createRef()
        this.modalSkKaryawanRef = React.createRef()
        this.DataGridDetailRef = React.createRef()
        this.DataGridDocumentRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.detailBiayaRef = React.createRef()
        this.documentDetailRef = React.createRef()
        
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Kirim',
                    onClick: () => {
                        this.props.showModalCatatan(this.state.dataMaster , 4)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        
        this.dataMaster = [
            {
                dataField:'programId',
                label:{
                    alignment:'left',
                    text:'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'noPengajuan',
                label : {
                    alignment: 'left',
                    text: 'No Pengajuan'
                },
                // editorOptions : {
                //     readOnly : true
                // }
            },
            {
                dataField: 'tanggalPengajuan',
                label : {
                    alignment: 'left',
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }
            },
            {
                dataField: 'requestBy',
                label: {
                    alignment: 'left',
                    text: 'Diajukan Oleh'
                },
                editorOptions: {
                    // value : this.props.store.getState().sessionUser,
                    readOnly : true
                }
            },
            {
                dataField: 'unitId',
                label: {
                    alignment: 'left',
                    text: 'Unit Kerja'
                },
                editorType : 'dxSelectBox',
                editorOptions:{
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'entitiId',
                label: {
                    text: 'Pembayaran Kepada',
                    alignment: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: entity(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'entityName', // provides display values
                    searchEnabled: true,
                    deferRendering: false
                }
            },
            {
                dataField: 'keterangan',
                label: {
                    alignment: 'left',
                    text: 'Catatan Pengajuan'
                },
                editorType: 'dxTextArea',
                editorOptions:{
                    width: '100%'
                }
            }
        ]

        this.detailColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    }
                ],
            },
            {
                dataField:"tujuanRekNo",
                caption:"Nomor Rekening",
                dataType: 'number',
                alignment : 'right',
            },
            {
                dataField:"pengajuanNominal",
                caption:"Nominal Pengajuan",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField: 'transactionTypeId',
                caption: 'Tipe Transaksi',
                lookup:{
                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'transactionTypeName' 
                }
            },
            {
                dataField:"ppn",
                caption:"PPN",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"pph",
                caption:"PPH",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"biayaLain",
                caption:"Biaya Lain",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"potongan",
                caption:"Potongan",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"pengajuanNominalNett",
                caption:"Nominal Bersih",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"cash",
                caption:"Cash",
                dataType:'boolean'
            },
            {
                dataField:"transfer",
                caption:"Transfer",
                dataType:'boolean'
            },
            {
                dataField:"cashOut",
                caption:"Cash Out",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"dpAmount",
                caption:"Uang Muka",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"tujuanRekName",
                caption:"Nama Rekening",
            },
            {
                dataField:"tujuanRekBankId",
                caption:"Bank",
                lookup:{
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'initialName'
                }
            },
            {
                dataField:"tujuanRekCabang",
                caption:"Cabang",
            },
        ]

        this.documentColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Download',
                        hint : 'Download',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.downloadFile(e.row.data)
                        }
                    },
                    {
                        text : 'Preview',
                        hint : 'Preview',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.previewFile(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'dokumenName',
                caption : 'Nama Dokumen',
            },
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Detail Pembayaran'
            },
            {
                id: 1,
                text: 'Dokumen'
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabModalVSindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabModalVSindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    downloadFile = (data) => {
        download(imageSource(data.dokumenFileContentType,data.dokumenFile),data.dokumenName)
    }

    previewFile = async (data) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            var src = imageSource(data.dokumenFileContentType,data.dokumenFile)
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    newData = (newData) => {
        this.setState({
          newData : newData
        })
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            newData:true
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = async(data) => {
        var loadAPI = `pembayaran-umums/${data.id}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPI, 'GET')
        this.setState({
            dataMaster: getData,
        })

        this.DataGridDetailRef.current.forceRefresh(true)
        this.DataGridDocumentRef.current.forceRefresh(true)
    }

    showModalDetail = (data) => {
        this.detailBiayaRef.current.show()
        if(data){
            this.detailBiayaRef.current.retrieveData(data)
        }
    }

    showModalDokumen = (data) => {
        this.documentDetailRef.current.show()
        if(data){
            this.documentDetailRef.current.retrieveData(data)
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Verifikasi SDMU'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="mb-5">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.dataMaster}
                            items={this.dataMaster}
                            labelLocation = 'left'
                            readOnly={true}
                        />
                    </div>
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                        <div id='tabModalVSindex-0' className={' mt-2 text-center' } style={{height: '100%'}}>
                            <DevExpressDataGrid 
                                ref = {this.DataGridDetailRef}
                                loadAPI = {`pembayaran-umum-detils/getByPembayaranUmumId/${this.state.dataMaster.id || 0}?size=9999`}
                                insertAPI = 'pembayaran-details'
                                updateAPI = 'pembayaran-details'
                                deleteAPI = 'pembayaran-details'

                                backendserver = {process.env.REACT_APP_BACKEND_HRD}

                                // useArraySource={true}
                                // ArraySourceData={[{id:1}]}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                showBorders = {true}

                                paging = {true}
                                defaultPageSize = {10}
                                
                                editingMode={'popup'}
                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle = {'Data Detail Biaya'}
                                popupWidth = {500} //masukan dalam ukuran pixel
                                popupHeight = {400} //masukkan dalam ukuran pixel

                                popupFormLabelLocation = 'left' //accepted value = top, left, right
                                popupFormMinColWidth = {300} // minimum lebar kolom
                                popupFormColCount = {1} //jumlah kolom pada form
                                height={400}
                                //akhir bagian konfigurasi popup

                                ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

                                FilterRow = {true} // set false untuk mematikan fitur filter
                                
                                ColumnConfiguration={this.detailColumn} // taruh konfigurasi kolom disini
                                onToolbarPreparing = {this.onToolbarPreparingDetail}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                                
                                store = {this.props.store} // jangan di edit edit
                            />
                        </div>
                        <div id='tabModalVSindex-1' className={'d-none mt-2 text-center' }>
                            <DevExpressDataGrid 
                                ref = {this.DataGridDocumentRef}
                                loadAPI = {`pembayaran-umum-dokumen/getByPembayaranUmumId/${this.state.dataMaster.id || 0}?size=9999`}
                                insertAPI = 'documents'
                                updateAPI = 'documents'
                                deleteAPI = 'documents'

                                backendserver = {process.env.REACT_APP_BACKEND_HRD}

                                // useArraySource={true}
                                // ArraySourceData={[{id:1}]}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                showBorders = {true}

                                paging = {true}
                                defaultPageSize = {10}
                                
                                editingMode={'popup'}
                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle = {'Data Document'}
                                popupWidth = {500} //masukan dalam ukuran pixel
                                popupHeight = {400} //masukkan dalam ukuran pixel

                                popupFormLabelLocation = 'left' //accepted value = top, left, right
                                popupFormMinColWidth = {300} // minimum lebar kolom
                                popupFormColCount = {1} //jumlah kolom pada form
                                height={400}
                                //akhir bagian konfigurasi popup

                                ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

                                FilterRow = {true} // set false untuk mematikan fitur filter
                                
                                ColumnConfiguration={this.documentColumn} // taruh konfigurasi kolom disini
                                onToolbarPreparing = {this.onToolbarPreparingDocument}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                                
                                store = {this.props.store} // jangan di edit edit
                            />
                        </div>
                    
                </div>
                </ScrollView>
                <PPUVerifikasiSdmActionDetailModal
                    ref = {this.detailBiayaRef}
                    store = {this.props.store}
                />
                <PPUVerifikasiSdmActionDokumenModal
                    ref = {this.documentDetailRef}
                    store = {this.props.store}
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default PPUVerifikasiSdmModal