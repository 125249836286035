import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import { productDefault } from 'dataSource/lookup'
import Excel from 'exceljs/dist/exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { formatDefaultFullDate, formatNumber, formatNumberAum, formatNumberB, PrintElem } from 'plugin/helper'
import reportRequest from 'plugin/reportRequest'
import { showLoading } from 'redux/actions/loading'

class LaporanPerPihak extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            dataMaster: {},
            filterData: {
                productId: 1,
                reportDate: this.sysDate.dateValue
            }
        }

        this.columns = [
            {
                dataField: 'no',
                caption: "No.",
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'emiten_name',
                caption: 'Pihak',
            },
            {
                dataField: 'sbn',
                caption: 'SBN',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'eba',
                caption: 'Efek Beragun Aset',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'deposito',
                caption: 'Deposito',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'saham',
                caption: 'Saham',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'obligasi',
                caption: 'Obligasi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'sukuk',
                caption: 'Sukuk',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'reksadana',
                caption: 'Unit Penyertaan Reksadana',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'penyertaan_langsung',
                caption: 'Penyertaan Langsung',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'tanah_bangunan',
                caption: 'Tanah & Bangunan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'total',
                caption: 'Total Investasi Per Pihak',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'total_rupiah',
                        caption: '(Rupiah)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'total_rupiah_percent',
                        caption: '(%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                ]
            },
            {
                dataField: 'accrued_deposito',
                caption: 'Accrued Deposito',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'accrued_eba',
                caption: 'Accrued EBA',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'accrued_sbn',
                caption: 'Accrued Sbn',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'accrued_bond',
                caption: 'Accrued Bond',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'accrued_sukuk',
                caption: 'Accrued Sukuk',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'spi_saham',
                caption: 'SPI Saham',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'spi_deposito',
                caption: 'SPI Deposito',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'spi_eba',
                caption: 'SPI EBA',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'spi_sbn',
                caption: 'SPI Sbn',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'spi_bond',
                caption: 'SPI Bond',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'spi_sukuk',
                caption: 'SPI Sukuk',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'spi_properti',
                caption: 'SPI Properti',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'emiten_name'
            },
            {
                name: 'sbn',
                column: 'sbn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'eba',
                column: 'eba',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'deposito',
                column: 'deposito',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'saham',
                column: 'saham',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'obligasi',
                column: 'obligasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'sukuk',
                column: 'sukuk',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'reksadana',
                column: 'reksadana',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'penyertaan_langsung',
                column: 'penyertaan_langsung',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'tanah_bangunan',
                column: 'tanah_bangunan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'total_rupiah',
                column: 'total_rupiah',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'total_rupiah_percent',
                column: 'total_rupiah_percent',
                summaryType: 'sum',
                valueFormat: '#',
                displayFormat: '{0}'
            },
            {
                name: 'accrued_deposito',
                column: 'Accrued Deposito',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'accrued_eba',
                column: 'Accrued EBA',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'accrued_sbn',
                column: 'Accrued Sbn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'accrued_bond',
                column: 'Accrued Bond',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'accrued_sukuk',
                column: 'Accrued Sukuk',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Spi Saham',
                column: 'spi_saham',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Spi Deposito',
                column: 'spi_deposito',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Spi Eba',
                column: 'spi_eba',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Spi Sbn',
                column: 'spi_sbn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Spi Bond',
                column: 'spi_bond',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Spi Sukuk',
                column: 'spi_sukuk',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Spi Properti',
                column: 'spi_properti',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.filterItem = [
            // {
            //     itemType: 'group',
            //     items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                this.productCode = e.productCode
                                return e && e.productCode + ' - ' + e.productName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'reportDate',
                        label: {
                            text: 'Tanggal',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            openOnFieldClick: true
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.filterData()
                            },
                        },
                        horizontalAlignment: "left"
                    },
            //     ]
            // },
        ]

        this.productCode = ''

        this.loadDataResult = []
        this.selectedRowsData = []
        this.dataGridRef = React.createRef()

    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    text: 'Print PDF',
                    onClick: (e) => {
                        this.printPdf()
                    },
                }
            }
        )
    }

    printPdf = async () => {
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-report-per-pihak-inv/${this.state.filterData.productId}/${this.state.filterData.reportDate}`, 'GET')

        var dataDetails = []
        var arraySbn = []
        var arrayEba = []
        var arrayDepostito = []
        var arraySaham = []
        var arrayObligasi = []
        var arraySukuk = []
        var arrayReksadana = []
        var arrayPenempatan = []
        var arrayTanah = []
        var arrayRupiah = []
        var arrayPercent = []
        // var arrayAccruedDeposito = []
        // var arrayAccruedEba = []
        // var arrayAccruedSbn = []
        // var arrayAccruedBond = []
        // var arrayAccruedSukuk = []
        // var arraySpiSaham = []
        // var arraySpiDeposito = []
        // var arraySpiEba = []
        // var arraySpiSbn = []
        // var arraySpiBond = []
        // var arraySpiSukuk = []
        // var arraySpiProperti = []

        for (let data of this.selectedRowsData) {
            arraySbn.push(data.sbn)
            arrayEba.push(data.eba)
            arrayDepostito.push(data.deposito)
            arraySaham.push(data.saham)
            arrayObligasi.push(data.obligasi)
            arraySukuk.push(data.sukuk)
            arrayReksadana.push(data.reksadana)
            arrayPenempatan.push(data.penyertaan_langsung)
            arrayTanah.push(data.tanah_bangunan)
            arrayRupiah.push(data.total_rupiah)
            arrayPercent.push(data.total_rupiah_percent)
            // arrayAccruedDeposito.push(data.accrued_deposito)
            // arrayAccruedEba.push(data.accrued_eba)
            // arrayAccruedSbn.push(data.accrued_sbn)
            // arrayAccruedBond.push(data.accrued_bond)
            // arrayAccruedSukuk.push(data.accrued_sukuk)
            // arraySpiSaham.push(data.spi_saham)
            // arraySpiDeposito.push(data.spi_deposito)
            // arraySpiEba.push(data.spi_eba)
            // arraySpiSbn.push(data.spi_sbn)
            // arraySpiBond.push(data.spi_bond)
            // arraySpiSukuk.push(data.spi_sukuk)
            // arraySpiProperti.push(data.spi_properti)

            var dataResult = {
                emiten_name: data.emiten_name,
                sbn: data.sbn !== null ? formatNumber(data.sbn) : 0,
                eba: data.eba !== null ? formatNumber(data.eba) : 0,
                depostito: data.deposito !== null ? formatNumber(data.deposito) : 0,
                saham: data.saham !== null ? formatNumber(data.saham) : 0,
                obligasi: data.obligasi !== null ? formatNumber(data.obligasi) : 0,
                sukuk: data.sukuk !== null ? formatNumber(data.sukuk) : 0,
                reksadana: data.reksadana !== null ? formatNumber(data.reksadana) : 0,
                penempatan: data.penyertaan_langsung !== null ? formatNumber(data.penyertaan_langsung) : 0,
                tanahbangunan: data.tanah_bangunan !== null ? formatNumber(data.tanah_bangunan) : 0,
                rupiah: data.total_rupiah !== null ? formatNumber(data.total_rupiah) : 0,
                percent: data.total_rupiah_percent !== null ? data.total_rupiah_percent : 0,
                // accruedDeposito: data.accrued_deposito ? formatNumber(data.accrued_deposito) : 0,
                // accruedEba: data.accrued_eba ? formatNumber(data.accrued_eba) : 0,
                // accruedSbn: data.accrued_sbn ? formatNumber(data.accrued_sbn) : 0,
                // accruedBond: data.accrued_bond ? formatNumber(data.accrued_bond) : 0,
                // accruedSukuk: data.accrued_sukuk ? formatNumber(data.accrued_deposito) : 0,
                // spiSaham: data.spi_saham ? formatNumber(data.spi_saham) : 0,
                // spiDeposito: data.spi_deposito ? formatNumber(data.spi_deposito) : 0,
                // spiEba: data.spi_eba ? formatNumber(data.spi_eba) : 0,
                // spiSbn: data.spi_sbn ? formatNumber(data.spi_saham) : 0,
                // spiBond: data.spi_bond ? formatNumber(data.spi_bond) : 0,
                // spiSukuk: data.spi_sukuk ? formatNumber(data.spi_sukuk) : 0,
                // spiProperti: data.spi_properti ? formatNumber(data.spi_properti) : 0,
            }
            dataDetails.push(dataResult)
        }

        let totalSbn = arraySbn.reduce((a, b) => a + b, 0)
        let totalEba = arrayEba.reduce((a, b) => a + b, 0)
        let totalDepostito = arrayDepostito.reduce((a, b) => a + b, 0)
        let totalSaham = arraySaham.reduce((a, b) => a + b, 0)
        let totalObligasi = arrayObligasi.reduce((a, b) => a + b, 0)
        let totalSukuk = arraySukuk.reduce((a, b) => a + b, 0)
        let totalReksadana = arrayReksadana.reduce((a, b) => a + b, 0)
        let totalPenempatan = arrayPenempatan.reduce((a, b) => a + b, 0)
        let totalTanah = arrayTanah.reduce((a, b) => a + b, 0)
        let totalRupiah = arrayRupiah.reduce((a, b) => a + b, 0)
        let totalPercent = arrayPercent.reduce((a, b) => a + b, 0)
        // let totalAccDeposito = arrayAccruedDeposito.reduce((a, b) => a + b, 0)
        // let totalAccEba = arrayAccruedEba.reduce((a, b) => a + b, 0)
        // let totalAccSbn = arrayAccruedSbn.reduce((a, b) => a + b, 0)
        // let totalAccBond = arrayAccruedBond.reduce((a, b) => a + b, 0)
        // let totalAccSukuk = arrayAccruedSukuk.reduce((a, b) => a + b, 0)
        // let totalSpiSaham = arraySpiSaham.reduce((a, b) => a + b, 0)
        // let totalSpiDeposito = arraySpiDeposito.reduce((a, b) => a + b, 0)
        // let totalSpiEba = arraySpiEba.reduce((a, b) => a + b, 0)
        // let totalSpiSbn = arraySpiSbn.reduce((a, b) => a + b, 0)
        // let totalSpiBond = arraySpiBond.reduce((a, b) => a + b, 0)
        // let totalSpiSukuk = arraySpiSukuk.reduce((a, b) => a + b, 0)
        // let totalSpiProperti = arraySpiProperti.reduce((a, b) => a + b, 0)

        var data = {
            template: {
                'shortid': 'ft5BIdD',
                'recipe': 'html'
            },
            data: {
                "productCode": this.productCode,
                "reportDate": this.state.filterData.reportDate,
                "dataDetails": dataDetails,
                "totalSbn": formatNumber(totalSbn),
                "totalEba": formatNumber(totalEba),
                "totalDepostito": formatNumber(totalDepostito),
                "totalSaham": formatNumber(totalSaham),
                "totalObligasi": formatNumber(totalObligasi),
                "totalSukuk": formatNumber(totalSukuk),
                "totalReksadana": formatNumber(totalReksadana),
                "totalPenempatan": formatNumber(totalPenempatan),
                "totalTanah": formatNumber(totalTanah),
                "totalRupiah": formatNumber(totalRupiah),
                "totalPercent": formatNumberB(totalPercent),
                // "totalAccDeposito": formatNumber(totalAccDeposito),
                // "totalAccEba": formatNumber(totalAccEba),
                // "totalAccSbn": formatNumber(totalAccSbn),
                // "totalAccBond": formatNumber(totalAccBond),
                // "totalAccSukuk": formatNumber(totalAccSukuk),
                // "totalSpiSaham": formatNumber(totalSpiSaham),
                // "totalSpiDeposito": formatNumber(totalSpiDeposito),
                // "totalSpiEba": formatNumber(totalSpiEba),
                // "totalSpiSbn": formatNumber(totalSpiSbn),
                // "totalSpiBond": formatNumber(totalSpiBond),
                // "totalSpiSukuk": formatNumber(totalSpiSukuk),
                // "totalSpiProperti": formatNumber(totalSpiProperti),
            },
            options: {
                reportName: "Report per pihak.pdf"
            }
        }
        var print = await reportRequest(this.props.store, data)
        PrintElem(print.data)
        return print
        // reportFile(data, false, "Report per pihak.pdf")
    }

    onExporting = (e) => {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Main Sheet');
        let numbers = [];
        exportDataGrid({
            component: e.component,
            worksheet,
            topLeftCell: { row: 8, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'data' || gridCell.rowType === 'totalFooter') {
                    if (gridCell.column.dataType == 'number') {
                        if (excelCell.value === null) {
                            excelCell.value = null;
                        } else {
                            if (gridCell.column.dataField !== 'no') {
                                excelCell.value = parseFloat(gridCell.value, 10);
                                excelCell.numFmt = '#,##0.00';
                                if (gridCell.column.dataField === 'total_rupiah_percent' && gridCell.rowType === 'totalFooter') {
                                    excelCell.numFmt = '#';
                                }
                            } else {
                                numbers.push(gridCell.value);
                            } 
                        }
                        // excelCell.value = parseFloat(gridCell.value, 10);
                        // excelCell.numFmt = '#,##0.00'
                    }
                }
            },
        }).then((cellRange) => {
            // header
            const headerRow = worksheet.getRow(2);
            headerRow.height = 15;
            worksheet.mergeCells(2, 1, 2, 13);

            headerRow.getCell(1).value = 'DANA PENSIUN PEGADAIAN';
            headerRow.getCell(1).font = { name: 'Calibri', size: 14, bold: true };
            headerRow.getCell(1).alignment = { horizontal: 'center' };

            const headerRow2 = worksheet.getRow(3);
            headerRow2.height = 15;
            worksheet.mergeCells(3, 1, 3, 13);

            headerRow2.getCell(1).value = 'PROGRAM PENSIUN MANFAAT PASTI';
            headerRow2.getCell(1).font = { name: 'Calibri', size: 14, bold: true };
            headerRow2.getCell(1).alignment = { horizontal: 'center' };

            const headerRow3 = worksheet.getRow(4);
            headerRow3.height = 15;
            worksheet.mergeCells(4, 1, 4, 13);

            headerRow3.getCell(1).value = 'DAFTAR PORTOFOLIO INVESTASI - BATASAN SATU PIHAK';
            headerRow3.getCell(1).font = { name: 'Calibri', size: 14, bold: true };
            headerRow3.getCell(1).alignment = { horizontal: 'center' };

            const headerRow4 = worksheet.getRow(5);
            headerRow4.height = 15;
            worksheet.mergeCells(5, 1, 5, 13);

            headerRow4.getCell(1).value = `PER ${formatDefaultFullDate(new Date(this.state.filterData.reportDate))}`;
            headerRow4.getCell(1).font = { name: 'Calibri', size: 14, bold: true };
            headerRow4.getCell(1).alignment = { horizontal: 'center' };

            numbers.sort((a, b) => {
                return a - b;
            })

            numbers.forEach((number, index) => {
                worksheet.getCell(index + 10, 1).value = number; 
            })

            // numbers = this.bubbleSort(numbers);
            // numbers.forEach((number, index) => {
            //     worksheet.getCell(index + 2, 1).value = number; 
            // });

            // footer
            // const footerRowIndex = cellRange.to.row + 2;
            // const footerRow = worksheet.getRow(footerRowIndex);
            // worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 13);

            // footerRow.getCell(1).value = 'www.wikipedia.org';
            // footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
            // footerRow.getCell(1).alignment = { horizontal: 'right' };
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Portofolio per pihak.xlsx');
            });
        })
        e.cancel = true
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    filterData = async () => {
        this.props.store.dispatch(showLoading(true))
        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-report-per-pihak-inv/${this.state.filterData.productId}/${this.state.filterData.reportDate}`, 'GET')
        let data = []
        let counter = 1;

        for (let value of response) {
            let dataValue = {
                ...value,
                no: counter++
            }
            data.push(dataValue)
        }

        data.sort((a, b) => a.no - b.no);

        this.loadDataResult = data
        this.forceRefresh()
        this.props.store.dispatch(showLoading(false))
    }

    loadData = async () => {
        return this.loadDataResult
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Portofolio Investasi - Batasan Satu Pihak</h2>
                {/* <div className="row"> */}
                    {/* <div className="col-md-7"> */}
                        <Form
                            colCount={4}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    {/* </div> */}
                {/* </div> */}
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`view-komposisi-detils?productId.in=${this.state.filterData.productId}&size=99999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Portofolio per pihak"}
                    allowExportSelectedData={true}
                    selection={'multiple'}
                    onExporting={this.onExporting}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio per pihak'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanPerPihak