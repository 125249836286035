import React, { Component } from 'react'
import { solvaBilitas, tipeSolvabilitas } from 'dataSource/lookup'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import ArrayStore from 'devextreme/data/array_store';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import { formatDate } from 'plugin/helper';
import notify from 'devextreme/ui/notify';

class ModalSolvabilitas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedSolvabilitasId: 2,
            formData: {
                solvabilitasId: null
            },
            // solvabilitasDataSource: []
        }

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.formData = [
            {
                dataField:"tanggal",
                label:{
                    text:"Tanggal"
                },
                editorType:"dxDateBox",
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick:true,
                    useMaskBehavior:true
                }
            },
            {
                dataField:"solvabilitasId",
                label:{
                    text:"Tipe Solvabilitas"
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: tipeSolvabilitas(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'solvabilitasTypeName', // provides display values
                    onValueChanged: async (e) => {
                        if (e.value) {
                            this.setState({
                                // selectedSolvabilitasId: e.value,
                                formData: {
                                    ...this.state.formData,
                                    solvabilitasId: e.value
                                }
                            });

                            let typeId = this.state.formData.solvabilitasId;
                            
                            let solvaBilitas = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `solvabilitas/type/${typeId}`, 'GET');
                            
                            let data = new ArrayStore({
                                data: solvaBilitas,
                                key: 'id'
                            })

                            if (solvaBilitas) {
                                this.formRef.current.instance.getEditor('solvabilitasTypeId').option('dataSource', data);
                            }
                        }
                    }
                },
            },
            {
                dataField:"solvabilitasTypeId",
                label:{
                    text:"Nama Solvabilitas"
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id', 
                    displayExpr: 'solvabilitasName'
                }
            },
            {
                dataField: 'nominal',
                label:{
                    text:"Nominal"
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
        ]

        // this.columns = [
        //     {
        //         dataField: 'solvabilitasName',
        //         caption: 'Nama Solvabilitas',
        //         lookup: {
        //             // dataSource: solvaBilitas(this.props.store, this.state.formData.solvabilitasId),
        //             dataSource: this.state.solvabilitasDataSource,
        //             valueExpr: 'id',
        //             displayExpr: 'solvabilitasName', // provides di splay values
        //         },
        //         editorOptions: {
        //             // dataSource: solvaBilitas(this.props.store, this.state.formData.solvabilitasId),
        //             dataSource: this.state.solvabilitasDataSource,
        //             // dataSource: [],
        //             valueExpr: 'id',
        //             displayExpr: 'solvabilitasName', // provides display values
        //             searchEnabled: true,
        //             wrapItemText: true,
        //             // onValueChanged: (e) => {
        //             //     console.log(e)
        //             // }
        //         },
        //         // width: 350
        //     },
        //     {
        //         dataField: 'nominal',
        //         caption: 'Nominal',
        //         dataType: 'number',
        //         alignment: 'right',
        //         format: '#,##0.00',
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //         },
        //         allowEditing: true
        //     },
        // ]

        // this.solvaBilitasData = []
        // this.dataGridRef = React.createRef();
        this.formRef = React.createRef();
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            formData:{}
        })
    }

    loadData = () => {
        return this.solvaBilitasData
    }

    submitData = async () => {
        try {
            let getSolvabilitas = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `solvabilitas/type/${this.state.formData.solvabilitasId}`, 'GET');
            let findSolvabilitasId = getSolvabilitas.find((value) => value.id === this.state.formData.solvabilitasTypeId);

            let dataValue = {
                ...this.state.formData,
                tanggal: formatDate(this.state.formData.tanggal),
                amountJan: this.state.formData.nominal,
                solvabilitasId: findSolvabilitasId.id,
                solvabilitasTypeId: this.state.formData.solvabilitasId
            }
            
            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `rasio-solvabilitas`, 'POST', {
                values: dataValue  
            })
    
            if (response) {
                notify({ message: 'Data Berhasil Disimpan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);

                this.hide();
                this.props.forceRefresh()
            }
        } catch (error) {
            notify({ message: 'Tidak bisa menambahkan data, data sudah ada!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center' } }, 'error', 600);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Input Rasio Solvabilitas'}
                width={500}
                height={300}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        formData={this.state.formData}
                        items={this.formData}
                        cssClass="mt-3"
                        labelLocation='left'
                        // readOnly={this.readOnly}
                    />

                    {/* <div className="row">
                        <div className="col-md-12 text-center">
                            <h6 className="mt-3 mb-0"></h6>
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                menuRightClick={false}

                                loadAPI=''
                                insertAPI=''
                                updateAPI=''
                                deleteAPI=''

                                backendserver={process.env.REACT_APP_BACKEND_ACC}

                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={true}
                                allowDeleting={true}
                                allowUpdating={true}

                                editingMode="row"

                                exportExcel={false}
                                exportFileName={"Rasio Solvabilitas"}
                                allowExportSelectedData={true}
                                selection={"none"}

                                showBorders={true}

                                paging={false}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Jurnal Manual Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={300} //masukkan dalam ukuran pixel

                                height={'calc(100vh - 660px)'}

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                // FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                // summaryTotalItem={this.sumJurnalInfo}

                                onRowInserted={this.onRowInserted}
                                onRowUpdated={this.onRowUpdated}
                                onRowRemoved={this.onRowRemoved}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div> */}
            </Popup>
        )
    }

}

export default ModalSolvabilitas