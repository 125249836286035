import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Form, Popup, FileUploader,ScrollView } from 'devextreme-react'
import Box, { Item } from 'devextreme-react/box';
import { formatDate, formatUploadFileData, imageSource } from "plugin/helper";
import { karyawan, periodeTunjangan, programs, tunjanganHrd } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';

class FormModalPembayaranTunjanganMed extends Component {
    constructor(props) {
        super(props)
        this.persenATunjanganMedicalPR = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'P-MDCL-PR') : null
        this.persenATunjanganMedical = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'P-MDCL') : null

        this.state = {
            DataMaster: {
                tipeTunjangan:33
            },
            popupVisible: false,
            uploadedData: [],
            DataDocument: {}
        }
        
        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.formMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'pengajuanNo',
                        label: {
                            text: 'No Pengajuan.',
                            alignment: 'left'
                        },
                        editorOptions: {
                            readOnly:true
                        },
                        // visible: this.props.from == 'pengajuan' ? true : false,
                    },
                    
                    {
                        dataField: "tanggalPengajuan",
                        label: {
                            text: "Tanggal Pengajuan",
                            alignment: 'left',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program',
                            alignment: 'left'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            }
                        },
                        // visible: this.props.from == 'pengajuan' ? true : false,
                    },
                    {
                        dataField: "karyawanId",
                        label: {
                            text: "Karyawan",
                            alignment: 'left'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.nik + ' - ' + e.fullName
                            },
                            searchEnabled: true,
                            onSelectionChanged: async(e) => {
                                if (e.selectedItem) {
                                    this.statusKepegawaianId = e.selectedItem.statusKepegawaianId
                                    var tanggalPengajuan = this.formRef.current.instance.getEditor('tanggalPengajuan').option('value')
                                    var month = tanggalPengajuan.getMonth() + 1
                                    var year = tanggalPengajuan.getFullYear()
                                    // try {
                                        var getDetailTunjangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `get-tunjangan-karyawan-medical-tunai/${e.selectedItem.id}/${month}/${year}`, 'GET')
                                        var DataMaster = {...this.state.DataMaster}
                                        DataMaster.dasar_nilai = getDetailTunjangan.dasar_nilai
                                        DataMaster.jum_telah_disetujui = getDetailTunjangan.jum_telah_disetujui
                                        DataMaster.nominal_tunjangan = getDetailTunjangan.nominal_tunjangan
                                        DataMaster.periode = getDetailTunjangan.periode
                                        DataMaster.persen_takehomepay = getDetailTunjangan.persen_takehomepay
                                        DataMaster.tanggal_terakhir_pengajuan = getDetailTunjangan.tanggal_terakhir_pengajuan
                                        DataMaster.tunjangan_name = getDetailTunjangan.tunjangan_name

                                        this.formRef.current.instance.updateData(DataMaster)
                                    // } catch (e) {
                                    //     console.log(e)
                                    //     // notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                    //     await alert(`Data karyawan tidak terserdia! Silahkan pilih karyawan lain`, e)
                                    // }
                                }
                            },
                        }
                    },
                    {
                        dataField: "statusKepegawaianId",
                        label: {
                            visible: false
                        },
                        editorType: '',
                    },
                    {
                        dataField: "tipeTunjangan",
                        label: {
                            text: "Tipe Tunjangan",
                            alignment: 'left',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tunjanganHrd(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'tunjanganName',
                            searchEnabled: true,
                            readOnly:true
                        }
                    },
                    {
                        dataField: "tanggalPembayaran",
                        label: {
                            text: "Tanggal Pembayaran",
                            alignment: 'left',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: "nominalPengajuan", 
                        label: {
                            text: "Nominal Pengajuan",
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged:(e) => {
                                if (e.value) {
                                    var DataMaster = {...this.state.DataMaster}
                                    switch (this.statusKepegawaianId) {
                                        case 1:
                                            DataMaster.nominalDisetujui = e.value * this.persenATunjanganMedicalPR.intValue / 100
                                            break;
                                        case 2:
                                            DataMaster.nominalDisetujui = e.value * this.persenATunjanganMedicalPR.intValue / 100
                                            break;
                                        case 3:
                                            DataMaster.nominalDisetujui = e.value * this.persenATunjanganMedical.intValue / 100
                                            break;
                                    
                                        default:
                                            DataMaster.nominalDisetujui = e.value
                                            break;
                                    }

                                    this.formRef.current.instance.updateData(DataMaster)
                                }
                            }
                        },
                    },
                    {
                        dataField: "nominalDisetujui",
                        label: {
                            text: "Nominal Disetujui",
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly:true
                        },
                        // visible: this.props.from == 'pengajuan' ? true : false,
                    },
                    {
                        dataField: "periodeTunjangan",
                        label: {
                            text: "Periode Tunjangan",
                            alignment: 'left',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: periodeTunjangan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'periodeName',
                            searchEnabled: true,
                        },
                        // visible: this.props.from == 'pengajuan' ? true : false,
                    },
                    {
                        dataField: "keteranganPenyakit",
                        label: {
                            text: "Keterangan Penyakit",
                            alignment: 'left',
                        },
                        editorType: 'dxTextArea'
                    },
                    {
                        dataField: "catatan",
                        label: {
                            text: "Catatan",
                            alignment: 'left',
                        },
                        editorType: 'dxTextArea'
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'INFORMASI NILAI TUNJANGAN',
                        items: [

                            {
                                dataField: "tunjangan_name",
                                label: {
                                    text: "Nama Tunjangan"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },

                            {
                                dataField: "dasar_nilai",
                                label: {
                                    text: "Dasar Nilai"
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "persen_takehomepay",
                                label: {
                                    text: "Presentase Take Home Pay"
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                }
                            },

                            {
                                dataField: "jum_telah_disetujui",
                                label: {
                                    text: "Nominal Telah di Setujui"
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "nominal_tunjangan",
                                label: {
                                    text: "Nominal Tunjangan"
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "periode",
                                label: {
                                    text: "Periode Tunjangan",
                                    alignment: 'left',
                                },
                                // editorType: 'dxSelectBox',
                                // editorOptions: {
                                //     dataSource: periodeTunjangan(this.props.store),
                                //     valueExpr: 'id',
                                //     displayExpr: 'periodeName',
                                //     searchEnabled: true,
                                //     readOnly: true
                                // },
                            },

                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'INFORMASI PENGAJUAN',
                        items: [
                            {
                                dataField: "tanggal_terakhir_pengajuan",
                                label: {
                                    text: "Tanggal Pengajuan Terakhir"
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    readOnly: true,
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            // {
                            //     dataField: "akumulasiPengajuanTerakhir",
                            //     label: {
                            //         text: "Akumulasi Pengajuan Terakhir"
                            //     },
                            //     editorOptions: {
                            //         readOnly: true
                            //     }
                            // },
                        ]
                    },
                ]
            }
        ]

        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.karMedicalId = 0
        this.statusKepegawaianId = 0
    }

    submitData = async () => {
        try {
            var loadApi = 'karyawan-medical-tunai-pengajuans'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
                        
            DataMaster.tanggalPembayaran = formatDate(DataMaster.tanggalPembayaran)
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)

            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: DataMaster,
            })

            if (response) {
                this.props.forceRefresh()
                this.hide()
                notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
            
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    updateData = async () => {
        try {
            var loadApi = 'edit-karyawan-medical-tunai'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.karyawanMedicalTunaiId = this.karMedicalId
            DataMaster.tanggalPembayaran = formatDate(DataMaster.tanggalPembayaran)
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: DataMaster,
            })

            if (response) {
                this.props.forceRefresh()
                this.hide()
                notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
            
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            var DataDocument = { ...this.state.DataDocument }

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)

                DataDocument[`dokumen${fieldName}`] = formattedFileData.base64data
                DataDocument[`dokumen${fieldName}ContentType`] = formattedFileData.mime
                DataDocument[`dokumen${fieldName}Name`] = files.name
                this.setState({
                    DataDocument: DataDocument
                })
            }

            fr.readAsDataURL(files)
        }
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`dokumen${fileName}ContentType`], state[`dokumen${fileName}`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    retrieveData = async (data) => {
        this.karMedicalId = data.id
        var DataMaster = {...this.state.DataMaster}
        var DataDocument = {...this.state.DataDocument}
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-medical-tunais/${data.id}`, 'GET')

        DataMaster.pengajuanNo = response.pengajuanNo
        DataMaster.programId = response.programId
        DataMaster.karyawanId = response.karyawanId
        DataMaster.tipeTunjangan = 33
        DataMaster.periodeTunjangan = response.periodeTunjanganId
        DataMaster.tanggalPembayaran = new Date(data.tanggalPembayaran)
        DataMaster.tanggalPengajuan = new Date(data.tanggalPengajuan)
        DataMaster.nominalPengajuan = data.nominalPengajuan
        DataMaster.nominalDisetujui = data.nominalDisetujui
        DataMaster.keteranganPenyakit = response.keterangan
        DataMaster.catatan = response.catatanMedic


        DataDocument.dokumenFileKetDokter = response.fileKeteranganDokter
        DataDocument.dokumenFileKetDokterContentType = response.fileKeteranganDokterContentType
        DataDocument.dokumenFileKetDokterName = response.dokumenFileKetDokterName
        DataDocument.dokumenFileKwitansi = response.fileKwitansi
        DataDocument.dokumenFileKwitansiContentType = response.fileKwitansiContentType
        DataDocument.dokumenFileKwitansiName = response.dokumenFileKwitansiName
        DataDocument.dokumenFileResepDokter = response.fileResepDokter
        DataDocument.dokumenFileResepDokterContentType = response.fileResepDokterContentType
        DataDocument.dokumenFileResepDokterName = response.dokumenFileResepDokterName
        this.setState({
            DataMaster:DataMaster,
            DataDocument:DataDocument
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: "Generate Semua Karyawan",
                    onClick: (e) => {
                        console.log(e)
                    },
                }
            },
        )
    }

    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans?size=99999`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        var filteredData = getKaryawan.filter(val => val.programId == programId)
        let dataSource = new ArrayStore({
            data: filteredData,
            key: 'id'
        })
        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }

    getNoPengajuan = async () => {
        let loadAPI = `generate-no-automatic/KaryawanMedicalTunai/pengajuanNo/MEDICAL`
        var getNoPengajuan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST',{
            values:{}
        })
        if (getNoPengajuan) {
            this.formRef.current.instance.updateData({pengajuanNo:getNoPengajuan})
        }
    }
    
    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.statusKepegawaianId = 0
        this.karMedicalId = 0
        this.setState({
            DataMaster: {
                tipeTunjangan:33
            },
            popupVisible: false,
            uploadedData: [],
            DataDocument: {}
        })
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={"Pengajuan Tunjangan Medical"}
                width={"70vw"}
                height={"85vh"}
                toolbarItems={this.props.action == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
            >
            <ScrollView width="100%" height="100%">
                <Form
                    colCount={2}
                    ref={this.formRef}
                    id={'formMaster'}
                    formData={this.state.DataMaster}
                    items={this.formMaster}
                    labelLocation={'left'}
                    readOnly={this.props.from == 'pengajuan' ? false : true}
                />
                        {/* <div className="row"> */}
                            {/* <div className="col-md-4"> */}
                                <FileUploader
                                    accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                    uploadMode={"useForm"}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText="Upload Kwitansi"
                                    showFileList={false}
                                    name={"FileKwitansi"}
                                    disabled={this.props.from == 'pengajuan' ? false : true}
                                />
                                <div className="row">
                                    <label className="col-2">File Name:</label>
                                    <label className="col-8">{this.state.DataDocument.dokumenFileKwitansiName}</label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button onClick={() => this.previewFile(this.state.DataDocument, 'FileKwitansi')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenFileKwitansiName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Preview
                                    </button>
                                </div>
                            {/* </div>
                            <div className="col-md-4"> */}
                                <FileUploader
                                    accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                    uploadMode={"useForm"}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText="Upload Keterangan Dokter"
                                    showFileList={false}
                                    name={"FileKetDokter"}
                                    disabled={this.props.from == 'pengajuan' ? false : true}
                                />
                                <div className="row">
                                    <label className="col-2">File Name:</label>
                                    <label className="col-8">{this.state.DataDocument.dokumenFileKetDokterName}</label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button onClick={() => this.previewFile(this.state.DataDocument, 'FileKetDokter')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenFileKetDokterName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Preview
                                    </button>
                                </div>
                            {/* </div>
                            <div className="col-md-4"> */}
                                <FileUploader
                                    accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                    uploadMode={"useForm"}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText="Upload Resep Dokter"
                                    showFileList={false}
                                    name={"FileResepDokter"}
                                    disabled={this.props.from == 'pengajuan' ? false : true}
                                />
                                <div className="row">
                                    <label className="col-2">File Name:</label>
                                    <label className="col-8">{this.state.DataDocument.dokumenFileResepDokterName}</label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button onClick={() => this.previewFile(this.state.DataDocument, 'FileResepDokter')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenFileResepDokterName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Preview
                                    </button>
                                </div>
                                
                            <ModalPreviewPdf
                                ref={this.modalPreviewPdfRef}
                                store={this.props.store}
                            />
                            <ModalPreviewImage
                                ref={this.modalPreviewImageRef}
                                store={this.props.store}
                            />
                            {/* </div> */}
                        {/* </div> */}
                        </ScrollView>
            </Popup>
        )
    }
}

export default FormModalPembayaranTunjanganMed