import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { broadcastNotification } from 'plugin/helper'
import ShowNoteAction from 'components/pages/modal/kepesertaan/pesertaDapen/aktif/online/note'

class DataGridAktivasiAkun extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    // {
                    //     text: 'TEST',
                    //     hint: 'TEST',
                    //     cssClass: 'text-primary',
                    //     onClick: (e) => {
                    //         this.broadcastNotification()
                    //     }
                    // },
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.props.showModalAktivasiAkun(e.row.data.id)
                        }
                    },
                    // {
                    //     text: 'Verifikasi',
                    //     hint: 'Verifikasi',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.updateStatus(e.row.data.id,3)
                    //     }
                    // },
                    // {
                    //     text: 'Reject',
                    //     hint: 'Reject',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.updateStatus(e.row.data.id,4)
                    //     }
                    // },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'emailAddress',
                caption: 'Email'
            },
            {
                dataField: 'mobilePhone',
                caption: 'No HP',
            },
        ]

        this.dataGridRef = React.createRef()
        this.popupShowNoteActionRef = React.createRef()
        
        this.currentData = []
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    updateStatus = (visitorId,statusCode) => {
        this.popupShowNoteActionRef.current.show(visitorId,statusCode)
    }

    broadcastNotification = () => {
        broadcastNotification(`Selamat Hari Raya Idul Fitri!`,`Selamat Liburan!`)
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='visitors/all-by-criteria-paging?memberStatus.in=WAITING'
                    insertAPI='visitors'
                    updateAPI='visitors'
                    deleteAPI='visitors'

                    backendserver={process.env.REACT_APP_BACKEND}
                    remoteOperations={true}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Aktivasi Akun Peserta Aktif"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aktivasi Akun Peserta Aktif'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ShowNoteAction
                    ref = {this.popupShowNoteActionRef}
                    updateStatus = {this.props.updateStatus}
                    store = {this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default DataGridAktivasiAkun
