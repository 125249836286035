import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { product } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, getSystemDate } from 'plugin/helper'

class LaporanPerubahanAsetNeto extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {
                productId: 1,
                balanceDate: getSystemDate(this.props.store)
            }
        }
        this.columns = [
            {
                dataField: 'akun_group',
                caption: 'Uraian',
                // cellRender: (e) => {
                //     var paddingSize = 20 * e.data.level
                //     return <span style={{ paddingLeft: `${paddingSize}px` }}>{e.text}</span>
                // }
            },
            {
                dataField: 'tahun_berjalan',
                caption: 'Tahun Berjalan',
            },
            {
                dataField: 'tahun_sebelumnya',
                caption: 'Tahun Sebelumnya',
            },
        ]
        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'balanceDate',
                        label: {
                            text: 'Tanggal Perubahan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            readOnly: true
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Load Data",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    loadData = () => {
        return [
            {
                id: 1,
                uraian: 'PENAMBAHAN',
                level: 0
            },
            {
                id: 1.1,
                uraian: 'Pendapatan Investasi',
                level: 1
            },
            {
                id: '1.1.1',
                uraian: 'Bunga/Bagi Hasil',
                level: 2
            },
            {
                id: '1.1.2',
                uraian: 'Dividen',
                level: 2
            },
            {
                id: '1.1.3',
                uraian: 'Sewa',
                level: 2
            },
            {
                id: '1.1.4',
                uraian: 'Laba (Rugi) Pelepasan Investasi',
                level: 2
            },
            {
                id: '1.1.5',
                uraian: 'Pendapatan Investasi Lain',
                level: 2
            },
            {
                id: '1.1.6',
                uraian: 'Total Pendapatan Investasi',
                level: 2
            },
            {
                id: 1.2,
                uraian: 'Peningkatan (Penurunan) Nilai Investasi',
                level: 1
            },
            {
                id: 1.3,
                uraian: 'Iuran Jatuh Tempo:',
                level: 1
            },
            {
                id: '1.3.1',
                uraian: 'Iuran Normal Pemberi Kerja',
                level: 2
            },
            {
                id: '1.3.2',
                uraian: 'Iuran Normal Peserta',
                level: 2
            },
            {
                id: '1.3.3',
                uraian: 'Iuran Tambahan',
                level: 2,
            },
            {
                id: 1.4,
                uraian: 'Pendapatan di Luar Investasi',
                level: 1,
            },
            {
                id: 1.5,
                uraian: 'Pengalihan Dana Dari Dana Pensiun Lain - -',
                level: 1
            },
            {
                id: 2,
                uraian: 'Jumlah Penambahan',
                level: 0
            },
            {
                id: 3,
                uraian: 'PENGURANGAN',
                level: 0
            },
            {
                id: 3.1,
                uraian: 'Beban Investasi',
                level: 1
            },
            {
                id: 3.2,
                uraian: 'Beban Operasional',
                level: 1
            },
            {
                id: 3.3,
                uraian: 'Beban di Luar Investasi dan Operasional',
                level: 1
            },
            {
                id: 3.4,
                uraian: 'Manfaat Pensiun',
                level: 1
            },
            {
                id: 3.5,
                uraian: 'Pajak Penghasilan',
                level: 1
            },
            {
                id: 3.6,
                uraian: 'Pengalihan Dana ke Dana Pensiun Lain',
                level: 1
            },
            {
                id: 4,
                uraian: 'Jumlah Pengurangan',
                level: 0
            },
            {
                id: 5,
                uraian: 'KENAIKAN (PENURUNAN) ASET NETO',
                level: 0
            },
            {
                id: 6,
                uraian: 'ASET NETO AKHIR PERIODE',
                level: 0
            },
            {
                id: 7,
                uraian: 'ASET NETO AWAL PERIODE',
                level: 0
            },
        ]
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Perubahan Aset Neto</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    loadAPI={`akuns/getPerubahanReportAsetNeto/${this.state.filterData.productId}/${formatDate(this.state.filterData.balanceDate)}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Perubahan Aset Neto"}
                    allowExportSelectedData={true}
                    selection={false}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanPerubahanAsetNeto