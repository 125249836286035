import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import uuidv4 from 'uuid/v4'
import { alert } from 'devextreme/ui/dialog'
import httpRequest from 'plugin/httprequest';
import FormAbsensiKepegawaian from 'components/pages/modal/sdm-umum/kepegawaian/absensi/index';
import { absenType, jabatan, karyawan, programs } from 'dataSource/lookup';
import UploadAbsen from 'components/pages/modal/sdm-umum/kepegawaian/absensi/upload';
import { Form } from 'devextreme-react';
import { confirmAlert } from 'react-confirm-alert'
import notify from "devextreme/ui/notify";
import { formatDate } from 'plugin/helper';

class Absensi extends Component {
    constructor(props) {
        super(props)

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            DataMaster: {
                programId: 1,
                startDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                endDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                absenTypeId: []
            }
        }

        this.dataGridRef = React.createRef()
        this.modalTambahRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.showModalUploadRef = React.createRef()

        this.karyawanAbsens = []

        this.formMaster = [
            {
                dataField: "programId",
                label: {
                    text: "Program",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    displayExpr: 'program',
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: "startDate",
                label: {
                    text: "Tanggal Dari",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true
                }
            },
            {

                dataField: 'absenTypeId',
                label: {
                    text: 'Tipe Absen',
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxTagBox',
                editorOptions: {
                    dataSource: absenType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'absenName',
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: async () => {
                        if (this.loadData) {
                            await this.dataGridRef.current.forceRefresh(true);
                        }
                        // this.setState({
                        //     DataMaster: this.state.DataMaster
                        // })
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModal(e.row.data, 'edit')
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: async (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteAPI(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    }
                ]
            },
            // {
            //     dataField:'karyawanId',
            //     caption:'NIK',
            //     lookup: {
            //         dataSource: karyawan(this.props.store),
            //         valueExpr: 'id',
            //         displayExpr: 'nik'
            //     }
            // },
            {
                dataField: 'tanggal',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'nik',
                caption: 'NIP',
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'nik',
                //     searchEnabled: true,
                //     deferRendering: false,
                // }
            },
            {
                dataField: 'fullName',
                caption: 'Nama Karyawan',
                lookup: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fullName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'manual_entry',
                dataType: 'boolean',
                caption: 'Manual Entry',
            },
            {
                caption: 'Datang',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'datangJam',
                        caption: 'Jam',
                        alignment: 'center'
                    },
                    {
                        dataField: 'datangTelat',
                        caption: 'Telat',
                        alignment: 'center'
                    },
                ]
            },
            {
                caption: 'Pulang',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pulangJam',
                        caption: 'Jam',
                        alignment: 'center'
                    },
                    {
                        dataField: 'pulangAwal',
                        caption: 'Lebih Awal',
                        alignment: 'center'
                    },
                ]
            },
            {
                caption: 'Keterangan Absen',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tipeAbsenId',
                        caption: 'Tipe',
                        lookup: {
                            dataSource: absenType(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'absenName',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'keterangan',
                        caption: 'Keterangan'
                    },
                ]
            }
        ]
    }

    // componentDidMount = async() => {
    //     await this.loadData()
    // }

    componentWillMount = () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (data, type) => {
        if(type === 'edit') {
            this.modalEditRef.current.show()
            this.modalEditRef.current.retrieveData(data)
        } else {
            this.modalTambahRef.current.show()
        }
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-absens`, 'DELETE', data.karyawanAbsenId);

            this.forceRefresh()
            notify({ message: "Sukses Hapus Data", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 1000);
        } catch (error) {
            await alert(error,'Failed')
        }
    }

    showModalUpload = (e) => {
        let data = this.karyawanAbsens
        this.showModalUploadRef.current.show()
        this.showModalUploadRef.current.getData(data)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Absen',
                    onClick: (e) => {
                        this.showModal('add')
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'upload',
                    text: 'Upload',
                    onClick: (e) => {
                        this.showModalUpload(e)
                    },
                }
            }
        )
    }

    loadData = async () => {
        let dataMaster = this.state.DataMaster;
        let startDate = formatDate(dataMaster.startDate);
        let endDate = formatDate(dataMaster.endDate);
        let dataResult = [];
        var formatJam = (tanggal) => {
            let date = tanggal ? tanggal.split('T') : null
            let jam = date[1].split(':00Z')
            let result = jam[0]

            return result
        }

        if (dataMaster.absenTypeId.length > 0) {
            var getAbsen = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-absens/filter?endDate=${endDate}&size=9999&startDate=${startDate}&tipeAbsenId=${this.state.DataMaster.absenTypeId}`, 'GET');
        } else {
            var getAbsen = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-absens/filter?endDate=${endDate}&size=9999&startDate=${startDate}`, 'GET');
        }

        let karyawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans?size=9999`, 'GET')
        let karyawanAbsens = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-absens?size=9999`, 'GET')

        this.karyawanAbsens = karyawanAbsens
        
        for (let value of getAbsen) {
            let namaKaryawan = karyawan.find(val => val.nik === value.nik)
            let fullName = namaKaryawan === undefined ? '-' : karyawan.find(val => val.nik === value.nik).id
            let manualEntry = karyawanAbsens.find(val => val.id === value.karyawanAbsenId).manual_entry

            let data = {
                ...value,
                tanggal: value.tanggal,
                nik: value.nik,
                // fullName: value.full_name ? null : karyawan.find(val => val.nik === value.nik).fullName,
                fullName: fullName,
                manual_entry: manualEntry ? manualEntry : false,
                datangJam: value.datangJam ? formatJam(value.datangJam) : null,
                datangTelat: value.datangTelat,
                pulangJam: value.pulangJam ? formatJam(value.pulangJam) : null,
                pulangAwal: value.pulangAwal,
                tipeAbsenId: value.tipeAbsenId,
                keterangan: value.keterangan
            }

            dataResult.push(data)
        }

        return dataResult;

        // if (dataMaster.absenTypeId.length > 0){
        //     let getAbsen = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-absens/filter?endDate=${endDate}&size=9999&startDate=${startDate}&tipeAbsenId=${this.state.DataMaster.absenTypeId}`, 'GET');

        //     let dataResult = [];
        //     for (let value of getAbsen){
        //         let data = {
        //             id: value.id,
        //             tanggal: value.tanggal,
        //             nik: value.nik,
        //             full_name: value.full_name,
        //             manual_entry: value.manual_entry,
        //             datang_jam: value.datang_jam,
        //             datang_telat: value.datang_telat,
        //             pulang_jam: value.pulang_jam,
        //             pulang_awal: value.pulang_awal,
        //             absen_name: value.absen_name,
        //             keterangan: value.keterangan
        //         }

        //         dataResult.push(data)
        //     }

        //     return dataResult;
        // } else {
        //     console.log('test');
        //     let getAbsen = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawan-absens/filter/select-all', 'GET');
        //     let dataResult = [];
        //     for (let value of getAbsen){
        //         let data = {
        //             id: value.id,
        //             tanggal: value.tanggal,
        //             nik: value.nik,
        //             full_name: value.full_name,
        //             manual_entry: value.manual_entry,
        //             datang_jam: value.datang_jam,
        //             datang_telat: value.datang_telat,
        //             pulang_jam: value.pulang_jam,
        //             pulang_awal: value.pulang_awal,
        //             absen_name: value.absen_name,
        //             keterangan: value.keterangan
        //         }

        //         dataResult.push(data)
        //     }
        //     return dataResult;
        // }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Absensi</h2>
                <div className="my-3">
                    <Form
                        colCount={5}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='karyawan-absens'
                    // loadAPI={`karyawan-absens/filter?endDate=${formatDate(this.state.DataMaster.endDate)}&size=9999&startDate=${formatDate(this.state.DataMaster.startDate)}&tipeAbsenId=${this.state.DataMaster.absenTypeId}`}
                    insertAPI='karyawan-absens'
                    updateAPI='karyawan-absens'
                    deleteAPI='karyawan-absens'

                    keyField="karyawanAbsenId"

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Absensi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Absensi'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 280px'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormAbsensiKepegawaian
                    ref={this.modalTambahRef}
                    store={this.props.store}
                    type={'add'}
                    forceRefresh={this.forceRefresh}
                />
                <FormAbsensiKepegawaian
                    ref={this.modalEditRef}
                    store={this.props.store}
                    type={'edit'}
                    forceRefresh={this.forceRefresh}
                />
                <UploadAbsen
                    ref={this.showModalUploadRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default Absensi