import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, managerInvestasis, product, instruments, productDefault, rdNeedConfirmByMi, kpds, fundManager, rekeningBank } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import ArrayStore from 'devextreme/data/array_store';
import { showLoading } from 'redux/actions/loading';


class ModalSettlementPelepasanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataPenjualan: {},
            dataMaster: {},
            actionType: null
        }
        this.prevTabIndex = 0
        this.instrumentName = ""
        this.instrumentId = 0
        this.amountOrder = 0
        this.unitOrder = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.redemptionRef = React.createRef()
        this.masterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'referensiNo',
                label: {
                    text: 'No Konfirmasi',
                },
                editorOptions: {
                    onValueChanged: (e) => {
                        this.setState({
                            dataMaster: {
                                referensiNo: e.value
                            }
                        })
                    },
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confrimation number"
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose product"
                    }
                ]
            },
            {
                dataField: 'orderDate',
                label: {
                    text: 'Tanggal Order',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose order date"
                    }
                ]
            },
            {
                dataField: 'miId',
                label: {
                    text: 'Manager Investasi',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName',
                    searchEnabled: true,
                    onValueChanged: async (e) => {
                        var productId = this.state.dataMaster.productId
                        var orderDate = this.state.dataMaster.orderDate ? formatDate(this.state.dataMaster.orderDate) : null
                        var miId = this.state.dataMaster.miId
                        var selectOrderId = this.redemptionRef.current.instance.getEditor('orderId')
                        var dataRd = await this.availableRdPelepasanConfirm(this.props.store, productId, miId, orderDate)
                        selectOrderId.option('dataSource', dataRd)
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Manager investasi"
                    }
                ]
            },
        ]

        this.TabDataSource = [
            {
                'id': 0,
                text: 'Penjualan'
            },
        ]

        this.itemRedemption = [
            {
                dataField: 'orderId',
                label: {
                    alignment: 'left',
                    text: 'No Order'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'orderId',
                    displayExpr: 'orderCode',
                    onSelectionChanged: async (e) => {
                        var data = e.selectedItem
                        //  
                        if (data) {
                            let loadAPI = `instruments?instrumentCode.in=${data.instrumentCode}`
                            var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')

                            this.instrumentId = getInstrument[0].id
                            this.instrumentName = data.instrumentName
                            this.amountOrder = data.amountOrder
                            this.unitOrder = data.unitOrder
                            this.redemptionRef.current.instance.updateData('productCode', data.productCode)
                            this.redemptionRef.current.instance.updateData('miName', data.miName)
                            this.redemptionRef.current.instance.updateData('instrumentName', data.instrumentName)
                            this.redemptionRef.current.instance.updateData('amountTrs', data.amountOrder)
                            this.redemptionRef.current.instance.updateData('quantity', data.unitOrder)
                            this.redemptionRef.current.instance.getEditor('currencyId').option('value', 1001)
                        }
                    }
                },
            },
            {
                dataField: 'pengelola',
                label: {
                    alignment: 'left',
                    text: 'Pengelolaan'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    layout: 'horizontal',
                    items: ['Internal', 'KPD'],
                    onValueChanged: (e) => {
                        var val = e.value
                        if (val == 'Internal') {
                            this.redemptionRef.current.instance.getEditor('kpdId').option('value', 1)
                            this.redemptionRef.current.instance.getEditor('kpdId').option('readOnly', true)
                        } else {
                            this.redemptionRef.current.instance.getEditor('kpdId').option('value', 0)
                            this.redemptionRef.current.instance.getEditor('kpdId').option('readOnly', false)
                        }
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Data pengelola tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'kpdId',
                label: {
                    alignment: 'left',
                    text: 'Tipe'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak',
                    onValueChanged: async (e) => {
                        if (e.value) {
                            if (e.value !== 1) {

                                let loadAPI = `efek-balance-avgs?categoryId.equals=2&kpdId.equals=${e.value}&productId.equals=1&quantity.greaterThan=0&miId.equals=${this.state.dataMaster.miId}`
                                var getEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')

                                var dataSource = []
                                for (let data of getEfekBalance) {
                                    let dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`, 'GET')

                                    data.instrumentName = dataInstrument.instrumentName
                                    data.instrumentCode = dataInstrument.instrumentCode
                                    dataSource.push(data)
                                }

                                this.redemptionRef.current.instance.getEditor('instrumentNameKpd').option('dataSource', dataSource)
                                this.redemptionRef.current.instance.updateData('instrumentName', "")
                                this.redemptionRef.current.instance.getEditor('instrumentNameKpd').option('readOnly', false)
                                this.redemptionRef.current.instance.getEditor('amountTrs').option('readOnly', false)
                            } else {
                                this.redemptionRef.current.instance.updateData('instrumentNameKpd', null)
                                this.redemptionRef.current.instance.updateData('instrumentName', this.instrumentName)
                                this.redemptionRef.current.instance.updateData('quantity', this.unitOrder)
                                this.redemptionRef.current.instance.updateData('amountTrs', this.amountOrder)
                                this.redemptionRef.current.instance.getEditor('instrumentNameKpd').option('readOnly', true)
                                this.redemptionRef.current.instance.getEditor('amountTrs').option('readOnly', true)
                            }
                        }
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Mohon pilih KPD terlebih dahulu'
                    }
                ]
            },
            {
                dataField: 'productCode',
                label: {
                    alignment: 'left',
                    text: 'Produk Dapen'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'miName',
                label: {
                    alignment: 'left',
                    text: 'Penerbit Reksadana'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'instrumentName',
                label: {
                    alignment: 'left',
                    text: 'Produk Reksadana'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'instrumentNameKpd',
                label: {
                    alignment: 'left',
                    text: 'Produk Reksadana KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'instrumentId',
                    displayExpr: 'instrumentName',
                    readOnly: true,
                    onSelectionChanged: (e) => {
                        if (e.selectedItem) {
                            this.redemptionRef.current.instance.updateData('quantity', e.selectedItem.quantity);
                            this.redemptionRef.current.instance.updateData('amountTrs', e.selectedItem.price);
                        }
                    }
                }
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal Transaksi tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'paymentDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Penerimaan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal Penerimaan tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'settleDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Settle'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal Settle tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'amountTrs',
                label: {
                    alignment: 'left',
                    text: 'Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true,
                    onValueChanged: (e) => {
                        var val = e.value
                        var fee = this.redemptionRef.current.instance.getEditor('biayaTransaksi').option('value')
                        if (val) {
                            this.redemptionRef.current.instance.updateData('netAmount', val + fee)
                        }
                    }
                }
            },
            {
                dataField: 'quantity',
                label: {
                    alignment: 'left',
                    text: 'Unit'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    step: 0
                }
            },
            {
                dataField: 'biayaTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Fee'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var val = e.value
                        var nominal = this.redemptionRef.current.instance.getEditor('amountTrs').option('value')
                        this.redemptionRef.current.instance.updateData('netAmount', val + nominal)
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Biaya Transaksi tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'netAmount',
                label: {
                    alignment: 'left',
                    text: 'total Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    readOnly: true,
                    onSelectionChanged: (e) => {
                        var data = e.selectedItem
                        if (data) {
                            this.redemptionRef.current.instance.updateData('currencyRate', data.rate)
                        }
                    }
                },
            },
            {
                dataField: 'currencyRate',
                label: {
                    alignment: 'left',
                    text: 'Rate Currency'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'biayaBank',
                label: {
                    alignment: 'left',
                    text: 'Biaya Bank/RTGS'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'fundManagerId',
                label: {
                    alignment: 'left',
                    text: 'Fund Manager'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fundManagerName',
                    searchEnabled: true
                }
            },
            {
                dataField: 'receiveRekBankId',
                label: {
                    alignment: 'left',
                    text: 'Rekening Tujuan Transfer'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Mohon pilih rekening tujuan terlebih dahulu'
                    }
                ]
            },
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show = async () => {
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            'bank-transactions/generate-no-konfirmasi/REKSADANA',
            'POST',
            { values: {} }
        )
        this.setState({
            popupVisible: true,
            dataMaster: {
                referensiNo: response
            },
            selectedTabIndex: 0
        });
    }

    hide() {
        this.instrumentName = ""
        this.instrumentId = 0
        this.amountOrder = 0
        this.unitOrder = 0
        this.setState({
            popupVisible: false,
            dataPenjualan: {},
            dataMaster: {},
            actionType: null
        })
    }

    availableRdPelepasanConfirm = async (store, productId, miId, orderDate) => {
        let loadAPI = `order-rd-penjualan-need-confirm/list?miId=${miId}&orderDate=${orderDate}&productId=${productId}`
        var getRd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRd,
            key: 'orderId'
        });

        return dataSource
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    validate = () => {
        var formValidation = this.redemptionRef.current.instance.validate();
        formValidation = this.masterRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        let productId = this.state.dataMaster.productId
        var refNo = this.state.dataMaster.referensiNo
        var dataPenjualan = this.state.dataPenjualan
        if (!dataPenjualan.biayaTransaksi) {
            dataPenjualan.biayaTransaksi = 0
        }
        if (!dataPenjualan.biayaBank) {
            dataPenjualan.biayaBank = 0
        }
        dataPenjualan.dueDate = dataPenjualan.settleDate
        dataPenjualan.productId = productId
        dataPenjualan.instrumentId = dataPenjualan.kpdId !== 1 ? dataPenjualan.instrumentNameKpd : this.instrumentId
        var insertAPI = dataPenjualan.kpdId !== 1 ? 'order-rds/konfirmasi-langsung-penjualan-reksadana' : 'order-rds/konfirmasi-penjualan-reksadana'

        var dataSubmit = {
            referensiNo: refNo,
            ...dataPenjualan,
        }

        if (dataSubmit) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: dataSubmit
                })

                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Reksadana'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formDataMaster'}
                        ref={this.masterRef}
                        formData={this.state.dataMaster}
                        items={this.dataMaster}
                        labelLocation={"left"}
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id="tabindex-0" className={'mt-2 col-md-12 text-center'} >
                            <div className="row">
                                <div className="col-md-12" style={{ overflowY: 'auto', height: 'calc(60vh - 200px)' }}>
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        ref={this.redemptionRef}
                                        formData={this.state.dataPenjualan}
                                        items={this.itemRedemption}
                                        scrollingEnabled={true}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalSettlementPelepasanReksadana