import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { broker, instrumentSubCategoryPenyertaan, stock } from 'dataSource/lookup';

class DetailPenempatanPenyertaanModal extends Component {
    constructor(props) {
        super(props)
        this.dataSource = new CustomStore({
            load: async (loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `order-requests-pl-dtls/${requestId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                return response.orderPlInfos
            },
            key: 'orderPlDTO.id',
            insert: (values) => {
            },
            update: (key, values) => {

            },
            remove: (key) => {

            }
        })

        this.columns = [
            {
                dataField: 'instrumentDTO.instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'instrumentDTO.instrumentName',
                caption: 'Nama Perusahaan',
            },
            {
                dataField: 'instrumentDTO.categorySubId',
                caption: 'Sub Kategori',
                lookup: {
                    dataSource: instrumentSubCategoryPenyertaan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                }
            },
            {
                dataField: 'instrumentDTO.unit',
                caption: 'Total Lembar Saham Terbit',
                dataType: 'number',
                format: '#,##0.0000',
            },
            {
                dataField: 'instrumentDTO.price',
                caption: 'Nilai Saham (/lbr)',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'orderPlDTO.nominalOrder',
                caption: 'Nominal Penyertaan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'orderPlDTO.quantityOrder',
                caption: 'Jumlah Saham Penyertaan',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'orderPlDTO.persentaseOrder',
                caption: 'Prosentase Penyertaan',
                alignment: 'right',
                format: '#,##0.00'
            },
        ]
    }

    render() {
        return (
            <DataGrid
                id={"successDataGrid"}
                showBorders={false}
                dataSource={this.dataSource}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                // height={100}
                showScrollbar={"always"}
            // columns={this.columns}
            >
                {
                    this.columns.map((column) => {
                        return <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            lookup={column.lookup}
                            cellRender={column.cellRender}
                            alignment={column.alignment || 'left'}
                            cssClass={column.cssClass}
                            format={column.format}
                            dataType={column.dataType}
                            width={column.width}
                            type={column.type}
                            buttons={column.buttons}
                            editorType={column.editorType}
                            editorOptions={column.editorOptions}
                            visible={column.visible}
                            sortOrder={column.sortOrder}
                            allowEditing={column.allowEditing}
                            calculateCellValue={column.calculateCellValue}
                        />
                    })
                }
                {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPenempatanPenyertaanModal