import React, { Component } from 'react'
import {Popup, FileUploader} from 'devextreme-react'
import Form from 'devextreme-react/form'
import { dapenProduk, karyawan, programs, tipePinjaman } from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog'
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import { formatUploadFileData, imageSource } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ArrayStore from 'devextreme/data/array_store';

class FormKaryawanDapen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataDocument:{},
            DataMaster: {},
            newData : true,
            uploadedData:[]
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: async() => {
                    await this.submitData()
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.form = [
            {
                itemType: 'group',
                items: [
                    
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            }
                        },
                    },
                    {
                        dataField: 'karyawanId',
                        label: {
                            text: 'Nama Lengkap',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: function(item){
                                return item && "NIK : " + item.nik + " - " + item.fullName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                    dataField: 'loanTypeId',
                        label: {
                            text: 'Tipe Pinjaman'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipePinjaman(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'loanName',
                            searchEnabled: true, 
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'tglPinjaman',
                        label: {
                            text: 'Tanggal Pinjaman'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'nominal',
                        label: {
                            text: 'Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'tenor',
                        label: {
                            text: 'Tenor (Bulan)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'bunga',
                        label: {
                            text: 'Bunga/Rate (%) p.a'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'totalBunga',
                        label: {
                            text: 'Total Bunga'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'POTONGAN',
                items: [
                    {
                        dataField: 'nominalCicilan',
                        label: {
                            text: 'Nominal Cicilan/Bulan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'bungaCicilan',
                        label: {
                            text: 'Bunga Cicilan/Bulan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'totalCicilan',
                        label: {
                            text: 'Total Cicilan/Bulan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'tglAwalCicilan',
                        label: {
                            text: 'Tanggal Awal Cicilan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'tglAkhirCicilan',
                        label: {
                            text: 'Tanggal Akhir Cicilan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                ]
            },
        ]
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}Base64`] = formattedFileData.base64data;
            DataDocument[`${fieldName}Base64ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}Base64ContentType`],state[`${fileName}Base64`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataKaryawanDapen: {},
            newData : true
        })
    }
    submitData = async() => {
        
    }
    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        var filteredData = getKaryawan.filter(val => val.programId == programId)
        let dataSource = new ArrayStore({
            data: filteredData,
            key: 'id'
        })
        this.formMasterRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }
    retrieveData = async(data, type = false) => {    
        this.setState({
            dataKaryawanDapen: data,
            newData : type
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Pinjaman Karyawan"}
                width={"50vw"}
                height={"80vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <Form
                        ref={this.formMasterRef}
                        colCount={1}
                        id={'formDataKepesertaanDapen'}
                        formData={this.state.dataKaryawanDapen}
                        items={this.form}
                        labelLocation = 'left'
                    />
                    <FileUploader
                        disabled={this.props.action == 'view' ? true : false}
                        accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                        uploadMode={"useForm"}
                        onValueChanged={this.onUploadStarted.bind(this)}
                        labelText="Upload File Document"
                        showFileList={false}
                        name={"file"}
                        value={this.state.uploadedData}
                        ref={this.fileUploderRef}
                    />
                        <div className="row">
                        <label className="col-4">File Name:</label>
                        <label className="col-8">{ this.state.DataDocument.fileName}</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument,'file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormKaryawanDapen