import React, { Component } from 'react'
import { mpPenerimaType , months, mpTypeNid2,product, viewPesertaAkunProducts } from 'dataSource/lookup';
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DropDownBox from 'devextreme-react/drop-down-box';
import notify from "devextreme/ui/notify";
import { ScrollView } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ActionLampiranPembayaranLainnya from './actionLampiran';
import httpRequest from 'plugin/httprequest';

class TambahPembayaranMpManfaatLainnya extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            dataMaster: {},
            fileTextSuratRs: '',
            fileTextStruk: '',
            fileTextLainnya: '',
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.akunProductId = 0

        this.popupRef = React.createRef()
        this.formRef = React.createRef()
        this.dataGridLampiranRef = React.createRef()
        this.actionLampiranRef = React.createRef()
        this.dropDownBoxRef = React.createRef()
        this.dataGridLookupRef = React.createRef()
        
        this.dataGridLampiran = []
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                        dataSource: product(this.props.store),
                        valueExpr: "id", // contains the same values as the "statusId" field provides
                        displayExpr: "productCode", // provides display values
                        searchEnabled: true,
                        deferRendering: false,
                        }
                    },
                    // {
                    //     dataField: 'akunProdukId',
                    //     label: {
                    //         alignment: 'left',
                    //         text: 'Card No / Nama'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: kepersertaanAkunPasif(this.props.store),
                    //         valueExpr: 'ids',
                    //         displayExpr: (item) => {
                    //             return item && 'Card No: ' + item.cardNos + ', Nama: ' + item.fullNames;
                    //         },
                    //         searchEnabled: true,
                    //         deferRendering: false,
                    //     }
                    // },
                    {
                        itemType: "group",
                        colCount: 2,
                        items: [
                            {
                                dataField: 'bulan',
                                label: {
                                    alignment: 'left',
                                    text: 'Periode'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                dataSource: months(),
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "value", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                            {
                                dataField: 'tahun',
                                label: {
                                    alignment: 'left',
                                    text: 'Tahun'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0
                                }
                            }
                        ]
                    },
                    // {
                    //     dataField: 'manfaatPensiunPenerimaTypeId',
                    //     label: {
                    //         alignment: 'left',
                    //         text: 'Penerima'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: mpPenerimaType(this.props.store),
                    //         valueExpr: "id", // contains the same values as the "statusId" field provides
                    //         displayExpr: "penerimaName", // provides display values
                    //         searchEnabled: true,
                    //         deferRendering: false,
                    //     }
                    // },
                    {
                        dataField: 'manfaatPensiunTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Manfaat Lain'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpTypeNid2(this.props.store,true),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "mpName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'dasarNilai',
                        label: {
                            alignment: 'left',
                            text: 'Dasar Nilai MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Presentase Mp Bulan'
                                },
                                {
                                    id: 2,
                                    value: 'Presentase Mp Sekaligus'
                                },
                                {
                                    id: 3,
                                    value: 'PHDP'
                                },
                                {
                                    id: 4,
                                    value: 'Nominal'
                                },
                            ],
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "value", // provides display values
                            onValueChanged : (e) => {
                                // var value = e.value
                                // if(value == 1){
                                //     this.formRef.current.instance.itemOption('persentase','visible', true)
                                //     this.formRef.current.instance.itemOption('nominalPengajuan','visible', false)
                                // }else{
                                //     this.formRef.current.instance.itemOption('persentase','visible', false)
                                //     this.formRef.current.instance.itemOption('nominalPengajuan','visible', true)
                                // }                                
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'persentase',
                        name: 'persentase',
                        label: {
                            alignment: 'left',
                            text: 'Presentasi Mp Bulanan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format : '#,##0',
                            step: 0
                        },
                        // visible: false
                    },
                    {
                        dataField: 'nominalPengajuan',
                        name: 'nominalPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Pengajuan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format : '#,##0.00',
                            step: 0
                        },
                        // visible: false
                    },
                    {
                        dataField: 'nominalDisetujui',
                        name: 'nominalDisetujui',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Disetujui'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format : '#,##0.00',
                            step: 0
                        },
                        // disabled: false
                    },
                    {
                        dataField: 'tanggalBayar',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Bayar'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            width: '100%',
                        }
                    },
                ]
            },
        ]

        this.dataGridColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        onClick : (e) => {
                            this.showModalActionLampiran(e.row.data)
                        },
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        onClick : (e) => {
                            this.deleteDataGrid(e.row.data.id)
                        },
                    },
                ],
            },
            {
               dataField: 'id',
               caption: 'No',
               cellRender : (e) => {
                   return e.rowIndex + 1
               }
            }, 
            {
               dataField: 'kwitansiNo',
               caption: 'No Kwitansi'
            },
            {
               dataField: 'kwitansiPenerbit',
               caption: 'Penerbit Kwitansi'
            },
            {
               dataField: 'keterangan',
               caption: 'Keterangan'
            },
            {
               dataField: 'kwitansiTanggal',
               caption: 'Tanggal',
               dataType: 'date',
               format: 'dd MMM yyyy'
            },
            {
               dataField: 'kwitansiNominal',
               caption: 'Nominal',
               format: '#,##0.00'
            },
        ]

        this.lookupColumn = [
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption : 'Nama Lengkap'
            }
        ]

        this.summary = {
            totalItems: [{
                column: 'kwitansiNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00'
            }]
        }
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    loadDataGrid = () => {
        return this.dataGridLampiran
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var data = {...this.state.dataMaster}
        var dataLampiran = this.dataGridLampiranRef.current.getDataSource()
        
        var dataPost = data
        dataPost.akunProdukId = this.akunProductId
        dataPost.persentase = dataPost.persentase || 0
        dataPost.nominalDisetujui = dataPost.nominalDisetujui || 0
        dataPost.nominalPengajuan = dataPost.nominalPengajuan || 0
        // dataPost.statusPeserta = 2
        
        try {
            var loadAPI = 'pengajuan-mp-lainnya'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: dataPost
            })
            
            // if(response){
            //     this.submitDataLampiran(response)
            // }

            let type = 'success'
            let text = 'Pengajuan Berhasil!'
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
            this.deleteAllDataGrid()
            this.props.forceRefresh()
            this.hide()
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }

    retrieveData = async(data) => {    
        this.setState({
            dataMaster: data,
        })
    }

    showModalActionLampiran = (data = {}) => {
        this.actionLampiranRef.current.show()
        if(data){
            this.actionLampiranRef.current.retrieveData(data)
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  text: 'Tambah lampiran',
                  onClick: (e) => {
                    this.showModalActionLampiran()
                  },
              }
          }
        )
      }

    forceRefresh = () => {
        this.dataGridLampiranRef.current.forceRefresh(true)
    }

    editDataGrid = async(data) => {
        var dataGrid = this.dataGridLampiranRef
        try{
            var key = data.id
            if(key){
                dataGrid.current.push([
                    {
                    type: 'update',
                    data : data,
                    key : key
                }
            ])
            }else{
                dataGrid.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
            }
            
            // this.formRef.current.instance.itemOption('nominalDisetujui','disabled', true)

            let type = 'success'
            let text = 'Data Berhasil!'
            
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }catch(e){
            console.log(e)
        }
    }
    
    deleteAllDataGrid = () => {
        this.dataGridLampiran = []
        this.forceRefresh()
    }
    deleteDataGrid = (id) => {
        this.dataGridLampiranRef.current.push([
            {
                type: 'remove',
                key : id
            }
        ])

        var record = this.dataGridLampiranRef.current.getDataSource()
        if(record.length == 0){
            // this.formRef.current.instance.itemOption('nominalDisetujui','disabled', false)
        }
        let type = 'success'
        let text = 'Berhasil Menghapus Data!'
        
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
    }

    onSelectionChanged = (e) => {
        this.akunProductId = e.selectedRowKeys[0]
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
    }

    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridLookupRef}
                loadAPI= {'view-lookup-kepersertaan-products?akunStatusId.in=2&akunStatusId.in=3&akunStatusId.in=4&akunStatusId.in=5&akunStatusId.in=7'}
                insertAPI='pesertas'
                updateAPI='pesertas'
                deleteAPI='pesertas'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Lookup Akun Produk"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Lookup Akun Produk'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.lookupColumn} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                // onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pembayaran Manfaat Pensiun Lain Per Pensiunan'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-1 col-form-label">Card No / Nama </label>
                                    <div className="col-sm-11">
                                        <DropDownBox
                                            ref={this.dropDownBoxRef}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr={(item) => {
                                                return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                            }}
                                            showClearButton={true}
                                            dataSource={viewPesertaAkunProducts(this.props.store)}
                                            onValueChanged={this.onValueChanged}
                                            contentRender={this.renderDatagrid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Form 
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formData'}
                                    formData={this.state.dataMaster}
                                    items={this.formItem}
                                    labelLocation = "left"
                                />
                            </div>
                            <div className="mt-5 col-md-12">
                                <h5 className="text-center">Lampiran</h5>
                                <DevExpressDataGrid
                                    ref = {this.dataGridLampiranRef}
                                    loadAPI='pembayaran-mp-lampirans'
                                    insertAPI='pembayaran-mp-lampirans'
                                    updateAPI='pembayaran-mp-lampirans'
                                    deleteAPI='pembayaran-mp-lampirans' 

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                                    useArraySource = {true}
                                    ArraySourceData = {this.loadDataGrid}
                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Pembayaran MP Lainnya Generate"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}
                                    
                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Pembayaran MP Lainnya Generate'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}

                                    onToolbarPreparing = {this.onToolbarPreparing}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ActionLampiranPembayaranLainnya
                    ref={this.actionLampiranRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                    editDataGrid = {this.editDataGrid}
                />
            </Popup>
        )
    }
}

export default TambahPembayaranMpManfaatLainnya