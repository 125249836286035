import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { coa, entity, product } from 'dataSource/lookup';
import ViewJournalVoucher from 'components/pages/modal/akutansi/journalVoucher/view';
import { showLoading } from 'redux/actions/loading';
import { reportFile } from 'plugin/reportRequest';
import { formatDefaultFullDate, formatNumber, formatNumberAum, formatNumberB, formatNumberNav, numberWithCommas } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog'

class GeneralLedger extends Component {
  constructor(props) {
    super(props)

    this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

    this.state = {
      balanceValue: 0,
      visible: false,
      totalTextArray: [],
      formFilter: {
        coaIds: [],
        entitiIds: [],
        startDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
        endDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
        produkId: null
      }
    }

    this.dataGridRef = React.createRef()
    this.popupModalJournalVoucherRef = React.createRef()

    this.filterItem = [
      {
        itemType: 'group',
        colSpan: 1,
        items: [
          {
            dataField: "coaIds",
            label: {
              text: "COA",
              alignment: "left",
              location: "left"
            },
            editorType: "dxTagBox",
            editorOptions: {
              dataSource: coa(this.props.store),
              displayExpr: function (item) {
                return item && item.coaCode + " - " + item.coaName;
              },
              valueExpr: 'id',
              searchEnabled: true,
              deferRendering: false,
              width: '74%',
            }
          },
        ]
      },
      {
        itemType: 'group',
        colCount: 4,
        items: [
          {
            dataField: "entitiIds",
            label: {
              text: "Entitas",
              alignment: "left",
              location: "left"
            },
            editorType: "dxTagBox",
            visible: false,
            editorOptions: {
              dataSource: entity(this.props.store),
              displayExpr: function (item) {
                return item && item.entityName;
              },
              valueExpr: 'id',
              searchEnabled: true,
              deferRendering: false,
            }
          },
          {
            dataField: "produkId",
            label: {
              text: "Produk",
              alignment: "left",
              location: "left"
            },
            editorType: "dxSelectBox",
            editorOptions: {
              dataSource: product(this.props.store),
              displayExpr: function (item) {
                return item && item.productCode + " - " + item.productName;
              },
              valueExpr: 'id',
              searchEnabled: true,
              deferRendering: false,
            }
          },
          {
            dataField: "startDate",
            label: {
              text: "Dari",
              alignment: "left",
              location: "left"
            },
            editorType: "dxDateBox",
            editorOptions: {
              useMaskBehavior: true,
              width: '100%',
              displayFormat: 'dd MMM yyyy'
            }
          },
          {
            dataField: "endDate",
            label: {
              text: "Sampai",
              alignment: "left",
              location: "left"
            },
            editorType: "dxDateBox",
            editorOptions: {
              useMaskBehavior: true,
              width: '100%',
              displayFormat: 'dd MMM yyyy'
            }
          },
          {
            itemType: "button",
            buttonOptions: {
              text: "Filter",
              // type:"default",
              elementAttr: { class: "bg-dapen-default" },
              onClick: () => {
                this.reloadDatagrid()
    
                let startDate = new Date(this.state.formFilter.startDate);
                let endDate = new Date(this.state.formFilter.endDate);
                // let entiti = this.state.formFilter.entitiIds;
                // entiti = null
    
                this.setState({
                  formFilter: {
                    ...this.state.formFilter,
                    // entitiIds: null,
                    startDate: startDate,
                    endDate: endDate,
                  }
                });
              },
            },
            horizontalAlignment: "left"
          },
        ]
      },
    ]

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Detail',
            hint: 'Detail',
            cssClass: 'text-success',
            onClick: (e) => {
              this.showModalView(e.row.data.journalId)
            }
          },
        ],
      },
      {
        dataField: 'coaId',
        caption: 'Chart of Account',
        groupIndex: 0,
        lookup: {
          dataSource: coa(this.props.store),
          valueExpr: 'id',
          displayExpr: function (item) {
            return item && item.coaCode + " - " + item.coaName;
          },
        }
      },
      {
        dataField: 'valueDate',
        caption: 'Tanggal',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right',
      },
      {
        dataField: 'journalCode',
        caption: 'Kode Jurnal',
      },
      {
        dataField: 'reffCode',
        caption: 'Reff',
      },
      {
        dataField: 'journalDesc',
        caption: 'Deskripsi',
      },
      {
        dataField: 'entitiName',
        caption: 'Entitas'
      },
      {
        dataField: 'debet',
        caption: 'Debet',
        format: "#,##0.00",
        alignment: 'right'
      },
      {
        dataField: 'credit',
        caption: 'Kredit',
        format: "#,##0.00",
        alignment: 'right',
      },
      {
        dataField: 'balance',
        caption: 'Saldo',
        format: "#,##0.00",
        alignment: 'right',
      },

    ]

    this.sumDebit = [
      {
        column: 'valueDate',
        summaryType: 'count',
        name: 'valueDate',
        displayFormat: `Total`,
        showInGroupFooter: true,
        alignByColumn: false,
        // alignment: 'left',
        customizeText: (e) => {
          return `Total ${this.pendingUpdate}`;
        }
        // itemTemplate: (e) => {
        //   return `<div style="text-align: left;">Total ${this.pendingUpdate}</div>`;
        // }
      },
      {
        column: 'debet',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'debet',
        displayFormat: '{0}',
        showInGroupFooter: true,
        alignByColumn: true
      },
      {
        column: 'credit',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'credit',
        displayFormat: '{0}',
        showInGroupFooter: true,
        alignByColumn: true
      },
      // {
      //   column: 'balance',
      //   summaryType: 'custom',
      //   // valueFormat: '#,##0.00',
      //   name: 'balance',
      //   displayFormat: '{0}',
      //   showInGroupFooter: true,
      //   alignByColumn: true,
      //   customizeText: () => {
      //     const formattedNumber = this.state.balanceValue;
      //     let numberWithoutDots = formattedNumber.replace(/\./g, '');
      //     // let numberWithDot = numberWithoutDots.replace(',', '.');
      //     // let integerNumber = Math.floor(parseFloat(numberWithDot));
      //     let combinedNumber = numberWithoutDots.replace(',', '');
      //     return this.state.balanceValue;
      //   }
      // },
    ]

    this.coaIds = []
    this.entitiIds = []
    this.pendingUpdate = ''
  }

  getDayMonthYear = (date) => {
    if (date) {
      let period = formatDefaultFullDate(date).split(' ');
      period = `${period[0]} ${period[1]} ${period[2]}`;
      return period;
    }
    return ''; // Handle the case when date is not available
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  loadData = async () => {
    if (this.coaIds.length === 0) {
      this.coaIds = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'coas')
    }

    if (this.entitiIds.length === 0) {
      this.entitiIds = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'entitis')
    }

    var data = []

    if (new Date(this.state.formFilter.startDate).getFullYear() !== new Date(this.state.formFilter.endDate).getFullYear()) {
      alert('Filter tanggal awal dan akhir harus di tahun yang sama', 'Informasi')

      data = []

      return data
    }

    if (this.state.formFilter.startDate && this.state.formFilter.endDate && this.state.formFilter.produkId) {
      var coaIds = this.state.formFilter.coaIds.length > 0 ? this.state.formFilter.coaIds : this.coaIds.map(value => value.id)
      // var entitiIds = this.state.formFilter.entitiIds.length > 0 ? this.state.formFilter.entitiIds : this.entitiIds.map(value => value.id)
      // data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'accounting-report/general-ledger-by-function', 'POST', {
      //   values: {
      //     coaIds: coaIds,
      //     endDate: this.state.formFilter.endDate,
      //     startDate: this.state.formFilter.startDate,
      //     produkId: this.state.formFilter.produkId
      //   }
      // })

      data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'accounting-report/general-ledger-by-entiti-id-by-function', 'POST', {
        values: {
          coaIds: coaIds,
          endDate: this.state.formFilter.endDate,
          entitiIds: [0],
          produkId: this.state.formFilter.produkId,
          startDate: this.state.formFilter.startDate
        }
      })

      while(data.length <= 0) {
        return []
      }
      var beginningBalance = data.filter(value => !value.valueDate)
      if (this.state.formFilter.coaIds.length === 0) {
        for (var coa of this.coaIds) {
          if (!beginningBalance.find(value => value.coaId === coa.id)) {
            data.push({
              amount: null,
              coaId: coa.id,
              credit: 0,
              currencyId: null,
              dealExrate: null,
              debet: 0,
              departmentId: null,
              id: 0,
              journalTypeId: null,
              journalCode: null,
              journalDesc: "Beginning Balance",
              journalId: null,
              reffCode: null,
              reffId: null,
              valueDate: null,
            })
          }
        }
      }

      data = data.sort((a, b) => {
        if (a.coaId < b.coaId) {
          return -1
        }

        if (a.coaId > b.coaId) {
          return 1
        }

        if (a.valueDate < b.valueDate) {
          return -1
        }

        if (a.valueDate > b.valueDate) {
          return 1
        }

        // if (a.journalId < b.journalId) {
        //   return -1
        // }

        // if (a.journalId > b.journalId) {
        //   return 1
        // }

        return 0
      })

      var coaBalance = []
      var arrDebet = []
      var arrCredit = []

      data = data.map((value, index) => {
        value.id = index
        arrDebet.push(value.debet);
        arrCredit.push(value.credit);
        var currentCoaBalanceIndex = coaBalance.findIndex(valueBalance => valueBalance.coaId === value.coaId)
        if (currentCoaBalanceIndex === -1) {
          coaBalance.push({
            coaId: value.coaId,
            currentBalance: value.debet - value.credit
          })
          value.balance = value.debet - value.credit
          this.setState({
            balanceValue: numberWithCommas(value.balance)
          })
        } else {
          // console.log(value);
          // if (value.entityName !== null) {
          //   if (value.debet === 0 || value.credit === 0) {
          //       let newCurrentBalance = coaBalance[currentCoaBalanceIndex].currentBalance;
          //       value.balance = newCurrentBalance;
          //   }
          // }
          var newCurrentBalance = coaBalance[currentCoaBalanceIndex].currentBalance + (value.debet - value.credit)
          coaBalance[currentCoaBalanceIndex].currentBalance = newCurrentBalance
          value.balance = newCurrentBalance
        }
        return value
      })
      const TotalDebet = arrDebet.reduce((a, b) => a + b, 0);
      const TotalCredit = arrCredit.reduce((a, b) => a + b, 0);
      const BalanceValue = TotalDebet - TotalCredit;

      this.setState({
            balanceValue: formatNumberAum(BalanceValue)
      })
    }
    return data
  }

  reloadDatagrid = () => {
    this.dataGridRef.current.forceRefresh(true)
  }

  showModalView = async (journalId) => {
    var dataJournalHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/${journalId}`)
    this.popupModalJournalVoucherRef.current.retrieveData(dataJournalHeader)
    await this.popupModalJournalVoucherRef.current.loadDataDetailAndExrate(journalId)
    this.popupModalJournalVoucherRef.current.show()
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'print',
          onClick: (e) => {
            this.print()
          },
        }
      }
    )
  }

  print = async () => {
    var data = this.dataGridRef.current.getDataSource()
    if (data.length > 0) {
      this.props.store.dispatch(showLoading(true))

      var coaIds = data.map(value => value.coaId)
      coaIds = [...new Set(coaIds)]
      var dataCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'coas')

      dataCoa = dataCoa.filter(value => coaIds.includes(value.id))
      dataCoa = dataCoa.sort((a, b) => {
        if (a.coaCode > b.coaCode) {
          return 1
        }
        if (a.coaCode === b.coaCode) {
          return 0
        }
        if (a.coaCode < b.coaCode) {
          return -1
        }
      })

      var dataGeneralLedger = []
      for (const coa of dataCoa) {
        var dataMutation = data.filter(value => value.coaId === coa.id)
        dataGeneralLedger.push({
          coaCode: coa.coaCode,
          coaName: coa.coaName,
          mutation: dataMutation
        })
      }

      var period = formatDefaultFullDate(this.state.formFilter.startDate)
      var prevPeriod = formatDefaultFullDate(this.state.formFilter.endDate)

      await reportFile({
        template: { 'shortid': 'HylMFlhDgF' },
        data: {
          period: period,
          prevPeriod: prevPeriod,
          dataGeneralLedger: dataGeneralLedger
        },
        options: {
          reportName: `Buku Besar ${period} - ${prevPeriod}`
        }
      }, true, `Buku Besar ${period} - ${prevPeriod}`)

      this.props.store.dispatch(showLoading(false))
    }
  }

  handleContentReady = (e) => {
    // console.log(e);
    const dataGrid = e.component;
    const grouping = dataGrid.option('grouping');
  }

  // handleOptionChanged = (e) => {
  //   if (e.fullName === 'columns[6].groupIndex') {
  //     console.log(e.fullName);
  //       console.log(e.value);
  //       if (e.value !== undefined) {
  //         console.log('test1');
  //         this.dataGridRef.current.DataGrid.deleteColumn('balance')
  //       } else {
  //         console.log('test2');
  //         this.dataGridRef.current.DataGrid.addColumn({
  //           dataField: 'balance',
  //           caption: 'Saldo',
  //           format: "#,##0.00",
  //           alignment: 'right',
  //         })
  //       }
  //   }
  // }

  handleOptionChanged = (e) => {
    if (e.fullName === 'columns[6].groupIndex') {
      const dataGrid = this.dataGridRef.current.DataGrid;
      if (e.value !== undefined) {
        dataGrid.beginUpdate();
        dataGrid.columnOption('balance', 'visible', false);
        dataGrid.endUpdate();
      } else {
        dataGrid.beginUpdate();
        dataGrid.columnOption('balance', {
          dataField: 'balance',
          caption: 'Saldo',
          format: "#,##0.00",
          alignment: 'right',
          visible: true,
        });
        dataGrid.endUpdate();
      }

      const dataSource = dataGrid.getDataSource();
      const dataItems = dataSource.items();

      let totalTextArray = [];

      dataItems.forEach(item => {
        if (item.collapsedItems && item.collapsedItems.length > 0) {
          item.collapsedItems.forEach(collapsedItem => {
            if (collapsedItem.entitiName) {
              totalTextArray.push(collapsedItem.entitiName);
            }
          });
        } else if (item.items && item.items.length > 0) {
          item.items.forEach(valueItem => {
            if (valueItem && valueItem.entitiName) {
              totalTextArray.push(valueItem.entitiName);
            }
          });
        }
      });

      this.setState({ totalTextArray: totalTextArray });
    }
  }

  // handleRowExpanding = (e) => {
  //   const { totalTextArray } = this.state;

  //   let entitiName;
  //   if (Array.isArray(e.key)) {
  //     entitiName = e.key.find((item) => totalTextArray.includes(item));
  //   } else if (typeof e.key === 'object') {
  //     for (let key in e.key) {
  //       if (totalTextArray.includes(e.key[key])) {
  //         entitiName = e.key[key];
  //         break;
  //       }
  //     }
  //   }

  //   if (typeof entitiName === 'string') {
  //     this.pendingUpdate = entitiName
  //   }
  // }

  handleRowExpanding = (e) => {
    const { totalTextArray } = this.state;

    let entitiName = null;
    
    if (Array.isArray(e.key)) {
        entitiName = e.key.find((item) => totalTextArray.includes(item));
    } else if (typeof e.key === 'object' && e.key !== null) {
        for (let key in e.key) {
            if (totalTextArray.includes(e.key[key])) {
                entitiName = e.key[key];
                break;
            }
        }
    }

    if (typeof entitiName === 'string') {
        this.pendingUpdate = entitiName;
    } else {
        this.pendingUpdate = ''; // Atau nilai default lainnya jika diperlukan
    }
  }

  handleCellText = (e) => {
    // if (e.rowType === 'groupFooter' && e.column.dataField === 'valueDate') {
    //   console.log(e)
    //   e.cellElement.classList.add('align-left-summary');
    // } 
    if (e.rowType === 'groupFooter' && e.column.dataField === 'valueDate') {
      const divElement = e.cellElement.querySelector('.dx-datagrid-summary-item');
      if (divElement) {
        divElement.style.textAlign = 'left';
      }
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Buku Besar</h2>
        <Form
          colCount={1}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
          labelLocation="left"
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='ledger'
          insertAPI='ledger'
          updateAPI='ledger'
          deleteAPI='ledger'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          useArraySource={true}
          ArraySourceData={this.loadData}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={`Buku Besar ${this.getDayMonthYear(this.state.formFilter.startDate)} - ${this.getDayMonthYear(this.state.formFilter.endDate)}`}
          allowExportSelectedData={false}
          selection={"none"}

          showBorders={true}

          exportWithFormatNum={true}
          exceptFieldExportFormtNum={
              [
                  'debet',
                  'credit',
                  'balance'
              ]
          }

          paging={false}
          showPageSizeSelector = {false}
          defaultPageSize={10}
          grouping={true}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'General Ledger'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          // height={'calc(95vh - 250px)'}

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          groupSummary={this.sumDebit}

          onToolbarPreparing={this.onToolbarPreparing}
          // onContentReady={this.handleContentReady}
          onOptionChanged={this.handleOptionChanged}
          onRowExpanding={this.handleRowExpanding}
          onCellPrepared={this.handleCellText}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        <ViewJournalVoucher
          ref={this.popupModalJournalVoucherRef}
          store={this.props.store}
          type={'view'}
          forceRefresh={this.reloadDatagrid}
        />
      </div>
    )
  }
}

export default GeneralLedger