import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { alert } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, pensiunType, product } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import ModalPemberhentianPeserta from 'components/pages/modal/kepesertaan/pesertaDapen/pemberhentian/approved/index';
import httpRequest from 'plugin/httprequest';
import { formatNumber, summaryValue } from 'plugin/helper';

class PemberhentianPeserta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            dataMaster: {},
            getUrl: 'kepersertaan-products/getByPesertaStatus/10'
        }

        this.dataGridRef = React.createRef()
        this.modalPemberhentianRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'nikPeserta',
                caption: 'NIK',
                alignment: "right",
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'tglPemberhentian',
                caption: 'Tanggal Berhenti',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'usia',
                caption: 'Usia Sekarang',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'productId',
                caption: 'Akun Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode" // provides display values
                }
            },
            {
                dataField: 'skPemberhentianNo',
                caption: 'No SK Henti'
            },
            {
                name: 'saldoIuran',
                alignment: 'center',
                caption: 'Saldo Iuran',
                columns: [
                    {
                        dataField: 'totalIuran',
                        caption: 'Iuran',
                    },
                    {
                        dataField: 'iuranTotal',
                        caption: 'Total Nominal',
                        // format: '#,##0.00',
                        cellRender : (e) => {
                            var data = e.row.data
                            var total = data.iuranManfaatLainPeserta + data.iuranManfaatLainPk + data.iuranNormalPeserta + data.iuranNormalPk + data.iuranSukarelaPeserta

                            return formatNumber(total)
                        },
                        alignment: 'right',
                    },
                ]
            },
        ]

        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        if(data.value){
                            this.setState({
                                getUrl: `kepersertaan-products/getByPesertaStatusAndAkunStatusAndParticipantId/10/8/${data.value}`
                            })
                        }else{
                            this.setState({
                                getUrl: `kepersertaan-products/getByPesertaStatus/10`
                            })
                        }
                        this.forceRefresh()
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (data = null) => {
        this.modalPemberhentianRef.current.show()
        this.modalPemberhentianRef.current.retrieveData(data)
    }

    retrieveData = async(param) => {
        var loadAPIAkun = this.state.getUrl + param
        var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'GET')
        
        var data = []
        if(dataAkun.length !== 0){
            for(var akun of dataAkun){
                var loadAPIAkunDetail = `kepersertaan-products/${akun.ids}`
                var dataAkunDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkunDetail, 'GET')

                var loadAPIPeserta = `pesertas/${akun.pesertaIds}`
                var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta, 'GET')

                var loadAPIIuran = `getIuranByGrupByAkun/${akun.ids}/1`
                var dataIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIIuran, 'GET')

                var loadAPIIuranByPeserta = `iurans/getByPesertaId/${akun.pesertaIds}`
                var dataIuranByPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIIuranByPeserta, 'GET')

                var result ={
                    id : akun.ids,
                    pesertaId : akun.pesertaIds,
                    nik : dataPeserta.nik,
                    fullName : dataPeserta.fullName,
                    pensiunTypeId : dataAkunDetail.pensiunType,
                    birthDate : dataPeserta.birthDate,
                    pensiunDate : dataAkunDetail.pensiunDate,
                    productId : dataAkunDetail.productId,
                    totalIuran: dataIuranByPeserta.length,
                    totalNominal : summaryValue(dataIuran,'nominal')
                }
                data.push(result)
            }
        }

        return data
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pemberhentian Peserta</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-simple-kepesertaan-products?akunStatusId.in=9'
                    insertAPI='kepersertaan-products'
                    updateAPI='kepersertaan-products'
                    deleteAPI='kepersertaan-products'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {true}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pemberhentian Peserta"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pemberhentian Peserta'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalPemberhentianPeserta
                    ref={this.modalPemberhentianRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'view'}
                />
            </div>
        )
    }
}

export default PemberhentianPeserta