import React, { Component } from 'react'
import { Popup, ScrollView, Form, FileUploader } from 'devextreme-react'
import { banks, currency, efekBalanceBuyDateInstrumentId, entity, entityP3, instrumentProperties, pejabat, productDefault } from 'dataSource/lookup'
import { formatDate, formatDefaultDate, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage'
import { showLoading } from 'redux/actions/loading';

class ModalOperasionalPemeliharaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataInfo: {},
            type: {},
            DataDocumentIns: {},
            DataDocumentTag: {},
            uploadedDataTagihan: [],
            uploadedDataInstruksi: []
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.tipeBiaya = [
            {
                id: 1,
                value: 'Add Value Aset'
            },
            {
                id: 2,
                value: 'Direct Cost'
            }
        ]

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async(e) => {
                        await this.submit()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'instruksiNo',
                        label: {
                            text: 'No Instruksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pengajuanDate',
                        label: {
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'transactionDate',
                        label: {
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            searchEnabled: true
                        }
                    },
                    // {
                    //     dataField: 'instrumentId',
                    //     label: {
                    //         text: 'Instrument Properti'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: [],
                    //         valueExpr: 'instrumentId',
                    //         displayExpr: (e) => {
                    //             return e && e.tipe + '  (' + formatDefaultDate(e.buyDate) + ')'
                    //         },
                    //         searchEnabled: true,
                    //         deferRendering: false,
                    //         onSelectionChanged: (e) => {
                    //             var slctd = e.selectedItem
                    //             if (slctd) {
                    //                 this.formRef.current.instance.updateData({
                    //                     tipe: slctd.tipe,
                    //                     keterangan: slctd.keterangan,
                    //                 })
                    //             }
                    //         }
                    //     }
                    // },
                    {
                        dataField: 'instrumentId',
                        label: {
                            text: 'Instrument Properti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentProperties(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'instrumentCode',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (e) => {
                                var filter = this.formRef.current.instance.getEditor('tipe')
    
                                filter.option('dataSource',efekBalanceBuyDateInstrumentId(this.props.store,e.value))
                            },
                            // onSelectionChanged: (e) => {
                            //     var slctd = e.selectedItem
                            //     if (slctd) {
                            //         this.formRef.current.instance.updateData({
                            //             tipe: slctd.tipe,
                            //             keterangan: slctd.keterangan,
                            //         })
                            //     }
                            // }
                        }
                    },
                    {
                        dataField: 'tipe',
                        label: {
                            text: 'Tipe'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'tipe',
                            displayExpr: 'tipe',
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: (e) => {
                                var slctd = e.selectedItem
                                if (slctd) {
                                    this.formRef.current.instance.updateData({
                                        keterangan: slctd.keterangan,
                                    })
                                }
                            }
                        }
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            text: 'Keterangan'
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'TAGIHAN',
                items: [
                    {
                        dataField: 'invoiceNo',
                        label: {
                            text: 'No Tagihan'
                        },
                    },
                    {
                        dataField: 'invoiceDate',
                        label: {
                            text: 'Tanggal Tagihan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'paymentDate',
                        label: {
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'pembayaranKepada',
                        label: {
                            text: 'Kontraktor / Entitas'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: entityP3(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'entityName',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'tipeBiaya',
                        label: {
                            text: 'Tipe Biaya'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: this.tipeBiaya,
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'nominalTransaksi',
                        label: {
                            text: 'Nominal Tagihan',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'pph',
                        label: {
                            alignment: 'left',
                            text: 'PPH'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nomTag = this.formRef.current.instance.getEditor('nominalTransaksi').option('value')
                                var pph = e.value
                                var ppn = this.formRef.current.instance.getEditor('ppn').option('value')
                                this.formRef.current.instance.getEditor('nominalBersih').option('value', nomTag - pph + ppn)
                            }
                        },
                    },
                    {
                        dataField: 'ppn',
                        label: {
                            text: 'PPn (10%)',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nomTag = this.formRef.current.instance.getEditor('nominalTransaksi').option('value')
                                var ppn = e.value
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                this.formRef.current.instance.getEditor('nominalBersih').option('value', nomTag - pph + ppn)
                            }
                        }
                    },
                    {
                        dataField: 'nominalBersih',
                        label: {
                            text: 'Total Tagihan',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    // {
                    //     dataField: 'entitasId',
                    //     label: {
                    //         text: 'Entitas',
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: entity(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'entityName',
                    //         searchEnabled: true,
                    //         deferRendering: false,
                    //     }
                    // },
                ]
            },
            {
                itemType: 'group',
                caption: 'REKENING TUJUAN',
                items: [
                    {
                        dataField: 'pembayaranRekNo',
                        label: {
                            text: 'No Rekening'
                        },
                    },
                    {
                        dataField: 'pembayaranRekName',
                        label: {
                            text: 'Nama Rekening',
                        },
                    },
                    {
                        dataField: 'pembayaranBankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true,
                            deferRendering: false
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: (e) => {
                                var slctd = e.selectedItem
                                if (slctd) {
                                    this.formRef.current.instance.updateData('currencyRate', slctd.rate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'currencyRate',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'DOKUMEN',
                items: [
                    {
                        dataField: 'pejabat1',
                        label: {
                            text: 'Pejabat 1',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'fullName',
                            displayExpr: 'fullName',
                            searchEnabled: true
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        label: {
                            text: 'Pejabat 2',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'fullName',
                            displayExpr: 'fullName',
                            searchEnabled: true
                        }
                    },
                ]
            },
        ]
    }

    show = async(type, data) =>  {
        this.setState({
            type: type,
            dataMaster: data,
            popupVisible: true,
        });

        if(data.fileInstruksiId && data.fileInvoiceId) {
            await this.retrieveFile(data.fileInstruksiId, data.fileInvoiceId)
        }

    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataInfo: {},
            type: {},
            DataDocumentIns: {},
            DataDocumentTag: {},
            uploadedDataTagihan: [],
            uploadedDataInstruksi: [],
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    retrieveFile = async (fileInstruksiId, fileInvoiceId) => {
        try {
            var responseInst = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `file-investasis/${fileInstruksiId}`,
                "GET",
            );
            var responseTag = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `file-investasis/${fileInvoiceId}`,
                "GET",
            );

            var DataDocumentIns = { ...this.state.DataDocumentIns };
            var DataDocumentTag = { ...this.state.DataDocumentTag };
            if (responseTag && responseInst) {
                DataDocumentTag[`id`] = responseTag.id;
                DataDocumentTag[`fileName`] = responseTag.fileName;
                DataDocumentTag[`fileBase64`] = responseTag.fileBase64;
                DataDocumentTag[`fileBase64ContentType`] = responseTag.fileBase64ContentType;

                DataDocumentIns[`id`] = responseInst.id;
                DataDocumentIns[`fileName`] = responseInst.fileName;
                DataDocumentIns[`fileBase64`] = responseInst.fileBase64;
                DataDocumentIns[`fileBase64ContentType`] = responseInst.fileBase64ContentType;
                this.setState({
                    DataDocumentIns: DataDocumentIns,
                    DataDocumentTag: DataDocumentTag
                });
            }

        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    previewFile = async (state, fileName) => {
        var src = imageSource(state[`${fileName}Base64ContentType`], state[`${fileName}Base64`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    onUploadInstruksi = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;
            var formattedFileNameAndExtention

            var DataDocument = { ...this.state.DataDocumentIns };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)

                DataDocument[`${fieldName}Name`] = formattedFileNameAndExtention.name;
                DataDocument[`${fieldName}Base64`] = formattedFileData.base64data;
                DataDocument[`${fieldName}Base64ContentType`] = formattedFileData.mime;
                DataDocument[`${fieldName}Extention`] = formattedFileNameAndExtention.extention;
                console.log(DataDocument);
                this.setState({
                    DataDocumentIns: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    onUploadTagihan = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocumentTag };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}Base64`] = formattedFileData.base64data;
                DataDocument[`${fieldName}Base64ContentType`] = formattedFileData.mime;
                this.setState({
                    DataDocumentTag: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    submit = async () => {
        this.props.store.dispatch(showLoading(true))

        try {
            var responseIns = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "POST", {
                values: this.state.DataDocumentIns
            }
            );

            var responseTag = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "POST", {
                values: this.state.DataDocumentTag
            }
            );

            if (responseIns && responseTag) {
                await this.submitData(responseIns.id, responseTag.id)

                this.props.store.dispatch(showLoading(false))
            } else {
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Terjadi Kesalahan System , Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    submitData = async (fileInstruksiId, fileInvoiceId) => {
        var data = { ...this.state.dataMaster }
        let type = this.state.type

        let loadEntity = `entitis/${data.pembayaranKepada}`
        var responseEntity = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadEntity, 'GET')

        var submitData = {
            currencyId: data.currencyId,
            currencyRate: data.currencyRate,
            description: 'Biaya Pemeliharaan Properti ' + data.instrumentId + ' Tanggal ' + formatDefaultDate(data.transactionDate) + ' @ ' + data.nominalTransaksi,
            entitasId: data.pembayaranKepada,
            feeBank: 0,
            fileInstruksiId: fileInstruksiId,
            fileInvoiceId: fileInvoiceId,
            institusi: 4,
            institusiLainNama: responseEntity.entityName,
            instruksiDate: formatDate(data.transactionDate),
            instruksiNo: data.instruksiNo,
            instrumentId: data.instrumentId,
            invoiceDate: formatDate(data.invoiceDate),
            invoiceNo: data.invoiceNo,
            kpdId: 1,
            nominalBersih: data.nominalBersih,
            nominalTransaksi: data.nominalTransaksi,
            paymentDate: formatDate(data.paymentDate),
            pejabat1: data.pejabat1,
            pejabat2: data.pejabat2,
            pembayaranBankId: data.pembayaranBankId,
            pembayaranKepada: responseEntity.entityName,
            pembayaranRekName: data.pembayaranRekName,
            pembayaranRekNo: data.pembayaranRekNo,
            pengajuanDate: formatDate(data.pengajuanDate),
            ppn: data.ppn,
            productId: data.productId,
            transactionDate: formatDate(data.transactionDate),
            transactionTypeId: 281
        }

        if (submitData) {
            if (type == 'add') {
                try {
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transaction/tagihan-pemeliharaan-properti', 'POST', { values: submitData })
                    if (response) {
                        this.hide()
                        this.props.forceRefresh()
                        notify({ message: 'Data Berhasil Disimpan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }else {
                submitData.id = data.id
                let oldData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `bank-transactions/${data.id}`, 'GET')
                try {
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transactions', 'PUT', { values: submitData, key: data.id }, oldData)
                    if (response) {
                        this.hide()
                        this.props.forceRefresh()
                        notify({ message: 'Data Berhasil Diubah!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengajuan Pemeliharaan Aset'}
                width={'700'}
                height={'800'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />

                                <FileUploader
                                    disabled={this.props.action == 'view' ? true : false}
                                    accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                    uploadMode={"useForm"}
                                    onValueChanged={this.onUploadInstruksi.bind(this)}
                                    labelText="Upload File Instruksi"
                                    showFileList={false}
                                    name={"file"}
                                    value={this.state.uploadedDataInstruksi}
                                    ref={this.fileUploderRef}
                                />
                                <div className="row">
                                    <label className="col-2">File Name:</label>
                                    <label className="col-8">{this.state.DataDocumentIns.fileName}</label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button onClick={() => this.previewFile(this.state.DataDocumentIns, 'file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocumentIns.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Preview
                                    </button>
                                </div>

                                <FileUploader
                                    disabled={this.props.action == 'view' ? true : false}
                                    accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                    uploadMode={"useForm"}
                                    onValueChanged={this.onUploadTagihan.bind(this)}
                                    labelText="Upload File Tagihan"
                                    showFileList={false}
                                    name={"file"}
                                    value={this.state.uploadedDataTagihan}
                                    ref={this.fileUploderRef}
                                />
                                <div className="row">
                                    <label className="col-2">File Name:</label>
                                    <label className="col-8">{this.state.DataDocumentTag.fileName}</label>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button onClick={() => this.previewFile(this.state.DataDocumentTag, 'file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocumentTag.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Preview
                                    </button>
                                </div>
                    </div>
                </ScrollView>

                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default ModalOperasionalPemeliharaan