import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { entityType, currency } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import { formatDate, formatDefaultDate, formatNumberAum } from 'plugin/helper';
import ModalCashSettlementVoucher from 'components/pages/modal/akutansi/cash-settlement/voucher';
import EditDataSpesific from 'components/pages/modal/akutansi/cash-settlement/editDataSpesific';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from 'redux/actions/loading';

class CashSettlementHistory extends Component {
    constructor(props) {
        super(props)

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            formFilter: {
                startDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                endDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                status: 'P'
            }
        }

        this.dataGridRef = React.createRef()
        this.modalCashSettlementVoucherRef = React.createRef()
        this.editDataSpesificRef = React.createRef()

        this.selectedRowDatas = []

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Tanggal Voucher",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: "status",
                label: {
                    text: "Status",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: [
                        {
                            id: 'RV',
                            value: 'Request Verification'
                        },
                        {
                            id: 'A',
                            value: 'Verified'
                        },
                        {
                            id: 'F',
                            value: 'Verification Failed'
                        },
                        {
                            id: 'R',
                            value: 'Rejected'
                        },
                        {
                            id: 'C',
                            value: 'Cancel'
                        },
                        {
                            id: 'P',
                            value: 'Posted'
                        },
                    ],
                    displayExpr: 'value',
                    valueExpr: 'id',
                    searchEnabled: true,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.dataGridRef.current.forceRefresh(true)
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Voucher',
                        hint: 'Lihat Voucher',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.modalCashSettlementVoucherRef.current.show()
                            this.modalCashSettlementVoucherRef.current.retrieveData(e.row.data)
                        }
                    },
                    {
                        text: 'Cetak Voucher',
                        hint: 'Cetak Voucher',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data) {
                                this.printVoucher(e.row.data)
                            }
                        }
                    },
                    {
                        text: 'Edit (Bank, Deskripsi, Tanggal Jurnal)',
                        hint: 'Edit (Bank, Deskripsi, Tanggal Jurnal)',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data) {
                                this.editDataSpesific(e.row.data)
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'RV',
                            value: 'Request Verification'
                        },
                        {
                            id: 'A',
                            value: 'Verified'
                        },
                        {
                            id: 'F',
                            value: 'Verification Failed'
                        },
                        {
                            id: 'R',
                            value: 'Rejected'
                        },
                        {
                            id: 'C',
                            value: 'Cancel'
                        },
                        {
                            id: 'P',
                            value: 'Posted'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'voucherNo',
                caption: 'No Voucher',
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'entityName',
                caption: 'Entitas'
            },
            {
                dataField: 'entityType',
                caption: 'Tipe Entitas',
                lookup: {
                    dataSource: entityType(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            },
            {
                dataField: 'type',
                caption: 'Tipe',
                lookup: {
                    dataSource: [
                        {
                            id: 'P',
                            value: 'Payment'
                        },
                        {
                            id: 'R',
                            value: 'Receive'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'entryDate',
                caption: 'Tanggal Entri',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'valueDate',
                caption: 'Tanggal Jurnal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'settleWith',
                caption: 'Pembayaran Dengan',
                lookup: {
                    dataSource: [
                        {
                            id: 'C',
                            value: 'Uang Tunai'
                        },
                        {
                            id: 'B',
                            value: 'Bank'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'amount',
                caption: 'Total Pembayaran',
                format: '#,##0.00; #,##0.00',
                alignment: 'right'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    editDataSpesific = async (data) => {
        try {
            let status = [
                {
                    id: 'F',
                    value: 'Verification Failed'
                },
                {
                    id: 'R',
                    value: 'Rejected'
                },
                {
                    id: 'C',
                    value: 'Cancel'
                },
                {
                    id: 'P',
                    value: 'Posted'
                },
            ]

            for (let statusDetail of status) {
                if (statusDetail.id === data.status) return notify({ message: `Data dengan status ${statusDetail.value} tidak bisa diedit`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }

            this.editDataSpesificRef.current.show()
            this.editDataSpesificRef.current.retrieveData(data)
        } catch (e) {
            console.log(e);
        }
    }

    printVoucher = async (data) => {
        try {
            this.props.store.dispatch(showLoading(true))
            let getCashVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `print/cashvoucher/${data.cashVoucherId}`)
            let getCashVoucherDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `print/cashvoucherdetil/${data.cashVoucherId}`)
            let printData = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `print/${getCashVoucher[0].bank_id}/${getCashVoucher[0].created_by}/${getCashVoucher[0].verifikasi_by}/${getCashVoucher[0].approve_by}`)

            var maapingGetCashVoucherDetil = []
            for (let result of getCashVoucherDetil) {
                result.amount_paid = formatNumberAum(result.amount_paid)
                maapingGetCashVoucherDetil.push(result)
            }

            let dataReport = {
                company_name: printData[0].company_name,
                address: printData[0].address,
                telp_number: printData[0].telp_number,
                fax_number: printData[0].fax_number,
                email: printData[0].email,
                value_date: formatDefaultDate(getCashVoucher[0].value_date),
                voucher_no: getCashVoucher[0].voucher_no,
                entity_name: getCashVoucher[0].entity_name,
                bank_name: printData[0].bank_name,
                rek_no: getCashVoucher[0].rek_no,
                rek_no: getCashVoucher[0].rek_no,
                dataTransaksi: maapingGetCashVoucherDetil,
                pph_coa_code: getCashVoucher[0].pph_coa_code,
                pph_coa_name: getCashVoucher[0].pph_coa_name,
                pph_nominal: formatNumberAum(getCashVoucher[0].pph_nominal),
                transfer_fee: formatNumberAum(getCashVoucher[0].transfer_fee),
                pembulatan_nominal: formatNumberAum(getCashVoucher[0].pembulatan_nominal),
                amount: formatNumberAum(getCashVoucher[0].amount),
                description: getCashVoucher[0].description,
                creator_fullname: printData[0].creator_fullname,
                verifikator_fullname: printData[0].verifikator_fullname,
                approver_fullname: printData[0].approver_fullname,
                created_by: getCashVoucher[0].created_by,
                verifikasi_by: getCashVoucher[0].verifikasi_by,
                approve_by: getCashVoucher[0].approve_by,
            }

            var data = {
                template: {
                    'shortid': 'rkghNJoEB3',
                    'recipe': 'chrome-pdf'
                },
                data: dataReport,
                options: {
                    reportName: "Voucher" + dataReport.voucher_no
                }
            }

            await reportFile(data, true, `Voucher-${dataReport.voucher_no}.pdf`)

            this.props.store.dispatch(showLoading(false))
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var status = this.state.formFilter.status
        // if(this.state.formFilter.status.length > 0){
        //     status = this.state.formFilter.status.join(',')
        // }else{
        //     status = "O,C,P"
        // }
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/getByStatusAndValueDateBetween/${status}/${formatDate(this.state.formFilter.startDate)}/${formatDate(this.state.formFilter.endDate)}`)

        return data
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Cash Settlement History</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='cash-voucers'
                    insertAPI='cash-voucers'
                    updateAPI='cash-voucers'
                    deleteAPI='cash-voucers'

                    keyField="cashVoucherId"

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    ArraySourceData={this.loadData}
                    useArraySource={true}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'amount'
                        ]
                    }

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Cash Settlement Voucher"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}
                    grouping={false}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Cash Settlement Voucher'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 250px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    summaryTotalItem={this.sumDebit}

                    onToolbarPreparing={this.onToolbarPreparing}

                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalCashSettlementVoucher ref={this.modalCashSettlementVoucherRef} store={this.props.store} forceRefresh={this.forceRefresh} type={"view"} />
                <EditDataSpesific ref={this.editDataSpesificRef} store={this.props.store} forceRefresh={this.forceRefresh} />
            </div>
        )
    }
}

export default CashSettlementHistory