import React, { Component } from "react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate } from "plugin/helper";
import httpRequest from "plugin/httprequest";

class LaporanHasilInvestasiDetail extends Component {
    constructor(props){
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                productId:1,
                // startDate: new Date(new Date().getFullYear(), 0, 1),
                periode: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.filterItem = [
            {
                dataField: "productId",
                label: {
                  text: "Produk",
                  alignment: "left",
                  location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                  dataSource: product(this.props.store),
                  displayExpr: function(item){
                    return item && item.productCode + " - " + item.productName;
                  },
                  valueExpr: 'id',
                  searchEnabled: true,
                  deferRendering: false,
                }
            },
            {
              dataField: "periode",
              label: {
                text: "Periode",
                alignment: "left",
                location: "left"
              },
              editorType: "dxDateBox",
              editorOptions: {
                useMaskBehavior: true,
                width: '100%',
                displayFormat: 'dd MMM yyyy',
                // calendarOptions: {
                //     maxZoomLevel: 'year'
                // },
                openOnFieldClick: true
              }
            },
            {
              itemType: "button",
              buttonOptions: {
                text: "Filter",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: async() => {
                //   this.treeListRef.current.forceRefresh(true)
                // await alert('Under Development', 'Information!');
                    this.filterData()

                    // let selectedDate = new Date(this.state.filterData.endDate)
                    
                    // this.setState({
                    //     tanggal: selectedDate
                    // })
                },
              },
              horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                dataField: 'emitenName',
                caption: 'Nama Emiten',
                fixed: true,
                fixedPosition: "left"
            },
            {
                dataField: 'kpdName',
                caption: 'Nama KPD',
                fixed: true,
                fixedPosition: "left"
            },
            {
                dataField: 'saham',
                caption: 'Saham',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'labaRugiSaham',
                        caption: 'Laba Rugi Saham',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'devidenSaham',
                        caption: 'Deviden Saham',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangSaham',
                        caption: 'Hutang Saham',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangSaham',
                        caption: 'Piutang Saham',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'reksadana',
                caption: 'Reksadana',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'labaRugiReksadana',
                        caption: 'Laba Rugi Reksadana',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'devidenReksadana',
                        caption: 'Deviden Reksadana',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangReksadana',
                        caption: 'Hutang Reksadana',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangReksadana',
                        caption: 'Piutang Reksadana',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'sbn',
                caption: 'Surat Berharga Negara',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'labaRugiSbn',
                        caption: 'Laba Rugi SBN',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'bungaBagiHasilSbn',
                        caption: 'Bunga Bagi Hasil SBN',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangSbn',
                        caption: 'Hutang SBN',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangSbn',
                        caption: 'Piutang SBN',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'obligasi',
                caption: 'Obligasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'labaRugiObligasi',
                        caption: 'Laba Rugi Obligasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'bungaBagiHasilObligasi',
                        caption: 'Bunga Bagi Hasil Obligasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangObligasi',
                        caption: 'Hutang Obligasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangObligasi',
                        caption: 'Piutang Obligasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'sukuk',
                caption: 'Sukuk',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'labaRugiSukuk',
                        caption: 'Laba Rugi Sukuk',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'bungaBagiHasilSukuk',
                        caption: 'Bunga Bagi Hasil Sukuk',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangSukuk',
                        caption: 'Hutang Sukuk',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangSukuk',
                        caption: 'Piutang Sukuk',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'eba',
                caption: 'EBA',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'labaRugiEba',
                        caption: 'Laba Rugi EBA',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'bungaBagiHasilEba',
                        caption: 'Bunga Bagi Hasil EBA',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangEba',
                        caption: 'Hutang EBA',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangEba',
                        caption: 'Piutang EBA',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'deposito',
                caption: 'Deposito',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bungaBagiHasilDeposito',
                        caption: 'Bunga Bagi Hasil Deposito',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangDeposito',
                        caption: 'Hutang Deposito',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangDeposito',
                        caption: 'Piutang Deposito',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'properti',
                caption: 'Properti',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'labaRugiProperti',
                        caption: 'Laba Rugi Properti',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'hutangProperti',
                        caption: 'Hutang Properti',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'piutangProperti',
                        caption: 'Piutang Properti',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'sewaProperti',
                        caption: 'Sewa Properti',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            }
        ];

        this.sumHasilInvestasi = [
            {
                showInColumn: 'kpdName',
                displayFormat: 'Total :'
            },
            {
                column: 'labaRugiSaham',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'labaRugiSaham',
                displayFormat: '{0}'
            },
            {
                column: 'devidenSaham',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'devidenSaham',
                displayFormat: '{0}'
            },
            {
                column: 'hutangSaham',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangSaham',
                displayFormat: '{0}'
            },
            {
                column: 'piutangSaham',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangSaham',
                displayFormat: '{0}'
            },
            {
                column: 'labaRugiReksadana',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'labaRugiReksadana',
                displayFormat: '{0}'
            },
            {
                column: 'devidenReksadana',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'devidenReksadana',
                displayFormat: '{0}'
            },
            {
                column: 'hutangReksadana',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangReksadana',
                displayFormat: '{0}'
            },
            {
                column: 'piutangReksadana',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangReksadana',
                displayFormat: '{0}'
            },
            {
                column: 'labaRugiSbn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'labaRugiSbn',
                displayFormat: '{0}'
            },
            {
                column: 'bungaBagiHasilSbn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bungaBagiHasilSbn',
                displayFormat: '{0}'
            },
            {
                column: 'hutangSbn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangSbn',
                displayFormat: '{0}'
            },
            {
                column: 'piutangSbn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangSbn',
                displayFormat: '{0}'
            },
            {
                column: 'labaRugiObligasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'labaRugiObligasi',
                displayFormat: '{0}'
            },
            {
                column: 'bungaBagiHasilObligasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bungaBagiHasilObligasi',
                displayFormat: '{0}'
            },
            {
                column: 'hutangObligasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangObligasi',
                displayFormat: '{0}'
            },
            {
                column: 'piutangObligasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangObligasi',
                displayFormat: '{0}'
            },
            {
                column: 'labaRugiSukuk',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'labaRugiSukuk',
                displayFormat: '{0}'
            },
            {
                column: 'bungaBagiHasilSukuk',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bungaBagiHasilSukuk',
                displayFormat: '{0}'
            },
            {
                column: 'hutangSukuk',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangSukuk',
                displayFormat: '{0}'
            },
            {
                column: 'piutangSukuk',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangSukuk',
                displayFormat: '{0}'
            },
            {
                column: 'labaRugiEba',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'labaRugiEba',
                displayFormat: '{0}'
            },
            {
                column: 'bungaBagiHasilEba',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bungaBagiHasilEba',
                displayFormat: '{0}'
            },
            {
                column: 'hutangEba',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangEba',
                displayFormat: '{0}'
            },
            {
                column: 'piutangEba',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangEba',
                displayFormat: '{0}'
            },
            {
                column: 'bungaBagiHasilDeposito',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bungaBagiHasilDeposito',
                displayFormat: '{0}'
            },
            {
                column: 'hutangDeposito',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangDeposito',
                displayFormat: '{0}'
            },
            {
                column: 'piutangDeposito',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangDeposito',
                displayFormat: '{0}'
            },
            {
                column: 'labaRugiProperti',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'labaRugiProperti',
                displayFormat: '{0}'
            },
            {
                column: 'hutangProperti',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'hutangProperti',
                displayFormat: '{0}'
            },
            {
                column: 'piutangProperti',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'piutangProperti',
                displayFormat: '{0}'
            },
            {
                column: 'sewaProperti',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sewaProperti',
                displayFormat: '{0}'
            }
        ];
        
        

        this.dataGridRef = React.createRef()
        this.dataResult = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    filterData = async () => {
        try {
            // this.props.store.dispatch(showLoading(true))
            let periode = new Date(this.state.filterData.periode);
            // let date = findLastDayOfMonth(periodeYear, periodeMonth);
            let productId = this.state.filterData.productId;

            let data = [];
            let loadAPI = `emitens/detail-report-hasil-investasi/${formatDate(periode)}/${productId}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');

            let counter = 1;

            for (let value of response) {
                let dataValue = {
                    id: counter++,
                    ...value
                }   
                data.push(dataValue);
            }
            
            this.dataResult = data;
            this.dataGridRef.current.forceRefresh(true)
            // this.props.store.dispatch(showLoading(false));
        } catch (error) {
            console.log(error);
        }
    }

    loadData = () => {
        return this.dataResult
    }

    render(){
        return (
            <div className="container-fluid">
                <h3 className="main-title">Laporan Hasil Investasi Detail</h3>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                />
                 <DevExpressDataGrid
                    ref={this.dataGridRef}
                    // loadAPI={`get-investasi-hasil-report?endDate=${formatDate(this.state.filterData.endDate)}&productId=${this.state.filterData.productId}&startDate=${formatDate(this.state.filterData.startDate)}`}
                    loadAPI={`emitens/detail-report-hasil-investasi/2023-12-31/1`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={`Lapor Hasil Investasi Detail`}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'labaRugiSaham',
                            'devidenSaham',
                            'hutangSaham',
                            'piutangSaham',
                            'labaRugiReksadana',
                            'devidenReksadana',
                            'hutangReksadana',
                            'piutangReksadana',
                            'labaRugiSbn',
                            'bungaBagiHasilSbn',
                            'hutangSbn',
                            'piutangSbn',
                            'labaRugiObligasi',
                            'bungaBagiHasilObligasi',
                            'hutangObligasi',
                            'piutangObligasi',
                            'labaRugiSukuk',
                            'bungaBagiHasilSukuk',
                            'hutangSukuk',
                            'piutangSukuk',
                            'labaRugiEba',
                            'bungaBagiHasilEba',
                            'hutangEba',
                            'piutangEba',
                            'bungaBagiHasilDeposito',
                            'hutangDeposito',
                            'piutangDeposito',
                            'labaRugiProperti',
                            'hutangProperti',
                            'piutangProperti',
                            'sewaProperti'
                        ]
                    }

                    showBorders={true}

                    paging={false}
                    showPageSizeSelector = {false}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 250px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumHasilInvestasi}

                    onToolbarPreparing={this.onToolbarPreparing}
                    onCellPrepared={this.onCellPrepared}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanHasilInvestasiDetail