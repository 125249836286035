import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { months, gender, pesertaAktif } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { formatNumber, formatNumberAum, numberWithCommas } from 'plugin/helper';
import { derToJose } from 'ecdsa-sig-formatter';
import ScrollView from 'devextreme-react/scroll-view';
import TransaksiAccruedInterest from 'components/pages/investasi/settlement/generate-transaksi/transaksi-accrued-interest/index';
import { showLoading } from 'redux/actions/loading';

class ModalDetailTransaksiIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            getUrl: `view-iuran-detil-summaries?iuranId.in=0`
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        
        this.prevTabIndex = 0
        this.PopupRef = React.createRef()
        this.dataGridDetailRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]


        this.TabDataSource = [
            {
                id: 0,
                text: 'Rekap'
            },
            {
                id: 1,
                text: 'Detail'
            }
        ]

        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                }
            },
            {
                // file upload
                dataField: 'produk',
                label: {
                    text: 'Produk',
                }
            },
            {
                dataField:'bulan',
                label:{
                    text: 'Bulan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    placeholder: ' ',
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:'tahun',
                label:{
                    text: 'Tahun',
                },
            },
        
        ]
        
        this.columnsDetail = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'nikPanjang',
                caption: 'NIK Panjang',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Karyawan',
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'dapenPerusahaan',
                caption: 'Dapen Perusahaan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'dapenKaryawan',
                caption: 'Dapen Karyawan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'totalDapen',
                caption: 'Total Dapen',
                format: '#,##0.00',
                alignment: 'right',
                cellRender: (e) => {
                    var data = e.row.data

                    return formatNumberAum((data.dapenKaryawan || 0) + (data.dapenPerusahaan || 0))
                }
            },
            {
                dataField: 'upahPokok',
                caption: 'Upah Pokok Pensiun',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'rapelUpah',
                caption: 'Rapel Upah Pokok Pensiun',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'phdp',
                caption: 'Dasar Perhitungan',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]

        this.formPeserta = [
            {
                dataField: 'pesertaAktif',
                label: {
                    text: 'Peserta Aktif',
                    alignment: 'left',
                },
            },
            {
                dataField: 'iuranNormalPeserta',
                label: {
                    text: 'Iuran Normal Peserta',
                    alignment:'left',
                },
            },
            {
                dataField: 'iuranManfaatLainPeserta',
                label: {
                    text: 'Iuran Manfaat Lain Peserta',
                    alignment:'left',
                },
            },
        ]

        this.formPemberiKerja = [
            {
                dataField: 'iuranNormalPk',
                label: {
                    text: 'Iuran Normal',
                    alignment:'left',
                },
            },
            {
                dataField: 'iuranManfaatLainPk',
                label: {
                    text: 'Iuran Manfaat Lain',
                    alignment:'left',
                },
            },
        ]

        this.dataPesertaIuran = []

    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            getUrl: `view-iuran-detil-summaries?iuranId.in=0`
        });
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    retrieveData = async(data) => {
        this.props.store.dispatch(showLoading(true))
        var iuranNormalPeserta = 0
        var iuranManfaatLainPeserta = 0

        var iuranNormalPk = 0
        var iuranManfaatLainPk = 0

        var loadAPIuranDetail = `iuran-details/getByIuranId/${data.id}`
        var getIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')
        this.props.store.dispatch(showLoading(false))

        if(getIuranDetail.length !== 0){
            for(var iuranByType of getIuranDetail){
                switch (iuranByType.iuranTypeId) {
                    case 1:
                        iuranNormalPeserta = iuranNormalPeserta + iuranByType.iuranNominal
                        break;
                    case 2:
                        iuranManfaatLainPeserta = iuranManfaatLainPeserta + iuranByType.iuranNominal
                        break;
                    case 3:
                        iuranNormalPk = iuranNormalPk + iuranByType.iuranNominal
                        break;
                    case 4:
                        iuranManfaatLainPk = iuranManfaatLainPk + iuranByType.iuranNominal
                        break;
                    case 5:
                        iuranManfaatLainPk = iuranManfaatLainPk + iuranByType.iuranNominal
                        break;
                    case 6:
                        iuranManfaatLainPeserta  = iuranManfaatLainPeserta    + iuranByType.iuranNominal
                        break;
                }
            }
        }
        // if(data.iuranByAkun.length > 0){
        //     for(var iuranAkun of data.iuranByAkun){
        //         var loadAPIAkun = `kepersertaan-products/${iuranAkun.akunProdukId}`
        //         var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
                
        //         var loadAPIPeserta = `simple-pesertas/getSimpleById/${dataAkun.pesertaId}`
        //         var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
                
        //         var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${dataPeserta.id}`
        //         var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')
                
        //         var loadAPIuranDetail = `iuran-details/getAllByPesertaAkunProductId/${iuranAkun.akunProdukId}`
        //         var dataIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')

        //         var loadAPIuranType = `iuran-types`
        //         var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranType, 'GET')
                
        //         var dapenPerusahaan = 0
        //         var dapenKaryawan = 0
                
        //         for(var iuranByType of dataIuranDetail){
        //             var findIuranByType = dataIuranTypes.find(val => val.id == iuranByType.iuranTypeId)
        //             if(findIuranByType.iuranPeserta){
        //                 dapenKaryawan = dapenKaryawan + iuranByType.iuranNominal
        //             }else{
        //                 dapenPerusahaan = dapenPerusahaan + iuranByType.iuranNominal
        //             }
        //         }
                
        //         for(var iuranByType of dataIuranDetail){
        //             switch (iuranByType.iuranTypeId) {
        //                 case 1:
        //                     iuranNormalPeserta = iuranNormalPeserta + iuranByType.iuranNominal
        //                     break;
        //                 case 2:
        //                     iuranManfaatLainPeserta = iuranManfaatLainPeserta + iuranByType.iuranNominal
        //                     break;
        //                 case 3:
        //                     iuranNormalPk = iuranNormalPk + iuranByType.iuranNominal
        //                     break;
        //                 case 4:
        //                     iuranManfaatLainPk = iuranManfaatLainPk + iuranByType.iuranNominal
        //                     break;
        //                 case 5:
        //                     iuranManfaatLainPk = iuranManfaatLainPk + iuranByType.iuranNominal
        //                     break;
        //                 case 6:
        //                     iuranManfaatLainPeserta  = iuranManfaatLainPeserta    + iuranByType.iuranNominal
        //                     break;
        //             }
        //         }

        //         var dataPush = {
        //             id: iuranAkun.akunProdukId,
        //             nik : dataPeserta.nik,
        //             nikPanjang: getKepegawaian.nikPanjang,
        //             namaKaryawan : dataPeserta.fullName,
        //             sex : dataPeserta.sex,
        //             tanggalLahir : dataPeserta.birthDate,
        //             noDapen: dataAkun.cardNo || 0,
        //             rapelUpahPokokPensiun: 0,
        //             phdp: formatNumberAum(getKepegawaian.phdp || 0),
        //             dapenPerusahaan: formatNumberAum(dapenPerusahaan || 0),
        //             dapenKaryawan : formatNumberAum(dapenKaryawan || 0),
        //             totalDapen : formatNumberAum((dapenPerusahaan + dapenKaryawan) || 0)
        //         }
        //         DataPesertaIuran.push(dataPush)
        //     }
        //     this.dataPesertaIuran = DataPesertaIuran
            
            
        // }
        this.setState({
            dataMaster: {
                ...data,
                pesertaAktif: numberWithCommas(data.pesertaAktif),
                iuranNormalPeserta : formatNumberAum(iuranNormalPeserta),
                iuranManfaatLainPeserta : formatNumberAum(iuranManfaatLainPeserta),
                iuranNormalPk : formatNumberAum(iuranNormalPk),
                iuranManfaatLainPk : formatNumberAum(iuranManfaatLainPk),
            },
            getUrl : `view-iuran-detil-summaries?iuranId.in=${data.id}`,
        })
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    forceRefresh = () => {
        this.dataGridDetailRef.current.forceRefresh(true)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Transaksi Iuran'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <Form
                        colCount={4}
                        id={'formPemberiKerja'}
                        formData={this.state.dataMaster}
                        items={this.filterItem}
                        labelLocation = {'left'}
                        readOnly = {true}
                    />
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                    <div id="tabindex-0" className={' mt-2 text-center' } >
                        <div className="row">
                            <div className="col-md-6" style={{paddingLeft:100, paddingRight:100}}>
                                <h5 className="text-center" style={{fontWeight:"bold"}}>Iuran Peserta</h5>
                                <Form
                                    colCount={1}
                                    id={'formPeserta'}
                                    formData={this.state.dataMaster}
                                    items={this.formPeserta}
                                    labelLocation="left"
                                    readOnly = {true}
                                />
                            </div>
                            <div className="col-md-6" style={{paddingLeft:100, paddingRight:100}}>
                                <h5 className="text-center" style={{fontWeight:"bold"}}>Iuran Pemberi Kerja</h5>
                                <Form
                                    colCount={1}
                                    id={'formIuranPemberiKerja'}
                                    formData={this.state.dataMaster}
                                    items={this.formPemberiKerja}
                                    labelLocation="left"
                                    readOnly = {true}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="tabindex-1" className={'d-none mt-2 text-center' } >
                        <DevExpressDataGrid
                            ref = {this.dataGridDetailRef}
                            loadAPI={this.state.getUrl}
                            insertAPI='data-details'
                            updateAPI='data-details'
                            deleteAPI='data-details' 

                            backendserver={process.env.REACT_APP_BACKEND_CORE}
                            remoteOperations = {false}

                            // useArraySource = {true}
                            // ArraySourceData = {this.dataPesertaIuran}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={true}
                            exportFileName={"Data Detail"}
                            allowExportSelectedData={true}
                            selection={"multiple"}
                            
                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Data Detail'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columnsDetail} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}

                            height = {'calc(100vh - 350px)'}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalDetailTransaksiIuran