import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { currency } from 'dataSource/lookup'
import { ThemeProvider } from 'styled-components'

class ModalListAsetProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Instrument',
            },
            {
                dataField: 'tipe',
                caption: 'Tipe',
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
        ]
    }
    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data = null) => {    
        this.setState({
            dataMaster: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Daftar Aset Sewa Properti'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI='penyewaan-invoices'
                                insertAPI='penyewaan-invoices'
                                updateAPI='penyewaan-invoices'
                                deleteAPI='penyewaan-invoices' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                useArraySource = {true}
                                ArraySourceData = {[]}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"List Aset Sewa Properti"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={450}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'List Aset Sewa Properti'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalListAsetProperti