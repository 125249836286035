import React, { Component } from 'react'
import KinerjaInvestasi from './kinerjaInvestasi'
import KlaimMp from './klaimMp'
import KomposisiInvestasi from './komposisiInvestasi'
import MonitoringTugas from './monitoringTugas'
import PengajuanMp from './pengajuanMp'
import PertumbuhanPeserta from './pertumbuhanPeserta'
import PortofolioInvestasi from './portofolioInvestasi'
import ProsesPensiun from './prosesPensiun'
import RealisasiAnggaran from './realisasiAnggaran'
import RoaProdukInvestasi from './roaProdukInvestasi'
import MonitoringKebijakanInves from './monitoringKebijakanInves'
import Solvabilitas from './solvabilitas'

class Dashboard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="container-fluid overflow-auto h-100">
                <div className="row mb-4">
                    {/* Monitoring Tugas */}
                    <div className="col-4">
                        <div className="accordion h-100" id="monitoringTugas">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerMonitoringTugas" data-toggle="collapse" data-target="#contentMonitoringTugas" aria-expanded="true" aria-controls="contentMonitoringTugas">
                                    <span>
                                        Monitoring Tugas
                                    </span>
                                </div>
                                <div id="contentMonitoringTugas" className="collapse show h-100" aria-labelledby="headerMonitoringTugas" data-parent="#monitoringTugas">
                                    <div className="card-body">
                                        <MonitoringTugas store={this.props.store} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="row mb-4">
                            {/* Portofolio Investasi */}
                            <div className="col-6">
                                <div className="accordion h-100" id="PortofolioInvestasi">
                                    <div className="card border-bottom">
                                        <div className="card-header py-2 px-3" id="headerPortofolioInvestasi" data-toggle="collapse" data-target="#contentPortofolioInvestasi" aria-expanded="true" aria-controls="contentPortofolioInvestasi">
                                            <span>
                                                Portofolio Investasi
                                            </span>
                                        </div>
                                        <div id="contentPortofolioInvestasi" className="collapse show h-100" aria-labelledby="headerPortofolioInvestasi" data-parent="#PortofolioInvestasi">
                                            <div className="card-body">
                                                <PortofolioInvestasi store={this.props.store} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Komposisi Investasi */}
                            <div className="col-6">
                                <div className="accordion h-100" id="KomposisiInvestasi">
                                    <div className="card border-bottom">
                                        <div className="card-header py-2 px-3" id="headerKomposisiInvestasi" data-toggle="collapse" data-target="#contentKomposisiInvestasi" aria-expanded="true" aria-controls="contentKomposisiInvestasi">
                                            <span>
                                                Komposisi Investasi
                                            </span>
                                        </div>
                                        <div id="contentKomposisiInvestasi" className="collapse show h-100" aria-labelledby="headerKomposisiInvestasi" data-parent="#KomposisiInvestasi">
                                            <div className="card-body">
                                                <KomposisiInvestasi store={this.props.store} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Pertumbuhan Investasi */}
                            <div className="col-12">
                                <div className="accordion h-100" id="PertumbuhanInvestasi">
                                    <div className="card border-bottom">
                                        <div className="card-header py-2 px-3" id="headerPertumbuhanInvestasi" data-toggle="collapse" data-target="#contentPertumbuhanInvestasi" aria-expanded="true" aria-controls="contentPertumbuhanInvestasi">
                                            <span>
                                                Kinerja Investasi
                                            </span>
                                        </div>
                                        <div id="contentPertumbuhanInvestasi" className="collapse show h-100" aria-labelledby="headerPertumbuhanInvestasi" data-parent="#PertumbuhanInvestasi">
                                            <div className="card-body">
                                                <KinerjaInvestasi store={this.props.store} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    {/* Pertumbuhan Peserta */}
                    <div className="col-4">
                        <div className="accordion h-100" id="PertumbuhanPeserta">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerPertumbuhanPeserta" data-toggle="collapse" data-target="#contentPertumbuhanPeserta" aria-expanded="true" aria-controls="contentPertumbuhanPeserta">
                                    <span>
                                        Pertumbuhan Peserta
                                    </span>
                                </div>
                                <div id="contentPertumbuhanPeserta" className="collapse show h-100" aria-labelledby="headerPertumbuhanPeserta" data-parent="#PertumbuhanPeserta">
                                    <div className="card-body">
                                        <PertumbuhanPeserta store={this.props.store} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="accordion h-100" id="PertumbuhanInvestasi">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerPertumbuhanInvestasi" data-toggle="collapse" data-target="#contentPertumbuhanInvestasi" aria-expanded="true" aria-controls="contentPertumbuhanInvestasi">
                                    <span>
                                        ROA Produk Investasi
                                    </span>
                                </div>
                                <div id="contentPertumbuhanInvestasi" className="collapse show h-100" aria-labelledby="headerPertumbuhanInvestasi" data-parent="#PertumbuhanInvestasi">
                                    <div className="card-body">
                                        <RoaProdukInvestasi store={this.props.store} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Pengajuan MP */}
                    {/* <div className="col-4">
                        <div className="accordion h-100" id="PengajuanMP">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerPengajuanMP" data-toggle="collapse" data-target="#contentPengajuanMP" aria-expanded="true" aria-controls="contentPengajuanMP">
                                    <span>
                                    Pengajuan MP
                                    </span>
                                </div>
                                <div id="contentPengajuanMP" className="collapse show h-100" aria-labelledby="headerPengajuanMP" data-parent="#PengajuanMP">
                                    <div className="card-body">
                                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row mb-4">

                    {/* Monitoring Tugas */}
                    <div className="col-4">
                        <div className="accordion h-100" id="ProsesPensiun">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerProsesPensiun" data-target="#contentProsesPensiun" aria-expanded="true" aria-controls="contentProsesPensiun">
                                    <span>
                                        Monitoring Kebijakan Investasi
                                    </span>
                                </div>
                                <div id="contentProsesPensiun" className="collapse show h-100" aria-labelledby="headerProsesPensiun" data-parent="#ProsesPensiun">
                                    <div className="card-body">
                                        <MonitoringKebijakanInves store={this.props.store} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Realisasi Anggaran */}
                    <div className="col-8">
                        <div className="accordion h-100" id="RealisasiAnggaran">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerRealisasiAnggaran" data-toggle="collapse" data-target="#contentRealisasiAnggaran" aria-expanded="true" aria-controls="contentPertumbuhanPeserta">
                                    <span>
                                        Realisasi Anggaran
                                    </span>
                                </div>
                                <div id="contentPertumbuhanPeserta" className="collapse show h-100" aria-labelledby="headerRealisasiAnggaran" data-parent="#RealisasiAnggaran">
                                    <div className="card-body">
                                        <RealisasiAnggaran store={this.props.store} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">

                    {/* Pengajuan MP */}
                    <div className="col-4">
                        <div className="accordion h-100" id="Klaim">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerKlaim" data-toggle="collapse" data-target="#contentKlaim" aria-expanded="true" aria-controls="contentKlaim">
                                    <span>
                                        Pengajuan MP
                                    </span>
                                </div>
                                <div id="contentKlaim" className="collapse show h-100" aria-labelledby="headerKlaim" data-parent="#Klaim">
                                    <div className="card-body">
                                        <PengajuanMp store={this.props.store} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Proses Pensiun */}
                    <div className="col-8">
                        <div className="accordion h-100" id="ProsesPensiun">
                            <div className="card border-bottom">
                                <div className="card-header py-2 px-3" id="headerProsesPensiun" data-target="#contentProsesPensiun" aria-expanded="true" aria-controls="contentProsesPensiun">
                                    <span>
                                        Proses Pensiun
                                    </span>
                                </div>
                                <div id="contentProsesPensiun" className="collapse show h-100" aria-labelledby="headerProsesPensiun" data-parent="#ProsesPensiun">
                                    <div className="card-body">
                                        <ProsesPensiun store={this.props.store} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard