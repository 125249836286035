import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import uuidv4  from 'uuid/v4'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { currency, grade } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';

class FormModalPenyesuaianGaji extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
            popupVisible: false,
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        
        this.form = [
            {
                dataField: 'nik',
                label: {
                    text: 'NIK'
                }
            },
            {
                dataField: 'karyawan',
                label: {
                    text: 'Karyawan',
                },
            },
            {
                dataField: 'gradeId',
                label: {
                    text: 'Grade'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: grade(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'gradeName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'noSk',
                label: {
                    text: 'No. SK'
                }
            },
            {
                dataField: 'tanggalSk',
                label: {
                    text: 'Tanggal SK'
                },
                editorType: 'dxDateBox',
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true
                }
            },
            {
                dataField: 'tanggalBerlaku',
                label: {
                    text: 'Tanggal Berlaku'
                },
                editorType: 'dxDateBox',
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true
                }
            },
            {
                dataField: 'nominalGajiPokok',
                label: {
                    text: 'Nominal Gaji Pokok'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'insentif',
                label: {
                    text: 'Insentif'
                }
            },
            {
                dataField: 'currency',
                label: {
                    text: 'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                    searchEnabled: true,
                    deferRendering: false,
                }
            }
        ]

        this.columnsTunjangan = [
            {
                dataField: 'tunjangan',
                caption: 'Tunjangan',
            },
            {
                dataField: 'nominalTunjangan',
                caption: 'Nominal Tunjangan',
                alignment:'right'
            },
        ]

        this.columnsPotongan = [
            {
                dataField: 'potongan',
                caption: 'Potongan',
            },
            {
                dataField: 'nominalPotongan',
                caption: 'Nominal Potongan',
                alignment:'right'
            },
            {
                dataField: 'noPinjaman',
                caption: 'No Pinjaman',
                alignment:'right'
            },
        ]
    }   
    
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    submitData = () => {
    
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = async(data) => {  
        console.log(data);  
        this.setState({
            DataMaster: data,
        })
    }

    loadDummyDataTunjangan = () => {
        var data = [
            {
                id: uuidv4(),
            }
        ]

        return data
    }

    loadDummyDataPotongan = () => {
        var data = [
            {
                id: uuidv4(),
            }
        ]

        return data
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Penyesuaian Gaji"}
                width={700}
                height={"calc(100vh - 50px)"}
                toolbarItems={this.PopupToolbarItem}
                >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formDataGaji'}
                        formData={this.state.DataMaster}
                        items={this.form}
                        labelLocation = 'left'
                    />
                    <div className='my-3'>
                        <h5>Daftar Tunjangan</h5>
                        <DevExpressDataGrid
                            ref = {this.dataGridRef}
                            loadAPI='daftar-pinjaman-karyawans'
                            insertAPI='daftar-pinjaman-karyawans'
                            updateAPI='daftar-pinjaman-karyawans'
                            deleteAPI='daftar-pinjaman-karyawans' 

                            backendserver={process.env.REACT_APP_BACKEND_CORE}
                            useArraySource={true}
                            ArraySourceData={this.loadDummyDataTunjangan}

                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}

                            exportExcel={true}
                            exportFileName={"Daftar Tunjangan Karyawan"}
                            allowExportSelectedData={true}
                            selection={"multiple"}
                            
                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}
                            height={400}
                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Daftar Tunjangan Karyawan'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columnsTunjangan} // taruh konfigurasi kolom disini

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                    <div className='my-3'>
                        <h5>Daftar Potongan</h5>
                        <DevExpressDataGrid
                            ref = {this.dataGridRef}
                            loadAPI='daftar-pinjaman-karyawans'
                            insertAPI='daftar-pinjaman-karyawans'
                            updateAPI='daftar-pinjaman-karyawans'
                            deleteAPI='daftar-pinjaman-karyawans' 

                            backendserver={process.env.REACT_APP_BACKEND_CORE}
                            useArraySource={true}
                            ArraySourceData={this.loadDummyDataPotongan}

                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}

                            exportExcel={true}
                            exportFileName={"Daftar Potongan Karyawan"}
                            allowExportSelectedData={true}
                            selection={"multiple"}
                            
                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}
                            height={400}
                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Daftar Potongan Karyawan'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columnsPotongan} // taruh konfigurasi kolom disini

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                    </div>
                    </ScrollView>
                </Popup>
        )
    }
}

export default FormModalPenyesuaianGaji