import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import ModalPortfolioRebalance from 'components/pages/modal/investasi/portfolio/rebalance/index';
import ModalCorporateActionSchedule from 'components/pages/modal/investasi/corporate-action/schedule/index';
import ModalFileCorporateActionSchedule from 'components/pages/modal/investasi/corporate-action/schedule/file';
import { addURL } from 'redux/actions/url';

class CorporateSchedule extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }

        this.modalAlokasiRef = React.createRef()
        this.modalCorporateActionScheduleRef = React.createRef()
        this.modalFileCorporateActionScheduleRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalCorporateActionSchedule('detail', e.row.data)
                        }
                    },
                    {
                        text : 'File',
                        hint : 'File',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModaFilelCorporateActionSchedule(e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'saham',
                caption: 'Saham',
            },
            {
                dataField: 'jenisCorpact',
                caption: 'Jenis Corpact',
            },
            {
                dataField: 'cumDate',
                caption: 'Cum Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'exDate',
                caption: 'Ex Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'recordingDate',
                caption: 'Recording Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'distributionDate',
                caption: 'Distribution Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    loadDummyData = () => {
        var dummy = [
            {
                id: 1,
                saham: 'PPMP',
                jenisCorpact: 'Swakelola',
                efek: 'Telah Di Alokasi',
                cumDate: '2020-07-25',
                exDate: '2020-07-25',
                recordingDate: '2020-07-25',
                distributionDate: '2020-07-25',
            },
        ]


        return dummy
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Jadwal',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalCorporateActionSchedule('add')
                    },
                }
            }
        )
    }

    showModalCorporateActionSchedule = (type,data = null) => {
        this.modalCorporateActionScheduleRef.current.show()
        this.modalCorporateActionScheduleRef.current.retrieveData(type, data)
    }
    showModaFilelCorporateActionSchedule = (data = null) => {
        this.modalCorporateActionScheduleRef.current.show()
        this.modalCorporateActionScheduleRef.current.retrieveData(data)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Corporate Action Schedule</h2>
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='corpact-schedules'
                    insertAPI='corpact-schedules'
                    updateAPI='corpact-schedules'
                    deleteAPI='corpact-schedules' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Corporate Action Schedule"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Corporate Action Schedule'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalCorporateActionSchedule 
                    ref={this.modalCorporateActionScheduleRef}
                    store={this.props.store}
                />

                <ModalFileCorporateActionSchedule 
                    ref={this.modalFileCorporateActionScheduleRef}
                    store={this.props.store}
                />
                
            </div>
        )
    }
}

export default CorporateSchedule