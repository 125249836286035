import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, programs, statusPembayaranRekapGaji, statusRekruitmen } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import notify from "devextreme/ui/notify";

import ModalKandidatRekruitmen from 'components/pages/modal/sdm-umum/kepegawaian/rekruitmen/aktif/kandidat/kandidat';
import ModalBiayaRekruitmen from 'components/pages/modal/sdm-umum/kepegawaian/rekruitmen/aktif/biaya/biaya';
import ModalProbisiRekruitmen from 'components/pages/modal/sdm-umum/kepegawaian/rekruitmen/aktif/probisi/probisi';

class RiwayatRekruitmen extends Component {
    constructor(props){
        super(props)

        this.dataGridRef = React.createRef()
        this.modalAddRef = React.createRef()
        this.modalEditRef = React.createRef()
        
        this.showModalKandidatReff = React.createRef()
        this.showModalBiayaReff = React.createRef()
        this.showModalProbisiReff = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'List Kandidat',
                        hint : 'List Kandidat',
                        onClick : (e) => {
                            this.showModalKandidat(e.row.data)
                        }
                    },
                    {
                        text : 'List Biaya Rekruitmen',
                        hint : 'List Biaya Rekruitmen',
                        onClick : (e) => {
                            this.showModalBiaya(e.row.data)
                        }
                    },
                    {
                        text : 'List Probisi',
                        hint : 'List Probisi',
                        onClick : (e) => {
                            this.showModalProbisi(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'kodeRekurtmen',
                caption: 'Kode Rekruitmen'
            },
            {
                dataField: 'headHunter',
                caption: 'Head Hunter'
            },
            {
                dataField: 'tanggalPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'jumlahUnit',
                caption: 'Jumlah Unit'
            },
            {
                dataField: 'jumlahPersonal',
                caption: 'Jumlah Personal'
            },
            {
                dataField: 'tglPublikasi',
                caption: 'Tanggal Publikasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tanggalPublikasiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalPublikasiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                dataField: 'tglPendaftaraan',
                caption: 'Tanggal Pendaftaraan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tanggalAdministrasiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalAdministrasiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                dataField: 'tglSeleksi',
                caption: 'Tanggal Seleksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tanggalSeleksiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalSeleksiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                dataField: 'tglProbisi',
                caption: 'Tanggal Probisi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tanggalProbisiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalProbisiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                dataField: 'tanggalPengangkatan',
                caption: 'Tanggal Pengangkatan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup:{
                    dataSource: statusRekruitmen(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                }
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    
    showModalKandidat = (data) => {
        this.showModalKandidatReff.current.show()
        this.showModalKandidatReff.current.retrieveData(data)
    }

    showModalBiaya = (data) => {
        this.showModalBiayaReff.current.show()
        this.showModalBiayaReff.current.retrieveData(data)
    }
    
    showModalProbisi = (data) => {
        this.showModalProbisiReff.current.show()
        this.showModalProbisiReff.current.retrieveData(data)
    }


    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  onClick: (e) => {
                    // this.showModalAdd(e)
                  },
              }
          }
        )
    }


    loadData = async() => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'rekurtmen/getByStatusId/3', 'GET')
        return [{id:1}]
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Riwayat Rekruitmen Karyawan</h3>
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-daftar-list-karyawan-rapels'
                    insertAPI='pengajuan-rapel'
                    updateAPI='pengajuan-rapel'
                    deleteAPI='pengajuan-rapel' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Rapel"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Rapel'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalKandidatRekruitmen  
                    ref={this.showModalKandidatReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalBiayaRekruitmen
                    ref={this.showModalBiayaReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalProbisiRekruitmen
                    ref={this.showModalProbisiReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default RiwayatRekruitmen