import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'

class MonitoringKebijakanInves extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                dataField: 'no',
                caption: 'No',
                alignment: 'center',
                cellRender: (e) => {
                    return e.rowIndex + 1
                },
                width: "50px"
            },
            {
                dataField: 'categoryName',
                caption: 'Portofolio',
            },
            {
                dataField: 'persenMax',
                caption: 'Maksimal per Jenis',
                alignment: 'right',
                dataType: 'number',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.persenMax >= 0) {
                        return <span style={{ color: `green` }}>{e.text + ' %'}</span>
                    } else {
                        return <span style={{ color: `red` }}>{e.text + ' %'}</span>
                    }
                }
            },
            {
                dataField: 'komposisiPercent',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'number',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.komposisiPercent >= 0) {
                        return <span style={{ color: `green` }}>{e.text + ' %'} </span>
                    } else {
                        return <span style={{ color: `red` }}>{e.text + ' %'}</span>
                    }
                }
            },
            {
                dataField: 'selisih',
                caption: 'Selisih(%)',
                alignment: 'right',
                dataType: 'number',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.selisih >= 0) {
                        return <span style={{ color: `green` }}>{e.text + ' %'}</span>
                    } else {
                        return <span style={{ color: `red` }}>{e.text + ' %'}</span>
                    }
                }
            },
        ]
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-monitoring-kebijakan-investasis?size=9999`, 'GET')
        return response
    }

    render() {
        return (
            <div className='container-fluid'>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`-`}
                    insertAPI=''
                    updateAPI=''
                    deleteAPI=''

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    height={'auto'}

                    exportExcel={false}
                    exportFileName={"Monitoring Kebijakan Investasi"}
                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={5}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Monitoring Kebijakan Investasi'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default MonitoringKebijakanInves