import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager, emitens, currency, rekeningBank } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";


class ModalPenyertaanIpoSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.formItem = [
            {
                dataField: 'requestNo',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'emitenId',
                label: {
                    text: 'Emiten'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: emitens(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.emitenCode + " - " + item.emitenName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harap memilih Emiten terlebih dahulu"
                    }
                ]
            },
            {
                dataField: 'nominalPenyertaan',
                label: {
                    text: 'Nominal Penyertaan'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0"
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harap mengisi Nominal Penyertaan terlebih dahulu"
                    }
                ]
            },
            {
                dataField: 'brokerId',
                label: {
                    text: 'Broker'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.brokerCode + " - " + item.brokerName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harap memilih Broker terlebih dahulu"
                    }
                ]
            },
            {
                dataField: 'etfMarket',
                label: {
                    text: 'Pasar'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'PRIMER',
                            value: 'Primer',
                        },
                        {
                            id: 'SEKUNDER',
                            value: 'Sekunder',
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    deferRendering: false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harap memilih Pasar terlebih dahulu"
                    }
                ]
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Mata Uang'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.currencyCode + " - " + item.currencyName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: (e) => {
                        var data = e.selectedItem
                        if (data) {
                            this.formMasterRef.current.instance.getEditor('kurs').option('value', data.rate)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harap memilih Mata Uang terlebih dahulu"
                    }
                ]
            },
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true,
                },
            },
            {
                dataField: 'rekBankId',
                label: {
                    text: 'Rekening Pembayaran'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.rekNo + " - " + item.rekName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harap memilih Mata Uang terlebih dahulu"
                    }
                ]
            },
            {
                dataField: 'catatan',
                label: {
                    text: 'Catatan'
                },
                editorType: 'dxTextArea',
            }
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    show () {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data) => {
        if(data){
            var instrument = {}, emiten = {}
            if (data.instrumentId) {
                instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`)
    
                emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `emitens/${instrument.emitenId}`)
            }
    
            this.setState({
                popupVisible: true,
                selectedTabIndex: 0,
                data: {
                    id: data.id,
                    brokerId: data.brokerId,
                    currencyId: data.currencyId,
                    emitenId: data.emitenId,
                    etfMarket: data.etfMarket,
                    instrumentId: data.instrumentId,
                    kurs: data.kurs,
                    requestNo: data.requestNo,
                    nominalPenyertaan: data.nominalPenyertaan,
                    rekBankId: data.rekBankId,
                    catatan: data.notes
                },
            });
        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.data
        
        this.props.submitDetailPenempatanData({
            id: data.id,
            brokerId: data.brokerId,
            currencyId: data.currencyId,
            emitenId: data.emitenId,
            etfMarket: data.etfMarket,
            instrumentId: data.instrumentId,
            kurs: data.kurs,
            nominalPenyertaan: data.nominalPenyertaan,
            rekBankId: data.rekBankId,
            notes: data.catatan
        })

        this.hide()
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Pengajuan Penyertaan IPO Saham`}
                width={'50vw'}
                height="70vh"

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'form'}
                                formData={this.state.data}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={this.props.type == 'view' ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalPenyertaanIpoSaham