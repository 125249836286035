import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import { Form, Button, FileUploader } from 'devextreme-react';
import { gender, participants, months } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { ab2str, cleanQuote, joinDuplicateData } from 'plugin/helper';
import XLSX from 'xlsx'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import { showLoading } from 'redux/actions/loading';
import { alert,confirm } from 'devextreme/ui/dialog';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class MatchingProses extends Component {
    constructor(props){
        super(props)

        this.state = {
            redirectBack: false,
            successData: [],
            errorData: [],
            uploadedData: [],
            uploadVisible: false,
            autoUpdateVisible: false,
            // selectedTabIndex: 0,
            matchingProcessFile: '',
            useHeader:true,
            filterItem:{},
            getUrl: `view-iuran-detil-summaries?bulan.in=0&pemberiKerjaId.in=0&tahun.in=0`
        }

        this.processData = []
        this.successData = []
        this.uploadData = []
        this.errorData = []
        this.dataIdMaster = 0

        this.prevTabIndex = 0

        this.TabDataSource = [
            {
                id: 0,
                text: "Data Proses"
            },
            {
                id: 1,
                text: "Data Sesuai"
            },
            {
                id: 2,
                text: "Data Tidak Sesuai"
            },
        ];

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'nikPanjang',
                caption: 'NIK Panjang'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Karyawan'
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup:{
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy'
            },
            // {
            //     dataField: 'cardNo',
            //     caption: 'No Dapen'
            // },
            {
                dataField: 'phdp',
                caption: 'Upah Pokok Pensiun',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'rapelUpahPokokPensiun',
                caption: 'Rapel Upah Pokok Pensiun',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'phdp',
                caption: 'Dasar Perhitungan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'iuranManfaatLainPk',
                caption: 'Iuran Manfaat Lain PK',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'iuranNormalPk',
                caption: 'Iuran Normal PK',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'iuranNormalPeserta',
                caption: 'Iuran Normal Peserta',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'totalDapen',
                caption: 'Total Dapen',
                format: '#,##0.00',
                alignment: 'right'
            },
            // {
            //     dataField: 'statusIuran',
            //     caption: 'Status Iuran'
            // },
        ]

        this.filterItem = [
            {
                dataField: 'participantId',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    // onValueChanged: (data) => {
                    //     this.dataGridSesuaiRef.current.forceRefresh()
                    //     this.dataGridTakSesuaiRef.forceRefresh()
                    // }
                },
                isRequired: true
            },
            {
                dataField: 'month',
                label: {
                    text: 'Bulan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
                isRequired: true
            },
            {
                dataField: 'tahun',
                label: {
                    text: 'Tahun',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    value: new Date().getFullYear(),
                    // readOnly: true
                },
                isRequired: true
            },
            {
                dataField: 'tahap',
                label: {
                    text: 'Tahap',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    value: 1,
                    // readOnly: true
                },
                isRequired: true
            },
            {
                dataField:'proses',
                label:{
                    visible: false,
                },
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Proses',
                    onClick: async(e) => {
                        if(this.formFilterRef.current.instance.validate().isValid){
                            await this.filterData()
                        }
                    }
                }
            },
        ]

        this.formFilterRef = React.createRef()
        this.fileUploderRef = React.createRef()
        this.dataGridProcessRef = React.createRef()
        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()
    }
    
    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var loadAPIuranTypes = `iuran-types`
        var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

        this.iuranType = dataIuranTypes
    } 
   
    filterData = async() => {
        var data = this.state.filterItem
        var dataIdMasterSuccess
        var processData = []
        try {
            // var loadAPI = `iurans/getByPkAndBulanAndTahun/${data.participantId}/${data.month}/${data.tahun}/1/1`
            var loadAPI = `iurans/getByBulanAndTahunAndTahap/${data.month}/${data.tahun}/${data.tahap}/1/1`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
            if(response.length !== 0){
                for(var value of response){
                    dataIdMasterSuccess = value.id
                }
                this.dataIdMaster = dataIdMasterSuccess
                this.setState({
                    uploadVisible: true,
                    getUrl: `view-iuran-detil-summary-by-types?bulan.in=${data.month}&pemberiKerjaId.in=${data.participantId}&tahun.in=${data.tahun}&tahap.in=${data.tahap}`
                })
            }else{
                var text = 'Data Periode Ini Kosong atau telah di proses!'
                notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);    
            }
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }
    prosesData = async() => {
        let store = this.props.store
        let dataProses = this.dataGridProcessRef.current.getDataSource();
        let dataSesuai = this.dataGridSesuaiRef.current.getDataSource();
  
        if (dataProses.length !== 0 && dataSesuai.length !== 0) {
            confirmAlert({
                title: 'Proses Data',
                message: 'Apakah anda yakin ingin memproses data? pastikan file upload terlebih dahulu.',
                closeOnClickOutside: false,
                buttons: [
                  {
                    label: 'Proses',
                    onClick: async() => {
                        if(this.dataIdMaster.length !== 0){
                            store.dispatch(showLoading(true))
                            var dataTidakSesuai = this.dataGridTakSesuaiRef.current.getDataSource()
                            var dataSesuai = this.dataGridSesuaiRef.current.getDataSource();
                            if(dataTidakSesuai.length != 0){
                                var loadAPIuranDetail = `iuran-details/getByIuranId/${dataTidakSesuai[0].iuranId}`
                                var getIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')
                                
                                var loadAPIuranByTypes = `iuran-by-types/getByIuranId/${dataTidakSesuai[0].iuranId}`
                                var dataIuranByTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranByTypes, 'GET')
                                for(var iuranDetilGagal of dataTidakSesuai){
                                    var dataIuranDetail = getIuranDetail.filter(val => val.pesertaAkunProductId == iuranDetilGagal.akunProdukId)
                                    if(Math.abs(iuranDetilGagal.phdp - iuranDetilGagal.phdpExcel) > 2){
                                        // UPDATE PHDP KEPEGAWAIAN
                                        var loadAPIGetKepegawaian = `peserta-kepegawaians/getByPesertaId/${iuranDetilGagal.pesertaId}`
                                        var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIGetKepegawaian, 'GET')
                                        getKepegawaian.phdp = iuranDetilGagal.phdpExcel
    
                                        var updateAPIKepegawaian = 'peserta-kepegawaians'
                                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPIKepegawaian, 'PUT', {
                                            key: getKepegawaian.id,
                                            values: getKepegawaian
                                        },getKepegawaian)
                                    }
                                    for(var iuranDetil of dataIuranDetail){
                                        if(!iuranDetilGagal.iuranNormalPesertaExcel && !iuranDetilGagal.iuranNormalPkExcel && !iuranDetilGagal.iuranManfaatLainPkExcel){
                                            // UPDATE STATUS PESERTA
                                            var loadAPIGetPeserta = `pesertas/${iuranDetilGagal.pesertaId}`
                                            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIGetPeserta, 'GET')
                                            getPeserta.statusPeserta = 3
                                            var updateAPIPeserta = 'pesertas'
                                            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPIPeserta, 'PUT', {
                                                key: getPeserta.id,
                                                values: getPeserta
                                            },getPeserta)
    
                                            // UPDATE STATUS KEPESERTAAN PRODUCT
                                            var loadAPIGetAkun = `kepersertaan-products/${iuranDetilGagal.akunProdukId}`
                                            var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIGetAkun, 'GET')
                                            getAkun.akunStatusId = 6
    
                                            var updateAPIPeserta = 'kepersertaan-products'
                                            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPIPeserta, 'PUT', {
                                                key: getAkun.id,
                                                values: getAkun
                                            },getAkun)
    
                                            var deleteAPIIuranDetail = 'iuran-details'
                                            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, deleteAPIIuranDetail, 'DELETE',iuranDetil.id)
                                            
                                        }else{
                                            var selisih = false
                                            switch (iuranDetil.iuranTypeId) {
                                                case 1:
                                                    if(iuranDetil.iuranNominal !== iuranDetilGagal.iuranNormalPesertaExcel){
                                                        iuranDetil.iuranNominal = iuranDetilGagal.iuranNormalPesertaExcel
                                                        selisih = true
                                                        iuranDetil.status = 3
                                                    }
                                                    break;
                                                case 3:
                                                    if(iuranDetil.iuranNominal !== iuranDetilGagal.iuranNormalPkExcel){
                                                        iuranDetil.iuranNominal = iuranDetilGagal.iuranNormalPkExcel
                                                        selisih = true
                                                        iuranDetil.status = 3
                                                    }
                                                    break;
                                                case 4:
                                                    if(iuranDetil.iuranNominal !== iuranDetilGagal.iuranManfaatLainPkExcel){
                                                        iuranDetil.iuranNominal = iuranDetilGagal.iuranManfaatLainPkExcel
                                                        selisih = true
                                                        iuranDetil.status = 3
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
    
                                            if(selisih){
                                                var updateAPIIuranDetails = 'iuran-details'
                                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPIIuranDetails, 'PUT', {
                                                    key: iuranDetil.id,
                                                    values: iuranDetil
                                                },iuranDetil)
                                            }
                                        }
                                    }
                                }
                            } else {
                                var loadAPIuranByTypes = `iuran-by-types/getByIuranId/${dataSesuai[0].iuranId}`
                                var dataIuranByTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranByTypes, 'GET')
                            }
    
    
                            // if (dataSesuai.length !== 0) {
                            //     // var loadAPIuranDetail = `iuran-details/getByIuranId/${dataSesuai[0].iuranId}`
                            //     // var getIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')
    
                            //     var loadAPIuranByTypes = `iuran-by-types/getByIuranId/${dataSesuai[0].iuranId}`
                            //     var dataIuranByTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranByTypes, 'GET')
                            // }
                            
                            var updateAPIIuranByType = 'iuran-by-types'
                            var dataIuranByType1 = dataIuranByTypes.find(val => val.iuranTypeId == 1);
                            var getSummaryType1 = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `iuran-details/sumIuranNominalByIuranTypeId/${this.dataIdMaster}/1`, 'GET')
                            if(dataIuranByType1){
                                dataIuranByType1.iuranNominal = getSummaryType1
                                dataIuranByType1.iuranOutstanding = getSummaryType1
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPIIuranByType, 'PUT', {
                                    key: dataIuranByType1.id,
                                    values: dataIuranByType1
                                },dataIuranByType1)
                            }
    
                            var dataIuranByType3 = dataIuranByTypes.find(val => val.iuranTypeId == 3);
                            var getSummaryType3 = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `iuran-details/sumIuranNominalByIuranTypeId/${this.dataIdMaster}/3`, 'GET')
                            if(dataIuranByType3){
                                dataIuranByType3.iuranNominal = getSummaryType3
                                dataIuranByType3.iuranOutstanding = getSummaryType3
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPIIuranByType, 'PUT', {
                                    key: dataIuranByType3.id,
                                    values: dataIuranByType3
                                },dataIuranByType3)
                            }
    
                            var dataIuranByType4 = dataIuranByTypes.find(val => val.iuranTypeId == 4);
                            var getSummaryType4 = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `iuran-details/sumIuranNominalByIuranTypeId/${this.dataIdMaster}/4`, 'GET')
                            if(dataIuranByType4){
                                dataIuranByType4.iuranNominal = getSummaryType4
                                dataIuranByType4.iuranOutstanding = getSummaryType4
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPIIuranByType, 'PUT', {
                                    key: dataIuranByType4.id,
                                    values: dataIuranByType4
                                },dataIuranByType4)
                            }
                            
                            var countIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `iuran-details/countIuranDetilByIuranIdAndIuranTypeId/${this.dataIdMaster}/1`, 'GET')
                            var sumAllSumarry = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `iuran-details/sumIuranNominalByIuranId/${this.dataIdMaster}`, 'GET')
                            var getIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `iurans/${this.dataIdMaster}`, 'GET')
                            getIuran.jumlahAkun = countIuran
                            getIuran.nominalIuran = sumAllSumarry
                            getIuran.receiveOutstanding = getIuran.nominalIuran
    
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'iurans', 'PUT', {
                                key: getIuran.id,
                                values: getIuran
                            },getIuran)
    
                            var value = {
                                idIuran : this.dataIdMaster,
                                listIuranDetailFail: []
                            }
                            try {
                                var loadAPI = 'pesertas/transaksiIuran'
                                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                                    values:value
                                })
                                if(response){
                                    var interval = setInterval(async() => { 
                                        try {
                                            var loadAPI = `iuran-details/getByIuranIdAndStatus/${response.id || response.idIuran}/2%2C3`
                                            var getIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')  
                                            if(getIuran.length !== 0){
                                                clearInterval(interval)
                                                store.dispatch(showLoading(false))
                                                var message = 'Data Berhasil Di Proses!'
                                                var res = await alert(message, 'Berhasil!')
                                                if(res){
                                                    this.props.store.dispatch(addURL(this.props.history.replace('/kepesertaan/iuran-kepesertaan/konfirmasi-iuran/piutang-iuran/pengajuan')));
                                                }
                                            }
                                        } catch (e) {
                                            console.log(e)
                                        }
                    
                                     }, 5000);
                                }
                            } catch (e) {
                                console.log(e)
                                // notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
    
                                var text = 'Data Berhasil Diproses!'
                                var type = "success"
                        
                                store.dispatch(showLoading(false))
                                this.forceRefresh()
                                notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);  
                            }
                        }else{
                            var text = 'Data Kosong!'
                            var type = "error"
                            
                            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);  
                            store.dispatch(showLoading(false))
                        }
                    }
                  },
                  {
                    label: 'Batal',
                    onClick: () => {
                        
                    }
                  },
                ]
            });
        } else {
            var message = 'Data sesuai kosong, silahkan melakukan upload file atau proses auto update terlebih dahulu!'
            await alert(message, 'Gagal!');
        }
    }

    retrieveDataProcess = () => {
        return this.processData
    }
    
    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    combineDataSimple = async(data) => {
        var DataPesertaIuran = []

        var loadAPIIuranByAkun = `iuran-by-akuns/getAllByIuranId/${data.id}`
        var dataIuranByAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIIuranByAkun, 'GET')
        
        if(dataIuranByAkun.length > 0){
            for(var iuranAkun of dataIuranByAkun){
                var loadAPIAkun = `kepersertaan-products/${iuranAkun.pesertaAkunProdukId}`
                var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
    
                var loadAPIPeserta = `simple-pesertas/getSimpleById/${dataAkun.pesertaId}`
                var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
                
                var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${dataPeserta.id}`
                var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')
                
                var loadAPIuranDetail = `iuran-details/getByIuranId/${data.id}`
                var getIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')

                var dataIuranDetail = getIuranDetail.filter(val => val.pesertaAkunProductId == iuranAkun.pesertaAkunProdukId)

                // var loadAPIuranTypes = `iuran-types`
                // var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

                var dataIuranTypes = this.iuranType
                
                var dapenPerusahaan = 0
                var dapenKaryawan = 0
                
                var idIuranDetails = []
                for(var iuranByType of dataIuranDetail){
                    var iuranType = dataIuranTypes.find(value => value.id === iuranByType.iuranTypeId)
                    idIuranDetails.push({
                        iuranByTypeId: iuranByType.id,
                        iuranPeserta: iuranType.iuranPeserta
                    })
                    if(iuranType.iuranPeserta){
                        var dapenKaryawan = dapenKaryawan + iuranByType.iuranNominal
                    }else{
                        var dapenPerusahaan = dapenPerusahaan + iuranByType.iuranNominal
                    }
                }
    
                var dataPush = {
                    id: iuranAkun.id,
                    idIuranDetails: idIuranDetails,
                    nik : dataPeserta.nik,
                    nikPanjang: getKepegawaian.nikPanjang,
                    namaKaryawan : dataPeserta.fullName,
                    sex : dataPeserta.sex,
                    tanggalLahir : dataPeserta.birthDate,
                    noDapen: dataAkun.cardNo,
                    rapelUpahPokokPensiun: 0,
                    phdp: getKepegawaian.phdp,
                    dapenPerusahaan: dapenPerusahaan,
                    dapenKaryawan : dapenKaryawan,
                    totalDapen : dapenPerusahaan + dapenKaryawan,
                    statusIuran : 'Aktif',
                    iuranId: data.id
    
                }
                DataPesertaIuran.push(dataPush)
            }
            // this.dataPesertaIuran = DataPesertaIuran
           return DataPesertaIuran
        }
    }

    compareObject = async(dataUpload,dataSistem) => {
        var errorData = []
        var successData = []
        var result = {}

        var listNikPanjang = joinDuplicateData([...dataUpload.map(value => value.nik),...dataSistem.map(value => value.nik)])
        for(var nik of listNikPanjang){
            var dataUploadNik = dataUpload.find(value => value.nik == nik)
            var dataSistemNik = dataSistem.find(value => value.nik == nik)
            var value = dataSistemNik //|| dataUploadNik
            if(dataUploadNik && dataSistemNik){
                var errorMsg = []
                if(dataUploadNik['phdp'] != dataSistemNik['phdp']){
                    errorMsg.push('Data PHDP tidak sesuai')
                }
                var selisih = Math.abs(dataUploadNik['totalDapen'] - dataSistemNik['totalDapen'])
                if(selisih > 1){
                    errorMsg.push('Data Total Dapen tidak sesuai')
                }
                // for(var validate of validateColumnName){
                //     value[`${validate}Excel`] = dataUploadNik[validate]
                //     if(dataUploadNik[validate] != dataSistemNik[validate]){
                //         var errorPoint
                //         switch (validate) {
                //             case 'phdp':
                //                 errorPoint = 'PHDP'
                //                 break;
                //             case 'dapenKaryawan':
                //                 errorPoint = 'Dapen Karyawan'
                //                 break;
                //             case 'dapenPerusahaan':
                //                 errorPoint = 'Dapen Perusahaan'
                //                 break;
                //             case 'totalDapen':
                //                 errorPoint = 'Total Dapen'
                //                 break;
                        
                //             default:
                //                 errorPoint = ""
                //                 break;
                //         }
                //         errorMsg.push('Data '+ errorPoint +' tidak sesuai')
                //     }
                // }
                value.rapelUpahPokokPensiunExcel = dataUploadNik.rapelUpahPokokPensiun
                if(errorMsg.length > 0){
                    value['phdpExcel'] = dataUploadNik['phdp']
                    value['totalDapenExcel'] = dataUploadNik['totalDapen']
                    value['iuranManfaatLainPkExcel'] = dataUploadNik['iuranManfaatLainPk']
                    value['iuranNormalPkExcel'] = dataUploadNik['iuranNormalPk']
                    value['iuranNormalPesertaExcel'] = dataUploadNik['iuranNormalPeserta']
                    value.errorMessage = errorMsg
                    errorData.push(value)
                }else{
                    successData.push(value)
                }
            }

            if(dataUploadNik && !dataSistemNik){
                if(value){
                    var value
                    value.errorMessage = ['Data tidak di temukan di dalam sistem']
                    value.totalDapenExcel = dataUploadNik.totalDapen
                    errorData.push(value)
                }
            }

            if(!dataUploadNik && dataSistemNik){
                if(value){
                    var value
                    value.errorMessage = ['Data tidak di temukan di dalam file upload']
                    errorData.push(value)
                }
            }
        }

        result = {
            error: errorData,
            success: successData
        }
        return result
    }
    editDataGrid = async (data, type) => {
        var editData = []
        if(type == 'success'){
            var dataGrid = this.dataGridSesuaiRef
        }else if(type == 'proses'){
            var dataGrid = this.dataGridProcessRef
        }else{
            var dataGrid = this.dataGridTakSesuaiRef
        }
        await this.deleteDataGrid(dataGrid)
        for (var value of data){
            try{
                editData.push(
                    {
                        type: 'insert',
                        data : value
                    }
                )
                // if(key){
                //     dataGrid.current.push([
                //         {
                //         type: 'update',
                //         data : value,
                //         key : key
                //         }
                //     ])
                // }else{
                //     dataGrid.current.push([
                //         {
                //             type: 'insert',
                //             data : value
                //         }
                //     ])
                // }
            
            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'
            
            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
            }catch(e){
                console.log(e)
            }
        }
        dataGrid.current.push(editData)
    }
    
    deleteDataGrid = async(dataGrid) => {
        var data = dataGrid.current.getDataSource()
        // var data = this.dataGridProcessRef.current.getDataSource();
        var deleteData = []
        for(var value of data){
            deleteData.push(
                {
                    type: 'remove',
                    key : value.id
                }
            )
        }
        dataGrid.current.push(deleteData)
    }
    onUploadStarted = (e) => {
        let dataFilter = this.state.filterItem;
        let store = this.props.store

        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]
            
            var reader = new FileReader();

            if (reader) {
                store.dispatch(showLoading(true))
                reader.onload = async (e) => {
                    var dataArrayBuffer = new Uint8Array(e.target.result);
                    var dataString = ab2str(dataArrayBuffer)
    
                    var data = dataString.split(/(\n\r)|(\n)|(\r)/g)
    
                    if(fileType === 'xls' || fileType === 'xlsx' ){
                        var workbook = XLSX.read(dataArrayBuffer, {type: 'array'});
                        var sheet = workbook.Sheets[workbook.SheetNames[0]];
    
                        var dataJSON = XLSX.utils.sheet_to_json(sheet)
                        
                        data = dataJSON.map(value => Object.values(value))
                    }
    
                    if(fileType === 'csv'){
                        var regex = RegExp(/,/g)
                        if (data.filter(value => regex.test(value)).length > 0) {
                           data = data.filter(value => 
                                {
                                    regex.lastIndex = 0
                                    return regex.test(value)
                                }
                            )
                            data = data.map((value, index) => {
                                value = cleanQuote(value)
                                
                                if (!(this.state.useHeader && index == 0)) {
                                    var splitRes = value.split(/,/g)
        
                                    var splitRes = splitRes.map(splitVal => splitVal.trim())
            
                                    var splitRes = splitRes.map(splitVal => {
                                        if(String(splitVal)){
                                            return String(splitVal)
                                        }else{
                                            return splitVal
                                        }
                                    })
        
                                    return splitRes
                                }
                            })
                        }else{
                            store.dispatch(showLoading(false))
                            notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    }
    
                    let dataArrValue = []
                    data = data.filter(value => value)
                    for (let value of data) {
                        let dataValue = {
                            'nik': value[0],
                            'nikPanjang': value[1],
                            'namaKaryawan': value[2],
                            'jenisKelamin': value[3],
                            'tanggalLahir': value[4],
                            'noDapen': value[5],
                            'upahPokok': value[6],
                            'rapelUpah': value[7],
                            'dasar': value[8],
                            'iuranManfaatLainPk': value[9],
                            'iuranNormalPk': value[10],
                            'iuranKaryawan': value[11],
                            'jumlahIuran': value[12],
                            'bulan': dataFilter.month,
                            'tahun': dataFilter.tahun,
                            'tahap': dataFilter.tahap
                        }
                        dataArrValue.push(dataValue);
                    }
                    let uploadSimpan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'iuran-autoupdate-tmps/simpan', 'POST', {
                        values: dataArrValue
                    });
                    this.uploadData = uploadSimpan
    
                    var dataGridProcess = this.dataGridProcessRef.current.getDataSource()
                    var successData = []
                    var errorData = []
    
                    var errorMessage = []
                    for(var uploadedData of data){
                        if(Array.isArray(uploadedData)){
                            errorMessage = []
                            if(uploadedData.length !== 13){
                                console.log(uploadedData)
                                let type = "error";
                                let text = "Format File Salah!";
    
                                notify(
                                    {
                                    message: text,
                                    width: "AUTO",
                                    shading: true,
                                    position: { at: "center", my: "center", of: window }
                                    },
                                    type,
                                    2000
                                );
                                store.dispatch(showLoading(false))
                                return false
                            }
                            
                            successData.push({
                                nik : uploadedData[0],
                                nikPanjang: uploadedData[1],
                                namaKaryawan: uploadedData[2],
                                sex: uploadedData[3],
                                tanggalLahir: uploadedData[4],
                                noDapen: uploadedData[5],
                                // upahPokokPensiun: formatNumber(uploadedData[6]),
                                rapelUpahPokokPensiun: uploadedData[7],
                                phdp: uploadedData[8],
                                iuranManfaatLainPk: uploadedData[9],
                                iuranNormalPk: uploadedData[10],
                                iuranNormalPeserta: uploadedData[11],
                                totalDapen: uploadedData[12],
                                // statusIuran: uploadedData[12],
                            })
                        }
                    }
                    console.log('processing')
                    if(dataGridProcess.length !== 0){
                        console.log('processing 1')
                        var filter = this.state.filterItem
                        var loadAPI = `list-iuran-detil-summary-by-types?bulan.in=${filter.month}&pemberiKerjaId.in=${filter.participantId}&tahun.in=${filter.tahun}&tahap.equals=${filter.tahap}`
                        var getDataIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
                        if(getDataIuran){
                            var compare = await this.compareObject(successData,getDataIuran)
                            if(compare){
                                successData = compare.success
                                errorData = compare.error
        
                                if(errorData.length > 0){
                                    this.errorData = errorData
                                    this.editDataGrid(this.errorData,'error')
                                    store.dispatch(showLoading(false))
                                    confirmAlert({
                                        title: 'Informasi',
                                        message: "Ada beberapa data unggahan yang gagal diunggah, harap periksa tab data unggahan yang gagal!",
                                        buttons: [
                                          {
                                            label: 'Tutup',
                                            onClick: () => {
                                            }
                                          },
                                        ]
                                    });
                                } else {
                                    this.successData = successData;
                                    this.editDataGrid(this.successData, 'success');
                                    store.dispatch(showLoading(false));
                                    confirmAlert({
                                        title: 'Informasi',
                                        message: 'Data Sudah sesuai!',
                                        buttons: [
                                            {
                                              label: 'Tutup',
                                              onClick: () => {
                                              }
                                            },
                                        ]
                                    });
                                }
                                this.successData = successData
                                this.errorData = errorData
    
                                if (this.errorData.length !== 0){
                                    this.setState({
                                        autoUpdateVisible: true    
                                    })
                                }
                                store.dispatch(showLoading(false))
                            }
                        }
                    }
                    
                    // this.setState({
                    //     [`matchingProcessFile`]: files.name,
                    // })
                }
                reader.readAsArrayBuffer(files);
                break;
            }
            // store.dispatch(showLoading(false))
        }   
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    errorDataGridErrorButtonColumn = () => {
        var columns = []
        
        for(var column of this.columns){
            columns.push(column)
        }

        columns.unshift({
            type: 'buttons',
            fixed: false,
            buttons: [{
                text : 'Error Detail',
                hint : 'Error Detail',
                icon: "warning",
                cssClass: "text-danger", 
                onClick : async(e) => {
                    var message = e.row.data.errorMessage.join("</br>")
                    await alert(message, 'Detail Gagal')
                },
            }]
        })

        columns.splice(7,6,{
            name: 'dataSistem',
            caption: 'Data Sistem',
            columns: [
                {
                    dataField: 'phdp',
                    caption: 'Upah Pokok Pensiun',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'rapelUpahPokokPensiun',
                    caption: 'Rapel Upah Pokok Pensiun',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'phdp',
                    caption: 'Dasar Perhitungan',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'iuranManfaatLainPk',
                    caption: 'Iuran Manfaat Lain PK',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'iuranNormalPk',
                    caption: 'Iuran Normal PK',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'iuranNormalPeserta',
                    caption: 'Iuran Normal Peserta',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'totalDapen',
                    caption: 'Total Dapen',
                    format: '#,##0.00',
                    alignment: 'right'
                },
            ]
        },
        {
            name: 'dataUpload',
            caption: 'Data Upload',
            columns: [
                {
                    dataField: 'phdpExcel',
                    caption: 'Upah Pokok Pensiun',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'rapelUpahPokokPensiunExcel',
                    caption: 'Rapel Upah Pokok Pensiun',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'phdpExcel',
                    caption: 'Dasar Perhitungan',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'iuranManfaatLainPkExcel',
                    caption: 'Iuran Manfaat Lain PK',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'iuranNormalPkExcel',
                    caption: 'Iuran Normal PK',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'iuranNormalPesertaExcel',
                    caption: 'Iuran Normal Peserta',
                    format: '#,##0.00',
                    alignment: 'right'
                },
                {
                    dataField: 'totalDapenExcel',
                    caption: 'Total Dapen',
                    format: '#,##0.00',
                    alignment: 'right'
                },
            ]
        })
        
        return columns
    }
    
    onValueChanged = (e) => {
        this.setState({
            useHeader:e.value
        })
    }

    forceRefresh = () => {
        this.processData = []
        this.successData = []
        this.errorData = []
        // this.deleteDataGrid(this.dataGridSesuaiRef)
        // this.deleteDataGrid(this.dataGridTakSesuaiRef)
        // this.deleteDataGrid(this.dataGridProcessRef)
        
        this.dataGridProcessRef.current.forceRefresh(true)
        this.dataGridSesuaiRef.current.forceRefresh(true)
        this.dataGridTakSesuaiRef.current.forceRefresh(true)
    };

    deleteDataGridProses = async () => {
        var data = this.dataGridProcessRef.current.getDataSource();
        var deleteData = []
        for(var value of data){
            console.log(value);
            deleteData.push(
                {
                    type: 'remove',
                    key : value.id
                }
            )
        }
        this.dataGridProcessRef.current.push(deleteData)
    }

    deleteDataGridSesuai = async () => {
        var data = this.dataGridSesuaiRef.current.getDataSource();
        var deleteData = []
        for(var value of data){
            deleteData.push(
                {
                    type: 'remove',
                    key : value.id
                }
            )
        }
        this.dataGridSesuaiRef.current.push(deleteData);
    }

    deleteDataGridTdkSesuai = async () => {
        var data = this.dataGridTakSesuaiRef.current.getDataSource();
        var deleteData = []
        for(var value of data){
            deleteData.push(
                {
                    type: 'remove',
                    key : value.id
                }
            )
        }
        this.dataGridTakSesuaiRef.current.push(deleteData);
    }

    autoUpdate = async () => {
        this.props.store.dispatch(showLoading(true));
        let data = this.state.filterItem;
        let loadAPI = `view-iuran-detil-summary-by-types?bulan.in=${data.month}&pemberiKerjaId.in=${data.participantId}&tahun.in=${data.tahun}&tahap.in=${data.tahap}&size=9999`;
        let dataProses = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');
        let dataArr = [];
        const firstTableRowNum = 1;

        for (let value of dataProses) {
            let dataValue = {
                nik: value.nik,
                nikPanjang: value.nikPanjang,
                fullName: value.fullName,
                sex: value.sex === '1' ? 'Perempuan' : 'Laki-Laki',
                birthDate: value.birthDate,
                phdp: value.phdp,
                rapelUpahPokokPensiun: value.phdp,
                iuranManfaatLainPk: value.iuranManfaatLainPk,
                iuranNormalPk: value.iuranNormalPk,
                iuranNormalPeserta: value.iuranNormalPeserta,
                totalDapen: value.totalDapen
            }

            const ObjectValues = Object.values(dataValue);
            dataArr.push(ObjectValues);
        }
        if (dataArr.length > 0) {
            let loadAPIListIuran = `list-iuran-detil-summary-by-types?bulan.in=${data.month}&pemberiKerjaId.in=${data.participantId}&tahun.in=${data.tahun}&tahap.in=${data.tahap}`;
            let getDataIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIListIuran, 'GET')
            if (getDataIuran) {
                let compare = await this.compareObject(this.successData, getDataIuran);
                this.errorData = compare.error;
                if (compare) {
                    let loadAPIFunctionIuran = `iuran-autoupdate-tmps/getIuranAutoupdateTmpByFunction/${dataProses[0].iuranId}/${data.month}/${data.tahun}/${data.tahap}`;
                    let responseFunctionIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFunctionIuran, 'GET');

                    if (responseFunctionIuran) {
                        // let compare1 = await this.compareObject(this.successData, getDataIuran);
                        
                        // let compareError = compare1.error;
                        // let compareErrorArr = [];
                        // let compareSuccess = compare1.success;
                        
                        // if (compareSuccess.length >= compareError.length) {
                        //     for (let valueJ of compareError) {
                        //         for (let valueI of this.uploadData) {
                        //             if (valueI.nikPanjang === valueJ.nikPanjang) {
                        //                 let dataValue = {
                        //                     nik: valueJ.nik,
                        //                     nikPanjang: valueJ.nikPanjang,
                        //                     fullName: valueJ.fullName,
                        //                     sex: valueJ.sex === "1" ? 'Perempuan' : 'Laki-Laki',
                        //                     birthDate: valueJ.birthDate,
                        //                     phdp: valueJ.phdp,
                        //                     rapelUpahPokokPensiun: valueI.rapelUpahPokokPensiun,
                        //                     dasar: valueI.dasar,
                        //                     iuranManfaatLainPk: valueI.iuranManfaatLainPk,
                        //                     iuranNormalPk: valueI.iuranNormalPk,
                        //                     iuranNormalPeserta: valueJ.iuranNormalPeserta,
                        //                     totalDapen: valueJ.totalDapen
                        //                 }
                        //                 compareErrorArr.push(dataValue);  
                        //             }
                        //         }                              
                        //     }
                        // } else {
                        //     for (let i = compareError; i > compareSuccess; i--) {
                        //         for (let value of this.uploadData) {
                        //             if (value.nikPanjang === compareSuccess[i].nikPanjang) {
                        //                 console.log(compareSuccess[i].nikPanjang);
                        //                 let dataValue = {
                        //                     nik: compareSuccess[i].nik,
                        //                     nikPanjang: compareSuccess[i].nikPanjang,
                        //                     fullName: compareSuccess[i].fullName,
                        //                     sex: compareSuccess[i].sex === "1" ? 'Perempuan' : 'Laki-Laki',
                        //                     birthDate: compareSuccess[i].birthDate,
                        //                     phdp: compareSuccess[i].phdp,
                        //                     rapelUpahPokokPensiun: value.rapelUpahPokokPensiun,
                        //                     dasar: value.dasar,
                        //                     iuranManfaatLainPk: value.iuranManfaatLainPk,
                        //                     iuranNormalPk: value.iuranNormalPk,
                        //                     iuranNormalPeserta: compareSuccess[i].iuranNormalPeserta,
                        //                     totalDapen: compareSuccess[i].totalDapen
                        //                 }
                        //                 compareErrorArr.push(dataValue);  
                        //             }
                        //         }
                        //     }
                        // }

                        // const CombineArr = compareErrorArr.concat(compareSuccess);
                        // console.log(CombineArr);
                        if (compare.error.length > 0) {
                            // await this.deleteDataGridProses();
                            await this.filterData();
                            await this.deleteDataGridSesuai();
                            await this.deleteDataGridTdkSesuai();
                            
                            // this.editDataGrid(CombineArr, 'success');
                            this.forceRefresh();
                        }
                    }
                }
            }
        }
        try {
        //     const workbook = new Excel.Workbook();
        //     var worksheet = workbook.addWorksheet('DAFTAR KARYAWAN');
        //     worksheet.addTable({
        //         name: 'MyTable',
        //         ref: `A${firstTableRowNum}`,
        //         headerRow: true,
        //         totalsRow: false,
        //         style: {
        //         theme: 'TableStyleLight15',
        //         showFirstColumn: true,
        //         // border: {
        //         //     top: {style:'thin'},
        //         //     left: {style:'thin'},
        //         //     bottom: {style:'thin'},
        //         //     right: {style:'thin'}
        //         // }
        //       },
        //       columns: [
        //         {name: 'NIK'},
        //         {name: 'Nik Panjang'},
        //         {name: 'Nama Karyawan'},
        //         {name: 'Jenis Kelamin'},
        //         {name: 'Tanggal Lahir'},
        //         {name: 'Upah Pokok Pensiun'},
        //         // {name: 'Rapel Upah Pokok Pensiun'},
        //         {name: 'Dasar Perhitungan'},
        //         {name: 'Iuran Manfaat Lain PK'},
        //         {name: 'Iuran Normal PK'},
        //         {name: 'Iuran Normal Peserta'},
        //         {name: 'Total Dapen'},
        //       ],
        //       rows: dataArr,
        //     });

        //     let lastRowNum = worksheet.lastRow.number;
        //     const lastTableRowNum = lastRowNum;

        //     for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
        //         const row = worksheet.getRow(i);
        //         row.eachCell({includeEmpty: true}, (cell => {    
        //             cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
        //         }))
        //     }

        //     for (let i = 2; i <= 10000; i++){
        //         worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'left'};
        //         worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
        //         worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};                    
        //         worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //     }

        //     var WidthColums = 20;
        //     var WidthColums2 = 35;

        //     const Data1 = worksheet.getColumn(1);
        //     Data1.width = WidthColums2;

        //     const Data2 = worksheet.getColumn(2);
        //     Data2.width = WidthColums2;

        //     const Data3 = worksheet.getColumn(3);
        //     Data3.width = WidthColums2;

        //     const Data4 = worksheet.getColumn(4);
        //     Data4.width = WidthColums2;

        //     const Data5 = worksheet.getColumn(5);
        //     Data5.width = WidthColums2;

        //     const Data6 = worksheet.getColumn(6);
        //     Data6.width = WidthColums2;

        //     const Data7 = worksheet.getColumn(7);
        //     Data7.width = WidthColums2;

        //     const Data8 = worksheet.getColumn(8);
        //     Data8.width = WidthColums2;

        //     const Data9 = worksheet.getColumn(9);
        //     Data9.width = WidthColums2;

        //     const Data10 = worksheet.getColumn(10);
        //     Data10.width = WidthColums2;

        //     const Data11 = worksheet.getColumn(11);
        //     Data11.width = WidthColums2;

        //     // const Data12 = worksheet.getColumn(12);
        //     // Data12.width = WidthColums2;

        //     const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        //     var bulan = months[data.month - 1]; 

        //   await workbook.xlsx.writeBuffer().then(function(buffer) {
        //         saveAs(
        //             new Blob([buffer], { type: "application/octet-stream" }),
        //             `Backup Iuran ${bulan} ${data.tahun}.xlsx`
        //         );
        //     });

        this.props.store.dispatch(showLoading(false));
           const result = await alert('Auto update iuran berhasil, silahkan lakukan proses matching kembali!', 'Informasi');
           if (result) {
                this.props.store.dispatch(addURL(this.props.history.replace('/kepesertaan/penerimaan-iuran/proses-iuran-bulanan')));
           }
            // confirmAlert({
            //     title: 'Informasi',
            //     message: 'Auto update iuran berhasil, silahkan lakukan proses matching kembali!',
            //     buttons: [
            //         {
            //           label: 'OK',
            //           onClick: () => {
            //             try {
            //                 this.props.store.dispatch(addURL(this.props.history.replace('/kepesertaan/penerimaan-iuran/proses-iuran-bulanan')));
            //             } catch (error) {
            //                 console.log(error);
            //             }
            //           }
            //         },
            //     ]
            // });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div className="container-fluid">
            {/* <div className="container-fluid" style={{ minHeight: 'calc(100vh - 160px)' }}> */}
                <h2 className="main-title font-weight-bold">Matching Process</h2>
                <Form
                    colCount={5}
                    id={'formFilterItem'}
                    ref={this.formFilterRef}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />
                <div className="d-flex flex-row mt-4 float-right">
                    <div style={{ width:"350px" }}>
                    <Button
                        elementAttr={{class:"bg-dapen"}}
                        text={"Proses Auto Update"}
                        type={"success"}
                        stylingMode={"contained"}
                        visible={this.state.autoUpdateVisible}
                        onClick={async () => {
                            const result = await confirm('Yakin akan melakukan proses auto update iuran?', 'Konfirmasi');
                            
                            if (result) {
                                this.autoUpdate()
                            }
                        }}
                        className={"mx-2"}
                        // visible={true}
                    />
                    </div>
                </div>
                <div className="d-flex flex-row" id="divUploadFile">
                    <div style={{width:"350px"}}>
                    <FileUploader
                        accept={'.txt,.xls,.xlsx,.csv'}
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)}
                        labelText={this.state.matchingProcessFile ||  'Upload File Matching Disini'}
                        showFileList={false}
                        name={'matchingProcessFile'}
                        visible= {this.state.uploadVisible}

                        value = {this.state.uploadedData}

                        ref={this.fileUploderRef}
                    />
                    </div>
                    {/* <div className="d-flex flex-row align-items-center mb-2">
                        <CheckBox 
                            value={this.state.useHeader} 
                            onValueChanged={this.onValueChanged}
                        />
                        <span className="ml-2">Use Header</span>
                    </div> */}
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0" className={' mt-2 text-center' }>
                    <DevExpressDataGrid
                        ref={this.dataGridProcessRef}
                        loadAPI={this.state.getUrl}
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        remoteOperations = {true}

                        keyField = {'id'}
                        // useArraySource = {true}
                        // ArraySourceData = {this.retrieveDataProcess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Proses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Proses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabindex-1" className={'d-none mt-2 text-center' }>
                    <DevExpressDataGrid
                        ref={this.dataGridSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        keyField = {'nik'}
                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataSuccess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Sukses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Sukses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabindex-2" className={'d-none mt-2 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridTakSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataFailed}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Gagal"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        menuRightClick = {false}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Gagal'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.errorDataGridErrorButtonColumn()} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{class:"bg-dapen"}}
                        text={"Proses Transaksi Iuran"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.prosesData.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
            </div>
        )
    }
}

export default MatchingProses