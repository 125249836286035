import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { banks, custodies, broker, managerInvestasis } from 'dataSource/lookup'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';

class ModalRekening extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            dataMaster: {},
            uploadedData: []
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.prevTabIndex = 0
        this.formItem = [
            {
                dataField: 'productCode',
                label: {
                    text: 'Kode Produk'
                }
            },
            {
                dataField: 'productName',
                label: {
                    text: 'Nama Produk'
                }
            },
        ]
        this.tabDataSource = [
            {
                id: 0,
                text: 'Bank'
            },
            {
                id: 1,
                text: 'Kustodi'
            },
            {
                id: 2,
                text: 'Broker'
            },
            {
                id: 3,
                text: 'Manager Investasi'
            },
            {
                id: 4,
                text: 'Aperd'
            },
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.columnBank = [
            {
                dataField: 'noRekening',
                caption: 'No. Rekening'
            },
            {
                dataField: 'rekeningName',
                caption: 'Nama Rekening'
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'jenisRekening',
                caption: 'Jenis Rekening',
                lookup: {
                    dataSource: [
                        {
                            id: 'G',
                            value: 'Giro'
                        },
                        {
                            id: 'T',
                            value: 'Tabungan'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'akunId',
                caption: 'Akun',
                lookup: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'ketrangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Aktif'
                        },
                        {
                            id: 2,
                            value: 'Tidak Aktif'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
        this.columnCustody = [
            {
                dataField: 'noRekening',
                caption: 'No. Rekening'
            },
            {
                dataField: 'rekeningName',
                caption: 'Nama Rekening'
            },
            {
                dataField: 'custodyId',
                caption: 'Bank Kustodi',
                lookup: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'custodyName'
                }
            },
            {
                dataField: 'ketrangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Aktif'
                        },
                        {
                            id: 2,
                            value: 'Tidak Aktif'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
        this.columnBroker = [
            {
                dataField: 'noRekening',
                caption: 'No. Rekening'
            },
            {
                dataField: 'rekeningName',
                caption: 'Nama Rekening'
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'ketrangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Aktif'
                        },
                        {
                            id: 2,
                            value: 'Tidak Aktif'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'feeBuy',
                caption: 'Fee Buy',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'feeSell',
                caption: 'Fee Sell',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
        this.columnMi = [
            {
                dataField: 'noRekening',
                caption: 'No. Rekening'
            },
            {
                dataField: 'rekeningName',
                caption: 'Nama Rekening'
            },
            {
                dataField: 'managerInvestasiId',
                caption: 'Manager Investasi',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'ketrangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Aktif'
                        },
                        {
                            id: 2,
                            value: 'Tidak Aktif'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
        this.columnAperd = [
            {
                dataField: 'noRekening',
                caption: 'No. Rekening'
            },
            {
                dataField: 'rekeningName',
                caption: 'Nama Rekening'
            },
            {
                dataField: 'aperdId',
                caption: 'Aperd',
                lookup: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'ketrangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Aktif'
                        },
                        {
                            id: 2,
                            value: 'Tidak Aktif'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show(dataMaster) {

        var dataMaster = dataMaster || {
        }

        this.setState({
            popupVisible: true,
            dataMaster: dataMaster,
            // dataPemberiKerja: dataMaster
        });

    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }
    loadData = () => {
        return []
    }
    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindexRekening-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexRekening-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Form Data Rekening Produk Dapen`}
                    minWidth={'70vw'}
                    height="70vh"

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid py-3">
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <Form
                                    colCount={2}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.formItem}
                                    labelLocation={"left"}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.tabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                        </div>
                        <div id="tabindexRekening-0" className={`col-md-12`} style={{ overflowY: "auto" }}>
                            <DevExpressDataGrid
                                loadAPI='rekenings'
                                insertAPI='rekenings'
                                updateAPI='rekenings'
                                deleteAPI='rekenings'

                                backendserver={process.env.REACT_APP_BACKEND}
                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={true}
                                allowDeleting={true}
                                allowUpdating={true}
                                exportExcel={true}
                                exportFileName={"Rekening"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={300}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Rekening Data'}
                                popupWidth={600} //masukan dalam ukuran pixel
                                popupHeight={450} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnBank} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                        <div id="tabindexRekening-1" className={`col-md-12 d-none`} style={{ overflowY: "auto" }}>
                            <DevExpressDataGrid
                                loadAPI='rekenings'
                                insertAPI='rekenings'
                                updateAPI='rekenings'
                                deleteAPI='rekenings'

                                backendserver={process.env.REACT_APP_BACKEND}
                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={true}
                                allowDeleting={true}
                                allowUpdating={true}
                                exportExcel={true}
                                exportFileName={"Rekening"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={300}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Rekening Data'}
                                popupWidth={600} //masukan dalam ukuran pixel
                                popupHeight={450} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnCustody} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                        <div id="tabindexRekening-2" className={`col-md-12 d-none`} style={{ overflowY: "auto" }}>
                            <DevExpressDataGrid
                                loadAPI='rekenings'
                                insertAPI='rekenings'
                                updateAPI='rekenings'
                                deleteAPI='rekenings'

                                backendserver={process.env.REACT_APP_BACKEND}
                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={true}
                                allowDeleting={true}
                                allowUpdating={true}
                                exportExcel={true}
                                exportFileName={"Rekening"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={300}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Rekening Data'}
                                popupWidth={600} //masukan dalam ukuran pixel
                                popupHeight={450} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnBroker} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                        <div id="tabindexRekening-3" className={`col-md-12 d-none`} style={{ overflowY: "auto" }}>
                            <DevExpressDataGrid
                                loadAPI='rekenings'
                                insertAPI='rekenings'
                                updateAPI='rekenings'
                                deleteAPI='rekenings'

                                backendserver={process.env.REACT_APP_BACKEND}
                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={true}
                                allowDeleting={true}
                                allowUpdating={true}
                                exportExcel={true}
                                exportFileName={"Rekening"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={300}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Rekening Data'}
                                popupWidth={600} //masukan dalam ukuran pixel
                                popupHeight={450} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnMi} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                        <div id="tabindexRekening-4" className={`col-md-12 d-none`} style={{ overflowY: "auto" }}>
                            <DevExpressDataGrid
                                loadAPI='rekenings'
                                insertAPI='rekenings'
                                updateAPI='rekenings'
                                deleteAPI='rekenings'

                                backendserver={process.env.REACT_APP_BACKEND}
                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={true}
                                allowDeleting={true}
                                allowUpdating={true}
                                exportExcel={true}
                                exportFileName={"Rekening"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={300}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Rekening Data'}
                                popupWidth={600} //masukan dalam ukuran pixel
                                popupHeight={450} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnAperd} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalRekening