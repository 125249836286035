import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { participants } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { generateTextFile } from 'plugin/helper'
import notify from "devextreme/ui/notify";
import { Form } from 'devextreme-react';
import UploadKonversi from 'components/pages/modal/kepesertaan/integrasi/konversi/uploadKonversi'

class SekaligusJadud extends Component {
    constructor(props){
        super(props)
        this.state = {
            DataMaster: {},
            getUrl: 'sekaligus-jaduds?size=9999'
        }

        this.filterData = [];
        this.dataGridRef = React.createRef();
        this.modalUploadKonversiRef = React.createRef();

        this.columns = [
            {
                dataField: 'bulan_0',
                caption: 'Bulan 0',
                alignment: 'right'
            },
            {
                dataField: 'bulan_1',
                caption: 'Bulan 1',
                alignment: 'right'
            },
            {
                dataField: 'bulan_2',
                caption: 'Bulan 2',
                alignment: 'right'
            },
            {
                dataField: 'bulan_3',
                caption: 'Bulan 3',
                alignment: 'right'
            },
            {
                dataField: 'bulan_4',
                caption: 'Bulan 4',
                alignment: 'right'
            },
            {
                dataField: 'bulan_5',
                caption: 'Bulan 5',
                alignment: 'right'
            },
            {
                dataField: 'bulan_6',
                caption: 'Bulan 6',
                alignment: 'right'
            },
            {
                dataField: 'bulan_7',
                caption: 'Bulan 7',
                alignment: 'right'
            },
            {
                dataField: 'bulan_8',
                caption: 'Bulan 8',
                alignment: 'right'
            }, 
            {
                dataField: 'bulan_9',
                caption: 'Bulan 9',
                alignment: 'right'
            },
            {
                dataField: 'bulan_10',
                caption: 'Bulan 10',
                alignment: 'right'
            },
            {
                dataField: 'bulan_11',
                caption: 'Bulan 11',
                alignment: 'right'
            },
            {
                dataField: 'addAnak',
                caption: 'Faktor Anak',
                alignment: 'right'
            },
            {
                dataField: 'usia',
                caption: 'Usia',
                alignment: 'center'
            },
            {
                dataField: 'periodeTahun',
                caption: 'Periode Tahun',
                alignment: 'center'
            }
        ]

        this.filterItem = [
            {
                dataField: "periodeTahun",
                label: {
                    text: "Tahun",
                    alignment: "left",
                    location: "left",
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname)) 
    }

    
    componentDidMount = async () => {
        var datas = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `sekaligus-jaduds?size=9999`, 'GET');
        this.filterData = datas
        this.forceRefresh()
    }

    // defaultData = async() => {
    //     var datas = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `sekaligus-jaduds?size=9999`, 'GET');

    //     this.filterData = datas;
    //     if (this.filterData){
    //         this.forceRefresh();
    //     }
    // }

    filterSubmit = async () => {
        const state = this.state.DataMaster;
        var periodeTahun = state.periodeTahun
        this.setState({
            getUrl: periodeTahun ? `sekaligus-jaduds?periodeTahun.in=${periodeTahun}&size=9999` : `sekaligus-jaduds?size=9999`
        })
        this.forceRefresh();
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    // loadData = async () => {
    //     return this.filterData;
    // }

    showModalUploadKonversi = () => {
        this.modalUploadKonversiRef.current.show();
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'upload',
                    text: 'Upload Konversi',
                    onClick: (e) => {
                        this.showModalUploadKonversi(e)
                    },
                }
            }
        )
    }

    render(){
        return(
            <div className="container-fluid">
                <h2 className="main-title">Daftar Konversi Jadud Sekaligus</h2>
                <Form
                    colCount={4}
                    id={'formJadudSekaligus'}
                    formData={this.state.DataMaster}
                    items={this.filterItem}
                    labelLocation={'left'}
                />
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI=''
                    updateAPI=''
                    deleteAPI='' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                
                    // useArraySource={true}
                    // ArraySourceData={this.loadData}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Konversi Jadud Sekaligus"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                /> 
                <UploadKonversi 
                    ref={this.modalUploadKonversiRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default SekaligusJadud;