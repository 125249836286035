import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { confirmAlert } from 'react-confirm-alert'
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import FormModalShift from 'components/pages/modal/sdm-umum/pengaturan/jam-kerja/jamShift';

class JamKerjaShift extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalShift(e)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async (e) => {
                            let data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteAPI(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    }
                ]
            },
            {
                dataField:"kodeShift",
                caption:"Kode Shift" 
            },
            {
                dataField:"namaShift",
                caption:"Nama Shift" 
            },
            {
                dataField:"jamMulai",
                caption:"Jam Mulai",
                format:"HH:mm",
                dataType:"datetime",
                editorType:"dxDateBox",
                editorOptions:{
                    type: "time",
                    openOnFieldClick:true,
                    // useMaskBehavior:true,
                    // pickerType:"rollers",
                    displayFormat: 'HH:mm',
                    // dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
                }
            },
            {
                dataField:"jamAkhir",
                caption:"Jam Akhir",
                format:"HH:mm",
                dataType:"datetime",
                editorType:"dxDateBox",
                editorOptions:{
                    type: "time",
                    openOnFieldClick:true,
                    // useMaskBehavior:true,
                    // pickerType:"rollers",
                    displayFormat: 'HH:mm',
                    // dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
                } 
            },
        ]

        this.showModalShiftRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    deleteAPI = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'shift-types', 'DELETE', data.id)
            this.forceRefresh()
            notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }

    showModalShift = (e) => {
        if (e.row) {
            this.showModalShiftRef.current.retrieveData(e.row.data)
        }
        this.showModalShiftRef.current.show()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Shift</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='shift-types'
                    insertAPI='shift-types'
                    updateAPI='shift-types'
                    deleteAPI='shift-types' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Jam Kerja Shift"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Jam Kerja Shift'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini


                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormModalShift
                    ref={this.showModalShiftRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default JamKerjaShift