import React, { Component } from 'react'
import { Chart, Series, Tooltip } from 'devextreme-react/chart';
import httpRequest from 'plugin/httprequest';
import { getPesertaStatuses } from 'dataSource/lookup';
import { Legend } from '../../../../node_modules/devextreme-react/bar-gauge';

class PertumbuhanPeserta extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <React.Fragment>
                <Chart
                    id="chart"
                    dataSource={getPesertaStatuses(this.props.store)}
                >
                    <Series
                        valueField="total_peserta"
                        argumentField="status_name"
                        type="bar"
                    />

                    <Legend
                        visible={false}
                    />
                    <Tooltip enabled={true} format={'fixedPoint'} zIndex={5} />
                </Chart>
            </React.Fragment>
        )
    }
}

export default PertumbuhanPeserta
