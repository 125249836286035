import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import {imageSource } from 'plugin/helper';

class ModalPreviewPdf extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            src: "",
            type: 'base64'
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            src: "",
        });
    }

    retrieveData = async(data, type = 'base64') => {
        this.setState({
            src: data,
            type : type
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 d-flex flex-column align-items-center justify-content-center">
                            <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(70vh - 50px)"}} title={'Preview'}/>
                        </div>
                    </div>

                </div>
            </Popup>
        );
    }
}


export default ModalPreviewPdf