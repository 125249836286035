import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import ModalUmumPembelianPenerimaan from 'components/pages/modal/sdm-umum/umum/pembelian/penerimaan/index';
import { karyawan } from 'dataSource/lookup';

class UmumPembelianPenerimaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data, 'view')
                        }
                    },
                ],
            },
            {
                dataField: 'noPenerimaan',
                caption: 'Nomor Penerimaan'
            },
            {
                dataField: 'namaAset',
                caption: 'Nama Asset'
            },
            {
                dataField: 'noPembelian',
                caption: 'Nomor Pemesanan'
            },
            {
                dataField: 'tanggalDiterima',
                caption: 'Tanggal Penerimaan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'receiveBy',
                caption: 'Diterima Oleh',
                lookup: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: (item) => {
                        return item && item.nik + ' - ' + item.fullName
                    }, // provides display values
                }
            },
            {
                dataField: 'jumlahDiterima',
                caption: 'Jumlah Diterima',
                type:'number',
                format: '#,##0.00'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (data, type) => {
        this.modalRef.current.show(type)
        this.modalRef.current.retrieveData(data, type)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.modalRef.current.show('add')
                    }
                }
            },
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        let assetPenerimaan = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `asset-penerimaans?size=9999`,
            "GET"
        );
        let assetPembelian = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-asset-pembelians`,
            "GET"
        );
        let result = assetPenerimaan.map(value => ({ ...value, ...assetPembelian.find(ap => ap.id === value.assetPembelianId)}))
        return result
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pemesanan Penerimaan</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='asset-penerimaans'
                        insertAPI='asset-penerimaans'
                        updateAPI='asset-penerimaans'
                        deleteAPI='asset-penerimaans'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}
                        useArraySource={true}
                        ArraySourceData={this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Pemesanan Penerimaan"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Pemesanan Penerimaan'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div> 
                <ModalUmumPembelianPenerimaan
                    ref={this.modalRef}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default UmumPembelianPenerimaan
