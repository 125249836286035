import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';

class Lembur extends Component {
    constructor(props){
        super(props)

        this.state = {  
            formData: {}
        }

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'nikPanjang',
                caption: 'NIK Panjang',
            },
            {
                dataField: 'nama',
                caption: 'Nama',
            },
            {
                dataField: 'divisi',
                caption: 'Divisi',
            },
            {
                dataField: 'jabatan',
                caption: 'Jabatan',
            },
            {
                dataField: 'tanggal',
                caption: 'Tanggal',
                dataType: 'date',  
                format: 'dd-MMM-yyyy',
            },
            {
                dataField: 'mulai',
                caption: 'Mulai',
                dataType: 'date',  
                format: 'dd-MMM-yyyy',
            },
            {
                dataField: 'selesai',
                caption: 'Selesai',
                dataType: 'date',  
                format: 'dd-MMM-yyyy',
            },
            {
                dataField: 'totalLembur',
                caption: 'Total Lembur',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Lembur</h3>

                <DevExpressDataGrid
                    loadAPI='daftar-lemburs'
                    insertAPI='daftar-lemburs'
                    updateAPI='daftar-lemburs'
                    deleteAPI='daftar-lemburs' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Daftar Lembur"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Lembur'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default Lembur
