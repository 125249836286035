import React, { Component } from 'react';
import { Popup,ScrollView,Form ,FileUploader } from 'devextreme-react'
import { bahasa, educationBackground, jabatan, keahlian, programs, sdmUnit } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest' 
import notify from "devextreme/ui/notify";
import { confirmAlert } from 'react-confirm-alert'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';


class FormModalRincianDetail extends Component {
    constructor(props){
        super(props)

        this.state={
            popupVisible:false,
            DataMaster:{
                bahasaId:[],
                keahlianId:[],
                pendidikanId:[]
            }
        }

        this.DataMaster = [
            {
                dataField: 'programId',
                label:{
                    text:'Program',
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'unitId',
                label:{
                    text:'Unit',
                },
                editorType:'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                },
            },
            {
                dataField: 'jabatanId',
                label:{
                    text:'Posisi Jabatan',
                },
                editorType:'dxSelectBox',
                editorOptions: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                },
            },
            {
                dataField: 'jumlahPersonel',
                label:{
                    text:'Jumlah Personel',
                },
            },
            {
                dataField: 'pendidikanId',
                label:{
                    text:'Pendidikan',
                },
                editorType:'dxTagBox',
                editorOptions: {
                    dataSource: educationBackground(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'educationName',
                },
            },
            {
                dataField: 'jurusanPendidikan',
                label:{
                    text:'Jurusan Pendidikan',
                },
            },
            {
                dataField: 'tahunPengalaman',
                label:{
                    text:'Pengalaman (Tahun)',
                },
            },
            {
                dataField: 'bahasaId',
                label:{
                    text:'Bahasa',
                },
                editorType:'dxTagBox',
                editorOptions: {
                    dataSource: bahasa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaBahasa',
                },
            },
            {
                dataField: 'keahlianId',
                label:{
                    text:'Keahlian ',
                },
                editorType:'dxTagBox',
                editorOptions: {
                    dataSource: keahlian(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaKeahlian',
                },
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.rekruitmenId = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formMasterRef = React.createRef()
    }

    show() { 
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.rekruitmenId = 0
        this.setState({
            popupVisible: false,
            DataMaster:{
                bahasaId:[],
                keahlianId:[],
                pendidikanId:[]
            }
        })
    }

    submitData = async () => {
        try {
            var DataMaster = this.state.DataMaster
            DataMaster.keahlianId = DataMaster.keahlianId.join(',')
            DataMaster.bahasaId = DataMaster.bahasaId.join(',')
            DataMaster.pendidikanId = DataMaster.pendidikanId.join(',')
            DataMaster.rekurtmenId = this.rekruitmenId
            var loadApi = 'save-rincian-pengajuan-rekurtmen'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: this.state.DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Input Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);  
        }
    }

    updateData = async () => {
        try {
            var DataMaster = this.state.DataMaster

            DataMaster.keahlianId = DataMaster.keahlianId.join(',')
            DataMaster.bahasaId = DataMaster.bahasaId.join(',')
            DataMaster.pendidikanId = DataMaster.pendidikanId.join(',')
            DataMaster.id =  DataMaster.id
            DataMaster.rekurtmenId = this.rekruitmenId
            var loadApi = 'save-rincian-pengajuan-rekurtmen'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: this.state.DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Ubah Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);  
        }
    }

    getRekruitmenId = (rekruitmenId) => {
        this.rekruitmenId = rekruitmenId
    }

    retrieveData = (data) => {
        data.bahasaId = typeof data.bahasaId === 'string' ? data.bahasaId.split(',') : data.bahasaId
        data.keahlianId = typeof data.keahlianId === 'string' ? data.keahlianId.split(',') : data.keahlianId
        data.pendidikanId = typeof data.pendidikanId === 'string' ? data.pendidikanId.split(',') : data.pendidikanId
        this.setState({
            DataMaster: data,
        })
    }
    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Detail Rincian Rekruitmen"}
                width={"50vw"}
                height={"40vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation = 'left'
                            readOnly={this.props.action == 'view' ? true : false}
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}


export default FormModalRincianDetail