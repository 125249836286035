import React, { Component } from 'react'
import { currency, banks, orders, product, pejabat } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormInstruksiKustodiAktif from 'components/pages/modal/investasi/instruksi/kustodi/aktif/index'
import FormInstruksiBankAktif from 'components/pages/modal/investasi/instruksi/bank/aktif/index'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDateDmy, formatDefaultFullDate, formatNumber, getDays, numberToIndo, PrintElem } from 'plugin/helper'

class InstruksiBankAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.rekeningBank = []
        this.getBank = []
        this.subCategory = []
        this.jabatan = []
        this.dataGridInstruksiBankRef = React.createRef()
        this.formPenempatanRef = React.createRef()
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalPenempatan(e.row.data)
                        }
                    },
                    {
                        text: 'Cetak Instruksi',
                        hint: 'Cetak Instruksi',
                        onClick: (e) => {
                            this.cetakInstruksi(e.row.data)
                        }
                    },
                    // {
                    //     text: 'Cetak Pembayaran',
                    //     onClick: (e) => {
                    //         this.cetakInstruksiPembayaran(e.row.data)
                    //     }
                    // },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin mengirim instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.sendInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin membatalkan instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.cancelInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                        }
                    },
                ]
            },
            {
                dataField: 'instruksiId',
                caption: 'ID.',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'transactionName',
                caption: 'Transaksi',
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiNo',
                        caption: 'Nomor'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'totalOrder',
                        caption: 'Jumlah',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'totalAmountOrder',
                        caption: 'Total Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    }
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var getRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-banks', 'GET')
        var getSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'instrument-category-subs/deposito', 'GET')
        var getJabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'jabatans', 'GET')
        var getBankData = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET')

        this.rekeningBank = getRekBank
        this.subCategory = getSubCategory
        this.jabatan = getJabatan
        this.getBank = getBankData
    }
    showModalPenempatan = async (data) => {
        var instruksiId = data.instruksiId
        var loadAPI = `order-instruksi-deposito/${instruksiId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderInstruksiDTO
        var dataDataGrid = response.orderDepositoInfos
        var deposito = []
        for (var dataDetail of dataDataGrid) {
            var reqId = dataDetail.orderDTO.orderRequestId
            var dataRequest = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${reqId}`, 'GET')
            var depositoDto = {
                ...dataDetail.orderDepositoDTO,
                subName: this.subCategory.find(val => val.id == dataMaster.categorySubId).subName,
                rekSourceName: dataDetail.orderDepositoDTO.sourceRekBankId ? this.rekeningBank.find(val => val.id == dataDetail.orderDepositoDTO.sourceRekBankId).rekName : null,
                rekBankName: this.rekeningBank.find(val => val.id == dataDetail.orderDepositoDTO.rekBankId).rekName,
                noOrder: dataDetail.orderDTO.orderCode,
                orderDate: dataRequest.orderDate,
                maturityDate: dataDetail.instrumentDTO != null ? dataDetail.instrumentDTO.maturityDate : dataDetail.orderDepositoDTO.maturityDate
            }
            deposito.push(depositoDto)
        }
        var customGetData = {
            ...dataMaster,
            ...data,
            dataDeposito: deposito
        }
        console.log(customGetData)
        this.formPenempatanRef.current.show(customGetData)
    }
    cetakInstruksiPembayaran = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-deposito/${data.instruksiId}`, 'GET')
        var rekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-banks/${detail.orderInstruksiDTO.sourceRekBankId}`, 'GET')
        var uraian = []
        var bankId = rekBank.bankId
        if (detail.orderInstruksiDTO.orderTypeId == 231) {
            uraian = 'Penempatan ' + data.categoryName + ' ' + data.bankName
        } else if (detail.orderInstruksiDTO.orderTypeId == 232) {
            uraian = 'Pencairan ' + data.categoryName + ' ' + data.bankName
        }
        var amountOnString = numberToIndo(data.totalAmountOrder) + ' Rupiah'
        var data = {
            template: {
                'shortid': 'ryga1_GQHd',
                'recipe': 'html'
            },
            data: {
                "instruksiNo": data.instruksiNo,
                "placementDate": formatDefaultFullDate(data.instruksiDate),
                "bankName": data.bankName,
                "amountOnString": amountOnString,
                "totalAmountOrder": formatNumber(data.totalAmountOrder),
                "rekNo": rekBank.rekNo,
                "rekName": rekBank.rekName,
                "cabang": rekBank.cabang,
                "bankNameRek": this.getBank.find(val => val.id == bankId).bankName,
                "uraian": uraian
            },
            options: {
                reportName: "PAYMENT-INSTRUCTION"
            }
        }
        var print = await reportRequest(this.props.store, data)
        PrintElem(print.data)
        return print
    }
    cetakInstruksi = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-deposito/${data.instruksiId}`, 'GET')
        var rekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-banks/${detail.orderInstruksiDTO.sourceRekBankId}`, 'GET')
        var dataBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `banks/${data.bankId}`, 'GET')
        const pejabat1 = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${parseInt(data.pejabat1)}`, 'GET')
        const pejabat2 = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${parseInt(data.pejabat2)}`, 'GET')
        var deposito = []
        if (detail.orderInstruksiDTO.orderTypeId == 231) {
            // Penempatan
            var no = 0
            for (var dataDeposito of detail.orderDepositoInfos) {
                var dataResult = {
                    no: ++no,
                    amountOrder: formatNumber(dataDeposito.orderDepositoDTO.amountOrder),
                    placementDate: formatDateDmy(dataDeposito.orderDepositoDTO.placementDate),
                    tenor: dataDeposito.orderDepositoDTO.tenor + ' ' + dataDeposito.orderDepositoDTO.tenorTypeId,
                    rateOrder: dataDeposito.orderDepositoDTO.rateOrder,
                }
                deposito.push(dataResult)
            }
            reportFile({
                template: { 'shortid': 'SygHBTopL_' },
                data: {
                    "instruksiNo": data.instruksiNo,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "bankName": data.bankName,
                    "totalAmountOrder": formatNumber(data.totalAmountOrder),
                    "rekNo": rekBank.rekNo,
                    "rekName": rekBank.rekName,
                    "rekBankCabang": rekBank.cabang,
                    "rekBankName": data.bankName,
                    "bankAlamat": dataBank.alamat,
                    "pejabat1": pejabat1.fullName,
                    "pejabat2": pejabat2.fullName,
                    "jabatan1": this.jabatan.find(val => val.id === pejabat1.jabatanId).jabatanName,
                    "jabatan2": this.jabatan.find(val => val.id === pejabat2.jabatanId).jabatanName,
                    "deposito": deposito
                },
                options: {
                    reportName: "Penempatan " + data.bankName
                }
            }, true, `Penempatan Deposito ${data.bankName}.docx`)
        } else if (detail.orderInstruksiDTO.orderTypeId == 232) {
            // Pelepasan
            var no = 0
            for (var dataDeposito of detail.orderDepositoInfos) {
                var dataResult = {
                    no: ++no,
                    amountOrder: formatNumber(dataDeposito.orderDepositoDTO.amountOrder),
                    maturityDate: formatDateDmy(dataDeposito.instrumentDTO.maturityDate),
                    bilyetNo: dataDeposito.orderDepositoDTO.bilyetNo,
                }
                deposito.push(dataResult)
            }
            reportFile({
                template: { 'shortid': 'BJx9T5C68_' },
                data: {
                    "instruksiNo": data.instruksiNo,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "instruksiDay": getDays(data.instruksiDate),
                    "bankName": data.bankName,
                    "totalAmountOrder": formatNumber(data.totalAmountOrder),
                    "rekNo": rekBank.rekNo,
                    "rekName": rekBank.rekName,
                    "rekBankCabang": rekBank.cabang,
                    "rekBankName": data.bankName,
                    "bankAlamat": dataBank.alamat,
                    "pejabat1": pejabat1.fullName,
                    "pejabat2": pejabat2.fullName,
                    "jabatan1": this.jabatan.find(val => val.id === pejabat1.jabatanId).jabatanName,
                    "jabatan2": this.jabatan.find(val => val.id === pejabat2.jabatanId).jabatanName,
                    "deposito": deposito
                },
                options: {
                    reportName: "Pelepasan " + data.bankName
                }
            }, true, `Pelepasan Deposito ${data.bankName}.docx`)
        } else if (detail.orderInstruksiDTO.orderTypeId == 233) {
            // Perpanjangan
            var no = 0
            for (var dataDeposito of detail.orderDepositoInfos) {
                var dataResult = {
                    no: ++no,
                    amountOrder: formatNumber(dataDeposito.orderDepositoDTO.amountOrder),
                    maturityDate: formatDateDmy(dataDeposito.instrumentDTO.maturityDate),
                    bilyetNo: dataDeposito.orderDepositoDTO.bilyetNo,
                    tenor: dataDeposito.orderDepositoDTO.tenor + ' ' + dataDeposito.orderDepositoDTO.tenorTypeId,
                    rateOrder: dataDeposito.orderDepositoDTO.rateOrder,
                }
                deposito.push(dataResult)
            }
            reportFile({
                template: { 'shortid': 'HyxkQp0TUu' },
                data: {
                    "instruksiNo": data.instruksiNo,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "bankName": data.bankName,
                    "totalAmountOrder": formatNumber(data.totalAmountOrder),
                    "rekNo": rekBank.rekNo,
                    "rekName": rekBank.rekName,
                    "rekBankCabang": rekBank.cabang,
                    "rekBankName": data.bankName,
                    "bankAlamat": dataBank.alamat,
                    "pejabat1": pejabat1.fullName,
                    "pejabat2": pejabat2.fullName,
                    "jabatan1": this.jabatan.find(val => val.id === pejabat1.jabatanId).jabatanName,
                    "jabatan2": this.jabatan.find(val => val.id === pejabat2.jabatanId).jabatanName,
                    "deposito": deposito
                },
                options: {
                    reportName: "Perpanjangan " + data.bankName
                }
            }, true, `Perpanjangan Deposito ${data.bankName}.docx`)
        }
    }
    sendInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        if (data.pejabat1 || data.pejabat2 != null) {
            if (data.fileInstruksiContentType != null) {
                if (instruksiId) {
                    try {
                        var insertAPI = 'order-depositos/send-instruksi-deposito'
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                            values: [
                                instruksiId
                            ]
                        })
                        notify({ message: 'Pengiriman Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.forceRefresh()
                        return response
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } else {
                notify({ message: 'Mohon lengkapi file instruksi terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        } else {
            notify({ message: 'Mohon lengkapi data pejabat terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }
    cancelInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        if (instruksiId) {
            try {
                var insertAPI = 'order-depositos/batal-instruksi-deposito'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: [
                        instruksiId
                    ]
                })
                notify({ message: 'Pembatalan Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    forceRefresh = () => {
        this.dataGridInstruksiBankRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Bank</h2>
                <DevExpressDataGrid
                    keyField='instruksiId'
                    ref={this.dataGridInstruksiBankRef}
                    loadAPI='order-instruksi-deposito/list?status=OPEN'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instruksi Bank"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Bank'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormInstruksiBankAktif
                    ref={this.formPenempatanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default InstruksiBankAktif