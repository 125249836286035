import React, { Component } from 'react'
import { product, pesertaAkunStatus } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ProsesDataPemberhentianPeserta from './actionProsesPemberhentian';
import { daysDiff, yearsDiff } from 'plugin/helper';
import { data } from 'dataSource/json/auctionBuybackCash';
import httpRequest from 'plugin/httprequest';

class FormDataPemberhentianPeserta extends Component {
    constructor(props) {
        super(props)

        this.formRef = React.createRef()
        this.showModalActionViewRef = React.createRef()
        this.DataGridAkunProductRef = React.createRef()

        this.dataPeserta = {}
        this.formDataPensiun = {}
        
        this.dataGridAkun = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : this.props.actionType == 'view' ? 'Buka' : 'Proses',
                        hint : this.props.actionType == 'view' ? 'Buka' : 'Proses',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalAction(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'cardNo',
                caption: 'Card No',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'akunStatusId',
                caption: 'Status Akun Peserta',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]
    }

    passDataToParent = () => {
        var data = this.dataPensiun
        return data
    }
    
    removeState = () => {
        this.formDataPensiun = {}
        this.dataPeserta = {}

        this.forceRefresh()
    }

    forceRefresh = () => {
        this.DataGridAkunProductRef.current.forceRefresh(true)
    }
    
    reloadData = async(pesertaId,dataAkun) => {
        this.formDataPensiun = dataAkun
        var loadAPIPeserta = `pesertas/${pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')

        this.dataPeserta = getPeserta

        this.forceRefresh()
    }

    retrieveData = async() => {
        var pesertaId = this.dataPeserta.id
        var akunId = this.formDataPensiun.id || this.formDataPensiun.ids

        var result = []
        if(akunId){
            var loadAPI = `kepersertaan-products/${akunId}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
            result.push(response)
        }else if(pesertaId){
            var loadAPI = `kepersertaan-products/getAllSimpleProductByPesertaId/${Array.isArray(pesertaId) ? pesertaId[0] : pesertaId}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
            for(var value of response){
                if(this.props.actionType == 'add'){
                    value.akunStatusId = value.statusId || value.akunStatusId
                    result.push(value)
                }else{
                    result.push(value)
                }
            }
        }
        return result
    }

    showModalAction = async(data) => {
        data.usia = yearsDiff(new Date(),this.dataPeserta.birthDate)
        data.totalIuran = data.iuranManfaatLainPeserta + data.iuranManfaatLainPk + data.iuranNormalPeserta + data.iuranNormalPk
        
        this.showModalActionViewRef.current.show(data,this.dataPeserta)
    }

    forceRefresh = () => {
        this.DataGridAkunProductRef.current.forceRefresh()
    }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid 
                    ref = {this.DataGridAkunProductRef}
                    loadAPI = 'kepesertaan-akun-products'
                    insertAPI = 'kepesertaan-akun-products'
                    updateAPI = 'kepesertaan-akun-products'
                    deleteAPI = 'kepesertaan-akun-products'

                    useArraySource = {true}
                    ArraySourceData = {this.retrieveData}

                    backendserver = {process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    showBorders = {true}

                    paging = {true}
                    defaultPageSize = {10}
                    
                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle = {'Data Akun PPMP'}
                    popupWidth = {1000} //masukan dalam ukuran pixel
                    popupHeight = {450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation = 'top' //accepted value = top, left, right
                    popupFormMinColWidth = {300} // minimum lebar kolom
                    popupFormColCount = {3} //jumlah kolom pada form
                    
                    //akhir bagian konfigurasi popup

                    ColumnChooser = {false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing = {false} // set false agar kolom tidak dapat di freeze

                    FilterRow = {true} // set false untuk mematikan fitur filter
                    
                    ColumnConfiguration={this.dataGridAkun} // taruh konfigurasi kolom disini
                    onToolbarPreparing = {this.onToolbarPreparingDataAkun}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                    
                    store = {this.props.store} // jangan di edit edit
                />
                <ProsesDataPemberhentianPeserta
                    ref = {this.showModalActionViewRef}
                    store = {this.props.store}
                    submitData = {this.props.submitData}
                    actionType = {this.props.actionType}
                    forceRefresh = {this.forceRefresh}
                />
            </React.Fragment>
        )
    }
}

export default FormDataPemberhentianPeserta
