import React, { Component } from 'react'
import DevExpressDataGrid from '../../../../inheritComponent/devexpressdatagrid'

class KewajibanDepan extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                dataField: 'itemKewajiban',
                caption: 'Item Kewajiban',
            },
            {
                dataField: 'nilaiKewajiban',
                caption: 'Nilai Kewajiban',
            },
        ]
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Kewajiban Depan</h2>

                <DevExpressDataGrid
                    loadAPI='kewajiban-depans'
                    insertAPI='kewajiban-depans'
                    updateAPI='kewajiban-depans'
                    deleteAPI='kewajiban-depans' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"KEwajiban Dapen"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Kewajiban Depan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default KewajibanDepan
