import React, { Component } from 'react'
import { formatDate} from 'plugin/helper';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalDetailPengkinianData from './detail-pengkinian-data';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormDataPesertaPengkinianData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pesertaId: 0,
            formData: {}
        }

        this.formRef = React.createRef()
        this.dataGridPesertaPengkinianDataRef = React.createRef()
        this.modalDetailPengkinianDataRef = React.createRef()

        this.items = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        onClick : (e) => {
                            this.showModal(e)
                        },
                    },
                    {
                        text : 'Simpan',
                        hint : 'Simpan',
                        onClick : (e) => {
                            this.submitData(e.row.data)
                        },
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
                format: '#,##0.00'
            },
            {
                dataField: 'fileSkPhdpGaji',
                caption: 'File SK PHDP Gaji',
            },
            {
                dataField: 'fileKtp',
                caption: 'File KTP',
            },
            {
                dataField: 'fileKk',
                caption: 'File KK',
                // type: 'buttons',
                // buttons: [
                //     {
                //         text : 'Upload',
                //         hint : 'Upload',
                //         onClick : (e) => {
                //             console.log(e)
                //         },
                //     },
                //     {
                //         text : 'View',
                //         hint : 'View',
                //         onClick : (e) => {
                //             console.log(e)
                //         },
                //     },
                // ],
            },
            {
                dataField: 'fileSkPengangkatan',
                caption: 'File SK Pengangkatan',
            }
        ]

        this.getKpId = 0
    }

    forceRefresh = () => {
        this.dataGridPesertaPengkinianDataRef.current.forceRefresh(true)
    }

    submitData = async (data) => {
        if (data) {
            var getPesertaId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/getByNik/${data.nik}`, 'GET')
            await this.updateDataPeserta(data,getPesertaId.id)
            await this.updateDataPesertaKepegawaian(data,getPesertaId.id)

            if (data.ppmpStatusId === 1) {
                if (data.ppmpIuranPesertaNormal != null && data.ppmpIuranPesertaNormal != 0) { 
                    this.updatePesertaIuranNormal(data, getPesertaId.id, 1, 1, data.ppmpIuranPesertaNormal)
                }

                if (data.ppmpIuranPesertaMpLain != null && data.ppmpIuranPesertaNormal != 0) { 
                    this.updatePesertaIuranManfaatLain(data, getPesertaId.id, 1, 2, data.ppmpIuranPesertaMpLain)
                }

                if (data.ppmpIuranPkNormal != null && data.ppmpIuranPkNormal != 0) { 
                    this.updatePesertaIuranNormalPk(data, getPesertaId.id, 1, 3, data.ppmpIuranPkNormal)
                }

                if (data.ppmpIuranPkMpLain != null && data.ppmpIuranPkMpLain != 0) { 
                    this.updatePesertaIuranManfaatLainPk(data, getPesertaId.id, 1, 4, data.ppmpIuranPkMpLain)
                }

                if (data.ppmpIuranSukarela != null && data.ppmpIuranSukarela != 0) {
                    this.updatePesertaIuranSukarela(data, getPesertaId.id, 1, 6, data.ppmpIuranSukarela)
                }
            }

            if (data.ppipStatusId === 1) {
                if (data.ppipIuranPesertaNormal != null && data.ppipIuranPesertaNormal != 0) { 
                    this.updatePesertaIuranNormal(data, getPesertaId.id, 2, 1, data.ppipIuranPesertaNormal)
                }

                if (data.ppipIuranPesertaMpLain != null && data.ppipIuranPesertaMpLain != 0) { 
                    this.updatePesertaIuranManfaatLain(data, getPesertaId.id, 2, 2, data.ppipIuranPesertaMpLain)
                }

                if (data.ppipIuranPkNormal != null && data.ppipIuranPkNormal != 0) { 
                    this.updatePesertaIuranNormalPk(data,getPesertaId.id,  2, 3, data.ppipIuranPkNormal)
                }

                if (data.ppipIuranPkMpLain != null && data.ppipIuranPkMpLain != 0) { 
                    this.updatePesertaIuranManfaatLainPk(data, getPesertaId.id, 2, 4, data.ppipIuranPkMpLain)
                }

                if (data.ppipIuranSukarela != null && data.ppipIuranSukarela != 0) { 
                    this.updatePesertaIuranSukarela(data, getPesertaId.id, 2, 6, data.ppipIuranSukarela)
                }
            }
            notify({ message: 'Sukses', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        }
    }

    updateDataPeserta = async (data, pesertaId) => {
        try {
            var loadApi = `pesertas`
            var getStatusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'status-pajaks', 'GET')
            var statusPajakId = getStatusPajak.find(value => value.statusName === data.statusPajak)

            var datas = {
                id:pesertaId,
                birthDate:data.birthDate,
                birthPlace:data.birthPlace,
                daftarPenerimaDate:data.daftarPenerimaDate,
                domicileAddress1:data.domicileAddress1,
                domicileCityId:data.domicileCityId,
                domicileCountryId:130,
                domicileKecamatanId:data.domicileKecamatanId,
                domicileKelurahanId:data.domicileKelurahanId,
                domicilePostalCode:data.domicilePostalCode,
                domicileProvinceId:data.domicileProvinceId,
                educationId:data.educationId,
                email:data.email,
                email2:data.email2, 
                fileDate:data.fileDate, 
                fullName:data.fullName,
                gelarBelakang:data.gelarBelakang,
                gelarDepan:data.gelarDepan,
                identityNo:data.noKtp,
                identityExpired:"9999-12-31",
                identityAddress1:data.identityAddress1,
                identityCityId:data.identityCityId,
                identityCountryId:130,
                identityKecamatanId:data.identityKecamatanId,
                identityKelurahanId:data.identityKelurahanId,
                identityPostalCode:data.identityPostalCode,
                identityProvinceId:data.identityProvinceId,
                kodeBank:data.kodeBank, 
                marriedId:data.marriedId,
                mkBulan:data.mkBulan,
                mkTahun:data.mkTahun,
                mobileNo:data.mobileNo,
                mobilePhone2:data.mobileNo2,
                nationality:data.nationality,
                nickName:data.nickName,
                nik:data.nik,
                noBpjsKesehatan:data.noBpjsKesehatan,
                noBpjsTk:data.noBpjsTk,
                noKk:data.noKk, 
                noKtp:data.noKtp, 
                npwp:data.npwp, 
                participantId:data.participantId,
                pemberhentianDate:data.pemberhentianDate,
                pemberhentianSkDate:data.pemberhentianSkDate,
                pemberhentianSkNo:data.pemberhentianSkNo,
                pendidikanJurusan:data.pendidikanJurusan,
                pendidikanLembaga:data.pendidikanLembaga,
                pensiunAddress:data.pensiunAddress,
                pensiunCityId:data.pensiunCityId,
                pensiunDate:data.pensiunDate,
                pensiunKecamatanId:data.pensiunKecamatanId,
                pensiunKelurahanId:data.pensiunKelurahanId,
                pensiunKodeBank:data.pensiunKodeBank,
                pensiunNamaRekening:data.pensiunNamaRekening,
                pensiunNoRekening:data.pensiunNoRekening,
                pensiunPostalCode:data.pensiunPostalCode,
                pensiunProvinceId:data.pensiunProvinceId,
                pensiunSkDate:data.pensiunSkDate,
                pensiunSkNo:data.pensiunSkNo,
                phoneNo:data.phoneNo,
                ppipIuranPesertaMpLain:data.ppipIuranPesertaMpLain,
                ppipIuranPesertaNormal:data.ppipIuranPesertaNormal,
                ppipIuranPkMpLain:data.ppipIuranPkMpLain,
                ppipIuranPkNormal:data.ppipIuranPkNormal,
                ppipIuranSukarela:data.ppipIuranSukarela,
                ppipStatusId:data.ppipStatusId,
                ppmpIuranPesertaMpLain:data.ppmpIuranPesertaMpLain,
                ppmpIuranPesertaNormal:data.ppmpIuranPesertaNormal,
                ppmpIuranPkMpLain:data.ppmpIuranPkMpLain,
                ppmpIuranPkNormal:data.ppmpIuranPkNormal,
                ppmpIuranSukarela:data.ppmpIuranSukarela,
                ppmpStatusId:data.ppmpStatusId,
                rekeningNama:data.rekeningNama,
                rekeningNo:data.rekeningNo,
                religionId:data.religionId,
                sex:data.sex,
                statusKeluarga:data.statusKeluarga,
                statusPajakId:statusPajakId,
                statusTrt:data.statusTrt,
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'PUT',{
                values:datas,
                key:pesertaId
            },datas)

            return response
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan Simpan Peserta', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    updateDataPesertaKepegawaian = async (data, pesertaId) => {
        try {
            var getPkId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-kepegawaians/getByPesertaId/${pesertaId}`, 'GET')
            var loadApi = `peserta-kepegawaians`

            var datas = {
                id:getPkId.id,
                nik:data.nik,
                nikPanjang:data.nikPanjang,
                statusKepegawaianId:data.statusKepegawaianId,
                unitKerjaCode:data.unitKerjaCode,
                unitKerjaName:data.unitKerjaName,
                unitKerja1Diatas:data.unitKerja1Diatas,
                unitKerja2Diatas:data.unitKerja2Diatas,
                unitKerja3Diatas:data.unitKerja3Diatas,
                jenisKantorName:data.jenisKantorName,
                statusKaryawanId:data.statusKaryawanId,
                statusKaryawanTanggal:data.statusKaryawanTanggal,
                tanggalEfektif:data.tanggalEfektif,
                grade:data.grade, 
                tmtGradeSetingkat:data.tmtGradeSetingkat,
                mkTahunGrade:data.mkTahunGrade,
                mkBulanGrade:data.mkBulanGrade,
                jabatanName:data.jabatanName, 
                jabatanPenugasan:data.jabatanPenugasan, 
                jabatanTmt:data.jabatanTmt, 
                penugasanGrade:data.penugasanGrade,
                unitKerjaPenugasan:data.unitKerjaPenugasan,
                penugasanTanggal:data.penugasanTanggal,
                indexPredikat:data.indexPredikat, 
                noSkTerakhir:data.noSkTerakhir,
                tanggalBerhenti:data.tanggalBerhenti,
                namaAtasan:data.namaAtasan,
                kodeMis:data.kodeMis,
                phdp:data.phdp,
                upnp:data.upnp,
                takeHomePay:data.takeHomePay,
                pesertaId:pesertaId
            }

            console.log(datas);
            
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'PUT',{
                values:datas,
                key:getPkId.id
            },datas)
    
            return response
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan Simpan Peserta', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        
        }
        
    }

    updatePesertaIuranNormal = async(data,pesertaId, productId, iuranTypeId, iuranNominal) => {
        try {
            var loadAPI = `peserta-akun-produk-iurans`
            var getKpId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getAllByPesertaIdAndProductId/${pesertaId}/${productId}`, 'GET')
            var Kpid = getKpId.find(value => value.productIds == productId)
            var getIuranId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${Kpid.ids}`, 'GET')
            var iuranId = getIuranId.find(value => value.iuranTypeId == iuranTypeId)
            var datas = {
                pesertaAkunProdukId:Kpid.ids,
                iuranTypeId:iuranTypeId,
                iuranNominal:iuranNominal,
                efektifDate:data.tanggalEfektif,
                skNo:"",
                productId: productId,
                id:iuranId.id,
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT',{
                values:datas,
                key:iuranId.id
            },datas)

            return response
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan Simpan PPMP Iuran Normal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
        
    }

    updatePesertaIuranManfaatLain = async (data,pesertaId, productId, iuranTypeId, iuranNominal) => {
        try {
            var loadAPI = `peserta-akun-produk-iurans`
            var getKpId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getAllByPesertaIdAndProductId/${pesertaId}/${productId}`, 'GET')
            var Kpid = getKpId.find(value => value.productIds == productId)
            var getIuranId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${Kpid.ids}`, 'GET')
            var iuranId = getIuranId.find(value => value.iuranTypeId == iuranTypeId)

            var datas = {
                pesertaAkunProdukId:Kpid.ids,
                iuranTypeId:iuranTypeId,
                iuranNominal:iuranNominal,
                efektifDate:data.tanggalEfektif,
                skNo:"",
                productId: productId,
                id:iuranId.id,
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT',{
                values:datas,
                key:iuranId.id
            },datas)

            return response
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan Simpan PPMP Iuran Normal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    updatePesertaIuranNormalPk = async (data,pesertaId, productId, iuranTypeId, iuranNominal) => {
        try {
            var loadAPI = `peserta-akun-produk-iurans`
            var getKpId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getAllByPesertaIdAndProductId/${pesertaId}/${productId}`, 'GET')
            var Kpid = getKpId.find(value => value.productIds == productId)
            var getIuranId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${Kpid.ids}`, 'GET')
            var iuranId = getIuranId.find(value => value.iuranTypeId == iuranTypeId)

            var datas = {
                pesertaAkunProdukId:Kpid.ids,
                iuranTypeId:iuranTypeId,
                iuranNominal:iuranNominal,
                efektifDate:data.tanggalEfektif,
                skNo:"",
                productId: productId,
                id:iuranId.id,
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT',{
                values:datas,
                key:iuranId.id
            },datas)

            return response
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan Simpan PPMP Iuran Normal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    updatePesertaIuranManfaatLainPk = async (data,pesertaId, productId, iuranTypeId, iuranNominal) => {
        try {
            var loadAPI = `peserta-akun-produk-iurans`
            var getKpId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getAllByPesertaIdAndProductId/${pesertaId}/${productId}`, 'GET')
            var Kpid = getKpId.find(value => value.productIds == productId)
            var getIuranId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${Kpid.ids}`, 'GET')
            var iuranId = getIuranId.find(value => value.iuranTypeId == iuranTypeId)

            var datas = {
                pesertaAkunProdukId:Kpid.ids,
                iuranTypeId:iuranTypeId,
                iuranNominal:iuranNominal,
                efektifDate:data.tanggalEfektif,
                skNo:"",
                productId: productId,
                id:iuranId.id,
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT',{
                values:datas,
                key:iuranId.id
            },datas)

            return response
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan Simpan PPMP Iuran Normal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    updatePesertaIuranSukarela = async (data,pesertaId, productId, iuranTypeId, iuranNominal) => {
        try {
            var loadAPI = `peserta-akun-produk-iurans`
            var getKpId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getAllByPesertaIdAndProductId/${pesertaId}/${productId}`, 'GET')
            var Kpid = getKpId.find(value => value.productIds == productId)
            var getIuranId = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${Kpid.ids}`, 'GET')
            var iuranId = getIuranId.find(value => value.iuranTypeId == iuranTypeId)

            var datas = {
                pesertaAkunProdukId:Kpid.ids,
                iuranTypeId:iuranTypeId,
                iuranNominal:iuranNominal,
                efektifDate:data.tanggalEfektif,
                skNo:"",
                productId: productId,
                id:iuranId.id,
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT',{
                values:datas,
                key:iuranId.id
            },datas)

            return response
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan Simpan PPMP Iuran Normal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    showModal = (data) => {
        this.modalDetailPengkinianDataRef.current.show()
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Simpan',
                    onClick: (e) => {
                        for(var idData of this.selectedRowKeys){
                            var filterData = this.props.data.find(value => value.id === idData)
                            this.submitData(filterData)
                        }
                    },
                } 
            },
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'plus',
            //         onClick: (e) => {
            //             this.dataGridPesertaAktifBaruRef.current.forceRefresh(true)
            //         },
            //     }
            // },
            
        )
    }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridPesertaPengkinianDataRef}
                    loadAPI={`pesertas`}
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData = {this.props.data}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Data Peserta Pengkinian Data Baru"}
                    selection={"multiple"}
                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Peserta Pengkinian Data Baru'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.items} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing = {this.onToolbarPreparingPesertaAktifBaru}
                    onSelectionChanged={this.onSelectionChanged}
                    height = {'calc(100vh - 470px)'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalDetailPengkinianData
                    ref={this.modalDetailPengkinianDataRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormDataPesertaPengkinianData
