import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import {  entity, pembayaranUmumDetilByPuId, pembayaranUmumSdm, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import PPListInvoiceDetailModal from './actionDetail';
import ModalConfirmKirim from './modalConfirmKirim';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { confirmAlert } from 'react-confirm-alert';

class PPListInvoiceModal extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster:{}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.PopupRef = React.createRef()
        this.DataGridDetailRef = React.createRef()
        this.showModalEditReff = React.createRef()
        this.showModalInsertReff = React.createRef()
        this.showModalConfirmKirimRef = React.createRef()
        this.idDataInvoice = 0
        this.dataPembayaranUmum = {}
        this.noPengajuan = ""
        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        cssClass: 'text-primary',
                        onClick : async (e) => {
                            if (e.row.data.statusId === 2) {
                                await alert("Data tidak bisa dirubah karena sudah terkirim","Gagal")
                            }else if(e.row.data.statusId === 3){
                                await alert("Data tidak bisa dirubah karena sudah dibatalkan","Gagal")
                            }else{
                                this.showModalEdit(e)
                            }
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.hapusInvoice(e.row.data)
                        }
                    },
                    {
                        text : 'Kirim',
                        hint : 'Kirim',
                        cssClass: 'text-primary',
                        onClick : async (e) => {
                            if (e.row.data.statusId === 2) {
                                await alert("Data tidak bisa dirubah karena sudah terkirim","Gagal")
                            }else if(e.row.data.statusId === 3){
                                await alert("Data tidak bisa dirubah karena sudah dibatalkan","Gagal")
                            }else{
                                this.showModalConfirmKirim(e)
                            }
                        }
                    },
                ],
            },
            {
                dataField:'invoiceNo',
                caption:'No Invoice'
            },
            {
                dataField:'pembayaranUmumId',
                caption:'Pembayaran Umum',
                lookup:{
                    dataSource:pembayaranUmumSdm(this.props.store),
                    valueExpr:'id',
                    displayExpr:'keterangan'
                }
            },
            {
                dataField:'pembayaran',
                caption:'Pembayaran '
            },
            {
                dataField:'entitasId',
                caption:'Entitas',
                lookup:{
                    dataSource:entity(this.props.store),
                    valueExpr:'id',
                    displayExpr:'entityName'
                }
            },
            {
                dataField:'invoiceDate',
                caption:'Tanggal Invoice',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'dueDate',
                caption:'Jatuh Tempo',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'description',
                caption:'Keterangan'
            },
            {
                dataField:'nominalBersih',
                caption:'Nominal Invoice Bersih',
                dataType:'number',
                format:'#,##0.00'
            },
            {
                dataField:'statusId',
                caption:'Status',
                lookup:{
                    dataSource:[
                        {
                            id:1,
                            value:"Terbuka"
                        },
                        {
                            id:2,
                            value:"Terkirim"
                        },
                        {
                            id:3,
                            value:"Dibatalkan"
                        },
                    ],
                    valueExpr:'id',
                    displayExpr:'value'
                }
            },
        ]
    }

    retrieveData = async (data) => {
        this.dataPembayaranUmum = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umums/${data.id}`, 'GET',)
        this.noPengajuan = data.noPengajuan
        this.idDataInvoice = data.id

        this.setState({
            dataMaster: data,
        })

        this.DataGridDetailRef.current.forceRefresh(true)
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.dataPembayaranUmum = {}
        this.noPengajuan = ""
        this.idDataInvoice = 0
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }

    hapusInvoice = async (data) => {
        try {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-invoices/deleteMasterAndDetailInvoice`, 'DELETE',data.id)
            this.forceRefresh()
            await alert('Data berhasil dihapus','Berhasil')
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    showModalConfirmKirim = (e) => {
        this.showModalConfirmKirimRef.current.show()
        this.showModalConfirmKirimRef.current.retrieveData(e.row.data)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'invoice',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            },
        )
    }

    showModalInsert = () => {
        this.showModalInsertReff.current.show()
        this.showModalInsertReff.current.getEntity()
        this.showModalInsertReff.current.getNoPengajuan(this.state.dataMaster)
        this.showModalInsertReff.current.getDataPembayaranUmum(this.dataPembayaranUmum)
    }

    showModalEdit = (e) => {
        this.showModalEditReff.current.retrieveData(e.row.data)
        this.showModalEditReff.current.show()
        this.showModalEditReff.current.getEntity()
        this.showModalInsertReff.current.getNoPengajuan(this.state.dataMaster)
        this.showModalInsertReff.current.getDataPembayaranUmum(this.dataPembayaranUmum)
    }

    forceRefresh = () => {
        this.DataGridDetailRef.current.forceRefresh(true)
    }

    loadDataTransaksi = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-invoices/getByPembayaranUmumId/${this.idDataInvoice}`, 'GET',)
        return  response
    }


    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Invoice Pembayaran Umum'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <DevExpressDataGrid 
                                ref = {this.DataGridDetailRef}
                                loadAPI = 'pembayaran-details'
                                insertAPI = 'pembayaran-details'
                                updateAPI = 'pembayaran-details'
                                deleteAPI = 'pembayaran-details'

                                backendserver = {process.env.REACT_APP_BACKEND_HRD}

                                useArraySource={true}
                                ArraySourceData={this.loadDataTransaksi}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                showBorders = {true}

                                paging = {true}
                                defaultPageSize = {10}
                                
                                editingMode={'popup'}
                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle = {'Data Detail Biaya'}
                                popupWidth = {500} //masukan dalam ukuran pixel
                                popupHeight = {400} //masukkan dalam ukuran pixel

                                popupFormLabelLocation = 'left' //accepted value = top, left, right
                                popupFormMinColWidth = {300} // minimum lebar kolom
                                popupFormColCount = {1} //jumlah kolom pada form
                                height={400}
                                //akhir bagian konfigurasi popup

                                ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

                                FilterRow = {true} // set false untuk mematikan fitur filter
                                
                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                onToolbarPreparing = {this.onToolbarPreparing}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                                
                                store = {this.props.store} // jangan di edit edit
                            /> 
                        </div>
                    </div> 
                </div>
                </ScrollView>
                <PPListInvoiceDetailModal
                    ref={this.showModalInsertReff}
                    store={this.props.store}
                    action={'add'}
                    forceRefresh={this.forceRefresh}
                />
                <PPListInvoiceDetailModal
                    ref={this.showModalEditReff}
                    store={this.props.store}
                    action={'edit'}
                    forceRefresh={this.forceRefresh}
                />
                <ModalConfirmKirim
                    ref={this.showModalConfirmKirimRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    noPengajuan = {this.noPengajuan}
                />
            </Popup>
        )
    }
}

export default PPListInvoiceModal