import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { gender, statusKeluarga, agama } from 'dataSource/lookup';

class FormPegawai extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData : {} 
        }

        this.items = [
            {
                dataField: 'tempatLahir',
                label: {
                    text: 'Tempat Lahir',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
                colSpan: 2
            },
            {
                dataField: 'jenisKelamin',
                label: {
                    text: 'Jenis Kelamin',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
                colSpan: 2
            },
            {
                dataField: 'tanggalLahir',
                label: {
                    text: 'Tanggal Lahir',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                },
                colSpan: 2
            },
            {
                dataField: 'statusKeluarga',
                label: {
                    text: 'Status Keluarga',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusKeluarga(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'jumlahTertanggung',
                label: {
                    text: 'Jumlah Tertanggung',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'agama',
                label: {
                    text: 'Agama',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: agama(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
                colSpan: 2
            },
            {
                dataField: 'jabatan',
                label: {
                    text: 'Jabatan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Direktur Keuangan'
                        },
                        {
                            id: 2,
                            value: 'Direktur Management'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
                colSpan: 2
            },
            {
                dataField: 'alamat',
                label: {
                    text: 'Alamat',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    minHeight: '100px',
                    autoResizeEnabled: true
                },
                rowSpan: 2,
                colSpan: 2
            },
            {
                dataField: 'unitKerja',
                label: {
                    text: 'Unit Kerja',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Pengurus'
                        },
                        {
                            id: 2,
                            value: 'Direktur Management'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
                colSpan: 2
            },
            {
                itemType: 'empty',
                colSpan: 2 
            },
            {
                dataField: 'tmtBekerja',
                label: {
                    text: 'TMT Bekerja',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                },
                colSpan: 2
            },
            {
                dataField: 'noTelp',
                label: {
                    text: 'Nomor Telepon',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxNumberBox',
                colSpan: 2
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    minHeight: '100px',
                    autoResizeEnabled: true
                },
                rowSpan: 2,
                colSpan: 2
            },
            {
                dataField: 'noHp',
                label: {
                    text: 'Nomor Handphone',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxNumberBox',
                colSpan: 2
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                {/* <div style={{backgroundColor : '#f7f7f7'}}> */}
                    <Form
                        colCount={4}
                        id={'form'}
                        formData={this.state.formData}
                        items={this.items}
                        scrollingEnabled={true}
                        ref={this.formRef}
                    />
                {/* </div> */}
            </React.Fragment>
        )
    }
}

export default FormPegawai