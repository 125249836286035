import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource, yearsDiff } from 'plugin/helper';
import { banks, mpPenerimaType, pensiunType, phkType, product, rekBank, statusPegawai } from 'dataSource/lookup';
import { FileUploader, ScrollView } from 'devextreme-react';
import ModalPreviewImage from '../../../compPeserta/previewImage';
import ModalPreviewPdf from '../../../compPeserta/previewPdf';
import { alert } from 'devextreme/ui/dialog';

class   ProsesDataPemberhentianPeserta extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async() => {
                        var formValidate = this.validate()
                        if(formValidate){
                            await this.props.submitData(this.state.dataMaster)
                            this.hide()
                            this.props.forceRefresh()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.PopupToolbarItemView = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                itemType: 'group',
                name: 'master',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pensiunType',
                        label: {
                            text: 'Jenis Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pensiunType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'statusName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly : true
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'skPensiunNo',
                        label: {
                            text: 'No SK Pensiun'
                        },
                    },
                    {
                        dataField: 'skPemberhentianNo',
                        label: {
                            text: 'No SK PHK'
                        },
                    },
                    {
                        dataField: 'pesertaPhkTypeId',
                        label: {
                            text: 'Jenis PHK'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: statusPegawai(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'statusName'
                        }
                    },
                    {
                        dataField: 'tglPemberhentian',
                        name: 'tglPemberhentian',
                        label: {
                            text: 'Tanggal Pemberhentian'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'tglPembayaranPemberhentian',
                        name: 'tglPembayaranPemberhentian',
                        label: {
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'masaKerja',
                        label: {
                            text: 'Masa Kerja'
                        },
                        editorOptions:{
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'usia',
                        label: {
                            text: 'Usia Saat Pemberhentian'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'totalIuran',
                        label: {
                            text: 'Total Iuran'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'unitPeserta',
                        label: {
                            text: 'Unit Peserta'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pengembangan',
                        label: {
                            text: 'Pengembangan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'rekeningPengembalianIuran',
                caption: 'Rekening Pengembalian Iuran',
                cssClass: 'mt-5',
                items: [
                    {
                        dataField: 'mpPenerimaTypeId',
                        label: {
                            text: 'Penerima'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpPenerimaType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'penerimaName'
                        }
                    },
                    {
                        dataField: 'pengembalianRekeningNo',
                        label: {
                            text: 'No Rek Pengembalian Iuran'
                        }
                    },
                    {
                        dataField: 'pengembalianRekeningName',
                        label: {
                            text: 'Nama Rek Pengembalian Iuran'
                        }
                    },
                    {
                        dataField: 'pengembalianRekeningBankId',
                        label: {
                            text: 'Bank Pengembalian Iuran'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName'
                        }
                    },
                    {
                        dataField: 'transferRekbankId',
                        label: {
                            text: 'Rekening Bank Transfer MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'rekName'
                        }
                    },
                ]
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show = (dataAkun) => {
        dataAkun.pensiunType = 6

        if(dataAkun.tglPemberhentian && dataAkun.efektifDate){
            var date1 = new Date(dataAkun.efektifDate)
            var date2 = new Date(dataAkun.tglPemberhentian)
            var diffYears = yearsDiff(dataAkun.tglPemberhentian,dataAkun.efektifDate)
            var diffMonths = date2.getMonth()-date1.getMonth();
            var masaKerja
            if(diffYears && diffMonths){
                masaKerja = `${diffYears} Tahun ${diffMonths} Bulan`
            }

            if(!diffYears && diffMonths){
                masaKerja = `${diffMonths} Bulan`
            }

            if(diffYears && !diffMonths){
                masaKerja = `${diffYears} Tahun`
            }

            dataAkun.masaKerja = masaKerja
        }

        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: dataAkun      
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention
            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}Name`]: formattedFileNameAndExtention.name,
                        [`${fieldName}Extention`]: formattedFileNameAndExtention.extention,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    },
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`]),this.state.dataMaster[`${fileName}Name`])
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Proses Data Pemberhentian'} 
                width={1000}

                ref={this.PopupRef}

                toolbarItems={this.props.actionType == 'view' ? this.PopupToolbarItemView : this.PopupToolbarItem}
            >
                <ScrollView height='100%' width='100%'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation = 'left'
                                />
                            </div>
                            {/* fileSkPensiun */}
                            <div className="col-md-6">
                                <FileUploader 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText={this.state.dataMaster.fileSkPensiunName || 'File SK Pensiun'}
                                    showFileList={false}
                                    name={'fileSkPensiun'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSkPensiun')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPensiun ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSkPensiun')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPensiun ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileSkPhk */}
                            <div className="col-md-6">
                                <FileUploader 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText={this.state.dataMaster.fileSkPhkName || 'File SK PHK'}
                                    showFileList={false}
                                    name={'fileSkPhk'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSkPhk')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPhk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSkPhk')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPhk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        );
    }
}


export default ProsesDataPemberhentianPeserta