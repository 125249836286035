import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { formatUploadFileData } from 'plugin/helper'
import { currency, instrumentProperti, kjpp, productDefault } from 'dataSource/lookup'
import ScrollView from 'devextreme-react/scroll-view'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import ModalActionValuasProperti from './action'
import notify from 'devextreme/ui/notify'

class ModalValuasiProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()
        this.modalActionRef = React.createRef()

        this.instrumentData = []

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    onValueChanged: (e) => {
                        this.insertEfekBalance(e.value)
                    }
                }
            },
            {
                dataField: 'noValuasi',
                label: {
                    alignment: 'left',
                    text: 'No Valuasi'
                },
                editorOptions: {
                    readOnly: true,
                }
            },
            {
                dataField: 'valuasiTahun',
                label: {
                    alignment: 'left',
                    text: 'Tahun Valuasi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    step: 0,
                }
            },
            {
                dataField: 'valuasiTanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Valuasi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'd MMM yyyy'
                }
            },
            {
                dataField: 'tanggalTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'd MMM yyyy'
                }
            },
            {
                dataField: 'kjpp',
                label: {
                    alignment: 'left',
                    text: 'KJPP'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kjpp(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kjppName', // provides display values
                },
            },
            {
                dataField: 'tanggalMulaiPenilaian',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Mulai Penilaian'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'd MMM yyyy'
                }
            },
            {
                dataField: 'tanggalAkhirPenilaian',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Akhir Penilaian'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'd MMM yyyy'
                }
            },
            {
                dataField: 'catatanValuasi',
                label: {
                    alignment: 'left',
                    text: 'Catatan Valuasi'
                },
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Mata Uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                    onSelectionChanged: (e) => {
                        var slctd = e.selectedItem
                        if (slctd) {
                            this.formRef.current.instance.updateData({ currencyRate: slctd.rate })
                        }
                    }
                },
            },
            {
                dataField: 'currencyRate',
                label: {
                    alignment: 'left',
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                }
            },
            {
                dataField: "file",
                label: {
                    text: "File Dokumen",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    accept: 'image/png, image/jpeg, application/pdf',
                    onValueChanged: this.onUploadStarted.bind(this),
                },
            },
        ]
        this.column = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            this.modalActionRef.current.show(data)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            this.deleteDetailValuasiData(data.id)
                        }
                    },
                ]
            },
            {
                dataField: 'properti',
                caption: 'Properti',
                lookup: {
                    dataSource: instrumentProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'efekBalanceBuyDateId',
                caption: 'ID'
            },
            {
                dataField: 'tipe',
                caption: 'Unit/Tipe/KAV'
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'tanggalPerolehan',
                caption: 'Tanggal Perolehan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'penilaian',
                caption: 'Penilaian',
                dataType: 'boolean'
            },
            {
                dataField: 'bookValue',
                caption: 'Nilai Buku',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'nilaiWajar',
                caption: 'Nilai Valuasi',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'spi',
                caption: 'SPI',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            }
        ]
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Dokumen`]: formattedFileData.base64data,
                    [`${fieldName}DokumenContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }
            fr.readAsDataURL(files)
            // this.formRef.current.instance.updateData('documentName', files.name)
        }
    }
    show = async (type, data) => {
        this.setState({
            popupVisible: true,
            actionType: type,
            dataMaster: data,
            fileDokumen: data.fileDokumen,
            fileDokumenContentType: data.fileDokumenContentType,
        })
    }

    cleanDataGrid = () => {
        var data = this.dataGridRef.current.getDataSource()
        var deleteData = []
        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            this.dataGridRef.current.push(deleteData)
        }
    }

    insertEfekBalance = async (productId) => {
        this.cleanDataGrid()
        var getEfekBalance = []
        if (this.state.actionType == 'add') {
            let loadAPI = `efek-balance-buy-dates?categoryId.in=8&productId.in=${productId}&quantity.greaterThan=0`
            getEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
            for (var data of getEfekBalance) {
                this.dataGridRef.current.push([{
                    type: 'insert',
                    data: {
                        id: data.id,
                        efekBalanceBuyDateId: data.id,
                        tipe: data.tipe,
                        keterangan: data.keterangan,
                        tanggalPerolehan: data.buyDate,
                        properti: data.instrumentId,
                        bookValue: data.bookValue,
                        nilaiWajar: 0,
                        spi: 0,
                        penilaian: false,
                    }
                }])
            }
        } else {
            getEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-valuasi-dtls?efekValuasiId.in=${this.state.dataMaster.id}`, 'GET')
            for (var data of getEfekBalance) {
                var dataBalanceBuyDate = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/${data.efekBalanceBuyDateId}`, 'GET')
                this.dataGridRef.current.push([{
                    type: 'insert',
                    data: {
                        id: data.id,
                        efekBalanceBuyDateId: data.efekBalanceBuyDateId,
                        tipe: dataBalanceBuyDate.tipe,
                        keterangan: dataBalanceBuyDate.keterangan,
                        tanggalPerolehan: dataBalanceBuyDate.buyDate,
                        properti: dataBalanceBuyDate.instrumentId,
                        bookValue: data.bookValue,
                        nilaiWajar: data.nilaiWajar,
                        spi: data.spi,
                        penilaian: false,
                    }
                }])
            }
        }
        this.dataGridRef.current.forceRefresh(true)
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    deleteDetailValuasiData = (key) => {
        this.dataGridRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    submitData = async () => {
        var dataMaster = this.state.dataMaster
        var dataDetail = this.dataGridRef.current.getDataSource()
        var result = []
        for (var detail of dataDetail) {
            var data = {
                bookValue: detail.bookValue,
                efekBalanceBuyDateId: detail.efekBalanceBuyDateId,
                nilaiWajar: detail.nilaiWajar,
                spi: detail.spi,
                categoryId: 8,
                currencyId: dataMaster.currencyId,
                currencyRate: dataMaster.currencyRate,
            }
            result.push(data)
        }
        var dataSubmit = {
            ...dataMaster,
            categoryId: 8,
            fileName: this.state.fileName,
            fileDokumen: this.state.fileDokumen,
            fileDokumenContentType: this.state.fileDokumenContentType,
            valuasiPropertiDtlDTOList: result
        }
        if (dataSubmit) {
            if(this.state.actionType == 'add'){
                try {
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'efek-valuasis/valuasi-properti', 'POST', { values: dataSubmit })
                    if (response) {
                        notify({ message: 'Sukses Menyimpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.hide()
                        this.props.forceRefresh()
                        return response
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }else {

            }
        }
    }
    submitDetailValuasi = (data) => {
        if (data.id) {
            this.dataGridRef.current.push([{
                type: "update",
                data: {
                    nilaiWajar: data.nilaiWajar,
                    spi: data.spi,
                },
                key: data.id
            }])
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Valuasi Aset'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <DevExpressDataGrid
                                    ref={this.dataGridRef}
                                    loadAPI='portfolios'
                                    insertAPI='portfolios'
                                    updateAPI='portfolios'
                                    deleteAPI='portfolios'

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                                    useArraySource={true}
                                    ArraySourceData={[]}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={true}
                                    exportFileName={"Valuasi Properti"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Valuasi Properti'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.column} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <ModalActionValuasProperti
                                    ref={this.modalActionRef}
                                    store={this.props.store}
                                    submitDetailValuasi={this.submitDetailValuasi}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalValuasiProperti