import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { confirm, alert } from "devextreme/ui/dialog";
import httpRequest from "plugin/httprequest";
import { coa, entity, fixedAssetByStatus } from "dataSource/lookup";
import uuidv4 from 'uuid/v4'
import { formatTimeOnly, formatyyyymmddDate, postingJournal } from "plugin/helper";

class ModalEditFixedAssetExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {},
            formState: 'view'
        };

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.columns = [
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'fixedAssetExpenseCode',
                        label: {
                            text: 'Kode Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'transDate',
                        label: {
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            max: this.systemDate ? new Date(this.systemDate.dateValue) : null
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'fixedAssetId',
                        label: {
                            alignment: 'left',
                            text: 'Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: fixedAssetByStatus(this.props.store, 'A'),
                            valueExpr: 'id',
                            displayExpr: 'fixedAssetCode',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                var fixedAssetData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `fixed-assets/${e.value}`, 'GET')
                                fixedAssetData.fixedAssetId = fixedAssetData.id
                                delete fixedAssetData.id
                                this.formRef.current.instance.updateData(fixedAssetData)
                            }
                        },
                        isRequired: true
                    },
                    {
                        dataField: 'fixedAssetName',
                        label: {
                            visible: false
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'fixedAssetDesc',
                        label: {
                            text: 'Deskripsi'
                        },
                        colSpan: 3,
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        colSpan: 3,
                        items: [
                            {
                                dataField: 'description',
                                label: {
                                    text: 'Alasan Pemeliharaan'
                                },
                            },
                            {
                                dataField: 'entitiId',
                                label: {
                                    text: 'Entitas'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: entity(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'entityName',
                                    searchEnabled: true
                                },
                                isRequired: true,
                            }
                        ]
                    },

                    {
                        dataField: 'accumDepreciation',
                        label: {
                            text: 'Akum. Penyusutan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bookValue',
                        label: {
                            text: 'Nilai Buku'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        type: 'empty'
                    },
                    {
                        dataField: 'expenseType',
                        label: {
                            text: 'Jenis Pengeluaran'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'E',
                                    value: 'Dibebankan'
                                },
                                {
                                    id: 'A',
                                    value: 'Pengurangan Akum. Penyusutan'
                                },
                                {
                                    id: 'C',
                                    value: 'Dikapitalisasi'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            onValueChanged: (e) => {
                                if (this.state.popupVisible) {
                                    this.formRef.current.instance.beginUpdate()
                                    switch (e.value) {
                                        case 'E': this.formRef.current.instance.itemOption('amount', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountService', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountService', 'isRequired', true)
                                            this.formRef.current.instance.itemOption('amountEmpty1', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountEmpty2', 'visible', true)
                                            break;
                                        case 'A': this.formRef.current.instance.itemOption('amount', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountService', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountService', 'isRequired', false)
                                            this.formRef.current.instance.itemOption('amountEmpty1', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountEmpty2', 'visible', false)
                                            break;
                                        case 'C': this.formRef.current.instance.itemOption('amount', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountService', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountService', 'isRequired', true)
                                            this.formRef.current.instance.itemOption('amountEmpty1', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountEmpty2', 'visible', false)
                                            break;
                                        default: this.formRef.current.instance.itemOption('amount', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountService', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountService', 'isRequired', false)
                                            this.formRef.current.instance.itemOption('amountEmpty1', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountEmpty2', 'visible', true)
                                            break;
                                    }

                                    this.formRef.current.instance.endUpdate()

                                    // this.formRef.current.instance.updateData({
                                    //     amount: 0,
                                    //     amountService: 0
                                    // })
                                }
                            },
                        }
                    },
                    {
                        dataField: 'amountService',
                        label: {
                            text: 'Biaya Servis'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        visible: false,
                        isRequired: true,
                    },
                    {
                        dataField: 'amount',
                        label: {
                            text: 'Biaya Komponen'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        visible: false,
                        isRequired: true,
                    },
                    {
                        name: 'amountEmpty1',
                        type: 'empty',
                    },
                    {
                        name: 'amountEmpty2',
                        type: 'empty',
                    },
                    {
                        dataField: 'paymentMetode',
                        label: {
                            text: 'Metode Pembayaran'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'T',
                                    value: 'Tunai'
                                },
                                {
                                    id: 'K',
                                    value: 'Kredit'
                                },
                                {
                                    id: 'C',
                                    value: 'Campuran'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            onValueChanged: (e) => {
                                if (this.state.popupVisible) {

                                    this.formRef.current.instance.beginUpdate()
                                    // this.formRef.current.instance.itemOption('detail.amountCash','visible',true)
                                    // this.formRef.current.instance.itemOption('detail.amountAp','visible',false)
                                    // this.formRef.current.instance.itemOption('coa.cashCoaId','visible',true)
                                    // this.formRef.current.instance.itemOption('coa.apCoaId','visible',false)
                                    switch (e.value) {
                                        case 'T': this.formRef.current.instance.itemOption('amountCash', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountAp', 'visible', false)
                                            this.formRef.current.instance.itemOption('dueDate', 'visible', false)
                                            this.formRef.current.instance.itemOption('coa.cashCoaId', 'visible', true)
                                            this.formRef.current.instance.itemOption('coa.apCoaId', 'visible', false)
                                            break;
                                        case 'K': this.formRef.current.instance.itemOption('amountCash', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountAp', 'visible', true)
                                            this.formRef.current.instance.itemOption('dueDate', 'visible', true)
                                            this.formRef.current.instance.itemOption('coa.cashCoaId', 'visible', false)
                                            this.formRef.current.instance.itemOption('coa.apCoaId', 'visible', true)
                                            break;
                                        case 'C': this.formRef.current.instance.itemOption('amountCash', 'visible', true)
                                            this.formRef.current.instance.itemOption('amountAp', 'visible', true)
                                            this.formRef.current.instance.itemOption('dueDate', 'visible', true)
                                            this.formRef.current.instance.itemOption('coa.cashCoaId', 'visible', true)
                                            this.formRef.current.instance.itemOption('coa.apCoaId', 'visible', true)
                                            break;
                                        default: this.formRef.current.instance.itemOption('amountCash', 'visible', false)
                                            this.formRef.current.instance.itemOption('amountAp', 'visible', false)
                                            this.formRef.current.instance.itemOption('dueDate', 'visible', false)
                                            this.formRef.current.instance.itemOption('coa.cashCoaId', 'visible', false)
                                            this.formRef.current.instance.itemOption('coa.apCoaId', 'visible', false)
                                            break;
                                    }

                                    // this.formRef.current.instance.updateData({
                                    //     amountCash: 0,
                                    //     amountAp: 0
                                    // })
                                    this.formRef.current.instance.endUpdate()
                                }
                            },
                        },
                        isRequired: true
                    },
                    {
                        dataField: 'amountAp',
                        label: {
                            text: 'Nilai Utang'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        visible: false,
                        isRequired: true
                    },
                    {
                        dataField: 'amountCash',
                        label: {
                            text: 'Nilai Uang Tunai'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        visible: false,
                        isRequired: true
                    },
                    {
                        dataField: 'dueDate',
                        label: {
                            text: 'Jatuh Tempo'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true
                        },
                        visible: false,
                        isRequired: true,
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'coa',
                caption: 'Konfigurasi COA',
                colCount: 3,
                items: [
                    {
                        dataField: 'accumDepreciationCoaId',
                        label: {
                            text: 'Akum. Penyusutan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'assetCoaId',
                        label: {
                            text: 'Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                    {
                        type: 'empty'
                    },
                    {
                        dataField: 'cashCoaId',
                        label: {
                            text: 'Kas'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            wrapItemText: true
                        },
                        isRequired: true,
                        visible: false
                    },
                    {
                        dataField: 'apCoaId',
                        label: {
                            text: 'Utang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            wrapItemText: true
                        },
                        isRequired: true,
                        visible: false
                    },
                    {
                        dataField: 'expenseCoaId',
                        label: {
                            text: 'Beban / Biaya'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            wrapItemText: true
                        },
                        isRequired: true,
                    },
                ]
            }
        ];

        this.formRef = React.createRef()
    }

    PopupToolbarItem = () => {
        if (this.state.formState === 'view') {
            return [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Close",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                }
            ];
        } else {
            return [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan Draft",
                        onClick: async () => {
                            this.submitData('draft')
                        }
                    },
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan",
                        onClick: async () => {
                            this.submitData('submit')
                        }
                    },
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Batal",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                }
            ];
        }
    }

    submitData = async (type) => {
        const { formData } = this.state

        let result = type === 'draft'

        if (type === 'submit') {
            var validateRes = this.formRef.current.instance.validate()

            if ((formData.amount || 0 + formData.amountService || 0) != (formData.amountAp || 0 + formData.amountCash || 0)) {
                var text
                switch (formData.paymentMetode) {
                    case 'T':
                        text = 'Total Biaya Pemeliharaan harus sama dengan nilai uang tunai'
                        break;
                    case 'K':
                        text = 'Total Biaya Pemeliharaan harus sama dengan nilai piutang'
                        break;
                    case 'C':
                        text = 'Total Biaya Pemeliharaan harus sama dengan nilai piutang + nilai uang tunai'
                        break;

                    default:
                        break;
                }

                alert(text, 'Warning')

                validateRes.isValid = false
            }

            if (validateRes.isValid) {
                result = await confirm('Apakah anda yakin untuk melanjutkan proses ini? proses ini tidak dapat di batalkan.', 'Confirmation')
            }
        }

        if (result) {
            var dataExpense = {
                "accumDepreciationCoaId": formData.accumDepreciationCoaId,
                "apCoaId": formData.apCoaId,
                "assetCoaId": formData.assetCoaId,
                "cashCoaId": formData.cashCoaId,
                "description": formData.description,
                "expectedEconomicLifeAdded": formData.expectedEconomicLifeAdded,
                "expenseCoaId": formData.expenseCoaId,
                "expenseType": formData.expenseType,
                "fixedAssetExpenseCode": `FAE-${formatyyyymmddDate(formData.transDate)}${formatTimeOnly(new Date())}`,
                "fixedAssetId": formData.fixedAssetId,
                "paymentMetode": formData.paymentMetode,
                "transDate": formData.transDate,
                "amountService": formData.amountService,
                "amount": formData.amount,
                "amountAp": formData.amountAp,
                "amountCash": formData.amountCash,
                "entitiId": formData.entitiId,
                "dueDate": formData.dueDate,
                "status": type === 'submit' ? 'P' : 'D'
            }

            try {
                var loadAPI = 'fixed-asset-expenses'
                var responseInsert

                if (formData.id) {
                    dataExpense.id = formData.id
                    responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'PUT', {
                        values: dataExpense
                    }, dataExpense)
                } else {
                    responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'POST', {
                        values: dataExpense
                    })
                }

                if (type === 'submit') {
                    //proses jurnal
                    var listJournalType = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-types')
                    var journalTypeExpense = listJournalType.find(value => value.journalTypeCode === '202')

                    var listCcy = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'currencies')
                    var ccyIDR = listCcy.find(value => value.currencyCode === 'IDR')

                    var dataJurnal = []
                    if (dataExpense.expenseType === 'E') {
                        dataJurnal.push({
                            "coaId": dataExpense.expenseCoaId,
                            "currencyId": ccyIDR.id,
                            "dealExrate": 1,
                            "amount": formData.amountService,
                            "departmentId": null,
                            "journaTypeId": journalTypeExpense.id,
                            "journalDesc": "Expense Aset " + formData.fixedAssetCode,
                            "reffCode": "FEXP",
                            "reffId": responseInsert.id,
                            "valueDate": dataExpense.transDate
                        })
                    }

                    if (dataExpense.expenseType === 'A' && dataExpense.expenseType === 'C') {
                        if (formData.amountService > 0) {
                            dataJurnal.push({
                                "coaId": dataExpense.expenseCoaId,
                                "currencyId": ccyIDR.id,
                                "dealExrate": 1,
                                "amount": formData.amountService,
                                "departmentId": null,
                                "journaTypeId": journalTypeExpense.id,
                                "journalDesc": "Expense Aset " + formData.fixedAssetCode,
                                "reffCode": "FEXP",
                                "reffId": responseInsert.id,
                                "valueDate": dataExpense.transDate
                            })
                        }
                        dataJurnal.push({
                            "coaId": dataExpense.accumDepreciationCoaId,
                            "currencyId": ccyIDR.id,
                            "dealExrate": 1,
                            "amount": formData.amount,
                            "departmentId": null,
                            "journaTypeId": journalTypeExpense.id,
                            "journalDesc": "Expense Aset " + formData.fixedAssetCode,
                            "reffCode": "FEXP",
                            "reffId": responseInsert.id,
                            "valueDate": dataExpense.transDate
                        })
                    }

                    if (dataExpense.paymentMetode === 'K') {
                        dataJurnal.push({
                            "coaId": dataExpense.apCoaId,
                            "currencyId": ccyIDR.id,
                            "dealExrate": 1,
                            "amount": -formData.amountAp,
                            "departmentId": null,
                            "journaTypeId": journalTypeExpense.id,
                            "journalDesc": "Expense Aset " + formData.fixedAssetCode,
                            "reffCode": "FEXP",
                            "reffId": responseInsert.id,
                            "valueDate": dataExpense.transDate
                        })
                    }

                    if (dataExpense.paymentMetode === 'T') {
                        dataJurnal.push({
                            "coaId": dataExpense.cashCoaId,
                            "currencyId": ccyIDR.id,
                            "dealExrate": 1,
                            "amount": -formData.amountCash,
                            "departmentId": null,
                            "journaTypeId": journalTypeExpense.id,
                            "journalDesc": "Expense Aset " + formData.fixedAssetCode,
                            "reffCode": "FEXP",
                            "reffId": responseInsert.id,
                            "valueDate": dataExpense.transDate
                        })
                    }

                    if (dataExpense.paymentMetode === 'C') {
                        dataJurnal.push({
                            "coaId": dataExpense.cashCoaId,
                            "currencyId": ccyIDR.id,
                            "dealExrate": 1,
                            "amount": -formData.amountCash,
                            "departmentId": null,
                            "journaTypeId": journalTypeExpense.id,
                            "journalDesc": "Expense Aset " + formData.fixedAssetCode,
                            "reffCode": "FEXP",
                            "reffId": responseInsert.id,
                            "valueDate": dataExpense.transDate
                        })
                        dataJurnal.push({
                            "coaId": dataExpense.apCoaId,
                            "currencyId": ccyIDR.id,
                            "dealExrate": 1,
                            "amount": -formData.amountAp,
                            "departmentId": null,
                            "journaTypeId": journalTypeExpense.id,
                            "journalDesc": "Expense Aset " + formData.fixedAssetCode,
                            "reffCode": "FEXP",
                            "reffId": responseInsert.id,
                            "valueDate": dataExpense.transDate
                        })
                    }

                    var responseJurnal = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'accounting-report/import', 'POST', {
                        values: dataJurnal
                    })

                    for (var jurnal of responseJurnal) {
                        await postingJournal(this.props.store, dataExpense.transDate, this.systemDate ? this.systemDate.dateValue : null, jurnal.id)
                    }

                    //update data fixed asset
                    var dataFixedAsset = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `fixed-assets/${dataExpense.fixedAssetId}`)

                    var dataUpdate
                    if (dataExpense.expenseType === 'A') {
                        dataUpdate = {
                            accumDepreciation: dataFixedAsset.accumDepreciation - dataExpense.amount
                        }
                    }

                    if (dataExpense.expenseType === 'C') {
                        dataUpdate = {
                            accumDepreciation: dataFixedAsset.accumDepreciation - dataExpense.amount,
                            baseAmount: dataFixedAsset.bookValue + dataExpense.amount,
                            economicLife: dataExpense.expectedEconomicLifeAdded
                        }
                    }

                    if (dataExpense.expenseType === 'A' || dataExpense.expenseType === 'C') {
                        var responseUpdateAsset = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'fixed-assets', 'PUT', {
                            values: dataUpdate
                        }, dataFixedAsset)
                    }

                    if (dataExpense.paymentMetode === 'C' || dataExpense.paymentMetode === 'K') {
                        //buat P jika transaksi utang
                        var responseARAP = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'araps', 'POST', {
                            values: {
                                "amount": -dataExpense.amountAp,
                                "currencyId": ccyIDR.id,
                                "defaultBank": null,
                                "dueDate": dataExpense.dueDate,
                                "outstandingAmount": -dataExpense.amountAp,
                                "reffNo": dataExpense.fixedAssetExpenseCode,
                                "reffType": 'FEXP',
                                "tradeDate": dataExpense.transDate,
                                "entitiId": dataExpense.entitiId,
                            }
                        })
                    }
                    notify({ message: 'Data berhasil ditambahkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                } else {
                    notify({ message: 'Data draft berhasil di simpan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }

                this.props.forceRefresh()
                this.hide()
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    setFormState = (type) => {
        this.setState({
            formState: type
        })

        if (type === 'view') {
            this.formRef.current.instance.option('readOnly', true)
        } else {
            this.formRef.current.instance.option('readOnly', false)
        }
    }

    show = (data) => {
        const systemDate = this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE')
        if (data) {
            this.setState({
                popupVisible: true,
                formData: data
            });

            this.formRef.current.instance.option('readOnly', true)
        } else {
            this.setState({
                popupVisible: true,
                formData: {
                    fixedAssetExpenseCode: '<AUTO>',
                    transDate: new Date(systemDate.dateValue),
                    paymentMetode: null,
                    amount: 0,
                    amountService: 0
                }
            });

            this.formRef.current.instance.option('readOnly', false)
        }
    };

    hide = () => {
        this.setState({
            popupVisible: false,
            formData: {}
        });
    };

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Form Fixed Asset Expense"}
                    width={"70vw"}
                    height={"70vh"}
                    toolbarItems={this.PopupToolbarItem()}
                >
                    <Form
                        colCount={1}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.columns}
                        ref={this.formRef}
                        scrollingEnabled={true}
                        labelLocation="left"
                    />

                </Popup>
            </React.Fragment>

        );
    }
}

export default ModalEditFixedAssetExpense