import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager, availableInstrumentStok, currency, emitens } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import ScrollView from 'devextreme-react/scroll-view';


class ModalInstrumentPelepasanSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.formItem = [
            {
                dataField: 'requestNo',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    // dataSource: [],
                    dataSource: availableInstrumentStok(this.props.store, 1),
                    valueExpr: 'instrumentId',
                    displayExpr: function (item) {
                        return item && item.instrumentCode + " - " + item.instrumentName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: async (e) => {
                        var data = e.selectedItem
                        if (data) {
                            this.setState({
                                efekBalanceAvgId: data.efekBalanceAvgId
                            })
                            this.formDetailRef.current.instance.updateData('quantity', data.quantity)
                            this.formDetailRef.current.instance.updateData('currencyId', data.currencyId)
                            this.formDetailRef.current.instance.updateData('avgPrice', data.avgPrice)
                            this.formDetailRef.current.instance.updateData('currencyAvgPrice', data.currencyAvgPrice)
                            this.formDetailRef.current.instance.updateData('faceValue', data.quantity * data.avgPrice)
                            this.formDetailRef.current.instance.updateData('currencyFaceValue', data.currencyFaceValue)

                            var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`)
                            var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `emitens/${instrument.emitenId}`)
                            if(emiten.sektorId !== null) {
                                var sektor = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `sektors/${emiten.sektorId}`)
                                this.formDetailRef.current.instance.updateData('sektor', sektor.sektorName)
                            }
                            this.formDetailRef.current.instance.updateData('emiten', emiten.emitenName)
                            this.formDetailRef.current.instance.updateData('hargaPenutupan', instrument.price)
                            // this.formMasterRef.current.instance.updateData('minOrdPrice', instrument.price)
                            // this.formMasterRef.current.instance.updateData('maxOrdPrice', instrument.price)
                            this.formDetailRef.current.instance.updateData('tanggalHargaPenutupan', instrument.priceDate)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Saham tidak boleh kosong"
                    }
                ]
            },
            {
                dataField: 'ordQty',
                label: {
                    text: 'Kuantitas (Lembar)'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0"
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Kuantitas tidak boleh kosong"
                    },
                    {
                        type: "range",
                        message: "Kuantitas harus lebih dari atau sama dengan 1 Lot (100 Lembar)",
                        min: 100
                    },
                    {
                        type: 'custom',
                        validationCallback: (e) => {
                            if (e.value > this.state.dataDetail.quantity) {
                                return false
                            }
                            return true
                        },
                        message: 'Kuantitas tidak boleh lebih dari yang dimiliki'
                    }
                ]
            },
            {
                itemType: 'empty'
            },
            {
                dataField: 'priceType',
                label: {
                    text: 'Harga'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    layout: 'horizontal',
                    items: ['FIXED', 'RANGE', 'BEST PRICE'],
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Tipe harga tidak boleh kosong"
                    }
                ]
            },
            {
                dataField: 'minOrdPrice',
                label: {
                    text: 'Harga Minimum'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harga minimum tidak boleh kosong"
                    },
                    {
                        type: "range",
                        message: "Minimum harga harus lebih dari 0",
                        min: 1
                    }
                ]
            },
            {
                dataField: 'maxOrdPrice',
                label: {
                    text: 'Harga Maximum'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Harga maksimum tidak boleh kosong"
                    },
                    {
                        type: "range",
                        message: "Maksimum harga harus lebih dari 0",
                        min: 1
                    }
                ]
            },
            {
                dataField: 'brokerId',
                label: {
                    text: 'Broker'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.brokerCode + " - " + item.brokerName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: (e) => {
                        var data = e.selectedItem
                         
                        if (data) {
                            this.formMasterRef.current.instance.getEditor('brokerCommission').option('value', data.feeSell)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose broker"
                    }
                ]
            },
            {
                dataField: 'brokerCommission',
                label: {
                    text: 'Komisi (%)'
                },
                editorType: 'dxNumberBox',
                // editorOptions: {
                //     format: "#,##0.00"
                // },
                validationRules: [
                    {
                        type: "required",
                        message: "Komisi broker tidak boleh kosong"
                    },
                ]
            },
            {
                dataField: 'catatan',
                label: {
                    text: 'Catatan'
                },
                editorType: 'dxTextArea',
            }
        ]

        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI SAHAM',
                items: [
                    {
                        dataField: 'emiten',
                        label: {
                            text: 'Emiten'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'sektor',
                        label: {
                            text: 'Sektor'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    // {
                    //     dataField: 'klasifikasiSaham',
                    //     label: {
                    //         text: 'Klasifikasi Saham'
                    //     },
                    //     editorType: 'dxTextBox',
                    //     editorOptions: {
                    //         readOnly: true
                    //     }
                    // },
                    {
                        dataField: 'hargaPenutupan',
                        label: {
                            text: 'Harga Penutupan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'tanggalHargaPenutupan',
                        label: {
                            text: 'Tanggal Harga Penutupan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: "d MMM yyyy",
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            width: '100%',
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'INFORMASI PORTOFOLIO',
                items: [
                    {
                        dataField: 'quantity',
                        label: {
                            text: 'Lembar'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata uang',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode'
                        }
                    },
                    {
                        dataField: 'avgPrice',
                        label: {
                            text: 'Harga Rata-rata'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            text: 'Total'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                ]
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    show(){
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data, type) => {
        if(data){
            this.productId = data.productId
            var instrument = {}, emiten = {}, availableStok = {}, sektor = {}
            if (data.instrumentId) {
                instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`)

                emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `emitens/${instrument.emitenId}`)

                if(emiten.sektorId !== null) {
                    sektor = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `sektors/${emiten.sektorId}`)
                }
            }

            let loadAPI = `efek-balance-avgs/all-instrument-stok?kdpId=1&productId=${1}`
            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');

            let dataFind = response.find(value => value.instrumentId === data.instrumentId);

            this.setState({
                data: {
                    id: data.id,
                    requestNo: data.requestNo,
                    instrumentId: data.instrumentId,
                    ordQty: data.ordQty,
                    priceType: data.priceType,
                    brokerId: data.brokerId,
                    komisiPercent: data.komisiPercent,
                    brokerCommission: data.brokerCommission,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    catatan: data.catatan,
                    productId: data.productId,
                    tanggalOrder: data.tanggalOrder || new Date(),
                },
                dataDetail: {
                    emiten: emiten.emitenName,
                    sektor: sektor.sektorName,
                    hargaPenutupan: instrument.price,
                    tanggalHargaPenutupan: instrument.priceDate,
                    quantity: type !== 'edit' ? null : dataFind.quantity,
                    currencyId: instrument.currencyId,
                    avgPrice: type !== 'edit' ? null : dataFind.avgPrice,
                    currencyAvgPrice: data.currencyAvgPrice,
                    faceValue: type !== 'edit' ? null : dataFind.quantity * dataFind.avgPrice,
                    currencyFaceValue: data.currencyFaceValue,
                },
            });
            var selectInstrumentId = this.formMasterRef.current.instance.getEditor('instrumentId')

            selectInstrumentId.option('dataSource', availableInstrumentStok(this.props.store, this.state.data.productId))
        }
    }
    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.data
        this.props.submitDetailPelepasanData({
            id: data.id,
            instrumentId: data.instrumentId,
            ordQty: data.ordQty,
            priceType: data.priceType.replace(" ", "_"),
            brokerId: data.brokerId,
            komisiPercent: data.komisiPercent,
            brokerCommission: data.brokerCommission,
            minOrdPrice: data.minOrdPrice,
            maxOrdPrice: data.maxOrdPrice,
            catatan: data.catatan,
            tanggalOrder: data.tanggalOrder,
            efekBalanceAvgId: this.state.efekBalanceAvgId,
        })

        this.hide()
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Pelepasan Investasi Saham`}
                width={'70vw'}
                height="70vh"

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Form
                                    ref={this.formMasterRef}
                                    colCount={1}
                                    id={'form'}
                                    formData={this.state.data}
                                    items={this.formItem}
                                    scrollingEnabled={true}
                                    labelLocation={"left"}
                                    readOnly={this.props.type == 'view' ? true : false}
                                />
                            </div>
                            <div className="col-md-6 border-left">
                                <Form
                                    ref={this.formDetailRef}
                                    colCount={1}
                                    id={'formDetail'}
                                    formData={this.state.dataDetail}
                                    items={this.formDetailItem}
                                    scrollingEnabled={true}
                                    labelLocation={"left"}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalInstrumentPelepasanSaham