import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instrumentCategorySubs, banks, ratings } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatAsMoney, formatDefaultFullDate, numberWithCommas, formatNumber, formatNumberAum, summaryValue } from 'plugin/helper'
import { reportFile } from 'plugin/reportRequest'
import notify from 'devextreme/ui/notify';
import store from 'redux/config'
import Excel from 'exceljs/dist/exceljs';
import { showLoading } from 'redux/actions/loading'
import { saveAs } from 'file-saver'

class DataGridDailyBalanceObligasi extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'maturityDate'
            },
            {
                name: 'faceValue',
                column: 'faceValue',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'bungaBerjalan',
                column: 'bungaBerjalan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiPenempatan',
                column: 'nilaiPenempatan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'acruedInterest',
                column: 'acruedInterest',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiSekarang',
                column: 'nilaiSekarang',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'akumPenyusutan',
                column: 'akumPenyusutan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'unrealizedPlPersen',
                showInColumn: 'unrealizedPlPersen',
                summaryType: 'custom',
                valueFormat: '#,##0.00',
                displayFormat: '{0}',
            },
            {
                name: 'unrealizedPlNominal',
                column: 'unrealizedPlNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                dataField: 'no.',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Instrument',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'tanggalPenempatan',
                caption: 'Tanggal Penempatan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'htm',
                caption: 'HTM',
            },
            {
                dataField: 'maturityDate',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'faceValue',
                caption: 'face Value',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'kupon',
                caption: 'Rate',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'priceAverage',
                caption: 'Harga Perolehan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiPenempatan',
                caption: 'Nilai Perolehan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rating1Id',
                caption: 'Rating Awal',
                lookup: {
                    dataSource: ratings(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'ratingCode'
                }
            },
            {
                dataField: 'price',
                caption: 'Harga Pasar',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiSekarang',
                caption: 'Nilai Sekarang',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'rating2Id',
                caption: 'Rating Akhir',
                lookup: {
                    dataSource: ratings(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'ratingCode'
                }
            },
            {
                dataField: 'periodeRating',
                caption: 'Periode Rating',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'akumPenyusutan',
                caption: ' Amortisasi',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                calculateCellValue: (data) => {
                    return data.akumPenyusutan === null ? 0 : data.akumPenyusutan;
                }
            },
            {
                dataField: 'unrealizedPlPersen',
                caption: 'Unrealized P/L (%)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'unrealizedPlNominal',
                caption: 'Unrealized Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'acruedInterest',
                Caption: 'Accrued Interest',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'basisHari',
                caption: 'Basis Hari'
            },
            {
                dataField: 'couponLastDate',
                caption: 'Last Coupon Date',
                alignment: 'center',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'couponNextDate',
                caption: 'Next Coupon Date',
                alignment: 'center',
                dataType: 'date',
                format: 'dd MMM yyyy',
            }
        ]
        this.dataGridRef = React.createRef();
        this.selectedRowsData = [];
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.print()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.exportExcel();
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            }
        )
    }

    customCalculate = (options) => {
        if (options.name === 'unrealizedPlPersen') {
            if (options.summaryProcess === 'start') {
                options.totalValue = {
                    
                };
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue = {
                    totalUnrealizedPlNominal : (options.totalValue.totalUnrealizedPlNominal || 0) + options.value.unrealizedPlNominal,
                    totalNilaiPenempatan : (options.totalValue.totalNilaiPenempatan || 0) + options.value.nilaiPenempatan,
                }
            } else if (options.summaryProcess === 'finalize') {
                options.totalValue = (options.totalValue.totalUnrealizedPlNominal / options.totalValue.totalNilaiPenempatan) * 100
            }
        }
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = this.selectedRowsData;
        let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');
        let responseRatings = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'ratings', 'GET');
        let arrFaceValue = [];
        let arrKupon = [];
        let arrPriceAverage = [];
        let arrAccruedInterest = [];
        let arrNilaiPenempatan = [];
        let arrNilaiSekarang = [];
        let arrAkunPenyusutan = [];
        let arrUnrealizedPlPersen = [];
        let arrUrealizedPlNominal = [];
        store.dispatch(showLoading(true));

        for (let value of response) {
            arrFaceValue.push(value.faceValue);
            arrKupon.push(value.kupon);
            arrPriceAverage.push(value.priceAverage);
            arrAccruedInterest.push(value.acruedInterest);
            arrNilaiPenempatan.push(value.nilaiPenempatan);
            arrNilaiSekarang.push(value.nilaiSekarang);
            arrAkunPenyusutan.push(value.nominalAmortisasi);
            arrUnrealizedPlPersen.push(value.unrealizedPlPersen);
            arrUrealizedPlNominal.push(value.unrealizedPlNominal);
            let dataValue = {
                instrumentName: value.instrumentName ? value.instrumentName : '-',
                instrumentCode: value.instrumentCode ? value.instrumentCode : '-',
                tanggalPenempatan: value.tanggalPenempatan ? formatDefaultFullDate(value.tanggalPenempatan) : '-',
                htm: value.htm === true ? 'HTM' : value.htm === false ? 'AFS' : '-',
                maturityDate: value.maturityDate ? formatDefaultFullDate(value.maturityDate) : 0,
                faceValue: value.faceValue,
                kupon: value.kupon,
                priceAverage: value.priceAverage,
                nilaiPenempatan: value.nilaiPenempatan,
                rating1Id: value.rating1Id ? responseRatings.find(v => v.id === value.rating1Id).ratingCode : '-',
                price: value.price,
                nilaiSekarang: value.nilaiSekarang,
                rating2Id: value.rating2Id ? responseRatings.find(v => v.id === value.rating2Id).ratingCode : '-',
                periodeRating: value.periodeRating ? formatDefaultFullDate(value.periodeRating) : '-',
                akumPenyusutan: value.nominalAmortisasi ? value.nominalAmortisasi : 0,
                unrealizedPlPersen: value.unrealizedPlPersen,
                unrealizedPlNominal: value.unrealizedPlNominal,
                // acruedInterest: value.acruedInterest ? formatAsMoney(value.acruedInterest) : 0,
                acruedInterest: value.acruedInterest,
                basisHari: value.basisHari ? value.basisHari : 0,
                couponLastDate: value.couponLastDate ? value.couponLastDate : 0,
                couponNextDate: value.couponNextDate ? value.couponNextDate : 0
            }
            const objectValue = Object.values(dataValue);
            data.push(objectValue);

            var totalFaceValue = arrFaceValue.reduce((a, b) => a + b, 0);
            var totalAccruedInterest = arrAccruedInterest.reduce((a, b) => a + b, 0);
            var totalKupon = arrKupon.reduce((a, b) => a + b, 0);
            var totalPriceAverage = arrPriceAverage.reduce((a, b) => a + b, 0);
            var totalNilaiPenempatan = arrNilaiPenempatan.reduce((a, b) => a + b, 0);
            var totalNilaiSekarang = arrNilaiSekarang.reduce((a, b) => a + b, 0);
            var totalAkunPenyusutan = arrAkunPenyusutan.reduce((a, b) => a + b, 0);
            var totalUnrealizedPlPersen = arrUnrealizedPlPersen.reduce((a, b) => a + b, 0);
            var totalUrealizedPlNominal = arrUrealizedPlNominal.reduce((a, b) => a + b, 0);
        }

        let totalUnrealizedPer = (totalUrealizedPlNominal / totalNilaiPenempatan) * 100

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('DAILY BALANCE PORTFOLIO OBLIGASI');
        worksheet.addTable({
            name: 'MyTable',
            ref: `A${firstTableRowNum}`,
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
            },
            columns: [
                { name: 'Nama Instrument' },
                { name: 'Kode Instrument' },
                { name: 'Tanggal Penempatan' },
                { name: 'HTM' },
                { name: 'Tanggal Jatuh Tempo' },
                { name: 'Face Value' },
                { name: 'Rate' },
                { name: 'Harga Perolehan' },
                { name: 'Nilai Perolehan' },
                { name: 'Rating Awal' },
                { name: 'Harga Pasar' },
                { name: 'Nilai Sekarang' },
                { name: 'Rating Akhir' },
                { name: 'Periode Rating' },
                { name: 'Amortisasi' },
                { name: 'Unrealized P/L (%)' },
                { name: 'Unrealized Nominal' },
                { name: 'Accrued Interest' },
                { name: 'Basis Hari' },
                { name: 'Last Coupon Date' },
                { name: 'Next Coupon Date' }
            ],
            rows: data
        })

        let lastRowNum = worksheet.lastRow.number;
        const lastTableRowNum = lastRowNum;

        for (let i = firstTableRowNum; i <= lastTableRowNum; i++) {
            const row = worksheet.getRow(i);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
            }))
        }

        for (let i = 9; i <= 10000; i++) {
            worksheet.getCell('A' + i).alignment = { vertical: 'middle', horizontal: 'left' };
            worksheet.getCell('F' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('G' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('H' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('I' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('K' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('L' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('O' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('P' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('Q' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('R' + i).alignment = { vertical: 'middle', horizontal: 'right' };
        }

        worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
        worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E2').font = { size: 18, bold: true }

        worksheet.getCell('E3').value = 'DAILY BALANCE PORTFOLIO OBLIGASI';
        worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E3').font = { size: 18, bold: true }

        worksheet.getCell('E4').value = `KPD ${this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName.toUpperCase() : '-'}`;
        worksheet.getCell('E4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E4').font = { size: 18, bold: true }

        worksheet.getCell('E5').value = `TANGGAL LAPORAN ${this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-'}`;
        worksheet.getCell('E5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('E5').font = { size: 18, bold: true }

        worksheet.mergeCells(`A${lastTableRowNum + 1}:E${lastTableRowNum + 1}`);
        worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total';
        worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'center' };
        worksheet.getCell(`A${lastTableRowNum + 1}`).font = { size: 12, bold: true }
        worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`F${lastTableRowNum + 1}`).value = totalFaceValue;
        worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`G${lastTableRowNum + 1}`).value = "";
        worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`H${lastTableRowNum + 1}`).value = "";
        worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`I${lastTableRowNum + 1}`).value = totalNilaiPenempatan;
        worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`J${lastTableRowNum + 1}`).value = "";
        worksheet.getCell(`J${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`K${lastTableRowNum + 1}`).value = "";
        worksheet.getCell(`K${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`L${lastTableRowNum + 1}`).value = totalNilaiSekarang;
        worksheet.getCell(`L${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`M${lastTableRowNum + 1}`).value = "";
        worksheet.getCell(`M${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`N${lastTableRowNum + 1}`).value = "";
        worksheet.getCell(`N${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`O${lastTableRowNum + 1}`).value = totalAkunPenyusutan;
        worksheet.getCell(`O${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`P${lastTableRowNum + 1}`).value = totalUnrealizedPer;
        worksheet.getCell(`P${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`Q${lastTableRowNum + 1}`).value = totalUrealizedPlNominal;
        worksheet.getCell(`Q${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`R${lastTableRowNum + 1}`).value = totalAccruedInterest;
        worksheet.getCell(`R${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.mergeCells(`S${lastTableRowNum + 1}:U${lastTableRowNum + 1}`);
        worksheet.getCell(`S${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        var WidthColums = 20;
        var WidthColums2 = 35;

        const Data1 = worksheet.getColumn(1);
        Data1.width = 70;

        const Data2 = worksheet.getColumn(2);
        Data2.width = WidthColums;

        const Data3 = worksheet.getColumn(3);
        Data3.width = WidthColums2;

        const Data4 = worksheet.getColumn(4);
        Data4.width = WidthColums;

        const Data5 = worksheet.getColumn(5);
        Data5.width = WidthColums;

        const Data6 = worksheet.getColumn(6);
        Data6.numFmt = '#,##0.00';
        Data6.width = WidthColums;

        const Data7 = worksheet.getColumn(7);
        Data7.numFmt = '#,##0.00';
        Data7.width = WidthColums;

        const Data8 = worksheet.getColumn(8);
        Data8.numFmt = '#,##0.00';
        Data8.width = WidthColums;

        const Data9 = worksheet.getColumn(9);
        Data9.numFmt = '#,##0.00';
        Data9.width = WidthColums;

        const Data10 = worksheet.getColumn(10);
        Data10.width = WidthColums;

        const Data11 = worksheet.getColumn(11);
        Data11.numFmt = '#,##0.00';
        Data11.width = WidthColums;

        const Data12 = worksheet.getColumn(12);
        Data12.numFmt = '#,##0.00';
        Data12.width = WidthColums;

        const Data13 = worksheet.getColumn(13);
        Data13.width = WidthColums;

        const Data14 = worksheet.getColumn(14);
        Data14.width = WidthColums;

        const Data15 = worksheet.getColumn(15);
        Data15.numFmt = '#,##0.00';
        Data15.width = WidthColums;

        const Data16 = worksheet.getColumn(16);
        Data16.numFmt = '#,##0.00';
        Data16.width = WidthColums;

        const Data17 = worksheet.getColumn(17);
        Data17.numFmt = '#,##0.00';
        Data17.width = WidthColums;

        const Data18 = worksheet.getColumn(18);
        Data18.numFmt = '#,##0.00';
        Data18.width = WidthColums;

        const Data19 = worksheet.getColumn(19);
        Data19.width = WidthColums;

        const Data20 = worksheet.getColumn(20);
        Data20.width = WidthColums;

        const Data21 = worksheet.getColumn(21);
        Data21.width = WidthColums;

        await workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                'Daily_balance_portfolio_obligasi.xlsx'
            );
        });

        store.dispatch(showLoading(false));
    }

    print = async () => {
        try {
            // let response = this.dataGridRef.current.getDataSource();
            let response = this.selectedRowsData;
            let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');
            let responseRatings = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'ratings', 'GET');

            let transaksiDetil = [];
            if (response.length !== 0) {
                let arrFaceValue = [];
                let arrKupon = [];
                let arrPriceAverage = [];
                let arrNilaiPenempatan = [];
                let arrNilaiSekarang = [];
                let arrAkunPenyusutan = [];
                let arrUnrealizedPlPersen = [];
                let arrUrealizedPlNominal = [];

                for (let data of response) {
                    arrFaceValue.push(data.faceValue);
                    arrKupon.push(data.kupon);
                    arrPriceAverage.push(data.priceAverage);
                    arrNilaiPenempatan.push(data.nilaiPenempatan);
                    arrNilaiSekarang.push(data.nilaiSekarang);
                    arrAkunPenyusutan.push(data.nominalAmortisasi);
                    arrUnrealizedPlPersen.push(data.unrealizedPlPersen);
                    arrUrealizedPlNominal.push(data.unrealizedPlNominal);

                    let htm = ""
                    if (data.htm) {
                        htm = 'HTM'
                    } else if (data.categoryId == 1 || data.categoryId == 2 || data.categoryId == 3 || data.categoryId == 8 || data.categoryId == 9) {
                        htm = '-'
                    } else {
                        htm = 'AFS'
                    }

                    let dataResult = {
                        instrumentName: data.instrumentName ? data.instrumentName : '-',
                        instrumentCode: data.instrumentCode ? data.instrumentCode : '-',
                        tanggalPenempatan: data.tanggalPenempatan ? formatDefaultFullDate(data.tanggalPenempatan) : '-',
                        htm: htm,
                        // htm: data.htm === true ? 'HTM' : data.htm === false ? 'AFS' : '-',
                        maturityDate: data.maturityDate ? formatDefaultFullDate(data.maturityDate) : 0,
                        faceValue: data.faceValue ? data.faceValue : 0,
                        kupon: data.kupon ? data.kupon : 0,
                        priceAverage: data.priceAverage ? data.priceAverage : 0,
                        nilaiPenempatan: data.nilaiPenempatan ? data.nilaiPenempatan : 0,
                        rating1Id: data.rating1Id ? responseRatings.find(v => v.id === data.rating1Id).ratingCode : '-',
                        price: data.price ? data.price : 0,
                        nilaiSekarang: data.nilaiSekarang ? data.nilaiSekarang : 0,
                        rating2Id: data.rating2Id ? responseRatings.find(v => v.id === data.rating2Id).ratingCode : '-',
                        periodeRating: data.periodeRating ? formatDefaultFullDate(data.periodeRating) : '-',
                        akunPenyusutan: data.nominalAmortisasi ? data.nominalAmortisasi : 0,
                        unrealizedPlPersen: data.unrealizedPlPersen ? data.unrealizedPlPersen : 0,
                        unrealizedPlNominal: data.unrealizedPlNominal ? data.unrealizedPlNominal : 0
                    }

                    transaksiDetil.push(dataResult);

                    var totalFaceValue = arrFaceValue.reduce((a, b) => a + b, 0);
                    var totalKupon = arrKupon.reduce((a, b) => a + b, 0);
                    var totalPriceAverage = arrPriceAverage.reduce((a, b) => a + b, 0);
                    var totalNilaiPenempatan = arrNilaiPenempatan.reduce((a, b) => a + b, 0);
                    var totalNilaiSekarang = arrNilaiSekarang.reduce((a, b) => a + b, 0);
                    var totalAkunPenyusutan = arrAkunPenyusutan.reduce((a, b) => a + b, 0);
                    var totalUnrealizedPlPersen = arrUnrealizedPlPersen.reduce((a, b) => a + b, 0);
                    var totalUrealizedPlNominal = arrUrealizedPlNominal.reduce((a, b) => a + b, 0);
                }

                let totalUnrealizedPer = (totalUrealizedPlNominal / totalNilaiPenempatan) * 100

                reportFile({
                    template: { 'shortid': 'YyWL_Se' },
                    data: {
                        "productCode": this.props.stateData.productCode,
                        "kpdName": this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName : '-',
                        "reportDate": this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-',
                        "categoryName": this.props.tabName ? this.props.tabName : '-',
                        "dailyBalanceDetail": transaksiDetil,
                        "totalFaceValue": totalFaceValue,
                        "totalKupon": totalKupon,
                        "totalPriceAverage": totalPriceAverage,
                        "totalNilaiPenempatan": totalNilaiPenempatan,
                        "totalNilaiSekarang": totalNilaiSekarang,
                        "totalAkunPenyusutan": totalAkunPenyusutan,
                        "totalPlPerc": totalUnrealizedPer,
                        "totalPlNom": totalUrealizedPlNominal
                    },
                    options: {
                        reportName: "Daily Balance Portofolio Obligasi & Sukuk"
                    }
                }, true, 'Daily Balance Portofolio Obligasi & Sukuk.pdf')
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`view-portofolio-daily-balances?balanceDate.equals=${this.props.stateData.balanceDate}&categoryId.in=5&kpdId.in=${this.props.stateData.kpdId}&productCode.in=${this.props.stateData.productCode}&size=9999`}
                    insertAPI='efek-balance-avgs'
                    updateAPI='efek-balance-avgs'
                    deleteAPI='efek-balance-avgs'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={false}
                    exportFileName={"Portofolio Obligasi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    showPageSizeSelector={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Obligasi'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    calculateCustomSummary={this.customCalculate}

                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    height={'calc(100vh - 367px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridDailyBalanceObligasi