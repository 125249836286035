import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, kpds, productDefault, managerInvestasis, instrumentReksadana, fundManager } from 'dataSource/lookup';
import { formatUploadFileData, imageSource} from 'plugin/helper';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';


class ModalAlokasiUnitPelepasanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        if (this.validate()) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]


        this.items = [
            {
                itemType: 'group',
                caption: 'Informasi Pembelian',
                name: 'informasiPembelian',
                items: [
                    {
                        dataField: 'transaksiId',
                        label: {
                            alignment: 'left',
                            text: 'ID Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'settleNo',
                        label: {
                            alignment: 'left',
                            text: 'No. Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pengelolaan',
                        label: {
                            alignment: 'left',
                            text: 'Pengelolaan'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: [
                                'Internal',
                                'KPD',
                            ],
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            alignment: 'left',
                            text: 'KPD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk Dapen'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'managerInvestasiId',
                        label: {
                            alignment: 'left',
                            text: 'Penerbit Reksadana'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'miName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            alignment: 'left',
                            text: 'Produk Reksadana'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentReksadana(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'instrumentName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'transactionDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'paymentDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'settleDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Settle'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Currency'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'currencyName',
                            readOnly: true,
                            onSelectionChanged: (e) => {
                                this.formRef.current.instance.getEditor('rateCurrency').option('value', e.selectedItem.rate)
                            }
                        },
                    },
                    {
                        dataField: 'rateCurrency',
                        label: {
                            alignment: 'left',
                            text: 'Rate Currency'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'fundManagerId',
                        label: {
                            alignment: 'left',
                            text: 'Fund Manager'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: fundManager(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fundManagerName',
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Alokasi Unit Penjualan',
                name: 'alokasiUnit',
                items: [
                    {
                        dataField: 'unit',
                        label: {
                            alignment: 'left',
                            text: 'Unit'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Unit Reksadana tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'nab',
                        label: {
                            alignment: 'left',
                            text: 'NAB'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            onValueChanged: (e) => {
                                var val = e.value
                                var unit = this.formRef.current.instance.getEditor('unit').option('value')
                                if (val) {
                                    this.formRef.current.instance.getEditor('amountTrs').option('value', unit * val)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'NAB Reksadana tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'amountTrs',
                        label: {
                            alignment: 'left',
                            text: 'Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var val = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                var amountTrs = e.value
                                var biayaRedemption = this.formRef.current.instance.getEditor('biayaRedemption').option('value')
                                this.formRef.current.instance.updateData('amountNet', amountTrs - val - biayaRedemption)
                            },
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Nominal Pelepasan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'biayaRedemption',
                        label: {
                            alignment: 'left',
                            text: 'Fee Penjualan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var val = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                var amountTrs = this.formRef.current.instance.getEditor('amountTrs').option('value')
                                var biayaRedemption = e.value
                                this.formRef.current.instance.updateData('amountNet', amountTrs - val - biayaRedemption)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Fee Penjualan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'biayaBank',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Bank/RTGS'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var val = e.value
                                var amountTrs = this.formRef.current.instance.getEditor('amountTrs').option('value')
                                var biayaRedemption = this.formRef.current.instance.getEditor('biayaRedemption').option('value')
                                this.formRef.current.instance.updateData('amountNet', amountTrs - val - biayaRedemption)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Biaya bank tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'amountNet',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Bersih'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Nominal Bersih tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: "file",
                        label: {
                            text: "File Settle",
                            alignment: 'left'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'File Settle tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },

        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    previewFile = async () => {
        var src = imageSource(this.state.dataMaster.fileSettleContentType, this.state.dataMaster.fileSettle)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Settle`]: formattedFileData.base64data,
                        [`${fieldName}SettleContentType`]: formattedFileData.mime,
                        [`fileName`]: files.name,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }
    submitData = async () => {
        var data = this.state.dataMaster
        var dataSubmit = {
            transaksiId: data.transaksiId,
            nab: data.nab,
            unit: data.unit,
            amountNet: data.amountNet,
            amountTrs: data.amountTrs,
            biayaBank: !data.biayaBank ? 0 : data.biayaBank,
            biayaRedemption: !data.biayaRedemption ? 0 : data.biayaRedemption,
            fileName: data.fileName,
            fileSettle: data.fileSettle,
            fileSettleContentType: data.fileSettleContentType,
        }

        if (dataSubmit) {
            try {
                var insertAPI = 'order-rds/alokasi-penjualan-reksadana'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: dataSubmit
                })

                notify({ message: 'Alokasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
        this.hide()
    }

    retrieveData = async (type, data) => {
        var transaction = []
        if (data.id) {
            transaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transactions/${data.id}`)
        }
        this.setState({
            dataMaster: {
                transaksiId: data.id,
                settleNo: data.settleNo,
                pengelolaan: transaction.pengelola,
                kpdId: transaction.kpdId,
                productId: transaction.productId,
                managerInvestasiId: data.managerInvestasiId,
                instrumentId: data.instrumentId,
                transactionDate: data.transactionDate,
                paymentDate: data.paymentDate,
                settleDate: data.settleDate,
                currencyId: data.currencyId,
                fundManagerId: transaction.fundManagerId,
                unit: transaction.quantity
            },
            fileName: transaction.fileSettleName,
            fileSettle: transaction.fileSettle,
            fileSettleContentType: transaction.fileSettleContentType,
            actionType: type
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Alokasi Unit'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    ref={this.formRef}
                                    items={this.items}
                                    labelLocation={"left"}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSettle ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                                <ModalPreviewPdf
                                    ref={this.modalPreviewPdfRef}
                                    store={this.props.store}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalAlokasiUnitPelepasanReksadana