import React, { Component } from 'react'
import { Form, Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import { participants, product, tipePembayaranMp } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDate, formatDefaultDate, PrintElem, generateExcelFile, formatNumber } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';

const bulan = [
    {
        id : 1,
        namaBulan: 'Januari'
    },
    {
        id : 2,
        namaBulan: 'Februari'
    },
    {
        id : 3,
        namaBulan: 'Maret'
    },
    {
        id : 4,
        namaBulan: 'April'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Juni'
    },
    {
        id : 7,
        namaBulan: 'Juli'
    },
    {
        id : 8,
        namaBulan: 'Agustus'
    },
    {
        id : 9,
        namaBulan: 'September'
    },
    {
        id : 10,
        namaBulan: 'Oktober'
    },
    {
        id : 11,
        namaBulan: 'November'
    },
    {
        id : 12,
        namaBulan: 'Desember'
    },
]

class ReportPesertaDialihkan extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'productId',
                        label:{
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField:'greaterTanggalPengalihan',
                                label:{
                                    text: 'Tanggal Pengalihan',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField:'lessTanggalPengalihan',
                                label:{
                                    text: 'S/D',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    {
                        dataField:'newMpPaymentTypeId',
                        label:{
                            text: 'Tipe Pembayaran',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipePembayaranMp(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'typeName',
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-pengalihan-mps?status.in=3&size=9999&`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        var productName = getProduct.find(val => val.id === filter.productId) ? getProduct.find(val => val.id === filter.productId).productCode : 'Semua'

        if(filter.newMpPaymentTypeId){
            loadAPI = loadAPI+`newMpPaymentTypeId.in=${filter.newMpPaymentTypeId}&`
            try {
                var mpPaymentTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `mp-payment-types/${filter.newMpPaymentTypeId}`, 'GET')).typeName
            } catch (error) {
                var mpPaymentTypeName = 'Semua'
            }
        }
        
        // Tanggal Pensiun
        if(filter.greaterTanggalPengalihan){
            loadAPI = loadAPI+`tanggalPengalihan.greaterThanOrEqual=${formatDate(filter.greaterTanggalPengalihan)}&`
        }
        
        if(filter.lessTanggalPengalihan){
            loadAPI = loadAPI+`tanggalPengalihan.lessThanOrEqual=${formatDate(filter.lessTanggalPengalihan)}&`
        }
        var getPesertaDialihkan = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')
        
        var tipePenerima = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))
        var tipePembayaran = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `mp-payment-types`, 'GET'))
        
        let summaryLastMp = []
        let summaryNewMp = []
        for(var value of getPesertaDialihkan){
            value.lastMpPenerimaTypeId = tipePenerima.find(val => val.id === value.lastMpPenerimaTypeId) ? tipePenerima.find(val => val.id === value.lastMpPenerimaTypeId).penerimaName : '-'
            value.newMpPenerimaTypeId = tipePenerima.find(val => val.id === value.newMpPenerimaTypeId) ? tipePenerima.find(val => val.id === value.newMpPenerimaTypeId).penerimaName : '-'
            value.newMpPaymentTypeId = tipePembayaran.find(val => val.id === value.newMpPaymentTypeId) ? tipePembayaran.find(val => val.id === value.newMpPaymentTypeId).typeName : '-'

            value.product = getProduct.find(val => val.id === filter.productId) ? getProduct.find(val => val.id === filter.productId).productCode : '-'

            value.tanggalPengalihan = value.tanggalPengalihan ? formatDefaultDate(value.tanggalPengalihan) : '-'

            value.lastMpBulanan = value.lastMpBulanan ? value.lastMpBulanan : 0
            value.newMpBulanan = value.newMpBulanan ? value.newMpBulanan : 0

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
            value.lastPenerimaName = value.lastPenerimaName ? (value.lastPenerimaName).toUpperCase() : value.lastPenerimaName
            value.newPenerimaName = value.newPenerimaName ? (value.newPenerimaName).toUpperCase() : value.newPenerimaName

            let sumLastMp = value.lastMpBulanan ? value.lastMpBulanan : 0
            let sumNewMp = value.newMpBulanan ? value.newMpBulanan : 0

            summaryLastMp.push(sumLastMp)
            summaryNewMp.push(sumNewMp)
        }

        if(summaryLastMp.length !== 0 && summaryNewMp.length !== 0) {
            var summaryLastMpBulanan = summaryLastMp.reduce((a, b) => a + b, 0);
            var summaryNewMpBulanan = summaryNewMp.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/peserta/peserta-dialihkan',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpPaymentType: mpPaymentTypeName,
                participant: participantName,
                tanggalBerhenti : filter.lessTanggalPengalihan && filter.greaterTanggalPengalihan ? (filter.greaterTanggalPengalihan ? formatDefaultDate(filter.greaterTanggalPengalihan) : "~") + ' S/D ' + (filter.lessTanggalPengalihan ? formatDefaultDate(filter.lessTanggalPengalihan) : "~") : 'Semua',
                dataPeserta : getPesertaDialihkan,
                summaryLastMp: summaryLastMpBulanan,
                summaryNewMp: summaryNewMpBulanan,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))

        this.setState({
            src : responseFile
        })
    }
    
    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-pengalihan-mps?status.in=3&size=9999&`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        var productName = getProduct.find(val => val.id === filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'

        if(filter.newMpPaymentTypeId){
            loadAPI = loadAPI+`newMpPaymentTypeId.in=${filter.newMpPaymentTypeId}&`
            try {
                var mpPaymentTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `mp-payment-types/${filter.newMpPaymentTypeId}`, 'GET')).typeName
            } catch (error) {
                var mpPaymentTypeName = 'Semua'
            }
        }

        // Tanggal Pensiun
        if(filter.greaterTanggalPengalihan){
            loadAPI = loadAPI+`tanggalPengalihan.greaterThanOrEqual=${formatDate(filter.greaterTanggalPengalihan)}&`
        }
        
        if(filter.lessTanggalPengalihan){
            loadAPI = loadAPI+`tanggalPengalihan.lessThanOrEqual=${formatDate(filter.lessTanggalPengalihan)}&`
        }
        var getPesertaDialihkan = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')
        
        var tipePenerima = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))
        var tipePembayaran = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `mp-payment-types`, 'GET'))
        
        let summaryLastMp = []
        let summaryNewMp = []
        for(var value of getPesertaDialihkan){
            value.lastMpPenerimaTypeId = tipePenerima.find(val => val.id === value.lastMpPenerimaTypeId) ? tipePenerima.find(val => val.id === value.lastMpPenerimaTypeId).penerimaName : '-'
            value.newMpPenerimaTypeId = tipePenerima.find(val => val.id === value.newMpPenerimaTypeId) ? tipePenerima.find(val => val.id === value.newMpPenerimaTypeId).penerimaName : '-'
            value.newMpPaymentTypeId = tipePembayaran.find(val => val.id === value.newMpPaymentTypeId) ? tipePembayaran.find(val => val.id === value.newMpPaymentTypeId).typeName : '-'

            value.product = getProduct.find(val => val.id === filter.productId) ? getProduct.find(val => val.id === filter.productId).productCode : '-'

            value.tanggalPengalihan = value.tanggalPengalihan ? formatDefaultDate(value.tanggalPengalihan) : '-'

            value.lastMpBulanan = value.lastMpBulanan ? value.lastMpBulanan : 0
            value.newMpBulanan = value.newMpBulanan ? value.newMpBulanan : 0

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
            value.lastPenerimaName = value.lastPenerimaName ? (value.lastPenerimaName).toUpperCase() : value.lastPenerimaName
            value.newPenerimaName = value.newPenerimaName ? (value.newPenerimaName).toUpperCase() : value.newPenerimaName

            let sumLastMp = value.lastMpBulanan ? value.lastMpBulanan : 0
            let sumNewMp = value.newMpBulanan ? value.newMpBulanan : 0

            summaryLastMp.push(sumLastMp)
            summaryNewMp.push(sumNewMp)
        }

        if(summaryLastMp.length !== 0 && summaryNewMp.length !== 0) {
            var summaryLastMpBulanan = summaryLastMp.reduce((a, b) => a + b, 0);
            var summaryNewMpBulanan = summaryNewMp.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/peserta/peserta-dialihkan',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpPaymentType: mpPaymentTypeName,
                participant: participantName,
                tanggalBerhenti : filter.lessTanggalPengalihan && filter.greaterTanggalPengalihan ? (filter.greaterTanggalPengalihan ? formatDefaultDate(filter.greaterTanggalPengalihan) : "~") + ' S/D ' + (filter.lessTanggalPengalihan ? formatDefaultDate(filter.lessTanggalPengalihan) : "~") : 'Semua',
                dataPeserta : getPesertaDialihkan,
                summaryLastMp: summaryLastMpBulanan,
                summaryNewMp: summaryNewMpBulanan,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'DAFTAR PENGALIHAN MANFAAT PENSIUN'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // PrintElem(response.data)
        // store.dispatch(showLoading(false))

        var responseFile = await reportFile(data, true, 'DAFTAR PENGALIHAN MANFAAT PENSIUN')
        store.dispatch(showLoading(false))

        return responseFile
    }

    exportExcel = async() => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-pengalihan-mps?status.in=3&size=9999&`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        var productName = getProduct.find(val => val.id === filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'

        if(filter.newMpPaymentTypeId){
            loadAPI = loadAPI+`newMpPaymentTypeId.in=${filter.newMpPaymentTypeId}&`
            try {
                var mpPaymentTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `mp-payment-types/${filter.newMpPaymentTypeId}`, 'GET')).typeName
            } catch (error) {
                var mpPaymentTypeName = 'Semua'
            }
        }

        // Tanggal Pensiun
        if(filter.greaterTanggalPengalihan){
            loadAPI = loadAPI+`tanggalPengalihan.greaterThanOrEqual=${formatDate(filter.greaterTanggalPengalihan)}&`
        }
        
        if(filter.lessTanggalPengalihan){
            loadAPI = loadAPI+`tanggalPengalihan.lessThanOrEqual=${formatDate(filter.lessTanggalPengalihan)}&`
        }
        var getPesertaDialihkan = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')
        
        var tipePenerima = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))
        var tipePembayaran = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `mp-payment-types`, 'GET'))

        var index = 1
        let summaryLastMp = []
        let summaryNewMp = []
        for(var value of getPesertaDialihkan){

            let sumLastMp = value.lastMpBulanan ? value.lastMpBulanan : 0
            let sumNewMp = value.newMpBulanan ? value.newMpBulanan : 0

            summaryLastMp.push(sumLastMp)
            summaryNewMp.push(sumNewMp)

            var dataValue = {
                id: index++,
                nik: value.nik,
                fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                tanggalPengalihan: value.tanggalPengalihan ? formatDefaultDate(value.tanggalPengalihan) : '-',
                // product: value.product = getProduct.find(val => val.id === filter.productId) ? getProduct.find(val => val.id === filter.productId).productCode : 'Semua',
                lastMpPenerimaTypeId: tipePenerima.find(val => val.id === value.lastMpPenerimaTypeId).penerimaName ? tipePenerima.find(val => val.id === value.lastMpPenerimaTypeId).penerimaName : 'Semua',
                lastPenerimaName: value.lastPenerimaName ? (value.lastPenerimaName).toUpperCase() : value.lastPenerimaName,
                newMpPenerimaTypeId: tipePenerima.find(val => val.id === value.newMpPenerimaTypeId) ? tipePenerima.find(val => val.id === value.newMpPenerimaTypeId).penerimaName  : '-',
                newPenerimaName: value.newPenerimaName ? (value.newPenerimaName).toUpperCase() : value.newPenerimaName,
                // newMpPaymentTypeId: tipePembayaran.find(val => val.id === value.newMpPaymentTypeId) ? tipePembayaran.find(val => val.id === value.newMpPaymentTypeId).typeName : '-',
                keterangan: value.keterangan,
                lastMpBulanan: value.lastMpBulanan ? value.lastMpBulanan : 0,
                newMpBulanan: value.newMpBulanan ? value.newMpBulanan : 0,
            }

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        if(summaryLastMp.length !== 0 && summaryNewMp.length !== 0) {
            var summaryLastMpBulanan = summaryLastMp.reduce((a, b) => a + b, 0);
            var summaryNewMpBulanan = summaryNewMp.reduce((a, b) => a + b, 0);
        }

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR PENGALIHAN MANFAAT PENSIUN');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A1',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                {name: 'NIK'},
                {name: 'Nama Peserta'},
                {name: 'Tanggal Pengalihan'},
                // {name: 'Produk'},
                {name: 'Tipe Penerima Sebelum'},
                {name: 'Nama Penerima Sebelum'},
                {name: 'Tipe Penerima Baru'},
                {name: 'Nama Penerima Baru'},
                // {name: 'Tipe Pembayaran Baru'},
                {name: 'Keterangan Pengalihan'},
                {name: 'MP Bulanan Sebelum\r\n(Rp)'},
                {name: 'MP Bulanan Sesudah\r\n(Rp)'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }
                }))
            }

            worksheet.headerFooter.differentFirst = true;
                worksheet.headerFooter = {
                    firstHeader: `&C&BDAFTAR PENGALIHAN MANFAAT PENSIUN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    oddHeader: `&C&BDAFTAR PENGALIHAN MANFAAT PENSIUN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    evenHeader: `&C&BDAFTAR PENGALIHAN MANFAAT PENSIUN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
                }

                worksheet.pageSetup = {
                    fitToPage: true,
                    margins: {
                        bottom: 0.75,
                        footer: 0.3,
                        header: 0.3,
                        left: 0.7,
                        right: 0.7,
                        top: 0.75
                    }
                }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // // worksheet.getCell('A3').value = 'Kode'
            // // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A3').value = 'Laporan'
            // worksheet.getCell('B3').value = ': DAFTAR PESERTA DIALIHKAN'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A6').value = 'Pemberi Kerja'
            // worksheet.getCell('B6').value = `: ${participantName}`
            
            // worksheet.getCell('A7').value = 'Produk'
            // worksheet.getCell('B7').value = `: ${productName}`

            // worksheet.getCell('A8').value = 'Tanggal Pengalihan'
            // worksheet.getCell('B8').value = `: ${filter.lessTanggalPengalihan && filter.greaterTanggalPengalihan ? (filter.greaterTanggalPengalihan ? formatDefaultDate(filter.greaterTanggalPengalihan) : "~") + ' S/D ' + (filter.lessTanggalPengalihan ? formatDefaultDate(filter.lessTanggalPengalihan) : "~") : 'Semua'}`

            // worksheet.getCell('A9').value = 'Tipe Pembayaran Baru'
            // worksheet.getCell('B9').value = `: ${mpPaymentTypeName}`

            worksheet.mergeCells(`A${lastTableRowNum + 1}:I${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

            worksheet.getCell(`J${lastTableRowNum + 1}`).value = (summaryLastMpBulanan)
            worksheet.getCell(`J${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`J${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`K${lastTableRowNum + 1}`).value = (summaryNewMpBulanan)
            worksheet.getCell(`K${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`K${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('J1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('K1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            for(var i = 2; i <= data.length + 1; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 40;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = 40;

            const Data6 = worksheet.getColumn(6);
            Data6.width = 40;

            const Data7 = worksheet.getColumn(7);
            Data7.width = 40;

            const Data8 = worksheet.getColumn(8);
            Data8.width = 40;

            const Data9 = worksheet.getColumn(9);
            Data9.width = 40;
            
            const Data10 = worksheet.getColumn(10);
            Data10.width = 40;
            Data10.numFmt = '#,##0.00'
            
            const Data11 = worksheet.getColumn(11);
            Data11.width = 40;
            Data11.numFmt = '#,##0.00'
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR PENGALIHAN MANFAAT PENSIUN.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"nik", 
        //         to:"NIK"
        //     },
        //     {
        //         from:"fullName", 
        //         to:"Nama"
        //     },
        //     {
        //         from:"birthDate", 
        //         to:"Tanggal Lahir"
        //     },
        //     {
        //         from:"tglPemberhentian", 
        //         to:"Tanggal Berhenti"
        //     },
        //     {
        //         from:"tglDialihkan", 
        //         to:"Tanggal Dialihkan"
        //     },
        //     {
        //         from:"mkTahun", 
        //         to:"MK Tahun"
        //     },
        //     {
        //         from:"mkBulan", 
        //         to:"MK Bulan"
        //     },
        //     {
        //         from:"usia", 
        //         to:"Usia Sekarang"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"cardNo", 
        //         to:"Card No"
        //     },
        //     {
        //         from:"mpBulanan", 
        //         to:"Nominal MP Bulanan"
        //     },
        //     {
        //         from:"mpSekaligus", 
        //         to:"Nominal MP Sekaligus"
        //     },
        //     {
        //         from:"dplkCode", 
        //         to:"DPLK Tujuan"
        //     },
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'DAFTAR PENSIUN DITUNDA'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Pemberi Kerja',
        //         B: null,
        //         C: ':',
        //         D: participantName
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        //     {
        //         A: 'Tanggal Berhenti',
        //         B: null,
        //         C: ':',
        //         D: filter.lessTanggalPengalihan && filter.greaterTanggalPengalihan ? (filter.greaterTanggalPengalihan ? formatDefaultDate(filter.greaterTanggalPengalihan) : "~") + ' S/D ' + (filter.lessTanggalPengalihan ? formatDefaultDate(filter.lessTanggalPengalihan) : "~") : 'Semua',
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getPesertaDialihkan){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_PESERTA_DIALIHKAN')
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Daftar Pengalihan Manfaat Pensiun </h4>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.formFilter}
                            items={this.filterItem}
                            labelLocation = 'left'
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPesertaDialihkan