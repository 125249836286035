import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";

import { tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, productDefault } from 'dataSource/lookup';


class FormObligasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            dataPembelian: {},
            dataPenjualan:{},
            dataPertimbangan:{},
            dataPenjualanObligasi:{},
            InfoSaldoObligasi: {},
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: console.log('simpan')
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                'id': 0,
                text: 'Pembelian'
            },
            {
                'id': 1,
                text: 'Pertimbangan Investasi'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                }
            },
            {
                dataField: 'kode',
                label: {
                    alignment: 'left',
                    text: 'Kode'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal'
                },
                editorType: 'dxDateBox'
            },
            {
                dataField: 'tipeTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Tipe Transaksi'
                }
            },
        ]
        this.DataPembelian = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Pembelian',
                        items: [
                            {
                                dataField: 'produk',
                                label: {
                                    alignment:'left',
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'productCode', // provides display values
                                },
                            },
                            {
                                dataField: 'tglPembelian',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Pembelian'
                                },
                                editorType: 'dxDateBox'
                            },
                            {
                                dataField: 'bank',
                                label: {
                                    alignment:'left',
                                    text: 'Bank'
                                },
                            },
                            {
                                dataField: 'broker',
                                label: {
                                    alignment:'left',
                                    text: 'Broker'
                                },
                            },
                            {
                                dataField: 'bond',
                                label: {
                                    alignment:'left',
                                    text: 'SBN / Bond'
                                }
                            },
                            {
                                dataField: 'currency',
                                label: {
                                    alignment:'left',
                                    text: 'Currency'
                                }
                            },
                            {
                                dataField: 'currencyRate',
                                label: {
                                    alignment:'left',
                                    text: 'Currency Rate'
                                }
                            },
                            {
                                dataField: 'faceValue',
                                label: {
                                    alignment:'left',
                                    text: 'Face Value'
                                }
                            },
                            {
                                dataField: 'pricePercent',
                                label: {
                                    alignment:'left',
                                    text: 'Price (%)'
                                }
                            },
                            {
                                dataField: 'managerInvest',
                                label: {
                                    alignment:'left',
                                    text: 'Manager Investasi'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Catatan',
                        items: [
                            {
                                dataField: 'note',
                                label: {
                                    visible: false,
                                },
                                editorType:'dxTextArea',
                                editorOptions:{
                                    height: '120'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
        this.DataPenjualanObligasi = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Obligasi',
                        items: [
                            {
                                dataField:'produk',
                                label: {
                                    alignment:'left',
                                    text: 'Produk'
                                }
                            },
                            {
                                dataField:'tglPenjualan',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Penjualan'
                                },
                                editorType: 'dxDateBox'
                            },
                            {
                                dataField:'obligasi',
                                label: {
                                    alignment:'left',
                                    text: 'Obligasi'
                                }
                            },
                            {
                                dataField:'managerInvest',
                                label: {
                                    alignment:'left',
                                    text: 'Manager Investasi'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Catatan',
                        items: [
                            {
                                dataField: 'note',
                                label: {
                                    visible: false,
                                },
                                editorType:'dxTextArea',
                                editorOptions:{
                                    height: '120'
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Informasi Saldo Obligasi',
                        items: [
                            {
                                dataField: 'currency',
                                label: {
                                    alignment:'left',
                                    text: 'Currency'
                                }
                            },
                            {
                                dataField: 'faceValue',
                                label: {
                                    alignment:'left',
                                    text: 'Face Value'
                                }
                            },
                            {
                                dataField: 'tglPembelian',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Pembelian'
                                }
                            },
                            {
                                dataField: 'hargaPembelian',
                                label: {
                                    alignment:'left',
                                    text: 'Harga Pembelian'
                                }
                            },
                            {
                                dataField: 'tglJatuhTempo',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Jatuh Tempo'
                                }
                            },
                            {
                                dataField: 'accuredInterest',
                                label: {
                                    alignment:'left',
                                    text: 'Accured Interest'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        itemType: 'group',
                        caption: 'Penjualan',
                        items: [
                            {
                                dataField: 'hargaPenjualan',
                                label:{
                                    alignment:'left',
                                    text: 'Harga Penjualan (%)'
                                }
                            },
                            {
                                dataField: 'bank',
                                label:{
                                    alignment:'left',
                                    text: 'Bank'
                                }
                            },
                            {
                                dataField: 'broker',
                                label:{
                                    alignment:'left',
                                    text: 'Broker'
                                }
                            },
                        ]
                    }
                ]
            }
        ]
        this.DataPertimbangan = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                }
            },
            {
                dataField: 'kode',
                label: {
                    alignment: 'left',
                    text: 'Kode'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal'
                }
            },
            {
                dataField: 'instrumen',
                label: {
                    alignment: 'left',
                    text: 'Instrumen'
                }
            },
            {
                dataField: 'transaksi',
                label: {
                    alignment: 'left',
                    text: 'Transaksi'
                }
            },
            {
                dataField: 'efek',
                label: {
                    alignment:'left',
                    text: 'Efek'
                }
            },
        ]
        this.toolbarItem = [
            'background' , 'bold' , 'color' , 'italic' , 'link' , 'image' , 'strike' , 'subscript' , 'superscript' , 'underline' , 'blockquote' , 'header' 
            , 'increaseIndent' , 'decreaseIndent' , 'orderedList' , 'bulletList' , 'alignLeft' , 'alignCenter' , 'alignRight' , 'alignJustify' , 'codeBlock' 
            , 'variable' , 'separator' , 'undo' , 'redo' , 'clear'
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async(data) => {    
        this.setState({
            dataPeserta: data,
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Order Obligasi'}
                minWidth={'70vh'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        
                        <div id="tabindex-0" className={' mt-2 col-md-12' } style={{height:"calc(55vh - 70px)", overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formDataPembelian'}
                                        formData={this.state.dataPembelian}
                                        items={this.DataPembelian}
                                        scrollingEnabled={true}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="tabindex-1" className={'d-none mt-2 col-md-12' } style={{height:"calc(55vh - 70px)", overflowY:"auto"}}>
                            <Form
                                colCount={4}
                                id={'formPertimbangan'}
                                formData={this.state.dataPertimbangan}
                                items={this.DataPertimbangan}
                                scrollingEnabled={false}
                                readOnly={true}
                                labelLocation={"left"}
                            />
                            <HtmlEditor 
                                height="300px"
                                value={this.state.content}
                                name={'content'}
                                onValueChanged={this.onValueChanged}
                            >
                                <Toolbar>
                                    {    
                                        this.toolbarItem.map((toolbarItem) => {
                                            return(
                                                <Item
                                                    formatName = {toolbarItem}
                                                />
                                            )
                                        })
                                    }
                                </Toolbar>
                            </HtmlEditor>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormObligasi
