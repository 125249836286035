import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormPersetujuanPengajuan from 'components/pages/modal/investasi/persetujuan/index'
import httpRequest from 'plugin/httprequest'
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import DetailListPersetujuan from './detail'

class ApprovalPengajuan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: this.props.store.getState().sessionUser,
            toggleModalState: false
        }

        this.toggleModalState = false

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Show',
                        hint: 'Show',
                        onClick: (e) => {
                            if (e.row) {
                                this.showModal(e.row.data)
                                this.toggleModalState = true
                                this.forceUpdate()
                                // this.setState({
                                //     toggleModalState: true
                                // })
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'noPengajuan',
                caption: 'Nomor Pengajuan'
            },
            {
                dataField: 'operation',
                caption: 'Operation',
            },
            {
                dataField: 'taskName',
                caption: 'Task Name'
            }
        ]
        this.persetujuanModalRef = React.createRef()
        this.dataGridApprovalRef = React.createRef()
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModal = async (data) => {
        var procId = data.procInstanceId
        var loadAPIOrder = []
        var requestData = []
        var loadAPIProc = `pengajuanorder-wf/${procId}`
        var responseDataProc = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPIProc, 'GET')
        var userComite = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `users/group/COMMITTEE`, 'GET')
        var entityId = responseDataProc.entityId
        var request = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${entityId}`)
        var category = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-categories/${request.categoryId}`)
        if (request.categoryId == 3) {
            loadAPIOrder = `order-requests-deposito/${entityId}`
            requestData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPIOrder, 'GET')
        } else if (request.categoryId == 1) {
            console.log(request.orderTypeId)
            if (request.orderTypeId == 319) {
                loadAPIOrder = `order-requests-ipo/${entityId}`
            } else {
                loadAPIOrder = `order-requests-stok/${entityId}`
            }
            requestData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPIOrder, 'GET')
        } else if (request.categoryId == 2) {
            loadAPIOrder = `order-requests-rd-dtls/${entityId}`
            requestData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPIOrder, 'GET')
        } else if (request.categoryId == 4) {
            loadAPIOrder = `order-requests-obligasi/${entityId}`
            requestData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPIOrder, 'GET')
        } else {
            requestData = request
        }
        var allData = {
            ...responseDataProc,
            instrument: category.categoryName,
            transactionType: request.orderTypeId,
            taskId: data.taskId,
            dataRequest: requestData,
            request: request,
            jumlahKomite: Object.keys(userComite).length,
        }
        this.persetujuanModalRef.current.show()
        this.persetujuanModalRef.current.retrieveData(allData)
    }
    forceRefresh = () => {
        this.dataGridApprovalRef.current.forceRefresh(true)
    }

    hideModal = () => {
        // this.setState({ toggleModalState: false })
        this.toggleModalState = false
    }
    // dataSource = async () => {
    //     var data = []
    //     var approveData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `pengajuanorder-wf/tasks/${this.state.username}`, 'GET')
    //     for (var approve of approveData) {
    //         var procData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `pengajuanorder-wf/${approve.procInstanceId}`, 'GET')
    //         var requestData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${procData.entityId}`, 'GET')
    //         var allData = {
    //             requestNo: requestData.requestNo,
    //             ...approve
    //         }
    //         data.push(allData)
    //     }
    //     return data
    // }
    customDetailElem = (data) => {
        return (
            <DetailListPersetujuan detailData={data} store={this.props.store} />
        )
    }
    render() {
        console.log(this.toggleModalState);
        return (
            <div className="container-fluid">
                <h2 className="main-title">Persetujuan Pengajuan Investasi</h2>
                <DevExpressDataGrid
                    ref={this.dataGridApprovalRef}
                    keyField="taskId"
                    loadAPI={`pengajuanorder-wf/tasks/${this.state.username}`}
                    insertAPI='pengajuanorder-wf/tasks'
                    updateAPI='pengajuanorder-wf/tasks'
                    deleteAPI='pengajuanorder-wf/tasks'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource={true}
                    // ArraySourceData={this.dataSource}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Approval Request"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Approval Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    height={'calc(100vh - 220px)'}

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}


                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                {this.toggleModalState && <FormPersetujuanPengajuan ref={this.persetujuanModalRef} toggleModal={this.hideModal} store={this.props.store} forceRefresh={this.forceRefresh} />}
                {/* <FormPersetujuanPengajuan ref={this.persetujuanModalRef} store={this.props.store} forceRefresh={this.forceRefresh} /> */}
            </div>
        )
    }
}

export default ApprovalPengajuan