import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instrumentCategorySubs, banks, ratings } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDefaultFullDate, numberWithCommas, formatNumberAum, summaryValue } from 'plugin/helper'
import { reportFile } from 'plugin/reportRequest'
import notify from 'devextreme/ui/notify';
import store from 'redux/config'
import Excel from 'exceljs/dist/exceljs';
import { showLoading } from 'redux/actions/loading'
import { saveAs } from 'file-saver'

class DataGridDailyBalanceAsetLain extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'tanggalPenempatan'
            },
            {
                name: 'faceValue',
                column: 'faceValue',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'Penyusutan',
                column: 'akumPenyusutan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'bookValue',
                column: 'bookValue',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiSekarang',
                column: 'nilaiSekarang',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'unrealizedPlPersen',
                showInColumn: 'unrealizedPlPersen',
                summaryType: 'custom',
                valueFormat: '#,##0.00',
                displayFormat: '{0}',
            },
            {
                name: 'unrealizedPlNominal',
                column: 'unrealizedPlNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            // {
            //     dataField: 'no.',
            //     alignment: 'right',
            //     cellRender: (e) => {
            //         return e.rowIndex + 1 + '.'
            //     }
            // },
            {
                dataField: 'instrumentName',
                caption: 'Nama Instrument',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'tanggalPenempatan',
                caption: 'Tanggal Penempatan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'faceValue',
                caption: 'face Value',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'akumPenyusutan',
                caption: ' Penyusutan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'bookValue',
                caption: 'Book Value',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiSekarang',
                caption: 'Nilai Sekarang',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'unrealizedPlPersen',
                caption: 'Unrealized P/L (%)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'unrealizedPlNominal',
                caption: 'Unrealized Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]
        this.dataGridRef = React.createRef();
        this.selectedRowsData = [];
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.print()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.exportExcel();
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            }
        )
    }

    customCalculate = (options) => {
        if (options.name === 'unrealizedPlPersen') {
            if (options.summaryProcess === 'start') {
                options.totalValue = {
                    
                };
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue = {
                    totalUnrealizedPlNominal : (options.totalValue.totalUnrealizedPlNominal || 0) + options.value.unrealizedPlNominal,
                    totalFaceValue : (options.totalValue.totalFaceValue || 0) + options.value.faceValue,
                }
            } else if (options.summaryProcess === 'finalize') {
                options.totalValue = (options.totalValue.totalUnrealizedPlNominal / options.totalValue.totalFaceValue) * 100
            }
        }
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = this.selectedRowsData;
        let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');
        let arrfaceValue = [];
        let arrAkumPenyusutan = [];
        let arrBookValue = [];
        let arrTotalNilaiSekarang = [];
        let arrTotalPlPerc = [];
        let arrTotalPlNom = [];
        store.dispatch(showLoading(true));

        for (let value of response) {
            arrfaceValue.push(value.faceValue);
            arrAkumPenyusutan.push(value.akumPenyusutan);
            arrBookValue.push(value.bookValue);
            arrTotalNilaiSekarang.push(value.nilaiSekarang);
            arrTotalPlPerc.push(value.unrealizedPlPersen);
            arrTotalPlNom.push(value.unrealizedPlNominal);
            let dataValue = {
                name: value.instrumentName ? value.instrumentName : '-',
                code: value.instrumentCode ? value.instrumentCode : '-',
                tanggalPenempatan: value.tanggalPenempatan ? formatDefaultFullDate(value.tanggalPenempatan) : '-',
                faceValue: value.faceValue,
                akumPenyusutan: value.akumPenyusutan,
                bookValue: value.bookValue,
                nilaiSekarang: value.nilaiSekarang,
                unrealizedPlPersen: value.unrealizedPlPersen,
                unrealizedPlNominal: value.unrealizedPlNominal
            }
            const objectValue = Object.values(dataValue);
            data.push(objectValue);

            var totalFaceValue = arrfaceValue.reduce((a, b) => a + b, 0);
            var totalAkunPenyusutan = arrAkumPenyusutan.reduce((a, b) => a + b, 0);
            var totalBookValue = arrBookValue.reduce((a, b) => a + b, 0);
            var totalNilaiSekarang = arrTotalNilaiSekarang.reduce((a, b) => a + b, 0);
            var totalPlPerc = arrTotalPlPerc.reduce((a, b) => a + b, 0);
            var totalPlNom = arrTotalPlNom.reduce((a, b) => a + b, 0);
        }

        let totalUnrealizedPer = (totalPlNom / totalFaceValue) * 100

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('DAILY BALANCE PORTFOLIO ASET LAIN LAIN');
        worksheet.addTable({
            name: 'MyTable',
            ref: `A${firstTableRowNum}`,
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
            },
            columns: [
                { name: 'Nama Instrument' },
                { name: 'Kode Instrument' },
                { name: 'Tanggal Penempatan' },
                { name: 'Face Value' },
                { name: 'Penyusutan' },
                { name: 'Book Value' },
                { name: 'Nilai Sekarang' },
                { name: 'Unrealized P/L (%)' },
                { name: 'Unrealized Nominal' }
            ],
            rows: data
        })

        let lastRowNum = worksheet.lastRow.number;
        const lastTableRowNum = lastRowNum;

        for (let i = firstTableRowNum; i <= lastTableRowNum; i++) {
            const row = worksheet.getRow(i);
            row.eachCell({ includeEmpty: true }, (cell => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
            }))
        }

        for (let i = 9; i <= 10000; i++) {
            worksheet.getCell('A' + i).alignment = { vertical: 'middle', horizontal: 'left' };
            worksheet.getCell('D' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('E' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('F' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('G' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('H' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            worksheet.getCell('I' + i).alignment = { vertical: 'middle', horizontal: 'right' };
        }

        worksheet.getCell('D2').value = 'DANA PENSIUN PEGADAIAN';
        worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D2').font = { size: 18, bold: true }

        worksheet.getCell('D3').value = 'DAILY BALANCE PORTFOLIO ASET LAIN - LAIN';
        worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D3').font = { size: 18, bold: true }

        worksheet.getCell('D4').value = `KPD ${this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName.toUpperCase() : '-'}`;
        worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D4').font = { size: 18, bold: true }

        worksheet.getCell('D5').value = `TANGGAL LAPORAN ${this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-'}`;
        worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
        worksheet.getCell('D5').font = { size: 18, bold: true }

        worksheet.mergeCells(`A${lastTableRowNum + 1}:C${lastTableRowNum + 1}`);
        worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total';
        worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'center' };
        worksheet.getCell(`A${lastTableRowNum + 1}`).font = { size: 12, bold: true }
        worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        worksheet.getCell(`D${lastTableRowNum + 1}`).value = totalFaceValue;
        worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`D${lastTableRowNum + 1}`).value = totalFaceValue;
        worksheet.getCell(`D${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`E${lastTableRowNum + 1}`).value = totalAkunPenyusutan;
        worksheet.getCell(`E${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`F${lastTableRowNum + 1}`).value = totalBookValue;
        worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`G${lastTableRowNum + 1}`).value = totalNilaiSekarang;
        worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`H${lastTableRowNum + 1}`).value = totalUnrealizedPer;
        worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        worksheet.getCell(`I${lastTableRowNum + 1}`).value = totalPlNom;
        worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

        var WidthColums = 20;
        var WidthColums2 = 35;

        const Data1 = worksheet.getColumn(1);
        Data1.width = 45;

        const Data2 = worksheet.getColumn(2);
        Data2.width = WidthColums;

        const Data3 = worksheet.getColumn(3);
        Data3.width = WidthColums2;

        const Data4 = worksheet.getColumn(4);
        Data4.numFmt = '#,##0.00';
        Data4.width = WidthColums;

        const Data5 = worksheet.getColumn(5);
        Data5.numFmt = '#,##0.00';
        Data5.width = WidthColums;

        const Data6 = worksheet.getColumn(6);
        Data6.numFmt = '#,##0.00';
        Data6.width = WidthColums;

        const Data7 = worksheet.getColumn(7);
        Data7.numFmt = '#,##0.00';
        Data7.width = WidthColums;

        const Data8 = worksheet.getColumn(8);
        Data8.numFmt = '#,##0.00';
        Data8.width = WidthColums;

        const Data9 = worksheet.getColumn(9);
        Data9.numFmt = '#,##0.00';
        Data9.width = WidthColums;

        await workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                'Daily_balance_portfolio_Aset_Lain_Lain.xlsx'
            );
        });

        store.dispatch(showLoading(false));
    }

    print = async () => {
        try {
            //  let loadAPI = `view-portofolio-daily-balances?balanceDate.equals=${this.props.stateData.balanceDate}&categoryId.in=1&kpdId.in=${this.props.stateData.kpdId}&productCode.in=${this.props.stateData.productCode}&size=9999`;
            //  let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');

            let response = this.selectedRowsData;

            let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');

            let transaksiDetil = [];
            if (response.length !== 0) {
                let arrfaceValue = [];
                let arrAkumPenyusutan = [];
                let arrBookValue = [];
                let arrTotalNilaiSekarang = [];
                let arrTotalPlPerc = [];
                let arrTotalPlNom = [];

                for (let data of response) {
                    arrfaceValue.push(data.faceValue);
                    arrAkumPenyusutan.push(data.akumPenyusutan);
                    arrBookValue.push(data.bookValue);
                    arrTotalNilaiSekarang.push(data.nilaiSekarang);
                    arrTotalPlPerc.push(data.unrealizedPlPersen);
                    arrTotalPlNom.push(data.unrealizedPlNominal);
                    let dataResult = {
                        name: data.instrumentName ? data.instrumentName : '-',
                        code: data.instrumentCode ? data.instrumentCode : '-',
                        tanggalPenempatan: data.tanggalPenempatan ? formatDefaultFullDate(data.tanggalPenempatan) : '-',
                        faceValue: data.faceValue ? data.faceValue : 0,
                        akumPenyusutan: data.akumPenyusutan ? data.akumPenyusutan : 0,
                        bookValue: data.bookValue ? data.bookValue : 0,
                        nilaiSekarang: data.nilaiSekarang ? data.nilaiSekarang : 0,
                        unrealizedPlPersen: data.unrealizedPlPersen ? data.unrealizedPlPersen : 0,
                        unrealizedPlNominal: data.unrealizedPlNominal ? data.unrealizedPlNominal : 0
                    }

                    transaksiDetil.push(dataResult);

                    var totalFaceValue = arrfaceValue.reduce((a, b) => a + b, 0);
                    var totalAkunPenyusutan = arrAkumPenyusutan.reduce((a, b) => a + b, 0);
                    var totalBookValue = arrBookValue.reduce((a, b) => a + b, 0);
                    //  var totalPrice = arrTotalPrice.reduce((a, b) => a + b, 0);
                    var totalNilaiSekarang = arrTotalNilaiSekarang.reduce((a, b) => a + b, 0);
                    var totalPlPerc = arrTotalPlPerc.reduce((a, b) => a + b, 0);
                    var totalPlNom = arrTotalPlNom.reduce((a, b) => a + b, 0);

                }

                let totalUnrealizedPer = (totalPlNom / totalFaceValue) * 100

                reportFile({
                    template: { 'shortid': 'nYt8uG4' },
                    data: {
                        "productCode": this.props.stateData.productCode,
                        "kpdName": this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName : '-',
                        "reportDate": this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-',
                        "categoryName": this.props.tabName ? this.props.tabName : '-',
                        "dailyBalanceDetail": transaksiDetil,
                        "totalFaceValue": totalFaceValue,
                        "totalAkumPenyusutan": totalAkunPenyusutan,
                        "totalBookValue": totalBookValue,
                        "totalNilaiSekarang": totalNilaiSekarang,
                        "totalPlPerc": totalUnrealizedPer,
                        "totalPlNom": totalPlNom
                    },
                    options: {
                        reportName: "Daily Balance Portofolio Aset Lain Lain"
                    }
                }, true, 'Daily Balance Portofolio Aset Lain Lain.pdf')
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`view-portofolio-daily-balances?balanceDate.equals=${this.props.stateData.balanceDate}&categoryId.in=17&kpdId.in=${this.props.stateData.kpdId}&productCode.in=${this.props.stateData.productCode}&size=9999`}
                    insertAPI='efek-balance-avgs'
                    updateAPI='efek-balance-avgs'
                    deleteAPI='efek-balance-avgs'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={false}
                    exportFileName={"Portofolio Aset Lain Lain"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    showPageSizeSelector={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Aset Lain Lain'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    calculateCustomSummary={this.customCalculate}
                    
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    height={'calc(100vh - 367px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridDailyBalanceAsetLain