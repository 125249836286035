import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, managerInvestasis, product, instruments, productDefault, rdNeedConfirmByMi, broker, emitens, kpds, fundManager, rekeningBank } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import ArrayStore from 'devextreme/data/array_store';
import ScrollView from "devextreme-react/scroll-view";
import { showLoading } from 'redux/actions/loading';


class ModalSettlementTransaksiIpo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataPembelian: {},
            dataMaster: {},
            actionType: null
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.subscriptionRef = React.createRef()
        this.masterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validateSubs()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    text: 'No Konfirmasi',
                },
                editorOptions: {
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confrimation number"
                    }
                ]
            },
            {
                dataField: 'orderId',
                label: {
                    alignment: 'left',
                    text: 'No Order'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'orderId',
                    displayExpr: 'orderCode',
                    onSelectionChanged: async (e) => {
                        var data = e.selectedItem
                        if (data) {
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-ipos/${data.orderIpoId}`, 'GET')
                            var responseOrd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `orders/${data.orderId}`, 'GET')
                            var responseRekening = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-brokers?brokerId.in=${data.brokerId}`, 'GET')
                            if (responseRekening.length <= 0) {
                                notify({ message: `Tidak dapat menemukan rekening dengan broker ${data.brokerName}`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                            this.masterRef.current.instance.updateData({
                                brokerId: data.brokerId,
                                emitenId: response.emitenId,
                                kpdId: responseOrd.kpdId,
                                productId: responseOrd.productId,
                                noRekBroker: responseRekening.length > 0 ? responseRekening[0].rekNo : ' ',
                                nameRekBroker: responseRekening.length > 0 ? responseRekening[0].rekName : ' ',
                                nominal: data.amountOrder,
                            })
                        }
                    }
                },
            },
            {
                dataField: 'brokerId',
                label: {
                    text: 'Broker',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.brokerCode + ' - ' + e.brokerName
                    },
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Broker"
                    }
                ]
            },
            {
                dataField: 'emitenId',
                label: {
                    text: 'Emiten',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: emitens(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.emitenCode + ' - ' + e.emitenName
                    },
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Emiten"
                    }
                ]
            },
            {
                dataField: 'noRekBroker',
                label: {
                    text: 'No Rekening Broker',
                },
                editorOptions: {
                    readOnly: true,
                }
            },
            {
                dataField: 'nameRekBroker',
                label: {
                    text: 'Nama Rekening Broker',
                },
                editorOptions: {
                    readOnly: true,
                }
            },
            {
                dataField: 'kpdId',
                label: {
                    text: 'Pengelola',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak',
                    searchEnabled: true,
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose KPD"
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    readOnly: true,
                    onValueChanged: (e) => {
                        this.rekBankByProduct(this.props.store, e.value)
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Product"
                    }
                ]
            },
            {
                dataField: 'tglTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose transaction date"
                    }
                ]
            },
            {
                dataField: 'tglPembayaran',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose payment date"
                    }
                ]
            },
            {
                dataField: 'tglSettle',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Settle'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose settle date"
                    }
                ]
            },
            {
                dataField: 'nominal',
                label: {
                    alignment: 'left',
                    text: 'Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Mata Uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    onSelectionChanged: (e) => {
                        var slctd = e.selectedItem
                        if(slctd.length > 0) {
                            this.masterRef.current.instance.updateData('rateCurrency', slctd.rate)
                        }
                    }
                }
            },
            {
                dataField: 'rateCurrency',
                label: {
                    alignment: 'left',
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'fundManager',
                label: {
                    alignment: 'left',
                    text: 'Fund Manager'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fundManagerName',
                }
            },
            {
                dataField: 'rekBankPaymentId',
                label: {
                    alignment: 'left',
                    text: 'Rekening Transfer Bayar'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                    searchEnabled: true,
                }
            },
        ]
    }

    show = () => {
        // var response = await httpRequest(
        //     process.env.REACT_APP_BACKEND_INVESTASI,
        //     this.props.store,
        //     'bank-transactions/generate-no-konfirmasi/REKSADANA',
        //     'POST',
        //     { values: {} }
        // )
        this.setState({
            popupVisible: true,
            dataMaster: {
                noKonfirmasi: 'TRIPO-' + Math.floor(Math.random() * 100000)
            },
            selectedTabIndex: 0
        });
        this.insertLookup()
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    validateSubs = () => {
        var formValidation = this.masterRef.current.instance.validate();
        // formValidation = this.masterRef.current.instance.validate();
        return formValidation.isValid
    }
    insertLookup = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-order-ipo-pembelian-need-confirm-lists?statusId.in=INSTRUKSI_SENT`, 'GET')
        let dataOrder = new ArrayStore({
            data: response,
            key: 'id'
        })
        this.masterRef.current.instance.getEditor('orderId').option('dataSource', dataOrder)
    }
    rekBankByProduct = async (store, productId) => {
        let loadAPI = `rek-banks-by-productId?productId=${productId}`
        var getRekening = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRekening,
            key: 'id'
        });
        
        this.masterRef.current.instance.getEditor('rekBankPaymentId').option('dataSource', dataSource)
    }
    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        var dataSubmit = this.state.dataMaster
        if (dataSubmit) {
            try {
                var insertAPI = 'order-ipos/settle-ipo-saham'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: dataSubmit
                })

                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi IPO Saham'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.masterRef}
                            formData={this.state.dataMaster}
                            items={this.dataMaster}
                            labelLocation={"left"}
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalSettlementTransaksiIpo