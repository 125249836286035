import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, product, hubunganKeluarga, pensiunType } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import FormModalGenerateMpSusulan from 'components/pages/modal/kepesertaan/pembayaranMp/susulan/formGenerate'
import httpRequest from 'plugin/httprequest';
import FormModalDetailMpSusulan from 'components/pages/modal/kepesertaan/pembayaranMp/susulan/formDetail';
import FormNoteModalMpSusulan from 'components/pages/modal/kepesertaan/pembayaranMp/susulan/formNotes'
import { alert } from 'devextreme/ui/dialog';
import { confirmAlert } from 'react-confirm-alert';
import { showLoading } from 'redux/actions/loading';
import FormNoteModalRejectMpSusulan from 'components/pages/modal/kepesertaan/pembayaranMp/susulan/formNotesReject';

class PengajuanPembayaranMpSusulan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formFilter: {},
            getUrl: 'view-pembayaran-mp-susulans?size=99999&statusName.in=Open'
        }

        this.data = {}

        this.filterItem = [
            {
                dataField: 'produk',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        var loadAPI
                        if(data.value){
                            loadAPI = `view-pembayaran-mp-susulans?statusName.in=Open&productId.in=${data.value}`
                        }else{
                            loadAPI = 'view-pembayaran-mp-susulans?statusName.in=Open'
                        }
                        this.setState({
                            getUrl: loadAPI
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                width: 100,
                buttons: [
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        visible: false,
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-info',
                        onClick: (e) => {
                            this.showModalNotes()
                            this.singleData = true
                            this.data = e.row.data
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-info',
                        onClick: (e) => {
                            this.showModalNotesReject()
                            this.data = e.row.data
                        }
                    },
                ],
            },
            // {
            //     dataField: 'no',
            //     caption: 'No.',
            //     cellRender: (e) => {
            //         return e.rowIndex + 1
            //     }
            // },
            {
                dataField: 'productId',
                caption: 'Nama Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'mpName',
                caption: 'Nama Pembayaran',
            },
            {
                dataField: 'jumlahPensiunan',
                caption: 'Jumlah Pensiunan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'totalNominal',
                caption: 'Total Nilai Pembayaran Susulan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            // {
            //     dataField: 'nominaPajak',
            //     caption: 'Nominal Pajak',
            //     dataType: 'number',
            //     alignment: 'right',
            //     format: '#,##0.00'
            // },
            {
                dataField: 'paymentDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                name: 'resume',
                caption: 'Resume',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'rekName',
                        caption: 'Bank Bayar',
                    },
                    {
                        dataField: 'totalBiayaBank',
                        caption: 'Total Biaya Bank',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'statusName',
                caption: 'AR/AP Status',
            },
        ]

        this.singleData = true
        this.notes = ''
        this.selectedRowDatas = []
        this.dataGridRef = React.createRef();
        this.showModalGenerateMpSusulanRef = React.createRef();
        this.showModalDetailMpSusulanRef = React.createRef();
        this.showModalNotesMpSusulanRef = React.createRef();
        this.showModalNotesRejectMpSusulanRef = React.createRef();
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Generate Pembayaran',
                    // elementAttr: { class: "bg-dapen" },
                    onClick: (e) => {
                        this.showModalGenerateMpSusulan(e)
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Kirim',
                    elementAttr: { class: "bg-dapen" },
                    onClick: () => {
                        this.singleData = false
                        this.showModalNotes()
                        // this.kirimMulti(this.selectedRowDatas)
                    },
                }
            },

        )
    }

    showModalGenerateMpSusulan = () => {
        this.showModalGenerateMpSusulanRef.current.show()
    }

    showModalDetail = (data) => {
        if (data) {
            this.showModalDetailMpSusulanRef.current.retrieveData(data)
        }
        this.showModalDetailMpSusulanRef.current.show()
    }

    showModalNotes = (from) => {
        this.showModalNotesMpSusulanRef.current.show(from)
    }

    showModalNotesReject = () => {
        this.showModalNotesRejectMpSusulanRef.current.show()
    }

    catatan = (catatan) => {
        if (this.singleData) {
            this.kirim()
        } else {
            this.kirimMulti(this.selectedRowDatas)
        }
        this.notes = catatan
    }

    kirim = async () => {
        this.props.store.dispatch(showLoading(true))
        var data = this.data
        let mpSusulanMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mps/${data.pembayaranMpId}`, 'GET')
        var mpSusulanDetilByMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils/getByMpMasterId/${data.pembayaranMpId}`, 'GET')

        mpSusulanMaster.statusId = 2
        mpSusulanMaster.notesMpSusulan = this.catatan
        var detilSusulan = []
        var message
        try {
            var submitMpMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mps`, 'PUT', {
                values: mpSusulanMaster,
                key: mpSusulanMaster.id
            }, mpSusulanMaster)
            if (submitMpMaster) {
                message = "Sukses"
            }
        } catch (e) {
            console.log(e);
            message = "Gagal"
            this.props.store.dispatch(showLoading(false))
        }

        for (let data of mpSusulanDetilByMaster) {
            let mpSusulanDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils/${data.idPembayaranMpDetil}`, 'GET')
            mpSusulanDetil.pembayaranMpStatusId = 2
            try {
                var submitMpDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils`, 'PUT', {
                    values: mpSusulanDetil,
                    key: mpSusulanDetil.id
                }, mpSusulanDetil)

                if (submitMpDetil) {
                    detilSusulan.push({ message: 'Sukses' })
                }
            } catch (e) {
                console.log(e);
                detilSusulan.push({ message: 'Gagal' })
                this.props.store.dispatch(showLoading(false))
            }
        }

        if (message == "Sukses" && detilSusulan[0].message == "Sukses") {
            this.props.store.dispatch(showLoading(false))
            await alert('Data Sukses dikirim', 'Sukses')
            this.forceRefresh()
        } else if (message != "Sukses") {
            this.props.store.dispatch(showLoading(false))
            await alert('Data Gagal dikirim', 'Gagal')
            this.forceRefresh()
        } else {
            this.props.store.dispatch(showLoading(false))
            await alert('Data Detil Gagal dikirim', 'Gagal')
            this.forceRefresh()
        }
    }

    kirimMulti = async (selectedRowDatas) => {
        this.props.store.dispatch(showLoading(false))
        this.statusSuccess = 0
        for (let data of selectedRowDatas) {
            try {
                var mpSusulanMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mps/${data.pembayaranMpId}`, 'GET')
                var mpSusulanDetilByMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils/getByMpMasterId/${data.pembayaranMpId}`, 'GET')

            } catch (e) {
                console.log(e);
                await alert('PembayaranMpId Kosong', 'Gagal')
                this.props.store.dispatch(showLoading(false))
            }

            mpSusulanMaster.statusId = 2
            mpSusulanMaster.notesMpSusulan = this.notes
            var detilSusulan = []
            var message

            try {
                var submitMpMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mps`, 'PUT', {
                    values: mpSusulanMaster,
                    key: mpSusulanMaster.id
                }, mpSusulanMaster)
                if (submitMpMaster) {
                    message = "Sukses"
                }
            } catch (e) {
                console.log(e);
                message = "Gagal"
                this.props.store.dispatch(showLoading(false))
            }

            for (let data of mpSusulanDetilByMaster) {
                let mpSusulanDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils/${data.idPembayaranMpDetil}`, 'GET')
                mpSusulanDetil.pembayaranMpStatusId = 2
                try {
                    var submitMpDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils`, 'PUT', {
                        values: mpSusulanDetil,
                        key: mpSusulanDetil.id
                    }, mpSusulanDetil)

                    if (submitMpDetil) {
                        detilSusulan.push({ message: 'Sukses' })
                    }
                } catch (e) {
                    console.log(e);
                    detilSusulan.push({ message: 'Gagal' })
                    this.props.store.dispatch(showLoading(false))
                }
            }

            if (message == "Sukses" && detilSusulan[0].message == "Sukses") {
                this.statusSuccess = 1
            } else if (message != "Sukses") {
                this.statusSuccess = 2
            } else {
                this.statusSuccess = 3
            }
        }
        this.props.store.dispatch(showLoading(false))
        if (this.statusSuccess == 1) {
            await alert('Data Sukses dikirim', 'Sukses')
        } else if (this.statusSuccess == 2) {
            await alert('Data Gagal dikirim', 'Gagal')
        } else if (this.statusSuccess == 3) {
            await alert('Data Detil Gagal dikirim', 'Gagal')
        } else {
            await alert('Tidak ada data yang di kirim', 'Gagal')
            this.statusSuccess = 0
        }

        this.forceRefresh()

    }

    reject = async (catatan) => {
        this.props.store.dispatch(showLoading(true))
        var data = this.data
        let mpSusulanMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mps/${data.pembayaranMpId}`, 'GET')
        var mpSusulanDetilByMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils/getByMpMasterId/${data.pembayaranMpId}`, 'GET')

        mpSusulanMaster.statusId = 999
        mpSusulanMaster.notesMpSusulan = catatan
        var detilSusulan = []
        var message
        try {
            var submitMpMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mps`, 'PUT', {
                values: mpSusulanMaster,
                key: mpSusulanMaster.id
            }, mpSusulanMaster)
            if (submitMpMaster) {
                message = "Sukses"
            }
        } catch (e) {
            console.log(e);
            message = "Gagal"
            this.props.store.dispatch(showLoading(false))
        }

        for (let data of mpSusulanDetilByMaster) {
            let mpSusulanDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils/${data.idPembayaranMpDetil}`, 'GET')
            mpSusulanDetil.pembayaranMpStatusId = 999
            try {
                var submitMpDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils`, 'PUT', {
                    values: mpSusulanDetil,
                    key: mpSusulanDetil.id
                }, mpSusulanDetil)

                if (submitMpDetil) {
                    detilSusulan.push({ message: 'Sukses' })
                }
            } catch (e) {
                console.log(e);
                detilSusulan.push({ message: 'Gagal' })
                this.props.store.dispatch(showLoading(false))
            }
        }

        if (message == "Sukses" && detilSusulan[0].message == "Sukses") {
            this.props.store.dispatch(showLoading(false))
            await alert('Data dibatalkan', 'Sukses')
            this.forceRefresh()
        } else if (message != "Sukses") {
            this.props.store.dispatch(showLoading(false))
            await alert('Data gagal dibatalkan', 'Gagal')
            this.forceRefresh()
        } else {
            this.props.store.dispatch(showLoading(false))
            await alert('Data Detil gagal dibatalkan', 'Gagal')
            this.forceRefresh()
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    onSelectionChanged = (e) => {
        this.selectedRowDatas = e.selectedRowsData
    }

    loadData = async () => {
        let result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${this.state.getUrl}?size=9999`, 'GET')
        result = result.filter(value => value.statusName === 'Open')
        return result
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengajuan Pembayaran MP Susulan</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />
                <div
                    className="mt-2 text-center">
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI={this.state.getUrl}
                        insertAPI='pembayaran-mps'
                        updateAPI='pembayaran-mps'
                        deleteAPI='pembayaran-mps'
                        
                        // useArraySource={true}
                        // ArraySourceData={this.loadData}

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        // remoteOperations={true}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan MP Susulan"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan MP Susulan'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}
                        onSelectionChanged={this.onSelectionChanged}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalGenerateMpSusulan
                        ref={this.showModalGenerateMpSusulanRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    />
                    <FormModalDetailMpSusulan
                        ref={this.showModalDetailMpSusulanRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    />
                    <FormNoteModalMpSusulan
                        ref={this.showModalNotesMpSusulanRef}
                        store={this.props.store}
                        catatan={this.catatan}
                        forceRefresh={this.forceRefresh}
                    />
                    <FormNoteModalRejectMpSusulan
                        ref={this.showModalNotesRejectMpSusulanRef}
                        store={this.props.store}
                        reject={this.reject}
                        forceRefresh={this.forceRefresh}
                    />
                </div>
            </div>
        )
    }
}

export default PengajuanPembayaranMpSusulan