import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import notify from 'devextreme/ui/notify';
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url'
import ModalPesertaAktifRegistrasiAktif from 'components/pages/modal/kepesertaan/pesertaDapen/aktif/registrasi/modalRegistrasi'
import UploadPeserta from 'components/pages/modal/kepesertaan/compPeserta/uploadPeserta'
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { gender,participants, maritalStatus, statusPajak } from 'dataSource/lookup'
import UploadKeluarga from 'components/pages/modal/kepesertaan/compPeserta/uploadKeluarga';
import { confirmAlert } from 'react-confirm-alert'

class RegistrasiAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pemberiKerja: {},
            getUrl: 'view-pesertas?statusPeserta.in=1'
        }

        this.allData = []
        this.dataGridRef = React.createRef()
        this.selectedId = []

        this.popupUploadPesertaRef = React.createRef()
        this.popupUploadKeluargaRef = React.createRef()
        this.popupModalKeluargaRegistrasiAktifRef = React.createRef()
        this.popupModalAddRegistrasiAktifRef = React.createRef()
        this.popupModalEditRegistrasiAktifRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        var loadAPI
                        if(data.value){
                            loadAPI = `view-pesertas?participantId.in=${data.value}&statusPeserta.in=1`
                        }else{
                            loadAPI = 'view-pesertas?statusPeserta.in=1'
                            // loadAPI = 'simple-pesertas/getNewSimpleAll?statusPeserta.in=1'
                        }
                        this.setState({
                            getUrl: loadAPI
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalRegistrasi(e.row.data, 'edit')
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.kirim(e.row.data.id)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.batal(e.row.data.id)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'nik',
                caption: 'NIK',
                // alignment: "right",
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir'
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                alignment: "right",
                format: "dd MMM yyyy"
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'address1',
                caption: 'Alamat KTP',
                // cellRender: (rowInfo) => {
                //     var data = rowInfo.data

                //     return data.identityAddress1 + ' ' + (data.identityAddress2 ? data.identityAddress2 : '')
                // }
            },
            {
                dataField: 'mobileNo',
                caption: 'No HP'
            },
            {
                dataField: 'email',
                caption: 'Email'
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Mulai Kerja',
                dataType: "date",
                alignment: "right",
                format: "dd MMM yyyy"
            },
            {
                dataField: 'tanggalPeserta',
                caption: 'Tanggal Menjadi Peserta',
                dataType: "date",
                alignment: "right",
                format: "dd MMM yyyy"
            },
            {
                dataField: 'mariedId',
                caption: 'Status Keluarga',
                lookup: {
                    dataSource: maritalStatus(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'statusPajak',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: statusPajak(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'unitKerjaName',
                caption: 'Unit Kerja',
            },
            {
                dataField: 'grade',
                caption: 'Kelas',
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
                format: '#,##0.00'
            },
            {
                dataField: 'npwp',
                caption: 'NPWP'
            },
            {
                dataField: 'noKtp',
                caption: 'No KTP'
            },
        ]        
    }
    
    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    showModalUploadPeserta = (e) => {
        this.popupUploadPesertaRef.current.show()
    }

    showModalUploadKeluarga = (e) => {
        this.popupUploadKeluargaRef.current.show()
    }

    showModalRegistrasi = (data, type) => {
        if(type == 'add'){
            this.popupModalAddRegistrasiAktifRef.current.show()
            if(data){
                this.popupModalAddRegistrasiAktifRef.current.retrieveData(type, data)
            }
        }

        if(type == 'edit'){
            this.popupModalEditRegistrasiAktifRef.current.show()
            this.popupModalEditRegistrasiAktifRef.current.retrieveData(type, data)
        }
    }

    kirim = async (pesertaId) => {
        try {
            var loadAPI = 'pesertas'
            var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + pesertaId, 'GET')
    
            var dataUpdate = dataPeserta
            dataUpdate.statusPeserta = 2
    
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: dataUpdate.id,
                values: dataUpdate
            },dataPeserta)
            var text = `Data berhasil dikirim!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }

    batal = async (pesertaId) => {
        try {
            var loadAPI = 'peserta/delete-with-relation'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'DELETE', pesertaId)
    
            var text = `Data berhasil dibatalkan!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    kirimSelectedData = async(selectedId) => {
        if(selectedId.length !== 0){
            try {
                for(var value of selectedId){
                    var loadAPI = 'pesertas'
                    var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + value, 'GET')
            
                    var dataUpdate = dataPeserta
                    dataUpdate.statusPeserta = 2
            
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                        key: dataUpdate.id,
                        values: dataUpdate
                    },
                    dataPeserta)
                }
                var text = `Data berhasil dikirim!`
                var type = 'success'
            } catch (e) {
                console.log(e)
                var text = e
                var type = 'error'
            }
    
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
            this.forceRefresh()
        }else{
            var text = `Silahkan centang data terlebih dahulu!`
            var type = 'error'
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }
    }

    onSelectionChanged = (e) => {
        this.selectedId = e.selectedRowKeys
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Register Baru',
                    onClick: (e) => {
                        this.showModalRegistrasi(null, 'add')
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'upload',
                    text: 'Upload',
                    onClick: (e) => {
                        this.showModalUploadPeserta(e)
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'upload',
                    text: 'Upload Keluarga',
                    onClick: (e) => {
                        this.showModalUploadKeluarga(e)
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'message',
                    text: 'Kirim',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                            buttons: [
                              {
                                label: 'Iya',
                                onClick: () => {
                                    this.kirimSelectedData(this.selectedId)
                                }
                              },
                              {
                                label: 'Tidak',
                                onClick: () => {

                                }
                              },
                            ]
                        });
                    }
                }
            },
        )
    }

    triggerDataLoaded =  () => {
        ReactDOM.render(
            <React.Fragment>
                <ModalPesertaAktifRegistrasiAktif
                    ref = {this.popupModalAddRegistrasiAktifRef}
                    store = {this.props.store}
                    actionType = {'add'}
                    forceRefresh = {this.forceRefresh}
                />
                <ModalPesertaAktifRegistrasiAktif
                    ref = {this.popupModalEditRegistrasiAktifRef}
                    store = {this.props.store}
                    actionType = {'edit'}
                    forceRefresh = {this.forceRefresh}
                />
                <UploadPeserta 
                    store = {this.props.store}
                    forceRefresh = {this.forceRefresh}
                    ref = {this.popupUploadPesertaRef}
                />
                <UploadKeluarga
                    store = {this.props.store}
                    editDataKeluarga = {this.editDataKeluarga}
                    ref = {this.popupUploadKeluargaRef}
                />
            </React.Fragment>,
            document.getElementById('modal-container')
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Registrasi</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={' mt-3 text-center' } style={{height: '100%'}}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI={this.state.getUrl}
                        insertAPI='pesertas'
                        updateAPI='pesertas'
                        deleteAPI='pesertas'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        // keyField={'pesertaId'}
                        // useArraySource = {true}
                        // ArraySourceData = {this.loadDummyData}

                        remoteOperations={true}
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}
                        
                        exportExcel={true}
                        exportFileName={"Registrasi Peserta Aktif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        triggerDataLoaded = {this.triggerDataLoaded}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Registrasi Peserta Aktif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        onToolbarPreparing={this.onToolbarPreparing}

                        onSelectionChanged={this.onSelectionChanged}
                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="modal-container">

                </div>
            </div>
        )
    }
}

export default RegistrasiAktif
