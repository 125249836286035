import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { banks, instrumentSubCategoryDeposito, ordersOpen, depositoWasSent, kpds, broker, productDefault, instrumentObligasi, instrumentTransactionTypes, instrumentCategories } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalActionPenempatanSettlementTransaksiDeposito from './actionPenempatan';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import { formatDate } from 'plugin/helper';
import ModalActionSettlementSbnBond from './actionPenempatan';
import ScrollView from 'devextreme-react/scroll-view';
import { showLoading } from 'redux/actions/loading';


class ModalSettlementPenempatanSbnBond extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            actionType: null
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.loadDummyData = []

        this.formMaster = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'No Konfirmasi'
                },
                editorOptions: {
                    onValueChanged: (e) => {
                        this.setState({
                            dataMaster: {
                                noKonfirmasi: e.value
                            }
                        })
                    },
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confirmation number"
                    }
                ]
            },
            {
                dataField: 'tanggalKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Konfirmasi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confirmation date"
                    }
                ]
            },
            {
                dataField: 'categoryId',
                label: {
                    alignment: 'left',
                    text: 'Kategori'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 4,
                            categoryName: 'SBN'
                        },
                        {
                            id: 5,
                            categoryName: 'Obligasi Korporasi'
                        },
                        {
                            id: 6,
                            categoryName: 'Surat Hutang'
                        },
                        {
                            id: 7,
                            categoryName: 'EBA'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'categoryName',
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Category"
                    }
                ]
            },
            {
                dataField: 'institusiBroker',
                label: {
                    alignment: 'left',
                    text: 'Institusi Order',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Broker'
                        },
                        {
                            id: 2,
                            value: 'Bank'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    onValueChanged: (e) => {
                        var bank = this.formRef.current.instance.getEditor('bankId')
                        var broker = this.formRef.current.instance.getEditor('brokerId')
                        this.formRef.current.instance.getEditor('brokerId')
                        if (e.value == 1) {
                            bank.option('readOnly', true)
                            bank.option('value', null)
                            broker.option('readOnly', false)
                        } else {
                            broker.option('readOnly', true)
                            broker.option('value', null)
                            bank.option('readOnly', false)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose order institution"
                    }
                ]
            },
            {
                dataField: 'brokerId',
                label: {
                    alignment: 'left',
                    text: 'Broker'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                },
            },
            {
                dataField: 'bankId',
                label: {
                    alignment: 'left',
                    text: 'Bank'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                },

            },
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Product"
                    }
                ]
            },
            {
                dataField: 'pengelola',
                label: {
                    alignment: 'left',
                    text: 'Pengelola'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    items: ['Internal', 'KPD'],
                    layout: 'horizontal',
                    onValueChanged: (e) => {
                        var kpd = this.formRef.current.instance.getEditor('kpdId')
                        if (e.value === 'Internal') {
                            kpd.option('readOnly', true)
                            kpd.option('value', 1)
                        } else {
                            kpd.option('readOnly', false)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose pengelola"
                    }
                ]
            },
            {
                dataField: 'kpdId',
                label: {
                    alignment: 'left',
                    text: 'Tipe'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak',
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose KPD"
                    }
                ]
            },
        ]
        this.penempatanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            this.modalInsertRef.current.show(data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDetailPenempatanData(e.row.key)
                        }
                    },
                ],
            },
            {
                dataField: 'no',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'orderCode',
                caption: 'No Order',
            },
            {
                dataField: 'instrumentId',
                caption: 'SBN/BOND/Sukuk',
                lookup: {
                    dataSource: instrumentObligasi(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'orderTypeId',
                caption: 'Tipe Transaksi',
                lookup: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName'
                }
            },
            {
                dataField: 'tanggalTransaksi',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalPembayaran',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalPenyelesaian',
                caption: 'Tanggal Penyelesaian',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'faceValue',
                caption: 'Face Value',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'hargaPembelian',
                caption: 'Harga (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'netProceed',
                caption: 'Proceed',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },

        ]
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    cleanDataGrid = () => {
        var data = this.dataGridPenempatanRef.current.getDataSource()
        var deleteData = []
        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            this.dataGridPenempatanRef.current.push(deleteData)
        }
    }
    show = async() => {
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            'bank-transactions/generate-no-konfirmasi/SBN',
            'POST',
            {values: {}}
        ) 
        this.setState({
            popupVisible: true,
            dataMaster: {
                noKonfirmasi: response
            },
            selectedTabIndex: 0,
        });
    }
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
        this.dataGridPenempatanRef.current.forceRefresh(true)
    }
    loadData = () => {
        return []
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    submitData = async () => {
        this.props.store.dispatch(showLoading(true))

        var dataDataGrid = this.dataGridPenempatanRef.current.getDataSource()
        var dataMaster = this.state.dataMaster
        var trsDate = formatDate(new Date())
        var customSubmitData = {
            ...dataMaster,
            transaksiItemSbns: dataDataGrid,
            transactionDate: dataDataGrid[0].tanggalTransaksi
        }
        if (customSubmitData) {
            try {
                var insertAPI = 'order-sbns/settle-transaksi'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: customSubmitData
                })

                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {

                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    onToolbarPreparingPenempatan = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Penempatan',
                    icon: 'plus',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.showModalInsert()
                        }
                    }
                }
            }
        )
    }
    forceRefresh = () => {
        this.dataGridPenempatanRef.current.forceRefresh(true)
    }
    showModalInsert = () => {
        var data = this.state.dataMaster
        var master = {
            institusiId: data.institusiBroker == 1 ? data.brokerId : data.bankId,
            institusiBroker: data.institusiBroker,
            tanggalTransaksi: data.tanggalKonfirmasi,
            productId: data.productId,
            categoryId: data.categoryId,
        }
        if (master.institusiId == null) {
            notify({ message: 'Harap pilih bank / broker terlebih dahulu', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600)
        } else {
            this.modalInsertRef.current.show(master)
        }
    }
    submitDetailPenempatanData = (data) => {
        if (data.id) {
            this.dataGridPenempatanRef.current.push([{
                type: "update",
                data: {
                    biayaBank: data.biayaBank,
                    biayaLainnya: data.biayaLainnya,
                    bungaBerjalan: data.bungaBerjalan,
                    capitalGain: data.capitalGain,
                    catatan: data.catatan,
                    faceValue: data.faceValue,
                    fileKonfirmasi: data.fileKonfirmasi,
                    fileKonfirmasiContentType: data.fileKonfirmasiContentType,
                    hargaPembelian: data.hargaPembelian,
                    hargaPenjualan: data.hargaPenjualan,
                    hargaPerolehan: data.hargaPerolehan,
                    instrumentId: data.instrumentId,
                    komisi: data.komisi,
                    kurs: data.kurs,
                    proceed: data.proceed,
                    netProceed: data.netProceed,
                    orderSbnId: data.orderSbnId,
                    pajakCapitalGain: data.pajakCapitalGain,
                    pasar: data.pasar,
                    pphBungaBerjalan: data.pphBungaBerjalan,
                    tanggalPembayaran: data.tanggalPembayaran,
                    tanggalPenyelesaian: data.tanggalPenyelesaian,
                    tanggalTransaksi: data.tanggalTransaksi,
                    orderCode: data.orderCode,
                    orderTypeId: data.orderTypeId,
                    institusiId: data.institusiId,
                    institusiBroker: data.institusiBroker,
                    productId: data.productId,
                    instrumentName: data.instrumentName,
                    currencyId: data.currencyId,
                    rekBankId: data.rekBankId,
                    rate: data.rate,
                    couponLastDate: data.couponLastDate,
                    couponNextDate: data.couponNextDate,
                    categoryId: data.categoryId,
                    netPayment: data.netPayment,
                },
                key: data.id
            }])
        } else {
            this.dataGridPenempatanRef.current.push([{
                type: "insert",
                data: {
                    biayaBank: data.biayaBank,
                    biayaLainnya: data.biayaLainnya,
                    bungaBerjalan: data.bungaBerjalan,
                    capitalGain: data.capitalGain,
                    catatan: data.catatan,
                    faceValue: data.faceValue,
                    fileKonfirmasi: data.fileKonfirmasi,
                    fileKonfirmasiContentType: data.fileKonfirmasiContentType,
                    hargaPembelian: data.hargaPembelian,
                    hargaPenjualan: data.hargaPenjualan,
                    hargaPerolehan: data.hargaPerolehan,
                    instrumentId: data.instrumentId,
                    komisi: data.komisi,
                    kurs: data.kurs,
                    proceed: data.proceed,
                    netProceed: data.netProceed,
                    orderSbnId: data.orderSbnId,
                    pajakCapitalGain: data.pajakCapitalGain,
                    pasar: data.pasar,
                    pphBungaBerjalan: data.pphBungaBerjalan,
                    tanggalPembayaran: data.tanggalPembayaran,
                    tanggalPenyelesaian: data.tanggalPenyelesaian,
                    tanggalTransaksi: data.tanggalTransaksi,
                    orderCode: data.orderCode,
                    orderTypeId: data.orderTypeId,
                    institusiId: data.institusiId,
                    institusiBroker: data.institusiBroker,
                    productId: data.productId,
                    instrumentName: data.instrumentName,
                    currencyId: data.currencyId,
                    rekBankId: data.rekBankId,
                    rate: data.rate,
                    couponLastDate: data.couponLastDate,
                    couponNextDate: data.couponNextDate,
                    categoryId: data.categoryId,
                    netPayment: data.netPayment,
                }
            }])
        }
    }
    deleteDetailPenempatanData = (key) => {
        this.dataGridPenempatanRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Obligasi'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div id="tabindex-0" className={'mt-2 col-md-12 text-center'}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Form
                                            colCount={1}
                                            id={'formDataMaster'}
                                            ref={this.formRef}
                                            formData={this.state.dataMaster}
                                            items={this.formMaster}
                                            labelLocation={'left'}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <DevExpressDataGrid
                                            ref={this.dataGridPenempatanRef}
                                            loadAPI='cash-deviden'
                                            insertAPI='cash-deviden'
                                            updateAPI='cash-deviden'
                                            deleteAPI='cash-deviden'

                                            backendserver={process.env.REACT_APP_BACKEND_CORE}

                                            useArraySource={true}
                                            ArraySourceData={this.loadData}

                                            allowAdding={false}
                                            allowDeleting={false}
                                            allowUpdating={false}

                                            exportExcel={true}
                                            exportFileName={"Penempatan"}
                                            allowExportSelectedData={true}
                                            selection={"multiple"}

                                            showBorders={true}

                                            paging={true}
                                            defaultPageSize={10}
                                            height={300}

                                            //bagian konfigurasi popup saat insert dan edit record
                                            popupTitle={'Penempatan'}
                                            popupWidth={700} //masukan dalam ukuran pixel
                                            popupHeight={500} //masukkan dalam ukuran pixel

                                            popupFormLabelLocation='left' //accepted value = top, left, right
                                            popupFormMinColWidth={300} // minimum lebar kolom
                                            popupFormColCount={3} //jumlah kolom pada form

                                            //akhir bagian konfigurasi popup

                                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                            FilterRow={true} // set false untuk mematikan fitur filter

                                            ColumnConfiguration={this.penempatanColumns} // taruh konfigurasi kolom disini
                                            SummaryConfiguration={this.summary}

                                            onToolbarPreparing={this.onToolbarPreparingPenempatan}

                                            //contoh konfigurasi kolom
                                            //this.columns = [{
                                            //    dataField: 'kolom1',
                                            //    caption: 'Ini Kolom 1'
                                            //}, {
                                            //    dataField: 'kolom2',
                                            //    caption: 'Ini Kolom 2'
                                            //}]
                                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                            store={this.props.store} // jangan di edit edit
                                        />
                                    </div>
                                    <ModalActionSettlementSbnBond
                                        ref={this.modalInsertRef}
                                        store={this.props.store}
                                        forceRefresh={this.forceRefresh}
                                        submitDetailPenempatanData={this.submitDetailPenempatanData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalSettlementPenempatanSbnBond