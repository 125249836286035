import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper'
import ScrollView from 'devextreme-react/scroll-view';
import Box, { Item } from 'devextreme-react/box';
import { asetKategoriSdm, subAsetKategoriSdm, supplierSdm } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';

class FormModalKontrakProyek extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            fileUploadReceipt: [],
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'pengajuanNo',
                                label: {
                                    text: 'No Pengajuan'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'kontrakNo',
                                label: {
                                    text: 'No Kontrak'
                                },
                                editorOptions: {
                                    // readOnly: true
                                }
                            },
                            {
                                dataField: 'perjanjianNo',
                                label: {
                                    text: 'No Perjanjian'
                                }
                            },
                            {
                                dataField: 'kontrakTanggal',
                                label: {
                                    text: 'Tanggal Kontrak'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'pelaksana',
                        caption: 'Pelaksana',
                        items: [
                            {
                                dataField: 'supplierId',
                                label: {
                                    text: 'Nama Pelaksana'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: supplierSdm(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'namaSupplier',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: (e) => {
                                        let slctd = e.selectedItem
                                        if(slctd){
                                            this.formRef.current.instance.updateData({
                                                kodeSupplier: slctd.kodeSupplier,
                                                namaSupplier: slctd.namaSupplier,
                                                noTelp: slctd.noTelp,
                                                email: slctd.email,
                                                alamat: slctd.alamat,
                                                // fax: slctd.fax,
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'pimpro',
                                label: {
                                    text: 'Nama Pimpro'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'nilai-kontrak',
                        caption: 'Nilai Kontrak',
                        items: [
                            {
                                dataField: 'kontrakNominal',
                                label: {
                                    text: 'Nilai Kontrak'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                            {
                                dataField: 'terminPembayaran',
                                label: {
                                    text: 'Termin Pembayaran'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                }
                            },
                            {
                                dataField: 'dpAmount',
                                label: {
                                    text: 'Downpayment (%)'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                },
                            },
                            {
                                dataField: 'kontrakGaransiBulan',
                                label: {
                                    text: 'Masa Garansi'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                },
                            },
                            {
                                dataField: 'taxIncluded',
                                label: {
                                    text: 'Pajak'
                                },
                                editorType: 'dxCheckBox',
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'pelaksanaan-proyek',
                        caption: 'Pelaksanaan Proyek',
                        items: [
                            {
                                dataField: 'kontrakStartDate',
                                label: {
                                    text: 'Tanggal Mulai Pelaksanaan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'kontrakEndDate',
                                label: {
                                    text: 'Tanggal Akhir Pelaksanaan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        name: 'informasi-pelaksanaan',
                        caption: 'Informasi Pelaksanaan',
                        items: [
                            {
                                dataField: 'kodeSupplier',
                                label: {
                                    text: 'Kode Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'namaSupplier',
                                label: {
                                    text: 'Nama Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'alamat',
                                label: {
                                    text: 'Alamat Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'noTelp',
                                label: {
                                    text: 'No Telp Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'fax',
                                label: {
                                    text: 'No Fax Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'email',
                                label: {
                                    text: 'Email Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'informasi-proyek',
                        caption: 'Informasi Proyek',
                        items: [
                            {
                                dataField: 'projectType',
                                label: {
                                    text: 'Jenis Proyek'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: "1",
                                            value: 'Pembangunan',
                                        },
                                        {
                                            id: "2",
                                            value: 'Renovasi',
                                        },
                                        {
                                            id: "3",
                                            value: 'Pengadaan',
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'projectCode',
                                label: {
                                    text: 'Kode Proyek'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'projectName',
                                label: {
                                    text: 'Nama Proyek'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'informasi-aset',
                        caption: 'Informasi Aset',
                        items: [
                            {
                                dataField: 'groupAset',
                                label: {
                                    text: 'Group Aset'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'assetCategoryId',
                                label: {
                                    text: 'Jenis Aset'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: asetKategoriSdm(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'categoryName',
                                    searchEnabled: true,
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'assetCategorySubId',
                                label: {
                                    text: 'Tipe Aset'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: subAsetKategoriSdm(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'categorySubName',
                                    searchEnabled: true,
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'asetSpesifikasi',
                                label: {
                                    text: 'Spesifikasi'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                        ]
                    },
                ]
            },

        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster: data,
        })
    }

    uploadPenawaran = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataMaster = { ...this.state.DataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataMaster[`${fieldName}Name`] = files.name;
                DataMaster[fieldName] = formattedFileData.base64data;
                DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataMaster: DataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }
    uploadKontrak = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataMaster = { ...this.state.DataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataMaster[`${fieldName}Name`] = files.name;
                DataMaster[fieldName] = formattedFileData.base64data;
                DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataMaster: DataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }
    uploadProfile = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataMaster = { ...this.state.DataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataMaster[`${fieldName}Name`] = files.name;
                DataMaster[fieldName] = formattedFileData.base64data;
                DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataMaster: DataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }
    previewFile = async (contentType, base64) => {
        var src = imageSource(contentType, base64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    submitData = async() => {
        var data = this.state.DataMaster
        var submitData = {
            id: data.id,
            kontrakNo: data.kontrakNo,
            perjanjianNo: data.perjanjianNo,
            kontrakTanggal: formatDate(data.kontrakTanggal),
            supplierId: data.supplierId,
            pimpro: data.pimpro,
            kontrakNominal: data.kontrakNominal,
            terminPembayaran: data.terminPembayaran,
            dpAmount: data.dpAmount,
            kontrakGaransiBulan: data.kontrakGaransiBulan,
            taxIncluded: data.taxIncluded == null ? false : data.taxIncluded,
            kontrakStartDate: formatDate(data.kontrakStartDate),
            kontrakEndDate: formatDate(data.kontrakEndDate),
            filePenawaran: data.filePenawaran,
            filePerjanjianKontrak: data.filePerjanjianKontrak,
            fileProfileKontraktor: data.fileProfileKontraktor,
        }
        if(submitData){
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects', 'PUT', {values: submitData, key: data.id}, data)
                if(response){
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: 'Sukses Simpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Kontrak Proyek'}
                width={"150vh"}
                height={"80vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <Form
                        ref={this.formRef}
                        colCount={2}
                        id={'formPotongan'}
                        formData={this.state.DataMaster}
                        items={this.form}
                        labelLocation='left'
                    />
                    <Box direction={"row"} width={"100%"}>
                        <Item ratio={1}>
                            <FileUploader
                                accept={'image/png, image/jpeg, application/pdf'}
                                uploadMode={'useForm'}
                                onValueChanged={this.uploadPenawaran.bind(this)}
                                labelText="File Penawaran"
                                showFileList={false}
                                name={'filePenawaran'}
                                value={this.state.fileUploadReceipt}
                                disabled={false}
                                ref={this.fileUploderRef}
                            />
                            <div className="row">
                                <label className="col-4">Nama Berkas:</label>
                                <label className="col-8">{this.state.DataMaster.filePenawaranName}</label>
                            </div>
                            <button onClick={() => this.previewFile(this.state.DataMaster.filePenawaranContentType, this.state.DataMaster.filePenawaran)} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.filePenawaran ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Buka Berkas
                            </button>
                        </Item>
                        <Item ratio={1}>
                            <FileUploader
                                accept={'image/png, image/jpeg, application/pdf'}
                                uploadMode={'useForm'}
                                onValueChanged={this.uploadKontrak.bind(this)}
                                labelText="File Kontrak"
                                showFileList={false}
                                name={'filePerjanjianKontrak'}
                                value={this.state.fileUploadReceipt}
                                disabled={false}
                                ref={this.fileUploderRef}
                            />
                            <div className="row">
                                <label className="col-4">Nama Berkas:</label>
                                <label className="col-8">{this.state.DataMaster.filePerjanjianKontrakName}</label>
                            </div>
                            <button onClick={() => this.previewFile(this.state.DataMaster.filePerjanjianKontrakContentType, this.state.DataMaster.filePerjanjianKontrak)} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.filePerjanjianKontrak ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Buka Berkas
                            </button>
                        </Item>
                        <Item ratio={1}>
                            <FileUploader
                                accept={'image/png, image/jpeg, application/pdf'}
                                uploadMode={'useForm'}
                                onValueChanged={this.uploadProfile.bind(this)}
                                labelText="File Profile Pelaksana"
                                showFileList={false}
                                name={'fileProfileKontraktor'}
                                value={this.state.fileUploadReceipt}
                                disabled={false}
                                ref={this.fileUploderRef}
                            />
                            <div className="row">
                                <label className="col-4">Nama Berkas:</label>
                                <label className="col-8">{this.state.DataMaster.fileProfileKontraktorName}</label>
                            </div>
                            <button onClick={() => this.previewFile(this.state.DataMaster.fileProfileKontraktorContentType, this.state.DataMaster.fileProfileKontraktor)} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.fileProfileKontraktor ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Buka Berkas
                            </button>
                        </Item>
                    </Box>
                </ScrollView>
                <ModalPreviewPdf 
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default FormModalKontrakProyek