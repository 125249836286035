import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, product, managerInvestasis, instrumentSubCategoryDeposito } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';


class FormPerpanjanganDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataPerpanjangan:{},
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formPerpanjanganRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.DataPerpanjangan =[
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Pencairan',
                        items: [
                            {
                                dataField: 'produk',
                                label: {
                                    alignment:'left',
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                dataField: 'tglPencairan',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Pencairan'
                                },
                                editorType: 'dxDateBox'
                            },
                            {
                                dataField: 'efekDeposito',
                                label: {
                                    alignment:'left',
                                    text: 'Deposito'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                dataField: 'managerInvest',
                                label: {
                                    alignment:'left',
                                    text: 'Manager Investasi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                label: {
                                    alignment:'left',
                                    text: 'Informasi Deposito'
                                }
                            },
                            {
                                dataField: 'nominal',
                                label: {
                                    alignment:'left',
                                    text: 'Nominal (Rp)'
                                },
                            },
                            {
                                dataField: 'tglPenempatan',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Penempatan'
                                }
                            },
                            {
                                dataField: 'rate',
                                label: {
                                    alignment:'left',
                                    text: 'Rate (%)'
                                }
                            },
                            {
                                dataField: 'tenor',
                                label: {
                                    alignment:'left',
                                    text: 'Tenor'
                                }
                            },
                            {
                                dataField: 'tglJatuhTempo',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Jatuh Tempo'
                                }
                            },
                            {
                                dataField: 'bunga',
                                label: {
                                    alignment:'left',
                                    text: 'Bunga'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Perpanjangan',
                        items: [
                            {
                                dataField: 'tipePerpanjangan',
                                label: {
                                    alignment:'left',
                                    text: 'Tipe Perpanjangan'
                                },
                                editorType:'dxRadioGroup',
                                editorOptions:{
                                    layout: "horizontal",
                                    items: [
                                        'Pokok+Bunga',
                                        'Sebagian',
                                        'Pokok',
                                    ],
                                    value: 'Sebagian',
                                    layout: "horizontal"
                                }
                            },
                            {
                                dataField: 'nominalPerpanjangan',
                                label: {
                                    alignment:'left',
                                    text: 'Nominal Perpanjangan'
                                }
                            },
                            {
                                label: {
                                    alignment:'left',
                                    text: 'Bank',
                                }
                            },
                            {
                                dataField: 'rekeningPencairan',
                                label: {
                                    alignment:'left',
                                    text: 'Rekening Pencairan/Bunga'
                                }
                            },
                            {
                                dataField: 'note',
                                label: {
                                    alignment:'left',
                                    text: 'Catatan'
                                },
                                editorType:'dxTextArea',
                                editorOptions:{
                                    height: '130'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        console.log('submit')
    }
    retrieveData = async(data) => {    
        this.setState({
            dataPeserta: data,
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Perpanjangan Order Deposito'}
                minWidth={'70vh'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12' } style={{overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formPerpanjangan'}
                                        ref={this.formPerpanjanganRef}
                                        formData={this.state.dataPerpanjangan}
                                        items={this.DataPerpanjangan}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormPerpanjanganDeposito