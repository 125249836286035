import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import {Form,FileUploader,ScrollView} from 'devextreme-react'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";

import { pengkinianType,tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, SKType, currency, modaTransportasi } from 'dataSource/lookup';
import { download, formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class FormTiketPenugasan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {},
            DataDocument:{},
            uploadData:[]
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupRef = React.createRef()
        this.sppdId = 0

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
          ];
        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
          ];
        this.formTiketPenugasan = [
            {
                dataField: 'modaTransportasi',
                label: {
                    text: 'Moda',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: modaTransportasi(),
                    valueExpr: 'value',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'noTiket',
                label: {
                    text: 'No. Tiket'
                }
            },
            {
                dataField: 'namaArmada',
                label: {
                    text: 'Armada/Maskapai',
                },
            },
            {
                dataField: 'tanggalPerjalanan',
                label: {
                    text: 'Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }
            },
            {
                dataField:"jam",
                label: {
                    text: 'Jam'
                },
                editorType:"dxDateBox",
                editorOptions:{
                    type: "time",
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat: 'HH:mm',
                    pickerType:"rollers"
                }
            },
            {
                dataField: 'dari',
                label: {
                    text: 'Dari'
                }
            },
            {
                dataField: 'tujuan',
                label: {
                    text: 'Tujuan'
                }
            },
            {
                dataField: 'status',
                label: {
                    text: 'Status Perjalanan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id:'Berangkat',
                            value:'Berangkat',
                        },
                        {
                            id:'Pulang',
                            value:'Pulang',
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'hargaTiket',
                label: {
                    text: 'Harga Tiket'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan'
                }
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.sppdId = 0
        this.setState({
            popupVisible: false,
            DataMaster: {},
            DataDocument:{},
            uploadData:[]
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    getSppdId = (sppdId) => {
        this.sppdId = sppdId
    }

    submitData = async() => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.tanggalPerjalanan = formatDate(DataMaster.tanggalPerjalanan)
            DataMaster.jam = new Date(DataMaster.jam).toISOString()
            DataMaster.karyawanSppdId = this.sppdId

            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-tikets`,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    updateData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.tanggalPerjalanan = formatDate(DataMaster.tanggalPerjalanan)
            DataMaster.jam = new Date(DataMaster.jam).toISOString()
            DataMaster.karyawanSppdId = this.sppdId

            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-tikets`,
                "PUT",{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    retrieveData = (data) => { 
        data.jam = new Date(data.jam) 
        this.setState({
            DataMaster: data,
            DataDocument: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}name`] = files.name;
            DataDocument[`${fieldName}`] = formattedFileData.base64data;
            DataDocument[`${fieldName}ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };

    
          fr.readAsDataURL(files);
        }
    }

    previewFile = async (fileName) => {
        var src = imageSource(this.state.DataDocument[`${fileName}ContentType`], this.state.DataDocument[`${fileName}`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataDocument[`${fileName}ContentType`], this.state.DataDocument[`${fileName}`]), this.state.DataDocument[`${fileName}name`])
    }
    
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Form Tiket"}
                width={"55vw"}
                height={"55vh"}
                toolbarItems={this.props.type == 'edit' ? this.PopupToolbarItem2 : this.PopupToolbarItem}
                >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <Form
                            colCount={1}
                            id={'formTiketPenugasan'}
                            formData={this.state.DataMaster}
                            items={this.formTiketPenugasan}
                            labelLocation = 'left'
                        />
                        {/* Ijazah */}
                        <FileUploader
                                disabled={this.props.action == 'view' ? true : false}
                                accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText="Tiket File"
                                showFileList={false}
                                name={"dokumenTiketFile"}
                                value={this.state.uploadData}
                                ref={this.fileUploderRef}
                            />
                            <div className="row">
                                <label className="col-2">File Name:</label>
                                <label className="col-4">{ this.state.DataDocument.dokumenTiketFilename}</label>
                            </div>
                            <div className="col-md-4 text-right">
                                <button onClick={() => this.previewFile('dokumenTiketFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenTiketFilename ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('dokumenTiketFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenTiketFilename ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormTiketPenugasan