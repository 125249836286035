import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, hubunganKeluarga, months, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { ScrollView } from 'devextreme-react';

class TransaksiPembayaranMpGeneratePembayaranModal extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {},
            getUrl: 'view-pembayaran-mp-detils?pembayaranMpId.in=0'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: console.log('simpan')
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                        dataSource: product(this.props.store),
                        valueExpr: "id", // contains the same values as the "statusId" field provides
                        displayExpr: "productCode", // provides display values
                        searchEnabled: true,
                        deferRendering: false,
                        width: '100%',
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'bulan',
                        label: {
                            alignment: 'left',
                            text: 'Periode'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                        dataSource: months(),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "value", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'tahun',
                        label: {
                            alignment: 'left',
                            text: 'S/D'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions : {
                            step: 0
                        }    
                    }
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'paymentDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Bayar'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy'
                        }
                        
                    },
                ]
            }
            
        ]

        this.dataGridColumn = [
            {
               dataField: 'no',
               caption: 'No',
               cellRender: (e) => {
                   return e.rowIndex + 1
               }
            }, 
            {
               dataField: 'nikPeserta',
               caption: 'NIK'
            },
            {
               dataField: 'noCard',
               caption: 'Card No'
            },
            {
               dataField: 'namaPeserta',
               caption: 'Nama Pensiunan'
            },
            // {
            //    dataField: 'statusMeninggal',
            //    caption: 'Status Meninggal',
            // },
            {
               dataField: 'mpDetilNominal',
               caption: 'Nilai Mp',
               format: '#,##0.00',
               alignment: 'right'
            },
            {
               dataField: 'namaPenerima',
               caption: 'Nama Penerima',
               cellRender: (e) => {
                   var data = e.row.data
                   if(data.pensiunPenerimaTypeId === 1){
                        return data.namaPeserta
                    }else{
                        return data.fullNameKeluarga
                   }
               }
            },
            {
               dataField: 'relasiCode',
               caption: 'Hubungan Keluarga',
               lookup:{
                    dataSource: hubunganKeluarga(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
            {
               dataField: 'mpDetilRekeningNo',
               caption: 'Nomor Rekening'
            },
            {
               dataField: 'mpDetilRekeningName',
               caption: 'Nama Rekening'
            },
            {
               dataField: 'mpDetilRekeningBankId',
               caption: 'Bank',
               lookup:{
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName' 
                }
            },
        ]

    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

        // console.log(dataSubmit)
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data) => {
        var loadAPIMaster = `pembayaran-mps/${data.id}`
        var getMasterPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')

        this.setState({
            dataMaster: getMasterPembayaranMp,
            getUrl: `view-pembayaran-mp-detils?pembayaranMpId.in=${data.idPembayaran || data.id}`
        })
    }

    loadDataGrid = async(param) => {
        var pembayaranMpId = this.state.dataMaster.id
        var result = []
        if(pembayaranMpId){
            var loadAPIDetail = `pembayaran-mp-detils/getByMpMasterId/${pembayaranMpId}${param}`
            var getDetailPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetail, 'GET')
            result = getDetailPembayaranMp
        }
        return result
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Pembayaran Manfaat Pensiun Bulanan'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid" style={{overflowY: 'auto',height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                        <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='pembayaran-mp-detils'
                                updateAPI='pembayaran-mp-detils'
                                deleteAPI='pembayaran-mp-detils' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}
                                keyField = {'idPembayaranMpDetil'}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}
                                
                                remoteOperations={true}
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Pembayaran MP Bulanan Detail"}
                                allowExportSelectedData={true}
                                // selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Pembayaran MP Bulanan Detail'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter
                                height={'calc(65vh - 150px)'}
                                
                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default TransaksiPembayaranMpGeneratePembayaranModal