import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import EditModalPendaftaranPeserta from '../modal/kepesertaan/editPendaftaranPeserta';
import httpRequest from 'plugin/httprequest';
import { addURL } from 'redux/actions/url';

class PendaftaranPeserta extends Component {
    constructor(props){
        super(props)
        this.state = {
            filterData : {}
        }

        this.dataGridRef = React.createRef()
        this.editModalPendaftaranPesertaRef = React.createRef()

        this.field = {
            "activedDate": "2020-06-08",
            "birthDate": "2020-06-08",
            "birthPlace": "string",
            "branchId": 0,
            "cardId": 0,
            "domicileAddress1": "string",
            "domicileAddress2": "string",
            "domicileCityId": 0,
            "domicileCountryId": 0,
            "domicilePostalCode": 0,
            "domicileProvinceId": 0,
            "education": "SD",
            "email": "string",
            "fingerPrintCode": "string",
            "fullName": "string",
            "gradeId": 0,
            "identityAddress1": "string",
            "identityAddress2": "string",
            "identityCityId": 0,
            "identityCountryId": 0,
            "identityExpired": "2020-06-08",
            "identityNo": "string",
            "identityPostalCode": 0,
            "identityProvinceId": 0,
            "iuranPerusahaan": 0,
            "iuranPeserta": 0,
            "iuranTotal": 0,
            "lastUnitId": 0,
            "marriedId": 0,
            "masaKerja": 0,
            "mobileNo": "string",
            "mpRekBankId": 0,
            "mpRekBranch": "string",
            "mpRekName": "string",
            "mpRekNo": "string",
            "nik": "string",
            "numberFamily": 0,
            "phdp": 0,
            "phoneNo": "string",
            "positionId": 0,
            "salary": 0,
            "sex": "Laki_Laki",
            "skKerjaId": 0,
            "skKerjaSalaryId": 0,
            "skPensiunId": 0,
            "statusPeserta": "Aktif",
            "taxId": 0,
            "taxNo": "string",
            "terminated": "Pensiun",
            "unitId": 0,
            "workingDate": "2020-06-08"
        }
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Show Transaction',
                        onClick : (e) => {
                            this.showPendaftaranPeserta(e.row.data)
                        },
                    },
                ],
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'tanggalLahir',
                caption: 'Tanggal Lahir',
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
            },
            {
                dataField: 'phoneNo',
                caption: 'No.Telp',
            },
        ]

        this.filterItem = [
            {
                dataField: 'jenisPeserta',
                label: {
                    text: 'Jenis Peserta',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: {
                        store: {
                          type: "array",
                          data: [
                            { id: "1", value: "Aktif" },
                            { id: "2", value: "Non Aktif" },
                          ],
                          key: "id"
                        }
                      },
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                }
            },
            {
                dataField: 'date',
                label: {
                    text: 'Tanggal',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            // {
            //     itemType:"button",
            //     buttonOptions:{
            //         text:"Submit",
            //         type:"default",
            //         cssClass:"bg-danger",
            //         onClick:() => {
            //             this.submitData()
            //         },
            //     },
            //     horizontalAlignment:"left"
            // },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showPendaftaranPeserta = (data) => {
        this.editModalPendaftaranPesertaRef.current.show()
        this.editModalPendaftaranPesertaRef.current.retrieveData(data)
    }

    submitData = async(data) => {
        var dataPeserta = data

        try {
            var loadAPI = 'pesertas'
            if(dataPeserta.id){
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'PUT', {
                    values: dataPeserta,
                    key: dataPeserta.id
                }, dataPeserta)

                var text = 'Data Peserta berhasil diubah!'
            }else{
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                    values: data
                })

                var text = 'Data Peserta berhasil ditambah!'
            }

            if(response){
                let type = 'success'
                notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);

                this.dataGridRef.current.forceRefresh()
            }
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                onClick: (e) => {
                    this.showPendaftaranPeserta('new')
                },
            }
        });
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pendaftaran Peserta</h2>

                <Form
                    colCount={3}
                    id={'form'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                    scrollingEnabled={true}
                    ref={this.formRef}
                />

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='pesertas'
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Daftar Peserta"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pendaftaran Peserta'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <EditModalPendaftaranPeserta 
                    store = {this.props.store}
                    submitData = {this.submitData}
                    ref = {this.editModalPendaftaranPesertaRef}
                />
            </div>
        )
    }
}

export default PendaftaranPeserta
