import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { alert } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, pensiunType, product } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import ModalPengajuanPemberhentianPeserta from 'components/pages/modal/kepesertaan/pesertaDapen/pemberhentian/pengajuan/index';
import httpRequest from 'plugin/httprequest';
import { formatNumber, summaryValue } from 'plugin/helper';
import { confirmAlert } from 'react-confirm-alert'

class PengajuanPemberhentianPeserta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            dataMaster: {},
            getUrl: 'view-lookup-kepersertaan-products?akunStatusId.in=10'
        }

        this.dataGridRef = React.createRef()
        this.modalPengajuanPemberhentianRef = React.createRef()
        this.modalInsertPengajuanPesertaPemberhentianRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showPengajuanPesertaPemberhentian(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.kirim(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'nikPeserta',
                caption: 'NIK',
                alignment: "right",
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'tglPemberhentian',
                caption: 'Tanggal Berhenti',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'usia',
                caption: 'Usia Sekarang',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'productId',
                caption: 'Akun Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode" // provides display values
                }
            },
            {
                dataField: 'skPemberhentianNo',
                caption: 'No SK Henti'
            },
            {
                name: 'saldoIuran',
                alignment: 'center',
                caption: 'Saldo Iuran',
                columns: [
                    {
                        dataField: 'totalIuran',
                        caption: 'Iuran',
                    },
                    {
                        dataField: 'iuranTotal',
                        caption: 'Total Nominal',
                        // format: '#,##0.00',
                        cellRender : (e) => {
                            var data = e.row.data
                            var total = data.iuranManfaatLainPeserta + data.iuranManfaatLainPk + data.iuranNormalPeserta + data.iuranNormalPk + data.iuranSukarelaPeserta

                            return formatNumber(total)
                        }
                    },
                ]
            },
            {
                dataField: 'statusKeuanganId',
                caption: 'Status Keuangan'
            },
        ]

        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        if(data.value){
                            this.setState({
                                getUrl: `view-simple-kepesertaan-products?akunStatusId.in=10/${data.value}`
                            })
                        }else{
                            this.setState({
                                getUrl: `view-simple-kepesertaan-products?akunStatusId.in=10`
                            })
                        }
                        this.forceRefresh()
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                text: 'Registrasi',
                onClick: (e) => {
                    this.showInsertPengajuanPesertaPemberhentian()
                },
            }
        });
    }
    
    kirim = async (data) => {
        try {
            var kirimData = {
                kepersertaanProductId : data.id,
                statusKeuanganId : 2
            }
            var loadAPI = 'kirim-pemberhentian'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                values:kirimData
            })

            await alert('Berhasil mengirim pengajuan pemberhentian baru', 'Berhasil!')
            this.forceRefresh()
        } catch (e) {
            await alert(e, 'Gagal!')
            this.forceRefresh()
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    showInsertPengajuanPesertaPemberhentian = () => {
        this.modalInsertPengajuanPesertaPemberhentianRef.current.show()
    }
    showPengajuanPesertaPemberhentian = (data = null) => {
        this.modalPengajuanPemberhentianRef.current.show()
        this.modalPengajuanPemberhentianRef.current.retrieveData(data)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Registrasi Pemberhentian Peserta</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-simple-kepesertaan-products?akunStatusId.in=10'
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {true}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Pemberhentian Peserta"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Pemberhentian Peserta'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalPengajuanPemberhentianPeserta
                    ref={this.modalInsertPengajuanPesertaPemberhentianRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'add'}
                />
                <ModalPengajuanPemberhentianPeserta
                    ref={this.modalPengajuanPemberhentianRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'edit'}
                />
            </div>
        )
    }
}

export default PengajuanPemberhentianPeserta