import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { confirm, alert } from 'devextreme/ui/dialog';
import { entityType, currency } from 'dataSource/lookup'
import ModalCashSettlementVoucher from 'components/pages/modal/akutansi/cash-settlement/voucher'
import httpRequest from 'plugin/httprequest';
import { formatDate, formatDefaultDate, formatNumberAum } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';
import ModalRejectCashVoucher from 'components/pages/modal/akutansi/cash-settlement/rejectNotes';
import { reportFile } from 'plugin/reportRequest';
import EditDataSpesific from 'components/pages/modal/akutansi/cash-settlement/editDataSpesific';

class CashSettlementVoucher extends Component {
    constructor(props) {
        super(props)

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.labaRugiAkunId = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'LB-AKUNID') : null
        this.pembulatanAkunId = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'P-AKUNID') : null
        this.pembulatanCoaAkunId = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'COAPB-KP') : null
        this.username = props.store.getState().sessionUser

        this.state = {
            formFilter: {
                startDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                endDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
            }
        }

        this.dataGridRef = React.createRef()
        this.modalCashSettlementVoucherRef = React.createRef()
        this.modalNotesRef = React.createRef()

        this.editDataSpesificRef = React.createRef()

        this.selectedRowDatas = []

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Tanggal Voucher",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.dataGridRef.current.forceRefresh(true)
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Voucher',
                        hint: 'Lihat Voucher',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.modalCashSettlementVoucherRef.current.show()
                            this.modalCashSettlementVoucherRef.current.retrieveData(e.row.data)
                        }
                    },
                    {
                        text: 'Posting',
                        hint: 'Posting',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data.status === 'C') {
                                notify({ message: 'Voucher Telah Dibatalkan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            if (e.row.data.status === 'F') {
                                notify({ message: 'Verifikasi voucher gagal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            if (e.row.data.status === 'R') {
                                notify({ message: 'Voucher Ditolak', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            if (e.row.data.status === 'P') {
                                notify({ message: 'Voucher Telah Diposting', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            let result = await confirm("Apakah anda yakin untuk memposting voucher ini?", "Konfirmasi");
                            if (result) {
                                this.posting(e.row.data, false)
                            }
                        }
                    },
                    {
                        text: 'Batalkan',
                        hint: 'Batalkan',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data.status === 'C') {
                                notify({ message: 'Voucher Telah Dibatalkan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            if (e.row.data.status === 'F') {
                                notify({ message: 'Verifikasi voucher gagal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            if (e.row.data.status === 'R') {
                                notify({ message: 'Voucher Ditolak', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            if (e.row.data.status === 'P') {
                                notify({ message: 'Voucher Telah Diposting', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            this.modalNotesRef.current.show(e.row.data);
                            // let result = await confirm("Apakah anda yakin untuk membatalkan voucher ini?", "Konfirmasi");
                            // if (result) {
                            //     this.cancel(e.row.data)
                            // }

                        }
                    },
                    {
                        text: 'Edit (Bank, Deskripsi, Tanggal Jurnal)',
                        hint: 'Edit (Bank, Deskripsi, Tanggal Jurnal)',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data) {
                                this.editDataSpesific(e.row.data)
                            }
                        }
                    },
                    {
                        text: 'Cetak Voucher',
                        hint: 'Cetak Voucher',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data) {
                                this.printVoucher(e.row.data)
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'F',
                            value: 'Verification Failed'
                        },
                        {
                            id: 'R',
                            value: 'Rejected'
                        },
                        {
                            id: 'O',
                            value: 'Open'
                        },
                        {
                            id: 'C',
                            value: 'Cancel'
                        },
                        {
                            id: 'P',
                            value: 'Posted'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'voucherNo',
                caption: 'No Voucher',
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'entityName',
                caption: 'Entitas'
            },
            {
                dataField: 'entityType',
                caption: 'Tipe Entitas',
                lookup: {
                    dataSource: entityType(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            },
            {
                dataField: 'type',
                caption: 'Tipe',
                lookup: {
                    dataSource: [
                        {
                            id: 'P',
                            value: 'Payment'
                        },
                        {
                            id: 'R',
                            value: 'Receive'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'entryDate',
                caption: 'Tanggal Entri',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'valueDate',
                caption: 'Tanggal Jurnal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'settleWith',
                caption: 'Pembayaran Dengan',
                lookup: {
                    dataSource: [
                        {
                            id: 'C',
                            value: 'Uang Tunai'
                        },
                        {
                            id: 'B',
                            value: 'Bank'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'amount',
                caption: 'Total Pembayaran',
                format: '#,##0.00; #,##0.00',
                alignment: 'right'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    editDataSpesific = async (data) => {
        try {
            let status = [
                {
                    id: 'F',
                    value: 'Verification Failed'
                },
                {
                    id: 'R',
                    value: 'Rejected'
                },
                {
                    id: 'C',
                    value: 'Cancel'
                },
                {
                    id: 'P',
                    value: 'Posted'
                },
            ]

            for (let statusDetail of status) {
                if (statusDetail.id === data.status) return notify({ message: `Data dengan status ${statusDetail.value} tidak bisa diedit`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }

            this.editDataSpesificRef.current.show()
            this.editDataSpesificRef.current.retrieveData(data)
        } catch (e) {
            console.log(e);
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var status = this.state.formFilter.status
        // if(this.state.formFilter.status.length > 0){
        //     status = this.state.formFilter.status.join(',')
        // }else{
        //     status = "O,C,P"
        // }
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/getByStatusAndValueDateBetween/O/${formatDate(this.state.formFilter.startDate)}/${formatDate(this.state.formFilter.endDate)}`)

        return data
    }

    posting = async (data, multiple = false) => {
        var messagePosting
        try {
            this.props.store.dispatch(showLoading(true))
            var dataVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/${data.cashVoucherId}`)
            var dataVoucherDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucer-detils/getByCashVoucherId/${data.cashVoucherId}`)

            var successCount = 0, failCount = 0, reffType = ''
            for (const voucherDetil of dataVoucherDetil) {
                var dataArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps/${voucherDetil.arapId}`)

                if (dataArap) {
                    reffType = dataArap.reffType
                    var dataSettle = {
                        "amount": Math.abs(voucherDetil.amountPaid),
                        "currencyId": dataArap.currencyId,
                        "defaultBank": dataVoucher.clientBankNo,
                        "clientBankNo": dataVoucher.clientBankNo,
                        "defaultBankId": dataVoucher.clientBankId,
                        "dueDate": dataArap.dueDate,
                        "entitiId": dataArap.entitiId,
                        "outstandingAmount": Math.abs(dataArap.amount),
                        "reffNo": dataArap.reffNo,
                        "reffType": dataArap.reffType,
                        "rekBankId": dataVoucher.companyBankId,
                        "tradeDate": dataArap.tradeDate,
                        "transferFee": dataVoucher.transferFee,
                        "valueDate": dataVoucher.valueDate,
                    }

                    var responseCashSettlement
                    var responseJurnal
                    switch (dataArap.reffType) {
                        case 'IN': const rekBankInvest = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-banks/${dataSettle.rekBankId}`)
                            responseCashSettlement = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transactions/cash-settlement', 'POST', { values: dataSettle })
                            responseJurnal = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `bank-transaction/set-jurnal-penyelesaian/${responseCashSettlement.reffId}/${rekBankInvest.rekNo}/${dataVoucher.coaPphAkunId || 0}/${dataVoucher.pphNominal || 0}/${dataVoucher.pembulatanNominal || 0}/${this.pembulatanAkunId.intValue || 0}/${this.labaRugiAkunId.intValue || 0}`, 'POST', { values: {} })
                            break;
                        case 'HR': const cashAmountHR = dataVoucher.settleWith === 'B' ? 0 : Math.abs(voucherDetil.amountPaid)
                            const rekeningAmountHR = dataVoucher.settleWith === 'B' ? Math.abs(voucherDetil.amountPaid) : 0
                            let noRekHR = null
                            if (dataVoucher.settleWith === 'B') {
                                const rekBankHR = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `rek-banks/${dataVoucher.companyBankId}`)
                                if (rekBankHR) {
                                    noRekHR = rekBankHR.rekNo
                                }
                            }
                            // responseCashSettlement = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'settle-arap', 'POST', {
                            //     values: {
                            //         "biayaAmount": dataVoucher.transferFee || 0,
                            //         "cashAmount": cashAmountHR,
                            //         "currentRate": 1,
                            //         "reffNo": dataArap.reffNo,
                            //         "rekeningAmount": rekeningAmountHR,
                            //         "rekeningNo": noRekHR,
                            //         "transactionDate": dataArap.tradeDate,
                            //         "valueDate": dataVoucher.valueDate
                            //     }
                            // })
                            responseCashSettlement = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'penyelesaian-arap-sdm', 'POST', {
                                values: {
                                    "biayaAmount": dataVoucher.transferFee || 0,
                                    "cashAmount": cashAmountHR,
                                    "currentRate": 1,
                                    "reffNo": dataArap.reffNo,
                                    "rekeningAmount": rekeningAmountHR,
                                    "rekeningNo": noRekHR !== null ? noRekHR : '0',
                                    "transactionDate": dataArap.tradeDate,
                                    "valueDate": dataVoucher.valueDate
                                }
                            })
                            responseJurnal = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store,
                                `pembayaran-umum-aktifs/set-jurnal-pembayaran-umum/${dataArap.reffNo}/${dataVoucher.valueDate}/${noRekHR}/${rekeningAmountHR}/0/0/${dataVoucher.transferFee || 0}/1/${dataVoucher.coaPphAkunId || 0}/${dataVoucher.pphNominal || 0}/${dataVoucher.pembulatanNominal || 0}`,
                                'POST', {
                                values: {}
                            })

                            break;
                        case 'KP': const cashAmountKP = dataVoucher.settleWith === 'B' ? 0 : Math.abs(voucherDetil.amountPaid)
                            const rekeningAmountKP = dataVoucher.settleWith === 'B' ? Math.abs(voucherDetil.amountPaid) : 0

                            let noRekKP = null
                            if (dataVoucher.settleWith === 'B') {
                                const rekBankKP = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `rekbanks/${dataVoucher.companyBankId}`)
                                if (rekBankKP) {
                                    noRekKP = rekBankKP.rekNo
                                }
                            }

                            responseCashSettlement = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'penyelesaian-mp', 'POST', {
                                values: {
                                    "akunPembulatanId": this.pembulatanCoaAkunId.intValue || 0,
                                    "biayaAmount": dataVoucher.transferFee || 0,
                                    "cashAmount": cashAmountKP,
                                    "currentRate": 1,
                                    "description": dataVoucher.description,
                                    "nominalPembulatan": dataVoucher.pembulatanNominal || 0,
                                    "reffNo": dataArap.reffNo,
                                    "rekeningAmount": rekeningAmountKP,
                                    "rekeningNo": noRekKP,
                                    "transactionDate": dataArap.tradeDate,
                                    "valueDate": dataVoucher.valueDate,
                                }
                            })
                            break;
                        default: break
                    }

                    if (responseCashSettlement) {
                        successCount++
                    } else {
                        failCount++
                    }
                }
            }

            if (failCount === 0) {
                var dataBankInstruction = {
                    "amount": Math.abs(dataVoucher.amount),
                    "charges": dataVoucher.transferFee,
                    "clientBank": null,//"DANA PENSIUN PEGADAIAN",
                    "clientBankId": dataVoucher.clientBankId,
                    "clientBankNo": dataVoucher.clientBankNo,
                    "companyBankId": dataVoucher.companyBankId,
                    "companyBankReff": reffType,
                    "currencyId": dataVoucher.currencyId,
                    "description": dataVoucher.description,
                    "entryDate": dataVoucher.entryDate,
                    "generated": false,
                    "inOut": dataVoucher.type === 'P' ? 'O' : 'I',
                    "instructionNo": `BI-${dataVoucher.voucherNo}`,
                    "netAmount": dataVoucher.type === 'P' ? Math.abs(dataVoucher.amount) + dataVoucher.transferFee : Math.abs(dataVoucher.amount) - dataVoucher.transferFee,
                    "reffNo": dataVoucher.voucherNo,
                    "reffType": "CV"
                }
                var responseCreateBankInstruction = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'bank-instructions', 'POST', { values: dataBankInstruction })

                const responseInsertVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'cash-voucers', 'PUT', {
                    values: {
                        status: "P"
                    }
                }, dataVoucher)
            }

            this.props.store.dispatch(showLoading(false))
            this.forceRefresh()
            if (multiple) {
                messagePosting = `Voucher ${data.voucherNo} Berhasil Diposting`
                notify({ message: 'Sukses Melakukan Posting Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } else {
                notify({ message: 'Sukses Melakukan Posting Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                await alert(`Voucher ${data.voucherNo} Berhasil Diposting`, 'Notification');
            }
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            console.log(e)
            if (multiple) {
                messagePosting = `Voucher ${data.voucherNo} Gagal Diposting`
                notify({ message: 'Gagal Melakukan Posting Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            } else {
                notify({ message: 'Gagal Melakukan Posting Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                await alert(`Voucher ${data.voucherNo} Gagal Diposting`, 'Notification');
            }
        }

        return messagePosting
    }

    cancel = async (data) => {
        try {
            this.props.store.dispatch(showLoading(true))
            var dataVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/${data.cashVoucherId}`)
            var dataVoucherDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucer-detils/getByCashVoucherId/${data.cashVoucherId}`)

            for (const voucherDetil of dataVoucherDetil) {
                var dataArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps/${voucherDetil.arapId}`, 'GET')
                await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps`, 'PUT', {
                    values: {
                        outstandingAmount: voucherDetil.outstandingAmount + voucherDetil.amountPaid
                    }
                }, {
                    amount: voucherDetil.amount,
                    currencyId: voucherDetil.currencyId,
                    defaultBank: voucherDetil.defaultBank,
                    dueDate: voucherDetil.dueDate,
                    entitiId: voucherDetil.entitiId,
                    id: voucherDetil.arapId,
                    outstandingAmount: voucherDetil,
                    reffNo: voucherDetil.reffNo,
                    reffType: voucherDetil.reffType,
                    description: dataArap.description,
                    tradeDate: voucherDetil.tradeDate
                })
            }

            const responseInsertVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'cash-voucers', 'PUT', {
                values: {
                    notes: data.rejectNotes,
                    rejectedBy: this.username,
                    status: "C"
                }
            }, dataVoucher)
            this.props.store.dispatch(showLoading(false))
            this.modalNotesRef.current.hide()
            this.forceRefresh()
            notify({ message: 'Sukses Melakukan Pembatalan Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            notify({ message: 'Gagal Melakukan Pembatalan Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            console.log(e)
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Posting Voucher Terpilih',
                    onClick: async (e) => {
                        if (this.selectedRowDatas.length === 0) {
                            alert('Tidak ada voucher yang dipilih!', 'Error');

                            return null
                        }

                        var result = await confirm(`Apakah anda yakin untuk memposting ${this.selectedRowDatas.length} voucher yang terpilih?`, 'Confirmation')

                        if (!result) {
                            return null
                        }

                        var results = []
                        for (const selectedData of this.selectedRowDatas) {
                            if (selectedData.status === 'C') {
                                results.push('Voucher Telah Dibatalkan')
                                continue
                            }
                            if (selectedData.status === 'P') {
                                results.push('Voucher Telah Diposting')
                                continue
                            }
                            if (selectedData.status === 'F') {
                                results.push('Verifikasi Voucher Gagal')
                                continue
                            }
                            if (selectedData.status === 'R') {
                                results.push('Voucher Ditolak')
                                continue
                            }

                            const result = await this.posting(selectedData, true)
                            results.push(result)
                        }

                        await alert(results.join('\n'), 'Notification');

                        this.selectedRowDatas = []
                    },
                }
            },
        )
    }

    printVoucher = async (data) => {
        try {
            this.props.store.dispatch(showLoading(true))
            let getCashVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `print/cashvoucher/${data.cashVoucherId}`)
            let getCashVoucherDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `print/cashvoucherdetil/${data.cashVoucherId}`)
            let printData = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `print/${getCashVoucher[0].bank_id}/${getCashVoucher[0].created_by}/${getCashVoucher[0].verifikasi_by}/${getCashVoucher[0].approve_by}`)

            var maapingGetCashVoucherDetil = []
            for (let result of getCashVoucherDetil) {
                result.amount_paid = formatNumberAum(result.amount_paid)
                maapingGetCashVoucherDetil.push(result)
            }

            let dataReport = {
                company_name: printData[0].company_name,
                address: printData[0].address,
                telp_number: printData[0].telp_number,
                fax_number: printData[0].fax_number,
                email: printData[0].email,
                value_date: formatDefaultDate(getCashVoucher[0].value_date),
                voucher_no: getCashVoucher[0].voucher_no,
                entity_name: getCashVoucher[0].entity_name,
                bank_name: printData[0].bank_name,
                rek_no: getCashVoucher[0].rek_no,
                rek_no: getCashVoucher[0].rek_no,
                dataTransaksi: maapingGetCashVoucherDetil,
                pph_coa_code: getCashVoucher[0].pph_coa_code,
                pph_coa_name: getCashVoucher[0].pph_coa_name,
                pph_nominal: formatNumberAum(getCashVoucher[0].pph_nominal),
                transfer_fee: formatNumberAum(getCashVoucher[0].transfer_fee),
                pembulatan_nominal: formatNumberAum(getCashVoucher[0].pembulatan_nominal),
                amount: formatNumberAum(getCashVoucher[0].amount),
                description: getCashVoucher[0].description,
                creator_fullname: printData[0].creator_fullname,
                verifikator_fullname: printData[0].verifikator_fullname,
                approver_fullname: printData[0].approver_fullname,
                created_by: getCashVoucher[0].created_by,
                verifikasi_by: getCashVoucher[0].verifikasi_by,
                approve_by: getCashVoucher[0].approve_by,
            }

            var data = {
                template: {
                    'shortid': 'rkghNJoEB3',
                    'recipe': 'chrome-pdf'
                },
                data: dataReport,
                options: {
                    reportName: "Voucher" + dataReport.voucher_no
                }
            }

            await reportFile(data, true, `Voucher-${dataReport.voucher_no}.pdf`)

            this.props.store.dispatch(showLoading(false))

        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    onSelectionChanged = (e) => {
        this.selectedRowDatas = e.selectedRowsData
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Cash Settlement Voucher</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='cash-voucers'
                    insertAPI='cash-voucers'
                    updateAPI='cash-voucers'
                    deleteAPI='cash-voucers'

                    keyField="cashVoucherId"

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    ArraySourceData={this.loadData}
                    useArraySource={true}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'amount'
                        ]
                    }

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Cash Settlement Voucher"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}
                    grouping={false}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Cash Settlement Voucher'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 250px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    summaryTotalItem={this.sumDebit}

                    onToolbarPreparing={this.onToolbarPreparing}

                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalCashSettlementVoucher ref={this.modalCashSettlementVoucherRef} store={this.props.store} forceRefresh={this.forceRefresh} cancel={this.cancel} posting={this.posting} />
                <ModalRejectCashVoucher ref={this.modalNotesRef} store={this.props.store} forceRefresh={this.forceRefresh} cancel={this.cancel} />
                <EditDataSpesific ref={this.editDataSpesificRef} store={this.props.store} forceRefresh={this.forceRefresh} />
            </div>
        )
    }
}

export default CashSettlementVoucher