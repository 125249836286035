import React, { Component } from 'react'
import { solvaBilitas, tipeSolvabilitas } from 'dataSource/lookup'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import ArrayStore from 'devextreme/data/array_store';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import { findLastDayOfMonth, formatDate } from 'plugin/helper';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog'

class ModalGenerateNka extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedSolvabilitasId: 2,
            formData: {
                nkId: null
            },
            // solvabilitasDataSource: []
        }

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Generate',
                    onClick: async () => {
                        let result = await confirm("Apakah anda yakin melakukan proses ini?", "Konfirmasi");

                        if (result) {
                            this.generateData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.formData = [
            {
                dataField:"periode",
                label:{
                    text:"Periode"
                },
                editorType:"dxDateBox",
                editorOptions:{
                    displayFormat: 'MMM yyyy',
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    calendarOptions: {
                        zoomLevel: 'year',
                        maxZoomLevel: 'year',
                        minZoomLevel: 'year',
                    },
                }
            }
        ]

        // this.solvaBilitasData = []
        // this.dataGridRef = React.createRef();
        this.formRef = React.createRef();
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            formData:{}
        })
    }

    loadData = () => {
        return this.solvaBilitasData
    }

    generateData = async () => {
        try {
            let data = this.state.formData;
            let dataPeriode = this.state.formData.periode;
            let periode = findLastDayOfMonth(new Date(dataPeriode).getFullYear(), new Date(dataPeriode).getMonth());
            for (let value of data) {
                let dataValue = {
                    nkaId: value.id,
                    bulan: periode.getMonth() + 1
                }
                await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/set-jurnal-nka`, 'POST', {
                    values: dataValue
                })
            }
            notify({ message: 'Generate Jurnal NKA Berhasil', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);

            this.hide();
            this.props.forceRefresh()
        } catch (error) {
            console.log(error);
        }
    }

    retrieveData = (data) => {
        this.setState({
            formData: data
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Pilih Bulan'}
                width={400}
                height={150}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        formData={this.state.formData}
                        items={this.formData}
                        cssClass="mt-3"
                        labelLocation='left'
                        // readOnly={this.readOnly}
                    />
            </Popup>
        )
    }

}

export default ModalGenerateNka