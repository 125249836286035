import React, { Component } from 'react'
import Form from "devextreme-react/form";
import { Popup } from 'devextreme-react/popup'
import { akunHR, akunHRbyCategori, coa, coaGroup, users } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import { kasUmum, programSdm } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import { formatDate } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';

class EditKasUmum extends Component {
    constructor(props){
        super(props);

        this.state = {
            dataMaster: {},
            popUpVisible: false
        }

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.formRef = React.createRef();
        this.popupVisibleRef = React.createRef();
        this.currentData = [];
        this.id = 0;

        this.popupToolBarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'simpan',
                    onClick: this.updateData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ];

        this.items = [
            {
                dataField: "kodeKas",
                caption: "Kode Kas Umum"
            },
            {
                dataField: "namaKas",
                caption: "Nama Kas Umum"
            },
            {
                dataField: "akunId",
                caption: "Akun",
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: {
                        store: akunHRbyCategori(this.props.store),
                        paginate: true,
                        pageSize: 10
                    },
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'maksimalPembayaran',
                caption: 'Maksimal Pembayaran',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",

                }
            },
            {
                dataField: 'minimalSaldo',
                caption: 'Minimal Saldo',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",

                }
            },
            {
                dataField: 'maksimalSaldo',
                caption: 'Maksimal Saldo',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",

                }
            },
            {
                dataField: 'saldo',
                caption: 'Saldo',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    readOnly: true,

                }
            },
            {
                dataField: 'totalPenerimaan',
                caption: 'Total Penerimaan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    readOnly: true,

                }
            },
            {
                dataField: 'totalPengeluaran',
                caption: 'Total Pengeluaran',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    readOnly: true,

                }
            },
            {
                dataField: "otoritasUser",
                caption: "Otoritas User",
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: users(this.props.store),
                    valueExpr: 'login', // contains the same values as the "statusId" field provides
                    displayExpr: 'login',
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
        ]
    }

    show(){
        this.setState({
            popUpVisible: true
        })
    }

    hide(){
        this.setState({
            popUpVisible: false,
            dataMaster: {}
        })

        this.props.forceRefresh()
    }

    updateData = async () => {
        try {
            let dataMaster = {...this.state.dataMaster};

            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'kas-umums', 'PUT', {
                values: dataMaster,
                key: dataMaster.id
            }, dataMaster);

            if (response){
                this.hide();
                this.props.forceRefresh();
                notify({ message: "Sukses Update Data Kas Umum", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        } catch(error) {
            console.log(error);
        }
    }

    retrieveData = (data) => {
        this.currentData = JSON.parse(JSON.stringify(data));
        this.setState({
            dataMaster: data
        })
        this.id = data.id
    }

    render(){
        return(
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popUpVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    showTitle={true}
                    title={'Edit Data Kas Umum'}
                    width={'500'}
                    height={'500'}
                    toolbarItems={this.popupToolBarItem}
                >
                <Form
                    ref={this.formRef}
                    colCount={1}
                    formData={this.state.dataMaster}
                    items={this.items}
                    labelLocation={'left'}
                />
                </Popup>
            </React.Fragment>
        )
    }
}

export default EditKasUmum;