import React, { Component } from 'react'
import { Form } from "devextreme-react";
import uuidv4  from 'uuid/v4'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import {formatDate,  formatDefaultDate, formatDefaultFullDate, formatDefaultMonthYear, getSystemDate} from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from "redux/actions/loading";
import { product, programs } from 'dataSource/lookup';

class AnggaranTahunan extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {
        tahun : null,
        programId: null
      }
    }

    this.dataGridRef = React.createRef()
    // this.modalDetailProsesIuranRef = React.createRef()
    // this.modalGenerateProsesIuranRef = React.createRef()

    this.filterItem = [
        {
            dataField: 'tahun',
            label:{
                alignment:"left",
                text:"Tahun Anggaran",
                location: "left"
            },
            editorType: 'dxNumberBox',
        },
        {
            dataField: "programId",
            label: {
                text: "Program",
                alignment: "left",
                location: "left"
            },
            editorType: "dxSelectBox",
            editorOptions: {
            dataSource: programs(this.props.store),
            displayExpr: 'program',
            valueExpr: 'id',
            searchEnabled: true,
            deferRendering: false,
            }
        },
        {
            itemType: "button",
            buttonOptions: {
            text: "Filter",
            // type:"default",
            elementAttr: { class: "bg-dapen-default" },
            onClick: () => {
              this.dataGridRef.current.forceRefresh(true)
            },
            },
            horizontalAlignment: "left"
        },
    ]

    this.columns = [
        {
            dataField: 'anggaranName',
            caption: 'Uraian',
            cellRender: (e) => {
              var data = e.row.data
              if(data.anggaranCode){
                return <span style={{paddingLeft: `1rem`}}>{e.text}</span>
              }else{
                return <span className='font-weight-bold'>{e.text}</span>
              }
            }
        },
        {
          dataField: 'anggaranCode',
          caption: 'Uraian',
        },
        {
            dataField: 'realisasiTahunSebelum',
            caption: 'Realisasi Tahun Sebelum',
            alignment: 'right',
            dataType: 'number',
            format: '#,##0.00',
        },
        {
            dataField: 'anggaranTahunBerjalan',
            caption: 'Anggaran Tahun Berjalan',
            alignment: 'right',
            dataType: 'number',
            format: '#,##0.00',
        },
        {
            dataField: 'realisasiTahunBerjalan',
            caption: 'Realisasi Tahun Berjalan',
            alignment: 'right',
            dataType: 'number',
            format: '#,##0.00',
        },
        {
            dataField: 'selisih',
            caption: 'Selisih',
            alignment: 'center',
            columns : [
                {
                    dataField: 'nominalSelisih',
                    caption: 'NominalSelisih',
                    alignment: 'right',
                    dataType: 'number',
                    format: '#,##0.00',
                },
                {
                    dataField: 'persenSelisih',
                    caption: '(%)',
                    alignment: 'right',
                    dataType: 'number',
                    format: '#,##0.00 %',
                },
            ] 
        },
    ]

  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  loadData = async () => {
    if(!this.state.formFilter.tahun || !this.state.formFilter.programId){
      return []
    }
    var systemDate = this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE')
    systemDate = new Date(systemDate.dateValue)
    var monthYear = ''

    var dataAnggaran = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggarans`)

    dataAnggaran = dataAnggaran.find(value => value.tahun === this.state.formFilter.tahun)

    var dataAnggaranDetails = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-details/getByAnggaranId/${dataAnggaran.id}`)

    dataAnggaranDetails = dataAnggaranDetails.filter(value => value.status === '4')

    var dataAnggaranItems = []
    for(const dataAnggaranDetail of dataAnggaranDetails){
      const responseDataAnggaranItem = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-items/${dataAnggaranDetail.anggaranItemId}`)

      dataAnggaranItems.push(responseDataAnggaranItem)
    }
    
    var dataAnggaranSubTypes = []
    for(const dataAnggaranItem of dataAnggaranItems){
      const responseAnggaranSubType = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-sub-types/${dataAnggaranItem.anggaranSubTypeId}`)

      dataAnggaranSubTypes.push(responseAnggaranSubType)
    }
    console.log(dataAnggaranSubTypes)

    var products = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store,`products`)
    products = products.filter(value => value.programId === this.state.formFilter.programId)

    var productFiltered = products.map(value => value.id)

    var coaBalance = []
    var coaBalancePrev = []
    for(const product of products){
      if(this.state.formFilter.tahun === systemDate.getFullYear()){
        const responseCoaBalance = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-positions/getByProdukId/${product.id}`)
        const responseCoaBalancePrev = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-position-dailies/getByProdukIdAndValueDate/${product.id}/${this.state.formFilter.tahun-1}-12-31`)
        monthYear = formatDefaultMonthYear(systemDate)
        
        coaBalance = [...coaBalance,...responseCoaBalance]
        coaBalancePrev = [...coaBalancePrev,...responseCoaBalancePrev]
      }else{
        const responseCoaBalance = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-position-dailies/getByProdukIdAndValueDate/${product.id}/${this.state.formFilter.tahun}-12-31`)
        const responseCoaBalancePrev = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-position-dailies/getByProdukIdAndValueDate/${product.id}/${this.state.formFilter.tahun-1}-12-31`)
        monthYear = formatDefaultMonthYear(`${this.state.formFilter.tahun}-12-31`)

        coaBalance = [...coaBalance,...responseCoaBalance]
        coaBalancePrev = [...coaBalancePrev,...responseCoaBalancePrev]
      }
    }
    
    var result = []
    var id = 0

    for(const dataAnggaranSubType of dataAnggaranSubTypes){
      result.push({
        id : id,
        anggaranName: dataAnggaranSubType.anggaranSubName,
        anggaranCode: null,
        realisasiTahunSebelum: null,
        anggaranTahunBerjalan: null,
        realisasiTahunBerjalan: null,
        nominalSelisih: null,
        persenSelisih: null
      })

      var dataAnggaranItemFiltered = dataAnggaranItems.filter(value => value.anggaranSubTypeId === dataAnggaranSubType.id)
      for(const dataAnggaranItem of dataAnggaranItemFiltered){
        var realisasiTahunSebelum = 0,
            realisasiTahunBerjalan = 0

        var responseAnggaranItemCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-item-coas/getByAnggaranItemId/${dataAnggaranItem.id}`)

        var coaFiltered = responseAnggaranItemCoa.map(value => value.coaId)

        var coaBalanceFiltered = coaBalance.filter(value => coaFiltered.includes(value.coaId) && productFiltered.includes(value.produkId))
        var coaBalancePrevFiltered = coaBalancePrev.filter(value => coaFiltered.includes(value.coaId) && productFiltered.includes(value.produkId))

        var dataAnggaranDetailFiltered = dataAnggaranDetails.find(value => value.anggaranItemId === dataAnggaranItem.id)

        console.log(coaBalanceFiltered,coaBalancePrevFiltered)

        realisasiTahunBerjalan = coaBalanceFiltered.map(value => value.balanceAmount).reduce((a,b) => a + b,0)
        realisasiTahunSebelum = coaBalancePrevFiltered.map(value => value.balanceAmount).reduce((a,b) => a + b,0)

        id++
        result.push({
          id : id,
          anggaranName: dataAnggaranItem.anggaranName,
          anggaranCode: dataAnggaranItem.anggaranCode,
          realisasiTahunSebelum: realisasiTahunSebelum,
          anggaranTahunBerjalan: dataAnggaranDetailFiltered.nominalEfektif,
          realisasiTahunBerjalan: realisasiTahunBerjalan,
          nominalSelisih: dataAnggaranDetailFiltered.nominalEfektif - realisasiTahunBerjalan,
          persenSelisih: (dataAnggaranDetailFiltered.nominalEfektif - realisasiTahunBerjalan) / dataAnggaranDetailFiltered.nominalEfektif
        })
      }
    }

    return result
  }

  print = async () => {
    this.props.store.dispatch(showLoading(true))

    
    
    reportFile({
      template: { 'shortid': 'rkeH9TtK3O' },
      data: {
        
      },
      options: {
          reportName: `neraca`
      }
    },true,`neraca`)
    
    this.props.store.dispatch(showLoading(false))
  }

  // onToolbarPreparing = (e) => {
  //   e.toolbarOptions.items.unshift(
  //     {
  //         location: 'after',
  //         widget: 'dxButton',
  //         options: {
  //             icon: 'print',
  //             onClick: (e) => {
  //               this.print()
  //             },
  //         }
  //     }
  //   )
  // }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Laporan Anggaran</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='trial-balances'
          insertAPI='trial-balances'
          updateAPI='trial-balances'
          deleteAPI='trial-balances'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          useArraySource={true}
          ArraySourceData={this.loadData}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Anggaran Tahunan"}
          allowExportSelectedData={false}
          selection={"none"}

          showBorders={true}

          paging={false}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Anggaran Tahunan'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={false} // set false agar kolom tidak dapat di freeze

          FilterRow={false} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          sortingMode = {'none'}
          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
      </div>
    )
  }
}

export default AnggaranTahunan