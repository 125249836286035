import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import {FileUploader, Form, ScrollView} from 'devextreme-react'
import notify from 'devextreme/ui/notify';
import { karyawan, tipeDokumen } from 'dataSource/lookup';
import { formatUploadFileData, formatDate, download, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class FormDokumenKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible:false,
            fileUploadReceipt:[],
            DataDokumen:{},
            type:"tambah"
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        
        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.formDokumen = [
            {
                dataField: 'dokumenNo',
                label: {
                    text: 'No Dokumen'
                }
            },
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Karyawan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dokumenTypeId',
                label: {
                    text: 'Tipe Dokumen'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: tipeDokumen(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dokumenName', // provides display values
                    searchEnabled: true,
                    deferRendering: false, 
                }
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan'
                }
            },
            {
                dataField: 'dokumenDate',
                label: {
                    text: 'Tanggal Dokumen'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataDokumen: {},
            type:"tambah"
        })
    }

    fieldReadOnly = (data) => {
        this.setState({
            type : data
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var dataDokumen = { ...this.state.DataDokumen };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            dataDokumen[`${fieldName}Name`] = files.name;
            dataDokumen[fieldName] = formattedFileData.base64data;
            dataDokumen[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataDokumen: dataDokumen
            });
        };

        fr.readAsDataURL(files);
        }
    }

    submitData = async() => {
        try {
            var loadApi = "karyawan-dokumen"
            var DataDokumen = {...this.state.DataDokumen}
            var data = {
                karyawanId:DataDokumen.karyawanId,
                dokumenTypeId:DataDokumen.dokumenTypeId,
                keterangan:DataDokumen.keterangan,
                dokumenDate:formatDate(DataDokumen.dokumenDate),
                file:DataDokumen.file,
                fileContentType:DataDokumen.fileContentType,
                fileName:DataDokumen.fileName,
                dokumenNo:DataDokumen.dokumenNo
            }
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadApi, 'POST', {
                values:data
            })

            if (response) {
                notify({ message: "Sukses Input Dokumen Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.props.forceRefresh()
                this.hide()
            }

        } catch (e) {
            console.log(e)
        }
    }

    updateData = async () => {
        try {
            var loadApi = "karyawan-dokumen"
            var DataDokumen = {...this.state.DataDokumen}
            var data = {
                id:DataDokumen.id,
                karyawanId:DataDokumen.karyawanId,
                dokumenTypeId:DataDokumen.dokumenTypeId,
                keterangan:DataDokumen.keterangan,
                dokumenDate:formatDate(DataDokumen.dokumenDate),
                file:DataDokumen.file,
                fileContentType: DataDokumen.fileContentType,
                fileName:DataDokumen.fileName,
                dokumenNo:DataDokumen.dokumenNo
            }
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadApi, 'PUT', {
                values:data,
                key:data.id
            },data)

            if (response) {
                notify({ message: "Sukses Update Dokumen Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.props.forceRefresh()
                this.hide()
            }

        } catch (e) {
            console.log(e)
        }
    }

    retrieveData = async(data) => {    
        var loadAPI = `karyawan-dokumen/${data.karyawanDokumenId}`
        var getDokumen = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPI, 'GET')
        this.setState({
            DataDokumen: getDokumen,
        })
    }

    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            // var extension = mime.extension(this.state.DataDokumen[`${fileName}ContentType`])
            // console.log(extension)
            var src = imageSource(this.state.DataDokumen[`${fileName}ContentType`],this.state.DataDokumen[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataDokumen[`${fileName}ContentType`],this.state.DataDokumen[`${fileName}`]),this.state.DataDokumen[`${fileName}Name`])
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Dokumen Karyawan"}
                width={"45vw"}
                height={"60vh"}
                toolbarItems={this.state.type === "tambah" ? this.PopupToolbarItem : this.state.type === "ubah" ? this.PopupToolbarItem2 : '' }
                >   
                <ScrollView height="100&" width="100%">
                    <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formDataDokumen'}
                        formData={this.state.DataDokumen}
                        items={this.formDokumen}
                        labelLocation = 'left'
                        readOnly={this.state.type === "lihat" ? true : false }
                    />
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <FileUploader
                                accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                                uploadMode={'useForm'}
                                onValueChanged={this.onUploadStarted.bind(this)} 
                                labelText={this.state.DataDokumen.fileName ? this.state.DataDokumen.fileName : "Upload File Disini"}
                                showFileList={false}
                                name={'file'}
                                value = {this.state.fileUploadReceipt}
                                disabled={this.state.type === "lihat" ? true : false }
                                ref={this.fileUploderRef}
                            />
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDokumen.file ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                            <button onClick={() => this.downloadFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDokumen.file ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Download
                            </button>
                        </div>
                    </div>
                    {/* <div className="row">
                        <label className="col-4">File Name:</label>
                        <label className="col-8">{ this.state.DataDokumen.fileName}</label>
                    </div> */}
                    </div>
                </ScrollView>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormDokumenKaryawan