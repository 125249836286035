import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { productDefault, custodies, banks, rekeningBank, pejabat, months } from 'dataSource/lookup'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import ModalBiayaCustodian from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/biaya-custodian/index'
import FormKonfirmasiSubmitCustody from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/biaya-custodian/formKonfirmasiSubmit'
import { formatDate, formatDefaultDate, formatNumberAum, numberToIndo } from 'plugin/helper';
import httpRequest from 'plugin/httprequest'
import { Form } from 'devextreme-react';
import notify from "devextreme/ui/notify";
import { confirmAlert } from 'react-confirm-alert'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import ModalReverse from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/biaya-custodian/reverse'

class BiayaCustodianTransaksi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.noFax = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '1 FP') : null
        this.noTelp = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '1 NTP') : null

        this.state = {
            FilterItem: {
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }
        this.prevTabIndex = 0
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.modalViewRef = React.createRef()
        this.modalReverseRef = React.createRef()
        this.dataGridBiayaRef = React.createRef()
        this.dataGridRiwayatRef = React.createRef()
        this.showModalKonfirmasiSubmitCustodyRef = React.createRef()
        this.FilterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Tanggal Terima",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '30%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '30%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text: 'Instruksi',
                    //     hint: 'Instruksi',
                    //     onClick: (e) => {
                    //         this.cetakInstruksi(e)
                    //     }
                    // },
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            if (e.row.data) {
                                if (e.row.data.status == 'OPEN') {
                                    this.showModalEditBiaya(e)
                                } else {
                                    notify({ message: `Status ${e.row.data.status} Tidak Bisa di Ubah`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                }
                            }

                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.status == 'AP') {
                                notify({ message: `Status ${e.row.data.status} Tidak Bisa di Batalkan`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            } else {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin ingin membatalkan data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelBiaya(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        onClick: (e) => {
                            if (e.row.data) {
                                if (e.row.data.status != 'AP') {
                                    confirmAlert({
                                        title: 'Konfirmasi Proses',
                                        message: 'Apakah akan di lakukan sekarang?',
                                        buttons: [
                                            {
                                                label: 'Ya',
                                                onClick: () => {
                                                    this.prosesBiaya(e)
                                                }
                                            },
                                            {
                                                label: 'Tidak'
                                            }
                                        ]
                                    })
                                } else {
                                    notify({ message: `Status ${e.row.data.status} Tidak Bisa di Proses`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                }
                            }
                        }
                    },
                    // {
                    //     text: 'Konfirmasi Debet',
                    //     hint: 'Konfirmasi Debet',
                    //     onClick: (e) => {
                    //         if (e.row.data.status == 'AP') {
                    //             this.showModalKonfirmasiSubmitCustody(e)
                    //         } else {
                    //             notify({ message: `Status ${e.row.data.status} Tidak Bisa di Konfimasi`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    //         }
                    //     }
                    // },
                ]
            },
            {
                dataField: 'noInstruksi',
                caption: 'No. Instruksi'
            },
            {
                dataField: 'produkName',
                caption: 'Produk',
            },
            {
                dataField: 'nomorAkunCustody',
                caption: 'Nomor Akun Kustodi',
            },
            {
                dataField: 'bankCustody',
                caption: 'Bank Kustodi',
            },
            {
                dataField: 'kpd',
                caption: 'KPD',
            },
            {
                dataField: 'tagihan',
                caption: 'Tagihan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'noTagihan',
                        caption: 'Nomor Tagihan'
                    },
                    {
                        dataField: 'tglTagihan',
                        caption: 'Tanggal Tagihan',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'totalTagihan',
                        caption: 'Total Tagihan',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'periode',
                caption: 'Periode',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'periodeTahun',
                        caption: 'Tahun'
                    },
                    {
                        dataField: 'periodeBulan',
                        caption: 'Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                        }
                    },
                ]
            },
            {
                dataField: 'pejabat',
                caption: 'Pejabat',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'value',
                            displayExpr: 'value',
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'value',
                            displayExpr: 'value',
                        }
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
        ]

        this.columnsRiwayat = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalViewBiaya(e)
                        }
                    },
                    {
                        text: 'Reverse',
                        hint: 'Reverse',
                        onClick: (e) => {
                            this.showModalReverse(e)
                        }
                    },
                ]
            },
            {
                dataField: 'noInstruksi',
                caption: 'No. Instruksi'
            },
            {
                dataField: 'produkName',
                caption: 'Produk',
            },
            {
                dataField: 'nomorAkunCustody',
                caption: 'Nomor Akun Kustodi',
            },
            {
                dataField: 'bankCustody',
                caption: 'Bank Kustodi',
            },
            {
                dataField: 'kpd',
                caption: 'KPD',
            },
            {
                dataField: 'tagihan',
                caption: 'Tagihan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'noTagihan',
                        caption: 'Nomor Tagihan'
                    },
                    {
                        dataField: 'tglTagihan',
                        caption: 'Tanggal Tagihan',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'totalTagihan',
                        caption: 'Total Tagihan',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'periode',
                caption: 'Periode',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'periodeTahun',
                        caption: 'Tahun'
                    },
                    {
                        dataField: 'periodeBulan',
                        caption: 'Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                        }
                    },
                ]
            },
            {
                dataField: 'pejabat',
                caption: 'Pejabat',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                        }
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
        ]

        this.TabDataSource = [
            {
                id: 1,
                text: 'TAGIHAN'
            },
            {
                id: 2,
                text: 'RIWAYAT'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    cancelBiaya = async (data) => {
        var id = data.id
        if (id) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `bank-transactions`,
                    'DELETE', id,
                )
                this.forceRefresh()
                notify({ message: 'Sukses Membatalkan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'TAGIHAN',
                    onClick: (e) => {
                        this.modalInsertRef.current.show()
                        this.modalInsertRef.current.generateNoKonf(true)
                    },
                },
            }
        )
    }

    forceRefresh = () => {
        this.dataGridBiayaRef.current.forceRefresh(true)
    }

    filterSubmit = () => {
        this.setState({
            FilterItem: this.state.FilterItem
        })

        this.dataGridRiwayatRef.current.forceRefresh(true)
    }

    prosesBiaya = async (e) => {
        try {
            if (e.row.data.status == 'OPEN') {
                var loadApi = `bank-transactions/tagihan-biaya-custody-konfirmasi/${e.row.data.id}`
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    loadApi,
                    "PUT", {
                    key: e.row.data.id,
                    values: {}
                },
                );

                if (response) {
                    this.forceRefresh()
                    notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } else {
                notify({ message: 'Data Gagal diproses', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }

        } catch (e) {
            console.log(e)
            notify({ message: 'Terjadi Kesalahan System', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

        }
    }

    cetakInstruksi = async (e) => {
        try {
            if (e.row.data.status == 'AP') {
                var dataBiaya = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `bank-transactions/${e.row.data.id}`,
                    "GET",
                );
                var dataRekBank = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `rek-banks/${dataBiaya.paymentRekBankId}`,
                    "GET",
                );
                var dataBank = await httpRequest(
                    process.env.REACT_APP_BACKEND,
                    this.props.store,
                    `banks/${dataRekBank.bankId}`,
                    "GET",
                );
                var month = months()._array.find(value => value.id == dataBiaya.periodeMonth).value

                reportFile({
                    template: { 'shortid': 'H1eehT8l2u' },
                    data: {
                        "fax": this.noFax ? this.noFax.stringValue : null,
                        "noTelp": this.noTelp ? this.noTelp.stringValue : null,
                        "custodyName": e.row.data.bankCustody,
                        "tglInstruksi": formatDefaultDate(dataBiaya.instruksiDate),
                        "noInstruksi": dataBiaya.instruksiNo,
                        "periodeBulan": month,
                        "year": dataBiaya.periodeYear,
                        "totalTagihan": formatNumberAum(Math.round(dataBiaya.nominalBersih)),
                        "totalTagihanToIndo": numberToIndo(Math.round(dataBiaya.nominalBersih)),
                        "jenisRekening": dataRekBank.jenis,
                        "rekeningDebet": dataRekBank.rekNo,
                        "rekeningName": dataRekBank.rekName,
                        "namaBank": dataBank.bankName,
                        "cabangBank": dataRekBank.cabang,
                        "pejabat1": dataBiaya.pejabat1,
                        "pejabat2": dataBiaya.pejabat2,
                        "keterangan": dataBiaya.description
                    },
                    options: {
                        reportName: "INSTRUKSI-" + dataBiaya.instruksiNo
                    }
                }, true, `Instruksi Biaya ${e.row.data.bankCustody}.docx`)

            } else {
                notify({ message: 'Data Gagal dicetak', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    showModalKonfirmasiSubmitCustody = (e) => {
        if (e.row) {
            this.showModalKonfirmasiSubmitCustodyRef.current.retrieveData(e.row.data)
        }
        this.showModalKonfirmasiSubmitCustodyRef.current.show()
    }

    showModalEditBiaya = (e) => {
        if (e.row) {
            this.modalEditRef.current.retrieveData(e.row.data)
        }
        this.modalEditRef.current.show()
    }

    showModalViewBiaya = (e) => {
        if (e.row) {
            this.modalViewRef.current.retrieveData(e.row.data)
        }
        this.modalViewRef.current.show()
    }

    showModalReverse = (e) => {
        this.modalReverseRef.current.show(e.row.data)
    }
    render() {
        return (
            <div className="container-fluid">
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="main-title">Daftar Tagihan Biaya Bank Kustodi</h2>
                            <DevExpressDataGrid
                                ref={this.dataGridBiayaRef}
                                loadAPI='bank-transactions/tagihan-biaya-custody-open-ap?size=999'
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Daftars"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Tagihan Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}
                                onToolbarPreparing={this.onToolbarPreparing}

                                remoteOperations={true}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ModalBiayaCustodian
                                ref={this.modalInsertRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                                action={'add'}
                            />
                            <ModalBiayaCustodian
                                ref={this.modalEditRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                                action={'edit'}
                            />
                            <FormKonfirmasiSubmitCustody
                                forceRefresh={this.forceRefresh}
                                ref={this.showModalKonfirmasiSubmitCustodyRef}
                                store={this.props.store}
                            />
                        </div>
                    </div>
                </div>
                <div id="tabindex-1" className="d-none">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="main-title">Riwayat Tagihan Biaya Bank Kustodi</h2>
                            <Form
                                colCount={1}
                                id={'dataMaster'}
                                formData={this.state.FilterItem}
                                items={this.FilterItem}
                                labelLocation={"left"}
                            />
                            <DevExpressDataGrid
                                ref={this.dataGridRiwayatRef}
                                loadAPI={`bank-transactions/tagihan-biaya-custody-riwayat/${formatDate(this.state.FilterItem.startDate)}/${formatDate(this.state.FilterItem.endDate)}?size=999`}
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Riwayats"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Riwayat Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnsRiwayat} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                // remoteOperations = {true}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ModalBiayaCustodian
                                ref={this.modalViewRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                                action={'view'}
                            />
                            <ModalReverse
                                ref={this.modalReverseRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BiayaCustodianTransaksi