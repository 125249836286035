import React, { Component } from 'react'
import {kjpp} from '../parts/staticDataColumnConfig'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { programs } from 'dataSource/lookup'
import FormEditorProduct from '../modal/static-data/product/form'
import ModalRekening from '../modal/static-data/product/rekening'
import { product } from '../parts/staticDataColumnConfig'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'

class Product extends Component {
    constructor(props) {
        super(props)
        this.modalFormRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalRekeningRef = React.createRef()
        this.columnStaticData = product(this.props.store)
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalForm(data)
                        }
                    },
                    {
                        text: 'Rekening',
                        hint: 'Rekening',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalRekening(data)
                        }
                    },
                ]
            },
            {
                dataField: 'roNumber',
                caption: 'No.',
                cellRender:(data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                }
            },
            {
                dataField: 'productCode',
                caption: 'Kode Produk'
            },
            {
                dataField: 'productName',
                caption: 'Nama Produk'
            },
            {
                dataField: 'kepesertaan',
                caption: 'KEPESERTAAN',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pemberiKerja',
                        caption: 'Pemberi Kerja',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'aktif',
                        caption: 'Aktif',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'pasif',
                        caption: 'Pasif',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'arsip',
                        caption: 'Arsip',
                        dataType: 'number',
                        alignment: 'right'
                    },
                ]
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 
    showModalForm = (data) => {
        this.modalFormRef.current.show(data)
    }
    showModalRekening = (data) => {
        this.modalRekeningRef.current.show(data)
    }
    forceRefresh =() => {
        this.dataGridRef.current.forceRefresh()
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Produk',
                    onClick: (e) => {
                        this.showModalForm()
                    },
                }
            }
        )
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `staticdata-wf/products`, 'DELETE', data.id);

            notify({message: 'Data Berhasil Dihapus', width: "AUTO", shading: true, position: { at: "center", my: "center", of: window }}, 'success', 600);
            this.forceRefresh()
        } catch (e) {
            notify({message: e, width: "AUTO", shading: true, position: { at: "center", my: "center", of: window }}, 'error', 600);
        }
    }

    render() {
        var columnStaticData = [{
            type: 'buttons',
            buttons: [
            //   {
            //     text: 'Ubah',
            //     hint: 'Ubah',
            //     cssClass: 'text-success',
            //     onClick: async(e) => {
            //         this.showModalEdit('edit', e.row.data)
            //     }
            //   },
              {
                text: 'Hapus',
                hint: 'Hapus',
                cssClass: 'text-success',
                onClick: async(e) => {
                    confirmAlert({
                        title: 'Konfirmasi',
                        message: 'Apakah anda yakin untuk menghapus data ini?',
                        buttons: [
                            {
                                label: 'Ya',
                                onClick: () => {
                                    this.deleteAPI(e.row.data)
                                }
                            },
                            {
                                label: 'Tidak'
                            }
                        ]
                    })
                }
              }
            ],
        },...this.columnStaticData]
        return (
            <div className="container-fluid">
                <h2 className="main-title">Produk</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='products'
                    insertAPI='staticdata-wf/products'
                    updateAPI='staticdata-wf/products'
                    deleteAPI='staticdata-wf/products' 

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Produk"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Produk Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={columnStaticData} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    remoteOperations = {true}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormEditorProduct ref={this.modalFormRef} store={this.props.store} forceRefresh={this.forceRefresh} />
                <ModalRekening ref={this.modalRekeningRef} store={this.props.store} />
            </div>
        )
    }
}

export default Product