import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import ManfaatLainnyaUploadSkBayar from 'components/pages/modal/kepesertaan/pembayaranMp/manfaat-lainnya/uploadSkBayar';
import TambahPembayaranMpManfaatLainnya from 'components/pages/modal/kepesertaan/pembayaranMp/manfaat-lainnya/tambahPembayaran';
import PembayaranMpPembayaranModal from 'components/pages/modal/kepesertaan/pembayaranMp/comp/showModal';
import ManfaatLainnyaGeneratePembayaran from 'components/pages/modal/kepesertaan/pembayaranMp/manfaat-lainnya/generatePembayaranModal';
import { product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import { alert } from 'devextreme/ui/dialog';
import { showLoading } from 'redux/actions/loading';

class PengajuanManfaatLainnya extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {
                produk: 1
            },
            url: null
        }

        this.dataGridRef = React.createRef()
        this.pembayaranMpGeneratePembayaranModalRef = React.createRef()
        this.pembayaranMpDetailModalRef = React.createRef()
        this.pembayaranMpUploadSkModalRef = React.createRef()
        this.tambahPembayaranMpModalRef = React.createRef()

        this.selectedId = []
        this.filterItem = [
            {
                dataField: 'produk',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        // this.dataGridRef.current.forceRefresh(true)
                        var loadAPI
                        var type = this.props.match.params.step
                        if(data.value) {
                            if(type === 'pengajuan') {
                                loadAPI = `pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/2/${data.value}?size=99999`
                            } else if(type === 'pemeriksaan') {
                                loadAPI = `pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/3/${data.value}?size=99999`
                            } else if (type === 'persetujuan') {
                                loadAPI = `pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/4/${data.value}?size=99999`
                            }
                        } else {
                            if(type === 'pengajuan') {
                                loadAPI = `pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/2/1?size=99999`
                            } else if(type === 'pemeriksaan') {
                                loadAPI = `pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/3/1?size=99999`
                            } else if (type === 'persetujuan') {
                                loadAPI = `pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/4/1?size=99999`
                            }
                        }

                        this.setState({
                            url : loadAPI
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Kirim',
                        hint : 'Kirim',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Menerima Pembayaran Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.kirim(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Batal',
                        hint : 'Batal',
                        cssClass: 'text-success',
                        onClick : async (e) => {
                            await this.batal(e.row.data.idPembayaran)
                        }
                    },
                    {
                        text: 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail('view',e.row.data)
                        }
                    },
                    {
                        text: 'Unggah SK',
                        hint : 'Unggah SK',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            this.showModalUploadSk(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'productIds',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                },
            },
            {
                dataField: 'mpNames',
                caption: 'Nama Pembayaran Lain',
            },
            {
                dataField: 'totalPeserta',
                caption: 'Jumlah Pensiunan',
                alignment: 'right',
                format: "#,##0",
            },
            {
                dataField: 'summaryNominal',
                caption: 'Total Nilai Pembayaran Lain',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'paymentDates',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',  
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'resume',
                caption: 'Resume',
                alignment:'center',
                columns: [
                    {
                        dataField: 'rekbank',
                        caption: 'Bank Bayar'
                    },
                    {
                        dataField: 'totalFeeBank',
                        caption: 'Total Biaya Bank',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'retur',
                        caption: 'Retur',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                ]
            },
            {
                dataField: 'arStatus',
                caption: 'AR/AP Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Maker'
                        },
                        {
                            id: 2,
                            value: 'Checker'
                        },
                        {
                            id: 3,
                            value: 'Approval'
                        },
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                },
            },
        ]
    }

    getUrl = () => {
        var url

        switch(this.props.match.params.step) {
            case 'pengajuan':
                url = 'pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/2/1?size=99999'
                break;
            case 'pemeriksaan':
                url = 'pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/3/1?size=99999'
                break;
            case 'persetujuan':
                url = 'pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/4/1?size=99999'
                break;
            default:
                url = 'pembayaran-mp-lainnya/getByCategoryNidAndStatusAndProduct/2/2/1?size=99999'
        }

        this.setState({
            url : url
        })
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    componentDidMount = () => {
        this.getUrl()
    }

    componentDidUpdate = (prevProps,prevState) => {
        if(prevProps.match.params.step !== this.props.match.params.step){
            this.getUrl()
            this.selectedId = []
        }
    }
    
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Generate Pembayaran',
                    onClick: (e) => {
                        this.showModalDetail('generate', {})
                    },
                    visible : this.props.match.params.step == 'pengajuan' ? true : false
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Tambah Pembayaran',
                    onClick: (e) => {
                        this.showModalTambahPembayaran()
                    },
                    visible : this.props.match.params.step == 'pengajuan' ? true : false
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Kirim',
                    elementAttr: { class: "bg-dapen" },
                    onClick: () => {
                        this.kirimSelectedData(this.selectedId)
                    },
                }
            }
        )
    }

    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                produk : 'produk test',
                namaManfaatLain : 'Nama Test',
                jumlahPensiunan : 1000,
                totalNilaiManfaatLain : 3000000,
                noSkPembayaran : 2501,
                tanggalPembayaran : '2020-07-16',
                bankBayar : 'BANK BCA',
                totalBiayaBank : 2500000,
                totalFeeBank : 3000000,
                retur : 500000,
                arApStatus : 'Payable',
            }
        ]
        this.currentData = JSON.parse(JSON.stringify(dummyData))
        return dummyData
    }

    showModalDetail = (type, data) => {
        if(type == 'view'){
            this.pembayaranMpDetailModalRef.current.show()
            this.pembayaranMpDetailModalRef.current.retrieveData(data)
        }

        if(type == 'generate'){
            this.pembayaranMpGeneratePembayaranModalRef.current.show()
        }
    }

    showModalUploadSk = (data) => {
        this.pembayaranMpUploadSkModalRef.current.show()
        this.pembayaranMpUploadSkModalRef.current.retrieveData(data)
    }

    showModalTambahPembayaran = () => {
        this.tambahPembayaranMpModalRef.current.show()
    }

    kirim = async(data) => {
        var updateStatus
        switch(this.props.match.params.step) {
            case 'pengajuan':
                updateStatus = 3
                break;
            case 'pemeriksaan':
                updateStatus = 4
                break;
            case 'persetujuan':
                updateStatus = 5
                break;
            default:
                updateStatus = 3
        }
        try {
            var loadAPIMaster = `pembayaran-mps/${data.idPembayaran}`
            var getMasterData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')
            
            var dataUpdateMaster = getMasterData
            dataUpdateMaster.statusId = updateStatus
            dataUpdateMaster.status = updateStatus

            var updateAPI = `pembayaran-mps`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                key : dataUpdateMaster.id,
                values: dataUpdateMaster
            },dataUpdateMaster)

            if(this.props.match.params.step == 'persetujuan'){
                var loadAPITransaction = 'transaksi-pembayaran-mps-lainnya'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPITransaction, 'POST', {
                    values: {
                        mpPensiunTypeId: dataUpdateMaster.mpTypeId,
                        pembayaranMpId: dataUpdateMaster.id
                    }
                })
            }

            this.forceRefresh()
            var text = 'Data Berhasil Dikirim!'
            var type = "success"
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);  
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
        }
    }

    kirimSelectedData = async(selectedId) => {
        if(selectedId.length !== 0){
            confirmAlert({
                title: 'Konfirmasi',
                message: "Apakah Anda Yakin Ingin Mengirim Pembayaran Ini?",
                buttons: [
                  {
                    label: 'Iya',
                    onClick:  async() => {
                        try {
                            for(var value of selectedId){
                                var loadAPI = `pembayaran-mps/${value}`
                                var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                                
                                var dataUpdate = getPembayaranMp
                                switch(this.props.match.params.step) {
                                    case 'pengajuan':
                                        dataUpdate.statusId = 3
                                        break;
                                    case 'pemeriksaan':
                                        dataUpdate.statusId = 4
                                        break;
                                    case 'persetujuan':
                                        dataUpdate.statusId = 5
                                        break;
                                    default:
                                        dataUpdate.statusId = 3
                                }

                                var loadAPIUpdate = 'pembayaran-mps'
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIUpdate, 'PUT', {
                                    key: dataUpdate.id,
                                    values: dataUpdate
                                },getPembayaranMp)

                                if(this.props.match.params.step == 'persetujuan'){
                                    var loadAPITransaction = 'transaksi-pembayaran-mps-lainnya'
                                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPITransaction, 'POST', {
                                        values: {
                                            mpPensiunTypeId: dataUpdate.manfaatPensiunTypeId,
                                            pembayaranMpId: dataUpdate.id
                                        }
                                    })
                                }
                            }
                            
                            var text = `Data berhasil dikirim!`
                            var type = 'success'
                            this.forceRefresh()
                        } catch (e) {
                            console.log(e)
                            var text = e
                            var type = 'error'
                        }
                        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                    }
                  },
                  {
                    label: 'Tidak',
                    onClick: () => {

                    }
                  },
                ]
            });
        }else{
            var text = `Silahkan centang data terlebih dahulu!`
            var type = 'error'
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }
    }

    batal = async(pembayaranMpId) => {
        var updateStatus
        switch(this.props.match.params.step) {
            case 'pengajuan':
                updateStatus = 2
                break;
            case 'pemeriksaan':
                updateStatus = 2
                break;
            case 'persetujuan':
                updateStatus = 3
                break;
            default:
                updateStatus = 3
        }

        if(this.props.match.params.step != 'pengajuan'){
            var loadAPIMaster = `pembayaran-mps/${pembayaranMpId}`
            var getMasterData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')
            
            var dataUpdateMaster = getMasterData
            dataUpdateMaster.statusId = updateStatus
            dataUpdateMaster.status = updateStatus

            var updateAPI = `pembayaran-mps`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                key : dataUpdateMaster.id,
                values: dataUpdateMaster
            },dataUpdateMaster)
            
            var message = `Data berhasil dibatalkan!`
            var title = 'Berhasil'
        }

        if(this.props.match.params.step == 'pengajuan'){
            let store = this.props.store
            store.dispatch(showLoading(true))
            try {            
                var loadAPIDetail = `pembayaran-mp-detils/deleteByPembayaranMpId`
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIDetail, 'DELETE', pembayaranMpId)
                
                var loadAPIMaster = `pembayaran-mps`
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIMaster, 'DELETE', pembayaranMpId)
        
                var message = `Data berhasil dibatalkan!`
                var title = 'Berhasil'
            } catch (e) {
                var message = e
                var title = 'Gagal'
                store.dispatch(showLoading(false))
            }
            store.dispatch(showLoading(false))
        }
        await alert(message, title)
        this.forceRefresh()
    }
    onSelectionChanged = (e) => {
        this.selectedId = e.selectedRowKeys
    }

    submitGenerateData = async(data,pembayaranMpId) => {
        try {
            for(var master of pembayaranMpId){
                var loadAPIMaster = `pembayaran-mps/${master}`
                var getMasterData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')
                
                var dataUpdateMaster = getMasterData
                dataUpdateMaster.statusId = 2
                dataUpdateMaster.status = 2
    
                var updateAPI = `pembayaran-mps`
                var updateMasterData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key : dataUpdateMaster.id,
                    values: dataUpdateMaster
                },dataUpdateMaster)
            }

            // for(var value of data){
            //     var loadAPIDetail = `pembayaran-mp-detils/${value.idPembayaranMpDetil}`
            //     var getDetailData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetail, 'GET')
    
            //     var dataUpdate = getDetailData
            //     dataUpdate.pembayaranMpStatusId = 2
    
            //     var updateAPI = `pembayaran-mp-detils`
            //     var updateDetailData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
            //         key : dataUpdate.id,
            //         values: dataUpdate
            //     },getDetailData)
    
            // }
            this.forceRefresh()
            var text = 'Data Berhasil Digenerate!'
            var type = "success"
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);  
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
        }
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title" style={{ textTransform: 'capitalize'}}>{(this.props.match.params.step)}</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={this.state.url}
                    insertAPI='pembayaran-mps'
                    updateAPI='pembayaran-mps'
                    deleteAPI='pembayaran-mps' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    keyField={'idPembayaran'}
                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Lainnya"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Lainnya'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ManfaatLainnyaGeneratePembayaran 
                    ref={this.pembayaranMpGeneratePembayaranModalRef}
                    store={this.props.store}
                    actionType={'generate'}
                    submitData = {this.submitGenerateData}
                    forceRefresh={this.forceRefresh}
                    type={'Pembayaran Lainnya'}
                />
                <PembayaranMpPembayaranModal 
                    ref={this.pembayaranMpDetailModalRef}
                    store={this.props.store}
                    actionType={'view'}
                    type={'Pembayaran Lainnya'}
                />
                <ManfaatLainnyaUploadSkBayar
                    ref={this.pembayaranMpUploadSkModalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <TambahPembayaranMpManfaatLainnya
                    ref={this.tambahPembayaranMpModalRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default PengajuanManfaatLainnya