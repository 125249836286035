import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import { alert, confirm } from 'devextreme/ui/dialog';
import { instrumentsCategoryProperti } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';

class FormModalInsertInstruksiProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            newData: true
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.formItems = [
            {
                dataField: 'instrumentCode',
                label: {
                    text: 'Kode Properti'
                }
            },
            {
                dataField: 'instrumentName',
                label: {
                    text: 'Nama Properti'
                }
            },
            {
                dataField: 'lokasi',
                label: {
                    text: 'Lokasi'
                }
            },
            {
                dataField: 'categorySubId',
                label: {
                    text: 'Kategori Properti'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentsCategoryProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'luasBangunan',
                label: {
                    text: 'Luas Bangunan m²'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                    step: 0,
                }
            },
            {
                dataField: 'luasTanah',
                label: {
                    text: 'Luas Tanah m²'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                    step: 0,
                }
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    // onClick: this.submitData.bind(this)
                    onClick: async (e) => {
                        this.submitData();
                        // const formValidation = this.validate();
                        // if (formValidation){
                        //     await this.submitData();
                        // }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.submitData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide(){
        // this.sumAmount = 0
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
        // this.readOnly = false
    }

    newData = (isTrue) => {
        this.setState({
            newData: isTrue
        })
    }

    retrieveData = async (data) => {
        try {
            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.id}`, 'GET');
            
            this.setState({
                dataMaster: response
            })
        } catch (error) {
            console.log(error);
        }
    }

    submitData = async () => {
        try {
            let data = this.state.dataMaster;

            if (this.state.newData === true) {
                let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'staticdata-wf/instruments', 'POST', {
                    values: data
                });
    
                if (response) {
                    this.hide();
                    this.props.forceRefresh();
                }
            } else {
                let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'staticdata-wf/instruments', 'PUT', {
                    key: data.id,
                    values: data
                }, data);

                if (response) {
                    this.hide();
                    this.props.forceRefresh();
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Tambah Instrument Properti'}
                width={"30vw"}
                height={"40vh"}
                toolbarItems={this.state.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                // ref={this.PopupRef}
            >
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.formItems}
                    labelLocation='left'
                    // readOnly={this.readOnly}
                />
            </Popup>
        )
    }
}

export default FormModalInsertInstruksiProperti