import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { formatUploadFileData, formatUploadFileNameAndExtention, formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import FormDataKepegawaian from '../../compPeserta/dataKepegawaian';
import FormDataPersonal from '../../compPeserta/dataPersonal';
import DataGridKeluarga from '../../compPeserta/dataKeluarga';
import FormDataAkun from '../../compPeserta/dataAkun';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import FormDataKepegawaianAktif from '../../compPeserta/dataKepegawaianAktif';

class ShowModalPengkinianPesertaRegistrasiAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataPengkinian: {},
            dataAkun: [],
            actionType: ''
        }

        this.prevTabIndex = 0
        this.iuranType = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.formMasterRef = React.createRef()
        this.formDataKepegawaianRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.formDataAkunRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => await this.submitData(),
                },
                toolbar: 'bottom',
                visible: this.props.actionType === 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': this.props.actionType === 'view' ? 'tutup' : 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Personal'
            },
            {
                id: 1,
                text: 'Data Kepegawaian'
            },
            {
                id: 2,
                text: 'Data Keluarga'
            },
            {
                id: 3,
                text: 'Data Akun'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                disabled: true,
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                }
            },
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show = async () => {
        try {
            var loadAPIIuranType = `iuran-types`
            var getIuranType = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIIuranType, 'GET')

            this.iuranType = getIuranType

        } catch (e) {
            console.log(e)
        }

        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataPengkinian: {},
            actionType: ''
        });
    }

    setNik = (nik) => {
        var data = { ...this.state.dataMaster }
        data.nik = nik
        this.formMasterRef.current.instance.updateData(data)
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        var validateMaster = this.formMasterRef.current.instance.validate()
        var validatePersonal = this.formDataPersonalRef.current.validate()
        var validateKepegawaian = this.formDataKepegawaianRef.current.validate()
        if (validateMaster.isValid && validatePersonal.isValid && validateKepegawaian.isValid) {
            var dataPengkinian = this.state.dataPengkinian
            var dataPersonal = this.formDataPersonalRef.current.passDataToParent()
            var dataKepegawaian = this.formDataKepegawaianRef.current.passDataToParent()
            var dataKeluarga = this.dataGridKeluargaRef.current.passDataToParent()
            var dataAkun = this.state.dataAkun
            // var dataAkun =  this.formDataAkunRef.current.passDataToParent()
            for (var akun of dataAkun) {
                if (!akun.dataIuran) {
                    var loadAPI = `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${akun.id}`
                    var dataIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

                    akun.dataIuran = dataIuran
                }

                // for (var iuran of akun.dataIuran) {
                //     var findPercent = this.iuranType.find(val => val.id == iuran.iuranTypeId)
                //     iuran.iuranNominal = (dataKepegawaian.phdp || 0) * (findPercent.persenPhdp / 100)
                // }
            }
            dataPersonal.nik = this.state.dataMaster.nik
            dataPersonal.fullName = this.state.dataMaster.fullName
            dataKepegawaian.statusKaryawanTanggal = dataKepegawaian.statusKaryawanTanggal ? formatDate(dataKepegawaian.statusKaryawanTanggal) : null
            // dataPersonal.noKtp = this.state.dataMaster.identityNo
            var dataParam = {
                dataPersonal: dataPersonal,
                dataKepegawaian: dataKepegawaian,
                dataKeluarga: dataKeluarga,
                dataAkun: dataAkun
            }
            dataPengkinian.updateParam = JSON.stringify(dataParam)
            await this.submitDataPengkinian(dataPengkinian)

            await alert('Berhasil Pengkinian Peserta Aktif!', 'Success')
            this.hide();
            this.props.forceRefresh()
            // this.hide()
        } else {
            var errorMessage = []
            if (validateMaster.brokenRules.length > 0) {
                for (var value of validateMaster.brokenRules) {
                    errorMessage.push(value.message)
                }
            }
            if (validatePersonal.brokenRules.length > 0) {
                for (var value of validatePersonal.brokenRules) {
                    errorMessage.push(value.message)
                }
            }
            if (validateKepegawaian.brokenRules.length > 0) {
                for (var value of validateKepegawaian.brokenRules) {
                    errorMessage.push(value.message)
                }
            }
            let text = errorMessage.join("<br/>");

            await alert(text, 'Validasi')
        }
    }

    submitDataPengkinian = async (data) => {
        var dataPengkinian = data
        // dataPengkinian.updatedDate = formatDate(new Date())
        dataPengkinian.pengkinianDate = formatDate(new Date())
        // dataPengkinian.updatedStatus = true
        if (dataPengkinian) {
            try {
                var updateAPI = 'pengkinian-peserta-bo-aktifs'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPengkinian.id,
                    values: dataPengkinian
                },
                    dataPengkinian)
                return response
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }


    retrieveData = async (pesertaId, dataPengkinian) => {

        if (dataPengkinian.updateParam) {
            var updatedData = JSON.parse(dataPengkinian.updateParam)
            if(updatedData.dataKepegawaian.statusKaryawanTanggal === '-') {
                updatedData.dataKepegawaian.statusKaryawanTanggal = null
            }
            await this.formDataPersonalRef.current.retrieveData(updatedData.dataPersonal, 'pengkinianPeserta')
            await this.formDataKepegawaianRef.current.retrieveData(updatedData.dataPersonal.id, updatedData.dataKepegawaian)
            await this.dataGridKeluargaRef.current.reloadData(updatedData.dataPersonal.id, updatedData.dataKeluarga.dataKeluarga || [], true)
            await this.formDataAkunRef.current.reloadData(updatedData.dataPersonal.id, (updatedData.dataAkun || []))
        } else {
            try {
                var loadAPI = `simple-pesertas/getSimpleById/${pesertaId}`
                var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${pesertaId}`, 'GET')

                dataPeserta.mpRekeningNo = response[0].mpRekeningNo
                dataPeserta.mpRekeningName = response[0].mpRekeningName
                dataPeserta.mpRekeningBankId = response[0].mpRekeningBankId
                dataPeserta.mpRekeningCabang = response[0].mpRekeningCabang
                dataPeserta.paymentRekbankId = response[0].paymentRekbankId

                await this.formDataPersonalRef.current.retrieveData(dataPeserta)
            } catch (e) {
                console.log(e)
            }
            try {
                var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`
                var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKeluarga, 'GET')

                await this.dataGridKeluargaRef.current.reloadData(dataPeserta.id, getKeluarga || [])

            } catch (e) {
                console.log(e)
            }
            try {
                var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${pesertaId}`
                var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKepegawaian, 'GET')

                await this.formDataKepegawaianRef.current.retrieveData(dataPeserta.id, getKepegawaian)
            } catch (e) {
                console.log(e)
            }
            try {
                var loadAPIAkun = `kepersertaan-products/getAllSimpleProductByPesertaId/${pesertaId}`
                var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'GET')

                await this.formDataAkunRef.current.reloadData(dataPeserta.id, (getAkun || []))

            } catch (e) {
                console.log(e)
            }
        }

        this.setState({
            pesertaId: pesertaId,
            dataPengkinian: dataPengkinian,
            dataMaster: dataPeserta || updatedData.dataPersonal
        })
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabIndexModalRegistrasi-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabIndexModalRegistrasi-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onUploadStarted = (e, jenisData) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState => ({
                    [jenisData]: {
                        ...prevState[jenisData],
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                        [`${fieldName}Name`]: formattedFileNameAndExtention.name,
                        webExtentionFile: formattedFileNameAndExtention.extention,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }

    saveDataAkun = (dataAkun) => {
        this.setState({
            dataAkun: dataAkun
        })

        console.log(this.state.dataAkun, 'Save data akun')

    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengkinian Peserta Aktif'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row" style={{ height: 'calc(90vh - 325px)' }}>
                        <div className="col-md-12">
                            <Form
                                colCount={3}
                                ref={this.formMasterRef}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                // readOnly={this.state.actionType == 'pengajuan' || this.state.actionType == 'add' ? false : true}
                                labelLocation='left'
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <ScrollView width='100%' height='100%'>
                            <div id="tabIndexModalRegistrasi-0" className={' mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)' }}>
                                <FormDataPersonal store={this.props.store} actionType={this.props.actionType} ref={this.formDataPersonalRef} />
                            </div>
                            <div id="tabIndexModalRegistrasi-1" className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)' }}>
                                <FormDataKepegawaianAktif store={this.props.store} actionType={this.props.actionType} ref={this.formDataKepegawaianRef} setNik={this.setNik.bind(this)} />
                            </div>
                            <div id="tabIndexModalRegistrasi-2" className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)' }}>
                                <DataGridKeluarga store={this.props.store} actionType={this.props.actionType} ref={this.dataGridKeluargaRef} />
                            </div>
                            <div id="tabIndexModalRegistrasi-3" className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)' }}>
                                <FormDataAkun store={this.props.store} actionType={this.props.actionType} ref={this.formDataAkunRef} saveDataAkun={this.saveDataAkun} />
                            </div>
                        </ScrollView>
                    </div>
                </div>
            </Popup>
        );
    }
}


export default ShowModalPengkinianPesertaRegistrasiAktif
