import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { kecamatan } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import { cities } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDefaultFullDate, formatNumber, formatNumberAum, numberToIndo } from 'plugin/helper'
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import notify from 'devextreme/ui/notify';
import { showLoading } from 'redux/actions/loading';

class LaporanKaryawanPerjalananDinas extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            // alert('Under development', 'Pemberitahuan')
                            this.printData(e.row.data);
                        }
                    },
                ],
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'unitName',
                caption: 'Unit',
            },
            {
                dataField: 'namaJagol',
                caption: 'Jabatan Golongan',
            },
            {
                dataField: 'perjalananDinas',
                caption: 'Perjalanan Dinas',
                columns: [
                    {
                        dataField: 'noPenugasan',
                        caption: 'No Penugasan',
                    },
                    {
                        dataField: 'sppdName',
                        caption: 'Jenis Perjalanan',
                    },
                    {
                        dataField: 'keterangan',
                        caption: 'Keterangan'
                    },
                    {
                        dataField: 'cityId',
                        caption: 'Kota',
                        lookup: {
                            dataSource: cities(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'cityName'
                        }
                    },
                    {
                        dataField: 'hari',
                        caption: 'Hari',
                    },
                    {
                        dataField: 'tanggalAwal',
                        caption: 'Tanggal Awal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tanggalAkhir',
                        caption: 'Tanggal Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'jumBiaya',
                        caption: 'Biaya SPPD',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
        ]
        this.selectedRowsData = [];

        this.dataGridRef = React.createRef();
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    // getCitiId = async (id) => {
    //     let data;
    //     if (id !== undefined) {
    //         data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `cities/${id}`, 'GET');
    //     } else {
    //         data = '';
    //     }
    //     return data;
    // }

    printData = async (data) => {
        try {
            let globalParamRes = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters');

            let findParamNpj = globalParamRes.find(val => val.paramCode === '1 NPJ').stringValue;
            let findParamJpj = globalParamRes.find(val => val.paramCode === '1 JPJ').stringValue;

            let responseSppd = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `laporan-sppd/${data.id}/${findParamNpj}/${findParamJpj}`);

            const res = await fetch('/file_upload/Laporan sppd.xlsx');
            const blob = await res.blob();

            const dataArrayBuffer = await blob.arrayBuffer();

            const workbook = new Excel.Workbook();
            await workbook.xlsx.load(dataArrayBuffer);

            const workSheetNames = [
                'SPPD',
                'lamp',
                'Rincian'
            ];

            for (const workSheetName of workSheetNames) {
                let workSheet = workbook.getWorksheet(workSheetName);
                switch(workSheetName) {
                    case 'SPPD' :
                        workSheet.getCell('D10').value = responseSppd[0].sppd_d10;
                        workSheet.getCell('D11').value = responseSppd[0].sppd_d11;
                        workSheet.getCell('E12').value = responseSppd[0].sppd_e12;
                        workSheet.getCell('E13').value = responseSppd[0].sppd_e13;
                        workSheet.getCell('E14').value = responseSppd[0].sppd_e14;
                        // workSheet.getCell('D15').value = responseSppd[0].sppd_d15;
                        workSheet.getCell('D16').value = responseSppd[0].sppd_d16;
                        workSheet.getCell('E18').value = responseSppd[0].sppd_e18;
                        workSheet.getCell('E19').value = responseSppd[0].sppd_e19;
                        workSheet.getCell('E20').value = responseSppd[0].sppd_e20;
                        workSheet.getCell('E21').value = responseSppd[0].sppd_e21;
                        workSheet.getCell('E22').value = responseSppd[0].sppd_e22;
                        workSheet.getCell('E23').value = responseSppd[0].sppd_e23;
                        workSheet.getCell('E24').value = responseSppd[0].sppd_e24;
                        workSheet.getCell('E25').value = responseSppd[0].sppd_e25;
                        workSheet.getCell('E26').value = responseSppd[0].sppd_e26;
                        workSheet.getCell('D27').value = responseSppd[0].sppd_d15;
                        workSheet.getCell('D31').value = responseSppd[0].sppd_d31;
                        workSheet.getCell('D32').value = responseSppd[0].sppd_d32;
                        workSheet.getCell('D39').value = responseSppd[0].sppd_d39;
                        workSheet.getCell('D40').value = responseSppd[0].sppd_d40;

                        workSheet.getCell('C29').value = 'Terbilang : ' + numberToIndo(responseSppd[0].sppd_e26);
                    break;
                    case 'lamp' : let dataLamp = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `laporan-sppd-lamp/${data.id}/${findParamNpj}/${findParamJpj}`);
                        for(let i = 0; i < dataLamp.length; i++){
                            // workSheet.getCell(`A${dataLamp[i].baris}`).value = dataLamp[i].kolom_a;
                            workSheet.getCell(`B${dataLamp[i].baris}`).value = dataLamp[i].kolom_b;
                            workSheet.getCell(`C${dataLamp[i].baris}`).value = dataLamp[i].kolom_c;
                            workSheet.getCell(`D${dataLamp[i].baris}`).value = dataLamp[i].kolom_d;
                            workSheet.getCell(`E${dataLamp[i].baris}`).value = dataLamp[i].kolom_e;
                            workSheet.getCell(`F${dataLamp[i].baris}`).value = dataLamp[i].kolom_f;
                            workSheet.getCell(`G${dataLamp[i].baris}`).value = dataLamp[i].kolom_g;
                            workSheet.getCell(`H${dataLamp[i].baris}`).value = dataLamp[i].kolom_h;
                            workSheet.getCell(`I${dataLamp[i].baris}`).value = dataLamp[i].kolom_i;
                            workSheet.getCell(`J${dataLamp[i].baris}`).value = dataLamp[i].kolom_j;
                            workSheet.getCell(`K${dataLamp[i].baris}`).value = dataLamp[i].kolom_k;
                            workSheet.getCell(`L${dataLamp[i].baris}`).value = dataLamp[i].kolom_l ? formatNumber(dataLamp[i].kolom_l) : '';
                            workSheet.getCell(`M${dataLamp[i].baris}`).value = dataLamp[i].kolom_m;
                            workSheet.getCell(`N${dataLamp[i].baris}`).value = dataLamp[i].kolom_n;
                            workSheet.getCell(`O${dataLamp[i].baris}`).value = dataLamp[i].kolom_o;
                            workSheet.getCell(`P${dataLamp[i].baris}`).value = dataLamp[i].kolom_p;
                            workSheet.getCell(`Q${dataLamp[i].baris}`).value = dataLamp[i].kolom_q ? formatNumber(dataLamp[i].kolom_q) : '';
                        }

                        // workSheet.getRow(14).hidden = true;

                        if (dataLamp.length <= 7) {
                            workSheet.mergeCells('K12:N12');
                            workSheet.mergeCells('K13:N13');
                            workSheet.mergeCells('K14:N14');
                            workSheet.mergeCells('K15:N15');

                            workSheet.mergeCells('K20:N20');
                            workSheet.mergeCells('K21:N21');
                            workSheet.getCell('K20').font = { underline: true}
                        } else if (dataLamp.length <= 9) {
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.mergeCells('L14:O14');
                            workSheet.getCell('L14').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('K18:N18');
                            workSheet.mergeCells('K19:N19');
                            workSheet.mergeCells('K20:N20');
                            workSheet.mergeCells('K21:N21');
                            workSheet.mergeCells('K26:N26');
                            workSheet.mergeCells('K27:N27');
                        } else if (dataLamp.length <= 12){
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.mergeCells('L14:O14');
                            workSheet.getCell('L14').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('K18:N18');
                            workSheet.mergeCells('K19:N19');
                            workSheet.mergeCells('K20:N20');
                            workSheet.mergeCells('K21:N21');
                            workSheet.mergeCells('K26:N26');
                            workSheet.mergeCells('K27:N27');
                        }else if (dataLamp.length <= 14) {
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10'); 
                            workSheet.getRow(20).hidden = true;
                            workSheet.mergeCells('L16:O16');
                            workSheet.getCell('L16').alignment = { vertical: 'middle', horizontal: 'right' };
                            workSheet.mergeCells('K21:N21');
                            workSheet.mergeCells('K22:N22');
                            workSheet.mergeCells('K23:N23');
                            workSheet.mergeCells('K24:N24');
                            workSheet.mergeCells('K28:N28');
                            workSheet.getCell('K28').font = { underline: true }
                            workSheet.mergeCells('K29:N29');
                        } else if (dataLamp.length <= 15) {
                            // workSheet.getRow(16).hidden = true;
                            // workSheet.getRow(17).hidden = true;
                            workSheet.getRow(20).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L18:O18');
                            workSheet.getCell('L17').alignment = { vertical: 'middle', horizontal: 'right' };
                            workSheet.getCell('L18').alignment = { vertical: 'middle', horizontal: 'right' };
                            workSheet.mergeCells('K27:O27');

                            workSheet.mergeCells('K22:N22');
                            workSheet.mergeCells('K23:N23');
                            workSheet.mergeCells('K24:N24');
                            workSheet.mergeCells('K25:N25');
                            workSheet.mergeCells('K30:N30');
                            workSheet.mergeCells('K31:N31');
                            // workSheet.mergeCells('K39:N39');
                            // workSheet.mergeCells('K40:N40');
                            // workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 18) {
                            // workSheet.getCell('B18').font = { bold: true };
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.mergeCells('B18:D18');
                            workSheet.mergeCells('C19:D19'); 
                            workSheet.mergeCells('L25:O25');
                            // workSheet.getCell('L25').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.getRow(12).hidden = true;
                            workSheet.getRow(14).hidden = true;
                            workSheet.getRow(15).hidden = true;
                            workSheet.getRow(22).hidden = true;
                            workSheet.getRow(23).hidden = true;

                            workSheet.getCell('K37').font = { underline: true}

                            workSheet.mergeCells('K29:N29');
                            workSheet.mergeCells('K30:N30');
                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K37:N37');
                            // workSheet.getCell('L37').font = { underline: true }
                            workSheet.mergeCells('K38:N38');
                        } else if (dataLamp.length <= 19) {
                            // workSheet.getRow(16).hidden = true;
                            // workSheet.getRow(17).hidden = true;
                            workSheet.getRow(13).hidden = true;
                            workSheet.getRow(23).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B19:D19');
                            workSheet.getCell('C19').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('C20:D20');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');
                            workSheet.mergeCells('L26:O26');
                            // workSheet.getCell('L26').alignment = { vertical: 'middle', horizontal: 'center' };

                            workSheet.getCell('K38').font = { underline: true}
                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K38:N38');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 20) {
                            workSheet.getRow(16).hidden = true;
                            workSheet.getRow(17).hidden = true;
                            workSheet.getRow(24).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');
                            workSheet.mergeCells('L27:O27');

                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 21) {
                            // workSheet.getRow(14).hidden = true;
                            // workSheet.getRow(25).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            // workSheet.mergeCells('C22:D22');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');

                            workSheet.getCell('K40').font = { underline: true}
                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 22) {
                            // workSheet.getRow(16).hidden = true;
                            // workSheet.getRow(17).hidden = true;
                            workSheet.getRow(25).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');

                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 24) {
                            workSheet.getRow(14).hidden = true;
                            workSheet.getRow(16).hidden = true;
                            workSheet.getRow(17).hidden = true;
                            workSheet.getRow(24).hidden = true;
                            workSheet.getRow(26).hidden = true;
                            workSheet.getRow(27).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            // workSheet.mergeCells('L28:O28');
                            // workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('L34:P34');

                            workSheet.getCell('K46').font = { underline: true}
                            workSheet.mergeCells('B30:C30');
                            workSheet.mergeCells('C31:D31');

                            workSheet.mergeCells('K38:N38');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                            workSheet.mergeCells('K46:N46');
                            workSheet.mergeCells('K47:N47');
                        }
                    break;
                    case 'Rincian' : let dataRincian = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `sppd-get-rincian?oprDir=${findParamJpj}&oprJbt=${findParamNpj}&sppdId=${data.id}`, 'GET');
                        for (let i = 0; i < dataRincian.length; i++) {
                            // workSheet.getCell(`A${dataRincian[i].baris}`).value = dataRincian[i].kolom_a ? formatNumber(dataRincian[i].kolom_a) : '';
                            workSheet.getCell(`B${dataRincian[i].baris}`).value = dataRincian[i].kolom_b ? formatNumber(dataRincian[i].kolom_b) : '';
                            workSheet.getCell(`C${dataRincian[i].baris}`).value = dataRincian[i].kolom_c ? formatNumber(dataRincian[i].kolom_c) : '';
                            workSheet.getCell(`D${dataRincian[i].baris}`).value = dataRincian[i].kolom_d ? formatNumber(dataRincian[i].kolom_d) : '';
                            workSheet.getCell(`E${dataRincian[i].baris}`).value = dataRincian[i].kolom_e ? formatNumber(dataRincian[i].kolom_e) : '';
                            workSheet.getCell(`F${dataRincian[i].baris}`).value = dataRincian[i].kolom_f ? formatNumber(dataRincian[i].kolom_f) : '';
                            workSheet.getCell(`G${dataRincian[i].baris}`).value = dataRincian[i].kolom_g ? formatNumber(dataRincian[i].kolom_g) : '';
                            workSheet.getCell(`H${dataRincian[i].baris}`).value = dataRincian[i].kolom_h ? formatNumber(dataRincian[i].kolom_h) : '';
                            workSheet.getCell(`I${dataRincian[i].baris}`).value = dataRincian[i].kolom_i ? formatNumber(dataRincian[i].kolom_i) : '';
                            workSheet.getCell(`J${dataRincian[i].baris}`).value = dataRincian[i].kolom_j ? formatNumber(dataRincian[i].kolom_j) : '';
                            workSheet.getCell(`K${dataRincian[i].baris}`).value = dataRincian[i].kolom_k ? formatNumber(dataRincian[i].kolom_k) : '';
                            workSheet.getCell(`L${dataRincian[i].baris}`).value = dataRincian[i].kolom_l ? formatNumber(dataRincian[i].kolom_l) : '';
                            workSheet.getCell(`M${dataRincian[i].baris}`).value = dataRincian[i].kolom_m ? formatNumber(dataRincian[i].kolom_m) : '';
                            workSheet.getCell(`N${dataRincian[i].baris}`).value = dataRincian[i].kolom_n ? formatNumber(dataRincian[i].kolom_n) : '';
                            if (dataRincian.length === 6) {
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D20').value = '';
                                workSheet.getCell('D21').value = '';
                                workSheet.getCell('D22').value = '';
                                workSheet.getCell('D23').value = '';
                                workSheet.getCell('K20').value = '';
                                workSheet.getCell('K21').value = '';
                                workSheet.getCell('K22').value = '';
                                workSheet.getCell('K23').value = '';

                                workSheet.getCell('D26').value = dataRincian[4].kolom_d;
                                workSheet.getCell('D25').value = dataRincian[5].kolom_d

                                workSheet.getCell('K25').value = dataRincian[4].kolom_k;
                                workSheet.getCell('K26').value = dataRincian[5].kolom_k;
                            } else if (dataRincian.length === 9) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D23').value = dataRincian[8].kolom_d;
                                workSheet.getCell('D24').value = dataRincian[7].kolom_d;

                                workSheet.getCell('K24').value = dataRincian[8].kolom_k;
                                workSheet.getCell('K23').value = dataRincian[7].kolom_k;
                                workSheet.getCell('M15').value = dataRincian[6].kolom_m ? formatNumber(dataRincian[6].kolom_m) : '';
                            }else if (dataRincian.length === 10) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D24').value = dataRincian[i].kolom_d;
                                workSheet.getCell('D25').value = dataRincian[8].kolom_d;

                                workSheet.getCell('D24').font = { underline: true }
                                workSheet.getCell('K24').font = { underline: true }

                                workSheet.getCell('D25').font = { underline: false }
                                workSheet.getCell('K25').font = { underline: false }

                                workSheet.getCell('K25').value = dataRincian[i].kolom_k;
                                workSheet.getCell('K24').value = dataRincian[8].kolom_k;
                                workSheet.getCell('M15').value = dataRincian[7].kolom_m ? formatNumber(dataRincian[7].kolom_m) : '';
                                // workSheet.mergeCells('K24:N24');                                                 
                                // workSheet.mergeCells('K23:N23');
                                // workSheet.mergeCells('K24:N24');
                            } else if (dataRincian.length >= 13){
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D27').value = dataRincian[12].kolom_d;
                                workSheet.getCell('D28').value = dataRincian[11].kolom_d;

                                workSheet.getCell('K28').value = dataRincian[12].kolom_k;
                                workSheet.getCell('K27').value = dataRincian[11].kolom_k;
                                // workSheet.mergeCells('K23:N23');
                                // workSheet.mergeCells('K24:N24');
                            } else if (dataRincian.length >= 12) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;      
                                workSheet.getCell('K27').value = dataRincian[11].kolom_k;
                                workSheet.getCell('K26').value = dataRincian[10].kolom_k;
                                workSheet.getCell('M15').value = dataRincian[9].kolom_m ? formatNumber(dataRincian[9].kolom_m) : '';
                                // workSheet.getCell('K24').value = dataRincian[i].kolom_k;                                                 
                                // workSheet.mergeCells('K23:N23');
                                // workSheet.mergeCells('K24:N24');
                            } else if (dataRincian.length >= 11) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D25').value = dataRincian[9].kolom_d;
                                workSheet.getCell('D26').value = dataRincian[10].kolom_d;

                                workSheet.getCell('K25').value = dataRincian[9].kolom_k;
                                workSheet.getCell('K26').value = dataRincian[10].kolom_k; 
                                workSheet.getCell('M15').value = dataRincian[8].kolom_m ? formatNumber(dataRincian[8].kolom_m) : '';  
                            } else {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                            }
                        }

                        workSheet.getColumn(14).width = 17;

                        workSheet.mergeCells('K25:N25');
                        workSheet.mergeCells('K26:N26');
                        workSheet.mergeCells('K27:N27'); 
                    break;
                }
            }

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_SPPD.xlsx`
                )
            })

        } catch (error) {
            console.log(error)
        }
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'view-report-karyawan-perjalanan-dinas', 'GET');
        let response = this.selectedRowsData;
        let store = this.props.store;
        store.dispatch(showLoading(true));
        // let result = [];

        // for (let value of citie) {
        //     result.push(value);
        // }

        let citie = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `cities`, 'GET');

        for (let value of response){
            
            try {
                let dataValue = {
                    nik: value.nik,
                    fullName: value.fullName ? value.fullName : '-',
                    unitName: value.unitName ? value.unitName : '-',
                    namaJagol: value.namaJagol ? value.namaJagol : '-',
                    noPenugasan: value.noPenugasan ? value.noPenugasan : '-',
                    keterangan: value.keterangan ? value.keterangan : '-',
                    sppdName: value.sppdName ? value.sppdName : '-',
                    // cityId: value.cityId === 4 ? 'Kota Denpasar' : value.cityId === 63 ? 'Kab. Bandung' : value.cityId === 40 ? 'Kota Jakarta Barat' : '-',
                    cityId: value.cityId ? citie.find(val => val.id === value.cityId).cityName : "-",
                    hari: value.hari,
                    tanggalAwal: value.tanggalAwal ? formatDefaultFullDate(value.tanggalAwal) : '-',
                    tanggalAkhir: value.tanggalAkhir ? formatDefaultFullDate(value.tanggalAkhir) : '-',
                    jumBiaya: value.jumBiaya ? formatNumberAum(value.jumBiaya) : 0
                }
                const objectValue = Object.values(dataValue);
                data.push(objectValue);
            } catch (error) {
                
            }
            // let citie = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `cities`, 'GET');

        }
        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR KARYAWAN PERJALANAN DINAS');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'NIK'},
                {name: 'Nama'},
                {name: 'Unit'},
                {name: 'Jabatan Golongan'},
                {name: 'No Penugasan'},
                {name: 'Keterangan'},
                {name: 'Jenis Perjalanan'},
                {name: 'Kota'},
                {name: 'Hari'},
                {name: 'Tanggal Awal'},
                {name: 'Tanggal Akhir'},
                {name: 'Biaya SPPD'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                // worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            
            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'DAFTAR KARYAWAN PERJALANAN DINAS';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums2; 

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums2;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR_PERJALANAN_DINAS.xlsx`
                );
            });

        } catch (error) {
            console.log(error);
        }
         store.dispatch(showLoading(false))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.exportExcel()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            }
        ) 
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Karyawan Perjalanan Dinas</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-report-karyawan-perjalanan-dinas?size=9999'
                    insertAPI='view-report-karyawan-perjalanan-dinas'
                    updateAPI='view-report-karyawan-perjalanan-dinas'
                    deleteAPI='view-report-karyawan-perjalanan-dinas'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={false}
                    exportFileName={"Report Karyawan Perjalanan Dinas"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Karyawan Perjalanan Dinas'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    remoteOperations={false}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanKaryawanPerjalananDinas