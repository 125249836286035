import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { participants, product } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import { Form, Button } from 'devextreme-react'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultMonthYear, formatDefaultDate, PrintElem, generateExcelFile, formatNumber, formatNumberAum, numberWithPoint, numberWithCommas } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';


const bulan = [
    {
        id : 1,
        namaBulan: 'Januari'
    },
    {
        id : 2,
        namaBulan: 'Februari'
    },
    {
        id : 3,
        namaBulan: 'Maret'
    },
    {
        id : 4,
        namaBulan: 'April'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Juni'
    },
    {
        id : 7,
        namaBulan: 'Juli'
    },
    {
        id : 8,
        namaBulan: 'Agustus'
    },
    {
        id : 9,
        namaBulan: 'September'
    },
    {
        id : 10,
        namaBulan: 'Oktober'
    },
    {
        id : 11,
        namaBulan: 'November'
    },
    {
        id : 12,
        namaBulan: 'Desember'
    }
]
class ReportResumeIuran extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formFilter: {},
            src: ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: participants(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'participantName'
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [ 
                            {
                                dataField: 'greaterPeriode',
                                label: {
                                    text: 'Periode'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    displayFormat: 'MMM yyyy',
                                    placeholder : 'Bulan/Tahun',
                                    calendarOptions: {
                                        maxZoomLevel: 'year'
                                    },
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'lessPeriode',
                                label: {
                                    text: 'S/D'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    displayFormat: 'MMM yyyy',
                                    placeholder : 'Bulan/Tahun',
                                    calendarOptions: {
                                        maxZoomLevel: 'year'
                                    },
                                    openOnFieldClick: true
                                },
                            }
                        ]
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        // var loadAPI = `view-report-resume-iurans?`
        var loadAPI = `iurans/list-resume-pk?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // PERIODE
        if(filter.greaterPeriode){
            var bulanPeriode = new Date(filter.greaterPeriode).getMonth() + 1;
            var tahunPeriode = new Date(filter.greaterPeriode).getFullYear()
            loadAPI = loadAPI+`startMonth=${bulanPeriode}&startYear=${tahunPeriode}&`
        }
        if(filter.lessPeriode){
            var bulanPeriode = new Date(filter.lessPeriode).getMonth() + 1;
            var tahunPeriode = new Date(filter.lessPeriode).getFullYear()
            loadAPI = loadAPI+`endMonth=${bulanPeriode}&endYear=${tahunPeriode}&`
        }
        var getIuranResumePk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        let arrTotalIuran = [];
        let arrTotalIuranPeserta = [];
        let arrIuranNormalPk = [];
        let arrIuranManfaatLainPk = [];

        for(var value of getIuranResumePk){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'
            
            var participantDetailName = getParticipant.find(val => val.id == value.participantId)
            value.participantId = participantDetailName ? participantDetailName.participantName : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            value.transactionDate = value.transactionDate ? formatDefaultDate(value.transactionDate) : '-'
            value.receiveDate = value.receiveDate ? formatDefaultDate(value.receiveDate) : '-'

            // summaryLainPk = summaryLainPk + value.lainPk ? value.lainPk : 0

            value.totalIuranPeserta = value.totalIuranPeserta ? value.totalIuranPeserta : 0
            value.iuranNormalPk = value.iuranNormalPk ? value.iuranNormalPk : 0
            value.iuranManfaatLainPk = value.iuranManfaatLainPk ? value.iuranManfaatLainPk : 0
            value.totalIuran = value.totalIuran ? value.totalIuran : 0

            arrTotalIuranPeserta.push(value.totalIuranPeserta);
            arrTotalIuran.push(value.totalIuran);
            arrIuranNormalPk.push(value.iuranNormalPk);
            arrIuranManfaatLainPk.push(value.iuranManfaatLainPk);
        }

        if(arrIuranManfaatLainPk.length !== 0 && arrIuranNormalPk.length !== 0 && arrTotalIuran.length !== 0 && arrTotalIuranPeserta.length !== 0) {
            var sumIuranPeserta = arrTotalIuranPeserta.reduce((a, b) => a + b, 0);
            var sumIuranTotal = arrTotalIuran.reduce((a, b) => a + b, 0);
            var sumIuranNormalPk = arrIuranNormalPk.reduce((a, b) => a + b, 0);
            var sumIuranManfatLainPk = arrIuranManfaatLainPk.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/iuran/resume-iuran',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                dataIuran : getIuranResumePk,
                summaryIuranPeserta: sumIuranPeserta,
                summaryIuranNormalPk: sumIuranNormalPk,
                summaryIuranManfaatPk: sumIuranManfatLainPk,
                summaryIuranTotal: sumIuranTotal,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
                // summaryLainPk: formatNumber(summaryLainPk)
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))
        this.setState({
            src : responseFile
        })
    }

    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        // var loadAPI = `view-report-resume-iurans?`
        var loadAPI = `iurans/list-resume-pk?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // PERIODE
        if(filter.greaterPeriode){
            var bulanPeriode = new Date(filter.greaterPeriode).getMonth() + 1;
            var tahunPeriode = new Date(filter.greaterPeriode).getFullYear()
            loadAPI = loadAPI+`startMonth=${bulanPeriode}&startYear=${tahunPeriode}&`
        }
        if(filter.lessPeriode){
            var bulanPeriode = new Date(filter.lessPeriode).getMonth() + 1;
            var tahunPeriode = new Date(filter.lessPeriode).getFullYear()
            loadAPI = loadAPI+`endMonth=${bulanPeriode}&endYear=${tahunPeriode}&`
        }
        var getIuranResumePk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        let arrTotalIuran = [];
        let arrTotalIuranPeserta = [];
        let arrIuranNormalPk = [];
        let arrIuranManfaatLainPk = [];

        for(var value of getIuranResumePk){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'
            
            var participantDetailName = getParticipant.find(val => val.id == value.participantId)
            value.participantId = participantDetailName ? participantDetailName.participantName : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            value.transactionDate = value.transactionDate ? formatDefaultDate(value.transactionDate) : '-'
            value.receiveDate = value.receiveDate ? formatDefaultDate(value.receiveDate) : '-'

            value.totalIuranPeserta = value.totalIuranPeserta ? value.totalIuranPeserta : 0
            value.iuranNormalPk = value.iuranNormalPk ? value.iuranNormalPk : 0
            value.iuranManfaatLainPk = value.iuranManfaatLainPk ? value.iuranManfaatLainPk : 0
            value.totalIuran = value.totalIuran ? value.totalIuran : 0

            arrTotalIuranPeserta.push(value.totalIuranPeserta);
            arrTotalIuran.push(value.totalIuran);
            arrIuranNormalPk.push(value.iuranNormalPk);
            arrIuranManfaatLainPk.push(value.iuranManfaatLainPk);
        }

        if(arrIuranManfaatLainPk.length !== 0 && arrIuranNormalPk.length !== 0 && arrTotalIuran.length !== 0 && arrTotalIuranPeserta.length !== 0) {
            var sumIuranPeserta = arrTotalIuranPeserta.reduce((a, b) => a + b, 0);
            var sumIuranTotal = arrTotalIuran.reduce((a, b) => a + b, 0);
            var sumIuranNormalPk = arrIuranNormalPk.reduce((a, b) => a + b, 0);
            var sumIuranManfatLainPk = arrIuranManfaatLainPk.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/iuran/resume-iuran',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                dataIuran : getIuranResumePk,
                summaryIuranPeserta: sumIuranPeserta,
                summaryIuranNormalPk: sumIuranNormalPk,
                summaryIuranManfaatPk: sumIuranManfatLainPk,
                summaryIuranTotal: sumIuranTotal,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
                // summaryLainPk: formatNumber(summaryLainPk)
            },
            options: {
                reportName: 'RESUME IURAN'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'RESUME IURAN')
        store.dispatch(showLoading(false))

        return responseFile
    }

    exportExcel = async() => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        // var loadAPI = `view-report-resume-iurans?`
        var loadAPI = `iurans/list-resume-pk?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // PERIODE
        if(filter.greaterPeriode){
            var bulanPeriode = new Date(filter.greaterPeriode).getMonth() + 1;
            var tahunPeriode = new Date(filter.greaterPeriode).getFullYear()
            loadAPI = loadAPI+`startMonth=${bulanPeriode}&startYear=${tahunPeriode}&`
        }
        if(filter.lessPeriode){
            var bulanPeriode = new Date(filter.lessPeriode).getMonth() + 1;
            var tahunPeriode = new Date(filter.lessPeriode).getFullYear()
            loadAPI = loadAPI+`endMonth=${bulanPeriode}&endYear=${tahunPeriode}&`
        }

        var periodeAwal = filter.greaterPeriode ? formatDefaultDate(filter.greaterPeriode).split(' ') : null
        var periodeAkhir = filter.lessPeriode ? formatDefaultDate(filter.lessPeriode).split(' ') : null

        var getIuranResumePk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var index = 1

        // let arrIuranPeserta = [];
        let arrTotalIuran = [];
        let arrTotalIuranPeserta = [];
        let arrIuranNormalPk = [];
        let arrIuranManfaatLainPk = [];

        for(var value of getIuranResumePk){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'
            
            var participantDetailName = getParticipant.find(val => val.id == value.participantId)
            value.participantId = participantDetailName ? participantDetailName.participantName : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            // summaryIuranPeserta = summaryIuranPeserta + value.totalIuranPeserta ? value.totalIuranPeserta : 0
            // summaryIuranPk = summaryIuranPk + value.totalIuranPk ? value.totalIuranPk : 0
            // summaryIuranTotal = summaryIuranTotal + value.totalIuran ? value.totalIuran : 0

            arrTotalIuranPeserta.push(value.totalIuranPeserta);
            arrTotalIuran.push(value.totalIuran);
            arrIuranNormalPk.push(value.iuranNormalPk);
            arrIuranManfaatLainPk.push(value.iuranManfaatLainPk);

            var dataValue = {
                id: index++,
                // participantId: value.participantId,
                // productId: value.productId,
                // bulan: value.bulan,
                // tahun: value.tahun,
                transactionDate: value.transactionDate ? formatDefaultDate(value.transactionDate) : '-',
                receiveDate: value.receiveDate ? formatDefaultDate(value.receiveDate) : '-',
                jumlahPeserta: value.jumlahPeserta ? value.jumlahPeserta : 0,
                totalIuranPeserta: value.totalIuranPeserta ? value.totalIuranPeserta : 0,
                iuranNormalPk: value.iuranNormalPk ? value.iuranNormalPk : 0,
                iuranManfaatLainPk: value.iuranManfaatLainPk ? value.iuranManfaatLainPk : 0,
                totalIuran: value.totalIuran ? value.totalIuran : 0,
                // lainPk: value.lainPk ? value.lainPk : 0,
            }

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        let sumIuranPeserta = arrTotalIuranPeserta.reduce((a, b) => a + b, 0);
        let sumIuranTotal = arrTotalIuran.reduce((a, b) => a + b, 0);
        let sumIuranNormalPk = arrIuranNormalPk.reduce((a, b) => a + b, 0);
        let sumIurnManfatLainPk = arrIuranManfaatLainPk.reduce((a, b) => a + b, 0);

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('RESUME IURAN');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A2',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                // {name: 'Pemberi Kerja'},
                // {name: 'Produk'},
                // {name: 'Bulan'},
                // {name: 'Tahun'},
                {name: 'Tanggal Proses'},
                {name: 'Tanggal Penerimaan'},
                {name: 'Jumlah Peserta'},
                {name: 'Iuran Peserta\r\n(Rp)'},
                {name: 'Iuran Normal\r\n(Rp)'},
                {name: 'Iuran Manfaat Lain\r\n(Rp)'},
                {name: 'Total Iuran\r\n(Rp)'},
                // {name: 'Iuran Lain Pemberi Kerja'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BRESUME IURAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BRESUME IURAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BRESUME IURAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            worksheet.mergeCells(`A${lastTableRowNum + 1}:D${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'right' };
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell(`D${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`E${lastTableRowNum + 1}`).value = (sumIuranPeserta);
            worksheet.getCell(`E${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`F${lastTableRowNum + 1}`).value = (sumIuranNormalPk);
            worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`G${lastTableRowNum + 1}`).value = (sumIurnManfatLainPk);
            worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`H${lastTableRowNum + 1}`).value = (sumIuranTotal);
            worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // worksheet.getCell('A3').value = 'Laporan'
            // worksheet.getCell('B3').value = ': RESUME IURAN'

            // worksheet.getCell('A4').value = 'Tanggal Laporan'
            // worksheet.getCell('B4').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A6').value = 'Pemberi Kerja'
            // worksheet.getCell('B6').value = `: ${participantName}`
            
            // worksheet.getCell('A7').value = 'Periode'
            // worksheet.getCell('B7').value = `: ${periodeAwal && periodeAkhir ? periodeAwal[1] + ' ' + periodeAkhir[2] + 'S/D' + periodeAkhir[1] + ' ' + periodeAkhir[2] : 'Semua'}`

            // worksheet.getCell('A8').value = 'Produk'
            // worksheet.getCell('B8').value = `: ${productName}`

            worksheet.mergeCells('A1:A2)');
            worksheet.getCell('A1').value = 'No';
            worksheet.getCell('A1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('A2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('B1:B2');
            worksheet.getCell('B1').value = 'Tanggal Proses';
            worksheet.getCell('B1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('B2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('C1:C2');
            worksheet.getCell('C1').value = 'Tanggal Penerimaan';
            worksheet.getCell('C1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C2').alignment = {vertical: 'middle', horizontal: 'center'};
            
            worksheet.mergeCells('D1:D2');
            worksheet.getCell('D1').value = 'Jumlah Peserta';
            worksheet.getCell('D1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('D2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('E1:E2');
            worksheet.getCell('E1').value = 'Iuran Peserta\r\n(Rp)';
            worksheet.getCell('E1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('E2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            worksheet.mergeCells('F1:G1');
            worksheet.getCell('F1').value = 'Iuran PK';
            worksheet.getCell('F1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('F2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('F2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('G2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.mergeCells('H1:H2');
            worksheet.getCell('H1').value = 'Total Iuran\r\n(Rp)';
            worksheet.getCell('H1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('H2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            for(var i = 3; i <= data.length + 2; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('A'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('C'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('D'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('E'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;
            Data4.numFmt = '#,##0'

            const Data5 = worksheet.getColumn(5);
            Data5.width = 30;
            Data5.numFmt = '#,##0.00'

            const Data6 = worksheet.getColumn(6);
            Data6.width = 30;
            Data6.numFmt = '#,##0.00'

            const Data7 = worksheet.getColumn(7);
            Data7.width = 30;
            Data7.numFmt = '#,##0.00'

            const Data8 = worksheet.getColumn(8);
            Data8.width = 30;
            Data8.numFmt = '#,##0.00'

            // const Data8 = worksheet.getColumn(8);
            // Data8.width = WidthColums;
            // Data8.numFmt = '#,##0.00'

            // const Data9 = worksheet.getColumn(9);
            // Data9.width = WidthColums;
            // Data9.numFmt = '#,##0.00'

            // const Data10 = worksheet.getColumn(10);
            // Data10.width = WidthColums;
            // Data10.numFmt = '#,##0.00'
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `RESUME IURAN.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"participantId", 
        //         to:"Pemberi Kerja"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"bulan", 
        //         to:"Bulan"
        //     },
        //     {
        //         from:"tahun", 
        //         to:"Tahun"
        //     },
        //     {
        //         from:"transactionDate", 
        //         to:"Tanggal Transaksi"
        //     },
        //     {
        //         from:"jumlahAkun", 
        //         to:"Jumlah Peserta"
        //     },
        //     {
        //         from:"normalPeserta", 
        //         to:"Iuran Peserta"
        //     },
        //     {
        //         from:"normalPk", 
        //         to:"Iuran Pemberi Kerja"
        //     },
        //     {
        //         from:"receiveAmount", 
        //         to:"Total Iuran"
        //     },
        //     {
        //         from:"lainPk", 
        //         to:"Iuran Lain Pemberi Kerja"
        //     }
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'RESUME IURAN'
        //     },
        //     {
        //         A: 'Tanggal Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Pemberi Kerja',
        //         B: null,
        //         C: ':',
        //         D: participantName
        //     },
        //     {
        //         A: 'Periode',
        //         B: null,
        //         C: ':',
        //         D: filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getIuranResumePk){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_IURAN_RESUME_PK')
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Resume Iuran</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.formRef}
                            formData={this.state.formFilter}
                            items={this.formItem}
                            labelLocation={"left"}
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportResumeIuran