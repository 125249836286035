import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { Form } from 'devextreme-react'
import FormModalGenerateJurnalMtm from 'components/pages/modal/investasi/settlement/generate-transaksi/transaksi-mtm/modalGenerateMtm'
import httpRequest from 'plugin/httprequest'
import {formatDate} from 'plugin/helper'
import { product, productDefault } from 'dataSource/lookup'
import ViewJurnalDetails from 'components/pages/modal/investasi/viewJurnalTrans'

class TransaksiMarkToMarket extends Component {
    constructor(props){
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            formFilter:{
                tanggalAwal:new Date(this.sysDate ? this.sysDate.dateValue : null),
                tanggalAkhir: new Date(this.sysDate ? this.sysDate.dateValue : null)
            }
        }

        this.dataGridRef = React.createRef()
        this.showModalRef = React.createRef()
        this.showModalGenerateMtmRef = React.createRef()

        this.filterItem = [
            {
                dataField:'tanggalAwal',
                label:{
                    text:"Tanggal Awal"
                },
                editorType:'dxDateBox',
                editorOptions:{
                    displayFormat:'dd MMM yyyy'
                },
            },
            {
                dataField:'tanggalAkhir',
                label:{
                    text:"Tanggal Akhir"
                },
                editorType:'dxDateBox',
                editorOptions:{
                    displayFormat:'dd MMM yyyy'
                },
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Lihat Jurnal',
                        hint : 'Lihat Jurnal',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalViewJurnal(e)
                        }
                    },
                ],
            },
            {
                dataField:'tanggal',
                caption:'Tanggal',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'produkId',
                caption:'Produk',
                lookup:{
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productName'
                }
            },
            {
                dataField:'kpdNo',
                caption:'KPD'
            },
            {
                dataField:'transactionTypeName',
                caption:'Tipe Transaksi'
            },
            {
                dataField:'instrumentCode',
                caption:'Instrument'
            },
            {
                dataField:'amountTrs',
                caption:'Harga/NAB',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField:'currencyCode',
                caption:'Currency'
            },
            {
                dataField:'currencyRate',
                caption:'Kurs',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField:'description',
                caption:'Keterangan'
            },
        ]
    }

    
    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalViewJurnal = (e) => {
        if (e.row) {
            this.showModalRef.current.retrieveData(e.row.data)
            this.showModalRef.current.newData(true)
        }else{
            this.showModalRef.current.newData(false)
        }

        this.showModalRef.current.show()
    }

    showModalGenerateMtm = (e) => {
        // if (e.row) {
        //     this.showModalGenerateMtmRef.current.retrieveData(e.row.data)
        //     this.showModalGenerateMtmRef.current.newData(true)
        // }else{
        //     this.showModalGenerateMtmRef.current.newData(false)
        // }

        this.showModalGenerateMtmRef.current.show()
        this.showModalGenerateMtmRef.current.generateMtm()
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    filterSubmit = () => {
        this.setState({
            formFilter:this.state.formFilter
        })

        this.dataGridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Generate',
                    onClick: (e) => {
                        this.showModalGenerateMtm(e)
                    },
                }
            }
        );
    }

    loadDummyData = async () => {
        var loadAPI = `transaction/get-transaction-mtm/${formatDate(this.state.formFilter.tanggalAwal)}/${formatDate(this.state.formFilter.tanggalAkhir)}`

        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadAPI,
            "GET"
        );
        if (response) {
            
        }

        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Transaksi Mark To Market</h3>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                />
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='daftar-penagjuan-pengadaan'
                    insertAPI='daftar-penagjuan-pengadaan'
                    updateAPI='daftar-penagjuan-pengadaan'
                    deleteAPI='daftar-penagjuan-pengadaan'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Transaksi Mark To Market"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Transaksi Mark To Market'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 400px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ViewJurnalDetails 
                    ref={this.showModalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    from={"generate"}
                />
                <FormModalGenerateJurnalMtm 
                    ref={this.showModalGenerateMtmRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default TransaksiMarkToMarket