import React, { Component } from 'react'
import { currency, banks, orders, product, broker, pejabat} from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormInstruksiBankTerkirim from 'components/pages/modal/investasi/instruksi/bank/terkirim/index'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import httpRequest from 'plugin/httprequest'
import { imageSource } from 'plugin/helper'

class InstruksiBrokerTerkirim extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.dataGridInstruksiBankRef = React.createRef()
        this.formPenempatanRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            this.previewFile(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'instruksiId',
                caption: 'ID',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiNo',
                        caption: 'Nomor'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'totalOrder',
                        caption: 'Jumlah',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'totalQuantityOrder',
                        caption: 'Total Kuantitas (Lembar)',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    }
                ]
            },
            {
                dataField: 'pembelian',
                caption: 'Pembelian',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'sahamBuy',
                        caption: 'Saham',
                    },
                    {
                        dataField: 'totalQuantityOrderBuy',
                        caption: 'Total Kuantitas',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'penjualan',
                caption: 'Penjualan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'sahamSell',
                        caption: 'Saham',
                    },
                    {
                        dataField: 'totalQuantityOrderSell',
                        caption: 'Total Kuantitas',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            },
        ]
    }
    previewFile = async(data) => {
        var instruksiId = data.instruksiId
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-stok/${instruksiId}`, 'GET')
        var src = imageSource(response.orderInstruksiDTO.fileInstruksiContentType,response.orderInstruksiDTO.fileInstruksi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Broker Terkirim</h2>
                <DevExpressDataGrid
                    keyField='instruksiId'
                    ref={this.dataGridInstruksiBankRef}
                    loadAPI='order-instruksi-stok/list?size=9999&status=SENT'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instruksi Broker"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Broker'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormInstruksiBankTerkirim
                    ref={this.formPenempatanRef}
                    store={this.props.store}
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default InstruksiBrokerTerkirim