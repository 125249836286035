import React, { Component } from 'react'
import { Form } from "devextreme-react";
import uuidv4 from 'uuid/v4'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatDefaultDate, formatDefaultFullDate, getSystemDate } from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from "redux/actions/loading";
import { product } from 'dataSource/lookup';

class BalanceSheet extends Component {
  constructor(props) {
    super(props)

    this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

    this.state = {
      tanggal: this.systemDate ? new Date(this.systemDate.dateValue) : null,
      formFilter: {
        tanggal: this.systemDate ? new Date(this.systemDate.dateValue) : null,
        produkId: null
      }
    }

    this.dataGridRef = React.createRef()
    // this.modalDetailProsesIuranRef = React.createRef()
    // this.modalGenerateProsesIuranRef = React.createRef()

    this.filterItem = [
      {
        dataField: "tanggal",
        label: {
          text: "Tanggal",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'MMM yyyy',
          calendarOptions: {
            maxZoomLevel: 'year'
          },
          openOnFieldClick: true
        }
      },
      {
        dataField: "produkId",
        label: {
          text: "Produk",
          alignment: "left",
          location: "left"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: product(this.props.store),
          displayExpr: function (item) {
            return item && item.productCode + " - " + item.productName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            const column = this.changeColumn()
            this.dataGridRef.current.changeColumn(column)
            this.dataGridRef.current.forceRefresh(true)

            let selectedDate = new Date(this.state.formFilter.tanggal);
            let selectedMonth = selectedDate.getMonth();
            let selectedYear = selectedDate.getFullYear();
            selectedDate = new Date(selectedYear, selectedMonth + 1, 0, 0, 0, 0, 0);

            this.setState({
              tanggal: selectedDate,
            });
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = this.changeColumn()

    this.sumDebit = [

      {
        column: 'aktivaBalanceAmount',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'totalAktivaBalanceAmount',
        displayFormat: '{0}'
      },
      {
        column: 'aktivaBalanceAmountBaseCurrency',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'totalAktivaBalanceAmountBaseCurrency',
        displayFormat: '{0}'
      },
      {
        column: 'aktivaBalanceAmountLastMonth',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'aktivaBalanceAmountLastMonth',
        displayFormat: '{0}'
      },
      {
        column: 'aktivaBalanceAmountBaseCurrencyLastMonth',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'aktivaBalanceAmountBaseCurrencyLastMonth',
        displayFormat: '{0}'
      },

      {
        column: 'pasivaBalanceAmount',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'totalPasivaBalanceAmount',
        displayFormat: '{0}'
      },
      {
        column: 'pasivaBalanceAmountBaseCurrency',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'totalPasivaBalanceAmountBaseCurrency',
        displayFormat: '{0}'
      },
      {
        column: 'pasivaBalanceAmountLastMonth',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'pasivaBalanceAmountLastMonth',
        displayFormat: '{0}'
      },
      {
        column: 'pasivaBalanceAmountBaseCurrencyLastMonth',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'pasivaBalanceAmountBaseCurrencyLastMonth',
        displayFormat: '{0}'
      },
    ]

    this.dataPasiva = []
    this.dataAktiva = []
    this.lastYearDate = new Date()
  }

  getDayMonthYear = (date) => {
    if (date) {
      let period = formatDefaultFullDate(date).split(' ');
      period = `${period[1]} ${period[2]}`;
      return period;
    }
    return ''; // Handle the case when date is not available
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  changeColumn = () => {
    var filteredDate
    if (this.state) {
      filteredDate = new Date(this.state.formFilter.tanggal)
    } else {
      filteredDate = this.systemDate ? new Date(this.systemDate.dateValue) : null
    }

    const prevDate = new Date(filteredDate.getFullYear() - 1, 12, 0, 0, 0, 0, 0)
    const currentDate = new Date(filteredDate.getFullYear(), filteredDate.getMonth() + 1, 0, 0, 0, 0, 0)
    return [
      {
        name: 'aktiva',
        caption: 'Aktiva',
        alignment: 'center',
        columns: [
          {
            dataField: 'aktivaCoaCode',
            caption: 'Akun COA',
          },
          {
            dataField: 'aktivaCoaName',
            caption: 'Nama COA',
          },
        ]
      },
      {
        name: 'saldoAktiva',
        caption: `Per Berjalan ${formatDefaultFullDate(currentDate)}`,
        alignment: 'center',
        columns: [
          {
            dataField: 'aktivaBalanceAmount',
            caption: 'Saldo',
            format: '#,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'aktivaBalanceAmountBaseCurrency',
            caption: 'Saldo (Base Curency)',
            format: '#,##0.00',
            alignment: 'right'
          },
        ]
      },
      {
        name: 'saldoAktivaLastMonth',
        caption: `Per Sebelumnya ${formatDefaultFullDate(prevDate)}`,
        alignment: 'center',
        columns: [
          {
            dataField: 'aktivaBalanceAmountLastMonth',
            caption: 'Saldo',
            format: '#,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'aktivaBalanceAmountBaseCurrencyLastMonth',
            caption: 'Saldo (Base Curency)',
            format: '#,##0.00',
            alignment: 'right'
          },
        ]
      },
      {
        name: 'pasiva',
        caption: 'Pasiva',
        alignment: 'center',
        columns: [
          {
            dataField: 'pasivaCoaCode',
            caption: 'Akun COA',
          },
          {
            dataField: 'pasivaCoaName',
            caption: 'Nama COA',
          },
        ]
      },
      {
        name: 'saldoPasiva',
        caption: `Per Berjalan ${formatDefaultFullDate(currentDate)}`,
        alignment: 'center',
        columns: [
          {
            dataField: 'pasivaBalanceAmount',
            caption: 'Saldo',
            format: '#,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'pasivaBalanceAmountBaseCurrency',
            caption: 'Saldo (Base Curency)',
            format: '#,##0.00',
            alignment: 'right'
          },
        ]
      },
      {
        name: 'saldoPasivaLastMonth',
        caption: `Per Sebelumnya ${formatDefaultFullDate(prevDate)}`,
        alignment: 'center',
        columns: [
          {
            dataField: 'pasivaBalanceAmountLastMonth',
            caption: 'Saldo',
            format: '#,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'pasivaBalanceAmountBaseCurrencyLastMonth',
            caption: 'Saldo (Base Curency)',
            format: '#,##0.00',
            alignment: 'right'
          },
        ]
      },
    ]
  }

  loadData = async (OJKProdukId = null, OJKTanggal = null) => {
    const produkId = OJKProdukId || this.state.formFilter.produkId
    const tanggal = OJKTanggal || this.state.formFilter.tanggal

    if (produkId === null || produkId === '') {
      return []
    }

    var response
    var dataCOA = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'coas')
    var coaRE = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters/get-by-paramCode/RE')
    coaRE = dataCOA.find(value => value.id === coaRE.intValue)

    var systemDate = new Date(this.systemDate ? this.systemDate.dateValue : null)
    const systemMonth = systemDate.getMonth()
    const systemYear = systemDate.getFullYear();
    systemDate.setHours(0, 0, 0, 0)

    var selectedDate = new Date(tanggal);
    const selectedMonth = selectedDate.getMonth()
    const selectedYear = selectedDate.getFullYear();
    selectedDate = new Date(selectedYear, selectedMonth + 1, 0);

    if (OJKProdukId) {
      var lastYearDate = new Date(selectedYear, selectedMonth, 0)
      this.lastYearDate = lastYearDate
    } else {
      var lastYearDate = new Date(selectedYear - 1, 12, 0)
      this.lastYearDate = lastYearDate
    }

    if (selectedDate.getTime() < systemDate.getTime()) {
      response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(selectedDate)}`)
      // response = response.filter(value => value.produkId === produkId)
      response = response.map(value => {
        const coa = dataCOA.find(valueCoa => valueCoa.id === value.coaId)
        value.coaCode = coa.coaCode
        value.coaName = coa.coaName
        value.normalBalance = coa.normalBalance
        value.category = coa.category
        value.plbs = coa.plbs
        value.coaGroupId = coa.coaGroupId
        return value
      })
    } else {
      response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-positions/getByProdukId/${produkId}`)
      response = response.map(value => {
        const coa = dataCOA.find(valueCoa => valueCoa.id === value.coaId)
        value.coaCode = coa.coaCode
        value.coaName = coa.coaName
        value.normalBalance = coa.normalBalance
        value.category = coa.category
        value.plbs = coa.plbs
        value.coaGroupId = coa.coaGroupId
        return value
      })
    }

    //get saldo akhir bulan lalu
    var responseSaldoLastMonth = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(lastYearDate)}`)
    responseSaldoLastMonth = responseSaldoLastMonth.map(value => {
      const coa = dataCOA.find(valueCoa => valueCoa.id === value.coaId)
      value.coaCode = coa.coaCode
      value.coaName = coa.coaName
      value.normalBalance = coa.normalBalance
      value.category = coa.category
      value.plbs = coa.plbs
      value.coaGroupId = coa.coaGroupId
      return value
    })

    // responseSaldoLastMonth = responseSaldoLastMonth.filter(value => value.produkId === produkId)
    var dataAktivaLastMonth = responseSaldoLastMonth.filter(value => value.normalBalance === 'D' && value.plbs === 'b')
    var dataPasivaLastMonth = responseSaldoLastMonth.filter(value => value.normalBalance === 'C' && value.plbs === 'b')
    var dataRELastMonth = responseSaldoLastMonth.filter(value => value.plbs === 'p')

    var dataAktiva = response.filter(value => value.normalBalance === 'D' && value.plbs === 'b')
    dataAktiva = dataAktiva.map(value => {
      const dataAktivaLastMonthFound = dataAktivaLastMonth.find(valueLastMonth => valueLastMonth.coaId === value.coaId)
      if (dataAktivaLastMonthFound) {
        value.balanceAmountLastMonth = dataAktivaLastMonthFound.balanceAmount
        value.balanceAmountBaseCurrencyLastMonth = dataAktivaLastMonthFound.balanceAmountBaseCurrency
      } else {
        value.balanceAmountLastMonth = 0
        value.balanceAmountBaseCurrencyLastMonth = 0
      }

      return value
    })
    var dataPasiva = response.filter(value => value.normalBalance === 'C' && value.plbs === 'b')
    dataPasiva = dataPasiva.map(value => {
      const dataPasivaLastMonthFound = dataPasivaLastMonth.find(valueLastMonth => valueLastMonth.coaId === value.coaId)
      if (dataPasivaLastMonthFound) {
        value.balanceAmountLastMonth = dataPasivaLastMonthFound.balanceAmount
        value.balanceAmountBaseCurrencyLastMonth = dataPasivaLastMonthFound.balanceAmountBaseCurrency
      } else {
        value.balanceAmountLastMonth = 0
        value.balanceAmountBaseCurrencyLastMonth = 0
      }

      return value
    })

    var dataRE = response.filter(value => value.plbs === 'p')
    dataRELastMonth = dataRELastMonth.map(value => {
      const dataRELastMonthFound = dataRELastMonth.find(valueLastMonth => valueLastMonth.coaId === value.coaId)
      if (dataRELastMonthFound) {
        value.balanceAmountLastMonth = dataRELastMonthFound.balanceAmount
        value.balanceAmountBaseCurrencyLastMonth = dataRELastMonthFound.balanceAmountBaseCurrency
      } else {
        value.balanceAmountLastMonth = 0
        value.balanceAmountBaseCurrencyLastMonth = 0
      }

      return value
    })

    var dataRELastMonth = responseSaldoLastMonth.filter(value => value.plbs === 'p')
    if (dataRELastMonth.length > 0) {
      var dataRELastMonthAmount = dataRELastMonth.map(value => value.balanceAmount)
      var dataRELastMonthAmountBaseCurrency = dataRELastMonth.map(value => value.balanceAmountBaseCurrency)

      dataRELastMonthAmount = dataRELastMonthAmount.reduce((accumulator, currentValue) => accumulator + currentValue);
      dataRELastMonthAmountBaseCurrency = dataRELastMonthAmountBaseCurrency.reduce((accumulator, currentValue) => accumulator + currentValue);

      dataRELastMonth = {
        id: 0,
        balanceAmount: dataRELastMonthAmount,
        balanceAmountBaseCurrency: dataRELastMonthAmountBaseCurrency,
        coaId: coaRE.id,
        currencyId: 1001,
        coaCode: coaRE.coaCode,
        coaName: coaRE.coaName,
        normalBalance: coaRE.normalBalance,
        category: coaRE.category,
        plbs: coaRE.plbs,
        coaGroupId: coaRE.coaGroupId
      }
    }

    if (dataRE.length > 0) {
      var dataREAmount = dataRE.map(value => value.balanceAmount)
      var dataREAmountBaseCurrency = dataRE.map(value => value.balanceAmountBaseCurrency)

      dataREAmount = dataREAmount.reduce((accumulator, currentValue) => accumulator + currentValue);
      dataREAmountBaseCurrency = dataREAmountBaseCurrency.reduce((accumulator, currentValue) => accumulator + currentValue);

      dataRE = {
        id: 0,
        balanceAmount: dataREAmount,
        balanceAmountBaseCurrency: dataREAmountBaseCurrency,
        coaId: coaRE.id,
        currencyId: 1001,
        coaCode: coaRE.coaCode,
        coaName: coaRE.coaName,
        normalBalance: coaRE.normalBalance,
        category: coaRE.category,
        plbs: coaRE.plbs,
        coaGroupId: coaRE.coaGroupId
      }

      var index = dataPasiva.findIndex(value => value.coaId === dataRE.coaId)

      if (index !== -1) {
        dataPasiva[index].balanceAmount = dataPasiva[index].balanceAmount + dataRE.balanceAmount
        dataPasiva[index].balanceAmountBaseCurrency = dataPasiva[index].balanceAmountBaseCurrency + dataRE.balanceAmountBaseCurrency
        dataPasiva[index].balanceAmountLastMonth = dataPasiva[index].balanceAmountLastMonth + dataRELastMonth.balanceAmount
        dataPasiva[index].balanceAmountBaseCurrencyLastMonth = dataPasiva[index].balanceAmountBaseCurrencyLastMonth + dataRELastMonth.balanceAmountBaseCurrency
      } else {
        dataPasiva.push(dataRE)
      }
    }

    dataAktiva = dataAktiva.sort((a, b) => {
      if (a.coaCode < b.coaCode) {
        return -1;
      }
      if (a.coaCode > b.coaCode) {
        return 1;
      }
      return 0;
    })

    dataPasiva = dataPasiva.sort((a, b) => {
      if (a.coaCode < b.coaCode) {
        return -1;
      }
      if (a.coaCode > b.coaCode) {
        return 1;
      }
      return 0;
    })

    var datagridDatas = []

    this.dataAktiva = dataAktiva
    this.dataPasiva = dataPasiva

    for (var i = 0; i < Math.max(dataAktiva.length, dataPasiva.length); i++) {
      var datagridData = {
        id: uuidv4()
      }
      if (dataAktiva[i]) {
        datagridData.aktivaCoaId = dataAktiva[i].coaId
        datagridData.aktivaCoaCode = dataAktiva[i].coaCode
        datagridData.aktivaCoaName = dataAktiva[i].coaName
        datagridData.aktivaBalanceAmountLastMonth = (dataAktiva[i].balanceAmountLastMonth)
        datagridData.aktivaBalanceAmountBaseCurrencyLastMonth = (dataAktiva[i].balanceAmountBaseCurrencyLastMonth)
        datagridData.aktivaBalanceAmount = (dataAktiva[i].balanceAmount)
        datagridData.aktivaBalanceAmountBaseCurrency = (dataAktiva[i].balanceAmountBaseCurrency)
      }
      if (dataPasiva[i]) {
        datagridData.pasivaCoaId = dataPasiva[i].coaId
        datagridData.pasivaCoaCode = dataPasiva[i].coaCode
        datagridData.pasivaCoaName = dataPasiva[i].coaName
        datagridData.pasivaBalanceAmountLastMonth = (dataPasiva[i].balanceAmountLastMonth) * -1
        datagridData.pasivaBalanceAmountBaseCurrencyLastMonth = (dataPasiva[i].balanceAmountBaseCurrencyLastMonth) * -1
        // datagridData.pasivaBalanceAmountLastMonth = (dataPasiva[i].balanceAmountLastMonth)
        // datagridData.pasivaBalanceAmountBaseCurrencyLastMonth = (dataPasiva[i].balanceAmountBaseCurrencyLastMonth)
        datagridData.pasivaBalanceAmount = (dataPasiva[i].balanceAmount) * -1
        datagridData.pasivaBalanceAmountBaseCurrency = (dataPasiva[i].balanceAmountBaseCurrency) * -1
      }

      datagridDatas.push(datagridData)
    }

    return datagridDatas
  }

  print = async () => {
    this.props.store.dispatch(showLoading(true))

    var dataReportHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/LK')
    dataReportHeader = dataReportHeader.find(value => value.reportCode === 'NRC')
    var dataMapping = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataReportHeader.id}`)

    var dataMappingAktiva = dataMapping.filter(value => value.seqNo < 20000)
    if (dataMappingAktiva.length > 0) {
      dataMappingAktiva = dataMappingAktiva.sort((a, b) => {
        if (a.seqNo > b.seqNo) {
          return 1
        }
        if (a.seqNo === b.seqNo) {
          return 0
        }
        if (a.seqNo < b.seqNo) {
          return -1
        }
      })
    }
    var dataMappingPasiva = dataMapping.filter(value => value.seqNo >= 20000)
    if (dataMappingPasiva.length > 0) {
      dataMappingPasiva = dataMappingPasiva.sort((a, b) => {
        if (a.seqNo > b.seqNo) {
          return 1
        }
        if (a.seqNo === b.seqNo) {
          return 0
        }
        if (a.seqNo < b.seqNo) {
          return -1
        }
      })
    }

    var grandParentsAktiva = dataMappingAktiva.filter(value => value.parentId === null || value.parentId === undefined)
    var grandParentsPasiva = dataMappingPasiva.filter(value => value.parentId === null || value.parentId === undefined)

    var dataAktiva = [], dataPasiva = []

    const loopData = async (dataMap, dataParent, dataBalance, dataset, level = 0) => {
      var balanceAmount = 0, balanceAmountLastMonth = 0

      var mapChild = dataMap.filter(value => value.parentId === dataParent.id)
      if (mapChild.length > 0) {
        dataset.push({
          coaCode: null,
          coaName: dataParent.description,
          level: level,
        })
        for (const child of mapChild) {
          let res = await loopData(dataMap, child, dataBalance, dataset, level + 1)
          balanceAmount = balanceAmount + res.balanceAmount
          balanceAmountLastMonth = balanceAmountLastMonth + res.balanceAmountLastMonth
        }
        dataset.push({
          coaCode: dataParent.seqNo,
          coaName: 'Total ' + dataParent.description,
          level: level,
          balanceAmount: balanceAmount,
          balanceAmountLastMonth: balanceAmountLastMonth
        })
      } else {
        var dataMappingCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${dataParent.id}`)
        for (const mappingCoa of dataMappingCoa) {
          const coa = dataBalance.find(value => value.coaId === mappingCoa.coaId)
          if (coa) {
            balanceAmount = balanceAmount + (coa.balanceAmount * (mappingCoa.type === 'n' ? -1 : 1))
            balanceAmountLastMonth = balanceAmountLastMonth + (coa.balanceAmountLastMonth * (mappingCoa.type === 'n' ? -1 : 1))
          }
        }
        dataset.push({
          coaCode: dataParent.seqNo,
          coaName: dataParent.description,
          level: level,
          balanceAmount: balanceAmount,
          balanceAmountLastMonth: balanceAmountLastMonth
        })
      }

      return {
        balanceAmount: balanceAmount,
        balanceAmountLastMonth: balanceAmountLastMonth
      }
    }

    for (const grandParentAktiva of grandParentsAktiva) {
      await loopData(dataMappingAktiva, grandParentAktiva, this.dataAktiva, dataAktiva)
    }

    for (const grandParentPasiva of grandParentsPasiva) {
      await loopData(dataMappingPasiva, grandParentPasiva, this.dataPasiva, dataPasiva)
    }

    var result = []
    for (var i = 0; i < Math.max(dataAktiva.length, dataPasiva.length); i++) {
      var resultData = {
      }
      if (dataAktiva[i]) {
        resultData.aktivaCoaCode = dataAktiva[i].coaCode
        resultData.aktivaCoaName = dataAktiva[i].coaName
        resultData.aktivaBalanceAmountLastMonth = (dataAktiva[i].balanceAmountLastMonth)
        // resultData.aktivaBalanceAmountBaseCurrencyLastMonth = (dataAktiva[i].balanceAmountBaseCurrencyLastMonth)
        resultData.aktivaBalanceAmount = (dataAktiva[i].balanceAmount)
        // resultData.aktivaBalanceAmountBaseCurrency = (dataAktiva[i].balanceAmountBaseCurrency)
        resultData.aktivaLevel = dataAktiva[i].level
      }
      if (dataPasiva[i]) {
        resultData.pasivaCoaCode = dataPasiva[i].coaCode
        resultData.pasivaCoaName = dataPasiva[i].coaName
        resultData.pasivaBalanceAmountLastMonth = (dataPasiva[i].balanceAmountLastMonth)
        // resultData.pasivaBalanceAmountBaseCurrencyLastMonth = (dataPasiva[i].balanceAmountBaseCurrencyLastMonth)
        resultData.pasivaBalanceAmount = (dataPasiva[i].balanceAmount)
        resultData.pasivaLevel = dataPasiva[i].level
        // resultData.pasivaBalanceAmountBaseCurrency = (dataPasiva[i].balanceAmountBaseCurrency)  * -1
      }

      result.push(resultData)
    }

    var period = formatDefaultFullDate(this.state.formFilter.tanggal)
    var prevPeriod = formatDefaultFullDate(this.lastYearDate)
    period = period.split(' ')
    prevPeriod = prevPeriod.split(' ')
    period = period[1] + ' ' + period[2]
    prevPeriod = prevPeriod[1] + ' ' + prevPeriod[2]

    reportFile({
      template: { 'shortid': 'rkeH9TtK3O' },
      data: {
        period: period,
        prevPeriod: prevPeriod,
        dataBalanceSheet: result
      },
      options: {
        reportName: `Neraca ${period}`
      }
    }, true, `Neraca ${period}`)

    this.props.store.dispatch(showLoading(false))
  }

  printOJK = async (produkId, tanggal) => {
    await this.loadData(produkId, tanggal)
    var dataReportHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/OJK')
    dataReportHeader = dataReportHeader.find(value => value.reportCode === 'NER')
    var dataMapping = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataReportHeader.id}`)

    var dataMappingAktiva = dataMapping.filter(value => value.seqNo < 77)
    if (dataMappingAktiva.length > 0) {
      dataMappingAktiva = dataMappingAktiva.sort((a, b) => {
        if (a.seqNo > b.seqNo) {
          return 1
        }
        if (a.seqNo === b.seqNo) {
          return 0
        }
        if (a.seqNo < b.seqNo) {
          return -1
        }
      })
    }
    var dataMappingPasiva = dataMapping.filter(value => value.seqNo >= 77)
    if (dataMappingPasiva.length > 0) {
      dataMappingPasiva = dataMappingPasiva.sort((a, b) => {
        if (a.seqNo > b.seqNo) {
          return 1
        }
        if (a.seqNo === b.seqNo) {
          return 0
        }
        if (a.seqNo < b.seqNo) {
          return -1
        }
      })
    }

    var grandParentsAktiva = dataMappingAktiva.filter(value => value.parentId === null || value.parentId === undefined)
    var grandParentsPasiva = dataMappingPasiva.filter(value => value.parentId === null || value.parentId === undefined)

    var dataAktiva = [], dataPasiva = []

    const loopData = async (dataMap, dataParent, dataBalance, dataset, level = 0) => {
      var balanceAmount = 0, balanceAmountLastMonth = 0

      var mapChild = dataMap.filter(value => value.parentId === dataParent.id)
      if (mapChild.length > 0) {
        dataset.push({
          coaCode: null,
          coaName: dataParent.description,
          level: level,
          reff: dataParent.seqNo
        })
        for (const child of mapChild) {
          let res = await loopData(dataMap, child, dataBalance, dataset, level + 1)
          balanceAmount = balanceAmount + res.balanceAmount
          balanceAmountLastMonth = balanceAmountLastMonth + res.balanceAmountLastMonth
        }
        dataset.push({
          coaCode: dataParent.seqNo,
          coaName: 'Total ' + dataParent.description,
          level: level,
          reff: dataParent.seqNo,
          balanceAmount: balanceAmount,
          balanceAmountLastMonth: balanceAmountLastMonth
        })
      } else {
        var dataMappingCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${dataParent.id}`)
        for (const mappingCoa of dataMappingCoa) {
          const coa = dataBalance.find(value => value.coaId === mappingCoa.coaId)
          if (coa) {
            balanceAmount = balanceAmount + (coa.balanceAmount * (mappingCoa.type === 'n' ? -1 : 1))
            balanceAmountLastMonth = balanceAmountLastMonth + (coa.balanceAmountLastMonth * (mappingCoa.type === 'n' ? -1 : 1))
          }
        }
        dataset.push({
          coaCode: dataParent.seqNo,
          coaName: dataParent.description,
          level: level,
          reff: dataParent.seqNo,
          balanceAmount: balanceAmount,
          balanceAmountLastMonth: balanceAmountLastMonth
        })
      }

      return {
        balanceAmount: balanceAmount,
        balanceAmountLastMonth: balanceAmountLastMonth
      }
    }

    for (const grandParentAktiva of grandParentsAktiva) {
      await loopData(dataMappingAktiva, grandParentAktiva, this.dataAktiva, dataAktiva)
    }

    for (const grandParentPasiva of grandParentsPasiva) {
      await loopData(dataMappingPasiva, grandParentPasiva, this.dataPasiva, dataPasiva)
    }

    return [
      ...dataAktiva,
      ...dataPasiva
    ]
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'print',
          onClick: (e) => {
            this.print()
          },
        }
      }
    )
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Neraca</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef} 
          loadAPI='trial-balances'
          insertAPI='trial-balances'
          updateAPI='trial-balances'
          deleteAPI='trial-balances'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          useArraySource={true}
          ArraySourceData={this.loadData}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={`Neraca ${this.getDayMonthYear(this.state.tanggal)}`}
          allowExportSelectedData={false}
          selection={"none"}

          exportWithFormatNum={true}
          exceptFieldExportFormtNum={
              [
                  'aktivaBalanceAmount',
                  'aktivaBalanceAmountBaseCurrency',
                  'aktivaBalanceAmountLastMonth',
                  'aktivaBalanceAmountBaseCurrencyLastMonth',
                  'pasivaBalanceAmount',
                  'pasivaBalanceAmountBaseCurrency',
                  'pasivaBalanceAmountLastMonth',
                  'pasivaBalanceAmountBaseCurrencyLastMonth'
              ]
          }

          showBorders={true}

          paging={false}
          showPageSizeSelector = {false}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Balance Sheet'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={false} // set false agar kolom tidak dapat di freeze

          FilterRow={false} // set false untuk mematikan fitur filter

          height={'calc(100vh - 250px)'}

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          summaryTotalItem={this.sumDebit}
          sortingMode={'none'}
          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        {/* <span style={{ fontSize: '14px' }}>* Data per tanggal {this.state.tanggal ? formatDefaultDate(this.state.tanggal) : ''}</span>   */}
      </div>
    )
  }
}

export default BalanceSheet