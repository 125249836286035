import React, { Component } from 'react'
import { currency, banks, instruments, orders, instrumentsCategoryReksadana, product } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormPengajuanPelepasanReksadana from 'components/pages/modal/investasi/pengajuan/reksadana/pelepasan/index'
import FormPersetujuanPelepasanReksadana from 'components/pages/modal/investasi/pengajuan/reksadana/pelepasan/persetujuan'
import DetailPelepasanReksadana from './detail'
import httpRequest from 'plugin/httprequest'
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify'
import { formatDefaultFullDate, formatNumber, formatNumberAum, inputToTaskInvestasi, numberToIndo, PrintElem, stripHtml, summaryValue } from 'plugin/helper'
import reportRequest from 'plugin/reportRequest'

class PengajuanPelepasanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridOrderReksadanaRef = React.createRef()
        this.formPengajuanPelepasanReksadanaRef = React.createRef()
        this.formPengajuanPelepasanReksadanaEditRef = React.createRef()
        this.PesetujuanPelepasanReksadana = React.createRef()

        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: async (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                this.showModalEdit(data, 'edit')
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Cetak',
                        hint: 'Cetak',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Belum terkirim!',
                                    message: 'Data belum terkirim tidak bisa cetak surat!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakSurat(data)
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Batalkan draft',
                                    message: 'Apakah anda yakin ingin membatalkan draft ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'requestNo',
                caption: 'No Pengajuan',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.requestNo == null) {
                        return 'DRAFT'
                    } else {
                        return data.requestNo
                    }
                }
            },
            {
                dataField: 'requestDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'orderDate',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'productCode',
                caption: 'Produk',
            },
            {
                dataField: 'categoryName',
                caption: 'Kategori',
            },
            {
                dataField: 'totalInstrument',
                caption: 'Jumlah Instrumen',
                alignment: 'right',
                dataType: 'number',
            },
            {
                dataField: 'totalMi',
                caption: 'Jumlah Manager Investasi',
                alignment: 'right',
                dataType: 'number',
            },
            {
                dataField: 'totalAmountOrder',
                caption: 'Nilai Pelepasan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'DRAFT',
                            value: 'Tersimpan'
                        },
                        {
                            id: 'INPROGRESS',
                            value: 'Dalam proses persetujuan'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.currentData = []
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var loadAPI = 'instrument-category-subs/reksadana'
        var getSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')

        this.subCategory = getSubCategory
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Pelepasan',
                    onClick: (e) => {
                        this.showModalInsert('add')
                    },
                }
            }
        )
    }
    showModalInsert = (type) => {
        if(type === 'add') {
            this.formPengajuanPelepasanReksadanaRef.current.show()
            this.formPengajuanPelepasanReksadanaRef.current.retrieveData()
        }
    }

    kirimData = async (data) => {
        var loadAPI = `order-requests-rd-dtls/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = dataMaster.kajian
        var content = dataMaster.kajianContentType
        var dataDetail = []
        data = {
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalPembelian: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            requestOrderId: dataMaster.id,
            noPengajuan: '<AUTO>',
            kajian: kajian,
            kajianContentType: content,
            instrument: 'Reksadana',
            draft: false,
        }
        for (var reksadana of response.orderRdInfos) {
            var reksadanaInfo = {
                id: reksadana.orderRdDTO.id,
                instrumentId: reksadana.orderRdDTO.instrumentId,
                aperdId: reksadana.orderRdDTO.aperdId,
                kpdId: reksadana.orderDTO.kpdId,
                amountVal: reksadana.orderRdDTO.amountOrder,
                miId: reksadana.orderRdDTO.miId,
                instSubCategoryId: reksadana.orderDTO.categorySubId,
                tanggalPenjualan: reksadana.orderDTO.orderDate,
                unit: reksadana.orderRdDTO.unitOrder,
                redeemUnitType: reksadana.orderRdDTO.valuePercent == false ? 1 : 0,
            }
            dataDetail.push(reksadanaInfo)
        }
        var submitData = {
            ...data,
            instrumentRdList: dataDetail
        }

        try {
            let orderRequestId = response.orderRdInfos[0].orderDTO.orderRequestId
            let orderTypeId = response.orderRdInfos[0].orderDTO.orderTypeId

            await inputToTaskInvestasi(this.props.store, orderRequestId, orderTypeId)
        } catch (error) {
            console.log(error);
        }

        if (submitData) {
            try {
                var insertAPI = 'order-rds/pengajuan-penjualan-rd'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Pengiriman berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    cetakSurat = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-rd-dtls/${data.requestId}`, 'GET')
        let dirKeuangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/6`, 'GET')
        let dirOperasional = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/79506`, 'GET')
        let dirut = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5`, 'GET')
        let komite = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5351`, 'GET')
        let dataMaster = response.orderRequestDTO
        let instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${response.orderRdInfos[0].orderDTO.instrumentId}`, 'GET')
        let miName = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `manager-investasis/${response.orderRdInfos[0].orderRdDTO.miId}`, 'GET')
        let transaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-transaction-types/${response.orderRdInfos[0].orderDTO.orderTypeId}`, 'GET')
        let kajian = stripHtml(atob(response.orderRequestDTO.kajian)) == 'undefined' ? ' ' : stripHtml(atob(response.orderRequestDTO.kajian))

        if (dirKeuangan.length > 0) {
            dirKeuangan = dirKeuangan[0]
        } else {
            dirKeuangan = {}
        }

        if (dirOperasional.length > 0) {
            dirOperasional = dirOperasional[0]
        } else {
            dirOperasional = {}
        }

        if (dirut.length > 0) {
            dirut = dirut[0]
        } else {
            dirut = {}
        }

        if (komite.length > 0) {
            komite = komite[0]
        } else {
            komite = {}
        }

        var dataRd = []
        var no = 0
        for (var reksadana of response.orderRdInfos) {
            var rdInfo = {
                no: ++no,
                categoryName: this.subCategory.find(val => val.id == reksadana.orderDTO.categorySubId).subName,
                instrumentName: reksadana.instrumentDTO.instrumentName,
                amountOrder: reksadana.orderRdDTO.amountOrder
            }
            dataRd.push(rdInfo)
        }

        var data = {
            template: {
                'shortid': 'Of7ycuR',
                'recipe': 'html'
            },
            data: {
                "requestNo": data.requestNo,
                "orderDate": formatDefaultFullDate(dataMaster.orderDate),
                "transactionName": transaction.transactionName,
                "miName": miName.miName,
                "instrument": instrument.instrumentName,
                "dataReksadana": dataRd,
                "totalAmountOrder": summaryValue(dataRd, 'amountOrder'),
                "kajian": kajian,
                "dirKeuangan": {
                    "fullName": dirKeuangan.fullName,
                    "jabatan": 'Direktur Keuangan'
                },
                "dirOperasional": {
                    "fullName": dirOperasional.fullName,
                    "jabatan": 'Direktur Operasional'
                },
                "dirut": {
                    "fullName": dirut.fullName,
                    "jabatan": 'Direktur Utama'
                },
                "dirInvest": {
                    "fullName": komite.fullName,
                    "jabatan": 'Direktur Investasi'
                }
            },
            options: {
                reportName: "UPSI-" + data.requestNo
            }
        }

        var print = await reportRequest(this.props.store, data)
        PrintElem(print.data)
        return print
    }

    cancelData = async (data) => {
        var reqId = data.requestId
        if (reqId) {
            try {
                var insertAPI = `order-request-batal?orderRequestId=${reqId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: {}
                })
                notify({ message: 'Pembatalan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            }
        }
    }
    showModalEdit = async (data, type) => {
        var loadAPI = `order-requests-rd-dtls/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = atob(dataMaster.kajian)
        var dataDetail = []
        data = {
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalPenjualan: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            orderTypeId: dataMaster.orderTypeId,
            requestId: dataMaster.id,
            noPengajuan: '<AUTO>',
            instrument: 'Reksadana',
            jenisTransaksi: 'Pelepasan',
            actionType: 'edit'
        }
        for (var reksadana of response.orderRdInfos) {
            var reksadanaInfo = {
                id: reksadana.orderRdDTO.id,
                instrumentId: reksadana.orderRdDTO.instrumentId,
                productId: reksadana.orderDTO.productId,
                aperdId: reksadana.orderRdDTO.aperdId,
                currencyId: reksadana.instrumentDTO.currencyId,
                unitOrder: reksadana.orderRdDTO.unitOrder,
                // kurs: reksadana.orderRdDTO.kurs,
                nabTerakhir: reksadana.instrumentDTO.price,
                amountOrder: reksadana.orderRdDTO.amountOrder,
                miId: reksadana.orderRdDTO.miId,
                instSubCategoryId: reksadana.orderDTO.categorySubId,
                portofolioPelepasan: reksadana.orderRdDTO.valueAll == false ? 'Sebagian' : 'Semua',
                unitPersen: reksadana.orderRdDTO.unitPersen,
                redeemUnitType: reksadana.orderRdDTO.valuePercent == false ? 1 : 0,
                amountVal: reksadana.orderRdDTO.amountOrder,
                // feePercent: reksadana.orderRdDTO.feePercent,
                // fee: reksadana.orderRdDTO.fee,
                // totalPenempatan: reksadana.orderRdDTO.totalPenempatan,
                tanggalPembelian: reksadana.orderDTO.orderDate,
                rekMiId: reksadana.orderRdDTO.sourceRekMiId
            }
            dataDetail.push(reksadanaInfo)
        }
        if(type === 'edit') {
            this.formPengajuanPelepasanReksadanaEditRef.current.show()
            this.formPengajuanPelepasanReksadanaEditRef.current.retrieveData(data, dataDetail, kajian)
        }
    }

    showModalView = (data) => {
        var dataMaster = { ...data }
        var dataPembelian = dataMaster.detail
        delete dataMaster.detail

        this.formPengajuanPelepasanReksadanaRef.current.show(dataMaster, dataPembelian)
    }
    customDetailElem = (data) => {
        return (
            <DetailPelepasanReksadana detailData={data.requestId} store={this.props.store} />
        )
    }
    forceRefresh = () => {
        this.DataGridOrderReksadanaRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengajuan Pelepasan Reksadana</h2>
                <DevExpressDataGrid
                    keyField='requestId'
                    ref={this.DataGridOrderReksadanaRef}
                    loadAPI='order-requests-rd-penjualan/list?status=DRAFT&status=INPROGRESS'
                    insertAPI='order-stoks'
                    updateAPI='order-stoks'
                    deleteAPI='order-stoks'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Order Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Order Reksadana'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPelepasanReksadana store={this.props.store} actionType={'add'} ref={this.formPengajuanPelepasanReksadanaRef} forceRefresh={this.forceRefresh} />
                <FormPengajuanPelepasanReksadana store={this.props.store} actionType={'edit'} ref={this.formPengajuanPelepasanReksadanaEditRef} forceRefresh={this.forceRefresh} />
                <FormPersetujuanPelepasanReksadana store={this.props.store} ref={this.PesetujuanPelepasanReksadana} />
            </div>
        )
    }
}

export default PengajuanPelepasanReksadana
