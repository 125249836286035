import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { kecamatan } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import notify from 'devextreme/ui/notify';
import { formatNumberAum } from 'plugin/helper'
import { Form } from "devextreme-react";
import { karyawan, periodeCuti } from 'dataSource/lookup'

class LaporanResumeCuti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formFilter: {}
        }
    
        this.dataGridRef = React.createRef()

        this.filterItem = [
            {
                dataField: "periodeId",
                label: {
                    text: "Periode",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: periodeCuti(this.props.store),
                    displayExpr: function (item) {
                        return item && item.namaPeriodeCuti;
                    },
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
            {
                itemType: 'empty'
            }
        ]

        this.columns = [
            {
                dataField: 'no',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'namaKaryawan',
                caption: 'Nama Karyawan',
            },
            {
                dataField: 'departement',
                caption: 'Unit Kerja',
            },
            {
                dataField: 'posisi',
                caption: 'Jabatan',
            },
            {
                dataField: 'sisaCutiSebelumnya',
                caption: 'Sisa Cuti Periode Sebelumnya',
                alignment: 'right'
            },
            {
                dataField: 'periodeSaatIni',
                caption: 'Periode Saat Ini',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'jatahCutiTahunan',
                        caption: 'Jatah Cuti',
                        alignment: 'right'
                    },
                    {
                        dataField: 'totalJatahCuti',
                        caption: 'Total Jatah Cuti',
                        alignment: 'right'
                    },
                    {
                        dataField: 'cutiTerpakai',
                        caption: 'Cuti Terpakai',
                        alignment: 'right'
                    },
                    {
                        dataField: 'cutiTersedia',
                        caption: 'Sisa Cuti',
                        alignment: 'right'
                    },
                    {
                        dataField: 'cutiBatal',
                        caption: 'Panggilan Dinas',
                        alignment: 'right'
                    },
                ]
            }
        ]
        this.selectedRowsData = [];
        this.result = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    // onSelectionChanged = (e) => {
    //     this.selectedRowsData = e.selectedRowsData
    // }

    filterSubmit = async () => {
        let dataState, loadAPI, response
        dataState = this.state.formFilter

        loadAPI = `karyawan-cutis/resume-periode/${dataState.periodeId}`
        response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')
        let index = 1

        var dataResult = []
        for(var data of response){
            var result = {
                no: index++,
                karyawanId: data.karyawanId,
                jumlahSebelumnya: data.jumlahSebelumnya,
                noUrut: data.noUrut,
                namaKaryawan: data.namaKaryawan,
                departement: data.departement,
                posisi: data.posisi,
                sisaCutiSebelumnya: data.sisaCutiSebelumnya,
                jatahCutiTahunan: data.jatahCutiTahunan,
                totalJatahCuti: data.totalJatahCuti,
                cutiTerpakai: data.cutiTerpakai,
                cutiTersedia: data.cutiTersedia,
                cutiBatal: data.cutiBatal
            }

            dataResult.push(result)
        }

        this.result = dataResult
        this.dataGridRef.current.forceRefresh(true)
    }

    // exportExcel = async () => {
        // let data = [];
        // const firstTableRowNum = 8;
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'view-report-karyawan-cutis', 'GET');

        // let response = this.selectedRowsData;
        
        // for (let value of response){
        //     let dataValue = {
        //         nik: value.nik,
        //         fullName: value.fullName,
        //         jabatanName: value.jabatanName ? value.jabatanName : '-',
        //         unitName: value.unitName ? value.unitName : '-',
        //         cutiName: value.cutiName,
        //         namaPeriodeCuti: value.namaPeriodeCuti,
        //         jatah: value.jatah,
        //         jumHari: value.jumHari,
        //         sisaHari: value.sisaHari,
        //         uangCuti: value.uangCuti === true ? 'Dapat Tunjangan' : '-',
        //         pembatalan: value.pembatalan === true ? 'Dibatalkan' : value.pembatalan === false ? '-' : '-',
        //     }
        //     const objectValue = Object.values(dataValue);
        //     data.push(objectValue);
        // }
        // try {
        //     const workbook = new Excel.Workbook();
        //     var worksheet = workbook.addWorksheet('DAFTAR KARYAWAN');
        //     worksheet.addTable({
        //         name: 'MyTable',
        //         ref: `A${firstTableRowNum}`,
        //         headerRow: true,
        //         totalsRow: false,
        //         style: {
        //         theme: 'TableStyleLight15',
        //         showFirstColumn: true,
        //         // border: {
        //         //     top: {style:'thin'},
        //         //     left: {style:'thin'},
        //         //     bottom: {style:'thin'},
        //         //     right: {style:'thin'}
        //         // }
        //       },
        //       columns: [
        //         {name: 'NIP'},
        //         {name: 'Nama'},
        //         {name: 'Jabatan'},
        //         {name: 'Unit'},
        //         {name: 'Tipe'},
        //         {name: 'Periode Cuti'},
        //         {name: 'Jatah Cuti'},
        //         {name: 'Cuti Terpakai'},
        //         {name: 'Sisa Cuti'},
        //         {name: 'Uang Cuti'},
        //         {name: 'Pembatalan'},
        //       ],
        //       rows: data,
        //     });

        //     let lastRowNum = worksheet.lastRow.number;
        //     const lastTableRowNum = lastRowNum;

        //     for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
        //         const row = worksheet.getRow(i);
        //         row.eachCell({includeEmpty: true}, (cell => {    
        //             cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
        //         }))
        //     }

        //     for (let i = 9; i <= 10000; i++){
        //         // worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //         worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        //     }

        //     worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
        //     worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
        //     worksheet.getCell('E2').font = {size: 18, bold: true}
            
        //     worksheet.getCell('E3').value = 'DAFTAR KARYAWAN CUTI';
        //     worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
        //     worksheet.getCell('E3').font = {size: 18, bold: true}

        //     worksheet.getColumn('J').alignment = { vertical: 'top', horizontal: 'center'};

        //     var WidthColums = 20;
        //     var WidthColums2 = 35;

        //     const Data1 = worksheet.getColumn(1);
        //     Data1.width = WidthColums;

        //     const Data2 = worksheet.getColumn(2);
        //     Data2.width = WidthColums2; 

        //     const Data3 = worksheet.getColumn(3);
        //     Data3.width = WidthColums2;

        //     const Data4 = worksheet.getColumn(4);
        //     Data4.width = WidthColums;

        //     const Data5 = worksheet.getColumn(5);
        //     Data5.width = WidthColums;

        //     const Data6 = worksheet.getColumn(6);
        //     Data6.width = WidthColums;

        //     const Data7 = worksheet.getColumn(7);
        //     Data7.width = WidthColums;

        //     const Data8 = worksheet.getColumn(8);
        //     Data8.width = WidthColums;

        //     const Data9 = worksheet.getColumn(9);
        //     Data9.width = WidthColums;

        //     const Data10 = worksheet.getColumn(10);
        //     Data10.width = WidthColums;

        //     const Data11 = worksheet.getColumn(11);
        //     Data11.width = WidthColums;

        //     await workbook.xlsx.writeBuffer().then(function(buffer) {
        //         saveAs(
        //             new Blob([buffer], { type: "application/octet-stream" }),
        //             `DAFTAR_KARYAWAN_CUTI.xlsx`
        //         );
        //     });
        // } catch (error) {
        //     console.log(error);
        // }
    // }
    
    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'download',
    //                 hint: 'Export',
    //                 onClick: (e) => {
    //                     if (this.selectedRowsData.length > 0) {
    //                         this.exportExcel()
    //                     } else {
    //                         notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    //                     }
    //                 },
    //             }
    //         }
    //     ) 
    // }

    loadData = () => {
        return this.result
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Resume Karyawan Cuti</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='-'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    keyField={'noUrut'}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Report Karyawan Cuti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Karyawan Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(-251px + 100vh)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    // onToolbarPreparing={this.onToolbarPreparing}
                    // onSelectionChanged={this.onSelectionChanged}

                    remoteOperations={false}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanResumeCuti