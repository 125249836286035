import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { productDefault } from 'dataSource/lookup';


class ModalPortfolioRebalance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            actionType: null
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridKategoriRef = React.createRef()
        this.dataGridEmitemRef = React.createRef()
        this.dataGridSektorRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: console.log('simpan')
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                'id': 0,
                text: 'Kategori Aset'
            },
            {
                'id': 1,
                text: 'Emiten'
            },
            {
                'id': 2,
                text: 'Sektor'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode', // provides display values
                },
            },
            {
                dataField: 'kpd',
                label: {
                    alignment: 'left',
                    text: 'KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kpdName', // provides display values
                },
            },
        ]

        this.kategoriColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Buka',
                        hint : 'Buka',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            // this.props.showModalActionKategoriAsetAlokasi('edit', e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No'
            },
            {
                dataField: 'kategori',
                caption: 'Kategori'
            },
            {
                dataField: 'subKategori',
                caption: 'Sub Kategori'
            },
            {
                dataField: 'alokasi',
                caption: 'Alokasi',
                alignment:'center',
                columns: [
                    {
                        dataField: 'presentaseAlokasiKategori',
                        caption: '(%)'
                    },
                    {
                        dataField: 'nominalAlokasiKategori',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                dataField: 'nilaiSaatIni',
                caption: 'Nilai Saat Ini',
                alignment:'center',
                columns: [
                    {
                        dataField: 'presentaseNilaiKategori',
                        caption: '(%)'
                    },
                    {
                        dataField: 'nominalNilaiKategori',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                dataField: 'deviasi',
                caption: 'Deviasi',
                alignment:'center',
                columns: [
                    {
                        dataField: 'presentaseDeviasiKategori',
                        caption: '(%)'
                    },
                    {
                        dataField: 'nominalDeviasiKategori',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
        ]

        this.emitemColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Buka',
                        hint : 'Buka',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            // this.props.showModalActionKategoriAsetAlokasi('edit', e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No'
            },
            {
                dataField: 'emiten',
                caption: 'Emiten'
            },
            {
                dataField: 'alokasi',
                caption: 'Alokasi',
                alignment:'center',
                columns: [
                    {
                        dataField: 'min',
                        caption: 'Min (%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'max',
                        caption: 'Max (%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'nilaiSaatIni',
                caption: 'Nilai Saat Ini',
                alignment:'center',
                columns: [
                    {
                        dataField: 'saham',
                        caption: 'Saham (%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'sahamNominal',
                        caption: 'Saham Nominal',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'bond',
                        caption: 'Bond (%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'bondNominal',
                        caption: 'Bond Nominal',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'total',
                        caption: 'Total (%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'totalNominal',
                        caption: 'Total Nominal',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'deviasi',
                caption: 'Deviasi',
                alignment:'center',
                columns: [
                    {
                        dataField: 'persenDeviasi',
                        caption: '(%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'nominal',
                        caption: 'Nominal',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
        ]

        this.sektorColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Buka',
                        hint : 'Buka',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            // this.props.showModalActionKategoriAsetAlokasi('edit', e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No'
            },
            {
                dataField: 'kategori',
                caption: 'Kategori'
            },
            {
                dataField: 'alokasi',
                caption: 'Alokasi',
                alignment:'center',
                columns: [
                    {
                        dataField: 'presentaseAlokasiSektor',
                        caption: '(%)'
                    },
                    {
                        dataField: 'nominalAlokasiSektor',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                dataField: 'nilaiSaatIni',
                caption: 'Nilai Saat Ini',
                alignment:'center',
                columns: [
                    {
                        dataField: 'presentaseNilaiSektor',
                        caption: '(%)'
                    },
                    {
                        dataField: 'nominalNilaiSektor',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                dataField: 'deviasi',
                caption: 'Deviasi',
                alignment:'center',
                columns: [
                    {
                        dataField: 'presentaseDeviasiSektor',
                        caption: '(%)'
                    },
                    {
                        dataField: 'nominalDeviasiSektor',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
        ]

        this.sumKategoriAset = [
            {
                showInColumn: 'kategori',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'presentaseAlokasiKategori',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'presentaseAlokasiKategori',
                displayFormat: '{0}'
            },
            {
                column: 'nominalAlokasiKategori',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalAlokasiKategori',
                displayFormat: '{0}'
            },
            {
                column: 'presentaseNilaiKategori',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'presentaseNilaiKategori',
                displayFormat: '{0}'
            },
            {
                column: 'nominalNilaiKategori',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalNilaiKategori',
                displayFormat: '{0}'
            },
            {
                column: 'presentaseDeviasiKategori',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'presentaseDeviasiKategori',
                displayFormat: '{0}'
            },
            {
                column: 'nominalDeviasiKategori',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalDeviasiKategori',
                displayFormat: '{0}'
            },
        ]
        this.sumSektor = [
            {
                showInColumn: 'kategori',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'presentaseAlokasiSektor',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'presentaseAlokasiSektor',
                displayFormat: '{0}'
            },
            {
                column: 'nominalAlokasiSektor',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalAlokasiSektor',
                displayFormat: '{0}'
            },
            {
                column: 'presentaseNilaiSektor',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'presentaseNilaiSektor',
                displayFormat: '{0}'
            },
            {
                column: 'nominalNilaiSektor',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalNilaiSektor',
                displayFormat: '{0}'
            },
            {
                column: 'presentaseDeviasiSektor',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'presentaseDeviasiSektor',
                displayFormat: '{0}'
            },
            {
                column: 'nominalDeviasiSektor',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalDeviasiSektor',
                displayFormat: '{0}'
            },
        ]
        this.sumEmiten = [
            {
                showInColumn: 'emiten',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'min',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'min',
                displayFormat: '{0}'
            },
            {
                column: 'max',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'max',
                displayFormat: '{0}'
            },
            {
                column: 'saham',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'saham',
                displayFormat: '{0}'
            },
            {
                column: 'sahamNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sahamNominal',
                displayFormat: '{0}'
            },
            {
                column: 'bond',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bond',
                displayFormat: '{0}'
            },
            {
                column: 'bondNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bondNominal',
                displayFormat: '{0}'
            },
            {
                column: 'total',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'total',
                displayFormat: '{0}'
            },
            {
                column: 'totalNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'totalNominal',
                displayFormat: '{0}'
            },
            {
                column: 'persenDeviasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'persenDeviasi',
                displayFormat: '{0}'
            },
            {
                column: 'nominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominal',
                displayFormat: '{0}'
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async(type,data) => {    
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    onClick: (e) => {
                        this.dataGridKategoriRef.current.forceRefresh(true)
                        this.dataGridEmitemRef.current.forceRefresh(true)
                        this.dataGridSektorRef.current.forceRefresh(true)
                    }
                }
            }
        )
    }

    dummyKategori = () => {
        var dummy = [
            {
                id: 1,
                kategori: 'kategori1',
                subKategori: 'sub1',
                presentaseAlokasiKategori: 5,
                nominalAlokasiKategori: 300000,
                presentaseNilaiKategori: 7,
                nominalNilaiKategori: 500000,
                presentaseDeviasiKategori: 2,
                nominalDeviasiKategori: 100000
            }
        ]

        return dummy
    }
    dummySektor = () => {
        var dummy = [
            {
                id: 1,
                kategori: 'kategori1',
                presentaseAlokasiSektor: 5,
                nominalAlokasiSektor: 300000,
                presentaseNilaiSektor: 7,
                nominalNilaiSektor: 500000,
                presentaseDeviasiSektor: 2,
                nominalDeviasiSektor: 100000
            }
        ]

        return dummy
    }
    dummyEmiten = () => {
        var dummy = [
            {
                id: 1,
                emitem: 'emitem1',
                min: '30',
                max: '30',
                saham: '10000000',
                sahamNominal: '10000000',
                bond: '10000000',
                bondNominal: '10000000',
                total: '10000000',
                totalNominal: '20000000',
                persenDeviasi: '40',
                nominal: '40000000'
            }
        ]
        return dummy
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Rebalancing Portfolio'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <Form
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id="tabindex-0" className={' mt-2 col-md-12' } style={{height:"calc(57vh - 70px)", overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                <DevExpressDataGrid
                                        ref = {this.dataGridKategoriRef}
                                        loadAPI='kategoris'
                                        insertAPI='kategoris'
                                        updateAPI='kategoris'
                                        deleteAPI='kategoris' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.dummyKategori}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={false}
                                        exportFileName={"Kategori"}
                                        allowExportSelectedData={false}
                                        selection={"multiple"}
                                        
                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}
                                        height={400}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Kategori'}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={500} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.kategoriColumns} // taruh konfigurasi kolom disini
                                        // SummaryConfiguration={this.summary}

                                        onToolbarPreparing = {this.onToolbarPreparing}
                                        summaryTotalItem={this.sumKategoriAset}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="tabindex-1" className={'d-none mt-2 col-md-12' } style={{height:"calc(57vh - 70px)", overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                <DevExpressDataGrid
                                        ref = {this.dataGridEmitemRef}
                                        loadAPI='emitems'
                                        insertAPI='emitems'
                                        updateAPI='emitems'
                                        deleteAPI='emitems' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.dummyEmiten}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={false}
                                        exportFileName={"Emitem"}
                                        allowExportSelectedData={false}
                                        selection={"multiple"}
                                        
                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}
                                        height={400}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Emitem'}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={500} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.emitemColumns} // taruh konfigurasi kolom disini
                                        // SummaryConfiguration={this.summary}

                                        onToolbarPreparing = {this.onToolbarPreparing}
                                        summaryTotalItem = {this.sumEmiten}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="tabindex-2" className={'d-none mt-2 col-md-12' } style={{height:"calc(57vh - 70px)", overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <DevExpressDataGrid
                                        ref = {this.dataGridSektorRef}
                                        loadAPI='sektors'
                                        insertAPI='sektors'
                                        updateAPI='sektors'
                                        deleteAPI='sektors' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.dummySektor}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={false}
                                        exportFileName={"Sektor"}
                                        allowExportSelectedData={false}
                                        selection={"multiple"}
                                        
                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}
                                        height={400}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Sektor'}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={500} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.sektorColumns} // taruh konfigurasi kolom disini
                                        // SummaryConfiguration={this.summary}

                                        onToolbarPreparing = {this.onToolbarPreparing}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalPortfolioRebalance
