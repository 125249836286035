import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import DetailBudget from 'components/pages/modal/akutansi/budget/detail';
import DetailRealisasiBudget from 'components/pages/modal/akutansi/budget/detailRealisasi';
import DetailRevisiBudget from 'components/pages/modal/akutansi/budget/detailRevisi';

class Budget extends Component {
    constructor(props){
        super(props)
        this.state = {
            filterData : {}
        }

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail Anggaran',
                        hint : 'Detail Anggaran',
                        onClick : (e) => {
                            this.modalDetailBudgetRef.current.retrieveData(e.row.data)
                            this.modalDetailBudgetRef.current.show()
                        }
                    },
                    {
                        text : 'Detail Realisasi Anggaran',
                        hint : 'Detail Realisasi Anggaran',
                        onClick : (e) => {
                            this.modalDetailRealisasiBudgetRef.current.retrieveData(e.row.data)
                            this.modalDetailRealisasiBudgetRef.current.show()
                        }
                    },
                    // {
                    //     text : 'Detail Revisi Anggaran',
                    //     hint : 'Detail Revisi Anggaran',
                    //     onClick : (e) => {
                    //         this.modalDetailRevisiBudgetRef.current.retrieveData(e.row.data)
                    //         this.modalDetailRevisiBudgetRef.current.show()
                    //     }
                    // }
                ],
            },
            {
                dataField: 'tahun',
                caption: 'Tahun Anggaran',
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'N',
                            value: 'Non Aktif'
                        },
                        {
                            id: 'A',
                            value: 'Aktif'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: [
                        {
                            id: 'N',
                            value: 'Non Aktif'
                        },
                        {
                            id: 'A',
                            value: 'Aktif'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
        ]

        this.journalId = null

        this.modalDetailBudgetRef = React.createRef()
        this.modalDetailRealisasiBudgetRef = React.createRef()
        this.modalDetailRevisiBudgetRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Anggaran</h2>

                <DevExpressDataGrid
                    ref= {this.datagridRef}
                    loadAPI='anggarans'
                    insertAPI='anggarans'
                    updateAPI='anggarans'
                    deleteAPI='anggarans' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    remoteOperations = {true}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Anggaran"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Anggaran Data'}
                    popupWidth={'70vw'} //masukan dalam ukuran pixel
                    popupHeight={'400px'} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <DetailBudget store={this.props.store} ref={this.modalDetailBudgetRef} />
                <DetailRealisasiBudget store={this.props.store} ref={this.modalDetailRealisasiBudgetRef} />
                <DetailRevisiBudget store={this.props.store} ref={this.modalDetailRevisiBudgetRef} />
            </div>
        )
    }
}

export default Budget
