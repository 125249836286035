import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instrumentCategorySubs, banks } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatAsMoney, formatDefaultFullDate, formatNumber, formatNumberAum, summaryValue } from 'plugin/helper'
import { reportFile } from 'plugin/reportRequest'
import notify from 'devextreme/ui/notify';
import store from 'redux/config'
import Excel from 'exceljs/dist/exceljs';
import { showLoading } from 'redux/actions/loading'
import { saveAs } from 'file-saver'

class DataGridDailyBalanceDeposito extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'maturityDate'
            },
            {
                name: 'faceValue',
                column: 'faceValue',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'acruedInterest',
                column: 'acruedInterest',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            // {
            //     name : 'kupon',
            //     column: 'kupon',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     displayFormat : '{0}'
            // }
        ]

        this.columns = [
            {
                dataField: 'no.',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'instrumentName',
                caption: 'Tipe',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Nomor Bilyet',
            },
            {
                dataField: 'tanggalPenempatan',
                caption: 'Tanggal Penempatan',
                alignment: 'center',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'jumlahTenor',
                caption: 'Tenor'
            },
            {
                dataField: 'tipeTenor',
                caption: 'Tipe Tenor'
            },
            {
                dataField: 'maturityDate',
                caption: 'Tanggal Jatuh Tempo',
                alignment: 'center',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'faceValue',
                caption: 'Face Value',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'kupon',
                caption: 'Rate / Tingkat Bunga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'acruedInterest',
                Caption: 'Accrued Interest',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'basisHari',
                caption: 'Basis Hari'
            },
            {
                dataField: 'couponLastDate',
                caption: 'Last Coupon Date',
                alignment: 'center',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'couponNextDate',
                caption: 'Next Coupon Date',
                alignment: 'center',
                dataType: 'date',
                format: 'dd MMM yyyy',
            }
        ]
        this.dataGridRef = React.createRef();
        this.selectedRowsData = []
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.print()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.exportExcel();
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            }
        )
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = this.selectedRowsData;
        let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');
        let arrFaceValue = [];
        let arrTotalRate = [];
        let arrTotalBunga = [];
        let arrJumlahTenor = [];
        store.dispatch(showLoading(true));

        try {
            for (let value of response) {
                arrFaceValue.push(value.faceValue);
                arrTotalRate.push(value.kupon);
                arrTotalBunga.push(value.acruedInterest);
                arrJumlahTenor.push(value.jumlahTenor)
                let dataResult = {
                    instrumentName: value.instrumentName ? value.instrumentName : '-',
                    instrumentCode: value.instrumentCode ? value.instrumentCode : '-',
                    tanggalPenempatan: value.tanggalPenempatan ? formatDefaultFullDate(value.tanggalPenempatan) : '-',
                    jumlahTenor: value.jumlahTenor ? value.jumlahTenor : 0,
                    tipeTenor: value.tipeTenor ? value.tipeTenor : '-',
                    maturityDate: value.maturityDate ? formatDefaultFullDate(value.maturityDate) : 0,
                    faceValue: value.faceValue,
                    kupon: value.kupon ? value.kupon : 0,
                    // acruedInterest: value.acruedInterest ? formatAsMoney(value.acruedInterest) : 0,
                    acruedInterest: value.acruedInterest,
                    basisHari: value.basisHari ? value.basisHari : 0,
                    couponLastDate: value.couponLastDate ? value.couponLastDate : 0,
                    couponNextDate: value.couponNextDate ? value.couponNextDate : 0
                }

                const objectValue = Object.values(dataResult);
                data.push(objectValue);

                var totalFaceValue = arrFaceValue.reduce((a, b) => a + b, 0);
                var totalAccruedInterest = arrTotalBunga.reduce((a, b) => a + b, 0);
                var totalRate = arrTotalRate.reduce((a, b) => a + b, 0);
                var totalJumlahTenor = arrJumlahTenor.reduce((a, b) => a + b, 0);
            }

            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet('DAILY BALANCE PORTFOLIO DEPOSITO');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                },
                columns: [
                    { name: 'Tipe' },
                    { name: 'Nomor Bilyet' },
                    { name: 'Tanggal Penempatan' },
                    { name: 'Tenor' },
                    { name: 'Tipe Tenor' },
                    { name: 'Tanggal Jatuh Tempo' },
                    { name: 'Face Value' },
                    { name: 'Rate / Tingkat Bunga' },
                    { name: 'Accrued Interest' },
                    { name: 'Basis Hari' },
                    { name: 'Last Coupon Date' },
                    { name: 'Next Coupon Date' }
                ],
                rows: data
            })

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                }))
            }

            for (let i = 9; i <= 10000; i++) {
                worksheet.getCell('A' + i).alignment = { vertical: 'middle', horizontal: 'left' };
                // worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('H' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('I' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            }

            worksheet.getCell('D2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D2').font = { size: 18, bold: true }

            worksheet.getCell('D3').value = 'DAILY BALANCE PORTFOLIO DEPOSITO';
            worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D3').font = { size: 18, bold: true }

            worksheet.getCell('D4').value = `KPD ${this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName.toUpperCase() : '-'}`;
            worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D4').font = { size: 18, bold: true }

            worksheet.getCell('D5').value = `TANGGAL LAPORAN ${this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-'}`;
            worksheet.getCell('D5').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D5').font = { size: 18, bold: true }

            worksheet.mergeCells(`A${lastTableRowNum + 1}:F${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`A${lastTableRowNum + 1}`).font = { size: 12, bold: true }
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`G${lastTableRowNum + 1}`).value = totalFaceValue;
            worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`H${lastTableRowNum + 1}`).value = "";
            worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`I${lastTableRowNum + 1}`).value = totalAccruedInterest;
            worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.mergeCells(`J${lastTableRowNum + 1}:L${lastTableRowNum + 1}`);
            worksheet.getCell(`J${lastTableRowNum + 1}`).value = "";
            worksheet.getCell(`J${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };



            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = 40;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums2;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums2;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.numFmt = '#,##0.00';
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.numFmt = '#,##0.00';
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.numFmt = '#,##0.00';
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.numFmt = '#,##0.00';
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.numFmt = '#,##0.00';
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.numFmt = '#,##0.00';
            Data12.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: 'application/octet-stream' }),
                    'Daily_balance_portfolio_deposito.xlsx'
                );
            });

            store.dispatch(showLoading(false));
        } catch (error) {
            console.log(error);
            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    print = async () => {
        try {
            //  let loadAPI = `view-portofolio-daily-balances?balanceDate.equals=${this.props.stateData.balanceDate}&categoryId.in=1&kpdId.in=${this.props.stateData.kpdId}&productCode.in=${this.props.stateData.productCode}&size=9999`;
            //  let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');

            // let response = this.dataGridRef.current.getDataSource();
            let response = this.selectedRowsData;

            let kpdResponse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET');

            let transaksiDetil = [];
            if (response.length !== 0) {
                let arrFaceValue = [];
                let arrTotalRate = [];
                let arrTotalBunga = [];
                let arrJumlahTenor = [];

                for (let data of response) {
                    arrFaceValue.push(data.faceValue);
                    arrTotalRate.push(data.kupon);
                    arrTotalBunga.push(data.acruedInterest);
                    arrJumlahTenor.push(data.jumlahTenor)
                    let dataResult = {
                        instrumentName: data.instrumentName ? data.instrumentName : '-',
                        instrumentCode: data.instrumentCode ? data.instrumentCode : '-',
                        tanggalPenempatan: data.tanggalPenempatan ? formatDefaultFullDate(data.tanggalPenempatan) : '-',
                        faceValue: data.faceValue ? data.faceValue : 0,
                        maturityDate: data.maturityDate ? formatDefaultFullDate(data.maturityDate) : 0,
                        kupon: data.kupon ? data.kupon : 0,
                        jumlahTenor: data.jumlahTenor ? data.jumlahTenor : 0,
                        tipeTenor: data.tipeTenor ? data.tipeTenor : '-'
                        //  acruedInterest: data.acruedInterest ? data.acruedInterest : 0
                    }

                    transaksiDetil.push(dataResult);

                    var totalFaceValue = arrFaceValue.reduce((a, b) => a + b, 0);
                    var totalRate = arrTotalRate.reduce((a, b) => a + b, 0);
                    var totalJumlahTenor = arrJumlahTenor.reduce((a, b) => a + b, 0);
                    var totalBunga = arrTotalBunga.reduce((a, b) => a + b, 0);
                }

                reportFile({
                    template: { 'shortid': 'X20q~hm' },
                    data: {
                        "productCode": this.props.stateData.productCode,
                        "kpdName": this.props.stateData.kpdId ? kpdResponse.find(v => v.id === this.props.stateData.kpdId).kpdName : '-',
                        "reportDate": this.props.stateData.balanceDate ? formatDefaultFullDate(this.props.stateData.balanceDate) : '-',
                        "categoryName": this.props.tabName ? this.props.tabName : '-',
                        "dailyBalanceDetail": transaksiDetil,
                        "totalFaceValue": totalFaceValue,
                        "totalRate": totalRate,
                        "totalJumlahTenor": totalJumlahTenor,
                        "totalBungaBerjalan": totalBunga,
                    },
                    options: {
                        reportName: "Daily Balance Portofolio Deposito"
                    }
                }, true, 'Daily Balance Portofolio Deposito.pdf')
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`view-portofolio-daily-balances?balanceDate.equals=${this.props.stateData.balanceDate}&categoryId.in=3&kpdId.in=${this.props.stateData.kpdId}&productCode.in=${this.props.stateData.productCode}&size=9999`}
                    insertAPI='efek-balance-avgs'
                    updateAPI='efek-balance-avgs'
                    deleteAPI='efek-balance-avgs'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={false}
                    exportFileName={"Portofolio Deposito"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    showPageSizeSelector={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Deposito'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    height={'calc(100vh - 367px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridDailyBalanceDeposito
