import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { journalType, statusJournal } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url'
import { showLoading } from 'redux/actions/loading'

import ViewJournalVoucher from 'components/pages/modal/akutansi/journalVoucher/view';
import { formatDate, getSystemDate, numberToIndo, postingJournal } from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { data } from 'dataSource/json/auctionBuybackCash';

class JournalVoucher extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tanggal: {
                startDate: getSystemDate(props.store),
                endDate: getSystemDate(props.store),
                status: 'O'
            }
        }

        this.getUserLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null
        this.systemDate = new Date();
        this.coaBalanceDate = new Date();
        this.journalHeaderId = []

        this.Tanggal = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd-MMM-yyyy'
                },
            },
            {
                dataField: "endDate",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd-MMM-yyyy'
                }
            },
            {
                dataField: "status",
                label: {
                    text: "Status",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: statusJournal(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        if (!this.state.tanggal.endDate) {
                            let type = 'error'
                            let text = 'Please Set End Date !'

                            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                            return null
                        }

                        if (!this.state.tanggal.startDate) {
                            let type = 'error'
                            let text = 'Please Set Start Date !'

                            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                            return null
                        }

                        if (!this.state.tanggal.status) {
                            let type = 'error'
                            let text = 'Please Set Start Date !'

                            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                            return null
                        }

                        this.forceRefresh()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalView(e.row.data)
                        }
                    },
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            if (e.row.data.status === 'D') {
                                this.showModalEdit(e.row.data)
                            } else if (e.row.data.status === 'O') {
                                this.showModalEditStatusOpen(e.row.data)
                            } else if (e.row.data.status === 'P') {
                                let type = 'error'
                                let text = 'Only Journal With Status Draft or Open!'

                                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                            } else if (e.row.data.status === 'C') {
                                let type = 'error'
                                let text = 'Only Journal With Status Draft or Open!'

                                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                            } else {
                                let type = 'error'
                                let text = 'Only Journal With Status Draft or Open!'

                                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                            }
                        }
                    },
                    {
                        text: 'Buat Draft',
                        hint: 'Buat Draft',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            let data = e.row.data;
                            let items = e.items;

                            if (items[2].text === "Buat Draft") {
                                let result = await confirm('Apakah anda yakin akan melakukan proses Buat Draft pada jurnal ini ?', 'Konfirmasi');

                                if (result) {
                                    this.createNewDraft(data);
                                }
                            }
                            // if (items[2]) {

                            // }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            let data = e.row.data;
                            let items = e.items;

                            if (items[3].text === 'Kirim') {
                                let result = await confirm('Apakah anda yakin akan melakukan proses Kirim pada jurnal ini  ?', 'Konfirmasi');

                                if (result) {
                                    this.kirim(data);
                                }
                            }
                        }
                    },
                    {
                        text: 'Un-Posting',
                        hint: 'Un-Posting',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            let data = e.row.data;
                            let items = e.items;

                            if (items[4].text === 'Un-Posting') {
                                let result = await confirm('Apakah anda yakin akan melakukan proses Un-Posting pada jurnal ini ?', 'Konfirmasi');

                                if (result) {
                                    this.unPosting(data);
                                }
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            var result = await confirm('Apakah anda yakin untuk membatalkan jurnal ini?', 'Confirmation')

                            if (!result) {
                                return null
                            }
                            this.cancel(e)
                        }
                    },
                    // {
                    //     text: 'Posting',
                    //     hint: 'Posting',
                    //     cssClass: 'text-success',
                    //     onClick: async (e) => {
                    //         if (new Date(e.row.data.valueDate) > new Date(this.systemDate.dateValue)) {
                    //             alert('Tanggal jurnal melebihi tanggal sistem', 'Tidak bisa posting!');

                    //             return null
                    //         }
                    //         if (new Date(e.row.data.valueDate) < new Date(this.systemDate.dateValue)) {
                    //             var result = await confirm('Tanggal jurnal kurang dari tanggal sistem, apakah anda ingin melanjutkan?', 'Confirmation')

                    //             if (!result) {
                    //                 return null
                    //             }
                    //         } else {
                    //             var result = await confirm('Apakah anda yakin untuk memposting jurnal ini?', 'Confirmation')

                    //             if (!result) {
                    //                 return null
                    //             }
                    //         }
                    //         this.posting(e)
                    //     }
                    // },
                    // {
                    //     text: 'Reverse',
                    //     hint: 'Reverse',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.reverse(e)
                    //     }
                    // },
                    {
                        text: 'Cetak',
                        hint: 'Cetak',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.print(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'journalType',
                caption: 'Journal Type',
                alignment: 'left',
                columns: [
                    {
                        dataField: 'journalTypeId',
                        caption: 'Kode',
                        lookup: {
                            dataSource: journalType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'journalTypeCode',
                        },
                    },
                    {
                        dataField: 'journalTypeId',
                        caption: 'Deskripsi',
                        lookup: {
                            dataSource: journalType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'journalTypeDesc',
                        },
                    },
                ]
            },
            {
                dataField: 'journalInformation',
                caption: 'Journal Information',
                columns: [
                    {
                        dataField: 'journalCode',
                        caption: 'Kode Jurnal'
                    },
                    {
                        dataField: 'journalDesc',
                        caption: 'Deskripsi Jurnal'
                    },
                    {
                        dataField: 'automatic',
                        caption: 'Jurnal Otomatis',
                        dataType: 'boolean',
                    },
                    {
                        dataField: 'status',
                        caption: 'Status',
                        lookup: {
                            dataSource: statusJournal(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                        },
                    },
                    {
                        dataField: 'valueDate',
                        caption: 'Value Date',
                        dataType: 'date',
                        format: 'dd-MMM-yyyy'
                    },
                    {
                        dataField: 'debet',
                        caption: 'Total Debet',
                        alignment: 'right',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'credit',
                        caption: 'Total Kredit',
                        alignment: 'right',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                ]
            }
        ]

        this.selectedRowDatas = []

        this.popupModalJournalVoucherRef = React.createRef()
        this.popupModalJournalVoucherInsertRef = React.createRef()
        this.popupModalJournalVoucherEditRef = React.createRef()
        this.popupModalJournalVoucherEditStatusOpenRef = React.createRef()
        this.datagridRef = React.createRef()

    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    componentDidMount = () => {
        if (this.props.store.getState().sessionToken) {
            this.systemDate = this.props.store.getState().getParam ? this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
            this.coaBalanceDate = this.props.store.getState().getParam ? this.props.store.getState().getParam.find(value => value.paramCode === 'CBDATE') : null
        }
    }

    createNewDraft = async (data) => {
        try {
            let loadAPI = `journal-headers/updateJournalVoucher/1/${data.id}/${this.getUserLoged}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'PUT', {
                values: {}
            }, null)

            console.log(response);

            if (response.statusCode === 200) {
                let title = 'Berhasil';
                let textStatus = response.statusDescription;

                await alert(textStatus, title);
                this.forceRefresh();
            } else if (response.statusCode === 500) {
                let title = 'Gagal';
                let textStatus = response.statusDescription;

                await alert(textStatus, title);
                this.forceRefresh();
            }
        } catch (error) {
            await alert(error, 'Gagal');
        }
    }

    kirim = async (data) => {
        try {
            let loadAPI = `journal-headers/updateJournalVoucher/2/${data.id}/${this.getUserLoged}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'PUT', {
                values: {}
            }, null)

            if (response.statusCode === 200) {
                let title = 'Berhasil';
                let textStatus = response.statusDescription;

                await alert(textStatus, title);
                this.forceRefresh();
            } else if (response.statusCode === 500) {
                let title = 'Gagal';
                let textStatus = response.statusDescription;

                await alert(textStatus, title);
                this.forceRefresh();
            }
        } catch (error) {
            await alert(error, 'Gagal');
        }
    }

    unPosting = async (data) => {
        try {
            let loadAPI = `journal-headers/updateJournalVoucher/3/${data.id}/${this.getUserLoged}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'PUT', {
                values: {}
            }, null)

            if (response.statusCode === 200) {
                let title = 'Berhasil';
                let textStatus = response.statusDescription;

                await alert(textStatus, title);
                this.forceRefresh();
            } else if (response.statusCode === 500) {
                let title = 'Gagal';
                let textStatus = response.statusDescription;

                await alert(textStatus, title);
                this.forceRefresh();
            }
        } catch (error) {
            await alert(error, 'Gagal');
        }
    }

    showModalView = async (data) => {
        this.popupModalJournalVoucherRef.current.show()
        this.popupModalJournalVoucherRef.current.retrieveData(data)
        await this.popupModalJournalVoucherRef.current.loadDataDetailAndExrate(data.id)
    }
    showModalInsert = async (data) => {
        this.popupModalJournalVoucherInsertRef.current.show()
        if (data) {
            this.popupModalJournalVoucherInsertRef.current.retrieveData(data)
            await this.popupModalJournalVoucherInsertRef.current.loadDataDetailAndExrate(data.id)
        }
    }
    showModalEdit = async (data) => {
        this.popupModalJournalVoucherEditRef.current.show()
        if (data) {
            this.popupModalJournalVoucherEditRef.current.retrieveData(data)
            await this.popupModalJournalVoucherEditRef.current.loadDataDetailAndExrate(data.id)
        }
    }
    showModalEditStatusOpen = async (data) => {
        this.popupModalJournalVoucherEditStatusOpenRef.current.show()
        if (data) {
            this.popupModalJournalVoucherEditStatusOpenRef.current.retrieveData(data)
            await this.popupModalJournalVoucherEditStatusOpenRef.current.loadDataDetailAndExrate(data.id)
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Posting Jurnal Terpilih',
                    onClick: async (e) => {
                        if (this.selectedRowDatas.length === 0) {
                            alert('Tidak ada jurnal yang dipilih!', 'Peringatan!');

                            return null
                        }


                        var validateValueDatae = ""
                        var validateValueDataeConf = false
                        for (let selectedData of this.selectedRowDatas) {
                            if (new Date(selectedData.valueDate) > new Date(this.systemDate.dateValue)) {
                                validateValueDatae = "Ada tanggal jurnal yang melebihi tanggal sistem"
                                validateValueDataeConf = true
                            }
                        }

                        if (validateValueDataeConf) {
                            alert(validateValueDatae, 'Peringatan!');

                            return null
                        }

                        var result = await confirm(`Apakah anda yakin untuk memposting ${this.selectedRowDatas.length} jurnal yang terpilih?`, 'Confirmation')

                        if (!result) {
                            return null
                        }

                        var results = []
                        for (const selectedData of this.selectedRowDatas) {
                            const result = await this.posting({
                                row: {
                                    data: selectedData,
                                    key: selectedData.id
                                }
                            }, true)

                            results.push(result.message)
                        }

                        let journalHeaderId = [];

                        for (let selectedData of this.selectedRowDatas) {
                            journalHeaderId.push(selectedData.id);
                        }

                        let dataValue = {
                            journalHeaderIds: journalHeaderId,
                            user: this.getUserLoged
                        }
                    
                        await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-positions/set-coa-position`, 'POST', {
                            values: dataValue
                        })

                        alert(results.join('\n'), 'Notification');

                        this.forceRefresh()

                        this.selectedRowDatas = []
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    text: 'Cetak',
                    elementAttr: {class: "bg-button-print-dapen-default"},
                    onClick: async(e) => {
                        if (this.selectedRowDatas.length === 0) {
                            alert('Tidak ada jurnal yang dipilih!', 'Peringatan!');

                            return null
                        }

                        // this.printNextTen()
                        this.printAllFilesWithDelay(this.selectedRowDatas)
                    },
                }
            }
        )
    }

    printAllFilesWithDelay = async (dataArray) => {
        this.props.store.dispatch(showLoading(true))
        // let resultConfirm = await confirm('Apakah anda ingin melanjutkan download laporan?', 'Konfirmasi!');
        for (let data of dataArray) {
            await this.printMulti(data);
            await new Promise(resolve => setTimeout(resolve, 3000)); // Wait for 3 seconds
        }
        this.props.store.dispatch(showLoading(false))
    }

    printNextTen = async() => {
        for (let i = 0; i < 10 && this.dataIndex < this.selectedRowDatas.length; i++) {
            this.printMulti(this.selectedRowDatas[this.dataIndex]);
            this.dataIndex++;
        }
    
        if (this.dataIndex < this.selectedRowDatas.length) {
            setTimeout(this.printNextTen, 10000); // 10 seconds timeout
        }
    }

    printMulti = async (data) => {
        var dataJurnalDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-detils/getAllByJournalHeaderId/${data.id}`)
        var getBankName = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `get-bank-name/${data.id}`)
        
        dataJurnalDetail = dataJurnalDetail.map(value => {
            if (value.dc === 'D') {
                value.debet = value.amountBasedCurrency
            } else {
                value.kredit = value.amountBasedCurrency
            }

            delete value.coa.coaGroup
            delete value.coa.currency

            return value
        })

        data.journalDetail = dataJurnalDetail
        const terbilang = numberToIndo(data.debet.toFixed(2))
        data.terbilang = terbilang
        data.namaBank = getBankName[0].bank_name

        // const fileName = prompt('Enter file name:', `journal-voucher-${data.journalCode}`);
        // if (fileName) {
        //     const filePath = `/path/to/your/directory/${fileName}`; // Replace this with the desired path
        //     reportFile({
        //         template: { 'shortid': 'r1xlkoHksd' },
        //         data: data,
        //         options: {
        //             reportName: fileName
        //         }
        //     }, true, filePath);
        // }

        reportFile({
            template: { 'shortid': 'r1xlkoHksd' },
            data: data,
            options: {
                reportName: `journal-voucher-${data.journalCode}`
            }
        }, true, `journal-voucher-${data.journalCode}`)
    }

    loadData = async () => {
        var journalData

        if (this.state.tanggal.startDate && this.state.tanggal.endDate) {
            journalData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getAllByValueDateBetweenAndStatus/${formatDate(this.state.tanggal.startDate)}/${formatDate(this.state.tanggal.endDate)}/${this.state.tanggal.status}`)

            journalData = (journalData || []).map(value => {
                value.journalTypeId = value.journalType.id

                if (value.automatic === null || value.automatic === undefined) {
                    value.automatic = false
                }

                delete value.journalType

                return value
            })

            return journalData
        }

        journalData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers`)

        journalData = (journalData || []).map(value => {
            if (value.automatic === null || value.automatic === undefined) {
                value.automatic = false
            }
            return value
        })

        return journalData
    }
    cancel = async (e) => {
        if (e.row.data.status === 'O' || e.row.data.status === 'D') {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers`, 'PUT', {
                    values: {
                        status: 'C'
                    }
                }, e.row.data)

                let type = 'success'
                let text = 'Journal Successfully Cancelled !'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                this.forceRefresh()
            } catch (e) {
                console.log(e)


                let type = 'error'
                let text = 'An Error Occured !'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
            }
        } else {
            let type = 'error'
            let text

            switch (e.row.data.status) {
                case 'P':
                    text = 'Journal Already Posted!'
                    break;
                case 'C':
                    text = 'Journal Already Cancelled!'
                    break;
                case 'R':
                    text = 'Journal Already Reversed!'
                    break;
                default:
                    break;
            }
            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        }
    }

    posting = async (e, isMultiple = false) => {
        this.props.store.dispatch(showLoading(true))
        var result
        if (e.row.data.status === 'O') {
            try {
                const dataJournalDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-detils/getAllByJournalHeaderId/${e.row.key}`)

                let totalDebet = 0, totalKredit = 0
                for (const journalDetil of dataJournalDetil) {
                    totalDebet = Math.round((totalDebet + (journalDetil.dc === 'D' ? journalDetil.amount : 0)) * 100) / 100
                    totalKredit = Math.round((totalKredit + (journalDetil.dc === 'C' ? journalDetil.amount : 0)) * 100) / 100
                }
                if (totalDebet !== totalKredit) {
                    result = {
                        status: false,
                        message: `${e.row.data.journalCode} : Debet dan Kredit tidak sama. Gagal melakukan posting jurnal.`
                    }

                    let type = 'error'
                    let text = 'Debet dan Kredit tidak sama. Gagal melakukan posting jurnal!'

                    if (!isMultiple) {
                        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                        this.forceRefresh()
                    }
                } else {
                    // await postingJournal(this.props.store, e.row.data.valueDate, this.systemDate.dateValue, e.row.key)
                    await postingJournal(this.props.store, e.row.data.valueDate, this.coaBalanceDate.dateValue, e.row.key)

                    let type = 'success'
                    let text = 'Jurnal Berhasil di Posting!'

                    if (!isMultiple) {
                        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                        this.forceRefresh()
                    }

                    result = {
                        status: true,
                        message: `${e.row.data.journalCode} : Jurnal Berhasil di Posting`
                    }
                }

            } catch (error) {
                result = {
                    status: false,
                    message: `${e.row.data.journalCode} : Kesalahan sistem. Gagal melakukan posting jurnal.`
                }

                this.props.store.dispatch(showLoading(false))


                let type = 'error'
                let text = 'Kesalahan Sistem!'
                if (!isMultiple) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                }
            }
        } else {
            let type = 'error'
            let text

            switch (e.row.data.status) {
                case 'P':
                    text = 'Jurnal Telah Diposting!'
                    break;
                case 'C':
                    text = 'Jurnal Telah Dibatalkan!'
                    break;
                case 'R':
                    text = 'Jurnal Telah Dibalik!'
                    break;
                case 'D':
                    text = 'Jurnal Masih Draft!'
                    break;
                default:
                    break;
            }

            result = {
                status: false,
                message: `${e.row.data.journalCode} : Gagal melakukan posting jurnal. ${text}`
            }

            if (!isMultiple) {
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
            }
        }

        this.props.store.dispatch(showLoading(false))

        return result
    }

    reverse = async (e) => {
        if (e.row.data.status === 'P' && e.row.data.journalReference === null) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/reverse/${e.row.key}`)

                let type = 'success'
                let text = 'Journal Successfully Reversed !'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                this.forceRefresh()
            } catch (e) {
                console.log(e)


                let type = 'error'
                let text = 'An Error Occured !'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
            }
        } else {
            let type = 'error'
            let text

            switch (e.row.data.status) {
                case 'O':
                    text = 'Journal Status Still Open!'
                    break;
                case 'C':
                    text = 'Journal Already Cancelled!'
                    break;
                case 'R':
                    text = 'Journal Already Reversed!'
                    break;
                case 'D':
                    text = 'Jurnal Masih Draft!'
                default:
                    break;
            }

            if (e.row.data.journalReference) {
                text = 'This is Reversed Journal, Cannot Reverse!'
            }
            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        }
    }

    print = async (data) => {
        var dataJurnalDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-detils/getAllByJournalHeaderId/${data.id}`)
        var getBankName = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `get-bank-name/${data.id}`)
        dataJurnalDetail = dataJurnalDetail.map(value => {
            if (value.dc === 'D') {
                value.debet = value.amountBasedCurrency
            } else {
                value.kredit = value.amountBasedCurrency
            }

            delete value.coa.coaGroup
            delete value.coa.currency

            return value
        })

        data.journalDetail = dataJurnalDetail
        const terbilang = numberToIndo(data.debet.toFixed(2))
        data.terbilang = terbilang
        data.namaBank = getBankName[0].bank_name

        reportFile({
            template: { 'shortid': 'r1xlkoHksd' },
            data: data,
            options: {
                reportName: `journal-voucher-${data.journalCode}`
            }
        }, true, `journal-voucher-${data.journalCode}`)
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    onSelectionChanged = (e) => {
        this.selectedRowDatas = e.selectedRowsData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Journal Voucher</h3>
                <Form
                    colCount={4}
                    id={'formTanggal'}
                    formData={this.state.tanggal}
                    items={this.Tanggal}
                />
                <DevExpressDataGrid
                    ref={this.datagridRef}
                    loadAPI='journal-headers'
                    insertAPI='journal-headers'
                    updateAPI='journal-headers'
                    deleteAPI='journal-headers'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'debet',
                            'credit'
                        ]
                    }

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Jurnal Voucher"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Jurnal Voucher Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 245px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    onSelectionChanged={this.onSelectionChanged}


                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ViewJournalVoucher
                    ref={this.popupModalJournalVoucherRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    type={'view'}
                />
                <ViewJournalVoucher
                    ref={this.popupModalJournalVoucherInsertRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    type={'add'}
                />
                <ViewJournalVoucher
                    ref={this.popupModalJournalVoucherEditRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    type={'edit'}
                />
                <ViewJournalVoucher
                    ref={this.popupModalJournalVoucherEditStatusOpenRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    type={'editStatusOpen'}
                />
            </div>
        )
    }
}

export default JournalVoucher