import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Popup } from 'devextreme-react'
import ViewPenggajianKaryawan from './slipGaji'
import { formatNumber, formatNumberAum, formatNumberNav } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import notify from 'devextreme/ui/notify';
import * as path from 'path';
import { jabatan } from 'dataSource/lookup'

class FormDetailGaji extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {}
        }

        this.dataSource = [{}];

        this.rekapColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Slip Gaji',
                        hint: 'Slip Gaji',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalSlipGaji(e)
                        }
                    },
                    // {
                    //     text: 'Tunda',
                    //     hint: 'Tunda',
                    //     cssClass: 'text-primary',
                    //     onClick: (e) => {
                    //         // this.showModalSlipGaji()
                    //     }
                    // },
                ],
            },
            {
                dataField: "k_id",
                caption: "K Id",
            },
            {
                dataField: "nik",
                caption: "NIK",
            },
            {
                dataField: "full_name",
                caption: "Nama Karyawan",
            },
            {
                dataField: "sts_karyawan",
                caption: "Status Karyawan",
            },
            {
                dataField: 'jabatan_id',
                caption: 'Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                }
            },
            // {
            //     dataField: "sts_kepegawaian",
            //     caption: "Jabatan",
            // },
            {
                dataField: "sts_pajak", 
                caption: "Status Pajak",
            },
            {
                caption: "Gaji Pokok",
                alignment: "center",
                columns: [
                    {
                        dataField: "penghasilan_dasar",
                        caption: "Gaji Pegawai",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t03",
                        caption: "Honorarium (T03)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t25",
                        caption: "Honorarium Komite (T25)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t15",
                        caption: "Tunjangan Tetap (T15)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t24",
                        caption: "Tunj Penugasan (T24)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t26",
                        caption: "Tunjangan Tidak Tetap (T26)",
                        alignment: "right",
                        dataType: "number",
                        format: "#,##0.00"
                    },
                    {
                        dataField: "t01",
                        caption: "Tunjangan Pengelola/Rapel (T01)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t27",
                        caption: "Gaji/Upah Rapel (T27)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "BPJS 4%",
                alignment: "center",
                columns: [
                    {
                        dataField: "t10",
                        caption: "BPJS1 Kes (T10)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t21",
                        caption: "BPJS Per Kes (T21)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t21a",
                        caption: "BPJS Per KES KP (T21A)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    }
                ]
            },
            {
                caption: "Kepegawaian",
                alignment: "center",
                columns: [
                    {
                        dataField: "t07",
                        caption: "Dana Pensiun (T07)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t44",
                        caption: "Tabungan Hari Tua (T44)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t19",
                        caption: "DPLK + Askom (T19)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t08",
                        caption: "Asikom (T08)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t05",
                        caption: "Beban Transport Pengurus COP (T05)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t02",
                        caption: "Perumahan/Rapel(T02)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t18",
                        caption: "Tunjangan Bisnis (T18)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t04",
                        caption: "Tunjangan Transport Pengawas (T04)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Lembur",
                alignment: "center",
                columns: [
                    {
                        dataField: "t17",
                        caption: "Lembur Honor (T17)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Lain Lain",
                alignment: "center",
                columns: [
                    {
                        dataField: "t14",
                        caption: "Kelebihan Potong (T14)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t13",
                        caption: "Pembulatan (T13)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t11",
                        caption: "YKPP (T11)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Tunjangan Jamsostek",
                alignment: "center",
                columns: [
                    {
                        dataField: "t09",
                        caption: "BPJS 2 Ket (T09)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t20",
                        caption: "BPJS Per Tgk (T20)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t20a",
                        caption: "BPJS Per TGK Penyesuaian (T20A)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t20b",
                        caption: "BPJS Per Tgk KP (T20b)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Pajak PPH",
                alignment: "center",
                columns: [
                    {
                        dataField: "t12",
                        caption: "Tunj Pajak Pengurus (T12)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t06",
                        caption: "Tunj Pajak Dewas (T06)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "t16",
                        caption: "TPPH (T16)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "JHT-JP",
                alignment: "center",
                columns: [
                    {
                        dataField: "p10",
                        caption: "BPJS Ket Karyawan (P10)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p17",
                        caption: "BPJS Karyawan Tgk (P17)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p17a",
                        caption: "BPJS Karyawan Tgk KP (P17a)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Potongan Jamsostek",
                alignment: "center",
                columns: [
                    {
                        dataField: "p13",
                        caption: "BPJS Kes Kar (P13)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p19",
                        caption: "BPJS Kes Karyawan (P19)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p19a",
                        caption: "BPJS Kes Kryw KP (P19a)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p19b",
                        caption: "BPJS Kes Kryw Tambahan (P19B)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p22a",
                        caption: "BPJS Kes Per KP (P22a)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p16a",
                        caption: "BPJS Per Tgk KP (P16a)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p35",
                        caption: "Potongan Kurang Bayar BPJSTK (P35)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p36",
                        caption: "Potongan Lainnya (P36)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p30",
                        caption: "RTT (P30)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p11",
                        caption: "BPJS Kes Per (P11)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p22",
                        caption: "BPJS Kes Per (P22)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p09",
                        caption: "BPJS Ket Per (P09)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p16",
                        caption: "BPJS Per Tgk (P16)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Potongan Lain Lain",
                alignment: "center",
                columns: [
                    {
                        dataField: "p12",
                        caption: "Potongan  Rumah Dinas (P12)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p01",
                        caption: "COP (P01)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p08",
                        caption: "Infaq/Sodaqoh (P08)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p18",
                        caption: "Koperasi Kresna (P18)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p05",
                        caption: "Koperasi (P05)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p29",
                        caption: "Link Aja (P29)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p21",
                        caption: "Potongan Keterlambatan (P21)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p04",
                        caption: "Potongan SP (P04)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p20",
                        caption: "Potongan Kehadiran (P20)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p25",
                        caption: "Pot. SP (P25)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p15",
                        caption: "Link Aja (P15)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p24",
                        caption: "YKPP (P24)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Dapen & Taspen",
                alignment: "center",
                columns: [
                    {
                        dataField: "p07",
                        caption: "Askom (P07)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p02",
                        caption: "Dana Pensiun Kry (P02)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p14",
                        caption: "Dana Pensiun Per (P14)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p26",
                        caption: "DPLK + Askom (P26)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p27",
                        caption: "DPLK(K)3 25  (P27)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p28",
                        caption: "DPLK(K)4 75  (P28)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p52",
                        caption: "Tabungan Hari Tua  (P52)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p03",
                        caption: "Taspen (P03)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                caption: "Pajak PPH",
                alignment: "center",
                columns: [
                    {
                        dataField: "p06",
                        caption: "Potongan PPh Pengurus (P06)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p23",
                        caption: "Potongan PPh Pegawai (P23)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: "p51",
                        caption: "Potongan PPH 21 Pusat (P51)",
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    }
                ]
            },
            {
                dataField: "gaji_ditransfer",
                caption: "Gaji Di Transfer",
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];


        this.selectedRowsData = [];
        this.payrollId = ''
        this.showModalSlipGajiRef = React.createRef()
    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {
        this.payrollId = 0
        this.setState({
            popupVisible: false
        })
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    showModalSlipGaji = (e) => {
        if (e.row) {
            this.showModalSlipGajiRef.current.retrieveData(e.row.data, this.state.DataMaster, this.payrollId)
        }
        this.showModalSlipGajiRef.current.show()
    }

    retrieveData = (data) => {
        this.payrollId = data.id
        this.setState({
            DataMaster: data
        })

        this.dataSource = this.loadData();
    }

    loadData = async () => {
        var pushResult = []
        let loadAPI = `pembayaran-umums/getRekapGaji/${this.state.DataMaster.id || 0}/${this.state.DataMaster.programId || 0}/${this.state.DataMaster.tahun || 0}/${this.state.DataMaster.bulan || 0}`
        let result = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')
        // for (var data of result) {
        //     let p = data.p01 + data.p02 + data.p03 + data.p04 + data.p05 + data.p06 + data.p07 + data.p08 + data.p09 + data.p10 + data.p11 + data.p12 + data.p13 + data.p14 + data.p15 + data.p16 + data.p17 + data.p18 + data.p19 + data.p20 + data.p21 + data.p22 + data.p23 + data.p24 + data.p25 + data.p26 + data.p27 + data.p28 + data.p29 + data.p19b + data.p35 + data.p17a + data.p36 + data.p16a + data.p30
        //     let t = data.t01 + data.t02 + data.t03 + data.t04 + data.t05 + data.t06 + data.t07 + data.t08 + data.t09 + data.t10 + data.t11 + data.t12 + data.t13 + data.t14 + data.t15 + data.t16 + data.t17 + data.t18 + data.t19 + data.t20 + data.t21 + data.t23653 + data.t20a
        //     let resultCalc = data.penghasilan_dasar + t - p
 
        //     if (resultCalc < 0) {
        //         data.gajiDiTransfer = 0
        //     } else {
        //         data.gajiDiTransfer = resultCalc
        //     }
        //     pushResult.push(data)
        // }
        // let data = e.row.data

        return result
    }

    exportExcel = async() => {
        let data = [];
        const firstTableRowNum = 8;
        // let loadData = `pembayaran-umums/getRekapGaji/${this.state.DataMaster.id || 0}/${this.state.DataMaster.programId || 0}/${this.state.DataMaster.tahun || 0}/${this.state.DataMaster.bulan || 0}`;
        // let getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadData, 'GET');

        let getData = this.selectedRowsData

        let lookUpJabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'jabatans?size=9999', 'GET');

        var index = 1
        for (let value of getData){
            // let p = value.p01 + value.p02 + value.p03 + value.p04 + value.p05 + value.p06 + value.p07 + value.p08 + value.p09 + value.p10 + value.p11 + value.p12 + value.p13 + value.p14 + value.p15 + value.p16 + value.p17 + value.p18 + value.p19 + value.p20 + value.p21 + value.p22 + value.p23 + value.p24 + value.p25 + value.p26 + value.p27 + value.p28 + value.p29 + value.p19b + value.p35 + value.p17a + value.p36 + value.p16a + value.p30
            // let t = value.t01 + value.t02 + value.t03 + value.t04 + value.t05 + value.t06 + value.t07 + value.t08 + value.t09 + value.t10 + value.t11 + value.t12 + value.t13 + value.t14 + value.t15 + value.t16 + value.t17 + value.t18 + value.t19 + value.t20 + value.t21 + value.t23653 + value.t20a
            // let resultCalc = value.penghasilan_dasar + t - p
 
            // if (resultCalc < 0) {
            //     value.gajiDiTransfer = 0
            // } else {
            //     value.gajiDiTransfer = resultCalc
            // }

            let dataValue = {
                k_id: index++,
                nik: value.nik,
                full_name: value.full_name,
                sts_karyawan: value.sts_karyawan,
                // sts_kepegawaian: value.sts_kepegawaian,
                jabatan: value.jabatan_id ? lookUpJabatan.find(val => val.id === value.jabatan_id).jabatanName : '-',
                sts_pajak: value.sts_pajak,
                penghasilan_dasar: value.penghasilan_dasar ? formatNumberAum(value.penghasilan_dasar) : '',
                t03: value.t03 ? formatNumberAum(value.t03) : 0,
                t25: value.t25 ? formatNumberAum(value.t25) : 0,
                t15: value.t15 ? formatNumberAum(value.t15) : 0,
                t24: value.t24 ? formatNumberAum(value.t24) : 0,
                t26: value.t26 ? formatNumberAum(value.t26) : 0,
                t01: value.t01 ? formatNumberAum(value.t01) : 0,
                t27: value.t27 ? formatNumberAum(value.t27) : 0,
                t10: value.t10 ? formatNumberAum(value.t10) : 0,
                t21: value.t21 ? formatNumberAum(value.t21) : 0,
                t21a: value.t21a ? formatNumberAum(value.t21a) : 0,
                t19: value.t19 ? formatNumberAum(value.t19) : 0, 
                t07: value.t07 ? formatNumberAum(value.t07) : 0,
                tht: value.tht ? formatNumberAum(value.tht) : 0,               
                t05: value.t05 ? formatNumberAum(value.t05) : 0,
                t08: value.t08 ? formatNumberAum(value.t08) : 0,
                t18: value.t18 ? formatNumberAum(value.t18) : 0,
                t02: value.t02 ? formatNumberAum(value.t02) : 0,
                t04: value.t04 ? formatNumberAum(value.t04) : 0,
                t17: value.t17 ? formatNumberAum(value.t17) : 0,
                t14: value.t14 ? formatNumberAum(value.t14) : 0,
                t13: value.t13 ? formatNumberAum(value.t13) : 0,
                t09: value.t09 ? formatNumberAum(value.t09) : 0,
                t11: value.t11 ? formatNumberAum(value.t11) : 0,
                t20: value.t20 ? formatNumberAum(value.t20) : 0,
                t20b: value.t20b ? formatNumberAum(value.t20b) : 0,
                t20a: value.t20a ? formatNumberAum(value.t20a) : 0,
                t12: value.t12 ? formatNumberAum(value.t12) : 0,
                t06: value.t06 ? formatNumberAum(value.t06) : 0,
                t16: value.t16 ? formatNumberAum(value.t16) : 0,
                p10: value.p10 ? formatNumberAum(value.p10) : 0,
                p17: value.p17 ? formatNumberAum(value.p17) : 0,
                p17a: value.p17a ? formatNumberAum(value.p17a) : 0,
                p13: value.p13 ? formatNumberAum(value.p13) : 0,
                p19: value.p19 ? formatNumberAum(value.p19) : 0,
                p19a: value.p19a ? formatNumberAum(value.p19a) : 0,
                p19b: value.p19b ? formatNumberAum(value.p19b) : 0,
                p16a: value.p16a ? formatNumberAum(value.p16a) : 0,
                p22: value.p22 ? formatNumberAum(value.p22) : 0,
                p16: value.p16 ? formatNumberAum(value.p16) : 0,
                p30: value.p30 ? formatNumberAum(value.p30) : 0,
                p09: value.p09 ? formatNumberAum(value.p09) : 0,
                p35: value.p35 ? formatNumberAum(value.p35) : 0,
                p36: value.p36 ? formatNumberAum(value.p36) : 0,
                p22a: value.p22a ? formatNumberAum(value.p22a) : 0,
                p11: value.p11 ? formatNumberAum(value.p11) : 0,
                p12: value.p12 ? formatNumberAum(value.p12) : 0,
                p01: value.p01 ? formatNumberAum(value.p01) : 0,
                p08: value.p08 ? formatNumberAum(value.p08) : 0,
                p18: value.p18 ? formatNumberAum(value.p18) : 0,
                p05: value.p05 ? formatNumberAum(value.p05) : 0,
                p29: value.p29 ? formatNumberAum(value.p29) : 0,
                p21: value.p21 ? formatNumberAum(value.p21) : 0,
                p04: value.p04 ? formatNumberAum(value.p04) : 0,
                p20: value.p20 ? formatNumberAum(value.p20) : 0,
                p25: value.p25 ? formatNumberAum(value.p25) : 0,
                p15: value.p15 ? formatNumberAum(value.p15) : 0,
                p24: value.p24 ? formatNumberAum(value.p24) : 0,
                p07: value.p07 ? formatNumberAum(value.p07) : 0,
                p02: value.p02 ? formatNumberAum(value.p02) : 0,
                p14: value.p14 ? formatNumberAum(value.p14) : 0,
                p26: value.p26 ? formatNumberAum(value.p26) : 0,
                p27: value.p27 ? formatNumberAum(value.p27) : 0,
                p28: value.p28 ? formatNumberAum(value.p28) : 0,
                p52: value.p52 ? formatNumberAum(value.p52) : 0,
                p03: value.p03 ? formatNumberAum(value.p03) : 0,
                p06: value.p06 ? formatNumberAum(value.p06) : 0,
                p23: value.p23 ? formatNumberAum(value.p23) : 0,
                p51: value.p51 ? formatNumberAum(value.p51) : 0,
                gaji_ditransfer: value.gaji_ditransfer ? value.gaji_ditransfer : 0
            }
            const ObjectValue = Object.values(dataValue);
            data.push(ObjectValue);
        }

        console.log(data);
        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('Daftar Rekap Gaji');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,
                    alignment: 'center'           
                },
                columns: [
                    {name: 'No'},
                    {name: 'NIK'},
                    {name: 'Nama'},
                    {name: 'Status Karyawan'},
                    {name: 'Jabatan'},
                    {name: 'Status Pajak'},
                    {name: 'Gaji Pegawai'},
                    {name: 'Honorarium (T03)'},
                    {name: 'Honorarium Komite (T25)'},
                    {name: 'Tunjangan Tetap (T15)'},
                    {name: 'Tunjangan Penugasan (T24)'},
                    {name: 'Tunjangan Tidak Tetap (T26)'},
                    {name: 'Tunjangan Pengelola/Rapel (T01)'},
                    {name: 'Gaji/Upah Rapel (T27)'},
                    {name: 'BPJS1 Kes (T10)'},
                    {name: 'BPJS Per Kes (T21)'},
                    {name: 'BPJS Per KES KP (T21A)'},
                    {name: 'DPLK + Askom (T19)'},
                    {name: 'Dana Pensiun (T07)'},
                    {name: 'Tabungan Hari Tua (THT)'},
                    {name: 'Beban Transport Pengurus (COP)(T05)'},
                    {name: 'Asikom (T08)'},
                    {name: 'Tunjangan Bisnis (T18)'},
                    {name: 'Perumahan/Rapel (T02)'},
                    {name: 'Tunjangan Transport Pengawas (T04)'},
                    {name: 'Lembur Honor (T17)'},
                    {name: 'Kelebihan Potong (T14)'},
                    {name: 'Pembulatan (T13)'},
                    {name: 'BPJS 2 Ket (T09)'},
                    {name: 'YKPP (T11)'},
                    {name: 'BPJS Per Tgk (T20)'},
                    {name: 'BPJS Per Tgk KP (T20B)'},
                    {name: 'BPJS Per TGK Penyesuaian (T20A)'},
                    {name: 'Tunj Pajak Pengurus (T12)'},
                    {name: 'Tunj Pajak Dewas (T06)'},
                    {name: 'TPPH (T16)'},
                    {name: 'BPJS Ket Karyawan (P10)'},
                    {name: 'BPJS Karyawan Tgk (P17)'},
                    {name: 'BPJS Karyawan Tgk KP (P17a)'},
                    {name: 'BPJS Kes Kar (P13)'},
                    {name: 'BPJS Kes Karyawan (P19)'},
                    {name: 'BPJS Kes Kryw KP (P19A)'},
                    {name: 'BPJS Kes Kryw Tambahan (P19b)'},
                    {name: 'BPJS Per Tgk KP (P16a)'},
                    {name: 'BPJS Kes Per P22'},
                    {name: 'BPJS Per Tgk (P16)'},
                    {name: 'RTT (P30)'},
                    {name: 'BPJS Ket Per (P09)'},
                    {name: 'Potongan Kurang Bayar BPJSTK (P35)'},
                    {name: 'Potongan Lainnya (P36)'},
                    {name: 'BPJS Kes Per KP (P22a)'},
                    {name: 'BPJS Kes Per (P11)'},
                    {name: 'Potoingan Rumah Dinas (P12)'},
                    {name: 'COP (P01)'},
                    {name: 'Infaq/Sodaqoh (P08)'},
                    {name: 'Koperasi Kresna(P18)'},
                    {name: 'Koperasi (P05)'},
                    {name: 'Link Aja (P29)'},
                    {name: 'Potongan Keterlambatan (P21)'},
                    {name: 'Potongan SP (P04)'},
                    {name: 'Potongan Kehadiran (P20)'},
                    {name: 'Pot SP (P25)'},
                    {name: 'Link Aja (P15)'},
                    {name: 'YKPP (P24)'},
                    {name: 'Askom (P07)'},
                    {name: 'Dana Pensiun Kry (P02)'},
                    {name: 'Dana Pensiun Per (P14)'},
                    {name: 'DPLK + Askom (P26)'},
                    {name: 'DPLK(K)3 25 (P27)'},
                    {name: 'DPLK(K)4 75 (P28)'},
                    {name: 'Potongan Tabungan Hari Tua (P52)'},
                    {name: 'Taspen (P03)'},
                    {name: 'Potongan PPh Pengurus (P06)'},
                    {name: 'Potongan PPH Pegawai (P23)'},
                    {name: 'Potongan PPH 21 Pusat (P51)'},
                    {name: 'Gaji Di Transfer'}
                ],
                rows: data
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('N'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('O'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('P'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('Q'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('R'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('S'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('T'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('U'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('V'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('W'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('X'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('Y'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('Z'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AA'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AB'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AC'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AD'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AE'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AF'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AG'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AH'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AI'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AJ'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AK'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AL'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AM'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AN'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AO'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AP'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AQ'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AR'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AS'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AT'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AU'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AV'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AW'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AX'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AY'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AZ'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BA'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BB'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BC'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BD'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BE'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BF'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BG'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BH'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BI'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BJ'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BK'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BL'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BM'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BN'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BO'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BP'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BQ'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BR'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BS'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('BT'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            // const imagePath = path.resolve('img/logo.jpg');
            // const imageId = workbook.addImage({
            //    filename: fs.readFile(imagePath),
            //    extension: 'jpg'
            // });
            // worksheet.addImage(imageId, 'B3:C3');
            // worksheet.mergeCells('B3:C3');

            worksheet.getCell('D3').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D3').font = {size: 18, bold: true}
            
            worksheet.getCell('D4').value = 'REKAP GAJI';
            worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D4').font = {size: 18, bold: true}

            const WidthColums = 31;

            const Data1 = worksheet.getColumn(1);
            Data1.width = 10;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.numFmt = '#,##0.00';
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.numFmt = '#,##0.00';
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.numFmt = '#,##0.00';
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.numFmt = '#,##0.00';
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.numFmt = '#,##0.00';
            Data11.width = WidthColums;
            
            const Data12 = worksheet.getColumn(12);
            Data12.numFmt = '#,##0.00';
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.numFmt = '#,##0.00';
            Data13.width = WidthColums;

            const Data14 = worksheet.getColumn(14);
            Data14.numFmt = '#,##0.00';
            Data14.width = WidthColums;

            const Data15 = worksheet.getColumn(15);
            Data15.numFmt = '#,##0.00';
            Data15.width = WidthColums;

            const Data16 = worksheet.getColumn(16);
            Data16.numFmt = '#,##0.00';
            Data16.width = WidthColums;

            const Data17 = worksheet.getColumn(17);
            Data17.numFmt = '#,##0.00';
            Data17.width = WidthColums;

            const Data18 = worksheet.getColumn(18);
            Data18.numFmt = '#,##0.00';
            Data18.width = WidthColums;

            const Data19 = worksheet.getColumn(19);
            Data19.numFmt = '#,##0.00';
            Data19.width = WidthColums;

            const Data20 = worksheet.getColumn(20);
            Data20.numFmt = '#,##0.00';
            Data20.width = WidthColums;
            
            const Data21 = worksheet.getColumn(21);
            Data21.numFmt = '#,##0.00';
            Data21.width = WidthColums;

            const Data22 = worksheet.getColumn(22);
            Data22.numFmt = '#,##0.00';
            Data22.width = WidthColums;

            const Data23 = worksheet.getColumn(23);
            Data23.numFmt = '#,##0.00';
            Data23.width = WidthColums;

            const Data24 = worksheet.getColumn(24);
            Data24.numFmt = '#,##0.00';
            Data24.width = WidthColums;

            const Data25 = worksheet.getColumn(25);
            Data25.numFmt = '#,##0.00';
            Data25.width = WidthColums;

            const Data26 = worksheet.getColumn(26);
            Data26.numFmt = '#,##0.00';
            Data26.width = WidthColums;

            const Data27 = worksheet.getColumn(27);
            Data27.numFmt = '#,##0.00';
            Data27.width = WidthColums;

            const Data28 = worksheet.getColumn(28);
            Data28.numFmt = '#,##0.00';
            Data28.width = WidthColums;

            const Data29 = worksheet.getColumn(29);
            Data29.numFmt = '#,##0.00';
            Data29.width = WidthColums;

            const Data30 = worksheet.getColumn(30);
            Data30.numFmt = '#,##0.00';
            Data30.width = WidthColums;

            const Data31 = worksheet.getColumn(31);
            Data31.numFmt = '#,##0.00';
            Data31.width = WidthColums;

            const Data32 = worksheet.getColumn(32);
            Data32.numFmt = '#,##0.00';
            Data32.width = WidthColums;
            
            const Data33 = worksheet.getColumn(33);
            Data33.numFmt = '#,##0.00';
            Data33.width = WidthColums;

            const Data34 = worksheet.getColumn(34);
            Data34.numFmt = '#,##0.00';
            Data34.width = WidthColums;

            const Data35 = worksheet.getColumn(35);
            Data35.numFmt = '#,##0.00';
            Data35.width = WidthColums;

            const Data36 = worksheet.getColumn(36);
            Data36.numFmt = '#,##0.00';
            Data36.width = WidthColums;

            const Data37 = worksheet.getColumn(37);
            Data37.numFmt = '#,##0.00';
            Data37.width = WidthColums;

            const Data38 = worksheet.getColumn(38);
            Data38.numFmt = '#,##0.00';
            Data38.width = WidthColums;

            const Data39 = worksheet.getColumn(39);
            Data39.numFmt = '#,##0.00';
            Data39.width = WidthColums;

            const Data40 = worksheet.getColumn(40);
            Data40.numFmt = '#,##0.00';
            Data40.width = WidthColums;
            
            const Data41 = worksheet.getColumn(41);
            Data41.numFmt = '#,##0.00';
            Data41.width = WidthColums;

            const Data42 = worksheet.getColumn(42);
            Data42.numFmt = '#,##0.00';
            Data42.width = WidthColums;

            const Data43 = worksheet.getColumn(43);
            Data43.numFmt = '#,##0.00';
            Data43.width = WidthColums;

            const Data44 = worksheet.getColumn(44);
            Data44.numFmt = '#,##0.00';
            Data44.width = WidthColums;
            
            const Data45 = worksheet.getColumn(45);
            Data45.numFmt = '#,##0.00';
            Data45.width = WidthColums;

            const Data46 = worksheet.getColumn(46);
            Data46.numFmt = '#,##0.00';
            Data46.width = WidthColums;

            const Data47 = worksheet.getColumn(47);
            Data47.numFmt = '#,##0.00';
            Data47.width = WidthColums;

            const Data48 = worksheet.getColumn(48);
            Data48.numFmt = '#,##0.00';
            Data48.width = WidthColums;
            
            const Data49 = worksheet.getColumn(49);
            Data49.numFmt = '#,##0.00';
            Data49.width = WidthColums;

            const Data50 = worksheet.getColumn(50);
            Data50.numFmt = '#,##0.00';
            Data50.width = WidthColums;

            const Data51 = worksheet.getColumn(51);
            Data51.numFmt = '#,##0.00';
            Data51.width = WidthColums;

            const Data52 = worksheet.getColumn(52);
            Data52.numFmt = '#,##0.00';
            Data52.width = WidthColums;
   
            const Data53 = worksheet.getColumn(53);
            Data53.width = WidthColums;

            const Data54 = worksheet.getColumn(54);
            Data54.numFmt = '#,##0.00';
            Data54.width = WidthColums;

            const Data55 = worksheet.getColumn(55);
            Data55.numFmt = '#,##0.00';
            Data55.width = WidthColums;

            const Data56 = worksheet.getColumn(56);
            Data56.numFmt = '#,##0.00';
            Data56.width = WidthColums;

            const Data57 = worksheet.getColumn(57);
            Data57.numFmt = '#,##0.00';
            Data57.width = WidthColums;

            const Data58 = worksheet.getColumn(58);
            Data58.numFmt = '#,##0.00';
            Data58.width = WidthColums;

            const Data59 = worksheet.getColumn(59);
            Data59.numFmt = '#,##0.00';
            Data59.width = WidthColums;

            const Data60 = worksheet.getColumn(60);
            Data60.numFmt = '#,##0.00';
            Data60.width = WidthColums;

            const Data61 = worksheet.getColumn(61);
            Data61.numFmt = '#,##0.00';
            Data61.width = WidthColums;

            const Data62 = worksheet.getColumn(62);
            Data62.numFmt = '#,##0.00';
            Data62.width = WidthColums;

            const Data63 = worksheet.getColumn(63);
            Data63.numFmt = '#,##0.00';
            Data63.width = WidthColums;

            const Data64 = worksheet.getColumn(64);
            Data64.numFmt = '#,##0.00';
            Data64.width = WidthColums;

            const Data65 = worksheet.getColumn(65);
            Data65.numFmt = '#,##0.00';
            Data65.width = WidthColums;

            const Data66 = worksheet.getColumn(66);
            Data66.numFmt = '#,##0.00';
            Data66.width = WidthColums;

            const Data67 = worksheet.getColumn(67);
            Data67.numFmt = '#,##0.00';
            Data67.width = WidthColums;

            const Data68 = worksheet.getColumn(68);
            Data68.numFmt = '#,##0.00';
            Data68.width = WidthColums;

            const Data69 = worksheet.getColumn(69);
            Data69.numFmt = '#,##0.00';
            Data69.width = WidthColums;

            const Data70 = worksheet.getColumn(70);
            Data70.numFmt = '#,##0.00';
            Data70.width = WidthColums;

            const Data71 = worksheet.getColumn(71);
            Data71.numFmt = '#,##0.00';
            Data71.width = WidthColums;

            const Data72 = worksheet.getColumn(72);
            Data72.numFmt = '#,##0.00';
            Data72.width = WidthColums;

            const Data73 = worksheet.getColumn(73);
            Data73.numFmt = '#,##0.00';
            Data73.width = WidthColums;

            const Data74 = worksheet.getColumn(74);
            Data74.numFmt = '#,##0.00';
            Data74.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_REKAP_GAJI.xlsx`
                )
            })

        } catch (error) {
            console.log(error);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.exportExcel()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            }
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Rekap Gaji'}
                width={'70vw'}
                heigth={'30vh'}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <DevExpressDataGrid
                    ref={this.DataGridRekapTrsRef}
                    loadAPI={`pembayaran-umums/getRekapGaji/${this.state.DataMaster.id || 0}/${this.state.DataMaster.programId || 0}/${this.state.DataMaster.tahun || 0}/${this.state.DataMaster.bulan || 0}`}
                    insertAPI='daftar-penerima-thr'
                    updateAPI='daftar-penerima-thr'
                    deleteAPI='daftar-penerima-thr'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    useArraySource={true}
                    ArraySourceData={this.dataSource}//{this.loadData}
                    keyField={'nik'}
                    exportExcel={false}
                    exportFileName={"Daftar Rekap Gaji"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Rekap Gaji'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.rekapColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    height={"calc(70vh - 170px)"}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ViewPenggajianKaryawan
                    ref={this.showModalSlipGajiRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}


export default FormDetailGaji
