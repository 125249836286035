import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { months, gender, participants, product, statusIuran } from 'dataSource/lookup';
import { formatNumber, formatNumberAum } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';

class ModalDetailProsesIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData: {},
            dataMaster: {},
            getUrl: `view-iuran-detil-summaries?iuranId.in=0`
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options: {
            //         'text': 'Simpan',
            //         onClick: () => {
            //             console.log('simpan')
            //         }
            //     },
            //     toolbar: 'bottom'
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    alignment: 'left',
                    text: 'Pemberi Kerja'
                },
                // editorType: 'dxSelectBox',
                // editorOptions: {
                //     dataSource: participants(this.props.store),
                //     valueExpr: "id", // contains the same values as the "statusId" field provides
                //     displayExpr: "participantName", // provides display values
                //     searchEnabled: true,
                //     deferRendering: false,
                // }
            },
            {
                dataField: 'produk',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
            },
            {
                dataField: 'bulan',
                label: {
                    alignment: 'left',
                    text: 'Periode Bulan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                }
                
            },
            {
                dataField: 'tahun',
                label: {
                    alignment: 'left',
                    text: 'Periode Tahun'
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Bayar'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                },
                visible: false
            },
            
        ]

        this.dataGridColumn = [
            {
               dataField: 'id',
               caption: 'No',
               cellRender : (e) => {
                   return e.rowIndex + 1
               }
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'nikPanjang',
                caption: 'NIK Panjang'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Karyawan'
            },
            {
                dataField: 'phdp',
                caption: 'Upah Pokok Pensiun',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'rapelUpahPokokPensiun',
                caption: 'Rapel Upah Pokok Pensiun',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'phdp',
                caption: 'Dasar Perhitungan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'dapenPerusahaan',
                caption: 'Dapen Perusahaan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'dapenKaryawan',
                caption: 'Dapen Karyawan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'totalDapen',
                caption: 'Total Dapen',
                format: '#,##0.00',
                alignment: 'right'
                // cellRender: (e) => {
                //     var data = e.row.data
                //     console.log(data);

                //     return formatNumber(data.dapenKaryawan || 0 + data.dapenPerusahaan || 0)
                // }
            },
            // {
            //     dataField: 'sex',
            //     caption: 'Jenis Kelamin',
            //     lookup:{
            //         dataSource: gender(),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'value' 
            //     }
            // },
            // {
            //     dataField: 'tanggalLahir',
            //     caption: 'Tanggal Lahir',
            //     type : 'date',
            //     format: 'dd MMM yyyy'
            // },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',  
                format: 'dd MMM yyyy',
                alignment: 'center'
            },
            {
                dataField: 'statusIuran',
                caption: 'Status Iuran',
                lookup:{
                    dataSource: statusIuran(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
        ]

        this.dataPesertaIuran = []

    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {},
            getUrl: `view-iuran-detil-summaries?iuranId.in=0`
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

        await this.props.submitData(dataSubmit)
        this.hide()
    }

    loadDataGrid = () => {
        return this.dataPesertaIuran
    }
    retrieveData = async(data) => { 
        let store = this.props.store
        store.dispatch(showLoading(true))
        this.setState({
            dataMaster: data,
            getUrl: `view-iuran-detil-summaries?iuranId.in=${data.id}`
        })
        // var DataPesertaIuran = []

        // var loadAPIIuranByAkun = `iuran-by-akuns/getAllByIuranId/${data.id}`
        // var dataIuranByAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIIuranByAkun, 'GET')
        
        // if(dataIuranByAkun.length > 0){
        //     for(var iuranAkun of dataIuranByAkun){
        //         var loadAPIAkun = `kepersertaan-products/${iuranAkun.pesertaAkunProdukId}`
        //         var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
                
        //         var loadAPIPeserta = `simple-pesertas/getSimpleById/${dataAkun.pesertaId}`
        //         var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
                
        //         var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${dataPeserta.id}`
        //         var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')

        //         var loadAPIuranDetail = `iuran-details/getAllByPesertaAkunProductId/${iuranAkun.pesertaAkunProdukId}`
        //         var dataIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')

        //         var loadAPIuranType = `iuran-types`
        //         var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranType, 'GET')
                
        //         var dapenPerusahaan = 0
        //         var dapenKaryawan = 0
                
        //         for(var iuranByType of dataIuranDetail){
        //             var findIuranByType = dataIuranTypes.find(val => val.id == iuranByType.iuranTypeId)
        //             if(findIuranByType.iuranPeserta){
        //                 dapenKaryawan = dapenKaryawan + iuranByType.iuranNominal
        //             }else{
        //                 dapenPerusahaan = dapenPerusahaan + iuranByType.iuranNominal
        //             }
        //         }
    
        //         var dataPush = {
        //             id: iuranAkun.id,
        //             nik : dataPeserta.nik,
        //             nikPanjang: getKepegawaian.nikPanjang,
        //             namaKaryawan : dataPeserta.fullName,
        //             sex : dataPeserta.sex,
        //             tanggalLahir : dataPeserta.birthDate,
        //             noDapen: dataAkun.cardNo,
        //             rapelUpahPokokPensiun: 0,
        //             phdp: getKepegawaian.phdp,
        //             dapenPerusahaan: dapenPerusahaan,
        //             dapenKaryawan : dapenKaryawan,
        //             totalDapen : dapenPerusahaan + dapenKaryawan,
        //             statusIuran : data.statusId
        //         }
        //         DataPesertaIuran.push(dataPush)
        //     }
        //     this.dataPesertaIuran = DataPesertaIuran
            
        //     this.setState({
        //         dataMaster: data,
        //     })
        // }
        store.dispatch(showLoading(false))
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Proses Iuran Bulanan'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='pembayaran-mp-bulanan'
                                updateAPI='pembayaran-mp-bulanan'
                                deleteAPI='pembayaran-mp-bulanan' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}
                                remoteOperations = {true}
                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Proses Iuran Bulanan Detail"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Proses Iuran Bulanan Detail'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}
                                
                                height={'calc(65vh - 150px)'}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalDetailProsesIuran