import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, programs, sdmUnit, tipeTransaksiSdmUmum, unit } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ScrollView from 'devextreme-react/scroll-view';
import { alert } from 'devextreme/ui/dialog';
import PPUPengajuanPembayaranActionDetailModal from './actionDetail';
import PPUPengajuanPembayaranActionDokumenModal from './actionDokumen';
import { download, formatNumberAum, imageSource } from 'plugin/helper';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class PPUPengajuaPembayaranModal extends Component {
    constructor(props) {
        super(props)

        this.user = props.store.getState().sessionUser ? props.store.getState().getParam : null

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            newData: true
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.readOnly = ''
        this.PopupRef = React.createRef()
        this.modalSkKaryawanRef = React.createRef()
        this.DataGridDetailRef = React.createRef()
        this.DataGridDocumentRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.detailBiayaRef = React.createRef()
        this.documentDetailRef = React.createRef()

        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.dataGridDetail = []
        this.dataGridDokumen = []
        this.prevTabIndex = 0

        this.PopupToolbarItemAdd = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.PopupToolbarItemEdit = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'simpan',
                    onClick: this.updateData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'programId',
                label: {
                    alignment: 'left',
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                },
            },
            {
                dataField: 'noPengajuan',
                label: {
                    alignment: 'left',
                    text: 'No Pengajuan'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                    displayFormat: "dd MMM yyyy",
                    width: '100%'
                }
            },
            {
                dataField: 'requestBy',
                label: {
                    alignment: 'left',
                    text: 'Diajukan Oleh'
                },
                editorOptions: {
                    // value : this.props.store.getState().sessionUser,
                    readOnly: true
                }
            },
            {
                dataField: 'unitId',
                label: {
                    alignment: 'left',
                    text: 'Unit Kerja'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: sdmUnit(this.props.store),
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: false
                },
            },
            {
                dataField: 'entitiId',
                label: {
                    text: 'Pembayaran Kepada',
                    alignment: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'keterangan',
                label: {
                    alignment: 'left',
                    text: 'Catatan Pengajuan'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    width: '100%'
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'Catatan Pengajuan Harus Diisi'
                    },
                    {
                        type: 'stringLength',
                        max: 255,
                        message: 'Tidak Bisa Lebih Dari 255 Karakter'
                    },
                ],
            },

        ]

        this.detailColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.deleteDataGrid(this.DataGridDetailRef, e.row.data.id)
                        }
                    },
                ],
            },

            {
                dataField: "tujuanRekNo",
                caption: "Nomor Rekening",
                dataType: 'number',
                alignment: 'right',
            },
            {
                dataField: "pengajuanNominal",
                caption: "Nominal Pengajuan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: 'transactionTypeId',
                caption: 'Tipe Transaksi',
                lookup: {
                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'transactionTypeName'
                }
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                dataField: "ppn",
                caption: "PPN",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "pph",
                caption: "PPH",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "biayaLain",
                caption: "Biaya Lain",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "potongan",
                caption: "Potongan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "pengajuanNominalNett",
                caption: "Nominal Bersih",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "cash",
                caption: "Cash",
                dataType: 'boolean'
            },
            {
                dataField: "transfer",
                caption: "Transfer",
                dataType: 'boolean'
            },
            {
                dataField: "cashOut",
                caption: "Cash Out",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "dpAmount",
                caption: "Uang Muka",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "tujuanRekName",
                caption: "Nama Rekening",
            },
            {
                dataField: "tujuanRekBankId",
                caption: "Bank",
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'initialName'
                }
            },
            {
                dataField: "tujuanRekCabang",
                caption: "Cabang",
            },
        ]

        this.documentColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Download',
                        hint: 'Download',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.downloadFile(e.row.data)
                        }
                    },
                    {
                        text: 'Preview',
                        hint: 'Preview',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.previewFile(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.deleteDataGrid(this.DataGridDocumentRef, e.row.data.id)
                        }
                    },
                ],
            },
            {
                dataField: 'dokumenName',
                caption: 'Nama Dokumen',
            }
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Detail Pembayaran'
            },
            {
                id: 1,
                text: 'Dokumen'
            },
        ]
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabModalindex-${this.props.action}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabModalindex-${this.props.action}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    newData = (newData) => {
        this.setState({
            newData: newData
        })
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.dataGridDetail = []
        this.dataGridDokumen = []
        this.DataGridDetailRef.current.forceRefresh(true)
        this.DataGridDocumentRef.current.forceRefresh(true)
        this.setState({
            popupVisible: false,
            dataMaster: {},
            newData: true
        })
    }

    getUnitUserLogin = async () => {
        // var data = this.state.dataMaster
        try {
            var getkaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByUsername/${this.props.store.getState().sessionUser}`, 'GET')
            // this.formMasterRef.current.instance.updateData({ requestBy: this.props.store.getState().sessionUser })

            if (getkaryawan.length !== 0) {
                this.formMasterRef.current.instance.updateData({ unitId: getkaryawan.unitId, requestBy: this.props.store.getState().sessionUser })
                // this.setState({
                //     dataMaster: {
                //         ...data,
                //         unitId: getkaryawan.unitId 
                //     }
                // })
            }
        } catch (e) {
            console.log(e);
            // this.formMasterRef.current.instance.updateData({ unitId: null })
            // this.setState({
            //     dataMaster: {
            //         ...data,
            //         unitId: null 
            //     }
            // })
        }

    }

    getEntity = async () => {
        var paramEntity = ['KR', 'SP', 'P3', 'CN', 'GV']
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllMultipleByType/${paramEntity.join()}`, 'GET')
        this.formMasterRef.current.instance.getEditor('entitiId').option('dataSource', data)
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    retrieveData = async (data) => {
        var loadAPI = `pembayaran-umums/${data.id}`
        var getPU = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')
        this.PopupRef.current.instance.option('toolbarItems', this.PopupToolbarItemEdit)

        var getkaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByUsername/${this.props.store.getState().sessionUser}`, 'GET')

        var masterData = {
            ...getPU,
            unitId: getkaryawan.unitId
        }
        
        this.setState({
            dataMaster: masterData,
        })

        this.DataGridDetailRef.current.forceRefresh(true)
        this.DataGridDocumentRef.current.forceRefresh(true)
    }

    submitData = async () => {
        var data = this.state.dataMaster
        var detailData = this.DataGridDetailRef.current.getDataSource()
        var dokumenData = this.DataGridDocumentRef.current.getDataSource()
        for (var detil of detailData) {
            if (typeof detil.id === 'string') {
                delete detil.id
            }
        }
        for (var dokumen of dokumenData) {
            if (typeof dokumen.id === 'string') {
                delete dokumen.id
            }
        }

        var pengajuanData = {
            ...data,
            "diAjukanOleh": this.props.store.getState().sessionUser,
            "pembayaranUmumDetilDTOList": detailData,
            "pembayaranUmumDokumenDTOList": dokumenData,
        }

        try {
            var insertAPI = 'pengajuan-pembayaran-umums'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, insertAPI, 'POST', {
                values: pengajuanData
            })
            var title = 'Berhasil!'
            var text = 'Data Berhasil Ditambahkan!'
        } catch (e) {
            console.log(e)
            var title = 'Gagal!'
            var text = e
        }
        await alert(text, title)
        this.props.forceRefresh()
        this.hide()
    }

    updateData = async () => {
        var data = this.state.dataMaster
        // console.log(data)
        // return null
        try {
            var insertAPI = 'pembayaran-umums'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, insertAPI, 'PUT', {
                key: data.id,
                values: data
            }, data)

            if (response) {
                var detailData = this.DataGridDetailRef.current.getDataSource()
                var dokumenData = this.DataGridDocumentRef.current.getDataSource()
                try {
                    // Add and Edit Pengajuan Detil
                    for (var detil of detailData) {
                        if (typeof detil.id === 'string') {
                            delete detil.id
                            detil.pembayaranUmumId = response.id
                            detil.unitId = response.unitId
                            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-detils', 'POST', {
                                values: detil
                            })
                        } else {
                            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-detils', 'PUT', {
                                key: detil.id,
                                values: detil
                            }, detil)
                        }
                    }

                    // Delete Pengajuan Detil
                    var getDeletedDetil = this.DataGridDetailRef.current.getDeletedIndex()
                    for (var dataDelete of getDeletedDetil) {
                        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-detils', 'DELETE', dataDelete)
                    }

                    // Add and Edit Pengajuan Document
                    for (var dokumen of dokumenData) {
                        if (typeof dokumen.id === 'string') {
                            delete dokumen.id
                            dokumen.pembayaranUmumId = response.id
                            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-dokumen', 'POST', {
                                values: dokumen
                            })
                        } else {
                            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-dokumen', 'PUT', {
                                key: dokumen.id,
                                values: dokumen
                            }, dokumen)
                        }
                    }

                    // Delete Pengajuan Document
                    var getDeletedDoc = this.DataGridDocumentRef.current.getDeletedIndex()
                    for (var dataDelete of getDeletedDoc) {
                        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-dokumen', 'DELETE', dataDelete)
                    }
                } catch (e) {
                    console.log(e)
                    var title = 'Gagal Simpan Detil'
                    var text = e
                }
            }
            var title = 'Berhasil!'
            var text = 'Data Berhasil Diubah!'
        } catch (e) {
            console.log(e)
            var title = 'Gagal!'
            var text = e
        }
        await alert(text, title)
        this.props.forceRefresh()
        this.hide()
    }

    showModalDetail = (data) => {
        this.detailBiayaRef.current.show(this.state.dataMaster)
        this.detailBiayaRef.current.getEntityId(this.state.dataMaster, data)
        if (data) {
            this.detailBiayaRef.current.retrieveData(data)
        }
    }

    showModalDokumen = (data) => {
        this.documentDetailRef.current.show()
        if (data) {
            this.documentDetailRef.current.retrieveData(data)
        }
    }

    downloadFile = (data) => {
        download(imageSource(data.dokumenFileContentType, data.dokumenFile), data.dokumenName)
    }

    previewFile = async (data) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            var src = imageSource(data.dokumenFileContentType, data.dokumenFile)
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    onToolbarPreparingDetail = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: () => {
                        this.showModalDetail()
                    }
                }
            },
        )
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        ) 
    }

    transactionTypeId = async () => {

    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `pembayaran-umum-detils/getByPembayaranUmumId/${this.state.dataMaster.id || 0}?size=99999`, 'GET');

        for (let value of response){
            let getTransactionId = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `transaction-types/${value.transactionTypeId}`);
            let dataResult = {
                tujuanRekNo: value.tujuanRekNo ? value.tujuanRekNo : '-',
                pengajuanNominal: value.pengajuanNominal ? formatNumberAum(value.pengajuanNominal) : 0,
                transactionTypeId: getTransactionId.transactionTypeName ? getTransactionId.transactionTypeName : '-',
                keterangan: value.keterangan ? value.keterangan : '-',
                ppn: value.ppn ? formatNumberAum(value.ppn) : 0,
                pph: value.pph ? formatNumberAum(value.pph) : 0,
                biayaLain: value.biayaLain ? formatNumberAum(value.biayaLain) : 0,
                potongan: value.potongan ? formatNumberAum(value.potongan) : 0,
                pengajuanNominalNett: value.pengajuanNominalNett ? formatNumberAum(value.pengajuanNominalNett) : 0,
                cash: value.cash ? value.cash : '-',
                transfer: value.transfer ? value.transfer : '-',
                cashOut: value.cashOut ? formatNumberAum(value.cashOut) : 0,
                dpAmount: value.dpAmount ? formatNumberAum(value.dpAmount) : 0,
                tujuanRekName: value.tujuanRekName ? value.tujuanRekName : '-',
                tujuanRekBankId: value.tujuanRekBankId ? value.tujuanRekBankId : '-',
                tujuanRekCabang: value.tujuanRekCabang ? value.tujuanRekCabang : '-'
            }
            const objectValue = Object.values(dataResult);
            data.push(objectValue);
        }
        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('PENGAJUAN PEMBAYARAN');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,   
                },
                columns: [
                    {name: 'Nomor Rekening'},
                    {name: 'Nominal Pengajuan'},
                    {name: 'Tipe Transaksi'},
                    {name: 'Keterangan'},
                    {name: 'PPN'},
                    {name: 'PPH'},
                    {name: 'Biaya Lain'},
                    {name: 'Potongan'},
                    {name: 'Nominal Bersih'},
                    {name: 'Cash'},
                    {name: 'Transfer'},
                    {name: 'Cash Out'},
                    {name: 'Uang Muka'},
                    {name: 'Nama Rekening'},
                    {name: 'Bank'},
                    {name: 'Cabang'},
                ],
                rows: data
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'PENGAJUAN PEMBAYARAN';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums; 

            const Data3 = worksheet.getColumn(3);
            Data3.width = 45;

            const Data4 = worksheet.getColumn(4);
            Data4.width = 45;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;

            const Data14 = worksheet.getColumn(14);
            Data14.width = WidthColums;

            const Data15 = worksheet.getColumn(15);
            Data15.width = WidthColums;

            const Data16 = worksheet.getColumn(16);
            Data16.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `PENGAJUAN_PEMBAYARAN.xlsx`
                );
            });
        } catch (error) {
            console.log(error);
        }
    }

    onToolbarPreparingDocument = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: () => {
                        this.showModalDokumen()
                    }
                }
            },
        )
    }

    editDataGrid = async (type, data) => {
        data.transfer = data.metodePembayaran === 1 ? true : false
        data.cash = data.metodePembayaran === 2 ? true : false
        if (type == 'detail') {
            var dataGrid = this.DataGridDetailRef
        } else {
            var dataGrid = this.DataGridDocumentRef
        }
        try {
            var key = data.id
            if (key) {
                dataGrid.current.push([
                    {
                        type: 'update',
                        data: data,
                        key: key
                    }
                ])
            } else {
                dataGrid.current.push([
                    {
                        type: 'insert',
                        data: data
                    }
                ])
            }

            var title = 'Berhasil!'
            var text = 'Data Berhasil Ditambahkan!'

        } catch (e) {
            console.log(e)
            var title = 'Gagal!'
            var text = e
        }
        await alert(text, title)
    }

    deleteDataGrid = async (dataGrid, key) => {
        dataGrid.current.push([
            {
                type: 'remove',
                key: key
            }
        ])
        var title = 'Berhasil!'
        var text = 'Data Berhasil Dihapus!'

        await alert(text, title)
    }

    forceRefresh = (type) => {
        if (type == 'detail') {
            var dataGrid = this.DataGridDetailRef
        } else {
            var dataGrid = this.DataGridDocumentRef
        }

        dataGrid.current.forceRefresh(true)
    }

    retrieveDataDetil = async () => {
        var data = this.state.dataMaster
        var loadAPI = `pembayaran-umum-detils/getByPembayaranUmumId/${data.id || 0}?size=9999`;
        this.dataGridDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        return this.dataGridDetail
    }

    retrieveDataDokumen = async () => {
        var data = this.state.dataMaster
        var loadAPI = `pembayaran-umum-dokumen/getByPembayaranUmumId/${this.state.dataMaster.id || 0}?size=9999`;
        this.dataGridDokumen = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        return this.dataGridDokumen
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengajuan Pembayaran'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.props.action == "add" ? this.PopupToolbarItemAdd : this.PopupToolbarItemEdit}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{ height: '100%' }}>
                        <div className="mb-5">
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation='left'
                                readOnly={this.readOnly}
                            />
                        </div>
                        <DevextremeTab
                            dataSource={this.TabDataSource}
                            onOptionChanged={this.onTabSelectionChanged}
                        />
                        <div id={`tabModalindex-${this.props.action}-0`} className={' mt-2 text-center'} style={{ height: '100%' }}>
                            <div className="container-fluid">
                                <DevExpressDataGrid
                                    ref={this.DataGridDetailRef}
                                    loadAPI={`pembayaran-umum-detils/getByPembayaranUmumId/${this.state.dataMaster.id || 0}`}
                                    insertAPI='pembayaran-umum-detils'
                                    updateAPI='pembayaran-umum-detils'
                                    deleteAPI='pembayaran-umum-detils'

                                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                                    // remoteOperations = {true}

                                    useArraySource={true}
                                    ArraySourceData={this.retrieveDataDetil}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    editingMode={'popup'}
                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Detail Biaya'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={400} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form
                                    height={400}
                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.detailColumn} // taruh konfigurasi kolom disini
                                    onToolbarPreparing={this.onToolbarPreparingDetail}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                        <div id={`tabModalindex-${this.props.action}-1`} className={'d-none mt-2 text-center'}>
                            <div className="container-fluid">
                                <DevExpressDataGrid
                                    ref={this.DataGridDocumentRef}
                                    loadAPI={`pembayaran-umum-dokumen/getByPembayaranUmumId/${this.state.dataMaster.id || 0}`}
                                    insertAPI='pembayaran-umum-dokumen'
                                    updateAPI='pembayaran-umum-dokumen'
                                    deleteAPI='pembayaran-umum-dokumen'

                                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                                    useArraySource={true}
                                    ArraySourceData={this.retrieveDataDokumen}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    editingMode={'popup'}
                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Document'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={400} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form
                                    height={400}
                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.documentColumn} // taruh konfigurasi kolom disini
                                    onToolbarPreparing={this.onToolbarPreparingDocument}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <PPUPengajuanPembayaranActionDetailModal
                    ref={this.detailBiayaRef}
                    store={this.props.store}
                    editDataGrid={this.editDataGrid}
                />
                <PPUPengajuanPembayaranActionDokumenModal
                    ref={this.documentDetailRef}
                    store={this.props.store}
                    editDataGrid={this.editDataGrid}
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default PPUPengajuaPembayaranModal