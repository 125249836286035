import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import ModalFixedAssetGroup from 'components/pages/modal/akutansi/fixedAssetGroup/index'
import { departments, depreciationMethod } from 'dataSource/lookup'

class FixedAssetGroup extends Component {
    constructor(props) {
        super(props)

        this.DataGridRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data, 'edit')
                        }
                    },
                ],
            },
            {
                dataField: 'assetGroupCode',
                caption: 'Aset Group Code'
            },
            {
                dataField: 'assetGroupName',
                caption: 'Aset Group Name'
            },
            {
                dataField: 'assetGroupDesc',
                caption: 'Aset Group Description'
            },
            {
                dataField: 'assetType',
                caption: 'Kelompok Aset',
                lookup: {
                    dataSource: [
                      {
                        id: 'TNBG',
                        value: 'Tanah dan Bangunan'
                      },
                      {
                        id: 'KNDR',
                        value: 'Kendaraan'
                      },
                      {
                        id: 'PKOM',
                        value: 'Peralatan Komputer'
                      },
                      {
                        id: 'PKAN',
                        value: 'Peralatan Kantor'
                      },
                      {
                        id: 'ASOL',
                        value: 'Aset Operasional Lain'
                      },
                      {
                        id: 'ASLN',
                        value: 'Aset Lain'
                      },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                  },
            },
            {
                dataField: 'depreciationMethod',
                caption: 'Depreciation Method',
                lookup: {
                    dataSource: depreciationMethod(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'economicLife',
                caption: 'Economic Life (Bulan)'
            },
            {
                dataField: 'departmentId',
                caption: 'Department Code',
                lookup: {
                    dataSource: departments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'departmentCode'
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah',
                    onClick: (e) => {
                        this.showModal(null, 'add')
                    }
                }
            }
        )
    }
    showModal = (data = null, type) => {
        this.modalRef.current.show()
        this.modalRef.current.retrieveData(data, type)
    }
    forceRefresh = () => {
        this.DataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Grup Aset Tetap</h2>

                <DevExpressDataGrid
                    loadAPI='fixed-asset-groups'
                    insertAPI='fixed-asset-groups'
                    updateAPI='fixed-asset-groups'
                    deleteAPI='fixed-asset-groups'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Fixed Asset Group"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Fixed Asset Group Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    ref={this.DataGridRef}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalFixedAssetGroup
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default FixedAssetGroup