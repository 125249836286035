import React, { Component } from 'react'
import { DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { currency, coa, departments, product } from 'dataSource/lookup';

class DetailJurnal extends Component {
    constructor(props){
        super(props)
        this.dataSource  = new CustomStore({
            load: async(loadOptions) => {
                var journalHeaderId = this.props.journalHeaderId
                var loadAPI = `journal-detils/getAllByJournalHeaderId/${journalHeaderId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET')

                response = response.map(value => {

                    value.debet = value.dc === 'D' ? value.amount : null
                    value.credit = value.dc === 'C' ? value.amount : null
                    value.baseDebet = value.dc === 'D' ? value.amountBasedCurrency : null
                    value.baseCredit = value.dc === 'C' ? value.amountBasedCurrency : null

                    return value
                })

                return response
            },
            key: 'id',
        })

        this.columns = [
            {
                dataField: 'coa.coaCode',
                caption: 'Kode COA',
            },
            {
                dataField: 'coa.coaName',
                caption: 'Nama COA',
            },
            {
                dataField: 'currency.currencyCode',
                caption: 'Mata Uang',
            },
            {
                dataField: 'produkId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                },
            },
            {
                dataField: 'department.departmentName',
                caption: 'Department',
            },
            {
                dataField: 'debet',
                caption: 'Debet',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'credit',
                caption: 'Kredit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'baseDebet',
                caption: 'Debet (Home Ccy)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'baseCredit',
                caption: 'Kredit (Home Ccy)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
        ]
    }

    render() {
        return (
            <DataGrid
            id={"successDataGrid"}
            showBorders={false}
            dataSource={this.dataSource}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            // height={100}
            showScrollbar={"always"}
            // columns={this.columns}
            >
            {
                this.columns.map((column) => {
                    return <Column 
                        dataField={column.dataField} 
                        caption={column.caption} 
                        lookup={column.lookup} 
                        cellRender={column.cellRender} 
                        alignment={column.alignment || 'left'} 
                        cssClass={column.cssClass}
                        format = {column.format}
                        dataType = {column.dataType}
                        width = {column.width}
                        type = {column.type}
                        buttons = {column.buttons}
                        editorType = {column.editorType}
                        editorOptions = {column.editorOptions}
                        visible = {column.visible}
                        sortOrder = {column.sortOrder}
                        allowEditing = {column.allowEditing}
                        calculateCellValue = {column.calculateCellValue}
                    />
                })
            }
            {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailJurnal