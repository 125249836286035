import { karyawan, tunjanganHrd, months, provinces, countries, cities, participants, taxCategories, coa, currency, departments, custodies, emitens, instrumentCategories, instrumentTypes, managerInvestasis, paymentAgents, paymentPeriods, rateMethods, rateTypes, valuasiMethods, valuasiPeriodes, coaGroup, kecamatans, agendaType, metodeDepresiasi, tipeTransaksiKepesertaan, plbs, coaCategory, dc, komponenGaji, entityType, programs, banks, papanPencatatan, sektors, parentSektors, sektorsByParent, instrumentCategorySubs, ratings, dayBase, tipeTransaksiSdmUmum, akunHR, dasarNilai, tipeSubTransaksiSdmUmum, tunjanganGroup, tunjanganKategori, periodeTunjangan, jabatanGolongan, payrollKategoriSub, rekBankHrd, productDefault, statusAktifNonAktif, anggaranSubType, sdmUnit, anggaranType, statusAktifNonAktifRekBank, allAkunHR, kecamatanAll, divisi, akunInvestasi, aperds, brokerStaticData, kepersertaanAkunById, asetKategoriSdm } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import Instrument from '../static-data/instrument'
import { confirm } from 'devextreme/ui/dialog'
import { confirmAlert } from 'react-confirm-alert'

const loadColumnConfig = (config, store) => {
    switch (config) {
        case 'country': return country

        case 'province': return province(store)

        case 'city': return city(store)

        case 'postalCode': return postalCode(store)

        case 'bank': return bank

        case 'broker': return broker

        case 'custody': return custody

        case 'participant': return participant

        case 'dpcoresvcBranch': return branchs

        case 'article': return article

        case 'redaksi': return redaksi

        case 'visitor': return visitor

        case 'currencies': return currencies

        case 'fiscalYears': return fiscalYears

        case 'dpinvestasisvcEmiten': return emiten(store)

        case 'budget': return budget(store)

        case 'periodeConfig': return periodeConfig

        case 'kecamatan': return kecamatan(store)

        case 'kelurahan': return kelurahan(store)

        case 'product': return product(store)

        case 'kjpp': return kjpp

        case 'identityType': return identity

        case 'agendaType': return agendaTypes

        case 'agendaBisnis': return agendaBisnis(store)

        case 'holiday': return holiday

        case 'tipePembayaran': return tipePembayaran

        case 'dplk': return dplk

        case 'dpaccountingsvcTax': return tax(store)

        case 'dpaccountingsvcPeriodeConfig': return periodeConfig

        case 'dpaccountingsvcJournalType': return journalTypes()

        case 'dpaccountingsvcFiscalYear': return fiscalYears

        case 'dpaccountingsvcExchangeRate': return exchange(store)

        case 'dpaccountingsvcEntiti': return entity

        case 'dpaccountingsvcDepartment': return departements

        case 'dpaccountingsvcCurrency': return currencies

        case 'dpaccountingsvcCoa': return chartOfAccount(store)

        case 'dpaccountingsvcCoaGroup': return chartOfAccountGroup(store)

        case 'dpaccountingsvcAnggaranItem': return anggaran(store)

        case 'dphrdsvcEducation': return education

        case 'dphrdsvcGrade': return grade

        case 'dphrdsvcJabatan': return jabatan(store)

        case 'dphrdsvcGajiKomponen': return gajiKomponen

        case 'dphrdsvcGajiPotongan': return gajiPotongan

        case 'dphrdsvcTransactionType': return tipeTransaksiSdm(store)

        case 'dphrdsvcTransactionTypeSub': return subTipeTransaksiSdm(store)

        case 'dpinvestasisvcAperd': return aperd

        case 'dpinvestasisvcBroker': return broker(store)

        case 'dpinvestasisvcManagerInvestasi': return managerInvest()

        case 'dpinvestasisvcSektor': return sektor(store)

        case 'dpinvestasisvcInstrument': return instrument(store)

        case 'dpcoresvcParticipant': return clientpemberikerja

        case 'dpcoresvcKepersertaanTransactionType': return tipeTransaksiPeserta

        case 'dpcoresvcDokumenType': return tipeDokumenPeserta

        case 'dpcoresvcManfaatPensiun': return manfaatPensiun(store)

        case 'dpcoresvcManfaatPensiunType': return manfaatPensiun(store)

        case 'dpcoresvcRekbank': return rekBank(store)

        case 'dphrdsvcPeriodeTunjangan': return periodeTunjanganStaticData

        case 'dphrdsvcLemburType': return lembur(store)

        case 'dphrdsvcSupplier': return supplier(store)

        case 'dphrdsvcLoanType': return loanType(store)

        case 'dphrdsvcPeriodeCuti': return periodeCuti

        case 'dphrdsvcAkun': return akun(store)

        case 'dphrdsvcRekBank': return rekBankSdm(store)

        case 'dphrdsvcTunjanganType': return tipeTunjanganSdm(store)

        case 'dphrdsvcUnit': return unitSdm(store)

        case 'dphrdsvcAssetCategory': return kategoriAsset1(store)

        case 'dpinvestasisvcInstrumentTransactionType': return tipeTransaksiInvest(store)

        case 'dpinvestasisvcRekBank': return DataMasterBank(store)

        case 'dpinvestasisvcRekCustody': return DataMasterKustodi(store)

        case 'dpinvestasisvcRekBroker': return DataBrokerInvestasi(store)

        case 'dpinvestasisvcRekMi': return DataMasterManagerInvestasi(store)

        case 'dpinvestasisvcRekAperd': return DataRekeningAperdInvestasi(store)

        case 'dpaccountingsvcAnggaranType': return tipeAnggaran(store)

        case 'dpaccountingsvcAnggaranSubType': return subTipeAnggaran(store)

        default: return null

    }
}

export const lookupModule = [
    {
        "id": "country",
        "value": "Umum - Negara"
    },
    {
        "id": "province",
        "value": "Umum - Provinsi"
    },
    {
        "id": "city",
        "value": "Umum - Kota"
    },
    {
        "id": "postalCode",
        "value": "Umum - Kode Pos"
    },
    {
        "id": "bank",
        "value": "Perusahaan - bank"
    },
    {
        "id": "broker",
        "value": "Perusahaan - broker"
    },
    {
        "id": "custody",
        "value": "Perusahaan - Kustodi"
    },
    {
        "id": "dpinvestasisvcEmiten",
        "value": "Investasi - Emiten"
    },
    {
        "id": "kecamatan",
        "value": "Umum - Kecamatan"
    },
    {
        "id": "dpcoresvcBranch",
        "value": "Umum - Cabang"
    },
    {
        "id": "kelurahan",
        "value": "Umum - Kelurahan"
    },
    {
        "id": "product",
        "value": "Umum - Produk"
    },
    {
        "id": "kjpp",
        "value": "Perusahaan - KJPP"
    },
    {
        "id": "identityType",
        "value": "Umum - Identitas"
    },
    {
        "id": "agendaType",
        "value": "Umum - Tipe Agenda"
    },
    {
        "id": "agendaBisnis",
        "value": "Umum - Agenda Bisnis"
    },
    {
        "id": "holiday",
        "value": "Umum - Hari Libur"
    },
    {
        "id": "tipePembayaran",
        "value": "Umum - Tipe Pembayaran"
    },
    {
        "id": "dplk",
        "value": "Umum - DPLK"
    },
    {
        "id": "dpaccountingsvcTax",
        "value": "Akuntansi - Pajak"
    },
    {
        "id": "dpaccountingsvcPeriodeConfig",
        "value": "Akuntansi - Konfigurasi Periode"
    },
    {
        "id": "dpaccountingsvcJournalType",
        "value": "Akuntansi - Tipe Jurnal"
    },
    {
        "id": "dpaccountingsvcFiscalYear",
        "value": "Akuntansi - Tahun Fiskal"
    },
    {
        "id": "dpaccountingsvcExchangeRate",
        "value": "Akuntansi - Nilai Tukar"
    },
    {
        "id": "dpaccountingsvcEntiti",
        "value": "Akuntansi - Entitas"
    },
    {
        "id": "dpaccountingsvcDepartment",
        "value": "Akuntansi - Departemen"
    },
    {
        "id": "dpaccountingsvcCurrency",
        "value": "Akuntansi - Mata Uang"
    },
    {
        "id": "dpaccountingsvcCoa",
        "value": "Akuntansi - COA"
    },
    {
        "id": "dpaccountingsvcCoaGroup",
        "value": "Akuntansi - Grup COA"
    },
    {
        "id": "dpaccountingsvcAnggaranItem",
        "value": "Akuntansi - Anggaran"
    },
    {
        "id": "dphrdsvcEducation",
        "value": "SDM & Umum - Pendidikan"
    },
    {
        "id": "dphrdsvcJabatan",
        "value": "SDM & Umum - Jabatan"
    },
    {
        "id": "dphrdsvcGajiKomponen",
        "value": "SDM & Umum - Komponen Gaji"
    },
    {
        "id": "dphrdsvcGajiPotongan",
        "value": "SDM & Umum - Potongan Gaji"
    },
    {
        "id": "dphrdsvcTransactionType",
        "value": "SDM & Umum - Tipe Transaksi"
    },
    {
        "id": "dpinvestasisvcAperd",
        "value": "Perusahaan - APERD"
    },
    {
        "id": "dpinvestasisvcBroker",
        "value": "Perusahaan - Broker"
    },
    {
        "id": "dpinvestasisvcInstrument",
        "value": "Investasi - Instrumen"
    },
    {
        "id": "dpinvestasisvcManagerInvestasi",
        "value": "Perusahaan - MI"
    },
    {
        "id": "dpcoresvcParticipant",
        "value": "Perusahaan - Pemberi Kerja"
    },
    {
        "id": "dpcoresvcKepersertaanTransactionType",
        "value": "Kepesertaan - Tipe Transaksi"
    },
    {
        "id": "dpcoresvcDokumenType",
        "value": "Kepesertaan - Tipe Dokumen"
    },
    {
        "id": "dpcoresvcManfaatPensiun",
        "value": "Kepesertaan - Manfaat Pensiun"
    },
    {
        "id": "dpcoresvcManfaatPensiunType",
        "value": "Kepesertaan - Tipe Manfaat Pensiun"
    },
    {
        "id": "dpinvestasisvcSektor",
        "value": "Investasi - Sektor"
    },
    {
        "id": "dphrdsvcPeriodeTunjangan",
        "value": "SDM & Umum - Periode Tunjangan"
    },
    {
        "id": "dphrdsvcLemburType",
        "value": "SDM & Umum - Lembur"
    },
    {
        "id": "dphrdsvcSupplier",
        "value": "SDM & Umum - Supplier"
    },
    {
        "id": "dphrdsvcGrade",
        "value": "SDM & Umum - Grade"
    },
    {
        "id": "dphrdsvcLoanType",
        "value": "SDM & Umum - Tipe Pinjaman"
    },
    {
        "id": "dphrdsvcPeriodeCuti",
        "value": "SDM & Umum - Periode Cuti"
    },
    {
        "id": "dphrdsvcAkun",
        "value": "SDM & Umum - Akun"
    },
    {
        "id": "dphrdsvcRekBank",
        "value": "SDM & Umum - Rek Bank"
    },
    {
        "id": "dphrdsvcTunjanganType",
        "value": "SDM & Umum - Tipe Tunjangan"
    },
    {
        "id": "dphrdsvcTransactionTypeSub",
        "value": "SDM & Umum - Sub Tipe Transaksi"
    },
    {
        "id": "dphrdsvcUnit",
        "value": "SDM & Umum - Unit"
    },
    {
        "id": "dphrdsvcAssetCategory",
        "value": "SDM & Umum - kategori Aset"
    },
    {
        "id": "dpinvestasisvcInstrumentTransactionType",
        "value": "Investasi - Tipe Transaksi Investasi"
    },
    {
        "id": "dpinvestasisvcRekBank",
        "value": "Investasi - Tipe Transaksi Bank"
    },
    {
        "id": "dpinvestasisvcRekCustody",
        "value": "Investasi - Tipe Transaksi Kustodi"
    },
    {
        "id": "dpinvestasisvcRekBroker",
        "value": "Investasi - Tipe Transaksi Broker"
    },
    {
        "id": "dpinvestasisvcRekMi",
        "value": "Investasi - Tipe Transaksi Manager Investasi"
    },
    {
        "id": "dpinvestasisvcRekAperd",
        "value": "Investasi - Tipe Transaksi APERD"
    },
    {
        "id": "dpaccountingsvcAnggaranType",
        "value": "Akuntansi - Tipe Anggaran"
    },
    {
        "id": "dpcoresvcRekbank",
        "value": "Kepesertaan - Rekening Bank Kepesertaan"
    },
    {
        "id": "dpaccountingsvcAnggaranSubType",
        "value": "Akuntansi - Sub Tipe Anggaran"
    }
]

export const country = [
    {
        dataField: 'countryCode',
        caption: 'Kode Negara',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'countryName',
        caption: 'Negara',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'nationality',
        caption: 'Kebangsaan',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]

export const province = (store) => [
    {
        dataField: 'provinceCode',
        caption: 'Kode Provinsi',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'provinceName',
        caption: 'Provinsi',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'countryId',
        caption: 'Negara',
        lookup: {
            dataSource: {
                store: countries(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'countryName',
            value: 'countryId',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: countries(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'countryName',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]

export const city = (store) => [
    {
        dataField: 'cityCode',
        caption: 'Kode Kota',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'cityName',
        caption: 'Kota',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'provinceId',
        caption: 'Provinsi',
        lookup: {
            dataSource: {
                store: provinces(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'provinceName',
            value: 'provinceId',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: provinces(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'provinceName',
            value: 'provinceId',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]

export const postalCode = (store) => [
    {
        dataField: 'postalCode',
        caption: 'Postal Code',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'cityId',
        caption: 'City',
        lookup: {
            dataSource: {
                store: cities(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'cityName',
            value: 'cityId'
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: cities(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'cityName',
        }
    }

]

export const bank = [
    {
        dataField: 'bankCode',
        caption: 'Kode Bank',
        sortOrder: 'asc'
    },
    {
        dataField: 'bankName',
        caption: 'Bank',
    },
    {
        dataField: 'biCode',
        caption: 'Kode BI',
    },
    {
        dataField: 'initialName',
        caption: 'Initial Name',
    },
    {
        dataField: 'swiftCode',
        caption: 'Swift Code',
    },
    {
        dataField: 'alamat',
        visible: false,
    },
    {
        dataField: 'capitalAdequacyRatio',
        visible: false,
    },
    {
        dataField: 'costIncomeRatio',
        visible: false,
    },
    {
        dataField: 'cp1Email',
        visible: false,
    },
    {
        dataField: 'cp1Nama',
        visible: false,
    },
    {
        dataField: 'cp1NoHp',
        visible: false,
    },
    {
        dataField: 'cp1NoTlp',
        visible: false,
    },
    {
        dataField: 'cp1UnitKerja',
        visible: false,
    },
    {
        dataField: 'cp2Email',
        visible: false,
    },
    {
        dataField: 'cp2Nama',
        visible: false,
    },
    {
        dataField: 'cp2NoHp',
        visible: false,
    },
    {
        dataField: 'cp2NoTlp',
        visible: false,
    },
    {
        dataField: 'cp2UnitKerja',
        visible: false,
    },
    {
        dataField: 'email',
        visible: false,
    },
    {
        dataField: 'fax',
        visible: false,
    },
    {
        dataField: 'initialName',
        visible: false,
    },
    {
        dataField: 'loanDepositRatio',
        visible: false,
    },
    {
        dataField: 'netInterestMargin',
        visible: false,
    },
    {
        dataField: 'noTelp',
        visible: false,
    },
    {
        dataField: 'nplGross',
        visible: false,
    },
    {
        dataField: 'nplNet',
        visible: false,
    },
    {
        dataField: 'returnOnAsset',
        visible: false,
    },
    {
        dataField: 'returnOnEquity',
        visible: false,
    },
    {
        dataField: 'totalAsset',
        visible: false,
    },
    {
        type: 'form',
        items: [
            {
                itemType: 'group',
                name: 'master',
                colCount: 4,
                colSpan: 2,
                items: [
                    {
                        dataField: 'bankCode',
                        label: {
                            text: 'Kode Bank'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'bankName',
                        label: {
                            text: 'Nama Bank'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'initialName',
                        label: {
                            text: 'Nama Inisial'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'biCode',
                        label: {
                            text: 'Kode BI'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'swiftCode',
                        label: {
                            text: 'Kode Swift'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'dayBase',
                        label: {
                            text: 'Hari/Tahun Aktua;'
                        },
                        colSpan: 2,
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: dayBase(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            openOnFieldClick: true,
                            searchEnabled: true
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'kinerja',
                caption: 'Informasi Kinerja',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'totalAsset',
                        label: {
                            text: 'Total Asset'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'returnOnAsset',
                        label: {
                            text: 'ROA'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'returnOnEquity',
                        label: {
                            text: 'ROE'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'netInterestMargin',
                        label: {
                            text: 'NIM'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'loanDepositRatio',
                        label: {
                            text: 'LDR'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'capitalAdequacyRatio',
                        label: {
                            text: 'CAR'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'nplGross',
                        label: {
                            text: 'NPL (Gross)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'nplNet',
                        label: {
                            text: 'NPL (Nett)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'costIncomeRatio',
                        label: {
                            text: 'CIR'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'address',
                caption: 'Informasi Alamat',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'alamat',
                        label: {
                            text: 'Alamat'
                        },
                        colSpan: 3,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        },
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'fax',
                        label: {
                            text: 'Fax'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'cp1',
                caption: 'Informasi Contact Person 1',
                colCount: 2,
                alignItemLabels: true,
                items: [
                    {
                        dataField: 'cp1Nama',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1UnitKerja',
                        label: {
                            text: 'Unit Kerja'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1NoTlp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'cp2',
                caption: 'Informasi Contact Person 2',
                colCount: 2,
                alignItemLabels: true,
                items: [
                    {
                        dataField: 'cp2Nama',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2UnitKerja',
                        label: {
                            text: 'Unit Kerja'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2NoTlp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
        ]
    }
]
export const broker = (store) => {
    var config = [
        {
            dataField: 'brokerCode',
            caption: 'Kode Broker',
            validationRules: [
                {
                    type: "required",
                }
            ]
        },
        {
            dataField: 'brokerName',
            caption: 'Broker',
            validationRules: [
                {
                    type: "required",
                }
            ]
        },
        {
            dataField: 'address',
            caption: 'Alamat',
            validationRules: [
                {
                    type: "required",
                }
            ]
        },
    ]

    var formItem = {
        type: 'form',
        items: [
            {
                itemType: 'group',
                name: 'master',
                colCount: 4,
                colSpan: 2,
                items: [
                    {
                        dataField: 'brokerCode',
                        label: {
                            text: 'Kode Broker'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'brokerName',
                        label: {
                            text: 'Nama Broker'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'feeBuy',
                        label: {
                            text: 'Fee Beli'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'feeSell',
                        label: {
                            text: 'Fee Jual'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'marginRate',
                        label: {
                            text: 'Rate Marjin'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'kinerja',
                caption: 'Informasi Kinerja',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'totalAset',
                        label: {
                            text: 'Total Aset'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'mkbd',
                        label: {
                            text: 'Modal Kerja Bersih Disesuaikan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        type: 'empty'
                    },
                    {
                        dataField: 'nominalOmzet',
                        label: {
                            text: 'Nominal Omzet'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'laba',
                        label: {
                            text: 'Laba'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'marginBersih',
                        label: {
                            text: 'Margin Bersih (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'prosentaseOmzet',
                        label: {
                            text: 'Persentase Omzet (YoY)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'prosentaseLaba',
                        label: {
                            text: 'Persentase Laba (YoY)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'tradeValue',
                        label: {
                            text: 'Trade Value (Bulan Akhir)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'tradeVolume',
                        label: {
                            text: 'Trade Volume (Bulan Akhir)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'tradePercentBei',
                        label: {
                            text: 'Trade Bei (%) (Bulan Akhir)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'address',
                caption: 'Informasi Alamat',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'address',
                        label: {
                            text: 'Alamat'
                        },
                        colSpan: 3,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        },
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'noFax',
                        label: {
                            text: 'Fax'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colSpan: 2,
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'INFORMASI REKENING BANK',
                        items: [
                            {
                                dataField: 'rekbankId',
                                label: {
                                    text: 'Bank',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                }
                            },
                            {
                                dataField: 'rekbankCabang',
                                label: {
                                    text: 'Cabang',
                                },
                                // editorType: 'dxSelectBox',
                                // editorOptions: {
                                //     dataSource: banks(this.props.store),
                                //     valueExpr: 'id',
                                //     displayExpr: 'bankName',
                                // }
                            },
                            {
                                dataField: 'rekbankName',
                                label: {
                                    text: 'Nama Rekening',
                                },
                                // editorType: 'dxSelectBox',
                                // editorOptions: {
                                //     dataSource: banks(this.props.store),
                                //     valueExpr: 'id',
                                //     displayExpr: 'bankName',
                                // }
                            },
                            {
                                dataField: 'rekbankNo',
                                label: {
                                    text: 'Nomor Rekening',
                                },
                                // editorType: 'dxSelectBox',
                                // editorOptions: {
                                //     dataSource: banks(this.props.store),
                                //     valueExpr: 'id',
                                //     displayExpr: 'bankName',
                                // }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'INFORMASI REKENING CBEST',
                        items: [
                            {
                                dataField: 'cbestAccName',
                                label: {
                                    text: 'Account Name',
                                },
                            },
                            {
                                dataField: 'cbestAccNo',
                                label: {
                                    text: 'C-Best Account',
                                },
                            },
                            {
                                dataField: 'cbestIfo',
                                label: {
                                    text: 'IFO',
                                },
                            },
                            {
                                dataField: 'contactPerson',
                                label: {
                                    text: 'Contact Person',
                                },
                            },
                        ]
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'contactPerson',
                colCount: 2,
                alignItemLabels: true,
                items: [
                    {
                        itemType: 'group',
                        name: 'contactPerson1',
                        caption: 'Informasi Contact Person 1',
                        colCount: 2,
                        alignItemLabels: true,
                        items: [
                            {
                                dataField: 'dealer1Name',
                                label: {
                                    text: 'Nama'
                                },
                                colSpan: 2,
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'workUnitPerson1',
                                label: {
                                    text: 'Unit Kerja'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer1NoTelp',
                                label: {
                                    text: 'No Telp.'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer1NoHp',
                                label: {
                                    text: 'No HP'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer1Email',
                                label: {
                                    text: 'Email'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'contactPerson2',
                        caption: 'Informasi Contact Person 2',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'dealer2Name',
                                label: {
                                    text: 'Nama'
                                },
                                colSpan: 2,
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'workUnitPerson1',
                                label: {
                                    text: 'Unit Kerja'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer2NoTelp',
                                label: {
                                    text: 'No Telp.'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer2NoHp',
                                label: {
                                    text: 'No HP'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer2Email',
                                label: {
                                    text: 'Email'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'logo',
                colCount: 2,
                items: [
                    {
                        dataField: 'logo',
                        label: {
                            text: 'Logo Broker',
                            visible: false
                        },
                        editorType: 'dxFileUploader',
                        editorOptions: {
                            accept: '.bmp,.gif,.ico,.jpeg,.jpg,.png,.svg,.tif,.tiff,.webp',
                            uploadMode: 'useForm',
                            // onValueChanged: this.onUploadStarted,
                            labelText: "Taruh Logo Broker disini",
                            showFileList: true,
                            selectButtonText: "Upload Logo Broker",
                        }
                    },
                ]
            }
        ]
    }

    var invisibleColumn = [
        "cbestAccName",
        "cbestAccNo",
        "contactPerson",
        "createdBy",
        "createdDate",
        "dealer1Email",
        "dealer1Name",
        "dealer1NoHp",
        "dealer1NoTelp",
        "dealer2Email",
        "dealer2Name",
        "dealer2NoHp",
        "dealer2NoTelp",
        "email",
        "feeBuy",
        "feeSell",
        "id",
        "klasifikasi",
        "laba",
        "lastModifiedBy",
        "lastModifiedDate",
        "marginBersih",
        "mkbd",
        "noFax",
        "noTelp",
        "nominalOmzet",
        "procId",
        "prosentaseLaba",
        "prosentaseOmzet",
        "settlement1Email",
        "settlement1Name",
        "settlement1NoHp",
        "settlement1NoTelp",
        "settlement2Email",
        "settlement2Name",
        "settlement2NoHp",
        "settlement2NoTelp",
        "totalAset",
        "tradePercentBei",
        "tradeValue",
        "tradeVolume",
        "upInstruksi"
    ]

    invisibleColumn = invisibleColumn.map(value => {
        return {
            dataField: value,
            visible: false,
        }
    })

    return [...config, ...invisibleColumn, formItem]
}

export const aperd = [
    {
        dataField: 'aperdCode',
        caption: 'Kode APERD',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'aperdName',
        caption: 'Nama APERD',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'alamat',
        caption: 'Alamat',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'noTelp',
        visible: false
    },
    {
        dataField: 'noFax',
        visible: false
    },
    {
        dataField: 'sales1Name',
        visible: false
    },
    {
        dataField: 'sales1Email',
        visible: false
    },
    {
        dataField: 'sales1NoHp',
        visible: false
    },
    {
        dataField: 'sales2Email',
        visible: false
    },
    {
        dataField: 'sales2Name',
        visible: false
    },
    {
        dataField: 'sales2NoHp',
        visible: false
    },
    {
        type: 'form',
        items: [
            {
                itemType: 'group',
                name: 'master',
                colCount: 4,
                colSpan: 2,
                items: [
                    {
                        dataField: 'aperdCode',
                        label: {
                            text: 'Kode APERD'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'aperdName',
                        label: {
                            text: 'Nama APERD'
                        },
                        colSpan: 3,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'alamat',
                        label: {
                            text: 'Alamat'
                        },
                        colSpan: 4,
                        editorType: 'dxTextArea',
                        editorOptions: {
                            height: '80px'
                        }
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            text: 'No Telp'
                        },
                        // colSpan: 4,
                        editorType: 'dxTextBox'
                    },
                    {
                        dataField: 'noFax',
                        label: {
                            text: 'No Fax'
                        },
                        colSpan: 3,
                        editorType: 'dxTextBox'
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'sales',
                colCount: 2,
                alignItemLabels: true,
                cssClass: 'mt-2',
                items: [
                    {
                        itemType: 'group',
                        name: 'sales1',
                        caption: 'Informasi Sales 1',
                        colCount: 2,
                        alignItemLabels: true,
                        items: [
                            {
                                dataField: 'sales1Name',
                                label: {
                                    text: 'Nama'
                                },
                                colSpan: 2,
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'sales1NoHp',
                                label: {
                                    text: 'No Telp.'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'sales1Email',
                                label: {
                                    text: 'Email'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'sales2',
                        caption: 'Informasi Sales 2',
                        colCount: 2,
                        alignItemLabels: true,
                        items: [
                            {
                                dataField: 'sales2Name',
                                label: {
                                    text: 'Nama'
                                },
                                colSpan: 2,
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'sales2NoHp',
                                label: {
                                    text: 'No Telp.'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'sales2Email',
                                label: {
                                    text: 'Email'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                        ]
                    },
                ]
            },
        ]
    }
]

export const custody = [
    {
        dataField: 'custodyCode',
        caption: 'Kode Kustodi',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'custodyName',
        caption: 'Bank Kustodi',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'noTelp',
        caption: 'No. Telephone',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'noFax',
        caption: 'No. Fax',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'up',
        caption: 'UP',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'alamat',
        caption: 'Alamat',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    // {
    //     dataField: 'alamat',
    //     caption: 'Alamat',
    // },
]
export const participant = [
    {
        dataField: 'participantCode',
        caption: 'Participant Code',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'participantName',
        caption: 'Participant Name',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'payIuranDate',
        caption: 'Pay Iuran Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        isRequired: true,
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    }
]
export const branchs = [
    {
        dataField: 'branchCode',
        caption: 'Kode Cabang',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'branchName',
        caption: 'Nama Cabang',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const article = [
    {
        dataField: 'articleCode',
        caption: 'Article Code',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'title',
        caption: 'Title',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'expiredDate',
        caption: 'Expired Date',
        editorType: 'dxDateBox',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'type',
        caption: 'Type',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const redaksi = [
    {
        dataField: 'redaksiCode',
        caption: 'Redaksi Code',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'deskripsi',
        caption: 'Deskripsi',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const visitor = [
    {
        dataField: 'email',
        caption: 'E-Mail',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'memberSince',
        caption: 'Member Since',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'memberStatus',
        caption: 'Status Member',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'mobilePhone',
        caption: 'Mobile Phone',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const currencies = [
    {
        dataField: 'currencyCode',
        caption: 'Kode Mata Uang',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'currencyName',
        caption: 'Mata Uang',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'currencySymbol',
        caption: 'Simbol',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'rate',
        caption: 'Rate',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#,##0.00',
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const fiscalYears = [
    {
        dataField: 'fiscalYearCode',
        caption: 'Kode Tahun Fiskal',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'fiscalYearName',
        caption: 'Tahun Fiskal',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'startDate',
        caption: 'Tanggal Mulai',
        dataType: 'date',
        format: 'dd MMM yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'endDate',
        caption: 'Tanggal Berakhir',
        dataType: 'date',
        format: 'dd MMM yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'status',
        caption: 'Status',
        lookup: {
            dataSource: [
                {
                    id: 'A',
                    value: 'Aktif'
                },
                {
                    id: 'N',
                    value: 'Non-Aktif'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 'A',
                    value: 'Aktif'
                },
                {
                    id: 'N',
                    value: 'Non-Aktif'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    },
]
export const emiten = (store, datagridRef) => {
    return [
        {
            dataField: 'emitenCode',
            caption: 'Emiten Code'
        },
        {
            dataField: 'emitenName',
            caption: 'Emiten Name'
        },
        {
            dataField: 'industriUtama',
            caption: 'Industri Utama'
        },
        {
            dataField: 'sektorId',
            caption: 'Sektor',
            lookup: {
                dataSource: parentSektors(store),
                valueExpr: 'id',
                displayExpr: 'sektorName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: parentSektors(store),
                valueExpr: 'id',
                displayExpr: 'sektorName',
                searchEnabled: true,
                // onValueChanged : (e) => {
                //     if(datagridRef){
                //         datagridRef.current.DatagridRef.current.instance.columnOption('subSektorId','editorOptions.dataSource',sektorsByParent(store,e.value))
                //     }
                // }
            },
        },
        {
            dataField: 'subSektorId',
            caption: 'Sub Sektor',
            lookup: {
                dataSource: sektors(store),
                valueExpr: 'id',
                displayExpr: 'sektorName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: sektors(store),
                valueExpr: 'id',
                displayExpr: 'sektorName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'papanPencatatan',
            caption: 'Papan Pencatatan',
            lookup: {
                dataSource: papanPencatatan(),
                valueExpr: 'id',
                displayExpr: 'value'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: papanPencatatan(),
                valueExpr: 'id',
                displayExpr: 'value',
            }
        }
    ]
}

export const budget = (store) => [
    {
        dataField: 'budgetCode',
        caption: 'Kode Anggaran'
    },
    {
        dataField: 'budget',
        caption: 'Anggaran',
        editorType: 'dxNumberBox'
    },
    {
        dataField: 'budgetRevision',
        caption: 'Revisi Anggaran',
        editorType: 'dxNumberBox'
    },
    {
        dataField: 'budgetType',
        caption: 'Tipe Anggaran',
        lookup: {
            dataSource: [
                {
                    id: 1,
                    value: 'Tipe 1'
                },
                {
                    id: 2,
                    value: 'Tipe 2'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
            // value: 'currencyId'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 1,
                    value: 'Tipe 1'
                },
                {
                    id: 2,
                    value: 'Tipe 2'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    },
    {
        dataField: 'bugdetRealization',
        caption: 'Realisasi Anggaran',
        editorType: 'dxNumberBox'
    },
    {
        dataField: 'currencyId',
        caption: 'Mata Uang',
        lookup: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
            value: 'currencyId'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
        }
    },
    {
        dataField: 'coaId',
        caption: 'Bagan Akun',
        lookup: {
            dataSource: {
                store: coa(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'coaName',
            value: 'coaId',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: coa(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'coaName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'departmentId',
        caption: 'Departemen',
        lookup: {
            dataSource: departments(store),
            valueExpr: 'id',
            displayExpr: 'departmentCode',
            value: 'departmentId',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: departments(store),
            valueExpr: 'id',
            displayExpr: 'departmentCode',
            searchEnabled: true,
        }
    }
]
export const periodeConfig = [
    {
        dataField: 'periodCode',
        caption: 'Period Code',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'periodName',
        caption: 'Period Name',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'startDate',
        caption: 'Start Date',
        dataType: 'date',
        format: 'dd MMM yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'endDate',
        caption: 'End Date',
        dataType: 'date',
        format: 'dd MMM yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'specialPeriod',
        caption: 'Special Period',
        dataType: 'boolean',
    },
]
export const education = [
    {
        dataField: 'educationName',
        caption: 'Pendidikan',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'keterangan',
        caption: 'Keterangan',
        validationRules: [
            {
                type: "required",
            }
        ]
    }
]
export const grade = [
    {
        dataField: 'gradeCode',
        caption: 'Kode Grade',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'gradeName',
        caption: 'Nama Grade',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'range',
        caption: 'Range(%)',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'gajiMax',
        caption: 'Gaji Max',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#,##0.00'
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'gajiMid',
        caption: 'Gaji Mid',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#,##0.00'
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'gajiMin',
        caption: 'Gaji Min',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#,##0.00'
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const tax = (store) => [
    {
        dataField: 'taxCode',
        caption: 'Kode Pajak',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'taxCategoryId',
        caption: 'Kategori Pajak',
        lookup: {
            dataSource: taxCategories(store),
            valueExpr: 'id',
            displayExpr: 'categoryName',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: taxCategories(store),
            valueExpr: 'id',
            displayExpr: 'categoryName',
        }
    },
    {
        dataField: 'longDesc',
        caption: 'Deskripsi Panjang',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'shortDesc',
        caption: 'Deskripsi Singkat',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const gajiKomponen = [
    {
        dataField: 'komponenName',
        caption: 'Komponen',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'phdpCalc',
        caption: 'Kalkulasi PHDP',
        dataType: 'number',
        format: '#,##0.00',
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#,##0.00'
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'status',
        caption: 'Status',
        dataType: 'number',
        lookup: {
            dataSource: [
                {
                    id: 1,
                    value: 'Active'
                },
                {
                    id: 2,
                    value: 'Not Active'
                },
            ],
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "value" // provides display values
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 1,
                    value: 'Aktif'
                },
                {
                    id: 2,
                    value: 'Non-Aktif'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    }
]
export const periodeTunjanganStaticData = [
    {
        dataField: 'periodeName',
        caption: 'Nama Periode',
    },
    {
        dataField: 'statusAvailable',
        caption: 'Status',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: true,
                    value: 'Yes'
                },
                {
                    id: false,
                    value: 'No'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    },
    {
        dataField: 'tanggalAwal',
        caption: 'Tanggal Awal',
        dataType: 'date',
        format: 'dd MMM yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'tanggalAkhir',
        caption: 'Tanggal Akhir',
        dataType: 'date',
        format: 'dd MMM yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
]
export const gajiPotongan = [
    {
        dataField: 'id',
        caption: 'ID',
        editorOptions: {
            readOnly: true,
        }
    },
    {
        dataField: 'potonganName',
        caption: 'Potongan',
        validationRules: [
            {
                type: "required",
            }
        ]
    }
]
export const managerInvest = () => {
    var config = [
        {
            dataField: 'miCode',
            caption: 'Kode Manager Investasi',
            validationRules: [
                {
                    type: "required",
                }
            ]
        },
        {
            dataField: 'miName',
            caption: 'Manager Investasi',
            validationRules: [
                {
                    type: "required",
                }
            ]
        },
        {
            dataField: 'logo',
            caption: 'Logo'
        },
    ]

    var formItem = {
        type: 'form',
        items: [
            {
                itemType: 'group',
                name: 'master',
                colCount: 4,
                colSpan: 2,
                items: [
                    {
                        dataField: 'miCode',
                        label: {
                            text: 'Kode MI'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'miName',
                        label: {
                            text: 'Nama MI'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'kepemilikan',
                        label: {
                            text: 'Kepemilikan'
                        },
                        editorType: "dxSelectBox",
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'N',
                                    value: 'Nasional'
                                },
                                {
                                    id: 'P',
                                    value: 'Patungan'
                                },
                            ],
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: 'value',
                            searchEnabled: true,
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'kinerja',
                caption: 'Informasi Kinerja',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'funds',
                        label: {
                            text: 'Total Produk'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0",
                        },
                    },
                    {
                        dataField: 'perform1m',
                        label: {
                            text: 'Performa 1 Bulan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'perform1y',
                        label: {
                            text: 'Performa 1 Tahun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'aumIdr',
                        label: {
                            text: 'AUM IDR'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'perform3m',
                        label: {
                            text: 'Performa 3 Bulan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'perform3y',
                        label: {
                            text: 'Performa 3 Tahun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'aumUsd',
                        label: {
                            text: 'AUM USD'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'perform6m',
                        label: {
                            text: 'Performa 6 Bulan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'perform5y',
                        label: {
                            text: 'Performa 5 Tahun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'unitIdr',
                        label: {
                            text: 'Unit IDR'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'perform9m',
                        label: {
                            text: 'Performa 9 Bulan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'unitUsd',
                        label: {
                            text: 'Unit USD'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'performYtd',
                        label: {
                            text: 'Performa YTD'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'address',
                caption: 'Informasi Alamat',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'alamat',
                        label: {
                            text: 'Alamat'
                        },
                        colSpan: 3,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        },
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'noFax',
                        label: {
                            text: 'Fax'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'sales1',
                caption: 'Informasi Sales 1',
                colCount: 2,
                alignItemLabels: true,
                items: [
                    {
                        dataField: 'sales1Name',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'sales1NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'sales1Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'sales2',
                caption: 'Informasi Sales 2',
                colCount: 2,
                items: [
                    {
                        dataField: 'sales2Name',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'sales2NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'sales2Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'settlement1',
                caption: 'Informasi Settlement 1',
                colCount: 2,
                alignItemLabels: true,
                items: [
                    {
                        dataField: 'settlement1Name',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'settlement1NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'settlement1Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'settlement2',
                caption: 'Informasi Settlement 2',
                colCount: 2,
                items: [
                    {
                        dataField: 'settlement2Name',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'settlement2NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'settlement2Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
        ]
    }

    var invisibleColumn = [
        "alamat",
        "aumIdr",
        "aumUsd",
        "createdBy",
        "createdDate",
        "funds",
        "id",
        "kepemilikan",
        "lastModifiedBy",
        "lastModifiedDate",
        "logo",
        "miCode",
        "miName",
        "noFax",
        "noTelp",
        "perform1m",
        "perform1y",
        "perform3m",
        "perform3y",
        "perform5y",
        "perform6m",
        "perform9m",
        "performYtd",
        "procId",
        "sales1Email",
        "sales1Name",
        "sales1NoHp",
        "sales2Email",
        "sales2Name",
        "sales2NoHp",
        "settlement1Email",
        "settlement1Name",
        "settlement1NoHp",
        "settlement2Email",
        "settlement2Name",
        "settlement2NoHp",
        "unitIdr",
        "unitUsd"
    ]

    invisibleColumn = invisibleColumn.map(value => {
        return {
            dataField: value,
            visible: false,
        }
    })

    return [...config, ...invisibleColumn, formItem]
}
export const instrument = (store) => [
    {
        dataField: 'instrumentCode',
        caption: 'Instrument Code',
    },
    {
        dataField: 'instrumentCodeShort',
        caption: 'Instrument Code Short'
    },
    {
        dataField: 'instrumentName',
        caption: 'Instrument Name'
    },
    {
        dataField: 'categorySubId',
        caption: 'Instrument Category Sub',
        lookup: {
            dataSource: instrumentCategorySubs(store),
            valueExpr: 'id',
            displayExpr: 'subName',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: instrumentCategorySubs(store),
            valueExpr: 'id',
            displayExpr: 'subName',
        },
    },
    {
        dataField: 'instrumentTypeId',
        caption: 'Instrument Category',
        lookup: {
            dataSource: instrumentTypes(store),
            valueExpr: 'id',
            displayExpr: 'typeName',
        }
    },
    {
        dataField: 'emitenId',
        caption: 'Emiten',
        lookup: {
            dataSource: {
                store: emitens(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'emitenName',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: emitens(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'emitenName',
        },
    },
    {
        dataField: 'custodyId',
        caption: 'Custody',
        lookup: {
            dataSource: custodies(store),
            valueExpr: 'id',
            displayExpr: 'custodyName',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: custodies(store),
            valueExpr: 'id',
            displayExpr: 'custodyName',
        },
    },
    {
        dataField: 'currencyId',
        caption: 'Currency',
        lookup: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyName',
        },
    },
    {
        dataField: 'managerInvestasiId',
        caption: 'Manager Investasi',
        lookup: {
            dataSource: managerInvestasis(store),
            valueExpr: 'id',
            displayExpr: 'miName',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: managerInvestasis(store),
            valueExpr: 'id',
            displayExpr: 'miName',
        },
    },
    {
        dataField: 'rate',
        caption: 'Rate'
    },
    {
        dataField: 'rateTypeId',
        caption: 'Rate Type',
        lookup: {
            dataSource: rateTypes(store),
            valueExpr: 'id',
            displayExpr: 'rateTypeName',
        }
    },
    {
        dataField: 'rateMethodId',
        caption: 'Rate Method',
        lookup: {
            dataSource: rateMethods(store),
            valueExpr: 'id',
            displayExpr: 'rateMethodName',
        }
    },
    {
        dataField: 'couponLastDate',
        caption: 'Coupoun Last Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'couponNextDate',
        caption: 'Coupoun Next Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'couponPeriod',
        caption: 'Coupoun Period',
    },
    {
        dataField: 'depresiasiYear',
        caption: 'Depresiasi Year'
    },
    {
        dataField: 'haircut',
        caption: 'Haircut'
    },
    {
        dataField: 'rating1Id',
        caption: 'Rating Awal',
        lookup: {
            dataSource: ratings(store),
            valueExpr: 'id',
            displayExpr: 'ratingName',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: ratings(store),
            valueExpr: 'id',
            displayExpr: 'ratingName',
        }
    },
    {
        dataField: 'rating2Id',
        caption: 'Rating Akhir',
        lookup: {
            dataSource: ratings(store),
            valueExpr: 'id',
            displayExpr: 'ratingName',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: ratings(store),
            valueExpr: 'id',
            displayExpr: 'ratingName',
        }
    },
    {
        dataField: 'dayBase',
        caption: 'Basis',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 'BASE_30_360',
                    value: '30/360'
                },
                {
                    id: 'BASE_ACT_360',
                    value: 'act/360'
                },
                {
                    id: 'BASE_ACT_ACT',
                    value: 'act/act'
                },
                {
                    id: 'BASE_30_365',
                    value: '30/365'
                },
                {
                    id: 'BASE_ACT_365',
                    value: 'act/365'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    },
    {
        dataField: 'haircutDate',
        caption: 'Haircut Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'isinCode',
        caption: 'Isin Code'
    },
    {
        dataField: 'maturityDate',
        caption: 'Maturity Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'paymentAgentId',
        caption: 'Payment Agent',
        lookup: {
            dataSource: paymentAgents(store),
            valueExpr: 'id',
            displayExpr: 'agentName',
        }
    },
    {
        dataField: 'paymentDate',
        caption: 'Payment Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'paymentPeriodId',
        caption: 'Payment Period',
        lookup: {
            dataSource: paymentPeriods(store),
            valueExpr: 'id',
            displayExpr: 'periodName',
        }
    },
    {
        dataField: 'price',
        caption: 'Price',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
        editorOptions: {
            format: '#,##0.00'
        }
    },
    {
        dataField: 'priceDate',
        caption: 'Price Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'valuasiMethodId',
        caption: 'Valuasi Method',
        lookup: {
            dataSource: valuasiMethods(store),
            valueExpr: 'id',
            displayExpr: 'methodName',
        }
    },
    {
        dataField: 'valuasiPeriodeId',
        caption: 'Valuasi Periode',
        lookup: {
            dataSource: valuasiPeriodes(store),
            valueExpr: 'id',
            displayExpr: 'periodeName',
        }
    },
    {
        dataField: 'valuationLastDate',
        caption: 'Valuation Last Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'valuationNextDate',
        caption: 'Valuation Next Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'status',
        caption: 'Status',
        lookup: {
            dataSource: [
                {
                    id: 1,
                    value: 'Active'
                },
                {
                    id: 2,
                    value: 'Delisting'
                },
                {
                    id: 3,
                    value: 'Suspend'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 1,
                    value: 'Active'
                },
                {
                    id: 2,
                    value: 'Delisting'
                },
                {
                    id: 3,
                    value: 'Suspend'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        },
    },
    {
        dataField: 'syariah',
        caption: 'Syariah',
        editorType: 'dxCheckBox'
    },
]

export const journalTypes = (showFormEdit, deleteAPI) => {
    var columndefs = [
        {
            dataField: 'journalTypeCode',
            caption: 'Journal Type Code',
            validationRules: [
                {
                    type: "required",
                },
                {
                    type: 'stringLength',
                    max: 3,
                }
            ],
        },
        {
            dataField: 'journalTypeDesc',
            caption: 'Journal Type Description',
            validationRules: [
                {
                    type: "required",
                }
            ]
        },
        {
            dataField: 'manual',
            caption: 'Manual',
            dataType: 'boolean',
            editorType: 'dxCheckBox'
        },
        {
            dataField: 'serviceName',
            caption: 'Service Name',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: [
                    {
                        id: 'KPS',
                        value: 'Kepesertaan'
                    },
                    {
                        id: 'INV',
                        value: 'Investasi'
                    },
                    {
                        id: 'SDM',
                        value: 'SDM & Umum'
                    },
                    {
                        id: 'ACC',
                        value: 'Akuntansi'
                    },
                ],
                valueExpr: 'id',
                displayExpr: 'value',
            }
        },
        {
            dataField: 'transactionType',
            caption: 'Transaction Type',
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: [],
                valueExpr: 'id',
                displayExpr: 'value',
                searchEnabled: true,
                showClearButton: true
            }
        },
    ]
    if (showFormEdit) {
        columndefs.unshift({
            type: 'buttons',
            buttons: [
                'edit',
                {
                    text: 'Ubah',
                    hint: 'Ubah',
                    cssClass: 'text-success',
                    onClick: (e) => {
                        showFormEdit(e.row.data)
                    }
                },
                {
                    text: 'Hapus',
                    hint: 'Hapus',
                    cssClass: 'text-success',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin untuk menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: () => {
                                        deleteAPI(e.row.data)
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ]
                        })
                    }
                },
            ]
        })
    }

    return columndefs
}

export const departements = [
    {
        dataField: 'departmentCode',
        caption: 'Departement Code',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'departmentName',
        caption: 'Departement Name',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]

export const chartOfAccountGroup = (store) => [
    {
        dataField: 'coaGroupCode',
        caption: 'Kode Grup COA',
        label: {
            alignment: "left",
            text: "Kode Grup COA"
        },
        validationRules: [
            {
                type: "required",
            },
            // {
            //     type: 'pattern',
            //     pattern: RegExp('^[^a-zA-Z]+$','g'),
            //     message: 'Kode Grup COA tidak valid karena mengandung huruf'
            // },
            // {
            //     type: 'async',
            //     validationCallback: async (e) => {
            //         var resultCheck = await httpRequest(process.env.REACT_APP_BACKEND_ACC,store,`coa-groups/getByCoaGroupCode/${e.value}`)
            //         if(resultCheck){
            //             return false
            //         }else{
            //             return true
            //         }
            //     },
            //     message: 'Kode Grup COA sudah terpakai'
            // },
        ]
    },
    {
        dataField: 'coaGroupName',
        caption: 'Nama Grup COA',
        label: {
            alignment: "left",
            text: "Nama Grup COA"
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'parentCoaGroupId',
        caption: 'Induk Grup COA',
        label: {
            alignment: "left",
            text: "Induk Grup COA"
        },
        lookup: {
            dataSource: coaGroup(store),
            valueExpr: 'id',
            displayExpr: (item) => {
                return item && item.coaGroupCode + ' - ' + item.coaGroupName
            }, // provides display values
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: coaGroup(store),
            valueExpr: 'id',
            displayExpr: (item) => {
                return item && item.coaGroupCode + ' - ' + item.coaGroupName
            },
            searchEnabled: true,
        }
    },
]

// export const chartOfAccount = (store) => [
//     {
//         dataField: 'coaCode',
//         caption: 'Kode COA',
//         label: {
//             alignment: "left",
//             text: "Kode COA"
//         },
//         editorType: "dxTextBox",
//         validationRules: [
//             {
//                 type: "required",
//             },
//             // {
//             //     type: 'pattern',
//             //     pattern: /^[^a-zA-Z]+$/g,
//             //     message: 'Kode COA tidak valid karena mengandung huruf'
//             // },
//             // {
//             //     type: 'async',
//             //     validationCallback: async (e) => {
//             //         try{
//             //             var resultCheck = await httpRequest(process.env.REACT_APP_BACKEND_ACC,store,`coas/getByCoaCode/${e.value}`)
//             //             if(resultCheck){
//             //                 return false
//             //             }else{
//             //                 return true
//             //             }
//             //         }catch(e){
//             //             if(e.includes('404')){
//             //                 return true
//             //             }else{
//             //                 return false
//             //             }
//             //         }
//             //     },
//             //     message: 'Kode COA sudah terpakai'
//             // },
//         ]
//     },
//     {
//         dataField: 'coaName',
//         caption: 'Nama COA',
//         label: {
//             alignment: "left",
//             text: "Nama COA"
//         },
//         validationRules: [
//             {
//                 type: "required",
//             }
//         ]
//     },
//     {
//         dataField: 'coaGroupId',
//         caption: 'Induk Grup COA',
//         label: {
//             alignment: "left",
//             text: "Induk Grup COA"
//         },
//         lookup: {
//             dataSource: coaGroup(store),
//             valueExpr: 'id',
//             displayExpr: (item) => {
//                 return item && item.coaGroupCode + ' - ' + item.coaGroupName
//             }, // provides display values
//             searchEnabled: true
//         },
//         validationRules: [
//             {
//                 type: "required",
//             }
//         ],
//         editorType: 'dxSelectBox',
//         editorOptions: {
//             dataSource: coaGroup(store),
//             valueExpr: 'id',
//             displayExpr: (item) => {
//                 return item && item.coaGroupCode + ' - ' + item.coaGroupName
//             },
//             searchEnabled: true,
//         }
//     },
//     {
//         dataField: 'aliasName',
//         caption: 'Nama Alias',
//         label: {
//             alignment: "left",
//             text: "Nama Alias"
//         },
//     },
//     {
//         dataField: 'normalBalance',
//         caption: 'Saldo Normal',
//         label: {
//             alignment: "left",
//             text: "Saldo Normal"
//         },
//         lookup: {
//             dataSource: dc(),
//             valueExpr: 'id',
//             displayExpr: 'value',
//         },
//         validationRules: [
//             {
//                 type: "required",
//             }
//         ],
//         editorType: 'dxSelectBox',
//         editorOptions: {
//             dataSource: dc(),
//             valueExpr: 'id',
//             displayExpr: 'value',
//         }
//     },
//     {
//         dataField: 'balanceAmount',
//         caption: 'Saldo',
//         label: {
//             alignment: "left",
//             text: "Saldo"
//         },
//         dataType: 'number',
//         alignment: 'right',
//         format: '#,##0.00',
//         editorType: 'dxNumberBox',
//         editorOptions: {
//             format: '#,##0.00'
//         },
//         validationRules: [
//             {
//                 type: "required",
//             }
//         ]
//     },
//     {
//         dataField: 'currencyId',
//         caption: 'Mata Uang',
//         label: {
//             alignment: "left",
//             text: "Mata Uang"
//         },
//         lookup: {
//             dataSource: currency(store),
//             valueExpr: 'id',
//             displayExpr: 'currencyCode',
//             searchEnabled: true,
//         },
//         validationRules: [
//             {
//                 type: "required",
//             }
//         ],
//         editorType: 'dxSelectBox',
//         editorOptions: {
//             dataSource: currency(store),
//             valueExpr: 'id',
//             displayExpr: 'currencyCode',
//         }
//     },
//     {
//         dataField: 'category',
//         caption: 'Kategori',
//         label: {
//             alignment: "left",
//             text: "Kategori"
//         },
//         lookup: {
//             dataSource: coaCategory(),
//             valueExpr: 'id',
//             displayExpr: 'value',
//             searchEnabled: true,
//         },
//         validationRules: [
//             {
//                 type: "required",
//             }
//         ],
//         editorType: 'dxSelectBox',
//         editorOptions: {
//             dataSource: coaCategory(),
//             valueExpr: 'id',
//             displayExpr: 'value',
//         }
//     },
//     {
//         dataField: 'plbs',
//         caption: 'Neraca / Laba Rugi',
//         label: {
//             alignment: "left",
//             text: "Neraca / Laba Rugi"
//         },
//         lookup: {
//             dataSource: plbs(),
//             valueExpr: 'id',
//             displayExpr: 'value',
//             searchEnabled: true,
//         },
//         validationRules: [
//             {
//                 type: "required",
//             }
//         ],
//         editorType: 'dxSelectBox',
//         editorOptions: {
//             dataSource: plbs(),
//             valueExpr: 'id',
//             displayExpr: 'value',
//         }
//     },
// ]

export const chartOfAccount = (store) => [
    {
        dataField: 'coaCode',
        caption: 'Kode COA',
        label: {
            alignment: "left",
            text: "Kode COA"
        },
        editorType: "dxTextBox",
        validationRules: [
            {
                type: "required",
            },
            // {
            //     type: 'pattern',
            //     pattern: /^[^a-zA-Z]+$/g,
            //     message: 'Kode COA tidak valid karena mengandung huruf'
            // },
            // {
            //     type: 'async',
            //     validationCallback: async (e) => {
            //         try{
            //             var resultCheck = await httpRequest(process.env.REACT_APP_BACKEND_ACC,store,`coas/getByCoaCode/${e.value}`)
            //             if(resultCheck){
            //                 return false
            //             }else{
            //                 return true
            //             }
            //         }catch(e){
            //             if(e.includes('404')){
            //                 return true
            //             }else{
            //                 return false
            //             }
            //         }
            //     },
            //     message: 'Kode COA sudah terpakai'
            // },
        ]
    },
    {
        dataField: 'coaName',
        caption: 'Nama COA',
        label: {
            alignment: "left",
            text: "Nama COA"
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'coaGroupId',
        caption: 'Induk Grup COA',
        label: {
            alignment: "left",
            text: "Induk Grup COA"
        },
        lookup: {
            dataSource: coaGroup(store),
            valueExpr: 'id',
            displayExpr: (item) => {
                return item && item.coaGroupCode + ' - ' + item.coaGroupName
            }, // provides display values
            searchEnabled: true
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        // editorType: 'dxSelectBox',
        // editorOptions: {
        //     dataSource: coaGroup(store),
        //     valueExpr: 'id',
        //     displayExpr: (item) => {
        //         return item && item.coaGroupCode + ' - ' + item.coaGroupName
        //     },
        //     searchEnabled: true,
        // }
    },
    {
        dataField: 'aliasName',
        caption: 'Nama Alias',
        label: {
            alignment: "left",
            text: "Nama Alias"
        },
    },
    {
        dataField: 'normalBalance',
        caption: 'Saldo Normal',
        label: {
            alignment: "left",
            text: "Saldo Normal"
        },
        lookup: {
            dataSource: dc(),
            valueExpr: 'id',
            displayExpr: 'value',
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        // editorType: 'dxSelectBox',
        // editorOptions: {
        //     dataSource: dc(),
        //     valueExpr: 'id',
        //     displayExpr: 'value',
        // }
    },
    {
        dataField: 'balanceAmount',
        caption: 'Saldo',
        label: {
            alignment: "left",
            text: "Saldo"
        },
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
        editorType: 'dxNumberBox',
        editorOptions: {
            format: '#,##0.00'
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'currencyId',
        caption: 'Mata Uang',
        label: {
            alignment: "left",
            text: "Mata Uang"
        },
        lookup: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        // editorType: 'dxSelectBox',
        // editorOptions: {
        //     dataSource: currency(store),
        //     valueExpr: 'id',
        //     displayExpr: 'currencyCode',
        // }
    },
    {
        dataField: 'category',
        caption: 'Kategori',
        label: {
            alignment: "left",
            text: "Kategori"
        },
        lookup: {
            dataSource: coaCategory(),
            valueExpr: 'id',
            displayExpr: 'value',
            searchEnabled: true,
        },
        // validationRules: [
        //     {
        //         type: "required",
        //     }
        // ],
        // editorType: 'dxSelectBox',
        // editorOptions: {
        //     dataSource: coaCategory(),
        //     valueExpr: 'id',
        //     displayExpr: 'value',
        // }
    },
    {
        dataField: 'plbs',
        caption: 'Neraca / Laba Rugi',
        label: {
            alignment: "left",
            text: "Neraca / Laba Rugi"
        },
        lookup: {
            dataSource: plbs(),
            valueExpr: 'id',
            displayExpr: 'value',
            searchEnabled: true,
        },
        // validationRules: [
        //     {
        //         type: "required",
        //     }
        // ],
        // editorType: 'dxSelectBox',
        // editorOptions: {
        //     dataSource: plbs(),
        //     valueExpr: 'id',
        //     displayExpr: 'value',
        // }
    },
]

export const anggaran = (store) => [
    {
        dataField: 'anggaranCode',
        caption: 'Kode Anggaran',
        label: {
            alignment: "left",
            text: "Kode Anggaran"
        },
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'anggaranName',
        caption: 'Nama Anggaran',
        label: {
            alignment: "left",
            text: "Nama Anggaran"
        },
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'anggaranSubTypeId',
        caption: 'Sub Tipe Anggaran',
        label: {
            alignment: "left",
            text: "Sub Tipe Anggaran"
        },
        lookup: {
            dataSource: anggaranSubType(store),
            valueExpr: 'id',
            displayExpr: 'anggaranSubName',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: anggaranSubType(store),
            valueExpr: 'id',
            displayExpr: 'anggaranSubName',
        }
    },
    {
        dataField: 'unitId',
        caption: 'Unit Kerja',
        label: {
            alignment: "left",
            text: "Unit Kerja"
        },
        lookup: {
            dataSource: sdmUnit(store),
            valueExpr: 'id',
            displayExpr: 'unitName',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: sdmUnit(store),
            valueExpr: 'id',
            displayExpr: 'unitName',
        }
    },
]

export const subTipeAnggaran = (store) => [
    {
        dataField: 'anggaranSubName',
        caption: 'Nama Sub Tipe Anggaran',
        label: {
            alignment: "left",
            text: "Nama Sub Tipe Anggaran"
        },
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'anggaranTypeId',
        caption: 'Tipe Anggaran',
        label: {
            alignment: "left",
            text: "Tipe Anggaran"
        },
        lookup: {
            dataSource: anggaranType(store),
            valueExpr: 'id',
            displayExpr: 'tipeName',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: anggaranType(store),
            valueExpr: 'id',
            displayExpr: 'tipeName',
        }
    },
]

export const tipeAnggaran = (store) => [
    {
        dataField: 'tipeName',
        caption: 'Nama Tipe Anggaran',
        label: {
            alignment: "left",
            text: "Nama Tipe Anggaran"
        },
        validationRules: [
            {
                type: "required",
            }
        ],
    },
]

export const entity = [
    {
        dataField: 'entityName',
        caption: 'Entity Name',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'type',
        caption: 'Type',
        lookup: {
            dataSource: entityType(),
            valueExpr: 'id',
            displayExpr: 'value',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: entityType(),
            valueExpr: 'id',
            displayExpr: 'value',
        }
    },
    {
        dataField: 'reff',
        caption: 'Reff',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const department = [
    {
        dataField: 'departmentCode',
        capion: 'Kode Departemen',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'departmentName',
        capion: 'Nama Departemen',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const holiday = [
    {
        dataField: 'holidayDate',
        caption: 'Tanggal',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'description',
        caption: 'Deskripsi',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'holidayType',
        caption: 'Tipe Hari Libur',
        lookup: {
            dataSource: [
                {
                    id: 'B',
                    value: 'Bursa'
                },
                {
                    id: 'U',
                    value: 'Umum'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 'B',
                    value: 'Bursa'
                },
                {
                    id: 'U',
                    value: 'Umum'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    }
]
export const project = [
    {
        dataField: 'projectCode',
        caption: 'Project Code',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'projectName',
        caption: 'Project Name',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
]
export const exchange = (store) => [
    {
        dataField: 'valueDate',
        caption: 'Value Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'currencyId',
        caption: 'Currency',
        lookup: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
        },
        setCellValue: (newData, value, currentRowData) => {
            newData.currencyId = value
            newData.symbol = value
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'currencyId',
        name: 'symbol',
        caption: 'Symbol',
        lookup: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencySymbol',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencySymbol',
            readOnly: true
        },
    },
    // {
    //     dataField: 'conversionModel',
    //     caption: 'Conversion Model',
    // },
    {
        dataField: 'conversionValue',
        caption: 'Conversion Value',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
        editorOptions: {
            format: '#,##0.00',
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    }
]
export const vendor = [
    {
        dataField: 'vendorName',
        caption: 'Vendor Name',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'personInCharge',
        caption: 'Person In Charge',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'address1',
        caption: 'Address 1',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'address2',
        caption: 'Address 2'
    },
    {
        dataField: 'address3',
        caption: 'Address 3'
    },
    {
        dataField: 'phoneNo',
        caption: 'Phone No',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'faxNo',
        caption: 'Fax No'
    },
]
export const identity = (deleteData) =>  [
    {
        type: 'buttons',
        buttons: [
            {
                text: 'Hapus',
                hint: 'Hapus',
                cssClass: 'text-success',
                onClick: (e) => {
                    deleteData(e.row.data)
                }
            },
        ]
    },
    {
        dataField: 'identityCode',
        caption: 'Kode Identitas',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'identityName',
        caption: 'Nama Identitas',
        validationRules: [
            {
                type: "required",
            }
        ]
    }
]
export const agendaBisnis = (store) => [
    {
        dataField: 'kode',
        caption: 'Kode Agenda'
    },
    {
        dataField: 'description',
        caption: 'Deskripsi Agenda'
    },
    {
        dataField: 'holiday',
        caption: 'Hari Libur',
    },
    {
        dataField: 'locked',
        caption: 'Locked',
    },
    {
        dataField: 'agendaTypeId',
        caption: 'Tipe Agenda',
        lookup: {
            dataSource: agendaType(store),
            valueExpr: 'id',
            displayExpr: 'typeAgenda',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: agendaType(store),
            valueExpr: 'id',
            displayExpr: 'typeAgenda',
        }
    },
    {
        dataField: 'dateRange',
        caption: 'Rentang Tanggal',
    },
    {
        dataField: 'date1',
        caption: 'Tanggal 1',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'date2',
        caption: 'Tanggal 2',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'publikasi',
        caption: 'Publikasi',
    },
]
export const kecamatan = (store) => [
    {
        dataField: 'kecamatanName',
        caption: 'Kecamatan',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'cityId',
        caption: 'Kota',
        lookup: {
            dataSource: {
                store: cities(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'cityName',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: cities(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'cityName',
        }
    }
]
export const kelurahan = (store) => [
    {
        dataField: 'kelurahanName',
        caption: 'Kelurahan',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'kodePos',
        caption: 'Kode POS',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'kecamatanId',
        caption: 'Kecamatan',
        lookup: {
            dataSource: {
                store: kecamatanAll(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'kecamatanName',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: kecamatanAll(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'kecamatanName',
        }
    }
]
export const clientpemberikerja = [
    {
        dataField: 'participantCode',
        caption: 'Kode',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'participantName',
        caption: 'Nama Pemberi Kerja',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'alamat',
        caption: 'Alamat',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'kepesertaan',
        caption: 'KEPESERTAAN',
        columns: [
            {
                dataField: 'aktif',
                caption: 'Aktif',
            },
            {
                dataField: 'pasif',
                caption: 'Pasif',
            },
            {
                dataField: 'arsip',
                caption: 'Arsip',
            },
        ],
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        type: 'form',
        items: [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'participantCode',
                        label: {
                            text: 'Kode'
                        }
                    },
                    {
                        dataField: 'participantName',
                        label: {
                            text: 'Nama Pemberi Kerja'
                        }
                    },
                    {
                        dataField: 'alamat',
                        label: {
                            text: 'Alamat'
                        }
                    },
                    {
                        dataField: 'email',
                        label: {
                            text: 'Email'
                        }
                    },
                    {
                        dataField: 'telp',
                        label: {
                            text: 'No. Telp',
                        },
                    },
                    {
                        dataField: 'fax',
                        label: {
                            text: 'No. Fax'
                        },
                    },
                    {
                        dataField: 'payIuranDate',
                        label: {
                            text: 'Tanggal Pembayaran Iuran',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        },
                        validationRules: [
                            {
                                type: "required",
                            }
                        ],
                    },
                    {
                        dataField: 'tglPembayaranIuran',
                        label: {
                            text: 'Tanggal Iuran',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'tglPengkinianPeserta',
                        label: {
                            text: 'Tanggal Pengkinian Peserta',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Kontak Person Kepesertaan',
                        items: [
                            {
                                dataField: 'namaKepesertaan',
                                label: {
                                    text: 'Nama'
                                }
                            },
                            {
                                dataField: 'emailKepesertaan',
                                label: {
                                    text: 'Email'
                                }
                            },
                            {
                                dataField: 'telpKepesertaan',
                                label: {
                                    text: 'No. Telp'
                                },
                            },
                            {
                                dataField: 'noHpKepesertaan',
                                label: {
                                    text: 'No. HP'
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Kontak Person Keuangan',
                        items: [
                            {
                                dataField: 'namaKeuangan',
                                label: {
                                    text: 'Nama'
                                }
                            },
                            {
                                dataField: 'emailKeuangan',
                                label: {
                                    text: 'Email'
                                }
                            },
                            {
                                dataField: 'telpKeuangan',
                                label: {
                                    text: 'No. Telp'
                                },
                            },
                            {
                                dataField: 'noHpKeuangan',
                                label: {
                                    text: 'No. HP'
                                },
                            },
                        ]
                    },
                ]
            }
        ]
    }
]
export const manfaatPensiun = (store) => [
    {
        dataField: 'mpCode',
        caption: 'Kode',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'mpName',
        caption: 'Manfaat Pensiun',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'categoryNid',
        caption: 'Kategori',
        lookup: {
            dataSource: [
                {
                    id: 1,
                    value: 'Manfaat Utama'
                },
                {
                    id: 2,
                    value: 'Manfaat Lain'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
            value: 'categoryNid'
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 1,
                    value: 'Manfaat Utama'
                },
                {
                    id: 2,
                    value: 'Manfaat Lain'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    },
    {
        dataField: 'dasarNilaiId',
        caption: 'Dasar Nilai',
        lookup: {
            dataSource: [
                {
                    id: 1,
                    value: 'Peresentase'
                },
                {
                    id: 2,
                    value: 'Nominal'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
            value: 'categoryNid'
        },
        editorType: 'dxNumberBox',
        editorOptions: {
            format: "#,##0.00"
        },
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'kepersertaanTransactionTypeId',
        caption: 'Tipe Transaksi',
        lookup: {
            dataSource: tipeTransaksiKepesertaan(store),
            valueExpr: 'id',
            displayExpr: 'transactionName',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: tipeTransaksiKepesertaan(store),
            valueExpr: 'id',
            displayExpr: 'transactionName',
            searchEnabled: true,
        }
    },
]
export const rekBank = (store) => [
    {
        dataField: 'productId',
        caption: 'Program',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: programs(store),
            valueExpr: 'id',
            displayExpr: 'program',
            searchEnabled: true,
        },
    },
    {
        dataField: 'bankId',
        caption: 'Bank',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: banks(store),
            valueExpr: 'id',
            displayExpr: 'bankName',
            searchEnabled: true
        }
    },
    {
        dataField: 'rekNo',
        caption: 'No Rekening',
    },
    {
        dataField: 'rekName',
        caption: 'Nama Rekening',
    },
    {
        dataField: 'transactionTypeId',
        caption: 'Tipe Transaksi',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: tipeTransaksiKepesertaan(store),
            valueExpr: 'id',
            displayExpr: 'transactionName',
            searchEnabled: true
        }
    },
    {
        dataField: 'akunId',
        caption: 'Akun',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: kepersertaanAkunById(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true
        }
    },
    {
        dataField: 'currencyId',
        caption: 'Currency',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
            searchEnabled: true,
        },
    }
]

export const dplk = [
    {
        dataField: 'dplkCode',
        caption: 'Kode',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'dplkName',
        caption: 'Nama DPLK',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'initialName',
        caption: 'Inisial',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'address',
        caption: 'Alamat',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'email',
        caption: 'Email',
        validationRules: [
            {
                type: "required",
            }
        ],
    },
    {
        dataField: 'phone',
        caption: 'No Telp',
        editorType: 'dxNumberBox',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        type: 'group',
        items: [
            {
                itemType: 'group',
                caption: 'Contact Person Kepesertaan',
                items: [
                    {
                        dataField: 'kepesertaanCpName',
                        caption: 'Nama',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                    {
                        dataField: 'kepesertaanCpEmail',
                        caption: 'Email',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                    {
                        dataField: 'kepesertaanCpHp',
                        caption: 'No HP',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                    {
                        dataField: 'kepesertaanCpPhone',
                        caption: 'No Telp',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Contact Person Keuangan',
                items: [
                    {
                        dataField: 'keuanganCpName',
                        caption: 'Nama',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                    {
                        dataField: 'keuanganCpEmail',
                        caption: 'Email',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                    {
                        dataField: 'keuanganCpHp',
                        caption: 'No HP',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                    {
                        dataField: 'keuanganCpPhone',
                        caption: 'No Telp',
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "required",
                            }
                        ]
                    },
                ]
            }
        ]
    }
]
export const jabatan = (store) => [
    {
        dataField: 'jabatanCode',
        caption: 'Kode Jabatan',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'jabatanName',
        caption: 'Jabatan',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'jabatanGolonganId',
        caption: 'Golongan Jabatan',
        lookup: {
            dataSource: jabatanGolongan(store),
            valueExpr: 'id',
            displayExpr: 'namaJagol',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: jabatanGolongan(store),
            valueExpr: 'id',
            displayExpr: 'namaJagol',
            searchEnabled: true,
        }
    },
    {
        dataField: 'instruksiTtd',
        caption: 'Instruksi TTD',
        dataType: 'boolean',
        editorType: "dxCheckBox"
    },
    {
        dataField: 'instruksiTtdUrutan',
        caption: 'Urutan Instruksi TTD',
        dataType: 'number',
        lookup: {
            dataSource: [
                {
                    id: 1,
                    value: '1'
                },
                {
                    id: 2,
                    value: '2'
                },
                {
                    id: 3,
                    value: '3'
                },
                {
                    id: 4,
                    value: '4'
                },
                {
                    id: 5,
                    value: '5'
                },
            ],
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "value" // provides display values
        }
    },
    {
        dataField: 'authorize',
        caption: 'Authorize',
        dataType: 'boolean',
        editorType: "dxCheckBox"
    },
    // {
    //     dataField: 'gajiKomponenId',
    //     caption: 'Komponen Gaji',
    //     lookup: {
    //         dataSource: komponenGaji(store),
    //         valueExpr: 'id',
    //         displayExpr: 'komponenName'
    //     },
    //     validationRules : [
    //         {
    //             type: "required",
    //         }
    //     ],
    //     editorType: 'dxSelectBox',
    //     editorOptions: {
    //         dataSource: komponenGaji(store),
    //         valueExpr: 'id',
    //         displayExpr: 'komponenName',
    //     }
    // },
]

export const golonganJabatan = [
    {
        dataField: 'kodeJagol',
        caption: 'Kode Golongan',
    },
    {
        dataField: 'namaJagol',
        caption: 'Golongan Jabatan'
    }
]

export const agendaTypes = [
    {
        dataField: 'typeAgenda',
        caption: 'Tipe Agenda',
    },
    {
        dataField: 'holiday',
        caption: 'Hari Libur'
    }
]
export const tipePembayaran = [
    {
        dataField: 'id',
        caption: 'ID',
        allowEditing: false
        // editorOptions: {
        //     readOnly: true
        // }
    },
    {
        dataField: 'tipePembayaran',
        caption: 'Tipe Pembayaran'
    }
]
export const kategoriAsset = (store) => [
    {
        dataField: 'categoryName',
        caption: 'Kategori',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'metodeDepresiasiId',
        caption: 'Metode Depresiasi',
        lookup: {
            dataSource: metodeDepresiasi(store),
            valueExpr: 'id',
            displayExpr: 'metodeDepresiasi',
            value: 'metodeDepresiasiId',
            searchEnabled: true,
        },
        validationRules: [
            {
                type: "required",
            }
        ],
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: metodeDepresiasi(store),
            valueExpr: 'id',
            displayExpr: 'metodeDepresiasi',
            searchEnabled: true,
        }
    },
    {
        dataField: 'periodeDepresiasi',
        caption: 'Periode Depresiasi',
        validationRules: [
            {
                type: "required",
            }
        ]
    }
]

export const kategoriAsset1 = (store) => [
    {
        dataField: 'categoryCode',
        caption: 'Kode',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'categoryName',
        caption: 'Kategori',
        validationRules: [
            {
                type: "required",
            }
        ]
    }
]
export const kjpp = [
    // {
    //     dataField: 'id',
    //     caption: 'ID',
    //     editorOptions: {
    //         readOnly: true
    //     }
    // },
    {
        dataField:'id',
        caption: "No",
        cellRender:(data) => {
            return data.rowIndex + 1
        },
        editorOptions: {
            readOnly: true
        }
    },
    {
        dataField: 'kjppName',
        caption: 'KJPP'
    }
]
export const tipeTransaksiSdm = (store, deleteData, showEditData) => [
    {
        type: 'buttons',
        buttons: [
            {
                text: 'Ubah',
                hint: 'Ubah',
                cssClass: 'text-success',
                onClick: (e) => {
                    showEditData('edit', e.row.data)
                }
            },
            {
                text: 'Hapus',
                hint: 'Hapus',
                cssClass: 'text-success',
                onClick: async(e) => {
                    let result = await confirm('Apakah anda ingin mengahapus data ini?', 'Konfirmasi');

                    if(result) {
                        deleteData(e.row.data)
                    }
                }
            },
        ]
    },
    {
        dataField: 'id',
        caption: 'No',
        allowEditing: false,
        // editorOptions: {
        //     readOnly: true,
        // },
        cellRender: (e) => {
            return e.rowIndex + 1
        }
    },
    // {
    //     dataField: 'transactionTypeCode',
    //     caption: 'Kode Transaksi'
    // },
    {
        dataField: 'transactionTypeName',
        caption: 'Nama Transaksi'
    },
    {
        dataField: 'akunId',
        caption: 'Akun',
        lookup: {
            dataSource: allAkunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: allAkunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'apAkunId',
        caption: 'AP Akun',
        lookup: {
            dataSource: allAkunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: allAkunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'arAkunId',
        caption: 'AR Akun',
        lookup: {
            dataSource: allAkunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: allAkunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        }
    },

]

export const subTipeTransaksiSdm = (store, deleteData, retrieveData) => [
        {
            type: 'buttons',
            buttons: [
                {
                    text: 'Hapus',
                    hint: 'Hapus',
                    cssClass: 'text-success',
                    onClick: (e) => {
                        deleteData(e.row.data)
                    }
                },
            ]
        },
        {
            dataField: 'tipeSubName',
            caption: 'Tipe Sub Name'
        },
        {
            dataField: 'transactionTypeId',
            caption: 'Tipe Transaksi',
            lookup: {
                dataSource: tipeTransaksiSdmUmum(store),
                valueExpr: 'id',
                displayExpr: 'transactionTypeName',
                value: 'transactionTypeId',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: tipeTransaksiSdmUmum(store),
                valueExpr: 'id',
                displayExpr: 'transactionTypeName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'id',
            caption: 'ID',
            editorOptions: {
                readOnly: true,
            }
        },
        {
            dataField: 'akunId',
            caption: 'Akun',
            lookup: {
                dataSource: allAkunHR(store),
                // dataSource: akunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'apAkunId',
            caption: 'AP Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'arAkunId',
            caption: 'AR Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'biayaAkunId',
            caption: 'Biaya Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'cashoutAkunId',
            caption: 'Cashout Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'dpAkunId',
            caption: 'DP Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'potonganAkunId',
            caption: 'Potongan Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'pphAkunId',
            caption: 'PPH Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'ppnAkunId',
            caption: 'PPN Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        },
        {
            dataField: 'ppnBmAkunId',
            caption: 'PPN BM Akun',
            lookup: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: allAkunHR(store),
                valueExpr: 'id',
                displayExpr: 'akunName',
                searchEnabled: true,
            }
        }
]
export const subCategoryAsets = (store, deleteData, showModalEdit) => [
    {
        type: 'buttons',
        buttons: [
            'edit',
            {
                text : 'Ubah',
                hint : 'Ubah',
                cssClass: 'text-primary',
                onClick : (e) => {
                    showModalEdit(e.row.data)
                }
            },
            {
                text : 'Hapus',
                hint : 'Hapus',
                cssClass: 'text-primary',
                onClick : async(e) => {
                    let result = await confirm('Apakah anda ingin mengahapus data ini?', 'Konfirmasi');

                    if(result) {
                        deleteData(e.row.data)
                    }
                }
            },
        ],
    },
    {
        dataField:"kodeAsset",
        caption:"Kode Sub Kategori" 
    },
    {
        dataField:"categorySubName",
        caption:"Nama Sub Kategori"
    },
    {
        dataField:"assetCategoryId",
        caption:"Kategori Aset",
        lookup : {
            dataSource: asetKategoriSdm(store),
            valueExpr: 'id',
            displayExpr: (item) => {
                return item && item.categoryCode + ' - ' + item.categoryName
            }
        },
    },
    {
        dataField:"akunId",
        caption:"Kategori Aset",
        lookup : {
            dataSource: akunHR(store),
            valueExpr: 'id',
            displayExpr: (item) => {
                return item && item.akunCode + ' - ' + item.akunName
            }
        },
    },
    {
        dataField:"akumDepresiasiAkunId",
        caption:"Kategori Aset",
        lookup : {
            dataSource: akunHR(store),
            valueExpr: 'id',
            displayExpr: (item) => {
                return item && item.akunCode + ' - ' + item.akunName
            }
        },
    },
    {
        dataField:"depresiasiAkunId",
        caption:"Kategori Aset",
        lookup : {
            dataSource: akunHR(store),
            valueExpr: 'id',
            displayExpr: (item) => {
                return item && item.akunCode + ' - ' + item.akunName
            }
        },
    },
]
export const tipeTransaksiInvest = (store) => [
    {
        dataField: 'transactionName',
        caption: 'Transaksi'
    },
    {
        dataField: 'instrumentCategoryId',
        caption: 'Kategori Instrumen',
        lookup: {
            dataSource: instrumentCategories(store),
            valueExpr: 'id',
            displayExpr: 'categoryName',
            value: 'instrumentCategoryId',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: instrumentCategories(store),
            valueExpr: 'id',
            displayExpr: 'categoryName',
            searchEnabled: true,
        }
    },
]
export const tipeTunjanganSdm = (store) => [
    {
        dataField: 'tunjanganCode',
        caption: 'Kode Tunjangan'
    },
    {
        dataField: 'tunjanganName',
        caption: 'Nama Tunjangan'
    },
    {
        dataField: 'tunjanganCategoryId',
        caption: 'Kategori Tunjangan ',
        lookup: {
            dataSource: tunjanganKategori(store),
            valueExpr: 'id',
            displayExpr: 'categoryName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: tunjanganKategori(store),
            valueExpr: 'id',
            displayExpr: 'categoryName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'tunjanganGroupId',
        caption: 'Grup Tunjangan ',
        lookup: {
            dataSource: tunjanganGroup(store),
            valueExpr: 'id',
            displayExpr: 'groupName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: tunjanganGroup(store),
            valueExpr: 'id',
            displayExpr: 'groupName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'transactionTypeSubId',
        caption: 'Sub Tipe Transaksi',
        lookup: {
            dataSource: tipeSubTransaksiSdmUmum(store),
            valueExpr: 'id',
            displayExpr: 'tipeSubName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: tipeSubTransaksiSdmUmum(store),
            valueExpr: 'id',
            displayExpr: 'tipeSubName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'periodeTunjanganId',
        caption: 'Periode Tunjangan',
        lookup: {
            dataSource: periodeTunjangan(store),
            valueExpr: 'id',
            displayExpr: 'periodeName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: periodeTunjangan(store),
            valueExpr: 'id',
            displayExpr: 'periodeName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'tunjanganTetap',
        caption: 'Tunjangan Tetap',
        dataType: 'boolean'
    },
    {
        dataField: 'tunjanganCuti',
        caption: 'Tunjangan Cuti',
        dataType: 'boolean'
    },
    {
        dataField: 'akunId',
        caption: 'Akun',
        lookup: {
            dataSource: akunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: akunHR(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
        }
    },
    {
        dataField: 'dasarNilaiId',
        caption: 'Dasar Nilai',
        lookup: {
            dataSource: dasarNilai(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'dasarNilai', // provides display values
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: dasarNilai(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'dasarNilai', // provides display values
            searchEnabled: true,
        }
    },
    {
        dataField: 'faktorPph21',
        caption: 'Faktor PPH 21',
        dataType: 'boolean'
    },
    {
        dataField: "maxGapokCalculate",
        caption: "Max Gapok Calculate",
        format: "#,##0.00",
        alignment: "right",
        dataType: 'number',
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00",
            showClearButton: true
        }
    },
    {
        dataField: "maxNominalPeriode",
        caption: "Max Nominal Periode",
        format: "#,##0.00",
        alignment: "right",
        dataType: 'number',
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00",
            showClearButton: true
        }
    },
    {
        dataField: "nominal",
        caption: "Nominal",
        format: "#,##0.00",
        alignment: "right",
        dataType: 'number',
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00",
            showClearButton: true
        }
    },
    {
        dataField: "percentValue",
        caption: "Percent Value(%)",
        alignment: "right",
        dataType: 'number',
        editorType: "dxNumberBox",
        editorOptions: {
            showClearButton: true
        }
    },
    {
        dataField: 'status',
        caption: 'Status',
        dataType: 'boolean'
    },
]

export const unitSdm = (store) => [
    {
        dataField: 'unitName',
        caption: 'Unit',
    },
    {
        dataField: 'divisiId',
        caption: 'Divisi',
        lookup: {
            dataSource: divisi(store),
            valueExpr: 'id',
            displayExpr: 'divisiName'
        }
    }
]

export const tipeTransaksiPeserta = [
    {
        dataField: 'id',
        caption: 'ID',
        editorOptions: {
            readOnly: true,
        }
    },
    {
        dataField: 'transactionName',
        caption: 'Transaksi'
    }
]
export const tipeDokumenPeserta = [
    {
        dataField: 'id',
        caption: 'ID',
        editorOptions: {
            readOnly: true,
        }
    },
    {
        dataField: 'dokumenTypeName',
        caption: 'Tipe'
    }
]

export const sektor = (store) => [
    {
        dataField: 'sektorName',
        caption: 'Nama Sektor',
    },
    {
        dataField: 'parentSektorId',
        caption: 'Parent Sektor',
        lookup: {
            dataSource: sektors(store),
            valueExpr: 'id',
            displayExpr: 'sektorName',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: sektors(store),
            valueExpr: 'id',
            displayExpr: 'sektorName',
            searchEnabled: true,
        }
    }
]

export const product = (store) => [
    {
        dataField: 'programId',
        caption: 'Program',
        lookup: {
            dataSource: programs(store),
            valueExpr: 'id',
            displayExpr: 'program',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: programs(store),
            valueExpr: 'id',
            displayExpr: 'program',
            searchEnabled: true,
        }
    },
    {
        dataField: 'productCode',
        caption: 'Kode Produk',

    },
    {
        dataField: 'productName',
        caption: 'Nama Produk',

    },
    {
        dataField: 'createdDate',
        caption: 'Tanggal Efektif',
        dataType: 'date',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
        format: 'dd-MMM-yyyy',
        validationRules: [
            {
                type: "required",
            }
        ]
    },
    {
        dataField: 'custodyId',
        caption: 'Bank Kustodi',
        lookup: {
            dataSource: custodies(store),
            valueExpr: 'id',
            displayExpr: 'custodyCode',
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: custodies(store),
            valueExpr: 'id',
            displayExpr: 'custodyCode',
            searchEnabled: true,
        }
    }
]

export const obligasi = (store) => [
    {
        dataField: 'instrumentCode',
        caption: 'Kode Obligasi',
    },
    {
        dataField: 'instrumentName',
        caption: 'Nama Obligasi',
    },
    {
        dataField: 'emitenId',
        caption: 'Nama Penerbit',
        lookup: {
            dataSource: {
                store: emitens(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "emitenName", // provides display values
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: {
                store: emitens(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id',
            displayExpr: 'emitenName',
            searchEnabled: true,
        }
    },

    // {
    //     dataField: "custodyId",
    //     caption: "Kustodian",
    //     lookup: {
    //     dataSource: custodies(store),
    //     valueExpr: "id", // contains the same values as the "statusId" field provides
    //     displayExpr: "custodiName" // provides display values
    //     }
    // },
    {
        dataField: 'marketCode',
        caption: 'Market',
    },
    {
        dataField: "currencyId",
        caption: "Currency",
        lookup: {
            dataSource: currency(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "currencyCode", // provides display values
            searchEnabled: true,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
            searchEnabled: true,
        }
    },
    {
        dataField: 'kategori',
        caption: 'Kategori',
    },
    {
        dataField: 'isinCode',
        caption: 'ISIN Code',
    },
    {
        dataField: 'isinDate',
        caption: 'ISIN Date',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'rating',
        caption: 'Rating',
    },
    {
        dataField: 'maturityDate',
        caption: 'Tanggal jatuh tempo',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'issuedDate',
        caption: 'Tanggal Terbit',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'listingDate',
        caption: 'Tanggal Pencatatan',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    {
        dataField: 'rateTypeId',
        caption: 'Acrued Type',
    },
    {
        dataField: 'rateMethodId',
        caption: 'Metode',
    },
    {
        dataField: 'couponPeriod',
        caption: 'Periode Kupon',
    },
    {
        dataField: 'rate',
        caption: 'Tingkat Kupon',
        format: "#,##0.00 %",
        alignment: 'right'
    },
    {
        dataField: 'haircut',
        caption: 'Haircut',
        format: "#,##0.00 %",
        alignment: 'right'
    },
    {
        dataField: 'syariah',
        caption: 'Syariah',
    }
]

export const saham = (store) => [
    {
        dataField: 'instrumentCode',
        caption: 'Kode Saham',
    },
    {
        dataField: 'instrumentName',
        caption: 'Nama Saham',
    },
    // {
    //     dataField: "emitenId",
    //     caption: "Emiten",
    //     lookup: {
    //         dataSource: emitens(store),
    //         valueExpr: "id", // contains the same values as the "statusId" field provides
    //         displayExpr: "emitenName" // provides display values
    //     },
    //     editorType: 'dxSelectBox',
    //     editorOptions: {
    //         dataSource: emitens(store),
    //         valueExpr: 'id',
    //         displayExpr: 'emitenName',
    //     }
    // },
    {
        dataField: 'isinCode',
        caption: 'ISIN Code',
    },
    // {
    //     dataField: 'isinDate',
    //     caption: 'ISIN Date',
    //     dataType: 'date',
    //     format: 'dd MMM yyyy',
    //     alignment: 'right',
    //     editorType: 'dxDateBox',
    //     editorOptions:{
    //         displayFormat: 'dd MMM yyyy',
    //         useMaskBehavior: true,
    //     },
    // },
    // {
    //     dataField: 'marketId',
    //     caption: 'Market Code',
    //     lookup: {
    //         dataSource: [
    //             {
    //                 id: 1,
    //                 value: 'IDX'
    //             }
    //         ],
    //         valueExpr: "id", // contains the same values as the "statusId" field provides
    //         displayExpr: "value" // provides display values
    //     }
    // },
    // {
    //     dataField: 'status',
    //     caption: 'Status',
    // },
    // {
    //     dataField: "currencyId",
    //     caption: "Currency",
    //     lookup: {
    //         dataSource: currency(store),
    //         valueExpr: "id", // contains the same values as the "statusId" field provides
    //         displayExpr: "currencyCode" // provides display values
    //     },
    //     editorType: 'dxSelectBox',
    //     editorOptions: {
    //         dataSource: currency(store),
    //         valueExpr: 'id',
    //         displayExpr: 'currencyCode',
    //     }
    // },
    // {
    //     dataField: 'tanggalTerbit',
    //     caption: 'Tanggal Terbit',
    //     dataType: 'date',
    //     format: 'dd MMM yyyy',
    //     alignment: 'right',
    //     editorType: 'dxDateBox',
    //     editorOptions:{
    //         displayFormat: 'dd MMM yyyy',
    //         useMaskBehavior: true,
    //     },
    // },
    // {
    //     dataField: 'tanggalDelisting',
    //     caption: 'Tanggal Delisting',
    //     dataType: 'date',
    //     format: 'dd MMM yyyy',
    //     alignment: 'right',
    //     editorType: 'dxDateBox',
    //     editorOptions:{
    //         displayFormat: 'dd MMM yyyy',
    //         useMaskBehavior: true,
    //     },
    // },
    {
        dataField: 'price',
        caption: 'Harga Penutupan',
        format: "#,##0.00",
        alignment: 'right'
    },
    {
        dataField: 'priceDate',
        caption: 'Tanggal Harga Penutupan',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right',
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
        },
    },
    // {
    //     dataField: 'sektor',
    //     caption: 'Sektor',
    //     lookup: {
    //         dataSource: [],
    //         valueExpr: "id", // contains the same values as the "statusId" field provides
    //         displayExpr: "sektorCode" // provides display values
    //     }
    // },
    // {
    //     dataField: 'haircut',
    //     caption: 'Haircut (%)',
    //     format: "#,##0.00 %",
    // },
    // {
    //     dataField: 'syariah',
    //     caption: 'Syariah',
    // }
]

export const reksadana = (store) => [
    {
        dataField: 'instrumentCode',
        caption: 'Kode Reksadana',
    },
    {
        dataField: 'instrumentName',
        caption: 'Nama Reksadana',
    },
    {
        dataField: "jenisReksadanaId",
        caption: "Jenis Reksadana",
        lookup: {
            dataSource: [],//jenisReksadana(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "namaJenis" // provides display values
        }
    },
    {
        dataField: "status",
        caption: "Status",
        lookup: {
            dataSource: [
                {
                    id: "1",
                    value: 'Active'
                },
                {
                    id: "2",
                    value: 'Deactive'
                },
                {
                    id: "3",
                    value: 'Liquidation'
                },
            ], //statusReksadana(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "value", // provides display values
            searchEnabled: true,
            deferRendering: false,
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: "1",
                    value: 'Active'
                },
                {
                    id: "2",
                    value: 'Deactive'
                },
                {
                    id: "3",
                    value: 'Liquidation'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
    },
    {
        dataField: "miId",
        caption: "Manager Investasi",
        lookup: {
            dataSource: managerInvestasis(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "miName", // provides display values
            searchEnabled: true,
        },
        editorOptions: {
            dataSource: managerInvestasis(store),
            valueExpr: 'id',
            displayExpr: 'miName',
            searchEnabled: true,
        }
    },
    {
        dataField: "custodyId",
        caption: "Custody",
        lookup: {
            dataSource: custodies(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "custodyName", // provides display values
            searchEnabled: true,
        },
        editorOptions: {
            dataSource: custodies(store),
            valueExpr: 'id',
            displayExpr: 'custodyName',
            searchEnabled: true,
        }
    },
    {
        dataField: "isinCode",
        caption: "Isin Code",
        editorType: "dxNumberBox",
        editorOptions: {
            showClearButton: true
        }
    },
    {
        dataField: "currencyId",
        caption: "Currency",
        lookup: {
            dataSource: currency(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "currencyCode" // provides display values
        },
        editorOptions: {
            dataSource: currency(store),
            valueExpr: 'id',
            displayExpr: 'currencyCode',
        }
    },
    {
        dataField: "kategoriReksadanaId",
        caption: "Kategori Reksadana",
        lookup: {
            dataSource: [],//kategoriReksadana(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: "namaKategori" // provides display values
        }
    },
    {
        dataField: "listingDate",
        caption: "Tanggal Peluncuran",
        dataType: "date",
        editorOptions: {
            useMaskBehavior: true
        },
        format: "d MMM yyyy"
    },
    {
        dataField: "aum",
        caption: "AUM",
        format: "#,##0.0000",
        alignment: "right",
        type: 'number',
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.0000",
            showClearButton: true
        }
    },
    {
        dataField: "unit",
        caption: "Unit",
        format: "#,##0.0000",
        alignment: "right",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.0000",
            showClearButton: true
        }
    },
    {
        dataField: "nav",
        caption: "NAV",
        format: "#,##0.0000",
        alignment: "right",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.0000",
            showClearButton: true
        }
    },
    {
        dataField: "biayaPembelian",
        caption: "Biaya Pembelian (%)",
        format: "#,##0.00",
        alignment: "right",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00 %",
            showClearButton: true
        }
    },
    {
        dataField: "biayaPenjualan",
        caption: "Biaya Penjualan (%)",
        format: "#,##0.00",
        alignment: "right",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00 %",
            showClearButton: true
        }
    },
    {
        dataField: "biayaSwitching",
        caption: "Biaya Switching (%)",
        format: "#,##0.00",
        alignment: "right",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00 %",
            showClearButton: true
        }
    },
    {
        dataField: "feeManagement",
        caption: "Fee Management (%)",
        format: "#,##0.00",
        alignment: "right",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00 %",
            showClearButton: true
        }
    },
    {
        dataField: "feeSharing",
        caption: "Fee Sharing (%)",
        format: "#,##0.00",
        alignment: "right",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00 %",
            showClearButton: true
        }
    },
    {
        dataField: 'syariah',
        caption: 'Syariah',
    }
]

export const lembur = (store) => [
    {
        dataField: "lemburCode",
        caption: "Kode"
    },
    {
        dataField: "lemburName",
        caption: "Nama Lembur"
    },
    {
        dataField: "akunId",
        caption: "Akun",
        lookup: {
            dataSource: akunHR(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'akunName', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "payrollCategorySubId",
        caption: "Kategori Payroll",
        lookup: {
            dataSource: payrollKategoriSub(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: 'categorySubName',
            searchEnabled: true,
            deferRendering: false
        }
    },
    {
        dataField: "jamAwal",
        caption: "Jam Awal",
        format: "HH:mm",
        dataType: "datetime",
        editorType: "dxDateBox",
        editorOptions: {
            type: "time",
            openOnFieldClick: true,
            useMaskBehavior: true,
            pickerType: "rollers",
            displayFormat: 'HH:mm',
            dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
        }
    },
    {
        dataField: "jamAkhir",
        caption: "Jam Akhir",
        format: "HH:mm",
        dataType: "datetime",
        editorType: "dxDateBox",
        editorOptions: {
            type: "time",
            openOnFieldClick: true,
            useMaskBehavior: true,
            pickerType: "rollers",
            displayFormat: 'HH:mm',
            dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
        }
    },
]

export const loanType = (store) => [
    {
        dataField: "loanCode",
        caption: "Kode"
    },
    {
        dataField: "loanName",
        caption: "Pinjaman"
    },
    {
        dataField: "loanAkunId",
        caption: "Akun Pinjaman",
        lookup: {
            dataSource: akunHR(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'akunName', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "cicilanAkunId",
        caption: "Akun Cicilan",
        lookup: {
            dataSource: akunHR(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'akunName', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "payrollCategorySubId",
        caption: "Kategori Payroll",
        lookup: {
            dataSource: payrollKategoriSub(store),
            valueExpr: "id", // contains the same values as the "statusId" field provides
            displayExpr: 'categorySubName',
            searchEnabled: true,
            deferRendering: false
        }
    },
]

export const periodeCuti = [
    {
        dataField: "kode",
        caption: "Kode"
    },
    {
        dataField: "namaPeriodeCuti",
        caption: "Periode Cuti"
    },
    {
        dataField: "tanggalAwal",
        caption: "Tanggal Awal",
        dataType: "date",
        format: "dd MMM yyyy",
        editorType: "dxDateBox",
        editorOptions: {
            useMaskBehavior: true,
            width: '100%',
            displayFormat: 'dd MMM yyyy',
            openOnFieldClick: true
        }
    },
    {
        dataField: "tanggalAkhir",
        caption: "Tanggal Akhir",
        dataType: "date",
        format: "dd MMM yyyy",
        editorType: "dxDateBox",
        editorOptions: {
            useMaskBehavior: true,
            width: '100%',
            displayFormat: 'dd MMM yyyy',
            openOnFieldClick: true
        }
    },
]

export const supplier = (store) => [
    {
        dataField: "kodeSupplier",
        caption: "Kode Supplier",
        editorOptions: {
            // readOnly: true
        }
    },
    {
        dataField: "namaSupplier",
        caption: "Nama Supplier"
    },
    {
        dataField: "noTelp",
        caption: "No Telp",
        dataType: 'number',
        editorType: "dxNumberBox",
        editorOptions: {
            mode: 'number'
        }
    },
    {
        dataField: "contactPerson",
        caption: "Contact Person"
    },
    {
        dataField: "email",
        caption: "Email"
    },
    {
        dataField: "maxPembelianTahun",
        caption: "Max. pembelian /tahun (Rupiah)",
        dataType: 'number',
        format: "#,##0.00",
        editorType: "dxNumberBox",
        editorOptions: {
            format: "#,##0.00",
            mode: 'number'
        }
    },
    {
        dataField: "rekNo",
        caption: "No Rekening",
        dataType: 'number',
        editorType: "dxNumberBox",
        editorOptions: {
            mode: 'number'
        }
    },
    {
        dataField: "rekName",
        caption: "Nama Rekening"
    },
    {
        dataField: "rekBankId",
        caption: "Bank Rekening",
        lookup: {
            dataSource: banks(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            // displayExpr: 'bankName', // provides display values
            displayExpr: (item) => {
                return item && 'Bank Name: ' + item.bankName + ' - Initial Name: ' + item.initialName;
            },
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "rekCabang",
        caption: "Cabang Rekening"
    },
    {
        dataField: "totalPembelian",
        caption: "Total Pembelian",
        dataType: 'number',
        format: '#,##0.00',
        editorType: "dxNumberBox",
        editorOptions: {
            mode: 'number',
            format: '#,##0.00',
        }
    },
    {
        dataField: "npwp",
        caption: "NPWP"
    },
    {
        dataField: "alamat",
        caption: "Alamat"
    },
    {
        dataField: "statusSupplier",
        caption: "Status Supplier",
        lookup: {
            dataSource: statusAktifNonAktif,
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'value', // provides display values
        }
    },
]

export const akun = (store) => [
    {
        dataField: "akunCode",
        caption: "Kode Akun"
    },
    {
        dataField: "akunName",
        caption: "Nama Akun"
    },
    {
        dataField: 'category',
        caption: 'Kategori',
        lookup: {
            dataSource: [
                {
                    id: 'Kas Umum',
                    value: "Kas Umum"
                },
                {
                    id: 'General',
                    value: "General"
                },
            ],
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'value', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: 'coaId',
        caption: 'COA',
        lookup: {
            dataSource: {
                store: coa(store),
                paginate: true,
                pageSize: 10
            },
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'coaName', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: 'groupId',
        caption: 'COA Group',
        lookup: {
            dataSource: coaGroup(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'coaGroupName', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "position",
        caption: "Position",
        lookup: {
            dataSource: [
                {
                    id: 'C',
                    value: 'Credit'
                },
                {
                    id: 'D',
                    value: 'Debit'
                },
            ],
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'value', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
]

export const rekBankSdm = (store) => [
    {
        dataField: "rekNo",
        caption: "No Rekening"
    },
    {
        dataField: "rekName",
        caption: "Nama Rekening"
    },
    {
        dataField: "productId",
        caption: "Produk",
        lookup: {
            dataSource: productDefault(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: (item) => {
                return item && item.productCode + ' - ' + item.productName
            },
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "akunId",
        caption: "Akun",
        lookup: {
            dataSource: akunHR(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'akunName', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "biayaBankAkunId",
        caption: "Biaya Bank Akun",
        lookup: {
            dataSource: akunHR(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'akunName', // provides display values
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "bankId",
        caption: "Bank",
        lookup: {
            dataSource: banks(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: (item) => {
                return item && item.bankCode + ' - ' + item.bankName
            },
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "currencyId",
        caption: "Currency",
        lookup: {
            dataSource: currency(store),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: (item) => {
                return item && item.currencyCode + ' - ' + item.currencyName
            },
            searchEnabled: true,
            deferRendering: false,
        }
    },
    {
        dataField: "feeBank",
        caption: "Fee bank",
        dataType: 'number',
        editorType: "dxNumberBox",
    },
    {
        dataField: "statusId",
        caption: "Status",
        lookup: {
            dataSource: statusAktifNonAktifRekBank(),
            valueExpr: 'id', // contains the same values as the "statusId" field provides
            displayExpr: 'value',
            searchEnabled: true,
            deferRendering: false,
        },
    },
    {
        dataField: "keterangan",
        caption: "Keterangan"
    },
]

export const DataMasterBank1 = (module, store, type) => {
        switch(type){
            case 'dataBank':
                 return [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Kode Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekNo',
                        label: {
                            text: 'No. Rekening'
                        }
                    },
                    {
                        dataField: 'rekName',
                        label: {
                            text: 'Nama Rekening'
                        }
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'brokerId',
                        label: {
                            text: 'Broker'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: brokerStaticData(store),
                            valueExpr: 'id',
                            displayExpr: 'brokerName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'custodyId',
                        label: {
                            text: 'Custody'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(store),
                            valueExpr: 'id',
                            displayExpr: 'custodyName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'cabang',
                        label: {
                            text: 'Cabang'
                        }
                    },
                    {
                        dataField: 'rekCustodian',
                        label: {
                            text: 'Rekening Kustodi'
                        },
                        editorType: 'dxCheckBox'
                    },
                    {
                        dataField: 'jenis',
                        label: {
                            text: 'Jenis Rekening'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    value: 'Giro'
                                },
                                {
                                    value: 'Tabungan'
                                },
                                {
                                    value: 'Deposito'
                                },
                            ],
                            valueExpr: 'value',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'statusId',
                        label: {
                            text: 'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 0,
                                    value: 'Non Aktif'
                                },
                                {
                                    id: 1,
                                    value: 'Aktif'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'akunId',
                        label: {
                            text: 'Akun Rekening'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: akunInvestasi(store),
                            valueExpr: 'id',
                            displayExpr: 'akunName',
                            searchEnabled: true,
                            deferRendering: false,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            text: 'Keterangan'
                        },
                    },
                ]
            case 'dataKustodi':
                return [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Kode Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekNo',
                        label: {
                            text: 'No. Rekening'
                        }
                    },
                    {
                        dataField: 'rekName',
                        label: {
                            text: 'Nama Rekening'
                        }
                    },
                    {
                        dataField: 'custodyId',
                        label: {
                            text: 'Kustodi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(store),
                            valueExpr: 'id',
                            displayExpr: 'custodyName',
                            deferRendering: false,
                            searchEnabled: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'tanggalEfektif',
                        label: {
                            text: 'Tanggal Efektif'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                ]
            case 'dataBroker':
                return [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Kode Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekNo',
                        label: {
                            text: 'No. Rekening'
                        }
                    },
                    {
                        dataField: 'rekName',
                        label: {
                            text: 'Nama Rekening'
                        }
                    },
                    {
                        dataField: 'brokerId',
                        label: {
                            text: 'Broker'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: brokerStaticData(store),
                            valueExpr: 'id',
                            displayExpr: 'brokerName',
                            deferRendering: false,
                            searchEnabled: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'tanggalEfektif',
                        label: {
                            text: 'Tanggal Efektif'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                ]
            case 'dataManagerInvestasi':
                return [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Kode Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekNo',
                        label: {
                            text: 'No. Rekening'
                        }
                    },
                    {
                        dataField: 'rekName',
                        label: {
                            text: 'Nama Rekening'
                        }
                    },
                    {
                        dataField: 'miId',
                        label: {
                            text: 'Manager Investasi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(store),
                            valueExpr: 'id',
                            displayExpr: 'miName',
                            deferRendering: false,
                            searchEnabled: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            deferRendering: false,
                            searchEnabled: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'cabang',
                        label: {
                            text: 'Cabang'
                        },
                    },
                    {
                        dataField: 'tanggalEfektif',
                        label: {
                            text: 'Tanggal Efektif'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                ]
            case 'dataRekeningAperdInvestasi':
                return [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Kode Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekNo',
                        label: {
                            text: 'No. Rekening'
                        }
                    },
                    {
                        dataField: 'rekName',
                        label: {
                            text: 'Nama Rekening'
                        }
                    },
                    {
                        dataField: 'aperdId',
                        label: {
                            text: 'APERD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: aperds(store),
                            valueExpr: 'id',
                            displayExpr: 'aperdName',
                            deferRendering: false,
                            searchEnabled: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'tanggalEfektif',
                        label: {
                            text: 'Tanggal Efektif'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                ]
        }
}

export const DataMasterBank = (store) => [
    {
        dataField: 'productId',
        label: {
            text: 'Kode Produk'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: productDefault(store),
            valueExpr: 'id',
            displayExpr: 'productCode',
            readOnly: true
        }
    },
    {
        dataField: 'rekNo',
        label: {
            text: 'No. Rekening'
        }
    },
    {
        dataField: 'rekName',
        label: {
            text: 'Nama Rekening'
        }
    },
    {
        dataField: 'bankId',
        label: {
            text: 'Bank'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: banks(store),
            valueExpr: 'id',
            displayExpr: 'bankName',
            searchEnabled: true,
            deferRendering: false,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'brokerId',
        label: {
            text: 'Broker'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: brokerStaticData(store),
            valueExpr: 'id',
            displayExpr: 'brokerName',
            searchEnabled: true,
            deferRendering: false,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'custodyId',
        label: {
            text: 'Custody'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: custodies(store),
            valueExpr: 'id',
            displayExpr: 'custodyName',
            searchEnabled: true,
            deferRendering: false,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'cabang',
        label: {
            text: 'Cabang'
        }
    },
    {
        dataField: 'rekCustodian',
        label: {
            text: 'Rekening Kustodi'
        },
        editorType: 'dxCheckBox'
    },
    {
        dataField: 'jenis',
        label: {
            text: 'Jenis Rekening'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    value: 'Giro'
                },
                {
                    value: 'Tabungan'
                },
                {
                    value: 'Deposito'
                },
            ],
            valueExpr: 'value',
            displayExpr: 'value',
            searchEnabled: true,
            deferRendering: false,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'statusId',
        label: {
            text: 'Status'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 0,
                    value: 'Non Aktif'
                },
                {
                    id: 1,
                    value: 'Aktif'
                },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
            searchEnabled: true,
            deferRendering: false,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'akunId',
        label: {
            text: 'Akun Rekening'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: akunInvestasi(store),
            valueExpr: 'id',
            displayExpr: 'akunName',
            searchEnabled: true,
            deferRendering: false,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'keterangan',
        label: {
            text: 'Keterangan'
        },
    },
]

export const DataMasterKustodi = (store) => [
    {
        dataField: 'productId',
        label: {
            text: 'Kode Produk'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: productDefault(store),
            valueExpr: 'id',
            displayExpr: 'productCode',
            readOnly: true
        }
    },
    {
        dataField: 'rekNo',
        label: {
            text: 'No. Rekening'
        }
    },
    {
        dataField: 'rekName',
        label: {
            text: 'Nama Rekening'
        }
    },
    {
        dataField: 'custodyId',
        label: {
            text: 'Kustodi'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: custodies(store),
            valueExpr: 'id',
            displayExpr: 'custodyName',
            deferRendering: false,
            searchEnabled: true,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'tanggalEfektif',
        label: {
            text: 'Tanggal Efektif'
        },
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
            openOnFieldClick: true
        }
    },
]

export const DataBrokerInvestasi = (store) => [
    {
        dataField: 'productId',
        label: {
            text: 'Kode Produk'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: productDefault(store),
            valueExpr: 'id',
            displayExpr: 'productCode',
            readOnly: true
        }
    },
    {
        dataField: 'rekNo',
        label: {
            text: 'No. Rekening'
        }
    },
    {
        dataField: 'rekName',
        label: {
            text: 'Nama Rekening'
        }
    },
    {
        dataField: 'brokerId',
        label: {
            text: 'Broker'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: brokerStaticData(store),
            valueExpr: 'id',
            displayExpr: 'brokerName',
            deferRendering: false,
            searchEnabled: true,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'tanggalEfektif',
        label: {
            text: 'Tanggal Efektif'
        },
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
            openOnFieldClick: true
        }
    },
]

export const DataMasterManagerInvestasi = (store) => [
    {
        dataField: 'productId',
        label: {
            text: 'Kode Produk'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: productDefault(store),
            valueExpr: 'id',
            displayExpr: 'productCode',
            readOnly: true
        }
    },
    {
        dataField: 'rekNo',
        label: {
            text: 'No. Rekening'
        }
    },
    {
        dataField: 'rekName',
        label: {
            text: 'Nama Rekening'
        }
    },
    {
        dataField: 'miId',
        label: {
            text: 'Manager Investasi'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: managerInvestasis(store),
            valueExpr: 'id',
            displayExpr: 'miName',
            deferRendering: false,
            searchEnabled: true,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'bankId',
        label: {
            text: 'Bank'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: banks(store),
            valueExpr: 'id',
            displayExpr: 'bankName',
            deferRendering: false,
            searchEnabled: true,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'cabang',
        label: {
            text: 'Cabang'
        },
    },
    {
        dataField: 'tanggalEfektif',
        label: {
            text: 'Tanggal Efektif'
        },
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
            openOnFieldClick: true
        }
    },
]

export const DataRekeningAperdInvestasi = (store) => [
    {
        dataField: 'productId',
        label: {
            text: 'Kode Produk'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: productDefault(store),
            valueExpr: 'id',
            displayExpr: 'productCode',
            readOnly: true
        }
    },
    {
        dataField: 'rekNo',
        label: {
            text: 'No. Rekening'
        }
    },
    {
        dataField: 'rekName',
        label: {
            text: 'Nama Rekening'
        }
    },
    {
        dataField: 'aperdId',
        label: {
            text: 'APERD'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: aperds(store),
            valueExpr: 'id',
            displayExpr: 'aperdName',
            deferRendering: false,
            searchEnabled: true,
            openOnFieldClick: true
        }
    },
    {
        dataField: 'tanggalEfektif',
        label: {
            text: 'Tanggal Efektif'
        },
        editorType: 'dxDateBox',
        editorOptions: {
            displayFormat: 'dd MMM yyyy',
            useMaskBehavior: true,
            openOnFieldClick: true
        }
    },
]


export default loadColumnConfig