import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, hubunganKeluarga, months, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import {confirm} from "devextreme/ui/dialog";
import { showLoading } from 'redux/actions/loading';
import { alert } from 'devextreme/ui/dialog';
import { formatDate, formatDefaultDate } from 'plugin/helper';

class PembayaranMpGeneratePembayaranModal extends Component {
    constructor(props){
        super(props)

        this.dataResult = []

        this.state = {
            formData: {},
            dataMaster: {},
            getUrl: 'view-pembayaran-mp-detils?pembayaranMpId.in=0'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.pembayaranMpId = 0
        this.id = 0

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async() => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            await this.submitData()
                        }
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Produk harus di isi'
                    },
                ],
            },
            {
                dataField: 'bulan',
                label: {
                    alignment: 'left',
                    text: 'Periode'
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'MMM yyyy',
                    calendarOptions: {
                        maxZoomLevel: 'year'
                    },
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Periode harus di isi'
                    },
                ],
            },
            {
                dataField: 'paymentDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Bayar'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    onValueChanged: async(e) => {
                       if (e.value) {
                        var tanggalBayarValue = e.value;
                        var bulanValue = this.formRef.current.instance.getEditor('bulan').option('value'); // Replace with the actual reference to the tanggalBayar value

                        var tanggalBayarValueDate = new Date(tanggalBayarValue),
                        tanggalBayarValueMonth = '' + (tanggalBayarValueDate.getMonth() + 1),
                        tanggalBayarValueYear = tanggalBayarValueDate.getFullYear();
                        if (tanggalBayarValueMonth.length < 2){
                            tanggalBayarValueMonth = '0' + tanggalBayarValueMonth;
                        }
                        var datesTanggalBayar = [tanggalBayarValueYear,tanggalBayarValueMonth].join('');

                        var bulanValueDate = new Date(bulanValue),
                        bulanValueMonth = '' + (bulanValueDate.getMonth() + 1),
                        bulanValueYear = bulanValueDate.getFullYear();
                        if (bulanValueMonth.length < 2){
                            bulanValueMonth = '0' + bulanValueMonth;
                        }
                        var datesBulanValue = [bulanValueYear,bulanValueMonth].join('');

                        if (datesTanggalBayar < datesBulanValue) {
                            this.formRef.current.instance.getEditor('tanggalBayar').option('value', null);
                            let message = 'Tanggal Bayar tidak bisa kurang dari periode';
                            await alert(message, 'Gagal');
                            return false;
                        }
                       }
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal bayar harus di isi'
                    },
                ],
            },
        ]

        this.dataGridColumn = [
            {
               dataField: 'no',
               caption: 'No',
               cellRender: (e) => {
                   return e.rowIndex + 1
               }
            }, 
            {
               dataField: 'nikPeserta',
               caption: 'NIK'
            },
            {
               dataField: 'noCard',
               caption: 'Card No'
            },
            {
               dataField: 'namaPeserta',
               caption: 'Nama Pensiunan'
            },
            // {
            //    dataField: 'statusMeninggal',
            //    caption: 'Status Meninggal',
            // },
            {
               dataField: 'mpDetilNominal',
               caption: 'Nilai Mp Bulanan',
               format: '#,##0.00',
               alignment: 'right'
            },
            {
               dataField: 'namaPenerima',
               caption: 'Nama Penerima',
               cellRender: (e) => {
                   var data = e.row.data
                   if(data.pensiunPenerimaTypeId === 1){
                        return data.namaPeserta
                    }else{
                        return "-"
                   }
               }
            },
            {
               dataField: 'relasiCode',
               caption: 'Hubungan Keluarga',
               lookup:{
                    dataSource: hubunganKeluarga(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
            {
               dataField: 'mpDetilRekeningNo',
               caption: 'Nomor Rekening'
            },
            {
               dataField: 'mpDetilRekeningName',
               caption: 'Nama Rekening'
            },
            {
               dataField: 'mpDetilRekeningBankId',
               caption: 'Bank',
               lookup:{
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName' 
                }
            }
        ]

    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hidePopup = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        this.forceRefresh()
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
        store.dispatch(showLoading(false))
    }

    hide = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var data = this.dataGridRef.current.getDataSource()
        if(data.length !== 0){
            try {
                var loadAPIDetail = `pembayaran-mp-detils/deleteByPembayaranMpId`
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIDetail, 'DELETE', this.mpId)
                
                var loadAPIMaster = `pembayaran-mps`
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIMaster, 'DELETE', this.mpId)
            } catch (error) {
                console.log(error);
            }
        }
        this.forceRefresh()
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
        store.dispatch(showLoading(false))
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    generate = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        let data = this.state.dataMaster;
        let bulan = formatDate(data.bulan);
        let arr = bulan.split('-');
        let getBulan = parseInt(arr[1]);
        let getTahun = parseInt(arr[0]);

        var dataSubmit = {
            ...this.state.dataMaster,
            bulan: getBulan,
            mpPaymentTypeId : 3,
            akunStatus: '2,7' ,
            // tahun : new Date().getFullYear(),
            tahun: getTahun,
            user: this.props.store.getState().sessionUser
        }
        try {
            let responseValidateMonth = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'simple-pembayaran-mps/getSimpleAllByMpPaymentType/3/1', 'GET');

            var loadAPI = 'pembayaran-mp-bulanan'
            var result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: dataSubmit
            })

            let bulan = this.state.dataMaster.bulan;
            let year = this.state.dataMaster.tanggalBayar;
            let fullYear = new Date(year).getFullYear();
            
            for (let value of responseValidateMonth) {
                if (value.bulan === bulan && value.tahun === fullYear) {
                    store.dispatch(showLoading(false));
                    let message = 'Data Bulan dan Tahun Sudah ada!';
                    await alert(message, 'Gagal!');
                    return false;
                }
            }

            if (result.status === "ERROR") {
                store.dispatch(showLoading(false));
                let message = 'Data Bulan dan Tahun Sudah ada!';
                await alert(message, 'Gagal!');
                return false;
            }

            if(result){
                var interval = setInterval(async() => { 
                    try {
                        var loadAPI = `view-pembayaran-mp-detils?pembayaranMpId.in=${result.mp_id}`
                        var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')  
                        if(getPembayaranMp.length !== 0){
                            console.log('test');
                            clearInterval(interval)
                            this.props.store.dispatch(showLoading(false))
                            var message = 'Data Berhasil Di Generate!'
                            var res = await alert(message, 'Berhasil!')
                            if(res){
                                await this.loadDataGrid(result.mp_id)
                            }
                        }
                    } catch (e) {
                        console.log(e)
                    }

                 }, 5000);
            }
        } catch (e) {
            store.dispatch(showLoading(false))
            var message = e
            await alert(message, 'Data Gagal!')
        }
    }

    submitData = async() => {
        let data = this.dataGridRef.current.getDataSource().data;
        
        if(data){
            var loadAPI = `pembayaran-mps`
            var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI + '/' + data[0].pembayaranMpId, 'GET')
            
            var dataUpdate = {...getPembayaranMp}
            dataUpdate.statusId = 1
    
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: data[0].pembayaranMpId,
                values: dataUpdate
            },getPembayaranMp)
        }

        this.props.forceRefresh()
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
        this.forceRefresh()
    }

    retrieveData = async(data) => {
        if(data){
            const loadAPIMaster = `pembayaran-mps/${data.id}`
            let getMasterPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')

            this.formRef.current.instance.updateData({
                bulan: `${getMasterPembayaranMp.tahun}-${getMasterPembayaranMp.bulan}-1`,
                productId: getMasterPembayaranMp.productId,
                paymentDate: getMasterPembayaranMp.paymentDate
            })

            const loadAPIDetil = `view-pembayaran-mp-detils?pembayaranMpId.in=${data.id}`
            let responseDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil, 'GET')

            this.forceRefresh()

            this.dataResult = responseDetil
        }
    }

    loadData = () => {
        return this.dataResult
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: "Proses",
                    onClick: async (e) => {
                        let bulan = this.state.dataMaster.bulan;
                        let year = this.state.dataMaster.bulan;

                        const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
                        let getBulan = new Date(bulan).getMonth();
                        let bulanValue = months[getBulan]
                        let fullYear = new Date(year).getFullYear();

                        let result = await confirm(`Apakah anda ingin mengerate data bulan ${bulanValue} tahun ${fullYear}`, 'Konfirmasi!');

                        if (result) {
                            this.generate()   
                        }
                    },
                }
            },
        );
    }

    loadDataGrid = async(id) => {
        this.setState({
            getUrl: `view-pembayaran-mp-detils?pembayaranMpId.in=${id}`
        })
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hidePopup}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Generate Pembayaran Manfaat Pensiun Bulanan'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='pembayaran-mps/getSimpleAllByMpPaymentType'
                                updateAPI='pembayaran-mps/getSimpleAllByMpPaymentType'
                                deleteAPI='pembayaran-mps/getSimpleAllByMpPaymentType' 
                                
                                backendserver={process.env.REACT_APP_BACKEND_CORE}
                                keyField = {'idPembayaranMpDetil'}

                                remoteOperations = {true}
                                // useArraySource = {true}
                                // ArraySourceData = {this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Pembayaran MP Bulanan Generate"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Pembayaran MP Bulanan Generate'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                height={'calc(65vh - 88px)'}
                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default PembayaranMpGeneratePembayaranModal