import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { product } from 'dataSource/lookup'
import { Form, Button } from 'devextreme-react'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDate, formatDefaultDate, formatDefaultMonthYear, formatNumber, generateExcelFile, PrintElem } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';
import { alert } from 'devextreme/ui/dialog';

const bulan = [
    {
        id : 1,
        namaBulan: 'Januari'
    },
    {
        id : 2,
        namaBulan: 'Februari'
    },
    {
        id : 3,
        namaBulan: 'Maret'
    },
    {
        id : 4,
        namaBulan: 'April'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Juni'
    },
    {
        id : 7,
        namaBulan: 'Juli'
    },
    {
        id : 8,
        namaBulan: 'Agustus'
    },
    {
        id : 9,
        namaBulan: 'September'
    },
    {
        id : 10,
        namaBulan: 'Oktober'
    },
    {
        id : 11,
        namaBulan: 'November'
    },
    {
        id : 12,
        namaBulan: 'Desember'
    }
]
class ReportMpSekaligus extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formFilter: {},
            src: ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'greaterPaymentDate',
                                label: {
                                    text: 'Periode'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                },
                            },
                            {
                                dataField: 'lessPaymentDate',
                                label: {
                                    text: 'S/D'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            }
                        ]
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        dataField: 'tipeMpSekaligus',
                        label: {
                            text: 'Tipe MP Sekaligus',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'Semua',
                                    value: 'Semua'
                                },
                                {
                                    id: 'Sekaligus 100%',
                                    value: '100%'
                                },
                                {
                                    id: 'Sekaligus 20%',
                                    value: '20%'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-sekaliguses?`
        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.tipeMpSekaligus === 'Sekaligus 20%') {
            loadAPI = loadAPI+`tipeMp.in=Sekaligus%2020%25&`
        } else if (filter.tipeMpSekaligus === 'Sekaligus 100%') {
            loadAPI = loadAPI+`tipeMp.in=Sekaligus%20100%25&`
        } else {
            loadAPI = loadAPI
        }

        if(filter.greaterPaymentDate) {
            loadAPI = loadAPI+`paymentDate.greaterThanOrEqual=${formatDate(filter.greaterPaymentDate)}&`
        } 

        if(filter.lessPaymentDate) {
            loadAPI = loadAPI+`paymentDate.lessThanOrEqual=${formatDate(filter.lessPaymentDate)}&`
        }
        
        // PERIODE
        // if(filter.periode){
        //     var bulanPeriode = new Date(filter.periode).getMonth() + 1;
        //     var tahunPeriode = new Date(filter.periode).getFullYear()
        //     loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        // }

        var getMpSekaligus = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var getBankAll = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET'))
        var jenisPenerimaAll = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))

        var arrSumNominalMp = []
        var arrNominalMpBruto = []
        var arrNominalPajak = []

        for(var value of getMpSekaligus){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            let totalMpBulanan = value.nominalPembayaran ? value.nominalPembayaran : 0
            let totalMpBruto = value.nominalMpBruto ? value.nominalMpBruto : 0
            let totalPajak = value.nominalPajak ? value.nominalPajak : 0

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
            value.mpRekeningName = value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName

            arrSumNominalMp.push(totalMpBulanan)
            arrNominalMpBruto.push(totalMpBruto)
            arrNominalPajak.push(totalPajak)
        }

        if(arrSumNominalMp.length !== 0 && arrNominalMpBruto.length !== 0 && arrNominalPajak.length !== 0) {
            var qtySumPembayaran = arrSumNominalMp.reduce((a, b) => a + b)
            var qtySumBruto = arrNominalMpBruto.reduce((a, b) => a + b)
            var qtySumPajak = arrNominalPajak.reduce((a, b) => a + b)
        }
        
        getMpSekaligus = getMpSekaligus.map(value => {
            if(value.paymentDate){
                value.paymentDate = formatDefaultDate(value.paymentDate)
            }
            value.mpNominal = formatNumber(value.mpNominal || 0)

            return value
        })
        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/pembayaran-mp-sekaligus',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                periode : `${filter.greaterPaymentDate ? formatDefaultDate(filter.greaterPaymentDate) : 'Semua'} S/D ${filter.lessPaymentDate ? formatDefaultDate(filter.lessPaymentDate) : 'Semua'}`,
                tipeMpp: filter.tipeMpSekaligus ? filter.tipeMpSekaligus : 'Semua',
                dataPeserta : getMpSekaligus,
                summaryNominalMp: qtySumPembayaran,
                summaryNominalMpBruto: qtySumBruto,
                summaryNominalPajak: qtySumPajak,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))
        this.setState({
            src : responseFile
        })
    }

    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-sekaliguses?`
        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.tipeMpSekaligus === 'Sekaligus 20%') {
            loadAPI = loadAPI+`tipeMp.in=Sekaligus%2020%25&`
        } else if (filter.tipeMpSekaligus === 'Sekaligus 100%') {
            loadAPI = loadAPI+`tipeMp.in=Sekaligus%20100%25&`
        } else {
            loadAPI = loadAPI
        }

        if(filter.greaterPaymentDate) {
            loadAPI = loadAPI+`paymentDate.greaterThanOrEqual=${formatDate(filter.greaterPaymentDate)}&`
        } 

        if(filter.lessPaymentDate) {
            loadAPI = loadAPI+`paymentDate.lessThanOrEqual=${formatDate(filter.lessPaymentDate)}&`
        }
        
        // PERIODE
        // if(filter.periode){
        //     var bulanPeriode = new Date(filter.periode).getMonth() + 1;
        //     var tahunPeriode = new Date(filter.periode).getFullYear()
        //     loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        // }

        var getMpSekaligus = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var getBankAll = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET'))
        var jenisPenerimaAll = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))

        var arrSumNominalMp = []
        var arrNominalMpBruto = []
        var arrNominalPajak = []

        for(var value of getMpSekaligus){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            let totalMpBulanan = value.nominalPembayaran ? value.nominalPembayaran : 0
            let totalMpBruto = value.nominalMpBruto ? value.nominalMpBruto : 0
            let totalPajak = value.nominalPajak ? value.nominalPajak : 0

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
            value.mpRekeningName = value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName

            arrSumNominalMp.push(totalMpBulanan)
            arrNominalMpBruto.push(totalMpBruto)
            arrNominalPajak.push(totalPajak)
        }

        if(arrSumNominalMp.length !== 0 && arrNominalMpBruto.length !== 0 && arrNominalPajak.length !== 0) {
            var qtySumPembayaran = arrSumNominalMp.reduce((a, b) => a + b)
            var qtySumBruto = arrNominalMpBruto.reduce((a, b) => a + b)
            var qtySumPajak = arrNominalPajak.reduce((a, b) => a + b)
        }

        getMpSekaligus = getMpSekaligus.map(value => {
            if(value.paymentDate){
                value.paymentDate = formatDefaultDate(value.paymentDate)
            }
            value.mpNominal = formatNumber(value.mpNominal || 0)

            return value
        })
        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/pembayaran-mp-sekaligus',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                periode : `${filter.greaterPaymentDate ? formatDefaultDate(filter.greaterPaymentDate) : 'Semua'} S/D ${filter.lessPaymentDate ? formatDefaultDate(filter.lessPaymentDate) : 'Semua'}`,
                tipeMpp: filter.tipeMpSekaligus ? filter.tipeMpSekaligus : 'Semua',
                dataPeserta : getMpSekaligus,
                summaryNominalMp: qtySumPembayaran,
                summaryNominalMpBruto: qtySumBruto,
                summaryNominalPajak: qtySumPajak,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'PEMBAYARAN MANFAAT PENSIUN SEKALIGUS'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'PEMBAYARAN MANFAAT PENSIUN SEKALIGUS')
        store.dispatch(showLoading(false))
        return responseFile
    }

    exportExcel = async() => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-sekaliguses?`
        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.tipeMpSekaligus === 'Sekaligus 20%') {
            loadAPI = loadAPI+`tipeMp.in=Sekaligus%2020%25&`
        } else if (filter.tipeMpSekaligus === 'Sekaligus 100%') {
            loadAPI = loadAPI+`tipeMp.in=Sekaligus%20100%25&`
        } else {
            loadAPI = loadAPI
        }

        if(filter.greaterPaymentDate) {
            loadAPI = loadAPI+`paymentDate.greaterThanOrEqual=${formatDate(filter.greaterPaymentDate)}&`
        } 

        if(filter.lessPaymentDate) {
            loadAPI = loadAPI+`paymentDate.lessThanOrEqual=${formatDate(filter.lessPaymentDate)}&`
        }
        
        // PERIODE
        // if(filter.periode){
        //     var bulanPeriode = new Date(filter.periode).getMonth() + 1;
        //     var tahunPeriode = new Date(filter.periode).getFullYear()
        //     loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        // }

        var getMpSekaligus = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var getBankAll = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET'))
        var jenisPenerimaAll = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-penerima-types`, 'GET'))

        var arrSumNominalMp = []
        var arrNominalMpBruto = []
        var arrNominalPajak = []
        var index = 1
        for(var value of getMpSekaligus){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'

            var getBank = getBankAll.find(val => val.id == value.mpRekeningBankId)
            if(getBank){
                var bankName = getBank.initialName
            }else{
                var bankName = '-'
            }
            value.mpRekeningBankId = bankName

            var getPenerima = jenisPenerimaAll.find(val => val.id == value.manfaatPensiunPenerimaTypeId)
            if(getPenerima){
                var jenisPenerima = getPenerima.penerimaName
            }else{
                var jenisPenerima = '-'
            }
            value.manfaatPensiunPenerimaTypeId = jenisPenerima

            var dataValue = {
                id: index++,
                // productcode: value.productCode,
                // tipeMp: value.tipeMp,
                nik: value.nik,
                fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                paymentDate: value.paymentDate ? formatDefaultDate(value.paymentDate) : '-',
                manfaatPensiunPenerimaTypeId: value.manfaatPensiunPenerimaTypeId,
                npwp: value.npwp,
                mpRekeningName: value.mpRekeningName ? (value.mpRekeningName).toUpperCase() : value.mpRekeningName,
                mpRekeningNo: value.mpRekeningNo,
                mpPenerimaBankId: value.mpRekeningBankId,
                nominalMpBruto: value.nominalMpBruto ? value.nominalMpBruto : '0',
                nominalPajak: value.nominalPajak ? value.nominalPajak : '0',
                mpNominal: value.nominalPembayaran ? value.nominalPembayaran : '0',
            }

            const objectValue = Object.values(dataValue)

            let totalMpBulanan = value.nominalPembayaran ? value.nominalPembayaran : 0
            let totalMpBruto = value.nominalMpBruto ? value.nominalMpBruto : 0
            let totalPajak = value.nominalPajak ? value.nominalPajak : 0

            arrSumNominalMp.push(totalMpBulanan)
            arrNominalMpBruto.push(totalMpBruto)
            arrNominalPajak.push(totalPajak)

            data.push(objectValue)
        }

        if(arrSumNominalMp.length !== 0 && arrNominalMpBruto.length !== 0 && arrNominalPajak.length !== 0) {
            var qtySumPembayaran = arrSumNominalMp.reduce((a, b) => a + b)
            var qtySumBruto = arrNominalMpBruto.reduce((a, b) => a + b)
            var qtySumPajak = arrNominalPajak.reduce((a, b) => a + b)
        }

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('PEMBAYARAN MANFAAT PENSIUN SEKALIGUS');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A1',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                // {name: 'Produk'},
                // {name: 'Tipe MP LainNo'},
                {name: 'NIK'},
                {name: 'Nama Peserta'},
                {name: 'Tanggal Pembayaran'},
                {name: 'Jenis Penerima'},
                {name: 'NPWP'},
                {name: 'Nama Rekening Penerima'},
                {name: 'No Rekening Penerima'},
                {name: 'Bank Penerima'},
                {name: 'MP Bruto\r\n(Rp)'},
                {name: 'Pajak\r\n(Rp)'},
                {name: 'Pembayaran\r\n(Rp)'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                }))
            }

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BPEMBAYARAN MANFAAT PENSIUN SEKALIGUS\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BPEMBAYARAN MANFAAT PENSIUN SEKALIGUS\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BPEMBAYARAN MANFAAT PENSIUN SEKALIGUS\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // worksheet.getCell('A3').value = 'Kode'
            // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = ': PEMBAYARAN PENGAJUAN MANFAAT PENSIUN SEKALIGUS'

            // worksheet.getCell('A5').value = 'Tanggal Laporan'
            // worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A7').value = 'Periode'
            // worksheet.getCell('B7').value = `: ${filter.greaterPaymentDate ? formatDefaultDate(filter.greaterPaymentDate) : 'Semua'} S/D ${filter.lessPaymentDate ? formatDefaultDate(filter.lessPaymentDate) : 'Semua'}`
            
            // worksheet.getCell('A8').value = 'Produk'
            // worksheet.getCell('B8').value = `: ${productName}`

            // worksheet.getCell('A9').value = 'Tipe MP'
            // worksheet.getCell('B9').value = `: ${filter.tipeMpSekaligus ? filter.tipeMpSekaligus : 'Semua'}`

            worksheet.mergeCells(`A${lastTableRowNum + 1}:I${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'right'};
            worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

            worksheet.getCell(`J${lastTableRowNum + 1}`).value = (qtySumBruto)
            worksheet.getCell(`J${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`J${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`K${lastTableRowNum + 1}`).value = (qtySumPajak)
            worksheet.getCell(`K${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`K${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`L${lastTableRowNum + 1}`).value = (qtySumPembayaran)
            worksheet.getCell(`L${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`L${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('J1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('K1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('L1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            for(var i = 2; i <= data.length + 1; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 45;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = 35;

            const Data6 = worksheet.getColumn(6);
            Data6.width = 35;

            const Data7 = worksheet.getColumn(7);
            Data7.width = 35;

            const Data8 = worksheet.getColumn(8);
            Data8.width = 35;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = 35;
            Data10.numFmt = '#,##0.00';

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;
            Data11.numFmt = '#,##0.00';

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;
            Data12.numFmt = '#,##0.00';
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `PEMBAYARAN MANFAAT PENSIUN SEKALIGUS.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"productcode", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"tipeMp", 
        //         to:"Tipe MP Lain"
        //     },
        //     {
        //         from:"paymentDate", 
        //         to:"Tanggal Pembayaran"
        //     },
        //     {
        //         from:"nik", 
        //         to:"NIK"
        //     },
        //     {
        //         from:"fullName", 
        //         to:"Nama Peserta"
        //     },
        //     {
        //         from:"manfaatPensiunPenerimaTypeId", 
        //         to:"Nama Penerima"
        //     },
        //     {
        //         from:"mpRekeningNo", 
        //         to:"No Rekening Penerima"
        //     },
        //     {
        //         from:"mpRekeningName", 
        //         to:"Nama Rekening Penerima"
        //     },
        //     {
        //         from:"mpPenerimaBankId", 
        //         to:"Bank Penerima"
        //     },
        //     {
        //         from:"mpNominal", 
        //         to:"Nominal MP"
        //     },
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'PEMBAYARAN PENGAJUAN MANFAAT PENSIUN SEKALIGUS'
        //     },
        //     {
        //         A: 'Tanggal Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Periode',
        //         B: null,
        //         C: ':',
        //         D: filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        //     {
        //         A: 'Tipe MP',
        //         B: null,
        //         C: ':',
        //         D: tipeMp
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getMpSekaligus){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})
        // store.dispatch(showLoading(false))
        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // generateExcelFile(excelBlob,'LAPORAN_PENGAJUAN_MP_SEKALIGUS')
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pembayaran Manfaat Pensiun Sekaligus</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.formRef}
                            formData={this.state.formFilter}
                            items={this.formItem}
                            labelLocation={"left"}
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportMpSekaligus