import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import { Form } from "devextreme-react";
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import { pesertaById } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import FormDataDplk from '../comp/formDataDplk';
import FormDataPersonal from '../../../compPeserta/dataPersonal';
import FormDataPersonalDPLK from '../comp/formDataPersonalDplk';
import DataGridKeluargaDplk from '../comp/dataKeluargaDplk';
import FormDataAkunDplk from '../comp/dataAkunDplk';
import FormDataKepegawaianDplk from '../comp/formDataKepegawaianDplk';

class ModalPengajuanDplk extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            pesertaId: 0,
            actionType: 'add',
            periodeNs: {},
            tahun: 0
        }

        this.pesertaId = 0
        this.pesertaIdAkun = 0
        this.prevTabIndex = 0
        this.dataPeserta = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()

        this.formMasterRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formDataKepegawaianRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.formDataAkunRef = React.createRef()
        this.formDataDplkRef = React.createRef()
        this.dropDownBoxRef = React.createRef()
        this.formFilterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Pensiun'
            },
            {
                id: 1,
                text: 'Data Personal'
            },
            {
                id: 2,
                text: 'Data Keluarga'
            },
            {
                id: 3,
                text: 'Data Akun'
            }, 
            {
                id: 4,
                text: 'Data Kepegawaian'
            }
        ]

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

        this.filterItem = [
            {
                dataField: 'periodeNs',
                label: {
                    text: 'Periode',
                    alignment: "left",
                    location: "left",
                    visible: false
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'tahun',
                    displayExpr: 'tahun',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    // onValueChanged: (data) => {
                    //     // if (data.value) {
                    //     //     this.setState({
                    //     //         tahun: data.value
                    //     //     })
                    //     // }
                    // }
                }
            },
        ]
    }
    get Popup() {
        return this.popupRef.current.instance
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    show(data) {
        let ns = []
        for(let val of data) {
            ns.push({
                tahun: val
            })
        }
        this.formFilterRef.current.instance.getEditor('periodeNs').option('dataSource', ns);
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
        // this.prevTabIndex = 0
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            periodeNs: {}
        })
        // this.props.forceRefresh();
    }

    onValueChanged = (e) => {
        var fieldValue = e.value 
        this.pesertaId = fieldValue
        this.formDataDplkRef.current.reloadData(this.pesertaId || null, this.state.dataMaster);
        this.formDataPersonalRef.current.reloadDataPersonal(this.pesertaId || null, this.state.dataMaster);
        this.dataGridKeluargaRef.current.reloadDataKeluarga(this.pesertaId || null, this.state.dataMaster);
        this.formDataAkunRef.current.reloadDataAkun(this.pesertaId || null, this.state.dataMaster);
        this.formDataKepegawaianRef.current.reloadDataKepegawaian(this.pesertaId || null, this.state.dataMaster);
    }
    setSelectedRow = (id) => {
        this.dropDownBoxRef.current.instance.option('value', id)
    }
    submitData = async (data = {}) => {
        if (this.state.actionType == 'add') {
            var postData = {
                "akunStatusId": 12,
                "dplkTujuan": data.dplkTujuan,
                "fileSkPensiun": data.fileSkPensiun,
                "fileSkPensiunContentType": data.fileSkPensiunContentType,
                "fileSkPhk": data.fileSkPhk,
                "fileSkPhkContentType": data.fileSkPhkContentType,
                "idAkunProduct": data.id,
                "nominalPengalihan": data.nominalPengalihan,
                "pengalihanRekeningBankId": data.pengalihanRekeningBankId,
                "pengalihanRekeningName": data.pengalihanRekeningName,
                "pengalihanRekeningNo": data.pengalihanRekeningNo,
                "pensiunType": data.pensiunType,
                "pesertaPhkTypeId": data.pesertaPhkTypeId,
                "productId": data.productId,
                "skPemberhentianNo": data.skPemberhentianNo,
                "skPensiunNo": data.skPensiunNo,
                "tglDialihkan": data.tglDialihkan,
                "tglPembayaranPengalihan": data.tglPembayaranPengalihan,
                "transferRekbankId": data.transferRekbankId
            }
            try {
                var loadAPI = 'registrasi-dplk'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                    values: postData
                })

                await alert('Berhasil menambahkan pengajuan dplk baru', 'Berhasil!')
            } catch (e) {
                await alert(e, 'Gagal!')
            }
        } else {
            try {
                var loadAPI = 'kepersertaan-products'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                    key: data.id,
                    values: data
                }, data)
                await alert('Berhasil merubah pengajuan dplk', 'Berhasil!')
            } catch (e) {
                await alert(e, 'Gagal!')
            }
        }
        this.hide()
        this.props.forceRefresh()
    }

    retrieveData = async (data = null, type) => {
        try {
            var loadApiPesertaId = `pesertas/${data.pesertaId}`;
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApiPesertaId, 'GET');

            if(type !== 'add'){
                var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${data.pesertaId}`, 'GET')

                if(getKp.length > 0){
                    getKp = getKp[0]

                    var dataNs = {
                        periodeNs: getKp.nsPeriode
                    }

                    this.formFilterRef.current.instance.updateData(dataNs)
                }
            }
        } catch (error) {
            console.log(error);
        }

        if (data) {
            await this.formDataPersonalRef.current.retrieveData(getPeserta);
            
            this.setState({
                dataMaster: data,
                actionType: type
            })
            this.pesertaId = data.pesertaId
            this.setSelectedRow(data.pesertaId)
            await this.formDataDplkRef.current.reloadData(data.pesertaId, data)
        } else {
            // await this.formDataDplkRef.current.reloadData()
            this.setState({
                pesertaId: 0,
                dataMaster: {},
            })

            this.pesertaId = 0
            this.formDataDplkRef.current.forceRefresh()
        }
    }

    onTabSelectionChanged = async (args) => {
        document.getElementById(`tabModalIndex${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabModalIndex${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        // if (args.value === 3) {
        //     await alert('Under development', 'Information!');
        // }

        this.prevTabIndex = args.value
    }

    onSelectionChanged = (e) => {
        this.dropDownBoxRef.current.instance.option('value', e.selectedRowKeys)
        this.pesertaId = e.selectedRowKeys
        this.formDataDplkRef.current.reloadData(e.selectedRowKeys, this.state.dataMaster)
    }

    removeState = () => {
        this.formDataPersonalRef.current.removeState();
        this.dataGridKeluargaRef.current.removeState();
        this.formDataAkunRef.current.removeState();
    }

    renderDatagrid = (e) => {
        return (
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI={this.props.actionType == 'add' ? 'view-pesertas?statusPeserta.in=2&statusPeserta.in=3&statusPeserta.in=5&statusPeserta.in=6' : 'view-pesertas'}
                insertAPI='lookup-pesertas/getByLookupByStatusPeserta/2'
                updateAPI='lookup-pesertas/getByLookupByStatusPeserta/2'
                deleteAPI='lookup-pesertas/getByLookupByStatusPeserta/2'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations={true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged={this.onSelectionChanged}

                height={'auto'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Peserta DPLK'}
                minWidth={800}
                minHeight={800}

                ref={this.popupRef}

                toolbarItems={this.popupToolbarItem}
            >
            <div className="container-fluid">
            <div className="row" style={{height: 'calc(90vh - 325px)'}}>
            <div className="col-md-12">
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Periode</label>
                        <div className='col-sm-10'>
                            <Form
                                formData={this.state.periodeNs}
                                items={this.filterItem}
                                ref={this.formFilterRef}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Peserta</label>
                        <div className="col-sm-10">
                            <DropDownBox
                                ref={this.dropDownBoxRef}
                                value={this.props.actionType === 'add' ? null : this.state.dataMaster.pesertaId}
                                valueExpr="id"
                                deferRendering={false}
                                displayExpr={(item) => {
                                    return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                }}
                                showClearButton={true}
                                dataSource={pesertaById(this.props.store)}
                                onValueChanged={this.onValueChanged}
                                contentRender={this.renderDatagrid}
                                readOnly={this.props.actionType == 'add' ? false : true}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                </div>
                <ScrollView width='100%' height='100%'>
                    <div id={`tabModalIndex${this.props.actionType}-0`} className={'mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)'}}>
                        <FormDataDplk store={this.props.store} submitData={this.submitData} actionType={this.props.actionType} ref={this.formDataDplkRef} peridoeNs={this.state.tahun} cleanDataGrid={this.cleanDataGrid} forceRefresh={this.props.forceRefresh} />
                    </div>
                    <div id={`tabModalIndex${this.props.actionType}-1`} className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)'}}>
                        <FormDataPersonalDPLK store={this.props.store} submitData={this.submitData} actionType={this.props.actionType} ref={this.formDataPersonalRef} />
                    </div>
                    <div id={`tabModalIndex${this.props.actionType}-2`} className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)'}}>
                        <DataGridKeluargaDplk store={this.props.store} submitData={this.submitData} actionType={this.props.actionType} ref={this.dataGridKeluargaRef} />
                    </div>
                    <div id={`tabModalIndex${this.props.actionType}-3`} className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)'}}>
                        <FormDataAkunDplk store={this.props.store} submitData={this.submitData} actionType={this.props.actionType} ref={this.formDataAkunRef} />
                    </div>
                    <div id={`tabModalIndex${this.props.actionType}-4`} className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(30vh)'}}>
                        <FormDataKepegawaianDplk store={this.props.store} submitData={this.submitData} actionType={this.props.actionType} ref={this.formDataKepegawaianRef} />
                    </div>
                </ScrollView>
            </div>
            </div>
            </Popup>
        )
    }
}

export default ModalPengajuanDplk