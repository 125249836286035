import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from 'devextreme/ui/notify'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { banks, managerInvestasis, custodies } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

class ModalActionKpd extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
            actionType: 'add'
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                dataField: 'noKontrak',
                label: {
                    alignment: 'left',
                    text: 'Nomor Kontrak'
                },
                editorType: 'dxNumberBox'
            },
            {
                dataField: 'managerInvestasiId',
                label: {
                    alignment: 'left',
                    text: 'Manager Investasi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'miName', // provides display values
                },
            },
            {
                dataField: 'custodyId',
                label: {
                    alignment: 'left',
                    text: 'Bank Custody'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'custodyName', // provides display values
                },
            },
            {
                dataField: 'periode',
                label: {
                    alignment: 'left',
                    text: 'Periode'
                },
            },
            {
                dataField: 'startDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Kontrak'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'endDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Akhir'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'nominal',
                label: {
                    alignment: 'left',
                    text: 'Nominal Kontrak'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'target',
                label: {
                    alignment: 'left',
                    text: 'Target (%)'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##',
                }
            },
            {
                dataField: 'status',
                label: {
                    alignment: 'left',
                    text: 'Status'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Aktif'
                        },
                        {
                            id: 2,
                            value: 'Tidak Aktif'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
        ]
    }
    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async() => {
        var dataMaster = this.state.formData
        if(dataMaster){
            try{
                if(this.state.actionType == 'add'){
                    var insertAPI = 'kpds'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: dataMaster
                    })
                     
                    if(response){
                        notify({ message: 'Data berhasil ditambahkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.props.forceRefresh()
                        this.hide()
                    }
                }else{
                    var updateAPI = 'kpds'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                        key: dataMaster.id,
                        values: dataMaster
                    },dataMaster)
                     
                    if(response){
                        notify({ message: 'Data '+response.noKontrak+' berhasil diubah!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.props.forceRefresh()
                        this.hide()
                    }
                }
                return response
            }catch(e){
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    retrieveData = async(type, data = null) => {    
        if (type !== 'add'){
            this.setState({
                kpdId: data.id,
                actionType:type,
                formData: data
            })
        }else{
            this.setState({
                kpdId: 0,
                actionType: type,
                formData: {}
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form ' + (this.state.actionType == 'edit' ? 'Ubah' : 'Tambah') + ' KPD'}
                    width={700}
                    height={500}
                    ref={this.PopupRef}
                    toolbarItems = {this.popupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={"formMaster"}
                                    store={this.props.store}
                                    formData={this.state.formData}
                                    items={this.items}
                                    ref={this.formRef}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalActionKpd