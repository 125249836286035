import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import PreviewApproval from 'components/pages/modal/previewApproval'
import { addURL } from 'redux/actions/url'
import { Form } from "devextreme-react";
import { formatDate, getSystemDate, numberToIndo, postingJournal } from 'plugin/helper';
import notify from 'devextreme/ui/notify';
import DetailAudit from './detailAudit'
import { lookupModule } from 'components/pages/parts/staticDataColumnConfig'

const status = [
    {
        id: 0,
        value: 'In Progres'
    },
    {
        id: 1,
        value: 'Approved'
    },
    {
        id: 2,
        value: 'Rejected'
    }
]
class ApprovalHistory extends Component {
    constructor(props){
        super(props)

        this.state = {
            tanggal: {
                startDate: getSystemDate(props.store),
                endDate: getSystemDate(props.store),
            }
        }

        this.allDataRiwayat = []
        this.systemDate = new Date();

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Show Data',
                        hint : 'Show Data',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : this.getProcessData.bind(this)
                    },
                ],
            },
            {
                dataField: 'procInstanceId',
                caption: 'Process ID',
                visible: false
            },
            {
                dataField: 'type',
                caption: 'Module',
                lookup: {
                    dataSource: lookupModule,
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'operation',
                caption: 'Operation',
            },
            {
                dataField: 'taskName',
                caption: 'Task Name',
            },
            {
                dataField: 'creatorId',
                caption: 'Create By',
            },
            {
                dataField: 'lastStatus',
                caption: 'Status',
            },
        ]

        this.Tanggal = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd-MMM-yyyy'
                },
            },
            {
                dataField: "endDate",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd-MMM-yyyy'
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        if (!this.state.tanggal.endDate) {
                            let type = 'error'
                            let text = 'Please Set End Date !'

                            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                            return null
                        }

                        if (!this.state.tanggal.startDate) {
                            let type = 'error'
                            let text = 'Please Set Start Date !'

                            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                            return null
                        }

                        this.refreshDataGrid()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.previewApprovalRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    getProcessData = async (e) => {
        let data = e.row.data
        let type = data.type.split('svc')

        if(type.length !== 1) {
            if(type[0] === 'dphrd') {
                let processData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `staticdata-wf/historic/${data.procInstanceId}`, 'GET')
                this.previewApprovalRef.current.setFields(data.type)
                this.previewApprovalRef.current.setData(data, processData.data, data.taskId)
                this.previewApprovalRef.current.show()
            } else if (type[0] === 'dpcore') {
                let processData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `staticdata-wf/historic/${data.procInstanceId}`, 'GET')
                this.previewApprovalRef.current.setFields(data.type)
                this.previewApprovalRef.current.setData(data, processData.data, data.taskId)
                this.previewApprovalRef.current.show()
            } else if (type[0] === 'dpaccounting') {
                let processData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `staticdata-wf/historic/${data.procInstanceId}`, 'GET')
                this.previewApprovalRef.current.setFields(data.type)
                this.previewApprovalRef.current.setData(data, processData.data, data.taskId)
                this.previewApprovalRef.current.show()
            } else if (type[0] === 'dpinvestasi') {
                let processData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/historic/${data.procInstanceId}`, 'GET')
                this.previewApprovalRef.current.setFields(data.type)
                this.previewApprovalRef.current.setData(data, processData.data, data.taskId)
                this.previewApprovalRef.current.show()
            }
        } else {
            let processData = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `staticdata-wf/historic/${data.procInstanceId}`, 'GET')
            this.previewApprovalRef.current.setFields(data.type)
            this.previewApprovalRef.current.setData(data, processData.data, data.taskId)
            this.previewApprovalRef.current.show()
        }
    }

    refreshDataGrid = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        let allDataRiwayat = []
        try {
            let loadAPIDefault = `staticdata-wf/historic-tasks?taskCreatedEndDate=${formatDate(this.state.tanggal.endDate)}&taskCreatedStartDate=${formatDate(this.state.tanggal.startDate)}`
            let resAPIDefault = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPIDefault, 'GET')
            
            for (let value of resAPIDefault) {
                let dataValue = {
                    ...value,
                    procInstanceId: value.procInstanceId ? value.procInstanceId : '-',
                    type: value.type ? value.type : '-',
                    operation: value.operation ? value.operation : '-',
                    taskName: value.taskName ? value.taskName : '-',
                    creatorId: value.creatorId ? value.creatorId : '-',
                    lastStatus: status.find(val => val.id === value.lastStatus) ? status.find(val => val.id === value.lastStatus).value : '-' 
                }
    
                allDataRiwayat.push(dataValue)
            }
        } catch (e) {
            console.log(e)
        }

        try {
            let loadAPIAkunting = `staticdata-wf/historic-tasks?taskCreatedEndDate=${formatDate(this.state.tanggal.endDate)}&taskCreatedStartDate=${formatDate(this.state.tanggal.startDate)}`
            let resAPIAkunting = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPIAkunting, 'GET')
            
            for (let value of resAPIAkunting) {
                let dataValue = {
                    ...value,
                    procInstanceId: value.procInstanceId ? value.procInstanceId : '-',
                    type: value.type ? value.type : '-',
                    operation: value.operation ? value.operation : '-',
                    taskName: value.taskName ? value.taskName : '-',
                    creatorId: value.creatorId ? value.creatorId : '-',
                    lastStatus: status.find(val => val.id === value.lastStatus) ? status.find(val => val.id === value.lastStatus).value : '-'
                }
    
                allDataRiwayat.push(dataValue)
            }    
        } catch (e) {
            console.log(e)
        }

        try {
            let loadAPICore = `staticdata-wf/historic-tasks?taskCreatedEndDate=${formatDate(this.state.tanggal.endDate)}&taskCreatedStartDate=${formatDate(this.state.tanggal.startDate)}`
            let resAPICore = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPICore, 'GET')
            
            for (let value of resAPICore) {
                let dataValue = {
                    ...value,
                    procInstanceId: value.procInstanceId ? value.procInstanceId : '-',
                    type: value.type ? value.type : '-',
                    operation: value.operation ? value.operation : '-',
                    taskName: value.taskName ? value.taskName : '-',
                    creatorId: value.creatorId ? value.creatorId : '-',
                    lastStatus: status.find(val => val.id === value.lastStatus) ? status.find(val => val.id === value.lastStatus).value : '-'
                }
    
                allDataRiwayat.push(dataValue)
            }
        } catch (e) {
            console.log(e)
        }

        try {
            let loadAPIHrd = `staticdata-wf/historic-tasks?taskCreatedEndDate=${formatDate(this.state.tanggal.endDate)}&taskCreatedStartDate=${formatDate(this.state.tanggal.startDate)}`
            let resAPIHrd = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIHrd, 'GET')
            
            for (let value of resAPIHrd) {
                let dataValue = {
                    ...value,
                    procInstanceId: value.procInstanceId ? value.procInstanceId : '-',
                    type: value.type ? value.type : '-',
                    operation: value.operation ? value.operation : '-',
                    taskName: value.taskName ? value.taskName : '-',
                    creatorId: value.creatorId ? value.creatorId : '-',
                    lastStatus: status.find(val => val.id === value.lastStatus) ? status.find(val => val.id === value.lastStatus).value : '-'
                }
    
                allDataRiwayat.push(dataValue)
            }
        } catch (e) {
            console.log(e)
        }

        try {
            let loadAPIInv = `staticdata-wf/historic-tasks?taskCreatedEndDate=${formatDate(this.state.tanggal.endDate)}&taskCreatedStartDate=${formatDate(this.state.tanggal.startDate)}`
            let resAPIInv = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPIInv, 'GET')
            
            for (let value of resAPIInv) {
                let dataValue = {
                    ...value,
                    procInstanceId: value.procInstanceId ? value.procInstanceId : '-',
                    type: value.type ? value.type : '-',
                    operation: value.operation ? value.operation : '-',
                    taskName: value.taskName ? value.taskName : '-',
                    creatorId: value.creatorId ? value.creatorId : '-',
                    lastStatus: status.find(val => val.id === value.lastStatus) ? status.find(val => val.id === value.lastStatus).value : '-'
                }
    
                allDataRiwayat.push(dataValue)
            }
        } catch (e) {
            console.log(e)
        }

        this.allDataRiwayat = allDataRiwayat
        return allDataRiwayat;
    }

    customDetailElem = (data) => {
        return (
            <DetailAudit dataDetail={data} store={this.props.store} />
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Approval History</h2>
                <Form
                    colCount={4}
                    id={'formTanggal'}
                    formData={this.state.tanggal}
                    items={this.Tanggal}
                />
                <DevExpressDataGrid
                    loadAPI={`staticdata-wf/riwayat-persetujuan/tasks=${this.props.approveType}`}
                    insertAPI={`staticdata-wf/tasks?assignee=${this.props.approveType}`}
                    updateAPI={`staticdata-wf/tasks?assignee=${this.props.approveType}`}
                    deleteAPI={`staticdata-wf/tasks?assignee=${this.props.approveType}`} 

                    backendserver={process.env.REACT_APP_BACKEND}

                    keyField = {'procInstanceId'}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Approval History"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Approval History Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    
                    
                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                    ref={this.dataGridRef}
                />
                <PreviewApproval store={this.props.store} ref={this.previewApprovalRef} refreshDataGrid={this.refreshDataGrid}/>
            </div>
        )
    }
}

export default ApprovalHistory
