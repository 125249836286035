import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import { depreciationMethod, fixedAsset, months } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify';
import React, { Component } from 'react'
import uuidv4 from 'uuid/v4'
import ModalConfirmProsesPenyusutan from 'components/pages/modal/akutansi/fixedAssetDepreciation/confirm';
import ChooseProgramAset from 'components/pages/modal/akutansi/fixedAssetDepreciation/pilihProgram';
import { showLoading } from 'redux/actions/loading';
import { formatDate } from 'plugin/helper';
import { addURL } from 'redux/actions/url';
import { confirmAlert } from 'react-confirm-alert';
class FixedAssetDeprectiation extends Component {
    constructor(props) {
        super(props)

        this.prevTabIndex = 0
        this.programId = 0
        this.DataGridRef = React.createRef()
        this.modalRef = React.createRef()
        this.showModalChooseProgramRef = React.createRef()

        this.months = [
            {
                id: 1,
                value: 'Januari'
            },
            {
                id: 2,
                value: 'Februari'
            },
            {
                id: 3,
                value: 'Maret'
            },
            {
                id: 4,
                value: 'April'
            },
            {
                id: 5,
                value: 'Mei'
            },
            {
                id: 6,
                value: 'Juni'
            },
            {
                id: 7,
                value: 'Juli'
            },
            {
                id: 8,
                value: 'Agustus'
            },
            {
                id: 9,
                value: 'September'
            },
            {
                id: 10,
                value: 'Oktober'
            },
            {
                id: 11,
                value: 'November'
            },
            {
                id: 12,
                value: 'Desember'
            },
        ]
        // this.columns = [
        //     {
        //         type: 'buttons',
        //         width: 100,
        //         buttons: [
        //             {
        //                 text: 'Susutkan',
        //                 hint: 'Susutkan',
        //                 cssClass: 'text-success',
        //                 onClick: (e) => {
        //                     this.process(e.row.data)
        //                 }
        //             },
        //         ]
        //     },
        //     {
        //         dataField: 'fixedAssetCode',
        //         caption: 'Kode Aset Tetap',
        //     },
        //     {
        //         dataField: 'fixedAssetName',
        //         caption: 'Aset Tetap',
        //     },
        //     {
        //         dataField: 'fixedAssetDesc',
        //         caption: 'Deskripsi',
        //     },
        //     {
        //         dataField: 'calcDate',
        //         caption: 'Disusutkan Setiap Tanggal',
        //         calculateCellValue: (rowData) => {
        //             var date = new Date(rowData.calcDate)
        //             var day = date.getDate()
        //             return day
        //         }
        //     },
        //     {
        //         dataField: 'depreciationMethod',
        //         caption: 'Metode Depresiasi',
        //         lookup: {
        //             dataSource: depreciationMethod(),
        //             valueExpr: 'id',
        //             displayExpr: 'value',
        //             searchEnabled: true,
        //             deferRendering: false,
        //         }
        //     },
        //     {
        //         dataField: 'bookValue',
        //         caption: 'Nilai Buku',
        //         format: '#,##0.00'
        //     },
        //     {
        //         dataField: 'accumDepreciation',
        //         caption: 'Nilai Akum. Penyusutan',
        //         format: '#,##0.00'
        //     },
        //     {
        //         dataField: 'depreciationAmount',
        //         caption: 'Nilai Penyusutan',
        //         format: '#,##0.00',
        //         calculateCellValue: (rowData) => {
        //             return (rowData.baseAmount - rowData.salvage) / rowData.economicLife
        //         }
        //     },
        // ]

        this.columns = [
            {
                dataField: 'assetId',
                caption: 'Aset Tetap',
                lookup: {
                    dataSource: fixedAsset(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.assetKode + ' - ' + item.assetName
                    }, // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                sortOrder: 'desc'
            },
            {
                dataField: 'bulan',
                caption: 'Periode Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Periode Tahun',
            },
            {
                dataField: 'nominalAmortisasi',
                caption: 'Nilai Penyusutan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
        ]

        this.columnDepreciations = [
            {
                dataField: 'assetId',
                caption: 'Aset Tetap',
                lookup: {
                    dataSource: fixedAsset(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.assetKode + ' - ' + item.assetName
                    }, // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                sortOrder: 'desc'
            },
            {
                dataField: 'bulan',
                caption: 'Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Tahun',
            },
            {
                dataField: 'tanggalProses',
                caption: 'Tanggal Penyusutan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'nominalAmortisasi',
                caption: 'Nilai Penyusutan',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nilaiBukuSesudah',
                caption: 'Nilai Buku',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Aset Tetap yang terlambat Disusutkan'
            },
            {
                id: 1,
                text: 'Daftar Transaksi Penyusutan Aset Tetap'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    getProgramId = (data) => {
        this.generate(data)
    }

    loadData = async () => {
        var systemDate = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters/get-by-paramCode/0 SYSDATE')
        systemDate = new Date(systemDate.dateValue)

        const systemYear = systemDate.getFullYear()
        const systemMonth = systemDate.getMonth() + 1
        // const systemDay = systemDate.getDate()
        // const systemEndMonthDate = new Date(systemYear, systemMonth + 1, 0);
        // const systemEndMonthDay = systemEndMonthDate.getDate()

        var dataFixedAsset = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/getByMonthLessThanAndYear/${systemMonth}/${systemYear}`)
        // dataFixedAsset = dataFixedAsset.filter(value => {
        //     if(value.status === 'D'){
        //         return false
        //     }
        //     const depDate = new Date(value.calcDate)
        //     const depDay = depDate.getDate()

        //     const lastDepDate = new Date(value.lastDepDate)
        //     if(lastDepDate.getTime() === systemDate.getTime()){
        //         return false
        //     }

        //     if(depDay === systemDay || (depDay > systemEndMonthDay && systemDay === systemEndMonthDay)){
        //         return true
        //     }

        //     return false
        // })
        dataFixedAsset = dataFixedAsset.filter(value => value.tanggalProses === null)

        return dataFixedAsset
    }

    loadJadwal = async () => {
        var dataJadwal = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals?size=9999`, 'GET')
        dataJadwal = dataJadwal.filter(value => value.tanggalProses !== null)
        return dataJadwal
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    text: 'Generate Jurnal',
                    onClick: (e) => {
                        this.showModalChooseProgram()
                    },
                }
            }
        )
    }

    showModalChooseProgram = () => {
        this.showModalChooseProgramRef.current.show()
    }

    generate = async (programId) => {
        confirmAlert({
            title: 'Konfirmasi',
            message: 'Apakah anda yakin ingin generate jurnal penyusutan aset tetap?',
            buttons: [
                {
                    label: 'Ya',
                    onClick: async (e) => {
                        var sysDate = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/0 SYSDATE`, 'GET')
                        this.props.store.dispatch(showLoading(true))
                        let bulan = new Date(sysDate.dateValue).getMonth() + 1
                        let tahun = new Date(sysDate.dateValue).getFullYear()
                        const dataByMonthAndYear = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/get-by-month-year/${bulan}/${tahun}`, 'GET')
                        if (dataByMonthAndYear.length > 0) {
                            if (dataByMonthAndYear[0].tanggalProses !== null) {
                                notify({ message: 'Tidak ada data yang bisa di generate!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 2000);
                                this.props.store.dispatch(showLoading(false))
                            } else {
                                var monthMin1 = bulan == 1 ? 12 : bulan - 1
                                var yearMin1 = bulan == 12 ? tahun - 1 : tahun
                                var dataByMonthAndYearMin1 = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/get-by-month-year/${monthMin1}/${yearMin1}`, 'GET')
                                dataByMonthAndYearMin1 = dataByMonthAndYearMin1.filter(value => value.tanggalProses == null)
                                if (dataByMonthAndYearMin1.length > 0) {
                                    try {
                                        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/generate-jurnal-penyusutan/${programId}/${yearMin1}/${monthMin1}`, 'POST', { values: {} })
                                        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/generate-jurnal-penyusutan/${programId}/${tahun}/${bulan}`, 'POST', { values: {} })
                                        var data = [
                                            ...dataByMonthAndYear,
                                            ...dataByMonthAndYearMin1,
                                        ]
                                        if (response.length > 0) {
                                            for (var data of dataByMonthAndYear) {
                                                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals`, 'PUT', {
                                                    values: {
                                                        tanggalProses: formatDate(sysDate.dateValue)
                                                    }
                                                }, data)
                                            }
                                            this.props.store.dispatch(showLoading(false))
                                            notify({ message: `Generate Jurnal bulan ${this.months.find(value => value.id === bulan).value} dan bulan ${this.months.find(value => value.id === monthMin1).value} berhasil!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 2000);
                                            this.forceRefresh()
                                        } else {
                                            this.props.store.dispatch(showLoading(false))
                                            notify({ message: 'Tidak ada jurnal yang berhasil di generate!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 2000);
                                        }
                                    } catch (e) {
                                        notify({ message: 'Gagal generate jurnal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 2000);
                                        this.props.store.dispatch(showLoading(false))
                                        console.log(e)
                                    }
                                } else {
                                    try {
                                        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/generate-jurnal-penyusutan/${programId}/${tahun}/${bulan}`, 'POST', { values: {} })
                                        if (response.length > 0) {
                                            for (var data of dataByMonthAndYear) {
                                                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals`, 'PUT', {
                                                    values: {
                                                        tanggalProses: formatDate(sysDate.dateValue)
                                                    }
                                                }, data)
                                            }
                                            this.props.store.dispatch(showLoading(false))
                                            notify({ message: `Generate Jurnal bulan ${this.months.find(value => value.id === bulan).value} berhasil!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 2000);
                                            this.forceRefresh()
                                        } else {
                                            this.props.store.dispatch(showLoading(false))
                                            notify({ message: 'Tidak ada jurnal yang berhasil di generate!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 2000);
                                        }
                                    } catch (e) {
                                        notify({ message: 'Gagal generate jurnal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 2000);
                                        this.props.store.dispatch(showLoading(false))
                                        console.log(e)
                                    }
                                }
                            }
                        } else {
                            notify({ message: 'Data tidak ditemukan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 2000);
                            this.props.store.dispatch(showLoading(false))
                        }
                    }
                },
                {
                    label: 'Batal',
                },
            ]
        })


    }

    process = async (data) => {
        var systemDate = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters/get-by-paramCode/0 SYSDATE')

        var dataPostDep = {
            cumulativeDepreciation: data.accumDepreciation,
            depreciableValue: data.baseAmount - data.accumDepreciation,
            depreciation: (data.baseAmount - data.salvage) / data.economicLife,
            depreciationCode: uuidv4().slice(0, 20),
            depreciationDate: systemDate.dateValue,
            fixedAssetId: data.id,
            postedJournalId: 0,
            reversedJournalId: 0,
            status: "O"
        }

        try {

            var responseDepreciation = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'fixed-asset-depreciations', 'POST', {
                values: dataPostDep
            })

            var listJournalType = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-types')
            var journalTypePenyusutan = listJournalType.find(value => value.journalTypeCode === '200')

            var listCcy = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'currencies')
            var ccyIDR = listCcy.find(value => value.currencyCode === 'IDR')
            var dataJurnal = [
                {
                    "coaId": data.depreciationCoaId,
                    "currencyId": ccyIDR.id,
                    "dealExrate": 1,
                    "amount": (data.baseAmount - data.salvage) / data.economicLife,
                    "departmentId": data.departmentId,
                    "journaTypeId": journalTypePenyusutan.id,
                    "journalDesc": "Penyusutan Aset " + data.fixedAssetCode,
                    "reffCode": "FDEP",
                    "reffId": responseDepreciation.id,
                    "valueDate": systemDate.dateValue
                },
                {
                    "coaId": data.accumDepreciationCoaId,
                    "amount": -((data.baseAmount - data.salvage) / data.economicLife),
                    "currencyId": ccyIDR.id,
                    "dealExrate": 1,
                    "departmentId": data.departmentId,
                    "journaTypeId": journalTypePenyusutan.id,
                    "journalDesc": "Penyusutan Aset " + data.fixedAssetCode,
                    "reffCode": "FDEP",
                    "reffId": responseDepreciation.id,
                    "valueDate": systemDate.dateValue
                },
            ]

            var responseJurnal = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'accounting-report/import', 'POST', {
                values: dataJurnal
            })

            for (var jurnal of responseJurnal) {
                var responsePostingJurnal = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/post/${jurnal.id}`)

                var responseDepreciation = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'fixed-asset-depreciations', 'PUT', {
                    values: {
                        postedJournalId: jurnal.id,
                        status: "S"
                    }
                }, responseDepreciation)

            }

            var responseDepreciation = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'fixed-assets', 'PUT', {
                values: {
                    accumDepreciation: data.accumDepreciation + dataPostDep.depreciation,
                    accumDepreciationYtd: data.accumDepreciationYtd + dataPostDep.depreciation,
                    bookValue: data.bookValue - dataPostDep.depreciation,
                    lastDepDate: systemDate.dateValue
                }
            }, data)

            var dataFixedAssetMutation = {
                "amount": dataPostDep.depreciation,
                "description": "Penyusutan",
                "fixedAssetId": data.id,
                "reffId": responseDepreciation.id,
                "reffType": "FDEP",
                "valueDate": systemDate.dateValue
            }

            var responseFixedAssetMutation = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'fixed-asset-mutations', 'POST', {
                values: dataFixedAssetMutation
            })

            notify({ message: 'Aset Berhasil Disusutkan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    forceRefresh = () => {
        this.DataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title font-weight-bold">Aset</h2>
                <h3 className="sub-title">Penyusutan Aset Tetap</h3>
                {/* <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                /> */}
                <DevExpressDataGrid
                    ref={this.DataGridRef}
                    loadAPI='fixed-assets'
                    insertAPI='fixed-assets'
                    updateAPI='fixed-assets'
                    deleteAPI='fixed-assets'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Penyusutan Aset tetap"}
                    allowExportSelectedData={true}
                    selection={'multiple'}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'nominalAmortisasi'
                        ]
                    }

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aset Tetap'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                    // onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 260px)'}

                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                {/* <label className='text-danger' style={{ fontSize: '12px' }}>* Data yang ditampilkan hanya data yang belum di generate dan sudah melewati periode penyusutan.</label> */}
                {/* <DevExpressDataGrid
                    ref={this.DataGridRef}
                    loadAPI='-'
                    insertAPI='asset-amortisasi-jadwals'
                    updateAPI='asset-amortisasi-jadwals'
                    deleteAPI='asset-amortisasi-jadwals'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadJadwal}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Penyusutan Aset tetap"}
                    allowExportSelectedData={true}
                    selection={'multiple'}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aset Tetap'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columnDepreciations} // taruh konfigurasi kolom disini

                    height={'calc(100vh - 330px)'}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                /> */}
                <ModalConfirmProsesPenyusutan
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ChooseProgramAset
                    ref={this.showModalChooseProgramRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    getProgramId={this.getProgramId}
                />
            </div>
        )
    }
}

export default FixedAssetDeprectiation