import React,{Component} from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { confirmAlert } from 'react-confirm-alert';
import { kpds, product } from 'dataSource/lookup';
import {Form} from 'devextreme-react';
import { formatDate } from 'plugin/helper';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import ViewPengajuanPemindahanRek from 'components/pages/modal/investasi/settlement/pemindahan-porto/pemindahan-rek/formPengajuan';
import ViewInstrumentPemindahanRek from 'components/pages/modal/investasi/settlement/pemindahan-porto/pemindahan-rek/formInstrument';

class PemindahanPortoRekening extends Component {
    constructor(props){
        super(props)

        this.columnPengajuan = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Rekening',
                        hint: 'Rekening',
                        onClick: (e) => {
                            this.showModalInstrument(e)
                        }
                    },
                    {
                        text: 'Instruksi',
                        hint: 'Instruksi',
                        onClick: (e) => {
                            // this.showModalEditKonfirmasi(e)
                        }
                    },
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            // this.showModalEditKonfirmasi(e)
                        }
                    },
                    
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            // this.kirimKonfirmasi(e)
                        }
                    },
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            this.showModalEditPengajuan(e)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            // this.batalPenempatan(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField:'produkName',
                caption:'Produk',
            },
            {
                dataField:'sid',
                caption:'SID',
            },
            {
                dataField:'tglPengajuan',
                caption:'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'tglPemindahan',
                caption:'Tanggal Pemindahan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'tglTransfer',
                caption:'Tanggal Transfer',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'dari',
                caption:'Dari',
            },
            {
                dataField:'tujuan',
                caption:'Tujuan',
            },
            {
                dataField:'jmlNominal',
                caption:'Jumlah Nominal',
            },
            {
                dataField:'penandatangan',
                caption:'Penandatangan',
                columns:[
                    {
                        dataField:'pejabat1',
                        caption:'Pejabat 1',
                    },
                    {
                        dataField:'pejabat2',
                        caption:'Pejabat 2',
                    },
                ]
            },
            {
                dataField:'status',
                caption:'Status',
            },
        ]

        this.columnRiwayat = [
            {
                dataField:'produkName',
                caption:'Produk',
            },
            {
                dataField:'sid',
                caption:'SID',
            },
            {
                dataField:'tglPengajuan',
                caption:'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'tglPemindahan',
                caption:'Tanggal Pemindahan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'tglTransfer',
                caption:'Tanggal Transfer',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'dari',
                caption:'Dari',
            },
            {
                dataField:'tujuan',
                caption:'Tujuan',
            },
            {
                dataField:'jmlNominal',
                caption:'Jumlah Nominal',
            },
            {
                dataField:'penandatangan',
                caption:'Penandatangan',
                columns:[
                    {
                        dataField:'pejabat1',
                        caption:'Pejabat 1',
                    },
                    {
                        dataField:'pejabat2',
                        caption:'Pejabat 2',
                    },
                ]
            },
            {
                dataField:'status',
                caption:'Status',
            },
        ]

        this.TabDataSource = [
            {
                id: 1,
                text: 'Pengajuan',
            },
            {
                id: 2,
                text: 'Riwayat'
            },
        ]
        
        this.showModalAddPengajuanRef = React.createRef()
        this.showModalEditPengajuanRef = React.createRef()
        this.showModaInstrumentRef = React.createRef()
        this.dataGridPengajuanRef = React.createRef()
        this.dataGridRiwayatRef = React.createRef()
        this.prevTabIndex = 0
        
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Pengajuan',
                    onClick: (e) => {
                        this.showModalAddPengajuan(e)
                    },
                }
            }
        )
    }
    
    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    forceRefresh = () => {
        this.dataGridPengajuanRef.current.forceRefresh(true)
    }

    showModalAddPengajuan = (e) => {
        this.showModalAddPengajuanRef.current.show()
    }

    showModalEditPengajuan = (e) => {
        if (e.row) {
            this.showModalEditPengajuanRef.current.retrieveData(e.row.data)
        }
        this.showModalEditPengajuanRef.current.show()
    }

    showModalInstrument = (e) => {
        if (e.row) {
            this.showModaInstrumentRef.current.retrieveData(e.row.data)
        }
        this.showModaInstrumentRef.current.show()
    }

    render(){
        return(
            <div className="container-fluid">
            <h2 className="main-title">Pengajuan Pemindahan Rekening</h2>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0" className={' col-md-12'}>
                    <div className="row">
                        <div className="col-md-12">
                        <DevExpressDataGrid
                                ref = {this.dataGridPengajuanRef}
                                loadAPI='pemindahan-rekenings/pemindahan-rekening-pengajuan'
                                // loadAPI='kpds'
                                insertAPI='kpds'
                                updateAPI='kpds'
                                deleteAPI='kpds' 

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Konfirmasi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={'calc(100vh - 380px)'}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Instrument'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnPengajuan} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                <div id="tabindex-1" className={'d-none col-md-12'}>
                    <div className="row">
                        <div className="col-md-12">
                        <DevExpressDataGrid
                                ref = {this.dataGridRiwayatRef}
                                loadAPI='kpds'
                                updateAPI='kpds'
                                deleteAPI='kpds' 

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                useArraySource = {true}
                                ArraySourceData = {this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Konfirmasi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={'calc(100vh - 380px)'}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Instrument'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnRiwayat} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                // onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                <ViewPengajuanPemindahanRek
                    ref={this.showModalAddPengajuanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'add'}
                />
                <ViewPengajuanPemindahanRek
                    ref={this.showModalEditPengajuanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'edit'}
                />
                <ViewInstrumentPemindahanRek 
                    ref={this.showModaInstrumentRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PemindahanPortoRekening