import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import BiayaPenugasanPerjalananDinas from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/penugasan/biaya';
import FormPerjalananDinas from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/perjalanan-dinas/form';
import TiketPerjalananDinas from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/perjalanan-dinas/tiket';
import ModalBiayaPerjalananDinas from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/perjalanan-dinas/biaya';
import { cities, jabatan, karyawan, months, sdmUnit, sppdType, unit } from 'dataSource/lookup';
import Form from 'devextreme-react/form';
import Excel from 'exceljs/dist/exceljs';
import httpRequest from 'plugin/httprequest';
import { formatDefaultDate, formatNumber, formatNumberAum } from 'plugin/helper';
import { saveAs } from 'file-saver';
import { confirm, alert } from 'devextreme/ui/dialog';
import TiketHotel from 'components/pages/modal/sdm-umum/kepegawaian/perjalanan-dinas/penugasan/tiketHotel';
import { numberToIndo } from 'plugin/helper';

class KepegawaianPerjalananDinas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {}
        }
        this.modalEditRef = React.createRef()
        this.modalBayarRef = React.createRef()
        this.modalTiketRef = React.createRef()
        this.modalFileRef = React.createRef()
        this.modalTiketHotelRef = React.createRef();
        this.DataGridPengajuanRef = React.createRef()
        this.filterData = []
        this.formMaster = [
            {
                dataField: "bulan",
                label: {
                    text: "Bulan",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true, // enables
                }
            },
            {
                dataField: "tahun",
                label: {
                    text: "Tahun",
                    alignment: "left",
                    location: "left",
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.penugasanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            // alert('Under development', 'Pemberitahuan')
                            this.printData(e.row.data);
                        }
                    },
                    {
                        text: 'Tiket',
                        hint: 'Tiket',
                        onClick: (e) => {
                            this.showModalTiket(e.row.data);
                        }
                    },
                    {
                        text: 'Penginapan',
                        hint: 'Penginapan',
                        onClick: (e) => {
                            this.showModalTiketHotel(e.row.data);
                        }
                    },
                    {
                        text: 'Biaya',
                        hint: 'Biaya',
                        onClick: (e) => {
                            this.showModalBiaya(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'name',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'jabatan',
                caption: 'Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName'
                }
            },
            {
                dataField: 'unit',
                caption: 'Unit',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                }
            },
            {
                dataField: 'perjalananDinas',
                caption: 'Perjalanan Dinas',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'penugasan',
                        caption: 'No. Penugasan'
                    },
                    {
                        dataField: 'perjalanan',
                        caption: 'Jenis Perjalanan',
                        lookup: {
                            dataSource: sppdType(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'sppdName', // provides display values
                        }
                    },
                    {
                        dataField: 'kota',
                        caption: 'Kota',
                        lookup: {
                            dataSource: cities(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'cityName', // provides display values
                        }
                    },
                    {
                        dataField: 'hari',
                        caption: 'Hari'
                    },
                    {
                        dataField: 'tanggalAwal',
                        caption: 'Tanggal Awal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tanggalAkhir',
                        caption: 'Tanggal Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'supervisor',
                caption: 'Supervisor'
            }
        ]
    }

    componentDidMount = async () => {
        this.defaultData()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    defaultData = async () => {
        var datas = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-list-perjalanan-dinas`,
            'GET'
        )
        this.filterData = datas
        if (datas) {
            this.forceRefresh()
        }
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    showModalTiketHotel = (data) => {
        this.modalTiketHotelRef.current.show()
        this.modalTiketHotelRef.current.retrieveData(data)
    }

    showModalEdit = (data) => {
        this.modalEditRef.current.retrieveData(data)
        this.modalEditRef.current.show()
    }

    showModalTiket = (data) => {
        this.modalTiketRef.current.show()
        this.modalTiketRef.current.retrieveData(data)
    }

    showModalBiaya = (data) => {
        this.modalBayarRef.current.show()
        this.modalBayarRef.current.retrieveData(data)
    }

    printData = async (data) => {
        try {
            let globalParamRes = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters');

            let findParamNpj = globalParamRes.find(val => val.paramCode === '1 NPJ').stringValue;
            let findParamJpj = globalParamRes.find(val => val.paramCode === '1 JPJ').stringValue;

            let responseSppd = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `laporan-sppd/${data.id}/${findParamNpj}/${findParamJpj}`);

            const res = await fetch('/file_upload/Laporan sppd.xlsx');
            const blob = await res.blob();

            const dataArrayBuffer = await blob.arrayBuffer();

            const workbook = new Excel.Workbook();
            await workbook.xlsx.load(dataArrayBuffer);

            const workSheetNames = [
                'SPPD',
                'lamp',
                'Rincian'
            ];

            for (const workSheetName of workSheetNames) {
                let workSheet = workbook.getWorksheet(workSheetName);
                switch(workSheetName) {
                    case 'SPPD' :
                        let sppdTypes = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `sppd-types/${data.perjalanan}`, 'GET');
                        workSheet.getCell('D10').value = responseSppd[0].sppd_d10;
                        workSheet.getCell('D11').value = responseSppd[0].sppd_d11;
                        workSheet.getCell('E12').value = responseSppd[0].sppd_e12;
                        workSheet.getCell('E13').value = responseSppd[0].sppd_e13;
                        workSheet.getCell('E14').value = responseSppd[0].sppd_e14;
                        // workSheet.getCell('D15').value = responseSppd[0].sppd_d15;
                        workSheet.getCell('D16').value = responseSppd[0].sppd_d16;
                        workSheet.getCell('E18').value = responseSppd[0].sppd_e18;
                        workSheet.getCell('E19').value = responseSppd[0].sppd_e19;
                        workSheet.getCell('E20').value = responseSppd[0].sppd_e20;
                        workSheet.getCell('E21').value = responseSppd[0].sppd_e21;
                        workSheet.getCell('E22').value = responseSppd[0].sppd_e22;
                        workSheet.getCell('E23').value = responseSppd[0].sppd_e23;
                        workSheet.getCell('E24').value = responseSppd[0].sppd_e24;
                        workSheet.getCell('E25').value = responseSppd[0].sppd_e25;
                        workSheet.getCell('E26').value = responseSppd[0].sppd_e26;
                        workSheet.getCell('D27').value = responseSppd[0].sppd_d15;
                        workSheet.getCell('D31').value = responseSppd[0].sppd_d31;
                        workSheet.getCell('D32').value = responseSppd[0].sppd_d32;
                        workSheet.getCell('D39').value = responseSppd[0].sppd_d39;
                        workSheet.getCell('D40').value = responseSppd[0].sppd_d40;
                        workSheet.getCell('D15').value = sppdTypes.sppdName;
                        workSheet.getCell('C29').value = 'Terbilang : ' + numberToIndo(responseSppd[0].sppd_e26);
                    break;
                    case 'lamp' : let dataLamp = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `laporan-sppd-lamp/${data.id}/${findParamNpj}/${findParamJpj}`);
                        for(let i = 0; i < dataLamp.length; i++){
                            // workSheet.getCell(`A${dataLamp[i].baris}`).value = dataLamp[i].kolom_a;
                            workSheet.getCell(`B${dataLamp[i].baris}`).value = dataLamp[i].kolom_b;
                            workSheet.getCell(`C${dataLamp[i].baris}`).value = dataLamp[i].kolom_c;
                            workSheet.getCell(`D${dataLamp[i].baris}`).value = dataLamp[i].kolom_d;
                            workSheet.getCell(`E${dataLamp[i].baris}`).value = dataLamp[i].kolom_e;
                            workSheet.getCell(`F${dataLamp[i].baris}`).value = dataLamp[i].kolom_f;
                            workSheet.getCell(`G${dataLamp[i].baris}`).value = dataLamp[i].kolom_g;
                            workSheet.getCell(`H${dataLamp[i].baris}`).value = dataLamp[i].kolom_h;
                            workSheet.getCell(`I${dataLamp[i].baris}`).value = dataLamp[i].kolom_i;
                            workSheet.getCell(`J${dataLamp[i].baris}`).value = dataLamp[i].kolom_j;
                            workSheet.getCell(`K${dataLamp[i].baris}`).value = dataLamp[i].kolom_k;
                            workSheet.getCell(`L${dataLamp[i].baris}`).value = dataLamp[i].kolom_l ? formatNumber(dataLamp[i].kolom_l) : '';
                            workSheet.getCell(`M${dataLamp[i].baris}`).value = dataLamp[i].kolom_m;
                            workSheet.getCell(`N${dataLamp[i].baris}`).value = dataLamp[i].kolom_n;
                            workSheet.getCell(`O${dataLamp[i].baris}`).value = dataLamp[i].kolom_o;
                            workSheet.getCell(`P${dataLamp[i].baris}`).value = dataLamp[i].kolom_p;
                            workSheet.getCell(`Q${dataLamp[i].baris}`).value = dataLamp[i].kolom_q ? formatNumber(dataLamp[i].kolom_q) : '';
                        }

                        // workSheet.getRow(14).hidden = true;

                        if (dataLamp.length <= 7) {
                            workSheet.mergeCells('K12:N12');
                            workSheet.mergeCells('K13:N13');
                            workSheet.mergeCells('K14:N14');
                            workSheet.mergeCells('K15:N15');

                            workSheet.mergeCells('K20:N20');
                            workSheet.mergeCells('K21:N21');
                            workSheet.getCell('K20').font = { underline: true}
                        } else if (dataLamp.length <= 9) {
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.mergeCells('L14:O14');
                            workSheet.getCell('L14').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('K18:N18');
                            workSheet.mergeCells('K19:N19');
                            workSheet.mergeCells('K20:N20');
                            workSheet.mergeCells('K21:N21');
                            workSheet.mergeCells('K26:N26');
                            workSheet.mergeCells('K27:N27');
                        } else if (dataLamp.length <= 12){
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.mergeCells('L14:O14');
                            workSheet.getCell('L14').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('K18:N18');
                            workSheet.mergeCells('K19:N19');
                            workSheet.mergeCells('K20:N20');
                            workSheet.mergeCells('K21:N21');
                            workSheet.mergeCells('K26:N26');
                            workSheet.mergeCells('K27:N27');
                        }else if (dataLamp.length <= 14) {
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10'); 
                            workSheet.getRow(20).hidden = true;
                            workSheet.mergeCells('L16:O16');
                            workSheet.getCell('L16').alignment = { vertical: 'middle', horizontal: 'right' };
                            workSheet.mergeCells('K21:N21');
                            workSheet.mergeCells('K22:N22');
                            workSheet.mergeCells('K23:N23');
                            workSheet.mergeCells('K24:N24');
                            workSheet.mergeCells('K28:N28');
                            workSheet.getCell('K28').font = { underline: true }
                            workSheet.mergeCells('K29:N29');
                        } else if (dataLamp.length <= 15) {
                            // workSheet.getRow(16).hidden = true;
                            // workSheet.getRow(17).hidden = true;
                            workSheet.getRow(20).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L18:O18');
                            workSheet.getCell('L17').alignment = { vertical: 'middle', horizontal: 'right' };
                            workSheet.getCell('L18').alignment = { vertical: 'middle', horizontal: 'right' };
                            workSheet.mergeCells('K27:O27');

                            workSheet.mergeCells('K22:N22');
                            workSheet.mergeCells('K23:N23');
                            workSheet.mergeCells('K24:N24');
                            workSheet.mergeCells('K25:N25');
                            workSheet.mergeCells('K30:N30');
                            workSheet.mergeCells('K31:N31');
                            // workSheet.mergeCells('K39:N39');
                            // workSheet.mergeCells('K40:N40');
                            // workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 18) {
                            // workSheet.getCell('B18').font = { bold: true };
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.mergeCells('B18:D18');
                            workSheet.mergeCells('C19:D19'); 
                            workSheet.mergeCells('L25:O25');
                            // workSheet.getCell('L25').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.getRow(12).hidden = true;
                            workSheet.getRow(14).hidden = true;
                            workSheet.getRow(15).hidden = true;
                            workSheet.getRow(22).hidden = true;
                            workSheet.getRow(23).hidden = true;

                            workSheet.getCell('K37').font = { underline: true}

                            workSheet.mergeCells('K29:N29');
                            workSheet.mergeCells('K30:N30');
                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K37:N37');
                            // workSheet.getCell('L37').font = { underline: true }
                            workSheet.mergeCells('K38:N38');
                        } else if (dataLamp.length <= 19) {
                            // workSheet.getRow(16).hidden = true;
                            // workSheet.getRow(17).hidden = true;
                            workSheet.getRow(13).hidden = true;
                            workSheet.getRow(23).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B19:D19');
                            workSheet.getCell('C19').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('C20:D20');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');
                            workSheet.mergeCells('L26:O26');
                            // workSheet.getCell('L26').alignment = { vertical: 'middle', horizontal: 'center' };

                            workSheet.getCell('K38').font = { underline: true}
                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K38:N38');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 20) {
                            workSheet.getRow(16).hidden = true;
                            workSheet.getRow(17).hidden = true;
                            workSheet.getRow(24).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');
                            workSheet.mergeCells('L27:O27');

                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 21) {
                            // workSheet.getRow(14).hidden = true;
                            // workSheet.getRow(25).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            // workSheet.mergeCells('C22:D22');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');

                            workSheet.getCell('K40').font = { underline: true}
                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 22) {
                            // workSheet.getRow(16).hidden = true;
                            // workSheet.getRow(17).hidden = true;
                            workSheet.getRow(25).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('L28:O28');

                            workSheet.mergeCells('K31:N31');
                            workSheet.mergeCells('K32:N32');
                            workSheet.mergeCells('K33:N33');
                            workSheet.mergeCells('K34:N34');
                            workSheet.mergeCells('K35:N35');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                        } else if (dataLamp.length <= 24) {
                            workSheet.getRow(14).hidden = true;
                            workSheet.getRow(16).hidden = true;
                            workSheet.getRow(17).hidden = true;
                            workSheet.getRow(24).hidden = true;
                            workSheet.getRow(26).hidden = true;
                            workSheet.getRow(27).hidden = true;
                            workSheet.mergeCells('B9:D9'); 
                            workSheet.mergeCells('C10:D10');
                            workSheet.getCell('C10').alignment = { vertical: 'middle', horizontal: 'left' };
                            workSheet.mergeCells('B20:D20');
                            workSheet.mergeCells('C21:D21');
                            workSheet.getCell('C21').alignment = { vertical: 'middle', horizontal: 'left' };
                            // workSheet.mergeCells('L28:O28');
                            // workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('L34:P34');

                            workSheet.getCell('K46').font = { underline: true}
                            workSheet.mergeCells('B30:C30');
                            workSheet.mergeCells('C31:D31');

                            workSheet.mergeCells('K38:N38');
                            workSheet.mergeCells('K39:N39');
                            workSheet.mergeCells('K40:N40');
                            workSheet.mergeCells('K41:N41');
                            workSheet.mergeCells('K46:N46');
                            workSheet.mergeCells('K47:N47');
                        }
                    break;
                    case 'Rincian' : let dataRincian = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `sppd-get-rincian?oprDir=${findParamJpj}&oprJbt=${findParamNpj}&sppdId=${data.id}`, 'GET');
                        for (let i = 0; i < dataRincian.length; i++) {
                            // workSheet.getCell(`A${dataRincian[i].baris}`).value = dataRincian[i].kolom_a ? formatNumber(dataRincian[i].kolom_a) : '';
                            workSheet.getCell(`B${dataRincian[i].baris}`).value = dataRincian[i].kolom_b ? formatNumber(dataRincian[i].kolom_b) : '';
                            workSheet.getCell(`C${dataRincian[i].baris}`).value = dataRincian[i].kolom_c ? formatNumber(dataRincian[i].kolom_c) : '';
                            workSheet.getCell(`D${dataRincian[i].baris}`).value = dataRincian[i].kolom_d ? formatNumber(dataRincian[i].kolom_d) : '';
                            workSheet.getCell(`E${dataRincian[i].baris}`).value = dataRincian[i].kolom_e ? formatNumber(dataRincian[i].kolom_e) : '';
                            workSheet.getCell(`F${dataRincian[i].baris}`).value = dataRincian[i].kolom_f ? formatNumber(dataRincian[i].kolom_f) : '';
                            workSheet.getCell(`G${dataRincian[i].baris}`).value = dataRincian[i].kolom_g ? formatNumber(dataRincian[i].kolom_g) : '';
                            workSheet.getCell(`H${dataRincian[i].baris}`).value = dataRincian[i].kolom_h ? formatNumber(dataRincian[i].kolom_h) : '';
                            workSheet.getCell(`I${dataRincian[i].baris}`).value = dataRincian[i].kolom_i ? formatNumber(dataRincian[i].kolom_i) : '';
                            workSheet.getCell(`J${dataRincian[i].baris}`).value = dataRincian[i].kolom_j ? formatNumber(dataRincian[i].kolom_j) : '';
                            workSheet.getCell(`K${dataRincian[i].baris}`).value = dataRincian[i].kolom_k ? formatNumber(dataRincian[i].kolom_k) : '';
                            workSheet.getCell(`L${dataRincian[i].baris}`).value = dataRincian[i].kolom_l ? formatNumber(dataRincian[i].kolom_l) : '';
                            workSheet.getCell(`M${dataRincian[i].baris}`).value = dataRincian[i].kolom_m ? formatNumber(dataRincian[i].kolom_m) : '';
                            workSheet.getCell(`N${dataRincian[i].baris}`).value = dataRincian[i].kolom_n ? formatNumber(dataRincian[i].kolom_n) : '';
                            if (dataRincian.length === 6) {
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D20').value = '';
                                workSheet.getCell('D21').value = '';
                                workSheet.getCell('D22').value = '';
                                workSheet.getCell('D23').value = '';
                                workSheet.getCell('K20').value = '';
                                workSheet.getCell('K21').value = '';
                                workSheet.getCell('K22').value = '';
                                workSheet.getCell('K23').value = '';

                                workSheet.getCell('D26').value = dataRincian[4].kolom_d;
                                workSheet.getCell('D25').value = dataRincian[5].kolom_d

                                workSheet.getCell('K25').value = dataRincian[4].kolom_k;
                                workSheet.getCell('K26').value = dataRincian[5].kolom_k;
                            } else if (dataRincian.length === 9) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D23').value = dataRincian[8].kolom_d;
                                workSheet.getCell('D24').value = dataRincian[7].kolom_d;

                                workSheet.getCell('K24').value = dataRincian[8].kolom_k;
                                workSheet.getCell('K23').value = dataRincian[7].kolom_k;
                                workSheet.getCell('M15').value = dataRincian[6].kolom_m ? formatNumber(dataRincian[6].kolom_m) : '';
                            }else if (dataRincian.length === 10) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D24').value = dataRincian[i].kolom_d;
                                workSheet.getCell('D25').value = dataRincian[8].kolom_d;

                                workSheet.getCell('D24').font = { underline: true }
                                workSheet.getCell('K24').font = { underline: true }

                                workSheet.getCell('D25').font = { underline: false }
                                workSheet.getCell('K25').font = { underline: false }

                                workSheet.getCell('K25').value = dataRincian[i].kolom_k;
                                workSheet.getCell('K24').value = dataRincian[8].kolom_k;
                                workSheet.getCell('M15').value = dataRincian[7].kolom_m ? formatNumber(dataRincian[7].kolom_m) : '';
                                // workSheet.mergeCells('K24:N24');                                                 
                                // workSheet.mergeCells('K23:N23');
                                // workSheet.mergeCells('K24:N24');
                            } else if (dataRincian.length >= 13){
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D27').value = dataRincian[12].kolom_d;
                                workSheet.getCell('D28').value = dataRincian[11].kolom_d;

                                workSheet.getCell('K28').value = dataRincian[12].kolom_k;
                                workSheet.getCell('K27').value = dataRincian[11].kolom_k;
                                // workSheet.mergeCells('K23:N23');
                                // workSheet.mergeCells('K24:N24');
                            } else if (dataRincian.length >= 12) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;      
                                workSheet.getCell('K27').value = dataRincian[11].kolom_k;
                                workSheet.getCell('K26').value = dataRincian[10].kolom_k;
                                workSheet.getCell('M15').value = dataRincian[9].kolom_m ? formatNumber(dataRincian[9].kolom_m) : '';
                                // workSheet.getCell('K24').value = dataRincian[i].kolom_k;                                                 
                                // workSheet.mergeCells('K23:N23');
                                // workSheet.mergeCells('K24:N24');
                            } else if (dataRincian.length >= 11) {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                                workSheet.getCell('D25').value = dataRincian[9].kolom_d;
                                workSheet.getCell('D26').value = dataRincian[10].kolom_d;

                                workSheet.getCell('K25').value = dataRincian[9].kolom_k;
                                workSheet.getCell('K26').value = dataRincian[10].kolom_k; 
                                workSheet.getCell('M15').value = dataRincian[8].kolom_m ? formatNumber(dataRincian[8].kolom_m) : '';  
                            } else {
                                workSheet.getCell('A2').value = dataRincian[i].kolom_a;
                                workSheet.getCell('B6').value = dataRincian[1].kolom_b;
                            }
                        }

                        workSheet.getColumn(14).width = 17;

                        workSheet.mergeCells('K25:N25');
                        workSheet.mergeCells('K26:N26');
                        workSheet.mergeCells('K27:N27'); 
                    break;
                }
            }

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_SPPD.xlsx`
                )
            })

        } catch (error) {
            console.log(error)
        }
    }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-list-perjalanan-dinas`,
            'GET'
        )

        var listData = []
        for (var data of datas) {
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => value.bulan == state.bulan && value.tahun == state.tahun)
        } else if (state.bulan) {
            this.filterData = listData.filter(value => value.bulan == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.tahun == state.tahun)
        } else {
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan && value.tanggalPengajuan.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()

    }

    loadData = () => {
        return this.filterData
    }
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Perjalanan Dinas</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.DataGridPengajuanRef}
                    loadAPI='regist-pengkinian-pengajuan-aktifs'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Perjalanan Dinas"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.penugasanColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPerjalananDinas
                    ref={this.modalEditRef}
                    store={this.props.store}
                    action={'edit'}
                />
                <TiketPerjalananDinas
                    ref={this.modalTiketRef}
                    store={this.props.store}
                />
                <TiketHotel
                    ref={this.modalTiketHotelRef}
                    store={this.props.store}
                />
                <ModalBiayaPerjalananDinas
                    ref={this.modalBayarRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default KepegawaianPerjalananDinas