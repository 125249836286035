import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, banks, pejabat, custodies, broker, instrumentSaham, instrumentTransactionTypes, instruments, rekCustody, jabatan } from "dataSource/lookup";
import DataGridDetailInstruksiPenempatan from "./datagrid/penempatan";
import DataGridDetailInstruksiPelepasan from "./datagrid/pelepasan";
import DataGridDetailInstruksiPerpanjangan from "./datagrid/perpanjangan";
import Box from "devextreme-react/box";
import { Item } from "devextreme-react/accordion";
import { FileUploader } from "devextreme-react";
import { formatUploadFileData, imageSource } from "plugin/helper";
import { data } from "dataSource/json/auctionBuybackCash";
import DevExpressDataGrid from "components/inheritComponent/devexpressdatagrid";
import ModalPreviewPdf from "components/pages/modal/kepesertaan/compPeserta/previewPdf";
import ScrollView from "devextreme-react/scroll-view";
import ArrayStore from 'devextreme/data/array_store';

class FormInstruksiKustodiAktif extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            DataMaster: {}
        };

        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'id',
                        label: {
                            text: 'ID'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'custodyId',
                        label: {
                            text: 'Kustodi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'custodyName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekCustodyId',
                        label: {
                            text: 'Nomor Rekening'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekCustody(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.rekNo + ' - ' + e.rekName
                            },
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instruksiNo',
                        label: {
                            text: 'Nomor Instruksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instruksiDate',
                        label: {
                            text: 'Tanggal Instruksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'pejabat1',
                        label: {
                            text: 'Pejabat 1'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                            searchEnabled: true,
                            onValueChanged: async(e) => {
                                var val = e.value
                                if(val){
                                    let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${val}`, 'GET')
                                    this.formRef.current.instance.getEditor('jabatan1').option('value', response.jabatanId)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Pejabat 1 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'jabatan1',
                        label: {
                            text: 'Jabatan 1'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'jabatanName',
                            readOnly: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Jabatan 1 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'pejabat2',
                        label: {
                            text: 'Pejabat 2'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                            searchEnabled: true,
                            onValueChanged: async(e) => {
                                var val = e.value
                                if(val){
                                    let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${val}`, 'GET')
                                    this.formRef.current.instance.getEditor('jabatan2').option('value', response.jabatanId)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Pejabat 2 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'jabatan2',
                        label: {
                            text: 'Jabatan 2'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'jabatanName',
                            readOnly: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Jabatan 2 tidak boleh kosong!'
                            }
                        ]
                    },
                    {
                        dataField: 'kajian',
                        label: {
                            text: 'Catatan Instruksi'
                        },
                        editorType: 'dxTextArea',
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'instruksiKepada',
                        label: {
                            text: 'Kepada'
                        }
                    },
                    {
                        dataField: 'instruksiAlamat',
                        label: {
                            text: 'Alamat'
                        }
                    },
                    {
                        dataField: 'instruksiUp',
                        label: {
                            text: 'UP Instruksi'
                        }
                    },
                    // {
                    //     dataField: 'instruksiEmailTo',
                    //     label: {
                    //         text: 'Email'
                    //     }
                    // },
                    {
                        dataField: 'instruksiFax',
                        label: {
                            text: 'No Fax'
                        }
                    },
                ]
            },
        ]
        this.formFile = [
            {
                dataField: "file",
                label: {
                    text: "File Instruksi",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    accept: 'image/png, image/jpeg, application/pdf',
                    onValueChanged: this.onUploadStarted.bind(this),
                },
            },
        ]
        this.columnPenempatan = [
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'settleNo',
                caption: 'Settle No'
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Settle',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'transactionTypeId',
                caption: 'Order',
                lookup: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName'
                }
            },
            {
                dataField: 'instrumentId',
                caption: 'Instrument',
                lookup: {
                    dataSource: instruments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode'
                }
            },
            {
                dataField: 'quantity',
                caption: 'Kuantitas (Lembar) / Face Value',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0',
            },
            {
                dataField: 'price',
                caption: 'Harga',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'amountTrs',
                caption: 'Jumlah Harga',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0',
            },
            {
                dataField: 'amountNet',
                caption: 'Jumlah Transaksi',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0',
            },
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Simpan Perubahan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.updateData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.formRef = React.createRef()
        this.formFileRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.formBankKustodiRef = React.createRef()
        this.dgRef = React.createRef()
    }

    updateData = async () => {
        var dataMaster = this.state.DataMaster
        var submitData = {
            ...dataMaster,
            custodyInstruksiId: dataMaster.id,
            fileInstruksi: this.state.fileInstruksi,
            fileInstruksiContentType: this.state.fileInstruksiContentType,
            fileName: this.state.fileName,
        }
        var insertAPI = []
        if (dataMaster.categoryId == 1) {
            insertAPI = 'order-stoks/update-instruksi-custody'
        } else if (dataMaster.categoryId == 9) {
            insertAPI = 'order-etfs/update-instruksi-custody'
        } else if (dataMaster.categoryId == 4 || 5 || 6 || 7) {
            insertAPI = 'order-sbns/update-instruksi-custody'
        }
        if (submitData) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Perubahan berhasil disimpan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show = (data) => {
        console.log('data',data)
        this.setState({
            popupVisible: true,
            DataMaster: {
                instruksiNo: data.instruksiNo || '<AUTO>',
                pejabat1: parseInt(data.pejabat1),
                pejabat2: parseInt(data.pejabat2),
                jabatan1: parseInt(data.jabatan1),
                jabatan2: parseInt(data.jabatan2),
                id: data.id,
                custodyId: data.custodyId,
                rekCustodyId: data.rekCustodyId,
                instruksiKepada: data.custody.custodyName,
                instruksiAlamat: data.custody.alamat,
                instruksiUp: data.custody.up,
                instruksiEmailTo: data.instruksiEmailTo,
                instruksiFax: data.custody.noFax,
                instruksiDate: data.instruksiDate,
                categoryId: data.categoryId,
            },
            fileInstruksi: data.fileInstruksi,
            fileInstruksiContentType: data.fileInstruksiContentType,
            dataInstruksi: {
                instruksi: data.detail
            }
        });
        this.dgRef.current.forceRefresh(true)
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Instruksi`]: formattedFileData.base64data,
                    [`${fieldName}InstruksiContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    loadData = () => {
        return this.state.dataInstruksi.instruksi
    }
    previewFile = async () => {
        var src = imageSource(this.state.fileInstruksiContentType, this.state.fileInstruksi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Form Instruksi Kustodi"}
                    width={'90vw'}
                    height={'90vh'}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <Form
                                        colCount={2}
                                        id={"formMaster"}
                                        formData={this.state.DataMaster}
                                        items={this.dataMaster}
                                        ref={this.formRef}
                                        labelLocation="left"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={"formFile"}
                                        items={this.formFile}
                                        ref={this.formFileRef}
                                        labelLocation="left"
                                    />
                                    <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileInstruksi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Buka File
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <DevExpressDataGrid
                                    ref={this.dgRef}
                                    loadAPI='detail-instruksi'
                                    insertAPI='detail-instruksi'
                                    updateAPI='detail-instruksi'
                                    deleteAPI='detail-instruksi'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                    useArraySource={true}
                                    ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Detail Instruksi"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Detail Instruksi Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={225}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnPenempatan} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <ModalPreviewPdf
                                ref={this.modalPreviewPdfRef}
                                store={this.props.store}
                            />
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        );
    }
}

export default FormInstruksiKustodiAktif