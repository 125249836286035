import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { banks, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';

class KUPengajuanPembayaranActionDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                nominalPembayaran:0,
                ppn:0,
                pph:0,
                biayaLain:0,
                potongan:0
            },
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.fromRiwayat = false

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    // onClick: this.submitData.bind(this)
                    onClick: () => {
                        var formValidation = this.validate();
                        if (formValidation){
                            this.submitData();
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        
        this.dataMaster = [
            {
                itemType: 'group',
                caption : 'Transaksi Biaya',
                items: [
                    {
                        dataField:'tipeTransaksiId',
                        label:{
                            alignment:'left',
                            text:'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'transactionTypeName', // provides display values
                            searchEnabled:true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'transaction-type-subs?size=9999', 'GET')
                                    data = data.filter(value => value.transactionTypeId === e.value)

                                    this.formDetailRef.current.instance.getEditor('subTipeTransaksi').option('dataSource',data)
                                }
                            }
                        },
                    },
                    {
                        dataField:'subTipeTransaksi',
                        label:{
                            alignment:'left',
                            text:'Sub Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'tipeSubName', // provides display values
                            searchEnabled:true,
                        },
                    },
                    {
                        dataField: 'nominalPembayaran',
                        label : {
                            alignment: 'left',
                            text: 'Nominal Pembayaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.nominalBersih = e.value + dataMaster.ppn - dataMaster.pph + dataMaster.biayaLain - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({nominalBersih:dataMaster.nominalBersih})
                            },
                        },
                    },
                    {
                        dataField: 'ppn',
                        label : {
                            alignment: 'left',
                            text: 'PPN'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.nominalBersih = dataMaster.nominalPembayaran + e.value - dataMaster.pph + dataMaster.biayaLain - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({nominalBersih:dataMaster.nominalBersih})
                            }
                        },
                    },
                    {
                        dataField: 'pph',
                        label : {
                            alignment: 'left',
                            text: 'PPH'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.nominalBersih = dataMaster.nominalPembayaran + dataMaster.ppn - e.value  + dataMaster.biayaLain - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({nominalBersih:dataMaster.nominalBersih})
                            }
                        },
                    },
                    {
                        dataField: 'biayaLain',
                        label : {
                            alignment: 'left',
                            text: 'Biaya Lain'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.nominalBersih = dataMaster.nominalPembayaran + dataMaster.ppn - dataMaster.pph + e.value - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({nominalBersih:dataMaster.nominalBersih})
                            }
                        },
                    },
                    {
                        dataField: 'potongan',
                        label : {
                            alignment: 'left',
                            text: 'Potongan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.nominalBersih = dataMaster.nominalPembayaran + dataMaster.ppn - dataMaster.pph + dataMaster.biayaLain - e.value
                                this.formDetailRef.current.instance.updateData({nominalBersih:dataMaster.nominalBersih})
                            }
                        },
                    },
                    {
                        dataField: 'nominalBersih',
                        label : {
                            alignment: 'left',
                            text: 'Jumlah'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly:true,
                        },
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Catatan Pengajuan'
                        },
                        editorType: 'dxTextArea',
                        editorOptions:{
                            width: '100%'
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Catatan Pengajuan Harap Diisi"
                            }
                        ]
                    },
                ]
            },
        ]
    }

    validate = () => {
        var formValidation = this.formDetailRef.current.instance.validate();
        return formValidation.isValid
    }
    show(fromRiwayat) {
        this.fromRiwayat = fromRiwayat
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.fromRiwayat = false
        this.setState({
            popupVisible: false,
            dataMaster: {
                nominalPembayaran:0,
                ppn:0,
                pph:0,
                biayaLain:0,
                potongan:0
            },
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = (data) => {
        this.setState({
            dataMaster: data,
        })
    }

    submitData = () => {
        var data = this.state.dataMaster
        this.props.editDataGrid(data)

        this.hide()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Pembayaran'}
                width={950}
                height={600}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formDetailRef}
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation = 'left'
                                readOnly={this.fromRiwayat}
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default KUPengajuanPembayaranActionDetail