import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import {formatDate,  formatDefaultDate, formatDefaultFullDate, getSystemDate} from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from "redux/actions/loading";
import DevExtremeTreeList from 'components/inheritComponent/devextremetreelist';
import { product } from 'dataSource/lookup';
import Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';

class BalanceSheetDetail extends Component {
  constructor(props) {
    super(props)

    this.systemDate = getSystemDate(props.store)

    this.state = {
      formFilter: {
        tanggal : getSystemDate(props.store),
        produkId : null
      }
    }

    this.treeListRef = React.createRef()
    // this.modalDetailProsesIuranRef = React.createRef()
    // this.modalGenerateProsesIuranRef = React.createRef()

    this.filterItem = [
      {
        dataField: "tanggal",
        label: {
          text: "Tanggal",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'MMM yyyy',
          calendarOptions: {
            maxZoomLevel: 'year'
          },
          openOnFieldClick: true
        }
      },
      {
        dataField: "produkId",
        label: {
          text: "Produk",
          alignment: "left",
          location: "left"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: product(this.props.store),
          displayExpr: function(item){
            return item && item.productCode + " - " + item.productName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            this.treeListRef.current.forceRefresh(true)
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
        {
            dataField: 'description',
            caption: 'Deskripsi',
        },
        {
            dataField: 'saldo',
            caption: 'Saldo',
            format: '#,##0.00',
            alignment: 'right',
            width: '300px'
        },
    ]

    this.dataPasiva = []
    this.dataAktiva = []
    this.lastMonthDate = new Date()
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  loadData = async () => {
    if(this.state.formFilter.produkId === null){
      return []
    }
    var selectedDate = new Date(this.state.formFilter.tanggal);
    const selectedMonth = selectedDate.getMonth()
    const selectedYear = selectedDate.getFullYear();
    selectedDate = new Date(selectedYear,selectedMonth + 1,0);

    var dataCOA = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'coas')
    var dataSaldo
    var systemDate = new Date(getSystemDate(this.props.store))

    systemDate.setHours(0,0,0,0)
    if(selectedDate.getTime() < systemDate.getTime()){
        dataSaldo = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-position-dailies/getByProdukIdAndValueDate/${this.state.formFilter.produkId}/${formatDate(selectedDate)}`)
        // dataSaldo = dataSaldo.filter(value => value.produkId === this.state.formFilter.produkId)
        dataSaldo = dataSaldo.map(value => {
            const coa = dataCOA.find(valueCoa => valueCoa.id === value.coaId)
            value.coaCode = coa.coaCode
            value.coaName = coa.coaName
            value.normalBalance = coa.normalBalance
            value.category = coa.category
            value.plbs = coa.plbs
            value.coaGroupId = coa.coaGroupId
            return value
        })
    }else{
        dataSaldo = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-positions/getByProdukId/${this.state.formFilter.produkId}`)
        dataSaldo = dataSaldo.map(value => {
            const coa = dataCOA.find(valueCoa => valueCoa.id === value.coaId)
            value.coaCode = coa.coaCode
            value.coaName = coa.coaName
            value.normalBalance = coa.normalBalance
            value.category = coa.category
            value.plbs = coa.plbs
            value.coaGroupId = coa.coaGroupId
            return value
        })
    }

    var coaRE = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store,'global-parameters/get-by-paramCode/RE')
    coaRE = dataCOA.find(value => value.id === coaRE.intValue)
    var dataRE = dataSaldo.filter(value=> value.plbs === 'p')
    dataSaldo = dataSaldo.filter(value => value.plbs === 'b')
    var dataREAmount = dataRE.map(value => value.balanceAmount)
    var dataREAmountBaseCurrency = dataRE.map(value => value.balanceAmountBaseCurrency)
    dataREAmount = dataREAmount.reduce((accumulator, currentValue) => (accumulator + currentValue),0);
    dataREAmountBaseCurrency = dataREAmountBaseCurrency.reduce((accumulator, currentValue) => (accumulator + currentValue),0);

    dataRE = {
      id: 0,
      balanceAmount: dataREAmount,
      balanceAmountBaseCurrency: dataREAmountBaseCurrency,
      coaId: coaRE.id,
      currencyId: 1001,
      coaCode : coaRE.coaCode,
      coaName : coaRE.coaName,
      normalBalance : coaRE.normalBalance,
      category : coaRE.category,
      plbs : coaRE.plbs,
      coaGroupId : coaRE.coaGroupId
    }

    var index = dataSaldo.findIndex(value => value.coaId === dataRE.coaId)

    if(index !== -1){
      dataSaldo[index].balanceAmount = dataSaldo[index].balanceAmount + dataRE.balanceAmount
      dataSaldo[index].balanceAmountBaseCurrency = dataSaldo[index].balanceAmountBaseCurrency + dataRE.balanceAmountBaseCurrency
    }else{
      dataSaldo.push(dataRE)
    }
    
    var dataMappingHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/LK')

    dataMappingHeader = dataMappingHeader.find(value => value.reportCode === 'NRC')
    
    var dataMap = []

    if(dataMappingHeader){
        var dataMappingDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataMappingHeader.id}`)
        dataMappingDetail = dataMappingDetail.sort((a,b) => {
                                if ( a.seqNo < b.seqNo ){
                                return -1;
                                }
                                if ( a.seqNo > b.seqNo ){
                                return 1;
                                }
                                return 0;
                            })
        
        var dataMappingGrandParent = dataMappingDetail.filter(value => value.parentId === null)

        const generateData = async (dataParent,dataMappingDetail,dataMap,dataSaldo,dataCOA,level = 0,parentSeqNo) => {
            var dataMappingCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${dataParent.id}`)
 
            const childFound = dataMappingDetail.filter(value => value.parentId === dataParent.id)

            let saldoParent = 0
            if(childFound.length > 0){
                for(var childData of childFound){
                    const saldoChild = await generateData(childData,dataMappingDetail,dataMap,dataSaldo,dataCOA,level+1,dataParent.seqNo)
                    saldoParent = saldoParent + saldoChild
                }
            }

            if(dataMappingCoa.length > 0){
                for(const mapCoa of dataMappingCoa){
                    const coa = dataCOA.find(valueCoa => valueCoa.id === mapCoa.coaId)
                    const saldo = dataSaldo.find(valueSaldo => valueSaldo.coaId === mapCoa.coaId)
    
                    dataMap.push({
                        id: `${dataParent.seqNo}-${mapCoa.id}`,
                        description : `${coa.coaCode} - ${coa.coaName}`,
                        saldo: (saldo ? saldo.balanceAmount : 0) * (mapCoa.type === 'n' ? -1 : 1),
                        parentId : `${dataParent.seqNo}`,
                        // level : level + 1
                        level: 3
                    })
    
                    saldoParent = saldoParent + ((saldo ? saldo.balanceAmount : 0) * (mapCoa.type === 'n' ? -1 : 1))
                }
            }

            dataMap.push({
                id: `${dataParent.seqNo}`,
                description : dataParent.description,
                saldo: saldoParent,
                parentId : parentSeqNo ? `${parentSeqNo}` : null,
                level : level
            })
            return saldoParent
        }

        for(const dataGrandParent of dataMappingGrandParent){
            await generateData(dataGrandParent,dataMappingDetail,dataMap,dataSaldo,dataCOA,0)
        }
    }
    return dataMap
  }

  print = async () => {
    // this.props.store.dispatch(showLoading(true))

    var result = await this.treeListRef.current.getDataSource()

    result = result.sort((a,b) => {
        if ( a.id < b.id ){
        return -1;
        }
        if ( a.id > b.id ){
        return 1;
        }
        return 0;
    })
    
    var period = formatDefaultFullDate(this.state.formFilter.tanggal)
    var prevPeriod = formatDefaultFullDate(this.lastMonthDate)
    period = period.split(' ');
    prevPeriod = prevPeriod.split(' ');
    period = period[1]+' '+period[2];
    prevPeriod = prevPeriod[1]+' '+prevPeriod[2];

    reportFile({
      template: { 'shortid': 'HJlpoxvQZt' },
      data: {
        period: period,
        prevPeriod: prevPeriod,
        dataBalanceSheet: result
      },
      options: {
          reportName: `Pos-Pos Neraca ${period}`
      }
    },true,`Pos-Pos Neraca ${period}.pdf`)
    
    this.props.store.dispatch(showLoading(false))
  }

  printToExcel = async() => {
    try {
      let data = [];
      let dataSource = this.treeListRef.current.getDataSource();
      let tanggal = this.state.formFilter.tanggal;
      const firstTableRowNum = 1;

      dataSource = dataSource.sort((a,b) => {
        if ( a.id < b.id ){
        return -1;
        }
        if ( a.id > b.id ){
        return 1;
        }
        return 0;
    })

      if (dataSource.length > 0) {
        for (let value of dataSource) {
          let dataDetail = {
            level: value.level,
            description: value.description,
            amount: value.saldo
          }
          const objectValue = Object.values(dataDetail);
          data.push(objectValue);
        }

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Pos-Pos Neraca');
        worksheet.addTable({
          name: 'MyTable',
          ref: `A${firstTableRowNum}`,
          headerRow: true,
          totalsRow: false,
          style: {
              theme: 'TableStyleLight15',
              showFirstColumn: true,
          },
          columns: [
              { name: 'level'},
              { name: 'Deskripsi'},
              { name: 'Saldo'}
          ],
          rows: data
        })
        let lastRowNum = worksheet.lastRow.number;
        const lastTableRowNum = lastRowNum;

        for (let i = firstTableRowNum; i <= lastTableRowNum; i++) {
          const row = worksheet.getRow(i);
          const levelValue = row.getCell(1).value;
          if (levelValue === 0 || levelValue === 1) {
            row.getCell(2).font = { bold: true };
            row.getCell(3).font = { bold: true };
          }
        }
      
        worksheet.getColumn(1).hidden = true;

        const Data1 = worksheet.getColumn(2);
        Data1.width = 110;

        const Data2 = worksheet.getColumn(3);
        Data2.width = 30;
        // Data2.numFmt = '""#,##0.00;[Red]\-""#,##0.00';
        Data2.numFmt = '""#,##0.00;\-""#,##0.00';

        tanggal = formatDefaultDate(tanggal).split(' ')
        await workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                'Pos-Pos Neraca ' + tanggal[1] + ' ' + tanggal[2] + '.xlsx'
            );
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
          location: 'after',
          widget: 'dxButton',
          options: {
              icon: 'print',
              hint: 'Export to pdf',
              onClick: (e) => {
                this.print()
              },
          }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'xlsxfile',
          hint: 'Export to excel',
          onClick: (e) => {
            this.printToExcel()
          },
        }
      }
    )
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Penjelasan Pos-Pos Neraca</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExtremeTreeList
            ref = {this.treeListRef}

            keyField= {'id'}
            parentIdExpr = {'parentId'}

            loadAPI='sys-menus'
            insertAPI='sys-menus'
            updateAPI='sys-menus'
            deleteAPI='sys-menus'

            backendserver={process.env.REACT_APP_BACKEND}

            useNotify = {false}

            useArraySource = {true}
            ArraySourceData = {this.loadData}

            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}

            onRowUpdating = {this.onRowUpdating}

            exportExcel={false}
            exportFileName={"User Group"}
            allowExportSelectedData={false}
            selection={'none'}
            showBorders={true}

            autoExpandAll = {true}

            paging={false}
            defaultPageSize={10}

            //bagian konfigurasi popup saat insert dan edit record
            popupTitle={'Data Menu'}
            popupWidth={700} //masukan dalam ukuran pixel
            popupHeight={500} //masukkan dalam ukuran pixel

            height = {'calc(100vh - 270px)'}

            popupFormLabelLocation='left' //accepted value = top, left, right
            popupFormMinColWidth={300} // minimum lebar kolom
            popupFormColCount={1} //jumlah kolom pada form

            //akhir bagian konfigurasi popup

            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

            FilterRow={false} // set false untuk mematikan fitur filter

            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
            SummaryConfiguration={this.summary}

            onToolbarPreparing = {this.onToolbarPreparing}

            //contoh konfigurasi kolom
            //this.columns = [{
            //    dataField: 'kolom1',
            //    caption: 'Ini Kolom 1'
            //}, {
            //    dataField: 'kolom2',
            //    caption: 'Ini Kolom 2'
            //}]
            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

            store={this.props.store} // jangan di edit edit
        />
        <span style={{fontSize: '14px'}}>* Data per tanggal {this.state.formFilter.tanggal ? formatDefaultDate(this.state.formFilter.tanggal) : ''}</span>
      </div>
    )
  }
}

export default BalanceSheetDetail