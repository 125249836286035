import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import { fixedAsset } from "dataSource/lookup";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalEditFixedAssetDisposal from 'components/pages/modal/akutansi/fixedAssetDisposal/edit';
import notify from "devextreme/ui/notify";
class FixedAssetDisposal extends Component {
    constructor(props) {
        super(props)

        this.DataGridRef = React.createRef()
        this.modalFixedAssetDisposalRef = React.createRef()
        this.modalInsertRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                width: 100,
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalView(e.row.data)
                        }
                    },
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            console.log(e)
                            if(e.row.data.status !== 'D'){
                                notify({ message: 'Hanya bisa merubah data yang masih draft!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                return null
                            }
                            this.showModalEdit(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'D',
                            value: 'Draft'
                        },
                        {
                            id: 'P',
                            value: 'Posted'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                dataField: 'fixedAssetDisposalCode',
                caption: 'Kode Transaksi',
            },
            {
                dataField: 'transDate',
                caption: 'Tanggal Transaksi',
                displayFormat: 'dd MMM yyyy',
            },
            {
                dataField: 'fixedAssetId',
                caption: 'Aset Tetap',
                lookup: {
                    dataSource: fixedAsset(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.fixedAssetCode + ' - ' + item.fixedAssetName
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'paymentMetode',
                caption: 'Metode Pembayaran',
                lookup: {
                    dataSource: [
                        {
                            id: 'T',
                            value: 'Tunai'
                        },
                        {
                            id: 'K',
                            value: 'Kredit'
                        },
                        {
                            id: 'C',
                            value: 'Campuran'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                dataField: 'amount',
                caption: 'Nilai Disposal',
                format: '#,##0.00',
            },
            {
                dataField: 'amountCash',
                caption: 'Nilai Tunai',
                format: '#,##0.00',
            },
            {
                dataField: 'amountAr',
                caption: 'Nilai AR',
                format: '#,##0.00',
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            }
        )
    }
    forceRefresh = () => {
        this.DataGridRef.current.forceRefresh()
    }
    showModalView = (data = {}) => {
        this.modalFixedAssetDisposalRef.current.show(data)
        this.modalFixedAssetDisposalRef.current.setFormState('view')
    }
    showModalEdit = (data = {}) => {
        this.modalFixedAssetDisposalRef.current.show(data)
        this.modalFixedAssetDisposalRef.current.setFormState('edit')
    }
    showModalInsert = () => {
        this.modalFixedAssetDisposalRef.current.show()
        this.modalFixedAssetDisposalRef.current.setFormState('insert')
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title font-weight-bold">Aset Tetap Disposal</h2>

                <DevExpressDataGrid
                    ref={this.DataGridRef}
                    loadAPI='fixed-asset-disposals'
                    insertAPI='fixed-asset-disposals'
                    updateAPI='fixed-asset-disposals'
                    deleteAPI='fixed-asset-disposals'

                    remoteOperations = {true}

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    selection={'multiple'}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aset Tetap'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalEditFixedAssetDisposal
                    ref={this.modalFixedAssetDisposalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default FixedAssetDisposal