import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { coa, currency, SpotControlPosition } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

class ForexBalancingConfig extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                dataField: 'configName',
                caption: 'Config Name',
                allowEditing: false
            }, 
            {
                dataField: 'currencyId',
                caption: 'Currency',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName'
                },
                setCellValue: async (newData, value, currentRowData) => {
                    newData.currencyId = value
                    if(typeof currentRowData.type === 'number' && newData.currencyId){
                        var currencyData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`currencies/${value}`)
                        if(currentRowData.type === 0){
                            newData.configName = `Spot Control ${currencyData.currencyCode}`
                        }
                        if(currentRowData.type === 1){
                            newData.configName = `Spot Position ${currencyData.currencyCode}`
                        }
                    }
                }
            }, 
            {
                dataField: 'type',
                caption: 'Config Type',
                lookup: {
                    dataSource: SpotControlPosition(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                },
                setCellValue: async (newData, value, currentRowData) => {
                    newData.type = value
                    if(typeof newData.type === 'number' && currentRowData.currencyId){
                        var currencyData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`currencies/${currentRowData.currencyId}`)
                        if(value === 0){
                            newData.configName = `Spot Control ${currencyData.currencyCode}`
                        }
                        if(value === 1){
                            newData.configName = `Spot Position ${currencyData.currencyCode}`
                        }
                    }
                }
            }, 
            {
                dataField: 'coaId',
                caption: 'Chart of Account',
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    }, // provides display values
                },
                editorOptions: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    }, 
                    searchEnabled: true,
                    wrapItemText: true
                }
            }, 
        ] 

        this.dataGridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Forex Balancing Config</h2>

                <DevExpressDataGrid
                ref={this.dataGridRef}
                    loadAPI='forex-balancing-configs'
                    insertAPI='forex-balancing-configs'
                    updateAPI='forex-balancing-configs'
                    deleteAPI='forex-balancing-configs' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Forex Balancing Config"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Forex Balancing Config Data'}
                    popupWidth={400} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default ForexBalancingConfig
