import React, { Component } from 'react'
import Form from 'devextreme-react/form'
import { gender, cities, countries, provinces, maritalStatus, banks, agama, kelurahans, kecamatans, provincesFilter, citiesFilter, kecamatansFilter, kelurahanFilter, participants, statusPajak, ptkp } from 'dataSource/lookup';
import { daysDiff } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class FormDataPersonalDPLK extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {
                identityCountryId: 130,
                domicileCountryId: 130,
                copyAddress: true
            }
        }

        this.formRef = React.createRef()

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'nickName',
                        label: {
                            alignment: 'left',
                            text: 'Nama Panggilan'
                        }
                    },
                    {
                        dataField: 'birthPlace',
                        label: {
                            alignment: 'left',
                            text: 'Tempat Lahir'
                        }
                    },
                    {
                        dataField: 'birthDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Lahir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            onValueChanged: (data) => {
                                var hari = Math.abs(daysDiff(data.value))
                                var tahun = Math.round(hari / 365)
                                var usia = this.formRef.current.instance.getEditor('usia')
                                usia.option('value', tahun)
                            }
                        }
                    },
                    {
                        dataField: 'sex',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Kelamin'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: gender(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'religionId',
                        label: {
                            alignment: 'left',
                            text: 'Agama'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: agama(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                    },
                    {
                        dataField: 'pendidikanPeringkat',
                        label: {
                            alignment: 'left',
                            text: 'Pendidikan'
                        },
                        // editorType: 'dxSelectBox',
                        // editorOptions: {
                        //     dataSource: educationBackground(this.props.store),
                        //     valueExpr: 'id', // contains the same values as the "statusId" field provides
                        //     displayExpr: 'educationName', // provides display values'
                        //     searchEnabled: true,
                        //     deferRendering: false,
                        // },
                    },
                    {
                        dataField: 'pendidikanJurusan',
                        label: {
                            alignment: 'left',
                            text: 'Jurusan Pendidikan'
                        },
                    },
                    {
                        dataField: 'pendidikanLembaga',
                        label: {
                            alignment: 'left',
                            text: 'Lembaga Pendidikan'
                        },
                    },
                    {
                        dataField: 'mariedId',
                        label: {
                            alignment: 'left',
                            text: 'Status Kawin'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: maritalStatus(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'statusPajakId',
                        label: {
                            alignment: 'left',
                            text: 'Status Pajak'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: ptkp(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: (item) => {
                                return item && item.kode + ' - ' + item.keterangan
                            },
                            onSelectionChanged: (e) => {
                                var selectedData = e.selectedItem
                                var data = this.state.formData

                                if (selectedData) {
                                    data.ptkp = selectedData.nominalSetahun
                                    data.kodePtkp = selectedData.kode

                                    this.setState({
                                        formData: data
                                    })
                                }

                                return false
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'npwp',
                        label: {
                            alignment: 'left',
                            text: 'No NPWP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            mask: 0,
                            maskRules: {
                                'h': /[0-9]/
                            }
                        }
                        // editorType: 'dxNumberBox',
                        // editorOptions: {
                        //     step: 0,
                        //     min : 0
                        // }
                    },
                    {
                        dataField: 'statusTrt',
                        label: {
                            alignment: 'left',
                            text: 'Status TRT'
                        },
                    },
                    {
                        dataField: 'noKtp',
                        label: {
                            alignment: 'left',
                            text: 'No KTP'
                        },
                        // isRequired: true,
                        // editorType: 'dxNumberBox',
                        // editorOptions: {
                        //     step: 0,
                        //     min : 0
                        // },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'No KTP Harus Diisi'
                            },
                            {
                                type: "pattern",
                                pattern: /^\(?([0-9]{0,16})\)?$/,
                                message: "No KTP harus berbentuk angka dan 16 digit"
                            }
                        ],
                    },
                    {
                        dataField: 'noKk',
                        label: {
                            alignment: 'left',
                            text: 'No KK'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            min: 0
                        }
                    },
                    {
                        dataField: 'mobileNo',
                        label: {
                            alignment: 'left',
                            text: 'No HP 1'
                        },
                        validationRules: [
                            {
                                type: "pattern",
                                pattern: /\(?([0-9])\)?/,
                                message: "No HP 1 harus berbentuk angka"
                            }
                        ],
                    },
                    {
                        dataField: 'mobilePhone2',
                        label: {
                            alignment: 'left',
                            text: 'No HP 2'
                        },
                        validationRules: [
                            {
                                type: "pattern",
                                pattern: /\(?([0-9])\)?/,
                                message: "No HP 2 harus berbentuk angka"
                            }
                        ],
                    },
                    {
                        dataField: 'email',
                        label: {
                            alignment: 'left',
                            text: 'Email 1'
                        },
                    },
                    {
                        dataField: 'email2',
                        label: {
                            alignment: 'left',
                            text: 'Email 2'
                        },
                    },
                    {
                        dataField: 'noBpjsTk',
                        label: {
                            alignment: 'left',
                            text: 'No BPJS TK'
                        },
                        validationRules: [
                            {
                                type: "pattern",
                                pattern: /\(?([0-9])\)?/,
                                message: "No BPJS TK harus berbentuk angka"
                            }
                        ],
                    },
                    {
                        dataField: 'rekeningNo',
                        label: {
                            alignment: 'left',
                            text: 'Rekening No'
                        },
                    },
                    {
                        dataField: 'rekeningNama',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Nama'
                        },
                    },
                    {
                        dataField: 'rekeningBankId',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'bankName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'gelarDepan',
                        label: {
                            alignment: 'left',
                            text: 'Gelar Didepan'
                        },
                    },
                    {
                        dataField: 'gelarBelakang',
                        label: {
                            alignment: 'left',
                            text: 'Gelar Dibelakang'
                        },
                    },
                    {
                        dataField: 'usia',
                        label: {
                            alignment: 'left',
                            text: 'Usia'
                        },
                        readOnly: true
                        // validationRules: [
                        //     {
                        //         type: "pattern",
                        //         pattern: /\(?([0-9])\)?/,
                        //         message: "Usia harus berbentuk angka"
                        //     }
                        // ],
                    },
                    {
                        dataField: 'noBpjsKesehatan',
                        label: {
                            alignment: 'left',
                            text: 'No BPJS Kesehatan'
                        },
                        validationRules: [
                            {
                                type: "pattern",
                                pattern: /\(?([0-9])\)?/,
                                message: "No BPJS Kesehatan harus berbentuk angka"
                            }
                        ],
                    },
                    {
                        dataField: 'participantId',
                        label: {
                            alignment: 'left',
                            text: 'Pemberi Kerja'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: participants(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'participantName',
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Pemberi Kerja Harus Diisi'
                            },
                        ],
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 1,
                items: [{
                    itemType: 'group',
                    caption: 'Alamat KTP',
                    items: [
                        {
                            dataField: 'identityAddress1',
                            label: {
                                alignment: 'left',
                                text: 'Alamat'
                            }
                        },
                        {
                            dataField: 'identityCountryId',
                            label: {
                                alignment: 'left',
                                text: 'Negara'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: countries(this.props.store),
                                value: 130,
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'countryName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectProvince = this.formRef.current.instance.getEditor('identityProvinceId')
                                    // selectProvince.option('value', '')
                                    if (data.value) {
                                        selectProvince.option('dataSource', provincesFilter(this.props.store, data.value, true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'identityProvinceId',
                            label: {
                                alignment: 'left',
                                text: 'Provinsi'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: provinces(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'provinceName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectCity = this.formRef.current.instance.getEditor('identityCityId')
                                    // selectCity.option('value', '')
                                    if (data.value) {
                                        selectCity.option('dataSource', citiesFilter(this.props.store, data.value, true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'identityCityId',
                            label: {
                                alignment: 'left',
                                text: 'Kota'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: cities(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'cityName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectKecamatan = this.formRef.current.instance.getEditor('identityKecamatanId')
                                    // selectKecamatan.option('value', '')
                                    if (data.value) {
                                        selectKecamatan.option('dataSource', kecamatansFilter(this.props.store, data.value, true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'identityKecamatanId',
                            label: {
                                alignment: 'left',
                                text: 'Kecamatan'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: kecamatans(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'kecamatanName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectKelurahan = this.formRef.current.instance.getEditor('identityKelurahanId')
                                    if (data.value) {
                                        selectKelurahan.option('dataSource', kelurahanFilter(this.props.store, data.value, true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'identityKelurahanId',
                            label: {
                                alignment: 'left',
                                text: 'Kelurahan'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: kelurahans(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'kelurahanName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onSelectionChanged: (data) => {
                                    if (data.selectedItem) {
                                        var inputKodePos = this.formRef.current.instance.getEditor('identityPostalCode')
                                        inputKodePos.option('value', data.selectedItem.kodePos)
                                    }
                                }
                            }
                        },
                        {
                            dataField: 'identityPostalCode',
                            label: {
                                alignment: 'left',
                                text: 'Kode Pos'
                            },
                            editorOptions: {
                                readOnly: true
                            }
                        }
                    ]
                },
                {
                    itemType: 'group',
                    caption: 'Alamat Domisili',
                    items: [
                        {
                            itemType: "button",
                            horizontalAlignment: "right",
                            buttonOptions: {
                                text: "Salin Alamat KTP",
                                onClick: (e) => {
                                    var dataPersonal = { ...this.state.formData }
                                    dataPersonal.domicileAddress1 = dataPersonal.identityAddress1
                                    dataPersonal.domicileCountryId = dataPersonal.identityCountryId
                                    dataPersonal.domicileProvinceId = dataPersonal.identityProvinceId
                                    dataPersonal.domicileCityId = dataPersonal.identityCityId
                                    dataPersonal.domicileKecamatanId = dataPersonal.identityKecamatanId
                                    dataPersonal.domicileKelurahanId = dataPersonal.identityKelurahanId
                                    dataPersonal.copyAddress = true

                                    this.formRef.current.instance.updateData(dataPersonal)
                                },
                            },
                        },
                        {
                            dataField: 'domicileAddress1',
                            label: {
                                alignment: 'left',
                                text: 'Alamat'
                            }
                        },
                        {
                            dataField: 'domicileCountryId',
                            label: {
                                alignment: 'left',
                                text: 'Negara'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: countries(this.props.store),
                                value: 130,
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'countryName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectProvince = this.formRef.current.instance.getEditor('domicileProvinceId')
                                    // selectProvince.option('value', '')
                                    if (data.value && this.state.formData.copyAddress) {
                                        selectProvince.option('dataSource', provincesFilter(this.props.store, data.value, true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'domicileProvinceId',
                            label: {
                                alignment: 'left',
                                text: 'Provinsi'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: provinces(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'provinceName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectCity = this.formRef.current.instance.getEditor('domicileCityId')
                                    // selectCity.option('value', '')
                                    if (data.value) {
                                        selectCity.option('dataSource', citiesFilter(this.props.store, data.value, true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'domicileCityId',
                            label: {
                                alignment: 'left',
                                text: 'Kota'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: cities(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'cityName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectKecamatan = this.formRef.current.instance.getEditor('domicileKecamatanId')
                                    // selectKecamatan.option('value', '')
                                    if (data.value) {
                                        selectKecamatan.option('dataSource', kecamatansFilter(this.props.store, data.value, true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'domicileKecamatanId',
                            label: {
                                alignment: 'left',
                                text: 'Kecamatan'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: kecamatans(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'kecamatanName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectKelurahan = this.formRef.current.instance.getEditor('domicileKelurahanId')
                                    var inputKodePos = this.formRef.current.instance.getEditor('domicilePostalCode')
                                    // inputKodePos.option('value', '')
                                    // selectKelurahan.option('value', '')
                                    if (data.value) {
                                        selectKelurahan.option('dataSource', kelurahanFilter(this.props.store, data.value, true))
                                    }
                                },
                            },
                        },
                        {
                            dataField: 'domicileKelurahanId',
                            label: {
                                alignment: 'left',
                                text: 'Kelurahan'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: kelurahans(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'kelurahanName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onSelectionChanged: (data) => {
                                    if (data.selectedItem) {
                                        var inputKodePos = this.formRef.current.instance.getEditor('domicilePostalCode')
                                        inputKodePos.option('value', data.selectedItem.kodePos)
                                    }
                                }
                            }
                        },
                        {
                            dataField: 'domicilePostalCode',
                            label: {
                                alignment: 'left',
                                text: 'Kode Pos'
                            },
                            editorOptions: {
                                readOnly: true
                            }
                        }
                    ]
                }
                ]
            },

        ]
    }
    retrieveData = (data) => {
        this.setState({
            formData: data
        })
        // this.formRef.current.instance.repaint()
    }

    passDataToParent = () => {
        var data = this.state.formData
        return data
    }

    reloadDataPersonal = async (pesertaId = 0, dataAkun) => {
        try {
            var loadAPI = `pesertas/${pesertaId}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            this.setState({
                formData: getPeserta
            })
        } catch (error) {
            console.log(error);
        }
    }

    removeState = () => {
        this.setState({
            formData: {
                identityCountryId: 130,
                domicileCountryId: 130
            }
        })
    }

    validate = () => {
        var validate = this.formRef.current.instance.validate()

        return validate
    }
    render() {
        return (
            <React.Fragment>
                <Form
                    ref={this.formRef}
                    colCount={2}
                    id={'formDataPersonal'}
                    formData={this.state.formData}
                    items={this.items}
                    height='500px'
                    // scrollingEnabled={true}
                    labelLocation='left'
                />
            </React.Fragment>
        )
    }
}

export default FormDataPersonalDPLK