import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatDefaultDate, getSystemDate } from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from 'redux/actions/loading';
import { product } from 'dataSource/lookup';

class CashFlow extends Component {
  constructor(props) {
    super(props)

    this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

    this.state = {
      formFilter: {
        periodDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
        produkId: null
      }
    }

    this.dataGridRef = React.createRef()
    this.modalGeneralLedgerRef = React.createRef()

    this.filterItem = [
      {
        dataField: "periodDate",
        label: {
          text: "Periode",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'MMM yyyy',
          calendarOptions: {
            maxZoomLevel: 'year'
          },
          openOnFieldClick: true
        }
      },
      {
        dataField: "produkId",
        label: {
          text: "Produk",
          alignment: "left",
          location: "left"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: product(this.props.store),
          displayExpr: function (item) {
            return item && item.productCode + " - " + item.productName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            this.setNewHeaderTitle()
            this.dataGridRef.current.forceRefresh(true)
            // this.filterSubmit()
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
      {
        dataField: 'description',
        caption: 'Description',
        grouping: 0,
        cellRender: (e) => {
          console.log(e.row.data);
          if (e.row.data.level === 0) {
            return <b>{e.value}</b>
          } else {
            return <span className="ml-3">{e.value}</span>
          }
        }
      },
      {
        dataField: 'currentPeriod',
        caption: 'Periode Berjalan',
        alignment: 'center',
        columns: [
          {
            dataField: 'currentAmount',
            caption: this.headerTitle('current'),
            format: "#,##0.00;(#,##0.00);",
            alignment: 'center',
            width: '150px',
            cellRender: (e) => {
              if (e.row.data.level === 0) {
                return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
              } else {
                return <span className="d-flex justify-content-end">{e.text}</span>
              }
            }
          },
        ]
      },
      {
        dataField: 'prevPeriod',
        caption: 'Periode Sebelumnya',
        alignment: 'center',
        columns: [
          {
            dataField: 'prevAmount',
            caption: this.headerTitle('prev'),
            format: "#,##0.00;(#,##0.00);",
            alignment: 'center',
            width: '150px',
            cellRender: (e) => {
              if (e.row.data.level === 0) {
                return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
              } else {
                return <span className="d-flex justify-content-end">{e.text}</span>
              }
            }
          },
        ]
      },
    ]

    this.dataArusKas = []
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  componentDidMount = () => {
    // this.filterSubmit()
  }

  headerTitle = (type) => {
    var periodDate = this.state.formFilter.periodDate;
    const selectedMonth = periodDate.getMonth()
    const selectedYear = periodDate.getFullYear();
    periodDate = formatDefaultDate(new Date(selectedYear, selectedMonth + 1, 0, 0, 0, 0, 0));
    var beginingYearDate = formatDefaultDate(`${selectedYear - 1}-12-31`)

    if (type === 'current') {
      return periodDate
    } else {
      return beginingYearDate
    }
  }

  setNewHeaderTitle = () => {
    this.dataGridRef.current.DataGrid.deleteColumn('currentPeriod')
    this.dataGridRef.current.DataGrid.deleteColumn('prevPeriod')
    this.dataGridRef.current.DataGrid.addColumn({
      dataField: 'currentPeriod',
      caption: 'Periode Berjalan',
      alignment: 'center',
      columns: [
        {
          dataField: 'currentAmount',
          caption: this.headerTitle('current'),
          format: "#,##0.00;(#,##0.00);",
          alignment: 'center',
          width: '150px',
          cellRender: (e) => {
            if (e.row.data.level === 0) {
              return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
            } else {
              return <span className="d-flex justify-content-end">{e.text}</span>
            }
          }
        },
      ]
    })
    this.dataGridRef.current.DataGrid.addColumn({
      dataField: 'prevPeriod',
      caption: 'Periode Sebelumnya',
      alignment: 'center',
      columns: [
        {
          dataField: 'prevAmount',
          caption: this.headerTitle('prev'),
          format: "#,##0.00;(#,##0.00);",
          alignment: 'center',
          width: '150px',
          cellRender: (e) => {
            if (e.row.data.level === 0) {
              return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
            } else {
              return <span className="d-flex justify-content-end">{e.text}</span>
            }
          }
        },
      ]
    })
  }

  filterSubmit = async (OJKProdukId, OJKTanggal) => {
    const produkId = OJKProdukId || this.state.formFilter.produkId
    const periodDate = OJKTanggal || this.state.formFilter.periodDate

    if (produkId === null || periodDate === null) {
      return []
    }

    var dataReportHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/OJK')
    dataReportHeader = dataReportHeader.find(value => value.reportCode === 'ARK')
    var dataMapping = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataReportHeader.id}`)

    var dataMappingParent = dataMapping.filter(value => value.parentId === null)
    if (dataMappingParent.length > 0) {
      dataMappingParent = dataMappingParent.sort((a, b) => {
        if (a.seqNo > b.seqNo) {
          return 1
        }
        if (a.seqNo === b.seqNo) {
          return 0
        }
        if (a.seqNo < b.seqNo) {
          return -1
        }
      })
    }

    var data = [], index = 0, total = 0, totalPrev = 0, idTotalMutasi, idAkhirPeriod, idAwalPeriode
    for (const parent of dataMappingParent) {
      var totalAmount = 0
      var totalAmountPrev = 0
      if (parent.beginningValue !== 0 && parent.beginningValue !== null) {
        var description = parent.description
        description = description.toLowerCase()
        description = description.replace('akhir', 'awal').toUpperCase()

        idTotalMutasi = index

        const saldo = parseFloat(await this.getMutasiKas(parent.id, false, periodDate, OJKProdukId) || 0)
        const saldoPrev = parseFloat(await this.getMutasiKas(parent.id, true, periodDate, OJKProdukId) || 0)
        console.log(parent, saldo, saldoPrev);

        data.push({
          id: index++,
          description: 'KENAIKAN (PENURUNAN) KAS BERSIH',
          currentAmount: 0,
          prevAmount: 0,
          level: 0,
          seqNo: 45
        })

        idAwalPeriode = index

        data.push({
          id: index++,
          description: description,
          currentAmount: saldo,
          prevAmount: saldoPrev,
          level: 0,
          seqNo: 46
        })

        idAkhirPeriod = index

        data.push({
          id: index++,
          description: parent.description,
          currentAmount: 0,
          prevAmount: 0,
          level: 0,
          seqNo: 47
        })
      } else {
        data.push({
          id: index++,
          description: parent.description,
          currentAmount: null,
          prevAmount: null,
          level: 0,
          seqNo: parent.seqNo
        })

        var dataMappingChild = dataMapping.filter(value => value.parentId === parent.id)
        if (dataMappingChild.length > 0) {
          dataMappingChild = dataMappingChild.sort((a, b) => {
            if (a.seqNo > b.seqNo) {
              return 1
            }
            if (a.seqNo === b.seqNo) {
              return 0
            }
            if (a.seqNo < b.seqNo) {
              return -1
            }
          })
        }


        for (const child of dataMappingChild) {
          const saldo = parseFloat(await this.getDataMutasi(child.id, false, periodDate, OJKProdukId) || 0)
          const saldoPrev = parseFloat(await this.getDataMutasi(child.id, true, periodDate, OJKProdukId) || 0)
          data.push({
            id: index++,
            description: child.description,
            currentAmount: saldo,
            prevAmount: saldoPrev,
            level: 1,
            seqNo: child.seqNo
          })

          totalAmount = totalAmount + saldo
          totalAmountPrev = totalAmountPrev + saldoPrev
        }
        total = total + totalAmount
        totalPrev = totalPrev + totalAmountPrev

        data.push({
          id: index++,
          description: 'Total ' + parent.description,
          currentAmount: totalAmount,
          prevAmount: totalAmountPrev,
          level: 0
        })
      }
    }

    const indexTotalMutasi = data.findIndex(value => value.id === idTotalMutasi)

    data[indexTotalMutasi].currentAmount = total
    data[indexTotalMutasi].prevAmount = totalPrev
    data[idAkhirPeriod].currentAmount = data[idAwalPeriode].currentAmount + total
    data[idAkhirPeriod].prevAmount = data[idAwalPeriode].prevAmount + totalPrev

    return data
  }

  getDataMutasi = async (seqNoTarget, isPrev, periodDate, OJKProdukId, isKas = false) => {
    // var periodDate = this.state.formFilter.periodDate;

    const selectedMonth = periodDate.getMonth()
    const selectedYear = periodDate.getFullYear();
    if (isPrev) {
      if (OJKProdukId && !isKas) {
        periodDate = new Date(selectedYear, selectedMonth, 0)
      } else {
        periodDate = new Date(selectedYear, 0, 0);
      }
    } else {
      periodDate = new Date(selectedYear, selectedMonth + 1, 0);
    }

    var res = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-books/getTotalNominalAndSeqNoAndLessThanTimestamp/${seqNoTarget}/${periodDate.toISOString()}`)

    return res
  }

  getMutasiKas = async (seqNoTarget, isPrev, periodDate, OJKProdukId) => {
    if (isPrev && OJKProdukId === '') {
      return parseFloat(await this.getDataMutasi(seqNoTarget, isPrev, periodDate, OJKProdukId) || 0)
    }
    // var periodDate = this.state.formFilter.periodDate;
    const selectedYear = periodDate.getFullYear();
    const selectedMonth = periodDate.getMonth();

    let periodDateLastYear
    periodDateLastYear = new Date(selectedYear, 0, 0);

    var res = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-books/getTotalNominalAndSeqNoAndLessThanTimestamp/${seqNoTarget}/${periodDateLastYear.toISOString()}`)

    var dataReportHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/OJK')
    dataReportHeader = dataReportHeader.find(value => value.reportCode === 'ARK')
    var dataMapping = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataReportHeader.id}`)

    var dataChild = dataMapping.filter(value => value.parentId !== null)
    if (dataChild.length > 0) {
      dataChild = dataChild.sort((a, b) => {
        if (a.seqNo > b.seqNo) {
          return 1
        }
        if (a.seqNo === b.seqNo) {
          return 0
        }
        if (a.seqNo < b.seqNo) {
          return -1
        }
      })
    }
    var total = 0
    for (const child of dataChild) {
      const amount = parseFloat(await this.getDataMutasi(child.id, true, periodDate, OJKProdukId, true) || 0)

      total = total + amount
    }
    return parseFloat(res || 0) + total
  }

  loadData = () => {
    return this.dataArusKas
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'print',
          onClick: (e) => {
            this.print()
          },
        }
      }
    )
  }

  print = () => {
    this.props.store.dispatch(showLoading(true))

    var endPeriod = this.state.formFilter.periodDate, prevPeriodDate, startPeriod;
    const selectedMonth = endPeriod.getMonth()
    const selectedYear = endPeriod.getFullYear();

    prevPeriodDate = new Date(selectedYear, 0, 0);
    endPeriod = new Date(selectedYear, selectedMonth + 1, 0);
    startPeriod = new Date(selectedYear, 0, 1);
    var data = this.dataGridRef.current.getDataSource()

    reportFile({
      template: { 'shortid': 'Hyg7nNsL1F' },
      data: {
        endPeriod: formatDefaultDate(endPeriod),
        startPeriod: formatDefaultDate(startPeriod),
        prevPeriod: formatDefaultDate(prevPeriodDate),
        dataCashFlow: data
      },
      options: {
        reportName: `arus-kas-${formatDefaultDate(endPeriod)}`
      }
    }, true, `arus-kas-${formatDefaultDate(endPeriod)}`)

    this.props.store.dispatch(showLoading(false))
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Arus Kas</h2>
        <div>
          <Form
            colCount={3}
            id={'formFilter'}
            formData={this.state.formFilter}
            items={this.filterItem}
          />
        </div>

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='cashflow'
          insertAPI='cashflow'
          updateAPI='cashflow'
          deleteAPI='cashflow'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          useArraySource={true}
          ArraySourceData={this.filterSubmit}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Cash Flow"}
          allowExportSelectedData={false}
          selection={"none"}

          showBorders={true}

          paging={false}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Cash Flow'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={false} // set false untuk mematikan fitur filter
          sortingMode={false}

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}
          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
      </div>
    )
  }
}

export default CashFlow