import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatUploadFileData } from 'plugin/helper'
import ScrollView from 'devextreme-react/scroll-view';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class FormModalSupplierPemeliharaanAset extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
            fileUploadReceipt:[],
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField:'noPemeliharaan',
                                label:{
                                    text:'No Pemeliharaan'
                                }
                            },
                            {
                                dataField:'supplier',
                                label:{
                                    text:'Supplier'
                                }
                            },
                            {
                                dataField:'tanggalPemeliharaan',
                                label:{
                                    text:'Tanggal Pemeliharaan'
                                }
                            },
                            {
                                dataField:'estimasiHari',
                                label:{
                                    text:'Estimasi Hari'
                                },
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'informasi-aset',
                caption:'Informasi Aset',
                items: [
                    {
                        dataField:'groupAset',
                        label:{
                            text:'Group Aset'
                        }
                    },
                    {
                        dataField:'jenisAset',
                        label:{
                            text:'Jenis Aset'
                        }
                    },
                    {
                        dataField:'tipeAset',
                        label:{
                            text:'Tipe Aset'
                        }
                    },
                    {
                        dataField:'unit',
                        label:{
                            text:'Unit'
                        }
                    },
                    {
                        dataField:'karyawan',
                        label:{
                            text:'Karyawan'
                        }
                    },
                ]
            },
            
        ]

        this.columns = [
            {
                dataField:'tanggal',
                caption:'Tanggal'
            },
            {
                dataField:'supplier',
                caption:'Supplier'
            },
            {
                dataField:'keterangan',
                caption:'Keterangan'
            },
            {
                dataField:'biaya',
                caption:'Biaya'
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}Name`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }


    submitData = () => {
    
    }

    updateData = () => {
    
    }

    loadDummyData = () => {
        var data = [
            {
                id:1
            }
        ]
        return data
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Supplier Pemeliharaan Aset'} 
                width={"100vh"}
                height={"80vh"}
                toolbarItems={this.props.from == 'riwayat' ? [] : this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
                <Form
                    ref={this.formRef}
                    colCount={2}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
                <div className="mt-3">
                    <DevExpressDataGrid
                        ref = {this.dataGridRef}
                        loadAPI='Kinerja'
                        insertAPI='Kinerja'
                        updateAPI='Kinerja'
                        deleteAPI='Kinerja' 

                        backendserver={process.env.REACT_APP_BACKEND_HRD}
                        useArraySource={true}
                        ArraySourceData={this.loadDummyData}
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Tanggal Cuti"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}
                        height={400}
                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Tanggal Cuti'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
            </ScrollView>
            </Popup>
        )
    }
}

export default FormModalSupplierPemeliharaanAset