import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { orders, stock, etfNeedConfirmByBroker, broker, productDefault, instrumentEtf, instruments } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ScrollView from 'devextreme-react/scroll-view';


class ModalActionSettlementTransaksiPenempatanEtf extends Component {
    constructor(props) {
        super(props)
        this.pph = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '3 ETF-PPH') : null
        this.levy = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '3 ETF-LEVY') : null
        this.vat = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '3 ETF-VAT') : null

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null,
            noOrderDataSource: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPencairanRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'orderEtfId',
                        label: {
                            text: 'No Order'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'orderEtfId',
                            displayExpr: 'orderCode',
                            onOpened: () => {
                                var selectOrderId = this.formRef.current.instance.getEditor('orderEtfId')

                                selectOrderId.option('dataSource', etfNeedConfirmByBroker(this.props.store, this.state.dataMaster.brokerId, this.state.dataMaster.productId, this.state.dataMaster.orderDate))
                            },
                            onSelectionChanged: (e) => {
                                var data = e.selectedItem
                                 
                                if (data) {
                                    this.formRef.current.instance.getEditor('quantity').option('value', data.quantityOrder)
                                    this.formRef.current.instance.getEditor('transactionName').option('value', data.transactionName)
                                    this.formRef.current.instance.getEditor('instrumentCode').option('value', data.instrumentCode)
                                    this.formRef.current.instance.getEditor('orderId').option('value', data.orderId)
                                    this.formRef.current.instance.getEditor('etfId').option('value', data.orderEtfId)
                                    this.formRef.current.instance.getEditor('instrumentId').option('value', data.instrumentId)
                                    this.formRef.current.instance.getEditor('trsName').option('value', data.transactionName)
                                    this.setState({
                                        orderCode: data.orderCode
                                    })
                                    this.formRef.current.instance.getEditor('pph').option('value', 0)
                                    // this.formRef.current.instance.getEditor('pph').option('readOnly', true)
                                }

                                return data
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose Order"
                            }
                        ]
                    },
                    {
                        dataField: 'transactionName',
                        label: {
                            text: 'Tipe order transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'instrumentCode',
                        label: {
                            text: 'Saham'
                        },
                        // editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true
                            // dataSource: stock(this.props.store),
                            // valueExpr: 'id',
                            // displayExpr: 'instrumentCode'
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose Instrument Saham"
                            }
                        ]
                    },
                    {
                        dataField: 'quantity',
                        label: {
                            text: 'Kuantitas'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            onValueChanged: (e) => {
                                var val = e.value
                                var price = this.formRef.current.instance.getEditor('price').option('value')
                                if (val) {
                                    this.formRef.current.instance.getEditor('amountTrs').option('value', val * price)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill quantity order"
                            }
                        ]
                    },
                    {
                        dataField: 'price',
                        label: {
                            text: 'Harga'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.000000',
                            onValueChanged: (e) => {
                                var val = e.value
                                var qty = this.formRef.current.instance.getEditor('quantity').option('value')
                                if (val) {
                                    this.formRef.current.instance.getEditor('amountTrs').option('value', qty * val)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill price"
                            }
                        ]
                    },
                    {
                        dataField: 'amountTrs',
                        label: {
                            text: 'Nilai Transaksi'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var levy = this.formRef.current.instance.getEditor('levy').option('value')
                                var amountTrs = e.value
                                var commission = this.formRef.current.instance.getEditor('commission').option('value')
                                var komisiPercent = this.formRef.current.instance.getEditor('komisiPercent').option('value')
                                var vat = this.formRef.current.instance.getEditor('vat').option('value')
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                if (e.value) {
                                    this.countAmountNet(amountTrs, commission, vat, levy, pph)
                                    this.formRef.current.instance.getEditor('levy').option('value', Math.round((this.levy.floatValue / 100) * amountTrs))
                                    this.formRef.current.instance.getEditor('commission').option('value', Math.round(Math.round(amountTrs * (komisiPercent / 100))))
                                }
                            },
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill amount transaction"
                            }
                        ]
                    },
                    {
                        dataField: 'komisiPercent',
                        label: {
                            text: 'Komisi (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00000000',
                            onValueChanged: (e) => {
                                var val = e.value
                                var amountTrs = this.formRef.current.instance.getEditor('amountTrs').option('value')
                                if (val) {
                                    this.formRef.current.instance.getEditor('commission').option('value', Math.round(amountTrs * (val / 100)))
                                }
                            },
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill commission"
                            }
                        ]
                    },
                    {
                        dataField: 'commission',
                        label: {
                            text: 'Komisi Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var levy = this.formRef.current.instance.getEditor('levy').option('value')
                                var amountTrs = this.formRef.current.instance.getEditor('amountTrs').option('value')
                                var commission = e.value
                                var vat = this.formRef.current.instance.getEditor('vat').option('value')
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                if (e.value) {
                                    this.countAmountNet(amountTrs, commission, vat, levy, pph)
                                    this.formRef.current.instance.getEditor('vat').option('value', Math.round((this.vat.floatValue / 100) * commission))
                                }
                            },
                        },
                    },
                    {
                        dataField: 'vat',
                        label: {
                            text: 'Vat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var levy = this.formRef.current.instance.getEditor('levy').option('value')
                                var amountTrs = this.formRef.current.instance.getEditor('amountTrs').option('value')
                                var commission = this.formRef.current.instance.getEditor('commission').option('value')
                                var vat = e.value
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                if (e.value) {
                                    this.countAmountNet(amountTrs, commission, vat, levy, pph)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill VAT"
                            }
                        ]
                    },
                    {
                        dataField: 'levy',
                        label: {
                            text: 'Levy'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var levy = e.value
                                var amountTrs = this.formRef.current.instance.getEditor('amountTrs').option('value')
                                var commission = this.formRef.current.instance.getEditor('commission').option('value')
                                var vat = this.formRef.current.instance.getEditor('vat').option('value')
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                if (e.value) {
                                    this.countAmountNet(amountTrs, commission, vat, levy, pph)
                                }
                            },
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill Levy"
                            }
                        ]
                    },
                    {
                        dataField: 'pph',
                        label: {
                            alignment: 'left',
                            text: 'PPh'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            // readOnly: true,
                            onValueChanged: (e) => {
                                var levy = this.formRef.current.instance.getEditor('levy').option('value')
                                var amountTrs = this.formRef.current.instance.getEditor('amountTrs').option('value')
                                var commission = this.formRef.current.instance.getEditor('commission').option('value')
                                var vat = this.formRef.current.instance.getEditor('vat').option('value')
                                var pph = e.value
                                if (e.value) {
                                    this.countAmountNet(amountTrs, commission, vat, levy, pph)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill PPh"
                            }
                        ]
                    },
                    {
                        dataField: 'amountNet',
                        label: {
                            alignment: 'left',
                            text: 'Nilai Transaksi Bersih'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill Netting Amount"
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'INFORMASI ORDER ETF',
                        items: [
                            {
                                dataField: 'orderId',
                                label: {
                                    alignment: 'left',
                                    text: 'Order ID'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'etfId',
                                label: {
                                    alignment: 'left',
                                    text: 'Order ETF ID'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'instrumentId',
                                label: {
                                    alignment: 'left',
                                    text: 'ETF'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: instruments(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.instrumentCode + ' - ' + e.instrumentName
                                    },
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'trsName',
                                label: {
                                    alignment: 'left',
                                    text: 'Tipe Transaksi'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'INFORMASI PORTOFOLIO',
                        items: [
                            {
                                dataField: 'produk',
                                label: {
                                    alignment: 'left',
                                    text: 'Produk'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'kpd',
                                label: {
                                    alignment: 'left',
                                    text: 'KPD'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'saldo',
                                label: {
                                    alignment: 'left',
                                    text: 'Saldo (Unit)'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                        ]
                    },
                ]
            }
        ]
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    show = async (data) => {
        var dataBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `brokers/${data.brokerId}`, 'GET')
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                orderDate: formatDate(data.orderDate),
                productId: data.productId,
                brokerId: data.brokerId,
                orderCode: data.orderCode,
                orderEtfId: data.orderEtfId,
                transactionName: data.transactionName,
                instrumentCode: data.instrumentCode,
                quantity: data.quantity,
                price: data.price,
                amountTrs: data.amountTrs,
                commission: data.commission,
                levy: data.levy,
                vat: data.vat,
                pph: data.pph,
                amountNet: data.amountNet,
                feeBuy: dataBroker.feeBuy,
                feeSell: dataBroker.feeSell,
                komisiPercent: data.komisiPercent,
                orderId: data.orderId,
                etfId: data.etfId,
                instrumentId: data.instrumentId,
                trsName: data.trsName,
            },
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    submitData = () => {
        var data = this.state.dataMaster
        this.props.submitDetailPenempatanData({
            id: data.id,
            orderEtfId: data.orderEtfId,
            transactionName: data.transactionName,
            instrumentCode: data.instrumentCode,
            quantity: data.quantity,
            price: data.price,
            amountTrs: data.amountTrs,
            commission: data.commission,
            levy: data.levy,
            vat: data.vat,
            pph: data.pph,
            amountNet: data.amountNet,
            orderCode: this.state.orderCode,
            komisiPercent: data.komisiPercent,
            orderId: data.orderId,
            etfId: data.etfId,
            instrumentId: data.instrumentId,
            trsName: data.trsName,
        })

        this.hide()
    }
    countAmountNet = (amountTrs, commission, vat, levy, pph) => {
        this.formRef.current.instance.getEditor('amountNet').option('value', Math.round(amountTrs + commission + vat + levy - pph))
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi ETF'}
                width={'60vw'}
                height={'65vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <Form
                        colCount={2}
                        ref={this.formRef}
                        id={'formDataMaster'}
                        formData={this.state.dataMaster}
                        items={this.items}
                        labelLocation={"left"}
                    />
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalActionSettlementTransaksiPenempatanEtf