import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { obligasi } from '../parts/staticDataColumnConfig';
import { currency, emitens, frekuensis, instrumentCategories, instrumentsCategoryObli, ratings } from 'dataSource/lookup';
import FormModalInstruObligasi from '../modal/static-data/investasi/obligasi/index';
import httpRequest from 'plugin/httprequest';

class InstruObligasi extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModal(e)
                        }
                    },
                    // {
                    //     text: 'Kupon',
                    //     onClick: (e) => {
                    //         console.log(e)
                    //         // this.showModal(e)
                    //     }
                    // }
                ]
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Obligasi',
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Obligasi',
            },
            {
                dataField: 'emitenId',
                caption: 'Emiten',
                lookup: {
                    dataSource: emitens(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'emitenName'
                }
            },
            {
                dataField: 'instrumentCategoryId',
                caption: 'Kategori',
                lookup: {
                    dataSource: [
                        {
                            id: 4,
                            categoryName: 'SBN'
                        },
                        {
                            id: 5,
                            categoryName: 'Obligasi Korporasi'
                        },
                        {
                            id: 6,
                            categoryName: 'Surat Hutang'
                        },
                        {
                            id: 7,
                            categoryName: 'EBA'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'categoryName'
                }
            },
            {
                dataField: 'instrumentCategorySubId',
                caption: 'Sub Kategori',
                lookup: {
                    dataSource: instrumentsCategoryObli(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                }
            },
            {
                dataField: 'maturityDate',
                caption: 'Tanggal jatuh tempo',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right',
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                },
            },
            {
                dataField: "currencyId",
                caption: "Currency",
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            },
            {
                dataField: 'rating1Id',
                caption: 'Rating Awal',
                lookup: {
                    dataSource: ratings(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.ratingName + ' (' + e.ratingCode + ')'
                    },
                }
            },
            {
                dataField: 'rating2Id',
                caption: 'Rating Akhir',
                lookup: {
                    dataSource: ratings(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.ratingName + ' (' + e.ratingCode + ')'
                    },
                }
            },
            {
                dataField: 'kupon',
                caption: 'Kupon',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'couponPeriod',
                        caption: 'Frekuensi / Tahun',
                        lookup: {
                            dataSource: frekuensis(),
                            valueExpr: 'value',
                            displayExpr: (e) => {
                                var val = e.value.split('_')
                                return val.join(' ')
                            }
                        }
                    },
                    {
                        dataField: 'rate',
                        caption: 'Rate',
                        format: "#,##0.000000",
                        alignment: 'right',
                        cellRender: (e) => {
                            return e.value + ' %'
                        },
                    },
                    {
                        dataField: 'couponLastDate',
                        caption: 'Tanggal Terakhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'couponNextDate',
                        caption: 'Tanggal Berikutnya',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'syariah',
                caption: 'Syariah',
            },
        ]

        this.modalRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = async (e) => {
        if (e.row) {
            var data = e.row.data
            var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.id}`)
            var customGetData = {
                id: data.id,
                instrumentCode: data.instrumentCode,
                instrumentName: data.instrumentName,
                isinCode: instrument.isinCode,
                categorySubId: data.instrumentCategorySubId,
                status: instrument.status,
                emitenId: data.emitenId,
                custodyId: data.custodyId,
                currencyId: data.currencyId,
                faceValue: instrument.faceValue,
                syariah: data.syariah,
                maturityDate: data.maturityDate,
                // currencyId: data.currencyId,
                rate: data.rate,
                couponPeriod: data.couponPeriod,
                dayBase: instrument.dayBase,
                couponLastDate: data.couponLastDate,
                couponNextDate: data.couponNextDate,
                rating1Id: data.rating1Id,
                rating2Id: data.rating2Id,
                rateTypeId: instrument.rateTypeId
            }
            this.modalRef.current.retrieveData(customGetData)
            this.modalRef.current.newData(true)
        } else {
            this.modalRef.current.newData(false)
        }
        this.modalRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal(e)
                    },
                }
            }
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Obligasi/SBN/EBA</h2>

                <DevExpressDataGrid
                    loadAPI='instruments/obligasi'
                    insertAPI='staticdata-wf/instruments'
                    updateAPI='staticdata-wf/instruments'
                    deleteAPI='staticdata-wf/instruments'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Instrumen Obligasi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instrumen Obligasi Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={600} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={500} // minimum lebar kolom
                    popupFormColCount={2} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalInstruObligasi
                    ref={this.modalRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default InstruObligasi
