import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'

import { gender, statusKeluarga, agama, hubunganKeluarga } from 'dataSource/lookup';
import { formatUploadFileData } from 'plugin/helper';
import { ScrollView } from '../../../../../../node_modules/devextreme-react';

class ModalKeluargaPengkinianAktifRegistrasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                actionType : false,
            },
            fileTextStatusMenikah: '',
            fileTextStatusSekolah: '',
            fileTextPenghasilan: '',
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.formRef = React.createRef()
        this.readOnly = false

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()

                        if (formValidation) {
                            this.submitData();
                        }
                    },
                    visible: this.props.actionType === 'view' ? false : true
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataMaster = [
            {
                itemType: "group",
                items: [
                    {
                        dataField: 'fullName',
                        label: {
                            alignment: 'left',
                            text: 'Nama Lengkap'
                        }
                    },
                    {
                        dataField: 'birthPlace',
                        label: {
                            alignment: 'left',
                            text: 'Tempat Lahir'
                        }
                    },
                    {
                        dataField: 'birthDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Lahir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal Lahir Harus Diisi'
                            },
                        ]
                    },
                    {
                        dataField: 'sex',
                        label: {
                            alignment: 'left',
                            text: 'Gender'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: gender(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values,
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'relationCode',
                        label: {
                            alignment: 'left',
                            text: 'Hubungan Keluarga'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: hubunganKeluarga(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'mariedId',
                        label: {
                            alignment: 'left',
                            text: 'Status Kawin'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: statusKeluarga(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'statusSekolah',
                        label: {
                            alignment: 'left',
                            text: 'Masih Sekolah/Kuliah'
                        },
                        editorType:'dxCheckBox',
                    },
                    {
                        dataField: 'statusPenghasilan',
                        label: {
                            alignment: 'left',
                            text: 'Sudah Berpenghasilan'
                        },
                        editorType:'dxCheckBox',
                    },
                    {
                        dataField: 'ktp', 
                        label: {
                            alignment: 'left',
                            text: 'No KTP'
                        },
                        validationRules: [
                            // {
                            //     type: 'required',
                            //     message: 'No KTP Harus Diisi'
                            // },
                            {
                                type: "pattern",
                                pattern: /^\(?([0-9]{0,16})\)?$/,
                                message: "No KTP harus berbentuk angka dan 16 digit"
                            }
                        ]
                    },
                    {
                        dataField: 'noKk',
                        label: {
                            alignment: 'left',
                            text: 'No KK'
                        },
                        validationRules: [
                            // {
                            //     type: 'required',
                            //     message: 'No KK Harus Diisi'
                            // }
                        ]
                    },
                    {
                        dataField: 'noBpjs',
                        label: {
                            alignment: 'left',
                            text: 'No BPJS'
                        }
                    },
                ]
            },
            {
                itemType: "group",
                cssClass: 'mt-3',
                colCount: 1,
                items: [
                    {
                        itemType: "group",
                        cssClass: 'mt-3',
                        caption: 'Alamat KTP',
                        items: [
                            {
                                dataField: 'address',
                                label:{
                                    text: 'Alamat'
                                }
                            },
                            // {
                            //     dataField: 'provinsiId',
                            //     label: {
                            //         alignment: 'left',
                            //         text: 'Provinsi'
                            //     },
                            //     editorType: 'dxSelectBox',
                            //     editorOptions: {
                            //         dataSource: provinces(this.props.store),
                            //         valueExpr: 'id',
                            //         displayExpr: 'provinceName',
                            //         searchEnabled: true,
                            //         deferRendering: false,
                            //         onValueChanged: async(data) => {
                            //             var selectCity = this.formRef.current.instance.getEditor('cityId')
                            //             selectCity.option('dataSource',citiesFilter(this.props.store,data.value,true))
                            //         }
                            //     }
                            // },
                            // {
                            //     dataField: 'cityId',
                            //     label: {
                            //         alignment: 'left',
                            //         text: 'Kota'
                            //     },
                            //     editorType: 'dxSelectBox',
                            //     editorOptions: {
                            //         dataSource: cities(this.props.store),
                            //         valueExpr: 'id',
                            //         displayExpr: 'cityName',
                            //         searchEnabled: true,
                            //         deferRendering: false,
                            //     }
                            // },
                            {
                                dataField: 'agama',
                                label: {
                                    alignment: 'left',
                                    text: 'Agama'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: agama(),
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                    {
                        itemType: "group",
                        cssClass: 'mt-3 d-none',
                        caption: 'Alamat Domisili',
                        items: [
                            {
                                dataField: 'address',
                                label:{
                                    text: 'Alamat'
                                }
                            },
                            // {
                            //     dataField: 'provinsiId',
                            //     label: {
                            //         alignment: 'left',
                            //         text: 'Provinsi'
                            //     },
                            //     editorType: 'dxSelectBox',
                            //     editorOptions: {
                            //         dataSource: provinces(this.props.store),
                            //         valueExpr: 'id',
                            //         displayExpr: 'provinceName',
                            //         searchEnabled: true,
                            //         deferRendering: false,
                            //     }
                            // },
                            // {
                            //     dataField: 'cityId',
                            //     label: {
                            //         alignment: 'left',
                            //         text: 'Kota'
                            //     },
                            //     editorType: 'dxSelectBox',
                            //     editorOptions: {
                            //         dataSource: cities(this.props.store),
                            //         valueExpr: 'id',
                            //         displayExpr: 'cityName',
                            //         searchEnabled: true,
                            //         deferRendering: false,
                            //     }
                            // },
                            {
                                dataField: 'agama',
                                label: {
                                    alignment: 'left',
                                    text: 'Agama'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: agama(),
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    }
                ]
            },
            {
                itemType: "group",
                cssClass: 'mt-3',
                caption: "Penunjukkan Sebagai Ahli Waris",
                items: [
                    // {
                    //     dataField: 'ahliWaris',
                    //     label: {
                    //         alignment: 'left',
                    //         text: 'Ahli Waris'
                    //     },
                    //     editorType:'dxCheckBox',
                    // },
                    {
                        dataField: 'ahliWarisSuratNo',
                        label: {
                            alignment: 'left',
                            text: 'Surat Penunjukkan No.'
                        }
                    },
                    {
                        dataField: 'ahliWarisSuratDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Surat'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        }
                    },
                ]
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {
                provinsiId: 0
            },
            typeData : {},
            fileTextStatusMenikah: '',
            fileTextStatusSekolah: '',
            fileTextPenghasilan: '',
        })
    }

    validate = () => {
        var validate = this.formRef.current.instance.validate()

        return validate.isValid
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`file${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    },
                    [`fileText${fieldName}`]: files.name,
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }
    
    submitData = () => {
        var dataSubmit = {...this.state.dataMaster}
        this.props.editDataKeluarga(dataSubmit)
        this.hide()
    }

    retrieveData = (data,type) => {   
        var dataKeluarga = data
        dataKeluarga.fileStatusMenikah = []
        dataKeluarga.fileStatusSekolah = []
        dataKeluarga.filePenghasilan = []
        this.readOnly = type === 'view' ? true : false
        this.setState({
            dataMaster: {
                ...dataKeluarga,
                actionType: type,
            },
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={(this.state.actionType == 'edit' ? 'Edit' : 'Tambah')+' Keluarga'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="mt-2 col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                readOnly={this.readOnly}
                                labelLocation = 'left'
                            />
                        </div>
                        {/* <div className="mt-2 col-md-12">
                            <h5>Dokumen Ahli Waris</h5>
                            <Box 
                                direction={'row'}
                                width={'50%'}
                            >
                                <Item ratio={1}>
                                    <FileUploader 
                                        // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)} 
                                        labelText={this.state.fileTextStatusMenikah || 'File Status Menikah'}
                                        showFileList={false}
                                        name={'StatusMenikah'}
                                    />
                                </Item>
                            </Box>
                            <Box 
                                direction={'row'}
                                width={'50%'}
                            >
                                <Item ratio={1}>
                                    <FileUploader 
                                        // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)} 
                                        labelText={this.state.fileTextStatusSekolah || 'File Status Sekolah'}
                                        showFileList={false}
                                        name={'StatusSekolah'}
                                    />
                                </Item>
                                
                            </Box>
                            <Box 
                                direction={'row'}
                                width={'50%'}
                            >
                                <Item ratio={1}>
                                    <FileUploader 
                                        // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)} 
                                        labelText={this.state.fileTextPenghasilan ||'File Penghasilan'}
                                        showFileList={false}
                                        name={'Penghasilan'}
                                    />
                                </Item>
                            </Box>
                        </div> */}
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalKeluargaPengkinianAktifRegistrasi
