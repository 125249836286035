import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { gender, banks } from 'dataSource/lookup'
import DetailPortofolioObligasi from 'components/pages/modal/investasi/portfolio/balance-position/detail/detailObligasi'
import httpRequest from 'plugin/httprequest'

class DataGridSBN extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {  
                displayFormat: 'Total',
                showInColumn : 'tanggalMaturity'
            },
            {
                name : 'faceValue',
                column: 'faceValue',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'komposisi',
                column: 'komposisi',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        onClick: (e) => {
                            this.showDetail(e.row.data)
                        }
                    }
                ]
                
            },
            {
                dataField: 'no.',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'kodeSbn',
                caption: 'Kode',
            },
            {
                dataField: 'namaSbn',
                caption: 'Nama SBN',
            },
            {
                dataField: 'jenisSbn',
                caption: 'jenis',
            },
            {
                dataField: 'tglPembelian',
                caption: 'Tanggal Pembelian',
                dataType: 'date',  
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'periodeKupon',
                caption: 'Kupon Periode',
            },
            {
                dataField: 'tglMaturity',
                caption: 'Tanggal Maturity',
                dataType: 'date',  
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'faceValue',
                caption: 'Face Value',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'hargaPembelian',
                caption: 'Harga Pembelian',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.0000'
            },
            {
                dataField: 'nilaiPerolehan',
                caption: 'Nilai Perolehan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rate',
                caption: 'Rate',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'bungaBerjalan',
                caption: 'Bunga Berjalan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'netProceed',
                caption: 'Net Proceed',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'htm',
                caption: 'HTM',
                lookup: {
                    dataSource: [
                        {
                            id: true,
                            value: 'HTM'
                        },
                        {
                            id: false,
                            value: 'AFS'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'totalPercentKomposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'decimal',
            },
        ]

        this.modalRef = React.createRef()
    }
    showDetail = async (data) => {
        if (data) {
            var dataEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-instrumentId/${data.instrumentId}`, 'GET')
            var dataBalanceBuyDate = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/get-list-detail-obligasi/${data.instrumentId}/${dataEfekBalance[0].productId}/${dataEfekBalance[0].kpdId}`, 'GET')
            var result = []
            for (var detail of dataBalanceBuyDate) {
                var customData = {
                    buyDate: detail.buyDate,
                    buyPrice: detail.buyPrice,
                    faceValue: detail.faceValue,
                    htm: detail.htm,
                    instrumentCode: detail.instrumentCode,
                    instrumentId: detail.instrumentId,
                    instrumentName: detail.instrumentName,
                    nilaiPasar: detail.nilaiPasar,
                    nilaiPerolehan: detail.nilaiPerolehan,
                    price: detail.price,
                    priceDate: detail.priceDate,
                    selisih: detail.selisih,
                }
                result.push(customData)
            }
            this.modalRef.current.show(result)
        }
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    keyField='kodeSbn'
                    loadAPI={`efek-balance-avgs/instrument-portofolio-sbn?productId=${this.props.productId}`}
                    insertAPI='kas-banks'
                    updateAPI='kas-banks'
                    deleteAPI='kas-banks' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Portofolio SBN"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio SBN'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    onToolbarPreparing = {this.onToolbarPreparing}

                    height = {'calc(100vh - 336px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <DetailPortofolioObligasi 
                    ref={this.modalRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default DataGridSBN
