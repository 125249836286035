import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from 'devextreme/ui/notify';
import { jenisIdentitas, tax, gender, genderHrd, cities, statusKaryawanSdm, statusPenghasilan, statusKeluarga, countries, provinces, educationBackground, grade, maritalStatus, jabatan, branch, SKTypeHRD, relations, banks, currency, statusKaryawan, programs, provincesFilter, citiesFilter, kecamatansFilter, kelurahanFilter, kecamatans, kelurahans, karyawan, sdmUnit, statusPajak, statusKepegawaianSdm, statusPajakSdm, agama, users, statusPeserta, statusPesertaHrd, unit, branchSDM } from 'dataSource/lookup';
import ModalSkKaryawan from './file/modalSkKaryawan';
import { formatDate, templateCustomStoreLookup } from 'plugin/helper'
import httpRequest from 'plugin/httprequest';
import ScrollView from 'devextreme-react/scroll-view';
import { alert } from 'devextreme/ui/dialog'
import { showLoading } from "redux/actions/loading";

class FormDaftarKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            dataPersonal: {},
            dataKepegawaian: {
                unitId: null,
                gajiPokok: 0
            },
            dataGridKepegawaian: {},
            dataKeluarga: {},
            newData: true
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.readOnly = ''
        this.PopupRef = React.createRef()
        this.modalSkKaryawanRef = React.createRef()
        this.DataGridSkKarRef = React.createRef()
        this.DataGridKarkelRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formRef = React.createRef()
        this.formKepegawaianRef = React.createRef()
        this.units = [];

        this.systemDate = props.store.getState().getParam ? (props.store.getState().getParam || []).find(value => value.paramCode === '0 SYSDATE') : null

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async (e) => {
                        var formValidationM = this.validateFormMaster()
                        var formValidationP = this.validateFormPersonal()
                        var formValidationK = this.validateFormKepegawaian()

                        if (!formValidationM) {
                            await alert("Terdapat Format Data yang salah", "Informasi")
                        } else if (!formValidationP) {
                            await alert("Terdapat Format Data yang salah", "Informasi")
                        } else if (!formValidationK) {
                            await alert("Terdapat Format Data yang salah", "Informasi")
                        } else {
                            this.submitData()
                        }

                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.PopupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async (e) => {
                        var formValidationM = this.validateFormMaster()
                        var formValidationP = this.validateFormPersonal()
                        var formValidationK = this.validateFormKepegawaian()
                        if (formValidationM && formValidationP && formValidationK) {
                            this.updateData()
                        } else {
                            await alert("Terdapat Data yang salah", "Informasi")
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Personal'
            },
            {
                id: 1,
                text: 'Data Kepegawaian'
            },
            {
                id: 2,
                text: 'Data Keluarga'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'programId',
                label: {
                    alignment: 'left',
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                },
            },
            {
                name: 'empty1',
                itemType: 'empty',
            },
            {
                name: 'empty1',
                itemType: 'empty',
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
                editorType: "dxTextBox",
                editorOptions: {
                    maxLength: 16
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'NIK tidak boleh kosong'
                    },
                ]
            },
            {
                dataField: 'nikPanjang',
                label: {
                    alignment: 'left',
                    text: 'NIK Panjang'
                },
                editorType: "dxTextBox",
                editorOptions: {
                    maxLength: 16
                },
                // validationRules: [
                //     {
                //         type: 'required',
                //         message: 'NIK Panjang tidak boleh kosong'
                //     },
                //     // {
                //     //     type: 'pattern',
                //     //     pattern: RegExp('^[^a-zA-Z]+$','g'),
                //     //     message: 'NIK tidak valid karena mengandung huruf'
                //     // },
                // ]
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                },
                editorType: "dxTextBox",
                editorOptions: {
                    maxLength: 50
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Nama tidak boleh kosong'
                    },
                ]
            },
        ]

        this.dataPersonal = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'birthPlace',
                        label: {
                            alignment: 'left',
                            text: 'Tempat Lahir'
                        },
                    },
                    {
                        dataField: 'birthDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Lahir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            displayFormat: "dd MMM yyyy",
                            width: '100%',
                            onValueChanged: (data) => {
                                let tanggalLahir = data.value
                                tanggalLahir = tanggalLahir ? formatDate(tanggalLahir).split('-') : null

                                let systemDate = this.systemDate.dateValue
                                systemDate = systemDate ? systemDate.split('-') : null

                                let usiaTahun = systemDate && tanggalLahir ? systemDate[0] - tanggalLahir[0] : null
                                this.formRef.current.instance.updateData({
                                    'usiaTahun': usiaTahun ? usiaTahun + " Tahun" : usiaTahun,
                                })
                            }
                        }
                    },
                    {
                        dataField: 'usiaTahun',
                        label: {
                            alignment: 'left',
                            text: 'Usia'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'sexId',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Kelamin'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: genderHrd(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                    },
                    {
                        dataField: 'religionId',
                        label: {
                            alignment: 'left',
                            text: 'Agama'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: agama(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'email',
                        label: {
                            alignment: 'left',
                            text: 'Email'
                        },
                    },
                    {
                        dataField: 'phoneNo',
                        label: {
                            alignment: 'left',
                            text: 'No Telephone'
                        },
                        editorType: "dxTextBox",
                        editorOptions: {
                            maxLength: 12
                        },
                        validationRules: [
                            {
                                type: 'pattern',
                                pattern: /^\(?([0-9]{0,12})\)?$/,
                                message: 'No Telepon tidak valid karena mengandung huruf'
                            },
                        ]
                    },
                    {
                        dataField: 'mobileNo',
                        label: {
                            alignment: 'left',
                            text: 'No HP'
                        },
                        editorType: "dxTextBox",
                        editorOptions: {
                            maxLength: 14
                        },
                        validationRules: [
                            {
                                type: 'pattern',
                                pattern: /^\(?([0-9]{0,14})\)?$/,
                                message: 'No HP tidak valid karena mengandung huruf'
                            },
                        ]
                    },
                    {
                        dataField: 'merriedId',
                        label: {
                            alignment: 'left',
                            text: 'Status Kawin'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: maritalStatus(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },

                    {
                        dataField: 'statusPajakId',
                        label: {
                            alignment: 'left',
                            text: 'Status Pajak'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: statusPajakSdm(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'kode', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Field ini harus diisi'
                            }
                        ]
                    },
                    {
                        dataField: 'taxNo',
                        label: {
                            alignment: 'left',
                            text: 'NPWP'
                        },
                        editorType: "dxTextBox",
                        editorOptions: {
                            maxLength: 16
                        },
                    },
                    {
                        dataField: 'noBpjsKesehatan',
                        label: {
                            alignment: 'left',
                            text: 'No BPJS Kesehatan'
                        },
                        editorType: "dxTextBox",
                    },
                    {
                        dataField: 'noBpjsKetenagakerjaan',
                        label: {
                            alignment: 'left',
                            text: 'No BPJS Ketenagakerjaan'
                        },
                        editorType: "dxTextBox",
                    },
                    {
                        dataField: 'educationId',
                        label: {
                            alignment: 'left',
                            text: 'Pendidikan Terakhir'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: educationBackground(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'educationName', // provides display values'
                        },
                    },
                    {
                        dataField: 'schoolName',
                        label: {
                            alignment: 'left',
                            text: 'Sekolah / Universitas'
                        },
                    },
                    {
                        dataField: 'fingerPrintCode',
                        label: {
                            alignment: 'left',
                            text: 'Kode Fingerprint'
                        },
                    },
                    {
                        dataField: 'bloodType',
                        label: {
                            alignment: 'left',
                            text: 'Golongan Darah'
                        },
                    },
                    {
                        dataField: 'motherName',
                        label: {
                            alignment: 'left',
                            text: 'Nama Ibu Kandung'
                        },
                    },
                    {
                        itemType: 'group',
                        caption: 'Identitas',
                        items: [
                            {
                                dataField: 'identityId',
                                label: {
                                    alignment: 'left',
                                    text: 'Jenis Identitas'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: jenisIdentitas(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false
                                },
                            },
                            {
                                dataField: 'identityNo',
                                label: {
                                    alignment: 'left',
                                    text: 'No Identitas'
                                },
                                editorType: "dxTextBox",
                                editorOptions: {
                                    maxLength: 16
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'No Identitas tidak boleh kosong'
                                    },
                                    {
                                        type: "pattern",
                                        pattern: /^\(?([0-9]{0,16})\)?$/,
                                        message: "No KTP harus berbentuk angka dan 16 digit"
                                    }
                                ]
                            },
                            {
                                dataField: 'identityExpired',
                                label: {
                                    alignment: 'left',
                                    text: 'Masa Berlaku Identitas'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    openOnFieldClick: true,
                                    useMaskBehavior: true,
                                    showClearButton: true,
                                    width: '100%',
                                    displayFormat: "dd MMM yyyy"
                                }
                            },
                            {
                                dataField: "ktpLifetime",
                                label: {
                                    alignment: "left",
                                    text: "Selamanya"
                                },
                                editorType: "dxCheckBox",
                                editorOptions: {
                                    onValueChanged: e => {
                                        // var fieldKtpExpDate = this.formRef.current.instance.itemOption(
                                        //     "identityExpired"
                                        // );

                                        if (e.value) {
                                            this.formRef.current.instance.updateData({
                                                identityExpired: new Date("12/31/9999"),
                                                ktpLifetime: e.value
                                            })
                                            // fieldKtpExpDate.editorOptions.readOnly = true;
                                        } else {
                                            // fieldKtpExpDate.editorOptions.readOnly = false;
                                        }
                                    },
                                    elementAttr: {
                                        class: "float-left"
                                    }
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Rekening Bank',
                        items: [
                            {
                                dataField: 'gajiRekNo',
                                label: {
                                    alignment: 'left',
                                    text: 'No Rekening'
                                },
                                // editorType: 'dxNumberBox',
                            },
                            {
                                dataField: 'gajiRekName',
                                label: {
                                    alignment: 'left',
                                    text: 'Nama Rekening'
                                },
                            },
                            {
                                dataField: 'gajiRekBankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            },
                            {
                                dataField: 'gajiRekBranch',
                                label: {
                                    alignment: 'left',
                                    text: 'Cabang Bank'
                                },
                            },
                        ]
                    }
                ],
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Alamat Identitas',
                        items: [
                            {
                                dataField: 'identityAddress',
                                label: {
                                    alignment: 'left',
                                    text: 'Alamat Identitas'
                                }
                            },
                            {
                                dataField: 'identityCountryId',
                                label: {
                                    alignment: 'left',
                                    text: 'Negara'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: countries(this.props.store),
                                    value: 130,
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'countryName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectProvince = this.formRef.current.instance.getEditor('identityProvinceId')
                                        // selectProvince.option('value', '')
                                        if (data.value) {
                                            selectProvince.option('dataSource', provincesFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'identityProvinceId',
                                label: {
                                    alignment: 'left',
                                    text: 'Provinsi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: provinces(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'provinceName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectCity = this.formRef.current.instance.getEditor('identityCityId')
                                        // selectCity.option('value', '')
                                        if (data.value) {
                                            selectCity.option('dataSource', citiesFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'identityCityId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kota'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: cities(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'cityName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectKecamatan = this.formRef.current.instance.getEditor('identityKecamatanId')
                                        // selectKecamatan.option('value', '')
                                        if (data.value) {
                                            selectKecamatan.option('dataSource', kecamatansFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'identityKecamatanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kecamatan Identitas'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kecamatans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kecamatanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectKelurahan = this.formRef.current.instance.getEditor('identityKelurahanId')
                                        if (data.value) {
                                            selectKelurahan.option('dataSource', kelurahanFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'identityKelurahanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kelurahan Identitas'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kelurahans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kelurahanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: (data) => {
                                        if (data.selectedItem) {
                                            var inputKodePos = this.formRef.current.instance.getEditor('identityPostalCode')
                                            inputKodePos.option('value', data.selectedItem.kodePos)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'identityPostalCode',
                                label: {
                                    alignment: 'left',
                                    text: 'Kode Pos'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            }
                        ],
                    },
                    {
                        itemType: 'group',
                        caption: 'Alamat Domisili',
                        items: [
                            {
                                dataField: "sameIdentity",
                                label: {
                                    alignment: "left",
                                    text: "Sesuai Alamat Identitas"
                                },
                                editorType: "dxCheckBox",
                                editorOptions: {
                                    onValueChanged: e => {
                                        if (e.value) {
                                            this.formRef.current.instance.updateData({
                                                domicileAddress: this.state.dataPersonal.identityAddress,
                                                domicileCountryId: this.state.dataPersonal.identityCountryId,
                                                domicileProvinceId: this.state.dataPersonal.identityProvinceId,
                                                domicileCityId: this.state.dataPersonal.identityCityId,
                                                domicileKecamatanId: this.state.dataPersonal.identityKecamatanId,
                                                domicileKelurahanId: this.state.dataPersonal.identityKelurahanId,
                                                domicilePostalCode: this.state.dataPersonal.identityPostalCode,
                                                sameIdentity: e.value
                                            })
                                            // fieldKtpExpDate.editorOptions.readOnly = true;
                                        } else {
                                            // fieldKtpExpDate.editorOptions.readOnly = false;
                                        }
                                    },
                                    elementAttr: {
                                        class: "float-left"
                                    }
                                }
                            },
                            {
                                dataField: 'domicileAddress',
                                label: {
                                    alignment: 'left',
                                    text: 'Alamat Domisili'
                                },
                            },
                            {
                                dataField: 'domicileCountryId',
                                label: {
                                    alignment: 'left',
                                    text: 'Negara'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: countries(this.props.store),
                                    value: 130,
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'countryName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectProvince = this.formRef.current.instance.getEditor('domicileProvinceId')
                                        // selectProvince.option('value', '')
                                        if (data.value) {
                                            selectProvince.option('dataSource', provincesFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'domicileProvinceId',
                                label: {
                                    alignment: 'left',
                                    text: 'Provinsi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: provinces(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'provinceName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectCity = this.formRef.current.instance.getEditor('domicileCityId')
                                        // selectCity.option('value', '')
                                        if (data.value) {
                                            selectCity.option('dataSource', citiesFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'domicileCityId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kota'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: cities(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'cityName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectKecamatan = this.formRef.current.instance.getEditor('domicileKecamatanId')
                                        // selectKecamatan.option('value', '')
                                        if (data.value) {
                                            selectKecamatan.option('dataSource', kecamatansFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'domicileKecamatanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kecamatan Domisili'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kecamatans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kecamatanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectKelurahan = this.formRef.current.instance.getEditor('domicileKelurahanId')
                                        if (data.value) {
                                            selectKelurahan.option('dataSource', kelurahanFilter(this.props.store, data.value, true))
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'domicileKelurahanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kelurahan Domisili'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kelurahans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kelurahanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: (data) => {
                                        if (data.selectedItem) {
                                            var inputKodePos = this.formRef.current.instance.getEditor('domicilePostalCode')
                                            inputKodePos.option('value', data.selectedItem.kodePos)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'domicilePostalCode',
                                label: {
                                    alignment: 'left',
                                    text: 'Kode Pos'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            }
                        ],
                    },

                ],
            },


        ]

        this.dataKepegawaian = [
            {
                dataField: 'workingDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Mulai Kerja'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                    displayFormat: "dd MMM yyyy",
                    width: '100%',
                    onValueChanged: (data) => {
                        let workingDate = data.value
                        workingDate = workingDate ? formatDate(workingDate).split('-') : null

                        let systemDate = this.systemDate.dateValue
                        systemDate = systemDate ? systemDate.split('-') : null

                        let masaKerja = systemDate && workingDate ? systemDate[0] - workingDate[0] : null
                        this.formKepegawaianRef.current.instance.updateData({
                            'masaKerja': masaKerja,
                        })
                    }
                }
            },
            {
                dataField: 'masaKerja',
                label: {
                    alignment: 'left',
                    text: 'Masa Kerja'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'supervisorId',
                label: {
                    alignment: 'left',
                    text: 'Atasan Langsung'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'fullName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'jabatanId',
                label: {
                    alignment: 'left',
                    text: 'Jabatan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'unitId',
                label: {
                    alignment: 'left',
                    text: 'Unit Kerja'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                // validationRules: [
                //     {
                //         type: 'required',
                //         message: 'Unit Kerja Tidak Boleh Kosong'
                //     },
                // ]
            },
            {
                dataField: 'branchId',
                label: {
                    alignment: 'left',
                    text: 'Cabang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: branchSDM(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'branchName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            // {
            //     dataField: 'title',
            //     label: {
            //         alignment: 'left',
            //         text: 'Title'
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         dataSource: titles(this.props.store),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'title', // provides display values
            //     },
            // },
            {
                dataField: 'gradeId',
                label: {
                    alignment: 'left',
                    text: 'Grade'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: grade(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'gradeCode', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'upahDasar',
                label: {
                    alignment: 'left',
                    text: 'Upah Dasar'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    onValueChanged: (e) => {
                        if (e.value >= 0) {
                            let upahDasar = e.value;
                            let gajiPokokState = this.state.dataKepegawaian.gajiPokok;
                            let tunjanganTetap = this.state.dataKepegawaian.tunjanganTetap;
                            
                            let gajiPokok = upahDasar + gajiPokokState + tunjanganTetap;
                            this.formKepegawaianRef.current.instance.updateData({ gajiPokok: gajiPokok })
                        }
                    }
                },
                validationRules: [
                    {
                        type: 'custom',
                        validationCallback: (e) => {
                            if (e.value < 0) {
                                return false
                            }
                            return true
                        },
                        message: 'Upah Dasar tidak boleh minus'
                    },
                    {
                        type: 'required',
                        message: 'Upah Dasar tidak boleh kosong'
                    },
                ]
            },
            {
                dataField: 'tunjanganTetap',
                label: {
                    alignment: 'left',
                    text: 'Tunjangan Tetap'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    onValueChanged: (e) => {
                        if (e.value >= 0) {
                            let tunjanganTetap = e.value;
                            // let gajiPokokState = this.state.dataKepegawaian.gajiPokok;
                            let upahDasar = this.state.dataKepegawaian.upahDasar;
                            let tunjanganTidakTetap = this.state.dataKepegawaian.tunjanganTidakTetap;

                            let gajiPokok = tunjanganTetap + upahDasar;

                            if (gajiPokok === 0) {
                                this.formKepegawaianRef.current.instance.updateData({ gajiPokok: tunjanganTidakTetap })
                            } else {
                                this.formKepegawaianRef.current.instance.updateData({ gajiPokok: gajiPokok })
                            }
                            // this.formKepegawaianRef.current.instance.getEditor('gajiPokok').option('value', gajiPokok);
                        }
                    }
                },
                validationRules: [
                    {
                        type: 'custom',
                        validationCallback: (e) => {
                            if (e.value < 0) {
                                return false
                            }
                            return true
                        },
                        message: 'Insentif tidak boleh minus'
                    },
                    {
                        type: 'required',
                        message: 'Insentif tidak boleh kosong'
                    },
                ]
            },
            {
                dataField: 'tunjanganTidakTetap',
                label: {
                    alignment: 'left',
                    text: 'Tunjangan Tidak Tetap'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    // readOnly: true,
                    onValueChanged: async (e) => {
                        if (e.value >= 0) {
                            let tunjanganTidakTetap = e.value;
                            let upahDasar = this.formKepegawaianRef.current.instance.getEditor('upahDasar').option('value');
                            let tunjanganTetap = this.formKepegawaianRef.current.instance.getEditor('tunjanganTetap').option('value');

                            let jumlah = tunjanganTidakTetap + upahDasar + tunjanganTetap;
                            this.formKepegawaianRef.current.instance.updateData({gajiPokok: jumlah})
                        }
                    }
                },
            },
            {
                dataField: 'gajiPokok',
                label: {
                    alignment: 'left',
                    text: 'Gaji Pokok'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    readOnly: true,
               },
                validationRules: [
                    {
                        type: 'custom',
                        validationCallback: (e) => {
                            if (e.value < 0) {
                                return false
                            }
                            return true
                        },
                        message: 'Gaji Pokok tidak boleh minus'
                    },
                ]
            },
            {
                dataField: 'phdp',
                label: {
                    alignment: 'left',
                    text: 'PHDP'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00"
                },
                validationRules: [
                    {
                        type: 'custom',
                        validationCallback: (e) => {
                            if (e.value > this.state.dataKepegawaian.gajiPokok) {
                                return false
                            }
                            return true
                        },
                        message: 'PHDP tidak boleh lebih besar dari Gaji Pokok'
                    },
                    {
                        type: 'custom',
                        validationCallback: (e) => {
                            if (e.value < 0) {
                                return false
                            }
                            return true
                        },
                        message: 'PHDP tidak boleh minus'
                    },
                    {
                        type: 'required',
                        message: 'PHDP tidak boleh kosong'
                    },
                ]
            },
            {
                dataField: 'iuranPensiun',
                label: {
                    alignment: 'left',
                    text: 'Iuran Pensiun'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00"
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Iuran Pensiun tidak boleh kosong'
                    },
                ]
            },
            {
                dataField: 'gajiCurrencyId',
                label: {
                    alignment: 'left',
                    text: 'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'currencyName', // provides display values
                },
            },
            {
                dataField: 'statusKaryawanId',
                label: {
                    alignment: 'left',
                    text: 'Status Karyawan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusKaryawanSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaStatus', // provides display values
                },
            },
            {
                dataField: 'resignDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Keluar'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                    displayFormat: "dd MMM yyyy",
                    width: '100%',
                }
            },
            { 
                dataField: 'statusKepegawaianId',
                label: {
                    alignment: 'left',
                    text: 'Kategori Jabatan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusKepegawaianSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaStatus', // provides display values
                },
            },
            {
                dataField: 'statusPesertaId',
                label: {
                    alignment: 'left',
                    text: 'Status Kepegawaian'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusPesertaHrd(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaStatus', // provides display values
                },
            },
            {
                dataField: 'username',
                label: {
                    alignment: 'left',
                    text: 'Username'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: users(this.props.store, 'login', 'login'),
                    valueExpr: 'login', // contains the same values as the "statusId" field provides
                    displayExpr: 'login', // provides display values
                    searchEnabled: true,
                    deferRendering: true
                },
            },
            {
                dataField: 'keterangan',
                label: {
                    alignment: 'left',
                    text: 'Keterangan'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    width: '100%'
                },
            },
        ]

        // this.dataKepegawaianEdit = [
        //     {
        //         dataField: 'workingDate',
        //         label: {
        //             alignment: 'left',
        //             text: 'Tanggal Mulai Kerja'
        //         },
        //         editorType: 'dxDateBox',
        //         editorOptions: {
        //             openOnFieldClick: true,
        //             useMaskBehavior: true,
        //             displayFormat: "dd MMM yyyy",
        //             width: '100%'
        //         }
        //     },
        //     {
        //         dataField: 'supervisorId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Atasan Langsung'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: karyawan(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'fullName', // provides display values
        //             searchEnabled: true,
        //             deferRendering: false,
        //         },
        //     },
        //     {
        //         dataField: 'jabatanId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Jabatan'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: jabatan(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'jabatanName', // provides display values
        //             searchEnabled: true,
        //             deferRendering: false,
        //         },
        //     },
        //     {
        //         dataField: 'unitId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Unit Kerja'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: sdmUnit(this.props.store),
        //             // dataSource: [],
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'unitName', // provides display values
        //             searchEnabled: true,
        //             deferRendering: false,
        //         },
        //     },
        //     {
        //         dataField: 'branchId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Cabang'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: branch(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'branchName', // provides display values
        //             searchEnabled: true,
        //             deferRendering: false,
        //         },
        //     },
        //     // {
        //     //     dataField: 'title',
        //     //     label: {
        //     //         alignment: 'left',
        //     //         text: 'Title'
        //     //     },
        //     //     editorType: 'dxSelectBox',
        //     //     editorOptions: {
        //     //         dataSource: titles(this.props.store),
        //     //         valueExpr: 'id', // contains the same values as the "statusId" field provides
        //     //         displayExpr: 'title', // provides display values
        //     //     },
        //     // },
        //     {
        //         dataField: 'gradeId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Grade'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: grade(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'gradeCode', // provides display values
        //             searchEnabled: true,
        //             deferRendering: false,
        //         },
        //     },
        //     {
        //         dataField: 'upahDasar',
        //         label: {
        //             alignment: 'left',
        //             text: 'Upah Dasar'
        //         },
        //         editorType: "dxNumberBox",
        //         editorOptions: {
        //             format: "#,##0.00",
        //             // onValueChanged: (e) => {
        //             //     if (e.value) {
        //             //         var gajiPokok = e.value + this.state.dataKepegawaian.intensif
        //             //         this.formKepegawaianRef.current.instance.updateData({ gajiPokok: gajiPokok })
        //             //     }
        //             // },
        //             readOnly: true
        //         },
        //         // validationRules: [
        //         //     {
        //         //         type: 'custom',
        //         //         validationCallback: (e) => {
        //         //             if (e.value < 0) {
        //         //                 return false
        //         //             }
        //         //             return true
        //         //         },
        //         //         message: 'Upah Dasar tidak boleh minus'
        //         //     },
        //         //     {
        //         //         type: 'required',
        //         //         message: 'Upah Dasar tidak boleh kosong'
        //         //     },
        //         // ]
        //     },
        //     {
        //         dataField: 'intensif',
        //         label: {
        //             alignment: 'left',
        //             text: 'Insentif'
        //         },
        //         editorType: "dxNumberBox",
        //         editorOptions: {
        //             format: "#,##0.00",
        //             // onValueChanged: (e) => {
        //             //     if (e.value) {
        //             //         var gajiPokok = this.state.dataKepegawaian.upahDasar + e.value
        //             //         this.formKepegawaianRef.current.instance.updateData({ gajiPokok: gajiPokok })
        //             //     }
        //             // }
        //             readOnly: true
        //         },
        //         // validationRules: [
        //         //     {
        //         //         type: 'custom',
        //         //         validationCallback: (e) => {
        //         //             if (e.value < 0) {
        //         //                 return false
        //         //             }
        //         //             return true
        //         //         },
        //         //         message: 'Insentif tidak boleh minus'
        //         //     },
        //         //     {
        //         //         type: 'required',
        //         //         message: 'Insentif tidak boleh kosong'
        //         //     },
        //         // ]
        //     },
        //     {
        //         dataField: 'gajiPokok',
        //         label: {
        //             alignment: 'left',
        //             text: 'Gaji Pokok'
        //         },
        //         editorType: "dxNumberBox",
        //         editorOptions: {
        //             format: "#,##0.00",
        //             readOnly: true
        //         },
        //         validationRules: [
        //             {
        //                 type: 'custom',
        //                 validationCallback: (e) => {
        //                     if (e.value < 0) {
        //                         return false
        //                     }
        //                     return true
        //                 },
        //                 message: 'Gaji Pokok tidak boleh minus'
        //             },
        //         ]
        //     },
        //     {
        //         dataField: 'phdp',
        //         label: {
        //             alignment: 'left',
        //             text: 'PHDP'
        //         },
        //         editorType: "dxNumberBox",
        //         editorOptions: {
        //             format: "#,##0.00"
        //         },
        //         validationRules: [
        //             {
        //                 type: 'custom',
        //                 validationCallback: (e) => {
        //                     if (e.value > this.state.dataKepegawaian.gajiPokok) {
        //                         return false
        //                     }
        //                     return true
        //                 },
        //                 message: 'PHDP tidak boleh lebih besar dari Gaji Pokok'
        //             },
        //             {
        //                 type: 'custom',
        //                 validationCallback: (e) => {
        //                     if (e.value < 0) {
        //                         return false
        //                     }
        //                     return true
        //                 },
        //                 message: 'PHDP tidak boleh minus'
        //             },
        //             {
        //                 type: 'required',
        //                 message: 'PHDP tidak boleh kosong'
        //             },
        //         ]
        //     },
        //     {
        //         dataField: 'iuranPensiun',
        //         label: {
        //             alignment: 'left',
        //             text: 'Iuran Pensiun'
        //         },
        //         editorType: "dxNumberBox",
        //         editorOptions: {
        //             format: "#,##0.00"
        //         },
        //         validationRules: [
        //             {
        //                 type: 'required',
        //                 message: 'Iuran Pensiun tidak boleh kosong'
        //             },
        //         ]
        //     },
        //     {
        //         dataField: 'gajiCurrencyId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Currency'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: currency(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'currencyName', // provides display values
        //         },
        //     },
        //     {
        //         dataField: 'statusKaryawanId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Status Karyawan'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: statusKaryawanSdm(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'namaStatus', // provides display values
        //         },
        //     },
        //     {
        //         dataField: 'statusKepegawaianId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Status Kepegawaian'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: statusKepegawaianSdm(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'namaStatus', // provides display values
        //         },
        //     },
        //     {
        //         dataField: 'statusPesertaId',
        //         label: {
        //             alignment: 'left',
        //             text: 'Status Peserta'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: statusPesertaHrd(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'namaStatus', // provides display values
        //         },
        //     },
        //     {
        //         dataField: 'username',
        //         label: {
        //             alignment: 'left',
        //             text: 'Username'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: users(this.props.store, 'login', 'login'),
        //             valueExpr: 'login', // contains the same values as the "statusId" field provides
        //             displayExpr: 'login', // provides display values
        //             searchEnabled: true,
        //             deferRendering: true
        //         },
        //     },
        // ]

        this.dataGridSK = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: this.showModalInsertSkKaryawan.bind(this)
                    },
                ],
            },
            {
                dataField: 'skNo',
                caption: 'No. SK',
            },
            {
                dataField: 'skTypeId',
                caption: 'Jenis SK',
                lookup: {
                    dataSource: SKTypeHRD(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'typeName'
                }
            },
            {
                dataField: 'skDate',
                caption: 'Tanggal SK',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'efektifDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]

        this.dataKeluarga = [
            {
                dataField: 'namaLengkap',
                caption: 'Nama',
            }, {
                dataField: 'relationId',
                caption: 'Hubungan Keluarga',
                lookup: {
                    dataSource: relations(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'relationName'
                }
            }, {
                dataField: 'tanggalLahir',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: "dd MMM yyyy",
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                    displayFormat: "dd MMM yyyy",
                }
            }, {
                dataField: 'sexId',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: genderHrd(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value'
                },
                editorOptions: {
                    dataSource: genderHrd(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            }, {
                dataField: 'noKtp',
                caption: 'No KTP'
            },
            // {
            //     dataField: 'educationId',
            //     caption: 'Status Pendidikan',
            //     lookup:{
            //         dataSource: educationBackground(this.props.store),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'educationName' 
            //     }
            // },
            {
                dataField: 'statusPenghasilan',
                caption: 'Status Penghasilan',
                lookup: {
                    dataSource: statusPenghasilan(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'statusKawin',
                caption: 'Status Kawin',
                lookup: {
                    dataSource: statusKeluarga(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value'
                }
            },
        ]
    }

    // lookUp = async (data) => {
    //     var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${data.id}`, 'GET')
    //     // var units = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `units?size=9999`, 'GET')
    //     // units = units.filter(value => value.id === response.unitId)

    //     var loadUnit = 'units?size=9999';
    //     var unitLookUp = templateCustomStoreLookup(loadUnit, this.props.store, process.env.REACT_APP_BACKEND_HRD);

    //     this.formKepegawaianRef.current.instance.getEditor('unitId').option('dataSource', unitLookUp);
    // }

    get Popup() {
        return this.PopupRef.current.instance
    }

    validateFormMaster = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    validateFormPersonal = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    validateFormKepegawaian = () => {
        var formValidation = this.formKepegawaianRef.current.instance.validate();
        return formValidation.isValid
    }

    newData = (newData) => {
        this.setState({
            newData: newData
        })
    }

    cleanDataGrid = () => {
        var data = this.DataGridKarkelRef.current.getDataSource()
        var deleteData = []
        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
        }
        this.DataGridKarkelRef.current.push(deleteData)
        // this.DataGridKarkelRef.current.emptyDeletedIndex()
        // this.retrieveData({},false)
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
        });
    }

    hide() {
        // this.cleanDataGrid()

        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataPersonal: {},
            dataKepegawaian: {
                gajiPokok: 0
            },
            dataGridKepegawaian: {},
            dataKeluarga: {},
            newData: true
        })

        this.props.forceRefresh()
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (data, readOnly) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${data.id}`, 'GET')
        var units = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `units?size=9999`, 'GET')
        this.readOnly = readOnly
        this.setState({
            dataMaster: response,
            dataPersonal: response,
            dataKepegawaian: response
        })
        
        if(data.identityExpired === "9999-12-31") {
            this.formRef.current.instance.updateData({
                ktpLifetime: true
            })
        }
        
        if(data.domicileAddress === data.identityAddress && data.domicileCountryId === data.identityCountryId && data.domicileProvinceId === data.identityProvinceId && data.domicileCityId === data.identityCityId && data.domicileKecamatanId === data.identityKecamatanId && data.domicileKelurahanId === data.identityKelurahanId && data.domicilePostalCode === data.identityPostalCode) {
            this.formRef.current.instance.updateData({
                sameIdentity: true
            })
        }
        let tanggalLahir = this.state.dataPersonal.birthDate
        tanggalLahir = tanggalLahir ? tanggalLahir.split('-') : null

        let systemDate = this.systemDate.dateValue
        systemDate = systemDate ? systemDate.split('-') : null

        let workingDate = this.state.dataKepegawaian.workingDate
        workingDate = workingDate ? workingDate.split('-') : null

        let usiaTahun = systemDate && tanggalLahir ? systemDate[0] - tanggalLahir[0] : null
        let masaKerja = systemDate && workingDate ? systemDate[0] - workingDate[0] : null
        this.formRef.current.instance.updateData({
            'usiaTahun': usiaTahun ? usiaTahun + " Tahun" : usiaTahun,
        })
        this.formKepegawaianRef.current.instance.updateData({
            'masaKerja': masaKerja
        })
        this.DataGridKarkelRef.current.forceRefresh(true)
    }

    submitData = async () => {
        try {
            this.props.store.dispatch(showLoading(true))
            var insertAPIKaryawan = 'karyawans'
            var dataPersonal = { ...this.state.dataMaster, ...this.state.dataPersonal, ...this.state.dataKepegawaian }

            var dataKerkel = this.DataGridKarkelRef.current.getDataSource()
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, insertAPIKaryawan, 'POST', {
                values: dataPersonal
            })
            if (response.id) {
                try {

                    for (var karKelArray of dataKerkel) {
                        if (typeof karKelArray.id === "string") {
                            delete karKelArray.id
                            await this.addDataKaryawanKeluarga(karKelArray, response.id)
                        } else {
                            await this.editDateKaryawanKeluarga(karKelArray)
                        }
                    }
                    var getDeletedKK = this.DataGridKarkelRef.current.getDeletedIndex()
                    for (var dataDelete of getDeletedKK) {
                        await this.deleteDataKaryawanKeluarga(dataDelete)
                    }
                } catch (e) {
                    console.log(e)
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }

                if (response) {

                    this.props.store.dispatch(showLoading(false))
                    notify({ message: "Sukses Input Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                    this.props.forceRefresh()
                    this.hide()
                }
            }
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            console.log(e)
        }
    }

    updateData = async () => {
        try {
            this.props.store.dispatch(showLoading(false))
            var insertAPIKaryawan = 'karyawans'
            var dataPersonal = { ...this.state.dataMaster, ...this.state.dataPersonal, ...this.state.dataKepegawaian }
            var dataKerkel = this.DataGridKarkelRef.current.getDataSource()

            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, insertAPIKaryawan, 'PUT', {
                values: dataPersonal,
                key: dataPersonal.id
            }, dataPersonal)

            if (response.id) {

                for (var karKelArray of dataKerkel) {
                    if (typeof karKelArray.id === "string") {
                        const dataKelInsert = { ...karKelArray }
                        delete dataKelInsert.id
                        this.addDataKaryawanKeluarga(dataKelInsert, response.id)
                    } else {
                        this.editDateKaryawanKeluarga(karKelArray)
                    }
                }

                var getDeletedKK = this.DataGridKarkelRef.current.getDeletedIndex()
                for (var dataDelete of getDeletedKK) {
                    this.deleteDataKaryawanKeluarga(dataDelete)
                }

                if (response) {
                    this.props.store.dispatch(showLoading(false))
                    notify({ message: "Sukses Update Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                    this.props.forceRefresh()
                    this.hide()
                }
            }
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            console.log(e)
        }
    }

    retriveDataKaryawanKeluarga = async () => {
        if (this.state.dataMaster.id) {
            var dataKK = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawan-keluargas/getAllByKaryawanId/' + this.state.dataMaster.id)

        } else {
            var dataKK = []
        }
        return dataKK
    }

    showModalInsertSkKaryawan = (e) => {
        if (e.row) {
            this.modalSkKaryawanRef.current.retrieveData(e.row.data)
        }
        this.modalSkKaryawanRef.current.show()
    }

    addDataKaryawanKeluarga = async (data, karyawanId) => {
        var loadAPI = `karyawan-keluargas`
        var dataKK = {
            karyawanId: karyawanId,
            namaLengkap: data.namaLengkap,
            noKtp: data.noKtp,
            relationId: data.relationId,
            sexId: data.sexId,
            educationId: data.educationId,
            tanggalLahir: data.tanggalLahir,
            statusPenghasilan: data.statusPenghasilan,
            statusKawin: data.statusKawin
        }
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: dataKK,
        })

        // if (response) {
        //     this.hide()
        //     notify({ message: "Sukses Tambah Keluarga", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
        // } else {
        //     notify({ message: "Error", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        // }
    }

    editDateKaryawanKeluarga = async (data) => {
        var loadAPI = `karyawan-keluargas`

        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
            values: data,
            key: data.id
        }, data)

        // if (response) {
        //     this.hide()
        //     notify({ message: "Sukses Update Keluarga", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
        // } else {
        //     notify({ message: "Error", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        // }
    }

    deleteDataKaryawanKeluarga = async (data) => {
        var loadAPI = `karyawan-keluargas`

        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE', data)
        this.hide()
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Data Karyawan'}
                minWidth={700}
                minHeight={700}

                ref={this.PopupRef}

                toolbarItems={this.readOnly ? [] : this.state.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{ height: '100%' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formMasterRef}
                                    colCount={3}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation='left'
                                    readOnly={this.readOnly}
                                />
                            </div>
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                            <div id="tabindex-0" className={' mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(50vh)' }}>
                                <Form
                                    ref={this.formRef}
                                    colCount={2}
                                    id={'formDataPersonal'}
                                    formData={this.state.dataPersonal}
                                    items={this.dataPersonal}
                                    labelLocation='left'
                                    readOnly={this.readOnly}
                                />
                            </div>
                            <div id="tabindex-1" className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(50vh)' }}>
                                <Form
                                    ref={this.formKepegawaianRef}
                                    colCount={1}
                                    id={'formDataKepegawaian1'}
                                    formData={this.state.dataKepegawaian}
                                    items={this.dataKepegawaian}
                                    labelLocation='left'
                                    readOnly={this.readOnly}
                                />
                            </div>
                            <div id="tabindex-2" className={'d-none mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(50vh)' }}>
                                <DevExpressDataGrid
                                    ref={this.DataGridKarkelRef}
                                    loadAPI='karyawan-keluargas'
                                    insertAPI='karyawan-keluargas'
                                    updateAPI='karyawan-keluargas'
                                    deleteAPI='karyawan-keluargas'

                                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                                    useArraySource={true}
                                    ArraySourceData={this.retriveDataKaryawanKeluarga}
                                    allowAdding={this.readOnly ? false : true}
                                    allowDeleting={this.readOnly ? false : true}
                                    allowUpdating={this.readOnly ? false : true}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    editingMode={'popup'}
                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Keluarga'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={400} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form
                                    height={400}
                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.dataKeluarga} // taruh konfigurasi kolom disini
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormDaftarKaryawan