import React, { Component } from 'react'
import { Form } from 'devextreme-react';
import { months, product, mpType } from 'dataSource/lookup';
import Popup from 'devextreme-react/popup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { showLoading } from 'redux/actions/loading';
import ScrollView from 'devextreme-react/scroll-view';

class FormModalDetailMpSusulan extends Component {
    constructor(props) {
        super(props);

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            redirectBack: false,
            uploadedData: [],
            // selectedTabIndex: 0,
            useHeader: false,
            formData: {},
            popupVisible: false,
        }

        this.prevTabIndex = 0

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'no'
            },
            {
                column: 'mpNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'mpNominal',
                displayFormat: '{0}'
            },
            {
                column: 'mpNominalPengajuan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'mpNominalPengajuan',
                displayFormat: '{0}'
            },
            {
                name: 'nominalPajak',
                column: 'nominalPajak',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nominalPembayaran',
                column: 'nominalPembayaran',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                dataField: 'no',
                caption: 'No.',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'mpRekeningName',
                caption: 'Nama Penerima',
            },
            {
                dataField: 'bulan',
                caption: 'Bulan MP',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Tahun MP',
            },
            {
                dataField: 'mpNominal',
                caption: 'MP Bruto',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'mpNominalPengajuan',
                caption: 'Nominal Susulan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalPajak',
                caption: 'Pajak',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalPembayaran',
                caption: 'Jumlah Bayar',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'susulanThp',
                caption: 'Susulan Thp',
                alignment: 'right',
            },
            {
                dataField: 'bankName',
                caption: 'Nama Bank',
            },
            {
                dataField: 'mpRekeningNo',
                caption: 'No Rekening',
                alignment: 'right',
            },
            // {
            //     dataField: 'jumlahKeseluruhan',
            //     caption: 'Jml',
            // },
        ]

        this.formItem = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location: 'left',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    displayExpr: function (item) {
                        return item && item.productCode + " - " + item.productName;
                    },
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'mpTypeId',
                label: {
                    text: 'Tipe MP'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: mpType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "mpName", // provides display values
                }
            },
            {
                dataField: 'dasarNilaiMp',
                label: {
                    text: 'Dasar Nilai MP'
                },
                editorOptions: {
                    value: 'Nominal',
                    readOnly: true
                },
            },
            {
                dataField: 'jumlahPensiunan',
                label: {
                    text: 'Presentasi MP Bulanan'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
            {
                dataField: 'totalNominal',
                label: {
                    text: 'Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
            {
                dataField: 'requestDate',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'paymentDate',
                label: {
                    text: 'Tanggal Bayar'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'description',
                label: {
                    text: 'Keterangan'
                }
            },
        ]

        this.fileUploderRef = React.createRef()
        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()
        this.formRef = React.createRef()
        this.dataDetails = []

    }

    checkMonth = (monthString) => {
        var dataBulan = [
            {
                id: 1,
                value: 'Januari'
            },
            {
                id: 2,
                value: 'Februari'
            },
            {
                id: 3,
                value: 'Maret'
            },
            {
                id: 4,
                value: 'April'
            },
            {
                id: 5,
                value: 'Mei'
            },
            {
                id: 6,
                value: 'Juni'
            },
            {
                id: 7,
                value: 'Juli'
            },
            {
                id: 8,
                value: 'Agustus'
            },
            {
                id: 9,
                value: 'September'
            },
            {
                id: 10,
                value: 'Oktober'
            },
            {
                id: 11,
                value: 'November'
            },
            {
                id: 12,
                value: 'Desember'
            },
        ]

        for (var data of dataBulan) {
            if (data.value === monthString) {
                return data.id
            }
        }
    }

    retrieveData = async (data) => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        
        let mpSusulanMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mps/${data.pembayaranMpId}`, 'GET')
        let mpSusulanDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-detils/list-peserta-by-mp-id/${data.pembayaranMpId}`, 'GET')

        var formData = { ...data }
        formData.requestDate = mpSusulanMaster.requestDate
        formData.dasarNilaiMp = 'Nominal'

        for (let data of mpSusulanDetil) {
            // let getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/${data.pesertaId}`, 'GET')
            data.nik = data.nik
            data.bulan = mpSusulanMaster.bulan
            data.tahun = mpSusulanMaster.tahun
            data.susulanThp = mpSusulanMaster.periodeBulan
            // data.nominalPembayaran = mpSusulanDetil.nominalPembayaran
            this.dataDetails.push(data)
        }

        this.setState({
            formData: formData
        })
        store.dispatch(showLoading(false))
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            redirectBack: false,
            uploadedData: [],
            // selectedTabIndex: 0,
            useHeader: false,
            formData: {},
            popupVisible: false,
        });
        this.dataDetails = []
        this.forceRefresh()
    };

    loadData = () => {
        return this.dataDetails
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    };

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Detail Pembayaran Manfaat Pensiun Pembayaran Susulan'}
                    minWidth={'70vh'}
                    minHeight={'50vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'form'}
                                        formData={this.state.formData}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation={'left'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={' mt-2 text-center'}>
                                <DevExpressDataGrid
                                    ref={this.dataGridRef}
                                    keyField={''}
                                    loadAPI=''
                                    insertAPI=''
                                    updateAPI=''
                                    deleteAPI=''

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                                    useArraySource={true}
                                    ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Data Sukses"}

                                    allowExportSelectedData={false}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Sukses'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                    // SummaryConfiguration={this.summary}
                                    summaryTotalItem={this.summary}
                                    // onToolbarPreparing = {this.onToolbarPreparing}

                                    height={'calc(70vh - 175px)'}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalDetailMpSusulan