import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { currency, banks, instrumentSubCategoryDeposito, instruments, rekeningBank } from 'dataSource/lookup';

class DetailPelepasanMtn extends Component {
    constructor(props){
        super(props)
        this.dataSource  = new CustomStore({
            load: async(loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `order-requests-obligasi/${requestId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                return response.orderObligasiInfos
            },
            key: 'orderDTO.orderRequestId',
            insert: (values) => {
            },
            update: (key,values) => {
               
            },
            remove: (key) => {
               
            }
        })

        this.columns = [
            {
                dataField: 'instrumentObligasiInfo.instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'instrumentObligasiInfo.instrumentName',
                caption: 'Nama Instrument',
            },
            {
                dataField: 'instrumentObligasiInfo.ratingId',
                caption: 'Rating',
            },
            {
                dataField: 'orderSbnBondSukukDTO.price',
                caption: 'Harga Penjualan (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'orderSbnBondSukukDTO.faceValue',
                caption: 'Nilai Investasi',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'instrumentObligasiInfo.rate',
                caption: 'Rate (%)',
                alignment: 'right'
            },
            {
                dataField: 'instrumentObligasiInfo.maturityDate',
                caption: 'Maturity Date',
                dataType: 'date',
                format: 'd MMM yyyy'
            },
            {
                dataField: 'orderSbnBondSukukDTO.accruedInterest',
                caption: 'Bunga Berjalan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'penerimaanKupon',
                caption: 'Penerimaan Kupon',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                calculateCellValue: (e) => {
                    if (e.penerimaanKupon == null) {
                        return 0
                    } else {
                        return e.penerimaanKupon
                    }
                }
            },
            {
                dataField: 'nilaiPelepasan',
                caption: 'Nilai Pelepasan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                calculateCellValue: (e) => {
                    var fv = e.orderSbnBondSukukDTO.faceValue
                    var bb = e.orderSbnBondSukukDTO.accruedInterest == null ? 0 : e.orderSbnBondSukukDTO.accruedInterest
                    var pk = e.penerimaanKupon == null ? 0 : e.penerimaanKupon
                    return fv + bb + pk
                }
            },
            {
                dataField: 'orderSbnBondSukukDTO.rekBankId',
                caption: 'Rekening Transfer',
                lookup: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    }
                }
            },
        ]
    }

    render() {
        return (
            <DataGrid
            id={"successDataGrid"}
            showBorders={false}
            dataSource={this.dataSource}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            // height={100}
            showScrollbar={"always"}
            // columns={this.columns}
            >
            {
                this.columns.map((column) => {
                    return <Column 
                        dataField={column.dataField} 
                        caption={column.caption} 
                        lookup={column.lookup} 
                        cellRender={column.cellRender} 
                        alignment={column.alignment || 'left'} 
                        cssClass={column.cssClass}
                        format = {column.format}
                        dataType = {column.dataType}
                        width = {column.width}
                        type = {column.type}
                        buttons = {column.buttons}
                        editorType = {column.editorType}
                        editorOptions = {column.editorOptions}
                        visible = {column.visible}
                        sortOrder = {column.sortOrder}
                        allowEditing = {column.allowEditing}
                        calculateCellValue = {column.calculateCellValue}
                    />
                })
            }
            {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPelepasanMtn