import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Form, Popup,FileUploader,ScrollView } from 'devextreme-react'
import { months, programs, tunjanganHrd } from 'dataSource/lookup'
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";

class FormModalPembayaranTunjanganLain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataDocument:{},
            DataMaster: {
                persenGapokTujangan:0,
                persenGapok:0,
                nilaiNominal:0,
            },
            popupVisible: false 
        }
        this.modalPreviewPdfRef = React.createRef()
        
        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem3 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.formMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: "programId",
                        label: {
                            text: "Program",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: "pengajuanNo",
                        label: {
                            text: "No Pengajuan",
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                    {
                        dataField: "keterangan",
                        label: {
                            text: "Keterangan"
                        }
                    },
                    {
                        dataField: "pengajuanTanggal",
                        label: {
                            text: "Tanggal Pengajuan"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "tunjanganTypeId",
                        label: {
                            text: "Tunjangan",
                            alignment: 'left',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'tunjanganName',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: 'periodeGaji',
                        label: {
                            text: 'Periode Gaji (Bln/Thn)'
                        },
                        visible:false,
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'monthAndYear',
                            calendarOptions: {
                                maxZoomLevel: 'year',
                                minZoomLevel: 'century',
                            },
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'bulan',
                        label: {
                            text: 'Periode Gaji (Bulan)'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: months(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: 'tahun',
                        label: {
                            text: 'Periode Gaji (Tahun)'
                        },
                        editorType: 'dxNumberBox',
                        
                    },
                    {
                        dataField: "tanggalPembayaran",
                        label: {
                            text: "Tanggal Pembayaran"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                itemType: 'group',
                                items: [

                                    {
                                        dataField: "dasarPerhitungan",
                                        label: {
                                            text: "Dasar Perhitungan"
                                        },
                                        editorType: 'dxRadioGroup',
                                        editorOptions: {
                                            layout: "vertical",
                                            dataSource: [
                                                {
                                                    id: 1,
                                                    value: '% Gaji Pokok'
                                                },
                                                {
                                                    id: 2,
                                                    value: '% Gaji + Tunjangan'
                                                },
                                                {
                                                    id: 3,
                                                    value: 'Nominal'
                                                },
                                            ],
                                            valueExpr: "id",
                                            displayExpr: "value",
                                            onValueChanged:(e) => {
                                                if (e.value) {
                                                    if (e.value === 1) {
                                                        this.formRef.current.instance.getEditor('persenGapok').option({'readOnly':false})
                                                        this.formRef.current.instance.getEditor('persenGapokTujangan').option({'readOnly':true,'value':0})
                                                        this.formRef.current.instance.getEditor('nilaiNominal').option({'readOnly':true,'value':0})
                                                    }else if (e.value === 2) {
                                                        this.formRef.current.instance.getEditor('persenGapok').option({'readOnly':true,'value':0})
                                                        this.formRef.current.instance.getEditor('persenGapokTujangan').option({'readOnly':false})
                                                        this.formRef.current.instance.getEditor('nilaiNominal').option({'readOnly':true,'value':0})
                                                    }else{
                                                        this.formRef.current.instance.getEditor('persenGapok').option({'readOnly':true,'value':0})
                                                        this.formRef.current.instance.getEditor('persenGapokTujangan').option({'readOnly':true,'value':0})
                                                        this.formRef.current.instance.getEditor('nilaiNominal').option({'readOnly':false})
                                                    }
                                                }
                                            }
                                        }
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                items: [
                                    {
                                        dataField: 'persenGapok',
                                        label: {
                                            visible: false,
                                        },
                                        editorType: 'dxNumberBox',
                                        editorOptions: {
                                            step: 0,
                                            format: '#,##0.00',
                                            readOnly:true
                                        }
                                    },
                                    {
                                        dataField: 'persenGapokTujangan',
                                        label: {
                                            visible: false,
                                        },
                                        editorType: 'dxNumberBox',
                                        editorOptions: {
                                            step: 0,
                                            format: '#,##0.00',
                                            readOnly:true
                                        }
                                    },
                                    {
                                        dataField: 'nilaiNominal',
                                        label: {
                                            visible: false,
                                        },
                                        editorType: 'dxNumberBox',
                                        editorOptions: {
                                            step: 0,
                                            format: '#,##0.00',
                                            readOnly:true
                                        }
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        dataField: 'karyawanPenerima',
                        label: {
                            text: 'Karyawan Penerima'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: "vertical",
                            items: [
                                'Semua',
                                'Personal',
                            ],
                        }
                    }
                ]
            },
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
    }

    submitData = async () => {
        try {
            var loadApi = 'karyawayan-tunjangan-lains/simpanTunjanganLain'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.karyawanPenerima = DataMaster.karyawanPenerima == "Semua" ? 1 : 2
            DataMaster.dasarPerhitungan = DataMaster.dasarPerhitungan
            DataMaster.pengajuanTanggal = formatDate(new Date(DataMaster.pengajuanTanggal))
            DataMaster.tanggalPembayaran = formatDate(new Date(DataMaster.tanggalPembayaran))
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: DataMaster,
            })

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }

        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            
        }
        
    }

    updateData = async () => {
        try {
            var loadApi = 'karyawayan-tunjangan-lains/simpanTunjanganLain'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.id = DataMaster.id
            DataMaster.karyawanPenerima = DataMaster.karyawanPenerima == "Semua" ? 1 : 2
            DataMaster.dasarPerhitungan = DataMaster.dasarPerhitungan == "% Gaji Pokok" ? 1 : DataMaster.dasarPerhitungan == "% Gaji + Tunjangan Tetap" ? 2 : 3
            DataMaster.pengajuanTanggal = formatDate(new Date(DataMaster.pengajuanTanggal))
            DataMaster.tanggalPembayaran = formatDate(new Date(DataMaster.tanggalPembayaran))
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: DataMaster,
            })

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }

        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            var DataDocument = { ...this.state.DataDocument }

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)

                DataDocument[`${fieldName}`] = formattedFileData.base64data
                DataDocument[`fileContentType`] = formattedFileData.mime
                DataDocument[`${fieldName}Name`] = files.name
                this.setState({
                    DataDocument: DataDocument
                })
            }

            fr.readAsDataURL(files)
        }
    }

    getListTunjanganType = async () => {
        var ListTunjanganType = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `tunjangan-types?size=999`, 'GET')
        ListTunjanganType = ListTunjanganType.filter(value => value.tunjanganGroupId === 6)
        this.formRef.current.instance.getEditor('tunjanganTypeId').option('dataSource', ListTunjanganType)
    }

    getNoPengajuan = async () => {
        let loadAPI = `generate-no-automatic/KaryawanTunjanganLain/pengajuanNo/TUNJANGANLAIN`
        var getNoPengajuan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST',{
            values:{}
        })
        if (getNoPengajuan) {
            this.formRef.current.instance.updateData({pengajuanNo:getNoPengajuan})
        }
    }

    retrieveData = async (data) => {
        var DataMaster = {...this.state.DataMaster}
        var DataTunjanganLain = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawayan-tunjangan-lains/${data.id}`, 'GET')
        DataMaster.id = DataTunjanganLain.id
        DataMaster.programId = DataTunjanganLain.programId
        DataMaster.pengajuanNo = DataTunjanganLain.pengajuanNo
        DataMaster.tanggalPembayaran = DataTunjanganLain.tanggalPembayaran
        DataMaster.keterangan = data.keterangan
        DataMaster.tunjanganTypeId = DataTunjanganLain.tunjanganTypeId
        DataMaster.pengajuanTanggal = data.tanggalPengajuan
        DataMaster.bulan = data.bulanDari
        DataMaster.tahun = data.tahunDari
        DataMaster.dasarPerhitungan = data.dasarPerhitungan
        DataMaster.persenGapok = data.nilaiPersenGapok
        DataMaster.persenGapokTujangan = data.nilaiPersenGapokTunjangan
        DataMaster.nilaiNominal = data.nilaiNominal
        DataMaster.karyawanPenerima = data.penerima == 1 ? 'Semua' : 'Personal' 
        this.setState({
            DataMaster:DataMaster
        })
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            DataDocument:{},
            popupVisible: false,
            DataMaster: {
                persenGapokTujangan:0,
                persenGapok:0,
                nilaiNominal:0,
            },
        })
    }


    previewFile = async() => {
        var src = imageSource(this.state.DataDocument.fileContentType, this.state.DataDocument.dokumen)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled= {true}
                showTitle={true}
                title={"Pembayaran Tunjangan Lain"}
                width={"50vw"}
                height={"90vh"}
                toolbarItems={this.props.from == 'pengajuan' && this.props.action == 'add' ? this.PopupToolbarItem  : this.props.from == 'pengajuan' && this.props.action == 'edit' ? this.PopupToolbarItem3 : this.PopupToolbarItem2}
            >
                <ScrollView>
                    <div className="container"> 
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formMaster'}
                            formData={this.state.DataMaster}
                            items={this.formMaster}
                            labelLocation={'left'}
                            readOnly={this.props.from == 'pengajuan' ? false : true}
                        />
                        <h6 className="item-title">Dokumen</h6>
                        <FileUploader
                            accept={".png,.jpg.jpeg"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload Dokumen"
                            showFileList={false}
                            name={"dokumen"}
                            disabled={this.props.from == 'pengajuan' ? false : true}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-8">{this.state.DataDocument.dokumenName}</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument, 'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                        <ModalPreviewPdf 
                            ref={this.modalPreviewPdfRef}
                            store={this.props.store}
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalPembayaranTunjanganLain