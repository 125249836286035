import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { pembayaranUmumStatus, programSdm, sdmUnit, unit } from 'dataSource/lookup';
import PPPengajuanCashOutModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/pengajuan-cashout';
import PPRealisasiUangMukaModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/realisasi-uangmuka';
import PPRealisasiSPModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/realisasi-sp';
import PPPengembalianCashOutModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/pengembalian-cashout';
import PPListDetailModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/list-detail/index';
import PPListTransaksiModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/list-transaksi/index';
import PPRealisasiAllModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/realisasi-all';
import PPUVerifikasiManagerModal from 'components/pages/modal/sdm-umum/pengajuan-pembayaran-umum/verifikasi-manager/index';
import notify from 'devextreme/ui/notify'
import { alert } from 'devextreme/ui/dialog';
import PPListInvoiceModal from 'components/pages/modal/sdm-umum/pembayaran-umum/pengajuan-aktif/list-invoice/index';
import { formatDefaultFullDate, formatNumberAum } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class PPPengajuanAktif extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()
        this.modalPengajuanCashOutRef = React.createRef()
        this.modalRealisasiUangMukaRef = React.createRef()
        this.modalRealisasiSPRef = React.createRef()
        this.modalRealisasiAllRef = React.createRef()
        this.modalPengembalianCashOutRef = React.createRef()
        this.modalListDetailRef = React.createRef()
        this.modalListTransaksiRef = React.createRef()
        this.modalListInvoiceRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text: 'Pengajuan Cash Out',
                    //     hint: 'Pengajuan Cash Out',
                    //     onClick: async (e) => {
                    //         if (e.row.data.totalCashOut == null || e.row.data.totalCashOut == 0) {
                    //             await alert('Metode Pembayaran tidak sesuai', 'Gagal')
                    //         } else {
                    //             this.showModalPengajuanCashOut(e.row.data)
                    //         }
                    //     }
                    // },
                    // {
                    //     text: 'Realisasi Uang Muka',
                    //     hint: 'Realisasi Uang Muka',
                    //     onClick: async (e) => {
                    //         if (e.row.data.totalUangMuka == null || e.row.data.totalUangMuka == 0) {
                    //             await alert('Metode Pembayaran tidak sesuai', 'Gagal')
                    //         } else {
                    //             this.showModalRealisasiUangMuka(e.row.data)
                    //         }
                    //     }
                    // },
                    {
                        text: 'Realisasi Pembayaran',
                        hint: 'Realisasi Pembayaran',
                        onClick: (e) => {
                            this.showModalRealisasiSP(e.row.data)
                        }
                    },
                    {
                        text: 'Realisasi Seluruh Pembayaran',
                        hint: 'Realisasi Seluruh Pembayaran',
                        onClick: async (e) => {
                            this.showModalRealiasasiAll(e.row.data)
                        }
                    },
                    // {
                    //     text: 'Pengembalian Cash Out',
                    //     hint: 'Pengembalian Cash Out',
                    //     onClick: (e) => {
                    //         this.showModalPengembalianCashOut(e.row.data)
                    //     }
                    // },
                    // {
                    //     text: 'Invoice Pembayaran',
                    //     hint: 'Invoice Pembayaran',
                    //     onClick: (e) => {
                    //         this.showModalListInvoice(e.row.data)
                    //     }
                    // },
                    {
                        text: 'List Detail Pembayaran',
                        hint: 'List Detail Pembayaran',
                        onClick: (e) => {
                            this.showModalListDetail(e.row.data)
                        }
                    },
                    {
                        text: 'List Transaksi',
                        hint: 'List Transaksi',
                        onClick: (e) => {
                            this.showModalListTransaksi(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: "id",
                caption: "No.",
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: "programId",
                caption: "Program",
                lookup: {
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: "statusId",
                caption: "Status",
                lookup: {
                    dataSource: pembayaranUmumStatus(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: "tanggalPengajuan",
                caption: "Tanggal Pengajuan",
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: "nominalDetil",
                caption: "Total Nominal Terkirim",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: "totalNominalPengajuan",
                caption: "Total Nominal Pengajuan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: "totalUangMuka",
                caption: "Total Uang Muka",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalCashOut",
                caption: "Total Cash Out",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalPph",
                caption: "Total PPH",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalPotongan",
                caption: "Total Potongan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalId",
                caption: "Jumlah transaksi",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "keterangan",
                caption: "Keterangan",
            },
            {
                dataField: 'cashOut',
                caption: 'Cash Out',
                alignment: 'center',
                columns: [
                    {
                        dataField: "sisaPembayaran",
                        caption: "Sisa Pembayaran",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "nominalCashOut",
                        caption: "Nominal Cash Out",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "nominalDigunakan",
                        caption: "Nominal Digunakan",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "sisaCash",
                        caption: "Sisa Cash",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                ]
            },
            {
                dataField: 'persetujuan',
                caption: 'Persetujuan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'unit',
                        caption: 'Unit Kerja',
                        lookup: {
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'requestBy',
                        caption: 'Dibuat Oleh'
                    },
                    {
                        dataField: 'managerVerifiedBy',
                        caption: 'Verifikasi Manager'
                    },
                    {
                        dataField: 'sdmVerifiedBy',
                        caption: 'Verifikasi SDMU'
                    },
                    {
                        dataField: 'directorApproveBy',
                        caption: 'Pengurus'
                    },
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengajuanCashOut = (data) => {
        this.modalPengajuanCashOutRef.current.show()
        if (data) {
            this.modalPengajuanCashOutRef.current.retrieveData(data)
        }
    }
    showModalRealisasiUangMuka = (data) => {
        // this.modalRealisasiUangMukaRef.current.getEntity()
        this.modalRealisasiUangMukaRef.current.show()
        if (data) {
            this.modalRealisasiUangMukaRef.current.retrieveData(data)
        }
    }
    showModalRealisasiSP = (data) => {
        this.modalRealisasiSPRef.current.getEntity()
        this.modalRealisasiSPRef.current.show()
        if (data) {
            this.modalRealisasiSPRef.current.retrieveData(data)
        }
    }
    showModalRealiasasiAll = (data) => {
        this.modalRealisasiAllRef.current.getEntity()
        this.modalRealisasiAllRef.current.show()
        if (data) {
            this.modalRealisasiAllRef.current.retrieveData(data)
        }
    }
    showModalPengembalianCashOut = (data) => {
        this.modalPengembalianCashOutRef.current.show()
        if (data) {
            this.modalPengembalianCashOutRef.current.retrieveData(data)
        }
    }
    showModalListDetail = (data) => {
        this.modalListDetailRef.current.show()
        if (data) {
            this.modalListDetailRef.current.retrieveData(data)
        }
    }
    showModalListTransaksi = (data) => {
        this.modalListTransaksiRef.current.show()
        if (data) {
            this.modalListTransaksiRef.current.retrieveData(data)
        }
    }
    showModalListInvoice = (data) => {
        this.modalListInvoiceRef.current.show()
        if (data) {
            this.modalListInvoiceRef.current.retrieveData(data)
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: () => {
                        this.showModalPengajuanCashOut()
                    }
                }
            },
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        ) 
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'view-pengajuan-pembayaran-umums?statusId.in=5&size=9999');

        for (let value of response){
            let getProgram = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `programs/${value.programId}`);
            let getPembayran = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `pembayaran-umum-statuses/${value.statusId}`);
            let unitId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `units/${value.unitId}`);
            let dataValue = {
                programId: getProgram.program ? getProgram.program : '-',
                statusId: getPembayran.statusName ? getPembayran.statusName : '-',
                tanggalPengajuan: value.tanggalPengajuan ? formatDefaultFullDate(value.tanggalPengajuan) : '-',
                nominalDetil: value.nominalDetil ? formatNumberAum(value.nominalDetil) : 0,
                totalNominalPengajuan: value.totalNominalPengajuan ? formatNumberAum(value.totalNominalPengajuan) : 0,
                totalUangMuka: value.totalUangMuka ? formatNumberAum(value.totalUangMuka) : 0,
                totalCashOut: value.totalCashOut ? formatNumberAum(value.totalCashOut) : 0,
                totalPph: value.totalPph ? formatNumberAum(value.totalPph) : 0,
                totalPotongan: value.totalPotongan ? formatNumberAum(value.totalPotongan) : 0,
                totalId: value.totalId ? value.totalId : 0,
                keterangan: value.keterangan ? value.keterangan : '-',
                sisaPembayaran: value.sisaPembayaran ? formatNumberAum(value.sisaPembayaran) : 0,
                nominalCashOut: value.nominalCashOut ? formatNumberAum(value.nominalCashOut) : 0,
                nominalDigunakan: value.nominalDigunakan ? formatNumberAum(value.nominalDigunakan) : 0,
                sisaCash: value.sisaCash ? formatNumberAum(value.sisaCash) : 0,
                unit: unitId.unitName ? unitId.unitName : '-',
                requestBy: value.requestBy,
                managerVerifiedBy: value.managerVerifiedBy,
                sdmVerifiedBy: value.sdmVerifiedBy,
                directorApproveBy: value.directorApproveBy,
            }
            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }
        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('PENGAJUAN AKTIF');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,   
                },
                columns: [
                    {name: 'Program'},
                    {name: 'Status'},
                    {name: 'Tanggal Pengajuan'},
                    {name: 'Total Nominal Terkirim'},
                    {name: 'Total Nominal Pengajuan'},
                    {name: 'Total Uang Muka'},
                    {name: 'Total Cash Out'},
                    {name: 'Total PPH'},
                    {name: 'Total Potongan'},
                    {name: 'Jumlah Transaksi'},
                    {name: 'Keterangan'},
                    {name: 'Sisa Pembayaran'},
                    {name: 'Nominal Cash Out'},
                    {name: 'Nominal Digunakan'},
                    {name: 'Sisa Cash'},
                    {name: 'Unit Kerja'},
                    {name: 'Dibuat Oleh'},
                    {name: 'Verifikasi Manager'},
                    {name: 'Verifikasi SDMU'},
                    {name: 'Pengurus'},
                ],
                rows: data
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('N'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('O'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'PENGAJUAN AKTIF';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums; 

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums2;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums2;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = 65;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;

            const Data14 = worksheet.getColumn(14);
            Data14.width = WidthColums;

            const Data15 = worksheet.getColumn(15);
            Data15.width = WidthColums;

            const Data16 = worksheet.getColumn(16);
            Data16.width = WidthColums;

            const Data17 = worksheet.getColumn(17);
            Data17.width = WidthColums;

            const Data18 = worksheet.getColumn(18);
            Data18.width = WidthColums;

            const Data19 = worksheet.getColumn(19);
            Data19.width = WidthColums;

            const Data20 = worksheet.getColumn(20);
            Data20.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `PENGAJUAN_AKTIF.xlsx`
                );
            });
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengajuan Aktif</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-pengajuan-pembayaran-umums?statusId.in=5&size=9999'
                    insertAPI='pembayarans'
                    updateAPI='pembayarans'
                    deleteAPI='pembayarans'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    // useArraySource = {true}
                    // ArraySourceData = {[{ id: 1 }]}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Pengajuan Aktif"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Aktif'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    height={'calc(100vh - 220px'}

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <PPPengajuanCashOutModal
                    ref={this.modalPengajuanCashOutRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <PPRealisasiUangMukaModal
                    ref={this.modalRealisasiUangMukaRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <PPRealisasiSPModal
                    ref={this.modalRealisasiSPRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <PPRealisasiAllModal
                    ref={this.modalRealisasiAllRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <PPPengembalianCashOutModal
                    ref={this.modalPengembalianCashOutRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <PPUVerifikasiManagerModal
                    ref={this.modalListDetailRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    from={"riwayatPu"}
                />
                <PPListTransaksiModal
                    ref={this.modalListTransaksiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <PPListInvoiceModal
                    ref={this.modalListInvoiceRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default PPPengajuanAktif