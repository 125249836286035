import React, { Component } from 'react'
import { product } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormDeposito from 'components/pages/modal/investasi/order/deposito/penempatan'
import { addURL } from 'redux/actions/url'
import FormOrderUploadDeposito from 'components/pages/modal/investasi/order/deposito/upload'
import FormPencairanDeposito from 'components/pages/modal/investasi/order/deposito/pencairan'
import FormPerpanjanganDeposito from 'components/pages/modal/investasi/order/deposito/perpanjangan'
import FormPertimbanganDeposito from 'components/pages/modal/investasi/order/deposito/pertimbangan'
import { Button } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'

class OrderDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridDepositoRef = React.createRef()
        this.popupFormPenempatan = React.createRef()
        this.popupFormPencairan = React.createRef()
        this.popupFormPerpanjangan = React.createRef()
        this.popupFormPertimbangan = React.createRef()
        this.popupFormUploadDepositoRef = React.createRef()
        this.currentData = []
        this.orderColumns = [
            // {
            //     type: 'buttons',
            //     width: 100,
            //     buttons: [
            //         'edit',
            //         {
            //             text: 'Generate Instruksi',
            //             hint: 'Generate Instruksi',
            //             cssClass: 'text-success',
            //             onClick: (e) => {

            //             }
            //         },
            //         {
            //             text: 'File',
            //             hint: 'File',
            //             cssClass: 'text-danger',
            //             onClick: (e) => {
            //                 this.showModalFormUploadDeposito(e)
            //             }
            //         },
            //     ]
            // },
            {
                dataField: 'productCode',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'productCode',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'orderCode',
                        caption: 'Kode Order',
                    },
                    {
                        dataField: 'transactionName',
                        caption: 'Tiper Order',
                    },
                    {
                        dataField: 'orderDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'bankName',
                caption: 'Bank',
                // lookup: {
                //     dataSource: banks(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'bankName'
                // }
            },
            {
                dataField: 'instrument',
                caption: 'Instrument',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'amountOrder',
                        caption: 'Nominal (Rp)',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'rateOrder',
                        caption: 'Rate (%)',
                        alignment: 'right'
                    },
                    {
                        dataField: 'tenor',
                        caption: 'Tenor',
                        calculateCellValue: (e) => {
                            return e.tenor + ' ' + e.tenorTypeId
                        }
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            },
        ]
        this.dropDownbutton = [
            {
                id: 1,
                text: 'Penempatan',
                onClick: (e) => {
                    console.log('upti')
                }
            },
            {
                id: 2,
                text: 'Pencairan',
                onClick: (e) => {
                    console.log('upsi')
                }
            },
            {
                id: 3,
                text: 'Perpanjangan',
                onClick: (e) => {
                    console.log('perpanjangan')
                }
            },
            // {
            //     id: 4,
            //     text: 'Pertimbangan',
            //     onClick : (e) => {
            //         this.showModalInsert()
            //     }
            // }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxDropDownButton',
    //             // options: {
    //             //     icon: 'plus',
    //             //     onClick: (e) => {
    //             //         this.showModalInsert()
    //             //     },
    //             // }
    //             options: {
    //                 text: 'Tambah Transaksi',
    //                 icon: 'plus',
    //                 items: this.dropDownbutton,
    //                 keyExpr: 'id',
    //                 displayExpr: 'text',
    //                 splitButton: true,
    //                 onItemClick: 'onClick'
    //             }
    //         }
    //     )
    // }

    // showModalInsert = (type) => {
    //     if (type === 'upti') {
    //         this.popupFormPenempatan.current.show();
    //     }

    //     if (type === 'upsi') {
    //         this.popupFormPencairan.current.show();
    //     }

    //     if (type === 'perpanjangan') {
    //         this.popupFormPerpanjangan.current.show();
    //     }
    // }

    showModalPertimbangan = () => {
        this.popupFormPertimbangan.current.show();
    }
    showModalFormUploadDeposito = () => {
        this.popupFormUploadDepositoRef.current.show();
    }
    forceRefresh = () => {
        this.DataGridDepositoRef.current.forceRefresh(true)
    }
    onSelectionChanged = (e) => {
        var orderId = e.selectedRowKeys
        this.setState({
            orderId: orderId
        })
    }
    generateInstruksi = () => {
        var id = {
            listOrderId: this.state.orderId
        }
        confirmAlert({
            title: 'Generate',
            message: 'Apakah anda yakin ingin generate data ini?',
            buttons: [
                {
                    label: 'Ya',
                    onClick: async (e) => {
                        if (id.listOrderId != undefined) {
                            try {
                                var insertAPI = 'order-depositos/buat-instruksi-deposito'
                                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                                    values: id
                                })
                                notify({ message: 'Generate Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                                this.forceRefresh()
                                return response
                            } catch (e) {
                                console.log(e)
                                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        } else {
                            notify({ message: 'Silahkan pilih data untuk di generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    }
                },
                {
                    label: 'Tidak'
                }
            ]
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Order Deposito</h2>
                <DevExpressDataGrid
                    ref={this.DataGridDepositoRef}
                    keyField="orderId"
                    loadAPI='order-depositos/list?size=9999&status=OPEN'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Deposito"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Deposito'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Generate Instruksi"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.generateInstruksi.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
                <FormDeposito
                    ref={this.popupFormPenempatan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormPencairanDeposito
                    ref={this.popupFormPencairan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormPerpanjanganDeposito
                    ref={this.popupFormPerpanjangan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormPertimbanganDeposito
                    ref={this.popupFormPertimbangan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormOrderUploadDeposito
                    ref={this.popupFormUploadDepositoRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default OrderDeposito
