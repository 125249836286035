import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import { ScrollView } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { formatDate } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';
import notify from 'devextreme/ui/notify';

class ConfirmClosingDays extends Component {
    constructor(props){
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.state = {
            popupVisible: false,
            DataMaster: {
                id: 0
            },
            DataPerformance: [],
            DataJurnal:{},
            newData:false,
        }

        
        
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: this.prosesEndDay.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                dataField:'id',
                caption:'No'
            },
            {
                dataField:'instrumentCode',
                caption:'Kode Instrument'
            }
        ]

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    loadData = async() => {
        var tanggal = formatDate(this.date)
        var getEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs?categoryId.in=1&categoryId.in=2&categoryId.in=9&quantity.greaterThan=0`);
        
        var dataPerformance = []
        if(getEfekBalance.length > 0){
            for(var responseEfekBalance of getEfekBalance){
                var getInstrumentPerformance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-performances?instrumentId.in=${responseEfekBalance.instrumentId}&priceDate.equals=${tanggal}`)

                var dataInstrument
                if(getInstrumentPerformance.length > 0){
                    return dataPerformance
                }else{
                    dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${responseEfekBalance.instrumentId}`)
                    dataPerformance.push(dataInstrument)
                }
            }

            this.setState({
                DataPerformance: dataPerformance
            })

            return dataPerformance
        }else{
            return []
        }
    }

    prosesEndDay = async() => {
        if(this.state.DataPerformance.length > 0){
            notify({ message: 'Harga Penutupan Belum Di Generate, Silahkan lakukan Generate Harga Penutupan Terlebih Dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }else{
            this.props.store.dispatch(showLoading(true))
            try{
                var tanggal = formatDate(this.date)
                // var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/closingDailyBalance/${tanggal}/${tanggal}`, 'POST', { 
                //     values: {} 
                // });

                let tanggalAwal = this.props.tanggal.sysDateNow;
                let tanggalAkhir = this.props.tanggal.sysDateNext;
                let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/closingDailyBalance/${formatDate(tanggalAwal)}/${formatDate(tanggalAkhir)}`, 'POST', {
                    values: {}
                })
    
                if(response){
                    var getClosing = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/closingDaily/${formatDate(tanggal)}/1/4`);

                    if(getClosing.length > 0){
                        var data = {
                            id: getClosing[0].id,
                            bulan: this.date.getMonth()+1,
                            modulId: 4,
                            prosesId: 1,
                            systemDate: tanggal,
                            tahun: this.date.getFullYear(),
                        }
        
                        await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/updateClosing`, 'PUT', {
                            key: data.id,
                            values: data
                        }, {});
                    }else{
                        var data = [
                            {
                                bulan: this.date.getMonth()+1,
                                modulId: 4,
                                prosesId: 1,
                                systemDate: tanggal,
                                tahun: this.date.getFullYear(),
                            }
                        ]
        
                        await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/saveToClosing`, 'POST', { 
                            values: data
                        });
                    }

                    this.props.store.dispatch(showLoading(false))
                    notify({ message: 'Proses Generate Akhir Hari berhasil dilakukan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.hide()
                    this.props.forceRefresh()
                }
    
                return response
            }catch(e){
                console.log(e)
    
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Proses Generate Akhir Hari gagal dilakukan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                this.hide()
                this.props.forceRefresh()
            }
        }
        
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
        // this.dataGridRef.current.forceRefresh(true)
    }

    hide = () => {
        this.setState({
            popupVisible: false
        })
    }

    
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'75vw'}
                    height="65vh"
                    title={`Cek Harga Penutupan`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                {/* <div style={{padding:"2%"}}> */}
                        <ScrollView width='100%' height='100%'>
                        <div className="container-fluid">

                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                loadAPI='daftar-penagjuan-pengadaan'
                                insertAPI='daftar-penagjuan-pengadaan'
                                updateAPI='daftar-penagjuan-pengadaan'
                                deleteAPI='daftar-penagjuan-pengadaan'

                                backendserver={process.env.REACT_APP_BACKEND_HRD}

                                useArraySource={true}
                                ArraySourceData={this.loadData}
                                
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Jurnal Transaksi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Transaksi Mark To Market'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}
                                
                                onToolbarPreparing={this.onToolbarPreparing}

                                // height={'calc(100vh - 500px)'}
                                height={'300px'}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            </div>
                        </ScrollView>
                    {/* </div> */}
                </Popup>
            </React.Fragment>
        )
    }
}

export default ConfirmClosingDays