import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { coa } from 'dataSource/lookup';
import uuidv4 from 'uuid/v4'
import httpRequest from 'plugin/httprequest';

class ViewDetailJournal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
        };

        this.formItem = [
            {
                dataField: 'transactionNo',
                label: {
                    alignment: 'left',
                    text: 'No Transaksi'
                }
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    value: new Date(),
                }
            },
            {
                type: 'emtpy'
            },
            {
                dataField: 'description',
                label: {
                    alignment: 'left',
                    text: 'Deskripsi'
                },
                colSpan: 3
            },
        ]

        this.journalColumn = [
            {
                dataField: 'akun',
                caption: 'Akun',
                calculateCellValue: (e) => {
                    return e.kodeAkun + ' - ' + e.namaAkun
                }
            },
            {
                dataField: 'coaId',
                caption: 'Kode Coa',
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'coaCode',
                }
            },
            {
                name: 'coaName',
                dataField: 'coaId',
                caption: 'Nama Coa',
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'coaName',
                }
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'amountDebit',
                caption: 'Debet',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'amountCredit',
                caption: 'Credit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
        ]

        this.sumJournal = [
            {
                name: 'total',
                showInColumn: 'akun',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'amountDebit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'amountDebit',
                displayFormat: '{0}'
            },
            {
                column: 'amountCredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'amountCredit',
                displayFormat: '{0}'
            },
        ]

        this.formRef = React.createRef()
        this.journalDetailRef = React.createRef()

        this.dataJurnal = []
    }

    getToolbarItem = () => {
        return [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    setDataJurnal = async (transType, dataTransaction) => {
        this.formRef.current.instance.updateData(dataTransaction)
        var data
        this.transType = transType
        switch (transType) {
            case 'KPS':
                data = await this.loadDataJournalKepesertaan(dataTransaction.transactionId)
                break;
            case 'HRD':
                data = await this.loadDataJournalHRD(dataTransaction.transactionId)
                break;
            case 'INV':
                data = await this.loadDataJournalInvestasi(dataTransaction.transactionId)
                break;

            default:
                break;
        }
        this.dataJurnal = data
        this.journalDetailRef.current.forceRefresh(true)
    }

    loadDataJournalKepesertaan = async (transactionId) => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-transaction-detils/getAllByTransactionId/${transactionId}`)

        return data
    }

    loadDataJournalHRD = async (transactionId) => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `transaction-dtl/getAllByTransactionId/${transactionId}?size=999`)

        return data
    }

    loadDataJournalInvestasi = async (transactionId) => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal-dtls-by-trans-id/${transactionId}`)

        data = data.map(value => {
            return {
                id: uuidv4(),
                akun: value.akunId,
                kodeAkun: value.akunCode,
                namaAkun: value.akunName,
                coaId: value.coaId,
                description: value.description,
                amountDebit: value.db,
                amountCredit: value.cr,
            }
        })

        console.log(data)

        return data
    }

    loadDataJournal = () => {
        return this.dataJurnal
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Detail Transaksi"}
                    width={"90vw"}
                    height={"90vh"}
                    toolbarItems={this.getToolbarItem()}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <Form
                                    colCount={3}
                                    id={"formMaster"}
                                    formData={this.state.formData}
                                    items={this.formItem}
                                    ref={this.formRef}
                                    labelLocation="left"
                                    readOnly={true}
                                />
                                <DevExpressDataGrid
                                    ref={this.journalDetailRef}
                                    menuRightClick={false}

                                    loadAPI='journal-details'
                                    insertAPI='journal-details'
                                    updateAPI='journal-details'
                                    deleteAPI='journal-details'

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataJournal}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    editingMode="row"
                                    // sortingMode = "none"

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={false}
                                    selection={"none"}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={'calc(100vh - 320px)'}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    // FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.journalColumn} // taruh konfigurasi kolom disini

                                    summaryTotalItem={this.sumJournal}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>

        );
    }
}
export default ViewDetailJournal