import React, { Component } from 'react'
import { ScrollView, Popup, Form } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import notify from "devextreme/ui/notify";
import { instrumentObligasi, instruments, kpds, months, productDefault } from 'dataSource/lookup';
import ArrayStore from 'devextreme/data/array_store';

class ModalGenerateJadwalAmortisasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            DataMaster: {}
        }
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'produkId',
                label: {
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    readOnly: true,
                }
            },
            {
                dataField: 'kpdId',
                label: {
                    text: 'KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak',
                    readOnly: true,
                }
            },
            {
                dataField: 'balanceBuyDateId',
                label: {
                    text: 'ID'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Kode Instrument'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instruments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode',
                    readOnly: true
                }
            },
            {
                dataField: 'namaInstrument',
                label: {
                    text: 'Nama Instrument'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'tglPembelian',
                label: {
                    text: 'Tanggal Pembelian'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    readOnly: true
                }
            },
            {
                dataField: 'tglMaturity',
                label: {
                    text: 'Tanggal Maturity'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    readOnly: true
                }
            },
            {
                dataField: 'interestKupon',
                label: {
                    text: 'Interest Kupon'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    readOnly: true
                }
            },
            {
                dataField: 'hargaPembelian',
                label: {
                    text: 'Harga Pembelian'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    readOnly: true
                }
            },
            {
                dataField: 'couponPeriod',
                label: {
                    text: 'Periode Kupon'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'dayBase',
                label: {
                    text: 'Basis Hari'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'BASE_30_360',
                            value: '30/360'
                        },
                        {
                            id: 'BASE_ACT_360',
                            value: 'act/360'
                        },
                        {
                            id: 'BASE_ACT_ACT',
                            value: 'act/act'
                        },
                        {
                            id: 'BASE_30_365',
                            value: '30/365'
                        },
                        {
                            id: 'BASE_ACT_365',
                            value: 'act/365'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    openOnFieldClick: true,
                    searchEnabled: true,
                    readOnly: true
                },
            },
            {
                dataField: 'lastCouponDate',
                label: {
                    text: 'Last Kupon Date'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'nextCouponDate',
                label: {
                    text: 'Next Kupon Date'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'faceValue',
                label: {
                    text: 'Face Value'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true
                }
            },
            {
                dataField: 'yield',
                label: {
                    text: 'Yield'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.000000',
                    step: 0
                }
            },
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
    }
    show = async (data) => {
        this.setState({
            DataMaster: data,
            popupVisible: true,
        })
    }
    submitData = async () => {
        var dataSubmit = this.state.DataMaster
        if (dataSubmit) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    'efek-amortisasi-jadwals/jadwal-amortisasi-generate',
                    'POST',
                    { values: dataSubmit }
                )
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                }
                notify({ message: 'Proses Sukses!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {}
        })
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={false}
                    showTitle={true}
                    width={'35vw'}
                    height="65vh"
                    title={`Generate Jadwal`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'dataMaster'}
                            formData={this.state.DataMaster}
                            items={this.dataMaster}
                            labelLocation={"left"}
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalGenerateJadwalAmortisasi