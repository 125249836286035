import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ShowModalActionAkunPeserta from 'components/pages/modal/kepesertaan/pesertaDapen/akunPeserta/actionModal';
import { addURL } from 'redux/actions/url';
import { compareObj } from 'plugin/helper';
import { participants } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';

class PPIP extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()
        this.modalActionIuranDataAkunPPIPRef = React.createRef()
        this.showModalActionAddRef = React.createRef()
        this.showModalActionViewRef = React.createRef()

        this.state = {
            pemberiKerja: {},
            getUrl: 'view-kepesertaan-products?akunStatusId.in=1&productId.in=2'
        }

        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        if(data.value){
                            this.setState({
                                getUrl : `view-kepesertaan-products?akunStatusId.in=1&productId.in=2&participantId.in=${data.value}`
                            })
                        }else{
                            this.setState({
                                getUrl : `view-kepesertaan-products?akunStatusId.in=1&productId.in=2`
                            })
                        }
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                width: 100,
                buttons: [
                    'edit',
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalAction('view',e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'cardNo',
                caption: 'No. Kartu',
            },
            {
                dataField: 'efektifDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy',
                // alignment: 'right'
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
                dataType: "number",
                format: "#,##0.00",
                alignment: 'right'
            },
            {
                dataField: 'iuranNormalPeserta',
                caption: 'Iuran Peserta',
                dataType: "number",
                format: "#,##0.00",
                alignment: 'right'
            },
            {
                dataField: 'iuranNormalPk',
                caption: 'Iuran Perusahaan',
                dataType: "number",
                format: "#,##0.00",
                alignment: 'right'
            },
            {
                dataField: 'iuranBulanan',
                caption: 'Iuran Perbulan',
                dataType: 'number',
                format: "#,##0.00",
                alignment: 'right'
            },
            {
                dataField: 'iuranTotal',
                caption: 'Total Pembayaran Iuran',
                dataType: "number",
                format: "#,##0.00",
                alignment: 'right'
            },
            {
                dataField: 'unitKerjaName',
                caption: 'Unit Kerja',
            },
            {
                dataField: 'mobileNo',
                caption: 'No HP',
            },
            {
                dataField: 'email',
                caption: 'Email',
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: "Pembukaan Akun Baru",
                    onClick: (e) => {
                        this.showModalAction('add')
                    },
                }
            },
        );
    }

    showModalAction = (type, data = {}) => {
        if (type === 'add') {
            this.showModalActionAddRef.current.retrieveData(data)
            this.showModalActionAddRef.current.show()
        } else {
            this.showModalActionViewRef.current.retrieveData(data)
            this.showModalActionViewRef.current.show()
        }
    }

    showModalActionIuranDataAkunPPIP = (viewData, data = null) => {
        this.modalActionIuranDataAkunPPIPRef.current.retrieveData(viewData, data)
        this.modalActionIuranDataAkunPPIPRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    submitData = async(data,pesertaId,actionType) => {
        // try {
        //     // var loadAPIValidationProduct = `kepersertaan-products/DataIuran/${pesertaId}/2`
        //     // await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIValidationProduct, 'GET')

        //     // confirmAlert({
        //     //     title: 'Informasi',
        //     //     message: "Tiap Peserta Tidak Bisa Memiliki 2 Produk Yang Sama!",
        //     //     buttons: [
        //     //       {
        //     //         label: 'Tutup',
        //     //         onClick: () => {

        //     //         }
        //     //       },
        //     //     ]
        //     // });
        //     // return false
        // } catch (e) {
        // }
        var dataAkun = data
        var pesertaAkunProdukId = dataAkun.id || 0
        var dataIuran = data.dataIuran
        var currentData = data.currentDataIuran
        var deletedDataIuran = data.deletedDataIuran

        var loadAPIPeserta = `pesertas/${pesertaId}`                    
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')

        var loadAPIIuranType = 'iuran-types'                    
        var getIuranType = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIIuranType, 'GET')

        if(dataAkun && dataIuran){
            dataAkun.pesertaId = pesertaId || 0
            // dataAkun.productId = this.props.product == 'ppmp' ? 1 : dataAkun.productId
            for(var iuran of dataIuran){
                var typeIuran = getIuranType.find(val => val.id === iuran.iuranTypeId)
                if(typeIuran.iuranName == 'Iuran Normal Peserta'){
                    dataAkun.iuranNormalPeserta  = iuran.iuranNominal || 0
                }else{
                    dataAkun.iuranNormalPeserta  = 0
                }

                if(typeIuran.iuranName == 'Iuran Lainnya Peserta'){
                    dataAkun.iuranManfaatLainPeserta  = iuran.iuranNominal
                }else{
                    dataAkun.iuranManfaatLainPeserta  = 0
                }

                if(typeIuran.iuranName == 'Iuran Normal PK'){
                    dataAkun.iuranNormalPk  = iuran.iuranNominal || 0
                }else{
                    dataAkun.iuranNormalPk  = 0
                }

                if(typeIuran.iuranName == 'Iuran Lainnya PK'){
                    dataAkun.iuranManfaatLainPk  = iuran.iuranNominal || 0
                }
                else{
                    dataAkun.iuranManfaatLainPk  = 0
                }

                // if(typeIuran.iuranPeserta){
                //     dataAkun.iuranNormalPeserta  = dataAkun.iuranNormalPeserta + iuran.iuranNominal
                // }else{
                //     dataAkun.iuranNormalPk  = dataAkun.iuranNormalPeserta + iuran.iuranNominal
                // }
            }
            try {
                if(!dataAkun.id || actionType == 'add'){
                    var insertAPI = 'kepersertaan-products'                    
                    var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                            values:dataAkun
                        }
                    )
                    pesertaAkunProdukId = responseProduct.id
                }else{
                    var updateAPI = 'kepersertaan-products'                    
                    var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                            key:dataAkun.id,
                            values:dataAkun
                        },
                    dataAkun)

                }
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        }
        if(dataIuran.length !== 0){
            for(var value of dataIuran){
                value.participantId = getPeserta.participantId
                value.pesertaAkunProdukId = pesertaAkunProdukId
                value.productId = responseProduct.productId
                if(value.id.length != 36){
                    var selectedData = currentData.find(cur => cur.id === value.id)
                    if(compareObj(value,selectedData)){
                        try {
                            var updateAPI = 'peserta-akun-produk-iurans'
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                                    values:value,
                                    key: value.id
                                },
                            value)
                        } catch (e) {
                            console.log(e)
                            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
                        }
                    }
                }else{
                    delete value.id
                    try {
                        var insertAPI = 'peserta-akun-produk-iurans'
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                                values:value
                            }
                        )
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
                    }
                }

            }
            try {
                var deleteAPI = 'peserta-akun-produk-iurans'
                for(var values of deletedDataIuran){
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, deleteAPI, 'DELETE', values)
                }
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        }
        var text = 'Berhasil!'
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
        this.forceRefresh()
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">PPIP</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='kepersertaan-products'
                    updateAPI='kepersertaan-products'
                    deleteAPI='kepersertaan-products'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    // keyField = {'ids'}
                    // useArraySource={true}
                    // ArraySourceData={this.retrieveDataGrid}

                    remoteOperations = {true}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Akun Peserta PPIP"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'PPIP'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ShowModalActionAkunPeserta
                    ref={this.showModalActionAddRef}
                    store={this.props.store}
                    callbackSubmit={this.forceRefresh}
                    actionType={'add'}
                    product={'ppip'}
                    submitData = {this.submitData}
                />

                <ShowModalActionAkunPeserta
                    ref={this.showModalActionViewRef}
                    store={this.props.store}
                    callbackSubmit={this.forceRefresh}
                    actionType={'view'}
                    product={'ppip'}
                    submitData = {this.submitData}
                />
            </div>
        )
    }
}

export default PPIP