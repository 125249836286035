import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest'
import { custodies, currency, instrumentTypes, instrumentsCategoryEtf, instrumentsCategoryObli, ratings, instrumentCategories, frekuensis, instrumentsCategorySaham, banks, instrumentSubCategoryDeposito } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';
import { showLoading } from 'redux/actions/loading';
import { formatNumber } from 'plugin/helper';

class FormModalInstruDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            newData: false,
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.updateData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.DataMaster = [
            {
                itemType: "group",
                colCount: 2,
                items: [

                    {
                        dataField: 'instrumentCode',
                        label: {
                            text: 'Kode Instrument'
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            text: 'Nama Instrument'
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'bilyetNo',
                        label: {
                            text: 'No Bilyet'
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'categorySubId',
                        label: {
                            text: 'Sub Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentSubCategoryDeposito(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'tenor',
                        label: {
                            text: 'Tenor'
                        },
                    },
                    {
                        dataField: 'tenorTypeId',
                        label: {
                            text: 'Tenor',
                            visible: false
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: [
                                {
                                    value: 'HARI',
                                    text: 'Hari'
                                },
                                {
                                    value: 'BULAN',
                                    text: 'Bulan'
                                },
                            ],
                            valueExpr: 'value', // contains the same values as the "statusId" field provides
                        }
                    },
                    {
                        dataField: 'rate',
                        label: {
                            // text: 'Rate %'
                            text: 'Rate'
                        },
                        format: '#,##0.00',
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                            // format: '#0,## %',
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'currencyCode', // provides display values
                            searchEnabled: true,
                            openOnFieldClick: true,
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'rateTypeId',
                        label: {
                            text: 'Tipe Rate'
                        },
                        colSpan: 2
                    },
                ]
            },
        ]
    }

    newData = (newData) => {
        this.setState({
            newData: newData
        })
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster: data,
        })
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate()
        return formValidation.isValid
    }
    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        var data = this.state.DataMaster
        if (data) {
            try {
                let type = 'success'
                let text = 'Sukses input !'
                var insertAPI = `staticdata-wf/instruments`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: data,
                })
                if (response) {
                    this.props.store.dispatch(showLoading(false))
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.hide()
                    this.props.forceRefresh();
                } else {
                    this.props.store.dispatch(showLoading(false))
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    updateData = async () => {
        this.props.store.dispatch(showLoading(true))
        try {
            let dataMaster = this.state.DataMaster;
            var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataMaster.id}`);
            
            let type = 'success'
            let text = 'Sukses ubah !'
            var updateAPI = `staticdata-wf/instruments`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                values: dataMaster,
                key: dataMaster.id
            }, instrument);

            console.log(response);

            if (response) {
                this.props.store.dispatch(showLoading(false))
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.hide()
                this.props.forceRefresh();
            } else {
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            console.log(e)
            notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
            newData: false,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'55vw'}
                    height="50vh"
                    title={`Form Deposito`}
                    ref={this.PopupRef}
                    toolbarItems={this.state.newData ? this.popupToolbarItem2 : this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={1}
                            ref={this.formRef}
                            id={'DataMaster'}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation={"left"}
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalInstruDeposito