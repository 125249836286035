import React, { Component } from 'react';

import ChangePassword from '../pages/modal/changepassword';
import { formatDefaultDate } from 'plugin/helper';

class Header extends Component {
  constructor(props) {
    super(props);
    this.store = this.props.store;

    this.state = {
      username : '',
      systemDate : null
    }

    this.popupChangePassword = React.createRef();
  }

  showModalChangePassword = (e) => {
    e.preventDefault()
    this.popupChangePassword.current.show()
  }

  setUsernameAndSystemDate = (username,systemDate) => {
    this.setState({
      username : username,
      systemDate: systemDate
    })
  }

  render() {
    var systemDateElem
    if(this.state.systemDate){
      systemDateElem = <span className="ml-2 text-white">| SYSTEM DATE : {formatDefaultDate(this.state.systemDate)}</span>
    }
    return (
      <React.Fragment>
        <div className="header d-flex align-items-center header-coloring">
          <nav className="navbar navbar-expand-lg navbar-light py-0 w-100">
            <a className="text-black font-weight-bold text-decoration-none text-white" href="#">DANA PENSIUN DIGITAL SYSTEM</a>
            {systemDateElem}
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="userdropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="far fa-user-circle fa-lg mr-2"></i>
                    <span className="name"> {this.state.username} </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="userdropdown">
                    <a className="dropdown-item" href="#" onClick={(e) => { this.showModalChangePassword(e) }}>
                      <i className="fa fa-gear icon"></i> Change Password
                        </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#" onClick={(e) => { this.props.logout(e) }}>
                      <i className="fa fa-power-off icon"></i> Logout
                        </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <ChangePassword
          ref={this.popupChangePassword}
          store={this.props.store}
        />
      </React.Fragment>
    );
  }
}

export default Header;
