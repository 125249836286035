import React, { Component } from 'react'
import Popup from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { agama, jabatan, karyawan, statusPembayaranRekapGaji } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

class ViewDaftarTransaksiThr extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }
        this.DataGridPengajuanRef = React.createRef()

        this.listData = []

        this.columns = [
            {
                dataField:"nik",
                caption:"NIK",
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'nikPanjang'
                // }
            },
            {
                dataField:"fullName",
                caption:"Nama Lengkap",
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'fullName'
                // }
            },
            {
                dataField:"jabatanId",
                caption:"Jabatan",
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName'
                }
            },
            {
                dataField:"unitId",
                caption:"Unit",
            },
            {
                dataField:"religionId",
                caption:"Agama",
                lookup: {
                    dataSource: agama(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                alignment: 'center',
                caption:"THR",
                columns: [
                    {
                        dataField: 'tunjanganName',
                        caption: 'Tipe'
                    },
                    {
                        dataField: 'thrTahun',
                        caption: 'Tahun'
                    },
                    {
                        dataField: 'nilaiPersen',
                        caption: '% Gaji'
                    },
                ]
            },
            // {
            //     dataField:"keterangan",
            //     caption:"Keterangan",
            // },
            {
                dataField:"nominalThr",
                caption:"Nominal THR",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:"pph",
                caption:"Pajak",
                dataType: 'number',
                format: '#,##0.00'
            },
            // {
            //     dataField:"thrBersih",
            //     caption:"THR Bersih",
            //     dataType: 'number',
            //     format: '#,##0.00'
            // },
            {
                dataField:"statusId",
                caption:"Status",
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];
    }

    retrieveData = async (data) => {
        let datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-transaksi-thrs?size=9999`,
            'GET'
        )

        let filterByIdThr = datas.filter(value => value.idThr === data.id)
        this.listData = filterByIdThr
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    loadData = () => {
        return this.listData
    }

    show = () => {
        this.setState({
            popupVisible:true
        })
    }

    hide = () => {
        this.listData = []
        this.DataGridPengajuanRef.current.emptyDeletedIndex()
        this.setState({
            popupVisible:false
        })
    }

    render() {
        return (
           <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Detail Daftar Transaksi THR'} 
                width={'70vw'}
                heigth={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <DevExpressDataGrid
                        ref = {this.DataGridPengajuanRef}
                        loadAPI='view-daftar-transaksi-thrs'
                        insertAPI='daftar-transaksi-thr'
                        updateAPI='daftar-transaksi-thr'
                        deleteAPI='daftar-transaksi-thr' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData= {this.loadData}
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Penerima THR"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Penerima THR'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        // onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
            </Popup>
        )
    }
}

export default ViewDaftarTransaksiThr