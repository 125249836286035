import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader, ScrollView } from 'devextreme-react';
import { download, formatDate, formatNumberAum, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import { alert } from 'devextreme/ui/dialog';
import { currency, entity, kasUmum, pembayaranUmumDetilByPuId, rekBankHrd } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';

class PPRealisasiAllModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {
                documentFile: '',
                documentFileContentType: '',
                documentFileName: ''
            },
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formKonfirmasiRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: async () => {
                        var formValidate = this.validate()
                        if (formValidate) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'noPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'id',
                        label: {
                            alignment: 'left',
                            text: 'Pembayaran ID'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPembayaran',
                        label: {
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            onValueChanged: (e) => {
                                var val = e.value
                                this.formKonfirmasiRef.current.instance.updateData({ tanggalPenerimaan: val })
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Harap isi Tanggal Pembayaran'
                            },
                        ],
                    },
                    {
                        name: 'nominalUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Pembayaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'entity',
                        label: {
                            text: 'Entitas',
                            alignment: "left",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'entityName', // provides display values
                            searchEnabled: true,
                            deferRendering: false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Harap isi Entitas'
                            }
                        ]
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang',
                            alignment: "left",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Harap isi Mata Uang'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Transfer Rekening Bank',
                items: [
                    {
                        dataField: 'nominalTransfer',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Transfer'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            step: 0,
                            readOnly: true
                            // onValueChanged: (e) => {
                            //     var cashout = this.formKonfirmasiRef.current.instance.getEditor('cashOut').option('value')
                            //     var kasum = this.formKonfirmasiRef.current.instance.getEditor('nominalKasUmum').option('value')
                            //     var nominalTransfer = e.value
                            //     var totPaid = cashout + kasum + nominalTransfer
                            //     this.formKonfirmasiRef.current.instance.updateData({
                            //         totalSisaPembayaran: this.state.dataMaster.totalNominalPengajuan - totPaid
                            //     })
                            // }
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Informasi Pengajuan',
                items: [
                    {
                        dataField: 'reffTransaksi',
                        label: {
                            alignment: 'left',
                            text: 'Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'itemPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Item Pembayaran'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'noPengajuanInfo',
                        label: {
                            alignment: 'left',
                            text: 'No Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'keteranganPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'Keterangan Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPenerimaan',
                        label: {
                            text: 'Tanggal Penerimaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalNominalPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'Total Nominal Pengajuan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Total Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalSisaPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Pembayaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'totalSisaUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalSisaCashOut',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Cash Out'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                ]
            },
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    validate = () => {
        var formValidation = this.formKonfirmasiRef.current.instance.validate();
        return formValidation.isValid
    }

    getEntity = async () => {
        var paramEntity = ['KR', 'SP', 'P3', 'CN', 'GV']
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllMultipleByType/${paramEntity.join()}`, 'GET')
        this.formKonfirmasiRef.current.instance.getEditor('entity').option('dataSource', data)
    }

    retrieveData = async (data) => {
        var loadApi = `pembayaran-umums/${data.id}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'GET')
        getData.itemPembayaran = data.totalId
        getData.nominalUangMuka = data.totalNominalPengajuan - data.nominalDetil
        getData.totalNominalPengajuan = data.totalNominalPengajuan
        getData.nominalTransfer = data.totalNominalPengajuan - data.nominalDetil
        getData.noPengajuanInfo = data.noPengajuan
        getData.totalUangMuka = data.totalUangMuka
        getData.totalSisaPembayaran = data.totalSisaPembayaran
        getData.keteranganPengajuan = data.keterangan

        this.setState({
            dataMaster: getData,
        })
        this.formKonfirmasiRef.current.instance.updateData(getData)
    }

    submitData = async () => {
        var data = this.state.dataMaster
        if(data.documentFile && data.documentFileContentType && data.documentFileName) {
            var dataPost = {
                currencyId: data.currencyId,
                documentFile: data.documentFile,
                documentName: data.documentFileName,
                documentFileContentType: data.documentFileContentType,
                entity: data.entity,
                itemPembayaran: data.itemPembayaran || 0,
                kasUmumId: 0,
                keteranganPengajuan: data.keteranganPengajuan,
                noPengajuan: data.noPengajuan,
                noPengajuanInfo: data.noPengajuanInfo,
                nominalCashOut: 0,
                nominalKasUmum: 0,
                nominalTransfer: data.nominalTransfer || 0,
                pembayaranUmumId: data.id,
                reffTransaksi: data.reffTransaksi,
                rekBankId: 0,
                tanggalPembayaran: formatDate(data.tanggalPembayaran),
                tanggalPenerimaan: formatDate(data.tanggalPenerimaan),
                totalNominalPengajuan: data.totalNominalPengajuan,
                totalSisaCashOut: data.totalSisaCashOut,
                totalSisaPembayaran: data.totalSisaPembayaran,
                totalSisaUangMuka: data.totalSisaUangMuka,
                totalUangMuka: data.totalUangMuka,
                username: this.props.store.getState().sessionUser
            }
            try {
                var postAPI = 'pembayaran-umum-aktif-arap-keseluruhan'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'POST', {
                    values: dataPost
                })
    
                if (response) {
                    await alert('Realisasi Keseluruhan Pembayaran Berhasil Dikirim!', 'Berhasil')
                    this.hide()
                    this.props.forceRefresh()
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            await alert('File Upload Harus Di Isi', 'Informasi')
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)

                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Name`]: files.name,
                        [`${fieldName}Extension`]: formatNameAndExtention.extention,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))

            }

            fr.readAsDataURL(files)
        }
    }

    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`]), this.state.dataMaster[`${fileName}Name`])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Pembayaran Keseluruhan'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{ height: '100%' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formKonfirmasiRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation='left'
                                />
                            </div>
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.documentFileName || 'File'}
                                    showFileList={false}
                                    name={'documentFile'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('documentFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('documentFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default PPRealisasiAllModal