import React, { Component, StrictMode } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { cities, genderHrd, jabatan, kelurahans, programSdm, programs } from 'dataSource/lookup';
import FormDaftarKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/daftar-karyawan/form';
import GajiDaftarKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/daftar-karyawan/gaji';
import ProfesiDaftarKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/daftar-karyawan/profesi';
import FileDaftarKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/daftar-karyawan/file';
import UploadKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/daftar-karyawan/uploadKaryawan';
import { Form } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { data } from 'dataSource/json/auctionBuybackCash';

class DaftarKaryawan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            DataMaster: {
                programId: 1,
                statusKaryawanId: 'ALL'
            }
        }

        this.modalEditRef = React.createRef()
        this.modalDaftarKaryawanRef = React.createRef()
        this.modalGajiRef = React.createRef()
        this.modalProfesiRef = React.createRef()
        this.modalFileRef = React.createRef()
        this.modalUploadKaryawanRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.filterData = []
        this.allData = []

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'NonAktifkan',
                        hint: 'NonAktifkan',
                        onClick: async(e) => {
                            let result = await confirm('Apakah anda ingin menonaktifkan data karyawan ini?', 'Konfirmasi');

                            if (result) {
                                await this.deleteKaryawan(e.row.data.id)
                            }
                        }
                    },
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalDaftarKaryawan(e, false)
                        }
                    },
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalDaftarKaryawan(e, true)
                        }
                    },
                    // {
                    //     text: 'Gaji',
                    //     hint: 'Gaji',
                    //     onClick: (e) => {
                    //         this.showModalGaji(e)
                    //     }
                    // },
                    {
                        text: 'Profesi',
                        hint: 'Profesi',
                        onClick: (e) => {
                            this.showModalProfesi(e)
                        }
                    },
                    {
                        text: 'Document & SK',
                        hint: 'Document & SK',
                        onClick: (e) => {
                            this.showModalFile(e)
                        }
                    },
                ]
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                }
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap'
            },
            {
                dataField: 'identityNo',
                caption: 'No Identitas'
            },
            {
                dataField: 'mobileNo',
                caption: 'No. Handphone'
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir'
            },
            {
                dataField: 'sexId',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: genderHrd(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            // {
            //     dataField: 'department',
            //     caption: 'Department',
            //     lookup: {
            //         dataSource: departments(this.props.store),
            //         valueExpr: 'id',
            //         displayExpr: 'departmentName',
            //     }
            // },
            {
                dataField: 'jabatanId',
                caption: 'Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                }
            }
        ]

        this.filterItem = [
            {
                dataField: 'programId',
                label: {
                    alignment: 'left',
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                },
            },
            {
                dataField: 'statusKaryawanId',
                label: {
                    alignment: 'left',
                    text: 'Status Karyawan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'ALL',
                            value: 'All Status'
                        },
                        {
                            id: 'ACTIVE',
                            value: 'Aktif'
                        },
                        {
                            id: 'NON_ACTIVE',
                            value: 'Tidak Aktif'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
    }

    deleteKaryawan = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawans/deactivate-karyawan/${data}`, 'GET')
            
            notify({ message: 'Data Karyawan Berhasil dinonaktifkan', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 800);
            this.forceRefresh()
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            console.log(e);
        }
    }

    // componentDidMount = async () => {
    //     this.defaultData()
    // }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    defaultData = async () => {
        var datas = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawans?size=9999`,
            'GET'
        )
        this.filterData = datas
        if (datas) {
            this.forceRefresh()
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    };
    // showModalEditDaftarKaryawan = (data) => {
    //     this.modalEditRef.current.retrieveData(data)
    //     this.modalEditRef.current.show()
    // }
    showModalDaftarKaryawan = (e, readOnly) => {
        // if (this.modalDaftarKaryawanRef.current.lookUp(e.row.data)) {
        if (e.row) {
            this.modalDaftarKaryawanRef.current.retrieveData(e.row.data, readOnly)
            this.modalDaftarKaryawanRef.current.newData(false)
        }
        // }
        this.modalDaftarKaryawanRef.current.show()
    }

    showModalGaji = (e) => {
        if (e.row) {
            this.modalGajiRef.current.retrieveData(e.row.data)
        }
        this.modalGajiRef.current.show()
        this.modalGajiRef.current.getKaryawanSk()
    }

    showModalProfesi = (e) => {
        if (e.row) {
            this.modalProfesiRef.current.getDataKaryawanId(e.row.data.id)
        }
        this.modalProfesiRef.current.show()
    }

    showModalFile = (e) => {
        if (e.row) {
            this.modalFileRef.current.getDataKaryawanId(e.row.data.id)
        }
        this.modalFileRef.current.show()
    }

    showModalUploadKaryawan = () => {
        this.modalUploadKaryawanRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'upload',
                    text: 'Upload Data Karyawan',
                    onClick: (e) => {
                        this.showModalUploadKaryawan(e)
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    // text: 'Karyawan',
                    onClick: (e) => {
                        this.showModalDaftarKaryawan(e, false)
                    }
                }
            },
        )
    }

    // filterSubmit = async () => {
    //     const state = this.state.DataMaster
    //     var response = await httpRequest(
    //         process.env.REACT_APP_BACKEND_HRD,
    //         this.props.store,
    //         `karyawans?size=9999`,
    //         'GET'
    //     )

    //     var statusKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `status-karyawans`, 'GET');
    //     var dataStatusArr = [];
    //     var dataArrFilter = [];
    //     var arrMerge = [];

    //     dataStatusArr = statusKaryawan.filter(value => value.statusKeaktifan === state.statusKaryawanId);

    //     for (let valueI of dataStatusArr) {
    //         if (valueI.statusKeaktifan === state.statusKaryawanId) {
    //             dataArrFilter = response.filter(value => value.statusKaryawanId === valueI.id);

    //             if (dataArrFilter.length > 0) {
    //                 for (let value of dataArrFilter) {
    //                     arrMerge.push(value);
    //                     this.filterData = arrMerge;
    //                 }
    //             }
    //         }
    //     }
    //     // console.log(dataStatusKar);
    //     // if (state.programId && state.statusKaryawanId === 1) {
    //     //     dataArr = statusKaryawan.filter(value => value.statusKeaktifan === state.statusKaryawanId);
    
    //     //     for (let valueI of dataArr) {
                
    //     //     }
    //     //     // this.filterData = response.filter(value => value.programId === state.programId && v.statusKeaktifan === 1);
    //     // } 
    //     // this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
    //     // this.loadData()
    //     this.forceRefresh()

    // }

    filterSubmit = async () => {
        const state = this.state.DataMaster

        if (state.programId && state.statusKaryawanId) {
            let allData = `karyawans?size=9999&status=${state.statusKaryawanId}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, allData);

            this.filterData = response;
        }
        // console.log(dataStatusKar);
        // if (state.programId && state.statusKaryawanId === 1) {
        //     dataArr = statusKaryawan.filter(value => value.statusKeaktifan === state.statusKaryawanId);
    
        //     for (let valueI of dataArr) {
                
        //     }
        //     // this.filterData = response.filter(value => value.programId === state.programId && v.statusKeaktifan === 1);
        // } 
        // this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()

    }

    loadData = async () => {
        return this.filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Karyawan</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.DataMaster}
                    items={this.filterItem}
                    labelLocation={'left'}
                />
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='karyawans?size=9999'
                    insertAPI='karyawans'
                    updateAPI='karyawans'
                    deleteAPI='karyawans'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormDaftarKaryawan
                    ref={this.modalDaftarKaryawanRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                />
                <UploadKaryawan
                    ref={this.modalUploadKaryawanRef}
                    store={this.props.store}
                />
                <GajiDaftarKaryawan
                    ref={this.modalGajiRef}
                    store={this.props.store}
                />
                <ProfesiDaftarKaryawan
                    ref={this.modalProfesiRef}
                    store={this.props.store}
                />
                <FileDaftarKaryawan
                    ref={this.modalFileRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}


export default DaftarKaryawan