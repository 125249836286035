import React, { Component } from 'react'
import {Popup, Form} from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import { akunHR, payrollKategoriSub } from 'dataSource/lookup';

class FormModalLembur extends Component {
    constructor(props){
        super(props)

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.formRef = React.createRef()
        this.PopupRef = React.createRef()

        this.newData = true

        this.state = {
            popupVisible:false,
            DataMaster:{
                faktorPPH21:true
            }
        }

        this.form = [
            {
                dataField:"lemburCode",
                label:{
                    text:"Kode"
                }
            },
            {
                dataField:"lemburName",
                label:{
                    text:"Nama Lembur"
                }
            },
            {
                dataField:"akunId",
                label:{
                    text:"Akun",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"payrollCategorySubId",
                label:{
                    text:"Kategori Payroll",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: payrollKategoriSub(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categorySubName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'jamAwal',
                label: {
                    text: 'Jam Awal'
                },
                dataType: 'datetime', // Tambahkan properti dataType dengan nilai 'datetime'
                editorType: 'dxDateBox',
                editorOptions: {
                    type: 'time',
                    displayFormat: 'HH:mm',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%'
                },
                allowFiltering: true // Jika Anda ingin mengaktifkan filter
            },
            {
                dataField: 'jamAkhir',
                label: {
                    text: 'Jam Akhir'
                },
                dataType: 'datetime', // Tambahkan properti dataType dengan nilai 'datetime'
                editorType: 'dxDateBox',
                editorOptions: {
                    type: 'time',
                    displayFormat: 'HH:mm',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%'
                },
                allowFiltering: true // Jika Anda ingin mengaktifkan filter
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
        ];
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster:data
        })
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    } 
    
    hide() {
        this.newData = true
        this.setState({
            DataMaster:{},
            popupVisible: false
        })
        this.props.forceRefresh()
    }

    submitData = async () => {
        try {
            var loadAPI = 'lembur-types'
            var text = 'Sukses Input Lembur'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"POST",{
                values: this.state.DataMaster
            });

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    updateData = async () => {
        try {
            var loadAPI = 'lembur-types'
            var text = 'Sukses Ubah Lembur'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"PUT",{
                values: this.state.DataMaster,
                key:this.state.DataMaster
            },this.state.DataMaster);

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Lembur'} 
                width={500}
                height={'auto'}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <Form
                ref={this.formRef}
                colCount={1}
                id={'formPotongan'}
                formData={this.state.DataMaster}
                items={this.form}
                labelLocation = 'left'
            />
            </Popup>
        )
    }
}

export default FormModalLembur