import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatDate, formatUploadFileData, getSystemDate, imageSource, monthDiff } from 'plugin/helper'
import ScrollView from 'devextreme-react/scroll-view';
import { asetKategoriSdm, assetCategory, assetSdm, currency, entity, entityType, programs, subAsetKategoriSdm } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import notify from "devextreme/ui/notify";

class FormModalPengajuanPenghapusanAset extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.userLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null

        this.state = {
            popupVisible: false,
            DataMaster: {
                currencyId:1001,
                currencyRate:1,
            },
            DataDocument:{},
            dataVerifManager: {
                verifikasiBy:this.userLoged,
                verifikasiDate:this.sysDate ? this.sysDate.dateValue : null,
            },
            dataVerifDireksi: {
                approvedBy:this.userLoged,
                approvedDate:this.sysDate ? this.sysDate.dateValue : null,
            },
            fileUploadReceipt: [],
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.formVerifManagerRef = React.createRef()
        this.newData = true
        this.modalPreviewPdfRef = React.createRef()

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'programId',
                                label: {
                                    text: 'Program'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: programs(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'program',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged:async (e) => {
                                        if(e.value){
                                            this.loadDataAset(e.value)
                                        }
                                    }
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'pengajuanNo',
                                label: {
                                    text: 'No Pengajuan'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'assetId',
                                label: {
                                    text: 'Kode Aset',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: (item) => {
                                        return item && item.assetKode + ' - ' + item.assetName
                                    }, // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged:(e) => {
                                        if (e.selectedItem) {
                                            this.formRef.current.instance.updateData({namaAsset:e.selectedItem.assetName, assetKode: e.selectedItem.assetKode})
                                            this.retrieveDataAset(e.selectedItem)
                                        }
                                    }
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'namaAsset',
                                label: {
                                    text: 'Nama Aset'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'pengajuanDate',
                                label: {
                                    text: 'Tanggal Pengajuan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    openOnFieldClick: true,
                                    useMaskBehavior: true,
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'disposalTypeId',
                                label: {
                                    text: 'Tipe Penghapusan'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: 1,
                                            value: 'Penjualan',
                                        },
                                        {
                                            id: 2,
                                            value: 'Rusak Permanen',
                                        },
                                        {
                                            id: 3,
                                            value: 'Hilang',
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    onValueChanged: (e) => {
                                        this.formRef.current.instance.beginUpdate()
                                        switch(e.value){
                                            case 1 :    this.formRef.current.instance.itemOption('kehilangan-aset','visible',false)
                                                        this.formRef.current.instance.itemOption('penjualan-aset','visible',true)
                                                        break;
                                            case 2 :    this.formRef.current.instance.itemOption('kehilangan-aset','visible',false)
                                                        this.formRef.current.instance.itemOption('penjualan-aset','visible',false)
                                                        break;
                                            case 3 :    this.formRef.current.instance.itemOption('kehilangan-aset','visible',true)
                                                        this.formRef.current.instance.itemOption('penjualan-aset','visible',false)
                                                        break;
                                            default:    break;
                                        }
                                        this.formRef.current.instance.endUpdate()
                                        if(this.state.DataMaster.programId){
                                            this.loadDataAset(this.state.DataMaster.programId)
                                        }
                                    }
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'disposalNote',
                                label: {
                                    text: 'Keterangan Penghapusan'
                                },
                                editorType: 'dxTextArea',
                                isRequired: true
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'kehilangan-aset',
                        caption: 'Kehilangan Aset',
                        visible: false,
                        items: [
                            {
                                dataField: 'laporanDate',
                                label: {
                                    text: 'Tanggal Laporan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    openOnFieldClick: true,
                                    useMaskBehavior: true
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'laporanNo',
                                label: {
                                    text: 'No Laporan Kehilangan'
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'laporanEntitas',
                                label: {
                                    text: 'Entitas Penerbit Keterangan'
                                },
                                isRequired: true
                            },
                            {
                                dataField: "laporan",
                                label: {
                                    text: "Dokumen Laporan Kehilangan",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                                isRequired: true
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'penjualan-aset',
                        caption: 'Penjualan Aset',
                        visible: false,
                        items: [
                            {
                                dataField: 'penjualanDate',
                                label: {
                                    text: 'Tanggal Penjualan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    openOnFieldClick: true,
                                    useMaskBehavior: true
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'pembeli',
                                label: {
                                    text: 'Entitas Pembeli'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: entity(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'entityName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'penjualanNominal',
                                label: {
                                    text: 'Nominal Penjualan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        this.formRef.current.instance.updateData({penjualanNominalNett: (e.value || 0) - (this.state.DataMaster.penjualanBiaya || 0) - (this.state.DataMaster.penjualanPajak || 0)})
                                    }
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'penjualanBiaya',
                                label: {
                                    text: 'Biaya Penjualan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        this.formRef.current.instance.updateData({penjualanNominalNett: (this.state.DataMaster.penjualanNominal || 0) - (e.value || 0) - (this.state.DataMaster.penjualanPajak || 0)})
                                    }
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'penjualanPajak',
                                label: {
                                    text: 'Pajak Penjualan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        this.formRef.current.instance.updateData({penjualanNominalNett: (this.state.DataMaster.penjualanNominal || 0) - (this.state.DataMaster.penjualanBiaya || 0) - (e.value || 0)})
                                    }
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'penjualanNominalNett',
                                label: {
                                    text: 'Nominal Bersih'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    text: 'Mata Uang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyCode'
                                },
                                isRequired: true
                            },
                            {
                                dataField: 'currencyRate',
                                label: {
                                    text: 'Kurs'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                },
                                isRequired: true
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'informasi-aset',
                caption: 'Informasi Aset',
                items: [
                    {
                        dataField: 'assetCategoryId',
                        label: {
                            text: 'Kategori Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: asetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'assetCategorySubId',
                        label: {
                            text: 'Sub Kategori Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: subAsetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categorySubName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'locationUnitId',
                        label: {
                            text: 'Unit'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'locationKaryawanId',
                        label: {
                            text: 'Karyawan'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'tanggalPerolehan',
                        label: {
                            text: 'Tanggal Perolehan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            displayFormat: 'dd MMM yyyy',
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'nilaiPerolehan',
                        label: {
                            text: 'Nominal Perolehan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions:{
                            format: '#,##0.00',
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'umurAset',
                        label: {
                            text: 'Umur Aset'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'nilaiBuku',
                        label: {
                            text: 'Nilai Buku'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions:{
                            format: '#,##0.00',
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'spesifikasi',
                        label: {
                            text: 'Spesifikasi'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                ]
            },

        ]
        this.formVerifManager = [
            {
                itemType: 'group',
                caption: 'VERIFIKASI MANAGER',
                items: [
                    {
                        dataField: 'verifikasiBy',
                        label: {
                            text: 'Verifikasi Oleh'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'verifikasiDate',
                        label: {
                            text: 'Tanggal Verifikasi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'verifikasiNote',
                        label: {
                            text: 'Keterangan Verifikasi'
                        },
                    },
                ]
            }
        ]
        this.formVerifDireksi = [
            {
                itemType: 'group',
                caption: 'VERIFIKASI DIREKSI',
                items: [
                    {
                        dataField: 'approvedBy',
                        label: {
                            text: 'Verifikasi Oleh'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'approvedDate',
                        label: {
                            text: 'Tanggal Verifikasi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'approvedNote',
                        label: {
                            text: 'Keterangan Verifikasi'
                        },
                    },
                ]
            }
        ]
        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: 'Simpan',
                    onClick: this.submit.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Setuju",
                    onClick: (e) => {
                        this.verifManager(this.props.type)
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tolak",
                    onClick: (e) => {
                        // this.verifManager(e.row.data, this.props.type)
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem3 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

    }

    loadDataAset = async (programId) => {
        var getAsset = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'assets?size=999999', 'GET')
        getAsset = getAsset.filter(value => value.programId == programId)
        getAsset = getAsset.filter(value => value.statusId == 2 || value.statusId == 3)
        this.formRef.current.instance.getEditor('assetId').option('dataSource',getAsset)
    }

    retrieveData = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-disposals/${data.id}`, 'GET')
        this.formRef.current.instance.updateData({
            assetId: response.assetId
        })
        this.newData = false
        this.setState({
            DataMaster: response,
            DataDocument:{
                documentFile: response.documentFile,
                documentFileContentType: response.documentFileContentType,
                documentName: response.documentName,
                laporanFile: response.laporanFile,
                laporanFileContentType: response.laporanFileContentType,
                laporanName: response.laporanName
            },
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocument };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}File`] = formattedFileData.base64data;
                DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;

                this.setState({
                    DataDocument: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.newData = true
        this.setState({
            popupVisible: false,
            DataMaster: {
                currencyId:1001,
                currencyRate:1,
                approvedBy:this.userLoged,
                approvedDate:this.sysDate.dateValue,
                verifikasiBy:this.userLoged,
                verifikasiDate:this.sysDate.dataValue
            },
            DataDocument:{},
            dataVerifManager: {
                verifiedDate: new Date()
            },
            dataVeriDireksi: {
                verifiedDate: new Date()
            },
            fileUploadReceipt: [],
        })
    }

    submit = () => {
        const valid = this.formRef.current.instance.validate()
        if(valid.isValid){
            if (this.newData) {
                this.submitDataPengajuan() 
            }else{
                this.updateDataPengajuan()
            }
        }
    }

    submitDataPengajuan = async () => {
        const isValid = this.formRef.current.instance.validate()
        try {
            var loadApi = 'asset-disposals/simpan'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.laporanDate = DataMaster.laporanDate
            DataMaster.pengajuanDate = DataMaster.pengajuanDate
            DataMaster.penjualanDate = DataMaster.penjualanDate
            DataMaster.requestNote = DataMaster.disposalNote
            DataMaster.requestBy = this.userLoged
            DataMaster.requestDate = this.sysDate.dateValue
            DataMaster.statusId = 1
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: DataMaster
            })

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    updateDataPengajuan = async () => {
        try {
            var loadApi = 'asset-disposals'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.laporanDate = DataMaster.laporanDate
            DataMaster.pengajuanDate = DataMaster.pengajuanDate
            DataMaster.penjualanDate = DataMaster.penjualanDate
            DataMaster.requestNote = DataMaster.disposalNote
            DataMaster.requestBy = this.userLoged
            DataMaster.requestDate = this.sysDate.dateValue
            DataMaster.statusId = 1
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'PUT', {
                values: DataMaster,
                key:DataMaster.id
            },DataMaster)

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    verifManager = async (type) => {
        try {
            if(type == "verifManager"){
                var DataMaster = {...this.state.DataMaster,...this.state.dataVerifManager}
                DataMaster.statusId = 3
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-disposals`, 'PUT',{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster)

            }else if(type == "verifDireksi"){
                var DataMaster = {...this.state.DataMaster,...this.state.dataVerifDireksi}
                DataMaster.statusId = 4
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-disposals`, 'PUT',{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster)
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            }

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    kirimPengajuan = async () => {
        try {
            var loadApi = 'asset-disposals'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.laporanDate = DataMaster.laporanDate
            DataMaster.pengajuanDate = DataMaster.pengajuanDate
            DataMaster.penjualanDate = DataMaster.penjualanDate
            DataMaster.requestNote = DataMaster.disposalNote
            DataMaster.requestBy = this.userLoged
            DataMaster.requestDate = this.sysDate.dateValue
            DataMaster.statusId = 2
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: DataMaster
            })

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
            
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    retrieveDataAset = (data) => {
        this.formRef.current.instance.updateData({
            assetCategoryId : data.assetCategoryId,
            assetCategorySubId : data.assetCategorySubId,
            locationUnitId : data.locationUnitId,
            locationKaryawanId : data.locationKaryawanId,
            tanggalPerolehan : data.tanggalPerolehan,
            nilaiPerolehan : data.nilaiPerolehan,
            umurAset : monthDiff(new Date(getSystemDate(this.props.store)),new Date(data.tanggalPerolehan)) + ' Bulan',
            nilaiBuku : data.nilaiBuku,
            spesifikasi : data.spesifikasi
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={`Form ${this.props.type == 'pengajuan' ? 'Pengajuan' : this.props.type == 'verifManager' ? 'Verifikasi' : 'Persetujuan'} Penghapusan Aset`}
                width={"55vw"}
                height={"80vh"}
                toolbarItems={this.props.type == 'pengajuan' ? this.PopupToolbarItem : this.props.type == 'aktif' ? this.PopupToolbarItem3 : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
                <ScrollView>
                    <div className="container">
                        <Form
                            ref={this.formRef}
                            colCount={2}
                            id={'formPotongan'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            readOnly={this.props.type == 'pengajuan' ? false : true}
                            labelLocation='left'
                        />
                        <div className="row">
                            <div className="col-sm-4">
                            <FileUploader
                                    accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'}
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.DataDocument.documentName || 'Dokumen Penghapusan'}
                                    showFileList={false}
                                    name={'document'}
                                    value={this.state.fileUploadReceipt}
                                    disabled={false}
                                    ref={this.fileUploderRef}
                                    visible={this.props.type == 'pengajuan' ? true : false}
                                />
                                
                            </div>
                            <div className={`col-sm-4 pt-3`}>
                                    <button onClick={() => this.previewFile(this.state.DataDocument, 'document')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.documentName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Preview
                                    </button>
                                </div>
                        </div>
                        
                        <Form
                            ref={this.formVerifManagerRef}
                            colCount={2}
                            id={'formPotongan'}
                            formData={this.state.dataVerifManager}
                            items={this.formVerifManager}
                            labelLocation='left'
                            visible={this.props.type == 'verifManager' ? true : false}
                        />
                        <Form
                            ref={this.formVerifDireksiRef}
                            colCount={2}
                            id={'formPotongan'}
                            formData={this.state.dataVerifDireksi}
                            items={this.formVerifDireksi}
                            labelLocation='left'
                            visible={this.props.type == 'verifDireksi' ? true : false}
                        />
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default FormModalPengajuanPenghapusanAset