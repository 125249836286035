import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { banks, product, instrumentSubCategoryDeposito, currency, rekeningBank, fundManager, deposito, productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormSubInstrumentPerpanjanganDeposito from './formInstrument,';
import { inputToTaskInvestasi } from 'plugin/helper';


class FormPengajuanPerpanjanganDeposito extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            kajian: "",
            kajianContentType: 'text/html',
            dataPerpanjangan: {},
        }

        this.prevTabIndex = 0
        this.dataPenjualan = []
        this.dataDeposito = []
        this.dataGridDetail = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.dataGridDetailPerpanjanganRef = React.createRef()
        this.modalInstrumentDepositoRef = React.createRef()
        this.kajianPengajuanRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('draft')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('kirim')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' || this.props.actionType == 'add' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id: 1,
                text: 'Instrumen Pengajuan'
            },
            {
                id: 2,
                text: 'Kajian Pengajuan'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                type: 'empty',
                colSpan: 2
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                }
            },
            {
                dataField: 'tanggalPerpanjangan',
                label: {
                    text: 'Tanggal Order'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please fill order date'
                    }
                ]
            },
            {
                dataField: 'fundManagerId',
                label: {
                    text: 'Fund Manager',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fundManagerName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Fund Manager'
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                onValueChanged: (e) => {
                    this.setState({
                        dataMaster: {
                            productId: e.value
                        }
                    })
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Product'
                    }
                ]
            },
            {
                dataField: 'kategori',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'jenisTransaksi',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
        ]
        this.toolbarItem = [
            'background', 'bold', 'color', 'italic', 'link', 'image', 'strike', 'subscript', 'superscript', 'underline', 'blockquote', 'header'
            , 'increaseIndent', 'decreaseIndent', 'orderedList', 'bulletList', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeBlock'
            , 'variable', 'separator', 'undo', 'redo', 'clear', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft',
            'insertColumnRight', 'deleteColumn'
        ]
    }
    getDataGridColumnConfiguration = (actionType) => {
        var columnConfiguration = [
            {
                dataField: 'instrumentName',
                caption: 'Deposito',
                // lookup: {
                //     dataSource: deposito(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'instrumentName'
                // }
                // lookup: this.props.actionType == 'view' ? {
                //     dataSource: deposito(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'instrumentName'
                // } : null
            },
            {
                dataField: 'bilyetNo',
                caption: 'No. Bilyet'
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName'
                }
            },
            {
                dataField: 'kurs',
                caption: 'Kurs',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'nominalPokok',
                caption: 'Nilai Deposito',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'rate',
                caption: 'Rate (%)',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'maturityDatePerpanjangan',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'd MMM yyyy'
            },
            {
                // dataField: 'acruedInterest',
                dataField: 'nilaiTransfer',
                // caption: 'Interet',
                caption: 'Nominal Bunga',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rolloverTypeId',
                caption: 'Tipe Perpanjangan',
                lookup: {
                    dataSource: [
                        {
                            id: 0,
                            value: 'Pokok + Bunga'
                        },
                        {
                            id: 1,
                            value: 'Pokok Saja'
                        },
                        {
                            id: 2,
                            value: 'Sebagian'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'nominalPerpanjangan',
                caption: 'Nilai Perpanjangan',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiTransfer',
                caption: 'Nilai Transfer',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rekbankId',
                caption: 'Rekening Penerima',
                lookup: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'rekName',
                }
            }
        ]

        // if (actionType === 'edit') {
        //     columnConfiguration.unshift(
        //         {
        //             type: 'buttons',
        //             buttons: [
        //                 'edit',
        //                 {
        //                     text: this.props.actionType === 'edit' ? 'Edit' : 'Buka',
        //                     onClick: (e) => {
        //                         let data = e.row.data;
        //                         console.log(data);
        //                     }
        //                 }
        //             ]
        //         }
        //     )
        // }

        if (actionType === 'view') {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                data.noPengajuan = this.state.dataMaster.noPengajuan
                                data.tanggalPerpanjangan = this.state.dataMaster.tanggalPengajuan
                                data.productId = this.state.dataMaster.productId
                                this.modalInstrumentDepositoRef.current.show()
                                this.modalInstrumentDepositoRef.current.retrieveData(data, this.props.actionType)
                            }
                        },
                    ]
                },
            )
            return columnConfiguration
        } else {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                data.noPengajuan = this.state.dataMaster.noPengajuan
                                data.tanggalPerpanjangan = this.state.dataMaster.tanggalPengajuan
                                this.modalInstrumentDepositoRef.current.show()
                                this.modalInstrumentDepositoRef.current.retrieveData(data, this.props.actionType)
                            }
                        },
                        {
                            text: 'Hapus',
                            hint: 'Hapus',
                            onClick: (e) => {
                                if (this.props.actionType == 'view') {
                                    notify({ message: 'Data Tidak Bisa Dihapus', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1500);
                                } else {
                                    this.deleteDetailPerpanjanganData(e.row.key)
                                }
                            }
                        },
                    ]
                },
            )

            return columnConfiguration
        }
        return columnConfiguration
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
        });
    }

    retrieveData = async (dataMaster, dataPenjualan, kajian, deposito) => {
        if (this.props.actionType === 'add') {
            this.dataPenjualan = dataPenjualan || []
            dataMaster = dataMaster || {
                noPengajuan: '<AUTO>',
                tanggalPengajuan: new Date(this.sysDate.dateValue),
                kategori: 'Deposito',
                jenisTransaksi: 'Perpanjangan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPenjualan: dataPenjualan,
                kajian: kajian
            });
            this.dataGridDetailPerpanjanganRef.current.forceRefresh(true)
        } else {
            // let diffTime = []
            // let diffDays = []
            // let result = 0
            // let tenor = []
            // let data = []
            // for (let value of dataPenjualan) {
            //     let loadAPI = `efek-balance-avgs/all-instrument-deposito?bankId=${value.bankId}&kdpId=1&productId=${value.productId}`;
            //     let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');

            //     let findBilyet = response.find(value => value.instrumentCode === value.bilyetNo);

            //     let date1 = new Date('12/31/2021')
            //     let date2 = new Date('01/01/2021')
            //     diffTime = Math.abs(date1 - date2)
            //     diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            //     let dayBases = diffDays + 1
            //     let date3 = new Date(value.tanggalPerpanjangan)
            //     let date4 = new Date(findBilyet.balanceDate)
            //     let diffTime2 = Math.abs(date3 - date4)
            //     tenor = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24))
            //     result = findBilyet.faceValue * (findBilyet.rate / 100) * (tenor / dayBases)
            //     let dataValue = {
            //         ...value,
            //         acruedToday: result,
            //         nilaiTransfer: result,
            //         rate: findBilyet.rate
            //     }
            //     data.push(dataValue)
            // }

            this.dataPenjualan = dataPenjualan || []
            this.dataDeposit = deposito
            if (dataMaster) {
                dataMaster.jenisTransaksi = 'Perpanjangan'
            }
            dataMaster = dataMaster || {
                noPengajuan: '<AUTO>',
                tanggalPengajuan: new Date(this.sysDate.dateValue),
                kategori: 'Deposito',
                jenisTransaksi: 'Perpanjangan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPenjualan: dataPenjualan,
                kajian: kajian
            });
            this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
            this.dataGridDetailPerpanjanganRef.current.forceRefresh(true)
        }

        // if(dataMaster && dataPenjualan && kajian){
        //     this.dataPenjualan = dataPenjualan || []
        //     if (dataMaster) {
        //         dataMaster.jenisTransaksi = 'Perpanjangan'
        //     }
        //     dataMaster = dataMaster || {
        //         noPengajuan: '<AUTO>',
        //         tanggalPengajuan: new Date(this.sysDate.dateValue),
        //         kategori: 'Deposito',
        //         jenisTransaksi: 'Perpanjangan'
        //     }

        //     this.setState({
        //         dataMaster: dataMaster,
        //         dataPenjualan: dataPenjualan,
        //         kajian: kajian
        //     });
        //     this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
        //     this.dataGridDetailPerpanjanganRef.current.forceRefresh(true)
        // }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async (type) => {
        var dataDataGrid = this.dataGridDetailPerpanjanganRef.current.getDataSource()
        let dataGridInsert = this.dataGridDetail;
        let dataGridInsertArr = [];
        var dataMaster = this.state.dataMaster
        var kajian = btoa(this.state.kajian)
        var kajianType = this.state.kajianContentType
    
        if (type == 'draft') {
            var draft = true
        }

        for (let value of dataGridInsert) {
            let dataValue = {
                ...value,
                'bungaPelepasan': value.acruedToday
            }

            if (value.id === undefined) {
                delete value.id
                delete value.acruedToday
            }
            dataGridInsertArr.push(dataValue)
        }

        var customSubmitData = {
            ...dataMaster,
            // instrumentDepositoList: dataDataGrid.map(value => value),
            instrumentDepositoList: dataGridInsertArr.map(value => value),
            draft: draft,
            kajian: kajian,
            kajianContentType: kajianType,
            requestOrderId: dataMaster.actionType == 'edit' ? dataMaster.requestId : null
        } 

        if (dataDataGrid.length == 0) {
            notify({ message: 'Harap isi data pengajuan terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        } else {
                if (customSubmitData.noPengajuan === '<AUTO>' && customSubmitData.actionType !== 'edit') {
                    try {
                        var insertAPI = 'order-depositos/pengajuan-perpanjangan-deposito' 
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                            values: customSubmitData
                        })
    
                        if (type == 'kirim') {
                            try {
                                await inputToTaskInvestasi(this.props.store, customSubmitData.requestOrderId, customSubmitData.orderTypeId)
                            } catch (e) {
                                console.log(e);
                                notify({ message: "Terjadi kesalahan saat input data ke task", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        }
                        notify({ message: 'Pengajuan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.hide()
                        this.props.forceRefresh()
                        return response
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                } else {
                    for (let value of dataDataGrid) {
                        if (typeof value.id === 'string'){
                            delete value.id
                        }
                        
                        let banks = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET');
                            let dataValue = {
                                    "id": value.id,
                                    "orderTypeId": dataMaster.orderTypeId,
                                    "instrumentId": this.dataDeposit.orderDepositoDTO.instrumentId,
                                    "bilyetNo": value.bilyetNo,
                                    "bankId": value.bankId,
                                    "bankName": banks.find(val => val.id === value.bankId).bankName,
                                    "rateOrder": value.rate,
                                    "amountOrder": this.dataDeposit.orderDepositoDTO.amountOrder,
                                    "periodeDays": this.dataDeposit.orderDepositoDTO.periodeDays,
                                    "statusId": "DRAFT",
                                    "tenor": value.tenor,
                                    "tenorTypeId": value.tenorTypeId,
                                    "placementDate": this.dataDeposit.orderDepositoDTO.placementDate,
                                    "maturityDate": this.dataDeposit.orderDepositoDTO.maturityDate,
                                    "withdrawDate": this.dataDeposit.orderDepositoDTO.withdrawDate,
                                    "rolloverAuto": this.dataDeposit.orderDepositoDTO.rolloverAuto,
                                    // "rolloverDate": this.dataDeposit.orderDepositoDTO.rolloverDate,
                                    "rolloverDate": value.tanggalPerpanjangan,
                                    // "rolloverTypeId": this.dataDeposit.orderDepositoDTO.rolloverTypeId,
                                    "rolloverTypeId": value.rolloverTypeId,
                                    // "rolloverAmount": this.dataDeposit.orderDepositoDTO.rolloverAmount,
                                    "rolloverAmount": value.rolloverAmount,
                                    "currencyId": this.dataDeposit.orderDepositoDTO.currencyId,
                                    "kurs": this.dataDeposit.orderDepositoDTO.kurs,
                                    "bungaPelepasan": value.acruedToday,
                                    "orderId": this.dataDeposit.orderDepositoDTO.orderId,
                                    "rekBankId": value.rekbankId,
                                    "sourceRekBankId": this.dataDeposit.orderDepositoDTO.sourceRekBankId,
                                    "orderInstruksiId": this.dataDeposit.orderDepositoDTO.orderInstruksiId
                            }
        
                            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-depositos`, 'PUT', {
                                values: dataValue,
                                key: value.id
                            }, dataValue)
        
                        notify({ message: 'Pengajuan berhasil diupdate!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.hide()
                        this.props.forceRefresh()
                    } 
                }

            
            // if (dataMaster.actionType === 'edit') {
            //     let banks = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET');
            //     for (let value of dataDataGrid) {
            //         if (typeof value.id === 'string'){
            //             delete value.id
            //         }
            //         let dataValue = {
            //                 "id": value.id,
            //                 "orderTypeId": dataMaster.orderTypeId,
            //                 "instrumentId": this.dataDeposit.orderDepositoDTO.instrumentId,
            //                 "bilyetNo": value.bilyetNo,
            //                 "bankId": value.bankId,
            //                 "bankName": banks.find(val => val.id === value.bankId).bankName,
            //                 "rateOrder": value.rate,
            //                 "amountOrder": this.dataDeposit.orderDepositoDTO.amountOrder,
            //                 "periodeDays": this.dataDeposit.orderDepositoDTO.periodeDays,
            //                 "statusId": "DRAFT",
            //                 "tenor": value.tenor,
            //                 "tenorTypeId": value.tenorTypeId,
            //                 "placementDate": this.dataDeposit.orderDepositoDTO.placementDate,
            //                 "maturityDate": this.dataDeposit.orderDepositoDTO.maturityDate,
            //                 "withdrawDate": this.dataDeposit.orderDepositoDTO.withdrawDate,
            //                 "rolloverAuto": this.dataDeposit.orderDepositoDTO.rolloverAuto,
            //                 "rolloverDate": this.dataDeposit.orderDepositoDTO.rolloverDate,
            //                 "rolloverTypeId": this.dataDeposit.orderDepositoDTO.rolloverTypeId,
            //                 "rolloverAmount": this.dataDeposit.orderDepositoDTO.rolloverAmount,
            //                 "currencyId": this.dataDeposit.orderDepositoDTO.currencyId,
            //                 "kurs": this.dataDeposit.orderDepositoDTO.kurs,
            //                 "bungaPelepasan": value.acruedToday,
            //                 "orderId": this.dataDeposit.orderDepositoDTO.orderId,
            //                 "rekBankId": value.rekbankId,
            //                 "sourceRekBankId": this.dataDeposit.orderDepositoDTO.sourceRekBankId,
            //                 "orderInstruksiId": this.dataDeposit.orderDepositoDTO.orderInstruksiId
            //         }

            //         await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-depositos`, 'PUT', {
            //             values: dataValue,
            //             key: value.id
            //         }, dataValue)
            //     }

            //     notify({ message: 'Pengajuan berhasil diupdate!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            //     this.hide()
            //     this.props.forceRefresh()
            // } else {
            //     if (customSubmitData) {
            //         try {
            //             var insertAPI = 'order-depositos/pengajuan-perpanjangan-deposito' 
            //             var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
            //                 values: customSubmitData
            //             })
    
            //             if (type == 'kirim') {
            //                 try {
            //                     await inputToTaskInvestasi(this.props.store, customSubmitData.requestOrderId, customSubmitData.orderTypeId)
            //                 } catch (e) {
            //                     console.log(e);
            //                     notify({ message: "Terjadi kesalahan saat input data ke task", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            //                 }
    
            //             }
            //             notify({ message: 'Pengajuan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            //             this.hide()
            //             this.props.forceRefresh()
            //             return response
            //         } catch (e) {
            //             console.log(e)
            //             notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            //         }
            //     }
            // }
        }
    }

    loadDetailPerpanjangan = () => {
        return this.dataPenjualan
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindexPerpanjanganDeposito-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexPerpanjanganDeposito-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) => {
        this.setState({
            kajian: e.value
        })
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Instrument Perpanjangan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.showModalInstrumentPerpanjangan(this.props.actionType)
                        }
                    },
                },
                visible: this.props.actionType == 'edit' || this.props.actionType === 'view' ? false : true
            }
        )
    }

    showModalInstrumentPerpanjangan = (type) => {
        this.modalInstrumentDepositoRef.current.show()
        this.modalInstrumentDepositoRef.current.retrieveData({
            noPengajuan: this.state.dataMaster.noPengajuan,
            productId: this.state.dataMaster.productId,
            tanggalPerpanjangan: this.state.dataMaster.tanggalPerpanjangan
        }, type)
    }

    submitDetailPerpanjanganData = (data) => {
        this.dataGridDetail.push(data);
        if (data.id) {
            this.dataGridDetailPerpanjanganRef.current.push([{
                type: "update",
                data: {
                    noPengajuan: data.noPengajuan,
                    productId: data.productId,
                    instrumentCode: data.instrumentCode,
                    instrumentName: data.instrumentName,
                    bilyetNo: data.bilyetNo,
                    rolloverTypeId: data.rolloverTypeId,
                    maturityDatePerpanjangan: data.maturityDatePerpanjangan,
                    tenorFirst: data.tenorFirst,
                    tenorFirsTypeId: data.tenorFirsTypeId,
                    tanggalPerpanjangan: data.tanggalPerpanjangan,
                    bankId: data.bankId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nominalPerpanjangan: data.nominalPerpanjangan,
                    nominalPokok: data.nominalPokok,
                    nilaiTransfer: data.nilaiTransfer,
                    efekBalanceAvgId: data.efekBalanceAvgId,
                    rateFirst: data.rateFirst,
                    rate: data.rate,
                    // acruedInterest: data.acruedInterest,
                    acruedToday: data.acruedToday,
                    maturityDate: data.maturityDate,
                    rekbankId: data.rekbankId,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    kpdId: 1
                },
                key: data.id
            }])
        } else {
            this.dataGridDetailPerpanjanganRef.current.push([{
                type: "insert",
                data: {
                    noPengajuan: data.noPengajuan,
                    productId: data.productId,
                    instrumentCode: data.instrumentCode,
                    instrumentName: data.instrumentName,
                    bilyetNo: data.bilyetNo,
                    rolloverTypeId: data.rolloverTypeId,
                    maturityDatePerpanjangan: data.maturityDatePerpanjangan,
                    tenorFirst: data.tenorFirst,
                    tenorFirsTypeId: data.tenorFirsTypeId,
                    tanggalPerpanjangan: data.tanggalPerpanjangan,
                    bankId: data.bankId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nominalPerpanjangan: data.nominalPerpanjangan,
                    nominalPokok: data.nominalPokok,
                    nilaiTransfer: data.nilaiTransfer,
                    efekBalanceAvgId: data.efekBalanceAvgId,
                    rateFirst: data.rateFirst,
                    rate: data.rate,
                    acruedToday: data.acruedToday,
                    maturityDate: data.maturityDate,
                    rekbankId: data.rekbankId,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    kpdId: 1
                }
            }])
        }
    }
    deleteDetailPerpanjanganData = (key) => {
        this.dataGridDetailPerpanjanganRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    forceRefresh = () => {
        this.dataGridDetailPerpanjanganRef.current.forceRefresh(true)
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Usulan Perpanjangan Investasi Deposito'}
                    minWidth={'90vh'}
                    minHeight={'90vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={3}
                                    id={'formMaster'}
                                    ref={this.formMasterRef}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={false}
                                    labelLocation={"left"}
                                />
                            </div>
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                            <div id="tabindexPerpanjanganDeposito-0" className={' col-md-12'}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <DevExpressDataGrid
                                            ref={this.dataGridDetailPerpanjanganRef}
                                            loadAPI='kpds'
                                            insertAPI='kpds'
                                            updateAPI='kpds'
                                            deleteAPI='kpds'

                                            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                            useArraySource={true}
                                            ArraySourceData={this.loadDetailPerpanjangan}

                                            allowAdding={false}
                                            allowDeleting={false}
                                            allowUpdating={false}

                                            exportExcel={false}
                                            exportFileName={"Instrument"}
                                            allowExportSelectedData={true}
                                            selection={"multiple"}

                                            showBorders={true}

                                            editingMode={'cell'}

                                            paging={true}
                                            defaultPageSize={10}
                                            height={'calc(100vh - 380px)'}

                                            //bagian konfigurasi popup saat insert dan edit record
                                            popupTitle={'Instrument'}
                                            popupWidth={700} //masukan dalam ukuran pixel
                                            popupHeight={500} //masukkan dalam ukuran pixel

                                            popupFormLabelLocation='left' //accepted value = top, left, right
                                            popupFormMinColWidth={300} // minimum lebar kolom
                                            popupFormColCount={3} //jumlah kolom pada form

                                            //akhir bagian konfigurasi popup

                                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                            FilterRow={true} // set false untuk mematikan fitur filter

                                            ColumnConfiguration={this.getDataGridColumnConfiguration(this.props.actionType)} // taruh konfigurasi kolom disini
                                            // SummaryConfiguration={this.summary}

                                            onToolbarPreparing={this.onToolbarPreparing}

                                            //contoh konfigurasi kolom
                                            //this.columns = [{
                                            //    dataField: 'kolom1',
                                            //    caption: 'Ini Kolom 1'
                                            //}, {
                                            //    dataField: 'kolom2',
                                            //    caption: 'Ini Kolom 2'
                                            //}]
                                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                            store={this.props.store} // jangan di edit edit
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="tabindexPerpanjanganDeposito-1" className={'d-none col-md-12'}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <HtmlEditor
                                            ref={this.kajianPengajuanRef}
                                            height="calc(100vh - 380px)"
                                            value={this.state.kajian}
                                            valueType={this.state.kajianContentType}
                                            name={'kajian'}
                                            onValueChanged={this.onValueChanged}
                                            readOnly={this.props.actionType == 'view' ? true : false}
                                        >
                                            <MediaResizing enabled={true} />
                                            <Toolbar>
                                                {
                                                    this.toolbarItem.map((toolbarItem) => {
                                                        return (
                                                            <Item
                                                                formatName={toolbarItem}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Toolbar>
                                        </HtmlEditor>
                                    </div>
                                </div>
                            </div>
                            <FormSubInstrumentPerpanjanganDeposito
                                ref={this.modalInstrumentDepositoRef}
                                store={this.props.store}
                                submitDetailPerpanjanganData={this.submitDetailPerpanjanganData}
                                forceRefresh={this.forceRefresh}
                                handleData={(data => this.handleDataFormInstrument(data))}
                                type={this.props.actionType}
                            />
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormPengajuanPerpanjanganDeposito