import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import { TextBox,Button } from 'devextreme-react';
import notify from 'devextreme/ui/notify';

import {httpRequest} from 'plugin/httprequest'

class ChangePassword extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            oldPassword: null,
            newPassword: null,
            newPasswordRepeat: null,
            validationError: {
                oldPassword: {},
                newPassword: {},
                newPasswordRepeat: {}
            },
            isValid: {
                oldPassword: true,
                newPassword: true,
                newPasswordRepeat: true
            }
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })

        if(!this.checkRepeatNewPassword()){
            return
        }

        this.setState(prevState => ({
            validationError: {
                ...prevState.validationError,
                [fieldName]: {}
            },
            isValid: {
                ...prevState.isValid,
                [fieldName]: true
            }
        }))
    }

    submitChangePassword = (e) => {
        try {
            let type = 'success'
            let text = 'The password has been changed successfully!'
            let invalidStatusCount = this.checkFieldNotNull()

            if (invalidStatusCount > 0 || !this.checkRepeatNewPassword()) {
                let type = 'warning'
                let text = 'Some fields are invalid, please recheck the value!'
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                return
            }

            this.submitData()
            
            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
            
            this.setState({
                popupVisible: false,
                oldPassword: '',
                newPassword: '',
                newPasswordRepeat: ''
            })
        } catch (error) {
            console.log(e)
            notify({ message: 'Error', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    submitData = async () => {
        let loadAPI = 'account/change-password'
        var response = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPI, 'POST', {
            values: {
                currentPassword: this.state.oldPassword,
                newPassword: this.state.newPassword
            }
        }) 
    }

    checkFieldNotNull = () => {
        let invalidStatusCount = 0

        if(!this.state.oldPassword){
            this.setState(prevState => ({
                isValid:{
                    ...prevState.isValid,
                    oldPassword: false,
                },
                validationError:{
                    ...prevState.validationError,
                    oldPassword: {message: 'Please fill the field!'},
                }
            }))

            invalidStatusCount++
        }

        if(!this.state.newPassword){
            this.setState(prevState => ({
                isValid:{
                    ...prevState.isValid,
                    newPassword: false,
                },
                validationError:{
                    ...prevState.validationError,
                    newPassword: {message: 'Please fill the field!'},
                }
            }))

            invalidStatusCount++
        }

        if(!this.state.newPasswordRepeat){
            this.setState(prevState => ({
                isValid:{
                    ...prevState.isValid,
                    newPasswordRepeat: false,
                },
                validationError:{
                    ...prevState.validationError,
                    newPasswordRepeat: {message: 'Please fill the field!'},
                }
            }))

            invalidStatusCount++
        }

        return invalidStatusCount
    }

    checkRepeatNewPassword = () => {
        let status = true
        if(this.state.newPassword !== null && this.state.newPasswordRepeat !== null){
            if(this.state.newPassword !== this.state.newPasswordRepeat){
                this.setState(prevState => ({
                    isValid:{
                        ...prevState.isValid,
                        newPassword: false,
                        newPasswordRepeat: false
                    },
                    validationError:{
                        ...prevState.validationError,
                        newPassword: {message: 'New password and repeat password not match!'},
                        newPasswordRepeat: {message: 'New password and repeat password not match!'},
                    }
                }))

                status = false
            }else{
                this.setState(prevState => ({
                    isValid:{
                        ...prevState.isValid,
                        newPassword: true,
                        newPasswordRepeat: true
                    },
                    validationError:{
                        ...prevState.validationError,
                        newPassword: {},
                        newPasswordRepeat: {},
                    }
                }))
            }
        }

        return status
    } 

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Change Password'} 
                width={400}
                height={260}

                ref={this.PopupRef}
            >
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>Old Password</div>
                    <div className={'dx-field-value'}>
                        <TextBox 
                            mode={'password'} 
                            name={'oldPassword'} 
                            onValueChanged={this.onValueChanged} 
                            isValid={this.state.isValid.oldPassword}
                            validationError={this.state.validationError.oldPassword}
                            value={this.state.oldPassword}    
                            />
                    </div>
                </div>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>New Password</div>
                    <div className={'dx-field-value'}>
                        <TextBox 
                            mode={'password'} 
                            name={'newPassword'} 
                            onValueChanged={this.onValueChanged}
                            isValid={this.state.isValid.newPassword}
                            validationError={this.state.validationError.newPassword}
                            value={this.state.newPassword}
                        />
                    </div>
                </div>
                <div className={'dx-field'}>
                    <div className={'dx-field-label'}>New Password Repeat</div>
                    <div className={'dx-field-value'}>
                        <TextBox 
                            mode={'password'} 
                            name={'newPasswordRepeat'} 
                            onValueChanged={this.onValueChanged}
                            isValid={this.state.isValid.newPasswordRepeat}
                            validationError={this.state.validationError.newPasswordRepeat}
                            onEnterKey={(e) => { this.submitChangePassword(e) }}
                            value={this.state.newPasswordRepeat}
                        />
                    </div>
                </div>
                <div className={'dx-field'}>
                    <div className={'dx-field-value'}>
                    <Button 
                        type={'success'}
                        text={'Submit'}
                        onClick={(e) => {this.submitChangePassword(e)}}
                    />
                    </div>
                </div>
            </Popup>
        );
    }
}


export default ChangePassword