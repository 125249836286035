import React, { Component } from 'react'
import { formatDate} from 'plugin/helper';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class FormDataRegistrasiPensiun extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pesertaId: 0,
            formData: {}
        }

        this.formRef = React.createRef()
        this.dataGridPesertaAktifBaruRef = React.createRef()

        this.items = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Simpan',
                        hint : 'Simpan',
                        onClick : (e) => {
                            console.log(e)
                        },
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                itemType: 'group',
                name: 'skPensiun',
                caption: 'SK Pensiun',
                items: [
                    {
                        dataField: 'no',
                        caption: 'No',
                    },
                    {
                        dataField: 'tanggal',
                        caption: 'Tanggal',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'fileSkPensiun',
                        caption: 'File',
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'skPemberhentian',
                caption: 'SK Pemberhentian',
                items: [
                    {
                        dataField: 'no',
                        caption: 'No',
                    },
                    {
                        dataField: 'tanggal',
                        caption: 'Tanggal',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'fileSkPensiun',
                        caption: 'File',
                    },
                ]
            },
        ]

        this.dummyData = [
            {
                id: 1,
                nik: 3215250302010003,
                fullName: 'Kelvin Febrian Go',
                phdp: 5000000
            }
        ]
    }

    passDataToParent = () => {
        var data = {...this.state.formData}
        data.tanggalEfektif = data.tanggalEfektif || formatDate(new Date())
        return data
    }

    removeState = () => {
        this.setState({
            formData: {},
        })
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridPesertaAktifBaruRef}
                    loadAPI={`pesertas`}
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData = {this.props.data}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Data Peserta Aktif Baru"}

                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Peserta Aktif Baru'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.items} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing = {this.onToolbarPreparingPesertaAktifBaru}

                    height = {'calc(100vh - 470px)'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default FormDataRegistrasiPensiun
