import React, { Component } from 'react'
import {Popup, Form} from 'devextreme-react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import {  akunHR,currency, pembayaranUmumDetilByPuId } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import { download, formatDate, formatNumberAum, formatUploadFileData, formatUploadFileNameAndExtention, imageSource, summaryValue } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';
import ModalDetailInvoice from './detailInvoice';
import notify from 'devextreme/ui/notify';
import { pembayaranUmumDetailSdm } from 'dataSource/lookup'

class PPListInvoiceDetailModal extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {
                currencyId:1001,
                currencyRate:1
            },
            
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.readOnly = ''
        this.listDetailInvoice = []
        this.PopupRef = React.createRef()
        this.DataGridDetailRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.showModalDetailInvoiceRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.PopupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: this.updateData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.detailColumn = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.removeData(e.row.data)
                        }
                    },
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetailInvoice(e)
                        }
                    },
                ],
            },
            {
                dataField:'detail',
                caption:'Detail Invoice',
                alignment:'center',
                columns:[
                    {
                        dataField:'pembayaranUmumDetilId',
                        caption:'Pembayaran Detail',
                        lookup:{
                            dataSource:pembayaranUmumDetailSdm(this.props.store),
                            valueExpr:'id',
                            displayExpr:'keterangan'
                        }
                    },
                    {
                        dataField:'keterangan',
                        caption:'Keterangan'
                    },
                    {
                        dataField:'nominal',
                        caption:'Nominal',
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                    {
                        dataField:'ppn',
                        caption:'PPN',
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                    {
                        dataField:'pph',
                        caption:'PPH',
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                    {
                        dataField:'potongan',
                        caption:'Potongan',
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                    {
                        dataField:'nominalBersih',
                        caption:'Nominal Bersin',
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                ]
            },
            
        ]

        this.dataMaster = [
            {
                dataField: 'pengajuanNo',
                label: {
                    alignment: 'left',
                    text: 'No Pengajuan'
                },
                editorOptions: {
                    readOnly: true
                }
                // editorType: 'dxSelectBox',
                // editorOptions: {
                //     dataSource: [],
                //     valueExpr: 'id',
                //     displayExpr: (e) => {
                //         return e && e.keterangan + '@' + formatNumberAum(e.pengajuanNominal)
                //     },
                //     onSelectionChanged: async (e) => {
                //         var selected = e.selectedItem
                //         if (selected) {
                //             var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `getDetailPengajuanByPembayaranUmumDetailId/${selected.id}`, 'GET')
                //             this.formMasterRef.current.instance.updateData({
                //                 pembayaranUmumId: selected.pembayaranUmumId,
                //             })
                //         }
                //     },
                //     searchEnabled: true,
                //     deferRendering: false
                // }
            },
            {
                dataField: 'pembayaranUmumId',
                label: {
                    alignment: 'left',
                    text: 'Pembayaran ID'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField:'invoiceNo',
                label:{
                    text:'No Tagihan'
                }
            },
            {
                dataField:'invoiceDate',
                label:{
                    text:'Tanggal Tagihan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
            },
            {
                dataField:'dueDate',
                label:{
                    text:'Tanggal Jatuh Tempo'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
            },
            // {
            //     dataField:'totalNominalBersih',
            //     label:{
            //         text:'Total Nominal Besih'
            //     },
            //     editorType: "dxNumberBox",
            //     editorOptions: {
            //         format: "#,##0.00",
            //         readOnly:true
            //     },
            // },
            {
                dataField:'currencyId',
                label:{
                    text:'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode', // provides display values
                    searchEnabled: true,
                    deferRendering: false
                }
            },
            {
                dataField:'currencyRate',
                label:{
                    text:'Kurs'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    readOnly:true
                },
            },
            {
                dataField:'pembayaran',
                label:{
                    text:'Pembayaran'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id:"Sisa Pembayaran",
                            value:"Sisa Pembayaran"
                        },
                        {
                            id:"Uang Muka",
                            value:"Uang Muka"
                        },
                        {
                            id:"Cicilan",
                            value:"Cicilan"
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false
                }
            },
            {
                dataField:'entitasId',
                label:{
                    text:'Invoice Pembayaran Kepada'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true,
                    onSelectionChanged:(e) => {
                        if (e.selectedItem) {
                            this.formMasterRef.current.instance.updateData({assetPembelianId:e.selectedItem.reff})
                        }
                    }
                }
            },
            {
                dataField:'assetPembelianId',
                label:{
                    text:'Reff'
                },
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField:'description',
                label:{
                    text:'Keterangan'
                }
            },
        ]
    }

    getEntity = async () => {
        var paramEntity = ['KR','SP','P3','CN','GV']
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllMultipleByType/${paramEntity.join()}`, 'GET')
        this.formMasterRef.current.instance.getEditor('entitasId').option('dataSource', data)
    }

    getNoPengajuan = async (data) => {
        var loadApi = `pembayaran-umums/${data.id}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'GET')
        this.formMasterRef.current.instance.updateData({
            pengajuanNo:getData.noPengajuan + " - " +getData.keterangan,
            pembayaranUmumId:data.id
        })

        // var loadApiDetail = `pembayaran-umum-detils/getByPembayaranUmumId/${data.id}`
        // var getDataDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApiDetail, 'GET')

        // var totalJumlahCo = 0

        // for (var value of getDataDetail) {
        //     totalJumlahCo += value.cashOut || 0
        // }
        // getData.nominalCashOut = totalJumlahCo

        // var pdId = this.formMasterRef.current.instance.getEditor('pengajuanNo')
        // // pdId.option('value', '')
        // if (getData.id) {
        //     pdId.option('dataSource', pembayaranUmumDetilByPuId(getData.id, this.props.store, true))
        // }
    }

    getDataPembayaranUmum = (data) => {
        this.formMasterRef.current.instance.updateData({
            entitasId:data.entitiId
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalDetailInvoice(e)
                    },
                }
            },
        )
    }

    showModalDetailInvoice = (e) => {
        if (e.row) {
            this.showModalDetailInvoiceRef.current.retrieveData(e.row.data)
        }
        this.showModalDetailInvoiceRef.current.getNoPengajuan(this.state.dataMaster.pembayaranUmumId)
        this.showModalDetailInvoiceRef.current.show()
    }

    retrieveData = async (data) => {
        var getDetailInvoice = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-invoice-details/getByInvoiceId/${data.id}`, 'GET')
        this.listDetailInvoice = getDetailInvoice
        data.pengajuanNo = data.invoiceNo
        this.setState({
            dataMaster:data
        })
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.listDetailInvoice = []
        this.DataGridDetailRef.current.forceRefresh(true)
        this.setState({
            popupVisible: false,
            dataMaster: {
                currencyId:1001,
                currencyRate:1
            },
            newData:true
        })
    }

    getDataSource = async (data) => {
        try{
            var key = data.id
            var title, text

            if(key){
                this.DataGridDetailRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                title = 'Berhasil!'
                text = 'Data Berhasil Diubah!'
              }else{
                this.DataGridDetailRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                title = 'Berhasil!'
                text = 'Data Berhasil Ditambah!'
            }
            
            await alert(text, title)
        }catch(e){
            console.log(e)
        }
    }

    removeData = async (data) => {
        this.DataGridDetailRef.current.push([
            {
                type: 'remove',
                key : data.id
            }
        ])
        await alert('Data Berhasil Dihapus', 'Berhasil')
    }

    submitData = async () => {
        try {
            var dataMaster = this.state.dataMaster
            var loadAPI = `pembayaran-umum-invoices`
            var getDataGridDetail = this.DataGridDetailRef.current.getDataSource()

            dataMaster.dueDate = formatDate(dataMaster.dueDate)
            dataMaster.invoiceDate = formatDate(dataMaster.invoiceDate)
            dataMaster.nominalBersih = summaryValue(getDataGridDetail,'nominalBersih')
            dataMaster.transactionNominal = summaryValue(getDataGridDetail,'nominal')
            dataMaster.discountNominal = summaryValue(getDataGridDetail,'potongan')
            dataMaster.ppn = summaryValue(getDataGridDetail,'ppn')
            dataMaster.pph = summaryValue(getDataGridDetail,'pph')
            dataMaster.statusId = 1
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
                values: dataMaster,
            })

            if (response) {
                for(var data of getDataGridDetail){
                    if (typeof data.id === "string") {
                        delete data.id
                        data.pembayaranUmumInvoiceId = response.id 
                         this.addDataDetail(data)
                    }else{
                         this.editDataDetail(data)
                    }
                }

                var getDeleted = this.DataGridDetailRef.current.getDeletedIndex()
                for(var deletedData of getDeleted){
                    await this.deleteDataDetail(deletedData)
                }
                

            await alert('Sukses Input Data','Berhasil')
            this.props.forceRefresh()
            this.hide()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    updateData = async () => {
        try {
            var dataMaster = this.state.dataMaster
            var loadAPI = `pembayaran-umum-invoices`
            var getDataGridDetail = this.DataGridDetailRef.current.getDataSource()

            dataMaster.dueDate = formatDate(dataMaster.dueDate)
            dataMaster.invoiceDate = formatDate(dataMaster.invoiceDate)
            dataMaster.nominalBersih = summaryValue(getDataGridDetail,'nominalBersih')
            dataMaster.transactionNominal = summaryValue(getDataGridDetail,'nominal')
            dataMaster.discountNominal = summaryValue(getDataGridDetail,'potongan')
            dataMaster.ppn = summaryValue(getDataGridDetail,'ppn')
            dataMaster.pph = summaryValue(getDataGridDetail,'pph')
            dataMaster.statusId = 1
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                values: dataMaster,
                key: dataMaster.id,
            }, dataMaster)

            if (response.id) {
                for(var data of getDataGridDetail){
                    if (typeof data.id === "string") {
                        delete data.id
                        data.pembayaranUmumInvoiceId = response.id
                         this.addDataDetail(data)
                    }else{
                         this.editDataDetail(data)
                    }
                }

                var getDeleted = this.DataGridDetailRef.current.getDeletedIndex()
                for(var deletedData of getDeleted){
                    await this.deleteDataDetail(deletedData)
                }

                await alert('Sukses Ubah Data','Berhasil')
                this.hide()
                this.props.forceRefresh()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    addDataDetail = async(data) => {
        try {
            var loadAPI = `pembayaran-umum-invoice-details`
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
                values: data,
            })
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    editDataDetail = async(data) => {
        try {
            var loadAPI = `pembayaran-umum-invoice-details`

            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                values: data,
                key:data.id
            },data)
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
        
    }

    deleteDataDetail = async (data) => {
        try {
            var loadAPI = `pembayaran-umum-invoice-details`
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE',data)
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    loadData = () => {
        return this.listDetailInvoice
    }


    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Invoice'}
                width={950}
                height={700}

                ref={this.PopupRef}

                toolbarItems = {this.props.action === 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="mb-5">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.dataMaster}
                            items={this.dataMaster}
                            labelLocation = 'left'
                            readOnly={this.readOnly}
                        />
                    </div>
                    <div className={' mt-2 text-center' } style={{height: '100%'}}>
                        <div className="container-fluid">
                        <DevExpressDataGrid 
                            ref = {this.DataGridDetailRef}
                            loadAPI = 'pembayaran-umum-detils'
                            insertAPI = 'pembayaran-umum-detils'
                            updateAPI = 'pembayaran-umum-detils'
                            deleteAPI = 'pembayaran-umum-detils'

                            backendserver = {process.env.REACT_APP_BACKEND_HRD}
                            // remoteOperations = {true}

                            useArraySource={true}
                            ArraySourceData={this.listDetailInvoice}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            showBorders = {true}

                            paging = {true}
                            defaultPageSize = {10}
                            
                            editingMode={'popup'}
                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle = {'Data Detail Biaya'}
                            popupWidth = {500} //masukan dalam ukuran pixel
                            popupHeight = {400} //masukkan dalam ukuran pixel

                            popupFormLabelLocation = 'left' //accepted value = top, left, right
                            popupFormMinColWidth = {300} // minimum lebar kolom
                            popupFormColCount = {1} //jumlah kolom pada form
                            height={400}
                            //akhir bagian konfigurasi popup

                            ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

                            FilterRow = {true} // set false untuk mematikan fitur filter
                            
                            ColumnConfiguration={this.detailColumn} // taruh konfigurasi kolom disini
                            onToolbarPreparing = {this.onToolbarPreparing}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                            
                            store = {this.props.store} // jangan di edit edit
                        />
                        </div>
                    </div>
                </div>
                </ScrollView>
                <ModalDetailInvoice 
                    ref={this.showModalDetailInvoiceRef}
                    store={this.props.store}
                    getDataSource={this.getDataSource}
                />
            </Popup>
        )
    }
}

export default PPListInvoiceDetailModal