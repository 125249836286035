import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, getSystemDate } from 'plugin/helper'

class LaporanAsetNeto extends Component {
    constructor(props) {
        super(props)

        this.firstDateAccounting = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'PA-DATE') : null
        this.state = {
            filterData: {
                productId: 1,
                balanceDate: getSystemDate(this.props.store)
            }
        }
        this.columns = [
            {
                dataField: 'akun',
                caption: 'Uraian',
                // cellRender: (e) => {
                //     var data = e.row.data
                //     var name = data.nama
                //     var nameSplit = name.split('-')
                //     var count = nameSplit.length
                //     return <span style={{paddingLeft: `${(count - 1) *3}px`}}>{nameSplit[count - 1]}</span>
                // }
            },
            {
                dataField: 'tahun_berjalan',
                caption: 'Tahun Berjalan',
            },
            {
                dataField: 'tahun_sebelumnya',
                caption: 'Tahun Sebelumnya',
            },
        ]
        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'balanceDate',
                        label: {
                            text: 'Tanggal Balance'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            readOnly: true
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Load Data",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Aset Neto</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    loadAPI={`akuns/getReportAsetNeto/${this.state.filterData.productId}/${this.firstDateAccounting.dateValue}/${formatDate(this.state.filterData.balanceDate)}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Aset Neto"}
                    allowExportSelectedData={true}
                    selection={false}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanAsetNeto