import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import { currency, kjpp } from 'dataSource/lookup';
import ModalValuasiPenyertaan from 'components/pages/modal/investasi/aset-non-portfolio/valuasi/penyertaan/index';
import { addURL } from 'redux/actions/url';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class ValuasiPenyertaan extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            filterItem: {
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        }

        this.modalValuasiPenyertaanRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            startDate: formatDate(e.value)
                        })
                    }
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            endDate: formatDate(e.value)
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalValuasiProperti('edit', e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            // this.showModalValuasiProperti('edit', e.row.data)
                        }
                    },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.prosesValuasiPenyertaan(e.row.data)
                        }
                    },
                    {
                        text: 'View Jurnal',
                        hint: 'View Jurnal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalJurnal(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'efekValuasiId',
                caption: 'No',
            },
            {
                dataField: 'tglValuasi',
                caption: 'Tanggal Valuasi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'noValuasi',
                caption: 'No. Valuasi',
            },
            {
                dataField: 'produk',
                caption: 'Product',
            },
            {
                dataField: 'kjppId',
                caption: 'KJPP',
                lookup: {
                    dataSource: kjpp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'kjppName'
                }
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode',
            },
            {
                dataField: 'instrumentName',
                caption: 'Instrument',
            },
            {
                dataField: 'jmlUnit',
                caption: 'Jumlah Unit',
            },
            {
                dataField: 'nominalPenempatan',
                caption: 'Nominal Penempatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nominalValuasi',
                caption: 'Nomina Valuasi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nominalPerLembar',
                caption: 'Nominal / Lembar Valuasi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'catatan',
                caption: 'Keterangan',
            },
            {
                dataField: 'proses',
                caption: 'Proses',
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Transaksi',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalValuasiPenyertaan('add')
                    },
                }
            },
        )
    }

    showModalValuasiPenyertaan = (type, data) => {
        if(type == 'add'){
            data = {
                noValuasi: '<AUTO>'
            }
        }
        this.modalValuasiPenyertaanRef.current.show()
        this.modalValuasiPenyertaanRef.current.retrieveData(type, data)
    }

    showModalInvoiceValuasiPenyertaan = (data = null) => {
        this.modalInvoiceValuasiPenyertaanRef.current.show()
        this.modalInvoiceValuasiPenyertaanRef.current.retrieveData(data)
    }
    prosesValuasiPenyertaan = async (data) => {
        var id = data.efekValuasiId
        if(id){
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-valuasis/valuasi-penyertaan-langsung-proses/${id}`, 'POST', {values: {}})
                // if(response){
                notify({ message: 'Data berhasil di proses!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                // }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Valuasi Penyertaan</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    keyField={'efekValuasiId'}
                    ref={this.dataGridRef}
                    loadAPI={`efek-valuasis/valuasi-penyertaan-langsung-list/${formatDate(this.state.filterItem.startDate)}/${formatDate(this.state.filterItem.endDate)}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Valuasi Penyertaan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Valuasi Penyertaan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalValuasiPenyertaan
                    ref={this.modalValuasiPenyertaanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />

            </div>
        )
    }
}

export default ValuasiPenyertaan