import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { confirm, alert } from 'devextreme/ui/dialog';
import { entityType, currency } from 'dataSource/lookup'
import ModalCashSettlementVoucher from 'components/pages/modal/akutansi/cash-settlement/voucher'
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';

class CashSettlementVerified extends Component {
    constructor(props) {
        super(props)

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.username = props.store.getState().sessionUser

        this.state = {
            formFilter: {
                startDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                endDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                status: 'O'
            }
        }

        this.dataGridRef = React.createRef()
        this.modalCashSettlementVoucherRef = React.createRef()

        this.selectedRowDatas = []

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Tanggal Voucher",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            // {
            //     dataField: "status",
            //     label: {
            //         text: "Status",
            //         alignment: "left",
            //         location: "left"
            //     },
            //     editorType: "dxSelectBox",
            //     editorOptions: {
            //         dataSource: [
            //             {
            //                 id: 'O',
            //                 value: 'Open'
            //             },
            //             {
            //                 id: 'C',
            //                 value: 'Cancel'
            //             },
            //             {
            //                 id: 'P',
            //                 value: 'Posted'
            //             },
            //         ],
            //         displayExpr: 'value',
            //         valueExpr: 'id',
            //         searchEnabled: true,
            //     }
            // },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.dataGridRef.current.forceRefresh(true)
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Voucher',
                        hint: 'Lihat Voucher',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.modalCashSettlementVoucherRef.current.show()
                            this.modalCashSettlementVoucherRef.current.retrieveData(e.row.data)
                        }
                    },
                    {
                        text: 'Verifikasi',
                        hint: 'Verifikasi',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            if (e.row.data.status === 'C') {
                                notify({ message: 'Voucher Telah Dibatalkan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            if (e.row.data.status === 'P') {
                                notify({ message: 'Voucher Telah Diposting', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return false
                            }
                            let result = await confirm("Apakah anda yakin untuk memverifikasi voucher ini?", "Konfirmasi");
                            if (result) {
                                this.posting(e.row.data)
                            }
                        }
                    },
                    // {
                    //     text: 'Tolak',
                    //     hint: 'Tolak',
                    //     cssClass: 'text-success',
                    //     onClick: async (e) => {
                    //         if (e.row.data.status === 'C') {
                    //             notify({ message: 'Voucher Telah Dibatalkan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    //             return false
                    //         }
                    //         if (e.row.data.status === 'P') {
                    //             notify({ message: 'Voucher Telah Diposting', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    //             return false
                    //         }
                    //         let result = await confirm("Apakah anda yakin untuk membatalkan voucher ini?", "Konfirmasi");
                    //         if (result) {
                    //             this.cancel(e.row.data)
                    //         }

                    //     }
                    // },
                ],
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'RV',
                            value: 'Request Verification'
                        },
                        {
                            id: 'A',
                            value: 'Verified'
                        },
                        {
                            id: 'F',
                            value: 'Verification Failed'
                        },
                        {
                            id: 'R',
                            value: 'Rejected'
                        },
                        {
                            id: 'O',
                            value: 'Open'
                        },
                        {
                            id: 'C',
                            value: 'Cancel'
                        },
                        {
                            id: 'P',
                            value: 'Posted'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'voucherNo',
                caption: 'No Voucher',
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'entityName',
                caption: 'Entitas'
            },
            {
                dataField: 'entityType',
                caption: 'Tipe Entitas',
                lookup: {
                    dataSource: entityType(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            },
            {
                dataField: 'type',
                caption: 'Tipe',
                lookup: {
                    dataSource: [
                        {
                            id: 'P',
                            value: 'Payment'
                        },
                        {
                            id: 'R',
                            value: 'Receive'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'entryDate',
                caption: 'Tanggal Entri',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'valueDate',
                caption: 'Tanggal Jurnal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'settleWith',
                caption: 'Pembayaran Dengan',
                lookup: {
                    dataSource: [
                        {
                            id: 'C',
                            value: 'Uang Tunai'
                        },
                        {
                            id: 'B',
                            value: 'Bank'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'amount',
                caption: 'Total Pembayaran',
                format: '#,##0.00; #,##0.00',
                alignment: 'right'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var status = this.state.formFilter.status
        // if(this.state.formFilter.status.length > 0){
        //     status = this.state.formFilter.status.join(',')
        // }else{
        //     status = "O,C,P"
        // }
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/getByStatusAndValueDateBetween/RV/${formatDate(this.state.formFilter.startDate)}/${formatDate(this.state.formFilter.endDate)}`)

        return data
    }

    posting = async (data, multiple = false) => {
        try {
            this.props.store.dispatch(showLoading(true))


            var dataVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/${data.cashVoucherId}`)
            const responseInsertVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'cash-voucers', 'PUT', {
                values: {
                    status: "A",
                    verifikasiBy: this.username
                }
            }, dataVoucher)

            if (responseInsertVoucher) {
                try {
                    var loadAPITask = `tasks?referenceId.equals=${data.cashVoucherId}&taskStatusId.equals=1&taskTypeId.equals=60`;
                    var getAPITask = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPITask, 'GET');

                    if (getAPITask.length === 0) {
                        this.props.store.dispatch(showLoading(false))
                        this.forceRefresh()
                        notify({ message: 'Sukses Melakukan Verifikasi Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    } else {
                        var valueTask = getAPITask[0];
                        valueTask.taskStatusId = 2

                        await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'tasks', 'PUT', {
                            key: valueTask.id,
                            values: valueTask
                        }, valueTask);

                        await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps/taskApprovalCashVoucer/${data.cashVoucherId}`, 'POST', {
                            values: {}
                        })

                        this.props.store.dispatch(showLoading(false))
                        this.forceRefresh()
                        notify({ message: 'Sukses Melakukan Verifikasi Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    }
                } catch (error) {
                    this.props.store.dispatch(showLoading(false))
                    console.log(error)
                    notify({ message: 'Gagal Melakukan Verifikasi Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }


            // this.props.store.dispatch(showLoading(false))
            // this.forceRefresh()
            // notify({ message: 'Sukses Melakukan Verifikasi Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            console.log(e)
            notify({ message: 'Gagal Melakukan Verifikasi Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    cancel = async (data) => {
        try {
            this.props.store.dispatch(showLoading(true))
            var dataVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/${data.cashVoucherId}`)
            var dataVoucherDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucer-detils/getByCashVoucherId/${data.cashVoucherId}`)

            for (const voucherDetil of dataVoucherDetil) {
                var dataArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps/${voucherDetil.arapId}`, 'GET')
                await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps`, 'PUT', {
                    values: {
                        outstandingAmount: voucherDetil.outstandingAmount + voucherDetil.amountPaid
                    }
                }, {
                    amount: voucherDetil.amount,
                    currencyId: voucherDetil.currencyId,
                    defaultBank: voucherDetil.defaultBank,
                    dueDate: voucherDetil.dueDate,
                    entitiId: voucherDetil.entitiId,
                    id: voucherDetil.arapId,
                    outstandingAmount: voucherDetil,
                    reffNo: voucherDetil.reffNo,
                    reffType: voucherDetil.reffType,
                    description: dataArap.description,
                    tradeDate: voucherDetil.tradeDate
                })
            }

            const responseInsertVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'cash-voucers', 'PUT', {
                values: {
                    status: "F",
                    notes: data.rejectNotes,
                    rejectedBy: this.username,
                }
            }, dataVoucher)

            var loadAPITask = `tasks?referenceId.equals=${data.cashVoucherId}&taskStatusId.equals=1&taskTypeId.equals=60`;
            var getAPITask = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPITask, 'GET');

            var valueTask = getAPITask[0];
            valueTask.taskStatusId = 2

            await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'tasks', 'PUT', {
                key: valueTask.id,
                values: valueTask
            }, valueTask);

            this.props.store.dispatch(showLoading(false))
            this.forceRefresh()
            notify({ message: 'Sukses Melakukan Penolakan Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        } catch (e) {
            this.props.store.dispatch(showLoading(false))
            notify({ message: 'Gagal Melakukan Penolakan Voucher', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            console.log(e)
        }
    }

    onSelectionChanged = (e) => {
        console.log(e)
        this.selectedRowDatas = e.selectedRowsData
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Verifikasi Cash Settlement Voucher</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='cash-voucers'
                    insertAPI='cash-voucers'
                    updateAPI='cash-voucers'
                    deleteAPI='cash-voucers'

                    keyField="cashVoucherId"

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    ArraySourceData={this.loadData}
                    useArraySource={true}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'amount'
                        ]
                    }

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Cash Settlement Voucher"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Cash Settlement Voucher'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 250px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalCashSettlementVoucher ref={this.modalCashSettlementVoucherRef} store={this.props.store} forceRefresh={this.forceRefresh} cancel={this.cancel} posting={this.posting} type={"verif"} />
            </div>
        )
    }
}

export default CashSettlementVerified