import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import Box from 'devextreme-react/box';
import { FileUploader } from 'devextreme-react';
import { Item }  from "devextreme-react/html-editor";
import { formatUploadFileData } from 'plugin/helper';
import { ScrollView } from 'devextreme-react';

class ActionLampiranPembayaranLainnya extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            dataMaster: {},
            fileTextSuratRs: '',
            fileTextStruk: '',
            fileTextLainnya: '',
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.formRef = React.createRef()
        
        this.dataGridLampiran = []
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'kwitansiNo',
                        label: {
                            alignment: 'left',
                            text: 'No Kwitansi'
                        },
                    },
                    {
                        dataField: 'kwitansiPenerbit',
                        label: {
                            alignment: 'left',
                            text: 'Penerbit Kwitansi'
                        },
                    },
                    {
                        dataField: 'kwitansiPenerbit',
                        label: {
                            alignment: 'left',
                            text: 'Penerbit Kwitansi'
                        },
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Keterangan'
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {
                            height: 100
                        }
                    },
                    {
                        dataField: 'kwitansiTanggal',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Kwitansi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'namaDokter',
                        label: {
                            alignment: 'left',
                            text: 'Nama Dokter'
                        },
                    },
                    {
                        dataField: 'penyakit',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Penyakit'
                        },
                    },
                    {
                        dataField: 'kwitansiNominal',
                        name: 'kwitansiNominal',
                        label: {
                            alignment: 'left',
                            text: 'Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format : '#,##0.00',
                            step: 0
                        }
                    },                    
                ]
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    loadDataGrid = () => {
        return this.dataGridLampiran
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0          
        });
    }
    
    retrieveData = (data) => {
        this.setState({
            dataMaster: data
        })
    }
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        await this.props.editDataGrid(dataSubmit)
        this.hide()
    }

    retrieveData = async(data) => {    
        this.setState({
            dataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`file${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    },
                    [`fileText${fieldName}`]: files.name,
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }
    
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pembayaran Manfaat Pensiun Lain Per Pensiunan'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form 
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formData'}
                                    formData={this.state.dataMaster}
                                    items={this.formItem}
                                    labelLocation = "left"
                                />
                            </div>
                            <div className="mt-5 col-md-12">
                                <h5 className="text-center">Dokumen Pendukung</h5>
                                <Box 
                                    direction={'row'}
                                    width={'50%'}
                                >
                                    <Item ratio={1}>
                                        <FileUploader 
                                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={this.onUploadStarted.bind(this)} 
                                            labelText={this.state.fileTextSuratRs || 'Surat Rumah Sakit/Klinik'}
                                            showFileList={false}
                                            name={'SuratRs'}
                                        />
                                    </Item>
                                </Box>
                                <Box 
                                    direction={'row'}
                                    width={'50%'}
                                >
                                    <Item ratio={1}>
                                        <FileUploader 
                                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={this.onUploadStarted.bind(this)} 
                                            labelText={this.state.fileTextStruk || 'Kwitansi/Struk Pembayaran'}
                                            showFileList={false}
                                            name={'Struk'}
                                        />
                                    </Item>
                                    
                                </Box>
                                <Box 
                                    direction={'row'}
                                    width={'50%'}
                                >
                                    <Item ratio={1}>
                                        <FileUploader 
                                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={this.onUploadStarted.bind(this)} 
                                            labelText={this.state.fileTextLainnya ||'Dokumen Lainnya'}
                                            showFileList={false}
                                            name={'Lainnya'}
                                        />
                                    </Item>
                                </Box>
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ActionLampiranPembayaranLainnya
