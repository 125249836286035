import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import uuidv4  from 'uuid/v4'
import { showLoading } from 'redux/actions/loading';

class BankReconcile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {}
    }

    this.dataGridRef = React.createRef()
    this.uploadFileRef = React.createRef()

    this.filterItem = [
      {
        dataField: "companyBank",
        label: {
          text: "Bank Perusahaan",
          alignment: "left",
          location: "left"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: [
            {
                id: 0,
                value: 'Mandiri - 12345671231231'
            },
            {
                id: 1,
                value: 'BCA - 0123456712'
            },
          ],
          valueExpr: 'id',
          displayExpr: 'value',
        }
      },
      {
        dataField: "tanggal1",
        label: {
          text: "Dari",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy',
          openOnFieldClick: true
        }
      },
      {
        dataField: "tanggal2",
        label: {
          text: "Sampai",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy',
          openOnFieldClick: true
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            this.filterSubmit()
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Detail',
            hint: 'Detail',
            cssClass: 'text-success',
            onClick: (e) => {
              // this.showModalDetail(e.row.data)
            }
          },
        ],
      },
      {
        caption: 'Data Sistem',
        alignment: 'center',
        columns: [
          {
            dataField: 'instructionNo',
            caption: 'No Instruksi',
          },
          {
            dataField: 'entryDate',
            caption: 'Tanggal Entri',
            type : 'date',
            format: 'dd MMM yyyy'
          },
          {
            dataField: 'description',
            caption: 'Deskripsi',
          },
          {
            dataField: 'currencyId',
            caption: 'Mata Uang',
          },
          {
            dataField: 'netAmount',
            caption: 'Nominal Bersih',
            format: '#,##0.00'
          },
        ]
      },
      {
        caption: 'Data Bank',
        alignment: 'center',
        columns: [
          {
            dataField: 'bankTrxNo',
            caption: 'Reff',
          },
          {
            dataField: 'bankStatementNo',
            caption: 'Nomor Statement',
          },
          {
            dataField: 'bankTrxDate',
            caption: 'Tanggal Transaksi',
            type : 'date',
            format: 'dd MMM yyyy'
          },
          {
            dataField: 'bankEffectiveDate',
            caption: 'Tanggal Efektif',
            type : 'date',
            format: 'dd MMM yyyy'
          },
          {
            dataField: 'bankTrxText',
            caption: 'Deskripsi',
          },
          {
            dataField: 'BankCurrencyCode',
            caption: 'Mata Uang',
          },
          {
            dataField: 'bankAmount',
            caption: 'Nominal Bersih',
            format: '#,##0.00'
          },
        ]
      }
    ]

    this.dataGridData = []
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  loadDataGridData = () => {
    return this.dataGridData
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
          location: 'after',
          widget: 'dxButton',
          options: {
              icon: 'upload',
              text: 'Upload Bank Statement',
              onClick: (e) => {
                if(this.dataGridData.length === 0){
                  let type = 'error'
                  let text = 'Harap muat data bank terlebih dahulu!'
            
                  notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
            
                  return null
                }
                this.uploadFileRef.current.click()
              },
          }
      }
    )
  }

  filterSubmit = () => {
    this.dataGridData = [
      {
        instructionNo : 'BI-202009-000001',
        entryDate : new Date('2020/09/21'),
        description : 'Order transaksi investasi Saham',
        currencyId : 1101,
        netAmount : -500000000,
      },
    ]

    this.dataGridData = this.dataGridData.map(value => {
      value.id = uuidv4()

      return value
    })

    this.dataGridRef.current.forceRefresh(true)
  }

  uploadBankStatementProcess = (e) => {
    this.props.store.dispatch(showLoading(true))
    try{

      console.log(e.target.files)
  
      var dataBank = [
        {
          bankStatementNo : '20200921001',
          bankTrxDate : '2020/09/21',
          bankEffectiveDate : '2020/09/21',
          bankTrxText : 'Order transaksi investasi Saham',
          BankCurrencyCode : 'IDR',
          bankAmount : -500000000,
          bankTrxNo : 'BI-202009-000001',
        },
        {
          bankStatementNo : '20200921002',
          bankTrxDate : '2020/09/21',
          bankEffectiveDate : '2020/09/21',
          bankTrxText : 'Bunga',
          BankCurrencyCode : 'IDR',
          bankAmount : 5000,
          bankTrxNo : null,
        },
      ]
      var pushRecord = []
      for(var data of dataBank){
        var instructionData = this.dataGridData.find(value => value.instructionNo === data.bankTrxNo)
        
        if(instructionData){
          pushRecord.push({
            type: "update", 
            data: data,
            key: instructionData.id
          })
        }else{
          pushRecord.push({
            type: "insert", 
            data: {
                id: uuidv4(),
                bankStatementNo : data.bankStatementNo,
                bankTrxDate : data.bankTrxDate,
                bankEffectiveDate : data.bankEffectiveDate,
                bankTrxText : data.bankTrxText,
                BankCurrencyCode : data.BankCurrencyCode,
                bankAmount : data.bankAmount,
                bankTrxNo : data.bankTrxNo,
            }
          })
        }
      }
  
      this.dataGridRef.current.push(pushRecord)

      this.props.store.dispatch(showLoading(false))
    }catch(e){
      console.log(e)
      this.props.store.dispatch(showLoading(false))
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Bank Reconcile</h2>
        <Form
          colCount={4}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />
        <input type="file" id="file-upload" className='d-none' ref={this.uploadFileRef} onChange={this.uploadBankStatementProcess.bind(this)}/>
        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='trial-balances'
          insertAPI='trial-balances'
          updateAPI='trial-balances'
          deleteAPI='trial-balances'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          useArraySource={true}
          ArraySourceData={this.loadDataGridData}

          allowAdding={false}
          allowDeleting={true}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Trial Balance"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={false}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Trial Balance'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          summaryTotalItem={this.sumDebit}
          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />

      </div>
    )
  }
}

export default BankReconcile