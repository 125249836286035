import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { summaryDate, formatDefaultDate } from 'plugin/helper';

class DetailOrderEtf extends Component {
    constructor(props) {
        super(props)
        this.dataSource = new CustomStore({
            load: async (loadOptions) => {
                var orderId = this.props.detailData
                var loadAPI = `order-etf-dtls/${orderId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                return response
            },
            key: 'orderEtfId',
            insert: (values) => {
            },
            update: (key, values) => {

            },
            remove: (key) => {

            }
        })

        this.dataGridDetalRef = React.createRef()
        this.columns = [
            {
                dataField: 'productCode',
                caption: 'Kode Produk'
            },
            {
                dataField: 'broker',
                caption: 'Broker',
                calculateCellValue: (e) => {
                    return e.brokerCode + ' - ' + e.brokerName
                }
            },
            {
                dataField: 'orderDate',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'amountOrder',
                caption: 'Nilai Order',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'unitOrder',
                caption: 'Nilai Unit',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'priceType',
                caption: 'Harga'
            },
            {
                dataField: 'priceMin',
                caption: 'Harga Min',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'priceMax',
                caption: 'Harga Max',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
    }

    getDataSource = () => {
        this.dataGridDetalRef.current.getDataSource()
    }
    render() {
        return (
            <DataGrid
                id={"successDataGrid"}
                ref={this.dataGridDetalRef}
                showBorders={false}
                dataSource={this.dataSource}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                // height={100}
                showScrollbar={"always"}
            // columns={this.columns}
            >
                {
                    this.columns.map((column) => {
                        return <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            lookup={column.lookup}
                            cellRender={column.cellRender}
                            alignment={column.alignment || 'left'}
                            cssClass={column.cssClass}
                            format={column.format}
                            dataType={column.dataType}
                            width={column.width}
                            type={column.type}
                            buttons={column.buttons}
                            editorType={column.editorType}
                            editorOptions={column.editorOptions}
                            visible={column.visible}
                            sortOrder={column.sortOrder}
                            allowEditing={column.allowEditing}
                            calculateCellValue={column.calculateCellValue}
                        />
                    })
                }
                {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailOrderEtf