import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { banks, programs, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum,rekBankHrd } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog';

class PPUPengajuanPembayaranActionDetailModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {
                metodePembayaran:1,
                pengajuanNominal:0,
                ppn:0,
                pph:0,
                biayaLain:0,
                potongan:0
            },
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.entitiId = 0
        this.metodePembayaran = 0

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: async() => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }else{
                            await alert('Terdapat kesalahan data', 'Informasi')
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        
        this.dataMaster = [
            {
                itemType: 'group',
                caption : 'Transaksi Biaya',
                items: [
                    {
                        dataField:'transactionTypeId',
                        label:{
                            alignment:'left',
                            text:'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'transactionTypeName', // provides display values
                            searchEnabled:true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'transaction-type-subs?size=9999', 'GET')
                                    data = data.filter(value => value.transactionTypeId === e.value)

                                    this.formDetailRef.current.instance.getEditor('transactionTypeSubId').option('dataSource',data)
                                }
                            }
                        },
                    },
                    {
                        dataField:'transactionTypeSubId',
                        label:{
                            alignment:'left',
                            text:'Sub Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'tipeSubName', // provides display values
                            searchEnabled:true,
                        },
                    },
                    {
                        dataField: 'pengajuanNominal',
                        label : {
                            alignment: 'left',
                            text: 'Nominal Pengajuan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.pengajuanNominalNett = e.value + dataMaster.ppn - dataMaster.pph + dataMaster.biayaLain - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                            },
                        },
                        validationRules: [
                            {
                                type: "range",
                                message: "Nominal tidak boleh minus (-)",
                                min: 0
                            },
                        ]
                    },
                    {
                        dataField: 'ppn',
                        label : {
                            alignment: 'left',
                            text: 'PPN'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + e.value - dataMaster.pph + dataMaster.biayaLain - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                            }
                        },
                        validationRules: [
                            {
                                type: "range",
                                message: "PPN tidak boleh minus (-)",
                                min: 0
                            },
                        ]
                    },
                    {
                        dataField: 'pph',
                        label : {
                            alignment: 'left',
                            text: 'PPH'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + dataMaster.ppn - e.value  + dataMaster.biayaLain - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                            }
                        },
                        validationRules: [
                            {
                                type: "range",
                                message: "PPH tidak boleh minus (-)",
                                min: 0
                            },
                        ]
                    },
                    {
                        dataField: 'biayaLain',
                        label : {
                            alignment: 'left',
                            text: 'Biaya Lain'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + dataMaster.ppn - dataMaster.pph + e.value - dataMaster.potongan
                                this.formDetailRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                            }
                        },
                        validationRules: [
                            {
                                type: "range",
                                message: "Biaya lain tidak boleh minus (-)",
                                min: 0
                            },
                        ]
                    },
                    {
                        dataField: 'potongan',
                        label : {
                            alignment: 'left',
                            text: 'Potongan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged:(e) => {
                                var dataMaster = {...this.state.dataMaster}
                                dataMaster.pengajuanNominalNett = dataMaster.pengajuanNominal + dataMaster.ppn - dataMaster.pph + dataMaster.biayaLain - e.value
                                this.formDetailRef.current.instance.updateData({pengajuanNominalNett:dataMaster.pengajuanNominalNett})
                            }
                        },
                        validationRules: [
                            {
                                type: "range",
                                message: "Potongan tidak boleh minus (-)",
                                min: 0
                            },
                        ]
                    },
                    {
                        dataField: 'pengajuanNominalNett',
                        label : {
                            alignment: 'left',
                            text: 'Nominal Transfer'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly:true,
                        },
                        validationRules: [
                            {
                                type: "range",
                                message: "Nominal tidak boleh minus (-)",
                                min: 0
                            },
                        ]
                    },
                    {
                        dataField: 'dpAmount',
                        label : {
                            alignment: 'left',
                            text: 'Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                        validationRules: [
                            {
                                type: "range",
                                message: "Uang muka tidak boleh minus (-)",
                                min: 0
                            },
                        ]
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Catatan Pengajuan'
                        },
                        editorType: 'dxTextArea',
                        editorOptions:{
                            width: '100%'
                        },
                        validationRules: [
                            {
                                type: 'stringLength',
                                max: 255,
                                message: 'Tidak Bisa Lebih Dari 255 Karakter'
                            },
                            {
                                type: 'required',
                                message: 'Catatan pengajuan tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items : [
                    {
                        itemType : 'group',
                        caption : 'Metode Pembayaran',
                        items: [
                            {
                                dataField:'metodePembayaran',
                                label:{
                                    text:'Metode Pembayaran'
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: [
                                        {
                                            id:1,
                                            value:'Transfer'
                                        },
                                        {
                                            id:2,
                                            value:'Cash'
                                        },
                                        {
                                            id:3,
                                            value:'Cash Out'
                                        },
                                    ],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                    onValueChanged:(e) => {
                                        if (e.value == 1) {
                                            this.formDetailRef.current.instance.updateData({transfer:true,cash:false,cashOut:0})
                                            this.formDetailRef.current.instance.getEditor('cashOut').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekNo').option('readOnly',false)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekName').option('readOnly',false)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekBankId').option('readOnly',false)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',false)
                                            this.getEntityId(this.entitiId)
                                        }else if(e.value == 2){
                                            this.formDetailRef.current.instance.updateData({
                                                transfer:false,
                                                cash:true,
                                                cashOut:0,
                                                tujuanRekNo:null,
                                                tujuanRekName:null,
                                                tujuanRekBankId:null,
                                                tujuanRekCabang:null
                                            })
                                            this.formDetailRef.current.instance.getEditor('cashOut').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekNo').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekName').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekBankId').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',true)
                                        }else{
                                            this.formDetailRef.current.instance.updateData({
                                                transfer:false,
                                                cash:false,
                                                tujuanRekNo:null,
                                                tujuanRekName:null,
                                                tujuanRekBankId:null,
                                                tujuanRekCabang:null
                                            })
                                            this.formDetailRef.current.instance.getEditor('cashOut').option('readOnly',false)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekNo').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekName').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekBankId').option('readOnly',true)
                                            this.formDetailRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',true)
                                        }
                                    }
                                }
                            },
                            // {
                            //     dataField: 'transfer',
                            //     label: {
                            //         alignment: 'left',
                            //         text: 'Transfer'
                            //     },
                            //     editorType: 'dxCheckBox',
                            //     editorOptions:{
                            //         onValueChanged:(e) => {
                            //             if (e.value) {
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekNo').option('readOnly',false)
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekName').option('readOnly',false)
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekBankId').option('readOnly',false)
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',false)
                            //             }else{
                            //                 var dataMaster = {}
                            //                 dataMaster.tujuanRekBankId = null
                            //                 dataMaster.tujuanRekNo = null
                            //                 dataMaster.tujuanRekName = null
                            //                 dataMaster.tujuanRekCabang = null
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekNo').option('readOnly',true)
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekName').option('readOnly',true)
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekBankId').option({readOnly:true})
                            //                 this.formDetailRef.current.instance.getEditor('tujuanRekCabang').option('readOnly',true)
                            //                 this.formDetailRef.current.instance.updateData(dataMaster)
                            //             }
                            //         }
                            //     }
                            // },
                            // {
                            //     dataField: 'cash',
                            //     label: {
                            //         alignment: 'left',
                            //         text: 'Cash'
                            //     },
                            //     editorType: 'dxCheckBox',
                            // },
                            {
                                dataField: 'cashOut',
                                label: {
                                    alignment: 'left',
                                    text: 'Cash Out'
                                },
                                editorType: "dxNumberBox",
                                editorOptions: {
                                    format: "#,##0.00",
                                    readOnly:true
                                },
                            },
                        ]
                    },
                    {
                        itemType : 'group',
                        caption : 'Rekening Tujuan',
                        items: [
                            {
                                dataField: 'tujuanRekNo',
                                label: {
                                    alignment: 'left',
                                    text: 'Nomor Rekening'
                                },
                                editorOptions:{
                                    readOnly:true
                                }
                            },
                            {
                                dataField: 'tujuanRekName',
                                label: {
                                    alignment: 'left',
                                    text: 'Nama Rekening',
                                },
                                editorOptions:{
                                    readOnly:true
                                }
                            },
                            {
                                dataField:'tujuanRekBankId',
                                label:{
                                    alignment:'left',
                                    text:'Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions:{
                                    searchEnabled: true,
                                    deferRendering: false,
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'bankName', // provides display values
                                    readOnly:true
                                },
                            },
                            {
                                dataField: 'tujuanRekCabang',
                                label: {
                                    alignment: 'left',
                                    text: 'Cabang',
                                },
                                editorOptions:{
                                    readOnly:true
                                }
                            },
                        ]
                    }
                ]
            }
        ]
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = (data) => {
        this.metodePembayaran = data.metodePembayaran
        if (data.cashOut) {
            data.metodePembayaran = 3
        }else if(data.cash){
            data.metodePembayaran = 2
        }else{
            data.metodePembayaran = 1
        }
        this.formDetailRef.current.instance.updateData(data)
    }

    validate = () => {
        var formValidation = this.formDetailRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async () => {
        var data = {...this.state.dataMaster}
        data.currencyId = 1
        data.currencyRate = 1
        await this.props.editDataGrid('detail', data)
        this.hide() 
    }

    
    show(data) {
        this.entitiId = data.entitiId
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.entitiId = 0
        this.setState({
            popupVisible: false,
            dataMaster: {
                metodePembayaran:1,
                pengajuanNominal:0,
                ppn:0,
                pph:0,
                biayaLain:0,
                potongan:0
            },
        })
    }

    getEntityId = async (dataEntiti) => {
        var loadAPI = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/${dataEntiti.entitiId}`, 'GET')
        var data, tujuanRekNo, tujuanRekName, tujuanRekCabang, tujuanRekBankId
        
        switch (loadAPI.type) {
            case 'SP':
                data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `suppliers/${loadAPI.reff}`, 'GET')
                tujuanRekNo = data.rekNo
                tujuanRekName = data.rekName
                tujuanRekCabang = data.rekCabang
                tujuanRekBankId = data.rekBankId
                break;
            case 'KR':
                data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${loadAPI.reff}`, 'GET')
                tujuanRekNo = data.gajiRekNo
                tujuanRekName = data.gajiRekName
                tujuanRekCabang = data.gajiRekBranch
                tujuanRekBankId = data.gajiRekBankId

                break;
            default:
                break;
        }
        
        this.formDetailRef.current.instance.updateData({
            tujuanRekNo:tujuanRekNo,
            tujuanRekName:tujuanRekName,
            tujuanRekBankId:tujuanRekBankId,
            tujuanRekCabang:tujuanRekCabang
        })
        
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Pembayaran'}
                width={950}
                height={600}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formDetailRef}
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation = 'left'
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default PPUPengajuanPembayaranActionDetailModal