import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { ScrollView } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';

class PengajuanPembayaranUmumCatatan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            action: ""
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.formItem = [
            {
                dataField: 'catatan',
                label: {
                    alignment: 'left',
                    text: 'Catatan'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    height: 200
                },
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: async () => {
                        var result = await confirm('Apakah Anda Yakin Ingin Proses Pembayaran Ini?', 'Konfirmasi')
                        if (result) {
                            await this.submitData()
                        }
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: async () => {
                        // this.hide(); 
                        const result = await confirm('Apakah anda ingin keluar dari form catatan pembayaran ini?', 'Konfirmasi');

                        if (result) {            
                            this.hide();
                        }
                    }
                    // onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    // getData = (data, action) => {
    //     this.setState({
    //         popupVisible: true,
    //         selectedTabIndex: 0,
    //         dataMaster: {},
    //         action: action
    //     })
    // }

    show(data, action) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: data,
            action: action
        });
    }

    hide() {
        this.setState({
            dataMaster: {},
            popupVisible: false,
            // action: ''
        });
    }

    submitData = async () => {
        var data = this.state.dataMaster;
        var catatan = data.catatan;
        var action = this.state.action;
        await this.props.kirim(data, catatan, action)
        this.hide()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Catatan'}
                width={500}
                height={400}
                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={'formData'}
                                    formData={this.state.dataMaster}
                                    items={this.formItem}
                                    labelLocation="top"
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default PengajuanPembayaranUmumCatatan