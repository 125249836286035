import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react';
import { karyawan, sdmUnit, programs, tipeTransaksiSdmUmum, banks, rekBank } from 'dataSource/lookup';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { FileUploader, ScrollView } from 'devextreme-react';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';

class ModalJurnalUmumPembayaranRiwayat extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            popupVisible: false,
        }

        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.form = [
            {
                itemType: 'group',
                items: [

                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program'
                        }
                    },
                    {
                        dataField: 'transactionNo',
                        label: {
                            text: 'No Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'requestDate',
                        label: {
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'transactionType',
                        label: {
                            text: 'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'transactionTypeName'
                        }
                    },
                    {
                        dataField: 'transactionSubTypeId',
                        label: {
                            text: 'Sub Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'tipeSubName'
                        }
                    },
                    {
                        dataField: 'description',
                        label: {
                            text: 'Keterangan Pembayaran'
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {
                            height: 100
                        },
                    },
                ]
            },
            {
                itemType: 'empty'
            },
            {
                itemType: 'group',
                colCount : 2,
                items : [
                    {
                        itemType: 'group',
                        name: 'pengajuan',
                        caption: 'PENGAJUAN',
                        items: [
                            {
                                dataField: 'paymentDate',
                                label: {
                                    text: 'Tanggal Pembayaran'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'paymentAmount',
                                label: {
                                    text: 'Nominal Pembayaran'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'ppn',
                                label: {
                                    text: 'PPN'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'pph',
                                label: {
                                    text: 'PPH'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'biayaLain',
                                label: {
                                    text: 'Biaya Lain'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'potonganPersen',
                                label: {
                                    text: 'Potongan Persen'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0'
                                }
                            },
                            {
                                dataField: 'potonganAmount',
                                label: {
                                    text: 'Potongan Nominal'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    text: 'Biaya Bank'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'transactionNetAmount',
                                label: {
                                    text: 'Net Pembayaran'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'unitDanRekeningTujuan',
                        items: [
                            {
                                itemType: 'group',
                                name: 'unitKerja',
                                caption: 'UNIT KERJA',
                                items: [
                                    {
                                        dataField: 'unitId',
                                        label: {
                                            text: 'Unit'
                                        },
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: sdmUnit(this.props.store),
                                            valueExpr: 'id',
                                            displayExpr: 'unitName'
                                        }
                                    },
                                    {
                                        dataField: 'requestKaryawanId',
                                        label: {
                                            text: 'Diajukan oleh'
                                        },
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: karyawan(this.props.store),
                                            valueExpr: 'id',
                                            displayExpr: 'fullName'
                                        }
                                    },
                                    {
                                        dataField: 'approvedKaryawanId',
                                        label: {
                                            text: 'Disetujui oleh'
                                        },
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: karyawan(this.props.store),
                                            valueExpr: 'id',
                                            displayExpr: 'fullName'
                                        }
                                    }
                                ]
                            },
                            {
                                itemType: 'group',
                                name: 'rekeningTujuan',
                                caption: 'Rekening Tujuan',
                                items: [
                                    {
                                        dataField: 'rekNo',
                                        label: {
                                            text: 'Rekening Nomor'
                                        },
                                        editorType: 'dxNumberBox'
                                    },
                                    {
                                        dataField: 'rekNama',
                                        label: {
                                            text: 'Rekening Atas Nama'
                                        }
                                    },
                                    {
                                        dataField: 'rekCabang',
                                        label: {
                                            text: 'Cabang'
                                        }
                                    },
                                    {
                                        dataField: 'rekBankId',
                                        label: {
                                            text: 'Bank'
                                        },
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: banks(this.props.store),
                                            valueExpr: 'id',
                                            displayExpr: 'bankName'
                                        }
                                    },
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                colCount : 2,
                name: 'uangMukaDanSisaPembayaran',
                items : [
                    {
                        itemType: 'group',
                        name: 'uangMuka',
                        caption: 'UANG MUKA',
                        items: [
                            {
                                dataField: 'useDp',
                                label: {
                                    text: 'Dengan Uang Muka'
                                },
                                editorType: 'dxCheckBox'
                            },
                            {
                                dataField: 'dpPersen',
                                label: {
                                    text: 'Persen Uang Muka'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0'
                                }
                            },
                            {
                                dataField: 'dpAmount',
                                label: {
                                    text: 'Nominal Uang Muka'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'dpRekbankId',
                                label: {
                                    text: 'Dari Rekening Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'rekName'
                                }
                            },
                            {
                                dataField: 'dpRekbankAmount',
                                label: {
                                    text: 'Nominal Rekening Bank'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'dpKasumId',
                                label: {
                                    text: 'Dari Kas Umum'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'namaKas'
                                }
                            },
                            {
                                dataField: 'dpKasumAmount',
                                label: {
                                    text: 'Nominal Kas Umum'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'sisaPembayaran',
                        caption: 'SISA PEMBAYARAN',
                        items: [
                            {
                                dataField: 'spAmount',
                                label: {
                                    text: 'Sisa Pembayaran'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'spRekbankId',
                                label: {
                                    text: 'Dari Rekening Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'rekName'
                                }
                            },
                            {
                                dataField: 'spRekbankAmount',
                                label: {
                                    text: 'Nominal Rekening Bank'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'spKasumId',
                                label: {
                                    text: 'Dari Kas Umum'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'namaKas'
                                }
                            },
                            {
                                dataField: 'spKasumAmount',
                                label: {
                                    text: 'Nominal Kas Umum'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                        ]
                    }
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ]
    }
    show = (data = {}) => {
        if (data) {
            this.setState({
                popupVisible: true,
                formData: data
            })
        } else {
            this.setState({
                popupVisible: true,
                formData: {}
            })
        }
    }

    hide = () => {
        this.setState({
            formData: {},
            popupVisible: false
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
                
                this.setState(prevState =>({
                    formData: {
                        ...prevState.formData,
                        [`${fieldName}Name`]: files.name,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))

            }
    
            fr.readAsDataURL(files)
        }
    }
    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            var src = imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`]),this.state.formData[`${fileName}Name`])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Riwayat Pembayaran'}
                minWidth={700}
                minHeight={760}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formPotongan'}
                                formData={this.state.formData}
                                items={this.form}
                                labelLocation='left'
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-12">
                            <h5>Dokumen</h5>
                        </div>
                        {/* File Kwitansi */}
                        <div className="col-md-6">
                                <FileUploader 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText={this.state.formData.fileKwitansiName || 'File Kwitansi'}
                                    showFileList={false}
                                    name={'fileKwitansi'}
                                    disabled = {true}
                                />
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile('fileKwitansi')} className={`btn btn-light btn-sm border rounded ${this.state.formData.fileKwitansi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                            <button onClick={() => this.downloadFile('fileKwitansi')} className={`btn btn-light btn-sm border rounded ${this.state.formData.fileKwitansi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Download
                            </button>
                        </div>

                        {/* File Tagihan */}
                        <div className="col-md-6">
                                <FileUploader 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText={this.state.formData.fileSkPhkName || 'File Tagihan / Invoice'}
                                    showFileList={false}
                                    name={'fileTagihan'}
                                    disabled = {true}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileTagihan')} className={`btn btn-light btn-sm border rounded ${this.state.formData.fileTagihan ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileTagihan')} className={`btn btn-light btn-sm border rounded ${this.state.formData.fileTagihan ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                    </div>
                </div>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalJurnalUmumPembayaranRiwayat