import React, { Component } from 'react'
import { akunHR, akunHRbyCategori, coa, coaGroup, users } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { alert,confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import EditKasUmum from 'components/pages/modal/sdm-umum/umum/kas-umum/edit-kas-umum/editKasUmum';
import AddKasUmum from 'components/pages/modal/sdm-umum/umum/kas-umum/add-kas-umum/addKasUmum';

class PengaturanKasUmum extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            if (e.row){
                                this.showModalEdit(e.row.data, 'edit');
                            }
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-primary',
                        onClick: async (e) => {
                            const result = await confirm("Apakah anda yakin menghapus data ini?", "Konfirmasi");
                            if (result){
                                this.deleteAPI(e.row.data);
                            }
                        }
                    },
                ]
            },
            {
                dataField: "kodeKas",
                caption: "Kode Kas Umum"
            },
            {
                dataField: "namaKas",
                caption: "Nama Kas Umum"
            },
            {
                dataField: "akunId",
                caption: "Akun",
                lookup: {
                    dataSource: {
                        store: akunHRbyCategori(this.props.store),
                        paginate: true,
                        pageSize: 10
                    },
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'maksimalPembayaran',
                caption: 'Maksimal Pembayaran',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    showClearButton: true

                }
            },
            {
                dataField: 'minimalSaldo',
                caption: 'Minimal Saldo',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    showClearButton: true

                }
            },
            {
                dataField: 'maksimalSaldo',
                caption: 'Maksimal Saldo',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    showClearButton: true

                }
            },
            {
                dataField: 'saldo',
                caption: 'Saldo',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    showClearButton: true

                }
            },
            {
                dataField: 'totalPenerimaan',
                caption: 'Total Penerimaan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    showClearButton: true

                }
            },
            {
                dataField: 'totalPengeluaran',
                caption: 'Total Pengeluaran',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00",
                    showClearButton: true

                }
            },
            {
                dataField: "otoritasUser",
                caption: "Otoritas User",
                lookup: {
                    dataSource: users(this.props.store),
                    valueExpr: 'login', // contains the same values as the "statusId" field provides
                    displayExpr: 'login',
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
        ]

        this.columnDefaultValue = [
            {
                dataField: 'maksimalPembayaran',
                value: 0
            },
            {
                dataField: 'minimalSaldo',
                value: 0
            },
            {
                dataField: 'maksimalSaldo',
                value: 0
            },
            {
                dataField: 'saldo',
                value: 0
            },
            {
                dataField: 'totalPenerimaan',
                value: 0
            },
            {
                dataField: 'totalPengeluaran',
                value: 0
            },
        ]

        this.modalEditRef = React.createRef();
        this.modalAddRef = React.createRef();
        this.dataGridRef = React.createRef();
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalEdit = (data) => {
        this.modalEditRef.current.show();
        this.modalEditRef.current.retrieveData(data);
    }

    onRowInserting = (e) => {
        var data = e.data
        data.maksimalPembayaran = data.maksimalPembayaran
        data.minimalSaldo = data.minimalSaldo
        data.maksimalSaldo = data.maksimalSaldo
        data.saldo = 0
        data.totalPenerimaan = 0
        data.totalPengeluaran = 0

        e.cancel = false
    }

    onRowUpdating = (e) => {
        var oldData = e.oldData
        var newData = e.newData
        oldData.maksimalPembayaran = newData.maksimalPembayaran ? newData.maksimalPembayaran : oldData.maksimalPembayaran
        oldData.minimalSaldo = newData.minimalSaldo ? newData.minimalSaldo : oldData.minimalSaldo
        oldData.maksimalSaldo = newData.maksimalSaldo ? newData.maksimalSaldo : oldData.maksimalSaldo
        oldData.saldo = oldData.saldo
        oldData.totalPenerimaan = oldData.totalPenerimaan
        oldData.totalPengeluaran = oldData.totalPengeluaran

        e.cancel = false
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData =  async () => {
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-umums?size=999', 'GET');

        return response;
    }

    deleteAPI = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'kas-umums', 'DELETE', data.id);

            this.forceRefresh();
            await notify({ message: "Sukses Hapus Data", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
        } catch (error) {
            console.log(error);
            notify({message: error,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',400);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: () => {
                        this.showModalAdd({}, 'add');
                    }
                }
            }
        )
    }

    showModalAdd = () => {
        this.modalAddRef.current.show();
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Kas Umum</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='kas-umums?size=999'
                    insertAPI='kas-umums'
                    updateAPI='kas-umums'
                    deleteAPI='kas-umums'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource = {true}
                    ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Kas Umum"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Kas Umum Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    onToolbarPreparing = {this.onToolbarPreparing}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    columnDefaultValue={this.columnDefaultValue}
                    onRowInserting={this.onRowInserting}
                    onRowUpdating={this.onRowUpdating}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <EditKasUmum 
                    ref={this.modalEditRef}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
                <AddKasUmum 
                     ref={this.modalAddRef}
                     forceRefresh={this.forceRefresh}
                     store={this.props.store}
                />
            </div>
        )
    }
}

export default PengaturanKasUmum