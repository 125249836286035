import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager, currency, emitens, reksadana } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing }  from "devextreme-react/html-editor";
import ModalInstrumentPengalihanReksadana from './modalInstrumentReksadana';


class FormPengajuanPengalihanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            dataPenjualan: {},
            note: {}
        }

        this.prevTabIndex = 0
        this.dataPenjualan = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridDetailPengalihanRef = React.createRef()
        this.modalInstrumentReksadanaRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan Draft',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly : true
                }
            },
            {
                type: 'empty',
                colSpan: 2
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly : true
                }
            },
            {
                dataField: 'tanggalOrder',
                label: {
                    text: 'Tanggal Order'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                }
            },
            {
                type: 'empty',
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: "dxSelectBox",
                editorOptions:{
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false
                },
            },
            {
                dataField: 'instrument',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'jenisTransaksi',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly : true
                }
            },
        ]

        this.detailPengalihan = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            var data = e.row.data
                            data.noPengajuan = this.state.dataMaster.noPengajuan
                            this.modalInstrumentReksadanaRef.current.show(data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDetailPengalihanData(e.row.key)
                        }
                    },
                ]
            },
            {
                dataField: 'instrumentId',
                caption: 'Instrumen Code',
                width: '150px',
                lookup : {
                    dataSource: reksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode',
                }
            },
            {
                dataField: 'instrumentName',
                caption: 'Instrumen Name',
                width: '150px',
            },
            {
                dataField: 'emitenId',
                caption: 'Penerbit Reksadana',
                width: '150px',
                lookup : {
                    dataSource: emitens(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function(item){
                        return item && item.emitenCode + " - " + item.emitenName;
                    },
                }
            },
            {
                dataField: 'currencyId',
                caption:'Mata Uang',
                format: '#,##0',
                width: '150px',
                lookup : {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr : 'currencyCode',
                }
            },
            {
                dataField: 'kurs',
                caption:'Kurs',
                format: '#,##0',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0"
                },
            },
            {
                dataField: 'unit',
                caption:'Unit Portofolio',
                format: '#,##0.0000',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.0000"
                },
            },
            {
                dataField: 'nab',
                caption:'NAB Perolehan',
                format: '#,##0.0000',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.0000"
                },
            },
            {
                dataField: 'nilaiPerolehan',
                caption:'Nilai Perolehan',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },
            {
                dataField: 'nabTerakhir',
                caption:'NAB Terakhir',
                format: '#,##0.0000',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.0000"
                },
            },
            {
                dataField: 'portofolioPengalihan',
                caption:'Portofolio Pengalihan',
            },
            {
                dataField: 'prosentasePengalihan',
                caption:'Prosentase Pengalihan',
                format: '#,##0.00 %',
                width: '100px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00 %"
                },
            },
            {
                dataField: 'nominalPengalihan',
                caption:'Nominal Pengalihan (NAB Terakhir)',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },
            {
                dataField: 'feePercent',
                caption:'Fee (%)',
                format: '#,##0.00 %',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00 %"
                },
            },
            {
                dataField: 'fee',
                caption:'Fee Nominal',
                format: '#,##0.00',
                width: '150px',
            },
            {
                dataField: 'totalPengalihan',
                caption:'Total Pengalihan',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },
            {
                dataField: 'reksadanaTujuan',
                caption:'Reksadana Tujuan',
                format: '#,##0',
                width: '150px',
            },
        ]
        this.note = [
            {
                itemType: 'group',
                caption: 'Catatan',
                items: [
                    {
                        dataField: 'catatan',
                        label: {
                            alignment:'left',
                            text: 'Catatan'
                        },
                        editorType:'dxTextArea',
                        editorOptions:{
                            height: '190'
                        }
                    }
                ]
            }
        ]

        this.TabDataSource = [
            {
                'id': 0,
                text: 'Instrument Pengajuan'
            },
            {
                'id': 1,
                text: 'Kajian Pengajuan'
            },
        ]

        this.toolbarItem = [
            'background' , 'bold' , 'color' , 'italic' , 'link' , 'image' , 'strike' , 'subscript' , 'superscript' , 'underline' , 'blockquote' , 'header' 
            , 'increaseIndent' , 'decreaseIndent' , 'orderedList' , 'bulletList' , 'alignLeft' , 'alignCenter' , 'alignRight' , 'alignJustify' , 'codeBlock' , 'separator' , 'undo' , 'redo' , 'clear'
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show(dataMaster, dataPenjualan) {
        this.dataPenjualan = dataPenjualan || []
        
        var dataMaster = dataMaster || {
            noPengajuan : '<AUTO>',
            tanggalPengajuan: new Date(),
            instrument: 'Reksadana',
            jenisTransaksi: 'Pengalihan'
        }
        
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: dataMaster
        });
        this.dataGridDetailPengalihanRef.current.forceRefresh(true)

    }

    hide(){
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        console.log(this.state)
    }

    loadDetailPengalihan = () => {
        return this.dataPenjualan
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Instrument Pengalihan',
                    onClick: (e) => {
                        this.showModalInstrumentPengalihan()
                    },
                }
            }
        )
    }

    showModalInstrumentPengalihan = () => {
        this.modalInstrumentReksadanaRef.current.show({
            noPengajuan: this.state.dataMaster.noPengajuan
        })
    }

    submitDetailPengalihanData = (data) => {
        if(data.id){
            this.dataGridDetailPengalihanRef.current.push([{
                type: "update", 
                data: {
                    instrumentId : data.instrumentId,
                    instrumentName : data.instrumentName,
                    emitenId : data.emitenId,
                    currencyId : data.currencyId,
                    kurs : data.kurs,
                    nabTerakhir : data.nabTerakhir,
                    nilaiInvestasi : data.nilaiInvestasi,
                    feePercent : data.feePercent,
                    fee : data.fee,
                    totalPengalihan : data.totalPengalihan ,
                    reksadanaTujuan: data.reksadanaTujuan,
                },
                key: data.id
            }])
        }else{
            this.dataGridDetailPengalihanRef.current.push([{
                type: "insert", 
                data: {
                    instrumentId : data.instrumentId,
                    instrumentName : data.instrumentName,
                    emitenId : data.emitenId,
                    currencyId : data.currencyId,
                    kurs : data.kurs,
                    nabTerakhir : data.nabTerakhir,
                    nilaiInvestasi : data.nilaiInvestasi,
                    feePercent : data.feePercent,
                    fee : data.fee,
                    totalPengalihan : data.totalPengalihan ,
                    reksadanaTujuan: data.reksadanaTujuan,
                }
            }])
        }
    }

    deleteDetailPengalihanData = (key) => {
        this.dataGridDetailPengalihanRef.current.push([{
            type: "remove", 
            key: key
        }])
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Form Usulan Pengalihan Investasi Reksadana`}
                    minWidth={'90vw'}
                    height="90vh"

                    ref={this.PopupRef}

                    toolbarItems = {this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={3}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation={"left"}
                                />
                            </div>
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                            <div id="tabindex-0" className={`col-md-12` } style={{height:"calc(100vh - 380px)", overflowY:"auto"}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <DevExpressDataGrid
                                            ref = {this.dataGridDetailPengalihanRef}
                                            loadAPI='brokers'
                                            insertAPI='brokers'
                                            updateAPI='brokers'
                                            deleteAPI='brokers' 

                                            useArraySource = {true}
                                            ArraySourceData = {this.loadDetailPengalihan}

                                            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                            allowAdding={false}
                                            allowDeleting={false}
                                            allowUpdating={false}

                                            exportExcel={false}
                                            exportFileName={"Broker"}
                                            allowExportSelectedData={true}
                                            selection={"multiple"}
                                            
                                            showBorders={true}

                                            paging={true}
                                            defaultPageSize={10}

                                            //bagian konfigurasi popup saat insert dan edit record
                                            popupTitle={'Broker'}
                                            popupWidth={400} //masukan dalam ukuran pixel
                                            popupHeight={200} //masukkan dalam ukuran pixel

                                            popupFormLabelLocation='left' //accepted value = top, left, right
                                            popupFormMinColWidth={300} // minimum lebar kolom
                                            popupFormColCount={1} //jumlah kolom pada form

                                            //akhir bagian konfigurasi popup
                                            height={'calc(100vh - 380px)'}
                                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                            FilterRow={true} // set false untuk mematikan fitur filter

                                            ColumnConfiguration={this.detailPengalihan} // taruh konfigurasi kolom disini
                                            // SummaryConfiguration={this.summary}

                                            onToolbarPreparing = {this.onToolbarPreparing}

                                            //contoh konfigurasi kolom
                                            //this.columns = [{
                                            //    dataField: 'kolom1',
                                            //    caption: 'Ini Kolom 1'
                                            //}, {
                                            //    dataField: 'kolom2',
                                            //    caption: 'Ini Kolom 2'
                                            //}]
                                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                            store={this.props.store} // jangan di edit edit
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="tabindex-1" className={`col-md-12 d-none` } style={{height:"calc(100vh - 380px)", overflowY:"auto"}}>
                                <div className="col-md-12">
                                    <HtmlEditor 
                                        height="calc(100vh - 380px)"
                                        value={this.state.content}
                                        name={'catatan'}
                                        onValueChanged={this.onValueChanged}
                                    >
                                        <MediaResizing enabled={true} />
                                        <Toolbar>
                                            {    
                                                this.toolbarItem.map((toolbarItem) => {
                                                    return(
                                                        <Item
                                                            formatName = {toolbarItem}
                                                        />
                                                    )
                                                })
                                            }
                                        </Toolbar>
                                    </HtmlEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
                <ModalInstrumentPengalihanReksadana store={this.props.store} ref={this.modalInstrumentReksadanaRef} submitDetailPengalihanData={this.submitDetailPengalihanData} />
            </React.Fragment>
        )
    }
}

export default FormPengajuanPengalihanReksadana