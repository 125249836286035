import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Popup,Form } from 'devextreme-react'
import { banks, branch, jabatan, karyawan, programs, sdmUnit } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

export default class FormKaryawanPenerima extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster:{},
            DataPengajuan:{}
        }

        this.formMaster = [
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Karyawan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: async (e) => {
                        if (e.value) {
                            var DataMaster = this.state.DataMaster
                            var DataPengajuan = this.state.DataPengajuan
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${e.value}`, 'GET')
                            
                            DataMaster.unitId = response.unitId
                            DataMaster.jabatanId = response.jabatanId
                            DataMaster.gajiPokok = response.gajiPokok
                            DataMaster.gajiPokokTunjanganTetap = response.gajiPokokTunjanganTetap
                            DataMaster.gajiRekNo = response.gajiRekNo
                            DataMaster.gajiRekName = response.gajiRekName
                            DataMaster.gajiRekBankId = response.gajiRekBankId
                            DataMaster.gajiRekBranch = response.gajiRekBranch


                            if (DataPengajuan.dasarPerhitungan === 1) {
                                DataMaster.transactionNominal = response.gajiPokok * (DataPengajuan.nilaiPersenGapok/100)
                            }else if (DataPengajuan.dasarPerhitungan === 2) {
                                DataMaster.transactionNominal = response.gajiPokok * (DataPengajuan.nilaiPersenGapokTunjangan/100)
                            }else{
                                DataMaster.transactionNominal = DataPengajuan.nilaiNominal
                            }
                            
                            this.formRef.current.instance.updateData(DataMaster)
                        }
                    }
                }
            },
            {
                dataField: 'unitId',
                label: {
                    text: 'Unit',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                },
            },
            {
                dataField: 'jabatanId',
                label: {
                    text: 'Jabatan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                },
            },
            {
                dataField: 'gajiPokok',
                label: {
                    text: 'Gaji Pokok',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly:true
                },
            },
            {
                dataField: 'gajiPokokTunjanganTetap',
                label: {
                    text: 'Gaji Pokok + Tunjangan',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly:true
                },
            },
            {
                dataField: 'gajiRekNo',
                label: {
                    text: 'Rekening No',
                },
                // editorType: 'dxNumberBox',
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField: 'gajiRekName',
                label: {
                    text: 'Nama Rekening',
                },
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField: 'gajiRekBankId',
                label: {
                    text: 'Bank',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                },
            },
            {
                dataField: 'gajiRekBranch',
                label: {
                    text: 'Cabang',
                },
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField: 'transactionNominal',
                label: {
                    text: 'Nominal',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly:true
                },
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: () => {
                        this.props.getDataSource(this.state.DataMaster)
                        this.hide()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
    }

    getListKaryawan = async (programId) => {
        var karyawanList = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store,`karyawans?size=99999`, 'GET')
        karyawanList = karyawanList.filter(value => value.programId === programId)

        this.formRef.current.instance.getEditor('karyawanId').option('dataSource',karyawanList)
    }

    retrieveData = (data) => {
        this.setState({
            DataPengajuan:data
        })
    }

    submitData = () => {
        
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        
        this.setState({
            popupVisible: false,
            DataMaster:{}
        })
    }


    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled= {true}
                showTitle={true}
                title={"Daftar Karyawan Penerima"}
                width={"30vw"}
                height={"50vh"}
                toolbarItems={this.PopupToolbarItem}
            >
            <div className="container">
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formMaster'}
                    formData={this.state.DataMaster}
                    items={this.formMaster}
                    labelLocation={'left'}
                />
            </div>
            </Popup>
        )
    }
}
