import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { kebijakan, productDefault, programs } from 'dataSource/lookup'
import ModalAlokasiKategori from '../modal/static-data/investasi/setup-kebijakan/alokasi/index'
import ModalIndikasi from '../modal/static-data/investasi/setup-kebijakan/indikasi/index'
import ModalMatrik from '../modal/static-data/investasi/setup-kebijakan/matrik/index'
import ModalAlokasiPerPihak from '../modal/static-data/investasi/setup-kebijakan/alokasiPerPihak/index'
import httpRequest from 'plugin/httprequest'

class SetupProfileInvestasi extends Component {
    constructor(props) {
        super(props)
        this.dataGridRef = React.createRef()
        this.modalAlokasiKategoriRef = React.createRef()
        this.modalIndikasiRef = React.createRef()
        this.modalMatrikRef = React.createRef()
        this.modalAlokasiPerPihakRef = React.createRef()
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.menuCode == 'MPKE') {
                                this.showModalProfile(data)
                            } else if (data.menuCode == 'MPPE') {
                                this.showModalProfilePerPihak(data)
                            } else if (data.menuCode == 'IRPK') {
                                this.showModalIndikasi(data)
                            } else if (data.menuCode == 'MR') {
                                this.showModalMatrik(data)
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'rowNumber',
                caption: 'No.',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'kebijakanId',
                caption: 'Kebijakan',
                lookup: {
                    dataSource: kebijakan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'kebijakanName',
                }
            },
            {
                dataField: 'spesifikasi',
                caption: 'Spesifikasi',
            },
            {
                dataField: 'rujukan',
                caption: 'Rujukan Aturan Badan Otoritas',
            },
            // {
            //     dataField: 'tipeData',
            //     caption: 'Tipe',
            // },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                }
            },
            // {
            //     dataField: 'status',
            //     caption: 'Status'
            // },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModalProfile = () => {
        this.modalAlokasiKategoriRef.current.show()
    }
    showModalProfilePerPihak = () => {
        this.modalAlokasiPerPihakRef.current.show()
    }
    showModalIndikasi = () => {
        this.modalIndikasiRef.current.show()
    }
    showModalMatrik = () => {
        this.modalMatrikRef.current.show()
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }
    loadData = async () => {
        // var data = [
        //     {
        //         id: 1,
        //         kebijakan: 'Alokasi Investasi',
        //         spesifikasi: 'Maksimum Penempatan Per Kategori Efek',
        //         rujukanAturan: 'Peraturan OJK No 1234',
        //         product: 1,
        //     },
        //     {
        //         id: 2,
        //         kebijakan: 'Alokasi Investasi',
        //         spesifikasi: 'Maksimum Penempatan Per Emiten',
        //         rujukanAturan: 'Peraturan OJK No 1234',
        //         product: 1,
        //     },
        //     {
        //         id: 3,
        //         kebijakan: 'Parameter Resiko',
        //         spesifikasi: 'Indikasi Resiko Per Kategori',
        //         rujukanAturan: 'Peraturan OJK No 1234',
        //         product: 1,
        //     },
        //     {
        //         id: 4,
        //         kebijakan: 'Parameter Resiko',
        //         spesifikasi: 'Matrik Resiko',
        //         rujukanAturan: 'Peraturan OJK No 1234',
        //         product: 1,
        //     },
        // ]
        // return data
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasis`, 'GET')
        return response
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Profile Investasi Produk</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='products'
                    insertAPI='staticdata-wf/products'
                    updateAPI='staticdata-wf/products'
                    deleteAPI='staticdata-wf/products'

                    backendserver={process.env.REACT_APP_BACKEND}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Produk"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Produk Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalAlokasiKategori
                    ref={this.modalAlokasiKategoriRef}
                    store={this.props.store}
                />
                <ModalAlokasiPerPihak
                    ref={this.modalAlokasiPerPihakRef}
                    store={this.props.store}
                />
                <ModalIndikasi
                    ref={this.modalIndikasiRef}
                    store={this.props.store}
                />
                <ModalMatrik
                    ref={this.modalMatrikRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default SetupProfileInvestasi