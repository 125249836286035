import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import {findLastDayOfMonth, formatDate,  formatDefaultDate, formatDefaultFullDate, getSystemDate} from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from "redux/actions/loading";
import DevExtremeTreeList from 'components/inheritComponent/devextremetreelist';
import { product } from 'dataSource/lookup';
import Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';

class BalanceSheetDetail2 extends Component {
  constructor(props) {
    super(props)

    this.systemDate = getSystemDate(props.store)

    this.state = {
      formFilter: {
        tanggal : getSystemDate(props.store),
        produkId : null
      }
    }

    this.treeListRef = React.createRef()
    // this.modalDetailProsesIuranRef = React.createRef()
    // this.modalGenerateProsesIuranRef = React.createRef()

    this.filterItem = [
      {
        dataField: "tanggal",
        label: {
          text: "Tanggal",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'MMM yyyy',
          calendarOptions: {
            maxZoomLevel: 'year'
          },
          openOnFieldClick: true
        }
      },
      {
        dataField: "produkId",
        label: {
          text: "Produk",
          alignment: "left",
          location: "left"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: product(this.props.store),
          displayExpr: function(item){
            return item && item.productCode + " - " + item.productName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            // this.treeListRef.current.forceRefresh(true)
            this.filter()
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
        {
            dataField: 'description',
            caption: 'Deskripsi',
        },
        {
            dataField: 'saldo',
            caption: 'Saldo',
            format: '#,##0.00',
            alignment: 'right',
            width: '300px'
        },
    ]

    this.dataPasiva = []
    this.dataAktiva = []
    this.result = []
    this.lastMonthDate = new Date()
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  filter = async () => {
    let dataState, loadAPI, response, tanggal
    dataState = this.state.formFilter
    tanggal = findLastDayOfMonth(new Date(dataState.tanggal).getFullYear(), new Date(dataState.tanggal).getMonth())
    loadAPI = `report-headers/get-rincian-neraca/${formatDate(tanggal)}/${dataState.produkId}`
    response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET')
    this.result = response
    this.treeListRef.current.forceRefresh(true)
  }

  loadData = async () => {
    return this.result
  }

  // loadData = async () => {
  //   dataMap.sort((a, b) => {
  //     // // return a.description.localeCompare(b.description);
  //     // let [aSeqNo, aId] = a.id.split('-').map(Number);
  //     // let [bSeqNo, bId] = b.id.split('-').map(Number);

  //     // if (aSeqNo !== bSeqNo) {
  //     //     return aSeqNo - bSeqNo;
  //     // }
  //     // return aId - bId;
  //     function splitDescription(description) {
  //       const [numericPart, ...textParts] = description.split(" - ");
  //       const numericParts = numericPart.split('.').map(Number); 
  //       const textPart = textParts.join(" - "); 
  //       return { numericParts, textPart };
  //   }

  //     const aParts = splitDescription(a.description);
  //     const bParts = splitDescription(b.description);

  //     for (let i = 0; i < Math.max(aParts.numericParts.length, bParts.numericParts.length); i++) {
  //         const aNum = aParts.numericParts[i] || 0;
  //         const bNum = bParts.numericParts[i] || 0;
  //         if (aNum < bNum) return -1;
  //         if (aNum > bNum) return 1;
  //     }

  //     const textComparison = aParts.textPart.localeCompare(bParts.textPart);
  //     if (textComparison !== 0) {
  //         return textComparison;
  //     }
  //     return a.id < b.id ? -1 : (a.id > b.id ? 1 : 0);
  //   })
 
  // }
  
  print = async () => {
    // this.props.store.dispatch(showLoading(true))

    var result = await this.treeListRef.current.getDataSource()
    var period = formatDefaultFullDate(this.state.formFilter.tanggal)
    var prevPeriod = formatDefaultFullDate(this.lastMonthDate)
    period = period.split(' ');
    prevPeriod = prevPeriod.split(' ');
    period = period[1]+' '+period[2];
    prevPeriod = prevPeriod[1]+' '+prevPeriod[2];

    reportFile({
      template: { 'shortid': 'HJlpoxvQZt' },
      data: {
        period: period,
        prevPeriod: prevPeriod,
        dataBalanceSheet: result
      },
      options: {
          reportName: `Pos-Pos Neraca ${period}`
      }
    },true,`Pos-Pos Neraca ${period}.pdf`)
    
    this.props.store.dispatch(showLoading(false))
  }

  printToExcel = async() => {
    try {
      let data = [];
      let dataSource = this.treeListRef.current.getDataSource();
      let tanggal = this.state.formFilter.tanggal;
      const firstTableRowNum = 1;

      if (dataSource.length > 0) {
        for (let value of dataSource) {
          let dataDetail = {
            level: value.level,
            description: value.description,
            amount: value.saldo
          }
          const objectValue = Object.values(dataDetail);
          data.push(objectValue);
        }

        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Pos-Pos Neraca');
        worksheet.addTable({
          name: 'MyTable',
          ref: `A${firstTableRowNum}`,
          headerRow: true,
          totalsRow: false,
          style: {
              theme: 'TableStyleLight15',
              showFirstColumn: true,
          },
          columns: [
              { name: 'level'},
              { name: 'Deskripsi'},
              { name: 'Saldo'}
          ],
          rows: data
        })
        let lastRowNum = worksheet.lastRow.number;
        const lastTableRowNum = lastRowNum;

        for (let i = firstTableRowNum; i <= lastTableRowNum; i++) {
          const row = worksheet.getRow(i);
          const levelValue = row.getCell(1).value;
          if (levelValue === 0 || levelValue === 1) {
            row.getCell(2).font = { bold: true };
            row.getCell(3).font = { bold: true };
          }
        }
      
        worksheet.getColumn(1).hidden = true;

        const Data1 = worksheet.getColumn(2);
        Data1.width = 110;

        const Data2 = worksheet.getColumn(3);
        Data2.width = 30;
        // Data2.numFmt = '""#,##0.00;[Red]\-""#,##0.00';
        Data2.numFmt = '""#,##0.00;\-""#,##0.00';

        tanggal = formatDefaultDate(tanggal).split(' ')
        await workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                'Pos-Pos Neraca ' + tanggal[1] + ' ' + tanggal[2] + '.xlsx'
            );
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
          location: 'after',
          widget: 'dxButton',
          options: {
              icon: 'print',
              hint: 'Export to pdf',
              onClick: (e) => {
                this.print()
              },
          }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'xlsxfile',
          hint: 'Export to excel',
          onClick: (e) => {
            this.printToExcel()
          },
        }
      }
    )
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Penjelasan Pos-Pos Neraca</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExtremeTreeList
            ref = {this.treeListRef}

            keyField= {'id'}
            parentIdExpr = {'parentId'}

            loadAPI='sys-menus'
            insertAPI='sys-menus'
            updateAPI='sys-menus'
            deleteAPI='sys-menus'

            backendserver={process.env.REACT_APP_BACKEND}

            useNotify = {false}

            useArraySource = {true}
            ArraySourceData = {this.loadData}

            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}

            onRowUpdating = {this.onRowUpdating}

            exportExcel={false}
            exportFileName={"User Group"}
            allowExportSelectedData={false}
            selection={'none'}
            showBorders={true}

            autoExpandAll = {true}

            paging={false}
            defaultPageSize={10}

            //bagian konfigurasi popup saat insert dan edit record
            popupTitle={'Data Menu'}
            popupWidth={700} //masukan dalam ukuran pixel
            popupHeight={500} //masukkan dalam ukuran pixel

            height = {'calc(100vh - 270px)'}

            popupFormLabelLocation='left' //accepted value = top, left, right
            popupFormMinColWidth={300} // minimum lebar kolom
            popupFormColCount={1} //jumlah kolom pada form

            //akhir bagian konfigurasi popup

            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

            FilterRow={false} // set false untuk mematikan fitur filter

            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
            SummaryConfiguration={this.summary}

            onToolbarPreparing = {this.onToolbarPreparing}

            //contoh konfigurasi kolom
            //this.columns = [{
            //    dataField: 'kolom1',
            //    caption: 'Ini Kolom 1'
            //}, {
            //    dataField: 'kolom2',
            //    caption: 'Ini Kolom 2'
            //}]
            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

            store={this.props.store} // jangan di edit edit
        />
        <span style={{fontSize: '14px'}}>* Data per tanggal {this.state.formFilter.tanggal ? formatDefaultDate(this.state.formFilter.tanggal) : ''}</span>
      </div>
    )
  }
}

export default BalanceSheetDetail2