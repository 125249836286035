import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import ModalAlokasi from 'components/pages/modal/investasi/pengaturan/aset-alocation/alokasi';
import { banks, managerInvestasis, custodies, productDefault } from 'dataSource/lookup';
import ModalActionKpd from 'components/pages/modal/investasi/pengaturan/kpd/action';
import { addURL } from 'redux/actions/url';

class KPD extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }

        this.modalActionKpdRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text : 'Ubah',
                    //     hint : 'Ubah',
                    //     cssClass: 'text-success',
                    //     onClick : (e) => {
                    //         this.showModalActionKpd('edit', e.row.data)
                    //     }
                    // },
                    {
                        text : 'File',
                        hint : 'File',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            console.log('file')
                        }
                    },
                ],
            },
            {
                dataField: 'noKontrak',
                caption: 'No Kontrak',
            },
            {
                dataField: 'custodyId',
                caption: 'Bank Custody',
                lookup: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'custodyName'
                }
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'managerInvestasiId',
                caption: 'Manager Investasi',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'startDate',
                caption: 'Tanggal Kontrak',
                dataType: 'date',
                format: 'dd MMM yyyy',
                editorOptions: {
                    useMaskBehavior: true,
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'endDate',
                caption: 'Tanggal Akhir',
                dataType: 'date',
                format: 'dd MMM yyyy',
                editorOptions: {
                    useMaskBehavior: true,
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'kontrakValue',
                caption: 'Nominal Kontrak',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'target',
                caption: 'Target Per Tahun(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'KPD',
                    onClick: (e) => {
                        this.showModalActionKpd('add')
                    },
                }
            }
        )
    }

    showModalActionKpd = (type,data = null) => {
        this.modalActionKpdRef.current.show()
        this.modalActionKpdRef.current.retrieveData(type,data)
    }
    forceRefresh =() => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Kerjasama Pengelolaan Dana</h2>
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='kpds'
                    insertAPI='kpds'
                    updateAPI='kpds'
                    deleteAPI='kpds' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"KPD"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'KPD'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    // onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalActionKpd 
                    ref={this.modalActionKpdRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                
            </div>
        )
    }
}

export default KPD