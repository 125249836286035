import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instruments } from 'dataSource/lookup'
import UpdatePenyertaanModal from 'components/pages/modal/investasi/portfolio/balance-position/detail/updatePenyertaanModal'
import httpRequest from 'plugin/httprequest'

class DataGridPenyertaanDana extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()
        this.showModalUpdateNilaiWajarRef = React.createRef()

        this.summary = [
            {  
                displayFormat: 'Total',
                showInColumn : 'bidangUsaha'
            },
            {
                name : 'amount',
                column: 'amount',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'lastNav',
                column: 'lastNav',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'nominalPl',
                column: 'nominalPl',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'quantity',
                column: 'quantity',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'hargaPerolehan',
                column: 'hargaPerolehan',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'hargaWajar',
                column: 'hargaWajar',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'nilaiWajar',
                column: 'nilaiWajar',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'percentPl',
                column: 'percentPl',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'percentKomposisi',
                column: 'percentKomposisi',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                width: 100,
                buttons: [
                    'edit',
                    {
                        text: 'Ubah Nilai Wajar',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'instrumentId',
                caption: 'ID',
                alignment: "right",
            },
            {
                dataField: 'instrumentId',
                caption: 'Kode Penyertaan',
                lookup: {
                    dataSource: instruments(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "instrumentCode" // provides display values
                }
            },
            {
                dataField: 'perusahaan',
                caption: 'Perusahaan',
                alignment:'center',
                columns: [
                    {
                        dataField: 'jenisPenyertaan',
                        caption: 'Jenis Penyertaan',
                    },
                    {
                        dataField: 'namaPerusahaan',
                        caption: 'Nama Perusahaan / Proyek'
                    },
                    {
                        dataField: 'bidangUsaha',
                        caption: 'Bidang Usaha',
                    },
                    {
                        dataField: 'amount',
                        caption: 'Nilai Penempatan',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0'
                    },
                    {
                        dataField: 'percentage',
                        caption: 'Persentase (%)',
                        alignment: 'right',
                        dataType: 'number',
                    },
                ]
            },
            {
                dataField: 'lastNav',
                caption: 'NAV Terakhir',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'nominalPl',
                caption: 'PL Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'quantity',
                caption: 'Quantity',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'hargaPerolehan',
                caption: 'Harga Perolehan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'hargaWajar',
                caption: 'Harga Wajar',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'nilaiWajar',
                caption: 'Nilai Wajar',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'percentPl',
                caption: 'PL (%)',
                alignment: 'right',
            },
            {
                dataField: 'percentKomposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'decimal',
            },
        ]
    }

    showModal = async(data) => {
        let getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs?instrumentId.in=${data.instrumentId}`, 'GET')
        if(data) {
            this.showModalUpdateNilaiWajarRef.current.show()
            this.showModalUpdateNilaiWajarRef.current.retrieveData(data, getInstrument[0])
        }
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    keyField={'instrumentId'}
                    loadAPI={`efek-balance-avgs/instrument-portofolio-penyertaanModal?productId=${this.props.productId}`}
                    insertAPI='efek-balance-avgs'
                    updateAPI='efek-balance-avgs'
                    deleteAPI='efek-balance-avgs' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Portofolio Penyertaan Modal"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Penyertaan Dana'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    height = {'calc(100vh - 336px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <UpdatePenyertaanModal
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    ref={this.showModalUpdateNilaiWajarRef}
                />
            </React.Fragment>
        )
    }
}

export default DataGridPenyertaanDana
