import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, getSystemDate } from 'plugin/helper'

class LaporanNeraca extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {
                productId: 1,
                balanceDate: getSystemDate(this.props.store)
            }
        }
        this.columns = [
            {
                dataField: 'akun_group',
                caption: 'Uraian',
                cellRender : (e) => {
                    var paddingSize = 20 * e.data.level
                    return <span style={{paddingLeft: `${paddingSize}px`}}>{e.text}</span>
                }
            },
            {
                dataField: 'tahun_berjalan',
                caption: 'Tahun Berjalan',
            },
            {
                dataField: 'tahun_sebelumnya',
                caption: 'Tahun Sebelumnya',
            },
        ]
        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'balanceDate',
                        label: {
                            text: 'Tanggal Balance'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            readOnly: true
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Load Data",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    loadData = () => {
        return [
            {
                id: 1,
                uraian: 'Aset',
                level: 0
            },
            {
                id: 1.1,
                uraian: 'INVESTASI (Nilai Historis)',
                level: 1
            },
            {
                id: '1.1.1',
                uraian: 'Tabungan pada bank',
                level: 2,
            },
            {
                id: '1.1.2',
                uraian: 'Deposito on call pada Bank',
                level: 2,
            },
            {
                id: '1.1.3',
                uraian: 'Deposito berjangka pada Bank',
                level: 2,
            },
            {
                id: '1.1.4',
                uraian: 'Sertifikat deposito pada Bank',
                level: 2,
            },
            {
                id: '1.1.5',
                uraian: 'Surat berharga yang diterbitkan Bank Indonesia',
                level: 2,
            },
            {
                id: '1.1.6',
                uraian: 'Surat berharga Negara',
                level: 2,
            },
            {
                id: '1.1.7',
                uraian: 'Saham yang tercatat di Bursa Efek di Indonesia',
                level: 2,
            },
            {
                id: '1.1.8',
                uraian: 'Obligasi Korporasi yang tercatat di Bursa Efek di Indonesia',
                level: 2,
            },
            {
                id: '1.1.9',
                uraian: 'Sukuk',
                level: 2,
            },
            {
                id: '1.1.10',
                uraian: 'Reksa Dana yang terdiri dari :',
                level: 2,
            },
            {
                id: '1.1.10.1',
                uraian: '- Reksa Dana pasar uang',
                level: 3,
            },
            {
                id: '1.1.10.2',
                uraian: '- Reksa Dana pendapatan tetap',
                level: 3,
            },
            {
                id: '1.1.10.3',
                uraian: '- Reksa Dana campuran',
                level: 3,
            },
            {
                id: '1.1.10.4',
                uraian: '- Reksa Dana saham',
                level: 3,
            },
            {
                id: '1.1.10.5',
                uraian: '- Reksa Dana terproteksi',
                level: 3,
            },
            {
                id: '1.1.10.6',
                uraian: '- Reksa Dana dengan penjaminan',
                level: 3,
            },
            {
                id: '1.1.10.7',
                uraian: '- Reksa Dana indeks',
                level: 3,
            },
            {
                id: '1.1.10.8',
                uraian: '- Reksa Dana berbentuk Kontrak Investasi Kolektif (KIK) Penyertaan Terbatas',
                level: 3,
            },
            {
                id: '1.1.10.9',
                uraian: '- Reksa Dana yang saham atau unit pernyataannya diperdagangkan di Bursa Efek',
                level: 3,
            },
            {
                id: '1.1.11',
                uraian: 'Medium Term Notes (MTN)',
                level: 2,
            },
            {
                id: '1.1.12',
                uraian: 'Efek beragun aset',
                level: 2,
            },
            {
                id: '1.1.13',
                uraian: 'Dana Investasi real estat berbentuk KIK',
                level: 2,
            },
            {
                id: '1.1.14',
                uraian: 'Kontrak opsi dan kontrak berjangka efek yang diperdagangkan di Bursa Efek di Indonesia',
                level: 2,
            },
            {
                id: '1.1.15',
                uraian: 'Repurchase Agreement (REPO)',
                level: 2,
            },
            {
                id: '1.1.16',
                uraian: 'Penyertaan Langsung',
                level: 2,
            },
            {
                id: '1.1.16.1',
                uraian: 'Penyertaan Langsung Dalam Negeri',
                level: 3,
            },
            {
                id: '1.1.16.1.1',
                uraian: 'Penyertaan Langsung pada LK',
                level: 4,
            },
            {
                id: '1.1.16.1.2',
                uraian: 'Penyertaan Langsung pada non LK',
                level: 4,
            },
            {
                id: '1.1.16.2',
                uraian: 'Penyertaan Langsung Luar Negeri',
                level: 3,
            },
            {
                id: '1.1.16.2.1',
                uraian: 'Penyertaan Langsung pada LK',
                level: 4,
            },
            {
                id: '1.1.16.2.2',
                uraian: 'Penyertaan Langsung pada non LK',
                level: 4,
            },
            {
                id: '1.1.17',
                uraian: 'Tanah di Indonesia',
                level: 2,
            },
            {
                id: '1.1.18',
                uraian: 'Bangunan di Indonesia',
                level: 2,
            },
            {
                id: '1.1.19',
                uraian: 'Tanah dan Bangunan di Indonesia',
                level: 2,
            },
            {
                id: '1.1.20',
                uraian: 'Akumulasi Penyusutan Bangunan',
                level: 2,
            },
            {
                id: 1.2,
                uraian: 'TOTAL INVESTASI',
                level: 1
            },
            {
                id: 1.3,
                uraian: 'ASET LANCAR DI LUAR INVESTASI',
                level: 1
            },
            {
                id: '1.3.1',
                uraian: 'Kas dan Bank Piutang Iuran',
                level: 2
            },
            {
                id: '1.3.1.1',
                uraian: 'Iuran Normal Pemberi Kerja',
                level: 3
            },
            {
                id: '1.3.1.2',
                uraian: 'Iuran Normal Peserta',
                level: 3
            },
            {
                id: '1.3.1.3',
                uraian: 'Iuran Tambahan',
                level: 3
            },
            {
                id: '1.3.2',
                uraian: 'Piutang Bunga keterlambatan iuran',
                level: 2
            },
            {
                id: '1.3.3',
                uraian: 'Beban Dibayar di Muka',
                level: 2
            },
            {
                id: '1.3.4',
                uraian: 'Piutang Investasi',
                level: 2
            },
            {
                id: '1.3.5',
                uraian: 'Piutang Hasil Investasi',
                level: 2
            },
            {
                id: '1.3.6',
                uraian: 'Piutang Lain-lain',
                level: 2
            },
            {
                id: 1.4,
                uraian: 'TOTAL ASET LANCAR DI LUAR INVESTASI',
                level: 1
            },
            {
                id: 1.5,
                uraian: 'ASET LAIN-LAIN',
                level: 1
            },
            {
                id: 2,
                uraian: 'TOTAL ASET',
                level: 0
            },
            {
                id: 3,
                uraian: 'LIABILITAS',
                level: 0
            },
            {
                id: 3.1,
                uraian: 'LIABILITAS DI LUAR NILAI KINI AKTUARIAL',
                level: 1
            },
            {
                id: '3.1.1',
                uraian: 'Utang Manfaat Pensiun Jatuh Tempo',
                level: 2
            },
            {
                id: '3.1.2',
                uraian: 'Utang Investasi',
                level: 2
            },
            {
                id: '3.1.3',
                uraian: 'Pendapatan Diterima di Muka',
                level: 2
            },
            {
                id: '3.1.4',
                uraian: 'Beban yang Masih Harus Dibayar',
                level: 2
            },
            {
                id: '3.1.5',
                uraian: 'Liabilitas Lain',
                level: 2
            },
            {
                id: 3.2,
                uraian: 'TOTAL LIABILITAS DI LUAR NILAI KINI AKTUARIAL',
                level: 1
            },
            {
                id: 4,
                uraian: 'ASET NETO',
                level: 0
            },
        ]
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">NERACA</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    loadAPI={`akuns/getReportNeraca/${this.state.filterData.productId}/${formatDate(this.state.filterData.balanceDate)}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"NERACA"}
                    allowExportSelectedData={true}
                    selection={false}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanNeraca