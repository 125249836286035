import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { findFirstDayOfMonth, findLastDayOfMonth, formatDate, formatDefaultDate, formatDefaultFullDate, formatDefaultMonthYear, getSystemDate } from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from "redux/actions/loading";
import DevExtremeTreeList from 'components/inheritComponent/devextremetreelist';
import { product } from 'dataSource/lookup';
import * as XLSX from 'xlsx-pro';
import notify from 'devextreme/ui/notify';
import Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver'

class RasioKeuangan extends Component {
    constructor(props) {
        super(props);

        this.systemDate = getSystemDate(props.store)

        this.state = {
            formFilter: {
                tanggal: getSystemDate(props.store),
                produkId: null
            }
        }

        this.treeListRef = React.createRef()

        this.filterItem = [
            {
                dataField: "tanggal",
                label: {
                    text: "Tanggal",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'MMM yyyy',
                    calendarOptions: {
                        maxZoomLevel: 'year'
                    },
                    openOnFieldClick: true
                }
            },
            {
                dataField: "produkId",
                label: {
                    text: "Produk",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: product(this.props.store),
                    displayExpr: function (item) {
                        return item && item.productCode + " - " + item.productName;
                    },
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filter()

                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                dataField: 'description',
                caption: 'Deskripsi',
                cellRender: (e) => {
                    let data = e.row.data
                    if (data.level === 1) {
                        return <span className='font-weight-bold'>{e.text}</span>
                    } else {
                        return <span>{e.text}</span>
                    }
                }
            },
            {
                dataField: 'amount',
                caption: 'Nilai',
                format: '#,##0.00',
                alignment: 'right',
                width: '300px',
                cellRender: (e) => {
                    let data = e.row.data
                    if (data.level === 3) {
                        return <span>{e.text} %</span>
                    } else {
                        return <span>{e.text}</span>
                    }
                }
            },
        ]

        this.result = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    filter = async () => {
        let dataState, loadAPI, response, tanggal
        dataState = this.state.formFilter
        tanggal = findLastDayOfMonth(new Date(dataState.tanggal).getFullYear(), new Date(dataState.tanggal).getMonth())
        this.props.store.dispatch(showLoading(true))
        loadAPI = `coa-position-dailies/getAllRasio/${formatDate(tanggal)}/${dataState.produkId}`
        response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET')
        this.result = response
        this.treeListRef.current.forceRefresh(true)
        this.props.store.dispatch(showLoading(false))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    hint: 'Export to pdf',
                    onClick: (e) => {
                        this.print()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'xlsxfile',
                    hint: 'Export to excel',
                    onClick: (e) => {
                        this.printExcel()
                    },
                }
            },
        )
    }

    print = () => {
        let dataMaster = this.state.formFilter
        let dataResult = this.treeListRef.current.getDataSource()

        let periodDate = formatDefaultFullDate(dataMaster.tanggal).split(' ')
        periodDate = `${periodDate[1]} ${periodDate[2]}`

        this.props.store.dispatch(showLoading(true))
        reportFile({
            template: { 'shortid': '5_JY_Ig' },
            data: {
                rasioDate: formatDefaultMonthYear(dataMaster.tanggal),
                dataRasio: dataResult
            },
            options: {
                reportName: `Rasio Keuangan ${periodDate}`
            }
        }, true, `Rasio Keuangan ${periodDate}`)

        this.props.store.dispatch(showLoading(false))
    }

    printExcel = async () => {
        let data = []
        let dataSource = this.treeListRef.current.getDataSource();
        var tanggal = this.state.formFilter.tanggal
        const firstTableRowNum = 1;
        if(dataSource.length > 0){
            for(let value of dataSource){
                var dataDetail = {
                    description: value.description,
                    amount: value.amount,
                }
                const objectValue = Object.values(dataDetail);
                data.push(objectValue)
            }
            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet('Rasio Keuangan');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                },
                columns: [
                    { name: 'Deskripsi'},
                    { name: 'Nilai'},
                ],
                rows: data
            });
            
            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;
            
            worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = 60;

            const Data2 = worksheet.getColumn(2);
            Data2.width = 30;
            Data2.numFmt = '""#,##0.00;[Red]\-""#,##0.00';

            tanggal = formatDefaultFullDate(tanggal).split(' ')
            await workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: 'application/octet-stream' }),
                    'Rasio Keuangan ' + tanggal[1] + ' ' + tanggal[2] + '.xlsx'
                );
            });
        }
        else{
            notify({ message: 'Mohon untuk memilih data terlebih dahulu!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 1000);
        }
    }

    loadData = () => {
        return this.result
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Rasio Keuangan</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />

                <DevExtremeTreeList
                    ref={this.treeListRef}

                    keyField={'id'}
                    parentIdExpr={'rasio_id'}

                    loadAPI='sys-menus'
                    insertAPI='sys-menus'
                    updateAPI='sys-menus'
                    deleteAPI='sys-menus'

                    backendserver={process.env.REACT_APP_BACKEND}

                    useNotify={false}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    // onRowUpdating={this.onRowUpdating}

                    exportExcel={false}
                    exportFileName={"Rasio Keuangan"}
                    allowExportSelectedData={false}
                    selection={'none'}
                    showBorders={true}

                    autoExpandAll={false}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Rasio Keuangan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    height={'calc(100vh - 270px)'}

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={false} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default RasioKeuangan