import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate } from 'plugin/helper'
import ModalKomite from 'components/pages/modal/investasi/laporan/transaksi/pengajuan-investasi/komite'
import ModalKajian from 'components/pages/modal/investasi/laporan/transaksi/pengajuan-investasi/kajian'
import ModalInstrument from 'components/pages/modal/investasi/laporan/transaksi/pengajuan-investasi/instrument'
import httpRequest from 'plugin/httprequest'

class LaporanPengajuanInvestasi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.modalKajianRef = React.createRef()
        this.modalInstrumentRef = React.createRef()
        this.modalKomiteRef = React.createRef()
        this.dgRef = React.createRef()

        this.state = {
            filterData: {
                productId: 1,
                statusId: 'ALL',
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        // text: 'Komite',
                        text: 'Persetujuan',
                        onClick: async (e) => {
                            this.modalKomite(e.row.data);
                        },
                    },
                    // {
                    //     text: 'Instrument',
                    //     onClick: async (e) => {
                    //         this.modalInstrument(e.row.data);
                    //     },
                    // },
                    {
                        text: 'Kajian',
                        onClick: async (e) => {
                            this.modalKajian(e.row.data);
                        },
                    },
                ]
            },
            {
                dataField: 'orderDate',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'categoryName',
                caption: 'Kategori Instrument',
            },
            {
                dataField: 'transactionName',
                caption: 'Transaksi',
            },
            {
                dataField: 'jumQuantity',
                caption: 'Total Kuantitas',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'jumNominal',
                caption: 'Total Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'brokerName',
                caption: 'Broker',
            },
            {
                dataField: 'bankName',
                caption: 'Bank',
            },
            {
                dataField: 'miName',
                caption: 'MI',
            },
            // {
            //     dataField: 'komite',
            //     caption: 'Komite',
            // },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'OPEN',
                            value: 'Disetujui'
                        },
                        {
                            id: 'REJECTED',
                            value: 'Ditolak'
                        },
                        {
                            id: 'WF_INPROGRESS',
                            value: 'Dalam Proses Persetujuan'
                        },
                        {
                            id: 'ALL',
                            value: 'Semua'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.productCode + ' - ' + e.productName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'startDate',
                        label: {
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'endDate',
                        label: {
                            visible: false
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'statusId',
                        label: {
                            text: 'Status',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'ALL',
                                    value: 'Semua'
                                },
                                {
                                    id: 'WF_INPROGRESS',
                                    value: 'Dalam Proses Persetujuan'
                                },
                                {
                                    id: 'OPEN',
                                    value: 'Disetujui'
                                },
                                {
                                    id: 'REJECTED',
                                    value: 'Ditolak'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                                this.dgRef.current.forceRefresh(true)
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    modalKomite = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `pengajuanorder-wf/historic/user-task-activity?procId=${data.procId}`)
        this.modalKomiteRef.current.show(response)
    }
    modalInstrument = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-report-transaksi-pengajuan-investasi-instruments?orderId.in=${data.orderId}`, 'GET')
        this.modalInstrumentRef.current.show(response)
    }
    modalKajian = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `pengajuanorder-wf/historic/${data.procId}`)
        let dataKajian = {
            kajian: atob(response.data.kajian),
            kajianContentType: response.data.kajianContentType
        }
        this.modalKajianRef.current.show(dataKajian)
    }

    loadData = async () => {
        var loadAPI = ''
        if (this.state.filterData.statusId == 'ALL') {
            loadAPI = `view-report-transaksi-pengajuan-investasis?orderDate.greaterThanOrEqual=${formatDate(this.state.filterData.startDate)}&orderDate.lessThanOrEqual=${formatDate(this.state.filterData.endDate)}&productId.in=${this.state.filterData.productId}&size=99999`
        } else {
            loadAPI = `view-report-transaksi-pengajuan-investasis?orderDate.greaterThanOrEqual=${formatDate(this.state.filterData.startDate)}&orderDate.lessThanOrEqual=${formatDate(this.state.filterData.endDate)}&productId.in=${this.state.filterData.productId}&size=99999&statusId.in=${this.state.filterData.statusId}`
        }
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var data = response
        return data
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Transaksi Pengajuan Investasi</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI={`-`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Pengajuan Investasi"}
                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'jumQuantity',
                            'jumNominal'
                            // 'komposisi'
                        ]
                    }
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalKomite
                    ref={this.modalKomiteRef}
                    store={this.props.store}
                />
                <ModalKajian
                    ref={this.modalKajianRef}
                    store={this.props.store}
                />
                <ModalInstrument
                    ref={this.modalInstrumentRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default LaporanPengajuanInvestasi