import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { jabatan, sppdBiayaType,sppdBiaya, jabatanGolongan } from 'dataSource/lookup';
import { alert, confirm } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import FormModalEditSppd from './formModalEditSppd';

class BiayaPerjalananDinas extends Component {
    constructor(props){
        super(props)

        this.dataGridRef = React.createRef();
        this.columns = [
            // {
            //     type: 'buttons',
            //     buttons: [
            //         { 
            //             text: 'Edit',
            //             hint: 'Edit',
            //             cssClass: 'text-success',
            //             onClick: async (e) => {
            //                 this.showModalView(e.row.data, 'edit')
            //             }
            //         },
            //         {
            //             text: 'Hapus',
            //             hint: 'Hapus',
            //             cssClass: 'text-success',
            //             onClick: async (e) => {
            //                 var konfirm = await confirm('Apakah anda yakin ingin membatalkan data ini ?', 'Konfirmasi')
            //                 if (konfirm) {
            //                     this.batal(e.row.data)
            //                 }
            //             }
            //         },
            //     ]
            // },
            {
                dataField:"jabatanGolonganId",
                caption:"Jabatan",
                lookup: {
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaJagol', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            // {
            //     dataField:"jabatanId",
            //     caption:"Golongan Jabatan",
            //     lookup: {
            //         dataSource: jabatan(this.props.store),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'jabatanName', // provides display values
            //     },
            // },
            {
                dataField:"biayaKode",
                caption:"Detail Biaya",
            },
            // {
            //     dataField:"keterangan",
            //     caption:"Keterangan Biaya",
            // },
            {
                dataField:"jumlahHari",
                caption:"Jumlah Hari",
                dataType:"number",
            },
            {
                dataField:"sppdBiayaTypeId",
                caption:"SPPD Biaya Type",
                lookup: {
                    dataSource: sppdBiayaType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'keterangan', // provides display values
                },
            },
            
            {
                dataField:"biayaPerhari",
                caption:"Biaya Perhari",
                dataType:"number",
                format:"#,##0.00",
                editorOptions:{
                    format:"#,##0.00",
                }
            },
        ]

        this.showModalRef = React.createRef();
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    showModalView = async (data, type) => {
        try {
            let loadApiSppd = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `sppd-biayas/${data.id}`, 'GET');
        
            if (type !== 'add') {
                await this.showModalRef.current.retrieveData(loadApiSppd, type);
            }
        } catch (error) {
            console.log(error);
        }

        this.showModalRef.current.show();
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: () => {
                        this.showModalView({}, 'add')
                    }
                }
            },
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Biaya Perjalanan Dinas</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='sppd-biayas?size=99999'
                    insertAPI='sppd-biayas'
                    updateAPI='sppd-biayas'
                    deleteAPI='sppd-biayas' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"Biaya Perjalanan Dinas"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Biaya Perjalanan Dinas'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    // onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                {/* <FormModalEditSppd 
                    ref={this.showModalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                /> */}
            </div>
        )
    }
}

export default BiayaPerjalananDinas