import React, { Component } from 'react'
import { currency, banks, product, pejabat, instrumentCategories, statusKeuangan } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import { imageSource } from 'plugin/helper'

class InstruksiKustodiTerkirim extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }

        this.rekBrokerData = []
        this.brokerData = []
        this.instrumentData = []

        this.dataGridInstruksiKustodiRef = React.createRef()
        this.formPenempatanRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            this.previewFile(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'instruksiId',
                caption: 'ID'
            },
            {
                dataField: 'custodyName',
                caption: 'Bank Kustodi',
            },
            {
                dataField: 'noRekening',
                caption: 'No Akun / Rekening',
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiCode',
                        caption: 'Nomor'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'categoryId',
                caption: 'Kategori',
                lookup: {
                    dataSource: instrumentCategories(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categoryName'
                }
            },
            {
                dataField: 'transactionName',
                caption: 'Transaksi',
            },
            // {
            //     dataField: 'transactionDate',
            //     caption: 'Tanggal Transaksi',
            //     dataType: 'date',
            //     format: 'dd MMM yyyy'
            // },
            // {
            //     dataField: 'tanggalJatuhTempo',
            //     caption: 'Tanggal Jatuh Tempo',
            //     dataType: 'date',
            //     format: 'dd MMM yyyy'
            // },
            {
                dataField: 'totalTransaction',
                caption: 'Total Transaksi',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'statusKeuanganId',
                caption: 'Status Keuangan',
                lookup: {
                    dataSource: statusKeuangan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName'
                }
            },
            // {
            //     dataField: 'currencyId',
            //     caption: 'Mata Uang',
            //     lookup: {
            //         dataSource: currency(this.props.store),
            //         valueExpr: 'id',
            //         displayExpr: 'currencyCode'
            //     }
            // },
            {
                dataField: 'status',
                caption: 'Status'
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    forceRefresh = () => {
        this.dataGridInstruksiKustodiRef.current.forceRefresh(true)
    }
    loadData = async () => {
        var dataStok = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksi-stok/list?size=9999&status=SENT`, 'GET')
        var dataEtf = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksi-etf/list?size=9999&status=SENT`, 'GET')
        var dataSbn = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksi-sbn/list?size=9999&status=SENT`, 'GET')
        var result = []
        var allData = [
            ...dataStok,
            ...dataEtf,
            ...dataSbn
        ]
        for (var data of allData) {
            var dataDetail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksis/${data.instruksiId}`, 'GET')
            var dataResult = {
                instruksiId: data.instruksiId,
                custodyName: data.custodyName,
                instruksiCode: data.instruksiCode,
                instruksiDate: data.instruksiDate,
                pejabat1: data.pejabat1,
                pejabat2: data.pejabat2,
                transactionName: data.transactionName,
                categoryId: dataDetail.categoryId,
                brokerId: data.brokerId,
                totalTransaction: data.totalTransaction,
                statusKeuanganId: dataDetail.statusKeuanganId,
                status: data.status,
            }
            result.push(dataResult)
        }
        return result
    }
    previewFile = async(data) => {
        var instruksiId = data.instruksiId
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksis/${instruksiId}`, 'GET')
        var src = imageSource(response.fileInstruksiContentType,response.fileInstruksi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Bank Kustodi Terkirim</h2>
                <DevExpressDataGrid
                    keyField='instruksiId'
                    ref={this.dataGridInstruksiKustodiRef}
                    loadAPI='custody-instruksi-stok/list?status=OPEN'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instruksi Bank"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Bank'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default InstruksiKustodiTerkirim