import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalProgramDapenKepesertaan from 'components/pages/modal/sdm-umum/kepegawaian/pensiun-iuran/kepesertaan/form';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';

class ProgramDapenKepesertaan extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        onClick : (e) => {
                            this.showModalProgramDapenKepesertaan(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField:'nik',
                caption:'NIK'
            },
            {
                dataField:'namaKaryawan',
                caption:'Nama Karyawan'
            },
            {
                dataField:'phdp',
                caption:'PHDP'
            },
            {
                dataField:'programDapen',
                caption:'Program Dapen',
                alignment:'center',
                columns:[
                    {
                        dataField:'dapen',
                        caption:'Dapen'
                    },
                    {
                        dataField:'jumlahProduk',
                        caption:'Jumlah Produk'
                    },
                ]
            },
            {
                dataField:'iuranDapen',
                caption:'Iuran Dapen',
                alignment:'center',
                columns:[
                    {
                        dataField:'pemberiKerja',
                        caption:'Pemberi Kerja'
                    },
                    {
                        dataField:'peserta',
                        caption:'Peserta'
                    },
                ]
            },
        ]

        this.showModalProgramDapenKepesertaanRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalProgramDapenKepesertaan = (e) => {
        if (e.row) {
            this.showModalProgramDapenKepesertaanRef.current.retrieveData(e.row.data)
        }
        this.showModalProgramDapenKepesertaanRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalProgramDapenKepesertaan(e)
                    }
                }
            }
        )
    }

    loadDummyData = () => {
        var data = [
            {
                id:1
            }
        ]

        return data
    }

    render() {
        return (
            <div className="container-fluid">
            <h3 className="main-title">Kepesertaan</h3>
            <DevExpressDataGrid
                ref = {this.DataGridPengajuanRef}
                loadAPI='regist-pengkinian-pengajuan-aktifs'
                insertAPI='regist-pengkinian-pengajuan-aktifs'
                updateAPI='regist-pengkinian-pengajuan-aktifs'
                deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                useArraySource = {true}
                ArraySourceData= {this.loadDummyData}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={true}
                exportFileName={"Kepesertaan"}
                allowExportSelectedData={true}
                selection={"multiple"}
                
                showBorders={true}

                paging={true}
                defaultPageSize={10}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Tipe Komponen'}
                popupWidth={450} //masukan dalam ukuran pixel
                popupHeight={330} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={1} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing = {this.onToolbarPreparing}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
            <FormModalProgramDapenKepesertaan 
                ref={this.showModalProgramDapenKepesertaanRef}
                store={this.props.store}
            />
        </div>
        )
    }
}

export default ProgramDapenKepesertaan
