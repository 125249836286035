import React, { Component } from "react";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { addURL } from 'redux/actions/url'
import { product, productDefault } from 'dataSource/lookup'
import { Form } from "devextreme-react";
import { findLastDayOfMonth, formatDate, formatDefaultFullDate } from 'plugin/helper'
import httpRequest from "plugin/httprequest";
import showloading from "redux/reducers/loading";
import DataGridSahamPph23 from "./comp/list-pajak-penghasilan/dataGridSahamPph23";
import DataGridSahamPph23Detail from "./comp/list-pajak-penghasilan/dataGridSahamPph23Detail";
import DataGridPph23JasaLainnya from "./comp/list-pajak-penghasilan/dataGridPph23JasaLainnya";
import DataGridPph23JasaLainnyaDetail from './comp/list-pajak-penghasilan/dataGridPph23JasaLainnyaDetail';
import DataGridPph21Karyawan from './comp/list-pajak-penghasilan/dataGridPph21Karyawan';
import DataGridPph21ManfaatPensiun from './comp/list-pajak-penghasilan/dataGridPph21ManfaatPensiun';

class ListPajakPenghasilan extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                productId:1,
                startDate: new Date(new Date().getFullYear(), 0, 1),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.prevTabIndex = 0
        this.dataList = []

        this.dataGridPph23SahamRef = React.createRef()
        this.dataGridPph23SahamDetailRef = React.createRef()
        this.dataGridPph23JasaLainnyaRef = React.createRef()
        this.dataGridPph23JasaLainnyaDetailRef = React.createRef()
        this.dataGridPph21KaryawanRef = React.createRef()
        this.dataPph21ManfaatPensiun = React.createRef()
        this.formRef = React.createRef()

        this.TabDataSource = [
            {
                id: 1, 
                text: 'PPH 23 Saham',
            },
            {
                id: 2,
                text: 'PPH 23 Saham - Detail'
            },
            {
                id: 3,
                text: 'PPH 23 Jasa Lainnya'
            },
            {
                id: 4,
                text: 'PPH 23 Jasa Lainnya - Detail'
            },
            {
                id: 5,
                text: 'PPH 21 Karyawan'
            },
            {
                id: 6,
                text: 'PPH 21 Manfaat Pensiun'
            }
        ]

        this.filterItem = [
            {
              dataField: "endDate",
              label: {
                text: "Periode",
                alignment: "left",
                location: "left"
              },
              editorType: "dxDateBox",
              editorOptions: {
                useMaskBehavior: true,
                displayFormat: 'MMM yyyy',
                calendarOptions: {
                    maxZoomLevel: 'year'
                },
                openOnFieldClick: true
              }
            },
            {
              dataField: "productId",
              label: {
                text: "Produk",
                alignment: "left",
                location: "left"
              },
              editorType: "dxSelectBox",
              editorOptions: {
                dataSource: product(this.props.store),
                displayExpr: function(item){
                  return item && item.productCode + " - " + item.productName;
                },
                valueExpr: 'id',
                searchEnabled: true,
                deferRendering: false,
              }
            },
            {
              itemType: "button",
              buttonOptions: {
                text: "Filter",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: async() => {
                    // this.treeListRef.current.forceRefresh(true)
                    // await alert('Under Development', 'Information!');
                    // this.filterData()

                    // let selectedDate = new Date(this.state.filterData.endDate)
                    // console.log(selectedDate);
                    this.setState({
                        filterData: this.state.filterData
                    })
                },
              },
              horizontalAlignment: "left"
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pajak Penghasilan</h3>
                <Form 
                    ref={this.formRef}
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                    labelLocation="left"
                />
                <div className="mt-3" >
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                </div>
                <div id="tabindex-0" className={' mt-2 text-center' } height={'100%'}>
                    <DataGridSahamPph23 store={this.props.store} ref={this.dataGridPph23SahamRef} stateData={this.state.filterData} tabName={'SahamPph23'} />
                </div>
                <div id="tabindex-1" className={' d-none mt-2 text-center' } height={'100%'}>
                    <DataGridSahamPph23Detail store={this.props.store} ref={this.dataGridPph23SahamDetailRef} stateData={this.state.filterData} tabName={'SahamPph23Detail'} />
                </div>
                <div id="tabindex-2" className={' d-none mt-2 text-center' } height={'100%'}>
                    <DataGridPph23JasaLainnya store={this.props.store} ref={this.dataGridPph23SahamDetailRef} stateData={this.state.filterData} tabName={'SahamPph23Lainnya'} />
                </div>
                <div id="tabindex-3" className={' d-none mt-2 text-center' } height={'100%'}>
                    <DataGridPph23JasaLainnyaDetail store={this.props.store} ref={this.dataGridPph23SahamDetailRef} stateData={this.state.filterData} tabName={'SahamPph23LainnyaDetail'} />
                </div>
                <div id="tabindex-4" className={' d-none mt-2 text-center' } height={'100%'}>
                    <DataGridPph21Karyawan store={this.props.store} ref={this.dataGridPph23SahamDetailRef} stateData={this.state.filterData} tabName={'pph21Karyawan'} />
                </div>
                <div id="tabindex-5" className={' d-none mt-2 text-center' } height={'100%'}>
                    <DataGridPph21ManfaatPensiun store={this.props.store} ref={this.dataGridPph23SahamDetailRef} stateData={this.state.filterData} tabName={'pph21ManfaatPensiun'} />
                </div>
            </div>
        )
    }
}

export default ListPajakPenghasilan