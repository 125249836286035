import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, hubunganKeluarga, months, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { showLoading } from 'redux/actions/loading';
import { alert } from 'devextreme/ui/dialog';

class TransaksiMpGenerateSPTModal extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {},
            getUrl : 'pph-21-details?size=99999'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.pembayaranMpId = 0

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: async() => {
                        await this.generate()
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                itemType: 'group',
                caption: 'Periode Laporan',
                items: [
                    {
                        dataField: 'tahun',
                        label: {
                            alignment: 'left',
                            text: 'Tahun'
                        }
                    },
                    {
                        itemType: 'group',
                        colCount : 2,
                        items: [
                            {
                                dataField: 'bulanDari',
                                label: {
                                    alignment: 'left',
                                    text: 'Periode MP Dari'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                dataSource: months(),
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "value", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                            {
                                dataField: 'tahunDari',
                                label: {
                                    alignment: 'left',
                                    text: 'Tahun'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount : 2,
                        items: [
                            {
                                dataField: 'bulanSampai',
                                label: {
                                    alignment: 'left',
                                    text: 'Periode MP Sampai'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                dataSource: months(),
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "value", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                            {
                                dataField: 'tahunSampai',
                                label: {
                                    alignment: 'left',
                                    text: 'Tahun'
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Pemotong',
                items: [
                    {
                        dataField: 'tanggalPotongan',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Potongan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy'
                        }  
                    },
                    {
                        dataField: 'pejabatNama',
                        label: {
                            alignment: 'left',
                            text: 'Nama Pejabat'
                        }
                    },
                    {
                        dataField: 'pejabatNpwp',
                        label: {
                            alignment: 'left',
                            text: 'Nama NPWP'
                        }
                    },
                ]
            }
        ]

        this.dataGridColumn = [
            {
               dataField: 'no',
               caption: 'No',
               cellRender: (e) => {
                   return e.rowIndex + 1
               }
            }, 
            {
               dataField: 'nikPeserta',
               caption: 'NIK'
            },
            {
               dataField: 'noCard',
               caption: 'Card No'
            },
            {
               dataField: 'namaPeserta',
               caption: 'Nama Pensiunan'
            },
            // {
            //    dataField: 'statusMeninggal',
            //    caption: 'Status Meninggal',
            // },
            {
               dataField: 'mpDetilNominal',
               caption: 'Nilai Mp Bulanan',
               format: '#,##0.00'
            },
            {
               dataField: 'namaPenerima',
               caption: 'Nama Penerima',
               cellRender: (e) => {
                   var data = e.row.data
                   if(data.pensiunPenerimaTypeId === 1){
                        return data.namaPeserta
                    }else{
                        return "-"
                   }
               }
            },
            {
               dataField: 'relasiCode',
               caption: 'Hubungan Keluarga',
               lookup:{
                    dataSource: hubunganKeluarga(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
            {
               dataField: 'mpDetilRekeningNo',
               caption: 'Nomor Rekening'
            },
            {
               dataField: 'mpDetilRekeningName',
               caption: 'Nama Rekening'
            },
            {
               dataField: 'mpDetilRekeningBankId',
               caption: 'Bank',
               lookup:{
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName' 
                }
            },
        ]

    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide = () => {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    generate = async() => {
        let store = this.props.store
        // var dataProduct = this.props.product
        var dataSubmit = {
            ...this.state.dataMaster
        }
        dataSubmit.productId = 1
        // console.log(dataSubmit)
        // return null
        try {
            var loadAPI = 'generate-spt'
            var result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: dataSubmit
            })
            // store.dispatch(showLoading(false))
            // var message = 'Data Berhasil Di Generate!'
            // var res = await alert(message, 'Berhasil!')
            // if(res){
            //     this.props.forceRefresh()
            //     this.hide()
            // }

            var message = 'Data Berhasil Di Generate!'
            var res = await alert(message, 'Berhasil!')
            if(res){
                await this.loadDataGrid()
                this.hide()
                this.props.forceRefresh()
            }
            // if(result){
            //     store.dispatch(showLoading(true))
            //     var interval = setInterval(async() => { 
            //         try {
            //             var loadAPI = `pph-21-details/getByPph21Id/${result.id}`
            //             var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')  
            //             if(getPembayaranMp.length !== 0){
            //                 clearInterval(interval)
            //                 this.props.store.dispatch(showLoading(false))
            //                 var message = 'Data Berhasil Di Generate!'
            //                 var res = await alert(message, 'Berhasil!')
            //                 if(res){
            //                     await this.loadDataGrid()
            //                 }
            //             }
            //         } catch (e) {
            //             console.log(e)
            //         }

            //      }, 5000);
            // }
        } catch (e) {
            store.dispatch(showLoading(false))
            var message = e
            await alert(message, 'Data Gagal!')
        }
    }

    submitData = async() => {
        if(this.pembayaranMpId){
            var loadAPI = `pembayaran-mps`
            var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI + '/' + this.pembayaranMpId, 'GET')
            
            var dataUpdate = {...getPembayaranMp}
            dataUpdate.statusId = 1
    
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: this.pembayaranMpId,
                values: dataUpdate
            },getPembayaranMp)

        }

        this.props.forceRefresh()
        this.setState({
            popupVisible: false,
            dataMaster: {}
        });
        this.forceRefresh()
    }

    retrieveData = async(data) => {
        this.loadDataGrid()
        // this.setState({
        //     dataMaster: data,
        // })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: "Proses",
                    onClick: (e) => {
                        this.generate()
                    },
                }
            },
        );
    }

    loadDataGrid = async() => {
        var loadAPIDetail
        var loadAPI = 'simple-pembayaran-mps/getSimpleAllByMpPaymentType/3/5'
        var getMasterPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        if(getMasterPembayaranMp.length !== 0){
            this.pembayaranMpId = getMasterPembayaranMp[0].id
            loadAPIDetail = `pph-21-details/getAllByPph21Id/${getMasterPembayaranMp[0].id}`

            // var getDetailPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetail, 'GET')

            // return getDetailPembayaranMp
        }else{
            var loadAPI = 'simple-pembayaran-mps/getSimpleAllByMpPaymentType/1/5'
            var getMasterPembayaranMp8020 = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            if(getMasterPembayaranMp8020.length !== 0){
                this.pembayaranMpId = getMasterPembayaranMp8020[0].id
                loadAPIDetail = `pph-21-details/getAllByPph21Id/${getMasterPembayaranMp8020[0].id}`
                // var getDetailPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetail, 'GET')

                // return getDetailPembayaranMp
            }else{
                loadAPIDetail = 'pph-21-details/getAllByPph21Id/0'

            }
        }
        this.setState({
            getUrl: loadAPIDetail
        })
    }
    // forceRefresh = () => {
    //     this.dataGridRef.current.forceRefresh(true)
    // }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Generate SPT'} 
                minWidth={800}
                height={300}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                            />
                        </div>
                        {/* <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='pph-21-details'
                                updateAPI='pph-21-details'
                                deleteAPI='pph-21-details' 
                                
                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                remoteOperations = {true}
                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Generate SPT"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Generate SPT'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                height={'calc(70vh - 200px)'}
                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div> */}
                    </div>
                </div>
            </Popup>
        )
    }
}

export default TransaksiMpGenerateSPTModal