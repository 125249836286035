import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, pesertaAktif, statusIuran } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalDetailTransaksiIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/transaksiIuran/index';
import { formatNumber, formatNumberAum } from 'plugin/helper';

class TransaksiIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            getUrl: 'iurans/ListIuran/1%2C2/2?size=99999'
        }

        this.dataGridRef = React.createRef()
        this.modalDetailTransaksiIuranRef = React.createRef()

        this.dataResult = []
        this.iuranType = []

        this.filterItem = [
            {
                dataField: 'bulan',
                label: {
                    text: 'Periode Bulan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                }
            },
            {
                dataField: 'tahun',
                label: {
                    text: 'Periode Tahun',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxNumberBox',
                // editorOptions: {
                //     step: 0
                // }
            },
            {
                dataField: 'filter',
                label:{
                    visible:false,
                },
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Filter',
                    onClick: async(e) => {
                        // var filter = this.state.formFilter
                        // var bulan = filter.bulan
                        // var tahun = filter.tahun
                        
                        // if(bulan && tahun) {
                        //     await this.loadData(filter)
                        //     this.forceRefresh()
                        // }
                        this.filterSubmit()
                    }
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender : (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'bulan',
                caption: 'Periode Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Periode Tahun',
            },
            {
                dataField: 'pemberiKerja',
                caption: 'Pemberi Kerja'
            },
            {
                dataField: 'produk',
                caption: 'Produk'
            },
            {
                dataField: 'pesertaAktif',
                caption: 'Peserta Aktif',
                alignment: 'right',
                format: "#,##0",
            },
            {
                dataField: 'iuran',
                caption: 'Iuran',
                alignment:'center',
                columns: [
                    {
                        dataField: 'totalIuranPeserta',
                        caption: 'Iuran Peserta',
                        alignment: 'right',
                        format: "#,##0.00",
                        cellRender : (e) => {
                            var data = e.row.data
                            var result = 0
                            for(var value of data.iuranByType){
                                var iuranType = this.iuranType.find(val => val.id === value.iuranType)
                                if(iuranType.iuranPeserta){
                                    result = result + value.nominal
                                }
                            }

                            return formatNumberAum(result)
                        }
                    },
                    {
                        dataField: 'totalIuranPk',
                        caption: 'Iuran PK',
                        alignment: 'right',
                        format: "#,##0.00",
                        cellRender : (e) => {
                            var data = e.row.data
                            var result = 0
                            for(var value of data.iuranByType){
                                var iuranType = this.iuranType.find(val => val.id === value.iuranType)
                                if(!iuranType.iuranPeserta){
                                    result = result + value.nominal
                                }
                            }
                            return formatNumberAum(result)
                        }
                    },
                ]
            },
            {
                dataField: 'totalIuran',
                caption: 'Total Iuran',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusIuran(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        this.filterSubmit();
        store.dispatch(addURL(this.props.location.pathname))

        await this.retrieveIuranType()
    } 


    retrieveIuranType = async() => {
        var loadAPIuranTypes = `iuran-types`
        var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

        this.iuranType = dataIuranTypes
    }
    showModalDetail = (data) => {
        this.modalDetailTransaksiIuranRef.current.show()
        this.modalDetailTransaksiIuranRef.current.retrieveData(data)
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    
    filterSubmit = async () => {
        let filter = this.state.formFilter
        var loadApi = 'iurans/ListIuran/1%2C2/2?size=99999'
        var resultData = []

        if(filter.bulan || filter.tahun) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `iurans/getByBulanAndTahun/${filter.bulan}/${filter.tahun}/1%2C2/2?size=9999`, 'GET')
    
                for(let value of response) {
                    let dataValue = {
                        ...value,
                        pesertaAktif: value.pesertaAktif ? value.pesertaAktif : 0,
                        totalIuran: value.totalIuran ? value.totalIuran : 0
                    }
    
                    resultData.push(dataValue)
                }
            } catch (e) {
                console.log(e)
            }
        } else {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'GET')
    
                for(let value of response) {
                    let dataValue = {
                        ...value,
                        pesertaAktif: value.pesertaAktif ? value.pesertaAktif : 0,
                        totalIuran: value.totalIuran ? value.totalIuran : 0
                    }
    
                    resultData.push(dataValue)
                }
            } catch (e) {
                console.log(e)
            }
        }
        this.dataResult = resultData
        this.forceRefresh()
    }

    loadData = () => {
        this.forceRefresh()
        return this.dataResult
    }

    prosesData = async(idIuran) => {
        var value = {
            idIuran : idIuran
        }

        try {
            var loadAPI = 'pesertas/transaksiIuran'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                values:value
            })
            var text = 'Data Berhasil Diproses!'
            var type = "success"

            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);  
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
            
        }
        this.forceRefresh()
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Transaksi Iuran</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    // loadAPI={this.state.getUrl}
                    loadAPI=''
                    insertAPI='iurans'
                    updateAPI='iurans'
                    deleteAPI='iurans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    // remoteOperations = {false}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    exportExcel={true}
                    exportFileName={"Transaksi Iuran"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Transaksi Iuran'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalDetailTransaksiIuran 
                    ref={this.modalDetailTransaksiIuranRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default TransaksiIuran