import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, programs, statusPembayaranRekapGaji, statusRekruitmen } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import notify from "devextreme/ui/notify";
import FormPengajuanRekruitmen from 'components/pages/modal/sdm-umum/kepegawaian/rekruitmen/pengajuan/form';
import FormRincianRekruitmen from 'components/pages/modal/sdm-umum/kepegawaian/rekruitmen/pengajuan/rincian';

class PengajuanRekruitmen extends Component {
    constructor(props){
        super(props)

        this.dataGridRef = React.createRef()
        this.modalAddRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.showModalEditRekruitReff = React.createRef()
        this.showModalAddRekruitReff = React.createRef()
        this.showRincianRekruitmenReff = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        onClick : (e) => {
                            this.showModalEditRekruit(e)
                        }
                    },
                    
                    {
                        text : 'Batal',
                        hint : 'Batal',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin membatalkan data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.batal(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text : 'Rincian',
                        hint : 'Rincian',
                        onClick : (e) => {
                            this.showRincianRekruitmen(e.row.data)
                        }
                    },
                    {
                        text : 'Kirim',
                        hint : 'Kirim',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.kirim(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ],
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'kodeRekurtmen',
                caption: 'Kode Rekruitmen'
            },
            {
                dataField: 'headHunter',
                caption: 'Head Hunter'
            },
            {
                dataField: 'tanggalPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'jumlahUnit',
                caption: 'Jumlah Unit'
            },
            {
                dataField: 'jumlahPersonal',
                caption: 'Jumlah Personal'
            },
            {
                caption: 'Tanggal Publikasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tanggalPublikasiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalPublikasiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                caption: 'Tanggal Pendaftaraan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tanggalAdministrasiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalAdministrasiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                caption: 'Tanggal Seleksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tanggalSeleksiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalSeleksiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                caption: 'Tanggal Probisi',
                alignment: 'center',
                columns: [
                    {   
                        dataField: 'tanggalProbisiDari',
                        caption: 'Dari',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'tanggalProbisiSampai',
                        caption: 'Sampai',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
            {
                dataField: 'tanggalPengangkatan',
                caption: 'Tanggal Pengangkatan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup:{
                    dataSource: statusRekruitmen(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                }
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  text:'Tambah Rekruitmen',
                  onClick: (e) => {
                    this.showModalAddRekruit(e)
                  },
              }
          }
        )
    }

    showModalAddRekruit = () => {
        this.showModalAddRekruitReff.current.show()
    }

    showModalEditRekruit = (e) => {
        this.showModalEditRekruitReff.current.show()
        this.showModalEditRekruitReff.current.retrieveData(e.row.data)
    }

    showRincianRekruitmen = (data) => {
        this.showRincianRekruitmenReff.current.show()
        this.showRincianRekruitmenReff.current.retrieveData(data)
    }

    loadData = async() => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'rekurtmen/getByStatusId/1', 'GET')
        return response
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    kirim = async (data) => {
        try {
            data.statusId = 2
            var loadApi = `rekurtmen`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'PUT', {
                values: data,
                key:data.id
            },data)
            if (response) {
                this.forceRefresh()
                notify({ message: "Sukses mengirim data!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000);
            }
        } catch (e) {
            
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);
        }
    }

    batal = async (data) => {
        try {
            data.statusId = 3
            var loadApi = `rekurtmen`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'PUT', {
                values: data,
                key:data.id
            },data)
            if (response) {
                this.forceRefresh()
                notify({ message: "Sukses mengirim data!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000);
            }
        } catch (e) {
            
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Rekruitmen Karyawan</h3>
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='rekurtmen?size=9999'
                    insertAPI='pengajuan-rapel'
                    updateAPI='pengajuan-rapel'
                    deleteAPI='pengajuan-rapel' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Rapel"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Rapel'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanRekruitmen 
                    ref={this.showModalAddRekruitReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'add'}
                />
                <FormPengajuanRekruitmen 
                    ref={this.showModalEditRekruitReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'edit'}
                />
                <FormRincianRekruitmen 
                    ref={this.showRincianRekruitmenReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PengajuanRekruitmen