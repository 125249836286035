import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { mpPenerimaType, tipePembayaranMp } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import ModalRegistrasiUpdatePengalihanMp from 'components/pages/modal/kepesertaan/pengkinianPeserta/pengalihan/registrasi/index';
import { alert } from 'devextreme/ui/dialog'
import { formatDate, pembulatan } from 'plugin/helper';
import notify from 'devextreme/ui/notify';
import { showLoading } from 'redux/actions/loading';

class RegistrasiPengalihanMp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalAddRef = React.createRef()
        this.modalEditRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data, 'edit')
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah anda yakin ingin mengirim pengalihan ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.kirim(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah anda yakin ingin membatalkan pengalihan ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.batal(e.row.data.id)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'NO',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'tanggalPengalihan',
                caption: 'Tanggal Pengalihan',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'lastMpPenerimaTypeId',
                caption: 'Tipe Penerima Sebelumnya',
                lookup: {
                    dataSource: mpPenerimaType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'penerimaName'
                }
            },
            {
                dataField: 'lastMpPaymentTypeId',
                caption: 'Tipe Pembayaran Sebelum',
                lookup: {
                    dataSource: tipePembayaranMp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName'
                }
            },
            {
                dataField: 'lastPenerimaName',
                caption: 'Nama Penerima Sebelumnya'
            },
            {
                dataField: 'newMpPenerimaTypeId',
                caption: 'Tipe Penerima Baru',
                lookup: {
                    dataSource: mpPenerimaType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'penerimaName'
                }
            },
            {
                dataField: 'newMpPaymentTypeId',
                caption: 'Tipe Pembayaran Baru',
                lookup: {
                    dataSource: tipePembayaranMp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName'
                }
            },
            {
                dataField: 'newPenerimaName',
                caption: 'Nama Penerima Baru'
            },
            {
                dataField: 'keterangan',
                caption: 'Alasan Pengalihan'
            },
        ]
    }

    renderButton = (button) => {
        return <div style={{ padding: 20 }}><p>{button.text}</p></div>;
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalEdit = async(dataPengalihan) => {
        let periodeTahun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'pembayaran-mp/periodeTahun', 'GET')
        this.modalEditRef.current.show(periodeTahun)
        this.modalEditRef.current.retrieveData(dataPengalihan)
    }
    showModalInsert = async() => {
        let periodeTahun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'pembayaran-mp/periodeTahun', 'GET')
        this.modalAddRef.current.show(periodeTahun)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Pengalihan',
                    onClick: (e) => {
                        this.showModalInsert(e)
                    }
                }
            }
        )
    }
    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
            }
        ]
        return dummyData
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    batal = async (pengalihanId) => {
        try {
            var deleteAPI = `pengalihan-mps`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, deleteAPI, 'DELETE', pengalihanId)

            var result = await alert('Data Berhasil Dibatalkan !', 'Success')
            if (result) {
                this.forceRefresh()
            }
        } catch (e) {
            var result = await alert(e, 'Error')
        }
    }
    kirim = async (data) => {
        this.props.store.dispatch(showLoading(true))
        var dataPengalihan = data

        try {
            let kirimPengalihan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-pensiuns/kirimPengalihanMp/${dataPengalihan.id}`, 'POST', { values: {} })

            if (kirimPengalihan) {
                kirimPengalihan = kirimPengalihan[0]

                var loadAPIPengalihan = 'pengalihan-mps'
                var getPengalihan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPengalihan + '/' + data.id, 'GET')

                var dataPengalihan = getPengalihan
                dataPengalihan.status = 3

                var loadAPIAkun = 'kepersertaan-products'
                var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun + '/' + dataPengalihan.kepersertaanProductId, 'GET')

                var dataAkun = getAkun
                dataAkun.mpBulananPeserta = pembulatan(dataPengalihan.newMpBulananPeserta,2) || 0
                dataAkun.mpBulananAnak = pembulatan(dataPengalihan.newMpBulananAnak,2) || 0
                dataAkun.mpBulananJadud = pembulatan(dataPengalihan.newMpBulananJadud,2) || 0
        
                dataAkun.mpSekaligusPeserta = pembulatan(dataPengalihan.newMpSekaligusPeserta, 2) || 0
                dataAkun.mpSekaligusAnak = pembulatan(dataPengalihan.newMpSekaligusAnak, 2) || 0
                dataAkun.mpSekaligusJadud = pembulatan(dataPengalihan.newMpSekaligusJadud, 2) || 0
        
                if(dataPengalihan.newMpPaymentTypeId === 1){
                    dataAkun.mpBulanan = pembulatan(dataPengalihan.newMpBulanan * 80 / 100, 2) || 0
                }else{
                    dataAkun.mpBulanan = pembulatan(dataPengalihan.newMpBulanan, 2) || 0
                }
                dataAkun.mpSekaligus = pembulatan(dataPengalihan.newMpSekaligus, 2) || 0
                dataAkun.mpBulanan80 = pembulatan(dataPengalihan.newMpBulanan80, 2) || 0
                dataAkun.mpSekaligus20 = pembulatan(dataPengalihan.newMpSekaligus20, 2) || 0

                if(dataAkun.mpPaymentTypeId == 2){
                    var loadAPIMpPembayaran = 'pembayaran-mp-sekaligus'
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMpPembayaran, 'POST', {
                        values: {
                            akunProductId: dataAkun.id,
                            mpPaymentTypeId: dataAkun.mpPaymentTypeId,
                          }
                    })
                }
                if(dataAkun.mpPaymentTypeId == 1){
                    var loadAPIMpPembayaran = 'pembayaran-mp-sekaligus-sebagian'
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMpPembayaran, 'POST', {
                        values: {
                            akunProductId: dataAkun.id,
                            mpPaymentTypeId: dataAkun.mpPaymentTypeId,
                          }
                    })
                }

                this.props.store.dispatch(showLoading(false))
                notify({ message: kirimPengalihan.status_description, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()

            }

            return kirimPengalihan
        } catch (e) {
            console.log(e)
            this.props.store.dispatch(showLoading(false))
        }
    }
    updateData = async (data) => {
        var dataPengalihan = data
        delete dataPengalihan.id
        // EXECUTE
        try {
            var dataSubmit = {
                alasanPengalihan: data.keterangan ? data.keterangan : "",
                kepersertaanProductId: data.kepesertaanProductId ? data.kepesertaanProductId : 0,
                lastBank: data.lastMpRekeningBankId ? data.lastMpRekeningBankId : 0,
                lastFamilyId: data.lastFamilyId ? data.lastFamilyId : 0,
                lastMpBulanan: data.lastMpBulanan ? data.lastMpBulanan : 0,
                lastMpBulanan80: data.lastMpBulanan80 ? data.lastMpBulanan80 : 0,
                lastMpBulanan80Anak: data.lastMpBulananAnak80 ? data.lastMpBulananAnak80 : 0,
                lastMpBulanan80AnakBulat: data.lastMpBulananAnak80Round ? data.lastMpBulananAnak80Round : 0,
                lastMpBulanan80Bulat: data.lastMpBulanan80Round ? data.lastMpBulanan80Round : 0,
                lastMpBulanan80Jadud: data.lastMpBulananJadud80 ? data.lastMpBulananJadud80 : 0,
                lastMpBulanan80JadudBulat: data.lastMpBulananJadud80Round ? data.lastMpBulananJadud80Round : 0,
                lastMpBulananAnak: data.lastMpBulananAnak ? data.lastMpBulananAnak : 0,
                lastMpBulananBulat: data.lastMpBulananRound ? data.lastMpBulananRound : 0,
                lastMpBulananJadud: data.lastMpBulananJadud ? data.lastMpBulananJadud : 0,
                lastMpSekaligus: data.lastMpSekaligus ? data.lastMpSekaligus : 0,
                lastMpSekaligus20: data.lastMpSekaligus20 ? data.lastMpSekaligus20 : 0,
                lastMpSekaligus20Anak: data.lastMpSekaligusAnak20 ? data.lastMpSekaligusAnak20 : 0,
                lastMpSekaligus20AnakBulat: data.lastMpSekaligusAnak20Round ? data.lastMpSekaligusAnak20Round : 0,
                lastMpSekaligus20Bulat: data.lastMpSekaligus20Round ? data.lastMpSekaligus20Round : 0,
                lastMpSekaligus20Jadud: data.lastMpSekaligusJadud20 ? data.lastMpSekaligusJadud20 : 0,
                lastMpSekaligus20JadudBulat: data.lastMpSekaligusJadud20Round ? data.lastMpSekaligusJadud20Round : 0,
                lastMpSekaligusAnak: data.lastMpSekaligusAnak ? data.lastMpSekaligusAnak : 0,
                lastMpSekaligusBulat: data.lastMpSekaligusRound ? data.lastMpSekaligusRound : 0,
                lastMpSekaligusJadud: data.lastMpSekaligusJadud ? data.lastMpSekaligusJadud : 0,
                lastRekeningCabang: data.lastMpRekeningCabang ? data.lastMpRekeningCabang : "",
                lastRekeningName: data.lastMpRekeningName ? data.lastMpRekeningName : "",
                lastRekeningNo: data.lastMpRekeningNo ? data.lastMpRekeningNo : 0,
                lastRekeningPembayaranMp: data.lastPaymentRekbankId ? data.lastPaymentRekbankId : 0,
                lastTipePembayaran: data.lastMpPaymentTypeId ? data.lastMpPaymentTypeId : 0,
                lastTipePenerima: data.lastMpPenerimaTypeId ? data.lastMpPenerimaTypeId : 0,
                newBank: data.newMpRekeningBankId ? data.newMpRekeningBankId : 0,
                newFamilyId: data.newFamilyId ? data.newFamilyId : 0,
                newMpBulanan: data.newMpBulanan ? data.newMpBulanan : 0,
                newMpBulanan80: data.newMpBulanan80 ? data.newMpBulanan80 : 0,
                newMpBulanan80Anak: data.newMpBulananAnak80 ? data.newMpBulananAnak80 : 0,
                newMpBulanan80AnakBulat: data.newMpBulananAnak80Round ? data.newMpBulananAnak80Round : 0,
                newMpBulanan80Bulat: data.newMpBulanan80Round ? data.newMpBulanan80Round : 0,
                newMpBulanan80Jadud: data.newMpBulananJadud80 ? data.newMpBulananJadud80 : 0,
                newMpBulanan80JadudBulat: data.newMpBulananJadud80Round ? data.newMpBulananJadud80Round : 0,
                newMpBulananAnak: data.newMpBulananAnak ? data.newMpBulananAnak : 0,
                newMpBulananBulat: data.newMpBulananRound ? data.newMpBulananRound : 0,
                newMpBulananJadud: data.newMpBulananJadud ? data.newMpBulananJadud : 0,
                newMpSekaligus: data.newMpSekaligus ? data.newMpSekaligus : 0,
                newMpSekaligus20: data.newMpSekaligus20 ? data.newMpSekaligus20 : 0,
                newMpSekaligus20Anak: data.newMpSekaligusAnak20 ? data.newMpSekaligusAnak20 : 0,
                newMpSekaligus20AnakBulat: data.newMpSekaligusAnak20Round ? data.newMpSekaligusAnak20Round : 0,
                newMpSekaligus20Bulat: data.newMpSekaligus20Round ? data.newMpSekaligus20Round : 0,
                newMpSekaligus20Jadud: data.newMpSekaligusJadud20 ? data.newMpSekaligusJadud20 : 0,
                newMpSekaligus20JadudBulat: data.newMpSekaligusJadud20Round ? data.newMpSekaligusJadud20Round : 0,
                newMpSekaligusAnak: data.newMpSekaligusAnak ? data.newMpSekaligusAnak : 0,
                newMpSekaligusBulat: data.newMpSekaligusRound ? data.newMpSekaligusRound : 0,
                newMpSekaligusJadud: data.newMpSekaligusJadud ? data.newMpSekaligusJadud : 0,
                newRekeningCabang: data.newMpRekeningCabang ? data.newMpRekeningCabang : "",
                newRekeningName: data.newMpRekeningName ? data.newMpRekeningName : "",
                newRekeningNo: data.newMpRekeningNo ? data.newMpRekeningNo : 0,
                newRekeningPembayaranMp: data.newPaymentRekbankId ? data.newPaymentRekbankId : 0,
                newTipePembayaran: data.newMpPaymentTypeId ? data.newMpPaymentTypeId : 0,
                newTipePenerima: data.newMpPenerimaTypeId ? data.newMpPenerimaTypeId : 0,
                nik: data.nik ? data.nik : "",
                noSkPengalihan: data.noSkPengalihan ? data.noSkPengalihan : "",
                periode: data.periode ? data.periode : "",
                productId: data.productId ? data.productId : 0,
                tanggalMeninggal: data.tanggalMeninggal ? formatDate(data.tanggalMeninggal) : "",
                tanggalNilaiMpBaru: data.tanggalNilaiMpBaru ? formatDate(data.tanggalNilaiMpBaru) : "",
                tanggalPengalihan: data.tanggalPengalihan ? formatDate(data.tanggalPengalihan) : "",
                tanggalSkPengalihan: data.tanggalSkPengalihan ? formatDate(data.tanggalSkPengalihan) : formatDate(new Date()),
                user: this.props.store.getState().sessionUser
            }

            var postAPIPengalihanMP = 'peserta-pensiuns/simpanPengalihan'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPIPengalihanMP, 'POST', {
                values: dataSubmit
            })

            var result = await alert('Data Berhasil Diubah !', 'Success')
            if (result) {
                this.forceRefresh()
            }
        } catch (e) {
            await alert(e, 'Error')
        }
    }

    submitData = async (data) => {
        var dataPengalihan = data
        // dataPengalihan.status = 1
        delete dataPengalihan.id

        // EXECUTE
        try {
            var dataSubmit = {
                alasanPengalihan: data.keterangan ? data.keterangan : "",
                kepersertaanProductId: data.kepesertaanProductId ? data.kepesertaanProductId : 0,
                lastBank: data.lastMpRekeningBankId ? data.lastMpRekeningBankId : 0,
                lastFamilyId: data.lastFamilyId ? data.lastFamilyId : 0,
                lastMpBulanan: data.lastMpBulanan ? data.lastMpBulanan : 0,
                lastMpBulanan80: data.lastMpBulanan80 ? data.lastMpBulanan80 : 0,
                lastMpBulanan80Anak: data.lastMpBulananAnak80 ? data.lastMpBulananAnak80 : 0,
                lastMpBulanan80AnakBulat: data.lastMpBulananAnak80Round ? data.lastMpBulananAnak80Round : 0,
                lastMpBulanan80Bulat: data.lastMpBulanan80Round ? data.lastMpBulanan80Round : 0,
                lastMpBulanan80Jadud: data.lastMpBulananJadud80 ? data.lastMpBulananJadud80 : 0,
                lastMpBulanan80JadudBulat: data.lastMpBulananJadud80Round ? data.lastMpBulananJadud80Round : 0,
                lastMpBulananAnak: data.lastMpBulananAnak ? data.lastMpBulananAnak : 0,
                lastMpBulananBulat: data.lastMpBulananRound ? data.lastMpBulananRound : 0,
                lastMpBulananJadud: data.lastMpBulananJadud ? data.lastMpBulananJadud : 0,
                lastMpSekaligus: data.lastMpSekaligus ? data.lastMpSekaligus : 0,
                lastMpSekaligus20: data.lastMpSekaligus20 ? data.lastMpSekaligus20 : 0,
                lastMpSekaligus20Anak: data.lastMpSekaligusAnak20 ? data.lastMpSekaligusAnak20 : 0,
                lastMpSekaligus20AnakBulat: data.lastMpSekaligusAnak20Round ? data.lastMpSekaligusAnak20Round : 0,
                lastMpSekaligus20Bulat: data.lastMpSekaligus20Round ? data.lastMpSekaligus20Round : 0,
                lastMpSekaligus20Jadud: data.lastMpSekaligusJadud20 ? data.lastMpSekaligusJadud20 : 0,
                lastMpSekaligus20JadudBulat: data.lastMpSekaligusJadud20Round ? data.lastMpSekaligusJadud20Round : 0,
                lastMpSekaligusAnak: data.lastMpSekaligusAnak ? data.lastMpSekaligusAnak : 0,
                lastMpSekaligusBulat: data.lastMpSekaligusRound ? data.lastMpSekaligusRound : 0,
                lastMpSekaligusJadud: data.lastMpSekaligusJadud ? data.lastMpSekaligusJadud : 0,
                lastRekeningCabang: data.lastMpRekeningCabang ? data.lastMpRekeningCabang : "",
                lastRekeningName: data.lastMpRekeningName ? data.lastMpRekeningName : "",
                lastRekeningNo: data.lastMpRekeningNo ? data.lastMpRekeningNo : 0,
                lastRekeningPembayaranMp: data.lastPaymentRekbankId ? data.lastPaymentRekbankId : 0,
                lastTipePembayaran: data.lastMpPaymentTypeId ? data.lastMpPaymentTypeId : 0,
                lastTipePenerima: data.lastMpPenerimaTypeId ? data.lastMpPenerimaTypeId : 0,
                newBank: data.newMpRekeningBankId ? data.newMpRekeningBankId : 0,
                newFamilyId: data.newFamilyId ? data.newFamilyId : 0,
                newMpBulanan: data.newMpBulanan ? data.newMpBulanan : 0,
                newMpBulanan80: data.newMpBulanan80 ? data.newMpBulanan80 : 0,
                newMpBulanan80Anak: data.newMpBulananAnak80 ? data.newMpBulananAnak80 : 0,
                newMpBulanan80AnakBulat: data.newMpBulananAnak80Round ? data.newMpBulananAnak80Round : 0,
                newMpBulanan80Bulat: data.newMpBulanan80Round ? data.newMpBulanan80Round : 0,
                newMpBulanan80Jadud: data.newMpBulananJadud80 ? data.newMpBulananJadud80 : 0,
                newMpBulanan80JadudBulat: data.newMpBulananJadud80Round ? data.newMpBulananJadud80Round : 0,
                newMpBulananAnak: data.newMpBulananAnak ? data.newMpBulananAnak : 0,
                newMpBulananBulat: data.newMpBulananRound ? data.newMpBulananRound : 0,
                newMpBulananJadud: data.newMpBulananJadud ? data.newMpBulananJadud : 0,
                newMpSekaligus: data.newMpSekaligus ? data.newMpSekaligus : 0,
                newMpSekaligus20: data.newMpSekaligus20 ? data.newMpSekaligus20 : 0,
                newMpSekaligus20Anak: data.newMpSekaligusAnak20 ? data.newMpSekaligusAnak20 : 0,
                newMpSekaligus20AnakBulat: data.newMpSekaligusAnak20Round ? data.newMpSekaligusAnak20Round : 0,
                newMpSekaligus20Bulat: data.newMpSekaligus20Round ? data.newMpSekaligus20Round : 0,
                newMpSekaligus20Jadud: data.newMpSekaligusJadud20 ? data.newMpSekaligusJadud20 : 0,
                newMpSekaligus20JadudBulat: data.newMpSekaligusJadud20Round ? data.newMpSekaligusJadud20Round : 0,
                newMpSekaligusAnak: data.newMpSekaligusAnak ? data.newMpSekaligusAnak : 0,
                newMpSekaligusBulat: data.newMpSekaligusRound ? data.newMpSekaligusRound : 0,
                newMpSekaligusJadud: data.newMpSekaligusJadud ? data.newMpSekaligusJadud : 0,
                newRekeningCabang: data.newMpRekeningCabang ? data.newMpRekeningCabang : "",
                newRekeningName: data.newMpRekeningName ? data.newMpRekeningName : "",
                newRekeningNo: data.newMpRekeningNo ? data.newMpRekeningNo : 0,
                newRekeningPembayaranMp: data.newPaymentRekbankId ? data.newPaymentRekbankId : 0,
                newTipePembayaran: data.newMpPaymentTypeId ? data.newMpPaymentTypeId : 0,
                newTipePenerima: data.newMpPenerimaTypeId ? data.newMpPenerimaTypeId : 0,
                nik: data.nik ? data.nik : "",
                noSkPengalihan: data.noSkPengalihan ? data.noSkPengalihan : "",
                periode: data.periode ? data.periode : "",
                productId: data.productId ? data.productId : 0,
                tanggalMeninggal: data.tanggalMeninggal ? formatDate(data.tanggalMeninggal) : "",
                tanggalNilaiMpBaru: data.tanggalNilaiMpBaru ? formatDate(data.tanggalNilaiMpBaru) : "",
                tanggalPengalihan: data.tanggalPengalihan ? formatDate(data.tanggalPengalihan) : "",
                tanggalSkPengalihan: data.tanggalSkPengalihan ? formatDate(data.tanggalSkPengalihan) : formatDate(new Date()),
                user: this.props.store.getState().sessionUser
            }

            var postAPIPengalihanMP = 'peserta-pensiuns/simpanPengalihan'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPIPengalihanMP, 'POST', {
                values: dataSubmit
            })

            var text = 'Data berhasil disimpan'
            var type = 'success'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
            this.forceRefresh()
        } catch (e) {
            await alert(e, 'Error')
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Registrasi Pengalihan MP</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='view-pengalihan-mps?size=9999&status.in=1'
                        insertAPI='pengalihan-mps'
                        updateAPI='pengalihan-mps'
                        deleteAPI='pengalihan-mps'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        // remoteOperations = {true}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ModalRegistrasiUpdatePengalihanMp
                    ref={this.modalAddRef}
                    actionType={'add'}
                    submitData={this.submitData}
                    updateData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />

                <ModalRegistrasiUpdatePengalihanMp
                    ref={this.modalEditRef}
                    actionType={'edit'}
                    submitData={this.submitData}
                    updateData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default RegistrasiPengalihanMp
