import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import notify from 'devextreme/ui/notify'

import httpRequest from 'plugin/httprequest';
import FormDataPensiunDitunda from '../comp/formDataPensiun';
import { DropDownBox, ScrollView } from 'devextreme-react';
import { pesertaById } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class ModalInsertPengajuanPesertaDitunda extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            pesertaId: '',
            dataMaster: {},
            actionType: 'add'
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.formDataPensiunRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: () => {
                        this.hide()
                        this.removeState()
                    }
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Pensiun'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                disabled: true,
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'NIK Harus Diisi'
                    },
                    {
                        type: "pattern",
                        pattern: /\(?([0-9])\)?/,
                        message: "NIK harus menggunakan angka"
                    },
                    // {
                    //     type: 'stringLength',
                    //     max: 16,
                    //     min : 16
                    // }
                ],
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Nama Lengkap Harus Diisi'
                    },
                ],
            },
        ]
        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

        this.pesertaId = 0
    }
    get Popup() {
        return this.popupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value
        this.pesertaId = fieldValue[0] || fieldValue
        // this.setState({
        //     [fieldName]: fieldValue
        // }) 
    }

    submitData = async () => {
        var dataAkun = this.formDataPensiunRef.current.passDataToParent()

        await this.submitDataPersonal(dataAkun[0].pesertaId)
        await this.submitDataAkun(dataAkun)

        notify({ message: 'Data Berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);

        this.props.forceRefresh()
        this.hide()
    }

    submitDataPersonal = async (pesertaId) => {
        var loadAPI = `pesertas/${pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        var dataPersonal = getPeserta
        dataPersonal.statusPeserta = 5
        if (dataPersonal) {
            try {
                var updateAPI = 'pesertas'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPersonal.id,
                    values: dataPersonal
                },
                    dataPersonal)
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    submitDataAkun = async (data) => {
        for (var value of data) {
            var dataAkun = value
            dataAkun.akunStatusId = 3
            if (dataAkun) {
                try {
                    var updateAPI = 'kepersertaan-products'
                    var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                        key: dataAkun.id,
                        values: dataAkun
                    },
                        dataAkun)

                } catch (e) {
                    console.log(e)
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (type, pesertaId = 0) => {
        var loadAPIPeserta = `pesertas/${pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta, 'GET')

        var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${pesertaId}`
        var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKepegawaian, 'GET')

        var loadAPIAkun = `kepersertaan-products/getAllSimpleProductByPesertaId/${pesertaId}`
        var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'GET')
        var peserta = getPeserta
        peserta.efektifDate = getKepegawaian.efektifDate

        this.dropDownBoxRef.current.instance.option('value', pesertaId)
        if (type !== 'add') {
            await this.formDataPensiunRef.current.reloadData(peserta, (getAkun || []))
            this.setState({
                pesertaId: getPeserta.id,
                actionType: type,
                dataMaster: getPeserta
            })
        } else {
            this.setState({
                pesertaId: 0,
                actionType: type,
                dataMaster: {}
            })
        }
    }

    removeState = () => {
        this.formDataPensiunRef.current.removeState()
    }

    onSelectionChanged = (e) => {
        this.dropDownBoxRef.current.instance.option('value', e.selectedRowKeys)
        this.retrieveData('edit', e.selectedRowKeys[0])
    }
    renderDatagrid = (e) => {
        return (
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI={'view-pesertas?statusPeserta.in=2&statusPeserta.in=3'}
                insertAPI='pengkinian-peserta-bo-aktifs'
                updateAPI='pengkinian-peserta-bo-aktifs'
                deleteAPI='pengkinian-peserta-bo-aktifs'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations={true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged={this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengajuan Peserta Pensiun Ditunda'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Peserta</label>
                                    <div className="col-sm-10">
                                        <DropDownBox
                                            ref={this.dropDownBoxRef}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr={(item) => {
                                                return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                            }}
                                            showClearButton={true}
                                            dataSource={pesertaById(this.props.store)}
                                            onValueChanged={this.onValueChanged}
                                            contentRender={this.renderDatagrid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                            <div id={`tabIndexModalRegistrasi${this.props.actionType}-0`} className={' mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(50vh)' }}>
                                <FormDataPensiunDitunda store={this.props.store} actionType={this.props.actionType} ref={this.formDataPensiunRef} />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalInsertPengajuanPesertaDitunda
