import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { months, product } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import { showLoading } from 'redux/actions/loading';

class ModalDaftarUlang extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popupVisible: false,
      dataMaster: {},
      // actionType: ''
    }

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)

    this.PopupRef = React.createRef()

    this.PopupToolbarItem = [
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Simpan',
          onClick: async () => await this.submitData(),
        },
        toolbar: 'bottom',
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Tutup',
          onClick: this.hide.bind(this)
        },
        toolbar: 'bottom'
      }
    ]

    this.dataMaster = [
      {
        dataField: 'productId',
        label: {
          text: 'Produk'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
          // dataSource: pesertaPasif(this.props.store),
          dataSource: product(this.props.store),
          valueExpr: 'id',
          displayExpr: 'productCode'
        },
      },
      {
        dataField: 'periodeBulan',
        label: {
          text: 'Bulan'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
          dataSource: months(),
          valueExpr: 'id',
          displayExpr: 'value'
        },
      },
      {
        dataField: 'periodeTahun',
        label: {
          text: 'Tahun'
        },
      },
      {
        dataField: 'pengkinianTanggal',
        label: {
          alignment: 'left',
          text: 'Tanggal Awal Daftar Ulang'
        },
        editorType: 'dxDateBox',
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        dataField: 'tanggalAkhir',
        label: {
          alignment: 'left',
          text: 'Tanggal Akhir Daftar Ulang'
        },
        editorType: 'dxDateBox',
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        dataField: 'noSurat',
        label: {
          text: 'Nomor Surat Pengantar'
        }
      },
      {
        dataField: 'tanggalSurat',
        label: {
          alignment: 'left',
          text: 'Tanggal Surat Pengantar'
        },
        editorType: 'dxDateBox',
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
    ]
  }

  get Popup() {
    return this.PopupRef.current.instance
  }

  show() {
    this.setState({
      popupVisible: true,
      selectedTabIndex: 0
    });
  }

  hide() {
    this.setState({
      popupVisible: false,
      Data: {},
      typeData: {}
    });
  }

  onValueChanged = (e) => {
    var fieldName = e.component._options._optionManager._options.name
    var fieldValue = e.value

    this.setState({
      [fieldName]: fieldValue
    })
  }

  submitData = async () => {
    var data = { ...this.state.dataMaster }

    try{
      this.props.store.dispatch(showLoading(true))
      var updateAPI = 'daftar-ulangs/pesertaPasif'                    
      var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'POST', {
        values:data
      })

      if(response){
        var interval = setInterval(async() => { 
            try {
                var loadAPI = `daftar-ulangs/${response.id}`
                var getDaftarUlang = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')  
                if(getDaftarUlang.length !== 0){
                    clearInterval(interval)
                    this.props.store.dispatch(showLoading(false))
                    notify({ message: 'Berhasil menambah data daftar ulang!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                }
            } catch (e) {
                console.log(e)
            }

         }, 5000);
    }
    }catch(e){
      this.props.store.dispatch(showLoading(false))
      notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
    }
    this.props.forceRefresh()
    this.hide()
  }

  retrieveData = async (data, type) => {
    this.setState({
      dataMaster: data,
      actionType: type
    })
  }

  // onUploadStarted = (e, jenisData) => {
  //   var fieldName = e.component._options._optionManager._options.name
  //   for (var i = 0; i < e.value.length; i++) {
  //     var files = e.value[i]

  //     var fr = new FileReader()

  //     var formattedFileData
  //     var formattedFileNameAndExtention

  //     fr.onload = (efr) => {
  //       formattedFileData = formatUploadFileData(efr.target.result)
  //       formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
  //       this.setState(prevState => ({
  //         [jenisData]: {
  //           ...prevState[jenisData],
  //           [`${fieldName}Data`]: formattedFileData.base64data,
  //           [`${fieldName}DataContentType`]: formattedFileData.mime,
  //           [`${fieldName}Name`]: formattedFileNameAndExtention.name,
  //           webExtentionFile: formattedFileNameAndExtention.extention,
  //         }
  //       }))
  //     }

  //     fr.readAsDataURL(files)
  //   }
  // }

  render() {
    return (
      <Popup
        className={'popup'}
        visible={this.state.popupVisible}
        onHiding={this.hide}
        dragEnabled={true}
        resizeEnabled={true}
        showTitle={true}
        title={'FORM DAFTAR ULANG PESERTA PASIF'}
        width={1000}
        height={350}

        ref={this.PopupRef}

        toolbarItems={this.PopupToolbarItem}
      >
        <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
          <div className="row">
            <div className="col-md-12">
              <Form
                colCount={1}
                id={'formDataMaster'}
                formData={this.state.dataMaster}
                items={this.dataMaster}
                labelLocation="left"
                scrollingEnabled={true}
              />
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}


export default ModalDaftarUlang