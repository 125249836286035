import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import {alert,confirm} from "devextreme/ui/dialog";
import httpRequest from "plugin/httprequest";
import { reportHeaderDetailFilterHeader } from "dataSource/lookup";
import { showLoading } from "redux/actions/loading";

class ModalAddReportHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible:false,
      DataMaster:{}
    };

    this.formItem = [
      {
        dataField: 'reportType',
        label: {
          text: 'Jenis Laporan',
          alignment: "left",
          location: "left"
        },
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: [
                {
                    id: 'OJK',
                    value: 'Laporan OJK'
                },
                {
                    id: 'LK',
                    value: 'Laporan Keuangan'
                }
            ],
            valueExpr: 'id',
            displayExpr: 'value',
        }
      }, 
      {
          dataField: 'reportCode',
          label: {
              text: 'Kode Laporan',
              alignment: "left",
              location: "left"
          },
      }, 
      {
          dataField: 'reportName',
          label: {
              text: 'Nama Laporan',
              alignment: "left",
              location: "left"
          },
      }, 
    ]

    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Save",
          onClick: this.updateData.bind(this)
        },
        toolbar: "bottom"
      },
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Cancel",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.formRef = React.createRef()
    this.reportHeaderId = null
  }
  

  updateData = async () => {
    this.props.store.dispatch(showLoading(true))
    try{
        var dataInsert = {...this.state.DataMaster}
        const responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-headers`,'POST',{
        values: dataInsert
        })
  
        let type = 'success'
        let text = 'Data berhasil disimpan!'
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
    
        this.hide()
        this.props.reloadHeaderLookup(dataInsert.reportType)

        this.props.store.dispatch(showLoading(false))
    }catch(e){
        let type = 'error'
        let text = 'Data gagal disimpan!'
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
        console.log(e)
        this.props.store.dispatch(showLoading(true))
    }
  }

  show = () => {
    this.setState({
      popupVisible: true
    });
  };

  hide = () => {
    this.setState({
      popupVisible: false,
      DataMaster: {}
    });
    this.reportHeaderId = null
  };

  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={false}
          showTitle={true}
          title={"Tambah Laporan Baru"}
          width={"400px"}
          height={"200px"}
          toolbarItems={this.PopupToolbarItem}
        >
            <Form
                colCount={1}
                id={"formMaster"}
                formData={this.state.DataMaster}
                items={this.formItem}
                ref={this.formRef}
            />
        </Popup>
      </React.Fragment>

    );
  }
}

export default ModalAddReportHeader;
