import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import initSubscriber from 'redux-subscriber';
import ReactDOM from 'react-dom'


class Navbar extends Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.subscribe = initSubscriber(this.store)

        this.popupChangePassword = React.createRef();
        this.state = {
            menuType: null,
            isDasboard: false
        }

        this.currentUrl = []

        this.menuList = []
    }

    componentDidMount = () => {
        this.subscribe('menu', data => {
            this.checkMenu(data.menu)
        });

    }

    setSideMenu = (data) => {
        this.menuList = data

        this.checkMenu(this.store.getState().menu)
    }

    generateMenuCSV = () => {
        var csvText = []
        var row = 0
        for(var rootMenu of this.state.menuList){
            var text = `${row},${rootMenu.menuName.replace(rootMenu.menuName[0], rootMenu.menuName[0].toUpperCase())},0,${rootMenu.menuName}`
            csvText.push(text)
            row++
            for(var menu of rootMenu.menuList){
                text = `${row},${menu.text},1,${menu.url}`
                csvText.push(text)
                row++
                if(menu.child){
                    for(var childMenu of menu.child){
                        text = `${row},${childMenu.text},2,${childMenu.url}`
                        csvText.push(text)
                        row++
                        if(childMenu.child){
                            for(var grandChild of childMenu.child){
                                text = `${row},${grandChild.text},3,${grandChild.url}`
                                csvText.push(text)
                                row++
                            }
                        }
                    }
                }
            }
        }
    }

    checkMenu = (menuType) => {
        var rootMenu = this.menuList.map(value => value.menuName)

        var appElems = document.getElementsByClassName('app')

        for(var appElem of appElems){
            if(menuType === 'dashboard'){
                appElem.classList.add('dashboard-grid')
            }else{
                appElem.classList.remove('dashboard-grid')

                this.setState({
                    menuType : menuType
                })
            }
        }
    }

    renderRootmenu = () => {
        var HTMLElem = []
        
        const selectedMenu = this.menuList.find(value => value.menuName === this.state.menuType)

        if(selectedMenu){
            return <div className="list-group list-group-flush pt-3" id="side-menu">
                {this.renderSubmenu(selectedMenu.menuList)}
            </div>
        }
    }

    renderSubmenu = (menuList) => {
        var HTMLElem = []

        menuList = menuList.sort((a,b) => {
            return a.text.localeCompare(b.text, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
        })

        const set = new Set()
        const removeDuplicateMenuParent = menuList.filter(menu => {
            const duplicate = set.has(menu.menuCode)
            set.add(menu.menuCode)
            return !duplicate
        })

        for (var menu of removeDuplicateMenuParent) {
            if (menu.child) {
                var child = menu.child.sort((a,b) => {
                    return a.text.localeCompare(b.text, undefined, {
                        numeric: true,
                        sensitivity: 'base'
                    });
                })

                const ParentMenu = <a key={menu.menuCode} className={`list-group-item list-group-item-action py-1`} href="#" target-child={`#${menu.url}`} target={`#${menu.url}icon`} onClick={(e) => { this.toggleSubMenu(e) }} >
                    <span>{menu.text}</span>
                    <i className={`fa fa-menu-dapen float-right ${this.currentUrl[1] === menu.url ? "fa-chevron-down" : 'fa-chevron-right'}`} id={`${menu.url}icon`}></i>
                </a>
                HTMLElem.push(
                    ParentMenu
                )

                var childElem = []
                const set = new Set()
                const removeDuplicateMenuChild = child.filter(menu => {
                    const duplicate = set.has(menu.menuCode)
                    set.add(menu.menuCode)
                    return !duplicate
                })
                for (var menuChild of removeDuplicateMenuChild) {
                    if (menuChild.child) {
                        var grandChild = menuChild.child.sort((a,b) => {
                            return a.text.localeCompare(b.text, undefined, {
                                numeric: true,
                                sensitivity: 'base'
                              });
                        })

                        const ChildMenu = <a key={menuChild.menuCode} className="list-group-item list-group-item-action py-1" href="#" target-child={`#${menuChild.url}`} target={`#${menuChild.url}icon`} onClick={(e) => { this.toggleSubMenu(e) }} >
                            <span>{menuChild.text}</span>
                            <i className={`fa fa-menu-dapen float-right ${this.currentUrl[2] === menuChild.url && this.currentUrl[1] === menu.url ? "fa-chevron-down" : 'fa-chevron-right'}`}></i>
                        </a>
                        childElem.push(
                            ChildMenu
                        )

                        var grandChileElem = []
                        const set = new Set()
                        const removeDuplicateMenuGrandChild = grandChild.filter(menu => {
                            const duplicate = set.has(menu.menuCode)
                            set.add(menu.menuCode)
                            return !duplicate
                        })
                        for (var menuGrandChild of removeDuplicateMenuGrandChild) {
                            grandChileElem.push(
                                <NavLink key={menuGrandChild.menuCode} exact to={menuGrandChild.url || '#'} className={`list-group-item sub-item py-1 ${menuGrandChild.url ? '' : 'text-muted'}`} >
                                    <span>
                                        {menuGrandChild.text}
                                    </span>
                                </NavLink>
                            )
                        }
                        childElem.push(
                            <div key={`${menuChild.menuCode}Container`} className={`collapse list-group-submenu ${this.currentUrl[2] === menuChild.url && this.currentUrl[1] === menu.url ? "show" : ''}`} id={menuChild.url}>
                                {grandChileElem}
                            </div>
                        )
                    } else {
                        childElem.push(
                            <NavLink key={menuChild.menuCode} exact to={menuChild.url || '#'} className={`list-group-item sub-item py-1 ${menuChild.url ? '' : 'text-muted'}`} >
                                <span>
                                    {menuChild.text}
                                </span>
                            </NavLink>
                        )
                    }
                }
                HTMLElem.push(
                    <div key={`${menu.menuCode}Container`} className={`collapse list-group-submenu ${this.currentUrl[1] === menu.url ? "show" : ''}`} id={menu.url}>
                        {childElem}
                    </div>
                )
            } else {
                HTMLElem.push(
                    <NavLink key={menu.menuCode} className={`list-group-item py-1 ${menu.url ? '' : 'text-muted'}`} exact to={menu.url || '#'}>
                        <span>
                            {menu.text}
                        </span>
                    </NavLink>
                )
            }
        }

        return (HTMLElem)
    }

    toggleSubMenu = (e) => {
        e.preventDefault()
        var targetElem = e.target

        var subMenuId = targetElem.getAttribute('target-child')
        var iconMenu = targetElem.lastChild

        if (targetElem.tagName.toLowerCase() !== 'a') {
            subMenuId = targetElem.parentNode.getAttribute('target-child')
            iconMenu = targetElem.parentNode.lastChild
        }

        var element = document.querySelector(subMenuId)

        if (element.classList.contains('show')) {
            element.classList.remove('show')
        } else {
            element.classList.add('show')
        }

        if (iconMenu.classList.contains('fa-chevron-right')) {
            iconMenu.classList.remove('fa-chevron-right')
            iconMenu.classList.add('fa-chevron-down')
        } else if (iconMenu.classList.contains('fa-chevron-down')) {
            iconMenu.classList.remove('fa-chevron-down')
            iconMenu.classList.add('fa-chevron-right')
        }
    }

    render() {
        this.currentUrl = window.location.pathname.substr(1).split('/')

        return (
            <React.Fragment>
                <div className={`menu-side`} id="navbar">
                    <div className="text-center my-4">
                        <img src="/assets/img/logo-landscape.png" alt="App Icon" width="100px" />
                    </div>
                    <div className="bg-white border-right" id="sidebar-wrapper" style={{ height: 'calc(100vh - 205px)', overflowY: 'auto' }}>
                        {this.renderRootmenu()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Navbar;
