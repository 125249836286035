import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, banks, karyawan, months, product, productDefault, programs } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, formatTimeZero } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'

class LaporanDaftarAbsensi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.dgRef = React.createRef()

        this.state = {
            filterData: {
                programId: 1,
                startDate: new Date(this.sysDate.dateValue),
                endDate: new Date(this.sysDate.dateValue),
                karyawanId: 1
            }
        }

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'posisi'
            },
            {
                name: 'jamKerja',
                showInColumn: 'jamKerja',
                summaryType: 'custom',
                valueFormat: '#,##0',
                displayFormat: '{0}',
            },
            {
                name: 'totalTerlambat',
                showInColumn: 'totalTerlambat',
                summaryType: 'custom',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'totalPulangCepat',
                showInColumn: 'totalPulangCepat',
                summaryType: 'custom',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'jumlahHari',
                column: 'jumlahHari',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                dataField: 'tanggal',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'hari',
                caption: 'Hari',
            },
            {
                dataField: 'nik',
                caption: 'NIP',
            },
            {
                dataField: 'namaKaryawan',
                caption: 'Nama Karyawan',
            },
            {
                dataField: 'departement',
                caption: 'Unit Kerja',
            },
            {
                dataField: 'posisi',
                caption: 'Jabatan',
            },
            {
                dataField: 'batasToleransi',
                caption: 'Batas Toleransi Masuk',
                dataType: 'date',
                format: 'HH:mm',
                alignment: 'center'
            },
            {
                dataField: 'jamPulangNormal',
                caption: 'Jam Pulang Normal',
                dataType: 'date',
                format: 'HH:mm',
                alignment: 'center'
            },
            {
                dataField: 'jamMasuk',
                caption: 'Jam Masuk',
                dataType: 'date',
                format: 'HH:mm',
                alignment: 'center'
            },
            {
                dataField: 'jamPulang',
                caption: 'Jam Pulang',
                dataType: 'date',
                format: 'HH:mm',
                alignment: 'center'
            },
            {
                dataField: 'jamKerja',
                caption: 'Total Jam Kerja',
                dataType: 'date',
                format: 'HH:mm',
                alignment: 'center'
            },
            {
                dataField: 'totalTerlambat',
                caption: 'Total Telat',
                alignment: 'center'
            },
            {
                dataField: 'totalPulangCepat',
                caption: 'Total Pulang Cepat',
                alignment: 'center'
            },
            {
                dataField: 'jumlahHari',
                caption: 'Total Hari Masuk',
                alignment: 'center'
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'programId',
                                label: {
                                    text: 'Program',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: programs(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.program
                                    },
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'startDate',
                                label: {
                                    text: 'Tanggal',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'endDate',
                                label: {
                                    text: 's/d',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'karyawanId',
                                label: {
                                    text: 'Karyawan',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: karyawan(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.fullName
                                    },
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        itemType: 'group',
                        items: [
                            {
                                itemType: "button",
                                buttonOptions: {
                                    text: "Filter",
                                    // type:"default",
                                    elementAttr: { class: "bg-dapen-default" },
                                    onClick: () => {
                                        this.filterData()
                                    },
                                },
                                horizontalAlignment: "left"
                            },
                        ]
                    },
                ]
            },
        ]

        this.result = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    convertMinute = (time) => {
        const jam = Math.floor(time / 60)
        const sisaMenit = time % 60

        return {jam, sisaMenit}
    }

    customCalculate = (options) => {
        if (options.name === 'jamKerja') {
            this.processTime(options, 'jamKerja');
        } else if (options.name === 'totalTerlambat') {
            this.processTime(options, 'totalTerlambat');
        } else if (options.name === 'totalPulangCepat') {
            this.processTime(options, 'totalPulangCepat');
        }
    }

    processTime = (options, key) => {
        if (options.summaryProcess === 'start') {
            options.totalValue = {};
        } else if (options.summaryProcess === 'calculate') {
            let time = options.value[key];
            let times = time !== null ? time.split(':') : null;
            if (times !== null) {
                options.totalValue = {
                    totalJamKerja: (options.totalValue.totalJamKerja || 0) + parseInt(times[0]),
                    totalMenitKerja: (options.totalValue.totalMenitKerja || 0) + parseInt(times[1])
                };
            }
        } else if (options.summaryProcess === 'finalize') {
            let convertJam = this.convertMinute(options.totalValue.totalMenitKerja);
            if (options.totalValue.totalJamKerja === undefined && options.totalValue.totalMenitKerja === undefined) {
                options.totalValue = '00:00';
            } else {
                options.totalValue = 
                    (options.totalValue.totalJamKerja + convertJam.jam).toString().padStart(2, '0') + 
                    ':' + 
                    convertJam.sisaMenit.toString().padStart(2, '0');
            }
        }
    }

    filterData = async () => {
        let dataState, loadAPI, response
        dataState = this.state.filterData

        loadAPI = `karyawan-absens/absensi-periode-detil?endDate=${formatDate(dataState.endDate)}&karyawanId=${dataState.karyawanId}&programId=${dataState.programId}&startDate=${formatDate(dataState.startDate)}`
        response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        let dataResult = []
        for(var data of response){
            let result = {
                id : data.noUrut,
                nik: data.nik,
                noUrut: data.noUrut,
                namaKaryawan: data.namaKaryawan,
                karyawanId: data.karyawanId,
                departement: data.departement,
                posisi: data.posisi,
                hari: data.hari,
                tanggal: data.tanggal,
                batasToleransi: data.batasToleransi,
                jamPulangNormal: data.jamPulangNormal,
                jamMasuk: data.jamMasuk ? formatTimeZero(data.jamMasuk) : data.jamMasuk,
                jamPulang: data.jamPulang ? formatTimeZero(data.jamPulang) : data.jamPulang,
                jamKerja: data.jamKerja,
                jumlahHari: data.jumlahHari,
                totalTerlambat: data.totalTerlambat,
                totalPulangCepat: data.totalPulangCepat,
                keterangan: data.keterangan
            }

            dataResult.push(result)
        }

        this.result = dataResult
        this.dgRef.current.forceRefresh(true)
    }

    loadData = () => {
        return this.result
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Laporan Daftar Absensi</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI={`karyawan-absens/absensi-periode-detil?endDate=${formatDate(this.state.filterData.endDate)}&karyawanId=${this.state.filterData.karyawanId}&programId=${this.state.filterData.programId}&startDate=${formatDate(this.state.filterData.startDate)}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Laporan Daftar Absensi"}
                    allowExportSelectedData={true}
                    selection={true}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(-355px + 100vh)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    calculateCustomSummary={this.customCalculate}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanDaftarAbsensi