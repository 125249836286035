import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager, instrumentSubCategoryPenyertaan, instrumentPenyertaanLangsung } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import ArrayStore from 'devextreme/data/array_store';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";


class ModalPelepasanInstrumentPl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'noPengajuan',
                        label: {
                            text: 'No Pengajuan'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'kodeInstrument',
                        label: {
                            text: 'Kode Instrument',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'instrumentId',
                            displayExpr: (e) => {
                                return e && e.kodePenyertaan + ' - ' + e.namaPerusahaan
                            },
                            searchEnabled: true,
                            onSelectionChanged: async(e) => {
                                try {
                                    var slctd = e.selectedItem
                                
                                    let loadAPI = `instruments/${slctd.instrumentId}`;
                                    let repsonse = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
                                    
                                    if (slctd) {
                                        this.formMasterRef.current.instance.updateData({
                                            nilaiPerLembarSaham: repsonse.price,
                                            namaPerusahaan: slctd.namaPerusahaan,
                                            kodePenyertaan: slctd.kodePenyertaan
                                        })
                                    }
                                } catch (error) {
                                    console.log(error)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'namaPerusahaan',
                        label: {
                            text: 'Nama Perusahaan',
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'kodePenyertaan',
                        // label: {
                        //     // visible: false,
                        // },
                        editorOptions: {
                            readOnly:true
                        },
                        visible: false
                    },

                ]
            },
            {
                itemType: 'group',
                caption: 'PELEPASAN',
                items: [
                    {
                        dataField: 'jmlSahamPenyertaan',
                        label: {
                            text: 'Jumlah Lembar Saham',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nilaiPerLembarSaham = this.formMasterRef.current.instance.getEditor('nilaiPerLembarSaham').option('value')
                                this.formMasterRef.current.instance.updateData('nominalPenyertaan', nilaiPerLembarSaham * e.value)
                            }
                        }
                    },
                    {
                        dataField: 'nilaiPerLembarSaham',
                        label: {
                            text: 'Nilai Per Lembar Saham',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var jmlSahamPenyertaan = this.formMasterRef.current.instance.getEditor('jmlSahamPenyertaan').option('value')
                                this.formMasterRef.current.instance.updateData('nominalPenyertaan', jmlSahamPenyertaan * e.value)
                            }
                        }
                    },
                    {
                        dataField: 'nominalPenyertaan',
                        label: {
                            text: 'Nominal Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'biayaPenyertaan',
                        label: {
                            text: 'Biaya Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'rekBankId',
                        label: {
                            text: 'Rekening Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.rekNo + ' - ' + e.rekName
                            },
                            searchEnabled: true,
                        }
                    },
                ]
            }
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    show (){
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data) => {
        console.log(data);
        if(data){
            if (data.productId) {
                var rekData = await this.rekBankByProduct(this.props.store, data.productId);
                this.formMasterRef.current.instance.getEditor('rekBankId').option('dataSource', rekData)
                var instruData = await this.dataInstruProp(this.props.store, data.productId);
                this.formMasterRef.current.instance.getEditor('kodeInstrument').option('dataSource', instruData)
            }

            let getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments?instrumentCode.equals=${data.kodeInstrument}`, 'GET');
            console.log(getInstrument);
            this.setState({
                data: {
                    id: data.id,
                    biayaPenyertaan: data.biayaPenyertaan,
                    jmlSahamPenyertaan: data.jmlSahamPenyertaan,
                    // kodeInstrument: getInstrument[0].id,
                    kodeInstrument: getInstrument.length !== 0 ? getInstrument[0].id : data.kodeInstrument,
                    namaPerusahaan: data.namaPerusahaan,
                    nilaiPerLembarSaham: data.nilaiPerLembarSaham,
                    nominalPenyertaan: data.nominalPenyertaan,
                    noPengajuan: data.noPengajuan,
                    // orderRequestId: data.orderRequestId,
                    rekBankId: data.rekBankId,
                },
            });

        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
        this.props.forceRefresh()
    }
    rekBankByProduct = async (store, productId) => {
        let loadAPI = `rek-banks-by-productId?productId=${productId}`
        var getRekening = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRekening,
            key: 'id'
        });

        return dataSource
    }
    dataInstruProp = async (store, productId) => {
        // let loadAPI = `instruments/penyertaan-langsung`
        let loadAPI = `efek-balance-avgs/instrument-portofolio-penyertaanModal?productId=${productId}`
        var getInstru = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getInstru,
            key: 'instrumentId'
        });

        return dataSource
    }
    submitData = async () => {
        var data = this.state.data
        this.props.submitDetailPenempatanData({
            id: data.id,
            biayaPenyertaan: data.biayaPenyertaan,
            jmlSahamPenyertaan: data.jmlSahamPenyertaan,
            kodeInstrument: data.kodePenyertaan,
            namaPerusahaan: data.namaPerusahaan,
            nilaiPerLembarSaham: data.nilaiPerLembarSaham,
            nominalPenyertaan: data.nominalPenyertaan,
            // orderRequestId: data.orderRequestId,
            rekBankId: data.rekBankId,
        })

        this.hide()
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Pelepasan Penyertaan Langsung`}
                width={'70vw'}
                height="70vh"

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'form'}
                                formData={this.state.data}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={this.props.type == 'view' ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalPelepasanInstrumentPl