import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { paramType } from "dataSource/lookup";

class ModalGlobalParam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible:false,
      DataMaster:{}
    };

    this.columns = [
      {
        dataField: 'paramCode',
        label:{
          text:"Global Param Code",
          alignment:"left",
        },
        editorOptions:{
          readOnly:true
        }
      },
      {
        dataField: 'paramName',
        label:{
          text:"Global Param Name",
          alignment:"left",
        },
        editorOptions:{
          readOnly:true
        }
      },
      {
        dataField: 'paramType',
        label:{
          text:"Global Param Type",
          alignment:"left",
        },
        editorType: 'dxSelectBox',
        editorOptions:{
          dataSource: paramType(),
          valueExpr: 'id',
          displayExpr: 'value',
          onValueChanged : async (e) => {
            this.setField(e.value)
          }
        },
      },
      {
        dataField: 'stringValue',
        label:{
          text:"Value"
        },
        visible: false
      },
      {
        dataField: 'intValue',
        label:{
          text:"Value"
        },
        editorType: 'dxNumberBox',
        visible: false
      },
      {
        dataField: 'floatValue',
        label:{
          text:"Value"
        },
        editorType: 'dxNumberBox',
        visible: false
      },
      {
        dataField: 'dateValue',
        label:{
          text:"Value"
        },
        editorType: 'dxDateBox',
        editorOptions: {
          displayFormat: 'dd MMM yyyy'
        },
        visible: false
      }
    ];


    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: this.updateData.bind(this)
        },
        toolbar: "bottom"
      },
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Batal",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.formRef = React.createRef()
  }
  

  retriveDataGlobalParam = async (data) => {
    var paramType = data.paramType    

    this.setField(paramType)

    this.setState({
      DataMaster:data
    })
  }

  setField = (paramType) => {
    this.formRef.current.instance.beginUpdate()
    switch (paramType) {
      case 'S':
        this.formRef.current.instance.itemOption('stringValue','visible',true)
        this.formRef.current.instance.itemOption('intValue','visible',false)
        this.formRef.current.instance.itemOption('floatValue','visible',false)
        this.formRef.current.instance.itemOption('dateValue','visible',false)
        break;
      case 'I':
        this.formRef.current.instance.itemOption('stringValue','visible',false)
        this.formRef.current.instance.itemOption('intValue','visible',true)
        this.formRef.current.instance.itemOption('floatValue','visible',false)
        this.formRef.current.instance.itemOption('dateValue','visible',false)
        break;
      case 'F':
        this.formRef.current.instance.itemOption('stringValue','visible',false)
        this.formRef.current.instance.itemOption('intValue','visible',false)
        this.formRef.current.instance.itemOption('floatValue','visible',true)
        this.formRef.current.instance.itemOption('dateValue','visible',false)
        break;
      case 'D':
        this.formRef.current.instance.itemOption('stringValue','visible',false)
        this.formRef.current.instance.itemOption('intValue','visible',false)
        this.formRef.current.instance.itemOption('floatValue','visible',false)
        this.formRef.current.instance.itemOption('dateValue','visible',true)
        break;
    
      default:
        break;
    }

    this.formRef.current.instance.endUpdate()
  }

  updateData = async () => {
    let type = "success";
    let text = "Global param has been update successfully!";
    let loadAPI = `global-parameters`

    var response = await httpRequest(
      process.env.REACT_APP_BACKEND,
      this.props.store,
      loadAPI,
      "PUT",
      {
        values: this.state.DataMaster,
        key:this.state.DataMaster
      },this.state.DataMaster
    );
    
    if (response) {
      notify(
        {
          message: text,
          width: "AUTO",
          shading: true,
          position: { at: "center", my: "center", of: window }
        },
        type,
        600
        );
        
      
    }else {
      notify(
        {
          message: "Error",
          width: "AUTO",
          shading: true,
          position: { at: "center", my: "center", of: window }
        },
        "error",
        600
      );
    }

    this.props.forceRefresh()
      this.hide()
  }

  show = () => {
    this.setState({
      popupVisible: true
    });
  };

  hide = () => {
    this.setState({
      popupVisible: false,
    });
  };
  

  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={false}
          showTitle={true}
          title={"Global Param"}
          width={"50vw"}
          height={"50vh"}
          toolbarItems={this.PopupToolbarItem}
        >
          <Form
            colCount={2}
            id={"formMaster"}
            formData={this.state.DataMaster}
            items={this.columns}
            ref={this.formRef}
          />

        </Popup>
      </React.Fragment>

    );
  }
}

export default ModalGlobalParam;
