import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { banks, months, mpType, product, rekBank, returType, viewPembayaranMpDetilReturs } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import DropDownBox from 'devextreme-react/drop-down-box';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';

class ModalActionTransaksiMpRetur extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.pembayaranMpDetilId = 0
        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        let validation = this.validate();
                        if (validation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.action === 'lihat' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom',
                visible: this.props.action === 'lihat' ? false : true
            },
        ]
        this.dataMaster = [
            {
                itemType: 'group',
                caption: 'Retur',
                items: [
                    {
                        dataField: 'returNo',
                        label: {
                            alignment: 'left',
                            text: 'No Retur'
                        }
                    },
                    {
                        dataField: 'returDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Retur'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Tanggal retur harus diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'returRekbankId',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Retur'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'rekName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'returTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Retur'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: returType(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                ]
            },
            {
                itemType: 'empty'
            },
            {
                itemType: 'group',
                name: 'TransaksiRetur',
                caption: 'Transaksi Retur',
                items: [
                    {
                        dataField: 'paymentDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bulan',
                        label: {
                            alignment: 'left',
                            text: 'Bulan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: months(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'tahun',
                        label: {
                            alignment: 'left',
                            text: 'Tahun'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpType(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'mpName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'productCode', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nik',
                        label: {
                            alignment: 'left',
                            text: 'NIK'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'fullName',
                        label: {
                            alignment: 'left',
                            text: 'Nama Peserta'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpNominal',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Manfaat Pensiun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'mpRekbankId',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Pembayaran MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'rekName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'mpRekeningNo',
                        label: {
                            alignment: 'left',
                            text: 'No Rekening Penerima'
                        }
                    },
                    {
                        dataField: 'mpRekeningName',
                        label: {
                            alignment: 'left',
                            text: 'Nama Rekening Penerima'
                        }
                    },
                    {
                        dataField: 'mpRekeningBankId',
                        label: {
                            alignment: 'left',
                            text: 'Bank Penerima'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'initialName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                ]
            },
            {
                itemType: 'empty',
            },
            {
                itemType: 'group',
                name: 'penandatangan',
                caption: 'Penanda Tangan',
                items: [
                    {
                        dataField: 'pejabat',
                        label: {
                            alignment: 'left',
                            text: 'Pejabat'
                        }
                    },
                    {
                        dataField: 'jabatan',
                        label: {
                            alignment: 'left',
                            text: 'Jabatan'
                        }
                    },
                ]
            },

        ]

        this.columns = [
            {
                dataField: 'id',
                caption: 'ID',
            },
            {
                dataField: 'tanggalTransaksi',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'bulan',
                caption: 'Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
            },
            {
                dataField: 'tahun',
                caption: 'Tahun',
            },
            {
                dataField: 'mpTypeId',
                caption: 'Tipe MP',
                lookup: {
                    dataSource: mpType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'mpName',
                },
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                },
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'mpNominal',
                caption: 'Nominal MP',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'paymentRekbankId',
                caption: 'Rekening Pembayar',
                lookup: {
                    dataSource: rekBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'rekName',
                },
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    validate = () => {
        let formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid;
    }

    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
        this.dropDownBoxRef.current.instance.option('value', '')
    }

    submitData = async () => {
        var dataSubmit = { ...this.state.formData }
        dataSubmit.pembayaranMpDetilId = this.pembayaranMpDetilId
        dataSubmit.returNominal = dataSubmit.mpNominal
        dataSubmit.statusId = 1
        await this.props.submitData(dataSubmit)
        this.hide()
    }
    retrieveData = async (data) => {
        var loadAPIMaster = `pembayaran-mp-returs/${data.id}`
        var getMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')

        var loadAPIMaster = `pembayaran-mp-detils/${getMaster.pembayaranMpDetilId}`
        var getMpDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')
        console.log(getMaster)

        this.pembayaranMpDetilId = getMaster.pembayaranMpDetilId

        var loadAPIDetil = `view-pembayaran-detil-returs/${this.pembayaranMpDetilId}`
        var getDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil, 'GET')

        var datas = { ...getMaster }
        datas.paymentDate = getDetil.tanggalTransaksi
        datas.bulan = getDetil.bulan
        datas.tahun = getDetil.tahun
        datas.mpTypeId = getDetil.mpTypeId
        datas.productId = getDetil.productId
        datas.nik = getDetil.nik
        datas.fullName = getDetil.fullName
        datas.returNominal = getDetil.mpNominal
        datas.mpNominal = data.mpNominal
        datas.paymentRekbankId = getDetil.paymentRekbankId
        datas.mpRekeningNo = getMpDetil.mpRekeningNo
        datas.mpRekeningName = getMpDetil.mpRekeningName
        datas.mpRekeningBankId = getMpDetil.mpRekeningBankId

        this.dropDownBoxRef.current.instance.option('value', this.pembayaranMpDetilId)
        // this.formRef.current.instance.updateData(data)
        this.setState({
            formData: datas
        })
    }

    onValueChanged = (e) => {
        var fieldValue = e.value
        this.pembayaranMpDetilId = fieldValue[0] || fieldValue
        // this.setState({
        //     [fieldName]: fieldValue
        // })
    }

    onSelectionChanged = async (e) => {
        this.pembayaranMpDetilId = e.selectedRowKeys[0]
        this.dropDownBoxRef.current.instance.option('value', e.selectedRowKeys)

        var loadAPI = `view-pembayaran-detil-returs/${this.pembayaranMpDetilId}`
        var getDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        var data = { ...this.state.formData }
        data.paymentDate = getDetil.tanggalTransaksi
        data.bulan = getDetil.bulan
        data.tahun = getDetil.tahun
        data.mpTypeId = getDetil.mpTypeId
        data.productId = getDetil.productId
        data.nik = getDetil.nik
        data.fullName = getDetil.fullName
        data.returNominal = getDetil.mpNominal
        data.mpNominal = e.selectedRowsData[0].mpNominal
        data.paymentRekbankId = getDetil.paymentRekbankId

        this.formRef.current.instance.updateData(data)
    }

    renderDatagrid = (e) => {
        return (
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI={'view-pembayaran-detil-returs'}
                insertAPI='pembayaran-mp-detils'
                updateAPI='pembayaran-mp-detils'
                deleteAPI='pembayaran-mp-detils'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                // keyField = {'idPembayaranMpDetil'}
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pembayaran MP Detil"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations={true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pembayaran MP Detil'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged={this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={this.props.title}
                minWidth={500}
                minHeight={300}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">ID Transaksi</label>
                                    <div className="col-sm-10">
                                        <DropDownBox
                                            ref={this.dropDownBoxRef}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr={(item) => {
                                                return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                            }}
                                            showClearButton={true}
                                            dataSource={viewPembayaranMpDetilReturs(this.props.store)}
                                            onValueChanged={this.onValueChanged}
                                            contentRender={this.renderDatagrid}
                                            readOnly={this.props.action == 'lihat' ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataActionDataIuran'}
                                    formData={this.state.formData}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation='left'
                                    readOnly={this.props.action == 'lihat' ? true : false}
                                />
                            </div>
                        </div>

                    </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ModalActionTransaksiMpRetur