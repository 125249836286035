import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import ArrayStore from 'devextreme/data/array_store';

import { banks, instrumentSubCategoryDeposito, currency, efekBalanceAvg, rekeningBank } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import { formatDate } from 'plugin/helper';


class FormSubInstrumentPerpanjanganDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            valueData: {},
            dataDetail: {}
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'noPengajuan',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPerpanjangan',
                        label: {
                            text: 'Tanggal Perpanjangan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            onValueChanged: (e) => {
                                if (this.props.type === 'edit' || this.props.type === 'add') {
                                    var selectBalanceEfek = this.formMasterRef.current.instance.getEditor('efekBalanceAvgId')
                                    selectBalanceEfek.option('dataSource', efekBalanceAvg(this.props.store, this.state.data.productId, e.value))
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Bank'
                            }
                        ]
                    },
                    {
                        dataField: 'efekBalanceAvgId',
                        label: {
                            text: 'No. Bilyet'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'efekBalanceAvgId',
                            displayExpr: 'bilyetNo',
                            onSelectionChanged: async (e) => {
                                await this.handleSelection(e)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose bilyet number'
                            }
                        ]
                    },
                    {
                        dataField: 'maturityDate',
                        label: {
                            text: 'Tanggal Jatuh tempo'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            readOnly: true,
                            onSelectionChanged: (e) => {
                                var data = e.selectedItem
                                if (data) {
                                    this.formMasterRef.current.instance.getEditor('kurs').option('value', data.rate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rateFirst',
                        label: {
                            text: 'Rate'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'tenorFirst',
                                label: {
                                    alignment: 'left',
                                    text: 'Tenor'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'tenorFirsTypeId',
                                label: { visible: false },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    items: [
                                        'HARI',
                                        'BULAN'
                                    ],
                                    value: 'HARI',
                                    layout: "horizontal",
                                    readOnly: true
                                },
                            },
                        ]
                    },
                    {
                        dataField: 'nominalPokok',
                        label: {
                            text: 'Nominal Pokok'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'acruedToday',
                        label: {
                            text: 'Nominal Bunga',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    // {
                    //     dataField: 'acruedInterest',
                    //     label: {
                    //         text: 'Nominal Bunga',
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0.00',
                    //         step: 0,
                    //         readOnly: true
                    //     }
                    // },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'tenor',
                                label: {
                                    alignment: 'left',
                                    text: 'Tenor'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0
                                }
                            },
                            {
                                dataField: 'tenorTypeId',
                                label: { visible: false },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    items: ['HARI', 'BULAN'],
                                    value: 'HARI',
                                    layout: "horizontal",
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        var maturityDate = this.formMasterRef.current.instance.getEditor('maturityDate').option('value')
                                        var tenor = this.formMasterRef.current.instance.getEditor('tenor').option('value')
                                        var result = []
                                        if (val == 'BULAN') {
                                            var month = new Date(maturityDate)
                                            var getMonth = new Date(maturityDate).getMonth()
                                            result = month.setMonth(getMonth + tenor)
                                        } else {
                                            var date = new Date(maturityDate).getTime()
                                            result = date + (tenor * 24 * 3600 * 1000)
                                        }
                                        var jatuhTempo = new Date(result)
                                        this.formMasterRef.current.instance.getEditor('maturityDatePerpanjangan').option('value', jatuhTempo)
                                    }
                                },
                            },
                        ]
                    },
                    {
                        dataField: 'rolloverTypeId',
                        label: {
                            text: 'Tipe Perpanjangan'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['Pokok + Bunga', 'Pokok', 'Sebagian'],
                            onValueChanged: (e) => {
                                if (e.value) {
                                    var val = e.value
                                    var nominalPerpanjangan = this.formMasterRef.current.instance.getEditor('nominalPerpanjangan')
                                    var nomPokok = this.formMasterRef.current.instance.getEditor('nominalPokok').option('value')
                                    // var nomBunga = this.formMasterRef.current.instance.getEditor('acruedInterest').option('value')
                                    var nomBunga = this.formMasterRef.current.instance.getEditor('acruedToday').option('value')
                                    if (val == 'Pokok + Bunga') {
                                        nominalPerpanjangan.option('value', nomPokok + nomBunga)
                                        nominalPerpanjangan.option('readOnly', true)
                                    } else if (val == 'Pokok') {
                                        nominalPerpanjangan.option('value', nomPokok)
                                        nominalPerpanjangan.option('readOnly', true)
                                    } else {
                                        nominalPerpanjangan.option('value', 0)
                                        nominalPerpanjangan.option('readOnly', false)
                                    }
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose extension"
                            }
                        ]
                    },
                    {
                        dataField: 'nominalPerpanjangan',
                        label: {
                            text: 'Nominal Perpanjangan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                            onValueChanged: (e) => {
                                var val = e.value
                                if (val) {
                                    var rolloverType = this.formMasterRef.current.instance.getEditor('rolloverTypeId').option('value')
                                    var nomPokok = this.formMasterRef.current.instance.getEditor('nominalPokok').option('value')
                                    // var nomBunga = this.formMasterRef.current.instance.getEditor('acruedInterest').option('value')
                                    var nomBunga = this.formMasterRef.current.instance.getEditor('acruedToday').option('value')
                                    var nilaiTransfer = this.formMasterRef.current.instance.getEditor('nilaiTransfer')
                                    if (val <= nomPokok) {
                                        if (rolloverType == 'Pokok + Bunga') {
                                            nilaiTransfer.option('value', 0)
                                        } else if (rolloverType == 'Pokok') {
                                            nilaiTransfer.option('value', nomBunga)
                                        } else {
                                            nilaiTransfer.option('value', nomPokok + nomBunga - val)
                                        }
                                    } else {
                                        notify({ message: 'Nominal Perpanjangan melebihi Nilai Deposito', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                    }
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please fill Rollover Amount'
                            }
                        ]
                    },
                    {
                        dataField: 'rate',
                        label: {
                            text: 'Rate Perpanjangan (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'maturityDatePerpanjangan',
                        label: {
                            text: 'Jatuh Tempo Perpanjangan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'nilaiTransfer',
                        label: {
                            text: 'Nilai Transfer',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please fill Transfer Amount'
                            }
                        ]
                    },
                    {
                        dataField: 'rekbankId',
                        label: {
                            text: 'Rekening Pencairan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.rekNo + ' - ' + item.rekName
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Bank Account'
                            }
                        ]
                    },
                ]
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    // retrieveData = async (data, type) => {
    //     if(data){
    //         if (data.productId) {
    //             var selectBankId = this.formMasterRef.current.instance.getEditor('bankId')
    
    //             selectBankId.option('dataSource', await this.availableBankDepositoPelepasan(this.props.store, data.productId))
    //         }

    //         if (type === 'add') {
    //             this.setState({
    //                 data: {
    //                     id: data.id,
    //                     noPengajuan: data.noPengajuan,
    //                     productId: data.productId,
    //                     instrumentCode: data.instrumentCode,
    //                     instrumentName: data.instrumentName,
    //                     bilyetNo: data.bilyetNo,
    //                     rolloverTypeId: data.rolloverTypeId == 0 ? 'Pokok + Bunga' : data.rolloverTypeId == 1 ? 'Pokok' : data.rolloverTypeId == 2 ? 'Sebagian' : null,
    //                     maturityDatePerpanjangan: data.maturityDatePerpanjangan,
    //                     tenorFirst: data.tenorFirst,
    //                     tenorFirsTypeId: data.tenorFirsTypeId,
    //                     tanggalPerpanjangan: data.tanggalPerpanjangan,
    //                     bankId: data.bankId,
    //                     currencyId: data.currencyId,
    //                     kurs: data.kurs,
    //                     nominalPerpanjangan: data.nominalPerpanjangan,
    //                     nominalPokok: data.nominalPokok,
    //                     nilaiTransfer: data.nilaiTransfer,
    //                     efekBalanceAvgId: data.efekBalanceAvgId,
    //                     rateFirst: data.rateFirst,
    //                     rate: data.rate,
    //                     // acruedInterest: data.acruedInterest,
    //                     acruedToday: data.acruedToday,
    //                     maturityDate: data.maturityDate,
    //                     rekbankId: data.rekbankId,
    //                     tenor: data.tenor,
    //                     tenorTypeId: data.tenorTypeId
    //                 },
    //                 // dataDetail: {
    //                 //     // nilaiTransfer: 
    //                 // },
    //             });
    //             this.formMasterRef.current.instance.getEditor('efekBalanceAvgId').option('dataSource', await this.availableEfekBalanceAvg(this.props.store, this.state.data.productId, this.state.data.bankId))
    //         } else if (type === 'edit' || type === 'view') {
    //             let loadAPI = `efek-balance-avgs/all-instrument-deposito?bankId=${data.bankId}&kdpId=1&productId=${data.productId}`;
    //             let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
    //             this.setState({
    //                 data: {
    //                     id: data.id,
    //                     noPengajuan: data.noPengajuan,
    //                     productId: data.productId,
    //                     instrumentCode: data.instrumentCode,
    //                     instrumentName: data.instrumentName,
    //                     bilyetNo: data.bilyetNo,
    //                     rolloverTypeId: data.rolloverTypeId == 0 ? 'Pokok + Bunga' : data.rolloverTypeId == 1 ? 'Pokok' : data.rolloverTypeId == 2 ? 'Sebagian' : null,
    //                     maturityDatePerpanjangan: data.maturityDatePerpanjangan,
    //                     tenorFirst: data.tenorFirst,
    //                     tenorFirsTypeId: data.tenorFirsTypeId,
    //                     tanggalPerpanjangan: data.tanggalPerpanjangan,
    //                     bankId: data.bankId,
    //                     currencyId: data.currencyId,
    //                     kurs: data.kurs,
    //                     nominalPerpanjangan: data.nominalPerpanjangan,
    //                     nominalPokok: data.nominalPokok,
    //                     nilaiTransfer: data.nilaiTransfer,
    //                     efekBalanceAvgId: response[0].efekBalanceAvgId,
    //                     rateFirst: data.rateFirst,
    //                     rate: data.rate,
    //                     // acruedInterest: data.acruedInterest,
    //                     acruedToday: data.acruedToday,
    //                     maturityDate: data.maturityDate,
    //                     rekbankId: data.rekbankId,
    //                     tenor: data.tenor,
    //                     tenorTypeId: data.tenorTypeId
    //                 },
    //                 // dataDetail: {
    //                 //     // nilaiTransfer: 
    //                 // },
    //             });
    
    //             // this.formMasterRef.current.instance.getEditor('efekBalanceAvgId').option('dataSource', await this.availableEfekBalanceAvg(this.props.store, this.state.data.productId, this.state.data.bankId))
                
    //             switch (this.state.data.rolloverTypeId) {
    //                 case 'Pokok + Bunga':
    //                     this.formMasterRef.current.instance.getEditor('nilaiTransfer').option('value', this.state.data.nominalPokok + this.state.data.acruedToday)
    //                     break;
    //                 case 'Pokok':
    //                     this.formMasterRef.current.instance.getEditor('nilaiTransfer').option('value', this.state.data.acruedToday)
    //                     break;
    //                 case 'Sebagian':
    //                     this.formMasterRef.current.instance.getEditor('nilaiTransfer').option('value', 0)
    //                     break;
    //                 default: 
    //                     return null
    //             }
    //         }
    //         // this.formMasterRef.current.instance.getEditor('efekBalanceAvgId').option('dataSource', response[0].id)
    //     }
    // }

    handleSelection = async(e) => {
        var data = e.selectedItem
        var tanggalPelepasan = this.formMasterRef.current.instance.getEditor('tanggalPerpanjangan').option('value')
        var diffTime = []
        var diffDays = []
        var result = 0
        var tenor = []
        if (data) {
            var date1 = new Date('12/31/2021')
            var date2 = new Date('01/01/2021')
            diffTime = Math.abs(date1 - date2)
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            var dayBases = diffDays + 1
            var date3 = new Date(tanggalPelepasan)
            var date4 = new Date(data.balanceDate)
            let diffTime2 = Math.abs(date3 - date4)
            tenor = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24))
            result = data.faceValue * (data.rate / 100) * (tenor / dayBases)
            this.formMasterRef.current.instance.getEditor('acruedToday').option('value', result)

            this.formMasterRef.current.instance.getEditor('currencyId').option('value', data.currencyId)
            this.formMasterRef.current.instance.getEditor('nominalPokok').option('value', data.faceValue)
            // this.formMasterRef.current.instance.getEditor('acruedInterest').option('value', data.acruedInterest)
            this.formMasterRef.current.instance.getEditor('maturityDate').option('value', data.maturityDate)
            this.formMasterRef.current.instance.getEditor('rateFirst').option('value', data.rate)
            this.formMasterRef.current.instance.getEditor('tenorFirst').option('value', data.tenor)
            this.formMasterRef.current.instance.getEditor('tenorFirsTypeId').option('value', data.tenorTypeId)
            this.setState({
                valueData: data
            })
        }
    }

    retrieveData = async (data, type) => {
        if (data) {
            if (data.productId) {
                const selectBankId = this.formMasterRef.current.instance.getEditor('bankId');
                const bankDataSource = await this.availableBankDepositoPelepasan(this.props.store, data.productId);
    
                if (selectBankId && bankDataSource) {
                    selectBankId.option('dataSource', bankDataSource);
                }
            }
    
            const updateForm = async (data) => {
                const efekBalanceAvgDataSource = await this.availableEfekBalanceAvg(
                    this.props.store,
                    data.productId,
                    data.bankId
                );
                this.formMasterRef.current.instance.getEditor('efekBalanceAvgId')
                    .option('dataSource', efekBalanceAvgDataSource);
    
                if (efekBalanceAvgDataSource && efekBalanceAvgDataSource._array && efekBalanceAvgDataSource._array.length > 0) {
                    this.formMasterRef.current.instance.getEditor('efekBalanceAvgId')
                        .option('value', efekBalanceAvgDataSource._array[0].efekBalanceAvgId);
                }
            };
    
            if (type === 'add') {
                this.setState({
                    data: {
                        ...data,
                        rolloverTypeId: data.rolloverTypeId === 0 ? 'Pokok + Bunga' :
                                         data.rolloverTypeId === 1 ? 'Pokok' :
                                         data.rolloverTypeId === 2 ? 'Sebagian' : null,
                    },
                }, async () => {
                    await updateForm(this.state.data);
                });
            } else if (type === 'edit' || type === 'view') {
                const loadAPI = `efek-balance-avgs/all-instrument-deposito?bankId=${data.bankId}&kdpId=1&productId=${data.productId}`;
                const response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    loadAPI,
                    'GET'
                );
    
                if (response && response.length > 0) {
                    this.setState({
                        data: {
                            ...data,
                            rolloverTypeId: data.rolloverTypeId === 0 ? 'Pokok + Bunga' :
                                             data.rolloverTypeId === 1 ? 'Pokok' :
                                             data.rolloverTypeId === 2 ? 'Sebagian' : null,
                            efekBalanceAvgId: response[0].efekBalanceAvgId,
                        },
                    }, async () => {
                        await updateForm(this.state.data);
    
                        const { rolloverTypeId, nominalPokok, acruedToday } = this.state.data;
                        const nilaiTransfer = rolloverTypeId === 'Pokok + Bunga' ?
                                              nominalPokok + acruedToday :
                                              rolloverTypeId === 'Pokok' ?
                                              acruedToday : 0;
                        
                        this.formMasterRef.current.instance.getEditor('nilaiTransfer')
                            .option('value', nilaiTransfer);
                    });
                }
            }
        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
        this.props.forceRefresh()
    }

    submitData = () => {
        var data = this.state.data
        var valueData = this.state.valueData
        this.props.submitDetailPerpanjanganData({
            id: data.id,
            noPengajuan: data.noPengajuan,
            productId: data.productId,
            instrumentCode: valueData.instrumentCode,
            instrumentName: valueData.instrumentName,
            bilyetNo: valueData.bilyetNo,
            rolloverTypeId: data.rolloverTypeId == 'Pokok + Bunga' ? 0 : data.rolloverTypeId == 'Pokok' ? 1 : 2,
            maturityDatePerpanjangan: formatDate(data.maturityDatePerpanjangan),
            tenorFirst: data.tenorFirst,
            tenorFirsTypeId: data.tenorFirsTypeId,
            tanggalPerpanjangan: formatDate(data.tanggalPerpanjangan),
            bankId: data.bankId,
            currencyId: data.currencyId,
            kurs: data.kurs,
            nominalPerpanjangan: data.nominalPerpanjangan,
            nominalPokok: data.nominalPokok,
            nilaiTransfer: data.nilaiTransfer,
            efekBalanceAvgId: data.efekBalanceAvgId,
            rateFirst: data.rateFirst,
            rate: data.rate,
            // acruedInterest: data.acruedInterest,
            acruedToday: data.acruedToday,
            maturityDate: data.maturityDate,
            rekbankId: data.rekbankId,
            tenor: data.tenor,
            tenorTypeId: data.tenorTypeId
        })
        this.hide()
    }

    availableBankDepositoPelepasan = async (store, productId) => {
        let loadAPI = `efek-balance-avgs/all-available-bank-deposito?kdpId=1&productId=${productId}`
        var getBankId = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')
        var data = []
        for (var value of getBankId) {
            let loadAPIBank = `banks/${value}`
            var getBankData = await httpRequest(process.env.REACT_APP_BACKEND, store, loadAPIBank, 'GET')

            var bankData = {
                id: value,
                bankName: getBankData.bankName
            }
            data.push(bankData)
        }

        let dataSource = new ArrayStore({
            data: data,
            key: 'id'
        });

        return dataSource
    }

    availableEfekBalanceAvg = async (store, productId, bankId) => {
        let loadAPI = `efek-balance-avgs/all-instrument-deposito?bankId=${bankId}&kdpId=1&productId=${productId}`;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET');
        let data = [];

        for (let value of response) {
            let efekBalanceData = {
                ...value,
                efekBalanceAvgId: value.efekBalanceAvgId,
                bilyetNo: value.bilyetNo
            }

            data.push(efekBalanceData);
        }

        let dataSource = new ArrayStore({
            data: data,
            key: 'efekBalanceAvgId'
        });

        return dataSource;
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Perpanjangan Investasi Deposito'}
                minWidth={'70vw'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={2}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.data}
                                        items={this.formItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={this.props.type == 'view' ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormSubInstrumentPerpanjanganDeposito