import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { formatUploadFileData, PrintElemWithoutTitle, formatDefaultFullDate, formatUploadFileNameAndExtention, download, imageSource, pembulatan, numberToIndo, yearsDiff, formatDate, daysDiff } from 'plugin/helper';
import { banks, pensiunType, product, tipePembayaranMp, pesertaFamiliesAll, phkType, mpPenerimaType, rekBank, banks_core } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import reportRequest, { reportFile } from 'plugin/reportRequest';
import { FileUploader, ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import ModalPreviewPdf from './previewPdf';
import ModalPreviewImage from './previewImage';
import ModalNoSuratCetak from '../pesertaDapen/pasif/registrasi/cetak';

class ViewDataPensiunPasif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {},
            nsPeriode: {},
            dataPeserta: {}
        }

        this.ns = [
            {
                id: 1,
                value: 2020
            },
            {
                id: 2,
                value: 2021
            },
            {
                id: 3,
                value: 2022
            }
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.modalCetakRef = React.createRef()

        this.globalParam = this.props.store.getState().getParam
        this.dataKeluargaByPesertaId = []
        this.valueFrom = 0
        this.PopupToolbarItemAktif = [
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Cetak',
                    onClick: () => {
                        this.modalCetakRef.current.show()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        var formValidate = this.validate()
                        if (formValidate) {
                            if (new Date(this.state.dataMaster.pensiunDate) < new Date(this.state.dataMaster.efektifDate)) {
                                var message = 'Tanggal pensiun tidak boleh kurang dari tanggal efektif akun peserta!'
                                await alert(message, 'Validasi!')
                            } else {
                                await this.props.submitData(this.state.dataMaster)
                                // this.hide()
                                this.props.removeItemDataGrid()
                                this.props.forceRefresh()
                            }
                        }
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.PopupToolbarItemRegistrasiPasif = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: async () => {
                        var formValidate = this.validate()
                        if (formValidate) {
                            if (new Date(this.state.dataMaster.pensiunDate) < new Date(this.state.dataMaster.efektifDate)) {
                                var message = 'Tanggal pensiun tidak boleh kurang dari tanggal efektif akun peserta!'
                                await alert(message, 'Validasi!')
                            } else {
                                await this.props.submitData(this.state.dataMaster)
                                // this.hide()
                                this.props.removeItemDataGrid()
                                this.props.forceRefresh()
                            }
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.PopupToolbarItemPensiunanPasif = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'master',
                items: [

                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'statusName',
                        label: {
                            text: 'Jenis Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pensiunType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'statusName',
                            searchEnabled: true,
                            deferRendering: false,
                            // onValueChanged: async (data) => {
                            //     var value = data.value
                            //     var dataAkun = this.state.dataMaster
                            //     var dataPeserta = this.state.dataPeserta
                            //     var pensiunDateField = this.formRef.current.instance.itemOption('master.pensiunDate')
                            //     // if(value == 4 || value == 5){
                            //     //     dataAkun.pensiunDateOld =  dataAkun.pensiunDate
                            //     //     var loadAPIGlblParam = `global-parameters/get-by-paramCode/2%20UP`
                            //     //     var getUsiaPensiun = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIGlblParam, 'GET')

                            //     //     var birthDate = new Date(dataPeserta.birthDate)
                            //     //     var day = birthDate.getDate() + 1
                            //     //     var month = birthDate.getMonth() + 1
                            //     //     var year = birthDate.getFullYear()
                            //     //     // var formatDay = day.toLocaleString('id-ID', {//this is the function that formats the numbers
                            //     //     //     minimumIntegerDigits: 2, //change this to your minimum length
                            //     //     //     useGrouping: false
                            //     //     // })
                            //     //     // var formatMonth = month.toLocaleString('id-ID', {//this is the function that formats the numbers
                            //     //     //     minimumIntegerDigits: 2, //change this to your minimum length
                            //     //     //     useGrouping: false
                            //     //     // })
                            //     //     var pensiunDate =  (year + getUsiaPensiun.intValue || 56) +'-'+ month +'-'+ day
                            //     //     dataAkun.pensiunDate = new Date(pensiunDate)
                            //     //     pensiunDateField.editorOptions.readOnly = true
                            //     // }else{
                            //     //     dataAkun.pensiunDate =  dataAkun.pensiunDate || dataAkun.pensiunDateOld
                            //     //     pensiunDateField.editorOptions.readOnly = false
                            //     // }
                            //     dataAkun.pensiunDate = dataAkun.pensiunDate || dataAkun.pensiunDateOld
                            //     pensiunDateField.editorOptions.readOnly = false

                            //     this.formRef.current.instance.itemOption('master.pensiunDate', pensiunDateField)
                            //     this.formRef.current.instance.updateData(dataAkun)

                            // }
                        },
                    },
                    {
                        dataField: 'skPensiunNo',
                        label: {
                            text: 'No SK Pensiun'
                        },
                    },
                    {
                        dataField: 'skPemberhentianNo',
                        label: {
                            text: 'No SK PHK'
                        },
                    },
                    {
                        dataField: 'phkName',
                        label: {
                            text: 'Jenis PHK'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: phkType(),
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'mpPaymentTypeId',
                        label: {
                            text: 'Pembayaran MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipePembayaranMp(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'typeName',
                            // onValueChanged: (data) => {
                            //     var getPenguranganPph = this.globalParam.find(val => val.paramCode == '2 Tax MPS')
                            //     var value = data.value
                            //     var dataAkun = this.state.dataMaster
                            //     var dataPeserta = this.state.dataPeserta
                            //     var formulaPph21 = dataPeserta.npwp ? 5 / 100 : 6 / 100
                            //     if (getPenguranganPph) {
                            //         if (value == 1) {
                            //             if (getPenguranganPph.intValue < dataAkun.mpSekaligus20) {
                            //                 dataAkun.pajakPph21MpSekaligus = (dataAkun.mpSekaligus20 - getPenguranganPph.intValue) * formulaPph21
                            //             }
                            //         }
                            //         if (value == 2) {
                            //             if (getPenguranganPph.intValue < dataAkun.mpSekaligus) {
                            //                 dataAkun.pajakPph21MpSekaligus = (dataAkun.mpSekaligus - getPenguranganPph.intValue) * formulaPph21
                            //             }
                            //         }

                            //         if (dataAkun.pajakPph21MpSekaligus) {
                            //             dataAkun.pajakPph21MpSekaligusBulat = pembulatan(dataAkun.pajakPph21MpSekaligus, 2)
                            //         } else {
                            //             dataAkun.pajakPph21MpSekaligusBulat = 0
                            //         }

                            //         this.formRef.current.instance.updateData(dataAkun)
                            //     }
                            // }
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'pensiunDate',
                        name: 'pensiunDate',
                        label: {
                            text: 'Tanggal Pensiun'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                    },
                    {
                        itemType: 'group',
                        colCount: 4,
                        items: [
                            {
                                dataField: 'masaKerja',
                                label: {
                                    text: 'Masa Kerja'
                                },
                                colSpan: 3,
                                editorOptions: {
                                    readOnly: true,
                                },
                                isRequired: true,
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Data Harus Diisi"
                                    }
                                ]
                            },
                            // {
                            //     itemType: 'button',
                            //     horizontalAlignment: 'left',
                            //     buttonOptions: {
                            //         text: 'Kalkulasi',
                            //         type: 'secondary',
                            //         onClick: async (e) => {
                            //             var dataMaster = this.state.dataMaster
                            //             var dataPeserta = this.state.dataPeserta
                            //             var nsPeriode = this.state.nsPeriode
                            //             console.log(dataMaster);
                            //             // var data = this.state.data
                            //             let loadAPI = `peserta-pensiuns/calculatePensiunByFunction/1/${dataMaster.productId}/${dataPeserta.nik}/${nsPeriode.periodeNs}/${dataMaster.pensiunType}/${dataMaster.pensiunDate}/${dataMaster.mpPenerimaTypeId}/${dataMaster.mpPenerimaTypeId > 1 ? dataMaster.mpPenerimaPesertaFamilyTypeId : 0}/${dataMaster.mpPaymentTypeId}/false`
                            //             var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                            //             dataMaster.masaKerja = `${response[0].masa_kerja_thn} tahun ${response[0].masa_kerja_bln} bulan`
                            //             dataMaster.usiaPesertaPensiun = `${response[0].usia_penerima_thn} tahun ${response[0].usia_penerima_bln} bulan`
                            //             dataMaster.mpSekaligusPeserta = response[0].mp_sekaligus_100_ori
                            //             dataMaster.mpBulananPeserta = response[0].mp_bulanan_100_ori
                            //             dataMaster.mpSekaligusJadud = response[0].mp_bulanan_80
                            //             dataMaster.mpBulananJadud = response[0].mp_sekaligus_100
                            //             dataMaster.mpSekaligusAnak = response[0].mp_sekaligus_20_ori
                            //             dataMaster.mpBulananAnak = response[0].mp_bulanan_20_ori

                            //             dataMaster.mpSekalligus100 = response[0].mp_sekaligus_100_ori
                            //             dataMaster.mpSekalligus100Bulat = response[0].mp_sekaligus_100
                            //             dataMaster.mpBulanan100 = response[0].mp_bulanan_100
                            //             dataMaster.mpBulanan100Bulat = response[0].mp_bulanan_100_ori
                            //             dataMaster.mpSekaligus20 = response[0].mp_sekaligus_20_ori
                            //             dataMaster.mpSekaligus20Bulat = response[0].mp_sekaligus_20
                            //             dataMaster.mpBulanan80 = response[0].mp_bulanan_80_ori
                            //             dataMaster.mpBulanan80Bulat = response[0].mp_bulanan_80
                            //             dataMaster.nominalPajak100 = response[0].mps_100_pajak_npwp
                            //             dataMaster.nominalPajak20 = response[0].mps_20_pajak_npwp
                            //             this.formRef.current.instance.updateData(dataMaster)
                            //             // if (dataMaster.masaKerja) {
                            //             //     var mkTahun = parseInt(dataMaster.masaKerja.split('Tahun')[0])
                            //             //     var mkBulan = dataMaster.masaKerja.split('Tahun')[1] ? parseInt((dataMaster.masaKerja.split('Tahun')[1]).split('Bulan')[0]) : 0
                            //             //     var tahun = this.props.peridoeNs
                            //             //     var loadAPI = `peserta-pensiuns/getPerhitunganPensiunWithMasaKerja/${dataMaster.pesertaId}/${dataMaster.productId}/${mkBulan}/${mkTahun}/${tahun}`
                            //             //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

                            //             //     this.retrieveData(data, response, dataPeserta)
                            //             // } else {
                            //             //     await alert("Silahkan Isi masa kerja terlebih dahulu!", 'Informasi!')
                            //             // }
                            //         }
                            //     },
                            // },
                        ]
                    },
                    {
                        dataField: 'usiaPesertaPensiun',
                        label: {
                            text: 'Usia Pensiun'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    // {
                    //     dataField: 'peridoeNs',
                    //     label: {
                    //         text: 'NS Periode'
                    //     },
                    //     editorOptions: {
                    //         readOnly: true
                    //     }
                    // },
                ]
            },
            {
                itemType: 'group',
                name: 'penerima',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Penerima MP',
                        name: 'penerimaMp',
                        items: [
                            {
                                dataField: 'mpPenerimaTypeId',
                                label: {
                                    text: 'Penerima'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: mpPenerimaType(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'penerimaName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                                isRequired: true,
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Data Harus Diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'mpPenerimaPesertaFamilyTypeId',
                                // name: 'namaPenerima',
                                label: {
                                    text: 'Keluarga Penerima MP'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: pesertaFamiliesAll(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'fullName',
                                    searchEnabled: true,
                                    deferRendering: false
                                },
                            },
                            {
                                dataField: 'mpPertamaDate',
                                label: {
                                    text: 'Tanggal MP Pertama'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    placeholder: 'dd MMM yyyy',
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    width: '100%'
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'rekeningMp',
                        caption: 'Rekening Manfaat Pensiun',
                        items: [
                            {
                                dataField: 'noRek',
                                label: {
                                    text: 'No Rek MP'
                                }
                            },
                            {
                                dataField: 'namaRekMp',
                                label: {
                                    text: 'Nama Rek MP'
                                }
                            },
                            {
                                dataField: 'bank',
                                label: {
                                    text: 'Rekening Bank Pembayaran'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                                isRequired: true,
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Rekening Pembayaran Harus Diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'rekeningCabang',
                                label: {
                                    text: 'Cabang'
                                }
                            },
                        ]
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'perhitunganMp',
                caption: 'Perhitungan Manfaat Pensiun',
                items: [
                    {
                        dataField: 'mpSekaligusPeserta',
                        label: {
                            text: 'Nilai MP Sekaligus Peserta'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpBulananPeserta',
                        label: {
                            text: 'Nilai MP Bulanan Peserta'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpSekaligusJadud',
                        label: {
                            text: 'Nilai MP Sekaligus Jadud'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpBulananJadud',
                        label: {
                            text: 'Nilai MP Bulanan Jadud'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpSekaligusAnak',
                        label: {
                            text: 'Nilai MP Sekaligus Anak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpBulananAnak',
                        label: {
                            text: 'Nilai MP Bulanan Anak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'mpTransfer',
                caption: 'Manfaat Pensiun Di Transfer',
                items: [
                    {
                        dataField: 'mpSekalligus100',
                        // name: 'nilaiMpSekaligusFull',
                        label: {
                            text: 'Nilai MP Sekaligus 100%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpSekalligus100Bulat',
                        label: {
                            text: 'Nilai MP Sekaligus 100% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpBulanan100',
                        // name: 'nilaiMpBulananFull',
                        label: {
                            text: 'Nilai MP Bulanan 100%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpBulanan100Bulat',
                        // name: 'nilaiMpBulananFull',
                        label: {
                            text: 'Nilai MP Bulanan 100% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpSekaligus20',
                        label: {
                            text: 'Nilai MP Sekaligus 20%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'mpSekaligus20Bulat',
                        label: {
                            text: 'Nilai MP Sekaligus 20% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'mpBulanan80',
                        label: {
                            text: 'Nilai MP Bulanan 80%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'mpBulanan80Bulat',
                        label: {
                            text: 'Nilai MP Bulanan 80% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'nominalPajak100',
                        label: {
                            text: 'Nilai Pajak PPH 21 MP Sekaligus 100%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        }
                    },
                    // {
                    //     dataField: 'pajakPph21MpSekaligusBulat',
                    //     label: {
                    //         text: 'Nilai Pajak PPH 21 MP Sekaligus 100% Bulat'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         step: 0,
                    //         format: '#,##0',
                    //     }
                    // },
                    {
                        dataField: 'nominalPajak20',
                        label: {
                            text: 'Nilai Pajak PPH 21 MP Sekaligus 20%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        }
                    },
                    // {
                    //     dataField: 'pajakPph21MpSekaligusBulat20',
                    //     label: {
                    //         text: 'Nilai Pajak PPH 21 MP Sekaligus 20% Bulat'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         step: 0,
                    //         format: '#,##0',
                    //     }
                    // },
                    {
                        dataField: 'rekName',
                        label: {
                            text: 'Rekening Pembayar'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'rekName',
                            searchEnabled: true,
                            deferRendering: false
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                ]
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            // var extension = mime.extension(this.state.dataMaster[`${fileName}ContentType`])
            // console.log(extension)
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`]), this.state.dataMaster[`${fileName}Name`])
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataPeserta: {},
            dataMaster: {},
            nsPeriode: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)

                var src = formattedFileData.base64data
                var decoded = atob(src)

                if (decoded.length > 1000000) {
                    alert('File Size Tidak Boleh Melebihi dari 1Mb', 'Error');
                } else {
                    this.setState(prevState => ({
                        dataMaster: {
                            ...prevState.dataMaster,
                            [`${fieldName}Name`]: files.name,
                            // [`${fieldName}Extension`]: formatNameAndExtention.extention,
                            [`${fieldName}`]: formattedFileData.base64data,
                            [`${fieldName}ContentType`]: formattedFileData.mime,
                        }
                    }))
                }
            }

            fr.readAsDataURL(files)
        }
    }

    retrieveData = async (dataAkun, dataPerhitungan, dataPeserta) => {
        let dataMaster = this.state.dataMaster

        var loadAPI = `peserta-pensiuns/loadDataRegistPensiun/${dataPeserta.nik}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        var familyPenerima = this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId')
        var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${dataPeserta.id}`
        var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKeluarga, 'GET')
        this.dataKeluargaByPesertaId = getKeluarga
        familyPenerima.editorOptions.dataSource = getKeluarga
        this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', familyPenerima)

        console.log(response[0].keluarga_penerima_mp)
        dataMaster.productId = dataAkun.productId
        dataMaster.bank = response[0].rekening_bank_pembayaran
        dataMaster.pensiunDate = response[0].tanggal_pensiun
        dataMaster.rekeningCabang = response[0].cabang
        dataMaster.mpPenerimaTypeId = response[0].penerima
        dataMaster.nominalPajak100 = response[0].nilai_pajak_pph_21_mp_sekaligus_100
        // dataMaster. = response[0].status_description
        dataMaster.statusName = response[0].jenis_pensiun
        dataMaster.skPensiunNo = response[0].no_sk_pensiun
        dataMaster.skPemberhentianNo = response[0].no_sk_phk
        dataMaster.phkName = response[0].jenis_phk
        dataMaster.mpPaymentTypeId = response[0].pembayaran_mp
        dataMaster.masaKerja = `${response[0].masa_kerja_tahun} tahun ${response[0].masa_kerja_bulan} bulan`
        dataMaster.usiaPesertaPensiun = `${response[0].usia_pensiun_tahun} tahun ${response[0].usia_pensiun_bulan} bulan`
        dataMaster.peridoeNs = response[0].ns_periode
        dataMaster.mpPenerimaPesertaFamilyTypeId = response[0].keluarga_penerima_mp
        dataMaster.mpPertamaDate = response[0].tanggal_mp_pertama
        dataMaster.noRek = response[0].no_rek_mp
        dataMaster.namaRekMp = response[0].nama_rek_mp

        dataMaster.mpSekaligusPeserta = response[0].nilai_mp_sekaligus_peserta
        dataMaster.mpBulananPeserta = response[0].nilai_mp_bulanan_peserta
        dataMaster.mpSekaligusJadud = response[0].nilai_mp_sekaligus_jadud
        dataMaster.mpBulananJadud = response[0].nilai_mp_bulanan_jadud
        dataMaster.mpBulananAnak = response[0].nilai_mp_bulanan_anak
        dataMaster.rekName = response[0].rekening_pembayar
        dataMaster.mpSekaligusAnak = response[0].nilai_mp_sekaligus_anak
        // dataMaster. = response[0].manfaat_pensiun_di_transfer
        dataMaster.mpSekalligus100 = response[0].nilai_mp_sekaligus_100
        dataMaster.mpSekalligus100Bulat = response[0].nilai_mp_sekaligus_100_bulat
        dataMaster.mpBulanan100 = response[0].nilai_mp_bulanan_100
        dataMaster.mpBulanan100Bulat = response[0].nilai_mp_bulanan_100_bulat
        dataMaster.mpSekaligus20 = response[0].nilai_mp_sekaligus_20
        dataMaster.mpSekaligus20Bulat = response[0].nilai_mp_sekaligus_20_bulat
        dataMaster.mpBulanan80 = response[0].nilai_mp_bulanan_80
        dataMaster.mpBulanan80Bulat = response[0].nilai_mp_bulanan_80_bulat
        dataMaster.nominalPajak20 = response[0].nilai_pajak_pph_21_mp_sekaligus_20

        // let loadAPI = `peserta-pensiuns/calculatePensiunByFunction/1/${dataAkun.productId}/${dataPeserta.nik}/${dataAkun.nsPeriode}/${dataAkun.pensiunType}/${dataAkun.pensiunDate}/${dataAkun.mpPenerimaTypeId}/${dataAkun.mpPenerimaTypeId > 1 ? dataAkun.mpPenerimaPesertaFamilyTypeId : 0}/${dataAkun.mpPaymentTypeId}/false`
        // var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        // dataMaster.statusName = dataAkun.pensiunType
        // dataMaster.skPensiunNo = dataAkun.skPensiunNo
        // dataMaster.skPemberhentianNo = dataAkun.skPemberhentianNo
        // dataMaster.phkName = dataAkun.pesertaPhkTypeId
        // dataMaster.pensiunDate = dataAkun.pensiunDate
        // dataMaster.mpPaymentTypeId = dataAkun.mpPaymentTypeId
        // dataMaster.mpPenerimaTypeId = dataAkun.mpPenerimaTypeId
        // dataMaster.mpPenerimaPesertaFamilyTypeId = dataAkun.mpPenerimaPesertaFamilyTypeId
        // dataMaster.mpPertamaDate = dataAkun.mpPertamaDate
        // dataMaster.noRek = dataAkun.mpRekeningNo
        // dataMaster.namaRekMp = dataAkun.mpRekeningName
        // dataMaster.bank = dataAkun.mpRekeningBankId
        // dataMaster.rekeningCabang = dataAkun.mpRekeningCabang
        // dataMaster.rekName = dataAkun.paymentRekbankId


        // dataMaster.masaKerja = `${response[0].masa_kerja_thn} tahun ${response[0].masa_kerja_bln} bulan`
        // dataMaster.usiaPesertaPensiun = `${response[0].usia_penerima_thn} tahun ${response[0].usia_penerima_bln} bulan`
        // dataMaster.mpSekaligusPeserta = response[0].mp_sekaligus_100_ori
        // dataMaster.mpBulananPeserta = response[0].mp_bulanan_100_ori
        // dataMaster.mpSekaligusJadud = response[0].mp_bulanan_80
        // dataMaster.mpBulananJadud = response[0].mp_sekaligus_100
        // dataMaster.mpSekaligusAnak = response[0].mp_sekaligus_20_ori
        // dataMaster.mpBulananAnak = response[0].mp_bulanan_20_ori

        // dataMaster.mpSekalligus100 = response[0].mp_sekaligus_100_ori
        // dataMaster.mpSekalligus100Bulat = response[0].mp_sekaligus_100
        // dataMaster.mpBulanan100 = response[0].mp_bulanan_100
        // dataMaster.mpBulanan100Bulat = response[0].mp_bulanan_100_ori
        // dataMaster.mpSekaligus20 = response[0].mp_sekaligus_20_ori
        // dataMaster.mpSekaligus20Bulat = response[0].mp_sekaligus_20
        // dataMaster.mpBulanan80 = response[0].mp_bulanan_80_ori
        // dataMaster.mpBulanan80Bulat = response[0].mp_bulanan_80
        // dataMaster.nominalPajak100 = response[0].mps_100_pajak_npwp
        // dataMaster.nominalPajak20 = response[0].mps_20_pajak_npwp

        // var getData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `view-perhitungan-pensiuns?nik.in=${dataPeserta.nik}`, 'GET')
        // getData[0].masaKerja = getData[0].mkTahun + ' Tahun ' + getData[0].mkBulan + ' Bulan '
        // getData[0].usiaPesertaPensiun = getData[0].usiaPensiunTahun + ' Tahun ' + getData[0].usiaPensiunBulan + ' Bulan ' + '0 Hari'

        this.setState({
            dataMaster: { ...dataMaster },
            dataPeserta: dataPeserta
        });
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Data Pensiun'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.props.pensiunan ? this.PopupToolbarItemPensiunanPasif : this.PopupToolbarItemAktif}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation='left'
                                    readOnly={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-12">
                                <h5>Dokumen</h5>
                            </div>

                            {/* fileSkPensiun */}

                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
                <ModalNoSuratCetak
                    ref={this.modalCetakRef}
                    store={this.props.store}
                    cetak={this.cetak}
                />
            </Popup>
        );
    }
}


export default ViewDataPensiunPasif