import React, { Component } from "react";
import { Form, Popup } from "devextreme-react";
import httpRequest from "plugin/httprequest";
import notify from 'devextreme/ui/notify';

class EditDataSpesific extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: false,
            formData: {}
        }

        this.columns = [
            {
                dataField: 'companyBankId',
                label: {
                    alignment: 'left',
                    text: 'Bank Perusahaan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    deferRendering: false,
                },
                disabled: false,
            },
            {
                dataField: 'description',
                label: {
                    alignment: 'left',
                    text: 'Deskripsi'
                },
            },
            {
                dataField: 'valueDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Jurnal'
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                },
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.formRef = React.createRef()
        this.companyBank = []
    }

    retrieveData = async (data) => {
        try {
            var companyBankEditor = this.formRef.current.instance.getEditor('companyBankId')
            var getCashVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/${data.cashVoucherId}`)
            if (data.settleWith == 'B') {
                var companyBank = []
                var dataRekBank
                var dataBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks')
                switch (getCashVoucher.companyBankReff) {
                    case 'IN': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-banks')
                        dataRekBank = dataRekBank.map(value => {
                            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
                            return {
                                id: value.id,
                                code: `${getCashVoucher.companyBankReff}-${value.id}`,
                                value: `${bank ? bank.initialName : null} - ${value.rekNo} - ${value.rekName}`
                            }
                        })
                        break;
                    case 'HR': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'rek-banks')
                        dataRekBank = dataRekBank.map(value => {
                            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
                            return {
                                id: value.id,
                                code: `${getCashVoucher.companyBankReff}-${value.id}`,
                                value: `${bank ? bank.initialName : null} - ${value.rekNo} - ${value.rekName}`
                            }
                        })
                        break;
                    case 'KP': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'rekbanks')
                        dataRekBank = dataRekBank.map(value => {
                            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)

                            return {
                                id: value.id,
                                code: `${getCashVoucher.companyBankReff}-${value.id}`,
                                value: `${bank ? bank.initialName : null} - ${value.rekNo} - ${value.rekName}`
                            }
                        })
                        break;
                    default:
                        break;
                }
                companyBank = [...companyBank, ...dataRekBank]
                companyBankEditor.option('dataSource', companyBank)
                this.setState({ formData: getCashVoucher })
                return
            }

            companyBankEditor.option('disabled', true)
            this.setState({ formData: getCashVoucher })
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    submitData = async () => {
        try {
            let formData = this.state.formData;
            let getCashVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers`, 'PUT', {
                key: formData.id,
                values: formData
            }, formData);

            if (getCashVoucher) {
                var loadAPITask = `tasks?referenceId.equals=${getCashVoucher.id}&taskStatusId.equals=1`;
                var getAPITask = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPITask, 'GET');

                if (getAPITask.length !== 0) {
                    let valueTask = getAPITask[0];
                    let desc = valueTask.description;
                    let valueDesc = desc.split(' ');
    
                    valueTask.description = `${valueDesc[0]} ${formData.description}`;
                    valueTask.taskDate = formData.entryDate;
                    valueTask.doDate = formData.valueDate;

                    await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'tasks', 'PUT', {
                        key: valueTask.id,
                        values: valueTask
                    }, valueTask);
                }

                this.hide();
                this.props.forceRefresh()
                return notify({ message: `Data Berhasil diubah`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
            }

        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            formData: {}
        });
    };

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Edit (Bank, Deskripsi, Tanggal Jurnal)"}
                    width={"50vw"}
                    height={"50vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form
                        colCount={1}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.columns}
                        ref={this.formRef}
                        scrollingEnabled={true}
                        labelLocation="left"
                    />

                </Popup>
            </React.Fragment>
        )
    }
}

export default EditDataSpesific