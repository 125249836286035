import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import { pesertaById } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import FormDataDplk from '../comp/formDataDplk';

class ModalPengalihanDplk extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            pesertaId: 0,
            actionType: 'add'
        }

        this.pesertaId = 0
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()

        this.formMasterRef = React.createRef()
        this.formDataKepegawaianRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.formDataAkunRef = React.createRef()
        this.formDataPensiunRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 4,
                text: 'Data Pensiun'
            }
        ]

        this.columns = [
            {
                dataField: 'nikPanjang',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]
    }
    get Popup(){
        return this.popupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });

        this.prevTabIndex = 0
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
        this.props.forceRefresh()
    }
    onValueChanged = (e) =>{
        var fieldValue = e.value
        this.pesertaId = fieldValue
        this.formDataPensiunRef.current.reloadData(this.pesertaId,this.state.dataMaster)
    }
    setSelectedRow = (id) => {
        this.dropDownBoxRef.current.instance.option('value',id)
    }
    submitData = async(data = {}) => {
        if(this.state.actionType == 'add'){
            var postData = {
                "akunStatusId": 12,
                "dplkTujuan": data.dplkTujuan,
                "fileSkPensiun": data.fileSkPensiun,
                "fileSkPensiunContentType": data.fileSkPensiunContentType,
                "fileSkPhk": data.fileSkPhk,
                "fileSkPhkContentType": data.fileSkPhkContentType,
                "idAkunProduct": data.id,
                "nominalPengalihan": data.nominalPengalihan,
                "pengalihanRekeningBankId": data.pengalihanRekeningBankId,
                "pengalihanRekeningName": data.pengalihanRekeningName,
                "pengalihanRekeningNo": data.pengalihanRekeningNo,
                "pensiunType": data.pensiunType,
                "pesertaPhkTypeId": data.pesertaPhkTypeId,
                "productId": data.productId,
                "skPemberhentianNo": data.skPemberhentianNo,
                "skPensiunNo": data.skPensiunNo,
                "tglDialihkan": data.tglDialihkan,
                "tglPembayaranPengalihan": data.tglPembayaranPengalihan,
                "transferRekbankId": data.transferRekbankId
              }
            try {
                var loadAPI = 'registrasi-dplk'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                    values:postData
                })

                await alert('Berhasil menambahkan pengajuan dplk baru', 'Berhasil!')
            } catch (e) {
                await alert(e, 'Gagal!')
            }
        }else{
            try {
                var loadAPI = 'kepersertaan-products'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'PUT', {
                    key: data.id,
                    values:data
                },data)
                await alert('Berhasil merubah pengajuan dplk', 'Berhasil!')
            } catch (e) {
                await alert(e, 'Gagal!')
            }
        }
        this.hide()
    }

    retrieveData = async(data = null, type) => {
        if(data){
            this.setState({
                pesertaId: data.pesertaId,
                dataMaster: data,
                actionType: type
            })
            this.pesertaId = data.pesertaId
            this.setSelectedRow(data.pesertaId)
            await this.formDataPensiunRef.current.reloadData(data.pesertaId,data)
        }else{
            // await this.formDataPensiunRef.current.reloadData()
            this.setState({
                pesertaId: 0,
                dataMaster: {},
            })

            this.pesertaId = 0
            this.formDataPensiunRef.current.forceRefresh()
        }
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabModalIndex${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabModalIndex${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onSelectionChanged = (e) => {
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
    }

    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI= {this.props.actionType == 'add' ? 'view-pesertas?statusPeserta.in=2' : 'view-pesertas'}
                insertAPI='lookup-pesertas/getByLookupByStatusPeserta/2'
                updateAPI='lookup-pesertas/getByLookupByStatusPeserta/2'
                deleteAPI='lookup-pesertas/getByLookupByStatusPeserta/2'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'auto'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Peserta DPLK'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Peserta</label>
                            <div className="col-sm-10">
                                <DropDownBox
                                    ref={this.dropDownBoxRef}
                                    value={this.state.dataMaster.pesertaId}
                                    valueExpr="id"
                                    deferRendering={false}
                                    displayExpr={(item) => {
                                        return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                    }}
                                    showClearButton={true}
                                    dataSource={pesertaById(this.props.store)}
                                    onValueChanged={this.onValueChanged}
                                    contentRender={this.renderDatagrid}
                                    readOnly = {true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <DevextremeTab
                            dataSource={this.TabDataSource}
                            onOptionChanged={this.onTabSelectionChanged}
                        />
                    </div>
                    <div id={`tabModalIndex${this.props.actionType}-4`} className={'mt-2 col-md-12 text-center' }>
                        <FormDataDplk store={this.props.store} submitData={this.submitData} ref={this.formDataPensiunRef} actionType={this.props.actionType}/>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalPengalihanDplk