import React, { Component } from 'react'
import { currency, banks, orders, product, pejabat} from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import uuidv4 from 'uuid/v4'
import FormInstruksiBankTerkirim from 'components/pages/modal/investasi/instruksi/bank/terkirim/index'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import httpRequest from 'plugin/httprequest'
import { formatDateDmy, formatDefaultFullDate, formatNumber, getDays, imageSource } from 'plugin/helper'
import { reportFile } from 'plugin/reportRequest'

class InstruksiBankTerkirim extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.rekeningBank = []
        this.getBank = []
        this.subCategory = []
        this.order = []
        this.dataGridInstruksiBankRef = React.createRef()
        this.formPenempatanRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            this.previewFile(e.row.data)
                        }
                    },
                    {
                        text: 'Cetak Instruksi',
                        hint: 'Cetak Instruksi',
                        onClick: (e) => {
                            this.cetakInstruksi(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'instruksiId',
                caption: 'ID',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'transactionName',
                caption: 'Transaksi',
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiNo',
                        caption: 'Nomor'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'totalOrder',
                        caption: 'Jumlah',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'totalAmountOrder',
                        caption: 'Total Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    }
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            },
        ]
    }
    componentWillMount = async() => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var getRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-banks', 'GET')
        var getBankData = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET')
        var getSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'instrument-category-subs/deposito', 'GET')
        var getOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'orders?size=999', 'GET')

        this.rekeningBank = getRekBank
        this.getBank = getBankData
        this.subCategory = getSubCategory
        this.order = getOrder
    }
    previewFile = async(data) => {
        var instruksiId = data.instruksiId
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-deposito/${instruksiId}`, 'GET')
        var src = imageSource(response.orderInstruksiDTO.fileInstruksiContentType,response.orderInstruksiDTO.fileInstruksi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    cetakInstruksi = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-deposito/${data.instruksiId}`, 'GET')
        var deposito = []
        if (detail.orderInstruksiDTO.orderTypeId == 231) {
            // Penempatan
            var bankId = this.rekeningBank.find(val => val.id == detail.orderInstruksiDTO.sourceRekBankId).bankId
            var no = 0
            for(var dataDeposito of detail.orderDepositoInfos){
                var dataResult = {
                    no: ++no,
                    amountOrder: formatNumber(dataDeposito.orderDepositoDTO.amountOrder),
                    placementDate: formatDateDmy(dataDeposito.orderDepositoDTO.placementDate),
                    tenor: dataDeposito.orderDepositoDTO.tenor + ' ' + dataDeposito.orderDepositoDTO.tenorTypeId,
                    rateOrder: dataDeposito.orderDepositoDTO.rateOrder,
                }
                deposito.push(dataResult)
            }
            reportFile({
                template: { 'shortid': 'SygHBTopL_' },
                data: {
                    "instruksiNo": data.instruksiNo,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "bankName": data.bankName,
                    "totalAmountOrder": formatNumber(data.totalAmountOrder),
                    "rekNo": this.rekeningBank.find(val => val.id == detail.orderInstruksiDTO.sourceRekBankId).rekNo,
                    "rekName": this.rekeningBank.find(val => val.id == detail.orderInstruksiDTO.sourceRekBankId).rekName,
                    "rekBankCabang": this.rekeningBank.find(val => val.id == detail.orderInstruksiDTO.sourceRekBankId).cabang,
                    "rekBankName": this.getBank.find(val => val.id == bankId).bankName,
                    "deposito": deposito
                },
                options: {
                    reportName: "Penempatan "+data.bankName
                }
            },true,`Penempatan Deposito ${data.bankName}.docx`)
        } else if (detail.orderInstruksiDTO.orderTypeId == 232) {
            // Pelepasan
            var bankId = this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).bankId
            var no = 0
            for(var dataDeposito of detail.orderDepositoInfos){
                var dataResult = {
                    no: ++no,
                    amountOrder: formatNumber(dataDeposito.orderDepositoDTO.amountOrder),
                    maturityDate: formatDateDmy(dataDeposito.instrumentDTO.maturityDate),
                    bilyetNo: dataDeposito.orderDepositoDTO.bilyetNo,
                }
                deposito.push(dataResult)
            }
            reportFile({
                template: { 'shortid': 'BJx9T5C68_' },
                data: {
                    "instruksiNo": data.instruksiNo,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "instruksiDay": getDays(data.instruksiDate),
                    "bankName": data.bankName,
                    "totalAmountOrder": formatNumber(data.totalAmountOrder),
                    "rekNo": this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).rekNo,
                    "rekName": this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).rekName,
                    "rekBankCabang": this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).cabang,
                    "rekBankName": this.getBank.find(val => val.id == bankId).bankName,
                    "deposito": deposito
                },
                options: {
                    reportName: "Pelepasan "+data.bankName
                }
            },true,`Pelepasan Deposito ${data.bankName}.docx`)
        }else if(detail.orderInstruksiDTO.orderTypeId == 233){
            // Perpanjangan
            var bankId = this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).bankId
            var no = 0
            for(var dataDeposito of detail.orderDepositoInfos){
                var dataResult = {
                    no: ++no,
                    amountOrder: formatNumber(dataDeposito.orderDepositoDTO.amountOrder),
                    maturityDate: formatDateDmy(dataDeposito.instrumentDTO.maturityDate),
                    bilyetNo: dataDeposito.orderDepositoDTO.bilyetNo,
                    tenor: dataDeposito.orderDepositoDTO.tenor + ' ' + dataDeposito.orderDepositoDTO.tenorTypeId,
                    rateOrder: dataDeposito.orderDepositoDTO.rateOrder,
                }
                deposito.push(dataResult)
            }
            reportFile({
                template: { 'shortid': 'HyxkQp0TUu' },
                data: {
                    "instruksiNo": data.instruksiNo,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "bankName": data.bankName,
                    "totalAmountOrder": formatNumber(data.totalAmountOrder),
                    "rekNo": this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).rekNo,
                    "rekName": this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).rekName,
                    "rekBankCabang": this.rekeningBank.find(val => val.id == detail.orderDepositoInfos[0].orderDepositoDTO.rekBankId).cabang,
                    "rekBankName": this.getBank.find(val => val.id == bankId).bankName,
                    "deposito": deposito
                },
                options: {
                    reportName: "Perpanjangan "+data.bankName
                }
            },true,`Perpanjangan Deposito ${data.bankName}.docx`)
        }
    }
    showModalPenempatan = (data) => {
        this.formPenempatanRef.current.show()
        this.formPenempatanRef.current.retrieveData(data)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Bank Terkirim</h2>
                <DevExpressDataGrid
                    keyField='instruksiId'
                    ref={this.dataGridInstruksiBankRef}
                    loadAPI='order-instruksi-deposito/list?size=9999&status=SENT'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instruksi Bank"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Bank'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                /> 
                <FormInstruksiBankTerkirim
                    ref={this.formPenempatanRef}
                    store={this.props.store}
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default InstruksiBankTerkirim