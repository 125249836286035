import React, { Component } from 'react'
import {Popup, Form} from 'devextreme-react'
import { akunHR, dasarNilai, tipeTransaksiSdmUmum } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormModalTunjanganLain extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{
                faktorPPH21:true,
                status:true,
            }
        }

        this.form = [
            {
                dataField:"kodeTunjangan",
                label:{
                    text:"Kode"
                }
            },
            {
                dataField:"namaTunjangan",
                label:{
                    text:"NamaTunjangan"
                }
            },
            {
                dataField:"periodeTunjanganPertahun",
                label:{
                    text:"Periode Tunjangan(Tahun)"
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    step:0,
                },
            },
            {
                dataField:"akunId",
                label:{
                    text:"Akun",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"dasarNilaiTunjangan",
                label:{
                    text:"Dasar Nilai Tunjangan"
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: dasarNilai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dasarNilai', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"presentasiGajiPokok",
                label:{
                    text:"Presentase Gaji Pokok"
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00 %',
                    step:0,
                }
            },
            {
                dataField:"presentasiTakeHomePay",
                label:{
                    text:"Presentase Take Home Pay"
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00 %',
                    step:0,
                }
            },
            {
                dataField:"nominalTunjangan",
                label:{
                    text:"NominalTunjangan"
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:"faktorPph21",
                label:{
                    text:"Faktor PPH 21"
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"status",
                label:{
                    text:"Status"
                },
                editorType:'dxCheckBox',
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster:data
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => { 
        this.newData = true
        this.setState({
            DataMaster:{
                faktorPPH21:true,
                status:true,
            },
            popupVisible: false
        })
        this.props.forceRefresh()
    }

    submitData = async () => {
        try {
            var loadAPI = 'tunjangan-lains'
            var text = 'Sukses Input Tunjangan Lain'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"POST",{
                values: this.state.DataMaster
            });

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    updateData = async () => {
        try {
            var loadAPI = 'tunjangan-lains'
            var text = 'Sukses Input Tunjangan Lain'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"PUT",{
                values: this.state.DataMaster,
                key:this.state.DataMaster
            },this.state.DataMaster);

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Tunjangan Lain'} 
                width={700}
                height={500}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <Form
                colCount={1}
                id={'formPotongan'}
                formData={this.state.DataMaster}
                items={this.form}
                labelLocation = 'left'
            />
            </Popup>
        )
    }
}

export default FormModalTunjanganLain