import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormPengajuanCuti from 'components/pages/modal/sdm-umum/kepegawaian/cuti-lembur/pengajuan-cuti/form';
import { jabatan, months, unit, tipeCuti, karyawan, sdmUnit, programs, provinces, cities, status } from 'dataSource/lookup';
import Form from 'devextreme-react/form';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import notify from 'devextreme/ui/notify'
import FormNoteModal from 'components/pages/modal/sdm-umum/kepegawaian/cuti-lembur/noteModal';
import { showLoading } from 'redux/actions/loading';

class KepegawaianVerifikasiCuti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {},
            selectedTabIndex: 0,
        }
        this.modalEditRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.modalInsertRef = React.createRef()
        
        this.modalNoteRef = React.createRef()
        this.filterData = []
        this.data = {}
        this.actionType = ""

        this.formMaster = [
            {
                dataField: "bulan",
                label: {
                    text: "Bulan",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled:true
                }
            },
            {
                dataField: "tahun",
                label: {
                    text: "Tahun",
                    alignment: "left",
                    location: "left",
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.pengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalNote()
                            this.actionType = "kirim"
                            this.data = data
                        }
                    },
                    {
                        text: 'Tolak',
                        hint: 'Tolak',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalNote()
                            this.actionType = "tolak"
                            this.data = data
                        }
                    },
                ],
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'pengajuanNo',
                caption: 'No Pengajuan',
            },
            {
                dataField: 'pengajuanTanggal',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'karyawanId',
                caption: 'Nama Lengkap',
                lookup: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function (item) {
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'jabatanId',
                caption: 'Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values
                }
            },
            {
                dataField: 'unitId',
                caption: 'Unit',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                }
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'Cuti',
                caption: 'Cuti',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'cutiTypeId',
                        caption: 'Tipe',
                        lookup: {
                            dataSource: tipeCuti(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'cutiName', // provides display values
                        }
                    },
                    {
                        dataField: 'hari',
                        caption: 'Hari',
                        // calculateCellValue: (e) => {
                        //     var timeDiff = (new Date(e.tanggalAkhir).getTime() - new Date(e.tanggalAwal).getTime()) / 1000
                        //     var daysCount = Math.floor(timeDiff / (86400))
                        //     return daysCount
                        // }
                    },
                    {
                        dataField: 'tanggalAwal',
                        caption: 'Tanggal Awal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tanggalAkhir',
                        caption: 'Tanggal Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tunjanganCuti',
                        caption: 'Uang Cuti',
                    },
                    {
                        dataField: 'tunjanganCutiAmount',
                        caption: 'Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    }
                ]
            },
            {
                dataField: 'lokasiCuti',
                caption: 'Lokasi Cuti',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'lokasiDalamNegeri',
                        caption: 'Lokasi',
                        lookup: {
                            dataSource: [
                                {
                                    id: true,
                                    value: 'Dalam Negeri'
                                },
                                {
                                    id: false,
                                    value: 'Luar Negeri'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'provinceId',
                        caption: 'Provinsi',
                        lookup: {
                            dataSource: provinces(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'provinceName'
                        }
                    },
                    {
                        dataField: 'cityId',
                        caption: 'Kota',
                        lookup: {
                            dataSource: cities(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'cityName'
                        }
                    },
                ]
            },
            {
                dataField: 'catatan',
                caption: 'Catatan',
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup:{
                    dataSource: status(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            }
        ]
    }

    componentDidMount = async() => {
        this.defaultData()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    defaultData = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-karyawan-cutis?statusId.in=2`,
            'GET'
        )
        this.filterData = datas
        if (this.filterData) {
            this.forceRefresh()
        }
    }

    showModalEdit = async(data) => {
        var dataDetailMaster = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-cutis/${data.id}`,
            'GET'
        )
        var dataDetailViewMaster = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-karyawan-cutis/${data.id}`,
            'GET'
        )
        var dataTanggal = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-cutis/findAllkaryawanCutiId/${data.id}`
        )

        var cutiDetil = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cuti-detils/findAllByKaryawanCutiId/${data.id}`, 'GET');
        // try {
        //     var cutiDetil = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cuti-detils`, 'GET');
        //     var resultCuti = [];
        //     var cutiDetilData = cutiDetil.filter(value => value.karyawanCutiId === data.id);

        //     console.log(cutiDetilData);
            
        //     resultCuti.push(cutiDetilData);
        // } catch (error) {
        //     console.log(error);
        // }
        // var listDataTanggal = dataTanggal.length > 0 ? dataTanggal[0].jumlahHari : 0
        var resultTgl = []
        var dataMaster = {
            id: dataDetailViewMaster.id || 0,
            programId: dataDetailViewMaster.programId,
            noPengajuan: dataDetailViewMaster.pengajuanNo,
            tanggalPengajuan: dataDetailViewMaster.pengajuanTanggal,
            karyawanId: dataDetailViewMaster.karyawanId,
            jabatanId: dataDetailViewMaster.jabatanId,
            unitId: dataDetailViewMaster.unitId,
            periodeCutiId: dataDetailMaster.periodeCutiId,
            cutiTypeId: dataDetailViewMaster.cutiTypeId,
            tunjanganCuti: dataDetailMaster.tunjanganCuti,
            tunjanganTypeId: dataDetailMaster.tunjanganTypeId,
            tglPembayaran: dataDetailMaster.paymentDate,
            catatan: dataDetailMaster.keterangan,
            tglInterval: dataDetailMaster.tanggalInterval,
            tglAwal: dataDetailViewMaster.tanggalAwal,
            tglAkhir: dataDetailViewMaster.tanggalAkhir,
            // jumlahHariCuti: dataDetailMaster.hari + listDataTanggal,
            jumlahHariCuti: dataDetailViewMaster.hari,
            lokasiCuti: data.lokasiDalamNegeri,
            provinceId: data.provinceId,
            cityId: data.cityId,
            alamat: dataDetailMaster.alamat,
            noTelp: dataDetailMaster.noTelp
        }
        var dataDokumen = {
            dokumenName: dataDetailMaster.dokumenName,
            dokumenFileContentType: dataDetailMaster.dokumenFileContentType,
            dokumenFile: dataDetailMaster.dokumenFile,
        }

        for (let value of cutiDetil) {
            if (value.tanggal_1 && value.tanggal_2) {
                var tgl = {
                    id: value.id,
                    tglAwal: value.tanggal_1,
                    tglAkhir: value.tanggal_2,
                    jumlahHari: value.hari
                }
                resultTgl.push(tgl);
            } else {
                resultTgl.push();
            }
        }

        this.modalEditRef.current.retrieveData(dataMaster, resultTgl, dataDokumen)
        this.modalEditRef.current.show()
        this.modalEditRef.current.getTipeTunjangan()
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }
    
    showModalInsert = () => {
        this.modalInsertRef.current.show()
    }

    setCutiNotesAndSend = async (catatan) => {
        this.props.store.dispatch(showLoading(true))
        try {
            if (this.actionType == "kirim") {
                var getKaryawanCuti =  await httpRequest(
                    process.env.REACT_APP_BACKEND_HRD,
                    this.props.store,
                    `karyawan-cutis/${this.data.id}`,
                    'GET'
                )
                getKaryawanCuti.karyawanCutiId = getKaryawanCuti.id
                getKaryawanCuti.approve = true
                getKaryawanCuti.catatan = catatan
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_HRD,
                    this.props.store,
                    `karyawan-cutis/kirim-pengajuan`,
                    'PUT',{
                        values: getKaryawanCuti,
                    },getKaryawanCuti
                )
                if(response){
                    this.props.store.dispatch(showLoading(false))
                    notify({ message: `Verifikasi Berhasil!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.defaultData()
                }
            }else{
                var getKaryawanCuti =  await httpRequest(
                    process.env.REACT_APP_BACKEND_HRD,
                    this.props.store,
                    `karyawan-cutis/${this.data.id}`,
                    'GET'
                )
                getKaryawanCuti.karyawanCutiId = getKaryawanCuti.id
                getKaryawanCuti.approve = false
                getKaryawanCuti.catatan = catatan
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_HRD,
                    this.props.store,
                    `karyawan-cutis/kirim-pengajuan`,
                    'PUT',{
                        values: getKaryawanCuti,
                    },getKaryawanCuti
                )
                if(response){
                    this.props.store.dispatch(showLoading(false))
                    notify({ message: `Verifikasi Ditolak!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.defaultData()
                }
            }
            
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    showModalNote = async() => {
        this.modalNoteRef.current.show()
    }

    // tolakCuti = async (data) => {
    //     try {
    //         var getKaryawanCuti =  await httpRequest(
    //             process.env.REACT_APP_BACKEND_HRD,
    //             this.props.store,
    //             `karyawan-cutis/${data.id}`,
    //             'GET'
    //         )
    //         getKaryawanCuti.statusId = 1
    //         var response = await httpRequest(
    //             process.env.REACT_APP_BACKEND_HRD,
    //             this.props.store,
    //             `karyawan-cutis`,
    //             'PUT',{
    //                 values: getKaryawanCuti,
    //                 key:getKaryawanCuti.id
    //             },getKaryawanCuti
    //         )
    //         if(response){
    //             notify({ message: `Verifikasi ditolak!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
    //             this.defaultData()
    //         }
    //     } catch (e) {
    //         notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    //     }
    // }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-karyawan-cutis?statusId.in=2`,
            'GET'
        )

        var listData = []
        for(var data of datas){
            data.pengajuanTanggal = new Date(data.pengajuanTanggal)
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        }else if (state.bulan) {
            this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.pengajuanTanggal.getFullYear() == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    
    }

    loadData = () => {
        return this.filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Verifikasi Cuti Karyawan</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.DataGridPengajuanRef}
                    loadAPI='view-karyawan-cutis?statusId.in=2'
                    insertAPI='view-karyawan-cutis'
                    updateAPI='view-karyawan-cutis'
                    deleteAPI='view-karyawan-cutis'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Karyawan Cuti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.pengajuanColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanCuti
                    ref={this.modalEditRef}
                    store={this.props.store}
                    type={'view'}
                    forceRefresh={this.defaultData}
                />
                <FormNoteModal
                    ref={this.modalNoteRef}
                    store={this.props.store}
                    setCutiNotes={this.setCutiNotesAndSend}
                    forceRefresh={this.defaultData}
                />
            </div>
        )
    }
}

export default KepegawaianVerifikasiCuti