import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import ModalPortfolioRebalance from 'components/pages/modal/investasi/portfolio/rebalance/index';
import ModalCorporateActionSchedule from 'components/pages/modal/investasi/corporate-action/schedule/index';
import Form from 'devextreme-react/form';
import ModalCorporateActionStockSplit from 'components/pages/modal/investasi/corporate-action/stock-split/index';
import ModalFileCorporateActionStockSplit from 'components/pages/modal/investasi/corporate-action/stock-split/file';
import { productDefault } from 'dataSource/lookup';
import { confirmAlert } from 'react-confirm-alert';
import httpRequest from 'plugin/httprequest';

class StockSplit extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            dataMaster: {},
            filterItem: {
                productId: 1
            }
        }

        this.modalAlokasiRef = React.createRef()
        this.modalCorporateActionStockSplitRef = React.createRef()
        this.modalFileCorporateActionStockSplitRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.filterItem = [
            {
                dataField: "productId",
                label: {
                    text: "Produk",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: (e) => {
                        return e && e.productCode + ' - ' + e.productName
                    }
                },
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.setState({
                            filterItem: this.state.filterItem
                        })
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalCorporateActionStockSplit('edit', e.row.data)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin membatalkan data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.cancelSplit(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.sendSplit(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'Nomor Konfirmasi',
            },
            {
                dataField: 'productName',
                caption: 'Produk',
            },
            {
                dataField: 'kpd',
                caption: 'Pengelola',
            },
            {
                dataField: 'jenisCorpact',
                caption: 'Jenis Corpact',
            },
            {
                dataField: 'saham',
                caption: 'Saham',
            },
            {
                dataField: 'tglEntry',
                caption: 'Tanggal Entry',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tglRecording',
                caption: 'Tanggal Recording',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'qtyRecording',
                caption: 'Quantity Recording',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'rasioSaham',
                caption: 'Rasio Saham',
                alignment: 'right',
            },
            {
                dataField: 'qtyAkhir',
                caption: 'Quantity Akhir',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: 'status',
                caption: 'Status',
            },

        ]
    }
    showModalCorporateActionStockSplit = async (type, data) => {
        if (type == 'add') {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                'bank-transactions/generate-no-konfirmasi/STOCK_SPLIT',
                'POST',
                { values: {} }
            )
            data = {
                corpactTypeId: 4,
                ratioQtyOld: 1,
                noKonfirmasi: response,
                recordingDate: this.sysDate.dateValue,
            }
        } else if (type == 'edit') {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `corpact-transactions/${data.id}`,
                'GET',
            )
            data = {
                id: data.id,
                noKonfirmasi: data.noKonfirmasi,
                corpactTypeId: response.corpactTypeId,
                corpactProductId: response.productId,
                recordingDate: response.recordingDate,
                ratioQtyOld: response.ratioQtyOld,
                ratioQtyNew: response.ratioQtyNew,
                splitProductId: response.productId,
                splitProductId: response.productId,
                splitKpdId: response.kpdId,
                instrumentId: response.instrumentId,
            }
        }
        this.modalCorporateActionStockSplitRef.current.show(type, data)
    }
    sendSplit = async (data) => {
        var id = data.id
        if (id) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `corpact-transactions/transaksi-stock-split-konfirmasi/${id}`,
                    'PUT', {
                    key: id,
                    values: {}
                },
                )
                this.forceRefresh()
                notify({ message: 'Sukses Kirim Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    cancelSplit = async (data) => {
        var id = data.id
        if (id) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `corpact-transactions`,
                    'DELETE', id,
                )
                this.forceRefresh()
                notify({ message: 'Sukses Membatalkan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Stock Split',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalCorporateActionStockSplit('add')
                    },
                }
            },
        )
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Stock Split</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`corpact-transactions/transaksi-stock-split-list/${this.state.filterItem.productId}?size=999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Stock Split"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Stock Split'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalCorporateActionStockSplit
                    ref={this.modalCorporateActionStockSplitRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalFileCorporateActionStockSplit
                    ref={this.modalFileCorporateActionStockSplitRef}
                    store={this.props.store}
                />

            </div>
        )
    }
}

export default StockSplit