import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'

class MatrikulasiRisiko extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                dataField: 'outputRiskGrade',
                caption: 'Output Risk Grade',
            },
            {
                dataField: '1',
                caption: '0 ≤ x ≤ Rp. 100 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '2',
                caption: 'Rp. 100 M < x ≤ Rp. 200 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '3',
                caption: 'Rp. 200 M < x ≤ Rp. 300 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '4',
                caption: 'Rp. 300 M < x ≤ Rp. 400 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
            {
                dataField: '5',
                caption: 'x ≥ Rp. 400 M',
                cellRender: (e) => {
                    return <p style={{ whiteSpace: 'pre-wrap' }}>{e.value}</p>
                }
            },
        ]
        this.dictionary = [
            {
                coordinate: [
                    [
                        1, 1
                    ],
                    [
                        1, 2
                    ],
                    [
                        2, 1
                    ],
                    [
                        3, 1
                    ]
                ],
                color: '#73c86e'
            },
            {
                coordinate: [
                    [
                        5, 1
                    ],
                    [
                        4, 1
                    ],
                    [
                        4, 2
                    ],
                    [
                        3, 2
                    ],
                    [
                        3, 3
                    ],
                    [
                        2, 2
                    ],
                    [
                        2, 3
                    ],
                    [
                        1, 3
                    ],
                    [
                        1, 4
                    ],
                ],
                color: '#fff208'
            },
            {
                coordinate: [
                    [
                        5, 2
                    ],
                    [
                        5, 3
                    ],
                    [
                        4, 3
                    ],
                    [
                        4, 4
                    ],
                    [
                        3, 4
                    ],
                    [
                        2, 4
                    ],
                    [
                        2, 5
                    ],
                    [
                        1, 5
                    ],
                ],
                color: '#fec000'
            },
            {
                coordinate: [
                    [
                        5, 4
                    ],
                    [
                        5, 5
                    ],
                    [
                        4, 5
                    ],
                    [
                        3, 5
                    ]
                ],
                color: '#ff0808'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    componentDidMount = () => {
        this.getData()
    }

    getData = async () => {
        let dataMatrix = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-matrik-resikos?size=9999`);

        dataMatrix = dataMatrix ? dataMatrix.filter(value => value.matrikx && value.matriky) : []

        var formatedData = []
        for (const matrix of dataMatrix) {
            const formatedDataFoundIndex = formatedData.findIndex(value => value.x === matrix.matrikx && value.y === matrix.matriky)

            if (formatedDataFoundIndex !== -1) {
                formatedData[formatedDataFoundIndex].description.push(`${matrix.categoryName}-${matrix.subName}`)
            } else {
                formatedData.push(
                    {
                        x: matrix.matrikx,
                        y: matrix.matriky-1,
                        description: [
                            `${matrix.categoryName}: ${matrix.subName}`
                        ]
                    }
                )
            }
        }

        const lookup = [
            'Low Risk - Score Risk 0 - 2,00',
            'Medium Risk - Score Risk 2,01 - 4,00',
            'Medium High Risk - Score Risk 4,01 - 6,00',
            'High Risk - Score Risk 6,01 - 8,00',
            'Very High Risk - Score Risk 8,01 - 10',
        ]

        let result = []

        for (let y = 0; y < 5; y++) {
            let xValues = []
            for (let x = 1; x < 6; x++) {
                const matrixFound = formatedData.find(value => value.x === x && value.y === y)
                if (matrixFound) {
                    xValues[x] = matrixFound.description
                } else {
                    xValues[x] = []
                }
            }
            result.push({
                id: y + 1,
                outputRiskGrade: lookup[y],
                1: xValues[1].join('\n'),
                2: xValues[2].join('\n'),
                3: xValues[3].join('\n'),
                4: xValues[4].join('\n'),
                5: xValues[5].join('\n'),
            })
        }

        return result
    }

    generateBgColor = (e) => {
        const y = e.key
        const x = parseInt(e.column.dataField)
        return this.dictionary.find(value => value.coordinate.find(coor => coor[0] === y && coor[1] === x))
    }

    onCellPrepared = (e) => {
        if (e.rowType == 'data') {
            const dictFound = this.generateBgColor(e)
            if (dictFound) {
                e.cellElement.style.backgroundColor = dictFound.color
            }
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Matrikulasi Risiko</h2>
                <DevExpressDataGrid
                    loadAPI='user'
                    insertAPI='user'
                    updateAPI='user'
                    deleteAPI='user'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useArraySource={true}
                    ArraySourceData={this.getData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={false}
                    exportFileName={"Matrikulasi Risiko"}
                    allowExportSelectedData={false}
                    selection={"none"}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Matrikulasi Risiko'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={false} // set false untuk mematikan fitur filter

                    height={'calc(100% - 257px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onCellPrepared={this.onCellPrepared}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <div className="text-right">
                    <dl>
                        <dt className="green"></dt>
                        <dd>Resiko Rendah</dd>

                        <dt className="yellow"></dt>
                        <dd>Resiko Sedang</dd>

                        <dt className="orange"></dt>
                        <dd>Resiko Tinggi</dd>

                        <dt className="red"></dt>
                        <dd>Resiko Sangat Tinggi</dd>
                    </dl>
                </div>
            </div>
        )
    }
}

export default MatrikulasiRisiko