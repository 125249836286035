import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url'
import DataGridKasBank from './comp/dataGridKasBank';
import DataGridDeposito from './comp/dataGridDeposito';
import DataGridSaham from './comp/dataGridSaham';
import DataGridSBN from './comp/dataGridSbn';
import DataGridObligasi from './comp/dataGridObligasi';
import DataGridReksadana from './comp/dataGridReksadana';
import DataGridProperti from './comp/dataGridProperti';
import DataGridPenyertaanDana from './comp/dataGridPenyertaanDana';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { productDefault } from 'dataSource/lookup';
import DataGridEba from './comp/dataGridEba';
import DataGridEtf from './comp/dataGridEtf';

class BalancePortfolioDetailInstrument extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {
                productId: 1
            },
        }

        this.prevTabIndex = 0

        this.DataGridKasBankRef = React.createRef()
        this.DataGridDepositoRef = React.createRef()
        this.DataGridSahamRef = React.createRef()
        this.DataGridSBNRef = React.createRef()
        this.DataGridObligasiRef = React.createRef()
        this.DataGridReksadanaRef = React.createRef()
        this.DataGridPropertiRef = React.createRef()
        this.DataGridPenyertaanDanaRef = React.createRef()
        
        this.TabDataSource = [
            {
                id: 0,
                text: 'Kas Bank'
            },
            {
                id: 1,
                text: 'Deposito'
            },
            {
                id: 2,
                text: 'Saham'
            },
            {
                id: 3,
                text: 'SBN'
            },
            {
                id: 4,
                text: 'Obligasi Korporasi'
            },
            {
                id: 5,
                text: 'EBA'
            },
            {
                id: 6,
                text: 'Reksadana'
            },
            // {
            //     id: 7,
            //     text: 'ETF'
            // },
            {
                id: 7,
                text: 'Properti'
            },
            {
                id: 8,
                text: 'Penyertaan Modal'
            },
        ]
        this.filterItem = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (e) => {
                        console.log(e)
                        this.setState({
                            filterData: {
                                productId: e.value
                            }
                        })
                    }
                }
            },
        ]
    }
    
    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Instrumen Portfolio</h3>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                />
                <div className="mt-3">
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                </div>
                <div id="tabindex-0" className={' mt-2 text-center' } height={'100%'}>
                    <DataGridKasBank store = {this.props.store} ref={this.DataGridKasBankRef} productId={this.state.filterData.productId}/>
                </div>
                <div id="tabindex-1" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridDeposito store = {this.props.store} ref={this.DataGridDepositoRef} productId={this.state.filterData.productId}/>
                </div>
                <div id="tabindex-2" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridSaham store = {this.props.store} ref={this.DataGridSahamRef} productId={this.state.filterData.productId}/>
                </div>
                <div id="tabindex-3" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridSBN store = {this.props.store} ref={this.DataGridSBNRef} productId={this.state.filterData.productId}/>
                </div>
                <div id="tabindex-4" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridObligasi store = {this.props.store} ref={this.DataGridObligasiRef} productId={this.state.filterData.productId}/>
                </div>
                <div id="tabindex-5" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridEba store = {this.props.store} ref={this.DataGridObligasiRef} productId={this.state.filterData.productId}/>
                </div>
                <div id="tabindex-6" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridReksadana store = {this.props.store} ref={this.DataGridReksadanaRef} productId={this.state.filterData.productId}/>
                </div>
                {/* <div id="tabindex-7" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridEtf store = {this.props.store} ref={this.DataGridReksadanaRef} productId={this.state.filterData.productId}/>
                </div> */}
                <div id="tabindex-7" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridProperti store = {this.props.store} ref={this.DataGridPropertiRef} productId={this.state.filterData.productId}/>
                </div>
                <div id="tabindex-8" className={'d-none mt-2 text-center' } height={'100%'}>
                    <DataGridPenyertaanDana store = {this.props.store} ref={this.DataGridPenyertaanDanaRef} productId={this.state.filterData.productId}/>
                </div>
            </div>
        )
    }
}

export default BalancePortfolioDetailInstrument
