/*
 * action types
 */

export const MENU = 'MENU'
/*
 * action creators
 */

export function menu(menuType) {
  return { type: MENU, menuType }
}