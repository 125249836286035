import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { currency, statusKeuangan } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import ModalSettlementKonfirmasiPenerimaan from 'components/pages/modal/investasi/settlement/penyelesaian/penerimaan/konfirmasi'

class PenerimaanPenyelesaianTransaksi extends Component {
    constructor(props){
        super(props)
        this.state={
            dataMaster: {}
        }
        this.modalEditKonfirmasiRef = React.createRef()
        this.modalAddKonfirmasiRef = React.createRef()
        this.dgRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Buka Transaksi',
                        onClick: (e) => {
                            this.showModalEditKonfirmasi(e.row.data)
                        }
                    }
                ]
            },
            {
                dataField:'rekNo',
                caption: 'Rekening Penerimaan',
            },
            {
                dataField: 'referensiNo',
                caption: 'No. Instruksi'
            },
            {
                dataField: 'brokerName',
                caption: 'Penerimaan Dari'
            },
            {
                dataField: 'categoryName',
                caption: 'Efek'
            },
            {
                dataField: 'productName',
                caption: 'Produk'
            },
            {
                dataField: 'noKontrak',
                caption: 'KPD'
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'amountNet',
                caption: 'Nominal Kewajiban',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'currencyCode',
                caption: 'Mata Uang',
            },
            {
                dataField: 'statusName',
                caption: 'Status Keuangan',
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModalEditKonfirmasi = async(data) => {
        var transaction = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `transactions/${data.id}`,
            'GET'
        )
        var jurnal = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `transaction-jurnals?transactionId.in=${transaction.id}`,
            'GET'
        )
        var getData = {
            rekeningPenerimaan: data.rekNo,
            noInstruksi: data.referensiNo,
            tipeTransaksiId: transaction.transactionTypeId,
            totalKewajiban: data.amountNet,
            produkId: transaction.productId,
            kpdId: transaction.kpdId,
            jmlTransaksi: transaction.amountTrs,
            currencyId: transaction.currencyId,
            efek: data.categoryName,
            jurnalNo: jurnal[0].jurnalNo,
            transactionId: transaction.id
        }
        this.modalEditKonfirmasiRef.current.show(getData)
    }
    showModalAddKonfirmasi(){
        this.modalAddKonfirmasiRef.current.show()
    }
    forceRefresh = () => {
        this.dgRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Penyelesaian Transaksi Pelepasan</h2>

                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI='bank-transactions/penyelesaian-transaksi-penerimaan-list'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Penerimaans"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Penerimaan Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalSettlementKonfirmasiPenerimaan
                    store={this.props.store}
                    ref={this.modalEditKonfirmasiRef}
                    type={'edit'}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementKonfirmasiPenerimaan
                    store={this.props.store}
                    ref={this.modalAddKonfirmasiRef}
                    type={'add'}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PenerimaanPenyelesaianTransaksi