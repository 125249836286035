import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, formatDefaultFullDate } from 'plugin/helper'
import { reportFile } from 'plugin/reportRequest'

class LaporanHasilInvestasi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                productId:1,
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.dataGridRef = React.createRef()

        this.columns = [
            {
                dataField: 'categoryName',
                caption: 'Jenis Investasi',
                calculateCellValue: (e) => {
                    return e.categoryName + ' - ' + e.subName
                }
                // cellRender : (e) => {
                //     var paddingSize = 20 * e.data.level
                //     return <span style={{paddingLeft: `${paddingSize}px`}}>{e.text}</span>
                // }
            },
            {
                dataField: 'hasilInvestasi',
                caption: 'Hasil Investasi Yang Terealisasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bunga',
                        caption: 'Bunga/Bagi Hasil',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'deviden',
                        caption: 'Deviden',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'sewa',
                        caption: 'Sewa',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'realizeGainloss',
                        caption: 'Laba/Rugi Pelepasan',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'lainnya',
                        caption: 'Lainnya',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'unrealizeGainloss',
                        caption: 'Hasil yang belum Teralisasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'bebanInvestasi',
                        caption: 'Beban Investasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'netRealizeGainloss',
                        caption: 'Hasil Investasi Bersih Terealisasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'avgInvestasi',
                        caption: 'Rata-rata Investasi',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'roi',
                        caption: 'ROI',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                ]
            }
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'startDate',
                        label: {
                            text: 'Periode Investasi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'endDate',
                        label: {
                            visible: false
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]

        this.sumHasilInvestasi = [
            {
                showInColumn: 'categoryName',
                displayFormat: 'Total :'
            },
            {
                column: 'bunga',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'bunga',
                displayFormat: '{0}'
            },
            {
                column: 'deviden',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumDeviden',
                displayFormat: '{0}'
            },
            {
                column: 'sewa',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumSewa',
                displayFormat: '{0}'
            },
            {
                column: 'unrealizeGainloss',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'unrealizeGainloss',
                displayFormat: '{0}'
            },
            {
                column: 'lainnya',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumLainnya',
                displayFormat: '{0}'
            },
            {
                column: 'realizeGainloss',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'realizeGainloss',
                displayFormat: '{0}'
            },
            {
                column: 'bebanInvestasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumBebanInvestasi',
                displayFormat: '{0}'
            },
            {
                column: 'netRealizeGainloss',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumHasilInvestBersihReal',
                displayFormat: '{0}'
            },
            {
                column: 'avgInvestasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumRataRataInvest',
                displayFormat: '{0}'
            },
            {
                column: 'roi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumRoi',
                displayFormat: '{0}'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    text: 'Cetak Data',
                    onClick: (e) => {
                        this.cetakData(this.state.filterData.startDate, this.state.filterData.endDate)
                    },
                }
            }
        )
    }

    cetakData =  async(startDate, endDate) => {
        var response = this.dataGridRef.current.getDataSource()

        var transaksiDetail = []
        if(response.data.length > 0){
            var arrBunga = []
            var arrDeviden = []
            var arrSewa = []
            var arrLaba = []
            var arrLainnya = []
            var arrunrealizeGainloss = []
            var arrBebanInvest = []
            var arrnetRealizeGainloss = []
            var arrInvestRata = []
            var arrRoi = []

            for(var value of response.data){
                arrBunga.push(value.bunga)
                arrDeviden.push(value.deviden)
                arrSewa.push(value.sewa)
                arrLaba.push(value.realizeGainloss)
                arrLainnya.push(value.lainnya)
                arrunrealizeGainloss.push(value.unrealizeGainloss)
                arrBebanInvest.push(value.bebanInvestasi)
                arrnetRealizeGainloss.push(value.netRealizeGainloss)
                arrInvestRata.push(value.avgInvestasi)
                arrRoi.push(value.roi)

                var dataResult = {
                    no: 1,
                    categoryName: `${value.categoryName} - ${value.subName}`,
                    bunga: value.bunga,
                    deviden: value.deviden,
                    sewa: value.sewa,
                    realizeGainloss: value.realizeGainloss,
                    lainnya: value.lainnya,
                    unrealizeGainloss: value.unrealizeGainloss,
                    bebanInvestasi: value.bebanInvestasi,
                    netRealizeGainloss: value.netRealizeGainloss,
                    avgInvestasi: value.avgInvestasi,
                    roi: value.roi
                }
                transaksiDetail.push(dataResult)

                var sumBunga = arrBunga.reduce((a, b) => a + b, 0)
                var sumDeviden = arrDeviden.reduce((a, b) => a + b, 0)
                var sumSewa = arrSewa.reduce((a, b) => a + b, 0)
                var sumLaba = arrLaba.reduce((a, b) => a + b, 0)
                var sumLainnya = arrLainnya.reduce((a, b) => a + b, 0)
                var sumunrealizeGainloss = arrunrealizeGainloss.reduce((a, b) => a + b, 0)
                var sumbebanInvestasi = arrBebanInvest.reduce((a, b) => a + b, 0)
                var sumnetRealizeGainloss = arrnetRealizeGainloss.reduce((a, b) => a + b, 0)
                var sumavgInvestasi = arrInvestRata.reduce((a, b) => a + b, 0)
                var sumroi = arrRoi.reduce((a, b) => a + b, 0)
            }

            reportFile({
                template: { 'shortid': 'rhNhepq' },
                data: {
                    "startDate": formatDefaultFullDate(startDate),
                    "endDate": formatDefaultFullDate(endDate),
                    "dailyBalanceDetail": transaksiDetail,
                    // "pengelola": kpdData.noKontrak,
                    "sumBunga": sumBunga,
                    "sumDeviden": sumDeviden,
                    "sumSewa": sumSewa,
                    "sumLaba": sumLaba,
                    "sumLainnya": sumLainnya,
                    "sumunrealizeGainloss": sumunrealizeGainloss,
                    "sumbebanInvestasi": sumbebanInvestasi,
                    "sumnetRealizeGainloss": sumnetRealizeGainloss,
                    "sumavgInvestasi": sumavgInvestasi,
                    "sumroi": sumroi
                },
                options: {
                    reportName: "Hasil Invetasi"
                }
            }, true, `Hasil Invetasi.pdf`)
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Hasil Investasi</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`get-investasi-hasil-report?endDate=${formatDate(this.state.filterData.endDate)}&productId=${this.state.filterData.productId}&startDate=${formatDate(this.state.filterData.startDate)}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                    // useArraySource={true}
                    // ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Hasil Investasi"}
                    allowExportSelectedData={true}
                    selection={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumHasilInvestasi}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanHasilInvestasi