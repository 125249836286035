import React, { Component } from 'react'
import {Popup,Form} from 'devextreme-react'
import { currency } from 'dataSource/lookup'

class ModalConfirmKirim extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster: {}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Kirim',
                    onClick: this.kirim.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.DataMaster = [
            {
                dataField:"invoiceNo",
                label:{
                    text:"No Invoice"
                },
                editorOptions:{
                    readOnly:true,
                }
            },
            {
                dataField:"noPengajuan",
                label:{
                    text:"No Pengajuan"
                },
                editorOptions:{
                    readOnly:true,
                }
            },
            {
                dataField:"currencyId",
                label:{
                    text:"Currency"
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    readOnly:true,
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode', // provides display values
                    searchEnabled: true,
                    deferRendering: false
                }
            },
            {
                dataField:"currencyRate",
                label:{
                    text:"Kurs"
                },
                editorOptions:{
                    readOnly:true,
                }
            },
        ]
    }

    retrieveData = (data) => {
        data.noPengajuan = this.props.noPengajuan
        this.setState({
            DataMaster:data
        })
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster: {}
        })
    }

    kirim = async() => {
        var DataMaster = this.state.DataMaster
        var data = {
            currencyRate:DataMaster.currencyRate,
            pembayaranUmumDetilId:DataMaster.currencyRate,
            pembayaranUmumId:DataMaster.currencyRate,
            pembayaranUmumInvoiceId:DataMaster.currencyRate,
        }
        console.log(this.state.DataMaster);
         try {
        //     var loadAPI = 'pembayaran-umum-aktif-arap-invoice'
        //     var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
        //         values: dataMaster,
        //     })

        //     if (response) {
        //         await alert('Sukses Kirim Data','Berhasil')
        //         this.hide()
        //     }

         } catch (e) {
             console.log();
                await alert(e,'Gagal')
         }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Pembayaraan Uang Muka'}
                width={550}
                height={400}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                <Form
                    ref={this.formMasterRef}
                    colCount={1}
                    id={'formDataMaster'}
                    formData={this.state.DataMaster}
                    items={this.DataMaster}
                    labelLocation = 'left'
                />
            </Popup>
        )
    }
}

export default ModalConfirmKirim