import {
    GET_PARAM
  } from '../actions/gblparam'
  
  function getParam(state=[], action){
    switch(action.type){
      case GET_PARAM:
        return action.value;
      default: 
        return state
    }
  }
  
  export default getParam