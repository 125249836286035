import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import { instrumentCategories, instruments, productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate, getSystemDate } from 'plugin/helper';

class BalancePositionResume extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.state = {
            dataMaster: {},
            filterData: {
                productId: 1,
                reportDate: formatDate(this.date)
            },
        }
        this.formRef = React.createRef()
        this.dataSource = []
        this.filterItem = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                            alignment: "left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            width: '100%',
                            onValueChanged: (data) => {
                            }
                        }
                    },
                    {
                        dataField: 'filter',
                        label: {
                            visible: false,
                        },
                        editorType: 'dxButton',
                        editorOptions: {
                            text: 'Filter',
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: (e) => {
                                // let reportDate = this.formRef.current.instance.getEditor('reportDate').option('value');
                                this.setState({
                                    filterData: this.state.filterData,
                                    // reportDate: reportDate
                                })
                                this.loadData(this.state.filterData.productId)
                                // this.dataGridRef.current.forceRefresh(true)
                            }
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                colSpan: 2,
                items: [
                    {
                        dataField: 'reportDate',
                        label: {
                            text: 'Tanggal Laporan',
                            alignment: "left",
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            width: '51%',
                            readOnly: false
                        },
                    },
                    {
                        dataField: 'totalPenempatanInvest',
                        label: {
                            text: 'Total Penempatan Investasi',
                            alignment: "left",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            verticalAlignment: 'right',
                            format: '#,##0.00',
                            width: '51%',
                            readOnly: true,
                            inputAttr: {
                                style: 'text-align: right;'
                            }
                        },
                    },
                    {
                        dataField: 'totalNilaiInvestasi',
                        label: {
                            alignment: 'left',
                            text: 'Total Nilai Investasi'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            width: '51%',
                            readOnly: true,
                            inputAttr: {
                                style: 'text-align: right;'
                            }
                        },
                    },
                    {
                        dataField: 'pertumbuhan',
                        label: {
                            alignment: 'left',
                            text: 'Pertumbuhan (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            width: '51%',
                            readOnly: true,
                            inputAttr: {
                                style: 'text-align: right;'
                            }
                        }
                    }
                ]
            },

        ]

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'id'
            },
            {
                name: 'nilaiPenempatan',
                column: 'nilaiPenempatan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'accuredInterest',
                column: 'accuredInterest',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'unralizedNominal',
                column: 'unralizedNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiSekarang',
                column: 'nilaiSekarang',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'komposisi',
                column: 'komposisi',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            // {
            //     dataField: 'id',
            //     caption: 'ID',
            //     alignment: "right",
            // },
            {
                dataField: 'no',
                caption: 'No',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'categoryName',
                caption: 'Jenis Instrument',
            },
            {
                dataField: 'nilaiPenempatan',
                caption: 'Nilai Penempatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'valuasiTerakhir',
                caption: 'Tanggal Valuasi Berakhir',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'accruedInterest',
                caption: 'Bunga Berjalan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'unrealizedPersen',
                caption: 'Unrealized P/L(%)',
                alignment: 'right',
                dataType: 'number',
                // format: '#,##0.0000'
                format: '#,##0.00',
            },
            {
                dataField: 'unrealizedNominal',
                caption: 'Unrealized P/L Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiSekarang',
                caption: 'Nilai Saat Ini',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'komposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                // format: '#,##0.0000'
            },
        ]

        this.dataGridRef = React.createRef()
    }

    componentDidMount() {
        this.loadData(this.state.filterData.productId)
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    loadData = async (productId) => {
        let dataProduct = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `products/${productId}`, 'GET')
        if (dataProduct) {
            // var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-report-resume-portofolios?productCode.in=${dataProduct.productCode}`, 'GET')
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avg-dailies/resume-portofolio/${dataProduct.productCode}/${this.state.filterData.reportDate}`);

            if (response.length > 0) {
                this.formRef.current.instance.updateData({
                    // reportDate: getSystemDate(this.props.store),
                    reportDate: this.state.filterData.reportDate,
                    totalPenempatanInvest: response[0].nilaiPenempatan || 0,
                    totalNilaiInvestasi: response[0].nilaiSekarang || 0,
                    pertumbuhan: response[0].persenPertumbuhan || 0,
                })
            } else {
                this.formRef.current.instance.updateData({
                    // reportDate: getSystemDate(this.props.store),
                    reportDate: this.state.filterData.reportDate,
                    totalPenempatanInvest: 0,
                    totalNilaiInvestasi: 0,
                    pertumbuhan: 0,
                })
            }
        }

        let responseDetail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avg-dailies/resume-portofolio-swakelola/${this.state.filterData.productId}/${this.state.filterData.reportDate}`);

        // if (responseDetail.length !== 0) {
            // this.dataSource = responseDetail
            let dataArr = [];
            let counter = 1;

            for (let value of responseDetail) {
                let dataValue = {
                    ...value,
                    no: counter++
                }
                dataArr.push(dataValue)
            }

            dataArr.sort((a, b) => a.no - b.no);

            this.dataSource = dataArr;

            // this.dataGridRef.current.forceRefresh(true);
            // return this.dataSource
        // }
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <h3 className="main-title">Resume Portfolio Produk Dana Pensiun</h3>
                    <div className='row'>
                        <div className="col-md-6">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formFilter'}
                                formData={this.state.filterData}
                                items={this.filterItem}
                                labelLocation="left"
                            />
                        </div>
                        <div className="mt-2 col-md-12">
                            <DevExpressDataGrid
                                // ref={this.dataGridRef}
                                // loadAPI={`view-report-resume-portofolio-dtls?productId.in=${this.state.filterData.productId}`}
                                loadAPI={`efek-balance-avg-dailies/resume-portofolio-swakelola/${this.state.filterData.productId}/${this.state.filterData.reportDate}`}
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                useArraySource={true}
                                ArraySourceData={this.dataSource}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Resume Portofolio"}
                                allowExportSelectedData={true}
                                exportWithFormatNum={true}
                                exceptFieldExportFormtNum={
                                    [
                                        'nilaiPenempatan',
                                        'accruedInterest',
                                        'unrealizedPersen',
                                        'unrealizedNominal',
                                        'nilaiSekarang',
                                        'komposisi'
                                    ]
                                }

                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Resume Portofolio'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                summaryTotalItem={this.summary}
                                // onToolbarPreparing = {this.onToolbarPreparing}

                                height={'calc(100vh - 370px)'}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default BalancePositionResume
