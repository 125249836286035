import React, { Component } from 'react'
import DevExpressDataGrid from '../../../../inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';

class Rekonsiliasi extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                dataField: 'dataKewajiban',
                caption: 'Data Kewajiban',
            },
            {
                dataField: 'dataAssetPendanaan',
                caption: 'Data Aset Pendanaan',
            },
            {
                dataField: 'dataIuran',
                caption: 'Data Iuran',
            },
            {
                dataField: 'dataDaftarPeserta',
                caption: 'Data Daftar Peserta',
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Rekonsiliasi</h2>

                <DevExpressDataGrid
                    loadAPI='rekonsiliasis'
                    insertAPI='rekonsiliasis'
                    updateAPI='rekonsiliasis'
                    deleteAPI='rekonsiliasis'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Rekonsiliasi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Rekonsiliasi'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default Rekonsiliasi
