import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { banks, instrumentSubCategoryDeposito, currency, instruments, fundManager, rekeningBank, provinces, cities, kjpp, instrumentsCategoryProperti } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import { alert } from 'devextreme/ui/dialog';


class FormSubInstrumentPenempatanProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {}
        }

        this.prevTabIndex = 0
        this.productId = 0
        this.instSubCategoryId = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No. Pengajuan'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.instrumentCode + " - " + item.instrumentName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: (e) => {
                        var slctd = e.selectedItem
                        this.instSubCategoryId = slctd.categorySubId
                        if (slctd) {
                            this.setState({
                                dataDetail: slctd
                            })
                        }
                    }
                }
            },
            {
                dataField: 'qtyOrder',
                label: {
                    text: 'Jumlah Unit/Kav/Tipe',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                    step: 0,
                }
            },
            {
                dataField: 'jenisHarga',
                label: {
                    text: 'Jenis Harga'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    items: ['Tetap', 'Antara'],
                    layout: 'horizontal',
                }
            },
            {
                dataField: 'hargaMinimum',
                label: {
                    text: 'Harga Minimum',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'hargaMaksimum',
                label: {
                    text: 'Harga Maksimum',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'fundManagerId',
                label: {
                    text: 'Fund Manager',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fundManagerName',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'agenPenjualId',
                label: {
                    text: 'Agen Penjual',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'market',
                label: {
                    text: 'Pasar'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    items: ['Primer', 'Sekunder'],
                    layout: 'horizontal',
                }
            },
            {
                dataField: 'penjualEntitasId',
                label: {
                    text: 'Pemilik/Penjual',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'pengembangEntitasId',
                label: {
                    text: 'Pengembang',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Silahkan Pilih Pengembang"
                    }
                ]
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Mata uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    onSelectionChanged: (e) => {
                        var slctd = e.selectedItem
                        if (slctd) {
                            this.formMasterRef.current.instance.updateData({ kurs: slctd.rate })
                        }
                    },
                    searchEnabled: true,
                },
            },
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'rekBankId',
                label: {
                    text: 'Rekening Pembayaran',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                    searchEnabled: true,
                },
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan',
                },
            }
        ]
        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI PROPERTI',
                items: [
                    {
                        dataField: 'instrumentCode',
                        label: {
                            text: 'Kode Properti'
                        },
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            text: 'Nama Properti'
                        },
                    },
                    {
                        dataField: 'categorySubId',
                        label: {
                            text: 'Jenis Properti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'subName'
                        }
                    },
                    {
                        dataField: 'lokasi',
                        label: {
                            text: 'Lokasi'
                        },
                    },
                    // {
                    //     dataField: 'provinsiId',
                    //     label: {
                    //         text: 'Provinsi'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: provinces(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'provinceName'
                    //     }
                    // },
                    // {
                    //     dataField: 'cityId',
                    //     label: {
                    //         text: 'Kota'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: cities(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'cityName'
                    //     }
                    // },
                    // {
                    //     dataField: 'kjppId',
                    //     label: {
                    //         text: 'KJPP'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: kjpp(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'kjppName'
                    //     }
                    // },
                    // {
                    //     dataField: 'rekBayarId',
                    //     label: {
                    //         text: 'Rekening Bayar'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: rekeningBank(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: (e) => {
                    //             return e && e.rekNo + ' - ' + e.rekName
                    //         }
                    //     }
                    // },
                    // {
                    //     dataField: 'pemilikId',
                    //     label: {
                    //         text: 'Pemilik'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: [],
                    //         valueExpr: 'id',
                    //         displayExpr: 'value'
                    //     }
                    // },
                    // {
                    //     dataField: 'sertifikatTypeId',
                    //     label: {
                    //         text: 'Jenis Sertifikat'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: [],
                    //         valueExpr: 'id',
                    //         displayExpr: 'value'
                    //     }
                    // },
                    // {
                    //     dataField: 'hargaPasar',
                    //     label: {
                    //         text: 'Harga Pasar'
                    //     },
                    // },
                    // {
                    //     dataField: 'capRate',
                    //     label: {
                    //         text: 'Cap Rate'
                    //     },
                    // },
                    // {
                    //     dataField: 'taksiranHargaSewa',
                    //     label: {
                    //         text: 'Taksiran Harga Sewa'
                    //     },
                    // },
                    // {
                    //     dataField: 'taksiranClosingCost',
                    //     label: {
                    //         text: 'Taksiran Closing Cost'
                    //     },
                    // },
                    // {
                    //     dataField: 'taksiranMaintenanceCost',
                    //     label: {
                    //         text: 'Taksiran Maintenance Cost'
                    //     },
                    // },
                    {
                        dataField: 'luasTanah',
                        label: {
                            text: 'Luas Tanah'
                        },
                    },
                    {
                        dataField: 'luasBangunan',
                        label: {
                            text: 'Luas Bangunan'
                        },
                    },
                ]
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = (data) => {
        if(data){
            this.productId = data.productId
            this.insertLookup()
    
            this.setState({
                data: {
                    id: data.id,
                    noPengajuan: data.noPengajuan,
                    agenPenjualId: data.agenPenjualId,
                    currencyId: data.currencyId,
                    fee: data.fee,
                    fundManagerId: data.fundManagerId,
                    hargaMaksimum: data.hargaMaksimum,
                    hargaMinimum: data.hargaMinimum,
                    instrumentId: data.instrumentId,
                    jenisHarga: data.jenisHarga,
                    keterangan: data.keterangan,
                    kurs: data.kurs,
                    nominalFee: data.nominalFee,
                    pengembangEntitasId: data.pengembangEntitasId,
                    penjualEntitasId: data.penjualEntitasId,
                    qtyOrder: data.qtyOrder,
                    rekBankId: data.rekBankId,
                },
                dataDetail: {
                },
            });
        }
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    hide() {
        this.productId = 0
        this.instSubCategoryId = 0
        this.setState({
            popupVisible: false,
        })
    }

    insertLookup = async () => {
        var getPenjual = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllByType/PJ`, 'GET')
        var getAgen = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllByType/AP`, 'GET')
        var getPengembang = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllByType/PG`, 'GET')
        var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments?categorySubId.in=34&categorySubId.in=35&categorySubId.in=36&categorySubId.in=37&categorySubId.in=38&categorySubId.in=39`, 'GET')
        var getSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-category-subs?instrumentCategoryId.in=8`, 'GET')

        let dataPenjual = new ArrayStore({
            data: getPenjual,
            key: 'id'
        });
        let dataAgen = new ArrayStore({
            data: getAgen,
            key: 'id'
        });
        let dataPengembang = new ArrayStore({
            data: getPengembang,
            key: 'id'
        });
        let dataInstrument = new ArrayStore({
            data: getInstrument,
            key: 'id'
        });
        let dataSub = new ArrayStore({
            data: getSubCategory,
            key: 'id'
        });

        this.formMasterRef.current.instance.getEditor('agenPenjualId').option('dataSource', dataAgen)
        this.formMasterRef.current.instance.getEditor('penjualEntitasId').option('dataSource', dataPenjual)
        this.formMasterRef.current.instance.getEditor('pengembangEntitasId').option('dataSource', dataPengembang)
        this.formMasterRef.current.instance.getEditor('instrumentId').option('dataSource', dataInstrument)
        this.formDetailRef.current.instance.getEditor('categorySubId').option('dataSource', dataSub)
    }

    submitData = async () => {
        var data = this.state.data
        let validateBatasan = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-validasi-pengajuan-inv/${this.productId}/${this.instSubCategoryId}/${data.hargaMaksimum}`, 'GET')
        if (validateBatasan.length > 0) {
            if (validateBatasan[0].potensiselisih >= 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")

                this.props.submitDetailPenempatanData({
                    id: data.id,
                    agenPenjualId: data.agenPenjualId,
                    currencyId: data.currencyId,
                    fee: 0,
                    fundManagerId: data.fundManagerId,
                    hargaMaksimum: data.hargaMaksimum,
                    hargaMinimum: data.hargaMinimum,
                    instrumentId: data.instrumentId,
                    jenisHarga: data.jenisHarga,
                    keterangan: data.keterangan,
                    kurs: data.kurs,
                    nominalFee: 0,
                    pengembangEntitasId: data.pengembangEntitasId,
                    penjualEntitasId: data.penjualEntitasId,
                    qtyOrder: data.qtyOrder,
                    rekBankId: data.rekBankId,
                })

                this.hide()
            } else if (validateBatasan[0].potensiselisih < 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")
            } else {
                await alert("Terdapat Kesalahan Data", "Informasi")
            }
        } else {
            await alert("Batasan Resiko Belum di Setting", "Informasi")
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan ${this.props.action == 'penempatan' ? 'Penempatan' : 'Pelepasan'} Investasi Properti`}
                minWidth={'70vw'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.data}
                                        items={this.formItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={this.props.type == 'view' ? true : false}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster1'}
                                        ref={this.formDetailRef}
                                        formData={this.state.dataDetail}
                                        items={this.formDetailItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormSubInstrumentPenempatanProperti