import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { instrumentCategories, productDefault } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';

class ModalEditIndikasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.newData = true

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.updateData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        // this.dataMaster = [
        //     {
        //         dataField: 'id',
        //         label: {
        //             text: 'ID'
        //         },
        //         editorOptions: {
        //             readOnly: true,
        //         }
        //     },
        //     {
        //         dataField: 'risikoKredit',
        //         label: {
        //             text: 'Risiko Kredit',
        //         },
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //             step: 0,
        //             onValueChanged: (e) => {
        //                 const rk = e.value
        //                 const rp = this.formRef.current.instance.getEditor('risikoPasar').option('value')
        //                 const ro = this.formRef.current.instance.getEditor('risikoOperasional').option('value')
        //                 const rl = this.formRef.current.instance.getEditor('risikoLikuiditas').option('value')
        //                 this.sumRisiko(rk, rp, ro, rl)
        //             }
        //         }
        //     },
        //     {
        //         dataField: 'risikoPasar',
        //         label: {
        //             text: 'Risiko Pasar',
        //         },
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //             step: 0,
        //             onValueChanged: (e) => {
        //                 const rk = this.formRef.current.instance.getEditor('risikoKredit').option('value')
        //                 const rp = e.value
        //                 const ro = this.formRef.current.instance.getEditor('risikoOperasional').option('value')
        //                 const rl = this.formRef.current.instance.getEditor('risikoLikuiditas').option('value')
        //                 this.sumRisiko(rk, rp, ro, rl)
        //             }
        //         }
        //     },
        //     {
        //         dataField: 'risikoOperasional',
        //         label: {
        //             text: 'Risiko Operasional',
        //         },
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //             step: 0,
        //             onValueChanged: (e) => {
        //                 const rk = this.formRef.current.instance.getEditor('risikoKredit').option('value')
        //                 const rp = this.formRef.current.instance.getEditor('risikoPasar').option('value')
        //                 const ro = e.value
        //                 const rl = this.formRef.current.instance.getEditor('risikoLikuiditas').option('value')
        //                 this.sumRisiko(rk, rp, ro, rl)
        //             }
        //         }
        //     },
        //     {
        //         dataField: 'risikoLikuiditas',
        //         label: {
        //             text: 'Risiko Likuiditas',
        //         },
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //             step: 0,
        //             onValueChanged: (e) => {
        //                 const rk = this.formRef.current.instance.getEditor('risikoKredit').option('value')
        //                 const rp = this.formRef.current.instance.getEditor('risikoPasar').option('value')
        //                 const ro = this.formRef.current.instance.getEditor('risikoOperasional').option('value')
        //                 const rl = e.value
        //                 this.sumRisiko(rk, rp, ro, rl)
        //             }
        //         }
        //     },
        //     {
        //         itemType: 'empty'
        //     },
        //     {
        //         dataField: 'total',
        //         label: {
        //             text: 'Total',
        //         },
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //             step: 0,
        //         }
        //     },
        //     {
        //         dataField: 'pengurang',
        //         label: {
        //             text: 'Pengurang',
        //         },
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //             step: 0,
        //         }
        //     },
        //     {
        //         dataField: 'risikoKelas',
        //         label: {
        //             text: 'Risiko Kelas',
        //         },
        //         editorType: 'dxNumberBox',
        //         editorOptions: {
        //             format: '#,##0.00',
        //             step: 0,
        //         }
        //     },
        // ]

        this.dataMaster = [
            {
                itemType: 'group',
                caption: "KATEGORI",
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        dataField: 'categorySubId',
                        label: {
                            text: 'Sub Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentCategories(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'RESIKO',
                        items: [
                            {
                                dataField: 'resikoKredit',
                                label: {
                                    text: 'Resiko Kredit',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    onValueChanged: (e) => {
                                        const rk = e.value
                                        const rp = this.formRef.current.instance.getEditor('resikoPasar').option('value')
                                        const ro = this.formRef.current.instance.getEditor('resikoOperasional').option('value')
                                        const rl = this.formRef.current.instance.getEditor('resikoLiquiditas').option('value')
                                        this.sumRisiko(rk, rp, ro, rl)
                                    }
                                },
                            },
                            {
                                dataField: 'resikoPasar',
                                label: {
                                    text: 'Resiko Pasar',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    onValueChanged: (e) => {
                                        const rk = this.formRef.current.instance.getEditor('resikoKredit').option('value')
                                        const rp = e.value
                                        const ro = this.formRef.current.instance.getEditor('resikoOperasional').option('value')
                                        const rl = this.formRef.current.instance.getEditor('resikoLiquiditas').option('value')
                                        this.sumRisiko(rk, rp, ro, rl)
                                    }
                                },
                            },
                            {
                                dataField: 'resikoOperasional',
                                label: {
                                    text: 'Resiko Operasional',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    onValueChanged: (e) => {
                                        const rk = this.formRef.current.instance.getEditor('resikoKredit').option('value')
                                        const rp = this.formRef.current.instance.getEditor('resikoPasar').option('value')
                                        const ro = e.value
                                        const rl = this.formRef.current.instance.getEditor('resikoLiquiditas').option('value')
                                        this.sumRisiko(rk, rp, ro, rl)
                                    }
                                },
                            },
                            {
                                dataField: 'resikoLiquiditas',
                                label: {
                                    text: 'Resiko Liquiditas',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    onValueChanged: (e) => {
                                        const rk = this.formRef.current.instance.getEditor('resikoKredit').option('value')
                                        const rp = this.formRef.current.instance.getEditor('resikoPasar').option('value')
                                        const ro = this.formRef.current.instance.getEditor('resikoOperasional').option('value')
                                        const rl = e.value
                                        this.sumRisiko(rk, rp, ro, rl)
                                    }
                                },
                            },
                            {
                                dataField: 'resikoTotal',
                                label: {
                                    text: 'Total',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                },
                            },
                            {
                                dataField: 'resikoPengurang',
                                label: {
                                    text: 'Pengurang',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                },
                            },
                            {
                                dataField: 'resikoKelas',
                                label: {
                                    text: 'Resiko Kelas',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                },
                            },
                        ]
                    },
                ]
            },

        ]
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.newData = true
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            dataMaster: data,
        })
    }

    sumRisiko = (rk, rp, ro, rl) => {
        var result = rk + rp + ro + rl
        this.formRef.current.instance.updateData('resikoTotal', result)
    }

    submitData = async () => {
        let data = this.state.dataMaster
        data.kebijakanSpesifikasi = 4
        try {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-values`, 'POST', {
                values: data
            })

            if (response) {
                notify({ message: "Data Sukses di simpan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                this.props.forceRefresh()
                this.hide()
            }
        } catch (e) {
            console.log(e);
            notify({ message: "Terjadi kesalahan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }

    updateData = async () => {
        let data = this.state.dataMaster
        data.kebijakanSpesifikasi = 4
        try {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-values`, 'PUT', {
                values: data,
                key: data.id
            }, data)

            if (response) {
                notify({ message: "Data Sukses di simpan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                this.props.forceRefresh()
                this.hide()
            }
        } catch (e) {
            console.log(e);
            notify({ message: "Terjadi kesalahan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'30vw'}
                    height="60vh"
                    title={`Form Indikasi Resiko`}
                    ref={this.PopupRef}
                    toolbarItems={this.newData ? this.popupToolbarItem : this.popupToolbarItem2}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <Form
                                        ref={this.formRef}
                                        colCount={1}
                                        id={'dataMaster'}
                                        formData={this.state.dataMaster}
                                        items={this.dataMaster}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalEditIndikasi