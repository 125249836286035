import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, fundManager, emitens, currency, reksadana, rekeningMi, availableInstrumentRd, rekeningBank, rekBankByProduct, instrumentCategorySubs } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";


class ModalInstrumentReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.formItem = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    // dataSource: reksadana(this.props.store),
                    dataSource: availableInstrumentRd(this.props.store, 1),
                    valueExpr: 'instrumentId',
                    // displayExpr: 'instrumentName',
                    displayExpr: function (item) {
                        return item && item.instrumentCode + " - " + item.instrumentName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: async (e) => {
                        var data = e.selectedItem

                        if (data) {
                            var dataEfek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-instrumentId/${data.instrumentId}`)
                            this.formDetailRef.current.instance.updateData('unit', dataEfek[0].quantity)
                            this.formDetailRef.current.instance.updateData('nabPerolehan', data.avgPrice)
                            this.formDetailRef.current.instance.updateData('nilaiPerolehan', dataEfek[0].quantity * data.avgPrice)
                            this.formMasterRef.current.instance.getEditor('currencyId').option('value', data.currencyId)
                        }
                    },
                    onValueChanged: async (e) => {
                        var val = e.value
                        if (val) {
                            var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${val}`)
                            this.formDetailRef.current.instance.updateData('nabTerakhir', instrument.price)
                            this.formDetailRef.current.instance.getEditor('miId').option('value', instrument.managerInvestasiId)
                            this.formDetailRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)
                            this.formDetailRef.current.instance.getEditor('kategoriReksadana').option('value', instrument.categorySubId)
                            this.formDetailRef.current.instance.getEditor('rate').option('value', instrument.rating1Id )
                            this.formDetailRef.current.instance.getEditor('isinCode').option('value', instrument.isinCode)
                            this.formDetailRef.current.instance.updateData('priceDate', instrument.priceDate)
                        }
                        // var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`emitens/${instrument.emitenId}`)
                        // this.formDetailRef.current.instance.updateData('emiten',emiten.emitenName)
                        // this.formDetailRef.current.instance.updateData('hargaPenutupan',instrument.price)
                        // this.formDetailRef.current.instance.updateData('tanggalHargaPenutupan',instrument.priceDate)
                    }
                },
            },
            {
                dataField: 'portofolioPelepasan',
                label: {
                    text: 'Portofolio Pelepasan'
                },
                editorType: "dxRadioGroup",
                editorOptions: {
                    layout: 'horizontal',
                    items: ['Semua', 'Sebagian'],
                    onValueChanged: async (e) => {
                        // var val = e.value
                    }
                },
            },
            {
                dataField: 'useMethod',
                label: {
                    text: 'Pelepasan Berdasarkan'
                },
                editorType: "dxRadioGroup",
                editorOptions: {
                    layout: 'horizontal',
                    items: ['Unit %', 'Nominal'],
                    onValueChanged: async (e) => {
                        var val = e.value
                        var porto = this.formMasterRef.current.instance.getEditor('portofolioPelepasan').option('value')
                        var onHandUnit = this.formDetailRef.current.instance.getEditor('unit').option('value')
                        var lastNab = this.formDetailRef.current.instance.getEditor('nabTerakhir').option('value')
                        var persent = this.formMasterRef.current.instance.getEditor('persentasePelepasan')
                        var nominal = this.formMasterRef.current.instance.getEditor('nominalPelepasan')
                        if (val) {
                            if (porto == 'Semua') {
                                persent.option('value', 100)
                                nominal.option('value', onHandUnit * lastNab)
                                persent.option('readOnly', true)
                                nominal.option('readOnly', true)
                            } else if (val == 'Unit %' && porto == 'Sebagian') {
                                persent.option('value', 0)
                                persent.option('readOnly', false)
                                nominal.option('value', 0)
                                nominal.option('readOnly', true)
                            } else if (val == 'Nominal' && porto == 'Sebagian') {
                                persent.option('value', 0)
                                persent.option('readOnly', true)
                                nominal.option('value', 0)
                                nominal.option('readOnly', false)
                            }
                        }
                    }
                },
            },
            {
                dataField: 'persentasePelepasan',
                label: {
                    text: 'Persentase Pelepasan'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    step: 0,
                    onValueChanged: (e) => {
                        var val = e.value
                        var method = this.formMasterRef.current.instance.getEditor('useMethod').option('value')
                        var onHandUnit = this.formDetailRef.current.instance.getEditor('unit').option('value')
                        var lastNab = this.formDetailRef.current.instance.getEditor('nabTerakhir').option('value')
                        var nominal = this.formMasterRef.current.instance.getEditor('nominalPelepasan')
                        if (val) {
                            if (method == 'Unit %') {
                                nominal.option('value', (val / 100) * (onHandUnit * lastNab))
                            }
                        }
                    }
                },
            },
            {
                dataField: 'nominalPelepasan',
                label: {
                    text: 'Nominal Pelepasan'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    step: 0,
                    onValueChanged: (e) => {
                        var val = e.value
                        var method = this.formMasterRef.current.instance.getEditor('useMethod').option('value')
                        var onHandUnit = this.formDetailRef.current.instance.getEditor('unit').option('value')
                        var lastNab = this.formDetailRef.current.instance.getEditor('nabTerakhir').option('value')
                        var persent = this.formMasterRef.current.instance.getEditor('persentasePelepasan')
                        if (this.props.type !== 'edit') {
                            if (val) {
                                if (method == 'Nominal') {
                                    persent.option('value', val / (onHandUnit * lastNab) * 100)
                                }
                            }
                        }
                    }
                },
            },
            {
                dataField: 'feePercent',
                label: {
                    text: 'Fee (%)'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    onValueChanged: (e) => {
                        var val = e.value
                        var nomPelepasan = this.formMasterRef.current.instance.getEditor('nominalPelepasan').option('value')
                        if (val) {
                            this.formMasterRef.current.instance.getEditor('fee').option('value', (val / 100) * nomPelepasan)
                        }
                    }
                },
            },
            {
                dataField: 'fee',
                label: {
                    text: 'Fee Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly: true
                },
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Mata Uang'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true,
                    onSelectionChanged: (e) => {
                        var data = e.selectedItem
                        if (data) {
                            this.formMasterRef.current.instance.updateData('kurs', data.rate)
                        }
                    }
                },
            },
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly: true
                },
            },
            {
                dataField: 'rekMiId',
                label: {
                    text: 'Rekening Penerimaan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                    searchEnabled: true,
                }
            },
        ]

        this.formDetailItem = [
            {
                itemType: 'group',
                name: 'information',
                caption: 'Informasi Reksadana',
                colCount: 1,
                colSpan: 2,
                items: [
                    {
                        dataField: 'miId',
                        label: {
                            text: 'Penerbit Reksadana'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'miName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'kategoriReksadana',
                        label: {
                            text: 'Kategori Reksadana'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentCategorySubs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: "dxSelectBox",
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'rate',
                        label: {
                            text: 'Rating'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'isinCode',
                        label: {
                            text: 'ISIN CODE'
                        },
                    },
                    {
                        dataField: 'nabTerakhir',
                        label: {
                            text: 'NAB Terakhir'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'priceDate',
                        label: {
                            text: 'Tanggal Harga Terakhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                        }
                    },
                    // {
                    //     dataField: 'aum',
                    //     label: {
                    //         text: 'AUM'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: "#,##0.00",
                    //         readOnly: true
                    //     },
                    // },
                    // {
                    //     dataField: 'unitBeredar',
                    //     label: {
                    //         text: 'Unit Beredar'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: "#,##0.0000",
                    //         readOnly: true
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja1bulan',
                    //     label: {
                    //         text: 'Kinerja 1 Bulan'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: "#,##0.00 %",
                    //         readOnly: true
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja1tahun',
                    //     label: {
                    //         text: 'Kinerja 1 Tahun'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: "#,##0.00 %",
                    //         readOnly: true
                    //     },
                    // },
                    // {
                    //     dataField: 'kinerja2tahun',
                    //     label: {
                    //         text: 'Kinerja 2 Tahun'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: "#,##0.00 %",
                    //         readOnly: true
                    //     },
                    // },
                ],
            },
            {
                itemType: 'group',
                name: 'portofolio',
                caption: 'Informasi Portofolio',
                colCount: 1,
                items: [
                    {
                        dataField: 'unit',
                        label: {
                            text: 'Jumlah Unit'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.0000",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nabPerolehan',
                        label: {
                            text: 'NAB Perolehan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.0000",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nilaiPerolehan',
                        label: {
                            text: 'Nilai Perolehan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    // {
                    //     dataField: 'keuntungan',
                    //     label: {
                    //         text: 'Nilai Keuntungan'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: "#,##0.00",
                    //         readOnly: true
                    //     },
                    // },
                    // {
                    //     dataField: 'persentaseKeuntungan',
                    //     label: {
                    //         text: 'keuntungan (%)'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: "#,##0.00",
                    //         readOnly: true
                    //     },
                    // },
                ]
            },
            // {
            //     itemType: 'group',
            //     name: 'lastPrice',
            //     caption: 'Informasi Harga Terakhir',
            //     colCount: 1,
            //     items: [
            //         {
            //             dataField: 'nabPeluncuran',
            //             label: {
            //                 text: 'NAB Peluncuran'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: "#,##0.0000",
            //                 readOnly: true
            //             },
            //         },
            //         {
            //             dataField: 'nabTertinggi',
            //             label: {
            //                 text: 'NAB Tertinggi'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: "#,##0.0000",
            //                 readOnly: true
            //             },
            //         },
            //         {
            //             dataField: 'nabTerakhir',
            //             label: {
            //                 text: 'NAB Terakhir'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 format: "#,##0.0000",
            //                 readOnly: true
            //             },
            //         },
            //     ]
            // },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = (data) => {
        console.log(data)
        if(data){
            this.setState({
                data: {
                    id: data.id,
                    noPengajuan: data.noPengajuan,
                    instrumentId: data.instrumentId,
                    portofolioPelepasan: data.portofolioPelepasan,
                    persentasePelepasan: data.unitPersen,
                    nominalPelepasan: data.amountVal,
                    feePercent: data.unitPersen,
                    fee: data.fee,
                    kurs: data.kurs,
                    currencyId: data.currencyId,
                    rekMiId: data.rekMiId,
                    totalPelepasan: data.totalPelepasan,
                    productId: data.productId,
                    useMethod: data.redeemUnitType == 1 ? 'Nominal' : 'Unit %'
                },
                dataDetail: {
                    unit: data.unitPorto,
                    nabPerolehan: data.nabPerolehan,
                    nilaiPerolehan: data.nilaiPerolehan,
                    nabTerakhir: data.nabTerakhir,
                    nominalPelepasan: data.amountVal,
                    rate: data.rate,
                    priceDate: data.priceDate,
                    isinCode: data.isinCode,
                    miId: data.miId,
                },
            });

            console.log(this.state.data);
    
            var selectInstrumentId = this.formMasterRef.current.instance.getEditor('instrumentId')
            var selectRekId = this.formMasterRef.current.instance.getEditor('rekMiId')
    
            console.log(selectInstrumentId);
            selectInstrumentId.option('dataSource', availableInstrumentRd(this.props.store, this.state.data.productId))
            selectRekId.option('dataSource', rekBankByProduct(this.props.store, this.state.data.productId))
        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.data
        var detail = this.state.dataDetail
        if (data.persentasePelepasan > 100) {
            notify({ message: 'Kuantitas melebihi yang tersedia di portofolio', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1500);
        } else {
            this.props.submitDetailPelepasanData({
                id: data.id,
                instrumentId: data.instrumentId,
                productId: data.productId,
                portofolioPelepasan: data.portofolioPelepasan,
                presentaseVal: data.persentasePelepasan,
                amountVal: data.nominalPelepasan,
                feePercent: data.feePercent,
                unitPersen: data.feePercent,
                fee: data.fee,
                kurs: data.kurs,
                currencyId: data.currencyId,
                rekMiId: data.rekMiId,
                totalPelepasan: data.nominalPelepasan - data.fee,
                redeemUnitType: data.useMethod == 'Unit %' ? 0 : 1,
                unitOrder: detail.unit,
                nabPerolehan: detail.nabPerolehan,
                nilaiPerolehan: detail.nilaiPerolehan,
                nabTerakhir: detail.nabTerakhir,
                miId: detail.miId,
                kpdId: 1,
                unit: detail.unit
            })

            this.hide()
        }
    }

    // getInstrument = async (data) => {
    //     // console.log(data);
    //     // let loadAPI = `efek-balance-avgs/all-instrument-rd?kdpId=1&productId=${1}`;
    //     // let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
    //     // console.log(response);
    //     // let dataArr = [];

    //     // for (let value of response) {
    //     //     let dataValue = {
    //     //         ...value,
    //     //         id: value.id,
    //     //         instrumentName: value.instrumentName
    //     //     }

    //     //     dataArr.push(dataValue);
    //     // }`

    //     // let filterData = dataArr.filter(value => value.instrumentId === data.instrumentId);
    //     // console.log(filterData[0].instrumentId);
    //     // this.formMasterRef.current.instance.updateData('instrumentId', filterData[0].instrumentId)
    // }


    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Pelepasan Investasi Reksadana`}
                width={'90vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5">
                            <Form
                                colCount={1}
                                id={'form'}
                                ref={this.formMasterRef}
                                formData={this.state.data}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                        <div className="col-md-7 border-left">
                            <Form
                                ref={this.formDetailRef}
                                colCount={2}
                                id={'formDetail'}
                                formData={this.state.dataDetail}
                                items={this.formDetailItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalInstrumentReksadana
