import React, { Component } from 'react'
import { ScrollView, Popup, Form } from 'devextreme-react';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, broker, orders, stock, productDefault, kpds } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import ModalActionSettlementTransaksiSaham from 'components/pages/modal/investasi/settlement/konfirmasi/saham/actionSaham';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import ModalActionSettlementPelepasanTransaksiSaham from './actionPelepasanSaham';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import { showLoading } from 'redux/actions/loading';

class ModalSettlementTransaksiSaham extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            selectedTabIndex: 0,
            dataMaster: {
                noKonfirmasi: {},
                orderDate: new Date(),
                brokerId: {},
                productId: {}
            },
            actionType: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.brokerCode = ""

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.formRef = React.createRef()

        this.modalActionSettlementTransaksiSahamRef = React.createRef()
        this.modalActionPelepasanSettlementTransaksiSahamRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.itemPenempatanMaster = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'No Konfirmasi'
                },
                editorOptions: {
                    // onValueChanged: (e) => {
                    //     this.setState({
                    //         dataMaster: {
                    //             noKonfirmasi: e.value
                    //         }
                    //     })
                    // },
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confirmation number"
                    }
                ]
            },
            {
                dataField: 'kpdId',
                label: {
                    alignment: 'left',
                    text: 'Pengelola'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose kpd'
                    }
                ]
            },
            {
                dataField: 'orderDate',
                label: {
                    alignment: 'left',
                    text: 'Order Date'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    onValueChanged: (e) => {
                        var today = new Date(e.value);
                        var tomorrow = new Date(today);
                        tomorrow.setDate(today.getDate() + 1);
                        tomorrow.toLocaleDateString();
                        if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                            tomorrow.setDate(tomorrow.getDate() + 1);
                            tomorrow.toLocaleDateString();
                            if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                                tomorrow.setDate(tomorrow.getDate() + 2);
                                tomorrow.toLocaleDateString();
                            } else {
                                tomorrow.setDate(tomorrow.getDate() + 1);
                                tomorrow.toLocaleDateString();
                            }
                        } else {
                            tomorrow.setDate(tomorrow.getDate() + 1);
                            tomorrow.toLocaleDateString();
                            if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                                tomorrow.setDate(tomorrow.getDate() + 2);
                                tomorrow.toLocaleDateString();
                            }
                        }
                        this.formRef.current.instance.updateData('settlementDate', tomorrow)
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose order date"
                    }
                ]
            },
            {
                dataField: 'settlementDate',
                label: {
                    alignment: 'left',
                    text: 'Settlement Date'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                },
            },
            {
                dataField: 'brokerId',
                label: {
                    alignment: 'left',
                    text: 'Broker'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.brokerCode + " - " + item.brokerName;
                    },
                    searchEnabled: true,
                    openOnFieldClick: true,
                    deferRendering: false,
                    onSelectionChanged: (e) => {
                        if (e.selectedItem) {
                            this.brokerCode = e.selectedItem.brokerCode;
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose broker"
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose product"
                    }
                ]
            },
            {
                dataField: 'includeWht',
                label: {
                    alignment: 'left',
                    text: 'Include Wht'
                },
                editorType: 'dxCheckBox',
            },
            {
                dataField: "file",
                label: {
                    text: "File Settle",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    accept: 'image/png, image/jpeg, application/pdf',
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please upload file"
                    }
                ]
            },
        ]
        this.penempatanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            data.brokerId = this.state.dataMaster.brokerId
                            data.productId = this.state.dataMaster.productId
                            data.orderDate = this.state.dataMaster.orderDate
                            data.kpdId = this.state.dataMaster.kpdId
                            this.modalActionSettlementTransaksiSahamRef.current.show(data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDetailPenempatanData(e.row.key)
                        }
                    },
                ],
            },
            {
                dataField: 'orderCode',
                caption: 'Nomor Order',
            },
            {
                dataField: 'transactionName',
                caption: 'Tipe order transaksi',
            },
            {
                dataField: 'instrumentId',
                caption: 'Saham',
                lookup: {
                    dataSource: stock(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode'
                }
            },
            {
                dataField: 'quantity',
                caption: 'Kuantitas',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0',
            },
            {
                dataField: 'price',
                caption: 'Harga',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'amountTrs',
                caption: 'Nilai Transaksi',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'commission',
                caption: 'Komisi',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'levy',
                caption: 'Levy',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'vat',
                caption: 'PPn',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'pph',
                caption: 'PPh',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'whtPph23',
                caption: 'Wht PPh23',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'biayaMaterai',
                caption: 'Biaya Materai',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'amountNet',
                caption: 'Total Nilai Transaksi',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
        ]
    }
    show = async () => {
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            'bank-transactions/generate-no-konfirmasi/SAHAM',
            'POST',
            { values: {} }
        )
        this.setState({
            popupVisible: true,
            dataMaster: {
                noKonfirmasi: response
            },
            selectedTabIndex: 0,
        });
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Settle`]: formattedFileData.base64data,
                    [`${fieldName}SettleContentType`]: formattedFileData.mime,
                    [`fileSettleName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    previewFile = async () => {
        var src = imageSource(this.state.fileSettleContentType, this.state.fileSettle)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    hide() {

        this.brokerCode = ""
        this.setState({
            popupVisible: false,
            selectedTabIndex: 0,
            dataMaster: {
                noKonfirmasi: {},
                orderDate: new Date(),
                brokerId: {},
                productId: {}
            },
            actionType: null
        })
        this.dataGridPenempatanRef.current.forceRefresh(true)
    }
    loadData = () => {
        return []
    }
    submitDetailPenempatanData = (data) => {
        if (data.id) {
            this.dataGridPenempatanRef.current.push([{
                type: "update",
                data: {
                    orderStokId: data.orderStokId,
                    transactionName: data.transactionName,
                    instrumentId: data.instrumentId,
                    quantity: data.quantity,
                    price: data.price,
                    amountTrs: data.amountTrs,
                    commission: data.commission,
                    levy: data.levy,
                    vat: data.vat,
                    vatLevy: data.vatLevy,
                    cleareingHouseLevy: data.cleareingHouseLevy,
                    pph: data.pph,
                    whtPph23: data.whtPph23,
                    amountNet: data.amountNet,
                    orderCode: data.orderCode,
                    biayaMaterai: data.biayaMaterai
                },
                key: data.id
            }])
        } else {
            this.dataGridPenempatanRef.current.push([{
                type: "insert",
                data: {
                    orderStokId: data.orderStokId,
                    transactionName: data.transactionName,
                    instrumentId: data.instrumentId,
                    quantity: data.quantity,
                    price: data.price,
                    amountTrs: data.amountTrs,
                    commission: data.commission,
                    levy: data.levy,
                    vat: data.vat,
                    vatLevy: data.vatLevy,
                    cleareingHouseLevy: data.cleareingHouseLevy,
                    pph: data.pph,
                    whtPph23: data.whtPph23,
                    amountNet: data.amountNet,
                    orderCode: data.orderCode,
                    biayaMaterai: data.biayaMaterai
                }
            }])
        }
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    deleteDetailPenempatanData = (key) => {
        this.dataGridPenempatanRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))

        var dataDataGrid = this.dataGridPenempatanRef.current.getDataSource()
        var dataMaster = this.state.dataMaster
        var transactionDate = formatDate(new Date(this.sysDate.dateValue))
        if (!dataMaster.includeWht) {
            dataMaster.includeWht = false
        }
        var customSubmitData = {
            ...dataMaster,
            transaksiItemStoks: dataDataGrid,
            transactionDate: transactionDate,
            fileSettle: this.state.fileSettle,
            fileSettleContentType: this.state.fileSettleContentType,
            fileSettleName: this.state.fileSettleName,
            noRekBroker: 1
        }
        if (customSubmitData) {
            try {
                var insertAPI = 'order-stoks/settle-transaksi-stok'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: customSubmitData
                })

                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Penempatan',
                    icon: 'plus',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.showModalActionSettlementTransaksiSaham()
                        }
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Pelepasan',
                    icon: 'plus',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.showModalActionPelepasanSettlementTransaksiSaham()
                        }
                    }
                }
            },
        )
    }
    forceRefresh = () => {
        this.dataGridPenempatanRef.current.forceRefresh(true)
    }
    showModalActionSettlementTransaksiSaham = () => {
        this.modalActionSettlementTransaksiSahamRef.current.show({
            orderDate: this.state.dataMaster.orderDate,
            brokerId: this.state.dataMaster.brokerId,
            productId: this.state.dataMaster.productId,
            kpdId: this.state.dataMaster.kpdId,
            brokerCode: this.brokerCode
        })
    }
    showModalActionPelepasanSettlementTransaksiSaham = () => {
        this.modalActionPelepasanSettlementTransaksiSahamRef.current.show({
            orderDate: this.state.dataMaster.orderDate,
            brokerId: this.state.dataMaster.brokerId,
            productId: this.state.dataMaster.productId,
            kpdId: this.state.dataMaster.kpdId,
            brokerCode: this.brokerCode
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Konfirmasi Saham'}
                    minWidth={'70vh'}
                    minHeight={'50vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        ref={this.formRef}
                                        id={'formDataMaster'}
                                        formData={this.state.dataMaster}
                                        items={this.itemPenempatanMaster}
                                        labelLocation={"left"}
                                    />
                                    <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileSettle ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Buka File
                                    </button>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <DevExpressDataGrid
                                        ref={this.dataGridPenempatanRef}
                                        loadAPI='cash-deviden'
                                        insertAPI='cash-deviden'
                                        updateAPI='cash-deviden'
                                        deleteAPI='cash-deviden'

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.loadData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Penempatan"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Penempatan'}
                                        popupWidth={1000} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.penempatanColumns} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        onToolbarPreparing={this.onToolbarPreparing}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <ModalActionSettlementTransaksiSaham
                    ref={this.modalActionSettlementTransaksiSahamRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    submitDetailPenempatanData={this.submitDetailPenempatanData}
                />
                <ModalActionSettlementPelepasanTransaksiSaham
                    ref={this.modalActionPelepasanSettlementTransaksiSahamRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    submitDetailPenempatanData={this.submitDetailPenempatanData}
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default ModalSettlementTransaksiSaham
