import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import UpdateNilaiWajar from 'components/pages/modal/investasi/portfolio/balance-position/detail/updateNilaiWajar'

class DetailInstrumentPortofolio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            size: 0,
            page: 0
        }

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'tglPembelian'
            },
            {
                name: 'faceValue',
                column: 'faceValue',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiPembelian',
                column: 'nilaiPembelian',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'bookValue',
                column: 'bookValue',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiPasar',
                column: 'nilaiPasar',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'nominalPl',
                column: 'nominalPl',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'percentPl',
                column: 'percentPl',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
        ]

        this.columns = [

            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Update Nilai Wajar',
                        hint: 'Update Nilai Wajar',
                        onClick: async (e) => {
                            if (e.row) {
                                this.showModalUpdateNilaiWajar(e.row.data)
                            }
                        },
                    },
                ]
            },
            // {
            //     dataField: 'id',
            //     caption: 'No.',
            //     alignment: 'right',
            //     cellRender: (e) => {
            //         return e.rowIndex + 1 + "."
            //     }
            // },
            {
                dataField: 'categoryName',
                caption: 'Kategori'
            },
            {
                dataField: 'subKategori',
                caption: 'Sub Kategori'
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode'
            },
            {
                dataField: 'instrumentName',
                caption: 'Instrument'
            },
            {
                dataField: 'tipe',
                caption: 'Tipe'
            },
            {
                dataField: 'kpdName',
                caption: 'Jenis'
            },
            {
                dataField: 'htm',
                caption: 'HTM'
            },
            {
                dataField: 'appraisalKjppName',
                caption: 'Nama KJPP'
            },
            {
                dataField: 'appraisalYear',
                caption: 'Tahun Apprisal',
                // dataType: 'date',
                // format: 'dd MMM yyyy'
            },
            {
                dataField: 'appraisalValue',
                caption: 'Nilai Wajar',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'tglPembelian',
                caption: 'Tanggal Pembelian',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'qty',
                caption: 'Kuantitas',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'faceValue',
                caption: 'Face Value',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'hargaPembelian',
                caption: 'Harga Pembelian',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'nilaiPembelian',
                caption: 'Nilai Pembelian',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                // calculateCellValue: (e) => {
                //     let res = e.faceValue * (e.hargaPembelian / 100)
                //     return res
                // }
            },
            {
                dataField: 'bookValue',
                caption: 'Nilai Buku',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'hargaPasar',
                caption: 'Harga Pasar',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'nilaiPasar',
                caption: 'Nilai Pasar',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'nominalPl',
                caption: 'PL Nominal',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'percentPl',
                caption: 'PL Persen',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            // {
            //     dataField: 'nilaiPerolehanLastDate',
            //     caption: 'Nilai Perolehan sd Last Date',
            //     dataType: 'number',
            //     format: '#,##0.00',
            //     alignment: 'right',
            // },
            // {
            //     dataField: 'amortisasiLastDate',
            //     caption: 'Amortisasi sd Last Date',
            //     dataType: 'number',
            //     format: '#,##0.00',
            //     alignment: 'right',
            // },
        ]

        this.datagridRef = React.createRef()
        this.showModalUpdateNilaiWajarRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    showModalUpdateNilaiWajar = (data) => {
        if (data) {
            this.showModalUpdateNilaiWajarRef.current.show()
            this.showModalUpdateNilaiWajarRef.current.retrieveData(data)
        }
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/get-list-detail-instrument?page=0&size=921`, 'GET')
        // var data = response.filter(val => val.qty !== 0)
        // var data2 = data.filter(val => val.faceValue !== 0)
        return response
    }

    // handleOptionChanged = async (e) => {
    //     let valueSize = e.value;
    //     let valuePage = e.value;
    //     console.log(valuePage);
    //     if (e.fullName === 'paging.pageSize' || e.fullName === 'paging.pageIndex') {
    //         if (valuePage) {
    //             this.setState({
    //                 page: valuePage
    //             })
    //         }
    //         if (valueSize) {
    //             this.setState({
    //                 size: valueSize
    //             })
    //         }
    //         var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/get-list-detail-instrument?page=${this.state.page}&size=${this.state.size}`, 'GET')
    //         var data = response.filter(val => val.qty !== 0)
    //         var data2 = data.filter(val => val.faceValue !== 0)
    //         return data2
    //     }
    // }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Detail Obligasi / Properti</h2>

                <DevExpressDataGrid
                    keyField="instrumentCode"
                    ref={this.datagridRef}
                    loadAPI='efek-balance-buy-dates/get-list-detail-instrument?size=999'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Daftar Instrument Portfolio"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            `appraisalValue`,
                            `qty`,
                            `faceValue`,
                            `hargaPembelian`,
                            `nilaiPembelian`,
                            `bookValue`,
                            `hargaPasar`,
                            `nilaiPasar`,
                            `nominalPl`,
                            `percentPl`,
                            `nilaiPerolehanLastDate`,
                            `amortisasiLastDate`,
                        ]
                    }

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Data'}
                    popupWidth={'70vw'} //masukan dalam ukuran pixel
                    popupHeight={'400px'} //masukkan dalam ukuran pixel

                    height="calc(100vh - 210px)"
                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    // onOptionChanged={this.handleOptionChanged}

                    formItems={this.columns}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <UpdateNilaiWajar
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    ref={this.showModalUpdateNilaiWajarRef}
                />
            </div>
        )
    }
}

export default DetailInstrumentPortofolio