import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { broker, pejabat, productDefault } from 'dataSource/lookup'
import FormInstruksiBrokerObligasiAktif from 'components/pages/modal/investasi/instruksi/brokerObligasi/aktif/index'
import httpRequest from 'plugin/httprequest'
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify'
import { formatDefaultFullDate, formatNumber, formatNumberAum, formatNumberNav } from 'plugin/helper'
import reportRequest from 'plugin/reportRequest'

class InstruksiBrokerEbaAktif extends Component {
    constructor(props) {
        super(props)
        this.modalRef = React.createRef()
        this.dgInstruksiRef = React.createRef()
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalPenempatan(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin mengirim instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.sendInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin membatalkan instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.cancelInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })

                        }
                    },
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'transactionName',
                        caption: 'Tipe'
                    },
                    {
                        dataField: 'nominal',
                        caption: 'Nominal',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'harga',
                        caption: 'Harga (%)',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.0000'
                    },
                ]
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiNo',
                        caption: 'Nomor Instruksi'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'

                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            }
        ]
        this.rekeningBank = []
        this.getBank = []
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var getRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-banks', 'GET')
        var getBankData = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET')
        this.rekeningBank = getRekBank
        this.getBank = getBankData
    }
    forceRefresh = () => {
        this.dgInstruksiRef.current.forceRefresh(true)
    }
    sendInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        var responseDetailInstruksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-sbns/get-instruksi/${instruksiId}`, 'GET')
        var fileInstruksi = responseDetailInstruksi.orderInstruksiDTO.fileInstruksi
        if (data.pejabat1 || data.pejabat2 == null) {
            if (fileInstruksi != null) {
                if (instruksiId) {
                    try {
                        var insertAPI = 'order-sbns/send-instruksi'
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                            values: [
                                instruksiId
                            ]
                        })
                        notify({ message: 'Pengiriman Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.forceRefresh()
                        return response
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } else {
                notify({ message: 'Mohon lengkapi file instruksi terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        } else {
            notify({ message: 'Mohon lengkapi data terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }
    showModalPenempatan = async (data) => {
        var instruksiId = data.instruksiId
        var loadAPI = `order-sbns/get-instruksi/${instruksiId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderInstruksiDTO
        var dataDataGrid = response.orderSbnInfos
        var dataObl = []
        for (var dataDetail of dataDataGrid) {
            var detail = {
                id: dataDetail.orderSbnDTO.id,
                orderId: dataDetail.orderDTO.id,
                orderCode: dataDetail.orderDTO.orderCode,
                orderTypeId: dataDetail.orderSbnDTO.orderTypeId,
                instrumentId: dataDetail.orderSbnDTO.instrumentId,
                orderDate: dataDetail.orderDTO.orderDate,
                productId: dataDetail.orderDTO.productId,
                faceValue: dataDetail.orderSbnDTO.faceValue,
                price: dataDetail.orderSbnDTO.price,
            }
            dataObl.push(detail)
        }
        var customGetData = {
            // rekName: this.rekBrokerData.find(val => val.id == dataMaster.sourceRekBrokerId).rekName,
            // rekNo: this.rekBrokerData.find(val => val.id == dataMaster.sourceRekBrokerId).rekNo,
            ...dataMaster,
            ...data,
            dataObl: dataObl,
        }
        this.modalRef.current.show(customGetData)
    }
    cancelInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        if (instruksiId) {
          try {
            var insertAPI = 'order-sbns/batal-instruksi'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
              values: [
                instruksiId
              ]
            })
            notify({ message: 'Pembatalan Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            this.forceRefresh()
            return response
          } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
          }
        }
    }
    cetakInstruksi = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-sbns/get-instruksi/${data.instruksiId}`, 'GET')
        var rekNo = this.rekeningBank.find(val => val.id == detail.orderSbnInfos[0].orderSbnDTO.rekBankId).rekNo
        var rekName = this.rekeningBank.find(val => val.id == detail.orderSbnInfos[0].orderSbnDTO.rekBankId).rekName
        var cabang = this.rekeningBank.find(val => val.id == detail.orderSbnInfos[0].orderSbnDTO.rekBankId).cabang
        var bankId = this.rekeningBank.find(val => val.id == detail.orderSbnInfos[0].orderSbnDTO.rekBankId).bankId
        var bankName = this.getBank.find(val => val.id == bankId).bankName
        reportRequest(this.props.store, {
            template: { 'shortid': 'Byxc82FtUv' },
            data: {
                "custodyName": detail.orderInstruksiDTO.instruksiKepada,
                "date": formatDefaultFullDate(detail.orderInstruksiDTO.instruksiDate),
                "up": detail.orderInstruksiDTO.instruksiUp,
                "noSurat": detail.orderInstruksiDTO.instruksiCode,
                "noFaximile": "(021) 5263602/ 5275858",
                "noFax": detail.orderInstruksiDTO.instruksiFax,
                "transactionType": data.transactionName,
                "securitiesSeries": detail.orderSbnInfos[0].instrumentDTO.instrumentName,
                "faceAmount": formatNumberAum(detail.orderSbnInfos[0].orderSbnDTO.faceValue),
                "coupon": "8,600%",
                "price": formatNumberNav(detail.orderSbnInfos[0].orderSbnDTO.price) + "%",
                "accruedInterst": formatNumberAum(detail.orderSbnInfos[0].orderSbnDTO.accruedInterest),
                "procced": "",
                "netProcced": "",
                "totalPayment": "",
                "transactionDate": "",
                "settlementDate": "",
                "counterparty": "",
                "accNo": "",
                "beneficiary": "",
                "aquicitonPrice": formatNumberNav(detail.orderSbnInfos[0].orderSbnDTO.price) + "%",
                "aquicitonDate": "",
                "accruedIntax": "0",
                "capitalGainTax": "0",
                "description": "Dana sebesar  Rp " + detail.orderSbnInfos[0].orderSbnDTO.faceValue + " dapat Saudara Kredit ke rekening giro Nomor " + rekNo + " atas nama " + rekName + " pada " + bankName + " Cabang " + cabang + ".",
                "code": "2.1.2.008",
                "attn": "Kreshna Narendra Ksatriya",
                "pejabat1": detail.orderInstruksiDTO.pejabat1,
                "pejabat2": detail.orderInstruksiDTO.pejabat2,
            },
            options: {
                reportName: "INSTRUKSI-" + data.instruksiNo
            }
        })
    }
    loadData = async() => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'order-instruksi-obligasi/list?status=OPEN', 'GET')
        var orderObl = []
        var filterResponse = response.filter((val) => val.transactionName.split(' ')[1] == 'EBA')
        for (var data of filterResponse) {
            var allData = {
                instruksiId: data.instruksiId,
                productId: data.productId,
                brokerId: data.brokerId,
                transactionName: data.transactionName,
                instruksiNo: data.instruksiNo,
                instruksiDate: data.instruksiDate,
                pejabat1: data.pejabat1,
                pejabat2: data.pejabat2,
                statusId: data.statusId,
            }
            orderObl.push(allData)
        }
        return orderObl
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Broker EBA</h2>

                <DevExpressDataGrid
                    keyField={`instruksiId`}
                    ref={this.dgInstruksiRef}
                    loadAPI='order-instruksi-obligasi/list?status=OPEN'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Instruksi Broker SBN"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Data'}
                    popupWidth={600} //masukan dalam ukuran pixel
                    popupHeight={450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormInstruksiBrokerObligasiAktif
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default InstruksiBrokerEbaAktif