import React, { Component } from 'react'
import { Form, Popup, ScrollView } from 'devextreme-react'
import { kpds, managerInvestasis, productDefault } from 'dataSource/lookup'
import ArrayStore from 'devextreme/data/array_store';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'

class FormAddDevidenEtf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
        }

        this.formRef = React.createRef()
        this.DataMaster = [
            {
                itemType: 'group',
                name: "corporateAction",
                caption: 'Corporate Action',
                items: [
                    {
                        dataField: 'noKonfirmasi',
                        label: {
                            text: 'No. Konfirmasi'
                        },
                        editorOptions: {
                            readOnly: this.props.action == 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'No Konfirmasi tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'jenisCorporate',
                        label: {
                            text: 'Jenis Corporate Action'
                        },
                        editorOptions: {
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'miId',
                        label: {
                            text: 'Manajer Investasi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'miName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            onValueChanged: async (e) => {
                                var kpdId = this.formRef.current.instance.getEditor('kpdId').option('value')
                                var dataRek = await this.rekeningBankByProduct(this.props.store, e.value)
                                this.formRef.current.instance.getEditor('rekeningBankPenerima').option('dataSource', dataRek)

                                var selectSaham = this.formRef.current.instance.getEditor('instrumentId')
                                var dataSaham = await this.efekBalanceAvgEtf(this.props.store, e.value, kpdId)
                                selectSaham.option('dataSource', dataSaham)
                            },
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Mohon memilih produk terlebih dahulu'
                            }
                        ]
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            alignment: 'left',
                            text: 'Pengelola'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            onValueChanged: async (e) => {
                                var productId = this.formRef.current.instance.getEditor('productId').option('value')
                                var selectSaham = this.formRef.current.instance.getEditor('instrumentId')

                                var dataSaham = await this.efekBalanceAvgEtf(this.props.store, productId, e.value)
                                selectSaham.option('dataSource', dataSaham)

                                if (selectSaham.option('value') != null) {
                                    var insId = selectSaham.option('value')
                                    var dataEfek = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `efek-balance-avgs/by-instrumentId/${insId}`,
                                        'GET'
                                    )
                                    var findData = dataEfek.find(val => val.kpdId == e.value)
                                    if (findData) {
                                        this.formRef.current.instance.updateData('qtyOnHand', findData.quantity)
                                    } else {
                                        this.formRef.current.instance.updateData('qtyOnHand', 0)
                                    }
                                }
                            },
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Mohon memilih kpd terlebih dahulu'
                            }
                        ]
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            alignment: 'left',
                            text: 'Reksadana / ETF'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'instrumentId',
                            searchEnabled: true,
                            displayExpr: (e) => {
                                return e && e.instrumentCode + ' - ' + e.instrumentName
                            },
                            onValueChanged: async (e) => {
                                var insId = e.value
                                var kpdId = this.formRef.current.instance.getEditor('kpdId').option('value')
                                var dataEfek = await httpRequest(
                                    process.env.REACT_APP_BACKEND_INVESTASI,
                                    this.props.store,
                                    `efek-balance-avgs/by-instrumentId/${insId}`,
                                    'GET'
                                )
                                var findData = dataEfek.find(val => val.kpdId == kpdId)
                                if (findData) {
                                    this.formRef.current.instance.updateData('qtyOnHand', findData.quantity)
                                } else {
                                    this.formRef.current.instance.updateData('qtyOnHand', 0)
                                }
                            },
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Saham tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'cumDate',
                        label: {
                            text: 'Cum Date'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                    },
                    {
                        dataField: 'exDate',
                        label: {
                            text: 'Ex Date'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                    },
                    {
                        dataField: 'recordingDate',
                        label: {
                            text: 'Recording Date'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                    },
                    {
                        dataField: 'distributionDate',
                        label: {
                            text: 'Distribution / Payment Date'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: this.props.action === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal Distribution tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                name: "devidenEtf",
                caption: 'Deviden ETF',
                items: [
                    {
                        dataField: 'devidenAmountPerStock',
                        label: {
                            text: 'Deviden / Lembar'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.000000',
                            step: 0,
                            onValueChanged: (e) => {
                                var val = e.value
                                var proceedQuantity = this.formRef.current.instance.getEditor('proceedQuantity').option('value')
                                this.formRef.current.instance.updateData({
                                    proceedAmount: val * proceedQuantity
                                })
                            },
                            readOnly: this.props.action === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'qtyOnHand',
                        label: {
                            text: 'Quantity On Hand'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'proceedQuantity',
                        label: {
                            text: 'Quantity Recording'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            onValueChanged: (e) => {
                                var val = e.value
                                var devidenAmountPerStock = this.formRef.current.instance.getEditor('devidenAmountPerStock').option('value')
                                this.formRef.current.instance.updateData({
                                    proceedAmount: val * devidenAmountPerStock
                                })
                            },
                            readOnly: this.props.action === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'proceedAmount',
                        label: {
                            text: 'Nominal Deviden'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var val = e.value
                                var proceedTax = this.formRef.current.instance.getEditor('proceedTax').option('value')
                                this.formRef.current.instance.updateData({
                                    proceedNetAmount: val - proceedTax
                                })
                            },
                            readOnly: this.props.action === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'bankFee',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Bank'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: this.props.action === 'lihat' ? true : false,
                            // step: 0,
                            // readOnly: true,
                            // onValueChanged: (e) => {
                            //     var pajak = this.formRef.current.instance.getEditor('pajak').option('value')
                            //     var nominalDeviden = e.value
                            //     this.formRef.current.instance.updateData('nominalBersih', nominalDeviden - pajak)
                            // }
                        },
                        // validationRules: [
                        //     {
                        //         type: 'required',
                        //         message: 'Nominal deviden tidak boleh kosong'
                        //     }
                        // ]
                    },
                    {
                        dataField: 'proceedTax',
                        label: {
                            text: 'Pajak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var val = e.value
                                var proceedAmount = this.formRef.current.instance.getEditor('proceedAmount').option('value')
                                var biayaBank = this.formRef.current.instance.getEditor('bankFee').option('value');
                                this.formRef.current.instance.updateData({
                                    proceedNetAmount: proceedAmount - biayaBank - val
                                })
                            },
                            readOnly: this.props.action === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'proceedNetAmount',
                        label: {
                            text: 'Nominal Bersih'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekeningBankPenerima',
                        label: {
                            text: 'Rekening Bank Terima'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.rekNo + ' - ' + e.rekName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: this.props.action === 'lihat' ? true : false
                        }
                    },
                ]
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            if(this.state.actionType == 'edit'){
                                this.submitEdit()
                            }else{
                                this.submit()
                            }
                        }
                    }
                },
                visible: this.props.action == 'lihat' ? false : true,
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
    }

    show(data, type) {
        data.jenisCorporate = 'Cash Deviden'
        this.setState({
            DataMaster: data,
            actionType: type,
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
        })
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    submit = async () => {
        var data = this.state.DataMaster
        var submitData = {
            cumDate: data.cumDate,
            devidenAmountPerStock: data.devidenAmountPerStock,
            distributionDate: data.distributionDate,
            exDate: data.exDate,
            instrumentId: data.instrumentId,
            kpdId: data.kpdId,
            miId: data.miId,
            noKonfirmasi: data.noKonfirmasi,
            paymentDate: data.distributionDate,
            proceedAmount: data.proceedAmount,
            bankFee: data.bankFee,
            proceedNetAmount: data.proceedNetAmount,
            proceedQuantity: data.proceedQuantity,
            proceedTax: data.proceedTax,
            productId: data.productId,
            qtyOnHand: data.qtyOnHand,
            recordingDate: data.recordingDate,
            rekeningBankPenerima: data.rekeningBankPenerima,
            statusId: 1,
        }
        if (submitData) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'deviden-etfs/simpanDevidenEtf', 'POST', { values: submitData })
                if (response) {
                    this.hide()
                    this.props.filterData()
                    // this.props.forceRefresh()
                    notify({ message: 'Sukses Menyimpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    submitEdit = async () => {
        var data = this.state.DataMaster
        var submitData = {
            cumDate: data.cumDate,
            devidenAmountPerStock: data.devidenAmountPerStock,
            distributionDate: data.distributionDate,
            exDate: data.exDate,
            instrumentId: data.instrumentId,
            kpdId: data.kpdId,
            miId: data.miId,
            noKonfirmasi: data.noKonfirmasi,
            paymentDate: data.distributionDate,
            proceedAmount: data.proceedAmount,
            proceedNetAmount: data.proceedNetAmount,
            proceedQuantity: data.proceedQuantity,
            proceedTax: data.proceedTax,
            productId: data.productId,
            qtyOnHand: data.qtyOnHand,
            recordingDate: data.recordingDate,
            rekeningBankPenerima: data.rekeningBankPenerima,
            statusId: 1,
            etfDevidenId: data.id
        }
        if (submitData) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'deviden-etfs/updateDevidenEtf', 'POST', { values: submitData })
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: 'Sukses Menyimpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    efekBalanceAvgEtf = async (store, productId, kpdId) => {
        var data = []
        let loadAPI = `efek-balance-avgs/all-instrument-etf?kdpId=${kpdId}&productId=${productId}`
        let loadRd = `efek-balance-avgs/all-instrument-rd?kdpId=${kpdId}&productId=${productId}`

        var getEtf = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')
        var getRd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadRd, 'GET')

        data = getEtf.concat(getRd)

        let dataSource = new ArrayStore({
            data: data,
            key: 'instrumentId'
        });

        return dataSource
    }
    rekeningBankByProduct = async (store, productId) => {
        let loadAPI = `rek-banks-by-productId?productId=${productId}`
        var getRek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRek,
            key: 'id'
        });

        return dataSource
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                resizeEnabled={true}
                showTitle={true}
                width={'40vw'}
                height="75vh"
                title={`Deviden Reksadana / ETF`}
                ref={this.PopupRef}
                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'dataMaster'}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation={"left"}
                            readOnly={this.props.action == 'view' ? true : false}
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormAddDevidenEtf