import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { asetKategoriSdm, assetSdm, currency, programs, subAsetKategoriSdm, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import { formatDate, getSystemDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify'

class FormModalPengajuanProyek extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            dasarPengajuan: {}
        }
        this.subTransType = []

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: 'pengajuanNo',
                        label: {
                            text: 'No Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'projectType',
                        label: {
                            text: 'Jenis Proyek'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: "1",
                                    value: 'Pembangunan',
                                },
                                {
                                    id: "2",
                                    value: 'Renovasi',
                                },
                                {
                                    id: "3",
                                    value: 'Pengadaan',
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'projectCode',
                        label: {
                            text: 'Kode Proyek'
                        }
                    },
                    {
                        dataField: 'projectName',
                        label: {
                            text: 'Nama Proyek'
                        }
                    },
                    {
                        dataField: 'projectDescription',
                        label: {
                            text: 'Keterangan Proyek'
                        }
                    },
                    {
                        itemType: 'group',
                        name: 'renovasi',
                        caption: 'Renovasi',
                        items: [
                            {
                                dataField: 'renovAssetId',
                                label: {
                                    text: 'Aset'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: assetSdm(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.registrasiNo + ' - ' + e.assetName
                                    },
                                    searchEnabled: true,
                                    onSelectionChanged: (e) => {
                                        var slctd = e.selectedItem
                                        if (slctd) {
                                            this.formRef.current.instance.updateData({
                                                assetCategoryId: slctd.assetCategoryId,
                                                assetCategorySubId: slctd.assetCategorySubId,
                                                asetSpesifikasi: slctd.spesifikasi,
                                            })
                                        }
                                    }
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Aset',
                        items: [
                            {
                                dataField: 'assetCategoryId',
                                label: {
                                    text: 'Kategori Aset'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: asetKategoriSdm(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'categoryName',
                                    searchEnabled: true,
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'assetCategorySubId',
                                label: {
                                    text: 'Sub Kategori Aset'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: subAsetKategoriSdm(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'categorySubName',
                                    searchEnabled: true,
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'asetSpesifikasi',
                                label: {
                                    text: 'Spesifikasi'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'biaya',
                        caption: 'Biaya',
                        items: [
                            {
                                dataField: 'transactionTypeId',
                                label: {
                                    text: 'Tipe Transaksi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'transactionTypeName',
                                    searchEnabled: true,
                                    onValueChanged: async (e) => {
                                        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'transaction-type-subs?size=999', 'GET')
                                        let filtered = response.filter(val => val.transactionTypeId === e.value)
                                        this.formRef.current.instance.getEditor('transactionTypeSubId').option('dataSource', filtered)
                                    }
                                }
                            },
                            {
                                dataField: 'transactionTypeSubId',
                                label: {
                                    text: 'Sub Tipe Transaksi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'tipeSubName',
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField: 'projectNominal',
                                label: {
                                    text: 'Nilai Proyek'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                            {
                                dataField: 'amortisasiPeriode',
                                label: {
                                    text: 'Bulan Amortisasi'
                                }
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    text: 'Mata Uang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyName',
                                    searchEnabled: true,
                                    onSelectionChanged: (e) => {
                                        var slctd = e.selectedItem
                                        if (slctd) {
                                            this.formRef.current.instance.updateData({
                                                currencyRate: slctd.rate,
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'currencyRate',
                                label: {
                                    text: 'Kurs'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                            {
                                itemType: 'empty'
                            },
                            {
                                dataField: 'tanggalAwalPekerjaan',
                                label: {
                                    text: 'Tanggal Pekerjaan Mulai'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'tanggalAkhirPekerjaan',
                                label: {
                                    text: 'Tanggal Pekerjaan Akhir'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'masaGaransi',
                                label: {
                                    text: 'Masa Garansi'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0',
                                    step: 0,
                                }
                            },
                        ]
                    },
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.toolbarItem = [
            'background', 'bold', 'color', 'italic', 'link', 'image', 'strike', 'subscript', 'superscript', 'underline', 'blockquote', 'header'
            , 'increaseIndent', 'decreaseIndent', 'orderedList', 'bulletList', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeBlock'
            , 'variable', 'separator', 'undo', 'redo', 'clear'
        ]
        this.formRef = React.createRef()
        this.newData = true
    }

    onValueChanged = (e) => {
        var fieldValue = e.value
        this.setState({
            dasarPengajuan: fieldValue
        })
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster: data,
            dasarPengajuan: data.dasarPengajuan
        })
    }

    submitData = async () => {
        var data = this.state.DataMaster
        let submitData = {
            id: this.newData ? null : data.id,
            amortisasiPeriode: data.amortisasiPeriode,
            asetSpesifikasi: data.asetSpesifikasi,
            assetCategoryId: data.assetCategoryId,
            assetCategorySubId: data.assetCategorySubId,
            currencyId: data.currencyId,
            currencyRate: data.currencyRate,
            dasarPengajuan: this.state.dasarPengajuan,
            masaGaransi: data.masaGaransi,
            pengajuanDate: getSystemDate(this.props.store),
            pengajuanNo: data.pengajuanNo,
            programId: data.programId,
            projectCode: data.projectCode,
            projectDescription: data.projectDescription,
            projectName: data.projectName,
            projectNominal: data.projectNominal,
            projectType: data.projectType,
            renovAssetId: data.renovAssetId,
            statusId: 1,
            tanggalAkhirPekerjaan: formatDate(data.tanggalAkhirPekerjaan),
            tanggalAwalPekerjaan: formatDate(data.tanggalAwalPekerjaan),
            transactionTypeId: data.transactionTypeId,
            transactionTypeSubId: data.transactionTypeSubId,
            dasarPengajuan: this.state.dasarPengajuan
        }
        if (submitData) {
            if (this.newData) {
                try {
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects-pengajuan', 'POST', { values: submitData })
                    if (response) {
                        this.hide()
                        this.props.forceRefresh()
                        notify({ message: 'Sukses Simpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'errror', 600);
                }
            } else {
                try {
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects', 'PUT', { values: submitData, key: submitData.id }, this.state.DataMaster)
                    if (response) {
                        this.hide()
                        this.props.forceRefresh()
                        notify({ message: 'Sukses Mengubah Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'errror', 600);
                }
            }
        }
    }

    updateData = () => {

    }

    show = () => {
        this.setState({
            DataMaster: {
                pengajuanNo: '<AUTO>'
            },
            popupVisible: true,
        })
    }

    hide = () => {
        this.setState({
            DataMaster: {},
            dasarPengajuan: {},
            popupVisible: false
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Pengajuan Proyek'}
                width={"150vh"}
                height={"80vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formPotongan'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation='left'
                        />
                        <label style={{ color: '#828282' }}>Dasar Pengajuan</label>
                        <HtmlEditor
                            height="300px"
                            value={this.state.dasarPengajuan}
                            name={'content'}
                            onValueChanged={this.onValueChanged}
                        >
                            <Toolbar>
                                {
                                    this.toolbarItem.map((toolbarItem) => {
                                        return (
                                            <Item
                                                formatName={toolbarItem}
                                            />
                                        )
                                    })
                                }
                            </Toolbar>
                        </HtmlEditor>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalPengajuanProyek