import React, { Component } from 'react'
import httpRequest from 'plugin/httprequest'
import { Popup, Form } from 'devextreme-react'
import notify from 'devextreme/ui/notify';
import { confirm } from "devextreme/ui/dialog";

class UpdatePenyertaanModal extends Component {
    constructor(props) {
        super(props)

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataInstrument: {}
        }

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.form = [
            {
                dataField: "nilaiWajar",
                label: {
                    text: "Nilai Wajar",
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0",
                    onValueChanged: (e) => {
                        if(e.value) {
                            this.formRef.current.instance.updateData('hargaWajar', e.value / this.state.dataMaster.quantity)
                        }
                    }
                }
            },
            {
                dataField:'quantity',
                label:{
                    text:'Quantity'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0",
                    readOnly: true
                }
            },
            {
                dataField: "hargaWajar",
                label: {
                    text: "Harga Wajar",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0",
                    readOnly: true
                }
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async (e) => {
                        var result = await confirm('Apakah anda yakin menyimpan data ini?', 'Konfirmasi')
                        if (result) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
    }

    retrieveData = async (data, getInstrument) => {
        this.setState({
            dataMaster: data,
            dataInstrument: getInstrument
        })
    }


    submitData = async () => {
        try {
            let dataMaster = this.state.dataMaster
            let dataInstrument = this.state.dataInstrument
            dataInstrument.price = dataMaster.hargaWajar

            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs`, 'PUT', {
                key: dataInstrument.id,
                values: dataInstrument,
            }, dataInstrument)

            if(response) {
                notify({ message: 'Sukses Update Nilai', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
            }
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Update Nilai Wajar Penyertaan Modal'}
                width={'50vh'}
                height={'40vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <Form
                    colCount={1}
                    id={"formAnggaran"}
                    formData={this.state.dataMaster}
                    items={this.form}
                    ref={this.formRef}
                    labelLocation="left"
                />
            </Popup>
        )
    }
}

export default UpdatePenyertaanModal