import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'

class DataGridDetailInstruksiPerpanjanganTerkirim extends Component {
    constructor(props) {
        super(props)
        this.columnPerpanjangan = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                        }
                    },
                ]
            },
            {
                dataField: 'orderId',
                caption: 'Order ID'
            },
            {
                dataField: 'noPengajuan',
                caption: 'No. Pengajuan'
            },
            {
                dataField: 'deposito',
                caption: 'Deposito'
            },
            {
                dataField: 'perpanjangan',
                caption: 'Perpanjangan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'noBilyet',
                        caption: 'No Bilyet'
                    },
                    {
                        dataField: 'jatuhTempo',
                        caption: 'JatuhTempo',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pokok',
                        caption: 'Pokok',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'bunga',
                        caption: 'Bunga',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'jenisRollover',
                        caption: 'Jenis Rollover'
                    },
                    {
                        dataField: 'nominalRollover',
                        caption: 'Nominal Rollover',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'rekeningPencairan',
                caption: 'Rekening Pencairan',
            },
        ]
    }
    render() {
        return (
            <div className="col-md-12" id="Perpanjangan">
                <h6 className='text-center'>Detail Instruksi (Perpanjangan)</h6>
                <DevExpressDataGrid
                    loadAPI='detail-instruksi'
                    insertAPI='detail-instruksi'
                    updateAPI='detail-instruksi'
                    deleteAPI='detail-instruksi'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.dummyPenempatan}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Detail Instruksi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Detail Instruksi Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    height={225}

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columnPerpanjangan} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default DataGridDetailInstruksiPerpanjanganTerkirim