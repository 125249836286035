import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import ModalRekeningReksadana from '../modal/static-data/investasi/reksadana/rekening';
import { reksadana } from '../parts/staticDataColumnConfig';
import { currency, managerInvestasis } from 'dataSource/lookup';
import FormModalInstruReksadana from '../modal/static-data/investasi/reksadana/index';
import httpRequest from 'plugin/httprequest';
import { confirm } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'

class InstruReksadana extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModal(e)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async(e) => {
                            let result = await confirm('Apakah anda yakin akan menghapus data ini?', 'Konfirmasi')

                            if(result) {
                                this.deleteAPI(e.row.data)
                            }
                        }
                    },
                    // {
                    //     text: 'Rekening',
                    //     onClick: (e) => {
                    //         this.showModalRekening(e)
                    //     }
                    // },
                ]
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Reksadana',
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Reksadana',
            },
            {
                dataField: "miId",
                caption: "Manager Investasi",
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: "id",
                    displayExpr: "miName"
                },
            },
            {
                dataField: "isinCode",
                caption: "Isin Code",
            },
            {
                dataField: "currencyId",
                caption: "Currency",
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: "id",
                    displayExpr: "currencyCode"
                },
            },
            {
                dataField: "price",
                caption: "NAB",
                format: "#,##0.0000",
                alignment: "right",
            },
        ]
        this.modalRekeningRef = React.createRef()
        this.modalRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModalRekening = () => {
        this.modalRekeningRef.current.show()
    }
    showModal = async (e) => {
        if (e.row) {
            var data = e.row.data
            var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.id}`)
            var customGetData = {
                id: data.id,
                instrumentCode: data.instrumentCode,
                instrumentName: data.instrumentName,
                isinCode: instrument.isinCode,
                categorySubId: instrument.categorySubId,
                managerInvestasiId: data.miId,
                status: instrument.status,
                emitenId: instrument.emitenId,
                custodyId: instrument.custodyId,
                currencyId: instrument.currencyId,
                listedDate: instrument.listedDate,
                feePercentSub: instrument.feePercentSub,
                feePercentRdm: instrument.feePercentRdm,
                feePercentSwt: instrument.feePercentSwt,
                feeManagement: instrument.feeManagement,
                syariah: instrument.syariah,
            }
            this.modalRef.current.retrieveData(customGetData)
            this.modalRef.current.newData(true)
        }else{
            this.modalRef.current.newData(false)
        }
        this.modalRef.current.show()
    }
    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/instruments`, 'DELETE', data.id);

            notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
            this.forceRefresh()
        } catch (error) {
            notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal(e)
                    },
                }
            }
        )
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Reksadana</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='instruments/reksadana'
                    insertAPI='staticdata-wf/instruments'
                    updateAPI='staticdata-wf/instruments'
                    deleteAPI='staticdata-wf/instruments'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Instrumen Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instrumen Reksadana Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={2} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalRekeningReksadana
                    ref={this.modalRekeningRef}
                    store={this.props.store}
                />
                <FormModalInstruReksadana
                    ref={this.modalRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default InstruReksadana
