import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { pembayaranUmumStatus, programs, sdmUnit, unit } from 'dataSource/lookup';
import PPUVerifikasiSdmModal from 'components/pages/modal/sdm-umum/pengajuan-pembayaran-umum/verifikasi-sdm-umum/index';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify'
import PengajuanPembayaranUmumCatatan from 'components/pages/modal/sdm-umum/pengajuan-pembayaran-umum/comp/catatan';
import { formatDefaultFullDate, formatNumberAum } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class PPUVerifikasiSdmu extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()
        this.modalCatatanRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data, 4)
                        }
                    },
                    {
                        text: 'Tolak',
                        hint: 'Tolak',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data, 2)
                        }
                    },
                ],
            },
            {
                dataField: "id",
                caption: "No.",
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: "noPengajuan",
                caption: "No Pengajuan",
            },
            {
                dataField: "programId",
                caption: "Program",
                lookup: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: "tanggalPengajuan",
                caption: "Tanggal Pengajuan",
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: "totalNominalPengajuan",
                caption: "Total Nominal Pengajuan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalUangMuka",
                caption: "Total Uang Muka",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalCashOut",
                caption: "Total Cash Out",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalPph",
                caption: "Total PPH",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalPotongan",
                caption: "Total Potongan",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: "totalId",
                caption: "Jumlah transaksi",
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: "keterangan",
                caption: "Keterangan",
            },
            {
                dataField: 'cashOut',
                caption: 'Cash Out',
                alignment: 'center',
                columns: [
                    {
                        dataField: "sisaPembayaran",
                        caption: "Sisa Pembayaran",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "nominalCashOut",
                        caption: "Nominal Cash Out",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "nominalDigunakan",
                        caption: "Nominal Digunakan",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: "sisaCash",
                        caption: "Sisa Cash",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                ]
            },
            {
                dataField: 'persetujuan',
                caption: 'Persetujuan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'unitId',
                        caption: 'Unit Kerja',
                        lookup: {
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'requestBy',
                        caption: 'Dibuat Oleh'
                    },
                    {
                        dataField: 'managerVerifiedBy',
                        caption: 'Verifikasi Manager'
                    },
                    {
                        dataField: 'sdmVerifiedBy',
                        caption: 'Verifikasi SDMU'
                    },
                    {
                        dataField: 'directorApproveBy',
                        caption: 'Pengurus'
                    },
                ]
            },
            {
                dataField: 'catatanNote',
                caption: 'Catatan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'managerVerifiedNote',
                        caption: 'Verifikasi Manager'
                    },
                    {
                        dataField: 'sdmVerifiedNote',
                        caption: 'Verifikasi SDMU'
                    },
                    {
                        dataField: 'directorApproveNote',
                        caption: 'Pengurus'
                    },
                ]
            },
            {
                dataField: "statusId",
                caption: "Status",
                lookup: {
                    dataSource: pembayaranUmumStatus(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalCatatan = (data, status) => {
        data.statusId = status
        this.modalCatatanRef.current.show(data)
        // this.modalCatatanRef.current.getData(data)
    }

    showModal = (data) => {
        this.modalRef.current.show()
        if (data) {
            this.modalRef.current.retrieveData(data)
        }
    }

    kirim = async (data, catatan) => {
        try {
            var loadAPI = 'pembayaran-umums'
            var getPU = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI + '/' + data.id, 'GET')
            var updateData = getPU
            var getDetailsPu = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-detils/getByPembayaranUmumId/${data.id}?size=9999`, 'GET')

            updateData.statusId = data.statusId

            updateData.sdmVerified = data.statusId == 4 ? true : false
            updateData.sdmVerifiedNote = catatan
            updateData.sdmVerifiedDate = new Date()
            updateData.sdmVerifiedBy = this.props.store.getState().sessionUser

            var loadAPI = 'pembayaran-umums'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                key: data.id,
                values: updateData
            }, getPU)

            for (let dataDetails of getDetailsPu) {
                dataDetails.statusId = updateData.statusId;
                try {
                    await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-detils`, 'PUT', {
                        key: dataDetails.id,
                        values: dataDetails
                    }, dataDetails)

                } catch (e) {
                    var title = 'Gagal!'
                    var text = 'Data Detail Gagal Dikirim!'
                }

            }

            let loadTask = `tasks?referenceId.equals=${data.id}&taskStatusId.equals=1&taskTypeId.equals=67`;
            let getTask = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadTask, 'GET');

            let loadTask1 = `tasks?referenceId.equals=${data.id}&taskStatusId.equals=2&taskTypeId.equals=66`;
            let getTask1 = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadTask1, 'GET');


            if (getTask.length === 0) {
                await alert('Data Berhasil Dikirim', 'Pemberitahuan');
                this.forceRefresh();
                return null;
            } else {
                let dataTask = getTask[0];
                dataTask.taskStatusId = 2;
    
                await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'tasks', 'PUT', {
                    key: dataTask.id,
                    values: dataTask
                }, dataTask);
            }

            if (data.statusId === 2) {
                let dataTask = getTask1[0];
                dataTask.taskStatusId = 1;
    
                let tolakResponse = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'tasks', 'PUT', {
                    key: dataTask.id,
                    values: dataTask
                }, dataTask);

                if (tolakResponse) {
                    await alert('Data Berhasil Diproses!', 'Berhasil')
                    this.forceRefresh()
                    return null;
                }
            } else {
                let loadAPIPost = `pembayaran-umums/taskPersetujuanPengurus/${getDetailsPu[0].pembayaranUmumId}`;
                let kirimResponse = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIPost, 'POST', {
                    values: {}
                })

                if (kirimResponse) {
                    await alert('Data Berhasil Diproses!', 'Berhasil')
                    this.forceRefresh()
                    return null;
                }
            }

            var title = 'Berhasil!'
            var text = 'Data Berhasil Diproses!'
        } catch (e) {
            console.log(e)
            var title = 'Gagal!'
            var text = e
        }
        await alert(text, title)
        this.forceRefresh()
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'view-pengajuan-pembayaran-umums?statusId.in=3&size=9999');

        for (let value of response){
            let programsId = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `programs/${value.programId}`);
            let unitId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `units/${value.unitId}`);
            let idStatus = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `pembayaran-umum-statuses/${value.statusId}`);
            let dataValue = {
                noPengajuan: value.noPengajuan,
                programId: programsId.program,
                tanggalPengajuan: value.tanggalPengajuan ? formatDefaultFullDate(value.tanggalPengajuan) : '-',
                totalNominalPengajuan: value.totalNominalPengajuan ? formatNumberAum(value.totalNominalPengajuan) : 0,
                totalUangMuka: value.totalUangMuka ? formatNumberAum(value.totalUangMuka) : 0,
                totalCashOut: value.totalCashOut ? formatNumberAum(value.totalCashOut) : 0,
                totalPph: value.totalPph ? formatNumberAum(value.totalPph) : 0,
                totalPotongan: value.totalPotongan ? formatNumberAum(value.totalPotongan) : 0,
                totalId: value.totalId ? value.totalId : 0,
                keterangan: value.keterangan,
                sisaPembayaran: value.sisaPembayaran ? formatNumberAum(value.sisaPembayaran) : 0,
                nominalCashOut: value.nominalCashOut ? formatNumberAum(value.nominalCashOut) : 0,
                nominalDigunakan: value.nominalDigunakan ? formatNumberAum(value.nominalDigunakan) : 0,
                sisaCash: value.sisaCash ? formatNumberAum(value.sisaCash) : 0,
                unit: unitId.unitName ? unitId.unitName : '-',
                requestBy: value.requestBy,
                managerVerifiedBy: value.managerVerifiedBy,
                sdmVerifiedBy: value.sdmVerifiedBy,
                directorApproveBy: value.directorApproveBy,
                managerVerifiedNote: value.managerVerifiedNote,
                sdmVerifiedNote: value.sdmVerifiedNote,
                directorApproveNote: value.directorApproveNote,
                statusId: idStatus.statusName
            }
            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }
        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('VERIFIKASI SDM & UMUM');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,   
                },
                columns: [
                    {name: 'No Pengajuan'},
                    {name: 'Program'},
                    {name: 'Tanggal Pengajuan'},
                    {name: 'Total Nominal Pengajuan'},
                    {name: 'Total Uang Muka'},
                    {name: 'Total Cash Out'},
                    {name: 'Total PPH'},
                    {name: 'Total Potongan'},
                    {name: 'Jumlah Transaksi'},
                    {name: 'Keterangan'},
                    {name: 'Sisa Pembayaran'},
                    {name: 'Nominal Cash Out'},
                    {name: 'Nominal Digunakan'},
                    {name: 'Sisa Cash'},
                    {name: 'Unit Kerja'},
                    {name: 'Dibuat Oleh'},
                    {name: 'Verifikasi Manager'},
                    {name: 'Verifikasi SDMU'},
                    {name: 'Pengurus'},
                    {name: 'Verifikasi Manager '},
                    {name: 'Verifikasi SDMU '},
                    {name: 'Pengurus '},
                    {name: 'Status'},
                ],
                rows: data
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'SDM & UMUM';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums; 

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums2;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums2;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = 65;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;

            const Data14 = worksheet.getColumn(14);
            Data14.width = WidthColums;

            const Data15 = worksheet.getColumn(15);
            Data15.width = WidthColums;

            const Data16 = worksheet.getColumn(16);
            Data16.width = WidthColums;

            const Data17 = worksheet.getColumn(17);
            Data17.width = WidthColums;

            const Data18 = worksheet.getColumn(18);
            Data18.width = WidthColums;

            const Data19 = worksheet.getColumn(19);
            Data19.width = WidthColums;

            const Data20 = worksheet.getColumn(20);
            Data20.width = WidthColums;

            const Data21 = worksheet.getColumn(21);
            Data21.width = WidthColums;

            const Data22 = worksheet.getColumn(22);
            Data22.width = WidthColums;

            const Data23 = worksheet.getColumn(23);
            Data23.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_VERIFIKASI_SDM_&_UMUM.xlsx`
                );
            });
        } catch (error) {
            console.log(error);
        }
    }


    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: () => {
                        this.showModal()
                    }
                }
            },
        )
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        ) 
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">SDM & Umum</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-pengajuan-pembayaran-umums?statusId.in=3&size=9999'
                    insertAPI='pembayaran-umums'
                    updateAPI='pembayaran-umums'
                    deleteAPI='pembayaran-umums'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Verifikasi SDMU"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Verifikasi SDMU'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <PPUVerifikasiSdmModal
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    showModalCatatan={this.showModalCatatan}
                />
                <PengajuanPembayaranUmumCatatan
                    ref={this.modalCatatanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    kirim={this.kirim}
                />

            </div>
        )
    }
}


export default PPUVerifikasiSdmu