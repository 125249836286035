import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
// import ModalKeluargaPengkinianAktifRegistrasi from './actionDataKeluarga'
// import UploadKeluarga from './uploadKeluarga'
import { hubunganKeluarga } from 'dataSource/lookup'
import ModalKeluargaPengalihanDPLK from './actionDataKeluarga'

var date = new Date().toISOString()

class DataGridKeluargaDplk extends Component {

    constructor(props) {
        super(props)

        this.state = {
            formData: []
        }

        this.dataGridKeluargaRef = React.createRef()
        this.popupModalKeluargaRegistrasiAktifRef = React.createRef()
        this.popupUploadKeluarga = React.createRef()

        this.currentData = []
        this.data = []
        this.loadDummyData = []

        this.pesertaId = 0
    }

    reloadDataKeluarga = async (pesertaId, data) => {
        try {
            // this.pesertaId = pesertaId
            // this.data = data
            var loadAPI = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`
            this.data = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            this.currentData = this.data;
            this.dataGridKeluargaRef.current.forceRefresh(true)
        } catch (error) {
            console.log(error);
        }
    }

    retrieveData = async () => {
        if (this.currentData.length !== 0) {
            try {
                this.currentData = JSON.parse(JSON.stringify(this.data))

                return this.data
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } 
        return []
    }

    passDataToParent = () => {
        var data = {
            dataKeluarga: this.dataGridKeluargaRef.current.getDataSource(),
            deletedDataKeluarga: this.dataGridKeluargaRef.current.getDeletedIndex(),
            currentDataKeluarga: this.currentData,
        }
        return data
    }

    showModalKeluarga = (data = {}, actionType) => {
        this.popupModalKeluargaRegistrasiAktifRef.current.show()
        this.popupModalKeluargaRegistrasiAktifRef.current.retrieveData(data, actionType)
    }

    showModalUpload = () => {
        this.popupUploadKeluarga.current.show()
    }

    editDataKeluarga = async (data) => {
        var dataKeluarga = this.dataGridKeluargaRef.current.getDataSource()
        var findAhliWaris = dataKeluarga.find(val => val.ahliWaris == true)
        // if (!findAhliWaris || (findAhliWaris && !data.ahliWaris)) {
        try {
            var key = data.id
            // if (this.dataGridKeluargaRef.current.getDataSource().length === 0) {
            //     data.ahliWaris = true
            // } else {
            //     data.ahliWaris = false
            // }

            if (key) {
                this.dataGridKeluargaRef.current.push([
                    {
                        type: 'update',
                        data: data,
                        key: key
                    }
                ])
            } else {
                this.dataGridKeluargaRef.current.push([
                    {
                        type: 'insert',
                        data: data
                    }
                ])
            }


            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'

            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        } catch (e) {
            console.log(e)
        }
        // } else {
        //     notify({ message: 'Ahli Waris hanya boleh 1 keluarga', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        // }
    }

    deleteDataKeluarga = (data) => {
        this.dataGridKeluargaRef.current.push([
            {
                type: 'remove',
                key: data.id
            }
        ])

        if (data.ahliWaris) {
            var dataKeluarga = this.dataGridKeluargaRef.current.getDataSource()
            if (dataKeluarga.length > 0) {
                dataKeluarga[0].ahliWaris = true
                this.dataGridKeluargaRef.current.push([
                    {
                        type: 'update',
                        data: dataKeluarga[0],
                        key: dataKeluarga[0].id
                    }
                ])
            }
        }
    }

    setPenerimaMP = (data) => {
        var dataKeluarga = this.dataGridKeluargaRef.current.getDataSource()
        var penerimaMPCurrent = dataKeluarga.find(value => value.ahliWaris)
        if (penerimaMPCurrent) {
            penerimaMPCurrent.ahliWaris = false

            var penerimaMPNew = data
            penerimaMPNew.ahliWaris = true

            this.dataGridKeluargaRef.current.push([
                {
                    type: 'update',
                    data: penerimaMPCurrent,
                    key: penerimaMPCurrent.id
                },
                {
                    type: 'update',
                    data: penerimaMPNew,
                    key: penerimaMPNew.id
                }
            ])
        } else {
            data.ahliWaris = true
            this.dataGridKeluargaRef.current.push([
                {
                    type: 'update',
                    data: data,
                    key: data.id
                }
            ])
            // dataKeluarga[0].ahliWaris = true
            // this.dataGridKeluargaRef.current.push([
            //     {
            //         type: 'update',
            //         data: dataKeluarga[0],
            //         key: dataKeluarga[0].id
            //     }
            // ])
        }

    }

    getDatagridColumnConfiguration = () => {
        var columnConfiguration = [
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'relationCode',
                caption: 'Hubungan Keluarga',
                lookup: {
                    dataSource: hubunganKeluarga(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'noKk',
                caption: 'No KK',
            },
            {
                dataField: 'ktp',
                caption: 'No KTP',
            },
            {
                dataField: 'noBpjs',
                caption: 'No BPJS',
            },
            {
                dataField: 'statusSekolah',
                caption: 'Status Pendidikan',
            },
            {
                dataField: 'ahliWaris',
                caption: 'Status Penerima MP',
            },
        ]
        if (this.props.actionType === 'edit') {
            columnConfiguration.unshift({
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            this.showModalKeluarga(e.row.data, 'edit')
                        },
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDataKeluarga(e.row.data)
                        },
                    },
                    {
                        text: 'Set Status Penerima MP',
                        hint: 'Set Status Penerima MP',
                        onClick: (e) => {
                            this.setPenerimaMP(e.row.data)
                        },
                    },
                ],
            })
        } else {
            columnConfiguration.unshift({
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        onClick: (e) => {
                            this.showModalKeluarga(e.row.data, 'view')
                        },
                    },
                    {
                        text: 'Set Status Penerima MP',
                        hint: 'Set Status Penerima MP',
                        onClick: (e) => {
                            this.setPenerimaMP(e.row.data)
                        },
                    },
                ],
            })
        }

        return columnConfiguration
    }

    onToolbarPreparingKeluarga = (e) => {
        if (this.props.actionType !== 'view') {
            e.toolbarOptions.items.unshift(
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'plus',
                        onClick: (e) => {
                            this.showModalKeluarga({}, 'add')
                        }
                    },
                },
                // {
                //     location: 'after',
                //     widget: 'dxButton',
                //     options: {
                //         icon: 'upload',
                //         text: 'Upload',
                //         onClick: (e) => {
                //             this.showModalUpload()
                //         }
                //     }
                // },
            )
        }
    }

    removeState = () => {
        this.currentData = []
        this.loadDummyData = []

        this.setState({
            formData: []
        })
    }

    forceRefresh = () => {
        this.dataGridKeluargaRef.current.forceRefresh(true)
    }

    // reloadDataKeluarga = async (pesertaId = 0, dataAkun) => {
    //     var loadAPI = `peserta-families/${pesertaId}`
    //     var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

    //     console.log(getKeluarga);
    // }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridKeluargaRef}
                    loadAPI={`peserta-families/${this.pesertaId}`}
                    insertAPI='peserta-families'
                    updateAPI='peserta-families'
                    deleteAPI='peserta-families'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.retrieveData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Data Keluarga"}

                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Keluarga'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.getDatagridColumnConfiguration()} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    // onToolbarPreparing={this.onToolbarPreparingKeluarga}

                    // height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                {/* <ModalKeluargaPengkinianAktifRegistrasi
                    store={this.props.store}
                    actionType={this.props.actionType}
                    editDataKeluarga={this.editDataKeluarga}
                    ref={this.popupModalKeluargaRegistrasiAktifRef}
                />
                <UploadKeluarga
                    store={this.props.store}
                    editDataKeluarga={this.editDataKeluarga}
                    ref={this.popupUploadKeluarga}
                /> */}

                <ModalKeluargaPengalihanDPLK 
                    store={this.props.store}
                    actionType={this.props.actionType}
                    ref={this.popupModalKeluargaRegistrasiAktifRef}
                />
            </React.Fragment>
        )
    }
}

export default DataGridKeluargaDplk