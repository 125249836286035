import { Button } from 'devextreme-react/button';
import Form from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import notify from "devextreme/ui/notify";
import React, { Component } from 'react';

import { instrumentTransactionTypes } from 'dataSource/lookup';
import { confirm } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import FormPengajuanPelepasanDeposito from '../pengajuan/deposito/pelepasan/form';
import FormPengajuanPenempatanDeposito from '../pengajuan/deposito/penempatan/form';
import FormPengajuanPerpanjanganDeposito from '../pengajuan/deposito/perpanjangan/form';
import FormPengajuanPelepasanEba from '../pengajuan/eba/pelepasan/form';
import FormPengajuanPenempatanEba from '../pengajuan/eba/penempatan/form';
import FormPengajuanPelepasanEtf from '../pengajuan/etf/pelepasan/form';
import FormPengajuanPenempatanEtf from '../pengajuan/etf/penempatan/form';
import FormPengajuanPelepasanMtn from '../pengajuan/mtn/pelepasan/form';
import FormPengajuanPenempatanMtn from '../pengajuan/mtn/penempatan/form';
import FormPengajuanPelepasanObligasiKorporasi from '../pengajuan/obligasi-korporasi/pelepasan/index';
import FormPengajuanPenempatanObligasiKorporasi from '../pengajuan/obligasi-korporasi/penempatan/index';
import FormPengajuanPelepasanPl from '../pengajuan/penyertaan-modal/pelepasan/index';
import FormPengajuanPenempatanPl from '../pengajuan/penyertaan-modal/penempatan/index';
import FormPengajuanPelepasanProperti from '../pengajuan/properti/pelepasan/form';
import FormPengajuanPenempatanProperti from '../pengajuan/properti/penempatan/form';
import FormPengajuanPelepasanReksadana from '../pengajuan/reksadana/pelepasan/index';
import FormPengajuanPenempatanReksadana from '../pengajuan/reksadana/penempatan/index';
import FormPengajuanPelepasanSaham from '../pengajuan/saham/pelepasan/index';
import FormPengajuanPenempatanSaham from '../pengajuan/saham/penempatan/index';
import FormPengajuanPenyertaanIpoSaham from '../pengajuan/saham/penyertaan-ipo/index';
import FormPengajuanPelepasanSbn from '../pengajuan/sbn/pelepasan/form';
import FormPengajuanPenempatanSbn from '../pengajuan/sbn/penempatan/form';


class FormPersetujuanPengajuan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            transaksiType: 0
        }

        this.prevTabIndex = 0
        this.dataTransaction = {}

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.transactionDepositoRef = React.createRef()
        this.transactionSahamRef = React.createRef()
        this.transactionReksadanaRef = React.createRef()
        this.withdrawDepositoRef = React.createRef()
        this.withdrawSahamRef = React.createRef()
        this.rolloverDepositoRef = React.createRef()
        this.redemptionReksadanaRef = React.createRef()
        this.buySbnRef = React.createRef()
        this.buyMtnRef = React.createRef()
        this.buyOblRef = React.createRef()
        this.sellSbnRef = React.createRef()
        this.sellMtnRef = React.createRef()
        this.sellOblRef = React.createRef()
        this.buyEbaRef = React.createRef()
        this.buyEtfRef = React.createRef()
        this.sellEbaRef = React.createRef()
        this.sellEtfRef = React.createRef()
        this.placementPlRef = React.createRef()
        this.sellPlRef = React.createRef()
        this.penyertaanIpoRef = React.createRef()
        this.penempatanPropertiRef = React.createRef()
        this.pelepasanPropertiRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Setuju',
                    type: 'success',
                    onClick: () => {
                        this.submitData('Approved')
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Menolak',
                    type: 'danger',
                    onClick: () => {
                        this.submitData('Rejected')
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.DataMaster = [
            {
                dataField: 'request.requestNo',
                label: {
                    text: 'No. Pengajuan'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'request.requestDate',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'd MMM yyyy',
                    readOnly: true
                },
            },
            {
                dataField: 'instrument',
                label: {
                    text: 'Instrument'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'transactionType',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName',
                    readOnly: true
                }
            },
            {
                dataField: 'jumlahKomite',
                label: {
                    text: 'Jumlah Komite'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                type: 'empty',
            },
            {
                dataField: 'totalApprovedByCommittee',
                label: {
                    text: 'Di Setujui'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'totalRejectedByCommittee',
                label: {
                    text: 'Di Tolak'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'note',
                label: {
                    text: 'Catatan'
                },
                colSpan: 2,
                editorType: 'dxTextArea',
                editorOptions: {
                    height: 250
                }
            }
        ]

        this.Components = [
            { type: 231, Component: FormPengajuanPenempatanDeposito, ref: this.transactionDepositoRef },
            { type: 262, Component: FormPengajuanPenempatanMtn, ref: this.buyMtnRef },
            { type: 211, Component: FormPengajuanPenempatanSaham, ref: this.transactionSahamRef },
            { type: 221, Component: FormPengajuanPenempatanReksadana, ref: this.transactionReksadanaRef },
            { type: 232, Component: FormPengajuanPelepasanDeposito, ref: this.withdrawDepositoRef },
            { type: 233, Component: FormPengajuanPerpanjanganDeposito, ref: this.rolloverDepositoRef },
            { type: 212, Component: FormPengajuanPelepasanSaham, ref: this.withdrawSahamRef },
            { type: 222, Component: FormPengajuanPelepasanReksadana, ref: this.redemptionReksadanaRef },
            { type: 243, Component: FormPengajuanPenempatanSbn, ref: this.buySbnRef },
            { type: 253, Component: FormPengajuanPenempatanObligasiKorporasi, ref: this.buyOblRef },
            { type: 242, Component: FormPengajuanPelepasanSbn, ref: this.sellSbnRef },
            { type: 254, Component: FormPengajuanPelepasanObligasiKorporasi, ref: this.sellOblRef },
            { type: 273, Component: FormPengajuanPenempatanEba, ref: this.buyEbaRef },
            { type: 302, Component: FormPengajuanPenempatanEtf, ref: this.buyEtfRef },
            { type: 274, Component: FormPengajuanPelepasanEba, ref: this.sellEbaRef },
            { type: 303, Component: FormPengajuanPelepasanEtf, ref: this.sellEtfRef },
            { type: 261, Component: FormPengajuanPelepasanMtn, ref: this.sellMtnRef },
            { type: 291, Component: FormPengajuanPenempatanPl, ref: this.placementPlRef },
            { type: 292, Component: FormPengajuanPelepasanPl, ref: this.sellPlRef },
            { type: 319, Component: FormPengajuanPenyertaanIpoSaham, ref: this.penyertaanIpoRef },
            { type: 284, Component: FormPengajuanPenempatanProperti, ref: this.penempatanPropertiRef },
            { type: 283, Component: FormPengajuanPelepasanProperti, ref: this.pelepasanPropertiRef },
        ]
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: {}
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })

        this.props.toggleModal()
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    submitData = async (type) => {
        var typeApproval = []
        if (type == 'Approved') {
            typeApproval = true
            var message = 'Setujui data ini?'
        } else {
            typeApproval = false
            var message = 'Tolak data ini?'
        }
        var dataMaster = this.state.dataMaster

        var customSubmitData = {
            id: dataMaster.taskId,
            notes: dataMaster.note,
            status: typeApproval
        }
        if (customSubmitData) {
            let result = await confirm(message, 'Konfirmasi')
            if (result) {
                try {
                    var insertAPI = `pengajuanorder-wf/review`
                    await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: customSubmitData
                    })
                    let resOrderRequestId = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${dataMaster.dataRequest.id || dataMaster.data.requestOrderId}`, 'GET');
                    if (resOrderRequestId.statusId === "COMPLETE") {
                        try {
                            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `tasks/update-status-persetujuan-pengajuan-penempatan-investasi/${dataMaster.dataRequest.id || dataMaster.data.requestOrderId}`, 'PUT', {
                                values: {},
                                key: {}
                            }, {});
                            notify({ message: type + ' succesfully!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                            this.hide()
                            this.props.forceRefresh()
                            return response
                        } catch (e) {
                            console.log(e)
                            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    } else {
                        notify({ message: type + ' succesfully!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.hide()
                        this.props.forceRefresh()
                    }
                } catch (e) {
                    console.log(e)
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }
        }
    }
    retrieveData = async (allData) => {
        this.setState({
            dataMaster: allData,
            transaksiType: allData.transactionType
        })
    }
    showTransaction = async () => {
        var dataTransaction = this.state.dataMaster
        var dataRequest = this.state.dataMaster.dataRequest
        var dataMaster = dataTransaction.data
        var type = dataTransaction.instrument
        var transactionType = dataTransaction.transactionType
        var jenisList = []
        var dataPembelian = []
        if (transactionType == 231) {
            // Penempatan Deposito
            jenisList = dataMaster.instrumentDepositoList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPengajuan: dataRequest.orderRequestDTO.requestDate,
                tanggalPenempatan: dataRequest.orderRequestDTO.orderDate,
                requestNo: dataRequest.orderRequestDTO.requestNo,
                categoryName: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    currencyId: dataDetail.currencyId,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    kpdId: dataDetail.kpdId,
                    kurs: dataDetail.kurs,
                    nominal: dataDetail.nominal,
                    rate: dataDetail.rate,
                    rekbankId: dataDetail.rekbankId,
                    rolloverAuto: dataDetail.rolloverAuto,
                    rolloverTypeId: dataDetail.rolloverTypeId,
                    sourceRekbankId: dataDetail.sourceRekbankId,
                    tanggalPenempatan: dataDetail.tanggalPenempatan,
                    tenor: dataDetail.tenor,
                    tenorTypeId: dataDetail.tenorTypeId,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.transactionDepositoRef.current.show()
            this.transactionDepositoRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 211) {
            // Penempatan Saham
            jenisList = dataMaster.instrumentStokList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalOrder: dataMaster.tanggalOrder,
                requestNo: dataRequest.orderRequestDTO.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    brokerCommission: dataDetail.brokerCommission,
                    brokerId: dataDetail.brokerId,
                    catatan: dataDetail.catatan,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    maxOrdPrice: dataDetail.maxOrdPrice,
                    minOrdPrice: dataDetail.minOrdPrice,
                    ordQty: dataDetail.ordQty,
                    priceType: dataDetail.priceType,
                    rekbankId: dataDetail.rekbankId,
                    tanggalPenempatan: dataDetail.tanggalPenempatan
                }
                dataPembelian.push(dataTransaksi)
            }
            this.transactionSahamRef.current.show()
            this.transactionSahamRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 221) {
            // Penempatan reksadana
            jenisList = dataMaster.instrumentRdList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPembelian: dataMaster.tanggalPembelian,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataRequest.orderRequestDTO.requestNo,
                instrument: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instruments = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`)
                var currData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${instruments.currencyId}`, 'GET')

                var dataTransaksi = {
                    id: dataDetail.orderId,
                    amountOrder: dataDetail.amountOrder,
                    aperdId: dataDetail.aperdId,
                    catatan: dataDetail.catatan,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    miId: dataDetail.miId,
                    currencyId: instruments.currencyId,
                    kurs: currData.rate,
                    tanggalPembelian: dataDetail.tanggalPembelian
                }
                dataPembelian.push(dataTransaksi)
            }
            this.transactionReksadanaRef.current.show()
            this.transactionReksadanaRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 232) {
            // Pelepasan Deposito
            jenisList = dataMaster.instrumentDepositoList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPengajuan: dataRequest.orderRequestDTO.requestDate,
                tanggalPelepasan: dataRequest.orderRequestDTO.orderDate,
                noPengajuan: dataRequest.orderRequestDTO.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of dataRequest.orderDepositoInfos) {
                var dataTransaksi = {
                    id: dataDetail.orderDepositoDTO.orderId,
                    bilyetNo: dataDetail.orderDepositoDTO.bilyetNo,
                    currencyId: dataDetail.orderDepositoDTO.currencyId,
                    kurs: dataDetail.orderDepositoDTO.kurs,
                    rate: dataDetail.orderDepositoDTO.rateOrder,
                    avgPrice: dataDetail.orderDepositoDTO.amountOrder,
                    rekbankId: dataDetail.orderDepositoDTO.rekBankId,
                    tanggalPelepasan: dataDetail.orderDepositoDTO.withdrawDate,
                    maturityDate: dataDetail.instrumentDTO.maturityDate,
                    bankId: dataDetail.orderDepositoDTO.bankId,
                    instrumentName: dataDetail.orderDepositoDTO.instrumentId,
                    placementDate: dataDetail.orderDepositoDTO.placementDate,
                    tenor: dataDetail.orderDepositoDTO.tenor,
                    tenorTypeId: dataDetail.orderDepositoDTO.tenorTypeId,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.withdrawDepositoRef.current.show()
            this.withdrawDepositoRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 233) {
            // Perpanjangan Deposito
            jenisList = dataMaster.instrumentDepositoList
            for (var dataDetail of dataRequest.orderDepositoInfos) {
                var dataEfek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-bilyet-no/${dataDetail.instrumentDTO.bilyetNo}`)
                var nilaiTransfer = 0
                for (var data of jenisList) {
                    if (data.rolloverTypeId == 0) {
                        // Pokok + Bunga
                        nilaiTransfer = 0
                    } else if (data.rolloverTypeId == 1) {
                        // Pokok
                        nilaiTransfer = dataEfek.acruedInterest
                    } else if (data.rolloverTypeId == 2) {
                        // Sebagian
                        nilaiTransfer = dataEfek.faceValue + dataEfek.acruedInterest - data.nilaiPerpanjangan
                    }
                    var dataTransaksi = {
                        id: dataDetail.orderDepositoDTO.orderId,
                        rekbankId: dataDetail.orderDepositoDTO.rekBankId,
                        tanggalPerpanjangan: dataDetail.orderDepositoDTO.rolloverDate,
                        bankId: dataDetail.orderDepositoDTO.bankId,
                        tenor: dataDetail.orderDepositoDTO.tenor,
                        tenorTypeId: dataDetail.orderDepositoDTO.tenorTypeId,
                        rolloverTypeId: data.rolloverTypeId,
                        rate: dataDetail.orderDepositoDTO.rateOrder,
                        nominalPerpanjangan: data.nominalPerpanjangan,
                        maturityDatePerpanjangan: dataDetail.instrumentDTO.maturityDate,
                        currencyId: dataDetail.orderDepositoDTO.currencyId,
                        kurs: dataDetail.orderDepositoDTO.kurs,
                        bilyetNo: dataDetail.orderDepositoDTO.bilyetNo,
                        instrumentName: dataDetail.orderDepositoDTO.instrumentId,
                        acruedInterest: dataEfek.acruedInterest,
                        nominalPokok: dataEfek.faceValue,
                        nilaiTransfer: nilaiTransfer
                    }
                    dataPembelian.push(dataTransaksi)
                }
            }
            
            const uniqueData = dataPembelian.filter((value, index, self) =>
                index === self.findIndex((t) => (
                  t.id === value.id
                ))
            );

            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPengajuan: dataRequest.orderRequestDTO.requestDate,
                tanggalPerpanjangan: dataRequest.orderRequestDTO.orderDate,
                noPengajuan: dataRequest.orderRequestDTO.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            this.rolloverDepositoRef.current.show()
            this.rolloverDepositoRef.current.retrieveData(dataMaster, uniqueData, kajian)
        } else if (transactionType == 212) {
            // Pelepasan Saham
            jenisList = dataMaster.instrumentStokList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalOrder: dataMaster.tanggalOrder,
                requestNo: dataRequest.orderRequestDTO.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    brokerCommission: dataDetail.brokerCommission,
                    brokerId: dataDetail.brokerId,
                    catatan: dataDetail.catatan,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    maxOrdPrice: dataDetail.maxOrdPrice,
                    minOrdPrice: dataDetail.minOrdPrice,
                    ordQty: dataDetail.ordQty,
                    priceType: dataDetail.priceType,
                    rekbankId: dataDetail.rekbankId,
                    tanggalPenempatan: dataDetail.tanggalPenempatan
                }
                dataPembelian.push(dataTransaksi)
            }
            this.withdrawSahamRef.current.show()
            this.withdrawSahamRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 222) {
            // Pelepasan Reksadana
            jenisList = dataMaster.instrumentRdList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPenjualan: dataRequest.orderRequestDTO.orderDate,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataRequest.orderRequestDTO.requestNo,
                instrument: type,
                jenisTransaksi: 'Pelepasan',
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    amountVal: dataRequest.orderRdInfos[0].orderRdDTO.amountOrder,
                    redeemUnitType: dataDetail.redeemUnitType,
                    presentaseVal: dataDetail.presentaseVal,
                    aperdId: dataDetail.aperdId,
                    catatan: dataDetail.catatan,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    miId: dataDetail.miId,
                    rekMiId: dataRequest.orderRdInfos[0].orderRdDTO.sourceRekMiId,
                    unitPersen: dataRequest.orderRdInfos[0].orderRdDTO.unitPersen,
                    portofolioPelepasan: dataRequest.orderRdInfos[0].orderRdDTO.valueAll == false ? 'Sebagian' : 'Semua',
                    unitOrder: dataRequest.orderRdInfos[0].orderRdDTO.unitOrder,
                    nabTerakhir: dataRequest.orderRdInfos[0].instrumentDTO.price,
                    currencyId: dataRequest.orderRdInfos[0].instrumentDTO.currencyId
                }
                dataPembelian.push(dataTransaksi)
            }
            this.redemptionReksadanaRef.current.show()
            this.redemptionReksadanaRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 243) {
            // Pembelian SBN
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPembelian: dataMaster.tanggalPembelian,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    htm: dataDetail.htm,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPembelian: dataDetail.tanggalPembelian,
                    accruedInterest: dataDetail.accruedInterest,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.buySbnRef.current.show()
            this.buySbnRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 253) {
            // Pembelian corporate
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPembelian: dataMaster.tanggalPembelian,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                categoryName: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    htm: dataDetail.htm,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPembelian: dataDetail.tanggalPembelian,
                    accruedInterest: dataDetail.accruedInterest,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.buyOblRef.current.show()
            this.buyOblRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 242) {
            // penjualan sbn
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPenjualan: dataMaster.tanggalPenjualan,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPenjualan: dataDetail.tanggalPenjualan,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.sellSbnRef.current.show()
            this.sellSbnRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 254) {
            // Penjualan obl
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPenjualan: dataMaster.tanggalPenjualan,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPenjualan: dataDetail.tanggalPenjualan,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.sellOblRef.current.show()
            this.sellOblRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 273) {
            // Pembelian EBA
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPembelian: dataMaster.tanggalPembelian,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    htm: dataDetail.htm,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPembelian: dataDetail.tanggalPembelian,
                    accruedInterest: dataDetail.accruedInterest,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.buyEbaRef.current.show()
            this.buyEbaRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 302) {
            // Pembelian ETF
            jenisList = dataMaster.instrumentEtfList
            dataMaster = {
                productId: dataRequest.productId,
                fundManagerId: dataRequest.fundManagerId,
                tanggalOrder: dataRequest.orderDate,
                tanggalPengajuan: dataRequest.requestDate,
                requestNo: dataRequest.requestNo,
                categoryName: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataRequest.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    instrumentId: dataDetail.instrumentId,
                    miId: dataDetail.miId,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    currencyId: dataDetail.currencyId,
                    amountOrder: dataDetail.amountOrder,
                    brokerCommission: dataDetail.brokerCommission,
                    brokerId: dataDetail.brokerId,
                    kpdId: dataDetail.kpdId,
                    kurs: dataDetail.kurs,
                    maxOrdPrice: dataDetail.maxOrdPrice,
                    minOrdPrice: dataDetail.minOrdPrice,
                    orderId: dataDetail.orderId,
                    priceType: dataDetail.priceType,
                    rekbankId: dataDetail.rekbankId,
                    tanggalOrder: dataDetail.tanggalOrder,
                    feeNom: (dataDetail.brokerCommission / 100) * dataDetail.amountOrder,
                    nabTerakhir: instrument.price
                }
                dataPembelian.push(dataTransaksi)
            }
            this.buyEtfRef.current.show()
            this.buyEtfRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 274) {
            // Pelepasan EBA
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPenjualan: dataMaster.tanggalPenjualan,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPenjualan: dataDetail.tanggalPenjualan,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.sellEbaRef.current.show()
            this.sellEbaRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 303) {
            // Penjualan ETF
            jenisList = dataMaster.instrumentEtfList
            dataMaster = {
                productId: dataRequest.productId,
                fundManagerId: dataRequest.fundManagerId,
                tanggalOrder: dataRequest.orderDate,
                tanggalPengajuan: dataRequest.requestDate,
                requestNo: dataRequest.requestNo,
                categoryName: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataRequest.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    instrumentId: dataDetail.instrumentId,
                    miId: dataDetail.miId,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    currencyId: dataDetail.currencyId,
                    amountOrder: dataDetail.amountOrder,
                    brokerCommission: dataDetail.brokerCommission,
                    feeNom: (dataDetail.brokerCommission / 100) * dataDetail.amountOrder,
                    brokerId: dataDetail.brokerId,
                    kpdId: dataDetail.kpdId,
                    kurs: dataDetail.kurs,
                    maxOrdPrice: dataDetail.maxOrdPrice,
                    minOrdPrice: dataDetail.minOrdPrice,
                    orderId: dataDetail.orderId,
                    priceType: dataDetail.priceType,
                    rekbankId: dataDetail.rekbankId,
                    amountUnit: dataDetail.amountUnit,
                    useMethod: dataDetail.amountUnit == 0 ? 'Nominal' : 'Unit',
                    tanggalOrder: dataDetail.tanggalOrder,
                    nabTerakhir: instrument.price
                }
                dataPembelian.push(dataTransaksi)
            }
            this.sellEtfRef.current.show()
            this.sellEtfRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 262) {
            // Penempatan MTN
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPembelian: dataMaster.tanggalPembelian,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    htm: dataDetail.htm,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPembelian: dataDetail.tanggalPembelian,
                    accruedInterest: dataDetail.accruedInterest,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.buyMtnRef.current.show()
            this.buyMtnRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 261) {
            // Pelepasan MTN
            jenisList = dataMaster.instrumentObligasiList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalPenjualan: dataMaster.tanggalPenjualan,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataDetail.instrumentId}`, 'GET')
                var dataTransaksi = {
                    id: dataDetail.orderId,
                    bankId: dataDetail.bankId,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    currencyRate: dataDetail.currencyRate,
                    faceValue: dataDetail.faceValue,
                    instSubCategoryId: dataDetail.instSubCategoryId,
                    instrumentId: dataDetail.instrumentId,
                    kpdId: dataDetail.kpdId,
                    institusiOrderId: dataDetail.institusiOrderId,
                    price: dataDetail.price,
                    rekBankId: dataDetail.rekBankId,
                    ratingId: instrument.rating1Id,
                    rate: instrument.rate,
                    maturityDate: instrument.maturityDate,
                    tanggalPenjualan: dataDetail.tanggalPenjualan,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.sellMtnRef.current.show()
            this.sellMtnRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 291) {
            // Penempatan PL
            jenisList = dataMaster.instrumentPlList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalOrder: dataMaster.tanggalOrder,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                categoryId: 11,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: dataDetail.id,
                    biayaPenyertaan: dataDetail.biayaPenyertaan,
                    jmlSahamPenyertaan: dataDetail.jmlSahamPenyertaan,
                    kodeInstrument: dataDetail.kodeInstrument,
                    namaPerusahaan: dataDetail.namaPerusahaan,
                    nilaiPerLembarSaham: dataDetail.nilaiPerLembarSaham,
                    nilaiPerusahaan: dataDetail.nilaiPerusahaan,
                    nominalPenyertaan: dataDetail.nominalPenyertaan,
                    // orderRequestId: data.orderRequestId,
                    prosentasePenyertaan: dataDetail.prosentasePenyertaan,
                    rekBankId: 1,
                    subCategory: dataDetail.subCategory,
                    totalLembarSaham: dataDetail.totalLembarSaham,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.placementPlRef.current.show()
            this.placementPlRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 292) {
            // Pelepasan PL
            jenisList = dataMaster.instrumentPlList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalOrder: dataMaster.tanggalOrder,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                noPengajuan: dataTransaction.request.requestNo,
                categoryId: 11,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: dataDetail.kodeInstrument,
                    biayaPenyertaan: dataDetail.biayaPenyertaan,
                    jmlSahamPenyertaan: dataDetail.jmlSahamPenyertaan,
                    kodeInstrument: dataDetail.kodeInstrument,
                    namaPerusahaan: dataDetail.namaPerusahaan,
                    nilaiPerLembarSaham: dataDetail.nilaiPerLembarSaham,
                    nominalPenyertaan: dataDetail.nominalPenyertaan,
                    // orderRequestId: data.orderRequestId,
                    rekBankId: 1,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.sellPlRef.current.show()
            this.sellPlRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 319) {
            // Penyertaan IPO
            jenisList = dataMaster.instrumentIpoList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalOrder: dataMaster.tanggalOrder,
                tanggalPengajuan: dataMaster.tanggalPengajuan,
                requestNo: dataRequest.orderRequestDTO.requestNo,
                kategori: type,
                jenisTransaksi: dataTransaction.transactionType,
                kajian: atob(dataMaster.kajian)
            }
            let kajian = dataMaster.kajian
            var row = 1
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: row++,
                    brokerId: dataDetail.brokerId,
                    currencyId: dataDetail.currencyId,
                    emitenId: dataDetail.emitenId,
                    etfMarket: dataDetail.etfMarket,
                    instrumentCategoryId: 1,
                    kurs: dataDetail.kurs,
                    nominalPenyertaan: dataDetail.nominalPenyertaan,
                    rekBankId: dataDetail.rekBankId,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.penyertaanIpoRef.current.show()
            this.penyertaanIpoRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 284) {
            // Penempatan Properti
            jenisList = dataMaster.detailListInstrumen
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalOrder: dataMaster.tanggalOrder,
                tanggalPengajuan: dataTransaction.dataRequest.requestDate,
                noPengajuan: dataTransaction.dataRequest.requestNo,
                categoriInstrumentId: dataMaster.categoriInstrumentId,
                jenisTransaksiId: dataTransaction.transactionType,
                dasarPengajuan: atob(dataMaster.dasarPengajuan)
            }
            let kajian = dataMaster.dasarPengajuan
            var row = 1
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: row++,
                    agenPenjualId: dataDetail.agenPenjualId,
                    currencyId: dataDetail.currencyId,
                    fee: dataDetail.fee,
                    fundManagerId: dataDetail.fundManagerId,
                    hargaMaksimum: dataDetail.hargaMaksimum,
                    hargaMinimum: dataDetail.hargaMinimum,
                    instrumentId: dataDetail.instrumentId,
                    jenisHarga: dataDetail.jenisHarga,
                    keterangan: dataDetail.keterangan,
                    kurs: dataDetail.kurs,
                    nominalFee: dataDetail.nominalFee,
                    pengembangEntitasId: dataDetail.pengembangEntitasId,
                    penjualEntitasId: dataDetail.penjualEntitasId,
                    qtyOrder: dataDetail.qtyOrder,
                    rekBankId: dataDetail.rekBankId,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.penempatanPropertiRef.current.show()
            this.penempatanPropertiRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        } else if (transactionType == 283) {
            // Pelepasan Properti
            jenisList = dataMaster.instrumenList
            dataMaster = {
                productId: dataMaster.productId,
                fundManagerId: dataMaster.fundManagerId,
                tanggalOrder: dataMaster.tanggalOrder,
                tanggalPengajuan: dataTransaction.dataRequest.requestDate,
                noPengajuan: dataTransaction.dataRequest.requestNo,
                categoriInstrumentId: dataMaster.categoriInstrumentId,
                jenisTransaksiId: dataTransaction.transactionType,
                dasarPengajuan: atob(dataMaster.dasarPengajuan)
            }
            let kajian = dataMaster.dasarPengajuan
            var row = 1
            for (var dataDetail of jenisList) {
                var dataTransaksi = {
                    id: row++,
                    agenPenjualId: dataDetail.agenPenjualId,
                    currencyId: dataDetail.currencyId,
                    efekBalanceAvgId: dataDetail.efekBalanceAvgId,
                    efekBalanceBuyDateId: dataDetail.efekBalanceBuyDateId,
                    fee: dataDetail.fee,
                    fundManagerId: dataDetail.fundManagerId,
                    hargaMaksimum: dataDetail.hargaMaksimum,
                    hargaMinimum: dataDetail.hargaMinimum,
                    instrumentId: dataDetail.instrumentId,
                    jenisHarga: dataDetail.jenisHarga,
                    keterangan: dataDetail.keterangan,
                    kurs: dataDetail.kurs,
                    nominalFee: dataDetail.nominalFee,
                    pengembangEntitasId: dataDetail.pengembangEntitasId,
                    penjualEntitasId: dataDetail.penjualEntitasId,
                    qtyOrder: dataDetail.qtyOrder,
                    rekBankId: dataDetail.rekBankId,
                }
                dataPembelian.push(dataTransaksi)
            }
            this.pelepasanPropertiRef.current.show()
            this.pelepasanPropertiRef.current.retrieveData(dataMaster, dataPembelian, kajian)
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Persetujuan'}
                width={'700'}
                height={'600'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                            <div className="row">
                                <div className='col-md-12 text-right mb-4'>
                                    <Button
                                        text="Lihat Data Transaksi"
                                        type="normal"
                                        stylingMode="contained"
                                        onClick={this.showTransaction.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={2}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.dataMaster}
                                        items={this.DataMaster}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.Components.map(({ type, Component, ref }) =>
                        this.state.transaksiType === type && (
                            <Component
                                ref={ref}
                                store={this.props.store}
                                actionType={'view'}
                            />
                        )
                    )
                }
            </Popup>
        )
    }
}

export default FormPersetujuanPengajuan