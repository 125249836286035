import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Popup } from 'devextreme-react'

import notify from "devextreme/ui/notify";
import { banks, months, programs, statusPembayaranRekapGaji, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ViewPenggajianKaryawan from './slipGaji';
import { formatNumberAum } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class FormModalRekapGaji extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false
        }

        this.rekapColumns = [
            // {
            //     type: 'buttons',
            //     buttons: [
            //         'edit',
            //         {
            //             text: 'Slip Gaji',
            //             hint: 'Slip Gaji',
            //             cssClass: 'text-primary',
            //             onClick: (e) => {
            //                 this.showModalSlipGaji(e)
            //             }
            //         },
            //         {
            //             text: 'Tunda',
            //             hint: 'Tunda',
            //             cssClass: 'text-primary',
            //             onClick: (e) => {
            //                 // this.showModalSlipGaji()
            //             }
            //         },
            //     ],
            // },
            {
                dataField: "k_id",
                caption: "K Id",
            },
            {
                dataField: "nik",
                caption: "NIK",
            },
            {
                dataField: "full_name",
                caption: "Nama",
            },

            {
                dataField: "sts_karyawan",
                caption: "Status Karyawan",
            },
            {
                dataField: "sts_kepegawaian",
                caption: "Status Kepegawaian",
            },
            {
                dataField: "sts_pajak",
                caption: "Status Pajak",
            },
            {
                dataField: "gaji_pokok",
                caption: "Gaji Pokok",
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: "jumlah_ditransfer",
                caption: "Nominal Transfer",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "penghasilan_bruto",
                caption: "Penghasilan bruto",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "penghasilan_nett",
                caption: "Penghasilan Nett",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pkp",
                caption: "PKP",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pot_cicilan",
                caption: "Potongan Cicilan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pot_jamsostek",
                caption: "Potongan jamsostek",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pot_jht_jo",
                caption: "Potongan JHT",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pot_lain_lain",
                caption: "Potongan lain lain",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "potongan_jabatan",
                caption: "Potongan Jabatan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },

            {
                dataField: "pPph_penghasilan_bruto",
                caption: "PPH Pengahasilan Bruto",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pph_penghasilan_nett_setahun",
                caption: "PPH Nett Pertahun",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pph_sebulan",
                caption: "PPh Sebulan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pph_sebulan_ditanggung",
                caption: "PPH SeBulan Ditanggung",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pph_sebulan_nett",
                caption: "PPH Sebulan Nett",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pph_setahun",
                caption: "PPH Setahun",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pph_setahun_nett",
                caption: "PPH Setahun Nett",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pph_setahun_potongan",
                caption: "PPH Setahun Potongan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "ptkp",
                caption: "PTKP",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "tunj_jamsostek",
                caption: "Tunjangan Jamsostek",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "tunj_kepegawaian",
                caption: "Tunjangan Kepegawaian",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "tunj_lain_lain",
                caption: "Tunjangan Lain lain",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "tunj_lembur",
                caption: "Tunjangan Lembur",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "tunj_medical",
                caption: "Tunjangan Medical",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];


        this.payrollId = 0
        this.showModalSlipGajiRef = React.createRef()
    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {
        this.payrollId = 0
        this.setState({
            popupVisible: false
        })
    }

    retrieveData = (data) => {
        this.payrollId = data
    }

    // showModalSlipGaji = (e) => {
    //     if (e.row) {
    //         this.showModalSlipGajiRef.current.retrieveData(e.row.data, this.payrollId)
    //     }
    //     this.showModalSlipGajiRef.current.show()
    // }

    exportExcel = async() => {
        let data = [];
        const firstTableRowNum = 8;
        let loadData = `pembayaran-umums/getListGajiPph/${this.payrollId || 0}`
        let getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadData, 'GET');
        
        for (let value of getData){
            let dataValue = {
                k_id: value.k_id,
                nik: value.nik,
                full_name: value.full_name,
                sts_karyawan: value.sts_karyawan,
                sts_kepegawaian: value.sts_kepegawaian,
                sts_pajak: value.sts_pajak ? formatNumberAum(value.sts_pajak) : 0,
                gaji_pokok: value.gaji_pokok ? formatNumberAum(value.gaji_pokok) : 0,
                jumlah_ditransfer: value.jumlah_ditransfer ? formatNumberAum(value.jumlah_ditransfer) : 0,
                penghasilan_bruto: value.penghasilan_bruto ? formatNumberAum(value.penghasilan_bruto) : 0,
                penghasilan_nett: value.penghasilan_nett ? formatNumberAum(value.penghasilan_nett) : 0,
                pkp: value.pkp ? formatNumberAum(value.pkp) : 0,
                pot_cicilan: value.pot_cicilan ? formatNumberAum(value.pot_cicilan) : 0,
                pot_jamsostek: value.pot_jamsostek ? formatNumberAum(value.pot_jamsostek) : 0,
                pot_jht_jo: value.pot_jht_jo ? formatNumberAum(value.pot_jht_jo) : 0,
                pot_lain_lain: value.pot_lain_lain ? formatNumberAum(value.pot_lain_lain) : 0,
                potongan_jabatan: value.potongan_jabatan ? formatNumberAum(value.potongan_jabatan) : 0,
                pPph_penghasilan_bruto: value.pPph_penghasilan_bruto ? formatNumberAum(value.pPph_penghasilan_bruto) : '',
                pph_penghasilan_nett_setahun: value.pph_penghasilan_nett_setahun ? formatNumberAum(value.pph_penghasilan_nett_setahun) : 0,
                pph_sebulan: value.pph_sebulan ? formatNumberAum(value.pph_sebulan) : 0,
                pph_sebulan_ditanggung: value.pph_sebulan_ditanggung ? formatNumberAum(value.pph_sebulan_ditanggung) : 0,
                pph_sebulan_nett: value.pph_sebulan_nett ? formatNumberAum(value.pph_sebulan_nett) : 0,
                pph_setahun: value.pph_setahun ? formatNumberAum(value.pph_setahun) : 0,
                pph_setahun_nett: value.pph_setahun_nett ? formatNumberAum(value.pph_setahun_nett) : 0,
                pph_setahun_potongan: value.pph_setahun_potongan ? formatNumberAum(value.pph_setahun_potongan) : 0,
                ptkp: value.ptkp ? formatNumberAum(value.ptkp) : 0,
                tunj_jamsosteK: value.tunj_jamsosteK ? formatNumberAum(value.tunj_jamsosteK) : 0,
                tunj_kepegawaian: value.tunj_kepegawaian ? formatNumberAum(value.tunj_kepegawaian) : 0,
                tunj_lain_lain: value.tunj_lain_lain ? formatNumberAum(value.tunj_lain_lain) : 0,
                tunj_lembur: value.tunj_lembur ? formatNumberAum(value.tunj_lembur) : 0,
                tunj_medical: value.tunj_medical ? formatNumberAum(value.tunj_medical) : 0
            }
            const ObjectValue = Object.values(dataValue);
            data.push(ObjectValue);
        }

        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('Daftar Rekap PPH');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,   
                },
                columns: [
                    {name: 'K id'},
                    {name: 'NIK'},
                    {name: 'Nama'},
                    {name: 'Status Karyawan'},
                    {name: 'Status Kepegawaian'},
                    {name: 'Status Pajak'},
                    {name: 'Gaji Pokok'},
                    {name: 'Nominal Transfer'},
                    {name: 'Penghasilan Bruto'},
                    {name: 'Penghasilan Nett'},
                    {name: 'pkp'},
                    {name: 'Potongan Cicilan'},
                    {name: 'Potongan Jamsostek'},
                    {name: 'Potongan JHT'},
                    {name: 'Potongan lain-lain'},
                    {name: 'Potongan Jabatan'},
                    {name: 'PPH Penghasilan Bruto'},
                    {name: 'PPH Nett Pertahun'},
                    {name: 'PPH Sebulan'},
                    {name: 'PPH Sebulan Ditanggung'},
                    {name: 'PPH Sebulan Nett'},
                    {name: 'PPH Setahun'},
                    {name: 'PPH Setahun Nett'},
                    {name: 'PPH Setahun Potongan'},
                    {name: 'PTKP'},
                    {name: 'Tunjangan Jamsostek'},
                    {name: 'Tunjangan Kepegawaian'},
                    {name: 'Tunjangan Lain Lain'},
                    {name: 'Tunjangan Lembur'},
                    {name: 'Tunjangan Medical'}
                ],
                rows: data
            });
            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            worksheet.getCell('D3').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D3').font = {size: 18, bold: true}
            
            worksheet.getCell('D4').value = 'REKAP PPH';
            worksheet.getCell('D4').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D4').font = {size: 18, bold: true}
            
            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('N'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('O'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('P'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('Q'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('R'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('S'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('T'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('U'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('V'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('W'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('X'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('Y'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('Z'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AA'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AB'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AC'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('AD'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            const WidthColums = 30;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;
            
            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;

            const Data14 = worksheet.getColumn(14);
            Data14.width = WidthColums;

            const Data15 = worksheet.getColumn(15);
            Data15.width = WidthColums;

            const Data16 = worksheet.getColumn(16);
            Data16.width = WidthColums;

            const Data17 = worksheet.getColumn(17);
            Data17.width = WidthColums;

            const Data18 = worksheet.getColumn(18);
            Data18.width = WidthColums;

            const Data19 = worksheet.getColumn(19);
            Data19.width = WidthColums;

            const Data20 = worksheet.getColumn(20);
            Data20.width = WidthColums;
            
            const Data21 = worksheet.getColumn(21);
            Data21.width = WidthColums;

            const Data22 = worksheet.getColumn(22);
            Data22.width = WidthColums;

            const Data23 = worksheet.getColumn(23);
            Data23.width = WidthColums;

            const Data24 = worksheet.getColumn(24);
            Data24.width = WidthColums;

            const Data25 = worksheet.getColumn(25);
            Data25.width = WidthColums;

            const Data26 = worksheet.getColumn(26);
            Data26.width = WidthColums;

            const Data27 = worksheet.getColumn(27);
            Data27.width = WidthColums;

            const Data28 = worksheet.getColumn(28);
            Data28.width = WidthColums;

            const Data29 = worksheet.getColumn(29);
            Data29.width = WidthColums;

            const Data30 = worksheet.getColumn(30);
            Data30.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_REKAP_PPH.xlsx`
                )
            })
        } catch (error) {
            console.log(error);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Rekap Gaji'}
                width={'70vw'}
                heigth={'30vh'}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <DevExpressDataGrid
                    ref={this.DataGridRekapTrsRef}
                    loadAPI={`pembayaran-umums/getListGajiPph/${this.payrollId || 0}`}
                    insertAPI='daftar-penerima-thr'
                    updateAPI='daftar-penerima-thr'
                    deleteAPI='daftar-penerima-thr'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    // useArraySource = {true}
                    // ArraySourceData= {this.loadData}                                                                                                     
                    keyField={'nik'}
                    exportExcel={false}
                    exportFileName={"Daftar Rekap Gaji"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Rekap Gaji'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.rekapColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={"calc(70vh - 170px)"}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                {/* <ViewPenggajianKaryawan
                    ref={this.showModalSlipGajiRef}
                    store={this.props.store}
                /> */}
            </Popup>
        )
    }
}

export default FormModalRekapGaji