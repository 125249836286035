import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import { kasUmum, programSdm } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { alert, confirm } from 'devextreme/ui/dialog';
class FormModalEditPengisianKasUmum extends Component {
    constructor(props) {
        super(props)
        this.userLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null

        this.state = {
            popupVisible: false,
            dataMaster: {},
            type: 'add'
        }

        this.sumAmount = 0
        this.sumAmountEdit = 0
        this.saldo = 0
        this.totalPengisian = 0

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async(e) => {
                                let dataId = e.value;
                                if (dataId === 1) {
                                    let data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-umums?size=999', 'GET');
                                    data = data.filter(value => value.otoritasUser === this.userLoged);

                                    this.formRef.current.instance.getEditor('kasUmumId').option('dataSource', data);
                                }
                            }
                        }
                    },
                    {
                        dataField: 'transactionNo',
                        label: {
                            text: 'No Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'kasUmumId',
                        label: {
                            text: 'Kas Umum'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: kasUmum(this.props.store),
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'namaKas', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: async (e) => {
                                var data = e.selectedItem
                                if (data) {
                                    let getSelectedData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getByMultipleStatusId/1%2C2%2C3`, 'GET')
                                    let filterByKode = getSelectedData.filter(value => value.kasUmumId == data.id);
                                    // console.log("FormModalEditPengisianKasUmum | filterByKode", filterByKode)

                                    this.sumAmount = filterByKode.map(item => item.requestAmount).reduce((sum, item) => sum + item, 0);

                                    var dataMaster = this.state.dataMaster
                                    
                                    var dataTotalPengisian = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umums/getByKasUmumLaporan/${dataMaster.kasUmumId}`);

                                    this.totalPengisian = dataTotalPengisian[0].total_pengisian;

                                    dataMaster.totalPenerimaan = data.totalPenerimaan || 0
                                    dataMaster.totalPengeluaran = data.totalPengeluaran || 0
                                    dataMaster.saldo = data.saldo || 0
                                    if (!dataMaster.requestAmount) {
                                        // dataMaster.requestAmount = data.minimalSaldo || 0
                                        dataMaster.requestAmount = 0
                                    }
                                    dataMaster.minimalSaldo = data.minimalSaldo || 0
                                    dataMaster.maksimalSaldo = data.maksimalSaldo || 0
                                    dataMaster.total_pengisian = dataTotalPengisian[0].total_pengisian || 0

                                    this.formRef.current.instance.updateData(dataMaster)
                                }
                            }
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'pengajuan',
                caption: 'Pengajuan',
                items: [
                    {
                        dataField: 'requestDate',
                        label: {
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'requestAmount',
                        label: {
                            text: 'Nominal Pengajuan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            onValueChanged: async (e) => {
                                this.sumAmountEdit = this.sumAmount - e.previousValue + e.value;
                            }
                        }
                    },
                    {
                        dataField: 'description',
                        label: {
                            text: 'Keterangan Pengajuan'
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Keterangan tidak boleh kosong"
                            }
                        ]
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'saldoKasBank',
                caption: 'Saldo Kas Bank',
                items: [
                    {
                        dataField: 'totalPenerimaan',
                        label: {
                            text: 'Total Penerimaan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'totalPengeluaran',
                        label: {
                            text: 'Total Pengeluaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'minimalSaldo',
                        label: {
                            text: 'Minimal Saldo'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'maksimalSaldo',
                        label: {
                            text: 'Maksimal Saldo'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'saldo',
                        label: {
                            text: 'Saldo'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'total_pengisian',
                        label: {
                            text: 'Total Pengisian'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        }
                    }
                ]
            },

        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    // onClick: this.submitData.bind(this)
                    onClick: async (e) => {
                        const formValidation = this.validate();
                        if (formValidation){
                            await this.submitData();
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.readOnly = false
    }

    validate = () => {
        let formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid;
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    // forceRefresh = () => {
    //     this.formRef.current.forceRefresh(true);
    // }

    // getTotalPengisian = async (data) => {
    //     let getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umums/getByKasUmumLaporan/${data.kasUmumId}`, 'GET');
        
    //     this.formRef.current.instance.getEditor('total_pengisian').option('dataSource', getData);
    // }

    retrieveData = async (data, type) => {
        console.log(data);
        this.readOnly = type === 'view' ? true : false
        // this.saldo = data.saldo;

        this.setState({
            dataMaster: data,
            type: type
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide(){
        // this.sumAmount = 0
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
        this.readOnly = false
    }


    submitData = async () => {
        var dataSubmit = { ...this.state.dataMaster }
        var kskUrl = 'kas-bank-transactions/getByMultipleStatusIdAndKasUmumIdAndKasUmumId/2%2C3/' + dataSubmit.kasUmumId
        var getKsk = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, kskUrl, 'GET')

        // if (getKsk.length !== 0) {
        //    await alert('Pembayaran Kas ini sedang berjalan!', 'Informasi')
        // } else {
            try {
                var requestdate = (new Date(dataSubmit.requestDate)).getTimezoneOffset() * 60000; //offset in milliseconds
                requestdate = (new Date(new Date(dataSubmit.requestDate) - requestdate)).toISOString();

                if (this.state.type == 'add') {
                    var kskUrl = 'kas-bank-transactions/getByMultipleStatusIdAndKasUmumIdAndKasUmumId/1/' + dataSubmit.kasUmumId
                    var getKsk = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, kskUrl, 'GET')

                    if (getKsk.length !== 0) {
                        var konfirm = await confirm('Pengajuan dengan kas umum ini telah dibuat, apakah anda ingin membuat lagi?', 'Konfirmasi')
                        if (!konfirm) {
                            return false
                        }
                    }

                    if (dataSubmit.requestAmount > dataSubmit.maksimalSaldo) {
                        await alert('Nominal Pengajuan Tidak Boleh Lebih Besar dari Maksimal Saldo', 'Informasi')
                        return
                    } else if (dataSubmit.requestAmount < dataSubmit.minimalSaldo) {
                        await alert('Nominal Pengajuan Tidak Boleh Lebih Kecil dari Minimal Saldo', 'Informasi')
                        return
                    }

                    if (this.totalPengisian === dataSubmit.maksimalSaldo) {
                        await alert('Nominal Pengajuan Sudah Mencapai Maksimal Saldo!', 'Informasi')
                        return
                    }
                    
                    if (dataSubmit.requestAmount + this.totalPengisian > dataSubmit.maksimalSaldo) {
                        await alert('Pengajuan Saldo Melebihi Maksimal Saldo!', ' Informasi')
                        return
                    }

                    // if (dataSubmit.requestAmount + this.sumAmount >= dataSubmit.maksimalSaldo) {
                    //     console.log("test2", dataSubmit.requestAmount + this.sumAmount >= dataSubmit.maksimalSaldo);
                    //     await alert('Pengajuan Saldo Melebihi Maksimal Saldo!', ' Informasi')
                    //     return
                    // }
                    var postData = {
                        "kasumId": dataSubmit.kasUmumId,
                        "keteranganPengajuan": dataSubmit.description,
                        "noTransaksi": dataSubmit.transactionNo,
                        "nominalPengajuan": dataSubmit.requestAmount,
                        "programId": dataSubmit.programId,
                        "saldo": dataSubmit.saldo,
                        "tanggalPengajuan": requestdate,
                        "totalPenerimaan": dataSubmit.totalPenerimaan,
                        "totalPengeluran": dataSubmit.totalPengeluaran
                    }
                    var postAPI = 'kas-umum-receives-simpan'
                    await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'POST', {
                        values: postData
                    })

                    var text = 'Data Berhasil Disimpan!'
                } else {
                    if (dataSubmit.requestAmount > dataSubmit.maksimalSaldo) {
                        await alert('Nominal Pengajuan Tidak Boleh Lebih Besar dari Maksimal Saldo', 'Informasi')
                        return
                    } else if (dataSubmit.requestAmount < dataSubmit.minimalSaldo) {
                        await alert('Nominal Pengajuan Tidak Boleh Lebih Kecil dari Minimal Saldo', 'Informasi')
                        return
                    }

                    if (dataSubmit.maksimalSaldo < dataSubmit.saldo + this.totalPengisian){
                        await alert('Pengajuan Saldo Melebihi Maksimal Saldo!', ' Informasi');
                        return;  
                    }
                    
                    var updateAPI = 'kas-bank-transactions'
                    await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, updateAPI, 'PUT', {
                        key: dataSubmit.id,
                        values: dataSubmit
                    }, dataSubmit)

                    var text = 'Data Berhasil Disimpan!'
                }

                await alert(text, 'Berhasil!')

                this.hide()
                this.props.forceRefresh()
            } catch (e) {
                console.log(e)
                await alert(e, 'Gagal!')
            }
        // }

        // await this.props.submitData(dataSubmit, this.state.type)
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Pengisian Kas Umum'}
                minWidth={"100vh"}
                minHeight={"40vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formPotongan'}
                        formData={this.state.dataMaster}
                        items={this.form}
                        labelLocation='left'
                        readOnly={this.readOnly}
                    />
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalEditPengisianKasUmum 