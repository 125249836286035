import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, banks, product, pensiunType, mpType } from 'dataSource/lookup';
import ModalProsesTransaksiMpDitunda from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/ditunda/proses';

class TransaksiMpDitunda extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {}
        }

        this.dataGridRef = React.createRef()
        this.prosesModalRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        this.dataGridRef.current.forceRefresh()
                    }
                },
                // colSpan: 3
            },
            {
                dataField: 'tanggalPembayaran',
                label : {
                    text: 'Tanggal Pembayaran',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd/mm/yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'tanggalPembayaran',
                label : {
                    text: 'S/D',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd/mm/yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Filter'
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Proses Pembayaran',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalProses(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'productCode',
                caption: 'Produk',
                // lookup: {
                //     dataSource: product(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'productCode'
                // }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Pensiun',
            },
            {
                dataField: 'tipePensiun',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName'
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: "dd MMM yyyy",
            },
            {
                dataField: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                dataType: 'date',
                format: "dd MMM yyyy",
            },
            {
                dataField: 'akunId',
                caption : 'Akun'
            },
            {
                dataField: 'pendingNote',
                caption: 'Alasan Ditunda',
            },
            {
                dataField: 'payment',
                caption: 'Pembayaran',
                alignment:'center',
                columns: [
                    {
                        dataField: 'mpName',
                        caption: 'Jenis MP',
                        // lookup: {
                        //     dataSource: mpType(this.props.store),
                        //     valueExpr: 'id',
                        //     displayExpr: 'mpCode'
                        // }
                    },
                    {
                        dataField: 'jumBulan',
                        caption: 'Jumlah Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'jumMpNominal',
                        caption: 'Total Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'jumPajak',
                        caption: 'Total Pajak',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'jumPotongan',
                        caption: 'Total Potongan',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'jumBiayaMp',
                        caption: 'Total Biaya',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'netMpNominal',
                        caption: 'Net MP Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'bankTransferMp',
                caption: 'Bank Transfer MP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'mpRekeningBankId',
                        caption: 'Nama Bank',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName'
                        }
                    },
                    {
                        dataField: 'mpRekeningNo',
                        caption: 'No. Rekening',
                    },
                    {
                        dataField: 'mpRekeningName',
                        caption: 'Nama Rekening',
                    },
                ]
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                paymentDate : '2020-07-16',
                bank : 'BANK BCA',
            }
        ]
        this.currentData = JSON.parse(JSON.stringify(dummyData))
        return dummyData
    }

    showModalProses = (data) => {
        this.prosesModalRef.current.show()
        this.prosesModalRef.current.retrieveData(data)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Ditunda</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-pembayaran-mp-ditundas'
                    insertAPI='pembayaran-mp-ditunda'
                    updateAPI='pembayaran-mp-ditunda'
                    deleteAPI='pembayaran-mp-ditunda' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations={true}
                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Ditunda"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalProsesTransaksiMpDitunda
                    ref={this.prosesModalRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default TransaksiMpDitunda