import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import { formatDate } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog'

class FormTglSpesifik extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
           
            data: {
                tglAwal: '',
                tglAkhir: '',
                jumlahHari: 0
            },
            dataDetail: {}
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.getrSisaCutiTerakhir = 0;
        this.batasCutiAkhir = 0
        this.sumCutiDate = 0
        this.getDayCountCuti = 0
        let dateFrom = null;

        this.formItem = [
            {
                dataField: 'tglAwal',
                label: {
                    text: 'Tanggal Awal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: async(e) => {
                        if (e.value) {
                            let tglAwal = e.value;
                            let tglAkhir = this.formMasterRef.current.instance.getEditor('tglAkhir').option('value')
                            let tglAkhirEditor = this.formMasterRef.current.instance.getEditor('tglAkhir');
                            dateFrom = tglAwal;
                            tglAkhirEditor.option('min', dateFrom);
                            let timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000;
                            let daysCount = Math.floor(timeDiff / (86400)) + 1
                            let range = this.getDaysArray(new Date(tglAwal), new Date(tglAkhir))
                            let res = []
                            for (let r of range) {
                                let dt = {
                                    day: r.getDay()
                                }
                                res.push(dt)
                            }
                            let filterSat = res.filter(val => val.day !== 6)
                            let filtered = filterSat.filter(val => val.day !== 0)
                            daysCount = filtered.length
                            this.batasCutiAkhir = daysCount
                            if (this.props.action !== 'edit') {
                                // if (daysCount > this.getSisaCuti) {
                                //     let notif = notify({ message: `Tanggal melebihi sisa cuti`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                    
                                //     if (notif) {
                                //         this.formMasterRef.current.instance.updateData({ tglAkhir: null })
                                //         this.formMasterRef.current.instance.updateData({ jumlahHari: null })
                                //     }
                                // } else {
                                    if (tglAwal && tglAkhir !== null) {
                                        this.formMasterRef.current.instance.updateData({ jumlahHari: daysCount + this.getDayCountCuti })
                                    }
                            //     }
                            } else {
                            //     if (daysCount > this.getrSisaCutiTerakhir) {
                            //         let notif = notify({ message: `Tanggal melebihi sisa cuti`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                   
                            //         if (notif) {
                            //             this.formMasterRef.current.instance.updateData({ tglAkhir: null })
                            //             this.formMasterRef.current.instance.updateData({ jumlahHari: null })
                            //         }
                            //     } else {
                                    if (tglAwal && tglAkhir !== null) {
                                        this.formMasterRef.current.instance.updateData({ jumlahHari: daysCount + this.getDayCountCuti })
                                    }
                                // }
                            }
                        }
                    },
                    elementAttr: this.props.action == 'view' ? { class: "bd-input-popup-disabled-dapen-default" } : { class: "bd-input-popup-dapen-default" },
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal awal tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'tglAkhir',
                label: {
                    text: 'Tanggal Sampai'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: async(e) => {
                        if (e.value) {
                            let tglAwal = this.formMasterRef.current.instance.getEditor('tglAwal').option('value')
                            let tglAkhir = e.value
                            let timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000
                            let daysCount = Math.floor(timeDiff / (86400)) + 1
                            let range = this.getDaysArray(new Date(tglAwal), new Date(tglAkhir))
                            let res = []
                            for (let r of range) {
                                let dt = {
                                    date: r.getDay()
                                }
                                res.push(dt)
                            }
                            let filterSat = res.filter(val => val.date !== 6)
                            let filtered = filterSat.filter(val => val.date !== 0)
                            daysCount = filtered.length
                            this.batasCutiAkhir = daysCount
                            if (this.props.action !== 'edit') {
                                // if (daysCount > this.getrSisaCutiTerakhir) {
                                //     let notif = notify({ message: `Tanggal melebihi sisa cuti`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                   
                                //     if (notif) {
                                //         this.formMasterRef.current.instance.updateData({ tglAkhir: null })
                                //         this.formMasterRef.current.instance.updateData({ jumlahHari: null })
                                //     }
                                // } else {
                                    if (tglAwal && tglAkhir !== null) {
                                        this.formMasterRef.current.instance.updateData({ jumlahHari: daysCount + this.getDayCountCuti })
                                    }
                                // }
                            } else {
                                // if (daysCount > this.getrSisaCutiTerakhir) {
                                //     let notif = notify({ message: `Tanggal melebihi sisa cuti`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                   
                                //     if (notif) {
                                //         this.formMasterRef.current.instance.updateData({ tglAkhir: null })
                                //         this.formMasterRef.current.instance.updateData({ jumlahHari: null })
                                //     }
                                // } else {
                                    if (tglAwal && tglAkhir !== null) {
                                        this.formMasterRef.current.instance.updateData({ jumlahHari: daysCount + this.getDayCountCuti })
                                    }
                                // }
                            }
                        }
                    },
                    elementAttr: this.props.action == 'view' ? { class: "bd-input-popup-disabled-dapen-default" } : { class: "bd-input-popup-dapen-default" },
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal sampai tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'jumlahHari',
                label: {
                    text: 'Jumlah Hari'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    readOnly: true,
                    elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
                }
            }
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    elementAttr: { class: "bg-button-popup-save-dapen-default" },
                    onClick: () => {
                        let formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        } else {
                            return null;
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.action == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    elementAttr: { class: "bg-button-popup-close-dapen-default" },
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    show(tanggal, sum) {
        this.sumCutiDate = sum
        this.setState({
            popupVisible: true,
            data: {
                tglAwal: tanggal
            }
        });
        let tglAwal = this.formMasterRef.current.instance.getEditor('tglAwal');
        let currentDate = new Date();
        tglAwal.option('min', formatDate(tanggal));
        tglAwal.option({
            type: 'date',
            valuer: currentDate
        })
    }

    getDayCount = () => {
        this.getDayCountCuti = 1
    }

    validate = () => {
        let formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    retrieveData = (data) => {
        if (this.props.action === 'view') {
            this.setState({
                popupVisible: true,
                data: data
            });
            let tglAwal = this.state.data.tglAwal;
            let tglAkhir = this.state.data.tglAkhir;
            let timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000
            let daysCount = Math.floor(timeDiff / (86400)) + 1
            let range = this.getDaysArray(new Date(tglAwal), new Date(tglAkhir))
            let res = []
            for (let r of range) {
                let dt = {
                    date: r.getDay()
                }
                res.push(dt)
            }
            let filterSat = res.filter(val => val.date !== 6)
            let filtered = filterSat.filter(val => val.date !== 0)
            daysCount = filtered.length
            this.formMasterRef.current.instance.updateData('jumlahHari', daysCount);   
        } else if (this.props.action === 'edit') {
            this.setState({
                popupVisible: true,
                data: data
            });
            let tglAwal = this.state.data.tglAwal;
            let tglAkhir = this.state.data.tglAkhir;
            let timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000
            let daysCount = Math.floor(timeDiff / (86400)) + 1
            let range = this.getDaysArray(new Date(tglAwal), new Date(tglAkhir))
            let res = []
            for (let r of range) {
                let dt = {
                    date: r.getDay()
                }
                res.push(dt)
            }
            let filterSat = res.filter(val => val.date !== 6)
            let filtered = filterSat.filter(val => val.date !== 0)
            daysCount = filtered.length
            this.formMasterRef.current.instance.updateData('jumlahHari', daysCount);   
        }
    }

    getSisaCuti = (data) => {
        this.getrSisaCutiTerakhir = data[0].jumlah_sisa;
    }

    hide() {
        this.setState({
            data: {},
            popupVisible: false,
        })
    }

    submitData = async () => {
        let data = {...this.state.data}
        let sisa = this.sumCutiDate + data.jumlahHari
        if (sisa > this.getrSisaCutiTerakhir) {
            let notif = await alert('Tanggal melebihi batas cuti!', 'Informasi')
           
            if (notif) {
                this.formMasterRef.current.instance.updateData({ tglAkhir: null })
                this.formMasterRef.current.instance.updateData({ jumlahHari: null })
            }
        } else {
            this.props.submitTglSpesifik({
                id: data.id,
                tglAwal: formatDate(data.tglAwal),
                tglAkhir: formatDate(data.tglAkhir),
                jumlahHari: data.jumlahHari
            })
            this.hide()
        }
    }

    getDaysArray(start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Tanggal Cuti'}
                width={'30vw'}
                height={'auto'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formMaster'}
                        ref={this.formMasterRef}
                        formData={this.state.data}
                        items={this.formItem}
                        scrollingEnabled={false}
                        labelLocation={"left"}
                        readOnly={this.props.action == 'view' ? true : false}
                    />
                </div>
            </Popup>
        )
    }
}

export default FormTglSpesifik