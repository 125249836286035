import React, { Component } from 'react'
import Form from 'devextreme-react/form'
import notify from 'devextreme/ui/notify'
import { unit, SKType, branch, grade, jabatan} from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class FormDataKepegawaianAndSk extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            formDataSk: []
        }

        this.pesertaId = 0
        this.formDataSk = []
        
        this.formRef = React.createRef()
        this.DataGridPensiunRef = React.createRef()

        this.items = [
            {
                dataField: 'statusKaryawanTanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Mulai Kerja'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                }
            },
            {
                dataField: 'masaKerja',
                label: {
                    alignment: 'left',
                    text: 'Masa Kerja'
                },
            },
            {
                dataField: 'tanggalEfektif',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Kepesertaan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                }
            },
            {
                dataField: 'jabatanId',
                label: {
                    alignment: 'left',
                    text: 'Posisi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values
                },
            },
            {
                dataField: 'unitKerjaId',
                label: {
                    alignment: 'left',
                    text: 'Unit Kerja'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: unit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                },
            },
            {
                dataField: 'branchId',
                label: {
                    alignment: 'left',
                    text: 'Cabang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: branch(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'branchName', // provides display values
                },
            },
            {
                dataField: 'grade',
                label: {
                    alignment: 'left',
                    text: 'Grade'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: grade(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'gradeName', // provides display values
                },
            },
            {
                dataField: 'phdp',
                label: {
                    alignment: 'left',
                    text: 'PHDP'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                },
            },
            {
                dataField: 'pesertaAkunProduk[0].iuranNormalPeserta',
                label: {
                    alignment: 'left',
                    text: 'Iuran Peserta'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                },
            },
            {
                dataField: 'pesertaAkunProduk[0].iuranNormalPk',
                label: {
                    alignment: 'left',
                    text: 'Iuran Perusahaan'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                },
            },
            {
                dataField: 'pesertaAkunProduk[0].iuranTotal',
                label: {
                    alignment: 'left',
                    text: 'Total Iuran'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    value : this.state.formData.pesertaAkunProduk ? (this.state.formData.pesertaAkunProduk[0].iuranNormalPeserta) + (this.state.formData.pesertaAkunProduk.iuranNormalPk) : 0
                }
            },
        ]
        this.dataGridKepegawaian = [
            {
                dataField: 'skNo',
                caption: 'No. SK',
            },
            {
                dataField: 'pesertaSkTypeId',
                caption: 'Jenis SK',
                lookup:{
                    dataSource: SKType(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'typeName' 
                }
            },
            {
                dataField: 'skDate',
                caption: 'Tanggal SK',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'efektifDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]
    }

    retrieveData = (pesertaId,dataKepegawaian,dataAkun) => {
        this.setState({
            formData: {
                ...dataKepegawaian,
                pesertaAkunProduk: dataAkun
            },
        })
        console.log(this.state.formData)
        this.pesertaId = pesertaId
        this.dataSk = dataKepegawaian.pesertaSK

        this.reloadData()
    }

    reloadData = () => {
        this.DataGridPensiunRef.current.forceRefresh()
    }

    loadDummyDataKepegawaian = () => {
        return this.formDataSk
    }

    passDataToParent = () => {
        var data = this.state.formData
        return data
    }

    render() {
        return (
            <React.Fragment>
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formDataKepegawaian'}
                    formData={this.state.formData}
                    items={this.items}
                    height='500px'
                    scrollingEnabled={true}
                    readOnly={true}
                    labelLocation='left'
                />
                <h5 className="font-weight-bold mb-3">Daftar Surat Keputusan</h5>
                <DevExpressDataGrid 
                ref = {this.DataGridPensiunRef}
                loadAPI = {`peserta-sks/${this.pesertaId}`}
                insertAPI = 'peserta-sks'
                updateAPI = 'peserta-sks'
                deleteAPI = 'peserta-sks'

                useArraySource = {true}
                ArraySourceData={this.loadDummyDataKepegawaian}

                backendserver = {process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                showBorders = {true}

                paging = {true}
                defaultPageSize = {10}
                
                editingMode={'popup'}
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle = {'Data Surat Keputusan'}
                popupWidth = {500} //masukan dalam ukuran pixel
                popupHeight = {450} //masukkan dalam ukuran pixel

                popupFormLabelLocation = 'top' //accepted value = top, left, right
                popupFormMinColWidth = {300} // minimum lebar kolom
                popupFormColCount = {3} //jumlah kolom pada form
                
                //akhir bagian konfigurasi popup

                ColumnChooser = {false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing = {false} // set false agar kolom tidak dapat di freeze

                FilterRow = {true} // set false untuk mematikan fitur filter
                
                ColumnConfiguration={this.dataGridKepegawaian} // taruh konfigurasi kolom disini

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                
                store = {this.props.store} // jangan di edit edit
            />
            </React.Fragment>
        )
    }
}

export default FormDataKepegawaianAndSk
