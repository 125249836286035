import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormGajiKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/gaji/form';
import httpRequest from 'plugin/httprequest';
import { confirm, alert } from 'devextreme/ui/dialog';
import { jabatan, karyawanSk } from 'dataSource/lookup';
import { formatDate, formatDefaultDate } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';
import notify from 'devextreme/ui/notify';

class KaryawanGaji extends Component {
    constructor(props){
        super(props)
        this.state ={

        }
        this.modalEditRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: async(e) => {
                            var result = await confirm('Apakah anda yakin untuk menghapus data ini?','Confirmation')

                            if(result){
                                this.delete(e.row.data)
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'jabatanId',
                caption: 'Jabatan',
                lookup : {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'skId',
                caption: 'No. SK',
                lookup : {
                    dataSource: karyawanSk(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'skNo',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'skId',
                caption: 'Tanggal SK',
                lookup : {
                    dataSource: karyawanSk(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && formatDefaultDate(item.skDate)
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'skId',
                caption: 'Tanggal Berlaku',
                lookup : {
                    dataSource: karyawanSk(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && formatDefaultDate(item.efektifDate)
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'gajiPokok',
                caption: 'Gaji Pokok',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'totalTunjangan',
                caption: 'Total Tunjangan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'totalPotongan',
                caption: 'Total Potongan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalEdit = (data) => {
        if (data) {
            this.modalEditRef.current.retrieveData(data)
        }
        this.modalEditRef.current.show()
    }

    showModalInsert = () => {
        this.modalInsertRef.current.getKaryawan()
        this.modalInsertRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            }
        )
    }   

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    delete = async(data) => {
        this.props.store.dispatch(showLoading(true))
        var karyawanId = data.karyawanId

        try{
            var deleteAPI = `delete-tunjangan-and-potongan`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, deleteAPI, 'DELETE', karyawanId)

            this.props.store.dispatch(showLoading(false))
            notify({ message: 'Data berhasil dihapus', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
            this.forceRefresh()

            return response
        }catch(e){
            console.log(e)
            this.props.store.dispatch(showLoading(false))
            notify({ message: 'Data gagal dihapus', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            this.forceRefresh()
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Gaji, Tunjangan, Potongan Karyawan</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-gaji-tunjangan-potongans'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-' 

                    remoteOperations = {true}
                    backendserver={process.env.REACT_APP_BACKEND_HRD}   

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    
                    exportExcel={true}
                    exportFileName={"Daftar Gaji Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}
 
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormGajiKaryawan
                    ref={this.modalEditRef}
                    store={this.props.store}
                    action={'edit'} 
                    submitData = {this.updateData}
                    forceRefresh={this.forceRefresh}
                    />
                <FormGajiKaryawan
                    ref={this.modalInsertRef}
                    store={this.props.store}
                    action={'add'}
                    submitData = {this.insertData}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default KaryawanGaji