import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import AkunKepesertaan from './akunKepesertaan';
import AkunInvestasi from './akunInvestasi';
import AkunSdm from './akunSdm';

class MappingCoa extends Component {
    constructor(props){
        super(props)

        this.prevTabIndex = 0

        this.TabDataSource = [
            {
                id : 0,
                text: 'Kepesertaan'
            },
            {
                id : 1,
                text: 'Investasi'
            },
            {
                id : 2,
                text: 'SDM & Umum'
            },
        ]  

        this.datagridInvestasiRef = React.createRef()
        this.datagridKepesertaanRef = React.createRef()
        this.datagridSDMRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        switch (args.value) {
            case 0:
                this.datagridKepesertaanRef.current.datagridRef.current.DatagridRef.current.instance.updateDimensions()
                break;
            case 1:
                this.datagridInvestasiRef.current.datagridRef.current.DatagridRef.current.instance.updateDimensions()
                break;
            case 2:
                this.datagridSDMRef.current.datagridRef.current.DatagridRef.current.instance.updateDimensions()
                break;
        
            default:
                break;
        }

        this.prevTabIndex = args.value
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Mapping COA</h3>

                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                
                <div id="tabindex-0" className={' mt-2 text-center' } style={{height: '100%'}}>
                    <AkunKepesertaan store = {this.props.store} ref={this.datagridKepesertaanRef}/>
                </div>
                <div id="tabindex-1" className={'d-none mt-2 text-center' } style={{height: '100%'}}>
                    <AkunInvestasi store = {this.props.store} ref={this.datagridInvestasiRef} />
                </div>
                <div id="tabindex-2" className={'d-none mt-2 text-center' } style={{height: '100%'}}>
                    <AkunSdm store = {this.props.store} ref={this.datagridSDMRef} />
                </div>
            </div>
        )
    }
}

export default MappingCoa