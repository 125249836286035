import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import Form from 'devextreme-react/form';

import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';


class FixedAssetMutation extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData: {},
            fixedAssetId: 0
        }

        this.datagridRef = React.createRef()

        this.formItem = [
            {
                dataField: 'fixedAssetCode',
                label: {
                    alignment: 'left',
                    text: 'Aset'
                }
            },
            {
                dataField: 'fixedAssetName',
                label: {
                    alignment: 'left',
                    visible: false
                }
            },
        ]

        this.columns = [
            {
                dataField: 'valueDate',
                caption: 'Tanggal',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'amount',
                caption: 'Nilai',
                format: '#,##0',
            },
            {
                dataField: 'reffId',
                caption: 'Reff',
            },
            {
                dataField: 'reffType',
                caption: 'Tipe Reff',
            },
        ]
    }

    show = (data) => {
        this.setState({
            popupVisible: true,
            formData: data
        });

        this.datagridRef.current.forceRefresh(true)
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    loadData = async () => {
        var {id} = this.state.formData

        console.log(this.state.formData)

        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`fixed-asset-mutations/getByFixedAssetId/${id}`)

        return data
    }

    render() {
        return (
            <Popup
            className={"popup"}
            visible={this.state.popupVisible}
            onHiding={this.hide}
            dragEnabled={false}
            showTitle={true}
            title={"Mutasi Aset Tetap"}
            width={"90vw"}
            height={"70vh"}
            toolbarItems={this.PopupToolbarItem}
            >
                <Form
                    ref={this.formRef}
                    colCount={2}
                    formData={this.state.formData}
                    items={this.formItem}
                    scrollingEnabled={false}
                    labelLocation='left'
                />
                <DevExpressDataGrid
                    ref={this.datagridRef}
                    loadAPI='fixed-asset-mutations'
                    insertAPI='fixed-asset-mutations'
                    updateAPI='fixed-asset-mutations'
                    deleteAPI='fixed-asset-mutations' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useArraySource = {true}
                    ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Data Sukses"}

                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Sukses'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={false} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    height = {'calc(70vh - 285px)'}
                    sortingMode = {'none'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </Popup>
        )
    }
}

export default FixedAssetMutation