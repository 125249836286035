import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { clientpemberikerja } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import FormModalClientParticipant from './modalClientParticipant'
import httpRequest from 'plugin/httprequest'

class ClientPemberiKerja extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'participantCode',
                caption: 'Kode',
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                dataField: 'participantName',
                caption: 'Nama Pemberi Kerja',
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                dataField: 'alamat',
                caption: 'Alamat',
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                dataField: 'kepesertaan',
                caption: 'KEPESERTAAN',
                columns: [
                    {
                        dataField: 'CountDataAktif',
                        caption: 'Aktif',
                        dataType: 'number',
                        format: '#,##0',
                        alignment: 'right',
                        editorOptions: {
                            format: '#,##0',
                        }
                    },
                    {
                        dataField: 'CountDataPasif',
                        caption: 'Pasif',
                        dataType: 'number',
                        format: '#,##0',
                        alignment: 'right',
                        editorOptions: {
                            format: '#,##0',
                        }
                    },
                    {
                        dataField: 'CountDataArsip',
                        caption: 'Arsip',
                        dataType: 'number',
                        format: '#,##0',
                        alignment: 'right',
                        editorOptions: {
                            format: '#,##0',
                        }
                    },
                ],
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                type: 'form',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'participantCode',
                                label: {
                                    text: 'Kode'
                                }
                            },
                            {
                                dataField: 'participantName',
                                label: {
                                    text: 'Nama Pemberi Kerja'
                                }
                            },
                            {
                                dataField: 'alamat',
                                label: {
                                    text: 'Alamat'
                                }
                            },
                            {
                                dataField: 'email',
                                label: {
                                    text: 'Email'
                                }
                            },
                            {
                                dataField: 'telp',
                                label: {
                                    text: 'No. Telp',
                                },
                            },
                            {
                                dataField: 'fax',
                                label: {
                                    text: 'No. Fax'
                                },
                            },
                            {
                                dataField: 'payIuranDate',
                                label: {
                                    text: 'Tanggal Pembayaran Iuran',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                    }
                                ],
                            },
                            {
                                dataField: 'tglPembayaranIuran',
                                label: {
                                    text: 'Tanggal Iuran',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    max: 31,
                                    min: 1
                                }
                            },
                            {
                                dataField: 'tglPengkinianPeserta',
                                label: {
                                    text: 'Tanggal Pengkinian Peserta',
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    max: 31,
                                    min: 1
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        items: [
                            {
                                itemType: 'group',
                                caption: 'Kontak Person Kepesertaan',
                                items: [
                                    {
                                        dataField: 'namaKepesertaan',
                                        label: {
                                            text: 'Nama'
                                        }
                                    },
                                    {
                                        dataField: 'emailKepesertaan',
                                        label: {
                                            text: 'Email'
                                        }
                                    },
                                    {
                                        dataField: 'telpKepesertaan',
                                        label: {
                                            text: 'No. Telp'
                                        },
                                    },
                                    {
                                        dataField: 'noHpKepesertaan',
                                        label: {
                                            text: 'No. HP'
                                        },
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                caption: 'Kontak Person Keuangan',
                                items: [
                                    {
                                        dataField: 'namaKeuangan',
                                        label: {
                                            text: 'Nama'
                                        }
                                    },
                                    {
                                        dataField: 'emailKeuangan',
                                        label: {
                                            text: 'Email'
                                        }
                                    },
                                    {
                                        dataField: 'telpKeuangan',
                                        label: {
                                            text: 'No. Telp'
                                        },
                                    },
                                    {
                                        dataField: 'noHpKeuangan',
                                        label: {
                                            text: 'No. HP'
                                        },
                                    },
                                ]
                            },
                        ]
                    }
                ]
            }
        ]

        this.modalFormRef = React.createRef()
        this.modalEditFormRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalForm = () => {
        this.modalFormRef.current.show()
    }
    
    showModalEdit = (data) => {
        if(data) {
            this.modalEditFormRef.current.show()
            this.modalEditFormRef.current.retrieveData(data)
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalForm()
                    },
                }
            }
        )
    }

    forceRefresh =() => {
        this.dataGridRef.current.forceRefresh()
    }

    loadData = async () => {
        let dataParticipants = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'participants', 'GET')

        let allData = []
        for(let data of dataParticipants){
            let dataAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `view-pesertas/count?participantId.in=${data.id}&statusPeserta.in=2`, 'GET')
            let dataPasif = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `view-pesertas/count?participantId.in=${data.id}&statusPeserta.in=4`, 'GET')
            let dataArsip = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `view-pesertas/count?participantId.in=${data.id}&statusPeserta.in=12`, 'GET')

            data['CountDataAktif'] = dataAktif
            data['CountDataPasif'] = dataPasif
            data['CountDataArsip'] = dataArsip

            allData.push(data)
        }

        return allData
    }

    render() {
        
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pemberi Kerja</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}

                    loadAPI='participants'
                    insertAPI='staticdata-wf/participants'
                    updateAPI='staticdata-wf/participants'
                    deleteAPI='staticdata-wf/participants' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Client Pemberi Kerja"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Client Pemberi Kerja Data'}
                    popupWidth={'70vw'} //masukan dalam ukuran pixel
                    popupHeight={'70vh'} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={2} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    formItems = {this.columns.find(value => value.type === 'form').items}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormModalClientParticipant 
                    ref={this.modalFormRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'add'}
                />  

                <FormModalClientParticipant 
                    ref={this.modalEditFormRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'edit'}
                />  
            </div>
        )
    }
}

export default ClientPemberiKerja