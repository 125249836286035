import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Popup,Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import { banks, branch, jabatan, karyawan, programs, sdmUnit } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

class ViewDaftarKaryawan extends Component {
    constructor(props){
        super(props);

        this.state = {
            popupVisible: false,
            DataMaster:{},
            DataPengajuan: {},
            type: 'view'
        }

        this.username = props.store.getState().sessionUser

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.formMaster = [
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Karyawan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'fullName',
                    // displayExpr: function(item){
                    //     return item && "NIK : " + item.nik + " - " + item.fullName;
                    // },
                    searchEnabled: true,
                    readOnly: true,
                    onSelectionChanged: async(e) => {
                        var data = e.selectedItem;
                        if (data) {
                            var dataMaster = this.state.DataMaster;
                            var DataPengajuan = this.state.DataPengajuan;
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${data.id}`, 'GET');

                            dataMaster.unitId = response.unitId;
                            dataMaster.jabatanId = response.jabatanId;
                            dataMaster.gajiPokok = response.gajiPokok;
                            dataMaster.gajiPokokTunjanganTetap = response.gajiPokokTunjanganTetap;
                            dataMaster.gajiRekNo = response.gajiRekNo;
                            dataMaster.gajiRekName = response.gajiRekName;
                            dataMaster.gajiRekBankId = response.gajiRekBankId;
                            dataMaster.gajiRekBranch = response.gajiRekBranch;
                            // dataMaster.transactionNominal = response.transactionNominal;

                            if (DataPengajuan.dasarPerhitungan == '% Gaji'){
                                dataMaster.transactionNominal = response.gajiPokok * (DataPengajuan.nilaiPersenGapok/100);
                            } else if (DataPengajuan.dasarPerhitungan == '% Gaji + Tunjangan') {
                                dataMaster.transactionNominal = response.gajiPokok * (DataPengajuan.nilaiPersenGapok/100);
                            } else {
                                dataMaster.transactionNominal = DataPengajuan.nilaiNominal;
                            }

                            this.formRef.current.instance.updateData(dataMaster);
                        }
                    }
                }
            },
            {
                dataField: 'unitId',
                label: {
                    text: 'Unit',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                },
            },
            {
                dataField: 'jabatanId',
                label: {
                    text: 'Jabatan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                },
            },
            {
                dataField: 'gajiPokok',
                label: {
                    text: 'Gaji Pokok',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly:true
                },
            },
            {
                dataField: 'gajiPokokTunjanganTetap',
                label: {
                    text: 'Gaji Pokok + Tunjangan',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly:true
                },
            },
            {
                dataField: 'gajiRekNo',
                label: {
                    text: 'Rekening No',
                },
                // editorType: 'dxNumberBox',
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField: 'gajiRekName',
                label: {
                    text: 'Nama Rekening',
                },
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField: 'gajiRekBankId',
                label: {
                    text: 'Bank',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                },
            },
            {
                dataField: 'gajiRekBranch',
                label: {
                    text: 'Cabang',
                },
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField: 'transactionNominal',
                label: {
                    text: 'Nominal',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly:true
                },
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ]

    }

    retrieveData = (data, type) => {
        this.setState({
            // DataPengajuan: data,
            DataMaster: data,
            type: type
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.setState({
            dataMaster: {},
            popupVisible: false,
            type: 'add'
        })
    }

    getKaryawanByUserLogin = async (data) => {
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans?size=9999`, 'GET')
        getData = getData.filter(value => value.id == data.karyawanId);

        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', getData)
    }

    render(){
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Pengembalian Kas Umum'}
                width={"60vw"}
                height={"60vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef} 
            >
                <ScrollView>
                    <Form 
                        ref={this.formRef}
                        colCount={1}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.state.type === 'view' ? this.formMaster : this.formMaster}
                        labelLocation='left'
                    />
                </ScrollView>
            </Popup>
        )
    }
}

export default ViewDaftarKaryawan;