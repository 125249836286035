import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';

class ModalNoSuratCetak extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Cetak',
                    onClick: () => {
                        this.props.cetak(this.state.formData)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'noSurat',
                label: {
                    alignment: 'left',
                    text: 'No Surat'
                },
            },
            {
                dataField: 'tanggalSurat',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Surat'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                }
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,         
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    retrieveData = (data) => {
        this.setState({
            formData: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Cetak Registrasi Pasif'} 
                width={500}
                height={200}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataCetak'}
                                formData={this.state.formData}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation = 'left'
                            />
                        </div>
                    </div>

                </div>
            </Popup>
        );
    }
}


export default ModalNoSuratCetak