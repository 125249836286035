import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, stock, fundManager, productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import ModalInstrumentSaham from './modalInstrumentSaham';
import { inputToTaskInvestasi } from 'plugin/helper';


class FormPengajuanPenempatanSaham extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            kajian: "",
            kajianContentType: 'text/html',
            dataPembelian: {},
        }

        this.prevTabIndex = 0
        this.dataPembelian = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridDetailPenempatanRef = React.createRef()
        this.modalInstrumentSahamRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.kajianPengajuanRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('draft')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('kirim')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' || this.props.actionType == 'add' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'requestNo',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                type: 'empty',
                colSpan: 2
            },
            {
                dataField: 'tanggalOrder',
                label: {
                    text: 'Tanggal Order'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                }
            },
            {
                dataField: 'fundManagerId',
                label: {
                    text: 'Fund Manager'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fundManagerName',
                    searchEnabled: true,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose fund Manager"
                    }
                ]
            },
            {
                type: 'empty',
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose product"
                    }
                ]
            },
            {
                dataField: 'kategori',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'jenisTransaksi',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
        ]
        this.TabDataSource = [
            {
                'id': 0,
                text: 'Instrument Pengajuan'
            },
            {
                'id': 1,
                text: 'Kajian Pengajuan'
            },
        ]

        this.toolbarItem = [
            'background', 'bold', 'color', 'italic', 'link', 'image', 'strike', 'subscript', 'superscript', 'underline', 'blockquote', 'header'
            , 'increaseIndent', 'decreaseIndent', 'orderedList', 'bulletList', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeBlock'
            , 'variable', 'separator', 'undo', 'redo', 'clear', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft',
            'insertColumnRight', 'deleteColumn'
        ]
    }
    getDataGridColumnConfiguration = (actionType) => {
        var columnConfiguration = [
            {
                dataField: 'instrumentId',
                caption: 'Instrumen',
                lookup: {
                    dataSource: stock(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.instrumentCode + " - " + item.instrumentName;
                    },
                }
            },
            {
                dataField: 'ordQty',
                caption: 'Kuantitas (Lembar)',
                width: '150px',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.brokerCode + " - " + item.brokerName;
                    },
                }
            },
            {
                dataField: 'priceType',
                caption: 'Harga',
            },
            {
                dataField: 'minOrdPrice',
                caption: 'Harga Min',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'maxOrdPrice',
                caption: 'Harga Max',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'brokerCommission',
                caption: 'Komisi Nominal',
                width: '150px',
                alignment: 'right',
                format: '#,##0.00'
            },
        ]

        if (actionType === 'view') {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                data.requestNo = this.state.dataMaster.requestNo
                                data.productId = this.state.dataMaster.productId
                                data.priceType = data.priceType.replace("_", " ")
                                this.modalInstrumentSahamRef.current.show()
                                this.modalInstrumentSahamRef.current.retrieveData(data)
                            }
                        },
                    ]
                },
            )
            return columnConfiguration
        } else {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                data.requestNo = this.state.dataMaster.requestNo
                                data.productId = this.state.dataMaster.productId
                                data.priceType = data.priceType.replace("_", " ")
                                this.modalInstrumentSahamRef.current.show()
                                this.modalInstrumentSahamRef.current.retrieveData(data)
                            }
                        },
                        {
                            text: 'Hapus',
                            hint: 'Hapus',
                            onClick: (e) => {
                                if (this.props.actionType == 'view') {
                                    notify({ message: 'Sorry, you cant remove data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1500);
                                }
                                else {
                                    this.deleteDetailPenempatanData(e.row.key)
                                }
                            }
                        },
                    ]
                },
            )

            return columnConfiguration
        }
        return columnConfiguration
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });

    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    retrieveData = (dataMaster, dataPembelian, kajian) => {
        if(this.props.actionType === 'add') {
            dataMaster = {
                requestNo: '<AUTO>',
                tanggalOrder: new Date(this.sysDate.dateValue),
                kategori: 'Saham',
                jenisTransaksi: 'Penempatan'
            }

            this.setState ({
                dataMaster: dataMaster,
                dataPembelian: dataPembelian,
                kajian: kajian,
            })
        } else {
            if (dataMaster) {
                this.dataPembelian = dataPembelian || []
                dataMaster.jenisTransaksi = 'Penempatan'
            }
            dataMaster = dataMaster || {
                requestNo: '<AUTO>',
                tanggalOrder: new Date(this.sysDate.dateValue),
                kategori: 'Saham',
                jenisTransaksi: 'Penempatan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPembelian: dataPembelian,
                kajian: kajian,
            });
            this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
            // this.dataGridDetailPenempatanRef.current.forceRefresh(true)
        
        }
    }
    
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    submitData = async (type) => {
        var dataDataGrid = this.dataGridDetailPenempatanRef.current.getDataSource()
        var kajian = btoa(this.state.kajian)
        var kajianType = this.state.kajianContentType
        var draft = type == 'draft' ? true : false
        var dataMaster = this.state.dataMaster
        var customSubmitData = {
            ...dataMaster,
            draft,
            kajian: kajian,
            kajianContentType: kajianType,
            instrumentStokList: dataDataGrid,
            requestOrderId: dataMaster.actionType == 'edit' ? dataMaster.requestId : null
        }

        if (dataDataGrid.length == 0) {
            notify({ message: 'Harap isi data pengajuan terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        } else {
            if (customSubmitData) {
                try {
                    var insertAPI = 'order-stoks/pengajuan-penempatan-stok'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: customSubmitData
                    })

                    if (type == 'kirim') {
                        try {
                            await inputToTaskInvestasi(this.props.store, customSubmitData.requestOrderId, customSubmitData.orderTypeId)
                        } catch (e) {
                            console.log(e);
                            notify({ message: "Terjadi kesalahan saat input data ke task", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }

                    }

                    var message = dataMaster.actionType == 'edit' ? 'Data Pengajuan berhasil diubah!' : 'Pengajuan berhasil!'
                    notify({ message: message, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.hide()
                    this.props.forceRefresh()
                    return response
                } catch (e) {
                    console.log(e)
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }
        }
    }

    loadDetailPenempatan = () => {
        return this.dataPembelian
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindexPenempatanStok-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexPenempatanStok-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) => {
        this.setState({
            kajian: e.value
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Instrument Penempatan',
                    onClick: (e) => {
                        this.showModalInstrumentPenempatan()
                    },
                },
                visible: this.props.actionType == 'view' ? false : true
            }
        )
    }

    showModalInstrumentPenempatan = () => {
        this.modalInstrumentSahamRef.current.show()
        this.modalInstrumentSahamRef.current.retrieveData({
            requestNo: this.state.dataMaster.requestNo,
            productId: this.state.dataMaster.productId
        })
    }

    submitDetailPenempatanData = (data) => {
        if (data.id) {
            this.dataGridDetailPenempatanRef.current.push([{
                type: "update",
                data: {
                    instrumentId: data.instrumentId,
                    ordQty: data.ordQty,
                    priceType: data.priceType,
                    brokerId: data.brokerId,
                    brokerCommission: data.brokerCommission,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    catatan: data.catatan,
                    tanggalPenempatan: data.tanggalPenempatan
                },
                key: data.id
            }])
        } else {
            this.dataGridDetailPenempatanRef.current.push([{
                type: "insert",
                data: {
                    instrumentId: data.instrumentId,
                    ordQty: data.ordQty,
                    priceType: data.priceType,
                    brokerId: data.brokerId,
                    brokerCommission: data.brokerCommission,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    catatan: data.catatan,
                    tanggalPenempatan: data.tanggalPenempatan
                }
            }])
        }
    }

    deleteDetailPenempatanData = (key) => {
        this.dataGridDetailPenempatanRef.current.push([{
            type: "remove",
            key: key
        }])
    }

    render() {
        let dataGridElem
        if(this.state.popupVisible){
            dataGridElem = <DevExpressDataGrid
                                ref={this.dataGridDetailPenempatanRef}
                                loadAPI='brokers'
                                insertAPI='brokers'
                                updateAPI='brokers'
                                deleteAPI='brokers'

                                useArraySource={true}
                                ArraySourceData={this.loadDetailPenempatan}

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Broker"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                editingMode={'cell'}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Broker'}
                                popupWidth={400} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup
                                height={'calc(100vh - 380px)'}
                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.getDataGridColumnConfiguration(this.props.actionType)} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
        }else{
            dataGridElem = null
        }
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Form Usulan Penempatan Investasi Saham`}
                    minWidth={'90vw'}
                    height="90vh"

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={3}
                                    id={'formDataMaster'}
                                    ref={this.formMasterRef}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation={"left"}
                                />
                            </div>
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                            <div id="tabindexPenempatanStok-0" className={`col-md-12`} style={{ height: "calc(100vh - 380px)", overflowY: "auto" }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        {dataGridElem}
                                    </div>
                                </div>
                            </div>
                            <div id="tabindexPenempatanStok-1" className={`col-md-12 d-none`} style={{ height: "calc(100vh - 380px)", overflowY: "auto" }}>
                                <div className="col-md-12">
                                    <HtmlEditor
                                        ref={this.kajianPengajuanRef}
                                        height="calc(100vh - 380px)"
                                        value={this.state.kajian}
                                        valueType={this.state.kajianContentType}
                                        name={'kajian'}
                                        onValueChanged={this.onValueChanged}
                                        readOnly={this.props.actionType == 'view' ? true : false}
                                    >
                                        <MediaResizing enabled={true} />
                                        <Toolbar>
                                            {
                                                this.toolbarItem.map((toolbarItem) => {
                                                    return (
                                                        <Item
                                                            formatName={toolbarItem}
                                                        />
                                                    )
                                                })
                                            }
                                        </Toolbar>
                                    </HtmlEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
                <ModalInstrumentSaham store={this.props.store} ref={this.modalInstrumentSahamRef} submitDetailPenempatanData={this.submitDetailPenempatanData} type={this.props.actionType} />
            </React.Fragment>
        )
    }
}

export default FormPengajuanPenempatanSaham
