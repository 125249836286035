import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { banks, instrumentSubCategoryDeposito, currency, fundManager, rekeningBank } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';


class FormSubInstrumentPenempatanDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {}
        }

        this.prevTabIndex = 0
        this.productId = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                name: 'datadeposito',
                items: [
                    {
                        dataField: 'requestNo',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true,
                            onSelectionChanged: (e) => {
                                var data = e.selectedItem
                                
                                this.setState({
                                    dataDetail: data
                                })
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose bank"
                            }
                        ]
                    },
                    {
                        dataField: 'tanggalPenempatan',
                        label: {
                            text: 'Tanggal Penempatan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instSubCategoryId',
                        label: {
                            text: 'Tipe Deposito'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentSubCategoryDeposito(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName',
                            searchEnabled: true,
                            deferRendering: false,
                            // onValueChanged: (e) => {
                            //     this.formMasterRef.current.instance.beginUpdate()
                            //     if (e.value == 18) {
                            //         this.formMasterRef.current.instance.itemOption('datadeposito.autoRenewal', 'visible', true)
                            //         this.formMasterRef.current.instance.itemOption('datadeposito.instructionOnMaturity', 'visible', true)
                            //     } else {
                            //         this.formMasterRef.current.instance.itemOption('datadeposito.autoRenewal', 'visible', false)
                            //         this.formMasterRef.current.instance.itemOption('datadeposito.instructionOnMaturity', 'visible', false)
                            //     }
                            //     this.formMasterRef.current.instance.endUpdate()
                            // },
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill Deposito type"
                            }
                        ]
                    },
                    {
                        dataField: 'autoRenewal',
                        label: {
                            text: 'Auto Renewal'
                        },
                        editorType: 'dxCheckBox',
                        editorOptions: {
                        },
                        visible: false
                    },
                    {
                        dataField: 'instructionOnMaturity',
                        label: {
                            text: 'Instruksi Saat Jatuh Tempo'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'P',
                                    value: 'Bunga akan menambah pokok deposito'
                                },
                                {
                                    id: 'R',
                                    value: 'Bunga di pindahkan ke rekening'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                        },
                        visible: false
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            onValueChanged: async (e) => {
                                var currData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${e.value}`, 'GET')
                                this.formMasterRef.current.instance.updateData('kurs', currData.rate)
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose currency"
                            }
                        ]
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            value: 1,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'nominal',
                        label: {
                            text: 'Nominal Penempatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill placement amomunt"
                            }
                        ]
                    },
                    {
                        dataField: 'rate',
                        label: {
                            text: 'Rate Deposito (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill rate Deposito"
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'tenor',
                                label: {
                                    text: 'Tenor'
                                },
                                editorType: 'dxNumberBox',
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please fill tenor"
                                    }
                                ]
                            },
                            {
                                dataField: 'tenorTypeId',
                                label: {
                                    visible: false
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: 'horizontal',
                                    items: ['HARI', 'BULAN'],
                                    value: 'tenorTypeId'
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please choose tenor type"
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        dataField: 'rolloverAuto',
                        label: {
                            text: 'Perpanjangan'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['Otomatis', 'Manual'],
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose extension type"
                            }
                        ]
                    },
                    {
                        dataField: 'rolloverTypeId',
                        label: {
                            text: 'Nilai Perpanjangan'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: ['Pokok + Bunga', 'Pokok'],
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose extension"
                            }
                        ]
                    },
                    {
                        dataField: 'sourceRekbankId',
                        label: {
                            text: 'Rekening Penempatan/Pembayaran',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.rekNo + ' - ' + item.rekName
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose placement account"
                            }
                        ]
                    },
                    {
                        dataField: 'rekbankId',
                        label: {
                            text: 'Rekening Pencairan (Pokok/Bunga)',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.rekNo + ' - ' + item.rekName
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose disbursement account"
                            }
                        ]
                    },
                ]
            }
        ]
        // this.formDetailItem = [
        //     {
        //         itemType: 'group',
        //         caption: 'INFORMASI BANK',
        //         items: [
        //             {
        //                 dataField: 'updateDate',
        //                 label: {
        //                     text: 'Tanggal Update'
        //                 },
        //                 editorType: 'dxDateBox',
        //                 editorOptions: {
        //                     displayFormat: 'd MMM yyyy'
        //                 }
        //             },
        //             {
        //                 dataField: 'kategoriBank',
        //                 label: {
        //                     text: 'Kategori Bank'
        //                 },
        //             },
        //             {
        //                 dataField: 'returnOnAsset',
        //                 label: {
        //                     text: 'ROA'
        //                 },
        //             },
        //             {
        //                 dataField: 'returnOnEquity',
        //                 label: {
        //                     text: 'ROE'
        //                 },
        //             },
        //             {
        //                 dataField: 'capitalAdequacyRatio',
        //                 label: {
        //                     text: 'CAR/KPMM'
        //                 },
        //             },
        //             {
        //                 dataField: 'nplGross',
        //                 label: {
        //                     text: 'NPL'
        //                 },
        //             },
        //             {
        //                 dataField: 'loanDepositRatio',
        //                 label: {
        //                     text: 'LDR'
        //                 },
        //             },
        //             {
        //                 dataField: 'totalAsset',
        //                 label: {
        //                     text: 'Nilai Aset'
        //                 },
        //                 editorType: 'dxNumberBox',
        //                 editorOptions: {
        //                     format: '#,##0.00'
        //                 }
        //             },
        //         ]
        //     },
        //     // {
        //     //     itemType: 'group',
        //     //     caption: 'INFORMASI PORTOFOLIO PADA BANK',
        //     //     items: [
        //     //         {
        //     //             dataField: 'jumlahDeposito',
        //     //             label: {
        //     //                 text: 'Jumlah Deposito'
        //     //             },
        //     //             editorType: 'dxNumberBox',
        //     //             editorOptions: {
        //     //                 format: '#,##0.00'
        //     //             }
        //     //         },
        //     //         {
        //     //             dataField: 'totalNilaiDeposito',
        //     //             label: {
        //     //                 text: 'Total Nilai Deposito'
        //     //             },
        //     //             editorType: 'dxNumberBox',
        //     //             editorOptions: {
        //     //                 format: '#,##0.00'
        //     //             }
        //     //         },
        //     //         {
        //     //             dataField: 'komposisiInstrument',
        //     //             label: {
        //     //                 text: 'Komposisi Instrument'
        //     //             },
        //     //             editorType: 'dxNumberBox',
        //     //             editorOptions: {
        //     //                 format: '#,##0.00'
        //     //             }
        //     //         },
        //     //         {
        //     //             dataField: 'komposisiPerBank',
        //     //             label: {
        //     //                 text: 'Komposisi Per Bank'
        //     //             },
        //     //             editorType: 'dxNumberBox',
        //     //             editorOptions: {
        //     //                 format: '#,##0.00'
        //     //             }
        //     //         },
        //     //     ]
        //     // }
        // ]

        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI BANK',
                cssClass: "group-radius",
                items: [
                    {
                        dataField: 'alamat',
                        label: {
                            text: 'Alamat'
                        },
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            text: 'Nomor Telepon'
                        },
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'email',
                        label: {
                            text: 'Email'
                        },
                        editorOptions: {
                        }
                    },
                    // {
                    //     dataField: 'updateDate',
                    //     label: {
                    //         text: 'Tanggal Update'
                    //     },
                    //     editorType: 'dxDateBox',
                    //     editorOptions: {
                    //         elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
                    //         displayFormat: 'd MMM yyyy'
                    //     }
                    // },
                    // {
                    //     dataField: 'kategoriBank',
                    //     label: {
                    //         text: 'Kategori Bank'
                    //     },
                    //     editorOptions: {
                    //         elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
                    //     }
                    // },
                    {
                        dataField: 'returnOnAsset',
                        label: {
                            text: 'ROA'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            // format: "#,##0.00 %"
                            format: '#0,## %',
                        },
                    },
                    {
                        dataField: 'returnOnEquity',
                        label: {
                            text: 'ROE'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            // format: "#,##0.00 %"
                            format: '#0,## %',
                        },
                    },
                    {
                        dataField: 'capitalAdequacyRatio',
                        label: {
                            text: 'CAR/KPMM'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            // format: "#,##0.00 %"
                            format: '#0,## %',
                        },
                    },
                    {
                        dataField: 'nplGross',
                        label: {
                            text: 'NPL'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            // format: "#,##0.00 %",
                            format: '#0,## %'
                        },
                    },
                    {
                        dataField: 'loanDepositRatio',
                        label: {
                            text: 'LDR'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            // format: "#,##0.00 %",
                            format: '#0,## %',
                        },
                    },
                    {
                        dataField: 'totalAsset',
                        label: {
                            text: 'Nilai Aset'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                ]
            },
            // {
            //     itemType: 'group',
            //     caption: 'INFORMASI PORTOFOLIO PADA BANK',
            //     cssClass: "group-radius",
            //     items: [
            //         {
            //             dataField: 'jumlahDeposito',
            //             label: {
            //                 text: 'Jumlah Deposito'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'totalNilaiDeposito',
            //             label: {
            //                 text: 'Total Nilai Deposito'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'komposisiInstrument',
            //             label: {
            //                 text: 'Komposisi Instrument'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
            //                 format: '#,##0.00'
            //             }
            //         },
            //         {
            //             dataField: 'komposisiPerBank',
            //             label: {
            //                 text: 'Komposisi Per Bank'
            //             },
            //             editorType: 'dxNumberBox',
            //             editorOptions: {
            //                 elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
            //                 format: '#,##0.00'
            //             }
            //         },
            //     ]
            // }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = (data) => {
        if(data){
            this.productId = data.productId
            this.setState({
                data: {
                    id: data.id,
                    requestNo: data.requestNo,
                    bankId: data.bankId,
                    tanggalPenempatan: data.tanggalPenempatan,
                    instSubCategoryId: data.instSubCategoryId,
                    autoRenewal: data.autoRenewal,
                    instructionOnMaturity: data.instructionOnMaturity,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nominal: data.nominal,
                    rate: data.rate,
                    tenorTypeId: data.tenorTypeId,
                    tenor: data.tenor,
                    rolloverAuto: data.rolloverAuto == true ? 'Otomatis' : 'Manual',
                    rolloverTypeId: data.rolloverTypeId == 0 ? 'Pokok + Bunga' : 'Pokok',
                    nilaiPerpanjangan: data.nilaiPerpanjangan,
                    sourceRekbankId: data.sourceRekbankId,
                    rekbankId: data.rekbankId,
                },
                // dataDetail: {},
            });
        }
    }

    hide() {
        this.productId = 0
        this.setState({
            popupVisible: false,
        })
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    submitData = async () => {
        var data = this.state.data
        let validateBatasan = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-validasi-pengajuan-inv/${this.productId}/${data.instSubCategoryId}/${data.nominal}`, 'GET')
        if (validateBatasan.length > 0) {
            console.log(validateBatasan[0].potensiselisih >= 0);
            console.log(validateBatasan[0].potensiselisih < 0);
            if (validateBatasan[0].potensiselisih >= 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")

                this.props.submitDetailPenempatanData({
                    id: data.id,
                    requestNo: data.requestNo,
                    bankId: data.bankId,
                    tanggalPenempatan: formatDate(data.tanggalPenempatan),
                    instSubCategoryId: data.instSubCategoryId,
                    autoRenewal: data.autoRenewal,
                    instructionOnMaturity: data.instructionOnMaturity,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nominal: data.nominal,
                    rate: data.rate,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    rolloverAuto: data.rolloverAuto == 'Otomatis' ? true : false,
                    rolloverTypeId: data.rolloverTypeId == 'Pokok + Bunga' ? 0 : 1,
                    nilaiPerpanjangan: data.nilaiPerpanjangan,
                    sourceRekbankId: data.sourceRekbankId,
                    rekbankId: data.rekbankId,
                })

                this.hide()

            } else if (validateBatasan[0].potensiselisih < 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")
            } else {
                await alert("Terdapat Kesalahan Data", "Informasi")
            }
        } else {
            await alert("Batasan Resiko Belum di Setting", "Informasi")
        }

        return
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Penempatan Investasi Deposito'}
                width={'90vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.data}
                                        items={this.formItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={this.props.type == 'view' ? true : false}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster1'}
                                        ref={this.formDetailRef}
                                        formData={this.state.dataDetail}
                                        items={this.formDetailItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormSubInstrumentPenempatanDeposito