import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { formatDefaultDate } from 'plugin/helper'

class SebaranRisiko extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);
        this.columnDate = formatDefaultDate(this.date)


        this.state = {
        }
        this.columns = [
            {
                dataField: 'subName',
                caption: 'Asset Investasi'
            },
            {
                dataField: 'resikoKelas',
                caption: 'Kelas Risiko',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'bearish',
                caption: this.columnDate,
                alignment: 'center',
                columns: [
                    {
                        dataField: 'nilaiPenempatan',
                        caption: 'Nilai Wajar',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'bobot',
                        caption: 'Bobot Portofolio (Nilai wajar / Total nilai wajar x 100%)',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                        cellRender: (e) => {
                            let percent = e.text ? ' %' : ' '
                            return e.text + percent
                        }
                    },
                    {
                        dataField: 'kontribusiResiko',
                        caption: 'Kontribusi Risiko (Kelas risiko x Bobot portfolio)',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    }
                ]
            },
        ]
        this.sumRisiko = [
            {
                showInColumn: 'kelasRisiko',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'nilaiPenempatan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumNilaiWajar',
                displayFormat: '{0}'
            },
            // {
            //     column: 'bobot',
            //     summaryType: 'sum',
            //     name: 'sumBobot',
            //     valueFormat: '#,##0.00',
            //     displayFormat: '{0} %'
            // },
            {
                column: 'kontribusiResiko',
                summaryType: 'sum',
                name: 'sumKontribusi',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Sebaran Risiko</h2>

                <DevExpressDataGrid
                    loadAPI='view-sebaran-resikos?size=9999'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Sebaran Risiko"}
                    allowExportSelectedData={true}
                    selection={false}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Risiko Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100% - 233px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumRisiko}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default SebaranRisiko