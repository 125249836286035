import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import httpRequest from "plugin/httprequest";
import { currency, entityType, settleWith, banks, arapReffType, arapType, coa } from "dataSource/lookup";
import DataSource from "devextreme/data/data_source";
import DevExpressDataGrid from "components/inheritComponent/devexpressdatagrid";
import ScrollView from "devextreme-react/scroll-view";
import ModalRejectCashVoucher from "./rejectNotes";

class ModalCashSettlementVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {
            }
        };

        this.clientBankDataSource = new DataSource({
            store: {
                data: [],
                type: 'array',
                key: 'id'
            }
        });

        this.formItem = [
            {
                itemType: 'group',
                name: 'entityInformation',
                caption: 'Informasi Entitas',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'entityName',
                        label: {
                            alignment: 'left',
                            text: 'Nama'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'entityType',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Entitas'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: entityType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'status',
                        label: {
                            alignment: 'left',
                            text: 'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'RV',
                                    value: 'Request Verification'
                                },
                                {
                                    id: 'A',
                                    value: 'Verified'
                                },
                                {
                                    id: 'F',
                                    value: 'Verification Failed'
                                },
                                {
                                    id: 'R',
                                    value: 'Rejected'
                                },
                                {
                                    id: 'O',
                                    value: 'Open'
                                },
                                {
                                    id: 'C',
                                    value: 'Cancel'
                                },
                                {
                                    id: 'P',
                                    value: 'Posted'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'cashSettlement',
                caption: 'Cash Settlement',
                colSpan: 2,
                colCount: 3,
                items: [
                    {
                        dataField: 'voucherNo',
                        label: {
                            alignment: 'left',
                            text: 'Kode Voucher'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'entryDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Entri'
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            // value: new Date(),
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'valueDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Jurnal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            // value: new Date(),
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'amount',
                        label: {
                            alignment: 'left',
                            text: 'Total'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'settleWith',
                        label: {
                            alignment: 'left',
                            text: 'Penyelesain Dengan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: settleWith(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            // onSelectionChanged: (e) => {
                            //     if (e.selectedItem.id === 'B') {
                            //         setTimeout(() => {
                            //             var companyBankEditor = this.formRef.current.instance.getEditor('companyBankId')
                            //             companyBankEditor.option('dataSource', this.companyBank)
                            //         }, 1000)

                            //         this.formRef.current.instance.updateData(this.data)
                            //     }
                            // },
                            onValueChanged: (e) => {
                                this.formRef.current.instance.beginUpdate()
                                if (e.value === 'B') {
                                    this.formRef.current.instance.itemOption('cashSettlement.companyBank', 'visible', true)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankId', 'visible', true)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankNo', 'visible', true)
                                    this.formRef.current.instance.itemOption('cashSettlement.transferFee', 'visible', true)
                                } else {
                                    this.formRef.current.instance.itemOption('cashSettlement.companyBank', 'visible', false)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankId', 'visible', false)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankNo', 'visible', false)
                                    this.formRef.current.instance.itemOption('cashSettlement.transferFee', 'visible', false)
                                }
                                this.formRef.current.instance.endUpdate()

                            },
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'companyBank',
                        label: {
                            alignment: 'left',
                            text: 'Bank Perusahaan'
                        },
                        // editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: [],
                            // valueExpr: 'id',
                            // displayExpr: 'value',
                            // searchEnabled: true,
                            // deferRendering: false,
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        dataField: 'clientBankId',
                        label: {
                            alignment: 'left',
                            text: 'Kode Bank Lawan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.initialName + ' - ' + item.bankCode
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        dataField: 'clientBankNo',
                        label: {
                            alignment: 'left',
                            text: 'No.Rek Bank Lawan'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: 'numeric',
                                message: 'Nomor rekening tidak valid'
                            }
                        ],
                        visible: false
                    },
                    {
                        dataField: 'description',
                        label: {
                            alignment: 'left',
                            text: 'Deskripsi'
                        },
                        editorOptions: {
                            readOnly: true
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'transferFee',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00;(#,##0.00);',
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        dataField: 'coaPphAkunId',
                        label: {
                            alignment: 'left',
                            text: 'COA Pemotong'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (e) => {
                                if (e.value) {
                                    this.formRef.current.instance.itemOption('cashSettlement.pphNominal', 'isRequired', true)
                                } else {
                                    this.formRef.current.instance.itemOption('cashSettlement.pphNominal', 'isRequired', false)
                                }
                            },
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'pphNominal',
                        label: {
                            alignment: 'left',
                            text: 'COA Nilai'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00'
                        },
                    },
                    {
                        dataField: 'pembulatanNominal',
                        label: {
                            alignment: 'left',
                            text: 'Selisih Pembulatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00'
                        },
                    },
                    {
                        dataField: 'transferFee',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        },
                        visible: false,
                        isRequired: true
                    },
                ]
            },
            {
                itemType: 'group',
                colSpan: 2,
                colCount: 3,
                items: [
                    {
                        dataField: 'rejectedBy',
                        label: {
                            alignment: 'left',
                            text: 'Ditolak Oleh'
                        },
                        visible: this.props.type == 'view' ? true : false
                    },
                    {
                        dataField: 'verifikasiBy',
                        // colSpan: 2,
                        label: {
                            alignment: 'left',
                            text: 'Di Verifikasi Oleh'
                        },
                        visible: this.props.type == 'view' ? true : this.props.type == 'verif' ? false : 'Posting',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'approveBy',
                        label: {
                            alignment: 'left',
                            text: 'Di setujui Oleh'
                        },
                        visible: this.props.type == 'view' ? true : this.props.type == 'verif' ? false : this.props.type == 'approve' ? false : 'Posting',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colSpan: 2,
                colCount: 3,
                items: [
                    {
                        dataField: 'notes',
                        colSpan: 3,
                        label: {
                            alignment: 'left',
                            text: 'Alasan Ditolak'
                        },
                        visible: this.props.type == 'view' ? true : false
                    },
                ]
            },
        ];

        this.columns = [
            // {
            //   type: 'buttons',
            //   buttons: [
            //     {
            //       text: 'Detail Transaksi',
            //       hint: 'Detail Transaksi',
            //       cssClass: 'text-success',
            //       onClick: (e) => {
            //         this.showModalDetail(e.row.data)
            //       }
            //     },
            //   ],
            // },
            {
                dataField: 'reffNo',
                caption: 'No Transaksi',
            },
            {
                dataField: 'reffType',
                caption: 'Tipe Transaksi',
                lookup: {
                    dataSource: arapReffType(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                dataField: 'tradeDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'dueDate',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                }
            },
            {
                dataField: 'type',
                caption: 'Tipe',
                lookup: {
                    dataSource: arapType(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                dataField: 'amount',
                caption: 'Nominal Transaksi',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'outstandingAmount',
                caption: 'Sisa Nominal Transaksi',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'amountPaid',
                caption: 'Nominal Bayar / Terima',
                format: '#,##0.00',
                alignment: 'right'
            },
        ]


        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: this.props.type === "verif" ? "Verifikasi" : this.props.type === "approve" ? "Setuju" : "Posting",
                    onClick: async () => {
                        if (this.state.formData.status === 'C') {
                            notify({ message: 'Voucher Telah Dibatalkan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        if (this.state.formData.status === 'F') {
                            notify({ message: 'Verifikasi Voucher gagal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        if (this.state.formData.status === 'R') {
                            notify({ message: 'Voucher telah ditolak', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        if (this.state.formData.status === 'P') {
                            notify({ message: 'Voucher Telah Diposting', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        let result = await confirm(`Apakah anda yakin untuk ${this.props.type === "verif" ? "memverifikasi" : this.props.type === "approve" ? "menyetujui" : "memposting"} voucher ini?`, "Konfirmasi");
                        if (result) {
                            await this.props.posting(this.state.formData)

                            this.hide()
                        }
                    }
                },
                toolbar: "bottom",
                visible: this.props.type === "view" ? false : true,
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: this.props.type === "verif" || this.props.type === "approve" ? "Tolak" : "Batal",
                    onClick: async () => {
                        if (this.state.formData.status === 'C') {
                            notify({ message: 'Voucher Telah Dibatalkan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        if (this.state.formData.status === 'F') {
                            notify({ message: 'Verifikasi Voucher gagal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        if (this.state.formData.status === 'R') {
                            notify({ message: 'Voucher telah ditolak', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        if (this.state.formData.status === 'P') {
                            notify({ message: 'Voucher Telah Diposting', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        this.modalRejectRef.current.show(this.state.formData);
                    }
                },
                toolbar: "bottom",
                visible: this.props.type === "view" ? false : true,
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.companyBank = ""

        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalRejectRef = React.createRef()
    }
    cancel = async (data) => {
        let result = await confirm(`Apakah anda yakin untuk ${this.props.type === "verif" ? "menolak" : this.props.type === "approve" ? "menolak" : "membatalkan"} voucher ini?`, "Konfirmasi");
        if (result) {
            await this.props.cancel(data)
            this.modalRejectRef.current.hide()

            this.hide()
        }
    }

    retrieveData = async (data) => {
        var dataVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/${data.cashVoucherId}`)
        dataVoucher.companyBank = dataVoucher.companyBankId
        dataVoucher.companyBankId = `${dataVoucher.companyBankReff}-${dataVoucher.companyBankId}`
        data = {
            ...data,
            ...dataVoucher
        }
        var dataVoucherDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucer-detils/getByCashVoucherId/${data.cashVoucherId}`)

        var arapIds = dataVoucherDetil.map(value => value.arapId)

        dataVoucherDetil = dataVoucherDetil.map(value => {
            return {
                type: "insert",
                data: value
            }
        })

        var reffType = []
        for (const arapId of arapIds) {
            var loadArapAPI = `araps/${arapId}`
            var responseArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadArapAPI)
            reffType.push(responseArap.reffType)
        }

        reffType = [...new Set(reffType)]

        var companyBank = []

        if (data.settleWith == "B") {
            for (const reff of reffType) {
                let dataRekBank
                let dataBank
                switch (reff) {
                    case 'IN':
                        dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-banks/${dataVoucher.companyBank}`)
                        dataBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `banks/${dataRekBank.bankId}`)
                        this.companyBank = `${dataBank ? dataBank.initialName : null} - ${dataRekBank ? dataRekBank.rekNo : null} - ${dataRekBank ? dataRekBank.rekName : null}`
                        break;
                    case 'HR':
                        dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `rek-banks/${dataVoucher.companyBank}`)
                        dataBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `banks/${dataRekBank.bankId}`)
                        this.companyBank = `${dataBank ? dataBank.initialName : null} - ${dataRekBank ? dataRekBank.rekNo : null} - ${dataRekBank ? dataRekBank.rekName : null}`
                        break;
                    case 'KP':
                        dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `rekbanks/${dataVoucher.companyBank}`)
                        dataBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `banks/${dataRekBank.bankId}`)
                        this.companyBank = `${dataBank ? dataBank.initialName : null} - ${dataRekBank ? dataRekBank.rekNo : null} - ${dataRekBank ? dataRekBank.rekName : null} `
                        break;

                    default:
                        break;
                }
            }
        }

        // for (const reff of reffType) {
        //     let dataRekBank
        //     switch (reff) {
        //         case 'IN': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-banks')
        //             dataRekBank = dataRekBank.map(value => {
        //                 const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
        //                 console.log(bank);
        //                 return {
        //                     id: `${reff}-${value.id}`,
        //                     value: `${bank ? bank.initialName : null} - ${value.rekNo}`
        //                 }
        //             })
        //             break;
        //         case 'HR': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'rek-banks')
        //             dataRekBank = dataRekBank.map(value => {
        //                 const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
        //                 return {
        //                     id: `${reff}-${value.id}`,
        //                     value: `${bank ? bank.initialName : null} - ${value.rekNo}`
        //                 }
        //             })
        //             break;
        //         case 'KP': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'rekbanks')
        //             dataRekBank = dataRekBank.map(value => {
        //                 const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
        //                 return {
        //                     id: `${reff}-${value.id}`,
        //                     value: `${bank ? bank.initialName : null} - ${value.rekNo}`
        //                 }
        //             })
        //             break;
        //         default: break;
        //     }

        //     companyBank = [...companyBank, ...dataRekBank]
        // }
        // this.companyBank = companyBank
        data.companyBank = this.companyBank || null
        this.formRef.current.instance.updateData(data)
        this.dataGridRef.current.push(dataVoucherDetil)
        this.dataGridRef.current.forceRefresh(true)
    }

    show = () => {
        this.setState({
            popupVisible: true
        });
    };

    hide = () => {
        this.companyBank = ""
        this.setState({
            popupVisible: false,
        });
    };


    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Form ${this.props.type === "verif" ? "Verifikasi" : this.props.type === "approve" ? "Persetujuan" : this.props.type === "view" ? " " : "Posting"} Cash Voucher`}
                    minWidth={"70vw"}
                    minHeight={"80vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <ScrollView>
                        <Form
                            colCount={2}
                            id={"formMaster"}
                            formData={this.state.formData}
                            items={this.formItem}
                            ref={this.formRef}
                            labelLocation="left"
                            readOnly={this.props.type === 'view' ? true : false}
                        />
                        <DevExpressDataGrid
                            ref={this.dataGridRef}
                            keyField="cashVoucherDetilId"
                            loadAPI='arap'
                            insertAPI='arap'
                            updateAPI='arap'
                            deleteAPI='arap'

                            backendserver={process.env.REACT_APP_BACKEND_CORE}

                            useArraySource={true}
                            ArraySourceData={[]}

                            editingMode="row"

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={false}
                            exportFileName={"Outstanding Cash Settlement"}
                            allowExportSelectedData={false}
                            selection={"none"}

                            showBorders={true}

                            paging={false}
                            defaultPageSize={10}
                            grouping={false}

                            height="calc(100% - 300px)"

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Outstanding Cash Settlement'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}

                            summaryTotalItem={this.sumDebit}
                            //   onToolbarPreparing={this.onToolbarPreparing}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </ScrollView>
                </Popup>
                <ModalRejectCashVoucher
                    ref={this.modalRejectRef}
                    store={this.props.store}
                    cancel={this.cancel}
                />
            </React.Fragment>

        );
    }
}

export default ModalCashSettlementVoucher;