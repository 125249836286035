import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import TransaksiMpManfaatLainnyaModal from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/manfaatLainnya/detailPembayaranModal';
import TransaksiMpManfaatLainnyaSkBayar from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/manfaatLainnya/viewSkBayar';
import { product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';

class TransaksiPembayaranMpManfaatLain extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formFilter: {
                produk: 1
            }
        }

        this.resultData = []

        this.dataGridRef = React.createRef()
        this.transaksiPembayaranMpDetailModalRef = React.createRef()
        this.transaksiPembayaranMpUploadSkModalRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'produk',
                label: {
                    text: 'Produk',
                    alignment: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
            },
            {
                dataField: 'awalTanggalPembayaran',
                label: {
                    text: 'Tanggal Pembayaran',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                dataField: 'akhirTanggalPembayaran',
                label: {
                    text: 'S/D',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Filter',
                    onClick: () => {
                        this.filterDataByDate();
                    }
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text: 'Lihat SK',
                        cssClass: 'text-info',
                        onClick: (e) => {
                            this.showModalUploadSk()
                        }
                    },
                ],
            },
            {
                dataField: 'productIds',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                },
            },
            {
                dataField: 'mpNames',
                caption: 'Nama Pembayaran Lain',
            },
            {
                dataField: 'totalPeserta',
                caption: 'Jumlah Pensiunan',
                alignment: 'right',
                format: "#,##0",
            },
            {
                dataField: 'mpNominals',
                caption: 'Total Nilai Pembayaran Lain',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'paymentDates',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',  
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'resume',
                caption: 'Resume',
                alignment:'center',
                columns: [
                    {
                        dataField: 'rekbank',
                        caption: 'Bank Bayar'
                    },
                    {
                        dataField: 'totalFeeBank',
                        caption: 'Total Biaya Bank',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'retur',
                        caption: 'Retur',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                ]
            },
            {
                dataField: 'arStatus',
                caption: 'AR/AP Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Maker'
                        },
                        {
                            id: 2,
                            value: 'Checker'
                        },
                        {
                            id: 3,
                            value: 'Approval'
                        },
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                },
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
        this.filterDataByDate()
    }

    showModalGeneratePembayaran = () => {
        this.pembayaranMpGeneratePembayaranModalRef.current.show()
    }

    showModalDetail = (data) => {
        this.transaksiPembayaranMpDetailModalRef.current.show()
        this.transaksiPembayaranMpDetailModalRef.current.retrieveData(data)
    }

    showModalUploadSk = () => {
        this.transaksiPembayaranMpUploadSkModalRef.current.show()
    }

    filterDataByDate = async() => {
        let filter = this.state.formFilter

        let loadAPI = `pembayaran-mp-lainnya/getByCategoryNidAndStatus/2/5`
        let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        let dataResult = []

        for(let value of response) {
            let dataValue = {
                ...value
            }

            dataResult.push(dataValue)
        } 

        if(filter.produk && filter.awalTanggalPembayaran && filter.akhirTanggalPembayaran) {
            let data = dataResult.filter(val => {
                let produk = val.productIds
                let tanggalPembayaran = new Date(val.paymentDates)
                return produk === filter.produk && formatDate(tanggalPembayaran) >= formatDate(filter.awalTanggalPembayaran) && formatDate(tanggalPembayaran) <= formatDate(filter.akhirTanggalPembayaran)
            })

            this.resultData = data
        } else {
            this.resultData = dataResult
        }

        this.forceRefresh()
    }

    loadData = async() => {
        return this.resultData
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pembayaran Lainnya</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='pembayaran-mp-lainnya/getByCategoryNidAndStatus/2/5'
                    insertAPI='pembayaran-mp-manfaat-lainnya'
                    updateAPI='pembayaran-mp-manfaat-lainnya'
                    deleteAPI='pembayaran-mp-manfaat-lainnya'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    keyField = {'idPembayaran'}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Lainnya"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Lainnya'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <TransaksiMpManfaatLainnyaModal
                    ref={this.transaksiPembayaranMpDetailModalRef}
                    store={this.props.store}
                />
                <TransaksiMpManfaatLainnyaSkBayar
                    ref={this.transaksiPembayaranMpUploadSkModalRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default TransaksiPembayaranMpManfaatLain