import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, pensiunType, product, pesertaAkunStatus, banks } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import ModalPengalihanDplk from 'components/pages/modal/kepesertaan/pesertaDapen/dplk/approved/index';
import { formatNumber } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';

class PengalihanDplk extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            dataMaster: {}
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.popupDetailPengalihanDplkRef = React.createRef()
        this.popupUploadPesertaRef = React.createRef()
        this.popupModalKeluargaRef = React.createRef()

        this.PengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.kirim(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'Id',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nikPeserta',
                name: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                name: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy',

            },
            {
                dataField: 'tglDialihkan',
                caption: 'Tanggal Berhenti',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'productId',
                caption: 'Akun Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode" // provides display values
                }
            },
            {
                dataField: 'skPemberhentianNo',
                caption: 'No SK PHK',
            },
            {
                name: 'dataAkun',
                caption: 'Data Akun',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'cardNo',
                        caption: 'No. Kartu',
                    },
                    {
                        dataField: 'iuranTotal',
                        caption: 'Total Iuran',
                        alignment: 'right',
                        // format: '#,##0.00',
                        cellRender: (e) => {
                            var data = e.row.data
                            var total = data.iuranManfaatLainPeserta + data.iuranManfaatLainPk + data.iuranNormalPeserta + data.iuranNormalPk + data.iuranSukarelaPeserta

                            return formatNumber(total)
                        }
                    },
                    {
                        dataField: 'totalUnit',
                        caption: 'Total Unit',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'nilaiPerUnit',
                        caption: 'Nilai Per Unit',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'pengembangan',
                        caption: 'Pengembangan',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'nominalTransfer',
                        caption: 'Nominal Transter',
                        format: '#,##0.00'
                    },
                    // {
                    //     name: 'akumulasiIuran',
                    //     caption: 'Akumulasi Iuran',
                    //     cellRender: (e) => {
                    //         var data = e.data
                    //         var akumulasiIuran = (data.iuranManfaatLainPeserta || 0) + (data.iuranManfaatLainPk || 0) + (data.iuranNormalPeserta || 0) + (data.iuranNormalPk || 0)

                    //         return formatNumberAum(akumulasiIuran)
                    //     }
                    // },
                ]
            },
            {
                name: 'dplkTujuan',
                caption: 'DPLK Tujuan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'dplkCode',
                        caption: 'Kode',
                    },
                    {
                        dataField: 'dplkName',
                        caption: 'Nama DPLK',
                    },
                ]
            },
            {
                name: 'bankTransferPengalihan',
                caption: 'Bank Transfer Pengalihan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pengalihanRekeningName',
                        caption: 'Nama Bank',
                    },
                    {
                        dataField: 'pengalihanRekeningNo',
                        caption: 'No Rekening',
                    },
                    {
                        dataField: 'pengalihanRekeningBankId',
                        caption: 'Nama Rekening',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "bankName" // provides display values
                        }
                    },
                ]
            },
            {
                dataField: 'akunStatusId',
                caption: 'Status',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]


        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        this.dataGridRef.current.forceRefresh()
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (data = {}) => {
        this.popupDetailPengalihanDplkRef.current.show()
        this.popupDetailPengalihanDplkRef.current.retrieveData(data)
    }

    retrieveData = async () => {
        var result = [
            {
                id: 1
            }
        ]
        return result
    }

    kirim = async () => {
        await alert('Under Development', 'Error')
        // var text = `Data berhasil dikirim!`
        // var type = 'success'
        // notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Pengalihan DPLK</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div className={' mt-2 text-center'} style={{ height: '100%' }}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='view-simple-kepesertaan-products?akunStatusId.in=8'
                        insertAPI='kepersertaan-products'
                        updateAPI='kepersertaan-products'
                        deleteAPI='kepersertaan-products'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        remoteOperations={true}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengalihan DPLK"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengalihan DPLK'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.PengajuanColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        height={'calc(100vh - 300px)'}

                        // onToolbarPreparing={this.onToolbarPreparing}

                        // height= '100%'
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ModalPengalihanDplk
                    ref={this.popupDetailPengalihanDplkRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'view'}
                />
            </div>
        )
    }
}

export default PengalihanDplk