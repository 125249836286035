import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { confirmAlert } from 'react-confirm-alert';
import { kpds, product } from 'dataSource/lookup';
import ViewKuponKonfirmasi from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/kupon-sbn/viewKonfirmasi';
import { Form } from 'devextreme-react';
import { formatDate } from 'plugin/helper';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { showLoading } from 'redux/actions/loading';
import ModalReverse from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/biaya-custodian/reverse';

class KuponSbn extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            FilterItem: {
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.FilterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Tanggal Terima",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '30%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '30%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.TabDataSource = [
            {
                id: 1,
                text: 'Konfirmasi',
            },
            {
                id: 2,
                text: 'Riwayat'
            },
        ]

        this.columnKonfirmasi = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            this.showModalEditKonfirmasi(e)
                        }
                    },
                    {
                        text: 'View',
                        hint: 'View',
                        onClick: (e) => {
                            this.showModalViewKonfirmasi(e)
                        }
                    },

                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            this.kirimKonfirmasi(e)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            this.cancelConfirmation(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'nomorKonfirmasi',
                caption: 'No. Konfirmasi'
            },
            {
                dataField: 'produk',
                caption: 'Produk',
            },
            {
                dataField: 'kpd',
                caption: 'KPD',
            },
            {
                dataField: 'tanggalTerima',
                caption: 'Tanggal Terima',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'nominalInterest',
                caption: 'Nominal Interest',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'obligasiKuponKon',
                caption: 'Obligasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'kodeObligasi',
                        caption: 'Kode',
                    },
                    {
                        dataField: 'namaObligasi',
                        caption: 'Nama Obligasi',
                    },
                ]
            },
            {
                dataField: 'rekeningPenerimaanKuponKon',
                caption: 'Rekening Penerimaan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'nomor',
                        caption: 'Nomor',
                    },
                    {
                        dataField: 'bank',
                        caption: 'Bank',
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
        ]

        this.columnRiwayat = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        onClick: (e) => {
                            this.showModalViewKonfirmasi(e)
                        }
                    },
                    {
                        text: 'Reverse',
                        hint: 'Reverse',
                        onClick: (e) => {
                            this.showModalReverse(e)
                        }
                    },
                ]
            },
            {
                dataField: 'nomorKonfirmasi',
                caption: 'No. Konfirmasi'
            },
            {
                dataField: 'produk',
                caption: 'Produk',
                // lookup: {
                //     dataSource: product(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'productCode'
                // }
            },
            {
                dataField: 'kpd',
                caption: 'KPD',
                // lookup: {
                //     dataSource: kpds(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'noKontrak'
                // }
            },
            {
                dataField: 'tanggalTerima',
                caption: 'Tanggal Terima',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'nominalInterest',
                caption: 'Nominal Interest',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'obligasiKuponRiw',
                caption: 'Obligasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'kodeObligasi',
                        caption: 'Kode',
                    },
                    {
                        dataField: 'namaObligasi',
                        caption: 'Nama Obligasi',
                    },
                ]
            },
            {
                dataField: 'rekeningPenerimaanKuponRiw',
                caption: 'Rekening Penerimaan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'nomor',
                        caption: 'Nomor',
                    },
                    {
                        dataField: 'bank',
                        caption: 'Bank',
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
        ]

        this.sumHasilInvestasi = [
            {
                showInColumn: 'tanggalTerima',
                displayFormat: 'Total :'
            },
            {
                column: 'nominalInterest',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'db',
                displayFormat: '{0}'
            },
        ]

        this.dataGridKonfirmasiRef = React.createRef()
        this.dataGridRiwayatRef = React.createRef()
        this.showModalAddKonfirmasiRef = React.createRef()
        this.modalReverseRef = React.createRef()
        this.showModalEditKonfirmasiRef = React.createRef()
        this.showModalViewKonfirmasiRef = React.createRef()
        this.prevTabIndex = 0
        this.selectedRowKeys = []
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'selectall',
                    text: 'Kirim',
                    onClick: (e) => {
                        this.kirimSemuaKonfirmasi(e)
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Konfirmasi',
                    onClick: (e) => {
                        this.showModalAddKonfirmasi(e, "add")
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridKonfirmasiRef.current.forceRefresh(true)
    }

    showModalViewKonfirmasi = (e) => {
        if (e.row) {
            this.showModalViewKonfirmasiRef.current.retrieveData(e.row.data)
        }
        this.showModalViewKonfirmasiRef.current.show()
    }

    showModalEditKonfirmasi = (e) => {
        if (e.row) {
            this.showModalEditKonfirmasiRef.current.retrieveData(e.row.data)
        }
        this.showModalEditKonfirmasiRef.current.show()
    }

    showModalReverse = (e) => {
        this.modalReverseRef.current.show(e.row.data)
    }
    showModalAddKonfirmasi = (e) => {
        this.showModalAddKonfirmasiRef.current.generateNoKonf(true)
        this.showModalAddKonfirmasiRef.current.show()
    }

    filterSubmit = () => {
        this.setState({
            FilterItem: this.state.FilterItem
        })

        this.dataGridRiwayatRef.current.forceRefresh(true)
    }

    cancelConfirmation = async (data) => {
        try {
            let result = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `bank-transactions/${data.id}`)
            let editedData = { ...result }
            editedData.status = 'CANCEL'

            let postEditedData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `bank-transactions`, "PUT", {
                values: editedData,
                key: editedData.id
            }, editedData)

            if (postEditedData) {
                this.forceRefresh()
                notify({ message: 'Berhasil di batalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }

        } catch (error) {
            console.log(error)
            notify({ message: 'Terjadi Kesalahan System', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    kirimKonfirmasi = async (e) => {
        try {
            var loadApi = `bank-transactions/konfirmasi-penerimaan-kupon-interest-konfirmasi/${e.row.data.id}`
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "PUT", {
                key: e.row.data.id,
                values: {}
            },
            );

            if (response) {
                this.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: 'Terjadi Kesalahan System', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

        }
    }

    kirimSemuaKonfirmasi = async (e) => {
        if (this.selectedRowKeys.length === 0) {
            notify({ message: "Silahkan pilih salah satu data", width: "AUTO", shading: true, position: { at: "center", my: "center", of: window } }, 'error', 600);
            return null
        }

        this.props.store.dispatch(showLoading(true))
        try {
            for (var data of this.selectedRowKeys) {
                var loadApi = `bank-transactions/konfirmasi-penerimaan-kupon-interest-konfirmasi/${data}`
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    loadApi,
                    "PUT", {
                    key: data,
                    values: {}
                },
                );
            }
            this.forceRefresh()
            notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan Saat Input Data (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            this.props.store.dispatch(showLoading(false))
        }
        this.props.store.dispatch(showLoading(false))
    }

    loadData = async () => {
        let postEditedData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `bank-transactions/transaksi-kupon-obligasi`)

        let filterData = postEditedData.filter(value => value.status !== 'CANCEL')
        return filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfirmasi Penerimaan Kupon Interest Obligasi</h2>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0" className={' col-md-12'}>
                    <div className="row">
                        <div className="col-md-12">
                            <DevExpressDataGrid
                                ref={this.dataGridKonfirmasiRef}
                                loadAPI='bank-transactions/transaksi-kupon-obligasi'
                                // loadAPI='kpds'
                                insertAPI='kpds'
                                updateAPI='kpds'
                                deleteAPI='kpds'
                                useArraySource={true}
                                ArraySourceData={this.loadData}
                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDetailPenempatan}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Konfirmasi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={'calc(100vh - 380px)'}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Instrument'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnKonfirmasi} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                <div id="tabindex-1" className={'d-none col-md-12'}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'dataMaster'}
                                formData={this.state.FilterItem}
                                items={this.FilterItem}
                                labelLocation={"left"}
                            />
                            <DevExpressDataGrid
                                ref={this.dataGridRiwayatRef}
                                loadAPI={`bank-transactions/transaksi-kupon-obligasi-riwayat/${formatDate(this.state.FilterItem.startDate)}/${formatDate(this.state.FilterItem.endDate)}?size=999`}
                                // loadAPI='kpds'
                                updateAPI='kpds'
                                deleteAPI='kpds'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDetailPenempatan}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Riwayat Transaksi Penerimaan Kupon Obligasi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={'calc(100vh - 380px)'}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Instrument'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnRiwayat} // taruh konfigurasi kolom disini
                                summaryTotalItem={this.sumHasilInvestasi}
                                // SummaryConfiguration={this.summary}

                                // onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                <ViewKuponKonfirmasi
                    ref={this.showModalAddKonfirmasiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'add'}
                />
                <ViewKuponKonfirmasi
                    ref={this.showModalEditKonfirmasiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'edit'}
                />
                <ViewKuponKonfirmasi
                    ref={this.showModalViewKonfirmasiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'view'}
                />
                <ModalReverse
                    ref={this.modalReverseRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default KuponSbn