import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalPembayaranTunjanganLain from 'components/pages/modal/sdm-umum/payroll/tunjangan-lain/form';
import FormModalPembayaranTunjanganMed from 'components/pages/modal/sdm-umum/payroll/tunjangan-medical/form';
import { karyawan, statusPembayaranRekapGaji, tunjanganHrd } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import notify from 'devextreme/ui/notify';

import { confirmAlert } from 'react-confirm-alert'

class PembayaranTunjanganMedical extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalEditPembayaranTunjanganMed(e)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin menolak data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.reject(e)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.approve(e)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ],
            },
            {
                dataField: 'nikKaryawan',
                caption: 'NIK',
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'nikPanjang'
                // }
            },
            {
                dataField: 'namaKaryawan',
                caption: 'Karyawan',
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'fullName'
                // }
            },
            {
                dataField: 'transactionTypeName',
                caption: 'Tipe Transaksi',
                // lookup: {
                //     dataSource: tunjanganHrd(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'tunjanganName'
                // }
            },
            {
                dataField: 'tanggalPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalPembayaran',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'pengajuanTerakhir',
                caption: 'Pengajuan Terakhir',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'nominalDisetujui',
                caption: 'Nominal disetujui',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalPengajuan',
                caption: 'Nominal Pengajuan',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.showModalEditPembayaranTunjanganMedRef = React.createRef()
        this.showModalPembayaranTunjanganMedRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    approve = async(e) => {
        try {
            var loadApi = `karyawan-medical-tunai-kirim/${e.row.data.id}`
            var data = {
                karyawanMedicalTunaiId:e.row.data.id
            }
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: data
            })

            if (response) {
                this.forceRefresh()
                notify({ message: "Sukses Kirim", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        
        }
    }

    reject = async(e) => {
        try {
            var loadApi = `karyawan-medical-tunai`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'DELETE', e.row.data.id)

            this.forceRefresh()
            notify({ message: "Sukses Reject", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        
        }
    }

    showModalPembayaranTunjanganMed = () => {
        this.showModalPembayaranTunjanganMedRef.current.show()
        this.showModalPembayaranTunjanganMedRef.current.getNoPengajuan()
    }

    showModalEditPembayaranTunjanganMed = (e) => {
        if (e.row) {
            this.showModalEditPembayaranTunjanganMedRef.current.retrieveData(e.row.data)
        }
        this.showModalEditPembayaranTunjanganMedRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPembayaranTunjanganMed()
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'view-karyawan-medical-tunais','GET')
        
        response = response.filter(value => value.statusId == 1)

        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Tunjangan Medical</h3>
                <DevExpressDataGrid
                    ref={this.DataGridPengajuanRef}
                    loadAPI='view-karyawan-medical-tunais'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPembayaranTunjanganMed
                    ref={this.showModalPembayaranTunjanganMedRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    from={'pengajuan'}
                    action={'add'}
                />
                <FormModalPembayaranTunjanganMed
                    ref={this.showModalEditPembayaranTunjanganMedRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    from={'pengajuan'}
                    action={'edit'}
                />
            </div>
        )
    }
}

export default PembayaranTunjanganMedical