import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { coa, product } from 'dataSource/lookup';
import { formatDate, formatDateDmy, formatDefaultDate, formatDefaultFullDate, getSystemDate } from 'plugin/helper'
import { alert } from 'devextreme/ui/dialog'
import { showLoading } from 'redux/actions/loading';
import { reportFile } from 'plugin/reportRequest';
class TrialBalance extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {
        tanggal1: getSystemDate(props.store),
        tanggal2: getSystemDate(props.store),
        produkId: null
      }
    }

    this.dataGridRef = React.createRef()
    // this.modalDetailProsesIuranRef = React.createRef()
    // this.modalGenerateProsesIuranRef = React.createRef()

    this.filterItem = [
      {
        dataField: "tanggal1",
        label: {
          text: "Tanggal",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy',
          openOnFieldClick: true,
        }
      },
      {
        dataField: "tanggal2",
        label: {
          text: "Sampai",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy',
          openOnFieldClick: true,
        }
      },
      {
        dataField: "produkId",
        label: {
          text: "Produk",
          alignment: "left",
          location: "left"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: product(this.props.store),
          displayExpr: function (item) {
            return item && item.productCode + " - " + item.productName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            this.dataGridRef.current.forceRefresh(true)

            let startDate = new Date(this.state.formFilter.tanggal1);
            let endDate = new Date(this.state.formFilter.tanggal2);

            this.setState({
              formFilter: {
                ...this.state.formFilter,
                startDate: startDate,
                endDate: endDate,
              }
            })
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
      // {
      //   type: 'buttons',
      //   buttons: [
      //     'edit',
      //     {
      //       text: 'Detail',
      //       hint: 'Detail',
      //       cssClass: 'text-success',
      //       onClick: (e) => {
      //         // this.showModalDetail(e.row.data)
      //       }
      //     },
      //   ],
      // },
      {
        dataField: 'coa_id',
        caption: 'Chart of Account',
        lookup: {
          dataSource: coa(this.props.store),
          valueExpr: 'id',
          displayExpr: function (item) {
            return item && item.coaCode + " - " + item.coaName;
          },
        }
      },
      {
        dataField: 'saldoAwal',
        caption: 'Saldo Awal',
        columns: [
          {
            dataField: 'saldo_awal_debet',
            caption: 'Debet',
            format: "#,##0.00",
            alignment: 'right',
          },
          {
            dataField: 'saldo_awal_credit',
            caption: 'Kredit',
            format: "#,##0.00",
            alignment: 'right',
          },
        ]
      },
      {
        dataField: 'mutasi',
        caption: 'Mutasi',
        columns: [
          {
            dataField: 'mutasi_debet',
            caption: 'Debet',
            format: "#,##0.00",
            alignment: 'right',
          },
          {
            dataField: 'mutasi_credit',
            caption: 'Kredit',
            format: "#,##0.00",
            alignment: 'right',
          },
        ]
      },
      {
        dataField: 'saldoAkhir',
        caption: 'Saldo Akhir',
        columns: [
          {
            dataField: 'saldo_akhir_debet',
            caption: 'Debet',
            format: "#,##0.00",
            alignment: 'right',
          },
          {
            dataField: 'saldo_akhir_credit',
            caption: 'Kredit',
            format: "#,##0.00",
            alignment: 'right',
          },
        ]
      }
    ]

    this.summary = [
      {
        column: 'mutasi_debet',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'mutasi_debet',
        displayFormat: '{0}'
      },
      {
        column: 'mutasi_credit',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'mutasi_credit',
        displayFormat: '{0}'
      },
      {
        column: 'saldo_awal_debet',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'saldo_awal_debet',
        displayFormat: '{0}'
      },
      {
        column: 'saldo_awal_credit',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'saldo_awal_credit',
        displayFormat: '{0}'
      },
      {
        column: 'saldo_akhir_debet',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'saldo_akhir_debet',
        displayFormat: '{0}'
      },
      {
        column: 'saldo_akhir_credit',
        summaryType: 'sum',
        valueFormat: '#,##0.00',
        name: 'saldo_akhir_credit',
        displayFormat: '{0}'
      }
    ]

    this.datagridData = []
  }

  getDayMonthYear = (date) => {
    if (date) {
      let period = formatDefaultFullDate(date).split(' ');
      period = `${period[0]} ${period[1]} ${period[2]}`;
      return period;
    }
    return ''; // Handle the case when date is not available
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }
  loadData = async () => {
    if (this.state.formFilter.produkId === null) {
      return []
    }
    if (new Date(this.state.formFilter.tanggal1).getTime() > new Date(this.state.formFilter.tanggal2)) {
      alert('Tanggal "Dari" melebihi tanggal "Sampai"', 'Informasi')

      this.datagridData = []

      return this.datagridData
    }

    if (new Date(this.state.formFilter.tanggal1).getFullYear() !== new Date(this.state.formFilter.tanggal2).getFullYear()) {
      alert('Filter tanggal awal dan akhir harus di tahun yang sama', 'Informasi')

      this.datagridData = []

      return this.datagridData
    }

    if (this.state.formFilter.tanggal1 && this.state.formFilter.tanggal2 && this.state.formFilter.produkId) {
      let res = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/trial-balance-by-function/${formatDate(this.state.formFilter.tanggal1)}/${formatDate(this.state.formFilter.tanggal2)}/${this.state.formFilter.produkId}`)
      this.datagridData = res
      return res
      // var urlTanggal2
      // var responseTanggal2
      // if(new Date(this.state.formFilter.tanggal2).getTime() === new Date(getSystemDate(this.props.store)).getTime()){
      //   urlTanggal2 = `coa-positions/getByProdukId/${this.state.formFilter.produkId}`
      //   responseTanggal2 = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,urlTanggal2)
      // }else{
      //   urlTanggal2 = `coa-position-dailies/getByProdukIdAndValueDate/${this.state.formFilter.produkId}/${formatDate(this.state.formFilter.tanggal2)}`
      //   responseTanggal2 = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,urlTanggal2)
      //   // responseTanggal2 = responseTanggal2.filter(value => value.produkId === this.state.formFilter.produkId)

      // }

      // var beforeTanggal1 = new Date(this.state.formFilter.tanggal1)
      // beforeTanggal1.setDate(beforeTanggal1.getDate() - 1)
      // var responseTanggal1 = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-position-dailies/getByProdukIdAndValueDate/${this.state.formFilter.produkId}/${formatDate(beforeTanggal1)}`)

      // var dataMutasi = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-headers/getMutasiJurnal/${formatDate(this.state.formFilter.tanggal1)}/${formatDate(this.state.formFilter.tanggal2)}?size=9999999`)

      // this.datagridData = responseTanggal2.map(value => {
      //   const saldoAwal = responseTanggal1.find(valueAwal => valueAwal.coaId === value.coaId)
      //   console.log(dataMutasi.filter(valueMutasi => valueMutasi.coaIds === value.coaId))
      //   const mutasi = dataMutasi.filter(valueMutasi => valueMutasi.coaIds === value.coaId).reduce((a, b) => ({
      //     amountDebet: a.amountDebet + b.amountDebet,
      //     amountKredit: a.amountKredit + b.amountKredit,
      //   }),{
      //     amountDebet: 0,
      //     amountKredit: 0,
      //   })

      //   if(saldoAwal){
      //     value.saldoAwalDebet = saldoAwal.balanceAmountBaseCurrency > 0 ? saldoAwal.balanceAmountBaseCurrency : 0
      //     value.saldoAwalKredit = saldoAwal.balanceAmountBaseCurrency < 0 ? Math.abs(saldoAwal.balanceAmountBaseCurrency) : 0
      //   }else{
      //     value.saldoAwalDebet = 0
      //     value.saldoAwalKredit = 0
      //   }

      //   value.mutasiDebet = mutasi.amountDebet
      //   value.mutasiKredit = mutasi.amountKredit

      //   value.saldoAkhirDebet = value.balanceAmountBaseCurrency > 0 ? value.balanceAmountBaseCurrency : 0
      //   value.saldoAkhirKredit = value.balanceAmountBaseCurrency < 0 ? Math.abs(value.balanceAmountBaseCurrency) : 0

      //   return value
      // })
    } else {
      // var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'coa-positions')
      this.datagridData = []
    }
    return this.datagridData
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      // {
      //   location: 'after',
      //   widget: 'dxButton',
      //   options: {
      //     icon: 'print',
      //     onClick: (e) => {
      //       this.print()
      //     },
      //   }
      // }
    )
  }

  print = async () => {
    this.props.store.dispatch(showLoading(true))

    var dataCOA = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'coas')

    const dataTrialBalance = this.datagridData.map(value => {
      const coa = dataCOA.find(valueCoa => valueCoa.id === value.coa_id)
      value.coa_code = coa.coaCode
      value.coa_name = coa.coaName
      return value
    })

    console.log(dataTrialBalance);

    let arrSaldoAwalDebet = [];
    let arrSaldoAwalKredit = [];
    let arrMutasiDebet = [];
    let arrMutasiKredit = [];
    let arrSaldoAkhirDebet = [];
    let arrSaldoAkhirKredit = [];

    var totalSaldoAwalDebet = 0,
      totalSaldoAwalKredit = 0,
      totalMutasiDebet = 0,
      totalMutasiKredit = 0,
      totalSaldoAkhirDebet = 0,
      totalSaldoAkhirKredit = 0

    for (const trialBalance of dataTrialBalance) {
      arrSaldoAwalDebet.push(trialBalance.saldo_awal_debet);
      arrSaldoAwalKredit.push(trialBalance.saldo_awal_credit);
      arrMutasiDebet.push(trialBalance.mutasi_debet);
      arrMutasiKredit.push(trialBalance.mutasi_credit);
      arrSaldoAkhirDebet.push(trialBalance.saldo_akhir_debet);
      arrSaldoAkhirKredit.push(trialBalance.saldo_akhir_credit);
    }

    totalSaldoAwalDebet = arrSaldoAwalDebet.reduce((a, b) => a + b, 0);
    totalSaldoAwalKredit = arrSaldoAwalKredit.reduce((a, b) => a + b, 0);
    totalMutasiDebet = arrMutasiDebet.reduce((a, b) => a + b, 0);
    totalMutasiKredit = arrMutasiKredit.reduce((a, b) => a + b, 0);
    totalSaldoAkhirDebet = arrSaldoAkhirDebet.reduce((a, b) => a + b, 0);
    totalSaldoAkhirKredit = arrSaldoAkhirKredit.reduce((a, b) => a + b, 0);
    // for (const trialBalance of dataTrialBalance) {
    //   totalSaldoAwalDebet = trialBalance.saldo_awal_debet
    //   totalSaldoAwalKredit = trialBalance.saldo_awal_credit
    //   totalMutasiDebet = trialBalance.mutasi_debet
    //   totalMutasiKredit = trialBalance.mutasi_credit
    //   totalSaldoAkhirDebet = trialBalance.saldo_akhir_debet
    //   totalSaldoAkhirKredit = trialBalance.saldo_akhir_credit
    // }

    const data = {
      totalSaldoAwalDebet: totalSaldoAwalDebet,
      totalSaldoAwalKredit: totalSaldoAwalKredit,
      totalMutasiDebet: totalMutasiDebet,
      totalMutasiKredit: totalMutasiKredit,
      totalSaldoAkhirDebet: totalSaldoAkhirDebet,
      totalSaldoAkhirKredit: totalSaldoAkhirKredit,
      period: formatDefaultFullDate(this.state.formFilter.tanggal1),
      prevPeriod: formatDefaultFullDate(this.state.formFilter.tanggal2),
      dataTrialBalance: dataTrialBalance
    }

    await reportFile({
      template: { 'shortid': 'B1gODKK-Td' },
      data: data,
      options: {
        reportName: `Neraca Percobaan ${data.period} - ${data.prevPeriod}`
      }
    }, true, `Neraca Percobaan ${data.period} - ${data.prevPeriod}`)

    this.props.store.dispatch(showLoading(false))
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Neraca Percobaan</h2>
        <Form
          colCount={4}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='trial-balances'
          insertAPI='trial-balances'
          updateAPI='trial-balances'
          deleteAPI='trial-balances'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          useArraySource={true}
          ArraySourceData={this.loadData}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={`Neraca Percobaan ${this.getDayMonthYear(this.state.formFilter.startDate)} - ${this.getDayMonthYear(this.state.formFilter.endDate)}`}
          allowExportSelectedData={true}
          selection={"multiple"}

          exportWithFormatNum={true}
          exceptFieldExportFormtNum={
              [
                `saldo_awal_debet`,
                `saldo_awal_credit`,
                `mutasi_debet`,
                `mutasi_credit`,
                `saldo_akhir_debet`,
                `saldo_akhir_credit`,
              ]
          }

          showBorders={true}

          paging={false}
          showPageSizeSelector = {false}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Trial Balance'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          height={'calc(100vh - 250px)'}

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          // SummaryConfiguration={this.summary}

          summaryTotalItem={this.summary}
          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        {/* <span style={{ fontSize: '14px' }}>* Data tanggal {this.state.formFilter.tanggal1 ? formatDefaultDate(this.state.formFilter.tanggal1) : ''} sampai {this.state.formFilter.tanggal2 ? formatDefaultDate(this.state.formFilter.tanggal2) : ''}</span> */}
      </div>
    )
  }
}

export default TrialBalance