import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ShowPesertaAktif from '../modal/kepesertaan/pesertaDapen/aktif/showPesertaAktif'
import { addURL } from 'redux/actions/url';
import { gender, participants, maritalStatus, statusPajak } from 'dataSource/lookup';
import ModalRiwayatIuran from '../modal/kepesertaan/compPeserta/dataRiwayatIuran';

class PesertaAktif extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            getUrl: 'view-pesertas?statusPeserta.in=2&size=99999'
        }
        this.popupPesertaAktifRef = React.createRef()
        this.popupRiwayatIuranRef = React.createRef()
        this.dataGridRef = React.createRef()

        // this.columns = [
        //     {
        //         type: 'buttons',
        //         buttons: [
        //             {
        //                 text: 'Buka',
        //                 hint: 'Buka',
        //                 cssClass: 'text-primary',
        //                 onClick: (e) => {
        //                     this.showModalPesertaAktif(e.row.data)
        //                 }
        //             },
        //             {
        //                 text: 'Riwayat Iuran',
        //                 hint: 'Riwayat Iuran',
        //                 cssClass: 'text-primary',
        //                 onClick: (e) => {
        //                     this.showModalRiwayatIuran(e.row.data)
        //                 }
        //             },
        //         ],
        //     },
        //     {
        //         dataField: 'id',
        //         caption: 'No',
        //         cellRender: (data) => {
        //             return data.rowIndex + 1
        //         },
        //     },
        //     {
        //         dataField: 'nik',
        //         caption: 'NIK',
        //         alignment: "right",
        //     },
        //     {
        //         dataField: 'fullName',
        //         caption: 'Nama'
        //     },
        //     {
        //         dataField: 'birthPlace',
        //         caption: 'Tempat Lahir'
        //     },
        //     {
        //         dataField: 'birthDate',
        //         caption: 'Tanggal Lahir',
        //         dataType: "date",
        //         format: 'dd MMM yyyy',
        //         alignment: 'right'
        //     },
        //     {
        //         dataField: 'sex',
        //         caption: 'Jenis Kelamin',
        //         lookup: {
        //             dataSource: gender(),
        //             valueExpr: "id", // contains the same values as the "statusId" field provides
        //             displayExpr: "value" // provides display values
        //         }
        //     },
        //     {
        //         dataField: 'address1',
        //         caption: 'Alamat KTP'
        //     },
        //     {
        //         dataField: 'mobileNo',
        //         caption: 'No HP'
        //     },
        //     {
        //         dataField: 'email',
        //         caption: 'Email'
        //     },
        //     {
        //         dataField: 'tanggalEfektif',
        //         caption: 'Tanggal Mulai Kerja',
        //         dataType: "date",
        //         format: 'dd MMM yyyy',
        //         alignment: 'right'
        //     },
        //     {
        //         dataField: 'tanggalPeserta',
        //         caption: 'Tanggal Menjadi Peserta',
        //         dataType: "date",
        //         format: 'dd MMM yyyy',
        //         alignment: 'right'
        //     },
        //     {
        //         dataField: 'statusKeluarga',
        //         caption: 'Status Keluarga'
        //     },
        //     {
        //         dataField: 'statusPajakId',
        //         caption: 'Status Pajak',
        //         lookup: {
        //             dataSource: tax(this.props.store),
        //             valueExpr: "id", // contains the same values as the "statusId" field provides
        //             displayExpr: "shortDesc" // provides display values
        //         }
        //     },
        //     {
        //         dataField: 'unitKerjaName',
        //         caption: 'Unit Kerja',
        //     },
        //     {
        //         dataField: 'grade',
        //         caption: 'Kelas'
        //     },
        //     {
        //         dataField: 'phdp',
        //         caption: 'PHDP'
        //     },
        //     {
        //         dataField: 'npwp',
        //         caption: 'NPWP'
        //     },
        //     {
        //         dataField: 'noKtp',
        //         caption: 'No KTP'
        //     },
        // ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPesertaAktif(e.row.data, 'edit')
                        }
                    },
                    {
                        text: 'Riwayat Iuran',
                        hint: 'Riwayat Iuran',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalRiwayatIuran(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'nik',
                caption: 'NIK',
                alignment: "right",
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir'
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                alignment: "right",
                format: "dd MMM yyyy"
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'address1',
                caption: 'Alamat KTP',
                // cellRender: (rowInfo) => {
                //     var data = rowInfo.data

                //     return data.identityAddress1 + ' ' + (data.identityAddress2 ? data.identityAddress2 : '')
                // }
            },
            {
                dataField: 'mobileNo',
                caption: 'No HP'
            },
            {
                dataField: 'email',
                caption: 'Email'
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Mulai Kerja',
                dataType: "date",
                alignment: "right",
                format: "dd MMM yyyy"
            },
            {
                dataField: 'tanggalPeserta',
                caption: 'Tanggal Menjadi Peserta',
                dataType: "date",
                alignment: "right",
                format: "dd MMM yyyy"
            },
            {
                dataField: 'mariedId',
                caption: 'Status Keluarga',
                lookup: {
                    dataSource: maritalStatus(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'statusPajak',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: statusPajak(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'unitKerjaName',
                caption: 'Unit Kerja',
            },
            {
                dataField: 'grade',
                caption: 'Kelas',
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
                format: '#,##0.00'
            },
            {
                dataField: 'npwp',
                caption: 'NPWP'
            },
            {
                dataField: 'noKtp',
                caption: 'No KTP'
            },
        ]
        
        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        var loadAPI
                        if(data.value){
                            loadAPI = `view-pesertas?participantId.in=${data.value}&statusPeserta.in=2&size=99999`
                        }else{
                            loadAPI = 'view-pesertas?statusPeserta.in=2&size=99999'
                        }
                        this.setState({
                            getUrl: loadAPI
                        })
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    forceRefresh = (param = false) => {
        this.dataGridRef.current.forceRefresh(param)
    }
    showModalPesertaAktif = (data) => {
        this.popupPesertaAktifRef.current.show()
        this.popupPesertaAktifRef.current.retrieveData(data)
    }
    showModalRiwayatIuran = (data) => {
        this.popupRiwayatIuranRef.current.show()
        this.popupRiwayatIuranRef.current.retrieveData(data)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Akun Peserta Aktif</h2>

                <Form
                    colCount={3}
                    id={'form'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                    scrollingEnabled={true}
                    ref={this.formRef}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    // keyField={'pesertaId'}

                    remoteOperations={false}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Peserta Aktif"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Peserta Aktif'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ShowPesertaAktif
                    store={this.props.store}
                    submitData={this.submitData}
                    ref={this.popupPesertaAktifRef}
                    actionType={'view'}
                />
                <ModalRiwayatIuran
                    store={this.props.store}
                    submitData={this.submitData}
                    ref={this.popupRiwayatIuranRef}
                />
            </div>
        )
    }
}

export default PesertaAktif
