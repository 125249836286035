import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { participants, product } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import { Form, Button } from 'devextreme-react'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultMonthYear, formatDefaultDate, PrintElem, generateExcelFile, formatNumber, formatNumberAum } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';
import { alert } from 'devextreme/ui/dialog';

const bulan = [
    {
        id : 1,
        namaBulan: 'Januari'
    },
    {
        id : 2,
        namaBulan: 'Februari'
    },
    {
        id : 3,
        namaBulan: 'Maret'
    },
    {
        id : 4,
        namaBulan: 'April'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Juni'
    },
    {
        id : 7,
        namaBulan: 'Juli'
    },
    {
        id : 8,
        namaBulan: 'Agustus'
    },
    {
        id : 9,
        namaBulan: 'September'
    },
    {
        id : 10,
        namaBulan: 'Oktober'
    },
    {
        id : 11,
        namaBulan: 'November'
    },
    {
        id : 12,
        namaBulan: 'Desember'
    }
]
class ReportDetailIuran extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formFilter: {},
            src: ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: participants(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'participantName'
                        }
                    },
                    {
                        dataField: 'periode',
                        label: {
                            text: 'Periode'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'MMM yyyy',
                            placeholder : 'Bulan/Tahun',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            openOnFieldClick: true
                        }
                    },
                    // {
                    //     itemType: 'group',
                    //     colCount: 2,
                    //     items: [
                    //         {
                    //             dataField: 'greaterPeriode',
                    //             label: {
                    //                 text: 'Periode (Bulan/Tahun)'
                    //             },
                    //             editorType: 'dxDateBox',
                    //             editorOptions: {
                    //                 useMaskBehavior: true,
                    //                 displayFormat: 'MMM yyyy',
                    //                 calendarOptions: {
                    //                     maxZoomLevel: 'year'
                    //                 },
                    //                 openOnFieldClick: true
                    //             }
                    //         },
                    //         {
                    //             dataField: 'lessPeriode',
                    //             label: {
                    //                 text: 'S/D'
                    //             },
                    //             editorType: 'dxDateBox',
                    //             editorOptions: {
                    //                 useMaskBehavior: true,
                    //                 displayFormat: 'MMM yyyy',
                    //                 calendarOptions: {
                    //                     maxZoomLevel: 'year'
                    //                 },
                    //                 openOnFieldClick: true
                    //             }
                    //         }
                    //     ]
                    // },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-iuran-detil?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = getParticipant.find(val => val.id == filter.participantId) ? getParticipant.find(val => val.id == filter.participantId).participantName : 'Semua'
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // Periode
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.greaterThanOrEqual=${tahunPeriode}&`
        }
        // if(filter.greaterPeriode){
        //     var greaterBulan = new Date(filter.greaterPeriode).getMonth() + 1;
        //     var greaterTahun = new Date(filter.greaterPeriode).getFullYear()
        //     loadAPI = loadAPI+`bulan.greaterThanOrEqual=${greaterBulan}&tahun.greaterThanOrEqual=${greaterTahun}&`
        // }

        // if(filter.lessPeriode){
        //     var lessBulan = new Date(filter.lessPeriode).getMonth() + 1;
        //     var lessTahun = new Date(filter.lessPeriode).getFullYear()
        //     loadAPI = loadAPI+`bulan.lessThanOrEqual=${lessBulan}&tahun.lessThanOrEqual=${lessTahun}&`
        // }
        try {
            var getIuranDetailPk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')
            var summaryIuranPeserta = []
            var summarySatuPersen = []
            var summaryLimaBelasPersen = []
            var summaryIuranPk = []
            var summaryIuranTotal = []
            for(var value of getIuranDetailPk){
                var month = bulan.find(val => val.id == value.bulan)
                value.bulan = month ? month.namaBulan : '-'
                
                var participantDetailName = getParticipant.find(val => val.id == value.participantId)
                value.participantId = participantDetailName ? participantDetailName.participantName : '-'
    
                var productDetailName = getProduct.find(val => val.id == value.productId)
                value.productId = productDetailName ? productDetailName.productCode : '-'
    
                // summaryIuranPeserta = summaryIuranPeserta + value.totalIuranPeserta ? value.totalIuranPeserta : 0
                // summarySatuPersen = summarySatuPersen + value.satuPersen ? value.satuPersen : 0
                // summaryLimaBelasPersen = summaryLimaBelasPersen + value.limabelasPersen ? value.limabelasPersen : 0
                // summaryIuranPk = summaryIuranPk + value.totalIuranPk ? value.totalIuranPk : 0
                // summaryIuranTotal = summaryIuranTotal + value.totalIuran ? value.totalIuran : 0

                value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName

                summaryIuranPeserta.push(value.totalIuranPeserta)
                summarySatuPersen.push(value.satuPersen)
                summaryLimaBelasPersen.push(value.limabelasPersen)
                summaryIuranTotal.push(value.totalIuran)
            }

            getIuranDetailPk = getIuranDetailPk.map(value => {
                value.transactionDate = formatDefaultDate(value.transactionDate)
                value.totalIuranPeserta = value.totalIuranPeserta ? (value.totalIuranPeserta) : 0
                value.satuPersen = value.satuPersen ? (value.satuPersen) : 0
                value.limabelasPersen = value.limabelasPersen ? (value.limabelasPersen) : 0
                value.totalIuranPk = value.totalIuranPk ? (value.totalIuranPk) : 0
                value.totalIuran = value.totalIuran ? (value.totalIuran) : 0

                return value
            })

            if(summaryIuranPeserta.length !== 0 && summaryLimaBelasPersen.length !== 0 && summarySatuPersen.length !== 0 && summaryIuranTotal.length !== 0) {
                var totalIuranPeserta = summaryIuranPeserta.reduce((a, b) => a + b, 0);
                var totlIuranSatuPersen = summarySatuPersen.reduce((a, b) => a + b, 0);
                var totalIuranLimaBelasPersen = summaryLimaBelasPersen.reduce((a, b) => a + b, 0);
                var totalIuranTotal = summaryIuranTotal.reduce((a, b) => a + b, 0);
            }

            var data = {
                template:{
                    name: '/dapen/kepesertaan/report/iuran/detail-iuran',
                    recipe: 'chrome-pdf',
                },
                data: {
                    tanggalLaporan: formatDefaultDate(new Date()),
                    product: productName,
                    participant: participantName,
                    periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                    // periode : filter.lessPeriode && filter.greaterPeriode ? (filter.greaterPeriode ? formatDefaultMonthYear(filter.greaterPeriode) : "~") + ' S/D ' + (filter.lessPeriode ? formatDefaultMonthYear(filter.lessPeriode) : "~") : 'Semua',
                    dataIuran : getIuranDetailPk,
                    summaryIuranPeserta: (totalIuranPeserta),
                    // summaryIuranPk: (totalIuranTotal),
                    summaryIuranTotal: (totalIuranTotal),
                    summarySatuPersen : (totlIuranSatuPersen),
                    summaryLimaBelasPersen : (totalIuranLimaBelasPersen),
                    tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                    tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
                },
            }
            var responseFile = await reportFile(data)
            store.dispatch(showLoading(false))
            this.setState({
                src : responseFile
            })
        } catch (e) {
            console.log(e)
            store.dispatch(showLoading(false))
            await alert(e, 'Failed !')
        }


    }
    
    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-iuran-detil?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // Periode
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.greaterThanOrEqual=${tahunPeriode}&`
        }

        // if(filter.greaterPeriode){
        //     var greaterBulan = new Date(filter.greaterPeriode).getMonth() + 1;
        //     var greaterTahun = new Date(filter.greaterPeriode).getFullYear()
        //     loadAPI = loadAPI+`bulan.greaterThanOrEqual=${greaterBulan}&tahun.greaterThanOrEqual=${greaterTahun}&`
        // }

        // if(filter.lessPeriode){
        //     var lessBulan = new Date(filter.lessPeriode).getMonth() + 1;
        //     var lessTahun = new Date(filter.lessPeriode).getFullYear()
        //     loadAPI = loadAPI+`bulan.lessThanOrEqual=${lessBulan}&tahun.lessThanOrEqual=${lessTahun}&`
        // }

        try {
            var getIuranDetailPk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')
            var summaryIuranPeserta = []
            var summarySatuPersen = []
            var summaryLimaBelasPersen = []
            var summaryIuranPk = []
            var summaryIuranTotal = []
            for(var value of getIuranDetailPk){
                var month = bulan.find(val => val.id == value.bulan)
                value.bulan = month ? month.namaBulan : '-'
                
                var participantDetailName = getParticipant.find(val => val.id == value.participantId)
                value.participantId = participantDetailName ? participantDetailName.participantName : '-'
    
                var productDetailName = getProduct.find(val => val.id == value.productId)
                value.productId = productDetailName ? productDetailName.productCode : '-'
    
                // summaryIuranPeserta = summaryIuranPeserta + value.totalIuranPeserta ? value.totalIuranPeserta : 0
                // summarySatuPersen = summarySatuPersen + value.satuPersen ? value.satuPersen : 0
                // summaryLimaBelasPersen = summaryLimaBelasPersen + value.limabelasPersen ? value.limabelasPersen : 0
                // summaryIuranPk = summaryIuranPk + value.totalIuranPk ? value.totalIuranPk : 0
                // summaryIuranTotal = summaryIuranTotal + value.totalIuran ? value.totalIuran : 0

                value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName

                summaryIuranPeserta.push(value.totalIuranPeserta)
                summarySatuPersen.push(value.satuPersen)
                summaryLimaBelasPersen.push(value.limabelasPersen)
                summaryIuranTotal.push(value.totalIuran)
            }

            getIuranDetailPk = getIuranDetailPk.map(value => {
                value.transactionDate = formatDefaultDate(value.transactionDate)
                value.totalIuranPeserta = value.totalIuranPeserta ? (value.totalIuranPeserta) : 0
                value.satuPersen = value.satuPersen ? (value.satuPersen) : 0
                value.limabelasPersen = value.limabelasPersen ? (value.limabelasPersen) : 0
                value.totalIuranPk = value.totalIuranPk ? (value.totalIuranPk) : 0
                value.totalIuran = value.totalIuran ? (value.totalIuran) : 0

                return value
            })

            if(summaryIuranPeserta.length !== 0 && summaryLimaBelasPersen.length !== 0 && summarySatuPersen.length !== 0 && summaryIuranTotal.length !== 0) {
                var totalIuranPeserta = summaryIuranPeserta.reduce((a, b) => a + b, 0);
                var totlIuranSatuPersen = summarySatuPersen.reduce((a, b) => a + b, 0);
                var totalIuranLimaBelasPersen = summaryLimaBelasPersen.reduce((a, b) => a + b, 0);
                var totalIuranTotal = summaryIuranTotal.reduce((a, b) => a + b, 0);
            }

            var data = {
                template:{
                    name: '/dapen/kepesertaan/report/iuran/detail-iuran',
                    // recipe: 'html'
                    recipe: 'chrome-pdf'
                },
                data: {
                    tanggalLaporan: formatDefaultDate(new Date()),
                    product: productName,
                    participant: participantName,
                    periode : filter.periode? formatDefaultMonthYear(filter.periode) : 'Semua',
                    // periode : filter.lessPeriode && filter.greaterPeriode ? (filter.greaterPeriode ? formatDefaultMonthYear(filter.greaterPeriode) : "~") + ' S/D ' + (filter.lessPeriode ? formatDefaultMonthYear(filter.lessPeriode) : "~") : 'Semua',
                    dataIuran : getIuranDetailPk,
                    summaryIuranPeserta: (summaryIuranPeserta),
                    summaryIuranPeserta: (totalIuranPeserta),
                    // summaryIuranPk: (totalIuranTotal),
                    summaryIuranTotal: (totalIuranTotal),
                    summarySatuPersen : (totlIuranSatuPersen),
                    summaryLimaBelasPersen : (totalIuranLimaBelasPersen),
                    tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                    tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
                },
                "options": { 
                    "reportName": 'DETAIL IURAN' 
                },
            }
            // var response = await reportRequest(this.props.store,data)
            // store.dispatch(showLoading(false))
            // PrintElem(response.data)

            var responseFile = await reportFile(data, true, 'DETAIL IURAN')
            store.dispatch(showLoading(false))

            return responseFile
        } catch (e) {
            console.log(e)
            await alert(e, 'Failed !')
        }


    }
    exportExcel = async() => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-iuran-detil?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // Periode
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.greaterThanOrEqual=${tahunPeriode}&`
        }

        // if(filter.greaterPeriode){
        //     var greaterBulan = new Date(filter.greaterPeriode).getMonth() + 1;
        //     var greaterTahun = new Date(filter.greaterPeriode).getFullYear()
        //     loadAPI = loadAPI+`bulan.greaterThanOrEqual=${greaterBulan}&tahun.greaterThanOrEqual=${greaterTahun}&`
        // }

        // if(filter.lessPeriode){
        //     var lessBulan = new Date(filter.lessPeriode).getMonth() + 1;
        //     var lessTahun = new Date(filter.lessPeriode).getFullYear()
        //     loadAPI = loadAPI+`bulan.lessThanOrEqual=${lessBulan}&tahun.lessThanOrEqual=${lessTahun}&`
        // }
        var getIuranDetailPk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var summaryIuranPeserta = []
        var summarySatuPersen = []
        var summaryLimaBelasPersen = []
        var summaryIuranPk = []
        var summaryIuranTotal = []
        var index = 1
        for(var value of getIuranDetailPk){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'
            
            var participantDetailName = getParticipant.find(val => val.id == value.participantId)
            value.participantId = participantDetailName ? participantDetailName.participantName : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'
            
            var dataValue = {
                id: index++,
                nik: value.nik,
                fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                // participantId: value.participantId,
                // productId: value.productId,
                // bulan: value.bulan,
                // tahun: value.tahun,
                // transactionDate: formatDefaultDate(value.transactionDate),
                totalIuranPeserta: value.totalIuranPeserta ? value.totalIuranPeserta : 0,
                limabelasPersen: value.limabelasPersen ? value.limabelasPersen : 0,
                satuPersen: value.satuPersen ? value.satuPersen : 0,
                // totalIuranPk: value.totalIuranPk ? value.totalIuranPk : 0,
                totalIuran: value.totalIuran ? value.totalIuran : 0,
            }

            summaryIuranPeserta.push(value.totalIuranPeserta)
            summarySatuPersen.push(value.satuPersen)
            summaryLimaBelasPersen.push(value.limabelasPersen)
            summaryIuranTotal.push(value.totalIuran)

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        let totalIuranPeserta = summaryIuranPeserta.reduce((a, b) => a + b, 0);
        let totlIuranSatuPersen = summarySatuPersen.reduce((a, b) => a + b, 0);
        let totalIuranLimaBelasPersen = summaryLimaBelasPersen.reduce((a, b) => a + b, 0);
        let totalIuranTotal = summaryIuranTotal.reduce((a, b) => a + b, 0);

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DETAIL IURAN');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A2',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                {name: 'NIK'},
                {name: 'Nama Peserta'},
                // {name: 'Pemberi Kerja'},
                // {name: 'Produk'},
                // {name: 'Bulan'},
                // {name: 'Tahun'},
                // {name: 'Tanggal Transaksi'},
                {name: 'Iuran Peserta\r\n(Rp)'},
                {name: 'Iuran Normal\r\n(Rp)'},
                {name: 'Iuran Manfaat Lain\r\n(Rp)'},
                {name: 'Total Iuran\r\n(Rp)'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                }))
            }

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BDETAIL IURAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BDETAIL IURAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BDETAIL IURAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // // worksheet.getCell('A3').value = 'Kode'
            // // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = ': DETAIL IURAN'

            // worksheet.getCell('A5').value = 'Tanggal Laporan'
            // worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A7').value = 'Pemberi Kerja'
            // worksheet.getCell('B7').value = `: ${participantName}`
            
            // worksheet.getCell('A8').value = 'Periode'
            // worksheet.getCell('B8').value = `: ${filter.periode ? formatDefaultDate(filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua') : 'Semua'}`

            // worksheet.getCell('A9').value = 'Produk'
            // worksheet.getCell('B9').value = `: ${productName}`

            worksheet.mergeCells('A1:A2)');
            worksheet.getCell('A1').value = 'No';
            worksheet.getCell('A1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('A2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('B1:B2');
            worksheet.getCell('B1').value = 'NIK';
            worksheet.getCell('B1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('B2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('C1:C2');
            worksheet.getCell('C1').value = 'Nama Peserta';
            worksheet.getCell('C1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C2').alignment = {vertical: 'middle', horizontal: 'center'};
            
            worksheet.mergeCells('D1:D2');
            worksheet.getCell('D1').value = 'Iuran Peserta\r\n(Rp)';
            worksheet.getCell('D1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('D2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            worksheet.mergeCells('E1:F1');
            worksheet.getCell('E1').value = 'Iuran PK';
            worksheet.getCell('E1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('E2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('E2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('F2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.mergeCells('G1:G2');
            worksheet.getCell('G1').value = 'Total Iuran\r\n(Rp)';
            worksheet.getCell('G1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('G2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            
            // worksheet.getCell('H12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('I12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('J12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('K12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('L12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('M12').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells(`A${lastTableRowNum + 1}:C${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'right'};
            worksheet.getCell(`C${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

            worksheet.getCell(`D${lastTableRowNum + 1}`).value = (totalIuranPeserta)
            worksheet.getCell(`D${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`D${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`E${lastTableRowNum + 1}`).value = (totalIuranLimaBelasPersen)
            worksheet.getCell(`E${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`E${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`F${lastTableRowNum + 1}`).value = (totlIuranSatuPersen)
            worksheet.getCell(`F${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`G${lastTableRowNum + 1}`).value = (totalIuranTotal)
            worksheet.getCell(`G${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            for(var i = 3; i <= data.length + 2; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('A'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('B'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('C'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('D'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('E'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                // worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                // worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 35;

            const Data4 = worksheet.getColumn(4);
            Data4.width = 25;
            Data4.numFmt = '#,##0.00';

            const Data5 = worksheet.getColumn(5);
            Data5.width = 25;
            Data5.numFmt = '#,##0.00';

            const Data6 = worksheet.getColumn(6);
            Data6.width = 25;
            Data6.numFmt = '#,##0.00';

            const Data7 = worksheet.getColumn(7);
            Data7.width = 25;
            Data7.numFmt = '#,##0.00';

            // const Data8 = worksheet.getColumn(8);
            // Data8.width = 35;

            // const Data9 = worksheet.getColumn(9);
            // Data9.width = WidthColums;

            // const Data10 = worksheet.getColumn(10);
            // Data10.width = WidthColums;

            // const Data11 = worksheet.getColumn(11);
            // Data11.width = WidthColums;

            // const Data12 = worksheet.getColumn(12);
            // Data12.width = WidthColums;

            // const Data13 = worksheet.getColumn(13);
            // Data13.width = WidthColums;
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DETAIL IURAN.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"participantId", 
        //         to:"Pemberi Kerja"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"bulan", 
        //         to:"Bulan"
        //     },
        //     {
        //         from:"tahun", 
        //         to:"Tahun"
        //     },
        //     {
        //         from:"transactionDate", 
        //         to:"Tanggal Transaksi"
        //     },
        //     {
        //         from:"nik", 
        //         to:"NIK"
        //     },
        //     {
        //         from:"fullName", 
        //         to:"Nama Peserta"
        //     },
        //     {
        //         from:"totalIuranPeserta", 
        //         to:"Iuran Peserta"
        //     },
        //     {
        //         from:"satuPersen", 
        //         to:"Iuran PK 1%"
        //     },
        //     {
        //         from:"limabelasPersen", 
        //         to:"Iuran PK 15%"
        //     },
        //     {
        //         from:"totalIuranPk", 
        //         to:"Iuran PK 16%"
        //     },
        //     {
        //         from:"totalIuran", 
        //         to:"Total Iuran"
        //     }
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'DETAIL IURAN'
        //     },
        //     {
        //         A: 'Tanggal Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Pemberi Kerja',
        //         B: null,
        //         C: ':',
        //         D: participantName
        //     },
        //     {
        //         A: 'Periode',
        //         B: null,
        //         C: ':',
        //         D: filter.periode? formatDefaultMonthYear(filter.periode) : 'Semua',
        //         // D: filter.lessPeriode && filter.greaterPeriode ? (filter.greaterPeriode ? formatDefaultMonthYear(filter.greaterPeriode) : "~") + ' S/D ' + (filter.lessPeriode ? formatDefaultMonthYear(filter.lessPeriode) : "~") : 'Semua',
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getIuranDetailPk){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_DETAIL_IURAN')
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Detail Iuran</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.formRef}
                            formData={this.state.formFilter}
                            items={this.formItem}
                            labelLocation={"left"}
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'Report PDF'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportDetailIuran