import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import FormModalPengajuanProyek from 'components/pages/modal/sdm-umum/umum/proyek/pengajuanProyek';
import FormModalKontrakProyek from 'components/pages/modal/sdm-umum/umum/proyek/kontrakProyek';
import { supplierSdm } from 'dataSource/lookup';
import FormModalPenutupanProyek from 'components/pages/modal/sdm-umum/umum/proyek/penutupanProyek';

class KontrakProyek extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Kontrak',
                        hint: 'Kontrak',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalKontrakProyek(e.row.data)
                        }
                    },
                    {
                        text: 'Tagihan',
                        hint: 'Tagihan',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            console.log(e)
                        }
                    },
                    {
                        text: 'Penutupan',
                        hint: 'Penutupan',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalPenutupan(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'pengajuanNo',
                caption: 'No Pengajuan'
            },
            {
                dataField: 'projectType',
                caption: 'Jenis Proyek',
                lookup: {
                    dataSource: [
                        {
                            id: "1",
                            value: "Pembangunan",
                        },
                        {
                            id: "2",
                            value: "Renovasi",
                        },
                        {
                            id: "3",
                            value: "Pengadaan",
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                dataField: 'projectCode',
                caption: 'Kode Proyek'
            },
            {
                dataField: 'projectName',
                caption: 'Nama Proyek'
            },
            {
                dataField: 'pengajuanDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'kontrak',
                caption: 'Kontrak Proyek',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'kontrakNo',
                        caption: 'No Kontrak'
                    },
                    {
                        dataField: 'perjanjianNo',
                        caption: 'No Penjanjian'
                    },
                    {
                        dataField: 'supplierId',
                        caption: 'Pelaksana Proyek',
                        lookup: {
                            dataSource: supplierSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'namaSupplier'
                        }
                    },
                    {
                        dataField: 'pimpro',
                        caption: 'Pimpinan Proyek'
                    },
                    {
                        dataField: 'kontrakTanggal',
                        caption: 'Tanggal Kontrak',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'kontrakEndDate',
                        caption: 'Tanggal Akhir Kontrak',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'kontrakGaransiBulan',
                        caption: 'Masa garansi',
                    },
                ]
            },
            {
                dataField: 'nilaiKontrak',
                caption: 'Nilai Kontrak',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'kontrakNominal',
                        caption: 'Nilai Kontrak',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'terminPembayaran',
                        caption: 'Termin'
                    },
                ]
            },
            {
                dataField: 'pembayaran',
                caption: 'Pembayaran',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'totalNominal',
                        caption: 'Total Nominal'
                    },
                    {
                        dataField: 'totalPersen',
                        caption: 'Total Persen'
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Pengajuan',
                        },
                        {
                            id: 2,
                            value: 'Kontrak',
                        },
                        {
                            id: 3,
                            value: "Ditutup"
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            }
        ]

        this.showModalKontrakProyekRef = React.createRef()
        this.showModalPenutupanRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalKontrakProyek = (data) => {
        this.showModalKontrakProyekRef.current.retrieveData(data)
        this.showModalKontrakProyekRef.current.show()
    }
    showModalPenutupan = async(data) => {
        this.showModalPenutupanRef.current.show()
        this.showModalPenutupanRef.current.retrieveData(data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Kontrak Proyek</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='projects-list-kontrak/'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Kontrak Proyek"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Kontrak Proyek'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalKontrakProyek
                    ref={this.showModalKontrakProyekRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalPenutupanProyek
                    ref={this.showModalPenutupanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default KontrakProyek