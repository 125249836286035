import React, { Component } from 'react'
import { Form, Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import { participants, periodeDaftarUlang, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { capitalizeFirstLetter, formatDate, formatDefaultDate, formatDefaultFullDate, formatNumber, generateExcelFile, PrintElem } from 'plugin/helper'
import { showLoading } from 'redux/actions/loading';

const bulan = [
    {
        id : 1,
        namaBulan: 'Januari'
    },
    {
        id : 2,
        namaBulan: 'Februari'
    },
    {
        id : 3,
        namaBulan: 'Maret'
    },
    {
        id : 4,
        namaBulan: 'April'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Juni'
    },
    {
        id : 7,
        namaBulan: 'Juli'
    },
    {
        id : 8,
        namaBulan: 'Agustus'
    },
    {
        id : 9,
        namaBulan: 'September'
    },
    {
        id : 10,
        namaBulan: 'Oktober'
    },
    {
        id : 11,
        namaBulan: 'November'
    },
    {
        id : 12,
        namaBulan: 'Desember'
    }
]
class ReportPengkinianDaftarUlang extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            src : ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'periodeRentang',
                        label:{
                            text: 'Periode',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: periodeDaftarUlang(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: function (item) {
                                return item && formatDefaultDate(item.pengkinianTanggal) + " - " + formatDefaultDate(item.tanggalAkhir);
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-laporan-daftar-ulang-pesertas?`

        var loadAPIProduct = `products`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIProduct , 'GET')

        var loadAPIPeriode = `view-daftar-ulang-peserta-pasifs`
        var getPeriode = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeriode, 'GET')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = getProduct.find(val => val.id == filter.productId).productCode
            } catch (error) {
                var productName = 'Semua'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.periodeRentang) {
            loadAPI = loadAPI + `daftarUlangId.in=${filter.periodeRentang}&`
            try {
                var periodeDaftarUlang = getPeriode.find(val => val.id === filter.periodeRentang)
                let periodePengkinian = periodeDaftarUlang.pengkinianTanggal ? formatDefaultFullDate(periodeDaftarUlang.pengkinianTanggal) : null
                let periodeAkhir = periodeDaftarUlang.tanggalAkhir ? formatDefaultFullDate(periodeDaftarUlang.tanggalAkhir) : null
                var periodeRentangDaftar = `${periodePengkinian} - ${periodeAkhir}`
            } catch (e) {
                
            }
        }

        var getDaftarUlang = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        let jumlahPeserta = []
        let jumlahKonvensional = []
        let jumlahOnline = []

        for(var value of getDaftarUlang){
            var month = bulan.find(val => val.id == value.periodeBulan)
            value.periodeBulan = month ? month.namaBulan : '-'

            value.statusPesertaName = value.statusPesertaName ? value.statusPesertaName : '-'

            value.pengkinianTanggal = value.pengkinianTanggal ? formatDefaultDate(value.pengkinianTanggal) : '-'
            value.tanggalAkhir = value.tanggalAkhir ? formatDefaultDate(value.tanggalAkhir) : '-'
            value.tglDiterima = value.tglDiterima ? formatDefaultDate(value.tglDiterima) : '-'
            value.productId = getProduct.find(val => val.id == value.productId).productCode
            value.jumlahPasif = value.jumlahPasif ? formatNumber(value.jumlahPasif) : 0
            value.dataMasukKonvensional = value.dataMasukKonvensional ? formatNumber(value.dataMasukKonvensional) : 0
            value.dataMasukOnline = value.dataMasukOnline ? formatNumber(value.dataMasukOnline) : 0
            value.dataProsesOnline = value.dataProsesOnline ? formatNumber(value.dataProsesOnline) : 0
            value.belumDaftarUlang = value.belumDaftarUlang ? formatNumber(value.belumDaftarUlang) : 0
            value.jenisDaftarUlang = value.jenisDaftarUlang ? value.jenisDaftarUlang.toLowerCase() : '-'
            value.rentangPeriode = periodeRentangDaftar ? periodeRentangDaftar : '-'
            value.namaPeserta = value.namaPeserta ? (value.namaPeserta).toUpperCase() : value.namaPeserta

            if(value.jenisDaftarUlang === 'online') {
                jumlahOnline.push(value.jenisDaftarUlang)
            } else {
                jumlahKonvensional.push(value.jenisDaftarUlang)
            }
            jumlahPeserta.push(value.namaPeserta)
        }

        let summaryPeserta = getDaftarUlang.length
        let summaryDaftarKonvensional = jumlahKonvensional.length
        let summaryDaftarOnline = jumlahOnline.length

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/pengkinian/daftar-ulang',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                dataDaftarUlang : getDaftarUlang,
                pengkinianTanggal : filter.pengkinianTanggal ? formatDefaultDate(filter.pengkinianTanggal): 'Semua',
                tanggalAkhir : filter.tanggalAkhir ? formatDefaultDate(filter.tanggalAkhir): 'Semua',
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
                jumlahPeserta: formatNumber(summaryPeserta),
                jumlahDaftarKonvensional: formatNumber(summaryDaftarKonvensional),
                jumlahDaftarOnline: formatNumber(summaryDaftarOnline)
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))

        this.setState({
            src : responseFile
        })
    }
    
    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-laporan-daftar-ulang-pesertas?`

        var loadAPIProduct = `products`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIProduct , 'GET')

        var loadAPIPeriode = `view-daftar-ulang-peserta-pasifs`
        var getPeriode = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeriode, 'GET')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = getProduct.find(val => val.id == filter.productId).productCode
            } catch (error) {
                var productName = 'Semua'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.periodeRentang) {
            loadAPI = loadAPI + `daftarUlangId.in=${filter.periodeRentang}&`
            try {
                var periodeDaftarUlang = getPeriode.find(val => val.id === filter.periodeRentang)
                let periodePengkinian = periodeDaftarUlang.pengkinianTanggal ? formatDefaultFullDate(periodeDaftarUlang.pengkinianTanggal) : null
                let periodeAkhir = periodeDaftarUlang.tanggalAkhir ? formatDefaultFullDate(periodeDaftarUlang.tanggalAkhir) : null
                var periodeRentangDaftar = `${periodePengkinian} - ${periodeAkhir}`
            } catch (e) {
                
            }
        }

        var getDaftarUlang = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        let jumlahPeserta = []
        let jumlahKonvensional = []
        let jumlahOnline = []

        for(var value of getDaftarUlang){
            var month = bulan.find(val => val.id == value.periodeBulan)
            value.periodeBulan = month ? month.namaBulan : '-'

            value.statusPesertaName = value.statusPesertaName ? value.statusPesertaName : '-'

            value.pengkinianTanggal = value.pengkinianTanggal ? formatDefaultDate(value.pengkinianTanggal) : '-'
            value.tanggalAkhir = value.tanggalAkhir ? formatDefaultDate(value.tanggalAkhir) : '-'
            value.tglDiterima = value.tglDiterima ? formatDefaultDate(value.tglDiterima) : '-'
            value.productId = getProduct.find(val => val.id == value.productId).productCode
            value.jumlahPasif = value.jumlahPasif ? formatNumber(value.jumlahPasif) : 0
            value.dataMasukKonvensional = value.dataMasukKonvensional ? formatNumber(value.dataMasukKonvensional) : 0
            value.dataMasukOnline = value.dataMasukOnline ? formatNumber(value.dataMasukOnline) : 0
            value.dataProsesOnline = value.dataProsesOnline ? formatNumber(value.dataProsesOnline) : 0
            value.jenisDaftarUlang = value.jenisDaftarUlang ? value.jenisDaftarUlang.toLowerCase() : '-'
            value.rentangPeriode = periodeRentangDaftar ? periodeRentangDaftar : '-'
            value.namaPeserta = value.namaPeserta ? (value.namaPeserta).toUpperCase() : value.namaPeserta

            if(value.jenisDaftarUlang === 'online') {
                jumlahOnline.push(value.jenisDaftarUlang)
            } else {
                jumlahKonvensional.push(value.jenisDaftarUlang)
            }
            jumlahPeserta.push(value.namaPeserta)
        }

        let summaryPeserta = getDaftarUlang.length
        let summaryDaftarKonvensional = jumlahKonvensional.length
        let summaryDaftarOnline = jumlahOnline.length

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/pengkinian/daftar-ulang',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                dataDaftarUlang : getDaftarUlang,
                pengkinianTanggal : filter.pengkinianTanggal ? formatDefaultDate(filter.pengkinianTanggal): 'Semua',
                tanggalAkhir : filter.tanggalAkhir ? formatDefaultDate(filter.tanggalAkhir): 'Semua',
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
                jumlahPeserta: formatNumber(summaryPeserta),
                jumlahDaftarKonvensional: formatNumber(summaryDaftarKonvensional),
                jumlahDaftarOnline: formatNumber(summaryDaftarOnline)
            },
            options: {
                reportName: 'PENGKINIAN DAFTAR ULANG'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'PENGKINIAN DAFTAR ULANG')
        store.dispatch(showLoading(false))
        return responseFile
    }

    exportExcel = async() => {
        try {
            var data = []
            let store = this.props.store
            store.dispatch(showLoading(true))
            var filter = this.state.formFilter
            var loadAPI = `list-laporan-daftar-ulang-pesertas?`
            
            var loadAPIProduct = `products`
            var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIProduct , 'GET')
            
            var loadAPIPeriode = `view-daftar-ulang-peserta-pasifs`
            var getPeriode = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeriode, 'GET')

            if(filter.productId){
                loadAPI = loadAPI+`productId.in=${filter.productId}&`
                try {
                    var productName = getProduct.find(val => val.id == filter.productId).productCode
                } catch (error) {
                    var productName = 'Semua'
                }
            }else{
                var productName = 'Semua'
            }

            if(filter.periodeRentang) {
                loadAPI = loadAPI + `daftarUlangId.in=${filter.periodeRentang}&`
                try {
                    var periodeDaftarUlang = getPeriode.find(val => val.id === filter.periodeRentang)
                    let periodePengkinian = periodeDaftarUlang.pengkinianTanggal ? formatDefaultFullDate(periodeDaftarUlang.pengkinianTanggal) : null
                    let periodeAkhir = periodeDaftarUlang.tanggalAkhir ? formatDefaultFullDate(periodeDaftarUlang.tanggalAkhir) : null
                    var periodeRentangDaftar = `${periodePengkinian} - ${periodeAkhir}`
                } catch (e) {
                    
                }
            }

            var getDaftarUlang = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')
        
            let jumlahPeserta = []
            let jumlahKonvensional = []
            let jumlahOnline = []

            var index = 1
            for(var value of getDaftarUlang){
                if(value.jenisDaftarUlang === 'ONLINE') {
                    jumlahOnline.push(value.jenisDaftarUlang)
                } else {
                    jumlahKonvensional.push(value.jenisDaftarUlang)
                }
                jumlahPeserta.push(value.namaPeserta)
                var dataValue = {
                    id: index++,
                    // productId: productName,
                    nik: value.nik,
                    namaPeserta: value.namaPeserta ? (value.namaPeserta).toUpperCase() : value.namaPeserta,
                    rentangPeriode: periodeRentangDaftar ? periodeRentangDaftar : '-',
                    // periodeBulan: value.periodeBulan ? bulan.find(val => val.id == value.periodeBulan).namaBulan : '-',
                    // periodeTahun: value.periodeTahun,
                    pengkinianTanggal: value.pengkinianTanggal ? formatDefaultDate(value.pengkinianTanggal) : '-',
                    tglDiterima: value.tglDiterima ? formatDefaultDate(value.tglDiterima) : '-',
                    // tanggalAkhir: value.tanggalAkhir ? formatDefaultDate(value.tanggalAkhir) : '-',
                    // jumlahPasif: value.jumlahPasif ? formatNumber(value.jumlahPasif) : '0',
                    // dataMasukKonvensional: value.dataMasukKonvensional ? formatNumber(value.dataMasukKonvensional) : '0',
                    // dataMasukOnline: value.dataMasukOnline ? formatNumber(value.dataMasukOnline) : '0',
                    // dataProsesKonvensional: value.dataProsesKonvensional ? formatNumber(value.dataProsesKonvensional) : '0',
                    // dataProsesOnline: value.dataProsesOnline ? formatNumber(value.dataProsesOnline) : '0',
                    // belumDaftarUlang: value.belumDaftarUlang ? formatNumber(value.belumDaftarUlang) : '0',
                    jenisDaftarUlang: value.jenisDaftarUlang ? capitalizeFirstLetter(value.jenisDaftarUlang.toLowerCase()) : '-',
                    statusPesertaName: value.statusPesertaName
                }
    
                const objectValue = Object.values(dataValue)
    
                data.push(objectValue)
            }

            let summaryPeserta = getDaftarUlang.length
            let summaryDaftarKonvensional = jumlahKonvensional.length
            let summaryDaftarOnline = jumlahOnline.length

            try{
                const workbook = new Excel.Workbook();
                var worksheet = workbook.addWorksheet('PENGKINIAN DAFTAR ULANG');
                worksheet.addTable({
                  name: 'MyTable',
                  ref: 'A1',
                  headerRow: true,
                  totalsRow: false,
                  style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                    // border: {
                    //     top: {style:'thin'},
                    //     left: {style:'thin'},
                    //     bottom: {style:'thin'},
                    //     right: {style:'thin'}
                    // }
                  },
                  columns: [
                    {name: 'No'},
                    // {name: 'Produk'},
                    {name: 'NIK'},
                    {name: 'Nama Peserta'},
                    {name: 'Periode Rentang'},
                    // {name: 'Tahun'},
                    {name: 'Tanggal Perubahan'},
                    {name: 'Tanggal Disetujui'},
                    // {name: 'Awal'},
                    // {name: 'Akhir'},
                    // {name: 'Jumlah Peserta Pasif'},
                    // {name: 'Konvensional'},
                    // {name: 'Online'},
                    // {name: 'Konvensional'},
                    // {name: 'Online'},
                    // {name: 'Belum Daftar Ulang'}
                    {name: 'Jenis Daftar Ulang'},
                    {name: 'Status Peserta'}
                  ],
                  rows: data,
                });

                let lastRowNum = worksheet.lastRow.number;
                const lastTableRowNum = lastRowNum;

                for(let i = 0; i < lastTableRowNum; i++) {
                    const row = worksheet.getRow(i);
                    row.eachCell({ includeEmpty: true }, (cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }
                    }))
                }

                worksheet.headerFooter.differentFirst = true;
                worksheet.headerFooter = {
                    firstHeader: `&C&BPENGKINIAN DAFTAR ULANG\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    oddHeader: `&C&BPENGKINIAN DAFTAR ULANG\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    evenHeader: `&C&BPENGKINIAN DAFTAR ULANG\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
                }

                worksheet.pageSetup = {
                    fitToPage: true,
                    margins: {
                        bottom: 0.75,
                        footer: 0.3,
                        header: 0.3,
                        left: 0.7,
                        right: 0.7,
                        top: 0.75
                    }
                }

                // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

                // worksheet.getCell('A3').value = 'Laporan'
                // worksheet.getCell('B3').value = ': DAFTAR PERUBAHAN DAFTAR ULANG'

                // worksheet.getCell('A4').value = 'Tanggal Laporan'
                // worksheet.getCell('B4').value = `:  ${formatDefaultDate(new Date())}`

                // worksheet.getCell('A6').value = 'Produk'
                // worksheet.getCell('B6').value = `: ${productName}`

                // worksheet.getCell('A7').value = 'Tanggal Awal'
                // worksheet.getCell('B7').value = `: ${formatDefaultDate(filter.pengkinianTanggal ? formatDate(filter.pengkinianTanggal): 'Semua')} S/D ${formatDefaultDate(filter.tanggalAkhir ? formatDate(filter.tanggalAkhir): 'Semua')}`

                // worksheet.mergeCells(`A${lastTableRowNum + 1}:B${lastTableRowNum + 1}`);
                worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Jumlah Peserta Pasif';
                worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};
                worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'left'};

                worksheet.getCell(`B${lastTableRowNum + 1}`).value = (summaryPeserta)
                worksheet.getCell(`B${lastTableRowNum + 1}`).numFmt = '#,##0';
                worksheet.getCell(`B${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell(`B${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

                worksheet.mergeCells(`C${lastTableRowNum + 1}:D${lastTableRowNum + 1}`);
                worksheet.getCell(`C${lastTableRowNum + 1}`).value = 'Jumlah Daftar Ulang Konvensional';
                worksheet.getCell(`D${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
                worksheet.getCell(`D${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell(`C${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};
                worksheet.getCell(`C${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'left'};

                worksheet.getCell(`E${lastTableRowNum + 1}`).value = (summaryDaftarKonvensional)
                worksheet.getCell(`E${lastTableRowNum + 1}`).numFmt = '#,##0';
                worksheet.getCell(`E${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell(`E${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

                worksheet.mergeCells(`F${lastTableRowNum + 1}:G${lastTableRowNum + 1}`);
                worksheet.getCell(`F${lastTableRowNum + 1}`).value = 'Jumlah Daftar Ulang Online';
                worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
                worksheet.getCell(`G${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};
                worksheet.getCell(`F${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'left'};

                worksheet.getCell(`H${lastTableRowNum + 1}`).value = (summaryDaftarOnline)
                worksheet.getCell(`H${lastTableRowNum + 1}`).numFmt = '#,##0';
                worksheet.getCell(`H${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

                worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center'};
                // worksheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center'};

                for(var i = 2; i <= data.length + 1; i++){
                    worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    // worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                }

                var WidthColums = 20;
                
                const Data1 = worksheet.getColumn(1);
                Data1.width = WidthColums;

                const Data2 = worksheet.getColumn(2);
                Data2.width = WidthColums;

                const Data3 = worksheet.getColumn(3);
                Data3.width = 30;

                const Data4 = worksheet.getColumn(4);
                Data4.width = 30;

                const Data5 = worksheet.getColumn(5);
                Data5.width = WidthColums;

                const Data6 = worksheet.getColumn(6);
                Data6.width = WidthColums;

                const Data7 = worksheet.getColumn(7);
                Data7.width = WidthColums;

                const Data8 = worksheet.getColumn(8);
                Data8.width = WidthColums;

                const Data9 = worksheet.getColumn(9);
                Data9.width = WidthColums;

                const Data10 = worksheet.getColumn(10);
                Data10.width = 30;

                const Data11 = worksheet.getColumn(11);
                Data11.width = 30;

                // const Data12 = worksheet.getColumn(12);
                // Data12.width = WidthColums;
                
                await workbook.xlsx.writeBuffer().then(function(buffer) {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        `PENGKINIAN DAFTAR ULANG.xlsx`
                    );
                });
            }catch(e){
                console.log(e)
            }
            store.dispatch(showLoading(false))

            // var headerNameMapping = [
            //     {
            //         from:"id",
            //         to:"No"
            //     },
            //     {
            //         from:"productId", 
            //         to:"Produk"
            //     },
            //     {
            //         from:"periodeBulan", 
            //         to:"Bulan"
            //     },
            //     {
            //         from:"periodeTahun", 
            //         to:"Tahun"
            //     },
            //     {
            //         from:"pengkinianTanggal", 
            //         to:"Tanggal Awal"
            //     },
            //     {
            //         from:"tanggalAkhir", 
            //         to:"Tanggal Akhir Terima Data"
            //     },
            //     {
            //         from:"jumlahPasif", 
            //         to:"Jumlah Peserta Pasif"
            //     },
            //     {
            //         from:"dataMasukKonvensional", 
            //         to:"Data Masuk Konvensional"
            //     },
            //     {
            //         from:"dataMasukOnline", 
            //         to:"Data Masuk Online"
            //     },
            //     {
            //         from:"dataProsesKonvensional", 
            //         to:"Proses Konvensional"
            //     },
            //     {
            //         from:"dataProsesOnline", 
            //         to:"Proses Online"
            //     },
            // ]

            // var header = [
            //     {
            //         A: 'DANA PENSIUN PEGADAIAN'
            //     },
            // ]
            
            // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

            // var title = [
            //     {
            //         A: 'Kode',
            //         B: null,
            //         C: ':',
            //         D: 'KPS-2-02'
            //     },
            //     {
            //         A: 'Laporan',
            //         B: null,
            //         C: ':',
            //         D: 'DAFTAR PENGKINIAN DAFTAR ULANG'
            //     },
            //     {
            //         A: 'Tanggal Laporan',
            //         B: null,
            //         C: ':',
            //         D: formatDefaultDate(new Date())
            //     },
            // ]

            // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

            // var subTitle = [
            //     {
            //         A: 'Produk',
            //         B: null,
            //         C: ':',
            //         D: productName
            //     },
            //     {
            //         A: 'Tanggal Awal',
            //         B: null,
            //         C: ':',
            //         D: filter.pengkinianTanggal ? formatDate(filter.pengkinianTanggal): 'Semua'
            //     },
            //     {
            //         A: 'Tanggal Akhir',
            //         B: null,
            //         C: ':',
            //         D: filter.tanggalAkhir ? formatDate(filter.tanggalAkhir): 'Semua'
            //     }
            // ]

            // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
            // var instructions = []
        
            // for(var InstructionData of getDaftarUlang){
            //     var row = {}
            //     for(var column in InstructionData){
            //         var headerMapping = headerNameMapping.find(value => value.from == column)
            //         if(headerMapping){
            //             row[headerMapping.to] = InstructionData[column]
            //         }
            //     }
            //     instructions.push(row)
            // }

            // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

            // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            // store.dispatch(showLoading(false))
            // generateExcelFile(excelBlob,'LAPORAN_DATA_PENGKINIAN_DAFTAR_ULANG')
        } catch (e) {
            let store = this.props.store
            store.dispatch(showLoading(false))
            console.log(e)
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Pengkinian Daftar Ulang </h4>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.formFilter}
                            items={this.filterItem}
                            labelLocation = 'left'
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPengkinianDaftarUlang