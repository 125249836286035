import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { kpds, productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import { getSystemDate } from 'plugin/helper';
import DataGridDailyBalanceDeposito from './comp/dataGridDeposito';
import DataGridDailyBalanceSaham from './comp/dataGridSaham';
import DataGridDailyBalanceReksadana from './comp/dataGridReksadana';
import DataGridDailyBalanceSbn from './comp/dataGridSbn';
import DataGridDailyBalanceObligasi from './comp/dataGridObligasi';
import DataGridDailyBalanceSukuk from './comp/dataGridSukuk';
import DataGridDailyBalanceEba from './comp/dataGridEba';
import DataGridDailyBalanceAsetLain from './comp/dataGridAsetLain';
import DataGridDailyBalanceProperti from './comp/dataGridProperti';
import DataGridDailyBalancePenyertaan from './comp/dataGridPenyertaan';

class DetailDailyBalancePortofolio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {
                productCode: 'PPMP',
                kpdId: 1,
                balanceDate: getSystemDate(this.props.store),
            },
        }

        this.prevTabIndex = 0

        this.DataGridSahamRef = React.createRef()
        this.DataGridReksadanaRef = React.createRef()
        this.DataGridDepositoRef = React.createRef()
        this.DataGridSBNRef = React.createRef()
        this.DataGridObligasiRef = React.createRef()
        this.DataGridSukukRef = React.createRef()
        this.DataGridEbaRef = React.createRef()
        this.DataGridPropertiRef = React.createRef()
        this.DataGridPenyertaanlangsungRef = React.createRef()
        this.formRef = React.createRef()

        this.dataList = []
        this.TabDataSource = [
            {
                id: 1,
                text: 'Saham'
            },
            {
                id: 2,
                text: 'Reksadana'
            },
            {
                id: 3,
                text: 'Deposito'
            },
            {
                id: 4,
                text: 'SBN'
            },
            {
                id: 5,
                text: 'Obligasi & Sukuk'
            },
            {
                id: 7,
                text: 'EBA'
            },
            {
                id: 8,
                text: 'Properti'
            },
            {
                id: 9,
                text: 'Penyertaan Langsung'
            },
            {
                id: 10,
                text: 'Aset Lain-Lain'
            },
        ]
        this.filterItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'productCode',
                        label: {
                            text: 'Produk',
                            alignment: "left",
                            location: "left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: "productCode",
                            displayExpr: "productCode",
                            searchEnabled: true,
                            deferRendering: false,
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            text: 'KPD',
                            alignment: 'left',
                            location: 'left',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            searchEnabled: true,
                            deferRendering: false
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'balanceDate',
                                label: {
                                    text: 'Tanggal Portofolio',
                                    alignment: 'left',
                                    location: 'left',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'filter',
                                label: {
                                    visible: false,
                                },
                                editorType: 'dxButton',
                                editorOptions: {
                                    text: 'Filter',
                                    elementAttr: { class: "bg-dapen-default" },
                                    onClick: (e) => {
                                        this.setState({
                                            filterData: this.state.filterData
                                        })

                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }

    componentDidMount = () => {
        this.insertLookup()
        this.loadData(this.state.filterData.productCode, this.state.filterData.kpdId, this.state.filterData.balanceDate, 1)
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    insertLookup = async () => {
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `products`, 'GET')
        let dataProduct = new ArrayStore({
            data: getProduct,
            key: 'id'
        });
        if (getProduct) {
            this.formRef.current.instance.getEditor('productCode').option('dataSource', dataProduct)
        }
    }
    loadData = async (productCode, kpdId, balanceDate, categoryId) => {
        // var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-portofolio-daily-balances?balanceDate.equals=${balanceDate}&categoryId.in=${categoryId}&kpdId.in=${kpdId}&productCode.in=${productCode}&size=9999`, 'GET')
        // this.dataList = response
        // if (response.length > 0) {
        //     var numArray = []
        //     for (var data of response) {
        //         numArray.push(data.nilaiPenempatan)
        //     }
        //     let result = numArray.reduce((a, b) => a + b, 0)

        //     this.formRef.current.instance.updateData({
        //         totalPenempatan: result
        //     })
        // } else {
        //     this.formRef.current.instance.updateData({
        //         totalPenempatan: 0
        //     })
        // }
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daily Balance Portofolio</h3>
                <Form
                    ref={this.formRef}
                    colCount={2}
                    id={'formFilter'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                    labelLocation="left"
                />
                <div className="mt-3">
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                </div>
                <div id="tabindex-0" className={' mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceSaham store={this.props.store} ref={this.DataGridSahamRef} stateData={this.state.filterData} tabName={'Saham'} />
                </div>
                <div id="tabindex-1" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceReksadana store={this.props.store} ref={this.DataGridSahamRef} stateData={this.state.filterData} tabName={'Reksadana'} />
                </div>
                <div id="tabindex-2" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceDeposito store={this.props.store} ref={this.DataGridDepositoRef} stateData={this.state.filterData} tabName={'Deposito'} />
                </div>
                <div id="tabindex-3" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceSbn store={this.props.store} ref={this.DataGridSBNRef} stateData={this.state.filterData} tabName={'SBN'} />
                </div>
                <div id="tabindex-4" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceObligasi store={this.props.store} ref={this.DataGridObligasiRef} stateData={this.state.filterData} tabName={'Obligasi & Sukuk'} />
                </div>
                <div id="tabindex-5" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceEba store={this.props.store} ref={this.DataGridEbaRef} stateData={this.state.filterData} tabName={'EBA'} />
                </div>
                <div id="tabindex-6" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceProperti store={this.props.store} ref={this.DataGridPropertiRef} stateData={this.state.filterData} tabName={'Properti'} />
                </div>
                <div id="tabindex-7" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalancePenyertaan store={this.props.store} ref={this.DataGridPenyertaanlangsungRef} stateData={this.state.filterData} tabName={'Penyertaan Langsung'} />
                </div>
                <div id="tabindex-8" className={'d-none mt-2 text-center'} height={'100%'}>
                    <DataGridDailyBalanceAsetLain store={this.props.store} ref={this.DataGridPenyertaanlangsungRef} stateData={this.state.filterData} tabName={'Aset Lain Lain'} />
                </div>
            </div>
        )
    }
}

export default DetailDailyBalancePortofolio