import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader, ScrollView } from 'devextreme-react';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import { alert } from 'devextreme/ui/dialog';

class PPUVerifikasiSdmActionDokumenModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDokumentRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        
        this.dataMaster = [
            {
                dataField: 'documentFileName',
                label: {
                    alignment: 'left',
                    text: 'Nama Dokumen'
                }
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = (data) => {
        this.setState({
            dataMaster: data,
        })
    }

    submitData = () => {

    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
                
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Name`]: files.name,
                        [`${fieldName}Extension`]: formatNameAndExtention.extention,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))

            }
    
            fr.readAsDataURL(files)
        }
    }

    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`]),this.state.dataMaster[`${fileName}Name`])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Pembayaran'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <Form
                                ref={this.formDokumentRef}
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-6">
                                <FileUploader 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText={this.state.dataMaster.documentFileName ||'File'}
                                    showFileList={false}
                                    name={'documentFile'}
                                    disabled = {this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('documentFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('documentFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                    </div>
                </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default PPUVerifikasiSdmActionDokumenModal