import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form, SelectBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import {alert} from "devextreme/ui/dialog"
import httpRequest from "plugin/httprequest";
import { chartOfAccount, chartOfAccountGroup } from 'components/pages/parts/staticDataColumnConfig'
import { confirm } from 'devextreme/ui/dialog'

class ModalCOA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      formData: {},
      formCoaData: {},
      formGroupData: {},
    };

    this.isNew = false

    this.form = [
      {
        dataField:"type",
        label:{
          alignment:"left",
          text:"Tipe"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: [
            {
              id: 'coa',
              value: 'COA'
            },
            {
              id: 'group',
              value: 'Grup COA'
            },
          ],
          valueExpr : 'id',
          displayExpr: 'value',
          onValueChanged : (e) => {
            this.formCOARef.current.instance.option('visible',e.value === 'coa')
            this.formGroupRef.current.instance.option('visible',e.value === 'group')
          }
        }
      },
    ]

    this.formGroup = chartOfAccountGroup(this.props.store)
    this.formCOA = chartOfAccount(this.props.store)

    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: async () => {
            this.submitData()
          }
        },
        toolbar: "bottom"
      },
    ];

    this.oldData = {}

    this.formRef = React.createRef()
    this.formCOARef = React.createRef()
    this.formGroupRef = React.createRef()
  }


  retrieveData = (data = null) => {
    this.formRef.current.instance.updateData({
      type: data.type
    })
    this.formRef.current.instance.option('visible',false)
    this.formCOARef.current.instance.option('visible',data.type === 'coa')
    this.formGroupRef.current.instance.option('visible',data.type === 'group')

    if(data.type === 'coa'){
      let newData = {...data}
      var id = newData.id.split('-')
      newData.id = id[1]
      
      this.oldData = newData

      this.formCOARef.current.instance.updateData(newData)
    }

    if(data.type === 'group'){
      this.oldData = data
    
      this.formGroupRef.current.instance.updateData(data)
    }
    
  }

  submitData = async () => {
    if(!this.state.formData.type){
      alert("Mohon pilih tipe terlebih dahulu","Warning")
      return false
    }
    
    try{
      var dataSubmit = {}
      if(this.state.formData.type === 'coa'){
        const validateRes = await this.formCOARef.current.instance.validate()
        if(!validateRes.isValid){
          return false
        }

        if(this.oldData.coaCode !== this.state.formCoaData.coaCode){
          try{
            var resultCheck = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coas/getByCoaCode/${this.state.formCoaData.coaCode}`)
            if(resultCheck){
              alert("Kode COA telah terpakai","Error")
              return false
            }
          }catch(e){
            if(!e.includes('404')){
              alert("Kode COA telah terpakai","Error")
              return false
            }
          }
        }
    
        dataSubmit = {
          aliasName: this.state.formCoaData.aliasName,
          balanceAmount: this.state.formCoaData.balanceAmount,
          category: this.state.formCoaData.category,
          coaCode: this.state.formCoaData.coaCode,
          coaGroupId: this.state.formCoaData.coaGroupId,
          coaName: this.state.formCoaData.coaName,
          currencyId: this.state.formCoaData.currencyId,
          normalBalance: this.state.formCoaData.normalBalance,
          plbs: this.state.formCoaData.plbs,
        }

        if(this.state.formCoaData.id){
          dataSubmit.id = this.state.formCoaData.id
        }

        if(dataSubmit.id){
          await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'staticdata-wf/coas','PUT',{values: dataSubmit},dataSubmit)
        }else{
          await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'staticdata-wf/coas','POST',{values: dataSubmit})
        }
      }
    
      if(this.state.formData.type === 'group'){
        const validateRes = await this.formGroupRef.current.instance.validate()
        if(!validateRes.isValid){
          return false
        }

        if(this.oldData.coaGroupCode !== this.state.formGroupData.coaGroupCode){
          try{
            var resultCheck = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-groups/getByCoaGroupCode/${this.state.formGroupData.coaGroupCode}`)
            if(resultCheck.length > 0){
              alert("Kode Grup COA telah terpakai","Error")
              return false
            }
          }catch(e){
            if(!e.includes('404')){
              alert("Kode Grup COA telah terpakai","Error")
              return false
            }
          }
        }
    
        dataSubmit = {
          coaGroupCode: this.state.formGroupData.coaGroupCode,
          coaGroupName: this.state.formGroupData.coaGroupName,
          parentCoaGroupId: this.state.formGroupData.parentCoaGroupId,
        }

        if(this.state.formGroupData.id){
          dataSubmit.id = this.state.formGroupData.id
        }

        if(dataSubmit.id){
          await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'staticdata-wf/coa-groups','PUT',{values: dataSubmit},dataSubmit)
        }else{
          await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'staticdata-wf/coa-groups','POST',{values: dataSubmit})
        }
      }

      let type = 'success'
      let text = 'Data Berhasil Disimpan'

      notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
      this.isNew = true
      this.hide()
    }catch(e){
      console.log(e)

      let type = 'error'
      let text = 'Proses Menyimpan Data Gagal'

      notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
    }
  }

  show = () => {
    this.setState({
      popupVisible: true,
    });
    this.formRef.current.instance.option('visible',true)
    this.formCOARef.current.instance.option('visible',false)
    this.formGroupRef.current.instance.option('visible',false)
  };

  hide = async(e) => {
    var result
    if (this.state.popupVisible === true && !this.isNew) {
      if (typeof e.cancel === 'boolean') {
        e.cancel = true
      }
      var result = await confirm('Apakah anda yakin untuk membatalkan input data ini?', 'Konfirmasi')
    } else {
      result = true
    }

    if (result) {
      if(!this.isNew) {
        if (typeof e.cancel === 'boolean') {
          e.cancel = false
        }
      }

      this.setState({
        popupVisible: false,
        formData: {},
        formCoaData: {},
        formGroupData: {},
      });
  
      this.oldData = {}
    }
  };

  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          resizeEnabled = {true}
          showTitle={true}
          title="Chart of Account"
          width={"500px"}
          height={"auto"}
          toolbarItems={this.PopupToolbarItem}
        > 
          <div className="mb-3">
              <Form
                colCount={1}
                id={"formCOA"}
                formData={this.state.formData}
                items={this.form}
                ref={this.formRef}
                labelLocation="left"
              />
          </div>
          <Form
            colCount={1}
            id={"formCOA"}
            formData={this.state.formCoaData}
            items={this.formCOA}
            ref={this.formCOARef}
            labelLocation="left"
          />
          <Form
            colCount={1}
            id={"formGroup"}
            formData={this.state.formGroupData}
            items={this.formGroup}
            ref={this.formGroupRef}
            labelLocation="left"
          />

        </Popup>
      </React.Fragment>

    );
  }
}

export default ModalCOA