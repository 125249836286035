import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { broker, pejabat, productDefault } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import { imageSource } from 'plugin/helper'

class InstruksiBrokerEbaTerkirim extends Component {
    constructor(props) {
        super(props)
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            this.previewFile(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'transactionName',
                        caption: 'Tipe'
                    },
                    {
                        dataField: 'nominal',
                        caption: 'Nominal',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'harga',
                        caption: 'Harga (%)',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.0000'
                    },
                    {
                        dataField: 'portofolio',
                        caption: 'ID Portofolio'
                    },
                ]
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiNo',
                        caption: 'Nomor Instruksi'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'

                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            }
        ]
        this.modalPreviewPdfRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    previewFile = async(data) => {
        var instruksiId = data.instruksiId
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-sbns/get-instruksi/${instruksiId}`, 'GET')
        var src = imageSource(response.orderInstruksiDTO.fileInstruksiContentType,response.orderInstruksiDTO.fileInstruksi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    loadData = async() => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'order-instruksi-obligasi/list?size=9999&status=SENT', 'GET')
        var orderObl = []
        var filterResponse = response.filter((val) => val.transactionName.split(' ')[1] == 'EBA')
        for (var data of filterResponse) {
            var allData = {
                instruksiId: data.instruksiId,
                productId: data.productId,
                brokerId: data.brokerId,
                transactionName: data.transactionName,
                instruksiNo: data.instruksiNo,
                instruksiDate: data.instruksiDate,
                pejabat1: data.pejabat1,
                pejabat2: data.pejabat2,
                statusId: data.statusId,
            }
            orderObl.push(allData)
        }
        return orderObl
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Broker EBA Terkirim</h2>

                <DevExpressDataGrid
                    keyField={`instruksiId`}
                    loadAPI='order-instruksi-obligasi/list?status=SENT'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Instruksi Broker SBN"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Data'}
                    popupWidth={600} //masukan dalam ukuran pixel
                    popupHeight={450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default InstruksiBrokerEbaTerkirim