import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from 'devextreme/ui/notify'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { banks, managerInvestasis, custodies, productDefault, instrumentProperti, efekBalanceBuyDate } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalKpaAsset from './action'
import ScrollView from 'devextreme-react/scroll-view'

class ModalActionKpa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
            actionType: 'add'
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalAsetRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'noKontrak',
                        label: {
                            alignment: 'left',
                            text: 'Nomor Kontrak'
                        },
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                        },
                    },
                    {
                        dataField: 'kontrakDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Kontrak'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'efektifDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Efektif'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'expiredDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Akhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'kontrakNominalTahun',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Target Pertahun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'sharingProfitPercent',
                        label: {
                            alignment: 'left',
                            text: 'Sharing Profit (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'paymentPeriode',
                        label: {
                            alignment: 'left',
                            text: 'Periode Pembayaran (Bulan)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'nominalPeriode',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Per Periode'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'lastPaymentDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran Terakhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'nextPaymentDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran berikutnya'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'statusId',
                        label: {
                            alignment: 'left',
                            text: 'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Aktif'
                                },
                                {
                                    id: 2,
                                    value: 'Expired'
                                },
                                {
                                    id: 3,
                                    value: 'Inactive'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                        },
                    },
                    {
                        dataField: 'file',
                        label: {
                            text: 'File Kontrak'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStarted.bind(this),
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'KONTRAKTOR',
                        items: [
                            {
                                dataField: 'kontraktorName',
                                label: {
                                    alignment: 'left',
                                    text: 'Kontraktor',
                                },
                            },
                            {
                                dataField: 'kontraktorAddress',
                                label: {
                                    alignment: 'left',
                                    text: 'Alamat',
                                },
                            },
                            {
                                dataField: 'kontraktorTelp',
                                label: {
                                    alignment: 'left',
                                    text: 'No. Telp',
                                },
                            },
                            {
                                dataField: 'kontraktorFax',
                                label: {
                                    alignment: 'left',
                                    text: 'No. Fax',
                                },
                            },
                            {
                                dataField: 'kontraktorEmail',
                                label: {
                                    alignment: 'left',
                                    text: 'Email',
                                },
                            },
                            {
                                dataField: 'kontraktorCategory',
                                label: {
                                    alignment: 'left',
                                    text: 'Kategori',
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: 'horizontal',
                                    items: ['Institusi', 'Individu'],
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'CONTACT PERSON',
                        items: [
                            {
                                dataField: 'kontraktorCpName',
                                label: {
                                    alignment: 'left',
                                    text: 'Kontraktor',
                                },
                            },
                            {
                                dataField: 'kontraktorCpHp',
                                label: {
                                    alignment: 'left',
                                    text: 'No. HP',
                                },
                            },
                            {
                                dataField: 'kontraktorCpEmail',
                                label: {
                                    alignment: 'left',
                                    text: 'Email',
                                },
                            },
                        ]
                    }
                ]
            }
        ]

        this.column = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            data.productId = this.state.formData.productId
                            this.modalAsetRef.current.show(data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: (e) => {
                            this.deleteDataKpaAset(e.row.key)
                        }
                    },
                ]
            },
            {
                dataField: 'no',
                caption: 'No.',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'instrumenId',
                caption: 'Nama Aset',
                lookup: {
                    dataSource: instrumentProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'efekBalanceBuyDateId',
                caption: 'Tipe/Unit/Kav',
                lookup: {
                    dataSource: efekBalanceBuyDate(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'tipe'
                }
            },
            {
                dataField: 'efektifDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'expiredDate',
                caption: 'Tanggal Expired',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'kontrakNominal',
                caption: 'Nominal Kontrak',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]

        this.dataKpaAset = []
    }
    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Kontrak`]: formattedFileData.base64data,
                    [`${fieldName}KontrakContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    submitData = async () => {
        var dataMaster = this.state.formData
        dataMaster.fileKontrak = this.state.fileKontrak
        dataMaster.fileKontrakContentType = this.state.fileKontrakContentType
        var dataDetail = this.dataGridRef.current.getDataSource()
        if (dataMaster && dataDetail.length > 0) {
            try {
                if (this.state.actionType == 'add') {
                    var insertAPI = 'kpas'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: dataMaster
                    })

                    if (response) {
                        for (var detail of dataDetail) {
                            var dataAset = {
                                efekBalanceBuyDateId: detail.efekBalanceBuyDateId,
                                efektifDate: formatDate(detail.efektifDate),
                                expiredDate: formatDate(detail.expiredDate),
                                kontrakNominal: detail.kontrakNominal,
                                instrumenId: detail.instrumenId,
                                kpaId: response.id
                            }
                            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpa-assets', 'POST', {
                                values: dataAset
                            })
                        }
                        notify({ message: 'Data berhasil ditambahkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.props.forceRefresh()
                        this.hide()
                    }
                } else {
                    var updateAPI = 'kpas'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                        key: dataMaster.id,
                        values: dataMaster
                    }, dataMaster)

                    if (response) {
                        for (var detail of dataDetail) {
                            if (typeof detail.id == "number") {
                                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpa-assets`, 'DELETE', detail.id)
                            }
                            var dataAset = {
                                efekBalanceBuyDateId: detail.efekBalanceBuyDateId,
                                efektifDate: formatDate(detail.efektifDate),
                                expiredDate: formatDate(detail.expiredDate),
                                kontrakNominal: detail.kontrakNominal,
                                instrumenId: detail.instrumenId,
                                kpaId: response.id
                            }
                            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpa-assets', 'POST', {
                                values: dataAset
                            })
                        }
                        notify({ message: 'Data ' + response.noKontrak + ' berhasil diubah!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.props.forceRefresh()
                        this.hide()
                    }
                }
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    previewFile = async () => {
        var src = imageSource(this.state.fileKontrakContentType, this.state.fileKontrak)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    retrieveData = async (type, data = null) => {
        if (type !== 'add') {
            this.setState({
                actionType: type,
                formData: data,
                fileKontrak: data.fileKontrak,
                fileKontrakContentType: data.fileKontrakContentType,
            })
            var dataAset = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpa-assets/getByKpaId/${data.id}`, 'GET')
            console.log(dataAset)
            this.dataKpaAset = dataAset
            this.dataGridRef.current.forceRefresh(true)
        } else {
            this.setState({
                actionType: type,
                formData: {}
            })
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Tambah Aset',
                    icon: 'plus',
                    onClick: (e) => {
                        var data = {}
                        data.productId = this.state.formData.productId
                        this.showModalAset(data)
                    },
                }
            },
        )
    }
    showModalAset = (data) => {
        this.modalAsetRef.current.show(data)
    }
    submitDataKpaAset = (data) => {
        if (data.id) {
            this.dataGridRef.current.push([{
                type: "update",
                data: {
                    efekBalanceBuyDateId: data.efekBalanceBuyDateId,
                    efektifDate: data.efektifDate,
                    expiredDate: data.expiredDate,
                    kontrakNominal: data.kontrakNominal,
                    instrumenId: data.instrumenId,
                },
                key: data.id
            }])
        } else {
            this.dataGridRef.current.push([{
                type: "insert",
                data: {
                    efekBalanceBuyDateId: data.efekBalanceBuyDateId,
                    efektifDate: data.efektifDate,
                    expiredDate: data.expiredDate,
                    kontrakNominal: data.kontrakNominal,
                    instrumenId: data.instrumenId,
                }
            }])
        }
    }
    deleteDataKpaAset = (key) => {
        this.dataGridRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    loadData = () => {
        return this.dataKpaAset
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form ' + (this.state.actionType == 'edit' ? 'Ubah' : 'Tambah') + ' KPA'}
                    width={'80vw'}
                    height={'80vh'}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={2}
                                        id={"formMaster"}
                                        store={this.props.store}
                                        formData={this.state.formData}
                                        items={this.items}
                                        ref={this.formRef}
                                        labelLocation={"left"}
                                    />
                                    <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileKontrak ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Buka File
                                    </button>
                                </div>
                                <DevExpressDataGrid
                                    ref={this.dataGridRef}
                                    loadAPI='kpa-assets'
                                    insertAPI='kpa-assets'
                                    updateAPI='kpa-assets'
                                    deleteAPI='kpa-assets'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                    useArraySource={true}
                                    ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={true}
                                    exportFileName={"Kontrak Pengelola Asset"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Kontrak Pengelola Asset'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.column} // taruh konfigurasi kolom disini
                                    // SummaryConfiguration={this.summary}

                                    onToolbarPreparing={this.onToolbarPreparing}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </ScrollView>
                    <ModalPreviewPdf
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                    <ModalKpaAsset
                        ref={this.modalAsetRef}
                        store={this.props.store}
                        submitDataKpaAset={this.submitDataKpaAset}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalActionKpa