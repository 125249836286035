import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { Button } from 'devextreme-react/button';
import notify from "devextreme/ui/notify";

import httpRequest from 'plugin/httprequest';


class FormPersetujuanPenempatanObligasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
        }

        this.prevTabIndex = 0
        this.dataTransaction = {}

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Setuju',
                    onClick: () => {
                        console.log('...')
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Menolak',
                    onClick: () => {
                        console.log('...')
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.DataMaster =[
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No. Pengajuan'
                },
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'pengajuanDate',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'd MMM yyyy',
                    readOnly: true
                },
            },
            {
                dataField: 'instrument',
                label: {
                    text: 'Instrument'
                },
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'transactionType',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'jumlahKomite',
                label: {
                    text: 'Jumlah Komite'
                },
                editorOptions: {
                    readOnly : true
                }
            },
            {
                type: 'empty',
            },
            {
                dataField: 'diSetujui',
                label: {
                    text: 'Di Setujui'
                },
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'diTolak',
                label: {
                    text: 'Di Tolak'
                },
                editorOptions: {
                    readOnly : true
                }
            },
            // {
            //     dataField: 'persetujuanAnda',
            //     label: {
            //         text: 'Persetujuan Anda'
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         dataSource: [
            //             {
            //                 id:1,
            //                 value: 'Menyetujui'
            //             },
            //             {
            //                 id:2,
            //                 value: 'Menolak'
            //             },
            //         ],
            //         valueExpr: 'id',
            //         displayExpr: 'value'
            //     }
            // },
            {
                dataField: 'note',
                label: {
                    text: 'Catatan'
                },
                colSpan : 2,
                editorType: 'dxTextArea',
                editorOptions: {
                    height: 250
                }
            }
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: {}
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }
    submitData = async() => {
        console.log('submit')
    }
    retrieveData = async(data,dataTransaction) => {
        this.setState({
            dataMaster: data
        })

        this.dataTransaction = dataTransaction
    }

    showTransaction = () => {
        this.props.showModalView(this.dataTransaction)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Persetujuan'}
                width={'700'}
                height={'600'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12' } style={{overflowY:"auto"}}>
                            <div className="row">
                                <div className='col-md-12 text-right mb-4'>
                                    <Button
                                        text="Lihat Data Transaksi"
                                        type="normal"
                                        stylingMode="contained"
                                        onClick={this.showTransaction.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={2}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.dataMaster}
                                        items={this.DataMaster}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormPersetujuanPenempatanObligasi