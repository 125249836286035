import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import {Form,FileUploader} from 'devextreme-react';
import { Button } from 'devextreme-react';
import ViewPenerimaThr from 'components/pages/modal/sdm-umum/payroll/viewPenerimaThr';
import ViewPenerimaBonus from 'components/pages/modal/sdm-umum/payroll/bonusTahunan/viewPenerimaBonus';
import notify from "devextreme/ui/notify";
import { months, gender, tunjanganHrd, statusPembayaranRekapGaji, programs, jabatan, sdmUnit } from 'dataSource/lookup';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';

class PengajuanBonusTahunan extends Component {
    constructor(props){
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            // selectedTabIndex: 0,
            DataRekap:{},
            DataMaster:{
                tanggalPengajuan: new Date(this.sysDate ? this.sysDate.dateValue : null),
                tahunBonus: this.sysDate ? new Date(this.sysDate.dateValue).getFullYear() : null,
            },
            DataDocument:{}
        }

        this.prevTabIndex = 0

        this.formMaster = [
            {
                itemType: "group",
                colCount: 1,
                items: [
                    {
                        dataField: "programId",
                        label: {
                            text: "Program",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField:"tanggalPengajuan",
                        label:{
                            text:"Tanggal Pengajuan",
                            alignment:"left"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField:"tahunBonus",
                        label:{
                            text:"Tahun Bonus",
                            alignment:"left"
                        },
                        editorType: 'dxNumberBox',
                    },
                ]
            },
            {
                itemType: "group",
                colCount: 1,
                items:[
                    
                    {
                        dataField: 'tipeTunjangan',
                        label: {
                            text: 'Tipe Tunjangan',
                            alignment: 'left'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tunjanganHrd(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'tunjanganName',
                            searchEnabled: true,
                            deferRendering: false,
                            // readOnly: true
                        }
                    },
                    
                    {
                        dataField:"keterangan",
                        label:{
                            text:"Keterangan",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"namaTunjangan",
                        label:{
                            text:"Nama Tunjangan",
                            alignment:"left"
                        }
                    },
                ]
            },
            {
                itemType: "group",
                colCount: 1,
                items:[
                    
                    {
                        dataField:"tanggalPembayaran",
                        label:{
                            text:"Tanggal Pembayaran",
                            alignment:"left"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField:"dasarNilaiId",
                        label:{
                            text:"Dasar Nilai",
                            alignment:"left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id:2,
                                    value:"Persentase",
                                },
                                {
                                    id:3,
                                    value:"Nominal",
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged:(e) => {
                                if (e.value) {
                                    if (e.value == 3) {
                                        this.formMasterRef.current.instance.getEditor('nominal').option('readOnly',false)
                                        this.formMasterRef.current.instance.getEditor('presentaseGapokTunjTetap').option('readOnly',true)
                                    }else{
                                        this.formMasterRef.current.instance.getEditor('nominal').option('readOnly',true)
                                        this.formMasterRef.current.instance.getEditor('presentaseGapokTunjTetap').option('readOnly',false)
                                    }
                                }
                            }
                        }
                    },
                    {
                        dataField:"presentaseGapokTunjTetap",
                        label:{
                            text:"Persentase Gapok+Tunjangan Tetap",
                            alignment:"left"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly:true
                        }
                    },
                    {
                        dataField:"nominal",
                        label:{
                            text:"Nominal Pembayaran",
                            alignment:"left"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format:'#,##0.00',
                            readOnly:true
                        }
                    },
                ]
            },
        ]

        this.formRekap = [
            {
                itemType: "group",
                colCount: 1,
                items: [
                    {
                        dataField:"bonusTahun",
                        label:{
                            text:"Tahun Bonus",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"keterangan",
                        label:{
                            text:"Keterangan",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"jumKaryawan",
                        label:{
                            text:"Jumlah Karyawan",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"jumKaryawan",
                        label:{
                            text:"Jumlah Penerima",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"jumNominal",
                        label:{
                            text:"Total Bonus",
                            alignment:"left"
                        }
                    }
                ]
            }
            
        ]

        this.penerimaBonusColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'View',
                        hint : 'View',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalPenerimaBonus()
                        }
                    },
                ],
            },
            {
                dataField:"bonus",
                caption:"Bonus",
                alignment:'center',
                columns:[
                    {
                        dataField:"tunjanganTypeId",
                        caption:"Tipe",
                        lookup:{
                            dataSource: tunjanganHrd(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'tunjanganName',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:"bonusTahun",
                        caption:"Tahun",
                    },
                    {
                        dataField:"persenGaji",
                        caption:"% Gaji",
                    },
                ]
            },
            {
                dataField:"nik",
                caption:"NIK",
            },
            {
                dataField:"fullName",
                caption:"Nama",
            },
            {
                dataField:"jabatanId",
                caption:"Jabatan",
                lookup:{
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"unitId",
                caption:"Unit",
                lookup:{
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            // {
            //     dataField:"cabang",
            //     caption:"Cabang",
            // },
            {
                dataField:"nominalBonus",
                caption:"Nominal Bonus",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:"pajak",
                caption:"Pajak",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:"nominalBersih",
                caption:"Bonus Bersih",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:"statusId",
                caption:"Status",
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Rekap'
            },
            {
                id: 1,
                text: 'Daftar Penerima Bonus'
            },
        ]

        this.popupPenerimaBonusRef = React.createRef()
        
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.formRekapRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.listDataPenerimaBonus = []
        this.idBonus = 0
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 


    cleanDataGrid = () => {
        var data = this.DataGridPengajuanRef.current.getDataSource()
        var deleteData = []
        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            this.DataGridPengajuanRef.current.push(deleteData)
        }
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
        if (args.value == 1) {
            this.loadDataGrid()
        }
    }

    showModalPenerimaBonus = () => {
        this.popupPenerimaBonusRef.current.show()
    }

    kirimBonus = async () => {
        try {
            confirmAlert({
                title: 'Konfirmasi',
                message: 'Kamu yakin ingin kirim data ??',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            var loadApi = `kirim-bonus-tahunan/${this.idBonus}`
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST',{
                                values:{}
                            })

                            if (response) {
                                notify({ message: 'Sukses Kirim', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                                this.cleanDataGrid()
                                this.listDataPenerimaBonus = []
                                this.idBonus = 0
                                this.setState({
                                    DataDocument:{},
                                    DataRekap: {},
                                    DataMaster: {
                                        tanggalPengajuan: new Date(this.sysDate.dateValue),
                                        tahunBonus: new Date(this.sysDate.dateValue).getFullYear(),
                                    },
                                })
                                
                            }
                        }
                    },{
                        label: 'No',
                    }
                ]
            })
            
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataDocument = { ...this.state.DataDocument };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;

            console.log(DataDocument);
            this.setState({
                DataDocument: DataDocument
            });
        };

        fr.readAsDataURL(files);
        }
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    
    generate = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)
            DataMaster.tanggalPembayaran = formatDate(DataMaster.tanggalPembayaran)
            var generate = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'generate-bonus-tahunan', 'POST',{
                values:DataMaster
            })

            if (generate) {
                var DataRekap = {}
                this.idBonus = generate[0].id
                DataRekap.bonusTahun = generate[0].bonusTahun
                DataRekap.keterangan = generate[0].keterangan
                DataRekap.jumKaryawan = generate[0].jumKaryawan
                DataRekap.jumPenerima = generate[0].jumKaryawan
                DataRekap.jumNominal = generate[0].jumNominal

                this.formRekapRef.current.instance.updateData(DataRekap)
                notify({ message: 'Sukses Generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    loadDataGrid = async () => {
        var loadApi = `view-daftar-penerima-bonuses?tunjanganTypeId.in=${this.state.DataMaster.tipeTunjangan || 0}&pengajuanTanggal.equals=${formatDate(this.state.DataMaster.tanggalPengajuan) || formatDate(this.sysDate.dateValue)}`
        var getDataPenerimaThr = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store,loadApi , 'GET')
        this.listDataPenerimaBonus = getDataPenerimaThr
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    loadData = async () => {
        return this.listDataPenerimaBonus
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Bonus Tahunan</h3>
                <div className="my-3">
                    <Form
                    
                        ref={this.formMasterRef}
                        colCount={3}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                        labelLocation={"left"}
                    />
                    <div className="row">
                        <div className="col-sm-4">
                            {/* <label style={{fontSize:"13px"}}>Dokumen</label> */}
                            <FileUploader
                                accept={".png,.jpg.jpeg"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText={this.state.DataDocument.dokumenName || 'Upload File'}
                                showFileList={false}
                                name={"dokumen"}
                                // disabled={this.props.from == 'pengajuan' ? false : true}
                            />
                        </div>
                        <div className="col-sm-4 pt-3">
                            <button onClick={() => this.previewFile(this.state.DataDocument, 'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                    </div>
                <Button 
                    type={'default'}
                    elementAttr={{class:"bg-dapen"}}
                    text={'Generate Bonus'}
                    onClick={(e) => {this.generate(e)}}
                />
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0" className={' mt-2 text-center' } style={{height: '100%'}}>
                <h3 className="main-title">Rekap Bonus Tahunan</h3>
                    <div style={{maxWidth:"500px",margin:"auto"}}>
                        <Form
                            ref={this.formRekapRef}
                            colCount={1}
                            id={'formRekap'}
                            formData={this.state.DataRekap}
                            items={this.formRekap}
                            labelLocation={"left"}
                            readOnly={'true'}
                        />
                    </div>
                </div>
                <div id="tabindex-1" className={'d-none mt-2 text-center' }>
                <div className="container-fluid" style={{height:"calc(75vh - 325px)" }}>
                    <div style={{height:"100%", width:'100%' }}>
                    <DevExpressDataGrid
                        ref = {this.DataGridPengajuanRef}
                        loadAPI='daftar-penerima-thr'
                        insertAPI='daftar-penerima-thr'
                        updateAPI='daftar-penerima-thr'
                        deleteAPI='daftar-penerima-thr' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData= {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Daftar Penerima THR"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        height={'calc(75vh - 345px)'}
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Penerima THR'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.penerimaBonusColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    </div>
                    </div>
                    <ViewPenerimaBonus 
                        ref={this.popupPenerimaBonusRef}
                        store={this.props.store}
                    />
                </div>
                <div className="my-3 float-right">
                        <Button
                            width={'200px'}
                            elementAttr={{ class: "bg-dapen" }}
                            text={"Batal"}
                            type={"success"}
                            stylingMode={"contained"}
                            onClick={console.log('ea')}
                            className={"mx-2"}
                            visible={true}
                        />
                        <Button
                            width={'200px'}
                            elementAttr={{ class: "bg-dapen" }}
                            text={"Kirim"}
                            type={"success"}
                            stylingMode={"contained"}
                            onClick={this.kirimBonus.bind(this)}
                            className={"mx-2"}
                            visible={true}
                        />
                    </div>
                    
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default PengajuanBonusTahunan