import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import Form from 'devextreme-react/form';
import { Button,FileUploader,ScrollView } from 'devextreme-react';
import ViewPenerimaThr from 'components/pages/modal/sdm-umum/payroll/viewPenerimaThr';
import { agama, jabatan, karyawan, programs, sdmUnit, statusKaryawanSdm, statusKepegawaianSdm, statusPembayaranRekapGaji, tunjanganHrd } from 'dataSource/lookup';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';

import notify from "devextreme/ui/notify";

class PengajuanThr extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            DataDocument:{},
            DataRekap: {},
            DataMaster: {
                tanggalPengajuan: new Date(this.sysDate ? this.sysDate.dateValue : null),
                tahunThr: this.sysDate ? new Date(this.sysDate.dateValue).getFullYear() : null,
            },
            url: `view-daftar-penerima-thrs?idThr.in=0`
        }
        this.prevTabIndex = 0
        this.formMaster = [
            {
                itemType:"group",
                colCount:1,
                items:[
                    {
                        dataField:'programId',
                        label:{
                            text:'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: "tanggalPengajuan",
                        label: {
                            text: "Tanggal Pengajuan",
                            alignment: "left"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'tahunThr',
                        label: {
                            text: 'Tahun THR',
                            alignment: 'left'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                        }
                    },
                    
                    {
                        dataField: "namaTunjangan",
                        label: {
                            text: "Nama Tunjangan",
                            alignment: "left"
                        }
                    },
                    // {
                    //     dataField: "dokumen",
                    //     label: {
                    //         text: "Document",
                    //         alignment: 'left'
                    //     },
                    //     editorType: "dxFileUploader",
                    //     editorOptions: {
                    //         showFileList:false,
                    //         selectButtonText: "Select File",
                    //         labelText: "Upload File",
                    //         uploadMode: "useForm",
                    //         accept: 'image/png, image/jpeg, application/pdf',
                    //         onValueChanged: this.onUploadStarted.bind(this),
                    //     },
                    // },
                ]
            },
            {
                itemType: "group",
                colCount: 1,
                items: [
                    {
                        dataField: 'statusKepegawaianId',
                        label: {
                            text: 'Status Kepegawaian',
                            alignment: 'left' 
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: statusKepegawaianSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'namaStatus',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged:(e) => {
                                if(e.value){
                                    var data = 0
                                    if(e.value == 1){
                                        data = 21
                                    }else if (e.value == 2){
                                        data = 20
                                    }else{
                                        data = 22
                                    }
                                    this.formMasterRef.current.instance.updateData({tunjanganType:data})
                                }
                            }
                            // readOnly: true
                        }
                    },
                    {
                        dataField: 'tunjanganType',
                        label: {
                            text: 'Tipe Tunjangan',
                            alignment: 'left'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tunjanganHrd(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'tunjanganName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                    {
                        dataField: "agama",
                        label: {
                            text: "Agama",
                            alignment: "left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: agama(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                        },
                    },
                    {
                        dataField: "tglHariRaya",
                        label: {
                            text: "Tanggal Hari Raya",
                            alignment: "left"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 1,
                items:[
                    {
                        dataField: "tanggalPembayaran",
                        label: {
                            text: "Tanggal Pembayaran",
                            alignment: "left"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: "prosentase",
                        label: {
                            text: "Prosentase",
                            alignment: "left"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                        }
                    },
                    {
                        dataField: "keterangan",
                        label: {
                            text: "Keterangan",
                            alignment: "left"
                        }
                    },
                ]
            },
        ]

        this.formRekap = [
            {
                itemType: "group",
                colCount: 2,
                items: [
                    {
                        dataField: "thrTahun",
                        label: {
                            text: "Tahun THR",
                            alignment: "left"
                        }
                    },
                    {
                        dataField: "keterangan",
                        label: {
                            text: "Keterangan THR",
                            alignment: "left"
                        }
                    },
                    {
                        dataField: "religionName",
                        label: {
                            text: "Agama",
                            alignment: "left"
                        },
                        // editorType: 'dxSelectBox',
                        // editorOptions: {
                        //     dataSource: agama(),
                        //     valueExpr: 'id',
                        //     displayExpr: 'value',
                        //     searchEnabled: true,
                        // }
                    },
                    {
                        dataField: "jumKaryawan",
                        label: {
                            text: "Jumlah Karyawan",
                            alignment: "left"
                        }
                    },
                    {
                        dataField: "jumPenerima",
                        label: {
                            text: "Jumlah Penerima",
                            alignment: "left"
                        }
                    },
                    {
                        dataField: "jumNominal",
                        label: {
                            text: "Total THR",
                            alignment: "left"
                        }
                    }
                ]
            }

        ]

        this.penerimaThrColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'View',
                        hint: 'View',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPenerimaThr(e)
                        }
                    },
                    // {
                    //     text: 'Approve',
                    //     hint: 'Approve',
                    //     cssClass: 'text-primary',
                    //     onClick: (e) => {
                    //         console.log("new")
                    //     }
                    // },
                ],
            },
            {
                dataField: "thr",
                alignment: 'center',
                caption: "THR",
                columns: [
                    {
                        dataField: 'tunjanganName',
                        caption: 'Tipe'
                    },
                    {
                        dataField: 'thrTahun',
                        caption: 'Tahun'
                    },
                    {
                        dataField: 'nilaiPersen',
                        caption: '% Gaji'
                    },
                ]
            },
            {
                dataField: "nik",
                caption: "NIK",
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'nikPanjang'
                // }
            },
            {
                dataField: "fullName",
                caption: "Nama Lengkap",
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'fullName'
                // }
            },
            {
                dataField: "jabatanId",
                caption: "Jabatan",
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName'
                }
            },
            {
                dataField: "unitId",
                caption: "Unit",
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName'
                }
            },
            {
                dataField:"religionId",
                caption:"Agama",
                lookup: {
                    dataSource: agama(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: "nominalThr",
                caption: "Nominal THR",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: "pph",
                caption: "Pajak",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: "nominalBersih",
                caption: "THR Bersih",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: "statusId",
                caption: "Status",
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Rekap'
            },
            {
                id: 1,
                text: 'Daftar Penerima THR'
            },
        ]
        
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.popupPenerimaThrRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.formRekapRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.listDataPenerimaThr = []
        this.idThr = 0
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
        if (args.value == 1) {
            this.loadDataGrid()
        }
        
    }

    showModalPenerimaThr = (e) => {
        if (e.row) {
            this.popupPenerimaThrRef.current.retrieveData(e.row.data)
        }
        this.popupPenerimaThrRef.current.show()
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataDocument = { ...this.state.DataDocument };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;

            this.setState({
                DataDocument: DataDocument
            });
        };

        fr.readAsDataURL(files);
        }
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    cleanDataGrid = () => {
        var data = this.DataGridPengajuanRef.current.getDataSource()
        var deleteData = []
        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            this.DataGridPengajuanRef.current.push(deleteData)
        }
    }

    generateGaji = async() => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            var loadApi = 'karyawan-thrs/generate'
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)
            DataMaster.tanggalPembayaran = formatDate(DataMaster.tanggalPembayaran)
            DataMaster.tglHariRaya = formatDate(DataMaster.tglHariRaya)
            var generate = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST',{
                values:DataMaster
            })
            

            if (generate) {
                var DataRekap = {}
                let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `view-rekap-generate-thrs/${generate[0].id}`, 'GET')

                this.idThr = generate[0].id
                DataRekap.thrTahun = response.thrtahun
                DataRekap.keterangan = response.keterangan
                DataRekap.religionName = response.religionname
                DataRekap.jumKaryawan = response.jumkaryawan
                DataRekap.jumPenerima = response.jumpenerima
                DataRekap.jumNominal = response.jumnominal
                
                this.loadDataGrid()
                this.formRekapRef.current.instance.updateData(DataRekap)
                notify({ message: 'Sukses Generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }

            
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    kirimThr = async (e) => {
        try {
            confirmAlert({
                title: 'Konfirmasi',
                message: 'Kamu yakin ingin kirim data ??',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            var loadApi = `karyawan-thrs/kirim/${this.idThr}`
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST',{
                                values:{}
                            })

                            if (response) {
                                notify({ message: 'Sukses Kirim', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                                this.cleanDataGrid()
                                this.listDataPenerimaThr = []
                                this.idThr = 0
                                this.setState({
                                    DataDocument:{},
                                    DataRekap: {},
                                    DataMaster: {
                                        tanggalPengajuan: new Date(this.sysDate.dateValue),
                                        tahunThr: new Date(this.sysDate.dateValue).getFullYear(),
                                    },
                                })
                                
                            }
                        }
                    },{
                        label: 'No',
                    }
                ]
            })
            
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    loadDataGrid = async () => {
        var loadApi = `view-daftar-penerima-thrs?idThr.in=${this.idThr}&size=9999`
        var getDataPenerimaThr = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store,loadApi , 'GET')

        this.listDataPenerimaThr = getDataPenerimaThr
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    loadData = async () => {
        return this.listDataPenerimaThr
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan THR</h3>
                <div className="my-3">
                    <Form
                        ref={this.formMasterRef}
                        colCount={3}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                        labelLocation={"left"}
                    />
                    <div className="row">
                        <div className="col-sm-4">
                            {/* <label style={{fontSize:"13px"}}>Dokumen</label> */}
                            <FileUploader
                                accept={".png,.jpg.jpeg"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText={this.state.DataDocument.dokumenName || 'Upload File'}
                                showFileList={false}
                                name={"dokumen"}
                                // disabled={this.props.from == 'pengajuan' ? false : true}
                            />
                        </div>
                        <div className="col-sm-4 pt-3">
                            <button onClick={() => this.previewFile(this.state.DataDocument, 'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                    </div>
                        <Button
                            type={'default'}
                            elementAttr={{ class: "bg-dapen" }}
                            text={'Generate THR'}
                            onClick={(e) => { this.generateGaji(e) }}
                        />
                </div>
                
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0" className={' mt-2 text-center'}>
                    <h3 className="main-title">Rekap Pengajuan THR</h3>
                    <div className='col-9 center-block mx-auto' style={{ width:'100%' }}>
                        <div style={{ width:'121%',paddingLeft:'25%' }}>
                            <Form
                                ref={this.formRekapRef}
                                colCount={2}
                                id={'formRekap'}
                                formData={this.state.DataRekap}
                                items={this.formRekap}
                                labelLocation={"left"}
                                readOnly={true}
                            />
                            </div>
                    </div>
                </div>
                <div id="tabindex-1" className={'d-none mt-2 text-center'}>
                    <div className="container-fluid" style={{height:"calc(75vh - 325px)" }}>
                    <div style={{height:"100%", width:'100%' }}>
                            <DevExpressDataGrid
                                ref={this.DataGridPengajuanRef}
                                loadAPI={this.state.url}
                                insertAPI='daftar-penerima-thr'
                                updateAPI='daftar-penerima-thr'
                                deleteAPI='daftar-penerima-thr'

                                backendserver={process.env.REACT_APP_BACKEND_HRD}

                                remoteOperations={false}

                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Daftar Penerima THR"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                height={'calc(75vh - 325px)'}
                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Daftar Penerima THR'}
                                popupWidth={450} //masukan dalam ukuran pixel
                                popupHeight={330} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.penerimaThrColumns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            </div>
                    </div>
                    <ViewPenerimaThr
                        ref={this.popupPenerimaThrRef}
                        store={this.props.store}
                    />
                </div>
                <div className="my-3 float-right">
                    <Button
                        width={'200px'}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Batal"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={console.log('ea')}
                        className={"mx-2"}
                        visible={true}
                    />
                    <Button
                        width={'200px'}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Kirim"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.kirimThr.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default PengajuanThr