import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, formatDefaultDate, formatNumber, formatNumberAum } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import { reportFile } from 'plugin/reportRequest'

class LaporanResumeJenisInstrument extends Component {
    constructor(props) {
        super(props)

        this.modalKajianRef = React.createRef()
        this.modalInstrumentRef = React.createRef()
        this.modalKomiteRef = React.createRef()
        this.dgRef = React.createRef()

        this.state = {
            filterData: {
                productId: 1,
                productCode: 'PPMP'
            }
        }

        this.columns = [
            {
                dataField: 'categoryName',
                caption: 'Jenis Instrument',
            },
            {
                dataField: 'totalNilaiPenempatan',
                caption: 'Total Penempatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'tglValuasiAkhir',
                caption: 'Tanggal Valuasi Terakhir',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'accuredInterest',
                caption: 'Bunga Berjalan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'unralizedPersen',
                caption: 'Unrealized P/L (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'unralizedNominal',
                caption: 'Unrealized P/L (Nominal)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nilaiSekarang',
                caption: 'Nilai Saat Ini',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'komposisi',
                caption: 'Komposisi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.productCode + ' - ' + e.productName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]

        this.sumHasilInvestasi = [
            {
                showInColumn: 'categoryName',
                displayFormat: 'Total :'
            },
            {
                column: 'totalNilaiPenempatan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'totalNilaiPenempatan',
                displayFormat: '{0}'
            },
            {
                column: 'accruedInterest',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumAccruedInterest',
                displayFormat: '{0}'
            },
            {
                column: 'unrealizedNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumUnrealizedNominal',
                displayFormat: '{0}'
            },
            {
                column: 'nilaiSekarang',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumNilaiSekarang',
                displayFormat: '{0}'
            },
            {
                column: 'komposisi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumKomposisi',
                displayFormat: '{0}'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    loadData = () => {
        var data = [
            {
                id: 1,
            }
        ]
        return data
    }

    print =  async () => {
        let newData = [];
        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'view-resume-portfolios?productId.in=1');
        
        if (response.length > 0){
            let arrNilaiPenempatan = [];
            let arrNilaiSaatIni = [];
            let arrKomp = [];
            for (let data of response){
                arrNilaiPenempatan.push(data.totalNilaiPenempatan);
                arrNilaiSaatIni.push(data.nilaiSekarang);
                arrKomp.push(data.komposisi);

                let dataResult = {
                    id: 1,
                    categoryName: data.categoryName,
                    totalNilaiPenempatan: data.totalNilaiPenempatan,
                    nilaiSekarang: data.nilaiSekarang,
                    tglValuasiAkhir: data.tglValuasiAkhir ? formatDefaultDate(data.tglValuasiAkhir) : '-',
                    accuredInterest: data.accuredInterest,
                    unralizedPersen: data.unralizedPersen,
                    unralizedNominal: data.unralizedNominal,
                    nilaiSekarang: data.nilaiSekarang,
                    komposisi: data.komposisi
                }
                newData.push(dataResult)
            }
            var totalNilaiPenempatan = arrNilaiPenempatan.reduce((a,b) => a + b, 0);
            var totalNilaiSaatIni = arrNilaiSaatIni.reduce((a, b) => a + b, 0);
            var totalKomp = arrKomp.reduce((a, b) => a + b, 0);
        }
        reportFile({
            template: { 'shortid': 'BkggnmzeGi' },
            data: {
                "portfolioDetail": newData,
                "totalNilai": totalNilaiPenempatan,
                "totalNilaiSaatIni": totalNilaiSaatIni,
                "totalKomp": totalKomp
            },
            options: {
                reportName: "Resume Portfolio Per Jenis Instrument"
            }
        }, true, `Resume Portfolio Per Jenis Instrument.pdf`)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'print',
                  onClick: (e) => {
                    this.print()
                  },
              }
          }
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Resume Portofolio Per Jenis Instrument</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI={`view-resume-portfolios?productId.in=${this.state.filterData.productId}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Hasil Investasi"}
                    allowExportSelectedData={true}
                    selection={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumHasilInvestasi}
                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanResumeJenisInstrument