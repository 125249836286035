import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import {alert,confirm} from "devextreme/ui/dialog";
import httpRequest from "plugin/httprequest";
import { reportHeaderDetailFilterHeader } from "dataSource/lookup";
import { showLoading } from "redux/actions/loading";

class ModalCashFlowConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible:false,
      DataMaster:{},
      reportHeaderData:{}
    };

    this.formItem = [
        {
            dataField: 'description',
            label: {
                text: 'Deskripsi',
                alignment: 'left'
            },
        }, 
        {
            dataField: 'seqNo',
            label: {
                text: 'Urutan',
                alignment: 'left'
            },
            editorType: 'dxNumberBox'
        }, 
        {
            dataField: 'beginningValue',
            label: {
                text: 'Nilai Awal',
                alignment: 'left'
            },
            editorType: 'dxNumberBox',
            editorOptions: {
                format:'#,##0.00;(#,##0.00);'
            }
        }, 
        {
            dataField: 'parentId',
            label: {
                text: 'Induk',
                alignment: 'left'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: [],
                valueExpr: 'id',
                displayExpr: 'description',
                searchEnabled: true,
                showClearButton: true
            }
        }, 
    ]

    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: this.updateData.bind(this)
        },
        toolbar: "bottom"
      },
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Batal",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.formRef = React.createRef()
    this.reportHeaderId = null
    // this.reportHeaderData = null
  }
  

  retriveData = async (data) => {
      this.formRef.current.instance.updateData(data)
  }

  updateData = async () => {
    this.props.store.dispatch(showLoading(true))
    try{
        var dataInsert = {...this.state.DataMaster}
        dataInsert.reportHeaderId = this.reportHeaderId
        if(typeof dataInsert.id === 'number'){
            const responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-header-details`,'PUT',{
            values: dataInsert
            },dataInsert)
        }else{
            delete dataInsert.id
            const responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-header-details`,'POST',{
            values: dataInsert
            })
        }
  
        let type = 'success'
        let text = 'Data berhasil disimpan!'
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
    
        this.hide()
        this.props.forceRefresh()

        this.props.store.dispatch(showLoading(false))
    }catch(e){
        let type = 'error'
        let text = 'Data gagal disimpan!'
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
        console.log(e)
        this.props.store.dispatch(showLoading(true))
    }
  }

  show = async (reportHeaderId) => {
    const ReportData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store, `report-headers/${reportHeaderId}`, 'GET');
    // console.log(this.reportHeaderData.reportType + ' - ' + this.reportHeaderData.reportName)
    this.setState({
      popupVisible: true,
      reportHeaderData: ReportData
    });

    this.reportHeaderId = reportHeaderId
    var parentIdEditor = this.formRef.current.instance.getEditor('parentId')
    const data = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`report-header-details/getAllByReportHeaderId/${reportHeaderId}`)
    parentIdEditor.option('dataSource',data)
  };

  hide = () => {
    this.setState({
      popupVisible: false,
      DataMaster: {}
    });
    this.reportHeaderId = null
  };

  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={false}
          showTitle={true}
          title={`Edit Konfigurasi Detail ${this.state.reportHeaderData.reportType} - ${this.state.reportHeaderData.reportName}`}
          width={"600px"}
          height={"400px"}
          toolbarItems={this.PopupToolbarItem}
        >
            <Form
                colCount={1}
                id={"formMaster"}
                formData={this.state.DataMaster}
                items={this.formItem}
                ref={this.formRef}
            />
        </Popup>
      </React.Fragment>

    );
  }
}

export default ModalCashFlowConfig;
