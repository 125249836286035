import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, statusIuran } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalInsertPengkinianAktif from 'components/pages/modal/kepesertaan/pengkinianPeserta/aktif/modalInsert'
import { confirmAlert } from 'react-confirm-alert'

class DaftarIuranDitunda extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {}
        }

        this.dataGridRef = React.createRef()
        this.modalInsertPengkinianAktifRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Update',
                        hint : 'Update',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text : 'Proses',
                        hint : 'Proses',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Proses Iuran Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.prosesData(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'iuranId',
                caption: 'No',
                cellRender : (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'bulan',
                caption: 'Periode Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'nama',
                caption: 'Nama',
            },
            {
                dataField: 'product',
                caption: 'Produk',
            },
            {
                dataField: 'dataIuranBo',
                caption: 'Data Iuran BO',
                alignment:'center',
                columns: [
                    {
                        dataField: 'iuranPesertaNominal',
                        caption: 'Peserta',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue : (e) => {
                            var data = e.byIuranPeserta.find(val => val.statusIuranPeserta)
                            return data ? data.nominal : 0
                        }
                    },
                    {
                        dataField: 'iuranPkNominal',
                        caption: 'PemberiKerja',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta.find(val => !val.statusIuranPeserta)
                            return data ? data.nominal : 0
                        }
                    },
                    {
                        dataField: 'total',
                        caption: 'Total',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta
                            return data.map(value => value.nominal).reduce((a,b) => a + b)
                        }
                    },
                ]
            },
            {
                dataField: 'dataIuranPk',
                caption: 'Data Iuran PK',
                alignment:'center',
                columns: [
                    {
                        dataField: 'iuranPesertaExcel',
                        caption: 'Peserta',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue : (e) => {
                            var data = e.byIuranPeserta.find(val => val.statusIuranPeserta)
                            return data ? data.excel : 0
                        }
                    },
                    {
                        dataField: 'iuranPkExcel',
                        caption: 'PemberiKerja',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta.find(val => !val.statusIuranPeserta)
                            return data ? data.excel : 0
                        }
                    },
                    {
                        dataField: 'total',
                        caption: 'Total',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta
                            return data.map(value => value.excel).reduce((a,b) => a + b)
                        }
                    },
                ]
            },
            {
                dataField: 'selisih',
                caption: 'Selisih',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: statusIuran(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalDetail = (data) => {
        var master = {
            pesertaId: data.pesertaId,
            updatedNote: 'Pengkinian Data PHDP atau Akun Iuran'
        }
        this.modalInsertPengkinianAktifRef.current.show()
        this.modalInsertPengkinianAktifRef.current.retrieveData(master)
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    
    insertData = async(data) => {
        var dataPengkinian = data
        dataPengkinian.pengkinianTypeId = 4
        dataPengkinian.pesertaStatusId = 2
        dataPengkinian.statusCode = 2
        dataPengkinian.online = false
        dataPengkinian.updatedStatus = false

        try {
            var loadAPI = 'pengkinian-peserta-bo-aktifs'
    
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: dataPengkinian
            })
            var text = `Data berhasil ditambah!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }

    prosesData = async(data) => {
        try {
            var loadAPIuranDetail = `iuran-details/getByIuranId/${data.iuranId}`
            var getIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')

            if(data.akunProdukId){
                var loadAPI = `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${data.akunProdukId}`
                var dataProductIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            }else{
                var dataProductIuran = []
            }

            var dataIuranDetail = getIuranDetail.filter(val => val.pesertaAkunProductId == data.akunProdukId)
            for(var value of dataIuranDetail){
                var dataIuran = dataProductIuran.find(val => val.pesertaAkunProductId == value.akunProdukId && val.iuranTypeId == value.iuranTypeId)
                if(dataIuran){
                    value.status = 4
                    value.iuranNominal = dataIuran.iuranNominal
                    var loadAPIUpdate = `iuran-details`
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIUpdate, 'PUT', {
                        values:value,
                        key: value.id
                    },value)
                }else{
                    var loadAPIDelete = `iuran-details`
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIDelete, 'DELETE',value.id)
                }
            }
            var text = 'Data Berhasil Diproses!'
            var type = "success"

            this.forceRefresh()
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);  
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
            
        }
        this.forceRefresh()
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Iuran Ditunda</h2>
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='iuran-details/getIuranTunda/3'
                    insertAPI='iurans'
                    updateAPI='iurans'
                    deleteAPI='iurans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Iuran Ditunda"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Iuran Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    keyField = {'iuranId'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalInsertPengkinianAktif
                    store={this.props.store}
                    submitData={this.insertData}
                    ref={this.modalInsertPengkinianAktifRef}
                />
            </div>
        )
    }
}

export default DaftarIuranDitunda