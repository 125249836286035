import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { currency, banks, productDefault, fundManager, instrumentSubCategoryById, rekeningBank, kpds, depositoNeddConfirmByBank, depositoWasSent, instrumentSubCategoryDeposito } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';


class ModalActionPenempatanSettlementTransaksiDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if(formValidation){
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        name: 'properti',
                        items: [
                            {
                                dataField: 'pengelola',
                                label: {
                                    alignment: 'left',
                                    text: 'Pengelolaan'
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    items: [
                                        'Internal',
                                        'KPD'
                                    ],
                                    value: 'Internal',
                                    layout: "horizontal",
                                    onValueChanged: (e) => {
                                        var val = e.value
                                        if(val != null){
                                            if(val == 'Internal'){
                                                this.formRef.current.instance.getEditor('tanggalPenempatan').option('readOnly', true)
                                                this.formRef.current.instance.getEditor('categorySubId').option('readOnly', true)
                                                this.formRef.current.instance.getEditor('nominal').option('readOnly', true)
                                                this.formRef.current.instance.getEditor('rateOrder').option('readOnly', true)
                                                this.formRef.current.instance.getEditor('tenor').option('readOnly', true)
                                                this.formRef.current.instance.getEditor('tenorTypeId').option('readOnly', true)
                                                this.formRef.current.instance.getEditor('kpdId').option('readOnly', true)
                                                this.formRef.current.instance.getEditor('kpdId').option('value', 1)
                                                this.formRef.current.instance.getEditor('orderId').option('readOnly', false)
                                            }else if(val == 'KPD'){
                                                this.formRef.current.instance.getEditor('tanggalPenempatan').option('readOnly', false)
                                                this.formRef.current.instance.getEditor('categorySubId').option('readOnly', false)
                                                this.formRef.current.instance.getEditor('nominal').option('readOnly', false)
                                                this.formRef.current.instance.getEditor('rateOrder').option('readOnly', false)
                                                this.formRef.current.instance.getEditor('tenor').option('readOnly', false)
                                                this.formRef.current.instance.getEditor('tenorTypeId').option('readOnly', false)
                                                this.formRef.current.instance.getEditor('kpdId').option('readOnly', false)
                                                this.formRef.current.instance.getEditor('kpdId').option('value', null)
                                                this.formRef.current.instance.getEditor('orderId').option('readOnly', true)

                                            }
                                        }
                                    }
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please choose"
                                    }
                                ]
                            },
                            {
                                dataField: 'orderId',
                                label: {
                                    alignment: 'left',
                                    text: 'No Order'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: depositoWasSent(this.props.store),
                                    valueExpr: 'orderId', // contains the same values as the "statusId" field provides
                                    displayExpr: 'orderCode',
                                    searchEnabled: true,
                                    onSelectionChanged: async(e) => {
                                        var data = e.selectedItem
                                        if (data) {
                                             
                                            var dataRekening = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-depositos/${data.orderDepositoId}`, 'GET')
                                            this.formRef.current.instance.getEditor('productCode').option('value', data.productCode)
                                            this.formRef.current.instance.getEditor('tanggalPenempatan').option('value', dataRekening.placementDate)
                                            this.formRef.current.instance.getEditor('categorySubId').option('value', data.categorySubId)
                                            this.formRef.current.instance.getEditor('nominal').option('value', data.amountOrder)
                                            this.formRef.current.instance.getEditor('rateOrder').option('value', data.rateOrder)
                                            this.formRef.current.instance.getEditor('tenor').option('value', data.tenor)
                                            this.formRef.current.instance.getEditor('tenorTypeId').option('value', data.tenorTypeId)
                                            this.formRef.current.instance.getEditor('maturityDate').option('value', dataRekening.maturityDate)
                                            this.formRef.current.instance.getEditor('fundManagerName').option('value', data.fundManagerName)
                                            this.formRef.current.instance.getEditor('paymentRekbankId').option('value', dataRekening.sourceRekBankId)
                                            this.formRef.current.instance.getEditor('receiveRekbankId').option('value', dataRekening.rekBankId)
                                            this.formRef.current.instance.getEditor('currencyId').option('value', dataRekening.currencyId)
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'kpdId',
                                label: {
                                    alignment: 'left',
                                    text: 'Tipe'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kpds(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'noKontrak',
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Penempatan',
                        name: 'deposito',
                        items: [
                            {
                                dataField: 'bilyetNo',
                                label: {
                                    text: 'No. Bilyet'
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please fill Bilyet number"
                                    }
                                ]
                            },
                            {
                                dataField: 'productCode',
                                label: {
                                    alignment: 'left',
                                    text: 'Produk'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'tanggalPenempatan',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Penempatan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    width: '100%',
                                    displayFormat: 'd MMM yyyy',
                                    openOnFieldClick: true,
                                    useMaskBehaviour: true,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'bankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Bank Deposito'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'bankName',
                                    readOnly: true,
                                    onValueChanged: (e) => {
                                        var selectOrderId = this.formRef.current.instance.getEditor('orderId')
                                        selectOrderId.option('dataSource', depositoNeddConfirmByBank(this.props.store, e.value))
                                    }
                                },
                            },
                            {
                                dataField: 'categorySubId',
                                label: {
                                    alignment: 'left',
                                    text: 'Jenis Deposito'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: instrumentSubCategoryDeposito(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'subName',
                                    openOnFieldClick: true,
                                    searchEnabled: true,
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'nominal',
                                label: {
                                    alignment: 'left',
                                    text: 'Nominal'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    alignment: 'left',
                                    text: 'Mata Uang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyName',
                                    openOnFieldClick: true
                                },
                                validationRules:[
                                    {
                                        type: 'required',
                                        message: 'Please choose currency'
                                    }
                                ]
                            },
                            {
                                dataField: 'rateOrder',
                                label: {
                                    alignment: 'left',
                                    text: 'Rate (%)'
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please fill rate order"
                                    }
                                ]
                            },
                            {
                                itemType: 'group',
                                colCount: 2,
                                items: [
                                    {
                                        dataField: 'tenor',
                                        label: {
                                            alignment: 'left',
                                            text: 'Tenor'
                                        },
                                        editorOptions: {
                                            readOnly: true
                                        }
                                    },
                                    {
                                        dataField: 'tenorTypeId',
                                        label: { visible: false },
                                        editorType: 'dxRadioGroup',
                                        editorOptions: {
                                            items: [
                                                'HARI',
                                                'BULAN'
                                            ],
                                            value: 'HARI',
                                            layout: "horizontal",
                                            readOnly: true
                                        },
                                    },
                                ]
                            },
                            {
                                dataField: 'maturityDate',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Jatuh Tempo'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    width: '100%',
                                    displayFormat: 'd MMM yyyy',
                                    openOnFieldClick: true,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'fundManagerName',
                                label: {
                                    alignment: 'left',
                                    text: 'Fund Manager'
                                },
                                editorOptions: {
                                    readOnly: true
                                },
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Bank',
                        items: [
                            {
                                dataField: 'paymentRekbankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Transfer'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (item) => {
                                        return item && item.rekNo + ' - ' + item.rekName
                                    },
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please choose payment bank account"
                                    }
                                ]
                            },
                            {
                                dataField: 'receiveRekbankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Pencairan / Bunga'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (item) => {
                                        return item && item.rekNo + ' - ' + item.rekName
                                    },
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please choose receive bank account"
                                    }
                                ]
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    text: 'Biaya Bank'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Please fill fee bank"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Catatan',
                        items: [
                            {
                                dataField: 'catatan',
                                label: {
                                    visible: false
                                },
                                editorType: 'dxTextArea',
                                editorOptions: {
                                    height: '100px'
                                }
                            },
                        ]
                    }
                ]
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                bilyetNo: data.bilyetNo,
                pengelola: data.pengelola,
                orderId: data.orderId,
                bankId: data.bankId,
                categorySubId: data.categorySubId,
                nominal: data.nominal,
                tanggalPenempatan: data.tanggalPenempatan,
                trsDate: data.tanggalPenempatan,
                tenor: data.tenor,
                tenorTypeId: data.tenorTypeId,
                rateOrder: data.rateOrder,
                kpdId: data.kpdId,
                paymentRekbankId: data.paymentRekbankId,
                receiveRekbankId: data.receiveRekbankId,
                currencyId: data.currencyId,
                catatan: data.catatan,
                biayaBank: data.biayaBank,
                productCode: data.productCode,
                maturityDate: data.maturityDate,
                fundManagerName: data.fundManagerName,
                kpdId: data.kpdId
            }
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    submitData = () => {
        var data = this.state.dataMaster
        this.props.submitDetailPenempatanData({
            id: data.id,
            bilyetNo: data.bilyetNo,
            pengelola: data.pengelola,
            orderId: data.orderId,
            bankId: data.bankId,
            categorySubId: data.categorySubId,
            nominal: data.nominal,
            tanggalPenempatan: data.tanggalPenempatan,
            trsDate: data.tanggalPenempatan,
            tenor: data.tenor,
            tenorTypeId: data.tenorTypeId,
            rateOrder: data.rateOrder,
            kpdId: data.kpdId,
            paymentRekbankId: data.paymentRekbankId,
            receiveRekbankId: data.receiveRekbankId,
            currencyId: data.currencyId,
            catatan: data.catatan,
            biayaBank: data.biayaBank,
            productCode: data.productCode,
            maturityDate: data.maturityDate,
            fundManagerName: data.fundManagerName,
            kpdId: data.kpdId
        })

        this.hide()
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Penempatan Deposito'}
                minWidth={'60vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                ref={this.formRef}
                                formData={this.state.dataMaster}
                                items={this.items}
                                scrollingEnabled={true}
                                height={'calc(70vh - 60px)'}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalActionPenempatanSettlementTransaksiDeposito
