import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import { addURL } from 'redux/actions/url';
import { broker, kpds, productDefault, rekeningBroker } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import { Button } from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import ModalSettlementTransaksiEtf from 'components/pages/modal/investasi/settlement/konfirmasi/etf/index';
import ModalSettlementTransaksiIpo from 'components/pages/modal/investasi/settlement/konfirmasi/ipo/index';

class SettlementTransaksiIpo extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        
        this.state = {
            dataMaster: {},
            filterItem: {
                startDate:new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        }

        this.modalAlokasiRef = React.createRef()
        this.modalSettlementTransaksiIpoRef = React.createRef()
        this.modalFileSettlementTransaksiIpoRef = React.createRef()
        this.modalUploadKpdRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.showModalViewJurnalRef = React.createRef()

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            startDate: formatDate(e.value)
                        })
                    }
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            endDate: formatDate(e.value)
                        })
                    }
                }
            },
        ]

        this.columns = [
            // {
            //     type: 'buttons',
            //     buttons: [
            //         'edit',
            //         {
            //             text: 'Detail',
            //             hint: 'Detail',
            //             cssClass: 'text-success',
            //             onClick: (e) => {
            //                 this.showModalSettlementTransaksiIpo('edit', e.row.data)
            //             }
            //         }
            //     ],
            // },
            {
                dataField: 'id',
                caption: 'ID',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'Nomor Konfirmasi',
            },
            {
                dataField: 'brokername',
                caption: 'Broker',
                // lookup: {
                //     dataSource: broker(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: (e) => {
                //         return e.brokerCode + ' - ' + e.brokerName
                //     }
                // }
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                }
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelola',
                // lookup: {
                //     dataSource: kpds(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'noKontrak',
                // }
            },
            {
                dataField: 'tglTransaksi',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tglSettlement',
                caption: 'Tanggal Settle',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'jmlEmiten',
                caption: 'Jumlah Emiten',
                alignment: 'right'
            },
            {
                dataField: 'netAmount',
                caption: 'Nominal Penyertaan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },

        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalViewJurnal = (e) => {
        if (e.row) {
            this.showModalViewJurnalRef.current.retrieveData(e.row.data)
            this.showModalViewJurnalRef.current.newData(true)
        }else{
            this.showModalViewJurnalRef.current.newData(false)
        }

        this.showModalViewJurnalRef.current.show()
    }
    onSelectionChanged = (e) => {
        var orderId = e.selectedRowKeys
        this.setState({
            orderId: orderId
        })
    }
    generateInstruksi = async () => {
        var id = {
            listConfirmationId: this.state.orderId
        }
        return false
        if (this.state.orderId != undefined) {
            try {
                var insertAPI = 'order-etfs/buat-instruksi-custody'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: id
                })
                notify({ message: 'Generate Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } else {
            notify({ message: 'Silahkan pilih data untuk di generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Konfirmasi',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalSettlementTransaksiIpo('add')
                    },
                }
            },
        )
    }

    showModalSettlementTransaksiIpo = () => {
        this.modalSettlementTransaksiIpoRef.current.show()
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    showModalUploadKPD = () => {
        this.modalUploadKpdRef.current.show()
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfirmasi IPO</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    keyField={'id'}
                    ref={this.dataGridRef}
                    loadAPI={`transaction-confirmations/ipo/list?endDate=${formatDate(this.state.filterItem.endDate)}&startDate=${formatDate(this.state.filterItem.startDate)}&status=2&size=9999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    // useArraySource={true}
                    // ArraySourceData={[]}

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Konfirmasi IPO"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Konfirmasi IPO'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup
                    height={'calc(100vh - 311px)'}

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onSelectionChanged={this.onSelectionChanged}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Generate Instruksi"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.generateInstruksi.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
                <ModalSettlementTransaksiIpo
                    ref={this.modalSettlementTransaksiIpoRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                {/* <ModalFileSettlementTransaksiETF
                    ref={this.modalFileSettlementTransaksiIpoRef}
                    store={this.props.store}
                /> */}
            </div>
        )
    }
}

export default SettlementTransaksiIpo