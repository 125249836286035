import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import ModalSettlementTransaksiReksadana from 'components/pages/modal/investasi/settlement/konfirmasi/reksadana/penempatan/index';
import ModalFileSettlementTransaksiReksadana from 'components/pages/modal/investasi/settlement/konfirmasi/reksadana/file';
import { addURL } from 'redux/actions/url';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ViewJurnalDetails from 'components/pages/modal/investasi/viewJurnalTrans';
import ModalSettlementPelepasanReksadana from 'components/pages/modal/investasi/settlement/konfirmasi/reksadana/pelepasan/index';
import ModalSettlementPengalihanReksadana from 'components/pages/modal/investasi/settlement/konfirmasi/reksadana/pengalihan/index';
import ModalDetailTransaksiReksadana from 'components/pages/modal/investasi/settlement/konfirmasi/reksadana/detailTransaksi';

class SettlementTransaksiReksadana extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            dataMaster: {},
            filterItem: {
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        }

        this.modalPenempatan = React.createRef()
        this.modalPencairan = React.createRef()
        this.modalPengalihan = React.createRef()
        this.modalFileSettlementTransaksiReksadanaRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.modalDetailTransaksiRef = React.createRef()

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            startDate: formatDate(e.value)
                        })
                    }
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            endDate: formatDate(e.value)
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text : 'Lihat Jurnal',
                    //     hint : 'Lihat Jurnal',
                    //     cssClass: 'text-success',
                    //     onClick : (e) => {
                    //         this.showModalViewJurnal(e)
                    //     }
                    // },
                    {
                        text: 'View',
                        hint: 'View',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.modalDetailTransaksiRef.current.show(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'konfirmasiId',
                caption: 'ID',
                alignment: 'right'
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'Nomor Konfirmasi',
            },
            // {
            //     dataField: 'jenisReksadana',
            //     caption: 'Jenis Reksadana',
            // },
            // {
            //     dataField: 'produkReksadana',
            //     caption: 'Produk Reksadana',
            // },
            {
                dataField: 'miName',
                caption: 'Manager Investasi',
            },
            {
                dataField: 'tipeTransaksi',
                caption: 'Tipe Transaksi',
            },
            {
                dataField: 'trsDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Settle',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'netAmount',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rekMiNo',
                caption: 'No. Rekening MI',
                alignment: 'right'
            },
            {
                dataField: 'rekMiName',
                caption: 'Nama Rekening MI',
            },

        ]
        this.dropDownbutton = [
            {
                id: 1,
                text: 'Penempatan',
                onClick: () => {
                    this.showModalPenempatan()
                }
            },
            {
                id: 2,
                text: 'Pencairan',
                onClick: () => {
                    this.showModalPencairan()
                }
            },
            // {
            //     id: 3,
            //     text: 'Pengalihan',
            //     onClick: () => {
            //         this.showModalPengalihan()
            //     }
            // },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalViewJurnal = (e) => {
        if (e.row) {
            this.showModalViewJurnalRef.current.retrieveData(e.row.data)
            this.showModalViewJurnalRef.current.newData(true)
        } else {
            this.showModalViewJurnalRef.current.newData(false)
        }

        this.showModalViewJurnalRef.current.show()
    }

    loadDummyData = () => {
        var dummy = [
            {
                id: 1,
            },
        ]
        return dummy
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxDropDownButton',
                options: {
                    text: 'Konfirmasi',
                    icon: 'plus',
                    items: this.dropDownbutton,
                    keyExpr: 'id',
                    displayExpr: 'text',
                    splitButton: true,
                    onItemClick: 'onClick'
                }
            }
        )
    }

    showModalPenempatan = (data = null) => {
        this.modalPenempatan.current.show()
        this.modalPenempatan.current.retrieveData(data)
    }
    showModalPencairan = (data = null) => {
        this.modalPencairan.current.show()
        this.modalPencairan.current.retrieveData(data)
    }
    showModalPengalihan = (data = null) => {
        this.modalPengalihan.current.show()
        this.modalPengalihan.current.retrieveData(data)
    }
    showModalFileSettlementTransaksiReksadana = (type, data = null) => {
        this.modalFileSettlementTransaksiReksadanaRef.current.show()
        this.modalFileSettlementTransaksiReksadanaRef.current.retrieveData(type, data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfirmasi Reksadana</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    keyField='konfirmasiId'
                    ref={this.dataGridRef}
                    loadAPI={`transaction-confirmations/rd/list?endDate=${formatDate(this.state.filterItem.endDate)}&startDate=${formatDate(this.state.filterItem.startDate)}&status=0&status=1&status=2&size=9999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Transaksi Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Transaksi Reksadana'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}
                    
                    height={'calc(100vh - 251px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ViewJurnalDetails
                    ref={this.showModalViewJurnalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementTransaksiReksadana
                    ref={this.modalPenempatan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementPelepasanReksadana
                    ref={this.modalPencairan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementPengalihanReksadana
                    ref={this.modalPengalihan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalFileSettlementTransaksiReksadana
                    ref={this.modalFileSettlementTransaksiReksadanaRef}
                    store={this.props.store}
                />
                <ModalDetailTransaksiReksadana
                    ref={this.modalDetailTransaksiRef}
                    store={this.props.store}
                />

            </div>
        )
    }
}

export default SettlementTransaksiReksadana