import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react';
import { karyawan, oustandingPemesanan, programSdm, sdmSupplier, statusAktifNonAktif} from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ScrollView from 'devextreme-react/scroll-view';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import DropDownBox from 'devextreme-react/drop-down-box';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import { download, formatDate, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { showLoading } from "redux/actions/loading";
class ModalUmumPembelianPenerimaan extends Component {
    constructor(props){
        super(props)
        
        this.state = {
            dataMaster: {},
            popupVisible:false,
            isOpened : false,
            DataDocument:{}
        }

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.dropDownBoxRef = React.createRef()
        this.dropDownBoxValidationRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        
        this.selectedNoPemesanan = {}
        this.selectedDataPembelian = {}
        this.selectedDataSuplier = {}

        this.formPenerimaan = [
            { 
                itemType: 'group',
                items: [
                    {
                        dataField:"noPenerimaan",
                        label:{
                            text:"No Penerimaan",
                        },
                        editorType:'dxTextBox',
                    },
                    {
                        dataField:"tanggalDiterima",
                        label:{
                            text:"Tanggal Diterima"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat : 'dd MMM yyyy',
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                            },
                        ]
                    },
                    {
                        dataField:"jumlahSisa",
                        label:{
                            text:"Sisa yang Dapat Dibeli",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'jumlahDiterima',
                        caption: 'Jumlah Diterima',
                        type:'number',
                        format: '#,##0.00',
                        validationRules: [
                            {
                                type: 'required',
                            },
                            {
                                type: 'range',
                                min: 1,
                                message: 'must be more than 0'
                            },
                            {
                                type: 'custom',
                                validationCallback: (e) => {
                                    if(this.state.dataMaster.id){
                                        return true
                                    }
                                    return e.value <= this.state.dataMaster.jumlahSisa
                                },
                                message: 'Exceed the remaining quantity'
                            }
                        ],
                    },
                    
                    {
                        dataField: 'receiveBy',
                        caption: 'Diterima Oleh',
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: karyawan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: (item) => {
                                return item && item.nik + ' - ' + item.fullName
                            }, // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    }, 
                    // {
                    //     dataField: 'file_serah_terima',
                    //     label:{
                    //         text:"File Serah Terima",
                    //     },
                    //     editorType: 'dxFileUploader',
                    //     editorOptions:{
                    //         uploadMode:'useForm',
                    //         labelText:'File Serah Terima',
                    //         name:'invoice',
                    //         onValueChanged: this.onUploadStarted.bind(this),
                    //     },
                    //     validationRules: [
                    //         {
                    //             type: 'required',
                    //         },
                    //     ]
                    // },
                ]
            },
            {
                itemType: 'group',
                caption: 'Informasi Pemesanan',
                name: 'pembelian',
                items: [
                    {
                        name: 'noPembelian',
                        dataField:"pembelian.noPembelian",
                        label:{
                            text:"No Pemesanan",
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        name: 'programId',
                        dataField:"pembelian.programId",
                        label:{
                            text:"Program",
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: programSdm(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        name: 'type',
                        dataField:"pembelian.type",
                        label:{
                            text:"Type"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'B',
                                    value: 'Pembelian'
                                },
                                {
                                    id: 'C',
                                    value: 'Batal Pembelian'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value',
                            onValueChanged: (e) => {
                                this.formRef.current.instance.beginUpdate()
                                this.formRef.current.instance.itemOption('pembelian.tanggalPembelian','visible',e.value === 'B')
                                this.formRef.current.instance.itemOption('pembelian.supplierId','visible',e.value === 'B')
                                this.formRef.current.instance.itemOption('pembelian.harga','visible',e.value === 'B')
                                this.formRef.current.instance.itemOption('pembelian.amount','visible',e.value === 'B')
                                this.formRef.current.instance.itemOption('pembelian.ppn','visible',e.value === 'B')
                                this.formRef.current.instance.itemOption('pembelian.biayaTransaksi','visible',e.value === 'B')
                                this.formRef.current.instance.itemOption('pembelian.total','visible',e.value === 'B')
                                this.formRef.current.instance.itemOption('informasiSupplier','visible',e.value === 'B')
                                this.formRef.current.instance.endUpdate()
                            },
                            readOnly: true
                        },
                    },
                    {
                        name: 'tanggalPembelian',
                        dataField: 'pembelian.tanggalPembelian',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pemesanan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat : 'dd MMM yyyy',
                            openOnFieldClick: true,
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        name: 'supplierId',
                        dataField: 'pembelian.supplierId',
                        label: {
                            alignment: 'left',
                            text: 'Supplier'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: sdmSupplier(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: (item) => {
                                return item && item.kodeSupplier + ' - ' + item.namaSupplier
                            }, // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            // onSelectionChanged : (e) => {
                            //     if (e.selectedItem) {
                            //         this.formRef.current.instance.updateData({
                            //             supplier: e.selectedItem
                            //         })
                            //     }
                            // },
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        name: 'harga',
                        dataField:"pembelian.harga",
                        label:{
                            text:"Harga per Unit",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        name: 'jumlah',
                        dataField:"pembelian.jumlah",
                        label:{
                            text:"Jumlah",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        },
                    },
                    {
                        name: 'amount',
                        dataField:"pembelian.amount",
                        label:{
                            text:"Total",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        name: 'ppn',
                        dataField:"pembelian.ppn",
                        label:{
                            text:"PPn",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        name: 'biayaTransaksi',
                        dataField:"pembelian.biayaTransaksi",
                        label:{
                            text:"Biaya Transaksi",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        name: 'total',
                        dataField:"pembelian.total",
                        label:{
                            text:"Total Bayar",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        },
                        visible: false
                    },
                ]
            },
            {
                name: 'informasiSupplier',
                itemType: 'group',
                caption: 'Informasi Supplier',
                items: [
                    {
                        dataField: 'supplier.kodeSupplier',
                        label: {
                            alignment: 'left',
                            text: 'Kode Supplier'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.namaSupplier',
                        label: {
                            alignment: 'left',
                            text: 'Nama Supplier'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.alamat',
                        label: {
                            alignment: 'left',
                            text: 'Alamat'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.contactPerson',
                        label: {
                            alignment: 'left',
                            text: 'Contact Person'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'supplier.email',
                        label: {
                            alignment: 'left',
                            text: 'Email'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField:"supplier.noTelp",
                        label:{
                            text:"No Telp",
                        },
                        editorType:'dxNumberBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        dataField:"supplier.maxPembelianTahun",
                        label:{
                            text:"Max Pembelian / Tahun",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField:"supplier.totalPembelian",
                        label:{
                            text:"Total Pembelian Tahun Berjalan",
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField:"supplier.statusSupplier",
                        label:{
                            text:"Status Supplier",
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            placeholder: '',
                            dataSource: statusAktifNonAktif(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                ],
                visible: false
            }
        ]
    }

    retrieveData = async(data) => {
        var getAPI = 'asset-penerimaans/getByAssetPembelianId/'+ data.id
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, getAPI, 'GET')

        
        var loadAPIAssetPembelian = 'asset-pembelians/'+ data.assetPembelianId
        var getAssetPembelian = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIAssetPembelian, 'GET')

        var getAPISupplier = {}
        if(getAssetPembelian.supplierId){
            var loadAPISupplier = 'suppliers/'+ getAssetPembelian.supplierId
            getAPISupplier = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPISupplier, 'GET')
        }
       
        this.setState({
            dataMaster: {
                ...getData, ...data,
                supplier:{
                    ...getAPISupplier
                },
                pembelian:{
                    ...getAssetPembelian
                }
            },
        })


    }

    show = (type ) => {
        this.setState({
            popupVisible: true,
        })

        if(type === 'add'){
            this.PopupRef.current.instance.option('toolbarItems',[
                {
                  widget: "dxButton",
                  location: "after",
                  options: {
                    text: "Simpan",
                    onClick: () => {
                        this.submitData()
                    }
                  },
                  toolbar: "bottom"
                },
                {
                  widget: "dxButton",
                  location: "after",
                  options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                  },
                  toolbar: "bottom"
                },
            ])

            this.formRef.current.instance.option('readOnly',false)
            this.dropDownBoxRef.current.instance.option('readOnly',false)
        }else{
            this.PopupRef.current.instance.option('toolbarItems',[
                {
                  widget: "dxButton",
                  location: "after",
                  options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                  },
                  toolbar: "bottom"
                },
            ])

            this.formRef.current.instance.option('readOnly',true)
            this.dropDownBoxRef.current.instance.option('readOnly',true)
        }
    }
    
    hide = () => {
        this.selectedNoPemesanan = {}
        this.selectedDataPembelian = {}
        this.selectedDataSuplier = {}
        this.setState({
            dataMaster: {},
            popupVisible: false
        })
    }

    selectNoPemesanan = async (e) => {
        if(e.selectedRowKeys.length > 0){
            const selectedData = e.selectedRowsData.find(value => value.id === e.selectedRowKeys[0])
            const dataPembelian = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,`asset-pembelians/${e.selectedRowKeys[0]}`)
            const dataSuplier = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,`suppliers/${dataPembelian.supplierId}`)
            this.selectedNoPemesanan = selectedData
            this.selectedDataPembelian = dataPembelian
            this.selectedDataSuplier = dataSuplier

            this.setState({
                dataMaster: {
                    assetPembelianId: e.selectedRowKeys[0],
                    pembelian: {
                        ...dataPembelian,
                        amount: dataPembelian.harga * dataPembelian.jumlah
                    },
                    supplier:{
                        ...dataSuplier
                    },
                    jumlahSisa: selectedData.jumlah - selectedData.jumlahDiterima
                },
                isOpened: false
            })
        }
    }

    dataGridRender = (e,props) => {
        const value = e.component.option('value');

        const loadData = async () => {
            const dataSource = e.component.getDataSource()
    
            const data = await dataSource.load()

            return data
        }

        const columns = [
            {
                dataField: 'noPembelian',
                caption: 'Nomor Pembelian'
            },
            {
                dataField: 'tanggalPembelian',
                caption: 'Tanggal Pembelian',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'namaAset',
                caption: 'Nama Aset',
            },
            {
                dataField: 'supplierId',
                caption: 'Supplier',
                lookup:{
                    dataSource: sdmSupplier(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: (item) => {
                        return item && item.kodeSupplier + ' - ' + item.namaSupplier
                    }, // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'jumlah',
                caption: 'Jumlah',
                dataType: 'number',
                format : '#,##0'
            },
            {
                dataField: 'jumlahDijumlahDiterimabeli',
                caption: 'Jumlah yang Telah Diterima',
                dataType: 'number',
                format : '#,##0'
            },
        ]

        return (
            <DevExpressDataGrid
                loadAPI='view-outstanding-penerimaans?size=9999'
                insertAPI='asset-transactions'
                updateAPI='asset-transactions'
                deleteAPI='asset-transactions'

                backendserver={process.env.REACT_APP_BACKEND_HRD}

                useArraySource={true}
                ArraySourceData={loadData}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Daftar Pemesanan Pembelian"}
                allowExportSelectedData={false}
                selection={"single"}

                showBorders={true}

                paging={true}
                defaultPageSize={10}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Daftar Pemesanan Pembelian'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={columns} // taruh konfigurasi kolom disini
                onSelectionChanged={this.selectNoPemesanan}
                // SummaryConfiguration={this.summary}
                height = "300px"

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={props.store} // jangan di edit edit
            />
        );
    }

    onDropdownOptionChanged= (e) => {
        if (e.name === 'opened') {
            this.setState({
                isOpened: e.value,
            });
        }
    }

    submitData = async() => {
        const validateResult = this.formRef.current.instance.validate()
        const validateNoPembelian = this.dropDownBoxValidationRef.current.instance.validate()
        const DataDocument = {...this.state.DataDocument}

        if(!validateResult.isValid || !validateNoPembelian.isValid){
            return null
        }

        var data = {...this.state.dataMaster}

        delete data.pembelian
        delete data.supplier

        try {
            this.props.store.dispatch(showLoading(true))

            const dataToJurnal = {
                "assetPembelianId": data.assetPembelianId,
                "assetPengadaanId" : this.selectedDataPembelian.assetPengadaanId,
                "filePengantar": DataDocument.filePengantar,
                "filePengantarContentType": DataDocument.filePengantarContentType,
                "fileSerahTerima": DataDocument.fileSerahTerima,
                "fileSerahTerimaContentType": DataDocument.fileSerahTerimaContentType,
                "jumlahDiterima": parseInt(data.jumlahDiterima),
                "noPenerimaan": data.noPenerimaan,
                "receiveBy": data.receiveBy,
                "tanggalDiterima": formatDate(data.tanggalDiterima)
              }

            const dataToMaster = {
                "assetPengadaanId" : this.selectedDataPembelian.assetPengadaanId,
                penerimaanPesananDTOList:[
                    dataToJurnal
                ]
            }

            try {
                var responseCreate = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'asset-penerimaans/create', 'POST', {
                    values: dataToJurnal
                })
            } catch (e) {
                let type = 'error'
                let text = e

                this.props.store.dispatch(showLoading(false))
                await alert(e , 'Error')
            }
            
            try {
                var responseSimpan = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'asset-penerimaans/simpan', 'POST', {
                    values: dataToMaster
                })
            } catch (e) {
                let type = 'error'
                let text = e

                this.props.store.dispatch(showLoading(false))
                await alert(e , 'Error')
            }

            if (responseCreate && responseSimpan) {
                this.props.store.dispatch(showLoading(false))

                await alert('Data berhasil disimpan !' , 'Information')

                this.hide()
                this.props.forceRefresh()
            }
        } catch (e) {
            let type = 'error'
            let text = e

            this.props.store.dispatch(showLoading(false))
            await alert(e , 'Error')
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataDocument = { ...this.state.DataDocument };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataDocument[fieldName + `Name`] = files.name;
            DataDocument[fieldName] = formattedFileData.base64data;
            DataDocument[fieldName + `ContentType`] = formattedFileData.mime;

            this.setState({
                DataDocument: DataDocument
            });
        };

        fr.readAsDataURL(files);
        }
    }

    
    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            var src = imageSource(this.state.DataDocument[`${fileName}ContentType`], this.state.DataDocument[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataDocument[`${fileName}ContentType`], this.state.DataDocument[`${fileName}`]), this.state.DataDocument[`${fileName}Name`])
    }

    displayExpr = (item) => {
        return item && item.noPembelian + ' - ' + item.namaAset;
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={'Detail Pembelian Penerimaan'} 
                minWidth={700}
                minHeight={500}
                // toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView height="100%" width="100%">
            <div className="container-fluid">
                <div className='row mb-2'>
                    <div className='col-12 d-flex flex-row align-items-center'>
                        <div style={{width: '138px'}}>
                            <span style={{color: 'rgba(117,117,117,.87)'}}>No Pemesanan/Nama Asset *</span>
                        </div>
                        <DropDownBox
                            ref={this.dropDownBoxRef}
                            value={this.state.dataMaster.assetPembelianId}
                            opened={this.state.isOpened}
                            valueExpr="id"
                            displayExpr={this.displayExpr}
                            showClearButton={true}
                            dataSource={oustandingPemesanan(this.props.store)}
                            onOptionChanged={this.onDropdownOptionChanged}
                            contentRender={(e) => this.dataGridRender(e,this.props)}
                            placeholder="Pilih No Pemesanan"
                            width = "calc(100% - 128px)"
                        >
                        <Validator
                            ref={this.dropDownBoxValidationRef}>
                            <RequiredRule />
                        </Validator>
                        </DropDownBox>
                    </div>
                </div>
                <Form
                    ref={this.formRef}
                    colCount={3}
                    id={'formPenerimaan'}
                    formData={this.state.dataMaster}
                    items={this.formPenerimaan}
                    labelLocation = 'left'
                />
                <div className="row">
                    <div className="col-sm-4">
                        <label style={{fontSize:"13px"}}>File Serah Terima</label>
                        <FileUploader
                            accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText={this.state.DataDocument.fileSerahTerimaName || 'File Serah Terima'}
                            showFileList={false}
                            name={"fileSerahTerima"}
                            // disabled={this.props.from == 'pengajuan' ? false : true}
                        />
                    </div>
                    <div className="col-sm-4 pt-3">
                        <button onClick={() => this.previewFile('fileSerahTerima')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.fileSerahTerima}`} style={{ width: '122px' }}>
                            Preview
                        </button>
                        <button onClick={() => this.downloadFile('fileSerahTerima')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.fileSerahTerima}`} style={{ width: '122px' }}>
                            Download
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <label style={{fontSize:"13px"}}>File Pengantar</label>
                        <FileUploader
                            accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText={this.state.DataDocument.filePengantarName || 'File Pengantar'}
                            showFileList={false}
                            name={"filePengantar"}
                            // disabled={this.props.from == 'pengajuan' ? false : true}
                        />
                    </div>
                    <div className="col-sm-4 pt-3">
                        <button onClick={() => this.previewFile('filePengantar')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.filePengantar}`} style={{ width: '122px' }}>
                            Preview
                        </button>
                        <button onClick={() => this.downloadFile('filePengantar')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.filePengantar}`} style={{ width: '122px' }}>
                            Download
                        </button>
                    </div>
                </div>
                {/* <div className="col-md-6 text-left">
                    
                </div> */}
            </div>
            </ScrollView>
            
            <ModalPreviewPdf
                ref={this.modalPreviewPdfRef}
                store={this.props.store}
            />
            <ModalPreviewImage
                ref={this.modalPreviewImageRef}
                store={this.props.store}
            />
            </Popup>
        )
    }
}

export default ModalUmumPembelianPenerimaan