import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import { anggaranItem, programs, sdmUnit } from 'dataSource/lookup';
import uuidv4 from 'uuid/v4'
import { confirm, alert } from "devextreme/ui/dialog"
import { capitalizeFirstLetter } from 'plugin/helper';
import DevextremeTab from 'components/inheritComponent/devextremeTab';

class ModalEditorBreakdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {
                description: null,
                quantity: null,
                amount: null,
                total: null,
            },
        };

        this.form = [
            {
                dataField: "description",
                label: {
                    text: "Deskripsi",
                },
                isRequired: true
            },
            {
                dataField: "quantity",
                label: {
                    text: "Quantity",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: '#,##0;(#,##0);',
                    onValueChanged: (e) => {
                        this.formRef.current.instance.updateData({
                            total: e.value * (this.state.formData.amount || 0)
                        })
                    }
                },
                validationRules: [
                    {
                        type: 'range',
                        min: 1,
                        message: 'Quantity Harus Lebih dari 0'
                    }
                ]
            },
            {
                dataField: "amount",
                label: {
                    text: "Harga",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: '#,##0.00;(#,##0.00);',
                    onValueChanged: (e) => {
                        this.formRef.current.instance.updateData({
                            total: e.value * (this.state.formData.quantity || 0)
                        })
                    }
                },
                validationRules: [
                    {
                        type: 'range',
                        min: 1,
                        message: 'Harga Harus Lebih dari 0'
                    }
                ]
            },
            {
                dataField: "total",
                label: {
                    text: "Total",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: '#,##0.00;(#,##0.00);',
                    readOnly: true,
                },
                validationRules: [
                    {
                        type: 'range',
                        min: 1,
                        message: 'Total Harus Lebih dari 0'
                    }
                ]
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async () => {
                        this.props.submitData(this.state.formData)
                        this.hide()
                    }
                },
                toolbar: "bottom"
            }
        ]

        this.formRef = React.createRef()
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    };

    retrieveData = async (data) => {
        this.formRef.current.instance.updateData(data)
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    resizeEnabled={true}
                    showTitle={true}
                    title="Editor Anggaran"
                    width={"90vw"}
                    height={"auto"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <div className="mb-3">
                        <Form
                            colCount={1}
                            id={"formAnggaran"}
                            formData={this.state.formData}
                            items={this.form}
                            ref={this.formRef}
                            labelLocation="left"
                        // readOnly = {true}
                        />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalEditorBreakdown