import React, { Component } from 'react'
import { instrument } from '../parts/staticDataColumnConfig'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import notify from "devextreme/ui/notify"
import { instrumentsCategoryProperti } from 'dataSource/lookup'
import FormModalInsertInstruksiProperti from './modalInsertInstruksiProperti'
import httpRequest from 'plugin/httprequest'
import { confirm } from 'devextreme/ui/dialog'

class InstrumentProperti extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModal(e)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: async(e) => {
                            let result = await confirm('Apakah anda yakin ingin menghapus data ini?', 'Konfirmasi')

                            if(result) {
                                this.deleteData(e.row.data);
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Properti'
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Properti'
            },
            {
                dataField: 'lokasi',
                caption: 'Lokasi'
            },
            {
                dataField: 'instrumentCategorySubId',
                caption: 'Kategori Properti',
                lookup: {
                    dataSource: instrumentsCategoryProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                }
            },
            {
                dataField: 'luasBangunan',
                caption: 'Luas Bangunan m²',
                dataType: 'number',
                format: '#,##0',
                alignment: 'right',
                editorOptions: {
                    step: 0,
                    format: '#,##0',
                }
            },
            {
                dataField: 'luasTanah',
                caption: 'Luas Tanah m²',
                dataType: 'number',
                format: '#,##0',
                alignment: 'right',
                editorOptions: {
                    step: 0,
                    format: '#,##0',
                }
            },
        ]

        this.formItems = [
            {
                dataField: 'instrumentCode',
                label: {
                    text: 'Kode Properti'
                }
            },
            {
                dataField: 'instrumentName',
                label: {
                    text: 'Nama Properti'
                }
            },
            {
                dataField: 'lokasi',
                label: {
                    text: 'Lokasi'
                }
            },
            {
                dataField: 'categorySubId',
                label: {
                    text: 'Kategori Properti'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentsCategoryProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'luasBangunan',
                label: {
                    text: 'Luas Bangunan m²'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                    step: 0,
                }
            },
            {
                dataField: 'luasTanah',
                label: {
                    text: 'Luas Tanah m²'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                    step: 0,
                }
            },
        ]

        this.showModalRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true);
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal(e);
                    },
                }
            }
        )
    }

    showModal = (e) => {
        if (e.row) {
            this.showModalRef.current.retrieveData(e.row.data);
            this.showModalRef.current.newData(false);
        }
        this.showModalRef.current.show()
    }

    deleteData = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/instruments`, 'DELETE', data.id);

            this.forceRefresh();
            notify({ message: "Sukses Hapus Data", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 1000);
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instrument Properti</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='instruments/properti'
                    insertAPI='staticdata-wf/instruments'
                    updateAPI='staticdata-wf/instruments'
                    deleteAPI='staticdata-wf/instruments' 

                    remoteOperations={false}

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instrument Properti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instrument Properti Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    formItems={this.formItems}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalInsertInstruksiProperti 
                    ref={this.showModalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default InstrumentProperti