import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instrumentSaham } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

class PortofolioInvestasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sbnData: [],
            obligasiData: [],
            ebaData: [],
            propertiData: [],
        }

        this.product = []

        this.columns = [
            {
                dataField: 'id',
                caption: 'No',
                alignment: 'center',
                cellRender: (e) => {
                    return e.rowIndex + 1
                },
            },
            {
                dataField: 'nameInstrument',
                caption: 'Instrument',
                sortOrder: 'asc',
                // lookup: {
                //     dataSource: instrumentSaham(this.props.store),
                //     valueExpr: "id", // contains the same values as the "statusId" field provides
                //     displayExpr: "instrumentCode", // provides display values
                // },
            }, {
                dataField: 'value',
                caption: 'Value',
                alignment: 'right',
                format: '#,##0'
            }, {
                dataField: 'gainLoss',
                caption: 'Unrealized P/L(%)',
                alignment: 'right',
                cellRender: (e) => {
                    if (e.data.gainLoss < 0) {
                        return <React.Fragment>
                            <span className="text-danger font-weight-bold">{`${Math.abs(e.data.gainLoss).toFixed(2)} %`}</span>
                        </React.Fragment>
                    } else {
                        return <React.Fragment>
                            <span className="text-success font-weight-bold">{`${Math.abs(e.data.gainLoss).toFixed(2)} %`}</span>
                        </React.Fragment>
                    }
                }
            }
        ]

        this.columnsDeposito = [
            {
                dataField: 'id',
                caption: 'No',
                alignment: 'center',
                cellRender: (e) => {
                    return e.rowIndex + 1
                },
            },
            {
                dataField: 'nameInstrument',
                caption: 'Instrument',
                sortOrder: 'asc',
                // lookup: {
                //     dataSource: instrumentSaham(this.props.store),
                //     valueExpr: "id", // contains the same values as the "statusId" field provides
                //     displayExpr: "instrumentCode", // provides display values
                // },
            }, {
                dataField: 'value',
                caption: 'Value',
                alignment: 'right',
                format: '#,##0'
            }, {
                dataField: 'tglJatuhTempo',
                caption: 'Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy'
            }
        ]
    }


    componentDidMount = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/get-list-detail-instrument?size=99999`, 'GET')
        if (response) {
            var dataSbn = response.filter(value => value.categoryName == 'SBN')
            var dataObligasi = response.filter(value => value.categoryName == 'Corporate Bond')
            var dataObligasi = response.filter(value => value.categoryName == 'Corporate Bond')
            var dataEba = response.filter(value => value.categoryName == 'EBA')
            var dataProperti = response.filter(value => value.categoryName == 'Properti')

            this.setState({
                sbnData: dataSbn,
                obligasiData: dataObligasi,
                ebaData: dataEba,
                propertiData: dataProperti,
            })
        }
    }

    loadDataSaham = async () => {
        let datas = []
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/instrument-portofolio-saham?productId=1`, 'GET')
        for (let saham of response) {
            if (saham.nilaiPenutupan > 0) {
                saham.nameInstrument = saham.saham
                saham.value = saham.nilaiPenutupan
                saham.gainLoss = saham.percentUnrealizedPl

                datas.push(saham)
            }
        }

        return datas
    }

    loadDataSbn = async () => {
        let datas = []
        for (let sbn of this.state.sbnData) {
            if (sbn.nilaiPasar > 0) {
                sbn.nameInstrument = sbn.instrumentCode
                sbn.value = sbn.nilaiPasar
                sbn.gainLoss = sbn.percentPl

                datas.push(sbn)
            }
        }

        return datas
    }

    loadDataObligasi = async () => {
        let datas = []

        for (let obligasi of this.state.obligasiData) {
            if (obligasi.nilaiPasar > 0) {
                obligasi.nameInstrument = obligasi.instrumentCode
                obligasi.value = obligasi.nilaiPasar
                obligasi.gainLoss = obligasi.percentPl

                datas.push(obligasi)
            }
        }

        return datas
    }

    loadDataDeposito = async () => {
        let datas = []
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/instrument-portofolio-deposito?productId=1`, 'GET')
        for (let deposito of response) {
            if (deposito.saldoNominal > 0) {
                deposito.nameInstrument = deposito.noBilyet
                deposito.value = deposito.saldoNominal

                datas.push(deposito)

            }
        }

        return datas
    }

    loadDataReksana = async () => {
        let datas = []
        let datasEtf = []
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/instrument-portofolio-reksadana?productId=1`, 'GET')
        var responseEtf = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/instrument-portofolio-etf?productId=1`, 'GET')

        for (let reksadana of response) {
            if (reksadana.lastNilaiInvest > 0) {
                reksadana.nameInstrument = reksadana.namaReksa
                reksadana.value = reksadana.lastNilaiInvest
                reksadana.gainLoss = reksadana.percentUnrealizedPl

                datas.push(reksadana)
            }
        }

        for (let etf of responseEtf) {
            if (etf.lastNilaiInvest > 0) {
                etf.nameInstrument = etf.namaReksa
                etf.value = etf.lastNilaiInvest
                etf.gainLoss = etf.percentUnrealizedPl

                datasEtf.push(etf)
            }
        }

        let combinedData = [...datas, ...datasEtf]

        return combinedData
    }

    loadDataEba = async () => {
        let datas = []
        for (let eba of this.state.ebaData) {
            if (eba.nilaiPasar > 0) {
                eba.nameInstrument = eba.instrumentCode
                eba.value = eba.nilaiPasar
                eba.gainLoss = eba.percentPl

                datas.push(eba)
            }
        }

        return datas
    }

    loadDataProperti = async () => {
        let datas = []
        for (let properti of this.state.propertiData) {
            if (properti.nilaiPasar > 0) {
                properti.nameInstrument = properti.instrumentCode
                // properti.value = properti.nilaiPasar
                properti.value = properti.nilaiPembelian
                properti.gainLoss = properti.percentPl

                datas.push(properti)
            }
        }

        return datas
    }

    render() {
        return (
            <React.Fragment>
                <div className="accordion" id="porto">
                    <div className="card">
                        <div className="card-header" id="headingPortoSaham" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapsePortoSaham" aria-expanded="true" aria-controls="collapsePortoSaham">
                            <span className="fas fa-chevron-down" /> Saham
                        </div>

                        <div id="collapsePortoSaham" className="collapse" aria-labelledby="headingPortoSaham" data-parent="#porto">
                            <div className="card-body">
                                <DevExpressDataGrid
                                    loadAPI='currencies'
                                    insertAPI='currencies'
                                    updateAPI='currencies'
                                    deleteAPI='currencies'

                                    ArraySourceData={this.loadDataSaham}
                                    useArraySource={true}
                                    keyField='saham'

                                    backendserver={process.env.REACT_APP_BACKEND}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    height={'auto'}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Monitoring Tugas Data'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='top' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    selection={'none'}

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingSbn" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapseSbn" aria-expanded="false" aria-controls="collapseSbn">
                            <span className="fas fa-chevron-down" /> SBN
                        </div>
                        <div id="collapseSbn" className="collapse" aria-labelledby="headingSbn" data-parent="#porto">
                            <div className="card-body">
                                <DevExpressDataGrid
                                    loadAPI='currencies'
                                    insertAPI='currencies'
                                    updateAPI='currencies'
                                    deleteAPI='currencies'

                                    ArraySourceData={this.loadDataSbn}
                                    useArraySource={true}
                                    keyField='instrumentCode'

                                    backendserver={process.env.REACT_APP_BACKEND}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    height={'auto'}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Monitoring Tugas Data'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='top' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    selection={'none'}

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingObligasi" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapseObligasi" aria-expanded="false" aria-controls="collapseObligasi">
                            <span className="fas fa-chevron-down" /> Obligasi Korporasi
                        </div>
                        <div id="collapseObligasi" className="collapse" aria-labelledby="headingObligasi" data-parent="#porto">
                            <div className="card-body">
                                <DevExpressDataGrid
                                    loadAPI='currencies'
                                    insertAPI='currencies'
                                    updateAPI='currencies'
                                    deleteAPI='currencies'

                                    ArraySourceData={this.loadDataObligasi}
                                    useArraySource={true}
                                    keyField='instrumentCode'

                                    backendserver={process.env.REACT_APP_BACKEND}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    height={'auto'}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Monitoring Tugas Data'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='top' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    selection={'none'}

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingDeposito" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapseDeposito" aria-expanded="false" aria-controls="collapseDeposito">
                            <span className="fas fa-chevron-down" /> Deposito
                        </div>
                        <div id="collapseDeposito" className="collapse" aria-labelledby="headingDeposito" data-parent="#porto">
                            <div className="card-body">
                                <DevExpressDataGrid
                                    loadAPI='currencies'
                                    insertAPI='currencies'
                                    updateAPI='currencies'
                                    deleteAPI='currencies'

                                    ArraySourceData={this.loadDataDeposito}
                                    useArraySource={true}
                                    keyField='noBilyet'

                                    backendserver={process.env.REACT_APP_BACKEND}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    height={'auto'}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Monitoring Tugas Data'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='top' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    selection={'none'}

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnsDeposito} // taruh konfigurasi kolom disini

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingReksadana" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapseReksadana" aria-expanded="false" aria-controls="collapseReksadana">
                            <span className="fas fa-chevron-down" /> Reksadana & ETF
                        </div>
                        <div id="collapseReksadana" className="collapse" aria-labelledby="headingReksadana" data-parent="#porto">
                            <div className="card-body">
                                <DevExpressDataGrid
                                    loadAPI='currencies'
                                    insertAPI='currencies'
                                    updateAPI='currencies'
                                    deleteAPI='currencies'

                                    ArraySourceData={this.loadDataReksana}
                                    useArraySource={true}
                                    keyField='kodeReksa'

                                    backendserver={process.env.REACT_APP_BACKEND}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    height={'auto'}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Monitoring Tugas Data'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='top' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    selection={'none'}

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingEba" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapseEba" aria-expanded="false" aria-controls="collapseEba">
                            <span className="fas fa-chevron-down" /> EBA
                        </div>
                        <div id="collapseEba" className="collapse" aria-labelledby="headingEba" data-parent="#porto">
                            <div className="card-body">
                                <DevExpressDataGrid
                                    loadAPI='currencies'
                                    insertAPI='currencies'
                                    updateAPI='currencies'
                                    deleteAPI='currencies'

                                    ArraySourceData={this.loadDataEba}
                                    useArraySource={true}
                                    keyField='instrumentCode'

                                    backendserver={process.env.REACT_APP_BACKEND}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    height={'auto'}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Monitoring Tugas Data'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='top' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    selection={'none'}

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingProperti" style={{ cursor: 'pointer' }} data-toggle="collapse" data-target="#collapseProperti" aria-expanded="false" aria-controls="collapseProperti">
                            <span className="fas fa-chevron-down" /> Properti
                        </div>
                        <div id="collapseProperti" className="collapse" aria-labelledby="headingProperti" data-parent="#porto">
                            <div className="card-body">
                                <DevExpressDataGrid
                                    loadAPI='currencies'
                                    insertAPI='currencies'
                                    updateAPI='currencies'
                                    deleteAPI='currencies'

                                    ArraySourceData={this.loadDataProperti}
                                    useArraySource={true}
                                    keyField='instrumentCode'
                                    backendserver={process.env.REACT_APP_BACKEND}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    height={'auto'}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Monitoring Tugas Data'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={500} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='top' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    selection={'none'}

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PortofolioInvestasi
