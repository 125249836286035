import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { jabatan, jabatanGolongan } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class LemburBiaya extends Component {
    constructor(props){
        super(props)
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.state = {
            popupVisible:false,
            DataLembur:[]
        }

        this.column = [
            {
                dataField:'jabatanGolonganId',
                caption:'Golongan Jabatan',
                lookup: {
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaJagol', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:'uangLembur',
                caption:'Uang Lembur',
                dataType:"number",
                format:"#,##0.00",
                editorOptions:{
                    format:"#,##0.00"
                }
            },
            {
                dataField:'dasarPerhitungan',
                caption:'Dasar Perhitungan',
                lookup: {
                    dataSource: [
                        {
                            id:'hari',
                            value:'Hari'
                        },
                        {
                            id:'jam',
                            value:'Jam'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            }
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.PopupToolbarItem = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Save Data',
                onClick: this.submitData.bind(this)
            },
            toolbar: 'bottom'
        }, {
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Cancel',
                onClick: this.hide.bind(this)
            },
            toolbar: 'bottom'
        }]

        this.lemburId = null
        this.biayaLembur = []
        this.dataGridRef = React.createRef()
    }

    retrieveData = (data) => {
        this.setState({
            DataLembur:data
        })
        this.lemburId = data.id
        this.dataGridRef.current.forceRefresh(true)
        
    }

    retrieveDataBiaya = async () => {
        var biayaLembur = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'lembur-biayas', 'GET')
        var filterData = biayaLembur.filter(value => value.lemburTypeId === this.lemburId)
        this.biayaLembur = filterData

        return this.biayaLembur
    }

    submitData = async () => {
        let type = 'success'
        let text = 'Sukses input !'
        var getDatabiaya = this.dataGridRef.current.getDataSource()
        var lemburTypeId = this.state.DataLembur.id

        try {
            for(var data of getDatabiaya){
                data.lemburTypeId = lemburTypeId
                if (typeof data.id === 'string') {
                    delete data.id
                    this.addDataBiayaLembur(data)
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                }else{
                    this.updateDataBiayaLembur(data)
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                }
            }
            
            var getDeletedDatabiaya = this.dataGridRef.current.getDeletedIndex()

            for(var dataDelete of getDeletedDatabiaya){
                this.deleteDataBiayaLembur(dataDelete)
            }
        } catch (e) {
            console.log(e)
        }
        
    }

    addDataBiayaLembur = async (data) => {
        try {
            var loadAPI = `lembur-biayas`
  
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
                values: data,
            })
            
            if (response) {
                this.hide()
            } else {
                notify({ message: "Error", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
        }
        
    }

    updateDataBiayaLembur = async (data) => {
        try {
            var loadAPI = `lembur-biayas`
      
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                values: data,
                key:data.id
            },data)
            
            if (response) {
                this.hide()
            } else {
                notify({ message: "Error", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        
        } catch (e) {
            console.log(e)
        }
        
    }

    deleteDataBiayaLembur = async (data) => {
        try {
            var loadAPI = `lembur-biayas`
    
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE', data)
            this.hide()
        
        } catch (e) {
            console.log(e)
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.dataGridRef.current.emptyDeletedIndex()
        this.setState({
            popupVisible: false,
            DataLembur:{}
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={'Tarif Lembur'} 
                width={700}
                height={500}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            {/* <div className="container-fluid" style={{overflowY : 'scroll', height: '100%'}}> */}
                <DevExpressDataGrid
                ref={this.dataGridRef}
                    loadAPI='lembur-biayas'
                    insertAPI='lembur-biayas'
                    updateAPI='lembur-biayas'
                    deleteAPI='lembur-biayas' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    useArraySource={true}
                    ArraySourceData={this.retrieveDataBiaya}
                    keyField = {'id'}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"Lembur Biaya"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tarif Lembur'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    height={320}

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.column} // taruh konfigurasi kolom disini
                    onRowInserting={this.onRowInserting}
                    onRowUpdating={this.onRowUpdating}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            {/* </div>   */}
            </Popup>
        )
    }
}

export default LemburBiaya