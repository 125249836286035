import React, { Component } from 'react'
import { chartOfAccount } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import DevExtremeTreeList from 'components/inheritComponent/devextremetreelist'
import ModalCOA from 'components/pages/modal/akutansi/coa/editCOA'
import {alert} from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'
import XLSX from 'xlsx'
import { confirmAlert } from 'react-confirm-alert'

class ChartOfAccount extends Component {
    constructor(props){
        super(props)

        this.columns = chartOfAccount(this.props.store)

        this.columnDefaultValue = [
            {
                dataField: 'balanceAmount',
                value:0
            },
        ]

        this.treelistRef = React.createRef()
        this.modalCOARef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    loadData = async () => {
        var dataCOA = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'coas')
        var dataCOAGroup = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'coa-groups')

        dataCOA = dataCOA.map(value => {
            value.type = 'coa'
            value.id = `coa-${value.id}`
            return value
        })

        dataCOAGroup = dataCOAGroup.map(value => {
            value.coaCode = value.coaGroupCode
            value.coaName = value.coaGroupName
            value.coaGroupId = value.parentCoaGroupId
            value.type = 'group'
            return value
        })

        var data = [...dataCOA,...dataCOAGroup]

        data = data.sort((a,b) => {
            if(a.coaCode < b.coaCode) { return -1; }
            if(a.coaCode > b.coaCode) { return 1; }
            return 0;
        })

        return data
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'export',
                    onClick: (e) => {
                        this.export()
                    },
                }
            },
        )
    }

    showModalInsert = () => {
        this.modalCOARef.current.show()
    }

    showModalEdit = (data) => {
        this.modalCOARef.current.show()
        this.modalCOARef.current.retrieveData(data)
    }

    forceRefresh = () => {
        this.treelistRef.current.forceRefresh(true)
    }

    export = async () => {
        var data = this.treelistRef.current.getDataSource()

        var dataCurrency = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'currencies')

        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: `Chart of Account`,
            Subject: "COA",
            Author: "Dapen",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("COA");

        var ws_data = [];
        ws_data.push(['Tipe','Kode COA','Nama COA','Kategori','Mata Uang','Saldo Normal','LR/Neraca'])
        for(var coa of data){
            if(coa.type === 'group'){
                ws_data.push([coa.type,coa.coaCode,coa.coaName])
            }else{
                const currency = dataCurrency.find(value => value.id === coa.currencyId)

                ws_data.push([coa.type,coa.coaCode,coa.coaName,coa.category,currency.currencyCode,coa.normalBalance === 'D' ? 'Debet' : 'Kredit',coa.plbs === 'b' ? 'Neraca' : 'Laba Rugi'])
            }
        }

        var ws = XLSX.utils.aoa_to_sheet(ws_data);

        wb.Sheets["COA"] = ws;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], {type: fileType});

        var a = document.createElement("a"),
            url = URL.createObjectURL(dataBlob);
        a.href = url;
        a.download = 'Chart of Account'+fileExtension;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);  
        }, 0); 
    }

    render() {
        var columns = [{
            type: 'buttons',
            buttons: [
              {
                text: 'Ubah',
                hint: 'Ubah',
                cssClass: 'text-success',
                onClick: (e) => {
                    this.showModalEdit(e.row.data)
                }
              },
              {
                text: 'Hapus',
                hint: 'Hapus',
                cssClass: 'text-success',
                onClick: async (e) => {
                    if(e.row.data.type === 'group' && !e.row.node.hasChildren){
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin untuk menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: async() => {
                                        await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`staticdata-wf/coa-groups`,'DELETE',e.row.data.id)
                                        notify({ message: 'Berhasil menghapus Group COA. Harap cek daftar persetujuan.', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 2000);  
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ],
                        })
                    }else{
                        alert('Tidak dapat menghapus COA dan Group COA yang masih memiliki anak.','Error')
                    }
                }
              },
            ],
        },...this.columns]

        return (
            <div className="container-fluid">
                <h2 className="main-title">Chart Of Account</h2>

                <DevExtremeTreeList
                    ref = {this.treelistRef}

                    keyField= {'id'}
                    parentIdExpr = {'coaGroupId'}

                    loadAPI='coas'
                    insertAPI='staticdata-wf/coas'
                    updateAPI='staticdata-wf/coas'
                    deleteAPI='staticdata-wf/coas' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useNotify = {false}

                    ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"User Group"}
                    allowExportSelectedData={false}
                    selection={'none'}
                    showBorders={true}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Menu'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    height = {'calc(100vh - 215px)'}

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalCOA 
                    ref = {this.modalCOARef}
                    store = {this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default ChartOfAccount
