import React, { Component } from 'react'
import { currency, banks, orders, product, instrumentsCategoryProperti } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import uuidv4 from 'uuid/v4'
import DetailPenempatanProperti from './detail'
import FormPengajuanPenempatanProperti from 'components/pages/modal/investasi/pengajuan/properti/penempatan/form'
import FormPersetujuanPenempatanProperti from 'components/pages/modal/investasi/pengajuan/properti/penempatan/persetujuan'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'
import { showLoading } from 'redux/actions/loading'
import { formatDefaultFullDate, inputToTaskInvestasi, PrintElem, stripHtml } from 'plugin/helper'
import reportRequest from 'plugin/reportRequest'

class PengajuanPenempatanProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridPropertiRef = React.createRef()
        this.formPengajuanPenempatanPropertiRef = React.createRef()
        this.formPengajuanPenempatanPropertiEditRef = React.createRef()
        this.formPersetujuanPenempatanPropertiRef = React.createRef()
        this.currentData = []
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: async (e) => {
                            var data = e.row.data
                            if (data.statusId == 'DRAFT') {
                                this.showModalEdit(data, 'edit')
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Cetak',
                        hint: 'Cetak',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Belum terkirim!',
                                    message: 'Data belum terkirim tidak bisa cetak surat!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakSurat(data)
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.statusId == 'DRAFT') {
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.statusId == 'DRAFT') {
                                confirmAlert({
                                    title: 'Batalkan draft',
                                    message: 'Apakah anda yakin ingin membatalkan draft ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa dibatalkan!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'requestNo',
                caption: 'Nomor Pengajuan',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.requestNo == null) {
                        return 'DRAFT'
                    } else {
                        return data.requestNo
                    }
                }
            },
            {
                dataField: 'tanggalPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'tanggalOrder',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'instrumentName',
                caption: 'Instrument',
            },
            {
                dataField: 'jumlahUnit',
                caption: 'Jumlah Unit',
                alignment: 'right'
            },
            {
                dataField: 'nominalOrder',
                caption: 'Nilai Penempatan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'DRAFT',
                            text: 'Tersimpan'
                        },
                        {
                            id: 'INPROGRESS',
                            text: 'Sedang dalam proses persetujuan'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'text'
                }
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Penempatan',
                    onClick: (e) => {
                        this.showModalInsert('add')
                    },
                }
            }
        )
    }

    showModalInsert = (type) => {
        if (type === 'add') {
            this.formPengajuanPenempatanPropertiRef.current.show()
            this.formPengajuanPenempatanPropertiRef.current.retrieveData()
        }
    }
    cancelData = async (data) => {
        var reqId = data.orderRequestId
        if (reqId) {
            try {
                var insertAPI = `order-request-batal?orderRequestId=${reqId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: {}
                })
                notify({ message: 'Pembatalan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            }
        }
    }
    kirimData = async (data) => {
        this.props.store.dispatch(showLoading(true))
        var loadAPI = `orders/get-by-orderRequestId/${data.orderRequestId}`
        var responseOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var responseRequest = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${data.orderRequestId}`, 'GET')
        let result = []
        for (var dataOrder of responseOrder) {
            var responseProperti = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-propertis/findByOrderId/${dataOrder.id}`, 'GET')
            var allData = {
                ...dataOrder,
                ...responseProperti
            }
            result.push(allData)
        }
        var dataMaster = responseRequest
        var kajian = dataMaster.kajian
        var content = dataMaster.kajianContentType
        var dataDetail = []
        data = {
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalOrder: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            requestId: dataMaster.id,
            categoriInstrumentId: dataMaster.categoryId,
            jenisTransaksiId: dataMaster.orderTypeId,
            dasarPengajuan: kajian,
            dasarPengajuanContentType: content,
            draft: false,
            orderRequestId: dataMaster.id
        }
        for (var properti of result) {
            var propertiInfo = {
                id: properti.id,
                agenPenjualId: properti.agenPenjualId,
                currencyId: properti.currencyId == null ? 1001 : properti.currencyId,
                fee: properti.comission == null ? 0 : properti.comission,
                fundManagerId: properti.fundManagerId,
                hargaMaksimum: properti.priceMax,
                hargaMinimum: properti.priceMin,
                instrumentId: properti.instrumentId,
                jenisHarga: properti.priceType,
                keterangan: properti.keterangan,
                kurs: properti.kurs,
                nominalFee: properti.nominalComission == null ? 0 : properti.nominalComission,
                pengembangEntitasId: properti.pengembangEntitasId,
                penjualEntitasId: properti.penjualEntitasId,
                qtyOrder: properti.qtyOrder,
                rekBankId: properti.rekBankId,
            }
            dataDetail.push(propertiInfo)
        }
        var submitData = {
            ...data,
            detailListInstrumen: dataDetail
        }

        try {
            let orderRequestId = responseOrder[0].orderRequestId
            let orderTypeId = responseOrder[0].orderTypeId

            await inputToTaskInvestasi(this.props.store, orderRequestId, orderTypeId)
        } catch (error) {
            console.log(error);
        }

        if (submitData) {
            try {
                var insertAPI = 'orders/penempatan-properti/pengajuan'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Pengiriman berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                this.props.store.dispatch(showLoading(true))
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    cetakSurat = async (data) => {
        var reqNo = data.requestNo
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${data.orderRequestId}`, 'GET')
        var dirKeuangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/6`, 'GET')
        var dirOperasional = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/79506`, 'GET')
        var dirut = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5`, 'GET')
        var komite = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5351`, 'GET')
        var dataMaster = response
        var kajian = stripHtml(atob(dataMaster.kajian)) == 'undefined' ? ' ' : stripHtml(atob(dataMaster.kajian))

        if (dirKeuangan.length > 0) {
            dirKeuangan = dirKeuangan[0]
        } else {
            dirKeuangan = {}
        }

        if (dirOperasional.length > 0) {
            dirOperasional = dirOperasional[0]
        } else {
            dirOperasional = {}
        }

        if (dirut.length > 0) {
            dirut = dirut[0]
        } else {
            dirut = {}
        }

        if (komite.length > 0) {
            komite = komite[0]
        } else {
            komite = {}
        }

        var data = {
            template: {
                'shortid': 'FP0QwoS',
                'recipe': 'html'
            },
            data: {
                "suratNo": reqNo,
                "tanggal": formatDefaultFullDate(dataMaster.orderDate),
                "jenisInvestasi": data.instrumentName,
                "kajian": kajian,
                "dirKeuangan": {
                    "fullName": dirKeuangan.fullName,
                    "jabatan": 'Direktur Keuangan'
                },
                "dirOperasional": {
                    "fullName": dirOperasional.fullName,
                    "jabatan": 'Direktur Operasional'
                },
                "dirut": {
                    "fullName": dirut.fullName,
                    "jabatan": 'Direktur Utama'
                },
                "dirInvest": {
                    "fullName": komite.fullName,
                    "jabatan": 'Direktur Investasi'
                }
            },
            options: {
                reportName: "UPTI-" + data.requestNo
            }
        }

        var print = await reportRequest(this.props.store, data)
        PrintElem(print.data)
        return print
    }

    showModalEdit = async (data, type) => {
        var loadAPI = `orders/get-by-orderRequestId/${data.orderRequestId}`
        var responseOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var responseRequest = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${data.orderRequestId}`, 'GET')
        let result = []
        for (var dataOrder of responseOrder) {
            var responseProperti = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-propertis/findByOrderId/${dataOrder.id}`, 'GET')
            var allData = {
                ...dataOrder,
                ...responseProperti
            }
            result.push(allData)
        }
        var dataMaster = responseRequest
        var kajian = atob(dataMaster.kajian)
        var dataDetail = []
        data = {
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalOrder: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            requestId: dataMaster.id,
            orderTypeId: dataMaster.orderTypeId,
            noPengajuan: '<AUTO>',
            categoriInstrumentId: dataMaster.categoryId,
            jenisTransaksiId: dataMaster.orderTypeId,
            actionType: 'edit'
        }
        for (var properti of result) {
            var propertiInfo = {
                id: properti.id,
                agenPenjualId: properti.agenPenjualId,
                currencyId: properti.currencyId == null ? 1001 : properti.currencyId,
                fee: properti.comission == null ? 0 : properti.comission,
                fundManagerId: properti.fundManagerId,
                hargaMaksimum: properti.priceMax,
                hargaMinimum: properti.priceMin,
                instrumentId: properti.instrumentId,
                jenisHarga: properti.priceType,
                keterangan: properti.keterangan,
                kurs: properti.kurs,
                nominalFee: properti.nominalComission == null ? 0 : properti.nominalComission,
                pengembangEntitasId: properti.pengembangEntitasId,
                penjualEntitasId: properti.penjualEntitasId,
                qtyOrder: properti.qtyOrder,
                rekBankId: properti.rekBankId,
            }
            dataDetail.push(propertiInfo)
        }
        if (type === 'edit') {
            this.formPengajuanPenempatanPropertiEditRef.current.show(data)
            this.formPengajuanPenempatanPropertiEditRef.current.retrieveData(data, dataDetail, kajian)
        }
    }
    customDetailElem = (data) => {
        return (
            <DetailPenempatanProperti detailData={data.orderRequestId} store={this.props.store} />
        )
    }
    forceRefresh = () => {
        this.DataGridPropertiRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Usulan Penempatan Investasi Properti</h2>
                <DevExpressDataGrid
                    ref={this.DataGridPropertiRef}
                    loadAPI='view-pengajuan-penempatan-propertis?orderTypeId.in=284&statusId.in=DRAFT&statusId.in=INPROGRESS'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Properti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Properti'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPenempatanProperti
                    ref={this.formPengajuanPenempatanPropertiRef}
                    actionType={'add'}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormPengajuanPenempatanProperti
                    ref={this.formPengajuanPenempatanPropertiEditRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormPersetujuanPenempatanProperti
                    ref={this.formPersetujuanPenempatanPropertiRef}
                    store={this.props.store}
                    showModalView={this.showModalView}
                />
            </div>
        )
    }
}

export default PengajuanPenempatanProperti