import React, { Component } from 'react'
import { Popup, ScrollView, Form, FileUploader } from 'devextreme-react'
import Box, { Item } from 'devextreme-react/box';
import { formatUploadFileData } from 'plugin/helper';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class FormModalProgramDapenKepesertaan extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
            fileUploadReceipt: []
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField:'namaKaryawan',
                        label:{
                            text:'Nama Karyawan'
                        }
                    },
                    {
                        dataField:'phdp',
                        label:{
                            text:'PHDP'
                        }
                    },
                ],
            },
            {
                itemType: 'group',
                caption:'Dana Pensiun',
                items: [
                    {
                        dataField:'danaPensiun',
                        label:{
                            text:'Dana Pensiun'
                        }
                    },
                    {
                        dataField:'noPeserta',
                        label:{
                            text:'No Peserta'
                        }
                    },
                    {
                        dataField:'tanggalKepesertaan',
                        label:{
                            text:'Tanggal Kepesertaan'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption:'Produk Dana Pensiun',
                items: [
                    {
                        dataField:'noSk',
                        label:{
                            text:'No SK Kepesertaan'
                        }
                    },
                    {
                        dataField:'tanggalSKKepesertaan',
                        label:{
                            text:'Tanggal SK Kepesertaan'
                        }
                    },
                ]
            }
        ]

        this.columns = [
            {
                dataField:'program',
                caption:'Program'
            },
            {
                dataField:'noAkun',
                caption:'No Akun'
            },
            {
                dataField:'tanggalAkun',
                caption:'Tanggal Akun'
            },
            {
                dataField:'iuranNormal',
                caption:'Iuran Normal',
                alignment:'center',
                columns:[
                    {
                        dataField:'pemberiKerjaIuranNomal',
                        caption:'Pemberi Kerja Iuran Nomal'
                    },
                    {
                        dataField:'peserta',
                        caption:'Peserta Iuran Nomal'
                    },
                ]
            },
            {
                dataField:'iuranTambahan',
                caption:'Iuran Tambahan',
                alignment:'center',
                columns:[
                    {
                        dataField:'pemberiKerjaIuranTambahan',
                        caption:'Pemberi Kerja Iuran Tambahan'
                    },
                    {
                        dataField:'peserta',
                        caption:'Peserta Iuran Tambahan'
                    },
                ]
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}Name`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster:data
        })
    }

    submitData = () => {
    
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster: {},
        })
    }

    loadDummyData = () => {
        var data = [
            {
                id:1
            }
        ]

        return data
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Kepesertaan"}
                toolbarItems={this.PopupToolbarItem}
                minWidth={500}
                minHeight={550}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={1}
                            id={'formDetailIuran'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation = 'left'
                        />
                        <div className="my-3">
                        <DevExpressDataGrid
                            ref = {this.DataGridPengajuanRef}
                            loadAPI='regist-pengkinian-pengajuan-aktifs'
                            insertAPI='regist-pengkinian-pengajuan-aktifs'
                            updateAPI='regist-pengkinian-pengajuan-aktifs'
                            deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                            backendserver={process.env.REACT_APP_BACKEND_CORE}

                            useArraySource = {true}
                            ArraySourceData= {this.loadDummyData}

                            allowAdding={true}
                            allowDeleting={true}
                            allowUpdating={true}

                            exportExcel={true}
                            exportFileName={"Cuti Karyawan"}
                            allowExportSelectedData={true}
                            selection={"multiple"}
                            
                            showBorders={true}
                            height={400}
                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Produk Dana Pensiun'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            // SummaryConfiguration={this.summary}

                            // onToolbarPreparing = {this.onToolbarPreparing}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                        </div>
                        <Box direction={"row"} width={"100%"}>
                            <Item ratio={1}>
                                <FileUploader
                                    accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText="File SK Kepesertaan"
                                    showFileList={false}
                                    name={'file'}
                                    value = {this.state.fileUploadReceipt}
                                    disabled={false }
                                    ref={this.fileUploderRef}
                                />
                                <div className="row">
                                    <label className="col-4">File Name:</label>
                                    <label className="col-8">{ this.state.DataMaster.fileName}</label>
                                </div>
                            </Item>
                            <Item ratio={1}>
                                <FileUploader
                                    accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText="File Kartu Peserta"
                                    showFileList={false}
                                    name={'file'}
                                    value = {this.state.fileUploadReceipt}
                                    disabled={false }
                                    ref={this.fileUploderRef}
                                />
                                <div className="row">
                                    <label className="col-4">File Name:</label>
                                    <label className="col-8">{ this.state.DataMaster.fileName}</label>
                                </div>
                            </Item>
                        </Box>
                    </ScrollView>
                </Popup>
        )
    }
}

export default FormModalProgramDapenKepesertaan
