import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from "devextreme-react/form";
import { Button } from 'devextreme-react';

//copy paste this code to create new modal

class FormNoteModalMpSusulan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            notesData: {},
            approveOrReject: null,
            action: ''
        }

        this.formItems = [
            {
                dataField: 'notesMpSusulan',
                label: {
                    visible: false,
                    alignment: 'left',
                    text: 'Catatan',
                    location: 'left',
                },
                editorType: 'dxTextBox',
            },
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.from = ''
        this.onShown = this.onShown.bind(this)

        this.formRef = React.createRef()
    }

    show(from) {
        this.from = from
        this.setState({
            popupVisible: true,
        });
    }

    onShown = (e) => {
        this.formRef.current.instance.getEditor("notesMpSusulan").focus()
    }

    hide() {
        this.from = ''
        this.setState({
            notesData: {},
            popupVisible: false
        });
    }

    getAction = (action) => {
        this.setState({
            action: action,
        })
    }

    setApprovalNotes = () => {
        this.props.catatan(this.state.notesData.notesMpSusulan)
        this.hide()
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={`Catatan`}
                width={500}
                height={160}

                onShown={this.onShown}

                ref={this.PopupRef}
            >
                <Form
                    colCount={1}
                    id={"formMaster"}
                    formData={this.state.notesData}
                    items={this.formItems}
                    ref={this.formRef}
                />
                <div className={'row fixed-bottom mr-2 mb-4'}>
                    <div className={'col-12'}>
                        <div className='float-right'>
                            <Button
                                type={'success'}
                                text={'Submit'}
                                className="mr-2"
                                onClick={(e) => { this.setApprovalNotes() }}
                            />
                            <Button
                                type={'danger'}
                                text={'Cancel'}
                                onClick={this.hide}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormNoteModalMpSusulan
