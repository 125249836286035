import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import {Form,FileUploader} from 'devextreme-react'
import { absenType, jabatan, karyawan, programs } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import { formatUploadFileData, imageSource } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import notify from "devextreme/ui/notify";

class FormAbsensiKepegawaian extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {
                programId: 1
            },
            DataDocument:{}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];
        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.form = [
            {
                dataField: 'programId',
                label: {
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (e) => {
                        this.karyawanByProgram(e.value)
                    }
                },
            },
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Nama Lengkap',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: [],
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'absenTypeId',
                label: {
                    text: 'Tipe Absen',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: absenType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'absenName',
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'manual_entry',
                label: {
                    text: 'Manual Entry'
                },
                dataType:'boolean',
                // caption: {
                //     text: 'Enable Cross',
                //     alignment: 'left',
                //     location: 'left'
                // },
                editorType: 'dxCheckBox',
            },
            {
                dataField: "tanggal",
                label: {
                    text: "Tanggal",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat:'dd MMM yyyy'
                }
            },
            // {
            //     dataField: "absenTypeId",
            //     label: {
            //         text: "Tipe Absen",
            //         alignment: "left",
            //         location: "left",
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         dataSource: absenType(this.props.store),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'absenName',
            //         searchEnabled: true,
            //         deferRendering: false,
            //     },
            // },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan',
                },
            },
        ]
    } 

    show() {
        this.setState({
            DataMaster: {
                programId: 1
            },
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster: {},
            DataDocument: {},
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value
        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}

            var loadApi = 'karyawan-absens'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    updateData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}

            var loadApi = 'karyawan-absens'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "PUT",{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        var filteredData = getKaryawan.filter(val => val.programId == programId)
        let dataSource = new ArrayStore({
            data: filteredData,
            key: 'id'
        })
        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }

    retrieveData = async(data) => {
        var loadAPI = `karyawan-absens/${data.karyawanAbsenId}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPI, 'GET')
        this.setState({
            DataMaster: getData,
            DataDocument: getData
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocument };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}Keterangan`] = formattedFileData.base64data;
                DataDocument[`${fieldName}KeteranganContentType`] = formattedFileData.mime;
                this.setState({
                    DataDocument: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    previewFile = async (state) => {
        var src = imageSource(state.fileKeteranganContentType, state.fileKeterangan)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={"Form Absensi"}
                width={500}
                height={400}
                toolbarItems={this.props.type == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formDataGaji'}
                        formData={this.state.DataMaster}
                        items={this.form}
                        labelLocation = 'left'
                    />
                    <div className='row'>
                        <div className='col-md-8'>
                            <FileUploader
                                accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText={this.state.DataDocument.fileName || "Upload File Keterangan"}
                                showFileList={false}
                                name={"file"}
                            />
                        </div>
                        <div className='col-md-3 text-center my-auto'>
                            <button onClick={() => this.previewFile(this.state.DataDocument, 'file')} className={`btn btn-light btn-sm bg-button-popup-save-dapen-default ${this.state.DataDocument.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                    </div>
                    <ModalPreviewPdf
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                    {/* <ModalPreviewImage
                        ref={this.modalPreviewImageRef}
                        store={this.props.store}
                    /> */}
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormAbsensiKepegawaian

































