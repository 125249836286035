import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import ArrayStore from 'devextreme/data/array_store';
import { reportFile } from 'plugin/reportRequest'

class LaporanKomposisiDetail extends Component {
    constructor(props) {
        super(props)

        this.dgRef = React.createRef()
        this.formRef = React.createRef();

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.state = {
            filterData: {
                productId: 1,
                reportDate: formatDate(this.date)
            }
        }

        this.columns = [
            {
                dataField: 'categoryName',
                caption: 'Jenis Instrument',
            },
            {
                dataField: 'subName',
                caption: 'Sub Kategori',
            },
            {
                dataField: 'priceType',
                caption: 'Tipe Harga',
            },
            {
                dataField: 'htm',
                caption: 'HTM',
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelola',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'tipe',
                caption: 'Tipe',
            },
            {
                dataField: 'kuantitas',
                caption: 'Kuantitas',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0',
            },
            {
                dataField: 'hargaPerolehan',
                caption: 'Harga Perolehan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'faceValue',
                caption: 'Face Value',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nilaiPembelian',
                caption: 'Nilai Pembelian',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'totalPembelian',
                caption: 'Total Pembelian',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'komposisi',
                caption: 'Komposisi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.productCode + ' - ' + e.productName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'reportDate',
                        label: {
                            text: 'Tanggal Laporan',
                            alignment: "left",
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            width: '100%',
                            readOnly: false
                        },
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]

        this.sumHasilInvestasi = [
            {
                showInColumn: 'tipe',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'faceValue',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumFaceValue',
                displayFormat: '{0}'
            },
            // {
            //     column: 'totalPembelian',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumTotalPembelian',
            //     displayFormat: '{0}'
            // },
            {
                column: 'nilaiPembelian',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumNilaiPembelian',
                displayFormat: '{0}'
            },
            {
                column: 'komposisi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumKomposisi',
                displayFormat: '{0}'
            },
        ]

        this.selectedRowDatas = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    loadData = () => {
        var data = [
            {
                id: 1,
            }
        ]
        return data
    }

    print = async (productId) => {
        let newData = [];
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'view-komposisi-detils?productId.in=1&size=99999');
        let response = this.selectedRowDatas;
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store, `view-komposisi-detils?categoryName.equals=Saham&size=9999`);

        if (response.length > 0) {
            let arrTotalPerolehan = [];
            let arrTotalFaceValue = [];
            let arrTotalPembelian = [];
            let arrNilaiPembelian = [];
            let arrTotalKom = [];
            for (let data of response) {
                arrTotalPerolehan.push(data.hargaPerolehan);
                arrTotalFaceValue.push(data.faceValue);
                arrTotalPembelian.push(data.totalPembelian);
                arrNilaiPembelian.push(data.nilaiPembelian);
                arrTotalKom.push(data.komposisi);

                let dataResult = {
                    id: 1,
                    subName: data.subName,
                    priceType: data.priceType,
                    htm: null,
                    pengelola: data.pengelola,
                    instrumentCode: data.instrumentCode,
                    tipe: "",
                    kuantitas: data.kuantitas,
                    hargaPerolehan: data.hargaPerolehan,
                    faceValue: data.faceValue,
                    nilaiPembelian: data.nilaiPembelian,
                    totalPembelian: data.totalPembelian,
                    komposisi: data.komposisi
                }
                newData.push(dataResult);
            }
            var totalNilaiFaceValue = arrTotalFaceValue.reduce((a, b) => a + b, 0);
            var totalPembelianSum = arrTotalPembelian.reduce((a, b) => a + b, 0);
            var totalKom = arrTotalKom.reduce((a, b) => a + b, 0);
            var totalNilaiPem = arrNilaiPembelian.reduce((a, b) => a + b, 0);
        }
        reportFile({
            template: { 'shortid': 'BkgE98Fezo' },
            data: {
                "productId": productId,
                "komposisiDetail": newData,
                "totalNilaiFaceValue": totalNilaiFaceValue,
                "totalPembelian": totalPembelianSum,
                "totalKom": totalKom,
                "totalNilaiPem": totalNilaiPem,
                "categoryName": response.categoryName
            },
            options: {
                reportName: "Komposisi Detail"
            }
        }, true, `Komposisi Detail.pdf`)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: (e) => {
                        this.print(this.state.filterData.productId)
                    },
                }
            }
        )
    }

    onSelectionChanged = (e) => {
        this.selectedRowDatas =  e.selectedRowsData
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Komposisi Detail</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dgRef}
                    // loadAPI={`view-komposisi-detils?productId.in=${this.state.filterData.productId}&size=99999`}
                    loadAPI={`efek-balance-avg-dailies/komposisi-detail/${this.state.filterData.productId}/${this.state.filterData.reportDate}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Komposisi Detail"}
                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'kuantitas',
                            'hargaPerolehan',
                            'faceValue',
                            'nilaiPembelian',
                            'totalPembelian',
                            'komposisi'
                        ]
                    }
                    allowExportSelectedData={true}
                    selection={'multiple'}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumHasilInvestasi}
                    onToolbarPreparing={this.onToolbarPreparing}

                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanKomposisiDetail