import React, { Component } from 'react'
import { currency, banks, orders, product } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import uuidv4 from 'uuid/v4'
import DetailPerpanjanganDeposito from './detail'
import FormPengajuanPerpanjanganDeposito from 'components/pages/modal/investasi/pengajuan/deposito/perpanjangan/form'
import { confirmAlert } from 'react-confirm-alert'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import reportRequest from 'plugin/reportRequest'
import { compactNumber, formatDefaultDate, formatDefaultFullDate, formatNumber, inputToTaskInvestasi, PrintElem, stripHtml } from 'plugin/helper'

class PengajuanPerpanjanganDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.subCategory = []
        this.DataGridDepositoRef = React.createRef()
        this.formPengajuanPerpanjanganDeposito = React.createRef()
        this.formPengajuanPerpanjanganDepositoEdit = React.createRef()
        this.formPersetujuanPenempatanDepositoRef = React.createRef()
        this.currentData = []
        this.dataPerpanjangan = []
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                this.showModalEdit(data, 'edit')
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Cetak',
                        hint: 'Cetak',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Belum terkirim!',
                                    message: 'Data belum terkirim tidak bisa cetak surat!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakSurat(data)
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Batalkan draft',
                                    message: 'Apakah anda yakin ingin membatalkan draft ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'requestNo',
                caption: 'Nomor Pengajuan',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.requestNo == null) {
                        return 'DRAFT'
                    } else {
                        return data.requestNo
                    }
                }
            },
            {
                dataField: 'requestDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'd MMM yyyy',
            },
            {
                dataField: 'orderDate',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'd MMM yyyy',
            },
            {
                dataField: 'productCode',
                caption: 'Produk',
                // lookup: {
                //     dataSource: product(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'productCode'
                // }
            },
            {
                dataField: 'categoryName',
                caption: 'Kategori'
            },
            {
                dataField: 'totalBank',
                caption: 'Jumlah Bank',
                alignment: 'right'
            },
            {
                dataField: 'totalCategorySubName',
                caption: 'Jumlah Deposito',
                alignment: 'right'
            },
            {
                dataField: 'totalAmountOrder',
                caption: 'Nilai Deposito',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                // dataField: 'acruedInterest',
                dataField: 'acruedToday',
                // caption: 'Nominal Interest',
                caption: 'Nominal Bunga',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0'
            },
            {
                dataField: 'totalAmountOrder',
                caption: 'Nilai Perpanjangan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Persetujuan',
                lookup: {
                    dataSource: [
                        {
                            id: 'DRAFT',
                            value: 'Tersimpan'
                        },
                        {
                            id: 'INPROGRESS',
                            value: 'Sedang proses persetujuan'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var loadAPI = 'instrument-category-subs/deposito'
        var getSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')

        this.subCategory = getSubCategory
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Perpanjangan',
                    onClick: (e) => {
                        this.showModalInsert('add')
                    },
                }
            }
        )
    }
    cancelData = async (data) => {
        var reqId = data.requestId
        if (reqId) {
            try {
                var insertAPI = `order-request-batal?orderRequestId=${reqId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: {}
                })
                notify({ message: 'Pembatalan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            }
        }
    }
    showModalInsert = (type) => {
        if (type === 'add') {
            this.formPengajuanPerpanjanganDeposito.current.show()
            this.formPengajuanPerpanjanganDeposito.current.retrieveData()
        }
    }
    cetakSurat = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-deposito/${data.requestId}`, 'GET')
        var dirKeuangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/6`, 'GET')
        var dirOperasional = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/79506`, 'GET')
        var dirut = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5`, 'GET')
        var komite = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5351`, 'GET')

        var kajian = stripHtml(atob(response.orderRequestDTO.kajian)) == 'undefined' ? ' ' : stripHtml(atob(response.orderRequestDTO.kajian))

        var dataDeposito = []

        if (dirKeuangan.length > 0) {
            dirKeuangan = dirKeuangan[0]
        } else {
            dirKeuangan = {}
        }

        if (dirOperasional.length > 0) {
            dirOperasional = dirOperasional[0]
        } else {
            dirOperasional = {}
        }

        if (dirut.length > 0) {
            dirut = dirut[0]
        } else {
            dirut = {}
        }

        if (komite.length > 0) {
            komite = komite[0]
        } else {
            komite = {}
        }

        var no = 0
        for (var deposito of response.orderDepositoInfos) {
            var depositoInfo = {
                no: ++no,
                bankName: deposito.orderDepositoDTO.bankName,
                categoryName: this.subCategory.find(val => val.id == deposito.orderDTO.categorySubId).subName,
                amountOrder: compactNumber(deposito.orderDepositoDTO.amountOrder),
                rateOrder: deposito.orderDepositoDTO.rateOrder,
                tenor: deposito.orderDepositoDTO.tenor,
                tenorType: deposito.orderDepositoDTO.tenorTypeId,
            }
            dataDeposito.push(depositoInfo)
        }
        var data = {
            template: {
                'shortid': 'r1eXPt__Vu',
                'recipe': 'html'
            },
            data: {
                "suratNo": data.requestNo,
                "tanggal": formatDefaultFullDate(data.requestDate),
                "tenor": dataDeposito[0].tenor,
                "tenorType": dataDeposito[0].tenorType,
                "jenisInvestasi": dataDeposito[0].categoryName,
                "bankName": dataDeposito[0].bankName,
                "amountOrder": dataDeposito[0].amountOrder,
                "totalAmountOrder": compactNumber(data.totalAmountOrder),
                "rate": dataDeposito[0].rateOrder,
                "transactionName": data.transactionName,
                "kajian": kajian,
                "dataDeposito": dataDeposito,
                "dirKeuangan": {
                    "fullName": dirKeuangan.fullName,
                    "jabatan": 'Direktur Keuangan'
                },
                "dirOperasional": {
                    "fullName": dirOperasional.fullName,
                    "jabatan": 'Direktur Operasional'
                },
                "dirut": {
                    "fullName": dirut.fullName,
                    "jabatan": 'Direktur Utama'
                },
                "dirInvest": {
                    "fullName": komite.fullName,
                    "jabatan": 'Direktur Investasi'
                }
            },
            options: {
                reportName: "ROLLOVER-" + data.requestNo
            }
        }
        var print = await reportRequest(this.props.store, data)
        PrintElem(print.data)
        return print
    }
    showModalView = (data) => {
        var dataMaster = { ...data }
        var dataPembelian = dataMaster.detail
        delete dataMaster.detail

        this.formPengajuanPerpanjanganDeposito.current.show(dataMaster, dataPembelian)
    }
    kirimData = async (data) => {
        var loadAPI = `order-requests-deposito/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = dataMaster.kajian
        var content = dataMaster.kajianContentType
        var dataDetail = []
        data = {
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalPerpanjangan: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            requestOrderId: dataMaster.id,
            requestNo: '<AUTO>',
            kajian: kajian,
            kajianContentType: content,
            categoryName: 'Deposito',
            draft: false,
        }
        for (var deposito of response.orderDepositoInfos) {
            var dataEfek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-bilyet-no/${deposito.orderDepositoDTO.bilyetNo}`, 'GET')
            var depositoInfo = {
                id: deposito.orderDepositoDTO.id,
                bilyetNo: deposito.orderDepositoDTO.bilyetNo,
                instrumentId: deposito.orderDepositoDTO.instrumentId,
                instSubCategoryId: deposito.orderDTO.categorySubId,
                kpdId: deposito.orderDTO.kpdId,
                bankId: deposito.orderDepositoDTO.bankId,
                currencyId: deposito.orderDepositoDTO.currencyId,
                kurs: deposito.orderDepositoDTO.kurs,
                orderId: deposito.orderDepositoDTO.orderId,
                nominalPerpanjangan: deposito.orderDepositoDTO.rolloverAmount,
                rate: deposito.orderDepositoDTO.rateOrder,
                rolloverAuto: deposito.orderDepositoDTO.rolloverAuto,
                rolloverTypeId: deposito.orderDepositoDTO.rolloverTypeId,
                tenor: deposito.orderDepositoDTO.tenor,
                tenorTypeId: deposito.orderDepositoDTO.tenorTypeId,
                tanggalPerpanjangan: deposito.orderDepositoDTO.rolloverDate,
                rekbankId: deposito.orderDepositoDTO.rekBankId,
                efekBalanceAvgId: dataEfek.id
            }
            dataDetail.push(depositoInfo)
        }
        var submitData = {
            ...data,
            instrumentDepositoList: dataDetail
        }

        var submitDataI = {
            orderRequestId: response.orderDepositoInfos[0].orderDTO.orderRequestId,
            orderTypeId: response.orderDepositoInfos[0].orderDTO.orderTypeId
        }

        try {
            let orderRequestId = response.orderDepositoInfos[0].orderDTO.orderRequestId
            let orderTypeId = response.orderDepositoInfos[0].orderDTO.orderTypeId

            await inputToTaskInvestasi(this.props.store, orderRequestId, orderTypeId)

        } catch (error) {
            console.log(error);
        }

        if (submitData) {
            try {
                var insertAPI = 'order-depositos/pengajuan-perpanjangan-deposito'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Pengiriman berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
            }
        }
    }
    showModalEdit = async (data, type) => {
        var loadAPI = `order-requests-deposito/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = atob(dataMaster.kajian)
        var dataDetail = []
        data = {
            ...data,
            productId: dataMaster.productId,
            fundManagerId: dataMaster.fundManagerId,
            tanggalPerpanjangan: dataMaster.orderDate,
            tanggalPengajuan: dataMaster.requestDate,
            orderTypeId: response.orderDepositoInfos[0].orderDTO.orderTypeId,
            requestId: dataMaster.id, 
            noPengajuan: '<AUTO>',
            kategori: 'Deposito',
            actionType: 'edit'
        }
        for (var deposito of response.orderDepositoInfos) {
            var dataEfek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/by-bilyet-no/${deposito.orderDepositoDTO.bilyetNo}`, 'GET')
            var depositoInfo = {
                id: deposito.orderDepositoDTO.id,
                instSubCategoryId: deposito.orderDTO.categorySubId,
                productId: dataMaster.productId,
                kpdId: deposito.orderDTO.kpdId,
                bankId: deposito.orderDepositoDTO.bankId,
                currencyId: deposito.orderDepositoDTO.currencyId,
                kurs: deposito.orderDepositoDTO.kurs,
                nominalPerpanjangan: deposito.orderDepositoDTO.amountOrder,
                rate: deposito.orderDepositoDTO.rateOrder,
                tanggalPerpanjangan: deposito.orderDepositoDTO.rolloverDate,
                tenor: deposito.orderDepositoDTO.tenor,
                tenorTypeId: deposito.orderDepositoDTO.tenorTypeId,
                rolloverTypeId: deposito.orderDepositoDTO.rolloverTypeId,
                rekbankId: deposito.orderDepositoDTO.rekBankId,
                maturityDatePerpanjangan: deposito.orderDepositoDTO.maturityDate,
                bilyetNo: deposito.orderDepositoDTO.bilyetNo,
                nominalPokok: dataEfek.faceValue,
                acruedToday: deposito.orderDepositoDTO.bungaPelepasan,
                instrumentName: data.categoryName,
                nilaiTransfer: deposito.orderDepositoDTO.bungaPelepasan
            }
            dataDetail.push(depositoInfo)
        }
        if (type === 'edit') {
            this.formPengajuanPerpanjanganDepositoEdit.current.show(data)
            this.formPengajuanPerpanjanganDepositoEdit.current.retrieveData(data, dataDetail, kajian, deposito)
        }
    }
    customDetailElem = (data) => {
        return (
            <DetailPerpanjanganDeposito detailData={data.requestId} store={this.props.store} />
        )
    }

    forceRefresh = () => {
        this.DataGridDepositoRef.current.forceRefresh(true)
    }

    // loadData = async () => {
    //     let loadAPI = `order-requests-deposito-perpanjangan/list?status=DRAFT&status=INPROGRESS`;
    //     let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
    //     let data = [];

    //     for (let valueI of response) {
    //         let loadDataDTO = `order-requests-deposito/${valueI.requestId}`;
    //         let responseDTO = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadDataDTO, 'GET');

    //         for (let valueJ of responseDTO.orderDepositoInfos) {
    //             let dataValue = {
    //                 ...valueI,
    //                 acruedToday: valueJ.orderDepositoDTO.bungaPelepasan
    //             }
    //             data.push(dataValue);
    //         }
    //     }

    //     return data;
    // }
    loadData = async () => {
        try {
            const loadAPI = `order-requests-deposito-perpanjangan/list?status=DRAFT&status=INPROGRESS`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
    
            for (let valueI of response) {
                const loadDataDTO = `order-requests-deposito/${valueI.requestId}`;
                const responseDTO = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadDataDTO, 'GET');
    
                const accruedTodayValues = responseDTO.orderDepositoInfos.map(valueJ => valueJ.orderDepositoDTO.bungaPelepasan);

                if (accruedTodayValues.length > 0) {
                    // valueI.acruedToday = accruedTodayValues.map(value => parseInt(value, 10)).join(', ');
                    valueI.acruedToday = parseInt(accruedTodayValues[0], 10);
                }
            }
    
            return response;
        } catch (error) {
            console.error('Error loading data:', error);
            return [];
        }
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Usulan Perpanjangan Investasi Deposito</h2>
                <DevExpressDataGrid
                    keyField="requestId"
                    ref={this.DataGridDepositoRef}
                    loadAPI='order-requests-deposito-perpanjangan/list?status=DRAFT&status=INPROGRESS'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    useArraySource = {true}
                    ArraySourceData = {this.loadData}

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Deposito"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Deposito'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}


                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPerpanjanganDeposito
                    ref={this.formPengajuanPerpanjanganDeposito}
                    store={this.props.store}
                    actionType={'add'}
                    forceRefresh={this.forceRefresh}
                />
                <FormPengajuanPerpanjanganDeposito
                    ref={this.formPengajuanPerpanjanganDepositoEdit}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'edit'}
                />
            </div>
        )
    }
}

export default PengajuanPerpanjanganDeposito