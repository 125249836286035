import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import httpRequest from 'plugin/httprequest'
import { Redirect } from 'react-router';
import notify from 'devextreme/ui/notify'
import { pensiunType } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import { formatDate } from 'plugin/helper';

class KlaimMp extends Component {
    constructor(props){
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        
        this.state = {
            dataMaster: {
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            }
        }

        this.columns = [
            {
                dataField: 'no',
                caption: 'No',
                alignment: 'center',
                cellRender: (e) => {
                    return e.rowIndex + 1
                },
                width: "50px"
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'klaimDate',
                caption: 'Tanggal Klaim',
                dataType: "date",
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            }
        ]
    }

    render(){
        return (
            <div className='container-fluid'>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`view-klaim-mps?klaimDate.equals=${formatDate(this.state.dataMaster.startDate)}&statusId.equals=2`}
                    insertAPI=''
                    updateAPI=''
                    deleteAPI=''

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    // useArraySource = {true}
                    // ArraySourceData = {this.retrieveData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    height={'auto'}

                    exportExcel={false}
                    exportFileName={"Pengajuan Peserta Pensiun Ditunda"}
                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={5}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Peserta Pensiun Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default KlaimMp;