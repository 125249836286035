import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, kpds, product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import JurnalLaporanTransaksiInvestasi from 'components/pages/modal/investasi/laporan/transaksi/jurnal'
import ArrayStore from 'devextreme/data/array_store';

class LaporanTransaksiPerPengelola extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                productId: 1,
                noKontrak: 'Swakelola',
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }
        this.modalJurnalRef = React.createRef()
        this.formRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Detail Jurnal',
                        onClick: async (e) => {
                            this.modalJurnal(e.row.data);
                        },
                    },
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'settleNo',
                caption: 'No Settlement'
            },
            {
                dataField: 'noKontrak',
                caption: 'KPD',
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'transactionName',
                caption: 'Nama Transaksi',
            },
            {
                dataField: 'instrument',
                caption: 'Instrument / Bilyet No',
            },
            {
                dataField: 'faceValue',
                caption: 'Face Value',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'quantity',
                caption: 'Kuantitas',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'harga',
                caption: 'Harga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nilaiTransaksi',
                caption: 'Nilai Transaksi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.productCode + ' - ' + e.productName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'noKontrak',
                        label: {
                            text: 'Pengelola',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'noKontrak',
                            displayExpr: 'noKontrak',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'startDate',
                        label: {
                            text: 'Tanggal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'endDate',
                        label: {
                            visible: false
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]
    }

    componentDidMount = () => {
        this.lookupKpd()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    lookupKpd = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kpds', 'GET')
        let data = new ArrayStore({
            data: response,
            key: 'id'
        });
        if (response) {
            this.formRef.current.instance.getEditor('noKontrak').option('dataSource', data)
        }
    }
    modalJurnal = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-report-transaksi-per-pengelola-detils?transactionId.in=${data.transactionId}`)
        this.modalJurnalRef.current.show(response)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Transaksi Per Pengelola</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            ref={this.formRef}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    loadAPI={`view-report-transaksi-per-pengelolas?noKontrak.in=${this.state.filterData.noKontrak}&productId.in=${this.state.filterData.productId}&transactionDate.greaterThanOrEqual=${formatDate(this.state.filterData.startDate)}&transactionDate.lessThanOrEqual=${formatDate(this.state.filterData.endDate)}&size=9999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Transaksi per Pengelola"}
                    allowExportSelectedData={true}
                    exceptFieldExportFormtNum={
                        [
                            'quantity',
                            'harga',
                            'nilaiTransaksi'
                        ]
                    }
                    selection={'multiple'}

                    showBorders={true}
                    exportWithFormatNum={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <JurnalLaporanTransaksiInvestasi
                    ref={this.modalJurnalRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default LaporanTransaksiPerPengelola