import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ShowModalPengkinianPesertaRegistrasiAktif from 'components/pages/modal/kepesertaan/pengkinianPeserta/aktif/showModalRegistrasiAktif';
import { addURL } from 'redux/actions/url';
import { pengkinianType, statusPeserta } from 'dataSource/lookup';
import ModalInsertPengkinianAktif from 'components/pages/modal/kepesertaan/pengkinianPeserta/aktif/modalInsert'
import httpRequest from 'plugin/httprequest';
import { formatDate, compareObj } from 'plugin/helper';
import { confirmAlert } from 'react-confirm-alert'
import { alert } from 'devextreme/ui/dialog'
import { showLoading } from 'redux/actions/loading';

class RegistrasiPengkinianAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.popupPesertaRegistrasiAktif = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPengkinianPesertaRegistrasiAktif(e.row.data.pesertaId, e.row.data)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Membatalkan Pengkinian Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.deleteData(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Verifikasi Pengkinian Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.kirim(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Kirim Ke Registrasi Pasif',
                        hint: 'Kirim Ke Registrasi Pasif',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Mengirim Peserta Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.kirimPasif(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'createdDate',
                caption: 'Tanggal Pengkinian',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'pengkinianTypeId',
                caption: 'Tipe Pengkinian',
                lookup: {
                    dataSource: pengkinianType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName',
                }
            },
            {
                dataField: 'nik',
                name: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                name: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pesertaStatusId',
                caption: 'Peserta Status',
                lookup: {
                    dataSource: statusPeserta(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName',
                }
            },
            {
                dataField: 'online',
                caption: 'Media'
            },
            {
                dataField: 'updatedNote',
                caption: 'Keterangan'
            }
        ]
    }

    renderButton = (button) => {
        return <div style={{ padding: 20 }}><p>{button.text}</p></div>;
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengkinianPesertaRegistrasiAktif = (pesertaId, dataPengkinian) => {
        this.popupPesertaRegistrasiAktif.current.show()
        this.popupPesertaRegistrasiAktif.current.retrieveData(pesertaId, dataPengkinian)
    }
    showModalInsert = () => {
        this.modalRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Pengkinian',
                    onClick: (e) => {
                        this.showModalInsert(e)
                    }
                }
            }
        )
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    deleteData = async (data) => {
        try {
            var deleteAPI = 'pengkinian-peserta-bo-aktifs'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, deleteAPI, 'DELETE', data.id)

            this.forceRefresh()

            await alert('Data Berhasil Dihapus!', 'Success')
        } catch (e) {
            console.log(e)
            await alert(e, 'Failed')
        }
    }

    kirimPasif = async (data) => {
        var dataPengkinian = data
        var updateParam = JSON.parse(dataPengkinian.updateParam)
        dataPengkinian.updatedDate = formatDate(new Date())
        dataPengkinian.updatedStatus = true
        if (dataPengkinian) {
            this.props.store.dispatch(showLoading(true))
            try {
                var updateAPI = 'pengkinian-peserta-bo-aktifs'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPengkinian.id,
                    values: dataPengkinian
                },
                    dataPengkinian)
                if (updateParam) {
                    var dataPersonal = updateParam.dataPersonal
                    var dataKepegawaian = updateParam.dataKepegawaian
                    var dataKeluarga = updateParam.dataKeluarga
                    var dataAkun = updateParam.dataAkun

                    dataPersonal.statusPeserta = '3'

                    var responsePersonal = await this.submitDataPersonal(dataPersonal)
                    if (responsePersonal) {
                        var pesertaId = responsePersonal.id
                        await this.submitDataKepegawaian(pesertaId, dataKepegawaian)
                        await this.submitDataKeluarga(pesertaId, dataKeluarga)
                        await this.submitDataAkun(dataAkun, pesertaId, '', dataPersonal)
                    }
                }

                if (response) {
                    var loadAPIProduct = `kepersertaan-products/getByPesertaId/${response.pesertaId}`
                    var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct, 'GET')
                    if (getAkun.length !== 0) {
                        for (var value of getAkun) {
                            var dataAkun = { ...value }
                            var updateAkun = `kepersertaan-products`
                            dataAkun.akunStatusId = 6
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAkun, 'PUT', {
                                key: dataAkun.id,
                                values: dataAkun
                            },
                                dataAkun)
                        }
                    }
                }
                this.props.store.dispatch(showLoading(false))
                var text = `Data berhasil dikirim!`
                await alert(text, 'Success')
                this.forceRefresh()
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }

    kirim = async (data) => {
        var dataPengkinian = data
        var updateParam = JSON.parse(dataPengkinian.updateParam)
        dataPengkinian.updatedDate = formatDate(new Date())
        dataPengkinian.updatedStatus = true
        if (dataPengkinian) {
            this.props.store.dispatch(showLoading(true))
            try {
                var updateAPI = 'pengkinian-peserta-bo-aktifs'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPengkinian.id,
                    values: dataPengkinian
                }, dataPengkinian)

                if (updateParam) {
                    var dataPersonal = updateParam.dataPersonal
                    var dataKepegawaian = updateParam.dataKepegawaian
                    var dataKeluarga = updateParam.dataKeluarga
                    var dataAkun = updateParam.dataAkun

                    var responsePersonal = await this.submitDataPersonal(dataPersonal)
                    if (responsePersonal) {
                        var pesertaId = responsePersonal.id
                        await this.submitDataKepegawaian(pesertaId, dataKepegawaian)
                        await this.submitDataKeluarga(pesertaId, dataKeluarga)
                        await this.submitDataAkun(dataAkun, pesertaId, '', dataPersonal)
                        // let submitKepegawaian = await this.submitDataKepegawaian(pesertaId, dataKepegawaian)
                        // if (submitKepegawaian) {
                        //     let submitKeluarga = await this.submitDataKeluarga(pesertaId, dataKeluarga)
                        //     if (submitKeluarga) {
                        //         let submitAkun = await this.submitDataAkun(dataAkun, pesertaId, dataPersonal)

                        //         if (submitAkun) {

                        //         }
                        //     } else {
                        //         await alert("Gagal Mengirim Data Keluarga", 'Failed')
                        //     }
                        // } else {
                        //     await alert("Gagal Mengirim Data Kepegawaian", 'Failed')
                        // }

                    }
                }

                if (response) {
                    var loadAPIProduct = `kepersertaan-products/getByPesertaId/${response.pesertaId}`
                    var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct, 'GET')
                    if(updateParam) {
                        var dataPersonal = updateParam.dataPersonal
                        if (getAkun.length !== 0) {
                            for (var value of getAkun) {
                                var dataAkun = { 
                                    ...value,
                                    mpRekeningNo: dataPersonal.mpRekeningNo,
                                    mpRekeningName: dataPersonal.mpRekeningName,
                                    mpRekeningBankId: dataPersonal.mpRekeningBankId,
                                    mpRekeningCabang: dataPersonal.mpRekeningCabang,
                                    paymentRekbankId: dataPersonal.paymentRekbankId
                                }
                                var updateAkun = `kepersertaan-products`
                                dataAkun.akunStatusId = 6
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAkun, 'PUT', {
                                    key: dataAkun.id,
                                    values: dataAkun
                                },
                                    dataAkun)
                            }
                        }
                    } else {
                        if (getAkun.length !== 0) {
                            for (var value of getAkun) {
                                var dataAkun = {
                                    ...value,
                                    mpRekeningNo: null,
                                    mpRekeningName: null,
                                    mpRekeningBankId: null,
                                    mpRekeningCabang: null,
                                    paymentRekbankId: null
                                }
                                var updateAkun = `kepersertaan-products`
                                dataAkun.akunStatusId = 6
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAkun, 'PUT', {
                                    key: dataAkun.id,
                                    values: dataAkun
                                },
                                    dataAkun)
                            }
                        }
                    }
                }

                var text = `Data berhasil dikirim!`

                this.props.store.dispatch(showLoading(false))
                await alert(text, 'Success')
                this.forceRefresh()
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }

    submitDataPersonal = async (data) => {
        var dataPersonal = data
        if (dataPersonal) {
            try {
                var updateAPI = 'pesertas'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPersonal.id,
                    values: dataPersonal
                },
                    dataPersonal)
                return response
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }

    submitDataKepegawaian = async (pesertaId, data) => {
        var dataKepegawaian = data
        if (data) {
            var loadAPI = `peserta-kepegawaians/getByPesertaId/${pesertaId}`
            var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            dataKepegawaian.id = getKepegawaian.id
            dataKepegawaian.pesertaId = pesertaId
            try {
                if (!dataKepegawaian.id) {
                    var insertAPI = 'peserta-kepegawaians'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                        values: dataKepegawaian
                    }
                    )
                } else {
                    var updateAPI = 'peserta-kepegawaians'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                        key: dataKepegawaian.id,
                        values: dataKepegawaian
                    },
                        dataKepegawaian)

                }
                return response
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }

    removeArray = (arr, value) => {
        arr.filter(ele => {
           return ele != value;
        })
    } 

    submitDataKeluarga = async (pesertaId, data) => {
        var dataKeluarga = data.dataKeluarga
        var deletedDataKeluarga = data.deletedDataKeluarga
        var currentData = data.currentDataKeluarga
        var API = 'peserta-families'
        if (data.length !== 0) {
            for (var value of dataKeluarga) {
                value.filePenghasilan = value.filePenghasilanData
                value.fileStatusMenikah = value.fileStatusMenikahData
                value.fileStatusSekolah = value.fileStatusSekolahData
                value.pesertaId = pesertaId
                try {
                    if (typeof value.id === "string") {
                        delete value.id
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'POST', {
                            values: value
                        })
                    } else {
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'PUT', {
                            values: value,
                            key: value.id
                        }, value)
                    }

                    // if (currentData.length !== 0 || value.id.length !== 36) {
                    //     var selectedData = currentData.find(cur => cur.id === value.id)

                    //     if (!selectedData) {
                    //         delete value.id
                    //         var insertAPI = 'peserta-families'
                    //         var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                    //             values: value
                    //         }
                    //         )
                    //     } else {
                    //         if (compareObj(value, selectedData)) {
                    //             var updateAPI = 'peserta-families'
                    //             var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    //                 values: value,
                    //                 key: value.id
                    //             },
                    //                 value)
                    //         }
                    //         // else{
                    //         //     delete value.id
                    //         //     var insertAPI = 'peserta-families'                    
                    //         //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                    //         //             values:value
                    //         //         }
                    //         //     )
                    //         // }
                    //     }
                    // } else {
                    //     delete value.id
                    //     var insertAPI = 'peserta-families'
                    //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                    //         values: value
                    //     }
                    //     )
                    // }

                    // if (deletedDataKeluarga) {
                    //     var loadAPI = 'peserta-families'
                    //     for (var values of deletedDataKeluarga) {
                    //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'DELETE', values)
                    //     }
                    // }
                } catch (e) {
                    console.log(e)
                    await alert(e, 'Failed Add or Edit Keluarga')
                }
            }
            try {
                // if (deletedDataKeluarga) {
                //     let resultMapDelKel = deletedDataKeluarga.map(value => value);
                //     let keluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`, 'GET');
                //     let resultKelId = keluarga.map(value => value.id);

                //     // console.log(resultMapDelKel);
                //     // console.log(resultKelId);

                //     const MergeDataKel = resultMapDelKel.concat(resultKelId);

                //     for (let i = 0; i < resultMapDelKel.length; i++) {
                //         for (let j = 0; j < resultKelId.length; i++) {
                //             if (resultMapDelKel[i] === resultKelId[i]) {
                //                 for (let value of resultMapDelKel) {
                //                     await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'DELETE', value);
                //                 }
                //                 break;
                //             }
                //         }
                //     }

                // }
                if (deletedDataKeluarga) {
                    let keluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`, 'GET');
            
                    for (var values of deletedDataKeluarga) {
                        for (let valueJ of keluarga){
                            if (valueJ.id === values) {
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'DELETE', values)
                                break
                            } 
                        } 
                    }
                }
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed Delete Keluarga')
            }
        }
    }

    submitDataAkun = async (data, pesertaId, actionType, dataPersonal) => {
        for (var value of data) {
            var dataAkun = value
            var pesertaAkunProdukId = dataAkun.id || 0
            var dataIuran = value.dataIuran || []
            var currentData = value.currentDataIuran || []
            var deletedDataIuran = value.deletedDataIuran || []

            var loadAPIPeserta = `pesertas/${pesertaId}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta, 'GET')

            var loadAPIIuranType = 'iuran-types'
            var getIuranType = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIIuranType, 'GET')
            if (dataAkun && dataIuran) {
                dataAkun.pesertaId = pesertaId || 0
                dataAkun.mpRekeningBankId = dataPersonal.rekeningBankId
                dataAkun.mpRekeningNo = dataPersonal.rekeningNo
                dataAkun.mpRekeningName = dataPersonal.rekeningNama
                dataAkun.akunStatusId = dataAkun.akunStatusId || 1
                dataAkun.productId = this.props.product == 'ppmp' ? 1 : dataAkun.productId
                for (var iuran of dataIuran) {
                    var typeIuran = getIuranType.find(val => val.id === iuran.iuranTypeId)
                    if (typeIuran.iuranName == 'Iuran Normal Peserta') {
                        dataAkun.iuranNormalPeserta = iuran.iuranNominal
                    }
                    if (typeIuran.iuranName == 'Iuran Lainnya Peserta') {
                        dataAkun.iuranManfaatLainPeserta = iuran.iuranNominal
                    }
                    if (typeIuran.iuranName == 'Iuran Normal PK') {
                        dataAkun.iuranNormalPk = iuran.iuranNominal
                    }
                    if (typeIuran.iuranName == 'Iuran Lainnya PK') {
                        dataAkun.iuranManfaatLainPk = iuran.iuranNominal
                    }

                    // if(typeIuran.iuranPeserta){
                    //     dataAkun.iuranNormalPeserta  = dataAkun.iuranNormalPeserta + iuran.iuranNominal
                    // }else{
                    //     dataAkun.iuranNormalPk  = dataAkun.iuranNormalPeserta + iuran.iuranNominal
                    // }
                }
                try {
                    if (!dataAkun.id || actionType == 'add') {
                        var insertAPI = 'kepersertaan-products'
                        var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                            values: dataAkun
                        }
                        )
                        pesertaAkunProdukId = responseProduct.id
                    } else {
                        var updateAPI = 'kepersertaan-products'
                        var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                            key: dataAkun.id,
                            values: dataAkun
                        },
                            dataAkun)

                    }
                } catch (e) {
                    console.log(e)
                    await alert(e, 'Failed')
                }
            }
            if (dataIuran) {
                if (dataIuran.length !== 0) {
                    for (var value of dataIuran) {
                        value.participantId = getPeserta.participantId
                        value.pesertaAkunProdukId = pesertaAkunProdukId
                        value.productId = responseProduct.productId
                        if (value.id.length != 36) {
                            try {
                                var updateAPI = 'peserta-akun-produk-iurans'
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                                    values: value,
                                    key: value.id
                                },
                                    value)
                            } catch (e) {
                                console.log(e)
                                await alert(e, 'Failed')
                            }
                        } else {
                            delete value.id
                            try {
                                var insertAPI = 'peserta-akun-produk-iurans'
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                                    values: value
                                }
                                )
                            } catch (e) {
                                console.log(e)
                                await alert(e, 'Failed')
                            }
                        }

                    }
                    try {
                        var deleteAPI = 'peserta-akun-produk-iurans'
                        for (var values of deletedDataIuran) {
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, deleteAPI, 'DELETE', values)
                        }
                    } catch (e) {
                        console.log(e)
                        await alert(e, 'Failed')
                    }
                }
            }
        }
    }

    insertData = async (data) => {
        var dataPengkinian = data
        dataPengkinian.pengkinianDate = formatDate(new Date())
        dataPengkinian.pesertaStatusId = 2
        dataPengkinian.pengkinianTypeId = 1
        dataPengkinian.statusCode = 2
        dataPengkinian.online = false
        dataPengkinian.updatedStatus = false
        try {
            var loadAPI = 'pengkinian-peserta-bo-aktifs'

            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: dataPengkinian
            })
            var text = `Data berhasil ditambah!`
            var title = 'Success'
        } catch (e) {
            console.log(e)
            var text = e
            var title = 'Failed'
        }
        this.forceRefresh()
        await alert(text, title)
    }

    loadData = async () => {
        try {
            let loadAPI = 'view-pengkinian-peserta-bo-aktifs?statusCode.in=2&statusCode.in=3&updatedStatus.in=false&size=9999';
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');
            let data = []

            for (let value of response) {
                let updateParamValue = JSON.parse(value.updateParam);
                if (updateParamValue !== null) {
                    let dataValue = {
                        ...value,
                        fullName: updateParamValue.dataPersonal.fullName
                    }

                    data.push(dataValue)
                } else {
                    data.push(value);
                }
            }

            return data;
        } catch (error) {
            console.log(error);
        }
    }


    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Registrasi</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='view-pengkinian-peserta-bo-aktifs?statusCode.in=2&statusCode.in=3&updatedStatus.in=false'
                        insertAPI='pengkinian-peserta-bo-aktifs'
                        updateAPI='pengkinian-peserta-bo-aktifs'
                        deleteAPI='pengkinian-peserta-bo-aktifs'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        // remoteOperations={true}

                        useArraySource = {true}
                        ArraySourceData = {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ModalInsertPengkinianAktif
                        store={this.props.store}
                        submitData={this.insertData}
                        ref={this.modalRef}
                    />
                </div>
                <ShowModalPengkinianPesertaRegistrasiAktif
                    ref={this.popupPesertaRegistrasiAktif}
                    actionType={'edit'}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default RegistrasiPengkinianAktif
