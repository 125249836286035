import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { programs } from 'dataSource/lookup'
import ModalRekeningInvestasi from '../modal/static-data/investasi/rekening/index'

class RekeningInvestasi extends Component {
    constructor(props) {
        super(props)
        this.dataGridRef = React.createRef()
        this.modalRekeningRef = React.createRef()
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Rekening',
                        hint: 'Rekening',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalRekening(data.id)
                        }
                    },
                ]
            },
            {
                dataField: 'rowNumber',
                caption: 'No.',
                cellRender:(data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                }
            },
            {
                dataField: 'productCode',
                caption: 'Kode Produk'
            },
            {
                dataField: 'productName',
                caption: 'Nama Produk'
            },
            {
                dataField: 'kepesertaan',
                caption: 'KEPESERTAAN',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pemberiKerja',
                        caption: 'Pemberi Kerja',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'aktif',
                        caption: 'Aktif',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'pasif',
                        caption: 'Pasif',
                        dataType: 'number',
                        alignment: 'right'
                    },
                    {
                        dataField: 'arsip',
                        caption: 'Arsip',
                        dataType: 'number',
                        alignment: 'right'
                    },
                ]
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModalRekening = (data) => {
        this.modalRekeningRef.current.show(data)
    }
    forceRefresh =() => {
        this.dataGridRef.current.forceRefresh()
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Rekening Investasi</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='products'
                    insertAPI='staticdata-wf/products'
                    updateAPI='staticdata-wf/products'
                    deleteAPI='staticdata-wf/products' 

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Produk"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Produk Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    remoteOperations = {true}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalRekeningInvestasi
                    ref={this.modalRekeningRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default RekeningInvestasi