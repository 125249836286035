import React, { Component } from 'react';
import { menu } from '../../redux/actions/menu';
import { NavLink } from "react-router-dom";

class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.store = this.props.store;

    this.state = {
      activeMenus: this.props.activeMenus,
      menuList : []
    }

    this.popupChangePassword = React.createRef();
  }

  componentDidMount = () => {
    var currentURL = window.location.pathname.split('/')

    if(currentURL.length > 1){
      this.store.dispatch(menu(currentURL[1]))
      this.setState({
        activeMenus: currentURL[1]
      })
    }
  }

  setTopMenu = (data) => {
    this.setState({
      menuList: [
        ...data
      ]
    })

    
  }

  showModalChangePassword = (e) => {
    e.preventDefault()
    this.popupChangePassword.current.show()
  }

  setSideMenu = (e) => {
    var menuType = e.target.href.replace(window.location.origin,'').replace('/','')
    
    if(menuType !== 'dashboard'){
      e.preventDefault();
    }

    this.setState({
      activeMenus: menuType
    })
    this.store.dispatch(menu(menuType))
  }

  render() {
    var HTMLElem = []

    var menus = [...this.state.menuList]
    menus = menus.sort((a,b) => {
      if(a.text < b.text) { return -1; }
      if(a.text > b.text) { return 1; }
      return 0;
    })
    menus = [{
      menuCode: 'Dashboard',
      text: 'Dashboard',
      url: '/dashboard'
    },...menus]

    const set = new Set()
    const removeDuplicateMenu = menus.filter(menu => {
      const duplicate = set.has(menu.menuCode)
      set.add(menu.menuCode)
      return !duplicate
    })

    for(var menu of removeDuplicateMenu){
        var menuSegment = menu.url.split('/')

        if(menu.menuCode === 'Dashboard'){
          HTMLElem.push(
            <li key={menu.menuCode} className={`nav-item ${this.state.activeMenus === menuSegment[1] ? 'active' : ''}`}>
                <NavLink exact to={menu.url || '#'} className={`nav-link text-top-menu mx-2`} onClick={(e) => {this.setSideMenu(e)}}>
                    {menu.text}
                </NavLink>
            </li>
          )
        }else{
          HTMLElem.push(
              <li key={menu.menuCode} className={`nav-item ${this.state.activeMenus === menuSegment[1] ? 'active' : ''}`}>
                  <a className={`nav-link text-top-menu mx-2`} href={menu.url} onClick={(e) => {this.setSideMenu(e)}}>
                      {menu.text}
                  </a>
              </li>
          )
        }

    }

    return (
      <React.Fragment>
        <div className="menu-top">
          <nav className="navbar navbar-expand-lg navbar-light py-0">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                    {HTMLElem}
                </ul>
            </div>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}

export default TopMenu;
