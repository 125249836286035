import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest'
import { managerInvestasis, custodies, emitens, currency, instrumentTypes, instrumentsCategoryEtf, instrumentsCategoryObli, ratings, instrumentCategories, frekuensis, instrumentsCategorySaham } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';

class FormModalInstruSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            newData: false,
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.updateData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.DataMaster = [
            {
                itemType: "group",
                items: [

                    {
                        dataField: 'instrumentCode',
                        label: {
                            text: 'Kode Saham'
                        }
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            text: 'Nama Saham'
                        }
                    },
                    {
                        dataField: 'isinCode',
                        label: {
                            text: 'Isin Code'
                        }
                    },
                    {
                        dataField: 'categorySubId',
                        label: {
                            text: 'Sub Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentsCategorySaham(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    var dataSub = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-category-subs/${e.value}`)
                                    this.formRef.current.instance.getEditor('categoryId').option('value', dataSub.instrumentCategoryId)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'categoryId',
                        label: {
                            text: 'Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentCategories(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'status',
                        label: {
                            text: 'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Active'
                                },
                                {
                                    id: 2,
                                    value: 'Delisting'
                                },
                                {
                                    id: 3,
                                    value: 'Jatuh Tempo'
                                },
                                {
                                    id: 4,
                                    value: 'Suspend'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'emitenId',
                        label: {
                            text: 'Emiten'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: emitens(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'emitenName', // provides display values
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'custodyId',
                        label: {
                            text: 'Custody'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'custodyName', // provides display values
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Currency'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'currencyCode', // provides display values
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'listedDate',
                        label: {
                            text: 'Tanggal Terdaftar',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'haircut',
                        label: {
                            text: 'Haircut (%)',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        },
                    },
                    {
                        dataField: 'syariah',
                        label: {
                            text: 'Syariah'
                        },
                        editorType: 'dxCheckBox'
                    },
                ]
            },
        ]
    }

    newData = (newData) => {
        this.setState({
            newData: newData
        })
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster: data,
        })
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate()
        return formValidation.isValid
    }
    submitData = async () => {
        var data = this.state.DataMaster
        if (data) {
            try {
                let type = 'success'
                let text = 'Sukses input !'
                var insertAPI = `staticdata-wf/instruments`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: data,
                })
                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    updateData = async () => {
        try {
            let type = 'success'
            let text = 'Sukses ubah !'
            var updateAPI = `staticdata-wf/instruments`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                values: this.state.DataMaster,
                key: this.state.DataMaster.id
            }, this.state.DataMaster)

            if (response) {
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.hide()
            } else {
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
            newData: false,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'55vw'}
                    height="50vh"
                    title={`Form Saham`}
                    ref={this.PopupRef}
                    toolbarItems={this.state.newData ? this.popupToolbarItem2 : this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={1}
                            ref={this.formRef}
                            id={'DataMaster'}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation={"left"}
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalInstruSaham