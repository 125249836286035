import React, { Component } from 'react'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import FormPegawai from './comp/formPegawai';
import { Form } from "devextreme-react";
import { statusPegawai } from 'dataSource/lookup'
import { addURL } from 'redux/actions/url';

class DataSDM extends Component {
    constructor(props){
        super(props)

        this.state = {  
            selectedTabIndex: 0,
            formDataMain: {}
        }

        this.formItemsMain = [
            {
                dataField: 'namaPegawai',
                label: {
                    text: 'Nama Pegawai',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'nomorInduk',
                label: {
                    text: 'Nomor Induk',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'statusPegawai',
                label: {
                    text: 'Status Pegawai',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusPegawai(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    // searchEnabled: true,
                    // deferRendering: false
                }
            },
            {
                dataField: 'kodeSidikJari',
                label: {
                    text: 'Kode Sidik Jari',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
                readOnly : true
            },
        ]

        this.TabDataSource = [
            {
                id : 0,
                text: 'Pegawai'
            },
            {
                id : 1,
                text: 'Keluarga Pegawai'
            },
            {
                id : 2,
                text: 'Penghasilan'
            },
            {
                id : 3,
                text: 'Payroll'
            },
            {
                id : 4,
                text: 'Daftar Keseluruhan'
            },
            {
                id : 5,
                text: 'Sarana Cetak'
            },
        ]  

        this.formPegawaiRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Sumber Daya Manusia (SDM)</h3>

                <div className="my-5">
                    <Form
                        colCount={4}
                        id={'form'}
                        formData={this.state.formDataMain}
                        items={this.formItemsMain}
                        scrollingEnabled={true}
                        ref={this.formRef}
                    />
                </div>

                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div className={(this.state.selectedTabIndex !== 0 ? 'd-none' : '') + ' mt-2 text-center' } style={{height: '100%'}}>
                    <FormPegawai store={this.props.store} ref={this.formPegawai}/>
                </div>
            </div>
        )
    }
}

export default DataSDM
