import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalGrade from 'components/pages/modal/sdm-umum/pengaturan/grade/form';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';

class GradeSDM extends Component {
    constructor(props){
        super(props)
        
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalGrade(e)
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.delete(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField:'gradeCode',
                caption:'Kode Grade'
            },
            {
                dataField:'gradeName',
                caption:'Nama Grade'
            },
            {
                dataField:'range',
                caption:'Rata Rata (%)',
                dataType:'number',
                format: "#0.##'%'",
            },
            {
                dataField:'gajiPokok',
                caption:'Gaji Pokok',
                alignment:'center',
                columns:[
                    {
                        dataField:'gajiMin',
                        caption:'Min Point',
                        alignment:'right',
                        dataType: 'number',
                        format:'#,##0.00'
                    },
                    {
                        dataField:'gajiMid',
                        caption:'Mid Point',
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                    {
                        dataField:'gajiMax',
                        caption:'Max Point',
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                ]
            },
            {
                dataField:'desc',
                caption:'Keterangan'
            },
        ]

        this.showModalGradeRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.selectedRowKeys = []
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalGrade = (e) => {
        if (e.row) {
            this.showModalGradeRef.current.retrieveData(e.row.data)
        }
        this.showModalGradeRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'trash',
                    text: 'hapus',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin ingin menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: () => {
                                        this.selectedDelete(e)
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ]
                        })
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus', 
                    onClick: (e) => {
                    this.showModalGrade(e)
                    },
                }
            }
        )
      }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    delete = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'grades', 'DELETE', data.id)
            this.forceRefresh()
            notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }

    selectedDelete = async () => {
        var errorMessage = []
        if (this.selectedRowKeys.length === 0) {
            notify({message: "Silahkan pilih yang akan di hapus",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',3000);
            return null
        }
        
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'grades', 'DELETE', this.selectedRowKeys[i])
            } catch (e){
                console.log(e);
                errorMessage.push('Sebagian data tidak bisa dihapus, karena sudah digunakan')
            }

        }
        
        if (errorMessage.length > 0) {
            notify({message: errorMessage.join('\n'),width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
            this.forceRefresh()
            return
        }

        this.forceRefresh()
        notify({message: 'Data Berhasil Dihapus',width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Grade</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='grades'
                    insertAPI='grades'
                    updateAPI='grades'
                    deleteAPI='grades' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Grade"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Grade'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    onSelectionChanged = {this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalGrade 
                    ref={this.showModalGradeRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default GradeSDM