import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { city } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import { formatDate } from 'plugin/helper'
import { productDefault } from 'dataSource/lookup'
import { Form } from 'devextreme-react';
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'


class ReverseTransaksi extends Component {
    constructor(props) {
        super(props)

        this.sysDate = this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                productId: 1,
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.dgRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            // this.showModalViewBiaya(e)
                            console.log('batalkeun');
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.kirimReverse(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                        }
                    },
                ]
            },
            {
                dataField: 'nomorReverse',
                caption: 'Nomor Reverse'
            },
            {
                dataField: 'produk',
                caption: 'Produk'
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelola'
            },
            {
                dataField: 'tglReverse',
                caption: 'Tanggal Reverse',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'transaksiReverse',
                alignment: 'center',
                caption: 'Transaksi Yang Di Reverse',
                columns: [
                    {
                        dataField: 'tipeTransaksi',
                        caption: 'Tipe Transaksi',
                    },
                    {
                        dataField: 'noKonfirmasi',
                        caption: 'No Konfirmasi',
                    },
                    {
                        dataField: 'kodeInstrument',
                        caption: 'Kode Instrument',
                    },
                    {
                        dataField: 'nominalTransaksi',
                        caption: 'Nominal Transaksi',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'statusBankTransaction',
                caption: 'Status'
            }
        ]
        this.FilterItem = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'productId',
                                label: {
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.productCode + ' - ' + e.productName
                                    }
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount: 3,
                        items: [
                            {
                                dataField: "startDate",
                                label: {
                                    text: "Tanggal Reverse",
                                    alignment: "left",
                                    location: "left"
                                },
                                editorType: "dxDateBox",
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: "endDate",
                                label: {
                                    text: "s/d",
                                    alignment: "left",
                                    location: "left"
                                },
                                editorType: "dxDateBox",
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                itemType: "button",
                                buttonOptions: {
                                    text: "Filter",
                                    elementAttr: { class: "bg-dapen-default" },
                                    onClick: () => {
                                        this.filterSubmit()
                                    },
                                },
                                horizontalAlignment: "left"
                            },
                        ]
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    filterSubmit = () => {
        this.setState({
            filterData:this.state.filterData
        })

        this.dgRef.current.forceRefresh(true)
    }
    kirimReverse = async(data) => {
        if(data){
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `bank-transactions/transaksi-reverse-konfirmasi/${data.id}`,
                    'PUT',{
                        key: data.id,
                        values: {}
                    }
                )
                if (response) {
                    this.forceRefresh()
                    notify({ message: 'Sukses Kirim Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    forceRefresh = () => {
        this.dgRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Reverse Transaksi</h2>
                <Form
                    colCount={2}
                    id={'dataMaster'}
                    formData={this.state.filterData}
                    items={this.FilterItem}
                    labelLocation={"left"}
                />
                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI={`bank-transactions/transaksi-reverse-list/${this.state.filterData.productId}/${formatDate(this.state.filterData.startDate)}/${formatDate(this.state.filterData.endDate)}?size=9999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Cities"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'City Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    // remoteOperations = {true}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default ReverseTransaksi