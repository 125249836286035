import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { currency, banks, instrumentSubCategoryDeposito, getInstrumentById, productDefault } from 'dataSource/lookup';
import { summaryDate, formatDefaultDate } from 'plugin/helper';
import Form from 'devextreme-react/form';

class DetailListPersetujuan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataDetail: {}
        }
        this.formDetailRef = React.createRef()
        this.formDetailItem = [
            {
                dataField: 'bankId',
                label: {
                    text: 'Bank'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                },
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: getInstrumentById(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                },
            },
            {
                dataField: 'requestDate',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'tenor',
                label: {
                    text: 'Tenor'
                },
            }
        ]
    }
    componentWillMount = async () => {
        var data = this.props.detailData
        var detailData = []
        var dataProc = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `pengajuanorder-wf/${data.procInstanceId}`, 'GET')
        var dataRequest = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests/${dataProc.entityId}`, 'GET')
        if (dataRequest.categoryId == 3) {
            var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-deposito/${dataRequest.id}`)
            detailData = {
                ...dataProc,
                ...dataRequest,
                tenor: dataInstrument.orderDepositoInfos[0].orderDepositoDTO.tenor + ' ' + dataInstrument.orderDepositoInfos[0].orderDepositoDTO.tenorTypeId
            }
        } else if (dataRequest.categoryId == 1) {
            var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-stok/${dataRequest.id}`)
            detailData = {
                ...dataProc,
                ...dataRequest
            }
        }else if (dataRequest.categoryId == 2){
            var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-rd-dtls/${dataRequest.id}`)
            detailData = {
                ...dataProc,
                ...dataRequest
            }
        }else{
            detailData = {
                ...dataProc,
                ...dataRequest
            }
        }
        var instrumentId = []
        var bankId = []
        if(detailData.data.instrumentStokList){
            instrumentId = detailData.data.instrumentStokList[0].instrumentId
        }else if(detailData.data.instrumentRdList){
            instrumentId = detailData.data.instrumentRdList[0].instrumentId
        }else if(detailData.data.instrumentObligasiList){
            instrumentId = detailData.data.instrumentObligasiList[0].instrumentId
            bankId = detailData.data.instrumentObligasiList[0].bankId
        }else if(detailData.data.instrumentDepositoList){
            bankId = detailData.data.instrumentDepositoList[0].bankId
        }
        this.setState({
            dataDetail: {
                requestNo: detailData.requestNo,
                requestDate: detailData.requestDate,
                productId: detailData.productId,
                instrumentId: instrumentId,
                bankId: bankId,
                tenor: detailData.tenor
            }
        })
    }
    render() {
        return (
            <Form
                colCount={4}
                id={'formDetail'}
                ref={this.formDetailRef}
                formData={this.state.dataDetail}
                items={this.formDetailItem}
                scrollingEnabled={false}
                labelLocation={"left"}
                readOnly={true}
            />
        )
    }
}

export default DetailListPersetujuan