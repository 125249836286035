import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { participants, product } from 'dataSource/lookup';
import { formatNumberAum, joinDuplicateData } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import { showLoading } from 'redux/actions/loading';

class ModalRekapRiwayatPenerimaanIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {}
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        
        this.prevTabIndex = 0
        this.PopupRef = React.createRef()
        this.dataGridDetailRef = React.createRef()

        this.summary = {
            totalItems: [
                {
                    column: 'receiveOutstanding',
                    summaryType: 'sum',
                    valueFormat: '#,##0.00'
                },
                {
                    column: 'nominalIuran',
                    summaryType: 'sum',
                    valueFormat: '#,##0.00'
                },
            ]
        }

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formMaster = [
            {
                itemType: "group",
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'productId',
                        label:{
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField : 'periode',
                        label : {
                            text: 'Periode',
                        }
                    },
                ]
            },
            {
                itemType: "group",
                cssClass: 'mt-3',
                caption: "Iuran Peserta",
                items: [
                    {
                        dataField: 'pesertaAktif',
                        label: {
                            alignment: 'left',
                            text: 'Peserta Aktif'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        }
                    },
                    {
                        dataField: 'iuranNormalPeserta',
                        label: {
                            alignment: 'left',
                            text: 'Iuran Normal Peserta'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'iuranManfaatLainPeserta',
                        label: {
                            alignment: 'left',
                            text: 'Iuran Manfaat Lain Peserta'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                ]
            },
            {
                itemType: "group",
                cssClass: 'mt-3',
                caption: "Iuran Pemberi Kerja",
                items: [
                    {
                        dataField: 'iuranNormalPk',
                        label: {
                            alignment: 'left',
                            text: 'Iuran Normal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'iuranManfaatLainPk',
                        label: {
                            alignment: 'left',
                            text: 'Iuran Manfaat Lain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                ]
            },
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    prosesData = () => {
        this.hide()
    }
    show = () => {
        this.setState({
            popupVisible: true,
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        });
    }

    retrieveData = async(data) => {
        this.props.store.dispatch(showLoading(true))
        var iuranNormalPeserta = 0
        var iuranManfaatLainPeserta = 0

        var iuranNormalPk = 0
        var iuranManfaatLainPk = 0

        var loadAPIuranDetail = `iuran-details/getByIuranId/${data.id}`
        var getIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')

        var pesertaAktif = 0
        var listPesertAktif = []
        // var filter = getIuranDetail.filter(val => val.pesertaAkunProductId ==)
        if(getIuranDetail.length !== 0){
            for(var iuranByType of getIuranDetail){
                switch (iuranByType.iuranTypeId) {
                    case 1:
                        iuranNormalPeserta = iuranNormalPeserta + iuranByType.iuranNominal
                        break;
                    case 2:
                        iuranManfaatLainPeserta = iuranManfaatLainPeserta + iuranByType.iuranNominal
                        break;
                    case 3:
                        iuranNormalPk = iuranNormalPk + iuranByType.iuranNominal
                        break;
                    case 4:
                        iuranManfaatLainPk = iuranManfaatLainPk + iuranByType.iuranNominal
                        break;
                    case 5:
                        iuranManfaatLainPk = iuranManfaatLainPk + iuranByType.iuranNominal
                        break;
                    case 6:
                        iuranManfaatLainPeserta  = iuranManfaatLainPeserta    + iuranByType.iuranNominal
                        break;
                }
                listPesertAktif.push(iuranByType.pesertaAkunProductId)
            }
            pesertaAktif = joinDuplicateData(listPesertAktif)
        }

        var bulan
        switch(data.bulan) {
            case 1:
                bulan = 'Januari'
            break
            case 2:
                bulan = 'Februari'
            break
            case 3:
                bulan = 'Maret'
            break
            case 4:
                bulan = 'April'
            break
            case 5:
                bulan = 'Mei'
            break
            case 6:
                bulan = 'Juni'
            break
            case 7:
                bulan = 'Juli'
            break
            case 8:
                bulan = 'Agustus'
            break
            case 9:
                bulan = 'September'
            break
            case 10:
                bulan = 'Oktober'
            break
            case 11:
                bulan = 'November'
            break
            case 12:
                bulan = 'Desember'
            break
        }
        data.periode = bulan + ' - ' + data.tahun
        this.setState({
            dataMaster: {
                ...data,
                iuranNormalPeserta : iuranNormalPeserta,
                iuranManfaatLainPeserta : iuranManfaatLainPeserta,
                iuranNormalPk : iuranNormalPk,
                iuranManfaatLainPk : iuranManfaatLainPk,
                pesertaAktif : pesertaAktif ? pesertaAktif.length : 0
            }
        });
        this.props.store.dispatch(showLoading(false))
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Penerimaan Iuran'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY : 'scroll', height: '100%'}}>
                    <Form
                        colCount={1}
                        id={'formPemberiKerja'}
                        formData={this.state.dataMaster}
                        items={this.formMaster}
                        labelLocation = {'left'}
                        readOnly={true}
                    />
                </div>
            </Popup>
        )
    }
}

export default ModalRekapRiwayatPenerimaanIuran