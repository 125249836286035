import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { currency, productDefault } from 'dataSource/lookup';


class ModalPortofolioJurnalDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataJurnal: [],
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridKategoriRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.sumDbCr = [
            {
                showInColumn: 'description',
                displayFormat: 'Total :'
            },
            {
                column: 'db',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumDb',
                displayFormat: '{0}'
            },
            {
                column: 'cr',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumCr',
                displayFormat: '{0}'
            },
        ]
        this.kategoriColumns = [
            {
                dataField: 'akunName',
                caption: 'Akun'
            },
            {
                dataField: 'description',
                caption: 'Deskripsi'
            },
            {
                dataField: 'db',
                caption: 'Debit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'cr',
                caption: 'Kredit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName'
                }
            },
        ]
    }
    show(data) {
        this.setState({
            popupVisible: true,
            dataJurnal: data,
        });
        this.dataGridKategoriRef.current.forceRefresh(true);
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataJurnal: [],
        })
    }
    loadData = () => {
        return this.state.dataJurnal
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Jurnal Detail'}
                width={'70vw'}
                height={'70vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <DevExpressDataGrid
                            keyField={'transactionJurnalId'}
                            ref={this.dataGridKategoriRef}
                            loadAPI='kategoris'
                            insertAPI='kategoris'
                            updateAPI='kategoris'
                            deleteAPI='kategoris'

                            backendserver={process.env.REACT_APP_BACKEND_CORE}

                            useArraySource={true}
                            ArraySourceData={this.loadData}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={true}
                            exportFileName={"Jurnal"}
                            allowExportSelectedData={false}
                            selection={false}

                            showBorders={true}

                            paging={false}
                            defaultPageSize={10}
                            height={400}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Jurnal'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.kategoriColumns} // taruh konfigurasi kolom disini
                            // SummaryConfiguration={this.summary}

                            onToolbarPreparing={this.onToolbarPreparing}
                            summaryTotalItem={this.sumDbCr}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalPortofolioJurnalDetail