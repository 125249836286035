import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalPromosi from 'components/pages/modal/sdm-umum/kepegawaian/kinerja/rotasi/formPromosi';
import FormModalPenyesuaianGaji from 'components/pages/modal/sdm-umum/kepegawaian/kinerja/rotasi/penyesuaianGaji';
import { grade, jabatan, karyawan, sdmUnit } from 'dataSource/lookup';
import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { addURL } from 'redux/actions/url';
import { confirm } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'

class Rotasi extends Component {
    constructor(props){
        super(props)
        this.state = {
            redirectBack:false,
        }

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : (e) => {
                            this.showModalPromosi(e)
                        } 
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: "text-info", 
                        onClick : async (e) => {
                            let data = e.row.data
                            var result = await confirm('Apakah anda yakin untuk menghapus data ini?','Konfirmasi')

                            if(result) {
                                this.delete(data)
                            }
                        } 
                    },
                    {
                        text : 'Penyesuaian Gaji',
                        hint : 'Penyesuaian Gaji',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : (e) => {
                            this.setRedirectBack()
                        } 
                    },
                ],
            },
            {
                dataField:"karyawanId",
                caption:"Karyawan",
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"tanggalPengajuan",
                caption:"Tanggal Pengajuan",
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:"sk",
                caption:"Surat Keputusan",
                alignment:'center',
                columns:[
                    {
                        dataField:"noSk",
                        caption:"Nomor"
                    },
                    {
                        dataField:"tanggalSk",
                        caption:"Tanggal SK",
                        dataType:'date',
                        format:'dd MMM yyyy'
                    },
                    {
                        dataField:"tanggalBerlaku",
                        caption:"Tanggal Efektif",
                        dataType:'date',
                        format:'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField:"jabatan",
                caption:"Jabatan",
                alignment:'center',
                columns:[
                    {
                        dataField:"oldJabatanId",
                        caption:"Lama",
                        lookup:{
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:"newJabatanId",
                        caption:"Baru",
                        lookup:{
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                dataField:"unitKerja",
                caption:"Unit Kerja",
                alignment:'center',
                columns:[
                    {
                        dataField:"oldUnitId",
                        caption:"Lama",
                        lookup:{
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                        
                    },
                    {
                        dataField:"newUnitId",
                        caption:"Baru",
                        lookup:{
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                dataField:"grade",
                caption:"Grade",
                alignment:'center',
                columns:[
                    {
                        dataField:"oldGradeId",
                        caption:"Lama",
                        lookup:{
                            dataSource: grade(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'gradeCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:"newGradeId",
                        caption:"Baru",
                        lookup:{
                            dataSource: grade(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'gradeCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                dataField:"gajiPokok",
                caption:"Gaji Pokok",
                alignment:'center',
                columns:[
                    {
                        dataField:"oldGajiPokok",
                        caption:"Lama",
                        dataType:'number',
                        format:'#,##0.00'
                    },
                    {
                        dataField:"newGajiPokok",
                        caption:"Baru",
                        dataType:'number',
                        format:'#,##0.00'
                    },
                ]
            },
        ]

        this.showModalPromosiRef = React.createRef()
        this.showModalPenyesuaianGajiRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    delete = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'karyawan-promosis', 'DELETE', data.id)
            this.forceRefresh()
            notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }

    showModalPromosi = (e) => {
        if (e.row) {
            this.showModalPromosiRef.current.retrieveData(e.row.data)
        }
        this.showModalPromosiRef.current.show()
    }

    showModalPenyesuaianGaji = (e) => {
        if (e.row) {
            this.showModalPenyesuaianGajiRef.current.retrieveData(e.row.data)
        }
        this.showModalPenyesuaianGajiRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPromosi(e)
                    },
                }
            },
        )
    }

    
    setRedirectBack = () => {
        this.setState({
            redirectBack: true
        })
    }

    RedirectBack = () => {
        if (this.state.redirectBack) {
            return <Redirect to='/sdm-umum/kepegawaian/karyawan/gaji-tunjangan' />
        }
    }

    render() {
        return (
            <React.Fragment>
            {this.RedirectBack()}
                <div className="container-fluid">
                    <h2 className="main-title">Promosi</h2>

                    <DevExpressDataGrid
                        ref = {this.dataGridRef}
                        loadAPI='karyawan-promosis'
                        insertAPI='karyawan-promosis'
                        updateAPI='karyawan-promosis'
                        deleteAPI='karyawan-promosis' 
                        backendserver={process.env.REACT_APP_BACKEND_HRD}
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Rotasi"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Promosi'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        onToolbarPreparing={this.onToolbarPreparing}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalPromosi 
                        ref={this.showModalPromosiRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    />
                    <FormModalPenyesuaianGaji 
                        ref={this.showModalPenyesuaianGajiRef}
                        store={this.props.store}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default Rotasi