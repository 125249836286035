import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { akunHR, tunjanganGroup, tunjanganHrd } from 'dataSource/lookup';
import FormModalTipeCuti from 'components/pages/modal/sdm-umum/pengaturan/cuti/tipeCuti';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { confirm } from 'devextreme/ui/dialog';

class TipeCuti extends Component {
    constructor(props){
        super(props)

        this.dataGridRef = React.createRef()
        this.showModalAddTipeCutiRef = React.createRef()
        this.showModalEditTipeCutiRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : async (e) => {
                            let data = e.row.data
                            var result = await confirm('Apakah anda yakin untuk menghapus data ini?','Konfirmasi')
                            if(result) {
                                this.delete(data)
                            }
                        }
                    },
                ],
            },
            {
                dataField:"cutiCode",
                caption:"Kode"
            },
            {
                dataField:"cutiName",
                caption:"Tipe Cuti" 
            },
            // {
            //     dataField:"akumulasiCuti",
            //     caption:"Akumulasi Cuti",
            //     lookup:{
            //         dataSource: [
            //             {
            //                 id: true,
            //                 value: 'Yes'
            //             },
            //             {
            //                 id: false,
            //                 value: 'No'
            //             }
            //         ],
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'value', // provides display values
            //         searchEnabled: true,
            //         deferRendering: false,
            //     },
            // },
            {
                dataField:"cutiDitetapkan",
                caption:"Cuti Di Tetapkan",
                lookup:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                
            },
            {
                dataField:"maxHariPeriode",
                caption:"Max Hari Periode (Hari)",
                editorType: 'dxNumberBox',
                editorOptions: {
                    step:0
                } 
            },
            {
                dataField:"uangCuti",
                caption:"Uang Cuti",
                lookup:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            // {
            //     dataField:"tipeTransaksi",
            //     caption:"Tipe Transaksi",
            // },
            // {
            //     dataField:"asalUangCuti",
            //     caption:"Asal Uang Cuti",
            //     editorType: 'dxTagBox',
            //     lookup:{
            //         dataSource: tunjanganHrd(this.props.store),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: function (item) {
            //             return item && item.tunjanganCode + " - " + item.tunjanganName;
            //         },
            //         searchEnabled: true,
            //         deferRendering: false,
            //     }, 
            // },
            {
                dataField:"uangCutiPercent",
                caption:"Prosentase Gapok + Tunjangan Tetap (%)",
                format: "#,##0",
                alignment:"right",
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0",
                    step:0
                }
            },
            {
                dataField: 'cutiTahunan',
                caption: 'Cuti Tahunan',
                alignment:"center",
                dataType:'boolean'
            },
            {
                dataField:"akumulasiCuti",
                caption:"Akumulasi Cuti",
                lookup:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }, 
            },
            // {
            //     dataField:"akunId",
            //     caption:"Akun ",
            //     lookup:{
            //         dataSource: akunHR(this.props.store),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'akunName', // provides display values
            //         searchEnabled: true,
            //         deferRendering: false,
            //     }
            // },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'trash',
                    text: 'hapus',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin ingin menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: () => {
                                        this.selectedDelete(e)
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ]
                        })
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalAddTipeCuti(e)
                    },
                }
            }
        )
    }

    showModalEditTipeCuti = (e) => {
        if (e.row) {
            this.showModalEditTipeCutiRef.current.retrieveData(e.row.data)
        }
        this.showModalEditTipeCutiRef.current.show()
    }

    showModalAddTipeCuti = (e) => {
        this.showModalAddTipeCutiRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    delete = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'cuti-types', 'DELETE', data.id)
            this.forceRefresh()
            notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }

    selectedDelete = async () => {
        var errorMessage = []
        if (this.selectedRowKeys.length === 0) {
            notify({message: "Silahkan pilih yang akan di hapus",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',3000);
            return null
        }
        
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'cuti-types', 'DELETE', this.selectedRowKeys[i])
            } catch (e){
                console.log(e);
                errorMessage.push('Sebagian data tidak bisa dihapus, karena sudah digunakan')
            }

        }
        
        if (errorMessage.length > 0) {
            notify({message: errorMessage.join('\n'),width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
            this.forceRefresh()
            return
        }

        this.forceRefresh()
        notify({message: 'Data Berhasil Dihapus',width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }


    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Tipe Cuti</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='cuti-types'
                    insertAPI='cuti-types'
                    updateAPI='cuti-types'
                    deleteAPI='cuti-types' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"Tipe Cuti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Cuti'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    onSelectionChanged = {this.onSelectionChanged}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalTipeCuti 
                    ref={this.showModalAddTipeCutiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'add'}
                />
                <FormModalTipeCuti 
                    ref={this.showModalEditTipeCutiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'edit'}
                />
            </div>
        )
    }
}


export default TipeCuti