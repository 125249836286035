import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import { karyawan, jabatan, tipeCuti, unit, sdmUnit, programs, periodeCuti, citiesFilter, provinces, kecamatansFilter, cities, tunjanganHrd, tunjangaCutinHrd } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { formatUploadFileData, imageSource, getSystemDate, formatDate } from 'plugin/helper';
import notify from 'devextreme/ui/notify'
import FormTglSpesifik from './formTgl';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import { alert } from 'devextreme/ui/dialog';
import { showLoading } from 'redux/actions/loading';

class FormPengajuanCuti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataPengajuan: {
                tglInterval: true,
                programId: 1,
            },
            popupVisible: false,
            uploadedData: [],
            DataDocument: {},
            maxDayPeriod: 0,
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalTglSpesifikRef = React.createRef()
        this.fileUploderRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.disabledEvent = false
        this.activeDateKaryawan = ''
        this.getSisaCuti = []
        this.getSisaCuti1 = []
        this.periodeCuti = ''
        this.jumlahHariCuti = 0;
        this.karyawanCutiId = 0;
        this.periodeCutiId = 0;
        this.deleteDataSpesifik = [];
        this.currentData = [];
        this.dataTgl = []

        this.elementAttrClass = this.props.type === 'view' ? "bd-input-popup-disabled-dapen-default" : "bd-input-popup-dapen-default"

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    elementAttr: { class: "bg-button-popup-save-dapen-default" },
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        } else {
                            notify({ message: "Terdapat kesalahan input", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                        }
                    }
                },
                visible: this.props.type == 'view' ? false : true,
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    elementAttr: { class: "bg-button-popup-close-dapen-default" },
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
        
        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    elementAttr: { class: "bg-button-popup-save-dapen-default" },
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.updateData()
                        } else {
                            notify({ message: "Terdapat kesalahan input", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                        }
                    }
                },
                visible: this.props.type == 'view' ? false : true,
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    elementAttr: { class: "bg-button-popup-close-dapen-default" },
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.formPengajuanCuti = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            alignment: 'left',
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            },
                            elementAttr: { class: this.elementAttrClass },
                        },
                    },
                    {
                        dataField: 'noPengajuan',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true,
                            elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
                        }
                    },
                    {
                        dataField: 'tanggalPengajuan',
                        label: {
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy',
                            elementAttr: { class: this.elementAttrClass },
                            min: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal pengajuan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'karyawanId',
                        label: {
                            text: 'Karyawan',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: karyawan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: function (item) {
                                return item && "NIK : " + item.nik + " - " + item.fullName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    this.activeDateKaryawan = e.selectedItem.workingDate
                                    if (this.props.type == 'add') {
                                        this.formMasterRef.current.instance.updateData({ jabatanId: e.selectedItem.jabatanId, unitId: e.selectedItem.unitId, periodeCutiId: null, cutiTypeId: null, sisaCuti: 0 })
                                    } else {
                                        this.formMasterRef.current.instance.updateData({ jabatanId: e.selectedItem.jabatanId, unitId: e.selectedItem.unitId })
                                    }
                                }
                            },
                            elementAttr: { class: this.elementAttrClass },
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Karyawan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'jabatanId',
                        label: {
                            text: 'Jabatan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true,
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
                        },
                    },
                    {
                        dataField: 'unitId',
                        label: {
                            text: 'Unit'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true,
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
                        },
                    },
                    {
                        dataField: 'periodeCutiId',
                        label: {
                            text: 'Periode Cuti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: periodeCuti(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'namaPeriodeCuti', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.periodeCuti = e.selectedItem.namaPeriodeCuti
                                }
                            },
                            elementAttr: { class: this.elementAttrClass },
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Periode cuti tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'cutiTypeId',
                        label: {
                            text: 'Tipe Cuti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeCuti(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'cutiName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    var karyawan = this.formMasterRef.current.instance.getEditor('karyawanId').option('value')
                                    var periode = this.formMasterRef.current.instance.getEditor('periodeCutiId').option('value')
                                    var tipeCuti = this.formMasterRef.current.instance.getEditor('cutiTypeId').option('value')

                                    var tahun = this.periodeCuti

                                    var getKarTypeId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/get-by-karyawanId/${karyawan}`, 'GET');

                                    if (getKarTypeId.length > 0) {
                                        for (let value of getKarTypeId) {
                                            if (this.props.type === 'edit'){
                                                this.getSisaCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/getPerhitunganSisaCutiByFunc/${karyawan}/${this.periodeCutiId}/${this.karyawanCutiId}/${tipeCuti}`, 'GET')
                                            } else {
                                                this.getSisaCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/getPerhitunganSisaCutiByFunc/${karyawan || 0}/${periode || 0}/0/${tipeCuti}`, 'GET')  
                                                // this.getSisaCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/getPerhitunganSisaCutiByFunc/${karyawan || 0}/${value.periodeCutiId || 0}/0`, 'GET')  
                                            } 
                                        }
                                    } else {
                                        this.getSisaCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/getPerhitunganSisaCutiByFunc/${karyawan || 0}/0/0/${tipeCuti}`, 'GET')
                                    }
                                    
                                    for (let value of this.getSisaCuti) {
                                        if (!this.disabledEvent) {
                                            this.formMasterRef.current.instance.updateData('sisaCuti', value.jumlah_sisa)
                                        } else {
                                            this.formMasterRef.current.instance.updateData('sisaCuti', value.jumlah_sisa)
                                        }
                                    }
                                     // this.getSisaCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/perhitunganSisaCuti/${karyawan || 0}/${karyawan || 0}/${e.selectedItem.id}/${tahun}`, 'GET')
                                }
                            },
                            elementAttr: { class: this.elementAttrClass },
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tipe cuti tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'sisaCuti',
                        label: {
                            text: 'Sisa Cuti Terakhir'
                        },
                        editorOptions: {
                            readOnly: true,
                            elementAttr: { class: "bd-input-popup-disabled-dapen-default" },
                        },
                        validationRules: [
                            {
                                type: 'range',
                                min: 0,
                                message: 'Jumlah hari cuti melebihi sisa cuti, mohon kurangi jumlah hari cuti anda'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'TUNJANGAN CUTI',
                items: [
                    {
                        dataField: 'tunjanganCuti',
                        label: {
                            text: 'Tunjangan Cuti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: true,
                                    value: 'Yes'
                                },
                                {
                                    id: false,
                                    value: 'No'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            onValueChanged: (e) => {
                                let val = e.value
                                if (val == false) {
                                    this.formMasterRef.current.instance.getEditor('tunjanganTypeId').option('readOnly', true)
                                    this.formMasterRef.current.instance.getEditor('tglPembayaran').option('readOnly', true)
                                    this.formMasterRef.current.instance.updateData({
                                        tunjanganTypeId: 0,
                                        tglPembayaran: null,
                                    })
                                } else {
                                    this.formMasterRef.current.instance.getEditor('tunjanganTypeId').option('readOnly', false)
                                    this.formMasterRef.current.instance.getEditor('tglPembayaran').option('readOnly', false)
                                }
                            },
                            elementAttr: { class: this.elementAttrClass },
                        }
                    },
                    {
                        dataField: 'tunjanganTypeId',
                        label: {
                            text: 'Tipe Tunjangan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: tunjanganHrd(this.props.store),
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'tunjanganName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            elementAttr: { class: this.elementAttrClass },
                            readOnly: this.props.type === 'view' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tipe tunjangan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'tglPembayaran',
                        label: {
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy',
                            elementAttr: { class: this.elementAttrClass },
                            readOnly: this.props.type === 'view' ? true : false,
                            min: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                        }
                    },
                    {
                        dataField: 'catatan',
                        label: {
                            text: 'Catatan'
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {
                            elementAttr: { class: "bd-input-popup-area-dapen-default" },
                            readOnly: this.props.type === 'view' ? true : false
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'TANGGAL CUTI',
                items: [
                    {
                        dataField: 'tglInterval',
                        label: {
                            text: 'Tipe Tanggal'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: true,
                                    value: 'Tanggal Interval'
                                },
                                {
                                    id: false,
                                    value: 'Tanggal Spesifik'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            readOnly: true,
                            elementAttr: { class: "bd-input-popup-disabled-dapen-default" }
                        },
                        visible: false
                    },
                    {
                        dataField: 'lokasiCuti',
                        label: {
                            text: 'Lokasi Cuti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: true,
                                    value: 'Dalam Negeri'
                                },
                                {
                                    id: false,
                                    value: 'Luar Negeri'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            onValueChanged: (e) => {
                                if (!e.value) {
                                    this.formMasterRef.current.instance.getEditor('provinceId').option({ readOnly: true, value: null })
                                    this.formMasterRef.current.instance.getEditor('cityId').option({ readOnly: true, value: null })
                                    this.formMasterRef.current.instance.updateData({
                                        provinceId: 0,
                                        cityId: 0,
                                    })
                                } else {
                                    this.formMasterRef.current.instance.getEditor('provinceId').option('readOnly', false)
                                    this.formMasterRef.current.instance.getEditor('cityId').option('readOnly', false)
                                }
                            },
                            elementAttr: { class: this.elementAttrClass },
                        }
                    },
                    {
                        dataField: 'provinceId',
                        label: {
                            alignment: 'left',
                            text: 'Provinsi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: provinces(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'provinceName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (data) => {
                                var selectCity = this.formMasterRef.current.instance.getEditor('cityId')
                                // selectCity.option('value', '')
                                if (data.value) {
                                    selectCity.option('dataSource', citiesFilter(this.props.store, data.value, true))
                                }
                            },
                            elementAttr: { class: this.elementAttrClass },
                            readOnly: this.props.type === 'view' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Provinsi tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'cityId',
                        label: {
                            alignment: 'left',
                            text: 'Kota'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: cities(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'cityName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            elementAttr: { class: this.elementAttrClass },
                            readOnly: this.props.type === 'view' ? true : false
                            // onValueChanged: (data) => {
                            //     var selectKecamatan = this.formMasterRef.current.instance.getEditor('identityKecamatanId')
                            //     // selectKecamatan.option('value', '')
                            //     if(data.value){
                            //         selectKecamatan.option('dataSource',kecamatansFilter(this.props.store,data.value,true))
                            //     }
                            // }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Kota tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'alamat',
                        label: {
                            alignment: 'left',
                            text: 'Alamat'
                        },
                        editorOptions: {
                            elementAttr: { class: this.elementAttrClass },
                            readOnly: this.props.type === 'view' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Alamat tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            alignment: 'left',
                            text: 'No.Telp'
                        },
                        editorType: "dxTextBox",
                        editorOptions: {
                            maxLength: 12,
                            elementAttr: { class: this.elementAttrClass },
                            readOnly: this.props.type === 'view' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                pattern: /^\(?([0-9]{0,12})\)?$/,
                                message: 'No Telepon tidak valid karena mengandung huruf'
                            },
                        ]
                    },
                ]
            }
        ]

        this.summary = [
            {
                showInColumn: 'tglAkhir',
                displayFormat: 'TOTAL HARI :'
            },
            {
                column: 'jumlahHari',
                summaryType: 'sum',
                valueFormat: '#,##0',
                name: 'jumlahHari',
                displayFormat: '{0}'
            }
        ]
    }
    generateNoPengajuan = async () => {
        var generateNo = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `generate-no-automatic/KaryawanCuti/pengajuanNo/DAFTARCUTI`,
            'POST', { values: {} }
        )

        this.formMasterRef.current.instance.updateData('noPengajuan', generateNo)
    }

    getTipeTunjangan = async () => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'tunjangan-types?size=9999', 'GET');

        data = data.filter(value => value.status === true && value.tunjanganCuti === true);

        this.formMasterRef.current.instance.getEditor('tunjanganTypeId').option('dataSource', data);
    }

    getDataGridColumnConfiguration = (actionType) => {
        var columnConfiguration = [
            {
                dataField: 'tglAwal',
                caption: 'Tanggal Dari',
                dataType: 'date',
                format: "dd MMM yyyy",
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'tglAkhir',
                caption: 'Tanggal Sampai',
                dataType: 'date',
                format: "dd MMM yyyy",
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'jumlahHari',
                label: {
                    text: 'Jumlah Hari'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    readOnly: true,
                    onValueChanged: (e) => {
                        var jumlahHariCuti = e.value
                        // this.jumlahHariCuti = e.value
                        // if (!this.disabledEvent) {
                        //     this.formMasterRef.current.instance.updateData('sisaCuti', this.getSisaCuti[0].jumlah_sisa - jumlahHariCuti)
                        // }
                    }
                }
            },
        ]

        if (actionType === 'view') {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: 'Buka',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                this.modalTglSpesifikRef.current.retrieveData(data)
                                // this.modalTglSpesifikRef.current.show(data)
                            }
                        },
                    ]
                },
            )
            return columnConfiguration
        } else {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: 'Ubah',
                            // hint: 'Edit',
                            onClick: (e) => {
                                var data = e.row.data
                                this.modalTglSpesifikRef.current.retrieveData(data)
                                this.modalTglSpesifikRef.current.getSisaCuti(this.getSisaCuti);
                            }
                        },
                        {
                            text: 'Hapus',
                            onClick: (e) => {
                                // this.deleteTglSpesifikAPI(e.row.data)
                                this.deleteTglSpesifik(e.row.key)
                            }
                        },
                    ]
                },
            )

            // return columnConfiguration
        }
        return columnConfiguration
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        // this.getSisaCuti = []
        this.activeDateKaryawan = ''
        this.periodeCuti = ''
        this.disabledEvent = false
        this.setState({
            popupVisible: false,
            dataPengajuan: {
                programId: 1
            },
            uploadedData: [],
            DataDocument: {},
        })
        if (this.props.type === 'add') {
            // this.cleanDataGrid()
            this.dataTgl = []
            this.dataGridRef.current.forceRefresh(true)
        }
    }

    getDaysArray(start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };

    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans?size=9999`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        var filteredData = getKaryawan.filter(val => val.programId == programId)
        let dataSource = new ArrayStore({
            data: filteredData,
            key: 'id'
        })
        this.formMasterRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }

    cleanDataGrid = () => {
        var data = this.dataGridRef.current.getDataSource()
        var deleteData = []

        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            this.dataGridRef.current.push(deleteData)
        }
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocument };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}File`] = formattedFileData.base64data;
                DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;
                this.setState({
                    DataDocument: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    previewFile = async (contentType, base64) => {
        console.log(base64);
        // var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        var src = imageSource(contentType, base64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    getWeekDaysInMonth = (year, month) => {
        const Days = [];
        let date = new Date(year, month, 1);
        while (date.getMonth() === month) {
            if (![0, 6].includes(date.getDay())) Days.push(date.getDate());
            date.setDate(date.getDate() + 1);
        }
        
        return Days;
    }

    submitData = async () => {
        // this.props.store.dispatch(showLoading(true))
        var lokasiCuti = this.formMasterRef.current.instance.getEditor('lokasiCuti').option('value')
        var tunjanganCuti = this.formMasterRef.current.instance.getEditor('tunjanganCuti').option('value')
        var data = this.dataGridRef.current.getDataSource()
        var periode = this.formMasterRef.current.instance.getEditor('periodeCutiId').option('value')
        let diffDate = Math.abs(new Date(this.activeDateKaryawan) - new Date(getSystemDate(this.props.store)))
        let diffTime = Math.ceil(diffDate / (1000 * 60 * 60 * 24))
        
        if (diffTime < 365) {
            this.props.store.dispatch(showLoading(false))
            notify({ message: 'Masa Kerja Belum Mencukupi', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            return
        }

        if((lokasiCuti === true || lokasiCuti ===  false) && (tunjanganCuti === true || tunjanganCuti === false)) {
            if(data.length > 0) {
                var sumHari = data.reduce((acc, cur) => acc.jumlahHari + cur.jumlahHari)
    
                var dataMaster = this.state.dataPengajuan
                dataMaster.tglInterval = true;
                var dokumen = this.state.DataDocument
                var dataTglSpesifik = this.dataGridRef.current.getDataSource()
        
                let allDateArr = [];
                for(let valTanggal of dataTglSpesifik) {
                    let range = this.getDaysArray(new Date(valTanggal.tglAwal), new Date(valTanggal.tglAkhir))
                    let tglArr = [];
                    for (let r of range) {
                        const day = r.getUTCDay()
                        if (day !== 6) {
                            if (day !== 0) {
                                tglArr.push(formatDate(r));
                            }
                        }
                    }
                    let data = {
                        statusId: 1,
                        tglCuti: tglArr
                    }
        
                    allDateArr.push(data);
                }
        
                var submitData = {
                    id: 0,
                    ...dataMaster,
                    ...dokumen,
                    listTanggal: allDateArr
                }
                
                var checkAvailableTunjanganCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/active-pengajuan-with-tunjangan-cuti/${dataMaster.karyawanId}/${periode}`, 'GET');
        
                // let cekTunjangan = checkAvailableTunjanganCuti.find(value => value.tunjanganCuti === true);
                // if (cekTunjangan !== undefined && dataMaster.tunjanganCuti && cekTunjangan.tunjanganCuti) {
                //     this.props.store.dispatch(showLoading(false))
                //     await alert('Karyawan ini sudah mendapatkan Tunjangan Cuti', 'Pemberitahuan')
                //     return null;
                // }
                if (sumHari > this.getSisaCuti[0].jumlah_sisa) {
                    this.props.store.dispatch(showLoading(false))
                    await alert('Jumlah cuti melebihi sisa cuti', 'Pemberitahuan!');
                    return null
                } 
                if (submitData) {
                    this.props.store.dispatch(showLoading(true))
                    try {
                        var response = await httpRequest(
                            process.env.REACT_APP_BACKEND_HRD,
                            this.props.store,
                            `send-pengajuan-karyawan-cuti`,
                            'POST',
                            { values: submitData }
                        )
                        if (response) {
                            this.props.store.dispatch(showLoading(false))
                            this.hide()
                            this.props.forceRefresh()
                            notify({ message: `Sukses mengajukan Cuti!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        }
                    } catch (error) {
                        this.props.store.dispatch(showLoading(false))
                        notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } else {
                await alert('Lengkapi data terlebih dahulu', 'Informasi')
            }
        } else {
            await alert('Tunjangan dan lokasi cuti harus diisi', 'Informasi')
        }
    }

    updateData = async () => {
        var data = this.dataGridRef.current.getDataSource()
        var lokasiCuti = this.formMasterRef.current.instance.getEditor('lokasiCuti').option('value')
        var tunjanganCuti = this.formMasterRef.current.instance.getEditor('tunjanganCuti').option('value')
        let diffDate = Math.abs(new Date(this.activeDateKaryawan) - new Date(getSystemDate(this.props.store)))
        let diffTime = Math.ceil(diffDate / (1000 * 60 * 60 * 24))
        if (diffTime < 365) {
            this.props.store.dispatch(showLoading(false))
            notify({ message: 'Masa Kerja Belum Mencukupi', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            return
        }
        
        if((lokasiCuti === true || lokasiCuti ===  false) && (tunjanganCuti === true || tunjanganCuti === false)) {
            if(data.length > 0) {
                var sumHari = data.reduce((acc, cur) => acc.jumlahHari + cur.jumlahHari)
    
                var dataMaster = this.state.dataPengajuan
                var dokumen = this.state.DataDocument
                var dataTglSpesifik = this.dataGridRef.current.getDataSource()
                
                let allDateArr = [];
                for(let valTanggal of dataTglSpesifik) {
                    let range = this.getDaysArray(new Date(valTanggal.tglAwal), new Date(valTanggal.tglAkhir))
                    let tglArr = [];
                    for (let r of range) {
                        const day = r.getUTCDay()
                        if (day !== 6) {
                            if (day !== 0) {
                                tglArr.push(formatDate(r));
                            }
                        }
                    }
                    let data = {
                        statusId: 1,
                        tglCuti: tglArr
                    }
        
                    allDateArr.push(data);
                }
        
                var submitData = {
                    id: dataMaster.id,
                    ...dataMaster,
                    ...dokumen,
                    listTanggal: allDateArr
                }
        
        
                var checkAvailableTunjanganCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/get-by-karyawanId/${dataMaster.karyawanId}`, 'GET');
        
                // let cekTunjangan = checkAvailableTunjanganCuti.find(value => value.tunjanganCuti === true);
                // if (cekTunjangan !== undefined && dataMaster.tunjanganCuti && cekTunjangan.tunjanganCuti) {
                //     this.props.store.dispatch(showLoading(false))
                //     await alert('Karyawan ini sudah mendapatkan Tunjangan Cuti', 'Pemberitahuan')
                //     return null;
                // }
                if (sumHari > this.getSisaCuti[0].jumlah_sisa) {
                    this.props.store.dispatch(showLoading(false))
                    await alert('Jumlah cuti melebihi sisa cuti', 'Pemberitahuan!');
                    return null 
                } 
                if (submitData) {
                    this.props.store.dispatch(showLoading(true))
                    try {
                        var response = await httpRequest(
                            process.env.REACT_APP_BACKEND_HRD,
                            this.props.store,
                            `send-pengajuan-karyawan-cuti`,
                            'POST',
                            { values: submitData }
                        )
                        if (response) {
                            this.props.store.dispatch(showLoading(false))
                            this.hide()
                            this.props.forceRefresh()
                            notify({ message: `Sukses mengajukan Cuti!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        }
                    } catch (error) {
                        this.props.store.dispatch(showLoading(false))
                        notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } else {
                await alert('Lengkapi data terlebih dahulu', 'Infomrasi')
            }
        } else {
            await alert('Lokasi cuti harus diisi', 'Informasi')
        }
    }


    // Jangan di hapus
    // updateData = async () => {
    //     this.props.store.dispatch(showLoading(true))
    //     var data = this.dataGridRef.current.getDataSource()
    //     var sumHari = data.reduce((acc, cur) => acc.jumlahHari + cur.jumlahHari)
    //     let diffDate = Math.abs(new Date(this.activeDateKaryawan) - new Date(getSystemDate(this.props.store)))
    //     let diffTime = Math.ceil(diffDate / (1000 * 60 * 60 * 24))
    //     if (diffTime < 365) {
    //         this.props.store.dispatch(showLoading(false))
    //         notify({ message: 'Masa Kerja Belum Mencukupi', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    //         return
    //     }
    //     var dataMaster = this.state.dataPengajuan
    //     var dokumen = this.state.DataDocument
    //     var dataTglSpesifik = this.dataGridRef.current.getDataSource()
    //     var listDataTglSpesifik = [];
    //     for (const key of dataTglSpesifik) {
    //         if (typeof key.id === "string") {
    //             delete key.id
    //         }
    //         // key.idCutiDate = 0
    //         key.statusId = 1
    //         listDataTglSpesifik.push(key)
    //     }

    //     var submitData = {
    //         id: dataMaster.id,
    //         ...dataMaster,
    //         ...dokumen,
    //         listTanggal: listDataTglSpesifik
    //     }

    //     var checkAvailableTunjanganCuti = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cutis/get-by-karyawanId/${dataMaster.karyawanId}`, 'GET');

    //     for (let value of checkAvailableTunjanganCuti) {
    //         if (value.tunjanganCuti == false && dataMaster.tunjanganCuti) {
    //             this.props.store.dispatch(showLoading(false))
    //             await alert('Karyawan ini sudah mendapatkan Tunjangan Cuti', 'Pemberitahuan')
    //             return null;
    //         } 
    //     }
    //     if (sumHari > this.getSisaCuti[0].jumlah_sisa) {
    //         this.props.store.dispatch(showLoading(false))
    //         await alert('Jumlah cuti melebihi sisa cuti', 'Pemberitahuan!');
    //         return null
    //     } 
    //     if (submitData) {
    //         try {
    //             var response = await httpRequest(
    //                 process.env.REACT_APP_BACKEND_HRD,
    //                 this.props.store,
    //                 `send-pengajuan-karyawan-cuti`,
    //                 'POST',
    //                 { values: submitData }
    //             )
    //             if (response) {
    //                 this.props.store.dispatch(showLoading(false))
    //                 this.hide()
    //                 this.props.forceRefresh()
    //                 notify({ message: `Sukses mengajukan Cuti!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
    //             }
    //         } catch (error) {
    //             this.props.store.dispatch(showLoading(false))
    //             notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    //         }
    //     }
        
    // }

    retrieveData = async (dataMaster, dataTgl, dataDokumen) => {
        this.karyawanCutiId = dataMaster.id;
        this.periodeCutiId = dataMaster.periodeCutiId;
        this.disabledEvent = true
        this.dataTgl = dataTgl
        if (this.dataTgl) {
            this.dataGridRef.current.forceRefresh(true);
        }
        var cutiType = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `cuti-types/${dataMaster.cutiTypeId}`,
            'GET'
        )

        let dataMasterSpesifik = {
                "id": dataMaster.id,
                "programId": dataMaster.programId,
                "noPengajuan": dataMaster.noPengajuan,
                "tanggalPengajuan": dataMaster.tanggalPengajuan,
                "karyawanId": dataMaster.karyawanId,
                "jabatanId": dataMaster.jabatanId,
                "unitId": dataMaster.unitId,
                "periodeCutiId": dataMaster.periodeCutiId,
                "cutiTypeId": dataMaster.cutiTypeId,
                "tunjanganCuti": dataMaster.tunjanganCuti,
                "tunjanganTypeId": dataMaster.tunjanganTypeId,
                "tglPembayaran": dataMaster.tglPembayaran,
                "catatan": dataMaster.catatan,
                "tglInterval": dataMaster.tglInterval,
                "tglAwal": dataMaster.tglAwal,
                "tglAkhir": dataMaster.tglAkhir,
                "jumlahHariCuti": dataMaster.jumlahHariCuti,
                "lokasiCuti": dataMaster.lokasiCuti,
                "provinceId": dataMaster.provinceId,
                "cityId": dataMaster.cityId,
                "alamat": dataMaster.alamat,
                "noTelp": dataMaster.noTelp
        }

        this.setState({
            dataPengajuan: dataMasterSpesifik,
            DataDocument: dataDokumen
        })

        // if (dataMaster.tglInterval === true) {
        //     this.setState({
        //         dataPengajuan: dataMasterSpesifik,
        //         DataDocument: dataDokumen
        //     })
        // }
        this.disabledEvent = false
    }

    loadDummyData = () => {
        return this.dataTgl
    }

    onRowChange = () => {
        var dataTglSpesifik = this.dataGridRef.current.getDataSource().length
        this.formMasterRef.current.instance.updateData('jumlahHariCuti', dataTglSpesifik)
    }

    modalTglSpesifik = async (data) => {
        var data = this.state.dataPengajuan
        if (data.sisaCuti === 0) {
            await alert('Sisa Cuti anda 0', 'Pemberitahuan')
        } else {
            let dataGridCuti = this.dataGridRef.current.getDataSource();
            let jumlahCuti = []
            if (dataGridCuti.length !== 0) {
                for(let value of dataGridCuti) {
                    jumlahCuti.push(value.jumlahHari ? value.jumlahHari : 0)
                }
                let sumCutiDate = jumlahCuti !== 0 ? jumlahCuti.reduce((a, b) => a + b) : jumlahCuti
                let lastElement = dataGridCuti[dataGridCuti.length - 1].tglAkhir;
                let btsTgl = new Date(lastElement)
                btsTgl.setDate(btsTgl.getDate() + 1)
                this.modalTglSpesifikRef.current.show(new Date(formatDate(btsTgl)), sumCutiDate);
                if(dataGridCuti.length >= 1) {
                    this.modalTglSpesifikRef.current.getDayCount()
                }
            } else {
                this.modalTglSpesifikRef.current.show(this.state.dataPengajuan.tanggalPengajuan, 0);
            }
            // this.modalTglSpesifikRef.current.retrieveData(data, this.getSisaCuti);
            this.modalTglSpesifikRef.current.getSisaCuti(this.getSisaCuti);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: async (e) => {
                        let tanggal = this.formMasterRef.current.instance.getEditor('tanggalPengajuan').option('value')
                        let sisaCuti = this.formMasterRef.current.instance.getEditor('sisaCuti').option('value')
                        if(tanggal && sisaCuti) {
                            this.modalTglSpesifik()
                        } else {
                            if(tanggal === null) {
                                await alert('Tanggal pengajuan harus di isi terlebih dahulu', 'Informasi')
                            } else if(sisaCuti === 0) {
                                await alert('Sisa cuti telah habis', 'Informasi')
                            }
                        }
                    },
                },
                visible: this.props.type == 'view' ? false : true
            }
        )
    }

    submitTglSpesifik = (data) => {
        try {
            if (data.id) {
                this.dataGridRef.current.push([{
                    type: "update",
                    data: {
                        tglAwal: data.tglAwal,
                        tglAkhir: data.tglAkhir,
                        jumlahHari: data.jumlahHari
                    },
                    key: data.id
                }])
            } else {
                this.dataGridRef.current.push([{
                    type: "insert",
                    data: {
                        tglAwal: data.tglAwal,
                        tglAkhir: data.tglAkhir,
                        jumlahHari: data.jumlahHari
                    },
                }])
                var dataTglSpesifik = this.dataGridRef.current.getDataSource().length + 1
                this.formMasterRef.current.instance.updateData('jumlahHari', dataTglSpesifik)
            }
        } catch (error) {
            console.log(error);
        }
    }

    deleteTglSpesifik = (key) => {
        this.dataGridRef.current.push([{
            type: "remove",
            key: key
        }])
        var dataTglSpesifik = this.dataGridRef.current.getDataSource().length - 1
        this.formMasterRef.current.instance.updateData('jumlahHari', dataTglSpesifik)
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    showTitle={true}
                    title={"Form Pengajuan Cuti"}
                    width={"40vw"}
                    height={"90vh"}
                    toolbarItems={this.props.type == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'formPengajuanCuti'}
                                formData={this.state.dataPengajuan}
                                items={this.formPengajuanCuti}
                                labelLocation='left'
                                readOnly={this.props.type == 'view' ? true : false}
                            />
                            <FileUploader
                                disabled={this.props.type == 'view' ? true : false}
                                accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText="Upload File"
                                showFileList={false}
                                name={"dokumen"}
                                value={this.state.uploadedData}
                                ref={this.fileUploderRef}
                            />
                            <div className="row">
                                <label className="col-2">File Name:</label>
                                <label className="col-8">{this.state.DataDocument.dokumenName}</label>
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile(this.state.DataDocument.dokumenFileContentType, this.state.DataDocument.dokumenFile)} className={`btn btn-light btn-sm border rounded bg-button-popup-preview-dapen-default ${this.state.DataDocument.dokumenFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                            </div>
                            <div className="mt-3">
                                <DevExpressDataGrid
                                    ref={this.dataGridRef}
                                    loadAPI='Kinerja'
                                    insertAPI='Kinerja'
                                    updateAPI='Kinerja'
                                    deleteAPI='Kinerja'

                                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                                    useArraySource={true}
                                    ArraySourceData={this.loadDummyData}
                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={true}
                                    exportFileName={"Tanggal Cuti"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}
                                    height={400}
                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Tanggal Cuti'}
                                    popupWidth={450} //masukan dalam ukuran pixel
                                    popupHeight={'auto'} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.getDataGridColumnConfiguration(this.props.type)} // taruh konfigurasi kolom disini

                                    onRowInserted={this.onRowChange}
                                    onRowRemoved={this.onRowChange}

                                    summaryTotalItem={this.summary}

                                    onToolbarPreparing={this.onToolbarPreparing}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </ScrollView>
                    <ModalPreviewPdf
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                    <ModalPreviewImage
                        ref={this.modalPreviewImageRef}
                        store={this.props.store}
                    />
                    <FormTglSpesifik
                        ref={this.modalTglSpesifikRef}
                        store={this.props.store}
                        submitTglSpesifik={this.submitTglSpesifik}
                        tanggalPengajuan={this.state.dataPengajuan.tanggalPengajuan}
                        action={this.props.type}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormPengajuanCuti