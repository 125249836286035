import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import Form from 'devextreme-react/form';
import { Button } from 'devextreme-react';
import ViewDaftarTransaksiPenggajian from 'components/pages/modal/sdm-umum/payroll/gaji/daftarTransaksiPenggajian';
import { months } from 'dataSource/lookup';

class DaftarTransaksiPenggajian extends Component {
    constructor(props){
        super(props)

        this.state = {
            selectedTabIndex: 0,
            DataRekap:{},
            DataMaster:{},
        }

        this.formMaster = [
            {
                dataField:"bulan",
                label:{
                    text:"Bulan",
                    alignment:"left",
                    location:"left",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                    location:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.cicilanPinjaman = [
            {
                dataField:"pinjaman",
                caption:"Pinjaman",
            },
            {
                dataField:"karyawan",
                caption:"Karyawan",
            },
            {
                dataField:"nominalCicilan",
                caption:"Nominal Cicilan",
            },
        ]

        this.iuranPemberiKerja = [
            {
                dataField:"produkDapen",
                caption:"Produk Dapen",
            },
            {
                dataField:"peserta",
                caption:"Peserta",
            },
            {
                dataField:"iuranPeserta",
                caption:"Iuran Peserta",
            },
            {
                dataField:"iuranPemberiKerja",
                caption:"Iuran Pemberi Kerja",
            },
        ]

        this.formRekap = [
            {
                itemType:"group",
                colCount: 1,
                items:[
                    {
                        itemType: "group",
                        name:"periode",
                        caption:"Periode",
                        items: [
                            {
                                dataField:"tahun",
                                label:{
                                    text:"Tahun",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"bulan",
                                label:{
                                    text:"Bulan",
                                    alignment:"left"
                                },
                                editorType:'dxSelectBox',
                                editorOptions:{
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                }
                            },
                        ]
                    },
                    {
                        itemType: "group",
                        name:"gaji",
                        caption:"Gaji",
                        items:[
                            {
                                dataField:"jmlhKaryawan",
                                label:{
                                    text:"Jumlah Karyawan",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"gajiPokok",
                                label:{
                                    text:"Gaji Pokok",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"tunjangan",
                                label:{
                                    text:"Tunjangan",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"rapel",
                                label:{
                                    text:"Rapel",
                                    alignment:"left"
                                }
                            },
                        ]
                    },
                    {
                        itemType: "group",
                        name:"cicilanPinjaman",
                        caption:"Cicilan Pinjaman",
                        items:[
                            {
                                dataField:"jmlhTransaksi",
                                label:{
                                    text:"Jumlah Transaksi",
                                    alignment:"left"
                                }
                            },
                            
                        ]
                    },
                    {
                        itemType: "group",
                        items:[
                            {   
                                dataField:"totalCicilan",
                                label:{
                                    text:"Total cicilan",
                                    alignment:"top",
                                    location:"top"
                                },
                                editorType:"dxDataGrid",
                                editorOptions:{
                                    columns: this.cicilanPinjaman,
                                    showBorders: true,
                                    selection:{
                                        mode:"multiple",
                                        showCheckBoxesMode: 'always',
                                        allowSelectAll:true,
                                        selectAllMode: "allPages"
                                    },
                                    filterRow:{
                                        showOperationChooser:true,
                                        visible:true
                                    }
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType:"group",
                colCount: 1,
                items:[
                    {
                        itemType: "group",
                        name:"lembur",
                        caption:"Lembur",
                        items: [
                            {
                                dataField:"jmlhTransaksi",
                                label:{
                                    text:"Jumlah Transaksi",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"totalLembur",
                                label:{
                                    text:"Total Biaya Lembur",
                                    alignment:"left"
                                }
                            },
                        ]
                    },
                    {
                        itemType: "group",
                        name:"iuranDapen",
                        caption:"Iuran Dapen",
                        items: [
                            {
                                dataField:"jmlhTransaksi",
                                label:{
                                    text:"Jumlah Transaksi",
                                    alignment:"left"
                                }
                            },
                            {
                                dataField:"totalKaryawan",
                                label:{
                                    text:"Total Karyawan",
                                    alignment:"left"
                                }
                            },
                        ]
                    },
                    {
                        itemType: "group",
                        items:[
                            {   
                                dataField:"totalIuranPemberiKerja",
                                label:{
                                    text:"Total Iuran Pemberi Kerja",
                                    alignment:"top",
                                    location:"top"
                                },
                                editorType:"dxDataGrid",
                                editorOptions:{
                                    columns: this.iuranPemberiKerja,
                                    showBorders: true,
                                    selection:{
                                        mode:"multiple",
                                        showCheckBoxesMode: 'always',
                                        allowSelectAll:true,
                                        selectAllMode: "allPages"
                                    },
                                    filterRow:{
                                        showOperationChooser:true,
                                        visible:true
                                    }
                                }
                            },
                        ]
                    },
                ]
            }
        ]

        this.penggajianKaryawanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'View',
                        hint : 'View',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalPenggajianKaryawan()
                        }
                    },
                ],
            },
            {
                dataField:"nik",
                caption:"NIK",
            },
            {
                dataField:"nama",
                caption:"Nama",
            },
            {
                dataField:"jabatan",
                caption:"Jabatan",
            },
            {
                dataField:"unit",
                caption:"Unit",
            },
            {
                dataField:"cabang",
                caption:"Cabang",
            },
            {
                dataField:"gaji",
                caption:"Gaji",
                alignment:"center",
                columns:[
                    {
                        dataField:"gajiPokok",
                        caption:"Gaji Pokok",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:"totalTunjangan",
                        caption:"Total Tunjangan",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:"rapel",
                        caption:"Rapel",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:"totalGaji",
                        caption:"Total Gaji",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField:"potongan",
                caption:"Potongan",
                alignment:"center",
                columns:[
                    {
                        dataField:"cicilanPinjaman",
                        caption:"Cicilan Pinjaman",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:"pajak",
                        caption:"Pajak",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:"iuranDapen",
                        caption:"Iuran Dapen",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:"potonganLainnya",
                        caption:"Potongan Lainnya",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:"totalPotongan",
                        caption:"Total Potongan",
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField:"lembur",
                caption:"Lembur",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:"gajiBersih",
                caption:"Gaji Bersih",
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:"status",
                caption:"Status",
            },
        ]

        this.popupPenggajianKaryawanRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    

    showModalPenggajianKaryawan = () => {
        this.popupPenggajianKaryawanRef.current.show()
    }

    loadData = () => {
        return []
    }
    
    generateGaji = () => {

    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Transaksi Penggajian</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                    <DevExpressDataGrid
                        ref = {this.DataGridPengajuanRef}
                        loadAPI='daftar-penerima-thr'
                        insertAPI='daftar-penerima-thr'
                        updateAPI='daftar-penerima-thr'
                        deleteAPI='daftar-penerima-thr' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData= {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Penggajian Karyawan"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Penggajian Karyawan'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.penggajianKaryawanColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        height = {400}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ViewDaftarTransaksiPenggajian 
                        ref={this.popupPenggajianKaryawanRef}
                        store={this.props.store}
                    />
            </div>
        )
    }
}

export default DaftarTransaksiPenggajian