import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'

import { months, participants, product } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { Form } from "devextreme-react";
import httpRequest from 'plugin/httprequest';

class ModalRiwayatIuran extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                id: 0
            },
            formData: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                dataField: 'iuranIds',
                caption: 'No',
                cellRender : (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField: 'tanggal',
                caption: 'Tanggal',
                dataType:"date",
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'product',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode" // provides display values
                }
            },
            {
                dataField: 'bulan',
                caption: 'Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'tahun',
                caption: 'Tahun',
            },
            // {
            //     dataField: 'iuran',
            //     caption: 'Iuran',
            //     alignment:'center',
            //     columns: [
            //         {
            //             dataField: 'peserta',
            //             caption: 'Peserta',
            //             format : '#,##.00'
            //         },
            //         {
            //             dataField: 'pk',
            //             caption: 'Pemberi Kerja',
            //             format : '#,##.00'
            //         },
            //     ]
            // },
            {
                dataField: 'total',
                caption: 'Total Iuran',
                format : '#,##0.00'
            },
        ]

        this.formItem = [
            {
                dataField: 'fullName',
                label: {
                    text: 'NIK / Nama Karyawan',
                }
            },
            {
                dataField: 'participantId',
                label: {
                    text: 'Pemberi Kerja',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'participantName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {
                id: 0
            }
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = async(data) => { 
        this.setState({
            dataMaster: data,
        })
        var test = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `iurans/getByPesertaId/${this.state.dataMaster.id}`, 'GET')
        console.log(data, test);
    }

    render() {
        
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Riwayat Iuran'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY:'auto',height: '100%'}}>
                    <div className="row">
                        <div className="mt-2 col-md-12">
                        <Form
                            colCount={3}
                            id={'form'}
                            formData={this.state.dataMaster}
                            items={this.formItem}
                            ref={this.formRef}
                            labelLocation={'left'}
                        />
                        </div>
                        <div className="mt-2 col-md-12">
                        <DevExpressDataGrid
                            ref={this.dataGridRef}
                            loadAPI={`iurans/getByPesertaId/${this.state.dataMaster.id}`}
                            insertAPI='iurans'
                            updateAPI='iurans'
                            deleteAPI='iurans' 

                            backendserver={process.env.REACT_APP_BACKEND_CORE}
                            keyField={'iuranIds'}
                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={false}
                            exportFileName={"Data Riwayat Iuran"}

                            allowExportSelectedData={false}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Data Riwayat Iuran'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            // SummaryConfiguration={this.summary}
                            // onToolbarPreparing = {this.onToolbarPreparing}

                            height = {'calc(100vh - 300px)'}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalRiwayatIuran
