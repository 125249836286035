import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { mpPenerimaType, tipePembayaranMp } from 'dataSource/lookup';
import { alert, confirm } from 'devextreme/ui/dialog';
import ModalRegistrasiUpdatePengalihanMp from 'components/pages/modal/kepesertaan/pengkinianPeserta/pengalihan/registrasi/index';
import httpRequest from 'plugin/httprequest';

class RiwayatPengalihanMp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'Rollback',
                        hint: 'Rollback',
                        cssClass: 'text-primary',
                        onClick: async(e) => {
                            const message = await confirm('Anda ingin rollback data ini?', 'Konfirmasi');

                            if (message) {
                                this.rollback(e.row.data.id);
                            }
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'NO',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'tanggalPengalihan',
                caption: 'Tanggal Pengalihan',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'lastMpPenerimaTypeId',
                caption: 'Tipe Penerima Sebelumnya',
                lookup: {
                    dataSource: mpPenerimaType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'penerimaName'
                }
            },
            {
                dataField: 'lastMpPaymentTypeId',
                caption: 'Tipe Pembayaran Sebelum',
                lookup: {
                    dataSource: tipePembayaranMp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName'
                }
            },
            {
                dataField: 'lastPenerimaName',
                caption: 'Nama Penerima Sebelumnya'
            },
            {
                dataField: 'newMpPenerimaTypeId',
                caption: 'Tipe Penerima Baru',
                lookup: {
                    dataSource: mpPenerimaType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'penerimaName'
                }
            },
            {
                dataField: 'newPenerimaName',
                caption: 'Nama Penerima Baru'
            },
            {
                dataField: 'newMpPaymentTypeId',
                caption: 'Tipe Pembayaran Baru',
                lookup: {
                    dataSource: tipePembayaranMp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName'
                }
            },
            {
                dataField: 'keterangan',
                caption: 'Alasan Pengalihan'
            },
        ]
    }

    renderButton = (button) => {
        return <div style={{ padding: 20 }}><p>{button.text}</p></div>;
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalEdit = (dataPengalihan) => {
        this.modalRef.current.show()
        this.modalRef.current.retrieveData(dataPengalihan)
    }
    showModalInsert = () => {
        this.modalRef.current.show()
    }

    rollback = async (dataMpId) => {
        try {
            let loadAPI = `pengalihan-mps/rollbackPengalihan/${dataMpId}`;
            let responseAPI = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');

            if (responseAPI) {
                await alert('Data berhasil di rollback', 'Informasi');
                this.forceRefresh();
            }
        } catch (error) {
            console.log(error);
            await alert(error);
        }
    }

    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'plus',
    //                 text: 'Pengalihan',
    //                 onClick: (e) => {
    //                     this.showModalInsert(e)
    //                 }
    //             }
    //         }
    //     )
    // }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Riwayat Pengalihan MP</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='view-pengalihan-mps?status.in=3&size=9999'
                        insertAPI='pengalihan-mps'
                        updateAPI='pengalihan-mps'
                        deleteAPI='pengalihan-mps'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        remoteOperations = {false}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ModalRegistrasiUpdatePengalihanMp
                    ref={this.modalRef}
                    actionType={'view'}
                    submitData={this.submitData}
                    updateData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default RiwayatPengalihanMp
