import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { karyawan } from 'dataSource/lookup'

class FormPengajuanPemberhentian extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataPemberhentian: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                // onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
          ];

        this.formPengajuan = [
            {
                dataField: 'nik',
                label: {
                    text: 'NIK / Nama Karyawan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'fullName'
                }
            },
            {
                dataField: 'jenisPemberhentiank',
                label: {
                    text: 'Jenis Pemberhentian'
                },
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    format: 'dd MMM yyyy',
                    alignment: 'right'
                }
            },
            {
                dataField: 'nilaiPesagon',
                label:{
                    text: 'Nilai Pesagon',
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    layout: 'vertical',
                    items: [
                        {
                            value: 1,
                            text: '% Gaji'
                        },
                        {
                            value: 2,
                            text: 'Nominal'
                        }
                    ]
                }
            },
            {
                dataField: 'skPemberhentian',
                label: {
                    text: 'No SK Pemberhentian'
                },
            },
            {
                dataField: 'fileSkPemberhentian',
                label: {
                    text: 'File SK Pemberhentian'
                },
                editorType: 'dxFileUploader',
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    // onValueChanged: this.onUploadStarted.bind(this),
                },
            },
            {
                dataField: 'fileSuratPengunduranDiri',
                label: {
                    text: 'File Surat Pengunduran Diri'
                },
                editorType: 'dxFileUploader',
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    // onValueChanged: this.onUploadStarted.bind(this),
                },
            },
            {
                dataField: 'kepesertaanDapen',
                label: {
                    text: 'Kepesertaan Dapen'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    layout: 'vertical',
                    items: [
                        {
                            value: 1,
                            text: 'Pensiun Dipercepat'
                        },
                        {
                            value: 2,
                            text: 'Pensiun Normal'
                        },
                        {
                            value: 3,
                            text: 'Pensiun Ditunda'
                        },
                    ]
                }
            },
            {
                dataField: 'keteranganPemberhentian',
                label : {
                    text: 'Keterangan Pemberhentian'
                },
                editorType: 'dxTextArea',
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    retrieveData = async(data) => {    
        this.setState({
            dataPengajuan: data,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Edit Pemberhentian"}
                width={"55vw"}
                height={"50vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <Form
                        colCount={1}
                        id={'formPengajuanPemebrhentian'}
                        formData={this.state.dataPemberhentian}
                        items={this.formPengajuan}
                        labelLocation = 'left'
                    />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormPengajuanPemberhentian