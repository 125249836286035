import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import { instrumentCategories, instruments, instrumentTransactionTypes, kpds, productDefault } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import ViewJurnalDetails from 'components/pages/modal/investasi/viewJurnalTrans';
import httpRequest from 'plugin/httprequest';

class InvestasiJurnalVoucher extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterItem: {
                produkId: 1,
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                transTypeId: [],
            },
        }

        this.filterItem = [
            {
                dataField: 'produkId',
                label: {
                    text: 'Produk',
                    alignment: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                }
            },
            {
                dataField: 'transTypeId',
                label: {
                    text: 'Tipe Transaksi',
                    alignment: "left",
                    location: "left"
                },
                colSpan: 4,
                editorType: 'dxTagBox',
                editorOptions: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName',
                    searchEnabled: true,
                    openOnFieldClick: true,
                    deferRendering: false,
                    hideSelectedItems: true,
                    width: '100%',
                }
            },
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    type: "default",
                    cssClass: "bg-danger",
                    onClick: () => {
                        this.filterData()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.summary = [
            {
                displayFormat: 'Total :',
                showInColumn: 'instrumentId'
            },
            {
                name: 'quantity',
                column: 'quantity',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'price',
                column: 'price',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nominal',
                column: 'nominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Jurnal',
                        hint: 'Lihat Jurnal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalViewJurnal(e)
                        }
                    },
                ],
            },
            {
                dataField: 'nomor',
                caption: 'No.',
                cellRender: (e) => {
                    return e.rowIndex + 1
                },
            },
            {
                dataField: 'tglTransaksi',
                caption: 'Tanggal transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'typeTransaksi',
                caption: 'Tipe Transaksi',
                alignment: "left",
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelola',
            },
            {
                dataField: 'instBilyet',
                caption: 'Instrument/Bilyet',
            },
            {
                dataField: 'nominalTransaksi',
                caption: 'Nominal Transaksi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'kuantitas',
                caption: 'Kuantitas',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'harga',
                caption: 'Harga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]

        this.showModalViewJurnalRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalViewJurnal = (e) => {
        if (e.row) {
            this.showModalViewJurnalRef.current.retrieveData(e.row.data)
            this.showModalViewJurnalRef.current.newData(true)
        } else {
            this.showModalViewJurnalRef.current.newData(false)
        }

        this.showModalViewJurnalRef.current.show()
    }

    filterData = () => {
        this.setState({
            filterItem: this.state.filterItem
        })

        this.dataGridRef.current.forceRefresh(true)
    }

    loadDummyData = async () => {
        var startDate = formatDate(this.state.filterItem.startDate)
        var endDate = formatDate(this.state.filterItem.endDate)
        var product = this.state.filterItem.produkId
        var typeTrans = this.state.filterItem.transTypeId
        var getAllTypeTrans = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            'instrument-transaction-types',
            "GET"
        );

        if (typeTrans.length == 0 || typeTrans.length == null) {
            var getId = getAllTypeTrans.map((elem) => {
                return elem.id
            }).join(',')
        }


        var loadApi = product && startDate && endDate && typeTrans.length > 0
            ? `transaction-jurnal/get-jurnal-voucher/${product}/${startDate}/${endDate}/${typeTrans.join()}?size=999`
            : product && startDate && endDate
                ? `transaction-jurnal/get-jurnal-voucher/${product}/${startDate}/${endDate}/${getId}?size=999`
                : `transaction-jurnal/get-jurnal-voucher/${product}/${formatDate(new Date())}/${formatDate(new Date())}/${getId}?size=999`

        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadApi,
            "GET"
        );

        return response
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <h3 className="main-title">Jurnal Voucher Investasi</h3>
                    <div className='row'>
                        <div className="col-md-6">
                            <Form
                                colCount={2}
                                id={'formFilter'}
                                formData={this.state.filterItem}
                                items={this.filterItem}
                                labelLocation="left"
                            />
                        </div>
                        <div className="mt-2 col-md-12">
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                loadAPI='kas-banks'
                                insertAPI='kas-banks'
                                updateAPI='kas-banks'
                                deleteAPI='kas-banks'

                                backendserver={process.env.REACT_APP_BACKEND_ACC}

                                useArraySource={true}
                                ArraySourceData={this.loadDummyData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Kas Bank"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Kas Bank'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                summaryTotalItem={this.summary}
                                // onToolbarPreparing = {this.onToolbarPreparing}
                                height={'calc(100vh - 350px)'}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ViewJurnalDetails
                                ref={this.showModalViewJurnalRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                                from={"jurnal"}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default InvestasiJurnalVoucher