import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import { anggaranItem, anggaranItemPerUnit, programs, sdmUnit } from 'dataSource/lookup';
import uuidv4 from 'uuid/v4'
import { confirm, alert } from "devextreme/ui/dialog"
import { capitalizeFirstLetter } from 'plugin/helper';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import ModalEditorBreakdown from './editorBreakdown';

class ModalEditorAnggaran extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {},
        };

        this.form = [
            {
                dataField: "programId",
                label: {
                    text: "Program",
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: programs(this.props.store),
                    displayExpr: 'program',
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                }
            },
            {
                dataField: "unitId",
                label: {
                    text: "Unit",
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                }
            },
            {
                dataField: "tahun",
                label: {
                    text: "Tahun",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: "anggaranItemId",
                label: {
                    text: "Anggaran",
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: anggaranItem(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.anggaranCode + ' - ' + item.anggaranName
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.stepProses !== 'REQ' || this.props.tipeProses === 'perubahan'
                },
                isRequired: true,
            },
        ]

        this.fieldName = this.props.stepProses === 'REQ' ? "requestNote" : this.props.stepProses === 'VER' ? "verifiedNote" : "approvedNote"

        this.formSummary = [
            {
                dataField: this.props.tipeProses === 'input' ? "nominalRequest" : this.props.tipeProses === 'perubahan' && this.props.stepProses === 'REQ' ? 'nominalEfektif' : 'nominalRevised',
                label: {
                    text: "Total Nominal",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: '#,##0.00;(#,##0.00);',
                    readOnly: true
                },
                // validationRules: [
                //     {
                //         type: 'range',
                //         min: 1,
                //         message: 'Total Nominal Harus Lebih dari 0'
                //     }
                // ]
            },
            {
                dataField: this.props.tipeProses === 'perubahan' ? "revised" + capitalizeFirstLetter(this.fieldName) : this.fieldName,
                label: {
                    text: "Catatan",
                },
                editorType: "dxTextArea",
                editorOptions: {
                    height: '200px'
                },
                isRequired: true
            },
        ]

        this.columns = [
            {
                dataField: 'periode',
                caption: 'Periode',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: this.props.tipeProses === 'input' ? 'periodeRequest' : 'nominalRevised',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                editorType: "dxNumberBox",
                editorOptions: {
                    format: '#,##0.00;(#,##0.00);',
                },
                // validationRules: [
                //     {
                //         type: 'range',
                //         min: 1,
                //         message: 'Total Nominal Harus Lebih dari 0'
                //     }
                // ]
            },
        ]

        this.columnBreakdown = [
            {
                type: 'buttons',
                fixed: true,
                fixedPosition: 'right',
                buttons: [
                    'edit',
                    'delete',
                    // {
                    //     icon: 'edit',
                    //     hint: 'Edit',
                    //     visible: () => {
                    //         return this.props.stepProses === 'REQ'
                    //     },
                    //     onClick : (e) => {
                    //         this.modalEditorBreakdownRef.current.show()
                    //         this.modalEditorBreakdownRef.current.retrieveData(e.row.data)
                    //     }
                    // },
                ],
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
            {
                dataField: 'quantity',
                caption: 'Quantity',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                editorOptions: {
                    format: '#,##0.00',
                },
                width: '150px',
                setCellValue: async (newData, value, currentRowData) => {
                    newData.quantity = value
                    newData.total = (value || 0) * (currentRowData.amount || 0)
                },
            },
            {
                dataField: 'amount',
                caption: 'Harga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                editorOptions: {
                    format: '#,##0.00',
                },
                width: '150px',
                setCellValue: async (newData, value, currentRowData) => {
                    newData.amount = value
                    newData.total = (currentRowData.quantity || 0) * (value || 0)
                },
            },
            {
                dataField: 'total',
                caption: 'Total',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                width: '150px',
                allowEditing: false
            },
        ]

        this.prevTabIndex = 0

        this.TabDataSource = [
            {
                id: 0,
                text: "Nilai Anggaran"
            },
            {
                id: 1,
                text: "Breakdown Anggaran"
            }
        ];

        this.formRef = React.createRef()
        this.formSummaryRef = React.createRef()
        this.datagridRef = React.createRef()
        this.datagridBreakdownRef = React.createRef()
        this.modalEditorBreakdownRef = React.createRef()
    }

    PopupToolbarItem = () => {
        var toolbarItem = []

        if (this.props.stepProses === 'REQ' && this.props.tipeProses === 'input') {
            toolbarItem.push(
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Ok",
                        onClick: async () => {
                            this.submitData()
                        }
                    },
                    toolbar: "bottom"
                }
            )
        } else if (this.props.stepProses === 'REQ' && this.props.tipeProses === 'perubahan') {
            toolbarItem.push(
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan Perubahan",
                        onClick: async () => {
                            this.sendRevise()
                        }
                    },
                    toolbar: "bottom"
                }
            )
        } else {
            toolbarItem.push(
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Setujui",
                        onClick: async () => {

                            this.approve()
                        }
                    },
                    toolbar: "bottom"
                }
            )
        }

        toolbarItem.push({
            widget: "dxButton",
            location: "after",
            options: {
                text: "Cancel",
                onClick: this.hide.bind(this)
            },
            toolbar: "bottom"
        })

        return toolbarItem
    }

    retrieveData = (data = null) => {
        this.formRef.current.instance.updateData(data)
        this.formSummaryRef.current.instance.updateData(data)

        const anggaranItemEditor = this.formRef.current.instance.getEditor('anggaranItemId')

        anggaranItemEditor.option('dataSource', anggaranItemPerUnit(this.props.store, data.unitId))

        this.datagridRef.current.forceRefresh(true)
        this.datagridBreakdownRef.current.forceRefresh(true)
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    };

    loadData = async () => {
        if (!this.state.formData.id) {
            const globalParam = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters/get-by-paramCode/PA')
            let periodeCount = 0
            if (globalParam.id) {
                periodeCount = globalParam.intValue
            }

            var datagridData = []
            for (let index = 0; index < periodeCount; index++) {
                datagridData.push({
                    id: uuidv4(),
                    periode: index + 1,
                    periodeRequest: 0
                })

            }

            return datagridData
        }

        var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-periodes/getByAnggaranDetailsId/${this.state.formData.id}`)
        if (this.props.tipeProses === 'input' && this.props.stepProses === 'APP') {
            response = response.map(value => {
                value.nominalEfektif = value.periodeRequest

                return value
            })
        }
        if (this.props.tipeProses === 'perubahan' && this.props.stepProses === 'APP') {
            response = response.map(value => {
                value.nominalEfektif = value.nominalRevised

                return value
            })
        }

        if (this.props.tipeProses === 'perubahan' && this.props.stepProses === 'REQ') {
            response = response.map(value => {
                value.nominalRevised = value.nominalEfektif

                return value
            })
        }
        return response.sort((a, b) => {
            if (a.periode < b.periode) {
                return -1;
            }
            if (a.periode > b.periode) {
                return 1;
            }
            return 0;
        })
    }

    loadDataBreakdown = async () => {
        if (!this.state.formData.id) {
            return []
        }
        var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-breakdowns/get-by-anggaranDetailsId/${this.state.formData.id}`)

        return response
    }

    onRowUpdated = () => {
        const datagridData = this.datagridRef.current.getDataSource()
        let totalAnggaran = 0
        for (const data of datagridData) {
            totalAnggaran += this.props.tipeProses === 'input' ? data.periodeRequest : data.nominalRevised
        }

        const fieldName = this.props.tipeProses === 'input' ? "nominalRequest" : this.props.tipeProses === 'perubahan' && this.props.stepProses === 'REQ' ? 'nominalEfektif' : 'nominalRevised'

        this.formSummaryRef.current.instance.updateData({
            [fieldName]: totalAnggaran
        })
    }

    submitData = async () => {
        const formValidate = this.formRef.current.instance.validate()
        const formSummaryValidate = this.formSummaryRef.current.instance.validate()

        const dataBreakdown = this.datagridBreakdownRef.current.getDataSource()
        var totalBreakdown = dataBreakdown.map(value => value.total).reduce((a, b) => a + b, 0)
        var fieldNameTotal = this.props.tipeProses === 'input' ? "nominalRequest" : this.props.tipeProses === 'perubahan' && this.props.stepProses === 'REQ' ? 'nominalEfektif' : 'nominalRevised'
        if (formValidate.isValid && formSummaryValidate.isValid) {
            if (this.state.formData[fieldNameTotal] !== totalBreakdown) {
                alert('Total breakdown anggaran harus sama dengan total nominal anggaran!', 'Error')
                return null
            }
            var dataDetail = {
                anggaranId: this.state.formData.anggaranId,
                anggaranItemId: this.state.formData.anggaranItemId,
                nominalRequest: this.state.formData.nominalRequest,
                requestBy: this.props.store.getState().sessionUser,
                requestDate: new Date(),
                requestNote: this.state.formData.requestNote,
                status: '1',
                unitId: this.state.formData.unitId,
                programId: this.state.formData.programId
            }

            const submitBreakdown = async (anggaranDetailsId) => {
                var dataDatagrid = this.datagridBreakdownRef.current.getDataSource()
                var dataDeletedDatagrid = this.datagridBreakdownRef.current.getDeletedIndex()

                for (const data of dataDatagrid) {
                    data.anggaranDetailsId = anggaranDetailsId
                    if (typeof data.id === 'string') {
                        delete data.id
                        await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-breakdowns`, 'POST', {
                            values: data
                        })
                    } else {
                        await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-breakdowns`, 'PUT', {
                            values: data
                        }, data)
                    }
                }

                for (const data of dataDeletedDatagrid) {
                    await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-breakdowns`, 'DELETE', data)
                }
            }

            if (!this.state.formData.id) {
                const responseInsertDataDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details`, 'POST', {
                    values: dataDetail
                })
                const dataDetailPeriod = this.datagridRef.current.getDataSource().map(value => {
                    delete value.id
                    value.anggaranDetailsId = responseInsertDataDetail.id
                    return value
                })

                for (const detailPeriod of dataDetailPeriod) {
                    await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-periodes`, 'POST', {
                        values: detailPeriod
                    })
                }

                submitBreakdown(responseInsertDataDetail.id)
            } else {
                dataDetail.id = this.state.formData.id
                const responseInsertDataDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details`, 'PUT', {
                    values: dataDetail
                }, dataDetail)

                const dataDetailPeriod = this.datagridRef.current.getDataSource()

                for (const detailPeriod of dataDetailPeriod) {
                    await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-periodes`, 'PUT', {
                        values: detailPeriod
                    }, detailPeriod)
                }

                await submitBreakdown(dataDetail.id)
            }

            alert('Data Anggaran berhasil disimpan', 'Success')
            this.hide()
            this.props.forceRefresh()
        }
    }

    sendRevise = async () => {
        const formValidate = this.formRef.current.instance.validate()
        const formSummaryValidate = this.formSummaryRef.current.instance.validate()

        if (formValidate.isValid && formSummaryValidate.isValid) {
            const result = await confirm('Apakah anda ingin merevisi data anggaran ini?', 'Confirmation')

            if (result) {
                var dataDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details/${this.state.formData.id}`)

                var dataUpdate = {
                    status: '5',
                    nominalRevised: this.state.formData.nominalEfektif,
                    revisedRequestNote: this.state.formData.revisedRequestNote,
                    revisedRequestBy: this.props.store.getState().sessionUser,
                    revisedRequestDate: new Date(),
                }

                const responseInsertDataDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details`, 'PUT', {
                    values: dataUpdate
                }, dataDetail)

                const dataDetailPeriod = this.datagridRef.current.getDataSource()
                const dataDetailBreakdownPeriod = this.datagridBreakdownRef.current.getDataSource()

                for (const detailBreakdownPeriod of dataDetailBreakdownPeriod) {
                    await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-breakdowns`, 'PUT', {
                        values: detailBreakdownPeriod
                    }, detailBreakdownPeriod)
                }

                for (const detailPeriod of dataDetailPeriod) {
                    await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-periodes`, 'PUT', {
                        values: detailPeriod
                    }, detailPeriod)
                }

                alert('Data Anggaran berhasil direvisi', 'Success')
                this.hide()
                this.props.forceRefresh()
            }
        }
    }

    approve = async () => {
        const formValidate = this.formRef.current.instance.validate()
        const formSummaryValidate = this.formSummaryRef.current.instance.validate()

        if (formValidate.isValid && formSummaryValidate.isValid) {
            let status, tipe
            switch (this.props.stepProses) {
                case 'VER': status = this.props.tipeProses === 'input' ? '3' : '6'
                    tipe = 'verified'
                    break;

                case 'APP': status = '4'
                    tipe = 'approved'
                    break;

            }
            const result = await confirm('Apakah anda ingin menyutujui data anggaran ini?', 'Confirmation')

            if (result) {
                var dataDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details/${this.state.formData.id}`)

                var dataUpdate = {
                    status: status,
                    [`${this.props.tipeProses === 'perubahan' ? "revised" + capitalizeFirstLetter(tipe) : tipe}Note`]: this.state.formData[`${this.props.tipeProses === 'perubahan' ? "revised" + capitalizeFirstLetter(tipe) : tipe}Note`],
                    [`${this.props.tipeProses === 'perubahan' ? "revised" + capitalizeFirstLetter(tipe) : tipe}By`]: this.props.store.getState().sessionUser,
                    [`${this.props.tipeProses === 'perubahan' ? "revised" + capitalizeFirstLetter(tipe) : tipe}Date`]: new Date(),
                }

                if (this.props.stepProses === 'APP') {
                    dataUpdate.nominalEfektif = this.props.tipeProses === 'perubahan' ? dataDetail.nominalRevised : dataDetail.nominalRequest

                    const dataDetailPeriod = this.datagridRef.current.getDataSource()

                    for (const detailPeriod of dataDetailPeriod) {
                        await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details-periodes`, 'PUT', {
                            values: detailPeriod
                        }, detailPeriod)
                    }
                }

                const responseInsertDataDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-details`, 'PUT', {
                    values: dataUpdate
                }, dataDetail)

                alert('Data Anggaran berhasil disimpan', 'Success')
                this.hide()
                this.props.forceRefresh()
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabeditor-anggaran-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabeditor-anggaran-${this.prevTabIndex}`).classList.add('d-none');
        this.datagridRef.current.repaint()
        this.datagridBreakdownRef.current.repaint()

        this.prevTabIndex = args.value
    }

    // onToolbarPreparing = (e) => {
    //     if(this.props.stepProses === 'REQ' && this.props.tipeProses === 'input'){
    //         e.toolbarOptions.items.unshift(
    //           {
    //               location: 'after',
    //               widget: 'dxButton',
    //               options: {
    //                   icon: 'add',
    //                   onClick: (e) => {
    //                       this.modalEditorBreakdownRef.current.show()
    //                   },
    //               }
    //           }
    //         )
    //     }
    // }

    forceRefreshBreakdown = () => {
        this.datagridBreakdownRef.current.forceRefresh(true)
    }

    submitDataBreakdown = (data) => {
        if (data.id) {
            this.datagridBreakdownRef.current.push([{ type: "update", data: data, key: data.id }])
        } else {
            this.datagridBreakdownRef.current.push([{ type: "insert", data: data }])
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    resizeEnabled={true}
                    showTitle={true}
                    title="Editor Anggaran"
                    width={"90vw"}
                    height={"auto"}
                    toolbarItems={this.PopupToolbarItem()}
                >
                    <div className="mb-3">
                        <Form
                            colCount={1}
                            id={"formAnggaran"}
                            formData={this.state.formData}
                            items={this.form}
                            ref={this.formRef}
                            labelLocation="left"
                        // readOnly = {true}
                        />
                        <DevextremeTab
                            dataSource={this.TabDataSource}
                            onOptionChanged={this.onTabSelectionChanged}
                        />
                        <div id="tabeditor-anggaran-0" className={' mt-2 col-md-12 text-center'} >
                            <DevExpressDataGrid
                                ref={this.datagridRef}
                                loadAPI='anggarans'
                                insertAPI='anggarans'
                                updateAPI='anggarans'
                                deleteAPI='anggarans'

                                backendserver={process.env.REACT_APP_BACKEND_ACC}

                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={this.props.stepProses === 'REQ'}
                                exportExcel={false}
                                exportFileName={"Detail Anggaran"}
                                allowExportSelectedData={false}
                                selection={"none"}

                                editingMode={'row'}
                                menuRightClick={false}

                                showBorders={true}

                                paging={false}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Detail Anggaran Data'}
                                popupWidth={'70vw'} //masukan dalam ukuran pixel
                                popupHeight={'400px'} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={false} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                height={'200px'}

                                onRowUpdated={this.onRowUpdated}

                                onFieldDataChanged={this.onFieldDataChanged}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                        <div id="tabeditor-anggaran-1" className={' mt-2 col-md-12 text-center d-none'} >
                            <DevExpressDataGrid
                                ref={this.datagridBreakdownRef}
                                loadAPI='anggarans'
                                insertAPI='anggarans'
                                updateAPI='anggarans'
                                deleteAPI='anggarans'

                                backendserver={process.env.REACT_APP_BACKEND_ACC}

                                useArraySource={true}
                                ArraySourceData={this.loadDataBreakdown}

                                allowAdding={this.props.stepProses === 'REQ'}
                                allowDeleting={this.props.stepProses === 'REQ'}
                                allowUpdating={this.props.stepProses === 'REQ'}
                                exportExcel={false}
                                exportFileName={"Detail Breakdown Anggaran"}
                                allowExportSelectedData={false}
                                selection={"none"}

                                onToolbarPreparing={this.onToolbarPreparing}

                                menuRightClick={false}

                                showBorders={true}

                                paging={false}
                                defaultPageSize={10}

                                editingMode="row"

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Detail Breakdown Anggaran Data'}
                                popupWidth={'70vw'} //masukan dalam ukuran pixel
                                popupHeight={'400px'} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={false} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnBreakdown} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                height={'200px'}

                                onRowUpdated={this.onRowUpdated}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                        <Form
                            colCount={1}
                            id={"formAnggaran"}
                            formData={this.state.formData}
                            items={this.formSummary}
                            ref={this.formSummaryRef}
                            labelLocation="left"
                        // readOnly = {true}
                        />
                    </div>
                </Popup>
                <ModalEditorBreakdown ref={this.modalEditorBreakdownRef} store={this.props.store} submitData={this.submitDataBreakdown} />
            </React.Fragment>
        )
    }
}

export default ModalEditorAnggaran