import React, { Component } from 'react'
import Form from 'devextreme-react/form'
import notify from 'devextreme/ui/notify'
import { statusPegawai, statusKaryawan, months } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';

class FormDataKepegawaian extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pesertaId: 0,
            formData: {}
        }

        this.formRef = React.createRef()

        this.items = [
            {
                dataField: 'nikPanjang',
                label: {
                    alignment: 'left',
                    text: 'NIK Panjang'
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'NIK Panjang Harus Diisi'
                    },
                    {
                        type: "pattern",
                        pattern: /\(?([0-9])\)?/,
                        message: "NIK Panjang harus menggunakan angka"
                    },
                ],
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK Pendek'
                },
                editorOptions: {
                    onValueChanged: (e) => {
                        var value = e.value
                        if (this.props.setNik) {
                            this.props.setNik(value)
                        }
                    }
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'NIK Pendek Harus Diisi'
                    },
                    {
                        type: "pattern",
                        pattern: /\(?([0-9])\)?/,
                        message: "NIK Pendek harus menggunakan angka"
                    }
                ]
            },
            {
                dataField: 'statusKepegawaianId',
                label: {
                    alignment: 'left',
                    text: 'Status Kepegawaian'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusPegawai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'unitKerjaName',
                label: {
                    alignment: 'left',
                    text: 'Nama Unit Kerja'
                },
            },
            {
                dataField: 'unirKerja1Atas',
                label: {
                    alignment: 'left',
                    text: 'Unit Kerja 1 Atas'
                },
            },
            {
                dataField: 'unitKerja2Atas',
                label: {
                    alignment: 'left',
                    text: 'Unit Kerja 2 Atas'
                },
            },
            {
                dataField: 'unitKerja3Atas',
                label: {
                    alignment: 'left',
                    text: 'Unit Kerja 3 Atas'
                },
            },
            {
                dataField: 'jenisKantorName',
                label: {
                    alignment: 'left',
                    text: 'Jenis Kantor'
                },
            },
            {
                dataField: 'statusKaryawanId',
                label: {
                    alignment: 'left',
                    text: 'Status Karyawan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusKaryawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'statusKaryawanTanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Status Karyawan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                }
            },
            {
                dataField: 'tanggalEfektif',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Kerja Efektif'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                }
            },
            {
                dataField: 'grade',
                label: {
                    alignment: 'left',
                    text: 'Grade'
                },
            },
            {
                dataField: 'gradeTanggal',
                label: {
                    alignment: 'left',
                    text: 'Grade Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                }
            },
            {
                dataField: 'mkBulanGrade',
                label: {
                    alignment: 'left',
                    text: 'MK Bulan Grade'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'mkTahunGrade',
                label: {
                    alignment: 'left',
                    text: 'MK Tahun Grade'
                },
            },
            {
                dataField: 'jabatanName',
                label: {
                    alignment: 'left',
                    text: 'Jabatan'
                },
            },
            {
                dataField: 'jabatanGolongan',
                label: {
                    alignment: 'left',
                    text: 'Jabatan Golongan'
                }
            },
            {
                dataField: 'jabatanTmt',
                label: {
                    alignment: 'left',
                    text: 'TMT Jabatan'
                }
            },
            {
                dataField: 'penugasanGrade',
                label: {
                    alignment: 'left',
                    text: 'Penugasan Grade'
                }
            },
            {
                dataField: 'jabatanPenugasan',
                label: {
                    alignment: 'left',
                    text: 'Penugasan Jabatan'
                },
            },
            {
                dataField: 'unitKerjaPenugasan',
                label: {
                    alignment: 'left',
                    text: 'Penugasan Unit'
                },
            },
            {
                dataField: 'penugasanTanggal',
                label: {
                    alignment: 'left',
                    text: 'Penugasan Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                }
            },
            {
                dataField: 'indexPredikat',
                label: {
                    alignment: 'left',
                    text: 'Index Predikat'
                },
            },
            {
                dataField: 'noSkTerakhir',
                label: {
                    alignment: 'left',
                    text: 'No SK Terakhir'
                },
            },
            {
                dataField: 'tanggalBerhenti',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Berhenti'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal Berhenti Harus Diisi'
                    },
                ]
            },
            {
                dataField: 'namaAtasan',
                label: {
                    alignment: 'left',
                    text: 'Nama Atasan'
                },
            },
            {
                dataField: 'kodeMis',
                label: {
                    alignment: 'left',
                    text: 'Kode MIS'
                },
            },
            {
                dataField: 'phdp',
                label: {
                    alignment: 'left',
                    text: 'PHDP'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    step: 0,
                    min: 0
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'PHDP Harus Diisi'
                    },
                ],
            },
            {
                dataField: 'upnp',
                label: {
                    alignment: 'left',
                    text: 'UPNP'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    step: 0,
                    min: 0
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'PHDP Harus Diisi'
                    },
                ],
            },
            {
                dataField: 'thp',
                label: {
                    alignment: 'left',
                    text: 'Take Home Pay'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    step: 0,
                    min: 0
                }
            },

        ]
    }

    retrieveData = async (pesertaId, data) => {
        try {
            // let loadAPI = `peserta-kepegawaians/${pesertaId}`      
            // var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')

            this.setState({
                pesertaId: pesertaId,
                formData: data || {}
            })
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    passDataToParent = () => {
        var data = { ...this.state.formData }
        data.tanggalEfektif = data.tanggalEfektif || formatDate(new Date())
        return data
    }

    removeState = () => {
        this.setState({
            formData: {},
        })
    }

    validate = () => {
        var validate = this.formRef.current.instance.validate()

        return validate
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataKepegawaian'}
                            formData={this.state.formData}
                            items={this.items}
                            // scrollingEnabled={true}
                            labelLocation='left'
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default FormDataKepegawaian
