import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import FormDataPemberhentianPeserta from '../comp/formDataPemberhentian';
import { DropDownBox, ScrollView } from 'devextreme-react';
import { pesertaById } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class ModalPengajuanPemberhentianPeserta extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            pesertaId: '',
            dataMaster : {},
            actionType: 'add'
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.dropDownBoxRef = React.createRef()
        
        this.formDataPemberhentianRef = React.createRef()

        this.popupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options:{
            //         'text': 'Simpan',
            //         onClick: async() => {
            //             this.submitData()
            //         }
            //     },
            //     toolbar: 'bottom'
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: () => {
                        this.hide()
                        this.removeState()
                    } 
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Pemberhentian'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                disabled: true,
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'NIK Harus Diisi'
                    },
                    {
                        type: "pattern",
                        pattern: /\(?([0-9])\)?/,
                        message: "NIK harus menggunakan angka"
                    },
                    // {
                    //     type: 'stringLength',
                    //     max: 16,
                    //     min : 16
                    // }
                ],
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Nama Lengkap Harus Diisi'
                    },
                ],
            },
        ]
        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

        this.pesertaId = 0
    }
    get Popup(){
        return this.popupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldValue = e.value
        this.pesertaId = fieldValue
        this.formDataPemberhentianRef.current.reloadData(this.pesertaId,this.state.dataMaster)
    }
    setSelectedRow = (id) => {
        this.dropDownBoxRef.current.instance.option('value',id)
    }
    submitData = async(data = {}) => {
        if(this.state.actionType == 'add'){
            var postData = {
                "akunStatusId": 10,
                "fileSkPensiun": data.fileSkPensiun,
                "fileSkPensiunContentType": data.fileSkPensiunContentType,
                "fileSkPhk": data.fileSkPhk,
                "fileSkPhkContentType": data.fileSkPhkContentType,
                "idAkunProduct": data.id,
                "mpPenerimaTypeId": data.mpPenerimaTypeId,
                "nominalPemberhentian": data.nominalPemberhentian,
                "pengembalianRekeningBankId": data.pengembalianRekeningBankId,
                "pengembalianRekeningName": data.pengembalianRekeningName,
                "pengembalianRekeningNo": data.pengembalianRekeningNo,
                "pensiunType": data.pensiunType,
                "pesertaPhkTypeId": data.pesertaPhkTypeId,
                "productId": data.productId,
                "skPemberhentianNo": data.skPemberhentianNo,
                "skPensiunNo": data.skPensiunNo,
                "tglPembayaranPemberhentian": data.tglPembayaranPemberhentian,
                "tglPemberhentian": data.tglPemberhentian,
                "transferRekbankId": data.transferRekbankId
              }
            try {
                var loadAPI = 'registrasi-pemberhentian'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                    values:postData
                })

                await alert('Berhasil menambahkan pengajuan pemberhentian baru', 'Berhasil!')
            } catch (e) {
                await alert(e, 'Gagal!')
            }
        }else{
            try {
                var loadAPI = 'kepersertaan-products'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'PUT', {
                    key: data.id,
                    values:data
                },data)
                await alert('Berhasil merubah pengajuan pemberhentian', 'Berhasil!')
            } catch (e) {
                await alert(e, 'Gagal!')
            }
        }
        this.hide()
        this.props.forceRefresh()
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    removeState = () => {
        this.formDataPemberhentianRef.current.removeState()
        this.dropDownBoxRef.current.instance.option('value',0)
    }

    onSelectionChanged = (e) => {
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
    }

    retrieveData = async(data = null) => {
        if(data){
            this.setState({
                pesertaId: data.pesertaId,
                dataMaster: data
            })
            this.pesertaId = data.pesertaId
            this.setSelectedRow(data.pesertaId)
            await this.formDataPemberhentianRef.current.reloadData(data.pesertaId,data)
        }else{
            // await this.formDataPemberhentianRef.current.reloadData()
            this.setState({
                pesertaId: 0,
                dataMaster: {},
            })

            this.pesertaId = 0
            this.formDataPemberhentianRef.current.forceRefresh()
        }
    }
    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI= {'lookup-pesertas/getByLookupByStatusPeserta/2'}
                insertAPI='pengkinian-peserta-bo-aktifs'
                updateAPI='pengkinian-peserta-bo-aktifs'
                deleteAPI='pengkinian-peserta-bo-aktifs'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengajuan Pemberhentian Peserta'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Peserta</label>
                                <div className="col-sm-10">
                                    <DropDownBox
                                        ref={this.dropDownBoxRef}
                                        valueExpr="id"
                                        deferRendering={false}
                                        displayExpr={(item) => {
                                            return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                        }}
                                        showClearButton={true}
                                        dataSource={pesertaById(this.props.store)}
                                        onValueChanged={this.onValueChanged}
                                        contentRender={this.renderDatagrid}
                                        readOnly = {this.props.actionType == 'add' ? false : true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id={`tabIndexModalRegistrasi${this.props.actionType}-0`} className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataPemberhentianPeserta store={this.props.store} actionType={this.props.actionType} ref={this.formDataPemberhentianRef} actionType={this.props.actionType} submitData = {this.submitData}/>
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalPengajuanPemberhentianPeserta
