import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import { participants, pensiunType, pesertas, product, pesertaAkunStatus, banks, pesertaFamiliesAll, mpPenerimaType, tipePembayaranMp, banks_core } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import ModalRegistrasiPasif from 'components/pages/modal/kepesertaan/pesertaDapen/pasif/registrasi/modalRegistrasi';
import UploadPasif from 'components/pages/modal/kepesertaan/pesertaDapen/pasif/uploadPasif';
import { formatNumberAum } from 'plugin/helper';
import { confirmAlert } from 'react-confirm-alert'

class RegistrasiPasif extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            getUrl: 'getByPesertaStatusAndAkunStatus'
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.popupNewPesertaPasifRef = React.createRef()
        this.popupDetailPesertaPasifRef = React.createRef()
        this.popupUploadPesertaRef = React.createRef()
        this.popupModalKeluargaRef = React.createRef()

        this.PengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalRegistrasiPasif('view', e.row.data)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Membatalkan Peserta Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.reject(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Mengirim Peserta Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.kirim(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'No',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                name: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                name: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'skPensiunNo',
                caption: 'No SK Pensiun',
            },
            {
                name: 'dataAkun',
                caption: 'Data Akun',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'productId',
                        caption: 'Nama Akun',
                        lookup: {
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode" // provides display values
                        }
                    },
                    {
                        dataField: 'cardNo',
                        caption: 'No. Kartu',
                    },
                    {
                        dataField: 'mpPaymentTypeId',
                        caption: 'Pembayaran MP',
                        lookup: {
                            dataSource: tipePembayaranMp(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "typeName" // provides display values
                        }
                    },
                    {
                        name: 'akumulasiIuran',
                        caption: 'Akumulasi Iuran',
                        cellRender: (e) => {
                            var data = e.data
                            var akumulasiIuran = (data.iuranManfaatLainPeserta || 0) + (data.iuranManfaatLainPk || 0) + (data.iuranNormalPeserta || 0) + (data.iuranNormalPk || 0)

                            return formatNumberAum(akumulasiIuran)
                        },
                        alignment: 'right'
                    },
                    {
                        dataField: 'mpSekaligus',
                        caption: 'MP Sekaligus',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'mpBulanan',
                        caption: 'MP Bulanan',
                        format: '#,##0.00',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'penerimaMP',
                caption: 'Penerima MP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'penerimaName',
                        caption: 'Nama',
                        // lookup: {
                        //     dataSource: pesertaFamiliesAll(this.props.store),
                        //     valueExpr: "id", // contains the same values as the "statusId" field provides
                        //     displayExpr: "fullName" // provides display values
                        // }
                    },
                    {
                        dataField: 'relationCode',
                        caption: 'Hubungan',
                        lookup: {
                            dataSource: mpPenerimaType(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "penerimaName" // provides display values
                        }
                    },
                ]
            },
            {
                dataField: 'bankTransferMP',
                caption: 'Bank Transfer MP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'mpRekeningName',
                        caption: 'Nama Rekening',
                    },
                    {
                        dataField: 'mpRekeningNo',
                        caption: 'No Rekening',
                    },
                    {
                        dataField: 'mpRekeningBankId',
                        caption: 'Nama Bank',
                        lookup: {
                            dataSource: banks_core(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "bankName" // provides display values
                        }
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]


        this.filterItem = [
            {
                dataField: 'participantId',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        if (data.value) {
                            this.setState({
                                getUrl: 'getByPesertaStatusAndAkunStatusAndParticipantId'
                            })
                        } else {
                            this.setState({
                                getUrl: 'getByPesertaStatusAndAkunStatus'
                            })
                        }

                        this.forceRefresh()
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalRegistrasiPasif = async(type, data = {}) => {
        let periodeTahun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'pembayaran-mp/periodeTahun', 'GET')
        if (type == 'add') {
            this.popupNewPesertaPasifRef.current.show(periodeTahun)
            // this.popupNewPesertaPasifRef.current.retrieveData()
        } else {
            this.popupDetailPesertaPasifRef.current.show(periodeTahun)
            this.popupDetailPesertaPasifRef.current.retrieveData(data)
        }
    }

    retrieveData = async () => {
        var loadAPI = `kepersertaan-products/${this.state.getUrl}`
        var participantId = this.state.filterData.participantId
        if (this.state.filterData.participantId) {
            var dataAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/2/6/${participantId}`, 'GET')
            var dataPersiapanPensiun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/3/6/${participantId}`, 'GET')
            var dataPersiapanPensiunRegistrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/3/1/${participantId}`, 'GET')
        } else {
            var dataAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/2/6`, 'GET')
            var dataPersiapanPensiun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/3/6`, 'GET')
            var dataPersiapanPensiunRegistrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/3/1`, 'GET')
        }
        var result = [
            ...dataAktif,
            ...dataPersiapanPensiun,
            ...dataPersiapanPensiunRegistrasi,
        ]
        return result
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Daftar Baru',
                    icon: "plus",
                    onClick: (e) => {
                        this.showModalRegistrasiPasif('add')
                    },
                }
            },
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         text: 'Upload',
            //         icon: 'upload',
            //         onClick: (e) => {
            //             this.showModalUploadPasif(e)
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    visible: this.state.selectedTabIndex == 0 ? false : true,
                    hint: "Muat Ulang",
                    onClick: (e) => {
                        this.forceRefresh()
                    }
                }
            }
        );
    }
    showModalUploadPasif = (e) => {
        this.popupUploadPesertaRef.current.show()
    }

    checkStatusDataProduct = async (pesertaId) => {
        var loadAPIProduct = `kepersertaan-products/getAllSimpleProductByPesertaId/${pesertaId}`
        var dataProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct, 'GET')

        var check = dataProduct.find(value => value.akunStatusId === 6 || value.akunStatusId === 1)
        if (check) {
            return false
        } else {
            return true
        }
    }

    reject = async (data) => {
        try {
            var loadAPIPeserta = `pesertas/batalRegistrasi/${data.pesertaId}/${data.productId}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta, 'POST', {
                values: {}
            })

            if (response) {
                var text = `Data berhasil ditolak!`
                var type = 'success'
            }

        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }

        this.forceRefresh()
        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    kirim = async (dataProduct) => {
        try {
            var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/${dataProduct.pesertaId}`, 'GET')

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/kirimRegistrasi/${dataPeserta.nik}/${dataProduct.productId}`, 'POST', {
                values: {}
            })
            if (response) {
                var loadAPIProduct = 'kepersertaan-products'
                var dataProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct + '/' + dataProduct.id, 'GET')
                var dataUpdateProduct = dataProduct

                if (dataUpdateProduct.mpPaymentTypeId == 2) {
                    var loadAPIMpPembayaran = 'pembayaran-mp-sekaligus'
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMpPembayaran, 'POST', {
                        values: {
                            akunProductId: dataUpdateProduct.id,
                            mpPaymentTypeId: dataProduct.mpPaymentTypeId,
                        }
                    })
                }
                if (dataUpdateProduct.mpPaymentTypeId == 1) {
                    var loadAPIMpPembayaran = 'pembayaran-mp-sekaligus-sebagian'
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMpPembayaran, 'POST', {
                        values: {
                            akunProductId: dataUpdateProduct.id,
                            mpPaymentTypeId: dataProduct.mpPaymentTypeId,
                        }
                    })
                }
                
                var text = `Data berhasil dikirim!`
                var type = 'success'
            }


            
            // dataUpdateProduct.pendingStatus = false

            // if (dataProduct.mpPaymentTypeId == 3) {
            //     dataUpdateProduct.akunStatusId = 2
            // }

            // if (dataProduct.mpPaymentTypeId == 2) {
            //     dataUpdateProduct.akunStatusId = 5
            // }

            // if (dataProduct.mpPaymentTypeId == 1) {
            //     dataUpdateProduct.akunStatusId = 7
            // }

            // await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct, 'PUT', {
            //     key: dataUpdateProduct.id,
            //     values: dataUpdateProduct
            // },
            //     dataProduct)

            // var check = await this.checkStatusDataProduct(dataProduct.pesertaId)
            // if (check) {
            //     var loadAPI = 'pesertas'
            //     var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + dataProduct.pesertaId, 'GET')

            //     var dataUpdate = dataPeserta
            //     dataUpdate.statusPeserta = 4
            //     await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
            //         key: dataUpdate.id,
            //         values: dataUpdate
            //     }, dataPeserta)
            // }

            

            // if(dataUpdateProduct.mpPaymentTypeId == 3){
            //     var loadAPIMpPembayaran = 'pembayaran-mp-bulanan'
            //     await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMpPembayaran, 'POST', {
            //         values: {
            //             productId: dataUpdateProduct.id,
            //             mpPaymentTypeId: dataProduct.mpPaymentTypeId,
            //             tanggalBayar: formatDate(dateNow),
            //             bulan: dateNow.getMonth(),
            //             tahun: dateNow.getFullYear(),
            //           }
            //     })
            // }
        } catch (e) {
            console.log(e)
            var text = `Data gagal dikirim!`
            var type = 'error'

            // var text = e
            // var type = 'error'
        }
        this.forceRefresh()
        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Registrasi Pasif</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                />
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-peserta-pasifs'
                    insertAPI='kepersertaan-products'
                    updateAPI='kepersertaan-products'
                    deleteAPI='kepersertaan-products'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations={true}

                    // keyField = {'ids'}
                    // useArraySource={true}
                    // ArraySourceData={this.retrieveData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Peserta Pasif"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Peserta Pasif'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 245px)'}

                    ColumnConfiguration={this.PengajuanColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    // height={'100%'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalRegistrasiPasif
                    ref={this.popupNewPesertaPasifRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'add'}
                />
                <ModalRegistrasiPasif
                    ref={this.popupDetailPesertaPasifRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'view'}
                />
                <UploadPasif
                    store={this.props.store}
                    ref={this.popupUploadPesertaRef}
                />
            </div>
        )
    }
}

export default RegistrasiPasif