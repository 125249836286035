import React, { Component } from 'react'
import { Form } from "devextreme-react";
import ModalActionPotongan from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/potongan/index';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { banks, months, product} from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog'
import { confirmAlert } from 'react-confirm-alert'
import httpRequest from 'plugin/httprequest';   
import { formatDate, formatDefaultMonthYear } from 'plugin/helper';

class PotonganPembayaranMp  extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            getUrl: 'view-pembayaran-potongans?size=9999'
        }

        this.dataGridRef = React.createRef()
        this.modalEditPotonganRef = React.createRef()
        this.modalLihatPotonganRef = React.createRef()
        this.modalTambahPotonganRef = React.createRef()

        this.filterItem = [
            // {
            //     dataField: 'produk',
            //     label: {
            //         text: 'Produk',
            //         alignment: "left",
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         dataSource: product(this.props.store),
            //         valueExpr: "id", // contains the same values as the "statusId" field provides
            //         displayExpr: "productCode", // provides display values
            //         searchEnabled: true,
            //         deferRendering: false,
            //         width: '100%',
            //         onValueChanged: (data) => {
            //             this.dataGridRef.current.forceRefresh()
            //         }
            //     },
            //     colSpan: 3
            // },
            {
                dataField: 'potonganPeriodeBulanDari',
                label : {
                    text: 'Tanggal Potongan',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    placeholder: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'potonganPeriodeBulanSampai',
                label : {
                    text: 'S/D',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    placeholder: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Filter',
                    onClick: (e) => {
                        var filter = this.state.formFilter
                        var greaterPotongan = filter.potonganPeriodeBulanDari
                        var lessPotongan = filter.potonganPeriodeBulanSampai
                        this.setState({
                            getUrl: greaterPotongan && lessPotongan ? `view-pembayaran-potongans?size=9999&potonganDate.greaterThanOrEqual=${formatDate(greaterPotongan)}&potonganDate.lessThanOrEqual=${formatDate(lessPotongan)}` : `view-pembayaran-potongans?size=9999`
                        })
                    }
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            var status = e.row.data
                            console.log(status.statusMpBulanan)
                            if(status.statusMpBulanan == 'selesai') {
                                alert('Tidak bisa melakukan Edit & Hapus data', 'Information');
                            } else {
                                // alert('This Feature Under Development', 'Information');
                                this.showModalEdit(e.row.data)
                            }
                        }
                    },
                    {
                        text: 'Lihat',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalLihat(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        cssClass: 'text-success',
                        onClick : async(e) => {
                            var status = e.row.data
                            
                            if(status.statusMpBulanan == 'selesai') {
                                alert('Tidak bisa melakukan Edit & Hapus data', 'Information');
                            } else{
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin untuk menghapus data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.deleteAPI(e.row.data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    // {
                    //     text: 'Kirim',
                    //     cssClass: 'text-info',
                    //     onClick : (e) => {
                    //         console.log('Kirim')
                    //     }
                    // },
                    // {
                    //     text: 'Cetak',
                    //     cssClass: 'text-info',
                    //     onClick : (e) => {
                    //         console.log('Cetak')
                    //     }
                    // },
                    // {
                    //     text: 'Konfirmasi Bank',
                    //     cssClass: 'text-info',
                    //     onClick : (e) => {
                    //         console.log('Konfirmasi Bank')
                    //     }
                    // },
                ],
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'potonganDate',
                caption: 'Tanggal Potongan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'periodePembayaran',
                caption: 'Periode Pembayaran',
                cellRender: (e) => {
                    var data = e.data
                    var result = formatDefaultMonthYear(data.potonganPeriodeBulanDari) + ' S/D ' + formatDefaultMonthYear(data.potonganPeriodeBulanSampai)

                    return result
                }
                // dataType: 'date',
                // format: 'MMM yyyy'
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'penerimaName',
                caption: 'Nama Penerima',
            },
            {
                dataField: 'potonganTypeId',
                caption: 'Jenis Potongan',
                cellRender : (e) =>{
                    return 'Potongan'
                }
            },
            {
                dataField: 'transaksiMp',
                caption: 'Transaksi MP',
                alignment:'center',
                columns: [
                    {
                        dataField: 'transactionPeriodeBulan',
                        caption: 'Periode Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'transactionPeriodeTahun',
                        caption: 'Periode Tahun',
                    },
                    {
                        dataField: 'nominalPotonganTotal',
                        caption: 'Total Nominal Potongan',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'mpRekeningName',
                        caption: 'Nama Rekening',
                    },
                    {
                        dataField: 'mpRekeningNo',
                        caption: 'No Rekening',
                    },
                    {
                        dataField: 'mpRekeningBankId',
                        caption: 'Bank Rekening',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'initialName'
                        }
                    },
                ]
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField: 'statusMpBulanan',
                caption: 'Status', 
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Potongan',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            }
        )
    }

    showModalEdit = (data) => {
        this.modalEditPotonganRef.current.show()
        this.modalEditPotonganRef.current.retrieveData(data) 
    }

    showModalLihat = (data) => {
        this.modalLihatPotonganRef.current.show()
        this.modalLihatPotonganRef.current.retrieveData(data)
    }

    showModalInsert = () => {
        this.modalTambahPotonganRef.current.show()
    }

    insertData = async(data) => {
        data.transactionPeriodeBulan = new Date(data.transactionPeriode).getMonth() + 1;
        data.transactionPeriodeTahun = new Date(data.transactionPeriode).getFullYear()
        data.potonganTypeId = 1
        
        delete data.transactionPeriode
        try {

            var postAPI = 'pembayaran-potongans'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
                values: data
            })

            await alert('Data Berhasil Disimpan !','Success')
            this.forceRefresh()
            // let loadAPI = `pengalihan-mps/getValidasiPotRapel/2/${data.transactionPeriodeBulan}/${data.transactionPeriodeTahun}/${data.kepersertaanProductId}`;
            // let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');

            // if (response[0].statusDescription !== 0) {
            //     let result = await confirm(response[0].statusDescription, 'Konfirmasi!');

            //     if (result) {
            //         var postAPI = 'pembayaran-potongans'
            //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
            //             values: data
            //         })
        
            //         await alert('Data Berhasil Disimpan !','Success')
            //         this.forceRefresh()
            //     }
            // } else {
            //     var postAPI = 'pembayaran-potongans'
            //     await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
            //         values: data
            //     })
    
            //     await alert('Data Berhasil Disimpan !','Success')
            //     this.forceRefresh()
            // }
        } catch (e) {
            await alert(e,'Failed')
        }
    }

    updateData = async(data) => {
        var loadAPI = `pembayaran-potongans/${data.pembayaranPotonganId}`
        var getPotongan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        data.transactionPeriodeBulan = data.transactionPeriode ? new Date(data.transactionPeriode).getMonth() + 1 : data.transactionPeriodeBulan
        data.transactionPeriodeTahun = data.transactionPeriode ? new Date(data.transactionPeriode).getFullYear() : data.transactionPeriodeBulan

        delete data.transactionPeriode
        try {
            // let loadAPI = `pengalihan-mps/getValidasiPotRapel/2/${data.transactionPeriodeBulan}/${data.transactionPeriodeTahun}/${data.kepersertaanProductId}`;
            // let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');

            let updateData = {
                    "id": data.pembayaranPotonganId,
                    "potonganNo": data.potonganNo,
                    "potonganDate": data.potonganDate,
                    "transactionPeriodeBulan": data.transactionPeriodeBulan,
                    "transactionPeriodeTahun": data.transactionPeriodeTahun,
                    "keterangan": data.keterangan,
                    "potonganTypeId": data.potonganTypeId,
                    "mpTypeId": data.mpTypeId,
                    "potonganPeriodeBulanDari": data.potonganPeriodeBulanDari,
                    "potonganPeriodeBulanSampai": data.potonganPeriodeBulanSampai,
                    "jumlahPeriodeBulan": data.jumlahPeriodeBulan,
                    "nominalMpSebelum": data.nominalMpSebelum,
                    "nominalPotonganTotal": data.nominalPotonganTotal,
                    "nominalPotonganPerbulan": data.nominalPotonganPerbulan,
                    "pajakSebelum": data.pajakSebelum,
                    "penguranganPajak": data.penguranganPajak,
                    "nominalSetelahPajak": data.nominalSetelahPajak,
                    "paymentRekbankId": data.paymentRekbankId,
                    "feeBank": data.feeBank,
                    "productId": data.productId,
                    "kepersertaanProductId": data.kepersertaanProductId
            };

            var updateAPI = 'pembayaran-potongans'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                key : data.pembayaranPotonganId,
                values: updateData
            }, getPotongan)  
            await alert('Data Berhasil Diubah !','Success')
            this.forceRefresh()

            // if (response[0].statusDescription !== 0) {
            //     let result = await confirm(response[0].statusDescription, 'Konfirmasi');

            //     if (result) {
            //         var updateAPI = 'pembayaran-potongans'
            //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
            //             key : data.pembayaranPotonganId,
            //             values: updateData
            //         }, getPotongan)  
            //         await alert('Data Berhasil Diubah !','Success')
            //         this.forceRefresh()
            //     }
            // } else {
            //     var updateAPI = 'pembayaran-potongans'
            //     await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
            //         key : data.pembayaranPotonganId,
            //         values: updateData
            //     }, getPotongan)  
            //     await alert('Data Berhasil Diubah !','Success')
            //     this.forceRefresh()
            // }

        } catch (e) {
            await alert(e,'Failed')
        }
        
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-potongans`, 'DELETE', data.pembayaranPotonganId);

            await alert('Data Berhasil Dihapus !','Success')
            this.forceRefresh()
        } catch (error) {
            await alert(error,'Failed')
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Potongan</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='pembayaran-potongans'
                    updateAPI='pembayaran-potongans'
                    deleteAPI='pembayaran-potongans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Ditunda"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalActionPotongan
                    ref={this.modalTambahPotonganRef}
                    store={this.props.store}
                    actionType = {'add'}
                    submitData = {this.insertData}
                />
                <ModalActionPotongan
                    ref={this.modalEditPotonganRef}
                    store={this.props.store}
                    actionType = {'edit'}
                    updateData = {this.updateData}
                />
                <ModalActionPotongan
                    ref={this.modalLihatPotonganRef}
                    store={this.props.store}
                    actionType = {'lihat'}
                />
            </div>
        )
    }
}

export default PotonganPembayaranMp