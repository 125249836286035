import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { rekeningBank } from 'dataSource/lookup'

class DataGridDetailInstruksiPelepasan extends Component {
    constructor(props) {
        super(props)
        this.columnPelepasan = [
            {
                dataField: 'orderId',
                caption: 'Order ID'
            },
            {
                dataField: 'noOrder',
                caption: 'No. Order'
            },
            {
                dataField: 'subName',
                caption: 'Deposito'
            },
            {
                dataField: 'pelepasan',
                caption: 'Pelepasan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bilyetNo',
                        caption: 'No Bilyet'
                    },
                    {
                        dataField: 'maturityDate',
                        caption: 'JatuhTempo',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'amountOrder',
                        caption: 'Pokok',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'bungaPelepasan',
                        caption: 'Bunga',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                        calculateCellValue: (e) => {
                            var result = (e.tenor/365) * ((e.rateOrder/100) * e.amountOrder)
                            return result
                        }
                    },
                    {
                        dataField: 'total',
                        caption: 'Total',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                        calculateCellValue: (e) => {
                            var result = (e.tenor/365) * ((e.rateOrder/100) * e.amountOrder)
                            return e.amountOrder + result
                        }
                    },
                ]
            },
            {
                dataField: 'rekBankName',
                caption: 'Rekening Pencairan (Pokok/Bunga)',
            },
        ]
    }
    dataSource = () => {
        return this.props.data
    }
    render() {
        return (
            <div className="col-md-12" id="Pelepasan">
                <h6 className='text-center'>Detail Instruksi (Pelepasan)</h6>
                <DevExpressDataGrid
                    keyField="id"
                    loadAPI='detail-instruksi'
                    insertAPI='detail-instruksi'
                    updateAPI='detail-instruksi'
                    deleteAPI='detail-instruksi'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.dataSource}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Detail Instruksi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Detail Instruksi Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    height={225}

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columnPelepasan} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default DataGridDetailInstruksiPelepasan