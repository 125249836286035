import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { exchange } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import FormModalStaticDataAkuntansi from './modalStaticDataAkuntansi'
import { confirmAlert } from 'react-confirm-alert'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify';

class ExchangeRate extends Component {
    constructor(props) {
        super(props)

        this.columns = exchange(this.props.store)

        this.datagridRef = React.createRef()
        this.modalEditKategoriAsetRef = React.createRef()
        this.modalAddKategoriAsetRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalEdit = (type, data) => {
        if(type === 'edit') {
            if(data) {
                this.modalEditKategoriAsetRef.current.show()
                this.modalEditKategoriAsetRef.current.retrieveData(data)
            }
        } else {
            this.modalAddKategoriAsetRef.current.show()
        }
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `staticdata-wf/exchange-rates`, 'DELETE', data.id);

            notify({message: 'Data Berhasil Dihapus', width: "AUTO", shading: true, position: { at: "center", my: "center", of: window }}, 'success', 600);
            this.forceRefresh()
        } catch (e) {
            notify({message: e, width: "AUTO", shading: true, position: { at: "center", my: "center", of: window }}, 'error', 600);
        }
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalEdit('add')
                    },
                }
            }
        )
    }

    render() {
        var columns = [{
            type: 'buttons',
            buttons: [
              {
                text: 'Ubah',
                hint: 'Ubah',
                cssClass: 'text-success',
                onClick: async(e) => {
                    this.showModalEdit('edit', e.row.data)
                }
              },
              {
                text: 'Hapus',
                hint: 'Hapus',
                cssClass: 'text-success',
                onClick: async(e) => {
                    confirmAlert({
                        title: 'Konfirmasi',
                        message: 'Apakah anda yakin untuk menghapus data ini?',
                        buttons: [
                            {
                                label: 'Ya',
                                onClick: () => {
                                    this.deleteAPI(e.row.data)
                                }
                            },
                            {
                                label: 'Tidak'
                            }
                        ]
                    })
                }
              },
            ],
        },...this.columns]

        return (
            <div className="container-fluid">
                <h2 className="main-title">Exchange Rate</h2>

                <DevExpressDataGrid
                    ref={this.datagridRef}
                    loadAPI='exchange-rates'
                    insertAPI='staticdata-wf/exchange-rates'
                    updateAPI='staticdata-wf/exchange-rates'
                    deleteAPI='staticdata-wf/exchange-rates'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Exchange Rates"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Exchange Rate Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormModalStaticDataAkuntansi 
                    ref={this.modalAddKategoriAsetRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'add'}
                    menuName={'accExchangeRate'}
                    title={'Exchange Rate'}
                />  

                <FormModalStaticDataAkuntansi 
                    ref={this.modalEditKategoriAsetRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'edit'}
                    menuName={'accExchangeRate'}
                    title={'Exchange Rate'}
                /> 
            </div>
        )
    }
}

export default ExchangeRate