import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, months, rekBank, iuranType } from 'dataSource/lookup';
import { formatUploadFileData, formatUploadFileNameAndExtention } from 'plugin/helper';
import { FileUploader } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import { confirm,alert } from 'devextreme/ui/dialog';
import { showLoading } from 'redux/actions/loading';

class ModalKonfirmasiPiutangIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            getUrl : 'iuran-by-types/getByIuranId/0'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        
        this.prevTabIndex = 0
        this.PopupRef = React.createRef()
        this.dataGridDetailRef = React.createRef()
        this.formRef = React.createRef()

        this.summary = {
            totalItems: [
                {
                    column: 'receiveOutstanding',
                    summaryType: 'sum',
                    valueFormat: '#,##0.00'
                },
                {
                    column: 'nominalIuran',
                    summaryType: 'sum',
                    valueFormat: '#,##0.00'
                },
            ]
        }

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': this.props.step != 'pengajuan' ? 'Kirim' : 'Simpan',
                    onClick: async() => {
                        let validate = this.validate();

                        if(validate) {
                            var result = await confirm('Apakah anda yakin data penerimaan sudah sesuai?', 'Konfirmasi')
                            if(result){
                                var statusPiutangId
                                switch(this.props.step){
                                    case 'pengajuan':
                                        statusPiutangId = 2;
                                    break;
                                    case 'verifikasi-manager':
                                        statusPiutangId = 3;
                                    break;
                                    case 'persetujuan-pengurus':
                                        statusPiutangId = 4;
                                    break;
                                }
    
                                if(this.props.step != 'persetujuan-pengurus') await this.updateIuran(this.state.dataMaster, statusPiutangId)
                                if(this.props.step == 'persetujuan-pengurus') {
                                    await this.prosesData()
                                }else{
                                    this.hide()
                                    this.props.forceRefresh()
                                }
                            }
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formMaster = [
            {
                dataField:'id',
                label:{
                    text: 'ID Iuran',
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField:'participantId',
                label:{
                    text: 'Pemberi Kerja',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    placeholder: ' ',
                    dataSource: participants(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "participantName", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                }
            },
            {
                dataField:'tahun',
                label:{
                    text: 'Periode Tahun',
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField:'bulan',
                label:{
                    text: 'Periode Bulan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    placeholder: ' ',
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    readOnly: true
                }
            },
            {
                // dataField:'receiveOutstanding',
                dataField: 'nominalIuran',
                label:{
                    text: 'Total Nominal Iuran',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly : true
                },
            },
            {
                dataField: 'receiveDate',
                label: {
                    text: 'Tanggal Diterima',
                },
                validationRules : [
                    {
                        type: "required",
                        // type: this.props.step != 'pengajuan' ? "required" : null
                    }
                ],
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    readOnly : this.props.step != 'pengajuan' ? true : false
                }
            },
            {
                dataField:'pembayaranRekbankId',
                label:{
                    text: 'Rekening Penerima',
                },
                editorType: 'dxSelectBox',
                validationRules : [
                    {
                        type: "required",
                        // type: this.props.step != 'pengajuan' ? "required" : null
                    }
                ],
                editorOptions: {
                    dataSource: rekBank(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "rekName", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly : this.props.step != 'pengajuan' ? true : false
                }
            },
            {
                dataField:'selisih',
                label:{
                    // text: 'Selisih Pembulatan',
                    text: 'Iuran Belum Diterima',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    // readOnly: true
                    readOnly : this.props.step != 'pengajuan' ? true : false
                },
            },
        ]
        
        this.columnsDetail = [
            {
                dataField: 'iuranTypeId',
                caption: 'Tipe Iuran',
                allowEditing : false,
                lookup: {
                    dataSource: iuranType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'iuranName'
                }
            },
            {
                dataField: 'iuranNominal',
                caption: 'Total Iuran',
                allowEditing : false,
                format: '#,##0.00',
                alignment: 'right',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
            {
                dataField: 'iuranReceived',
                caption: 'Total Telah Diterima',
                allowEditing : false,
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'iuranOutstanding',
                caption: 'Iuran Belum Diterima',
                allowEditing : false,
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'jumlahDibayar',
                caption: 'Penerimaan Iuran',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                editorType: 'dxNumberBox',
                // validationRules: [
                //     // {
                //     //     type: 'required',
                //     //     message: 'this field is required'
                //     // },
                //     {
                //         type: 'custom',
                //         validationCallback: (e) => {
                //             if (e.value) {
                //                 let dataGrid = this.dataGridDetailRef.current.getDataSource().find(value => value.id === e.data.id);
                //                 let jumlahDibayar = e.value;

                //                 if (jumlahDibayar > dataGrid.iuranOutstanding) {
                //                     alert('Penerimaan iuran lebih besar', 'Informasi');
                //                     // e.value = 0;
                //                     return false;
                //                 }

                //                 if (jumlahDibayar < 0 ){
                //                     alert('Penerimaan tidak boleh minus', 'Informasi');
                //                     // e.value = 0;
                //                     return false;
                //                 }

                //                 return true;

                //             }
                //         },
                //         // message: 'Penerimaan iuran tidak bisa lebih besar atau minus, dari iuran belum diterima!'
                //     }
                // ],
                editorOptions: {
                    format: '#,##0.00'
                },
            },
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
      }

    prosesData = async() => {
        var stateData={...this.state.dataMaster}
        var iuranByTypeList = this.dataGridDetailRef.current.getDataSource()
        var iuranDetailsListByType = []
        var summaryPembulatan = 0
        var outstandingIuran = 0

        var loadAPIuranTypes = 'iuran-types'
        var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

        for(var value of iuranByTypeList){
            var findIuranTypes = dataIuranTypes.find(val => val.id == value.iuranTypeId)
            var iuranName = findIuranTypes ? findIuranTypes.iuranName : ''

            iuranDetailsListByType.push({
                idIuranByType : value.id,
                idIuranType : value.iuranTypeId,
                jumlahDibayar : value.jumlahDibayar || 0,
                namaIuran: iuranName,
                totalDiterima: value.iuranReceived || 0,
                totalNominal: value.iuranNominal || 0,
                totalTagihan: value.iuranOutstanding || 0
            })

            summaryPembulatan = summaryPembulatan + value.jumlahDibayar
            // outstandingIuran = value.iuranOutstanding || 0
            // stateData.receiveOutstanding = outstandingIuran
        }
        var data = {
            bulan: stateData.bulan,
            fileBuktiTransfer: stateData.dokumenFile,
            fileBuktiTransferContentType: stateData.dokumenFileContentType,
            idIuran: stateData.id,
            nominalTagihan: stateData.receiveOutstanding || 0,
            participantId: stateData.participantId,
            rekeningPenerima: stateData.pembayaranRekbankId,
            selisihPembulatan: stateData.selisih || 0,
            summaryNominal: summaryPembulatan || 0,
            tahun: stateData.tahun,
            tanggalDiterima: stateData.receiveDate,
            iuranDetailListByType: iuranDetailsListByType
        }
        
        let store = this.props.store
        store.dispatch(showLoading(true))
        try {
            var loadAPIKonfirmasi = 'iurans/piutangIurans'
            var result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKonfirmasi, 'POST', {
                values: data
            })

            if(result){
                var loadAPI = 'iurans'
                var getIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/${stateData.id}`, 'GET')
                var updateData = getIuran
                // console.log(getIuran.receiveOutstanding);
    
                if (getIuran.receiveOutstanding > 0) {
                    updateData.statusPiutangId = 1;
                } else {
                    updateData.statusPiutangId = 4;
                }
                // getIuran.statusPiutangId = 4
                getIuran.receiveOutstanding = stateData.receiveOutstanding
    
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                    key: updateData.id,
                    values: updateData
                },getIuran)
            }
            var message = `Data berhasil dikonfirmasi!`
            var title = 'Berhasil'
            store.dispatch(showLoading(false))
            this.hide()
            this.props.forceRefresh()
        } catch (e) {
            var message = e
            var title = 'Gagal'
            store.dispatch(showLoading(false))
            this.hide()
        }
        await alert(message, title)
        this.hide()
    }

    updateIuran = async(data,statusPiutangId) => {
        try {
            var dataState = {...this.state.dataMaster}
            var loadAPI = 'iurans'
            var getIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/${data.id}`, 'GET')
            var updateData = getIuran
            updateData.statusPiutangId = statusPiutangId
            updateData.pembayaranRekbankId = data.pembayaranRekbankId

            var summaryPembulatan = 0
            var iuranByTypeList = this.dataGridDetailRef.current.getDataSource()

            for(var value of iuranByTypeList){
                var paramIBT = value
                if (statusPiutangId === 2) {
                    paramIBT.interestNominal = value.jumlahDibayar
                    var lastIuranReceive = paramIBT.iuranReceived
                    paramIBT.iuranReceived = lastIuranReceive + value.jumlahDibayar
                    paramIBT.iuranOutstanding = paramIBT.iuranNominal - paramIBT.iuranReceived

                    var loadIBT = 'iuran-by-types'
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadIBT, 'PUT',{
                        key: paramIBT.id,
                        values: paramIBT
                    },paramIBT)
    
                    summaryPembulatan = summaryPembulatan + value.jumlahDibayar
                }
                // outstandingIuran = statusPiutangId === '2' ? value.iuranOutstanding === 0 ? 0 : value.jumlahDibayar - value.iuranOutstanding : value.iuranOutstanding
            }
            if(this.props.step){
                if (statusPiutangId === 2) {
                    updateData.receiveDate = data.receiveDate
                    var lastReceive = updateData.receiveAmount
                    updateData.receiveAmount = lastReceive + summaryPembulatan
                    // updateData.receiveAmount = summaryPembulatan
                    // updateData.receiveOutstanding = dataState.selisih
                    updateData.receiveOutstanding = updateData.nominalIuran - updateData.receiveAmount;
                } 
            }
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: updateData.id,
                values: updateData
            },getIuran)
            
            var message = `Data berhasil di kirim!`
            var title = 'Berhasil'
        } catch (e) {
            var message = e
            var title = 'Gagal'
        }
        await alert(message, title)
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
        });
    }

    retrieveData = (data) => {
        var datas = data
        // datas.selisih = ((data.receiveAmount || data.receiveOutstanding) || 0) - data.nominalIuran
        datas.selisih = data.receiveOutstanding
        this.setState({
            dataMaster : datas,
            getUrl : `iuran-by-types/getByIuranId/${data.id}`
        })

        this.forceRefresh()
    }
    
    loadDataGrid = async() => {
        var loadAPIuranTypes = this.state.getUrl
        var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

        for(var value of dataIuranTypes){
            value.iuranReceived = value.iuranReceived
            value.iuranOutstanding = (value.iuranNominal - value.iuranReceived) || 0
            // value.jumlahDibayar = value.iuranReceived || value.iuranOutstanding
            if(this.props.step == 'pengajuan') {
                value.jumlahDibayar = 0
            } else {
                value.jumlahDibayar = value.interestNominal || 0
            }
        }
        return dataIuranTypes
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}File`]: formattedFileData.base64data,
                        [`${fieldName}FileName`]: formattedFileNameAndExtention.name,
                        [`${fieldName}FileExtention`]: formattedFileNameAndExtention.extention,
                        [`${fieldName}FileContentType`]: formattedFileData.mime,
                    },
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    forceRefresh = () => {
        this.dataGridDetailRef.current.forceRefresh(true)
    }

    onRowUpdatedKonfirmasi = async(e) => {
        let dataGrid = this.dataGridDetailRef.current.getDataSource().find(value => value.id === e.key);
        let jumlahDibayar = e.data.jumlahDibayar;
        
        if (jumlahDibayar > dataGrid.iuranOutstanding) {
            await alert('Penerimaan Iuran lebih besar dari iuran belum diterima', 'Gagal');
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Penerimaan Iuran'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <Form
                        colCount={1}
                        ref={this.formRef}
                        id={'formPemberiKerja'}
                        formData={this.state.dataMaster}
                        items={this.formMaster}
                        labelLocation = {'left'}
                    />
                    {/* <FileUploader
                        allowCanceling={true}
                        // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)} 
                        labelText={this.state.dataMaster.dokumenFileName || 'Upload File Bukti Transfer Disini'}
                        showFileList={false}
                        name={'dokumen'}
                    /> */}
                    <DevExpressDataGrid
                        ref = {this.dataGridDetailRef}
                        loadAPI={this.state.getUrl}
                        insertAPI='iuran-by-types'
                        updateAPI='iuran-by-types'
                        deleteAPI='iuran-by-types' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        
                        useArraySource = {true}
                        ArraySourceData = {this.loadDataGrid}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={this.props.step == 'pengajuan' ? true : false}
                        editingMode = {'row'}
                        
                        exportExcel={true}
                        exportFileName={"Data Detail"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Detail'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columnsDetail} // taruh konfigurasi kolom disini
                        SummaryConfiguration={this.summary}
                        // onRowUpdated={this.onRowUpdatedKonfirmasi}

                        menuRightClick= {false}

                        height = {'calc(100vh - 350px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalKonfirmasiPiutangIuran