import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, banks, months, product, productDefault, programs } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate } from 'plugin/helper'

class LaporanDaftarPinjaman extends Component {
    constructor(props) {
        super(props)

        this.dgRef = React.createRef()

        this.state = {
            filterData: {
                programId: 1,
            }
        }

        this.columns = [
            {
                dataField: 'noPengajuan',
                caption: 'No Pengajuan',
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan',
            },
            {
                dataField: 'loanName',
                caption: 'Tipe Pinjaman',
            },
            {
                dataField: 'loanDate',
                caption: 'Tanggal Pinjaman',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'loanAmount',
                caption: 'Nominal Pinjaman',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'potongan',
                caption: 'Potongan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'jumCicilan',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'loanDate',
                        caption: 'Tanggal Awal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'maturityDate',
                        caption: 'Tanggal Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tenor',
                        caption: 'Tenor (Bulan)',
                    },
                ]
            },
            {
                dataField: 'sisaPeriode',
                caption: 'Sisa Periode'
            }
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.program
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Laporan Daftar Pinjaman</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI={`view-report-gaji-daftar-pinjamen`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Laporan Daftar Pinjaman"}
                    allowExportSelectedData={true}
                    selection={true}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanDaftarPinjaman