import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { currency, banks, productDefault, fundManager, instrumentSubCategoryById, rekeningBank, instrumentTransactionTypes, needSettleSbnPelepasan, instruments, needSettleObliBondSukukPelepasan, catNameObliBondSukukPelepasan } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatDefaultDate, formatUploadFileData, imageSource, pembulatan } from 'plugin/helper';
import ScrollView from 'devextreme-react/scroll-view';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class ModalActionSettlementPelepasanObligasiKpdNotOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            popupVisible: false,
            dataMaster: {
                orderTypeId: 242,
            },
            dataBank: {}
        }


        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.pphBb = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-B-Bond') : null
        this.feeCg = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-CG-Bond-DN') : null


        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.formBankRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.kpdId = 0
        this.productId = 0
        this.price = 0
        this.efekBalanceBuyDateId = 0
        this.htm = false

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formMaster = [
            {
                itemType: 'group',
                caption: 'Transaksi',
                items: [

                    {
                        dataField: 'categoryId',
                        label: {
                            alignment: 'left',
                            text: 'SBN/BOND/Sukuk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'categoryName',
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    var selectOrderId = this.formRef.current.instance.getEditor('instrumentId')
                                    let loadAPI = `efek-balance-buy-dates?categoryId.equals=${e.selectedItem.id}&faceValue.greaterThan=0&kpdId.equals=${this.kpdId}&productId.equals=${this.productId}`
                                    var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')

                                    var dataSource = []
                                    for (let data of dataInstrument) {
                                        let dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`, 'GET')
                                        data.instrumentName = dataInstrument.instrumentName
                                        data.instrumentCode = dataInstrument.instrumentCode
                                        dataSource.push(data)
                                    }

                                    // selectOrderId.option('dataSource', needSettleObliBondSukukPelepasan(this.props.store, e.selectedItem.id, this.kpdId, this.productId))
                                    selectOrderId.option('dataSource', dataSource)

                                }
                            }
                        }
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            alignment: 'left',
                            text: 'Instruments'
                        },
                        editorType: 'dxSelectBox',
                        // visible: this.props.kpdId == 1 ? true : false,
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'instrumentId',
                            displayExpr: (e) => {
                                // var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${e.instrumentId}`, 'GET')
                                return e && e.instrumentCode + ' - (' + formatDefaultDate(e.buyDate) + ') - ' + e.instrumentName
                            },
                            onSelectionChanged: async (e) => {
                                var data = e.selectedItem
                                if (data) {
                                    this.price = data.price
                                    this.efekBalanceBuyDateId = data.id
                                    this.htm = data.htm

                                    var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`, 'GET')

                                    if (data) {
                                        // this.formRef.current.instance.getEditor('instrumentName').option('value', dataInstrument.instrumentName)
                                        this.formRef.current.instance.getEditor('faceValue').option('value', data.faceValue)
                                        this.formRef.current.instance.getEditor('hargaPenjualan').option('value', data.price)
                                        this.formRef.current.instance.getEditor('hargaPerolehan').option('value', data.buyPrice)
                                        this.formRef.current.instance.getEditor('currencyId').option('value', dataInstrument.currencyId)
                                        this.formRef.current.instance.updateData('capitalGain', data.faceValue * ((data.price - data.buyPrice) / 100))
                                        this.setState({
                                            instrumentId: data.instrumentId,
                                            couponLastDate: dataInstrument.couponLastDate,
                                            couponNextDate: dataInstrument.couponNextDate,
                                            rate: dataInstrument.rate,
                                            dayBases: dataInstrument.dayBase,
                                            periodeCoupon: dataInstrument.couponPeriod,
                                        })
                                    }
                                }
                            }
                            // onSelectionChanged: async (e) => {
                            //     var data = e.selectedItem

                            //     if (data) {
                            //         var dataOrderSbn = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-sbn-bond-sukuks/${data.orderObligasiId}`, 'GET')
                            //         var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataOrderSbn.instrumentId}`, 'GET')
                            //         var dataEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/${dataOrderSbn.efekBalanceBuyDateId}`, 'GET')
                            //         var tanggalPenyelesaian = this.formRef.current.instance.getEditor('tanggalPenyelesaian').option('value')
                            //         this.formRef.current.instance.getEditor('instrumentName').option('value', data.instrumentName)
                            //         this.formRef.current.instance.getEditor('orderTypeId').option('value', dataOrderSbn.orderTypeId)
                            //         this.formRef.current.instance.getEditor('faceValue').option('value', dataOrderSbn.faceValue)
                            //         this.formRef.current.instance.getEditor('hargaPenjualan').option('value', dataOrderSbn.price)
                            //         this.formRef.current.instance.getEditor('hargaPerolehan').option('value', dataEfekBalance.buyPrice)
                            //         this.formRef.current.instance.getEditor('currencyId').option('value', dataInstrument.currencyId)
                            //         this.formBankRef.current.instance.getEditor('rekBankId').option('value', dataOrderSbn.rekBankId)
                            //         this.formRef.current.instance.updateData('capitalGain', dataOrderSbn.faceValue * ((dataOrderSbn.price - dataEfekBalance.buyPrice) / 100))
                            //         this.setState({
                            //             instrumentId: dataOrderSbn.instrumentId,
                            //             orderSbnId: data.orderObligasiId,
                            //             couponLastDate: dataInstrument.couponLastDate,
                            //             couponNextDate: dataInstrument.couponNextDate,
                            //             rate: dataInstrument.rate,
                            //             dayBases: dataInstrument.dayBase,
                            //             periodeCoupon: dataInstrument.couponPeriod,
                            //         })
                            //         this.hitungAccruedInterest(tanggalPenyelesaian, this.state.rate, this.state.couponLastDate, dataOrderSbn.faceValue, dataInstrument.dayBase, this.state.periodeCoupon, this.state.couponNextDate)
                            //     }
                            // }
                        }
                    },
                    {
                        dataField: 'orderTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentTransactionTypes(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'transactionName',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'tanggalTransaksi',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            readOnly: true,
                            // onValueChanged: async (e) => {
                            //     var today = new Date(e.value);
                            //     var tomorrow = new Date(today);
                            //     tomorrow.setDate(today.getDate() + 1);
                            //     tomorrow.toLocaleDateString();
                            //     if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                            //         tomorrow.setDate(tomorrow.getDate() + 1);
                            //         tomorrow.toLocaleDateString();
                            //         if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                            //             tomorrow.setDate(tomorrow.getDate() + 1);
                            //             tomorrow.toLocaleDateString();
                            //         }
                            //     }
                            //     // var holidays = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'holidays ', 'GET')
                            //     // if (this.getHoliday.find(val => new Date(val.holidayDate) == tomorrow)) {
                            //     //     tomorrow.setDate(tomorrow.getDate() + 1);
                            //     //     tomorrow.toLocaleDateString();
                            //     // }
                            //     this.formRef.current.instance.updateData('tanggalPembayaran', tomorrow)
                            //     this.formRef.current.instance.updateData('tanggalPenyelesaian', tomorrow)
                            // }
                        }
                    },
                    {
                        dataField: 'tanggalPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Penerimaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'tanggalPenyelesaian',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Penyelesaian'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            // onValueChanged: (e) => {
                            //     var tanggalPenyelesaian = e.value
                            //     var fv = this.formRef.current.instance.getEditor('faceValue').option('value')
                            //     this.hitungAccruedInterest(tanggalPenyelesaian, this.state.rate, this.state.couponLastDate, fv, this.state.dayBases, this.state.periodeCoupon, this.state.couponNextDate)
                            // }
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            readOnly: true,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formRef.current.instance.updateData('kurs', e.selectedItem.rate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            alignment: 'left',
                            text: 'Rate'
                        },
                    },
                    {
                        dataField: 'hargaPerolehan',
                        label: {
                            alignment: 'left',
                            text: 'Harga Perolehan (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            alignment: 'left',
                            text: 'Face Value'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'hargaPenjualan',
                        label: {
                            alignment: 'left',
                            text: 'Harga Penjualan (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            onValueChanged: (e) => {
                                var fv = this.formRef.current.instance.getEditor('faceValue').option('value')
                                var hargaPerolehan = this.formRef.current.instance.getEditor('hargaPerolehan').option('value')
                                this.formRef.current.instance.updateData('proceed', fv * (e.value / 100))
                                this.formRef.current.instance.updateData('capitalGain', fv * ((e.value - hargaPerolehan) / 100))
                            }
                        }
                    },
                    {
                        dataField: 'proceed',
                        label: {
                            alignment: 'left',
                            text: 'Proceed'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bungaBerjalan',
                        label: {
                            alignment: 'left',
                            text: 'Bunga Berjalan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData('pphBungaBerjalan', e.value * (this.pphBb.floatValue / 100))

                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = e.value
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Bunga berjalan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pphBungaBerjalan',
                        label: {
                            alignment: 'left',
                            text: 'PPH Bunga Berjalan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = e.value
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'PPH Bunga berjalan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'capitalGain',
                        label: {
                            alignment: 'left',
                            text: 'Capital Gain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData('pajakCapitalGain', e.value * (this.feeCg.floatValue / 100))
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Capital Gain tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pajakCapitalGain',
                        label: {
                            alignment: 'left',
                            text: 'Pajak Capital Gain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = e.value
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'PPH Capital Gain tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'komisi',
                        label: {
                            alignment: 'left',
                            text: 'Komisi'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = e.value
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Komisi tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'biayaLainnya',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Lainnya'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = e.value
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Biaya lainnya tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'netProceed',
                        label: {
                            alignment: 'left',
                            text: 'Net Proceed'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
        ]

        this.formBank = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Bank',
                        items: [
                            {
                                dataField: 'rekBankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Bayar (Transfer)'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    }
                                }
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    alignment: 'left',
                                    text: 'Biaya Bank'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Catatan',
                        items: [
                            {
                                dataField: "catatan",
                                label: {
                                    visible: false
                                },
                                editorType: "dxTextArea",
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Dokumen',
                        items: [
                            {
                                dataField: "file",
                                label: {
                                    text: "File Konfirmasi",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    },
                ]
            }
        ]
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    previewFile = async () => {
        var src = imageSource(this.state.fileKonfirmasiContentType, this.state.fileKonfirmasi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    submitData = async () => {
        var data = this.state.dataMaster
        var dataBank = this.state.dataBank

        this.props.submitDetailPelepasanData({
            id: data.id,
            biayaBank: dataBank.biayaBank,
            biayaLainnya: data.biayaLainnya,
            bungaBerjalan: data.bungaBerjalan,
            capitalGain: data.capitalGain,
            catatan: data.catatan,
            faceValue: data.faceValue,
            fileKonfirmasi: this.state.fileKonfirmasi,
            fileKonfirmasiContentType: this.state.fileKonfirmasiContentType,
            hargaPembelian: data.hargaPembelian,
            hargaPenjualan: data.hargaPenjualan,
            hargaPerolehan: data.hargaPerolehan,
            instrumentId: this.state.instrumentId,
            komisi: data.komisi,
            kurs: data.kurs,
            proceed: data.proceed,
            netProceed: data.netProceed,
            rate: this.state.rate,
            couponLastDate: this.state.couponLastDate,
            couponNextDate: this.state.couponNextDate,
            dayBases: this.state.dayBases,
            periodeCoupon: this.state.periodeCoupon,
            pajakCapitalGain: data.pajakCapitalGain,
            pasar: data.pasar,
            pphBungaBerjalan: data.pphBungaBerjalan,
            tanggalPembayaran: data.tanggalPembayaran,
            tanggalPenyelesaian: data.tanggalPenyelesaian,
            tanggalTransaksi: data.tanggalTransaksi,
            orderTypeId: data.orderTypeId,
            productId: data.productId,
            categoryId: data.categoryId,
            currencyId: data.currencyId,
            rekBankId: dataBank.rekBankId,
            kpdId: this.kpdId,
            price: this.price,
            html: this.htm,
            efekBalanceBuyDateId: this.efekBalanceBuyDateId,
        })

        this.hide()
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Konfirmasi`]: formattedFileData.base64data,
                    [`${fieldName}KonfirmasiContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }

    show = async (data, kpdId) => {
        this.kpdId = kpdId
        this.productId = data.productId
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                biayaLainnya: data.biayaLainnya,
                bungaBerjalan: data.bungaBerjalan,
                capitalGain: data.capitalGain,
                catatan: data.catatan,
                faceValue: data.faceValue,
                hargaPembelian: data.hargaPembelian,
                hargaPenjualan: data.hargaPenjualan,
                hargaPerolehan: data.hargaPerolehan,
                komisi: data.komisi,
                kurs: data.kurs,
                proceed: data.proceed,
                netProceed: data.netProceed,
                pajakCapitalGain: data.pajakCapitalGain,
                pasar: data.pasar,
                pphBungaBerjalan: data.pphBungaBerjalan,
                tanggalPembayaran: data.tanggalPembayaran,
                tanggalPenyelesaian: data.tanggalPenyelesaian,
                tanggalTransaksi: data.tanggalTransaksi,
                orderTypeId: data.orderTypeId,
                productId: data.productId,
                instrumentId: data.instrumentId,
                categoryId: data.categoryId,
                currencyId: data.currencyId,
                price: data.price,
                html: data.htm,
                efekBalanceBuyDateId: data.efekBalanceBuyDateId,
            },
            dataBank: {
                biayaBank: data.biayaBank,
                rekBankId: data.rekBankId,
            },
            fileKonfirmasi: data.fileKonfirmasi,
            fileKonfirmasiContentType: data.fileKonfirmasiContentType,
            instrumentId: data.instrumentId,
            rate: data.rate,
            couponLastDate: data.couponLastDate,
            couponNextDate: data.couponNextDate,
            dayBases: data.dayBases,
            periodeCoupon: data.periodeCoupon,
        })
        var dataTipe = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-transaction-types/242`, 'GET')

        var selectOrderId = this.formRef.current.instance.getEditor('categoryId')
        selectOrderId.option('dataSource', catNameObliBondSukukPelepasan(this.props.store))
        this.formRef.current.instance.updateData({ orderTypeId: dataTipe.id })

    }

    hide = () => {
        this.kpdId = 0
        this.productId = 0
        this.price = 0
        this.efekBalanceBuyDateId = 0
        this.htm = false
        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataBank: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pelepasan Obligasiaa'}
                minWidth={'60vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    ref={this.formRef}
                                    formData={this.state.dataMaster}
                                    items={this.formMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataBank'}
                                    ref={this.formBankRef}
                                    formData={this.state.dataBank}
                                    items={this.formBank}
                                    labelLocation={"left"}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileKonfirmasi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                            </div>
                            <ModalPreviewPdf
                                ref={this.modalPreviewPdfRef}
                                store={this.props.store}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>

        )
    }
}

export default ModalActionSettlementPelepasanObligasiKpdNotOne