import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { alert } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import KlaimDetailBaru from 'components/pages/modal/kepesertaan/pembayaranMp/klaimMp/klaimDetail';
import KlaimProsesBaru from 'components/pages/modal/kepesertaan/pembayaranMp/klaimMp/klaimProses';
import ModalCatatan from 'components/pages/modal/kepesertaan/compPeserta/catatan';
import httpRequest from 'plugin/httprequest';
import { klaimType, media } from 'dataSource/lookup';

class KlaimMpProses extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {}
    }

    this.dataGridRef = React.createRef()
    this.klaimDetailBaruRef = React.createRef()
    this.klaimProsesBaruRef = React.createRef()
    this.modalCatatanRef = React.createRef()

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Detail',
            hint: 'Detail',
            onClick: (e) => {
              this.showModalDetail(e.row.data)
            }
          },
          {
            text: 'Proses',
            hint: 'Proses',
            visible: true,
            onClick: (e) => {
              this.showModalProses(e.row.data)
            },
          },
        ],
      },
      {
        dataField: 'id',
        caption: "No.",
        cellRender: (data) => {
          return data.rowIndex + 1
        },
      },
      {
        dataField: 'klaimDate',
        caption: 'Tanggal',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right'
      },
      {
        dataField: 'mediaId',
        caption: 'Media',
        lookup : {
          dataSource : media(this.props.store),
          valueExpr : 'id',
          displayExpr : 'mediaName'
        }
      },
      {
        dataField: 'fullName',
        caption: 'Peserta',
      },
      {
        dataField: 'klaimTypeId',
        caption: 'Jenis Klaim',
        lookup : {
          dataSource: klaimType(this.props.store),
          displayExpr: 'klaimTypeName',
          valueExpr: 'id',
        }
      },
      {
        dataField: 'keterangan',
        caption: 'Keterangan',
      },
      {
        dataField: 'verifikasiBy',
        caption: 'Verifikasi Oleh',
      },
      {
        dataField: 'verifikasiDate',
        caption: 'Tanggal Verifikasi',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right'
      },
      {
        dataField: 'verifikasiNote',
        caption: 'Catatan Verifikasi',
      },
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          text: 'Klaim Manual',
          onClick: (e) => {
            // this.showModalGeneratePembayaran()
          },
        }
      }
    )
  }

  showModalDetail = (data) => {
    this.klaimDetailBaruRef.current.show()
    this.klaimDetailBaruRef.current.retrieveData(data)
  }
  showModalProses = (data) => {
    this.klaimProsesBaruRef.current.show()
    this.klaimProsesBaruRef.current.retrieveData(data)
  }

  showModalCatatan = (data, status) => {
    data.statusId = status
    this.modalCatatanRef.current.show(data)
  }

  changeStatus = async(data, catatan) => {
    try {
      var loadAPI = 'peserta-klaims/'+data.id
      var getData = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
  
      var updateData = getData
      updateData.statusId = data.statusId
      updateData.verifikasiNote = catatan
      updateData.verifikasiBy = this.props.store.getState().sessionUser
      updateData.verifikasiDate = new Date()
  
      var updateAPI = 'peserta-klaims'
      await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
          values: updateData,
          key: updateData.id
      }, getData)
      
      await alert('Data Berhasil Diproses!', 'Berhasil!')

      this.klaimDetailBaruRef.current.hide()
      this.forceRefresh()
    } catch (e) {
      await alert(e, 'Gagal!')
    }
  }

  forceRefresh = () => {
    this.dataGridRef.current.forceRefresh(true)
  }
  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Klaim Proses</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
        // items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='view-klaim-mps?statusId.in=2'
          insertAPI='peserta-klaims'
          updateAPI='peserta-klaims'
          deleteAPI='peserta-klaims'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Klaim MP Baru"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Klaim MP Baru'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          // SummaryConfiguration={this.summary}

          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />

        <KlaimDetailBaru
          ref={this.klaimDetailBaruRef}
          store={this.props.store}
          module = {'proses'}
        />

        <KlaimProsesBaru
          ref={this.klaimProsesBaruRef}
          store={this.props.store}
          showModalCatatan = {this.showModalCatatan}
          module = {'proses'}
        />
        <ModalCatatan
          ref={this.modalCatatanRef} 
          store={this.props.store}
          submitData = {this.changeStatus}
        />
      </div>
    )
  }
}

export default KlaimMpProses