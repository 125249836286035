import React from 'react'
import axios from 'axios'
import { blobToBase64 } from './helper'

const reportRequest = async (store, data) => {
  var response = await axios.post(process.env.REACT_APP_REPORT, data, {
    headers: {
      Authorization: `Basic ${btoa(process.env.REACT_APP_REPORT_USERNAME + ':' + process.env.REACT_APP_REPORT_PASSWORD)}`,
      "Content-Type": 'application/json'
    }
  })

  return response
}

export const reportFile = async (data, isDownload = false, fileName) => {
  var res
  try {
    res = await fetch(process.env.REACT_APP_REPORT, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${btoa(process.env.REACT_APP_REPORT_USERNAME + ':' + process.env.REACT_APP_REPORT_PASSWORD)}`,
        "Content-Type": 'application/json',
      },
      body: JSON.stringify(data)
    }, { timeout: 99999999 });
    const blob = await res.blob();

    if (isDownload) {
      var a = document.createElement("a"),
        url = URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      // setTimeout(function() {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      // }, 0); 
    } else {
      // var base64data = await blobToBase64(blob)
      var file = window.URL.createObjectURL(blob);

      return file
      // return base64data
    }
  } catch (e) {

  }
}

export default reportRequest