import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import { showLoading } from 'redux/actions/loading';
import * as XLSX from 'xlsx-pro';
import Excel from 'exceljs/dist/exceljs';
import { formatDate, formatDateDmy, formatDefaultDate, formatNumber, formatNumberAum, formatNumberB } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import CashFlow from './cashFlow';
import BalanceSheet from './balanceSheet';
import ProfitLoss from './profitLoss';
import { akunGroupInvestasi, coaGroup, participants, product } from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog'
import AkuntansiAsetNeto from './asetNeto';
import AkuntansiPerubahanAsetNeto from './perubahanAsetNeto';
import FixedAsset from '../fixedAsset/index';

class LaporanOJK extends Component {
  constructor(props) {
    super(props)

    this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : { dateValue: null }

    this.state = {
      formFilter: {
        period: new Date(this.systemDate.dateValue),
        participantId: null,
        reportType: 'S',
        produkId: null,
        coaGroupId: null,
        akunGroupId: null,
      }
    }

    this.filterItem = [
      {
        dataField: "period",
        label: {
          text: "Periode",
          alignment: "left",
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'MMM yyyy',
          calendarOptions: {
            maxZoomLevel: 'year'
          },
          openOnFieldClick: true
        }
      },
      {
        dataField: "participantId",
        label: {
          text: "Partisipan",
          alignment: "left",
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: participants(this.props.store),
          displayExpr: function (item) {
            return item && item.participantCode + " - " + item.participantName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: "produkId",
        label: {
          text: "Produk",
          alignment: "left",
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: product(this.props.store),
          displayExpr: function (item) {
            return item && item.productCode + " - " + item.productName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      // {
      //   dataField: "coaGroupId",
      //   label: {
      //     text: "Coa Group",
      //     alignment: "left",
      //   },
      //   editorType: "dxSelectBox",
      //   editorOptions: {
      //     dataSource: coaGroup(this.props.store),
      //     displayExpr: function (item) {
      //       return item && item.coaGroupCode + " - " + item.coaGroupName;
      //     },
      //     valueExpr: 'id',
      //     searchEnabled: true,
      //     deferRendering: false,
      //   }
      // },
      // {
      //   dataField: "akunGroupId",
      //   label: {
      //     text: "Akun Group",
      //     alignment: "left",
      //   },
      //   editorType: "dxSelectBox",
      //   editorOptions: {
      //     dataSource: akunGroupInvestasi(this.props.store),
      //     displayExpr: function (item) {
      //       return item && item.groupCode + " - " + item.groupName;
      //     },
      //     valueExpr: 'id',
      //     searchEnabled: true,
      //     deferRendering: false,
      //   }
      // },
      {
        dataField: "reportType",
        label: {
          text: "Jenis Laporan",
          alignment: "left",
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: [
            {
              id: 'S',
              value: 'Sederhana'
            },
            {
              id: 'D',
              value: 'Detail'
            },
          ],
          displayExpr: 'value',
          valueExpr: 'id'
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Download Dokumen",
          // type:"default",
          elementAttr: { class: "bg-dapen-default mt-3" },
          onClick: () => {
            if (this.state.formFilter.produkId === null) {
              alert('Mohon pilih produk terlebih dahulu', 'Error')

              return null
            }
            if (this.state.formFilter.reportType === 'S') {
              this.generateDocumentSederhana()
            } else {
              this.generateDocumentDetail()
            }
          },
        },
        horizontalAlignment: "left"
      },
    ]
    this.dataBank = []
  }

  componentWillMount = async () => {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))

    var getBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `banks`, 'GET')
    this.dataBank = getBank
  }

  generateDocumentSederhana = async () => {
    this.props.store.dispatch(showLoading(true))
    var period = this.state.formFilter.period
    const selectedMonth = period.getMonth()
    const selectedYear = period.getFullYear();
    period = new Date(selectedYear, selectedMonth + 1, 0);

    var namaDapen = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 NP`)
    var kodePerusahaan = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 KP`)
    var alamat = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 AP`)
    alamat = alamat.stringValue.split(';')
    var alamatCount = alamat.length
    var alamatArray = []
    if (alamatCount > 3) {
      for (var j = 0; j < 3; j++) {
        alamatArray.push('')
        for (var i = Math.ceil(alamatCount / 3) * j; i < Math.ceil(alamatCount / 3) * (j + 1); i++) {
          if (alamat[i]) {
            alamatArray[j] = alamatArray[j] + alamat[i] + ','
          }
        }
        alamatArray[j] = alamatArray[j].slice(0, -1)
      }
    } else {
      alamatArray[0] = alamat[0] || ''
      alamatArray[1] = alamat[1] || ''
      alamatArray[2] = alamat[2] || ''
    }
    var jabatanPenanggungJawab = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 JPJ`)
    var namaPenanggungJawab = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 NPJ`)

    const res = await fetch('/file_upload/Laporan OJK-sederhana.xlsx');
    const blob = await res.blob();

    const dataArrayBuffer = await blob.arrayBuffer()

    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(dataArrayBuffer);

    // var wb = XLSX.read(dataArrayBuffer,{type:'array',cellText:false,cellDates:true});

    var workSheetNames = [
      'Data Umum',
      'LAN',
      'LPAN',
      'NERACA',
      'LPHU',
      'LAK',
      'REK_INV',
      'ROI',
      'ALM',
      'Peserta',
      'RINC_SBN'
    ]

    for (const workSheetName of workSheetNames) {
      var worksheet = workbook.getWorksheet(workSheetName);
      switch (workSheetName) {
        case 'Data Umum':
          worksheet.getCell('D5').value = new Date(this.systemDate.dateValue);
          worksheet.getCell('D5').value = new Date(this.systemDate.dateValue)
          worksheet.getCell('D6').value = kodePerusahaan.stringValue
          worksheet.getCell('D7').value = namaDapen.stringValue
          worksheet.getCell('D8').value = alamatArray[0]
          worksheet.getCell('D9').value = alamatArray[1]
          worksheet.getCell('D10').value = alamatArray[2]
          worksheet.getCell('D11').value = period
          worksheet.getCell('D15').value = namaPenanggungJawab.stringValue
          worksheet.getCell('D16').value = jabatanPenanggungJawab.stringValue
          break;
        case 'LAN': const asetNeto = new AkuntansiAsetNeto({ store: this.props.store, })
          let dataAsetNetos = await asetNeto.loadData(this.state.formFilter.produkId, period)

          const skipRowAsetNeto = [16, 17, 27, 42, 43, 46, 53, 55, 65, 74, 75]
          dataAsetNetos = dataAsetNetos.filter(value => !skipRowAsetNeto.includes(value.seqNo) && value.seqNo)
          for (const dataAsetNeto of dataAsetNetos) {
            worksheet.getCell(`E${dataAsetNeto.seqNo}`).value = dataAsetNeto.currentAmount;
            worksheet.getCell(`F${dataAsetNeto.seqNo}`).value = dataAsetNeto.prevAmount;
          }
          break;
        case 'LPAN': const perubahanAsetNeto = new AkuntansiPerubahanAsetNeto({ store: this.props.store, })
          let dataPerubahanAsetNetos = await perubahanAsetNeto.loadData(this.state.formFilter.produkId, period)

          const skipRowPerubahanAsetNeto = [16, 23, 25, 31, 32, 39, 40, 42]
          dataPerubahanAsetNetos = dataPerubahanAsetNetos.filter(value => !skipRowPerubahanAsetNeto.includes(value.seqNo) && value.seqNo)
          for (const dataPerubahanAsetNeto of dataPerubahanAsetNetos) {
            worksheet.getCell(`E${dataPerubahanAsetNeto.seqNo}`).value = dataPerubahanAsetNeto.currentAmount;
            worksheet.getCell(`F${dataPerubahanAsetNeto.seqNo}`).value = dataPerubahanAsetNeto.prevAmount;
          }
          break;
        case 'NERACA': const neraca = new BalanceSheet({ store: this.props.store, })
          let dataNeracas = await neraca.printOJK(this.state.formFilter.produkId, period)

          const skipRowNeraca = [16, 17, 27, 42, 43, 46, 53, 55, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 74, 75, 76, 77, 80, 81, 82, 83, 84, 85, 86, 87]
          dataNeracas = dataNeracas.filter(value => !skipRowNeraca.includes(value.reff))
          for (const dataNeraca of dataNeracas) {
            worksheet.getCell(`E${dataNeraca.reff}`).value = dataNeraca.balanceAmount;
            worksheet.getCell(`F${dataNeraca.reff}`).value = dataNeraca.balanceAmountLastMonth;
          }
          break;
        case 'LPHU': const profitloss = new ProfitLoss({ store: this.props.store })
          let dataProfitLoss = await profitloss.filterSubmit(this.state.formFilter.produkId, period)
          const skipRowProfitLoss = [16, 17, 18, 19, 20, 21, 22, 23, 39, 30, 31, 38, 39, 45, 46, 48]
          for (var i = 0; i < dataProfitLoss.length; i++) {
            const skipRowFoundProfitLoss = skipRowProfitLoss.find(value => value === 16 + i)
            if (!skipRowFoundProfitLoss) {
              worksheet.getCell(`E${16 + i}`).value = dataProfitLoss[i].currentAmount;
              worksheet.getCell(`F${16 + i}`).value = dataProfitLoss[i].prevAmount;
            }
          }
          break;
        case 'LAK': const cashFlow = new CashFlow({ store: this.props.store })
          let dataCashFlow = await cashFlow.filterSubmit(this.state.formFilter.produkId, period)
          const skipRowCashFlow = [16, 24, 25, 34, 35, 43, 44, 46]
          for (var i = 0; i < dataCashFlow.length; i++) {
            const skipRowFoundCashFlow = skipRowCashFlow.find(value => value === 16 + i)
            if (!skipRowFoundCashFlow) {
              worksheet.getCell(`E${16 + i}`).value = dataCashFlow[i].currentAmount
              worksheet.getCell(`F${16 + i}`).value = dataCashFlow[i].prevAmount
            }
          }
          break;
        //   case 'REK_INV'   :  await this.rekInv(wb.Sheets[workbook])
        //                       break;
        //   case 'ALM'       :  await this.alm(wb.Sheets[workbook])
        //                       break;
        case 'Peserta': let dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByProductId/${this.state.formFilter.produkId}`)
          dataPeserta = dataPeserta.length > 0 ? dataPeserta[0] : {}
          worksheet.getCell('E17').value = dataPeserta.pesertaAktif
          worksheet.getCell('E18').value = 0
          worksheet.getCell('E21').value = dataPeserta.pensiunan
          worksheet.getCell('E22').value = dataPeserta.jandaDuda
          worksheet.getCell('E23').value = dataPeserta.anak
          worksheet.getCell('E24').value = dataPeserta.pensiunanDitunda
          worksheet.getCell('E26').value = 0
          worksheet.getCell('E27').value = 0
          worksheet.getCell('E28').value = 0
          worksheet.getCell('E29').value = 0

          break;
        // case 'RINC_SBN'  :  await this.rincSBN(wb.Sheets[workbook])
        //                     break;
      }
    }

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });


    this.props.store.dispatch(showLoading(false))
    var a = document.createElement("a"),
      url = URL.createObjectURL(data);
    a.href = url;
    period = formatDefaultDate(period).split(' ')
    a.download = 'Laporan OJK Sederhana ' + period[1] + '-' + period[2] + fileExtension;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  generateDocumentDetail = async () => {
    this.props.store.dispatch(showLoading(true))

    var period = this.state.formFilter.period
    var participantId = this.state.formFilter.participantId
    const selectedMonth = period.getMonth()
    const selectedYear = period.getFullYear();
    period = new Date(selectedYear, selectedMonth + 1, 0);
    period.setHours(7)

    var namaDapen = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 NP`)
    var kodePerusahaan = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 KP`)
    var alamat = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 AP`)
    alamat = alamat.stringValue.split(';')
    var alamatCount = alamat.length
    var alamatArray = []
    if (alamatCount > 3) {
      for (var j = 0; j < 3; j++) {
        alamatArray.push('')
        for (var i = Math.ceil(alamatCount / 3) * j; i < Math.ceil(alamatCount / 3) * (j + 1); i++) {
          if (alamat[i]) {
            alamatArray[j] = alamatArray[j] + alamat[i] + ','
          }
        }
        alamatArray[j] = alamatArray[j].slice(0, -1)
      }
    } else {
      alamatArray[0] = alamat[0] || ''
      alamatArray[1] = alamat[1] || ''
      alamatArray[2] = alamat[2] || ''
    }
    var jabatanPenanggungJawab = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 JPJ`)
    var namaPenanggungJawab = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 NPJ`)
    var teleponPenanggungJawab = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 TPJ`)
    var faxPenanggungJawab = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 FPJ`)
    var emailPenanggungJawab = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 EPJ`)

    var divisiPetugas = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 DPT`)
    var namaPetugas = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 NPT`)
    var teleponPetugas = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 TPT`)
    var faxPetugas = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 FPT`)
    var emailPetugas = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 EPT`)

    var sid = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/1 SID`)

    const res = await fetch('/file_upload/Laporan OJK-detail.xlsx');
    const blob = await res.blob();

    const dataArrayBuffer = await blob.arrayBuffer()

    const workbook = new Excel.Workbook();
    await workbook.xlsx.load(dataArrayBuffer);

    var workSheetNames = [
      'Data Umum',
      'DTUM',
      'LAN',
      'LPAN',
      'NRC',
      'LPHU',
      'LAK',
      'PST',
      'KUP1',
      'KUP2',
      'KUP',
      'REKINV',
      'ROI',
      'ROI ML',
      'ALM',
      'INSP',
      'INPT',
      'INBM',
      'SBN',
      'RAS1',
      'RAS2',
      'RAS3',
      'RAS4',
      'RAS5',
      'PMI',
      'TAB',
      'DOC',
      'DPJKA',
      'DPJKV',
      'SRDP',
      'SBI',
      'RSBN',
      'SHM',
      'OBLI',
      'SUKUK',
      'OBSUD',
      'RKSD',
      'MTN',
      'EBA',
      'DIRE',
      'DNFRA',
      'KOKB',
      'REPO',
      'PNYL',
      'PROP',
      'KASB',
      'PIUT',
      'PIUB',
      'BBMK',
      'PIUI',
      'PIHI',
      'PILL',
      'TNBG',
      'KNDR',
      'PKOM',
      'PKAN',
      'ASOL',
      'ASLN',
      'UMPJ',
      'UMPS',
      'UTIN',
      'PDDM',
      'BMHB',
      'UTLN',
      'PPIN',
      'IUJT',
      'PDIN',
      'PDPL',
      'BINV',
      'BOPR',
      'BIPR',
      'PPH',
      'PKPL',
    ]

    for (const workSheetName of workSheetNames) {
      try {
        var worksheet = workbook.getWorksheet(workSheetName);
        switch (workSheetName) {
          case 'Data Umum':
            worksheet.getCell('D5').value = new Date(this.systemDate.dateValue);
            worksheet.getCell('D5').value = new Date(this.systemDate.dateValue)
            worksheet.getCell('D6').value = kodePerusahaan.stringValue
            worksheet.getCell('D7').value = namaDapen.stringValue
            worksheet.getCell('D8').value = alamatArray[0]
            worksheet.getCell('D9').value = alamatArray[1]
            worksheet.getCell('D10').value = alamatArray[2]
            worksheet.getColumn(4).width = 60
            worksheet.getCell('D11').value = period
            worksheet.getCell('D15').value = namaPenanggungJawab.stringValue
            worksheet.getCell('D16').value = jabatanPenanggungJawab.stringValue
            console.log('DATA UMUM')
            break;
          case 'DTUM':
            worksheet.getCell('E16').value = sid.stringValue;
            worksheet.getCell('E18').value = namaPetugas.stringValue
            worksheet.getCell('E19').value = divisiPetugas.stringValue
            worksheet.getCell('E20').value = teleponPetugas.stringValue
            worksheet.getCell('E21').value = faxPetugas.stringValue
            worksheet.getCell('E22').value = emailPetugas.stringValue
            worksheet.getCell('E24').value = namaPenanggungJawab.stringValue
            worksheet.getCell('E25').value = jabatanPenanggungJawab.stringValue
            worksheet.getCell('E26').value = teleponPenanggungJawab.stringValue
            worksheet.getCell('E27').value = faxPenanggungJawab.stringValue
            worksheet.getCell('E28').value = emailPenanggungJawab.stringValue
            console.log('DTUM')
            break;
          case 'LAN': let dataLan = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/getOjkGenBalance/${formatDate(period)}/${this.state.formFilter.produkId}/LAN`)
            console.log(dataLan)
            for (let i = 0; i < dataLan.length; i++) {
              // worksheet.getCell(`E${16 + i}`).value = '0%'
              // worksheet.getCell(`F${16 + i}`).value = dataLan[i].program_pensiun
              // worksheet.getCell(`G${16 + i}`).value = dataLan[i].program_ml
              // worksheet.getCell(`H${16 + i}`).value = dataLan[i].gabungan
            }
            break;
          case 'LPAN': let dataLpan = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkLpan/${formatDate(period)}/${this.state.formFilter.produkId}`)
            
            worksheet.getCell(`E37`).value = dataLpan[0].program_pensiun;
            worksheet.getCell(`F37`).value = formatNumberB(dataLpan[0].manfaat_lain);
            worksheet.getCell(`E42`).value = dataLpan[1].program_pensiun;
            worksheet.getCell(`F42`).value = dataLpan[1].manfaat_lain;

            worksheet.getCell(`E42`).alignment = { horizontal: 'right' }
            worksheet.getCell(`F42`).alignment = { horizontal: 'right' }


            console.log('LPAN')
            break;
          case 'NRC': let dataNeracas = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkNrc/${formatDate(period)}/${this.state.formFilter.produkId}`)
            worksheet.getCell(`E66`).value = dataNeracas[0].program_pensiun;
            worksheet.getCell(`F66`).value = dataNeracas[0].manfaat_lain;
            worksheet.getCell(`E67`).value = dataNeracas[1].program_pensiun;
            worksheet.getCell(`E68`).value = dataNeracas[1].manfaat_lain;
            console.log('NRC')
            break;
          case 'LPHU': const profitloss = new ProfitLoss({ store: this.props.store })
            let dataProfitLoss = await profitloss.filterSubmit(this.state.formFilter.produkId, period)
            const rowProfitLoss = [17, 18, 19, 20, 21, 40, 41, 42, 43, 44, 47]

            dataProfitLoss = dataProfitLoss.filter(value => rowProfitLoss.includes(value.seqNo)).sort((a, b) => {
              if (a.seqNo > b.seqNo) {
                return 1
              }
              if (a.seqNo === b.seqNo) {
                return 0
              }
              if (a.seqNo < b.seqNo) {
                return -1
              }
            })

            worksheet.getCell(`E17`).value = dataProfitLoss[0].currentAmount;
            worksheet.getCell(`E18`).value = dataProfitLoss[1].currentAmount;
            worksheet.getCell(`E19`).value = dataProfitLoss[2].currentAmount;
            worksheet.getCell(`E20`).value = dataProfitLoss[3].currentAmount;
            worksheet.getCell(`E21`).value = dataProfitLoss[4].currentAmount;
            worksheet.getCell(`E41`).value = dataProfitLoss[5].currentAmount;
            worksheet.getCell(`E42`).value = dataProfitLoss[6].currentAmount;
            worksheet.getCell(`E43`).value = dataProfitLoss[7].currentAmount;
            worksheet.getCell(`E44`).value = dataProfitLoss[8].currentAmount;
            worksheet.getCell(`E45`).value = dataProfitLoss[9].currentAmount;
            worksheet.getCell(`E48`).value = dataProfitLoss[10].currentAmount;
            console.log('LPHU')
            break;
          // case 'LAK': const cashFlow = new CashFlow({ store: this.props.store })
          case 'LAK': let dataLak = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/getReportOjkLak/${formatDate(period)}/${this.state.formFilter.produkId}`)
            dataLak = dataLak.map(value => {
              if (value.seq_no === 45) {
                  return { ...value, seq_no: 46 };
              }
              return value;
            });  

            const skipRowCashFlow = [17, 18, 19, 20, 21, 22, 23, 26, 27, 28, 29, 30, 31, 32, 33, 36, 37, 38, 39, 40, 41, 42, 43, 46]
            dataLak = dataLak.filter(value => skipRowCashFlow.includes(value.seq_no) && value.seq_no)
            console.log(dataLak);

            for (const value of dataLak) {
              worksheet.getCell(`E${value.seq_no}`).value = value.manfaat_pensiun
              worksheet.getCell(`F${value.seq_no}`).value = value.manfaat_lain
            }
            // for (let i = 0; i < dataLak.length; i++) {
            //   worksheet.getCell(`E${17 + i}`).value = dataLak[i].manfaat_pensiun
            //   worksheet.getCell(`F${17 + i}`).value = dataLak[i].manfaat_lain
            // }

            // let dataCashFlow = await cashFlow.filterSubmit(this.state.formFilter.produkId, period)
            // const skipRowCashFlow = [16, 24, 25, 34, 35, 44, 45, 47]
            // dataCashFlow = dataCashFlow.filter(value => !skipRowCashFlow.includes(value.seqNo) && value.seqNo)
            // console.log(dataCashFlow)

            // for (const dataCash of dataCashFlow) {
            //   console.log(`E${dataCash.seqNo}`, dataCash.currentAmount)
            //   worksheet.getCell(`E${dataCash.seqNo}`).value = dataCash.currentAmount;
            // }
            console.log('LAK')
            break;
          case 'PST': let dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkPst/${formatDate(period)}/${this.state.formFilter.participantId}`)
            // dataPeserta = dataPeserta.length > 0 ? dataPeserta[0] : {}

            console.log(dataPeserta);
            for (let i = 0; i < dataPeserta.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPeserta[i].program_pensiun
              worksheet.getCell(`E${16 + j}`).value = dataPeserta[i].manfaat_lain
            }
            // worksheet.getCell('E17').value = dataPeserta._jum_active
            // worksheet.getCell('E18').value = 0
            // worksheet.getCell('E21').value = dataPeserta._jum_pen_pesiunan
            // worksheet.getCell('E22').value = dataPeserta._jum_pen_jadud
            // worksheet.getCell('E23').value = dataPeserta._jum_pen_anak
            // worksheet.getCell('E24').value = dataPeserta._jum_tunda
            // worksheet.getCell('E26').value = 0
            // worksheet.getCell('E27').value = 0
            // worksheet.getCell('E28').value = 0
            // worksheet.getCell('E29').value = 0
            // worksheet.getCell('E31').value = dataPeserta._jum_pen_pihak3

            console.log('PST')
            break;
          case 'KUP1': let dataKup1 = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkKup1/${formatDate(period)}`)

            for (let i = 0; i < dataKup1.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataKup1[i].kekayaan
              worksheet.getCell(`F${16 + i}`).value = dataKup1[i].nilai
            }
            console.log('KUP1')
            break;
          case 'KUP': let dataKup = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/TBA`, 'GET');
            
            worksheet.getCell(`E23`).value = dataKup.floatValue

          break;
          // case 'REKINV': let dataRekinv = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkRekinv/${formatDate(period)}/${this.state.formFilter.produkId}`)
          case 'REKINV': let dataRekinv = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/getOjkRekInvAcc/${formatDate(period)}/${this.state.formFilter.produkId}`)
            console.log('dataRekinv', dataRekinv);
            for (let i = 0; i < dataRekinv.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataRekinv[i].jan
              worksheet.getCell(`F${16 + i}`).value = dataRekinv[i].feb
              worksheet.getCell(`G${16 + i}`).value = dataRekinv[i].mar
              worksheet.getCell(`H${16 + i}`).value = dataRekinv[i].apr
              worksheet.getCell(`I${16 + i}`).value = dataRekinv[i].mei
              worksheet.getCell(`J${16 + i}`).value = dataRekinv[i].jun
              worksheet.getCell(`K${16 + i}`).value = dataRekinv[i].jul
              worksheet.getCell(`L${16 + i}`).value = dataRekinv[i].agu
              worksheet.getCell(`M${16 + i}`).value = dataRekinv[i].sep
              worksheet.getCell(`N${16 + i}`).value = dataRekinv[i].okt
              worksheet.getCell(`O${16 + i}`).value = dataRekinv[i].nov
              worksheet.getCell(`P${16 + i}`).value = dataRekinv[i].des
            }
            console.log('REKINV')
            break;
          // case 'ROI': let dataRoi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkRoi/${formatDate(period)}/${this.state.formFilter.produkId}`)
          case 'ROI': let dataRoi = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `ojk-roi/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < 21; i++) {
              console.log('dataRoi', dataRoi[i]);
              // worksheet.getCell(`E${17 + i}`).value = dataRoi[i].bunga_bagihasil
              // worksheet.getCell(`F${17 + i}`).value = dataRoi[i].deviden
              // worksheet.getCell(`G${17 + i}`).value = dataRoi[i].sewa
              // worksheet.getCell(`H${17 + i}`).value = dataRoi[i].pl_pelepasan
              // worksheet.getCell(`I${17 + i}`).value = dataRoi[i].lainnya
              // worksheet.getCell(`J${17 + i}`).value = dataRoi[i].pl_unrealized
              // worksheet.getCell(`K${17 + i}`).value = dataRoi[i].beban_investasi

              worksheet.getCell(`E${17 + i}`).value = dataRoi[i].bunga
              worksheet.getCell(`F${17 + i}`).value = dataRoi[i].deviden
              worksheet.getCell(`G${17 + i}`).value = dataRoi[i].sewa
              worksheet.getCell(`H${17 + i}`).value = dataRoi[i].laba_rugi
              worksheet.getCell(`I${17 + i}`).value = dataRoi[i].lainnya
              worksheet.getCell(`J${17 + i}`).value = dataRoi[i].delta_spi
              worksheet.getCell(`K${17 + i}`).value = dataRoi[i].beban_investasi
              // worksheet.getCell(`L${17 + i}`).value = dataRoi[i].hasil_bersih
            }
            console.log('ROI')
            break;
          case 'ROI ML': let dataRoiMl = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkRoiMl/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < 21; i++) {
              worksheet.getCell(`E${17 + i}`).value = dataRoi[i].bunga_bagihasil
              worksheet.getCell(`F${17 + i}`).value = dataRoi[i].deviden
              worksheet.getCell(`G${17 + i}`).value = dataRoi[i].sewa
              worksheet.getCell(`H${17 + i}`).value = dataRoi[i].pl_pelepasan
              worksheet.getCell(`I${17 + i}`).value = dataRoi[i].lainnya
              worksheet.getCell(`J${17 + i}`).value = dataRoi[i].pl_unrealized
              worksheet.getCell(`K${17 + i}`).value = dataRoi[i].beban_investasi
              worksheet.getCell(`M${17 + i}`).value = dataRoi[i].rata_rata
            }
            console.log('ROI ML')
            break;
          case 'ALM': let dataAlmInv = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkAlmInv/${formatDate(period)}/${this.state.formFilter.produkId}`)
            let dataAlmAcc = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkAlmAcc/${formatDate(period)}/${this.state.formFilter.produkId}`)
            const rowNumber = [40, 42, 43, 44, 45, 46, 47, 48, 49, 50, 53, 54, 55, 56, 57, 59, 61, 63, 64, 65, 66, 67, 68]
            console.log(dataAlmInv);
            console.log(dataAlmAcc);
            
            for (let i = 0; i < 21; i++) {
              worksheet.getCell(`E${17 + i}`).value = dataAlmInv[i].dwn_1_thn_idr
              worksheet.getCell(`F${17 + i}`).value = dataAlmInv[i].dwn_1_thn_cry
              worksheet.getCell(`H${17 + i}`).value = dataAlmInv[i].f1_5_thn_idr
              worksheet.getCell(`I${17 + i}`).value = dataAlmInv[i].f1_5_thn_cry
              worksheet.getCell(`K${17 + i}`).value = dataAlmInv[i].f5_10_thn_idr
              worksheet.getCell(`L${17 + i}`).value = dataAlmInv[i].f5_10_thn_cry
              worksheet.getCell(`N${17 + i}`).value = dataAlmInv[i].f10_up_thn_idr
              worksheet.getCell(`O${17 + i}`).value = dataAlmInv[i].f10_up_thn_cry
            }
            rowNumber.forEach((element, index) => {
              worksheet.getCell(`E${element}`).value = dataAlmAcc[index].dwn_1_thn_idr || 0
              worksheet.getCell(`F${element}`).value = dataAlmAcc[index].dwn_1_thn_cry
              worksheet.getCell(`H${element}`).value = dataAlmAcc[index].f1_5_thn_idr
              worksheet.getCell(`I${element}`).value = dataAlmAcc[index].f1_5_thn_cry
              worksheet.getCell(`K${element}`).value = dataAlmAcc[index].f5_10_thn_idr
              worksheet.getCell(`L${element}`).value = dataAlmAcc[index].f5_10_thn_cry
              worksheet.getCell(`N${element}`).value = dataAlmAcc[index].f10_up_thn_idr
              worksheet.getCell(`O${element}`).value = dataAlmAcc[index].f10_up_thn_cry
            })
            console.log('ALM')
            break;
          case 'INSP': let dataInsp = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkInsp/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < dataInsp.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataInsp[i].nama_pihak
              worksheet.getCell(`F${16 + i}`).value = dataInsp[i].jenis
              worksheet.getCell(`G${16 + i}`).value = dataInsp[i].jumlah
              worksheet.getCell(`I${16 + i}`).value = dataInsp[i].batasan_dai
              worksheet.getCell(`J${16 + i}`).value = dataInsp[i].batasan_isk
            }
            console.log('INSP')
            break;

          case 'INBM': let dataInbm = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkInbm/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < dataInbm.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataInbm[i].nama_pihak
              worksheet.getCell(`F${16 + i}`).value = dataInbm[i].jenis
              worksheet.getCell(`G${16 + i}`).value = dataInbm[i].jumlah
              worksheet.getCell(`I${16 + i}`).value = dataInbm[i].masalah_keterangan_afiliasi
              worksheet.getCell(`J${16 + i}`).value = dataInbm[i].keterangan
            }
          console.log('INBM')
          break;
          case 'INPT': let dataInpt = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkInpt/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < dataInpt.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataInpt[i].nama_pihak
              worksheet.getCell(`F${16 + i}`).value = dataInpt[i].jenis
              worksheet.getCell(`G${16 + i}`).value = dataInpt[i].jumlah
              worksheet.getCell(`I${16 + i}`).value = dataInpt[i].hubungan_afiliasi
              worksheet.getCell(`J${16 + i}`).value = dataInpt[i].keterangan
            }
            console.log('INPT')
            break;
          case 'SBN': let dataSbn = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkSbn/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < dataSbn.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataSbn[i].sbn_nama
              worksheet.getCell(`F${16 + i}`).value = dataSbn[i].sbn_saldo
              worksheet.getCell(`G${16 + i}`).value = dataSbn[i].obligasi_nama
              worksheet.getCell(`H${16 + i}`).value = dataSbn[i].obligasi_seri
              worksheet.getCell(`I${16 + i}`).value = dataSbn[i].obligasi_jenis
              worksheet.getCell(`J${16 + i}`).value = dataSbn[i].obligasi_rating
              worksheet.getCell(`K${16 + i}`).value = dataSbn[i].obligasi_saldo
              worksheet.getCell(`L${16 + i}`).value = dataSbn[i].rd_sbn_nama
              worksheet.getCell(`M${16 + i}`).value = dataSbn[i].rd_sbn_mi
              worksheet.getCell(`N${16 + i}`).value = dataSbn[i].rd_sbn_nilaiwajar
              worksheet.getCell(`O${16 + i}`).value = dataSbn[i].rd_sbn_persen_sbn
              // worksheet.getCell(`Q${16 + i}`).value = dataSbn[i].rd_pt_nama
              // worksheet.getCell(`R${16 + i}`).value = dataSbn[i].rd_pt_mi
              // worksheet.getCell(`S${16 + i}`).value = dataSbn[i].rd_pt_jenis
              // worksheet.getCell(`T${16 + i}`).value = dataSbn[i].rd_pt_eniten
              // worksheet.getCell(`U${16 + i}`).value = dataSbn[i].rd_pt_saldo
              worksheet.getCell(`V${16 + i}`).value = dataSbn[i].eba_nama
              worksheet.getCell(`W${16 + i}`).value = dataSbn[i].eba_seri
              worksheet.getCell(`X${16 + i}`).value = dataSbn[i].eba_jenis
              worksheet.getCell(`Y${16 + i}`).value = dataSbn[i].eba_rating
              worksheet.getCell(`Z${16 + i}`).value = dataSbn[i].eba_saldo
              worksheet.getCell(`AA${16 + i}`).value = dataSbn[i].inv_lain_infra_nama
              worksheet.getCell(`AB${16 + i}`).value = dataSbn[i].inv_lain_infra_saldo
            }

            let dataSbnObl = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkSbnObl/${formatDate(period)}/${this.state.formFilter.produkId}`);

            for (let i = 0; i < dataSbnObl.length; i++) {
              worksheet.getCell(`G${16 + i}`).value = dataSbnObl[i].obligasiNama
              worksheet.getCell(`H${16 + i}`).value = dataSbnObl[i].obligasiSeri
              worksheet.getCell(`I${16 + i}`).value = dataSbnObl[i].obligasiJenis
              worksheet.getCell(`J${16 + i}`).value = dataSbnObl[i].obligasiRating
              worksheet.getCell(`K${16 + i}`).value = dataSbnObl[i].obligasiSaldo
            }

            let dataSbnRd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkSbnRd/${formatDate(period)}/${this.state.formFilter.produkId}`);

            for (let i = 0; i < dataSbnRd.length; i++) {
              worksheet.getCell(`L${16 + i}`).value = dataSbnRd[i].rdSbnNama
              worksheet.getCell(`M${16 + i}`).value = dataSbnRd[i].rdSbnMi
              worksheet.getCell(`N${16 + i}`).value = dataSbnRd[i].rdSbnNilaiWajar
              worksheet.getCell(`O${16 + i}`).value = dataSbnRd[i].rdSbnPersen
              // worksheet.getCell(`P${16 + i}`).value = dataSbnRd[i].rdSbnSaldo
            }

            let dataSbnRdPt = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkSbnRdPt/${formatDate(period)}/${this.state.formFilter.produkId}`);

            for (let i = 0; i < dataSbnRdPt.length; i++) {
              worksheet.getCell(`Q${16 + i}`).value = dataSbnRdPt[i].rdPtNama
              worksheet.getCell(`R${16 + i}`).value = dataSbnRdPt[i].rdPtMi
              worksheet.getCell(`S${16 + i}`).value = dataSbnRdPt[i].rdPtJenis
              worksheet.getCell(`T${16 + i}`).value = dataSbnRdPt[i].rdPtEmiten
              worksheet.getCell(`U${16 + i}`).value = dataSbnRdPt[i].rdPtSaldo
            }

            let dataSbnEba = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkSbnEba/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < dataSbnEba.length; i++) {
              worksheet.getCell(`V${16 + i}`).value = dataSbnEba[i].ebaNama
              worksheet.getCell(`W${16 + i}`).value = dataSbnEba[i].ebaSeri
              worksheet.getCell(`X${16 + i}`).value = dataSbnEba[i].ebaJenis
              worksheet.getCell(`Y${16 + i}`).value = dataSbnEba[i].ebaRating
              worksheet.getCell(`Z${16 + i}`).value = dataSbnEba[i].ebaSaldo
            }

            let dataSbnInfra = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkSbnInfra/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < dataSbnInfra.length; i++) {
              worksheet.getCell(`AA${16 + i}`).value = dataSbnInfra[i].invLainInfraNama
              worksheet.getCell(`AB${16 + i}`).value = dataSbnInfra[i].invLainInfraSaldo
            }

            worksheet.getColumn(6).width = 20
            worksheet.getColumn(7).width = 110
            console.log('SBN')
            break;
          case 'RAS2': let dataRas2 = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `getReportOjkRas2Acc/${formatDate(period)}/${this.state.formFilter.produkId}`);
                worksheet.getCell(`G26`).value = dataRas2[0].total;
                worksheet.getCell(`G30`).value = dataRas2[1].total;
          break;
          case 'PMI': let dataPMI = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkPmi/${formatDate(period)}`)

            for (let i = 0; i < dataPMI.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPMI[i].kpd_name
              worksheet.getCell(`F${16 + i}`).value = dataPMI[i].no_kontrak
              worksheet.getCell(`G${16 + i}`).value = formatDefaultDate(dataPMI[i].tgl_kontrak)
              worksheet.getCell(`H${16 + i}`).value = dataPMI[i].masa_kontrak
              worksheet.getCell(`I${16 + i}`).value = dataPMI[i].jenis_investasi
              worksheet.getCell(`J${16 + i}`).value = dataPMI[i].nilai_wajar
              worksheet.getCell(`K${16 + i}`).value = dataPMI[i].tingkat_hasil
              worksheet.getCell(`L${16 + i}`).value = dataPMI[i].jumlah_biaya
              worksheet.getCell(`M${16 + i}`).value = dataPMI[i].afiliasi
              worksheet.getCell(`N${16 + i}`).value = dataPMI[i].nilai_perolehan
              worksheet.getCell(`P${16 + i}`).value = dataPMI[i].return_persen + '%'
            }

            for(var i = 16; i <= 10000; i++){
              worksheet.getCell('J'+i).alignment = {vertical: 'top', horizontal: 'center'};
              worksheet.getCell('N'+i).alignment = {vertical: 'top', horizontal: 'center'};
              worksheet.getCell('P'+i).alignment = {vertical: 'top', horizontal: 'right'};
          }
            worksheet.getColumn(5).width = 50
            worksheet.getColumn(8).width = 110
            console.log('PMI')
            break;
          case 'TAB': let dataTAB = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkTab/${formatDate(period)}/${this.state.formFilter.produkId}`)

            for (let i = 0; i < dataTAB.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataTAB[i].bank_name
              worksheet.getCell(`F${16 + i}`).value = formatDefaultDate(dataTAB[i].balance_date)
              worksheet.getCell(`G${16 + i}`).value = dataTAB[i].saldo
              worksheet.getCell(`H${16 + i}`).value = dataTAB[i].rate
              worksheet.getCell(`I${16 + i}`).value = dataTAB[i].product_name
              worksheet.getCell(`J${16 + i}`).value = dataTAB[i].keterangan
              worksheet.getCell(`K${16 + i}`).value = dataTAB[i].cabang
              worksheet.getCell(`L${16 + i}`).value = dataTAB[i].swift_code
              worksheet.getCell(`M${16 + i}`).value = dataTAB[i].kpd_name
              worksheet.getCell(`N${16 + i}`).value = dataTAB[i].mi_name
            }
            console.log('TAB')
            break;
          case 'DOC': let dataDOC = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkDoc/${formatDate(period)}`)
            console.log(dataDOC)
            console.log('test', `transaction-jurnal/ojkDoc/${formatDate(period)}`);
            for (let i = 0; i < dataDOC.length; i++) {
              console.log(dataDOC[i].tanggal_perolehan);
              if (dataDOC[i].tanggal_perolehan !== null) {
                console.log('test');
                worksheet.getCell(`E${16 + i}`).value = dataDOC[i].bank_id
                worksheet.getCell(`F${16 + i}`).value = formatDefaultDate(dataDOC[i].tanggal_perolehan)
                worksheet.getCell(`G${16 + i}`).value = dataDOC[i].nilai_nominal
                worksheet.getCell(`H${16 + i}`).value = dataDOC[i].jangka_waktu
                worksheet.getCell(`I${16 + i}`).value = dataDOC[i].tingkat_bunga / 100
                worksheet.getCell(`J${16 + i}`).value = dataDOC[i].program
                worksheet.getCell(`K${16 + i}`).value = dataDOC[i].keterangan
                worksheet.getCell(`L${16 + i}`).value = dataDOC[i].nama_cabang
                worksheet.getCell(`M${16 + i}`).value = dataDOC[i].kode_bank
                worksheet.getCell(`N${16 + i}`).value = dataDOC[i].swa_kpd
                worksheet.getCell(`O${16 + i}`).value = dataDOC[i].nama_mi
              } else {
                worksheet.getCell(`E${16 + i}`).value = dataDOC[i].bank_id
                worksheet.getCell(`F${16 + i}`).value = dataDOC[i].tanggal_perolehan
                worksheet.getCell(`G${16 + i}`).value = dataDOC[i].nilai_nominal
                worksheet.getCell(`H${16 + i}`).value = dataDOC[i].jangka_waktu
                worksheet.getCell(`I${16 + i}`).value = dataDOC[i].tingkat_bunga / 100
                worksheet.getCell(`J${16 + i}`).value = dataDOC[i].program
                worksheet.getCell(`K${16 + i}`).value = dataDOC[i].keterangan
                worksheet.getCell(`L${16 + i}`).value = dataDOC[i].nama_cabang
                worksheet.getCell(`M${16 + i}`).value = dataDOC[i].kode_bank
                worksheet.getCell(`N${16 + i}`).value = dataDOC[i].swa_kpd
                worksheet.getCell(`O${16 + i}`).value = dataDOC[i].nama_mi
              }

              let lastRowNum = worksheet.lastRow.number;
              const lastTableRowNum = lastRowNum;

              for (let i = 16; i <= 10000; i++){
                // worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell(`H${i}`).alignment = { horizontal: 'left' }
                worksheet.getCell(`I${i}`).alignment = { vertical: 'middle', horizontal: 'left' }
                worksheet.getCell(`J${i}`).alignment = { vertical: 'middle', horizontal: 'left' }
                worksheet.getCell(`K${i}`).alignment = { horizontal: 'left' }
                worksheet.getCell(`L${i}`).alignment = { horizontal: 'left' }
                worksheet.getCell(`M${i}`).alignment = { horizontal: 'left' }
                worksheet.getCell(`N${i}`).alignment = { horizontal: 'left' }
                worksheet.getCell(`O${i}`).alignment = { horizontal: 'left' }
            }

              // worksheet.getCell(`H${16}`).alignment = { horizontal: 'left' }
              // worksheet.getCell(`I${16}`).alignment = { vertical: 'middle', horizontal: 'left' }
              // worksheet.getCell(`J${16}`).alignment = { vertical: 'middle', horizontal: 'left' }
              // worksheet.getCell(`K${16}`).alignment = { horizontal: 'left' }
              // worksheet.getCell(`L${16}`).alignment = { horizontal: 'left' }
              // worksheet.getCell(`M${16}`).alignment = { horizontal: 'left' }
              // worksheet.getCell(`N${16}`).alignment = { horizontal: 'left' }
              // worksheet.getCell(`O${16}`).alignment = { horizontal: 'left' }
            }
            console.log('DOC')
            break;
          case 'DPJKA': let dataDPJKA = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkDpjka/${formatDate(period)}`)

            for (let i = 0; i < dataDPJKA.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataDPJKA[i].bank_id
              worksheet.getCell(`F${16 + i}`).value = formatDefaultDate(dataDPJKA[i].tanggal_perolehan)
              worksheet.getCell(`G${16 + i}`).value = dataDPJKA[i].nilai_nominal
              worksheet.getCell(`H${16 + i}`).value = dataDPJKA[i].jangka_waktu
              worksheet.getCell(`I${16 + i}`).value = dataDPJKA[i].tingkat_bunga
              worksheet.getCell(`J${16 + i}`).value = dataDPJKA[i].program
              worksheet.getCell(`K${16 + i}`).value = dataDPJKA[i].keterangan
              worksheet.getCell(`L${16 + i}`).value = dataDPJKA[i].nama_cabang
              worksheet.getCell(`M${16 + i}`).value = dataDPJKA[i].kode_bank
              worksheet.getCell(`N${16 + i}`).value = dataDPJKA[i].swa_kpd
              worksheet.getCell(`O${16 + i}`).value = dataDPJKA[i].nama_mi
            }
            console.log('DPJKA')
            break;
          case 'SRDP': let dataSRDP = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkSrdp/${formatDate(period)}`)

            for (let i = 0; i < dataSRDP.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataSRDP[i].kode_bank
              worksheet.getCell(`F${16 + i}`).value = dataSRDP[i].tanggal_perolehan
              worksheet.getCell(`G${16 + i}`).value = dataSRDP[i].nilai_nominal
              worksheet.getCell(`H${16 + i}`).value = dataSRDP[i].jangka_waktu
              worksheet.getCell(`I${16 + i}`).value = dataSRDP[i].tingkat_bunga
              worksheet.getCell(`J${16 + i}`).value = dataSRDP[i].program
              worksheet.getCell(`K${16 + i}`).value = dataSRDP[i].keterangan
            }
            console.log('SRDP')
            break;
          case 'SBI': let dataSBI = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkSbi/${formatDate(period)}`)

            for (let i = 0; i < dataSBI.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataSBI[i].kode
              worksheet.getCell(`F${16 + i}`).value = dataSBI[i].buy_date
              worksheet.getCell(`G${16 + i}`).value = dataSBI[i].face_value
              worksheet.getCell(`H${16 + i}`).value = dataSBI[i].interest
              worksheet.getCell(`I${16 + i}`).value = dataSBI[i].maturity_date
              worksheet.getCell(`J${16 + i}`).value = dataSBI[i].buy_value
              worksheet.getCell(`K${16 + i}`).value = dataSBI[i].market_value
              worksheet.getCell(`N${16 + i}`).value = dataSBI[i].program
              worksheet.getCell(`O${16 + i}`).value = dataSBI[i].keterangan
            }
            console.log('SBI')
            break;
          case 'RSBN': let dataRSBN = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkRsbn/${formatDate(period)}`)

            for (let i = 0; i < dataRSBN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataRSBN[i].kode
              worksheet.getCell(`F${16 + i}`).value = formatDefaultDate(dataRSBN[i].buy_date)
              worksheet.getCell(`G${16 + i}`).value = dataRSBN[i].face_value
              worksheet.getCell(`H${16 + i}`).value = dataRSBN[i].interest
              worksheet.getCell(`I${16 + i}`).value = formatDefaultDate(dataRSBN[i].maturity_date)
              worksheet.getCell(`J${16 + i}`).value = dataRSBN[i].buy_value
              worksheet.getCell(`K${16 + i}`).value = dataRSBN[i].market_value
              worksheet.getCell(`N${16 + i}`).value = dataRSBN[i].program
              worksheet.getCell(`O${16 + i}`).value = dataRSBN[i].metode
              worksheet.getCell(`P${16 + i}`).value = dataRSBN[i].keterangan
              worksheet.getCell(`Q${16 + i}`).value = dataRSBN[i].pengelola
              worksheet.getCell(`R${16 + i}`).value = dataRSBN[i].mi_name
            }
            console.log('RSBN')
            break;
          case 'SHM': let dataSHM = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkShm/${formatDate(period)}`)
            for (let i = 0; i < dataSHM.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataSHM[i].kode ? dataSHM[i].kode : ""
              worksheet.getCell(`F${16 + i}`).value = dataSHM[i].emiten ? dataSHM[i].emiten : ""
              worksheet.getCell(`G${16 + i}`).value = dataSHM[i].buy_date ? formatDefaultDate(dataSHM[i].buy_date) : ""
              worksheet.getCell(`H${16 + i}`).value = dataSHM[i].quantity ? dataSHM[i].quantity : ""
              worksheet.getCell(`I${16 + i}`).value = dataSHM[i].buy_value ? dataSHM[i].buy_value : ""
              worksheet.getCell(`J${16 + i}`).value = dataSHM[i].market_value ? dataSHM[i].market_value : ""
              worksheet.getCell(`M${16 + i}`).value = dataSHM[i].sektor ? dataSHM[i].sektor : ""
              worksheet.getCell(`N${16 + i}`).value = dataSHM[i].program ? dataSHM[i].program : ""
              worksheet.getCell(`O${16 + i}`).value = dataSHM[i].keterangan ? dataSHM[i].keterangan : ""
              worksheet.getCell(`P${16 + i}`).value = dataSHM[i].swa_kpd ? dataSHM[i].swa_kpd : ""
              worksheet.getCell(`Q${16 + i}`).value = dataSHM[i].mi_name ? dataSHM[i].mi_name : ""
            }
            console.log('SHM')
            break;
          case 'OBLI': let dataOBLI = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkObli/${formatDate(period)}`)

            for (let i = 0; i < dataOBLI.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataOBLI[i].penerbit
              worksheet.getCell(`F${16 + i}`).value = dataOBLI[i].kode
              worksheet.getCell(`G${16 + i}`).value = dataOBLI[i].nama_obligasi//Nama Obligasi
              worksheet.getCell(`H${16 + i}`).value = formatDefaultDate(dataOBLI[i].buy_date)
              worksheet.getCell(`I${16 + i}`).value = dataOBLI[i].face_value
              worksheet.getCell(`J${16 + i}`).value = dataOBLI[i].interest
              worksheet.getCell(`K${16 + i}`).value = formatDefaultDate(dataOBLI[i].maturity_date)
              worksheet.getCell(`L${16 + i}`).value = dataOBLI[i].rating_awal//Peringkat Awal
              worksheet.getCell(`M${16 + i}`).value = dataOBLI[i].rating_akhir//Peringkat Akhir
              worksheet.getCell(`N${16 + i}`).value = dataOBLI[i].buy_value
              worksheet.getCell(`O${16 + i}`).value = dataOBLI[i].market_value
              worksheet.getCell(`R${16 + i}`).value = dataOBLI[i].sektor_name
              worksheet.getCell(`S${16 + i}`).value = dataOBLI[i].program
              worksheet.getCell(`T${16 + i}`).value = dataOBLI[i].metode
              worksheet.getCell(`U${16 + i}`).value = dataOBLI[i].keterangan
              worksheet.getCell(`V${16 + i}`).value = dataOBLI[i].pengelola
              worksheet.getCell(`W${16 + i}`).value = dataOBLI[i].mi_name
            }
            console.log('OBLI')
            break;
          case 'SUKUK': let dataSUKUK = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkSukuk/${formatDate(period)}`)

            for (let i = 0; i < dataSUKUK.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataSUKUK[i].pengelola
              worksheet.getCell(`F${16 + i}`).value = dataSUKUK[i].kode
              worksheet.getCell(`G${16 + i}`).value = dataSUKUK[i].nama_sukuk
              worksheet.getCell(`H${16 + i}`).value = formatDefaultDate(dataSUKUK[i].buy_date)
              worksheet.getCell(`I${16 + i}`).value = dataSUKUK[i].face_value
              worksheet.getCell(`J${16 + i}`).value = dataSUKUK[i].interest
              worksheet.getCell(`K${16 + i}`).value = formatDefaultDate(dataSUKUK[i].maturity_date)
              worksheet.getCell(`L${16 + i}`).value = dataSUKUK[i].rating_awal
              worksheet.getCell(`M${16 + i}`).value = dataSUKUK[i].rating_akhir
              worksheet.getCell(`N${16 + i}`).value = dataSUKUK[i].buy_value
              worksheet.getCell(`O${16 + i}`).value = dataSUKUK[i].market_value
              worksheet.getCell(`R${16 + i}`).value = dataSUKUK[i].sektor_name
              worksheet.getCell(`S${16 + i}`).value = dataSUKUK[i].program
              worksheet.getCell(`T${16 + i}`).value = dataSUKUK[i].metode
              worksheet.getCell(`U${16 + i}`).value = dataSUKUK[i].keterangan
              worksheet.getCell(`V${16 + i}`).value = dataSUKUK[i].pengelola
              worksheet.getCell(`W${16 + i}`).value = dataSUKUK[i].mi_name
            }
            console.log('SUKUK')
            break;
          case 'OBSUD': let dataOBSUD = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkObsud/${formatDate(period)}`)

            for (let i = 0; i < dataOBSUD.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataOBSUD[i].pengelola
              worksheet.getCell(`F${16 + i}`).value = dataOBSUD[i].kode
              worksheet.getCell(`G${16 + i}`).value = 'Nama Obligasi / Suku Daerah'//dataOBSUD[i].buy_date
              worksheet.getCell(`H${16 + i}`).value = dataOBSUD[i].buy_date
              worksheet.getCell(`I${16 + i}`).value = dataOBSUD[i].face_value
              worksheet.getCell(`J${16 + i}`).value = dataOBSUD[i].interest
              worksheet.getCell(`K${16 + i}`).value = dataOBSUD[i].maturity_date
              worksheet.getCell(`L${16 + i}`).value = 'Peringkat Awal'//dataOBSUD[i].interest
              worksheet.getCell(`M${16 + i}`).value = 'Peringkat Akhir'//dataOBSUD[i].sektor
              worksheet.getCell(`N${16 + i}`).value = dataOBSUD[i].buy_value
              worksheet.getCell(`O${16 + i}`).value = dataOBSUD[i].market_value
              worksheet.getCell(`R${16 + i}`).value = dataOBSUD[i].sektor_name
              worksheet.getCell(`S${16 + i}`).value = dataOBSUD[i].program
              worksheet.getCell(`T${16 + i}`).value = dataOBSUD[i].metode
              worksheet.getCell(`U${16 + i}`).value = dataOBSUD[i].keterangan
            }
            console.log('OBSUD')
            break;
          case 'RKSD': let dataRKSD = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkRksd/${formatDate(period)}`)
            for (let i = 0; i < dataRKSD.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataRKSD[i].kode ? dataRKSD[i].kode : ""
              worksheet.getCell(`F${16 + i}`).value = dataRKSD[i].produk_rd ? dataRKSD[i].produk_rd : ""
              worksheet.getCell(`G${16 + i}`).value = dataRKSD[i].jenis_rd ? dataRKSD[i].jenis_rd : ""
              worksheet.getCell(`H${16 + i}`).value = dataRKSD[i].mi_name ? dataRKSD[i].mi_name : ""
              worksheet.getCell(`I${16 + i}`).value = dataRKSD[i].buy_date ? formatDefaultDate(dataRKSD[i].buy_date) : ""
              worksheet.getCell(`J${16 + i}`).value = dataRKSD[i].quantity ? dataRKSD[i].quantity : ""
              worksheet.getCell(`K${16 + i}`).value = dataRKSD[i].buy_value ? dataRKSD[i].buy_value : ""
              worksheet.getCell(`L${16 + i}`).value = dataRKSD[i].market_value ? dataRKSD[i].market_value : ""
              worksheet.getCell(`O${16 + i}`).value = dataRKSD[i].program ? dataRKSD[i].program : ""
              worksheet.getCell(`Q${16 + i}`).value = dataRKSD[i].swa_kpd ? dataRKSD[i].swa_kpd : ""
              worksheet.getCell(`R${16 + i}`).value = dataRKSD[i].mi_name ? dataRKSD[i].mi_name : ""
            }
            console.log('RKSD')
            break;
          case 'MTN': let dataMTN = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkMtn/${formatDate(period)}`)

            for (let i = 0; i < dataMTN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataMTN[i].kode
              worksheet.getCell(`F${16 + i}`).value = dataMTN[i].produk
              worksheet.getCell(`G${16 + i}`).value = dataMTN[i].penerbit
              worksheet.getCell(`H${16 + i}`).value = dataMTN[i].buy_date
              worksheet.getCell(`I${16 + i}`).value = dataMTN[i].face_value
              worksheet.getCell(`J${16 + i}`).value = dataMTN[i].interest
              worksheet.getCell(`K${16 + i}`).value = dataMTN[i].maturity_date
              worksheet.getCell(`L${16 + i}`).value = dataMTN[i].rating_awal
              worksheet.getCell(`M${16 + i}`).value = dataMTN[i].rating_akhir
              worksheet.getCell(`N${16 + i}`).value = dataMTN[i].buy_value
              worksheet.getCell(`O${16 + i}`).value = dataMTN[i].market_value
              worksheet.getCell(`R${16 + i}`).value = dataMTN[i].sektor_name
              worksheet.getCell(`S${16 + i}`).value = 'Program hilang'//dataMTN[i].keterangan
              worksheet.getCell(`T${16 + i}`).value = dataMTN[i].keterangan
            }
            console.log('MTN')
            break;
          case 'EBA': let dataEBA = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkEba/${formatDate(period)}`)

            for (let i = 0; i < dataEBA.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataEBA[i].kode
              worksheet.getCell(`F${16 + i}`).value = dataEBA[i].produk
              worksheet.getCell(`G${16 + i}`).value = dataEBA[i].penerbit
              worksheet.getCell(`H${16 + i}`).value = formatDefaultDate(dataEBA[i].buy_date)
              worksheet.getCell(`I${16 + i}`).value = dataEBA[i].face_value
              worksheet.getCell(`J${16 + i}`).value = dataEBA[i].interest
              worksheet.getCell(`K${16 + i}`).value = formatDefaultDate(dataEBA[i].maturity_date)
              worksheet.getCell(`L${16 + i}`).value = dataEBA[i].rating_awal
              worksheet.getCell(`M${16 + i}`).value = dataEBA[i].rating_akhir
              worksheet.getCell(`N${16 + i}`).value = dataEBA[i].buy_value
              worksheet.getCell(`O${16 + i}`).value = dataEBA[i].market_value
              worksheet.getCell(`R${16 + i}`).value = dataEBA[i].sektor_name
              worksheet.getCell(`S${16 + i}`).value = dataEBA[i].program
              worksheet.getCell(`T${16 + i}`).value = dataEBA[i].keterangan
              worksheet.getCell(`U${16 + i}`).value = dataEBA[i].pengelola
              worksheet.getCell(`V${16 + i}`).value = dataEBA[i].mi_name
            }
            console.log('EBA')
            break;
          case 'DIRE': let dataDIRE = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkDire/${formatDate(period)}`)

            for (let i = 0; i < dataDIRE.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataDIRE[i].kode
              worksheet.getCell(`F${16 + i}`).value = dataDIRE[i].produk_rd
              worksheet.getCell(`G${16 + i}`).value = dataDIRE[i].mi_name
              worksheet.getCell(`H${16 + i}`).value = dataDIRE[i].buy_date
              worksheet.getCell(`I${16 + i}`).value = dataDIRE[i].quantity
              worksheet.getCell(`J${16 + i}`).value = dataDIRE[i].buy_value
              worksheet.getCell(`K${16 + i}`).value = dataDIRE[i].market_value
              worksheet.getCell(`N${16 + i}`).value = dataDIRE[i].program
              worksheet.getCell(`O${16 + i}`).value = dataDIRE[i].keterangan
            }
            console.log('DIRE')
            break;
          case 'DNFRA': let dataDNFRA = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkDnfra/${formatDate(period)}`)

            for (let i = 0; i < dataDNFRA.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataDNFRA[i].kode
              worksheet.getCell(`F${16 + i}`).value = dataDNFRA[i].produk_rd
              worksheet.getCell(`G${16 + i}`).value = dataDNFRA[i].mi_name
              worksheet.getCell(`H${16 + i}`).value = dataDNFRA[i].buy_date
              worksheet.getCell(`I${16 + i}`).value = dataDNFRA[i].quantity
              worksheet.getCell(`J${16 + i}`).value = dataDNFRA[i].buy_value
              worksheet.getCell(`K${16 + i}`).value = dataDNFRA[i].market_value
              worksheet.getCell(`N${16 + i}`).value = dataDNFRA[i].program
              worksheet.getCell(`O${16 + i}`).value = dataDNFRA[i].keterangan
            }
            console.log('DNFRA')
            break;
          case 'KOKB': let dataKOKB = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkKokb/${formatDate(period)}`)

            for (let i = 0; i < dataKOKB.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataKOKB[i].nama_emiten
              worksheet.getCell(`F${16 + i}`).value = dataKOKB[i].nama_pembeli
              worksheet.getCell(`G${16 + i}`).value = dataKOKB[i].tanggal_perolehan
              worksheet.getCell(`H${16 + i}`).value = dataKOKB[i].jangka_waktu
              worksheet.getCell(`I${16 + i}`).value = dataKOKB[i].nilai_perolehan
              worksheet.getCell(`J${16 + i}`).value = dataKOKB[i].nilai_wajar
              worksheet.getCell(`M${16 + i}`).value = dataKOKB[i].program
              worksheet.getCell(`N${16 + i}`).value = dataKOKB[i].keterangan
            }
            console.log('KOKB')
            break;
          case 'REPO': let dataREPO = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkRepo/${formatDate(period)}`)

            for (let i = 0; i < dataREPO.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataREPO[i].counterparty
              worksheet.getCell(`F${16 + i}`).value = dataREPO[i].jenis_simpanan
              worksheet.getCell(`G${16 + i}`).value = dataREPO[i].tanggal_perolehan
              worksheet.getCell(`H${16 + i}`).value = dataREPO[i].nilai_jaminan
              worksheet.getCell(`I${16 + i}`).value = dataREPO[i].rating_awal
              worksheet.getCell(`J${16 + i}`).value = dataREPO[i].rating_akhir
              worksheet.getCell(`K${16 + i}`).value = dataREPO[i].jangka_waktu
              worksheet.getCell(`L${16 + i}`).value = dataREPO[i].penyimpanan_efek
              worksheet.getCell(`M${16 + i}`).value = dataREPO[i].nilai_perolehan
              worksheet.getCell(`N${16 + i}`).value = dataREPO[i].nilai_jaminan
              worksheet.getCell(`O${16 + i}`).value = dataREPO[i].persen_selisih_jual
              worksheet.getCell(`P${16 + i}`).value = dataREPO[i].nilai_amortisasi
              worksheet.getCell(`Q${16 + i}`).value = dataREPO[i].nilai_jual
              worksheet.getCell(`T${16 + i}`).value = dataREPO[i].program
              worksheet.getCell(`U${16 + i}`).value = dataREPO[i].keterangan
            }
            console.log('REPO')
            break;
          case 'PNYL': let dataPNYL = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkPnyl/${formatDate(period)}`)

            for (let i = 0; i < dataPNYL.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPNYL[i].pdp_nama_perusahaan
              worksheet.getCell(`F${16 + i}`).value = dataPNYL[i].pdp_nama
              worksheet.getCell(`G${16 + i}`).value = dataPNYL[i].pdp_jabatan
              worksheet.getCell(`H${16 + i}`).value = dataPNYL[i].pdp_kategori_penyertaan
              worksheet.getCell(`I${16 + i}`).value = formatDefaultDate(dataPNYL[i].pdp_tanggal_perolehan)
              worksheet.getCell(`J${16 + i}`).value = formatDefaultDate(dataPNYL[i].pa_tanggal)
              worksheet.getCell(`K${16 + i}`).value = dataPNYL[i].pa_persen_kepemilikan
              worksheet.getCell(`L${16 + i}`).value = dataPNYL[i].pa_total
              worksheet.getCell(`M${16 + i}`).value = dataPNYL[i].nptl_persen_kepemilikan
              worksheet.getCell(`N${16 + i}`).value = dataPNYL[i].nptl_total
              worksheet.getCell(`O${16 + i}`).value = formatDefaultDate(dataPNYL[i].na_tanggal)
              worksheet.getCell(`P${16 + i}`).value = dataPNYL[i].na_persen_kepemilikan
              worksheet.getCell(`Q${16 + i}`).value = dataPNYL[i].na_total
              worksheet.getCell(`T${16 + i}`).value = dataPNYL[i].spi_sektor_ekonomi
              worksheet.getCell(`U${16 + i}`).value = dataPNYL[i].spi_program
              worksheet.getCell(`V${16 + i}`).value = dataPNYL[i].keterangan
              worksheet.getCell(`W${16 + i}`).value = dataPNYL[i].p_swakelola_kpd
              worksheet.getCell(`X${16 + i}`).value = dataPNYL[i].p_manajer_investasi
              worksheet.getCell(`Y${16 + i}`).value = dataPNYL[i].p_total_modal_disetor
              worksheet.getCell(`Z${16 + i}`).value = dataPNYL[i].prkv_pendiri
              worksheet.getCell(`AA${16 + i}`).value = dataPNYL[i].prkv_dana_pensiun
              worksheet.getCell(`AB${16 + i}`).value = dataPNYL[i].prkv_mitra_sendiri
              worksheet.getCell(`AC${16 + i}`).value = dataPNYL[i].prkv_pengurus
              worksheet.getCell(`AD${16 + i}`).value = dataPNYL[i].prkv_penerima_titipan
              worksheet.getCell(`AE${16 + i}`).value = dataPNYL[i].prkv_serikat_kerja
              worksheet.getCell(`AF${16 + i}`).value = dataPNYL[i].prkv_lainnya
              worksheet.getCell(`AG${16 + i}`).value = dataPNYL[i].prkv_anak_perusahaan
              worksheet.getCell(`AH${16 + i}`).value = dataPNYL[i].prkv_total_modal_disetor
              worksheet.getCell(`AI${16 + i}`).value = dataPNYL[i].prke1_pendiri
              worksheet.getCell(`AJ${16 + i}`).value = dataPNYL[i].prke1_mitra_pendiri
              worksheet.getCell(`AK${16 + i}`).value = dataPNYL[i].prke1_pengurus
              worksheet.getCell(`AL${16 + i}`).value = dataPNYL[i].prke1_penerima_titipan
              worksheet.getCell(`AM${16 + i}`).value = dataPNYL[i].prke1_serikat_kerja
              worksheet.getCell(`AN${16 + i}`).value = dataPNYL[i].prke1_lainnya
              worksheet.getCell(`AO${16 + i}`).value = dataPNYL[i].prke1_dividen
            }
            console.log('PNYL')
            break;
          case 'PROP': let dataPROP = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkProp/${formatDate(period)}`)

            for (let i = 0; i < dataPROP.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPROP[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataPROP[i].alamat
              worksheet.getCell(`G${16 + i}`).value = dataPROP[i].lokasi_kota + ',' + dataPROP[i].lokasi_provinsi
              worksheet.getCell(`H${16 + i}`).value = dataPROP[i].luas
              worksheet.getCell(`I${16 + i}`).value = dataPROP[i].jenis_sertifikat
              worksheet.getCell(`J${16 + i}`).value = dataPROP[i].no_sertifikat
              worksheet.getCell(`K${16 + i}`).value = dataPROP[i].buy_date
              worksheet.getCell(`L${16 + i}`).value = dataPROP[i].buy_value
              worksheet.getCell(`M${16 + i}`).value = dataPROP[i].buy_value - dataPROP[i].nilai_buku
              worksheet.getCell(`N${16 + i}`).value = dataPROP[i].nilai_buku
              worksheet.getCell(`O${16 + i}`).value = dataPROP[i].nilai_wajar
              worksheet.getCell(`P${16 + i}`).value = dataPROP[i].tahun_appraisal
              worksheet.getCell(`Q${16 + i}`).value = dataPROP[i].nama_Appraisal
              worksheet.getCell(`R${16 + i}`).value = dataPROP[i].nama_kjpp
              worksheet.getCell(`U${16 + i}`).value = dataPROP[i].program
              worksheet.getCell(`V${16 + i}`).value = dataPROP[i].keterangan
              worksheet.getCell(`W${16 + i}`).value = dataPROP[i].swa_kpd
            }
            console.log('PROP')
            break;
          // case 'KASB': let dataKASB = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkKasb/${formatDate(period)}/${this.state.formFilter.produkId}`)
          case 'KASB': let dataKASB = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-books/getReportOjkKasb/${formatDate(period)}/${this.state.formFilter.produkId}`)

          console.log(dataKASB);
            for (let i = 0; i < dataKASB.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataKASB[i].bank_name
              worksheet.getCell(`F${16 + i}`).value = dataKASB[i].rek_no
              worksheet.getCell(`G${16 + i}`).value = dataKASB[i].balance_amount
              worksheet.getCell(`H${16 + i}`).value = dataKASB[i].program_name
              worksheet.getCell(`I${16 + i}`).value = dataKASB[i].keterangan
              worksheet.getCell(`J${16 + i}`).value = dataKASB[i].cabang
              worksheet.getCell(`K${16 + i}`).value = dataKASB[i].swift_code
            }
            console.log('KASB')
            break;
          case 'TNBG': let dataFixedAssetTNBG = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-transactions/getReportOjkTnbg/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataFixedAssetTNBG.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetTNBG[i].nama_aset
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetTNBG[i].no_sertifikat
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetTNBG[i].alamat
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetTNBG[i].tanggal_perolehan
              worksheet.getCell(`I${16 + i}`).value = dataFixedAssetTNBG[i].nilai_perolehan
              worksheet.getCell(`J${16 + i}`).value = dataFixedAssetTNBG[i].akumulasi_penyusutan
              // worksheet.getCell(`K${16 + i}`).value = dataFixedAssetTNBG[i].bookValue 
              worksheet.getCell(`L${16 + i}`).value = dataFixedAssetTNBG[i].nilai_pasar
              worksheet.getCell(`M${16 + i}`).value = dataFixedAssetTNBG[i].keterangan
            }
            console.log('TNBG')
            break;
          case 'KNDR': let dataFixedAssetKNDR = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-transactions/getReportOjkKndr/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataFixedAssetKNDR.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetKNDR[i].no_plat
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetKNDR[i].jenis
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetKNDR[i].tanggal_perolehan
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetKNDR[i].nilai_perolehan
              worksheet.getCell(`I${16 + i}`).value = dataFixedAssetKNDR[i].akumulasi_penyusutan
              // worksheet.getCell(`J${16 + i}`).value = dataFixedAssetKNDR[i].bookValue 
              worksheet.getCell(`K${16 + i}`).value = dataFixedAssetKNDR[i].keterangan
            }
            console.log('KNDR')
            break;
          case 'PKOM': let dataFixedAssetPKOM = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-transactions/getReportOjkPkom/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataFixedAssetPKOM.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetPKOM[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetPKOM[i].tanggal_perolehan
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetPKOM[i].nilai_perolehan
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetPKOM[i].akumulasi_penyusutan
              // worksheet.getCell(`I${16 + i}`).value = dataFixedAssetPKOM[i].bookValue
              worksheet.getCell(`J${16 + i}`).value = dataFixedAssetPKOM[i].keterangan
            }
            console.log('PKOM')
            break;
          case 'PKAN': let dataFixedAssetPKAN = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-transactions/getReportOjkPkan/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataFixedAssetPKAN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetPKAN[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetPKAN[i].tanggal_perolehan
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetPKAN[i].nilai_perolehan
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetPKAN[i].akumulasi_penyusutan
              // worksheet.getCell(`I${16 + i}`).value = dataFixedAssetPKAN[i].bookValue
              worksheet.getCell(`J${16 + i}`).value = dataFixedAssetPKAN[i].keterangan
            }
            console.log('PKAN')
            break;
          case 'ASOL': let dataFixedAssetASOL = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-transactions/getReportOjkAsol/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataFixedAssetASOL.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetASOL[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetASOL[i].tanggal_perolehan
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetASOL[i].nilai_perolehan
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetASOL[i].akumulasi_penyusutan
              // worksheet.getCell(`I${16 + i}`).value = dataFixedAssetASOL[i].bookValue
              worksheet.getCell(`J${16 + i}`).value = dataFixedAssetASOL[i].keterangan
            }
            console.log('ASOL')
            break;
          case 'ASLN': let dataFixedAssetASLN = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-transactions/getReportOjkAsln/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataFixedAssetASLN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetASLN[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetASLN[i].tanggal_perolehan
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetASLN[i].nilai_buku
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetASLN[i].program
              worksheet.getCell(`I${16 + i}`).value = dataFixedAssetASLN[i].keterangan
            }
            console.log('ASLN')
            break;
          case 'UMPJ': let dataUmpj = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkUmpj/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataUmpj.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataUmpj[i].kurang_setahun
              worksheet.getCell(`F${16 + i}`).value = dataUmpj[i].lebih_setahun
              worksheet.getCell(`H${16 + i}`).value = dataUmpj[i].program
              worksheet.getCell(`I${16 + i}`).value = dataUmpj[i].keterangan
            }
            console.log('UMPJ')
            break;
          case 'UMPS': let dataUmps = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkUmps/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataUmps.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataUmps[i].kurang_setahun
              worksheet.getCell(`F${16 + i}`).value = dataUmps[i].lebih_setahun
              worksheet.getCell(`H${16 + i}`).value = dataUmps[i].program
              worksheet.getCell(`I${16 + i}`).value = dataUmps[i].keterangan
            }
            console.log('UMPS')
            break;
          // case 'UTIN': let dataFixedAssetUTIN = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkUtin/${formatDate(period)}/${this.state.formFilter.produkId}`)
          case 'UTIN': let dataFixedAssetUTIN = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/getOjkUtin/${formatDate(period)}/${this.state.formFilter.produkId}`)
            console.log('dataFixedAssetUTIN', dataFixedAssetUTIN);
            for (let i = 0; i < dataFixedAssetUTIN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetUTIN[i].rincian
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetUTIN[i].nilai
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetUTIN[i].program
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetUTIN[i].keterangan
              worksheet.getCell(`I${16 + i}`).value = dataFixedAssetUTIN[i].pihak
            }
            console.log('UTIN')
            break;
          case 'PDDM': let dataPDDM = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkPddm/${formatDate(period)}/${this.state.formFilter.produkId}/${this.state.formFilter.coaGroupId}`)
            console.log(dataPDDM);
            for (let i = 0; i < dataPDDM.length; i++) {
              // worksheet.getCell(`E${16 + i}`).value = dataPDDM[i].coa_name
              // worksheet.getCell(`F${16 + i}`).value = dataPDDM[i].entity_name
              // worksheet.getCell(`G${16 + i}`).value = dataPDDM[i].balance_amount
              // worksheet.getCell(`H${16 + i}`).value = dataPDDM[i].produk_id
              // worksheet.getCell(`I${16 + i}`).value = dataPDDM[i].journal_desc
              worksheet.getCell(`E${16 + i}`).value = dataPDDM[i].rincian
              worksheet.getCell(`F${16 + i}`).value = dataPDDM[i].pihak
              worksheet.getCell(`G${16 + i}`).value = dataPDDM[i].nilai
              worksheet.getCell(`H${16 + i}`).value = dataPDDM[i].program
              worksheet.getCell(`I${16 + i}`).value = dataPDDM[i].keterangan
            }
            const Data1 = worksheet.getColumn(5);
            Data1.width = 40;
            console.log('PDDM')
            break;
          case 'BMHB': let dataFixedAssetBMHB = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkBmhb/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataFixedAssetBMHB.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetBMHB[i].rincian
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetBMHB[i].pihak
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetBMHB[i].nilai
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetBMHB[i].program
              worksheet.getCell(`I${16 + i}`).value = dataFixedAssetBMHB[i].keterangan
            }
            console.log('BMHB')
            break;
          case 'UTLN': let dataFixedAssetUTLN = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `getReportOjkUtln/{reportDate}/{productId}?productId=${this.state.formFilter.produkId}&reportDate=${formatDate(period)}`)
            for (let i = 0; i < dataFixedAssetUTLN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataFixedAssetUTLN[i].jenis_utang_lain
              worksheet.getCell(`F${16 + i}`).value = dataFixedAssetUTLN[i].pihak
              worksheet.getCell(`G${16 + i}`).value = dataFixedAssetUTLN[i].nilai
              worksheet.getCell(`H${16 + i}`).value = dataFixedAssetUTLN[i].program_pensiun
              // worksheet.getCell(`H${16 + i}`).alignment = { horizontal: 'right' }
              worksheet.getCell(`I${16 + i}`).value = dataFixedAssetUTLN[i].keterangan
            }
            break;
          // case 'PPIN': let dataPPIN = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkPpin/${formatDate(period)}/${this.state.formFilter.produkId}`)
          case 'PPIN': let dataPPIN = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/getOjkPpin/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataPPIN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPPIN[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataPPIN[i].spi
              worksheet.getCell(`G${16 + i}`).value = dataPPIN[i].peningkatan
              worksheet.getCell(`H${16 + i}`).value = dataPPIN[i].program
              worksheet.getCell(`I${16 + i}`).value = dataPPIN[i].keterangan
            }
            console.log('PPIN')
            break;
          case 'IUJT': let dataIUJT = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkIujt/${formatDate(period)}/${participantId}`)
            for (let i = 0; i < dataIUJT.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataIUJT[i].nama_pk
              worksheet.getCell(`F${16 + i}`).value = dataIUJT[i].phdp
              worksheet.getCell(`G${16 + i}`).value = dataIUJT[i].jt_in_pst_persen
              worksheet.getCell(`H${16 + i}`).value = dataIUJT[i].jt_in_pst_nominal
              worksheet.getCell(`I${16 + i}`).value = dataIUJT[i].jt_in_pk_persen
              worksheet.getCell(`J${16 + i}`).value = dataIUJT[i].jt_in_pk_nominal
              worksheet.getCell(`K${16 + i}`).value = dataIUJT[i].jt_isr_psr
              worksheet.getCell(`L${16 + i}`).value = dataIUJT[i].jt_i_tambahan
              worksheet.getCell(`M${16 + i}`).value = dataIUJT[i].pi_in_pst
              worksheet.getCell(`N${16 + i}`).value = dataIUJT[i].pi_in_pk
              worksheet.getCell(`O${16 + i}`).value = dataIUJT[i].pi_tbh
              worksheet.getCell(`P${16 + i}`).value = dataIUJT[i].pi_isr_pst
              worksheet.getCell(`Q${16 + i}`).value = dataIUJT[i].kkb_in_pst
              worksheet.getCell(`R${16 + i}`).value = dataIUJT[i].kkb_in_pk
              worksheet.getCell(`S${16 + i}`).value = dataIUJT[i].kkb_i_tbh
              worksheet.getCell(`T${16 + i}`).value = dataIUJT[i].program_ml
              worksheet.getCell(`U${16 + i}`).value = dataIUJT[i].keterangan
            }
            console.log('IUJT')
            break;
          case 'PDIN': let dataPDIN = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkPdin/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataPDIN.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPDIN[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataPDIN[i].jumlah
              worksheet.getCell(`G${16 + i}`).value = dataPDIN[i].program
              worksheet.getCell(`H${16 + i}`).value = dataPDIN[i].keterangan
              const Data1 = worksheet.getColumn(5);
              Data1.width = 70;
            }
            console.log('PDIN')
            break;
          case 'BINV': let dataBINV = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkBinv/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataBINV.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataBINV[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataBINV[i].jumlah
              worksheet.getCell(`G${16 + i}`).value = dataBINV[i].program
              worksheet.getCell(`H${16 + i}`).value = dataBINV[i].keterangan
            }
            console.log('BINV')
            break;
          case 'BOPR': let dataBOPR = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/getOjkBopr/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataBOPR.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataBOPR[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataBOPR[i].jumlah
              worksheet.getCell(`G${16 + i}`).value = dataBOPR[i].program
              worksheet.getCell(`H${16 + i}`).value = dataBOPR[i].keterangan
            }
            console.log('BOPR')
            break;
          case 'BIPR': let dataBIPR = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkBipr/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataBIPR.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataBIPR[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataBIPR[i].jumlah
              worksheet.getCell(`G${16 + i}`).value = dataBIPR[i].program
              worksheet.getCell(`H${16 + i}`).value = dataBIPR[i].keterangan
            }
            console.log('BIPR')
            break;
          case 'PPH': let dataPPH = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkPph/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataPPH.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPPH[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataPPH[i].jumlah
              worksheet.getCell(`G${16 + i}`).value = dataPPH[i].program
              worksheet.getCell(`H${16 + i}`).value = dataPPH[i].keterangan
            }
            console.log('PPH')
            break;
          case 'PDPL': let dataPdpl = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkPdpl/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataPdpl.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPdpl[i].nama_dp_lain
              worksheet.getCell(`F${16 + i}`).value = dataPdpl[i].jumlah
              worksheet.getCell(`G${16 + i}`).value = dataPdpl[i].program
              worksheet.getCell(`H${16 + i}`).value = dataPdpl[i].rincian
              worksheet.getCell(`I${16 + i}`).value = dataPdpl[i].keterangan
            }
            console.log('PDPL')
            break;
          case 'RAS3': let dataRas3 = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkRas3/${formatDate(period)}/${this.state.formFilter.participantId}`)
          // for (let i = 0; i < dataRas3.length; i++) {
          //   worksheet.getCell(`E${16 + i}`).value = dataRas3[i].total_pp
          //   worksheet.getCell(`F${16 + i}`).value = dataRas3[i].total_ml
          //   worksheet.getCell(`G${16 + i}`).value = dataRas3[i].total
          // }
            worksheet.getCell('G34').value = dataRas3[0].total
          console.log('PDPL')
          break;
          case 'PKPL': let dataPkpl = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkPkpl/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataPkpl.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPkpl[i].nama_dp_lain
              worksheet.getCell(`F${16 + i}`).value = dataPkpl[i].jumlah
              worksheet.getCell(`G${16 + i}`).value = dataPkpl[i].program
              worksheet.getCell(`H${16 + i}`).value = dataPkpl[i].rincian
              worksheet.getCell(`I${16 + i}`).value = dataPkpl[i].keterangan
              // worksheet.getCell(`H${16 + i}`).alignment = { horizontal: 'right' };

              const Data1 = worksheet.getColumn(9);
              Data1.width = 60;
            }

            // const Data2 = worksheet.getColumn(8); // Column H is the 8th column (counting starts from 1)
            // Data2.eachCell({ includeEmpty: true }, function(cell, rowNumber) {
            //   cell.alignment = { horizontal: 'right' }; // Aligning all cells in column H to the right
            // });
            console.log('PKPL')
            break;
          case 'PIUB': let dataPIUB = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkPiub/${formatDate(period)}/${participantId}`)
            for (let i = 0; i < dataPIUB.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPIUB[i].nama_pk
              worksheet.getCell(`F${16 + i}`).value = dataPIUB[i].piutang_bunga_pst
              worksheet.getCell(`G${16 + i}`).value = dataPIUB[i].piutang_bunga_pk
              worksheet.getCell(`H${16 + i}`).value = dataPIUB[i].piutang_bunga_tbh
              worksheet.getCell(`J${16 + i}`).value = dataPIUB[i].program_ml
            }
            console.log('PIUB')
            break;
          case 'PIUT': let dataPIUT = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkPiut/${formatDate(period)}/${participantId}`)
            for (let i = 0; i < dataPIUT.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPIUT[i].nama_pk
              worksheet.getCell(`F${16 + i}`).value = dataPIUT[i].piutang_pk_kurang3bln
              worksheet.getCell(`G${16 + i}`).value = dataPIUT[i].piutang_pk_lebih3bln
              worksheet.getCell(`I${16 + i}`).value = dataPIUT[i].piutang_pst_kurang3bln
              worksheet.getCell(`J${16 + i}`).value = dataPIUT[i].piutang_pst_lebih3bln
              worksheet.getCell(`L${16 + i}`).value = dataPIUT[i].piutang_tbh_kurang3bln
              worksheet.getCell(`M${16 + i}`).value = dataPIUT[i].piutang_tbh_lebih3bln
              worksheet.getCell(`O${16 + i}`).value = dataPIUT[i].piutang_sukarela
              worksheet.getCell(`P${16 + i}`).value = dataPIUT[i].program_ml
              worksheet.getCell(`Q${16 + i}`).value = dataPIUT[i].keterangan
            }
            console.log('PIUT')
            break;
          case 'PIUI': let dataPIUI = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction/getReportOjkPiui/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataPIUI.length; i++) {
              console.log('dataPIUI', dataPIUI);
              // worksheet.getCell(`E${16 + i}`).value = dataPIUI[i].category_name
              worksheet.getCell(`E${16 + i}`).value = dataPIUI[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataPIUI[i].amount
              // worksheet.getCell(`G${16 + i}`).value = dataPIUI[i].product_name
              worksheet.getCell(`G${16 + i}`).value = dataPIUI[i].program
              worksheet.getCell(`H${16 + i}`).value = dataPIUI[i].description
              worksheet.getCell(`I${16 + i}`).value = dataPIUI[i].pihak
              // worksheet.getCell(`J${16 + i}`).value = dataPIUI[i].kpd_name
            }
            console.log('PIUI')
            break;
          case 'PIHI': let dataPIHI = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkPihi/${formatDate(period)}/${this.state.formFilter.produkId}`)
            console.log('pihi', dataPIHI);
            for (let i = 0; i < dataPIHI.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPIHI[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataPIHI[i].nilai
              worksheet.getCell(`G${16 + i}`).value = dataPIHI[i].program
              worksheet.getCell(`H${16 + i}`).value = dataPIHI[i].keterangan
              worksheet.getCell(`I${16 + i}`).value = dataPIHI[i].pihak

              const Data1 = worksheet.getColumn(5);
              Data1.width = 70;

              const Data2 = worksheet.getColumn(8);
              Data2.width = 70;
            }
            console.log('PIHI');
          break;
          // case 'BBMK': let dataBBMK = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/getReportOjkBbmk/${formatDate(period)}/${this.state.formFilter.produkId}`)
          case 'BBMK': let dataBBMK = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/getOjkBbmk/${formatDate(period)}/${this.state.formFilter.produkId}`)
            console.log('databbmk', dataBBMK);
            for (let i = 0; i < dataBBMK.length; i++) {
              // worksheet.getCell(`E${16 + i}`).value = dataBBMK[i].akun_name
              // worksheet.getCell(`F${16 + i}`).value = dataBBMK[i].amount
              // worksheet.getCell(`G${16 + i}`).value = dataBBMK[i].product_name
              // worksheet.getCell(`H${16 + i}`).value = dataBBMK[i].description
              worksheet.getCell(`E${16 + i}`).value = dataBBMK[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataBBMK[i].nilai
              worksheet.getCell(`G${16 + i}`).value = dataBBMK[i].program
              worksheet.getCell(`H${16 + i}`).value = dataBBMK[i].keterangan
            }
            console.log('BBMK')
            break;
          case 'PILL': let dataPILL = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getReportOjkPill/${formatDate(period)}/${this.state.formFilter.produkId}`)
            for (let i = 0; i < dataPILL.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataPILL[i].jenis
              worksheet.getCell(`F${16 + i}`).value = dataPILL[i].nilai
              worksheet.getCell(`G${16 + i}`).value = dataPILL[i].program
              worksheet.getCell(`H${16 + i}`).value = dataPILL[i].keterangan
              worksheet.getCell(`I${16 + i}`).value = dataPILL[i].pihak
            }
            console.log('PILL')
            break;
          case 'DPJKV': let dataDPJKV = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/ojkDpjkv/${formatDate(period)}/${this.state.formFilter.produkId}`)
            console.log(dataDPJKV);
            for (let i = 0; i < dataDPJKV.length; i++) {
              worksheet.getCell(`E${16 + i}`).value = dataDPJKV[i].bank_name
              worksheet.getCell(`F${16 + i}`).value = dataDPJKV[i].tanggal_perolehan
              worksheet.getCell(`G${16 + i}`).value = dataDPJKV[i].nilai_nominal
              worksheet.getCell(`H${16 + i}`).value = dataDPJKV[i].nilai_wajar
              worksheet.getCell(`K${16 + i}`).value = dataDPJKV[i].jangka_waktu
              worksheet.getCell(`L${16 + i}`).value = dataDPJKV[i].tingkat_bunga
              worksheet.getCell(`M${16 + i}`).value = dataDPJKV[i].program
              worksheet.getCell(`N${16 + i}`).value = dataDPJKV[i].keterangan
              worksheet.getCell(`O${16 + i}`).value = dataDPJKV[i].nama_cabang
              worksheet.getCell(`P${16 + i}`).value = dataDPJKV[i].kode_bank
              worksheet.getCell(`Q${16 + i}`).value = dataDPJKV[i].swa_kpd
              worksheet.getCell(`R${16 + i}`).value = dataDPJKV[i].nama_mi  
              worksheet.getCell(`S${16 + i}`).value = dataDPJKV[i].jenis_mata_uang
            }
            console.log('dataDPJKV');
          default: break
        }
      } catch (e) {
        console.log(e)
      }
    }

    var dataReportHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/OJK')
    dataReportHeader = dataReportHeader.find(value => value.reportCode === 'OJKD')
    var dataMapping = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataReportHeader.id}`)

    const systemDate = new Date(this.systemDate.dateValue)
    let selectedDate = new Date(period)
    selectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);

    let coaBalance
    if (systemDate.getTime() < selectedDate.getTime()) {
      coaBalance = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-positions/getByProdukId/${this.state.formFilter.produkId}`)
    } else {
      coaBalance = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${this.state.formFilter.produkId}/${formatDate(period)}`)
    }
    var dataCOA = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'coas')

    for (const mapping of dataMapping) {
      var worksheet = workbook.getWorksheet(mapping.description);
      let coaMap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${mapping.id}`)
      coaMap = coaMap.map(value => value.coaId)
      let coaBalanceMap = coaBalance.filter(value => coaMap.includes(value.coaId))
      switch (mapping.description) {
        case 'PDDM': for (let i = 0; i < coaBalanceMap.length; i++) {
          const coa = dataCOA.find(value => value.id === coaBalanceMap[i].coaId)
          // 22 November 2023 adjustment pddm sheet
          // worksheet.getCell(`E${16 + i}`).value = coa.coaName
          // worksheet.getCell(`F${16 + i}`).value = coaBalanceMap[i].balanceAmountBaseCurrency
          // worksheet.getCell(`G${16 + i}`).value = '1001'
          // worksheet.getCell(`H${16 + i}`).value = null
        }
          break;
        // case 'PDIN': for (let i = 0; i < coaBalanceMap.length; i++) {
        //   const coa = dataCOA.find(value => value.id === coaBalanceMap[i].coaId)
        //   worksheet.getCell(`E${16 + i}`).value = coa.coaName
        //   worksheet.getCell(`F${16 + i}`).value = coaBalanceMap[i].balanceAmountBaseCurrency
        //   worksheet.getCell(`G${16 + i}`).value = '1001'
        //   worksheet.getCell(`H${16 + i}`).value = null
        // }
        //   break;
        // case 'BOPR': for (let i = 0; i < coaBalanceMap.length; i++) {
        //   const coa = dataCOA.find(value => value.id === coaBalanceMap[i].coaId)
        //   worksheet.getCell(`E${16 + i}`).value = null // ini minta map kode dengan orang accounting
        //   worksheet.getCell(`F${16 + i}`).value = coaBalanceMap[i].balanceAmountBaseCurrency
        //   worksheet.getCell(`G${16 + i}`).value = '1001'
        //   worksheet.getCell(`H${16 + i}`).value = coa.coaName
        // }
        //   break;
        default: break;
      }
    }



    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    console.log(workbook)
    const excelBuffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([excelBuffer], { type: fileType });


    this.props.store.dispatch(showLoading(false))
    var a = document.createElement("a"),
      url = URL.createObjectURL(data);
    a.href = url;
    period = formatDefaultDate(period).split(' ')
    a.download = 'Laporan OJK Detail ' + period[1] + '-' + period[2] + fileExtension;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Laporan OJK</h2>
        <div>
          <Form
            colCount={1}
            id={'formFilter'}
            formData={this.state.formFilter}
            items={this.filterItem}
            width={'300px'}
          />
        </div>
      </div>
    )
  }
}

export default LaporanOJK