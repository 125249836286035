import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { months, tipeTransaksiKepesertaan } from 'dataSource/lookup';
import notify from 'devextreme/ui/notify'
import httpRequest from 'plugin/httprequest';

class ModalDetailPiutangIuranKepesertaan extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData: {},
            dataMaster: {}
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options: {
            //         'text': 'Simpan',
            //         onClick: () => {
            //             console.log('simpan')
            //         }
            //     },
            //     toolbar: 'bottom'
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    alignment: 'left',
                    text: 'Pemberi Kerja'
                },
                // editorType: 'dxSelectBox',
                // editorOptions: {
                //     dataSource: participants(this.props.store),
                //     valueExpr: "id", // contains the same values as the "statusId" field provides
                //     displayExpr: "participantName", // provides display values
                //     searchEnabled: true,
                //     deferRendering: false,
                // }
            },
            {
                dataField: 'produk',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
            },
            {
                dataField: 'bulan',
                label: {
                    alignment: 'left',
                    text: 'Periode Bulan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                }
                
            },
            {
                dataField: 'tahun',
                label: {
                    alignment: 'left',
                    text: 'Periode Tahun'
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Bayar'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
                
            },
            
        ]

        this.dataGridColumn = [
            {
               dataField: 'id',
               caption: 'No',
               cellRender : (e) => {
                   return e.rowIndex + 1
               }
            },
            {
                dataField: 'kepersertaanAkunId',
                caption: 'Kode Akun',
                lookup:{
                    dataSource: tipeTransaksiKepesertaan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'typeCode' 
                }
            },
            {
                dataField: 'kepersertaanAkunId',
                caption: 'Nama Akun',
                lookup:{
                    dataSource: tipeTransaksiKepesertaan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'transactionName' 
                }
            },
            {
                dataField: 'amountDebit',
                caption: 'Debet',
                format: '#,##0.00'
            },
            {
                dataField: 'amountCredit',
                caption: 'Kredit',
                format: '#,##0.00'
            },
        ]

        this.summary = [
            {
                showInColumn: 'id',
                displayFormat: 'TOTAL'
            },
            {
                column: 'amountDebit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'totalAmountDebit',
                displayFormat: '{0}'
            },
            {
                column: 'amountCredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'totalAmountCredit',
                displayFormat: '{0}'
            },
        ]

        this.dataPesertaIuran = []

    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

        await this.props.submitData(dataSubmit)
        this.hide()
    }

    loadDataGrid = () => {
        return this.dataPesertaIuran
    }

    // retrieveData = async(data) => { 
    //     var DataPesertaIuran = []

    //     var loadAPIIuranByAkun = `iuran-by-akuns/getAllByIuranId/${data.id}`
    //     var dataIuranByAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIIuranByAkun, 'GET')

    //     if(dataIuranByAkun.length > 0){
    //         for(var iuranAkun of dataIuranByAkun){
    //             var loadAPIAkun = `kepersertaan-products/${iuranAkun.pesertaAkunProdukId}`
    //             var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
    
    //             var loadAPIPeserta = `simple-pesertas/getSimpleById/${dataAkun.pesertaId}`
    //             var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
    
    //             var loadAPIuranDetail = `getIuranByGrupByType/${iuranAkun.pesertaAkunProdukId}`
    //             var dataIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')
                
    //             var loadAPIuranTypes = `iuran-types`
    //             var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')
    
    //             var dapenPerusahaan = 0
    //             var dapenKaryawan = 0
    
    //             for(var iuranByType of dataIuranDetail){
    //                 var iuranType = dataIuranTypes.find(value => value.id === iuranByType.iuranType)
    
    //                 if(iuranType.iuranPeserta){
    //                     var dapenKaryawan = dapenKaryawan + iuranByType.nominal
    //                 }else{
    //                     var dapenPerusahaan = dapenPerusahaan + iuranByType.nominal
    //                 }
    //             }
    
    //             var dataPush = {
    //                 id: iuranAkun.id,
    //                 namaPeserta : dataPeserta.fullName,
    //                 phdp: dataPeserta.pesertaKepegawaian.phdp,
    //                 dapenPerusahaan: dapenPerusahaan,
    //                 dapenKaryawan : dapenKaryawan,
    //                 totalDapen : dapenPerusahaan + dapenKaryawan,
    
    //             }
    //             DataPesertaIuran.push(dataPush)
    //         }
    //         this.dataPesertaIuran = DataPesertaIuran
            
    //         this.setState({
    //             dataMaster: data,
    //         })
    //     }
    // }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Piutang Iuran Kepesertaan'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY: 'auto',height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI='kepersertaan-transaction-detils'
                                insertAPI='kepersertaan-transaction-detils'
                                updateAPI='kepersertaan-transaction-detils'
                                deleteAPI='kepersertaan-transaction-detils' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Piutang Iuran Kepesertaan Detail"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                height={'100%'}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Piutang Iuran Kepesertaan Detail'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={false} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}
                                summaryTotalItem={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalDetailPiutangIuranKepesertaan