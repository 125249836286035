import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, participants, product, statusIuran } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalProsesTransaksiDitunda from 'components/pages/modal/kepesertaan/penerimaanIuran/transaksiDitunda/index';
import { confirmAlert } from 'react-confirm-alert'

class ProsesIuranDitunda extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {}
        }

        this.dataGridRef = React.createRef()
        this.modalDetailProsesTransaksiDitundaRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Pengkinian Ulang',
                        hint : 'Pengkinian Ulang',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.batalProsesData(e.row.data)
                        }
                    },
                    {
                        text : 'Proses',
                        hint : 'Proses',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Proses Iuran Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.prosesData(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'iuranId',
                caption: 'No',
                cellRender : (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'bulan',
                caption: 'Periode Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'nama',
                caption: 'Nama',
            },
            {
                dataField: 'product',
                caption: 'Produk',
            },
            {
                dataField: 'dataIuranBo',
                caption: 'Data Iuran BO',
                alignment:'center',
                columns: [
                    {
                        dataField: 'iuranPesertaNominal',
                        caption: 'Peserta',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue : (e) => {
                            var data = e.byIuranPeserta.find(val => val.statusIuranPeserta)
                            return data ? data.nominal : 0
                        }
                    },
                    {
                        dataField: 'iuranPkNominal',
                        caption: 'PemberiKerja',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta.find(val => !val.statusIuranPeserta)
                            return data ? data.nominal : 0
                        }
                    },
                    {
                        dataField: 'total',
                        caption: 'Total',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta
                            return data.map(value => value.nominal).reduce((a,b) => a + b)
                        }
                    },
                ]
            },
            {
                dataField: 'dataIuranPk',
                caption: 'Data Iuran PK',
                alignment:'center',
                columns: [
                    {
                        dataField: 'iuranPesertaExcel',
                        caption: 'Peserta',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue : (e) => {
                            var data = e.byIuranPeserta.find(val => val.statusIuranPeserta)
                            return data ? data.excel : 0
                        }
                    },
                    {
                        dataField: 'iuranPkExcel',
                        caption: 'PemberiKerja',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta.find(val => !val.statusIuranPeserta)
                            return data ? data.excel : 0
                        }
                    },
                    {
                        dataField: 'total',
                        caption: 'Total',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e.byIuranPeserta
                            return data.map(value => value.excel).reduce((a,b) => a + b)
                        }
                    },
                ]
            },
            {
                dataField: 'selisih',
                caption: 'Selisih',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: statusIuran(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalDetail = (data) => {
        this.modalDetailProsesTransaksiDitundaRef.current.show()
        this.modalDetailProsesTransaksiDitundaRef.current.retrieveData(data)
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    

    prosesData = async(data) => {
        var validate = true
        for(var value of data.byIuranPeserta){
            if(value.excel){
                if(value.nominal != value.excel){
                    validate = false
                }
            }
        }
        if(validate){
            var value = {
                idIuran : data.iuranId,
                pesertaAkunProductId: data.akunProdukId 
            }
            try {
                var loadAPI = 'pesertas/transaksiIuranTunda'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                    values:value
                })
                
                this.forceRefresh()
                var text = 'Data Berhasil Diproses!'
                var type = "success"
    
                notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
    
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
                
            }
            this.forceRefresh()
        }else{
            var text = 'Data Masih Belum Sesuai!'
            var type = "error"

            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }
    }

    batalProsesData = async(data) => {
        try {
            var loadAPIuranDetail = `iuran-details/getByIuranId/${data.iuranId}`
            var getIuranDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranDetail, 'GET')

            var dataIuranDetail = getIuranDetail.filter(val => val.pesertaAkunProductId == data.akunProdukId)

            for(var value of dataIuranDetail){
                var dataUpdate = value
                dataUpdate.status = 3
                console.log(dataUpdate)
                var loadAPI = 'iuran-details'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'PUT', {
                    key: value.id,
                    values:dataUpdate
                },value)
            }

            this.forceRefresh()
            var text = 'Data Berhasil Diproses ulang!'
            var type = "success"

            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);

        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
            
        }
        this.forceRefresh()
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Proses Iuran Ditunda</h2>
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='iuran-details/getIuranTunda/4'
                    insertAPI='iurans'
                    updateAPI='iurans'
                    deleteAPI='iurans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Proses Iuran Ditunda"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Proses Iuran Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    keyField={'iuranId'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalProsesTransaksiDitunda 
                    ref={this.modalDetailProsesTransaksiDitundaRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default ProsesIuranDitunda