import React, { Component } from 'react'
import { Form } from "devextreme-react";
import ModalActionPotongan from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/potongan/index';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { banks, months, product, programs} from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog'
import httpRequest from 'plugin/httprequest';
import { formatDefaultMonthYear } from 'plugin/helper';
import FormGenerateModalPhdpNs from './formGenerateModalPhdpNs';
import FormDetailModalPhdpNs from './formDetailModalPhdpNs';

class PenyesuaianPhdp  extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {
                programId: 0,
                getUrl: `view-pengajuan-perubahan-phdp-ns?productId.equals=1&size=9999`
            }
        }

        this.dataGridRef = React.createRef()
        this.showModalGenerateRef = React.createRef()
        this.showModalDetailRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'programId',
                label: {
                    alignment: 'left',
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                },
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        cssClass: 'text-success',
                        onClick : async (e) => {
                            if (e) {
                                await alert('Under Development', 'Information!');
                            }
                            this.showModalDetail(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'NO',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'noPengajuan',
                caption: 'Nomor Pengajuan',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'rapelTypeId',
                caption: 'Jenis Rapel',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Rapel dibayar bersama MP Bulanan'
                        },
                        {
                            id: 2,
                            value: 'Rapel terpisah'
                        },
                        {
                            id: 3,
                            value: 'PHDP'
                        },
                        {
                            id: 4,
                            value: 'NS'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField: 'jumlahPeserta',
                caption: 'Jumlah Peserta',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'jumB',
                        caption: 'Bulanan'
                    },
                    {
                        dataField: 'jumS',
                        caption: 'Sekaligus'
                    },
                ]
            },
            {
                dataField: 'periodeNs',
                caption: 'Periode NS',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'periodeNsLama',
                        caption: 'Lama'
                    },
                    {
                        dataField: 'periodeNsBaru',
                        caption: 'Baru'
                    }
                ]
            },
            {
                dataField: 'totalRapel',
                caption: 'Total Rapel',
                alignment: 'center', 
                columns: [
                    {
                        dataField: 'totalRapelMp',
                        caption: 'Nilai MP',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'totalPotonganIuran',
                        caption: 'Potongan Iuran',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'totalRapelPajak',
                        caption: 'Pajak',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'totalRapelMpTransfer',
                        caption: 'MP Ditransfer',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'tanggalPengajuan',
                        caption: 'Tanggal Diajukan'
                    }
                ]
            },
            {
                dataField: "status",
                label: {
                    text: "Status",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: [
                        {
                            id: '1',
                            value: 'Open'
                        },
                        {
                            id: '2',
                            value: 'Sent'
                        }
                    ],
                    displayExpr: 'value',
                    valueExpr: 'id',
                    searchEnabled: true,
                }
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    filterSubmit = async() => {
        let data = this.state.formFilter;

        this.setState(prevState => ({
                formFilter: {
                    ...prevState.formFilter,
                    getUrl: `view-pengajuan-perubahan-phdp-ns?productId.equals=${data.programId}&size=9999`
                }
        }))

        this.forceRefresh();
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    // icon: 'plus',
                    text: 'Generate Rapel',
                    onClick: (e) => {
                        this.showModaGenerate()
                    },
                }
            }
        )
    }

    showModaGenerate = () => {
        this.showModalGenerateRef.current.show()
    }

    showModalDetail = (data) => {
        console.log(data);
        // if (data) {
        //     this.showModalDetailRef.current.retrieveData(data);
        // }
        // this.showModalDetailRef.current.show();
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Rapel Penyesuaian PHDP & NS</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                    // className = {'mb-3'}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={this.state.formFilter.getUrl}
                    insertAPI='pembayaran-potongans'
                    updateAPI='pembayaran-potongans'
                    deleteAPI='pembayaran-potongans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Ditunda"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormGenerateModalPhdpNs 
                    ref={this.showModalGenerateRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                
                {/* <FormDetailModalPhdpNs 
                    ref={this.showModalDetailRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                /> */}
            </div>
        )
    }
}

export default PenyesuaianPhdp