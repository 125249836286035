import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import {alert,confirm} from "devextreme/ui/dialog";
import httpRequest from "plugin/httprequest";
import { coa, journalType } from "dataSource/lookup";
import { showLoading } from "redux/actions/loading";
import DataGridDetailInstruksiManagerInvestasi from "../investasi/instruksi/mi/datagrid/index";

class ModalAddCashFlowConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible:false,
      DataMaster:{}
    };

    this.dataFromType = {}
    this.dataFromCoa = {}
    this.listDataTable = {}

    this.formItem = [
        {
          dataField: 'coaId',
          label: {
            text: 'Kode COA'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: coa(this.props.store),
            valueExpr: 'id',
            displayExpr: (item) => {
              return item && item.coaCode + ' - ' + item.coaName
            }, // provides display values
            searchEnabled: true,
            deferRendering: false,
          },
          validationRules: [
            {
              type: 'async',
              validationCallback: async (e) => {
                this.dataFromCoa = e.value
                const result = await this.checkCoaAndTypeId()
                if (result.isValid) {
                  return true
                } else {
                  throw new Error(result.message)
                }
              },
            },
            {
              type: 'required',
              message: 'Kode COA wajib diisi!'
            }
          ]
        },
        {
          dataField: 'journalTypeId',
          label: {
            text: 'Tipe Jurnal'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: journalType(this.props.store),
            valueExpr: 'id',
            displayExpr: (item) => {
              return item && item.journalTypeCode + ' - ' + item.journalTypeDesc
            }, // provides display values
            searchEnabled: true,
            deferRendering: false,
          },
          validationRules: [
            {
              type: 'async',
              validationCallback: async (e) => {
                this.dataFromType = e.value
                const result = await this.checkCoaAndTypeId()
                if (result.isValid) {
                  return true
                } else {
                  throw new Error(result.message)
                }
              },
            },
            {
              type: 'required',
              message: 'Tipe Jurnal wajib diisi!'
            }
          ]
        },
        {
          dataField: 'type',
          label: {
            text: 'Tipe Saldo'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: [
              {
                id: 'p',
                value: 'Positif (Mengikuti Saldo Normal)'
              },
              {
                id: 'n',
                value: 'Negatif (Kebalikan dari Saldo Normal)'
              }
            ],
            valueExpr: 'id',
            displayExpr: 'value',
          },
          validationRules: [
            {
              type: 'required',
              message: 'Tipe Saldo wajib diisi!'
            }
          ]
        },
        {
          dataField: 'dcMethod',
          label: {
            text: 'Filter DC'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: [
              {
                id: 'd',
                value: 'Hanya Debet'
              },
              {
                id: 'c',
                value: 'Hanya Kredit'
              },
              {
                id: 'b',
                value: 'Tidak ada Filter'
              },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
          },
          validationRules: [
            {
              type: 'required',
              message: 'Filter DC wajib diisi!'
            }
          ]
        }
    ]

    this.formItemEdit = [
        {
          dataField: 'coaId',
          label: {
            text: 'Kode COA'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: coa(this.props.store),
            valueExpr: 'id',
            displayExpr: (item) => {
              return item && item.coaCode + ' - ' + item.coaName
            }, // provides display values
            searchEnabled: true,
            deferRendering: false,
          },
          validationRules: [
            // {
            //   type: 'async',
            //   validationCallback: async (e) => {
            //     this.dataFromCoa = e.value
            //     const result = await this.checkCoaAndTypeId()
            //     if (result.isValid) {
            //       return true
            //     } else {
            //       throw new Error(result.message)
            //     }
            //   },
            // },
            {
              type: 'required',
              message: 'Kode COA wajib diisi!'
            }
          ]
        },
        {
          dataField: 'journalTypeId',
          label: {
            text: 'Tipe Jurnal'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: journalType(this.props.store),
            valueExpr: 'id',
            displayExpr: (item) => {
              return item && item.journalTypeCode + ' - ' + item.journalTypeDesc
            }, // provides display values
            searchEnabled: true,
            deferRendering: false,
          },
          validationRules: [
            // {
            //   type: 'async',
            //   validationCallback: async (e) => {
            //     this.dataFromType = e.value
            //     const result = await this.checkCoaAndTypeId()
            //     if (result.isValid) {
            //       return true
            //     } else {
            //       throw new Error(result.message)
            //     }
            //   },
            // },
            {
              type: 'required',
              message: 'Tipe Jurnal wajib diisi!'
            }
          ]
        },
        {
          dataField: 'type',
          label: {
            text: 'Tipe Saldo'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: [
              {
                id: 'p',
                value: 'Positif (Mengikuti Saldo Normal)'
              },
              {
                id: 'n',
                value: 'Negatif (Kebalikan dari Saldo Normal)'
              }
            ],
            valueExpr: 'id',
            displayExpr: 'value',
          },
          validationRules: [
            {
              type: 'required',
              message: 'Tipe Saldo wajib diisi!'
            }
          ]
        },
        {
          dataField: 'dcMethod',
          label: {
            text: 'Filter DC'
          },
          editorType: 'dxSelectBox',
          editorOptions: {
            dataSource: [
              {
                id: 'd',
                value: 'Hanya Debet'
              },
              {
                id: 'c',
                value: 'Hanya Kredit'
              },
              {
                id: 'b',
                value: 'Tidak ada Filter'
              },
            ],
            valueExpr: 'id',
            displayExpr: 'value',
          },
          validationRules: [
            {
              type: 'required',
              message: 'Filter DC wajib diisi!'
            }
          ]
        }
    ]

    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: async () => {
            var formValidation = this.validate()
            if (formValidation) {
              this.submitData()
            }
          }
          // onClick: this.submitData.bind(this)
        },
        toolbar: "bottom"
      },
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Batal",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.PopupToolbarItem2 = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: async () => {
            var formValidation = this.validate()
            if (formValidation) {
              this.updateData()
            }
          }
          // onClick: this.submitData.bind(this)
        },
        toolbar: "bottom"
      },
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Batal",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.formRef = React.createRef()
  }

  submitData = async () => {
    let dataState = this.state.DataMaster;

    var listData = this.listDataTable
    let coa = listData.find(value => value.coaId === dataState.coaId)
    let tipeJurnal = listData.find(value => value.journalTypeId === dataState.journalTypeId)

    if (coa && tipeJurnal) {
      return {
        isValid: false,
        message: `COA Dan Tipe Jurnal sudah terpakai!`
      }
    } else {
      this.props.submitDataTest({
        ...dataState
      })
      this.hide()
    }
  }

  updateData = async() => {
    let dataState = this.state.DataMaster;

    if(dataState) {
      this.props.submitDataTest({
        ...dataState
      })
      this.hide()
    }
  }

  validate = () => {
    var formValidation = this.formRef.current.instance.validate();
    return formValidation.isValid
  }

  checkCoaAndTypeId = async () => {
    var listData = this.listDataTable
    let coa = listData.find(value => value.coaId === this.dataFromCoa)
    let tipeJurnal = listData.find(value => value.journalTypeId === this.dataFromType)

    if (coa && tipeJurnal) {
      return {
        isValid: false,
        message: `COA Dan Tipe Jurnal sudah terpakai!`
      }
    } else {
      return {
        isValid: true,
        message: ''
      }
    }
  }

  retrieveData = (id, listData, type) => {
    this.listDataTable = listData
    this.reportHeaderDetailsId = id
    if(type === 'add') {
      let data = {
        journalTypeId: 1101
      }
  
      this.setState({
        DataMaster: data
      })
    } else {
      this.setState({
        DataMaster: listData
      })
    }
  }

  retrieveDataDetail = (data) => {
    this.setState({
      DataMaster: data
    })
  }

  show = () => {
    this.setState({
      popupVisible: true
    });
  };

  hide = () => {
    this.setState({
      popupVisible: false,
      DataMaster: {}
    });
  };

  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={false}
          showTitle={true}
          title={this.props.actionType === 'add' ? `Tambah Konfigurasi Detail ${this.props.reportHeaderData.reportType} - ${this.props.reportHeaderData.reportName}` : `Edit Konfigurasi Detail ${this.props.reportHeaderData.reportType} - ${this.props.reportHeaderData.reportName}`}
          width={"700"}
          height={"auto"}
          toolbarItems={this.props.actionType === 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
        >
          <Form
            colCount={1}
            id={'formMaster'}
            formData={this.state.DataMaster}
            items={this.props.actionType === 'add' ? this.formItem : this.formItemEdit}
            labelLocation = 'left'
            ref={this.formRef}
          />
        </Popup>
      </React.Fragment>

    );
  }
}

export default ModalAddCashFlowConfig;
