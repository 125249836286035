import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { instrumentCategories, instrumentCategorySubs, productDefault } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalEditIndikasi from './edit-indikasi/index';

class ModalIndikasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.modalRef = React.createRef()
        this.datagridRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.column = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModalEditIndikasi(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: (e) => {
                            this.delete(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'categorySubId',
                caption: 'Kategori',
                lookup: {
                    dataSource: instrumentCategorySubs(this.props.store),
                    valueExpr: "id",
                    displayExpr: "subName",
                }
            },
            {
                dataField: 'indikasiRisiko',
                caption: 'Indikasi Risiko',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'resikoKredit',
                        caption: 'Risiko Kredit (40%)',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'resikoPasar',
                        caption: 'Risiko Pasar (30%)',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'resikoOperasional',
                        caption: 'Risiko Operasional (10%)',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'resikoLiquiditas',
                        caption: 'Risiko Likuiditas (20%)',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                ]
            },
            {
                dataField: 'resikoTotal',
                caption: 'Total (%)',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'resikoPengurang',
                caption: 'Pengurang',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'resikoKelas',
                caption: 'Risiko Kelas',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    showModalAddIndikasi = () => {
        this.modalRef.current.show()
    }

    showModalEditIndikasi = (data = null) => {
        if (data) {
            this.modalRef.current.retrieveData(data)
        }
        this.modalRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Indikasi',
                    onClick: (e) => {
                        this.modalRef.current.show()
                    },
                }
            }
        )
    }

    delete = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kebijakan-spesifikasi-values', 'DELETE', data.id);

            this.forceRefresh();
            notify({ message: "Sukses Hapus Data", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 1000);
        } catch (error) {
            console.log(error);
            notify({ message: "Terjadi Kesalahan", width: "AUTO", shading: true, position: { at: "center", my: "center", of: window } }, 'error', 1000);
        }
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-values?kebijakanSpesifikasi.equals=4&productId.equals=1`, 'GET')
        return response
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'80vw'}
                    height="80vh"
                    title={`Indikasi`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}
                                        loadAPI='-'
                                        insertAPI='-'
                                        updateAPI='-'
                                        deleteAPI='-'

                                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                        useArraySource={true}
                                        ArraySourceData={this.loadData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}
                                        exportExcel={true}
                                        exportFileName={"Indikasi"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={''}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={350} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.column} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}
                                        onToolbarPreparing={this.onToolbarPreparing}
                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                    <ModalEditIndikasi
                                        ref={this.modalRef}
                                        store={this.props.store}
                                        forceRefresh={this.forceRefresh}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalIndikasi