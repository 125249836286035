import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import { tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta } from 'dataSource/lookup';


class FormPertimbangan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            dataPertimbangan:{},
        }
        
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                }
            },
            {
                dataField: 'kode',
                label: {
                    alignment: 'left',
                    text: 'Kode'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal'
                },
                editorType: 'dxDateBox'
            },
            {
                dataField: 'tipeTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Tipe Transaksi'
                }
            },
        ]
        this.DataPertimbangan = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                }
            },
            {
                dataField: 'kode',
                label: {
                    alignment: 'left',
                    text: 'Kode'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal'
                }
            },
            {
                dataField: 'instrumen',
                label: {
                    alignment: 'left',
                    text: 'Instrumen'
                }
            },
            {
                dataField: 'transaksi',
                label: {
                    alignment: 'left',
                    text: 'Transaksi'
                }
            },
            {
                dataField: 'efek',
                label: {
                    alignment:'left',
                    text: 'Efek'
                }
            },
        ]
        this.toolbarItem = [
            'background' , 'bold' , 'color' , 'italic' , 'link' , 'image' , 'strike' , 'subscript' , 'superscript' , 'underline' , 'blockquote' , 'header' 
            , 'increaseIndent' , 'decreaseIndent' , 'orderedList' , 'bulletList' , 'alignLeft' , 'alignCenter' , 'alignRight' , 'alignJustify' , 'codeBlock' 
            , 'variable' , 'separator' , 'undo' , 'redo' , 'clear'
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

         
        // this.hide()
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Order Pertimbangan Saham`}
                minWidth={'70vh'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                        <div id="tabindex-2" className={`mt-2 col-md-12` } style={{height:"calc(57vh - 70px)", overflowY:"auto"}}>
                            <Form
                                colCount={4}
                                id={'formPertimbangan'}
                                formData={this.state.dataPertimbangan}
                                items={this.DataPertimbangan}
                                scrollingEnabled={false}
                                readOnly={true}
                                labelLocation={"left"}
                            />
                            <HtmlEditor 
                                height="300px"
                                value={this.state.content}
                                name={'content'}
                                onValueChanged={this.onValueChanged}
                            >
                                <Toolbar>
                                    {    
                                        this.toolbarItem.map((toolbarItem) => {
                                            return(
                                                <Item
                                                    formatName = {toolbarItem}
                                                />
                                            )
                                        })
                                    }
                                </Toolbar>
                            </HtmlEditor>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormPertimbangan
