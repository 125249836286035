import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import { banks, currency, kepersertaanAkun, kepersertaanAkunById, programs, tipeTransaksiKepesertaan } from 'dataSource/lookup'
import notify from 'devextreme/ui/notify'

class FormModalRekBankKepesertaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            newData: true
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.formItems = [
            {
                dataField: 'productId',
                label: {
                    text: 'Program',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                },
            },
            {
                dataField: 'bankId',
                label: {
                    text: 'Bank',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName',
                    searchEnabled: true
                }
            },
            {
                dataField: 'rekNo',
                label: {
                    text: 'No Rekening',
                },
            },
            {
                dataField: 'rekName',
                label: {
                    text: 'Nama Rekening',
                },
            },
            {
                dataField: 'transactionTypeId',
                label: {
                    text: 'Tipe Transaksi',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: tipeTransaksiKepesertaan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName',
                    searchEnabled: true
                }
            },
            {
                dataField: 'akunId',
                label: {
                    text: 'Akun',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: this.props.actionType === 'add' ? kepersertaanAkun(this.props.store) : kepersertaanAkunById(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName',
                    searchEnabled: true
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Currency',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                    searchEnabled: true,
                },
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.submitData();
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.updateData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    retrieveData = async (data) => {
        this.setState({
            dataMaster: data
        })
    }

    submitData = async () => {
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'staticdata-wf/rekbanks', 'POST', {
                values: data
            });

            if (response) {
                this.hide();
                await notify({ message: 'Berhasil tambah data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            await notify({ message: `Terjadi kesalahan input ${error}`, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            console.log(error);
        }
    }

    updateData = async () => {
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'staticdata-wf/rekbanks', 'PUT', {
                key: data.id,
                values: data
            }, data);

            if (response) {
                this.hide();
                await notify({ message: 'Berhasil ubah data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            await notify({ message: `Terjadi kesalahan input ${error}`, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            console.log(error);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={this.props.actionType === 'add' ? 'Form Rekening Bank Kepesertaan' : 'Form Edit Rekening Bank Kepesertaan'}
                width={"30vw"}
                height={"auto"}
                toolbarItems={this.props.actionType === 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                <Form
                    ref={this.formRef}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.formItems}
                    labelLocation='left'
                />
            </Popup>
        )
    }
}

export default FormModalRekBankKepesertaan