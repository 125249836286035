import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import ModalGlobalParam from 'components/pages/modal/global-setting/modalGlobalParam'
import { addURL } from 'redux/actions/url'
import { paramType } from 'dataSource/lookup'

class GlobalParameter extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: "buttons",
                width: 140,
                buttons: [
                  "edit",
                  {
                    text: "Ubah",
                    hint: "Ubah",
                    icon: "edit",
                    cssClass: "text-danger", 
                    visible: (e) => {
                        if(e.row.data.edit == 1){
                          return true
                        }else{
                          return false
                        }
                      },
                    onClick:this.showModalGlobalParam.bind(this)
                    },
                ]
              },
            {
            dataField: 'paramCode',
            caption: 'Kode Parameter Global',
            sortOrder: 'asc'
        }, 
        {
            dataField: 'paramName',
            caption: 'Parameter Global',
        }, 
        {
            dataField: 'paramType',
            caption: 'Parameter Type',
            lookup: {
                dataSource: paramType(),
                valueExpr: 'id',
                displayExpr: 'value',
            },
        }, 
        {
            dataField: 'paramValue',
            caption: 'Global Param Value',
            cellRender:(rowInfo) => {
                var paramType = rowInfo.data.paramType

                switch (paramType) {
                    case 'S':
                        return rowInfo.data.stringValue
                        break;
                    case 'F':
                        return rowInfo.data.floatValue
                        break;
                    case 'D':
                        return rowInfo.data.dateValue
                        break;
                    case 'I':
                        return rowInfo.data.intValue
                        break;
                
                    default:
                        break;
                }
            }
        },
        ] 

        this.dataGridRef = React.createRef()
        this.popupModalGlobalParamRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 


    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh();
    };
    
    showModalGlobalParam = (e) => {
        if (e.row) {
            this.popupModalGlobalParamRef.current.retriveDataGlobalParam(e.row.data)
        }
        this.popupModalGlobalParamRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  text: 'Global Parameter',
                  onClick: (e) => {
                    this.showModalGlobalParam(e)
                  },
              }
          }
        )
      }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Parameter Global</h2>

                <DevExpressDataGrid
                ref={this.dataGridRef}
                    loadAPI='global-parameters'
                    insertAPI='global-parameters'
                    updateAPI='global-parameters'
                    deleteAPI='global-parameters' 

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Global Parameter"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Global Parameter Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalGlobalParam 
                    ref={this.popupModalGlobalParamRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default GlobalParameter
