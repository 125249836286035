import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { months, product } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog';

class ModalCloseDaftarUlang extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popupVisible: false,
      dataMaster: {},
      // actionType: ''
    }

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)

    this.PopupRef = React.createRef()

    this.PopupToolbarItem = [
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Simpan',
          onClick: async () => await this.submitData(),
        },
        toolbar: 'bottom',
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Tutup',
          onClick: this.hide.bind(this)
        },
        toolbar: 'bottom'
      }
    ]

    this.dataMaster = [
      {
        dataField: 'productId',
        label: {
          text: 'Produk'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
          // dataSource: pesertaPasif(this.props.store),
          dataSource: product(this.props.store),
          valueExpr: 'id',
          displayExpr: 'productCode'
        },
      },
      {
        dataField: 'periodeBulan',
        label: {
          text: 'Bulan'
        },
        editorType: 'dxSelectBox',
        editorOptions: {
          dataSource: months(),
          valueExpr: 'id',
          displayExpr: 'value'
        },
      },
      {
        dataField: 'periodeTahun',
        label: {
          text: 'Tahun'
        },
      },
      {
        dataField: 'pengkinianTanggal',
        label: {
          alignment: 'left',
          text: 'Tanggal Awal Daftar Ulang'
        },
        editorType: 'dxDateBox',
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        dataField: 'tanggalAkhir',
        label: {
          alignment: 'left',
          text: 'Tanggal Akhir Daftar Ulang'
        },
        editorType: 'dxDateBox',
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        dataField: 'tanggalPenutupan',
        label: {
          alignment: 'left',
          text: 'Tanggal Penutupan Periode'
        },
        editorType: 'dxDateBox',
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
    ]
  }

  get Popup() {
    return this.PopupRef.current.instance
  }

  show() {
    this.setState({
      popupVisible: true,
      selectedTabIndex: 0
    });
  }

  hide() {
    this.setState({
      popupVisible: false,
      Data: {},
      typeData: {}
    });
  }

  onValueChanged = (e) => {
    var fieldName = e.component._options._optionManager._options.name
    var fieldValue = e.value

    this.setState({
      [fieldName]: fieldValue
    })
  }

  submitData = async () => {
    var data = { ...this.state.dataMaster }
    try{
      var updateAPI = 'daftar-ulangs/closingDaftarUlang'                    
      var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'POST', {
        values:data
      })
      
      await alert('Berhasil menutup data daftar ulang!','Success')
    }catch(e){
      await alert(e, 'Failed')
    }
    this.props.forceRefresh()
    this.hide()
  }

  retrieveData = async (data) => {
    this.setState({
      dataMaster: data,
    })
  }
  render() {
    return (
      <Popup
        className={'popup'}
        visible={this.state.popupVisible}
        onHiding={this.hide}
        dragEnabled={true}
        resizeEnabled={true}
        showTitle={true}
        title={'FORM DAFTAR ULANG PESERTA PASIF'}
        width={1000}
        height={350}

        ref={this.PopupRef}

        toolbarItems={this.PopupToolbarItem}
      >
        <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
          <div className="row">
            <div className="col-md-12">
              <Form
                colCount={1}
                id={'formDataMaster'}
                formData={this.state.dataMaster}
                items={this.dataMaster}
                labelLocation="left"
                scrollingEnabled={true}
              />
            </div>
          </div>
        </div>
      </Popup>
    );
  }
}


export default ModalCloseDaftarUlang