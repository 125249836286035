import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { banks, instrumentSubCategoryDeposito, ordersOpen, depositoWasSent, kpds } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalActionPenempatanSettlementTransaksiDeposito from './actionPenempatan';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import { showLoading } from 'redux/actions/loading';
import { formatDate } from 'plugin/helper';


class ModalSettlementTransaksiDepositoPenempatn extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            biayaBank: {},
            actionType: null
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if(formValidation){
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.loadDummyData = []

        this.itemPenempatanMaster = [
            {
                dataField: 'bankId',
                label: {
                    alignment: 'left',
                    text: 'Bank'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName',
                    searchEnabled: true,
                    deferRendering: false, 
                    onValueChanged: (e) => {
                        this.setState({
                            dataMaster : {
                                bankId: e.value
                            }
                        })
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Bank"
                    }
                ]
            },
            {
                dataField: 'referensiNo',
                label: {
                    alignment: 'left',
                    text: 'No Referensi'
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill referensi Number"
                    }
                ]
            },
        ]
        this.penempatanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            data.bankId = this.state.dataMaster.bankId
                            this.modalInsertRef.current.show(data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDetailPenempatanData(e.row.key)
                        }
                    },
                ],
            },
            {
                dataField: 'no',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'bilyetNo',
                caption: 'No Bilyet',
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelolaan',
            },
            {
                dataField: 'orderId',
                caption: 'No Order',
                lookup: {
                    dataSource: depositoWasSent(this.props.store),
                    valueExpr: 'orderId',
                    displayExpr: 'orderCode'
                }
            },
            {
                dataField: 'kpdId',
                caption: 'Tipe',
                lookup: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak'
                }
            },
            {
                dataField: 'categorySubId',
                caption: 'Jenis Deposito',
                lookup: {
                    dataSource: instrumentSubCategoryDeposito(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                }
            },
            {
                dataField: 'tanggalPenempatan',
                caption: 'Tanggal Penempatan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tenor',
                caption: 'Tenor',
                cellRender: (e) => {
                    var data = e.row.data
                    return data.tenor + ' ' + data.tenorTypeId
                }
            },
            {
                dataField: 'rateOrder',
                caption: 'Rate (% pa)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nominal',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'biayaBank',
                caption: 'Biaya Bank',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },

        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
        this.dataGridPenempatanRef.current.forceRefresh(true)
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))

        var dataDataGrid = this.dataGridPenempatanRef.current.getDataSource()
        var dataMaster = this.state.dataMaster
        var customSubmitData = {
            ...dataMaster,
            penempatanDepositoList: dataDataGrid,
            trsDate: this.sysDate.dateValue
        }
         
        if (customSubmitData) {
            try {

                var insertAPI = 'order-depositos/settle-all-penempatan-deposito'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: customSubmitData
                })


                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()

                return response
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    onToolbarPreparingPenempatan = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Penempatan',
                    icon: 'plus',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if(formValidation){
                            this.showModalInsert()
                        }
                    }
                }
            }
        )
    }
    forceRefresh = () => {
        this.dataGridPenempatanRef.current.forceRefresh(true)
    }
    showModalInsert = () => {
        this.modalInsertRef.current.show({
            bankId: this.state.dataMaster.bankId
        })
    }
    submitDetailPenempatanData = (data) => {
        if (data.id) {
            this.dataGridPenempatanRef.current.push([{
                type: "update",
                data: {
                    bilyetNo: data.bilyetNo,
                    pengelola: data.pengelola,
                    orderId: data.orderId,
                    bankId: data.bankId,
                    categorySubId: data.categorySubId,
                    nominal: data.nominal,
                    tanggalPenempatan: data.tanggalPenempatan,
                    trsDate: data.trsDate,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    rateOrder: data.rateOrder,
                    paymentRekbankId: data.paymentRekbankId,
                    receiveRekbankId: data.receiveRekbankId,
                    currencyId: data.currencyId,
                    catatan: data.catatan,
                    biayaBank: data.biayaBank,
                    productCode: data.productCode,
                    maturityDate: data.maturityDate,
                    fundManagerName: data.fundManagerName,
                    kpdId: data.kpdId
                },
                key: data.id
            }])
        } else {
            this.dataGridPenempatanRef.current.push([{
                type: "insert",
                data: {
                    bilyetNo: data.bilyetNo,
                    pengelola: data.pengelola,
                    orderId: data.orderId,
                    bankId: data.bankId,
                    categorySubId: data.categorySubId,
                    nominal: data.nominal,
                    tanggalPenempatan: data.tanggalPenempatan,
                    trsDate: data.trsDate,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    rateOrder: data.rateOrder,
                    paymentRekbankId: data.paymentRekbankId,
                    receiveRekbankId: data.receiveRekbankId,
                    currencyId: data.currencyId,
                    catatan: data.catatan,
                    biayaBank: data.biayaBank,
                    productCode: data.productCode,
                    maturityDate: data.maturityDate,
                    fundManagerName: data.fundManagerName,
                    kpdId: data.kpdId
                }
            }])
        }
    }
    deleteDetailPenempatanData = (key) => {
        this.dataGridPenempatanRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    loadData = () => {
        return []
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Deposito'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                    <div className="row">
                        <div id="tabindex-0" className={'mt-2 col-md-12 text-center'}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        ref={this.formRef}
                                        formData={this.state.dataMaster}
                                        items={this.itemPenempatanMaster}
                                        labelLocation={"left"}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <DevExpressDataGrid
                                        ref={this.dataGridPenempatanRef}
                                        loadAPI='cash-deviden'
                                        insertAPI='cash-deviden'
                                        updateAPI='cash-deviden'
                                        deleteAPI='cash-deviden'

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        // ArraySourceData={this.loadDummyData}
                                        ArraySourceData={this.loadData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Penempatan"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}
                                        height={300}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Penempatan'}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={500} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.penempatanColumns} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        onToolbarPreparing={this.onToolbarPreparingPenempatan}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <ModalActionPenempatanSettlementTransaksiDeposito
                                    ref={this.modalInsertRef}
                                    store={this.props.store}
                                    forceRefresh={this.forceRefresh}
                                    submitDetailPenempatanData={this.submitDetailPenempatanData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalSettlementTransaksiDepositoPenempatn