import React, { Component } from 'react'
import { currency, banks, instruments, orders, instrumentsCategorySaham, product } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormOrderUploadSaham from 'components/pages/modal/investasi/order/saham/upload'
import { addURL } from 'redux/actions/url'
import FormPembelianSaham from 'components/pages/modal/investasi/order/saham/pembelian'
import FormPenjualanSaham from 'components/pages/modal/investasi/order/saham/penjualan'
import FormPertimbangan from 'components/pages/modal/investasi/order/saham/pertimbangan'
import { Button } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'
import * as XLSX from 'xlsx';
import { excelCellFormat, formatDefaultFullDate, formatNumber } from 'plugin/helper'
import DetailOrderStock from './detail'

class OrderSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridOrderSahamRef = React.createRef()
        this.popupFormPembelianSahamRef = React.createRef()
        this.popupFormPenjualanSahamRef = React.createRef()
        this.popupFormPertimbanganSahamRef = React.createRef()
        this.popupFormOrderUploadSahamRef = React.createRef()

        this.orderColumns = [
            {
                dataField: 'orderCode',
                caption: 'Kode',
            },
            {
                dataField: 'transactionName',
                caption: 'Tipe',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Saham',
            },
            {
                dataField: 'orderDate',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'totalBroker',
                caption: 'Total Broker',
                dataType: 'number',
                alignment: 'right',
            },
            {
                dataField: 'totalQuantityOrder',
                caption: 'Kuantitas',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0',
            },
            {
                dataField: 'orderStatus',
                caption: 'Status',
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.generateExcelFile()
                    },
                }
            }
        )
    }
    showModalFormOrderUploadSaham = () => {
        this.popupFormOrderUploadSahamRef.current.show();
    }
    onSelectionChanged = (e) => {
        var orderId = e.selectedRowKeys
        this.setState({
            orderId: orderId
        })
    }
    forceRefresh = () => {
        this.DataGridOrderSahamRef.current.forceRefresh(true)
    }
    customDetailElem = (data) => {
        return (
            <DetailOrderStock detailData={data.orderId} store={this.props.store} />
        )
    }
    generateExcelFile = () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Data Order Saham",
            Author: "DAPEN Pegadaian",
            CreatedDate: new Date()
        };
        wb.SheetNames.push("Order Saham");

        var ws_data = ['No.', 'Kode Order', 'Tipe Order', 'Nama Saham', 'Tanggal Order', 'Kuantitas', 'Tipe Harga', 'Harga Minimum', 'Harga Maksimum'];
        var dataGrid = this.DataGridOrderSahamRef.current.getDataSource()

        // ws_data.push()
        for (var dataOrder of dataGrid.data) {
            var rowNo = 1
            ws_data.push([
                rowNo,
                dataOrder.orderData.orderCode,
                dataOrder.orderData.transactionName,
                dataOrder.orderData.instrumentCode,
                formatDefaultFullDate(dataOrder.orderData.orderDate),
                excelCellFormat(dataOrder.orderData.totalQuantityOrder, 'n', '#,##0.00'),
                dataOrder.detailData.priceType,
                excelCellFormat(dataOrder.detailData.priceMin, 'n', '#,##0.00'),
                excelCellFormat(dataOrder.detailData.priceMax, 'n', '#,##0.00'),
            ])
            rowNo++
            ws_data.push([''])
            ws_data.push([''])
        }
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        var wscols = [
            { wch: 7 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
            { wch: 20 },
        ];
        ws['!cols'] = wscols

        wb.Sheets["Order Saham"] = ws;

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });


        var a = document.createElement("a"),
            url = URL.createObjectURL(data);
        a.href = url;
        a.download = 'Order Saham' + fileExtension;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
    generateInstruksi = async () => {
        var id = {
            listOrderId: this.state.orderId
        }
        confirmAlert({
            title: 'Generate',
            message: 'Apakah anda yakin ingin generate data ini?',
            buttons: [
                {
                    label: 'Ya',
                    onClick: async (e) => {
                        if (id.listOrderId != undefined) {
                            try {
                                var insertAPI = 'order-stoks/buat-instruksi-stok'
                                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                                    values: id
                                })
                                notify({ message: 'Generate Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                                this.forceRefresh()
                                return response
                            } catch (e) {
                                console.log(e)
                                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        } else {
                            notify({ message: 'Silahkan pilih data untuk di generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    }
                },
                {
                    label: 'Tidak'
                }
            ]
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Order Saham</h2>
                <DevExpressDataGrid
                    keyField="orderId"
                    ref={this.DataGridOrderSahamRef}
                    loadAPI='order-stoks/list?size=9999&status=OPEN'
                    insertAPI='order-stoks'
                    updateAPI='order-stoks'
                    deleteAPI='order-stoks'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource={true}
                    // ArraySourceData={this.dataSource}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Order Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Order Saham'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onSelectionChanged={this.onSelectionChanged}
                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Generate Instruksi"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.generateInstruksi.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
                <FormOrderUploadSaham
                    ref={this.popupFormOrderUploadSahamRef}
                    store={this.props.store}
                />
                <FormPembelianSaham
                    ref={this.popupFormPembelianSahamRef}
                    store={this.props.store}
                />
                <FormPenjualanSaham
                    ref={this.popupFormPenjualanSahamRef}
                    store={this.props.store}
                />
                <FormPertimbangan
                    ref={this.popupFormPertimbanganSahamRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default OrderSaham
