import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { journalType, statusJournal } from 'dataSource/lookup';
import DetailJurnal from './detailJurnal';
import { formatDate, formatDefaultDate, formatDefaultFullDate, formatNumber, formatNumberAum, getSystemDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import { showLoading } from 'redux/actions/loading';
import { reportFile } from 'plugin/reportRequest';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class ReportJurnal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {
        startDate: getSystemDate(props.store),
        endDate: getSystemDate(props.store),
      }
    }

    this.dataGridRef = React.createRef()
    // this.modalDetailProsesIuranRef = React.createRef()
    // this.modalGenerateProsesIuranRef = React.createRef()
    this.selectedRowDatas = []
    this.dataSource = []

    this.filterItem = [
      {
        dataField: "startDate",
        label: {
          text: "Dari",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        dataField: "endDate",
        label: {
          text: "Sampai",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            this.dataGridRef.current.forceRefresh(true)
            // this.filterSubmit()
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
      {
        dataField: 'valueDate',
        caption: 'Value Date',
        dataType: 'date',
        format: 'dd-MMM-yyyy',
        sortOrder: 'asc'
      },
      {
        dataField: 'journalTypeId',
        caption: 'Tipe Jurnal',
        lookup: {
          dataSource: journalType(this.props.store),
          valueExpr: 'id',
          displayExpr: (item) => {
            return item && item.journalTypeCode + ' - ' + item.journalTypeDesc
          }, // provides display values
        },
      },
      {
        dataField: 'journalCode',
        caption: 'Kode Jurnal'
      },
      {
        dataField: 'journalDesc',
        caption: 'Deskripsi Jurnal'
      },
      {
        dataField: 'debet',
        caption: 'Total Debet',
        alignment: 'right',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00',
      },
      {
        dataField: 'credit',
        caption: 'Total Kredit',
        alignment: 'right',
        dataType: 'number',
        alignment: 'right',
        format: '#,##0.00'
      },
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  customDetailElem = (data) => {
    return (
      <DetailJurnal journalHeaderId={data.id} store={this.props.store} />
    )
  }

  loadData = async () => {
    var journalData

    if (this.state.formFilter.startDate && this.state.formFilter.endDate) {
      journalData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/getAllByValueDateBetweenAndStatus/${formatDate(this.state.formFilter.startDate)}/${formatDate(this.state.formFilter.endDate)}/P`)

      journalData = journalData.map(value => {
        value.journalTypeId = value.journalType.id

        if (value.automatic === null || value.automatic === undefined) {
          value.automatic = false
        }

        delete value.journalType

        return value
      })

      this.dataSource = journalData
      
      return journalData
    } else {
      return []
    }
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'print',
          text: 'Export PDF',
          onClick: (e) => {
            // this.print()
            if (this.selectedRowDatas.length > 0) {
              let journalCodeData = [];
              for (let value of this.selectedRowDatas) {
                let dataValue = {
                  ...value,
                  journalCode: value.journalCode
                }
                journalCodeData.push(dataValue);
              }
              this.print(journalCodeData)
            } else {
                notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
          },
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'download',
          text: 'Export Excel',
          onClick: (e) => {
            // this.exportExcel()
            if (this.selectedRowDatas.length === this.dataSource.length) {
              // this.print()
              // this.exportExcel()
              this.exportExcelAll()
            } else if (this.selectedRowDatas.length > 0) {
              let selectedData = this.selectedRowDatas;
              let dataJhId = [];
              for (let data of selectedData) {
                let jhId = data.id;
                dataJhId.push(jhId)
              }
              this.exportExcelSelected(dataJhId);
            } else {
                notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
          },
        }
      }
    )
  }

  print = async (journalCode) => {
    try {
      this.props.store.dispatch(showLoading(true))
      var data = this.dataGridRef.current.getDataSource();
      var period = formatDefaultFullDate(this.state.formFilter.startDate)
      var prevPeriod = formatDefaultFullDate(this.state.formFilter.endDate)

      var apiProduc = `products`;
      var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, apiProduc, 'GET');

      var loadAPI = `journal-headers/getAllDetailJournal/${formatDate(this.state.formFilter.startDate)}/${formatDate(this.state.formFilter.endDate)}/${data[0].status}`;
      var getJournalAllDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET');

      var dataReport = []

      if (getJournalAllDetil.length !== journalCode.length) {
        let dataJournalSelected = [];
        for (let valueI of journalCode) {
          let findJournalCode = getJournalAllDetil.find(valueJ => valueJ.journalCode === valueI.journalCode);
          dataJournalSelected.push(findJournalCode);
        }
        for (let dataHeader of dataJournalSelected) {
          for (let dataDetil of dataHeader.journalDetilInfo) {
              dataDetil.product = getProduct.find(value => value.id === dataDetil.productId).productCode            
          }
          dataReport.push(dataHeader);
        }
      } else {
        for (let dataHeader of getJournalAllDetil) {
          for (let dataDetil of dataHeader.journalDetilInfo) {
            dataDetil.product = getProduct.find(value => value.id === dataDetil.productId).productCode
          }
          dataReport.push(dataHeader)
        }
      }

      reportFile({
        template: { 'shortid': 'f6DpO3r' },
        data: {
          valueDate: data[0].valueDate,
          period: period,
          prevPeriod: prevPeriod,
          dataReportJournal: dataReport
        },
        options: {
          reportName: `Daftar Jurnal ${period} - ${prevPeriod}`
        }
      }, true, `Daftar Jurnal ${period} - ${prevPeriod}`)

      this.props.store.dispatch(showLoading(false))
    } catch (error) {
        if (error) {
          this.props.store.dispatch(showLoading(false))
          notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
  }

  exportExcel = async() => {
    let store = this.props.store
    store.dispatch(showLoading(true))

    var data = this.dataGridRef.current.getDataSource();
    var period = formatDefaultFullDate(this.state.formFilter.startDate)
    var prevPeriod = formatDefaultFullDate(this.state.formFilter.endDate)

    var dataValue = []
    for(var value of data){
      var getJournalTypeId = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-types/${value.journalTypeId}`, 'GET')
      value.journalTypeId = getJournalTypeId.journalTypeCode + ' - ' + getJournalTypeId.journalTypeDesc

      var loadAPI = `journal-detils/getAllByJournalHeaderId/${value.id}`
      var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET')

      response = response.map(value => {
        value.debet = value.dc === 'D' ? value.amount : null
        value.credit = value.dc === 'C' ? value.amount : null
        value.baseDebet = value.dc === 'D' ? value.amountBasedCurrency : null
        value.baseCredit = value.dc === 'C' ? value.amountBasedCurrency : null

        return value
      })

      for(var values of response){
        var datas = {
          valueDate: formatDefaultFullDate(value.valueDate),
          journalTypeId: value.journalTypeId,
          journalCode: value.journalCode,
          journalDesc: value.journalDesc,
          totalDebet: value.debet ? value.debet : 0,
          totalKredit: value.credit ? value.credit : 0,
          coaCode: values.coa.coaCode,
          coaName: values.coa.coaName,
          currency: values.currency.currencyCode,
          debet: values.debet ? values.debet : 0,
          credit: values.credit ? values.credit : 0
        }

        const objectValue = Object.values(datas)

        dataValue.push(objectValue)
      }

    }

    // dataValue = dataValue[0].sort((a,b)=> {
    //   if(a.valueDate < b.valueDate){
    //     return -1;
    //   }
    //   if(a.valueDate > b.valueDate){
    //     return 1;
    //   }
    //     return 0;
    // })
    

    // var apiProduc = `products`;
    // var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, apiProduc, 'GET');

    // var loadAPI = `journal-headers/getAllDetailJournal/${formatDate(this.state.formFilter.startDate)}/${formatDate(this.state.formFilter.endDate)}/${data[0].status}`;
    // var getJournalAllDetil = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET');

    // var dataReport = []

    // for (let dataHeader of getJournalAllDetil) {
    //   for (let dataDetil of dataHeader.journalDetilInfo) {
    //     dataDetil.product = getProduct.find(value => value.id == dataDetil.productId).productCode
    //   }
    //   dataReport.push(dataHeader)
    // }

    try{
        const workbook = new Excel.Workbook();
        var worksheet = workbook.addWorksheet('JURNAL REPORT');
        worksheet.addTable({
          name: 'MyTable',
          ref: 'A4',
          headerRow: true,
          totalsRow: false,
          style: {
            theme: 'TableStyleLight15',
            showFirstColumn: true,
            // border: {
            //     top: {style:'thin'},
            //     left: {style:'thin'},
            //     bottom: {style:'thin'},
            //     right: {style:'thin'}
            // }
          },
          columns: [
            {name: 'Tanggal'},
            {name: 'Tipe Jurnal'},
            {name: 'Kode Jurnal'},
            {name: 'Deskripsi Jurnal'},
            {name: 'Total Debet'},
            {name: 'Total Kredit'},
            {name: 'Kode COA'},
            {name: 'Nama COA'},
            {name: 'Mata Uang'},
            {name: 'Debet'},
            {name: 'Kredit'}
          ],
          rows: dataValue,
        });

        worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

        worksheet.getCell('A2').value = `TRANSAKSI PER ${period} - ${prevPeriod}`

        worksheet.getCell('A4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('B4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('C4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('D4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('E4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('F4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('G4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('H4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('I4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('J4').alignment = {vertical: 'middle', horizontal: 'center'};
        worksheet.getCell('K4').alignment = {vertical: 'middle', horizontal: 'center'};

        for(var i = 5; i <= 10000; i++){
            worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
        }

        var WidthColums = 20;
            
        const Data1 = worksheet.getColumn(1);
        Data1.width = WidthColums;

        const Data2 = worksheet.getColumn(2);
        Data2.width = WidthColums;

        const Data3 = worksheet.getColumn(3);
        Data3.width = WidthColums;

        const Data4 = worksheet.getColumn(4);
        Data4.width = 35;

        const Data5 = worksheet.getColumn(5);
        Data5.numFmt = '#,##0.00';
        Data5.width = WidthColums;

        const Data6 = worksheet.getColumn(6);
        Data6.numFmt = '#,##0.00';
        Data6.width = WidthColums;

        const Data7 = worksheet.getColumn(7);
        Data7.width = WidthColums;

        const Data8 = worksheet.getColumn(8);
        Data8.width = WidthColums;

        const Data9 = worksheet.getColumn(9);
        Data9.width = WidthColums;

        const Data10 = worksheet.getColumn(10);
        Data10.numFmt = '#,##0.00';
        Data10.width = WidthColums;

        const Data11 = worksheet.getColumn(11);
        Data11.numFmt = '#,##0.00';
        Data11.width = WidthColums;
        
        await workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                `Daftar Jurnal ${period} - ${prevPeriod}.xlsx`
            );
        });
    }catch(e){
        console.log(e)
        store.dispatch(showLoading(false))
    }
    store.dispatch(showLoading(false))
  }

  exportExcelAll = async() => {
    try {
      let store = this.props.store
      store.dispatch(showLoading(true))
      let period = formatDate(this.state.formFilter.startDate)
      let prevPeriod = formatDate(this.state.formFilter.endDate)
      let loadAPI = `cash-voucers/get-all-journal-dtl-by-date/${formatDate(this.state.formFilter.startDate)}/${formatDate(this.state.formFilter.endDate)}/P`;
      let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET');
      let dataValue = [];

      for (let value of response) {
        let datas = {
          value_date: value.value_date,
          journal_type: value.journal_type,
          journal_code: value.journal_code,
          journal_desc: value.journal_desc,
          total_debet: value.total_debet,
          total_credit: value.total_credit,
          coa_code: value.coa_code,
          coa_name: value.coa_name,
          currency_code: value.currency_code,
          debet: value.debet,
          credit: value.credit
        }
        const objectValue = Object.values(datas);
        dataValue.push(objectValue);
      }

      try {
        const workbook = new Excel.Workbook();
        let workSheet = workbook.addWorksheet('Daftar Jurnal');
        workSheet.addTable({
          name: 'MyTable',
          ref: 'A4',
          headerRow: true,
          totalsRow: false,
          style: {
            theme: 'TableStyleLight15',
            showFirstColumn: true,
            // border: {
            //     top: {style:'thin'},
            //     left: {style:'thin'},
            //     bottom: {style:'thin'},
            //     right: {style:'thin'}
            // }
          },
          columns: [
            {name: 'Tanggal'},
            {name: 'Tipe Jurnal'},
            {name: 'Kode Jurnal'},
            {name: 'Deskripsi Jurnal'},
            {name: 'Total Debet'},
            {name: 'Total Kredit'},
            {name: 'Kode COA'},
            {name: 'Nama COA'},
            {name: 'Mata Uang'},
            {name: 'Debet'},
            {name: 'Kredit'}
          ],
          rows: dataValue,
        })

        // Set nilai untuk cell A1 dan A2
        workSheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN';
        workSheet.getCell('A2').value = `TRANSAKSI PER ${period} - ${prevPeriod}`;

        // Set alignment untuk row 4
        const centerAlignment = { vertical: 'middle', horizontal: 'center' };
        const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
        columns.forEach(column => {
            workSheet.getCell(`${column}4`).alignment = centerAlignment;
        });

        const leftAlignment = { vertical: 'middle', horizontal: 'left' };
        const rightAlignment = { vertical: 'middle', horizontal: 'right' };
        const leftAlignedColumns = ['B', 'C', 'D', 'G', 'H'];
        const rightAlignedColumns = ['E', 'F', 'J', 'K'];
        const centerAlignedColumns = ['A', 'I']

        for (let i = 5; i <= 10000; i++) {
            leftAlignedColumns.forEach(column => {
                workSheet.getCell(`${column}${i}`).alignment = leftAlignment;
            });
            rightAlignedColumns.forEach(column => {
                workSheet.getCell(`${column}${i}`).alignment = rightAlignment;
            });
            centerAlignedColumns.forEach(column => {
              workSheet.getCell(`${column}${i}`).alignment = centerAlignment;
            })
        }

        // Set lebar dan format kolom
        const widthColumns = 20;
        const formattedColumns = [
            { index: 1, width: 15 },
            { index: 2, width: widthColumns },
            { index: 3, width: widthColumns },
            { index: 4, width: 50 },
            { index: 5, width: widthColumns, numFmt: '#,##0.00' },
            { index: 6, width: widthColumns, numFmt: '#,##0.00' },
            { index: 7, width: widthColumns },
            { index: 8, width: 40 },
            { index: 9, width: 15 },
            { index: 10, width: widthColumns, numFmt: '#,##0.00' },
            { index: 11, width: widthColumns, numFmt: '#,##0.00' }
        ];

        formattedColumns.forEach(column => {
            const col = workSheet.getColumn(column.index);
            col.width = column.width;
            if (column.numFmt) {
                col.numFmt = column.numFmt;
            }
        });

        await workbook.xlsx.writeBuffer().then(function(buffer) {
          saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `Daftar Jurnal ${period} - ${prevPeriod}.xlsx`
          );
      });
      } catch (error) {
        console.log(error)
        store.dispatch(showLoading(false))
        notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
      }
      store.dispatch(showLoading(false))
    } catch (error) {
      if (error) {
        console.log(error)
        this.props.store.dispatch(showLoading(false))
        notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
      }
    }
  }

  exportExcelSelected = async (jhId) => {
    try {
      let store = this.props.store
      store.dispatch(showLoading(true))
      let period = formatDefaultFullDate(this.state.formFilter.startDate)
      let prevPeriod = formatDefaultFullDate(this.state.formFilter.endDate)

      let loadAPI = `cash-voucers/get-all-journal-dtl-by-jh-id`;
      let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'POST', {
        values: jhId
      }); 
      let dataValue = [];

      for(let value of response) {
        let datas = {
          value_date: formatDefaultFullDate(value.value_date),
          journal_type: value.journal_type,
          journal_code: value.journal_code,
          journal_desc: value.journal_desc,
          total_debet: value.total_debet,
          total_credit: value.total_credit,
          coa_code: value.coa_code,
          coa_name: value.coa_name,
          currency_code: value.currency_code,
          debet: value.debet,
          credit: value.credit
        }
        const objectValue = Object.values(datas);
        dataValue.push(objectValue);
      }

      try {
        const workbook = new Excel.Workbook();
        let workSheet = workbook.addWorksheet('Daftar Jurnal');
        workSheet.addTable({
          name: 'MyTable',
          ref: 'A4',
          headerRow: true,
          totalsRow: false,
          style: {
            theme: 'TableStyleLight15',
            showFirstColumn: true,
            // border: {
            //     top: {style:'thin'},
            //     left: {style:'thin'},
            //     bottom: {style:'thin'},
            //     right: {style:'thin'}
            // }
          },
          columns: [
            {name: 'Tanggal'},
            {name: 'Tipe Jurnal'},
            {name: 'Kode Jurnal'},
            {name: 'Deskripsi Jurnal'},
            {name: 'Total Debet'},
            {name: 'Total Kredit'},
            {name: 'Kode COA'},
            {name: 'Nama COA'},
            {name: 'Mata Uang'},
            {name: 'Debet'},
            {name: 'Kredit'}
          ],
          rows: dataValue,
        })

        // Set nilai untuk cell A1 dan A2
        workSheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN';
        workSheet.getCell('A2').value = `TRANSAKSI PER ${period} - ${prevPeriod}`;

        // Set alignment untuk row 4
        const centerAlignment = { vertical: 'middle', horizontal: 'center' };
        const columns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'];
        columns.forEach(column => {
            workSheet.getCell(`${column}4`).alignment = centerAlignment;
        });

        const leftAlignment = { vertical: 'middle', horizontal: 'left' };
        const rightAlignment = { vertical: 'middle', horizontal: 'right' };
        const leftAlignedColumns = ['B', 'C', 'D', 'G', 'H'];
        const rightAlignedColumns = ['E', 'F', 'J', 'K'];
        const centerAlignedColumns = ['A', 'I']

        for (let i = 5; i <= 10000; i++) {
            leftAlignedColumns.forEach(column => {
                workSheet.getCell(`${column}${i}`).alignment = leftAlignment;
            });
            rightAlignedColumns.forEach(column => {
                workSheet.getCell(`${column}${i}`).alignment = rightAlignment;
            });
            centerAlignedColumns.forEach(column => {
              workSheet.getCell(`${column}${i}`).alignment = centerAlignment;
            })
        }

        // Set lebar dan format kolom
        const widthColumns = 20;
        const formattedColumns = [
          { index: 1, width: 15 },
          { index: 2, width: widthColumns },
          { index: 3, width: widthColumns },
          { index: 4, width: 50 },
          { index: 5, width: widthColumns, numFmt: '#,##0.00' },
          { index: 6, width: widthColumns, numFmt: '#,##0.00' },
          { index: 7, width: widthColumns },
          { index: 8, width: 40 },
          { index: 9, width: 15 },
          { index: 10, width: widthColumns, numFmt: '#,##0.00' },
          { index: 11, width: widthColumns, numFmt: '#,##0.00' }
        ];

        formattedColumns.forEach(column => {
            const col = workSheet.getColumn(column.index);
            col.width = column.width;
            if (column.numFmt) {
                col.numFmt = column.numFmt;
            }
        });

        await workbook.xlsx.writeBuffer().then(function(buffer) {
          saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `Daftar Jurnal ${period} - ${prevPeriod}.xlsx`
          );
      });
      } catch (error) {
        console.log(error)
        notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
      }
      store.dispatch(showLoading(false))
    } catch (error) {
      console.log(error);
      notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    }
  }

onSelectionChanged = (e) => {
  this.selectedRowDatas =  e.selectedRowsData
}

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Daftar Jurnal</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI={`journal-headers`}
          insertAPI='journal-headers'
          updateAPI='journal-headers'
          deleteAPI='journal-headers'

          useArraySource={true}
          ArraySourceData={this.loadData}

          backendserver={process.env.REACT_APP_BACKEND_ACC}

          allowEnabledMasterDetail={true}

          customDetailElem={this.customDetailElem}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={false}
          exportFileName={"Daftar Jurnal"}
          allowExportSelectedData={true}
          selection={"multiple"}

          exportWithFormatNum={true}
          exceptFieldExportFormtNum={
              [
                `debet`,
                `credit`
              ]
          }

          showBorders={true}

          paging={true}
          defaultPageSize={10}
          grouping={true}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Journal List'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan d alam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          height={'calc(100vh - 250px)'}

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          summaryTotalItem={this.sumDebitBase}
          onToolbarPreparing={this.onToolbarPreparing}

          onSelectionChanged={this.onSelectionChanged}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />

      </div>
    )
  }
}

export default ReportJurnal