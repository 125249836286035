import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatDefaultDate, formatDefaultFullDate, getSystemDate } from 'plugin/helper';
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from 'redux/actions/loading';
import { product } from 'dataSource/lookup';

class CashFlowAuto extends Component {
    constructor(props) {
        super(props)

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            tanggal: this.systemDate ? new Date(this.systemDate.dateValue) : null,
            formFilter: {
                periodDate: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                produkId: null
            }
        }

        this.dataGridRef = React.createRef()
        this.modalGeneralLedgerRef = React.createRef()

        this.filterItem = [
            {
                dataField: "periodDate",
                label: {
                    text: "Periode",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    onValueChanged: () => {
                        this.dataGridRef.current.DataGrid.deleteColumn('currentPeriod')
                        this.dataGridRef.current.DataGrid.deleteColumn('prevPeriod')
            
                        let period = formatDefaultFullDate(this.state.formFilter.periodDate).split(' ');
                        period = `${period[1]} ${period[2]}`;
                        console.log(period);
            
                        this.setState({
                          tanggal: period
                        })
                    }
                }
            },
            {
                dataField: "produkId",
                label: {
                    text: "Produk",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: product(this.props.store),
                    displayExpr: function (item) {
                        return item && item.productCode + " - " + item.productName;
                    },
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: async (e) => {
                        await this.filterSubmit()
                        this.setNewHeaderTitle()
                        this.dataGridRef.current.forceRefresh(true)

                        // let selectedDate = new Date(this.state.formFilter.periodDate);

                        // this.setState({
                        //     tanggal: selectedDate,
                        // });
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                dataField: '_urutandesc',
                caption: 'Description',
                grouping: 0,
                cellRender: (e) => {
                    if (e.row.data._level === 1 || e.row.data._level === 3 || e.row.data._level === 4 || e.row.data._level === 5 || e.row.data._level === 6) {
                        return <b>{e.value}</b>
                    } else {
                        return <span className="ml-3">{e.value}</span>
                    }
                }
            },
            {
                dataField: 'currentPeriod',
                caption: 'Periode Berjalan',
                alignment: 'right',
                columns: [
                    {
                        dataField: this.useAmount2021amt1 ? 'amount_2021' : '_amt1',
                        caption: this.headerTitle('current'),
                        format: "#,##0.00;(#,##0.00);",
                        alignment: 'right',
                        width: '150px',
                        cellRender: (e) => {
                            if (e.row.data._level === 1) {
                                return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
                            } else {
                                return <span className="d-flex justify-content-end">{e.text}</span>
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'prevPeriod',
                caption: 'Periode Sebelumnya',
                alignment: 'right',
                columns: [
                    {
                        dataField: this.useAmount2021amt2 ? 'amount_2021' : '_amt2',
                        caption: this.headerTitle('prev'),
                        format: "#,##0.00;(#,##0.00);",
                        alignment: 'right',
                        width: '150px',
                        cellRender: (e) => {
                            if (e.row.data._level === 1) {
                                return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
                            } else {
                                return <span className="d-flex justify-content-end">{e.text}</span>
                            }
                        }
                    },
                ]
            },
        ]

        this.useAmount2021amt1 = false;
        this.useAmount2021amt2 = false;
        this.dataArusKas = []
        this.stDate1 = ""
        this.stDate2 = ""
    }

    getDayMonthYear = (date) => {
        if (date) {
            let periodDate = this.state.formFilter.periodDate ? this.state.formFilter.periodDate : null;
            let selectedMonth = periodDate.getMonth();
            let selectedYear = periodDate.getFullYear();
            periodDate = formatDefaultDate(new Date(selectedYear, selectedMonth + 1, 0)).split(' ');
            periodDate = `${periodDate[1]} ${periodDate[2]}`;
            return periodDate;
        //   let period = formatDefaultFullDate(date).split(' ');
        //   period = `${period[0]} ${period[1]} ${period[2]}`;
        //   return period;
        }
        return ''; // Handle the case when date is not available
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    headerTitle = (type) => {
        var periodDate = this.state.formFilter.periodDate;
        const selectedMonth = periodDate ? periodDate.getMonth() : null
        const selectedYear = periodDate ? periodDate.getFullYear() : null
        // periodDate = formatDefaultDate(new Date(selectedYear, selectedMonth + 1, 0, 0, 0, 0, 0));
        periodDate = formatDefaultDate(new Date(periodDate));
        var beginingYearDate = formatDefaultDate(`${selectedYear - 1}-12-31`)

        if (type === 'current') {
            return periodDate
        } else {
            return beginingYearDate
        }
    }

    setNewHeaderTitle = () => {
        this.dataGridRef.current.DataGrid.deleteColumn('currentPeriod')
        this.dataGridRef.current.DataGrid.deleteColumn('prevPeriod')
        this.dataGridRef.current.DataGrid.addColumn({
            dataField: 'currentPeriod',
            caption: 'Periode Berjalan',
            alignment: 'right',
            columns: [
                {
                    dataField: this.useAmount2021amt1 ? 'amount_2021' : '_amt1',
                    caption: this.headerTitle('current'),
                    format: "#,##0.00;(#,##0.00);",
                    alignment: 'right',
                    width: '150px',
                    cellRender: (e) => {
                        if (e.row.data._level === 1) {
                            return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
                        } else {
                            return <span className="d-flex justify-content-end">{e.text}</span>
                        }
                    }
                },
            ]
        })
        this.dataGridRef.current.DataGrid.addColumn({
            dataField: 'prevPeriod',
            caption: 'Periode Sebelumnya',
            alignment: 'right',
            columns: [
                {
                    dataField: this.useAmount2021amt2 ? 'amount_2021' : '_amt2',
                    caption: this.headerTitle('prev'),
                    format: "#,##0.00;(#,##0.00);",
                    alignment: 'right',
                    width: '150px',
                    cellRender: (e) => {
                        if (e.row.data._level === 1) {
                            return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
                        } else {
                            return <span className="d-flex justify-content-end">{e.text}</span>
                        }
                    }
                },
            ]
        })
    }

    getFirstDayOfYear = (year) => {
        return new Date(year, 0, 1);
    }

    getLastDayOfYear = (year) => {
        return new Date(year, 11, 31);
    }

    subtractYears = (numOfYears, date = new Date()) => {
        date.setFullYear(date.getFullYear() - numOfYears);

        return date;
    }

    filterSubmit = async () => {

        let dataState, stDate1, stDate2, endDate2, loadAPI, response
        dataState = this.state.formFilter
        stDate1 = this.getFirstDayOfYear(new Date(dataState.periodDate).getFullYear())
        stDate2 = this.subtractYears(1, this.getFirstDayOfYear(new Date(dataState.periodDate).getFullYear()))
        endDate2 = this.getLastDayOfYear(new Date(stDate2).getFullYear())

        if ((dataState.periodDate).getFullYear() == 2021) {
            this.useAmount2021amt1 = true;
        } else {
            this.useAmount2021amt1 = false;
        }

        if ((endDate2).getFullYear() == 2021) {
            this.useAmount2021amt2 = true;
        } else {
            this.useAmount2021amt2 = false;
        }

        loadAPI = `cash-flow-configs/getByCashFlow/${formatDate(stDate1)}/${formatDate(dataState.periodDate)}/${formatDate(stDate2)}/${formatDate(endDate2)}`
        response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET')
        this.dataGridRef.current.forceRefresh(true)
        this.dataArusKas = response
    }

    loadData = () => {
        return this.dataArusKas
        // return []
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: (e) => {
                        this.print()
                    },
                }
            }
        )
    }

    print = () => {
        this.props.store.dispatch(showLoading(true))
        var dataResult = []

        var endPeriod = this.state.formFilter.periodDate, prevPeriodDate, startPeriod;
        const selectedMonth = endPeriod.getMonth()
        const selectedYear = endPeriod.getFullYear();

        prevPeriodDate = new Date(selectedYear, 0, 0);
        // endPeriod = new Date(selectedYear, selectedMonth + 1, 0);
        startPeriod = new Date(selectedYear, 0, 1);
        var data = this.dataGridRef.current.getDataSource()

        for (let result of data) {
            if (result._level === 3 || result._level === 4 || result._level === 5 || result._level === 6) {
                result._level = 1
            }

            if (this.useAmount2021amt1) {
                result._amt1 = result.amount_2021
            } else {
                result._amt1 = result._amt1
            }

            if (this.useAmount2021amt2) {
                result._amt2 = result.amount_2021
            } else {
                result._amt2 = result._amt2
            }

            dataResult.push(result)

        }
        reportFile({
            template: { 'shortid': 'BkgErRqgUo' },
            data: {
                endPeriod: formatDefaultDate(endPeriod),
                startPeriod: formatDefaultDate(startPeriod),
                prevPeriod: formatDefaultDate(prevPeriodDate),
                dataCashFlow: dataResult
            },
            options: {
                reportName: `Arus Kas ${formatDefaultFullDate(endPeriod)}`
            }
        }, true, `Arus Kas ${formatDefaultFullDate(endPeriod)}`)

        this.props.store.dispatch(showLoading(false))
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Arus Kas</h2>
                <div>
                    <Form
                        colCount={3}
                        id={'formFilter'}
                        formData={this.state.formFilter}
                        items={this.filterItem}
                    />
                </div>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='cashflow'
                    insertAPI='cashflow'
                    updateAPI='cashflow'
                    deleteAPI='cashflow'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    keyField={'_id'}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={`Arus Kas ${this.getDayMonthYear(this.state.tanggal)}`}
                    allowExportSelectedData={false}
                    selection={"none"}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            this.useAmount2021amt1 ? 'amount_2021' : '_amt1',
                            this.useAmount2021amt2 ? 'amount_2021' : '_amt2'
                        ]
                    }

                    showBorders={true}

                    paging={false}
                    showPageSizeSelector = {false}
                    defaultPageSize={10}
                    grouping={false}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Cash Flow'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={false} // set false untuk mematikan fitur filter
                    height={'calc(100vh - 250px)'}
                    sortingMode={false}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default CashFlowAuto