import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import ModalUserAccess from 'components/pages/modal/administrator/user-group/modalUserAcess';
import ModalEditGrupMenu from './modalEdit';

class UserGroup extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                  'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            // if(e.row.data.statusId !== 1){
                            //     await alert('Data ini sedang berproses!', 'Informasi')
                            // }else{                            
                            // }

                            this.showModal(e.row.data, 'edit')
                        }
                    },
                  {
                    text: 'Akses Menu',
                    hint: 'Akses Menu',
                    cssClass: 'text-success',
                    onClick: (e) => {

                        this.modalUserAccessRef.current.show()
                        this.modalUserAccessRef.current.retrieveData(e.row.data)
                    }
                  },
                ],
              },
            {
                dataField: 'groupCode',
                caption: 'Kode Grup',
                validationRules: [
                    {
                        type: 'stringLength',
                        max: 10,
                        message: 'Kode grup tidak boleh lebih dari 10 karakter'
                    },
                    {
                        type: 'custom',
                        validationCallback : (e) => {
                            var regexPattern = new RegExp(/\s/, 'g')
                            if(regexPattern.test(e.value)){
                                return false
                            }
                            return true
                        },
                        message: 'Kode grup tidak boleh memiliki spasi'
                    }
                ]
            },
            {
                dataField: 'groupName',
                caption: 'Nama Grup',
            },
        ]

        this.modalUserAccessRef = React.createRef()
        this.modalEditGrupRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    // text: 'Pengajuan Pengisian',
                    onClick: () => {
                        this.showModal({}, 'add')
                    }
                }
            },
        )
    }

    showModal = (data, type) => {
        if (type !== 'add') {
            this.modalEditGrupRef.current.retrieveData(data, type)
        } 
        this.modalEditGrupRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Grup Pengguna</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='sys-groups'
                    insertAPI='sys-groups'
                    updateAPI='sys-groups'
                    deleteAPI='sys-groups'

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"User Group"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Grup Pengguna'}
                    popupWidth={300} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalUserAccess ref={this.modalUserAccessRef} store={this.props.store}/>
                <ModalEditGrupMenu ref={this.modalEditGrupRef} store={this.props.store} forceRefresh={this.forceRefresh}/>
            </div>
        )
    }
}

export default UserGroup
