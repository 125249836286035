import React, { Component } from 'react'
import { tipeSertifikasi } from 'dataSource/lookup'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'

class ModalKaryawanSertifikasi extends Component {
    constructor(props){
        super(props)

        this.state = {
            fileUploadReceipt:[],
            popupVisible:false,
            DataSerti:{} 
        }

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: () => {
                        this.props.getDataSourceSerti(this.state.DataSerti)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.DataSerti = [
            {
                dataField:"noSertifikasi",
                label:{
                    text:"No. Sertifikasi"
                }
            },
            {
                dataField:"sertifikasiId",
                label:{
                    text:"Tipe Sertifikasi"
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: tipeSertifikasi(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'sertifikasiName', // provides display values
                },
            },
            {
                dataField:"institusi",
                label:{
                    text:"Institusi/Lembaga"
                }
            },
            {
                dataField:"tanggalSertifikasi",
                label:{
                    text:"Tanggal Sertifikasi"
                },
                editorType:"dxDateBox",
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick:true,
                    useMaskBehavior:true
                }
            },
            {
                dataField:"tanggalKadaluarsa",
                label:{
                    text:"Tanggal Kadaluarsa"
                },
                editorType:"dxDateBox",
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick:true,
                    useMaskBehavior:true
                }
            },
            {
                dataField:"desc",
                label:{
                    text:"Keterangan"
                },
            },
        ]

        this.readOnly = ''
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            DataSerti:{}
        })
    }

    retrieveData = (data, readOnly) => {
        this.readOnly = readOnly
        this.setState({
            DataSerti : data
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Data Karyawan Sertifikasi'}
                width={500}
                height={300}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formDataAkun'}
                        formData={this.state.DataSerti}
                        items={this.DataSerti}
                        cssClass="mt-3"
                        labelLocation='left'
                        readOnly={this.readOnly}
                    />
            </Popup>
        )
    }
}

export default ModalKaryawanSertifikasi
