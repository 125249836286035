import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';
import { FileUploader } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import ModalPreviewPdf from '../../../compPeserta/previewPdf';
import ModalPreviewImage from '../../../compPeserta/previewImage';

class ModalKonfirmasiTransaksiMpRetur extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            formData: {}
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        
        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        let validate = this.validate();

                        if (validate) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID Retur'
                }
            },
            {
                dataField: 'returNo',
                label: {
                    alignment: 'left',
                    text: 'No Retur'
                }
            },
            {
                // dataField: 'biayaRetur',
                dataField: 'returBiaya',
                label: {
                    alignment: 'left',
                    text: 'Biaya Retur'
                },
                validationRules : [
                    {
                        type: "required",
                        message: 'Biaya Retur Tidak Boleh Kosong'
                    }
                ],
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,         
        });
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate()
        return formValidation.isValid
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    retrieveData = async(data) => {
        var loadAPI = 'pembayaran-mp-returs'
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + data.id, 'GET')
        
        this.setState({
            formData: getData
        })
    }

    submitData = async() => {
        var data = this.state.formData
        var dataSubmit = {
            "biayaRetur": data.returBiaya,
            "dokumen": data.dokumen,
            "dokumenContentType": data.dokumenContentType,
            "idRetur": data.id,
            "noRetur": data.returNo
        }
        await this.props.submitData(dataSubmit)
        this.hide()
    }
    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            // var extension = mime.extension(this.state.formData[`${fileName}ContentType`])
            // console.log(extension)
            var src = imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`]),this.state.formData[`${fileName}Name`])
    }


    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
                
                this.setState(prevState =>({
                    formData: {
                        ...prevState.formData,
                        [`${fieldName}Name`]: files.name,
                        // [`${fieldName}Extension`]: formatNameAndExtention.extention,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))

            }
    
            fr.readAsDataURL(files)
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Retur'} 
                width={800}
                height={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formActionKonfirmasiRetur'}
                                formData={this.state.formData}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-6">
                                <FileUploader 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)} 
                                    labelText={this.state.formData.dokumenName  || 'File Dokumen'}
                                    showFileList={false}
                                    name={'dokumen'}
                                    disabled = {this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.formData.dokumen ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.formData.dokumen ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                    </div>

                </div>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        );
    }
}


export default ModalKonfirmasiTransaksiMpRetur