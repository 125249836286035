import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import httpRequest from 'plugin/httprequest'
import { Redirect } from 'react-router';
import notify from 'devextreme/ui/notify'
import { anggaranItem, anggaranYear, anggarans, coa, pensiunType, product, sdmUnit } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import { compactNumber, formatAsMoney, formatDate, formatNumber, formatNumberAum, numberWithCommas } from 'plugin/helper';
import ArrayStore from 'devextreme/data/array_store';
import Form from 'devextreme-react/form';

class RealisasiAnggaran extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            formFilter: {
                tahun: new Date(this.sysDate ? this.sysDate.dateValue : null).getFullYear(),
                produkId: 1
                // periodeDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                // status: null
            }
        }

        this.formRef = React.createRef()
        this.dataGridRef = React.createRef();
        this.dataRealisasiAnggaran = [];

        this.formMaster = [
            // {
            //     dataField: "periodeDate",
            //     label: {
            //         text: "Tahun",
            //         alignment: "left",
            //         location: "left",
            //     },
            //     editorType: "dxDateBox",
            //     editorOptions: {
            //         useMaskBehavior: true,
            //         width: '100%',
            //         displayFormat: 'dd MMM yyyy',
            //         openOnFieldClick: true
            //     }
            // },
            {
                dataField: "tahun",
                label: {
                    text: "Tahun",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: anggarans(this.props.store),
                    dataSource: [],
                    displayExpr: 'tahun',
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: "produkId",
                label: {
                    text: "Produk",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: product(this.props.store),
                    displayExpr: "productCode",
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: async (e) => {
                        await this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            // {
            //     dataField: 'no',
            //     caption: 'No',
            //     alignment: 'center',
            //     cellRender: (e) => {
            //         return e.rowIndex + 1
            //     },
            //     width: "50px"
            // },
            {
                dataField: 'anggaran_item_id',
                caption: 'Anggaran',
                width: "430px",
                lookup: {
                    dataSource: anggaranItem(this.props.store),
                    valueExpr: 'id',
                    // displayExpr: 'coaName'
                    displayExpr: function (item) {
                        return item && item.anggaranCode + " - " + item.anggaranName;
                    },
                }
            },
            {
                dataField: 'unit_id',
                caption: 'Unit',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName'
                },
                width: "80px"
            },
            {
                dataField: 'nominal_efektif',
                caption: 'Anggaran',
                format: '#,##0.00; #,##0.00',
                alignment: 'right',
                width: "200px"
            },
            {
                dataField: 'nominal_realisasi',
                caption: 'Realisasi',
                format: '#,##0.00; #,##0.00',
                alignment: 'right',
                width: "200px"
            },
            {
                dataField: 'keterangan',
                caption: 'Sisa Anggaran',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'nominal',
                        caption: 'Nominal',
                        format: '#,##0.00; #,##0.00',
                        alignment: 'right',
                        width: "200px",
                        cellRender: (e) => {
                            let data = e.row.data;

                            let sisaAnggaran = data.nominal_efektif - data.nominal_realisasi;

                            return formatAsMoney(sisaAnggaran);
                        }
                    },
                    {
                        dataField: 'sisaPersen',
                        caption: '%',
                        format: '#,##0.00; #,##0.00',
                        alignment: 'right',
                        width: "200px",
                        // cellRender: (e) => {
                        //     let data = e.row.data;
                        //     let sisaPersen = 0;

                        //     if (data.nominal_efektif === 0) {
                        //         sisaPersen = 0;
                        //     } else {
                        //         sisaPersen = (data.nominal_efektif - data.nominal_realisasi) / data.nominal_efektif * 100;
                        //     }

                        //     return formatNumberAum(sisaPersen);
                        // }  
                    },
                ]
            },

        ]
    }

    getFirstDayOfYear = (year) => {
        return new Date(year, 0, 1);
    }

    getLastDayOfYear = (year) => {
        return new Date(year, 11, 31);
    }

    subtractYears = (numOfYears, date = new Date()) => {
        date.setFullYear(date.getFullYear() - numOfYears);

        return date;
    }

    filterSubmit = async () => {
        let dataState, year, stDate1, stDate2, endDate2, loadAPI, response;
        var result = [];
        dataState = this.state.formFilter;
        let responseYear = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggarans/${dataState.tahun}`, 'GET');
        loadAPI = `anggaran/getDashboardAnggaran/${responseYear.tahun}/${dataState.produkId}`;

        response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET');

        for (let value of response) {
            // value.nominal = value.nominal_efektif - value.nominal_realisasi;
            if (value.nominal_efektif === 0) {
                value.sisaPersen = 0;
            } else if (value.nominal_efektif - value.nominal_realisasi < 0) {
                value.sisaPersen = 0;
            } else {
                value.sisaPersen = (value.nominal_efektif - value.nominal_realisasi) / value.nominal_efektif * 100;
            }
            result.push(value);
        }
        this.dataGridRef.current.forceRefresh(true);
        this.dataRealisasiAnggaran = result;
    }

    loadData = async() => {
        let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggarans?size=9999`, 'GET');
        let sortYear = response.sort((a, b) => {
            return a.tahun - b.tahun
        })

        let dataSource = new ArrayStore({
            data: sortYear,
            key: 'id'
        })

        this.formRef.current.instance.getEditor('tahun').option('dataSource', dataSource)
        return this.dataRealisasiAnggaran
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className="my-3">
                    <Form
                        colCount={4}
                        ref={this.formRef}
                        id={'formMaster'}
                        formData={this.state.formFilter}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI=''
                    insertAPI=''
                    updateAPI=''
                    deleteAPI=''

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    height={'auto'}

                    exportExcel={false}
                    exportFileName={"Pengajuan Peserta Pensiun Ditunda"}
                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={5}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Peserta Pensiun Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default RealisasiAnggaran;