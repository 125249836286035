import React, { Component } from 'react'
import {Popup,Form} from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import { programs } from 'dataSource/lookup';

class FormModalRiwayatProyek extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
            dasarPengajuan:{},
            DataDocumentSK:{},
            DataDocumentBast:{}
        }

        this.form = [
            {
                itemType:'group',
                items:[
                    {
                        dataField: "programId",
                        label: {
                            text: "Program",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: "noPengajuan",
                        label: {
                            text: "No Pengajuan",
                        },
                    },
                    {
                        dataField: "jenisProyek",
                        label: {
                            text: "Jenis Proyek",
                        },
                    },
                    {
                        dataField: "kodeProyek",
                        label: {
                            text: "Kode Proyek",
                        },
                    },
                    {
                        dataField: "namaProyek",
                        label: {
                            text: "Nama Proyek",
                        },
                    },
                    {
                        dataField: "keteranganProyek",
                        label: {
                            text: "Keterangan Proyek",
                        },
                    },
                    {
                        itemType:'group',
                        caption:'Renovasi',
                        items:[
                            {
                                dataField: "aset",
                                label: {
                                    text: "Aset",
                                },
                            },
                        ]
                    },
                    {
                        itemType:'group',
                        caption:'Asset',
                        items:[
                            {
                                dataField: "kategoriAset",
                                label: {
                                    text: "Kategori Aset",
                                },
                            },
                            {
                                dataField: "subKategoriAset",
                                label: {
                                    text: "Sub Kategori Aset",
                                },
                            },
                            {
                                dataField: "spesifikasi",
                                label: {
                                    text: "Spesofikasi",
                                },
                            },
                        ]
                    },
                    {
                        itemType:'group',
                        caption:'Biaya',
                        items:[
                            {
                                dataField: "tipeTransaksi",
                                label: {
                                    text: "Tipe Transaksi",
                                },
                            },
                            {
                                dataField: "subTipeTransaksi",
                                label: {
                                    text: "subTypeTransaksi",
                                },
                            },
                            {
                                dataField: "nilaiProyek",
                                label: {
                                    text: "Nilai Proyek",
                                },
                            },
                            {
                                dataField: "bulanAmortisasi",
                                label: {
                                    text: "Bulan Amortisasi",
                                },
                            },
                            {
                                dataField: "currency",
                                label: {
                                    text: "Currency",
                                },
                            },
                            {
                                dataField: "kurs",
                                label: {
                                    text: "Kurs",
                                },
                            },
                        ]
                    },
                    {
                        dataField: "tanggalPekerjaanMulai",
                        label: {
                            text: "Tanggal Pekerjaan Mulai",
                        },
                    },
                    {
                        dataField: "tanggalPekerjaanSelesai",
                        label: {
                            text: "Tanggal Pekerjaan Selesai",
                        },
                    },
                    {
                        dataField: "masaGaransi",
                        label: {
                            text: "Masa Garansi",
                        },
                    },

                ]
            }
        ]

        
        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.toolbarItem = [
            'background' , 'bold' , 'color' , 'italic' , 'link' , 'image' , 'strike' , 'subscript' , 'superscript' , 'underline' , 'blockquote' , 'header' 
            , 'increaseIndent' , 'decreaseIndent' , 'orderedList' , 'bulletList' , 'alignLeft' , 'alignCenter' , 'alignRight' , 'alignJustify' , 'codeBlock' 
            , 'variable' , 'separator' , 'undo' , 'redo' , 'clear'
        ]


        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster: data,
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            dasarPengajuan:{},
            popupVisible: false
        })
    } 
    
    submitData = () => {
        
    }

    updateData = () => {
        
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Riwayat Proyek'} 
                width={"70vh"}
                height={"80vh"}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formPotongan'}
                        formData={this.state.DataMaster}
                        items={this.form}
                        labelLocation = 'left'
                    />
                    <label style={{color:'#828282'}}>Dasar Pengajuan</label>
                    <HtmlEditor 
                        height="300px"
                        value={this.state.dasarPengajuan}
                        name={'content'}
                        onValueChanged={this.onValueChanged}
                        >
                        <Toolbar>
                            {    
                                this.toolbarItem.map((toolbarItem) => {
                                    return(
                                        <Item
                                            formatName = {toolbarItem}
                                        />
                                    )
                                })
                            }
                        </Toolbar>
                    </HtmlEditor>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalRiwayatProyek