import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import FormDaftarUlangKonvensional from './comp/konvensional';
import FormDaftarUlangOnline from './comp/online';
import ScrollView from 'devextreme-react/scroll-view';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import { compareObj } from 'plugin/helper';

class ShowModalPengkinianPesertaProsesDaftarUlang extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.formDataOnline = React.createRef()
        this.formDataKonvensional = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Konvensional'
            },
            {
                id: 1,
                text: 'Online'
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        // var dataMaster = this.state.dataMaster
        // var dataKonvensional = this.formDataKonvensional.current.passDataToParent()

        // try {
        //     for (var konvensional of dataKonvensional.dataKonvensional){
        //         if(konvensional.id.length >= 36){
        //             delete konvensional.id
        //             var dataPost = {
        //                 pesertaId : dataKonvensional.pesertaId,
        //                 daftarUlangId : dataMaster.id,
        //                 ...konvensional}
        //             var insertAPI = 'daftar-ulang-detils/konvensional'
        //             var resp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
        //                 values: dataPost
        //             })
        //         }
        //     }
        //     var message = 'Data berhasil di proses!'
        //     await alert(message, 'Berhasil!')
        //     this.hide()
        //     this.props.forceRefresh()
        // } catch (e) {
        //     console.log(e)
        //     await alert(e, 'Gagal!')
        // }
    }
    retrieveData = async(data) => {
        this.formDataKonvensional.current.reloadData(data.id)
        this.formDataOnline.current.reloadData(data.id)
        this.setState({
            dataMaster: data,
        })
    }
    
    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabIndexModalProses-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabIndexModalProses-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Proses Daftar Ulang'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height={'100%'} width={'100%'}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id="tabIndexModalProses-0" className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDaftarUlangKonvensional store={this.props.store} ref={this.formDataKonvensional}/>
                        </div>
                        <div id="tabIndexModalProses-1" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDaftarUlangOnline store={this.props.store} ref={this.formDataOnline}/>
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ShowModalPengkinianPesertaProsesDaftarUlang