import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, departments, fixedAssetGroups, coa, depreciationMethod } from "dataSource/lookup";

class ModalRejectCashVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {}
        };

        this.columns = [
            {
                dataField: 'rejectNotes',
                label: {
                    alignment: 'left',
                    text: 'Catatan'
                },
                editorType: 'dxTextArea',
                validationRules: [
                    {
                        type: 'required',
                        message: 'Catatan tidak boleh kosong'
                    }
                ]
            }
        ];


        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.formRef = React.createRef()
    }
    submitData = async () => {
        var data = this.state.formData
        this.props.cancel(data);
    }

    show = (data) => {
        this.setState({
            popupVisible: true,
            formData: data
        });
    };
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };


    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Tolak/Batalkan Cash Voucher"}
                    width={"20vw"}
                    height={"20vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form
                        colCount={1}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.columns}
                        ref={this.formRef}
                        scrollingEnabled={true}
                        labelLocation="left"
                    />

                </Popup>
            </React.Fragment>

        );
    }
}

export default ModalRejectCashVoucher;