import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader } from 'devextreme-react';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { imageSource, formatUploadFileData } from 'plugin/helper';
import { programs, custodies } from 'dataSource/lookup';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormEditorPemberiKerja from './formPemberiKerja';


class FormEditorProduct extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataPemberiKerja: {},
            uploadedData: []
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.datagridRef = React.createRef()
        this.modalFormRef = React.createRef()
        this.prevTabIndex = 0

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataMaster = [
            {
                dataField: 'programId',
                label: {
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true
                },
            },
            {
                dataField: 'productCode',
                label: {
                    text: 'Kode Produk'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                }
            },
            {
                dataField: 'productName',
                label: {
                    text: 'Nama Produk'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                }
            },
            {
                dataField: 'efektifDate',
                label: {
                    text: 'Tanggal Efektif'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                }
            },
            {
                dataField: 'custodyId',
                label: {
                    text: 'Bank Kustodi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'custodyName',
                    searchEnabled: true
                }
            },
            {
                dataField: 'tanggalPembayaranMpBulanan',
                label: {
                    text: 'Tanggal Pembayaran MP Bulanan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                }
            },
        ]
        this.tabDataSource = [
            // {
            //     id: 0,
            //     text: 'Pemberi Kerja',
            // },
            {
                id: 1,
                text: 'Pengaturan Investasi',
            },
        ]
        this.dataPemberiKerja = [
            {
                dataField: 'productCode',
                label: {
                    text: 'Kode Produk'
                }
            },
            {
                dataField: 'productName',
                label: {
                    text: 'Nama Produk'
                }
            },
        ]
        this.columnPemberiKerja = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalForm(data)
                        }
                    }
                ]
            },
            {
                dataField: 'participantCode',
                caption: 'Kode'
            },
            {
                dataField: 'participantName',
                caption: 'Nama Pemberi Kerja'
            },
            {
                dataField: 'alamat',
                caption: 'Alamat'
            },
            {
                dataField: 'kepesertaan',
                caption: 'KEPESERTAAN',
                columns: [
                    {
                        dataField: 'aktif',
                        caption: 'Aktif',
                    },
                    {
                        dataField: 'pasif',
                        caption: 'Pasif',
                    },
                    {
                        dataField: 'arsip',
                        caption: 'Arsip',
                    },
                ]
            }
        ]
        this.columnPenempatanInstrument = [
            {
                type: 'buttons',
                buttons:[
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                    }
                ]
            },
            {
                dataField: 'instrument',
                caption: 'Instrument',
            },
            {
                dataField: 'min',
                caption: 'Min (%)',
            },
            {
                dataField: 'max',
                caption: 'Max (%)',
            },
        ]
        this.columnPenempatanReksadana = [
            {
                type: 'buttons',
                buttons:[
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                    }
                ]
            },
            {
                dataField: 'jenisReksadana',
                caption: 'Jenis Reksadana',
            },
            {
                dataField: 'min',
                caption: 'Min (%)',
            },
            {
                dataField: 'max',
                caption: 'Max (%)',
            },
        ]
        this.columnPenempatanDeposito = [
            {
                type: 'buttons',
                buttons:[
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                    }
                ]
            },
            {
                dataField: 'issuer',
                caption: 'ISSUER',
            },
            {
                dataField: 'min',
                caption: 'Min (%)',
            },
            {
                dataField: 'max',
                caption: 'Max (%)',
            },
        ]
        this.columnPenempatanObligasiSukuk = [
            {
                type: 'buttons',
                buttons:[
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                    }
                ]
            },
            {
                dataField: 'issuer',
                caption: 'ISSUER',
            },
            {
                dataField: 'min',
                caption: 'Min (%)',
            },
            {
                dataField: 'max',
                caption: 'Max (%)',
            },
        ]
        this.columnPenempatanSektor = [
            {
                type: 'buttons',
                buttons:[
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                    }
                ]
            },
            {
                dataField: 'sektor',
                caption: 'Sektor',
            },
            {
                dataField: 'min',
                caption: 'Min (%)',
            },
            {
                dataField: 'max',
                caption: 'Max (%)',
            },
        ]
        this.columnKriteriaObligasi = [
            {
                type: 'buttons',
                buttons:[
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                    }
                ]
            },
            {
                dataField: 'kriteria',
                caption: 'Kriteria',
            },
            {
                dataField: 'min',
                caption: 'Minimum',
            },
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show(dataMaster) {

        var dataMaster = dataMaster || {
        }

        this.setState({
            popupVisible: true,
            dataMaster: dataMaster,
            // dataPemberiKerja: dataMaster
        });

    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        if (this.state.dataMaster.id) {
            httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'staticdata-wf/products', 'PUT', { values: this.state.dataMaster }, this.state.dataMaster)
        } else {
            httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'staticdata-wf/products', 'POST', { values: this.state.dataMaster })
        }

        let type = 'success'
        let text = 'Data Produk berhasil di simpan'
        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

        this.props.forceRefresh()
        this.hide()
    }
    loadData = () => {
        return []
    }
    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');
        
        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    showModalForm = (data) => {
        this.modalFormRef.current.show(data)
    }
    forceRefresh = () => {
        this.datagridRef.current.forceRefresh()
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Produk',
                    onClick: (e) => {
                        this.showModalForm()
                    },
                }
            }
        )
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'90vw'}
                    height="90vh"
                    title={`Form Data Produk`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid py-3">
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <Form
                                    colCount={2}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.tabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                        </div>
                        {/* <div id="tabindex-0" className={`col-md-12` } style={{overflowY:"auto"}}>
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataPemberiKerja'}
                                    formData={this.state.dataPemberiKerja}
                                    items={this.dataPemberiKerja}
                                    labelLocation={"left"}
                                    readOnly={true}
                                />
                            </div>
                            <DevExpressDataGrid
                                ref={this.datagridRef}

                                loadAPI='products'
                                insertAPI='products'
                                updateAPI='products'
                                deleteAPI='products' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                useArraySource = {true}
                                ArraySourceData = {this.loadData}

                                allowAdding={false}
                                allowDeleting={true}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Product"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                height = {200}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Product Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={300} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={false} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnPemberiKerja} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}
                                onToolbarPreparing={this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <FormEditorPemberiKerja ref={this.modalFormRef} store={this.props.store} forceRefresh={this.forceRefresh} />
                        </div> */}
                        <div id="tabindex-1" className={`col-md-12` } style={{height:"calc(100vh - 400px)", overflowY:"auto"}}>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Komposisi Penempatan Per Instrumen</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='products'
                                        insertAPI='products'
                                        updateAPI='products'
                                        deleteAPI='products' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadData}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Product"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Product Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnPenempatanInstrument} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Komposisi Penempatan Reksadana</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='products'
                                        insertAPI='products'
                                        updateAPI='products'
                                        deleteAPI='products' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadData}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Product"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Product Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnPenempatanReksadana} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Komposisi Penempatan Deposito</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='products'
                                        insertAPI='products'
                                        updateAPI='products'
                                        deleteAPI='products' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadData}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Product"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Product Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnPenempatanDeposito} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Komposisi Penempatan Obligasi & Sukuk</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='products'
                                        insertAPI='products'
                                        updateAPI='products'
                                        deleteAPI='products' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadData}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Product"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}
                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Product Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnPenempatanObligasiSukuk} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Komposisi Penempatan Per Sektor</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='products'
                                        insertAPI='products'
                                        updateAPI='products'
                                        deleteAPI='products' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadData}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Product"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Product Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnPenempatanSektor} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Kriteria Obligasi Swasta & BUMN</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='products'
                                        insertAPI='products'
                                        updateAPI='products'
                                        deleteAPI='products' 

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadData}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Product"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}
                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Product Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnKriteriaObligasi} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormEditorProduct