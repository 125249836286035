import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instrumentSubCategoryDeposito, orders, rekeningBank } from 'dataSource/lookup'

class DataGridDetailInstruksiPenempatan extends Component {
    constructor(props) {
        super(props)
        this.columnPenempatan = [
            {
                dataField: 'noOrder',
                caption: 'No. Order',
            },
            {
                dataField: 'subName',
                caption: 'Deposito',
            },
            {
                dataField: 'penempatan',
                caption: 'Penempatan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'amountOrder',
                        caption: 'Nominal',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'rateOrder',
                        caption: 'Rate',
                        alignment: 'right',
                    },
                    {
                        dataField: 'tenor',
                        caption: 'Tenor',
                        cellRender: (e) => {
                            var data = e.row.data
                            return data.tenor + data.tenorTypeId
                        }
                    },
                    {
                        dataField: 'rolloverAuto',
                        caption: 'Otomatis Rollover',
                    },
                    {
                        dataField: 'rolloverTypeId',
                        caption: 'Jenis Rollover',
                        lookup: {
                            dataSource: [
                                {
                                    id: 0,
                                    value: 'Pokok + Bunga'
                                },
                                {
                                    id: 1,
                                    value: 'Pokok'
                                }
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                ]
            },
            {
                dataField: 'rekSourceName',
                caption: 'Rekening Penempatan',
            },
            {
                dataField: 'rekBankName',
                caption: 'Rekening Pencairan (Pokok/Bunga)',
            },
        ]
    }
    dataSource = () => {
        return this.props.data
    }
    render() {
        return (
            <div className="col-md-12" id="Penempatan">
                <h6 className='text-center'>Detail Instruksi (Penempatan)</h6>
                <DevExpressDataGrid
                    keyField="id"
                    loadAPI='detail-instruksi'
                    insertAPI='detail-instruksi'
                    updateAPI='detail-instruksi'
                    deleteAPI='detail-instruksi'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.dataSource}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Detail Instruksi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Detail Instruksi Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    height={225}

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columnPenempatan} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default DataGridDetailInstruksiPenempatan