import React, { Component } from 'react'
import {Popup,Form} from 'devextreme-react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import uuidv4  from 'uuid/v4'
import { currency, grade, jabatan, karyawan, tipePotongan, tunjanganHrd } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import FormModalPenyesuaianTunjangan from './formTunjangan';
import FormModalPenyesuaianPotongan from './formPotongan';
import notify from "devextreme/ui/notify";

class FormGajiKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                currencyId:1001,
                gajiPokok:0,
                totalIuranPensiun:0,
                totalTunjanganTetap:0
            }
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.showModalSetNominalPotonganRef = React.createRef()
        this.showModalSetNominalTunjanganRef = React.createRef()
        this.showModalEditPotonganRef = React.createRef()
        this.showModalEditTunjanganRef = React.createRef()
        this.dataGridPotRef = React.createRef()
        this.dataGridTunRef = React.createRef()
        this.prevTabIndex = 0
        this.tunjanganId = ''
        this.potonganId = ''
        this.dataTunjangan  = []
        this.dataPotongan  = []

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.columnsTunjangan = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Set Nominal',
                        hint: 'Set Nominal',
                        onClick: (e) => {
                            if (e.row.data.dasarNilai == 'Nominal') {
                                this.showModalSetNominalTunjangan(e)
                            }else{
                                notify({ message: 'Tidak Dapat di Set Nominal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        }
                    },
                ]
            },
            {
                dataField:"idsTunjanganType",
                caption:"Tunjangan ID", 
            },
            {
                dataField: 'groupName',
                caption: 'Group',
            },
            {
                dataField: 'tunjanganName',
                caption: 'Tunjangan',
            },
            {
                dataField: 'dasarNilai',
                caption: 'Dasar Nilai',
            },
            {
                dataField: 'persen',
                caption: 'Persen (%)',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'nilaiTunjangan',
                caption: 'Nominal',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'tunjanganTetap',
                caption: 'Tunjangan Tetap',
            },
        ]

        this.columnsPotongan = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Set Nominal',
                        hint: 'Set Nominal',
                        onClick: (e) => {
                            if (e.row.data.dasarNilai == 'Nominal') {
                                this.showModalSetNominalPotongan(e)
                            }else{
                                notify({ message: 'Tidak Dapat di Set Nominal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                            // this.showModalEdit(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField:"ids",
                caption:"Potongan ID",
            },
            {
                dataField: 'potonganTypeId',
                caption: 'Tipe Potongan',
                lookup: {
                    dataSource: tipePotongan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'potonganCode'
                }
            },
            {
                dataField: 'groupName',
                caption: 'Group',
            },
            {
                dataField:"potonganName",
                caption:"Potongan", 
            },
            {
                dataField: 'dasarNilai',
                caption: 'Dasar Nilai',
            },
            {
                dataField: 'persen',
                caption: 'Persen (%)',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'nilaiPotongan',
                caption: 'Nominal',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
            {
                dataField: 'potonganAmount',
                caption: 'Potongan Amount',
                dataType:'number',
                format:'#,##0.00',
                alignment:'right'
            },
        ]
        
        this.formGaji = [
            {
                itemType: 'group',
                name:'group',
                items: [
                    {
                        dataField: 'karyawanId',
                        label: {
                            text: 'Karyawan',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            readOnly:this.props.action == 'add' ? false : true,
                            dataSource: this.props.action == 'add' ? [] : karyawan(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: (item) => {
                                return item && item.nik + ' - ' + item.fullName
                            }, // provides display values
                            onSelectionChanged:(e) => {
                                console.log(this.props.action);
                                if(e.selectedItem){
                                    var data = {
                                        nik : e.selectedItem.nik,
                                        karyawanName:e.selectedItem.fullName,
                                        jabatanId : e.selectedItem.jabatanId,
                                        gradeId : e.selectedItem.gradeId,
                                        nominalGajiPokok : e.selectedItem.gajiPokok,
                                        totalIuranPensiun : e.selectedItem.iuranPensiun,
                                        totalTunjanganTetap : e.selectedItem.gajiPokokTunjanganTetap,
                                        gajiBruto : e.selectedItem.gajiBruto,
                                        gajiBrutoPajak : e.selectedItem.gajiBrutoPajak,
                                        pphTetap : e.selectedItem.pphTetap,
                                        ptkpNominal : e.selectedItem.ptkpNominal,
                                        statusKepegawaianId:e.selectedItem.statusKepegawaianId,
                                        currencyId : e.selectedItem.gajiCurrencyId,
                                    }
                                    
                                    this.formRef.current.instance.updateData(data)
                                }
                            },
                            onValueChanged: async(e) => {
                                if(e.value){
                                    var getKaryawanSks = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-sks/getAllByKaryawanId/${e.value}`, 'GET')
                                    // getKaryawanSks = getKaryawanSks.filter(value => value.skTypeId == 1)
                                    
                                    
                                    this.formRef.current.instance.getEditor('skId').option('dataSource', getKaryawanSks)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Karyawan tidak boleh kosong'
                            },
                        ]
                    },
                    {
                        dataField: 'karyawanName',
                        label: {
                            text: 'karyawanName',
                            visible: false,
                        },
                        editorOptions:{
                            visible: false,
                        }
                    },
                    {
                        dataField: 'statusKepegawaianId',
                        label: {
                            text: 'statusKepegawaianId',
                            visible: false,
                        },
                        editorOptions:{
                            visible: false,
                        }
                    },
                    {
                        dataField: 'nik',
                        label: {
                            text: 'NIK'
                        },
                        editorOptions:{
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'jabatanId',
                        label: {
                            text: 'Jabatan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            placeholder: '',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'gradeId',
                        label: {
                            text: 'Grade'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: grade(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'gradeCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            placeholder: '',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'skId',
                        label: {
                            text: 'No. SK'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'skNo',
                            onSelectionChanged: async(e) => {
                                if(e.selectedItem){
                                    this.formRef.current.instance.updateData({skTgl:e.selectedItem.skDate, tglBerlaku:e.selectedItem.efektifDate})
                                }
                            }
                        }
                    },
                    {
                        dataField: 'skTgl',
                        label: {
                            text: 'Tanggal SK'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tglBerlaku',
                        label: {
                            text: 'Tanggal Berlaku'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:'group',
                items: [
                    {
                        dataField: 'nominalGajiPokok',
                        label: {
                            text: 'Nominal Gaji Pokok'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Gaji pokok tidak boleh kosong'
                            },
                            {
                                type:'range',
                                min:1,
                                message: 'Gaji pokok tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'totalIuranPensiun',
                        label: {
                            text: 'Total Iuran Pensiun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Total Iuran Pensiun tidak boleh kosong'
                            },
                            {
                                type:'range',
                                min:0.00,
                                message: 'Total Iuran Pensiun tidak boleh kosong'
                            }
                        ]
                    },
                    // {
                    //     dataField: 'intensif',
                    //     label: {
                    //         text: 'Intensif'
                    //     },
                    //     // editorType: 'dxNumberBox',
                    //     // editorOptions: {
                    //     //     format: '#,##0.00'
                    //     // }
                    // },
                    {
                        dataField: 'totalTunjanganTetap',
                        label: {
                            text: 'Total Tunjangan Tetap'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                        // validationRules: [
                        //     {
                        //         type: 'required',
                        //         message: 'Total Tunjangan Tetap tidak boleh kosong'
                        //     },
                        // ]
                    },
                    {
                        dataField: 'gajiBruto',
                        label: {
                            text: 'Gaji Bruto'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'gajiBrutoPajak',
                        label: {
                            text: 'Gaji Bruto Pajak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'ptkpNominal',
                        label: {
                            text: 'PTKP Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pphTetap',
                        label: {
                            text: 'PPH Tetap'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Currency'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            readOnly: true
                        }
                    }
                ]
            },
            
            
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Daftar Tunjangan'
            },
            {
                id: 1,
                text: 'Daftar Potongan'
            },
        ]
    } 

    show() {
        this.prevTabIndex = 0
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.dataPotongan  = []
        this.dataTunjangan = []
        this.dataGridTunRef.current.forceRefresh(true)
        this.dataGridPotRef.current.forceRefresh(true)
        this.setState({
            popupVisible: false,
            dataMaster: {
                currencyId:1001,
                gajiPokok:0,
                totalIuranPensiun:0,
                totalTunjanganTetap:0
            },
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value
        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`${this.props.action}tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`${this.props.action}tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onToolbarPreparingTunj = (e) => {
        e.toolbarOptions.items.unshift(
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'refresh',
            //         text:'Penyesuaian',
            //         onClick: (e) => {
            //             var formValidation = this.validate()
            //             if (formValidation) {
            //                 this.penyesuaianTunjangan()
            //             }
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    text:'Load Tunjangan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.loadTunjangan(e)
                        }
                    },
                }
            },
        )
    }

    onToolbarPreparingPot = (e) => {
        e.toolbarOptions.items.unshift(
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'refresh',
            //         text:'Penyesuaian',
            //         onClick: (e) => {
            //             // this.showModalSetNominalPotongan(e)
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    text:'Load Potongan',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.loadPotongan(e)
                        }
                    },
                }
            }
        )
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async() => {
        if (this.props.action == 'add') {
            this.addDataListTunjanganDanPotongan()
        }

        if (this.props.action == 'edit') {
            this.editDataListTunjanganDanPotongan()
        }
    }

    addDataListTunjanganDanPotongan = async () => {
        try {
            var loadAPI = `send-tunjangan-and-potongan`
            var data = {...this.state.dataMaster}
            var dataListTunjangan = this.dataGridTunRef.current.getDataSource()
            var dataListPotongan = this.dataGridPotRef.current.getDataSource()
            var listTunjangan = []
            var listPotongan = []
            
            for(var listDataTunj of dataListTunjangan){
                listDataTunj.karyawanId = data.karyawanId
                listDataTunj.nominal = listDataTunj.nilaiTunjangan
                listDataTunj.tunjanganTypeId = listDataTunj.idsTunjanganType
                listTunjangan.push(listDataTunj)
            }

            for(var listDataPot of dataListPotongan){
                listDataPot.karyawanId = data.karyawanId
                listDataPot.nominal = listDataPot.nilaiPotongan
                listDataPot.potonganTypeId = listDataPot.ids
                listPotongan.push(listDataPot)
            }

            data.totalIuran = data.totalIuranPensiun
            data.tunjanganDetailList = listTunjangan
            data.potonganDetailList = listPotongan
            data.totalTunjanganTetap = 0
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
                values: data,
            })

            notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            this.hide()
            this.props.forceRefresh()
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    editDataListTunjanganDanPotongan = async () => {
        try {
            var loadAPI = `edit-tunjangan-and-potongan`
            var data = {...this.state.dataMaster}
            var dataListTunjangan = this.dataGridTunRef.current.getDataSource()
            var dataListPotongan = this.dataGridPotRef.current.getDataSource()
            var listTunjangan = []
            var listPotongan = []
            
            for(var listDataTunj of dataListTunjangan){
                listDataTunj.karyawanId = data.karyawanId
                listDataTunj.nominal = listDataTunj.nilaiTunjangan
                listDataTunj.tunjanganTypeId = listDataTunj.idsTunjanganType
                listTunjangan.push(listDataTunj)
            }

            for(var listDataPot of dataListPotongan){
                listDataPot.karyawanId = data.karyawanId
                listDataPot.nominal = listDataPot.nilaiPotongan
                listDataPot.potonganTypeId = listDataPot.ids
                listPotongan.push(listDataPot)
            }

            data.totalIuran = data.totalIuranPensiun
            data.tunjanganDetailList = listTunjangan
            data.potonganDetailList = listPotongan
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
                values: data,
            })

            this.hide()
            this.props.forceRefresh()
            notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    retrieveData = async(data) => {
        var DataMaster = {...this.state.dataMaster}
        var getKarById = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${data.karyawanId}`, 'GET')
        
        
        var postAPITun = `list-tunjangans/getPenyesuaianTunjanganByKarywanIdAndGajiPokokAndTunjanganTetapAndStatusKepegawaianId/${data.karyawanId}/${data.gajiPokok}/${getKarById.gajiPokokTunjanganTetap || 0}/${getKarById.statusKepegawaianId}`
        var getListTunjangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPITun, 'GET')
        this.dataTunjangan = getListTunjangan
        
        // let sum = 0;
        // let formula = 0;

        // for (let value of this.dataTunjangan) {
        //     if (value.karyawanId === data.karyawanId) {
        //         sum += value.gajiPokok;
        //     }  
        // }

        // if (sum > 12000000) {
        //     formula = 12000000 * 4 / 100
        // } else {

        // }

        var postAPIPot = `list-potongan/getPenyesuaianPotonganByKaryawanIdAndstatusKepegawaianId/${data.karyawanId}/${getKarById.statusKepegawaianId}`
        var getListPotongan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPIPot, 'GET')
        this.dataPotongan = getListPotongan

        DataMaster.skTgl = data.skDate
        DataMaster.tglBerlaku = data.efektifDate

        this.setState({
            dataMaster: data
        })
        
        this.dataGridPotRef.current.forceRefresh(true)
        this.dataGridTunRef.current.forceRefresh(true)
    }

    getKaryawan = async() => {
        var getKar = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans?size=9999`, 'GET')
        // var filterKar = getKar.filter(value => value.gajiPokokTunjanganTetap === null || value.gajiPokokTunjanganTetap === 0)

        this.formRef.current.instance.getEditor('karyawanId').option('dataSource',getKar)
    }

    // * DataGrid Tunjangan
    // *
    // *
    // *

    showModalSetNominalTunjangan = (e) => {
        if (e.row) {
            this.showModalSetNominalTunjanganRef.current.retrieveData(e.row.data, this.state.dataMaster)
        }
        this.showModalSetNominalTunjanganRef.current.show()
    }

    showModalEditTunjangan = (e) => {
        if (e.row.data) {
            this.showModalEditTunjanganRef.current.retrieveData(e.row.data)
        }
        this.showModalEditTunjanganRef.current.show()
    }

    loadTunjangan = async(e) => {
        var DataMaster = this.state.dataMaster
        
        var postAPI = `list-tunjangans/getPenyesuaianTunjanganByKarywanIdAndGajiPokokAndTunjanganTetapAndStatusKepegawaianId/${DataMaster.karyawanId}/${DataMaster.nominalGajiPokok}/${DataMaster.totalTunjanganTetap || 0}/${DataMaster.statusKepegawaianId}`
        var getListTunjangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'GET')
        this.dataTunjangan = getListTunjangan

        this.dataGridTunRef.current.forceRefresh(true)
    }

    // penyesuaianTunjangan = async () => {
    //     var postAPI = `list-tunjangans/getPenyesuaianTunjanganByKarywanIdAndGajiPokokAndTunjanganTetap/${this.state.dataMaster.karyawanId}/${this.state.dataMaster.nominalGajiPokok}/${this.state.dataMaster.totalTunjanganTetap}`
    //     var getListTunjangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'GET')
    //     this.dataTunjangan = getListTunjangan

    //     this.dataGridTunRef.current.forceRefresh(true)
    // }
    
    loadDummyDataTun = () => {
        return this.dataTunjangan
    }

    getDataSourceTunj = (data) => {
        try{
            var key = data.idsTunjanganType

            if(key){
                this.dataGridTunRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.dataGridTunRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    // ***********************

    // * DataGrid Potongan
    // *
    // *
    // *
    // *

    showModalSetNominalPotongan = (e) => {
        if (e.row) {
            this.showModalSetNominalPotonganRef.current.retrieveData(e.row.data, this.state.dataMaster)
        }
        this.showModalSetNominalPotonganRef.current.show()
    }

    showModalEditPotongan = (e) => {
        if (e.row.data) {
            this.showModalEditPotonganRef.current.retrieveData(e.row.data)
        }
        this.showModalEditPotonganRef.current.show()
    }

    loadPotongan = async () => {
        var DataMaster = this.state.dataMaster
         
        var postAPI = `list-potongan/getPenyesuaianPotonganByKaryawanIdAndstatusKepegawaianId/${DataMaster.karyawanId}/${DataMaster.statusKepegawaianId}`
        var getListPotongan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'GET')
        this.dataPotongan = getListPotongan

        this.dataGridPotRef.current.forceRefresh(true)
    }

    loadDummyDataPot = () => {
        return this.dataPotongan
    }

    getDataSourcePot = (data) => {
        try{
            var key = data.ids

            if(key){
                this.dataGridPotRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Potongan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.dataGridPotRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Potongan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }
    // ****************

    render() {
        return (
            <React.Fragment> 
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={"Form Tunjangan Potongan Karyawan"}
                width={'70vw'}
                height="95vh"
                toolbarItems={this.props.action == 'add' ? this.PopupToolbarItem :  this.PopupToolbarItem2}
                >
                    <Form
                        ref={this.formRef}
                        colCount={2}
                        id={'formDataGaji'}
                        formData={this.state.dataMaster}
                        items={this.formGaji}
                        labelLocation = 'left'
                    />
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                    <div className="container-fluid">
                    <div id={`${this.props.action}tabindex-0`} className={' col-md-12'}>
                        <div className="row">
                            <div className="col-md-12">
                                <DevExpressDataGrid
                                    ref = {this.dataGridTunRef}
                                    loadAPI='daftar-pinjaman-karyawans'
                                    insertAPI='daftar-pinjaman-karyawans'
                                    updateAPI='daftar-pinjaman-karyawans'
                                    deleteAPI='daftar-pinjaman-karyawans' 

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                                    useArraySource={true}
                                    ArraySourceData={this.loadDummyDataTun}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={true}
                                    exportFileName={"Daftar Tunjangan Karyawan"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}
                                    
                                    showBorders={true}
                                    
                                    keyField={'idsTunjanganType'}

                                    paging={false}
                                    defaultPageSize={10}
                                    height={'calc(100vh - 470px)'}
                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Daftar Tunjangan Karyawan'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnsTunjangan} // taruh konfigurasi kolom disini
                                    onToolbarPreparing = {this.onToolbarPreparingTunj}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <FormModalPenyesuaianTunjangan
                                    ref={this.showModalSetNominalTunjanganRef}
                                    store={this.props.store}
                                    getDataSourceTunj = {this.getDataSourceTunj}
                                />
                                {/* <FormModalPenyesuaianTunjangan
                                    ref={this.showModalEditTunjanganRef}
                                    store={this.props.store}
                                    type={'edit'}
                                /> */}
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="container-fluid">
                    <div id={`${this.props.action}tabindex-1`} className={'d-none col-md-12'}>
                        <div className="row">
                            <div className="col-md-12">
                            <DevExpressDataGrid
                                    ref = {this.dataGridPotRef}
                                    loadAPI='daftar-pinjaman-karyawans'
                                    insertAPI='daftar-pinjaman-karyawans'
                                    updateAPI='daftar-pinjaman-karyawans'
                                    deleteAPI='daftar-pinjaman-karyawans' 

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                                    useArraySource={true}
                                    ArraySourceData={this.loadDummyDataPot}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={true}
                                    exportFileName={"Daftar Potongan Karyawan"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}
                                    
                                    showBorders={true}
                                    
                                    keyField={'ids'}
                                    height={'calc(100vh - 470px)'}
                                    paging={false}
                                    defaultPageSize={10}
                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Daftar Tunjangan Karyawan'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnsPotongan} // taruh konfigurasi kolom disini
                                    onToolbarPreparing = {this.onToolbarPreparingPot}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <FormModalPenyesuaianPotongan
                                    ref={this.showModalSetNominalPotonganRef}
                                    store={this.props.store}
                                    getDataSourcePot={this.getDataSourcePot}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                    {/* <div className='my-3'>
                        
                    </div> */}
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormGajiKaryawan