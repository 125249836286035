import React, { Component } from 'react'
import {FileUploader,Popup,Form} from 'devextreme-react'
import { SKType, tipeDokumen } from 'dataSource/lookup'
import { download, formatUploadFileData, imageSource } from 'plugin/helper'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage'

class ModalDokKaryawan extends Component {
    constructor(props){
        super(props)

        this.state = {
            fileUploadReceipt:[],
            popupVisible:false,
            DataDok:{}
        }

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: () => {
                        this.props.getDataSourceDok(this.state.DataDok)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.PopupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.DataDok = [
            {
                dataField:"dokumenNo",
                label:{
                    text:"No. Dokumen"
                }
            },
            {
                dataField:"dokumenTypeId",
                label:{
                    text:"Tipe Dokumen"
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: tipeDokumen(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dokumenName', // provides display values
                },
            },
            {
                dataField:"dokumenDate",
                label:{
                    text:"Tanggal Dokumen"
                },
                editorType:"dxDateBox",
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick:true,
                    useMaskBehavior:true
                }
            },
            {
                dataField:"keterangan",
                label:{
                    text:"Keterangan"
                }
            },
        ]

        this.readOnly = false
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            DataDok:{}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataDok = { ...this.state.DataDok };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataDok[`${fieldName}Name`] = files.name;
            DataDok[fieldName] = formattedFileData.base64data;
            DataDok[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataDok: DataDok
            });
        };

        fr.readAsDataURL(files);
        }
    }

    retrieveData = (data, readOnly) => {
        console.log(readOnly);
        this.readOnly = readOnly
        this.setState({
            DataDok : data
        })
    }

    previewFile = async(state, fileName) => {
            var src = imageSource(state[`${fileName}ContentType`],state[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataDok[`${fileName}ContentType`],this.state.DataDok[`${fileName}`]),this.state.DataDok[`${fileName}Name`])
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Data Dokumen Karyawan'}
                width={500}
                height={500}

                ref={this.PopupRef}
                readOnly={this.readOnly}
                toolbarItems = {this.readOnly ? this.PopupToolbarItem2 : this.PopupToolbarItem}
            >
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formDataAkun'}
                        formData={this.state.DataDok}
                        items={this.DataDok}
                        cssClass="mt-3"
                        labelLocation='left'
                        readOnly={this.readOnly}
                    />
                    <FileUploader
                        accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)} 
                        labelText="Upload File Disini"
                        showFileList={false}
                        name={'file'}
                        value = {this.state.fileUploadReceipt}
                        ref={this.fileUploderRef}
                        disabled={this.readOnly}
                    />
                    
                    <div className="row">
                        <label className="col-4">File Name:</label>
                        <label className="col-8">{ this.state.DataDok.fileName}</label>
                    </div>
                    <div className="col-md-6 text-right">
                        <button onClick={() => this.previewFile(this.state.DataDok,'file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDok.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                            Preview
                        </button>
                        <button onClick={() => this.downloadFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDok.file ? '' : 'd-none'}`} style={{ width: '122px' }}>
                            Download
                        </button>
                    </div>
                    <ModalPreviewPdf
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                    <ModalPreviewImage
                        ref={this.modalPreviewImageRef}
                        store={this.props.store}
                    />
            </Popup>
        )
    }
}

export default ModalDokKaryawan