import React, { Component } from 'react'
import { currency, banks, instruments, orders, instrumentsCategorySaham, product, productDefault } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormPengajuanPenempatanSaham from 'components/pages/modal/investasi/pengajuan/saham/penempatan/index'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";
import { confirmAlert } from 'react-confirm-alert'
import reportRequest from 'plugin/reportRequest'
import { formatDefaultFullDate, formatNumber, stripHtml, formatNumberAum, PrintElem, inputToTaskInvestasi } from 'plugin/helper'
import DetailPenempatanPenyertaanModal from './detail'
import FormPengajuanPenempatanPl from 'components/pages/modal/investasi/pengajuan/penyertaan-modal/penempatan/index'
import { reportFile } from 'plugin/reportRequest'

class PengajuanPenempatanPl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridOrderPlRef = React.createRef()
        this.formPengajuanPenempatanPlRef = React.createRef()
        this.formPengajuanPenempatanPlEditRef = React.createRef()

        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: async (e) => {
                            var data = e.row.data
                            if (data.noPengajuan == null) {
                                this.showModalEdit(data, 'edit')
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        },
                    },
                    {
                        text: 'Cetak',
                        hint: 'Cetak',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.noPengajuan == null) {
                                confirmAlert({
                                    title: 'Belum terkirim!',
                                    message: 'Data belum terkirim tidak bisa cetak surat!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakSurat(data)
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.noPengajuan == null) {
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.noPengajuan == null) {
                                confirmAlert({
                                    title: 'Batalkan draft',
                                    message: 'Apakah anda yakin ingin membatalkan draft ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'noPengajuan',
                caption: 'No Pengajuan',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.noPengajuan == null) {
                        return 'DRAFT'
                    } else {
                        return data.noPengajuan
                    }
                }
            },
            {
                dataField: 'tglPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tglOrder',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'categoryName',
                caption: 'Kategori',
            },
            {
                dataField: 'jmlBroker',
                caption: 'Jumlah Broker',
                alignment: 'right'
            },
            {
                dataField: 'jmlSaham',
                caption: 'Jumlah Penyertaan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiPenempatan',
                caption: 'Milai Penempatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'DRAFT',
                            value: 'Tersimpan'
                        },
                        {
                            id: 'INPROGRESS',
                            value: 'Dalam proses persetujuan'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.currentData = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Penempatan',
                    onClick: (e) => {
                        this.showModalInsert('add')
                    },
                }
            }
        )
    }
    kirimData = async (data) => {
        var loadAPI = `order-requests-pl-dtls/${data.orderRequestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var kajian = response.orderRequestDTO.kajian
        var content = response.orderRequestDTO.kajianContentType
        var dataDetail = []
        data = {
            productId: response.orderRequestDTO.productId,
            fundManagerId: response.orderRequestDTO.fundManagerId,
            tanggalOrder: response.orderRequestDTO.orderDate,
            tanggalPengajuan: response.orderRequestDTO.requestDate,
            requestOrderId: data.orderRequestId,
            kajian: kajian,
            kajianContentType: content,
            categoryId: response.orderRequestDTO.categoryId,
            draft: false,
        }
        for (var pl of response.orderPlInfos) {
            var plInfo = {
                id: pl.orderPlDTO.id,
                biayaPenyertaan: pl.orderPlDTO.biaya,
                jmlSahamPenyertaan: pl.orderPlDTO.quantityOrder,
                kodeInstrument: pl.instrumentDTO.instrumentCode,
                namaPerusahaan: pl.instrumentDTO.instrumentName,
                nilaiPerLembarSaham: pl.instrumentDTO.price,
                nominalPenyertaan: pl.orderPlDTO.nominalOrder,
                // orderRequestId: data.orderRequestId,
                totalLembarSaham: pl.instrumentDTO.unit,
                rekBankId: 1,
                subCategory: pl.instrumentDTO.categorySubId,
            }
            dataDetail.push(plInfo)
        }
        var submitData = {
            ...data,
            instrumentPlList: dataDetail
        }

        try {
            let orderRequestId = response.orderPlInfos[0].orderDTO.orderRequestId
            let orderTypeId = response.orderPlInfos[0].orderDTO.orderTypeId

            await inputToTaskInvestasi(this.props.store, orderRequestId, orderTypeId)
        } catch (error) {
            console.log(error);
        }
        // return false
        if (submitData) {
            try {
                var insertAPI = 'order-pls/pengajuan-penempatan-pl'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Pengiriman berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    cetakSurat = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-pl-dtls/${data.orderRequestId}`, 'GET')
        var dirKeuangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/6`, 'GET')
        var dirOperasional = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/79506`, 'GET')
        var dirut = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5`, 'GET')
        var komite = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5351`, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = stripHtml(atob(response.orderRequestDTO.kajian)) == 'undefined' ? ' ' : stripHtml(atob(response.orderRequestDTO.kajian))
        let instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${response.orderPlInfos[0].orderDTO.instrumentId}`, 'GET')
        let transaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-transaction-types/${response.orderPlInfos[0].orderDTO.orderTypeId}`, 'GET')


        if (dirKeuangan.length > 0) {
            dirKeuangan = dirKeuangan[0]
        } else {
            dirKeuangan = {}
        }

        if (dirOperasional.length > 0) {
            dirOperasional = dirOperasional[0]
        } else {
            dirOperasional = {}
        }

        if (dirut.length > 0) {
            dirut = dirut[0]
        } else {
            dirut = {}
        }

        if (komite.length > 0) {
            komite = komite[0]
        } else {
            komite = {}
        }

        var data = {
            template: {
                'shortid': 't4s3ihD',
                'recipe': 'chrome-pdf'
            },
            data: {
                "requestNo": dataMaster.requestNo,
                "orderDate": formatDefaultFullDate(dataMaster.requestDate),
                "instrumentName": instrument.instrumentName,
                "transaction": transaction.transactionName,
                "kajian": kajian,
                "dirKeuangan": {
                    "fullName": dirKeuangan.fullName,
                    "jabatan": 'Direktur Keuangan'
                },
                "dirOperasional": {
                    "fullName": dirOperasional.fullName,
                    "jabatan": 'Direktur Operasional'
                },
                "dirut": {
                    "fullName": dirut.fullName,
                    "jabatan": 'Direktur Utama'
                },
                "dirInvest": {
                    "fullName": komite.fullName,
                    "jabatan": 'Direktur Investasi'
                }
            },
            options: {
                reportName: "UPTI-" + data.requestNo
            }
        }
        reportFile(data, true, `UPTI-${dataMaster.requestNo}`)
        // var print = await reportRequest(this.props.store, data)
        // PrintElem(print.data)
        // return print
    }
    showModalEdit = async (data, type) => {
        var loadAPI = `order-requests-pl-dtls/${data.orderRequestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var kajian = atob(response.orderRequestDTO.kajian)
        var dataDetail = []
        data = {
            productId: response.orderRequestDTO.productId,
            fundManagerId: response.orderRequestDTO.fundManagerId,
            tanggalOrder: response.orderRequestDTO.orderDate,
            tanggalPengajuan: response.orderRequestDTO.requestDate,
            orderTypeId: response.orderRequestDTO.orderTypeId,
            requestId: data.orderRequestId,
            noPengajuan: '<AUTO>',
            actionType: 'edit',
            categoryId: response.orderRequestDTO.categoryId,
        }
        for (var pl of response.orderPlInfos) {
            var plInfo = {
                id: pl.orderPlDTO.id,
                biayaPenyertaan: pl.orderPlDTO.biaya,
                jmlSahamPenyertaan: pl.orderPlDTO.quantityOrder,
                kodeInstrument: pl.instrumentDTO.instrumentCode,
                namaPerusahaan: pl.instrumentDTO.instrumentName,
                nilaiPerLembarSaham: pl.instrumentDTO.price,
                nilaiPerusahaan: pl.instrumentDTO.bookValue,
                noPengajuan: '<AUTO>',
                nominalPenyertaan: pl.orderPlDTO.nominalOrder,
                // orderRequestId: data.orderRequestId,
                prosentasePenyertaan: pl.orderPlDTO.persentaseOrder,
                rekBankId: 1,
                subCategory: pl.instrumentDTO.categorySubId,
                totalLembarSaham: pl.instrumentDTO.unit,
            }
            dataDetail.push(plInfo)
        }
        if (type === 'edit') {
            this.formPengajuanPenempatanPlEditRef.current.show()
            this.formPengajuanPenempatanPlEditRef.current.retrieveData(data, dataDetail, kajian)   
        }
    }
    cancelData = async (data) => {
        var reqId = data.requestId
        if (reqId) {
            try {
                var insertAPI = `order-request-batal?orderRequestId=${reqId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: {}
                })
                notify({ message: 'Pembatalan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    showModalInsert = (type) => {
        if (type === 'add') {
            this.formPengajuanPenempatanPlRef.current.show()
            this.formPengajuanPenempatanPlRef.current.retrieveData()
        }
    }
    forceRefresh = () => {
        this.DataGridOrderPlRef.current.forceRefresh(true)
    }

    customDetailElem = (data) => {
        return (
            <DetailPenempatanPenyertaanModal detailData={data.orderRequestId} store={this.props.store} />
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengajuan Penempatan Penyertaan Langsung</h2>
                <DevExpressDataGrid
                    keyField='orderRequestId'
                    ref={this.DataGridOrderPlRef}
                    loadAPI='order-requests-pl-penempatan/list?status=DRAFT&status=INPROGRESS'
                    insertAPI='order-stoks'
                    updateAPI='order-stoks'
                    deleteAPI='order-stoks'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Order Penyertaan Langsung"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Order Penyertaan Langsung'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPenempatanPl store={this.props.store} actionType={'add'} ref={this.formPengajuanPenempatanPlRef} forceRefresh={this.forceRefresh} />
                <FormPengajuanPenempatanPl store={this.props.store} ref={this.formPengajuanPenempatanPlEditRef} forceRefresh={this.forceRefresh} />
            </div>
        )
    }
}

export default PengajuanPenempatanPl