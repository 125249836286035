import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import httpRequest from 'plugin/httprequest';
import { ScrollView } from 'devextreme-react/scroll-view';
import { alert } from 'devextreme/ui/dialog'

class FormKinerja extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {
                loanAmount:0,
                loanRate:0,
                cicilanAmount:0,
                cicilanBunga:0
            }
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.insertData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }, 
            
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }, 
            
        ];

        this.formPinjaman = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'periodeTahun',
                        label: {
                            text: 'Periode (Tahun)',
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'jumlahKaryawan',
                        label: {
                            text: 'Jumlah Karyawan',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'skorAverage',
                        label: {
                            text: 'Skor Rata-Rata',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0'
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'tanggalAwal',
                                label: {
                                    text: 'Tanggal Penilaian'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: 'tanggalAkhir',
                                label: {
                                    text: 'S/D'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    
                ]
            }            
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster: {}
        })
    }

    insertData = async() => {
        if(this.state.DataMaster.tanggalAwal > this.state.DataMaster.tanggalAkhir ){
            await alert('Tanggal awal tidak boleh melebihi tanggal akhir !','Validasi')
            return false
        }
        try { 
            var loadAPI = 'evaluasi-kinerjas'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"POST",{
                values: this.state.DataMaster
            });
            
            if (response) {
                var text = 'Sukses Input Evaluasi Kinerja'
                await alert(text, 'Success')
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            await alert(e, 'Failed')
        }
    }

    updateData = async() => {
        if(this.state.DataMaster.tanggalAwal > this.state.DataMaster.tanggalAkhir ){
            await alert('Tanggal awal tidak boleh melebihi tanggal akhir !','Validasi')
            return false
        }
        try { 
            var loadAPI = 'evaluasi-kinerjas'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"PUT", {
                key: this.state.DataMaster.id,
                values: this.state.DataMaster,
            },this.state.DataMaster);
            
            if (response) {
                var text = 'Sukses Update Evaluasi Kinerja'
                await alert(text, 'Success')
                this.hide();
                this.props.forceRefresh()
            }
            
        } catch (e) {
            await alert(e, 'Failed')
        }
    }

    retrieveData = async(data) => {   
        this.setState({
            DataMaster: data
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Evaluasi Kinerja"}
                width={"40vw"}
                height={"45vh"}
                toolbarItems={this.props.type == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formDataPinjaman'}
                        formData={this.state.DataMaster}
                        items={this.formPinjaman}
                        labelLocation = 'left'
                    />
                    </div>
                </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormKinerja