import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, statusPembayaranRekapGaji } from 'dataSource/lookup';
import { Form } from '../../../../../../node_modules/devextreme-react/form';
import FormPengajuanRapel from 'components/pages/modal/sdm-umum/kepegawaian/rapel/rapel';
import FormDaftarKaryawanPenerima from 'components/pages/modal/sdm-umum/payroll/tunjangan-lain/daftarKaryawanPenerima';
import httpRequest from 'plugin/httprequest';
// import { karyawan } from 'dataSource/lookup';
import { confirmAlert } from 'react-confirm-alert'
import notify from "devextreme/ui/notify";

class PengajuanRapel extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }
        this.showModalKaryawanPenerimaRef = React.createRef()
        this.modalAddRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.filterData = []

        this.formMaster = [
            {
                dataField:"bulan",
                label:{
                    text:"Bulan",
                    alignment:"left",
                    location:"left",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled:true
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                    location:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.Columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        onClick : (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text : 'Daftar Karyawan Penerima',
                        hint : 'Daftar Karyawan Penerima',
                        onClick : (e) => {
                            this.showModalKaryawanPenerima(e.row.data)
                        }
                    },
                    {
                        text : 'Disetujui',
                        hint : 'Disetujui',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.kirim(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text : 'Tolak',
                        hint : 'Tolak',
                        onClick : (e) => {
                            console.log("new")
                        }
                    },
                ],
            },
            {
                dataField: 'kodeRapel',
                caption: 'No Pengajuan.',
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'jumKaryawan',
                caption: 'Jumlah Karyawan'
            },
            {
                dataField: 'besaranRapel',
                caption: 'Besaran Rapel',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'nilaiPersenGapok',
                        caption: '% Gaji',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'nilaiPersenGapokTunjangan',
                        caption: '% Gaji + Tunjangan',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'nilaiNominal',
                        caption: 'Nominal',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    }
                ]
            },
            {
                dataField: 'totalNominalRapel',
                caption: 'Total Nominal Pengajuan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField:'penerima',
                caption:'Penerima',
                lookup: {
                    dataSource: [
                        {
                            id:1,
                            value:'Semua'
                        },
                        {
                            id:2,
                            value:'Personal'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'pengajuanTanggal',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'periodeDari',
                caption: 'Periode Gaji (Bulan / Tahun)',
                dataType: 'date',
                format: 'MMM yyyy',
            },
            {
                dataField: 'periodeSampai',
                caption: 'Periode Gaji (Bulan / Tahun)',
                dataType: 'date',
                format: 'MMM yyyy',
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            }
        ]
        
        this.dataGridRef = React.createRef()
    }

    componentDidMount = async() => {
        this.defaultData()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    defaultData = async () => { 
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-list-karyawan-rapels?statusId.in=1`,
            'GET'
        )
        this.filterData = datas
        if (this.filterData) {
            this.forceRefresh()
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  onClick: (e) => {
                    this.showModalAdd(e)
                  },
              }
          }
        )
      }

    showModalKaryawanPenerima = (data) => {
        if (data) {
            this.showModalKaryawanPenerimaRef.current.retrieveData(data)
        }
        this.showModalKaryawanPenerimaRef.current.show()
    }

    kirim = async (data) => {
        try {
            var loadApi = `karyawan-rapels/kirim-rapel`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: { karyawanRapelId:data.id }
            })
            if (response) {
                this.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    filterSubmit = async () => {
        const state = this.state.dataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-list-karyawan-rapels?statusId.in=1`,
            'GET'
        )

        var listData = []
        for(var data of datas){
            data.pengajuanTanggal = new Date(data.pengajuanTanggal)
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        }else if (state.bulan) {
            this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.pengajuanTanggal.getFullYear() == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    
    }

    loadData = async () => {
        return this.filterData
    }

    showModalAdd = (data) => {
        this.modalAddRef.current.generateNoPengajuan()
        this.modalAddRef.current.getListTunjanganType()
        this.modalAddRef.current.show()
    }

    showModalEdit = (data) => {
        if (data) {
            this.modalEditRef.current.retrieveData(data)
        }
        this.modalEditRef.current.getListTunjanganType()
        this.modalEditRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Rapel</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.dataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-daftar-list-karyawan-rapels'
                    insertAPI='pengajuan-rapel'
                    updateAPI='pengajuan-rapel'
                    deleteAPI='pengajuan-rapel' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Rapel"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Rapel'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.Columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormPengajuanRapel
                    ref={this.modalAddRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                    from={'pengajuan'}
                    action={'add'}
                />
                <FormPengajuanRapel
                    ref={this.modalEditRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                    from={'pengajuan'}
                    action={'edit'}
                />
                
                <FormDaftarKaryawanPenerima
                    ref={this.showModalKaryawanPenerimaRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                    action={'rapel'}
                />
            </div>
        )
    }
}

export default PengajuanRapel