import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import ModalSettlementPenyelesaianAlokasiUnit from 'components/pages/modal/investasi/settlement/penyelesaian/alokasiUnit/index';
import ModalFileSettlementPenyelesaianAlokasiUnit from 'components/pages/modal/investasi/settlement/penyelesaian/alokasiUnit/file';
import { addURL } from 'redux/actions/url';
import { managerInvestasis, currency, instrumentTransactionTypes, instrumentReksadana, instrumentSubCategoryReksadana } from 'dataSource/lookup';
import ModalAlokasiUnitPelepasanReksadana from 'components/pages/modal/investasi/settlement/penyelesaian/alokasiUnit/pelepasan/index';
import httpRequest from 'plugin/httprequest';

class SettlementPenyelesaianAlokasiUnit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {},
            filterItem: {}
        }

        this.modalAlokasiRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalSettlementPenyelesaianAlokasiUnitRef = React.createRef()
        this.modalAlokasiPelepasanRef = React.createRef()
        this.modalFileSettlementPenyelesaianAlokasiUnitRef = React.createRef()

        this.filterItem = [
            {
                dataField: "tanggalTransaksi",
                label: {
                    text: "Tanggal Transaksi",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox'
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        // this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Konfirmasi',
                        hint: 'Konfirmasi',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalSettlementPenyelesaianAlokasiUnit('edit', e.row.data)
                        }
                    },
                    {
                        text: 'File',
                        hint: 'File',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalFileSettlementPenyelesaianAlokasiUnit('edit', e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'settleNo',
                caption: 'Nomor Transaksi',
            },
            {
                dataField: 'categorySubId',
                caption: 'Jenis Reksadana',
                lookup: {
                    dataSource: instrumentSubCategoryReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                },
            },
            {
                dataField: 'instrumentId',
                caption: 'Produk Reksadana',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'managerInvestasiId',
                caption: 'Manager Investasi',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'transactionTypeId',
                caption: 'Tipe Transaksi',
                lookup: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName'
                }
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Settle',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'amountTrs',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            // {
            //     dataField: 'fee',
            //     caption: 'Fee',
            //     alignment: 'right',
            //     dataType: 'number',
            //     format: '#,##0.00'
            // },
            {
                dataField: 'currencyId',
                caption: 'Currency',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    loadDummyData = () => {
        var dummy = [
            {
                id: 1,
                noTransaksi: '09873',
                jenisReksadana: 'Campuran',
                produkReksadana: 'Panin Campuran Plus',
                managerInvestasi: 1002,
                tipeTransaksi: 'Subscription',
                tanggalTransaksi: '03 Sep 2020',
                tanggalSettle: '04 Sep 2020',
                nominal: 2000000000,
                currencyId: 1101
            },
        ]
        return dummy
    }

    showModalSettlementPenyelesaianAlokasiUnit = async(type, data = null) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transactions/${data.id}`, 'GET')
        data.paymentDate = detail.paymentDate
        if (data.transactionTypeId == 221) {
            this.modalSettlementPenyelesaianAlokasiUnitRef.current.show()
            this.modalSettlementPenyelesaianAlokasiUnitRef.current.retrieveData(type, data)
        }else if(data.transactionTypeId == 222){
            this.modalAlokasiPelepasanRef.current.show()
            this.modalAlokasiPelepasanRef.current.retrieveData(type,data)
        }
    }
    showModalFileSettlementPenyelesaianAlokasiUnit = (type, data = null) => {
        this.modalFileSettlementPenyelesaianAlokasiUnitRef.current.show()
        this.modalFileSettlementPenyelesaianAlokasiUnitRef.current.retrieveData(type, data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Alokasi Unit Produk Reksadana</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='transaction-need-allocations/rd/list'
                    insertAPI='cash-deviden'
                    updateAPI='cash-deviden'
                    deleteAPI='cash-deviden'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Alokasi Unit Produk Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Alokasi Unit Produk Reksadana'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}


                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalSettlementPenyelesaianAlokasiUnit
                    ref={this.modalSettlementPenyelesaianAlokasiUnitRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalAlokasiUnitPelepasanReksadana
                    ref={this.modalAlokasiPelepasanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalFileSettlementPenyelesaianAlokasiUnit
                    ref={this.modalFileSettlementPenyelesaianAlokasiUnitRef}
                    store={this.props.store}
                />

            </div>
        )
    }
}

export default SettlementPenyelesaianAlokasiUnit