import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { formatUploadFileData } from 'plugin/helper';
import { modaTransportasi } from 'dataSource/lookup';

class FormTiketPerjalananDinas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataPengajuan: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Batal",
                  onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
              },
          ];
        this.formTiketPenugasan = [
            {
                dataField: 'modaTransportasi',
                label: {
                    text: 'Moda',
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: modaTransportasi(),
                    valueExpr: 'value',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'noTiket',
                label: {
                    text: 'No. Tiket'
                }
            },
            {
                dataField: 'namaArmada',
                label: {
                    text: 'Armada/Maskapai',
                },
                // editorType: 'dxSelectBox',
                // editorOptions:{
                //     dataSource: [
                //         {
                //             id: 1,
                //             value: 'Lion Air'
                //         },
                //         {
                //             id: 2,
                //             value: 'Argo Anggrek'
                //         },
                //         {
                //             id: 3,
                //             value: 'Kirana'
                //         },
                //         {
                //             id: 4,
                //             value: 'Margi Travels'
                //         },
                //     ],
                //     valueExpr: 'id',
                //     displayExpr: 'value'
                // }
            },
            {
                dataField: 'tanggalPerjalanan',
                label: {
                    text: 'Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    format: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'jam',
                label: {
                    text: 'Jam'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    type: 'time',
                    format: 'HH:mm'
                }
            },
            {
                dataField: 'dari',
                label: {
                    text: 'Dari'
                }
            },
            {
                dataField: 'tujuan',
                label: {
                    text: 'Tujuan'
                }
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var data = this.state
        var dataSubmit = {...data.dataPengajuan}

        // console.log(dataSubmit)
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data) => {    
        this.setState({
            dataPengajuan: data,
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Tiket"}
                width={"55vw"}
                height={"45vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <Form
                        colCount={1}
                        id={'formTiketPenugasan'}
                        formData={this.state.dataPengajuan}
                        items={this.formTiketPenugasan}
                        labelLocation = 'left'
                    />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormTiketPerjalananDinas