import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { statusPeserta } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { pushNotification } from 'plugin/helper';
import ModaDetailPengkinianOnline from 'components/pages/modal/kepesertaan/pengkinianPeserta/online/index';
import { confirmAlert } from 'react-confirm-alert'
import { showLoading } from 'redux/actions/loading';

class PengkinianPengajuanOnline extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.DataGridPemeriksaanRef = React.createRef()
        this.DataGridPersetujuanRef = React.createRef()
        this.modalDetailPengkinianOnlineRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPengkinianPesertaRegistrasiAktif(e.row.data, 'edit')
                        }
                    },
                    {
                        text: 'Setuju',
                        hint: 'Setuju',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Menerima Pengkinian Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.kirim(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Tolak',
                        hint: 'Tolak',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Menolak Pengkinian Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.reject(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'entryDate',
                caption: 'Tanggal Pengkinian',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pesertaStatusId',
                caption: 'Peserta Status',
                lookup: {
                    dataSource: statusPeserta(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName',
                }
            },
        ]
    }

    renderButton = (button) => {
        return <div style={{ padding: 20 }}><p>{button.text}</p></div>;
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengkinianPesertaRegistrasiAktif = (data, type) => {
        this.modalDetailPengkinianOnlineRef.current.show()
        this.modalDetailPengkinianOnlineRef.current.retrieveData(data, type)
    }
    showModalInsert = () => {
        this.modalRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    kirim = async (data) => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        try {
            var loadAPIPengkinian = `pengkinian-onlines/${data.id}`
            var getPengkinian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPengkinian, 'GET')
        } catch (e) {
            console.log(e)
        }

        var dataPengkinian = getPengkinian
        dataPengkinian.statusCode = "2"
        if (dataPengkinian) {
            try {
                // UPDATE PESERTA
                var apiPeserta = 'pesertas'
                var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, apiPeserta + '/' + dataPengkinian.pesertaId, 'GET')
                var dataPeserta = {
                    ...getPeserta,
                    id: dataPengkinian.pesertaId,
                    mariedId: dataPengkinian.marriedId || getPeserta.mariedId,
                    domicileAddress1: dataPengkinian.alamatDomisili || getPeserta.domicileAddress1,
                    domicileProvinceId: dataPengkinian.provinsiId || getPeserta.domicileProvinceId,
                    domicileCityId: dataPengkinian.kotaId || getPeserta.domicileCityId,
                    domicileKecamatanId: dataPengkinian.kecamatanId || getPeserta.domicileKecamatanId,
                    domicileKelurahanId: dataPengkinian.kelurahanId || getPeserta.domicileKelurahanId,
                    domicilePostalCode: dataPengkinian.postalCode || getPeserta.domicilePostalCode,
                    mobilePhone2: dataPengkinian.no_telp || getPeserta.mobilePhone2,
                    mobileNo: dataPengkinian.no_hp || getPeserta.mobileNo,
                    rekeningNo: dataPengkinian.rekeningNo || getPeserta.rekeningNo,
                    rekeningName: dataPengkinian.rekeningName || getPeserta.rekeningName,
                    rekeningBankId: dataPengkinian.rekeningBankId || getPeserta.rekeningBankId,
                    rekeningCabang: dataPengkinian.rekeningCabang || getPeserta.rekeningCabang,
                }
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, apiPeserta, 'PUT', {
                    key: dataPeserta.id,
                    values: dataPeserta
                },
                    getPeserta)

                // UPDATE FAMILY
                var loadAPIFamily = 'peserta-families'

                var loadAPIPasangan = `simple-peserta-families/getAllPesertaFamiliesByRelationCode/${dataPengkinian.pesertaId}/SI`
                var getDataPasangan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPasangan, 'GET')

                if (dataPengkinian.namaPasangan) {
                    if (getDataPasangan.length !== 0) {
                        var getPasangan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily + '/' + getDataPasangan[0].id, 'GET')

                        var dataPasangan = getPasangan
                        dataPasangan.fullName = dataPengkinian.namaPasangan || dataPasangan.fullName
                        dataPasangan.birthDate = dataPengkinian.tglLahirPasangan || dataPasangan.birthDate
                        dataPasangan.sex = dataPengkinian.sex || dataPasangan.sex
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'PUT', {
                            key: dataPasangan.id,
                            values: dataPasangan
                        },
                            getPasangan)
                    } else {
                        var dataPasangan = {
                            pesertaId: dataPengkinian.pesertaId,
                            fullName: dataPengkinian.namaPasangan,
                            birthDate: dataPengkinian.tglLahirPasangan,
                            sex: dataPengkinian.sex
                        }
                        dataPasangan.fullName = dataPengkinian.namaPasangan || dataPasangan.fullName
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'POST', {
                            values: dataPasangan
                        })
                    }
                }

                var loadAPIPasangan = `simple-peserta-families/getAllPesertaFamiliesByRelationCode/${dataPengkinian.pesertaId}/AK`
                var getAllAnak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPasangan, 'GET')

                for (let i = 0; i < 3; i++) {
                    var dataAnak = {}
                    if (getAllAnak[i]) {
                        dataAnak = getAllAnak[i]
                    }

                    dataAnak.fullName = dataPengkinian[`anak${i + 1}Nama`]
                    dataAnak.birthDate = dataPengkinian[`anak${i + 1}TglLahir`]
                    dataAnak.sex = dataPengkinian[`anak${i + 1}SexId`]

                    if (dataAnak.id) {
                        var getAnak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily + '/' + dataAnak.id, 'GET')

                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'PUT', {
                            key: dataAnak.id,
                            values: dataAnak
                        },
                            getAnak)
                    } else {
                        if (dataAnak.fullName) {
                            dataAnak.pesertaId = dataPengkinian.pesertaId
                            dataAnak.relationCode = 'AK'
                            dataAnak.ahliWaris = 'false'
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'POST', {
                                values: dataAnak
                            })
                        }
                    }
                }

                var loadAPIDokumen = `dokumen-pesertas/getByPesertaIdAndType/${data.pesertaId}/`
                var loadAPIBasicDoc = 'dokumen-pesertas'
                var getDokumenKk = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDokumen + '7', 'GET')
                var getDokumenBukuNikah = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDokumen + '8', 'GET')

                if (dataPengkinian.fileKk) {
                    if (getDokumenKk.length !== 0) {
                        var dataDokumen = getDokumenKk[0]

                        dataDokumen.pesertaId = dataPengkinian.pesertaId
                        dataDokumen.dokumenFile = dataPengkinian.fileKk
                        dataDokumen.dokumenFileName = dataPengkinian.fileKkName
                        dataDokumen.dokumenFileExtention = dataPengkinian.fileKkExtention
                        dataDokumen.dokumenFileContentType = dataPengkinian.fileKkContentType

                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIBasicDoc, 'PUT', {
                            key: dataDokumen.id,
                            values: dataDokumen
                        },
                            getDokumenKk[0])

                    } else {
                        var dataDokumen = {
                            pesertaId: dataPengkinian.pesertaId,
                            dokumenFile: dataPengkinian.fileKk,
                            dokumenFileName: dataPengkinian.fileKkName,
                            dokumenFileExtention: dataPengkinian.fileKkExtention,
                            dokumenFileContentType: dataPengkinian.fileKkContentType,
                            dokumenTypeId: '7'
                        }
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIBasicDoc, 'POST', {
                            values: dataDokumen
                        })
                    }
                }

                if (dataPengkinian.marriedFile) {
                    if (getDokumenBukuNikah.length !== 0) {
                        var dataDokumen = getDokumenBukuNikah[0]
                        dataDokumen.pesertaId = dataPengkinian.pesertaId
                        dataDokumen.dokumenFile = dataPengkinian.marriedFile
                        dataDokumen.dokumenFileName = dataPengkinian.marriedFileName
                        dataDokumen.dokumenFileExtention = dataPengkinian.marriedFileExtention
                        dataDokumen.dokumenFileContentType = dataPengkinian.marriedFileContentType

                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIBasicDoc, 'PUT', {
                            key: dataDokumen.id,
                            values: dataDokumen
                        },
                            getDokumenBukuNikah[0])
                    } else {
                        var dataDokumen = {
                            pesertaId: dataPengkinian.pesertaId,
                            dokumenFile: dataPengkinian.marriedFile,
                            dokumenFileName: dataPengkinian.marriedFileName,
                            dokumenFileExtention: dataPengkinian.marriedFileExtention,
                            dokumenFileContentType: dataPengkinian.marriedFileContentType,
                            dokumenTypeId: '8'
                        }
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIBasicDoc, 'POST', {
                            values: dataDokumen
                        })
                    }
                }

                var updateAPI = 'pengkinian-onlines'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPengkinian.id,
                    values: dataPengkinian
                },
                    dataPengkinian)

                var text = `Data berhasil dikirim!`
                var type = 'success'

                // Push notif
                var visitors = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `visitors/all-by-criteria-paging?nik.in=${getPeserta.nik}`, 'GET')
                var reffNo = ("0" + dataPengkinian.id).slice(-5);
                var dataPostNotif = {
                    "description": "Pengkinian Data Anda Diterima",
                    "readStatus": false,
                    "reffNo": reffNo,
                    "reffType": "1",
                    "title": "Data Diterima",
                    "userId": visitors.length !== 0 ? visitors[0].id : null
                }
                var postNotifAPI = 'notifications/postNotif'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postNotifAPI, 'POST', {
                    values: dataPostNotif
                })
                // pushNotification(`Pengkinan Data Anda Diterima`,1,`PD${reffNo}`, dataPengkinian.pesertaId)
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 2000);
                this.forceRefresh()
                store.dispatch(showLoading(false))
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 2000);
                store.dispatch(showLoading(false))
            }
        }
    }

    reject = async (data) => {
        if (data) {
            try {
                var loadAPIPengkinian = `pengkinian-onlines/${data.id}`
                var dataPengkinian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPengkinian, 'GET')

                var apiPeserta = 'pesertas'
                var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, apiPeserta + '/' + dataPengkinian.pesertaId, 'GET')

                var deleteAPI = 'pengkinian-onlines'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, deleteAPI, 'DELETE', data.id)

                var text = `Data berhasil ditolak!`
                var type = 'success'

                // Push notif
                var visitors = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `visitors/all-by-criteria-paging?nik.in=${getPeserta.nik}`, 'GET')
                var reffNo = ("0" + dataPengkinian.id).slice(-5);
                var dataPostNotif = {
                    "description": "Pengkinian Data Anda Ditolak",
                    "readStatus": false,
                    "reffNo": reffNo,
                    "reffType": "1",
                    "title": "Data Ditolak",
                    "userId": visitors.length !== 0 ? visitors[0].id : null
                }
                var postNotifAPI = 'notifications/postNotif'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postNotifAPI, 'POST', {
                    values: dataPostNotif
                })
                // pushNotification(`Pengkinan Data Anda Ditolak`, 1, `PD${reffNo}`, data.pesertaId)
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.forceRefresh()
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    updateData = () => {
        var text = `Data berhasil disimpan!`
        var type = 'success'
        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Pengkinian Online</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='pengkinian-onlines/getAllPengkinianOnlinesByStatusCode/1?size=99999'
                        insertAPI='pengkinian-onlines'
                        updateAPI='pengkinian-onlines'
                        deleteAPI='pengkinian-onlines'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ModaDetailPengkinianOnline
                    ref={this.modalDetailPengkinianOnlineRef}
                    actionType={'edit'}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default PengkinianPengajuanOnline
