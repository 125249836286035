import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader, ScrollView } from 'devextreme-react';
import { download, formatNumberAum, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import { alert } from 'devextreme/ui/dialog';
import { currency, kasUmum, pembayaranUmumDetilByPuId, rekBankHrd } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';

class PPPengajuanCashOutModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formKonfirmasiRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    } 
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'pengajuanNo',
                        label: {
                            alignment: 'left',
                            text: 'No Pengajuan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                if (e) {
                                    var a = e.cash ? "Cash" : e.transfer ? "Transfer" : e.cashOut ? "Cash Out" : "Lain"
                                    return e && e.keterangan + '@' + formatNumberAum(e.pengajuanNominal) + ' - ' + a
                                }
                                
                            },
                            onSelectionChanged: async (e) => {
                                var selected = e.selectedItem
                                if (selected) {
                                    var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `getDetailPengajuanByPembayaranUmumDetailId/${selected.id}`, 'GET')

                                    this.formKonfirmasiRef.current.instance.updateData({
                                        nominalUangMuka: selected.dpAmount,
                                        cashOut: selected.cashOut,
                                        pembayaranUmumId: selected.pembayaranUmumId,
                                        totalNominalPengajuan: response ? response.jumPengajuanNominal == undefined ? 0 : response.jumPengajuanNominal : 0,
                                        totalUangMuka: response ? response.totUangMuka == undefined ? 0 : response.totUangMuka : 0,
                                        totalSisaPembayaran: response ? response.id8 == undefined ? 0 : response.id8 : 0,
                                        totalSisaUangMuka: response ? response.id9 == undefined ? 0 : response.id9 : 0,
                                        totalSisaCashOut: response ? response.id10 == undefined ? 0 : response.id10 : 0,
                                    })
                                }
                            },
                            searchEnabled: true,
                            deferRendering: false
                        }
                    },
                    {
                        dataField: 'pembayaranUmumId',
                        label: {
                            alignment: 'left',
                            text: 'Pembayaran ID'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPembayaran',
                        label: {
                            text: 'Tanggal Penerimaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            onValueChanged: (e) => {
                                var val = e.value
                                this.formKonfirmasiRef.current.instance.updateData({ tanggalPenerimaan: val })
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Tanggal Penerimaan tidak boleh kosong"
                            }
                        ]
                    },
                    {
                        dataField: 'cashOut',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Cash Out'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly:true
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Nominal Cash Out tidak boleh kosong"
                            }
                        ]
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Currency',
                            alignment: "left",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                caption: 'Informasi Pengajuan',
                items: [
                    {
                        dataField: 'reffTransaction',
                        label: {
                            alignment: 'left',
                            text: 'Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'itemPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Item Pembayaran'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'noPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'No Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Keterangan Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPenerimaan',
                        label: {
                            text: 'Tanggal Penerimaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true,
                        },
                    },
                    {
                        name: 'totalNominalPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'Total Nominal Pengajuan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        name: 'totalUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Total Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        name: 'totalSisaPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Pembayaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        name: 'totalSisaUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        name: 'totalSisaCashOut',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Cash Out'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                ]
            },
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    validate = () => {
        var formValidation = this.formKonfirmasiRef.current.instance.validate();
        return formValidation.isValid
    }

    retrieveData = async (data) => {
        var loadApi = `pembayaran-umums/${data.id}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'GET')

        var loadApiDetail = `pembayaran-umum-detils/getByPembayaranUmumId/${data.id}`
        var getDataDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApiDetail, 'GET')

        var totalJumlahCo = 0

        for (var value of getDataDetail) {
            totalJumlahCo += value.cashOut || 0
        }
        getData.nominalCashOut = totalJumlahCo

        var pdId = this.formKonfirmasiRef.current.instance.getEditor('pengajuanNo')
        // pdId.option('value', '')
        if (getData.id) {
            pdId.option('dataSource', pembayaranUmumDetilByPuId(getData.id, this.props.store, true))
        }
        this.setState({
            dataMaster: getData
        })

        // this.formKonfirmasiRef.current.instance.updateData(getData)
    }

    submitData = async () => {
        try {
            var data = this.state.dataMaster
            // var getCurrency = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'currencies/' + data.currencyId, 'GET')
            var postData = {
                "currencyId": data.currencyId,
                "documentFile": data.documentFile,
                "documentName": data.documentName,
                "entity": 0,
                "itemPengajuan": data.itemPembayaran || 0,
                "kasUmumId": 0,
                "keteranganPengajuan": data.keterangan,
                "noPengajuan": data.noPengajuan,
                "noPengajuanInfo": data.noPengajuan,
                "nominalCashOut": data.cashOut || 0,
                "nominalKasUmum": 0,
                "nominalTransfer": 0,
                "pembayaranUmumId": data.pembayaranUmumId,
                "pembayaranUmumDetilId": data.pengajuanNo,
                "reffTransaksi": data.reffTransaction,
                "rekBankId": 0,
                "tanggalPembayaran": data.tanggalPembayaran,
                "tanggalPenerimaan": data.tanggalPenerimaan,
                "totalNominalPengajuan": data.totalNominalPengajuan,
                "totalSisaCashOut": data.totalSisaCashOut,
                "totalSisaPembayaran": data.totalSisaPembayaran,
                "totalSisaUangMuka": data.totalSisaUangMuka,
                "totalUangMuka": data.totalUangMuka,
                "username": this.props.store.getState().sessionUser
            }
            var insertAPI = 'pembayaran-umum-aktif-arap-co'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, insertAPI, 'POST', {
                values: postData
            })

            await alert('Data berhasil dikirim!', 'Berhasil!')

            this.hide()
            this.props.forceRefresh()
        } catch (e) {
            console.log(e)
            await alert(e, 'Gagal')
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)

                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Name`]: files.name,
                        [`${fieldName}File`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))

            }

            fr.readAsDataURL(files)
        }
    }

    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}File`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}File`]), this.state.dataMaster[`${fileName}Name`])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Cash Out'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{ height: '100%' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formKonfirmasiRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation='left'
                                />
                            </div>
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.documentName || 'File'}
                                    showFileList={false}
                                    name={'document'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('document')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('document')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default PPPengajuanCashOutModal