import React, { Component } from 'react'
import { Form, Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import { participants, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import reportRequest, {reportFile} from 'plugin/reportRequest'
import { formatDate, formatDefaultDate, formatNpwp, formatNumber, generateExcelFile, imageSource, PrintElem } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';

class ReportPesertaAktif extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            src: ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'productId',
                        label:{
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField:'greaterEfektifDate',
                                label:{
                                    text: 'Tanggal Efektif',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField:'lessEfektifDate',
                                label:{
                                    text: 'S/D',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-laporan-peserta-aktifs?`

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
        }

        if(filter.greaterEfektifDate){
            loadAPI = loadAPI+`efektifDate.greaterThanOrEqual=${formatDate(filter.greaterEfektifDate)}&`
        }

        if(filter.lessEfektifDate){
            loadAPI = loadAPI+`efektifDate.lessThanOrEqual=${formatDate(filter.lessEfektifDate)}&`
        }
        var getPesertaAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        try {
            var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
        } catch (error) {
            var productName = 'Semua'
        }

        try {
            var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
        } catch (error) {
            var participantName = 'Semua'
        }

        const loadAPIStatusPajak = `status-pajaks?size=99999`
        const getStatusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIStatusPajak, 'GET')

        let summaryPhdp = []
        for(var value of getPesertaAktif){
            if(value.provinsiId) {
                const loadAPIProvince = `provinces/${value.provinsiId}`
                const getProvince = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIProvince, 'GET')    
                var provinsi = value.provinsiId ? getProvince.provinceName : "-"
            }
            if (value.kecamatanId) {
                const loadAPIKecamatan = `kecamatans/${value.kecamatanId}`
                const getKecamatan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKecamatan, 'GET')
                var kecamatan = value.kecamatanId ? getKecamatan.kecamatanName : "-"
            }
            if(value.kelurahanId) {
                const loadAPIKelurahan = `kelurahans/${value.kelurahanId}`
                const getKelurahan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKelurahan, 'GET')
                var kelurahan = value.kelurahanId ? getKelurahan.kelurahanName : "-"
            }

            value.alamat = value.alamat ? (value.alamat !== '-' ? `${value.alamat}, Kel ${kelurahan !== undefined ? kelurahan : '-'}, Kec ${kecamatan !== undefined ? kecamatan : '-'}, Provinsi ${provinsi !== undefined ? provinsi : '-'}` : '-') : '-'

            let statusName = getStatusPajak.find(val => val.id === value.statusPajakId)
            value.statusPajakId = statusName ? statusName.statusName : '-'

            value.birthDate = value.birthDate ? formatDefaultDate(value.birthDate) : '-'
            value.statusKaryawanTanggal = value.statusKaryawanTanggal ? formatDefaultDate(value.statusKaryawanTanggal) : '-'
            value.efektifDate = value.efektifDate ? formatDefaultDate(value.efektifDate) : '-'

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName

            let totalPhdp = value.phdp ? value.phdp : 0

            summaryPhdp.push(value.phdp)
        }

        if(summaryPhdp.length !== 0) {
            var sumPhdp = summaryPhdp.reduce((a, b) => a + b)
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/peserta/peserta-aktif',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                tanggalEfektif : filter.lessEfektifDate && filter.greaterEfektifDate ? (filter.greaterEfektifDate ? formatDefaultDate(filter.greaterEfektifDate) : "~") + ' S/D ' + (filter.lessEfektifDate ? formatDefaultDate(filter.lessEfektifDate) : "~") : 'Semua',
                dataPeserta : getPesertaAktif,
                summaryPhdp: sumPhdp,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))

        this.setState({
            src : responseFile
        })
    }
    
    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-laporan-peserta-aktifs?`

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
        }

        // if(filter.efektifDate){
        //     loadAPI = loadAPI+`efektifDate.equals=${formatDate(filter.efektifDate)}`
        // }

        if(filter.greaterEfektifDate){
            loadAPI = loadAPI+`efektifDate.greaterThanOrEqual=${formatDate(filter.greaterEfektifDate)}&`
        }

        if(filter.lessEfektifDate){
            loadAPI = loadAPI+`efektifDate.lessThanOrEqual=${formatDate(filter.lessEfektifDate)}&`
        }
        // var getPesertaAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
        var getPesertaAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        try {
            var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
        } catch (error) {
            var productName = 'Semua'
        }

        try {
            var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
        } catch (error) {
            var participantName = 'Semua'
        }

        const loadAPIStatusPajak = `status-pajaks?size=99999`
        const getStatusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIStatusPajak, 'GET')

        let summaryPhdp = []
        for(var value of getPesertaAktif){
            if(value.provinsiId) {
                const loadAPIProvince = `provinces/${value.provinsiId}`
                const getProvince = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIProvince, 'GET')    
                var provinsi = value.provinsiId ? getProvince.provinceName : "-"
            }
            if (value.kecamatanId) {
                const loadAPIKecamatan = `kecamatans/${value.kecamatanId}`
                const getKecamatan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKecamatan, 'GET')
                var kecamatan = value.kecamatanId ? getKecamatan.kecamatanName : "-"
            }
            if(value.kelurahanId) {
                const loadAPIKelurahan = `kelurahans/${value.kelurahanId}`
                const getKelurahan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKelurahan, 'GET')
                var kelurahan = value.kelurahanId ? getKelurahan.kelurahanName : "-"
            }

            let statusName = getStatusPajak.find(val => val.id === value.statusPajakId)
            value.statusPajakId = statusName ? statusName.statusName : '-'

            value.alamat = value.alamat ? (value.alamat !== '-' ? `${value.alamat}, Kel ${kelurahan !== undefined ? kelurahan : '-'}, Kec ${kecamatan !== undefined ? kecamatan : '-'}, Provinsi ${provinsi !== undefined ? provinsi : '-'}` : '-') : '-'

            value.birthDate = value.birthDate ? formatDefaultDate(value.birthDate) : '-'
            value.statusKaryawanTanggal = value.statusKaryawanTanggal ? formatDefaultDate(value.statusKaryawanTanggal) : '-'
            value.efektifDate = value.efektifDate ? formatDefaultDate(value.efektifDate) : '-'

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName

            let totalPhdp = value.phdp ? value.phdp : 0

            summaryPhdp.push(totalPhdp)
        }

        if(summaryPhdp.length !== 0) {
            var sumPhdp = summaryPhdp.reduce((a, b) => a + b)
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/peserta/peserta-aktif',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                tanggalEfektif : filter.lessEfektifDate && filter.greaterEfektifDate ? (filter.greaterEfektifDate ? formatDefaultDate(filter.greaterEfektifDate) : "~") + ' S/D ' + (filter.lessEfektifDate ? formatDefaultDate(filter.lessEfektifDate) : "~") : 'Semua',
                dataPeserta : getPesertaAktif,
                summaryPhdp: sumPhdp,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'DAFTAR PESERTA AKTIF'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'DAFTAR PESERTA AKTIF')
        store.dispatch(showLoading(false))
        return responseFile
    }

    exportExcel = async() => {
        try {
            var data = []
            let store = this.props.store
            store.dispatch(showLoading(true))
            var filter = this.state.formFilter
            var loadAPI = `view-laporan-peserta-aktifs?`

            if(filter.participantId){
                loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            }

            if(filter.productId){
                loadAPI = loadAPI+`productId.in=${filter.productId}&`
            }

            // if(filter.efektifDate){
            //     loadAPI = loadAPI+`efektifDate.equals=${formatDate(filter.efektifDate)}`
            // }
            
            if(filter.greaterEfektifDate){
                loadAPI = loadAPI+`efektifDate.greaterThanOrEqual=${formatDate(filter.greaterEfektifDate)}&`
            }
    
            if(filter.lessEfektifDate){
                loadAPI = loadAPI+`efektifDate.lessThanOrEqual=${formatDate(filter.lessEfektifDate)}&`
            }
            // var getPesertaAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
            var getPesertaAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = 'Semua'
            }

            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }

            const loadAPIStatusPajak = `status-pajaks?size=99999`
            const getStatusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIStatusPajak, 'GET')
            
            var index = 1
            let summaryPhdp = []
            for(var value of getPesertaAktif){
                if(value.provinsiId) {
                    const loadAPIProvince = `provinces/${value.provinsiId}`
                    const getProvince = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIProvince, 'GET')    
                    var provinsi = value.provinsiId ? getProvince.provinceName : "-"
                }
                if (value.kecamatanId) {
                    const loadAPIKecamatan = `kecamatans/${value.kecamatanId}`
                    const getKecamatan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKecamatan, 'GET')
                    var kecamatan = value.kecamatanId ? getKecamatan.kecamatanName : "-"
                }
                if(value.kelurahanId) {
                    const loadAPIKelurahan = `kelurahans/${value.kelurahanId}`
                    const getKelurahan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKelurahan, 'GET')
                    var kelurahan = value.kelurahanId ? getKelurahan.kelurahanName : "-"
                }
    
                value.alamat = value.alamat ? (value.alamat !== '-' ? `${value.alamat}, Kel ${kelurahan !== undefined ? kelurahan : '-'}, Kec ${kecamatan !== undefined ? kecamatan : '-'}, Provinsi ${provinsi !== undefined ? provinsi : '-'}` : '-') : '-'

                var dataValue = {
                    id: index++,
                    nik: value.nik,
                    fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                    // birthPlace: value.birthPlace,
                    birthDate: value.birthDate ? formatDefaultDate(value.birthDate) : '-',
                    efektifDate: value.efektifDate ? formatDefaultDate(value.efektifDate) : '-',
                    statusKaryawanTanggal: value.statusKaryawanTanggal ? formatDefaultDate(value.statusKaryawanTanggal) : '-',
                    grade: value.grade,
                    alamat: value.alamat,
                    // email: value.email,
                    statusPajakId: value.statusPajakId ? getStatusPajak.find(val => val.id === value.statusPajakId).statusName : '-',
                    jnsKel: value.jnsKel,
                    mobileNo: value.mobileNo,
                    // statusPajakId: value.statusPajakId,
                    // unitKerjaName: value.unitKerjaName,
                    usia: (value.usia),
                    mk: (value.mk),
                    phdp: (value.phdp),
                    // npwp: formatNpwp(value.npwp),
                    // noKtp: value.noKtp
                }
    
                const objectValue = Object.values(dataValue)

                let totalPhdp = value.phdp ? value.phdp : 0

                summaryPhdp.push(totalPhdp)
                data.push(objectValue)
            }

            if(summaryPhdp.length !== 0) {
                var sumPhdp = summaryPhdp.reduce((a, b) => a + b)
            }

            try{
                const workbook = new Excel.Workbook();
                var worksheet = workbook.addWorksheet('DAFTAR PESERTA AKTIF');
                worksheet.addTable({
                    name: 'MyTable',
                    ref: 'A1',
                    headerRow: true,
                    totalsRow: false,
                    style: {
                        theme: 'TableStyleLight15',
                        showFirstColumn: true,
                        // border: {
                            //     top: {style:'thin'},
                            //     left: {style:'thin'},
                            //     bottom: {style:'thin'},
                    //     right: {style:'thin'}
                    // }
                  },
                  columns: [
                        {name: 'No'},
                        {name: 'NIK'},
                        {name: 'Nama'},
                        // {name: 'Tempat Lahir'},
                        {name: 'Tanggal Lahir'},
                        {name: 'Tanggal Mulai Kerja'},
                        {name: 'Tanggal Menjadi Karyawan Tetap'},
                        {name: 'Grade'},
                        {name: 'Alamat'},
                        // {name: 'Email'},
                        {name: 'Status Keluarga'},
                        {name: 'Jenis Kelamin'},
                        {name: 'No HP'},
                        // {name: 'Status Pajak'},
                        // {name: 'Unit Kerja'},
                        {name: 'Usia'},
                        {name: 'Mk'},
                        {name: 'PHDP\r\n(Rp)'},
                        // {name: 'NPWP'},
                        // {name: 'No KTP'},
                  ],
                  rows: data,
                });

                let lastRowNum = worksheet.lastRow.number;
                const lastTableRowNum = lastRowNum;

                for(let i = 0; i < lastTableRowNum; i++) {
                    const row = worksheet.getRow(i);
                    row.eachCell({ includeEmpty: true }, (cell => {
                        cell.alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }
                    }))
                }

                // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

                // // worksheet.getCell('A3').value = 'Kode'
                // // worksheet.getCell('B3').value = ': KPS-2-02'

                // worksheet.getCell('A4').value = 'Laporan'
                // worksheet.getCell('B4').value = ': DAFTAR PESERTA AKTIF'

                // worksheet.getCell('A5').value = 'Tanggal Laporan'
                // worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

                // worksheet.getCell('A5').value = 'Pemberi Kerja'
                // worksheet.getCell('B7').value = `: ${participantName}`
                
                // worksheet.getCell('A8').value = 'Produk'
                // worksheet.getCell('B8').value = `: ${productName}`

                // worksheet.getCell('A9').value = 'Tanggal Efektif'
                // worksheet.getCell('B9').value = `: ${filter.lessEfektifDate && filter.greaterEfektifDate ? (filter.greaterEfektifDate ? formatDefaultDate(filter.greaterEfektifDate) : "~") + ' S/D ' + (filter.lessEfektifDate ? formatDefaultDate(filter.lessEfektifDate) : "~") : 'Semua'}`

                worksheet.headerFooter.differentFirst = true;
                worksheet.headerFooter = {
                    firstHeader: `&C&BDAFTAR PESERTA AKTIF\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    oddHeader: `&C&BDAFTAR PESERTA AKTIF\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    evenHeader: `&C&BDAFTAR PESERTA AKTIF\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
                }

                worksheet.pageSetup = {
                    fitToPage: true,
                    margins: {
                        bottom: 0.75,
                        footer: 0.3,
                        header: 0.3,
                        left: 0.7,
                        right: 0.7,
                        top: 0.75
                    }
                }

                worksheet.mergeCells(`A${lastTableRowNum + 1}:M${lastTableRowNum + 1}`);
                worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
                worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell(`M${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
                worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

                worksheet.getCell(`N${lastTableRowNum + 1}`).value = (sumPhdp)
                worksheet.getCell(`N${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell(`N${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

                worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('J1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('K1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('L1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('M1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('N1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                // worksheet.getCell('O1').alignment = {vertical: 'middle', horizontal: 'center'};
                // worksheet.getCell('P1').alignment = {vertical: 'middle', horizontal: 'center'};
                // worksheet.getCell('Q1').alignment = {vertical: 'middle', horizontal: 'center'};

                for(var i = 2; i <= data.length + 1; i++){
                    worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left', wrapText: true};
                    worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('N'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                    // worksheet.getCell('O'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                    // worksheet.getCell('P'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    // worksheet.getCell('Q'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                }

                var WidthColums = 20;
                
                const Data1 = worksheet.getColumn(1);
                Data1.width = WidthColums;

                const Data2 = worksheet.getColumn(2);
                Data2.width = WidthColums;

                const Data3 = worksheet.getColumn(3);
                Data3.width = 30;

                const Data4 = worksheet.getColumn(4);
                Data4.width = WidthColums;

                const Data5 = worksheet.getColumn(5);
                Data5.width = WidthColums;

                const Data6 = worksheet.getColumn(6);
                Data6.width = WidthColums;

                const Data7 = worksheet.getColumn(7);
                Data7.width = WidthColums;

                const Data8 = worksheet.getColumn(8);
                Data8.width = 60;

                const Data9 = worksheet.getColumn(9);
                Data9.width = WidthColums;

                const Data10 = worksheet.getColumn(10);
                Data10.width = WidthColums;

                const Data11 = worksheet.getColumn(11);
                Data11.width = WidthColums;

                const Data12 = worksheet.getColumn(12);
                Data12.width = WidthColums;

                const Data13 = worksheet.getColumn(13);
                Data13.width = WidthColums;

                const Data14 = worksheet.getColumn(14);
                Data14.width = 30;
                Data14.numFmt = '#,##0.00';

                // const Data13 = worksheet.getColumn(13);
                // Data13.width = WidthColums;

                // const Data14 = worksheet.getColumn(14);
                // Data14.width = WidthColums;

                // const Data15 = worksheet.getColumn(15);
                // Data15.width = WidthColums;
                // Data15.numFmt = '#,##0.00';

                // const Data16 = worksheet.getColumn(16);
                // Data16.width = WidthColums;

                // const Data17 = worksheet.getColumn(17);
                // Data17.width = WidthColums;
                
                await workbook.xlsx.writeBuffer().then(function(buffer) {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        `DAFTAR PESERTA AKTIF.xlsx`
                    );
                });
            }catch(e){
                console.log(e)
            }
            store.dispatch(showLoading(false))

            // var headerNameMapping = [
            //     {
            //         from:"id",
            //         to:"No"
            //     },
            //     {
            //         from:"nik", 
            //         to:"NIK"
            //     },
            //     {
            //         from:"fullName", 
            //         to:"Nama"
            //     },
            //     {
            //         from:"birthPlace", 
            //         to:"Tempat Lahir"
            //     },
            //     {
            //         from:"birthDate", 
            //         to:"Tanggal Lahir"
            //     },
            //     {
            //         from:"jnsKel", 
            //         to:"Jenis Kelamin"
            //     },
            //     {
            //         from:"alamat", 
            //         to:"Alamat KTP"
            //     },
            //     {
            //         from:"mobileNo", 
            //         to:"No HP"
            //     },
            //     {
            //         from:"email", 
            //         to:"Email"
            //     },
            //     {
            //         from:"efektifDate", 
            //         to:"Tanggal Mulai Kerja"
            //     },
            //     {
            //         from:"statusKeluarga", 
            //         to:"Status Keluarga"
            //     },
            //     {
            //         from:"statusPajakId", 
            //         to:"Status Pajak"
            //     },
            //     {
            //         from:"unitKerjaName", 
            //         to:"Unit Kerja"
            //     },
            //     {
            //         from:"grade", 
            //         to:"Grade"
            //     },
            //     {
            //         from:"phdp", 
            //         to:"PHDP"
            //     },
            //     {
            //         from:"npwp", 
            //         to:"NPWP"
            //     },
            //     {
            //         from:"noKtp", 
            //         to:"NO KTP"
            //     },
            // ]

            // var header = [
            //     {
            //         A: 'DANA PENSIUN PEGADAIAN'
            //     },
            // ]
            
            // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

            // var title = [
            //     {
            //         A: 'Kode',
            //         B: null,
            //         C: ':',
            //         D: 'KPS-2-02'
            //     },
            //     {
            //         A: 'Laporan',
            //         B: null,
            //         C: ':',
            //         D: 'DAFTAR PESERTA AKTIF'
            //     },
            //     {
            //         A: 'Laporan',
            //         B: null,
            //         C: ':',
            //         D: formatDefaultDate(new Date())
            //     },
            // ]

            // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

            // var subTitle = [
            //     {
            //         A: 'Pemberi Kerja',
            //         B: null,
            //         C: ':',
            //         D: participantName
            //     },
            //     {
            //         A: 'Produk',
            //         B: null,
            //         C: ':',
            //         D: productName
            //     },
            //     {
            //         A: 'Tanggal Efektif',
            //         B: null,
            //         C: ':',
            //         D: filter.lessEfektifDate && filter.greaterEfektifDate ? (filter.greaterEfektifDate ? formatDefaultDate(filter.greaterEfektifDate) : "~") + 'S/D' + (filter.lessEfektifDate ? formatDefaultDate(filter.lessEfektifDate) : "~") : 'Semua'
            //     },
            // ]

            // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
            // var instructions = []
        
            // for(var InstructionData of getPesertaAktif){
            //     var row = {}
            //     for(var column in InstructionData){
            //         var headerMapping = headerNameMapping.find(value => value.from == column)
            //         if(headerMapping){
            //             row[headerMapping.to] = InstructionData[column]
            //         }
            //     }
            //     instructions.push(row)
            // }

            // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

            // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            // store.dispatch(showLoading(false))
            // generateExcelFile(excelBlob,'LAPORAN_PESERTA_AKTIF')
        } catch (e) {
            let store = this.props.store
            store.dispatch(showLoading(false))
            console.log(e)
        }
    }

    // centerLine = () => {
    //     return (
    //         <div>
    //             <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
    //                 <span>DAFTAR PESERTA AKTIF</span>
    //                 <span>DANA PENSIUN PEGADAIAN</span>
    //                 <span>Valuasi per {{tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,}}</span>
    //             </div>
    //         </div>
    //     )
    // }

    // exportExcelTemplate = async () => {
    //     let data = []
    //     let store = this.props.store
    //     store.dispatch(showLoading(true))
    //     var filter = this.state.formFilter
    //     var loadAPI = `view-laporan-peserta-aktifs?`

    //     if(filter.participantId){
    //         loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
    //     }

    //     if(filter.productId){
    //         loadAPI = loadAPI+`productId.in=${filter.productId}&`
    //     }
        
    //     if(filter.greaterEfektifDate){
    //         loadAPI = loadAPI+`efektifDate.greaterThanOrEqual=${formatDate(filter.greaterEfektifDate)}&`
    //     }

    //     if(filter.lessEfektifDate){
    //         loadAPI = loadAPI+`efektifDate.lessThanOrEqual=${formatDate(filter.lessEfektifDate)}&`
    //     }

    //     var getPesertaAktif = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')
    //     const loadAPIStatusPajak = `status-pajaks?size=99999`
    //     const getStatusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIStatusPajak, 'GET')

    //     var index = 1
    //     for(var value of getPesertaAktif){
    //         var dataValue = {
    //             id: index++,
    //             nik: value.nik,
    //             fullName: value.fullName,
    //             birthDate: value.birthDate ? formatDefaultDate(value.birthDate) : '-',
    //             efektifDate: value.efektifDate ? formatDefaultDate(value.efektifDate) : '-',
    //             statusKaryawanTanggal: value.statusKaryawanTanggal ? formatDefaultDate(value.statusKaryawanTanggal) : '-',
    //             grade: value.grade,
    //             alamat: value.alamat,
    //             statusPajakId: value.statusPajakId ? getStatusPajak.find(val => val.id === value.statusPajakId).statusName : '-',
    //             jnsKel: value.jnsKel,
    //             mobileNo: value.mobileNo,
    //             usia: (value.usia),
    //             mk: (value.mk),
    //             phdp: (value.phdp),
    //         }

    //         const objectValue = Object.values(dataValue)

    //         data.push(objectValue)
    //     }

    //     const res = await fetch('/file_upload/template_report_daftar_peserta_aktif.xlsx');
    //     const blob = await res.blob();

    //     const dataArrayBuffer = await blob.arrayBuffer();
    //     const workbook = new Excel.Workbook();
    //     await workbook.xlsx.load(dataArrayBuffer);

    //     const workSheetNames = 'Daftar Peserta Aktif';

    //     let workSheet = workbook.getWorksheet(workSheetNames);
        
    //     workSheet.addTable({
    //         name: 'MyTable',
    //         ref: 'A1',
    //         headerRow: true,
    //         totalsRow: false,
    //         style: {
    //             theme: 'TableStyleLight15',
    //             showFirstColumn: true,
    //             // border: {
    //             //     top: {style:'thin'},
    //             //     left: {style:'thin'},
    //             //     bottom: {style:'thin'},
    //             //     right: {style:'thin'}
    //             // }
    //         },
    //         columns: [
    //             {name: 'No'},
    //             {name: 'NIK'},
    //             {name: 'Nama'},
    //             {name: 'Tanggal Lahir'},
    //             {name: 'Tanggal Mulai Kerja'},
    //             {name: 'Tanggal Menjadi Karyawan Tetap'},
    //             {name: 'Grade'},
    //             {name: 'Alamat'},
    //             {name: 'Status Keluarga'},
    //             {name: 'Jenis Kelamin'},
    //             {name: 'No HP'},
    //             {name: 'Usia'},
    //             {name: 'Mk'},
    //             {name: 'PHDP'},
    //       ],
    //         rows: data,
    //     });

    //     workSheet.headerFooter.differentFirst = true;
    //     workSheet.headerFooter = {
    //         firstHeader: `&C&BDAFTAR PESERTA AKTIF\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
    //         oddHeader: `&C&BDAFTAR PESERTA AKTIF\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
    //         evenHeader: `&C&BDAFTAR PESERTA AKTIF\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
    //     }

    //     // workSheet.getCell('C4').value = this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null
    //     // workSheet.getCell('C4').alignment = {vertical: 'middle', horizontal: 'center'};

    //     // workSheet.getCell('M4').value = this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null
    //     // workSheet.getCell('M4').alignment = {vertical: 'middle', horizontal: 'center'};

    //     workSheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
    //     workSheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('J1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('K1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('L1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('M1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     workSheet.getCell('N1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     // workSheet.getCell('O1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     // workSheet.getCell('P1').alignment = {vertical: 'middle', horizontal: 'center'};
    //     // workSheet.getCell('Q1').alignment = {vertical: 'middle', horizontal: 'center'};

    //     for(var i = 2; i <= 10000; i++){
    //         workSheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
    //         workSheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
    //         workSheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left', wrapText: true};
    //         workSheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'center'};
    //         workSheet.getCell('N'+i).alignment = {vertical: 'middle', horizontal: 'right'};
    //         // workSheet.getCell('O'+i).alignment = {vertical: 'middle', horizontal: 'right'};
    //         // workSheet.getCell('P'+i).alignment = {vertical: 'middle', horizontal: 'left'};
    //         // workSheet.getCell('Q'+i).alignment = {vertical: 'middle', horizontal: 'left'};
    //     }

    //     var WidthColums = 20;
        
    //     const Data1 = workSheet.getColumn(1);
    //     Data1.width = WidthColums;

    //     const Data2 = workSheet.getColumn(2);
    //     Data2.width = WidthColums;

    //     const Data3 = workSheet.getColumn(3);
    //     Data3.width = 30;

    //     const Data4 = workSheet.getColumn(4);
    //     Data4.width = WidthColums;

    //     const Data5 = workSheet.getColumn(5);
    //     Data5.width = WidthColums;

    //     const Data6 = workSheet.getColumn(6);
    //     Data6.width = WidthColums;

    //     const Data7 = workSheet.getColumn(7);
    //     Data7.width = WidthColums;

    //     const Data8 = workSheet.getColumn(8);
    //     Data8.width = 60;

    //     const Data9 = workSheet.getColumn(9);
    //     Data9.width = WidthColums;

    //     const Data10 = workSheet.getColumn(10);
    //     Data10.width = WidthColums;

    //     const Data11 = workSheet.getColumn(11);
    //     Data11.width = WidthColums;

    //     const Data12 = workSheet.getColumn(12);
    //     Data12.width = WidthColums;

    //     const Data13 = workSheet.getColumn(13);
    //     Data13.width = WidthColums;

    //     const Data14 = workSheet.getColumn(14);
    //     Data14.width = 30;
    //     Data14.numFmt = '#,##0.00';
        
    //     store.dispatch(showLoading(false))
    //     await workbook.xlsx.writeBuffer().then(function(buffer) {
    //         saveAs(
    //             new Blob([buffer], { type: "application/octet-stream" }),
    //             `DAFTAR PESERTA AKTIF.xlsx`
    //         );
    //     });
    // }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Daftar Peserta Aktif </h4>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.formFilter}
                            items={this.filterItem}
                            labelLocation = 'left'
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'Preview'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPesertaAktif