import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { dummyData } from './dummyDataLaporanIuran';
import { formatNumber, formatNumberAum } from 'plugin/helper';
import { Form } from "devextreme-react";
// import { addURL } from 'redux/actions/url'
import { months } from 'dataSource/lookup';


class LaporanIuran extends React.Component {
    constructor(props){
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.state = {
            formData: {
                name: 'PT.KALA CKARA',
                sysDateNow: new Date(this.sysDate ? this.sysDate.dateValue : null),
                sysDateNext: this.date.setDate(this.date.getDate() + 1)
            }
        }

        this.dataGridRef = React.createRef();

        this.formItem = [
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'name',
                        label: {
                            text: 'PEMBERI KERJA'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'sysDateNow',
                        label: {
                            text: 'PERIODE'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'sysDateNext',
                        label: {
                            text: 's/d'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    }
                ]
            }
        ]

        this.columns = [
            {
                dataField: 'id',
                caption: 'NO'
            },
            {
                dataField: 'periode',
                caption: 'PERIODE',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tahun',
                        caption: 'TAHUN'
                    },
                    {
                        dataField: 'bulan',
                        caption: 'BULAN'
                    }
                ]
            },
            {
                dataField: 'iuran',
                caption: 'IURAN',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'jatuhTempo',
                        caption: 'JATUH TEMPO',
                        alignment: 'right'
                    },
                    {
                        dataField: 'pembayaran',
                        caption: 'PEMBAYARAN',
                        alignment: 'right'
                    },
                    {
                        dataField: 'sisaPiutang',
                        captions: 'SISA PIUTANG',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'shu',
                caption: 'SHU',
                alignment: 'CENTER',
                columns: [
                    {
                        dataField: 'orangShuSpi',
                        caption: 'ORANG',
                        alignment: 'right'
                    },
                    {
                        dataField: 'jumlahShu',
                        caption: 'JUMLAH',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'spi',
                caption: 'SPI',
                alignment: 'CENTER',
                columns: [
                    {
                        dataField: 'orangShuSpi',
                        caption: 'ORANG',
                        alignment: 'right'
                    },
                    {
                        dataField: 'jumlahShu',
                        caption: 'JUMLAH',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'pembayaranMp',
                caption: 'PEMBAYARAN MP',
                alignment: 'CENTER',
                columns: [
                    {
                        dataField: 'orangMpOther',
                        caption: 'ORANG',
                        alignment: 'right'
                    },
                    {
                        dataField: 'jumlahMpOther',
                        caption: 'JUMLAH',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'mpOther',
                caption: 'MP OTHER',
                alignment: 'CENTER',
                columns: [
                    {
                        dataField: 'orangMpOther',
                        caption: 'ORANG',
                        alignment: 'right'
                    },
                    {
                        dataField: 'jumlahMpOther',
                        caption: 'JUMLAH',
                        alignment: 'right'
                    }
                ]
            }
        ]
    }

    // componentWillUnmount = () => {
    //     let store = this.props.store;
    //     store.dispatch(addURL(this.props.location.pathname))
    // }

    loadDummyData = () => {
        let loadDummyData = dummyData;
        let data = [];

       for (let value of loadDummyData){
        var dataValue = {
                id: value.id,
                tahun: value.tahun,
                bulan: value.bulan,
                jatuhTempo: formatNumber(value.jatuhTempo),
                pembayaran: formatNumber(value.pembayaran),
                sisaPiutang: value.sisaPiutang ? formatNumberAum(value.sisaPiutang) : 0,
                orangShuSpi: value.orangShuSpi ? formatNumberAum(value.orangShuSpi) : 0,
                jumlahShu: value.jumlahShu ? formatNumberAum(value.jumlahShu) : 0,
                jumlahSpi: value.jumlahSpi ? formatNumberAum(value.jumlahSpi) : 0,
                orangMpOther: value.orangMpOther ? formatNumberAum(value.orangMpOther) : 0,
                jumlahMpOther: value.jumlahMpOther ? formatNumberAum(value.jumlahMpOther) : 0,
            }
        data.push(dataValue);
       }
       return data;
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">DANA PENSIUN CAKRA TEKNOLOGI</h2>
                {/* <p className="sub-title">Pemberi Kerja &nbsp;&nbsp;: PT.Kala Cakra</p> */}
                <div className='row'>
                    <div className='col-md-6'>
                        <Form 
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.formData}
                            items={this.formItem}
                            labelLocation="left"
                        />
                    </div>
                {/* <h5 className="sub-title">Periode &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 1 Januari 2022 s/d 31 Juli 2022</h5> */}
                <div className='mt-2 col-md-12'>
                    <DevExpressDataGrid
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI=''

                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                        useArraySource = {true}
                        ArraySourceData = {this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Laporan Iuran"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Instruksi Bank'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
            </div>
            </div>
        )
    }
}

export default LaporanIuran;