import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react';
import { tipeTransaksiSdmUmum, kasUmum, sdmUnit, currency, tipeSubTransaksiSdmUmum, programSdm } from 'dataSource/lookup';
import { download, formatDate, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { FileUploader, ScrollView } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import KUPengajuanPembayaranActionDetail from './actionDetail';
import { alert, confirm } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import notify from "devextreme/ui/notify";

class ModalUmumPembayaranPengajuan extends Component {
    constructor(props) {
        super(props)
        this.userLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null

        this.state = {
            formData: {
                currencyId:1001,
                kurs:1,
            },
            popupVisible: false,
        }

        this.formRef = React.createRef()
        this.actionDetailRef = React.createRef()
        this.DataGridDetailRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.detailPembayaran = []
        this.maxPembayaranKasUmum = 0
        this.addData = []

        this.dataGridDetail = []
        
        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                // onClick: this.submitData.bind(this)
                onClick: () => {
                    var formValidation = this.validate();
                    if (formValidation){
                        this.submitData();
                    }
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.dataMaster = [
            {
                dataField:'programId',
                label:{
                    alignment:'left',
                    text:'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                },
            },
            {
                dataField:'kasUmumId',
                label:{
                    text:'Kas Umum'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: kasUmum(this.props.store),
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'namaKas', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: async(e) => {
                        // if (e.selectedItem) {
                        //     this.maxPembayaranKasUmum = e.selectedItem.maksimalPembayaran
                        //     this.formRef.current.instance.updateData({maksimalPembayaran:e.selectedItem.maksimalPembayaran,saldo:e.selectedItem.saldo})
                        // }

                        // if (e.selectedItem) {
                        //     this.maxPembayaranKasUmum = e.selectedItem.saldo
                        //     this.formRef.current.instance.updateData({maksimalPembayaran:e.selectedItem.maksimalPembayaran,saldo:e.selectedItem.saldo})
                        // }

                        var data = e.selectedItem;
                        if (data){
                            let getSelectedData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-umum-payments/getByMultipleStatusId/1?size=999', 'GET');
                            let filterByKode = getSelectedData.filter(value => value.kasUmumId == data.id);
                            console.log(filterByKode);

                            this.maxPembayaranKasUmum = filterByKode.map(item => item.totalPaymentNominal).reduce((sum, item) => sum + item, 0);
                            
                            console.log(this.maxPembayaranKasUmum);

                            this.formRef.current.instance.updateData({maksimalPembayaran:e.selectedItem.maksimalPembayaran,saldo:e.selectedItem.saldo});
                        }
                    }
                }
            },
            {
                dataField: 'noPembayaran',
                label : {
                    text: 'No Pembayaran'
                },
                editorOptions : {
                    readOnly : true
                }
            },
            {
                dataField:'tanggalPembayaran',
                label:{
                    text:'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Tanggal Pembayaran Harap Diisi"
                    }
                ]
            },
            {
                dataField:'userName',
                label:{
                    text:'Diajukan Oleh'
                },
                editorOptions: {
                    readOnly : true,
                    // value:this.props.store.getState().sessionUser
                },
            },
            {
                dataField:'unitKerjaId',
                label:{
                    text:'Unit'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly : true
                }
            },
            {
                dataField:'maksimalPembayaran',
                label:{
                    text:'Max Pembayaran'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format:'#,##0.00',
                    readOnly:true
                }
            },
            {
                dataField:'saldo',
                label:{
                    text:'Saldo'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format:'#,##0.00',
                    readOnly:true
                }
            },
            {
                dataField:'catatanPembayaran',
                label:{
                    text:'Catatan Pembayaran'
                },
                editorType: 'dxTextArea',
                validationRules: [
                    {
                        type: "required",
                        message: "Catatan Pembayaran Harap Diisi"
                    }
                ]
            },
            {
                itemType: 'empty',
            },
            {
                dataField:'currencyId',
                label:{
                    text:'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true,
                    onSelectionChange : (e) =>{
                        if(e.selectedItem){
                            var data = {
                                currencyRate : e.selectedItem.rate
                            }
                            this.formRef.current.instance.updateData(data)
                        }
                    }
                }
            },
            {
                dataField:'kurs',
                label:{
                    text:'Kurs'
                },
                editorOptions:{
                    readOnly:true,
                }
            },
        ]

        this.dataMaster2 = [
            {
                dataField:'programId',
                label:{
                    alignment:'left',
                    text:'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                },
            },
            // {
            //     dataField:'kasUmumId',
            //     label:{
            //         text:'Kas Umum'
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         // dataSource: kasUmum(this.props.store),
            //         dataSource: [],
            //         valueExpr: 'id',
            //         displayExpr: 'namaKas', // provides display values
            //         searchEnabled: true,
            //         deferRendering: false,
            //         onSelectionChanged: async(e) => {
            //             // if (e.selectedItem) {
            //             //     this.maxPembayaranKasUmum = e.selectedItem.maksimalPembayaran
            //             //     this.formRef.current.instance.updateData({maksimalPembayaran:e.selectedItem.maksimalPembayaran,saldo:e.selectedItem.saldo})
            //             // }

            //             // if (e.selectedItem) {
            //             //     this.maxPembayaranKasUmum = e.selectedItem.saldo
            //             //     this.formRef.current.instance.updateData({maksimalPembayaran:e.selectedItem.maksimalPembayaran,saldo:e.selectedItem.saldo})
            //             // }

            //             var data = e.selectedItem;
            //             if (data){
            //                 let getSelectedData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-umum-payments/getByMultipleStatusId/1?size=999', 'GET');
            //                 let filterByKode = getSelectedData.filter(value => value.kasUmumId == data.id);
            //                 console.log(filterByKode);

            //                 this.maxPembayaranKasUmum = filterByKode.map(item => item.totalPaymentNominal).reduce((sum, item) => sum + item, 0);
                            
            //                 console.log(this.maxPembayaranKasUmum);

            //                 this.formRef.current.instance.updateData({maksimalPembayaran:e.selectedItem.maksimalPembayaran,saldo:e.selectedItem.saldo});
            //             }
            //         }
            //     }
            // },
            {
                dataField:'kasUmumId',
                label:{
                    text:'Kas Umum'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kasUmum(this.props.store),
                    // dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'namaKas', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'noPembayaran',
                label : {
                    text: 'No Pembayaran'
                },
                editorOptions : {
                    readOnly : true
                }
            },
            {
                dataField:'tanggalPembayaran',
                label:{
                    text:'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
            },
            {
                dataField:'userName',
                label:{
                    text:'Diajukan Oleh'
                },
                editorOptions: {
                    readOnly : true,
                    // value:this.props.store.getState().sessionUser
                },
            },
            {
                dataField:'unitKerjaId',
                label:{
                    text:'Unit'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly : true
                }
            },
            {
                dataField:'maksimalPembayaran',
                label:{
                    text:'Max Pembayaran'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format:'#,##0.00',
                    readOnly:true
                }
            },
            {
                dataField:'saldo',
                label:{
                    text:'Saldo'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format:'#,##0.00',
                    readOnly:true
                }
            },
            {
                dataField:'catatanPembayaran',
                label:{
                    text:'Catatan Pembayaran'
                },
                editorType: 'dxTextArea',
                validationRules: [
                    {
                        type: "required",
                        message: "Catatan Pembayaran Harap Diisi"
                    }
                ]
            },
            {
                itemType: 'empty',
            },
            {
                dataField:'currencyId',
                label:{
                    text:'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true,
                    onSelectionChange : (e) =>{
                        if(e.selectedItem){
                            var data = {
                                currencyRate : e.selectedItem.rate
                            }
                            this.formRef.current.instance.updateData(data)
                        }
                    }
                }
            },
            {
                dataField:'kurs',
                label:{
                    text:'Kurs'
                },
                editorOptions:{
                    readOnly:true,
                }
            },
        ]

        this.detailColumn = [
            {
                type: 'buttons',
                disabled:this.props.from == 'riwayat' ? true : false,
                buttons: [
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.deleteDataGrid(this.DataGridDetailRef,e.row.data.id)
                        }
                    },
                ],
            },
            {
                dataField: 'tipeTransaksiId',
                caption: 'Tipe Transaksi',
                lookup:{
                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'transactionTypeName' 
                }
            },
            {
                dataField: 'subTipeTransaksi',
                caption: 'Sub Tipe Transaksi',
                lookup:{
                    dataSource: tipeSubTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'tipeSubName' 
                }
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField:"nominalPembayaran",
                caption:"Nominal Pembayaran",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            // {
            //     dataField:"kasUmumPayment",
            //     caption:"Kas Umum Payment",
            //     dataType: 'number',
            //     alignment : 'right',
            //     format: '#,##0'
            // },
            {
                dataField:"ppn",
                caption:"PPN",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"pph",
                caption:"PPH",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"biayaLain",
                caption:"Biaya Lain",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"potongan",
                caption:"Potongan",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"nominalBersih",
                caption:"Jumlah",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
        ]

        this.detailColumn2 = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Lihat',
                        hint : 'Lihat',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data, true)
                        }
                    },
                ],
            },
            {
                dataField: 'tipeTransaksiId',
                caption: 'Tipe Transaksi',
                lookup:{
                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'transactionTypeName' 
                }
            },
            {
                dataField: 'subTipeTransaksi',
                caption: 'Sub Tipe Transaksi',
                lookup:{
                    dataSource: tipeSubTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'tipeSubName' 
                }
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField:"nominalPembayaran",
                caption:"Nominal Pembayaran",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            // {
            //     dataField:"kasUmumPayment",
            //     caption:"Kas Umum Payment",
            //     dataType: 'number',
            //     alignment : 'right',
            //     format: '#,##0'
            // },
            {
                dataField:"ppn",
                caption:"PPN",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"pph",
                caption:"PPH",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"biayaLain",
                caption:"Biaya Lain",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"potongan",
                caption:"Potongan",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
            {
                dataField:"nominalBersih",
                caption:"Jumlah",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0',
                step: 0
            },
        ]

        this.sumKas = [
            {
                showInColumn: 'keterangan',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'nominalPembayaran',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalPembayaran',
                displayFormat: '{0}'
            },
            {
                column: 'ppn',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'ppn',
                displayFormat: '{0}'
            },
            {
                column: 'pph',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'pph',
                displayFormat: '{0}'
            },
            {
                column: 'biayaLain',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'biayaLain',
                displayFormat: '{0}'
            },
            {
                column: 'potongan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'potongan',
                displayFormat: '{0}'
            },
            {
                column: 'nominalBersih',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'nominalBersih',
                displayFormat: '{0}'
            },
        ]
    }

    getKasUmumByUserLogin = async () => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umums?size=9999`, 'GET')
        data = data.filter(value => value.otoritasUser == this.userLoged && value.saldo > 0)
        // console.log(data.filter(value => value.saldo > 0));
        this.formRef.current.instance.getEditor('kasUmumId').option('dataSource', data)
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async () => {   
        var data = this.state.formData
        var kskUrl = 'kas-bank-transactions/getByMultipleStatusIdAndKasUmumIdAndKasUmumId/3/'+data.kasUmumId
        var getKsk = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,kskUrl,'GET')
        if(getKsk.length == 0){
            var validation = await alert('Kas Umum ini tidak ada pengisian kas yang aktif, silahkan ajukan pengisian kas terlebih dahulu', 'Informasi') 
            if(validation){
                return false
            }
        }
        var detailData = this.DataGridDetailRef.current.getDataSource()
        for(var detil of detailData){
            console.log(detil);
            if(detil.id){
                if(detil.id.length === 99){
                    detil.id = 0
                }
                if(!detil.nominalPembayaran){
                    detil.nominalPembayaran = 0
                }
                if(!detil.nominalBersih){
                    detil.nominalBersih = 0
                }
                if(!detil.ppn){
                    detil.ppn = 0
                }
                if(!detil.pph){
                    detil.pph = 0
                }
                if(!detil.biayaLain){
                    detil.biayaLain = 0
                }
                if(!detil.potongan){
                    detil.potongan = 0
                }
            }
        }

        // if (sumPaymentNominalNet > this.maxPembayaranKasUmum) {
        //     await alert("Jumlah tidak boleh lebih besar dari Max Pembayaran","Informasi")
        //     return
        // }
        
        data.dikeluarkanOleh = data.userName
        data.tanggalPembayaran = formatDate(data.tanggalPembayaran)
        data.paymentDate = data.tanggalPembayaran
        data.transactioDate = data.tanggalPembayaran
        var pengajuanData = {
            ...data,
            "detailBiayaDTOList": detailData,
        }

        var sumPaymentNominalNet = this.sumValue(detailData, 'nominalBersih')
    
        console.log(sumPaymentNominalNet);
        console.log(this.maxPembayaranKasUmum);

        if (this.maxPembayaranKasUmum === data.saldo) {
            await alert('Nominal Pengajuan Sudah Mencapai Maksimal Saldo!', 'Informasi')
            return
        }

        if (sumPaymentNominalNet + this.maxPembayaranKasUmum > data.saldo) {
            await alert("Jumlah tidak boleh lebih besar dari Max Saldo","Informasi")
            return
        }

        try {
            var insertAPI = 'kas-umum-payments-simpan' 
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, insertAPI, 'POST', {
                    values:pengajuanData
            })

            if (response) {
                var title = 'Berhasil!'
                var text = 'Data Berhasil Ditambahkan!'

                await alert(text, title)
                this.props.forceRefresh()
                this.hide()
            }
            var title = 'Berhasil!'
            var text = 'Data Berhasil Ditambahkan!'
        } catch(e) {
            console.log(e)
            var title = 'Gagal!'
            var text = e
            await alert(text, title)
        }
    }

    updateData = async () => {
        var formData = this.state.formData
        var detailData = this.DataGridDetailRef.current.getDataSource()
        var sumTotalPembayaran = this.sumValue(detailData, 'nominalPembayaran')
        var sumPPN = this.sumValue(detailData, 'ppn')
        var sumPPH = this.sumValue(detailData, 'pph')
        var sumBiaya = this.sumValue(detailData, 'biayaLain')
        var sumPotongan = this.sumValue(detailData, 'potongan')
        var sumPaymentNominalNet = this.sumValue(detailData, 'nominalBersih')

        formData.totalPaymentNominal = sumTotalPembayaran
        formData.totalPpn = sumPPN
        formData.totalPph = sumPPH
        formData.totalBiaya = sumBiaya
        formData.totalPotongan = sumPotongan
        formData.paymentNominalNett = sumPaymentNominalNet
        formData.keterangan = formData.catatanPembayaran
        formData.fileDocument = formData.documentFile
        formData.fileName = formData.documentName
        formData.paymentDate = formData.tanggalPembayaran
        formData.transactioDate = formData.tanggalPembayaran
        // if (sumPaymentNominalNet > this.maxPembayaranKasUmum) {
        //     await alert("Jumlah tidak boleh lebih besar dari Max Saldo","Informasi")
        //     return
        // }

        if (sumPaymentNominalNet > formData.saldo) {
            await alert("Jumlah tidak boleh lebih besar dari Max Saldo","Informasi")
            return
        }

        try {
            var insertAPI = 'kas-umum-payments' 
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, insertAPI, 'PUT', {
                key: formData.id,
                values:formData
            }, formData)

            if (response) {
                try {
                    for(var detil of detailData){
                        if(typeof detil.id === 'string'){
                            delete detil.id
                            detil.kasUmumPaymentId =  response.id
                            detil.transactionTypeId = detil.tipeTransaksiId
                            detil.transactionTypeSubId = detil.subTipeTransaksi
                            detil.kasUmumId = response.kasUmumId
                            detil.pengajuanNominal = detil.nominalPembayaran
                            detil.pengajuanNominalNett = detil.nominalBersih
                            
                            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'kas-umum-payment-details/simpanWithKbt', 'POST', {
                                values:detil
                            })
                        }else{
                            detil.kasUmumPaymentId =  response.id
                            detil.transactionTypeId = detil.tipeTransaksiId
                            detil.transactionTypeSubId = detil.subTipeTransaksi
                            detil.kasUmumId = response.kasUmumId
                            detil.pengajuanNominal = detil.nominalPembayaran
                            detil.pengajuanNominalNett = detil.nominalBersih
                            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'kas-umum-payment-details', 'PUT', {
                                key: detil.id,
                                values:detil
                            }, detil)
                        }
                    }

                    // Delete Pengajuan Detil
                    var getDeletedDetil = this.DataGridDetailRef.current.getDeletedIndex()
                    for(var dataDelete of getDeletedDetil){
                        await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'kas-umum-payment-details', 'DELETE', dataDelete)
                    }
                } catch (e) {
                    console.log(e)
                    var title = 'Gagal Simpan Detil'
                    var text = e
                }
            }
            var title = 'Berhasil!'
            var text = 'Data Berhasil Diubah!'
        } catch (e) {
            console.log(e)
            var title = 'Gagal!'
            var text = e
        }

        await alert(text, title)
        this.props.forceRefresh()
        this.forceRefresh()
        this.hide()
    }

    sumValue = (items, prop) => {
        return items.reduce( function(a, b){
            return a + b[prop];
        }, 0);
    }

    showModalDetail = (data , fromRiwayat) => {
        this.actionDetailRef.current.show(fromRiwayat)
        if(data){
            this.actionDetailRef.current.retrieveData(data)
        }
    }

    editDataGrid = async (data) => {
        var dataGrid = this.DataGridDetailRef
        console.log(data);
        try{
            var key = data.id
            if(key){
                dataGrid.current.push([
                    {
                    type: 'update',
                    data : data,
                    key : key
                    }
                ])
            }else{
                dataGrid.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
            }
        
        var title = 'Berhasil!'
        var text = 'Data Berhasil Ditambahkan!'
        
        }catch(e){
            console.log(e)
            var title = 'Gagal!'
            var text = e
        }
        await alert(text, title)
    }

    // editDataGrid = async (data) => {
    //     try {
    //         var key = data.id;

    //         if (key) {
    //             this.addData.push(
    //                 {
    //                     type: 'update',
    //                     data: data,
    //                     key: key
    //                 }
    //             )
    //             notify({ message: "Sukses Update Data", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    //         } else {
    //             this.addData.push(
    //                 {
    //                     type: 'insert',
    //                     data: data
    //                 }
    //             )
    //             notify({ message: "Sukses Input Data", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    //         }
    //         this.DataGridDetailRef.current.push(this.addData);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    
    deleteDataGrid = async(dataGrid, key) => {
        dataGrid.current.push([
            {
                type: 'remove',
                key : key
            }
        ])
        var title = 'Berhasil!'
        var text = 'Data Berhasil Dihapus!'

        await alert(text, title)
    }

    retrieveData = async (data) => {
        var getDetails = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `kas-umum-payment-details/getByKasUmumPaymentId/${data.id}`, 'GET')
        var detailList = []
        for(var detail of getDetails){
            detail.tipeTransaksiId = detail.transactionTypeId
            detail.subTipeTransaksi = detail.transactionTypeSubId
            detail.nominalPembayaran = detail.pengajuanNominal
            detail.nominalBersih = detail.pengajuanNominalNett
            detailList.push(detail)
        }

        // var kasUmumId = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `kas-umums/${data.kasUmumId}`, 'GET');

        this.detailPembayaran = detailList
        data.unitKerjaId = data.unitId
        data.noPembayaran = data.paymentNo
        data.tanggalPembayaran = data.transactioDate
        data.userName = data.paymentBy
        data.catatanPembayaran = data.keterangan
        data.kurs = data.currencyRate
        data.kasUmumId = data.kasUmumId
        this.setState({
            formData: data,
        })
    }
    
    getUnitUserLogin = async() => {
        try {
            var getkaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawans/getByUsername/${this.props.store.getState().sessionUser}`, 'GET')
            if (getkaryawan.length !== 0) {
                this.formRef.current.instance.updateData({unitKerjaId:getkaryawan.unitId,userName:getkaryawan.fullName})
            }
        } catch (e) {
            this.formRef.current.instance.updateData({unitKerjaId:null,userName:null})
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.maxPembayaranKasUmum = 0
        this.detailPembayaran = []
        this.DataGridDetailRef.current.forceRefresh(true)
        this.setState({
            formData: {
                currencyId:1001,
                kurs:1,
            },
            popupVisible: false,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
                
                this.setState(prevState =>({
                    formData: {
                        ...prevState.formData,
                        [`${fieldName}Name`]: files.name,
                        [`${fieldName}Extension`]: formatNameAndExtention.extention,
                        [`${fieldName}Document`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}Document`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}Document`]),this.state.dataMaster[`${fileName}Name`])
    }

    retrieveDataDetil = async() => {
        return this.detailPembayaran
    }

    onToolbarPreparingDetail = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick:() => { 
                        this.showModalDetail()
                    }
                }
            }, 
        )
    }
    
    forceRefresh = () => {
        this.DataGridDetailRef.current.forceRefresh(true)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={'Form Pengajuan Pembayaran'}
                minWidth={700}
                minHeight={760}
                toolbarItems={this.props.action == "add" ? this.PopupToolbarItem : this.props.action == 'edit' ? this.PopupToolbarItem2 : []}
                ref={this.PopupRef}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formPotongan'}
                                formData={this.state.formData}
                                items={this.props.from == 'riwayat' ? this.dataMaster2 : this.dataMaster}
                                labelLocation='left'
                                readOnly = {this.props.from == 'riwayat' ? true : false}
                            />
                        </div>
                        <div className="col-md-6">
                            <FileUploader 
                                uploadMode={'useForm'}
                                onValueChanged={this.onUploadStarted.bind(this)} 
                                labelText={this.state.formData.fileName ||'File'}
                                showFileList={false}
                                name={'file'}
                                disabled = {this.props.from == 'riwayat' ? true : false}
                            />
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.formData.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                            <button onClick={() => this.downloadFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.formData.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Download
                            </button>
                        </div>
                        <div className="col-md-12 mt-5">
                            <DevExpressDataGrid 
                                ref = {this.DataGridDetailRef}
                                loadAPI = 'kas-umum-payment-details'
                                insertAPI = 'kas-umum-payment-details'
                                updateAPI = 'kas-umum-payment-details'
                                deleteAPI = 'kas-umum-payment-details'

                                backendserver = {process.env.REACT_APP_BACKEND_HRD}
                                
                                useArraySource={true}
                                ArraySourceData={this.retrieveDataDetil}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                showBorders = {true}

                                paging = {true}
                                defaultPageSize = {10}
                                
                                editingMode={'popup'}
                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle = {'Data Detail Biaya'}
                                popupWidth = {500} //masukan dalam ukuran pixel
                                popupHeight = {400} //masukkan dalam ukuran pixel

                                popupFormLabelLocation = 'left' //accepted value = top, left, right
                                popupFormMinColWidth = {300} // minimum lebar kolom
                                popupFormColCount = {1} //jumlah kolom pada form
                                height={400}
                                //akhir bagian konfigurasi popup

                                ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

                                FilterRow = {true} // set false untuk mematikan fitur filter
                                
                                ColumnConfiguration={this.props.from == 'riwayat' ? this.detailColumn2 : this.detailColumn} // taruh konfigurasi kolom disini
                                onToolbarPreparing = {this.props.from == 'riwayat' ? {} : this.onToolbarPreparingDetail}
                                summaryTotalItem = {this.sumKas}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                                
                                store = {this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
                <KUPengajuanPembayaranActionDetail
                    ref={this.actionDetailRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                    editDataGrid = {this.editDataGrid}
                />
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default ModalUmumPembayaranPengajuan