import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import ModalAlokasi from 'components/pages/modal/investasi/pengaturan/aset-alocation/alokasi';
import ModalKategoriAsetAlokasi from 'components/pages/modal/investasi/pengaturan/aset-alocation/kategori/index';
import ModalActionKategoriAsetAlokasi from 'components/pages/modal/investasi/pengaturan/aset-alocation/kategori/action';
import ModalSektorAsetAlokasi from 'components/pages/modal/investasi/pengaturan/aset-alocation/sektor/index';
import ModalActionSektorAsetAlokasi from 'components/pages/modal/investasi/pengaturan/aset-alocation/sektor/action';
import ModalActionEmitenAsetAlokasi from 'components/pages/modal/investasi/pengaturan/aset-alocation/emiten/action';
import { addURL } from 'redux/actions/url';

class AsetAlocation extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }

        this.modalAlokasiRef = React.createRef()
        this.modalKategoriAsetAlokasiRef = React.createRef()
        this.modalActionKategoriAsetAlokasiRef = React.createRef()
        this.modalEmitenAsetAlokasiRef = React.createRef()
        this.modalActionEmitenAsetAlokasiRef = React.createRef()
        this.modalSektorAsetAlokasiRef = React.createRef()
        this.modalActionSektorAsetAlokasiRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalAlokasi()
                        }
                    }
                ],
            },
            {
                dataField: 'produk',
                caption: 'Product',
            },
            {
                dataField: 'kpd',
                caption: 'KPD',
            },
            {
                dataField: 'alokasi',
                caption: 'Alokasi',
                alignment:'center',
                columns: [
                    {
                        dataField: 'kategori',
                        caption: 'Kategori'
                    },
                    {
                        dataField: 'emiten',
                        caption: 'Emiten'
                    },
                    {
                        dataField: 'sektor',
                        caption: 'Sektor'
                    },
                ]
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    loadDummyData = () => {
        var dummy = [
            {
                id: 1,
                produk : 'PPMP',
                kpd: 'Swakelola',
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
            {
                id: 2,
                produk : 'PPMP',
                kpd: 'BNP Paribas',
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
            {
                id: 3,
                produk : 'PPIP-L',
                kpd: 'Swakelola',
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
        ]


        return dummy
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Alokasi',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalAlokasi()
                    },
                }
            }
        )
    }

    showModalAlokasi = () => {
        this.modalAlokasiRef.current.show()
    }

    showModalKategoriAsetAlokasi = () => {
        this.modalKategoriAsetAlokasiRef.current.show()
    }
    showModalActionKategoriAsetAlokasi = () => {
        this.modalActionKategoriAsetAlokasiRef.current.show()
    }

    showModalActionEmitenAsetAlokasi = () => {
        this.modalActionEmitenAsetAlokasiRef.current.show()
    }

    showModalSektorAsetAlokasi = () => {
        this.modalSektorAsetAlokasiRef.current.show()
    }
    showModalActionSektorAsetAlokasi = () => {
        this.modalActionSektorAsetAlokasiRef.current.show()
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Aset Alocation</h2>
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='aset-alocation'
                    insertAPI='aset-alocation'
                    updateAPI='aset-alocation'
                    deleteAPI='aset-alocation' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Aset Alocation"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aset Alocation'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalAlokasi 
                    ref={this.modalAlokasiRef}
                    store={this.props.store}
                    showModalKategoriAsetAlokasi = {this.showModalKategoriAsetAlokasi}
                    showModalActionEmitenAsetAlokasi = {this.showModalActionEmitenAsetAlokasi}
                    showModalSektorAsetAlokasi = {this.showModalSektorAsetAlokasi}
                    showModalActionSektorAsetAlokasi = {this.showModalActionSektorAsetAlokasi}
                />

                <ModalKategoriAsetAlokasi 
                    ref={this.modalKategoriAsetAlokasiRef}
                    store={this.props.store}
                    showModalActionKategoriAsetAlokasi = {this.showModalActionKategoriAsetAlokasi}
                />
                <ModalActionKategoriAsetAlokasi 
                    ref={this.modalActionKategoriAsetAlokasiRef}
                    store={this.props.store}
                />

                <ModalSektorAsetAlokasi 
                    ref={this.modalSektorAsetAlokasiRef}
                    store={this.props.store}
                    showModalActionSektorAsetAlokasi = {this.showModalActionSektorAsetAlokasi}
                />
                <ModalActionSektorAsetAlokasi 
                    ref={this.modalActionSektorAsetAlokasiRef}
                    store={this.props.store}
                />

                <ModalActionEmitenAsetAlokasi 
                    ref={this.modalActionEmitenAsetAlokasiRef}
                    store={this.props.store}
                />
                
            </div>
        )
    }
}

export default AsetAlocation