import React, { Component } from 'react'
import DataGridAktivasiAkun from './comp/dataGrid'
import { addURL } from 'redux/actions/url'
import ShowAktivasiAkun from 'components/pages/modal/kepesertaan/pesertaDapen/aktif/showAktivasiAkun'
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import { pushNotification } from 'plugin/helper';

class RiwayatAktivasiAkun extends Component {
    constructor(props) {
        super(props)

        this.DataGridRef = React.createRef()
        this.popupModalAktivasiAkunRef = React.createRef()
    }
    
    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalAktivasiAkun = async(visitorId) => {
        var DataVisitor = {}
        try {
            var loadAPI = `visitors/${visitorId}`
            var getVisitor = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPI, 'GET')

            DataVisitor = getVisitor
        } catch (e) {
            console.log(e)
        }

        this.popupModalAktivasiAkunRef.current.show()
        this.popupModalAktivasiAkunRef.current.retrieveData(getVisitor)
    }

    updateStatus = async(data,statusType) => {
        var loadAPI = `visitors/${data.id}`                    
        var getVisitor = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPI, 'GET')

        var loadAPIPeserta = `pesertas/getByNik/${getVisitor.nik}`                    
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')

        var DataVisitor = getVisitor
        DataVisitor.memberStatus = statusType

        try {
            var updateAPI = 'visitors'
            var response = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, updateAPI, 'PUT', {
                    key: DataVisitor.id,
                    values:DataVisitor
                },
            DataVisitor)
            
            var verification = statusType == 'VERIFIED' ? 'Diterima' : 'Ditolak'
            var reffNo = ("0" + response.id).slice(-5);
            pushNotification(`Aktivasi Akun Anda ${verification} ! ${data.description || ""}`,5,`PD${reffNo}`, getPeserta.id)

            var text = `Data berhasil ${verification}`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }
    forceRefresh = () => {
        this.DataGridRef.current.forceRefresh()
    }
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Riwayat Aktivasi Akun Online</h3>
                <div className={' mt-3 text-center' } style={{height: '100%'}}>
                    <DataGridAktivasiAkun store = {this.props.store} ref={this.DataGridRef} updateStatus={this.updateStatus} showModalAktivasiAkun={this.showModalAktivasiAkun} />
                </div>
                <ShowAktivasiAkun
                    ref = {this.popupModalAktivasiAkunRef}
                    store = {this.props.store}
                    submitData = {this.submitData}
                    updateStatus={this.updateStatus}
                    forceRefresh = {this.forceRefresh}
                    type = 'riwayat'
                />
            </div>
        )
    }
}

export default RiwayatAktivasiAkun
