import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Form } from 'devextreme-react'
import { addURL } from 'redux/actions/url'
import FormModalRegistrasiAset from 'components/pages/modal/sdm-umum/umum/aset/registrasiAset'
import { asetKategoriSdm, kategoriPengadaanSdm, subAsetKategoriSdm } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDefaultFullDate, formatNumberAum } from 'plugin/helper'
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';

class DaftarAset extends Component {
    constructor(props){
        super(props)

        this.state = {
            filter:{}
        }

        this.filterItem = [
            {
                dataField:'tahunPengadaan',
                label:{
                    text:'Tahun Pengadaan',
                },
                editorOptions:{
                    onValueChanged:(e) => {
                        console.log(e);
                    }
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalDaftarAset(e)
                        }
                    },
                ]
            },
            {
                dataField: 'assetKode',
                caption: 'Kode Aset',
            },
            {
                dataField: 'assetName',
                caption: 'Nama Aset',
            },
            {
                dataField: 'barcodeNumber',
                caption: 'Bar Code',
            },
            {
                dataField: 'noPengadaan',
                caption: 'No Pengadaan',
            },
            {
                dataField: 'tanggalPerolehan',
                caption: 'Tanggal Perolehan',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField: 'aset',
                caption: 'Aset',
                alignment:'center',
                columns:[
                    {
                        dataField:'assetCategoryId',
                        caption:'Jenis Aset',
                        lookup : {
                            dataSource: asetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName',
                        },
                    },
                    {
                        dataField:'assetCategorySubId',
                        caption:'Tipe Aset',
                        lookup : {
                            dataSource: subAsetKategoriSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categorySubName',
                        },
                    },
                    {
                        dataField:'spesifikasi',
                        caption:'Spesifikasi Aset'
                    },
                ]
            },
            {
                dataField: 'nilaiAset',
                caption: 'Nilai Aset',
                alignment:'center',
                columns:[
                    {
                        dataField:'nilaiPerolehan',
                        caption:'Nilai Perolehan',
                        format: '#,##0.00;(#,##0.00);'
                    },
                    {
                        dataField:'akumPenyusutan',
                        caption:'Akumulasi Penyusutan',
                        format: '#,##0.00;(#,##0.00);',
                        calculateCellValue: (rowData) => {
                            return(rowData.nilaiPerolehan - rowData.nilaiBuku)
                        }
                    },
                    {
                        dataField:'nilaiBuku',
                        caption:'Nilai Buku',
                        format: '#,##0.00;(#,##0.00);'
                    },
                ]
            },
            {
                dataField: 'penempatan',
                caption: 'Penempatan',
                alignment:'center',
                columns:[
                    {
                        dataField:'unitId',
                        caption:'Unit Kerja'
                    },
                    {
                        dataField:'karyawanFullName',
                        caption:'Karyawan'
                    },
                ]
            },
        ]

        this.showModalDaftarAsetRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalDaftarAset = (e) => {
        if (e.row) {
            this.showModalDaftarAsetRef.current.retrieveData(e.row.data)
        }
        this.showModalDaftarAsetRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalDaftarAset(e)
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    // loadData = async () => {
    //     var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'assets?size=99999', 'GET')
    //     console.log(response);

    //     return response
    // }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-assets?size=9999', 'GET');
        let store = this.props.store;
        store.dispatch(showLoading(true));

        for (let value of response){
            let dataAssetCategory = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-categories/${value.assetCategoryId}`, 'GET');
            let dataSubAssetCategory = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-category-subs/${value.assetCategorySubId}`, 'GET');
            let dataValue = {
                assetKode: value.assetKode,
                assetName: value.assetName,
                barcodeNumber: value.barcodeNumber ? value.barcodeNumber : '-',
                noPengadaan: value.noPengadaan ? value.noPengadaan : '-',
                tanggalPerolehan: value.tanggalPerolehan ? formatDefaultFullDate(value.tanggalPerolehan) : '-',
                assetCategoryId: dataAssetCategory.categoryName,
                assetCategorySubId: dataSubAssetCategory.categorySubName,
                spesifikasi: value.spesifikasi ? value.spesifikasi : '-',
                nilaiPerolehan: value.nilaiPerolehan ? formatNumberAum(value.nilaiPerolehan) : 0,
                akumPenyusutan: value.nilaiPerolehan - value.nilaiBuku ? formatNumberAum(value.nilaiPerolehan - value.nilaiBuku) : 0,
                nilaiBuku: value.nilaiBuku ? formatNumberAum(value.nilaiBuku) : 0,
                unitId: value.unitId ? value.unitId : '-',
                karyawanFullName: value.karyawanFullName ? value.karyawanFullName : '-'
            }
        const objectValue = Object.values(dataValue);
        data.push(objectValue);
        }

       try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR PENSIUN DITUNDA');
            worksheet.addTable({
            name: 'MyTable',
            ref: `A${firstTableRowNum}`,
            headerRow: true,
            totalsRow: false,
            style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
            },
            columns: [
                {name: 'Kode Aset'},
                {name: 'Nama Aset'},
                {name: 'Bar Code'},
                {name: 'No Pengadaan'},
                {name: 'Tanggal Perolehan'},
                {name: 'Jenis Aset'},
                {name: 'Tipe Aset'},
                {name: 'Spesifikasi Aset'},
                {name: 'Nilai Perolehan'},
                {name: 'Akumulasi Penyusutan'},
                {name: 'Nilai Buku'},
                {name: 'Unit Kerja'},
                {name: 'Karyawan'},
            ],
            rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'DAFTAR ASET';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 25;
            var WidthColums2 = 35;
                
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = 60;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 13;

            const Data4 = worksheet.getColumn(4);
            Data4.width = 13;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums2;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums2;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR_ASSET.xlsx`
                );
            });
       } catch (error) {
            console.log(error);
       }
       store.dispatch(showLoading(false))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Aset</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.filter}
                    items={this.filterItem}
                    labelLocation={'left'}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='view-assets'
                        insertAPI='assets'
                        updateAPI='assets'
                        deleteAPI='assets'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}
                        // useArraySource={true}
                        // ArraySourceData={this.loadData}

                        remoteOperations = {true}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Aset"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Aset'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalRegistrasiAset 
                        ref={this.showModalDaftarAsetRef}
                        store={this.props.store}
                        forceRefresh = {this.forceRefresh}
                    />
                </div>
            </div>
        )
    }
}

export default DaftarAset