import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { months, product } from 'dataSource/lookup';
import ModalTambahMpBerkala from 'components/pages/modal/kepesertaan/pengkinianPeserta/berkala/tambah';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import PembayaranMpFileSkModal from 'components/pages/modal/kepesertaan/pengkinianPeserta/berkala/fileSk';
import ModalEditMpBerkala from 'components/pages/modal/kepesertaan/pengkinianPeserta/berkala/edit';
import { showLoading } from 'redux/actions/loading';
import { formatNumberAum } from 'plugin/helper';

class KenaikanBerkalaMp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterItem: {}
        }

        this.modalTambahMpBerkalaRef = React.createRef()
        this.modalViewMpBerkalaRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalFileSKRef = React.createRef()
        
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalView(e.row.data)
                        }
                    },
                    {
                        text: 'File SK',
                        hint: 'File SK',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalFileSK(e.row.data)
                        }
                    },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Proses Pengkinian Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.proses(e.row.data.id)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Membatalkan Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        // this.batal(e.row.data.id)
                                        this.batal(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'pengajuanDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'periodeBulan',
                caption: 'Bulan Efektif',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'periodeTahun',
                caption: 'Tahun Efektif'
            },
            {
                dataField: 'prosesDate',
                caption: 'Tanggal Proses',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'persenKenaikan',
                caption: 'Persen Kenaikkan',
                alignment: 'right',
                // format: '#0,## %',
                cellRender: (e) => {
                    let persenKenaikan = e.row.data.persenKenaikan;
                    let intValue = parseInt(persenKenaikan);
            
                    // Format the number as a percentage
                    let formattedPercentage = (intValue / 100).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 });
            
                    return formattedPercentage;
                }
            },
            {
                dataField: 'skNo',
                caption: 'No SK'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Open'
                        },
                        {
                            id: 2,
                            value: 'Efektif'
                        },
                        {
                            id: 3,
                            value: 'Batal'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
        this.filterItem = [
            {
                dataField: "tanggal1",
                label: {
                    text: "Dari",
                    alignment: "left",
                    location: "left"
                },
            },
            {
                dataField: "tanggal2",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Kenaikan Berkala',
                    onClick: (e) => {
                        this.showModalInsert(e)
                    }
                }
            }
        )
    }

    showModalView = (data) => {
        this.modalViewMpBerkalaRef.current.show()
        this.modalViewMpBerkalaRef.current.retrieveData(data)
    }

    showModalFileSK = (data) => {
        this.modalFileSKRef.current.show()
        this.modalFileSKRef.current.retrieveData(data)
    }

    showModalInsert = () => {
        this.modalTambahMpBerkalaRef.current.show()
    }

    proses = async(mpBerkalaId) => {
        try {
            var data = {
                // id: mpBerkalaId,
                kenaikanBerkalaMpId : mpBerkalaId
            }
            var postAPI = 'proses-kenaikan-berkala-mp'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
                values: data
            })

            await alert('Data Berhasil Diproses !','Success')
            this.forceRefresh()
        } catch (e) {
            await alert(e,'Failed')
        }
    }

    batal = async(dataMpBerkala) => {
        try {
            // var loadAPIDelete = `kenaikan-berkala-mps`
            // await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIDelete, 'DELETE',mpBerkalaId)

            let updateStatusId = dataMpBerkala.statusId;
    
            updateStatusId = 3;

            let dataValue = {
                "statusId": updateStatusId,
            }

            let loadAPI = `kenaikan-berkala-mps`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: dataMpBerkala.id,
                values: dataValue
            }, dataMpBerkala);
           
            console.log(response)
            if (response) {
                await alert('Data Berhasil Diproses !','Success')
                this.forceRefresh()
            }
        } catch (e) {
            await alert(e,'Failed')
        }
    }
    insertData = async(data) => {
        let store = this.props.store
        delete data.kenaikanPeriode
        data.periodeBulan += 1
        try {
            store.dispatch(showLoading(true))
            var postAPI = 'simpan-kenaikan-berkala-mp'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
                values: data
            })
            var interval = setInterval(async() => { 
                try {
                    var loadAPI = `kenaikan-berkala-mp-detils/countByKenaikanBerkalaMpId/${response.id}`
                    var getIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')  
                    if(getIuran !== 0){
                        clearInterval(interval)
                        store.dispatch(showLoading(false))
                        await alert('Data Berhasil Ditambah !','Success')
                        this.forceRefresh()
                    }
                } catch (e) {
                    console.log(e)
                }

            }, 5000);
        } catch (e) {
            await alert(e,'Failed')
        }
    }

    updateData = async(data) => {
        try {
            var updateAPI = 'kenaikan-berkala-mps'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                key: data.id,
                values: data
            },data)

            await alert('Data Berhasil Dirubah !','Success')
            this.forceRefresh()
        } catch (e) {
            await alert(e,'Failed')
        }
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Kenaikan MP Berkala</h2>
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='kenaikan-berkala-mps/getByStatus/1'
                    insertAPI='kenaikan-berkala-mps'
                    updateAPI='kenaikan-berkala-mps'
                    deleteAPI='kenaikan-berkala-mps'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Kenaikan Berkala Manfaaat Pensiun"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Kenaikan Berkala Manfaaat Pensiun Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalTambahMpBerkala
                    ref={this.modalTambahMpBerkalaRef}
                    store={this.props.store}
                    submitData = {this.insertData}
                />
                <ModalEditMpBerkala
                    ref={this.modalViewMpBerkalaRef}
                    store={this.props.store}
                    actionType = {'edit'}
                />
                <PembayaranMpFileSkModal
                    ref={this.modalFileSKRef}
                    store={this.props.store}
                    submitData = {this.updateData}
                />
            </div>
        )
    }
}

export default KenaikanBerkalaMp