import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalPengajuanPenghapusanAset from 'components/pages/modal/sdm-umum/umum/penghapusan/pengajuanPenghapusan';
import FormModalPenjualanAset from 'components/pages/modal/sdm-umum/umum/penghapusan/penjualanAset';
import FormModalRiwayatPenghapusanAset from 'components/pages/modal/sdm-umum/umum/penghapusan/riwayatPenghapusan';
import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';

class RiwayatPenghapusanAset extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalVerifikasiManager(e)
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField:'noPengajuan',
                caption:'No Pengajuan'
            },
            {
                dataField:'noRegistrasiAset',
                caption:'No Registrasi Aset'
            },
            {
                dataField:'namaAset',
                caption:'Nama Aset'
            },
            {
                dataField:'diajukanOleh',
                caption:'Diajukan Oleh'
            },
            {
                dataField:'tanggalPengajuan',
                caption:'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'keterangan',
                caption:'Keterangan'
            },
            {
                dataField:'verifiedBy',
                caption:'Diverifikasi Oleh'
            },
            {
                dataField:'verifiedDate',
                caption:'Tanggal Verifikasi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'keteranganVerifikasi',
                caption:'Keterangan Verifikasi'
            },
            {
                dataField:'approvedBy',
                caption:'Disetujui Oleh'
            },
            {
                dataField:'approvedDate',
                caption:'Tanggal Persetujuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'keteranganPersetujuan',
                caption:'Keterangan Persetujuan'
            },
            {
                dataField:'tipePenghapusan',
                caption:'Tipe Penghapusan'
            },
            {
                dataField: 'aset',
                caption: 'Aset',
                alignment:'center',
                columns:[
                    {
                        dataField:'groupAset',
                        caption:'Group Aset'
                    },
                    {
                        dataField:'jenisAset',
                        caption:'Jenis Aset'
                    },
                    {
                        dataField:'tipeAset',
                        caption:'Tipe Aset'
                    },
                    {
                        dataField:'spesifkasiAset',
                        caption:'Spesifikasi Aset'
                    },
                    {
                        dataField:'tanggalPengadaan',
                        caption:'Tanggal Pengadaaan'
                    },
                    {
                        dataField:'nominalPembelian',
                        caption:'Nominal Pembelian'
                    },
                ]
            },
            {
                dataField: 'penempatan',
                caption: 'Penempatan',
                alignment:'center',
                columns:[
                    {
                        dataField:'unitKerja',
                        caption:'Unit Kerja'
                    },
                    {
                        dataField:'karyawan',
                        caption:'Karyawan'
                    },
                ]
            },
            {
                dataField: 'nilaiEkonomi',
                caption: 'Nilai Ekonomi',
                alignment:'center',
                columns:[
                    {
                        dataField:'umurAset',
                        caption:'Umur Aset'
                    },
                    {
                        dataField:'nilaiBuku',
                        caption:'Nilai Buku'
                    },
                ]
            },
            {
                dataField:'keterangan',
                caption:'Keterangan'
            },
        ]

        this.showModalVerifikasiManagerRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalVerifikasiManager = (e) => {
        if (e.row) {
            this.showModalVerifikasiManagerRef.current.retrieveData(e.row.data)
        }
        this.showModalVerifikasiManagerRef.current.show()
    }


    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-penghapusan-aset-aktifs', 'GET')
        response = response.filter(value => value.statusId == 5)

        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Riwayat Penghapusan Aset</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='daftar-penagjuan-pengadaan'
                    insertAPI='daftar-penagjuan-pengadaan'
                    updateAPI='daftar-penagjuan-pengadaan'
                    deleteAPI='daftar-penagjuan-pengadaan'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Penghapusan Aset"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Penghapusan Aset'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    // onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPengajuanPenghapusanAset 
                    ref={this.showModalVerifikasiManagerRef}
                    store={this.props.store}
                    type={'riwayat'}
                />
            </div>
        )
    }
}

export default RiwayatPenghapusanAset