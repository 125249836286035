import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';

import axios from 'axios';
import initSubscriber from 'redux-subscriber';
import { menuRole } from './components/pages/parts/menurole'

// import logo from './logo.svg';
// import './App.css';

import { sessionToken, sessionRole, sessionUser } from './redux/actions/session';
import axiosException from "./plugin/exceptionmessage";
import { httpRequest } from 'plugin/httprequest'
import { getParam } from 'redux/actions/gblparam';
import { showLoading } from "redux/actions/loading";

//components
import Header from './components/headerComponent/header';
import Navbar from './components/headerComponent/navbar';
// import Footer from './components/footerComponent/footer';
import Loading from './components/pages/parts/loading';

//pages
import Dashboard from './components/pages/dashboard/index';
import Login from './components/pages/login';
import TopMenu from './components/headerComponent/topmenu';
import Rekonsiliasi from 'components/pages/aktuaria/valuasi/rekonsiliasi/index';
import AsetPendanaan from 'components/pages/aktuaria/valuasi/asetPendanaan/index';
import KewajibanDepan from 'components/pages/aktuaria/valuasi/kewajibanDapen/index';
import SkorAktuaria from 'components/pages/aktuaria/valuasi/skorAktuaria/index';
import UserGroup from 'components/pages/administrator/userGroup/index';
import User from 'components/pages/administrator/user/index';
import AuditLog from 'components/pages/administrator/auditLog/index';
import CpObligasi from 'components/pages/static-data/closing-price/obligasi';
import CpSaham from 'components/pages/static-data/closing-price/saham';
import CpReksadana from 'components/pages/static-data/closing-price/reksadana';
import Bank from 'components/pages/static-data/bank';
import Kustodi from 'components/pages/static-data/kustodi';
import Currency from 'components/pages/static-data/currency';
import Broker from 'components/pages/static-data/broker';
import InstruSaham from 'components/pages/static-data/instruSaham';
import InstruDepo from 'components/pages/static-data/instruDepo';
import InstruObligasi from 'components/pages/static-data/instruObligasi';
import InstruReksadana from 'components/pages/static-data/instruReksadana';
import JurnalAkutansi from 'components/pages/akutansi/jurnal/index';
import PengajuanPenempatan from 'components/pages/investasi/pengajuanPenempatan/index';
import PorfolioPasarModal from 'components/pages/investasi/portfolioPasarModal/index';
import DaftarRiwayatPegawai from 'components/pages/sdm-umum/daftar-riwayat-pegawai/index';
import DataKaryawan from 'components/pages/sdm-umum/administrasi/data-karyawan/index';
import Gaji from 'components/pages/sdm-umum/administrasi/gaji/index';
import Lembur from 'components/pages/sdm-umum/administrasi/lembur/index';
import DataSDM from 'components/pages/sdm-umum/data-sdm/index';
import PendaftaranPeserta from 'components/pages/kepesertaan/pendaftaranPeserta';
import PesertaAktif from 'components/pages/kepesertaan/pesertaAktif';
import KlaimManfaatPensiun from 'components/pages/kepesertaan/pesertaPasif/klaimManfaatPensiun';
import Province from 'components/pages/static-data/province';
import Country from 'components/pages/static-data/country';
import City from 'components/pages/static-data/city';
import ApprovalList from 'components/pages/administrator/approvalList/index';
import PostalCode from 'components/pages/static-data/postalCode';
import Participant from 'components/pages/static-data/participant';
import GlobalParameter from 'components/pages/global-setting/global-parameter/index';
import JournalEditor from 'components/pages/akutansi/jurnal/journaleditor';
import Branch from 'components/pages/static-data/branch';
import Article from 'components/pages/static-data/article';
import Redaksi from 'components/pages/static-data/redaksi';
import Visitor from 'components/pages/static-data/visitor';
import FiscalYear from 'components/pages/static-data/fiscalYear';
import PersiapanPensiun from 'components/pages/kepesertaan/pesertaDapen/aktif/persiapanPensiun/index';
import AkunPensiunanPasif from 'components/pages/kepesertaan/pesertaDapen/pasif/akunPensiunanPasif/index';
import PPIP from 'components/pages/kepesertaan/pesertaDapen/akunPeserta/ppip/index';
import RegistrasiAktif from 'components/pages/kepesertaan/pesertaDapen/aktif/registrasi/index';
import PPMP from 'components/pages/kepesertaan/pesertaDapen/akunPeserta/ppmp/index';
import RegistrasiPasif from 'components/pages/kepesertaan/pesertaDapen/pasif/registrasi/index';
import Emiten from 'components/pages/static-data/emiten';
import Tax from 'components/pages/static-data/tax';
import PeriodeConfig from 'components/pages/static-data/periodeConfig';
import Education from 'components/pages/static-data/education';
import Grade from 'components/pages/static-data/grade';
import GajiPotongan from 'components/pages/static-data/gajiPotongan';
import GajiKomponen from 'components/pages/static-data/gajiKomponen';
import ManagerInvestasi from 'components/pages/static-data/managerInvestasi';
import Instrument from 'components/pages/static-data/instrument';
import MatchingProses from 'components/pages/kepesertaan/penerimaanIuran/matchingProses/index';
import TransaksiIuran from 'components/pages/kepesertaan/penerimaanIuran/transaksiIuran/index';
import RegistrasiPengkinianAktif from 'components/pages/kepesertaan/pengkinianPeserta/aktif/registrasi/index';
import RegistrasiPengkinianPasif from 'components/pages/kepesertaan/pengkinianPeserta/pasif/registrasi/index';
import Obligasi from 'components/pages/investasi/order/obligasi/index';
import OrderReksadana from 'components/pages/investasi/order/reksadana/index';
import OrderDeposito from 'components/pages/investasi/order/deposito/index';
import OrderSaham from 'components/pages/investasi/order/saham/index';
import TransaksiPembayaranMpBulanan from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/bulanan/index';
import PengajuanManfaatLainnya from 'components/pages/kepesertaan/pembayaranMp/manfaatLainnya/pengajuan/index'
import TransaksiPembayaranMpManfaatLain from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/manfaatLainnya/index';
import TransaksiPembayaranMpSekaligus from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/sekaligus/index';
import FixedAsset from 'components/pages/akutansi/fixedAsset/index';
import ChartOfAccount from 'components/pages/static-data/chartOfAccount';
import Department from 'components/pages/static-data/department';
import Holiday from 'components/pages/static-data/holiday';
import Project from 'components/pages/static-data/project';
import ExchangeRate from 'components/pages/static-data/exchangeRate';
import Vendor from 'components/pages/static-data/vendor';
import JournalVoucher from 'components/pages/akutansi/jurnal/journal-voucher/index';
import FixedAssetGroup from 'components/pages/akutansi/fixedAssetGroup/index';
import JurnalManual from 'components/pages/akutansi/jurnal/jurnal-manual/index';
import JurnalReccuring from 'components/pages/akutansi/jurnal/reccuring/index';
import ApprovalHistory from 'components/pages/administrator/approvalHistory/index';
import CheckerList from 'components/pages/administrator/checkerList/index';
import AsetAlocation from 'components/pages/investasi/pengaturan/aset-alocation/index';
import TipeCuti from 'components/pages/sdm-umum/pengaturan/cuti/tipeCuti';
import PeriodeCuti from 'components/pages/sdm-umum/pengaturan/cuti/periodeCuti';
import Identity from 'components/pages/static-data/identity';
import AgendaBisnis from 'components/pages/static-data/agendaBisnis';
import KPD from 'components/pages/investasi/pengaturan/kpd/index';
import PortfolioRebalance from 'components/pages/investasi/portfolio/rebalance/index';
import TipeDokumen from 'components/pages/sdm-umum/pengaturan/dokumen/tipeDokumen';
import TipeSK from 'components/pages/sdm-umum/pengaturan/dokumen/tipeSk';
import SdmJabatan from 'components/pages/sdm-umum/pengaturan/pekerjaan/jabatan';
import TitleKaryawan from 'components/pages/sdm-umum/pengaturan/pekerjaan/title';
import CorporateSchedule from 'components/pages/investasi/corporate-action/schedule/index';
import CashDeviden from 'components/pages/investasi/corporate-action/cash-deviden/index';
import StockDeviden from 'components/pages/investasi/corporate-action/stock-deviden/index';
import StockReverse from 'components/pages/investasi/corporate-action/stock-reverse/index';
import StockSplit from 'components/pages/investasi/corporate-action/stock-split/index';

import JamKerjaShift from 'components/pages/sdm-umum/pengaturan/jam-kerja/shift';
import JamKerjaLembur from 'components/pages/sdm-umum/pengaturan/jam-kerja/lembur';
import SettlementTransaksiObligasi from 'components/pages/investasi/settlement/konfirmasi/obligasi/index';
import SettlementTransaksiDeposito from 'components/pages/investasi/settlement/konfirmasi/deposito/index';
import Keahlian from 'components/pages/sdm-umum/pengaturan/kualifikasi/keahlian';
import Pendidikan from 'components/pages/sdm-umum/pengaturan/kualifikasi/pendidikan';
import Sertifikasi from 'components/pages/sdm-umum/pengaturan/kualifikasi/sertifikasi';
import Bahasa from 'components/pages/sdm-umum/pengaturan/kualifikasi/bahasa';
import TipePerjalananDinas from 'components/pages/sdm-umum/pengaturan/perjalanan-dinas/tipePerjalananDinas';
import TipeBiayaPerjalananDinas from 'components/pages/sdm-umum/pengaturan/perjalanan-dinas/tipeBiayaPerjalanan';
import BiayaPerjalananDinas from 'components/pages/sdm-umum/pengaturan/perjalanan-dinas/biayaPerjalananDinas';
import DaftarKaryawan from 'components/pages/sdm-umum/kepegawaian/karyawan/daftar-karyawan/index';
import KaryawanGaji from 'components/pages/sdm-umum/kepegawaian/karyawan/gaji/index';
import DokumenKaryawan from 'components/pages/sdm-umum/kepegawaian/karyawan/dokumen/index';
import PinjamanKaryawan from 'components/pages/sdm-umum/kepegawaian/karyawan/pinjaman/index';
import SuratKeputusanKaryawan from 'components/pages/sdm-umum/kepegawaian/karyawan/surat-keputusan/index';
import ShiftKaryawan from 'components/pages/sdm-umum/kepegawaian/karyawan/shift/index';
import KaryawanPesertaDapen from 'components/pages/sdm-umum/kepegawaian/pensiun-iuran/dapen/index';
import StatusKaryawan from 'components/pages/sdm-umum/pengaturan/pekerjaan/statusKaryawan';
import Tunjangan from 'components/pages/sdm-umum/pengaturan/gaji/tunjangan';
import Pinjaman from 'components/pages/sdm-umum/pengaturan/gaji/pinjaman';
import PengajuanThr from 'components/pages/sdm-umum/payroll/thr/pengajuanThr';
import SettlementTransaksiReksadana from 'components/pages/investasi/settlement/konfirmasi/reksadana/index';
import SettlementTransaksiSaham from 'components/pages/investasi/settlement/konfirmasi/saham/index';
import SettlementPenyelesaianAlokasiUnit from 'components/pages/investasi/settlement/penyelesaian/alokasi-unit/index';
import KepegawaianIuranDapen from 'components/pages/sdm-umum/kepegawaian/pensiun-iuran/iuran-dapen/index';
import DaftarTransaksiThr from 'components/pages/sdm-umum/payroll/thr/daftarTransaksiThr';
import KepegawaianPengajuanCuti from 'components/pages/sdm-umum/kepegawaian/cuti/pengajuan-cuti/index';
import KepegawaianDaftarCuti from 'components/pages/sdm-umum/kepegawaian/cuti/daftar-cuti/index';
import KepegawaianDaftarLembur from 'components/pages/sdm-umum/kepegawaian/lembur/daftar-lembur/index';
import PengajuanBonusTahunan from 'components/pages/sdm-umum/payroll/bonusTahunan/pengajuanBonusTahunan';
import DaftarTransaksiBonus from 'components/pages/sdm-umum/payroll/bonusTahunan/daftarTransaksiBonus';
import ProsesIuran from 'components/pages/kepesertaan/penerimaanIuran/prosesIuran/index';
import PengajuanGaji from 'components/pages/sdm-umum/payroll/gajiKaryawan/pengajuanGaji';
import DaftarTransaksiPenggajian from 'components/pages/sdm-umum/payroll/gajiKaryawan/daftarTransaksiPenggajian';
import DaftarTransaksiGaji from 'components/pages/sdm-umum/payroll/gajiKaryawan/daftarTransaksiGaji';
import BalancePortfolioDetailInstrument from 'components/pages/investasi/portfolio/balance-position/detail/index';
import KepegawaianPenugasan from 'components/pages/sdm-umum/kepegawaian/perjalanan-dinas/penugasan/index';
import KepegawaianPerjalananDinas from 'components/pages/sdm-umum/kepegawaian/perjalanan-dinas/perjalanan-dinas/index';
import PortofolioKPD from 'components/pages/investasi/portfolio/balance-position/kpd';
import BalancePositionResume from 'components/pages/investasi/portfolio/balance-position/resume/index';
import Kecamatan from 'components/pages/static-data/kecamatan';
import Kelurahan from 'components/pages/static-data/kelurahan';
import ClientPemberiKerja from 'components/pages/static-data/clientPemberiKerja';
import ManfaatPensiun from 'components/pages/static-data/manfaatPensiun';
import Jabatan from 'components/pages/static-data/jabatan';
import AgendaType from 'components/pages/static-data/agendaType';
import KategoriAsset from 'components/pages/static-data/kategoriAsset';
import KJPP from 'components/pages/static-data/kjpp';
import TipePembayaran from 'components/pages/static-data/tipePembayaran';
import TipeTransaksiSdm from 'components/pages/static-data/tipeTransaksiSdm';
import TipeTransaksiInvestasi from 'components/pages/static-data/tipeTransaksiInvest';
import TipeTransaksiPeserta from 'components/pages/static-data/tipeTransaksiPeserta';
import Sektor from 'components/pages/static-data/sektor';
import TransaksiMpDitunda from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/ditunda/index';
import RiwayatPengkinianAktif from 'components/pages/kepesertaan/pengkinianPeserta/aktif/riwayat/index';
import RiwayatPengkinianPasif from 'components/pages/kepesertaan/pengkinianPeserta/pasif/riwayat/index';
import KenaikanBerkalaMp from 'components/pages/kepesertaan/pengkinianPeserta/berkala/kenaikan-mp/index';
import DaftarUlangPengkinianPasif from 'components/pages/kepesertaan/pengkinianPeserta/pasif/daftar-ulang/index';
import PembayaranPenyelesaianTransaksi from 'components/pages/investasi/settlement/penyelesaian/pembayaran/index';
import PenerimaanPenyelesaianTransaksi from 'components/pages/investasi/settlement/penyelesaian/penerimaan/index';
import AktivasiAkun from 'components/pages/kepesertaan/pesertaDapen/aktif/aktivasiAkun/index';
import CoaGroup from 'components/pages/static-data/coaGroup';
import JournalType from 'components/pages/static-data/journalType';
import ForexBalancingConfig from 'components/pages/global-setting/forexBalancingConfig/index';
import CashFlowConfig from 'components/pages/global-setting/cashFlowConfig/index';
import KlaimMpProses from 'components/pages/kepesertaan/pembayaranMp/klaimMp/klaimProses';
import GeneralLedger from 'components/pages/akutansi/ledger/index';
import ReportJurnal from 'components/pages/akutansi/report-jurnal/index';
import ProfitLoss from 'components/pages/akutansi/report-jurnal/profitLoss';
import TrialBalance from 'components/pages/akutansi/report-jurnal/trialBalance';
import BalanceSheet from 'components/pages/akutansi/report-jurnal/balanceSheet';
import BalanceSheetDetail from 'components/pages/akutansi/report-jurnal/balanceSheetDetail';
import BalanceSheetDetail2 from 'components/pages/akutansi/report-jurnal/balanceSheetDetail2';
import CashFlow from 'components/pages/akutansi/report-jurnal/cashFlow';
import CashFlowAuto from 'components/pages/akutansi/report-jurnal/cashFlowAuto';
import PosProfitLoss from 'components/pages/akutansi/report-jurnal/posProfitLoss';
import RasioKeuangan from 'components/pages/akutansi/report-jurnal/rasioKeuangan';
import CashFlowDetail from 'components/pages/akutansi/report-jurnal/cashFlowDetils';
import LaporanOJK from 'components/pages/akutansi/report-jurnal/ojk';
import KPA from 'components/pages/investasi/pengaturan/kpa/index';
import InvoiceKpa from 'components/pages/investasi/pengaturan/invoice-kpa/index';
import FixedAssetDisposal from 'components/pages/akutansi/fixedAssetDisposal/index';
import ClosingPriceObligasi from 'components/pages/investasi/settlement/closing-price/obligasi/index';
import ClosingPriceNab from 'components/pages/investasi/settlement/closing-price/nab/index';
import TransaksiProperti from 'components/pages/investasi/settlement/konfirmasi/properti/index';
import TransaksiPenyertaan from 'components/pages/investasi/settlement/konfirmasi/penyertaan/index';
import ValuasiProperti from 'components/pages/investasi/settlement/valuasi/properti/index';
import ValuasiPenyertaan from 'components/pages/investasi/settlement/valuasi/penyertaan/index';
import OperasionalPemeliharaan from 'components/pages/investasi/settlement/transaksi-aset-properti/pemeliharaan/index';
import OperasionalPenyewaan from 'components/pages/investasi/settlement/transaksi-aset-properti/sewa/index';
import PortofolioMaturity from 'components/pages/investasi/portfolio/maturity/index';
import PengkinianPengajuanOnline from 'components/pages/kepesertaan/pengkinianPeserta/online/pengajuan/index';
import PengkinianRiwayatOnline from 'components/pages/kepesertaan/pengkinianPeserta/online/riwayat/index';
import PengajuanPenempatanSaham from 'components/pages/investasi/pengajuan/saham/penempatan'
import PengajuanPelepasanSaham from 'components/pages/investasi/pengajuan/saham/pelepasan/index';
import PengajuanPenempatanDeposito from 'components/pages/investasi/pengajuan/deposito/penempatan/index';
import PengajuanPelepasanDeposito from 'components/pages/investasi/pengajuan/deposito/pelepasan/index';
import PengajuanPenempatanReksadana from 'components/pages/investasi/pengajuan/reksadana/penempatan/index';
import PengajuanPelepasanReksadana from 'components/pages/investasi/pengajuan/reksadana/pelepasan/index';
import PengajuanPenempatanObligasiKorporasi from 'components/pages/investasi/pengajuan/obligasi-korporasi/penempatan/index'
import PengajuanPelepasanObligasiKorporasi from 'components/pages/investasi/pengajuan/obligasi-korporasi/pelepasan/index';
import PengajuanPenempatanMediumTermNote from 'components/pages/investasi/pengajuan/mtn/penempatan/index';
import PengajuanPelepasanMediumTermNote from 'components/pages/investasi/pengajuan/mtn/pelepasan/index';
import PengajuanPenempatanProperti from 'components/pages/investasi/pengajuan/properti/penempatan/index';
import PengajuanPelepasanProperti from 'components/pages/investasi/pengajuan/properti/pelepasan/index';
import PengajuanPenempatanSbn from 'components/pages/investasi/pengajuan/sbn/penempatan/index';
import PengajuanPelepasanSbn from 'components/pages/investasi/pengajuan/sbn/pelepasan/index';
import IuranKepesertaan from 'components/pages/akutansi/piutang/iuran-kepesertaan/index';
import TransaksiInvestasi from 'components/pages/akutansi/piutang/transaksi-investasi/index';
import PiutangLain from 'components/pages/akutansi/piutang/piutang-dll/index';
import PiutangPinjaman from 'components/pages/akutansi/piutang/pinjaman/index';
import JatuhTempo from 'components/pages/akutansi/hutang/jatuh-tempo/index';
import PembayaranMp from 'components/pages/akutansi/hutang/pembayaranMP/index';
import Supplier from 'components/pages/akutansi/hutang/supplier/index';
import Pajak from 'components/pages/akutansi/hutang/pajak/index';
import HutangLain from 'components/pages/akutansi/hutang/hutang-lain/index';
import HutangGaji from 'components/pages/akutansi/hutang/hutang-gaji/index';
import BankInstruction from 'components/pages/akutansi/bank-instruction/index';
import BankReconcile from 'components/pages/akutansi/bank-reconcile/index';
import InstruksiBankAktif from 'components/pages/investasi/instruksi/bank/aktif/index';
import InstruksiBankTerkirim from 'components/pages/investasi/instruksi/bank/terkirim/index';
import Product from 'components/pages/static-data/product';
import InstruksiBrokerAktif from 'components/pages/investasi/instruksi/broker/aktif/index';
import LaporanHasilInvestasi from 'components/pages/investasi/laporan/finansial-produk/hasil-investasi/index';
import LaporanAsetNeto from 'components/pages/investasi/laporan/finansial-produk/aset-neto/index';
import LaporanPerubahanAsetNeto from 'components/pages/investasi/laporan/finansial-produk/perubahan-aset-neto/index';
import LaporanNeraca from 'components/pages/investasi/laporan/finansial-produk/neraca/index';
import SebaranRisiko from 'components/pages/investasi/manajemen-risiko/sebaran-risiko/index';

import ProsesAkhirHari from 'components/pages/investasi/prosesAkhirHari/index';
import ProsesAkhirBulan from 'components/pages/investasi/prosesAkhirBulan/index';

import PengajuanRapel from 'components/pages/sdm-umum/kepegawaian/rapel/pengajuanRapel';
import PengajuanPemberhentian from 'components/pages/sdm-umum/kepegawaian/pemberhentian/pengajuanPemberhentian';
import ApprovalPengajuan from 'components/pages/investasi/persetujuan/index'
import HistoryApprovalPengajuan from 'components/pages/investasi/persetujuan/historic/index';
import Potongan from 'components/pages/sdm-umum/pengaturan/gaji/potongan';
import KaryawanPotongan from 'components/pages/sdm-umum/kepegawaian/karyawan/potongan/index';
import Absensi from 'components/pages/sdm-umum/kepegawaian/absensi/absensi';
import InstruksiKustodiAktif from 'components/pages/investasi/instruksi/kustodi/aktif/index';
import UmumPengadaanPengajuan from 'components/pages/sdm-umum/umum/pengadaan/pengajuan/index';
import Entity from 'components/pages/static-data/entity';
import CashSettlement from 'components/pages/akutansi/cash-settlement/index';
import CashSettlementVoucher from 'components/pages/akutansi/cash-settlement/voucher';
import TunjanganLain from 'components/pages/sdm-umum/pengaturan/gaji/tunjanganLain';
import TunjanganMedis from 'components/pages/sdm-umum/pengaturan/gaji/tunjanganMedis';
import GradeSDM from 'components/pages/sdm-umum/pengaturan/gaji/grade';
import Kinerja from 'components/pages/sdm-umum/pengaturan/pekerjaan/kinerja';
import GroupKompetensi from 'components/pages/sdm-umum/pengaturan/pekerjaan/groupKompetensi';
import Kompetensi from 'components/pages/sdm-umum/pengaturan/pekerjaan/kompetensi';
import UmumPembelianTagihan from 'components/pages/sdm-umum/umum/pembelian/tagihan/index';
import UmumPembelianRiwayat from 'components/pages/sdm-umum/umum/pembelian/riwayat/index';
import JournalImportTransaction from 'components/pages/akutansi/jurnal-import/index';
import EvaluasiKinerja from 'components/pages/sdm-umum/kepegawaian/kinerja/evaluasi-kinerja/evaluasiKinerja';
import KepegawaianPembatalanCuti from 'components/pages/sdm-umum/kepegawaian/cuti/pembatalan-cuti/index';
import PembayaranTunjanganLain from 'components/pages/sdm-umum/payroll/tunjangan-lain/index';
import DaftarPembayaranTunjanganLain from 'components/pages/sdm-umum/payroll/tunjangan-lain/daftarPembayaranTunjanganLain';
import PembayaranTunjanganMedical from 'components/pages/sdm-umum/payroll/tunjangan-medical/index';
import DaftarPembayaranTunjanganMedical from 'components/pages/sdm-umum/payroll/tunjangan-medical/daftarTunjanganMedical';
import DaftarIuranDitunda from 'components/pages/kepesertaan/penerimaanIuran/IuranDitunda/index';
import ProsesIuranDitunda from 'components/pages/kepesertaan/penerimaanIuran/transaksiDitunda/index';
import Rotasi from 'components/pages/sdm-umum/kepegawaian/kinerja/rotasi/rotasi';
import DaftarPemberhentian from 'components/pages/sdm-umum/kepegawaian/pemberhentian/daftarPemberhentian';
import DaftarAset from 'components/pages/sdm-umum/umum/aset/daftarAset';
import SerahTerimaAset from 'components/pages/sdm-umum/umum/aset/serahTerimaAset';
import RiwayatPenghapusanAset from 'components/pages/sdm-umum/umum/penghapusan/riwayatPenghapusanAset';
import PengajuanPenghapusanAset from 'components/pages/sdm-umum/umum/penghapusan/pengajuanPenghapusanAset';
import PenghapusanAktifAsset from 'components/pages/sdm-umum/umum/penghapusan/penghapusanAktif';
import PengajuanPemeliharaanAset from 'components/pages/sdm-umum/umum/pemeliharaan-aset/pengajuan';
import PemeliharaanAset from 'components/pages/sdm-umum/umum/pemeliharaan-aset/pemeliharaanAset';
import RiwayatPemeliharaanAset from 'components/pages/sdm-umum/umum/pemeliharaan-aset/riwayat';
import PengajuanProyek from 'components/pages/sdm-umum/umum/proyek/pengajuanProyek';
import KontrakProyek from 'components/pages/sdm-umum/umum/proyek/kontrakProyek';
import TagihanProyek from 'components/pages/sdm-umum/umum/proyek/tagihanProyek';
import InstruksiBrokerTerkirim from 'components/pages/investasi/instruksi/broker/terkirim/index';
import Menu from 'components/pages/administrator/menu/index';
import BiayaCustodianTransaksi from 'components/pages/investasi/settlement/transaksi-aset-properti/biaya-custodian/index';
import ProgramDapenPensiun from 'components/pages/sdm-umum/kepegawaian/pensiun-iuran/pensiun/index';
import ProgramDapenPengajuanPensiun from 'components/pages/sdm-umum/kepegawaian/pensiun-iuran/pengajuanPensiun.js/index';
import ProgramDapenKepesertaan from 'components/pages/sdm-umum/kepegawaian/pensiun-iuran/kepesertaan/index';
import Aperd from 'components/pages/static-data/aperd';
import DaftarRapel from 'components/pages/sdm-umum/kepegawaian/rapel/daftarRapel';
import MappingCoa from 'components/pages/akutansi/mapping/index';
import InstruksiMiAktif from 'components/pages/investasi/instruksi/mi/aktif/index';
import InstruksiMiTerkirim from 'components/pages/investasi/instruksi/mi/terkirim/index';
import PengajuanPesertaDitunda from 'components/pages/kepesertaan/pesertaDapen/ditunda/pengajuan/index';
import PesertaDitunda from 'components/pages/kepesertaan/pesertaDapen/ditunda/approved/index';
import RekBank from 'components/pages/static-data/rekbank';
import RekeningInvestasi from 'components/pages/static-data/rekeningInvestasi';
import ExchangeTradedFund from 'components/pages/static-data/etf';
import SetupProfileInvestasi from 'components/pages/static-data/setupProfile';
import PengajuanPenempatanExchangeTrade from 'components/pages/investasi/pengajuan/etf/penempatan/index';
import OrderExchange from 'components/pages/investasi/order/etf/index';
import PengajuanPelepasanExchangeeTradedFund from 'components/pages/investasi/pengajuan/etf/pelepasan/index';
import PengajuanPemberhentianPeserta from 'components/pages/kepesertaan/pesertaDapen/pemberhentian/pengajuan/index';
import PemberhentianPeserta from 'components/pages/kepesertaan/pesertaDapen/pemberhentian/approval/index';
import PengajuanPengalihanDplk from 'components/pages/kepesertaan/pesertaDapen/dplk/registrasi/index';
import PengalihanDplk from 'components/pages/kepesertaan/pesertaDapen/dplk/approved/index';
import AkunInstrumen from 'components/pages/investasi/pengaturan/akun-instrumen/index';
import PemeriksaanPembayaranMpSekaligus from 'components/pages/kepesertaan/pembayaranMp/sekaligus/pemeriksaan/index';
import PersetujuanPembayaranMpSekaligus from 'components/pages/kepesertaan/pembayaranMp/sekaligus/persetujuan/index';
import PengajuanPembayaranMpSekaligus from 'components/pages/kepesertaan/pembayaranMp/sekaligus/pengajuan/index';
import PengajuanPembayaranMpBulanan from 'components/pages/kepesertaan/pembayaranMp/bulanan/pengajuan/index';
import PemeriksaanPembayaranMpBulanan from 'components/pages/kepesertaan/pembayaranMp/bulanan/pemeriksaan/index';
import PersetujuanPembayaranMpBulanan from 'components/pages/kepesertaan/pembayaranMp/bulanan/persetujuan/index';
import PengajuanPembayaranMpSusulan from 'components/pages/kepesertaan/pembayaranMp/susulan/pengajuan/index';
import PemeriksaanPembayaranMpSusulan from 'components/pages/kepesertaan/pembayaranMp/susulan/pemeriksaan/index';
import PersetujuanPembayaranMpSusulan from 'components/pages/kepesertaan/pembayaranMp/susulan/persetujuan/index';
import RiwayatPembayaranMpSusulan from 'components/pages/kepesertaan/pembayaranMp/susulan/riwayat/index';
import RoleWorkflow from 'components/pages/administrator/userWorkflow/index';
import PengajuanPerpanjanganDeposito from 'components/pages/investasi/pengajuan/deposito/perpanjangan/index';
import TipeDokumenPeserta from 'components/pages/static-data/tipeDokumenPeserta';
import TransaksiMarkToMarket from 'components/pages/investasi/settlement/generate-transaksi/transaksi-mtm/index';
import TransaksiAccruedInterest from 'components/pages/investasi/settlement/generate-transaksi/transaksi-accrued-interest/index';
import InstruksiBrokerObligasiAktif from 'components/pages/investasi/instruksi/brokerObligasi/aktif/index';
import InstruksiBrokerObligasiTerkirim from 'components/pages/investasi/instruksi/brokerObligasi/terkirim/index';
import IntegrasiServerDirektori from 'components/pages/kepesertaan/integrasi/server/direktori';
import IntegrasiServerRiwayat from 'components/pages/kepesertaan/integrasi/server/riwayat';
import IntegrasiProsesDataBelumProses from 'components/pages/kepesertaan/integrasi/proses-data/data-belum-proses';
import IntegrasiProsesDataKonfirmasiUpdateData from 'components/pages/kepesertaan/integrasi/proses-data/konfirmasi-update-data';
import IntegrasiProsesDataRiwayat from 'components/pages/kepesertaan/integrasi/proses-data/riwayat';
import RiwayatPenerimaanIuran from 'components/pages/kepesertaan/penerimaanIuran/riwayatPenerimaanIuran/index';
import PiutangIuran from 'components/pages/kepesertaan/penerimaanIuran/piutangIuran/index';
import ClosingPriceSaham from 'components/pages/investasi/settlement/closing-price/saham/index';
import ReportResumeIuran from 'components/pages/kepesertaan/report/iuran/resume-iuran/index';
import ReportPesertaAktif from 'components/pages/kepesertaan/report/peserta/pesertaAktif';
import ReportPesertaPasif from 'components/pages/kepesertaan/report/peserta/pesertaPasif';
import ReportPensiunDitunda from 'components/pages/kepesertaan/report/peserta/pensiunDitunda';
import ReportPesertaDialihkan from 'components/pages/kepesertaan/report/peserta/pesertaDialihkan';
import ReportPesertaPemberhentian from 'components/pages/kepesertaan/report/peserta/pesertaPemberhentian';
import ReportPesertaArsip from 'components/pages/kepesertaan/report/peserta/pesertaArsip';
import ReportDetailPeserta from 'components/pages/kepesertaan/report/peserta/detailPeserta';
import ReportDetailIuran from 'components/pages/kepesertaan/report/iuran/detail-iuran/index';
import ReportDetailIuranPeserta from 'components/pages/kepesertaan/report/iuran/detail-iuran-peserta/index';
import PengajuanPengalihanReksadana from 'components/pages/investasi/pengajuan/reksadana/pengalihan/index';
import ReportPengkinianDataIntegrasi from 'components/pages/kepesertaan/report/pengkinian/dataIntegrasi';
import ReportPengkinianBackoffice from 'components/pages/kepesertaan/report/pengkinian/backoffice';
import ReportPengkinianMobileApps from 'components/pages/kepesertaan/report/pengkinian/mobileApps';
import ReportMpResume from 'components/pages/kepesertaan/report/manfaat-pensiun/resume';
import ReportMpDetailBulanan from 'components/pages/kepesertaan/report/manfaat-pensiun/detail';
import ReportMpDetailRiwayatMpPeserta from 'components/pages/kepesertaan/report/manfaat-pensiun/riwayat';
import ReportMpLainGenerate from 'components/pages/kepesertaan/report/manfaat-pensiun/mpLainGenerate';
import ReportMpSekaligus from 'components/pages/kepesertaan/report/manfaat-pensiun/mpSekaligus';
import ReportMpLainPengajuan from 'components/pages/kepesertaan/report/manfaat-pensiun/mpLainPengajuan';
import InstruksiBankDibatalkan from 'components/pages/investasi/instruksi/bank/dibatalkan/index';
import PengajuanPesertaArsip from 'components/pages/kepesertaan/pesertaDapen/arsip/pengajuan/index';
import PesertaArsip from 'components/pages/kepesertaan/pesertaDapen/arsip/approved/index';
import InstruksiBrokerDibatalkan from 'components/pages/investasi/instruksi/broker/dibatalkan/index';
import RegistrasiPengalihanMp from 'components/pages/kepesertaan/pengkinianPeserta/pengalihanMp/registrasi/index';
import LaporanTransaksiPerInstrument from 'components/pages/investasi/portfolio/transaksi/transaksi-instrument/index';
import InstruksiMiDibatalkan from 'components/pages/investasi/instruksi/mi/dibatalkan/index';
import InvestasiJurnalVoucher from 'components/pages/investasi/portfolio/jurnal-voucher/index';
import FixedAssetDeprectiation from 'components/pages/akutansi/fixedAssetDepreciation/index';
import FixedAssetExpense from 'components/pages/akutansi/fixedAssetExpense/index';
import AkunBalanceSaldoOnly from 'components/pages/investasi/portfolio/akun-balance/index';
import BatchEndDays from 'components/pages/administrator/endDay/index';
import KuponSbn from 'components/pages/investasi/settlement/transaksi-aset-properti/kupon-sbn/index';
import BiayaManagementTransaksi from 'components/pages/investasi/settlement/transaksi-aset-properti/biaya-management/index';
import PenerimaanBungaDeposito from 'components/pages/investasi/settlement/transaksi-aset-properti/penerimaan-bunga-deposito/index';
import PengajuanPenempatanEba from 'components/pages/investasi/pengajuan/eba/penempatan/index';
import InterestDepositoPerBank from 'components/pages/investasi/laporan/transaksi/interest-deposito-perbank/index';
import InstruksiBrokerEtfAktif from 'components/pages/investasi/instruksi/brokerEtf/aktif/index';
import InstruksiBrokerEtfTerkirim from 'components/pages/investasi/instruksi/brokerEtf/terkirim/index';
import PengajuanPelepasanEba from 'components/pages/investasi/pengajuan/eba/pelepasan/index';
import TransaksiPembayaranRetur from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/retur/index';
import SettlementTransaksiEtf from 'components/pages/investasi/settlement/konfirmasi/etf/index';
import PendingPengalihanMp from 'components/pages/kepesertaan/pengkinianPeserta/pengalihanMp/pending/index';
import PemindahanPortoEfek from 'components/pages/investasi/settlement/pemindahan-porto/pemindahan-efek/index';
import RapelPembayaranMp from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/rapel/index';
import RiwayatPengalihanMp from 'components/pages/kepesertaan/pengkinianPeserta/pengalihanMp/riwayat/index';
import RiwayatKenaikanBerkalaMp from 'components/pages/kepesertaan/pengkinianPeserta/berkala/riwayat/index';
import PemindahanPortoRekening from 'components/pages/investasi/settlement/pemindahan-porto/pemindahan-rek/index';
import LaporanTransaksiPerAkun from 'components/pages/investasi/laporan/transaksi/transaksi-per-akun/index';
import OrderEba from 'components/pages/investasi/order/eba/index';
import InstruksiBrokerEbaAktif from 'components/pages/investasi/instruksi/brokerEba/index';
import InstruksiBrokerEbaTerkirim from 'components/pages/investasi/instruksi/brokerEba/terkirim/index';
import BiayaSinvestTransaksi from 'components/pages/investasi/settlement/transaksi-aset-properti/biaya-sinvest/index';
import PotonganPembayaranMp from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/potongan/index';
import ReportMpBank from 'components/pages/kepesertaan/report/manfaat-pensiun/resumePerBank';
import DetailInstrumentPortofolio from 'components/pages/investasi/portfolio/balance-position/detail-instrument/index';
import RiwayatPengisianKasUmum from 'components/pages/sdm-umum/umum/kas-umum/riwayat-pengisian-kas-umum/index';
import UmumPengajuanPembayaran from 'components/pages/sdm-umum/umum/kas-umum/pengajuan-pembayaran/index';
import UmumRiwayatPembayaran from 'components/pages/sdm-umum/umum/kas-umum/riwayat-pembayaran/index';
import PengisianKasUmum from 'components/pages/sdm-umum/umum/kas-umum/pengisian-kas-umum/index';
import TransaksiAmortisasi from 'components/pages/investasi/settlement/generate-transaksi/amortisasi/index';
import SubTipeTransaksiSdm from 'components/pages/static-data/subTipeTransaksiSdm';
import TransaksiMpGenerateSPT from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/spt/index';
import PPUPengajuanPembayaran from 'components/pages/sdm-umum/pengajuan-pembayaran-umum/pengajuan-pembayaran/index';
import PPUVerifikasiManager from 'components/pages/sdm-umum/pengajuan-pembayaran-umum/verifikasi-manager/index';
import PPUVerifikasiSdmu from 'components/pages/sdm-umum/pengajuan-pembayaran-umum/verifikasi-sdm-umum/index';
import PPUPersetujuanPengurus from 'components/pages/sdm-umum/pengajuan-pembayaran-umum/persetujuan-pengurus/index';
import PPPengajuanAktif from 'components/pages/sdm-umum/pembayaran-umum/pengajuan-aktif/index.';
import GolonganJabatan from 'components/pages/sdm-umum/pengaturan/pekerjaan/golonganJabatan';
import PeriodeTunjangan from 'components/pages/static-data/periodeTunjangan';
import TipeTunjanganSdm from 'components/pages/static-data/tipeTunjanganSdm';
import PeriodeCutiSdm from 'components/pages/static-data/periodeCutiSdm';
import PengaturanAkun from 'components/pages/sdm-umum/pengaturan/umum/akun';
import PengaturanKasUmum from 'components/pages/sdm-umum/pengaturan/umum/kasUmum';
import PengaturanSupplier from 'components/pages/sdm-umum/pengaturan/umum/supplier';
import PengaturanRekeningBank from 'components/pages/sdm-umum/pengaturan/umum/rekeningBank';
import PengaturanTipePinjaman from 'components/pages/sdm-umum/pengaturan/umum/tipePinjaman';
import AssetCategory from 'components/pages/sdm-umum/pengaturan/aset/assetCategory';
import AssetCategorySub from 'components/pages/sdm-umum/pengaturan/aset/assetCategorySub';
import ReverseTransaksi from 'components/pages/investasi/settlement/transaksi-aset-properti/reverse-transaksi/index';
import OrderProperti from 'components/pages/investasi/order/properti/index';
import UmumPembelianPemesanan from 'components/pages/sdm-umum/umum/pembelian/pemesanan/index';
import UmumPembelianPenerimaan from 'components/pages/sdm-umum/umum/pembelian/penemiraan/index';
import VerifikasiManager from 'components/pages/sdm-umum/umum/penghapusan/verifikasi-penghapusan/index';
import VerifikasiDireksi from 'components/pages/sdm-umum/umum/penghapusan/persetujuan-penghapusan/index';
import RiwayatAktivasiAkun from 'components/pages/kepesertaan/pesertaDapen/aktif/riwayatAktivasiAkun/index';
import MigrateJournal from 'components/pages/akutansi/migrate-journal/index';
import GenerateCoaPositionDaily from 'components/pages/akutansi/generate-coa-daily/index';
import StockRightIssue from 'components/pages/investasi/corporate-action/right-issue/index';
import PPRiwayatPembayaranUmum from 'components/pages/sdm-umum/pembayaran-umum/riwayat-pembayaran-umum/index';
import PenyertaanIpoSaham from 'components/pages/investasi/pengajuan/saham/penyertaan-ipo/index';
import OrderIpoSaham from 'components/pages/investasi/order/ipo-saham/index';
import InstruksiBrokerIpoAktif from 'components/pages/investasi/instruksi/ipo-saham/aktif/index';
import InstruksiBrokerIpoTerkirim from 'components/pages/investasi/instruksi/ipo-saham/terkirim/index';
import SettlementTransaksiIpo from 'components/pages/investasi/settlement/konfirmasi/ipo/index';
import SettlementPenyelesaianAllotmentIpo from 'components/pages/investasi/settlement/penyelesaian/allotment-ipo/index';
import MatrikulasiRisiko from 'components/pages/investasi/manajemen-risiko/matrikulasi-risiko/index';
import PengajuanPenempatanPl from 'components/pages/investasi/pengajuan/penyertaan-modal/penempatan/index';
import LaporanPengajuanInvestasi from 'components/pages/investasi/laporan/transaksi/pengajuan-investasi/index';
import LaporanTransaksiPerPengelola from 'components/pages/investasi/laporan/transaksi/transaksi-per-pengelola/index';
import LaporanTransaksiPerInstitusi from 'components/pages/investasi/laporan/transaksi/transaksi-per-institusi/index';
import LaporanDaftarKaryawan from 'components/pages/sdm-umum/laporan/karyawan/daftar-karyawan/index';
import PendapatanDevidenEtf from 'components/pages/investasi/settlement/transaksi-aset-properti/pendapatan-deviden-etf/index';
import LaporanKaryawanCuti from 'components/pages/sdm-umum/laporan/karyawan/karyawan-cuti/index';
import LaporanResumeCuti from 'components/pages/sdm-umum/laporan/karyawan/resume-cuti/index';
import LaporanKaryawanPerjalananDinas from 'components/pages/sdm-umum/laporan/karyawan/perjalanan-dinas/index';
import LaporanKaryawanSk from 'components/pages/sdm-umum/laporan/karyawan/sk-karyawan/index';
import LaporanKaryawanDokumen from 'components/pages/sdm-umum/laporan/karyawan/dokumen-karyawan/index';
import CashBook from 'components/pages/akutansi/cash-book/index';
import KlaimMpBaru from 'components/pages/kepesertaan/pembayaranMp/klaimMp/klaimBaru/index';
import KlaimMpRiwayat from 'components/pages/kepesertaan/pembayaranMp/klaimMp/klaimRiwayat/index';
import PengajuanPelepasanPl from 'components/pages/investasi/pengajuan/penyertaan-modal/pelepasan/index';
import PenutupanProyek from 'components/pages/sdm-umum/umum/proyek/penutupanProyek';
import RiwayatProyek from 'components/pages/sdm-umum/umum/proyek/riwayatProyek';
import Anggaran from 'components/pages/static-data/anggaran';
import SubTipeAnggaran from 'components/pages/static-data/subTipeAnggaran';
import TipeAnggaran from 'components/pages/static-data/tipeAnggaran';
import LaporanResumeJenisInstrument from 'components/pages/investasi/laporan/portofolio/resume-jenis/index';
import LaporanRoiPerKategori from 'components/pages/investasi/laporan/portofolio/roi-kategori/index';
import LaporanKomposisiDetail from 'components/pages/investasi/laporan/portofolio/komposisi-detail/index';
import LaporanPerPihak from 'components/pages/investasi/laporan/portofolio/laporan-perpihak/index';
import LaporanDaftarTransaksiGaji from 'components/pages/sdm-umum/laporan/gaji/daftar-transaksi/index';
import LaporanDaftarPinjaman from 'components/pages/sdm-umum/laporan/gaji/daftar-pinjaman/index';
import LaporanDaftarTunjangan from 'components/pages/sdm-umum/laporan/gaji/daftar-tunjangan/index';
import LaporanDaftarAbsensi from 'components/pages/sdm-umum/laporan/gaji/absensi/index';
import Unit from 'components/pages/static-data/unit';
import Budget from 'components/pages/akutansi/budgeting/budget/index';
import AnggaranTahunan from 'components/pages/akutansi/report-jurnal/anggaranTahunan';
import AnggaranUnit from 'components/pages/sdm-umum/anggaran/index';
import DPLK from 'components/pages/static-data/dplk';
import KepegawaianPengajuanLembur from 'components/pages/sdm-umum/kepegawaian/lembur/pengajuan-lembur/index';
import KepegawaianVerifikasiCuti from 'components/pages/sdm-umum/kepegawaian/cuti/verifikasi-cuti/index';
import KepegawaianPersetujuanCuti from 'components/pages/sdm-umum/kepegawaian/cuti/persetujuan-cuti/index';
import KepegawaianRiwayatCuti from 'components/pages/sdm-umum/kepegawaian/cuti/riwayat-cuti/index';
import KepegawaianVerifikasiLembur from 'components/pages/sdm-umum/kepegawaian/lembur/verifikasi-lembur/index';
import KepegawaianRiwayatLembur from 'components/pages/sdm-umum/kepegawaian/lembur/riwayat-lembur/index';
import KepegawaianPersetujuanLembur from 'components/pages/sdm-umum/kepegawaian/lembur/persetujuan-lembur/index';
import InstruksiKustodiTerkirim from 'components/pages/investasi/instruksi/kustodi/terkirim/index';
import ReportPengkinianDaftarUlang from 'components/pages/kepesertaan/report/pengkinian/daftarUlang';
import AkuntansiAsetNeto from 'components/pages/akutansi/report-jurnal/asetNeto';
import AkuntansiPerubahanAsetNeto from 'components/pages/akutansi/report-jurnal/perubahanAsetNeto';
import PiutangIuranPengajuan from 'components/pages/kepesertaan/penerimaanIuran/piutangIuran/pengajuan';
import PiutangIuranVerifikasi from 'components/pages/kepesertaan/penerimaanIuran/piutangIuran/verifikasi';
import PiutangIuranPersetujuan from 'components/pages/kepesertaan/penerimaanIuran/piutangIuran/persetujuan';
import PengembalianKasUmum from 'components/pages/sdm-umum/umum/kas-umum/pengembalian/index';
import PengajuanRekruitmen from 'components/pages/sdm-umum/kepegawaian/rekruitmen/pengajuan/index';
import RiwayatRekruitmen from 'components/pages/sdm-umum/kepegawaian/rekruitmen/riwayat/index';
import RekruitmenAktif from 'components/pages/sdm-umum/kepegawaian/rekruitmen/aktif/index';
import CashSettlementVerified from 'components/pages/akutansi/cash-settlement/verification';
import CashSettlementApproval from 'components/pages/akutansi/cash-settlement/approve';
import CashSettlementHistory from 'components/pages/akutansi/cash-settlement/riwayat';
import DailyBalancePortofolio from 'components/pages/investasi/portfolio/balance-position/daily-balance/index';
import InstrumentProperti from 'components/pages/static-data/instruProperti';
import BatchEndMonths from 'components/pages/administrator/endMonth/index';
import BatchEndYears from 'components/pages/administrator/endYear/index';
import LaporanIuran from 'components/pages/akutansi/report-jurnal/laporanIuran';
import SekaligusJadud from 'components/pages/kepesertaan/integrasi/konversi/SekaligusJadud';
import SekaligusPeserta from 'components/pages/kepesertaan/integrasi/konversi/SekaligusPeserta';
import NilaiSekarang from 'components/pages/kepesertaan/integrasi/konversi/NilaiSekarang';
import KonversiAnak from 'components/pages/kepesertaan/integrasi/konversi/KonversiAnak';
import GenerateJurnalPenutup from 'components/pages/akutansi/generate-jurnal-penutup/index';
import DetailDailyBalancePortofolio from 'components/pages/investasi/laporan/daily-balance-portofolio/index';
import CreateNewPassword from 'components/pages/createNewPassword';
import PenyesuaianPhdp from 'components/pages/kepesertaan/pembayaranMp/transaksiMp/penyesuaianPhdp/index';
import PersetujuanPengurus from 'components/pages/sdm-umum/umum/pengadaan/persetujuan/index';
import LaporanHasilInvestasiAkunting from 'components/pages/akutansi/report-jurnal/laporanHasilInvestasiAkuntansi';
import ListPajakPenghasilan from 'components/pages/akutansi/report-jurnal/listPajakPenghasilan';
import RasioSolvabilitas from 'components/pages/aktuaria/kalkulasi/rasio/rasioSolvabilitas';
import LaporanHasilInvestasiDetail from 'components/pages/akutansi/report-jurnal/laporanHasilInvestasiDetail';

class App extends Component {
  constructor(props) {
    super(props)

    this.PropTypes = {
      store: PropTypes.object.isRequired
    }

    this.state = {
      loginStatus: false,
      redirect: '',
      username: '',
      role: [],
      menuActive: '',
      menuArray: []
    }

    this.store = this.props.store;
    this.subscribe = initSubscriber(this.store)
    this.NavbarRef = React.createRef();
    this.TopMenuRef = React.createRef();

    this.headerRef = React.createRef()
  }

  componentDidMount = () => {
    this.subscribe('sessionToken', data => {
      this.checkSession(this.store)
    });

    this.subscribe('urls', data => {
      this.checkSession(this.store)
      // this.setActiveMenu(this.store)
    });

    this.subscribe('sessionRole', data => {
      this.getMenuAccess()
    })

    this.checkSession(this.store)
    // this.setActiveMenu(this.store)

    this.store.dispatch(showLoading(false))

    setTimeout(() => {
      this.getMenuAccess()
    }, 0)
  }

  getMenuAccess = async () => {
    var menu
    if (this.props.store.getState().sessionToken && typeof this.props.store.getState().sessionToken !== 'object') {
      menu = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `sys-menu-groups/enabled-multigroup/${this.props.store.getState().sessionRole.join(',')}/true`) || []

      if (menu.length === 0) {
        this.TopMenuRef.current.setTopMenu([])
        this.NavbarRef.current.setSideMenu([])
      }
      var topMenu = menu.filter(value => value.level === 0).map(value => {
        return {
          menuCode: value.menuCode,
          text: value.menuName,
          url: `/${value.linkPage}`
        }
      })
      var sideMenu = menu.filter(value => value.level === 0).map(value => {
        var menuLevel1 = menu.filter(level1 => level1.level === 1 && level1.parentCode === value.menuCode).map(level1 => {
          var menuLevel2 = menu.filter(level2 => level2.level === 2 && level2.parentCode === level1.menuCode)

          if (menuLevel2.length > 0) {
            menuLevel2 = menuLevel2.map(level2 => {
              var menuLevel3 = menu.filter(level3 => level3.level === 3 && level3.parentCode === level2.menuCode)

              if (menuLevel3.length > 0) {
                menuLevel3 = menuLevel3.map(level3 => {
                  return {
                    menuCode: level3.menuCode,
                    text: level3.menuName,
                    url: level3.linkPage,
                  }
                })
                return {
                  menuCode: level2.menuCode,
                  text: level2.menuName,
                  url: level2.linkPage,
                  child: menuLevel3
                }
              } else {
                return {
                  menuCode: level2.menuCode,
                  text: level2.menuName,
                  url: level2.linkPage,
                }
              }
            })

            return {
              menuCode: level1.menuCode,
              text: level1.menuName,
              url: level1.linkPage,
              child: menuLevel2
            }
          } else {
            return {
              menuCode: level1.menuCode,
              text: level1.menuName,
              url: level1.linkPage,
            }
          }
        })

        return {
          menuCode: value.menuCode,
          menuName: value.linkPage,
          menuList: menuLevel1
        }
      })

      this.TopMenuRef.current.setTopMenu(topMenu)
      this.NavbarRef.current.setSideMenu(sideMenu)
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    // let storePrevProps = prevProps.store.getState()

    // if (((this.store.getState().sessionToken instanceof Array) || !this.store.getState().sessionToken) && storePrevProps.urls !== '/') {
    //   var activeMenus = storePrevProps.urls.split('/')
    //   if (activeMenus.length > 1) {
    //     this.setState({
    //       loginStatus: false,
    //       redirect: '/'
    //     })
    //   }
    // }

    // if (storePrevProps.sessionRole instanceof Array && storePrevProps.sessionRole && storePrevProps.urls !== '/') {
    //   this.NavbarRef.current.setRoles(storePrevProps.sessionRole)

    //   let StatusRole = false
    //   if (storePrevProps.sessionRole.indexOf('ROLE_ADMIN') !== -1) {
    //     let menuPrivilage = menuRole('ROLE_ADMIN')

    //     if (menuPrivilage.findIndex(element => storePrevProps.urls.includes(element)) === -1) {
    //       StatusRole = false
    //     } else {
    //       StatusRole = true
    //     }
    //   }
    //   if (storePrevProps.sessionRole.indexOf('ROLE_USER') !== -1) {
    //     let menuPrivilage = menuRole('ROLE_USER')

    //     if (menuPrivilage.findIndex(element => storePrevProps.urls.includes(element)) === -1) {
    //       StatusRole = false
    //     } else {
    //       StatusRole = true
    //     }
    //   }

    //   if (!StatusRole && storePrevProps.sessionRole.length > 0) {
    //     this.setState({
    //       redirect: '/'
    //     })
    //   }
    // }
  }

  setActiveMenu = (store) => {
    var activeMenus = store.getState().urls.split('/')

    if (activeMenus.length > 1) {
      this.setState({
        menuActive: activeMenus[2],
        topmenuActive: activeMenus[1],
        menuArray: activeMenus
      })
    }
  }

  checkSession = (store) => {
    if (!(store.getState().sessionToken instanceof Array) && store.getState().sessionToken) {
      let token = store.getState().sessionToken;

      axios.get(process.env.REACT_APP_BACKEND + 'authenticate', {
        headers: {
          Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
      }).then((response) => {
        if (response.data === '') {
          document.getElementById('app-container').classList.add('d-none')
          // this.setState({
          //   loginStatus: false
          // })

          confirmAlert({
            title: 'Session Expired',
            message: "Session expired, please re-login!",
            buttons: [
              {
                label: 'Close',
                onClick: () => {
                  this.logout()
                }
              },
            ]
          });
        } else {
          // this.setState({
          //   username: response.data,
          //   loginStatus: true
          // })
          var param = this.props.store.getState().getParam
          var systemDate
          if (param ? param.length > 0 : param) {
            systemDate = param.find(value => value.paramCode === '0 SYSDATE')
          }
          systemDate = systemDate ? systemDate.dateValue : null

          if (this.headerRef.current) {
            // console.log(this.headerRef.current)
            this.headerRef.current.setUsernameAndSystemDate(response.data, systemDate)
          }

          if (document.getElementById('app-container')) {
            document.getElementById('app-container').classList.remove('d-none')
          }

          if (store.getState().urls === '/') {
            this.setState({
              redirect: '/dashboard'
            })
          }


        }

      }).catch((error) => {
        console.log(error)
        // this.setState({
        //   loginStatus: false
        // })
        document.getElementById('app-container').classList.add('d-none')

        confirmAlert({
          title: 'Connection Lost',
          message: axiosException(error),
          buttons: [
            {
              label: 'Close',
              onClick: () => {
                this.logout()
              }
            },
          ]
        });
      })
    } else {
      document.getElementById('app-container').classList.add('d-none')
      if (((this.store.getState().sessionToken instanceof Array) || !this.store.getState().sessionToken) && this.store.getState().urls !== '/' && this.store.getState().urls !== '/createNewPassword') {
        // if (!this.store.getState().sessionToken) {
        this.setState({
          // loginStatus: false,
          redirect: '/'
        })
      }else if(((this.store.getState().sessionToken instanceof Array) || !this.store.getState().sessionToken) && this.store.getState().urls === '/createNewPassword'){
        this.setState({
          // loginStatus: false,
          redirect: '/createNewPassword'
        })
      }
    }
  }

  logout = (e) => {
    if (e) {
      e.preventDefault()
    }

    let store = this.props.store;

    store.dispatch(sessionToken(null));
    store.dispatch(getParam(null));
    store.dispatch(sessionUser(null));
    store.dispatch(sessionRole(null));
    this.setState({
      redirect: '/'
    })
  }

  renderRedirect = () => {
    if (this.state.redirect !== '') {
      return <Redirect to={this.state.redirect} />
    } else {
      return null
    }
  }

  render() {
    return (
      <div className="App dx-swatch-dapen-pegadaian">
        <Loading store={this.props.store} />
        <Provider store={this.props.store}>
          <Router>
            {this.renderRedirect()}
            {/* <Route exact path="/" component={(props) => <Login {...props} store={this.props.store} />}/> */}
            <Route exact path="/" component={(props) => <Login {...props} store={this.props.store}/>}/>
            <Route exact path="/createNewPassword" component={(props) => <CreateNewPassword {...props} store={this.props.store} />} />
            <div id="app-container">
              <div className="app">
                <Header ref={this.headerRef} store={this.props.store} logout={(e) => this.logout(e)} />
                <TopMenu store={this.props.store} ref={this.TopMenuRef} />
                <Navbar store={this.props.store} ref={this.NavbarRef} />

                <article className="content dashboard-page">
                  <section className="section">
                    <Route exact path="/dashboard" component={(props) => <Dashboard {...props} store={this.props.store} />} />
                    {/* Static Data */}
                    <Route exact path="/static-data/closing-price/obligasi" component={(props) => <CpObligasi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/closing-price/saham" component={(props) => <CpSaham {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/closing-price/reksadana" component={(props) => <CpReksadana {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/bank" component={(props) => <Bank {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/currency" component={(props) => <Currency {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/kustodi" component={(props) => <Kustodi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/broker" component={(props) => <Broker {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/aperd" component={(props) => <Aperd {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/saham" component={(props) => <InstruSaham {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/deposito" component={(props) => <InstruDepo {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/properti" component={(props) => <InstrumentProperti {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/obligasi" component={(props) => <InstruObligasi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/reksadana" component={(props) => <InstruReksadana {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/province" component={(props) => <Province {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/country" component={(props) => <Country {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/city" component={(props) => <City {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/postal-codes" component={(props) => <PostalCode {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/participants" component={(props) => <Participant {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/branches" component={(props) => <Branch {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/articles" component={(props) => <Article {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/redaksi" component={(props) => <Redaksi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/visitors" component={(props) => <Visitor {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/emitens" component={(props) => <Emiten {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/taxes" component={(props) => <Tax {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/periode-configs" component={(props) => <PeriodeConfig {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/educations" component={(props) => <Education {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/grades" component={(props) => <Grade {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/potongan-gaji" component={(props) => <GajiPotongan {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/komponen-gaji" component={(props) => <GajiKomponen {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/manager-investasi" component={(props) => <ManagerInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/instruments" component={(props) => <Instrument {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/fiscal-year-configuration" component={(props) => <FiscalYear {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/chart-of-account" component={(props) => <ChartOfAccount {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/chart-of-account-group" component={(props) => <CoaGroup {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/journal-type" component={(props) => <JournalType {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/department" component={(props) => <Department {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/holiday" component={(props) => <Holiday {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/project" component={(props) => <Project {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/identity" component={(props) => <Identity {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/exchange-rate" component={(props) => <ExchangeRate {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/vendor" component={(props) => <Vendor {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/agenda-bisnis" component={(props) => <AgendaBisnis {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/kecamatan" component={(props) => <Kecamatan {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/kelurahan" component={(props) => <Kelurahan {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/client-pemberi-kerja" component={(props) => <ClientPemberiKerja {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/manfaat-pensiun" component={(props) => <ManfaatPensiun {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/jabatan" component={(props) => <Jabatan {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-agenda" component={(props) => <AgendaType {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/asset-category" component={(props) => <KategoriAsset {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/kjpp" component={(props) => <KJPP {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-pembayaran" component={(props) => <TipePembayaran {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-transaksi-sdm" component={(props) => <TipeTransaksiSdm {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-transaksi-stt" component={(props) => <SubTipeTransaksiSdm {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-transaksi-investasi" component={(props) => <TipeTransaksiInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-transaksi-kepesertaan" component={(props) => <TipeTransaksiPeserta {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-dokumen-kepesertaan" component={(props) => <TipeDokumenPeserta {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/product" component={(props) => <Product {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/entity" component={(props) => <Entity {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/pemberi-kerja" component={(props) => <ClientPemberiKerja {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/sektor" component={(props) => <Sektor {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/rekbank-kepesertaan" component={(props) => <RekBank {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/rekening-investasi" component={(props) => <RekeningInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/etf" component={(props) => <ExchangeTradedFund {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/profile-investasi" component={(props) => <SetupProfileInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/periode-tunjangan" component={(props) => <PeriodeTunjangan {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/tipe-tunjangan" component={(props) => <TipeTunjanganSdm {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/periode-cuti" component={(props) => <PeriodeCutiSdm {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/anggaran" component={(props) => <Anggaran {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/sub-type-anggaran" component={(props) => <SubTipeAnggaran {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/type-anggaran" component={(props) => <TipeAnggaran {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/unit" component={(props) => <Unit {...props} store={this.props.store} />} />
                    <Route exact path="/static-data/dplk" component={(props) => <DPLK {...props} store={this.props.store} />} />

                    {/* KEPESERTAAN */}
                    <Route exact path="/kepesertaan/pembayaran-mp/bulanan/perencanaan" component={(props) => <PengajuanPembayaranMpBulanan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/bulanan/pemeriksaan" component={(props) => <PemeriksaanPembayaranMpBulanan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/bulanan/persetujuan" component={(props) => <PersetujuanPembayaranMpBulanan {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pembayaran-mp/pembayaran-lainnya/:step" component={(props) => <PengajuanManfaatLainnya {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pembayaran-mp/klaim-mp/baru" component={(props) => <KlaimMpBaru {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/klaim-mp/proses" component={(props) => <KlaimMpProses {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/klaim-mp/selesai" component={(props) => <KlaimMpRiwayat {...props} store={this.props.store} />} />
                    {/* <Route exact path="/kepesertaan/pembayaran-mp/klaim-mp/:step" component={(props) => <KlaimMp {...props} store={this.props.store} />} /> */}

                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/bulanan" component={(props) => <TransaksiPembayaranMpBulanan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/sekaligus" component={(props) => <TransaksiPembayaranMpSekaligus {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/pembayaran-lainnya" component={(props) => <TransaksiPembayaranMpManfaatLain {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/ditunda" component={(props) => <TransaksiMpDitunda {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/retur" component={(props) => <TransaksiPembayaranRetur {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/rpl" component={(props) => <RapelPembayaranMp {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/potongan" component={(props) => <PotonganPembayaranMp {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/penyesuaian-phdp" component={(props) => <PenyesuaianPhdp {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/transaksi-mp/spt" component={(props) => <TransaksiMpGenerateSPT {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pembayaran-mp/sekaligus/pengajuan" component={(props) => <PengajuanPembayaranMpSekaligus {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/sekaligus/pemeriksaan" component={(props) => <PemeriksaanPembayaranMpSekaligus {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/sekaligus/persetujuan" component={(props) => <PersetujuanPembayaranMpSekaligus {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pembayaran-mp/susulan/pengajuan" component={(props) => <PengajuanPembayaranMpSusulan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/susulan/pemeriksaan" component={(props) => <PemeriksaanPembayaranMpSusulan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/susulan/persetujuan" component={(props) => <PersetujuanPembayaranMpSusulan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pembayaran-mp/susulan/riwayat" component={(props) => <RiwayatPembayaranMpSusulan {...props} store={this.props.store} />} />


                    <Route exact path="/kepesertaan/pendaftaran-peserta" component={(props) => <PendaftaranPeserta {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-pasif/klaim" component={(props) => <KlaimManfaatPensiun {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/peserta-dapen/aktif/registrasi" component={(props) => <RegistrasiAktif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/aktif/akun-peserta-aktif" component={(props) => <PesertaAktif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/aktif/persiapan-pensiun" component={(props) => <PersiapanPensiun {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/aktif/aktivasi-akun-online" component={(props) => <AktivasiAkun {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/aktif/riwayat-aktivasi-akun-online" component={(props) => <RiwayatAktivasiAkun {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/peserta-dapen/pasif/registrasi" component={(props) => <RegistrasiPasif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/pasif/akun-pensiunan-pasif" component={(props) => <AkunPensiunanPasif {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/peserta-dapen/ditunda/pengajuan" component={(props) => <PengajuanPesertaDitunda {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/ditunda/list" component={(props) => <PesertaDitunda {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/peserta-dapen/pengalihan-dplk/pengajuan" component={(props) => <PengajuanPengalihanDplk {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/pengalihan-dplk/list" component={(props) => <PengalihanDplk {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/peserta-dapen/pemberhentian/pengajuan" component={(props) => <PengajuanPemberhentianPeserta {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/pemberhentian/list" component={(props) => <PemberhentianPeserta {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/peserta-dapen/akun-peserta/registrasi" component={(props) => <KlaimManfaatPensiun {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/akun-peserta/ppip" component={(props) => <PPIP {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/akun-peserta/ppmp" component={(props) => <PPMP {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/peserta-dapen/arsip-penutupan-akun/pengajuan" component={(props) => <PengajuanPesertaArsip {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/peserta-dapen/arsip-penutupan-akun/arsip" component={(props) => <PesertaArsip {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pengkinian-peserta/aktif/registrasi" component={(props) => <RegistrasiPengkinianAktif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/aktif/history" component={(props) => <RiwayatPengkinianAktif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/aktif/akun-pensiunan-aktif" component={(props) => <KlaimManfaatPensiun {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pengkinian-peserta/pasif/registrasi" component={(props) => <RegistrasiPengkinianPasif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/pasif/daftar-ulang" component={(props) => <DaftarUlangPengkinianPasif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/pasif/history" component={(props) => <RiwayatPengkinianPasif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/pasif/akun-pensiunan-aktif" component={(props) => <RiwayatPengkinianAktif {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pengkinian-peserta/online/pengajuan" component={(props) => <PengkinianPengajuanOnline {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/online/riwayat" component={(props) => <PengkinianRiwayatOnline {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pengkinian-peserta/berkala/kenaikan-mp" component={(props) => <KenaikanBerkalaMp {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/berkala/rekonsiliasi-peserta" component={(props) => <RiwayatKenaikanBerkalaMp {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/pengkinian-peserta/pengalihan-mp/registrasi" component={(props) => <RegistrasiPengalihanMp {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/pengalihan-mp/pending" component={(props) => <PendingPengalihanMp {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/pengkinian-peserta/pengalihan-mp/riwayat" component={(props) => <RiwayatPengalihanMp {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/penerimaan-iuran/proses-iuran-bulanan" component={(props) => <ProsesIuran {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/penerimaan-iuran/matching-proses" component={(props) => <MatchingProses {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/penerimaan-iuran/daftar-iuran-ditunda" component={(props) => <DaftarIuranDitunda {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/penerimaan-iuran/proses-iuran-ditunda" component={(props) => <ProsesIuranDitunda {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/penerimaan-iuran/transaksi-iuran" component={(props) => <TransaksiIuran {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/iuran-kepesertaan/konfirmasi-iuran/piutang-iuran/pengajuan" component={(props) => <PiutangIuranPengajuan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/iuran-kepesertaan/konfirmasi-iuran/piutang-iuran/verifikasi-manager" component={(props) => <PiutangIuranVerifikasi {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/iuran-kepesertaan/konfirmasi-iuran/piutang-iuran/persetujuan-pengurus" component={(props) => <PiutangIuranPersetujuan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/iuran-kepesertaan/konfirmasi-iuran/riwayat-penerimaan-iuran" component={(props) => <RiwayatPenerimaanIuran {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/integrasi/proses-date/before" component={(props) => <IntegrasiProsesDataBelumProses {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/integrasi/proses-date/konfirmasi" component={(props) => <IntegrasiProsesDataKonfirmasiUpdateData {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/integrasi/proses-date/riwayat" component={(props) => <IntegrasiProsesDataRiwayat {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/integrasi/konversi/sekaligusJadud" component={(props) => <SekaligusJadud {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/integrasi/konversi/sekaligusPeserta" component={(props) => <SekaligusPeserta {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/integrasi/konversi/nilaiSekarang" component={(props) => <NilaiSekarang {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/integrasi/konversi/faktorAnak" component={(props) => <KonversiAnak {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/integrasi/server/direktori" component={(props) => <IntegrasiServerDirektori {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/integrasi/server/riwayat" component={(props) => <IntegrasiServerRiwayat {...props} store={this.props.store} />} />

                    {/* REPORT */}
                    <Route exact path="/kepesertaan/report/kepesertaan/peserta-aktif" component={(props) => <ReportPesertaAktif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/kepesertaan/peserta-pasif" component={(props) => <ReportPesertaPasif {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/kepesertaan/pensiun-tunda" component={(props) => <ReportPensiunDitunda {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/kepesertaan/pengalihan" component={(props) => <ReportPesertaDialihkan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/kepesertaan/pemberhentian" component={(props) => <ReportPesertaPemberhentian {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/kepesertaan/peserta-arsip" component={(props) => <ReportPesertaArsip {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/kepesertaan/profile" component={(props) => <ReportDetailPeserta {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/report/pengkinian/date-integrasi" component={(props) => <ReportPengkinianDataIntegrasi {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/pengkinian/bo" component={(props) => <ReportPengkinianBackoffice {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/pengkinian/mobile" component={(props) => <ReportPengkinianMobileApps {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/pengkinian/daftar-ulang" component={(props) => <ReportPengkinianDaftarUlang {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/report/manfaat-pensiun/resume-mp-bulanan" component={(props) => <ReportMpResume {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/manfaat-pensiun/detail-mp-bulanan" component={(props) => <ReportMpDetailBulanan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/manfaat-pensiun/riwayat-mp-peserta" component={(props) => <ReportMpDetailRiwayatMpPeserta {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/manfaat-pensiun/mp-lain-generate" component={(props) => <ReportMpLainGenerate {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/manfaat-pensiun/mp-sekaligus" component={(props) => <ReportMpSekaligus {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/manfaat-pensiun/mp-lain-pengajuan" component={(props) => <ReportMpLainPengajuan {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/manfaat-pensiun/resume-mp-bank" component={(props) => <ReportMpBank {...props} store={this.props.store} />} />

                    <Route exact path="/kepesertaan/report/iuran/participant-resume" component={(props) => <ReportResumeIuran {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/iuran/detail-pk" component={(props) => <ReportDetailIuran {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/iuran/detail-peserta" component={(props) => <ReportDetailIuranPeserta {...props} store={this.props.store} />} />
                    <Route exact path="/kepesertaan/report/iuran/laporan-iuran" component={(props) => <LaporanIuran {...props} store={this.props.store} />} />

                    {/* AKTUARIA */}
                    <Route exact path="/aktuaria/valuasi/list" component={(props) => <Rekonsiliasi {...props} store={this.props.store} />} />
                    <Route exact path="/aktuaria/valuasi/aset-pendanaan" component={(props) => <AsetPendanaan {...props} store={this.props.store} />} />
                    <Route exact path="/aktuaria/valuasi/kewajiban-dapen" component={(props) => <KewajibanDepan {...props} store={this.props.store} />} />
                    <Route exact path="/aktuaria/valuasi/skor-aktuaria" component={(props) => <SkorAktuaria {...props} store={this.props.store} />} />
                    <Route exact path="/aktuaria/kalklasi/rasio/rasio-solvabilitas" component={(props) => <RasioSolvabilitas {...props} store={this.props.store} />} />
                    {/* Administrator */}
                    <Route exact path="/administrator/user-group" component={(props) => <UserGroup {...props} store={this.props.store} />} />
                    <Route exact path="/administrator/user" component={(props) => <User {...props} store={this.props.store} />} />
                    <Route exact path="/administrator/menu" component={(props) => <Menu {...props} store={this.props.store} />} />
                    <Route exact path="/administrator/audit-log" component={(props) => <AuditLog {...props} store={this.props.store} />} />
                    <Route exact path="/administrator/approval-history" component={(props) => <ApprovalHistory {...props} store={this.props.store} approveType='approval' />} />
                    <Route exact path="/administrator/checker-list" component={(props) => <CheckerList {...props} store={this.props.store} approveType='checker' />} />
                    <Route exact path="/administrator/approval-list" component={(props) => <ApprovalList {...props} store={this.props.store} approveType='approval' />} />
                    <Route exact path="/administrator/user-group-wf" component={(props) => <RoleWorkflow {...props} store={this.props.store} />} />
                    <Route exact path="/administrator/closeday" component={(props) => <BatchEndDays {...props} store={this.props.store} />} />
                    <Route exact path="/administrator/closemonth" component={(props) => <BatchEndMonths {...props} store={this.props.store} />} />
                    <Route exact path="/administrator/closeyear" component={(props) => <BatchEndYears {...props} store={this.props.store} />} />

                    {/* AKUTANSI */}
                    <Route exact path="/akuntansi/mapping-coa" component={(props) => <MappingCoa {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/migrate-journal" component={(props) => <MigrateJournal {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/generate-coa-daily" component={(props) => <GenerateCoaPositionDaily {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/jurnal/jurnal-berulang" component={(props) => <JurnalReccuring {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/jurnal/jurnal-voucher" component={(props) => <JournalVoucher {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/jurnal-import" component={(props) => <JournalImportTransaction {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/cash-book" component={(props) => <CashBook {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/aset-tetap/index" component={(props) => <FixedAsset {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/aset-tetap/grup" component={(props) => <FixedAssetGroup {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/aset-tetap/disposal" component={(props) => <FixedAssetDisposal {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/aset-tetap/penyusutan" component={(props) => <FixedAssetDeprectiation {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/aset-tetap/pemeliharaan" component={(props) => <FixedAssetExpense {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/budget" component={(props) => <Budget {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/reports/general-ledger" component={(props) => <GeneralLedger {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/report-jurnal" component={(props) => <ReportJurnal {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/profit-loss" component={(props) => <ProfitLoss {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/trial-balance" component={(props) => <TrialBalance {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/balance-sheet" component={(props) => <BalanceSheet {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/balance-sheet-detail" component={(props) => <BalanceSheetDetail {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/balance-sheet-detail-2" component={(props) => <BalanceSheetDetail2 {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/cash-flow" component={(props) => <CashFlow {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/cash-flow-auto" component={(props) => <CashFlowAuto {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/cash-flow-details" component={(props) => <CashFlowDetail {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/aset-neto" component={(props) => <AkuntansiAsetNeto {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/perubahan-aset-neto" component={(props) => <AkuntansiPerubahanAsetNeto {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/laporan-ojk" component={(props) => <LaporanOJK {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/anggaran-tahunan" component={(props) => <AnggaranTahunan {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/detail-profit-loss" component={(props) => <PosProfitLoss {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/rasio-keuangan" component={(props) => <RasioKeuangan {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/laporan-hasil-investasi" component={(props) => <LaporanHasilInvestasiAkunting {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/list-pajak-penghasilan" component={(props) => <ListPajakPenghasilan {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/reports/laporan-hasil-invesatasi-detail" component={(props) => <LaporanHasilInvestasiDetail {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/hutang-piutang/piutang/iuran-kepesertaan" component={(props) => <IuranKepesertaan {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/piutang/transaksi-investasi" component={(props) => <TransaksiInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/piutang/piutang-lain" component={(props) => <PiutangLain {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/piutang/piutang-pinjaman" component={(props) => <PiutangPinjaman {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/hutang/jatuh-tempo" component={(props) => <JatuhTempo {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/hutang/pembayaran-mp" component={(props) => <PembayaranMp {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/hutang/supplier" component={(props) => <Supplier {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/hutang/transaksi-investasi" component={(props) => <TransaksiInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/hutang/pajak" component={(props) => <Pajak {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/hutang/hutang-lain" component={(props) => <HutangLain {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/hutang-piutang/hutang/hutang-gaji" component={(props) => <HutangGaji {...props} store={this.props.store} />} />

                    <Route exact path="/akuntansi/bank-instruction" component={(props) => <BankInstruction {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/bank-reconcile" component={(props) => <BankReconcile {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/cash-settlement/outstanding" component={(props) => <CashSettlement {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/cash-settlement/verified" component={(props) => <CashSettlementVerified {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/cash-settlement/approval" component={(props) => <CashSettlementApproval {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/cash-settlement/voucher" component={(props) => <CashSettlementVoucher {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/cash-settlement/riwayat" component={(props) => <CashSettlementHistory {...props} store={this.props.store} />} />
                    <Route exact path="/akuntansi/closing-jurnal-auto" component={(props) => <GenerateJurnalPenutup {...props} store={this.props.store} />} />
                    {/* INVESTASI */}
                    <Route exact path="/investasi/pengaturan/aset-alocation" component={(props) => <AsetAlocation {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengaturan/kpd" component={(props) => <KPD {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengaturan/akun-investasi" component={(props) => <AkunInstrumen {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/corporate-action/schedule" component={(props) => <CorporateSchedule {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/corporate-action/transaksi/cash-deviden" component={(props) => <CashDeviden {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/corporate-action/transaksi/stock-deviden" component={(props) => <StockDeviden {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/corporate-action/transaksi/stock-right" component={(props) => <StockRightIssue {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/corporate-action/transaksi/stock-reverse" component={(props) => <StockReverse {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/corporate-action/transaksi/stock-split" component={(props) => <StockSplit {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/portfolio/balance-position/resume" component={(props) => <BalancePositionResume {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/portfolio/balance-position/detail-jenis-instrumen" component={(props) => <BalancePortfolioDetailInstrument {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/portfolio/balance-position/detail-instrument" component={(props) => <DetailInstrumentPortofolio {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/portfolio/balance-position/kpd" component={(props) => <PortofolioKPD {...props} store={this.props.store} />} />
                    {/* <Route exact path="/investasi/portfolio/balance-position/daily-balance" component={(props) => <DailyBalancePortofolio {...props} store={this.props.store} />} /> */}
                    <Route exact path="/investasi/portfolio/balance-position/daily-balance" component={(props) => <DetailDailyBalancePortofolio {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/portfolio/jurnal-akun" component={(props) => <LaporanTransaksiPerInstrument {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/portfolio/jurnal-voucher" component={(props) => <InvestasiJurnalVoucher {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/portfolio/akun-balance" component={(props) => <AkunBalanceSaldoOnly {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/portfolio/maturity" component={(props) => <PortofolioMaturity {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/portfolio/rebalance-portfolio" component={(props) => <PortfolioRebalance {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/settlement/konfirmasi/obligasi" component={(props) => <SettlementTransaksiObligasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/konfirmasi/deposito" component={(props) => <SettlementTransaksiDeposito {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/konfirmasi/reksadana" component={(props) => <SettlementTransaksiReksadana {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/konfirmasi/saham" component={(props) => <SettlementTransaksiSaham {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/konfirmasi/etf" component={(props) => <SettlementTransaksiEtf {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/konfirmasi/aset-properti" component={(props) => <TransaksiProperti {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/konfirmasi/aset-penyertaan-modal" component={(props) => <TransaksiPenyertaan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/konfirmasi/ipo" component={(props) => <SettlementTransaksiIpo {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/settlement/penyelesaian/alokasi-unit" component={(props) => <SettlementPenyelesaianAlokasiUnit {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/penyelesaian/pembayaran" component={(props) => <PembayaranPenyelesaianTransaksi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/penyelesaian/penerimaan" component={(props) => <PenerimaanPenyelesaianTransaksi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/penyelesaian/allotment" component={(props) => <SettlementPenyelesaianAllotmentIpo {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/settlement/harga-penutupan/obligasi" component={(props) => <ClosingPriceObligasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/harga-penutupan/nab" component={(props) => <ClosingPriceNab {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/harga-penutupan/saham" component={(props) => <ClosingPriceSaham {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/settlement/valuasi/aset-properti" component={(props) => <ValuasiProperti {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/valuasi/aset-penyertaan-modal" component={(props) => <ValuasiPenyertaan {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/settlement/transaksi-lain-lain/pemeliharaan-aset-properti" component={(props) => <OperasionalPemeliharaan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/penerimaan-sewa-properti" component={(props) => <OperasionalPenyewaan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/biaya-custodian" component={(props) => <BiayaCustodianTransaksi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/biaya-sinvest" component={(props) => <BiayaSinvestTransaksi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/trannsaksi-reverse" component={(props) => <ReverseTransaksi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/kupon-sbn" component={(props) => <KuponSbn {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/interest-deposito" component={(props) => <PenerimaanBungaDeposito {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/biaya-management" component={(props) => <BiayaManagementTransaksi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/transaksi-lain-lain/deviden-etf" component={(props) => <PendapatanDevidenEtf {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/generate-transaksi/transaksi-mtm" component={(props) => <TransaksiMarkToMarket {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/generate-transaksi/transaksi-ai" component={(props) => <TransaksiAccruedInterest {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/generate-transaksi/transaksi-amr" component={(props) => <TransaksiAmortisasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/move/efek" component={(props) => <PemindahanPortoEfek {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/settlement/move/rek" component={(props) => <PemindahanPortoRekening {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/pasar-modal/pengajuan-penempatan-investasi" component={(props) => <PengajuanPenempatan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/non-pasar-modal/pengajuan-penempatan-investasi" component={(props) => <PengajuanPenempatan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pasar-modal/portofolio" component={(props) => <PorfolioPasarModal {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/pengajuan/deposito/upti" component={(props) => <PengajuanPenempatanDeposito {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/deposito/upsi" component={(props) => <PengajuanPelepasanDeposito {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/deposito/perpanjangan" component={(props) => <PengajuanPerpanjanganDeposito {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/obligasi/upti" component={(props) => <PengajuanPenempatanObligasiKorporasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/obligasi/upsi" component={(props) => <PengajuanPelepasanObligasiKorporasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/saham/upti" component={(props) => <PengajuanPenempatanSaham {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/saham/upsi" component={(props) => <PengajuanPelepasanSaham {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/saham/ipo" component={(props) => <PenyertaanIpoSaham {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/reksadana/upti" component={(props) => <PengajuanPenempatanReksadana {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/reksadana/upsi" component={(props) => <PengajuanPelepasanReksadana {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/reksadana/pengalihan" component={(props) => <PengajuanPengalihanReksadana {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/mtn/upti" component={(props) => <PengajuanPenempatanMediumTermNote {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/mtn/upsi" component={(props) => <PengajuanPelepasanMediumTermNote {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/properti/upti" component={(props) => <PengajuanPenempatanProperti {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/properti/upsi" component={(props) => <PengajuanPelepasanProperti {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/sbn/upti" component={(props) => <PengajuanPenempatanSbn {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/sbn/upsi" component={(props) => <PengajuanPelepasanSbn {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/etf/upti" component={(props) => <PengajuanPenempatanExchangeTrade {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/etf/upsi" component={(props) => <PengajuanPelepasanExchangeeTradedFund {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/eba/upti" component={(props) => <PengajuanPenempatanEba {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/eba/upsi" component={(props) => <PengajuanPelepasanEba {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/penyertaan/upti" component={(props) => <PengajuanPenempatanPl {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengajuan/penyertaan/upsi" component={(props) => <PengajuanPelepasanPl {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/order/obligasi" component={(props) => <Obligasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/order/reksadana" component={(props) => <OrderReksadana {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/order/deposito" component={(props) => <OrderDeposito {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/order/saham" component={(props) => <OrderSaham {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/order/etf" component={(props) => <OrderExchange {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/order/eba" component={(props) => <OrderEba {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/order/properti" component={(props) => <OrderProperti {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/order/ipo" component={(props) => <OrderIpoSaham {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/instruksi/bank/aktif" component={(props) => <InstruksiBankAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/bank/terkirim" component={(props) => <InstruksiBankTerkirim {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/bank/dibatalkan" component={(props) => <InstruksiBankDibatalkan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/aktif" component={(props) => <InstruksiBrokerAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/terkirim" component={(props) => <InstruksiBrokerTerkirim {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/dibatalkan" component={(props) => <InstruksiBrokerDibatalkan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/mi/aktif" component={(props) => <InstruksiMiAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/mi/terkirim" component={(props) => <InstruksiMiTerkirim {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/mi/dibatalkan" component={(props) => <InstruksiMiDibatalkan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/kustodi/aktif" component={(props) => <InstruksiKustodiAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/kustodi/terkirim" component={(props) => <InstruksiKustodiTerkirim {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/obligasi/aktif" component={(props) => <InstruksiBrokerObligasiAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/obligasi/terkirim" component={(props) => <InstruksiBrokerObligasiTerkirim {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/etf/aktif" component={(props) => <InstruksiBrokerEtfAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/etf/terkirim" component={(props) => <InstruksiBrokerEtfTerkirim {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/eba/aktif" component={(props) => <InstruksiBrokerEbaAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/eba/terkirim" component={(props) => <InstruksiBrokerEbaTerkirim {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/ipo/aktif" component={(props) => <InstruksiBrokerIpoAktif {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/instruksi/broker/ipo/terkirim" component={(props) => <InstruksiBrokerIpoTerkirim {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/pengaturan/kpa" component={(props) => <KPA {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/pengaturan/invoice-kpa" component={(props) => <InvoiceKpa {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/laporan/finansial-produk/hasil-investasi" component={(props) => <LaporanHasilInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/finansial-produk/aset-neto" component={(props) => <LaporanAsetNeto {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/finansial-produk/perubahan-aset-neto" component={(props) => <LaporanPerubahanAsetNeto {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/finansial-produk/neraca-produk" component={(props) => <LaporanNeraca {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/transaksi/interest-deposito-per-bank" component={(props) => <InterestDepositoPerBank {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/transaksi/transaksi-per-akun" component={(props) => <LaporanTransaksiPerAkun {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/transaksi/transaksi-per-institusi" component={(props) => <LaporanTransaksiPerInstitusi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/transaksi/pengelola" component={(props) => <LaporanTransaksiPerPengelola {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/transaksi/pengajuan-investasi" component={(props) => <LaporanPengajuanInvestasi {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/portfolio/resume-jenis-instrumen" component={(props) => <LaporanResumeJenisInstrument {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/portfolio/komposisi-resume" component={(props) => <LaporanRoiPerKategori {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/portfolio/komposisi-detail" component={(props) => <LaporanKomposisiDetail {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/laporan/portfolio/laporan-per-pihak" component={(props) => <LaporanPerPihak {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/manajemen-risiko/sebaran-risiko" component={(props) => <SebaranRisiko {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/manajemen-risiko/matrikulasi-risiko" component={(props) => <MatrikulasiRisiko {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/persetujuan/list" component={(props) => <ApprovalPengajuan {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/persetujuan/history" component={(props) => <HistoryApprovalPengajuan {...props} store={this.props.store} />} />

                    <Route exact path="/investasi/proses-akhir-hari" component={(props) => <ProsesAkhirHari {...props} store={this.props.store} />} />
                    <Route exact path="/investasi/proses-akhir-bulan" component={(props) => <ProsesAkhirBulan {...props} store={this.props.store} />} />

                    {/* SDM UMUM */}
                    <Route exact path="/sdm-umum/administrasi-sdm/data-karyawan" component={(props) => <DataKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/administrasi-sdm/lembur" component={(props) => <Lembur {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/administrasi-sdm/gaji" component={(props) => <Gaji {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/pengaturan-sdm/cuti/tipe-cuti" component={(props) => <TipeCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/cuti/periode" component={(props) => <PeriodeCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/dokumen/tipe-dokumen" component={(props) => <TipeDokumen {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/dokumen/tipe-sk" component={(props) => <TipeSK {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pekerjaan/jabatan" component={(props) => <SdmJabatan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pekerjaan/title" component={(props) => <TitleKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pekerjaan/kinerja" component={(props) => <Kinerja {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pekerjaan/group-kompetensi" component={(props) => <GroupKompetensi {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pekerjaan/kompetensi" component={(props) => <Kompetensi {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pekerjaan/gol-jabatan" component={(props) => <GolonganJabatan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pekerjaan/status-Karyawan" component={(props) => <StatusKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/jam-kerja/shift" component={(props) => <JamKerjaShift {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/jam-kerja/lembur" component={(props) => <JamKerjaLembur {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/kualifikasi/keahlian" component={(props) => <Keahlian {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/kualifikasi/pendidikan" component={(props) => <Pendidikan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/kualifikasi/sertifikasi" component={(props) => <Sertifikasi {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/kualifikasi/bahasa" component={(props) => <Bahasa {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/perjalanan-dinas/tipe-perjalanan-dinas" component={(props) => <TipePerjalananDinas {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/perjalanan-dinas/tipe-biaya-perjalanan-dinas" component={(props) => <TipeBiayaPerjalananDinas {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/perjalanan-dinas/biaya-perjalanan-dinas" component={(props) => <BiayaPerjalananDinas {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/gaji/tunjangan" component={(props) => <Tunjangan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/gaji/tipe-pinjaman" component={(props) => <Pinjaman {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/gaji/potongan" component={(props) => <Potongan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/gaji/tunjangan-lain" component={(props) => <TunjanganLain {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/gaji/tunjangan-medis" component={(props) => <TunjanganMedis {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/gaji/grade" component={(props) => <GradeSDM {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/umum/akun" component={(props) => <PengaturanAkun {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/umum/kas-umum" component={(props) => <PengaturanKasUmum {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/umum/supplier" component={(props) => <PengaturanSupplier {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/umum/rekening-bank" component={(props) => <PengaturanRekeningBank {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/umum/tipe-pinjaman" component={(props) => <PengaturanTipePinjaman {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pengaturan-aset/kategori" component={(props) => <AssetCategory {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengaturan-sdm/pengaturan-aset/sub-kategori" component={(props) => <AssetCategorySub {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/payroll/thr/pengajuan" component={(props) => <PengajuanThr {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/thr/daftar-transaksi-thr" component={(props) => <DaftarTransaksiThr {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/bonus-tahunan/pengajuan" component={(props) => <PengajuanBonusTahunan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/bonus-tahunan/daftar-transaksi-bonus" component={(props) => <DaftarTransaksiBonus {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/payroll-gaji/pengajuan" component={(props) => <PengajuanGaji {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/payroll-gaji/daftar-transaksi-penggajian" component={(props) => <DaftarTransaksiPenggajian {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/payroll-gaji/daftar-transaksi-gaji" component={(props) => <DaftarTransaksiGaji {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/tunjangan-lain/pengajuan" component={(props) => <PembayaranTunjanganLain {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/tunjangan-lain/daftar-transaksi-tunjangan-lain" component={(props) => <DaftarPembayaranTunjanganLain {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/tunjangan-medical/pengajuan" component={(props) => <PembayaranTunjanganMedical {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/payroll/tunjangan-medical/daftar-transaksi-tunjangan-medical" component={(props) => <DaftarPembayaranTunjanganMedical {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/aset/daftar-aset" component={(props) => <DaftarAset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/aset/serah-terima-aset" component={(props) => <SerahTerimaAset {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/aset/index" component={(props) => <FixedAsset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/aset/grup" component={(props) => <FixedAssetGroup {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/aset/disposal" component={(props) => <FixedAssetDisposal {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/aset/penyusutan" component={(props) => <FixedAssetDeprectiation {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/aset/pemeliharaan" component={(props) => <FixedAssetExpense {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/pemeliharaan-aset/pengajuan-pemeliharaan-aset" component={(props) => <PengajuanPemeliharaanAset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/pemeliharaan-aset/pemeliharaan-aset" component={(props) => <PemeliharaanAset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/pemeliharaan-aset/riwayat-pemeliharaan-aset" component={(props) => <RiwayatPemeliharaanAset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/penghapusan/pengajuan-penghapusan-aset" component={(props) => <PengajuanPenghapusanAset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/penghapusan/verifikasi-penghapusan" component={(props) => <VerifikasiManager {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/penghapusan/persetujuan-penghapusan" component={(props) => <VerifikasiDireksi {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/penghapusan/penghapusan-aktif" component={(props) => <PenghapusanAktifAsset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/penghapusan/penghapusan-riwayat" component={(props) => <RiwayatPenghapusanAset {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/absensi/absensi" component={(props) => <Absensi {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/daftar-karyawan" component={(props) => <DaftarKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/gaji-tunjangan" component={(props) => <KaryawanGaji {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/dokumen" component={(props) => <DokumenKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/pinjaman" component={(props) => <PinjamanKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/surat-keputusan" component={(props) => <SuratKeputusanKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/shift" component={(props) => <ShiftKaryawan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/dapen" component={(props) => <KaryawanPesertaDapen {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/karyawan/potongan" component={(props) => <KaryawanPotongan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/program-dapen/peserta-dapen" component={(props) => <KaryawanPesertaDapen {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/program-dapen/pengajuan-pensiun" component={(props) => <ProgramDapenPengajuanPensiun {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/program-dapen/pensiun" component={(props) => <ProgramDapenPensiun {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/program-dapen/kepesertaan" component={(props) => <ProgramDapenKepesertaan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kepegawaian-cuti/pengajuan-cuti" component={(props) => <KepegawaianPengajuanCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kepegawaian-cuti/verifikasi-cuti" component={(props) => <KepegawaianVerifikasiCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kepegawaian-cuti/persetujuan-cuti" component={(props) => <KepegawaianPersetujuanCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kepegawaian-cuti/daftar-cuti" component={(props) => <KepegawaianDaftarCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kepegawaian-cuti/riwayat-cuti" component={(props) => <KepegawaianRiwayatCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kepegawaian-cuti/pembatalan-cuti" component={(props) => <KepegawaianPembatalanCuti {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/lembur/daftar-lembur" component={(props) => <KepegawaianDaftarLembur {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/lembur/pengajuan-lembur" component={(props) => <KepegawaianPengajuanLembur {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/lembur/verifikasi-lembur" component={(props) => <KepegawaianVerifikasiLembur {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/lembur/persetujuan-lembur" component={(props) => <KepegawaianPersetujuanLembur {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/lembur/riwayat-lembur" component={(props) => <KepegawaianRiwayatLembur {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/rapel/pengajuan-rapel" component={(props) => <PengajuanRapel {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/rapel/daftar-rapel" component={(props) => <DaftarRapel {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/rekurtmen/pengajuan-rekurtmen" component={(props) => <PengajuanRekruitmen {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/rekurtmen/rekurtmen-aktif" component={(props) => <RekruitmenAktif {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/rekurtmen/riwayat-rekurtmen" component={(props) => <RiwayatRekruitmen {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/kepegawaian/pemberhentian/pengajuan-pemberhentian" component={(props) => <PengajuanPemberhentian {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/pemberhentian/pemberhentian" component={(props) => <DaftarPemberhentian {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/perjalanan-dinas/penugasan" component={(props) => <KepegawaianPenugasan {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/perjalanan-dinas/perjalanan-dinas" component={(props) => <KepegawaianPerjalananDinas {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kinerja/rotasi" component={(props) => <Rotasi {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kinerja/evaluasi-kinerja" component={(props) => <EvaluasiKinerja {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/kepegawaian/kinerja/hasil-kinerja:periode" component={(props) => <EvaluasiKinerja {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/daftar-riwayat-pegawai" component={(props) => <DaftarRiwayatPegawai {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/data-sdm" component={(props) => <DataSDM {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/pengadaan/pengajuan" component={(props) => <UmumPengadaanPengajuan {...props} store={this.props.store} type='pengajuan' />} />
                    <Route exact path="/sdm-umum/umum/pengadaan/pemeriksaanPersetujuan" component={(props) => <PersetujuanPengurus {...props} store={this.props.store} type="persetujuan" />} />
                    <Route exact path="/sdm-umum/umum/pengadaan/riwayat" component={(props) => <UmumPengadaanPengajuan {...props} store={this.props.store} type='riwayat' />} />

                    <Route exact path="/sdm-umum/umum/pembelian/tagihan" component={(props) => <UmumPembelianTagihan {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/proyek/pengajuan-proyek" component={(props) => <PengajuanProyek  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/proyek/kontrak-proyek" component={(props) => <KontrakProyek  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/proyek/tagihan-proyek" component={(props) => <TagihanProyek  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/proyek/penutupan-proyek" component={(props) => <PenutupanProyek  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/proyek/riwayat-proyek" component={(props) => <RiwayatProyek  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/kas-umum/req-um" component={(props) => <PengisianKasUmum  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/kas-umum/receive-um" component={(props) => <RiwayatPengisianKasUmum  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/kas-umum/pengembalian-um" component={(props) => <PengembalianKasUmum  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/pembayaran/pengajuan" component={(props) => <UmumPengajuanPembayaran  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/pembayaran/riwayat" component={(props) => <UmumRiwayatPembayaran  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/umum/pembelian/pemesanan" component={(props) => <UmumPembelianPemesanan type="pemesanan"  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/pembelian/penerimaan" component={(props) => <UmumPembelianPenerimaan  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/umum/pembelian/riwayat" component={(props) => <UmumPembelianPemesanan type="riwayat"  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/pengajuan-pembayaran-umum/pengajuan-pembayaran" component={(props) => <PPUPengajuanPembayaran  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengajuan-pembayaran-umum/verifikasi-manager" component={(props) => <PPUVerifikasiManager  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengajuan-pembayaran-umum/verifikasi-sdm-umum" component={(props) => <PPUVerifikasiSdmu  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pengajuan-pembayaran-umum/persetujuan-pengurus" component={(props) => <PPUPersetujuanPengurus  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/pembayaran-umum/pengajuan-aktif" component={(props) => <PPPengajuanAktif  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/pembayaran-umum/riwayat-pembayaran-umum" component={(props) => <PPRiwayatPembayaranUmum  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/report-sdm/report-karyawan/daftar-karyawan" component={(props) => <LaporanDaftarKaryawan  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-karyawan/cuti" component={(props) => <LaporanKaryawanCuti  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-karyawan/resume-cuti" component={(props) => <LaporanResumeCuti  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-karyawan/perjalanan-dinas" component={(props) => <LaporanKaryawanPerjalananDinas  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-karyawan/sk" component={(props) => <LaporanKaryawanSk  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-karyawan/dokumen" component={(props) => <LaporanKaryawanDokumen  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/report-sdm/report-gaji/daftar-gaji" component={(props) => <LaporanDaftarTransaksiGaji  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-gaji/daftar-pinjaman" component={(props) => <LaporanDaftarPinjaman  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-gaji/daftar-tunjangan" component={(props) => <LaporanDaftarTunjangan  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/report-sdm/report-gaji/absensi" component={(props) => <LaporanDaftarAbsensi  {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/anggaran/anggaran-unit/request" component={(props) => <AnggaranUnit stepProses="REQ" tipeProses="input"  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/anggaran/anggaran-unit/verifikasi" component={(props) => <AnggaranUnit stepProses="VER" tipeProses="input" {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/anggaran/anggaran-unit/approval" component={(props) => <AnggaranUnit stepProses="APP" tipeProses="input" {...props} store={this.props.store} />} />

                    <Route exact path="/sdm-umum/anggaran/perubahan-anggaran/request" component={(props) => <AnggaranUnit stepProses="REQ" tipeProses="perubahan"  {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/anggaran/perubahan-anggaran/verifikasi" component={(props) => <AnggaranUnit stepProses="VER" tipeProses="perubahan" {...props} store={this.props.store} />} />
                    <Route exact path="/sdm-umum/anggaran/perubahan-anggaran/approval" component={(props) => <AnggaranUnit stepProses="APP" tipeProses="perubahan" {...props} store={this.props.store} />} />
                    {/* GLOBAL SETTING AND PARAMETER */}
                    <Route exact path="/global-setting/global-parameter" component={(props) => <GlobalParameter {...props} store={this.props.store} />} />
                    <Route exact path="/global-setting/forex-balancing-config" component={(props) => <ForexBalancingConfig {...props} store={this.props.store} />} />
                    <Route exact path="/global-setting/accounting-report-mapping" component={(props) => <CashFlowConfig {...props} store={this.props.store} />} />
                  </section>
                </article>
              </div>
            </div>
            {/* <Footer store={this.props.store}/> */}
          </Router>
        </Provider>

      </div>
    );
  }
}

export default App;
