import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import { provinces, cities, currency, managerInvestasis, product, instruments, productDefault, rdNeedConfirmByMi, kpds } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import ArrayStore from 'devextreme/data/array_store';
import { showLoading } from 'redux/actions/loading';


class ModalSettlementTransaksiReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataPembelian: {},
            dataMaster: {},
            actionType: null
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.subscriptionRef = React.createRef()
        this.masterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validateSubs()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]   

        this.dataMaster = [
            {
                dataField: 'referensiNo',
                label: {
                    text: 'No Konfirmasi',
                },
                editorOptions: {
                    onValueChanged: (e) => {
                        this.setState({
                            dataMaster: {
                                referensiNo: e.value
                            }
                        })
                    },
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confrimation number"
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose product"
                    }
                ]
            },
            {
                dataField: 'orderDate',
                label: {
                    text: 'Tanggal Order',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose order date"
                    }
                ]
            },
            {
                dataField: 'miId',
                label: {
                    text: 'Manager Investasi',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName',
                    searchEnabled: true,
                    onValueChanged: async(e) => {
                        var productId = this.state.dataMaster.productId
                        var orderDate = formatDate(this.state.dataMaster.orderDate)
                        var miId = this.state.dataMaster.miId
                        var selectOrderId = this.subscriptionRef.current.instance.getEditor('orderId')
                        var dataRd = await this.availableRdPenempatanConfirm(this.props.store, productId, miId, orderDate)
                        selectOrderId.option('dataSource', dataRd)
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Manager investasi"
                    }
                ]
            },
        ]

        this.TabDataSource = [
            {
                'id': 0,
                text: 'Pembelian'
            },
        ]

        this.itemSubcription = [
            {
                dataField: 'pengelola',
                label: {
                    alignment: 'left',
                    text: 'Pengelolaan'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    layout: 'horizontal',
                    items: ['Internal', 'KPD'],
                    onValueChanged: async(e) => {
                        let val = e.value
                        let dataMiId = this.state.dataMaster.miId;
                        if (val === 'Internal') {
                            this.subscriptionRef.current.instance.getEditor('kpd').option('value', 1)
                            this.subscriptionRef.current.instance.getEditor('kpd').option('readOnly', true)

                            this.subscriptionRef.current.instance.getEditor('produkReksadana').option('value', 1)
                            this.subscriptionRef.current.instance.getEditor('produkReksadana').option('readOnly', true)

                            this.subscriptionRef.current.instance.getEditor('nominal').option('value', 0)
                            this.subscriptionRef.current.instance.getEditor('nominal').option('readOnly', true)

                            this.subscriptionRef.current.instance.getEditor('orderId').option('editorType', 'dxSelectBox')
                        } else {
                            this.subscriptionRef.current.instance.getEditor('kpd').option('value', 0)
                            this.subscriptionRef.current.instance.getEditor('kpd').option('readOnly', false)

                            this.subscriptionRef.current.instance.getEditor('produkReksadana').option('value', 0)
                            this.subscriptionRef.current.instance.getEditor('produkReksadana').option('readOnly', false)

                            this.subscriptionRef.current.instance.getEditor('nominal').option('value', 0)
                            this.subscriptionRef.current.instance.getEditor('nominal').option('readOnly', false)

                            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments?managerInvestasiId.equals=${dataMiId}&size=9999`, 'GET');
                            let dataSource = new ArrayStore({
                                data: response,
                                key: 'id'
                            })

                            let selectProdukReksadana = this.subscriptionRef.current.instance.getEditor('produkReksadana')
                            selectProdukReksadana.option('dataSource', dataSource);

                            // let dataSourceOrder = new ArrayStore({
                            //     data: dataOrderId,
                            //     key: 'id'
                            // })
                        
                            // this.subscriptionRef.current.instance.getEditor('orderId').option('readOnly', false)
                            // this.subscriptionRef.current.instance.updateData('orderId', dataOrderId)
                            // this.subscriptionRef.current.instance.getEditor('orderId').option('value', orderId)
                            // console.log(this.state.dataPembelian)
                        }
                    }
                }
            },
            {
                dataField: 'orderId',
                label: {
                    alignment: 'left',
                    text: 'No Order'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'orderId', // contains the same values as the "statusId" field provides
                    displayExpr: 'orderCode', // provides display values
                    onSelectionChanged: (e) => {
                        var data = e.selectedItem
                        this.subscriptionRef.current.instance.getEditor('produkDapenId').option('value', data.productCode)
                        this.subscriptionRef.current.instance.getEditor('penerbitReksadanaId').option('value', data.miName)
                        this.subscriptionRef.current.instance.getEditor('produkReksadana').option('value', data.instrumentName)
                        this.subscriptionRef.current.instance.getEditor('nominal').option('value', data.amountOrder)
                        this.subscriptionRef.current.instance.getEditor('totalNominal').option('value', data.amountOrder)
                    }
                },
            },
            {
                dataField: 'kpd',
                label: {
                    alignment: 'left',
                    text: 'Tipe'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: []
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'kpdName'
                }
            },
            {
                dataField: 'produkDapenId',
                label: {
                    alignment: 'left',
                    text: 'Produk Dapen'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'penerbitReksadanaId',
                label: {
                    alignment: 'left',
                    text: 'Penerbit Reksadana'
                },
                editorOptions: {

                    readOnly: true
                }
            },
            {
                dataField: 'produkReksadana',
                label: {
                    alignment: 'left',
                    text: 'Produk Reksadana KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'instrumentName',
                    readOnly: true
                }
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose transaction date"
                    }
                ]
            },
            {
                dataField: 'paymentDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose payment date"
                    }
                ]
            },
            {
                dataField: 'settleDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Settle'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose settle date"
                    }
                ]
            },
            {
                dataField: 'nominal',
                label: {
                    alignment: 'left',
                    text: 'Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true,
                    onValueChanged: (e) => {
                        var nominal = e.value
                        this.subscriptionRef.current.instance.getEditor('totalNominal').option('value', nominal)
                    }
                }
            },
            {
                dataField: 'biayaTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Fee'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    onValueChanged: (e) => {
                        var fee = e.value
                        var nominal = this.subscriptionRef.current.instance.getEditor('nominal').option('value')
                        this.subscriptionRef.current.instance.getEditor('totalNominal').option('value', nominal + fee)
                    }
                },
            },
            {
                dataField: 'totalNominal',
                label: {
                    alignment: 'left',
                    text: 'Total Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    onSelectionChanged: (e) => {
                        this.subscriptionRef.current.instance.getEditor('rateCurrency').option('value', e.selectedItem.rate)
                    }
                },
            },
            {
                dataField: 'rateCurrency',
                label: {
                    alignment: 'left',
                    text: 'Rate Currency'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'biayaBank',
                label: {
                    alignment: 'left',
                    text: 'Biaya Bank/RTGS'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
        ]
    }

    show = async() => {
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            'bank-transactions/generate-no-konfirmasi/REKSADANA',
            'POST',
            {values: {}}
        ) 
        this.setState({
            popupVisible: true,
            dataMaster: {
                referensiNo: response
            },
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
        this.props.forceRefresh()
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }
    validateSubs = () => {
        var formValidation = this.subscriptionRef.current.instance.validate();
        formValidation = this.masterRef.current.instance.validate();
        return formValidation.isValid
    }
    availableRdPenempatanConfirm = async (store, productId, miId, orderDate) => {
        let loadAPI = `order-rd-pembelian-need-confirm/list?miId=${miId}&orderDate=${orderDate}&productId=${productId}`
        var getRd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRd,
            key: 'orderId'
        });

        return dataSource
    }
    submitData = async() => {
        this.props.store.dispatch(showLoading(true))

        let dataMaster = this.state.dataMaster;
        var refNo = this.state.dataMaster.referensiNo
        var dataPembelian = this.state.dataPembelian
        if(!dataPembelian.biayaTransaksi){
            dataPembelian.biayaTransaksi = 0
        }
        if(!dataPembelian.biayaBank){
            dataPembelian.biayaBank  = 0
        }
        dataPembelian.dueDate = dataPembelian.settleDate
        var dataSubmit = {
            referensiNo: refNo,
            ...dataPembelian,
            kpdId: 1,
            paymentRekBankId: 1,
        }

        if(dataSubmit.pengelola !== 'KPD'){
            try {
                var insertAPI = 'order-rds/konfirmasi-pembelian-reksadana'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: dataSubmit
                })

                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {

                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } else {
            try {
                
                let prefix = "TRREKKPD-B";
                let randomNumber = Math.floor(Math.random() * 1000000000);
                let paddedRandomNumber = randomNumber.toString().padStart(9, '0');
                let orderId = prefix + paddedRandomNumber;

                let dataValue = {
                    'amountNet': dataSubmit.totalNominal,
                    'amountTrs': dataSubmit.nominal,
                    'biayaBank': dataSubmit.biayaBank,
                    'biayaTransaksi': dataSubmit.biayaTransaksi,
                    'currencyId': dataSubmit.currencyId,
                    'currencyRate': dataSubmit.rateCurrency,
                    'dueDate': formatDate(dataSubmit.dueDate),
                    'instrumentId': dataSubmit.produkReksadana,
                    'kpdId': dataSubmit.kpd,
                    'miId': dataMaster.miId,
                    'paymentDate': formatDate(dataSubmit.paymentDate),
                    'paymentRekBankId': dataSubmit.paymentRekBankId,
                    'productId': 1,
                    'settleDate': formatDate(dataSubmit.settleDate),
                    'settleNo': orderId,
                    'transactionDate': dataSubmit.transactionDate
                }

                var insertAPI = 'transaction/transaksi-reksadana-kpd'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: dataValue
                })

                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {

                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async(type,data) => {    
        this.setState({
            dataMaster: data,
            // dataPembelian: dataPembelianValue,
            actionType: type
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Reksadana'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formDataMaster'}
                        ref={this.masterRef}
                        formData={this.state.dataMaster}
                        items={this.dataMaster}
                        labelLocation={"left"}
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id="tabindex-0" className={' mt-2 col-md-12 text-center' }>
                            <div className="row">
                                <div className="col-md-12" style={{overflowY: 'auto', height: 'calc(60vh - 200px)'}}>
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        ref={this.subscriptionRef}
                                        formData={this.state.dataPembelian}
                                        items={this.itemSubcription}
                                        scrollingEnabled={true}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalSettlementTransaksiReksadana
