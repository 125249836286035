import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { gender, banks } from 'dataSource/lookup'

class DataGridSaham extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {  
                displayFormat: 'Total',
                showInColumn : 'hargaRataRata'
            },
            {
                name : 'nilaiPerolehan',
                column: 'nilaiPerolehan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'nilaiPenutupan',
                column: 'nilaiPenutupan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'percentUnrealizedPl',
                showInColumn: 'percentUnrealizedPl',
                summaryType: 'custom',
                valueFormat: '#,##0.00',
                displayFormat : '{0}',
            },
            {
                name : 'nominalUnrelaizedPl',
                column: 'nominalUnrelaizedPl',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'percentKomposisi',
                column: 'percentKomposisi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
        ]

        this.columns = [
            {
                dataField: 'no.',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'saham',
                caption: 'Saham',
            },
            {
                dataField: 'emiten',
                caption: 'Emiten',
            },
            {
                dataField: 'kpdName',
                caption: 'Pengelola'
            },
            {
                dataField: 'jmlSaham',
                caption: 'Jumlah (Lembar)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'hargaAvg',
                caption: 'Harga Rata Rata',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiPerolehan',
                caption: 'Nilai Perolehan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'hargaPenutupan',
                caption: 'Harga Penutupan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiPenutupan',
                caption: 'Nilai Penutupan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'percentUnrealizedPl',
                caption: 'Unrealized P/L(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nominalUnrelaizedPl',
                caption: 'Unrealized P/L Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'percentKomposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
    }
    customCalculate = (options) => {
        if (options.name === 'percentUnrealizedPl') {
            if (options.summaryProcess === 'start') {
                options.totalValue = {
                    
                };
            } else if (options.summaryProcess === 'calculate') {
                options.totalValue = {
                    totalNilaiPenutupan : (options.totalValue.totalNilaiPenutupan || 0) + options.value.nilaiPenutupan,
                    totalnilaiPerolehan : (options.totalValue.totalnilaiPerolehan || 0) + options.value.nilaiPerolehan,
                }
            } else if (options.summaryProcess === 'finalize') {
                options.totalValue = ((options.totalValue.totalNilaiPenutupan - options.totalValue.totalnilaiPerolehan) / options.totalValue.totalnilaiPerolehan) * 100
            }
          }
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    keyField= 'saham'
                    loadAPI={`efek-balance-avgs/instrument-portofolio-saham?productId=${this.props.productId}`}
                    insertAPI='kas-banks'
                    updateAPI='kas-banks'
                    deleteAPI='kas-banks' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Portofolio Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Saham'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    calculateCustomSummary={this.customCalculate}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    height = {'calc(100vh - 336px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridSaham
