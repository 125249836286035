import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { daysDiff, formatUploadFileData } from 'plugin/helper';
import { product, pesertaAkunStatus } from 'dataSource/lookup';
import ProsesDataPensiunPasif from './actionProsesPensiun'
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'

class FormDataPensiunPasif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            pesertaId: 0,
            dataPeserta: {}
        }

        this.formRef = React.createRef()
        this.DataGridPensiunRef = React.createRef()
        this.modalRef = React.createRef()

        this.dataGridPensiun = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'cardNo',
                caption: 'Card No'
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'akunStatusId',
                caption: 'Status Akun Peserta',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },

        ]
    }

    passDataToParent = () => {
        var data = {
            dataPensiun: this.state.formDataPensiun,
            dataGridPensiun: this.DataGridPensiunRef.current.getDataSource(),
        }
        return data
    }

    removeState = () => {
        this.setState({
            pesertaId: 0,
            formData: {},
        })
    }

    removeItemDataGrid = () => {
        var beforeData = this.DataGridPensiunRef.current.getDataSource()
        for (var deleteData of beforeData) {
            this.deleteDataProduct(deleteData)
        }
    }
    reloadData = async (pesertaId = 0, dataAkun) => {
        var loadAPI = `pesertas/${pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${pesertaId}`
        var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKepegawaian, 'GET')

        var dataPeserta = {
            ...getPeserta,
            phdp: getKepegawaian.phdp,
            tanggalBerhenti: getKepegawaian.tanggalBerhenti
        }
        this.setState({
            dataPeserta: dataPeserta,
            formData: dataAkun,
            pesertaId: pesertaId
        })

        this.forceRefresh(true)
    }

    retrieveData = async () => {
        var pesertaId = this.state.pesertaId
        var akunId = this.state.formData.id || this.state.formData.ids
        var result = []
        if (akunId) {
            var loadAPI = `kepersertaan-products/${akunId}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            result.push(response)
        } else if (pesertaId) {
            var loadAPI = `kepersertaan-products/getAllSimpleProductByPesertaId/${pesertaId}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            for (var value of response) {
                if (this.props.actionType == 'add') {
                    value.akunStatusId = value.statusId || value.akunStatusId
                    if (value.statusId == 1 || value.statusId == 2 || value.statusId == 3 || value.statusId == 6) {
                        result.push(value)
                    }
                } else {
                    if (value.statusId == 1 || value.statusId == 6) {
                        result.push(value)
                    }
                }
            }
        }
        return result
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState => ({
                    DocumentData: {
                        ...prevState.DocumentData,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }
    showModal = async (data) => {
        var dataPerhitungan = {}
        data.statusId = data.statusId || data.akunStatusId
        var getKepesertaanProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByNikAndProductId/${this.state.dataPeserta.nik}/${data.productId}`, 'GET')
        if (getKepesertaanProduct.pensiunDate !== null && getKepesertaanProduct.pensiunType !== null) {
            dataPerhitungan = {}

            this.modalRef.current.show()
            this.modalRef.current.retrieveData(data, dataPerhitungan, this.state.dataPeserta, getKepesertaanProduct, false)
        } else {
            if (data.statusId === 1 || !data.pensiunDate) {
                dataPerhitungan = {}

                this.modalRef.current.show()
                this.modalRef.current.retrieveData(data, dataPerhitungan, this.state.dataPeserta, {}, true)

            } else {
                var loadAPIGlblParam = `global-parameters/get-by-paramCode/2%20UP`
                var getUsiaPensiun = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPIGlblParam, 'GET')

                var hari = Math.abs(daysDiff(this.state.dataPeserta.birthDate))
                var tahun = Math.round(hari / 365)
                if (tahun <= (getUsiaPensiun.intValue || 56)) {
                    try {
                        dataPerhitungan = await this.props.getPerhitunganPeserta(data.productId)
                        // if (!this.state.dataPeserta.masaKerjaTahun && !this.state.dataPeserta.masaKerjaBulan) {
                        //     // dataPerhitungan = {}
                        //     dataPerhitungan = await this.props.getPerhitunganPeserta(data.productId)
                        // } else {
                        //     dataPerhitungan = await this.props.getPerhitunganPeserta(data.productId)
                        // }

                        this.modalRef.current.show()
                        this.modalRef.current.retrieveData(data, dataPerhitungan, this.state.dataPeserta, {}, true)
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                } else {
                    notify({ message: 'Umur Peserta melebihi global param batas umur pensiun', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }
        }
    }

    forceRefresh = (param = false) => {
        this.DataGridPensiunRef.current.forceRefresh(param)
    }

    deleteDataProduct = (data) => {
        this.DataGridPensiunRef.current.push([
            {
                type: 'remove',
                key: data.id
            }
        ])
    }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.DataGridPensiunRef}
                    loadAPI='kepersertaan-products'
                    insertAPI='kepersertaan-products'
                    updateAPI='kepersertaan-products'
                    deleteAPI='kepersertaan-products'

                    height={'100%'}
                    useArraySource={true}
                    ArraySourceData={this.retrieveData}

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Pensiun'}
                    popupWidth={1000} //masukan dalam ukuran pixel
                    popupHeight={450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='top' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.dataGridPensiun} // taruh konfigurasi kolom disini
                    onToolbarPreparing={this.onToolbarPreparingDataAkun}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ProsesDataPensiunPasif
                    store={this.props.store}
                    ref={this.modalRef}
                    submitData={this.props.submitData}
                    forceRefresh={this.forceRefresh}
                    removeItemDataGrid={this.removeItemDataGrid}
                    pensiunan={false}
                    peridoeNs={this.props.peridoeNs}
                />
            </React.Fragment>
        )
    }
}

export default FormDataPensiunPasif
