import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { CheckBox } from 'devextreme-react';
import { ab2str, yyyymmddToDate, formatNumber, cleanQuote } from 'plugin/helper';
import XLSX from 'xlsx'

import { DataGrid, FileUploader } from "devextreme-react";
import { Paging, Column } from "devextreme-react/data-grid";
import { gender, agama, statusPegawai, unit,statusKaryawan,grade, months, jabatan, maritalStatus, kelurahans, provinces, cities, kecamatans, banks, product } from 'dataSource/lookup';
import { tax, kelurahan } from 'components/pages/parts/staticDataColumnConfig';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';

class UploadPasif extends Component {
    constructor(props){
        super(props)

        this.fileUploderRef = React.createRef();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = {
            popupVisible: false,
            uploadedData: [],
            // selectedTabIndex: 0,
            useHeader:false
        }
        
        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()

        this.successData = []
        this.errorData = []
        this.prevTabIndex = 0
        
        this.columns = [
            {
                dataField: 'id',
                caption: 'ID'
            },
            {
                dataField: 'nikPanjang',
                caption: 'NIK Panjang'
            },
            {
                dataField: 'nik',
                caption: 'NIK Pendek'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap'
            },
            {
                dataField: 'fullName',
                name: 'fullNamePenerimaMp',
                caption: 'Nama Penerima MP'
            },
            {
                dataField: 'nickName',
                caption: 'Nama Panggilan'
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'statusKepegawaianId',
                caption: 'Status Kepegawaian',
                lookup: {
                    dataSource: statusPegawai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values
                },
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir',
            },
            {
                dataField: 'birthPlace',
                name: 'birthPlacePenerimaMp',
                caption: 'Tempat Lahir Penerima MP',
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date'
            },
            {
                dataField: 'birthDate',
                name: 'birthDatePenerimaMp',
                caption: 'Tanggal Lahir Penerima MP',
                dataType: 'date'
            },
            {
                dataField: 'religionId',
                caption: 'Agama',
                lookup: {
                    dataSource: agama(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'religionId',
                name: 'religionIdPenerimaMp',
                caption: 'Agama Penerima MP',
                lookup: {
                    dataSource: agama(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'unitKerjaId',
                caption: 'Kode Unit Kerja',
                lookup: {
                    dataSource: unit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,

                }
            },
            {
                dataField: 'unitName',
                caption: 'Nama Unit Kerja',
            },
            {
                dataField: 'unitDiatas1',
                caption: 'Unit 1 Tingkat Diatas'
            },
            {
                dataField: 'unitDiatas2',
                caption: 'Unit 2 Tingkat Diatas'
            },
            {
                dataField: 'unitDiatas3',
                caption: 'Unit 3 Tingkat Diatas'
            },
            {
                dataField: 'jenisKantorId',
                caption: 'Jenis Kantor',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'PT'
                        },
                        {
                            id: 2,
                            value: 'CV'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,

                }
            },
            {
                dataField: 'statusKaryawanId',
                caption: 'Status Karyawan',
                lookup: {
                    dataSource: statusKaryawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,

                }
            },
            {
                dataField: 'tmtStatus',
                caption: 'TMT Status Karyawan'
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Diakui Masa Kerja',
                dataType:'date'
            },
            {
                dataField: 'grade',
                caption: 'Grade',
                lookup: {
                    dataSource: grade(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'gradeName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'tmtGrade',
                caption: 'TMT Grade Setingkat'
            },
            {
                dataField: 'mkTahunGrade',
                caption: 'MK Tahun Grade'
            },
            {
                dataField: 'mkBulanGrade',
                caption: 'MK Bulan Grade',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                }
            },
            {
                dataField: 'jabatanGolongan',
                caption: 'Jabatan Golongan',
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan Name',
            },
            {
                dataField: 'jabatanTanggal',
                caption: 'Jabatan Tanggal',
                dataType: 'date'
            },
            {
                dataField: 'penugasanGrade',
                caption: 'Grade Penugasan',
            },
            {
                dataField: 'penugasanJabatanId',
                caption: 'Penugasan Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values'
                }
            },
            {
                dataField: 'penugasanUnitId',
                caption: 'Penugasan Unit',
                lookup: {
                    dataSource: unit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'penugasanTanggal',
                caption: 'Penugasan Tanggal',
                dataType: 'date'
            },
            {
                dataField: 'indexPredikat',
                caption: 'Index Predikat',
            },
            {
                dataField: 'pendidikanId',
                caption: 'Pendidikan',
            },
            {
                dataField: 'pendidikanJurusan',
                caption: 'Nama Jurusan',
            },
            {
                dataField: 'pendidikanLembaga',
                caption: 'Nama Lembaga Pendidikan',
            },
            {
                dataField: 'noSkTerakhir',
                caption: 'No SK Terakhir',
            },
            {
                dataField: 'tanggalBerhenti',
                caption: 'Tanggal Berhenti',
                dataType: 'date'
            },
            {
                dataField: 'mariedId',
                caption: 'Status Perkawinan',
                lookup: {
                    dataSource: maritalStatus(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'mariedId',
                name: 'mariedIdPenerimaMp',
                caption: 'Status Perkawinan Penerima MP',
                lookup: {
                    dataSource: maritalStatus(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'statusKeluarga',
                caption: 'Status Keluarga',
            },
            {
                dataField: 'statusPajakId',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: tax(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'shortDesc', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'npwp',
                caption: 'No NPWP',
            },
            {
                dataField: 'statusTrt',
                caption: 'Status TRT',
            },
            {
                dataField: 'identityNo',
                caption: 'No KTP',
            },
            {
                dataField: 'noKk',
                caption: 'No KK',
            },
            {
                dataField: 'domicileAddress1',
                caption: 'Alamat 1',
            },
            {
                dataField: 'domicileKelurahanId',
                caption: 'Alamat Kelurahan',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileKecamatanId',
                caption: 'Alamat Kecamatan',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileCityId',
                caption: 'Alamat Kota/Kabupaten',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileProvinceId',
                caption: 'Alamat Provinsi',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileAddress1',
                name: 'domicileAddress1PenerimaMp',
                caption: 'Alamat 1 Penerima MP',
            },
            {
                dataField: 'domicileKelurahanId',
                name: 'domicileKelurahanIdPenerimaMp',
                caption: 'Alamat Kelurahan Penerima MP',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileKecamatanId',
                name: 'domicileKecamatanIdPenerimaMp',
                caption: 'Alamat Kecamatan Penerima MP',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileCityId',
                name: 'domicileCityIdPenerimaMp',
                caption: 'Alamat Kota/Kabupaten Penerima MP',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileProvinceId',
                name: 'domicileProvinceIdPenerimaMp',
                caption: 'Alamat Provinsi Penerima MP',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityAddress1',
                caption: 'Alamat 1',
            },
            {
                dataField: 'identityKelurahanId',
                caption: 'Alamat Kelurahan',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityKecamatanId',
                caption: 'Alamat Kecamatan',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityCityId',
                caption: 'Alamat Kota/Kabupaten',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityProvinceId',
                caption: 'Alamat Provinsi',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityAddress1',
                dataField: 'identityAddress1PenerimaMp',
                caption: 'Alamat 1 Penerima MP',
            },
            {
                dataField: 'identityKelurahanId',
                name: 'identityKelurahanIdPenerimaMp',
                caption: 'Alamat Kelurahan Penerima MP',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityKecamatanId',
                name: 'identityKecamatanIdPenerimaMp',
                caption: 'Alamat Kecamatan Penerima MP',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityCityId',
                name: 'identityCityIdPenerimaMp',
                caption: 'Alamat Kota/Kabupaten Penerima MP',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityProvinceId',
                name: 'identityProvinceIdPenerimaMp',
                caption: 'Alamat Provinsi Penerima MP',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'mobileNo',
                caption: 'Telepon Rumah Tinggal',
            },
            {
                dataField: 'mobileNo',
                name: 'mobileNoPenerimaMp',
                caption: 'Telepon Rumah Tinggal Penerima MP',
            },
            {
                dataField: 'mobilePhone',
                caption: 'Nomor Hp 1',
            },
            {
                dataField: 'mobilePhone',
                name: 'mobilePhonePenerimaMp',
                caption: 'Nomor Hp 1 Penerima MP',
            },
            {
                dataField: 'mobilePhone2',
                caption: 'Nomor HP 2',
            },
            {
                dataField: 'mobilePhone2',
                name: 'mobilePhone2PenerimaMp',
                caption: 'Nomor HP 2 Penerima MP',
            },
            {
                dataField: 'email',
                caption: 'Email 1',
            },
            {
                dataField: 'email',
                name: 'emailPenerimaMp',
                caption: 'Email 1 Penerima MP',
            },
            {
                dataField: 'email2',
                caption: 'Email 2',
            },
            {
                dataField: 'email2',
                name: 'email2PenerimaMp',
                caption: 'Email 2 Penerima MP',
            },
            {
                dataField: 'noBpjsTk',
                caption: 'No BPJS TK',
            },
            {
                dataField: 'rekeningNo',
                caption: 'No Rekening',
            },
            {
                dataField: 'rekeningBankId',
                caption: 'Nama Rekening',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'gelarDepan',
                caption: 'Gelar Didepan',
            },
            {
                dataField: 'gelarBelakang',
                caption: 'Gelar Dibelakang',
            },
            {
                dataField: 'usia',
                caption: 'Usia',
            },
            {
                dataField: 'usia',
                name:'usiaPenerimaMp',
                caption: 'Usia Penerima MP',
            },
            {
                dataField: 'namaAtasan',
                caption: 'Nama Atasan',
            },
            {
                dataField: 'statusFoto',
                caption: 'Status Foto',
            },
            {
                dataField: 'kodeMis',
                caption: 'Kode MIS',
            },
            {
                dataField: 'noBpjsKesehatan',
                caption: 'Nomor BPJS Kesehatan',
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
            },
            {
                dataField: 'upnp',
                caption: 'UPNP',
            },
            {
                dataField: 'thp',
                caption: 'Take Home Pay',
            },
            {
                dataField: 'productId',
                caption: 'Jenis Kepesertaan Program Pensiun',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'skPemberhentianNo',
                caption: 'No SK Henti',
            },
            {
                dataField: 'skPensiunDateHenti',
                caption: 'Tanggal SK Henti',
                dataType: 'date'
            },
            {
                dataField: 'pemberhentianEfektifDate',
                caption: 'Tanggal SK Henti',
                dataType: 'date'
            },
            {
                dataField: 'skPensiunDate',
                caption: 'SK Pensiun Date',
                dataType: 'date'
            },
            {
                dataField: 'skPensiunNo',
                caption: 'SK Pensiun No',
            },
            {
                dataField: 'pensiunEfektifDate',
                caption: 'Tanggal Mulai Pensiun',
            },
            {
                dataField: 'mpPertamaDate',
                caption: 'Tanggal Mulai Pembayaran Pensiun',
            },
            {
                dataField: 'stppp',
                caption: 'STPPP',
            },
            {
                dataField: 'daftarPenerimaMpDate',
                caption: 'Tanggal Surat Daftar Calon Penerima Pensiun',
                dataType: 'date'
            },
            {
                dataField: 'mkTahunSeluruh',
                caption: 'MK Tahun Keseluruhan',
                dataType: 'number'
            },
            {
                dataField: 'mkBulanSeluruh',
                caption: 'MK Tahun Keseluruhan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'pembayaranMpBankId',
                caption: 'Bank Rekening Pembayaran Pensiun (Berdasarkan DCPP)',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'pembayaranMpRekBankId',
                caption: 'Nomor Rekening Pembayaran (Berdasarkan DJPP)',
                // lookup: {
                //     dataSource: banks(this.props.store),
                //     valueExpr: 'id', // contains the same values as the "statusId" field provides
                //     displayExpr: 'bankName', // provides display values'
                //     searchEnabled: true,
                //     deferRendering: false,
                // },
            },
            {
                dataField: 'dcppAlamat',
                caption: 'Alamat Pensiun DCPP',
            },
            {
                dataField: 'dcppAddress1',
                caption: 'Alamat 1',
            },
            {
                dataField: 'dcppKelurahanId',
                caption: 'Alamat Kelurahan',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppKecamatanId',
                caption: 'Alamat Kecamatan',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppCityId',
                caption: 'Alamat Kota/Kabupaten',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppProvinceId',
                caption: 'Alamat Provinsi',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppKewarganegaraan',
                caption: 'Kewarganegaraan',
            },
        ]

        this.TabDataSource = [
            {
              id: 0,
              text: "Success Upload Data"
            },
            {
              id: 1,
              text: "Failed Upload Data"
            }
        ];

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan Data",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
              },
              {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Batal",
                  onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
              }
        ]
    }
    
    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    onUploadStarted = (e) => {
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if(fileType === 'xls' || fileType === 'xlsx' ){
                    var workbook = XLSX.read(dataArrayBuffer, {type: 'array'});
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)
                    
                    data = dataJSON.map(value => Object.values(value))
                }

                if(fileType === 'csv'){
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                       data = data.filter(value => 
                            {
                                regex.lastIndex = 0
                                return regex.test(value)
                            }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)
                            
                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)
    
                                var splitRes = splitRes.map(splitVal => splitVal.trim())
        
                                var splitRes = splitRes.map(splitVal => {
                                    if(String(splitVal)){
                                        return String(splitVal)
                                    }else{
                                        return splitVal
                                    }
                                })
    
                                return splitRes
                            }
                        })
                    }else{
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                var errorMessage = []
                // var reffId = data.map(value => value[19])
                // let getAvailableId = `reksadana-transactions/get-id-unit-allocation/strIdList/${reffId.join()}`

                // var availableId= await httpRequest(
                //     process.env.REACT_APP_BACKEND_CORE,
                //     this.props.store,
                //     getAvailableId,
                //     "GET"
                // );

                for(var uploadedData of data){
                    if(Array.isArray(uploadedData)){
                        errorMessage = []
                        
                        // if (!availableId.includes(parseInt(uploadedData[19]))) {
                        //     errorMessage.push('This transaction already settled')
                        // }

                        
                        try{
                            // let loadAPI
                            
                            // if(uploadedData[1] === "3" || uploadedData[1] === "4"){
                            //     loadAPI = `reksadana-switchings/saReference/${uploadedData[19]}`;
                            // }else{
                            //     loadAPI = `reksadana-transactions/saReference/${uploadedData[19]}`;
                            // }
                            
                            
                            // var selectedTransaction= await httpRequest(
                            //     process.env.REACT_APP_BACKEND_CORE,
                            //     this.props.store,
                            //     loadAPI,
                            //     "GET"
                            // );

                            // if(uploadedData[1] === 2){
                            //     errorMessage.push('Transaction type is Redemption')
                            // }

                            // if(uploadedData[1] === 3){
                            //     errorMessage.push('Transaction type is Switch-In')
                            // }

                            // if(uploadedData[1] === 4){
                            //     errorMessage.push('Transaction type is Switch-Out')
                            // }

                            // if(selectedTransaction.status == '1' ){
                            //     errorMessage.push('Transaction status is Order')
                            // }
                            // if(selectedTransaction.status == '2' ){
                            //     errorMessage.push('Transaction status is Paid')
                            // }
                            // if(selectedTransaction.status == '4' ){
                            //     errorMessage.push('Transaction status is Rejected')
                            // }
                            // if(selectedTransaction.status == '5' ){
                            //     errorMessage.push('Transaction status is Cancel')
                            // }
                            // if(selectedTransaction.status == '6' ){
                            //     errorMessage.push('Transaction status is Settle')
                            // }
                            // if(selectedTransaction.status == '7' ){
                            //     errorMessage.push('Transaction status is Rejected (Custody)')
                            // }
                            
                        }catch(e){
                            console.log(e)

                            // if(uploadedData.length !== 12){
                            //     let type = "error";
                            //     let text = "Format File Salah!";

                            //     notify(
                            //         {
                            //         message: text,
                            //         width: "AUTO",
                            //         shading: true,
                            //         position: { at: "center", my: "center", of: window }
                            //         },
                            //         type,
                            //         2000
                            //     );

                            //     break
                            // }

                            // errorMessage.push('Data Ini tidak tercantum dalam iuran!')

                            // errorData.push({
                            //     id : uploadedData[0],
                            //     nikPanjang: uploadedData[1],
                            //     nik: uploadedData[2],
                            //     fullName: uploadedData[3],
                            //     nickName: uploadedData[4],
                            //     sex: uploadedData[5],
                            //     statusKepegawaianId: uploadedData[6],
                            //     birthPlace: uploadedData[7],
                            //     birthPlace: uploadedData[8],
                            //     birthDate: uploadedData[9],
                            //     birthDate: uploadedData[10],
                            //     religionId: uploadedData[11],
                            //     religionId: uploadedData[12],
                            //     unitKerjaId: uploadedData[13],
                            //     unitName: uploadedData[14],
                            //     unitDiatas1: uploadedData[15],
                            //     unitDiatas2: uploadedData[16],
                            //     unitDiatas3: uploadedData[17],
                            //     jenisKantorId: uploadedData[18],
                            //     statusKaryawanId: uploadedData[19],
                            //     tmtStatus: uploadedData[20],
                            //     tanggalEfektif: uploadedData[21],
                            //     grade: uploadedData[22],
                            //     tmtGrade: uploadedData[23],
                            //     mkTahunGrade: uploadedData[24],
                            //     mkBulanGrade: uploadedData[25],
                            //     jabatanGolongan: uploadedData[26],
                            //     jabatanName: uploadedData[27],
                            //     jabatanTanggal: uploadedData[28],
                            //     penugasanGrade: uploadedData[29],
                            //     penugasanJabatanId: uploadedData[30],
                            //     penugasanUnitId: uploadedData[31],
                            //     penugasanTanggal: uploadedData[32],
                            //     indexPredikat: uploadedData[33],
                            //     pendidikanId: uploadedData[34],
                            //     pendidikanJurusan: uploadedData[35],
                            //     pendidikanLembaga: uploadedData[36],
                            //     noSkTerakhir: uploadedData[37],
                            //     tanggalBerhenti: uploadedData[38],
                            //     mariedId: uploadedData[39],
                            //     mariedId: uploadedData[40],
                            //     statusKeluarga: uploadedData[41],
                            //     statusPajakId: uploadedData[42],
                            //     npwp: uploadedData[43],
                            //     statusTrt: uploadedData[44],
                            //     identityNo: uploadedData[45],
                            //     noKk: uploadedData[46],
                            //     domicileAddress1: uploadedData[47],
                            //     domicileKelurahanId: uploadedData[48],
                            //     domicileKecamatanId: uploadedData[49],
                            //     domicileCityId: uploadedData[50],
                            //     domicileProvinceId: uploadedData[51],
                            //     domicileAddress1: uploadedData[52],
                            //     domicileKelurahanId: uploadedData[53],
                            //     domicileKecamatanId: uploadedData[54],
                            //     domicileCityId: uploadedData[55],
                            //     domicileProvinceId: uploadedData[56],
                            //     identityAddress1: uploadedData[57],
                            //     identityKelurahanId: uploadedData[58],
                            //     identityKecamatanId: uploadedData[59],
                            //     identityCityId: uploadedData[60],
                            //     identityProvinceId: uploadedData[61],
                            //     identityAddress1: uploadedData[62],
                            //     identityKelurahanId: uploadedData[63],
                            //     identityKecamatanId: uploadedData[64],
                            //     identityCityId: uploadedData[65],
                            //     identityProvinceId: uploadedData[66],
                            //     mobileNo: uploadedData[67],
                            //     mobilePhone: uploadedData[68],
                            //     mobilePhone: uploadedData[69],
                            //     mobilePhone2: uploadedData[70],
                            //     mobilePhone2: uploadedData[71],
                            //     email: uploadedData[72],
                            //     email: uploadedData[73],
                            //     email2: uploadedData[74],
                            //     email2: uploadedData[75],
                            //     noBpjsTk: uploadedData[76],
                            //     rekeningNo: uploadedData[77],
                            //     rekeningBankId: uploadedData[78],
                            //     gelarDepan: uploadedData[79],
                            //     gelarBelakang: uploadedData[80],
                            //     usia: uploadedData[81],
                            //     usia: uploadedData[82],
                            //     namaAtasan: uploadedData[83],
                            //     statusFoto: uploadedData[84],
                            //     kodeMis: uploadedData[85],
                            //     noBpjsKesehatan: uploadedData[86],
                            //     phdp: uploadedData[87],
                            //     upnp: uploadedData[88],
                            //     thp: uploadedData[89],
                            //     productId: uploadedData[90],
                            //     skPensiunNo: uploadedData[91],
                            //     skPensiunDateHenti: uploadedData[92],
                            //     pemberhentianEfektifDate: uploadedData[93],
                            //     skPensiunDate: uploadedData[94],
                            //     skPensiunNo: uploadedData[95],
                            //     pensiunEfektifDate: uploadedData[96],
                            //     mpPertamaDate: uploadedData[98],
                            //     stppp: uploadedData[99],
                            //     daftarPenerimaMpDate: uploadedData[100],
                            //     mkTahunSeluruh: uploadedData[101],
                            //     mkBulanSeluruh: uploadedData[102],
                            //     pembayaranMpBankId: uploadedData[103],
                            //     pembayaranMpRekBankId: uploadedData[104],
                            //     dcppAlamat: uploadedData[105],
                            //     dcppAddress1: uploadedData[106],
                            //     dcppKelurahanId: uploadedData[107],
                            //     dcppKecamatanId: uploadedData[108],
                            //     dcppCityId: uploadedData[109],
                            //     dcppProvinceId: uploadedData[110],
                            //     dcppKewarganegaraan: uploadedData[111],
                            // })

                            // continue
                        }
                        if(uploadedData.length !== 13){
                            let type = "error";
                            let text = "Format File Salah!";

                            notify(
                                {
                                message: text,
                                width: "AUTO",
                                shading: true,
                                position: { at: "center", my: "center", of: window }
                                },
                                type,
                                2000
                            );
                        }
                        
                        successData.push({
                            id : uploadedData[0],
                            nikPanjang: uploadedData[1],
                            nik: uploadedData[2],
                            fullName: uploadedData[3],
                            nickName: uploadedData[4],
                            sex: uploadedData[5],
                            statusKepegawaianId: uploadedData[6],
                            birthPlace: uploadedData[7],
                            birthPlace: uploadedData[8],
                            birthDate: uploadedData[9],
                            birthDate: uploadedData[10],
                            religionId: uploadedData[11],
                            religionId: uploadedData[12],
                            unitKerjaId: uploadedData[13],
                            unitName: uploadedData[14],
                            unitDiatas1: uploadedData[15],
                            unitDiatas2: uploadedData[16],
                            unitDiatas3: uploadedData[17],
                            jenisKantorId: uploadedData[18],
                            statusKaryawanId: uploadedData[19],
                            tmtStatus: uploadedData[20],
                            tanggalEfektif: uploadedData[21],
                            grade: uploadedData[22],
                            tmtGrade: uploadedData[23],
                            mkTahunGrade: uploadedData[24],
                            mkBulanGrade: uploadedData[25],
                            jabatanGolongan: uploadedData[26],
                            jabatanName: uploadedData[27],
                            jabatanTanggal: uploadedData[28],
                            penugasanGrade: uploadedData[29],
                            penugasanJabatanId: uploadedData[30],
                            penugasanUnitId: uploadedData[31],
                            penugasanTanggal: uploadedData[32],
                            indexPredikat: uploadedData[33],
                            pendidikanId: uploadedData[34],
                            pendidikanJurusan: uploadedData[35],
                            pendidikanLembaga: uploadedData[36],
                            noSkTerakhir: uploadedData[37],
                            tanggalBerhenti: uploadedData[38],
                            mariedId: uploadedData[39],
                            mariedId: uploadedData[40],
                            statusKeluarga: uploadedData[41],
                            statusPajakId: uploadedData[42],
                            npwp: uploadedData[43],
                            statusTrt: uploadedData[44],
                            identityNo: uploadedData[45],
                            noKk: uploadedData[46],
                            domicileAddress1: uploadedData[47],
                            domicileKelurahanId: uploadedData[48],
                            domicileKecamatanId: uploadedData[49],
                            domicileCityId: uploadedData[50],
                            domicileProvinceId: uploadedData[51],
                            domicileAddress1: uploadedData[52],
                            domicileKelurahanId: uploadedData[53],
                            domicileKecamatanId: uploadedData[54],
                            domicileCityId: uploadedData[55],
                            domicileProvinceId: uploadedData[56],
                            identityAddress1: uploadedData[57],
                            identityKelurahanId: uploadedData[58],
                            identityKecamatanId: uploadedData[59],
                            identityCityId: uploadedData[60],
                            identityProvinceId: uploadedData[61],
                            identityAddress1: uploadedData[62],
                            identityKelurahanId: uploadedData[63],
                            identityKecamatanId: uploadedData[64],
                            identityCityId: uploadedData[65],
                            identityProvinceId: uploadedData[66],
                            mobileNo: uploadedData[67],
                            mobilePhone: uploadedData[68],
                            mobilePhone: uploadedData[69],
                            mobilePhone2: uploadedData[70],
                            mobilePhone2: uploadedData[71],
                            email: uploadedData[72],
                            email: uploadedData[73],
                            email2: uploadedData[74],
                            email2: uploadedData[75],
                            noBpjsTk: uploadedData[76],
                            rekeningNo: uploadedData[77],
                            rekeningBankId: uploadedData[78],
                            gelarDepan: uploadedData[79],
                            gelarBelakang: uploadedData[80],
                            usia: uploadedData[81],
                            usia: uploadedData[82],
                            namaAtasan: uploadedData[83],
                            statusFoto: uploadedData[84],
                            kodeMis: uploadedData[85],
                            noBpjsKesehatan: uploadedData[86],
                            phdp: uploadedData[87],
                            upnp: uploadedData[88],
                            thp: uploadedData[89],
                            productId: uploadedData[90],
                            skPensiunNo: uploadedData[91],
                            skPensiunDateHenti: uploadedData[92],
                            pemberhentianEfektifDate: uploadedData[93],
                            skPensiunDate: uploadedData[94],
                            skPensiunNo: uploadedData[95],
                            pensiunEfektifDate: uploadedData[96],
                            mpPertamaDate: uploadedData[98],
                            stppp: uploadedData[99],
                            daftarPenerimaMpDate: uploadedData[100],
                            mkTahunSeluruh: uploadedData[101],
                            mkBulanSeluruh: uploadedData[102],
                            pembayaranMpBankId: uploadedData[103],
                            pembayaranMpRekBankId: uploadedData[104],
                            dcppAlamat: uploadedData[105],
                            dcppAddress1: uploadedData[106],
                            dcppKelurahanId: uploadedData[107],
                            dcppKecamatanId: uploadedData[108],
                            dcppCityId: uploadedData[109],
                            dcppProvinceId: uploadedData[110],
                            dcppKewarganegaraan: uploadedData[111],
                        })
                    }
                }

                if(errorData.length > 0){
                    let type = "error";
                    let text = "There are some upload data that failed to upload, please check failed upload data tab";
    
                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                }

                this.successData = successData
                this.errorData = errorData
                this.editDataGrid(this.successData, 'success')
            }
            reader.readAsArrayBuffer(files);
        }   
    }
    
    editDataGrid = async (data, type) => {
        if(type == 'success'){
            var dataGrid = this.dataGridSesuaiRef
        }else{
            var dataGrid = this.dataGridTakSesuaiRef
        }
        await this.deleteDataGrid(dataGrid)
        for (var value of data){
            try{
                var key = value.id
                if(key){
                    dataGrid.current.push([
                        {
                        type: 'update',
                        data : value,
                        key : key
                    }
                ])
            }else{
                dataGrid.current.push([
                    {
                        type: 'insert',
                        data : value
                    }
                ])
            }
            
            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'
            
            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
            }catch(e){
                console.log(e)
            }
        }
    }
    
    deleteDataGrid = (dataGrid) => {
        var data = dataGrid.current.getDataSource()

        for(var value of data){
            dataGrid.current.push([
                {
                    type: 'remove',
                    key : value.id
                }
            ])
        }
    }
    
    submitData = async() => {
        var dataSesuai = this.dataGridSesuaiRef.current.getDataSource()
        var dataTakSesuai = this.dataGridSesuaiRef.current.getDataSource()

        for(var value of dataSesuai){
            var dataPersonal = {
                fullName: value.fullName,
                nickName: value.nickName,
                sex : value.sex,
                birthPlace : value.birthPlace,
                birthDate : value.birthDate,
                religionId : value.religionId,
                pendidikanId : value.pendidikanId,
                pendidikanJurusan : value.pendidikanJurusan,
                pendidikanLembaga : value.pendidikanLembaga,
                mariedId : value.mariedId,
                statusKeluarga : value.statusKeluarga,
                statusPajakId : value.statusPajakId,
                npwp : value.npwp,
                identityNo : value.identityNo,
                noKk : value.noKk,
                domicileAddress1 : value.domicileAddress1,
                domicileKelurahanId : value.domicileKelurahanId,
                domicileKecamatanId : value.domicileKecamatanId,
                domicileCityId : value.domicileCityId,
                domicileProvinceId : value.domicileProvinceId,
                identityAddress1 : value.identityAddress1,
                identityKelurahanId : value.identityKelurahanId,
                identityKecamatanId : value.identityKecamatanId,
                identityCityId : value.domicileKelurahanId,
                identityProvinceId : value.identityProvinceId,
                mobileNo : value.mobileNo,
                mobilePhone : value.mobilePhone,
                mobilePhone2 : value.mobilePhone2,
                email : value.email,
                email2 : value.email2,
                noBpjsTk : value.noBpjsTk,
                rekeningNo : value.rekeningNo,
                rekeningBankId : value.rekeningBankId,
                gelarDepan : value.gelarDepan,
                gelarBelakang : value.gelarBelakang,
                usia : value.usia,
                statusFoto : value.statusFoto,
                noBpjsKesehatan : value.noBpjsKesehatan,
            }

            var dataKepegawaian = {
                pesertaId: 0,
                nikPanjang: value.nikPanjang,
                nik: value.nik,
                statusKepegawaianId: value.statusKepegawaianId,
                unitKerjaId: value.unitKerjaId,
                unitName: value.unitName,
                unitDiatas1: value.unitDiatas1,
                unitDiatas2: value.unitDiatas2,
                unitDiatas3: value.unitDiatas3,
                jenisKantorId: value.jenisKantorId,
                statusKaryawanId: value.statusKaryawanId,
                tmtStatus: value.tmtStatus,
                tanggalEfektif: value.tanggalEfektif,
                grade: value.grade,
                tmtGrade: value.tmtGrade,
                mkTahunGrade: value.mkTahunGrade,
                mkBulanGrade: value.mkBulanGrade,
                jabatanGolongan: value.jabatanGolongan,
                jabatanName: value.jabatanName,
                jabatanTanggal: value.jabatanTanggal,
                penugasanGrade: value.penugasanGrade,
                penugasanJabatanId: value.penugasanJabatanId,
                penugasanUnitId: value.penugasanUnitId,
                penugasanTanggal: value.penugasanTanggal,
                indexPredikat: value.indexPredikat,
                noSkTerakhir: value.noSkTerakhir,
                tanggalBerhenti: value.tanggalBerhenti,
                namaAtasan: value.namaAtasan,
                kodeMis: value.kodeMis,
                phdp: value.phdp,
                upnp: value.upnp,
                thp: value.thp,
            }

            var dataAkun = {
                pesertaId: 0,
                productId : value.productId,
                skPensiunNo : value.skPensiunNo,
                skPensiunDate : value.skPensiunDate,
                pemberhentianEfektifDate : value.pemberhentianEfektifDate,
                skPensiunDate : value.skPensiunDate,
                skPensiunNo : value.skPensiunNo,
                pensiunEfektifDate : value.pensiunEfektifDate,
                stppp : value.stppp,
                daftarPenerimaMpDate : value.daftarPenerimaMpDate,
                mkTahunSeluruh : value.mkTahunSeluruh,
                mkBulanSeluruh : value.mkBulanSeluruh,
                pembayaranMpBankId : value.pembayaranMpBankId,
                pembayaranMpRekBankId : value.pembayaranMpRekBankId,
                dcppAlamat : value.dcppAlamat,
                dcppAddress1 : value.dcppAddress1,
                dcppKelurahanId : value.dcppKelurahanId,
                dcppKecamatanId : value.dcppKecamatanId,
                dcppCityId : value.dcppCityId,
                dcppProvinceId : value.dcppProvinceId,
                dcppKewarganegaraan : value.dcppKewarganegaraan,
            }
            try {
                var insertAPIPersonal = 'pesertas'                    
                var responsePersonal = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIPersonal, 'POST', {
                        values:dataPersonal
                    }
                )
                
                if(responsePersonal){
                    dataKepegawaian.pesertaId = responsePersonal.id
                    var insertAPIKepegawaian = 'peserta-kepegawaians'                    
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIKepegawaian, 'POST', {
                            values:dataKepegawaian
                        }
                    )
    
                    dataAkun.pesertaId = responsePersonal.id
                    var insertAPIAkun = 'kepersertaan-products'                    
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIAkun, 'POST', {
                            values:dataAkun
                        }
                    )
                }
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        }
    }

    show = () => {
        this.setState({
          popupVisible: true
        });
    };
    
    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabuploadindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabuploadindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns];
        columns.unshift({
        dataType: "buttons",
        buttons: [
            {
            text: "Error Detail",
            hint: "Error Detail",
            icon: "warning",
            cssClass: "text-danger",
            onClick: e => {
                let type = "error";
                let text = e.row.data.errorMessage.join("\n");

                notify(
                {
                    message: text,
                    width: "AUTO",
                    shading: true,
                    position: { at: "center", my: "center", of: window }
                },
                type,
                2000
                );
            }
            }
        ]
        });

        return columns;
    }

    onValueChanged = (e) => {
        this.setState({
          useHeader:e.value
        })
    }

    render() {
        return (
            <Popup
            className={"popup"}
            visible={this.state.popupVisible}
            onHiding={this.hide}
            dragEnabled={false}
            showTitle={true}
            title={"Upload Peserta"}
            width={"90vw"}
            height={650}
            toolbarItems={this.PopupToolbarItem}
            >
                <div className="d-flex flex-row">
                    <div style={{width:"350px"}}>
                        <FileUploader
                            accept={".txt,.xls,.xlsx,.csv"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload Peserta File Disini"
                            showFileList={false}
                            name={"uploadPeserta"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                        <CheckBox 
                            value={this.state.useHeader} 
                            onValueChanged={this.onValueChanged}
                        />
                        <span className="ml-2">Use Header</span>
                    </div>
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabuploadindex-0" className={' mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataSuccess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Sukses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Sukses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabuploadindex-1" className={'d-none mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridTakSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataFailed}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Gagal"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Gagal'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    </div>
            </Popup>
        )
    }
}


export default UploadPasif