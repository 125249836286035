import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest'
import { productDefault, broker } from 'dataSource/lookup';
import { DataBrokerInvestasi, DataMasterBank } from 'components/pages/parts/staticDataColumnConfig';


class ModalRekeningBrokerInvestasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.updateData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        // this.dataMaster = [
        //     {
        //         dataField: 'productId',
        //         label: {
        //             text: 'Kode Produk'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: productDefault(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'productCode',
        //             readOnly: true
        //         }
        //     },
        //     {
        //         dataField: 'rekNo',
        //         label: {
        //             text: 'No. Rekening'
        //         }
        //     },
        //     {
        //         dataField: 'rekName',
        //         label: {
        //             text: 'Nama Rekening'
        //         }
        //     },
        //     {
        //         dataField: 'brokerId',
        //         label: {
        //             text: 'Broker'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: broker(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'brokerName',
        //             deferRendering: false,
        //             searchEnabled: true,
        //             openOnFieldClick: true
        //         }
        //     },
        //     {
        //         dataField: 'tanggalEfektif',
        //         label: {
        //             text: 'Tanggal Efektif'
        //         },
        //         editorType: 'dxDateBox',
        //         editorOptions: {
        //             displayFormat: 'dd MMM yyyy',
        //             useMaskBehavior: true,
        //             openOnFieldClick: true
        //         }
        //     },
        // ]
        this.dataMaster = DataBrokerInvestasi(this.props.store)
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show = async (type) => {
        this.setState({
            popupVisible: true,
            action: type,
            dataMaster: {
                productId: 1
            }
        })
    }

    retrieveData = async(type, data) => {
        this.setState({
            dataMaster: data,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }

    updateData = async () => {
        var data = this.state.dataMaster
        if (data) {
            try {
                let type = 'success'
                let text = 'Sukses Ubah Data!'
                var updateAPI = `staticdata-wf/rek-brokers`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                    values: data,
                    key: data.id
                }, data)
                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.props.forceRefresh()
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    submitData = async() => {
        var data = this.state.dataMaster
        if (data) {
            try {
                let type = 'success'
                let text = 'Sukses Input Data!'
                var insertAPI = `staticdata-wf/rek-brokers`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: data,
                })
                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.props.forceRefresh()
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'55vw'}
                    height="50vh"
                    title={`Form Edit Rekening`}
                    ref={this.PopupRef}
                    toolbarItems={this.props.actionType === 'add' ? this.popupToolbarItem : this.popupToolbarItem2}
                >
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={'dataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalRekeningBrokerInvestasi