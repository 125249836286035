import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { currency, banks, productDefault, fundManager, instrumentSubCategoryById, rekeningBank, instrumentTransactionTypes, needSettleSbnPelepasan, instruments } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatUploadFileData, imageSource, pembulatan } from 'plugin/helper';
import ScrollView from 'devextreme-react/scroll-view';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';


class ModalActionSettlementPelepasanObligasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataBank: {},
            actionType: null,
            rate: 0,
            couponLastDate: 0,
            couponNextDate: 0,
            dayBases: null,
            periodeCoupon: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.pphBb = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-B-Bond') : null
        this.feeCg = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-CG-Bond-DN') : null

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.formBankRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.getHoliday = []
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                caption: 'Transaksi',
                items: [
                    {
                        dataField: 'orderCode',
                        label: {
                            alignment: 'left',
                            text: 'No Order'
                        },
                        editorType: 'dxSelectBox',
                        // visible: this.props.kpdId == 1 ? true : false,
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'orderCode',
                            displayExpr: 'orderCode',
                            onSelectionChanged: async (e) => {
                                var data = e.selectedItem

                                if (data) {
                                    var dataOrderSbn = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-sbn-bond-sukuks/${data.orderObligasiId}`, 'GET')
                                    var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataOrderSbn.instrumentId}`, 'GET')
                                    var dataEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/${dataOrderSbn.efekBalanceBuyDateId}`, 'GET')
                                    var tanggalPenyelesaian = this.formRef.current.instance.getEditor('tanggalPenyelesaian').option('value')
                                    this.formRef.current.instance.getEditor('instrumentName').option('value', data.instrumentName)
                                    this.formRef.current.instance.getEditor('orderTypeId').option('value', dataOrderSbn.orderTypeId)
                                    this.formRef.current.instance.getEditor('faceValue').option('value', dataOrderSbn.faceValue)
                                    this.formRef.current.instance.getEditor('hargaPenjualan').option('value', dataOrderSbn.price)
                                    this.formRef.current.instance.getEditor('hargaPerolehan').option('value', dataEfekBalance.buyPrice)
                                    this.formRef.current.instance.getEditor('currencyId').option('value', dataInstrument.currencyId)
                                    this.formBankRef.current.instance.getEditor('rekBankId').option('value', dataOrderSbn.rekBankId)
                                    this.formRef.current.instance.updateData('capitalGain', dataOrderSbn.faceValue * ((dataOrderSbn.price - dataEfekBalance.buyPrice) / 100))
                                    this.setState({
                                        instrumentId: dataOrderSbn.instrumentId,
                                        orderSbnId: data.orderObligasiId,
                                        couponLastDate: dataInstrument.couponLastDate,
                                        couponNextDate: dataInstrument.couponNextDate,
                                        rate: dataInstrument.rate,
                                        dayBases: dataInstrument.dayBase,
                                        periodeCoupon: dataInstrument.couponPeriod,
                                    })
                                    this.hitungAccruedInterest(tanggalPenyelesaian, this.state.rate, this.state.couponLastDate, dataOrderSbn.faceValue, dataInstrument.dayBase, this.state.periodeCoupon, this.state.couponNextDate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            alignment: 'left',
                            text: 'SBN/BOND/Sukuk'
                        },
                        // editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true
                            // dataSource: instrumentObligasi(this.props.store),
                            // valueExpr: 'id',
                            // displayExpr: 'instrumentName',
                        }
                    },
                    {
                        dataField: 'orderTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentTransactionTypes(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'transactionName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalTransaksi',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            readOnly: true,
                            onValueChanged: async (e) => {
                                var today = new Date(e.value);
                                var tomorrow = new Date(today);
                                tomorrow.setDate(today.getDate() + 1);
                                tomorrow.toLocaleDateString();
                                if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                                    tomorrow.setDate(tomorrow.getDate() + 1);
                                    tomorrow.toLocaleDateString();
                                    if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                                        tomorrow.setDate(tomorrow.getDate() + 1);
                                        tomorrow.toLocaleDateString();
                                    }
                                }
                                // var holidays = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'holidays ', 'GET')
                                // if (this.getHoliday.find(val => new Date(val.holidayDate) == tomorrow)) {
                                //     tomorrow.setDate(tomorrow.getDate() + 1);
                                //     tomorrow.toLocaleDateString();
                                // }
                                this.formRef.current.instance.updateData('tanggalPembayaran', tomorrow)
                                this.formRef.current.instance.updateData('tanggalPenyelesaian', tomorrow)
                            }
                        }
                    },
                    {
                        dataField: 'tanggalPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Penerimaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'tanggalPenyelesaian',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Penyelesaian'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            onValueChanged: (e) => {
                                var tanggalPenyelesaian = e.value
                                var fv = this.formRef.current.instance.getEditor('faceValue').option('value')
                                this.hitungAccruedInterest(tanggalPenyelesaian, this.state.rate, this.state.couponLastDate, fv, this.state.dayBases, this.state.periodeCoupon, this.state.couponNextDate)
                            }
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            readOnly: true,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formRef.current.instance.updateData('kurs', e.selectedItem.rate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            alignment: 'left',
                            text: 'Rate'
                        },
                    },
                    {
                        dataField: 'hargaPerolehan',
                        label: {
                            alignment: 'left',
                            text: 'Harga Perolehan (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            alignment: 'left',
                            text: 'Face Value'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'hargaPenjualan',
                        label: {
                            alignment: 'left',
                            text: 'Harga Penjualan (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            onValueChanged: (e) => {
                                var fv = this.formRef.current.instance.getEditor('faceValue').option('value')
                                var hargaPerolehan = this.formRef.current.instance.getEditor('hargaPerolehan').option('value')
                                this.formRef.current.instance.updateData('proceed', fv * (e.value / 100))
                                this.formRef.current.instance.updateData('capitalGain', fv * ((e.value - hargaPerolehan) / 100))
                            }
                        }
                    },
                    {
                        dataField: 'proceed',
                        label: {
                            alignment: 'left',
                            text: 'Proceed'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bungaBerjalan',
                        label: {
                            alignment: 'left',
                            text: 'Bunga Berjalan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData('pphBungaBerjalan', e.value * (this.pphBb.floatValue / 100))

                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = e.value
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Bunga berjalan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pphBungaBerjalan',
                        label: {
                            alignment: 'left',
                            text: 'PPH Bunga Berjalan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = e.value
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'PPH Bunga berjalan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'capitalGain',
                        label: {
                            alignment: 'left',
                            text: 'Capital Gain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                this.formRef.current.instance.updateData('pajakCapitalGain', e.value * (this.feeCg.floatValue / 100))
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Capital Gain tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pajakCapitalGain',
                        label: {
                            alignment: 'left',
                            text: 'Pajak Capital Gain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = e.value
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'PPH Capital Gain tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'komisi',
                        label: {
                            alignment: 'left',
                            text: 'Komisi'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = e.value
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Komisi tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'biayaLainnya',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Lainnya'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = e.value
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Biaya lainnya tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'netProceed',
                        label: {
                            alignment: 'left',
                            text: 'Net Proceed'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
        ]
        this.formBank = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Bank',
                        items: [
                            {
                                dataField: 'rekBankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Bayar (Transfer)'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    }
                                }
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    alignment: 'left',
                                    text: 'Biaya Bank'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Catatan',
                        items: [
                            {
                                dataField: "catatan",
                                label: {
                                    visible: false
                                },
                                editorType: "dxTextArea",
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Dokumen',
                        items: [
                            {
                                dataField: "file",
                                label: {
                                    text: "File Konfirmasi",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    },
                ]
            }
        ]
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show = async (data, kpdId1, kpdId2) => {
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                biayaLainnya: data.biayaLainnya,
                bungaBerjalan: data.bungaBerjalan,
                capitalGain: data.capitalGain,
                catatan: data.catatan,
                faceValue: data.faceValue,
                hargaPembelian: data.hargaPembelian,
                hargaPenjualan: data.hargaPenjualan,
                hargaPerolehan: data.hargaPerolehan,
                komisi: data.komisi,
                kurs: data.kurs,
                proceed: data.proceed,
                netProceed: data.netProceed,
                orderObligasiId: data.orderSbnId,
                pajakCapitalGain: data.pajakCapitalGain,
                pasar: data.pasar,
                pphBungaBerjalan: data.pphBungaBerjalan,
                tanggalPembayaran: data.tanggalPembayaran,
                tanggalPenyelesaian: data.tanggalPenyelesaian,
                tanggalTransaksi: data.tanggalTransaksi,
                orderCode: data.orderCode,
                orderTypeId: data.orderTypeId,
                institusiId: data.institusiId,
                institusiBroker: data.institusiBroker,
                productId: data.productId,
                instrumentName: data.instrumentName,
                currencyId: data.currencyId,
            },
            dataBank: {
                biayaBank: data.biayaBank,
                rekBankId: data.rekBankId,
            },
            fileKonfirmasi: data.fileKonfirmasi,
            fileKonfirmasiContentType: data.fileKonfirmasiContentType,
            instrumentId: data.instrumentId,
            orderSbnId: data.orderSbnId,
            rate: data.rate,
            couponLastDate: data.couponLastDate,
            couponNextDate: data.couponNextDate,
            dayBases: data.dayBases,
            periodeCoupon: data.periodeCoupon,
        })


        // if (this.props.kpdId == 1) {
        var selectOrderId = this.formRef.current.instance.getEditor('orderCode')
        selectOrderId.option('dataSource', needSettleSbnPelepasan(this.props.store, data.productId, data.institusiId, data.institusiBroker))
        // }
    }
    hitungAccruedInterest = (tanggalPenyelesaian, rate, couponLastDate, faceValue, dayBase, periodeCoupon, couponNextDate) => {

        var date3 = new Date(tanggalPenyelesaian)
        var date4 = new Date(couponLastDate)
        var diffTime2 = Math.abs(date3 - date4)
        var tenor = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24))
        var selisihTahun = date3.getFullYear() - date3.getFullYear()
        var selisihBulan = date3.getMonth() - date4.getMonth()
        var selisihHari = date3.getDate() - date4.getDate()
        var diffTime = []
        var diffDays = []
        var dayBases = []
        var result = 0
        if (dayBase == 'BASE_ACT_ACT') {
            var date1 = new Date(couponNextDate)
            var date2 = new Date(couponLastDate)
            diffTime = Math.abs(date1 - date2)
            diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (periodeCoupon == 'SEMI_TAHUNAN') {
                dayBases = diffDays * 2
            } else if (periodeCoupon == 'TAHUNAN') {
                dayBases = diffDays
            } else if (periodeCoupon == 'TRIWULAN') {
                dayBases = diffDays * 4
            } else if (periodeCoupon == 'CATURWULAN') {
                dayBases = diffDays * 3
            } else if (periodeCoupon == 'MONTHLY') {
                dayBases = diffDays * 12
            }
        } else if (dayBase == 'BASE_ACT_360') {
            dayBases = 360
        } else if (dayBase == 'BASE_ACT_365') {
            dayBases = 365
        } else if (dayBase == 'BASE_30_360') {
            dayBases = 360 * (selisihTahun) + 30 * (selisihBulan) + (selisihHari)
        } else if (dayBase == 'BASE_30_365') {
            dayBases = 365 * (selisihTahun) + 30 * (selisihBulan) + (selisihHari)
        }
        result = faceValue * (rate / 100) * (tenor / dayBases)
        this.formRef.current.instance.getEditor('bungaBerjalan').option('value', (Math.floor(result / 1000) * 1000))
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Konfirmasi`]: formattedFileData.base64data,
                    [`${fieldName}KonfirmasiContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    hide() {
        this.getHoliday = []
        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataBank: {},
            actionType: null,
            rate: 0,
            couponLastDate: 0,
            couponNextDate: 0,
            dayBases: null,
            periodeCoupon: null
        })
    }
    previewFile = async () => {
        var src = imageSource(this.state.fileKonfirmasiContentType, this.state.fileKonfirmasi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    submitData = () => {
        var data = this.state.dataMaster
        var dataBank = this.state.dataBank
        this.props.submitDetailPelepasanData({
            id: data.id,
            biayaBank: dataBank.biayaBank,
            biayaLainnya: data.biayaLainnya,
            bungaBerjalan: data.bungaBerjalan,
            capitalGain: data.capitalGain,
            catatan: data.catatan,
            faceValue: data.faceValue,
            fileKonfirmasi: this.state.fileKonfirmasi,
            fileKonfirmasiContentType: this.state.fileKonfirmasiContentType,
            hargaPembelian: data.hargaPembelian,
            hargaPenjualan: data.hargaPenjualan,
            hargaPerolehan: data.hargaPerolehan,
            instrumentId: this.state.instrumentId,
            komisi: data.komisi,
            kurs: data.kurs,
            proceed: data.proceed,
            netProceed: data.netProceed,
            orderSbnId: this.state.orderSbnId,
            rate: this.state.rate,
            couponLastDate: this.state.couponLastDate,
            couponNextDate: this.state.couponNextDate,
            dayBases: this.state.dayBases,
            periodeCoupon: this.state.periodeCoupon,
            pajakCapitalGain: data.pajakCapitalGain,
            pasar: data.pasar,
            pphBungaBerjalan: data.pphBungaBerjalan,
            tanggalPembayaran: data.tanggalPembayaran,
            tanggalPenyelesaian: data.tanggalPenyelesaian,
            tanggalTransaksi: data.tanggalTransaksi,
            orderCode: data.orderCode,
            orderTypeId: data.orderTypeId,
            institusiId: data.institusiId,
            institusiBroker: data.institusiBroker,
            productId: data.productId,
            instrumentName: data.instrumentName,
            currencyId: data.currencyId,
            rekBankId: dataBank.rekBankId,
        })

        this.hide()
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pelepasan Obligasi'}
                minWidth={'60vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    ref={this.formRef}
                                    formData={this.state.dataMaster}
                                    items={this.items}
                                    labelLocation={"left"}
                                />
                            </div>
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataBank'}
                                    ref={this.formBankRef}
                                    formData={this.state.dataBank}
                                    items={this.formBank}
                                    labelLocation={"left"}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileKonfirmasi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                            </div>
                            <ModalPreviewPdf
                                ref={this.modalPreviewPdfRef}
                                store={this.props.store}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalActionSettlementPelepasanObligasi