import {
    MENU
  } from '../actions/menu'
  
  function menu(state=[], action){
    switch(action.type){
      case MENU:
        return action.menuType;
      default: 
        return state
    }
  }
  
  export default menu