import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import { currency, jabatan, jabatanGolongan, karyawan, sppdBiaya, sppdBiayaType } from 'dataSource/lookup';
import FormBiayaPenugasan from './formBiaya';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class BiayaPenugasanPerjalananDinas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {
                currencyId:1001,
                kurs:1
            }
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.sppdId = 0

        this.dataCurrent = []
        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
          ];
        this.formMaster = [
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Nama Karyawan'
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly : true
                }
            },
            {
                dataField: 'jabatanGolonganId',
                label: {
                    text: 'Golongan Jabatan'
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaJagol', // provides display values
                    readOnly : true
                },
            },
            {
                dataField: 'noPenugasan',
                label: {
                    text: 'No Penugasan',
                },
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: currency(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'currencyCode', // provides display values
                },
                visible: false
            },
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs'
                },
                visible: false
            },
        ]
        this.columnBiaya = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.hapusData(e.row.data)
                        }
                    },
                ]
            },
            // {
            //     dataField: 'noTugas',
            //     caption: 'No. Tugas'
            // },
            {
                dataField: 'biaya',
                caption: 'Biaya',
                alignment: 'center',
                columns : [
                    {
                        dataField: 'sppdBiayaId',
                        caption: 'Jenis Biaya',
                        lookup:{
                            dataSource: sppdBiaya(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'biayaKode'
                        }
                    },
                    {
                        dataField: 'biayaPerhari',
                        caption: 'Biaya/hari',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'jumlahHari',
                        caption: 'Jumlah Hari',
                    },
                    {
                        dataField: 'jumlahBiaya',
                        caption: 'Jumlah Biaya',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            }
        ]
        this.sumJumlahBiaya = [
            {
                showInColumn: 'jumlahHari',
                displayFormat: 'TOTAL BIAYA :'
            },
            {
                column: 'jumlahBiaya',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'jumlahBiayaSum',
                displayFormat: '{0}'
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster: {
                currencyId:1001,
                kurs:1
            }
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = async(data) => { 
        if (this.sppdId) {
            this.dataGridRef.current.forceRefresh(true);
        }   
        this.sppdId = data.id
        this.setState({
            DataMaster: data,
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Biaya',
                    onClick: (e) => {
                        this.showModalInsert(e)
                    },
                }
            }
        )
    }

    showModalInsert = () => {
        this.modalInsertRef.current.show()
        this.modalInsertRef.current.getSppdId(this.sppdId)
        this.modalInsertRef.current.getHariSppd(this.state.DataMaster.hari)
    }

    showModalEdit = async (data) => {
        this.modalEditRef.current.retrieveData(data)
        this.modalEditRef.current.show()
        this.modalEditRef.current.getSppdId(this.sppdId)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    hapusData = async (data) => {
        try {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-biayas`,
                "DELETE",data.id
            );
            this.forceRefresh()
            notify({ message: 'Sukses', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
        } catch (e) {
            console.log(e);
        }
        
    }

    loadDataGrid = async() => {
        var loadAPI = `karyawan-sppd-biayas/get-by-karyawanSppdId/${this.sppdId}`
        var getDataKaryawanBiaya = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        return getDataKaryawanBiaya
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Biaya Perjalanan Dinas"}
                width={"70vw"}
                height={"85vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="container-fluid">
                            <Form
                                colCount={1}
                                id={'formBiayaPenugasan'}
                                formData={this.state.DataMaster}
                                items={this.formMaster}
                                labelLocation = 'left'
                                readOnly={true}
                            />
                            <div className="my-3">
                                <DevExpressDataGrid
                                ref={this.dataGridRef}
                                    loadAPI='karyawan-sppd-biayas'
                                    insertAPI='karyawan-sppd-biayas'
                                    updateAPI='karyawan-sppd-biayas'
                                    deleteAPI='karyawan-sppd-biayas' 

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataGrid}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={'calc(100vh - 470px)'}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnBiaya} // taruh konfigurasi kolom disini
                                    summaryTotalItem={this.sumJumlahBiaya}
                                    onToolbarPreparing = {this.onToolbarPreparing}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <FormBiayaPenugasan
                                    ref={this.modalInsertRef}
                                    store={this.props.store}
                                    forceRefresh={this.forceRefresh}
                                    type={'add'}
                                />
                                <FormBiayaPenugasan
                                    ref={this.modalEditRef}
                                    store={this.props.store}
                                    forceRefresh={this.forceRefresh}
                                    type={'edit'}
                                />
                            </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default BiayaPenugasanPerjalananDinas