export const dummyData = [
    {
        id: 1,
        tahun: 2022,
        bulan: 'Januari',
        jatuhTempo: 2832947009,
        pembayaran: 2832947009,
        sisaPiutang: 0,
        orangShuSpi: 2849,
        jumlahShu: 1566023462,
        jumlahSpi: 1106671358,
        orangMpOther: 3008,
        jumlahMp: 7265069823,
        jumlahOther: 0
    },
    {
        id: 2,
        tahun: 2022,
        bulan: 'Februari',
        jatuhTempo: 2815001254,
        pembayaran: 2815001254,
        sisaPiutang: 0,
        orangShuSpi: 2829,
        jumlahShu: 1481620893,
        jumlahSpi: 993837131,
        orangMpOther: 3016,
        jumlahMp: 7294865684,
        jumlahOther: 0
    },
    {
        id: 3,
        tahun: 2022,
        bulan: 'Maret',
        jatuhTempo: 2790112306,
        pembayaran: 2790112306,
        sisaPiutang: 0,
        orangShuSpi: 2806,
        jumlahShu: 1439645151,
        jumlahSpi: 1063278634,
        orangMpOther: 3027,
        jumlahMp: 7332544215,
        jumlahOther: 0
    },
    {
        id: 4,
        tahun: 2022,
        bulan: 'April',
        jatuhTempo: 2768461724,
        pembayaran: 2768461724,
        sisaPiutang: 0,
        orangShuSpi: 2782,
        jumlahShu: 1438764919,
        jumlahSpi: 1180623844,
        orangMpOther: 3031,
        jumlahMp: 7341046093,
        jumlahOther: 0
    },
    {
        id: 5,
        tahun: 2022,
        bulan: 'Mei',
        jatuhTempo: 2729718255,
        pembayaran: 2729718255,
        sisaPiutang: 0,
        orangShuSpi: 2744,
        jumlahShu: 1530744591,
        jumlahSpi: 996679921,
        orangMpOther: 3056,
        jumlahMp: 7406204859,
        jumlahOther: 0
    },
    {
        id: 6,
        tahun: 2022,
        bulan: 'Juni',
        jatuhTempo: 2695039144,
        pembayaran: 2695039144,
        sisaPiutang: 0,
        orangShuSpi: 2711,
        jumlahShu: 1528700527,
        jumlahSpi: 1163618486,
        orangMpOther: 2767,
        jumlahMp: 6864851318,
        jumlahOther: 0
    },
    {
        id: 7,
        tahun: 2022,
        bulan: 'Juli',
        jatuhTempo: 2671928338,
        pembayaran: 2671928338,
        sisaPiutang: 0,
        orangShuSpi: 2686,
        jumlahShu: 1561049426,
        jumlahSpi: 1115304950,
        orangMpOther: 2935,
        jumlahMp: 7247967881,
        jumlahOther: 0
    }
]