
import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { kasUmum, programs, rekBank, statusPengisianKasUmumSdm, statusAktifNonAktif, statusPengisianKasUmumSdm2 } from 'dataSource/lookup';
import FormJurnalModalRiwayatPengisianKasUmum from 'components/pages/modal/sdm-umum/umum/kas-umum/riwayat-pengisian-kas-umum/jurnal';
import { confirmAlert } from 'react-confirm-alert'
import httpRequest from 'plugin/httprequest'
import { reportFile } from 'plugin/reportRequest'
import { formatDefaultFullDate, formatNumber } from 'plugin/helper';
import { alert, confirm } from 'devextreme/ui/dialog';

class RiwayatPengisianKasUmum extends Component {
    constructor(props){
        super(props)

        this.dataSource = [{}];
        this.dataGridRef = React.createRef();

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    // {
                    //     text: 'Lihat Jurnal',
                    //     hint: 'Lihat Jurnal',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.showModalView(e.row.data)
                    //     }
                    // }
                    {
                        text: 'Laporan',
                        hint: 'Laporan',
                        cssClass: 'text-success',
                        onClick: async(e) => {
                            if (e.row.data.statusId === 4) {
                                await alert("Data Batal dan Tidak Bisa Di Print", "Gagal");
                            } else {
                                this.printLaporan(e.row.data);
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField:'transactionNo',
                caption:'No Transaksi'
            },
            {
                dataField:'programId',
                caption:'Program',
                lookup:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program' 
                }
            },
            {
                dataField:'kasUmumId',
                caption:'Kas Umum',
                lookup:{
                    dataSource: kasUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaKas' 
                }
            },
            {
                dataField: 'pengajuan',
                caption: 'Pengajuan',
                alignment:'center',
                columns:[
                    {
                        dataField:'requestDate',
                        caption:'Tanggal Pengajuan',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField:'requestAmount',
                        caption:'Nominal Pengajuan',
                        alignment: 'right',
                        format : '#,##0.00'
                    },
                    {
                        dataField:'description',
                        caption:'Keterangan Pengajuan'
                    }
                ]
            },
            {
                dataField: 'penerimaan',
                caption: 'Konfirmasi Penerimaan',
                alignment:'center',
                columns:[
                    {
                        dataField:'receivedDate',
                        caption:'Tanggal Diterima',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField:'receivedAmount',
                        caption:'Nominal Diterima',
                        alignment: 'right',
                        format : '#,##0.00'
                    },
                ]
            },
            {
                dataField:'rekBankId',
                caption:'Rekening Bank',
                lookup:{
                    dataSource: rekBank(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'rekName' 
                }
            },
            {
                dataField:'statusId',
                caption:'Status',
                lookup:{
                    dataSource: statusPengisianKasUmumSdm2(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
        ]

        // this.columns = [
        //     {
        //         type: 'buttons',
        //         buttons: [
        //             // {
        //             //     text: 'Lihat Jurnal',
        //             //     hint: 'Lihat Jurnal',
        //             //     cssClass: 'text-success',
        //             //     onClick: (e) => {
        //             //         this.showModalView(e.row.data)
        //             //     }
        //             // }
        //             {
        //                 text: 'Laporan',
        //                 hint: 'Laporan',
        //                 cssClass: 'text-success',
        //                 onClick: async(e) => {
        //                     if (e.row.data.statusId === 4) {
        //                         await alert("Data Batal dan Tidak Bisa Di Print", "Gagal");
        //                     } else {
        //                         this.printLaporan(e.row.data);
        //                     }
        //                 }
        //             },
        //         ]
        //     },
        //     {
        //         dataField: 'id',
        //         caption: 'No',
        //         cellRender: (e) => {
        //             var no = e.rowIndex + 1
        //             return no
        //         }
        //     },
        //     {
        //         dataField:'transaction_no',
        //         caption:'No Transaksi'
        //     },
        //     {
        //         dataField:'program',
        //         caption:'Program',
        //         // lookup:{
        //         //     dataSource: programs(this.props.store),
        //         //     valueExpr: 'id', // contains the same values as the "statusId" field provides
        //         //     displayExpr: 'program' 
        //         // }
        //     },
        //     {
        //         dataField:'kas_umum_id',
        //         caption:'Kas Umum',
        //         lookup:{
        //             dataSource: kasUmum(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'namaKas' 
        //         }
        //     },
        //     {
        //         dataField: 'pengajuan',
        //         caption: 'Pengajuan',
        //         alignment:'center',
        //         columns:[
        //             {
        //                 dataField:'request_date',
        //                 caption:'Tanggal Pengajuan',
        //                 dataType: 'date',
        //                 format: 'dd MMM yyyy'
        //             },
        //             {
        //                 dataField:'transaction_amount',
        //                 caption:'Nominal Pengajuan',
        //             },
        //             {
        //                 dataField:'description',
        //                 caption:'Keterangan Pengajuan'
        //             }
        //         ]
        //     },
        //     {
        //         dataField: 'penerimaan',
        //         caption: 'Konfirmasi Penerimaan',
        //         alignment:'center',
        //         columns:[
        //             {
        //                 dataField:'request_date',
        //                 caption:'Tanggal Diterima',
        //                 dataType: 'date',
        //                 format: 'dd MMM yyyy'
        //             },
        //             {
        //                 dataField:'request_amount',
        //                 caption:'Nominal Diterima',
        //             },
        //         ]
        //     },
        //     {
        //         dataField:'rekBankId',
        //         caption:'Rekening Bank',
        //         lookup:{
        //             dataSource: rekBank(this.props.store),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'rekName' 
        //         }
        //     },
        //     {
        //         dataField:'status_ku',
        //         caption:'Status',
        //         lookup:{
        //             dataSource: statusPengisianKasUmumSdm2(),
        //             valueExpr: 'id', // contains the same values as the "statusId" field provides
        //             displayExpr: 'value' 
        //         }
        //     },
        //     // {
        //     //     dataField:'saldo',
        //     //     caption:'Saldo',
        //     // },
        // ]

        this.showModalViewRef = React.createRef()
    } 

    // loadData = async () => {
    //     let loadAPIKasUmum = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-umums?size=999', 'GET');
    //     let loadAPIKasBank = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-bank-transactions/getByMultipleStatusId/3%2C4%2C5?size=999', 'GET');

    //     for (let v of loadAPIKasUmum) {
    //         let data = loadAPIKasBank.filter(value => value.kasUmumId === v.id);
            
    //         for (let value of data) {
    //             let loadKasUmumId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umums/${value.kasUmumId}`, 'GET');
                
    //         }
    //     }

    //     return loadAPIKasBank;
    // }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalView = (data) => {
        if (data) {
            this.showModalViewRef.current.retrieveData(data)
        }
        this.showModalViewRef.current.show()
    }

    // printLaporan = async (data) => {
    //     console.log(data);
    //     var responseMaster = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getResumeReportPenanggungJawab/${data.id}`, 'GET')
    //     var responseDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getRealisasiReportPenanggungJawab/${data.id}`, 'GET')
    //     let detail = []
    //     var no = 1
    //     for (var dataDetail of responseDetail) {
    //         var result = {
    //             no: no++,
    //             keterangan: dataDetail.keterangan,
    //             jumAmount: dataDetail.jumAmount,
    //         }
    //         detail.push(result)
    //     }
    //     if (responseMaster !== null) {
    //         var data = {
    //             template: {
    //                 'shortid': 'r1lH9b-iiY',
    //                 'recipe': 'chrome-pdf'
    //             },
    //             data: {
    //                 "tanggalTerima": responseMaster.tanggalTerima != null ? formatDefaultFullDate(responseMaster.tanggalTerima) : '',
    //                 "jumlahPembayaran": responseMaster.jumlahPembayaran,
    //                 "nominalTerima": responseMaster.nominalTerima,
    //                 "pengambalianTanggal": responseMaster.pengembalianTanggal != null ? formatDefaultFullDate(responseMaster.pengembalianTanggal) : '',
    //                 "pengembalianAmount": responseMaster.pengembalianAmount,
    //                 "saldoAkhir": responseMaster.saldoAkhir,
    //                 "dataPengisian": detail,
    //             },
    //         }
    //         reportFile(data, true, `LAPORAN-PERTANGGUNG-JAWABAN.pdf`)
    //     }else{
    //         confirmAlert({
    //             title: 'Tidak ditemukan!',
    //             message: 'Data tidak ditemukan',
    //             buttons: [
    //                 {
    //                     label: 'OK'
    //                 }
    //             ]
    //         })
    //     }
    // }

    printLaporan = async (data) => { 
        var responseMaster = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umums/getByKasUmumLaporan/${data.kasUmumId}`, 'GET')
        var responseDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umums/getByKasUmumLaporanDetil/${data.kasUmumId}`, 'GET')
      
        let detail = []
        var no = 1
        for (var dataDetail of responseDetail) {
            var result = {
                no: no++,
                transaksi: dataDetail.transaksi,
                tanggal: dataDetail.tanggal ? formatDefaultFullDate(dataDetail.tanggal) : '-',
                keterangan: dataDetail.keterangan,
                jumAmount: dataDetail.jum_amount,
                saldo: dataDetail.saldo,
            }
            detail.push(result)
        }

        if (responseMaster !== null) {
            var data = {
                template: {
                    'shortid': 'r1lH9b-iiY',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "kasUmum": responseMaster[0].nama_kas,
                    "nominalTerima": responseMaster[0].jumlah_terima,
                    "jumlahPembayaran": responseMaster[0].jumlah_pembayaran,
                    "jumlahPengembalian": responseMaster[0].jumlah_pengembalian,
                    "saldoAkhir": responseMaster[0].saldo,
                    "dataPengisian": detail,
                },
            }
            reportFile(data, true, `LAPORAN-PERTANGGUNG-JAWABAN.pdf`)
        }else{
            confirmAlert({
                title: 'Tidak ditemukan!',
                message: 'Data tidak ditemukan',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
        }
    }

    loadData = async () => {
        let responseAPI = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-bank-transactions/getByMultipleStatusId/1%2C2%2C3%2C4%2C5?size=999', 'GET');
        let responseByFunc = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'kas-bank-transactions/getKuRiwayatPengisianByFunction/1', 'GET');
        console.log(responseByFunc);

        console.log(responseAPI);
        let data = [];

        for (let value of responseAPI) {
            console.log(responseByFunc.find(val => val.transaction_no === value.transactionNo));
            let dataValue = {
                "id": value,
                "transactionNo": value.transactionNo,
                "programId": value.programId,
                "kasUmumId": value.kasUmumId,
                // "status": value.status,
                "requestDate": value.requestDate,
                "requestAmount": value.requestAmount,
                "description": value.description,
                "receivedDate": value.receivedDate,
                "receivedAmount": value.receivedAmount,
                "rekBankId": value.rekBankId,
                "statusId": value.statusId ? responseByFunc.find(val => val.transaction_no === value.transactionNo).status_ku : '-',
            }
            data.push(dataValue);
        }

        return data;
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Riwayat Pengisian Kas Umum</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    // loadAPI='kas-bank-transactions/getByMultipleStatusId/3%2C4%2C5?size=999'
                    loadAPI='kas-bank-transactions/getKuRiwayatPengisianByFunction/1'
                    insertAPI='kas-bank-transactions'
                    updateAPI='kas-bank-transactions'
                    deleteAPI='kas-bank-transactions'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource = {true}
                    ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengisian Kas Umum"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengisian Kas Umum'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormJurnalModalRiwayatPengisianKasUmum
                    ref={this.showModalViewRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default RiwayatPengisianKasUmum