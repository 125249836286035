import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, kpds, productDefault, banks } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalActionPerpanjanganSettlementTransaksiDeposito from './actionPerpanjangan';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import { formatDate } from 'plugin/helper';
import notify from 'devextreme/ui/notify'
import { showLoading } from 'redux/actions/loading';


class ModalSettlementTransaksiDepositoPerpanjangan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            actionType: null
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPerpanjanganRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        if (this.validate()) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.loadDummyData = []

        this.itemPerpanjanganMaster = [
            {
                dataField: 'referensiNo',
                label: {
                    alignment: 'left',
                    text: 'No Konfirmasi Bank'
                },
                editorOptions: {
                    onValueChanged: (e) => {
                        this.setState({
                            dataMaster: {
                                referensiNo: e.value
                            }
                        })
                    },
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please fill Confirmation Number'
                    }
                ],
            },
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Product'
                    }
                ]
            },
            {
                dataField: 'pengelola',
                label: {
                    alignment: 'left',
                    text: 'Pengelola'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    items: ['Internal', 'KPD'],
                    layout: 'horizontal'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Pengelola'
                    }
                ]
            },
            {
                dataField: 'kpdId',
                label: {
                    alignment: 'left',
                    text: 'KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak',
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose KPD'
                    }
                ]
            },
            {
                dataField: 'bankId',
                label: {
                    alignment: 'left',
                    text: 'Bank'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'bankName',
                    searchEnabled: true,
                    deferRendering: false,
                    onOpened: async () => {
                        var selectBankId = this.formRef.current.instance.getEditor('bankId')
                        selectBankId.option('dataSource', await this.availableBankDepositoPerpanjangan(this.props.store, this.state.dataMaster.kpdId, this.state.dataMaster.productId))
                    },
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Please choose Bank'
                    }
                ]
            },
        ]
        this.perpanjanganColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            this.modalInsertRef.current.show(data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDetailPerpanjanganData(e.row.key)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelolaan',
            },
            {
                dataField: 'kpdId',
                caption: 'Tipe',
                lookup: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak'
                }
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'bilyetNo',
                caption: 'No Bilyet',
            },
            {
                dataField: 'orderCode',
                caption: 'No Order',
            },
            {
                dataField: 'tanggalPerpanjangan',
                caption: 'Tanggal Perpanjangan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'nominalTransfer',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalBunga',
                caption: 'Bunga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rolloverTypeId',
                caption: 'Jenis Perpanjangan',
                lookup: {
                    dataSource: [
                        {
                            id: 0,
                            value: 'Pokok + Bunga'
                        },
                        {
                            id: 1,
                            value: 'Pokok Saja'
                        },
                        {
                            id: 2,
                            value: 'Sebagian'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'YearDays',
                caption: 'Year Days',
                alignment: 'right',
                dataType: 'number',
            },
            {
                dataField: 'InterestDay',
                caption: 'Interest Day',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'Interest',
                caption: 'Interest',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'Pajak',
                caption: 'Pajak',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'Perpanjangan',
                caption: 'Perpanjangan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },

        ]
        this.itemPerpanjanganFooter = [
            {
                dataField: 'biayaBankRtgs',
                label: {
                    alignment: 'left',
                    text: 'Biaya Bank/RTGS'
                },
                editorType: 'dxTextArea'
            },
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }
    availableBankDepositoPerpanjangan = async (store, kpdId, productId) => {
        let loadAPI = `efek-balance-avgs/all-need-settle-available-bank-deposito-perpanjangan?kpdId=${kpdId}&productId=${productId}`
        var getBankId = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')
        var data = []
        for (var value of getBankId) {
            let loadAPIBank = `banks/${value}`
            var getBankData = await httpRequest(process.env.REACT_APP_BACKEND, store, loadAPIBank, 'GET')

            var bankData = {
                id: value,
                bankName: getBankData.bankName
            }
            data.push(bankData)
        }

        let dataSource = new ArrayStore({
            data: data,
            key: 'id'
        });

        return dataSource
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))

        var dataDataGrid = this.dataGridPerpanjanganRef.current.getDataSource()
        var dataMaster = this.state.dataMaster
        var customSubmitData = {
            ...dataMaster,
            perpanjanganDepositoList: dataDataGrid,
            trsDate: formatDate(new Date()),
        }
        if (customSubmitData) {
            try {
                var insertAPI = 'order-depositos/settle-all-perpanjangan-deposito'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: customSubmitData
                })
                 
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }
    onToolbarPreparingPerpanjangan = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Perpanjangan',
                    icon: 'plus',
                    onClick: (e) => {
                        if(this.validate()){
                            this.showModalInsert()
                        }
                    }
                }
            }
        )
    }
    submitDetailPerpanjanganData = (data) => {
        if (data.id) {
            this.dataGridPerpanjanganRef.current.push([{
                type: "update",
                data: {
                    bankId: data.bankId,
                    kpdId: data.kpdId,
                    referensiNo: data.referensiNo,
                    productId: data.productId,
                    pengelola: data.pengelola,
                    orderCode: data.orderCode,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    ratePerpanjangan: data.ratePerpanjangan,
                    biayaBank: data.biayaBank,
                    bilyetNo: data.bilyetNo,
                    bilyetType: data.bilyetType,
                    newBilyetNo: data.newBilyetNo,
                    maturityDate: data.maturityDate,
                    nominalPerpanjangan: data.nominalPerpanjangan,
                    nominalAccruedBunga: data.nominalAccruedBunga,
                    bunga: data.bunga,
                    pph: data.pph,
                    nominalBungaBersih: data.nominalBungaBersih,
                    efekBalanceAvgId: data.efekBalanceAvgId,
                    kurs: data.kurs,
                    catatan: data.catatan,
                    nominalBunga: data.nominalBunga,
                    nominalDeposito: data.nominalDeposito,
                    nominalTransfer: data.nominalTransfer,
                    receiveRekbankId: data.receiveRekbankId,
                    tanggalPerpanjangan: formatDate(data.tanggalPerpanjangan),
                    rolloverTypeId: data.rolloverTypeId
                },
                key: data.id
            }])
        } else {
            this.dataGridPerpanjanganRef.current.push([{
                type: "insert",
                data: {
                    bankId: data.bankId,
                    kpdId: data.kpdId,
                    referensiNo: data.referensiNo,
                    productId: data.productId,
                    pengelola: data.pengelola,
                    orderCode: data.orderCode,
                    tenor: data.tenor,
                    tenorTypeId: data.tenorTypeId,
                    ratePerpanjangan: data.ratePerpanjangan,
                    biayaBank: data.biayaBank,
                    bilyetNo: data.bilyetNo,
                    bilyetType: data.bilyetType,
                    newBilyetNo: data.newBilyetNo,
                    maturityDate: data.maturityDate,
                    nominalPerpanjangan: data.nominalPerpanjangan,
                    nominalAccruedBunga: data.nominalAccruedBunga,
                    bunga: data.bunga,
                    pph: data.pph,
                    nominalBungaBersih: data.nominalBungaBersih,
                    efekBalanceAvgId: data.efekBalanceAvgId,
                    kurs: data.kurs,
                    catatan: data.catatan,
                    nominalBunga: data.nominalBunga,
                    nominalDeposito: data.nominalDeposito,
                    nominalTransfer: data.nominalTransfer,
                    receiveRekbankId: data.receiveRekbankId,
                    tanggalPerpanjangan: formatDate(data.tanggalPerpanjangan),
                    rolloverTypeId: data.rolloverTypeId
                }
            }])
             
        }
    }
    showModalInsert = () => {
        this.modalInsertRef.current.show({
            referensiNo: this.state.dataMaster.referensiNo,
            pengelola: this.state.dataMaster.pengelola,
            bankId: this.state.dataMaster.bankId,
            productId: this.state.dataMaster.productId,
            kpdId: this.state.dataMaster.kpdId,
        })
    }
    forceRefresh = () => {
        this.dataGridPerpanjanganRef.current.forceRefresh(true)
    }
    deleteDetailPerpanjanganData = (key) => {
        this.dataGridPerpanjanganRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Deposito'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                    <div className="row">
                        <div id="tabindex-2" className={'mt-2 col-md-12 text-center'} >
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        ref={this.formRef}
                                        formData={this.state.dataMaster}
                                        items={this.itemPerpanjanganMaster}
                                        labelLocation={"left"}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <DevExpressDataGrid
                                        ref={this.dataGridPerpanjanganRef}
                                        loadAPI='cash-deviden'
                                        insertAPI='cash-deviden'
                                        updateAPI='cash-deviden'
                                        deleteAPI='cash-deviden'

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.loadDummyData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Perpanjangan"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Perpanjangan'}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={500} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.perpanjanganColumns} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        onToolbarPreparing={this.onToolbarPreparingPerpanjangan}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <div className="col-md-12 text-center mt-3">
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        formData={this.state.dataMaster}
                                        items={this.itemPerpanjanganFooter}
                                        labelLocation={"left"}
                                    />
                                </div>
                                <ModalActionPerpanjanganSettlementTransaksiDeposito
                                    ref={this.modalInsertRef}
                                    store={this.props.store}
                                    forceRefresh={this.forceRefresh}
                                    submitDetailPerpanjanganData={this.submitDetailPerpanjanganData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalSettlementTransaksiDepositoPerpanjangan