import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { tipePinjaman, karyawan, jabatan, programs } from 'dataSource/lookup';
import FormPinjamanKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/pinjaman/form'
import FormKirimPinjaman from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/pinjaman/formKirim';

class PinjamanKaryawan extends Component {
    constructor(props){
        super(props)
        this.modalEditRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.modalFileRef = React.createRef()
        this.modalKirimRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalEdit(e)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            this.showModalKirim(e)
                        }
                    },
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            console.log('file')
                        }
                    }
                ]
            },
            {
                dataField: 'program',
                caption: 'Program',
                lookup:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'noPengajuan',
                caption: 'No Pengajuan'
            },
            
            {
                dataField: 'nik',
                caption: 'NIK',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'jabatanId',
                caption: 'Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                }
            },
            {
                dataField: 'loanTypeId',
                caption: 'Tipe Pinjaman',
                lookup: {
                    dataSource: tipePinjaman(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'loanName',
                }
            },
            {
                dataField: 'loanDate',
                caption: 'Tanggal Pinjaman',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'loanAmount',
                caption: 'Nominal',
                dataType: 'number',
                alignment: 'right',
                format: '#,#00.00'
            },
            {
                dataField: '.',
                caption: 'Potongan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'cicilanAmount',
                        caption: 'Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,#00.00'
                    },
                    {
                        dataField: 'startDate',
                        caption: 'Tanggal Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'maturityDate',
                        caption: 'Tanggal Akhir',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'cicilanTenor',
                        caption: 'Tenor (Bulan)'
                    },
                ]
            },
            {
                dataField: 'sisaPeriode',
                caption: 'Sisa Periode'
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModalEdit = (e) => {
        if (e.row) {
            this.modalEditRef.current.retrieveData(e.row.data)
        }
        this.modalEditRef.current.show()
    }
    showModalKirim = (e) => {
        if (e.row) {
            this.modalKirimRef.current.retrieveData(e.row.data)
        }
        this.modalKirimRef.current.show()
    }
    showModalInsert = () => {
        this.modalInsertRef.current.show()
    }
    showModalFile = () => {
        // this.modalFileRef.current.show()
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }


    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Pinjaman Karyawan</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-karyawan-loans'
                    insertAPI='karyawan-loans'
                    updateAPI='karyawan-loans'
                    deleteAPI='karyawan-loans' 

                    keyField = {'karyawanLoanId'}
                    backendserver={process.env.REACT_APP_BACKEND_HRD} 

                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Pinjaman Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPinjamanKaryawan
                    ref={this.modalEditRef}
                    store={this.props.store}
                    type={'edit'}
                    forceRefresh={this.forceRefresh}
                />
                <FormPinjamanKaryawan
                    ref={this.modalInsertRef}
                    store={this.props.store}
                    type={'add'}
                    forceRefresh={this.forceRefresh}
                />
                <FormKirimPinjaman
                    ref={this.modalKirimRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default PinjamanKaryawan