import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import { alert, confirm } from 'devextreme/ui/dialog';
import ShowModalAkunPensiunanPasif from 'components/pages/modal/kepesertaan/pesertaDapen/pasif/showAkunPensiunanPasif';
import { gender, educationBackground, unit, grade, participants, tax, maritalStatus, statusPajak } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';


class AkunPensiunanPasif extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            dataMaster: {},
            getUrl: 'view-pesertas?statusPeserta.in=4'
        }

        this.dataGridRef = React.createRef()
        this.showModalAkunPensiunanPasifRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showAkunPensiunanPasif(e.row.data, 'view')
                        }
                    },
                    {
                        text: 'Rollback',
                        hint: 'Rollback',
                        cssClass: 'text-primary',
                        onClick: async (e) => {
                            // await alert('Under Development', 'Informasi');
                            const result = await confirm('Apakah data ini ingin di rollback?', 'Konfirmasi');
                            if (result) {
                                this.rollBack(e.row.data);
                            }
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'nik',
                caption: 'NIK',
                alignment: "right",
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir'
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'address1',
                caption: 'Alamat KTP'
            },
            {
                dataField: 'mobileNo',
                caption: 'No HP'
            },
            {
                dataField: 'email',
                caption: 'Email'
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Mulai Kerja',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'tanggalPeserta',
                caption: 'Tanggal Menjadi Peserta',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'mariedId',
                caption: 'Status Keluarga',
                lookup: {
                    dataSource: maritalStatus(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'statusPajak',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: statusPajak(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'unitKerjaName',
                caption: 'Unit Kerja',
            },
            {
                dataField: 'grade',
                caption: 'Kelas',
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'npwp',
                caption: 'NPWP'
            },
            {
                dataField: 'noKtp',
                caption: 'No KTP'
            },
            {
                dataField: 'pendingStatus',
                caption: 'Status Pending',
            }
        ]

        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        var loadAPI
                        if (data.value) {
                            loadAPI = `view-pesertas?participantId.in=${data.value}&statusPeserta.in=4`
                        } else {
                            loadAPI = 'view-pesertas?statusPeserta.in=4'
                        }
                        this.setState({
                            getUrl: loadAPI
                        })
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showAkunPensiunanPasif = (data, type) => {
        this.showModalAkunPensiunanPasifRef.current.show()
        this.showModalAkunPensiunanPasifRef.current.retrieveData(data, type)
    }

    rollBack = async (data) => {
        try {
            let getPesertaByNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/getByNik/${data.nik}`, 'GET');
        
            let rollBackAPI = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-pensiuns/regRollbackPensiun/${getPesertaByNik.id}`, 'GET');
            
            if (rollBackAPI) {
                this.dataGridRef.current.forceRefresh(true);
                var text = 'Data Peserta Berhasil Rollback!'
                let type = 'Success'
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    
                return false;
            }
        } catch (error) {
            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            console.log(error);
        }
    }

    submitData = async (data) => {
        var text = 'Data Peserta berhasil diubah!'
        let type = 'success'
        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

        return false
    }
    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift({
    //         location: 'after',
    //         widget: 'dxButton',
    //         options: {
    //             icon: 'plus',
    //             onClick: (e) => {
    //                 this.showAkunPensiunanPasif('new')
    //             },
    //         }
    //     });
    // }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Akun Pensiunan Pasif</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    // keyField = {'pesertaId'}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Akun Pensiunan Pasif"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    remoteOperations={true}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Akun Pensiunan Pasif'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ShowModalAkunPensiunanPasif
                    store={this.props.store}
                    submitData={this.submitData}
                    ref={this.showModalAkunPensiunanPasifRef}
                />
            </div>
        )
    }
}

export default AkunPensiunanPasif