import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { reksadana, emitens, currency, instrumentReksadana, rekeningMi, managerInvestasis } from 'dataSource/lookup';

class DetailPelepasanReksadana extends Component {
    constructor(props) {
        super(props)
        this.dataSource = new CustomStore({
            load: async (loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `order-requests-rd-dtls/${requestId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                let data = [];

                for (let value of response.orderRdInfos) {
                    console.log(value);
                    let dataValue = {
                        ...value,
                        orderRdDTO: {
                            ...value.orderRdDTO,
                            unitPersen: value.orderRdDTO.amountOrder / (value.orderRdDTO.unitOrder * value.instrumentDTO.price) * 100
                        }
                    }
                    data.push(dataValue);
                }

                return data                
                // return response.orderRdInfos
            },
            key: 'orderDTO.orderRequestId',
            insert: (values) => {
            },
            update: (key, values) => {

            },
            remove: (key) => {

            }
        })

        this.columns = [
            {
                dataField: 'orderDTO.instrumentId',
                caption: 'Instrumen Code',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode',
                }
            },
            {
                dataField: 'orderDTO.instrumentId',
                caption: 'Instrumen Name',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'orderRdDTO.miId',
                caption: 'Manager Investasi Code',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'instrumentDTO.currencyId',
                caption: 'Mata Uang',
                format: '#,##0',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                }
            },
            {
                dataField: 'orderRdDTO.unitOrder',
                caption: 'Unit Order',
                format: '#,##0.0000',
                alignment: 'right',
            },
            {
                dataField: 'instrumentDTO.price',
                caption: 'NAB Terakhir',
                format: '#,##0.0000',
                alignment: 'right',
            },
            {
                dataField: 'portofolioPelepasan',
                caption: 'Portofolio Pelepasan',
                cellRender: (e) => {
                    if (e.valueAll == true) {
                        return 'Semua'
                    } else {
                        return 'Sebagian'
                    }
                }
            },
            {
                dataField: 'orderRdDTO.unitPersen',
                caption: 'Persentase Pelepasan',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'orderRdDTO.amountOrder',
                caption: 'Nominal Pelepasan',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'orderRdDTO.sourceRekMiId',
                caption: 'Rekening Bayar',
                lookup: {
                    dataSource: rekeningMi(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'rekName'
                }
            },
        ]
    }

    render() {
        return (
            <DataGrid
                id={"successDataGrid"}
                showBorders={false}
                dataSource={this.dataSource}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                // height={100}
                showScrollbar={"always"}
            // columns={this.columns}
            >
                {
                    this.columns.map((column) => {
                        return <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            lookup={column.lookup}
                            cellRender={column.cellRender}
                            alignment={column.alignment || 'left'}
                            cssClass={column.cssClass}
                            format={column.format}
                            dataType={column.dataType}
                            width={column.width}
                            type={column.type}
                            buttons={column.buttons}
                            editorType={column.editorType}
                            editorOptions={column.editorOptions}
                            visible={column.visible}
                            sortOrder={column.sortOrder}
                            allowEditing={column.allowEditing}
                            calculateCellValue={column.calculateCellValue}
                        />
                    })
                }
                {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPelepasanReksadana