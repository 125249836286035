import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from 'devextreme/ui/notify'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { banks, managerInvestasis, custodies, productDefault, instrumentProperti, efekBalanceBuyDate } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatUploadFileData, imageSource } from 'plugin/helper'
import ArrayStore from 'devextreme/data/array_store';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'

class ModalKpaAsset extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
            actionType: 'add'
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'instrumenId',
                        label: {
                            alignment: 'left',
                            text: 'Properti',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'instrumentName',
                            onValueChanged: async(e) => {
                                var dataBalance = await this.balanceBuyDate(this.props.store, this.state.formData.productId, e.value)
                                this.formRef.current.instance.getEditor('efekBalanceBuyDateId').option('dataSource', dataBalance)
                            }
                        }
                    },
                    {
                        dataField: 'efekBalanceBuyDateId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe/Unit/Kav'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'tipe',
                        },
                    },
                    {
                        dataField: 'efektifDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Efektif'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'expiredDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Akhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'kontrakNominal',
                        label: {
                            alignment: 'left',
                            text: 'Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                ]
            },
        ]
    }
    show = async(data) => {
        this.setState({
            popupVisible: true,
            formData: data
        });
        var dataInstrument = await this.getInstrumentProperti(this.props.store)
        this.formRef.current.instance.getEditor('instrumenId').option('dataSource', dataInstrument)
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Kontrak`]: formattedFileData.base64data,
                    [`${fieldName}KontrakContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    balanceBuyDate = async (store, productId, instrumentId) => {
        let loadAPI = `efek-balance-buy-dates?instrumentId.in=${instrumentId}&productId.in=${productId}`
        var getBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getBalance,
            key: 'id'
        });

        return dataSource
    }
    getInstrumentProperti = async (store) => {
        let loadAPI = `instruments?categorySubId.in=34&categorySubId.in=35&categorySubId.in=36&categorySubId.in=37&categorySubId.in=38&categorySubId.in=39`
        var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getInstrument,
            key: 'id'
        });

        return dataSource
    }
    submitData = async () => {
        var data = this.state.formData
        this.props.submitDataKpaAset({
            id: data.id,
            efekBalanceBuyDateId: data.efekBalanceBuyDateId,
            efektifDate: data.efektifDate,
            expiredDate: data.expiredDate,
            kontrakNominal: data.kontrakNominal,
            instrumenId: data.instrumenId,
        })

        this.hide()
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form ' + (this.state.actionType == 'edit' ? 'Ubah' : 'Tambah') + ' KPA Asset'}
                    width={'40vw'}
                    height={'40vh'}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={"formMaster"}
                                    store={this.props.store}
                                    formData={this.state.formData}
                                    items={this.items}
                                    ref={this.formRef}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalKpaAsset