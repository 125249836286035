import React, { Component } from 'react'
import { currency, banks, orders, product, broker, pejabat} from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify'
import FormInstruksiBrokerEtfAktif from 'components/pages/modal/investasi/instruksi/brokerEtf/aktif/index'

class InstruksiBrokerEtfAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.dataGridInstruksiBrokerRef = React.createRef()
        this.formInstruksiBrokerAktifRef = React.createRef()
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalBrokerAktif(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin mengirim instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.sendInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin membatalkan instruksi ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.cancelInstruksi(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                        }
                    },
                ]
            },
            {
                dataField: 'instruksiId',
                caption: 'ID',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiNo',
                        caption: 'Nomor'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'totalOrder',
                        caption: 'Jumlah',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: 'totalUnitOrder',
                        caption: 'Total Unit',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'totalAmountOrder',
                        caption: 'Total Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    }
                ]
            },
            {
                dataField: 'pembelian',
                caption: 'Pembelian',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'totalBuy',
                        caption: 'Jumlah',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: 'totalUnitOrderBuy',
                        caption: 'Total Unit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'totalAmountOrderBuy',
                        caption: 'Total Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'penjualan',
                caption: 'Penjualan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'totalSell',
                        caption: 'Jumlah',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0'
                    },
                    {
                        dataField: 'totalUnitOrderSell',
                        caption: 'Total Unit',
                        dataType: 'number',
                        format: '#,##0',
                        alignment: 'right'
                    },
                    {
                        dataField: 'totalAmountOrderSell',
                        caption: 'Total Nominal',
                        dataType: 'number',
                        format: '#,##0',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            },
        ]
    }
    downloadFile = async (data) => {
        var instruksiId = data.instruksiId
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksis/doc-instruksi/${instruksiId}`, 'GET')
        return response
    }
    sendInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        var responseDetailInstruksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-etf/${instruksiId}`, 'GET')
        var fileInstruksi = responseDetailInstruksi.orderInstruksiDTO.fileInstruksi
        if (data.pejabat1 || data.pejabat2 == null) {
            if (fileInstruksi != null) {
                if (instruksiId) {
                    try {
                        var insertAPI = 'order-etfs/send-instruksi-etf'
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                            values: [
                                instruksiId
                            ]
                        })
                        notify({ message: 'Pengiriman Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.forceRefresh()
                        return response
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } else {
                notify({ message: 'Mohon lengkapi file instruksi terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        } else {
            notify({ message: 'Mohon lengkapi data terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }
    cancelInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        if (instruksiId) {
            try {
                var insertAPI = 'order-etfs/batal-instruksi-etf'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: [
                        instruksiId
                    ]
                })
                notify({ message: 'Pembatalan Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    showModalBrokerAktif = async (data) => {
        var instruksiId = data.instruksiId
        var loadAPI = `order-instruksi-etf/${instruksiId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderInstruksiDTO
        var dataDataGrid = response.orderEtfInfos
        var dataEtf = []
        for (var dataDetail of dataDataGrid) {
            for (var etfData of dataDetail.orderEtfDTOList) {
                var dataOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `orders/${etfData.orderId}`, 'GET')
                var detail = {
                    id: etfData.id,
                    orderId: etfData.orderId,
                    orderCode: dataOrder.orderCode,
                    orderTypeId: etfData.orderTypeId,
                    instrumentId: etfData.instrumentId,
                    amountOrder: etfData.amountOrder,
                    unitOrder: etfData.unitOrder,
                    priceType: etfData.priceType,
                    priceMin: etfData.priceMin,
                    priceMax: etfData.priceMax,
                }
                dataEtf.push(detail)
            }
            var customGetData = {
                rekName: dataMaster.sourceRekBrokerId == null ? null :  this.rekBrokerData.find(val => val.id == dataMaster.sourceRekBrokerId).rekName,
                rekNo: dataMaster.sourceRekBrokerId == null ? null : this.rekBrokerData.find(val => val.id == dataMaster.sourceRekBrokerId).rekNo,
                ...dataMaster,
                ...data,
                dataEtf: dataEtf,
            }
        }
        this.formInstruksiBrokerAktifRef.current.show(customGetData)
    }
    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var getRekBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-brokers', 'GET')

        this.rekBrokerData = getRekBroker
    }
    forceRefresh = () => {
        this.dataGridInstruksiBrokerRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Broker ETF</h2>
                <DevExpressDataGrid
                    keyField='instruksiId'
                    ref={this.dataGridInstruksiBrokerRef}
                    loadAPI='order-instruksi-etf/list?status=OPEN'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instruksi Broker"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Broker'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormInstruksiBrokerEtfAktif
                    ref={this.formInstruksiBrokerAktifRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default InstruksiBrokerEtfAktif