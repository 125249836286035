import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import { allAkunHR, instrumentsCategoryProperti } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'

class FormModalTipeTransaksiSdm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            newData: true
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.formItems = [
            {
                dataField: 'transactionTypeName',
                caption: 'Nama Transaksi'
            },
            {
                dataField: 'akunId',
                caption: 'Akun',
                lookup: {
                    dataSource: allAkunHR(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName',
                    searchEnabled: true,
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: allAkunHR(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'apAkunId',
                caption: 'AP Akun',
                lookup: {
                    dataSource: allAkunHR(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName',
                    searchEnabled: true,
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: allAkunHR(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'arAkunId',
                caption: 'AR Akun',
                lookup: {
                    dataSource: allAkunHR(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName',
                    searchEnabled: true,
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: allAkunHR(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName',
                    searchEnabled: true,
                }
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.submitData();
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.updateData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    retrieveData = async (data) => {
        this.setState({
            dataMaster: data
        })
    }

    submitData = async () => {
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'staticdata-wf/transaction-types', 'POST', {
                values: data
            });

            if (response) {
                this.hide();
                let type = 'success'
                let text = 'Sukses tambah data'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            console.log(error);
        }
    }

    updateData = async () => {
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'staticdata-wf/transaction-types', 'PUT', {
                key: data.id,
                values: data
            }, data);

            if (response) {
                this.hide();
                let type = 'success'
                let text = 'Sukses ubah data'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={this.props.actionType === 'add' ? 'Form Tipe Transaksi SDM' : 'Form Edit Tipe Transaksi SDM'}
                width={"30vw"}
                height={"30vh"}
                toolbarItems={this.props.actionType === 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                <Form
                    ref={this.formRef}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.formItems}
                    labelLocation='left'
                />
            </Popup>
        )
    }
}

export default FormModalTipeTransaksiSdm