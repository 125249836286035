import React, {Component} from 'react'
import { Popup,ScrollView, Form, FileUploader } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import { formatDate, formatUploadFileData } from 'plugin/helper'
import notify from "devextreme/ui/notify";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormAddInstrumentPemindahanEfek from './addInstrument';
import FormAddInstrumentPemindahanRek from './addInstrument';
import { currency, productDefault, rekeningBank } from 'dataSource/lookup';

class ViewInstrumentPemindahanRek extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
            popupVisible: false,
        }

        this.DataMaster = [
            {
                itemType: 'group',
                colCount:1,
                items:[
                    {
                        dataField:'noPengajuan',
                        label:{
                            text:'No. Pengajuan'
                        },
                    },
                    {
                        dataField:'noSuratAwal',
                        label:{
                            text:'No. Surat Awal'
                        },
                    },
                    {
                        dataField:'noSuratTujuan',
                        label:{
                            text:'No. SuratTujuan'
                        },
                    },
                    {
                        dataField:'productId',
                        label:{
                            text:'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'productCode',
                        }
                    },
                    {
                        dataField:'tglPemindahan',
                        label:{
                            text:'Tanggal Perpindahan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField:'tanggalTransfer',
                        label:{
                            text:'Tanggal Transfer'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                ]
            }
        ]

        this.columnInstrument = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            this.showModalEditInstrument(e)
                        }
                    },
                ]
            },
            {
                dataField: 'no',
                caption: 'No.',
                cellRender: (e) => {
                    return e.rowIndex + 1+'.'
                },
            },
            {
                caption:"Rekening Sumber",
                alignment:'center',
                columns:[
                    {
                        dataField:'sourceRekBankId',
                        caption:'No Rekening',
                        lookup: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "rekNo", // provides display values
                        },
                    },
                    {
                        dataField:'sourceRekBankId',
                        caption:'Nama Rekening',
                        lookup: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "rekName", // provides display values
                        },
                    },
                    {
                        dataField:'sourceAvgKurs',
                        caption:'Kurs Rata-Rata',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField:'sourceCurrencyId',
                        caption:'Currency',
                        lookup: {
                            dataSource: currency(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "currencyCode"
                        },
                    },
                ]
            },
            {
                caption:"Rekening Tujuan",
                alignment:'center',
                columns:[
                    {
                        dataField:'destinationRekBankId',
                        caption:'No Rekening',
                        lookup: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "rekNo", // provides display values
                        },
                    },
                    {
                        dataField:'destinationRekBankId',
                        caption:'Nama Rekening',
                        lookup: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "rekName", // provides display values
                        },
                    },
                    {
                        dataField:'destinationCurrencyId',
                        caption:'Currency',
                        lookup: {
                            dataSource: currency(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "currencyCode"
                        },
                    },
                ]
            },
            {
                dataField:'nominalPerpindahan',
                caption:"Nominal Perpindahan",
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:'kursPerpindahan',
                caption:'Kurs Perpindahan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:'selisihKurs',
                caption:'Selisih Kurs',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:'profitlossSelisihKurs',
                caption:'Profit Loss Selisih Kurs',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.pengajuanId = 0

        this.PopupRef = React.createRef()
        this.dataGridInstrumentRef = React.createRef()
        this.showModalAddInstrumentRef = React.createRef()
        this.showModalEditInstrumentRef = React.createRef()
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            DataMaster:{},
            popupVisible: false,
        })
    }

    forceRefresh = () => {
        this.dataGridInstrumentRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Instrument',
                    onClick: (e) => {
                        this.showModalAddInstrument(e)
                    },
                }
            }
        )
    }

    retrieveData = async(data) => {
        var DataMaster = {...this.state.DataMaster}
        this.pengajuanId = data.id
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `pemindahan-rekenings/${data.id}`,
            "GET"
        );
        DataMaster.noPengajuan = response.noPengajuan
        DataMaster.tglPemindahan = response.tanggalPemindahan
        DataMaster.tanggalTransfer = response.tanggalTransfer
        DataMaster.noSuratAwal = response.noSuratSource
        DataMaster.noSuratTujuan = response.noSuratDestination
        DataMaster.productId = response.productId
        this.setState({
            DataMaster:DataMaster
        })
    }

    showModalAddInstrument = (e) => {
        this.showModalAddInstrumentRef.current.getDataRekBank()
        this.showModalAddInstrumentRef.current.getPengajuanId(this.pengajuanId)
        this.showModalAddInstrumentRef.current.show()
    }

    showModalEditInstrument = (e) => {
        if (e.row) {
            this.showModalEditInstrumentRef.current.retrieveData(e.row.data)
        }
        this.showModalEditInstrumentRef.current.getPengajuanId(this.pengajuanId)
        this.showModalEditInstrumentRef.current.show()
    }

    render(){
        return(
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'75vw'}
                    height="75vh"
                    title={`Daftar Instrument Pemindahan Efek`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className="container-fluid">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.DataMaster}
                                items={this.DataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={true}
                            />
                            <DevExpressDataGrid
                                ref = {this.dataGridInstrumentRef}
                                loadAPI={`pemindahan-rekening-dtls/get-by-pemindahan-rekeningId/${this.pengajuanId}`}
                                updateAPI='kpds'
                                deleteAPI='pemindahan-rekening-dtls' 

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadData}

                                allowAdding={false}
                                allowDeleting={true}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Konfirmasi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}
                                height={'calc(100vh - 500px)'}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Instrument'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnInstrument} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                        <FormAddInstrumentPemindahanRek 
                            ref={this.showModalAddInstrumentRef}
                            store={this.props.store}
                            forceRefresh={this.forceRefresh}
                            productId={this.state.DataMaster.productId}
                            action={'add'}
                        />
                        <FormAddInstrumentPemindahanRek 
                            ref={this.showModalEditInstrumentRef}
                            store={this.props.store}
                            forceRefresh={this.forceRefresh}
                            productId={this.state.DataMaster.productId}
                            action={'edit'}
                        />
                    </ScrollView>
                </Popup>
        )
    }
}

export default ViewInstrumentPemindahanRek