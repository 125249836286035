import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader, ScrollView } from 'devextreme-react';
import { download, formatNumberAum, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import { alert } from 'devextreme/ui/dialog';
import { currency, entity, kasUmum, pembayaranUmumDetilByPuId, rekBankHrd } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import { showLoading } from 'redux/actions/loading'

class PPRealisasiSPModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {
                currencyId: 1001
            },
            dataDocument: {
                documentFile: '',
                documentFileContentType: '',
                documentName: '',
            }
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formKonfirmasiRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.statusPembayaranDetail = 0
        this.keterangan = "";

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'pengajuanNo',
                        label: {
                            alignment: 'left',
                            text: 'Pembayaran'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                if (e) {
                                    this.keterangan = e.keterangan;
                                    var a = e.cash ? "Cash" : e.transfer ? "Transfer" : e.cashOut ? "Cash Out" : "Lain"
                                    if (e.keterangan) {
                                        return e && e.keterangan + '@' + formatNumberAum(e.pengajuanNominal) + ' - ' + a
                                    } else {
                                        // var getTransactionType = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `transaction-type-subs/${e.transactionTypeSubId}`, 'GET')
                                        // return e && getTransactionType.tipeSubName + '@' + formatNumberAum(e.pengajuanNominal) + ' - ' + a
                                    }
                                }

                            },
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    let getDetails = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-detils/${e.value}`, 'GET')

                                    if (getDetails.statusId == 32301) {
                                        await alert('Sedang diproses oleh Akuntansi', 'Peringatan!')
                                    }
                                    console.log(getDetails.statusId);
                                    this.statusPembayaranDetail = getDetails.statusId
                                }
                            },
                            onSelectionChanged: async (e) => {
                                var selected = e.selectedItem
                                if (selected) {
                                    var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `getDetailPengajuanByPembayaranUmumDetailId/${selected.id}`, 'GET')

                                    // if (selected.statusId == 32301) {
                                    //     await alert('Sedang diproses oleh Akuntansi', 'Peringatan!')
                                    // }

                                    // this.statusPembayaranDetail = selected.statusId
                                    this.formKonfirmasiRef.current.instance.updateData({
                                        nominalUangMuka: response ? response.id8 : 0,
                                        cashOut: selected.cashOut || 0,
                                        ppn: selected.ppn,
                                        pph: selected.pph,
                                        biayaLain: selected.biayaLain,
                                        potongan: selected.potongan,
                                        pembayaranUmumId: selected.pembayaranUmumId,
                                        totalNominalPengajuan: response ? response.jumPengajuanNominal : 0,
                                        totalUangMuka: response ? response.totUangmuka : 0,
                                        totalSisaPembayaran: response ? response.id8 : 0,
                                        totalSisaUangMuka: response ? response.id9 : 0,
                                        totalSisaCashOut: response ? response.id10 : 0,
                                    })
                                }
                            },
                            searchEnabled: true,
                            deferRendering: false
                        }
                    },
                    {
                        dataField: 'pembayaranUmumId',
                        label: {
                            alignment: 'left',
                            text: 'Pembayaran ID'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPembayaran',
                        label: {
                            text: 'Tanggal Kirim'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            onValueChanged: (e) => {
                                var val = e.value
                                this.formKonfirmasiRef.current.instance.updateData({ tanggalPenerimaan: val })
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Tanggal Pembayaran tidak boleh kosong"
                            }
                        ]
                    },
                    {
                        name: 'nominalUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Pembayaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Nominal Pembayaran tidak boleh kosong"
                            }
                        ]
                    },
                    {
                        dataField: 'entitiId',
                        label: {
                            text: 'Entitas',
                            alignment: "left",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'entityName', // provides display values
                            searchEnabled: true,
                            deferRendering: false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Harap isi Entitas'
                            }
                        ]
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang',
                            alignment: "left",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Harap isi Mata Uang'
                            }
                        ]
                    },
                    {
                        dataField: 'ppn',
                        label: {
                            alignment: 'left',
                            text: 'PPN'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'pph',
                        label: {
                            alignment: 'left',
                            text: 'PPH'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'biayaLain',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Lain'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'potongan',
                        label: {
                            alignment: 'left',
                            text: 'Potongan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Informasi Pengajuan',
                items: [
                    {
                        dataField: 'reffTransaction',
                        label: {
                            alignment: 'left',
                            text: 'Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'itemPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Item Pembayaran'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'noPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'No Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Keterangan Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPenerimaan',
                        label: {
                            text: 'Tanggal Penerimaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalNominalPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'Total Nominal Pengajuan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Total Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalSisaPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Pembayaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'totalSisaUangMuka',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                    {
                        dataField: 'totalSisaCashOut',
                        label: {
                            alignment: 'left',
                            text: 'Total Sisa Cash Out'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        },
                    },
                ]
            },
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.statusPembayaranDetail = 0
        this.setState({
            popupVisible: false,
            dataMaster: {
                currencyId: 1001
            },
            dataDocument: {
                documentFile: '',
                documentFileContentType: '',
                documentName: '',
            }
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    validate = () => {
        var formValidation = this.formKonfirmasiRef.current.instance.validate();
        return formValidation.isValid
    }

    getEntity = async () => {
        var paramEntity = ['KR', 'SP', 'P3', 'CN', 'GV']
        var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllMultipleByType/${paramEntity.join()}`, 'GET')
        this.formKonfirmasiRef.current.instance.getEditor('entitiId').option('dataSource', data)
    }


    retrieveData = async (data) => {
        // console.log(data)
        var getFile = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-realisasis/getByPembayaranUmum/${data.id}`, 'GET')
        var loadApi = `pembayaran-umums/${data.id}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'GET')
        var totNomPengajuan = []
        var totUangMuka = []

        var pdId = this.formKonfirmasiRef.current.instance.getEditor('pengajuanNo')
        if (getData.id) {
            await pdId.option('dataSource', pembayaranUmumDetilByPuId(getData.id, this.props.store, true))
            var dataPud = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-detils/getByPembayaranUmumId/${getData.id}`, 'GET')
            getData.itemPembayaran = dataPud.length
            // for (var nomPengajuan of dataPud) {
            //     totNomPengajuan.push(nomPengajuan.pengajuanNominalNett)
            //     totUangMuka.push(nomPengajuan.dpAmount)
            // }
            // getData.totalNominalPengajuan = totNomPengajuan.reduce((a, b) => a + b, 0)
            // getData.totalUangMuka = totUangMuka.reduce((a, b) => a + b, 0)
        }
        getData.currencyId = 1001
        getData.totalNominalPengajuan = data.totalNominalPengajuan
        getData.totalUangMuka = data.totalUangMuka
        getData.totalCashOut = data.totalCashOut
        getData.totalSisaPembayaran = data.sisaPembayaran
        getData.totalSisaUangMuka = data.sisaCash

        let document = this.state.dataDocument
        let dataFile = []

        for(let value of getFile) {
            let dataValue = {
                documentFile: value.documentFile,
                documentName: value.documentName,
                documentFileContentType: value.documentFileContentType
            }

            dataFile.push(dataValue)
        }

        document = {
            documentFile: dataFile.length === 0 ? "" : dataFile[0].documentFile, 
            documentName: dataFile.length === 0 ? "" : dataFile[0].documentName, 
            documentFileContentType: dataFile.length === 0 ? "" : dataFile[0].documentFileContentType 
        }
        console.log(document)

        this.setState({
            dataMaster: getData,
            dataDocument: document
        })
        this.formKonfirmasiRef.current.instance.updateData(getData)
    }

    submitData = async () => {
        let data = this.state.dataMaster
        let dataDocument = this.state.dataDocument
        // let getResponseRealisasi = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-realisasis/getByPembayaranUmum/${data.id}`, 'GET')

        if (this.statusPembayaranDetail === 32301) {
            await alert('Sedang diproses oleh Akuntansi', 'Peringatan!')
        } else if (this.statusPembayaranDetail === 5) {
            if(dataDocument.documentFile === "" && dataDocument.documentName === "" && dataDocument.documentFileContentType === "") {
                await alert('File Upload Harus Di Isi', 'Informasi')
            } else {
                var dataPost = {
                    "biayaLain": data.biayaLain,
                    "currencyId": data.currencyId,
                    "documentFile": dataDocument.documentFile,
                    "documentFileContentType": dataDocument.documentFileContentType,
                    "documentName": dataDocument.documentName,
                    "entity": data.entitiId,
                    "itemPembayaran": data.itemPembayaran,
                    "kasUmumId": 0,
                    "keteranganPengajuan": this.keterangan,
                    "noPengajuan": data.noPengajuan,
                    "noPengajuanInfo": data.noPengajuan,
                    "nominalCashOut": 0,
                    "nominalKasUmum": 0,
                    "nominalTransfer": 0,
                    "nominalUangMuka": data.nominalUangMuka,
                    "pembayaranUmumDetilId": data.pengajuanNo,
                    "pembayaranUmumId": data.pembayaranUmumId,
                    "potongan": data.potongan,
                    "pph": data.pph,
                    "ppn": data.ppn,
                    "reffTransaksi": data.reffTransaction,
                    "rekBankId": 0,
                    "sisaCashOut": data.totalSisaCashOut,
                    "sisaPembayaran": data.totalSisaPembayaran,
                    "sisaUangMuka": data.totalSisaUangMuka,
                    "tanggalPembayaran": data.tanggalPembayaran,
                    "tanggalPenerimaan": data.tanggalPenerimaan,
                    "totalNominalPengajuan": data.totalNominalPengajuan,
                    "uangMuka": data.nominalUangMuka || 0,
                    "username": this.props.store.getState().sessionUser,
                }
            }
            try {
                var postAPI = 'pembayaran-umum-aktif-arap-sp'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'POST', {
                    values: dataPost
                })

                if (response) {
                    await alert('Realisasi Pembayaran Berhasil Dikirim!', 'Berhasil')
                    this.hide()
                    this.props.forceRefresh()
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } else {
            await alert('Sedang proses persetujuan atau verifikasi', 'Peringatan!')
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)

                this.setState(prevState => ({
                    dataDocument: {
                        ...prevState.dataDocument,
                        [`documentName`]: files.name,
                        [`${fieldName}Extension`]: formatNameAndExtention.extention,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))

            }

            fr.readAsDataURL(files)
        }
    }

    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            var src = imageSource(this.state.dataDocument[`${fileName}ContentType`], this.state.dataDocument[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataDocument[`${fileName}ContentType`], this.state.dataDocument[`${fileName}`]), this.state.dataDocument[`${fileName}Name`])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Pembayaran'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid" style={{ height: '100%' }}>
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formKonfirmasiRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation='left'
                                />
                            </div>
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataDocument.documentName || 'File'}
                                    showFileList={false}
                                    name={'documentFile'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('documentFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataDocument.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('documentFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataDocument.documentFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default PPRealisasiSPModal