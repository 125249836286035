import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import httpRequest from "plugin/httprequest";
import notify from 'devextreme/ui/notify';
import { showLoading } from "redux/actions/loading";

class ModalUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {}
        };

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    // onClick: console.log('Simpan')
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                dataField: 'resetDate',
                label: {
                    alignment: 'left',
                    text: 'Last Generate Password'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true,
                    readOnly: true
                }
            },
            {
                dataField: 'password',
                label: {
                    alignment: 'left',
                    text: 'New Password'
                }
            }
        ]
    }

    show = () => {
        this.setState({
            popupVisible: true
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };
    
    retrieveData = async (data) => {
        if(data){
            var loadAPI = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `reset-passwords?userId.equals=${data.id}`, 'GET')

            if(loadAPI.length > 0){
                var dataValue = {
                    userId: loadAPI[0].userId,
                    resetDate: loadAPI[0].resetDate
                }

                this.setState({
                    formData: dataValue
                })
            }else{
                var dataValue = {
                    userId: data.id,
                    resetDate: null
                }

                this.setState({
                    formData: dataValue
                })
            }
        }
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        var data = {...this.state.formData}
        console.log(data)
        var dataPost = {
            password: data.password,
            userId: data.userId
        }

        try{
            let resetUserPassword = `account/resetUserPassword`
            await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, resetUserPassword, 'POST', { values: dataPost })

            this.props.store.dispatch(showLoading(false))
            this.hide()
            notify({ message: 'Berhasil Reset Password', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
            this.props.refreshDataGrid()
        }catch(e){
            this.props.store.dispatch(showLoading(false))
            console.log(e)
            this.hide()
            notify({ message: 'Terjadi Kesalahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            this.props.refreshDataGrid()
        }
    }

    render() {
        console.log(this.state.formData)
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Reset Password"}
                    width={"70vw"}
                    height={"70vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form 
                        colCount={1}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.formItem}
                        ref={this.formRef}
                        labelLocation="left"
                    />
                </Popup>
            </React.Fragment>

        );
    }
}

export default ModalUser;