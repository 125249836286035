import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { bank } from '../parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import FormEditorBank from '../modal/static-data/bank/form'
import UploadBank from '../modal/static-data/bank/upload'

class Bank extends Component {
    constructor(props){
        super(props)
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModalEditor(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'bankCode',
                caption: 'Kode Bank',
            },
            {
                dataField: 'bankName',
                caption: 'Nama Bank',
            },
            {
                dataField: 'biCode',
                caption: 'Kode BI',
            },
            {
                dataField: 'initialName',
                caption: 'Initial Name',
            },
            {
                dataField: 'swiftCode',
                caption: 'Kode Swift',
            },
        ]

        this.modalEditorRef = React.createRef()
        this.modalUploadBankRef = React.createRef()
        this.datagridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'upload',
                    text: 'Upload Data Performa',
                    onClick: (e) => {
                        this.showModalUpload()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalEditor()
                    },
                }
            }
        )
    }

    showModalEditor = (data) => {
        this.modalEditorRef.current.show(data)
    }

    showModalUpload = () => {
        this.modalUploadBankRef.current.show()
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Bank</h2>

                <DevExpressDataGrid
                    ref={this.datagridRef}
                    loadAPI='banks'
                    insertAPI='staticdata-wf/banks'
                    updateAPI='staticdata-wf/banks'
                    deleteAPI='staticdata-wf/banks' 

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Banks"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Bank Data'}
                    popupWidth={'70vw'} //masukan dalam ukuran pixel
                    popupHeight={'75vh'} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={2} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    formItems = {this.columns}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormEditorBank ref={this.modalEditorRef} store={this.props.store} forceRefresh={this.forceRefresh}/>
                <UploadBank ref={this.modalUploadBankRef} store={this.props.store} forceRefresh={this.forceRefresh}/>
            </div>
        )
    }
}

export default Bank
