import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, kpds, product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, formatDefaultFullDate } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import { reportFile } from 'plugin/reportRequest'

class LaporanTransaksiPerAkun extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null


        this.state = {
            filterData: {
                productId: 1,
                noKontrak: 1,
                akunId: [],
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }
        this.datagridRef = React.createRef()
        this.selectedRowDatas = []

        this.columns = [
            {
                dataField: 'akunId',
                caption: 'Kode Akun',
                lookup: {
                    dataSource: akunInvestasi(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunCode'
                }
            },
            {
                dataField: 'akunName',
                caption: 'Nama Akun',
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'transactionName',
                caption: 'Nama Transaksi',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Instrument / Bilyet No',
            },
            // {
            //     dataField: 'instrumentName',
            //     caption: 'Nama Instrument'
            // },
            {
                dataField: 'db',
                caption: 'Debet',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'cr',
                caption: 'Kredit',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'mutasi',
                caption: 'Mutasi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            // {
            //     dataField: 'interest',
            //     caption: 'Interest',
            //     alignment: 'right',
            //     dataType: 'number',
            //     format: '#,##0.00',
            // },
            {
                dataField: 'quantity',
                caption: 'Kuantitas',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'price',
                caption: 'Harga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'priceAverage',
                caption: 'Average Price',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'amountTrs',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            // {
            //     dataField: 'days',
            //     caption: 'Jumlah Hari',
            //     alignment: 'right',
            //     dataType: 'number',
            //     format: '#,##0',
            // },
            {
                dataField: 'faceValue',
                caption: 'Face Value',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'gainPercent',
                caption: 'Persentase (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.productCode + ' - ' + e.productName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'noKontrak',
                        label: {
                            text: 'Pengelola',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'akunId',
                        label: {
                            text: 'Akun',
                        },
                        editorType: 'dxTagBox',
                        editorOptions: {
                            dataSource: akunInvestasi(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'akunName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            deferRendering: false,
                            hideSelectedItems: true,
                            width: '100%',
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'startDate',
                        label: {
                            text: 'Periode Investasi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                        }
                    },
                    {
                        dataField: 'endDate',
                        label: {
                            visible: false
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                // this.setState({
                                //     filterData: this.state.filterData
                                // })

                                this.loadDummyData()
                                this.datagridRef.current.forceRefresh(true)
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]

        this.sumHasilInvestasi = [
            // {
            //     showInColumn: 'instrumentCode',
            //     displayFormat: 'Total :'
            // },
            // {
            //     column: 'db',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'db',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'cr',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumCr',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'mutasi',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumMutasi',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'unrealizeGainloss',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'unrealizeGainloss',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'lainnya',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumLainnya',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'realizeGainloss',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'realizeGainloss',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'bebanInvestasi',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumBebanInvestasi',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'netRealizeGainloss',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumHasilInvestBersihReal',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'avgInvestasi',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumRataRataInvest',
            //     displayFormat: '{0}'
            // },
            // {
            //     column: 'roi',
            //     summaryType: 'sum',
            //     valueFormat: '#,##0.00',
            //     name: 'sumRoi',
            //     displayFormat: '{0}'
            // },
        ]
    }
    loadDummyData = async () => {
        var startDate = formatDate(this.state.filterData.startDate)
        var endDate = formatDate(this.state.filterData.endDate)
        var product = this.state.filterData.productId
        var kpd = this.state.filterData.noKontrak
        var akunId = this.state.filterData.akunId

        // var getAllAkun = await httpRequest(
        //     process.env.REACT_APP_BACKEND_INVESTASI,
        //     this.props.store,
        //     'akuns',
        //     "GET"
        // );

        if (akunId.length == 0 || akunId.length == null) {
            var getId = 0
            // var getId = getAllAkun.map((elem) => {
            //     return elem.id
            // }).join(',')
        }


        var loadApi = kpd && product && startDate && endDate && akunId.length > 0
            ? `transaction-jurnal-dtls/report-transaksi-per-akun?akunIdList=${akunId.join()}&endDate=${endDate}&kpdId=${kpd}&productId=${product}&size=999&startDate=${startDate}`
            : kpd && product && startDate && endDate
                ? `transaction-jurnal-dtls/report-transaksi-per-akun?akunIdList=${getId}&endDate=${endDate}&kpdId=${kpd}&productId=${product}&size=999&startDate=${startDate}`
                : `transaction-jurnal-dtls/report-transaksi-per-akun?akunIdList=${getId}&endDate=${formatDate(new Date)}&kpdId=${kpd}&productId=${product}&size=999&startDate=${formatDate(new Date)}`

        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadApi,
            "GET"
        );

        let id = 0
        let result = []
        for (var data of response) {
            id++
            let dataTransaksi = {
                id: id,
                ...data,
            }
            result.push(dataTransaksi)
        }

        return result
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    text: 'Cetak Data',
                    onClick: (e) => {
                        this.cetakData(this.state.filterData.startDate, this.state.filterData.endDate, this.state.filterData.productId, this.state.filterData.noKontrak, this.state.filterData.akunId)
                    },
                }
            }
        )
    }

    cetakData = async (startDate, endDate, productId, kpdId, akunId) => {
        // var response = this.datagridRef.current.getDataSource()
        var response = this.selectedRowDatas

        var transaksiDetail = []
        if (response.length > 0) {
            var arrDebet = []
            var arrKredit = []
            var arrMutasi = []

            for (var data of response) {
                arrDebet.push(data.db)
                arrKredit.push(data.cr)
                arrMutasi.push(data.mutasi)

                var dataResult = {
                    no: 1,
                    akunId: data.akunId,
                    akunName: data.akunName,
                    transactionDate: data.transactionDate,
                    transactionName: data.transactionName,
                    instrumentCode: data.instrumentCode,
                    avgPrice: data.priceAverage !== null ? data.priceAverage : 0,
                    db: data.db !== null ? data.db : 0,
                    cr: data.cr !== null ? data.cr : 0,
                    mutasi: data.mutasi !== null ? data.mutasi : 0,
                    interest: data.interest !== null ? data.interest : 0,
                    quantity: data.quantity !== null ? data.quantity : 0,
                    price: data.price !== null ? data.price : 0,
                    amountTrs: data.amountTrs !== null ? data.amountTrs : 0,
                    persentase: data.gainPercent !== null ? data.gainPercent : 0,
                    faceValue: data.faceValue !== null ? data.faceValue : 0
                }
                transaksiDetail.push(dataResult)

                var totalNilaiDebet = arrDebet.reduce((a, b) => a + b, 0)
                var totalNilaiKredit = arrKredit.reduce((a, b) => a + b, 0)
                var totalNilaiMutasi = arrMutasi.reduce((a, b) => a + b, 0)
            }

            reportFile({
                template: { 'shortid': '~qABi1D' },
                data: {
                    "startDate": formatDefaultFullDate(startDate),
                    "endDate": formatDefaultFullDate(endDate),
                    // "pengelola": kpdData.noKontrak,
                    "dailyBalanceDetail": transaksiDetail,
                    "totalNilaiDebet": totalNilaiDebet,
                    "totalNilaiKredit": totalNilaiKredit,
                    "totalNilaiMutasi": totalNilaiMutasi
                },
                options: {
                    reportName: "Transaksi Per Akun"
                }
            }, true, `Transaksi Per Akun.pdf`)
        }

    }

    onSelectionChanged = (e) => {
        this.selectedRowDatas =  e.selectedRowsData
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Transaksi Per Akun</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.datagridRef}
                    loadAPI={`transaction-jurnal-dtls/report-transaksi-per-akun?akunIdList=${this.state.filterData.akunId}&endDate=${formatDate(this.state.filterData.endDate)}&productId=${this.state.filterData.productId}&size=999&startDate=${formatDate(this.state.filterData.startDate)}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Transaksi Per Akun"}
                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'db',
                            'cr',
                            'mutasi',
                            'quantity',
                            'price',
                            'priceAverage',
                            'amountTrs',
                            'faceValue',
                            'gainPercent'
                        ]
                    }
                    allowExportSelectedData={true}
                    selection={'multiple'}

                    showBorders={true}
                    height={'calc(100vh - 330px)'}
                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumHasilInvestasi}

                    onToolbarPreparing={this.onToolbarPreparing}
                    
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanTransaksiPerAkun