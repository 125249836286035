import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalPotongan from 'components/pages/modal/sdm-umum/pengaturan/potongan/form';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import { dasarNilai, potonganGroups, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import notify from 'devextreme/ui/notify';
import uuidv4  from 'uuid/v4'
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { confirm } from 'devextreme/ui/dialog'

class Potongan extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalPotongan(e)
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : async (e) => {
                            let data = e.row.data
                            let result = await confirm("Apakah anda yakin ingin menghapus data ini?", "Konfirmasi");

                            if (result) {
                                this.delete(data)
                            }
                        }
                    },
                ],
            },
            {
                dataField:"potonganCode",
                caption:"Kode"
            },
            {
                dataField:"potonganName",
                caption:"Potongan"
            },
            {
                dataField:"transactionTypeId",
                caption:"Tipe Transaksi",
                lookup:{
                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'transactionTypeName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"dasarNilaiId",
                caption:"Dasar Nilai",
                lookup:{
                    dataSource: dasarNilai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dasarNilai', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'potonganGroupId',
                caption: 'Potongan Group',
                lookup:{
                    dataSource: potonganGroups(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'groupName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"dasarNilaiPotongan",
                caption:"Dasar Nilai Potongan",
                alignment:'center',
                columns:[
                    {
                        dataField:"persenGapok",
                        caption:"Gaji Pokok",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00 %'
                    },
                    {
                        dataField:"persenGajiTunjangan",
                        caption:"Gaji Pokok + Tunjangan",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00 %'
                    },
                    {
                        dataField:"nominal",
                        caption:"Nominal",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                ]
            },
            {
                dataField:"status",
                caption:"Status",
                lookup:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Aktif'
                        },
                        {
                            id: false,
                            value: 'Non Aktif'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            }
        ]

        this.dataGridRef = React.createRef()
        this.showModalPotonganRef = React.createRef()
        this.selectedRowKeys = []
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'trash',
                    text: 'hapus',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin ingin menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: () => {
                                        this.selectedDelete(e)
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ]
                        })
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPotongan(e)
                    },
                }
            },
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    showModalPotongan = (e) => {
        if (e.row) {
            this.showModalPotonganRef.current.retrieveData(e.row.data)
        }
        this.showModalPotonganRef.current.show()
    }

    delete = async(data) => {
        let id = data.id
        if(id) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'potongan-types', 'DELETE', id)
                this.forceRefresh()
                notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success', 1000);
            } catch (e) {
                notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error', 1000);
            }
        }
    }

    selectedDelete = async () => {
        var errorMessage = []
        if (this.selectedRowKeys.length === 0) {
            notify({message: "Silahkan pilih yang akan di hapus",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',3000);
            return null
        }
        
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'potongan-types', 'DELETE', this.selectedRowKeys[i])
            } catch (e){
                console.log(e);
                errorMessage.push('Sebagian data tidak bisa dihapus, karena sudah digunakan')
            }

        }
        
        if (errorMessage.length > 0) {
            notify({message: errorMessage.join('\n'),width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
            this.forceRefresh()
            return
        }

        this.forceRefresh()
        notify({message: 'Data Berhasil Dihapus',width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }

    render() {
        return (
            <div className="container-fluid">
            <h2 className="main-title">Potongan</h2>

            <DevExpressDataGrid
                ref = {this.dataGridRef}
                loadAPI='potongan-types?size=9999'
                insertAPI='potongan-types'
                updateAPI='potongan-types'
                deleteAPI='potongan-types' 

                backendserver={process.env.REACT_APP_BACKEND_HRD}
                // useArraySource={true}
                // ArraySourceData={this.loadDummyData}
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={true}
                exportFileName={"Potongan"}
                allowExportSelectedData={true}
                selection={"multiple"}
                
                showBorders={true}

                paging={true}
                defaultPageSize={10}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Potongan'}
                popupWidth={450} //masukan dalam ukuran pixel
                popupHeight={330} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={1} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                SummaryConfiguration={this.summary}

                onToolbarPreparing = {this.onToolbarPreparing}
                onSelectionChanged = {this.onSelectionChanged}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
            <FormModalPotongan 
                ref={this.showModalPotonganRef}
                store={this.props.store}
                forceRefresh={this.forceRefresh}
            />
        </div>
        )
    }
}

export default Potongan