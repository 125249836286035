import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import { Form, Button, DataGrid, FileUploader, CheckBox } from 'devextreme-react';
import { Paging, Column } from 'devextreme-react/data-grid';
import { gender, participants, months, kpds, productDefault, rekeningBank, instrumentSubCategoryDeposito, currency } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { ab2str, yyyymmddToDate, formatNumber, cleanQuote, ddmmyyyyToDate, formatDate } from 'plugin/helper';
import XLSX from 'xlsx'
import Popup from 'devextreme-react/popup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { showLoading } from 'redux/actions/loading';
import { confirm } from 'devextreme/ui/dialog';
import ScrollView from 'devextreme-react/scroll-view';

class ModalUploadKpdDeposito extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            redirectBack: false,
            uploadedData: [],
            // selectedTabIndex: 0,
            useHeader: false,
            formData: {},
            popupVisible: false,
        }

        this.prevTabIndex = 0
        this.getBank = []

        this.TabDataSource = [
            {
                id: 0,
                text: "Data Sesuai"
            },
            {
                id: 1,
                text: "Data Tidak Sesuai"
            },
        ];
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.columns = [
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Instrument',
            },
            {
                dataField: 'bankCode',
                caption: 'Kode Bank',
            },
            {
                dataField: 'bankName',
                caption: 'Nama Bank',
            },
            {
                dataField: 'tglPenempatan',
                caption: 'Tanggal Penempatan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tenor',
                caption: 'Tenor (Hari)',
            },
            {
                dataField: 'rate',
                caption: 'Rate (%pa)',
            },
            {
                dataField: 'tglJatuhTempo',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'nominalPenempatan',
                caption: 'Nominal Penempatan',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
        this.formItem = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'No. Konfirmasi'
                },
                editorOptions: {
                    readOnly: true
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Nomor Konfirmasi tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Produk tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'kpdId',
                label: {
                    alignment: 'left',
                    text: 'KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: kpds(this.props.store),
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'noKontrak'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'KPD tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'rekBankId',
                label: {
                    alignment: 'left',
                    text: 'Rekening Bank'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Rekening tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'categorySubId',
                label: {
                    alignment: 'left',
                    text: 'Jenis Deposito'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentSubCategoryDeposito(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Jenis Deposito tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'biayaBank',
                label: {
                    alignment: 'left',
                    text: 'Biaya Bank'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0
                },
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Mata Uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    onValueChanged: async (e) => {
                        var curr = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${e.value}`, 'GET')
                        this.formRef.current.instance.updateData('kurs', curr.rate)
                    }
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Mata uang tidak boleh kosong'
                    }
                ]
            },
            {
                dataField: 'kurs',
                label: {
                    alignment: 'left',
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0
                }
            },
        ]
        this.fileUploderRef = React.createRef()
        this.PopupRef = React.createRef()
        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()
        this.formRef = React.createRef()
        this.successData = []
        this.errorData = []
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    getKpd = async() => {
        let loadAPI = 'kpds';
        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
        response = response.filter(val => val.kpdType !== 'SWAKELOLA');

        this.formRef.current.instance.getEditor('kpdId').option('dataSource', response);
    }

    show = async () => {
        var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `bank-transactions/generate-no-konfirmasi/UPLOAD_KPD_DEPOSITO`,
                "POST", {
                values: {}
            }
        );
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            formData: {
                noKonfirmasi: response
            }
        });
        this.forceRefresh()
        this.getBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET')
    }

    hide = () => {
        this.setState({
            popupVisible: false,
        });
        this.successData = []
        this.errorData = []
    };
    onUploadStarted = (e) => {
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if (fileType === 'xls' || fileType === 'xlsx') {
                    var workbook = XLSX.read(dataArrayBuffer, {
                        type: 'array',
                        cellDates: true,
                        dateNF: 'dd/mm/yyyy',
                    });
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet, {
                        // raw: false,
                        defval: null,
                    })
                    dataJSON = dataJSON.map(value => {
                        value['Book Date'].setHours(value['Book Date'].getHours() + 7)
                        value['Maturity Date'].setHours(value['Maturity Date'].getHours() + 7)
                        // value['Book Date'] = value['Book Date'].setHours(value['Book Date'].getHours() + 7)
                        // value['Settle Date'] = value['Settle Date'].toLocaleString()
                        return value
                    })

                    data = dataJSON
                }

                if (fileType === 'csv') {
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                        data = data.filter(value => {
                            regex.lastIndex = 0
                            return regex.test(value)
                        }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)

                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)

                                var splitRes = splitRes.map(splitVal => splitVal.trim())

                                var splitRes = splitRes.map(splitVal => {
                                    if (String(splitVal)) {
                                        return String(splitVal)
                                    } else {
                                        return splitVal
                                    }
                                })

                                return splitRes
                            }
                        })
                    } else {
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                var errorMessage = []

                for (var uploadedData of data) {
                    if (Array.isArray(uploadedData) || typeof uploadedData === 'object') {
                        errorMessage = []

                        const selectedBank = this.getBank.find(val => val.bankName === uploadedData["Counterparty Name"])
                        if (!selectedBank) {
                            errorMessage.push('Bank tidak tersedia')
                        }
                        if (errorMessage.length > 0) {
                            errorData.push({
                                errorMessage: errorMessage,
                                tglPenempatan: formatDate(uploadedData["Book Date"]),
                                tglJatuhTempo: formatDate(uploadedData["Maturity Date"]),
                                bankCode: uploadedData["Counterparty Code"],
                                bankName: uploadedData["Counterparty Name"],
                                instrumentCode: uploadedData["Deal No"],
                                instrumentName: uploadedData["Instrument Name"],
                                nominalPenempatan: uploadedData["Principal"],
                                tenor: uploadedData["Term Days"],
                                rate: uploadedData["Interest Rate"],
                            })
                            continue
                        }
                        successData.push({
                            tglPenempatan: formatDate(uploadedData["Book Date"]),
                            tglJatuhTempo: formatDate(uploadedData["Maturity Date"]),
                            bankCode: uploadedData["Counterparty Code"],
                            bankName: uploadedData["Counterparty Name"],
                            instrumentCode: uploadedData["Deal No"],
                            instrumentName: uploadedData["Instrument Name"],
                            nominalPenempatan: uploadedData["Principal"],
                            tenor: uploadedData["Term Days"],
                            rate: uploadedData["Interest Rate"],
                        })
                    }
                }
                if (errorData.length > 0) {
                    let type = "error";
                    let text = "Ada beberapa data unggahan yang gagal diunggah, harap periksa tab data unggahan yang gagal";
                    notify(
                        {
                            message: text,
                            width: "AUTO",
                            shading: true,
                            position: { at: "center", my: "center", of: window }
                        },
                        type,
                        1000
                    );
                }
                this.successData = successData
                this.errorData = errorData
                this.forceRefresh()
            }
            reader.readAsArrayBuffer(files);
        }
    }
    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }
    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    submitData = async () => {
        var dataSesuai = this.successData
        var dataTakSesuai = this.errorData
        if (dataTakSesuai.length > 0) {
            var message = 'Ada data yang tidak sesuai, apakah ingin tetap lanjut?'
        } else {
            var message = 'Apakah anda yakin ingin mengunggah data ini?'
        }
        var value = {
            ...this.state.formData,
            transactionDate: formatDate(new Date(this.sysDate.dateValue)),
            uploadKonfirmasiKpdDepositoItemList: dataSesuai
        }
        // console.log(value)
        // return false
        let result = await confirm(message, "Konfirmasi")
        if (result) {
            this.props.store.dispatch(showLoading(true))
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/upload-kpd-deposito`, 'POST', {
                    values: value
                })
                notify({ message: 'Sukses!', width: 'AUTO', position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.props.forceRefresh()
                this.hide()
            } catch (e) {
                console.log(e)
                this.props.store.dispatch(showLoading(false))
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
            this.props.store.dispatch(showLoading(false))
        }
    }
    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns]
        columns.unshift({
            type: 'buttons',
            buttons: [
                {
                    text: "Error Detail",
                    hint: "Error Detail",
                    icon: "warning",
                    cssClass: "text-danger",
                    onClick: e => {
                        console.log(e)
                        let type = "error";
                        let text = e.row.data.errorMessage;

                        notify(
                            {
                                message: text,
                                width: "AUTO",
                                shading: true,
                                position: { at: "center", my: "center", of: window }
                            },
                            type,
                            2000
                        );
                    }
                }
            ]
        });

        return columns;
    }

    onValueChanged = (e) => {
        this.setState({
            useHeader: e.value
        })
    }

    forceRefresh = () => {
        this.dataGridSesuaiRef.current.forceRefresh(true)
        this.dataGridTakSesuaiRef.current.forceRefresh(true)
    };

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Upload Konfirmasi KPD'}
                    minWidth={'70vh'}
                    minHeight={'50vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'form'}
                                        formData={this.state.formData}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation={'left'}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-4">
                                <div style={{ width: "350px" }}>
                                    <FileUploader
                                        accept={'.txt,.xls,.xlsx,.csv'}
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)}
                                        labelText="Upload KPD File Here"
                                        showFileList={false}
                                        name={'reksadanaAsset'}

                                        value={this.state.uploadedData}

                                        ref={this.fileUploderRef}
                                    />
                                </div>
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <CheckBox
                                        value={this.state.useHeader}
                                        onValueChanged={this.onValueChanged}
                                    />
                                    <span className="ml-2">Use Header</span>
                                </div>
                            </div>
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                            <div id="tabindex-0" className={' mt-2 text-center'}>
                                <DevExpressDataGrid
                                    ref={this.dataGridSesuaiRef}
                                    keyField={'instrumentCode'}
                                    loadAPI=''
                                    insertAPI=''
                                    updateAPI=''
                                    deleteAPI=''

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                                    useArraySource={true}
                                    ArraySourceData={this.retrieveDataSuccess}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Data Sukses"}

                                    allowExportSelectedData={false}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Sukses'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                    // SummaryConfiguration={this.summary}
                                    // onToolbarPreparing = {this.onToolbarPreparing}

                                    height={'calc(70vh - 175px)'}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <div id="tabindex-1" className={'d-none mt-2 text-center'} >
                                <DevExpressDataGrid
                                    ref={this.dataGridTakSesuaiRef}
                                    keyField={'instrumentCode'}
                                    loadAPI=''
                                    insertAPI=''
                                    updateAPI=''
                                    deleteAPI=''

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                                    useArraySource={true}
                                    ArraySourceData={this.retrieveDataFailed}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Data Sukses"}

                                    allowExportSelectedData={false}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Sukses'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.errorDataGridErrorButtonColumn()} // taruh konfigurasi kolom disini
                                    // SummaryConfiguration={this.summary}
                                    // onToolbarPreparing = {this.onToolbarPreparing}

                                    height={'calc(70vh - 175px)'}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalUploadKpdDeposito