import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'
import PreviewApproval from 'components/pages/modal/previewApproval'
import { addURL } from 'redux/actions/url'
import { lookupModule } from 'components/pages/parts/staticDataColumnConfig'

class CheckerList extends Component {
    constructor(props) {
        super(props)
        this.roleChecker = props.store.getState().sessionRole ? props.store.getState().sessionRole.find(value => value == 'CHECKER') ? true : false : null
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Show Data',
                        hint: 'Show Data',
                        icon: "info",
                        cssClass: "text-info",
                        onClick: this.getProcessData.bind(this)
                    },
                ],
            },
            // {
            //     dataField: 'procInstanceId',
            //     caption: 'Process ID',
            // },
            {
                dataField: 'id',
                caption: "No.",
                cellRender: (data) => {
                  return data.rowIndex + 1
                },
            },
            {
                dataField: 'type',
                caption: 'Module',
                lookup: {
                    dataSource: lookupModule,
                    valueExpr: 'id',
                    displayExpr: 'value'
                },
                editorOptions: {
                    dataSource: lookupModule,
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'operation',
                caption: 'Operation',
            },
            {
                dataField: 'taskName',
                caption: 'Task Name',
            },
            {
                dataField: 'creatorId',
                caption: 'Create By',
            },
            {
                dataField: 'startTime',
                caption: 'Task Created Date',
                dataType: 'date',
                format: 'dd MMM yyy HH:mm:ss',
                sortOrder: 'desc'
            },
        ]

        this.previewApprovalRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    getProcessData = async (e) => {
        var processData = await httpRequest(e.row.data.backendserver, this.props.store, 'staticdata-wf/', 'GET', e.row.data.procInstanceId)
        this.previewApprovalRef.current.setFields(e.row.data.type, this.props.store)
        this.previewApprovalRef.current.setData(e.row.data, processData.data, e.row.data.taskId)
        this.previewApprovalRef.current.show()
    }

    refreshDataGrid = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var dataWFDefault = [], dataWFAcc = [], dataWFCore = [], dataWFHrd = [], dataWFInv = []
        if (this.roleChecker == true) {
            try {
                dataWFDefault = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `staticdata-wf/tasks/${this.props.approveType}`)

                dataWFDefault = dataWFDefault.map(value => {
                    value.backendserver = process.env.REACT_APP_BACKEND
                    return value
                })
            } catch (e) {
                console.log(e)
            }

            try {
                dataWFAcc = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `staticdata-wf/tasks/${this.props.approveType}`)

                dataWFAcc = dataWFAcc.map(value => {
                    value.backendserver = process.env.REACT_APP_BACKEND_ACC
                    return value
                })
            } catch (e) {
                console.log(e)
            }

            try {
                dataWFHrd = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `staticdata-wf/tasks/${this.props.approveType}`)

                if (!Array.isArray(dataWFHrd)) {
                    dataWFHrd = []
                }

                dataWFHrd = dataWFHrd.map(value => {
                    value.backendserver = process.env.REACT_APP_BACKEND_HRD
                    return value
                })
            } catch (e) {
                console.log(e)
            }

            try {
                dataWFCore = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `staticdata-wf/tasks/${this.props.approveType}`)

                dataWFCore = dataWFCore.map(value => {
                    value.backendserver = process.env.REACT_APP_BACKEND_CORE
                    return value
                })
            } catch (e) {
                console.log(e)
            }

            try {
                dataWFInv = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/tasks/${this.props.approveType}`)

                dataWFInv = dataWFInv.map(value => {
                    value.backendserver = process.env.REACT_APP_BACKEND_INVESTASI
                    return value
                })
            } catch (e) {
                console.log(e)
            }

        }
        var data = [...dataWFDefault, ...dataWFAcc, ...dataWFHrd, ...dataWFCore, ...dataWFInv]

        return data
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Checker List</h2>

                <DevExpressDataGrid
                    loadAPI={`staticdata-wf/tasks?assignee=${this.props.approveType}`}
                    insertAPI={`staticdata-wf/tasks?assignee=${this.props.approveType}`}
                    updateAPI={`staticdata-wf/tasks?assignee=${this.props.approveType}`}
                    deleteAPI={`staticdata-wf/tasks?assignee=${this.props.approveType}`}

                    backendserver={process.env.REACT_APP_BACKEND}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    keyField={'taskId'}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Checker List"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Checker List Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                    ref={this.dataGridRef}
                />
                <PreviewApproval store={this.props.store} ref={this.previewApprovalRef} approveType={this.props.approveType} refreshDataGrid={this.refreshDataGrid} />
            </div>
        )
    }
}

export default CheckerList