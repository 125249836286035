import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager, instrumentSubCategoryPenyertaan } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import ArrayStore from 'devextreme/data/array_store';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import { alert } from 'devextreme/ui/dialog';


class ModalInstrumentPl extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.productId = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'noPengajuan',
                        label: {
                            text: 'No Pengajuan'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'kodeInstrument',
                        label: {
                            text: 'Kode Instrument',
                        },
                    },
                    {
                        dataField: 'namaPerusahaan',
                        label: {
                            text: 'Nama Perusahaan',
                        },
                    },
                    {
                        dataField: 'nilaiPerusahaan',
                        label: {
                            text: 'Nilai Perusahaan',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'totalLembarSaham',
                        label: {
                            text: 'Total Lembar Saham Di Terbitkan',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var jmlSahamPenyertaan = this.formMasterRef.current.instance.getEditor('jmlSahamPenyertaan').option('value')
                                var totalLembarSaham = e.value

                                this.formMasterRef.current.instance.updateData('prosentasePenyertaan', jmlSahamPenyertaan / totalLembarSaham * 100)
                            }
                        }
                    },
                    {
                        dataField: 'nilaiPerLembarSaham',
                        label: {
                            text: 'Nilai Per Lembar Saham',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nominalPenyertaan = this.formMasterRef.current.instance.getEditor('nominalPenyertaan').option('value')
                                var nilaiPerLembarSaham = e.value

                                this.formMasterRef.current.instance.updateData('jmlSahamPenyertaan', nominalPenyertaan / nilaiPerLembarSaham)
                            }
                        }
                    },
                    {
                        dataField: 'subCategory',
                        label: {
                            text: 'Sub Kategori',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentSubCategoryPenyertaan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'PENYERTAAN',
                items: [
                    {
                        dataField: 'nominalPenyertaan',
                        label: {
                            text: 'Nominal Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nominalPenyertaan = e.value
                                var nilaiPerLembarSaham = this.formMasterRef.current.instance.getEditor('nilaiPerLembarSaham').option('value')

                                this.formMasterRef.current.instance.updateData('jmlSahamPenyertaan', nominalPenyertaan / nilaiPerLembarSaham)
                            }
                        }
                    },
                    {
                        dataField: 'jmlSahamPenyertaan',
                        label: {
                            text: 'Jumlah Saham Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                            onValueChanged: (e) => {
                                var jmlSahamPenyertaan = e.value
                                var totalLembarSaham = this.formMasterRef.current.instance.getEditor('totalLembarSaham').option('value')

                                this.formMasterRef.current.instance.updateData('prosentasePenyertaan', jmlSahamPenyertaan / totalLembarSaham * 100)
                            }
                        }
                    },
                    {
                        dataField: 'prosentasePenyertaan',
                        label: {
                            text: 'Prosentase Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                        }
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        dataField: 'biayaPenyertaan',
                        label: {
                            text: 'Biaya Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'rekBankId',
                        label: {
                            text: 'Rekening Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.rekNo + ' - ' + e.rekName
                            },
                            searchEnabled: true,
                        }
                    },
                ]
            }
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    show () {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data) => {
        if(data){
            this.productId = data.productId;
            if (data.productId) {
                var rekData = await this.rekBankByProduct(this.props.store, data.productId);
                this.formMasterRef.current.instance.getEditor('rekBankId').option('dataSource', rekData)
            }
            this.setState({
                data: {
                    id: data.id,
                    biayaPenyertaan: data.biayaPenyertaan,
                    jmlSahamPenyertaan: data.jmlSahamPenyertaan,
                    kodeInstrument: data.kodeInstrument,
                    namaPerusahaan: data.namaPerusahaan,
                    nilaiPerLembarSaham: data.nilaiPerLembarSaham,
                    nilaiPerusahaan: data.nilaiPerusahaan,
                    noPengajuan: data.noPengajuan,
                    nominalPenyertaan: data.nominalPenyertaan,
                    // orderRequestId: data.orderRequestId,
                    prosentasePenyertaan: data.prosentasePenyertaan,
                    rekBankId: data.rekBankId,
                    subCategory: data.subCategory,
                    totalLembarSaham: data.totalLembarSaham,
                },
            });
        }
    }

    hide() {
        this.productId = 0
        this.setState({
            popupVisible: false,
        })
    }
    rekBankByProduct = async (store, productId) => {
        let loadAPI = `rek-banks-by-productId?productId=${productId}`
        var getRekening = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRekening,
            key: 'id'
        });

        return dataSource
    }
    submitData = async () => {
        var data = this.state.data
        let validateBatasan = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-validasi-pengajuan-inv/${this.productId}/${data.subCategory}/${data.nominalPenyertaan}`, 'GET')
        if (validateBatasan.length > 0) {
            if (validateBatasan[0].potensiselisih >= 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")

                this.props.submitDetailPenempatanData({
                    id: data.id,
                    biayaPenyertaan: data.biayaPenyertaan,
                    jmlSahamPenyertaan: data.jmlSahamPenyertaan,
                    kodeInstrument: data.kodeInstrument,
                    namaPerusahaan: data.namaPerusahaan,
                    nilaiPerLembarSaham: data.nilaiPerLembarSaham,
                    nilaiPerusahaan: data.nilaiPerusahaan,
                    noPengajuan: data.noPengajuan,
                    nominalPenyertaan: data.nominalPenyertaan,
                    // orderRequestId: data.orderRequestId,
                    prosentasePenyertaan: data.prosentasePenyertaan,
                    rekBankId: data.rekBankId,
                    subCategory: data.subCategory,
                    totalLembarSaham: data.totalLembarSaham,
                })

                this.hide()
            } else if (validateBatasan[0].potensiselisih < 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")
            } else {
                await alert("Terdapat Kesalahan Data", "Informasi")
            }
        } else {
            await alert("Batasan Resiko Belum di Setting", "Informasi")
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Penempatan Penyertaan Langsung`}
                width={'70vw'}
                height="70vh"

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'form'}
                                formData={this.state.data}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={this.props.type == 'view' ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalInstrumentPl