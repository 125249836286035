import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { formatUploadFileData } from 'plugin/helper'
import { currency, instrumentProperti, kjpp, productDefault } from 'dataSource/lookup'
import ScrollView from 'devextreme-react/scroll-view'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import httpRequest from 'plugin/httprequest'

class ModalActionValuasProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.instrumentData = []

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'tanggalPerolehan',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Perolehan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    readOnly: true
                }
            },
            {
                dataField: 'bookValue',
                label: {
                    alignment: 'left',
                    text: 'Nilai Buku'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true
                }
            },
            {
                dataField: 'nilaiWajar',
                label: {
                    alignment: 'left',
                    text: 'Nilai Valuasi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var data = this.state.dataMaster
                        this.formRef.current.instance.updateData({spi: e.value - data.bookValue})
                    }
                }
            },
            {
                dataField: 'spi',
                label: {
                    alignment: 'left',
                    text: 'SPI'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true,
                }
            },
        ]
    }
    show = async (data) => {
        this.setState({
            popupVisible: true,
            dataMaster: data
        });
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }
    submitData = () => {
        var data = this.state.dataMaster
        this.props.submitDetailValuasi({
            id: data.id,
            nilaiWajar: data.nilaiWajar,
            spi: data.spi,
        })
        this.hide()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Valuasi Aset'}
                width={600}
                height={400}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalActionValuasProperti