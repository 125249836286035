import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { entityType } from 'dataSource/lookup';


class FormEntity extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.datagridRef = React.createRef()
        this.modalFormRef = React.createRef()
        this.formRef = React.createRef()

        this.newData = true

        this.entitiName = ""

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.updateData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'type',
                label:{
                    text: 'Type',
                },
                validationRules : [
                    {
                        type: "required",
                    }
                ],
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: entityType(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    onValueChanged: async (e) => {
                        if(e.value){
                            var dataPihakKetiga = []
                            var data = []
                            var displayExpr = ''
                            switch (e.value) {
                                case 'SP':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `suppliers?size=9999`, 'GET')
                                    displayExpr = 'namaSupplier'
                                    break;
                                case 'KR':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans?size=9999`, 'GET')
                                    displayExpr = 'fullName'
                                    break;
                                case 'MI':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `manager-investasis?size=9999`, 'GET')
                                    displayExpr = 'miName'
                                    break;
                                case 'BR':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `brokers?size=9999`, 'GET')
                                    displayExpr = 'brokerName'
                                    break;
                                case 'AP':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `aperds?size=9999`, 'GET')
                                    displayExpr = 'aperdName'
                                    break;
                                case 'BC':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `custodies?size=9999`, 'GET')
                                    displayExpr = 'custodyName'
                                    break;
                                case 'BN':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `banks?size=9999`, 'GET')
                                    displayExpr = 'bankName'
                                    break;
                                case 'PK':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `participants`, 'GET')
                                    displayExpr = 'participantName'
                                    break;
                                case 'PM':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `lookup-pesertas/getByLookupByStatusPeserta/4?size=99999`, 'GET')
                                    displayExpr = 'fullName'
                                    break;
                                case 'PA':
                                    data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpas`, 'GET')
                                    displayExpr = 'kontraktorName'
                                    break;
                                case 'P3':
                                    let data1 = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpds`, 'GET')
                                    let data2 = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpas`, 'GET')
                                    for(let val of data1) {
                                        dataPihakKetiga.push({
                                            pihakName: val.kpdName,
                                            id: val.id
                                        })
                                    }
                                    for(let val of data2) {
                                        dataPihakKetiga.push({
                                            pihakName: val.kontraktorName,
                                            id: val.id
                                        })
                                    }
                                    displayExpr = 'pihakName'
                                    break;
                            
                                default:
                                    break;
                            }

                            if(e.value === 'P3') {
                                this.formRef.current.instance.getEditor('entityName').option({dataSource: dataPihakKetiga,displayExpr:displayExpr,valueExpr:displayExpr})                                
                            } else {
                                this.formRef.current.instance.getEditor('entityName').option({dataSource: data,displayExpr:displayExpr,valueExpr:displayExpr})                                
                            }
                        }
                    }
                }
            },
            {
                dataField: 'entityName',
                label:{
                    text: 'Entity Name',
                },
                editorType:"dxSelectBox",
                editorOptions:{
                    dataSource: [],
                    valueExpr: '',
                    displayExpr: '',
                    searchEnabled:true,
                    deferRendering:false,
                    onSelectionChanged:(e) => {
                        if (e.selectedItem) {
                            this.formRef.current.instance.updateData({reff:e.selectedItem.id})
                        }
                    }
                },
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
            {
                dataField: 'reff',
                label:{
                    text: 'Reff',
                },
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
        ]
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            dataMaster:data
        })
    }

    show() {
        this.setState({
            popupVisible: true,
        });

    }

    hide() {
        this.newData = true
        this.setState({
            popupVisible: false,
            dataMaster:{}
        })
    }

    submitData = async () => {
        try {
            let type = 'success'
            let text = 'Sukses input !'
            var insertAPI = `staticdata-wf/entitis`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, insertAPI, 'POST', {
                values: this.state.dataMaster,
            })
            if (response) {
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.hide()
            } else {
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            console.log(e)
        }
    }
    
    updateData = async () => {
        try {
            let type = 'success'
            let text = 'Sukses ubah !'
            var updateAPI = `staticdata-wf/entitis`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, updateAPI, 'PUT', {
                values: this.state.dataMaster,
                key: this.state.dataMaster.id
            }, this.state.dataMaster)

            if (response) {
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.hide()
            } else {
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            console.log(e)
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'500'}
                    height="400"
                    title={`Entity`}
                    ref={this.PopupRef}
                    toolbarItems={this.newData ? this.popupToolbarItem : this.popupToolbarItem2}
                >
                    <Form
                        ref={this.formRef}
                        id={'formDataMaster'}
                        formData={this.state.dataMaster}
                        items={this.dataMaster}
                        labelLocation={"left"}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormEntity