import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { months, product,participants } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import {monthDiff} from 'plugin/helper';
import notify from 'devextreme/ui/notify'
import { showLoading } from 'redux/actions/loading';
import { alert } from 'devextreme/ui/dialog';

class ModalGenerateProsesIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                bulan : new Date().getMonth() + 1,
                tahun : new Date().getFullYear(),
                tahap: 1
            },
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Submit',
                    // onClick: this.submitData
                    onClick: () => {
                        let formValidation = this.validate();
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataMaster = [
            {
                itemType: 'group',
                caption: 'Periode',
                name: 'periode',
                items: [
                    {
                        dataField: 'bulan',
                        label: {
                            alignment: 'left',
                        text: 'Bulan'
                        },
                        editorType: 'dxSelectBox',
                        validationRules : [
                            {
                                type: "required",
                                message: "Bulan tidak boleh kosong"
                            }
                        ],
                        editorOptions: {
                            dataSource: months(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            // readOnly: true
                        },
                    },
                    {
                        dataField: 'tahun',
                        label: {
                            alignment: 'left',
                            text: 'Tahun'
                        },
                        editorType: 'dxNumberBox',
                        validationRules : [
                            {
                                type: "required",
                                message: "Tahun tidak boleh kosong"
                            }
                        ],
                        editorOptions: {
                            // readOnly: true
                        }
                    },
                    {
                        dataField: 'tahap',
                        label: {
                            alignment: 'left',
                            text: 'Tahap'
                        },
                        editorType: 'dxNumberBox',
                        validationRules : [
                            {
                                type: "required",
                                message: "Tahap tidak boleh kosong"
                            }
                        ],
                    },
                    {
                        dataField: 'participantId',
                        label: {
                            alignment: 'left',
                        text: 'Pemberi Kerja'
                        },
                        editorType: 'dxSelectBox',
                        validationRules: [
                            {
                              type: 'required',
                              message: 'Pemberi kerja tidak boleh kosong!'
                            }
                        ],
                        editorOptions: {
                            dataSource: participants(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'participantName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                        text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        validationRules: [
                            {
                              type: 'required',
                              message: 'Product tidak boleh kosong!'
                            }
                        ],
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'productCode', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'tglTransaksi',
                        label: {
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        validationRules: [
                            {
                              type: 'required',
                              message: 'Tangga Transaksi tidak boleh kosong!'
                            }
                        ],
                        editorOptions:{
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                    },
                    {
                        dataField: 'tglPembayaran',
                        label: {
                            text: 'Bulan Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        validationRules : [
                            {
                                type: "required",
                                message: "Bulan pembayaran tidak boleh kosong"
                            }
                        ],
                        editorOptions:{
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            width: '100%',
                            placeholder: 'MMM yyyy',
                            displayFormat: 'MMM yyyy',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            }
                        },
                    },
                ]
            },
            
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
      }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {
                bulan : new Date().getMonth() + 1,
                tahun : new Date().getFullYear(),
                tahap: 1
            },
            typeData: {}
        });
    }

    submitData = async() => {
        let store = this.props.store
        var data = this.state.dataMaster
        console.log(data);
        var dateTglPembayaran = new Date(data.tglPembayaran).getDate()
        var dateTglTransaksi = new Date(data.tglTransaksi).getDate()
        if(data.tglPembayaran < data.tglTransaksi){
            notify({ message: 'Tanggal pembayaran harus melebihi tanggal transaksi!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }else{
            var checkMonth = monthDiff(data.tglPembayaran,data.tglTransaksi)
            if(checkMonth > 1){
                notify({ message: 'Max tanggal pembayaran harus 1 bulan dari tanggal transaksi!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }else{
                if(checkMonth == 1 && dateTglPembayaran > dateTglTransaksi){
                    notify({ message: 'Max tanggal pembayaran harus 1 bulan dari tanggal transaksi!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
                }else{
                    store.dispatch(showLoading(true))
                    try {
                        var loadAPI = 'pesertas/generateIuran'
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                            values:data
                        })
                        if(response){
                            var interval = setInterval(async() => { 
                                try {
                                    var loadAPI = `iuran-details/getByIuranId/${response.id || response.iuranId}`
                                    var getIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')  
                                    if(getIuran.length !== 0){
                                        clearInterval(interval)
                                        store.dispatch(showLoading(false))
                                        var message = 'Data Berhasil Di Generate!'
                                        var res = await alert(message, 'Berhasil!')
                                        if(res){
                                            this.props.forceRefresh()
                                            this.hide()
                                        }
                                    }
                                } catch (e) {
                                    console.log(e)
                                }
            
                             }, 5000);
                        }
                    } catch (e) {
                        console.log(e)
                        notify({ message: 'Data Periode ini kosong atau telah di proses!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
                        store.dispatch(showLoading(false))
                    }
                }
            }
        }        

        // if(data.tahun == yearNow && data.bulan == monthNow){
        //     await this.generate(data)
        // }else{
            // notify({ message: 'Data Periode tidak boleh lebih dan kurang bulan sekarang!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        // }
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    retrieveData = async(viewData, data = null) => {   
        this.setState({
            dataMaster: data,
            viewData : viewData
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Proses Generate Penerimaan Iuran'} 
                width={500}
                height={400}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formDataMaster'}
                    formData={this.state.dataMaster}
                    items={this.dataMaster}
                    scrollingEnabled={true}
                    labelLocation = 'left'
                />
            </Popup>
        );
    }
}


export default ModalGenerateProsesIuran