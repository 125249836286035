import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalProgramDapenPengajuanPensiun from 'components/pages/modal/sdm-umum/kepegawaian/pensiun-iuran/pengajuan-pensiun/edit';
import { statusPembayaranRekapGaji } from 'dataSource/lookup';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';

class ProgramDapenPensiun extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        onClick : (e) => {
                            this.showModalProgramDapenPensiun(e)
                        }
                    },
                ],
            },
            {
                dataField:'nik',
                caption:'NIK'
            },
            {
                dataField:'fullName',
                caption:'Nama Karyawan'
            },
            {
                dataField:'phkType',
                caption:'Jenis Pemberhentian'
            },
            // {
            //     dataField:'besaranPesangon',
            //     caption:'Besaran Pesangon',
            //     alignment:'center',
            //     columns:[
            //         {
            //             dataField:'gaji',
            //             caption:'Gaji (%)'
            //         },
            //         {
            //             dataField:'nominal',
            //             caption:'Nominal'
            //         },
            //     ]
            // },
            {
                dataField:'pesangonNominal',
                caption:'Total Nominal Pesangon',
                dataType:'number',
                format:'#,##0.00'
            },
            {
                dataField:'tanggalPengajuan',
                caption:'Tanggal Pengajuan',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'tanggalEfektif',
                caption:'Tanggal Efektif',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'pesangonPaydate',
                caption:'Tanggal Pembayaran Pesangon',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'pensiunType',
                caption:'Jenis Pensiun Dapen'
            },
            {
                dataField:'statusId',
                caption:'Status',
                lookup:{
                    dataSource: statusPembayaranRekapGaji(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                }
            },
        ]

        this.showModalProgramDapenPensiunRef = React.createRef()

    }
    
    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalProgramDapenPensiun = (e) => {
        if (e.row) {
            this.showModalProgramDapenPensiunRef.current.retrieveData(e.row.data)
        }
        this.showModalProgramDapenPensiunRef.current.show()
    }   

    render() {
        return (
            <div className="container-fluid">
            <h3 className="main-title">Daftar Pensiun</h3>
            <DevExpressDataGrid
                ref = {this.DataGridPengajuanRef}
                loadAPI='view-regist-pensiun-lists?statusId.in=2'
                insertAPI='regist-pengkinian-pengajuan-aktifs'
                updateAPI='regist-pengkinian-pengajuan-aktifs'
                deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                backendserver={process.env.REACT_APP_BACKEND_HRD}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={true}
                exportFileName={"Pensiun"}
                allowExportSelectedData={true}
                selection={"multiple"}
                
                showBorders={true}

                paging={true}
                defaultPageSize={10}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Tipe Komponen'}
                popupWidth={450} //masukan dalam ukuran pixel
                popupHeight={330} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={1} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing = {this.onToolbarPreparing}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
            <FormModalProgramDapenPengajuanPensiun 
                    ref={this.showModalProgramDapenPensiunRef}
                    store={this.props.store}
                    action={'lihat'}
                    forceRefresh={this.forceRefresh}
                />
        </div>
        )
    }
}

export default ProgramDapenPensiun