import React, { Component } from 'react'
import {Popup , ScrollView, FileUploader} from 'devextreme-react'
import Form from 'devextreme-react/form'

import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import {  lemburBiaya, karyawan, jabatan, tipeLembur, sdmUnit, months, programs, tunjanganHrd, jabatanGolongan } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatTime, formatUploadFileData, imageSource } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormTambahTanggalLembur from './formTanggal';
import { showLoading } from 'redux/actions/loading';

class FormPengajuanLembur extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            popupVisible:false,
            DataPengajuan: {
                tunjanganTypeId:19
            },
            DataDocument:{},
            uploadedData:[],
            newData:true
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.date1 = ''
        this.date2 = new Date()
        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.fileUploderRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.showModalAddTanggalRef = React.createRef()
        this.showModalEditTanggalRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.readOnly = false
        this.lemburId = ''
        this.getGapok = 0
        this.karyawanId = 0
        this.dataLemburDetail = []

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem3 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.formPengajuanLembur = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'pengajuanNo',
                        label: {
                            text: 'No Pengajuan',
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField:'programId',
                        label:{
                            alignment:'left',
                            text:'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            }
                        },
                    },
                    {
                        dataField: 'karyawanId',
                        label: {
                            text: 'Nama Karyawan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr:'fullName',
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: async(e) => {
                                if (e.selectedItem) {
                                    var data = e.selectedItem
                                    var getJabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `jabatans/${data.jabatanId}`, 'GET')
                                    this.getGapok = e.selectedItem.gajiPokok
                                    this.karyawanId = e.selectedItem.id
                                    this.formRef.current.instance.updateData({nik:data.nik,unitId:data.unitId,jabatanGolonganId:getJabatan.jabatanGolonganId})
                                }
                            }
                        }
                    },
                    {
                        dataField: 'nik',
                        label: {
                            text: 'NIK Karyawan'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField:'jabatanGolonganId',
                        label:{
                            alignment:'left',
                            text:'Golongan Jabatan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: jabatanGolongan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'namaJagol', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'unitId',
                        label: {
                            text: 'Unit'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true
                        },
                    },
                    {
                        dataField:"bulan",
                        label:{
                            text:"Periode Bulan",
                            alignment:"left",
                            location:"left",
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: months(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'tahun',
                        label: {
                            text: 'Periode Tahun'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'tglAwal',
                        label: {
                            text: 'Tanggal Awal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick:true,
                            useMaskBehavior:true,
                        }
                    },
                    {
                        dataField: 'tglAkhir',
                        label: {
                            text: 'Tanggal Akhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick:true,
                            useMaskBehavior:true,
                        }
                    },
                    {
                        dataField:"tunjanganTypeId",
                        label:{
                            text:"Tunjangan",
                            alignment:"left",
                            location:"left",
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: tunjanganHrd(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: function(item){
                                return item && item.tunjanganCode + " - " + item.tunjanganName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'tglBayar',
                        label: {
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick:true,
                            useMaskBehavior:true,
                        }
                    },
                ]
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        onClick : (e) => {
                            this.showModalEditTanggal(e)
                        },
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        onClick : (e) => {
                            this.removeDataTanggal(e.row.data)
                        },
                    },
                ],
            },
            {
                dataField: 'tglLembur',
                caption: 'Tanggal',
                type : 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'lemburType',
                caption: 'Type',
                lookup:{
                    dataSource: tipeLembur(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'lemburName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'tarifLembur',
                caption: 'Tarif Lembur',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0',
            },
            {
                dataField:"jamDari",
                caption:"Jam Awal",
                format:"HH:mm",
                dataType:"datetime",
                // editorType:"dxDateBox",
                // editorOptions:{
                //     type: "time",
                //     openOnFieldClick:true,
                //     useMaskBehavior:true,
                //     pickerType:"rollers",
                //     displayFormat: 'HH:mm',
                //     dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
                // }
            },
            {
                dataField:"jamSampai",
                caption:"Jam Akhir",
                format:"HH:mm",
                dataType:"datetime",
                // editorType:"dxDateBox",
                // editorOptions:{
                //     type: "time",
                //     openOnFieldClick:true,
                //     useMaskBehavior:true,
                //     pickerType:"rollers",
                //     displayFormat: 'HH:mm',
                //     dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
                // }
            },
            {
                dataField: 'dasarPerhitungan',
                caption: 'Dasar Perhitungan',
                lookup: {
                    dataSource: lemburBiaya(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'dasarPerhitungan',
                }
            },
            {
                dataField: 'jamJumlah',
                caption: 'Durasi Lembur(Jam)',
                dataType: 'number',
            },
            {
                dataField: 'uangLembur',
                caption: 'Uang Lembur',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0',
            },
            {
                dataField: 'aktivitas',
                caption: 'Aktifitas',
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
        ]

        this.columns2 = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Lihat',
                        hint : 'Lihat',
                        onClick : (e) => {
                            this.showModalLihatTanggal(e)
                        },
                    },
                ],
            },
            {
                dataField: 'tglLembur',
                caption: 'Tanggal',
                type : 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'lemburType',
                caption: 'Type',
                lookup:{
                    dataSource: tipeLembur(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'lemburName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'tarifLembur',
                caption: 'Tarif Lembur',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0',
            },
            {
                dataField:"jamDari",
                caption:"Jam Awal",
                format:"HH:mm",
                dataType:"datetime",
                // editorType:"dxDateBox",
                // editorOptions:{
                //     type: "time",
                //     openOnFieldClick:true,
                //     useMaskBehavior:true,
                //     pickerType:"rollers",
                //     displayFormat: 'HH:mm',
                //     dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
                // }
            },
            {
                dataField:"jamSampai",
                caption:"Jam Akhir",
                format:"HH:mm",
                dataType:"datetime",
                // editorType:"dxDateBox",
                // editorOptions:{
                //     type: "time",
                //     openOnFieldClick:true,
                //     useMaskBehavior:true,
                //     pickerType:"rollers",
                //     displayFormat: 'HH:mm',
                //     dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ"
                // }
            },
            {
                dataField: 'dasarPerhitungan',
                caption: 'Dasar Perhitungan',
                lookup: {
                    dataSource: lemburBiaya(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'dasarPerhitungan',
                }
            },
            {
                dataField: 'jamJumlah',
                caption: 'Durasi Lembur(Jam)',
                dataType: 'number',
            },
            {
                dataField: 'uangLembur',
                caption: 'Uang Lembur',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0',
            },
            {
                dataField: 'aktivitas',
                caption: 'Aktifitas',
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.dataGridRef.current.emptyDeletedIndex()
        this.readOnly = false
        this.lemburId = ''
        this.getGapok = 0
        this.karyawanId = 0
        this.dataLemburDetail = []
        this.setState({
            popupVisible:false,
            DataPengajuan: {
                tunjanganTypeId:19
            },
            DataDocument:{},
            uploadedData:[],
            newData:true
        })
        this.dataGridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text:'Tambah Tanggal',
                    onClick: (e) => {
                        this.showModalAddTanggal(e)
                    },
                    disabled:this.readOnly
                }
            }
        )
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;
            this.setState({
                DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    }

    showModalAddTanggal = (e) => {
        this.showModalAddTanggalRef.current.show()
        this.showModalAddTanggalRef.current.getDataGapok(this.getGapok)
        this.showModalAddTanggalRef.current.getDataKaryawanId(this.karyawanId)
        // this.showModalAddTanggalRef.current.getJabatanGolId(this.state.DataPengajuan.jabatanGolonganId)
    }
    showModalEditTanggal = (e) => {
        this.showModalEditTanggalRef.current.retrieveData(e.row.data)
        this.showModalEditTanggalRef.current.show()
        this.showModalEditTanggalRef.current.getDataGapok(this.getGapok)
        this.showModalEditTanggalRef.current.getDataKaryawanId(this.karyawanId)
        // this.showModalEditTanggalRef.current.getJabatanGolId(this.state.DataPengajuan.jabatanGolonganId)
    }

    showModalLihatTanggal = (e) => {
        this.showModalAddTanggalRef.current.retrieveData(e.row.data)
        this.showModalAddTanggalRef.current.show()
        this.showModalAddTanggalRef.current.getDataGapok(this.getGapok)
        this.showModalAddTanggalRef.current.getDataKaryawanId(this.karyawanId)
        // this.showModalAddTanggalRef.current.getJabatanGolId(this.state.DataPengajuan.jabatanGolonganId)
        this.showModalAddTanggalRef.current.newData(false)
    }

    generateNoPengajuan = async () => {
        var generateNo = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `generate-no-automatic/KaryawanLembur/pengajuanNo/LEMBUR`,
            'POST',{values:{}}
        )

        this.formRef.current.instance.updateData('pengajuanNo', generateNo)
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`],state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans?size=9999`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        var filteredData = getKaryawan.filter(val => val.programId == programId)
        let dataSource = new ArrayStore({
            data: filteredData,
            key: 'id'
        })
        
        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }

    submitData = async() => {
        try {
            var dataLemburDetail = this.dataGridRef.current.getDataSource()
            if (this.props.action == 'add') {
                for(var lemburDetail of dataLemburDetail){
                    await this.addDataLemburDetail(lemburDetail,this.lemburId)
                }
                this.submitMasterData(this.dataLemburDetail)
            }

            if (this.props.action == 'edit') {
                for(var lemburDetail of dataLemburDetail){
                    await this.editDataLemburDetail()
                }
                this.editMasterData(this.dataLemburDetail)
            }

            
        //     var getDeletedLemburDetail = this.dataGridRef.current.getDeletedIndex()
        //     for(var dataDeleteld of getDeletedLemburDetail){
        //         await this.deleteDataLemburDetail(dataDeleteld)
        //     }
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    addDataLemburDetail = async() => {
        var dataLemburDetail = this.dataGridRef.current.getDataSource()
        var lemburDetail = []
        for(var lemburDtl of dataLemburDetail){
            if (typeof lemburDtl.id === 'string') {
                lemburDtl.id = null
            }
            lemburDtl.tglLembur = formatDate(lemburDtl.tglLembur)
            lemburDetail.push(lemburDtl)
        }
        
        return this.dataLemburDetail = lemburDetail
    }

    submitMasterData = async (lemburDetail) => {
        this.props.store.dispatch(showLoading(true))
        var loadAPI = `send-pengajuan-karyawan-lembur`
        var data = {...this.state.DataPengajuan,...this.state.DataDocument}
        data.tglAkhir = formatDate(data.tglAkhir)
        data.tglAwal = formatDate(data.tglAwal)
        data.tglBayar = formatDate(data.tglBayar)
        data.hari = lemburDetail.length
        data.karyawanLemburId = 0
        data.pengajuanKaryawanLemburDTOList = lemburDetail
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: data,
        })

        if (response) {
            notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            this.hide()
            this.props.forceRefresh()
            this.props.store.dispatch(showLoading(false))
        }
    } 

    editDataLemburDetail = async() => {
        var dataLemburDetail = this.dataGridRef.current.getDataSource()
        var lemburDetail = []
        for(var lemburDtl of dataLemburDetail){
            if (typeof lemburDtl.id === 'string') {
                lemburDtl.id = null
            }
            lemburDtl.tglLembur = formatDate(lemburDtl.tglLembur)
            lemburDetail.push(lemburDtl)
        }

        return this.dataLemburDetail = lemburDetail
    }

    editMasterData = async(lemburDetail) => {
        this.props.store.dispatch(showLoading(true))
        var loadAPI = `update-pengajuan-karyawan-lembur`
        var data = {...this.state.DataPengajuan,...this.state.DataDocument}
        data.id = data.id
        data.tglAkhir = formatDate(data.tglAkhir)
        data.tglAwal = formatDate(data.tglAwal)
        data.tglBayar = formatDate(data.tglBayar)
        data.hari = lemburDetail.length
        data.karyawanLemburId = this.lemburId
        data.pengajuanKaryawanLemburDTOList = lemburDetail
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: data,
        })

        if (response) {
            notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            this.hide()
            this.props.forceRefresh()
            this.props.store.dispatch(showLoading(false))
        }
    }

    deleteDataLemburDetail = async(data) => {
        var loadAPI = `karyawan-lemburs`

        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE', data)
    }

    updateData = async() => {
        try {
            console.log(this.lemburId);
        } catch (e) {
            
        }
        
    }

    newData = (newData) => {
        this.setState({
          newData : newData
        })
    }
 
    getDataLemburDetailId = (data) => {
        this.lemburId = data

        this.dataGridRef.current.forceRefresh(true)
    }

    retrieveData = async (data, readOnly) => {
        this.readOnly = readOnly

        data.tglAwal = data.tanggalAwal
        data.tglAkhir = data.tanggalAkhir
        data.tglBayar = data.paymentDate
        
        this.setState({
            DataPengajuan: data,
            DataDocument: data
        })
    }
    
    loadDataTglLembur = async () => {
        if (this.lemburId == '') {
            var response = []
        }else{
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-lembur-details/findAllByKaryawanLemburId/${this.lemburId}`, 'GET')
            
            if (response.length > 0) {
                response.forEach((value, i) => {
                    response[i].tglLembur = new Date(value.tanggal)
                    response[i].lemburType = value.lemburTypeId
                    response[i].aktivitas = value.aktifitas
                })
                return response
            }else{
                return []
            }
        }
        
        return response
    }

    getDataSource = (data) => {
        try{
            var key = data.id

            if(key){
                this.dataGridRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Detail Lembur", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.dataGridRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Detail Lembur", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    removeDataTanggal = (data) => {
        this.dataGridRef.current.push([
            {
                type: 'remove',
                key : data.id
            }
        ])
        notify({ message: "Sukses Delete Detail Lembur", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Form Pengajuan Lembur"}
                width={"40vw"}
                height={"80vh"}
                toolbarItems={this.props.action == 'add' ? this.PopupToolbarItem :  this.readOnly ? this.PopupToolbarItem3 : this.PopupToolbarItem2}
                >
                <ScrollView height="100%" width="100%">
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formPengajuanLembur'}
                        formData={this.state.DataPengajuan}
                        items={this.formPengajuanLembur}
                        labelLocation = 'left'
                        readOnly={this.readOnly}
                    />
                    <FileUploader
                        disabled={this.readOnly}
                        accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                        uploadMode={"useForm"}
                        onValueChanged={this.onUploadStarted.bind(this)}
                        labelText="Upload File"
                        showFileList={false}
                        name={"dokumen"}
                        value={this.state.uploadedData}
                        ref={this.fileUploderRef}   
                    />
                    <div className="row">
                        <label className="col-2">File Name:</label>
                        <label className="col-8">{ this.state.DataDocument.dokumenName}</label>
                    </div>
                    <div className="col-md-6 text-right">
                        <button onClick={() => this.previewFile(this.state.DataDocument,'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                            Preview
                        </button>
                    </div>
                    <div className="container-fluid">
                        <DevExpressDataGrid
                            ref = {this.dataGridRef}
                            loadAPI='daftar-pinjaman-karyawans'
                            insertAPI='daftar-pinjaman-karyawans'
                            updateAPI='daftar-pinjaman-karyawans'
                            deleteAPI='daftar-pinjaman-karyawans' 

                            backendserver={process.env.REACT_APP_BACKEND_CORE}
                            useArraySource={true}
                            ArraySourceData={this.loadDataTglLembur}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={true} 
                            exportFileName={"Daftar Tunjangan Karyawan"}
                            allowExportSelectedData={true}
                            selection={"multiple"}
                            
                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}
                            height={'calc(50vh - 200px)'}
                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Daftar Tunjangan Karyawan'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.state.newData ? this.columns : this.columns2} // taruh konfigurasi kolom disini
                            onToolbarPreparing = {this.onToolbarPreparing}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
                <FormTambahTanggalLembur
                    ref={this.showModalAddTanggalRef}
                    store={this.props.store}
                    getDataSource={this.getDataSource}
                    action={'add'}
                />
                <FormTambahTanggalLembur
                    ref={this.showModalEditTanggalRef}
                    store={this.props.store}
                    getDataSource={this.getDataSource}
                    action={'edit'}
                />
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormPengajuanLembur