import React, { Component } from 'react'
import { Form, ProgressBar } from "devextreme-react";
import httpRequest from 'plugin/httprequest';
import { showLoading } from "redux/actions/loading";
import { getSystemDate, formatDate } from 'plugin/helper';

class MigrateJournal extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {
                startDate : getSystemDate(props.store),
                endDate : getSystemDate(props.store),
            },
            currentCount: 0,
            recordCount: 0,
        }

        this.filterItem = [
            {
              dataField: "startDate",
              label: {
                text: "Mulai",
                alignment: "left",
                location: "left"
              },
              editorType: "dxDateBox",
              editorOptions: {
                useMaskBehavior: true,
                width: '100%',
                displayFormat: 'dd MMM yyyy',
                openOnFieldClick: true
              }
            },
            {
              dataField: "endDate",
              label: {
                text: "Sampai",
                alignment: "left",
                location: "left"
              },
              editorType: "dxDateBox",
              editorOptions: {
                useMaskBehavior: true,
                width: '100%',
                displayFormat: 'dd MMM yyyy',
                openOnFieldClick: true
              }
            },
            {
            itemType: "button",
              buttonOptions: {
                text: "Proses",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: () => {
                    this.process()
                },
              },
              horizontalAlignment: "left"
            },
          ]
    }

    process = async () => {
        const {startDate,endDate} = this.state.formFilter
        const diffTime = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24)
        this.setState({
            recordCount: diffTime
        })
        var currentDate = new Date(startDate)
        
        //generate base coa position
        const products = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store,`products`)
        const coas = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coas`)
        const baseCCY = this.props.store.getState().getParam.find(value => value.paramCode === '9 BASECCY')

        const coaPosition = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-positions`)

        if(coaPosition.length === 0){
            for(const product of products){
                for(const coa of coas){
                    const resultInsertCoaPosition = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,'coa-positions','POST',{
                        values: {
                            "balanceAmount": 0,
                            "balanceAmountBaseCurrency": 0,
                            "coaId": coa.id,
                            "currencyId": baseCCY.intValue,
                            "produkId": product.id
                        }
                    })
                }
            }
        }


        for(let i=0;i<=diffTime;i++){
            const journalData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-headers/getAllByValueDateBetweenAndStatus/${formatDate(currentDate)}/${formatDate(currentDate)}/O`)

            for(const journal of journalData){
                await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store, `journal-headers/post/${journal.id}`)
            }

            await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-positions/dailyBalanceProcess/${formatDate(currentDate)}`)
            this.setState({
                currentCount: i
            })
            currentDate.setDate(currentDate.getDate() + 1)
        }
    }

    statusFormat = (value) => {
        return `Memproses Data : ${this.state.currentCount} / ${this.state.recordCount} (${Math.round(value * 10000)/100}%)`
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Migrasi Jurnal</h2>
                <Form
                colCount={3}
                id={'formFilter'}
                formData={this.state.formFilter}
                items={this.filterItem}
                />
                <ProgressBar
                    min = {0}
                    max = {this.state.recordCount}
                    statusFormat = {this.statusFormat}
                    value = {this.state.currentCount}
                />
            </div>
        )
    }
}

export default MigrateJournal 