import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalKaryawanPotongan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/potongan/form';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import uuidv4  from 'uuid/v4'

class KaryawanPotongan extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalKaryawanPotongan(e)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            console.log('file')
                        }
                    }
                ]
            },
            {
                dataField: 'id',
                caption: "No.",
                cellRender: (data) => {
                  return data.rowIndex + 1
                },
            },
            {
                dataField: 'nik',
                caption: "NIK",
            },
            {
                dataField: 'namaKaryawan',
                caption: "Nama Karyawan",
            },
            {
                dataField: 'jabatan',
                caption: "Jabatan",
            },
            {
                dataField: 'noSk',
                caption: "No SK",
            },
            {
                dataField: 'tanggalSk',
                caption: "Tanggal SK",
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField: 'tanggalBerlaku',
                caption: "Tanggal Berlaku",
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField: 'itemPotongan',
                caption: "Item Potongan",
            },
        ]

        this.dataGridRef = React.createRef()
        this.showModalKaryawanPotonganRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalKaryawanPotongan(e)
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    showModalKaryawanPotongan = (e) => {
        if(e.row){
            this.showModalKaryawanPotonganRef.current.retrieveData(e.row.data)
        }
        this.showModalKaryawanPotonganRef.current.show()
    }

    loadDummyData = () => {
        var data = [
            {
                id: uuidv4(),
                nik:1235123141,
                namaKaryawan:"Sutami",
                jabatan:'Manager',
                noSk:'ADDS/2002/212',
                tanggalSk:new Date(),
                tanggalBerlaku:new Date(),
                itemPotongan:''
            }
        ]

        return data
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Potongan Karyawan</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='daftar-pinjaman-karyawans'
                    insertAPI='daftar-pinjaman-karyawans'
                    updateAPI='daftar-pinjaman-karyawans'
                    deleteAPI='daftar-pinjaman-karyawans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    useArraySource={true}
                    ArraySourceData={this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Potongan Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Potongan Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalKaryawanPotongan 
                    ref={this.showModalKaryawanPotonganRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default KaryawanPotongan