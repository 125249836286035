import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import {  tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import PPListTransaksiDetailModal from './actionDetail';
import httpRequest from 'plugin/httprequest';

class PPListTransaksiModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.readOnly = ''
        this.PopupRef = React.createRef()
        this.DataGridDetailRef = React.createRef()
        this.detailBiayaRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        
        this.detailColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Lihat',
                        hint : 'Lihat',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalDetail(e)
                        }
                    }
                ],
            },
            {
                dataField:"transactioNo",
                caption:"No. Transaksi",
            },
            {
                dataField: 'tipeTransaksi',
                caption: 'Tipe Transaksi',
                // lookup:{
                //     dataSource: tipeTransaksiSdmUmum(this.props.store),
                //     valueExpr: 'id', // contains the same values as the "statusId" field provides
                //     displayExpr: 'transactionTypeName' 
                // }
            },
            {
                dataField: 'subTipeTransaksi',
                caption: 'Sub Tipe Transaksi',
                // lookup:{
                //     dataSource: tipeSubTransaksiSdmUmum(this.props.store),
                //     valueExpr: 'id', // contains the same values as the "statusId" field provides
                //     displayExpr: 'tipeSubName' 
                // }
            },
            {
                dataField:"keterangan",
                caption:"Keterangan",
            },
            {
                dataField:"nominalTransaction",
                caption:"Nominal Transaksi",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }

    retrieveData = (data) => {
        this.setState({
            dataMaster: data,
        })

        this.DataGridDetailRef.current.forceRefresh(true)
    }

    showModalDetail = (e) => {
        if (e.row) {
            this.detailBiayaRef.current.retrieveData(e.row.data)
        }
        this.detailBiayaRef.current.show()
    }

    loadDataTransaksi = async() => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-aktifs-transaction-list/${this.state.dataMaster.id || 0}`, 'GET')
        return response
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Transaksi Pembayaran Umum'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <DevExpressDataGrid 
                                ref = {this.DataGridDetailRef}
                                loadAPI = 'pembayaran-details'
                                insertAPI = 'pembayaran-details'
                                updateAPI = 'pembayaran-details'
                                deleteAPI = 'pembayaran-details'

                                backendserver = {process.env.REACT_APP_BACKEND_HRD}

                                useArraySource={true}
                                ArraySourceData={this.loadDataTransaksi}
                                keyField={'transactionId'}
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                showBorders = {true}

                                paging = {true}
                                defaultPageSize = {10}
                                
                                editingMode={'popup'}
                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle = {'Data Detail Biaya'}
                                popupWidth = {500} //masukan dalam ukuran pixel
                                popupHeight = {400} //masukkan dalam ukuran pixel

                                popupFormLabelLocation = 'left' //accepted value = top, left, right
                                popupFormMinColWidth = {300} // minimum lebar kolom
                                popupFormColCount = {1} //jumlah kolom pada form
                                height={400}
                                //akhir bagian konfigurasi popup

                                ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

                                FilterRow = {true} // set false untuk mematikan fitur filter
                                
                                ColumnConfiguration={this.detailColumn} // taruh konfigurasi kolom disini
                                // onToolbarPreparing = {this.onToolbarPreparingDetail}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                                
                                store = {this.props.store} // jangan di edit edit
                            /> 
                        </div>
                    </div>
                </div>
                </ScrollView>
                <PPListTransaksiDetailModal
                    ref = {this.detailBiayaRef}
                    store = {this.props.store}
                />
            </Popup>
        )
    }
}

export default PPListTransaksiModal