import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { formatUploadFileData, PrintElemWithoutTitle, formatDefaultFullDate, formatUploadFileNameAndExtention, download, imageSource, pembulatan, numberToIndo, yearsDiff, formatDate, daysDiff, formatNpwp, formatNumber, formatNumberAum, formatDefaultDate } from 'plugin/helper';
import { banks, pensiunType, product, tipePembayaranMp, pesertaFamiliesAll, phkType, mpPenerimaType, rekBank, banks_core, status } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import reportRequest, { reportFile } from 'plugin/reportRequest';
import { FileUploader, ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import ModalPreviewPdf from './previewPdf';
import ModalPreviewImage from './previewImage';
import ModalNoSuratCetak from '../pesertaDapen/pasif/registrasi/cetak';

import { confirmAlert } from 'react-confirm-alert';
import { showLoading } from 'redux/actions/loading';

const jenisPhk = [
    {
        id: 1,
        value: 'Meninggal'
    },
    {
        id: 2,
        value: 'Cacat'
    },
    {
        id: 3,
        value: 'Kepentingan Perusahaan'
    },
    {
        id: 4,
        value: 'Sanksi'
    },
    {
        id: 5,
        value: 'Mengundurkan Diri'
    },
    {
        id: 6,
        value: 'Pensiun Normal'
    },
    {
        id: 7,
        value: 'Promosi Dan Demosi'
    },
]
class ProsesDataPensiunPasif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataPerhitungan: {},
            dataPeserta: {}
        }

        this.username = props.store.getState().sessionUser

        this.ns = [
            {
                id: 1,
                value: 2020
            },
            {
                id: 2,
                value: 2021
            },
            {
                id: 3,
                value: 2022
            }
        ]

        this.getFaktor = []

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.modalCetakRef = React.createRef()

        this.globalParam = this.props.store.getState().getParam
        this.dataKeluargaByPesertaId = []
        this.valueFrom = 0
        this.PopupToolbarItemAktif = [
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Cetak'
                },
                toolbar: 'bottom',
                disabled: true
            },
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Pilihan',
                    onClick: async () => {
                        await this.cetak('pilihan')
                    }
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Blanko Pilihan',
                    onClick: async () => {
                        await this.cetak('blankoPilihan')
                    }
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Perhitungan',
                    onClick: async () => {
                        await this.cetak('perhitungan')
                    }
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Pembayaran',
                    onClick: async () => {
                        await this.cetak('pembayaran')
                    }
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Penetapan - 1',
                    onClick: async () => {
                        await this.cetak('penetapan1')
                    }
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'before',
                options: {
                    'text': 'Penetapan - 2',
                    onClick: async () => {
                        await this.cetak('penetapan2')
                    }
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        var formValidate = this.validate()
                        if (formValidate) {
                            if (new Date(this.state.dataMaster.pensiunDate) < new Date(this.state.dataMaster.efektifDate)) {
                                var message = 'Tanggal pensiun tidak boleh kurang dari tanggal efektif akun peserta!'
                                await alert(message, 'Validasi!')
                            } else {
                                await this.submitData(this.state.dataMaster)
                                // this.hide()
                                // this.props.removeItemDataGrid()
                                this.props.forceRefresh()
                            }
                        }
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.PopupToolbarItemRegistrasiPasif = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: async () => {
                        var formValidate = this.validate()
                        if (formValidate) {
                            if (new Date(this.state.dataMaster.pensiunDate) < new Date(this.state.dataMaster.efektifDate)) {
                                var message = 'Tanggal pensiun tidak boleh kurang dari tanggal efektif akun peserta!'
                                await alert(message, 'Validasi!')
                            } else {
                                await this.props.submitData(this.state.dataMaster)
                                // this.hide()
                                this.props.removeItemDataGrid()
                                this.props.forceRefresh()
                            }
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.PopupToolbarItemPensiunanPasif = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'master',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pensiunType',
                        label: { 
                            text: 'Jenis Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pensiunType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'statusName',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (data) => {
                                // var value = data.value
                                // var dataAkun = this.state.dataMaster
                                // var dataPeserta = this.state.dataPeserta
                                // var pensiunDateField = this.formRef.current.instance.itemOption('master.pensiunDate')
                                // // if(value == 4 || value == 5){
                                // //     dataAkun.pensiunDateOld =  dataAkun.pensiunDate
                                // //     var loadAPIGlblParam = `global-parameters/get-by-paramCode/2%20UP`
                                // //     var getUsiaPensiun = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIGlblParam, 'GET')

                                // //     var birthDate = new Date(dataPeserta.birthDate)
                                // //     var day = birthDate.getDate() + 1
                                // //     var month = birthDate.getMonth() + 1
                                // //     var year = birthDate.getFullYear()
                                // //     // var formatDay = day.toLocaleString('id-ID', {//this is the function that formats the numbers
                                // //     //     minimumIntegerDigits: 2, //change this to your minimum length
                                // //     //     useGrouping: false
                                // //     // })
                                // //     // var formatMonth = month.toLocaleString('id-ID', {//this is the function that formats the numbers
                                // //     //     minimumIntegerDigits: 2, //change this to your minimum length
                                // //     //     useGrouping: false
                                // //     // })
                                // //     var pensiunDate =  (year + getUsiaPensiun.intValue || 56) +'-'+ month +'-'+ day
                                // //     dataAkun.pensiunDate = new Date(pensiunDate)
                                // //     pensiunDateField.editorOptions.readOnly = true
                                // // }else{
                                // //     dataAkun.pensiunDate =  dataAkun.pensiunDate || dataAkun.pensiunDateOld
                                // //     pensiunDateField.editorOptions.readOnly = false
                                // // }
                                // dataAkun.pensiunDate = dataAkun.pensiunDate || dataAkun.pensiunDateOld
                                // pensiunDateField.editorOptions.readOnly = false

                                // this.formRef.current.instance.itemOption('master.pensiunDate', pensiunDateField)
                                // this.formRef.current.instance.updateData(dataAkun)

                            }
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Jenis Pensiun Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'skPensiunNo',
                        label: {
                            text: 'No SK Pensiun'
                        },
                    },
                    {
                        dataField: 'tglSkPensiun',
                        label: {
                            text: 'Tanggal SK Pensiun'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Tanggal SK Pensiun harus diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'skPemberhentianNo',
                        label: {
                            text: 'No SK PHK'
                        },
                    },
                    {
                        dataField: 'tglSkPemberhentian',
                        label: {
                            text: 'Tanggal SK PHK'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Tanggal SK PHK harus diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'pesertaPhkTypeId',
                        label: {
                            text: 'Jenis PHK'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: phkType(),
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'mpPaymentTypeId',
                        label: {
                            text: 'Pembayaran MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipePembayaranMp(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'typeName',
                            onValueChanged: (data) => {
                                // var getPenguranganPph = this.globalParam.find(val => val.paramCode == '2 Tax MPS')
                                // var value = data.value
                                // var dataAkun = this.state.dataMaster
                                // var dataPeserta = this.state.dataPeserta
                                // var formulaPph21 = dataPeserta.npwp ? 5 / 100 : 6 / 100
                                // if (getPenguranganPph) {
                                //     if (value == 1) {
                                //         if (getPenguranganPph.intValue < dataAkun.mpSekaligus20) {
                                //             dataAkun.pajakPph21MpSekaligus = (dataAkun.mpSekaligus20 - getPenguranganPph.intValue) * formulaPph21
                                //         }
                                //     }
                                //     if (value == 2) {
                                //         if (getPenguranganPph.intValue < dataAkun.mpSekaligus) {
                                //             dataAkun.pajakPph21MpSekaligus = (dataAkun.mpSekaligus - getPenguranganPph.intValue) * formulaPph21
                                //         }
                                //     }

                                //     if (dataAkun.pajakPph21MpSekaligus) {
                                //         dataAkun.pajakPph21MpSekaligusBulat = pembulatan(dataAkun.pajakPph21MpSekaligus, 2)
                                //     } else {
                                //         dataAkun.pajakPph21MpSekaligusBulat = 0
                                //     }

                                //     this.formRef.current.instance.updateData(dataAkun)
                                // }
                            }
                        },
                        // isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Pembayaran MP Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'pensiunDate',
                        name: 'pensiunDate',
                        label: {
                            text: 'Tanggal Pensiun'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            onValueChanged: async () => {
                                let dataMaster = this.state.dataMaster
                                let dataPeserta = this.state.dataPeserta
                                switch(dataMaster.pensiunType) {
                                    case 1:
                                    case 4:
                                    case 5:
                                    case 7:
                                        // let dataPesertaNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/getByNik/${dataPeserta.nik}`, 'GET');
                                        // let tangalBerhentiCalculate = new Date(dataPesertaNik.birthDate).getFullYear() + 56;
                                        // let tanggalBerhenti = `${tangalBerhentiCalculate}-${new Date(dataPesertaNik.birthDate).getMonth() + 1}-${new Date(dataPesertaNik.birthDate).getDate() + 1}`;
                                        // console.log(tanggalBerhenti);
                                        // this.formRef.current.instance.updateData({
                                        //     tanggal_berhenti: tanggalBerhenti
                                        // });

                                        let dataPesertaNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/getByNik/${dataPeserta.nik}`, 'GET');
                                        let birthDate = new Date(dataPesertaNik.birthDate);
                                        let tanggalBerhentiCalculate = new Date(birthDate.getFullYear() + 56, birthDate.getMonth(), birthDate.getDate() + 1);
                                        let tanggalBerhenti = `${tanggalBerhentiCalculate.getFullYear()}-${tanggalBerhentiCalculate.getMonth() + 1}-${tanggalBerhentiCalculate.getDate()}`;
                                        this.formRef.current.instance.updateData({
                                            tanggal_berhenti: tanggalBerhenti
                                        });
                                    break;
                                }
                            }   
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'tanggal_berhenti',
                        name: 'tanggal_berhenti',
                        label: {
                            text: 'Tanggal Berhenti'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount: 4,
                        items: [
                            {
                                dataField: 'masaKerja',
                                label: {
                                    text: 'Masa Kerja'
                                },
                                colSpan: 3,
                                editorOptions: {
                                    // readOnly: true,
                                }
                            },
                            {
                                itemType: 'button',
                                horizontalAlignment: 'left',
                                buttonOptions: {
                                    text: 'Kalkulasi',
                                    type: 'secondary',
                                    onClick: async (e) => {
                                        try {
                                            let dataMaster = this.state.dataMaster
                                            let dataPeserta = this.state.dataPeserta
                                            let data = this.state.data
                                            let loadAPI = `peserta-pensiuns/calculatePensiunByFunction/1/${dataMaster.productId}/${dataPeserta.nik}/${dataMaster.peridoeNs}/${dataMaster.pensiunType}/${formatDate(dataMaster.pensiunDate)}/${dataMaster.mpPenerimaTypeId}/${dataMaster.mpPenerimaTypeId > 1 ? dataMaster.mpPenerimaPesertaFamilyTypeId : 0}/${dataMaster.mpPaymentTypeId}/false/${formatDate(dataMaster.tanggal_berhenti)}`
                                            // let loadAPI = `peserta-pensiuns/calculatePensiunByFunction/1/${dataMaster.productId}/${dataPeserta.nik}/${dataMaster.peridoeNs}/${dataMaster.pensiunType}/${formatDate(dataMaster.pensiunDate)}/${dataMaster.mpPenerimaTypeId}/${dataMaster.mpPenerimaTypeId > 1 ? dataMaster.mpPenerimaPesertaFamilyTypeId : 0}/false/${formatDate(dataMaster.tanggal_berhenti)}`
                                            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                                            this.getFaktor = response
                                            
                                            dataMaster.masaKerja = `${response[0].masa_kerja_thn} tahun ${response[0].masa_kerja_bln} bulan`
                                            dataMaster.usiaPenerimaMp = `${response[0].usia_penerima_thn} tahun ${response[0].usia_penerima_bln} bulan`
                                            dataMaster.usiaPesertaPensiun = dataMaster.pensiunType === 4 && dataMaster.mpPenerimaTypeId !== 1 ? '56 Tahun 0 bulan' : `${response[0].usia_penerima_thn} tahun ${response[0].usia_penerima_bln} bulan`
                                            dataMaster.mpSekaligusPeserta = response[0].mp_sekaligus_100_ori
                                            dataMaster.mpBulananPeserta = response[0].mp_bulanan_100_ori
                                            dataMaster.mpSekaligusJadud = response[0].mp_sekaligus_100
                                            dataMaster.mpBulananJadud = response[0].mp_bulanan_80
                                            dataMaster.mpSekaligusAnak = response[0].mp_sekaligus_20_ori
                                            dataMaster.mpBulananAnak = response[0].mp_bulanan_20_ori

                                            dataMaster.mpSekaligusNoRound = response[0].mp_sekaligus_100_ori
                                            dataMaster.mpSekaligus = response[0].mp_sekaligus_100
                                            dataMaster.mpBulananNoRound = response[0].mp_bulanan_100_ori
                                            dataMaster.mpBulanan = response[0].mp_bulanan_100
                                            dataMaster.mpSekaligus20NoRound = response[0].mp_sekaligus_20_ori
                                            dataMaster.mpSekaligus20 = response[0].mp_sekaligus_20
                                            dataMaster.mpBulanan80NoRound = response[0].mp_bulanan_80_ori
                                            dataMaster.mpBulanan80 = response[0].mp_bulanan_80
                                            dataMaster.pajakPph21MpSekaligus = response[0].mps_100_pajak_npwp
                                            dataMaster.pajakPph21MpSekaligus20 = response[0].mps_20_pajak_npwp
                                            this.formRef.current.instance.updateData(dataMaster)
                                        } catch (error) {
                                            alert(error, 'Error')
                                            console.log(error);
                                        }
                                        // if (dataMaster.masaKerja) {
                                        //     var mkTahun = parseInt(dataMaster.masaKerja.split('Tahun')[0])
                                        //     var mkBulan = dataMaster.masaKerja.split('Tahun')[1] ? parseInt((dataMaster.masaKerja.split('Tahun')[1]).split('Bulan')[0]) : 0
                                        //     var tahun = this.props.peridoeNs
                                        //     var loadAPI = `peserta-pensiuns/getPerhitunganPensiunWithMasaKerja/${dataMaster.pesertaId}/${dataMaster.productId}/${mkBulan}/${mkTahun}/${tahun}`
                                        //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

                                        //     this.retrieveData(data, response, dataPeserta)
                                        // } else {
                                        //     await alert("Silahkan Isi masa kerja terlebih dahulu!", 'Informasi!')
                                        // }
                                    }
                                },
                            },
                        ]
                    },
                    {
                        dataField: 'usiaPenerimaMp',
                        label: {
                            text: 'Usia Penerima MP'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'usiaPesertaPensiun',
                        label: {
                            text: 'Usia Pensiun'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 6,
                        items: [
                            {
                                dataField: 'peridoeNs',
                                label: {
                                    text: 'NS Periode'
                                },
                                colSpan: 2,
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'nilaiSekarang',
                                label: {
                                    text: 'Nilai Sekarang'
                                },
                                colSpan: 2,
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'nilaiSekaligusPeserta',
                                label: {
                                    text: 'Faktor Konversi'
                                },
                                colSpan: 2,
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                        ]
                    }

                ]
            },
            {
                itemType: 'group',
                name: 'penerima',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Penerima MP',
                        name: 'penerimaMp',
                        items: [
                            {
                                dataField: 'mpPenerimaTypeId',
                                label: {
                                    text: 'Penerima'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: mpPenerimaType(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'penerimaName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: async (data) => {
                                        this.valueFrom = data.value
                                        var value = data.value
                                        var dataAkun = this.state.dataMaster
                                        var dataPerhitungan = this.state.dataPerhitungan
                                        var dataPeserta = this.state.dataPeserta
                                        var penerimaFamilyId = this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId')
                                        var formulaPph21 = dataPeserta.npwp ? 5 / 100 : 6 / 100
                                        var getPenguranganPph = this.globalParam.find(val => val.paramCode == '2 Tax MPS')
                                        dataAkun.pajakPph21MpSekaligus = 0

                                        if (value == 1) {
                                            penerimaFamilyId.isRequired = false
                                            penerimaFamilyId.validationRules = []
                                            penerimaFamilyId.editorOptions.readOnly = true
                                            penerimaFamilyId.editorOptions.placeholder = ""
                                            penerimaFamilyId.editorOptions.dataSource = []
                                            this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', penerimaFamilyId)
                                        } else {
                                            penerimaFamilyId.isRequired = true
                                            penerimaFamilyId.validationRules = [{
                                                type: "required",
                                                message: "Data Harus Diisi"
                                            }]
                                            penerimaFamilyId.editorOptions.readOnly = false
                                            penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                                            penerimaFamilyId.editorOptions.dataSource = this.dataKeluargaByPesertaId
                                            this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', penerimaFamilyId)

                                        }
                                    }
                                },
                                isRequired: true,
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Data Harus Diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'mpPenerimaPesertaFamilyTypeId',
                                name: 'mpPenerimaPesertaFamilyTypeId',
                                label: {
                                    text: 'Keluarga Penerima MP'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: pesertaFamiliesAll(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'fullName',
                                    searchEnabled: true,
                                    deferRendering: false
                                },
                                // isRequired: true,
                                // validationRules: [
                                //     {
                                //         type: "required",
                                //         message: "Data Harus Diisi"
                                //     }
                                // ]
                            },
                            {
                                dataField: 'mpPertamaDate',
                                label: {
                                    text: 'Tanggal MP Pertama'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    placeholder: 'dd MMM yyyy',
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    width: '100%'
                                },
                                isRequired: true,
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Tanggal MP pertama harus diisi"
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'rekeningMp',
                        caption: 'Rekening Manfaat Pensiun',
                        items: [
                            {
                                dataField: 'mpRekeningNo',
                                label: {
                                    text: 'No Rek MP'
                                }
                            },
                            {
                                dataField: 'mpRekeningName',
                                label: {
                                    text: 'Nama Rek MP'
                                }
                            },
                            {
                                dataField: 'mpRekeningBankId',
                                label: {
                                    text: 'Rekening Bank Pembayaran'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks_core(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                                isRequired: true,
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Rekening Pembayaran Harus Diisi"
                                    }
                                ]
                            },
                            {
                                dataField: 'mpRekeningCabang',
                                label: {
                                    text: 'Cabang'
                                }
                            },
                        ]
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'perhitunganMp',
                caption: 'Perhitungan Manfaat Pensiun',
                items: [
                    {
                        dataField: 'mpSekaligusPeserta',
                        label: {
                            text: 'Nilai MP Sekaligus Peserta'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpBulananPeserta',
                        label: {
                            text: 'Nilai MP Bulanan Peserta'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpSekaligusJadud',
                        label: {
                            text: 'Nilai MP Sekaligus Jadud'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpBulananJadud',
                        label: {
                            text: 'Nilai MP Bulanan Jadud'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpSekaligusAnak',
                        label: {
                            text: 'Nilai MP Sekaligus Anak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpBulananAnak',
                        label: {
                            text: 'Nilai MP Bulanan Anak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'mpTransfer',
                caption: 'Manfaat Pensiun Di Transfer',
                items: [
                    {
                        dataField: 'mpSekaligusNoRound',
                        // name: 'nilaiMpSekaligusFull',
                        label: {
                            text: 'Nilai MP Sekaligus 100%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpSekaligus',
                        label: {
                            text: 'Nilai MP Sekaligus 100% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpBulananNoRound',
                        // name: 'nilaiMpBulananFull',
                        label: {
                            text: 'Nilai MP Bulanan 100%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpBulanan',
                        // name: 'nilaiMpBulananFull',
                        label: {
                            text: 'Nilai MP Bulanan 100% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'mpSekaligus20NoRound',
                        label: {
                            text: 'Nilai MP Sekaligus 20%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'mpSekaligus20',
                        label: {
                            text: 'Nilai MP Sekaligus 20% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'mpBulanan80NoRound',
                        label: {
                            text: 'Nilai MP Bulanan 80%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'mpBulanan80',
                        label: {
                            text: 'Nilai MP Bulanan 80% Bulat'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        },
                    },
                    {
                        dataField: 'pajakPph21MpSekaligus',
                        label: {
                            text: 'Nilai Pajak PPH 21 MP Sekaligus 100%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        }
                    },
                    // {
                    //     dataField: 'pajakPph21MpSekaligusBulat',
                    //     label: {
                    //         text: 'Nilai Pajak PPH 21 MP Sekaligus 100% Bulat'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         step: 0,
                    //         format: '#,##0',
                    //     }
                    // },
                    {
                        dataField: 'pajakPph21MpSekaligus20',
                        label: {
                            text: 'Nilai Pajak PPH 21 MP Sekaligus 20%'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0',
                        }
                    },
                    // {
                    //     dataField: 'pajakPph21MpSekaligusBulat20',
                    //     label: {
                    //         text: 'Nilai Pajak PPH 21 MP Sekaligus 20% Bulat'
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         step: 0,
                    //         format: '#,##0',
                    //     }
                    // },
                    {
                        dataField: 'paymentRekbankId',
                        label: {
                            text: 'Rekening Pembayar'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'rekName',
                            searchEnabled: true,
                            deferRendering: false
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                ]
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            // var extension = mime.extension(this.state.dataMaster[`${fileName}ContentType`])
            // console.log(extension)
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`]), this.state.dataMaster[`${fileName}Name`])
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.valueFrom = 0
        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataPeserta: {},
            data: {},
            typeData: {}
        });
    }

    cetak = async (tipe) => {
        try {
            var hubunganKeluarga = [
                {
                    id: 'SI',
                    value: 'Suami/Istri'
                },
                {
                    id: 'AK',
                    value: 'Anak Kandung'
                },
                {
                    id: 'AIK',
                    value: 'Orang Tua'
                },
                {
                    id: 'KAK',
                    value: 'Saudara Kandung'
                },
                {
                    id: 'PK',
                    value: 'Pihak Ketiga'
                }
            ]

            this.props.store.dispatch(showLoading(true));
            let dataMaster = this.state.dataMaster
            let dataPeserta = this.state.dataPeserta
            let dataKeluarga = this.dataKeluargaByPesertaId
            
            try {
                var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${dataPeserta.id}`
                var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKepegawaian, 'GET')
            } catch (e) {
                console.log(e)
            }

            let loadAPICetak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-pensiuns/regPensiunCetak/${dataPeserta.nik}`, 'GET');
            var loadAPICetak2 = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store,`peserta-pensiuns/loadDataRegistPensiun/${dataPeserta.nik}`, 'GET');
            let globalParamRes = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters');
            
            let findParamNpj = globalParamRes.find(val => val.paramCode === '1 NPJ').stringValue;
            let findParamJpj = globalParamRes.find(val => val.paramCode === '1 JPJ').stringValue;
            let namaDirectur = globalParamRes.find(val => val.paramCode === 'OPR-DIR').stringValue;
            let namaManager = globalParamRes.find(val => val.paramCode === '0 MNG').stringValue;

            if(dataPeserta.domicileCityId) {
                var citie = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `cities/${dataPeserta.domicileCityId}`, 'GET');
            }
            if(dataPeserta.domicileCountryId) {
                var negara = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `countries/${dataPeserta.domicileCountryId}`, 'GET');
            }
            if(dataPeserta.domicileKelurahanId) {
                var kelurahans = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `kelurahans/${dataPeserta.domicileKelurahanId}`, 'GET');
            }
            if(dataPeserta.domicileKecamatanId) {
                var kecamatan = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `kecamatans/${dataPeserta.domicileKecamatanId}`, 'GET');
            }
            if(dataPeserta.domicileProvinceId) {
                var province = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `provinces/${dataPeserta.domicileProvinceId}`, 'GET');
            }
            let penerima = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `manfaat-pensiun-penerima-types/${dataMaster.mpPenerimaTypeId}`, 'GET')
            let namaBank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `banks/${dataMaster.mpRekeningBankId}`, 'GET')
            let paymentType = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `mp-payment-types/${dataMaster.mpPaymentTypeId}`, 'GET')
            let pensiunType = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pensiun-types/${dataMaster.pensiunType}`, 'GET')

            let dataKeluargaPeserta = []
            
            let index = 1
            for(let value of dataKeluarga) {
                let dataValue = {
                    index: index++,
                    fullname_pf: value.fullName,
                    birthdate_pf: value.birthDate ? formatDefaultDate(value.birthDate) : '-',
                    relation_pf: value.relationCode ? hubunganKeluarga.find(val => val.id === value.relationCode).value : '-'
                }

                dataKeluargaPeserta.push(dataValue)
            }

            if (tipe === 'pilihan') {
                // const res = await fetch('/file_upload/Laporan_registrrasi_pasif_pilihan.xlsx');
                // const blob = await res.blob();


                // const dataArrayBuffer = await blob.arrayBuffer();

                // const workbook = new Excel.Workbook();
                // await workbook.xlsx.load(dataArrayBuffer);

                // const workSheetNames = 'Pilihan';

                // let workSheet = workbook.getWorksheet(workSheetNames);

                // if (workSheet.name === 'Pilihan') {
                //     for (let value of loadAPICetak) {
                //         workSheet.getCell('B6').value = value.nama_peserta;
                //         workSheet.getCell('B7').value = this.state.dataPeserta.nik;
                //         workSheet.getCell('B9').value = value.domicile_city_id ? citie.find(val => val.id === value.domicile_city_id).cityName : null;
                //         workSheet.getCell('F14').value = value.nama_peserta;
                //         workSheet.getCell('L14').value = this.state.dataPeserta.nik;
                //         workSheet.getCell('N39').value = value.mp_bulanan_80;
                //         workSheet.getCell('O43').value = value.mps_20_sebelum_pajak;
                //         workSheet.getCell('O44').value = value.mps_20_pajak_npwp;
                //         workSheet.getCell('O45').value = value.mps_20_setelah_pajak_npwp;
                //         workSheet.getCell('O47').value = value.mps_20_sebelum_pajak;
                //         workSheet.getCell('O48').value = value.mps_20_pajak_non_npwp;
                //         workSheet.getCell('O49').value = value.mps_20_setelah_pajak_non_npwp;
                //     }
                //     await workbook.xlsx.writeBuffer().then(function(buffer) {
                //         saveAs(
                //             new Blob([buffer], { type: "application/octet-stream" }),
                //             `Laporan_registrrasi_pasif_pilihan.xlsx`
                //         )
                //     })   
                // }

                var data = []
                for (var value of loadAPICetak) {
                    let result = {
                        tanggal_pensiun: formatDefaultFullDate(value.tanggal_pensiun),
                        tanggal_mp_pertama: formatDefaultFullDate(value.tanggal_mp_pertama),
                        nama_peserta: value.nama_peserta,
                        nik_panjang: value.nik_panjang,
                        cityName: value.domicile_city_id ? citie.cityName : null,
                        mp_bulanan_80: value.mp_bulanan_80 ? formatNumberAum(value.mp_bulanan_80) : 0,
                        mp_bulanan_100: value.mp_bulanan_100 ? formatNumberAum(value.mp_bulanan_100) : 0,
                        mp_100_terbilang: (numberToIndo(value.mp_bulanan_100)).toLowerCase(),
                        mp_sekaligus_20: (value.mp_sekaligus_20) ? formatNumberAum(value.mp_sekaligus_20) : 0,
                        mps_20_pajak_npwp: (value.mps_20_pajak_npwp) ? formatNumberAum(value.mps_20_pajak_npwp) : 0,
                        mps_20_setelah_pajak_npwp: formatNumberAum(value.mp_sekaligus_20 -  value.mps_20_pajak_npwp),
                        mps_20_pajak_non_npwp: (value.mps_20_pajak_non_npwp) ? formatNumberAum(value.mps_20_pajak_non_npwp) : 0,
                        mps_20_setelah_pajak_non_npwp: formatNumberAum(value.mp_sekaligus_20 - value.mps_20_pajak_non_npwp),
                        mp_sekaligus_100: (value.mp_sekaligus_100) ? formatNumberAum(value.mp_sekaligus_100) : 0,
                        mps_100_pajak_npwp: (value.mps_100_pajak_npwp) ? formatNumberAum(value.mps_100_pajak_npwp) : 0,
                        mps_100_setelah_pajak_npwp: formatNumberAum(value.mp_sekaligus_100 - value.mps_100_pajak_npwp), 
                        mps_100_pajak_non_npwp: (value.mps_100_pajak_non_npwp) ? formatNumberAum(value.mps_100_pajak_non_npwp) : 0,
                        mps_100_setelah_pajak_non_npwp: formatNumberAum(value.mp_sekaligus_100 - value.mps_100_pajak_non_npwp),
                        namaDirectur: namaDirectur,
                        penerimaName: penerima.penerimaName
                    }
                    data.push(result)
                }

                // var data = this.formRef.current.getDataSource()

                var dataRegistrasiPilihan = data[0]

                reportFile({
                    template: { 
                        'shortid': 'Sygc5rT2op',
                    },
                    data: dataRegistrasiPilihan,
                    options: {
                        reportName: "registrasi_pasif_pilihan"
                    }
                }, true, 'registrasi_pasif_pilihan.docx')
            } else if (tipe === 'blankoPilihan') {
                // const res = await fetch('/file_upload/Laporan_registrrasi_pasif_blangko_pilihan.xlsx');
                // const blob = await res.blob();

                // const dataArrayBuffer = await blob.arrayBuffer();

                // const workbook = new Excel.Workbook();
                // await workbook.xlsx.load(dataArrayBuffer);

                // await workbook.xlsx.writeBuffer().then(function(buffer) {
                //     saveAs(
                //         new Blob([buffer], { type: "application/octet-stream" }),
                //         `Laporan_registrrasi_pasif_blangko_pilihan.xlsx`
                //     )
                // })
                var data = []
                for(value of loadAPICetak) {
                    var result = {
                        nama_peserta: value.nama_peserta,
                        nik_panjang: value.nik_panjang,
                        birth_date: formatDefaultFullDate(value.birth_date),
                        alamat_lengkap: `${dataPeserta.domicileAddress1}, ${citie.cityName} Kel ${kelurahans.kelurahanName} Kec ${kecamatan.kecamatanName}, ${province.provinceName} ${negara.countryName} ${dataPeserta.domicilePostalCode}`,
                        phone_no: dataPeserta.mobileNo ? dataPeserta.mobileNo : dataPeserta.mobilePhone2,
                        bank_name: namaBank.bankName,
                        no_rek_mp: value.no_rek_mp,
                        no_ktp: dataPeserta.noKtp,
                        no_npwp: dataPeserta.npwp
                    }
                    data.push(result)
                }

                var dataPilihanBlanko = data[0]

                reportFile({
                    template: {
                        'shortid': 'SJl77A1Ro6'
                    },
                    data: dataPilihanBlanko,
                    options: {
                        reportName: "registrasi_pasif_blanko"
                    }
                }, true, 'registrasi_pasif_blanko.docx')
            } else if (tipe === 'perhitungan') {
                // const res = await fetch('/file_upload/Laporan_registrrasi_pasif_perhitungan.xlsx');
                // const blob = await res.blob();

                // const dataArrayBuffer = await blob.arrayBuffer();
                // const workbook = new Excel.Workbook();
                // await workbook.xlsx.load(dataArrayBuffer);

                // const workSheetNames = 'perhitungan';

                // let workSheet = workbook.getWorksheet(workSheetNames);

                // if (workSheet.name === 'perhitungan') {
                //     for (let value of loadAPICetak) {
                //         workSheet.getCell('D4').value = value.nama_peserta;
                //         workSheet.getCell('D5').value = this.state.dataPeserta.nik;
                //         workSheet.getCell('D6').value = value.birth_date;
                //         workSheet.getCell('D7').value = value.domicile_kelurahan_id ? kelurahans.find(val => val.id === value.domicile_kelurahan_id).kelurahanName : null;
                //         workSheet.getCell('D8').value = value.domicile_kecamatan_id ? kecamatan.find(val => val.id === value.domicile_kecamatan_id).kecamatanName : null;
                //         workSheet.getCell('D9').value = value.domicile_city_id ? citie.find(val => val.id === value.domicile_city_id).cityName : null;
                //         workSheet.getCell('D10').value = value.domicile_province_id ? province.find(val => val.id === value.domicile_province_id).provinceName : null;
                //         workSheet.getCell('D11').value = value.phone_no;
                //         workSheet.getCell('D12').value = formatNpwp(value.npwp);
                //         workSheet.getCell('D13').value = value.no_ktp;
                //         // workSheet.getCell('')
                //     }
                // }

                // await workbook.xlsx.writeBuffer().then(function(buffer) {
                //     saveAs(
                //         new Blob([buffer], { type: "application/octet-stream" }),
                //         `Laporan_registrrasi_pasif_blangko_pilihan.xlsx`
                //     )
                // })

                let statusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `ptkps/${dataPeserta.statusPajakId}`, 'GET');

                var data = []
                
                for(value of loadAPICetak) {
                    let hitMK = value.masa_kerja_thn + (value.masa_kerja_bln / 12)
                    
                    let result = {
                        usia_penerima_thn: value.usia_penerima_thn,
                        usia_penerima_bln: value.usia_penerima_bln,
                        tanggal_pensiun: formatDefaultFullDate(value.tanggal_pensiun),
                        ns_periode: value.ns_periode,
                        nama_peserta: value.nama_peserta,
                        nik_panjang: value.nik_panjang,
                        birth_date: formatDefaultFullDate(value.birth_date),
                        alamat_lengkap: `${dataPeserta.domicileAddress1}, ${citie ? citie.cityName : '-'} Kel ${kelurahans ? kelurahans.kelurahanName : '-'} Kec ${kecamatan ? kecamatan.kecamatanName : '-'}, ${province ? province.provinceName : '-'} ${negara ? negara.countryName : '-'} ${dataPeserta.domicilePostalCode}`,
                        phone_no: dataPeserta.mobileNo ? dataPeserta.mobileNo : dataPeserta.mobilePhone2,
                        no_npwp: dataPeserta.npwp,
                        no_ktp: dataPeserta.noKtp,
                        tanggal_berhenti: formatDefaultFullDate(value.tanggal_berhenti),
                        masa_kerja_tahun: value.masa_kerja_thn,
                        masa_kerja_bulan: value.masa_kerja_bln,
                        phdp: value.phdp ? formatNumberAum(value.phdp) : 0,
                        ptkp: value.ptkp ? formatNumberAum(value.ptkp) : 0,
                        pkp: value.pkp ? formatNumberAum(value.pkp) : 0,
                        status_pajak: dataPeserta.statusPajakId ? `${statusPajak.kode} - ${statusPajak.keterangan}` : '-',
                        hit_mk: hitMK.toFixed(2),
                        faktor: value.ns_periode === 2021 ? value.fk_value : 0,
                        mp_bulanan_100_ori: value.mp_bulanan_100_ori ? formatNumberAum(value.mp_bulanan_100_ori) : 0,
                        mp_bulanan_80_ori: value.mp_bulanan_80_ori ? formatNumberAum(value.mp_bulanan_80_ori) : 0,
                        mp_bulanan_20_ori: value.mp_bulanan_20_ori ? formatNumberAum(value.mp_bulanan_20_ori) : 0,
                        mp_sekaligus_20_ori: value.mp_sekaligus_20_ori ? formatNumberAum(value.mp_sekaligus_20_ori) : 0,
                        mp_sekaligus_100: (value.mp_sekaligus_100) ? formatNumberAum(value.mp_sekaligus_100) : 0,
                        mp_sekaligus_20: (value.mp_sekaligus_20) ? formatNumberAum(value.mp_sekaligus_20) : 0,
                        mp_bulanan_100: (value.mp_bulanan_100) ? formatNumberAum(value.mp_bulanan_100) : 0,
                        mp_bulanan_80: (value.mp_bulanan_80) ? formatNumberAum(value.mp_bulanan_80) : 0,
                        mps_20_pajak_npwp: (value.mps_20_pajak_npwp) ? formatNumberAum(value.mps_20_pajak_npwp) : 0,
                        mps_20_setelah_pajak_npwp: (value.mps_20_setelah_pajak_npwp) ? formatNumberAum(value.mps_20_setelah_pajak_npwp) : 0,
                        mps_20_pajak_non_npwp: (value.mps_20_pajak_non_npwp) ? formatNumberAum(value.mps_20_pajak_non_npwp) : 0,
                        mps_20_setelah_pajak_non_npwp: (value.mps_20_setelah_pajak_non_npwp) ? formatNumberAum(value.mps_20_setelah_pajak_non_npwp) : 0,
                        today: formatDefaultFullDate(new Date()),
                        namaDirectur: namaDirectur,
                        namaManager: namaManager,
                        efectif_date: getKepegawaian.tanggalEfektif ? formatDefaultFullDate(getKepegawaian.tanggalEfektif) : '-',
                        user: this.username,
                        dataKeluarga: dataKeluargaPeserta
                    }
                    data.push(result)
                }

                var dataPerhitungan = data[0]

                reportFile({
                    template: {
                        'shortid': 'SyxmaKZRoT'
                    },
                    data: dataPerhitungan,
                    oprions: {
                        reportName: 'registrasi_pasif_perhitungan'
                    }
                }, true, 'registrasi_pasif_perhitungan.docx')
            } else if (tipe === 'pembayaran') {
                // const res = await fetch('/file_upload/Laporan_registrrasi_pasif_pembayaran_80.xlsx');
                // const blob = await res.blob();

                // const dataArrayBuffer = await blob.arrayBuffer();

                // const workbook = new Excel.Workbook();
                // await workbook.xlsx.load(dataArrayBuffer);

                // const workSheetNames = 'Pembayaran 80';

                // let workSheet = workbook.getWorksheet(workSheetNames);

                // if (workSheet.name === 'Pembayaran 80') {
                //     for (let value of loadAPICetak) {
                //         workSheet.getCell('B7').value = value.nama_peserta;
                //         workSheet.getCell('B8').value = this.state.dataPeserta.nik;
                //         workSheet.getCell('B10').value = value.domicile_city_id ? citie.find(val => val.id === value.domicile_city_id).cityName : null;
                //         workSheet.getCell('E16').value = value.nama_peserta;
                //         workSheet.getCell('J16').value = this.state.dataPeserta.nik;
                //     }
                //     await workbook.xlsx.writeBuffer().then(function(buffer) {
                //         saveAs(
                //             new Blob([buffer], { type: "application/octet-stream" }),
                //             `Laporan_registrrasi_pasif_pembayaran_80.xlsx`
                //         )
                //     }) 
                // }

                let mpPertamaDate = dataMaster.mpPertamaDate
                mpPertamaDate = mpPertamaDate ? formatDefaultFullDate(mpPertamaDate).split(' ') : mpPertamaDate
                var data = []
                for(value of loadAPICetak) {
                    let result = {
                        today: formatDefaultFullDate(new Date()),
                        nama_peserta: value.nama_peserta,
                        nik_panjang: value.nik_panjang,
                        cityName: value.domicile_city_id ? citie.cityName : '-',
                        penerima: penerima.penerimaName,
                        sk_no: value.no_sk_pensiun,
                        sk_date: dataMaster.tglSkPensiun ? formatDefaultFullDate(dataMaster.tglSkPensiun) : (dataMaster.tglSkPemberhentian ? formatDefaultFullDate(dataMaster.tglSkPemberhentian) : '-'),
                        mp_pertama_periode: mpPertamaDate[1],
                        bankName: namaBank.bankName,
                        mp_bulanan_80: value.mp_bulanan_80 ? formatNumberAum(value.mp_bulanan_80) : 0,
                        mp_bulanan_terbilang: (numberToIndo(value.mp_bulanan_80)).toLowerCase(),
                        cabang: loadAPICetak2[0].cabang,
                        no_rek_mp: value.no_rek_mp,
                        jumlah_bayar: value.mp_bulanan_80 && value.nilai_rapel && value.pajak_mp_bulanan ? formatNumberAum(value.mp_bulanan_80 + value.nilai_rapel + value.pajak_mp_bulanan) : 0,
                        nilai_rapel: null,
                        pajak_mp_bulanan: null,
                        namaDirectur: namaDirectur
                    }
                    data.push(result)
                }

                var dataPembayaran = data[0]

                reportFile({
                    template: {
                        'shortid': 'SkG1VQ13p'
                    },
                    data: dataPembayaran,
                    oprions: {
                        reportName: 'registrasi_pasif_pembayaran'
                    }
                }, true, 'registrasi_pasif_pembayaran.docx')
            } else if (tipe === 'penetapan1') {
                var data = []
                for(value of loadAPICetak) {
                    let result = {
                        nik_panjang: value.nik_panjang,
                        nama_penerima: value.nama_penerima,
                        jabatan_name: getKepegawaian.jabatanName ? getKepegawaian.jabatanName : '-',
                        grade: getKepegawaian.grade ? getKepegawaian.grade : '-',
                        birth_date: value.birth_date ? formatDefaultFullDate(value.birth_date) : '-',
                        pensiun_date: dataMaster.pensiunDate ? formatDefaultFullDate(dataMaster.pensiunDate) : '-',
                        // pensiun_type: dataMaster.pesertaPhkTypeId ? jenisPhk.find(val => val.id === value.pesertaPhkTypeId).value : '-',
                        pensiun_type: dataMaster.pensiunType ? pensiunType.statusName : '-',
                        pembayaran_type: dataMaster.mpPaymentTypeId ? paymentType.typeName : '-',
                        mp_bulanan: value.mp_sekaligus_20 ? formatNumber(value.mp_sekaligus_20) : 0,
                        mp_sekaligus: value.mp_bulanan_80 ? formatNumber(value.mp_bulanan_80) : 0,
                        cabang: loadAPICetak2[0].cabang,
                        mp_rekening_no: dataPeserta.rekeningNo,
                        alamat_lengkap: `${dataPeserta.domicileAddress1}, ${citie ? citie.cityName : '-'} Kel ${kelurahans ? kelurahans.kelurahanName : '-'} Kec ${kecamatan ? kecamatan.kecamatanName : '-'}, ${province ? province.provinceName : '-'} ${negara ? negara.countryName : '-'} ${dataPeserta.domicilePostalCode}`,
                        dataKeluarga: dataKeluargaPeserta,
                        today: formatDefaultFullDate(new Date()),
                        direktur_utama: namaDirectur
                    }
                    data.push(result)
                }

                var dataPenetapan1 = data[0]

                reportFile({
                    template: {
                        'shortid': 'HygRwoXk3T'
                    },
                    data: dataPenetapan1,
                    oprions: {
                        reportName: 'registrasi_pasif_penetapan1'
                    }
                }, true, 'registrasi_pasif_penetapan1.docx')
            } else if (tipe === 'penetapan2') {
                var data = []
                for(value of loadAPICetak) {
                    let result = {
                        nik_panjang: value.nik_panjang,
                        nama_peserta: value.nama_peserta,
                        cityName: value.domicile_city_id ? citie.cityName : '-',
                        today: formatDefaultFullDate(new Date()),
                        namaDirectur: namaDirectur
                    }
                    data.push(result)
                }

                var dataPenetapan2 = data[0]

                reportFile({
                    template: {
                        'shortid': 'rJLip7k26'
                    },
                    data: dataPenetapan2,
                    oprions: {
                        reportName: 'registrasi_pasif_penetapan2'
                    }
                }, true, 'registrasi_pasif_penetapan2.docx')
            }
            this.props.store.dispatch(showLoading(false))
        } catch (error) {
            console.log(error);
            alert(error, 'Error');
        }

        // var loadAPIPensiunType = `pensiun-types`
        // var getPensiunType = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPensiunType, 'GET')

        // var dataPeserta = this.state.dataPeserta
        // var dataAkun = this.state.dataMaster
        // dataAkun.pensiunTypeName = getPensiunType.find(val => val.id == dataAkun.pensiunType) ? getPensiunType.find(val => val.id == dataAkun.pensiunType).statusName : '-'
        // var faktorKonversi = 0
        // var mpSekaligus = dataAkun.mpSekaligus

        // var getPerhituganPensiun;

        // try {
        //     var tahun = this.props.peridoeNs
        //     var loadAPI = `peserta-pensiuns/getPerhitunganPensiun/${dataPeserta.id}/${dataAkun.productId}/${tahun}`
        //     // var loadAPI = `peserta-pensiuns/getPerhitunganPensiun/${dataPeserta.id}/${dataAkun.productId}`
        //     var getPerhituganPensiun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        // } catch (e) {
        //     console.log(e, 'get perhitungan cetak')
        // }
        // switch (dataAkun.mpPenerimaTypeId) {
        //     case 1:
        //         faktorKonversi = getPerhituganPensiun.nilaiSekaligusPeserta || 0
        //         mpSekaligus = dataAkun.mpSekaligusPeserta
        //         break;
        //     case 2:
        //         faktorKonversi = getPerhituganPensiun.nilaiSekaligusJadud || 0
        //         mpSekaligus = dataAkun.mpSekaligusJadud
        //         break;
        //     case 3:
        //         faktorKonversi = getPerhituganPensiun.faktorAnak || 0
        //         mpSekaligus = dataAkun.mpSekaligusAnak
        //         break;
        //     case 4:
        //         faktorKonversi = getPerhituganPensiun.nilaiSekaligusPeserta || 0
        //         mpSekaligus = dataAkun.mpSekaligusPeserta
        //         break;
        // }

        // var date1 = new Date(getKepegawaian.efektifDate)
        // var date2 = new Date(dataAkun.pensiunDate)
        // var diffYears = yearsDiff(dataAkun.pensiunDate, getKepegawaian.efektifDate)
        // var diffMonths = Math.abs(date2.getMonth() - date1.getMonth());

        // if (!dataAkun.masaKerja) {
        //     dataAkun.masaKerja = `${diffYears || 0} Tahun ${diffMonths || 0} Bulan`
        // }
        // let tmtPensiun = dataAkun.pensiunType == 4 ? getKepegawaian.tanggalBerhenti : dataAkun.pensiunDate
        // var perhitungan = {
        //     tmtPensiun: tmtPensiun ? formatDefaultFullDate(tmtPensiun) : '-',
        //     efektifDate: dataAkun.efektifDate ? formatDefaultFullDate(dataAkun.efektifDate) : '-',
        //     pensiunDate: dataAkun.pensiunDate ? formatDefaultFullDate(dataAkun.pensiunDate) : '-',
        //     mpBulanan: dataAkun.mpBulanan || 0,
        //     mpBulanan80NoRound: parseInt(dataAkun.mpBulanan80NoRound),
        //     mpBulanan80NoRound20: parseInt((dataAkun.mpBulanan80NoRound || 0).toFixed(2) * (20 / 100)),
        //     mpBulanan80NoRound80: parseInt((dataAkun.mpBulanan80NoRound || 0).toFixed(2) * (80 / 100)),
        //     // mpBulanan80Round80: pembulatan((dataAkun.mpBulanan80NoRound * 80 / 100) , 2),
        //     mpBulanan80Round80: pembulatan((((dataAkun.mpBulanan80NoRound || 0).toFixed(2) || 0) * 80 / 100).toFixed(2), 2),
        //     mpBulanan80: dataAkun.mpBulanan80 || 0,
        //     mpSekaligus: mpSekaligus,
        //     mpSekaligus20: dataAkun.mpSekaligus20 || 0,
        //     mpSekaligus20NoRound: dataAkun.mpSekaligus20NoRound || 0,
        //     pph21Npwp: 0,
        //     pph21NoNpwp: 0,
        //     mpSekaligusDiterimaNpwp: 0,
        //     mpSekaligusDiterimaNoNpwp: 0,
        //     mpSekaligusPph50: mpSekaligus - 50000,
        //     masaKerja: dataAkun.masaKerja,
        //     perhitunganMk: ((diffYears || 0) + (diffMonths ? diffMonths / 12 : 0)).toFixed(3),
        //     faktorKonversi: faktorKonversi || 0,
        //     sekaligusPph: dataAkun.mpSekaligus20 - dataAkun.pajakPph21MpSekaligus20,
        //     mpPajakSekaligus: 0
        // }

        // var getPenguranganPph = this.globalParam.find(val => val.paramCode == '2 Tax MPS')
        // if (dataAkun.mpPaymentTypeId == 1) {
        //     if (getPenguranganPph.intValue < dataAkun.mpSekaligus20) {
        //         perhitungan.pph21Npwp = (dataAkun.mpSekaligus20 - getPenguranganPph.intValue) * 5 / 100
        //         perhitungan.pph21NoNpwp = (dataAkun.mpSekaligus20 - getPenguranganPph.intValue) * 6 / 100
        //     }
        //     perhitungan.mpSekaligusDiterimaNpwp = (5 / 100) * perhitungan.mpSekaligus20 - 50000
        //     perhitungan.mpSekaligusDiterimaNoNpwp = (5 / 100) * (120 / 100) * perhitungan.mpSekaligus20 - 50000
        //     if (dataPeserta.npwp) {
        //         perhitungan.pph21 = perhitungan.pph21Npwp
        //     } else {
        //         perhitungan.pph21 = perhitungan.pph21NoNpwp
        //     }
        //     perhitungan.mpPajakSekaligus = perhitungan.mpSekaligus20
        // } else {
        //     if (getPenguranganPph.intValue < perhitungan.mpSekaligus) {
        //         perhitungan.pph21Npwp = (perhitungan.mpSekaligus - getPenguranganPph.intValue) * 5 / 100
        //         perhitungan.pph21NoNpwp = (perhitungan.mpSekaligus - getPenguranganPph.intValue) * 6 / 100
        //     }
        //     perhitungan.mpSekaligusDiterimaNpwp = (5 / 100) * perhitungan.mpSekaligus - 50000
        //     perhitungan.mpSekaligusDiterimaNoNpwp = (5 / 100) * (120 / 100) * perhitungan.mpSekaligus - 50000

        //     perhitungan.mpPajakSekaligus = perhitungan.mpSekaligus
        // }

        // var dataKeluarga = {
        //     fullName: '-'
        // }
        // if (dataAkun.mpPenerimaPesertaFamilyTypeId) {
        //     var loadAPIFamily = `peserta-families/${dataAkun.mpPenerimaPesertaFamilyTypeId}`
        //     var getFamily = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIFamily, 'GET')

        //     dataKeluarga = getFamily

        //     switch (dataKeluarga.relationCode) {
        //         case 'SI':
        //             dataKeluarga.relationCode = 'Suami/Istri'
        //             break;
        //         case 'AK':
        //             dataKeluarga.relationCode = 'Anak Kandung'
        //             break;
        //         case 'AIK':
        //             dataKeluarga.relationCode = 'Orang Tua'
        //             break;
        //         case 'KAK':
        //             dataKeluarga.relationCode = 'Saudara Kandung'
        //             break;
        //         case 'PK':
        //             dataKeluarga.relationCode = 'Pihak Ketiga'
        //             break;
        //     }
        // }

        // var loadAPIProduct = `products`
        // var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct, 'GET')

        // var productName = getProduct.find(val => val.id == this.state.dataMaster.productId).productCode

        // try {
        //     var loadAPIProvince = `provinces/${dataPeserta.identityProvinceId}`
        //     var getProvince = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPIProvince, 'GET')
        // } catch (error) {
        //     console.log(error)
        //     var getProvince = ""
        // }

        // try {
        //     var loadAPICity = `cities/${dataPeserta.identityCityId}`
        //     var getCity = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPICity, 'GET')
        // } catch (error) {
        //     console.log(error)
        //     var getCity = ""
        // }


        // try {
        //     var loadAPIKecamatan = `kecamatans/${dataPeserta.identityKecamatanId}`
        //     var getKecamatan = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPIKecamatan, 'GET')
        // } catch (error) {
        //     console.log(error)
        //     var getKecamatan = ""
        // }


        // try {
        //     var loadAPIKelurahan = `kelurahans/${dataPeserta.identityKelurahanId}`
        //     var getKelurahan = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPIKelurahan, 'GET')
        // } catch (error) {
        //     console.log(error)
        //     var getKelurahan = ""
        // }

        // dataPeserta.provinsiId = getProvince.provinceName || ""
        // dataPeserta.cityId = getCity.cityName || ""
        // dataPeserta.kecamatanId = getKecamatan.kecamatanName || ""
        // dataPeserta.kelurahanId = getKelurahan.kelurahanName || ""

        // perhitungan.mpBulananBilangan = perhitungan.mpBulanan ? `${numberToIndo(perhitungan.mpBulanan, "")} Rupiah` : "Nol Rupiah"
        // perhitungan.mpSekaligusBilangan = perhitungan.mpSekaligus ? `${numberToIndo(perhitungan.mpSekaligus, "")} Rupiah` : "Nol Rupiah"
        // perhitungan.mpSekaligus20Bilangan = perhitungan.mpSekaligus20 ? `${numberToIndo(perhitungan.mpSekaligus20, "")} Rupiah` : "Nol Rupiah"
        // perhitungan.mpBulanan80Bilangan = perhitungan.mpBulanan80 ? `${numberToIndo(perhitungan.mpBulanan80, "")} Rupiah` : "Nol Rupiah"

        // var statusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `ptkps/${dataPeserta.statusPajakId}`)
        // const mkSplit = perhitungan.masaKerja.split(' ')
        // dataPeserta.statusPajakName = statusPajak.kode
        // dataPeserta.tanggalLahir = dataPeserta.birthDate ? formatDefaultFullDate(dataPeserta.birthDate) : '-'
        // perhitungan.perhitunganMk = ((parseInt(mkSplit[0]) || 0) + (parseInt(mkSplit[2]) ? parseInt(mkSplit[2]) / 12 : 0)).toFixed(3)
        // dataAkun.formatPensiunDate = dataAkun.pensiunDate ? formatDefaultFullDate(dataAkun.pensiunDate) : '-'
        // dataAkun.tanggalMpPertama = dataAkun.mpPertamaDate ? formatDefaultFullDate(dataAkun.mpPertamaDate) : '-'
        // // var data = {
        // //     template:{
        // //         name: '/dapen/surat-pengantar-pilihan/surat-pengantar-pilihan-main',
        // //         recipe: 'html'
        // //     },
        // //     data: {
        // //         nomorSurat: dataCetak.noSurat || '-',
        // //         dataPeserta : dataPeserta,
        // //         dataAkun : dataAkun,
        // //         dataKepegawaian : getKepegawaian,
        // //         dataKeluarga : dataKeluarga,
        // //         produk: productName,
        // //         perhitungan : perhitungan,
        // //         tanggalSurat : formatDefaultFullDate(dataCetak.tanggalSurat || new Date())
        // //     },
        // // }


        // // var response = await reportRequest(this.props.store,data)
        // // PrintElemWithoutTitle(response.data)
        // // return response
        // var data = {
        //     template: {
        //         shortid: "B1bMxv7KY",
        //         // name: '/dapen/surat-pengantar-pilihan/surat-pengantar-pilihan-word',
        //         // recipe: 'docx'
        //     },
        //     data: {
        //         nomorSurat: dataCetak.noSurat || '-',
        //         dataPeserta: dataPeserta,
        //         dataAkun: dataAkun,
        //         dataKepegawaian: getKepegawaian,
        //         dataKeluarga: dataKeluarga,
        //         produk: productName,
        //         perhitungan: perhitungan,
        //         tanggalSurat: formatDefaultFullDate(dataCetak.tanggalSurat || new Date())
        //     },
        // }

        // // return false
        // await reportFile(data, true, 'Surat-Cetak-Pensiun-' + dataPeserta.fullName + '.docx')
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)

                var src = formattedFileData.base64data
                var decoded = atob(src)

                if (decoded.length > 1000000) {
                    alert('File Size Tidak Boleh Melebihi dari 1Mb', 'Error');
                } else {
                    this.setState(prevState => ({
                        dataMaster: {
                            ...prevState.dataMaster,
                            [`${fieldName}Name`]: files.name,
                            // [`${fieldName}Extension`]: formatNameAndExtention.extention,
                            [`${fieldName}`]: formattedFileData.base64data,
                            [`${fieldName}ContentType`]: formattedFileData.mime,
                        }
                    }))
                }
            }

            fr.readAsDataURL(files)
        }
    }

    retrieveData = async (data = {}, perhitungan = {}, dataPeserta = {}, dataKepesertaanProduct = {}, status) => {
        if (status) {
            var loadAPI = `kepersertaan-products/${data.id}`
            var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            var dataAkun = { ...getAkun }
            dataAkun.peridoeNs = this.props.peridoeNs
            if (dataAkun.pensiunDate == null) {
                if (dataPeserta.masaKerjaBulan && dataPeserta.masaKerjaTahun) {
                    if (!this.state.dataMaster.masaKerja) {
                        dataAkun.masaKerja = dataPeserta.masaKerjaTahun + ' Tahun ' + dataPeserta.masaKerjaBulan + ' Bulan'
                    }
                }
            } else {
                let api = `peserta-pensiuns/getPerhitunganPensiun/${dataPeserta.id}/${dataAkun.productId}/${this.props.peridoeNs}`
                let result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, api, 'GET')
                dataAkun.masaKerja = result.masaKerja
                dataAkun.usiaPesertaPensiun = result.usiaPesertaPensiun
                dataAkun.mpSekaligusPeserta = result.mpSekaligusPeserta
                dataAkun.mpBulananPeserta = result.mpBulananPeserta
                dataAkun.mpSekaligusJadud = result.mpSekaligusJadud
                dataAkun.mpBulananJadud = result.mpBulananJadud
                dataAkun.mpSekaligusAnak = result.mpSekaligusAnak
                dataAkun.mpBulananAnak = result.mpBulananAnak
                dataAkun.nilaiSekarang = result.nilaiSekarang
                dataAkun.nilaiSekaligusPeserta = result.nilaiSekaligusPeserta
            }

            if (perhitungan) {
                if (!perhitungan.masaKerja) {
                    if (dataAkun.pensiunDate == null) {
                        if (dataPeserta.masaKerjaBulan && dataPeserta.masaKerjaTahun) {
                            if (!this.state.dataMaster.masaKerja) {
                                dataAkun.masaKerja = dataPeserta.masaKerjaTahun + ' Tahun ' + dataPeserta.masaKerjaBulan + ' Bulan'
                            }
                        }
                    } else {
                        let api = `peserta-pensiuns/getPerhitunganPensiun/${dataPeserta.id}/${dataAkun.productId}/${this.props.peridoeNs}`
                        let result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, api, 'GET')
                        dataAkun.masaKerja = result.masaKerja
                        dataAkun.usiaPesertaPensiun = result.usiaPesertaPensiun
                        dataAkun.mpSekaligusPeserta = result.mpSekaligusPeserta
                        dataAkun.mpBulananPeserta = result.mpBulananPeserta
                        dataAkun.mpSekaligusJadud = result.mpSekaligusJadud
                        dataAkun.mpBulananJadud = result.mpBulananJadud
                        dataAkun.mpSekaligusAnak = result.mpSekaligusAnak
                        dataAkun.mpBulananAnak = result.mpBulananAnak
                        dataAkun.nilaiSekarang = result.nilaiSekarang
                        dataAkun.nilaiSekaligusPeserta = result.nilaiSekaligusPeserta
                    }
                    // else {
                    //     if (dataAkun.pensiunDate && dataAkun.efektifDate) {
                    //         var date1 = new Date(dataAkun.efektifDate)
                    //         var date2 = new Date(dataAkun.pensiunDate)
                    //         var diffYears = yearsDiff(dataAkun.pensiunDate, dataAkun.efektifDate)
                    //         var diffMonths = Math.abs(date2.getMonth() - date1.getMonth());
                    //         var masaKerja
                    //         if (diffYears && diffMonths) {
                    //             masaKerja = `${diffYears} Tahun ${diffMonths} Bulan`
                    //         }

                    //         if (!diffYears && diffMonths) {
                    //             masaKerja = `${diffMonths} Bulan`
                    //         }

                    //         if (diffYears && !diffMonths) {
                    //             masaKerja = `${diffYears} Tahun`
                    //         }

                    //         dataAkun.masaKerja = masaKerja
                    //     }
                    // }
                } else {
                    if (dataPeserta.masaKerjaBulan && dataPeserta.masaKerjaTahun) {
                        if (!this.state.dataMaster.masaKerja) {
                            perhitungan.masaKerja = dataPeserta.masaKerjaTahun + ' Tahun ' + dataPeserta.masaKerjaBulan + ' Bulan'
                        }
                    }
                }

                if (perhitungan.usiaPesertaPensiun) {
                    dataAkun.usiaPesertaPensiun = perhitungan.usiaPesertaPensiun
                } else {
                    dataAkun.usiaPesertaPensiun = dataAkun.usiaPesertaPensiun
                }
            }
            dataAkun.mpRekeningNo = dataAkun.mpRekeningNo || dataPeserta.rekeningNo
            dataAkun.mpRekeningName = dataAkun.mpRekeningName || dataPeserta.rekeningNama
            dataAkun.mpRekeningBankId = dataPeserta.rekeningBankId
            // dataAkun.mpRekeningBankId = dataAkun.mpRekeningBankId || dataPeserta.rekeningBankId

            var penerimaFamily = this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId')

            try {
                var loadAPIBank = `banks/${dataAkun.mpRekeningBankId}`
                var getBank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIBank, 'GET')

                dataAkun.mpRekeningBankName = getBank.initialName
            } catch (e) {
                console.log(e);
                await alert("Gagal Mengambil data bank", 'Validasi!')
            }

            var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${dataPeserta.id}`
            var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKeluarga, 'GET')

            this.dataKeluargaByPesertaId = getKeluarga
            penerimaFamily.editorOptions.dataSource = getKeluarga
            this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', penerimaFamily)
            if (dataAkun.akunStatusId == 1 || dataAkun.akunStatusId == 11) {
                dataAkun.mpPaymentTypeId = dataAkun.mpPaymentTypeId === 0 ? null : dataAkun.mpPaymentTypeId
                dataAkun.paymentRekbankId = dataAkun.paymentRekbankId === 0 ? null : dataAkun.paymentRekbankId
                this.formRef.current.instance.updateData(dataAkun)
                this.setState({
                    dataPeserta: dataPeserta
                })
                this.formRef.current.instance.beginUpdate()
                penerimaFamily.isRequired = true
                penerimaFamily.validationRules = []
                // codingan ga jelas
                // this.formRef.current.instance.itemOption('master.pensiunDate', 'readOnly', false)
                // this.formRef.current.instance.itemOption('mpPenerimaTypeId', 'isRequired', true)
                // this.formRef.current.instance.itemOption('master.pensiunType', 'isRequired', true)
                // this.formRef.current.instance.itemOption('master.pensiunType', 'validationRules', [])
                // this.formRef.current.instance.itemOption('penerima.penerimaMp.pesertaId', 'isRequired', false)
                // this.formRef.current.instance.itemOption('penerima.penerimaMp.pesertaId', 'validationRules', [])


                this.formRef.current.instance.endUpdate()
            } else {
                var mpPenerimaType = dataAkun.mpPenerimaTypeId
                // var mpPaymentType = dataAkun.mpPaymentTypeId
                var formulaPph21 = dataPeserta.npwp ? 5 / 100 : 6 / 100
                var penerimaFamilyId = this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId')
                dataAkun.pajakPph21MpSekaligus = 0
                if (mpPenerimaType == 1) {
                    penerimaFamilyId.isRequired = false
                    penerimaFamilyId.validationRules = []
                    penerimaFamilyId.editorOptions.readOnly = true
                    penerimaFamilyId.editorOptions.placeholder = ""
                    penerimaFamilyId.editorOptions.dataSource = []
                    this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', penerimaFamilyId)
                } else {
                    penerimaFamilyId.isRequired = true
                    penerimaFamilyId.validationRules = [{
                        type: "required",
                        message: "Data Harus Diisi"
                    }]
                    penerimaFamilyId.editorOptions.readOnly = false
                    penerimaFamilyId.editorOptions.placeholder = "Pilih Penerima"
                    penerimaFamilyId.editorOptions.dataSource = this.dataKeluargaByPesertaId
                    this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', penerimaFamilyId)

                }
                if (!dataAkun.mpBulanan && !dataAkun.mpSekaligus20 && !dataAkun.mpBulanan80) {
                    var getPenguranganPph = this.globalParam.find(val => val.paramCode == '2 Tax MPS')
                    var mpBulananNoRound
                    switch (mpPenerimaType) {
                        case 1:
                            mpBulananNoRound = dataAkun.mpBulananPeserta || 0
                            dataAkun.mpBulanan = dataAkun.mpBulananPeserta || 0
                            dataAkun.mpSekaligus = dataAkun.mpSekaligusPeserta || 0
                            dataAkun.mpSekaligus20 = dataAkun.mpSekaligusPeserta * 20 / 100 || 0
                            dataAkun.mpBulanan80 = dataAkun.mpBulananPeserta * 80 / 100 || 0

                            if (getPenguranganPph.intValue < dataAkun.mpSekaligusPeserta * 20 / 100) {
                                dataAkun.pajakPph21MpSekaligus20 = (pembulatan(dataAkun.mpSekaligusPeserta * 20 / 100, 2) - getPenguranganPph.intValue) * formulaPph21
                            }

                            if (getPenguranganPph.intValue < dataAkun.mpSekaligusPeserta) {
                                dataAkun.pajakPph21MpSekaligus = (pembulatan(dataAkun.mpSekaligusPeserta, 2) - getPenguranganPph.intValue) * formulaPph21
                            }
                            break;
                        case 2:
                            mpBulananNoRound = dataAkun.mpBulananJadud || 0
                            dataAkun.mpBulanan = dataAkun.mpBulananJadud || 0
                            dataAkun.mpSekaligus = dataAkun.mpSekaligusJadud || 0
                            dataAkun.mpSekaligus20 = dataAkun.mpSekaligusJadud * 20 / 100 || 0
                            dataAkun.mpBulanan80 = dataAkun.mpBulananJadud * 80 / 100 || 0

                            if (getPenguranganPph.intValue < dataAkun.mpSekaligusJadud * 20 / 100) {
                                dataAkun.pajakPph21MpSekaligus20 = (pembulatan(dataAkun.mpSekaligusJadud * 20 / 100, 2) - getPenguranganPph.intValue) * formulaPph21
                            }
                            if (getPenguranganPph.intValue < dataAkun.mpSekaligusJadud) {
                                dataAkun.pajakPph21MpSekaligus = (pembulatan(dataAkun.mpSekaligusJadud, 2) - getPenguranganPph.intValue) * formulaPph21
                            }
                            break;
                        case 3:
                            mpBulananNoRound = dataAkun.mpBulananAnak || 0
                            dataAkun.mpBulanan = dataAkun.mpBulananAnak || 0
                            dataAkun.mpSekaligus = dataAkun.mpSekaligusAnak || 0
                            dataAkun.mpSekaligus20 = dataAkun.mpSekaligusAnak * 20 / 100 || 0
                            dataAkun.mpBulanan80 = dataAkun.mpBulananAnak * 80 / 100 || 0

                            if (getPenguranganPph.intValue < dataAkun.mpSekaligusAnak * 20 / 100) {
                                dataAkun.pajakPph21MpSekaligus20 = (pembulatan(dataAkun.mpSekaligusAnak * 20 / 100, 2) - getPenguranganPph.intValue) * formulaPph21
                            }
                            if (getPenguranganPph.intValue < dataAkun.mpSekaligusAnak) {
                                dataAkun.pajakPph21MpSekaligus = (pembulatan(dataAkun.mpSekaligusAnak, 2) - getPenguranganPph.intValue) * formulaPph21
                            }
                            break;
                        case 4:
                            mpBulananNoRound = dataAkun.mpBulananPeserta || 0
                            dataAkun.mpBulanan = dataAkun.mpBulananPeserta || 0
                            dataAkun.mpSekaligus = dataAkun.mpSekaligusPeserta || 0
                            dataAkun.mpSekaligus20 = dataAkun.mpSekaligusPeserta * 20 / 100 || 0
                            dataAkun.mpBulanan80 = dataAkun.mpBulananPeserta * 80 / 100 || 0

                            dataAkun.pajakPph21MpSekaligus = (pembulatan(dataAkun.mpSekaligusPeserta, 2) * 20 / 100) - getPenguranganPph.intValue * formulaPph21
                            if (getPenguranganPph.intValue < dataAkun.mpSekaligusPeserta) {
                                dataAkun.pajakPph21MpSekaligus = (pembulatan(dataAkun.mpSekaligusPeserta, 2) - getPenguranganPph.intValue) * formulaPph21
                            }
                            break;
                        default:
                            mpBulananNoRound = dataAkun.mpBulananPeserta
                            dataAkun.mpSekaligus20 = 0
                            dataAkun.mpBulanan80 = 0

                            dataAkun.pajakPph21MpSekaligus = 0
                    }

                    dataAkun.mpBulananNoRound = mpBulananNoRound
                    if (dataAkun.pajakPph21MpSekaligus20) {
                        dataAkun.pajakPph21MpSekaligusBulat20 = pembulatan(dataAkun.pajakPph21MpSekaligus20, 2)
                    } else {
                        dataAkun.pajakPph21MpSekaligusBulat20 = 0
                    }
                    if (dataAkun.pajakPph21MpSekaligus) {
                        dataAkun.pajakPph21MpSekaligusBulat = pembulatan(dataAkun.pajakPph21MpSekaligus, 2)
                    } else {
                        dataAkun.pajakPph21MpSekaligusBulat = 0
                    }

                    if (dataAkun.mpBulanan > 2000000) {
                        // dataAkun.mpSekaligus20 = dataAkun.mpSekaligus
                        dataAkun.mpSekaligus = 0
                    }
                }

                var mpBulananNoRound
                if (perhitungan) {
                    switch (mpPenerimaType) {
                        case 1:
                            // mpBulananNoRound = dataAkun.mpBulananPeserta || 0
                            mpBulananNoRound = perhitungan.mpBulananPeserta || 0
                            break;
                        case 2:
                            // mpBulananNoRound = dataAkun.mpBulananJadud || 0
                            mpBulananNoRound = perhitungan.mpBulananJadud || 0
                            break;
                        case 3:
                            // mpBulananNoRound = dataAkun.mpBulananAnak || 0
                            mpBulananNoRound = perhitungan.mpBulananAnak || 0
                            break;
                        case 4:
                            // mpBulananNoRound = dataAkun.mpBulananPeserta || 0
                            mpBulananNoRound = perhitungan.mpBulananPeserta || 0
                            break;
                        default:
                            // mpBulananNoRound = dataAkun.mpBulananPeserta
                            mpBulananNoRound = perhitungan.mpBulananPeserta
                    }

                    dataAkun.mpBulananNoRound = mpBulananNoRound
                    this.formRef.current.instance.updateData({ ...dataAkun, ...perhitungan })
                    this.setState({
                        dataPerhitungan: { ...perhitungan },
                        dataPeserta: dataPeserta,
                        data: data
                    })
                } else {
                    switch (mpPenerimaType) {
                        case 1:
                            mpBulananNoRound = dataAkun.mpBulananPeserta || 0
                            break;
                        case 2:
                            mpBulananNoRound = dataAkun.mpBulananJadud || 0
                            break;
                        case 3:
                            mpBulananNoRound = dataAkun.mpBulananAnak || 0
                            break;
                        case 4:
                            mpBulananNoRound = dataAkun.mpBulananPeserta || 0
                            break;
                        default:
                            mpBulananNoRound = dataAkun.mpBulananPeserta
                    }

                    dataAkun.mpBulananNoRound = mpBulananNoRound
                    this.formRef.current.instance.updateData(dataAkun)
                    this.setState({
                        dataPerhitungan: null,
                        dataPeserta: dataPeserta,
                        data: data
                    })
                }
                this.formRef.current.instance.beginUpdate()
                this.formRef.current.instance.itemOption('master.pensiunDate', 'readOnly', true)
                this.formRef.current.instance.itemOption('pensiunDate', 'isRequired', false)
                this.formRef.current.instance.itemOption('master.pensiunType', 'isRequired', true)
                this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaTypeId', 'isRequired', false)
                this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaTypeId', 'validationRules', [])
                this.formRef.current.instance.endUpdate()
            }
            if (!this.props.pensiunan) {
                this.PopupRef.current.instance.option("toolbarItems", this.PopupToolbarItemAktif)
            } else {
                this.PopupRef.current.instance.option("toolbarItems", this.PopupToolbarItemPensiunanPasif)
            }
        } else {
            var dataMaster = { ...this.state.dataMaster, ...data }
            var loadAPI = `peserta-pensiuns/loadDataRegistPensiun/${dataPeserta.nik}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            var familyPenerima = this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId')
            var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${dataPeserta.id}`
            var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKeluarga, 'GET')
            this.dataKeluargaByPesertaId = getKeluarga

            if(response[0].penerima === 1){
                familyPenerima.editorOptions.dataSource = []
                this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', familyPenerima)
            }else{
                familyPenerima.editorOptions.dataSource = getKeluarga
                this.formRef.current.instance.itemOption('penerima.penerimaMp.mpPenerimaPesertaFamilyTypeId', familyPenerima)
            }

            // dataMaster.tanggal_berhenti = response[0].tanggal_berhenti
            dataMaster.tanggal_berhenti = dataPeserta.tanggalBerhenti
            dataMaster.pensiunDate = response[0].tanggal_pensiun
            dataMaster.mpRekeningCabang = response[0].cabang
            dataMaster.mpPenerimaTypeId = response[0].penerima
            dataMaster.pajakPph21MpSekaligus = response[0].nilai_pajak_pph_21_mp_sekaligus_100
            // dataMaster. = response[0].status_description
            dataMaster.pensiunType = response[0].jenis_pensiun
            dataMaster.skPensiunNo = response[0].no_sk_pensiun
            dataMaster.skPemberhentianNo = response[0].no_sk_phk
            dataMaster.pesertaPhkTypeId = response[0].jenis_phk
            dataMaster.mpPaymentTypeId = response[0].pembayaran_mp
            dataMaster.masaKerja = `${response[0].masa_kerja_tahun ? response[0].masa_kerja_tahun : 0} tahun ${response[0].masa_kerja_bulan ? response[0].masa_kerja_bulan : 0} bulan`
            // dataMaster.usiaPesertaPensiun = `${response[0].usia_pensiun_tahun} tahun ${response[0].usia_pensiun_bulan} bulan`
            dataMaster.usiaPesertaPensiun = dataMaster.pensiunType === 4 && dataMaster.mpPenerimaTypeId !== 1 ? '56 Tahun 0 bulan' : `${response[0].usia_pensiun_tahun ? response[0].usia_pensiun_tahun : 0} tahun ${response[0].usia_pensiun_bulan ? response[0].usia_pensiun_bulan : 0} bulan`
            dataMaster.usiaPenerimaMp = `${response[0].usia_penerima_tahun ? response[0].usia_penerima_tahun : 0} tahun ${response[0].usia_penerima_bulan ? response[0].usia_penerima_bulan : 0} bulan`
            dataMaster.peridoeNs = response[0].ns_periode || this.props.peridoeNs
            dataMaster.mpPenerimaPesertaFamilyTypeId = response[0].keluarga_penerima_mp
            dataMaster.mpPertamaDate = response[0].tanggal_mp_pertama
            dataMaster.mpRekeningNo = response[0].no_rek_mp
            dataMaster.mpRekeningName = response[0].nama_rek_mp
            dataMaster.mpSekaligusPeserta = response[0].nilai_mp_sekaligus_peserta
            dataMaster.mpBulananPeserta = response[0].nilai_mp_bulanan_peserta
            dataMaster.mpSekaligusJadud = response[0].nilai_mp_sekaligus_jadud
            dataMaster.mpBulananJadud = response[0].nilai_mp_bulanan_jadud
            dataMaster.mpBulananAnak = response[0].nilai_mp_bulanan_anak
            dataMaster.paymentRekbankId = response[0].rekening_pembayar
            dataMaster.mpSekaligusAnak = response[0].nilai_mp_sekaligus_anak
            // dataMaster. = response[0].manfaat_pensiun_di_transfer
            dataMaster.mpSekaligusNoRound = response[0].nilai_mp_sekaligus_100
            dataMaster.mpSekaligus = response[0].nilai_mp_sekaligus_100_bulat
            dataMaster.mpBulananNoRound = response[0].nilai_mp_bulanan_100
            dataMaster.mpBulanan = response[0].nilai_mp_bulanan_100_bulat
            dataMaster.mpSekaligus20NoRound = response[0].nilai_mp_sekaligus_20
            dataMaster.mpSekaligus20 = response[0].nilai_mp_sekaligus_20_bulat
            dataMaster.mpBulanan80NoRound = response[0].nilai_mp_bulanan_80
            dataMaster.mpBulanan80 = response[0].nilai_mp_bulanan_80_bulat
            dataMaster.pajakPph21MpSekaligus20 = response[0].nilai_pajak_pph_21_mp_sekaligus_20
            dataMaster.mpRekeningBankId = response[0].rekening_bank_pembayaran
            dataMaster.tglSkPensiun = response[0].tanggal_sk_pensiun
            dataMaster.tglSkPemberhentian = response[0].tanggal_sk_phk
            // dataMaster.mpRekeningBankId = dataPeserta.rekeningBankId
            // dataMaster. = response.status

            // var fullDataKepesertaanProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/${data.id}`, 'GET')
            // var dataMaster = { ...this.state.dataMaster, ...data }
            // let loadAPI = `peserta-pensiuns/calculatePensiunByFunction/1/${fullDataKepesertaanProduct.productId}/${dataPeserta.nik}/${this.props.peridoeNs}/${dataKepesertaanProduct.pensiunTypeId}/${formatDate(dataKepesertaanProduct.pensiunDate)}/${fullDataKepesertaanProduct.mpPenerimaTypeId}/${fullDataKepesertaanProduct.mpPenerimaTypeId > 1 ? fullDataKepesertaanProduct.mpPenerimaPesertaFamilyTypeId : 0}/${fullDataKepesertaanProduct.mpPaymentTypeId}/false`
            // var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            // dataMaster.productId = fullDataKepesertaanProduct.productId
            // dataMaster.pensiunType = fullDataKepesertaanProduct.pensiunType
            // dataMaster.skPensiunNo = fullDataKepesertaanProduct.skPensiunNo
            // dataMaster.skPemberhentianNo = fullDataKepesertaanProduct.skPemberhentianNo
            // dataMaster.pesertaPhkTypeId = fullDataKepesertaanProduct.pesertaPhkTypeId
            // dataMaster.mpPaymentTypeId = fullDataKepesertaanProduct.mpPaymentTypeId
            // dataMaster.pensiunDate = fullDataKepesertaanProduct.pensiunDate
            // dataMaster.mpPenerimaTypeId = fullDataKepesertaanProduct.mpPenerimaTypeId
            // dataMaster.mpPenerimaPesertaFamilyTypeId = fullDataKepesertaanProduct.mpPenerimaPesertaFamilyTypeId
            // dataMaster.mpPertamaDate = fullDataKepesertaanProduct.mpPertamaDate
            // dataMaster.mpRekeningNo = fullDataKepesertaanProduct.mpRekeningNo
            // dataMaster.mpRekeningName = fullDataKepesertaanProduct.mpRekeningName
            // dataMaster.mpRekeningBankId = fullDataKepesertaanProduct.mpRekeningBankId
            // dataMaster.mpRekeningCabang = fullDataKepesertaanProduct.mpRekeningCabang
            // dataMaster.paymentRekbankId = fullDataKepesertaanProduct.paymentRekbankId
            // dataMaster.peridoeNs = fullDataKepesertaanProduct.nsPeriode
            // dataMaster.masaKerja = `${response[0].masa_kerja_thn} tahun ${response[0].masa_kerja_bln} bulan`
            // dataMaster.usiaPesertaPensiun = `${response[0].usia_penerima_thn} tahun ${response[0].usia_penerima_bln} bulan`
            // dataMaster.mpSekaligusPeserta = response[0].mp_sekaligus_100_ori
            // dataMaster.mpBulananPeserta = response[0].mp_bulanan_100_ori
            // dataMaster.mpSekaligusJadud = response[0].mp_bulanan_80
            // dataMaster.mpBulananJadud = response[0].mp_sekaligus_100
            // dataMaster.mpSekaligusAnak = response[0].mp_sekaligus_20_ori
            // dataMaster.mpBulananAnak = response[0].mp_bulanan_20_ori

            // dataMaster.mpSekaligusNoRound = response[0].mp_sekaligus_100_ori
            // dataMaster.mpSekaligus = response[0].mp_sekaligus_100
            // dataMaster.mpBulananNoRound = response[0].mp_bulanan_100_ori
            // dataMaster.mpBulanan = response[0].mp_bulanan_100
            // dataMaster.mpSekaligus20NoRound = response[0].mp_sekaligus_20_ori
            // dataMaster.mpSekaligus20 = response[0].mp_sekaligus_20
            // dataMaster.mpBulanan80NoRound = response[0].mp_bulanan_80_ori
            // dataMaster.mpBulanan80 = response[0].mp_bulanan_80
            // dataMaster.pajakPph21MpSekaligus = response[0].mps_100_pajak_npwp
            // dataMaster.pajakPph21MpSekaligus20 = response[0].mps_20_pajak_npwp
            this.formRef.current.instance.updateData(dataMaster)

            this.setState({
                dataPeserta: dataPeserta
            })
        }
    }

    submitData = async (data = {}) => {
        try {
            var dataPeserta = this.state.dataPeserta
            let masaKerja = data.masaKerja
            var mkTahun = parseInt(masaKerja.split('tahun')[0])
            var mkBulan = masaKerja.split('tahun')[1] ? parseInt((masaKerja.split('tahun')[1]).split('bulan')[0]) : parseInt(masaKerja.split('tahun')[1])

            let usia = data.usiaPesertaPensiun
            var usiaTahun = parseInt(usia.split('tahun')[0])
            var usiaBulan = usia.split('tahun')[1] ? parseInt((usia.split('tahun')[1]).split('bulan')[0]) : parseInt(usia.split('tahun')[1])

            let usiaPenerimaMp = data.usiaPenerimaMp;
            let usiaPenerimaMpThn = parseInt(usiaPenerimaMp.split('tahun')[0]);
            let usiaPenerimaMpBln = usiaPenerimaMp.split('tahun')[1] ? parseInt((usiaPenerimaMp.split('tahun')[1]).split('bulan')[0]) : parseInt(usiaPenerimaMp.split('tahun')[1]);
            let dateBerhenti = new Date(data.tanggal_berhenti);

            var dataSend = {
                cabang: data.mpRekeningCabang || "",
                fileJadud: data.fileKeteranganJadudPenerimaMp,
                fileJadudContentType: data.fileKeteranganJadudPenerimaMpContentType,
                fileKartuKeluarga: data.fileKkPenerimaMp,
                fileKartuKeluargaContentType: data.fileKkPenerimaMpContentType,
                fileKtpPenerima: data.fileKtpPenerimaMp,
                fileKtpPenerimaContentType: data.fileKtpPenerimaMpContentType,
                filePengantarPilihan: data.filePengantar,
                filePengantarPilihanContentType: data.filePengantarContentType,
                filePensiunSk: data.fileSkPensiun,
                filePensiunSkContentType: data.fileSkPensiunContentType,
                filePhkSk: data.fileSkPhk,
                filePhkSkContentType: data.fileSkPhkContentType,
                fileSekolah: data.fileKeteranganSekolahPenerimaMp,
                fileSekolahContentType: data.fileKeteranganSekolahPenerimaMpContentType,
                fileSptb: data.fileSptb,
                fileSptbContentType: data.fileSptbContentType,
                fileSuratPernyataan: data.fileSuratPernyataan,
                fileSuratPernyataanContentType: data.fileSuratPernyataanContentType,
                jenisPensiun: data.pensiunType || 0,
                jenisPhk: data.pesertaPhkTypeId || 0,
                keluargaPenerimaMp: data.mpPenerimaPesertaFamilyTypeId || 0,
                masaKerjaBulan: mkBulan || 0,
                masaKerjaTahun: mkTahun || 0,
                namaRekMp: data.mpRekeningName || "",
                nik: dataPeserta.nik || "",
                nilaiMpBulananAnak: data.mpBulananAnak || 0,
                nilaiMpBulananJadud: data.mpBulananJadud || 0,
                nilaiMpBulananPeserta: data.mpBulananPeserta || 0,
                nilaiMpSekaligusJadud: data.mpSekaligusJadud || 0,
                nilaiMpSekaligusPeserta: data.mpSekaligusPeserta || 0,
                noRekMp: data.mpRekeningNo || "",
                noSkPensiun: data.skPensiunNo || "",
                tanggalSkPensiun: data.tglSkPensiun || "",
                noSkPhk: data.skPemberhentianNo || "",
                tanggalSkPhk: data.tglSkPemberhentian || "",
                nsPeriode: this.props.peridoeNs || 0,
                pembayaranMp: data.mpPaymentTypeId || 0,
                rekeningBankPembayaran: data.mpRekeningBankId || 0,
                rekeningPembayar: data.paymentRekbankId || 0,
                tanggalMpPertama: data.mpPertamaDate !== null ? formatDate(data.mpPertamaDate) : null,
                tanggalPensiun: formatDate(data.pensiunDate),
                tanggalBerhenti: formatDate(dateBerhenti),
                // tanggalBerhenti: formatDate(data.tanggal_berhenti),
                tipePenerima: data.mpPenerimaTypeId || 0,
                usiaPensiunBulan: usiaBulan || 0,
                usiaPensiunTahun: usiaTahun || 0,
                usiaPenerimaBln: usiaPenerimaMpBln || 0,
                usiaPenerimaThn: usiaPenerimaMpThn || 0
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-pensiuns/registrasiPensiunByFunction`, 'POST', {
                values: dataSend
            })
            if (response) {
                await alert("Sukses Menyimpan Data", 'Sukses!')
                this.hide();
                this.props.forceRefresh();
            }
        } catch (e) {
            console.log(e);
            await alert(e, 'Gagal!')
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Action Data Pensiun'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.props.pensiunan ? this.PopupToolbarItemPensiunanPasif : this.PopupToolbarItemAktif}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation='left'
                                    readOnly={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-12">
                                <h5>Dokumen</h5>
                            </div>

                            {/* fileSkPensiun */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSkPensiunName || 'File SK Pensiun'}
                                    showFileList={false}
                                    name={'fileSkPensiun'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSkPensiun')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPensiun ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSkPensiun')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPensiun ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileSkPhk */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSkPhkName || 'File SK PHK'}
                                    showFileList={false}
                                    name={'fileSkPhk'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSkPhk')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPhk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSkPhk')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPhk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* filePengantar */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.filePengantarName || 'File Pengantar Pilihan'}
                                    showFileList={false}
                                    name={'filePengantar'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('filePengantar')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.filePengantar ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('filePengantar')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.filePengantar ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileSuratPernyataan */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSuratPernyataanName || 'File Surat Pernyataan'}
                                    showFileList={false}
                                    name={'fileSuratPernyataan'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSuratPernyataan')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSuratPernyataan ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSuratPernyataan')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSuratPernyataan ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileKeteranganJadudPenerimaMp */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileKeteranganJadudPenerimaMpName || 'File Janda Duda'}
                                    showFileList={false}
                                    name={'fileKeteranganJadudPenerimaMp'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileKeteranganJadudPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKeteranganJadudPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileKeteranganJadudPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKeteranganJadudPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileKeteranganSekolahPenerimaMp */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileKeteranganSekolahPenerimaMpName || 'File Sekolah'}
                                    showFileList={false}
                                    name={'fileKeteranganSekolahPenerimaMp'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileKeteranganSekolahPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKeteranganSekolahPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileKeteranganSekolahPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKeteranganSekolahPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileKkPenerimaMp */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileKkPenerimaMpName || 'File Kartu Keluarga'}
                                    showFileList={false}
                                    name={'fileKkPenerimaMp'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileKkPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKkPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileKkPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKkPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileKtpPenerimaMp */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileKtpPenerimaMpName || 'File KTP Penerima'}
                                    showFileList={false}
                                    name={'fileKtpPenerimaMp'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileKtpPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKtpPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileKtpPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKtpPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileSptb */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSptbName || 'File SPTB'}
                                    showFileList={false}
                                    name={'fileSptb'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSptb')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSptb ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSptb')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSptb ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
                <ModalNoSuratCetak
                    ref={this.modalCetakRef}
                    store={this.props.store}
                    cetak={this.cetak}
                />
            </Popup>
        );
    }
}


export default ProsesDataPensiunPasif
