import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { reksadana, emitens, currency } from 'dataSource/lookup';

class DetailPengalihanReksadana extends Component {
    constructor(props){
        super(props)
        this.dataSource  = new CustomStore({
            key: 'id',
            load: (loadOptions) => {
                return this.props.detailData
            },
            insert: (values) => {
            },
            update: (key,values) => {
               
            },
            remove: (key) => {
               
            }
        })

        this.columns = [
            {
                dataField: 'instrumentId',
                caption: 'Instrumen Code',
                width: '150px',
                lookup : {
                    dataSource: reksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode',
                }
            },
            {
                dataField: 'instrumentName',
                caption: 'Instrumen Name',
                width: '150px',
            },
            {
                dataField: 'emitenId',
                caption: 'Penerbit Reksadana',
                width: '150px',
                lookup : {
                    dataSource: emitens(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function(item){
                        return item && item.emitenCode + " - " + item.emitenName;
                    },
                }
            },
            {
                dataField: 'currencyId',
                caption:'Mata Uang',
                format: '#,##0',
                width: '150px',
                lookup : {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr : 'currencyCode',
                }
            },
            {
                dataField: 'kurs',
                caption:'Kurs',
                format: '#,##0',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0"
                },
            },
            {
                dataField: 'unit',
                caption:'Unit Portofolio',
                format: '#,##0.0000',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.0000"
                },
            },
            {
                dataField: 'nab',
                caption:'NAB Perolehan',
                format: '#,##0.0000',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.0000"
                },
            },
            {
                dataField: 'nilaiPerolehan',
                caption:'Nilai Perolehan',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },
            {
                dataField: 'nabTerakhir',
                caption:'NAB Terakhir',
                format: '#,##0.0000',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.0000"
                },
            },
            {
                dataField: 'portofolioPengalihan',
                caption:'Portofolio Pengalihan',
            },
            {
                dataField: 'prosentasePengalihan',
                caption:'Prosentase Pengalihan',
                format: '#,##0.00 %',
                width: '100px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00 %"
                },
            },
            {
                dataField: 'nominalPengalihan',
                caption:'Nominal Pengalihan (NAB Terakhir)',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },
            {
                dataField: 'feePercent',
                caption:'Fee (%)',
                format: '#,##0.00 %',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00 %"
                },
            },
            {
                dataField: 'fee',
                caption:'Fee Nominal',
                format: '#,##0.00',
                width: '150px',
            },
            {
                dataField: 'totalPengalihan',
                caption:'Total Pengalihan',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },
            {
                dataField: 'reksadanaTujuan',
                caption:'Reksadana Tujuan',
                format: '#,##0',
                width: '150px',
            },
        ]
    }

    render() {
        return (
            <DataGrid
            id={"successDataGrid"}
            showBorders={false}
            dataSource={this.dataSource}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            // height={100}
            showScrollbar={"always"}
            // columns={this.columns}
            >
            {
                this.columns.map((column) => {
                    return <Column 
                        dataField={column.dataField} 
                        caption={column.caption} 
                        lookup={column.lookup} 
                        cellRender={column.cellRender} 
                        alignment={column.alignment || 'left'} 
                        cssClass={column.cssClass}
                        format = {column.format}
                        dataType = {column.dataType}
                        width = {column.width}
                        type = {column.type}
                        buttons = {column.buttons}
                        editorType = {column.editorType}
                        editorOptions = {column.editorOptions}
                        visible = {column.visible}
                        sortOrder = {column.sortOrder}
                        allowEditing = {column.allowEditing}
                        calculateCellValue = {column.calculateCellValue}
                    />
                })
            }
            {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPengalihanReksadana