import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, rekeningBank, productDefault, kpds, instrumentTransactionTypes } from 'dataSource/lookup';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import notify from 'devextreme/ui/notify'


class ModalSettlementKonfirmasiPembayaran extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Konfirmasi',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Informasi Transaksi',
                        items: [
                            {
                                dataField: 'rekPembayaran',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Pembayaran'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'noInstruksi',
                                label: {
                                    alignment: 'left',
                                    text: 'No. Instruksi'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'tipeTransaksiId',
                                label: {
                                    alignment: 'left',
                                    text: 'Tipe Transaksi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: instrumentTransactionTypes(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'transactionName',
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'totalKewajiban',
                                label: {
                                    alignment: 'left',
                                    text: 'Total Kewajiban'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'produkId',
                                label: {
                                    alignment: 'left',
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'productCode',
                                    onValueChanged: async (e) => {
                                        var dataRek = await this.rekeningBankByProduct(this.props.store, e.value)
                                        this.formRef.current.instance.getEditor('sourceRekBank').option('dataSource', dataRek)
                                    },
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'kpdId',
                                label: {
                                    alignment: 'left',
                                    text: 'KPD'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kpds(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'noKontrak',
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'jmlTransaksi',
                                label: {
                                    alignment: 'left',
                                    text: 'Jumlah Transaksi'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    alignment: 'left',
                                    text: 'Mata Uang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyCode',
                                    onSelectionChanged: (e) => {
                                        var data = e.selectedItem
                                        if (this.state.dataMaster.currencycRate == null) {
                                            this.formRef.current.instance.updateData('currencycRate', data.rate)
                                        }
                                    },
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'efekId',
                                label: {
                                    alignment: 'left',
                                    text: 'Efek'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Parameter',
                        items: [
                            {
                                dataField: 'tglPembayaran',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Pembayaran',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'noKonfirmasi',
                                label: {
                                    alignment: 'left',
                                    text: 'No. Konfirmasi Pembayaran'
                                }
                            },
                            {
                                dataField: 'sourceRekBank',
                                label: {
                                    alignment: 'left',
                                    text: 'Dibayarkan dari rekening'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    },
                                    onSelectionChanged: (e) => {
                                        var data = e.selectedItem
                                        if (data) {
                                            this.formRef.current.instance.updateData('rekPembayaran', data.rekNo)
                                        }
                                    },
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField: 'nominalPembayaran',
                                label: {
                                    alignment: 'left',
                                    text: 'Nominal Pembayaran'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var bb = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        var np = e.value
                                        this.formRef.current.instance.updateData('totalPembayaran', bb + np)
                                    }
                                }
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    alignment: 'left',
                                    text: 'Biaya Bank/RTGS'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var bb = e.value
                                        var np = this.formRef.current.instance.getEditor('nominalPembayaran').option('value')
                                        this.formRef.current.instance.updateData('totalPembayaran', bb + np)
                                    }
                                }
                            },
                            {
                                dataField: 'totalPembayaran',
                                label: {
                                    alignment: 'left',
                                    text: 'Total Pembayaran'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                            {
                                dataField: 'currencycRate',
                                label: {
                                    alignment: 'left',
                                    text: 'Currency Rate'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                            {
                                dataField: 'file',
                                label: {
                                    alignment: 'left',
                                    text: 'File Konfirmasi'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    }
                ]
            }
        ]
    }
    rekeningBankByProduct = async (store, productId) => {
        let loadAPI = `rek-banks-by-productId?productId=${productId}`
        var getRek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRek,
            key: 'id'
        });

        return dataSource
    }
    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: data
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }
    previewFile = async () => {
        var src = imageSource(this.state.fileBase64ContentType, this.state.fileBase64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Base64`]: formattedFileData.base64data,
                    [`${fieldName}Base64ContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    submitData = async () => {
        var dataSubmit = this.state.dataMaster
        var uploadFile = {
            fileName: this.state.fileName,
            fileBase64: this.state.fileBase64,
            fileBase64ContentType: this.state.fileBase64ContentType,
        }
        if (uploadFile) {
            try {
                var responseFile = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    'file-investasis',
                    'POST', {
                    values: uploadFile
                }
                )
                if (responseFile) {
                    this.submit(responseFile.id)
                } else {
                    notify({ message: 'Terjadi Kesalahan Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (e) {

            }
        }
    }
    submit = async (fileKonfirmasiId) => {
        var data = this.state.dataMaster
        var dataMaster = {
            biayaBank: data.biayaBank,
            currencyId: data.currencyId,
            currencycRate: data.currencycRate,
            efekId: data.efekId,
            fileKonfirmasiId: fileKonfirmasiId,
            jmlTransaksi: data.jmlTransaksi,
            jurnalNo: data.jurnalNo,
            kpdId: data.kpdId,
            noInstruksi: data.noInstruksi,
            noKonfirmasi: data.noKonfirmasi,
            nominalPembayaran: data.nominalPembayaran,
            produkId: data.produkId,
            rekPembayaran: data.rekPembayaran,
            sourceRekBank: data.sourceRekBank,
            tglPembayaran: formatDate(data.tglPembayaran),
            tipeTransaksiId: data.tipeTransaksiId,
            totalKewajiban: data.totalKewajiban,
            totalPembayaran: data.totalPembayaran,
            transactionId: data.transactionId
        }
        if (dataMaster) {
            try {
                var loadAPI = 'bank-transactions/penyelesaian-transaksi-pembayaran-konfirmasi'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, "POST", {
                    values: dataMaster,
                }
                )
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                console.log(e)
                notify({ message: `Terjadi Kesalahan (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Konfirmasi Transaksi Kewajiban Pembayaran Transaksi'}
                width={'40vw'}
                height={'80vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.items}
                                    labelLocation={"left"}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileBase64 ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                            </div>
                        </div>
                        <ModalPreviewPdf
                            ref={this.modalPreviewPdfRef}
                            store={this.props.store}
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalSettlementKonfirmasiPembayaran