import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { divisi } from 'dataSource/lookup'
import { confirmAlert } from 'react-confirm-alert';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';

class Unit extends Component {
    constructor(props) { 
        super(props)

        this.dataGridRef = React.createRef();
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    { 
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            if (e.row.data) {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin ingin menghapus data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.deleteData(e.row.data.id)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'id',
                caption: 'ID',
                allowEditing: false
                // editorOptions: {
                //     readOnly: true,
                // },
            },
            {
                dataField: 'unitName',
                caption: 'Unit',
            },
            {
                dataField: 'divisiId',
                caption: 'Divisi',
                lookup: {
                    dataSource: divisi(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'divisiName'
                }
            }
        ]
    } 

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    deleteData = async (unitId) => {
        try {
            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `staticdata-wf/units`, 'DELETE', unitId);

            if (response) {
                this.dataGridRef.current.forceRefresh(true);
                notify({ message: 'Sukses', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
            }
        } catch (error) {
            let arr = error.split(' ');
            let messageSlice = arr.slice(4, 15);
            let message = messageSlice.join(' ');
            notify({ message: message, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 1200);
            console.log(error)
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Unit</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='units'
                    insertAPI='staticdata-wf/units'
                    updateAPI='staticdata-wf/units'
                    deleteAPI='staticdata-wf/units' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Unit"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Unit Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default Unit