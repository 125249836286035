import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { FileUploader } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import { cities, citiesFilter, genderInt, kecamatans, kecamatansFilter, kelurahanFilter, kelurahans, maritalStatus, provinces, banks } from 'dataSource/lookup';
import { formatUploadFileData, formatUploadFileNameAndExtention,imageSource, download } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import { ScrollView } from 'devextreme-react';
import ModalPreviewImage from '../../compPeserta/previewImage';
import ModalPreviewPdf from '../../compPeserta/previewPdf';

class ModaDetailPengkinianOnline extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataPeserta: {},
            actionType: ''
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formPengkinianRef = React.createRef()
        this.formPesertaRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options: {
            //         'text': 'Simpan',
            //         onClick: async() => await this.submitData(),
            //     },
            //     toolbar: 'bottom',
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'id',
                        label: {
                            text: 'ID'
                        }
                    },
                    {
                        dataField: 'nik',
                        label: {
                            text: 'NIK'
                        }
                    },
                    {
                        dataField: 'fullName',
                        label: {
                            text: 'Nama Peserta'
                        }
                    },
                ]
            },
        ]

        this.dataPengkinian = [
            {
                itemType: 'group',
                caption: 'Data Pengkinian',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Status Perkawinan',
                        items: [
                            {
                                dataField: 'marriedId',
                                label: {visible: false},
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: maritalStatus(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (e) => {
                                        var namaPasangan = this.formPengkinianRef.current.instance.getEditor('namaPasangan')
                                        if(e.value > 1 && e.value < 6){
                                            namaPasangan.option('visible',true)
                                        }else{
                                            namaPasangan.option('visible',false)
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'namaPasangan',
                                label: {visible: false},
                                editorOptions: {
                                    placeholder: 'Nama Pasangan',
                                    visible: false
                                }
                            },
                            {
                                dataField: 'tglLahirPasangan',
                                label: {visible:false},
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                }
                            },
                            {
                                dataField: 'sex',
                                label: {visible:false},
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: genderInt(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                itemType: 'empty',
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Tanggungan Anak',
                        items: [
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Tanggungan Anak'
                                        }
                                    },
                                    {
                                        label: {
                                            text: 'Nama Anak'
                                        }
                                    },
                                    {
                                        label: {
                                            text: 'Tanggal Lahir'
                                        }
                                    },
                                    {
                                        label: {
                                            text: 'Kelamin'
                                        }
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Anak Ke 1'
                                        }
                                    },
                                    {
                                        dataField: 'anak1Nama',
                                        label: {visible:false},
                                        editorOptions: {
                                            placeholder: 'Nama Anak Ke 1'
                                        }
                                    },
                                    {
                                        dataField: 'anak1TglLahir',
                                        label: {visible:false},
                                        editorType: 'dxDateBox',
                                        editorOptions: {
                                            displayFormat: 'dd MMM yyyy',
                                            useMaskBehavior: true,
                                        }
                                    },
                                    {
                                        dataField: 'anak1SexId',
                                        label: {visible:false},
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: genderInt(),
                                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                                            displayExpr: 'value', // provides display values
                                            searchEnabled: true,
                                            deferRendering: false,
                                        },
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Anak Ke 2'
                                        }
                                    },
                                    {
                                        dataField: 'anak2Nama',
                                        label: {visible:false},
                                        editorOptions: {
                                            placeholder: 'Nama Anak Ke 2'
                                        }
                                    },
                                    {
                                        dataField: 'anak2TglLahir',
                                        label: {visible:false},
                                        editorType: 'dxDateBox',
                                        editorOptions: {
                                            displayFormat: 'dd MMM yyyy',
                                            useMaskBehavior: true,
                                        }
                                    },
                                    {
                                        dataField: 'anak2SexId',
                                        label: {visible:false},
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: genderInt(),
                                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                                            displayExpr: 'value', // provides display values
                                            searchEnabled: true,
                                            deferRendering: false,
                                        },
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Anak Ke 3'
                                        }
                                    },
                                    {
                                        dataField: 'anak3Nama',
                                        label: {visible:false},
                                        editorOptions: {
                                            placeholder: 'Nama Anak Ke 3'
                                        }
                                    },
                                    {
                                        dataField: 'anak3TglLahir',
                                        label: {visible:false},
                                        editorType: 'dxDateBox',
                                        editorOptions: {
                                            displayFormat: 'dd MMM yyyy',
                                            useMaskBehavior: true,
                                        }
                                    },
                                    {
                                        dataField: 'anak3SexId',
                                        label: {visible:false},
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: genderInt(),
                                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                                            displayExpr: 'value', // provides display values
                                            searchEnabled: true,
                                            deferRendering: false,
                                        },
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Alamat Domisili',
                        items: [
                            {
                                dataField: 'alamatDomisili',
                                label:{visible: true},
                                editorType: 'dxTextArea',
                                editorOptions: {
                                    height: '200px'
                                }
                            },
                            {
                                dataField: 'provinsiId',
                                label: {
                                    alignment: 'left',
                                    text: 'Provinsi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: provinces(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'provinceName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: async(data) => {
                                        var selectCity = this.formPengkinianRef.current.instance.getEditor('kotaId')
                                        // selectCity.option('value', '')
                                        selectCity.option('dataSource',citiesFilter(this.props.store,data.value,true))
                                    }
                                },
                            },
                            {
                                dataField: 'kotaId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kota'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: cities(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'cityName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false
                                },
                            },
                            {
                                dataField: 'kecamatanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kecamatan'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kecamatans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kecamatanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectKelurahan = this.formPengkinianRef.current.instance.getEditor('kelurahanId')
                                        if(data.value){
                                            selectKelurahan.option('dataSource',kelurahanFilter(this.props.store,data.value,true))
                                        }
                                    }  
                                },
                            },
                            {
                                dataField: 'kelurahanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kelurahan'
                                },
                                editorType : 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kelurahans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kelurahanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: (data) => {
                                        if(data.selectedItem) {
                                            var inputKodePos = this.formPengkinianRef.current.instance.getEditor('postalCode')
                                            inputKodePos.option('value', data.selectedItem.kodePos)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'postalCode',
                                label: {
                                    alignment: 'left',
                                    text: 'Kode Pos'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'No Telp / Handphone',
                        items: [
                            {
                                dataField: 'no_telp',
                                label: {
                                    alignment: 'left',
                                    text: 'No Telp'
                                }
                            },
                            {
                                dataField: 'no_hp',
                                label: {
                                    alignment: 'left',
                                    text: 'No HP'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Rekening',
                        items: [
                            {
                                dataField: 'rekeningBankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'initialName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField: 'rekeningNo',
                                label: {
                                    alignment: 'left',
                                    text: 'No Rekening'
                                }
                            },
                            {
                                dataField: 'rekeningName',
                                label: {
                                    alignment: 'left',
                                    text: 'Nama Rekening'
                                }
                            },
                            {
                                dataField: 'rekeningCabang',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Cabang'
                                }
                            }
                        ]
                    }
                ]
            },
        ]

        this.dataPeserta = [
            {
                itemType: 'group',
                caption: 'Data Peserta',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Status Perkawinan',
                        items: [
                            {
                                dataField: 'marriedId',
                                label: {visible: false},
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: maritalStatus(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (e) => {
                                        var namaPasangan = this.formPesertaRef.current.instance.getEditor('namaPasangan')
                                        if(e.value > 1 && e.value < 6){
                                            namaPasangan.option('visible',true)
                                        }else{
                                            namaPasangan.option('visible',false)
                                        }
                                    }
                                },
                            },
                            {
                                dataField: 'namaPasangan',
                                label: {visible: false},
                                editorOptions: {
                                    placeholder: 'Nama Pasangan',
                                    visible: false
                                }
                            },
                            {
                                dataField: 'tglLahirPasangan',
                                label: {visible:false},
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    placeholder : 'Tanggal Lahir',
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                }
                            },
                            {
                                dataField: 'sex',
                                label: {visible:false},
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: genderInt(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    placeholder : 'Jenis Kelamin'
                                },
                            },
                            {
                                itemType: 'empty',
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Tanggungan Anak',
                        items: [
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Tanggungan Anak'
                                        }
                                    },
                                    {
                                        label: {
                                            text: 'Nama Anak'
                                        }
                                    },
                                    {
                                        label: {
                                            text: 'Tanggal Lahir'
                                        }
                                    },
                                    {
                                        label: {
                                            text: 'Kelamin'
                                        }
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Anak Ke 1'
                                        }
                                    },
                                    {
                                        dataField: 'anak1Nama',
                                        label: {visible:false},
                                        editorOptions: {
                                            placeholder: 'Nama Anak Ke 1'
                                        }
                                    },
                                    {
                                        dataField: 'anak1TglLahir',
                                        label: {visible:false},
                                        editorType: 'dxDateBox',
                                        editorOptions: {
                                            displayFormat: 'dd MMM yyyy',
                                            useMaskBehavior: true,
                                        }
                                    },
                                    {
                                        dataField: 'anak1SexId',
                                        label: {visible:false},
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: genderInt(),
                                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                                            displayExpr: 'value', // provides display values
                                            searchEnabled: true,
                                            deferRendering: false,
                                        },
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Anak Ke 2'
                                        }
                                    },
                                    {
                                        dataField: 'anak2Nama',
                                        label: {visible:false},
                                        editorOptions: {
                                            placeholder: 'Nama Anak Ke 2'
                                        }
                                    },
                                    {
                                        dataField: 'anak2TglLahir',
                                        label: {visible:false},
                                        editorType: 'dxDateBox',
                                        editorOptions: {
                                            displayFormat: 'dd MMM yyyy',
                                            useMaskBehavior: true,
                                        }
                                    },
                                    {
                                        dataField: 'anak2SexId',
                                        label: {visible:false},
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: genderInt(),
                                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                                            displayExpr: 'value', // provides display values
                                            searchEnabled: true,
                                            deferRendering: false,
                                        },
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                colCount: 4,
                                items: [
                                    {
                                        label: {
                                            text: 'Anak Ke 3'
                                        }
                                    },
                                    {
                                        dataField: 'anak3Nama',
                                        label: {visible:false},
                                        editorOptions: {
                                            placeholder: 'Nama Anak Ke 3'
                                        }
                                    },
                                    {
                                        dataField: 'anak3TglLahir',
                                        label: {visible:false},
                                        editorType: 'dxDateBox',
                                        editorOptions: {
                                            displayFormat: 'dd MMM yyyy',
                                            useMaskBehavior: true,
                                        }
                                    },
                                    {
                                        dataField: 'anak3SexId',
                                        label: {visible:false},
                                        editorType: 'dxSelectBox',
                                        editorOptions: {
                                            dataSource: genderInt(),
                                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                                            displayExpr: 'value', // provides display values
                                            searchEnabled: true,
                                            deferRendering: false,
                                        },
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Alamat Domisili',
                        items: [
                            {
                                dataField: 'domicileAddress1',
                                label:{visible: true},
                                editorType: 'dxTextArea',
                                editorOptions: {
                                    height: '200px'
                                }
                            },
                            {
                                dataField: 'domicileProvinceId',
                                label: {
                                    alignment: 'left',
                                    text: 'Provinsi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: provinces(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'provinceName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: async(data) => {
                                        var selectCity = this.formPesertaRef.current.instance.getEditor('kotaId')
                                        // selectCity.option('value', '')
                                        selectCity.option('dataSource',citiesFilter(this.props.store,data.value,true))
                                    }
                                },
                            },
                            {
                                dataField: 'kotaId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kota'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: cities(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'cityName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField: 'kecamatanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kecamatan'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kecamatans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kecamatanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onValueChanged: (data) => {
                                        var selectKelurahan = this.formPesertaRef.current.instance.getEditor('kelurahanId')
                                        if(data.value){
                                            selectKelurahan.option('dataSource',kelurahanFilter(this.props.store,data.value,true))
                                        }
                                    }  
                                },
                            },
                            {
                                dataField: 'kelurahanId',
                                label: {
                                    alignment: 'left',
                                    text: 'Kelurahan'
                                },
                                editorType : 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kelurahans(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'kelurahanName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: (data) => {
                                        if(data.selectedItem) {
                                            var inputKodePos = this.formPesertaRef.current.instance.getEditor('postalCode')
                                            inputKodePos.option('value', data.selectedItem.kodePos)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'postalCode',
                                label: {
                                    alignment: 'left',
                                    text: 'Kode Pos'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'No Telp / Handphone',
                        items: [
                            {
                                dataField: 'mobilePhone2',
                                label: {
                                    alignment: 'left',
                                    text: 'No Telp'
                                }
                            },
                            {
                                dataField: 'mobileNo',
                                label: {
                                    alignment: 'left',
                                    text: 'No HP'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Rekening',
                        items: [
                            {
                                dataField: 'rekeningBankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'initialName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField: 'rekeningNo',
                                label: {
                                    alignment: 'left',
                                    text: 'No Rekening'
                                }
                            },
                            {
                                dataField: 'rekeningName',
                                label: {
                                    alignment: 'left',
                                    text: 'Nama Rekening'
                                }
                            },
                            {
                                dataField: 'rekeningCabang',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Cabang'
                                }
                            }
                        ]
                    }
                ]
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataPeserta: {},
            actionType: ''
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data,type) => {
        var dataPeserta = {}
        var loadAPIPengkinian = `pengkinian-onlines/${data.id}`
        var getPengkinian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPengkinian, 'GET')

        var loadAPI = `pesertas/${data.pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        
        var loadAPIPasangan = `simple-peserta-families/getAllPesertaFamiliesByRelationCode/${data.pesertaId}/SI`
        var dataPasangan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPasangan, 'GET')

        var loadAPIPasangan = `simple-peserta-families/getAllPesertaFamiliesByRelationCode/${data.pesertaId}/AK`
        var dataAnak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPasangan, 'GET')
        
        for(var anak of dataAnak){
            anak.sex = parseInt(anak.sex)
        }

        dataPeserta = {
            ...getPeserta,
            alamatDomisili: getPeserta.domicileAddress1,
            anak1Nama: dataAnak[0] ? dataAnak[0].fullName : null,
            anak1SexId: dataAnak[0] ? dataAnak[0].sex : null,
            anak1TglLahir: dataAnak[0] ? dataAnak[0].birthDate : null,
            anak2Nama: dataAnak[1] ? dataAnak[1].fullName : null,
            anak2SexId: dataAnak[1] ? dataAnak[1].sex : null,
            anak2TglLahir: dataAnak[1] ? dataAnak[1].birthDate : null,
            anak3Nama: dataAnak[2] ? dataAnak[2].fullName : null,
            anak3SexId: dataAnak[2] ? dataAnak[2].sex : null,
            anak3TglLahir: dataAnak[2] ? dataAnak[2].birthDate : null,
            kotaId: getPeserta.domicileCityId,
            kecamatanId: getPeserta.domicileKecamatanId,
            kelurahanId: getPeserta.domicileKelurahanId,
            postalCode: getPeserta.domicilePostalCode,
            marriedFile: "",
            marriedFileContentType: "image/png",
            marriedId: getPeserta.mariedId,
            mpPaymentTypeId: 0,
            mpRekeningBankId: getPeserta.rekeningBankId,
            mpRekeningName: getPeserta.rekeningNama,
            mpRekeningNo: getPeserta.rekeningNo,
            rekeningBankId: getPeserta.rekeningBankId,
            rekeningName: getPeserta.rekeningNama,
            rekeningNo: getPeserta.rekeningNo,
            namaPasangan: dataPasangan[0] ? dataPasangan[0].fullName : "",
            tglLahirPasangan : dataPasangan[0] ? dataPasangan[0].birthDate : "",
            sex : dataPasangan[0] ? dataPasangan[0].sex : "",
            no_hp: getPeserta.phoneNo,
            no_telp: getPeserta.mobileNo,
            pesertaId: getPeserta.id,
            pesertaStatusId: getPeserta.statusPeserta,
            provinsiId: getPeserta.identityProvinceId,
            fileKkName : '',
            fileKkExtention: '',
            fileKkContentType: '',
            fileKk : '',
            marriedFileName : '',
            marriedFileExtention: '',
            marriedFileContentType: '',
            marriedFile : ''
        }
        var loadAPIDokumen = `dokumen-pesertas/getByPesertaIdAndType/${data.pesertaId}/`

        var getDokumenKk = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDokumen + '7', 'GET')
        var getDokumenBukuNikah = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDokumen + '8', 'GET')

        if(getDokumenKk.length !== 0){
            dataPeserta.fileKkName = getDokumenKk[0].dokumenFileName || ''
            dataPeserta.fileKkExtention = getDokumenKk[0].dokumenFileExtention || ''
            dataPeserta.fileKkContentType = getDokumenKk[0].dokumenFileContentType || ''
            dataPeserta.fileKk = getDokumenKk[0].dokumenFile || ''
        }
        if(getDokumenBukuNikah.length === 0){
            var getDokumenSuratCerai = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDokumen + '10', 'GET')
            if(getDokumenSuratCerai.length !== 0){
                dataPeserta.marriedFileName = getDokumenSuratCerai[0].dokumenFileName
                dataPeserta.marriedFileExtention = getDokumenSuratCerai[0].dokumenFileExtention
                dataPeserta.marriedFileContentType = getDokumenSuratCerai[0].dokumenFileContentType
                dataPeserta.marriedFile = getDokumenSuratCerai[0].dokumenFile
                getPengkinian.marriedFileType = 'cerai'
            }
        }else{
            dataPeserta.marriedFileName = getDokumenBukuNikah[0].dokumenFileName
            dataPeserta.marriedFileExtention = getDokumenBukuNikah[0].dokumenFileExtention
            dataPeserta.marriedFileContentType = getDokumenBukuNikah[0].dokumenFileContentType
            dataPeserta.marriedFile = getDokumenBukuNikah[0].dokumenFile
            getPengkinian.marriedFileType = 'nikah'
        }

        // this.formPesertaRef.current.instance.updateData(dataPeserta)
        // this.formMasterRef.current.instance.updateData(data)
        // this.formPengkinianRef.current.instance.updateData(data)
        console.log(getPengkinian)
        this.setState({
            dataPeserta: dataPeserta,
            dataMaster: getPengkinian,
            actionType : type
        })

    }
    
    onUploadStarted = (e,jenisData) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention
            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    [jenisData]: {
                        ...prevState[jenisData],
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                        [`${fieldName}Name`]: formattedFileNameAndExtention.name,
                        webExtentionFile: formattedFileNameAndExtention.extention,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    previewFile = async(fileName, states) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            // var extension = mime.extension(this.state.dataMaster[`${fileName}ContentType`])
            if(states == 'dataPeserta'){
                var src = imageSource(this.state.dataPeserta[`${fileName}ContentType`],this.state.dataPeserta[`${fileName}`])
            }else{
                var src = imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`])
            }
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName, states) => {
        if(states == 'dataPeserta'){
            download(imageSource(this.state.dataPeserta[`${fileName}ContentType`],this.state.dataPeserta[`${fileName}`]),this.state.dataPeserta[`${fileName}Name`])
        }else{
            download(imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`]),this.state.dataMaster[`${fileName}Name`])
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengkinian Online'}
                minWidth={1000}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height='100%' width='100%'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <Form
                                    colCount={1}
                                    ref={this.formMasterRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataPeserta}
                                    items={this.dataMaster}
                                    labelLocation = "left"
                                    readOnly={true}
                                />
                            </div>
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    ref={this.formPesertaRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataPeserta}
                                    items={this.dataPeserta}
                                    labelLocation = "left"
                                    readOnly = {true}
                                />
                            </div>
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    ref={this.formPengkinianRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataPengkinian}
                                    labelLocation = "left"
                                    readOnly = {true}
                                />
                                
                            </div>
                            <div className="col-md-12 text-center">
                                <font className="font-weight-bold" style={{fontSize: '15px'}}>Data Dokumen</font>
                                <hr/>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FileUploader 
                                            accept={'image/png, image/jpeg, image/png'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e,'dataPeserta')} 
                                            labelText={this.state.dataPeserta.marriedFileName || 'Lampiran Foto KTP Suami/Istri untuk Status Kawin atau Photo Surat Cerai'}
                                            showFileList={false}
                                            name={'marriedFile'}
                                        />
                                    </div>
                                    <div className="col-md-6 text-right pt-3">
                                        <button onClick={() => this.previewFile('marriedFile', 'dataPeserta')} className={`btn btn-light btn-sm border rounded ${this.state.dataPeserta.marriedFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Preview
                                        </button>
                                        <button onClick={() => this.downloadFile('marriedFile', 'dataPeserta')} className={`btn btn-light btn-sm border rounded ${this.state.dataPeserta.marriedFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Download
                                        </button>
                                    </div>

                                    <div className="col-md-6">
                                        <FileUploader 
                                            accept={'image/png, image/jpeg, image/png'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e,'dataPeserta')} 
                                            labelText={this.state.dataPeserta.fileKkName || 'Lampiran Foto Kartu Keluarga'}
                                            showFileList={false}
                                            name={'fileKk'}
                                        />
                                    </div>
                                    <div className="col-md-6 text-right pt-3">
                                        <button onClick={() => this.previewFile('fileKk', 'dataPeserta')} className={`btn btn-light btn-sm border rounded ${this.state.dataPeserta.fileKk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Preview
                                        </button>
                                        <button onClick={() => this.downloadFile('fileKk', 'dataPeserta')} className={`btn btn-light btn-sm border rounded ${this.state.dataPeserta.fileKk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <FileUploader 
                                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                                            labelText={this.state.dataMaster.marriedFileName || 'Lampiran Foto KTP Suami/Istri untuk Status Kawin atau Photo Surat Cerai'}
                                            showFileList={false}
                                            name={'marriedFile'}
                                            />
                                    </div>
                                    <div className="col-md-6 text-right pt-3">
                                        <button onClick={() => this.previewFile('marriedFile', 'dataMaster')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.marriedFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Preview
                                        </button>
                                        <button onClick={() => this.downloadFile('marriedFile', 'dataMaster')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.marriedFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Download
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <FileUploader 
                                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                            uploadMode={'useForm'}
                                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                                            labelText={this.state.dataMaster.fileKkName || 'Lampiran Foto Kartu Keluarga'}
                                            showFileList={false}
                                            name={'fileKk'}
                                        />
                                    </div>
                                    <div className="col-md-6 text-right pt-3">
                                        <button onClick={() => this.previewFile('fileKk', 'dataMaster')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Preview
                                        </button>
                                        <button onClick={() => this.downloadFile('fileKk', 'dataMaster')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileKk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        );
    }
}


export default ModaDetailPengkinianOnline