import React, { Component } from 'react'
import { Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { pesertaById, pesertas } from 'dataSource/lookup';
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { PrintElem, formatDefaultDate, daysDiff, formatNumberAum } from 'plugin/helper'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { confirmAlert } from 'react-confirm-alert';
import { alert } from 'devextreme/ui/dialog';
import { showLoading } from 'redux/actions/loading';

class ReportDetailPeserta extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            src: '',
        }

        this.dataGridRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.pesertaId = 0

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        this.props.store.dispatch(showLoading(true))
        if(this.pesertaId){
            var statusKawin = [
                {
                    id: "1",
                    value: 'Kawin'
                },
                {
                    id: "2",
                    value: 'Belum Kawin'
                },
                {
                    id: "3",
                    value: 'Janda'
                },
                {
                    id: "4",
                    value: 'Duda'
                },
            ]

            var hubunganKeluarga = [
                {
                    id: 'SI',
                    value: 'Suami/Istri'
                },
                {
                    id: 'AK',
                    value: 'Anak Kandung'
                },
                {
                    id: 'AIK',
                    value: 'Orang Tua'
                },
                {
                    id: 'KAK',
                    value: 'Saudara Kandung'
                },
                {
                    id: 'PK',
                    value: 'Pihak Ketiga'
                }
            ]

            var tipePengkinian = [
                {
                    id: 1,
                    value: 'BO Dengan Surat Keputusan'
                },
                {
                    id: 2,
                    value: 'BO Formulir (Blangko) Pengkinian Data'
                },
                {
                    id: 3,
                    value: 'Online Mobile Apps'
                },
                {
                    id: 4,
                    value: 'Online Website'
                },
            ]
    
            try {
                var loadAPIPeserta = `pesertas/${this.pesertaId}`
                var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${this.pesertaId}`
                var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`
                var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKeluarga, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIAkun = `kepersertaan-products/getAllSimpleProductByPesertaId/${this.pesertaId}`
                var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIADataSk = `view-daftar-surat-keputusans/peserta/${this.pesertaId}`
                var getDataSk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIADataSk, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIADataPengkinian = `pengkinian-peserta-bo-aktifs/getAllByPeserta/${this.pesertaId}`
                var getDataPengkinian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIADataPengkinian, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }
            
            try {
                var pemberiKerja = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${getPeserta.participantId}`, 'GET')).participantName
            } catch (error) {
                var pemberiKerja = "-"
            }

            try {
                var status = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `peserta-statuses/${getPeserta.statusPeserta}`, 'GET')).statusName
            } catch (error) {
                var status = "-"
            }
            
            try {
                var identityCountryId = getPeserta.identityCountryId ? (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `countries/${getPeserta.identityCountryId}`, 'GET')).countryName : '-'
            } catch (error) {
                var identityCountryId = "-"
            }
            
            try {
                var identityProvinceId = getPeserta.identityProvinceId ? (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `provinces/${getPeserta.identityProvinceId}`, 'GET')).provinceName : '-'
            } catch (error) {
                var identityProvinceId = "-"
            }
            
            try {
                var identityCityId = getPeserta.identityCityId ? (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `cities/${getPeserta.identityCityId}`, 'GET')).cityName : '-'
            } catch (error) {
                var identityCityId = "-"
            }
            
            try {
                var statusPajakId = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `status-pajaks/${getPeserta.statusPajakId}`, 'GET')).statusName
            } catch (error) {
                var statusPajakId = "-"
            }
            // COMPARATION
            var dataPeserta = getPeserta
            dataPeserta.pemberiKerja = pemberiKerja
            dataPeserta.status = status
            dataPeserta.birthDate = dataPeserta.birthDate ? formatDefaultDate(dataPeserta.birthDate) : '-'
            dataPeserta.identityCountryId = identityCountryId
            dataPeserta.identityProvinceId = identityProvinceId
            dataPeserta.identityCityId = identityCityId
            dataPeserta.noIdentitas = dataPeserta.identityNo || dataPeserta.noKtp
            dataPeserta.sex = dataPeserta.sex = 0 ? 'Perempuan' : 'Laki-Laki'
            dataPeserta.identityExpired = dataPeserta.identityExpired ? formatDefaultDate(dataPeserta.identityExpired) : 'Seumur Hidup'
            dataPeserta.statusPajakId = statusPajakId
            dataPeserta.mariedId = (statusKawin.find(val => val.id == dataPeserta.mariedId)).value
            dataPeserta.fullName = dataPeserta.fullName ? (dataPeserta.fullName).toUpperCase() : dataPeserta.fullName
            
            var dataKepegawaian = getKepegawaian
            if(dataKepegawaian) {
                var hari = Math.abs(daysDiff(dataKepegawaian.tanggalEfektif ? dataKepegawaian.tanggalEfektif : null))
                var bulan = Math.round((hari % 365) / 30)
                var tahun = Math.round(hari / 365)
                dataKepegawaian.masaKerja = `${tahun} tahun ${bulan} bulan`
                dataKepegawaian.statusKaryawanTanggal = dataKepegawaian.statusKaryawanTanggal ? formatDefaultDate(dataKepegawaian.statusKaryawanTanggal) : '-'
                dataKepegawaian.tanggalEfektif = dataKepegawaian.tanggalEfektif ? formatDefaultDate(dataKepegawaian.tanggalEfektif) : '-'
                dataKepegawaian.phdp = dataKepegawaian.phdp ? (dataKepegawaian.phdp) : 0
      
                dataKepegawaian.iuranTotal = (dataKepegawaian.iuranPerusahaan + dataKepegawaian.iuranPeserta)
                dataKepegawaian.iuranPeserta = (dataKepegawaian.iuranPeserta)
                dataKepegawaian.iuranPerusahaan = (dataKepegawaian.iuranPerusahaan)
            }
            var dataAkun = []
            if(getAkun.length > 0){
                for(var value of getAkun){
                    var data = {...value}
                    try {
                        var productId = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${value.productId}`, 'GET')).productCode
                    } catch (error) {
                        var productId = "-"
                    }
                    data.productId = productId
                    data.efektifDate = data.efektifDate ? formatDefaultDate(data.efektifDate) : '-'
                    data.iuranPerBulan = (value.iuranNormalPeserta + value.iuranManfaatLainPeserta + value.iuranNormalPk + value.iuranManfaatLainPk)
                    if(dataKepegawaian) {
                        dataKepegawaian.iuranPeserta = (dataKepegawaian.iuranPeserta || 0) + value.iuranNormalPeserta + value.iuranManfaatLainPeserta
                        dataKepegawaian.iuranPerusahaan = (dataKepegawaian.iuranPerusahaan || 0) + value.iuranNormalPk + value.iuranManfaatLainPk
                        dataKepegawaian.iuranTotal = dataKepegawaian.iuranPeserta + dataKepegawaian.iuranPerusahaan
                    }
                    data.mpBulanan = data.mpBulanan ? (data.mpBulanan) : 0
                    data.mpSekaligus = data.mpSekaligus ? (data.mpSekaligus) : 0
                    data.penerimaName = data.penerimaName ? data.penerimaName : '-'
                    data.mpRekeningNo = data.mpRekeningNo ? data.mpRekeningNo : '-'
                    data.mpRekeningName = data.mpRekeningName ? (data.mpRekeningName).toUpperCase() : '-'
                    data.bankPenerimaName = data.bankPenerimaName ? data.bankPenerimaName : '-'
                    data.penerimaName = data.penerimaName ? (data.penerimaName).toUpperCase() : '-'
                    
                    dataAkun.push(data)
                }
            }else{
                this.props.store.dispatch(showLoading(false))
                console.log('error')
            }
            
            var dataKeluarga = []
            if(getKeluarga.length !== 0){
                for(var value of getKeluarga){
                    var data = {...value}
                    data.fullName = data.fullName ? (data.fullName).toUpperCase() : '-'
                    data.relationCode = hubunganKeluarga.find(val => val.id === data.relationCode).value
                    data.birthDate = data.birthDate ? formatDefaultDate(data.birthDate) : '-'
                    data.ktp = data.ktp ?  data.ktp : '-'
                    data.statusSekolah = data.statusSekolah ? 'Tidak Sekolah' : 'Sekolah'
                    if(data.sex == 0) {
                        data.sex = 'Laki - Laki'
                    } else if (data.sex == 1) {
                        data.sex = 'Perempuan'
                    } else {
                        data.sex = data.sex
                    }

                    dataKeluarga.push(data)
                }
            }

            var dataSk = []
            if(getDataSk.length !== 0) {
                for(let value of getDataSk) {
                    let dataValue = {
                        skNo: value.skNo ? value.skNo : '-',
                        jenisSk: value.jenisSk ? value.jenisSk : '-',
                        tglSkPemberhentian: value.tglSkPemberhentian ? formatDefaultDate(value.tglSkPemberhentian) : '-',
                        efektifDate: getAkun[0].efektifDate ? formatDefaultDate(getAkun[0].efektifDate) : '-' 
                    }

                    dataSk.push(dataValue)
                }
            }

            var dataPengkinian = []
            let index = 1
            if(getAkun.length !== 0) {
                for(let value of getDataPengkinian) {
                    let dataValue = {
                        index: index++,
                        pengkinianDate: value.pengkinianDate ? formatDefaultDate(value.pengkinianDate) : '-',
                        noSk: value.noSk ? value.noSk : '-',
                        skType: value.skType ? value.skType : '-',
                        pengkinianTypeId: tipePengkinian.find(val => val.id === value.pengkinianTypeId) ? tipePengkinian.find(val => val.id === value.pengkinianTypeId).value : '-',
                        media: value.online === false ? 'Back Office' : 'Mobile Apps'
                    }
                    
                    dataPengkinian.push(dataValue)
                }
            }

            var exportData = {
                kode : `KPS-${getPeserta.id}`,
                tanggalLaporan : formatDefaultDate(new Date()),
                dataPeserta : dataPeserta,
                dataKepegawaian: dataKepegawaian,
                dataKeluarga : dataKeluarga,
                dataAkunProduct : dataAkun,
                dataSk: dataSk,
                dataPengkinian: dataPengkinian,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
            var data = {
                template:{
                    name: '/dapen/kepesertaan/report/peserta/detail-peserta',
                    recipe: 'chrome-pdf'
                },
                data: exportData,
            }
            var responseFile = await reportFile(data)
            this.props.store.dispatch(showLoading(false))
            this.setState({
                src : responseFile
            })

        }else{
            this.props.store.dispatch(showLoading(false))
            confirmAlert({
                title: 'Informasi',
                message: "Isi peserta terlebih dahulu",
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {}
                  },
                ]
            });
        }
    }
    
    print = async() => {
        this.props.store.dispatch(showLoading(true))
        if(this.pesertaId){
            var statusKawin = [
                {
                    id: "1",
                    value: 'Kawin'
                },
                {
                    id: "2",
                    value: 'Belum Kawin'
                },
                {
                    id: "3",
                    value: 'Janda'
                },
                {
                    id: "4",
                    value: 'Duda'
                },
            ]

            var hubunganKeluarga = [
                {
                    id: 'SI',
                    value: 'Suami/Istri'
                },
                {
                    id: 'AK',
                    value: 'Anak Kandung'
                },
                {
                    id: 'AIK',
                    value: 'Orang Tua'
                },
                {
                    id: 'KAK',
                    value: 'Saudara Kandung'
                },
                {
                    id: 'PK',
                    value: 'Pihak Ketiga'
                }
            ]

            var tipePengkinian = [
                {
                    id: 1,
                    value: 'BO Dengan Surat Keputusan'
                },
                {
                    id: 2,
                    value: 'BO Formulir (Blangko) Pengkinian Data'
                },
                {
                    id: 3,
                    value: 'Online Mobile Apps'
                },
                {
                    id: 4,
                    value: 'Online Website'
                },
            ]

            try {
                var loadAPIPeserta = `pesertas/${this.pesertaId}`
                var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${this.pesertaId}`
                var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`
                var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKeluarga, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIAkun = `kepersertaan-products/getAllSimpleProductByPesertaId/${this.pesertaId}`
                var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIADataSk = `view-daftar-surat-keputusans/peserta/${this.pesertaId}`
                var getDataSk = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIADataSk, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }

            try {
                var loadAPIADataPengkinian = `pengkinian-peserta-bo-aktifs/getAllByPeserta/${this.pesertaId}`
                var getDataPengkinian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIADataPengkinian, 'GET')
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                await alert(`Data Tidak Tersedia`, 'Informasi');
            }
            
            try {
                var pemberiKerja = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${getPeserta.participantId}`, 'GET')).participantName
            } catch (error) {
                var pemberiKerja = "-"
            }

            try {
                var status = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `peserta-statuses/${getPeserta.statusPeserta}`, 'GET')).statusName
            } catch (error) {
                var status = "-"
            }

            try {
                var identityCountryId = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `countries/${getPeserta.identityCountryId}`, 'GET')).countryName
            } catch (error) {
                var identityCountryId = "-"
            }

            try {
                var identityProvinceId = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `provinces/${getPeserta.identityProvinceId}`, 'GET')).provinceName
            } catch (error) {
                var identityProvinceId = "-"
            }

            try {
                var identityCityId = (await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `cities/${getPeserta.identityCityId}`, 'GET')).cityName
            } catch (error) {
                var identityCityId = "-"
            }

            try {
                var statusPajakId = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `status-pajaks/${getPeserta.statusPajakId}`, 'GET')).statusName
            } catch (error) {
                var statusPajakId = "-"
            }
            // COMPARATION
            var dataPeserta = getPeserta
            dataPeserta.pemberiKerja = pemberiKerja
            dataPeserta.status = status
            dataPeserta.birthDate = dataPeserta.birthDate ? formatDefaultDate(dataPeserta.birthDate) : '-'
            dataPeserta.identityCountryId = identityCountryId
            dataPeserta.identityProvinceId = identityProvinceId
            dataPeserta.identityCityId = identityCityId
            dataPeserta.noIdentitas = dataPeserta.identityNo || dataPeserta.noKtp
            dataPeserta.sex = dataPeserta.sex = 0 ? 'Perempuan' : 'Laki-Laki'
            dataPeserta.identityExpired = dataPeserta.identityExpired ? formatDefaultDate(dataPeserta.identityExpired) : 'Seumur Hidup'
            dataPeserta.statusPajakId = statusPajakId
            dataPeserta.mariedId = (statusKawin.find(val => val.id == dataPeserta.mariedId)).value
            dataPeserta.fullName = dataPeserta.fullName ? (dataPeserta.fullName).toUpperCase() : dataPeserta.fullName
            
            var dataKepegawaian = getKepegawaian
            if(dataKepegawaian) {
                var hari = Math.abs(daysDiff(dataKepegawaian.tanggalEfektif ? dataKepegawaian.tanggalEfektif : null))
                var bulan = Math.round((hari % 365) / 30)
                var tahun = Math.round(hari / 365)
                dataKepegawaian.masaKerja = `${tahun} tahun ${bulan} bulan`
                dataKepegawaian.statusKaryawanTanggal = dataKepegawaian.statusKaryawanTanggal ? formatDefaultDate(dataKepegawaian.statusKaryawanTanggal) : '-'
                dataKepegawaian.tanggalEfektif = dataKepegawaian.tanggalEfektif ? formatDefaultDate(dataKepegawaian.tanggalEfektif) : '-'
                dataKepegawaian.phdp = dataKepegawaian.phdp ? (dataKepegawaian.phdp) : 0
      
                dataKepegawaian.iuranTotal = (dataKepegawaian.iuranPerusahaan + dataKepegawaian.iuranPeserta)
                dataKepegawaian.iuranPeserta = (dataKepegawaian.iuranPeserta)
                dataKepegawaian.iuranPerusahaan = (dataKepegawaian.iuranPerusahaan)
            }
            var dataAkun = []
            if(getAkun.length > 0){
                for(var value of getAkun){
                    var data = {...value}
                    try {
                        var productId = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${value.productId}`, 'GET')).productCode
                    } catch (error) {
                        var productId = "-"
                    }
                    data.productId = productId
                    data.efektifDate = data.efektifDate ? formatDefaultDate(data.efektifDate) : '-'
                    data.iuranPerBulan = (value.iuranNormalPeserta + value.iuranManfaatLainPeserta + value.iuranNormalPk + value.iuranManfaatLainPk)
                    if(dataKepegawaian) {
                        dataKepegawaian.iuranPeserta = (dataKepegawaian.iuranPeserta || 0) + value.iuranNormalPeserta + value.iuranManfaatLainPeserta
                        dataKepegawaian.iuranPerusahaan = (dataKepegawaian.iuranPerusahaan || 0) + value.iuranNormalPk + value.iuranManfaatLainPk
                        dataKepegawaian.iuranTotal = dataKepegawaian.iuranPeserta + dataKepegawaian.iuranPerusahaan
                    }
                    data.mpBulanan = data.mpBulanan ? (data.mpBulanan) : 0
                    data.mpSekaligus = data.mpSekaligus ? (data.mpSekaligus) : 0
                    data.penerimaName = data.penerimaName ? data.penerimaName : '-'
                    data.mpRekeningNo = data.mpRekeningNo ? data.mpRekeningNo : '-'
                    data.mpRekeningName = data.mpRekeningName ? data.mpRekeningName : '-'
                    data.bankPenerimaName = data.bankPenerimaName ? data.bankPenerimaName : '-'
                    data.penerimaName = data.penerimaName ? (data.penerimaName).toUpperCase() : data.penerimaName
    
                    dataAkun.push(data)
                }
            }else{
                this.props.store.dispatch(showLoading(false))
                console.log('error')
            }
            
            var dataKeluarga = []
            if(getKeluarga.length !== 0){
                for(var value of getKeluarga){
                    var data = {...value}
                    data.relationCode = hubunganKeluarga.find(val => val.id === data.relationCode).value
                    data.birthDate = data.birthDate ? formatDefaultDate(data.birthDate) : '-'
                    data.ktp = data.ktp ?  data.ktp : '-'
                    data.statusSekolah = data.statusSekolah ? 'Tidak Sekolah' : 'Sekolah'
                    if(data.sex == 0) {
                        data.sex = 'Laki - Laki'
                    } else if (data.sex == 1) {
                        data.sex = 'Perempuan'
                    } else {
                        data.sex = data.sex
                    }

                    dataKeluarga.push(data)
                }
            }

            var dataSk = []
            if(getDataSk.length !== 0) {
                for(let value of getDataSk) {
                    let dataValue = {
                        skNo: value.skNo ? value.skNo : '-',
                        jenisSk: value.jenisSk ? value.jenisSk : '-',
                        tglSkPemberhentian: value.tglSkPemberhentian ? formatDefaultDate(value.tglSkPemberhentian) : '-',
                        efektifDate: getAkun[0].efektifDate ? formatDefaultDate(getAkun[0].efektifDate) : '-' 
                    }

                    dataSk.push(dataValue)
                }
            }

            var dataPengkinian = []
            let index = 1
            if(getAkun.length !== 0) {
                for(let value of getDataPengkinian) {
                    let dataValue = {
                        index: index++,
                        pengkinianDate: value.pengkinianDate ? formatDefaultDate(value.pengkinianDate) : '-',
                        noSk: value.noSk ? value.noSk : '-',
                        skType: value.skType ? value.skType : '-',
                        pengkinianTypeId: tipePengkinian.find(val => val.id === value.pengkinianTypeId) ? tipePengkinian.find(val => val.id === value.pengkinianTypeId).value : '-',
                        media: value.online === false ? 'Back Office' : 'Mobile Apps'
                    }
                    
                    dataPengkinian.push(dataValue)
                }
            }

            var exportData = {
                kode : `KPS-${getPeserta.id}`,
                tanggalLaporan : formatDefaultDate(new Date()),
                dataPeserta : dataPeserta,
                dataKepegawaian: dataKepegawaian,
                dataKeluarga : dataKeluarga,
                dataAkunProduct : dataAkun,
                dataSk: dataSk,
                dataPengkinian: dataPengkinian,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
            var data = {
                template:{
                    name: '/dapen/kepesertaan/report/peserta/detail-peserta',
                    // recipe: 'html'
                    recipe: 'chrome-pdf'
                },
                data: exportData,
                options: {
                    reportName: 'DAFTAR DETAIL PESERTA'
                }
            }
            // var response = await reportRequest(this.props.store,data)
            // PrintElem(response.data)
            
            var responseFile = await reportFile(data, true, 'DAFTAR DETAIL PESERTA')
            this.props.store.dispatch(showLoading(false))
            
            return responseFile
        }else{
            this.props.store.dispatch(showLoading(false))
            confirmAlert({
                title: 'Informasi',
                message: "Isi peserta terlebih dahulu",
                buttons: [
                  {
                    label: 'OK',
                    onClick: () => {}
                  },
                ]
            });
        }
    }

    exportExcel = () => {

    }

    onSelectionChanged = (e) => {
        this.pesertaId = e.selectedRowKeys[0]
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
    }

    forceRefresh = async () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI= {'pesertas/all-simple'}
                insertAPI='pengkinian-peserta-bo-aktifs'
                updateAPI='pengkinian-peserta-bo-aktifs'
                deleteAPI='pengkinian-peserta-bo-aktifs'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {false}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Daftar Detail Peserta </h4>
                            <DropDownBox
                                ref={this.dropDownBoxRef}
                                valueExpr="id"
                                deferRendering={false}
                                placeholder = {'Pilih Peserta'}
                                displayExpr={(item) => {
                                    return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                }}
                                showClearButton={true}
                                dataSource={pesertaById(this.props.store)}
                                onValueChanged={this.onValueChanged}
                                contentRender={this.renderDatagrid}
                            />
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                {/* <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                /> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportDetailPeserta