import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import {Form,FileUploader,ScrollView} from 'devextreme-react'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";

import { pengkinianType,tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, SKType, currency } from 'dataSource/lookup';
import { download, formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class FormTiketHotel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            DataDocument:{},
            uploadData:[]
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.disableEvent = false
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.sppdId = 0

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
          ];
        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
          ];
        this.formTiketHotel = [
            {
                dataField: 'bookingNo',
                label: {
                    text: 'No. Booking',
                },
            },
            {
                dataField: 'kamarNo',
                label: {
                    text: 'No. Kamar'
                },
                visible: false
            },
            {
                dataField: 'hotelName',
                label: {
                    text: 'Nama Hotel'
                }
            },
            {
                dataField: 'checkinDate',
                label: {
                    text: 'Tanggal Check In'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%',
                    onValueChanged: (e) => {
                        if (e.value && !this.disableEvent) {
                            this.formRef.current.instance.getEditor('totalBiaya').option('value',0)
                            this.formRef.current.instance.getEditor('biaya').option('value',0)
                        }
                    }
                }
            },
            {
                dataField:"jamCheckin",
                label: {
                    text: 'Jam Check In'
                },
                visible: false,
                editorType:"dxDateBox",
                editorOptions:{
                    type: "time",
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat: 'HH:mm',
                    pickerType:"rollers"
                }
            },
            {
                dataField: 'chekoutDate',
                label: {
                    text: 'Tanggal Check Out'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%',
                    onValueChanged: (e) => {
                        if (e.value && !this.disableEvent) {
                            this.formRef.current.instance.getEditor('totalBiaya').option('value',0)
                            this.formRef.current.instance.getEditor('biaya').option('value',0)
                        }
                    }
                }
            },
            {
                dataField:"jamCheckout",
                label: {
                    text: 'Jam Check Out'
                },
                visible: false,
                editorType:"dxDateBox",
                editorOptions:{
                    type: "time",
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat: 'HH:mm',
                    pickerType:"rollers"
                }
            },
            {
                dataField: 'biaya',
                label: {
                    text: 'Biaya Perhari'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    onValueChanged : (e) => {
                        if (e.value && !this.disableEvent) {
                            let date1 = this.formRef.current.instance.getEditor('checkinDate').option('value')
                            let date2 = this.formRef.current.instance.getEditor('chekoutDate').option('value')
    
                            let diff = new Date(date2).getTime() - new Date(date1).getTime()
                            let totalDays = Math.ceil(diff / (1000 * 3600 * 24));
                            this.formRef.current.instance.getEditor('totalBiaya').option('value',totalDays*e.value)
                        }
                    }
                }
            },
            {
                dataField: 'totalBiaya',
                label: {
                    text: 'Total Biaya'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly:true
                }
            },
            {
                dataField: 'alamat',
                label: {
                    text: 'Alamat',
                },
            },
            // {
            //     dataField: 'keterangan',
            //     label: {
            //         text: 'Keterangan'
            //     }
            // },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    getSppdId = (sppdId) => {
        this.sppdId = sppdId
    }

    submitData = async() => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.checkinDate = formatDate(DataMaster.checkinDate)
            DataMaster.chekoutDate = formatDate(DataMaster.chekoutDate)
            // DataMaster.jamCheckin = new Date(DataMaster.jamCheckin).toISOString()
            // DataMaster.jamCheckout = new Date(DataMaster.jamCheckout).toISOString()
            DataMaster.jamCheckin = null;
            DataMaster.jamCheckout = null;
            DataMaster.karyawanSppdId = this.sppdId

            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-hotels`,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    updateData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.checkinDate = formatDate(DataMaster.checkinDate)
            DataMaster.chekoutDate = formatDate(DataMaster.chekoutDate)
            DataMaster.jamCheckin = null
            DataMaster.jamCheckout = null
            DataMaster.karyawanSppdId = this.sppdId

            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-hotels`,
                "PUT",{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    retrieveData = (data) => {
        this.disableEvent = true
        data.jamCheckin = new Date(data.jamCheckin) 
        data.jamCheckout = new Date(data.jamCheckout) 
        this.setState({
            DataMaster: data,
            DataDocument: data,
        })
        this.props.forceRefresh()
        this.disableEvent = false
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}name`] = files.name;
            DataDocument[`${fieldName}`] = formattedFileData.base64data;
            DataDocument[`${fieldName}ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };

    
          fr.readAsDataURL(files);
        }
    }

    previewFile = async (fileName) => {
        var src = imageSource(this.state.DataDocument[`${fileName}ContentType`], this.state.DataDocument[`${fileName}`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataDocument[`${fileName}ContentType`], this.state.DataDocument[`${fileName}`]), this.state.DataDocument[`${fileName}name`])
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.sppdId = 0
        this.setState({
            popupVisible: false,
            DataMaster: {},
            DataDocument:{},
            uploadData:[]
        })
    }
    
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Form Tiket"}
                width={"55vw"}
                height={"55vh"}
                toolbarItems={this.props.type == 'edit' ? this.PopupToolbarItem2 : this.PopupToolbarItem}
                >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formTiketHotel'}
                            formData={this.state.DataMaster}
                            items={this.formTiketHotel}
                            labelLocation = 'left'
                        />
                        {/* Ijazah */}
                        <FileUploader
                                disabled={this.props.action == 'view' ? true : false}
                                accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText="Tiket File Penginapan"
                                showFileList={false}
                                name={"dokumenTiketFile"}
                                value={this.state.uploadData}
                                ref={this.fileUploderRef}
                            />
                            <div className="row">
                                <label className="col-2">File Name:</label>
                                <label className="col-4">{ this.state.DataDocument.dokumenTiketFilename}</label>
                            </div>
                            <div className="col-md-4 text-right">
                                <button onClick={() => this.previewFile('dokumenTiketFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenTiketFilename ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('dokumenTiketFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenTiketFilename ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormTiketHotel