import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest'
import { akunInvestasi, banks, broker, currency, custodies, product, productDefault } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import { DataMasterBank } from 'components/pages/parts/staticDataColumnConfig';


class ModalRekeningBankInvestasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            action: 'add'
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.updateData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        // this.dataMaster = [
        //     {
        //         dataField: 'productId',
        //         label: {
        //             text: 'Kode Produk'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: productDefault(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'productCode',
        //             readOnly: true
        //         }
        //     },
        //     {
        //         dataField: 'rekNo',
        //         label: {
        //             text: 'No. Rekening'
        //         }
        //     },
        //     {
        //         dataField: 'rekName',
        //         label: {
        //             text: 'Nama Rekening'
        //         }
        //     },
        //     {
        //         dataField: 'bankId',
        //         label: {
        //             text: 'Bank'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: banks(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'bankName',
        //             searchEnabled: true,
        //             deferRendering: false,
        //             openOnFieldClick: true
        //         }
        //     },
        //     {
        //         dataField: 'brokerId',
        //         label: {
        //             text: 'Broker'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: broker(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'brokerName',
        //             searchEnabled: true,
        //             deferRendering: false,
        //             openOnFieldClick: true
        //         }
        //     },
        //     {
        //         dataField: 'custodyId',
        //         label: {
        //             text: 'Custody'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: custodies(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'custodyName',
        //             searchEnabled: true,
        //             deferRendering: false,
        //             openOnFieldClick: true
        //         }
        //     },
        //     {
        //         dataField: 'cabang',
        //         label: {
        //             text: 'Cabang'
        //         }
        //     },
        //     {
        //         dataField: 'rekCustodian',
        //         label: {
        //             text: 'Rekening Kustodi'
        //         },
        //         editorType: 'dxCheckBox'
        //     },
        //     {
        //         dataField: 'jenis',
        //         label: {
        //             text: 'Jenis Rekening'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: [
        //                 {
        //                     value: 'Giro'
        //                 },
        //                 {
        //                     value: 'Tabungan'
        //                 },
        //                 {
        //                     value: 'Deposito'
        //                 },
        //             ],
        //             valueExpr: 'value',
        //             displayExpr: 'value',
        //             searchEnabled: true,
        //             deferRendering: false,
        //             openOnFieldClick: true
        //         }
        //     },
        //     // {
        //     //     dataField: 'currencyId',
        //     //     label: {
        //     //         text: 'Mata Uang'
        //     //     },
        //     //     editorType: 'dxSelectBox',
        //     //     editorOptions: {
        //     //         dataSource: currency(this.props.store),
        //     //         valueExpr: 'id',
        //     //         displayExpr: 'currencyName',
        //     //         searchEnabled: true,
        //     //         deferRendering: false,
        //     //         openOnFieldClick: true
        //     //     },
        //     // },
        //     {
        //         dataField: 'statusId',
        //         label: {
        //             text: 'Status'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: [
        //                 {
        //                     id: 0,
        //                     value: 'Non Aktif'
        //                 },
        //                 {
        //                     id: 1,
        //                     value: 'Aktif'
        //                 },
        //             ],
        //             valueExpr: 'id',
        //             displayExpr: 'value',
        //             searchEnabled: true,
        //             deferRendering: false,
        //             openOnFieldClick: true
        //         }
        //     },
        //     {
        //         dataField: 'akunId',
        //         label: {
        //             text: 'Akun Rekening'
        //         },
        //         editorType: 'dxSelectBox',
        //         editorOptions: {
        //             dataSource: akunInvestasi(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'akunName',
        //             searchEnabled: true,
        //             deferRendering: false,
        //             openOnFieldClick: true
        //         }
        //     },
        //     {
        //         dataField: 'keterangan',
        //         label: {
        //             text: 'Keterangan'
        //         },
        //     },
        // ]
        this.dataMaster = DataMasterBank(this.props.store)
    }
    get Popup() {
        return this.PopupRef.current.instance
    }
    show = async (type) => {
        this.setState({
            popupVisible: true,
            action: type,
            dataMaster: {
                productId: 1
            }
        })
    }
    retrieveData = async(type, data) => {
        var dataRek = []
        if (data.id) {
            dataRek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-banks/${data.id}`, 'GET')
        }

        this.setState({
            dataMaster: data,
        })
    }

    submitData = async () => {
        var data = this.state.dataMaster
        if (data) {
            try {
                let type = 'success'
                let text = 'Sukses input Data!'
                var insertAPI = `staticdata-wf/rek-banks`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: data,
                })
                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.props.forceRefresh()
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    updateData = async() => {
        var data = this.state.dataMaster
        if(data) {
            try {
                let type = 'success'
                let text = 'Sukses Ubah Data!'
                var updateAPI = `staticdata-wf/rek-banks`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                    values: data,
                    key: data.id
                }, data)
                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.props.forceRefresh()
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }
    
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'55vw'}
                    height="60vh"
                    title={`Form Edit Rekening`}
                    ref={this.PopupRef}
                    toolbarItems={this.props.actionType === 'add' ? this.popupToolbarItem : this.popupToolbarItem2}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'dataMaster'}
                                        formData={this.state.dataMaster}
                                        items={this.dataMaster}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalRekeningBankInvestasi