import React, { Component } from 'react'
import { Popup, ScrollView, Form, FileUploader } from 'devextreme-react'
import { productDefault, custodies, rekeningBank, banks, currency, kpds, months, pejabat, rekCustody } from 'dataSource/lookup'
import { download, formatDate, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";
import { confirm } from 'devextreme/ui/dialog';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage'

class ModalReverse extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.idBiaya = 0

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submit.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                caption: 'REVERSE',
                items: [
                    {
                        dataField: 'bankTransactionId',
                        label: {
                            alignment: 'left',
                            text: 'ID'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tglReverse',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Reverse'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                messsage: 'Tanggal Reverse tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
        ]
    }

    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: {
                bankTransactionId: data.id,
                noKonfirmasi: data.nomorKonfirmasi
            }
        });
    }
    submit = async () => {
        if (this.state.dataMaster) {
            let result = await confirm('Konfirmasi Reverse sekarang?', "Konfirmasi")
            var insertAPI = `bank-transactions/transaksi-reverse-create/${this.state.dataMaster.bankTransactionId}/${this.state.dataMaster.noKonfirmasi}/${formatDate(this.state.dataMaster.tglReverse)}`
            if (result) {
                try {
                    var response = await httpRequest(
                        process.env.REACT_APP_BACKEND_INVESTASI,
                        this.props.store,
                        insertAPI,
                        'POST',
                        { values: {} }
                    )
                    if (response) {
                        this.hide()
                        this.props.forceRefresh()
                        notify({ message: `Reverse Sukses!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
                    }
                } catch (e) {
                    console.log(e)
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600)
                }
            }
        }
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData: {}
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Konfirmasi Reverse Transaksi Bank'}
                width={'30vw'}
                height={'35vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.dataMaster}
                            items={this.dataMaster}
                            scrollingEnabled={true}
                            labelLocation={"left"}
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalReverse