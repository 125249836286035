import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react';
import { asetKategoriSdm, sdmUnit, subAsetKategoriSdm, subAsetKategoriSdmByAssetCategoryId} from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';

class ModalUmumPengajuan extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster: {},
            popupVisible:false,
            actionType: '' 
        }

        this.modalPopupModalUmumPengajuanRef = React.createRef()
        this.formRef = React.createRef()

        this.actionType = 'add'
        this.formPotongan = [
            {
                dataField:"noPengadaan",
                label:{
                    text:"No Pengajuan"
                },
                editorOptions: {
                    readOnly : true,
                }
            },
            {
                dataField:"namaAset",
                label:{
                    text:"Nama Aset"
                },
                editorOptions: {
                    readOnly : true,
                }
            },
            {
                dataField: 'pengadaanDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pengadaan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true,
                    readOnly : true,
                }  
            },
            {
                dataField:"unitId",
                label:{
                    text:"Unit Kerja",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly : true,
                },
            },
            {
                dataField:"assetKategoriId",
                label:{
                    text:"Kategori Aset",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: asetKategoriSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categoryName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (e) => {
                        const assetKategoriSubIdEditor = this.formRef.current.instance.getEditor('assetKategoriSubId')

                        assetKategoriSubIdEditor.option('dataSource',subAsetKategoriSdmByAssetCategoryId(this.props.store,e.value))
                    },
                    readOnly : true,
                },
            },
            {
                dataField:"assetKategoriSubId",
                label:{
                    text:"Sub Kategori Aset",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: subAsetKategoriSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categorySubName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly : true,
                },
            },
            {
                dataField:"merek",
                label:{
                    text:"Merk",
                },
                editorOptions: {
                    readOnly : true,
                }
            },
            {
                dataField:"jumlah",
                label:{
                    text:"Jumlah Pengadaan",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0',
                    readOnly : true,
                }
            },
            {
                dataField:"hargaPerkiraan",
                label:{
                    text:"Harga Perkiraan",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0',
                    readOnly : true,
                }
            },
            {
                dataField:"ketSpesifikasi",
                label:{
                    text:"Spesifikasi",
                },
                editorType:'dxTextArea',
                editorOptions:{
                    height: '100px',
                    readOnly : true,
                },
            },
        ]
    }

    retrieveData = (data) => {
        this.formRef.current.instance.updateData(data)
    }

    show = (viewOnly = false) => {
        if(!viewOnly){
            this.modalPopupModalUmumPengajuanRef.current.instance.option('toolbarItems',[
                {
                  widget: "dxButton",
                  location: "after",
                  options: {
                    text: "Simpan",
                    onClick: () => {
                        this.submitData()
                    }
                  },
                  toolbar: "bottom"
                },
                {
                  widget: "dxButton",
                  location: "after",
                  options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                  },
                  toolbar: "bottom"
                },
            ])
        }else{
            this.modalPopupModalUmumPengajuanRef.current.instance.option('toolbarItems',[
                {
                  widget: "dxButton",
                  location: "after",
                  options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                  },
                  toolbar: "bottom"
                },
            ])
        }

        this.setState({
            popupVisible: true,
            DataMaster: {
                noPengadaan: 'AUTO'
            }
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    submitData = async() => {
        var data = this.state.DataMaster
        var type = this.actionType
        try{
            var loadAPI = 'asset-pengadaans/create'
            if(type == 'add'){ 
                var postData = {
                    "namaAset": data.namaAset,
                    "jumlah": data.jumlah,
                    "hargaPerkiraan": data.hargaPerkiraan,
                    "ketSpesifikasi": data.ketSpesifikasi,
                    "merek": data.merek,
                    "requestBy": this.props.store.getState().sessionUser,
                    "requestDate": formatDate(new Date()),
                    "pengadaanDate": formatDate(data.pengadaanDate),
                    "pengajuanDate": formatDate(new Date()),
                    "assetKategoriId" : data.assetKategoriId,
                    "assetKategoriSubId" : data.assetKategoriSubId,
                    "unitId": data.unitId,
                  }

                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,'POST',{
                    values: postData
                })
                await alert('Data Pengajuan Berhasil Disimpan', 'Information')
            }else{
                var loadAPI = 'asset-pengadaans'
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,'PUT',{
                    key : data.id,
                    values: data
                },data)
                await alert('Ubah Data Berhasil Dilakukan', 'Information')
            }
            this.hide()
            this.props.forceRefresh()
        }catch(e){
            console.log(e)
            await alert(e, 'Error')
        }

    }

    retrieveData = async(data, type) => {
        this.setState({
            DataMaster: data,
            content : data.dasarPengajuan
        })

        this.actionType = type
    }

    onValueChanged = (e) => {
        this.setState({
            content: e.value
        })
    }
    render() { 
        return (
            <Popup
                ref={this.modalPopupModalUmumPengajuanRef}
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Dokumen Pengajuan Pengadaan'} 
                minWidth={700}
                minHeight={300}
                // toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formPotongan'}
                                formData={this.state.DataMaster}
                                items={this.formPotongan}
                                labelLocation = 'left'
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalUmumPengajuan