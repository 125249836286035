import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, banks, months, product, productDefault, programs } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, formatDefaultMonthYear, formatNumberAum } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { confirm, alert } from 'devextreme/ui/dialog';
import { showLoading } from 'redux/actions/loading';
import notify from 'devextreme/ui/notify';
import { reportFile } from 'plugin/reportRequest'
import { confirmAlert } from 'react-confirm-alert'

class LaporanDaftarTransaksiGaji extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.dgRef = React.createRef()

        this.state = {
            filterData: {
                programId: 1,
                monthPeriode: this.sysDate ? new Date(this.sysDate.dateValue).getMonth() + 1 : null,
                yearPeriode: this.sysDate ? new Date(this.sysDate.dateValue).getFullYear() : null,
            }
        }

        this.dataSource= [];

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'jabatanName'
            },
            {
                name: 'gajiPokok',
                column: 'gajiPokok',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'jumTunj',
                column: 'jumTunj',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'potJht',
                column: 'potJht',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'penghBrutoPajak',
                column: 'penghBrutoPajak',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'jumPot',
                column: 'jumPot',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'penghasilanNett',
                column: 'penghasilanNett',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'potonganJabatan',
                column: 'potonganJabatan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'ptkp',
                column: 'ptkp',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'pkp',
                column: 'pkp',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'pphSetahun',
                column: 'pphSetahun',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'pphSebulan',
                column: 'pphSebulan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nominalTransfer',
                column: 'nominalTransfer',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Laporan Slip Gaji',
                        hint: 'Laporan Slip Gaji',
                        onClick: (e) => {
                            this.print(e.row.data)
                        }
                    }
                ]
            },
            {
                dataField: 'bulan',
                caption: 'Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Tahun',
            },
            {
                dataField: 'nik',
                caption: 'NIP',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Karyawan',
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan',
            },
            {
                dataField: 'gajiPokok',
                caption: 'Gaji Pokok',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'jumTunj',
                caption: 'Total Tunjangan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'potJht',
                caption: 'POT. JHT/JP',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'penghBrutoPajak',
                caption: 'Gaji Bruto Pajak',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'jumPot',
                caption: 'Total Potongan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'penghasilanNett',
                caption: 'Penghasilan Nett',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'potonganJabatan',
                caption: 'Potongan Jabatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'ptkp',
                caption: 'PTKP',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'pkp',
                caption: 'PKP',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'pphSetahun',
                caption: 'PPH Setahun',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'pphSebulan',
                caption: 'PPH Sebulan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nominalTransfer',
                caption: 'Nominal Transfer',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'gajiRekNo',
                caption: 'Rek Nomor',
            },
            {
                dataField: 'gajiRekName',
                caption: 'Rek Nama',
            },
            {
                dataField: 'gajiRekBankId',
                caption: 'Rek Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'gajiRekBranch',
                caption: 'Rek Cabang',
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.program
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'monthPeriode',
                        label: {
                            text: 'Periode'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'yearPeriode',
                        label: {
                            visible: false,
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.filterSubmit();
                                // this.dgRef.current.forceRefresh(true);
                                // this.setState({
                                //     filterData: this.state.filterData
                                // }, () => {
                                //     console.log(this.state.filterData);
                                // })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]
        this.selectedRowDatas = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    print = async (data) => {
        var slipGajiDetailTunjangan = [];
        var slipGajiDetailPotongan = [];

        var responseMaster = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `view-report-gaji-daftar-transaksis/${data.id}`, 'GET');

        var loadAPIRekapGaji = `pembayaran-umums/getRekapGaji/${data.karyawanPayrollId}/1/${data.tahun}/${data.bulan}`;
        var responseRekapGaji = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIRekapGaji, 'GET');

        var dplkRoundAskom = responseRekapGaji[0].p26 * responseRekapGaji[0].t18;

        var dataResultTunjangan = {
            tpph: responseRekapGaji[0].t16 ? formatNumberAum(responseRekapGaji[0].t16) : 0,
            dplkAskomTunjanganBisnis: formatNumberAum(dplkRoundAskom),
            pph: responseRekapGaji[0].p51 ? formatNumberAum(responseRekapGaji[0].p51) : 0,
            bpjsPerTgk: responseRekapGaji[0].t20 ? formatNumberAum(responseRekapGaji[0].t20) : 0,
            bpjsPerKes: responseRekapGaji[0].t21 ? formatNumberAum(responseRekapGaji[0].t21) : 0
        }

        slipGajiDetailTunjangan.push(dataResultTunjangan);

        var dataResultPotongan = {
            bpjsPerTgkPotongan: responseRekapGaji[0].t20 ? formatNumberAum(responseRekapGaji[0].t20) : 0,
            bpjsPerKrywnTgk: responseRekapGaji[0].p17 ? formatNumberAum(responseRekapGaji[0].p17) : 0,
            koperasiKresna: responseRekapGaji[0].p18 ? formatNumberAum(responseRekapGaji[0].p18) : 0,
            bpjsKesKrywn: responseRekapGaji[0].p19 ? formatNumberAum(responseRekapGaji[0].p19) : 0,
            potonganUpah: responseRekapGaji[0].p20 ? formatNumberAum(responseRekapGaji[0].p20) : 0,
            potonganIntensif: responseRekapGaji[0].p21 ? formatNumberAum(responseRekapGaji[0].p21) : 0,
            bpjsKesPer: responseRekapGaji[0].p11 ? formatNumberAum(responseRekapGaji[0].p11) : 0,
            ykpp: responseRekapGaji[0].t11 ? formatNumberAum(responseRekapGaji[0].t11) : 0,
            potSp: responseRekapGaji[0].p25 ? formatNumberAum(responseRekapGaji[0].p25) : 0,
            dplkAskom: responseRekapGaji[0].p26 ? formatNumberAum(responseRekapGaji[0].p26) : 0
        }

        slipGajiDetailPotongan.push(dataResultPotongan);

        var gajiKotor = responseMaster.gajiPokok + responseRekapGaji[0].t15 + responseRekapGaji[0].t16 + dplkRoundAskom + responseRekapGaji[0].p51 + responseRekapGaji[0].t20 + responseRekapGaji[0].t21;

        var totalPotongan = responseRekapGaji[0].t20 + responseRekapGaji[0].p17 + responseRekapGaji[0].p18 + responseRekapGaji[0].p19 + responseRekapGaji[0].p20 + responseRekapGaji[0].p21 + responseRekapGaji[0].p11 + responseRekapGaji[0].t11 + responseRekapGaji[0].p25 + responseRekapGaji[0].p26;

        var gajiNeto = gajiKotor - totalPotongan;


        if (responseMaster !== null) {
            var data = {
                template: {
                    'shortid': '35adu09',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "periodeBulan": responseMaster.bulan === 1 ? 'Januari' : responseMaster.bulan === 2 ? 'Februari' : responseMaster.bulan === 3 ? 'Maret' : responseMaster.bulan === 4 ? 'April' : responseMaster.bulan === 5 ? 'Mei' : responseMaster.bulan === '6' ? 'Juni' : responseMaster.bulan === 7 ? 'Juli' : responseMaster.bulan === 8 ? 'Agustus' : responseMaster.bulan === 9 ? 'September' : responseMaster.bulan === 10 ? 'Oktober' : responseMaster.bulan === 11 ? 'November' : responseMaster.bulan === 12 ? 'Desember' : '',
                    "periodeTahun": responseMaster.tahun,
                    "nik": responseMaster.nik,
                    "name": responseMaster.fullName,
                    "jabatan": responseMaster.jabatanName,
                    "statusPajak": responseRekapGaji[0].sts_pajak,
                    "gapok": responseMaster.gajiPokok ? formatNumberAum(responseMaster.gajiPokok) : 0,
                    "intensifKinerjaRapel": responseRekapGaji[0].t15 ? formatNumberAum(responseRekapGaji[0].t15) : 0,
                    "detailTunjangan": slipGajiDetailTunjangan,
                    "detailPotongan": slipGajiDetailPotongan,
                    "gajiKotor": formatNumberAum(gajiKotor),
                    "totalPotongan": formatNumberAum(totalPotongan),
                    "gajiNetto": formatNumberAum(gajiNeto)
                },
            }
            reportFile(data, true, 'Laporan-Slip-Gaji.pdf');
        } else {
            confirmAlert({
                title: 'Tidak ditemukan!',
                message: 'Data tidak ditemukan',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
        }
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `view-report-gaji-daftar-transaksis?bulan.in=${this.state.filterData.monthPeriode}&tahun.in=${this.state.filterData.yearPeriode}&size=99999`);
        let response = this.selectedRowDatas;
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `view-report-gaji-daftar-transaksis?size=9999`);
        let store = this.props.store;
        let arrGapok = [];
        let arrTotTunj = [];
        let arrPotJht = [];
        let arrGaBruPak = [];
        let arrToPot = [];
        let arrPengNett = [];
        let arrPotJab = [];
        let arrPtkp = [];
        let arrPkp = [];
        let arrPphSet = [];
        let arrPphSeb = [];
        let arrNomTrans = [];
        store.dispatch(showLoading(true));

        for (let value of response) {
            var responseDataBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `banks/${value.gajiRekBankId}`, 'GET');
            const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            var bulan = months[value.bulan - 1]
            // if (bulan.length < 2){
            //     bulan = '0' + bulan;
            // }
            arrGapok.push(value.gajiPokok);
            arrTotTunj.push(value.jumTunj);
            arrPotJht.push(value.potJht);
            arrGaBruPak.push(value.penghBrutoPajak);
            arrToPot.push(value.jumPot);
            arrPengNett.push(value.penghasilanNett);
            arrPotJab.push(value.potonganJabatan);
            arrPtkp.push(value.ptkp);
            arrPkp.push(value.pkp);
            arrPphSet.push(value.pphSetahun);
            arrPphSeb.push(value.pphSebulan);
            arrNomTrans.push(value.nominalTransfer);
            let dataValue = {
                // bulan: value.bulan === 1 ? 'Januari' : value.bulan === 2 ? 'Februari' : value.bulan === 3 ? 'Maret' : value.bulan === 4 ? 'April' : value.bulan === 5 ? 'Mei' : value.bulan === '6' ? 'Juni' : value.bulan === 7 ? 'Juli' : value.bulan === 8 ? 'Agustus' : value.bulan === 9 ? 'September' : value.bulan === 10 ? 'Oktober' : value.bulan === 11 ? 'November' : value.bulan === 12 ? 'Desember' : '',
                bulan: bulan,
                tahun: value.tahun ? value.tahun : '-',
                nik: value.nik ? value.nik : '-',
                fullName: value.fullName ? value.fullName : '-',
                jabatanName: value.jabatanName ? value.jabatanName : '-',
                gajiPokok: value.gajiPokok ? (value.gajiPokok) : 0,
                jumTunj: value.jumTunj ? (value.jumTunj) : 0,
                potJht: value.potJht ? (value.potJht) : 0,
                penghBrutoPajak: value.penghBrutoPajak ? (value.penghBrutoPajak) : 0,
                jumPot: value.jumPot ? (value.jumPot) : 0,
                penghasilanNett: value.penghasilanNett ? (value.penghasilanNett) : 0,
                potonganJabatan: value.potonganJabatan ? (value.potonganJabatan) : 0,
                ptkp: value.ptkp ? (value.ptkp) : 0,
                pkp: value.pkp ? (value.pkp) : 0,
                pphSetahun: value.pphSetahun ? (value.pphSetahun) : 0,
                pphSebulan: value.pphSebulan ? (value.pphSebulan) : 0,
                nominalTransfer: value.nominalTransfer ? (value.nominalTransfer) : 0,
                gajiRekNo: value.gajiRekNo ? value.gajiRekNo : '-',
                gajiRekName: value.gajiRekName,
                gajiRekBankId: responseDataBank.bankName,
                // gajiRekNama: value.gajiRekNama ? (value.gajiRekNama) : 0,
                // gajiRekBankId: value.gajiRekBankId === 3254 ? 'BANK NEGARA INDONESIA' : value.gajiRekBankId === 2 ? 'Bank Mandiri' : '-',
                // gajiRekBankId: dataGrid.gajiRekBankId === null ? '-' : getBank.bankName,
                // gajiRekBankId: idBank,
                // gajiRekBankId: getBankId.bankName,
                gajiRekBranch: value.gajiRekBranch,
            }

            const objectValue = Object.values(dataValue);
            data.push(objectValue);

            var totalGapok = arrGapok.reduce((a, b) => a + b, 0);
            var totTunj = arrTotTunj.reduce((a, b) => a + b, 0);
            var TotPotJht = arrPotJht.reduce((a, b) => a + b, 0);
            var totGaBruPak = arrGaBruPak.reduce((a, b) => a + b, 0);
            var totPot = arrToPot.reduce((a, b) => a + b, 0);
            var totPengNett = arrPengNett.reduce((a, b) => a + b, 0);
            var totPotJab = arrPotJab.reduce((a, b) => a + b, 0);
            var totPtkp = arrPtkp.reduce((a, b) => a + b, 0);
            var totPkp = arrPkp.reduce((a, b) => a + b, 0);
            var totPphSet = arrPphSet.reduce((a, b) => a + b, 0);
            var totPphSeb = arrPphSeb.reduce((a, b) => a + b, 0);
            var totNomTrans = arrNomTrans.reduce((a, b) => a + b, 0);
        }
        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('LAPORAN TRANSAKSI GAJI');
            // let headStyle = {header:'Ultima visita',key:'last_visit',style: { numFmt: 'DD/MM/YYYY HH:mm:ss' },width: 22};
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,
                },
                columns: [
                    { name: 'Bulan' },
                    { name: 'Tahun' },
                    { name: 'NIP' },
                    { name: 'Nama Karyawan' },
                    { name: 'Jabatan' },
                    { name: 'Gaji Pokok' },
                    { name: 'Total Tunjangan' },
                    { name: 'POT.JHT/JP' },
                    { name: 'Gaji Bruto Pajak' },
                    { name: 'Total Potongan' },
                    { name: 'Penghasilan Nett' },
                    { name: 'Potongan Jabatan' },
                    { name: 'PTKP' },
                    { name: 'PKP' },
                    { name: 'PPH Setahun' },
                    { name: 'PPH Sebulan' },
                    { name: 'Nominal Transfer' },
                    { name: 'Rek Nomor' },
                    { name: 'Rek Nama' },
                    { name: 'Rek Bank' },
                    { name: 'Rek Cabang' },
                ],
                rows: data
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                }))
            }

            for (let i = 9; i <= 10000; i++) {
                worksheet.getCell('F' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('G' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('H' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('I' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('J' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('K' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('L' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('M' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('N' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('O' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('P' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('Q' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                // worksheet.getCell('R'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('S' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('U' + i).alignment = { vertical: 'middle', horizontal: 'right' };
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = { size: 18, bold: true }

            worksheet.getCell('E3').value = 'LAPORAN TRANSAKSI GAJI';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = { size: 18, bold: true }

            worksheet.mergeCells(`A${lastTableRowNum + 1}:E${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = { horizontal: 'center' };
            worksheet.getCell(`A${lastTableRowNum + 1}`).font = { size: 12, bold: true }
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.mergeCells(`R${lastTableRowNum + 1}:U${lastTableRowNum + 1}`);
            worksheet.getCell(`R${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`F${lastTableRowNum + 1}`).value = (totalGapok);
            worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`G${lastTableRowNum + 1}`).value = (totTunj);
            worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`H${lastTableRowNum + 1}`).value = (TotPotJht);
            worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`I${lastTableRowNum + 1}`).value = (totGaBruPak);
            worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`J${lastTableRowNum + 1}`).value = (totPot);
            worksheet.getCell(`J${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`K${lastTableRowNum + 1}`).value = (totPengNett);
            worksheet.getCell(`K${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`L${lastTableRowNum + 1}`).value = (totPotJab)
            worksheet.getCell(`L${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`M${lastTableRowNum + 1}`).value = (totPtkp);
            worksheet.getCell(`M${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`N${lastTableRowNum + 1}`).value = (totPkp);
            worksheet.getCell(`N${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`O${lastTableRowNum + 1}`).value = (totPphSet);
            worksheet.getCell(`O${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`P${lastTableRowNum + 1}`).value = (totPphSeb);
            worksheet.getCell(`P${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`Q${lastTableRowNum + 1}`).value = (totNomTrans);
            worksheet.getCell(`Q${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums2;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums2;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;
            Data6.numFmt = '#,##0.00';

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;
            Data7.numFmt = '#,##0.00';

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;
            Data8.numFmt = '#,##0.00';

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;
            Data9.numFmt = '#,##0.00';

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;
            Data10.numFmt = '#,##0.00';

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;
            Data11.numFmt = '#,##0.00';

            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;
            Data12.numFmt = '#,##0.00';

            const Data13 = worksheet.getColumn(13);
            Data13.width = WidthColums;
            Data13.numFmt = '#,##0.00';

            const Data14 = worksheet.getColumn(14);
            Data14.width = WidthColums;
            Data14.numFmt = '#,##0.00';

            const Data15 = worksheet.getColumn(15);
            Data15.width = WidthColums;
            Data15.numFmt = '#,##0.00';

            const Data16 = worksheet.getColumn(16);
            Data16.width = WidthColums;
            Data16.numFmt = '#,##0.00';

            const Data17 = worksheet.getColumn(17);
            Data17.width = WidthColums;
            Data17.numFmt = '#,##0.00';

            const Data18 = worksheet.getColumn(18);
            Data18.width = WidthColums;

            const Data19 = worksheet.getColumn(19);
            Data19.width = WidthColums;
            Data19.numFmt = '#,##0.00';

            const Data20 = worksheet.getColumn(20);
            Data20.width = WidthColums2;

            const Data21 = worksheet.getColumn(21);
            Data21.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_TRANSAKSI_GAJI.xlsx`
                );
            });
        } catch (error) {
            console.log(error);
        }
        store.dispatch(showLoading(false))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowDatas.length > 0) {
                            // console.log(this.selectedRowDatas);
                            this.exportExcel()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: async (e) => {
                        if (this.selectedRowDatas.length === 0) {
                            alert('Tidak ada data yang di pilih', 'Peringatan!');

                            return null
                        }

                        for (const selectedData of this.selectedRowDatas) {
                            await this.printMulti(selectedData)
                        }
                    },
                },
                visible: false
            }
        )
    }

    printMulti = async (selectedData) => {
        this.props.store.dispatch(showLoading(true));

        var slipGajiDetailTunjangan = [];
        var slipGajiDetailPotongan = [];

        var responseMaster = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `view-report-gaji-daftar-transaksis/${selectedData.id}`, 'GET');

        var loadAPIRekapGaji = `pembayaran-umums/getRekapGaji/${selectedData.karyawanPayrollId}/1/${selectedData.tahun}/${selectedData.bulan}`;
        var responseRekapGaji = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPIRekapGaji, 'GET');

        var dplkRoundAskom = responseRekapGaji[0].p26 * responseRekapGaji[0].t18;

        var dataResultTunjangan = {
            tpph: responseRekapGaji[0].t16 ? formatNumberAum(responseRekapGaji[0].t16) : 0,
            dplkAskomTunjanganBisnis: formatNumberAum(dplkRoundAskom),
            pph: responseRekapGaji[0].p51 ? formatNumberAum(responseRekapGaji[0].p51) : 0,
            bpjsPerTgk: responseRekapGaji[0].t20 ? formatNumberAum(responseRekapGaji[0].t20) : 0,
            bpjsPerKes: responseRekapGaji[0].t21 ? formatNumberAum(responseRekapGaji[0].t21) : 0
        }

        slipGajiDetailTunjangan.push(dataResultTunjangan);

        var dataResultPotongan = {
            bpjsPerTgkPotongan: responseRekapGaji[0].t20 ? formatNumberAum(responseRekapGaji[0].t20) : 0,
            bpjsPerKrywnTgk: responseRekapGaji[0].p17 ? formatNumberAum(responseRekapGaji[0].p17) : 0,
            koperasiKresna: responseRekapGaji[0].p18 ? formatNumberAum(responseRekapGaji[0].p18) : 0,
            bpjsKesKrywn: responseRekapGaji[0].p19 ? formatNumberAum(responseRekapGaji[0].p19) : 0,
            potonganUpah: responseRekapGaji[0].p20 ? formatNumberAum(responseRekapGaji[0].p20) : 0,
            potonganIntensif: responseRekapGaji[0].p21 ? formatNumberAum(responseRekapGaji[0].p21) : 0,
            bpjsKesPer: responseRekapGaji[0].p11 ? formatNumberAum(responseRekapGaji[0].p11) : 0,
            ykpp: responseRekapGaji[0].t11 ? formatNumberAum(responseRekapGaji[0].t11) : 0,
            potSp: responseRekapGaji[0].p25 ? formatNumberAum(responseRekapGaji[0].p25) : 0,
            dplkAskom: responseRekapGaji[0].p26 ? formatNumberAum(responseRekapGaji[0].p26) : 0
        }

        slipGajiDetailPotongan.push(dataResultPotongan);


        var gajiKotor = responseMaster.gajiPokok + responseRekapGaji[0].t15 + responseRekapGaji[0].t16 + dplkRoundAskom + responseRekapGaji[0].p51 + responseRekapGaji[0].t20 + responseRekapGaji[0].t21;

        var totalPotongan = responseRekapGaji[0].t20 + responseRekapGaji[0].p17 + responseRekapGaji[0].p18 + responseRekapGaji[0].p19 + responseRekapGaji[0].p20 + responseRekapGaji[0].p21 + responseRekapGaji[0].p11 + responseRekapGaji[0].t11 + responseRekapGaji[0].p25 + responseRekapGaji[0].p26;

        var gajiNeto = gajiKotor - totalPotongan;

        if (responseMaster !== null) {
            var data = {
                template: {
                    'shortid': '35adu09'
                },
                data: {
                    "periodeBulan": responseMaster.bulan === 1 ? 'Januari' : responseMaster.bulan === 2 ? 'Februari' : responseMaster.bulan === 3 ? 'Maret' : responseMaster.bulan === 4 ? 'April' : responseMaster.bulan === 5 ? 'Mei' : responseMaster.bulan === '6' ? 'Juni' : responseMaster.bulan === 7 ? 'Juli' : responseMaster.bulan === 8 ? 'Agustus' : responseMaster.bulan === 9 ? 'September' : responseMaster.bulan === 10 ? 'Oktober' : responseMaster.bulan === 11 ? 'November' : responseMaster.bulan === 12 ? 'Desember' : '',
                    "periodeTahun": responseMaster.tahun,
                    "nik": responseMaster.nik,
                    "name": responseMaster.fullName,
                    "jabatan": responseMaster.jabatanName,
                    "statusPajak": responseRekapGaji[0].sts_pajak,
                    "gapok": responseMaster.gajiPokok ? formatNumberAum(responseMaster.gajiPokok) : 0,
                    "intensifKinerjaRapel": responseRekapGaji[0].t15 ? formatNumberAum(responseRekapGaji[0].t15) : 0,
                    "detailTunjangan": slipGajiDetailTunjangan,
                    "detailPotongan": slipGajiDetailPotongan,
                    "gajiKotor": formatNumberAum(gajiKotor),
                    "totalPotongan": formatNumberAum(totalPotongan),
                    "gajiNetto": formatNumberAum(gajiNeto)
                },
            }
            reportFile(data, true, 'Laporan-Slip-Gaji.pdf');
        } else {
            confirmAlert({
                title: 'Tidak ditemukan!',
                message: 'Data tidak ditemukan',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
        }
        this.props.store.dispatch(showLoading(false));
    }

    // componentDidMount = async () => {
    //     let loadAPI = `view-report-gaji-daftar-transaksis?bulan.in=${this.state.filterData.monthPeriode}&size=9999&tahun.in=${this.state.filterData.yearPeriode}`;
    //     let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET');
    //     let data = [];
    //     let counter = 0;

    //     for (let value of response) {
    //         // const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    //         // var bulan = months[value.bulan - 1]
    //         let dataValue = {
    //             id: counter++,
    //             bulan: value.bulan,
    //             tahun: value.tahun ? value.tahun : '-',
    //             nik: value.nik ? value.nik : '-',
    //             fullName: value.fullName ? value.fullName : '-',
    //             jabatanName: value.jabatanName ? value.jabatanName : '-',
    //             gajiPokok: value.gajiPokok ? formatNumberAum(value.gajiPokok) : '-',
    //             jumTunj: value.jumTunj ? formatNumberAum(value.jumTunj) : '-',
    //             potJht: value.potJht ? formatNumberAum(value.potJht) : '-',
    //             penghBrutoPajak: value.penghBrutoPajak ? formatNumberAum(value.penghBrutoPajak) : 0,
    //             jumPot: value.jumPot ? formatNumberAum(value.jumPot) : 0,
    //             penghasilanNett: value.penghasilanNett ? formatNumberAum(value.penghasilanNett) : 0,
    //             potonganJabatan: value.potonganJabatan ? formatNumberAum(value.potonganJabatan) : 0,
    //             ptkp: value.ptkp ? formatNumberAum(value.ptkp) : 0,
    //             pkp: value.pkp ? formatNumberAum(value.pkp) : 0,
    //             pphSetahun: value.pphSetahun ? formatNumberAum(value.pphSetahun) : 0,
    //             pphSebulan: value.pphSebulan ? formatNumberAum(value.pphSebulan) : 0,
    //             nominalTransfer: value.nominalTransfer ? formatNumberAum(value.nominalTransfer) : 0,
    //             gajiRekNo: value.gajiRekNo ? value.gajiRekNo : '-',
    //             gajiRekNama: value.gajiRekNama ? formatNumberAum(value.gajiRekNama) : '-',
    //             gajiRekBankId: value.gajiRekBankId === 3254 ? 'BANK NEGARA INDONESIA' : value.gajiRekBankId === 2 ? 'Bank Mandiri' : '-',
    //             // gajiRekBankId: dataGrid.gajiRekBankId === null ? '-' : getBank.bankName,
    //             // gajiRekBankId: idBank,
    //             // gajiRekBankId: getBankId.bankName,
    //             gajiRekBranch: value.gajiRekBranch,
    //         }
    //         data.push(dataValue);
    //     }
    //     this.dataSource = data;

    //     // return this.dataSource
    // }

    filterSubmit = async () => {
        let loadAPI = `view-report-gaji-daftar-transaksis?bulan.in=${this.state.filterData.monthPeriode}&size=9999&tahun.in=${this.state.filterData.yearPeriode}&sort=fullName%2Casc`;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET');
        let data = [];
        let counter = 0;

        for (let value of response) {
            // const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
            // var bulan = months[value.bulan - 1]
            let dataValue = {
                id: counter++,
                bulan: value.bulan,
                tahun: value.tahun ? value.tahun : '-',
                nik: value.nik ? value.nik : '-',
                fullName: value.fullName ? value.fullName : '-',
                jabatanName: value.jabatanName ? value.jabatanName : '-',
                gajiPokok: value.gajiPokok ? (value.gajiPokok) : 0,
                jumTunj: value.jumTunj ? (value.jumTunj) : 0,
                potJht: value.potJht ? (value.potJht) : 0,
                penghBrutoPajak: value.penghBrutoPajak ? (value.penghBrutoPajak) : 0,
                jumPot: value.jumPot ? (value.jumPot) : 0,
                penghasilanNett: value.penghasilanNett ? (value.penghasilanNett) : 0,
                potonganJabatan: value.potonganJabatan ? (value.potonganJabatan) : 0,
                ptkp: value.ptkp ? (value.ptkp) : 0,
                pkp: value.pkp ? (value.pkp) : 0,
                pphSetahun: value.pphSetahun ? (value.pphSetahun) : 0,
                pphSebulan: value.pphSebulan ? (value.pphSebulan) : 0,
                nominalTransfer: value.nominalTransfer ? (value.nominalTransfer) : 0,
                gajiRekNo: value.gajiRekNo ? value.gajiRekNo : '-',
                gajiRekName: value.gajiRekName,
                gajiRekBankId: value.gajiRekBankId,
                // gajiRekNama: value.gajiRekNama ? (value.gajiRekNama) : 0,
                // gajiRekBankId: value.gajiRekBankId === 3254 ? 'BANK NEGARA INDONESIA' : value.gajiRekBankId === 2 ? 'Bank Mandiri' : '-',
                // gajiRekBankId: dataGrid.gajiRekBankId === null ? '-' : getBank.bankName,
                // gajiRekBankId: idBank,
                // gajiRekBankId: getBankId.bankName,
                gajiRekBranch: value.gajiRekBranch,
            }
            data.push(dataValue);
        }
        this.dataSource = data;
        this.dgRef.current.forceRefresh(true);
    }

    onSelectionChanged = (e) => {
        this.selectedRowDatas = e.selectedRowsData
    }

    loadData = async () => {
        // let loadAPI = `view-report-gaji-daftar-transaksis?bulan.in=${this.state.filterData.monthPeriode}&size=9999&tahun.in=${this.state.filterData.yearPeriode}`;
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET');
        // let data = [];
        // let counter = 0;

        // for (let value of response) {
        //     // const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
        //     // var bulan = months[value.bulan - 1]
        //     let dataValue = {
        //         id: counter++,
        //         bulan: value.bulan,
        //         tahun: value.tahun ? value.tahun : '-',
        //         nik: value.nik ? value.nik : '-',
        //         fullName: value.fullName ? value.fullName : '-',
        //         jabatanName: value.jabatanName ? value.jabatanName : '-',
        //         gajiPokok: value.gajiPokok ? formatNumberAum(value.gajiPokok) : '-',
        //         jumTunj: value.jumTunj ? formatNumberAum(value.jumTunj) : '-',
        //         potJht: value.potJht ? formatNumberAum(value.potJht) : '-',
        //         penghBrutoPajak: value.penghBrutoPajak ? formatNumberAum(value.penghBrutoPajak) : 0,
        //         jumPot: value.jumPot ? formatNumberAum(value.jumPot) : 0,
        //         penghasilanNett: value.penghasilanNett ? formatNumberAum(value.penghasilanNett) : 0,
        //         potonganJabatan: value.potonganJabatan ? formatNumberAum(value.potonganJabatan) : 0,
        //         ptkp: value.ptkp ? formatNumberAum(value.ptkp) : 0,
        //         pkp: value.pkp ? formatNumberAum(value.pkp) : 0,
        //         pphSetahun: value.pphSetahun ? formatNumberAum(value.pphSetahun) : 0,
        //         pphSebulan: value.pphSebulan ? formatNumberAum(value.pphSebulan) : 0,
        //         nominalTransfer: value.nominalTransfer ? formatNumberAum(value.nominalTransfer) : 0,
        //         gajiRekNo: value.gajiRekNo ? value.gajiRekNo : '-',
        //         gajiRekNama: value.gajiRekNama ? formatNumberAum(value.gajiRekNama) : '-',
        //         gajiRekBankId: value.gajiRekBankId === 3254 ? 'BANK NEGARA INDONESIA' : value.gajiRekBankId === 2 ? 'Bank Mandiri' : '-',
        //         // gajiRekBankId: dataGrid.gajiRekBankId === null ? '-' : getBank.bankName,
        //         // gajiRekBankId: idBank,
        //         // gajiRekBankId: getBankId.bankName,
        //         gajiRekBranch: value.gajiRekBranch,
        //     }
        //     data.push(dataValue);
        // }
        // this.dataSource = data;

        return this.dataSource;
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Laporan Transaksi Gaji</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dgRef}
                    // loadAPI={`view-report-gaji-daftar-transaksis?bulan.in=${this.state.filterData.monthPeriode}&size=9999&tahun.in=${this.state.filterData.yearPeriode}`}
                    loadAPI={``}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Daftar Transaksi Gaji"}
                    allowExportSelectedData={true}
                    selection={'multiple'}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}
                    height = {'calc(80vh - 205px)'}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanDaftarTransaksiGaji