import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import CustomStore from 'devextreme/data/custom_store';

import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import Box from 'devextreme-react/box';
import { tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta } from 'dataSource/lookup';

class EditModalPendaftaranPeserta extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            selectedTabIndex: 0,
            dataPeserta: {},
            typeData: '',
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Simpan Data',
                onClick: this.submitData.bind(this)
            },
            toolbar: 'bottom'
        },{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Batal',
                onClick: this.hide.bind(this)
            },
            toolbar: 'bottom'
        }]

        this.items = 
        [
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
            }, 
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama'
                },
            },
            {
                dataField: 'email',
                label: {
                    alignment: 'left',
                    text: 'Email'
                },
            },
            {
                dataField: 'sex',
                label: {
                    alignment: 'left',
                    text: 'Jenis Kelamin'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'phoneNo',
                label: {
                    alignment: 'left',
                    text: 'No. telp'
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'mobileNo',
                label: {
                    alignment: 'left',
                    text: 'No. handphone'
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'salary',
                label: {
                    alignment: 'left',
                    text: 'Gaji'
                },
                editorType: 'dxNumberBox',
                format: '#,##0',
                editorOptions: {
                    format: '#,##0',
                }
            },
            {
                dataField: 'fingerPrintCode',
                label: {
                    alignment: 'left',
                    text: 'Kode Fingerprint'
                },
            },
            {
                dataField: 'activedDate',
                label: {
                    alignment: 'left',
                    text: 'Actived Date'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%'
                }  
            },
            {
                dataField: 'birthDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Lahir'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }  
            },
            {
                dataField: 'birthPlace',
                label: {
                    alignment: 'left',
                    text: 'Tempat Lahir'
                },
            },
            {
                dataField: 'branchId',
                label: {
                    alignment: 'left',
                    text: 'Branch'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'cardId',
                label: {
                    alignment: 'left',
                    text: 'ID Card'
                },
                editorType: 'dxNumberBox'
            },
            {
                dataField: 'identityNo',
                label: {
                    alignment: 'left',
                    text: 'Nomor Identitas Pribadi'
                },
                editorType: 'dxNumberBox'
            },
            {
                dataField: 'identityExpired',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Kadaluarsa Identitas'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }  
            },
            {
                dataField: 'identityAddress1',
                label: {
                    alignment: 'left',
                    text: 'Alamat Pribadi 1'
                },
            },
            {
                dataField: 'identityAddress2',
                label: {
                    alignment: 'left',
                    text: 'Alamat Pribadi 2'
                },
            },
            {
                dataField: 'identityCountryId',
                label: {
                    alignment: 'left',
                    text: 'Negara Pribadi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: countries(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityProvinceId',
                label: {
                    alignment: 'left',
                    text: 'Provinsi Pribadi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityCityId',
                label: {
                    alignment: 'left',
                    text: 'Kota Pribadi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityPostalCode',
                label: {
                    alignment: 'left',
                    text: 'Kode Pos Pribadi'
                },
                editorType : 'dxNumberBox'
            },
            {
                dataField: 'workingDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Bekerja'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'domicileAddress1',
                label: {
                    alignment: 'left',
                    text: 'Alamat Domisili 1'
                },
            },
            {
                dataField: 'domicileAddress2',
                label: {
                    alignment: 'left',
                    text: 'Alamat Domisili 2'
                },
            },
            {
                dataField: 'domicileCountryId',
                label: {
                    alignment: 'left',
                    text: 'Negara Domisili'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: countries(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileProvinceId',
                label: {
                    alignment: 'left',
                    text: 'Provinsi Domisili'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileCityId',
                label: {
                    alignment: 'left',
                    text: 'Kota Domisili'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicilePostalCode',
                label: {
                    alignment: 'left',
                    text: 'Kode Pos Domisili'
                },
                editorType : 'dxNumberBox'
            },
            {
                dataField: 'education',
                label: {
                    alignment: 'left',
                    text: 'Pendidikan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: educationBackground(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'gradeId',
                label: {
                    alignment: 'left',
                    text: 'Nilai'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: grade(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'iuranPerusahaan',
                label: {
                    alignment: 'left',
                    text: 'Iuran Perusahaan'
                },
                editorType: 'dxNumberBox',
                format: '#,##0',
                editorOptions: {
                    format: '#,##0',
                }
            },
            {
                dataField: 'iuranPeserta',
                label: {
                    alignment: 'left',
                    text: 'Iuran Peserta'
                },
                editorType: 'dxNumberBox',
                format: '#,##0',
                editorOptions: {
                    format: '#,##0',
                }
            },
            {
                dataField: 'iuranTotal',
                label: {
                    alignment: 'left',
                    text: 'Iuran Total'
                },
                editorType: 'dxNumberBox',
                format: '#,##0',
                editorOptions: {
                    format: '#,##0',
                }
            },
            {
                dataField: 'unitId',
                label: {
                    alignment: 'left',
                    text: 'Unit'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: unit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'lastUnitId',
                label: {
                    alignment: 'left',
                    text: 'Unit Terakhir'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: unit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'marriedId',
                label: {
                    alignment: 'left',
                    text: 'Status Kawin'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: maritalStatus(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'masaKerja',
                label: {
                    alignment: 'left',
                    text: 'Masa Kerja'
                },
            },
            {
                dataField: 'mpRekName',
                label: {
                    alignment: 'left',
                    text: 'Nama Manfaat Pensiun Rekening'
                },
            },
            {
                dataField: 'mpRekNo',
                label: {
                    alignment: 'left',
                    text: 'Nomor Manfaat Pensiun Rekening'
                },
                editorType: 'dxNumberBox'
            },
            {
                dataField: 'mpRekBankId',
                label: {
                    alignment: 'left',
                    text: 'Manfaat Pensiun Rekening Bank'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'mpRekBranch',
                label: {
                    alignment: 'left',
                    text: 'Manfaat Pensiun Rekening Bank Cabang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: branch(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'numberFamily',
                label: {
                    alignment: 'left',
                    text: 'Nomor KK'
                },
                editorType: 'dxNumberBox'
            },
            {
                dataField: 'phdp',
                label: {
                    alignment: 'left',
                    text: 'PHDP'
                },
            },
            {
                dataField: 'positionId',
                label: {
                    alignment: 'left',
                    text: 'Posisi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'skKerjaId',
                label: {
                    alignment: 'left',
                    text: 'SK Kerja'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: SKKerja(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'skKerjaSalaryId',
                label: {
                    alignment: 'left',
                    text: 'SK Gaji Kerja'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: SKKerjaGaji(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'skPensiunId',
                label: {
                    alignment: 'left',
                    text: 'SK Pensiun'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: SKPensiun(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'statusPeserta',
                label: {
                    alignment: 'left',
                    text: 'Status Peserta'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusPeserta(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'taxId',
                label: {
                    alignment: 'left',
                    text: 'Tax'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: tax(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'taxNo',
                label: {
                    alignment: 'left',
                    text: 'No Tax'
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'terminated',
                label: {
                    alignment: 'left',
                    text: 'Terminasi'
                },
            },
            
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

        // console.log(dataSubmit)
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data) => {    
        if(data !== 'new'){
            this.setState({
                dataPeserta: data,
                typeData: 'edit'
            })
        }else{
            this.setState({
                typeData: 'new'
            })
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Data Pendaftaran Peserta'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY : 'scroll'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={3}
                                id={'formPendaftaranPeserta'}
                                formData={this.state.dataPeserta}
                                items={this.items}
                                height='500px'
                                scrollingEnabled={true}
                                labelLocation = 'left'
                            />
                        </div>
                    </div>

                </div>
                
            </Popup>
        );
    }
}


export default EditModalPendaftaranPeserta