import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import { pengkinianType,tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, SKType, currency, sppdBiayaType, sppdBiaya, sppdType } from 'dataSource/lookup';
import { formatUploadFileData } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormBiayaPenugasan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {
                jumlahBiaya: 0
            }
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.sppdId = 0
        this.sppdHari = 0

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
            
          ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
            
          ];
        this.formBiayaPenugasan = [
            {
                dataField: 'sppdBiayaId',
                label: {
                    text: 'Jenis Biaya',
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: sppdBiaya(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'biayaKode',
                    onSelectionChanged: (e) => {
                        if(e.selectedItem){
                            var Data = {}
                            Data.biayaPerhari = e.selectedItem.biayaPerhari
                            Data.jumlahHari = this.sppdHari
                            Data.jumlahBiaya =  e.selectedItem.biayaPerhari * this.sppdHari

                            this.formRef.current.instance.updateData(Data)
                        }
                    }
                }
            },
            
            {
                dataField: 'biayaPerhari',
                label: {
                    text: 'Biaya perhari',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    onValueChanged:(e) => {
                        var Data = {}
                        Data.jumlahBiaya =  e.value * this.sppdHari
                        this.formRef.current.instance.updateData(Data)
                    },
                    readOnly: true
                },
            },
            {
                dataField: 'jumlahHari',
                label: {
                    text: 'Jumlah'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    onValueChanged: (e) => {
                        let jumlah = e.value;
                        let biayaPerhari = this.formRef.current.instance.getEditor('biayaPerhari').option('value');

                        let result = jumlah * biayaPerhari;

                        this.formRef.current.instance.updateData({ jumlahBiaya: result });
                    },
                    readOnly:false
                }
            },
            {
                dataField: 'jumlahBiaya',
                label: {
                    text: 'Jumlah Biaya'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    format: '#,##0.00',
                    readOnly:true
                }
            },
        ]
        this.formBiayaPenugasanEdit = [
            {
                dataField: 'sppdBiayaId',
                label: {
                    text: 'Jenis Biaya',
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: sppdBiaya(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'biayaKode',
                    onSelectionChanged: (e) => {
                        if(e.selectedItem){
                            // var Data = {}
                            // Data.biayaPerhari = e.selectedItem.biayaPerhari
                            // Data.jumlahHari = this.sppdHari
                            // Data.jumlahBiaya =  e.selectedItem.biayaPerhari * this.sppdHari

                            // this.formRef.current.instance.updateData(Data)
                        }
                    }
                }
            },
            
            {
                dataField: 'biayaPerhari',
                label: {
                    text: 'Biaya perhari',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    onValueChanged:(e) => {
                        var Data = {}
                        Data.jumlahBiaya =  e.value * this.sppdHari
                        this.formRef.current.instance.updateData(Data)
                    },
                    readOnly: true
                },
            },
            {
                dataField: 'jumlahHari',
                label: {
                    text: 'Jumlah'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    onValueChanged: (e) => {
                        let jumlah = e.value;
                        let biayaPerhari = this.formRef.current.instance.getEditor('biayaPerhari').option('value');

                        let result = jumlah * biayaPerhari;
                        this.formRef.current.instance.updateData({ jumlahBiaya: result });
                    },
                    readOnly:false
                }
            },
            {
                dataField: 'jumlahBiaya',
                label: {
                    text: 'Jumlah Biaya'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    format: '#,##0.00',
                    readOnly:true
                }
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.sppdHari = 0
        this.sppdId = 0
        this.setState({
            popupVisible: false,
            DataMaster: {},
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    getSppdId = (sppdId) => {
        this.sppdId = sppdId
    }

    getHariSppd = (sppdHari) => {
        this.sppdHari = sppdHari
    }

    submitData = async() => {
        try {
            var DataMaster = {...this.state.DataMaster}
            DataMaster.karyawanSppdId = this.sppdId
            DataMaster.currencyId = 1001
            DataMaster.currencyRate = 1
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-biayas`,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    updateData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster}
            DataMaster.karyawanSppdId = this.sppdId
            DataMaster.currencyId = 1001
            DataMaster.currencyRate = 1
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-biayas`,
                "PUT",{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600); 
        }
    }

    retrieveData = async(data) => {
        this.setState({
            DataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Form Biaya"}
                width={"30vw"}
                height={"30vh"}
                toolbarItems={this.props.type == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formBiayaPenugasan'}
                        formData={this.state.DataMaster}
                        items={this.props.type === 'add' ? this.formBiayaPenugasan : this.formBiayaPenugasanEdit}
                        labelLocation = 'left'
                    />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormBiayaPenugasan