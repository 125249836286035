import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormPengajuanLembur from 'components/pages/modal/sdm-umum/kepegawaian/cuti-lembur/pengajuan-lembur/form';
import { jabatan, jabatanGolongan, karyawan, months, sdmUnit, status, tipeLembur } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirm } from "devextreme/ui/dialog";
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import { confirmAlert } from 'react-confirm-alert';

class KepegawaianPengajuanLembur extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{}
        }

        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.filterData = []

        this.formMaster = [
            {
                dataField:"bulan",
                label:{
                    text:"Bulan",
                    alignment:"left",
                    location:"left",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled:true
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                    location:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.pengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        visible: (e) => {
                            if(e.row.data.status === 1){
                                return true
                            }else{
                                return false
                            }
                        },
                        onClick : (e) => {
                            this.showModalaEditLemburKaryawan(e,false)
                        }
                    },
                    {
                        text : 'Lihat',
                        hint : 'Lihat',
                        onClick : (e) => {
                            this.showModalaLihatLemburKaryawan(e,true)
                        }
                    },
                    {
                        text : 'Setuju',
                        hint : 'Setuju',
                        onClick : async (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Ingin mengirim data pengajuan ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.approve(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text : 'Tolak',
                        hint : 'Tolak',
                        visible: (e) => {
                            if(e.row.data.status === 1){
                                return true
                            }else{
                                return false
                            }
                        },
                        onClick : async (e) => {
                        if (e.row.data.status == 1) {
                            var result = await confirm('Yakin ingin menolak pengajuan ini?', 'Konfirmasi')
                                if (result) {
                                    this.reject(e.row.data)
                                }
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'pengajuanNo',
                caption: 'No pengajuan',
            },
            {
                dataField: 'karyawanId',
                caption: 'NIK',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'nik',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'karyawanId',
                caption: 'Karyawan',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'fullName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'jabatanGolonganId',
                caption: 'Golongan Jabatan',
                lookup:{
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaJagol', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'unitId',
                caption: 'Unit',
                lookup:{
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'pengajuanTanggal',
                caption: 'Pengajuan Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'Lembur',
                caption: 'Lembur',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bulan',
                        caption: 'Bulan',
                        lookup:{
                            dataSource: months(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'tahun',
                        caption: 'Tahun'
                    },
                    {
                        dataField: 'hari',
                        caption: 'Hari'
                    },
                    {
                        dataField: 'uangLembur',
                        caption: 'Uang Lembur',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    }
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup:{
                    dataSource: status(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            }
        ]
    }
    componentDidMount = async() => {
        this.defaultData()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    defaultData = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-lemburs?size=9999`,
            'GET'
        )

        var filterData = datas.filter(value => value.status == "1")
        this.filterData = filterData
        this.forceRefresh()
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }
    
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalaAddLemburKaryawan(e,false)
                    },
                }
            }
        )
    }

    approve = async (data) => {
        try {
            var loadAPI = 'karyawan-lemburs'
            
            data.status = "2"
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                values: data,
                key:data.id
            },data)
            if (response) {
                notify({ message: "Sukses Approve Lembur Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.defaultData()
            }
        } catch (e) {
            console.log(e)
        }
    }

    reject = async (data) => {
        try {
            var loadAPI = 'karyawan-lemburs'

            data.status = "5"
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                values: data,
                key:data.id
            },data)

            if (response) {
                notify({ message: "Sukses Menolak Lembur Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.defaultData()
            }
        } catch (e) {
            console.log(e)
        }
    }

    showModalaAddLemburKaryawan = (e,readOnly) => {
        this.modalInsertRef.current.show()
        this.modalInsertRef.current.generateNoPengajuan()
    }

    showModalaEditLemburKaryawan = (e,readOnly) => {
        if (e.row) {
            this.modalEditRef.current.retrieveData(e.row.data, readOnly)
            this.modalEditRef.current.getDataLemburDetailId(e.row.data.id)
            this.modalEditRef.current.newData(true)
        } 
        this.modalEditRef.current.show()
    }

    showModalaLihatLemburKaryawan = (e,readOnly) => {
        if (e.row) {
            this.modalEditRef.current.retrieveData(e.row.data, readOnly)
            this.modalEditRef.current.getDataLemburDetailId(e.row.data.id)
            this.modalEditRef.current.newData(false)
        } 
        this.modalEditRef.current.show()
    }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-lemburs?size=9999`,
            'GET'
        )

        var filterData = datas.filter(value => value.status == "1")
        var listData = []
        for(var data of filterData){
            data.pengajuanTanggal = new Date(data.pengajuanTanggal)
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        }else if (state.bulan) {
            this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.pengajuanTanggal.getFullYear() == state.tahun)
        }else{
            this.filterData = filterData
        }
        // this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    }

    loadData = async () => { 
        return this.filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pengajuan Lembur</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='karyawan-lemburs'
                    insertAPI='karyawan-lemburs'
                    updateAPI='karyawan-lemburs'
                    deleteAPI='karyawan-lemburs' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    useArraySource={true}
                    ArraySourceData={this.loadData}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengjuan Lembur"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengjuan Lembur'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.pengajuanColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanLembur
                    ref={this.modalInsertRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                    action={'add'}
                />
                <FormPengajuanLembur
                    ref={this.modalEditRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                    action={'edit'}
                />
            </div>
        )
    }
}

export default KepegawaianPengajuanLembur