import React, { Component } from 'react'
import { coa } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class AkunInvestasi extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                dataField: 'akunCode',
                caption: 'Kode Akun',
                allowEditing: false,
                sortOrder: 'asc'
            },
            {
                dataField: 'akunName',
                caption: 'Nama Akun',
                allowEditing: false,
            },
            {
                dataField: 'coaId',
                caption: 'COA Modul Akuntansi',
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    }, // provides display values
                },
                // editorOptions: {
                //     dataSource: coa(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: (item) => {
                //         return item && item.coaCode + ' - ' + item.coaName
                //     }, 
                //     searchEnabled: true,
                //     wrapItemText: true
                // }
            },
        ]

        this.datagridRef = React.createRef()

    }
    
    render() {
        return (
            <DevExpressDataGrid
                ref = {this.datagridRef}
                loadAPI='akuns'
                insertAPI='akuns'
                updateAPI='akuns'
                deleteAPI='akuns' 

                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={true}

                exportExcel={true}
                exportFileName={"Akun Investasi"}
                allowExportSelectedData={true}
                selection={"multiple"}

                // remoteOperations = {true}

                showBorders={true}

                paging={true}
                defaultPageSize={10}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Akun Investasi Data'}
                popupWidth={500} //masukan dalam ukuran pixel
                popupHeight={300} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={1} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                height={'calc(100vh - 260px)'}

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}
                

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }
}

export default AkunInvestasi