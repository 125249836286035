import DevExpressDataGrid from "components/inheritComponent/devexpressdatagrid";
import { asetKategoriSdm, kategoriPengadaanSdm, sdmUnit, subAsetKategoriSdm } from "dataSource/lookup";
import httpRequest from "plugin/httprequest";
import React, { Component } from "react";
import { addURL } from "redux/actions/url";
import { alert } from 'devextreme/ui/dialog';
import PersetujuanPengadaanAsetCatatan from "./persetujuanPengadaanAsetCatatan";
import ModalViewPersetujuanPengadaan from "./modalViewPersetujuanPengadaan";

class PersetujuanPengurus extends Component {
    constructor(props) {
        super(props) 
        
        this.dataGridRef = React.createRef();
        this.modalSetujuCatatanRef = React.createRef();
        this.modalTolakCatatanRef = React.createRef();
        this.showModalRef = React.createRef();
        
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                    {
                        text: 'Setuju',
                        hint: 'Setuju',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data, 'setuju')
                        }
                    },
                    {
                        text: 'Tolak',
                        hint: 'Tolak',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data, 'tolak')
                        }
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id : 'O',
                            value: 'Open'
                        },
                        {
                            id : 'A',
                            value: 'Proses Pemeriksaan'
                        },
                        {
                            id : 'P',
                            value: 'Selesai'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {                
                dataField: 'pengajuanDate',
                caption: 'Tanggal Pengadaan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'unitId',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                },
            },
            {
                dataField: 'noPengadaan',
                caption: 'No Pengadaan'
            },
            {
                dataField: 'assetKategoriId',
                caption: 'Kategori Asset',
                lookup: {
                    dataSource: asetKategoriSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categoryName',
                }
            },
            {
                dataField: 'assetKategoriSubId',
                caption: 'Sub Kategori',
                lookup: {
                    dataSource: subAsetKategoriSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categorySubName',
                }
            },
            {
                dataField: 'merek',
                caption: 'Merek'
            },
            {
                dataField: 'jumlah',
                caption: 'Jumlah',
                // dataType: 'number',
                // format : '#,##0.00'
            },
            {
                dataField: 'hargaPerkiraan',
                caption: 'Harga',
                dataType: 'number',
                format : '#,##0.00'
            },
            // {
            //     dataField: 'ketSpesifikasi',
            //     caption: 'Spesifikasi'
            // },
            {
                dataField: 'persetujuanManager',
                caption: 'Persetujuan Manager',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'managerVerifiedBy',
                        caption: 'Nama'
                    },
                    {
                        dataField: 'managerVerifiedNote',
                        caption: 'Catatan',
                        width: '150px'
                    },
                    {
                        dataField: 'managerVerified',
                        caption: 'Setuju / Tolak',
                        dataType:'boolean',
                        // alignment: 'center'
                    }
                ]
            },
            {
                dataField: 'persetujuanPengurus',
                caption: 'Persetujuan Pengurus',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pengurusVerifiedBy',
                        caption: 'Nama'
                    },
                    {
                        dataField: 'pengurusVerifiedNote',
                        caption: 'Catatan',
                        width: '150px'
                    },
                    {
                        dataField: 'pengurusVerified',
                        caption: 'Setuju / Tolak',
                        dataType:'boolean',
                        // alignment: 'center'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true);
    }

    showModal = (data) => {
        this.showModalRef.current.show();
        this.showModalRef.current.retrieveData(data);
    }

    showModalCatatan = async (data, type) => {
        try {
            let karyawanUrl = 'karyawans/getByUsername/' + this.props.store.getState().sessionUser
            let getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, karyawanUrl, 'GET')
            // let entitiUrl = `entitis/findByTypeAndReffByPath/KR/${getKaryawan.id}`
            // let getEntiti = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, entitiUrl, 'GET')
            let jabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `jabatans/${getKaryawan.jabatanId}`, 'GET');

            if (jabatan.jabatanCode !== 'MSDM' && jabatan.jabatanCode !== 'DIROPR'){
                await alert('Persetujuan hanya bisa dilakukan oleh manager dan pengurus', 'Informasi');
                return null
            }

            if (type === 'setuju') {
                if (jabatan.jabatanCode === 'MSDM'){
                    this.modalSetujuCatatanRef.current.show(data, type);
                } else if (jabatan.jabatanCode === 'DIROPR') {
                    if (data.managerVerified === null || data.managerVerified !== true) {
                        await alert('Manager belum melakukan persetujuan', 'Informasi');
                        return null;
                    } else {
                        this.modalSetujuCatatanRef.current.show(data, type);
                    }
                } else {
                    return null;
                }
            } else if (type === 'tolak') {
                if (jabatan.jabatanCode === 'MSDM') {
                    if (data.pengurusVerified === true) {
                        await alert('Manager tidak bisa melakukan tolak, Pengurus sudah menyetujui', 'Informasi');
                    } else {
                        this.modalTolakCatatanRef.current.show(data, type);
                    }
                } else if (jabatan.jabatanCode === 'DIROPR') {
                    if (data.managerVerified === null || data.managerVerified !== true) {
                        await alert('Manager belum melakukan persetujuan', 'Informasi');
                    } else {
                        this.modalTolakCatatanRef.current.show(data, type);
                    }
                }
            }

            // if (jabatan.jabatanCode === 'MSDM' || jabatan.jabatanCode === 'S-UMUM') {
            //     if (type === 'setuju') {
            //         this.modalSetujuCatatanRef.current.show(data, type);
            //     } else if (type === 'tolak') {
            //         this.modalTolakCatatanRef.current.show(data, type);
            //     } else {
            //         return null;
            //     }
            // } else if (jabatan.jabatanCode === 'DIROPR') {
            //     if (data.managerVerified === null && type === 'setuju') {
            //         await alert('Manager belum melakukan persetujuan', 'Informasi');
            //         return null;
            //     } else if (data.managerVerified === null && type === 'tolak') {
            //         await alert('Manager belum melakukan persetujuan', 'Informasi');
            //         return null;
            //     } else {
            //         this.modalSetujuCatatanRef.current.show(data, type);
            //     }
            // }
        } catch (error) {
            var title = 'Gagal!'
            var text = 'Data Detail Gagal Dikirim!'
            await alert(text, title);
        }
    }

    kirim = async(data, catatan, action) => {
        try {
            let karyawanUrl = 'karyawans/getByUsername/' + this.props.store.getState().sessionUser
            let getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, karyawanUrl, 'GET')
            let jabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `jabatans/${getKaryawan.jabatanId}`, 'GET');

            let assetPenggadaan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pengadaans/${data.id}`, 'GET');
            let updateData = assetPenggadaan;

            if (action === 'setuju') {
                if (jabatan.jabatanCode === 'MSDM') {
                    updateData.managerVerified = true;
                    updateData.managerVerifiedNote = catatan;
                    updateData.managerVerifiedDate = new Date();
                    updateData.managerVerifiedBy = this.props.store.getState().sessionUser
                    updateData.status = 'A';

                    await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pengadaans`, 'PUT', {
                        key: data.id,
                        values: updateData
                    }, assetPenggadaan);
        
                    await alert('Data berhasil disetujui oleh manager', 'Informasi');
                    this.forceRefresh();
                    return null;
                } else if (jabatan.jabatanCode === 'DIROPR') {
                    updateData.pengurusVerified = true;
                    updateData.pengurusVerifiedNote = catatan;
                    updateData.pengurusVerifiedDate = new Date();
                    updateData.pengurusVerifiedBy = this.props.store.getState().sessionUser
                    updateData.status = 'P'

                    await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pengadaans`, 'PUT', {
                        key: data.id,
                        values: updateData
                    }, assetPenggadaan);

                    await alert('Data berhasil disetujui oleh pengurus', 'Informasi');
                    this.forceRefresh();
                    return null;
                } else {
                    return null;
                }
            } else if (action === 'tolak') {
                if (jabatan.jabatanCode === 'MSDM') {
                    updateData.managerVerified = false;
                    updateData.managerVerifiedNote = null;
                    updateData.managerVerifiedDate = new Date();
                    updateData.status = 'O';
                } else if (jabatan.jabatanCode === 'DIROPR') {
                    updateData.pengurusVerified = false;
                    updateData.pengurusVerifiedNote = catatan;
                    updateData.pengurusVerifiedDate = new Date();
                    updateData.status = 'A';
                } else {
                    return null;
                }

                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pengadaans`, 'PUT', {
                    key: data.id,
                    values: updateData
                }, assetPenggadaan);
    
                await alert('Data berhasil ditolak', 'Informasi');
                this.forceRefresh();
                return null;
            } else {
                return null;
            }

            // if (jabatan.jabatanCode === 'S-UMUM' && action === 'setuju') {
            //     updateData.managerVerified = true;
            //     updateData.managerVerifiedNote = catatan;
            //     updateData.managerVerifiedDate = new Date();
            // } else if (jabatan.jabatanCode === 'DIROPR' && action === 'setuju') {
            //     updateData.pengurusVerified = true;
            //     updateData.pengurusVerifiedNote = catatan;
            //     updateData.pengurusVerifiedDate = new Date();
            // } else {
            //     return null;
            // }

            // if (jabatan.jabatanCode === 'S-UMUM' && action === 'tolak') {
            //     updateData.managerVerified = false;
            //     updateData.managerVerifiedNote = catatan;
            //     updateData.managerVerifiedDate = new Date();
            // } else if (jabatan.jabatanCode === 'DIROPR' && action === 'tolak') {
            //     console.log('test');
            //     updateData.pengurusVerified = false;
            //     updateData.pengurusVerifiedNote = catatan;
            //     updateData.pengurusVerifiedDate = new Date();
            // } else {
            //     return null;
            // }
        } catch (error) {
            console.log(error);
            var title = 'Gagal!'
            var text = 'Data Detail Gagal Dikirim!'
            await alert(text, title);
        }
    }

    loadData = async () => {
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pengadaans?size=9999', 'GET');

        if(this.props.type === 'persetujuan'){
            response = response.filter(value => value.status === 'A');
        }

        return response
    }

    render () {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Persetujuan & Pengurus</h3>

                <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='asset-pengadaans'
                        insertAPI='asset-pengadaans'
                        updateAPI='asset-pengadaans'
                        deleteAPI='asset-pengadaans'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource = {true}
                        ArraySourceData = {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        // exportFileName={MenuTitle}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        // popupTitle={MenuTitle}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ModalViewPersetujuanPengadaan
                        ref={this.showModalRef}
                        store={this.props.store}
                    />

                    <PersetujuanPengadaanAsetCatatan
                        ref={this.modalSetujuCatatanRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        kirim={this.kirim}
                    />

                    <PersetujuanPengadaanAsetCatatan
                        ref={this.modalTolakCatatanRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        kirim={this.kirim}
                    />
            </div>
        )
    }
}

export default PersetujuanPengurus;