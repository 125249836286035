import React, { Component } from "react";
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import { alert, confirm } from 'devextreme/ui/dialog';
import httpRequest from "plugin/httprequest";

class ModalEditGrupMenu extends Component {
    constructor(props) {
        super (props)

        this.state = {
            popupVisible: false,
            formData: {},
            type: 'add'
        }

        this.formItem = [
            {
                dataField: 'groupCode',
                label: {
                    alignment: 'left',
                    text: 'Kode Grup'
                },
                // editorOptions: {
                //     readOnly: true
                // }
            },
            {
                dataField: 'groupName',
                label: {
                    alignment: 'left',
                    text: 'Nama Grup'
                },
                editorOptions: {
                    readOnly: false
                }
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    // onClick: this.submitData.bind(this)
                    onClick: async (e) => {
                        if (this.state.type !== 'add') {
                            console.log('edit');
                            this.updateData();
                        } else {
                            console.log('tambah data');
                            this.submitData();
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.readOnly = false
    }

    retrieveData = (data, type) => {
        this.readOnly = type === 'edit' ? true : false
        this.setState({
            formData: data,
            type: type
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () =>{
        // this.sumAmount = 0
        this.setState({
            popupVisible: false,
            formData: {},
        })
        this.readOnly = false
    }

    submitData = async () => {
        try {
            let data = this.state.formData;

        let response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `sys-groups`, 'POST', {
            values: data
        })

        if (response) {
            await alert('Data Berhasil Disimpan', 'Berhasil!')
            this.hide();
            this.props.forceRefresh()
        }
        } catch (error) {
            console.log(error);
            await alert(error, 'Gagal!')
        }
    }

    updateData = async () => {
        try {
            let data = this.state.formData;

            console.log(data);
        let response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `sys-groups`, 'PUT', {
            key: data.id,
            values: data
        }, data)

        if (response) {
            await alert('Data Berhasil Di Edit', 'Berhasil!');
            this.hide();
            this.props.forceRefresh();
        }
        } catch (error) {
            console.log(error);
            await alert(error, 'Gagal!')
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Grup Pengguna'}
                width={"20vw"}
                height={"20vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <Form 
                        ref={this.formRef}
                        colCount={1}
                        id={'formPotongan'}
                        formData={this.state.formData}
                        items={this.formItem}
                        labelLocation='left'
                        readOnly={this.readOnly}
                    />
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalEditGrupMenu