import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { currency, banks, instrumentSubCategoryDeposito, instruments, managerInvestasis, instrumentsCategoryEtf, instrumentEtf } from 'dataSource/lookup';

class DetailPenempatanExchangeTraded extends Component {
    constructor(props){
        super(props)
        this.dataSource  = new CustomStore({
            load: async(loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `order-requests-etf/${requestId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                var result = []
                for (var etf of response.orderEtfInfos){
                    for (var detail of etf.orderEtfDTOList) {
                        var etfInfo = {
                            id: detail.id,
                            instrumentId: detail.instrumentId,
                            miId: detail.miId,
                            categorySubId: etf.orderDTO.categorySubId,
                            currencyId: 1001,
                            amountOrder: detail.amountOrder,
                            commission: detail.commission,
                            brokerId: detail.brokerId,
                            kpdId: 1,
                            kurs: 1,
                            maxOrdPrice: detail.priceMax,
                            minOrdPrice: detail.priceMin,
                            orderId: detail.orderId,
                            priceType: detail.priceType,
                            rekbankId: detail.rekBankId,
                            tanggalOrder: etf.orderDTO.orderDate,
                        }
                    }
                    result.push(etfInfo)
                }
                return result
            },
            key: 'id',
            insert: (values) => {
            },
            update: (key,values) => {
               
            },
            remove: (key) => {
               
            }
        })

        this.columns = [
            {
                dataField: 'instrumentId',
                caption: 'Kode Instrument',
                lookup: {
                    dataSource: instrumentEtf(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode'
                },
            },
            {
                dataField: 'instrumentId',
                caption: 'Kode Instrument',
                lookup: {
                    dataSource: instrumentEtf(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                },
            },
            {
                dataField: 'miId',
                caption: 'Penerbit',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'categorySubId',
                caption: 'Jenis',
                lookup: {
                    dataSource: instrumentsCategoryEtf(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            },
            {
                dataField: 'kurs',
                caption: 'Kurs',
            },
            {
                dataField: 'nabTerakhir',
                caption: 'NAB Terakhir',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'amountOrder',
                caption: 'Nilai Investasi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'commission',
                caption: 'Fee (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'feeNom',
                caption: 'Fee Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                calculateCellValue: (e) => {
                    return e.amountOrder * (e.commission / 100)
                }
            },
            {
                dataField: 'totalPenempatan',
                caption: 'Total Penempatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                calculateCellValue: (e) => {
                    return e.amountOrder + (e.amountOrder * (e.commission / 100))
                }
            },
        ]
    }

    render() {
        return (
            <DataGrid
            id={"successDataGrid"}
            showBorders={false}
            dataSource={this.dataSource}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            // height={100}
            showScrollbar={"always"}
            // columns={this.columns}
            >
            {
                this.columns.map((column) => {
                    return <Column 
                        dataField={column.dataField} 
                        caption={column.caption} 
                        lookup={column.lookup} 
                        cellRender={column.cellRender} 
                        alignment={column.alignment || 'left'} 
                        cssClass={column.cssClass}
                        format = {column.format}
                        dataType = {column.dataType}
                        width = {column.width}
                        type = {column.type}
                        buttons = {column.buttons}
                        editorType = {column.editorType}
                        editorOptions = {column.editorOptions}
                        visible = {column.visible}
                        sortOrder = {column.sortOrder}
                        allowEditing = {column.allowEditing}
                        calculateCellValue = {column.calculateCellValue}
                    />
                })
            }
            {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPenempatanExchangeTraded