import React, { Component } from 'react'
import { Form, Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import { participants } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultDate, generateExcelFile, PrintElem } from 'plugin/helper'
import { showLoading } from 'redux/actions/loading';

class ReportPengkinianDataIntegrasi extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            src : ''
        }

        this.formRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField:'greaterTanggalPerubahan',
                                label:{
                                    text: 'Tanggal Perubahan',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField:'lessTanggalPerubahan',
                                label:{
                                    text: 'S/D',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    {
                        dataField:'tipePerubahan',
                        label:{
                            text: 'Tipe Perubahan',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Registrasi Aktif'
                                },
                                {
                                    id: 2,
                                    value: 'Pengkinian Data'
                                },
                                {
                                    id: 3,
                                    value: 'Registrasi Pensiun'
                                },
                                {
                                    id: 4,
                                    value: 'Semua'
                                },
                            ],
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "value", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-view-data-integrasis?`

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        var getDataIntegrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        for(var value of getDataIntegrasi){
            value.fileDate = value.fileDate ? formatDefaultDate(value.fileDate) : '-'
            value.createdDate = value.createdDate ? formatDefaultDate(value.createdDate) : '-'
        }

        var tipePerubahan = filter.tipePerubahan

        if(tipePerubahan === 1){
            tipePerubahan = 'Registrasi Aktif'
        }else if(tipePerubahan === 2){
            tipePerubahan = 'Pengkinian Data'
        }else if(tipePerubahan === 3){
            tipePerubahan = 'Registrasi Pensiun'
        }else{
            tipePerubahan = 'Semua'
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/pengkinian/data-integrasi',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                participant: participantName,
                tipePerubahan: tipePerubahan,
                dataPeserta : getDataIntegrasi
            }
        }
        store.dispatch(showLoading(false))
        var responseFile = await reportFile(data)

        this.setState({
            src : responseFile
        })
    }
    
    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-view-data-integrasis?`

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        var getDataIntegrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        for(var value of getDataIntegrasi){
            value.fileDate = value.fileDate ? formatDefaultDate(value.fileDate) : '-'
            value.createdDate = value.createdDate ? formatDefaultDate(value.createdDate) : '-'
        }

        var tipePerubahan = filter.tipePerubahan

        if(tipePerubahan === 1){
            tipePerubahan = 'Registrasi Aktif'
        }else if(tipePerubahan === 2){
            tipePerubahan = 'Pengkinian Data'
        }else if(tipePerubahan === 3){
            tipePerubahan = 'Registrasi Pensiun'
        }else{
            tipePerubahan = 'Semua'
        }
        
        var data = {
            template:{
                name: '/dapen/kepesertaan/report/pengkinian/data-integrasi',
                recipe: 'html'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                participant: participantName,
                tipePerubahan: tipePerubahan,
                dataPeserta : getDataIntegrasi
            }
        }
        var response = await reportRequest(this.props.store,data)
        store.dispatch(showLoading(false))
        PrintElem(response.data)
        return response
    }

    exportExcel = async() => {
        try {
            var data = []
            let store = this.props.store
            store.dispatch(showLoading(true))
            var filter = this.state.formFilter
            var loadAPI = `list-view-laporan-pensiun-di-tundas?`
            var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'

            if(filter.participantId){
                try {
                    var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
                } catch (error) {
                    var participantName = 'Semua'
                }
            }else{
                var participantName = 'Semua'
            }

            var getDataIntegrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

            var tipePerubahan = filter.tipePerubahan

            if(tipePerubahan === 1){
                tipePerubahan = 'Registrasi Aktif'
            }else if(tipePerubahan === 2){
                tipePerubahan = 'Pengkinian Data'
            }else if(tipePerubahan === 3){
                tipePerubahan = 'Registrasi Pensiun'
            }else{
                tipePerubahan = 'Semua'
            }
            
            var index = 1
            for(var value of getDataIntegrasi){
                var dataValue = {
                    id: index++,
                    participantName: participantName,
                    nik: value.nik,
                    fullName: value.fullName,
                    fileDate: value.fileDate ? formatDefaultDate(value.fileDate) : '-',
                    tipePerubahan: tipePerubahan,
                    tanggalProses: value.tanggalProses ? formatDefaultDate(value.tanggalProses) : '-',
                    createdDate: value.createdDate ? formatDefaultDate(value.createdDate) : '-'
                }
    
                const objectValue = Object.values(dataValue)
    
                data.push(objectValue)
            }

            try{
                const workbook = new Excel.Workbook();
                var worksheet = workbook.addWorksheet('LAPORAN DATA PENGKINIAN INTEGRASI');
                worksheet.addTable({
                  name: 'MyTable',
                  ref: 'A12',
                  headerRow: true,
                  totalsRow: false,
                  style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                    // border: {
                    //     top: {style:'thin'},
                    //     left: {style:'thin'},
                    //     bottom: {style:'thin'},
                    //     right: {style:'thin'}
                    // }
                  },
                  columns: [
                    {name: 'No'},
                    {name: 'Pemberi Kerja'},
                    {name: 'NIK'},
                    {name: 'Nama'},
                    {name: 'Tanggal file'},
                    {name: 'Tipe Perubahan'},
                    {name: 'Tanggal Proses'},
                    {name: 'Tanggal Update'}
                  ],
                  rows: data,
                });

                worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

                worksheet.getCell('A3').value = 'Kode'
                worksheet.getCell('B3').value = ': KPS-2-02'

                worksheet.getCell('A4').value = 'Laporan'
                worksheet.getCell('B4').value = ': DAFTAR PENGKINIAN VIA INTEGRASI'

                worksheet.getCell('A5').value = 'Tanggal Laporan'
                worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

                worksheet.getCell('A7').value = 'Pemberi Kerja'
                worksheet.getCell('B7').value = `: ${participantName}`
                
                worksheet.getCell('A8').value = 'Tipe Perubahan'
                worksheet.getCell('B8').value = `: ${tipePerubahan}`

                worksheet.getCell('A12').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B12').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('C12').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('D12').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('E12').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('F12').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('G12').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('H12').alignment = {vertical: 'middle', horizontal: 'center'};

                for(var i = 13; i <= 10000; i++){
                    worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                }

                var WidthColums = 20;
                
                const Data1 = worksheet.getColumn(1);
                Data1.width = WidthColums;

                const Data2 = worksheet.getColumn(2);
                Data2.width = WidthColums;

                const Data3 = worksheet.getColumn(3);
                Data3.width = WidthColums;

                const Data4 = worksheet.getColumn(4);
                Data4.width = 40;

                const Data5 = worksheet.getColumn(5);
                Data5.width = WidthColums;

                const Data6 = worksheet.getColumn(6);
                Data6.width = WidthColums;

                const Data7 = worksheet.getColumn(7);
                Data7.width = WidthColums;

                const Data8 = worksheet.getColumn(8);
                Data8.width = WidthColums;
                
                await workbook.xlsx.writeBuffer().then(function(buffer) {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        `LAPORAN_DATA_PENGKINIAN_INTEGRASI.xlsx`
                    );
                });
            }catch(e){
                console.log(e)
            }
            store.dispatch(showLoading(false))

            // var headerNameMapping = [
            //     {
            //         from:"id",
            //         to:"No"
            //     },
            //     {
            //         from:"participantName", 
            //         to:"Pemberi Kerja"
            //     },
            //     {
            //         from:"nik", 
            //         to:"NIK"
            //     },
            //     {
            //         from:"fullName", 
            //         to:"Nama"
            //     },
            //     {
            //         from:"fileDate", 
            //         to:"Tanggal File"
            //     },
            //     {
            //         from:"tipePerubahan", 
            //         to:"Tipe Perubahan"
            //     },
            //     {
            //         from:"tanggalProses", 
            //         to:"Tanggal Proses"
            //     },
            //     {
            //         from:"createdDate", 
            //         to:"Tanggal Update"
            //     },
            // ]

            // var header = [
            //     {
            //         A: 'DANA PENSIUN PEGADAIAN'
            //     },
            // ]
            
            // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

            // var title = [
            //     {
            //         A: 'Kode',
            //         B: null,
            //         C: ':',
            //         D: 'KPS-2-02'
            //     },
            //     {
            //         A: 'Laporan',
            //         B: null,
            //         C: ':',
            //         D: 'DAFTAR PENGKINIAN VIA INTEGRASI'
            //     },
            //     {
            //         A: 'Tanggal Laporan',
            //         B: null,
            //         C: ':',
            //         D: formatDefaultDate(new Date())
            //     },
            // ]

            // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

            // var subTitle = [
            //     {
            //         A: 'Pemberi Kerja',
            //         B: null,
            //         C: ':',
            //         D: participantName
            //     },
            //     {
            //         A: 'tipePerubahan',
            //         B: null,
            //         C: ':',
            //         D: filter.tipePerubahan
            //     }
            // ]

            // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
            // var instructions = []
        
            // for(var InstructionData of getDataIntegrasi){
            //     var row = {}
            //     for(var column in InstructionData){
            //         var headerMapping = headerNameMapping.find(value => value.from == column)
            //         if(headerMapping){
            //             row[headerMapping.to] = InstructionData[column]
            //         }
            //     }
            //     instructions.push(row)
            // }

            // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

            // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            // store.dispatch(showLoading(false))
            // generateExcelFile(excelBlob,'LAPORAN_DATA_PENGKINIAN_INTEGRASI')
        } catch (e) {
            let store = this.props.store
            store.dispatch(showLoading(false))
            console.log(e)
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Daftar Perubahan Via Integrasi Data </h4>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.formFilter}
                            items={this.filterItem}
                            labelLocation = 'left'
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPengkinianDataIntegrasi