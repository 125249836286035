import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from "devextreme/ui/notify";
import { alert, confirm } from "devextreme/ui/dialog";
import { confirmAlert } from 'react-confirm-alert'
import httpRequest from "plugin/httprequest";
import { coa, journalType } from "dataSource/lookup";
import { showLoading } from "redux/actions/loading";
import ModalAddCashFlowConfig from 'components/pages/modal/global-setting/modalAddCashFlow';

class ModalDetailCashFlowConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      DataMaster: {},
      reportHeaderData: {}
    };

    this.dataFromType = {}
    this.dataFromCoa = {}

    this.modalAddCashFlowConfig = React.createRef()
    this.modalEditCashFlowConfig = React.createRef()

    this.formItem = [
      {
        dataField: 'description',
        label: {
          text: 'Deskripsi',
          alignment: 'left'
        },
        allowEditing: false
      },
      {
        dataField: 'seqNo',
        label: {
          text: 'Urutan',
          alignment: 'left'
        },
        editorType: 'dxNumberBox'
      },
      {
        dataField: 'beginningValue',
        label: {
          text: 'Nilai Awal',
          alignment: 'left'
        },
        editorType: 'dxNumberBox',
        editorOptions: {
          format: '#,##0.00;(#,##0.00);'
        }
      },
      {
        dataField: 'parentName',
        label: {
          text: 'Induk',
          alignment: 'left'
        },
      },
    ];


    this.columns = [
      {
        type: 'buttons',
        width: 100,
        buttons: [
          {
            icon: 'edit',
            cssClass: 'text-success',
            onClick: async (e) => {
              this.showModalCashFlow('edit', e.row.data)
            }
          },
          {
            icon: 'trash',
            cssClass: 'text-success',
            onClick: async (e) => {
              let data = e.row.data
              confirmAlert({
                title: 'Konfirmasi',
                message: 'Apakah anda yakin untuk menghapus data ini?',
                buttons: [
                  {
                    label: 'Ya',
                    onClick: () => {
                      this.deleteAPI(data)
                    }
                  },
                  {
                    label: 'Tidak'
                  }
                ]
            })
            }
          }
        ]
      },
      {
        dataField: 'coaId',
        caption: 'Kode COA',
        lookup: {
          dataSource: coa(this.props.store),
          valueExpr: 'id',
          displayExpr: (item) => {
            return item && item.coaCode + ' - ' + item.coaName
          }, // provides display values
          searchEnabled: true,
          deferRendering: false,
        },
        validationRules: [
          {
            type: 'async',
            validationCallback: async (e) => {
              this.dataFromCoa = e.data
              const result = await this.checkCoaAndTypeId()
              if (result.isValid) {
                return true
              } else {
                throw new Error(result.message)
              }
            },
          },
          {
            type: 'required',
            message: 'Kode COA wajib diisi!'
          }
        ]
      },
      {
        dataField: 'journalTypeId',
        caption: 'Tipe Jurnal',
        lookup: {
          dataSource: journalType(this.props.store),
          valueExpr: 'id',
          displayExpr: (item) => {
            return item && item.journalTypeCode + ' - ' + item.journalTypeDesc
          }, // provides display values
          searchEnabled: true,
          deferRendering: false,
        },
        validationRules: [
          {
            type: 'async',
            validationCallback: async (e) => {
              this.dataFromType = e.data
              const result = await this.checkCoaAndTypeId()
              if (result.isValid) {
                return true
              } else {
                throw new Error(result.message)
              }
            },
          },
          {
            type: 'required',
            message: 'Tipe Jurnal wajib diisi!'
          }
        ]
      },
      {
        dataField: 'type',
        caption: 'Tipe Saldo',
        lookup: {
          dataSource: [
            {
              id: 'p',
              value: 'Positif (Mengikuti Saldo Normal)'
            },
            {
              id: 'n',
              value: 'Negatif (Kebalikan dari Saldo Normal)'
            }
          ],
          valueExpr: 'id',
          displayExpr: 'value',
        },
        validationRules: [
          {
            type: 'required',
            message: 'Tipe Saldo wajib diisi!'
          }
        ]
      },
      {
        dataField: 'dcMethod',
        caption: 'Filter DC',
        lookup: {
          dataSource: [
            {
              id: 'd',
              value: 'Hanya Debet'
            },
            {
              id: 'c',
              value: 'Hanya Kredit'
            },
            {
              id: 'b',
              value: 'Tidak ada Filter'
            },
          ],
          valueExpr: 'id',
          displayExpr: 'value',
        },
        validationRules: [
          {
            type: 'required',
            message: 'Filter DC wajib diisi!'
          }
        ]
      }
    ]

    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: this.updateData.bind(this)
        },
        toolbar: "bottom"
      },
    ];

    this.formRef = React.createRef()
    this.dataGridRef = React.createRef()
    this.dataReportCoa = []
    this.reportHeaderDetailsId = null
    this.isNew = false
  }


  retriveData = async (data) => {
    this.retrieveDataDetail(data.id)
    this.setState({
      DataMaster: data
    })
  }

  retrieveDataDetail = async (reportHeaderDetailsId) => {
    this.reportHeaderDetailsId = reportHeaderDetailsId
    const response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${reportHeaderDetailsId}`)

    this.dataReportCoa = response

    this.dataGridRef.current.forceRefresh(true)
  }

  updateData = async () => {
    this.props.store.dispatch(showLoading(true))
    try {
      var data = this.dataGridRef.current.getDataSource()
      var deleted = this.dataGridRef.current.getDeletedIndex()

      const reportHeaderDetailsId = this.state.DataMaster.id

      for (const dataInsert of data) {
        if (typeof dataInsert.id === 'number') {
          const responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas`, 'PUT', {
            values: dataInsert
          }, dataInsert)
        } else {
          delete dataInsert.id
          dataInsert.reportHeaderDetailsId = reportHeaderDetailsId
          const responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas`, 'POST', {
            values: dataInsert
          })
        }
      }

      for (const dataDeleted of deleted) {
        const responseInsert = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas`, 'DELETE', dataDeleted)
      }

      let type = 'success'
      let text = 'Data berhasil disimpan!'
      notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 1000);

      this.dataGridRef.current.emptyDeletedIndex()
      this.isNew = true
      this.hide()

      this.props.store.dispatch(showLoading(false))
    } catch (e) {
      let type = 'error'
      let text = 'Data gagal disimpan!'
      notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 1000);
      console.log(e)
      this.props.store.dispatch(showLoading(true))
    }
  }

  deleteAPI = async(data) => {
    this.dataGridRef.current.push([
      {
        type: "remove",
        key: data.id
      }
    ])
  }

  show = async(reportHeaderId) => {
    const ReportData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store, `report-headers/${reportHeaderId}`, 'GET');
    this.setState({
      popupVisible: true,
      reportHeaderData: ReportData
    });
  };

  hide = async(e) => {
    var result
    if (this.state.popupVisible === true && !this.isNew) {
      if (typeof e.cancel === 'boolean') {
        e.cancel = true
      }
      var result = await confirm('Apakah anda yakin untuk membatalkan input data ini?', 'Konfirmasi')
    } else {
        result = true
    }
    if (result) {
      if(!this.isNew) {
        if (typeof e.cancel === 'boolean') {
          e.cancel = false
        }
      }

      this.setState({
        popupVisible: false,
      });

      this.dataFromType = {}
      this.dataFromCoa = {}
      this.dataReportCoa = []
      this.reportHeaderDetailsId = null
      this.dataGridRef.current.forceRefresh(true)
    }
  };

  showModalCashFlow = (type, data) => {
    var listData = this.dataGridRef.current.getDataSource()
    if(type === 'edit') {
      this.modalEditCashFlowConfig.current.show()
      this.modalEditCashFlowConfig.current.retrieveData(this.state.DataMaster.id, data, type)
    } else {
      this.modalAddCashFlowConfig.current.show()
      this.modalAddCashFlowConfig.current.retrieveData(this.state.DataMaster.id, listData, type)
    }
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          text: 'Tambah',
          onClick: (e) => {
            this.showModalCashFlow('add')
          },
        }
      }
    )
  }

  forceRefresh = () => {
    this.dataGridRef.current.forceRefresh(true)
  }

  loadData = () => {
    return this.dataReportCoa
  }

  checkCoaAndTypeId = async () => {
    // var listData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${this.reportHeaderDetailsId}`)
    var listData = this.dataGridRef.current.getDataSource()
    let coa = listData.find(value => value.coaId === this.dataFromCoa.coaId)
    let tipeJurnal = listData.find(value => value.journalTypeId === this.dataFromType.journalTypeId)

    if (coa && tipeJurnal) {
      return {
        isValid: false,
        message: `COA Dan Tipe Jurnal sudah terpakai!`
      }
    }

    return {
      isValid: true,
      message: ''
    }
  }

  submitDataTemp = async (data) => {
    var dataCoaAndType = this.dataGridRef.current.getDataSource()
    if(data.id) {
      let dataID = dataCoaAndType.filter(val => val.id !== data.id)
      var coa = dataID.find(value => value.coaId === data.coaId)
      var type = dataID.find(value => value.journalTypeId === data.journalTypeId)
    } else {
      var coa = dataCoaAndType.find(value => value.coaId === data.coaId)
      var type = dataCoaAndType.find(value => value.journalTypeId === data.journalTypeId)
    }

    if(coa && type) {
      await alert('Tidak dapat menambah data! COA dan Type sudah ada!', 'Informasi')
      if(data.id) {
        this.modalEditCashFlowConfig.current.show()
        this.modalEditCashFlowConfig.current.retrieveDataDetail(data)
      } else {
        this.modalAddCashFlowConfig.current.show()
        this.modalAddCashFlowConfig.current.retrieveDataDetail(data)
      }
    } else {
      if(data.id) {
        this.dataGridRef.current.push([{
          type: "update",
          data: {
            coaId: data.coaId,
            dcMethod: data.dcMethod,
            journalTypeId: data.journalTypeId,
            type: data.type
          },
          key: data.id
        }])
      } else {
        this.dataGridRef.current.push([{
          type: "insert",
          data: {
            coaId: data.coaId,
            dcMethod: data.dcMethod,
            journalTypeId: data.journalTypeId,
            type: data.type
          },
        }])
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={false}
          showTitle={true}
          title={`Edit Konfigurasi Detail ${this.state.reportHeaderData.reportType} - ${this.state.reportHeaderData.reportName}`}
          width={"1200px"}
          height={"calc(100vh - 100px)"}
          toolbarItems={this.PopupToolbarItem}
        >
          <Form
            colCount={2}
            id={"formMaster"}
            formData={this.state.DataMaster}
            items={this.formItem}
            ref={this.formRef}
            readOnly={true}
          />

          <DevExpressDataGrid
            ref={this.dataGridRef}
            loadAPI='cash-flow-config'
            insertAPI='cash-flow-config'
            updateAPI='cash-flow-config'
            deleteAPI='cash-flow-config'

            backendserver={process.env.REACT_APP_BACKEND_ACC}

            ArraySourceData={this.loadData}
            useArraySource={true}

            menuRightClick={false}

            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}
            exportExcel={false}
            exportFileName={"Cash Flow Detail Config"}
            allowExportSelectedData={false}
            selection={"none"}

            showBorders={true}
            height={'calc(100vh - 330px'}

            paging={false}
            defaultPageSize={10}

            //bagian konfigurasi popup saat insert dan edit record
            popupTitle={`Edit Konfigurasi ${this.state.reportHeaderData.reportType} - ${this.state.reportHeaderData.reportName}`}
            popupWidth={700} //masukan dalam ukuran pixel
            popupHeight={300} //masukkan dalam ukuran pixel

            popupFormLabelLocation='left' //accepted value = top, left, right
            popupFormMinColWidth={300} // minimum lebar kolom
            popupFormColCount={1} //jumlah kolom pada form

            //akhir bagian konfigurasi popup

            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

            FilterRow={false} // set false untuk mematikan fitur filter

            onToolbarPreparing={this.onToolbarPreparing}
            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

            // onRowInserting = {this.onRowInserting}
            // onRowUpdating = {this.onRowUpdating}

            //contoh konfigurasi kolom
            //this.columns = [{
            //    dataField: 'kolom1',
            //    caption: 'Ini Kolom 1'
            //}, {
            //    dataField: 'kolom2',
            //    caption: 'Ini Kolom 2'
            //}]
            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

            store={this.props.store} // jangan di edit edit
          />
        </Popup>

        <ModalAddCashFlowConfig 
          store={this.props.store} 
          ref={this.modalAddCashFlowConfig} 
          forceRefresh={this.forceRefresh} 
          submitDataTest={this.submitDataTemp}
          checkCoaAndTypeId={this.checkCoaAndTypeId}
          reportHeaderData={this.state.reportHeaderData}
          actionType={'add'}
          />

        <ModalAddCashFlowConfig 
          store={this.props.store} 
          ref={this.modalEditCashFlowConfig} 
          forceRefresh={this.forceRefresh} 
          submitDataTest={this.submitDataTemp}
          checkCoaAndTypeId={this.checkCoaAndTypeId}
          reportHeaderData={this.state.reportHeaderData}
          actionType={'edit'}
        />
      </React.Fragment>

    );
  }
}

export default ModalDetailCashFlowConfig;
