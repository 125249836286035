import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { formatUploadFileData, imageSource } from 'plugin/helper';
import { Item } from 'devextreme-react/accordion';
import { FileUploader, Box } from 'devextreme-react';

class TransaksiMpManfaatLainnyaSkBayar extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.formItem = [
            {
                dataField: 'noSK',
                label: {
                    alignment: 'left',
                    text: 'No SK'
                },
            },
            {
                dataField: 'tanggalSk',
                label: {
                    alignment: 'left',
                    text: 'Tanggal SK'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
                
            },
            {
                dataField: 'jenisSk',
                label: {
                    alignment: 'left',
                    text: 'Jenis SK'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                dataSource: [
                    {
                        id: 1,
                        value: 'Jenis 1'
                    },
                    {
                        id: 2,
                        value: 'Jenis 2'
                    },
                ],
                valueExpr: "id", // contains the same values as the "statusId" field provides
                displayExpr: "value", // provides display values
                searchEnabled: true,
                deferRendering: false,
                width: '100%',
                }
            },
            {
                dataField: 'tanggalEfektif',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Efektif'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
                
            },
            // {
            //     dataField: "file",
            //     label: {
            //         alignment: 'left',
            //         text: "File"
            //     },
            //     editorType: "dxFileUploader",
            //     editorOptions: {
            //         selectButtonText: "Select File",
            //         uploadMode: "useForm",
            //         onValueChanged: this.onUploadStarted.bind(this),
            //         allowedFileExtensions: [".png", ".jpg", ".jpeg"]
            //     },
            // },
            
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
    }

    retrieveData = async(data) => {    
        this.setState({
            dataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`] : formattedFileData.base64data,
                        [`${fieldName}DataContentType`] : formattedFileData.mime,
                    }
                }))
            }
            fr.readAsDataURL(files)
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Surat Keputusan Pembayaran'} 
                minWidth={500}
                minHeight={500}
                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY: 'auto',height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                            />
                        </div>
                        <div className="col-md-12">
                            <Box
                                direction={'row'}
                                width={'100%'}
                            >
                                <Item ratio={1}>
                                    <FileUploader
                                        accept={'image/png, image/jpeg'} 
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)} 
                                        labelText="Upload File Here"
                                        showFileList={false}
                                        name={'file'}
                                    />
                                    <div className="text-center mt-5">
                                        <img className={`${this.state.dataMaster.fileData || this.state.dataMaster.fileDataContentType ? '' : 'd-none'}`} src={this.state.dataMaster.fileData ? imageSource(this.state.dataMaster.fileDataContentType, this.state.dataMaster.fileData) : ''} alt="Image View" width="30%"/>
                                    </div>
                                </Item>
                            </Box>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default TransaksiMpManfaatLainnyaSkBayar