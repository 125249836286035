import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react';
import { programs } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { download, formatDate, formatTime, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';
import Excel from 'exceljs/dist/exceljs';
import { ab2str } from 'plugin/helper';
import { confirmAlert } from 'react-confirm-alert';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';

export default class UploadAbsen extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
            DataDocument:{},
            uploadedData:[]
        }

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.formMaster = [
            {
                dataField: 'programId',
                label: {
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
        ]
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.fileUploderRef = React.createRef()
        
        this.resultData = []
        this.data = []
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster: {},
            DataDocument: {}
        })
    }

    getData = async(data) => {
        this.data = data
    }

    submitData = async () => {
        var DataMaster = {...this.state.DataMaster}
        let listData = []
        DataMaster.listUpload = listData
        let list = this.resultData
        let data = this.data

        for (const value of list) {
            var tanggalAbsen = data.find(val => val.tanggal === value.tanggal);
            listData.push({ ...value });
        }
        if (tanggalAbsen) {
            this.hide();
            confirmAlert({
                title: 'Konfirmasi',
                message: 'Apakah anda yakin ingin mengubah data ini?',
                buttons: [
                    {
                        label: 'Ya',
                        onClick: () => this.sendUpload(DataMaster)
                    },
                    {
                        label: 'Tidak'
                    }
                ]
            });
        } else {
            this.sendUpload(DataMaster);
        }
    }

    sendUpload = async(data) => {
        try {
            var loadApi = 'karyawan-absens/upload-file-absen'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: data
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Input Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000); 
        }
    }

    // onUploadStarted = (e) => {
    //     // var fieldName = e.component._options._optionManager._options.name
    //     // this.fieldName = fieldName
    //     // for (var i = 0; i < e.value.length; i++) {
    //     //     var files = e.value[i];

    //     //     var fr = new FileReader();

    //     //     var formattedFileData;
    //     //     var formatNameAndExtention

    //     //     var DataDocument = { ...this.state.DataDocument };

    //     //     fr.onload = efr => {
    //     //         formattedFileData = formatUploadFileData(efr.target.result);
    //     //         formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
    //     //         DataDocument[`${fieldName}Name`] = formatNameAndExtention.name;
    //     //         DataDocument[`${fieldName}Base64`] = formattedFileData.base64data;
    //     //         DataDocument[`${fieldName}Base64ContentType`] = formattedFileData.mime;
    //     //         DataDocument[`${fieldName}Extension`]= formatNameAndExtention.extention;
    //     //         this.setState({
    //     //             DataDocument: DataDocument
    //     //         });
    //     //     };

    //     //     fr.readAsDataURL(files);
    //     // }
    // }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var resultData = []
        for (var i = 0; i < e.value.length; i++) { 
            var files = e.value[i]
            let fileName = files.name

            var reader = new FileReader();
            var DataDocument = { ...this.state.DataDocument };
            reader.onload = async (e) => { 
                DataDocument[`${fieldName}Name`] = fileName;
                this.setState({
                    DataDocument: DataDocument
                });


                var dataArrayBuffer = new Uint8Array(e.target.result);
                const workbook = new Excel.Workbook();
                await workbook.xlsx.load(dataArrayBuffer);
                var worksheet = workbook.worksheets[0];      
                var rowCount = worksheet.rowCount   
                
                for(var row = 5;row <= rowCount; row++){
                    var data = {
                        absen: worksheet.getCell(`K${row}`).value,
                        catatan: worksheet.getCell(`M${row}`).value,
                        id: worksheet.getCell(`A${row}`).value,
                        departemen: worksheet.getCell(`C${row}`).value,
                        tanggal: worksheet.getCell(`D${row}`).value ? formatDate(worksheet.getCell(`D${row}`).value) : null,
                        masukI: worksheet.getCell(`E${row}`).value,
                        masukII: worksheet.getCell(`G${row}`).value,
                        keluarI: worksheet.getCell(`F${row}`).value,
                        keluarII: worksheet.getCell(`H${row}`).value,
                        terlambat: worksheet.getCell(`I${row}`).value,
                        pulangAwal: worksheet.getCell(`J${row}`).value,
                        total: worksheet.getCell(`L${row}`).value,
                    }
                     
                    resultData.push(data)
                }
                this.resultData = resultData
            }
            reader.readAsArrayBuffer(files);
        }
    }

    previewFile = async (state, fileName) => {
        var src = imageSource(state[`${fileName}Base64ContentType`], state[`${fileName}Base64`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataDocument[`${fileName}Base64ContentType`],this.state.DataDocument[`${fileName}Base64`]),this.state.DataDocument[`${fileName}Name`],this.state.DataDocument[`${fileName}Extension`])
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Absensi"}
                width={500}
                height={400}
                toolbarItems={this.PopupToolbarItem}
                >
                    <Form
                        ref={this.formMasterRef}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                        labelLocation={"left"}
                    />
                    <FileUploader
                        accept={'.xls,.xlsx'}
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)}
                        labelText="Upload File Absensi"
                        showFileList={false}
                        name={'file'}
                        value = {this.state.uploadedData}
                        ref={this.fileUploderRef}
                    />
                    <div className="row">
                        <label className="col-3">File Name:</label>
                        <label className="col-8">{this.state.DataDocument.fileName}</label>
                    </div>
                    {/* <div className="col-md-6 text-right">
                        <button onClick={() => this.downloadFile('file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                            Download
                        </button>
                    </div> */}
                    <ModalPreviewPdf
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                    <ModalPreviewImage
                        ref={this.modalPreviewImageRef}
                        store={this.props.store}
                    />
                </Popup>
        )
    }
}
