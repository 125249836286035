import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from 'devextreme/ui/notify';

import { pengkinianType,tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, SKType } from 'dataSource/lookup';
import FormTiketPenugasan from './formTiket';
import httpRequest from 'plugin/httprequest';

import { confirmAlert } from 'react-confirm-alert'
import FormTiketHotel from './formTiketHotel';
class TiketHotel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.dataGridItems = []
        this.sppdId = 0

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
          ];
        this.columnTiket = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.hapusData(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'bookingNo',
                caption: 'No. Booking',
            },
            // {
            //     dataField: 'kamarNo',
            //     caption: 'No. Kamar',
            // },
            {
                dataField: 'tiket',
                caption: 'Tiket',
                alignment: 'center',
                columns : [
                    {
                        dataField: 'hotelName',
                        caption: 'Nama Hotel',
                    },
                    {
                        dataField: 'checkinDate',
                        caption: 'Tanggal Check In',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    
                    // {
                    //     dataField: 'jamCheckin',
                    //     caption: 'Jam Check In',
                    //     dataType:"datetime",
                    //     format:"HH:mm",
                    // },
                    {
                        dataField: 'chekoutDate',
                        caption: 'Tanggal Check Out',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    // {
                    //     dataField: 'jamCheckout',
                    //     caption: 'Jam Check Out',
                    //     dataType:"datetime",
                    //     format:"HH:mm",
                    // },
                    {
                        dataField: 'alamat',
                        caption: 'Alamat',
                    },
                ]
            },
            {
                dataField: 'totalBiaya',
                caption: 'Total Biaya',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.sppdId = 0
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = async(data) => {    
        this.sppdId = data.id
        this.setState({
            dataMaster: data
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tiket',
                    onClick: (e) => {
                        this.showModalInsert(e)
                    },
                }
            }
        )
    }

    showModalInsert = () => {
        this.modalInsertRef.current.show()
        this.modalInsertRef.current.getSppdId(this.sppdId)
    }

    showModalEdit = (data) => {
        this.modalEditRef.current.show()
        this.modalEditRef.current.getSppdId(this.sppdId)
        this.modalEditRef.current.retrieveData(data)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    hapusData = async (data) => {
        try {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-hotels   `,
                "DELETE",data.id
            );
            this.forceRefresh()
            notify({ message: 'Sukses', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
        } catch (e) {
            console.log(e);
        }
        
    }

    loadData = async () => {
        var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-hotels/get-by-karyawanSppdId/${this.sppdId}`,
                "GET"
            );

        return response
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Tiket Penginapan"}
                width={"70vw"}
                height={"70vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                        <div className="row" style={{paddingTop: '8px'}}>
                            <div className="col-md-12">
                                <DevExpressDataGrid
                                    ref = {this.dataGridRef}
                                    loadAPI={`karyawan-sppd-tikets/get-by-karyawanSppdId/${this.sppdId || 0}`}
                                    insertAPI='karyawan-sppd-tikets'
                                    updateAPI='karyawan-sppd-tikets'
                                    deleteAPI='karyawan-sppd-tikets' 

                                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                                    useArraySource={true}
                                    ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Form Tiket"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Form Tiket'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={'calc(100vh - 470px)'}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnTiket} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing = {this.onToolbarPreparing}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <FormTiketHotel
                                    ref={this.modalInsertRef}
                                    store={this.props.store}
                                    forceRefresh={this.forceRefresh}
                                    type={'add'}
                                />
                                <FormTiketHotel
                                    ref={this.modalEditRef}
                                    store={this.props.store}
                                    forceRefresh={this.forceRefresh}
                                    type={'edit'}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default TiketHotel