import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { Form } from "devextreme-react";
import { efekBalanceBuyDate, getInstrumentById, instrumentProperties, instruments, kpas, kpds, productDefault } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import { showLoading } from 'redux/actions/loading';
import ModalKpaInvoice from 'components/pages/investasi/pengaturan/invoice-kpa/modalInvoice'
import notify from "devextreme/ui/notify";

class InvoiceKpa extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            formFilter: {
                productId: 1,
                paymentPeriode: new Date(this.sysDate ? this.sysDate.dateValue : null),
                statusInvoice: 1,
            }
        }

        this.dataGridRef = React.createRef()
        this.showModalInvoiceRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'paymentPeriode',
                label: {
                text: 'Tanggal Pembayaran',
                alignment: "left",
                location: "left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'statusInvoice',
                label: {
                text: 'Status',
                alignment: "left",
                location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'OPEN'
                        },
                        {
                            id: 2,
                            value: 'SEND'
                        },
                        {
                            id: 3,
                            value: 'PAID'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%'
                },
            },
            {
                dataField: 'productId',
                label: {
                text: 'Produk',
                alignment: "left",
                location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.productCode + ' - ' + e.productName
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.setState({
                            formFilter: this.state.formFilter
                        })
                    },
                },
                horizontalAlignment: "left"
            }
        ]

        this.columns = [
            {
            type: 'buttons',
            buttons: 
                [
                    'edit',
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            // this.kirim(e.row.data)
                            this.showModalInvoice(e.row.data)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-danger',
                        onClick: (e) => {
                            this.batalInvoice(e.row.data);
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: "No.",
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'invoiceNo',
                caption: 'No Invoice',
            },
            {
                dataField: 'kpdId',
                caption: 'Pengelola',
                lookup: {
                  dataSource: kpds(this.props.store),
                  valueExpr: 'id',
                  displayExpr: 'noKontrak'
                }
            },
            {
                dataField: 'kpaId',
                caption: 'KPA',
                lookup: {
                  dataSource: kpas(this.props.store),
                  valueExpr: 'id',
                  displayExpr: 'kontraktorName'
                }
            },
            {
                dataField: 'instrumentId',
                caption: 'Nama Aset',
                lookup: {
                    dataSource: instrumentProperties(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'efekBalanceBuyDateId',
                caption: 'Tipe Aset',
                lookup: {
                    dataSource: efekBalanceBuyDate(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'tipe'
                }
            },
            {
                dataField: 'paymentDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'invoiceDate',
                caption: 'Tanggal Tagihan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'nominalInvoice',
                caption: 'Nominal Invoice',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'statusInvoice',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'OPEN'
                        },
                        {
                            id: 2,
                            value: 'SEND'
                        },
                        {
                            id: 3,
                            value: 'PAID'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () =>{
        var paymentPeriode = formatDate(this.state.formFilter.paymentPeriode)
        var statusInvoice = this.state.formFilter.statusInvoice
        var productId = this.state.formFilter.productId

        var loadApi = `invoices?paymentDate.equals=${paymentPeriode}&productId.in=${productId}&statusInvoice.in=${statusInvoice}`

        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadApi,
            "GET"
        );

        let id = 0
        let result = []
        for(var data of response){
            id++
            let dataTransaksi = {
                id: id,
                ...data,
            }
            result.push(dataTransaksi)
        }
        return result
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    showModalInvoice = (data) => {
        this.showModalInvoiceRef.current.show()
        this.showModalInvoiceRef.current.retrieveData(data)
    }

    batalInvoice = async(data) => {
        try {
            let loadAPI = `invoices/batalInvoice/${data.id}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'POST', {
                values: {}
            })

            console.log(response);
            if (response) {
                notify({ message: "Invoice Berhasil dibatalkan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000);
                this.forceRefresh();
            }
        } catch (error) {
            console.error(error);
            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);
        }
    }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Daftar Invoice Aset</h2>

        <Form
            colCount={4}
            id={'formFilter'}
            formData={this.state.formFilter}
            items={this.filterItem}
        />

        <DevExpressDataGrid
            ref={this.dataGridRef}
            loadAPI='invoice-kpa'
            insertAPI='invoice-kpa'
            updateAPI='invoice-kpa'
            deleteAPI='invoice-kpa'

            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

            useArraySource={true}
            ArraySourceData={this.loadData}

            allowAdding={false}
            allowDeleting={false}
            allowUpdating={false}

            exportExcel={true}
            exportFileName={"Aset Alocation"}
            allowExportSelectedData={true}
            selection={"multiple"}

            showBorders={true}

            paging={true}
            defaultPageSize={10}

            //bagian konfigurasi popup saat insert dan edit record
            popupTitle={'Aset Alocation'}
            popupWidth={700} //masukan dalam ukuran pixel
            popupHeight={500} //masukkan dalam ukuran pixel

            popupFormLabelLocation='left' //accepted value = top, left, right
            popupFormMinColWidth={300} // minimum lebar kolom
            popupFormColCount={3} //jumlah kolom pada form

            //akhir bagian konfigurasi popup

            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

            FilterRow={true} // set false untuk mematikan fitur filter

            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
            SummaryConfiguration={this.summary}

            onToolbarPreparing={this.onToolbarPreparing}

            //contoh konfigurasi kolom
            //this.columns = [{
            //    dataField: 'kolom1',
            //    caption: 'Ini Kolom 1'
            //}, {
            //    dataField: 'kolom2',
            //    caption: 'Ini Kolom 2'
            //}]
            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

            store={this.props.store} // jangan di edit edit
        />

        <ModalKpaInvoice
          ref={this.showModalInvoiceRef}
          store={this.props.store}
          forceRefresh={this.forceRefresh}
        />

      </div>
    )
  }
}

export default InvoiceKpa