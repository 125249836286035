import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, statusIuran } from 'dataSource/lookup';
import ModalGenerateProsesIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/prosesIuran/generate';
import ModalDetailProsesIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/prosesIuran/detail';
import httpRequest from 'plugin/httprequest';
import { showLoading } from 'redux/actions/loading';
import { alert,confirm } from 'devextreme/ui/dialog';

class ProsesIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            getUrl: 'iurans/ListIuran/1/1'
        }

        this.dataGridRef = React.createRef()
        this.modalDetailProsesIuranRef = React.createRef()
        this.modalGenerateProsesIuranRef = React.createRef()
        this.iuranType = []

        this.filterItem = [
            {
                dataField: 'bulan',
                label: {
                    text: 'Periode Bulan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                }
            },
            {
                dataField: 'tahun',
                label: {
                    text: 'Periode Tahun',
                    alignment: "left",
                    location:"left"
                },
                editorOptions:{
                    value: new Date().getFullYear(),
                    // readOnly: true
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'filter',
                label:{
                    visible:false,
                },
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Filter',
                    onClick: (e) => {
                        var filter = this.state.formFilter
                        var bulan = filter.bulan
                        var tahun = filter.tahun
                        this.setState({
                            getUrl: bulan && tahun ? `iurans/getByBulanAndTahun/${bulan}/${tahun}/1/1` : 'iurans/ListIuran/1/1'
                        })
                    }
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text : 'Un-generate',
                        hint : 'Un-generate',
                        cssClass: 'text-info',
                        onClick : async(e) => {
                            if (e.row.data.iuranByAkun.length === 0) {
                                await alert('Data sudah di ungenerate', 'Informasi!');
                                return false;
                            } else {
                                this.unGenerate(e.row.data.id)
                            }
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-info',
                        onClick : async (e) => {
                            if (e.row.data.iuranByAkun.length !== 0) {
                                await alert('Silahkan ungenerate data terlebih dahulu', 'Informasi!');
                                return false;
                            } else {
                                this.hapus(e.row.data.id)
                            }
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender : (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'bulan',
                caption: 'Periode Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Periode Tahun',
            },
            {
                dataField: 'pemberiKerja',
                caption: 'Pemberi Kerja',
                // lookup: {
                //     dataSource: participants(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'participantName'
                // }
            },
            {
                dataField: 'produk',
                caption: 'Produk',
                // lookup: {
                //     dataSource: product(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'productCode'
                // }
            },
            {
                dataField: 'pesertaAktif',
                caption: 'Peserta Aktif',
            },
            {
                dataField: 'iuran',
                caption: 'Iuran',
                alignment:'center',
                columns: [
                    {
                        dataField: 'iuranByType[0].nominal',
                        caption: 'Iuran Peserta',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e
                            var type = this.iuranType
                            var total = 0
                            for(var value of data.iuranByType){
                                var iuranType = type.find(val => val.id === value.iuranType)

                                if(iuranType && iuranType.iuranPeserta){
                                    total = total + value.nominal
                                }
                            }

                            return total
                        }
                    },
                    {
                        dataField: 'iuranByType[1].nominal',
                        caption: 'Iuran PK',
                        alignment: 'right',
                        format: "#,##0.00",
                        calculateCellValue: (e) => {
                            var data = e
                            var type = this.iuranType
                            var total = 0
                            for(var value of data.iuranByType){
                                var iuranType = type.find(val => val.id === value.iuranType)

                                if(iuranType && !iuranType.iuranPeserta){
                                    total = total + value.nominal
                                }
                            }

                            return total
                        }
                    },
                ]
            },
            {
                dataField: 'totalIuran',
                caption: 'Total Iuran',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusIuran(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var loadAPIuranTypes = `iuran-types`
        var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

        this.iuranType = dataIuranTypes
    } 
    
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Generate Iuran',
                    onClick: (e) => {
                        this.showModalGenerateIuran()
                    },
                 }
            }
        )
    }

    showModalGenerateIuran = () => {
        this.modalGenerateProsesIuranRef.current.show()
    }

    showModalDetail = (data) => {
        this.modalDetailProsesIuranRef.current.show()
        this.modalDetailProsesIuranRef.current.retrieveData(data)
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    hapus = async(iuranId) => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        try {
            let deleteMasterAPI = 'iurans';
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, deleteMasterAPI, 'DELETE', iuranId)
            
            this.forceRefresh()
            var text = `Data berhasil di Hapus!`
            var type = 'success'
            store.dispatch(showLoading(false))
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        } catch (e) {
            console.log(e)
            store.dispatch(showLoading(false))
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }
    
    unGenerate = async(iuranId) => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        try {
        let dataGrid = this.dataGridRef.current.getDataSource();
        
        if (iuranId) {
            for (let value of dataGrid) {
                if (value.iuranByType.length !== 0) {
                    let deleteAPI = `iurans-and-iuran-detils/deleteByIuranId`
                    let responseDeleteDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, deleteAPI, 'DELETE',iuranId)

                    if (responseDeleteDetail) {
                        let deleteMasterApi = `iurans`;
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, deleteMasterApi, 'DELETE', iuranId);    
                        
                        this.forceRefresh()
                        var text = `Data berhasil di un-generate!`
                        var type = 'success'
                        store.dispatch(showLoading(false))
                        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                    } else {
                        let deleteMasterApi = `iurans`;
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, deleteMasterApi, 'DELETE', iuranId);    
                        
                        this.forceRefresh()
                        var text = `Data berhasil di un-generate!`
                        var type = 'success'
                        store.dispatch(showLoading(false))
                        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                    }
                }        
            }
        }

        } catch (e) {
            console.log(e)
            store.dispatch(showLoading(false))
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }
    prosesData = async(idIuran) => {
        var value = {
            idIuran : idIuran
        }

        try {
            var loadAPI = 'pesertas/transaksiIuran'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                values:value
            })
            var text = 'Data Berhasil Diproses!'
            var type = "success"

            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);  
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);  
            
        }
        this.forceRefresh()
    }

    loadData = () => {
        return this.dataIuran
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Proses Iuran Bulanan</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='iurans'
                    updateAPI='iurans'
                    deleteAPI='iurans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    remoteOperations = {false}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Proses Iuran Bulanan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Proses Iuran Bulanan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalGenerateProsesIuran 
                    ref={this.modalGenerateProsesIuranRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
                <ModalDetailProsesIuran 
                    ref={this.modalDetailProsesIuranRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default ProsesIuran