import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { formatUploadFileData } from 'plugin/helper'

class ModalFileSettlementTransaksiObligasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: console.log('simpan')
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'transaksi',
                label: {
                    alignment: 'left',
                    text: 'Transaksi'
                },
                readOnly: true
            },
            {
                dataField: 'efek',
                label: {
                    alignment: 'left',
                    text: 'efek'
                },
                readOnly: true
            },
            {
                itemType: 'group',
                cssClass: 'border mt-3',
                colCount: 1,
                colSpan: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Settlement',
                        cssClass: 'p-3',
                        items: [
                            {
                                dataField: "instruksiBank",
                                label: {
                                    text: "Instruksi Bank",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                            {
                                dataField: "konfirmasiBank",
                                label: {
                                    text: "Konfirmasi Bank",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    },
                ]
            }
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    

    retrieveData = async(data = null) => {    
        this.setState({
            dataMaster: data
        })
    }

    loadDummyData = () => {
        var dummy = [
            {
                id: 1,
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
            {
                id: 2,
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
            {
                id: 3,
                kategori: 'Telah di Alokasi',
                emiten: '',
                sektor: 'Telah Di Alokasi'
            },
        ]


        return dummy
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Alur Dokumen Investasi'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalFileSettlementTransaksiObligasi