import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { formatUploadFileData, imageSource } from 'plugin/helper'
import { currency, kjpp, productDefault } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import ArrayStore from 'devextreme/data/array_store';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ScrollView from 'devextreme-react/scroll-view'
import notify from 'devextreme/ui/notify'

class ModalValuasiPenyertaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'produkId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    searchEnabled: true
                }
            },
            {
                dataField: 'noValuasi',
                label: {
                    alignment: 'left',
                    text: 'No. Valuasi'
                },
                editorOptions: {
                    readOnly: true,
                }
            },
            {
                dataField: 'tahunValuasi',
                label: {
                    alignment: 'left',
                    text: 'Tahun Valuasi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    step: 0
                }
            },
            {
                dataField: 'tglValuasi',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Valuasi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                }
            },
            {
                dataField: 'tglTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                }
            },
            {
                dataField: 'kjppId',
                label: {
                    alignment: 'left',
                    text: 'KJPP'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: kjpp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'kjppName',
                    searchEnabled: true
                }
            },
            {
                dataField: 'tglMulai',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Mulai Penilaian'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                }
            },
            {
                dataField: 'tglAkhir',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Akhir Penilaian'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                }
            },
            {
                dataField: 'catatan',
                label: {
                    alignment: 'left',
                    text: 'Catatan Valuasi'
                },
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Mata Uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                    searchEnabled: true,
                    onSelectionChanged: (e) => {
                        var slctd = e.selectedItem
                        if (slctd) {
                            this.formRef.current.instance.updateData('kurs', slctd.rate)
                        }
                    }
                }
            },
            {
                dataField: 'kurs',
                label: {
                    alignment: 'left',
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: "file",
                label: {
                    text: "File Dokumen",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    accept: 'image/png, image/jpeg, application/pdf',
                    onValueChanged: this.onUploadStarted.bind(this),
                },
            },
            {
                itemType: 'group',
                caption: 'EFEK VALUASI',
                items: [
                    {
                        dataField: 'efekBalanceAvgId',
                        label: {
                            text: 'Instrument',
                            alignment: 'left',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.instrumentCode + ' - ' + e.instrumentName
                            },
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.setState({
                                        instrumentId: e.selectedItem.instrumentId
                                    })
                                }
                            }
                        }
                    },
                    {
                        dataField: 'nominalPenempatan',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Penempatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nominalPenempatan = e.value
                                var nominalValuasi = this.formRef.current.instance.getEditor('nominalValuasi').option('value')
                                this.formRef.current.instance.updateData('spi', nominalValuasi - nominalPenempatan)
                            }
                        }
                    },
                    {
                        dataField: 'nominalValuasi',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Valuasi'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nominalPenempatan = this.formRef.current.instance.getEditor('nominalPenempatan').option('value')
                                var nominalValuasi = e.value
                                this.formRef.current.instance.updateData('spi', nominalValuasi - nominalPenempatan)
                            }
                        }
                    },
                    {
                        dataField: 'spi',
                        label: {
                            alignment: 'left',
                            text: 'SPI'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true,
                        }
                    },
                ]
            },
        ]
    }
    show = async () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData: {}
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Base64`]: formattedFileData.base64data,
                    [`${fieldName}Base64ContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        var dataMaster = this.state.dataMaster
        var submitFile = {
            fileBase64: this.state.fileBase64,
            fileBase64ContentType: this.state.fileBase64ContentType,
            fileName: this.state.fileName,
        }
        if (submitFile) {
            try {
                var responseFile = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'file-investasis', 'POST', { values: submitFile })
                if (responseFile) {
                    var submitData = {
                        ...dataMaster,
                        fileId: responseFile.id,
                        instrumentId: this.state.instrumentId,
                    }
                    if (submitData) {
                        try {
                            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'efek-valuasis/valuasi-penyertaan-langsung-create-update', 'POST', { values: submitData })
                            notify({ message: 'Sukses Menyimpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                            this.hide()
                            this.props.forceRefresh()
                        } catch (e) {
                            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    }
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    efekBalanceProperti = async (store) => {
        var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'instruments?size=999', 'GET')
        let loadAPI = `efek-balance-avgs?categoryId.in=11`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')
        var getBalance = []

        for (var data of response) {
            var dataBalance = {
                instrumentCode: getInstrument.find(val => val.id == data.instrumentId).instrumentCode,
                instrumentName: getInstrument.find(val => val.id == data.instrumentId).instrumentName,
                id: data.id,
            }
            getBalance.push(dataBalance)
        }

        let dataSource = new ArrayStore({
            data: getBalance,
            key: 'id'
        });

        return dataSource
    }

    previewFile = async () => {
        var src = imageSource(this.state.fileBase64ContentType, this.state.fileBase64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    retrieveData = async (type, data) => {
        var balance = await this.efekBalanceProperti(this.props.store)
        this.formRef.current.instance.getEditor('efekBalanceAvgId').option('dataSource', balance)
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Valuasi Penyertaan'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    ref={this.formRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileBase64 ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                                <ModalPreviewPdf
                                    ref={this.modalPreviewPdfRef}
                                    store={this.props.store}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalValuasiPenyertaan