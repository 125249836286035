import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { Popup } from 'devextreme-react'
import FormKaryawanPenerima from './formKaryawanPenerima'
import { formatDate } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import { jabatan, karyawan, sdmUnit, unit } from 'dataSource/lookup'
import { alert, confirm } from 'devextreme/ui/dialog';

import notify from "devextreme/ui/notify";
import ViewDaftarKaryawan from './viewDaftarKaryawan'

export default class FormDaftarKaryawanPenerima extends Component {
    constructor(props){
        super(props) 

        this.state = {
            popupVisible: false,
            DataMaster:{}
        }

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalViewKarPenerima(e.row.data, true);
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        onClick : (e) => {
                            this.removeData(e.row.data)
                        },
                    },
                ],
            },
            {
                dataField: 'karyawanId',
                caption: 'Karyawan',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.nik + ' - ' + item.fullName
                    }, // provides display values
                }
            },
            {
                dataField: 'unitId',
                caption: 'Unit',
                lookup:{
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values'
                }
            },
            {
                dataField: 'jabatanId',
                caption: 'Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName'
                }
            },
            {
                dataField: 'transactionNominal',
                caption: 'Nominal',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.showModalKaryawanPenerimanRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.showViewKarRef = React.createRef();
        this.dataKaryawanPenerima = []
        this.deleteData = [];
        this.addData = [];
        this.listKaryawan = [];
        this.programId = 0
    }

    showModalViewKarPenerima = (data, type) => {
        console.log(type);
        if (data){
            this.showViewKarRef.current.retrieveData(data, type);
            this.showViewKarRef.current.getKaryawanByUserLogin(data);
        }
        this.showViewKarRef.current.show();
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: "Generate Semua Karyawan",
                    onClick: (e) => {
                        this.generateKaryawanPenerima(e)
                    },
                    visible: this.state.DataMaster.penerima == '1' ? true : false
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon:"plus",
                    text: "Karyawan",
                    onClick: (e) => {
                        this.showModalKaryawanPeneriman(e)
                    },
                    visible: this.state.DataMaster.penerima == '2' ? true : false
                }
            },
        )
    }

    retrieveData = async (data) => {
        this.setState({
            DataMaster:data
        })
        
        this.idKarTunjanganLain = data.id
        if (this.props.action === 'rapel') {
            var getProgramId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store,`karyawan-rapels/${data.id}`, 'GET')
            var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 
                `karyawan-transactions/getAllByReffTransactionAndPengajuanTanggal/${'RAPEL KARYAWAN'}/${formatDate(data.pengajuanTanggal)}`, 'GET')
            this.programId = getProgramId.programId
            }else{
                var getProgramId = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store,`karyawayan-tunjangan-lains/${data.id}`, 'GET')
            var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 
                `karyawan-transactions/getAllByReffTransactionAndPengajuanTanggal/${'TUNJANGAN KARYAWAN LAIN'}/${formatDate(data.tanggalPengajuan)}`, 'GET')
                this.programId = getProgramId.programId
        }
            
        this.dataKaryawanPenerima = getKaryawan
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    showModalKaryawanPeneriman = (e) => {
        this.showModalKaryawanPenerimanRef.current.retrieveData(this.state.DataMaster)
        this.showModalKaryawanPenerimanRef.current.getListKaryawan(this.programId)
        this.showModalKaryawanPenerimanRef.current.show()
    }

    generateKaryawanPenerima = async (e) => {
        try {
            if (this.props.action == 'rapel') {
                var loadAPI = `karyawan-rapels/generateKaryawanRapel/${this.state.DataMaster.id}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST',{
                    values:{}
                })
                if (response) {
                    var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 
                        `karyawan-transactions/getAllByReffTransactionAndPengajuanTanggal/${'RAPEL KARYAWAN'}/${formatDate(this.state.DataMaster.pengajuanTanggal)}`, 'GET')
                        
                    this.dataKaryawanPenerima = getKaryawan
                    this.DataGridPengajuanRef.current.forceRefresh(true)
                }
            }else{
                var loadAPI = `karyawayan-tunjangan-lains/generateTunjanganLain/${this.state.DataMaster.id}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST',{
                    values:{}
                })
    
                if (response) {
                    var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 
                        `karyawan-transactions/getAllByReffTransactionAndPengajuanTanggal/${'TUNJANGAN KARYAWAN LAIN'}/${formatDate(this.state.DataMaster.tanggalPengajuan)}`, 'GET')
                        
                    this.dataKaryawanPenerima = getKaryawan
                    this.DataGridPengajuanRef.current.forceRefresh(true)
                }
            }
            
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    submitData = async() => {
        try {
            var getDatakaryawan = this.DataGridPengajuanRef.current.getDataSource()
            var listKaryawan = []
            
            let resultMap = this.deleteData.map(value => value.key);
            let index = resultMap.indexOf(10);
            if (index > -1){
                resultMap.splice(index, 1);
            }
            for (let dataDelete of resultMap){
                await this.deleteAPI(dataDelete);
            }
            while(this.deleteData.length > 0){
                this.deleteData.pop();
            }
        
            for(var data of getDatakaryawan){
                if (typeof data.id === 'string') {
                    delete data.id
                    this.listKaryawan.push({karyawanId: data.karyawanId});
                }
                console.log(this.listKaryawan);
            }

            if (this.props.action == 'rapel') {
                var dataKar = { 
                    karyawanList:this.listKaryawan,
                    karyawanRapelId:this.idKarTunjanganLain
                }
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawan-rapels/generate-personal-rapel', 'POST',{
                    values:dataKar
                })
            }else{
                var dataKar = { 
                    karyawanList:this.listKaryawan,
                    karyawanTunjanganLainId:this.idKarTunjanganLain
                }
    
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawayan-tunjangan-lains/generatePersonal', 'POST',{
                    values:dataKar
                })
            }

            if (response) {
                this.hide()
                this.DataGridPengajuanRef.current.forceRefresh(true)
                this.props.forceRefresh()
                
                notify({ message: 'Sukses ', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        } catch (e) {
            
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'karyawan-transactions', 'DELETE', data);
        } catch (error) {
            console.log(error);
        }
    }

    editDataToApi = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'karyawan-transactions', 'PUT', {
                values: data,
                key: data.id
            }, data)
        } catch (error) {
            console.log(error);
        }
    }

    addDataToApi = async () => {
        console.log('test');
    }

    removeData = (data) => {
        if (this.props.from == 'daftar') {
            notify({ message: "Tidak dapat dihapus", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
            return
        }
        // this.DataGridPengajuanRef.current.push([
        //     {
        //         type: 'remove',
        //         key : data.id
        //     }
        // ])
        if (data){
            this.deleteData.push(
                {
                    type: 'remove',
                    key: data.id
                }
            )
        }
        this.DataGridPengajuanRef.current.push(this.deleteData);
        notify({ message: "Sukses Hapus Karyawan Penerima", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.DataGridPengajuanRef.current.emptyDeletedIndex()
        this.listKaryawan = [];
        // this.props.forceRefresh()
        this.dataKaryawanPenerima = []
        this.programId = 0
        this.setState({
            DataMaster:{},
            popupVisible: false,
        })
    }

      /*Function check to existing data*/
    isDataExists = (arrSource, keyValue) => {
        return arrSource.some(el => el.karyawanId === keyValue);
    }

    getDataSource = async(data) => {
        try{
            var key = data.id

            var arrCurrentData = this.DataGridPengajuanRef.current.getDataSource();
            var isExist = this.isDataExists(arrCurrentData, data.karyawanId);

            if (isExist){
                await alert("Data Tidak boleh kembar "," Gagal");
                return;
            } else if (key){
                this.addData.push(
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                )
                notify({ message: "Sukses Update Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.addData.push(
                    {
                        type: 'insert',
                        data : data
                    }
                )
                notify({ message: "Sukses Input Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
            this.DataGridPengajuanRef.current.push(this.addData);
        }catch(e){
            console.log(e)
        }
    }

    // getDataSource = (data) => {
    //     try{
    //         var key = data.id

    //         if(key){
    //             this.DataGridPengajuanRef.current.push([
    //                 {
    //                     type: 'update',
    //                     data : data,
    //                     key : key
    //                 }
    //             ])
    //             notify({ message: "Sukses Update Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    //         }else{
    //             this.DataGridPengajuanRef.current.push([
    //                 {
    //                     type: 'insert',
    //                     data : data
    //                 }
    //             ])
    //             notify({ message: "Sukses Input Tunjangan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    //         }
    //     }catch(e){
    //         console.log(e)
    //     }
    // }

    loadData = (e) => {
        return this.dataKaryawanPenerima
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled= {true}
                showTitle={true}
                title={"Daftar Karyawan Penerima"}
                width={"50vw"}
                height={"75vh"}
                toolbarItems={this.state.DataMaster.penerima == '1' || this.props.from == 'daftar' ?  this.PopupToolbarItem : this.PopupToolbarItem2 }
            >
            <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='regist-pengkinian-pengajuan-aktifs'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Pengajuan"}
                    allowExportSelectedData={false}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze
                    height = {'calc(70vh - 150px)'}
                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.props.from == 'daftar' ? [] : this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormKaryawanPenerima 
                    ref={this.showModalKaryawanPenerimanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    getDataSource={this.getDataSource}
                />

                <ViewDaftarKaryawan 
                    ref={this.showViewKarRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </Popup>
        )
    }
}
