import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { banks, instrumentSubCategoryDeposito, currency, instruments, fundManager, rekeningBank, provinces, cities, kjpp, instrumentsCategoryProperti, product, entityPG } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import { formatDefaultDate, formatNumberAum } from 'plugin/helper';


class FormSubInstrumentPelepasanProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {}
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No. Pengajuan'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'efekBalanceAvgId',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.instrumentCode + " - " + item.instrumentName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: async (e) => {
                        var slctd = e.selectedItem
                        if (slctd) {
                            var dataInstru = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${slctd.instrumentId}`, 'GET')
                            var dataBuyDates = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates?efekBalanceAvgId.in=${slctd.id}`)
                            this.formMasterRef.current.instance.getEditor('efekBalanceBuyDateId').option('dataSource', dataBuyDates)
                            this.formMasterRef.current.instance.updateData({
                                market: 'Sekunder',
                                instrumentId: slctd.instrumentId
                            })
                            this.setState({
                                dataDetail: dataInstru
                            })
                        }
                    }
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument ID'
                },
                visible: false
            },
            {
                dataField: 'efekBalanceBuyDateId',
                label: {
                    text: 'Instrument Detail'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && formatDefaultDate(item.buyDate) + " @ " + formatNumberAum(item.buyPrice);
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: async (e) => {
                        var slctd = e.selectedItem
                        if (slctd) {
                            // console.log(slctd)
                            this.formMasterRef.current.instance.updateData({
                                keterangan: slctd.keterangan,
                                hargaMinimum: slctd.price,
                                hargaMaksimum: slctd.price,
                            })
                            this.formDetailRef.current.instance.updateData({
                                tipe: slctd.tipe,
                                availableQty: slctd.quantityAvailable
                            })
                        }
                    }
                }
            },
            {
                dataField: 'jenisHarga',
                label: {
                    text: 'Jenis Harga'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    items: ['Tetap', 'Antara'],
                    layout: 'horizontal',
                }
            },
            {
                dataField: 'hargaMinimum',
                label: {
                    text: 'Harga Minimum',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'hargaMaksimum',
                label: {
                    text: 'Harga Maksimum',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        let price = e.value
                        let fee = this.formMasterRef.current.instance.getEditor('fee').option('value')
                        this.formMasterRef.current.instance.updateData('nominalFee', price * (fee / 100))
                    }
                }
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan',
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'qtyOrder',
                label: {
                    text: 'Jumlah Unit',
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                    step: 0,
                },
                validationRules: [
                    {
                        type: 'range',
                        min: 1,
                        message: 'Jumlah Unit tidak boleh kurang dari 1'
                    },
                    {
                        type: 'custom',
                        validationCallback: (e) => {
                            if (e.value > this.state.dataDetail.availableQty) {
                                return false
                            }
                            return true
                        },
                        message: 'Jumlah Unit tidak boleh lebih dari yang tersedia'
                    }
                ]
            },
            {
                dataField: 'agenPenjualId',
                label: {
                    text: 'Agen Penjual',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'market',
                label: {
                    text: 'Pasar'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'fee',
                label: {
                    text: 'Fee (%)'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        let price = this.formMasterRef.current.instance.getEditor('hargaMaksimum').option('value')
                        let fee = e.value
                        this.formMasterRef.current.instance.updateData('nominalFee', price * (fee / 100))
                    }
                }
            },
            {
                dataField: 'nominalFee',
                label: {
                    text: 'Nominal Fee'
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    readOnly: true
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Mata uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    onSelectionChanged: (e) => {
                        var slctd = e.selectedItem
                        if (slctd) {
                            this.formMasterRef.current.instance.updateData({ kurs: slctd.rate })
                        }
                    },
                    searchEnabled: true,
                },
            },
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'rekBankId',
                label: {
                    text: 'Rekening Pembayaran',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                    searchEnabled: true,
                },
            },
            {
                dataField: 'pengembangEntitasId',
                label: {
                    text: 'Agen Pengembang',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: entityPG(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                },
            },
        ]
        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI PROPERTI',
                items: [
                    {
                        dataField: 'instrumentCode',
                        label: {
                            text: 'Kode Properti'
                        },
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            text: 'Nama Properti'
                        },
                    },
                    {
                        dataField: 'categorySubId',
                        label: {
                            text: 'Jenis Properti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'subName'
                        }
                    },
                    // {
                    //     dataField: 'tipe',
                    //     label: {
                    //         text: 'Tipe'
                    //     }
                    // },
                    // {
                    //     dataField: 'availableQty',
                    //     label: {
                    //         text: 'Unit'
                    //     }
                    // },
                    {
                        dataField: 'lokasi',
                        label: {
                            text: 'Lokasi'
                        },
                    },
                    // {
                    //     dataField: 'provinsiId',
                    //     label: {
                    //         text: 'Provinsi'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: provinces(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'provinceName'
                    //     }
                    // },
                    // {
                    //     dataField: 'cityId',
                    //     label: {
                    //         text: 'Kota'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: cities(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'cityName'
                    //     }
                    // },
                    // {
                    //     dataField: 'kjppId',
                    //     label: {
                    //         text: 'KJPP'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: kjpp(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: 'kjppName'
                    //     }
                    // },
                    // {
                    //     dataField: 'rekBayarId',
                    //     label: {
                    //         text: 'Rekening Bayar'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: rekeningBank(this.props.store),
                    //         valueExpr: 'id',
                    //         displayExpr: (e) => {
                    //             return e && e.rekNo + ' - ' + e.rekName
                    //         }
                    //     }
                    // },
                    // {
                    //     dataField: 'pemilikId',
                    //     label: {
                    //         text: 'Pemilik'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: [],
                    //         valueExpr: 'id',
                    //         displayExpr: 'value'
                    //     }
                    // },
                    // {
                    //     dataField: 'sertifikatTypeId',
                    //     label: {
                    //         text: 'Jenis Sertifikat'
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         dataSource: [],
                    //         valueExpr: 'id',
                    //         displayExpr: 'value'
                    //     }
                    // },
                    // {
                    //     dataField: 'hargaPasar',
                    //     label: {
                    //         text: 'Harga Pasar'
                    //     },
                    // },
                    // {
                    //     dataField: 'capRate',
                    //     label: {
                    //         text: 'Cap Rate'
                    //     },
                    // },
                    // {
                    //     dataField: 'taksiranHargaSewa',
                    //     label: {
                    //         text: 'Taksiran Harga Sewa'
                    //     },
                    // },
                    // {
                    //     dataField: 'taksiranClosingCost',
                    //     label: {
                    //         text: 'Taksiran Closing Cost'
                    //     },
                    // },
                    // {
                    //     dataField: 'taksiranMaintenanceCost',
                    //     label: {
                    //         text: 'Taksiran Maintenance Cost'
                    //     },
                    // },
                    {
                        dataField: 'luasTanah',
                        label: {
                            text: 'Luas Tanah'
                        },
                    },
                    {
                        dataField: 'luasBangunan',
                        label: {
                            text: 'Luas Bangunan'
                        },
                    },
                ]
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = (data) => {
        if(data){
            this.insertLookup(data.productId)
    
            this.setState({
                data: {
                    id: data.id,
                    noPengajuan: data.noPengajuan,
                    agenPenjualId: data.agenPenjualId,
                    currencyId: data.currencyId,
                    efekBalanceAvgId: data.efekBalanceAvgId,
                    efekBalanceBuyDateId: data.efekBalanceBuyDateId,
                    fee: data.fee,
                    fundManagerId: data.fundManagerId,
                    hargaMaksimum: data.hargaMaksimum,
                    hargaMinimum: data.hargaMinimum,
                    instrumentId: data.instrumentId,
                    jenisHarga: data.jenisHarga,
                    keterangan: data.keterangan,
                    kurs: data.kurs,
                    nominalFee: data.nominalFee,
                    pengembangEntitasId: data.pengembangEntitasId,
                    penjualEntitasId: data.agenPenjualId,
                    qtyOrder: data.qtyOrder,
                    rekBankId: data.rekBankId,
                },
                dataDetail: {
                },
            });

        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    insertLookup = async (productId) => {
        var getPenjual = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllByType/AP`, 'GET')
        var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments?categorySubId.in=34&categorySubId.in=35&categorySubId.in=36&categorySubId.in=37&categorySubId.in=38&categorySubId.in=39`, 'GET')
        var getEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs?categoryId.in=8&kpdId.in=1&productId.in=${productId}`, 'GET')
        var getSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-category-subs?instrumentCategoryId.in=8`, 'GET')
        var result = []

        // var mergedList = _.map(getEfekBalance, function(item){
        //     return _.extend(item, _.findWhere(getInstrument, { id: item.instrumentId }));
        // })
        for (var detail of getEfekBalance) {
            var instruments = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${detail.instrumentId}`, 'GET')

            var data = {
                ...detail,
                instrumentCode: instruments.instrumentCode,
                instrumentName: instruments.instrumentName,
            }
            result.push(data)
        }
        let dataPenjual = new ArrayStore({
            data: getPenjual,
            key: 'id'
        });
        let dataInstrument = new ArrayStore({
            data: getInstrument,
            key: 'id'
        });
        let dataEfek = new ArrayStore({
            data: result,
            key: 'id'
        })
        let dataSub = new ArrayStore({
            data: getSubCategory,
            key: 'id'
        });

        this.formMasterRef.current.instance.getEditor('agenPenjualId').option('dataSource', dataPenjual)
        this.formMasterRef.current.instance.getEditor('efekBalanceAvgId').option('dataSource', dataEfek)
        this.formDetailRef.current.instance.getEditor('categorySubId').option('dataSource', dataSub)
    }

    submitData = () => {
        var data = this.state.data
        this.props.submitDetailPenempatanData({
            id: data.id,
            agenPenjualId: data.agenPenjualId,
            currencyId: data.currencyId,
            efekBalanceAvgId: data.efekBalanceAvgId,
            efekBalanceBuyDateId: data.efekBalanceBuyDateId,
            fee: data.fee,
            fundManagerId: data.fundManagerId,
            hargaMaksimum: data.hargaMaksimum,
            hargaMinimum: data.hargaMinimum,
            instrumentId: data.instrumentId,
            jenisHarga: data.jenisHarga,
            keterangan: data.keterangan,
            kurs: data.kurs,
            nominalFee: data.nominalFee,
            pengembangEntitasId: data.pengembangEntitasId,
            penjualEntitasId: data.agenPenjualId,
            qtyOrder: data.qtyOrder,
            rekBankId: data.rekBankId,
        })

        this.hide()
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Pelepasan Investasi Properti'}
                minWidth={'70vw'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.data}
                                        items={this.formItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={this.props.type == 'view' ? true : false}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster1'}
                                        ref={this.formDetailRef}
                                        formData={this.state.dataDetail}
                                        items={this.formDetailItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormSubInstrumentPelepasanProperti