import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { ScrollView } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog';

class PembayaranMpSekaligusKonfirmasi extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.splitType = 0

        this.formItem = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'Reference ID'
                },
                editorOptions : {
                    readOnly : true
                }
            },
            {
                dataField: 'description',
                label: {
                    alignment: 'left',
                    text: 'Keterangan',
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    height: 200,
                },
            },
            {
                itemType: 'empty'
            },
            {
                dataField: 'tglBayar',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Pembayaran Ke-2'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                },
                visible: false
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Submit',
                    onClick: async() => {
                        var result = await confirm('Apakah Anda Yakin?', 'Konfirmasi')
                        if(result){
                            await this.submitData()
                        }
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show(pembayaranMpId) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster : {
                pembayaranMpId : pembayaranMpId
            }
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            pembayaranMpId : 0,
            dataMaster : {}
        });
        
        this.splitType = 0
    }

    submitData = async() => {
        var formData = this.state.dataMaster
        var pembayaranMpId = this.state.dataMaster.id
        var splitType = this.splitType
        var param = {
            catatan : formData.description,
            splitType : this.splitType,
            tglBayar : formData.tglBayar
        }
        await this.props.kirim(pembayaranMpId,param)

        this.hide()
    }

    retrieveData = async(pembayaranMpId) => {
        try {
            var loadAPI = `pembayaran-mp-detils/getByMpMasterId/${pembayaranMpId}`
            var getPembayaranMpDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
    
            var loadAPI = `kepersertaan-products/${getPembayaranMpDetail[0].akunProdukId}`
            var getKepersertaanProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

            var data = {
                id : pembayaranMpId
            }
            if(getKepersertaanProduct.mpBulanan <= 1600000){
                this.splitType = 1
                this.formRef.current.instance.itemOption('tglBayar','visible',false)
            }else if (getKepersertaanProduct.mpBulanan > 1600000 && getKepersertaanProduct.mpBulanan <= 2000000){
                this.splitType = 2
                this.formRef.current.instance.itemOption('tglBayar','visible',true)
            }else{
                this.splitType = 2
                this.formRef.current.instance.itemOption('tglBayar','visible',false)
                // var alerts = await alert('Nominal MP Bulanan melebihi Rp.2.000.000!', 'Informasi')
                // if(alerts) {
                //     this.hide()
                // }
            }
            this.formRef.current.instance.updateData(data)
        } catch (e) {
            console.log(e)
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Pembayaran'} 
                minWidth={500}
                minHeight={400}
                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref = {this.formRef}
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default PembayaranMpSekaligusKonfirmasi