import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { participants, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalKonfirmasiPiutangIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/piutangIuran/konfirmasi';
import ModalDetailPiutangIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/piutangIuran/detail';
import { confirmAlert } from 'react-confirm-alert';
import { alert } from 'devextreme/ui/dialog';

class PiutangIuranPersetujuan extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            getUrl: `iurans/getByStatusPiutangId/3`
        }

        this.dataGridRef = React.createRef()
        this.modalKonfirmasiRef = React.createRef()
        this.modalDetailRef = React.createRef()

        this.iuranType = []

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text : 'Konfirmasi',
                        hint : 'Konfirmasi',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalKonfirmasi(e.row.data)
                        }
                    },
                    {
                        text : 'Batal',
                        hint : 'Batal',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            confirmAlert({
                                title: 'Apakah yakin?',
                                message: 'Apakah anda yakin ingin membatalkan data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.updateIuran(e.row.data,2)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender : (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'participantId',
                caption: 'Pemberi Kerja',
                lookup: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName'
                }
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Periode Tahun-Bulan',
                cellRender : (e) => {
                    var data = e.row.data
                    var bulan
                    switch(data.bulan) {
                        case 1:
                            bulan = 'Januari'
                        break
                        case 2:
                            bulan = 'Februari'
                        break
                        case 3:
                            bulan = 'Maret'
                        break
                        case 4:
                            bulan = 'April'
                        break
                        case 5:
                            bulan = 'Mei'
                        break
                        case 6:
                            bulan = 'Juni'
                        break
                        case 7:
                            bulan = 'Juli'
                        break
                        case 8:
                            bulan = 'Agustus'
                        break
                        case 9:
                            bulan = 'September'
                        break
                        case 10:
                            bulan = 'Oktober'
                        break
                        case 11:
                            bulan = 'November'
                        break
                        case 12:
                            bulan = 'Desember'
                        break
                    }
                    return bulan + ' - ' + data.tahun
                }
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'dueDate',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'jumlahAkun',
                caption: 'Jumlah Akun',
                format: '#,##0'
            },
            {
                dataField: 'nominalIuran',
                caption: 'Jumlah Nominal Iuran',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'receiveAmount',
                caption: 'Jumlah Diterima',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'receiveOutstanding',
                caption: 'Outstanding',
                format: '#,##0.00',
                alignment: 'right'
            }
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        await this.retrieveIuranType()
    } 


    retrieveIuranType = async() => {
        var loadAPIuranTypes = `iuran-types`
        var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

        this.iuranType = dataIuranTypes
    }

    showModalDetail = (data) => {
        this.modalDetailRef.current.show()
        this.modalDetailRef.current.retrieveData(data)
    }
    showModalKonfirmasi = (data) => {
        this.modalKonfirmasiRef.current.show()
        this.modalKonfirmasiRef.current.retrieveData(data)
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    updateIuran = async(data,statusPiutangId) => {
        try {
            var loadAPI = 'iurans'
            var getIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPI}/${data.id}`, 'GET')
            var updateData = getIuran
            getIuran.statusPiutangId = statusPiutangId
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: updateData.id,
                values: updateData
            },getIuran)
            
            var message = `Data berhasil di batalkan!`
            var title = 'Berhasil'
        } catch (e) {
            var message = e
            var title = 'Gagal'
        }
        await alert(message, title)
        this.forceRefresh()
    }
    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'plus',
    //                 text: 'Konfirmasi',
    //                 onClick: (e) => {
    //                     this.showModalKonfirmasi()
    //                 }
    //             }
    //         }
    //     )
    // }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Piutang Iuran Persetujuan Pengurus</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={`iurans/getByStatusPiutangId/3`}
                    insertAPI='iurans'
                    updateAPI='iurans'
                    deleteAPI='iurans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {false}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Piutang Iuran"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Piutang Iuran'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalKonfirmasiPiutangIuran 
                    ref={this.modalKonfirmasiRef}
                    store={this.props.store}
                    step={'persetujuan-pengurus'}
                    forceRefresh = {this.forceRefresh}
                />
                <ModalDetailPiutangIuran 
                    ref={this.modalDetailRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default PiutangIuranPersetujuan