import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { productDefault } from 'dataSource/lookup'
import { Form } from "devextreme-react";
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class AkunBalanceSaldoOnly extends Component {
    constructor(props) {
        super(props)
        
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                productId:1,
                startDate:new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.summary = [
            {  
                displayFormat: 'Total :',
                showInColumn : 'akunName'
            },
            {
                name : 'debit',
                column: 'debit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'credit',
                column: 'credit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'beginingBalanceDebit',
                column: 'beginingBalanceDebit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'beginingBalanceCredit',
                column: 'beginingBalanceCredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'endingBalanceDebet',
                column: 'endingBalanceDebet',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'endingBalanceCredit',
                column: 'endingBalanceCredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            }
        ]

        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            openOnFieldClick: true,
                            searchEnabled: true,
                            deferRendering: false
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'startDate',
                        label: {
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'endDate',
                        label: {
                            text: 's/d' 
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                          text: "Filter",
                          // type:"default",
                          elementAttr: { class: "bg-dapen-default" },
                          onClick: () => {
                            this.filterSubmit()
                          },
                        },
                        horizontalAlignment: "left"
                      },
                ]
            }
        ]

        this.columns = [
            {
                dataField: 'akunCode',
                caption: 'Kode Akun',
                cellRender:(e) => {
                    if (e.row) {
                        var paddingSize = 20 * e.row.data.reportLevel
                        return <span style={{paddingLeft: `${paddingSize}px`}}>{e.text}</span>
                    }
                    console.log(e);
                }
            },
            {
                dataField: 'akunName',
                caption: 'Nama Akun',
            },
            // {
            //     dataField: 'position',
            //     caption: 'Posisi',
            //     // lookup: {
            //     //     dataSource: kpds(this.props.store),
            //     //     valueExpr: 'id',
            //     //     displayExpr: 'noKontrak'
            //     // }
            // },
            {
                caption: 'Saldo Awal',
                name:'saldoAwal',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'beginingBalanceDebit',
                        caption: 'Debet',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment:'right'
                    },
                    {
                        dataField: 'beginingBalanceCredit',
                        caption: 'Kredit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment:'right'
                    },
                ]
            },
            {
                caption: 'Mutasi',
                name:'mutasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'debit',
                        caption: 'Debet',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment:'right'
                    },
                    {
                        dataField: 'credit',
                        caption: 'Kredit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment:'right'
                    },
                ]
            },
            {
                caption: 'Saldo Akhir',
                name:'saldoAkhir',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'endingBalanceDebet',
                        caption: 'Debet',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment:'right'
                    },
                    {
                        dataField: 'endingBalanceCredit',
                        caption: 'Kredit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment:'right'
                    },
                ]
            },
            // {
            //     dataField: 'reportLevel',
            //     caption: 'Level Report',
            // },
            // {
            //     dataField: 'reportPath',
            //     caption: 'Path Report',
            // },
        ]

        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    filterSubmit = () => {
        this.setState({
            filterData: this.state.filterData
        })

        this.dataGridRef.current.forceRefresh(true)
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Akun Balance</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation="left"
                        />
                    </div>
                    <div className="mt-2 col-md-12">
                        <DevExpressDataGrid
                        ref={this.dataGridRef}
                            loadAPI={`get-investasi-akun-balance-mutation-report?endDate=${formatDate(this.state.filterData.endDate)}&productId=${this.state.filterData.productId}&startDate=${formatDate(this.state.filterData.startDate)}`}
                            insertAPI='-'
                            updateAPI='-'
                            deleteAPI='-'

                            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                            // useArraySoure={true}
                            // ArraySourceData={this.loadData}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            exportExcel={true}
                            exportFileName={"Akun Balances"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Balance Data'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={200} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            summaryTotalItem={this.summary}
                            height={'calc(100vh - 350px)'}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default AkunBalanceSaldoOnly