/*
 * action types
 */

export const GET_PARAM = 'GET_PARAM'
/*
 * action creators
 */

export function getParam(value) {
  return { type: GET_PARAM, value }
}