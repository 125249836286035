import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form, Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, banks, rekeningBroker, stock, instrumentTransactionTypes, productDefault, pejabat, jabatan } from "dataSource/lookup";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import ScrollView from "devextreme-react/scroll-view";
import { formatUploadFileData, imageSource } from "plugin/helper";
import ModalPreviewPdf from "components/pages/modal/kepesertaan/compPeserta/previewPdf";

class FormInstruksiBrokerAktif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      DataMaster: {},
      // InfoBroker: {}
    };

    this.dataGridDetailInstruksiRef = React.createRef()

    this.dataMaster = [
      {
        itemType: 'group',
        items: [
          {
            dataField: 'orderInstruksiId',
            label: {
              text: 'ID'
            },
            editorOptions: {
              readOnly: true
            }
          },
          {
            dataField: 'brokerName',
            label: {
              text: 'Broker'
            },
            editorOptions: {
              readOnly: true
            }
          },
          {
            dataField: 'productId',
            label: {
              text: 'SID'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: productDefault(this.props.store),
              valueExpr: 'id',
              displayExpr: 'sid',
              readOnly: true
            }
          },
          {
            dataField: 'sourceRekBrokerId',
            label: {
              text: 'Nomor Rekening'
            },
            editorOptions: {
              readOnly: true
            }
          },
          {
            dataField: 'instruksiNo',
            label: {
              text: 'Nomor Instruksi'
            },
            editorOptions: {
              readOnly: true
            }
          },
          {
            dataField: 'instruksiDate',
            label: {
              text: 'Tanggal Instruksi'
            },
            editorType: 'dxDateBox',
            editorOptions: {
              displayFormat: 'dd MMM yyyy',
              useMaskBehavior: true,
            }
          },
          // {
          //   dataField: 'tanggalOrder',
          //   label: {
          //     text: 'Tanggal Order'
          //   },
          //   editorType: 'dxDateBox',
          //   editorOptions: {
          //     displayFormat: 'dd MMM yyyy',
          //     useMaskBehavior: true,
          //     readOnly: true
          //   }
          // },

          {
            dataField: 'pejabat1',
            label: {
              text: 'Pejabat 1'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: pejabat(this.props.store),
              valueExpr: 'id',
              displayExpr: 'fullName',
              searchEnabled: true,
              onValueChanged: async (e) => {
                var val = e.value
                if (val) {
                  let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${val}`, 'GET')
                  this.formRef.current.instance.getEditor('jabatan1').option('value', response.jabatanId)
                }
              }
            },
            validationRules: [
              {
                type: 'required',
                message: 'Pejabat 1 tidak boleh kosong!'
              }
            ]
          },
          {
            dataField: 'jabatan1',
            label: {
              text: 'Jabatan 1'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: jabatan(this.props.store),
              valueExpr: 'id',
              displayExpr: 'jabatanName',
              readOnly: true,
            },
            validationRules: [
              {
                type: 'required',
                message: 'Jabatan 1 tidak boleh kosong!'
              }
            ]
          },
          {
            dataField: 'pejabat2',
            label: {
              text: 'Pejabat 2'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: pejabat(this.props.store),
              valueExpr: 'id',
              displayExpr: 'fullName',
              searchEnabled: true,
              onValueChanged: async (e) => {
                var val = e.value
                if (val) {
                  let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/${val}`, 'GET')
                  this.formRef.current.instance.getEditor('jabatan2').option('value', response.jabatanId)
                }
              }
            },
            validationRules: [
              {
                type: 'required',
                message: 'Pejabat 2 tidak boleh kosong!'
              }
            ]
          },
          {
            dataField: 'jabatan2',
            label: {
              text: 'Jabatan 2'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: jabatan(this.props.store),
              valueExpr: 'id',
              displayExpr: 'jabatanName',
              readOnly: true,
            },
            validationRules: [
              {
                type: 'required',
                message: 'Jabatan 2 tidak boleh kosong!'
              }
            ]
          },
          {
            dataField: 'note',
            label: {
              text: 'Catatan Instruksi'
            },
            editorType: 'dxTextArea',
          },
        ]
      },
      {
        itemType: 'group',
        caption: 'INFORMASI BROKER',
        items: [
          {
            dataField: 'instruksiKepada',
            label: {
              text: 'Kepada'
            }
          },
          {
            dataField: 'instruksiAlamat',
            label: {
              text: 'Alamat'
            }
          },
          // {
          //   dataField: 'instruksiUp',
          //   label: {
          //     text: 'UP Instruksi'
          //   }
          // },
          // {
          //   dataField: 'instruksiEmailTo',
          //   label: {
          //     text: 'Email'
          //   }
          // },
          {
            dataField: 'noTelp',
            label: {
              text: 'No Telp'
            }
          },
          {
            dataField: 'instruksiFax',
            label: {
              text: 'No Fax'
            }
          },
        ]
      },
    ]
    this.formFile = [
      {
        itemType: 'group',
        items: [
          {
            dataField: "file",
            label: {
              text: "File Instruksi",
              alignment: 'left'
            },
            editorType: "dxFileUploader",
            editorOptions: {
              selectButtonText: "Select File",
              labelText: "Upload File",
              uploadMode: "useForm",
              accept: 'image/png, image/jpeg, application/pdf',
              onValueChanged: this.onUploadStarted.bind(this),
            },
          },
        ]
      }
    ]
    this.orderColumns = [
      {
        dataField: 'orderId',
        caption: 'Order ID'
      },
      {
        dataField: 'orderCode',
        caption: 'No. Order'
      },
      {
        dataField: 'orderTypeId',
        caption: 'Order',
        lookup: {
          dataSource: instrumentTransactionTypes(this.props.store),
          valueExpr: 'id',
          displayExpr: 'transactionName'
        }
      },
      {
        dataField: 'instrumentId',
        caption: 'Saham',
        lookup: {
          dataSource: stock(this.props.store),
          valueExpr: 'id',
          displayExpr: (e) => {
            return e && e.instrumentCode + ' - ' + e.instrumentName
          }
        }
      },
      {
        dataField: 'quantityOrder',
        caption: 'Kuantitas (Lembar)',
        alignment: 'right',
        dataType: 'number',
        format: '#,##0.00'
      },
      {
        dataField: 'priceType',
        caption: 'Harga',
      },
      {
        dataField: 'priceMin',
        caption: 'Harga Min',
        alignment: 'right',
        dataType: 'number',
        format: '#,##0.00'
      },
      {
        dataField: 'priceMax',
        caption: 'Harga Max',
        alignment: 'right',
        dataType: 'number',
        format: '#,##0.00'
      },
    ]

    this.popupToolbarItem = [
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          text: 'Simpan Perubahan',
          onClick: () => {
            var formValidation = this.validate()
            if (formValidation) {
              this.updateData()
            }
          }
        },
        toolbar: 'bottom'
      },
      // {
      //   widget: 'dxButton',
      //   location: 'after',
      //   options: {
      //     text: 'Cetak Instruksi',
      //     onClick: () => {
      //       this.generateInstruksi()
      //     }
      //   },
      //   toolbar: 'bottom'
      // },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          text: 'Kirim',
          onClick: () => {
            console.log('Sending..')
          }
        },
        toolbar: 'bottom'
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          text: 'Tutup',
          onClick: this.hide.bind(this)
        },
        toolbar: 'bottom'
      }
    ]
    this.formRef = React.createRef()
    this.formFileRef = React.createRef()
    this.modalPreviewPdfRef = React.createRef()
  }
  validate = () => {
    var formValidation = this.formRef.current.instance.validate();
    return formValidation.isValid
  }
  downloadFile = async () => {
    var instruksiId = this.state.DataMaster.orderInstruksiId
    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksis/doc-instruksi/${instruksiId}`, 'GET')
    return response
  }
  onUploadStarted = (e) => {
    var fieldName = e.component._options._optionManager._options.name
    for (var i = 0; i < e.value.length; i++) {
      var files = e.value[i]

      var fr = new FileReader()

      var formattedFileData

      fr.onload = (efr) => {
        formattedFileData = formatUploadFileData(efr.target.result)
        this.setState({
          [`${fieldName}Instruksi`]: formattedFileData.base64data,
          [`${fieldName}InstruksiContentType`]: formattedFileData.mime,
          [`fileName`]: files.name,
        })
      }

      fr.readAsDataURL(files)
    }
  }
  generateInstruksi = () => {
    var data = this.state.DataMaster.orderInstruksiId
    var response = httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-stoks/generate-pdf-instruksi-stok/${data}`, 'GET')
    return response
  }
  show = async (data) => {
    var dataOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `orders/${data.dataStok[0].orderId}`, 'GET')

    if (data.brokerId) {
      var dataBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `brokers/${data.brokerId}`, 'GET')
    }
    console.log(dataBroker)
    this.setState({
      popupVisible: true,
      DataMaster: {
        instruksiNo: data.instruksiNo || '<AUTO>',
        pejabat1: parseInt(data.pejabat1),
        pejabat2: parseInt(data.pejabat2),
        orderInstruksiId: data.instruksiId,
        transactionName: data.transactionName,
        instruksiKepada: data.instruksiKepada || dataBroker.brokerName,
        instruksiAlamat: data.instruksiAlamat || dataBroker.address,
        instruksiUp: data.instruksiUp || dataBroker.upInstruksi,
        noTelp: data.noTelp || dataBroker.noTelp,
        instruksiEmailTo: data.instruksiEmailTo || dataBroker.dealer1Email,
        instruksiFax: data.instruksiFax || dataBroker.noFax,
        instruksiDate: data.instruksiDate,
        tanggalOrder: dataOrder.orderDate,
        brokerName: data.brokerName,
        productId: data.productId,
        sourceRekBrokerId: data.rekNo || data.rekName == null ? ' ' : data.rekNo + ' - ' + data.rekName
      },
      fileInstruksi: data.fileInstruksi,
      fileInstruksiContentType: data.fileInstruksiContentType,
      dataStok: {
        stok: data.dataStok
      },
    });
    this.dataGridDetailInstruksiRef.current.forceRefresh(true)
  };

  hide = () => {
    this.setState({
      popupVisible: false,
      DataMaster: {},
      dataStok: {},
    });
  };
  updateData = async () => {
    var dataMaster = this.state.DataMaster
    var submitData = {
      ...dataMaster,
      fileInstruksi: this.state.fileInstruksi,
      fileInstruksiContentType: this.state.fileInstruksiContentType,
      fileName: this.state.fileName,
    }
    if (submitData) {
      try {
        var insertAPI = 'order-stoks/update-instruksi-stok'
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
          values: submitData
        })
        notify({ message: 'Perubahan berhasil disimpan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        this.hide()
        this.props.forceRefresh()
        return response
      } catch (e) {
        console.log(e)
        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
      }
    }
  }
  loadData = () => {
    return this.state.dataStok.stok
  }
  previewFile = async () => {
    var src = imageSource(this.state.fileInstruksiContentType, this.state.fileInstruksi)
    this.modalPreviewPdfRef.current.show()
    this.modalPreviewPdfRef.current.retrieveData(src)
  }
  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={false}
          showTitle={true}
          title={"Form Instruksi Broker"}
          width={'90vw'}
          height={'90vh'}
          toolbarItems={this.popupToolbarItem}
        >
          <ScrollView>
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-md-12">
                  <Form
                    colCount={2}
                    id={"formMaster"}
                    formData={this.state.DataMaster}
                    items={this.dataMaster}
                    ref={this.formRef}
                    labelLocation="left"
                  />
                </div>
                <div className="col-md-12">
                  <Form
                    colCount={1}
                    id={"formFile"}
                    items={this.formFile}
                    ref={this.formFileRef}
                    labelLocation="left"
                  />
                  <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileInstruksi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                    Buka File
                  </button>
                </div>

                <div className="row" style={{ marginTop: '50px' }}>
                  <div className="col-md-12">
                    <h6 className='text-center'>Detail Instruksi</h6>
                    <DevExpressDataGrid
                      ref={this.dataGridDetailInstruksiRef}
                      loadAPI='detail-broker'
                      insertAPI='detail-broker'
                      updateAPI='detail-broker'
                      deleteAPI='detail-broker'

                      backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                      useArraySource={true}
                      ArraySourceData={this.loadData}

                      allowAdding={false}
                      allowDeleting={false}
                      allowUpdating={false}

                      exportExcel={false}
                      exportFileName={"Detail Instruksi"}
                      allowExportSelectedData={true}
                      selection={"multiple"}

                      showBorders={true}

                      paging={true}
                      defaultPageSize={10}

                      //bagian konfigurasi popup saat insert dan edit record
                      popupTitle={'Detail Instruksi Data'}
                      popupWidth={500} //masukan dalam ukuran pixel
                      popupHeight={300} //masukkan dalam ukuran pixel

                      height={225}

                      popupFormLabelLocation='left' //accepted value = top, left, right
                      popupFormMinColWidth={300} // minimum lebar kolom
                      popupFormColCount={1} //jumlah kolom pada form

                      //akhir bagian konfigurasi popup

                      ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                      ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                      FilterRow={true} // set false untuk mematikan fitur filter

                      ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                      // SummaryConfiguration={this.summary}
                      //contoh konfigurasi kolom
                      //this.columns = [{
                      //    dataField: 'kolom1',
                      //    caption: 'Ini Kolom 1'
                      //}, {
                      //    dataField: 'kolom2',
                      //    caption: 'Ini Kolom 2'
                      //}]
                      // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                      store={this.props.store} // jangan di edit edit
                    />
                  </div>
                </div>

                {/* {
                this.state.type == 'Penempatan' ?
                  <DataGridDetailInstruksiPenempatan tipe={this.props.tipe} /> : this.state.type == 'Pelepasan' ?
                    <DataGridDetailInstruksiPelepasan tipe={this.props.tipe} /> : this.state.type == 'Perpanjangan' ?
                      <DataGridDetailInstruksiPerpanjangan tipe={this.props.tipe} /> : null
              } */}
              </div>
            </div>
            <ModalPreviewPdf
              ref={this.modalPreviewPdfRef}
              store={this.props.store}
            />
          </ScrollView>
        </Popup>
      </React.Fragment>
    );
  }
}

export default FormInstruksiBrokerAktif