import httpRequest from "./httprequest";
import CustomStore from 'devextreme/data/custom_store';
import axios from "axios";
import fire from './fireConf'
import DataSource from "devextreme/data/data_source";

export function imageSource(contentType, base64) {
    if (contentType && base64) {
        var result = `data:${contentType};base64,${base64}`
        return result
    }

    return null
}

export const base64toFile = async (data, contentType, fileName, url) => {
    const res = await fetch(url || imageSource(contentType, data));
    const blob = await res.blob();
    return new File([blob], fileName, { type: res.headers.get('content-type') || contentType });
}

export const fileToBlob = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function(event) {
            let blob = new Blob([event.target.result], { type: file.type });
            resolve(blob);
        };
        reader.onerror = function(event) {
            reject(event.target.error);
        };
        reader.readAsArrayBuffer(file);
    });
}

export function formatUploadFileData(fileData) {
    var mime = fileData.split(':')[1]
    var mimeClean = mime.split(';')[0]
    var base64data = fileData.split(',')[1]

    var result = {
        mime: mimeClean,
        base64data: base64data
    }

    return result
}

export function formatUploadFileNameAndExtention(fileData) {
    var name = fileData.split('.')[0]
    var extention = fileData.split('.')[1]

    var result = {
        name: name,
        extention: extention
    }

    return result
}

export function blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve, reject) => {
        return reader.onloadend = () => {
            return resolve(reader.result)
        };
    })
}

export function formatTime(date) {
    var d = typeof date === 'string' ? new Date(date) : date,
        hours = '' + d.getHours(),
        minutes = '' + d.getMinutes();
    if (minutes == 0) {
        minutes = minutes + "0"
    }
    var strTime = hours + ':' + minutes;
    return strTime;
}

export function formatNumber(num) {
    if(num === null) {
        return 0
    } else {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }
}

export function formatNumberB(num) {
    var numString = num.toString();

    var positionComa = numString.indexOf('.');

    if (positionComa === -1) {
        return num;
    }

    return parseFloat(numString.slice(0, positionComa));
}

export function formatNumberAum(num) {
    return num.toLocaleString(undefined, { minimumFractionDigits: 2 })
}

export function formatNumberNav(num) {
    return num.toLocaleString(undefined, { minimumFractionDigits: 4 })
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatAsMoney(n) {
    n = (Number(n).toFixed(2) + '').split('.');
    return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + (n[1] || '00');
}

export function compactNumber(number, min = 0) {
    var result = number ? number : 0
    var isMinus = false
    if (number < 0) {
        isMinus = true
        number = Math.abs(number)
    }
    if (number > 999999999999 && 999999999999 > min) {
        result = `${formatNumber(Math.round((number / 1000000000000) * 100) / 100, 1)} Triliun`
    } else if (number > 999999999 && 999999999 > min) {
        result = `${formatNumber(Math.round((number / 1000000000) * 100) / 100, 1)} Miliar`
    } else if (number > 999999 && 999999 > min) {
        result = `${formatNumber(Math.round((number / 1000000) * 100) / 100, 1)} Juta`
    } else if (number > 999 && 999 > min) {
        result = `${formatNumber(Math.round((number / 1000) * 100) / 100, 1)} Ribu`
    }

    if (/^[0-9]*$/g.test(result)) {
        result = formatNumber(result)
    }

    if (isMinus && number > 999) {
        result = "-" + result
    }

    return result
}

export function formatNpwp(value) {
    if (typeof value === 'string') {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/, '$1.$2.$3.$4-$5.$6');
    }
}

export function search(value, propName, arrayobject) {
    for (var i = 0; i < arrayobject.length; i++) {
        if (arrayobject[i][propName]) {
            if (arrayobject[i][propName].trim() === value.trim()) {
                return arrayobject[i];
            }
        }
    }
}

export function searchIndex(value, propName, arrayobject) {
    for (var i = 0; i < arrayobject.length; i++) {
        if (arrayobject[i][propName]) {
            if (arrayobject[i][propName].trim() === value.trim()) {
                return i;
            }
        }
    }
}

export function formatDate(date) {

    var d = typeof date === 'string' ? new Date(date) : date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');

}
export function ddmmyyyyToDate(date) {

    var start = new Date('1899-12-30')
    console.log(start)
    start.setDate(start.getDate() + date)
    start.toLocaleDateString()
    return start
    // month = d[1]
}
export function formatDateDmy(date) {

    var d = typeof date === 'string' ? new Date(date) : date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('-');

}

export function formatDefaultDate(date) {
    if(date === null) {
        return null
    } else {
        const months = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"];
        var d = typeof date === 'string' ? new Date(date) : date,
            // month = '' + (d.getMonth() + 1),
            month = months[d.getMonth()],
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    
        return [day, month, year].join(' ');
    }
}
export function formatDefaultFullDate(date) {
    const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    var d = typeof date === 'string' ? new Date(date) : date,
        // month = '' + (d.getMonth() + 1),
        month = months[d.getMonth()],
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join(' ');
}

export function formatDefaultMonthYear(date) {
    const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"];
    var d = typeof date === 'string' ? new Date(date) : date,
        // month = '' + (d.getMonth() + 1),
        month = months[d.getMonth()],
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    return [month, year].join(' ');
}

export function getDays(date) {
    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
    var d = typeof date === 'string' ? new Date(date) : date,

        day = days[d.getDay()]

    return day
}

export function formatDateTimeNumber(date) {
    if (date) {
        if (typeof date === 'string') {
            date = new Date(date)
        }

        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
        var hour = date.getHours();
        var minute = date.getMinutes()
        var second = date.getSeconds()

        return year + '-' + ("0" + (monthIndex + 1)).slice(-2) + '-' + ("0" + day).slice(-2) + ' ' + ("0" + hour).slice(-2) + ':' + ("0" + minute).slice(-2) + ':' + ("0" + second).slice(-2);
    }

    return null
}

let dataRequested = []

export function templateCustomStoreLookup(loadAPI, store, backendurl, sortData, haveFilterById = true, customAPI, filterKey = 'id') {
    var customLoadApi = loadAPI
    var customStore = new CustomStore({
        key: filterKey,
        cacheRawData: true,
        loadMode: 'raw',
        load: async (loadOptions) => {
            if (customLoadApi.slice(-1) === '/') {
                customLoadApi = customLoadApi.slice(0, -1)
            }
            if (customLoadApi === 'users') {
                customLoadApi = 'users?size=999'
            }
            if (customLoadApi === 'periode-tunjangans') {
                customLoadApi = 'periode-tunjangans?statusAvailable.in=true'
            }
            if (customLoadApi === 'tunjangan-types') {
                customLoadApi = 'tunjangan-types?size=999'
            }
            // if (customLoadApi === 'instrument-category-subs') {
            //     customLoadApi = 'instrument-category-subs/etf'
            // }
            if (customLoadApi === 'instruments') {
                customLoadApi = 'instruments?size=999'
            }
            if (customLoadApi === 'instruments/obligasi') {
                customLoadApi = 'instruments/obligasi?size=999'
            }

            // if (customLoadApi === 'instrument-category-subs') {
            //     customLoadApi = 'instrument-category-subs/obligasi'
            // }

            var loadedData = dataRequested.find(value => value.url === customLoadApi)

            if (!loadedData) {
                if (customAPI) {
                    loadedData = await customAPI()
                } else {
                    loadedData = await httpRequest(backendurl, store, customLoadApi)
                }


                dataRequested.push({
                    url: customLoadApi,
                    data: loadedData
                })
            } else {
                loadedData = loadedData.data
            }

            if (sortData) {
                // sort = Object.entries(sort)
                loadedData = loadedData.sort((a, b) => {
                    var aValue, bValue
                    if (typeof a[sortData] === 'string') {
                        aValue = a[sortData].toUpperCase(); // ignore upper and lowercase
                        bValue = b[sortData].toUpperCase(); // ignore upper and lowercase
                    }

                    if (aValue < bValue) {
                        return -1;
                    }
                    if (aValue > bValue) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                })
            }

            if (loadOptions.searchValue !== null) {
                if (typeof loadOptions.searchExpr === 'function') {
                    var filteredData = loadedData.filter(value => new RegExp(loadOptions.searchValue, 'gi').test(loadOptions.searchExpr(value)))
                } else {
                    var filteredData = loadedData.filter(value => new RegExp(loadOptions.searchValue, 'gi').test(value[loadOptions.searchExpr]))
                }

                return filteredData
            } else {
                return loadedData
            }
        },
        byKey: async (key) => {
            if (customLoadApi === 'periode-tunjangans?statusAvailable.in=true') {
                customLoadApi = 'periode-tunjangans'
            }
            if (customLoadApi === 'tunjangan-types?size=999') {
                customLoadApi = 'tunjangan-types'
            }
            if (customLoadApi === 'instruments?size=999') {
                customLoadApi = 'instruments'
            }
            if (customLoadApi === 'users?size=999') {
                customLoadApi = 'users'
            }

            if (customLoadApi === 'instruments/obligasi?size=999') {
                customLoadApi = 'instruments/obligasi'
            }
            if (customLoadApi === 'instrument-category-subs/etf') {
                customLoadApi = 'instrument-category-subs'
            }
            if (customLoadApi === 'instrument-category-subs/obligasi') {
                customLoadApi = 'instrument-category-subs'
            }

            if (customLoadApi === 'akuns/filterAkunsGetAll') {
                customLoadApi = 'akuns/filterAkunsGetAll?id.equals='
            }

            if (customLoadApi.slice(-1) !== '/') {
                customLoadApi = `${customLoadApi}/`
            }

            if (customLoadApi === 'akuns/filterAkunsGetAll?id.equals=/') {
                customLoadApi = customLoadApi.slice(-0, -1);
            }

            if (haveFilterById) {
                return await httpRequest(backendurl, store, customLoadApi, 'GET', key)
            } else {
                var data = await httpRequest(backendurl, store, customLoadApi)

                return data.find(value => value[filterKey] === key)
            }

        }
    })

    return customStore
}

export function makeAsDataSource(customStore) {
    return new DataSource({
        store: customStore,
        paginate: true,
        pageSize: 10,
        sort: [{ selector: 'id', desc: true }]
    })
}

export async function getDateFormat(store, paramCode) {
    var selectedParam = store.getState().getParam.find(value => value.paramCode === paramCode)

    if (selectedParam) {
        var value = null

        switch (selectedParam.paramType) {
            case 'S': value = selectedParam.stringValue
                break;
            case 'F': value = selectedParam.floatValue
                break;
            case 'I': value = selectedParam.intValue
                break;
            case 'D': value = selectedParam.dateValue
                break;
            default: break;
        }

        return value
    } else {
        return null
    }
}

export function ab2str(buf) {
    let utf8decoder = new TextDecoder()

    return utf8decoder.decode(buf)
    // return String.fromCharCode.apply(null, new Uint8Array(buf));
}
export function str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint8Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

export function yyyymmddToDate(dateString) {
    if (typeof dateString !== 'string') {
        dateString = dateString.toString()
    }
    var year = dateString.substring(0, 4);
    var month = dateString.substring(4, 6);
    var day = dateString.substring(6, 8);

    var date = new Date(year, month - 1, day);

    return date
}

export function formatyyyymmddDate(date) {
    if (typeof date === 'string') {
        date = new Date(date)
    }

    var month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('');
}

export function formatTimeOnly(date) {
    if (typeof date === 'string') {
        date = new Date(date)
    }

    var month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds()

    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;
    if (second.length < 2)
        second = '0' + second;

    return [hour, minute, second].join('');
}

export function formatTimeZero(time) {
    var [hours, minutes] = time.split(":");

    // Format hours and minutes with leading zeros
    var formattedHours = hours.padStart(2, "0");
    var formattedMinutes = minutes.padStart(2, "0");

    // Combine formatted hours and minutes into a new string
    var formattedTime = `${formattedHours}:${formattedMinutes}`;

    return formattedTime
}

export function download(url, name, fileExtension = '') {
    var a = document.createElement("a");
    a.target = "_blank"
    a.href = url;
    a.download = name + fileExtension;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

export function generateTextFile(text, name, type, extension) {
    var file = new Blob([text], { type: type });

    var a = document.createElement("a"),
        url = URL.createObjectURL(file);
    a.href = url;
    a.download = name + extension;
    document.body.appendChild(a);
    a.click();
    // setTimeout(function() {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    // }, 0); 
}

export function generateExcelFile(data, name) {
    const fileExtension = '.xlsx';


    var a = document.createElement("a"),
        url = URL.createObjectURL(data);
    a.href = url;
    a.download = name + fileExtension;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }, 0);
}

export function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

export function isToday(someDate) {
    if (typeof someDate === 'string') {
        someDate = new Date(someDate)
    }

    const today = new Date()

    console.log(someDate.getDate(), today.getDate(),
        someDate.getMonth(), today.getMonth(),
        someDate.getFullYear(), today.getFullYear())

    return someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
}

export const objectMap = (obj, fn) =>
    Object.fromEntries(
        Object.entries(obj).map(
            ([k, v], i) => [k, fn(v, k, i)]
        )
    )
export function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
}

export function summaryValue(array, key) {
    return array.reduce((a, b) => a + (b[key] || 0), 0);
}
export function summaryDate(date, day, month, year) {
    var result = 0
    date = date ? new Date(date) : new Date()
    if (day) {
        result = date.getTime() + (day * 24 * 3600 * 1000)
    }

    if (month) {
        var monthDate = date.getMonth() + 1
        var yearDate = result ? new Date(result).getFullYear() : date.getFullYear()

        var nextMonth = monthDate
        for (var i = 1; i < month; i++) {
            nextMonth = nextMonth + i
            var numberOfDays = getDaysInMonth(nextMonth, yearDate)
            result = result + (numberOfDays * 24 * 3600 * 1000)
        }
    }

    if (year) {
        var yearDate = result ? new Date(result).getFullYear() : date.getFullYear()
        var sumYear = yearDate + year
        var dateYear = new Date(result).setFullYear(sumYear)
        return new Date(dateYear)
    }
    return new Date(result)
}

export function monthDiff(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24 * 7 * 4);
    return Math.abs(Math.round(diff));
}

export function dateAddDays(date, days) {
    const newDate = new Date(date.valueOf())
    newDate.setDate(newDate.getDate() + days)
    return newDate
}

export function daysDiff(date1, date2) {
    date1 = new Date(date1);
    date2 = date2 ? new Date(date2) : new Date()

    var Difference_In_Time = date1.getTime() - date2.getTime();

    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Math.round(Difference_In_Days)
}

export function yearsDiff(date1, date2) {
    date1 = new Date(date1).getFullYear();
    date2 = date2 ? new Date(date2).getFullYear() : new Date().getFullYear()

    return Math.round(date1 - date2)
}

export function timeDiff(date1, date2) {
    date1 = new Date(date1);
    date2 = date2 ? new Date(date2) : new Date()

    var Difference_In_Time = date1.getTime() - date2.getTime();

    Difference_In_Time = Math.abs(Difference_In_Time)

    var Difference_In_Sec = Difference_In_Time / (1000);

    var DiffText
    if (Difference_In_Sec < 5) {
        DiffText = `Just Now`
        return DiffText
    }

    if (Difference_In_Sec < 60) {
        DiffText = `${Math.round(Difference_In_Sec)} seconds Ago`
        return DiffText
    }

    if (Difference_In_Sec < 3600) {
        DiffText = `${Math.round(Difference_In_Sec / 60)} minutes Ago`
        return DiffText
    }

    if (Difference_In_Sec < (3600 * 24)) {
        DiffText = `${Math.round(Difference_In_Sec / 3600)} hours Ago`
        return DiffText
    }

    if (Difference_In_Sec >= (3600 * 24)) {
        DiffText = `${Math.round(Difference_In_Sec / (3600 * 24))} days Ago`
        return DiffText
    }
}
export const clone = (items) => {
    return items.map(item => Array.isArray(item) ? clone(item) : item)
}


export const PrintElem = (elem, landscape = false) => {
    var mywindow = window.open('', 'PRINT');

    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('<style type="text/css" media="print">')
    if (landscape) {
        mywindow.document.write('@page {size: landscape;}')
    }
    mywindow.document.write('</style>');
    mywindow.document.write('</head><body style="font-size: 10px">');
    mywindow.document.write(elem);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
        mywindow.print()
        mywindow.close();
    }, 200);

    return true;
}

export const PrintElemWithoutTitle = (elem, landscape = false) => {
    var mywindow = window.open('', 'PRINT');

    mywindow.document.write('<html><head><title></title>');
    mywindow.document.write('<style type="text/css" media="print">')
    if (landscape) {
        mywindow.document.write('@page {size: landscape;}')
    }
    mywindow.document.write('</style>');
    mywindow.document.write('</head><body style="font-size: 10px">');
    mywindow.document.write(elem);
    mywindow.document.write('</body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setTimeout(() => {
        mywindow.print()
        mywindow.close();
    }, 200);

    return true;
}

export const loadReport = async (reportPath, data, landscape) => {
    var res = await axios.post(process.env.REACT_APP_REPORT, {
        template: {
            // name: "/investamart/portofolio-by-account/portofolio-by-account-main",
            name: reportPath,
            recipe: 'html'
        },
        data: data
    }, {
        headers: {
            Authorization: `Basic ${btoa(`${process.env.REACT_APP_REPORT_USERNAME}:${process.env.REACT_APP_REPORT_PASSWORD}`)}`
        }
    })

    PrintElem(res.data, landscape)
}

export function excelCellFormat(value, cellType, format) {
    //cell type definition
    //b: boolean
    //n: number
    //d: date
    //s: string

    //untuk format bisa coba coba di excel di custom format lalu copas ke variabel format
    return { v: value, t: cellType, z: format }
}

export function getExcelDate(excelDate) {
    return new Date(((excelDate - (25567 + 2)) * 86400 - 25200) * 1000)
}
export function pembulatan(value, digit) {
    var pembagi = Math.pow(10, digit)
    var nilaiSisa = value % pembagi
    var nilaiBagi = value - nilaiSisa

    // var nilaiSisa = Math.ceil(nilaiSisa / pembagi)*pembagi
    var nilaiSisa = Math.round(nilaiSisa / pembagi) * pembagi

    return nilaiBagi + nilaiSisa
}
export function compareObj(object1, object2) {
    var objectKey = Object.keys(object1).sort()

    var diff = 0

    for (var key of objectKey) {
        if (typeof object1[key] === 'object' || typeof object2[key] === 'object') {
            var object1str, object2str

            object1str = JSON.stringify(object1[key])
            object2str = JSON.stringify(object2[key])

            if (object1str != object2str) {
                diff++
            }

        } else {
            if (object1[key] != object2[key]) {
                diff++
            }
        }
    }

    return diff > 0 ? true : false
}

export function cleanQuote(value) {
    var regexBetweenTwoDoubleQuote = RegExp(/""(.[^"]*)""/g)
    var regexBetweenTwoQuote = RegExp(/"(.[^"]*)"/g)

    //clean start char from quote
    value = value.replace(/^"/g, '')

    //clean end char from quote
    value = value.replace(/"$/g, '')

    if (regexBetweenTwoDoubleQuote.test(value)) {
        var cleanValueBetweenTwoQuoteArrays = value.match(regexBetweenTwoDoubleQuote)

        for (var valueBetweenTwoQuote of cleanValueBetweenTwoQuoteArrays) {
            var cleanValueBetweenTwoQuote = valueBetweenTwoQuote.replace(/,/g, '.')
            cleanValueBetweenTwoQuote = cleanValueBetweenTwoQuote.replace(/""/g, '')
            value = value.replace(valueBetweenTwoQuote, cleanValueBetweenTwoQuote)
        }
    }
    if (regexBetweenTwoQuote.test(value)) {
        var cleanValueBetweenTwoQuoteArrays = value.match(regexBetweenTwoQuote)

        for (var valueBetweenTwoQuote of cleanValueBetweenTwoQuoteArrays) {
            var cleanValueBetweenTwoQuote = valueBetweenTwoQuote.replace(/,/g, '.')
            cleanValueBetweenTwoQuote = cleanValueBetweenTwoQuote.replace(/"/g, '')
            value = value.replace(valueBetweenTwoQuote, cleanValueBetweenTwoQuote)
        }
    }

    return value
}


export function firstUpperCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function joinDuplicateData(data) {
    var joinedData = [...new Set(data)]

    return joinedData

}

export function broadcastNotification(description, title) {
    // reffType
    // 1 = All
    // 2 = Agama Islam
    // 3 = Agama Kristen
    // 4 = Agama Khatolik
    // 5 = Agama Buddha
    // 6 = Agama Hindu
    // 7 = Agama Konghucu
    // 8 = Agama Aliran Kepercayaan
    var messageListRef = fire.database().ref('broadcast');
    messageListRef.push({
        createDate: formatDateTimeNumber(new Date()),
        description: description,
        reffType: 2,
        title: title,
    });
}
export function pushNotification(description, reffType, reffNo, pesertaId) {

    //reffType
    // 1 = pengkinian online
    // 2 = Hasil Simulasi
    // 3 = Ngubah Kata sandi dan Ngubah Email
    // 4 = Daftar Ulang
    // 5 = Aktivasi Ulang (kalau foto ktp aktivasi di reject)

    // reffNo wajib diisi sesuai dengan id record yang bersangkutan
    // pesertaId adalah createUserId di dalam hasil rest API
    var title
    switch (reffType) {
        case 1:
            title = 'Pengkinian Online'
            break;
        case 2:
            title = 'Hasil Simulasi'
            break;
        case 3:
            title = 'Perubahan Kata Sandi dan Email'
            break;
        case 4:
            title = 'Daftar Ulang'
            break;
        case 5:
            title = 'Aktivasi Online'
            break;
        default:
            title = ''
    }
    var messageListRef = fire.database().ref('notifications');
    messageListRef.push({
        createDate: formatDateTimeNumber(new Date()),
        description: description,
        pesertaId: pesertaId,
        readStatus: false,
        reffNo: reffNo,
        reffType: reffType,
        title: title,
    });
}
export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export function numberToEnglish(n, custom_join_character) {

    var string = n.toString(),
        units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words;

    var and = custom_join_character || 'and';

    /* Is number zero? */
    if (parseInt(string) === 0) {
        return 'zero';
    }

    /* Array of units as words */
    units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

    /* Array of tens as words */
    tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    /* Array of scales as words */
    scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];

    /* Split user arguemnt into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
        end = start;
        chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }

    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
        return '';
    }

    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {

        chunk = parseInt(chunks[i]);

        if (chunk) {

            /* Split chunk into array of individual integers */
            ints = chunks[i].split('').reverse().map(parseFloat);

            /* If tens integer is 1, i.e. 10, then add 10 to units integer */
            if (ints[1] === 1) {
                ints[0] += 10;
            }

            /* Add scale word if chunk is not zero and array item exists */
            if ((word = scales[i])) {
                words.push(word);
            }

            /* Add unit word if array item exists */
            if ((word = units[ints[0]])) {
                words.push(word);
            }

            /* Add tens word if array item exists */
            if ((word = tens[ints[1]])) {
                words.push(word);
            }

            /* Add 'and' string after units or tens integer if: */
            if (ints[0] || ints[1]) {

                /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                if (ints[2] || !i && chunksLen) {
                    words.push(and);
                }

            }

            /* Add hundreds word if array item exists */
            if ((word = units[ints[2]])) {
                words.push(word + ' hundred');
            }

        }

    }

    return words.reverse().join(' ');

}

export function numberToIndo(a) {
    var bilangan = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan', 'Sepuluh', 'Sebelas'];

    var koma = 0
    if (a % 1 !== 0) {
        koma = a % 1
        koma = parseFloat(koma.toFixed(2))
        koma = koma.toString().split('.')
        koma = parseInt(koma[1])
    }

    // 1 - 11
    if (a < 12) {
        var depan = parseInt(a)
        var kalimat = bilangan[depan];
        if (koma) {
            kalimat = kalimat[depan] + ' Koma ' + numberToIndo(koma)
        }
    }
    // 12 - 19
    else if (a < 20) {
        var depan = parseInt(a)
        var kalimat = bilangan[depan - 10] + ' Belas';
        if (koma) {
            kalimat = bilangan[depan - 10] + ' Belas Koma ' + numberToIndo(koma)
        }
    }
    // 20 - 99
    else if (a < 100) {
        var utama = a / 10;
        var depan = parseInt(String(utama).substr(0, 1));
        var belakang = a % 10;
        var kalimat = bilangan[depan] + ' Puluh ' + bilangan[belakang];
    }
    // 100 - 199
    else if (a < 200) {
        var kalimat = 'Seratus ' + numberToIndo(a - 100);
    }
    // 200 - 999
    else if (a < 1000) {
        var utama = a / 100;
        var depan = parseInt(String(utama).substr(0, 1));
        var belakang = a % 100;
        var kalimat = bilangan[depan] + ' Ratus ' + numberToIndo(belakang);
    }
    // 1,000 - 1,999
    else if (a < 2000) {
        var kalimat = 'Seribu ' + numberToIndo(a - 1000);
    }
    // 2,000 - 9,999
    else if (a < 10000) {
        var utama = a / 1000;
        var depan = parseInt(String(utama).substr(0, 1));
        var belakang = a % 1000;
        var kalimat = bilangan[depan] + ' Ribu ' + numberToIndo(belakang);
    }
    // 10,000 - 99,999
    else if (a < 100000) {
        var utama = a / 100;
        var depan = parseInt(String(utama).substr(0, 2));
        var belakang = a % 1000;
        var kalimat = numberToIndo(depan) + ' Ribu ' + numberToIndo(belakang);
    }
    // 100,000 - 999,999
    else if (a < 1000000) {
        var utama = a / 1000;
        var depan = parseInt(String(utama).substr(0, 3));
        var belakang = a % 1000;
        var kalimat = numberToIndo(depan) + ' Ribu ' + numberToIndo(belakang);
    }
    // 1,000,000 - 	99,999,999
    else if (a < 100000000) {
        var utama = a / 1000000;
        var depan = parseInt(String(utama).substr(0, 4));
        var belakang = a % 1000000;
        var kalimat = numberToIndo(depan) + ' Juta ' + numberToIndo(belakang);
    }
    else if (a < 1000000000) {
        var utama = a / 1000000;
        var depan = parseInt(String(utama).substr(0, 4));
        var belakang = a % 1000000;
        var kalimat = numberToIndo(depan) + ' Juta ' + numberToIndo(belakang);
    }
    else if (a < 10000000000) {
        var utama = a / 1000000000;
        var depan = parseInt(String(utama).substr(0, 1));
        var belakang = a % 1000000000;
        var kalimat = numberToIndo(depan) + ' Milyar ' + numberToIndo(belakang);
    }
    else if (a < 100000000000) {
        var utama = a / 1000000000;
        var depan = parseInt(String(utama).substr(0, 2));
        var belakang = a % 1000000000;
        var kalimat = numberToIndo(depan) + ' Milyar ' + numberToIndo(belakang);
    }
    else if (a < 1000000000000) {
        var utama = a / 1000000000;
        var depan = parseInt(String(utama).substr(0, 3));
        var belakang = a % 1000000000;
        var kalimat = numberToIndo(depan) + ' Milyar ' + numberToIndo(belakang);
    }
    else if (a < 10000000000000) {
        var utama = a / 10000000000;
        var depan = parseInt(String(utama).substr(0, 1));
        var belakang = a % 10000000000;
        var kalimat = numberToIndo(depan) + ' Triliun ' + numberToIndo(belakang);
    }
    else if (a < 100000000000000) {
        var utama = a / 1000000000000;
        var depan = parseInt(String(utama).substr(0, 2));
        var belakang = a % 1000000000000;
        var kalimat = numberToIndo(depan) + ' Triliun ' + numberToIndo(belakang);
    }

    else if (a < 1000000000000000) {
        var utama = a / 1000000000000;
        var depan = parseInt(String(utama).substr(0, 3));
        var belakang = a % 1000000000000;
        var kalimat = numberToIndo(depan) + ' Triliun ' + numberToIndo(belakang);
    }

    else if (a < 10000000000000000) {
        var utama = a / 1000000000000000;
        var depan = parseInt(String(utama).substr(0, 1));
        var belakang = a % 1000000000000000;
        var kalimat = numberToIndo(depan) + ' Kuadriliun ' + numberToIndo(belakang);
    }

    var pisah = kalimat.split(' ');
    var full = [];
    for (var i = 0; i < pisah.length; i++) {
        if (pisah[i] != "") { full.push(pisah[i]); }
    }
    return full.join(' ');
}

export function getSystemDate(store) {
    var systemDate = (store.getState().getParam || []).find(value => value.paramCode === '0 SYSDATE')

    systemDate = (systemDate || {}).dateValue

    return systemDate
}

export const postingJournal = async (store, valueDate, systemDate, journalHeaderId) => {
    await httpRequest(process.env.REACT_APP_BACKEND_ACC, store, `journal-headers/post/${journalHeaderId}`)
    var dataJournal = await httpRequest(process.env.REACT_APP_BACKEND_ACC, store, `journal-detils/getAllByJournalHeaderId/${journalHeaderId}`)

    var dataJournalDetilCheckProdukId = dataJournal.filter(value => value.produkId === null)

    if (dataJournalDetilCheckProdukId.length > 0) {
        return null
    }

    // if (new Date(valueDate) <= new Date(systemDate)) {
    //     for (var d = new Date(valueDate); d <= new Date(systemDate); d.setDate(d.getDate() + 1)) {
    //         var dataSaldo = await httpRequest(process.env.REACT_APP_BACKEND_ACC, store, `coa-position-dailies/get-by-valueDate/${formatDate(d)}`)
    //         for (const journalDetil of dataJournal) {
    //             const selectedBalance = dataSaldo.find(value => value.coaId === journalDetil.coa.id && value.currencyId === journalDetil.currency.id && value.produkId === journalDetil.produkId)
    //             if (selectedBalance) {
    //                 await httpRequest(process.env.REACT_APP_BACKEND_ACC, store, `coa-position-dailies`, 'PUT', {
    //                     values: {
    //                         "produkId": journalDetil.produkId,
    //                         "id": selectedBalance.id,
    //                         "balanceAmount": selectedBalance.balanceAmount + (journalDetil.dc === 'D' ? journalDetil.amount : -journalDetil.amount),
    //                         "balanceAmountBaseCurrency": selectedBalance.balanceAmountBaseCurrency + (journalDetil.dc === 'D' ? journalDetil.amountBasedCurrency : -journalDetil.amountBasedCurrency),
    //                     }
    //                 }, selectedBalance)
    //             } else {
    //                 await httpRequest(process.env.REACT_APP_BACKEND_ACC, store, `coa-position-dailies`, 'POST', {
    //                     values: {
    //                         "produkId": journalDetil.produkId,
    //                         "balanceAmount": journalDetil.dc === 'D' ? journalDetil.amount : -journalDetil.amount,
    //                         "balanceAmountBaseCurrency": journalDetil.dc === 'D' ? journalDetil.amountBasedCurrency : -journalDetil.amountBasedCurrency,
    //                         "coaId": journalDetil.coa.id,
    //                         "currencyId": journalDetil.currency.id,
    //                         "valueDate": formatDate(d)
    //                     }
    //                 })
    //             }
    //         }
    //     }
    // }
}

export const inputToTaskInvestasi = async (store, orderRequestId, orderTypeId) => {
    let dataForTask = {
        orderRequestId: orderRequestId,
        orderTypeId: orderTypeId
    }
    let insertAPITask = 'tasks/persetujuan-pengajuan-penempatan-investasi';
    let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, insertAPITask, 'POST', {
        values: dataForTask
    })

    return response
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function findFirstDayOfMonth(year, month) {
    return new Date(year, month, 1)
}

export function findLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0)
}