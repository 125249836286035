import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import Form from 'devextreme-react/form';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { ab2str, yyyymmddToDate, formatNumber, cleanQuote, getExcelDate } from 'plugin/helper';
import XLSX from 'xlsx'
import { showLoading } from 'redux/actions/loading'
import { confirm,alert } from 'devextreme/ui/dialog';

import { DataGrid, FileUploader } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import { departments, depreciationMethod, coa, fixedAssetGroups } from 'dataSource/lookup';

class ModalUploadFixedAsset extends Component {
    constructor(props){
        super(props)

        this.fileUploderRef = React.createRef();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = {
            popupVisible: false,
            uploadedData: [],
            successData: [],
            errorData: []
            // selectedTabIndex: 0,
        }
        
        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()

        // this.formItem = {
        //     useHeader : true
        // }

        this.successData = []
        this.errorData = []

        this.prevTabIndex = 0

        this.TabDataSource = [
            {
                id: 0,
                text: "Data Sesuai"
            },
            {
                id: 1,
                text: "Data Tidak Sesuai"
            },
        ];

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: () => {
                    this.submit()
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: () => {
    
                }
              },
              toolbar: "bottom"
            }
        ];
        
        // this.checkboxItem = [
        //     {
        //         dataField: 'useHeader',
        //         label: {
        //             alignment: 'left',
        //             text: 'Use Header'
        //         },
        //         editorType: 'dxCheckBox',
        //     }
        // ]
        this.columns = [
            {
                dataField: 'fixedAssetCode',
                caption: 'Kode Aset Tetap',
            },
            {
                dataField: 'fixedAssetName',
                caption: 'Aset Tetap',
            },
            {
                dataField: 'fixedAssetDesc',
                caption: 'Deskripsi',
            },
            {
                dataField: 'fixedAssetGroupId',
                caption: 'Kode Grup Aset',
                lookup: {
                    dataSource: fixedAssetGroups(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'assetGroupName',
                }
            },
            {
                dataField: 'purchaseDate',
                caption: 'Tanggal Pembelian',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'buyingPrice',
                caption: 'Harga Beli',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'bookValue',
                caption: 'Nilai Buku',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'economicLife',
                caption: 'Economic Life (Bulan)',
            },
            {
                dataField: 'location',
                caption: 'Lokasi',
            },
            {
                dataField: 'departmentId',
                caption: 'Departemen',
                lookup: {
                    dataSource: departments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'departmentName',
                }
            },
            {
                dataField: 'calcDate',
                caption: 'Tanggal Mulai Penyusutan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'depreciationMethod',
                caption: 'Metode Depresiasi',
                lookup: {
                    dataSource: depreciationMethod(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                dataField: 'accumDepreciation',
                caption: 'Nilai Akumulasi Penyusutan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'accumDepreciationYtd',
                caption: 'Nilai Akumulasi Penyusutan Tahun Berjalan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'assetCoaId',
                caption: 'Akun Aset',
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    } 
                }
            },
            {
                dataField: 'accumDepreciationCoaId',
                caption: 'Akun Akumulasi Penyusutan',
                lookup: {
                    dataSource:  coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    } 
                }
            },
            {
                dataField: 'depreciationCoaId',
                caption: 'Akun Beban Penyusutan',
                lookup: {
                    dataSource:  coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    } 
                }
            },
            {
                dataField: 'monthlyDepreciation',
                caption: 'Nilai Penyusutan Bulanan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'salvage',
                caption: 'Nilai Residu',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value

        this.dataGridSesuaiRef.current.repaint()
        this.dataGridTakSesuaiRef.current.repaint()
    }

    show = () => {
        this.setState({
            popupVisible: true
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
            uploadedData: [],
            successData: [],
            errorData: []
        });
    };


    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns]
        columns = columns.map((value) => {
            value = {...value}
            delete value.lookup

            return value
        })

        columns.unshift({
            type: 'buttons',
            fixed: false,
            buttons: [{
                text : 'Error Detail',
                hint : 'Error Detail',
                icon: "warning",
                cssClass: "text-danger", 
                onClick : (e) => {
                    let text = e.row.data.errorMessage.join("\n");

                    alert( text,'Error')
                },
            }]
        })

        return columns
    }

    onUploadStarted = async (e) => {
        var dataCOA = await httpRequest(
            process.env.REACT_APP_BACKEND_ACC,
            this.props.store,
            'coas',
            "GET"
        );

        var dataAsetGroup = await httpRequest(
            process.env.REACT_APP_BACKEND_ACC,
            this.props.store,
            'fixed-asset-groups',
            "GET"
        );

        var dataDepartemen = await httpRequest(
            process.env.REACT_APP_BACKEND_ACC,
            this.props.store,
            'departments',
            "GET"
        );


        for (var i = 0; i < e.value.length; i++) {
            this.props.store.dispatch(showLoading(true))
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);

                var data

                if(fileType === 'xls' || fileType === 'xlsx' ){
                    var workbook = XLSX.read(dataArrayBuffer, {type: 'array'});
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)
                    
                    data = dataJSON.map(value => Object.values(value))
                }

                data = data.filter(value => value.length === 19)
                data = data.slice(1,data.length)

                var successData = []
                var errorData = []

                var errorMessage = []

                var dateColumn = [{
                    index: 4,
                    label: 'Tanggal Pembelian'
                },{
                    index: 10,
                    label: 'Tanggal Mulai Penyusutan'
                }]

                var NumberColumn = [
                    {
                        index: 5,
                        label: 'Harga Perolehan'
                    },
                    {
                        index: 6,
                        label: 'Nilai Buku'
                    },
                    {
                        index: 7,
                        label: 'Umur Ekonomis'
                    },
                    {
                        index: 12,
                        label: 'Akum. Penyusutan'
                    },
                    {
                        index: 13,
                        label: 'Akum. Penyusutan Tahun Berjalan'
                    },
                    {
                        index: 17,
                        label: 'Penyusutan Bulanan'
                    },
                    {
                        index: 18,
                        label: 'Nilai Residu'
                    },
                ]

                for(var uploadedData of data){
                    if(Array.isArray(uploadedData)){
                        errorMessage = []

                        var assetCoaId = dataCOA.find(value => value.coaCode === uploadedData[14])
                        var accumDepreciationCoaId = dataCOA.find(value => value.coaCode === uploadedData[15])
                        var depreciationCoaId = dataCOA.find(value => value.coaCode === uploadedData[16])
                        var fixedAssetGroupId = dataAsetGroup.find(value => value.assetGroupCode === uploadedData[3])
                        var departmentId = dataDepartemen.find(value => value.departmentCode === uploadedData[9])
                        var depreciationMethodId = depreciationMethod(true).find(value => value.id === uploadedData[11])

                        if(!assetCoaId){
                            errorMessage.push(`Data COA untuk Aset dengan kode (${uploadedData[14]}) tidak di temukan di sistem`)
                        }
                        if(!accumDepreciationCoaId){
                            errorMessage.push(`Data COA untuk Akum Depresiasi dengan kode (${uploadedData[15]}) tidak di temukan di sistem`)
                        }
                        if(!depreciationCoaId){
                            errorMessage.push(`Data COA untuk Beban Depresiasi dengan kode (${uploadedData[16]}) tidak di temukan di sistem`)
                        }

                        if(!fixedAssetGroupId){
                            errorMessage.push(`Data Group Aset Tetap dengan kode (${uploadedData[3]}) tidak di temukan di sistem`)
                        }

                        if(!departmentId){
                            errorMessage.push(`Data Departemen dengan kode (${uploadedData[9]}) tidak di temukan di sistem`)
                        }

                        if(!depreciationMethodId){
                            errorMessage.push(`Data Metode Depresiasi dengan nilai (${uploadedData[11]}) tidak ditemukan di sistem`)
                        }

                        for(var dateCol of dateColumn){
                            if(!getExcelDate(uploadedData[dateCol.index]) instanceof Date){
                                errorMessage.push(`Data kolom ${dateCol.label} bukan merupakan tipe data Date`)
                            }
                        }

                        for(var numberCol of NumberColumn){
                            if(isNaN(uploadedData[numberCol.index])){
                                errorMessage.push(`Data kolom ${dateCol.label} bukan merupakan tipe data numerik`)
                            }
                        }

                        var mappedData =  {
                            fixedAssetCode : uploadedData[0],
                            fixedAssetName : uploadedData[1],
                            fixedAssetDesc : uploadedData[2],
                            fixedAssetGroupId : fixedAssetGroupId ? fixedAssetGroupId.id : uploadedData[3],
                            purchaseDate : getExcelDate(uploadedData[4]),
                            buyingPrice : uploadedData[5],
                            bookValue : uploadedData[6],
                            economicLife : uploadedData[7],
                            location : uploadedData[8],
                            departmentId : departmentId ? departmentId.id : uploadedData[9],
                            calcDate : getExcelDate(uploadedData[10]),
                            depreciationMethod : depreciationMethodId ? depreciationMethodId.id : uploadedData[11],
                            accumDepreciation : uploadedData[12],
                            accumDepreciationYtd : uploadedData[13],
                            assetCoaId : assetCoaId ? assetCoaId.id : uploadedData[14],
                            accumDepreciationCoaId : accumDepreciationCoaId ? accumDepreciationCoaId.id : uploadedData[15],
                            depreciationCoaId : depreciationCoaId ? depreciationCoaId.id : uploadedData[16],
                            monthlyDepreciation : uploadedData[17],
                            salvage : uploadedData[18],
                        }

                        if(errorMessage.length > 0){
                            mappedData['errorMessage'] = errorMessage
                            errorData.push(mappedData)

                            continue
                        }

                        successData.push(mappedData)
                        
                    }
                }

                if(errorData.length > 0){
                    let type = "error";
                    let text = "Ada beberapa data unggahan yang tidak sesuai, harap periksa tab data unggahan yang tidak sesuai";
                    
                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                }

                this.setState({
                    successData: successData,
                    errorData: errorData
                })

                this.props.store.dispatch(showLoading(false))
            }
            reader.readAsArrayBuffer(files);
        }

    }

    retrieveDataSuccess = () => {
        return this.state.successData
    }

    retrieveDataFailed = () => {
        return this.state.errorData
    }

    submit = async () => {
        var dataFailed = this.dataGridTakSesuaiRef.current.getDataSource()
        var dataSuccess = this.dataGridSesuaiRef.current.getDataSource()
        
        if(dataFailed.length > 0){
            let result = await confirm("Terdapat data upload yang masih salah, lanjutkan proses?", "Konfirmasi");
            if(!result){
                return false
            }
        }
        
        this.props.store.dispatch(showLoading(true))

        for(var dataMaster of dataSuccess){
            dataMaster.status = 'A'

            try{
                var insertAPI = 'fixed-assets'
                dataMaster.baseAmount = dataMaster.buyingPrice
                var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, insertAPI, 'POST', {
                    values: dataMaster
                })
            }catch (e){
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                this.props.store.dispatch(showLoading(false))
            }
        }

        notify({ message: 'Data berhasil ditambahkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        this.props.forceRefresh()
        this.hide()

        this.props.store.dispatch(showLoading(false))
    }

    render() {
        return (
            <Popup
            className={"popup"}
            visible={this.state.popupVisible}
            onHiding={this.hide}
            dragEnabled={false}
            showTitle={true}
            title={"Upload Aset"}
            width={"90vw"}
            height={650}
            toolbarItems={this.PopupToolbarItem}
            >
                <div className="d-flex flex-row">
                    <div style={{width:"350px"}}>
                        <FileUploader
                            accept={".txt,.xls,.xlsx,.csv"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload File Aset Disini"
                            showFileList={false}
                            name={"uploadAset"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                        <a href="/file_upload/format_file_upload_fixed_asset.xlsx" download><i class="fas fa-file-download mr-2"></i>Unduh format file upload</a>
                        {/* <Form
                            ref={this.formRef}
                            id={'formUseHeader'}
                            colCount={1}
                            formData={this.formItem}
                            items={this.checkboxItem}
                            scrollingEnabled={false}
                            labelLocation='left'
                        /> */}
                        {/* <CheckBox 
                            value={this.formItem} 
                            onValueChanged={this.onValueChangedUseHeader}
                        />
                        <span className="ml-2">Use Header</span> */}
                    </div>
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0" className={' mt-2 w-100 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataSuccess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Sukses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Sukses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 285px)'}
                        keyField = {'fixedAssetCode'}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabindex-1" className={'d-none mt-2 w-100 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridTakSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        menuRightClick = {false}

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataFailed}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Gagal"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Gagal'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.errorDataGridErrorButtonColumn()} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 285px)'}
                        keyField = {'fixedAssetCode'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    </div>
            </Popup>
        )
    }
}

export default ModalUploadFixedAsset