import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, pensiunType, product } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import ModalPengajuanPesertaDitunda from 'components/pages/modal/kepesertaan/pesertaDapen/ditunda/pengajuan/index';
import httpRequest from 'plugin/httprequest';
import {summaryValue} from 'plugin/helper';
import ModalInsertPengajuanPesertaDitunda from 'components/pages/modal/kepesertaan/pesertaDapen/ditunda/pengajuan/insert';
import { confirmAlert } from 'react-confirm-alert'

class PengajuanPesertaDitunda extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            dataMaster: {},
            getUrl : 'view-pesertas?statusPeserta.in=5'
        }

        this.dataGridRef = React.createRef()
        this.modalPengajuanPesertaDitundaRef = React.createRef()
        this.modalInsertPengajuanPesertaDitundaRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showPengajuanPesertaDitunda('edit',e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Mengirim Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.kirim(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'nik',
                caption: 'NIK',
                alignment: "right",
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'pensiunTypeId',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'tglDitunda',
                caption: 'Tanggal Non Aktif',
                dataType: "date",
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'productId',
                caption: 'Akun Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode" // provides display values
                }
            },
            {
                dataField: 'skPemberhentianNo',
                caption: 'No SK Henti'
            },
            {
                name: 'saldoIuran',
                alignment: 'center',
                caption: 'Saldo Iuran',
                columns: [
                    {
                        dataField: 'totalIuran',
                        caption: 'Iuran',
                    },
                    {
                        dataField: 'totalNominal',
                        caption: 'Total Nominal',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
        ]

        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        if(data.value){
                            this.setState({
                                getUrl: `view-pesertas?statusPeserta.in=5&participantId.in=${data.value}`
                            })
                        }else{
                            this.setState({
                                getUrl: `view-pesertas?statusPeserta.in=5`
                            })
                        }
                        this.forceRefresh()
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    kirim = async(data) => {
        var loadAPIPeserta = `pesertas/${data.pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta, 'GET')

        var loadAPIAkun = `kepersertaan-products/${data.id}`
        var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'GET')

        var dataPeserta = getPeserta
        dataPeserta.statusPeserta = 6

        var dataAkun = getAkun
        dataAkun.akunStatusId = 3
        try {
            var updateAPIPeserta = `pesertas`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPIPeserta, 'PUT', {
                    key: dataPeserta.id,
                    values:dataPeserta
                },
                getPeserta)

            var updateAPIAkun = `kepersertaan-products`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPIAkun, 'PUT', {
                    key: dataAkun.id,
                    values:dataAkun
                },
                getAkun)
            this.forceRefresh()
            notify({ message: 'Data Berhasil Dikirim!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'plus',
                text: 'Pensiun Ditunda',
                onClick: async(e) => {
                    this.insertPengajuanPesertaDitunda()
                },
            }
        });
    }

    retrieveData = async(param) => {
        var loadAPIAkun = this.state.getUrl + param
        var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'GET')

        var data = []
        if(dataPeserta.length !== 0){
            for(var peserta of dataPeserta){
                var loadAPIAkun = `kepersertaan-products/getByPesertaId/${peserta.ids || peserta.id}`
                var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'GET')
                for(var akun of dataAkun){
                    var loadAPIAkunDetail = `kepersertaan-products/${akun.ids || akun.id}`
                    var dataAkunDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkunDetail, 'GET')
    
                    var loadAPIIuran = `getIuranByGrupByAkun/${akun.ids || akun.id}/1`
                    var dataIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIIuran, 'GET')
    
                    var loadAPIIuranByPeserta = `iurans/getByPesertaId/${akun.pesertaIds || akun.pesertaId}`
                    var dataIuranByPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIIuranByPeserta, 'GET')
    
                    var result ={
                        id : akun.ids || akun.id,
                        pesertaId : peserta.ids || peserta.id,
                        nik : peserta.nik,
                        fullName : peserta.fullName,
                        pensiunTypeId : dataAkunDetail.pensiunType,
                        birthDate : peserta.birthDate,
                        pensiunDate : dataAkunDetail.pensiunDate,
                        tglDitunda: dataAkunDetail.tglDitunda,
                        skPemberhentianNo: dataAkunDetail.skPemberhentianNo,
                        productId : dataAkunDetail.productId,
                        totalIuran : dataIuranByPeserta.length,
                        totalNominal : summaryValue(dataIuran,'nominal')
                    }
                    data.push(result)
                }
            }
        }

        return data
    }

    showPengajuanPesertaDitunda = (type,data) => {
        this.modalPengajuanPesertaDitundaRef.current.show()
        if(type == 'edit'){
            this.modalPengajuanPesertaDitundaRef.current.retrieveData(type,data)
        }
    }

    insertPengajuanPesertaDitunda = () => {
        this.modalInsertPengajuanPesertaDitundaRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Registrasi Peserta Pensiun Ditunda</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData = {this.retrieveData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Peserta Pensiun Ditunda"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Peserta Pensiun Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalInsertPengajuanPesertaDitunda
                    ref={this.modalInsertPengajuanPesertaDitundaRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'add'}
                />
                <ModalPengajuanPesertaDitunda
                    ref={this.modalPengajuanPesertaDitundaRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'view'}
                />
            </div>
        )
    }
}

export default PengajuanPesertaDitunda