import React, { Component } from 'react'
import { Form } from "devextreme-react";

class FormRiwayatJabatan extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData : {} 
        }

        this.items = [
            {
                dataField: 'nomorUrut',
                label: {
                    text: 'Nomor Urut',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'jumlahTunjanganJabatan',
                label: {
                    text: 'Jumlah Tunjangan Jabatan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'namaJabatan',
                label: {
                    text: 'Nama Jabatan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'tanggalMulaiJabatan',
                label: {
                    text: 'Tanggal Mulai Jabatan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'nomorSKJabatan',
                label: {
                    text: 'Nomor SK Jabatan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'tanggalBerakhirJabatan',
                label: {
                    text: 'Tanggal Berakhir Jabatan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'tanggalSKJabatan',
                label: {
                    text: 'Tanggal SK Jabatan',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
        ]
    }
    render() {
        return (
            <React.Fragment>
                {/* <div style={{backgroundColor : '#f7f7f7'}}> */}
                    <Form
                        colCount={2}
                        id={'form'}
                        formData={this.state.formData}
                        items={this.items}
                        scrollingEnabled={true}
                        ref={this.formRef}
                    />
                {/* </div> */}
            </React.Fragment>
        )
    }
}

export default FormRiwayatJabatan