import React, { Component } from 'react'
import { tax } from '../parts/staticDataColumnConfig'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'

class Tax extends Component {
    constructor(props) {
        super(props)

        this.columns = tax(this.props.store)

        this.dataGridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `staticdata-wf/taxes`, 'DELETE', data.id);

            notify({message: 'Data Berhasil Dihapus', width: "AUTO", shading: true, position: { at: "center", my: "center", of: window }}, 'success', 600);
            this.forceRefresh()
        } catch (e) {
            notify({message: e, width: "AUTO", shading: true, position: { at: "center", my: "center", of: window }}, 'error', 600);
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        var columns = [{
            type: 'buttons',
            buttons: [
            //   {
            //     text: 'Ubah',
            //     hint: 'Ubah',
            //     cssClass: 'text-success',
            //     onClick: async(e) => {
            //         this.showModalEdit('edit', e.row.data)
            //     }
            //   },
              {
                text: 'Hapus',
                hint: 'Hapus',
                cssClass: 'text-success',
                onClick: async(e) => {
                    confirmAlert({
                        title: 'Konfirmasi',
                        message: 'Apakah anda yakin untuk menghapus data ini?',
                        buttons: [
                            {
                                label: 'Ya',
                                onClick: () => {
                                    this.deleteAPI(e.row.data)
                                }
                            },
                            {
                                label: 'Tidak'
                            }
                        ]
                    })
                }
              }
            ],
        },...this.columns]
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pajak</h2>

                <DevExpressDataGrid
                ref={this.dataGridRef}
                    loadAPI='taxes'
                    insertAPI='staticdata-wf/taxes'
                    updateAPI='staticdata-wf/taxes'
                    deleteAPI='staticdata-wf/taxes' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Taxes"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Pajak'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default Tax
