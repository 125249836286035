import React, { Component } from 'react'
import Loader from 'react-loader-spinner'

class Loading extends Component {

    constructor(props) {
        super(props)

        this.state = {
            show: false,
            visible: false,
        }

        this.unsubscribe = '';
    }


    componentDidMount() {
        let store = this.props.store;

        this.toggleLoading(store.getState().showloading)

        this.unsubscribe = store.subscribe(() => {
            this.toggleLoading(store.getState().showloading)
        })
    }

    toggleLoading(status = false) {

        if (!(status instanceof Array) && status) {
            this.setState({
                show: 'd-block',
                visible: status
            })
        } else {
            this.setState({
                show: 'd-none',
                visible: false
            })
        }
    }

    render() {
        return (
            <div className={'loading-container ' + this.state.show} >
                <Loader
                    className="loading"
                    visible={this.state.visible}
                    type="ThreeDots"
                    // type="CradleLoader"
                    // color="#00BFFF"
                    height={100}
                    width={100}
                />
            </div>
        )
    }
}

export default Loading
