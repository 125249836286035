import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { orders, stock, stokNeedConfirmByBroker, broker, productDefault } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';


class ModalActionSettlementTransaksiSaham extends Component {
    constructor(props) {
        super(props)
        this.pph = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'STK-PPH') : null
        this.levy = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'STK-LEVY') : null
        this.wht = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'STK-WHT') : null
        this.vat = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'STK-VAT') : null
        this.vatLevy = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'STK-VAT-LEVY') : null
        this.clrHs = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'STK-CLR-HS') : null

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null,
            noOrderDataSource: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.brokerCode = ""
        this.PopupRef = React.createRef()
        this.dataGridPencairanRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                dataField: 'orderStokId',
                label: {
                    text: 'No Order'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'orderStokId',
                    displayExpr: 'orderCode',
                    onSelectionChanged: async (e) => {
                        var data = e.selectedItem
                        if (data) {
                            var dataOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `orders/${data.orderId}`, 'GET')
                            this.formRef.current.instance.getEditor('quantity').option('value', data.quantityOrder)
                            this.formRef.current.instance.getEditor('transactionName').option('value', data.transactionName)
                            this.formRef.current.instance.getEditor('instrumentId').option('value', dataOrder.instrumentId)
                            this.setState({
                                orderCode: data.orderCode
                            })
                            this.formRef.current.instance.getEditor('pph').option('value', 0)
                            this.formRef.current.instance.getEditor('pph').option('readOnly', true)
                            this.formRef.current.instance.getEditor('whtPph23').option('value', 0)
                            // this.formRef.current.instance.getEditor('whtPph23').option('readOnly', true)
                        }
                        return data
                    }
                },
            },
            {
                dataField: 'transactionName',
                label: {
                    text: 'Tipe order transaksi'
                },
                editorOptions: {
                    readOnly: true
                },
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Saham'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: stock(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode',
                    searchEnabled: true,
                    deferRendering: false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Instrument Saham"
                    }
                ]
            },
            {
                dataField: 'quantity',
                label: {
                    text: 'Kuantitas'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0',
                    onValueChanged: (e) => {
                        var val = e.value
                        var price = this.formRef.current.instance.getEditor('price').option('value')
                        if (val) {
                            this.formRef.current.instance.getEditor('amountTrs').option('value', val * price)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill quantity order"
                    }
                ]
            },
            {
                dataField: 'price',
                label: {
                    text: 'Harga'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    onValueChanged: (e) => {
                        var val = e.value
                        var qty = this.formRef.current.instance.getEditor('quantity').option('value')
                        if (val) {
                            this.formRef.current.instance.getEditor('amountTrs').option('value', qty * val)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill price"
                    }
                ]
            },
            {
                dataField: 'amountTrs',
                label: {
                    text: 'Nilai Transaksi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    onValueChanged: (e) => {
                        var val = e.value
                        if (val) {
                            let brokerFee = this.state.dataMaster.feeBuy,
                                levy = this.levy.floatValue,
                                vatLevy = this.vatLevy.floatValue,
                                clrHs = this.clrHs.floatValue

                            this.formRef.current.instance.getEditor('commission').option('value', Math.round(100 / 111 * (brokerFee - vatLevy - clrHs - levy) * val / 100))
                            this.formRef.current.instance.getEditor('levy').option('value', Math.round(levy * val / 100))
                            this.formRef.current.instance.getEditor('vatLevy').option('value', Math.round(vatLevy * val / 100))
                            this.formRef.current.instance.getEditor('clearingHouseLevy').option('value', Math.round(clrHs * val / 100))
                            this.formRef.current.instance.getEditor('pph').option('value', 0)
                            // this.formRef.current.instance.getEditor('commission').option('value', Math.round(((this.state.dataMaster.feeBuy - this.levy.floatValue) / 100 / ((100 + this.vat.floatValue) / 100)) * val))
                            // this.formRef.current.instance.getEditor('commission').option('value', Math.round((val * (this.state.dataMaster.feeBuy - this.levy.floatValue) / 100) / (1 + this.vat.floatValue / 100)))
                            // this.formRef.current.instance.getEditor('levy').option('value', Math.round((this.levy.floatValue / 100) * val))
                            // this.formRef.current.instance.getEditor('vatLevy').option('value', Math.round((this.vatLevy.floatValue / 100) * val))
                            // this.formRef.current.instance.getEditor('clearingHouseLevy').option('value', Math.round((this.clrHs.floatValue / 100) * val))
                        }
                    },
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill amount transaction"
                    }
                ]
            },
            {
                dataField: 'commission',
                label: {
                    text: 'Komisi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    onValueChanged: async (e) => {
                        var val = e.value
                        if (val) {
                            this.formRef.current.instance.getEditor('vat').option('value', Math.round(val * this.vat.floatValue / 100))
                        }
                    },
                    step: 0
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill commission"
                    }
                ]
            },
            {
                dataField: 'vat',
                label: {
                    text: 'Vat'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill VAT"
                    }
                ]
            },
            {
                dataField: 'levy',
                label: {
                    text: 'Levy'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    onValueChanged: async (e) => {
                        var val = e.value;
                        if (val) {
                            let commission = await this.formRef.current.instance.getEditor('commission').option('value')
                            if (this.brokerCode == "GR") {
                                this.formRef.current.instance.getEditor('whtPph23').option('value', Math.floor((this.wht.floatValue / 100) * (commission + val)))
                                return
                            }

                            this.formRef.current.instance.getEditor('whtPph23').option('value', Math.floor((this.wht.floatValue / 100) * commission))
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill Levy"
                    }
                ]
            },
            {
                dataField: 'vatLevy',
                label: {
                    text: 'VAT Levy'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill VAT Levy"
                    }
                ]
            },
            {
                dataField: 'clearingHouseLevy',
                label: {
                    text: 'Clearing House Levy'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill Clearing House Levy"
                    }
                ]
            },
            {
                dataField: 'pph',
                label: {
                    alignment: 'left',
                    text: 'Sales Tax'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill Sales Tax"
                    }
                ]
            },
            {
                dataField: 'whtPph23',
                label: {
                    alignment: 'left',
                    text: 'PPH 23'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: async (e) => {
                        var val = e.value
                        if (val) {
                            let amountTrs = await this.formRef.current.instance.getEditor('amountTrs').option('value')
                            let commission = await this.formRef.current.instance.getEditor('commission').option('value')
                            let levy = await this.formRef.current.instance.getEditor('levy').option('value')
                            let vat = await this.formRef.current.instance.getEditor('vat').option('value')
                            let vatLevy = await this.formRef.current.instance.getEditor('vatLevy').option('value')
                            let clrHs = await this.formRef.current.instance.getEditor('clearingHouseLevy').option('value')
                            let pph = await this.formRef.current.instance.getEditor('pph').option('value')
                            let whtPph23 = await this.formRef.current.instance.getEditor('whtPph23').option('value')
                            let biayaMaterai = await this.formRef.current.instance.getEditor('biayaMaterai').option('value')

                            this.formRef.current.instance.getEditor('amountNet').option('value', Math.round(amountTrs + (commission + levy + vat + vatLevy + clrHs) - pph - whtPph23 + biayaMaterai))
                        }
                    },
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill PPH 23"
                    }
                ]
            },
            {
                dataField: 'amountNet',
                label: {
                    alignment: 'left',
                    text: 'Nilai Transaksi Bersih'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill Netting Amount"
                    }
                ]
            },
            {
                itemType: 'empty'
            },
            {
                dataField: 'selectBiayaMaterai',
                label: {
                    visible: false
                },
                editorType: 'dxCheckBox',
                editorOptions: {
                    text: 'Checklist bila menggunakan biaya materai',
                    onValueChanged: (e) => {
                        if (e.value) {
                            this.formRef.current.instance.getEditor('biayaMaterai').option('readOnly', false)
                        } else {
                            this.formRef.current.instance.getEditor('biayaMaterai').option('readOnly', true)
                        }
                    },
                }
            },
            {
                dataField: 'biayaMaterai',
                label: {
                    alignment: 'left',
                    text: 'Biaya Materai'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    readOnly: true,
                    onValueChanged: (e) => {
                        var val = e.value
                        var amountNet = this.formRef.current.instance.getEditor('amountNet').option('value')

                        this.formRef.current.instance.getEditor('amountNet').option('value', Math.round(amountNet + val))
                    },
                }
            }
        ]
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    show = async (data) => {
        this.brokerCode = data.brokerCode
        var dataBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `brokers/${data.brokerId}`, 'GET')
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                orderDate: formatDate(data.orderDate),
                productId: data.productId,
                kpdId: data.kpdId,
                brokerId: data.brokerId,
                orderCode: data.orderCode,
                orderStokId: data.orderStokId,
                transactionName: data.transactionName,
                instrumentId: data.instrumentId,
                quantity: data.quantity,
                price: data.price,
                amountTrs: data.amountTrs,
                commission: data.commission,
                levy: data.levy,
                vat: data.vat,
                pph: data.pph,
                vatLevy: data.vatLevy,
                clearingHouseLevy: data.cleareingHouseLevy,
                whtPph23: data.whtPph23,
                amountNet: data.amountNet,
                feeBuy: dataBroker.feeBuy,
                feeBuy: dataBroker.feeBuy,
            },
        });
        var selectOrderId = this.formRef.current.instance.getEditor('orderStokId')
        var selectInstrumentId = this.formRef.current.instance.getEditor('instrumentId')
        if (this.state.dataMaster.kpdId == 1) {
            selectOrderId.option('dataSource', stokNeedConfirmByBroker(this.props.store, this.state.dataMaster.brokerId, this.state.dataMaster.productId, this.state.dataMaster.orderDate))
            selectOrderId.option('readOnly', false)
            selectInstrumentId.option('readOnly', true)
            this.formRef.current.instance.getEditor('transactionName').option('value', null)
        } else {
            selectOrderId.option('dataSource', [])
            selectOrderId.option('readOnly', true)
            selectInstrumentId.option('readOnly', false)
            this.formRef.current.instance.getEditor('transactionName').option('value', 'Stock Buy')
        }
    }

    hide() {
        this.brokerCode = ""
        this.setState({
            popupVisible: false,
            dataMaster: {},
            actionType: null,
            noOrderDataSource: {}
        })
    }

    submitData = () => {
        var data = this.state.dataMaster
        this.props.submitDetailPenempatanData({
            id: data.id,
            orderStokId: data.orderStokId,
            transactionName: data.transactionName,
            instrumentId: data.instrumentId,
            quantity: data.quantity,
            price: data.price,
            amountTrs: data.amountTrs,
            commission: data.commission,
            levy: data.levy,
            vat: data.vat,
            pph: data.pph,
            vatLevy: data.vatLevy,
            cleareingHouseLevy: data.clearingHouseLevy,
            whtPph23: data.whtPph23,
            amountNet: data.amountNet,
            orderCode: this.state.orderCode,
            biayaMaterai: data.biayaMaterai
        })

        this.hide()
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Saham'}
                width={'1000px'}
                height={'300px'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <Form
                    colCount={3}
                    ref={this.formRef}
                    id={'formDataMaster'}
                    formData={this.state.dataMaster}
                    items={this.items}
                    labelLocation={"left"}
                />
            </Popup>
        )
    }
}

export default ModalActionSettlementTransaksiSaham
