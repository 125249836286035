import React, { Component } from 'react'
import { Form, Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import { participants } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDate, formatDefaultDate, generateExcelFile, PrintElem } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';

class ReportPengkinianBackoffice extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            src: ''
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.formRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField:'greaterTanggalPerubahan',
                                label:{
                                    text: 'Tanggal Perubahan',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField:'lessTanggalPerubahan',
                                label:{
                                    text: 'S/D',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    // {
                    //     dataField:'tipePerubahan',
                    //     label:{
                    //         text: 'Tipe Perubahan',
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         placeholder: ' ',
                    //         dataSource: [
                    //             {
                    //                 id: 1,
                    //                 value: 'Registrasi Aktif'
                    //             },
                    //             {
                    //                 id: 2,
                    //                 value: 'Pengkinian Data'
                    //             },
                    //             {
                    //                 id: 3,
                    //                 value: 'Registrasi Pensiun'
                    //             },
                    //             {
                    //                 id: 4,
                    //                 value: 'Semua'
                    //             },
                    //         ],
                    //         valueExpr: "id", // contains the same values as the "statusId" field provides
                    //         displayExpr: "value", // provides display values
                    //         searchEnabled: true,
                    //         deferRendering: false,
                    //     }
                    // },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-view-back-offices?`

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        // Tanggal Perubahan
        if(filter.greaterTanggalPerubahan){
            loadAPI = loadAPI+`pengkinianDate.greaterThanOrEqual=${formatDate(filter.greaterTanggalPerubahan)}&`
        }

        if(filter.lessTanggalPerubahan){
            loadAPI = loadAPI+`pengkinianDate.lessThanOrEqual=${formatDate(filter.lessTanggalPerubahan)}&`
        }

        var getDataIntegrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        for(var value of getDataIntegrasi){
            value.pengkinianDate = value.pengkinianDate ? formatDefaultDate(value.pengkinianDate) : '-'
            value.updatedDate = value.updatedDate ? formatDefaultDate(value.updatedDate) : '-'
            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
        }

        var tipePerubahan = filter.tipePerubahan

            if(tipePerubahan === 1){
                tipePerubahan = 'Registrasi Aktif'
            }else if(tipePerubahan === 2){
                tipePerubahan = 'Pengkinian Data'
            }else if(tipePerubahan === 3){
                tipePerubahan = 'Registrasi Pensiun'
            }else{
                tipePerubahan = 'Semua'
            }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/pengkinian/backoffice',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                participant: participantName,
                tanggalPerubahan : filter.lessTanggalPerubahan && filter.greaterTanggalPerubahan ? (filter.greaterTanggalPerubahan ? formatDefaultDate(filter.greaterTanggalPerubahan) : "~") + ' S/D ' + (filter.lessTanggalPerubahan ? formatDefaultDate(filter.lessTanggalPerubahan) : "~") : 'Semua',
                tipePerubahan: tipePerubahan,
                dataPeserta : getDataIntegrasi,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))

        this.setState({
            src : responseFile
        })
    }
    
    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `list-view-back-offices?`

        if(filter.participantId){
            loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        // Tanggal Perubahan
        if(filter.greaterTanggalPerubahan){
            loadAPI = loadAPI+`pengkinianDate.greaterThanOrEqual=${formatDate(filter.greaterTanggalPerubahan)}&`
        }

        if(filter.lessTanggalPerubahan){
            loadAPI = loadAPI+`pengkinianDate.lessThanOrEqual=${formatDate(filter.lessTanggalPerubahan)}&`
        }

        var getDataIntegrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        for(var value of getDataIntegrasi){
            value.pengkinianDate = value.pengkinianDate ? formatDefaultDate(value.pengkinianDate) : '-'
            value.updatedDate = value.updatedDate ? formatDefaultDate(value.updatedDate) : '-'
            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
        }

        var tipePerubahan = filter.tipePerubahan

        if(tipePerubahan === 1){
            tipePerubahan = 'Registrasi Aktif'
        }else if(tipePerubahan === 2){
            tipePerubahan = 'Pengkinian Data'
        }else if(tipePerubahan === 3){
            tipePerubahan = 'Registrasi Pensiun'
        }else{
            tipePerubahan = 'Semua'
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/pengkinian/backoffice',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                participant: participantName,
                tanggalPerubahan : filter.lessTanggalPerubahan && filter.greaterTanggalPerubahan ? (filter.greaterTanggalPerubahan ? formatDefaultDate(filter.greaterTanggalPerubahan) : "~") + ' S/D ' + (filter.lessTanggalPerubahan ? formatDefaultDate(filter.lessTanggalPerubahan) : "~") : 'Semua',
                tipePerubahan: tipePerubahan,
                dataPeserta : getDataIntegrasi,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'DAFTAR PERUBAHAN VIA BACKOFFICE'
            }
        }
        // store.dispatch(showLoading(false))
        // var response = await reportRequest(this.props.store,data)
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'DAFTAR PERUBAHAN VIA BACKOFFICE')
        store.dispatch(showLoading(false))

        return responseFile
    }

    exportExcel = async() => {
        try {
            var data = []
            let store = this.props.store
            store.dispatch(showLoading(true))
            var filter = this.state.formFilter
            var loadAPI = `list-view-back-offices?`

            if(filter.participantId){
                loadAPI = loadAPI+`participantId.in=${filter.participantId}&`
                try {
                    var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
                } catch (error) {
                    var participantName = 'Semua'
                }
            }else{
                var participantName = 'Semua'
            }

            // Tanggal Perubahan
            if(filter.greaterTanggalPerubahan){
                loadAPI = loadAPI+`pengkinianDate.greaterThanOrEqual=${formatDate(filter.greaterTanggalPerubahan)}&`
            }

            if(filter.lessTanggalPerubahan){
                loadAPI = loadAPI+`pengkinianDate.lessThanOrEqual=${formatDate(filter.lessTanggalPerubahan)}&`
            }

            var getDataIntegrasi = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

            var tipePerubahan = filter.tipePerubahan

            if(tipePerubahan === 1){
                tipePerubahan = 'Registrasi Aktif'
            }else if(tipePerubahan === 2){
                tipePerubahan = 'Pengkinian Data'
            }else if(tipePerubahan === 3){
                tipePerubahan = 'Registrasi Pensiun'
            }else{
                tipePerubahan = 'Semua'
            }

            var index = 1
            for(var value of getDataIntegrasi){
                var dataValue = {
                    id: index++,
                    // participantName: value.participantName,
                    nik: value.nik,
                    fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                    pengkinianDate: value.pengkinianDate ? formatDefaultDate(value.pengkinianDate) : '-',
                    statusName: value.statusName,
                    noSk: value.noSk,
                    updatedNote: value.updatedNote,
                    // updatedDate: value.updatedDate ? formatDefaultDate(value.updatedDate) : '-'
                }
    
                const objectValue = Object.values(dataValue)
    
                data.push(objectValue)
            }

            try{
                const workbook = new Excel.Workbook();
                var worksheet = workbook.addWorksheet('DAFTAR PERUBAHAN VIA BACKOFFICE');
                worksheet.addTable({
                  name: 'MyTable',
                  ref: 'A1',
                  headerRow: true,
                  totalsRow: false,
                  style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                    // border: {
                    //     top: {style:'thin'},
                    //     left: {style:'thin'},
                    //     bottom: {style:'thin'},
                    //     right: {style:'thin'}
                    // }
                  },
                  columns: [
                    {name: 'No'},
                    // {name: 'Pemberi Kerja'},
                    {name: 'NIK'},
                    {name: 'Nama'},
                    {name: 'Tanggal Perubahan'},
                    {name: 'Status Peserta'},
                    {name: 'Nomor SK'},
                    {name: 'Keterangan'},
                    // {name: 'Tanggal Update'},
                  ],
                  rows: data,
                });

                worksheet.headerFooter.differentFirst = true;
                worksheet.headerFooter = {
                    firstHeader: `&C&BDAFTAR PERUBAHAN VIA BACKOFFICE\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    oddHeader: `&C&BDAFTAR PERUBAHAN VIA BACKOFFICE\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                    evenHeader: `&C&BDAFTAR PERUBAHAN VIA BACKOFFICE\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
                }

                worksheet.pageSetup = {
                    fitToPage: true,
                    margins: {
                        bottom: 0.75,
                        footer: 0.3,
                        header: 0.3,
                        left: 0.7,
                        right: 0.7,
                        top: 0.75
                    }
                }

                // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

                // worksheet.getCell('A3').value = 'Kode'
                // worksheet.getCell('B3').value = ': KPS-2-02'

                // worksheet.getCell('A3').value = 'Laporan'
                // worksheet.getCell('B3').value = ': DAFTAR PENGKINIAN VIA BACKOFFICE'

                // worksheet.getCell('A4').value = 'Tanggal Laporan'
                // worksheet.getCell('B4').value = `: ${formatDefaultDate(new Date())}`

                // worksheet.getCell('A7').value = 'Pemberi Kerja'
                // worksheet.getCell('B7').value = `: ${participantName}`
                
                // worksheet.getCell('A5').value = 'Tanggal Perubahan'
                // worksheet.getCell('B5').value = `: ${filter.lessTanggalPerubahan && filter.greaterTanggalPerubahan ? (filter.greaterTanggalPerubahan ? formatDefaultDate(filter.greaterTanggalPerubahan) : "~") + ' S/D ' + (filter.lessTanggalPerubahan ? formatDefaultDate(filter.lessTanggalPerubahan) : "~") : 'Semua'}`

                worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('H1').alignment = {vertical: 'middle', horizontal: 'center'};
                // worksheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center'};

                for(var i = 2; i <= 10000; i++){
                    worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                    worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                    // worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                }

                var WidthColums = 20;
                
                const Data1 = worksheet.getColumn(1);
                Data1.width = WidthColums;

                const Data2 = worksheet.getColumn(2);
                Data2.width = WidthColums;

                const Data3 = worksheet.getColumn(3);
                Data3.width = 40;

                const Data4 = worksheet.getColumn(4);
                Data4.width = WidthColums;

                const Data5 = worksheet.getColumn(5);
                Data5.width = WidthColums;

                const Data6 = worksheet.getColumn(6);
                Data6.width = WidthColums;

                const Data7 = worksheet.getColumn(7);
                Data7.width = 60;

                const Data8 = worksheet.getColumn(8);
                Data8.width = 60;

                // const Data9 = worksheet.getColumn(9);
                // Data9.width = WidthColums;
                
                await workbook.xlsx.writeBuffer().then(function(buffer) {
                    saveAs(
                        new Blob([buffer], { type: "application/octet-stream" }),
                        `DAFTAR PERUBAHAN VIA BACKOFFICE.xlsx`
                    );
                });
            }catch(e){
                console.log(e)
            }
            store.dispatch(showLoading(false))

            // var headerNameMapping = [
            //     {
            //         from:"id",
            //         to:"No"
            //     },
            //     {
            //         from:"participantName", 
            //         to:"Pemberi Kerja"
            //     },
            //     {
            //         from:"nik", 
            //         to:"NIK"
            //     },
            //     {
            //         from:"fullName", 
            //         to:"Nama"
            //     },
            //     {
            //         from:"pengkinianDate", 
            //         to:"Tanggal Perubahan"
            //     },
            //     {
            //         from:"statusPeserta", 
            //         to:"Status Peserta"
            //     },
            //     {
            //         from:"noSk", 
            //         to:"SK No"
            //     },
            //     {
            //         from:"updatedNote", 
            //         to:"Keterangan"
            //     },
            //     {
            //         from:"updatedDate", 
            //         to:"Tanggal Update"
            //     }
            // ]

            // var header = [
            //     {
            //         A: 'DANA PENSIUN PEGADAIAN'
            //     },
            // ]
            
            // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

            // var title = [
            //     {
            //         A: 'Kode',
            //         B: null,
            //         C: ':',
            //         D: 'KPS-2-02'
            //     },
            //     {
            //         A: 'Laporan',
            //         B: null,
            //         C: ':',
            //         D: 'DAFTAR PENGKINIAN VIA BACKOFFICE'
            //     },
            //     {
            //         A: 'Tanggal Laporan',
            //         B: null,
            //         C: ':',
            //         D: formatDefaultDate(new Date())
            //     },
            // ]

            // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

            // var subTitle = [
            //     {
            //         A: 'Pemberi Kerja',
            //         B: null,
            //         C: ':',
            //         D: participantName
            //     },
            //     {
            //         A: 'tipePerubahan',
            //         B: null,
            //         C: ':',
            //         D: filter.tipePerubahan
            //     }
            // ]

            // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
            // var instructions = []
        
            // for(var InstructionData of getDataIntegrasi){
            //     var row = {}
            //     for(var column in InstructionData){
            //         var headerMapping = headerNameMapping.find(value => value.from == column)
            //         if(headerMapping){
            //             row[headerMapping.to] = InstructionData[column]
            //         }
            //     }
            //     instructions.push(row)
            // }

            // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

            // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
            // store.dispatch(showLoading(false))
            // generateExcelFile(excelBlob,'LAPORAN_DATA_PENGKINIAN_BACKOFFICE')
        } catch (e) {
            let store = this.props.store
            store.dispatch(showLoading(false))
            console.log(e)
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Daftar Perubahan Via Backoffice </h4>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.formFilter}
                            items={this.filterItem}
                            labelLocation = 'left'
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPengkinianBackoffice