import { combineReducers } from 'redux'
import { createStore } from 'redux';

//reducers
import urls from './reducers/urls';
import {sessionUser, sessionToken,sessionRole} from './reducers/session'
import showloading from './reducers/loading'
import menu from './reducers/menu'
import getParam from './reducers/gblparam'

function saveToLocalStorage(state){
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state',serializedState)
    } catch (e) {
        console.log(e)
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if(serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        console.log(e)
        return undefined
    }   
}

const persistedState = loadFromLocalStorage()

const rootReducer  = combineReducers({
    urls,
    sessionToken,
    sessionUser,
    sessionRole,
    sessionUser,
    showloading,
    menu,
    getParam
  })

const store = createStore(
    rootReducer,
    persistedState
)

store.subscribe(() => saveToLocalStorage(store.getState()))

export default store