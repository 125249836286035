import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ShowModalPengkinianPesertaRegistrasiAktif from 'components/pages/modal/kepesertaan/pengkinianPeserta/aktif/showModalRegistrasiAktif';
import { addURL } from 'redux/actions/url';
import { statusPeserta } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';

class PengkinianRiwayatOnline extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
            DataPengajuan: {},
            DataPemeriksaan: {},
            DataPersetujuan: {}
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.DataGridPemeriksaanRef = React.createRef()
        this.DataGridPersetujuanRef = React.createRef()
        this.popupPesertaRegistrasiAktif = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPengkinianPesertaRegistrasiAktif(e.row.data.pesertaId,e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'entryDate',
                caption: 'Tanggal Pengkinian',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pesertaStatusId',
                caption: 'Peserta Status',
                lookup: {
                    dataSource: statusPeserta(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName',
                }
            },
            {
                dataField: 'updatedNote',
                caption: 'Keterangan'
            },
            {
                dataField: 'statusCode',
                caption: 'Status Pengkinian',
                lookup: {
                    dataSource: [
                        {
                            id: 2,
                            value: 'Diterima'
                        },
                        {
                            id: 3,
                            value: 'Ditolak'
                        },                        
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
        ]
    }

    renderButton = (button) => {
        return <div style={{ padding: 20 }}><p>{button.text}</p></div>;
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengkinianPesertaRegistrasiAktif = (pesertaId,dataPengkinian) => {
        this.popupPesertaRegistrasiAktif.current.show()
        this.popupPesertaRegistrasiAktif.current.retrieveData(pesertaId,dataPengkinian)
    }
    showModalInsert = () => {
        this.modalRef.current.show()
    }
    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                pesertaId: 1011,
                tanggalPengkinian: '2020-07-16',
                nik: '31740110203948',
                nama: 'Nama Test',
                cardNoPpmp: '000001',
                cardNoPpip: '000002',
                media: 'Media Test',
                pengkinianTypeId: 1,
                formulirNo: '000003',
                skNo: '000004',
                typeSK: 'Type Test',
                statusPengkinian: 'Status Test',
            }
        ]
        return dummyData
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    kirim = async(data) => {
        var dataPengkinian = data
        dataPengkinian.updatedDate = formatDate(new Date())
        dataPengkinian.pengkinianDate = formatDate(new Date())
        dataPengkinian.updatedStatus = true
        if(dataPengkinian){
            try {
                var updateAPI = 'pengkinian-peserta-bo-aktifs'                    
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                        key: dataPengkinian.id,
                        values:dataPengkinian
                    },
                dataPengkinian)

                var text = `Data berhasil dikirim!`
                var type = 'success'

                notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                this.forceRefresh()
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        }
    }

    insertData = async(data) => {
        var dataPengkinian = data
        dataPengkinian.pengkinianTypeId = 2351
        dataPengkinian.pesertaStatusId = 3052
        dataPengkinian.statusCode = 2
        dataPengkinian.online = false
        dataPengkinian.updatedStatus = false

        try {
            var loadAPI = 'pengkinian-peserta-bo-aktifs'
    
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: dataPengkinian
            })
            var text = `Data berhasil ditambah!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }


    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Riwayat Pengkinian Online</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='pengkinian-onlines/getAllPengkinianOnlinesByStatusCode/2,3?size=99999'
                        insertAPI='pengkinian-onlines'
                        updateAPI='pengkinian-onlines'
                        deleteAPI='pengkinian-onlines'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ShowModalPengkinianPesertaRegistrasiAktif
                    ref={this.popupPesertaRegistrasiAktif}
                    actionType={'pengajuan'}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default PengkinianRiwayatOnline
