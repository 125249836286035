import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { mpType, pesertaById, product,rekBank } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import httpRequest from 'plugin/httprequest';
import { alert, confirm} from 'devextreme/ui/dialog';
import { compactNumber, formatDate, pembulatan } from 'plugin/helper';

class ModalActionRapel extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.kepersertaanProductId = 0
        this.pesertaId = 0

        this.rapelTypeId = 0
        this.penambahanPajak = 0;
        this.totalMpSebRapel = 0;
        this.totalNominalRapel = 0;
        this.pajakSebelumnya = 0;

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    'visible': this.props.actionType === 'lihat' ? false : true,
                    onClick: () => {
                        // console.log(this.state.formData)
                        let formValidate = this.validate();
                        if (formValidate) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]
        
        this.dataMaster = [
            {
                itemType: 'group',
                caption: 'Peserta Pensiun',
                items: [
                    {
                        dataField: 'rapelNo',
                        label: {
                            alignment: 'left',
                            text: 'No Rapel'
                        },
                        editorOptions: {
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'productCode', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        },
                    },
                    {
                        dataField: 'rapelDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Rapel'
                        },
                        editorType: 'dxDateBox',
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal rapel harus diisi'
                            }
                        ],
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'periodePembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Periode Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Periode pembayaran harus diisi'
                            }
                        ],
                        editorOptions: {
                            displayFormat: 'MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                            calendarOptions: {
                                zoomLevel: 'year',
                                maxZoomLevel: 'year',
                                minZoomLevel: 'year',
                            },
                        }
                    },
                    {
                        dataField: 'rapelTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Rapel'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Rapel dibayar bersama MP Bulanan'
                                },
                                {
                                    id: 2,
                                    value: 'Rapel terpisah'
                                },
                            ],
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.penambahanPajak = this.formRef.current.instance.getEditor('penambahanPajak').option('value');
                                    this.totalMpSebRapel = this.formRef.current.instance.getEditor('nominalMpSebelum').option('value');
                                    this.totalNominalRapel = this.formRef.current.instance.getEditor('nominalRapelTotal').option('value');
                                    this.pajakSebelumnya = this.formRef.current.instance.getEditor('pajakSebelum').option('value') === undefined ? 0 : 0;
    
                                    this.rapelTypeId = e.selectedItem.id;

                                    let resultMpBulanan = (this.totalMpSebRapel + this.totalNominalRapel) - (this.pajakSebelumnya + this.penambahanPajak);
                                    let resultTerpisah = this.totalNominalRapel - this.penambahanPajak;

                                    if (this.rapelTypeId === 1) {
                                        this.formRef.current.instance.updateData({ nominalSetelahPajak: resultMpBulanan});
                                    } else if (this.rapelTypeId === 2) {
                                        this.formRef.current.instance.updateData({ nominalSetelahPajak: resultTerpisah});
                                    }
                                }
                            },
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values'
                            searchEnabled: true,
                            deferRendering: true,
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Jenis rapel harus dipilih'
                            }
                        ]
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Keterangan'
                        },
                        editorOptions: {
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Keterangan tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Rapel',
                items: [
                    {
                        dataField: 'mpTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Manfaat Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpType(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'mpName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        },
                    },
                    {
                        dataField: 'rapelPeriodeBulanDari',
                        label: {
                            alignment: 'left',
                            text: 'Periode Rapel Dari'
                        },
                        editorType: 'dxDateBox',
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Periode rapel dari harus diisi'
                            }
                        ],
                        editorOptions: {
                            displayFormat: 'MMM yyyy',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'rapelPeriodeBulanSampai',
                        label: {
                            alignment: 'left',
                            text: 'Periode Rapel Sampai'
                        },
                        editorType: 'dxDateBox',
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Periode rapel sampai harus diisi'
                            }
                        ],
                        editorOptions: {
                            displayFormat: 'MMM yyyy',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'nominalMpSebelum',
                        label: {
                            alignment: 'left',
                            text: 'Total MP Sebelum Rapel'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            // format: '#,##0',
                            readOnly: true,
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                            onValueChanged: (e) => {
                                // let r = Math.round(e.value);

                                // console.log(r);
                                // return r;
                            }
                        }
                    },
                    {
                        dataField: 'nominalRapelTotal',
                        label: {
                            alignment: 'left',
                            text: 'Total Nominal Rapel'
                        },
                        
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: async(e) => {
                                this.penambahanPajak = this.formRef.current.instance.getEditor('penambahanPajak').option('value');
                                this.totalMpSebRapel = this.formRef.current.instance.getEditor('nominalMpSebelum').option('value');
                                this.totalNominalRapel = e.value;
                                this.pajakSebelumnya = this.formRef.current.instance.getEditor('pajakSebelum').option('value') === undefined ? 0 : 0;
    

                                let resultMpBulanan = (this.totalMpSebRapel + this.totalNominalRapel) - (this.pajakSebelumnya + this.penambahanPajak);
                                let resultTerpisah = this.totalNominalRapel - this.penambahanPajak;

                                if (this.rapelTypeId === 1) {
                                    this.formRef.current.instance.updateData({ nominalSetelahPajak: resultMpBulanan});
                                } else if (this.rapelTypeId === 2) {
                                    this.formRef.current.instance.updateData({ nominalSetelahPajak: resultTerpisah});
                                } 
                                // else {
                                //     await alert('Silahkan pilih jenis rapel untuk mendapatkan nilai setelah pajak', 'Gagal!');
                                //     return false;
                                // }
                            },
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        }
                    },
                    {
                        dataField: 'pajakSebelum',
                        label: {
                            alignment: 'left',
                            text: 'Pajak Sebelumnya'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'penambahanPajak',
                        label: {
                            alignment: 'left',
                            text: 'Penambahan Pajak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: async(e) => {
                                this.penambahanPajak = e.value;
                                this.totalMpSebRapel = this.formRef.current.instance.getEditor('nominalMpSebelum').option('value');
                                this.totalNominalRapel = this.formRef.current.instance.getEditor('nominalRapelTotal').option('value');
                                this.pajakSebelumnya = this.formRef.current.instance.getEditor('pajakSebelum').option('value') === undefined ? 0 : 0;

                                let resultMpBulanan = (this.totalMpSebRapel + this.totalNominalRapel) - (this.pajakSebelumnya + this.penambahanPajak);
                                let resultTerpisah = this.totalNominalRapel - this.penambahanPajak;

                                if (this.rapelTypeId === 1) {
                                    this.formRef.current.instance.updateData({ nominalSetelahPajak: resultMpBulanan});
                                } else if (this.rapelTypeId === 2) {
                                    this.formRef.current.instance.updateData({ nominalSetelahPajak: resultTerpisah});
                                } 
                                // else {
                                //     await alert('Silahkan pilih jenis rapel untuk mendapatkan nilai setelah pajak', 'Gagal!');
                                //     return false;
                                // }
                            },
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Penambahan pajak tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'nominalSetelahPajak',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Setelah Pajak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'paymentRekbankId',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Bayar'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'rekName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                            onSelectionChanged: (data) => {
                                if(data.selectedItem) {
                                    var inputFeeBank = this.formRef.current.instance.getEditor('feeBank')
                                    inputFeeBank.option('value', data.selectedItem.feeBank)
                                }
                            }
                        },
                    },
                    {
                        dataField: 'feeBank',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Bank'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        }
                    },
                ]
            },
        ]
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        console.log(this.props.actionType);
        this.setState({
            popupVisible: true,         
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    retrieveData = async(data) => {
        var loadAPI = `kepersertaan-products/${data.kepersertaanProductId}`
        var getKepersertaanProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        var loadAPIRapel = `pembayaran-rapels/${data.pembayaranRapelId}`
        var getRapel = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIRapel, 'GET')

        // var loadAPI = `peserta-pensiuns/loadDataRegistPensiun/${data.nik}`
        // var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
      
        this.kepersertaanProductId = getKepersertaanProduct.id
        this.pesertaId = getKepersertaanProduct.pesertaId
        this.dropDownBoxRef.current.instance.option('value',getKepersertaanProduct.pesertaId)
        this.setState({
            formData: getRapel
        })

        this.formRef.current.instance.getEditor('nominalMpSebelum').option('value', getRapel.nominalMpSebelum - 32.75);
        this.formRef.current.instance.updateData({
            periodePembayaran: `${getRapel.transactionPeriodeTahun}-${getRapel.transactionPeriodeBulan}-1`, 
        })
    }

    submitData = async() => {
        var dataSubmit = {...this.state.formData}
        dataSubmit.kepersertaanProductId = this.kepersertaanProductId

        let actionType = this.props.actionType;
        let periodebulan = formatDate(dataSubmit.periodePembayaran);
        let splitPreiode = periodebulan.split('-');
        let transactionPeriodeBulan = parseInt(splitPreiode[1]);
        let transactionPeriodeTahun = parseInt(splitPreiode[0]);

        let loadAPI = `pengalihan-mps/getValidasiPotRapel/1/${transactionPeriodeBulan}/${transactionPeriodeTahun}/${dataSubmit.kepersertaanProductId}`;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');
        
        if (actionType !== 'add') {
            await this.props.submitData(dataSubmit);
            this.hide();
        } else {
            if (response[0].statusDescription !== null) {
                let result = await confirm(response[0].statusDescription, 'Konfirmasi');
                if (result) {
                    await this.props.submitData(dataSubmit)
                    this.hide()             
                }
            } else {
                await this.props.submitData(dataSubmit);
                this.hide();
            }
        }
    }

    onSelectionChanged = async(e) => {
        this.kepersertaanProductId = e.selectedRowKeys[0]
        this.pesertaId = e.selectedRowsData[0].pesertaId

        var loadAPI = `kepersertaan-products/${e.selectedRowKeys[0]}`
        var getKepersertaanProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        var data = {...this.state.formData}
        // data.nominalMpSebelum = pembulatan(getKepersertaanProduct.mpBulanan, 2)
        data.nominalMpSebelum = getKepersertaanProduct.mpBulanan
        data.pajakSebelum = getKepersertaanProduct.pph21
        data.paymentRekbankId = getKepersertaanProduct.paymentRekbankId
        
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowsData[0].pesertaId)
        this.formRef.current.instance.updateData(data)
    }

    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI= {'view-lookup-kepersertaan-products?akunStatusId.in=2&akunStatusId.in=5&akunStatusId.in=7'}
                insertAPI='kepersertaan-product'
                updateAPI='kepersertaan-product'
                deleteAPI='kepersertaan-product'

                backendserver={process.env.REACT_APP_BACKEND_CORE}
                
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Lookup Peserta Pensiun"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Lookup Peserta Pensiun'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Rapel Manfaat Pensiun'} 
                minWidth={500}
                minHeight={300}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid" style={{height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Peserta Pensiun</label>
                                <div className="col-sm-10">
                                    <DropDownBox
                                        ref={this.dropDownBoxRef}
                                        valueExpr="id"
                                        deferRendering={false}
                                        readOnly={this.props.actionType === 'lihat' ? true : false}
                                        displayExpr={(item) => {
                                            return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                        }}
                                        showClearButton={true}
                                        dataSource={pesertaById(this.props.store)}
                                        onValueChanged={this.onValueChanged}
                                        contentRender={this.renderDatagrid}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataActionRapel'}
                                formData={this.state.formData}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation = 'left'
                                readOnly = {this.props.actionType == 'view' ? true : false}
                            />
                        </div>
                    </div>

                </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ModalActionRapel