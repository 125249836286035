import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { download, formatDate, formatDefaultDate, formatDefaultFullDate, formatUploadFileData, formatUploadFileNameAndExtention, imageSource, yearsDiff } from 'plugin/helper';
import { banks, pensiunType, product, rekBank, statusPegawai } from 'dataSource/lookup';
import { FileUploader, ScrollView } from 'devextreme-react';
import ModalPreviewImage from '../../../compPeserta/previewImage';
import ModalPreviewPdf from '../../../compPeserta/previewPdf';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';

class ProsesDataDplk extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            pesertaId: 0,
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.pesertaId = 0;

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        // await alert('Under Development', 'Error')
                        // // var formValidate = this.validate()
                        // // if (formValidate) {
                        // //     await this.props.submitData(this.state.dataMaster)

                        // //     this.hide()
                        // // }
                        const result = await confirm("Apakah anda ingin menyimpan data ini?", "Konfirmasi?");
                        if (result){
                            this.submitData();
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.PopupToolbarItemView = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                itemType: 'group',
                name: 'master',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pensiunType',
                        label: {
                            text: 'Jenis Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true,
                            dataSource: pensiunType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'statusName',
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        dataField: 'skPensiunNo',
                        label: {
                            text: 'No SK Pensiun'
                        },
                    },
                    {
                        dataField: 'skPemberhentianNo',
                        label: {
                            text: 'No SK PHK'
                        },
                    },
                    {
                        dataField: 'pesertaPhkTypeId',
                        label: {
                            text: 'Jenis PHK'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: statusPegawai(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'statusName'
                        }
                    },
                    {
                        dataField: 'tglDialihkan',
                        name: 'tglDialihkan',
                        label: {
                            text: 'Tanggal Dialihkan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            width: '100%'

                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: "required",
                                message: "Data Harus Diisi"
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount: 4,
                        items: [
                            {
                                dataField: 'masaKerja',
                                label: {
                                    text: 'Masa Kerja'
                                },
                                colSpan: 3,
                                editorOptions: {
                                    // readOnly: true,
                                }
                            },
                            {
                                itemType: 'button',
                                horizontalAlignment: 'left',
                                buttonOptions: {
                                    text: 'Kalkulasi',
                                    type: 'secondary',
                                    disabled: this.props.actionType === 'view' ? true : false,
                                    onClick: async (e) => {
                                        var formValidate = this.validate();
                                        if (formValidate){
                                            var dataMaster = this.state.dataMaster;
                                            var pesertaNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${dataMaster.pesertaId}`, 'GET');
    
                                            // var tglBerhenti = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `peserta-kepegawaians/getByPesertaId/${dataMaster.pesertaId}`, 'GET');
                                            
                                            var dataTanggal = formatDate(dataMaster.tglDialihkan);
                                            
                                            var loadAPI = `peserta-pensiuns/calculatePensiunByFunction/2/1/${pesertaNik.nik}/${dataMaster.nsPeriode}/8/${dataTanggal}/1/0/1/${false}/${dataTanggal}`;
                                            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET');
                                            if(response.length > 0){
                                                var masaKerja = response[0].masa_kerja_thn + ' Tahun ' + response[0].masa_kerja_bln + ' Bulan';
                                                var usiaDialihkan = response[0].usia_penerima_thn + ' Tahun ' + response[0].usia_penerima_bln + ' Bulan';
                                                var totalIuran = response[0].mp_sekaligus_100;
                                                var pengembanganIuran = response[0].mp_sekaligus_100 + dataMaster.pengembangan;
    
                                                this.formRef.current.instance.getEditor('masaKerja').option('value', masaKerja);
                                                this.formRef.current.instance.getEditor('usiaSaatDialihkan').option('value', usiaDialihkan);
                                                this.formRef.current.instance.getEditor('totalIuran').option('value', totalIuran);
                                                this.formRef.current.instance.getEditor('nominalPengalihan').option('value', totalIuran);
                                                this.formRef.current.instance.getEditor('iuranPengembangan').option('value', pengembanganIuran);
                                            }
                                        }
                                        
                                        // if (response){
                                        //     dataMaster
                                        // }
                                        // var dataMaster = this.state.dataMaster
                                        // console.log(dataMaster)
                                        // var dataPeserta = this.state.dataPeserta
                                        // console.log(dataPeserta);
                                        // var data = this.state.data
                                        // if (dataMaster.masaKerja) {
                                        //     var mkTahun = parseInt(dataMaster.masaKerja.split('Tahun')[0])
                                        //     var mkBulan = dataMaster.masaKerja.split('Tahun')[1] ? parseInt((dataMaster.masaKerja.split('Tahun')[1]).split('Bulan')[0]) : 0
                                        //     var tahun = this.props.peridoeNs
                                        //     var loadAPI = `peserta-pensiuns/getPerhitunganPensiunWithMasaKerja/${dataMaster.pesertaId}/${dataMaster.productId}/${mkBulan}/${mkTahun}/${tahun}`
                                        //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

                                        //     this.retrieveData(data, response, dataPeserta)
                                        // } else {
                                        //     await alert("Silahkan Isi masa kerja terlebih dahulu!", 'Informasi!')
                                        // }
                                    },
                                },
                            },
                        ]
                    },
                    {
                        dataField: 'usiaSaatDialihkan',
                        label: {
                            text: 'Usia Saat Dialihkan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'totalIuran',
                        label: {
                            text: 'Total Iuran'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'unitPeserta',
                        label: {
                            text: 'Unit Peserta'
                        },
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'pengembangan',
                        label: {
                            text: 'Pengembangan'
                        },
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00',
                        }
                    },
                    {
                        dataField: 'iuranPengembangan',
                        label: {
                            text: 'Iuran + Pengembangan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: false,
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                let iuran = e.value;

                                this.formRef.current.instance.getEditor('nominalPengalihan').option('value', iuran);
                            }
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'pengalihan',
                caption: 'Pengalihan',
                cssClass: 'mt-5',
                items: [
                    {
                        dataField: 'nominalPengalihan',
                        label: {
                            text: 'Nominal Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'tglPembayaranPengalihan',
                        name: 'tanggalTransfer',
                        label: {
                            text: 'Tanggal Transfer'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            width: '100%'
                        },
                    },
                    {
                        dataField: 'dplkTujuan',
                        label: {
                            text: 'DPLK Tujuan'
                        }
                    },
                    {
                        dataField: 'pengalihanRekeningNo',
                        label: {
                            text: 'No Rekening'
                        }
                    },
                    {
                        dataField: 'pengalihanRekeningName',
                        label: {
                            text: 'Nama Rekening'
                        }
                    },
                    {
                        dataField: 'pengalihanRekeningBankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true
                        }
                    },
                    {
                        dataField: 'transferRekbankId',
                        label: {
                            text: 'Rekening Bank Transfer MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'rekName'
                        }
                    },
                ]
            }
        ]
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async () => {
        try {
            let dataMaster = {...this.state.dataMaster};
            
            // return null;
            var pesertaNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${dataMaster.pesertaId}`, 'GET');
            var dataTanggal = formatDate(dataMaster.tglDialihkan);
            var loadAPI = `peserta-pensiuns/calculatePensiunByFunction/2/1/${pesertaNik.nik}/${this.props.peridoeNs}/8/${dataTanggal}/1/0/1/${false}/${dataTanggal}`;
            var responseCalculate = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET');

            // return null;

            let dataMasterValue = {
                masaKerjaTahun: responseCalculate[0].masa_kerja_thn,
                masaKerjaBulan: responseCalculate[0].masa_kerja_bln,
                usiaPensiunTahun: responseCalculate[0].usia_penerima_thn,
                usiaPensiunBulan: responseCalculate[0].usia_penerima_bln,
                pesertaPhkTypeId: dataMaster.pesertaPhkTypeId,
                transferRekBankId: dataMaster.transferRekbankId,
                kepesertaanProductId: dataMaster.id,
                dplkTujuan: dataMaster.dplkTujuan,
                fileSkPensiun: dataMaster.fileSkPensiun,
                nominalPengalihan: dataMaster.nominalPengalihan,
                pengalihanBankId: dataMaster.pengalihanRekeningBankId,
                pengalihanRekName: dataMaster.pengalihanRekeningName,
                pengalihanRekNo: dataMaster.pengalihanRekeningNo,
                skPemberhentianNo: dataMaster.skPemberhentianNo,
                skPensiunNo: dataMaster.skPensiunNo,
                tanggalDialihkan: dataMaster.tglDialihkan,
                tanggalPembayaran: dataMaster.tglPembayaranPengalihan,
                nsPeriode: this.props.peridoeNs
            }

            // // console.log(dataMaster);
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, 'registrasi-pengalihan-dplk', 'POST', {
                values: dataMasterValue
            });

            if (response){
                this.hide();
                this.props.forceRefresh();
                notify({ message: 'Sukses Tambah Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: 'window' } }, 'success', 200);
            }
        } catch (error) {
            console.log(error);
            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: 'window' } }, 'error', 200);
        }
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData: {}
        });
    }

    previewFile = async (fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if (result) {
            var src = imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.dataMaster[`${fileName}ContentType`], this.state.dataMaster[`${fileName}`]), this.state.dataMaster[`${fileName}Name`])
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention
            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}Name`]: formattedFileNameAndExtention.name,
                        [`${fieldName}Extention`]: formattedFileNameAndExtention.extention,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    },
                }))
            }

            fr.readAsDataURL(files)
        }
    }
    retrieveData = async (data = {}) => {
        if (this.props.actionType === 'view'){
            var loadAPI = `kepersertaan-products/${data.id}`
            var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            var keperSaldo = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-product-saldos`, 'GET')
            var pesertaNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${data.pesertaId}`, 'GET');
            var kepesertaanProductId = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `kepersertaan-products/getByPesertaId/${data.pesertaId}`, 'GET');
    
            keperSaldo = keperSaldo.filter(value => value.pesertaId === dataAkun.pesertaId)
            // dataAkun.totalIuran = dataAkun.iuranManfaatLainPeserta + dataAkun.iuranManfaatLainPk + dataAkun.iuranNormalPeserta + dataAkun.iuranNormalPk + dataAkun.iuranSukarelaPeserta
            dataAkun.pensiunType = 8
            dataAkun.totalIuran = dataAkun.nominalPengalihan;
            dataAkun.iuranPengembangan = dataAkun.nominalPengalihan;
            dataAkun.unitPeserta = keperSaldo.saldoUnit || 0
            dataAkun.pengembangan = keperSaldo.nominalPengembangan || 0
            // dataAkun.iuranPengembangan = keperSaldo.saldoUnit + keperSaldo.nominalPengembangan || 0
            dataAkun.usiaSaatDialihkan = pesertaNik.usiaPensiunTahun + ' Tahun ' + pesertaNik.usiaPensiunBulan + ' Bulan ';
            dataAkun.masaKerja = pesertaNik.masaKerjaTahun + ' Tahun ' + pesertaNik.masaKerjaBulan + ' Bulan ';
            dataAkun.transferRekBankId = kepesertaanProductId.transferRekbankId;
    
            // if (dataAkun.tglDitunda && dataAkun.efektifDate) {
            //     var date1 = new Date(dataAkun.efektifDate)
            //     var date2 = new Date(dataAkun.tglDitunda)
            //     var diffYears = yearsDiff(dataAkun.tglDitunda, dataAkun.efektifDate)
            //     var diffMonths = date2.getMonth() - date1.getMonth();
            //     var masaKerja
            //     if (diffYears && diffMonths) {
            //         masaKerja = `${diffYears} Tahun ${diffMonths} Bulan`
            //     }
    
            //     if (!diffYears && diffMonths) {
            //         masaKerja = `${diffMonths} Bulan`
            //     }
    
            //     if (diffYears && !diffMonths) {
            //         masaKerja = `${diffYears} Tahun`
            //     }
    
            //     dataAkun.masaKerja = masaKerja
            // }
            this.setState({
                dataMaster: dataAkun
            })
        } else {
            var loadAPI = `kepersertaan-products/${data.id}`
            var dataAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            var keperSaldo = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-product-saldos`, 'GET')
            var pesertaNik = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${data.pesertaId}`, 'GET');
            var kepesertaanProductId = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `kepersertaan-products/getByPesertaId/${data.pesertaId}`, 'GET');
    
            keperSaldo = keperSaldo.filter(value => value.pesertaId === dataAkun.pesertaId)
            // dataAkun.totalIuran = dataAkun.iuranManfaatLainPeserta + dataAkun.iuranManfaatLainPk + dataAkun.iuranNormalPeserta + dataAkun.iuranNormalPk + dataAkun.iuranSukarelaPeserta
            dataAkun.pensiunType = 8
            dataAkun.totalIuran = dataAkun.nominalPengalihan;
            dataAkun.iuranPengembangan = dataAkun.nominalPengalihan;
            dataAkun.unitPeserta = keperSaldo.saldoUnit || 0
            dataAkun.pengembangan = keperSaldo.nominalPengembangan || 0
         
            dataAkun.transferRekBankId = kepesertaanProductId.transferRekbankId;
    
            this.setState({
                dataMaster: dataAkun
            })
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Proses Data Pengalihan'}
                width={1000}

                ref={this.PopupRef}

                toolbarItems={this.props.actionType == 'view' ? this.PopupToolbarItemView : this.PopupToolbarItem}
            >
                <ScrollView height='100%' width='100%'>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation='left'
                                    readOnly={this.props.actionType === 'view' ? true : false}
                                />
                            </div>
                            <div className="col-md-12">
                                <h5>Dokumen</h5>
                            </div>

                            {/* fileSkPensiun */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSkPensiunName || 'File SK Pensiun'}
                                    showFileList={false}
                                    name={'fileSkPensiun'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSkPensiun')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPensiun ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSkPensiun')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPensiun ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileSkPhk */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSkPhkName || 'File SK PHK'}
                                    showFileList={false}
                                    name={'fileSkPhk'}
                                    disabled={this.props.pensiunan}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile('fileSkPhk')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPhk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('fileSkPhk')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPhk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        );
    }
}


export default ProsesDataDplk