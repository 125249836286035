import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import {  akunHR } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import PPListDetailActionDetailModal from './actionDetail';
import httpRequest from 'plugin/httprequest';

class PPListTransaksiDetailModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.readOnly = ''
        this.PopupRef = React.createRef()
        this.DataGridDetailRef = React.createRef()

        this.listDetail = []

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        
        this.detailColumn = [
            {
                dataField:"id",
                caption:"No. ",
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'akunId',
                caption: 'Akun ID',
                lookup:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName' 
                }
            },
            {
                dataField:"description",
                caption:"Keterangan",
            },
            {
                dataField:"db",
                caption:"DB",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            },
            {
                dataField:"cr",
                caption:"CR",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0'
            }
        ]

        this.summary = [
            {
                showInColumn: 'description',
                displayFormat: 'TOTAL'
            },
            {
              column: 'db',
              summaryType: 'sum',
              valueFormat: '#,##0.00',
              name: 'totalDB',
              displayFormat: '{0}'
            },
            {
              column: 'cr',
              summaryType: 'sum',
              valueFormat: '#,##0.00',
              name: 'totalCR',
              displayFormat: '{0}'
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    newData = (newData) => {
        this.setState({
          newData : newData
        })
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            newData:true
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = async(data) => {
        var responseDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-aktifs-transaction-list-detail/${data.transactionId}`, 'GET')
        this.setState({
            dataMaster: data,
        })
        this.listDetail = responseDetail
        this.DataGridDetailRef.current.forceRefresh(true)
    }

    loadDataDetail = () => {
        return this.listDetail
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Detail Transaksi Pembayaran Umum'}
                width={900}
                height={700}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <DevExpressDataGrid 
                                ref = {this.DataGridDetailRef}
                                loadAPI = 'pembayaran-details'
                                insertAPI = 'pembayaran-details'
                                updateAPI = 'pembayaran-details'
                                deleteAPI = 'pembayaran-details'

                                backendserver = {process.env.REACT_APP_BACKEND_HRD}

                                useArraySource={true}
                                ArraySourceData={this.loadDataDetail}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                showBorders = {true}

                                paging = {true}
                                defaultPageSize = {10}
                                
                                editingMode={'popup'}
                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle = {'Data Detail Biaya'}
                                popupWidth = {500} //masukan dalam ukuran pixel
                                popupHeight = {400} //masukkan dalam ukuran pixel

                                popupFormLabelLocation = 'left' //accepted value = top, left, right
                                popupFormMinColWidth = {300} // minimum lebar kolom
                                popupFormColCount = {1} //jumlah kolom pada form
                                height={400}
                                //akhir bagian konfigurasi popup

                                ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

                                FilterRow = {true} // set false untuk mematikan fitur filter
                                
                                ColumnConfiguration={this.detailColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration = {this.summary}
                                // onToolbarPreparing = {this.onToolbarPreparingDetail}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                                
                                store = {this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default PPListTransaksiDetailModal