import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import ScrollView from '../../../../../../../node_modules/devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import { banks, klaimType, media, months, tipePembayaranMp } from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog';
import ModalPreviewImage from '../../compPeserta/previewImage';
import ModalPreviewPdf from '../../compPeserta/previewPdf';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';

class KlaimProsesBaru extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {},
      dataMaster: {},
      dataDetail: {},
      dataNow: {}

    }

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)

    this.PopupRef = React.createRef()
    this.modalPreviewPdfRef = React.createRef()
    this.modalPreviewImageRef = React.createRef()

    this.popupToolbarItem = [
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Klaim Selesai',
          onClick: () => {
            var data = this.state.dataMaster
            this.props.showModalCatatan(data, 5)
          }
        },
        toolbar: 'bottom',
        visible: this.props.module == 'proses' ? true : false
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Klaim Tolak',
          onClick: () => {
            var data = this.state.dataMaster
            this.props.showModalCatatan(data, 3)
          }
        },
        toolbar: 'bottom',
        visible: this.props.module == 'proses' ? true : false
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Tutup',
          onClick: this.hide.bind(this)
        },
        toolbar: 'bottom',
      }
    ]

    this.formItem = [
      {
        dataField: 'id',
        label: {
          alignment: 'left',
          text: 'No.'
        },
      },
      {
        dataField: 'klaimDate',
        label: {
          alignment: 'left',
          text: 'Tanggal'
        },
        editorType: 'dxDateBox',
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy'
        }
      },
      {
        dataField: 'mediaId',
        label: {
          alignment: 'left',
          text: 'Media'
        },
        editorType: "dxSelectBox",
            editorOptions: {
              dataSource: media(this.props.store),
              displayExpr: 'mediaName',
              valueExpr: 'id',
              searchEnabled: true,
              deferRendering: false,
            }
      },
      {
        dataField: 'fullName',
        label: {
          alignment: 'left',
          text: 'Peserta'
        },
      },
      {
        dataField: 'klaimTypeId',
        label: {
          alignment: 'left',
          text: 'Jenis Klaim'
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: klaimType(this.props.store),
          displayExpr: 'klaimTypeName',
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'keterangan',
        label: {
          alignment: 'left',
          text: 'Catatan Peserta'
        },
      },
    ]

    this.formDetail = [
      {
        dataField: 'mpPaymentTypeId',
        label: {
          text: 'Tipe Pembayaran MP',
          alignment: 'left',
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: tipePembayaranMp(this.props.store),
          displayExpr: 'typeName',
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'mpRekeningNo',
        label: {
          text: 'No Rekening Pembayaran MP',
          alignment: 'left',
        }
      },
      {
        dataField: 'mpRekeningName',
        label: {
          text: 'Nama Rekening Pembayaran MP',
          alignment: 'left',
        }
      },
      {
        dataField: 'mpRekeningBankId',
        label: {
          text: 'Bank Pembayaran MP',
          alignment: 'left',
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: banks(this.props.store),
          valueExpr: 'id',
          displayExpr: 'bankName',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'mpRekeningCabang',
        label: {
          text: 'Cabang Pembayaran MP',
          alignment: 'left',
        }
      },
      {
        dataField: 'mpBulan',
        label: {
          text: 'Periode Bulan Pembayaran',
          alignment: 'left',
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: months(),
          valueExpr: 'id',
          displayExpr: 'value',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'mpTahun',
        label: {
          text: 'Periode Tahun Pembayaran',
          alignment: 'left',
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'buktiDiriFile',
        label : {
          text: 'File Bukti Diri Detail',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'buktiDiriFile',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'buktiDiriFile',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'detail')
            }else{
              this.downloadFile(e.itemData.name , 'detail')
            }
          },
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'fotoKtpFile',
        label : {
          text: 'File KTP Detail',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'fotoKtpFile',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'fotoKtpFile',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'detail')
            }else{
              this.downloadFile(e.itemData.name , 'detail')
            }
          },
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'fotoSkPensiunFile',
        label : {
          text: 'File SK Pensiun Detail',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'fotoSkPensiunFile',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'fotoSkPensiunFile',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'detail')
            }else{
              this.downloadFile(e.itemData.name , 'detail')
            }
          },
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'fotoKtpPenerimaMp',
        label : {
          text: 'File KTP Penerima MP Detail',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'fotoKtpPenerimaMp',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'fotoKtpPenerimaMp',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'detail')
            }else{
              this.downloadFile(e.itemData.name , 'detail')
            }
          },
        }
      }
    ]

    this.formDataNow = [
      {
        dataField: 'mpPaymentTypeId',
        label: {
          text: 'Tipe Pembayaran MP',
          alignment: 'left',
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: tipePembayaranMp(this.props.store),
          displayExpr: 'typeName',
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'mpRekeningNo',
        label: {
          text: 'No Rekening Pembayaran MP',
          alignment: 'left',
        }
      },
      {
        dataField: 'mpRekeningName',
        label: {
          text: 'Nama Rekening Pembayaran MP',
          alignment: 'left',
        }
      },
      {
        dataField: 'mpRekeningBankId',
        label: {
          text: 'Bank Pembayaran MP',
          alignment: 'left',
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: banks(this.props.store),
          valueExpr: 'id',
          displayExpr: 'bankName',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'mpRekeningCabang',
        label: {
          text: 'Cabang Pembayaran MP',
          alignment: 'left',
        }
      },
      {
        dataField: 'mpBulan',
        label: {
          text: 'Periode Bulan Pembayaran',
          alignment: 'left',
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: months(),
          valueExpr: 'id',
          displayExpr: 'value',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'mpTahun',
        label: {
          text: 'Periode Tahun Pembayaran',
          alignment: 'left',
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'buktiDiriFile',
        label : {
          text: 'File Bukti Diri Saat Ini',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'buktiDiriFile',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'buktiDiriFile',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'now')
            }else{
              this.downloadFile(e.itemData.name , 'now')
            }
          },
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'skPensiunFile',
        label : {
          text: 'File SK Pensiun Saat Ini',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'skPensiunFile',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'skPensiunFile',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'now')
            }else{
              this.downloadFile(e.itemData.name , 'now')
            }
          },
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'fotoKtpFile',
        label : {
          text: 'File KTP Saat Ini',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'fotoKtpFile',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'fotoKtpFile',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'now')
            }else{
              this.downloadFile(e.itemData.name , 'now')
            }
          },
        }
      },
      {
        itemType: 'empty'
      },
      {
        dataField : 'fotoKtpPenerimaMp',
        label : {
          text: 'File KTP Penerima MP Saat Ini',
          alignment: 'left'
        },
        editorType: 'dxButtonGroup',
        editorOptions : {
          items: [
            {
              name:'fotoKtpPenerimaMp',
              text: 'Preview',
              alignment: 'left',
              hint: 'Preview',
            },
            {
              name : 'fotoKtpPenerimaMp',
              text: 'Download',
              alignment: 'left',
              hint: 'Download',
            },
          ],
          keyExpr: 'alignment',
          stylingMode: 'outlined',
          selectedItemKeys: ['left'],
          onItemClick : (e) => {
            if(e.itemData.hint == 'Preview'){
              this.previewFile(e.itemData.name , 'now')
            }else{
              this.downloadFile(e.itemData.name , 'now')
            }
          },
        }
      }
    ]

  }

  get Popup() {
    return this.PopupRef.current.instance
  }

  show() {
    this.setState({
      popupVisible: true,
      selectedTabIndex: 0
    });
  }

  hide() {
    this.setState({
      popupVisible: false,
      formData: {},
      dataMaster: {}
    });
  }

  onValueChanged = (e) => {
    var fieldName = e.component._options._optionManager._options.name
    var fieldValue = e.value

    this.setState({
      [fieldName]: fieldValue
    })
  }

  submitData = async () => {
    var dataSubmit = { ...this.state.dataPeserta }

    // console.log(dataSubmit)
    await this.props.submitData(dataSubmit)
    this.hide()
  }

  retrieveData = async (data) => {
    var loadAPI = 'peserta-klaim-detils/findByPesertaKlaimId/'+ data.id
    var getDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')

    var loadAPI = 'peserta-klaim-detils/data-saat-ini/findByPesertaId/'+ data.pesertaId
    var getSaatIni = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
    
    this.setState({
      dataMaster: data,
      dataDetail : getDetail,
      dataNow : getSaatIni
    })
  }

  previewFile = async(fileName, dataSource = 'detail') => {
      var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
      var result = await alert(message, 'Informasi!')
      if(result){
          var data
          var src
          if(dataSource == 'detail'){
            data = this.state.dataDetail
            src = process.env.REACT_APP_BACKEND_CORE + data[fileName]
          }else{
            data = this.state.dataNow
            src = imageSource(data[`${fileName}ContentType`],data[`${fileName}`])
          }
          if(data[fileName]){
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src, 'url')
          }else{
            await alert('File ini kosong', 'Informasi')
          }
      }
  }

  downloadFile = async(fileName, dataSource = 'detail') => {
    var data
    if(dataSource == 'detail'){
      data = this.state.dataDetail
      if(data[fileName]){
        download(process.env.REACT_APP_BACKEND_CORE + data[fileName])
      }else{
        await alert('File ini kosong', 'Informasi')
      }
    }else{
      data = this.state.dataNow
      if(data[fileName]){
        download(imageSource(data[`${fileName}ContentType`],data[`${fileName}`]),data[`${fileName}Name`])
      }else{
        await alert('File ini kosong', 'Informasi')
      }
    }
  }

  render() {
    return (
      <Popup
        className={'popup'}
        visible={this.state.popupVisible}
        onHiding={this.hide}
        dragEnabled={true}
        resizeEnabled={true}
        showTitle={true}
        title={'Form Detail Klaim MP'}
        minWidth={500}
        minHeight={500}

        ref={this.PopupRef}

        toolbarItems={this.popupToolbarItem}
      >
        <ScrollView height="100%" width="100%">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Form
                  colCount={1}
                  id={'formData'}
                  formData={this.state.dataMaster}
                  items={this.formItem}
                  labelLocation="left"
                  readOnly={true}
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: 50 }}>
              <div className="col-md-6" style={{ paddingRight: 100 }}>
                <h5 className="text-center" style={{ fontWeight: "bold" }}>KLAIM DETAIL</h5>
                <Form
                  colCount={1}
                  id={'formDetail'}
                  formData={this.state.dataDetail}
                  items={this.formDetail}
                  labelLocation="left"
                  readOnly={true}
                />
              </div>

              <div className="col-md-6" style={{ paddingLeft: 100 }}>
                <h5 className="text-center" style={{ fontWeight: "bold" }}>DATA SAAT INI</h5>
                <Form
                  colCount={1}
                  id={'formDataNow'}
                  formData={this.state.dataNow}
                  items={this.formDataNow}
                  labelLocation="left"
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </ScrollView>
        <ModalPreviewPdf
            ref={this.modalPreviewPdfRef}
            store={this.props.store}
        />
        <ModalPreviewImage
            ref={this.modalPreviewImageRef}
            store={this.props.store}
        />
      </Popup>
    )
  }
}

export default KlaimProsesBaru