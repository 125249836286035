import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';

import httpRequest from 'plugin/httprequest';
import FormDataKepegawaian from '../../../compPeserta/dataKepegawaian';
import FormDataPersonal from '../../../compPeserta/dataPersonal';
import FormDataAkun from '../../../compPeserta/dataAkun';
import DataGridKeluarga from '../../../compPeserta/dataKeluarga';
import { compareObj } from 'plugin/helper';
import DataGridDokumen from '../../../compPeserta/dataDokumen';
import { ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';

class ModalPesertaAktifRegistrasiAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            pesertaId: '',
            dataMaster : {},
            actionType: 'add'
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.popupKeluargaRef = React.createRef()
        
        this.formDataKepegawaianRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.formDataAkunRef = React.createRef()
        this.dataGridDokumenRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: async() => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: () => {
                        this.hide()
                        this.removeState()
                    } 
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Personal'
            },
            {
                id: 1,
                text: 'Data Kepegawaian'
            },
            {
                id: 2,
                text: 'Data Keluarga'
            },
            {
                id: 3,
                text: 'Data Akun'
            },
            {
                id: 4,
                text: 'Data Dokumen'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                disabled: true,
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
                // isRequired: true,
                editorOptions : {
                    readOnly : true
                },
                // validationRules: [
                    // {
                    //     type: 'required',
                    //     message: 'NIK Harus Diisi'
                    // },
                //     {
                //         type: "pattern",
                //         pattern: /\(?([0-9])\)?/,
                //         message: "NIK harus menggunakan angka"
                //     },
                //     // {
                //     //     type: 'stringLength',
                //     //     max: 16,
                //     //     min : 16
                //     // }
                // ],
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Nama Lengkap Harus Diisi'
                    },
                ],
            },
        ]
    }
    get Popup(){
        return this.popupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }
    
    setNik = (nik) => {
        var data = {...this.state.dataMaster}
        data.nik = nik
        this.formMasterRef.current.instance.updateData(data)
    }
    submitData = async() => {
        var validateMaster = this.formMasterRef.current.instance.validate()
        var validatePersonal = this.formDataPersonalRef.current.validate()
        var validateKepegawaian = this.formDataKepegawaianRef.current.validate()

        if(validateMaster.isValid && validatePersonal.isValid && validateKepegawaian.isValid){
            var dataPersonal =  this.formDataPersonalRef.current.passDataToParent()
            var dataKepegawaian =  this.formDataKepegawaianRef.current.passDataToParent()
            var dataKeluarga =  this.dataGridKeluargaRef.current.passDataToParent()
            var dataDokumen =  this.dataGridDokumenRef.current.passDataToParent()
            var dataAkun =  this.formDataAkunRef.current.passDataToParent()
            var responsePersonal = await this.submitDataPersonal(dataPersonal)
            if(responsePersonal){
                var pesertaId = responsePersonal.id
                await this.submitDataKepegawaian(pesertaId,dataKepegawaian)
                await this.submitDataKeluarga(pesertaId,dataKeluarga)
                await this.submitDataAkun(dataAkun,pesertaId)
                await this.submitDataDokumen(pesertaId,dataDokumen)
                
                this.formDataPersonalRef.current.removeState()
                this.formDataKepegawaianRef.current.removeState()
                this.formDataAkunRef.current.removeState()
                
                var text = 'Berhasil Registrasi Peserta Aktif Baru!'
                await alert(text, 'Success!')
                // notify({ message: 'Berhasil Registrasi Peserta Aktif Baru!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.props.forceRefresh()
                // this.hide()
            }
        }else{
            var errorMessage = []
            if(validateMaster.brokenRules.length > 0){
                for(var value of validateMaster.brokenRules){
                    errorMessage.push(value.message)
                }
            }
            if(validatePersonal.brokenRules.length > 0){
                for(var value of validatePersonal.brokenRules){
                    errorMessage.push(value.message)
                }
            }
            if(validateKepegawaian.brokenRules.length > 0){
                for(var value of validateKepegawaian.brokenRules){
                    errorMessage.push(value.message)
                }
            }
            let text = errorMessage.join("<br/>");

            await alert(text, 'Failed!')
        }
    }

    submitDataPersonal = async(data) => {
        var dataPersonal = data
        dataPersonal.nik = this.state.dataMaster.nik
        dataPersonal.fullName = this.state.dataMaster.fullName
        dataPersonal.identityNo = this.state.dataMaster.noKtp
        dataPersonal.statusPeserta = 1
        dataPersonal.statusFoto = false
        if(dataPersonal){
            try {
                if(this.state.actionType == 'add'){
                    var insertAPI = 'pesertas'                    
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                            values:dataPersonal
                        }
                    )
                }else{
                    var updateAPI = 'pesertas'                    
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                            key: dataPersonal.id,
                            values:dataPersonal
                        },
                        dataPersonal)
                }
                return response
            } catch (e) {
                console.log(e)
                await alert(e, 'Gagal!')
            }
        }
    }
    
    submitDataKepegawaian = async(pesertaId,data) => {
        var dataKepegawaian = data
        if(data){
            dataKepegawaian.pesertaId = pesertaId
            try {
                if(!dataKepegawaian.id || this.state.actionType == 'add'){
                    var insertAPI = 'peserta-kepegawaians'                    
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                            values:dataKepegawaian
                        }
                    )
                }else{
                    var updateAPI = 'peserta-kepegawaians'                    
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                            key: dataKepegawaian.id,
                            values:dataKepegawaian
                        },
                    dataKepegawaian)
                }
                return response
            } catch (e) {
                console.log(e)
                await alert(e, 'Gagal!')
            }
        }
    }

    submitDataKeluarga = async(pesertaId,data) => {
        var dataKeluarga = data.dataKeluarga
        var deletedDataKeluarga = data.deletedDataKeluarga
        var currentData = data.currentDataKeluarga
        // console.log(dataKeluarga)
        if(data.length !== 0){
            for(var value of dataKeluarga){
                value.filePenghasilan = value.filePenghasilanData
                value.fileStatusMenikah = value.fileStatusMenikahData
                value.fileStatusSekolah = value.fileStatusSekolahData
                value.pesertaId = pesertaId
                try {
                    if(this.state.actionType == 'add'){
                        delete value.id
                        var insertAPI = 'peserta-families'                    
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                                values:value
                            }
                        )
                    }else{
                        if(value.id.length !== 36){
                            var selectedData = currentData.find(cur => cur.id === value.id)
                            if(compareObj(value,selectedData)){
                                var updateAPI = 'peserta-families'                    
                                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                                        values:value,
                                        key: value.id
                                    },
                                value)
                            }
                            // else{
                            //     delete value.id
                            //     var insertAPI = 'peserta-families'                    
                            //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                            //             values:value
                            //         }
                            //     )
                            // }
                        }else{
                            delete value.id
                            var insertAPI = 'peserta-families'                    
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                                    values:value
                                }
                            )                        }
                    }

                    var loadAPI = 'peserta-families'
                    for(var values of deletedDataKeluarga){
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'DELETE', values)
                    }
                } catch (e) {
                    console.log(e)
                    await alert(e, 'Gagal!')
                }
            }
        }
    }

    submitDataDokumen = async(pesertaId,data) => {
        var dataDokumen = data.dataDokumen
        var deletedDataDokumen = data.deletedDataDokumen
        var currentData = data.currentDataDokumen
        if(data.length !== 0){
            for(var value of dataDokumen){
                value.pesertaId = pesertaId
                value.online = false
                try {
                    if(!value.id || this.state.actionType == 'add'){
                        delete value.id
                        var insertAPI = 'dokumen-pesertas'                    
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                                values:value
                            }
                        )
                    }else{
                        if(value.id.length !== 36){
                            var selectedData = currentData.find(cur => cur.id === value.id)
                            if(compareObj(value,selectedData)){
                                var updateAPI = 'dokumen-pesertas'                    
                                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                                        values:value,
                                        key: value.id
                                    },
                                value)
                            }
                        }else{
                            delete value.id
                            var insertAPI = 'dokumen-pesertas'                    
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                                    values:value
                                }
                            )
                        }
                    }

                    var loadAPI = 'dokumen-pesertas'
                    for(var values of deletedDataDokumen){
                        await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'DELETE', values)
                    }
                } catch (e) {
                    console.log(e)
                    await alert(e, 'Gagal!')
                }
            }
        }
    }

    submitDataAkun = async(data,pesertaId,actionType) => {
        for(var value of data){
            var dataAkun = value
            var pesertaAkunProdukId = dataAkun.id || 0
            var dataIuran = value.dataIuran
            var currentData = value.currentDataIuran
            var deletedDataIuran = value.deletedDataIuran
            var loadAPIIuranType = 'iuran-types'                    
            var getIuranType = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIIuranType, 'GET')

            if(dataAkun && dataIuran){
                dataAkun.pesertaId = pesertaId || 0
                dataAkun.productId = this.props.product == 'ppmp' ? 1 : dataAkun.productId
                for(var iuran of dataIuran){
                    var typeIuran = getIuranType.find(val => val.id === iuran.iuranTypeId)
                    if(typeIuran.iuranName == 'Iuran Normal Peserta'){
                        dataAkun.iuranNormalPeserta  = iuran.iuranNominal
                    }
                    if(typeIuran.iuranName == 'Iuran Lainnya Peserta'){
                        dataAkun.iuranManfaatLainPeserta  = iuran.iuranNominal
                    }
                    if(typeIuran.iuranName == 'Iuran Normal PK'){
                        dataAkun.iuranNormalPk  = iuran.iuranNominal
                    }
                    if(typeIuran.iuranName == 'Iuran Lainnya PK'){
                        dataAkun.iuranManfaatLainPk  = iuran.iuranNominal
                    }

                    // if(typeIuran.iuranPeserta){
                    //     dataAkun.iuranNormalPeserta  = dataAkun.iuranNormalPeserta + iuran.iuranNominal
                    // }else{
                    //     dataAkun.iuranNormalPk  = dataAkun.iuranNormalPeserta + iuran.iuranNominal
                    // }
                }
                try {
                    if(!dataAkun.id || actionType == 'add'){
                        var insertAPI = 'kepersertaan-products'                    
                        var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                                values:dataAkun
                            }
                        )
                        pesertaAkunProdukId = responseProduct.id
                    }else{
                        var updateAPI = 'kepersertaan-products'                    
                        var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                                key:dataAkun.id,
                                values:dataAkun
                            },
                        dataAkun)

                    }
                } catch (e) {
                    console.log(e)
                    await alert(e, 'Gagal!')
                }
            }
            if(dataIuran){
                if(dataIuran.length !== 0){
                    for(var value of dataIuran){
                        value.pesertaAkunProdukId = pesertaAkunProdukId
                        value.productId = responseProduct.productId
                        if(value.id.length != 36){
                            var selectedData = currentData.find(cur => cur.id === value.id)
                            if(compareObj(value,selectedData)){
                                try {
                                    var updateAPI = 'peserta-akun-produk-iurans'
                                    await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                                            values:value,
                                            key: value.id
                                        },
                                    value)
                                } catch (e) {
                                    console.log(e)
                                    await alert(e, 'Gagal!')
                                }
                            }
                        }else{
                            delete value.id
                            try {
                                var insertAPI = 'peserta-akun-produk-iurans'
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                                        values:value
                                    }
                                )
                            } catch (e) {
                                console.log(e)
                                await alert(e, 'Gagal!')
                            }
                        }
        
                    }
                    try {
                        var deleteAPI = 'peserta-akun-produk-iurans'
                        for(var values of deletedDataIuran){
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, deleteAPI, 'DELETE', values)
                        }
                    } catch (e) {
                        console.log(e)
                        await alert(e, 'Gagal!')
                    }
                }
            }
        }
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    
    retrieveData = async(type,data) => {
        try {
            var loadAPIPeserta = `pesertas/${data.pesertaId || data.id}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
        } catch (e) {
            console.log(e)
        }

        try {
            var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${data.pesertaId || data.id}`
            var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKeluarga, 'GET')
        } catch (e) {
            console.log(e)
        }
        
        try {
            var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${data.pesertaId || data.id}`
            var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')
        } catch (e) {
            console.log(e)
        }

        try {
            var loadAPIAkun = `kepersertaan-products/getAllSimpleProductByPesertaId/${data.pesertaId || data.id}`
            var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
        } catch (e) {
            console.log(e)
        }

        if(type !== 'add'){
            await this.formDataPersonalRef.current.retrieveData(getPeserta)
            await this.formDataKepegawaianRef.current.retrieveData(getPeserta.id,getKepegawaian)
            await this.dataGridKeluargaRef.current.reloadData(getPeserta.id,getKeluarga)
            await this.formDataAkunRef.current.reloadData(getPeserta.id,(getAkun || []))
            await this.dataGridDokumenRef.current.reloadData(getPeserta.id)
            this.setState({
                pesertaId: data.id,
                actionType: type,
                dataMaster: getPeserta
            })
        }else{
            this.setState({
                pesertaId: 0,
                actionType: type,
                dataMaster: {}
            })
        }
    }

    removeState = () => {
        this.formDataPersonalRef.current.removeState()
        this.formDataKepegawaianRef.current.removeState()
        this.formDataAkunRef.current.removeState()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Registrasi Peserta Aktif'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row" style={{height: 'calc(90vh - 325px)'}}>
                        <div className="col-md-12">
                            <Form
                                ref={this.formMasterRef}
                                colCount={3}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                readOnly={this.state.actionType == 'add' ? false : false}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <ScrollView width='100%' height='100%'>
                            <div id={`tabIndexModalRegistrasi${this.props.actionType}-0`} className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataPersonal store={this.props.store} actionType={this.props.actionType} ref={this.formDataPersonalRef}/>
                            </div>
                            <div id={`tabIndexModalRegistrasi${this.props.actionType}-1`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataKepegawaian store={this.props.store} actionType={this.props.actionType} ref={this.formDataKepegawaianRef} setNik = {(this.setNik.bind(this))}/>
                            </div>
                            <div id={`tabIndexModalRegistrasi${this.props.actionType}-2`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <DataGridKeluarga store={this.props.store} actionType={this.props.actionType} ref={this.dataGridKeluargaRef}/>
                            </div>
                            <div id={`tabIndexModalRegistrasi${this.props.actionType}-3`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataAkun store={this.props.store} actionType={this.props.actionType} ref={this.formDataAkunRef}/>
                            </div>
                            <div id={`tabIndexModalRegistrasi${this.props.actionType}-4`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <DataGridDokumen store={this.props.store} actionType={this.props.actionType} ref={this.dataGridDokumenRef}/>
                            </div>
                        </ScrollView>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalPesertaAktifRegistrasiAktif
