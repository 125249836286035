import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, hubunganKeluarga, months, product, ptkp, tax } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog';
import ModalCatatan from '../../compPeserta/catatan';
import notify from 'devextreme/ui/notify';
import { showLoading } from 'redux/actions/loading';
import { formatDate, formatDefaultDate, formatDefaultFullDate, formatNpwp, formatNumber } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import { saveAs } from 'file-saver';

class PembayaranMpPembayaranModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {},
            dataPembayaranSekaligus: {},
            getUrl: 'view-pembayaran-mp-detils?pembayaranMpId.in=0'
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()
        this.modalCatatanRef = React.createRef()
        this.selectedRowDatas = []
        this.idPembayaran = 0;

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    },
                    visible: this.props.type === 'Sekaligus' ? true : false
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                dataField: 'productIds',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Produk harus di isi'
                    },
                ],
            },
            {
                dataField: 'bulan',
                label: {
                    alignment: 'left',
                    text: 'Periode'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    // valueExpr: "id", // contains the same values as the "statusId" field provides
                    valueExpr: 'value',
                    displayExpr: "value", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Periode harus di isi'
                    },
                ],
            },
            {
                dataField: 'paymentDates',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Bayar'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Tanggal bayar harus di isi'
                    },
                ],
            },
        ]

        this.dataGridColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Pending',
                        hint: 'Pending',
                        visible: this.props.type == 'sekaligus' ? false : true,
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data)
                        }
                    },
                ]
            },
            // {
            //    dataField: 'idPembayaranMpDetil',
            //    caption: 'No',
            //    cellRender: (e) => {
            //        return e.rowIndex + 1
            //    }
            // }, 
            {
                dataField: 'nikPeserta',
                caption: 'NIK'
            },
            {
                dataField: 'noCard',
                caption: 'Card No'
            },
            {
                dataField: 'namaPeserta',
                caption: 'Nama Pensiunan'
            },
            {
                dataField: 'mpDetilRekeningName',
                caption: 'Nama Penerima',
                // cellRender: (e) => {
                //     var data = e.row.data
                //     if (data.pensiunPenerimaTypeId === 1) {
                //         return data.namaPeserta
                //     } else {
                //         return data.fullNameKeluarga
                //     }
                // }
            },
            {
                dataField: 'mpDetilRekeningNo',
                caption: 'Nomor Rekening'
            },
            {
                dataField: 'mpDetilRekeningName',
                caption: 'Nama Rekening'
            },
            {
                dataField: 'npwp',
                caption: 'NPWP',
            },
            {
                dataField: 'bruto',
                caption: 'MP Brutto',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'nominalRapel',
                caption: 'Rapel',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'nominalPotongan',
                caption: 'Potongan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'nominalPajak',
                caption: 'Pajak',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'mpDetilNominal',
                caption: 'Netto',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'statusPajakId',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: ptkp(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kode'
                }
            },
            {
                dataField: 'mpDetilRekeningBankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'mpDetilRekeningBankId',
                caption: 'Kode Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankCode'
                }
            },
            {
                dataField: 'mpRekeningCabang',
                caption: 'Bank Cabang',
            },
            {
                dataField: 'feeBank',
                caption: 'Fee Bank',
                format: '#,##0.00',
                alignment: 'right'
            },
            // {
            //    dataField: 'relasiCode',
            //    caption: 'Hubungan Keluarga',
            //    lookup:{
            //         dataSource: hubunganKeluarga(),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'value' 
            //     }
            // },
        ]

        this.dataGridColumnSekaligus = [
            // {
            //     type: 'buttons',
            //     buttons: [
            //         {
            //             text: 'Pending',
            //             hint: 'Pending',
            //             visible: this.props.type == 'sekaligus' ? false : true,
            //             onClick: (e) => {
            //                 this.showModalCatatan(e.row.data)
            //             }
            //         },
            //     ]
            // },
            // {
            //    dataField: 'idPembayaranMpDetil',
            //    caption: 'No',
            //    cellRender: (e) => {
            //        return e.rowIndex + 1
            //    }
            // }, 
            {
                dataField: 'nikPeserta',
                caption: 'NIK'
            },
            {
                dataField: 'noCard',
                caption: 'Card No'
            },
            {
                dataField: 'namaPeserta',
                caption: 'Nama Pensiunan'
            },
            {
                dataField: 'mpDetilRekeningName',
                caption: 'Nama Penerima',
                // cellRender: (e) => {
                //     var data = e.row.data
                //     if (data.pensiunPenerimaTypeId === 1) {
                //         return data.namaPeserta
                //     } else {
                //         return data.fullNameKeluarga
                //     }
                // }
            },
            {
                dataField: 'mpDetilRekeningNo',
                caption: 'Nomor Rekening'
            },
            {
                dataField: 'mpDetilRekeningName',
                caption: 'Nama Rekening'
            },
            {
                dataField: 'npwp',
                caption: 'NPWP',
            },
            {
                dataField: 'bruto',
                caption: 'MP Brutto',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'nominalRapel',
                caption: 'Rapel',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'nominalPotongan',
                caption: 'Potongan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'nominalPajak',
                caption: 'Pajak',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'mpDetilNominal',
                caption: 'Netto',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'statusPajakId',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: ptkp(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kode'
                }
            },
            {
                dataField: 'mpDetilRekeningBankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'mpDetilRekeningBankId',
                caption: 'Kode Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankCode'
                }
            },
            {
                dataField: 'mpRekeningCabang',
                caption: 'Bank Cabang',
            },
            {
                dataField: 'feeBank',
                caption: 'Fee Bank',
                format: '#,##0.00',
                alignment: 'right'
            },
            // {
            //    dataField: 'relasiCode',
            //    caption: 'Hubungan Keluarga',
            //    lookup:{
            //         dataSource: hubunganKeluarga(),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'value' 
            //     }
            // },
        ]

    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    showModalCatatan = (data) => {
        this.modalCatatanRef.current.show(data)
    }

    show = async (data) => {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataPembayaranSekaligus: data
        })
    }

    // loadDataGrid = async () => {
    //     let loadAPI = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, this.state.getUrl, 'GET');

    //     return loadAPI;
    // }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        });

        // this.forceRefresh()
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async () => {
        var dataSekaligus = this.state.dataPembayaranSekaligus
        let dataMaster = {
            paymentDate: this.formRef.current.instance.getEditor('paymentDates').option('value'),
            productId: this.formRef.current.instance.getEditor('productIds').option('value'),
            bulan: this.formRef.current.instance.getEditor('bulan').option('value'),
        }

        dataSekaligus.productId = dataMaster.productId
        dataSekaligus.paymentDate = dataMaster.paymentDate
        dataSekaligus.bulan = dataMaster.bulan

        try {
            var updateAPI = `pembayaran-mps`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                key: dataSekaligus.pembayaranMpId || dataSekaligus.id,
                values: dataSekaligus
            }, dataSekaligus)

            await alert('Berhasil merubah data pembayaran !', 'Berhasil!')
            this.hide()
        } catch (e) {
            console.log(e)
            await alert(e, 'Gagal!')
        }
    }

    pending = async (data, catatan) => {
        try {
            var loadAPI = `kepersertaan-products/${data.akunProdukId}`
            var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            var updateData = getAkun
            updateData.pendingDate = new Date()
            updateData.pendingNote = catatan
            updateData.pendingStatus = true

            if (getAkun) {
                // var deleteAPI = 'pembayaran-mp-detils'
                // await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store,deleteAPI,'DELETE',data.idPembayaranMpDetil)

                var updateAPI = 'kepersertaan-products'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: updateData.id,
                    values: updateData
                }, getAkun)

            }
            await alert('Data berhasil dipending !', 'Berhasil!')
            this.forceRefresh()
        } catch (e) {
            console.log(e)
            await alert(e, 'Gagal!')
        }

    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    forceRefresh = (param = false) => {
        this.dataGridRef.current.forceRefresh(param)
    }

    onSelectionChanged = (e) => {
        this.selectedRowDatas = e.selectedRowsData
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.printMulti();
                        // if (this.selectedRowDatas.length > 0) {
                        //     // console.log(this.selectedRowDatas);
                        //     this.printMulti()
                        // } else {
                        //     notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        // }
                    },
                }
            },
        )
    }

    printMulti = async () => {
        this.props.store.dispatch(showLoading(true));
        let data = [];
        const firstTableRowNum = 1;
        let loadData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `view-pembayaran-mp-detils/list?pembayaranMpId.in=${this.idPembayaran}`, 'GET');
        let statusPajak = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'ptkps', 'GET');
        let banks = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET');

        for (let value of loadData) {
            let dataValue = {
                nikPeserta: value.nikPeserta,
                noCard: value.noCard,
                namaPeserta: value.namaPeserta,
                mpDetilRekeningName1: value.mpDetilRekeningName ? value.mpDetilRekeningName : '-',
                mpDetilRekeningNo: value.mpDetilRekeningNo ? value.mpDetilRekeningNo : '-',
                mpDetilRekeningName2: value.mpDetilRekeningName ? value.mpDetilRekeningName : '-',
                npwp: formatNpwp(value.npwp),
                bruto: value.bruto ? value.bruto : '-',
                nominalRapel: value.nominalRapel,
                nominalPotongan: value.nominalPotongan,
                nominalPajak: value.nominalPajak,
                mpDetilNominal: value.mpDetilNominal,
                statusPajakId: value.statusPajakId ? statusPajak.find(val => val.id === value.statusPajakId).kode : null,
                mpDetilRekeningBankId1: value.mpDetilRekeningBankId ? banks.find(val => val.id === value.mpDetilRekeningBankId).bankName : null,
                mpDetilRekeningBankId2: value.mpDetilRekeningBankId ? banks.find(val => val.id === value.mpDetilRekeningBankId).bankCode : null,
                mpRekeningCabang: value.mpRekeningCabang,
                feeBank: value.feeBank
            }
            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }
        
        try {
            const workbook = new Excel.Workbook();
            let workSheet = workbook.addWorksheet('Daftar Pembayaran Pensiun Bulanan');
            workSheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                    // border: {
                    //     top: {style:'thin'},
                    //     left: {style:'thin'},
                    //     bottom: {style:'thin'},
                    //     right: {style:'thin'}
                    // }
                },
                columns: [
                    {name: 'NIK'},
                    {name: 'Card No'},
                    {name: 'Nama Pensiun'},
                    {name: 'Nama Penerima'},
                    {name: 'Nomor Rekening'},
                    {name: 'Nama Rekening'},
                    {name: 'NPWP'},
                    {name: 'MP Bruto'},
                    {name: 'Rapel'},
                    {name: 'Potongan'},
                    {name: 'Pajak'},
                    {name: 'Netto'},
                    {name: 'Status Pajak'},
                    {name: 'Bank'},
                    {name: 'Kode Bank'},
                    {name: 'Bank Cabang'},
                    {name: 'Fee Bank'}
                ],
                rows: data
            })

            let widthColums = 20;

            const Data1 = workSheet.getColumn(1);
            Data1.width = widthColums;

            const Data2 = workSheet.getColumn(2);
            Data2.width = widthColums; 

            const Data3 = workSheet.getColumn(3);
            Data3.width = widthColums;

            const Data4 = workSheet.getColumn(4);
            Data4.width = widthColums;

            const Data5 = workSheet.getColumn(5);
            Data5.width = widthColums;

            const Data6 = workSheet.getColumn(6);
            Data6.width = widthColums;

            const Data7 = workSheet.getColumn(7);
            Data7.width = widthColums;

            const Data8 = workSheet.getColumn(8);
            Data8.width = widthColums;

            const Data9 = workSheet.getColumn(9);
            Data9.width = widthColums;

            const Data10 = workSheet.getColumn(10);
            Data10.width = widthColums;

            const Data11 = workSheet.getColumn(11);
            Data11.width = widthColums;

            const Data12 = workSheet.getColumn(12);
            Data12.width = widthColums;

            const Data13 = workSheet.getColumn(13);
            Data13.width = widthColums;

            const Data14 = workSheet.getColumn(14);
            Data14.width = widthColums;

            const Data15 = workSheet.getColumn(15);
            Data15.width = widthColums;

            const Data16 = workSheet.getColumn(16);
            Data16.width = 30;

            const Data17 = workSheet.getColumn(17);
            Data17.width = widthColums;

            let tanggal = this.state.dataMaster.paymentDates;
            tanggal = tanggal ? formatDefaultDate(tanggal).split(' ') : tanggal

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: 'application/octet-stream'}),
                    tanggal ? `Pembayaran MP ${tanggal[1]} ${tanggal[2]}.xlsx` : `Pembayaran MP.xlsx`
                )
            })
            this.props.store.dispatch(showLoading(false));
        } catch (error) {
            console.log(error);
        }
    }

    retrieveData = async(data) => {
        let periodeBulan = data.paymentDates ? formatDefaultFullDate(data.paymentDates).split(' ') : data.paymentDates
        data.paymentDates = data.paymentDates
        data.productIds = data.productIds
        data.bulan = periodeBulan ? periodeBulan[1] : null

        this.setState({
            dataMaster: data,
            getUrl: `view-pembayaran-mp-detils?pembayaranMpId.in=${data.idPembayaran || data.id}`
        });
        this.idPembayaran = data.idPembayaran || data.id;
    }

    retrieveDataSekaligus = async(data) => {
        try {
            var loadAPI = `pembayaran-mps/${data.pembayaranMpId || data.id}`
            var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            this.formRef.current.instance.getEditor('bulan').option({
                valueExpr: 'id'
            })
            
            this.formRef.current.instance.updateData({
                productIds: getPembayaranMp.productId,
                paymentDates: getPembayaranMp.paymentDate,
                bulan: getPembayaranMp.bulan,
            })
            
            this.setState({
                getUrl: `view-pembayaran-mp-detils?pembayaranMpId.in=${data.pembayaranMpId || data.id}`
            });
            this.idPembayaran = data.pembayaranMpId || data.id;
        } catch (e) {
            console.log(e)
        }
    }

    retrieveDataBulanan = async(data) => {
        try {
            var loadAPI = `pembayaran-mps/${data.pembayaranMpId || data.id}`
            var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            this.formRef.current.instance.getEditor('bulan').option({
                valueExpr: 'id'
            })
            
            this.formRef.current.instance.updateData({
                productIds: getPembayaranMp.productId,
                paymentDates: getPembayaranMp.paymentDate,
                bulan: getPembayaranMp.bulan,
            })
            
            this.setState({
                getUrl: `view-pembayaran-mp-detils?pembayaranMpId.in=${data.pembayaranMpId || data.id}`
            });
            this.idPembayaran = data.pembayaranMpId || data.id;
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form ${this.props.actionType == 'view' ? 'Detail' : 'Generate'} Pembayaran Manfaat Pensiun ${this.props.type}`}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                {/* <ScrollView height="100%" width="100%"> */}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation="left"
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='pembayaran-mp-detils'
                                updateAPI='pembayaran-mp-detils'
                                deleteAPI='pembayaran-mp-detils'

                                backendserver={process.env.REACT_APP_BACKEND_CORE}
                                keyField={'idPembayaranMpDetil'}

                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}

                                remoteOperations={true}
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Pembayaran MP Bulanan"}
                                allowExportSelectedData={true}
                                // selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Pembayaran MP Bulanan'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter
                                height={'calc(65vh - 88px)'}

                                ColumnConfiguration={this.props.type === 'Sekaligus' ? this.dataGridColumnSekaligus : this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                {/* </ScrollView> */}
                <ModalCatatan
                    ref={this.modalCatatanRef}
                    store={this.props.store}
                    submitData={this.pending}
                />
            </Popup>
        )
    }
}

export default PembayaranMpPembayaranModal