import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import notify from 'devextreme/ui/notify'
import FormDataAkun from './comp/dataAkun';
import httpRequest from 'plugin/httprequest';
import { pesertaById } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { ScrollView } from 'devextreme-react';

class ShowModalActionAkunPeserta extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            DataMaster: {
                pesertaPensiun: {}
            },
            dataAkun: {},
            statusPeserta: null,
            product: (this.props.product || '').toUpperCase()
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.pesertaId = 0
        this.PopupRef = React.createRef()
        // this.formMasterRef = React.createRef()
        this.formDataAkunRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this),
                },
                visible: this.props.actionType === 'view' ? false : true,
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': this.props.actionType === 'view' ? 'tutup' : 'Batal',
                    onClick: this.hide.bind(this)
                },
                // visible: this.props.actionType === 'view' ? false : true,
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                dataField: 'nikPanjang',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            DataMaster: {
                pesertaPensiun: {}
            },
            dataAkun: {},
            statusPeserta: null,
        });
    }

    hide() {
        this.dropDownBoxRef.current.instance.option('value', "")
        this.formDataAkunRef.current.removeState()
        this.setState({
            popupVisible: false,
            DataMaster: {
                pesertaPensiun: {}
            },
            dataAkun: {},
            statusPeserta: null,
            product: (this.props.product || '').toUpperCase()
        });
    }

    onValueChanged = (e) => {
        // var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value
        this.pesertaId = Array.isArray(fieldValue) ? fieldValue[0] : 0

        // this.setState({
        //     [fieldName]: fieldValue
        // })
    }

    retrieveData = async (data = null) => {
        if (this.props.actionType !== 'add') {
            var loadAPI = `simple-pesertas/getSimpleById/${data.pesertaIds || data.pesertaId}`
            var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

            var loadAPI = `kepersertaan-products/${data.ids || data.id}`
            var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            await this.formDataAkunRef.current.reloadData(dataPeserta, getAkun, data.dataIuran)
            await this.formDataAkunRef.current.getStatusPeserta(dataPeserta.statusPeserta)

            this.setSelectedRow(dataPeserta.id)
            this.setState({
                statusPeserta: dataPeserta.statusPeserta,
                DataMaster: dataPeserta,
                product: getAkun.productId == 1 ? 'PPMP' : 'PPIP'
            })

            this.pesertaId = dataPeserta.id
        } else {
            await this.formDataAkunRef.current.getStatusPeserta(0)
            this.setState({
                statusPeserta: null,
                DataMaster: {}
            })
        }

        this.formDataAkunRef.current.forceRefreshIuran()
    }
    setSelectedRow = (id) => {
        this.dropDownBoxRef.current.instance.option('value', id)
    }
    passDataToParent = () => {
        var data = this.state.dataAkun

        return data
    }
    submitData = async () => {
        // var validateMaster = this.formMasterRef.current.instance.validate()
        var validateDetail = this.formDataAkunRef.current.validate()
        var validateAkun = {}
        if (validateDetail) {
            validateAkun = validateDetail.validateAkun
        }
        if (validateAkun.isValid) {
            var data = this.formDataAkunRef.current.passDataToParent()
            // this.setState({
            //     dataAkun: data,
            // })
            var pesertaId = this.pesertaId

            var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${pesertaId}`
            var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKepegawaian, 'GET')
            // if(data.efektifDate < new Date(getKepegawaian.tanggalEfektif)){
            //     var text = 'Tanggal efektif akun tidak boleh kurang dari tanggal efektif kepegawaian peserta!'
            //     notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 1000);

            //     return false
            // }
            console.log(data);
            this.props.submitData(data, pesertaId, this.props.actionType)
            this.hide()

            // return false
        } else {
            var errorMessage = []
            if (validateAkun.brokenRules.length > 0) {
                for (var value of validateAkun.brokenRules) {
                    errorMessage.push(value.message)
                }
            }
            let type = "error";
            let text = errorMessage.join("\n");

            notify(
                {
                    message: text,
                    width: "AUTO",
                    shading: true,
                    position: { at: "center", my: "center", of: window }
                },
                type,
                2000
            );
        }
    }

    onToolbarPreparingDataAkun = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Tambah Iuran',
                    icon: 'plus',
                    onClick: (e) => {
                        this.props.showModalActionIuranDataAkun('add')
                    },
                }
            }
        )
    }

    onSelectionChanged = (e) => {
        this.dropDownBoxRef.current.instance.option('value', e.selectedRowKeys)
    }

    renderDatagrid = (e) => {
        return (
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI={'view-pesertas?statusPeserta.in=2'}
                insertAPI='pengkinian-peserta-bo-aktifs'
                updateAPI='pengkinian-peserta-bo-aktifs'
                deleteAPI='pengkinian-peserta-bo-aktifs'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations={true}

                paging={true}
                defaultPageSize={5}

                showPageSizeSelector={false}
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged={this.onSelectionChanged}

                height={'auto'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }
    render() {
        var TabDataSource = [
            {
                id: 0,
                text: `Data Akun ${this.state.product}`
            },
        ]
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form ${this.state.product}`}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}
                toolbarItems = {this.PopupToolbarItem}

                // toolbarItems={this.props.actionType == 'view' ? [] : this.PopupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group row">
                                    <label className="col-sm-2 col-form-label">Peserta</label>
                                    <div className="col-sm-10">
                                        <DropDownBox
                                            ref={this.dropDownBoxRef}
                                            valueExpr="id"
                                            deferRendering={false}
                                            displayExpr={(item) => {
                                                return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                            }}
                                            showClearButton={true}
                                            dataSource={pesertaById(this.props.store)}
                                            onValueChanged={this.onValueChanged}
                                            contentRender={this.renderDatagrid}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <DevextremeTab
                                    dataSource={TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                            <div id="tabindex-4" className={'mt-2 col-md-12 text-center'} style={{ minHeight: 'calc(50vh)' }}>
                                <FormDataAkun store={this.props.store} actionType={this.props.actionType} ref={this.formDataAkunRef} product={this.props.product} />
                            </div>
                        </div>

                    </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ShowModalActionAkunPeserta