import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import { currency, instrumentProperti, productDefault } from 'dataSource/lookup';
import ModalOperasionalPemeliharaan from 'components/pages/modal/investasi/aset-non-portfolio/operasional/pemeliharaan/index';
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { confirmAlert } from 'react-confirm-alert';
import httpRequest from 'plugin/httprequest';

class OperasionalPemeliharaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {}
        }
        this.prevTabIndex = 0
        this.modalOperasionalPemeliharaanRef = React.createRef()
        this.modalInvoiceOperasionalPemeliharaanRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.dataGridRiwayatRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalOperasionalPemeliharaan('edit', e.row.data)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.status == 'OPEN') {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Yakin ingin membatalkan data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak',
                                        },
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Gagal',
                                    message: 'Data ini sudah terkirim!',
                                    buttons: [
                                        {
                                            label: 'Tutup',
                                        },
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.status == 'OPEN') {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.sendData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak',
                                        },
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Gagal',
                                    message: 'Data ini sudah terkirim!',
                                    buttons: [
                                        {
                                            label: 'Tutup',
                                        },
                                    ]
                                })
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'instruksiNo',
                caption: 'No. Instruksi',
            },
            {
                dataField: 'instrumentId',
                caption: 'Properti',
                lookup: {
                    dataSource: instrumentProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName',
                },
            },
            {
                dataField: 'unit',
                caption: 'Unit / Kav',
            },
            {
                dataField: 'description',
                caption: 'Keterangan',
            },
            {
                dataField: 'invoice',
                alignment: 'center',
                caption: 'Tagihan',
                columns: [
                    {
                        dataField: 'invoiceNo',
                        caption: 'No. Tagihan'
                    },
                    {
                        dataField: 'invoiceDate',
                        catpion: 'Tanggal Tagihan',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'paymentDate',
                        catpion: 'Tanggal Pembayaran',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'nominalTransaksi',
                        catpion: 'Nominal Tagihan',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                ]
            },
            {
                dataField: 'pejabat',
                alignment: 'center',
                caption: 'Pejabat',
                columns: [
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1'
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status'
            },
        ]
        this.columnPemeliharaan = [
            // {
            //     type: 'buttons',
            //     buttons: [
            //         'edit',
            //         {
            //             text: 'Detail',
            //             onClick: console.log('Riwayat')
            //         },
            //     ]
            // },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'instruksiNo',
                caption: 'No. Instruksi',
            },
            {
                dataField: 'instrumentId',
                caption: 'Properti',
                lookup: {
                    dataSource: instrumentProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName',
                },
            },
            {
                dataField: 'unit',
                caption: 'Unit / Kav',
            },
            {
                dataField: 'description',
                caption: 'Keterangan',
            },
            {
                dataField: 'invoice',
                alignment: 'center',
                caption: 'Tagihan',
                columns: [
                    {
                        dataField: 'invoiceNo',
                        caption: 'No. Tagihan'
                    },
                    {
                        dataField: 'invoiceDate',
                        catpion: 'Tanggal Tagihan',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'paymentDate',
                        catpion: 'Tanggal Pembayaran',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'nominalTransaksi',
                        catpion: 'Nominal Tagihan',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                ]
            },
            {
                dataField: 'pejabat',
                alignment: 'center',
                caption: 'Pejabat',
                columns: [
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1'
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status'
            },
        ]
        this.TabDataSource = [
            {
                id: 1,
                text: 'Pengajuan',
            },
            {
                id: 2,
                text: 'Riwayat'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    sendData = async (data) => {
        var submit = {
            bankTransactionId: data.id,
        }
        if (submit) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transaction/kirim-pemeliharaan-properti', 'POST', { values: submit })
                if (response) {
                    this.forceRefresh()
                    notify({ message: 'Data Berhasil Dikirim!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    cancelData = async (data) => {
        if (data) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `bank-transactions`, 'DELETE', data.id)
                this.forceRefresh()
                notify({ message: 'Data Berhasil Dibatalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    loadDummyPemeliharaan = () => {
        var dummy = [
            {
                id: 1,
                noPemeliharaan: 'PEM-001/01',
                noRegAset: 'REG-001/01',
            }
        ]
        return dummy
    }
    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
        if(this.prevTabIndex === 1) {
            this.dataGridRiwayatRef.current.forceRefresh(true)
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Pengajuan',
                    icon: 'plus',
                    onClick: async (e) => {
                        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transactions/generate-no-instruksi/PEMELIHARAAN_PROPERTI', 'POST', { values: {} })
                        var data = {
                            instruksiNo: response
                        }
                        this.showModalOperasionalPemeliharaan('add', data)
                    },
                }
            },
        )
    }

    showModalOperasionalPemeliharaan = (type, data) => {
        this.modalOperasionalPemeliharaanRef.current.show(type, data)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="main-title">Pemeliharaan Aset</h2>
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                // loadAPI='bank-transactions?status.in=OPEN&status.in=AP&transactionTypeId.in=281'
                                loadAPI='bank-transactions?status.in=OPEN&transactionTypeId.in=281'
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Pemeliharaan Aset"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Pemeliharaan Aset'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                <div id="tabindex-1" className="d-none">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="main-title">Riwayat Pemeliharaan Properti</h2>
                            <DevExpressDataGrid
                                ref={this.dataGridRiwayatRef}
                                // loadAPI='bank-transactions?status.in=PAID&transactionTypeId.in=281'
                                loadAPI='bank-transactions?status.in=AP&transactionTypeId.in=281'
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                
                                exportExcel={true}
                                exportFileName={"Pemeliharaan"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Pemeliharaan Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnPemeliharaan} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                // remoteOperations = {true}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                <ModalOperasionalPemeliharaan
                    ref={this.modalOperasionalPemeliharaanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />

            </div >
        )
    }
}

export default OperasionalPemeliharaan