import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { CheckBox } from 'devextreme-react';
import { ab2str, yyyymmddToDate, formatNumber, cleanQuote } from 'plugin/helper';
import XLSX from 'xlsx'

import { DataGrid, FileUploader } from "devextreme-react";
import { Paging, Column } from "devextreme-react/data-grid";
import { gender, agama, statusPegawai, unit,statusKaryawan,grade, months, jabatan, maritalStatus, kelurahans, provinces, cities, kecamatans, banks, product } from 'dataSource/lookup';
import { tax, kelurahan } from 'components/pages/parts/staticDataColumnConfig';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import { showLoading } from 'redux/actions/loading';

class UploadNilaiSekarang extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            uploadedData: [],
            selectedTabIndex: 0,
            useHeader:false
        }

        this.show = this.show.bind(this)
        this.fileUploderRef = React.createRef()
        this.hide = this.hide.bind(this)
        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()
        this.successData = []
        this.errorData = []
        this.listDataSuccess = []
        this.prevTabIndex = 0

        this.PopupToolbarItem = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Simpan Data',
                onClick: this.submitData.bind(this)
            },
            toolbar: 'bottom'
        }, {
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Batal',
                onClick: this.hide.bind(this)
            },
            toolbar: 'bottom'
        }]

        this.TabDataSource = [
            {
              id: 0,
              text: "Data Sesuai"
            },
            {
              id: 1,
              text: "Data Tidak Sesuai"
            },
        ];

        this.columns = [
            {
                dataField: 'id',
                caption: "No.",
                cellRender: (data) => {
                  return data.rowIndex + 1
                },
            },
            {
                dataField: 'bulan_0',
                caption: 'Bulan 0',
                alignment: 'right'
            },
            {
                dataField: 'bulan_1',
                caption: 'Bulan 1',
                alignment: 'right'
            },
            {
                dataField: 'bulan_2',
                caption: 'Bulan 2',
                alignment: 'right'
            },
            {
                dataField: 'bulan_3',
                caption: 'Bulan 3',
                alignment: 'right'
            },
            {
                dataField: 'bulan_4',
                caption: 'Bulan 4',
                alignment: 'right'
            },
            {
                dataField: 'bulan_5',
                caption: 'Bulan 5',
                alignment: 'right'
            },
            {
                dataField: 'bulan_6',
                caption: 'Bulan 6',
                alignment: 'right'
            },
            {
                dataField: 'bulan_7',
                caption: 'Bulan 7',
                alignment: 'right'
            },
            {
                dataField: 'bulan_8',
                caption: 'Bulan 8',
                alignment: 'right'
            }, 
            {
                dataField: 'bulan_9',
                caption: 'Bulan 9',
                alignment: 'right'
            },
            {
                dataField: 'bulan_10',
                caption: 'Bulan 10',
                alignment: 'right'
            },
            {
                dataField: 'bulan_11',
                caption: 'Bulan 11',
                alignment: 'right'
            },
            {
                dataField: 'usia',
                caption: 'Usia',
                alignment: 'center'
            },
            {
                dataField: 'periodeTahun',
                caption: 'Periode Tahun',
                alignment: 'center'
            }
        ]
    }

    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            successData: [],
            errorData: [],
            uploadedData: []
        });

    }

    onTabSelectionChanged = args => {
        document.getElementById(`tabuploadindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabuploadindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    };

    onUploadStarted = (e) => {
        console.log(e);
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if(fileType === 'xls' || fileType === 'xlsx' ){
                    var workbook = XLSX.read(dataArrayBuffer, {type: 'array'});
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)
                    
                    data = dataJSON.map(value => Object.values(value))
                }

                if(fileType === 'csv'){
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                       data = data.filter(value => 
                            {
                                regex.lastIndex = 0
                                return regex.test(value)
                            }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)
                            
                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)
    
                                var splitRes = splitRes.map(splitVal => splitVal.trim())
        
                                var splitRes = splitRes.map(splitVal => {
                                    if(String(splitVal)){
                                        return String(splitVal)
                                    }else{
                                        return splitVal
                                    }
                                })
    
                                return splitRes
                            }
                        })
                    }else{
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                
                var errorMessage = []

                for(var uploadedData of data){
                    if(Array.isArray(uploadedData)){
                        errorMessage = []
            
                        // if(uploadedData.length !== 13){
                        //     let type = "error";
                        //     let text = "Format Data Masuk";

                        //     notify(
                        //         { message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600
                        //     );
                        // }
                        
                        successData.push({
                            bulan_0: uploadedData[1],
                            bulan_1: uploadedData[2],
                            bulan_2: uploadedData[3],
                            bulan_3: uploadedData[4],
                            bulan_4: uploadedData[5],
                            bulan_5: uploadedData[6],
                            bulan_6: uploadedData[7],
                            bulan_7: uploadedData[8],
                            bulan_8: uploadedData[9],
                            bulan_9: uploadedData[10],
                            bulan_10: uploadedData[11],
                            bulan_11: uploadedData[12],
                            usia: uploadedData[0],
                            periodeTahun: uploadedData[13] 
                        })
                    }
                }

                if(errorData.length > 0){
                    let type = "error";
                    let text = "There are some upload data that failed to upload, please check failed upload data tab";
    
                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                }

                this.successData = successData
                this.errorData = errorData
                this.editDataGrid(this.successData, 'success')
            }
            reader.readAsArrayBuffer(files);
        }  
    }

    editDataGrid = async (data, type) => {
        if (type === 'success'){
            var dataGrid = this.dataGridSesuaiRef;
        } else {
            var dataGrid = this.dataGridTakSesuaiRef;
        }
        await this.deleteDataGrid(dataGrid);
        for (var value of data){
            try {
                var key = value.id;
                if (key){
                    dataGrid.current.push([
                        {
                            type: 'update',
                            data: value,
                            key: key
                        }
                    ])
                }  else {
                    dataGrid.current.push([
                        {
                            type: 'insert',
                            data: value
                        }
                    ])
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    deleteDataGrid = (dataGrid) => {
        var data = dataGrid.current.getDataSource()

        for(var value of data){
            dataGrid.current.push([
                {
                    type: 'remove',
                    key : value.id
                }
            ])
        }
    }
   
    submitData = async() => {
        var dataSesuai = this.successData;

        for (var data of dataSesuai){
            if (typeof data.id === 'string'){
                delete data.id
            }
        }
        this.props.store.dispatch(showLoading(true));
        try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, 'nilai-sekarangs/uploadNilaiSekarang', 'POST', {
                    values: dataSesuai
                })
                if (response){
                    notify({ message: 'Sukses!', width: 'AUTO', position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.props.forceRefresh()
                    this.hide()
                }
        } catch (e) {
            console.log(e)
            this.props.store.dispatch(showLoading(false))
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
        this.props.store.dispatch(showLoading(false))
    }
 
    // submitData = async() => {
    //     var dataSesuai = this.dataGridSesuaiRef.current.getDataSource();
    //     console.log(dataSesuai);

    //     // var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, 'nilai-sekarangs', 'PUT', {
    //     //     values: dataSesuai,
    //     //     key: dataSesuai.id
    //     // }, dataSesuai);

    //     // if (response.id){
    //         try {
    //             for (let value of dataSesuai){
    //                 if (typeof value.id === "string"){
    //                     for (let i = 0; i < dataSesuai.length; i++){
    //                         delete dataSesuai[i].id;
    //                         this.addDataToAPi(dataSesuai[i]);
    //                     }
    //                     // this.addDataToAPi(dataSesuaiInsert);
    //                 }
    //             }
                
    //         } catch (error) {
    //             console.log(error);
    //         }
    //    // }
    // }

    // addDataToAPi = async (value) => {
    //     console.log(value);
    //     var arrData = [];
    //     let dataNilaiSekarang = {
    //         bulan_0: value.bulan_0,
    //         bulan_1: value.bulan_1,
    //         bulan_2: value.bulan_2,
    //         bulan_3: value.bulan_3,
    //         bulan_4: value.bulan_4,
    //         bulan_5: value.bulan_5,
    //         bulan_6: value.bulan_6,
    //         bulan_7: value.bulan_7,
    //         bulan_8: value.bulan_8,
    //         bulan_9: value.bulan_9,
    //         bulan_10: value.bulan_10,
    //         bulan_11: value.bulan_11,
    //         usia: value.usia,
    //         periodeTahun: value.periodeTahun
    //     }
   
    //     try {
    //         let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, 'nilai-sekarangs/uploadNilaiSekarang', 'POST', {
    //             values: dataNilaiSekarang
    //         })
    //         if (response){
    //             notify({ message: 'Sukses Simpan Data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600)
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns]

        columns = columns.map(value => {
            var modifiedValue = {...value}
            delete modifiedValue.lookup

            return modifiedValue
        })

        columns.unshift({
            type: 'buttons',
            buttons: [{
                text : 'Error Detail',
                hint : 'Error Detail',
                icon: "warning",
                cssClass: "text-danger", 
                onClick : (e) => {
                    let type = "error";
                    let text = e.row.data.errorMessage.join("\n");

                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                },
            }]
        })
        
        return columns
    }

    render(){
        return (
            <Popup
            className={"popup"}
            visible={this.state.popupVisible}
            onHiding={this.hide}
            dragEnabled={false}
            showTitle={true}
            title={"Upload Nilai Sekarang"}
            width={"90vw"}
            height={750}
            toolbarItems={this.PopupToolbarItem}
            >
                <div className="d-flex flex-row">
                    <div style={{width:"350px"}}>
                        <FileUploader
                            accept={".txt,.xls,.xlsx,.csv"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload File Disini"
                            showFileList={false}
                            name={"uploadPeserta"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                    </div>
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    selectedIndex={this.state.selectedTabIndex}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabuploadindex-0" className={' mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataSuccess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Sukses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Sukses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabuploadindex-1" className={'d-none mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridTakSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataFailed}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Gagal"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Gagal'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    </div>
            </Popup>
        )
    }
}

export default UploadNilaiSekarang;