// import React from 'react'
import React from 'react';
import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';

import { httpRequest } from '../plugin/httprequest'
import { templateCustomStoreLookup } from 'plugin/helper';
import { findDOMNode } from 'react-dom';

/* this is only sample code
export function sample(store) {
    //store = redux
    let loadAPI = 'mis'

    let dataSource = new CustomStore({
        key: 'id',
        load: () => httpRequest(store, loadAPI),
        byKey: (key) => httpRequest(store,loadAPI,'GET',key)
    })

    return dataSource
}


export function sampleArray(){
    var data = [
        {
            id: O,
            value: 'Open'
        },
        {
            id: S,
            value: 'Posted'
        },
        {
            id: R,
            value: 'Reversed'
        },
        {
            id: C,
            value: 'Cancel'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });
    
    return dataSource
}
*/

export function paramType() {
    var data = [
        {
            id: 'S',
            value: 'String'
        },
        {
            id: 'I',
            value: 'Integer'
        },
        {
            id: 'F',
            value: 'Float'
        },
        {
            id: 'D',
            value: 'Date'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function months() {
    var data = [
        {
            id: 1,
            value: 'Januari'
        },
        {
            id: 2,
            value: 'Februari'
        },
        {
            id: 3,
            value: 'Maret'
        },
        {
            id: 4,
            value: 'April'
        },
        {
            id: 5,
            value: 'Mei'
        },
        {
            id: 6,
            value: 'Juni'
        },
        {
            id: 7,
            value: 'Juli'
        },
        {
            id: 8,
            value: 'Agustus'
        },
        {
            id: 9,
            value: 'September'
        },
        {
            id: 10,
            value: 'Oktober'
        },
        {
            id: 11,
            value: 'November'
        },
        {
            id: 12,
            value: 'Desember'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function programPPIP(store) {
    // var loadAPI = 'products'
    // var data = await httpRequest(process.env.REACT_APP_BACKEND_CORE,store, loadAPI,'GET')

    // var ppip = []

    // for(var value of data){
    //     if(value.productCode !== 'PPMP'){
    //        ppip.push(value)
    //     }
    // }

    // let dataSource = new ArrayStore({
    //     data: ppip,
    //     key: 'id'
    // });
    var dataSource = product(store)
    return dataSource
}

export function jenisIdentitas() {
    var data = [
        {
            id: 1,
            value: 'KTP'
        },
        {
            id: 2,
            value: 'Passport'
        },
        {
            id: 3,
            value: 'Kitas'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function gender() {
    var data = [
        {
            id: '1',
            value: 'Perempuan'
        },
        {
            id: '0',
            value: 'Laki Laki'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function genderHrd() {
    var data = [
        {
            id: 1,
            value: 'Laki Laki'
        },
        {
            id: 2,
            value: 'Perempuan'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function genderInt() {
    var data = [
        {
            id: 1,
            value: 'Perempuan'
        },
        {
            id: 0,
            value: 'Laki Laki'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusKeluarga() {
    var data = [
        {
            id: 1,
            value: 'Kawin'
        },
        {
            id: 2,
            value: 'Belum Kawin'
        },
        {
            id: 3,
            value: 'Janda'
        },
        {
            id: 4,
            value: 'Duda'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function statusPenghasilan() {
    var data = [
        {
            id: "1",
            value: 'Belum Berpenghasilan'
        },
        {
            id: "2",
            value: 'Berpenghasilan'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusJournal() {
    var data = [
        {
            id: 'D',
            value: 'Draft'
        },
        {
            id: 'O',
            value: 'Open'
        },
        {
            id: 'P',
            value: 'Posted'
        },
        {
            id: 'C',
            value: 'Cancelled'
        },
        {
            id: 'R',
            value: 'Reversed'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusSppd() {
    var data = [
        {
            id: 1,
            value: 'Open'
        },
        {
            id: 2,
            value: 'Kirim'
        },
        {
            id: 3,
            value: 'Paid'
        },
        {
            id: 4,
            value: 'Batal'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function SpotControlPosition() {
    var data = [
        {
            id: 0,
            value: 'Spot Control'
        },
        {
            id: 1,
            value: 'Spot Position'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function agama() {
    var data = [
        {
            id: 1,
            value: 'Islam'
        },
        {
            id: 2,
            value: 'Kristen'
        },
        {
            id: 3,
            value: 'Katholik'
        },
        {
            id: 4,
            value: 'Buddha'
        },
        {
            id: 5,
            value: 'Hindu'
        },
        {
            id: 6,
            value: 'Konghucu'
        },
        {
            id: 7,
            value: 'Aliran Kepercayaan'
        },
        {
            id: 8,
            value: 'Kosong'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function maritalStatus(radio = false) {
    // 1  TK  Tidak Kawin
    // 2  K0  Kawin Tanpa Anak
    // 3  K1  Kawin 1 Anak
    // 4  K2  Kawin 2 Anak
    // 5  K3  Kawin 3 Anak
    // 6  J0  Janda Tanpa Anak
    // 7  J1  Janda 1 Anak
    // 8  J2  Janda 2 Anak
    // 9  J3  Janda 3 Anak
    // 10  D0  Duda Tanpa Anak
    // 11  D1  Duda 1 Anak
    // 12  D2  Duda 2 Anak
    // 13  D3  Duda 3 Anak
    // 14  Tanpa Keterangan
    // var data = [
    //     {
    //         id: 1,
    //         value: 'Tidak Kawin'
    //     },
    //     {
    //         id: 2,
    //         value: 'Kawin Tanpa Anak'
    //     },
    //     {
    //         id: 3,
    //         value: 'Kawin 1 Anak'
    //     },
    //     {
    //         id: 4,
    //         value: 'Kawin 2 Anak'
    //     },
    //     {
    //         id: 5,
    //         value: 'Kawin 3 Anak'
    //     },
    //     {
    //         id: 6,
    //         value: 'Janda Tanpa Anak'
    //     },
    //     {
    //         id: 7,
    //         value: 'Janda 1 Anak'
    //     },
    //     {
    //         id: 8,
    //         value: 'Janda 2 Anak'
    //     },
    //     {
    //         id: 9,
    //         value: 'Janda 3 Anak'
    //     },
    //     {
    //         id: 10,
    //         value: 'Duda Tanpa Anak'
    //     },
    //     {
    //         id: 11,
    //         value: 'Duda 1 Anak'
    //     },
    //     {
    //         id: 12,
    //         value: 'Duda 2 Anak'
    //     },
    //     {
    //         id: 13,
    //         value: 'Duda 3 Anak'
    //     },
    //     {
    //         id: 14,
    //         value: 'Tanpa Keterangan'
    //     },
    // ]
    var data = [
        {
            id: 1,
            value: 'Tidak Kawin'
        },
        {
            id: 2,
            value: 'Kawin'
        },
        {
            id: 3,
            value: 'Janda'
        },
        {
            id: 4,
            value: 'Duda'
        }
    ]
    // var data = [
    //     {
    //         id: 1,
    //         value: 'Tidak Kawin'
    //     },
    //     {
    //         id: 2,
    //         value: 'Kawin Tanpa Anak'
    //     },
    //     {
    //         id: 3,
    //         value: 'Kawin 1 Anak'
    //     },
    //     {
    //         id: 4,
    //         value: 'Kawin 2 Anak'
    //     },
    //     {
    //         id: 5,
    //         value: 'Kawin 3 Anak'
    //     },
    //     {
    //         id: 6,
    //         value: 'Janda Tanpa Anak'
    //     },
    //     {
    //         id: 7,
    //         value: 'Janda 1 Anak'
    //     },
    //     {
    //         id: 8,
    //         value: 'Janda 2 Anak'
    //     },
    //     {
    //         id: 9,
    //         value: 'Janda 3 Anak'
    //     },
    //     {
    //         id: 10,
    //         value: 'Duda Tanpa Anak'
    //     },
    //     {
    //         id: 11,
    //         value: 'Duda 1 Anak'
    //     },
    //     {
    //         id: 12,
    //         value: 'Duda 2 Anak'
    //     },
    //     {
    //         id: 13,
    //         value: 'Duda 3 Anak'
    //     },
    //     {
    //         id: 14,
    //         value: 'Tanpa Keterangan'
    //     },
    // ]

    if (radio) {
        return data
    } else {
        let dataSource = new ArrayStore({
            data: data,
            key: 'id'
        });
        return dataSource
    }

}

export function hubunganKeluarga() {
    var data = [
        {
            id: 'SI',
            value: 'Suami/Istri'
        },
        {
            id: 'AK',
            value: 'Anak Kandung'
        },
        {
            id: 'AIK',
            value: 'Orang Tua'
        },
        {
            id: 'KAK',
            value: 'Saudara Kandung'
        },
        {
            id: 'PK',
            value: 'Pihak Ketiga'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function journalStatus() {
    var data = [
        {
            id: 'D',
            value: 'Draft'
        },
        {
            id: 'O',
            value: 'Open'
        },
        {
            id: 'S',
            value: 'Posted'
        },
        {
            id: 'R',
            value: 'Reversed'
        },
        {
            id: 'C',
            value: 'Cancel'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function returType() {
    var data = [
        {
            id: 1,
            value: 'Kesalahan Rekening'
        },
        {
            id: 2,
            value: 'Pensiunan Meninggal'
        },
        {
            id: 3,
            value: 'Perubahan Penerima MP'
        },
        {
            id: 4,
            value: 'Perubahan Nilai Transfer'
        },
        {
            id: 5,
            value: 'Lainnya'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function dc() {
    var data = [
        {
            id: 'D',
            value: 'Debet'
        },
        {
            id: 'C',
            value: 'Credit'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusPengisianKasUmumSdm() {
    var data = [
        {
            id: '1',
            value: 'Pengajuan'
        },
        {
            id: '2',
            value: 'Proses'
        },
        {
            id: '3',
            value: 'Aktif'
        },
        {
            id: '4',
            value: 'Batal'
        },
        {
            id: '5',
            value: 'Selesai'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusPengisianKasUmumSdm2() {
    var data = [
        {
            id: 'Pengajuan',
            value: 'Pengajuan'
        },
        {
            id: 'Proses',
            value: 'Proses'
        },
        {
            id: 'Aktif',
            value: 'Aktif'
        },
        {
            id: 'Batal',
            value: 'Batal'
        },
        {
            id: 'Selesai',
            value: 'Selesai'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function statusRekruitmen() {
    var data = [
        {
            id: '1',
            value: 'Pengajuan'
        },
        {
            id: '2',
            value: 'Aktif'
        },
        {
            id: '3',
            value: 'Batal'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusPembayaranKasUmumSdm() {
    var data = [
        {
            id: '1',
            value: 'Pengajuan'
        },
        {
            id: '2',
            value: 'Berhasil'
        },
        {
            id: '3',
            value: 'Batal'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusPengembalianKasUmumSdm() {
    var data = [
        {
            id: '21',
            value: 'Pengajuan'
        },
        {
            id: '22',
            value: 'Berhasil'
        },
        {
            id: '23',
            value: 'Batal'
        },
        {
            id: '24',
            value: 'Proses'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function statusRetur() {
    var data = [
        {
            id: 1,
            value: 'Menunggu Konfirmasi Bank'
        },
        {
            id: 2,
            value: 'Menunggu Proses Susulan'
        },
        {
            id: 3,
            value: 'Selesai'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function oustandingPembelian(store) {
    let loadAPI = 'view-outstanding-pembelians'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function oustandingPemesanan(store) {
    let loadAPI = 'view-outstanding-penerimaans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function supplierSdm(store) {
    let loadAPI = 'suppliers'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function kategoriPengadaanSdm(store) {
    let loadAPI = 'kategori-pengadaans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function asetKategoriSdm(store) {
    let loadAPI = 'asset-categories'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function subAsetKategoriSdm(store) {
    let loadAPI = 'asset-category-subs'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function subAsetKategoriSdmByAssetCategoryId(store, assetCategoryId) {
    let loadAPI = `asset-category-subs/getByAssetCategoryId/${assetCategoryId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function karyawan(store) {
    let loadAPI = 'karyawans?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}

export function pejabat(store) {
    let loadAPI = 'karyawans/getByJabatanId/5,6,79501,79506'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}

export function gajiKomponen(store) {
    let loadAPI = 'gaji-komponens'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function tipeDokumen(store) {
    let loadAPI = 'dokumen-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function tipePotongan(store) {
    let loadAPI = 'potongan-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function potonganGroups(store) {
    let loadAPI = 'potongan-groups?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}

export function tipePinjaman(store) {
    let loadAPI = 'loan-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function tipeLembur(store) {
    let loadAPI = 'lembur-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function lemburBiaya(store) {
    let loadAPI = 'lembur-biayas'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function tipeCuti(store) {
    let loadAPI = 'cuti-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function periodeCuti(store) {
    let loadAPI = 'periode-cutis?sort=namaPeriodeCuti%2Casc'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function tipeSertifikasi(store) {
    let loadAPI = 'sertifikasis'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function tipeShift(store) {
    let loadAPI = 'shift-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function dapenProduk(store) {
    let loadAPI = 'dapen-produks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function kasUmum(store) {
    let loadAPI = 'kas-umums?size=999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function rekBankHrd(store) {
    let loadAPI = 'rek-banks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function media(store) {
    let loadAPI = 'media'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function klaimType(store) {
    let loadAPI = 'klaim-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function statusPegawai(store) {
    let loadAPI = 'status-kepegawaians'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function statusPajak(store) {
    let loadAPI = 'status-pajaks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function statusPajakSdm(store) {
    let loadAPI = 'status-pajaks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function statusKaryawan(store) {
    let loadAPI = 'status-karyawans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function statusKaryawanSdm(store) {
    let loadAPI = 'status-karyawans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function statusKepegawaianSdm(store) {
    let loadAPI = 'status-kepegawaians'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function divisi(store) {
    let loadAPI = 'divisis'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function journalType(store) {
    let loadAPI = 'journal-types/'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}
export function journalTypeByManual(store, manual) {
    let loadAPI = `journal-types/getAllByManual/${manual}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, null, false)
}

export function coaGroup(store) {
    let loadAPI = 'coa-groups/'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}

export function coaCategory() {
    var data = [
        {
            id: "1",
            value: 'Assets'
        },
        {
            id: "2",
            value: 'Liabilities'
        },
        {
            id: "3",
            value: 'Equities'
        },
        {
            id: "4",
            value: 'Earnings'
        },
        {
            id: "5",
            value: 'Expenses'
        },
        {
            id: "6",
            value: 'Other Earnings'
        },
        {
            id: "7",
            value: 'Other Expenses'
        },
        {
            id: "8",
            value: 'Tax Expenses'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function entityType() {
    var data = [
        {
            id: 'PK',
            value: 'Pemberi Kerja'
        },
        {
            id: 'MI',
            value: 'Manajer Investasi'
        },
        {
            id: 'BR',
            value: 'Broker'
        },
        {
            id: 'BN',
            value: 'Bank'
        },
        {
            id: 'BC',
            value: 'Bank Kustodian'
        },
        {
            id: 'AP',
            value: 'APERD'
        },
        {
            id: 'KR',
            value: 'Karyawan'
        },
        {
            id: 'PM',
            value: 'Penerima Manfaat'
        },
        {
            id: 'P3',
            value: 'Pihak Ketiga'
        },
        {
            id: 'PP',
            value: 'Pembeli Properti'
        },
        {
            id: 'SP',
            value: 'Supplier'
        },
        {
            id: 'GV',
            value: 'Goverment'
        },
        {
            id: 'PA',
            value: 'Pengelola Aset'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function settleWith() {
    var data = [
        {
            id: 'C',
            value: 'Tunai'
        },
        {
            id: 'B',
            value: 'Bank'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function phkType() {
    var data = [
        {
            id: 1,
            value: 'Meninggal'
        },
        {
            id: 2,
            value: 'Cacat'
        },
        {
            id: 3,
            value: 'Kepentingan Perusahaan'
        },
        {
            id: 4,
            value: 'Sanksi'
        },
        {
            id: 5,
            value: 'Mengundurkan Diri'
        },
        {
            id: 6,
            value: 'Pensiun Normal'
        },
        {
            id: 7,
            value: 'Promosi Dan Demosi'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function arapReffType() {
    var data = [
        {
            id: 'KP',
            value: 'Kepesertaan'
        },
        {
            id: 'IN',
            value: 'Investasi'
        },
        {
            id: 'HR',
            value: 'HRD & Umum'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function arapType() {
    var data = [
        {
            id: 'R',
            value: 'Piutang'
        },
        {
            id: 'P',
            value: 'Utang'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function plbs() {
    var data = [
        {
            id: 'p',
            value: 'Laba Rugi'
        },
        {
            id: 'b',
            value: 'Neraca'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function status() {
    var data = [
        {
            id: 1,
            value: 'Proses Pengajuan'
        },
        {
            id: 2,
            value: 'Proses Verifikasi'
        },
        {
            id: 3,
            value: 'Proses Persetujuan'
        },
        {
            id: 4,
            value: 'Cuti Disetujui'
        },
        {
            id: 5,
            value: 'Cuti Dibatalkan Sebagian'
        },
        {
            id: 6,
            value: 'Cuti Dibatalkan Semua'
        },
        {
            id: 7,
            value: 'Pengajuan Cuti Ditolak'
        },
        {
            id: 8,
            value: 'Ditunda'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function statusAktifNonAktif() {
    var data = [
        {
            id: '1',
            value: 'Aktif'
        },
        {
            id: '2',
            value: 'Tidak Aktif'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function statusAktifNonAktifRekBank() {
    var data = [
        {
            id: 1,
            value: 'Aktif'
        },
        {
            id: 2,
            value: 'Tidak Aktif'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function statusPembayaranRekapGaji() {
    var data = [
        {
            id: 1,
            value: 'Pengajuan'
        },
        {
            id: 2,
            value: 'Telah disetujui'
        },
        {
            id: 3,
            value: 'Ditolak'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function statusIuran() {
    var data = [
        {
            id: '1',
            value: 'Maker'
        },
        {
            id: '2',
            value: 'Berhasil'
        },
        {
            id: '3',
            value: 'Daftar Iuran Ditunda'
        },
        {
            id: '4',
            value: 'Proses Iuran Ditunda'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function depreciationMethod(getDataOnly = false) {
    var data = [
        {
            id: "S",
            value: 'Straight-Line Method'
        },
        {
            id: 'D',
            value: 'Double Declining Balance'
        },
        {
            id: 'Y',
            value: 'Sum of Years Digits'
        }
    ]

    if (getDataOnly) {
        return data
    }

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function papanPencatatan() {
    var data = [
        {
            id: 'U',
            value: 'Utama'
        },
        {
            id: 'P',
            value: 'Pengembangan'
        },
        {
            id: 'A',
            value: 'Akselerasi'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}
export function pejabats() {
    var data = [
        {
            value: 'ISMANTO'
        },
        {
            value: 'M.R. ANNA SAVITRA'
        },
        {
            value: 'SASIKA PRETISA'
        },
        {
            value: 'TRI JOKO PURWANTO'
        },
        {
            value: 'ERI MARDIANTO'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'value'
    });

    return dataSource
}
export function hardJabatan() {
    var data = [
        {
            value: 'DIREKTUR UTAMA'
        },
        {
            value: 'DIREKTUR KEUANGAN'
        },
        {
            value: 'DIREKTUR OPERASI'
        },
        {
            value: 'MANAJER INVESTASI'
        },
        {
            value: 'MANAJER SDM UMUM'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'value'
    });

    return dataSource
}
export function frekuensis() {
    var data = [
        {
            value: 'TAHUNAN'
        },
        {
            value: 'SEMI_TAHUNAN'
        },
        {
            value: 'TRIWULAN'
        },
        {
            value: 'BULANAN'
        },
        {
            value: 'NOT_COUPON_BOND'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'value'
    });

    return dataSource
}
export function dayBase() {
    var data = [
        {
            id: 'BASE_30_360',
            value: '30/360'
        },
        {
            id: 'BASE_ACT_360',
            value: 'act/360'
        },
        {
            id: 'BASE_ACT_ACT',
            value: 'act/act'
        },
        {
            id: 'BASE_30_365',
            value: '30/365'
        },
        {
            id: 'BASE_ACT_365',
            value: 'act/365'
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function coa(store) {
    let loadAPI = 'coas/'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}

export function anggranItem(store) {
    let loadAPI = 'anggaran-items/';

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC);
}

export function anggarans(store) {
    let loadAPI = 'anggarans?size=9999';

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC);
}

export function entity(store) {
    let loadAPI = 'entitis/'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}

export function entityP3(store) {
    let loadAPI = 'entitis/getAllMultipleByType/P3'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}

export function entitySP(store) {
    let loadAPI = 'entitis/getAllMultipleByType/SP'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}

export function entityPG(store) {
    let loadAPI = 'entitis/getAllMultipleByType/PG'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}

export function provincesFilter(store, countryId, useDevextreme = false) {
    let loadAPI = `provinces/get-by-countryId/${countryId}`

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND, store, loadAPI)

        return dataSource
    }
}

export function citiesFilter(store, provinceId, useDevextreme = false) {
    let loadAPI = `cities/get-by-provinceId/${provinceId}`

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND, store, loadAPI)

        return dataSource
    }
}

export function cities(store) {
    let loadAPI = 'cities'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}

export function kecamatans(store) {
    let loadAPI = 'kecamatans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}

export function kecamatanAll(store) {
    let loadAPI = 'kecamatans/getAll'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}

export function kecamatansFilter(store, cityId, useDevextreme = false) {
    let loadAPI = `kecamatans/get-by-cityId/${cityId}`

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND, false, false)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND, store, loadAPI)

        return dataSource
    }
}
export function countries(store) {
    let loadAPI = 'countries'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function provinces(store) {
    let loadAPI = 'provinces'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function kelurahanFilter(store, kecamatanId) {
    let loadAPI = `kelurahans/get-by-kecamatanId/${kecamatanId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND, false, false)
}
export function kelurahans(store) {
    let loadAPI = 'kelurahans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function tipeTransaksiSdmUmum(store, karyawanPayrollId) {
    let loadAPI = 'transaction-types?size=9999'
    // let loadAPI = `karyawan-payroll-by-transactions/getRekapTransactionByKaryawanPayrollId/${karyawanPayrollId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function tipeSubTransaksiSdmUmum(store) {
    let loadAPI = 'transaction-type-subs?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function tunjanganHrd(store) {
    let loadAPI = 'tunjangan-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function tunjangaCutinHrd(store) {
    let loadAPI = 'tunjangan-types?tunjanganCuti.in=true'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function periodeTunjangan(store) {
    let loadAPI = `periode-tunjangans?statusAvailable.in=true`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function tunjanganKategori(store) {
    let loadAPI = 'tunjangan-categories'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function tunjanganGroup(store) {
    let loadAPI = 'tunjangan-groups'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function grade(store) {
    let loadAPI = 'grades'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function bahasa(store) {
    let loadAPI = 'bahasas?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function keahlian(store) {
    let loadAPI = 'keahlians?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function gradeCore(store) {
    let loadAPI = 'grades'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function titles(store) {
    let loadAPI = 'titles'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function payrollKategoriSub(store) {
    let loadAPI = 'payroll-category-subs'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function karyawanSk(store) {
    let loadAPI = 'karyawan-sks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function relations(store) {
    let loadAPI = 'relations'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function pembayaranUmumDetilByPuId(puId, store, useDevextreme = false) {
    let loadAPI = 'pembayaran-umum-detils/getByPembayaranUmumId/' + puId

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND_HRD, store, loadAPI)

        return dataSource
    }
}

export function pembayaranUmumStatus(store) {
    let loadAPI = 'pembayaran-umum-statuses'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function pembayaranUmumDetailSdm(store) {
    let loadAPI = 'pembayaran-umum-detils?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function pembayaranUmumSdm(store) {
    let loadAPI = 'pembayaran-umums?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function unit(store) {
    let loadAPI = 'units'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function branch(store) {
    let loadAPI = 'branches'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE, null, false);
}
export function branchSDM(store) {
    let loadAPI = 'branches'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false);
}
export function banks(store) {
    let loadAPI = 'banks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}

export function banks_core(store) {
    let loadAPI = 'banks?size=999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function companyBank(store) {
    const loadData = async () => {
        let loadedData = []

        let dataBank = await httpRequest(process.env.REACT_APP_BACKEND, store, 'banks')
        var bankInvestasi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, 'rek-banks')
        bankInvestasi = bankInvestasi.map(value => {
            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
            return {
                id: `IN-${value.id}`,
                value: `${bank ? bank.initialName : null} - ${value.rekNo}`
            }
        })
        var bankSDM = await httpRequest(process.env.REACT_APP_BACKEND_HRD, store, 'rek-banks')
        bankSDM = bankSDM.map(value => {
            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
            return {
                id: `HR-${value.id}`,
                value: `${bank ? bank.initialName : null} - ${value.rekNo}`
            }
        })
        var bankKepesertaan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, store, 'rekbanks')
        bankKepesertaan = bankKepesertaan.map(value => {
            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
            return {
                id: `KP-${value.id}`,
                value: `${bank ? bank.initialName : null} - ${value.rekNo}`
            }
        })

        loadedData = [
            ...bankInvestasi,
            ...bankSDM,
            ...bankKepesertaan
        ]
        return loadedData
    }

    return templateCustomStoreLookup('company-bank', store, process.env.REACT_APP_BACKEND, null, false, loadData)
}
export function SKKerja(store) {
    let loadAPI = 'sk-kerjas'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function invoiceType(store) {
    let loadAPI = 'invoice-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function projectSdm(store) {
    let loadAPI = 'projects'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function SKKerjaGaji(store) {
    let loadAPI = 'sk-kerjas-salaries'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function SKPensiun(store) {
    let loadAPI = 'sk-pensiuns'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function SKType(store) {
    let loadAPI = 'peserta-sk-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function SKTypeHRD(store) {
    let loadAPI = 'sk-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function tax(store) {
    let loadAPI = 'taxes'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}
export function participants(store) {
    let loadAPI = 'participants'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function ptkp(store) {
    let loadAPI = 'ptkps'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function educationBackground(store) {
    let loadAPI = 'educations'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function akunHR(store) {
    let loadAPI = 'akuns?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function akunHRbyCategori(store) {
    let loadAPI = 'akuns/find-by-category/Kas Umum'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function allAkunHR(store) {
    let loadAPI = 'akuns/filterAkunsGetAll'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, true)
}
export function taxCategories(store) {
    let loadAPI = 'tax-categories'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}
export function currency(store) {
    let loadAPI = 'currencies'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}
export function departments(store) {
    let loadAPI = 'departments'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}
export function custodies(store) {
    let loadAPI = 'custodies'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function emitens(store) {
    let loadAPI = 'emitens'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function kebijakan(store) {
    let loadAPI = 'kebijakans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentCategorySubs(store) {
    let loadAPI = 'instrument-category-subs'
    // let loadAPI = 'instrument-category-subs?instrumentCategoryId.equals=3'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentCategories(store) {
    let loadAPI = 'instrument-categories'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentTypes(store) {
    let loadAPI = 'instrument-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}

export function instrumentProperties(store) {
    let loadAPI = 'instruments/properti'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}

export function paymentAgents(store) {
    let loadAPI = 'payment-agents'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function paymentPeriods(store) {
    let loadAPI = 'payment-periods'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rateMethods(store) {
    let loadAPI = 'rate-methods'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rateTypes(store) {
    let loadAPI = 'rate-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function valuasiMethods(store) {
    let loadAPI = 'valuasi-methods'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function valuasiPeriodes(store) {
    let loadAPI = 'valuasi-periodes'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function managerInvestasis(store) {
    let loadAPI = 'manager-investasis'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function pengkinianType(store) {
    let loadAPI = 'pengkinian-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function agendaType(store) {
    let loadAPI = 'agenda-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function card(store) {
    let loadAPI = 'cards'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function periodeDaftarUlang(store) {
    let loadAPI = 'view-daftar-ulang-peserta-pasifs'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function product(store, useDevextreme = true) {
    let loadAPI = 'products'

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND_CORE, store, loadAPI)

        return dataSource
    }
}
export function productDefault(store) {
    let loadAPI = 'products'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function metodeDepresiasi(store) {
    let loadAPI = 'metode-depresiasis'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function pesertaKepegawaians(store) {
    let loadAPI = 'peserta-kepegawaians?size=999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function pesertaById(store) {
    let loadAPI = 'pesertas'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function getAllPeserta(store) {
    let loadAPI = 'pesertas?size=99999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function getAllRIwayatPeserta(store) {
    let loadAPI = 'pesertas/riwayat-manfaat-pensiun-peserta-list'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function viewPembayaranMpDetilReturs(store) {
    let loadAPI = 'view-pembayaran-detil-returs'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function pesertas(store, useDevextreme = false) {
    let loadAPI = 'lookup-pesertas?size=99999'

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND_CORE, store, loadAPI)

        return dataSource
    }
}
export function pesertaAktif(store) {
    let loadAPI = 'lookup-pesertas/getByLookupByStatusPeserta/2?size=99999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function pesertaPasif(store) {
    let loadAPI = 'lookup-pesertas/getByLookupByStatusPeserta/4?size=99999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function pesertaAkunProducts(store) {
    let loadAPI = 'kepersertaan-products'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function viewPesertaAkunProducts(store) {
    let loadAPI = 'view-lookup-kepersertaan-products'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function pesertaAkunProductsPasif(store) {
    let loadAPI = 'view-lookup-kepersertaan-products?akunStatusId.in=2&akunStatusId.in=5&akunStatusId.in=7'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function kepersertaanAkun(store) {
    let loadAPI = 'kepersertaan-akuns?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function kepersertaanAkunById(store) {
    let loadAPI = 'kepersertaan-akuns'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function kepersertaanAkunPasif(store) {
    let loadAPI = 'kepersertaan-products/getAllByProductIdAndAkunStatus/1%2C2/2%2C3%2C4%2C5%2C7?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function pesertaAkunStatus(store) {
    let loadAPI = 'akun-statuses'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function pesertaFamiliesAll(store, useDevextreme = false) {
    let loadAPI = 'peserta-families'

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND_CORE, store, loadAPI)

        return dataSource
    }
}
export function pesertaFamilies(pesertaId, store, useDevextreme = false) {
    let loadAPI = 'simple-peserta-families/getAllPesertaFamiliesByPesertaId/' + pesertaId

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND_CORE, store, loadAPI)

        return dataSource
    }
}
export function tipeTransaksiKepesertaan(store) {
    let loadAPI = 'kepersertaan-transaction-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function tipePembayaranMp(store) {
    let loadAPI = 'mp-payment-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function rekBank(store) {
    let loadAPI = 'rekbanks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function rekCustody(store) {
    let loadAPI = 'rek-custodies'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function mpTypeNid2(store, useDevextreme = false) {
    let loadAPI = 'manfaat-pensiun-types/getByCategoryNid/2'

    if (useDevextreme) {
        return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
    } else {
        var dataSource = httpRequest(process.env.REACT_APP_BACKEND_CORE, store, loadAPI)

        return dataSource
    }
}

export function pensiunType(store) {
    let loadAPI = 'pensiun-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function statusPeserta(store) {
    let loadAPI = 'peserta-statuses'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function iuranType(store) {
    let loadAPI = 'iuran-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function dokumenType(store) {
    let loadAPI = 'dokumen-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function jabatan(store) {
    let loadAPI = 'jabatans?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function jabatanName(store) {
    let loadAPI = 'jabatans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function jabatanGolongan(store) {
    let loadAPI = 'jabatan-golongans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function statusPesertaHrd(store) {
    let loadAPI = 'status-pesertas?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function instruments(store) {
    let loadAPI = 'instruments?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function getInstrumentById(store) {
    let loadAPI = 'instruments'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentTransactionTypes(store) {
    let loadAPI = 'instrument-transaction-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}

export function instrumentPortoCorporate(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-corporate?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoDeposito(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-deposito?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoEba(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-eba?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoEtf(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-etf?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoPenyertaanModal(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-penyertaanModal?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoProperti(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-properti?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoRekeningBank(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-rekeningBank?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoReksadana(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-reksadana?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoSaham(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-saham?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoSbn(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-sbn?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoRepo(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-repo?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoNonPortoLainnya(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-nonPortoLainnya?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoSuratUtang(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-suratUtang?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPortoCurrentAccount(store, productId) {
    let loadAPI = `efek-balance-avgs/instrument-portofolio-currentAccount?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}

export function instrumentSaham(store) {
    let loadAPI = 'instruments/saham'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentReksadana(store) {
    let loadAPI = 'instruments/reksadana?size=999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentObligasi(store) {
    let loadAPI = 'instruments/obligasi?size=999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentEba(store) {
    let loadAPI = 'instruments/eba'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function orderPropertiOpen(store) {
    let loadAPI = 'orders?orderTypeId.in=284&orderTypeId.in=283&statusId.in=OPEN'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function orderPlOpened(store, transactionDate) {
    let loadAPI = `orders?orderDate.equals=${transactionDate}&orderTypeId.in=291&orderTypeId.in=292&statusId.in=OPEN`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentSbn(store) {
    let loadAPI = 'instruments/sbn'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentPenyertaanLangsung(store) {
    let loadAPI = 'instruments/penyertaan-langsung'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentMtn(store) {
    let loadAPI = 'instruments?categorySubId.in=29&categorySubId.in=30'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentProperti(store) {
    let loadAPI = `instruments?categorySubId.in=34&categorySubId.in=35&categorySubId.in=36&categorySubId.in=37&categorySubId.in=38&categorySubId.in=39`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentCorporate(store) {
    let loadAPI = 'instruments/corporate'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentRepo(store) {
    let loadAPI = 'instruments/repo'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentCurrentAccount(store) {
    let loadAPI = 'instruments/current-account'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentNonPortoLainnya(store) {
    let loadAPI = 'instruments/non-portofolio-lainnya'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentsCategorySaham(store) {
    let loadAPI = 'instrument-category-subs/saham'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentsCategoryProperti(store) {
    let loadAPI = 'instrument-category-subs?instrumentCategoryId.in=8'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentsCategoryEtf(store) {
    let loadAPI = 'instrument-category-subs/etf'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentsCategoryObli(store) {
    let loadAPI = 'instrument-category-subs/obligasi'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rekBankByProduct(store, productId) {
    let loadAPI = `rek-banks-by-productId?productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function sppdType(store) {
    let loadAPI = 'sppd-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function sppdBiaya(store) {
    let loadAPI = 'sppd-biayas'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function groupKompetensi(store) {
    let loadAPI = 'group-kompetensis'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function sppdBiayaType(store) {
    let loadAPI = 'sppd-biaya-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function fixedAssetGroups(store) {
    let loadAPI = 'fixed-asset-groups'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC)
}
export function fixedAsset(store) {
    let loadAPI = 'assets?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function fixedAssetByStatus(store, status) {
    let loadAPI = `fixed-asset/getAllByStatus/${status}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, null, false)
}
export function kpds(store) {
    let loadAPI = 'kpds'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function kpas(store) {
    let loadAPI = 'kpas'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function orders(store) {
    let loadAPI = 'orders'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function ordersOpen(store) {
    let loadAPI = 'orders?status.in=1'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function stock(store) {
    let loadAPI = 'instruments/saham'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function reksadana(store, miId) {
    let loadAPI = `instruments/reksadana?miId=${miId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function getAllReksadana(store) {
    let loadAPI = 'instruments/reksadana'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentEtf(store) {
    let loadAPI = 'instruments/etf'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function broker(store) {
    let loadAPI = 'brokers'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function brokerStaticData(store) {
    let loadAPI = 'brokers'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentSubCategoryDeposito(store) {
    let loadAPI = 'instrument-category-subs/deposito'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI, false, false)
}
export function deposito(store) {
    let loadAPI = 'instruments/deposito'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function instrumentSubCategoryReksadana(store) {
    let loadAPI = 'instrument-category-subs/reksadana'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI, null, false)
}
export function instrumentSubCategoryPenyertaan(store) {
    let loadAPI = 'instrument-category-subs?instrumentCategoryId.in=11'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI, null, false)
}
export function instrumentSubCategoryById(store) {
    let loadAPI = 'instrument-category-subs'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function fundManager(store) {
    let loadAPI = 'fund-managers'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function programs(store) {
    let loadAPI = 'programs'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}
export function programSdm(store) {
    let loadAPI = 'programs'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function aperds(store) {
    let loadAPI = 'aperds'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function komponenGaji(store) {
    let loadAPI = 'gaji-komponens'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function rekeningBank(store) {
    let loadAPI = 'rek-banks'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rekeningBroker(store) {
    let loadAPI = 'rek-brokers'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rekeningBrokerGetByBrokerId(store, brokerId) {
    let loadAPI = `rek-brokers?brokerId.in=${brokerId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rekeningMi(store) {
    let loadAPI = 'rek-mis'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rekMiBymiId(store, miId) {
    let loadAPI = `rek-mis?miId.in=${miId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function depositoNeddConfirmByBank(store, bankId) {
    let loadAPI = `order-deposito-penempatan-need-confirm/list?bankId=${bankId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function depositoWasSent(store) {
    let loadAPI = 'order-depositos/list?status=INSTRUKSI_SENT'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function akunInvestasi(store) {
    let loadAPI = 'akuns'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function akunGroupInvestasi(store) {
    let loadAPI = 'akun-groups'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function ratings(store) {
    let loadAPI = 'ratings'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function efekAkunInvestasi(store) {
    let loadAPI = 'efek-akuns'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function sysGroup(store) {
    let loadAPI = 'sys-groups'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function sysMenu(store) {
    let loadAPI = 'sys-menus'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function stokNeedConfirmByBroker(store, brokerId, productId, orderDate) {
    let loadAPI = `order-stok-pembelian-need-confirm/list?brokerId=${brokerId}&orderDate=${orderDate}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function etfNeedConfirmByBroker(store, brokerId, productId, orderDate) {
    let loadAPI = `order-etf-pembelian-need-confirm/list?brokerId=${brokerId}&orderDate=${orderDate}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function etfSellNeedConfirmByBroker(store, brokerId, productId, orderDate) {
    let loadAPI = `order-etf-penjualan-need-confirm/list?brokerId=${brokerId}&orderDate=${orderDate}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function stokSellNeedConfirmByBroker(store, brokerId, productId, orderDate) {
    let loadAPI = `order-stok-penjualan-need-confirm/list?brokerId=${brokerId}&orderDate=${orderDate}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function availableInstrumentStok(store, productId) {
    let loadAPI = `efek-balance-avgs/all-instrument-stok?kdpId=1&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function availableInstrumentEtf(store, productId) {
    let loadAPI = `efek-balance-avgs/all-instrument-etf?kdpId=1&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function availableInstrumentSbn(store, productId, kpdId) {
    let loadAPI = `efek-balance-buy-dates/get-list-simple/${productId}/${kpdId}/4`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function availableInstrumentMtn(store, productId, kpdId) {
    let loadAPI = `efek-balance-buy-dates/get-list-simple/${productId}/${kpdId}/6`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function availableInstrumentEba(store, productId, kpdId) {
    let loadAPI = `efek-balance-buy-dates/get-list-simple/${productId}/${kpdId}/7`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function availableInstrumentCorporate(store, productId, kpdId) {
    let loadAPI = `efek-balance-buy-dates/get-list-simple/${productId}/${kpdId}/5`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function availableInstrumentRd(store, productId) {
    let loadAPI = `efek-balance-avgs/all-instrument-rd?kdpId=1&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function needSettleSbnPenempatan(store, productId, institusiId, institusiBroker, categoryId) {
    let loadAPI = `order-obligasi-penempatan-need-confirm/list?categoryId=${categoryId}&institusiId=${institusiId}&institusiOrderId=${institusiBroker}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function needSettleSbnPelepasan(store, productId, institusiId, institusiBroker) {
    let loadAPI = `order-obligasi-pelepasan-need-confirm/list?institusiId=${institusiId}&institusiOrderId=${institusiBroker}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function needSettleObliBondSukukPelepasan(store, categoryId, kpdId, productId) {
    let loadAPI = `efek-balance-buy-dates?categoryId.equals=${categoryId}&faceValue.greaterThan=0&kpdId.equals=${kpdId}&productId.equals=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function catNameObliBondSukukPelepasan(store) {
    let loadAPI = `instrument-categories?id.in=4&id.in=5&id.in=7`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function rdNeedConfirmByMi(store, miId, orderDate, productId) {
    let loadAPI = `order-rd-pembelian-need-confirm/list?miId=${miId}&orderDate=${orderDate}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function needSettleDepositoPelepasan(store, bankId, kpdId, productId) {
    let loadAPI = `efek-balance-avgs/all-need-settle-instrument-deposito-pelepasan?bankId=${bankId}&kpdId=${kpdId}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function needSettleDepositoPerpanjangan(store, bankId, kpdId, productId) {
    let loadAPI = `efek-balance-avgs/all-need-settle-instrument-deposito-perpanjangan?bankId=${bankId}&kpdId=${kpdId}&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function efekBalanceAvg(store, productId, bankId) {
    let loadAPI = `efek-balance-avgs/all-instrument-deposito?bankId=${bankId}&kdpId=1&productId=${productId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function efekBalanceBuyDate(store) {
    let loadAPI = `efek-balance-buy-dates?size=999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function efekBalanceBuyDateInstrumentId(store, instrumentId) {
    let loadAPI = `efek-balance-buy-dates?instrumentId.in=${instrumentId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function statusKeuangan(store) {
    let loadAPI = 'status-keuangans'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function corpactType(store) {
    let loadAPI = 'corpact-types'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}
export function kjpp(store) {
    let loadAPI = 'kjpps'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND)
}
export function manfaatPensiunType(store) {
    let loadAPI = `manfaat-pensiuns/get-by-categoryNid/2`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function mpType(store) {
    let loadAPI = `manfaat-pensiun-types`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function mpPenerimaType(store) {
    let loadAPI = `manfaat-pensiun-penerima-types`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE)
}

export function sektors(store) {
    let loadAPI = `sektors`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI)
}

export function sdmUnit(store) {
    let loadAPI = 'units?size=9999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}

export function sdmSupplier(store) {
    let loadAPI = 'suppliers?size=99999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}

export function assetSdm(store) {
    let loadAPI = 'assets?size=999999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, 'assetKode', false)
}

export function assetStatus(store) {
    let loadAPI = 'asset-statuses'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}

export function dasarNilai(store) {
    let loadAPI = 'dasar-nilais'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD)
}
export function users(store, sortBy = 'firstName', filterKey) {
    let loadAPI = 'users?size=999'

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND, sortBy, true, null, filterKey)
}

export function parentSektors(store) {
    let loadAPI = `sektors`

    var customProcess = (data) => {
        data = data.filter(value => value.parentSektorId === null)
        return data
    }

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI, 'id', true, customProcess)
}

export function sektorsByParent(store, parentSektorId) {
    let loadAPI = `sektors/get-by-parentSektorId/${parentSektorId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI, 'id', false)
}

export function budgetYear() {
    var date = new Date()
    var year = date.getFullYear()

    var yearList = []
    yearList.push(year + 1)
    yearList.push(year)

    for (var i = 1; i <= 2; i++) {
        yearList.push(year - i)
    }

    return yearList
}

export function reportHeaderFilter(store, reportType) {
    let loadAPI = `report-headers/getAllReportType/${reportType}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, 'reportCode', false)
}

export function reportHeaderDetailFilterHeader(store, headerId) {
    let loadAPI = `report-header-details/getAllByReportHeaderId/${headerId}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, 'seqNo', false)
}

export function reportHeaderDetail(store) {
    let loadAPI = `report-header-details?size=99999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, 'seqNo', false)
}

export function anggaranItem(store) {
    let loadAPI = `anggaran-items?size=9999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, null, false)
}

export function anggaranItemPerUnit(store, unit) {
    let loadAPI = `anggaran-items/findAllByUnitId/${unit}`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, null, false)
}

export function anggaranSubType(store) {
    let loadAPI = `anggaran-sub-types?size=9999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, null, false)
}

export function anggaranType(store) {
    let loadAPI = `anggaran-types?size=9999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, null, false)
}
export function absenType(store) {
    let loadAPI = `absen-types?size=9999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function phkTypeSdm(store) {
    let loadAPI = `phk-types?size=9999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function pensiunTypeSdm(store) {
    let loadAPI = `pensiun-types?size=999`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_HRD, null, false)
}
export function getKomposisi(store) {
    let loadAPI = `efek-balance-avgs/get-komposisi-investasi`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI, null, false)
}

export function getRoaProduct(store, sysDate) {
    let loadAPI = `roa-dashboard/${sysDate}/1`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_ACC, null, false)
}

export function getKinerjaInv(store) {
    let loadAPI = `view-roi-per-instruments?productId.in=1`

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_INVESTASI, null, false)
}

export function getPesertaStatuses(store) {
    let loadAPI = `peserta-statuses/getAllPesertaStatus`;

    return templateCustomStoreLookup(loadAPI, store, process.env.REACT_APP_BACKEND_CORE, null, false);
}

export function anggaranYear() {
    var data = [
        {
            value: 2019
        },
        {
            value: 2020
        },
        {
            value: 2021
        },
        {
            value: 2022
        },
        {
            value: 2023
        },
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'value'
    });

    return dataSource
}

export function modaTransportasi() {
    var data = [
        {
            id: 1,
            value: 'Pesawat'
        },
        {
            id: 2,
            value: 'Kereta'
        },
        {
            id: 3,
            value: 'Kendaraan'
        }
    ]

    let dataSource = new ArrayStore({
        data: data,
        key: 'id'
    });

    return dataSource
}

export function tipeSolvabilitas(store) {
    let loadApi = 'solvabilitas-types';

    return templateCustomStoreLookup(loadApi, store, process.env.REACT_APP_BACKEND_ACC, null, false);
}