import React, { Component } from 'react'
import { emiten } from '../parts/staticDataColumnConfig'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormEditorEmiten from '../modal/static-data/emiten/form'
import { parentSektors, sektors, papanPencatatan, sektorsByParent } from 'dataSource/lookup'

class Emiten extends Component {
    constructor(props) {
        super(props)

        this.datagridRef = React.createRef()

        this.columns = emiten(this.props.store,this.datagridRef)

        this.modalEditorRef = React.createRef()
        this.modalUploadBankRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalEditor = (data) => {
        this.modalEditorRef.current.show(data)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'upload',
            //         text: 'Upload Data Performa',
            //         onClick: (e) => {
            //             this.showModalUpload()
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalEditor()
                    },
                }
            }
        )
    }

    formItem = (ref) => [
        {
            dataField: 'emitenCode',
            label: {
                alignment: 'left',
                text: 'Kode Emiten',
                location: 'left',
            },
            editorType: 'dxTextBox',
        },
        {
            dataField: 'emitenName',
            label: {
                alignment: 'left',
                text: 'Nama Emiten',
                location: 'left',
            },
            editorType: 'dxTextBox',
        },
        {
            dataField: 'industriUtama',
            label: {
                alignment: 'left',
                text: 'Industri Utama',
                location: 'left',
            },
            editorType: 'dxTextBox',
        },
        {
            dataField: 'sektorId',
            label: {
                alignment: 'left',
                text: 'Sektor',
                location: 'left',
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: parentSektors(this.props.store),
                valueExpr: 'id',
                displayExpr: 'sektorName',
                onValueChanged: () => {
                    console.log(this.datagridRef.current)
                }
            },
        },
        {
            dataField: 'subSektorId',
            label: {
                alignment: 'left',
                text: 'Sub Sektor',
                location: 'left',
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: sektors(this.props.store),
                valueExpr: 'id',
                displayExpr: 'sektorName',
            }
        },
        {
            dataField: 'papanPencatatan',
            label: {
                alignment: 'left',
                text: 'Papan Pencatatan',
                location: 'left',
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: papanPencatatan(),
                valueExpr: 'id',
                displayExpr: 'value',
            }
        }
    ]

    // showModalUpload = () => {
    //     this.modalUploadBankRef.current.show()
    // }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Emiten</h2>

                <DevExpressDataGrid
                    ref={this.datagridRef}

                    loadAPI='emitens'
                    insertAPI='staticdata-wf/emitens'
                    updateAPI='staticdata-wf/emitens'
                    deleteAPI='staticdata-wf/emitens' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"Emiten"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Emiten Data'}
                    popupWidth={400} //masukan dalam ukuran pixel
                    popupHeight={350} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    // formItems = {this.formItem}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormEditorEmiten ref={this.modalEditorRef} store={this.props.store} forceRefresh={this.forceRefresh}/>
            </div>
        )
    }
}

export default Emiten
