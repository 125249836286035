import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, departments, depreciationMethod, coa } from "dataSource/lookup";

class ModalFixedAssetGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      assetId: '',
      formData: {},
      actionType: 'add'
    };

    this.form = [
      {
        itemType: 'group',
        colCount: 2,
        items: [
          {
            dataField: 'assetGroupCode',
            label: {
              text: 'Grup'
            },
            editorOptions: {
              placeholder: 'Kode Grup'
            }
          },
          {
            dataField: 'assetGroupName',
            label: {
              visible: false
            },
            editorOptions: {
              placeholder: 'Nama Grup'
            }
          },
          {
            dataField: 'depreciationMethod',
            label: {
              text: 'Metode Penyusutan'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: depreciationMethod(),
              valueExpr: 'id',
              displayExpr: 'value',
              value: 'depreciationMethod'
            },
            colSpan: 2
          },
        ]
      },
      {
        itemType: 'group',
        colCount: 2,
        items: [
          {
            dataField: 'departmentId',
            label: {
              text: 'Departemen'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: departments(this.props.store),
              valueExpr: 'id',
              displayExpr: 'departmentCode',
              searchEnabled: true,
              deferRendering: false,
              value: 'departmentId',
              onValueChanged: async (e) => {
                var departmentName = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `departments/${e.value}`, 'GET')
                this.formRef.current.instance.updateData('departmentName', departmentName.departmentName)
              }
            }
          },
          {
            dataField: 'departmentName',
            label: {
              visible: false
            },
            editorOptions: {
              readOnly: true
            },
            editorOptions: {
              placeholder: 'Nama Departemen'
            }
          },
          {
            dataField: 'economicLife',
            label: {
              text: 'Umur Ekonomis (Bulan)'
            },
            colSpan: 2
          },
          {
            dataField: 'assetType',
            label: {
              text: 'Kelompok Aset'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: [
                {
                  id: 'TNBG',
                  value: 'Tanah dan Bangunan'
                },
                {
                  id: 'KNDR',
                  value: 'Kendaraan'
                },
                {
                  id: 'PKOM',
                  value: 'Peralatan Komputer'
                },
                {
                  id: 'PKAN',
                  value: 'Peralatan Kantor'
                },
                {
                  id: 'ASOL',
                  value: 'Aset Operasional Lain'
                },
                {
                  id: 'ASLN',
                  value: 'Aset Lain'
                },
              ],
              valueExpr: 'id',
              displayExpr: 'value',
            },
            colSpan: 2
          },
        ]
      },
      {
        itemType: 'group',
        colCount: 1,
        items: [
          
          {
            dataField: 'assetGroupDesc',
            label: {
              // location: 'left',
              text: 'Deskripsi'
            },
            editorType: 'dxTextArea',
          },
        ]
      },
      {
        itemType: 'group',
        caption: 'Konfigurasi COA',
        items: [
          {
            dataField: 'assetCoaId',
            label: {
              text: 'Aset'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: coa(this.props.store),
              valueExpr: 'id',
              displayExpr: (item) => {
                  return item && item.coaCode + ' - ' + item.coaName
              },
              searchEnabled: true,
              wrapItemText: true
            }
          },
          {
            dataField: 'accumDepreciationCoaId',
            label: {
              text: 'Akum. Penyusutan'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: coa(this.props.store),
              valueExpr: 'id',
              displayExpr: (item) => {
                  return item && item.coaCode + ' - ' + item.coaName
              }, 
              searchEnabled: true,
              wrapItemText: true
            },
            value: 'accumDepreciationCoaId'
          },
          {
            dataField: 'depreciationCoaId',
            label: {
              text: 'Beban Penyusutan'
            },
            editorType: 'dxSelectBox',
            editorOptions: {
              dataSource: coa(this.props.store),
              valueExpr: 'id',
              displayExpr: (item) => {
                  return item && item.coaCode + ' - ' + item.coaName
              }, 
              searchEnabled: true,
              wrapItemText: true
            },
            value: 'depreciationCoaId'
          },
        ]
      }
    ]

    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: async () => {
            this.submitData()
          }
        },
        toolbar: "bottom"
      },
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Batal",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.formRef = React.createRef()
  }


  retrieveData = async (data = null, type) => {
    if (type !== 'add') {
      this.setState({
        assetId: data.id,
        actionType: type,
        formData: data
      })
    } else {
      this.setState({
        assetId: 0,
        actionType: type,
        formData: {}
      })
    }
  }

  submitData = async () => {
    var dataMaster = this.state.formData
    if (dataMaster) {
      try {
        if (this.state.actionType == 'add') {
          var insertAPI = 'fixed-asset-groups'
          var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, insertAPI, 'POST', {
            values: dataMaster
          }
          )
          // console.log(response)
          if (response) {
            notify({ message: 'Data berhasil ditambahkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            this.props.forceRefresh()
            this.hide()
          }
        } else {
          var updateAPI = 'fixed-asset-groups'
          var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, updateAPI, 'PUT', {
            key: dataMaster.id,
            values: dataMaster
          },
            dataMaster)
          console.log(response)
          if (response) {
            notify({ message: 'Data ' + response.assetGroupName + ' berhasil diubah!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            this.props.forceRefresh()
            this.hide()
          }
        }
        return response
      } catch (e) {
        console.log(e)
        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
      }
    }
  }

  show = () => {
    this.setState({
      popupVisible: true
    });
  };

  hide = () => {
    this.setState({
      popupVisible: false,
    });
  };


  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          dragEnabled={false}
          showTitle={true}
          title={(this.state.actionType == 'edit' ? 'Ubah' : 'Tambah') + " Fixed Asset Group"}
          width={"60vw"}
          height={"60vh"}
          toolbarItems={this.PopupToolbarItem}
        >
          <Form
            colCount={1}
            id={"formMaster"}
            store={this.props.store}
            formData={this.state.formData}
            items={this.form}
            ref={this.formRef}
            height={'500px'}
            labelLocation="left"
          />

        </Popup>
      </React.Fragment>

    );
  }
}

export default ModalFixedAssetGroup