import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { mpType, product, rekBank } from 'dataSource/lookup';

class ModalProsesTransaksiMpDitunda extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        // console.log(this.state.formData)
                        this.props.editDataIuran(this.state.formData)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'iuranTypeId',
                label: {
                    alignment: 'left',
                    text: 'Jenis Iuran'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
            },
            {
                dataField: 'akunId',
                label: {
                    alignment: 'left',
                    text: 'Akun ID'
                },
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Pensiunan'
                },
            },
            {
                dataField: 'mpTypeId',
                label: {
                    alignment: 'left',
                    text: 'Tipe Manfaat Pensiun'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: mpType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'mpName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'jumlahBulanPending',
                label: {
                    alignment: 'left',
                    text: 'Jumlah Bulang Pending'
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'totalNominalMp',
                label: {
                    alignment: 'left',
                    text: 'Total Nominal MP'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'totalPajak',
                label: {
                    alignment: 'left',
                    text: 'Total Pajak'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'totalRapel',
                label: {
                    alignment: 'left',
                    text: 'Total Rapel'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'totalPotongan',
                label: {
                    alignment: 'left',
                    text: 'Total Potongan'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'totalBiaya',
                label: {
                    alignment: 'left',
                    text: 'Total Biaya'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                dataField: 'netNominalTransfer',
                label: {
                    alignment: 'left',
                    text: 'Net Nominal Transfer'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },
            {
                itemType: 'group',
                caption: 'Rekening Pembayar',
                items: [
                    {
                        dataField: 'tanggalProses',
                        label: {
                            text: 'Tanggal Proses'
                        },
                        editorType: 'dxDateBox',
                        editorOptions:{
                            placeholder: 'dd MMM yyyy',
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%'
                        },
                    },
                    {
                        dataField: 'paymentRekbankId',
                        label: {
                            text: 'Rekening Pembayar'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'rekName',
                            searchEnabled: true,
                            deferRendering: false
                        },
                    },
                    {
                        dataField: 'feeBank',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        }
                    },
                ]
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,         
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    retrieveData = (data) => {
        this.setState({
            formData: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Proses Pembayaran'} 
                minWidth={500}
                minHeight={300}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataProsesMpDitunda'}
                                formData={this.state.formData}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation = 'left'
                            />
                        </div>
                    </div>

                </div>
            </Popup>
        );
    }
}


export default ModalProsesTransaksiMpDitunda