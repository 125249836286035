import React, { Component } from 'react'
import {Popup, Form, FileUploader} from 'devextreme-react';
import { formatDate, formatUploadFileData } from 'plugin/helper';
import { grade, jabatan, karyawan, sdmUnit, unit } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { imageSource } from 'plugin/helper';

import notify from "devextreme/ui/notify";
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class FormModalPromosi extends Component {
    constructor(props){
        super(props)

        this.state = {
            fileUploadReceipt:[],
            DataMaster:{},
            DataDocument:{},
            popupVisible:false
        }

        this.form = [
            {
                dataField:'karyawanId',
                label:{
                    text:'Karyawan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: (e) => {
                        if (e.selectedItem) {
                            this.formRef.current.instance.updateData({ 
                                oldJabatanId: e.selectedItem.jabatanId, 
                                oldUnitId: e.selectedItem.unitId,
                                oldGradeId:e.selectedItem.gradeId,
                                oldGajiPokok: e.selectedItem.gajiPokok,
                            })
                        }
                    }
                },
            },
            {
                dataField:'tanggalPengajuan',
                label:{
                    text:'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }  
            },
            {
                dataField:'noSk',
                label:{
                    text:'No SK'
                }
            },
            {
                dataField:'tanggalSk',
                label:{
                    text:'Tanggal SK'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }  
            },
            {
                dataField:'tanggalBerlaku',
                label:{
                    text:'Tanggal Efektif'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }  
            },
            {
                itemType:'group',
                caption:'Jabatan',
                items:[
                    {
                        dataField:'oldJabatanId',
                        label:{
                            text:'Lama'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true,
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                        },
                    },
                    {
                        dataField:'newJabatanId',
                        label:{
                            text:'Baru'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                        },
                    },
                ]   
            },
            {
                itemType:'group',
                caption:'Grade',
                items:[
                    {
                        dataField:'oldGradeId',
                        label:{
                            text:'Lama'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: grade(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'gradeCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField:'newGradeId',
                        label:{
                            text:'Baru'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: grade(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'gradeCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                ]   
            },
            {
                itemType:'group',
                caption:'Unit Kerja',
                items:[
                    {
                        dataField:'oldUnitId',
                        label:{
                            text:'Lama'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true,
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                        },
                    },
                    {
                        dataField:'newUnitId',
                        label:{
                            text:'Baru'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values
                        },
                    },
                ]   
            },
            {
                itemType:'group',
                caption:'Gaji Pokok',
                items:[
                    {
                        dataField:'oldGajiPokok',
                        label:{
                            text:'Lama'
                        },
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0.00',
                            readOnly:true
                        }
                    },
                    {
                        dataField:'newGajiPokok',
                        label:{
                            text:'Baru'
                        },
                        
                        editorType:'dxNumberBox',
                        editorOptions:{
                            format:'#,##0.00'
                        }
                    },
                ]   
            },
        ]


        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Close",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Update",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Close",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
        this.modalPreviewPdfRef = React.createRef()
        this.fileUploderRef = React.createRef()
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster:data,
            DataDocument:{
                dokumenFileName:data.dokumenFileName,
                dokumenFile:data.dokumenFile,
                dokumenFileConte:data.dokumenFileConte,
            }
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => { 
        this.newData = true
        this.setState({
            DataMaster:{},
            DataDocument:{},
            popupVisible: false
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];
            
            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocument };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[fieldName] = formattedFileData.base64data;
                DataDocument[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataDocument: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    submitData = async () => {
        try {
            var Data = {...this.state.DataMaster,...this.state.DataDocument}
            Data.tanggalPengajuan = formatDate(Data.tanggalPengajuan)
            Data.tanggalSk = formatDate(Data.tanggalSk)
            Data.tanggalBerlaku = formatDate(Data.tanggalBerlaku)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'karyawan-promosis',"POST", {
                values: Data
            });
            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    updateData = async () => {
        try {
            var Data = {...this.state.DataMaster,...this.state.DataDocument}
            Data.tanggalPengajuan = formatDate(Data.tanggalPengajuan)
            Data.tanggalSk = formatDate(Data.tanggalSk)
            Data.tanggalBerlaku = formatDate(Data.tanggalBerlaku)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'karyawan-promosis',"PUT", {
                values: Data,
                key:Data.id
            },Data);
            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    previewFile = async () => {
        var src = imageSource(this.state.DataMaster.dokumenFileContentType, this.state.DataMaster.dokumenFile)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Promosi'} 
                width={700}
                height={800}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <Form
                ref={this.formRef}
                colCount={1}
                id={'formPotongan'}
                formData={this.state.DataMaster}
                items={this.form}
                labelLocation = 'left'
            />
            <div>
                <div className='d-flex justify-content-between align-items-centers'>
                    <FileUploader
                        accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)} 
                        labelText="Upload File Surat Keputusan"
                        showFileList={false}
                        name={'dokumenFile'}
                        disabled={this.state.type === "lihat" ? true : false }
                        value = {this.state.fileUploadReceipt}
                        ref={this.fileUploderRef}
                    />
                    <div className="col-md-6 text-right">
                        <button onClick={() => this.previewFile('file')} className={`btn btn-light btn-sm border rounded bg-button-popup-preview-dapen-default mr-2 ${this.state.DataMaster.dokumenFile ? '' : 'd-none'}`} style={{ width: '122px', height: '28px' }}>
                            <p style={{ marginTop: '-1px' }}>Preview</p>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <label className="col-4">File Name:</label>
                    <label className="col-8">{ this.state.DataDocument.dokumenFileName}</label>
                </div>

            </div>

            <ModalPreviewPdf 
                ref={this.modalPreviewPdfRef}
                store={this.props.store}
            />
            </Popup>
            
        )
    }
}

export default FormModalPromosi