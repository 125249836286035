import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { karyawan } from 'dataSource/lookup';
import FormPengajuanPemberhentian from 'components/pages/modal/sdm-umum/kepegawaian/pemberhentian/pengajuan';

class DaftarPemberhentian extends Component {
    constructor(props){
        super(props)

        this.modalEditRef = React.createRef()

        this.pengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        onClick : (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text : 'Disetujui',
                        hint : 'Disetujui',
                        onClick : (e) => {
                            console.log("new")
                        }
                    },
                    {
                        text : 'Tolak',
                        hint : 'Tolak',
                        onClick : (e) => {
                            console.log("new")
                        }
                    },
                ],
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'karyawanId',
                caption: 'Karyawan'
            },
            {
                dataField: 'jenisBerhenti',
                caption: 'Jenis Pemberhentian'
            },
            {
                dataField: 'besaranPesangon',
                caption: 'Besaran Pesangon',
                alignment: 'center',
                columns:[
                    {
                        dataField: 'gaji',
                        caption: 'Gaji (%)'
                    },
                    {
                        dataField: 'nominal',
                        caption: 'Nominal'
                    },
                ]
            },
            {
                dataField: 'totalNominalPesangon',
                caption: 'Total Nominal Pesangon'
            },
            {
                dataField: 'tglPengajuan',
                caption: 'Tanggal Pengajuan'
            },
            {
                dataField: 'tglEfektif',
                caption: 'Tanggal Efektif'
            },
            {
                dataField: 'tglPembayaranPesangon',
                caption: 'Tanggal Pembayaran Pesangon'
            },
            {
                dataField: 'kepesertaanDapen',
                caption: 'Kepesertaan Dapen'
            },
            {
                dataField: 'status',
                caption: 'Status'
            },
            
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalEdit = (data) => {
        this.modalEditRef.current.retrieveData(data)
        this.modalEditRef.current.show()
    }
    
    loadDummyDataPengajuan = () => {
        var dummy = [
            {
                id : 1,
                nik: 12351323523,
                karyawanId: "Hartono",
                jenisBerhenti: "PHK",
                gaji:10,
                nominal:10000000,
                totalNominalPesangon:20000000,
                tglPengajuan:"20/20/2020",
                tglEfektif:"15/20/2020",
                tglPembayaranPesangon:"18/20/2020",
                kepesertaanDapen:"",
                status:'Pengajuan'
            }
        ]
        return dummy
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pemberhentian</h3>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='regist-pengkinian-pengajuan-aktifs'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData= {this.loadDummyDataPengajuan}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Pemberhentian"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Pemberhentian'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.pengajuanColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    // onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormPengajuanPemberhentian
                    ref={this.modalEditRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default DaftarPemberhentian