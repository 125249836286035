import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalPembatalanCuti from 'components/pages/modal/sdm-umum/kepegawaian/cuti-lembur/pembatalan-cuti/form';
import { karyawan, months, status } from 'dataSource/lookup';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import notify from 'devextreme/ui/notify'
import {Form} from 'devextreme-react'
import { formatDefaultFullDate } from 'plugin/helper';
import { showLoading } from 'redux/actions/loading';

class KepegawaianPembatalanCuti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {},
            selectedTabIndex: 0,
        }

        this.formMaster = [
            {
                dataField: "bulan",
                label: {
                    text: "Bulan",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled:true
                }
            },
            {
                dataField: "tahun",
                label: {
                    text: "Tahun",
                    alignment: "left",
                    location: "left",
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalEditPembatalanCuti(e.row.data, 'edit')
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Ingin mengirim data pengajuan ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.sendCancelCuti(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            let data = e.row.data
                            this.batalCuti(data)
                        }
                    },
                ],
            },
            {
                dataField: "pembatalanNo",
                caption: "No Pembatalan",
            },
            {
                dataField: "pembatalanTanggal",
                caption: "Tanggal Pembatalan",
                dataType: "date",
                format: "dd MMM yyyy"
            },
            {
                dataField: "karyawanId",
                caption: "Karyawan",
                lookup: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function (item) {
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: "tanggalCuti",
                caption: "Tanggal Cuti",
                alignment: "center",
                columns: [
                    {
                        dataField: "tanggalAwal",
                        caption: "Dari",
                        dataType: "date",
                        format: "dd MMM yyyy"
                    },
                    {
                        dataField: "tanggalAkhir",
                        caption: "Sampai",
                        dataType: "date",
                        format: "dd MMM yyyy"
                    },
                ]
            },
            {
                dataField: "hari",
                caption: "Hari Cuti",
            },
            {
                dataField: "pembatalanHari",
                caption: "Hari Batal",
            },
            {
                dataField: "keterangan",
                caption: "Keterangan",
            },
            {
                dataField: "biayaPemanggilan",
                caption: "Biaya Pemanggilan",
                alignment: "center",
                columns: [
                    {
                        dataField: 'biayaPemanggilan',
                        caption: 'Biaya',
                    },
                    {
                        dataField: 'pembatalanBiaya',
                        caption: 'Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: status(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            }
        ]

        this.showModalPembatalanCutiRef = React.createRef()
        this.showModalEditPembatalanCutiRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.filterData = []
    }

    componentDidMount = async() => {
        this.defaultData()
    }

    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    
    defaultData = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-pembatalan-karyawan-cutis?pembatalan.in=true&statusId.in=6&statusId.in=5`,
            'GET'
        )
        this.filterData = datas
        if (this.filterData) {
            this.forceRefresh()
        }
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    sendCancelCuti = async(data) => {
        var id = {
            karyawanCutiId: data.id
        }
        if(data){
            this.props.store.dispatch(showLoading(true))
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_HRD,
                    this.props.store,
                    `send-pembatalan-karyawan-cuti`,
                    'POST',
                    {values: id}
                )
                if(response){
                    this.props.store.dispatch(showLoading(false))
                    notify({ message: `Sukses mengirimkan pembatalan Cuti!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    // this.dataGridRef.current.forceRefresh(true)
                    this.defaultData()
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    batalCuti = async(data) => {
        this.props.store.dispatch(showLoading(true))
        var getKaryawanCuti = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-cutis/${data.id}`,
            'GET'
        )
        getKaryawanCuti.approve = false
        getKaryawanCuti.karyawanCutiId = getKaryawanCuti.id
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-cutis/kirim-pengajuan`,
            'PUT', {
            values: getKaryawanCuti,
        }, getKaryawanCuti
        )
        if (response) {
            this.props.store.dispatch(showLoading(false))
            notify({ message: `Pembatalan Ditolak!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            this.defaultData()
        }
    }
    showModalPembatalanCuti = () => {
        this.showModalPembatalanCutiRef.current.show()
        this.showModalPembatalanCutiRef.current.generateNoPembatalan()
    }
    showModalEditPembatalanCuti = async(data, type) => {
        this.props.store.dispatch(showLoading(true))
        var dataDetailMaster = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-cutis/${data.id}`,
            'GET'
        )

        var dataDetailViewBatalMaster = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-pembatalan-karyawan-cutis/${data.id}`,
            'GET'
        )

        var dataDetailViewMaster = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-karyawan-cutis/${data.id}`,
            'GET'
        )

        var dataTanggal = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-cutis/findAllkaryawanCutiId/${data.id}`
        )

        var karCutiDetils = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cuti-detils/findAllByKaryawanCutiId/${data.id}`);
        var dataKarCutiDate = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawan-cuti-dates?size=9999', 'GET');

        let findStatusIdDetil = []
        for (let v of karCutiDetils) {
            var statusCutiDate = dataKarCutiDate.find(value => value.karyawanCutiDetilId === v.id);
            findStatusIdDetil.push(statusCutiDate)
        }

        var cutiDetil = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-cuti-detils/findAllByKaryawanCutiId/${data.id}`, 'GET');
        var resultTgl = []

        var dataMaster = {
            id: dataDetailViewMaster.id || 0,
            programId: dataDetailViewMaster.programId,
            noPengajuan: dataDetailViewMaster.pengajuanNo,
            tanggalPengajuan: dataDetailViewMaster.pengajuanTanggal,
            karyawanId: dataDetailMaster.karyawanId,
            jabatanId: dataDetailViewMaster.jabatanId,
            unitId: dataDetailViewMaster.unitId,
            periodeCutiId: dataDetailMaster.periodeCutiId,
            cutiTypeId: dataDetailViewMaster.cutiTypeId,
            tunjanganCuti: dataDetailViewMaster.tunjanganCuti,
            tunjanganTypeId: dataDetailViewMaster.tunjanganTypeId,
            tglPembayaran: dataDetailMaster.paymentDate,
            catatan: dataDetailViewBatalMaster.keterangan,
            tglInterval: dataDetailMaster.tanggalInterval,
            tglAwal: dataDetailViewMaster.tanggalAwal,
            tglAkhir: dataDetailViewMaster.tanggalAkhir,
            // jumlahHariCuti: dataDetailMaster.hari + listDataTanggal,
            jumlahHariCuti: dataDetailViewMaster.hari,
            lokasiCuti: dataDetailMaster.lokasiDalamNegeri,
            provinceId: data.provinceId,
            cityId: data.cityId,
            alamat: dataDetailMaster.alamat,
            noTelp: dataDetailMaster.noTelp
        }

        var dataInfoMaster = {
            id: dataDetailViewBatalMaster.id || 0,
            programId: dataDetailViewBatalMaster.programId,
            pembatalanNo: dataDetailViewBatalMaster.pembatalanNo,
            pembatalanTanggal: dataDetailViewBatalMaster.pembatalanTanggal,
            karyawanId: dataDetailViewBatalMaster.karyawanId,
            jabatanId: dataDetailViewBatalMaster.jabatanId,
            unitId: dataDetailViewBatalMaster.unitId,
            keterangan: dataDetailViewMaster.keterangan,
            cutiTypeId: dataDetailViewBatalMaster.cutiTypeId,
            hari: dataDetailViewBatalMaster.hari,
            tanggalAwal: dataDetailViewBatalMaster.tanggalAwal,
            tanggalAkhir: dataDetailViewBatalMaster.tanggalAkhir,
            pembatalanBiaya: dataDetailViewBatalMaster.pembatalanBiaya,
            provinceId: dataDetailViewBatalMaster.provinceId,
            cityId: dataDetailViewBatalMaster.cityId,
            statusId: dataDetailViewBatalMaster.statusId,
            pembatalan: dataDetailViewBatalMaster.pembatalan,
            pembatalanHari: dataDetailViewBatalMaster.pembatalanHari,
            biayaPemanggilan: dataDetailViewBatalMaster.biayaPemanggilan,
            paymentDate: dataDetailViewBatalMaster.paymentDate,
            transactionTypeId: dataDetailViewBatalMaster.transactionTypeId
        }
        var dataDokumen = {
            dokumenName: dataDetailMaster.pembatalanDokumenName,
            dokumenFileContentType: dataDetailMaster.pembatalanDokumenFileContentType,
            dokumenFile: dataDetailMaster.pembatalanDokumenFile,
        }
        for (let value of cutiDetil) {
            if (value.tanggal_1 && value.tanggal_2) {
                var tgl = {
                    id: value.id,
                    tglAwal: value.tanggal_1,
                    tglAkhir: value.tanggal_2,
                    jumlahHari: value.hari,
                    statusId: findStatusIdDetil.find(val => val.karyawanCutiDetilId === value.id).statusId,
                    karyawanCutiDetilId: value.id
                }
                resultTgl.push(tgl);
            } else {
                resultTgl.push();
            }
        }
        this.showModalEditPembatalanCutiRef.current.show()
        this.showModalEditPembatalanCutiRef.current.retrieveData(dataMaster, dataInfoMaster, resultTgl, dataDokumen, type)
        this.props.store.dispatch(showLoading(false))
    }
    // showModalEditPembatalanCuti = async(data, type) => {
    //     var dataDetailMaster = await httpRequest(
    //         process.env.REACT_APP_BACKEND_HRD,
    //         this.props.store,
    //         `karyawan-cutis/${data.id}`,
    //         'GET'
    //     )

    //     var dataTanggal = await httpRequest(
    //         process.env.REACT_APP_BACKEND_HRD,
    //         this.props.store,
    //         `karyawan-cutis/findAllkaryawanCutiId/${data.id}`
    //     )
    //     var resultTgl = []
    //     var dataMaster = {
    //         id: data.id || 0,
    //         programId: data.programId,
    //         karyawanId: dataDetailMaster.karyawanId,
    //         noPengajuan: dataDetailMaster.pengajuanNo,
    //         pembatalanTanggal: dataDetailMaster.pembatalanTanggal,
    //         noPembatalan: dataDetailMaster.pembatalanNo,
    //         biayaPemanggilan: dataDetailMaster.biayaPemanggilan,
    //         nominalBiaya: dataDetailMaster.pembatalanBiaya,
    //         tglPembayaran: dataDetailMaster.pembatalanPaymentDate,
    //         transactionTypeId: dataDetailMaster.transactionTypeId,
    //         tanggalPengajuan: dataDetailMaster.pengajuanTanggal,
    //         periodeCutiId: dataDetailMaster.periodeCutiId,
    //         cutiTypeId: dataDetailMaster.cutiTypeId,
    //         tunjanganCuti: dataDetailMaster.tunjanganCuti,
    //         tunjanganTypeId: dataDetailMaster.tunjanganTypeId,
    //         // tglPembayaran: dataDetailMaster.paymentDate,
    //         catatan: dataDetailMaster.keterangan,
    //         tglInterval: dataDetailMaster.tanggalInterval,
    //         tglAwal: dataDetailMaster.tanggalAwal,
    //         tglAkhir: dataDetailMaster.tanggalAkhir,
    //         jumlahHariCuti: dataDetailMaster.hari,
    //         lokasiCuti: dataDetailMaster.lokasiDalamNegeri,
    //         provinceId: data.provinceId,
    //         cityId: data.cityId,
    //         alamat: dataDetailMaster.alamat,
    //         noTelp: dataDetailMaster.noTelp
    //     }
    //     var dataDokumen = {
    //         dokumenName: dataDetailMaster.dokumenName,
    //         dokumenFileContentType: dataDetailMaster.dokumenFileContentType,
    //         dokumenFile: dataDetailMaster.dokumenFile,
    //     }
    //     for (var dataTglSpesifik of dataTanggal) {
    //         if (dataTglSpesifik.tanggal) {
    //             var tgl = {
    //                 id: dataTglSpesifik.id,
    //                 tglSpesifik: dataTglSpesifik.tanggal
    //             }
    //             resultTgl.push(tgl)
    //         } else {
    //             resultTgl.push()
    //         }
    //     }
    //     this.showModalEditPembatalanCutiRef.current.show()
    //     this.showModalEditPembatalanCutiRef.current.retrieveData(dataMaster, resultTgl, dataDokumen, type)
    // }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPembatalanCuti(e)
                    },
                }
            }
        )
    }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-pembatalan-karyawan-cutis?pembatalan.in=true&statusId.in=6`,
            'GET'
        )

        var listData = []
        for(var data of datas){
            data.pembatalanTanggal = new Date(data.pembatalanTanggal)
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => (value.pembatalanTanggal.getMonth() + 1) == state.bulan && value.pembatalanTanggal.getFullYear() == state.tahun)
        }else if (state.bulan) {
            this.filterData = listData.filter(value => (value.pembatalanTanggal.getMonth() + 1) == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.pembatalanTanggal.getFullYear() == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.pembatalanTanggal.getMonth() + 1) == state.bulan && value.pembatalanTanggal.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    
    }

    loadData = () => {
        return this.filterData
    }
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pembatalan Cuti</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-pembatalan-karyawan-cutis?pembatalan.in=true&statusId.in=6'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembatalan Cuti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}


                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPembatalanCuti
                    ref={this.showModalPembatalanCutiRef}
                    store={this.props.store}
                    type={'add'}
                    forceRefresh={this.defaultData}
                />
                <FormModalPembatalanCuti
                    ref={this.showModalEditPembatalanCutiRef}
                    store={this.props.store}
                    type={'edit'}
                    forceRefresh={this.defaultData}
                />
            </div>
        )
    }
}

export default KepegawaianPembatalanCuti