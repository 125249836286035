import React, { Component } from 'react'
import { currency, banks, instruments, orders, instrumentsCategorySaham, product, productDefault } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";
import { confirmAlert } from 'react-confirm-alert'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultDate, formatDefaultFullDate, formatNumber, formatNumberAum, inputToTaskInvestasi, stripHtml } from 'plugin/helper'
import DetailPelepasanSaham from './detail'
import FormPengajuanPelepasanSaham from 'components/pages/modal/investasi/pengajuan/saham/pelepasan/index'

class PengajuanPelepasanSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridOrderSahamRef = React.createRef()
        this.formPengajuanPenempatanSahamRef = React.createRef()
        this.formPengajuanPenempatanSahamEditRef = React.createRef()
        this.formPersetujuanPenempatanSahamRef = React.createRef()

        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: async (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                this.showModalEdit(data, 'edit')
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        },
                    },
                    {
                        text: 'Cetak',
                        hint: 'Cetak',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Belum terkirim!',
                                    message: 'Data belum terkirim tidak bisa cetak surat!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakSurat(data)
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Batalkan draft',
                                    message: 'Apakah anda yakin ingin membatalkan draft ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'requestNo',
                caption: 'No Pengajuan',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.requestNo == null) {
                        return 'DRAFT'
                    } else {
                        return data.requestNo
                    }
                }
            },
            {
                dataField: 'requestDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'requestDate',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'productCode',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'productCode',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'categoryName',
                caption: 'Kategori',
            },
            {
                dataField: 'totalBroker',
                caption: 'Jumlah Broker',
                alignment: 'right'
            },
            {
                dataField: 'totalInstrument',
                caption: 'Jumlah Saham',
                alignment: 'right'
            },
            {
                dataField: 'totalQuantityOrder',
                caption: 'Total Kuantitas (Lembar)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'DRAFT',
                            value: 'Tersimpan'
                        },
                        {
                            id: 'INPROGRESS',
                            value: 'Dalam proses persetujuan'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.currentData = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Pelepasan',
                    onClick: (e) => {
                        this.showModalInsert('add')
                    },
                }
            }
        )
    }
    kirimData = async (data) => {
        var loadAPI = `order-requests-stok/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = dataMaster.kajian
        var content = dataMaster.kajianContentType
        var dataDetail = []
        data = {
            productId: response.orderRequestDTO.productId,
            fundManagerId: response.orderRequestDTO.fundManagerId,
            tanggalOrder: response.orderRequestDTO.orderDate,
            requestOrderId: data.requestId,
            kajian: kajian,
            kajianContentType: content,
            kategori: data.categoryName,
            draft: false,
        }
        for (var saham of response.orderStokInfos) {
            for (var detail of saham.orderStokDTOList) {
                var sahamInfo = {
                    id: detail.id,
                    instrumentId: detail.instrumentId,
                    ordQty: detail.quantityOrder,
                    brokerId: detail.brokerId,
                    priceType: detail.priceType,
                    minOrdPrice: detail.priceMin,
                    maxOrdPrice: detail.priceMax,
                    orderId: detail.orderId,
                    kpdId: saham.orderDTO.kpdId,
                    tanggalOrder: saham.orderDTO.orderDate,
                    rekbankId: detail.rekBankId,
                    brokerCommission: detail.commission
                }
            }
            dataDetail.push(sahamInfo)
        }
        var submitData = {
            ...data,
            instrumentStokList: dataDetail
        }

        try {
            let orderRequestId = response.orderStokInfos[0].orderDTO.orderRequestId
            let orderTypeId = response.orderStokInfos[0].orderDTO.orderTypeId

            await inputToTaskInvestasi(this.props.store, orderRequestId, orderTypeId)
        } catch (error) {
            console.log(error);
        }

        // return false
        if (submitData) {
            try {
                var insertAPI = 'order-stoks/pengajuan-pelepasan-stok'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Pengiriman berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    cetakSurat = async (data) => {
        let reqNo = data.requestNo
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-stok-dtls/${data.requestId}`, 'GET')
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-stok/${data.requestId}`, 'GET')
        var dirKeuangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/6`, 'GET')
        var dirOperasional = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/79506`, 'GET')
        var dirut = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5`, 'GET')
        var komite = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5351`, 'GET')
        let instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${detail.orderStokInfos[0].orderDTO.instrumentId}`, 'GET')

        var kajian = stripHtml(atob(detail.orderRequestDTO.kajian)) == 'undefined' ? ' ' : stripHtml(atob(detail.orderRequestDTO.kajian))

        if (dirKeuangan.length > 0) {
            dirKeuangan = dirKeuangan[0]
        } else {
            dirKeuangan = {}
        }

        if (dirOperasional.length > 0) {
            dirOperasional = dirOperasional[0]
        } else {
            dirOperasional = {}
        }

        if (dirut.length > 0) {
            dirut = dirut[0]
        } else {
            dirut = {}
        }

        if (komite.length > 0) {
            komite = komite[0]
        } else {
            komite = {}
        }

        var dataSaham = []
        let arrQtyOrd = []
        let arrSellPrice = []
        let arrBuyPrice = []
        let arrBrokerFee = []
        let arrAll = []
        let arrLaba = []
        var no = 0
        for (var saham of response) {
            var instrumentData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${saham.instrumentId}`, 'GET')
            var sellPrice = saham.priceMax * saham.quantityOrder
            var buyPrice = instrumentData.price * saham.quantityOrder
            var brokerCommission = saham.feeSell / 100 * sellPrice
            var sahamInfo = {
                no: ++no,
                instrumentCode: saham.instrumentCode,
                categoryName: data.categoryName,
                quantityOrder: saham.quantityOrder,
                priceMin: saham.priceMin,
                priceMax: saham.priceMax,
                brokerName: saham.brokerName,
                sellPrice: sellPrice,
                buyPrice: buyPrice,
                brokerCommission: brokerCommission,
                perkiraanLaba: sellPrice - buyPrice,
                totalPrice: saham.priceMax * saham.quantityOrder
            }
            dataSaham.push(sahamInfo)
        }
        // for (var broker of response){
        //     buyPrice = broker.priceMax * broker.quantityOrder
        //     var brokerFee = broker.feeSell / 100 * buyPrice
        //     arrBroker.push(brokerFee)
        // }
        for (var detailSaham of dataSaham) {
            let totalQuantityOrder = detailSaham.quantityOrder
            let totalHargaJual = detailSaham.priceMin
            let totalHargaBeli = detailSaham.priceMax
            let totalBrokerCommission = detailSaham.brokerCommission
            let totalSemua = detailSaham.totalPrice
            let totalPerkiraanlaba = detailSaham.perkiraanLaba
            arrQtyOrd.push(totalQuantityOrder)
            arrSellPrice.push(totalHargaJual)
            arrBuyPrice.push(totalHargaBeli)
            arrBrokerFee.push(totalBrokerCommission)
            arrAll.push(totalSemua)
            arrLaba.push(totalPerkiraanlaba)
        }
        var totalQuantityOrder = arrQtyOrd.reduce((a, b) => a + b)
        var data = {
            template: {
                'shortid': 'rkgw7r379v',
                'recipe': 'chrome-pdf'
            },
            data: {
                "requestNo": data.requestNo,
                "requestDate": formatDefaultFullDate(data.requestDate),
                "categoryName": data.categoryName,
                "instrumentCode": dataSaham[0].instrumentCode,
                "instrumentName": instrument.instrumentName,
                "quantityOrder": dataSaham[0].quantityOrder,
                "sahamData": dataSaham,
                "kajian": kajian,
                "nowDate": formatDefaultFullDate(new Date()),
                "brokerName": dataSaham[0].brokerName,
                "totalQuantityOrder": totalQuantityOrder,
                "totalSellPrice": arrSellPrice.reduce((a, b) => a + b),
                "totalBuyPrice": arrBuyPrice.reduce((a, b) => a + b),
                "totalBrokerCommission": arrBrokerFee.reduce((a, b) => a + b),
                "totalSemua": arrAll.reduce((a, b) => a + b),
                "totalPerkiraanLaba": arrLaba.reduce((a, b) => a + b),
                "transactionName": data.transactionName,
                "dirKeuangan": {
                    "fullName": dirKeuangan.fullName,
                    "jabatan": 'Direktur Keuangan'
                },
                "dirOperasional": {
                    "fullName": dirOperasional.fullName,
                    "jabatan": 'Direktur Operasional'
                },
                "dirut": {
                    "fullName": dirut.fullName,
                    "jabatan": 'Direktur Utama'
                },
                "dirInvest": {
                    "fullName": komite.fullName,
                    "jabatan": 'Direktur Investasi'
                }
            },
            options: {
                reportName: "UPTI-" + data.requestNo
            }
        }
        reportFile(data, true, `UPTI-${reqNo}.pdf`)
    }
    showModalEdit = async (data, type) => {
        var loadAPI = `order-requests-stok/${data.requestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var kajian = atob(response.orderRequestDTO.kajian)
        var dataDetail = []
        data = {
            productId: response.orderRequestDTO.productId,
            fundManagerId: response.orderRequestDTO.fundManagerId,
            tanggalOrder: response.orderRequestDTO.orderDate,
            orderTypeId: response.orderRequestDTO.orderTypeId,
            requestId: data.requestId,
            requestNo: '<AUTO>',
            actionType: 'edit',
            kategori: data.categoryName,
        }
        for (var saham of response.orderStokInfos) {
            for (var detail of saham.orderStokDTOList) {
                var sahamInfo = {
                    id: detail.id,
                    instrumentId: detail.instrumentId,
                    ordQty: detail.quantityOrder,
                    brokerId: detail.brokerId,
                    priceType: detail.priceType,
                    minOrdPrice: detail.priceMin,
                    maxOrdPrice: detail.priceMax,
                    brokerCommission: detail.commission
                }
            }
            dataDetail.push(sahamInfo)
        }
        if(type === 'edit') {
            this.formPengajuanPenempatanSahamEditRef.current.show()
            this.formPengajuanPenempatanSahamEditRef.current.retrieveData(data, dataDetail, kajian)
        }
    }
    cancelData = async (data) => {
        var reqId = data.requestId
        if (reqId) {
            try {
                var insertAPI = `order-request-batal?orderRequestId=${reqId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: {}
                })
                notify({ message: 'Pembatalan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    showModalInsert = (type) => {
        if(type === 'add') {
            this.formPengajuanPenempatanSahamRef.current.show()
            this.formPengajuanPenempatanSahamRef.current.retrieveData()
        }
    }
    forceRefresh = () => {
        this.DataGridOrderSahamRef.current.forceRefresh(true)
    }
    showModalView = (data) => {
        var dataMaster = { ...data }
        var dataPembelian = dataMaster.detail
        delete dataMaster.detail
        this.formPengajuanPenempatanSahamRef.current.show(dataMaster, dataPembelian)
    }

    showModalPersetujuan = (rowData) => {
        this.formPersetujuanPenempatanSahamRef.current.show()
        this.formPersetujuanPenempatanSahamRef.current.retrieveData({
            noPengajuan: rowData.noPengajuan,
            pengajuanDate: rowData.tanggalPengajuan,
            instrument: rowData.kategori,
            transactionType: 'Pembelian',
            jumlahKomite: 7,
            diSetujui: 3,
            diTolak: 2,
        }, rowData)
    }

    customDetailElem = (data) => {
        return (
            <DetailPelepasanSaham detailData={data.requestId} store={this.props.store} />
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengajuan Pelepasan Saham</h2>
                <DevExpressDataGrid
                    keyField='requestId'
                    ref={this.DataGridOrderSahamRef}
                    loadAPI='order-requests-stok-penjualan/list?status=DRAFT&status=INPROGRESS'
                    insertAPI='order-stoks'
                    updateAPI='order-stoks'
                    deleteAPI='order-stoks'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Order Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Order Saham'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPelepasanSaham store={this.props.store} actionType={'add'} ref={this.formPengajuanPenempatanSahamRef} forceRefresh={this.forceRefresh} />
                <FormPengajuanPelepasanSaham store={this.props.store} ref={this.formPengajuanPenempatanSahamEditRef} forceRefresh={this.forceRefresh} />
            </div>
        )
    }
}

export default PengajuanPelepasanSaham