import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormClosingPriceReksadana from 'components/pages/modal/investasi/settlement/harga-penutupan/nab/index'
import { formatDate, getSystemDate } from 'plugin/helper'
import Form from 'devextreme-react/form'
import httpRequest from 'plugin/httprequest'
import ProgressBar from 'devextreme-react/progress-bar'
import notify from 'devextreme/ui/notify'
import { showLoading } from 'redux/actions/loading';
import { instrumentReksadana } from 'dataSource/lookup'

class ClosingPriceReksadana extends Component {
    constructor(props){
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            filterItem: {
                priceDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
            valueData: 0,
            startDate: '2000-01-01',
            endDate: '2000-01-01'
        }

        this.modalRef = React.createRef()
        this.dgref = React.createRef()
        this.progressBarRef = React.createRef()
        
        this.filterItem = [
            {
                dataField:"priceDate",
                label:{
                    text:"Tanggal Harga",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Load Data",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: async(e) => {
                        // await this.loadData(this.state.filterItem)
                        this.setState({
                            filterItem: {
                                priceDate: this.state.filterItem.priceDate
                            }
                        })
                        this.dgref.current.forceRefresh()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
        this.columns = [
            {
                dataField: 'no',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex+1
                },
            },
            {
                dataField: 'priceDate',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'instrumentId',
                caption: 'Reksadana',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "instrumentName", // provides display values
                },
            },
            {
                dataField: 'close',
                caption: 'NAB',
                dataType: 'number',
                format: '#,##0.0000',
                alignment: 'right',
            },
            {
                dataField: 'yield',
                caption: 'Yield'
            },
            {
                dataField: 'unit',
                caption: 'Total Unit',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'aum',
                caption: 'Total AUM',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'performance',
                caption: 'Performance',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'perform1Week',
                        caption: '1 Week',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'perform30Days',
                        caption: '30 Days',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'perform180Days',
                        caption: '180 Days',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'perform1Year',
                        caption: '1 Year',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'perform3Year',
                        caption: '3 Year',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                ]
            },
        ]
    }

    componentDidMount = async() => {
        await this.loadData()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'upload',
                    text: 'Upload',
                    onClick: (e) => {
                        this.modalRef.current.show()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    text: 'Generate',
                    onClick: (e) => {
                        this.generateData()
                    },
                }
            },
        )
    }
    forceRefresh = () => {
        this.dgref.current.forceRefresh(true)
    }
    progressOnValueChanged = (e) => {
        if (e.value === this.state.valueData) {
            notify({ message: `Generate Data Sukses!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            document.getElementById('progress-bar').classList.add('d-none')
        }
    }
    generateData = async () => {
        this.props.store.dispatch(showLoading(true))
        await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'pasar-dana/fund/update-instrument-pasarDanaId', 'POST', {values: {}});
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'pasar-dana/fund/get-pasarDanaId-reksadana', 'GET');
        // document.getElementById('progress-bar').classList.remove('d-none')
        try {
            var maxValue = response.length
            this.setState({
                valueData: maxValue
            })
            // var currentValue = 1

            // this.progressBarRef.current.instance.option('value', 1)
            // this.progressBarRef.current.instance.option('max', maxValue)

            if(maxValue > 0) {
                // for (var pasarDanaId of response) {
                //     var postCode = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `pasar-dana/fund/import-performance-reksadana/${pasarDanaId}`, 'POST',
                //         { values: {} }
                //     );
                //     this.progressBarRef.current.instance.option('value', currentValue++)
                // }

                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `pasar-dana/fund/import-performance-reksadana-by-date/${formatDate(getSystemDate(this.props.store))}`, 'POST', {
                    values: {}
                })
                this.forceRefresh();
            } else {
                notify({ message: 'Reksadana tidak tersedia', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        } catch (e) {
            console.log(maxValue)
            this.props.store.dispatch(showLoading(false))
            notify({ message: 'Terjadi kesalahan sistem', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
        this.props.store.dispatch(showLoading(false))
    }

    loadData = async() => {
        var priceDate = formatDate(this.state.filterItem.priceDate)
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-instrument-performances?categoryId.in=2&priceDate.equals=${priceDate}&size=9999`, 'GET');
        
        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Harga Reksadana</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />
                <div className='d-none' style={{paddingTop: 20 + 'px'}} id="progress-bar">
                    <ProgressBar
                        ref={this.progressBarRef}
                        min={0}
                        onValueChanged={this.progressOnValueChanged}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.dgref}
                    // keyField='nama'
                    loadAPI={`instrument-performances`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Harga Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Harga Reksadana Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    remoteOperations = {false}
                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormClosingPriceReksadana
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default ClosingPriceReksadana