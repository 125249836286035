import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { educationBackground } from 'dataSource/lookup'

class ModalKaryawanPendidikan extends Component {
    constructor(props){
        super(props)

        this.state = {
            fileUploadReceipt:[],
            popupVisible:false,
            DataPendi:{}
        }

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: () => {
                        this.props.getDataSourcePend(this.state.DataPendi)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.DataPendi = [
            {
                dataField:"educationId",
                label:{
                    text:"Pendidikan"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: educationBackground(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'educationName', // provides display values'
                },
            },
            {
                dataField:"institusi",
                label:{
                    text:"Institusi/Lembaga"
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun Lulus"
                },
                editorType:"dxNumberBox"
            },
            {
                dataField:"indexPrestasi",
                label:{
                    text:"Index Prestasi"
                },
            },
        ]

        this.readOnly = ''
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            DataPendi:{}
        })
    }

    retrieveData = (data, readOnly) => {
        this.readOnly = readOnly
        this.setState({
            DataPendi : data
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Data Karyawan Pendidikan'}
                width={500}
                height={250}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formDataAkun'}
                        formData={this.state.DataPendi}
                        items={this.DataPendi}
                        cssClass="mt-3"
                        labelLocation='left'
                        readOnly={this.readOnly}
                    />
            </Popup>
        )
    }
}

export default ModalKaryawanPendidikan