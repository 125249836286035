import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from 'devextreme/ui/notify'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { banks, managerInvestasis, custodies, productDefault, instrumentProperti, efekBalanceBuyDate, kpds, kpas, instruments, instrumentTypes } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDate, formatUploadFileData, getSystemDate, imageSource } from 'plugin/helper'
import ArrayStore from 'devextreme/data/array_store';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalKpaAsset from './action'
import ScrollView from 'devextreme-react/scroll-view'
import { showLoading } from 'redux/actions/loading'

class ModalInvoiceKpa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            formData: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalAsetRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        this.submitData()
                        // console.log('button submit')
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    // {
                    //     dataField: 'noInvoice',
                    //     label: {
                    //         alignment: 'left',
                    //         text: 'Nomor Invoice'
                    //     },
                    // },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                        },
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            alignment: 'left',
                            text: 'Pengelola'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'kpaId',
                        label: {
                            alignment: 'left',
                            text: 'KPA'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpas(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'kontraktorName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            alignment: 'left',
                            text: 'Nama Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'instrumentName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                var instrumentFilter = this.formRef.current.instance.getEditor('efekBalanceBuyDateId')
    
                                instrumentFilter.option('dataSource',this.lookupEBBA(e.value))
                            },
                        },
                    },
                    {
                        dataField: 'efekBalanceBuyDateId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'tipe',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'paymentDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'periode',
                        label: {
                            alignment: 'left',
                            text: 'Periode'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'invoiceDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Tagihan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'nominalInvoice',
                        label: {
                            alignment: 'left',
                            text: 'Nominal invoice'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    {
                        dataField: 'statusBayar',
                        label: {
                            alignment: 'left',
                            text: 'Status Bayar'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: "1",
                                    value: 'Normal'
                                },
                                {
                                    id: "2",
                                    value: 'Overdue'
                                }
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                        },
                    },
                    {
                        dataField: 'description',
                        label: {
                            alignment: 'left',
                            text: 'Deskripsi'
                        },
                        editorType: 'dxTextArea',
                    },
                    {
                        dataField: 'file',
                        label: {
                            text: 'File Invoice'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStarted.bind(this),
                        }
                    }
                ]
            }
        ]
    }
    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    retrieveData = async (data) => {
        var sysDate = this.props.store.getState().getParam ? this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        var value = {
            productId: data.productId,
            periode: data.paymentPeriode,
            paymentDate: formatDate(new Date(sysDate ? sysDate.dateValue : null)),
            kpaId: data.id,
            invoiceDate: formatDate(new Date(sysDate ? sysDate.dateValue : null)),

        }

        this.setState({
            formData: value
        })

        this.lookupInstrument()
    }

    lookupInstrument = async () => {
        var kpaId = this.state.formData.kpaId
        var dataInstrument = []
        // var dataEBBD = []

        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpa-assets/getByKpaId/${kpaId}`, 'GET')

        for(var value of response){
            var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${value.instrumenId}`, 'GET')

            dataInstrument.push(instrument)
        }

        let dataSourceinstrument = new ArrayStore({
            data: dataInstrument,
            key: 'id'
        });
        this.formRef.current.instance.getEditor('instrumentId').option('dataSource', dataSourceinstrument)
    }

    lookupEBBA = async (instrumentId) => {
        var efekBalanceBuyDate = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates?instrumentId.in=${instrumentId}`, 'GET')

        let dataEfekBalance = new ArrayStore({
            data: efekBalanceBuyDate,
            key: 'id'
        });

        this.formRef.current.instance.getEditor('efekBalanceBuyDateId').option('dataSource', dataEfekBalance)

        // return dataEfekBalance
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Invoice`]: formattedFileData.base64data,
                    [`${fieldName}InvoiceContentType`]: formattedFileData.mime,
                    [`fileInvoiceName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        var dataMaster = this.state.formData
        dataMaster.fileInvoice = this.state.fileInvoice
        dataMaster.fileInvoiceContentType = this.state.fileInvoiceContentType
        dataMaster.fileInvoiceName = this.state.fileInvoiceName
        // dataMaster.invoiceDate = formatDate(dataMaster.invoiceDate)
        
        if (dataMaster) {
            try {
                    var insertAPI = 'invoices/simpanInvoice'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: dataMaster
                    })

                    if (response) {

                        this.props.store.dispatch(showLoading(false))
                        notify({ message: 'Data berhasil ditambahkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.props.forceRefresh()
                        this.hide()
                    }

                return response
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    previewFile = async () => {
        var src = imageSource(this.state.fileKontrakContentType, this.state.fileKontrak)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Tambah Aset',
                    icon: 'plus',
                    onClick: (e) => {
                        var data = {}
                        data.productId = this.state.formData.productId
                        this.showModalAset(data)
                    },
                }
            },
        )
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Invoice Aset'}
                    width={'700'}
                    height={'800'}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        id={"formMaster"}
                                        ref={this.formRef}
                                        colCount={1}
                                        formData={this.state.formData}
                                        items={this.items}
                                        labelLocation={"left"}
                                    />
                                    {/* <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileKontrak ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Buka File
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </ScrollView>

                    <ModalPreviewPdf
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                    <ModalKpaAsset
                        ref={this.modalAsetRef}
                        store={this.props.store}
                        submitDataKpaAset={this.submitDataKpaAset}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalInvoiceKpa