import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, product, managerInvestasis, instrumentSubCategoryDeposito, currency } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import HtmlEditor, { Toolbar, Item, MediaResizing }  from "devextreme-react/html-editor";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';


class FormPersetujuanPelepasanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Submit',
                    onClick: () => {
                        console.log('...')
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.DataMaster =[
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No. Pengajuan'
                },
                disabled: true
            },
            {
                dataField: 'pengajuanDate',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'd MMM yyyy'
                },
                disabled: true
            },
            {
                dataField: 'instrument',
                label: {
                    text: 'Instrument'
                },
                disabled: true
            },
            {
                dataField: 'transactionType',
                label: {
                    text: 'Jenis Transaksi'
                },
                disabled: true
            },
            {
                dataField: 'jumlahKomite',
                label: {
                    text: 'Jumlah Komite'
                },
                disabled: true
            },
            {
                dataField: 'diSetujui',
                label: {
                    text: 'Di Setujui'
                },
                disabled: true
            },
            {
                dataField: 'diTolak',
                label: {
                    text: 'Di Tolak'
                },
                disabled: true
            },
            {
                dataField: 'persetujuanAnda',
                label: {
                    text: 'Persetujuan Anda'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id:1,
                            value: 'Menyetujui'
                        },
                        {
                            id:2,
                            value: 'Menolak'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'note',
                label: {
                    text: 'Catatan'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    height: 120
                }
            }
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: {}
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }
    submitData = async() => {
        console.log('submit')
    }
    retrieveData = async(data) => {
        this.setState({
            dataMaster: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Persetujuan'}
                width={'700'}
                height={'600'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12' } style={{overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.dataMaster}
                                        items={this.DataMaster}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormPersetujuanPelepasanReksadana