import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { kasUmum, programs, programSdm, statusPengembalianKasUmumSdm } from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import FormModalEditPengembalianKasUmum from 'components/pages/modal/sdm-umum/umum/kas-umum/pengembalian/index';
import { formatDate } from 'plugin/helper';
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify';

class PengembalianKasUmum extends Component {
    constructor(props){
        super(props)
        this.userLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-success',
                        onClick:async (e) => {
                            if (e.row.data.statusId == 22) {
                                await alert("Data Tidak Bisa Di Edit, Silahkan Pilih Lihat Untuk Membuka Data",'Gagal');
                            }else if (e.row.data.statusId == 23) {
                                await alert("Data Dibatalkan dan tidak bisa diedit",'Gagal');
                            }else if (e.row.data.statusId == 24) {
                                await alert("Data sedang di proses dan tidak bisa diedit", "Gagal");
                            } else{
                                this.showModal(e.row.data, 'edit', false);
                            }
                        }
                    },
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModal(e.row.data, 'lihat', true)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick:async (e) => {
                            if (e.row.data.statusId == 22) {
                                await alert("Data Sudah Terkirim dan Tidak Bisa Dikirm Lagi",'Gagal')
                            }
                            else if (e.row.data.statusId == 23) {
                                await alert("Data Sudah Dibatalkan dan Tidak Bisa Dikirim",'Gagal')
                            }else if (e.row.data.statusId == 24) {
                                await alert("Data Sedang Dalam Proses",'Informasi')
                            }else{
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.kirim(e.row.data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    // {
                    //     text: 'Konfirmasi',
                    //     hint: 'Konfirmasi',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         console.log(e)
                    //     }
                    // },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick:async (e) => {
                            if (e.row.data.statusId == 22) {
                                await alert("Status Data Sudah Berhasil, Tidak Bisa Melakukan Pembatalan",'Gagal')
                            }
                            else if (e.row.data.statusId == 24) {
                                await alert("Data Sudah Diterima",'Gagal')
                            }else{
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin ingin membatalkan data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.batal(e.row.data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                ]
            },
            {
                dataField:"transactionNo",
                caption:"No Transaksi"
            },
            {
                dataField:"programId",
                caption:"Program",
                lookup: {
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program'
                }
            },
            {
                dataField:"kasUmumId",
                caption:"Kas Umum",
                lookup: {
                    dataSource: kasUmum(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaKas'
                }
            },
            {
                dataField:"pengembalian",
                caption:"Pengembalian",
                columns:[
                    {
                        dataField:"requestDate",
                        caption:"Tanggal Dikembalikan",
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField:"requestAmount",
                        caption:"Nominal Dikembalikan",
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00',
                    },
                ]
            },
            {
                dataField:"statusId",
                caption:"Status",
                lookup: {
                    dataSource: statusPengembalianKasUmumSdm(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.modalEditRef = React.createRef()
        this.dataGridRef = React.createRef()    
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal(null, 'add', {transactionNo: '<AUTO>'})
                    },
                }
            }
        )
    }

    loadData = async () => {
        var result = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getByTransactionSubTypeId/70?size=99999`, 'GET')
        return result
    }

    showModal = (data, type, readOnly) => {
        // let statusId = data.statusId;

        // console.log(statusId);
        // switch(statusId) {
        //     case 22: 
        //     case 23:
        //     case 24:
        //         console.log('test');
        //         this.modalEditRef.current.retrieveData(data, type, readOnly);
        //         break;
        //     case 21:
        //         console.log('test1');
        //         this.modalEditRef.current.retrieveData(data);    
        //         break;
        // }

        if (data) {
            this.modalEditRef.current.retrieveData(data, type, readOnly)
        }

        this.modalEditRef.current.show(type)
        this.modalEditRef.current.getKasUmumByUserLogin()
    }

    kirim = async (data) => {
        try {
            var getKaryawanUserLoged = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByUsername/${this.userLoged}`, 'GET')
            var entitiId = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/findByTypeAndReffByPath/KR/${getKaryawanUserLoged.id}`, 'GET')
            var dataSubmit = {
                description:data.description,
                amount: data.transactionAmount,
                currencyId: 1001,
                dueDate: formatDate(data.transactionDate),
                entitiId: entitiId.id,
                outstandingAmount: data.transactionAmount,
                reffNo: "PKU-"+data.id,
                reffType: "HR",
                defaultBank:0,
                tradeDate: formatDate(data.transactionDate)
              }
              var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps`, 'POST',{
                values:dataSubmit
              })

              if (response) {
                var getDataKas = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/${data.id}`, 'GET')
                getDataKas.statusId = 24

                var changeStatus = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions`, 'PUT',{
                    values:getDataKas,
                    key:getDataKas.id
                },getDataKas)
                await alert("Data Berhasil terkirim","Berhasil")
                this.forceRefresh()
              }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    batal = async(data) => {
        try {
            var getDataKas = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/${data.id}`, 'GET')
            getDataKas.statusId = 23

            var changeStatus = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions`, 'PUT',{
                values:getDataKas,
                key:getDataKas.id
            },getDataKas)

            if (changeStatus) {
                await alert("Data Berhasil dibatalkan","Berhasil")
                this.forceRefresh()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pengajuan Pengembalian Kas Umum</h3>
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='kas-bank-transactions/getByMultipleStatusId/21%2C22%2C23%2C24?size=999'
                        insertAPI='kas-bank-transactions'
                        updateAPI='kas-bank-transactions'
                        deleteAPI='kas-bank-transactions'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}
                        // remoteOperations = {true}
                        // useArraySource={true}
                        // ArraySourceData={this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Pengajuan Pembayaran Kas Umum"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Pengajuan Pembayaran Kas Umum'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze
                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        onToolbarPreparing={this.onToolbarPreparing}
                        // SummaryConfiguration={this.summary}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalEditPengembalianKasUmum 
                        ref={this.modalEditRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    />
                </div>
            </div>
        )
    }
}

export default PengembalianKasUmum