import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import Box from 'devextreme-react/box';
import { FileUploader } from 'devextreme-react';
import { Item }  from "devextreme-react/html-editor";

import { dokumenType } from 'dataSource/lookup';
import { formatUploadFileData, formatUploadFileNameAndExtention, formatDate, download, imageSource } from 'plugin/helper';

class ModalActionDokumen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                dokumenFileContentType: '',
                dokumenFile: '',
                dokumenFileName: '',
                dokumenFileExtention: '',
            },
            totalCount: 0
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataMaster = [
            {
                dataField: 'dokumenTypeId',
                label: {
                    alignment: 'left',
                    alignment: 'left',
                    text: 'Tipe Dokumen'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: dokumenType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dokumenTypeName', // provides display values,
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dokumenSubject',
                label: {
                    alignment: 'left',
                    alignment: 'left',
                    text: 'Subjek Dokumen'
                }
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {
                dokumenFileContentType: '',
                dokumenFile: '',
                dokumenFileName: '',
                dokumenFileExtention: '',
            },
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}File`]: formattedFileData.base64data,
                        [`${fieldName}FileName`]: formattedFileNameAndExtention.name,
                        [`${fieldName}FileExtention`]: formattedFileNameAndExtention.extention,
                        [`${fieldName}FileContentType`]: formattedFileData.mime,
                    },
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }
    
    submitData = () => {
        var dataSubmit = {...this.state.dataMaster}
        var dateNow = formatDate(new Date())
        dataSubmit.dokumenNo = 'DOC'+ dataSubmit.dokumenTypeId + this.state.totalCount + dateNow.replace("-", "")
        dataSubmit.dokumenDate = dateNow
        this.props.editDataDokumen(dataSubmit)
        this.hide()
    }

    retrieveData = (data,count) => {   
        var dataDokumen = data
        this.setState({
            dataMaster: dataDokumen,
            totalCount: count,
        })
    }

    downloadFile = () => {
        var dokumen = this.state.dataMaster
        download(imageSource(dokumen.dokumenFileContentType,dokumen.dokumenFile),dokumen.dokumenFileName,dokumen.dokumenFileExtention)
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Action Data Dokumen'}
                minWidth={500}
                minHeight={500}
                width={800}
                height={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY:'auto',height: '100%'}}>
                    <div className="row">
                        <div className="mt-2 col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="mt-2 col-md-9">
                            <Box 
                                direction={'row'}
                                width={'100%'}
                            >
                                <Item ratio={1}>
                                    <FileUploader 
                                        allowCanceling={true}
                                        // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)} 
                                        labelText={this.state.dataMaster.dokumenFileName || 'Upload Dokumen Disini'}
                                        showFileList={false}
                                        name={'dokumen'}
                                    />
                                </Item>
                            </Box>
                        </div>
                        <div className="mt-4 col-md-3 text-right">
                            <Box 
                                direction={'row'}
                                width={'50%'}
                                visible={this.state.dataMaster.dokumenFileContentType ? true : false}
                            >
                                <Item ratio={1}>
                                    <button onClick={this.downloadFile.bind(this)} className={`btn btn-light btn-sm border rounded d-xl-flex justify-content-xl-center`} style={{ width: '122px' }}>
                                        Download
                                    </button>
                                </Item>

                            </Box>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalActionDokumen
