import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { anggaranSubType, coa } from 'dataSource/lookup';
import { showLoading } from 'redux/actions/loading';
import ModalInsertAnggaran from './modalInsertAnggaran';
import { confirm } from 'devextreme/ui/dialog'
import { confirmAlert } from 'react-confirm-alert'

class EditorAnggaranItemCoa extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            uploadedData: []
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.datagridRef = React.createRef()
        this.modalCoaAnggaranRef = React.createRef()

        this.isNew = false

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options: {
            //         'text': 'Batal',
            //         onClick: this.hide.bind(this)
            //     },
            //     toolbar: 'bottom'
            // }
        ]

        this.dataMaster = [
            {
                dataField: 'anggaranCode',
                label: {
                    text: 'Kode Anggaran'
                },
            },
            {
                dataField: 'anggaranName',
                label: {
                    text: 'Nama Anggaran'
                },
                colSpan: 2,
                editorType: 'dxTextBox',
                editorOptions: {
                }
            },
            {
                dataField: 'anggaranSubTypeId',
                label: {
                    text: 'Sub Tipe Anggaran'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: anggaranSubType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'anggaranSubName',
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModalCoaAnggaran('edit', e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async(e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteAPI(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ],
                                overlayClassName: 'react-custom-confirm-alert-overlay',
                            })
                        }
                    }
                ],
            },
            {
                dataField: 'coaId',
                caption: 'Chart of Account',
                editorType: 'dxSelectBox',
                // editorOptions: {
                //     dataSource: coa(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: (item) => {
                //         return item && item.coaCode + ' - ' + item.coaName
                //     }, // provides display values
                // },
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    }, // provides display values
                }
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show(dataMaster) {
        
        var dataMaster = dataMaster || {}
        
        this.setState({
            popupVisible: true,
            dataMaster: dataMaster
        });

        this.forceRefresh()

    }

    hide = async(e) => {
        var result
        if (this.state.popupVisible === true && !this.isNew) {
            if (typeof e.cancel === 'boolean') {
                e.cancel = true
            }
            var result = await confirm('Apakah anda yakin untuk membatalkan input data ini?', 'Konfirmasi')
        } else {
            result = true
        }
        if (result) {
            if(!this.isNew) {
                if (typeof e.cancel === 'boolean') {
                    e.cancel = false
                }
            }
            this.setState({
                dataMaster: {},
                popupVisible: false,
            })
            this.props.forceRefresh()
        }
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `anggaran-item-coas`, 'DELETE', data.id);

            notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
            this.forceRefresh()
        } catch (error) {
            notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        try{
            const dataSubmit = this.datagridRef.current.getDataSource()

            const dataDeletedIndex = this.datagridRef.current.getDeletedIndex()

            const dataInsert = dataSubmit.filter(value => typeof value.id === 'string')

            const dataUpdate = dataSubmit.filter(value => typeof value.id === 'number')

            for(const data of dataInsert){
                var insertAPI = `anggaran-item-coas`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, insertAPI, 'POST', {
                    values: {
                        coaId: data.coaId,
                        anggaranItemId : this.state.dataMaster.id
                    },
                })
            }

            for(const data of dataUpdate){
                var insertAPI = `anggaran-item-coas`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, insertAPI, 'PUT', {
                    values: {
                        coaId: data.coaId,
                    },
                },{
                    coaId: data.coaId,
                    anggaranItemId: this.state.dataMaster.id,
                    id: data.id
                })
            }

            for(const data of dataDeletedIndex){
                const response = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-item-coas`,'DELETE',data)
            }

            let type = 'success'
            let text = 'Data berhasil disimpan!'
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);

            
            this.datagridRef.current.emptyDeletedIndex()

            this.isNew = true
            this.hide();
            this.props.store.dispatch(showLoading(false))
        }catch(e){
            let type = 'error'
            let text = 'Data gagal disimpan!'
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
            console.log(e)
            this.props.store.dispatch(showLoading(false))
        }
    }

    getAnggaranItemCoa = async () => {
        const response = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-item-coas/getByAnggaranItemId/${this.state.dataMaster.id}`)

        return response
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    // text: 'Tambah Deviden',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalCoaAnggaran('add')
                    },
                }
            },
        )
    }

    showModalCoaAnggaran = (type, data) => {
        // if (type === 'add') {
            this.modalCoaAnggaranRef.current.show()
            this.modalCoaAnggaranRef.current.retrieveData(data, type)
        // } else {
        //     this.modalCoaAnggaranRef.current.show()
        //     this.modalCoaAnggaranRef.current.retrieveData(data)
        // }
    }

    addDataToGrid = async(data) => {
        try {
            let key = data.id;
            let dataGrid = this.datagridRef.current.getDataSource();
            
            for (let value of dataGrid) {
                if (value.coaId === data.coaId) {
                    let type = 'error'
                    let text = 'Data COA Sudah ada!'
                    notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 1000);
                    return null;
                }
            }
            if (key) {
                this.datagridRef.current.push([{
                        type: 'update',
                        data: {
                            coaId: data.coaId
                        },
                        key: key
                }])
            } else {
                this.datagridRef.current.push([{
                        type: 'insert',
                        data: {
                            coaId: data.coaId
                        }
                }])
            }
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Daftar Coa Mata Anggaran`}
                    minWidth={'90vw'}
                    height="90vh"

                    ref={this.PopupRef}

                    toolbarItems = {this.popupToolbarItem}
                >
                    <div className="container-fluid py-3">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={4}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <DevExpressDataGrid
                                    ref= {this.datagridRef}
                                    loadAPI='anggaran-items'
                                    insertAPI='anggaran-items'
                                    updateAPI='anggaran-items'
                                    deleteAPI='anggaran-items' 

                                    useArraySource = {true}
                                    ArraySourceData = {this.getAnggaranItemCoa}

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    allowAdding={false}
                                    allowUpdating={false}
                                    allowDeleting={false}
                                    exportExcel={true}
                                    exportFileName={"Anggaran"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Anggaran Data'}
                                    popupWidth={'70vw'} //masukan dalam ukuran pixel
                                    popupHeight={'400px'} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    height={'calc(100vh - 260px'}

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}

                                    onToolbarPreparing={this.onToolbarPreparing}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <ModalInsertAnggaran 
                                    store={this.props.store}
                                    ref={this.modalCoaAnggaranRef}
                                    addDataGrid={this.addDataToGrid}
                                    forceRefresh={this.forceRefresh}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default EditorAnggaranItemCoa
