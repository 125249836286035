import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, fundManager, emitens, currency, reksadana, instrumentSubCategoryReksadana, aperds } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";


class ModalInstrumentPengalihanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.formItem = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument'
                },
                editorType: "dxSelectBox",
                editorOptions:{
                    dataSource: reksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function(item){
                        return item && item.instrumentCode + " - " + item.instrumentName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged : async (e) => {
                        console.log(e)
                        // var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`instruments/${e.value}`)

                        // var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`emitens/${instrument.emitenId}`)
                        // this.formDetailRef.current.instance.updateData('emiten',emiten.emitenName)
                        // this.formDetailRef.current.instance.updateData('hargaPenutupan',instrument.price)
                        // this.formDetailRef.current.instance.updateData('tanggalHargaPenutupan',instrument.priceDate)
                    }
                },
            },
            {
                dataField: 'allUnit',
                label: {
                    text: 'Portofolio Pelepasan'
                },
                editorType: "dxSelectBox",
                editorOptions:{
                    dataSource: [
                        {
                            id: 'A',
                            value: 'Semua'
                        },
                        {
                            id: 'P',
                            value: 'Sebagian'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged : async (e) => {
                        console.log(e)
                        // var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`instruments/${e.value}`)

                        // var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`emitens/${instrument.emitenId}`)
                        // this.formDetailRef.current.instance.updateData('emiten',emiten.emitenName)
                        // this.formDetailRef.current.instance.updateData('hargaPenutupan',instrument.price)
                        // this.formDetailRef.current.instance.updateData('tanggalHargaPenutupan',instrument.priceDate)
                    }
                },
            },
            {
                dataField: 'useMethod',
                label: {
                    text: 'Pelepasan Berdasarkan'
                },
                editorType: "dxSelectBox",
                editorOptions:{
                    dataSource: [
                        {
                            id: 'U',
                            value: 'Unit'
                        },
                        {
                            id: 'N',
                            value: 'Nominal'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged : async (e) => {
                        console.log(e)
                        // var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`instruments/${e.value}`)

                        // var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`emitens/${instrument.emitenId}`)
                        // this.formDetailRef.current.instance.updateData('emiten',emiten.emitenName)
                        // this.formDetailRef.current.instance.updateData('hargaPenutupan',instrument.price)
                        // this.formDetailRef.current.instance.updateData('tanggalHargaPenutupan',instrument.priceDate)
                    }
                },
            },
            {
                dataField: 'persentasePelepasan',
                label: {
                    text: 'Persentase Pelepasan'
                },
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00 %"
                },
            },
            {
                dataField: 'nominalPelepasan',
                label: {
                    text: 'Nominal Pelepasan'
                },
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },
            {
                dataField: 'fee',
                label: {
                    text: 'Fee Nominal'
                },
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00",
                    readOnly: true
                },
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Mata Uang'
                },
                editorType: "dxSelectBox",
                editorOptions:{
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                    searchEnabled: true,
                    deferRendering: false
                },
            },    
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs'
                },
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
            },     
            {
                dataField: 'rekeningBayar',
                label: {
                    text: 'Rekening Bayar'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly : true
                }
            },   
        ]

        this.formDetailItem = [
            {
                itemType: 'group',
                name: 'information',
                caption: 'Informasi Reksadana Asal',
                colCount: 1,
                items:[
                    {
                        dataField: 'emitenId',
                        label: {
                            text: 'Penerbit Reksadana'
                        },
                        editorOptions:{
                            dataSource: emitens(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'emitenName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'kategoriReksadana',
                        label: {
                            text: 'Kategori Reksadana'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly : true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: "dxSelectBox",
                        editorOptions:{
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            searchEnabled: true,
                            deferRendering: false
                        },
                    },    
                    {
                        dataField: 'aum',
                        label: {
                            text: 'AUM'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'unitBeredar',
                        label: {
                            text: 'Unit Beredar'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.0000",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'kinerja1bulan',
                        label: {
                            text: 'Kinerja 1 Bulan'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'kinerja1tahun',
                        label: {
                            text: 'Kinerja 1 Tahun'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'kinerja2tahun',
                        label: {
                            text: 'Kinerja 2 Tahun'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                            readOnly : true
                        },
                    },
                ],
            },
            {
                itemType: 'group',
                name: 'information',
                caption: 'Informasi Reksadana Tujuan',
                colCount: 1,
                items:[
                    {
                        dataField: 'emitenId',
                        label: {
                            text: 'Penerbit Reksadana'
                        },
                        editorOptions:{
                            dataSource: emitens(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'emitenName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'kategoriReksadana',
                        label: {
                            text: 'Kategori Reksadana'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly : true
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: "dxSelectBox",
                        editorOptions:{
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            searchEnabled: true,
                            deferRendering: false
                        },
                    },    
                    {
                        dataField: 'aum',
                        label: {
                            text: 'AUM'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'unitBeredar',
                        label: {
                            text: 'Unit Beredar'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.0000",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'kinerja1bulan',
                        label: {
                            text: 'Kinerja 1 Bulan'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'kinerja1tahun',
                        label: {
                            text: 'Kinerja 1 Tahun'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'kinerja2tahun',
                        label: {
                            text: 'Kinerja 2 Tahun'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                            readOnly : true
                        },
                    },
                ],
            },
            {
                itemType: 'group',
                name: 'portofolio',
                caption: 'Informasi Portofolio',
                colCount: 1,
                items:[
                    {
                        dataField: 'nilaiPengalihanInstrument',
                        label: {
                            text: 'Nilai Pengalihan Instrument'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'nab',
                        label: {
                            text: 'NAB Perolehan'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'nilaiPerolehan',
                        label: {
                            text: 'Nilai Perolehan'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'keuntungan',
                        label: {
                            text: 'Nilai Keuntungan'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'persentaseKeuntungan',
                        label: {
                            text: 'keuntungan (%)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'komposisiInstrument',
                        label: {
                            text: 'Komposisi Instrument'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'komposisiEmiten',
                        label: {
                            text: 'Komposisi Emiten'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly : true
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'lastPrice',
                caption: 'Informasi Harga Terakhir',
                colCount: 1,
                items:[
                    {
                        dataField: 'nabPeluncuran',
                        label: {
                            text: 'NAB Peluncuran'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.0000",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'nabTertinggi',
                        label: {
                            text: 'NAB Tertinggi'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.0000",
                            readOnly : true
                        },
                    },
                    {
                        dataField: 'nabTerakhir',
                        label: {
                            text: 'NAB Terakhir'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.0000",
                            readOnly : true
                        },
                    },
                ]
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show(data) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            data: {
                id: data.id,
                instrumentId: data.instrumentId,
                instrumentName: data.instrumentName,
                emitenId: data.emitenId,
                currencyId: data.currencyId,
                kurs: data.kurs,
                nabTerakhir: data.nabTerakhir,
                nilaiInvestasi: data.nilaiInvestasi,
                feePercent: data.feePercent,
                fee: data.fee,
                totalPengalihan: data.nilaiInvestasi + data.fee,
                reksadanaTujuan: data.reksadanaTujuan,
            },
            dataDetail: {

            },
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.data
        this.props.submitDetailPengalihanData({
            id: data.id,
            instrumentId: data.instrumentId,
            instrumentName: data.instrumentName,
            emitenId: data.emitenId,
            currencyId: data.currencyId,
            kurs: data.kurs,
            nabTerakhir: data.nabTerakhir,
            nilaiInvestasi: data.nilaiInvestasi,
            feePercent: data.feePercent,
            fee: data.fee,
            totalPengalihan: data.nilaiInvestasi + data.fee,
            reksadanaTujuan: data.reksadanaTujuan,
        })

        this.hide()
    }

    loadDetailPengalihan = () => {
        return []
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Pengalihan Investasi Reksadana`}
                width={'90vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-5">
                            <Form
                                colCount={1}
                                id={'form'}
                                formData={this.state.data}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                        <div className="col-md-7 border-left">
                            <Form
                                ref={this.formDetailRef}
                                colCount={2}
                                id={'formDetail'}
                                formData={this.state.dataDetail}
                                items={this.formDetailItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalInstrumentPengalihanReksadana