import React, { Component } from 'react'
import { Form, Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url';
import { participants, product, tipePembayaranMp } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDate, formatDefaultDate, PrintElem, generateExcelFile, formatNumber } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';

const bulan = [
    {
        id: 1,
        namaBulan: 'Januari'
    },
    {
        id: 2,
        namaBulan: 'Februari'
    },
    {
        id: 3,
        namaBulan: 'Maret'
    },
    {
        id: 4,
        namaBulan: 'April'
    },
    {
        id: 5,
        namaBulan: 'Mei'
    },
    {
        id: 6,
        namaBulan: 'Juni'
    },
    {
        id: 7,
        namaBulan: 'Juli'
    },
    {
        id: 8,
        namaBulan: 'Agustus'
    },
    {
        id: 9,
        namaBulan: 'September'
    },
    {
        id: 10,
        namaBulan: 'Oktober'
    },
    {
        id: 11,
        namaBulan: 'November'
    },
    {
        id: 12,
        namaBulan: 'Desember'
    }
]
class ReportPesertaArsip extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formFilter: {},
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null


        this.formRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'greaterArsipDate',
                                label: {
                                    text: 'Tanggal Arsip',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: 'lessArsipDate',
                                label: {
                                    text: 'S/D',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                        ]
                    },
                    // {
                    //     dataField: 'mpType',
                    //     label: {
                    //         text: 'Tipe Arsip',
                    //     },
                    //     editorType: 'dxSelectBox',
                    //     editorOptions: {
                    //         placeholder: ' ',
                    //         dataSource: tipePembayaranMp(this.props.store),
                    //         valueExpr: "id", // contains the same values as the "statusId" field provides
                    //         displayExpr: "typeName", // provides display values
                    //         searchEnabled: true,
                    //         deferRendering: false,
                    //     }
                    // },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async () => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-laporan-arsips?size=9999&`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `products`, 'GET')

        if (filter.participantId) {
            loadAPI = loadAPI + `participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        } else {
            var participantName = 'Semua'
        }

        if (filter.productId) {
            loadAPI = loadAPI + `productId.in=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        } else {
            var productName = 'Semua'
        }

        if (filter.mpPaymentTypeId) {
            loadAPI = loadAPI + `mpPaymentTypeId.in=${filter.mpPaymentTypeId}&`
            try {
                var mpPaymentTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `mp-payment-types/${filter.mpPaymentTypeId}`, 'GET')).typeName
            } catch (error) {
                var mpPaymentTypeName = 'Semua'
            }
        }

        // Tanggal Arsip
        if (filter.greaterArsipDate) {
            loadAPI = loadAPI + `arsipDate.greaterThanOrEqual=${formatDate(filter.greaterArsipDate)}&`
        }

        if (filter.lessArsipDate) {
            loadAPI = loadAPI + `arsipDate.lessThanOrEqual=${formatDate(filter.lessArsipDate)}&`
        }
        var getPesertaDialihkan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI.slice(0, -1), 'GET')

        var summaryMpBulanan = []
        var summaryMpSekaligus = []
        for (var value of getPesertaDialihkan) {
            let totalMpBulanan = value.mpBulanan ? value.mpBulanan : 0
            let totalMpSekaligus = value.mpSekaligus ? value.mpSekaligus : 0

            summaryMpBulanan.push(totalMpBulanan)
            summaryMpSekaligus.push(totalMpSekaligus)
            // var month = bulan.find(val => val.id == value.mkBulan)
            // value.mkBulan = month ? month.namaBulan : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            value.birthDate = value.birthDate ? formatDefaultDate(value.birthDate) : '-'
            value.pensiunDate = value.pensiunDate ? formatDefaultDate(value.pensiunDate) : '-'
            value.arsipDate = value.arsipDate ? formatDefaultDate(value.arsipDate) : '-'

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
        }

        if(summaryMpBulanan.length !== 0 && summaryMpSekaligus.length !== 0) {
            var sumBulanan = summaryMpBulanan.reduce((a, b) => a + b)
            var sumSekaligus = summaryMpSekaligus.reduce((a, b) => a + b)
        }

        var data = {
            template: {
                name: '/dapen/kepesertaan/report/peserta/peserta-arsip',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpPaymentType: mpPaymentTypeName,
                participant: participantName,
                tanggalArsip: filter.lessArsipDate && filter.greaterArsipDate ? (filter.greaterArsipDate ? formatDefaultDate(filter.greaterArsipDate) : "~") + ' S/D ' + (filter.lessArsipDate ? formatDefaultDate(filter.lessArsipDate) : "~") : 'Semua',
                dataPeserta: getPesertaDialihkan,
                summaryMpBulanan: sumBulanan,
                summaryMpSekaligus: sumSekaligus,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))

        this.setState({
            src: responseFile
        })
    }

    print = async () => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-laporan-arsips?size=9999&`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `products`, 'GET')

        if (filter.participantId) {
            loadAPI = loadAPI + `participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        } else {
            var participantName = 'Semua'
        }

        if (filter.productId) {
            loadAPI = loadAPI + `productId.in=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        } else {
            var productName = 'Semua'
        }

        if (filter.mpPaymentTypeId) {
            loadAPI = loadAPI + `mpPaymentTypeId.in=${filter.mpPaymentTypeId}&`
            try {
                var mpPaymentTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `mp-payment-types/${filter.mpPaymentTypeId}`, 'GET')).typeName
            } catch (error) {
                var mpPaymentTypeName = 'Semua'
            }
        }

        // Tanggal Arsip
        if (filter.greaterArsipDate) {
            loadAPI = loadAPI + `arsipDate.greaterThanOrEqual=${formatDate(filter.greaterArsipDate)}&`
        }

        if (filter.lessArsipDate) {
            loadAPI = loadAPI + `arsipDate.lessThanOrEqual=${formatDate(filter.lessArsipDate)}&`
        }
        var getPesertaDialihkan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI.slice(0, -1), 'GET')
        // var getKp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'kepersertaan-products?size=9999', 'GET')

        var summaryMpBulanan = []
        var summaryMpSekaligus = []
        for (var value of getPesertaDialihkan) {
            let totalMpBulanan = value.mpBulanan ? value.mpBulanan : 0
            let totalMpSekaligus = value.mpSekaligus ? value.mpSekaligus : 0

            summaryMpBulanan.push(totalMpBulanan)
            summaryMpSekaligus.push(totalMpSekaligus)
            // var month = bulan.find(val => val.id == value.mkBulan)
            // value.mkBulan = month ? month.namaBulan : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            // var arrKp = getKp.find(val => val.pesertaId === value.pesertaId)
            // value.nsPeriode = arrKp ? arrKp.nsPeriode : 0

            value.birthDate = value.birthDate ? formatDefaultDate(value.birthDate) : '-'
            value.pensiunDate = value.pensiunDate ? formatDefaultDate(value.pensiunDate) : '-'
            value.arsipDate = value.arsipDate ? formatDefaultDate(value.arsipDate) : '-'

            value.fullName = value.fullName ? (value.fullName).toUpperCase() : value.fullName
        }
       
        if(summaryMpBulanan.length !== 0 && summaryMpSekaligus.length !== 0) {
            var sumBulanan = summaryMpBulanan.reduce((a, b) => a + b)
            var sumSekaligus = summaryMpSekaligus.reduce((a, b) => a + b)
        }

        var data = {
            template: {
                name: '/dapen/kepesertaan/report/peserta/peserta-arsip',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpPaymentType: mpPaymentTypeName,
                participant: participantName,
                tanggalArsip: filter.lessArsipDate && filter.greaterArsipDate ? (filter.greaterArsipDate ? formatDefaultDate(filter.greaterArsipDate) : "~") + ' S/D ' + (filter.lessArsipDate ? formatDefaultDate(filter.lessArsipDate) : "~") : 'Semua',
                dataPeserta: getPesertaDialihkan,
                summaryMpBulanan: sumBulanan,
                summaryMpSekaligus: sumSekaligus,
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'DAFTAR ARSIP KEPESERTAAN'
            }
        }
        // var response = await reportRequest(this.props.store, data)
        // PrintElem(response.data)
        // store.dispatch(showLoading(false))

        var responseFile = await reportFile(data, true, 'DAFTAR ARSIP KEPESERTAAN')
        store.dispatch(showLoading(false))
        return responseFile
    }

    exportExcel = async () => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `view-laporan-arsips?size=9999&`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `products`, 'GET')

        if (filter.participantId) {
            loadAPI = loadAPI + `participantId.in=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        } else {
            var participantName = 'Semua'
        }

        if (filter.productId) {
            loadAPI = loadAPI + `productId.in=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        } else {
            var productName = 'Semua'
        }

        // Tanggal Arsip
        if (filter.greaterArsipDate) {
            loadAPI = loadAPI + `ArsipDate.greaterThanOrEqual=${formatDate(filter.greaterArsipDate)}&`
        }

        if (filter.lessArsipDate) {
            loadAPI = loadAPI + `ArsipDate.lessThanOrEqual=${formatDate(filter.lessArsipDate)}&`
        }
        var getPesertaDialihkan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI.slice(0, -1), 'GET')

        var index = 1
        var summaryMpBulanan = []
        var summaryMpSekaligus = []
        for (var value of getPesertaDialihkan) {
            let totalMpBulanan = value.mpBulanan ? value.mpBulanan : 0
            let totalMpSekaligus = value.mpSekaligus ? value.mpSekaligus : 0

            summaryMpBulanan.push(totalMpBulanan)
            summaryMpSekaligus.push(totalMpSekaligus)
            // var month = bulan.find(val => val.id == value.mkBulan)
            // value.mkBulan = month ? month.namaBulan : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            var dataValue = {
                id: index++,
                nik: value.nik,
                fullName: value.fullName ? (value.fullName).toUpperCase() : value.fullName,
                birthDate: value.birthDate ? formatDefaultDate(value.birthDate) : '-',
                pensiunDate: value.pensiunDate ? formatDefaultDate(value.pensiunDate) : '-',
                arsipDate: value.arsipDate ? formatDefaultDate(value.arsipDate) : '-',
                mkBulan: value.mkBulan,
                mkTahun: value.mkTahun,
                // arsipSkNo: value.arsipSkNo,
                // productId: value.productId,
                cardNo: value.cardNo,
                arsipKeterangan: value.arsipKeterangan,
                mpBulanan: value.mpBulanan ? (value.mpBulanan) : 0,
                mpSekaligus: value.mpSekaligus ? (value.mpSekaligus) : 0,
            }

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        if(summaryMpBulanan.length !== 0 && summaryMpSekaligus.length !== 0) {
            var sumBulanan = summaryMpBulanan.reduce((a, b) => a + b)
            var sumSekaligus = summaryMpSekaligus.reduce((a, b) => a + b)
        }

        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR ARSIP KEPESERTAAN');
            worksheet.addTable({
                name: 'MyTable',
                ref: 'A1',
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColumn: true,
                    // border: {
                    //     top: {style:'thin'},
                    //     left: {style:'thin'},
                    //     bottom: {style:'thin'},
                    //     right: {style:'thin'}
                    // }
                },
                columns: [
                    { name: 'No' },
                    { name: 'NIK' },
                    { name: 'Nama' },
                    { name: 'Tanggal Lahir' },
                    { name: 'Tanggal Pensiun' },
                    { name: 'Tanggal Arsip' },
                    { name: 'MK Bulan' },
                    { name: 'MK Tahun' },
                    // { name: 'No SK Arsip' },
                    // { name: 'Produk' },
                    { name: 'Card No' },
                    { name: 'Keterangan' },
                    { name: 'Nominal MP Bulanan\r\n(Rp)' },
                    { name: 'Nominal MP Sekaligus\r\n(Rp)' },
                ],
                rows: data,
            });

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BDAFTAR ARSIP KEPESERTAAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BDAFTAR ARSIP KEPESERTAAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BDAFTAR ARSIP KEPESERTAAN\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'right', wrapText: true }
                }))
            }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // // worksheet.getCell('A3').value = 'Kode'
            // // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A3').value = 'Laporan'
            // worksheet.getCell('B3').value = ': DAFTAR PESERTA ARSIP'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A6').value = 'Pemberi Kerja'
            // worksheet.getCell('B6').value = `: ${participantName}`

            // worksheet.getCell('A7').value = 'Produk'
            // worksheet.getCell('B7').value = `: ${productName}`

            // worksheet.getCell('A8').value = 'Tanggal Arsip'
            // worksheet.getCell('B8').value = `: ${filter.lessArsipDate && filter.greaterArsipDate ? (filter.greaterArsipDate ? formatDefaultDate(filter.greaterArsipDate) : "~") + ' S/D ' + (filter.lessArsipDate ? formatDefaultDate(filter.lessArsipDate) : "~") : 'Semua'}`

            worksheet.mergeCells(`A${lastTableRowNum + 1}:J${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`J${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

            worksheet.getCell(`K${lastTableRowNum + 1}`).value = (sumBulanan)
            worksheet.getCell(`K${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`K${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`L${lastTableRowNum + 1}`).value = (sumSekaligus)
            worksheet.getCell(`L${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`L${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('B1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('C1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('D1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('E1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('F1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('G1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('H1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('I1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('J1').alignment = { vertical: 'middle', horizontal: 'center' };
            worksheet.getCell('K1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            worksheet.getCell('L1').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            // worksheet.getCell('M1').alignment = { vertical: 'middle', horizontal: 'center' };
            // worksheet.getCell('N12').alignment = { vertical: 'middle', horizontal: 'center' };

            for (var i = 2; i <= data.length + 1; i++) {
                worksheet.getCell('A' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('B' + i).alignment = { vertical: 'middle', horizontal: 'left' };
                worksheet.getCell('C' + i).alignment = { vertical: 'middle', horizontal: 'left' };
                worksheet.getCell('D' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('E' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('F' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('G' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('H' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('I' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('J' + i).alignment = { vertical: 'middle', horizontal: 'center' };
                worksheet.getCell('K' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                worksheet.getCell('L' + i).alignment = { vertical: 'middle', horizontal: 'right' };
                // worksheet.getCell('M' + i).alignment = { vertical: 'middle', horizontal: 'left' };
                // worksheet.getCell('N' + i).alignment = { vertical: 'middle', horizontal: 'left' };
            }

            var WidthColums = 20;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 50;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = 30;
            Data11.numFmt = '#,##0.00';
            
            const Data12 = worksheet.getColumn(12);
            Data12.width = 30;
            Data12.numFmt = '#,##0.00';
            
            // const Data13 = worksheet.getColumn(13);
            // Data13.width = 30;

            // const Data14 = worksheet.getColumn(14);
            // Data14.width = 30;

            await workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR ARSIP KEPESERTAAN.xlsx`
                );
            });
        } catch (e) {
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"nik", 
        //         to:"NIK"
        //     },
        //     {
        //         from:"fullName", 
        //         to:"Nama"
        //     },
        //     {
        //         from:"birthDate", 
        //         to:"Tanggal Lahir"
        //     },
        //     {
        //         from:"arsipDate", 
        //         to:"Tanggal Arsip"
        //     },
        //     {
        //         from:"tglDialihkan", 
        //         to:"Tanggal Dialihkan"
        //     },
        //     {
        //         from:"mkBulan", 
        //         to:"MK Bulan"
        //     },
        //     {
        //         from:"mkTahun", 
        //         to:"MK Tahun"
        //     },
        //     {
        //         from:"arsipSkNo", 
        //         to:"No SK Arsip"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"cardNo", 
        //         to:"Card No"
        //     },
        //     {
        //         from:"mpBulanan", 
        //         to:"Nominal MP Bulanan"
        //     },
        //     {
        //         from:"mpSekaligus", 
        //         to:"Nominal MP Sekaligus"
        //     },
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]

        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'DAFTAR PESERTA ARSIP'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Pemberi Kerja',
        //         B: null,
        //         C: ':',
        //         D: participantName
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        //     {
        //         A: 'Tanggal Arsip',
        //         B: null,
        //         C: ':',
        //         D: filter.lessArsipDate && filter.greaterArsipDate ? (filter.greaterArsipDate ? formatDefaultDate(filter.greaterArsipDate) : "~") + ' S/D ' + (filter.lessArsipDate ? formatDefaultDate(filter.lessArsipDate) : "~") : 'Semua',
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []

        // for(var InstructionData of getPesertaDialihkan){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_PESERTA_DIALIHKAN')
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="main-title"> Daftar Arsip Kepesertaan </h4>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataMaster'}
                            formData={this.state.formFilter}
                            items={this.filterItem}
                            labelLocation='left'
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{ height: "calc(100vh - 415px)" }} title={'ea'} />
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportPesertaArsip