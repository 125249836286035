import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { sdmUnit, karyawan, tipeTransaksiSdmUmum, programs, statusPembayaranKasUmumSdm, programSdm, kasUmum } from 'dataSource/lookup';
import ModalJurnalUmumPembayaranRiwayat from 'components/pages/modal/sdm-umum/umum/kas-umum/riwayat-pembayaran/jurnal';
import ModalUmumPembayaranPengajuan from 'components/pages/modal/sdm-umum/umum/kas-umum/pengajuan-pembayaran/index';

class UmumRiwayatPembayaran extends Component {
    constructor(props) {
        super(props)

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalRef = React.createRef()
        this.modalJurnalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModal(data)
                        }
                    },
                    // {
                    //     text: 'Lihat Jurnal',
                    //     hint: 'Lihat Jurnal',
                    //     cssClass: 'text-primary',
                    //     onClick: (e) => {
                    //         this.showModalJurnal(e.row.data)
                    //     }
                    // },
                ],
            },
            {
                dataField:"id",
                caption:"No.",
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField:"programId",
                caption:"Program",
                lookup:{
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"kasUmumId",
                caption:"Kas Umum",
                lookup: {
                    dataSource: kasUmum(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaKas'
                }
            },
            {
                dataField: 'paymentNo',
                caption : 'No Pembayaran'
            },
            // {
            //     dataField:"paymentDate",
            //     caption:"Tanggal Pembayaran",
            //     dataType: 'date',
            //     format: 'dd MMM yyyy'
            // },
            {
                dataField:"transactioDate",
                caption:"Tanggal Transaksi",
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:"paymentNominalNett",
                caption:"Total Nominal",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0.00'
            },
            {
                dataField:"statusId",
                caption:"Status",
                alignment : 'center',
                lookup:{
                    dataSource: statusPembayaranKasUmumSdm(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
        ]
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }
    showModal = (data) => {
        this.modalRef.current.show()
        this.modalRef.current.retrieveData(data)
    }
    showModalJurnal = (data) => {
        this.modalJurnalRef.current.show(data)
        // this.modalJurnalRef.current.retrieveData(data)
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Riwayat Pembayaran</h3>
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='kas-umum-payments/getByMultipleStatusId/2%2C3?size=999'
                        insertAPI='kas-umum-payments'
                        updateAPI='kas-umum-payments'
                        deleteAPI='kas-umum-payments'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Riwayat Pembayaran"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Riwayat Pembayaran'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ModalUmumPembayaranPengajuan
                        ref={this.modalRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        from={'riwayat'}
                    />
                    <ModalJurnalUmumPembayaranRiwayat
                        ref={this.modalJurnalRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    />
                </div>
            </div>
        )
    }
}

export default UmumRiwayatPembayaran