import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { mpTypeNid2, product } from 'dataSource/lookup'
import { Form, Button } from 'devextreme-react'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultDate,formatDefaultMonthYear, PrintElem, generateExcelFile, formatNumber } from 'plugin/helper'
import httpRequest from 'plugin/httprequest';
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading'

class ReportMpLainGenerate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formFilter: {},
            src : ''
        }
        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'periode',
                        label: {
                            text: 'Periode'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'MMM yyyy',
                            placeholder : 'Bulan/Tahun',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        dataField: 'mpTypeId',
                        label: {
                            text: 'Tipe MP Lain',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpTypeNid2(this.props.store, true),
                            valueExpr: 'id',
                            displayExpr: 'mpName'
                        }
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-generates?`
        var mpTypeDetailName = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types`, 'GET')
        var bankDetailName = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET')
        var productDetailName = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.mpTypeId){
            loadAPI = loadAPI+`mpTypeId.in=${filter.mpTypeId}&`
            try {
                var mpTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types/${filter.mpTypeId}`, 'GET')).mpName
            } catch (error) {
                var mpTypeName = '-'
            }
        }else{
            var mpTypeName = 'Semua'
        }

        // PERIODE
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        }
        var getMpGenerate = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var summaryNominalMp = 0
        for(var value of getMpGenerate){
            if(value !== null) {
                var ProductCode = productDetailName.find(val => val.id == value.productId)
                value.productId = ProductCode ? ProductCode.productCode : '-'
    
                var MpName = mpTypeDetailName.find(val => val.id == value.mpTypeId)
                value.mpTypeId = MpName ? MpName.mpName : '-'
    
                var bankName = bankDetailName.find(val => val.id == value.mpRekeningBankId)
                value.mpRekeningBankId = bankName ? bankName.initialName : '-'
    
                summaryNominalMp = summaryNominalMp + (value.mpNominal || 0)
            }
        }
        getMpGenerate = getMpGenerate.map(value => {
            if(value !== null) {
                value.paymentDate = formatDefaultDate(value.paymentDate)
                value.mpNominal = formatNumber(value.mpNominal)
            }

            return value
        })
        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/mp-generate',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpType: mpTypeName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                dataPeserta : getMpGenerate,
                summaryNominalMp: formatNumber(summaryNominalMp)
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))
        this.setState({
            src : responseFile
        })
    }
    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-generates?`
        var mpTypeDetailName = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types`, 'GET')
        var bankDetailName = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET')
        var productDetailName = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.mpTypeId){
            loadAPI = loadAPI+`mpTypeId.in${filter.mpTypeId}&`
            try {
                var mpTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types/${filter.mpTypeId}`, 'GET')).mpName
            } catch (error) {
                var mpTypeName = '-'
            }
        }else{
            var mpTypeName = 'Semua'
        }

        // PERIODE
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        }
        var getMpGenerate = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var summaryNominalMp = 0
        for(var value of getMpGenerate){
            if(value !== null) {
                var ProductCode = productDetailName.find(val => val.id == value.productId)
                value.productId = ProductCode ? ProductCode.productCode : '-'
    
                var MpName = mpTypeDetailName.find(val => val.id == value.mpTypeId)
                value.mpTypeId = MpName ? MpName.mpName : '-'
    
                var bankName = bankDetailName.find(val => val.id == value.mpRekeningBankId)
                value.mpRekeningBankId = bankName ? bankName.initialName : '-'
    
                summaryNominalMp = summaryNominalMp + (value.mpNominal || 0)
            }
        }
        getMpGenerate = getMpGenerate.map(value => {
            if(value !== null) {
                value.paymentDate = formatDefaultDate(value.paymentDate)
                value.mpNominal = formatNumber(value.mpNominal)
            }

            return value
        })
        var data = {
            template:{
                name: '/dapen/kepesertaan/report/manfaat-pensiun/mp-generate',
                recipe: 'html'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                mpType: mpTypeName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                dataPeserta : getMpGenerate,
                summaryNominalMp: formatNumber(summaryNominalMp)
            }
        }
        var response = await reportRequest(this.props.store,data)
        store.dispatch(showLoading(false))
        PrintElem(response.data)
        return response
    }
    exportExcel = async() => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))

        var filter = this.state.formFilter

        var loadAPI = `list-view-manfaat-pensiun-generates?`
        var mpTypeDetailName = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types`, 'GET')
        var bankDetailName = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, `banks`, 'GET')
        var productDetailName = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')
        // var rekbank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, store, 'rekbanks')

        if(filter.productId){
            loadAPI = loadAPI+`productId.in=${filter.productId}&`
            try {
                var productName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products/${filter.productId}`, 'GET')).productCode
            } catch (error) {
                var productName = '-'
            }
        }else{
            var productName = 'Semua'
        }

        if(filter.mpTypeId){
            loadAPI = loadAPI+`mpTypeId.in${filter.mpTypeId}&`
            try {
                var mpTypeName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `manfaat-pensiun-types/${filter.mpTypeId}`, 'GET')).mpName
            } catch (error) {
                var mpTypeName = '-'
            }
        }else{
            var mpTypeName = 'Semua'
        }

        // PERIODE
        if(filter.periode){
            var bulanPeriode = new Date(filter.periode).getMonth() + 1;
            var tahunPeriode = new Date(filter.periode).getFullYear()
            loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        }
        var getMpGenerate = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var index = 1
        var summaryNominalMp = 0
        for(var value of getMpGenerate){
            if(value !== null) {
                var ProductCode = productDetailName.find(val => val.id == value.productId)
                value.productId = ProductCode ? ProductCode.productCode : '-'
    
                var MpName = mpTypeDetailName.find(val => val.id == value.mpTypeId)
                value.mpTypeId = MpName ? MpName.mpName : '-'
    
                var bankName = bankDetailName.find(val => val.id == value.mpRekeningBankId)
                value.mpRekeningBankId = bankName ? bankName.initialName : '-'
    
                // var rekbankname = rekbank.map(value => {
                //     const bank = bankDetailName.find((valueBank) => valueBank.id === value.bankId)
                //     value.paymentRekbankId = bank ? bank.initialName : '-'
                // })
    
                summaryNominalMp = summaryNominalMp + (value.mpNominal || 0)

                var dataValue = {
                    id: index++,
                    productId: value.productId,
                    mpTypeId: value.mpTypeId,
                    dasarNilai: value.dasarNilai,
                    persentase: value.persentase,
                    nominal: value.nominal ? formatNumber(value.nominal) : '0',
                    paymentDate: value.paymentDate ? formatDefaultDate(value.paymentDate) : '-',
                    nik: value.nik,
                    fullName: value.fullName,
                    namaPenerima: value.namaPenerima,
                    relasiPenerima: value.relasiPenerima,
                    // rekeningPembayar: value.rekeningPembayar,
                    // paymentRekbankId: value.paymentRekbankId,
                    mpRekeningNo: value.mpRekeningNo,
                    mpRekeningName: value.mpRekeningName,
                    mpPenerimaBankId: value.mpRekeningBankId,
                    mpNominal: value.mpNominal ? formatNumber(value.mpNominal) : 0,
                }
            }


            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('PEMBAYARAN GENERATE MANFAAT PENSIUN LAIN');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A10',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                {name: 'Produk'},
                {name: 'Tipe MP Lain'},
                {name: 'Dasar Nilai'},
                {name: 'Prosentase'},
                {name: 'Nominal'},
                {name: 'Tanggal Pembayaran'},
                {name: 'NIK'},
                {name: 'Nama Peserta'},
                {name: 'Nama Penerima'},
                {name: 'Relasi Penerima'},
                // {name: 'Rekening Pembayar'},
                // {name: 'Bank Bayar'},
                {name: 'No Rekening Penerima'},
                {name: 'Nama Rekening Penerima'},
                {name: 'Bank Penerima'},
                {name: 'Nominal MP'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                }))
            }

            worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // worksheet.getCell('A3').value = 'Kode'
            // worksheet.getCell('B3').value = ': KPS-2-02'

            worksheet.getCell('A3').value = 'Laporan'
            worksheet.getCell('B3').value = ': PEMBAYARAN GENERATE MANFAAT PENSIUN LAIN'

            worksheet.getCell('A4').value = 'Tanggal Laporan'
            worksheet.getCell('B4').value = `: ${formatDefaultDate(new Date())}`

            worksheet.getCell('A6').value = 'Periode (Bulan/Tahun)'
            worksheet.getCell('B6').value = `: ${filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua'}`
            
            worksheet.getCell('A7').value = 'Produk'
            worksheet.getCell('B7').value = `: ${productName}`

            worksheet.getCell('A8').value = 'Tipe MP Lain'
            worksheet.getCell('B8').value = `: ${mpTypeName}`

            worksheet.getCell('A10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('B10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('D10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('E10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('F10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('G10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('H10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('I10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('J10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('K10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('L10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('M10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('N10').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('O10').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('P11').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('Q11').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells(`A${lastTableRowNum + 1}:M${lastTableRowNum + 1}`);
            worksheet.getCell(`N${lastTableRowNum + 1}`).value = 'Total';
            worksheet.getCell(`N${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`O${lastTableRowNum + 1}`).value = `${formatNumber(summaryNominalMp)}`
            worksheet.getCell(`O${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            for(var i = 11; i <= 10000; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('L'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('M'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('N'+i).alignment = {vertical: 'middle', horizontal: 'left'};
                worksheet.getCell('O'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('P'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('Q'+i).alignment = {vertical: 'middle', horizontal: 'left'};
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 35;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = 30;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            const Data12 = worksheet.getColumn(12);
            Data12.width = 35;

            const Data13 = worksheet.getColumn(13);
            Data13.width = 35;

            const Data14 = worksheet.getColumn(14);
            Data14.width = 30;

            const Data15 = worksheet.getColumn(15);
            Data15.width = WidthColums;

            const Data16 = worksheet.getColumn(16);
            Data16.width = WidthColums;

            const Data17 = worksheet.getColumn(17);
            Data17.width = WidthColums;
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_GENERATE_MP_LAIN.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"mpTypeId", 
        //         to:"Tipe MP Lain"
        //     },
        //     {
        //         from:"dasarNilai", 
        //         to:"Dasar Nilai"
        //     },
        //     {
        //         from:"persentase", 
        //         to:"Prosentase"
        //     },
        //     {
        //         from:"nominal", 
        //         to:"Nominal"
        //     },
        //     {
        //         from:"paymentDate", 
        //         to:"Tanggal Pembayaran"
        //     },
        //     {
        //         from:"nik", 
        //         to:"NIK"
        //     },
        //     {
        //         from:"fullName", 
        //         to:"Nama Peserta"
        //     },
        //     {
        //         from:"namaPenerima", 
        //         to:"Nama Penerima"
        //     },
        //     {
        //         from:"relasiPenerima", 
        //         to:"Relasi Penerima"
        //     },
        //     {
        //         from:"rekeningPembayar", 
        //         to:"Rekening Pembayar"
        //     },
        //     {
        //         from:"paymentRekbankId", 
        //         to:"Bank Bayar"
        //     },
        //     {
        //         from:"mpRekeningNo", 
        //         to:"No Rekening Penerima"
        //     },
        //     {
        //         from:"mpRekeningName", 
        //         to:"Nama Rekening Penerima"
        //     },
        //     {
        //         from:"mpPenerimaBankId", 
        //         to:"Bank Penerima"
        //     },
        //     {
        //         from:"nominal", 
        //         to:"Nominal MP"
        //     }
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'PEMBAYARAN GENERATE MANFAAT PENSIUN LAIN'
        //     },
        //     {
        //         A: 'Tanggal Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Periode',
        //         B: null,
        //         C: ':',
        //         D: filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        //     {
        //         A: 'Tipe MP Lain',
        //         B: null,
        //         C: ':',
        //         D: mpTypeName
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getMpGenerate){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})
        // store.dispatch(showLoading(false))
        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // generateExcelFile(excelBlob,'LAPORAN_GENERATE_MP_LAIN')
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pembayaran Generate Manfaat Pensiun Lain</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.formRef}
                            formData={this.state.formFilter}
                            items={this.formItem}
                            labelLocation={"left"}
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportMpLainGenerate