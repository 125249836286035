import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import { addURL } from 'redux/actions/url';
import { managerInvestasis, currency, instrumentTransactionTypes, instrumentReksadana, instrumentSubCategoryReksadana, productDefault, kpds, emitens } from 'dataSource/lookup';
import ModalAllotmentIpoPelepasanReksadana from 'components/pages/modal/investasi/settlement/penyelesaian/allotment-ipo/index';
import httpRequest from 'plugin/httprequest';
import ModalSettlementPenyelesaianAllotmentIpo from 'components/pages/modal/investasi/settlement/penyelesaian/allotment-ipo/index';

class SettlementPenyelesaianAllotmentIpo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {},
            filterItem: {}
        }

        this.modalAlokasiRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalSettlementPenyelesaianAllotmentIpoRef = React.createRef()
        this.modalAlokasiPelepasanRef = React.createRef()
        this.modalFileSettlementPenyelesaianAllotmentIpoRef = React.createRef()

        this.filterItem = [
            {
                dataField: "tanggalTransaksi",
                label: {
                    text: "Tanggal Transaksi",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox'
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        // this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Allotment',
                        hint: 'Allotment',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalSettlementPenyelesaianAllotmentIpo(e.row.data)
                        }
                    },
                    {
                        text: 'File',
                        hint: 'File',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalFileSettlementPenyelesaianAllotmentIpo('edit', e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'settleNo',
                caption: 'Nomor Transaksi',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                },
            },
            {
                dataField: 'kpdId',
                caption: 'Pengelola',
                lookup: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak'
                }
            },
            {
                dataField: 'emitenId',
                caption: 'Emiten',
                lookup: {
                    dataSource: emitens(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'emitenName'
                }
            },
            {
                dataField: 'transactionTypeId',
                caption: 'Tipe Transaksi',
                lookup: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName'
                }
            },
            {
                dataField: 'paymentDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'allotmentDate',
                caption: 'Tanggal Allotment',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'amountTrs',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModalSettlementPenyelesaianAllotmentIpo = async (data) => {
        this.modalSettlementPenyelesaianAllotmentIpoRef.current.show(data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Allotment IPO Saham</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='transactions?statusId.in=ORDER&transactionTypeId.in=319'
                    insertAPI='cash-deviden'
                    updateAPI='cash-deviden'
                    deleteAPI='cash-deviden'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Allotment IPO Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Allotment IPO Saham'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}


                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalSettlementPenyelesaianAllotmentIpo
                    ref={this.modalSettlementPenyelesaianAllotmentIpoRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default SettlementPenyelesaianAllotmentIpo