import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { ScrollView } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import { product } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class ViewJurnalDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {
                id: 0
            },
            DataJurnal: {},
            newData: false,
        }

        this.summary1 = [
            {
                displayFormat: 'Total :',
                showInColumn: 'baseCurrencyCode'
            },
            {
                name: 'baseCurrencyDebit',
                column: 'baseCurrencyDebit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'baseCurrencyKredit',
                column: 'baseCurrencyKredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                displayFormat: 'Total :',
                showInColumn: 'transactionCurrencyCode'
            },
            {
                name: 'transactionCurrencyDebit',
                column: 'transactionCurrencyDebit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'transactionCurrencyKredit',
                column: 'transactionCurrencyKredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            }
        ]

        this.summary2 = [
            {
                displayFormat: 'Total :',
                showInColumn: 'baseCurrency'
            },
            {
                name: 'baseCurrencyDb',
                column: 'baseCurrencyDb',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'getBaseCurrencyCr',
                column: 'getBaseCurrencyCr',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                displayFormat: 'Total :',
                showInColumn: 'transCurrency'
            },
            {
                name: 'transCurrencyDb',
                column: 'transCurrencyDb',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'transCurrencyCr',
                column: 'transCurrencyCr',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            }
        ]

        this.popupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options: {
            //         'text': 'Simpan',
            //         onClick: this.submitData.bind(this)
            //     },
            //     toolbar: 'bottom'
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster1 = [
            {
                dataField: 'tanggal',
                label: {
                    text: 'Tanggal',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMarkBehavior: true,
                }
            },
            {
                dataField: 'produkName',
                label: {
                    text: 'Produk',
                },
            },
            {
                dataField: 'kpdCode',
                label: {
                    text: 'Kpd',
                },
            },
            {
                dataField: 'instrumentCategoryCode',
                label: {
                    text: 'Kategori',
                },
            },
            {
                dataField: 'instrumentCategorySubCode',
                label: {
                    text: 'Sub Kategori',
                },
            },
            {
                dataField: 'instrumentCode',
                label: {
                    text: 'Intrument',
                },
            },
            {
                dataField: 'currencyCode',
                label: {
                    text: 'Currency',
                },
            },
            {
                dataField: 'currencyRate',
                label: {
                    text: 'Kurs',
                },
            },
            {
                dataField: 'bilyetNo',
                label: {
                    text: 'Bilyet',
                },
            },
            {
                dataField: 'transactionTypeName',
                label: {
                    text: 'Tipe Transaksi',
                },
            },
            {
                dataField: 'description',
                label: {
                    text: 'Keterangan',
                },
            },
        ]

        this.dataMaster2 = [
            {
                dataField: 'tglJurnal',
                label: {
                    text: 'Tanggal',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMarkBehavior: true,
                }
            },
            {
                dataField: 'produk',
                label: {
                    text: 'Produk',
                },
            },
            {
                dataField: 'kpd',
                label: {
                    text: 'Kpd',
                },
            },
            {
                dataField: 'kategori',
                label: {
                    text: 'Kategori',
                },
            },
            {
                dataField: 'subKategori',
                label: {
                    text: 'Sub Kategori',
                },
            },
            {
                dataField: 'instrument',
                label: {
                    text: 'Intrument',
                },
            },
            {
                dataField: 'currency',
                label: {
                    text: 'Currency',
                },
            },
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs',
                },
            },
            {
                dataField: 'bilyet',
                label: {
                    text: 'Bilyet',
                },
            },
            {
                dataField: 'tipeTransaksi',
                label: {
                    text: 'Tipe Transaksi',
                },
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan',
                },
            },
        ]

        this.columnsJurnal1 = [
            {
                dataField: 'akun',
                caption: 'Akun'
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                caption: 'Mata Uang Basis',
                alignment: 'center',
                name: "mataUangBasis",
                columns: [
                    {
                        dataField: 'baseCurrencyCode',
                        caption: 'Currency'
                    },
                    {
                        dataField: 'baseCurrencyDebit',
                        caption: 'Debet',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'baseCurrencyKredit',
                        caption: 'Credit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                caption: 'Mata Uang Transaksi',
                alignment: 'center',
                name: "mataUangTrans",
                columns: [
                    {
                        dataField: 'transactionCurrencyCode',
                        caption: 'Currency'
                    },
                    {
                        dataField: 'transactionCurrencyDebit',
                        caption: 'Debet',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'transactionCurrencyKredit',
                        caption: 'Credit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'transactionCurrencyKurs',
                        caption: 'Kurs',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
        ]

        this.columnsJurnal2 = [
            {
                dataField: 'akun',
                caption: 'Akun'
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                caption: 'Mata Uang Basis',
                alignment: 'center',
                name: "mataUangBasis",
                columns: [
                    {
                        dataField: 'baseCurrency',
                        caption: 'Currency'
                    },
                    {
                        dataField: 'baseCurrencyDb',
                        caption: 'Debet',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'getBaseCurrencyCr',
                        caption: 'Credit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                caption: 'Mata Uang Transaksi',
                alignment: 'center',
                name: "mataUangTrans",
                columns: [
                    {
                        dataField: 'transCurrency',
                        caption: 'Currency'
                    },
                    {
                        dataField: 'transCurrencyDb',
                        caption: 'Debet',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'transCurrencyCr',
                        caption: 'Credit',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'kurs',
                        caption: 'Kurs',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    newData = (newData) => {
        this.setState({
            newData: newData
        })
    }

    retrieveData = (data) => {

        this.setState({
            DataMaster: data,
        })
    }

    loadDataFromGenerate = async () => {
        var DataDetails = this.state.DataMaster
        var loadApi = `transaction-jurnal/get-by-transactionId/${DataDetails.id}`

        try {
            var DataJurnal = { ...this.state.DataJurnal }
            var DataMaster = { ...this.state.DataMaster }
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "GET"
            );

            var dataProduct = await httpRequest(
                process.env.REACT_APP_BACKEND,
                this.props.store,
                `products/${this.state.DataMaster.produkId}`,
                "GET"
            );
            DataJurnal.produkName = dataProduct.productName
            DataJurnal.tanggal = response.tanggal
            DataJurnal.kpdCode = response.kpdCode
            DataJurnal.instrumentCategoryCode = response.instrumentCategoryCode
            DataJurnal.instrumentCategorySubCode = response.instrumentCategorySubCode
            DataJurnal.instrumentCode = response.instrumentCode
            DataJurnal.currencyCode = response.currencyCode
            DataJurnal.currencyRate = response.currencyRate
            DataJurnal.bilyetNo = response.bilyetNo
            DataJurnal.transactionTypeName = response.transactionTypeName
            DataJurnal.description = response.description

            this.setState({
                DataJurnal: DataJurnal
            })

            if (response.transJurnalDtlList.length > 0) {
                return response.transJurnalDtlList
            } else {
                return []
            }
        } catch (e) {
            console.log(e)

            return []
        }
    }

    loadDataFromJurnal = async () => {
        var DataDetails = this.state.DataMaster
        var loadApi = `transaction-jurnal-dtls/view-jurnal-detail/${DataDetails.id}`

        try {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "GET"
            );

            var DataJurnal = { ...this.state.DataJurnal }

            DataJurnal.produk = response.produk
            DataJurnal.tglJurnal = response.tglJurnal
            DataJurnal.kpd = response.kpd
            DataJurnal.kategori = response.kategori
            DataJurnal.subKategori = response.subKategori
            DataJurnal.instrument = response.instrument
            DataJurnal.currency = response.currency
            DataJurnal.kurs = response.kurs
            DataJurnal.bilyet = response.bilyet
            DataJurnal.tipeTransaksi = response.tipeTransaksi
            DataJurnal.keterangan = response.keterangan
            this.setState({
                DataJurnal: DataJurnal
            })

            if (response.jurnalVoucherDtls.length > 0) {

                return response.jurnalVoucherDtls
            } else {
                return []
            }
        } catch (e) {
            console.log(e)

            return []
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        })
        this.dataGridRef.current.forceRefresh(true)
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
            DataJurnal: {},
        })
    }


    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'75vw'}
                    height="65vh"
                    title={`Jurnal Transaksi`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    {/* <div style={{padding:"2%"}}> */}
                    <ScrollView width='100%' height='100%'>
                        <div className="container-fluid">
                            <Form
                                colCount={2}
                                id={'dataMaster'}
                                formData={this.state.DataJurnal}
                                items={this.props.from == "generate" ? this.dataMaster1 : this.dataMaster2}
                                labelLocation={"left"}
                                readOnly={true}
                            />
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                loadAPI='daftar-penagjuan-pengadaan'
                                insertAPI='daftar-penagjuan-pengadaan'
                                updateAPI='daftar-penagjuan-pengadaan'
                                deleteAPI='daftar-penagjuan-pengadaan'

                                backendserver={process.env.REACT_APP_BACKEND_HRD}

                                useArraySource={true}
                                ArraySourceData={this.props.from == "generate" ? this.loadDataFromGenerate : this.loadDataFromJurnal}
                                keyField={this.props.from == "generate" ? 'seqNo' : 'id'}
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Jurnal Transaksi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={false}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Transaksi Mark To Market'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.props.from == "generate" ? this.columnsJurnal1 : this.columnsJurnal2} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}
                                summaryTotalItem={this.props.from == "generate" ? this.summary1 : this.summary2}
                                onToolbarPreparing={this.onToolbarPreparing}

                                height={'calc(100vh - 675px)'}
                                // height={'300px'}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </ScrollView>
                    {/* </div> */}
                </Popup>
            </React.Fragment>
        )
    }
}

export default ViewJurnalDetails