import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'

class RoleWorkflow extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                dataField: 'groupCode',
                caption: 'Kode Grup'
            },
            {
                dataField: 'groupName',
                caption: 'Nama Grup'
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 
    loadDummyData = () => {
        var data = [
            {
                id: 1,
                groupCode: 'KI',
                groupName: 'Komite Investasi'
            },
            {
                id: 2,
                groupCode: 'DR',
                groupName: 'Direktur'
            },
            {
                id: 3,
                groupCode: 'MK',
                groupName: 'Maker'
            },
            {
                id: 4,
                groupCode: 'CK',
                groupName: 'Checker'
            },
            {
                id: 5,
                groupCode: 'AP',
                groupName: 'Approval'
            },
        ]
        return data
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Group Workflow</h2>

                <DevExpressDataGrid
                    loadAPI='kecamatans'
                    insertAPI='staticdata-wf/kecamatans'
                    updateAPI='staticdata-wf/kecamatans'
                    deleteAPI='staticdata-wf/kecamatans' 

                    backendserver={process.env.REACT_APP_BACKEND}

                    useArraySource = {true}
                    ArraySourceData = {this.loadDummyData}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Workflow"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Group Workflow Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    // remoteOperations = {true}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default RoleWorkflow