import React, { Component } from 'react'
import { currency, banks, orders, product, broker, pejabat} from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormInstruksiBrokerAktif from 'components/pages/modal/investasi/instruksi/broker/aktif/index'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'
import { formatDefaultFullDate, formatNumber, numberToIndo } from 'plugin/helper'
import reportRequest from 'plugin/reportRequest'

class InstruksiBrokerAktif extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTabIndex: 0,
    }

    this.dataGridInstruksiBrokerRef = React.createRef()
    this.formInstruksiBrokerAktifRef = React.createRef()
    this.rekBrokerData = []
    this.instrumentData = []
    this.brokerData = []

    this.orderColumns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Ubah',
            hint: 'Ubah',
            onClick: (e) => {
              this.showModalBrokerAktif(e.row.data)
            }
          },
          // {
          //   text: 'Cetak Instruksi',
          //   hint: 'Cetak Instruksi',
          //   onClick: (e) => {
          //     this.cetakInstruksi(e.row.data)
          //   }
          // },
          {
            text: 'Kirim',
            hint: 'Kirim',
            onClick: (e) => {
              var data = e.row.data
              confirmAlert({
                title: 'Apakah yakin?',
                message: 'Apakah anda yakin ingin mengirim instruksi ini?',
                buttons: [
                  {
                    label: 'Ya',
                    onClick: () => {
                      this.sendInstruksi(data)
                    }
                  },
                  {
                    label: 'Tidak'
                  }
                ]
              })
            }
          },
          {
            text: 'Batal',
            hint: 'Batal',
            onClick: (e) => {
              var data = e.row.data
              confirmAlert({
                title: 'Apakah yakin?',
                message: 'Apakah anda yakin ingin membatalkan instruksi ini?',
                buttons: [
                  {
                    label: 'Ya',
                    onClick: (e) => {
                      this.cancelInstruksi(data)
                    }
                  },
                  {
                    label: 'Tidak',
                  }
                ]
              })
            }
          },
        ]
      },
      {
        dataField: 'instruksiId',
        caption: 'ID',
        alignment: 'right',
        width: '75px'
      },
      {
        dataField: 'brokerId',
        caption: 'Broker',
        lookup: {
          dataSource: broker(this.props.store),
          valueExpr: 'id',
          displayExpr: 'brokerName'
        }
      },
      {
        dataField: 'instruksi',
        caption: 'Instruksi',
        alignment: 'center',
        columns: [
          {
            dataField: 'instruksiNo',
            caption: 'Nomor'
          },
          {
            dataField: 'instruksiDate',
            caption: 'Tanggal',
            dataType: 'date',
            format: 'dd MMM yyyy'
          },
          {
            dataField: 'pejabat1',
            caption: 'Pejabat 1',
            lookup: {
                dataSource: pejabat(this.props.store),
                valueExpr: 'id',
                displayExpr: 'fullName'
            }
          },
          {
            dataField: 'pejabat2',
            caption: 'Pejabat 2',
            lookup: {
                dataSource: pejabat(this.props.store),
                valueExpr: 'id',
                displayExpr: 'fullName'
            }
          },
        ]
      },
      {
        dataField: 'order',
        caption: 'Order',
        alignment: 'center',
        columns: [
          {
            dataField: 'totalOrder',
            caption: 'Jumlah',
            dataType: 'number',
            alignment: 'right'
          },
          {
            dataField: 'totalQuantityOrder',
            caption: 'Total Kuantitas (Lembar)',
            dataType: 'number',
            alignment: 'right',
            format: '#,##0'
          }
        ]
      },
      {
        dataField: 'pembelian',
        caption: 'Pembelian',
        alignment: 'center',
        columns: [
          {
            dataField: 'sahamBuy',
            caption: 'Saham',
          },
          {
            dataField: 'totalQuantityOrderBuy',
            caption: 'Total Kuantitas',
            dataType: 'number',
            format: '#,##0.00',
            alignment: 'right'
          }
        ]
      },
      {
        dataField: 'penjualan',
        caption: 'Penjualan',
        alignment: 'center',
        columns: [
          {
            dataField: 'sahamSell',
            caption: 'Saham',
          },
          {
            dataField: 'totalQuantityOrderSell',
            caption: 'Total Kuantitas',
            dataType: 'number',
            format: '#,##0.00',
            alignment: 'right'
          }
        ]
      },
      {
        dataField: 'statusId',
        caption: 'Status',
      },
    ]
  }

  componentWillMount = async () => {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))

    var getRekBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-brokers', 'GET')
    var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'instruments?size=999', 'GET')
    var getBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'brokers', 'GET')

    this.rekBrokerData = getRekBroker
    this.brokerData = getBroker
    this.instrumentData = getInstrument
  }
  forceRefresh = () => {
    this.dataGridInstruksiBrokerRef.current.forceRefresh(true)
  }
  sendInstruksi = async (data) => {
    var instruksiId = data.instruksiId
    var responseDetailInstruksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-stok/${instruksiId}`, 'GET')
    var fileInstruksi = responseDetailInstruksi.orderInstruksiDTO.fileInstruksi
    if (data.pejabat1 || data.pejabat2 == null) {
      if (fileInstruksi != null) {
        if (instruksiId) {
          try {
            var insertAPI = 'order-stoks/send-instruksi-stok'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
              values: [
                instruksiId
              ]
            })
            notify({ message: 'Pengiriman Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            this.forceRefresh()
            return response
          } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
          }
        }
      } else {
        notify({ message: 'Mohon lengkapi file instruksi terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
      }
    } else {
      notify({ message: 'Mohon lengkapi data terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
    }
  }
  cancelInstruksi = async (data) => {
    var instruksiId = data.instruksiId
    if (instruksiId) {
      try {
        var insertAPI = 'order-stoks/batal-instruksi-stok'
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
          values: [
            instruksiId
          ]
        })
        notify({ message: 'Pembatalan Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        this.forceRefresh()
        return response
      } catch (e) {
        console.log(e)
        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
      }
    }
  }
  showModalBrokerAktif = async (data) => {
    var instruksiId = data.instruksiId
    var loadAPI = `order-instruksi-stok/${instruksiId}`
    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
    var dataMaster = response.orderInstruksiDTO
    var dataDataGrid = response.orderStokInfos
    var dataStok = []
    for (var dataDetail of dataDataGrid) {
      for (var stokData of dataDetail.orderStokDTOList) {
        var dataOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `orders/${stokData.orderId}`, 'GET')
        var detail = {
          id: stokData.id,
          orderId: stokData.orderId,
          orderCode: dataOrder.orderCode,
          orderTypeId: stokData.orderTypeId,
          instrumentId: stokData.instrumentId,
          quantityOrder: stokData.quantityOrder,
          priceType: stokData.priceType,
          priceMin: stokData.priceMin,
          priceMax: stokData.priceMax,
        }
        dataStok.push(detail)
      }
      var customGetData = {
        rekName: dataMaster.sourceRekBrokerId == null ? null : this.rekBrokerData.find(val => val.id == dataMaster.sourceRekBrokerId).rekName,
        rekNo: dataMaster.sourceRekBrokerId == null ? null : this.rekBrokerData.find(val => val.id == dataMaster.sourceRekBrokerId).rekNo,
        ...dataMaster,
        ...data,
        dataStok: dataStok,
      }
    }
    this.formInstruksiBrokerAktifRef.current.show(customGetData)
  }
  cetakInstruksi = async (data) => {
    var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-instruksi-stok/${data.instruksiId}`, 'GET')
    var result = []
    if (data.totalBuy !== 0 && data.totalSell !== 0) {
      console.log('Jual Beli Saham')
    } else if (data.totalBuy !== 0 && data.totalSell == 0) {
      for(var saham of detail.orderStokInfos){
        var no = 0
        for (var dataDetail of saham.orderStokDTOList){
          var dataSaham = {
            no: ++no,
            jumlahLembar: formatNumber(dataDetail.quantityOrder),
            namaSaham: this.instrumentData.find(val => val.id == dataDetail.instrumentId).instrumentCode,
            broker: this.brokerData.find(val => val.id == dataDetail.brokerId).brokerName
          }
        }
        result.push(dataSaham)
      }
      reportRequest(this.props.store, {
        template: { 'shortid': 'rklnU1KFLD' },
        data: {
          "instruksiNo": data.instruksiNo,
          "instruksiDate": formatDefaultFullDate(data.instruksiDate),
          "saham": result,
          "instrumentName": data.sahamBuy,
          "rekNo": this.rekBrokerData.find(val => val.id == detail.orderInstruksiDTO.sourceRekBrokerId).rekNo,
          "rekName": this.rekBrokerData.find(val => val.id == detail.orderInstruksiDTO.sourceRekBrokerId).rekName,
          "rekBankName": "Bank -",
          "rekBankCabang": "Jakarta",
          "brokerName": data.brokerName,
          "instruksiKepada": detail.orderInstruksiDTO.instruksiKepada,
          "instruksiUp": detail.orderInstruksiDTO.instruksiUp,
          "instruksiFax": detail.orderInstruksiDTO.instruksiFax,
          "pejabat1": detail.orderInstruksiDTO.pejabat1,
          "pejabat2": detail.orderInstruksiDTO.pejabat2,
        },
        options: {
          reportName: "INSTRUKSI-"+data.instruksiNo
        }
      })
    } else if (data.totalBuy == 0 && data.totalSell !== 0) {
      console.log('Jual Saham')
    }
  }
  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Instruksi Broker</h2>
        <DevExpressDataGrid
          keyField='instruksiId'
          ref={this.dataGridInstruksiBrokerRef}
          loadAPI='order-instruksi-stok/list?status=OPEN'
          insertAPI='instruksi-broker'
          updateAPI='instruksi-broker'
          deleteAPI='instruksi-broker'

          backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Instruksi Broker"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Instruksi Broker'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          height={'calc(100vh - 220px)'}

          ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
          // SummaryConfiguration={this.summary}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />

        <FormInstruksiBrokerAktif
          ref={this.formInstruksiBrokerAktifRef}
          store={this.props.store}
          forceRefresh={this.forceRefresh}
        />
      </div>
    )
  }
}

export default InstruksiBrokerAktif