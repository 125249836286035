import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { alert,confirm } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { asetKategoriSdm,sdmUnit, subAsetKategoriSdm } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalUmumPengajuan from 'components/pages/modal/sdm-umum/umum/pengadaan/pengajuan/index';
import { formatDefaultFullDate, formatNumberAum } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class UmumPengadaanPengajuan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.DataGridPemeriksaanRef = React.createRef()
        this.DataGridPersetujuanRef = React.createRef()
        this.modalUmumPengajuan = React.createRef()
        this.modalRef = React.createRef()
        
        this.columns = [
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id : 'O',
                            value: 'Open'
                        },
                        {
                            id : 'A',
                            value: 'Proses Pemeriksaan'
                        },
                        {
                            id : 'P',
                            value: 'Selesai'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'noPengadaan',
                caption: 'No Pengadaan'
            },
            {
                dataField: 'namaAset',
                caption: 'Nama Aset',
            },
            {
                dataField: 'pengadaanDate',
                caption: 'Tanggal Pengadaan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'unitId',
                caption: 'Unit Kerja',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                }
            },
            {
                dataField: 'assetKategoriId',
                caption: 'Category',
                lookup: {
                    dataSource: asetKategoriSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categoryName',
                }
            },
            {
                dataField: 'assetKategoriSubId',
                caption: 'Sub Kategori',
                lookup: {
                    dataSource: subAsetKategoriSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categorySubName',
                }
            },
            {
                dataField: 'merek',
                caption: 'Merk'
            },
            {
                dataField: 'jumlah',
                caption: 'Jumlah',
                dataType: 'number',
                format : '#,##0'
            },
            {
                dataField: 'hargaPerkiraan',
                caption: 'Harga Perkiraan',
                dataType: 'number',
                format : '#,##0.00'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        if(this.props.type === 'pengajuan'){
            this.columns = [
                {
                    type: 'buttons',
                    buttons: [
                        {
                            text: 'Edit',
                            hint: 'Edit',
                            cssClass: 'text-primary',
                            onClick: (e) => {
                                this.showModal(e.row.data, 'edit')
                            }
                        },
                        // {
                        //     text: 'Detail',
                        //     hint: 'Detail',
                        //     cssClass: 'text-primary',
                        //     onClick: (e) => {
                        //         this.showModal(e.row.data, 'detail')
                        //     }
                        // },
                        {
                            text: 'Kirim',
                            hint: 'Kirim',
                            cssClass: 'text-success',
                            onClick: async (e) => {
                                const result = await confirm("Apakah anda yakin ingin mengirim data pengajuan ini?","Konfirmasi")
                                if(result){
                                    this.kirim(e.row.data)
                                }
                            }
                        },
                        {
                            text: 'Hapus',
                            hint: 'Hapus',
                            cssClass: 'text-primary',
                            onClick: async(e) => {
                                const result = await confirm("Apakah anda ingin menghapus data ini??", "Konfirmasi");
                                if (result) {
                                    this.deleteData(e.row.data, 'edit')
                                }
                            }
                        }
                    ],
                },
                ...this.columns
            ]
        }else{
            this.columns = [
                {
                    type: 'buttons',
                    buttons: [
                        {
                            text: 'Lihat',
                            hint: 'Lihat',
                            cssClass: 'text-primary',
                            onClick: (e) => {
                                this.showModal(e.row.data, 'view')
                            }
                        },
                    ],
                },
                ...this.columns
            ]
        }
    }

    showModal = (data, type) => {
        switch(type){
            case 'add' :    this.modalUmumPengajuan.current.show(false)
                            break;
            case 'edit' :   this.modalUmumPengajuan.current.show(false)
                            this.modalUmumPengajuan.current.retrieveData(data)
                            break;
            case 'view' :   this.modalUmumPengajuan.current.show(true)
                            this.modalUmumPengajuan.current.retrieveData(data)
                            break;
            default : break
        }
    }

    deleteData = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pengadaans`, 'DELETE', data.id);

            await alert('Data berhasil dihapus', 'Information');
            this.forceRefresh()            
        } catch (error) {
            console.log(error);
            await alert(error, 'Error');
        }
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'asset-pengadaans?size=9999');
        
        for (let value of response){
            let dataUnit = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `units/${value.unitId}`, 'GET');
            let dataCategory = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-categories/${value.assetKategoriId}`, 'GET');
            let dataSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-category-subs/${value.assetKategoriSubId}`, 'GET');
            let dataValue = {
                status: value.status === 'O' ? 'Open' : value.status === 'P' ? 'Dalam Proses' : value.status === 'S' ? 'Selesai' : '-',
                noPengadaan: value.noPengadaan,
                namaAset: value.namaAset,
                pengadaanDate: value.pengadaanDate ? formatDefaultFullDate(value.pengadaanDate) : '-',
                unitId: dataUnit.unitName,
                assetKategoriId: dataCategory.categoryName,
                assetKategoriSubId: dataSubCategory.categorySubName,
                merek: value.merek,
                jumlah: value.jumlah,
                hargaPerkiraan: formatNumberAum(value.hargaPerkiraan) ? formatNumberAum(value.hargaPerkiraan) : 0
            }
           const objectValue = Object.values(dataValue);
           data.push(objectValue);
        }

        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR PENSIUN DITUNDA');
            worksheet.addTable({
              name: 'MyTable',
              ref: `A${firstTableRowNum}`,
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'Status'},
                {name: 'No Pengadaan'},
                {name: 'Nama Aset'},
                {name: 'Tanggal Pengadaan'},
                {name: 'Unit Kerja'},
                {name: 'Caategory'},
                {name: 'Sub Kategori'},
                {name: 'Merk'},
                {name: 'Jumlah'},
                {name: 'Harga Pengkinian'}
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'DAFTAR RIWAYAT PENGAJUAN PENGADAAN';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            var WidthColums = 20;
            var WidthColums2 = 35;
                
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums2;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums2;

            const Data7 = worksheet.getColumn(7);
            Data7.width = 40;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR_RIWAYAT_PENGAJUAN_PENGADAAN.xlsx`
                );
            });
        } catch (error) {
            console.log(error);
        }
    }

    onToolbarPreparing = (e) => {
        if(this.props.type === 'pengajuan'){
            e.toolbarOptions.items.unshift(
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'plus',
                        text: 'Pengajuan',
                        onClick: (e) => {
                            this.showModal({}, 'add')
                        }
                    }
                }
            )
        }else {
            e.toolbarOptions.items.unshift(
                {
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'download',
                        hint: 'Export',
                        onClick: (e) => {
                            this.exportExcel()
                        },
                    }
                }
            )
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    kirim = async(data) => {
        try {
            var loadAPI = `asset-pengadaans`
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,'PUT',{
                values: {
                    status : 'A'
                }
            },data)
    
            await alert('Data berhasil dikirim', 'Information')
            this.forceRefresh()
        } catch (e) {
            console.log(e)
            await alert(e, 'Error')
        }
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'asset-pengadaans?size=9999')

        if(this.props.type === 'pengajuan'){
            response = response.filter(value => value.status === 'O')
        }else{
            response = response.filter(value => value.status === 'P')
        }

        return response
    }

    render() {
        let MenuTitle
        if(this.props.type === 'pengajuan'){
            MenuTitle = 'Daftar Pengajuan Pengadaan'
        }else{
            MenuTitle = 'Daftar Riwayat Pengajuan Pengadaan'
        }
        return (
            <div className="container-fluid">
                <h3 className="main-title">{MenuTitle}</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='asset-pengadaans'
                        insertAPI='asset-pengadaans'
                        updateAPI='asset-pengadaans'
                        deleteAPI='asset-pengadaans'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource = {true}
                        ArraySourceData = {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={MenuTitle}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={MenuTitle}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ModalUmumPengajuan
                    ref={this.modalUmumPengajuan}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default UmumPengadaanPengajuan
