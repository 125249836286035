import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { CheckBox } from 'devextreme-react';
import { ab2str, yyyymmddToDate, formatNumber, cleanQuote, } from 'plugin/helper';
import XLSX from 'xlsx'

import { DataGrid, FileUploader } from "devextreme-react";
import { Paging, Column } from "devextreme-react/data-grid";
import { gender, agama, statusPegawai, unit, statusKaryawan, grade, months, jabatan, maritalStatus, kelurahans, provinces, cities, kecamatans, banks, product, hubunganKeluarga, dasarNilai } from 'dataSource/lookup';
import { tax, kelurahan } from 'components/pages/parts/staticDataColumnConfig';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { showLoading } from 'redux/actions/loading';
import { confirm } from 'devextreme/ui/dialog';

class FormClosingPriceObligasi extends Component {
    constructor(props) {
        super(props)

        this.fileUploderRef = React.createRef();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = {
            popupVisible: false,
            uploadedData: [],
            // selectedTabIndex: 0,
            useHeader: false
        }

        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()

        this.successData = []
        this.errorData = []
        this.getInstrument = []
        this.prevTabIndex = 0

        this.columns = [
            {
                dataField: 'date',
                caption: 'Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'series',
                caption: 'Series',
            },
            {
                dataField: 'isinCode',
                caption: 'ISIN',
            },
            {
                dataField: 'bondName',
                caption: 'Name',
            },
            {
                dataField: 'couponRate',
                caption: 'Coupon Rate',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'maturityDate',
                caption: 'Maturity Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'ttm',
                caption: 'TTM',
            },
            {
                dataField: 'todayYield',
                caption: 'Today Fair Yield',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'todayLowPrice',
                caption: 'Today Low Price',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'todayFairPrice',
                caption: 'Today Fair Price',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'todayHighPrice',
                caption: 'Today High Price',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'change',
                caption: 'Change (BPS)',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'yesterdayYield',
                caption: 'Yesterday Fair Yield',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'yesterdayPrice',
                caption: 'Yesterday Fair Price',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'lastWeekYield',
                caption: 'lastWeek Fair Yield',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'lastWeekPrice',
                caption: 'lastWeek Fair Price',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'lastMonthYield',
                caption: 'Lastmonth Fair Yield',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'lastMonthPrice',
                caption: 'Lastmonth Fair Price',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: "Data Berhasil"
            },
            {
                id: 1,
                text: "Data Gagal"
            }
        ];

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ]
    }

    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    onUploadStarted = (e) => {
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if (fileType === 'xls' || fileType === 'xlsx') {
                    var workbook = XLSX.read(dataArrayBuffer, { type: 'array' });
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)

                    data = dataJSON.map(value => Object.values(value))
                }

                if (fileType === 'csv') {
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                        data = data.filter(value => {
                            regex.lastIndex = 0
                            return regex.test(value)
                        }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)

                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)

                                var splitRes = splitRes.map(splitVal => splitVal.trim())

                                var splitRes = splitRes.map(splitVal => {
                                    if (String(splitVal)) {
                                        return String(splitVal)
                                    } else {
                                        return splitVal
                                    }
                                })

                                return splitRes
                            }
                        })
                    } else {
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                var errorMessage = []


                for (var uploadedData of data) {
                    if (Array.isArray(uploadedData)) {
                        errorMessage = []
                        if (uploadedData.length !== 18) {
                            errorMessage.push('Format file salah')
                        }
                        const selectedInstrument = this.getInstrument.find(val => val.instrumentCode === uploadedData[1])
                        if (!selectedInstrument) {
                            errorMessage.push('Instrument tidak tersedia')
                        }
                        if (errorMessage.length > 0) {
                            errorData.push({
                                errorMessage: errorMessage,
                                date: yyyymmddToDate(uploadedData[0]),
                                series: uploadedData[1],
                                isinCode: uploadedData[2],
                                bondName: uploadedData[3],
                                couponRate: uploadedData[4],
                                maturityDate: yyyymmddToDate(uploadedData[5]),
                                ttm: uploadedData[6],
                                todayYield: uploadedData[7],
                                todayLowPrice: uploadedData[8],
                                todayFairPrice: uploadedData[9],
                                todayHighPrice: uploadedData[10],
                                change: uploadedData[11],
                                yesterdayYield: uploadedData[12],
                                yesterdayPrice: uploadedData[13],
                                lastWeekYield: uploadedData[14],
                                lastWeekPrice: uploadedData[15],
                                lastMonthYield: uploadedData[16],
                                lastMonthPrice: uploadedData[17],
                            })
                            continue
                        }
                        successData.push({
                            date: yyyymmddToDate(uploadedData[0]),
                            series: uploadedData[1],
                            isinCode: uploadedData[2],
                            bondName: uploadedData[3],
                            couponRate: uploadedData[4],
                            maturityDate: yyyymmddToDate(uploadedData[5]),
                            ttm: uploadedData[6],
                            todayYield: uploadedData[7],
                            todayLowPrice: uploadedData[8],
                            todayFairPrice: uploadedData[9],
                            todayHighPrice: uploadedData[10],
                            change: uploadedData[11],
                            yesterdayYield: uploadedData[12],
                            yesterdayPrice: uploadedData[13],
                            lastWeekYield: uploadedData[14],
                            lastWeekPrice: uploadedData[15],
                            lastMonthYield: uploadedData[16],
                            lastMonthPrice: uploadedData[17],
                        })
                    }
                }
                if (errorData.length > 0) {
                    let type = "error";
                    let text = "Ada beberapa data unggahan yang gagal diunggah, harap periksa tab data unggahan yang gagal";
                    notify(
                        {
                            message: text,
                            width: "AUTO",
                            shading: true,
                            position: { at: "center", my: "center", of: window }
                        },
                        type,
                        1000
                    );
                }
                this.successData = successData
                this.errorData = errorData
                this.forceRefresh()
                // this.editDataGrid(this.successData, 'success')
                // this.editDataGrid(this.errorData, 'error')
            }
            reader.readAsArrayBuffer(files);
        }
    }

    editDataGrid = async (data, type) => {
        if (type == 'success') {
            var dataGrid = this.dataGridSesuaiRef
        } else {
            var dataGrid = this.dataGridTakSesuaiRef
        }
        await this.deleteDataGrid(dataGrid)
        for (var value of data) {
            try {
                var key = value.id
                if (key) {
                    dataGrid.current.push([
                        {
                            type: 'update',
                            data: value,
                            key: key
                        }
                    ])
                } else {
                    dataGrid.current.push([
                        {
                            type: 'insert',
                            data: value
                        }
                    ])
                }

                // let type = 'success'
                // let text = 'Campaign Updates has changed successfully!'

                // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
            } catch (e) {
                console.log(e)
            }
        }
    }

    deleteDataGrid = (dataGrid) => {
        var data = dataGrid.current.getDataSource()

        for (var value of data) {
            dataGrid.current.push([
                {
                    type: 'remove',
                    key: value.id
                }
            ])
        }
    }

    forceRefresh = () => {
        this.dataGridSesuaiRef.current.forceRefresh(true)
        this.dataGridTakSesuaiRef.current.forceRefresh(true)
    }
    submitData = async () => {
        var dataSesuai = this.successData
        var dataTakSesuai = this.errorData
        if (dataTakSesuai.length > 0) {
            var message = 'Ada data yang tidak sesuai, apakah ingin tetap lanjut?'
        } else {
            var message = 'Apakah anda yakin ingin mengunggah data ini?'
        }
        let result = await confirm(message, "Konfirmasi")
        if (result) {
            this.props.store.dispatch(showLoading(true))
            try {
                for (var value of dataSesuai) {
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-performances/upload-harga-obligasi`, 'POST', {
                        values: value
                    })
                }
                notify({ message: 'Sukses!', width: 'AUTO', position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.props.forceRefresh()
                this.hide()
            } catch (e) {
                console.log(e)
                this.props.store.dispatch(showLoading(false))
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
            this.props.store.dispatch(showLoading(false))
        }
        // confirmAlert({
        //     title: 'Konfirmasi',
        //     message: message,
        //     buttons: [
        //         {
        //             label: 'Ya',
        //             onClick: async (e) => {

        //             }
        //         },
        //         {
        //             label: 'Tidak'
        //         }
        //     ]
        // })
    }
    show = async () => {
        this.setState({
            popupVisible: true
        });
        this.props.store.dispatch(showLoading(true))
        this.getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'instruments?categorySubId.in=25&categorySubId.in=52&categorySubId.in=42&categorySubId.in=29&categorySubId.in=31&categorySubId.in=22&categorySubId.in=26&categorySubId.in=53&categorySubId.in=30&categorySubId.in=23&categorySubId.in=24&categorySubId.in=32&categorySubId.in=28&categorySubId.in=27&categorySubId.in=33&size=99999', 'GET')
        this.props.store.dispatch(showLoading(false))
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };
    onTabSelectionChanged = (args) => {
        document.getElementById(`tabuploadindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabuploadindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns];
        columns.unshift({
            type: 'buttons',
            buttons: [
                {
                    text: "Error Detail",
                    hint: "Error Detail",
                    icon: "warning",
                    cssClass: "text-danger",
                    onClick: e => {
                        console.log(e)
                        let type = "error";
                        let text = e.row.data.errorMessage;

                        notify(
                            {
                                message: text,
                                width: "AUTO",
                                shading: true,
                                position: { at: "center", my: "center", of: window }
                            },
                            type,
                            2000
                        );
                    }
                }
            ]
        });

        return columns;
    }

    onValueChanged = (e) => {
        this.setState({
            useHeader: e.value
        })
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Upload Harga Penutupan"}
                width={"90vw"}
                height={750}
                toolbarItems={this.PopupToolbarItem}
            >
                <div className="d-flex flex-row">
                    <div style={{ width: "350px" }}>
                        <FileUploader
                            accept={".txt,.xls,.xlsx,.csv"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload File Harga Penutupan Disini"
                            showFileList={false}
                            name={"uploadKeluarga"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                        <CheckBox
                            value={this.state.useHeader}
                            onValueChanged={this.onValueChanged}
                        />
                        <span className="ml-2">Use Header</span>
                    </div>
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabuploadindex-0" className={' mt-2 col-md-12 text-center'} >
                    <DevExpressDataGrid
                        ref={this.dataGridSesuaiRef}
                        keyField={'isinCode'}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI=''

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource={true}
                        ArraySourceData={this.retrieveDataSuccess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Sukses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Sukses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height={'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabuploadindex-1" className={'d-none mt-2 col-md-12 text-center'} >
                    <DevExpressDataGrid
                        ref={this.dataGridTakSesuaiRef}
                        keyField={'isinCode'}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI=''

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource={true}
                        ArraySourceData={this.retrieveDataFailed}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Gagal"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Gagal'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.errorDataGridErrorButtonColumn()} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height={'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
            </Popup>
        )
    }
}


export default FormClosingPriceObligasi