import React, { Component } from 'react'
import { Popup, ScrollView, Form, FileUploader } from 'devextreme-react'
import Box, { Item } from 'devextreme-react/box';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import { pensiunTypeSdm, phkType, phkTypeSdm, programs } from 'dataSource/lookup';
import ArrayStore from 'devextreme/data/array_store';
import notify from "devextreme/ui/notify";
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class FormModalProgramDapenPengajuanPensiun extends Component {
    constructor(props){
        super(props)

        this.user = props.store.getState().sessionUser ? props.store.getState().getParam : null
        this.state = {
            DataDocument:{},
            popupVisible:false,
            DataMaster:{},
            fileUploadReceipt: []
        }

        this.getKaryawan = []
        this.modalPreviewPdfRef = React.createRef()
        this.form = [
            {
                dataField: 'programId',
                label: {
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                    onValueChanged: (e) => {
                        this.karyawanByProgram(e.value)
                    }
                }
            },
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Karyawan',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: async(e) => {
                        if(e.value){
                            var getKaryawanSks = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-sks/getAllByKaryawanId/${e.value}`, 'GET')
                            // getKaryawanSks = getKaryawanSks.filter(value => value.skTypeId == 1)
                            this.formRef.current.instance.getEditor('pensiunSkId').option('dataSource', getKaryawanSks)
                        }
                    }
                },
            },
            {
                dataField:'phkType',
                label:{
                    text:'Jenis Pemberhentian'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: phkTypeSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'phkType',
                    searchEnabled: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Jenis Pemberhentian Harap Diisi"
                    }
                ]
            },
            {
                dataField:'registDate',
                label:{
                    text:'Tanggal Registrasi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField:'efektifDate',
                label:{
                    text:'Tanggal Efektif'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField:'pesangonAmount',
                label:{
                    text:'Nominal Pesangon'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:'purnatugasAmount',
                label:{
                    text:'Nominal Purna Tugas'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:'lainnyaAmount',
                label:{
                    text:'Nominal Lainnya'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:'pesangonDate',
                label:{
                    text:'Tanggal Pembayaran Pesangon'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField:'pensiunSkId',
                label:{
                    text:'No SK Pensiun'
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'skNo',
                    onSelectionChanged: async(e) => {
                        console.log(e);
                        if(e.selectedItem){
                            this.setState(prevState => ({
                                ...prevState,
                                DataMaster: {
                                    ...prevState.DataMaster,
                                    dokumenFileSkName: e.selectedItem.fileName,
                                    dokumenFileSkContentType: e.selectedItem.fileContentType,
                                    dokumenFileSk: e.selectedItem.file
                                }
                            }))
                            this.formRef.current.instance.updateData({skDate:e.selectedItem.skDate})
                        }
                    }
                }
            },
            {
                dataField:'skDate',
                label:{
                    text:'Tanggal SK'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy',
                    readOnly: true
                }
            },
            {
                dataField:'pensiunType',
                label:{
                    text:'Jenis Pensiun'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: pensiunTypeSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'pensiunType',
                    searchEnabled: true,
                }
            },
            {
                dataField:'keterangan',
                label:{
                    text:'Keterangan Pengajuan Pensiun'
                },
                editorType:'dxTextArea'
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: () => {
                        const formValidation = this.validate();
                        if (formValidation) {
                            this.submitData();
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "simpan",
                    onClick: () => {
                        const formValidation = this.validate();
                        if (formValidation) {
                            this.updateData()
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
        this.newData = true
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this) 
        this.formRef = React.createRef()
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    previewFile = async(contentType, base64) => {
        var src = imageSource(contentType, base64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    onUploadStartedSk = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };
        // var DataDocument = { ...this.state.DataDocument };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}Name`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    onUploadStartedResign = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };
        // var DataDocument = { ...this.state.DataDocument };

        console.log(DataMaster);
        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}SkName`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}SkContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')
        var getKaryawanSks = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-sks`, 'GET');
        let dataArr = [];
        let data = [];

        for (let value of getKaryawanSks) {
            var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

            let data = getKaryawan.filter(val => val.id === value.karyawanId);
            dataArr.push(data);
        }

        for (let valueI of dataArr) {
            for (let valueJ of valueI) {
                data = data.concat(valueJ);
            }
        }

        var uniqueIds = new Set();

        var filteredData = data.filter(val => val.programId == programId)
        var filteredArray = filteredData.filter(item => {
            if (!uniqueIds.has(item.id)) {
                uniqueIds.add(item.id);
                return true;
            }
            return false;
        })
        console.log(filteredArray);
        let dataSource = new ArrayStore({
            data: filteredArray,
            key: 'id'
        })
        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)

        // kodingan lama
        // var filteredData = getKaryawan.filter(val => val.programId == programId)
        // let dataSource = new ArrayStore({
        //     data: filteredData,
        //     key: 'id'
        // })
        // this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }

    retrieveData = async (data) => {
        this.newData = false
        var DataMaster = {...this.state.DataMaster}
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-pensiuns/${data.id}`, 'GET')
        var getKaryawanSks = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-sks/getAllByKaryawanId/${response.karyawanId}`, 'GET')
        DataMaster.id = response.id
        DataMaster.programId = response.programId
        DataMaster.karyawanId = response.karyawanId
        DataMaster.phkType = response.phkTypeId
        DataMaster.registDate = response.tanggalPengajuan
        DataMaster.efektifDate = response.tanggalEfektif
        DataMaster.pesangonAmount = response.pesangonNominal
        DataMaster.purnatugasAmount = response.nominalPurnatugas
        DataMaster.lainnyaAmount = response.nominalLainnya
        DataMaster.pesangonDate = response.pesangonPaydate
        DataMaster.pensiunSkId = getKaryawanSks.length === 0 ? null : getKaryawanSks.find(value => value.id === response.pensiunSkId).id
        DataMaster.skDate = getKaryawanSks.length === 0 ? null : getKaryawanSks.find(value => value.id === response.pensiunSkId).skDate
        DataMaster.pensiunType = response.pensiunTypeId
        DataMaster.keterangan = response.keterangan
        DataMaster.dokumenFileSk = response.fileSkPensiun
        DataMaster.dokumenFileSkContentType = response.fileSkPensiunContentType
        DataMaster.dokumenFileSkName = response.dokumenFileSkName
        DataMaster.dokumenResign = response.fileResign
        DataMaster.dokumenResignSkContentType = response.fileResignContentType
        DataMaster.dokumenResignSkName = response.dokumenResignSkName

        this.setState({
            DataMaster:DataMaster
        })
    }

    submitData = async () => {
        try {
            var loadApi = 'regist-karyawan-pensiun'
            var DataMaster = {...this.state.DataMaster}
            var getKaryawanSks = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-sks/getAllByKaryawanId/${DataMaster.karyawanId}`, 'GET')
            // DataMaster.efektifDate = formatDate(DataMaster.efektifDate)
            // DataMaster.pesangonDate = formatDate(DataMaster.pesangonDate)
            // DataMaster.registDate = formatDate(DataMaster.registDate)
            // DataMaster.skDate = formatDate(DataMaster.skDate)
            // DataMaster.statusId = 1
            let dataValue = {
                "dokumenFileSk": DataMaster.dokumenFileSk,
                "dokumenFileSkContentType": DataMaster.dokumenFileSkContentType,
                "dokumenFileSkName": DataMaster.dokumenFileSkName,
                "dokumenResign": DataMaster.dokumenResign,
                "dokumenResignSkContentType": DataMaster.dokumenResignSkContentType,
                "dokumenResignSkName": DataMaster.dokumenResignSkName,
                "efektifDate": formatDate(DataMaster.efektifDate),
                "karyawanId": DataMaster.karyawanId,
                "keterangan": DataMaster.keterangan,
                "lainnyaAmount": DataMaster.lainnyaAmount,
                "pensiunTypeId": DataMaster.pensiunType,
                // "pensiunSkId": getKaryawanSks.length === 0 ? null : getKaryawanSks.find(value => value.id === DataMaster.pensiunSkId).id,
                "pensiunSkId": DataMaster.pensiunSkId,
                "pesangonAmount": DataMaster.pesangonAmount,
                "pesangonDate": formatDate(DataMaster.pesangonDate),
                "phkTypeId": DataMaster.phkType,
                "programId": DataMaster.programId,
                "purnatugasAmount": DataMaster.purnatugasAmount,
                "registDate": formatDate(DataMaster.registDate),
                "skDate": formatDate(DataMaster.skDate),
                "statusId": 1
            }

            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: dataValue,
            },dataValue)

            if (response) {
                notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.hide()
                this.props.forceRefresh()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }
    
    updateData = async() => {
        try {
            var loadApi = 'karyawan-pensiuns'
            var DataMaster = {...this.state.DataMaster}
            // DataMaster.id = DataMaster.id
            // DataMaster.pesangonNominal = DataMaster.pesangonAmount
            // DataMaster.nominalPurnatugas = DataMaster.purnatugasAmount
            // DataMaster.nominalLainnya = DataMaster.lainnyaAmount
            // DataMaster.fileSkPensiun = DataMaster.dokumenFileSk
            // DataMaster.fileSkPensiunContentType = DataMaster.dokumenFileSkContentType
            // DataMaster.dokumenFileSkName = DataMaster.dokumenFileSkName
            // DataMaster.fileResign = DataMaster.dokumenResign
            // DataMaster.fileResignContentType = DataMaster.dokumenResignSkContentType
            // DataMaster.dokumenResignSkName = DataMaster.dokumenResignSkName

            // DataMaster.tanggalEfektif = formatDate(DataMaster.efektifDate)
            // DataMaster.pesangonPaydate = formatDate(DataMaster.pesangonDate)
            // DataMaster.tanggalPengajuan = formatDate(DataMaster.registDate)
            // DataMaster.skDate = formatDate(DataMaster.skDate)
            let dataValue = {
                "id": DataMaster.id,
                "fileSkPensiun": DataMaster.dokumenFileSk,
                "fileSkPensiunContentType": DataMaster.dokumenFileSkContentType,
                "dokumenFileSkName": DataMaster.dokumenFileSkName,
                "fileResign": DataMaster.dokumenResign,
                "fileResignContentType": DataMaster.dokumenResignSkContentType,
                "dokumenResignSkName": DataMaster.dokumenResignSkName,
                "tanggalEfektif": formatDate(DataMaster.efektifDate),
                "karyawanId": DataMaster.karyawanId,
                "keterangan": DataMaster.keterangan,
                "nominalLainnya": DataMaster.lainnyaAmount,
                "pensiunTypeId": DataMaster.pensiunType,
                // "pensiunSkId": this.getKaryawan.length === 0 ? null : this.getKaryawan[0].id,
                "pensiunSkId": DataMaster.pensiunSkId,
                "pesangonNominal": DataMaster.pesangonAmount,
                "pesangonPaydate": formatDate(DataMaster.pesangonDate),
                "phkTypeId": DataMaster.phkType,
                "programId": DataMaster.programId,
                "nominalPurnatugas": DataMaster.purnatugasAmount,
                "tanggalPengajuan": formatDate(DataMaster.registDate),
                "skDate": formatDate(DataMaster.skDate),
                "statusId": 1
            }
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'PUT', {
                values: dataValue,
                key:dataValue.id
            },dataValue)

            if (response) {
                notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.hide()
                this.props.forceRefresh()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.newData = true
        this.setState({
            popupVisible: false,
            DataMaster: {},
            fileUploadReceipt: []
        })
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Edit Pengajuan Pensiun"}
                toolbarItems={this.props.action == 'add' ? this.PopupToolbarItem : this.props.action == 'edit' ? this.PopupToolbarItem2 : []}
                width={800}
                height={550}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                        ref={this.formRef}
                            colCount={1}
                            id={'formDetailIuran'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation = 'left'
                            readOnly={this.props.action == 'lihat' ? true : false}
                        />
                        <Box direction={"row"} width={"100%"}>
                            <Item ratio={1}>
                                {/* <FileUploader
                                    accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStartedSk.bind(this)} 
                                    labelText="File SK Pensiun"
                                    showFileList={false}
                                    name={'dokumenFileSk'}
                                    value = {this.state.fileUploadReceipt}
                                    disabled={this.props.action == 'lihat' ? true : false }
                                    ref={this.fileUploderRef}
                                /> */}
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="row">
                                    <label className="col-4">File Name:</label>
                                    <label className="col-8">{ this.state.DataMaster.dokumenFileSkName}</label>
                                </div>
                                <button onClick={() => this.previewFile(this.state.DataMaster.dokumenFileSkContentType, this.state.DataMaster.dokumenFileSk)} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.dokumenFileSk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                            </Item>
                            <Item ratio={1}>
                                <FileUploader
                                    accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStartedResign.bind(this)} 
                                    labelText="File Surat Pengunduran Diri"
                                    showFileList={false}
                                    name={'dokumenResign'}
                                    value = {this.state.fileUploadReceipt}
                                    disabled={this.props.action == 'lihat' ? true : false }
                                    ref={this.fileUploderRef}
                                />
                                <div className="row">
                                    <label className="col-4">File Name:</label>
                                    <label className="col-8">{ this.state.DataMaster.dokumenResignSkName}</label>
                                </div>
                                <button onClick={() => this.previewFile(this.state.DataMaster.dokumenResignSkContentType, this.state.DataMaster.dokumenResign)} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.dokumenResign ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>                               
                            </Item>
                        </Box>
                    </ScrollView>
                    <ModalPreviewPdf 
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                </Popup>
        )
    }
}

export default FormModalProgramDapenPengajuanPensiun