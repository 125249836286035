import React, { Component } from 'react'
import { Form } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { instruments, kpds, managerInvestasis, product, productDefault } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import FormAddDevidenEtf from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/pendapatan-deviden-etf/viewDevidenEtf';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import notify from 'devextreme/ui/notify'
import { formatDate } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog'

class PendapatanDevidenEtf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {
                productId: 1
            },
        }

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        visible: false,
                        onClick: async (e) => {
                            var data = e.row.data
                            if(data.statusId === 2 || data.statusId === 3) {
                                confirmAlert({
                                    title: data.statusId === 2 ? 'Sudah terkirim!' : 'Sudah Dibatalkan!',
                                    message: 'Data tidak bisa di ubah',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.showModalAddDeviden('edit', data)
                            }
                        },
                    },
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        visible: false,
                        onClick: async (e) => {
                            var data = e.row.data
                            if(data.statusId === 1) {
                                await alert('Fitur ini hanya untuk status Sent & Batal', 'Informasi')
                            } else {
                                this.showModalLihatDeviden('lihat', data)
                            }
                        },
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.statusId == 1) {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.kirimDeviden(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak',
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: data.statusId === 2 ? 'Sudah terkirim!' : 'Sudah Dibatalkan!',
                                    message: 'Data tidak bisa dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: async(e) => {
                            var data = e.row.data
                            if(data.statusId === 2 || data.statusId === 3) {
                                confirmAlert({
                                    title: data.statusId === 2 ? 'Sudah terkirim!' : 'Sudah Dibatalkan!',
                                    message: 'Data tidak bisa dibatalkan',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cancelConfirmation(e.row.data)
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'No Konfirmasi'
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'kpdId',
                caption: 'KPD',
                lookup: {
                    dataSource: kpds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'noKontrak'
                }
            },
            {
                dataField: 'miId',
                caption: 'Manager Investasi',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'instrumentId',
                caption: 'Instrument',
                lookup: {
                    dataSource: instruments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'entryDate',
                caption: 'Tanggal Entry',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'recordingDate',
                caption: 'Tanggal Recording',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'distributionDate',
                caption: 'Tanggal Distribusi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'proceedQuantity',
                caption: 'Quantity Recording',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.0000',
            },
            {
                dataField: 'devidenAmountPerStock',
                caption: 'Deviden / Lembar',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.000000',
            },
            {
                dataField: 'proceedAmount',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'proceedTax',
                caption: 'Pajak',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'proceedNetAmount',
                caption: 'Net Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'OPEN'
                        },
                        {
                            id: 2,
                            value: 'SENT'
                        },
                        {
                            id: 3,
                            value: 'BATAL'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'productId',
                        colSpan: 1,
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.productCode + " - " + item.productName;
                            },
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'startDistributionDate',
                                colSpan: 1,
                                label: {
                                    text: 'Tanggal Distribusi'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'endDistributionDate',
                                colSpan: 1,
                                label: {
                                    text: 'S/D'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                        ]
                    },
                    {
                        dataField: 'statusId',
                        colSpan: 1,
                        label: {
                            text: 'Status'
                        },
                        editorType: 'dxTagBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'OPEN'
                                },
                                {
                                    id: 2,
                                    value: 'SENT'
                                },
                                {
                                    id: 3,
                                    value: 'BATAL'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.filterData()
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]

        this.dataGrid = React.createRef()
        this.showModalAddDevidenRef = React.createRef()
        this.showModalLihatDevidenRef = React.createRef()
        this.dataValue = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Deviden ETF',
                    onClick: (e) => {
                        this.showModalAddDeviden('add', {})
                    },
                }
            },
        )
    }

    showModalAddDeviden = async(type, data) => {
        if (type == 'add') {
            data.noKonfirmasi = 'ETFDVDN-' + Math.floor(Math.random() * 100000)
        }
        if (type == 'edit') {
            var dataBankTrs = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `bank-transactions?reffId.in=${data.id}`,
                'GET'
            )
            if(dataBankTrs.length !== 0){
                data.rekeningBankPenerima = dataBankTrs[0].receiveRekBankId
            }else{
                data.rekeningBankPenerima = null
            }
        }
        this.showModalAddDevidenRef.current.show(data, type)
    }
    showModalLihatDeviden = async(type, data) => {
        var dataBankTrs = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `bank-transactions?reffId.in=${data.id}`,
            'GET'
        )
        if(dataBankTrs.length !== 0){
            data.rekeningBankPenerima = dataBankTrs[0].receiveRekBankId
        }else{
            data.rekeningBankPenerima = null
        }
        
        this.showModalLihatDevidenRef.current.show(data, type)
    }
    kirimDeviden = async (data) => {
        var dataBankTrs = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `bank-transactions?reffId.in=${data.id}`,
            'GET'
        )
        if (dataBankTrs) {
            var submit = {
                referenceId: dataBankTrs[0].id,
                devidenEtfId: data.id,
            }
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `deviden-etfs/kirimDevidenEtf`,
                    'POST', {
                    values: submit
                }
                )
                if (response) {
                    // this.forceRefresh()
                    this.filterData()
                    notify({ message: 'Sukses Kirim Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } else {
            notify({ message: 'Gagal Menemukan Data Transaksi!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    forceRefresh = () => {
        this.dataGrid.current.forceRefresh(true)
    }

    filterData = async() => {
        let filter = this.state.filterData
        let loadAPI = `deviden-etfs/getByProductId/${filter.productId}?`

        if(filter.startDistributionDate) {
            loadAPI = loadAPI + `startDistributionDate=${formatDate(filter.startDistributionDate)}&`
        } 

        if(filter.endDistributionDate) {
            loadAPI = loadAPI + `endDistributionDate=${formatDate(filter.endDistributionDate)}&`
        } 
        
        if(filter.statusId) {
            loadAPI = loadAPI + `status=${filter.statusId}&`
        }

        let data = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadAPI.slice(0, -1),
            'GET'
        )

        var dataFilter = []

        for(let value of data) {
            let dataValue = {
                ...value,
                // statusId: value.statusId === 1 ? 'OPEN' : value.statusId === 2 ? 'SENT' : 'BATAL'
            }

            dataFilter.push(dataValue)
        }

        this.dataValue = dataFilter
        this.forceRefresh()
    }

    loadDataFilter = async () => {
        return this.dataValue
    }

    cancelConfirmation = async (data) => {
        try {
            let result = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `deviden-etfs/${data.id}`)
            let editedData = { ...result }
            editedData.statusId = 3

            let postEditedData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `deviden-etfs`, "PUT", {
                values: editedData,
                key: editedData.id
            }, editedData)

            if (postEditedData) {
                this.filterData();
                notify({ message: 'Berhasil di batalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }

        } catch (error) {
            console.log(error)
            notify({ message: 'Terjadi Kesalahan System', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Transaksi Deviden ETF</h2>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            colCount={2}
                            id={'formMaster'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={"left"}
                        />
                        <DevExpressDataGrid
                            ref={this.dataGrid}
                            loadAPI=''
                            insertAPI='kpds'
                            updateAPI='kpds'
                            deleteAPI='kpds'

                            useArraySource={true}
                            ArraySourceData={this.loadDataFilter}

                            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={false}
                            exportFileName={"Konfirmasi"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}
                            height={'calc(100vh - 340px)'}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Instrument'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            // SummaryConfiguration={this.summary}

                            onToolbarPreparing={this.onToolbarPreparing}
                            // onSelectionChanged = {this.onSelectionChanged}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                        <FormAddDevidenEtf
                            ref={this.showModalAddDevidenRef}
                            store={this.props.store}
                            forceRefresh={this.forceRefresh}
                            filterData={this.filterData}
                            action={'add'}
                        />
                        <FormAddDevidenEtf
                            ref={this.showModalLihatDevidenRef}
                            store={this.props.store}
                            forceRefresh={this.forceRefresh}
                            action={'lihat'}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PendapatanDevidenEtf