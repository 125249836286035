import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest'
import { managerInvestasis, custodies, emitens, currency, instrumentTypes, instrumentsCategoryEtf, instrumentsCategoryObli, ratings, instrumentCategories, frekuensis } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';

class FormModalInstruObligasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            newData: false,
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.updateData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.DataMaster = [
            {
                itemType: "group",
                items: [

                    {
                        dataField: 'instrumentCode',
                        label: {
                            text: 'Kode Obligasi'
                        }
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            text: 'Nama Obligasi'
                        }
                    },
                    {
                        dataField: 'isinCode',
                        label: {
                            text: 'Isin Code'
                        }
                    },
                    {
                        dataField: 'categorySubId',
                        label: {
                            text: 'Sub Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentsCategoryObli(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    var dataSub = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-category-subs/${e.value}`)
                                    this.formRef.current.instance.getEditor('categoryId').option('value', dataSub.instrumentCategoryId)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'categoryId',
                        label: {
                            text: 'Kategori'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentCategories(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'status',
                        label: {
                            text: 'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Active'
                                },
                                {
                                    id: 2,
                                    value: 'Delisting'
                                },
                                {
                                    id: 3,
                                    value: 'Jatuh Tempo'
                                },
                                {
                                    id: 4,
                                    value: 'Suspend'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'emitenId',
                        label: {
                            text: 'Emiten'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: emitens(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'emitenName', // provides display values
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'custodyId',
                        label: {
                            text: 'Custody'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'custodyName', // provides display values
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Currency'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'currencyCode', // provides display values
                            searchEnabled: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            text: 'Nominal (Face Value)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0
                        }
                    },
                    {
                        dataField: 'syariah',
                        label: {
                            text: 'Syariah'
                        },
                        editorType: 'dxCheckBox'
                    },
                ]
            },
            {
                itemType: "group",
                items: [
                    {
                        itemType: "group",
                        name: "kupon",
                        caption: "Kupon",
                        items: [

                            {
                                dataField: 'maturityDate',
                                label: {
                                    text: 'Tanggal Jatuh Tempo'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'rateTypeId',
                                label: {
                                    text: 'Jenis Kupon'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            value: 'FIXED_RATE',
                                            displayExpr: 'FIXED',
                                        },
                                        {
                                            value: 'FLOATING_RATE',
                                            displayExpr: 'FLOATING'
                                        },
                                    ],
                                    valueExpr: 'value',
                                    displayExpr: 'value',
                                }
                            },
                            {
                                dataField: 'rate',
                                label: {
                                    text: 'Suka Bunga'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.000000'
                                }
                            },
                            {
                                dataField: 'couponPeriod',
                                label: {
                                    text: 'Frekuensi / Tahun'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: frekuensis(),
                                    valueExpr: 'value',
                                    displayExpr: 'value',
                                    openOnFieldClick: true,
                                    searchEnabled: true
                                },
                            },
                            {
                                dataField: 'dayBase',
                                label: {
                                    text: 'Basis'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: 'BASE_30_360',
                                            value: '30/360'
                                        },
                                        {
                                            id: 'BASE_ACT_360',
                                            value: 'act/360'
                                        },
                                        {
                                            id: 'BASE_ACT_ACT',
                                            value: 'act/act'
                                        },
                                        {
                                            id: 'BASE_30_365',
                                            value: '30/365'
                                        },
                                        {
                                            id: 'BASE_ACT_365',
                                            value: 'act/365'
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    openOnFieldClick: true,
                                    searchEnabled: true
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please choose day base'
                                    }
                                ]
                            },
                            {
                                dataField: 'couponLastDate',
                                label: {
                                    text: 'Tanggal Terakhir'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'couponNextDate',
                                label: {
                                    text: 'Tanggal Berikutnya'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: "group",
                        name: "rating",
                        caption: "Rating",
                        items: [
                            {
                                dataField: 'rating1Id',
                                label: {
                                    text: 'Rating Awal'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: ratings(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: (e) => {
                                        return e && e.ratingName + ' (' + e.ratingCode + ')'
                                    },
                                    searchEnabled: true,
                                    openOnFieldClick: true,
                                }

                            },
                            {
                                dataField: 'rating2Id',
                                label: {
                                    text: 'Rating Akhir'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: ratings(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: (e) => {
                                        return e && e.ratingName + ' (' + e.ratingCode + ')'
                                    },
                                    searchEnabled: true,
                                    openOnFieldClick: true,
                                }
                            },
                        ],
                    }
                ]
            }
        ]
    }

    newData = (newData) => {
        this.setState({
            newData: newData
        })
    }

    retrieveData = (data) => {
        this.setState({
            DataMaster: data,
        })
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate()
        return formValidation.isValid
    }
    submitData = async () => {
        var data = this.state.DataMaster
        if (data) {
            try {
                let type = 'success'
                let text = 'Sukses input !'
                var insertAPI = `staticdata-wf/instruments`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: data,
                })
                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    updateData = async () => {
        try {
            let type = 'success'
            let text = 'Sukses ubah !'
            var updateAPI = `staticdata-wf/instruments`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                values: this.state.DataMaster,
                key: this.state.DataMaster.id
            }, this.state.DataMaster)

            if (response) {
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.hide()
            } else {
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
            newData: false,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'55vw'}
                    height="50vh"
                    title={`Form Obligasi`}
                    ref={this.PopupRef}
                    toolbarItems={this.state.newData ? this.popupToolbarItem2 : this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={2}
                            ref={this.formRef}
                            id={'DataMaster'}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation={"left"}
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalInstruObligasi