import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { FileUploader, ScrollView } from 'devextreme-react';
import ModalPreviewPdf from '../../compPeserta/previewPdf';
import ModalPreviewImage from '../../compPeserta/previewImage';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';
import { banks, klaimType, media, months, tipePembayaranMp } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';

class KlaimDetailBaru extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {},
      dataMaster: {}
    }

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)

    this.PopupRef = React.createRef()
    this.modalPreviewPdfRef = React.createRef()
    this.modalPreviewImageRef = React.createRef()

    this.popupToolbarItem = [
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Proses',
          onClick: () => {
            var data = this.state.dataMaster
            delete data.detail
            this.props.showModalCatatan(data, 2)
          }
        },
        toolbar: 'bottom',
        visible : this.props.module == 'proses' ? false : true
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Tolak',
          onClick: () => {
            var data = this.state.dataMaster
            delete data.detail
            this.props.showModalCatatan(data, 5)
          },
        },
        toolbar: 'bottom',
        visible : this.props.module == 'proses' ? false : true
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Tutup',
          onClick: this.hide.bind(this)
        },
        toolbar: 'bottom'
      }
    ]

    this.formItem = [
      {
        itemType: 'group',
        items: [
          {
            dataField: 'id',
            label: {
              alignment: 'left',
              text: 'No.'
            },
          },
          {
            dataField: 'klaimDate',
            label: {
              alignment: 'left',
              text: 'Tanggal'
            },
            editorType: 'dxDateBox',
            editorOptions: {
              useMaskBehavior: true,
              width: '100%',
              displayFormat: 'dd MMM yyyy'
            }
          },
          {
            dataField: 'mediaId',
            label: {
              alignment: 'left',
              text: 'Media'
            },
            editorType: "dxSelectBox",
            editorOptions: {
              dataSource: media(this.props.store),
              displayExpr: 'mediaName',
              valueExpr: 'id',
              searchEnabled: true,
              deferRendering: false,
            }
          },
          {
            dataField: 'fullName',
            label: {
              alignment: 'left',
              text: 'Peserta'
            },
          },
          {
            dataField: 'klaimTypeId',
            label: {
              alignment: 'left',
              text: 'Jenis Klaim'
            },
            editorType: "dxSelectBox",
            editorOptions: {
              dataSource: klaimType(this.props.store),
              displayExpr: 'klaimTypeName',
              valueExpr: 'id',
              searchEnabled: true,
              deferRendering: false,
            }
          },
          {
            dataField: 'keterangan',
            label: {
              alignment: 'left',
              text: 'Catatan Peserta'
            },
          },

          {
            itemType: 'group',
            caption: 'Klaim Detail',
            cssClass: 'mt-5',
            items: [
              {
                dataField: 'detail.mpPaymentTypeId',
                label: {
                  alignment: 'left',
                  text: 'Tipe Pembayaran MP'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                  dataSource: tipePembayaranMp(this.props.store),
                  displayExpr: 'typeName',
                  valueExpr: 'id',
                  searchEnabled: true,
                  deferRendering: false,
                }
              },
              {
                dataField: 'detail.mpRekeningNo',
                label: {
                  alignment: 'left',
                  text: 'No Rekening Pembayaran MP'
                },
              },
              {
                dataField: 'detail.mpRekeningName',
                label: {
                  alignment: 'left',
                  text: 'Nama Rekening Pembayaran MP'
                },
              },
              {
                dataField: 'detail.mpRekeningBankId',
                label: {
                  alignment: 'left',
                  text: 'Bank Pembayaran MP'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                  dataSource: banks(this.props.store),
                  valueExpr: 'id',
                  displayExpr: 'bankName',
                  searchEnabled: true,
                  deferRendering: false,
                }
              },
              {
                dataField: 'detail.mpRekeningCabang',
                label: {
                  alignment: 'left',
                  text: 'Cabang Pembayaran MP'
                },
              },
              {
                dataField: 'detail.mpBulan',
                label: {
                  alignment: 'left',
                  text: 'Periode Bulan Pembayaran'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                  dataSource: months(),
                  valueExpr: 'id',
                  displayExpr: 'value',
                  searchEnabled: true,
                  deferRendering: false,
                }
              },
              {
                dataField: 'detail.mpTahun',
                label: {
                  alignment: 'left',
                  text: 'Periode Tahun Pembayaran'
                },
              },

            ]
          }
        ]
      }

    ]

  }

  get Popup() {
    return this.PopupRef.current.instance
  }

  show() {
    this.setState({
      popupVisible: true,
      selectedTabIndex: 0
    });
  }

  hide() {
    this.setState({
      popupVisible: false,
      formData: {},
      dataMaster: {}
    });
  }

  onValueChanged = (e) => {
    var fieldName = e.component._options._optionManager._options.name
    var fieldValue = e.value

    this.setState({
      [fieldName]: fieldValue
    })
  }

  submitData = async () => {
    var dataSubmit = { ...this.state.dataPeserta }

    // console.log(dataSubmit)
    await this.props.submitData(dataSubmit)
    this.hide()
  }

  retrieveData = async (data) => {
    var loadAPI = 'peserta-klaim-detils/findByPesertaKlaimId/'+data.id
    var getDetail = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')

    this.setState({
      dataMaster: {
        ...data,
        detail : getDetail
      },
    })
  }

  onUploadStarted = (e) => {
    var fieldName = e.component._options._optionManager._options.name
    for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i]
        
        var fr = new FileReader()

        var formattedFileData
        var formatNameAndExtention

        fr.onload = (efr) => {
            formattedFileData = formatUploadFileData(efr.target.result)
            formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
            
            this.setState(prevState =>({
                dataMaster: {
                    ...prevState.dataMaster,
                    [`${fieldName}Name`]: files.name,
                    // [`${fieldName}Extension`]: formatNameAndExtention.extention,
                    [`${fieldName}`]: formattedFileData.base64data,
                    [`${fieldName}ContentType`]: formattedFileData.mime,
                }
            }))

        }

        fr.readAsDataURL(files)
    }
}

  previewFile = async(fileName) => {
      var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
      var result = await alert(message, 'Informasi!')
      if(result){
          // var src = imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`])
          if(this.state.dataMaster[fileName]){
            var src = process.env.REACT_APP_BACKEND_CORE + this.state.dataMaster[fileName]
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src, 'url')
          }else{
            await alert('File ini kosong', 'Informasi')
          }
      }
  }

  downloadFile = async(fileName) => {
      // download(imageSource(this.state.dataMaster[`${fileName}ContentType`],this.state.dataMaster[`${fileName}`]),this.state.dataMaster[`${fileName}Name`])
      if(this.state.dataMaster[fileName]){
        download(process.env.REACT_APP_BACKEND_CORE + this.state.dataMaster[fileName])
      }else{
        await alert('File ini kosong', 'Informasi')
      }
  }

  render() {
    return (
      <Popup
        className={'popup'}
        visible={this.state.popupVisible}
        onHiding={this.hide}
        dragEnabled={true}
        resizeEnabled={true}
        showTitle={true}
        title={'Form Detail Klaim MP'}
        minWidth={500}
        minHeight={500}

        ref={this.PopupRef}

        toolbarItems={this.popupToolbarItem}
      >
        <ScrollView height="100%" width="100%">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Form
                  colCount={1}
                  id={'formData'}
                  formData={this.state.dataMaster}
                  items={this.formItem}
                  labelLocation="left"
                  readOnly = {true}
                />
              </div>
              <div className="col-md-12">
                <h5>Data Dokumen</h5>
              </div>

              {/* FILE BUKTI DIRI */}
              <div className="col-md-12 row">
                <div className="col-md-6">
                    <FileUploader 
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)} 
                        labelText={this.state.dataMaster.buktiDiriFileName  || 'File Bukti Diri Detail'}
                        showFileList={false}
                        readOnly = {true}
                        name={'buktiDiriFile'}
                    />
                </div>
                <div className="col-md-6 text-right">
                    <button onClick={() => this.previewFile('buktiDiriFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.buktiDiriFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Preview
                    </button>
                    <button onClick={() => this.downloadFile('buktiDiriFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.buktiDiriFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Download
                    </button>
                </div>
              </div>

              {/* FILE KTP */}
              <div className="col-md-12 row">
                <div className="col-md-6">
                    <FileUploader 
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)} 
                        labelText={this.state.dataMaster.fotoKtpFileName  || 'File KTP Detail'}
                        showFileList={false}
                        readOnly = {true}
                        name={'fotoKtpFile'}
                    />
                </div>
                <div className="col-md-6 text-right">
                    <button onClick={() => this.previewFile('fotoKtpFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fotoKtpFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Preview
                    </button>
                    <button onClick={() => this.downloadFile('fotoKtpFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fotoKtpFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Download
                    </button>
                </div>
              </div>

              {/* FILE SK PENSIUN */}
              <div className="col-md-12 row">
                <div className="col-md-6">
                    <FileUploader 
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)} 
                        labelText={this.state.dataMaster.fotoSkPensiunFileName  || 'File SK Pensiun Detail'}
                        showFileList={false}
                        readOnly = {true}
                        name={'fotoSkPensiunFile'}
                    />
                </div>
                <div className="col-md-6 text-right">
                    <button onClick={() => this.previewFile('fotoSkPensiunFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fotoSkPensiunFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Preview
                    </button>
                    <button onClick={() => this.downloadFile('fotoSkPensiunFile')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fotoSkPensiunFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Download
                    </button>
                </div>
              </div>

              {/* FILE KTP PENERIMA */}
              <div className="col-md-12 row">
                <div className="col-md-6">
                    <FileUploader 
                        uploadMode={'useForm'}
                        onValueChanged={this.onUploadStarted.bind(this)} 
                        labelText={this.state.dataMaster.fotoKtpPenerimaMpName  || 'File KTP Penerima Detail'}
                        showFileList={false}
                        readOnly = {true}
                        name={'fotoKtpPenerimaMp'}
                    />
                </div>
                <div className="col-md-6 text-right">
                    <button onClick={() => this.previewFile('fotoKtpPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fotoKtpPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Preview
                    </button>
                    <button onClick={() => this.downloadFile('fotoKtpPenerimaMp')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fotoKtpPenerimaMp ? '' : 'd-none'}`} style={{ width: '122px' }}>
                        Download
                    </button>
                </div>
              </div>
            </div>
          </div>
        </ScrollView>
        <ModalPreviewPdf
            ref={this.modalPreviewPdfRef}
            store={this.props.store}
        />
        <ModalPreviewImage
            ref={this.modalPreviewImageRef}
            store={this.props.store}
        />
      </Popup>
    )
  }
}

export default KlaimDetailBaru