import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { months, product } from 'dataSource/lookup';
import PembayaranMpFileSkModal from 'components/pages/modal/kepesertaan/pengkinianPeserta/berkala/fileSk';
import ModalEditMpBerkala from 'components/pages/modal/kepesertaan/pengkinianPeserta/berkala/edit';
import httpRequest from 'plugin/httprequest';

class RiwayatKenaikanBerkalaMp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterItem: {}
        }

        this.modalEditMpBerkalaRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalFileSKRef = React.createRef()
        
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    // {
                    //     text: 'File SK',
                    //     hint: 'File SK',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.showModalFileSK(e.row.data)
                    //     }
                    // }
                ],
            },
            {
                dataField: 'id',
                caption: 'ID'
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'pengajuanDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'periodeBulan',
                caption: 'Bulan Efektif',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'periodeTahun',
                caption: 'Tahun Efektif'
            },
            {
                dataField: 'prosesDate',
                caption: 'Tanggal Proses',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'center'
            },
            {
                dataField: 'persenKenaikan',
                caption: 'Persen Kenaikkan',
                alignment: 'right',
                cellRender: (e) => {
                    let persenKenaikan = e.row.data.persenKenaikan;
                    let intValue = parseInt(persenKenaikan);
            
                    // Format the number as a percentage
                    let formattedPercentage = (intValue / 100).toLocaleString('en-US', { style: 'percent', minimumFractionDigits: 2 });
            
                    return formattedPercentage;
                }
            },
            {
                dataField: 'skNo',
                caption: 'No SK'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Open'
                        },
                        {
                            id: 2,
                            value: 'Efektif'
                        },
                        {
                            id: 3,
                            value: 'Batal'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
        this.filterItem = [
            {
                dataField: "tanggal1",
                label: {
                    text: "Dari",
                    alignment: "left",
                    location: "left"
                },
            },
            {
                dataField: "tanggal2",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalDetail = (data) => {
        this.modalEditMpBerkalaRef.current.show()
        this.modalEditMpBerkalaRef.current.retrieveData(data)
    }

    showModalFileSK = (data) => {
        this.modalFileSKRef.current.show()
        this.modalFileSKRef.current.retrieveData(data)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        let data = []
        let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kenaikan-berkala-mps`, 'GET');
    
        console.log(response);
        for (let value of response) {
            let dataValue = {
                "id": value.id,
                "pengajuanDate": value.pengajuanDate,
                "periodeBulan": value.periodeBulan,
                "periodeTahun": value.periodeTahun,
                "prosesDate": value.prosesDate,
                "persenKenaikan": value.persenKenaikan,
                "roundingup": value.roundingup,
                "roundingValue": value.roundingValue,
                "skNo": value.skNo,
                "statusId": value.statusId,
                "productId": value.productId
            }

            data.push(dataValue);
        }

        data = data.filter(value => value.statusId === 2 || value.statusId === 3);

        return data;
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Kenaikan MP Berkala</h2>
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI=''
                    // loadAPI='kenaikan-berkala-mps/getByStatus/2'
                    insertAPI='kenaikan-berkala-mps'
                    updateAPI='kenaikan-berkala-mps'
                    deleteAPI='kenaikan-berkala-mps'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Kenaikan Berkala Manfaaat Pensiun"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Kenaikan Berkala Manfaaat Pensiun Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <PembayaranMpFileSkModal
                    ref={this.modalFileSKRef}
                    store={this.props.store}
                    submitData = {this.updateData}
                    actionType = {'view'}
                />
                <ModalEditMpBerkala
                    ref={this.modalEditMpBerkalaRef}
                    store={this.props.store}
                    submitData = {this.insertData}
                    actionType = {'view'}
                />
            </div>
        )
    }
}

export default RiwayatKenaikanBerkalaMp