import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { mpPenerimaType, tipePembayaranMp } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import ModalRegistrasiUpdatePengalihanMp from 'components/pages/modal/kepesertaan/pengkinianPeserta/pengalihan/registrasi/index';
import { alert } from 'devextreme/ui/dialog';
import { pembulatan } from 'plugin/helper';

class PendingPengalihanMp extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.kirim(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                    // {
                    //     text: 'Batal',
                    //     hint: 'Batal',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         confirmAlert({
                    //             title: 'Konfirmasi',
                    //             message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                    //             buttons: [
                    //               {
                    //                 label: 'Iya',
                    //                 onClick: () => {
                    //                     this.kirim(e.row.data)
                    //                 }
                    //               },
                    //               {
                    //                 label: 'Tidak',
                    //                 onClick: () => {

                    //                 }
                    //               },
                    //             ]
                    //         });
                    //     }
                    // },
                ],
            },
            {
                dataField: 'id',
                caption: 'NO',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'tanggalPengalihan',
                caption: 'Tanggal Pengalihan',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta',
            },
            {
                dataField: 'lastMpPenerimaTypeId',
                caption: 'Tipe Penerima Sebelumnya',
                lookup: {
                    dataSource: mpPenerimaType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'penerimaName',
                }
            },
            {
                dataField: 'lastPenerimaName',
                caption: 'Nama Penerima Sebelumnya'
            },
            {
                dataField: 'newMpPenerimaTypeId',
                caption: 'Tipe Penerima Baru',
                lookup: {
                    dataSource: mpPenerimaType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'penerimaName',
                }
            },
            {
                dataField: 'newPenerimaName',
                caption: 'Nama Penerima Baru'
            },
            {
                dataField: 'newMpPenerimaTypeId',
                caption: 'Tipe Pembayaran Baru',
                lookup: {
                    dataSource: tipePembayaranMp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName',
                }
            },
            {
                dataField: 'keterangan',
                caption: 'Alasan Pengalihan'
            }
        ]
    }

    renderButton = (button) => {
        return <div style={{ padding: 20 }}><p>{button.text}</p></div>;
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalEdit = (dataPengalihan) => {
        this.modalRef.current.show()
        this.modalRef.current.retrieveData(dataPengalihan)
    }
    showModalInsert = () => {
        this.modalRef.current.show()
    }

    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'plus',
    //                 text: 'Pengalihan',
    //                 onClick: (e) => {
    //                     this.showModalInsert(e)
    //                 }
    //             }
    //         }
    //     )
    // }
    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
            }
        ]
        return dummyData
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    kirim = async(data) => {
        // EXECUTE
        var loadAPIPengalihan = 'pengalihan-mps'
        var getPengalihan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPengalihan + '/' + data.id, 'GET')

        var dataPengalihan = getPengalihan
        dataPengalihan.status = 3

        var loadAPIAkun = 'kepersertaan-products'
        var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun + '/' + dataPengalihan.kepersertaanProductId, 'GET')

        var dataAkun = getAkun
        dataAkun.mpBulananPeserta = pembulatan(dataPengalihan.newMpBulananPeserta,2) || 0
        dataAkun.mpBulananAnak = pembulatan(dataPengalihan.newMpBulananAnak,2) || 0
        dataAkun.mpBulananJadud = pembulatan(dataPengalihan.newMpBulananJadud,2) || 0

        dataAkun.mpSekaligusPeserta = pembulatan(dataPengalihan.newMpSekaligusPeserta, 2) || 0
        dataAkun.mpSekaligusAnak = pembulatan(dataPengalihan.newMpSekaligusAnak, 2) || 0
        dataAkun.mpSekaligusJadud = pembulatan(dataPengalihan.newMpSekaligusJadud, 2) || 0

        if(dataPengalihan.newMpPaymentTypeId === 1){
            dataAkun.mpBulanan = pembulatan(dataPengalihan.newMpBulanan * 80 / 100, 2) || 0
        }else{
            dataAkun.mpBulanan = pembulatan(dataPengalihan.newMpBulanan, 2) || 0
        }
        dataAkun.mpSekaligus = pembulatan(dataPengalihan.newMpSekaligus, 2) || 0
        dataAkun.mpBulanan80 = pembulatan(dataPengalihan.newMpBulanan80, 2) || 0
        dataAkun.mpSekaligus20 = pembulatan(dataPengalihan.newMpSekaligus20, 2) || 0

        // PENERIMA NAMA
        dataAkun.mpPenerimaPesertaFamilyTypeId = dataPengalihan.newFamilyId
        try {
            var postAPIPengalihanMP = 'pengalihan-mps'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPIPengalihanMP, 'PUT', {
                key: dataPengalihan.id,
                values: dataPengalihan
            },data)

            // await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'PUT', {
            //     key : dataAkun.id,
            //     values: dataAkun
            // }, getAkun)
            
            if(dataAkun.mpPaymentTypeId == 2){
                var loadAPIMpPembayaran = 'pembayaran-mp-sekaligus'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMpPembayaran, 'POST', {
                    values: {
                        akunProductId: dataAkun.id,
                        mpPaymentTypeId: dataAkun.mpPaymentTypeId,
                      }
                })
            }
            if(dataAkun.mpPaymentTypeId == 1){
                var loadAPIMpPembayaran = 'pembayaran-mp-sekaligus-sebagian'
                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMpPembayaran, 'POST', {
                    values: {
                        akunProductId: dataAkun.id,
                        mpPaymentTypeId: dataAkun.mpPaymentTypeId,
                      }
                })
            }

            var result = await alert('Data Berhasil Dikirim !','Success')
            if(result){
                this.forceRefresh()
            }
        } catch (e) {
            await alert(e,'Error')
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pending Pengalihan MP</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='view-pengalihan-mps?status.in=2'
                        insertAPI='pengalihan-mps'
                        updateAPI='pengalihan-mps'
                        deleteAPI='pengalihan-mps'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        remoteOperations = {true}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <ModalRegistrasiUpdatePengalihanMp
                    ref={this.modalRef}
                    actionType={'edit'}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default PendingPengalihanMp
