import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader } from 'devextreme-react';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { imageSource, formatUploadFileData } from 'plugin/helper';
import { banks } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';


class FormEditorBroker extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            uploadedData: []
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'master',
                colCount: 4,
                colSpan: 2,
                items: [
                    {
                        dataField: 'brokerCode',
                        label: {
                            text: 'Kode Broker'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'brokerName',
                        label: {
                            text: 'Nama Broker'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'feeBuy',
                        label: {
                            text: 'Fee Beli'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'feeSell',
                        label: {
                            text: 'Fee Jual'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'marginRate',
                        label: {
                            text: 'Rate Marjin'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'nik',
                        label: {
                            alignment: 'left',
                            text: 'NIK'
                        },
                        editorType: "dxTextBox",
                    },
                    {
                        dataField: 'npwp',
                        label: {
                            alignment: 'left',
                            text: 'NPWP'
                        },
                        editorType: "dxTextBox",
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'kinerja',
                caption: 'Informasi Kinerja',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'totalAset',
                        label: {
                            text: 'Total Aset'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'mkbd',
                        label: {
                            text: 'Modal Kerja Bersih Disesuaikan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        type: 'empty'
                    },
                    {
                        dataField: 'nominalOmzet',
                        label: {
                            text: 'Nominal Omzet'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'laba',
                        label: {
                            text: 'Laba'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'marginBersih',
                        label: {
                            text: 'Margin Bersih (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'prosentaseOmzet',
                        label: {
                            text: 'Persentase Omzet (YoY)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'prosentaseLaba',
                        label: {
                            text: 'Persentase Laba (YoY)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'tradeValue',
                        label: {
                            text: 'Trade Value (Bulan Akhir)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'tradeVolume',
                        label: {
                            text: 'Trade Volume (Bulan Akhir)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'tradePercentBei',
                        label: {
                            text: 'Trade Bei (%) (Bulan Akhir)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'address',
                caption: 'Informasi Alamat',
                colCount: 3,
                colSpan: 2,
                items: [
                    {
                        dataField: 'address',
                        label: {
                            text: 'Alamat'
                        },
                        colSpan: 3,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        },
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'noFax',
                        label: {
                            text: 'Fax'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colSpan: 2,
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'INFORMASI REKENING BANK',
                        items: [
                            {
                                dataField: 'rekbankId',
                                label: {
                                    text: 'Bank',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField: 'rekbankCabang',
                                label: {
                                    text: 'Cabang',
                                },
                                // editorType: 'dxSelectBox',
                                // editorOptions: {
                                //     dataSource: banks(this.props.store),
                                //     valueExpr: 'id',
                                //     displayExpr: 'bankName',
                                // }
                            },
                            {
                                dataField: 'rekbankName',
                                label: {
                                    text: 'Nama Rekening',
                                },
                                // editorType: 'dxSelectBox',
                                // editorOptions: {
                                //     dataSource: banks(this.props.store),
                                //     valueExpr: 'id',
                                //     displayExpr: 'bankName',
                                // }
                            },
                            {
                                dataField: 'rekbankNo',
                                label: {
                                    text: 'Nomor Rekening',
                                },
                                // editorType: 'dxSelectBox',
                                // editorOptions: {
                                //     dataSource: banks(this.props.store),
                                //     valueExpr: 'id',
                                //     displayExpr: 'bankName',
                                // }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'INFORMASI REKENING CBEST',
                        items: [
                            {
                                dataField: 'cbestAccName',
                                label: {
                                    text: 'Account Name',
                                },
                            },
                            {
                                dataField: 'cbestAccNo',
                                label: {
                                    text: 'C-Best Account',
                                },
                            },
                            {
                                dataField: 'cbestIfo',
                                label: {
                                    text: 'IFO',
                                },
                            },
                            {
                                dataField: 'contactPerson',
                                label: {
                                    text: 'Contact Person',
                                },
                            },
                        ]
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'contactPerson',
                colCount: 2,
                alignItemLabels: true,
                items: [
                    {
                        itemType: 'group',
                        name: 'contactPerson1',
                        caption: 'Informasi Contact Person 1',
                        colCount: 2,
                        alignItemLabels: true,
                        items: [
                            {
                                dataField: 'dealer1Name',
                                label: {
                                    text: 'Nama'
                                },
                                colSpan: 2,
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'workUnitPerson1',
                                label: {
                                    text: 'Unit Kerja'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer1NoTelp',
                                label: {
                                    text: 'No Telp.'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer1NoHp',
                                label: {
                                    text: 'No HP'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer1Email',
                                label: {
                                    text: 'Email'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'contactPerson2',
                        caption: 'Informasi Contact Person 2',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'dealer2Name',
                                label: {
                                    text: 'Nama'
                                },
                                colSpan: 2,
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'workUnitPerson1',
                                label: {
                                    text: 'Unit Kerja'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer2NoTelp',
                                label: {
                                    text: 'No Telp.'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer2NoHp',
                                label: {
                                    text: 'No HP'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                            {
                                dataField: 'dealer2Email',
                                label: {
                                    text: 'Email'
                                },
                                editorType: 'dxTextBox',
                                editorOptions: {
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'logo',
                colCount: 2,
                items: [
                    {
                        dataField: 'logo',
                        label: {
                            text: 'Logo Broker',
                            visible: false
                        },
                        editorType: 'dxFileUploader',
                        editorOptions: {
                            accept: '.bmp,.gif,.ico,.jpeg,.jpg,.png,.svg,.tif,.tiff,.webp',
                            uploadMode: 'useForm',
                            // onValueChanged: this.onUploadStarted,
                            labelText: "Taruh Logo Broker disini",
                            showFileList: true,
                            selectButtonText: "Upload Logo Broker",
                        }
                    },
                ]
            }
        ]
    }
    show(dataMaster) {
        var dataMaster = dataMaster || {
        }

        this.setState({
            popupVisible: true,
            dataMaster: dataMaster
        });

    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.dataMaster
        if (data.id) {
            try {
                let type = 'success'
                let text = 'Sukses ubah !'
                var updateAPI = `staticdata-wf/brokers`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, updateAPI, 'PUT', {
                    values: this.state.dataMaster,
                    key: this.state.dataMaster.id
                }, this.state.dataMaster)

                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                console.log(e)
            }
        } else {
            if (data) {
                try {
                    let type = 'success'
                    let text = 'Sukses input !'
                    var insertAPI = `staticdata-wf/brokers`
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: data,
                    })
                    if (response) {
                        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                        this.hide()
                    } else {
                        notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                    console.log(e)
                }
            }
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var dataMaster = { ...this.state.dataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                dataMaster[`${fieldName}Name`] = files.name;
                dataMaster[fieldName] = formattedFileData.base64data;
                dataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                console.log(dataMaster)

                this.setState({
                    dataMaster: dataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Broker Data`}
                    width={'70vw'}
                    height="70vh"

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid py-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        formData={this.state.dataMaster}
                                        items={this.dataMaster}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormEditorBroker
