import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { daysDiff, formatDate, formatUploadFileData, formatUploadFileNameAndExtention, summaryDate, yearsDiff } from 'plugin/helper';
import { pensiunType, product, phkType } from 'dataSource/lookup';
import { FileUploader, ScrollView } from 'devextreme-react';

class ProsesDataPensiunDitunda extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }

        this.dataPeserta = {}

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        var formValidate = this.validate()
                        if (formValidate) {
                            await this.props.submitData(this.state.dataMaster)
                            this.props.forceRefresh()
                            this.hide()
                        }
                    }
                },
                visible: this.props.actionType == 'view' ? false : true,
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    readOnly: true
                }
            },
            {
                dataField: 'pensiunType',
                label: {
                    text: 'Jenis Pensiun'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: pensiunType(this.props.store),
                    value: 2,
                    valueExpr: 'id',
                    displayExpr: 'statusName',
                    searchEnabled: true,
                    deferRendering: false,
                },
                isRequired: true,
                validationRules: [
                    {
                        type: "required",
                        message: "Data Harus Diisi"
                    }
                ]
            },
            {
                dataField: 'skPensiunNo',
                label: {
                    text: 'No SK Pensiun'
                },
            },
            {
                dataField: 'skPemberhentianNo',
                label: {
                    text: 'No SK PHK'
                },
            },
            {
                dataField: 'pesertaPhkTypeId',
                label: {
                    text: 'Jenis PHK'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: phkType(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tglDitunda',
                name: 'tglDitunda',
                label: {
                    text: 'Tanggal Ditunda'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        if (e.value) {
                            let data = this.state.dataMaster
                            let hari = Math.abs(daysDiff(formatDate(new Date(e.value)), data.efektifDate))
                            let bulan = Math.round((hari % 365) / 30)
                            let tahun = Math.round(hari / 365)
                            let masaKerja = `${tahun} tahun ${bulan} bulan`
                            let usia = yearsDiff(formatDate(new Date(e.value)), this.dataPeserta.birthDate)
                            this.formRef.current.instance.updateData({
                                masaKerja: masaKerja,
                                usia: usia
                            })
                        }
                    }
                },
                isRequired: true,
                validationRules: [
                    {
                        type: "required",
                        message: "Data Harus Diisi"
                    }
                ]
            },
            {
                dataField: 'masaKerja',
                label: {
                    text: 'Masa Kerja'
                },
                editorOptions: {
                    readOnly: true,
                }
            },
            {
                dataField: 'usia',
                label: {
                    text: 'Usia Saat Ditunda'
                },
                editorOptions: {
                    readOnly: true
                }
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.dataPeserta = {}
        this.setState({
            popupVisible: false,
            dataMaster: {},
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)

                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Name`]: formatNameAndExtention.name,
                        [`${fieldName}Extension`]: formatNameAndExtention.extention,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }
    retrieveData = async (dataPeserta, data = {}) => {
        this.dataPeserta = dataPeserta
        data.pensiunType = 2
        this.setState({
            dataMaster: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Edit Data Pensiun'}
                width={1000}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation='left'
                                    readOnly={this.props.actionType == 'view'}
                                />
                            </div>
                            {/* fileSkPensiun */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSkPensiunName || 'File SK Pensiun'}
                                    showFileList={false}
                                    name={'fileSkPensiun'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.downloadFile('fileSkPensiun')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPensiun ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>

                            {/* fileSkPhk */}
                            <div className="col-md-6">
                                <FileUploader
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.dataMaster.fileSkPhkName || 'File SK PHK'}
                                    showFileList={false}
                                    name={'fileSkPhk'}
                                />
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.downloadFile('fileSkPhk')} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSkPhk ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ProsesDataPensiunDitunda