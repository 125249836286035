import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';


class FormPembelianSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            dataPembelian: {},
            note: {}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.DataGridOrderSahamBrokerRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Submit',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    text: 'ID'
                }
            },
            {
                dataField: 'kode',
                label: {
                    text: 'Kode'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    text: 'Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                }
            },
            {
                dataField: 'tipeTransaksi',
                label: {
                    text: 'Tipe Transaksi'
                }
            },
        ]
        this.DataPembelian = [
            {
                name: 'transaction',
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'productCode', // provides display values
                        },
                        validationRules: [{
                            type: "required",
                            message: "Pilih produk terlebih dahulu"
                        }]
                    },
                    {
                        dataField: 'tglOrder',
                        label: {
                            text: 'Tanggal Order'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: "d MMM yyyy",
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            width: '100%',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            text: 'Saham'
                        },
                        editorType: "dxSelectBox",
                        editorOptions:{
                            dataSource: stock(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: function(item){
                                return item && item.instrumentCode + " - " + item.instrumentName;
                            },
                            searchEnabled: true,
                            deferRendering: false
                        },
                        validationRules: [{
                            type: "required",
                            message: "Pilih Saham terlebih dahulu"
                        }]
                    },
                    {
                        dataField: 'quantityOrder',
                        label: {
                            text: 'Kuantitas (Lembar)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                        validationRules: [{
                            type: "range",
                            min: 100,
                            message: "Jumlah lembah minimal 100 lembar (1lot)"
                        }]
                    },
                    {
                        dataField: 'priceType',
                        label: {
                            text: 'Jenis Harga'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: "1",
                                    value: 'Tetap'
                                },
                                {
                                    id: "2",
                                    value: 'Antara'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            onValueChanged: (e) => {
                                this.formRef.current.instance.beginUpdate()
                                if(e.value === "1"){
                                    this.formRef.current.instance.itemOption('price.priceMin','visible',false)
                                    this.formRef.current.instance.itemOption('price.priceMax','visible',false)
                                    this.formRef.current.instance.itemOption('price.price','visible',true)
                                }

                                if(e.value === "2"){
                                    this.formRef.current.instance.itemOption('price.priceMin','visible',true)
                                    this.formRef.current.instance.itemOption('price.priceMax','visible',true)
                                    this.formRef.current.instance.itemOption('price.price','visible',false)
                                }

                                this.formRef.current.instance.itemOption('price.empty','visible',false)
                                this.formRef.current.instance.endUpdate()
                            }
                        },
                        validationRules: [{
                            type: "required",
                            message: "Pilih jenis harga terlebih dahulu"
                        }]
                    },
                    {
                        dataField: 'fundManagerId',
                        label: {
                            text: 'Manager Investasi'
                        },
                        editorType: "dxSelectBox",
                        editorOptions:{
                            dataSource: fundManager(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "fundManagerName", // provides display values
                            searchEnabled: true,
                            deferRendering: false
                        },
                        validationRules: [{
                            type: "required",
                            message: "Manager investasi terlebih dahulu"
                        }]
                    },                   
                ]
            },
            {
                itemType: 'group',
                name: 'price',
                colCount: 4,
                items:[
                    {
                        dataField: 'priceMin',
                        label: {
                            text: 'Harga Minimum'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00"
                        },
                        visible: false,
                        validationRules: [{
                            type: "range",
                            min: 1,
                            message: "Harga minimum harus lebih dari 0"
                        }]
                    },
                    {
                        dataField: 'priceMax',
                        label: {
                            text: 'Harga Maximum'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00"
                        },
                        visible: false,
                        validationRules: [{
                            type: "range",
                            min: 1,
                            message: "Harga maximum harus lebih dari 0"
                        }]
                    },
                    {
                        dataField: 'price',
                        label: {
                            text: 'Harga'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00"
                        },
                        visible: false,
                        validationRules: [{
                            type: "range",
                            min: 1,
                            message: "Harga harus lebih dari 0"
                        }]
                    },
                ]
            }, 
        ]

        this.brokerColumns = [
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup : {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function(item){
                        return item && item.brokerCode + " - " + item.brokerName;
                    },
                }
            },
            {
                dataField: 'quantityOrder',
                caption:'Kuantitas (Lembar)',
                format: '#,##0',
                width: '150px',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0"
                },
            },
        ]
        this.note = [
            {
                itemType: 'group',
                caption: 'Catatan',
                items: [
                    {
                        dataField: 'catatan',
                        label: {
                            alignment:'left',
                            text: 'Catatan'
                        },
                        editorType:'dxTextArea',
                        editorOptions:{
                            height: '190'
                        }
                    }
                ]
            }
        ]

        this.formRef = React.createRef()
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataPembelian: {
                tglOrder : new Date(),
                quantityOrder: 0,
                priceMin: 0,
                priceMax: 0,
                price: 0,
            },
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async() => {
        var formValidation = this.formRef.current.instance.validate()

        if(!formValidation.isValid){
            var messageArray = formValidation.brokenRules.map(value => value.message)
    
            let type = "error";
            let text = messageArray.join("\n");
    
            notify(
            {
                message: text,
                width: "AUTO",
                shading: true,
                position: { at: "center", my: "center", of: window }
            },
            type,
            2000
            );
            return
        }
        
        var dataSubmit = {...this.state.dataPembelian}
        var dataNote = {...this.state.note}
        var dataBroker = this.DataGridOrderSahamBrokerRef.current.getDataSource()

        dataBroker = dataBroker.map(value => {
            return {
                brokerId: value.brokerId,
                quantityOrder : value.quantityOrder
            }
        })

        dataSubmit = {
            "catatan": dataNote.catatan,
            "fundManagerId": dataSubmit.fundManagerId,
            "instrumentId": dataSubmit.instrumentId,
            "orderBrokerItems": dataBroker,
            "priceMax": dataSubmit.priceType === "1" ? dataSubmit.price : dataSubmit.priceMax,
            "priceMin": dataSubmit.priceType === "1" ? dataSubmit.price : dataSubmit.priceMin,
            "priceType": dataSubmit.priceType,
            "productId": dataSubmit.productId,
            "quantityOrder": dataSubmit.quantityOrder,
            "transactionTypeId": 211
        }

        // console.log(JSON.stringify(dataSubmit))

        var orderStok = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store, 'order-stoks/order-penempatan-stok','POST',{
            values : dataSubmit
        })

        this.hide()
    }

    loadBrokerData = () => {
        return []
    }

    onBrokerInserted = (e) => {
        this.updateTotalQuantity()
    }

    onBrokerUpdated = (e) => {
        this.updateTotalQuantity()
    }

    onBrokerDeleted = (e) => {
        this.updateTotalQuantity()
    }

    updateTotalQuantity = () => {
        var dataBroker = this.DataGridOrderSahamBrokerRef.current.getDataSource()

        var totalLembar = dataBroker.map(value => value.quantityOrder).reduce((a,b) => a + b)

        this.formRef.current.instance.updateData('quantityOrder',totalLembar)
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Penempatan Investasi Saham`}
                minWidth={'70vh'}
                height="520px"

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        {/* <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div> */}
                        <div id="tabindex-0" className={`col-md-12` } style={{height:"400px", overflowY:"auto"}}>
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <Form
                                        ref={this.formRef}
                                        // colCount={2}
                                        id={'formDataPembelian'}
                                        formData={this.state.dataPembelian}
                                        items={this.DataPembelian}
                                        scrollingEnabled={true}
                                        labelLocation={'left'}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <h6 className="text-left"><u>Broker</u></h6>
                                    <DevExpressDataGrid
                                        ref = {this.DataGridOrderSahamBrokerRef}
                                        loadAPI='brokers'
                                        insertAPI='brokers'
                                        updateAPI='brokers'
                                        deleteAPI='brokers' 

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadBrokerData}

                                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={true}

                                        exportExcel={false}
                                        exportFileName={"Broker"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}
                                        
                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}

                                        
                                        onRowInserted={this.onBrokerInserted}
                                        onRowUpdated={this.onBrokerUpdated}
                                        onRowRemoved={this.onBrokerDeleted}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Broker'}
                                        popupWidth={400} //masukan dalam ukuran pixel
                                        popupHeight={200} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup
                                        height={200}
                                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.brokerColumns} // taruh konfigurasi kolom disini
                                        // SummaryConfiguration={this.summary}

                                        onToolbarPreparing = {this.onToolbarPreparing}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formNote'}
                                        formData={this.state.note}
                                        items={this.note}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormPembelianSaham
