import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalProsesDaftarUlang from './actionProsesDaftarUlang';
import notify from 'devextreme/ui/notify';

class FormDaftarUlangOnline extends Component {
    constructor(props) {
        super(props)

        this.state = {
            getUrl: 'view-daftar-ulang-onlines?daftarUlangId.in=0',
        }

        this.columnConfiguration = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        onClick: (e) => {
                            this.showModalProsesOnline(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'tglTerima',
                caption: 'Tanggal Diterima',
                dataType: 'date',  
                format: 'dd MMM yyyy',
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'verifiedDate',
                caption: 'Tanggal Verifikasi',
                dataType: 'date',  
                format: 'dd MMM yyyy',
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
        ]

        this.modalProsesOnlineDaftarUlangRef = React.createRef()
        this.dataGridOnlineRef = React.createRef()

        this.currentData = []

    }

    passDataToParent = () => {
        var data = {
            dataOnline: this.dataGridOnlineRef.current.getDataSource(),
            currentDataOnline: this.currentData,
            deletedDataOnline: this.dataGridOnlineRef.current.getDeletedIndex(),
        }
        return data
    }

    removeState = () => {
        this.setState({
            getUrl: "view-daftar-ulang-onlines?daftarUlangId.in=0",
        })
    }
    
    getStatusPeserta = (status) => {
        this.statusPeserta = status
    }

    reloadData = (daftarUlangId) => {
        this.setState({
            getUrl : `view-daftar-ulang-onlines?daftarUlangId.in=${daftarUlangId}`
        })

        console.log(daftarUlangId)
    }

    showModalProsesOnline = (data = {}) => {
        this.modalProsesOnlineDaftarUlangRef.current.retrieveData(data, 'online')
        this.modalProsesOnlineDaftarUlangRef.current.show()
    }

    editData = async (data) => {
        try {
            var key = data.id

            if (key) {
                this.dataGridOnlineRef.current.push([
                    {
                        type: 'update',
                        data: data,
                        key: key
                    }
                ])
            } else {
                this.dataGridOnlineRef.current.push([
                    {
                        type: 'insert',
                        data: data
                    }
                ])
            }


            let type = 'success'
            let text = 'Berhasil diubah!'

            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        } catch (e) {
            console.log(e)
            let type = 'error'
            let text = 'Gagal diubah!'

            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }
    }

    deleteData = (data) => {
        this.dataGridOnlineRef.current.push([
            {
                type: 'remove',
                key: data.id
            }
        ])
    }

    forceRefresh = () => {
        this.dataGridOnlineRef.current.forceRefresh(true)
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridOnlineRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='daftar-ulang-onlines'
                    updateAPI='daftar-ulang-onlines'
                    deleteAPI='daftar-ulang-onlines'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {true}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle = {`Data Online`}
                    popupWidth = {500} //masukan dalam ukuran pixel
                    popupHeight = {300} //masukkan dalam ukuran pixel
                    

                    popupFormLabelLocation = 'left' //accepted value = top, left, right
                    popupFormMinColWidth = {300} // minimum lebar kolom
                    popupFormColCount = {1} //jumlah kolom pada form
                    
                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter
                    
                    ColumnConfiguration={this.columnConfiguration} // taruh konfigurasi kolom disini
                    // onToolbarPreparing = {this.onToolbarPreparingDataAkun}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalProsesDaftarUlang
                    store={this.props.store}
                    ref={this.modalProsesOnlineDaftarUlangRef}
                    submitData={this.editData}
                />
            </React.Fragment>
        )
    }
}

export default FormDaftarUlangOnline
