import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { entity } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import { entityType } from 'dataSource/lookup'
import FormEntity from '../modal/static-data/akunting/entity'


class Entity extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModalEntityEditor(e)
                        }
                    },
                ]
            },
            {
                dataField: 'entityName',
                caption: 'Entity Name',
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
            {
                dataField: 'type',
                caption: 'Type',
                lookup: {
                    dataSource: entityType(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                },
                validationRules : [
                    {
                        type: "required",
                    }
                ],
            },
            {
                dataField: 'reff',
                caption: 'Reff',
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
        ]

        this.dataGridRef = React.createRef()
        this.showModalEntityEditorReff = React.createRef()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalEntityEditor(e)
                    },
                }
            }
        )
    }

    showModalEntityEditor = (e) => {
        if(e.row){
            this.showModalEntityEditorReff.current.retrieveData(e.row.data)
        }
        this.showModalEntityEditorReff.current.show()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Entitas</h2>

                <DevExpressDataGrid
                ref={this.dataGridRef}
                    loadAPI='entitis'
                    insertAPI='staticdata-wf/entitis'
                    updateAPI='staticdata-wf/entitis'
                    deleteAPI='staticdata-wf/entitis' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Account Entity"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Account Entity Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    height= 'calc(-220px + 100vh)'

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormEntity 
                    ref={this.showModalEntityEditorReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default Entity
