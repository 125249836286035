import React from 'react';
import ReactDOM from 'react-dom'
import DataGrid, { Editing, Popup, Paging, Pager, Form, ColumnChooser, ColumnFixing, FilterRow, Column, Scrolling, Sorting, MasterDetail, Summary, TotalItem, Grouping, GroupPanel, } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import uuidv4 from 'uuid/v4'
import DataSource from 'devextreme/data/data_source';

import Excel from 'exceljs/dist/exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';

import { httpRequest } from 'plugin/httprequest'
import { search, searchIndex, formatDate, formatNumberAum } from 'plugin/helper'
import { GroupItem } from '../../../node_modules/devextreme-react/data-grid';

/* contoh pemanggilan komponen
    <DevExpressDataGrid 
        loadAPI = 'api-url'
        insertAPI = 'api-url'
        updateAPI = 'api-url'
        deleteAPI = 'api-url'

        allowAdding={true or false}
        allowDeleting={true or false}
        allowUpdating={true or false}


        showBorders = {true or false}

        paging = {true or false}
        defaultPageSize = {10 jumlah baris default untuk paging}
    	
        //bagian konfigurasi popup saat insert dan edit record
        popupTitle = {'judul popup taruh disini'}
        popupWidth = {300} //masukan dalam ukuran pixel
        popupHeight = {310} //masukkan dalam ukuran pixel

        popupFormLabelLocation = 'top' //accepted value = top, left, right
        popupFormMinColWidth = {300} // minimum lebar kolom
        popupFormColCount = {1} //jumlah kolom pada form
    	
        //akhir bagian konfigurasi popup

        ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
        ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

        FilterRow = {true} // set false untuk mematikan fitur filter

        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
    	
        //contoh konfigurasi kolom
        //this.columns = [{
        //    dataField: 'kolom1',
        //    caption: 'Ini Kolom 1'
        //}, {
        //    dataField: 'kolom2',
        //    caption: 'Ini Kolom 2'
        //}]
        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
    	
        store = {this.props.store} // jangan di edit edit
        />
*/

class DevExpressDataGrid extends React.Component {
    constructor(props) {
        super(props);

        this.elementLoaded = false

        this.dataSource = new CustomStore({
            key: this.props.keyField || 'id',
            load: async (loadOptions) => {
                let params = "?";

                if (this.props.remoteOperations) {
                    if (!isNaN(loadOptions.skip) && !isNaN(loadOptions.take)) {
                        params += `page=${(loadOptions.skip / loadOptions.take)}&`
                        params += `size=${loadOptions.take}&`
                    }

                    if (loadOptions.filter === undefined && loadOptions.select === undefined && params.length === 1) {
                        params += `page=0&`
                        params += `size=${this.totalCount}&`
                    }

                    if (loadOptions.sort) {
                        params += loadOptions.sort.map(value => {
                            return (`sort=${value.selector},${value.desc ? 'desc' : 'asc'}&`)
                        }).join('')
                    }

                    if (loadOptions.filter) {
                        var filterParam = loadOptions.filter.filter(value => Array.isArray(value))

                        if (filterParam.length == 0) {
                            var operations
                            switch (loadOptions.filter[1]) {
                                case '<': operations = 'lessThan'
                                    break
                                case '<=': operations = 'lessThanOrEqual'
                                    break
                                case '>': operations = 'greaterThan'
                                    break
                                case '>=': operations = 'greaterThanOrEqual'
                                    break
                                case '=': operations = 'equals'
                                    break
                                case '<>': operations = 'notEquals'
                                    break
                                default: operations = loadOptions.filter[1]
                                    break
                            }

                            params += `${loadOptions.filter[0]}.${operations}=${loadOptions.filter[2]}&`

                        } else {
                            params += filterParam.map(value => {
                                var operations

                                if (value.filterValue instanceof Date) {
                                    var dateFilter = value.filter(value => Array.isArray(value))

                                    var filterText = dateFilter.map((value, index) => {
                                        switch (value[1]) {
                                            case '<': operations = 'lessThan'
                                                break
                                            case '<=': operations = 'lessThanOrEqual'
                                                break
                                            case '>': operations = 'greaterThan'
                                                break
                                            case '>=': operations = 'greaterThanOrEqual'
                                                break
                                            case '=': operations = 'equals'
                                                break
                                            case '<>': operations = 'notEquals'
                                                break
                                        }

                                        return (`${value[0]}.${operations}=${value[2]}&`)
                                    }).join('')

                                    return (filterText)
                                } else {
                                    switch (value[1]) {
                                        case '<': operations = 'lessThan'
                                            break
                                        case '<=': operations = 'lessThanOrEqual'
                                            break
                                        case '>': operations = 'greaterThan'
                                            break
                                        case '>=': operations = 'greaterThanOrEqual'
                                            break
                                        case '=': operations = 'equals'
                                            break
                                        case '<>': operations = 'notEquals'
                                            break
                                    }

                                    return (`${value[0]}.${operations}=${value[2]}&`)
                                }
                            }).join('')
                        }
                    }
                }

                if (this.props.loadAPI.includes('?')) {
                    params = '&' + params.slice(1, params.length)
                }

                params = params.slice(0, -1);
                var response

                // if(this.props.useArraySource && this.props.ArraySourceData && (this.ArraySourceData.length === 0 || this.refreshForced)){
                if (this.props.useArraySource && this.props.ArraySourceData && ((this.ArraySourceData.data ? this.ArraySourceData.data.length === 0 : this.ArraySourceData.length === 0) || this.refreshForced)) {
                    if (typeof this.props.ArraySourceData === 'function') {
                        this.ArraySourceData = await this.props.ArraySourceData(params)
                    } else {
                        this.ArraySourceData = this.props.ArraySourceData
                    }
                } else if (this.props.useArraySource && this.props.ArraySourceData && this.props.remoteOperations) {
                    if (typeof this.props.ArraySourceData === 'function') {
                        this.ArraySourceData = await this.props.ArraySourceData(params)
                    } else {
                        this.ArraySourceData = this.props.ArraySourceData
                    }
                }

                if (((this.ArraySourceData.data ? this.ArraySourceData.data.length === 0 : this.ArraySourceData.length === 0) || !this.props.useArraySource) && !this.props.ArraySourceData) {
                    if (this.props.remoteOperations) {
                        if (loadOptions.isLoadingAll) {
                            const loopCount = this.totalCount / 1000
                            let data = []
                            for (let index = 0; index <= loopCount; index++) {
                                params = "?"
                                params += `page=${index}&`
                                params += `size=${1000}&`

                                if (this.props.loadAPI.includes('?')) {
                                    params = '&' + params.slice(1, params.length)
                                }

                                params = params.slice(0, -1);

                                const responseLoop = await httpRequest(this.props.backendserver, this.props.store, this.props.loadAPI + params, 'GET', null, true)

                                data = [...data, ...responseLoop.data]
                            }

                            response = {
                                data: data,
                                totalCount: this.totalCount,
                            }

                            this.ArraySourceData = response.data
                        } else {
                            response = await httpRequest(this.props.backendserver, this.props.store, this.props.loadAPI + params, 'GET', null, true)

                            response.headers['x-total-count'] = response.headers['x-total-count'] || response.data.length

                            response = {
                                data: response.data,
                                totalCount: parseInt(response.headers['x-total-count']),
                            }

                            this.totalCount = response.totalCount

                            this.ArraySourceData = response.data
                        }
                    } else {
                        if (this.refreshForced || (this.ArraySourceData.data ? this.ArraySourceData.data.length === 0 : this.ArraySourceData.length === 0)) {
                            response = await httpRequest(this.props.backendserver, this.props.store, this.props.loadAPI, 'GET', null, true)

                            response.headers['x-total-count'] = response.headers['x-total-count'] || response.data.length

                            response = {
                                data: response.data,
                                totalCount: parseInt(response.headers['x-total-count']),
                            }

                            this.ArraySourceData = response
                        } else {
                            response = this.ArraySourceData
                        }
                    }
                } else {
                    response = this.ArraySourceData
                }

                if (this.refreshForced) {
                    this.refreshForced = false
                }
                return response
            },
            insert: async (values) => {
                if (this.useArraySource) {
                    values.id = uuidv4()
                    this.ArraySourceData.push(values)

                } else {
                    await httpRequest(this.props.backendserver, this.props.store, this.props.insertAPI, 'POST', {
                        values: values
                    })

                    this.forceRefresh(true)
                }
            },
            update: async (key, values) => {
                if (this.useArraySource) {
                    var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == key)

                    for (var entry of Object.entries(values)) {
                        let keyObject = entry[0];
                        let value = entry[1];

                        this.ArraySourceData[rowUpdated][keyObject] = value
                    }
                } else {
                    await httpRequest(this.props.backendserver, this.props.store, this.props.updateAPI, 'PUT', {
                        values: values,
                        key: key
                    }, this.focusedRow)

                    this.forceRefresh(true)
                }
            },
            remove: async (key) => {
                if (this.useArraySource) {
                    if (key.length != 36) {
                        this.DeletedIndex.push(key)
                    }

                    var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == key)

                    this.ArraySourceData.splice(rowUpdated, 1)

                } else {
                    await httpRequest(this.props.backendserver, this.props.store, this.props.deleteAPI, 'DELETE', key)
                    this.forceRefresh(true)
                }
            },
            onPush: (changes) => {
                for (var change of changes) {
                    switch (change.type) {
                        case 'insert':
                            var keyField = this.props.keyField || 'id'

                            if (change.data[keyField] == null) {
                                change.data[keyField] = uuidv4()
                            }
                            this.ArraySourceData.push(change.data)

                            if (this.props.onRowUpdated && this.props.usePushEvent) {
                                this.props.onRowUpdated({
                                    key: change.data[keyField],
                                    data: change.data
                                })
                            }
                            break;
                        case 'update':
                            var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == change.key)

                            for (var entry of Object.entries(change.data)) {
                                let keyObject = entry[0];
                                let value = entry[1];

                                this.ArraySourceData[rowUpdated][keyObject] = value
                            }

                            if (this.props.onRowUpdated && this.props.usePushEvent) {
                                this.props.onRowUpdated({
                                    key: this.ArraySourceData[rowUpdated][this.props.keyField || 'id'],
                                    data: this.ArraySourceData[rowUpdated]
                                })
                            }
                            break;
                        case 'remove':
                            if (change.key.length != 36) {
                                this.DeletedIndex.push(change.key)
                            }

                            var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == change.key)

                            this.ArraySourceData.splice(rowUpdated, 1)
                            break;
                        default:
                            break;
                    }
                }
            },
            onLoaded: () => {
                if (typeof this.props.triggerDataLoaded === 'function' && !this.elementLoaded) {
                    setTimeout(() => {
                        this.props.triggerDataLoaded()
                    }, 500)
                    this.elementLoaded = true
                }
            }
        })

        this.state = {
            dataSource: new DataSource({
                store: this.dataSource,
                reshapeOnPush: true
            }),
            requests: [],
            refreshMode: 'reshape',
            contextMenu: [],
            autoExpandAll: false,
            selectedRowKeys: [],
            columns: []
        };

        // this.columns = []

        this.refreshForced = false
        this.focusedRow = {}
        this.DatagridRef = React.createRef()

        this.ArraySourceData = []
        this.DeletedIndex = []
        this.useArraySource = this.props.useArraySource === undefined ? false : this.props.useArraySource
        this.totalCount = 0

        this.menuRightClick = typeof this.props.menuRightClick === 'boolean' ? this.props.menuRightClick : true
    }

    get DataGrid() {
        return this.DatagridRef.current.instance
    }

    componentWillMount = () => {
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.loadAPI !== this.props.loadAPI) {
            this.forceRefresh(true)
        }
    }

    componentDidMount = () => {
        this.columnDraw()

        this.updateEditor({
            allowAdding: this.props.allowAdding,
            allowUpdating: this.props.allowUpdating,
            allowDeleting: this.props.allowDeleting,
        })
    }

    onEditingStart = (e) => {
        if (this.props.editingMode !== 'cell' && this.props.editingMode !== 'batch') {
            this.focusedRow = e.data

            delete this.focusedRow.procId
            // this.setState({
            //     focusedRow: e.data
            // })
        }
        // console.log('editing',e)
    }

    onInitNewRow = (e) => {
        if (this.props.columnDefaultValue !== null && this.props.columnDefaultValue !== undefined) {
            this.props.columnDefaultValue.forEach((element, index) => {
                e.data[element.dataField] = element.value
            })
        }
        // console.log('initNew',e)
    }

    onRowInserting = (e) => {
        // console.log('inserting',e)
    }

    onRowInserted = (e) => {
        let type = 'success'
        let text = 'Data successfully inserted!'

        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    onRowUpdating = (e) => {
        // console.log('updating',e)
    }

    onRowUpdated = (e) => {
        let type = 'success'
        let text = 'Data successfully updated!'

        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    onRowRemoving = (e) => {
        // console.log('removing',e)
    }

    onRowRemoved = (e) => {
        let type = 'success'
        let text = 'Data successfully deleted!'

        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    onExporting = (e) => {

    }

    bubbleSort = (arr) => {
        var len = arr.length;
        for (var i = 0; i < len - 1; i++) {
            for (var j = 0; j < len - 1 - i; j++) {
                if (arr[j] > arr[j + 1]) {
                    var temp = arr[j];
                    arr[j] = arr[j + 1];
                    arr[j + 1] = temp;
                }
            }
        }
        return arr;
      }

    // onExportingWithFormatNum = (e) => {
    //     const workbook = new Excel.Workbook();
    //     const worksheet = workbook.addWorksheet(this.props.exportFileName);
    //     let numbers = [];
    //     // let str = ''
    //     exportDataGrid({
    //         component: e.component,
    //         worksheet,
    //         customizeCell: ({ gridCell, excelCell }) => {
    //             if (gridCell.rowType === 'data' || gridCell.rowType === 'totalFooter') {
    //                 if (gridCell.column.dataType === 'number') {
    //                     if (this.props.exceptFieldExportFormtNum.includes(gridCell.column.dataField)) {
    //                         if(excelCell.value === null) {
    //                             excelCell.value = null;
    //                         } else {
    //                             excelCell.value = parseFloat(gridCell.value, 10);
    //                             excelCell.numFmt = '#,##0.00'
    //                         }
    //                     }

    //                     if (gridCell.column.dataField === 'no') {
    //                         numbers.push(excelCell.value)
    //                     }
    //                 } 
    //             }
    //         },
    //     }).then(() => {
    //         this.bubbleSort(numbers)
    //         workbook.xlsx.writeBuffer().then((buffer) => {
    //             saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.props.exportFileName + '.xlsx');
    //         });
    //     })
    //     e.cancel = true
    // }

    onExportingWithFormatNum = (e) => {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet(this.props.exportFileName);
        let numbers = [];
        // let str = ''
        exportDataGrid({
            component: e.component,
            worksheet,
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'data' || gridCell.rowType === 'totalFooter' || gridCell.rowType === 'groupFooter') {
                    if (gridCell.column.dataType === 'number') {
                        if (this.props.exceptFieldExportFormtNum.includes(gridCell.column.dataField)) {
                            if (excelCell.value === null || isNaN(parseFloat(gridCell.value))) {
                                excelCell.value = null;
                            } else {
                                excelCell.value = parseFloat(gridCell.value, 10);
                                excelCell.numFmt = '#,##0.00';
                            }
                        }
    
                        if (gridCell.column.dataField === 'no' || gridCell.column.dataField === 'id') {
                            numbers.push(gridCell.value);
                        }
                    }
                }
            },
        }).then(() => {
            numbers = this.bubbleSort(numbers);
            numbers.forEach((number, index) => {
                worksheet.getCell(index + 2, 1).value = number; 
            });
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.props.exportFileName + '.xlsx');
            });
        });
        e.cancel = true;
    }
    

    onContextMenuPreparing = (e) => {
        if (this.menuRightClick) {
            var contextMenu = []

            // if(this.props.allowAdding){
            //     contextMenu.push({
            //         text: "insert",
            //         onItemClick: () => {
            //             this.DataGrid.insertRow();
            //         }
            //     });
            // }

            if (this.props.allowUpdating) {
                contextMenu.push({
                    text: "Ubah",
                    onItemClick: () => {
                        this.DataGrid.editRow(e.row.rowIndex);
                    }
                });
            }

            if (this.props.allowDeleting) {
                contextMenu.push({
                    text: "Hapus",
                    onItemClick: () => {
                        this.DataGrid.deleteRow(e.row.rowIndex);
                    }
                });
            }

            var buttonConfig = search('buttons', 'type', this.props.ColumnConfiguration)
            if (buttonConfig) {
                buttonConfig.buttons.forEach((value, index) => {
                    if (typeof value === 'object') {
                        contextMenu.push({
                            text: value.text,
                            onItemClick: () => { value.onClick(e) }
                        });
                    }
                })
            }

            if (e.row) {
                if (e.row.rowType === "data") {
                    e.items = contextMenu;
                }
            }
        }
    }

    columnDraw = () => {
        let columns = this.props.ColumnConfiguration

        var buttonIndex = searchIndex('buttons', 'type', columns)

        if (buttonIndex !== undefined) {
            if (this.props.grouping) {
                // columns[buttonIndex].buttons.push({
                //     text: "Expand / Collapse All",
                //     // type:"default",
                //     elementAttr: { class: "bg-dapen-default" },
                //     onClick: () => {
                //         this.setState({
                //             autoExpandAll: true
                //         })
                //     },
                // })
            }
            columns[buttonIndex].caption = 'Action'
            columns[buttonIndex].visible = !this.menuRightClick
            // console.log(columns[buttonIndex])
        } else {
            if (this.props.editingMode !== 'cell') {
                var buttons = []
                if (this.props.allowUpdating) {
                    buttons.push('edit')
                }

                if (this.props.allowDeleting) {
                    buttons.push('delete')
                }

                if (this.props.grouping) {
                    buttons.push({
                        itemType: "button",
                        buttonOptions: {
                            text: "Expand / Collapse All",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    autoExpandAll: true
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    })
                }

                if (buttons.length > 0) {
                    columns.push({
                        type: 'buttons',
                        caption: "Action",
                        buttons: buttons,
                        visible: !this.menuRightClick
                    })
                }
            }
        }

        this.setState({
            columns: columns
        })
        // this.columns = columns

        // console.log(this.columns)

        this.expandedRowKey = null
        this.loadedRow = []
    }
    onSelectionChanged = (e) => {
        var selectedRowKeys = e.selectedRowKeys

        this.DatagridRef.current.instance.selectRows(selectedRowKeys)

        // console.log(selectedRowKeys)

        // this.setState({
        //     selectedRowKeys : selectedRowKeys
        // })

        if (typeof this.props.onSelectionChanged === 'function') {
            this.props.onSelectionChanged(e)
        }
    }

    forceRefresh = (param = false) => {
        this.refreshForced = param
        this.DataGrid.refresh(true)
    }

    repaint = () => {
        this.DataGrid.repaint()
    }

    getDataSource = () => {
        return this.ArraySourceData
    }

    injectDataToDataSource = async (data) => {
        var datasource = this.state.dataSource

        if (Array.isArray(data)) {
            for (var row of data) {
                await datasource.insert(row)
            }
        } else {
            await datasource.insert(data)
        }
    }

    getDeletedIndex = () => {
        return this.DeletedIndex
    }

    emptyDeletedIndex = () => {
        this.DeletedIndex = []
    }

    onRowExpanded = (e) => {
        if (!this.loadedRow.some(value => value === e.key) && this.props.useDelayExpand) {
            this.DataGrid.collapseRow(e.key)
            this.expandedRowKey = e.key
        }
    }

    forceExpand = () => {
        if (this.expandedRowKey) {
            this.loadedRow.push(this.expandedRowKey)
            setTimeout(() => { this.DataGrid.expandRow(this.expandedRowKey) }, 1)

        }
    }

    push = (data) => {
        this.dataSource.push(data)
    }

    updateEditor = (prop) => {
        this.DatagridRef.current.instance.beginUpdate()
        this.DatagridRef.current.instance.option('editing.allowAdding', prop.allowAdding)
        this.DatagridRef.current.instance.option('editing.allowUpdating', prop.allowUpdating)
        this.DatagridRef.current.instance.option('editing.allowDeleting', prop.allowDeleting)
        this.DatagridRef.current.instance.endUpdate()
    }

    getDatagridComponentOption = (optionName) => {
        return this.DatagridRef.current.instance.option(optionName)
    }

    setDatagrid = (optionName, optionValue) => {
        this.DatagridRef.current.instance.option(optionName, optionValue)
    }

    changeColumn = (columnConfig) => {
        this.setState({
            columns: columnConfig
        })
    }

    render() {
        const { refreshMode, dataSource } = this.state;
        return (
            <DataGrid
                ref={this.DatagridRef}
                id={'grid'}
                keyExpr={this.props.keyField || 'id'}
                showBorders={this.props.showBorders}
                dataSource={dataSource}
                repaintChangesOnly={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                allowFiltering={true}
                columnAutoWidth={true}
                columnMinWidth={20}
                export={{
                    enabled: this.props.exportExcel,
                    fileName: this.props.exportFileName,
                    allowExportSelectedData: this.props.allowExportSelectedData
                }}
                onExporting={this.props.exportWithFormatNum ? this.onExportingWithFormatNum : this.props.onExporting || this.onExporting}
                // onExporting={this.props.onExporting || this.onExporting}
                remoteOperations={this.props.remoteOperations ? { sorting: true, paging: true, filtering: true } : false}

                onEditingStart={this.onEditingStart}
                onInitNewRow={this.props.onInitNewRow || this.onInitNewRow}
                onRowInserting={this.props.onRowInserting || this.onRowInserting}
                onRowInserted={this.props.onRowInserted || this.onRowInserted}
                onRowUpdating={this.props.onRowUpdating || this.onRowUpdating}
                onRowUpdated={this.props.onRowUpdated || this.onRowUpdated}
                onRowRemoving={this.props.onRowRemoving || this.onRowRemoving}
                onRowRemoved={this.props.onRowRemoved || this.onRowRemoved}
                onFocusedCellChanged={this.props.onFocusedCellChanged || this.onFocusedCellChanged}
                onCellPrepared={this.props.onCellPrepared}
                onEditorPreparing={this.props.onEditorPreparing}
                onContextMenuPreparing={this.onContextMenuPreparing}

                onRowExpanded={this.props.onRowExpanded || this.onRowExpanded}
                onRowExpanding={this.props.onRowExpanding || this.onRowExpanded}

                // selectedRowKeys={this.state.selectedRowKeys}

                // columns={this.state.columns}
                onToolbarPreparing={this.props.onToolbarPreparing}
                onContentReady={this.props.onContentReady}
                onOptionChanged={this.props.onOptionChanged}
                
                selection={{
                    mode: this.props.selection || 'single',
                    showCheckBoxesMode: 'always',
                    allowSelectAll: true,
                    // deferred: true,
                    selectAllMode: this.props.selectAllMode || "allPages"
                }}
                onSelectionChanged={this.onSelectionChanged}

                height={this.props.height || 'calc(100vh - 305px)'}

                hoverStateEnabled={true}
                rowAlternationEnabled={true}
                masterDetail={
                    {
                        enabled: this.props.allowEnabledMasterDetail,
                        template: (container, info) => {
                            if (this.props.customDetailElem) {
                                ReactDOM.render(
                                    this.props.customDetailElem(info.data)
                                    , container
                                )
                            }
                        },
                        // component: this.props.masterDetailComponent,
                        autoExpandAll: this.props.autoExpandmasterDetail
                    }
                }
            >
                <GroupPanel visible={this.props.grouping} allowColumnDragging={true} />
                <Grouping autoExpandAll={this.state.autoExpandAll} />

                <FilterRow visible={this.props.FilterRow || false} />

                <Paging defaultPageSize={this.props.defaultPageSize || 10} enabled={this.props.paging} />
                <Pager
                    showPageSizeSelector={this.props.showPageSizeSelector == false ? false : true}
                    allowedPageSizes={[5, 10, 20]}
                    // showInfo={this.props.paging}
                    showInfo={this.props.paging === false ? false : this.props.showInfo === false ? false : true}
                    visible={this.props.paging}
                />

                <Sorting
                    mode={this.props.sortingMode || 'multiple'}
                />

                {
                    this.state.columns.map((column) => {
                        var editorType = column.editorType === 'dxSelectBox' ? null : column.editorType
                        var editorOptions = column.editorType === 'dxSelectBox' ? null : column.editorOptions
                        return <Column
                            key={column.name || column.dataField || 'buttons'}
                            dataField={column.dataField}
                            name={column.name}
                            caption={column.caption}
                            lookup={column.lookup}
                            cellRender={column.cellRender}
                            alignment={column.alignment || 'left'}
                            cssClass={column.cssClass}
                            format={column.format}
                            dataType={column.dataType}
                            width={column.width}
                            type={column.type}
                            buttons={column.buttons}
                            editorType={editorType}
                            editorOptions={editorOptions}
                            visible={column.visible}
                            sortOrder={column.sortOrder}
                            allowEditing={column.allowEditing}
                            calculateCellValue={column.calculateCellValue}
                            setCellValue={column.setCellValue}
                            groupIndex={column.groupIndex}
                            fixed={column.fixed}
                            fixedPosition={column.fixedPosition}
                            validationRules={column.validationRules}
                        >
                            {
                                (column.columns || []).map((columnDetail) => {
                                    return <Column
                                        key={column.name || column.dataField || 'buttons'}
                                        dataField={columnDetail.dataField}
                                        name={columnDetail.name}
                                        caption={columnDetail.caption}
                                        lookup={columnDetail.lookup}
                                        cellRender={columnDetail.cellRender}
                                        alignment={columnDetail.alignment || 'left'}
                                        cssClass={columnDetail.cssClass}
                                        format={columnDetail.format}
                                        dataType={columnDetail.dataType}
                                        width={columnDetail.width}
                                        type={columnDetail.type}
                                        buttons={columnDetail.buttons}
                                        editorType = {columnDetail.editorType}
                                        editorOptions = {columnDetail.editorOptions}
                                        visible={columnDetail.visible}
                                        sortOrder={columnDetail.sortOrder}
                                        allowEditing={columnDetail.allowEditing}
                                        calculateCellValue={columnDetail.calculateCellValue}
                                        setCellValue={columnDetail.setCellValue}
                                        groupIndex={columnDetail.groupIndex}
                                        validationRules={columnDetail.validationRules}
                                    />
                                })
                            }
                        </Column>
                    })
                }

                <Summary
                    calculateCustomSummary={this.props.calculateCustomSummary}
                    recalculateWhileEditing={true}
                >
                    {
                        (this.props.groupSummary || []).map((groupItem) => {
                            return <GroupItem
                                alignByColumn={groupItem.alignByColumn}
                                column={groupItem.column}
                                customizeText={groupItem.customizeText}
                                displayFormat={groupItem.displayFormat}
                                name={groupItem.name}
                                showInColumn={groupItem.showInColumn}
                                showInGroupFooter={groupItem.showInGroupFooter}
                                skipEmptyValues={groupItem.skipEmptyValues}
                                summaryType={groupItem.summaryType}
                                valueFormat={groupItem.valueFormat}
                            />
                        })
                    }
                    {
                        (this.props.summaryTotalItem || []).map((column) => {
                            return <TotalItem
                                key={column.name}
                                name={column.name}
                                summaryType={column.summaryType}
                                valueFormat={column.valueFormat}
                                displayFormat={column.displayFormat}
                                showInColumn={column.showInColumn}
                                column={column.column}
                                customizeText={column.customizeText}
                            />
                        })
                    }
                </Summary>

                <Editing
                    refreshMode={refreshMode}
                    mode={this.props.editingMode || 'popup'}
                    useIcons={true}
                    // allowAdding={this.props.allowAdding}
                    // allowDeleting={this.props.allowDeleting}
                    // allowUpdating={this.props.allowUpdating}

                    texts={
                        {
                            saveRowChanges: 'Simpan',
                            cancelRowChanges: 'Batal',
                            deleteRow: 'Hapus',
                            editRow: 'Ubah'
                        }
                    }
                >
                    <Popup
                        title={this.props.popupTitle}
                        showTitle={true}
                        width={this.props.popupWidth || 600}
                        height={this.props.popupHeight || 300}
                        dragEnabled={true}
                        resizeEnabled={true}
                    >
                    </Popup>
                    <Form
                        id={'form'}
                        // formData={this.state.focusedRow}
                        showColonAfterLabel={true}
                        labelLocation={this.props.popupFormLabelLocation || 'top'}
                        minColWidth={this.props.popupFormMinColWidth || 300}
                        colCount={this.props.popupFormColCount || 1}
                        items={this.props.formItems}
                    />

                </Editing>

                <ColumnChooser enabled={this.props.ColumnChooser || false} />
                <ColumnFixing enabled={this.props.ColumnFixing || false} />
                <Scrolling showScrollbar="always" />
                {/* <MasterDetail 
                    enabled={this.props.allowEnabledMasterDetail}
                    component={this.props.masterDetailComponent}
                    autoExpandAll={this.props.autoExpandmasterDetail}
                /> */}
            </DataGrid>
        );
    }
}

export default DevExpressDataGrid;
