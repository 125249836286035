import React, { Component } from 'react'
import Form from 'devextreme-react/form'
import { product, iuranType, pesertaAkunStatus } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalActionIuranDataAkunPPIP from './actionDataAkun';
import httpRequest from 'plugin/httprequest';

class FormDataAkun extends Component {
    constructor(props) {
        super(props)

        this.state = {
            statusPeserta: '',
            dataPeserta: {},
            formDataAkun: {
                // productId: 0
            },
        }

        this.columnConfiguration = [
            {
                dataField: 'iuranTypeId',
                caption: 'Jenis Iuran',
                lookup: {
                    dataSource: iuranType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'iuranName'
                }
            },
            {
                dataField: 'efektifDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy',
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'skNo',
                caption: 'No SK Iuran',
                width: '150px',
                alignment: 'right',
            },
            {
                dataField: 'iuranNominal',
                caption: 'Nominal Iuran',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    min: 0
                }
            },
            // {
            //     dataField: 'status',
            //     caption: 'Status'
            // },
        ]

        this.formRefAkun = React.createRef()
        this.formRefPensiun = React.createRef()
        this.modalActionIuranDataAkunPPIPRef = React.createRef()
        this.DataGridAkunIuranRef = React.createRef()

        this.currentData = []
        this.dataIuran = []
        this.pesertaId = 0
        this.statusPeserta = 0

        this.dataAkun = [
            {
                itemType: 'group',
                caption: 'Data Akun',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            value: this.props.product === 'ppmp' ? 1 : 2,
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'productCode', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Product Harus Diisi'
                            },
                        ],
                    },
                    {
                        dataField: 'cardNo',
                        label: {
                            alignment: 'left',
                            text: 'Card No'
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Card No Harus Diisi'
                            },
                            {
                                type: "pattern",
                                pattern: /\(?([0-9])\)?/,
                                message: "Card No harus menggunakan angka"
                            }
                        ],
                    },
                    {
                        dataField: 'akunStatusId',
                        label: {
                            alignment: 'left',
                            text: 'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pesertaAkunStatus(this.props.store),
                            value: 1,
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'description', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'efektifDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Efektif'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy'
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal Efektif Harus Diisi'
                            },
                        ],
                    },
                ]
            },
        ]
    }

    passDataToParent = () => {
        var dataGridIuran = this.DataGridAkunIuranRef.current.getDataSource()
        var dataIuran = []
        for (var value of dataGridIuran) {
            value.participantId = this.state.dataPeserta.participantId
            dataIuran.push(value)
        }

        var data = {
            ...this.state.formDataAkun,
            dataIuran: dataIuran,
            currentDataIuran: this.currentData,
            deletedDataIuran: this.DataGridAkunIuranRef.current.getDeletedIndex(),
        }

        return data
    }

    removeState = () => {
        this.setState({
            formDataAkun: {},
        })
    }

    getStatusPeserta = (status) => {
        this.statusPeserta = status
    }

    reloadData = async (dataPeserta, dataAkun, dataIuran = []) => {
        this.pesertaId = dataPeserta.id
        this.pesertaAkunProdukId = dataAkun.ids || dataAkun.id
        this.dataIuran = dataIuran
        this.setState({
            formDataAkun: dataAkun,
            dataPeserta: dataPeserta
        })
        // this.forceRefreshIuran()
    }

    retrieveData = async () => {
        try {
            if (this.dataIuran.length === 0) {
                if (this.pesertaAkunProdukId) {
                    var loadAPI = `peserta-akun-produk-iurans/getByPesertaIdAndProductId/${this.pesertaAkunProdukId}`
                    var dataIuran = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                } else {
                    var dataIuran = []
                }
            } else {
                var dataIuran = this.dataIuran
            }
        } catch (e) {
            var dataIuran = []
        }

        this.currentData = JSON.parse(JSON.stringify(dataIuran))
        return dataIuran
    }

    showModalActionIuranDataAkunRegistrasiAktif = (data = {}) => {
        this.modalActionIuranDataAkunPPIPRef.current.retrieveData(data)
        this.modalActionIuranDataAkunPPIPRef.current.show()
    }


    editDataIuran = async (data) => {
        try {
            var key = data.id

            if (key) {
                this.DataGridAkunIuranRef.current.push([
                    {
                        type: 'update',
                        data: data,
                        key: key
                    }
                ])
            } else {
                this.DataGridAkunIuranRef.current.push([
                    {
                        type: 'insert',
                        data: data
                    }
                ])
            }


            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'

            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        } catch (e) {
            console.log(e)
        }
    }

    deleteDataIuran = (data) => {
        this.DataGridAkunIuranRef.current.push([
            {
                type: 'remove',
                key: data.id
            }
        ])
    }

    forceRefreshIuran = () => {
        this.DataGridAkunIuranRef.current.forceRefresh(true)
    }

    validate = () => {
        var validateAkun = this.formRefAkun.current.instance.validate()
        var validate = {
            validateAkun: validateAkun,
        }

        return validate
    }

    onToolbarPreparingDataAkun = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'refresh',
                    icon: 'refresh',
                    onClick: (e) => {
                        this.forceRefreshIuran()
                    },
                }
            }
        )
    }

    removeData = () => {
        this.setState({
            formDataAkun: {}
        })
        var dataIuran = this.DataGridAkunIuranRef.current.getDataSource()
        var deletedData = []

        for (var value of dataIuran) {
            deletedData.push({
                type: 'remove',
                key: value.id
            })
        }
        this.DataGridAkunIuranRef.current.push(deletedData)
    }
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            ref={this.formRefAkun}
                            colCount={1}
                            id={'formDataAkun'}
                            formData={this.state.formDataAkun}
                            items={this.dataAkun}
                            height='200px'
                            cssClass="mt-3"
                            scrollingEnabled={true}
                            labelLocation='left'
                            readOnly={this.props.actionType === 'view' ? true : false}
                        />
                    </div>
                </div>

                <DevExpressDataGrid
                    ref={this.DataGridAkunIuranRef}
                    loadAPI='peserta-akun-produk-iurans'
                    insertAPI='peserta-akun-produk-iurans'
                    updateAPI='peserta-akun-produk-iurans'
                    deleteAPI='peserta-akun-produk-iurans'

                    useArraySource={true}
                    ArraySourceData={this.retrieveData}

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={this.props.actionType !== 'view'}
                    allowDeleting={this.props.actionType !== 'view'}
                    allowUpdating={this.props.actionType !== 'view'}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={`Data Akun ${(this.props.product).toUpperCase()}`}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel


                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columnConfiguration} // taruh konfigurasi kolom disini
                    onToolbarPreparing={this.onToolbarPreparingDataAkun}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalActionIuranDataAkunPPIP
                    store={this.props.store}
                    ref={this.modalActionIuranDataAkunPPIPRef}
                    editDataIuran={this.editDataIuran}
                />
            </React.Fragment>
        )
    }
}

export default FormDataAkun
