import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import Form from 'devextreme-react/form';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { ab2str, yyyymmddToDate, cleanQuote, formatDate } from 'plugin/helper';
import XLSX from 'xlsx'

import { FileUploader } from "devextreme-react";
import { gender, agama, statusPegawai, unit,statusKaryawan,grade, months, jabatan, maritalStatus, kelurahans, provinces, cities, kecamatans, banks, product, tax } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';

class UploadPeserta extends Component {
    constructor(props){
        super(props)

        this.fileUploderRef = React.createRef();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = {
            popupVisible: false,
            uploadedData: [],
            // selectedTabIndex: 0,
        }
        
        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()

        this.formItem = {
            useHeader : true
        }

        this.successData = []
        this.errorData = []

        this.sex = []
        this.statusKepegawaian = []
        this.religion = []
        this.jenisKantor = []
        this.unit = []
        this.statusKaryawan = []
        this.grade = []
        this.months = []
        this.jabatan = []
        this.married = []
        this.tax = []
        this.country = []
        this.province = []
        this.city = []
        this.kecamatan = []
        this.kelurahan = []
        this.banks = []
        this.products = []

        this.prevTabIndex = 0
        
        this.checkboxItem = [
            {
                dataField: 'useHeader',
                label: {
                    alignment: 'left',
                    text: 'Use Header'
                },
                editorType: 'dxCheckBox',
            }
        ]
        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'nikPanjang',
                caption: 'NIK Panjang'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap'
            },
            {
                dataField: 'nickName',
                caption: 'Nama Panggilan'
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'statusKepegawaianId',
                caption: 'Status Kepegawaian',
                lookup: {
                    dataSource: statusPegawai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values
                },
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir',
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format : 'dd MMM yyyy'
            },
            {
                dataField: 'religionId',
                caption: 'Agama',
                lookup: {
                    dataSource: agama(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'unitKerjaId',
                caption: 'Kode Unit Kerja',
                lookup: {
                    dataSource: unit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,

                }
            },
            {
                dataField: 'unitName',
                caption: 'Nama Unit Kerja',
            },
            {
                dataField: 'unitDiatas1',
                caption: 'Unit 1 Tingkat Diatas'
            },
            {
                dataField: 'unitDiatas2',
                caption: 'Unit 2 Tingkat Diatas'
            },
            {
                dataField: 'unitDiatas3',
                caption: 'Unit 3 Tingkat Diatas'
            },
            {
                dataField: 'jenisKantorId',
                caption: 'Jenis Kantor',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Kantor Cabang'
                        },
                        {
                            id: 2,
                            value: 'Kantor Pusat'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,

                }
            },
            {
                dataField: 'statusKaryawanId',
                caption: 'Status Karyawan',
                lookup: {
                    dataSource: statusKaryawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'statusName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,

                }
            },
            {
                dataField: 'tmtStatus',
                caption: 'TMT Status Karyawan',
                dataType: 'date',
                format : 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Diakui Masa Kerja',
                dataType:'date',
                format : 'dd MMM yyyy'
            },
            {
                dataField: 'grade',
                caption: 'Grade',
                lookup: {
                    dataSource: grade(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'gradeName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'tmtGrade',
                caption: 'TMT Grade Setingkat',
                dataType:'date',
                format : 'dd MMM yyyy'
            },
            {
                dataField: 'mkTahunGrade',
                caption: 'MK Tahun Grade'
            },
            {
                dataField: 'mkBulanGrade',
                caption: 'MK Bulan Grade',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                }
            },
            {
                dataField: 'jabatanGolongan',
                caption: 'Jabatan Golongan',
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan Name',
            },
            {
                dataField: 'jabatanTanggal',
                caption: 'TMT Jabatan',
                dataType: 'date'
            },
            {
                dataField: 'penugasanGrade',
                caption: 'Grade Penugasan',
            },
            {
                dataField: 'penugasanJabatanId',
                caption: 'Penugasan Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values'
                }
            },
            {
                dataField: 'penugasanUnitId',
                caption: 'Penugasan Unit',
                lookup: {
                    dataSource: unit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'penugasanTanggal',
                caption: 'TMT Penugasan',
                dataType: 'date'
            },
            {
                dataField: 'indexPredikat',
                caption: 'Index Predikat',
            },
            {
                dataField: 'pendidikanId',
                caption: 'Pendidikan',
            },
            {
                dataField: 'pendidikanJurusan',
                caption: 'Nama Jurusan',
            },
            {
                dataField: 'pendidikanLembaga',
                caption: 'Nama Lembaga Pendidikan',
            },
            {
                dataField: 'noSkTerakhir',
                caption: 'No SK Terakhir',
            },
            {
                dataField: 'tanggalBerhenti',
                caption: 'Tanggal Berhenti',
                dataType: 'date'
            },
            {
                dataField: 'mariedId',
                caption: 'Status Perkawinan',
                lookup: {
                    dataSource: maritalStatus(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'statusKeluarga',
                caption: 'Status Keluarga',
            },
            {
                dataField: 'statusPajakId',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: tax(this.props.store),
                    valueExpr: 'shortDesc', // contains the same values as the "statusId" field provides
                    displayExpr: 'shortDesc', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'npwp',
                caption: 'No NPWP',
            },
            {
                dataField: 'statusTrt',
                caption: 'Status TRT',
            },
            {
                dataField: 'identityNo',
                caption: 'No KTP',
            },
            {
                dataField: 'noKk',
                caption: 'No KK',
            },
            {
                dataField: 'domicileAddress1',
                caption: 'Alamat 1',
            },
            {
                dataField: 'domicileKelurahanId',
                caption: 'Alamat Kelurahan',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileKecamatanId',
                caption: 'Alamat Kecamatan',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileCityId',
                caption: 'Alamat Kota/Kabupaten',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'domicileProvinceId',
                caption: 'Alamat Provinsi',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityAddress1',
                caption: 'Alamat 1',
            },
            {
                dataField: 'identityKelurahanId',
                caption: 'Alamat Kelurahan',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityKecamatanId',
                caption: 'Alamat Kecamatan',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityCityId',
                caption: 'Alamat Kota/Kabupaten',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityProvinceId',
                caption: 'Alamat Provinsi',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'mobileNo',
                caption: 'Telepon Rumah Tinggal',
            },
            {
                dataField: 'mobilePhone',
                caption: 'Nomor Hp 1',
            },
            {
                dataField: 'mobilePhone2',
                caption: 'Nomor HP 2',
            },
            {
                dataField: 'email',
                caption: 'Email 1',
            },
            {
                dataField: 'email2',
                caption: 'Email 2',
            },
            {
                dataField: 'noBpjsTk',
                caption: 'No BPJS TK',
            },
            {
                dataField: 'rekeningNo',
                caption: 'No Rekening',
            },
            {
                dataField: 'rekeningBankId',
                caption: 'Nama Rekening',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'gelarDepan',
                caption: 'Gelar Didepan',
            },
            {
                dataField: 'gelarBelakang',
                caption: 'Gelar Dibelakang',
            },
            {
                dataField: 'usia',
                caption: 'Usia',
            },
            {
                dataField: 'namaAtasan',
                caption: 'Nama Atasan',
            },
            {
                dataField: 'statusFoto',
                caption: 'Status Foto',
            },
            {
                dataField: 'kodeMis',
                caption: 'Kode MIS',
            },
            {
                dataField: 'noBpjsKesehatan',
                caption: 'Nomor BPJS Kesehatan',
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
                dataType : 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'upnp',
                caption: 'UPNP',
                dataType : 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'thp',
                caption: 'Take Home Pay',
                dataType : 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'productId',
                caption: 'Jenis Kepesertaan Program Pensiun',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'skPensiunHenti',
                caption: 'No SK Henti',
            },
            {
                dataField: 'skPensiunDateHenti',
                caption: 'Tanggal SK Henti',
                dataType: 'date'
            },
            {
                dataField: 'pemberhentianEfektifDate',
                caption: 'Tanggal SK Henti',
                dataType: 'date'
            },
            {
                dataField: 'skPensiunDate',
                caption: 'SK Pensiun Date',
                dataType: 'date'
            },
            {
                dataField: 'skPensiunNo',
                caption: 'SK Pensiun No',
            },
            {
                dataField: 'pensiunEfektifDate',
                caption: 'Tanggal Mulai Pensiun',
            },
            {
                dataField: 'stppp',
                caption: 'STPPP',
            },
            {
                dataField: 'daftarPenerimaMpDate',
                caption: 'Tanggal Surat Daftar Calon Penerima Pensiun',
                dataType: 'date'
            },
            {
                dataField: 'mkTahunSeluruh',
                caption: 'MK Tahun Keseluruhan',
                dataType: 'number'
            },
            {
                dataField: 'mkBulanSeluruh',
                caption: 'MK Tahun Keseluruhan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'pembayaranMpBankId',
                caption: 'Bank Rekening Pembayaran Pensiun (Berdasarkan DCPP)',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'pembayaranMpRekBankId',
                caption: 'Nomor Rekening Pembayaran (Berdasarkan DJPP)',
                // lookup: {
                //     dataSource: banks(this.props.store),
                //     valueExpr: 'id', // contains the same values as the "statusId" field provides
                //     displayExpr: 'bankName', // provides display values'
                //     searchEnabled: true,
                //     deferRendering: false,
                // },
            },
            {
                dataField: 'dcppAlamat',
                caption: 'Alamat Pensiun DCPP',
            },
            {
                dataField: 'dcppAddress1',
                caption: 'Alamat 1',
            },
            {
                dataField: 'dcppKelurahanId',
                caption: 'Alamat Kelurahan',
                lookup: {
                    dataSource: kelurahans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kelurahanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppKecamatanId',
                caption: 'Alamat Kecamatan',
                lookup: {
                    dataSource: kecamatans(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kecamatanName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppCityId',
                caption: 'Alamat Kota/Kabupaten',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppProvinceId',
                caption: 'Alamat Provinsi',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dcppKewarganegaraan',
                caption: 'Kewarganegaraan',
            },
        ]

        this.TabDataSource = [
            {
              id: 0,
              text: "Data Peserta Baru"
            },
            {
              id: 1,
              text: "Data Peserta Pengkinian"
            }
        ];

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
              },
              {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Batal",
                  onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
              }
        ]
    }
    
    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    retrieveStaticData = async() => {
        try {
            if(this.sex.length === 0){
                var getSex = [
                    {
                        id: 'Perempuan',
                        value: 'Perempuan'
                    },
                    {
                        id: 'Laki_Laki',
                        value: 'Laki Laki'
                    },
                ]
                this.sex = getSex
            }

            if(this.statusKepegawaian.length === 0){
                var loadAPIStatusKepegawaian = 'status-kepegawaians'
                var getStatusKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIStatusKepegawaian, 'GET')
                this.statusKepegawaian = getStatusKepegawaian
            }
            
            if(this.religion.length === 0){
                var getReligion = [
                    {
                        id: 1,
                        value: 'Islam'
                    },
                    {
                        id: 2,
                        value: 'Kristen'
                    },
                    {
                        id: 3,
                        value: 'Katholik'
                    },
                    {
                        id: 4,
                        value: 'Buddha'
                    },
                    {
                        id: 5,
                        value: 'Hindu'
                    },
                    {
                        id: 6,
                        value: 'Konghucu'
                    },
                    {
                        id: 7,
                        value: 'Lainnya'
                    },
                ]
                this.religion = getReligion
            }

            if(this.jenisKantor.length === 0){
                var getJenisKantor = [
                    {
                        id: 1,
                        value: 'Kantor Cabang'
                    },
                    {
                        id: 2,
                        value: 'Kantor Pusat'
                    },
                ]
                this.jenisKantor = getJenisKantor
            }
            
            if(this.unit.length === 0){
                var loadAPIUnitKerja = 'units'
                var getUnitKerja = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIUnitKerja, 'GET')
                this.unit = getUnitKerja
            }
            
            if(this.statusKaryawan.length === 0){
                var loadAPIStatusKaryawan = 'status-karyawans'
                var getStatusKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIStatusKaryawan, 'GET')
                this.statusKaryawan = getStatusKaryawan
            }
            
            if(this.grade.length === 0){
                var loadAPIGrade = 'grades'
                var getGrade = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPIGrade, 'GET')
                this.grade = getGrade

            }
            
            if(this.months.length === 0){
                var getmonth = [
                    {
                        id: 1,
                        value: 'Januari'
                    },
                    {
                        id: 2,
                        value: 'Februari'
                    },
                    {
                        id: 3,
                        value: 'Maret'
                    },
                    {
                        id: 4,
                        value: 'April'
                    },
                    {
                        id: 5,
                        value: 'Mei'
                    },
                    {
                        id: 6,
                        value: 'Juni'
                    },
                    {
                        id: 7,
                        value: 'Juli'
                    },
                    {
                        id: 8,
                        value: 'Agustus'
                    },
                    {
                        id: 9,
                        value: 'September'
                    },
                    {
                        id: 10,
                        value: 'Oktober'
                    },
                    {
                        id: 11,
                        value: 'November'
                    },
                    {
                        id: 12,
                        value: 'Desember'
                    },
                ]
                this.months = getmonth
            }
            
            if(this.jabatan.length === 0){
                var loadAPIJabatan = 'jabatans'
                var getJabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPIJabatan, 'GET')
                this.jabatan = getJabatan
            }
            
            if(this.married.length === 0){
                var getMarried = [
                    {
                        id: "1",
                        value: 'Belum Kawin'
                    },
                    {
                        id: "2",
                        value: 'Kawin'
                    },
                    {
                        id: "3",
                        value: 'Cerai Hidup'
                    },
                    {
                        id: "4",
                        value: 'Cerai Mati'
                    },
                ]
                this.married = getMarried
            }
            
            if(this.tax.length === 0){
                var loadAPITax = 'taxes'
                var getTax = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store, loadAPITax, 'GET')
                this.tax = getTax
            }
            
            if(this.country.length === 0){
                var loadAPICountry = 'countries'
                var getCountry = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPICountry, 'GET')
                this.country = getCountry
            }
            
            if(this.province.length === 0){
                var loadAPIProvince = 'provinces'
                var getProvince = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIProvince, 'GET')
                this.province = getProvince
            }
            
            if(this.city.length === 0){
                var loadAPICity = 'cities'
                var getCity = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPICity, 'GET')
                this.city = getCity
            }
            
            if(this.kecamatan.length === 0){
                var loadAPIKecamatan = 'kecamatans'
                var getKecamatan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKecamatan, 'GET')
                this.kecamatan = getKecamatan
            }
            
            if(this.kelurahan.length === 0){
                var loadAPIKelurahan = 'kelurahans'
                var getKelurahan = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIKelurahan, 'GET')
                this.kelurahan = getKelurahan
            }
            
            if(this.banks.length === 0){
                var loadAPIBank = 'banks'
                var getBank = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIBank, 'GET')
                this.banks = getBank
            }
            
            if(this.products.length === 0){
                var loadAPIProduct = 'products'
                var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIProduct, 'GET')
                this.products = getProduct
            }

        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    onUploadStarted = async(e) => {
        await this.retrieveStaticData()

        var sex = this.sex
        var statusKepegawaian = this.statusKepegawaian
        var religion = this.religion
        var unit = this.unit
        var statusKaryawan = this.statusKaryawan
        var grade = this.grade
        var months = this.months
        var jabatan = this.jabatan
        var married = this.married
        var tax = this.tax
        var country = this.country
        var province = this.province
        var city = this.city
        var kecamatan = this.kecamatan
        var kelurahan = this.kelurahan
        var banks = this.banks
        var products = this.products
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if(fileType === 'xls' || fileType === 'xlsx' ){
                    var workbook = XLSX.read(dataArrayBuffer, {type: 'array'});
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)
                    
                    data = dataJSON.map(value => Object.values(value))
                }

                if(fileType === 'csv'){
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                       data = data.filter(value => 
                            {
                                regex.lastIndex = 0
                                return regex.test(value)
                            }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)
                            
                            if (!(this.formItem.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)
    
                                var splitRes = splitRes.map(splitVal => splitVal.trim())
        
                                var splitRes = splitRes.map(splitVal => {
                                    if(String(splitVal)){
                                        return String(splitVal)
                                    }else{
                                        return splitVal
                                    }
                                })
    
                                return splitRes
                            }
                        })
                    }else{
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                var errorMessage = []
                var pesertaId = 0
                // var reffId = data.map(value => value[19])
                // let getAvailableId = `reksadana-transactions/get-id-unit-allocation/strIdList/${reffId.join()}`

                // var availableId= await httpRequest(
                //     process.env.REACT_APP_BACKEND_CORE,
                //     this.props.store,
                //     getAvailableId,
                //     "GET"
                // );

                for(var uploadedData of data){
                    if(Array.isArray(uploadedData)){
                        errorMessage = []
                        
                        // if (!availableId.includes(parseInt(uploadedData[19]))) {
                        //     errorMessage.push('This transaction already settled')
                        // }

                        try {
                            var loadAPIByNik = `pesertas/getByNik/${uploadedData[0]}`
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store,loadAPIByNik,'GET')

                            pesertaId = response.id
                            errorMessage.push('Data NIK Peserta Telah Tercantum!')
                        } catch (e) {
                        }
                        if(!sex.find(value => value.id === uploadedData[4])){
                            // uploadedData[5] = 0
                            errorMessage.push('Data Jenis Kelamin tidak ada!')
                        }
                        // if(!statusKepegawaian.find(value => value.id == uploadedData[5])){
                        //     // uploadedData[5] = 0
                        //     errorMessage.push('Data Status Kepegawaian tidak ada!')
                        // }
    
                        // if(!religion.find(value => value.id == uploadedData[8])){
                        //     // uploadedData[8] = 0
                        //     errorMessage.push('Data Agama tidak ada!')
                        // }
    
                        // if(!unit.find(value => value.id == uploadedData[9])){
                        //     // uploadedData[9] = 0
                        //     errorMessage.push('Data Unit tidak ada!')
                        // }
    
                        // if(!statusKaryawan.find(value => value.id == uploadedData[15])){
                        //     // uploadedData[15] = 0
                        //     errorMessage.push('Data Status Karyawan tidak ada!')
                        // }
    
                        // if(!grade.find(value => value.id == uploadedData[18])){
                        //     // uploadedData[18] = 0
                        //     errorMessage.push('Data Grade tidak ada!')
                        // }
    
                        // if(!months.find(value => value.id == uploadedData[21])){
                        //     // uploadedData[21] = 0
                        //     errorMessage.push('Data Unit tidak ada!')
                        // }
    
                        // if(!jabatan.find(value => value.id == uploadedData[22])){
                        //     // uploadedData[22] = 0
                        //     errorMessage.push('Data Golongan Jabatan tidak ada!')
                        // }
    
                        // if(!jabatan.find(value => value.id == uploadedData[26])){
                        //     // uploadedData[26] = 0
                        //     errorMessage.push('Data Penugasan Jabatan tidak ada!')
                        // }
    
                        // if(!unit.find(value => value.id == uploadedData[27])){
                        //     // uploadedData[27] = 0
                        //     errorMessage.push('Data Unit tidak ada!')
                        // }
    
                        // if(!married.find(value => value.id == uploadedData[35])){
                        //     // uploadedData[35] = 0
                        //     errorMessage.push('Data Status Kawin tidak ada!')
                        // }
                        // if(!tax.find(value => value.shortCode == uploadedData[37])){
                        //     // uploadedData[37] = 0
                        //     errorMessage.push('Data Status Pajak tidak ada!')
                        // }
    
                        // // DOMISILI
                        // if(!province.find(value => value.id == uploadedData[46])){
                        //     // uploadedData[46] = 0
                        //     errorMessage.push('Data Domisili Provinsi tidak ada!')
                        // }
                        // if(!city.find(value => value.id == uploadedData[45])){
                        //     // uploadedData[45] = 0
                        //     errorMessage.push('Data Domisili Kota tidak ada!')
                        // }
                        // if(!kecamatan.find(value => value.id == uploadedData[44])){
                        //     // uploadedData[44] = 0
                        //     errorMessage.push('Data Domisili Kecamatan tidak ada!')
                        // }
                        // if(!kelurahan.find(value => value.id == uploadedData[43])){
                        //     // uploadedData[43] = 0
                        //     errorMessage.push('Data Domisili Kelurahan tidak ada!')
                        // }
    
                        // // IDENTITY
                        // if(!province.find(value => value.id == uploadedData[51])){
                        //     // uploadedData[51] = 0
                        //     errorMessage.push('Data Identitas Provinsi tidak ada!')
                        // }
                        // if(!city.find(value => value.id == uploadedData[50])){
                        //     // uploadedData[50] = 0
                        //     errorMessage.push('Data Identitas Kota tidak ada!')
                        // }
                        // if(!kecamatan.find(value => value.id == uploadedData[49])){
                        //     // uploadedData[49] = 0
                        //     errorMessage.push('Data Identitas Kecamatan tidak ada!')
                        // }
                        // if(!kelurahan.find(value => value.id == uploadedData[48])){
                        //     // uploadedData[48] = 0
                        //     errorMessage.push('Data Identitas Kelurahan tidak ada!')
                        // }
    
                        // // DCPP
                        // if(!country.find(value => value.id == uploadedData[89])){
                        //     // uploadedData[89] = 0
                        //     errorMessage.push('Data DCPP Negara tidak ada!')
                        // }
                        // if(!province.find(value => value.id == uploadedData[88])){
                        //     // uploadedData[88] = 0
                        //     errorMessage.push('Data DCPP Provinsi tidak ada!')
                        // }
                        // if(!city.find(value => value.id == uploadedData[87])){
                        //     // uploadedData[87] = 0
                        //     errorMessage.push('Data DCPP Kota tidak ada!')
                        // }
                        // if(!kecamatan.find(value => value.id == uploadedData[86])){
                        //     // uploadedData[86] = 0
                        //     errorMessage.push('Data DCPP Kecamatan tidak ada!')
                        // }
                        // if(!kelurahan.find(value => value.id == uploadedData[85])){
                        //     // uploadedData[85] = 0
                        //     errorMessage.push('Data Identitas Kelurahan tidak ada!')
                        // }
    
                        // if(!banks.find(value => value.bankCode == uploadedData[60])){
                        //     // uploadedData[60] = 0
                        //     errorMessage.push('Data Bank tidak ada!')
                        // }
                        // if(!products.find(value => value.productCode == uploadedData[70])){
                        //     // uploadedData[70] = 0
                        //     errorMessage.push('Data Produk tidak ada!')
                        // }
                        // if(!months.find(value => value.id == uploadedData[80])){
                        //     // uploadedData[80] = 0
                        //     errorMessage.push('Data MK Bulan tidak ada!')
                        // }
                        var findProduct = products.find(value => value.productCode === uploadedData[70])
                        var productId = findProduct ? findProduct.id : 0
                        if (errorMessage.length > 0) {
                            errorData.push({
                                errorMessage: errorMessage,
                                id: pesertaId,
                                nik: uploadedData[1],
                                nikPanjang : uploadedData[0],
                                fullName: uploadedData[2],
                                nickName: uploadedData[3],
                                sex: uploadedData[4],
                                statusKepegawaianId: uploadedData[5],
                                birthPlace: uploadedData[6],
                                birthDate: yyyymmddToDate(uploadedData[7]),
                                religionId: uploadedData[8],
                                unitKerjaId: uploadedData[9],
                                unitName: uploadedData[10],
                                unitDiatas1: uploadedData[11],
                                unitDiatas2: uploadedData[12],
                                unitDiatas3: uploadedData[13],
                                jenisKantorId: uploadedData[14],
                                statusKaryawanId: uploadedData[15],
                                tmtStatus: yyyymmddToDate(uploadedData[16]),
                                tanggalEfektif: yyyymmddToDate(uploadedData[17]),
                                grade: uploadedData[18],
                                tmtGrade: yyyymmddToDate(uploadedData[19]),
                                mkTahunGrade: uploadedData[20],
                                mkBulanGrade: uploadedData[21],
                                jabatanGolongan: uploadedData[22],
                                jabatanName: uploadedData[23],
                                jabatanTanggal: yyyymmddToDate(uploadedData[24]),
                                penugasanGrade: uploadedData[25],
                                penugasanJabatanId: uploadedData[26],
                                penugasanUnitId: uploadedData[27],
                                penugasanTanggal: yyyymmddToDate(uploadedData[28]),
                                indexPredikat: uploadedData[29],
                                pendidikanId: uploadedData[30],
                                pendidikanJurusan: uploadedData[31],
                                pendidikanLembaga: uploadedData[32],
                                noSkTerakhir: uploadedData[33],
                                tanggalBerhenti: yyyymmddToDate(uploadedData[34]),
                                mariedId: uploadedData[35],
                                statusKeluarga: uploadedData[36],
                                statusPajakId: uploadedData[37],
                                npwp: uploadedData[38],
                                statusTrt: uploadedData[39],
                                identityNo: uploadedData[40],
                                noKk: uploadedData[41],
                                domicileAddress1: uploadedData[42],
                                domicileKelurahanId: uploadedData[43],
                                domicileKecamatanId: uploadedData[44],
                                domicileCityId: uploadedData[45],
                                domicileProvinceId: uploadedData[46],
                                identityAddress1: uploadedData[47],
                                identityKelurahanId: uploadedData[48],
                                identityKecamatanId: uploadedData[49],
                                identityCityId: uploadedData[50],
                                identityProvinceId: uploadedData[51],
                                // mobileNo: uploadedData[53],
                                mobilePhone: uploadedData[53],
                                mobilePhone2: uploadedData[54],
                                email: uploadedData[55],
                                email2: uploadedData[56],
                                noBpjsTk: uploadedData[57],
                                rekeningNo: uploadedData[58],
                                rekeningBankId: uploadedData[59],
                                gelarDepan: uploadedData[60],
                                gelarBelakang: uploadedData[61],
                                // : uploadedData[62],
                                usia: uploadedData[62],
                                namaAtasan: uploadedData[63],
                                statusFoto: uploadedData[64],
                                kodeMis: uploadedData[65],
                                noBpjsKesehatan: uploadedData[66],
                                phdp: uploadedData[67],
                                upnp: uploadedData[68],
                                thp: uploadedData[69],
                                productId: productId,
                                skPensiunDateHenti: uploadedData[71],
                                skPensiunTanggal: uploadedData[72],
                                pemberhentianEfektifDate: uploadedData[73],
                                skPensiunDate: uploadedData[74],
                                skPensiunNo: uploadedData[75],
                                pensiunEfektifDate: uploadedData[76],
                                stppp: uploadedData[77],
                                daftarPenerimaMpDate: uploadedData[78],
                                mkTahunSeluruh: uploadedData[79],
                                mkBulanSeluruh: uploadedData[80],
                                pembayaranMpBankId: uploadedData[81],
                                pembayaranMpRekBankId: uploadedData[82],
                                dcppAlamat: uploadedData[83],
                                dcppAddress1: uploadedData[84],
                                dcppKelurahanId: uploadedData[85],
                                dcppKecamatanId: uploadedData[86],
                                dcppCityId: uploadedData[87],
                                dcppProvinceId: uploadedData[88],
                                dcppKewarganegaraan: uploadedData[89],
                            })
                        }else{
                            successData.push({
                                nikPanjang : uploadedData[0],
                                nik: uploadedData[1],
                                fullName: uploadedData[2],
                                nickName: uploadedData[3],
                                sex: uploadedData[4],
                                statusKepegawaianId: uploadedData[5],
                                birthPlace: uploadedData[6],
                                birthDate: yyyymmddToDate(uploadedData[7]),
                                religionId: uploadedData[8],
                                unitKerjaId: uploadedData[9],
                                unitName: uploadedData[10],
                                unitDiatas1: uploadedData[11],
                                unitDiatas2: uploadedData[12],
                                unitDiatas3: uploadedData[13],
                                jenisKantorId: uploadedData[14],
                                statusKaryawanId: uploadedData[15],
                                tmtStatus: yyyymmddToDate(uploadedData[16]),
                                tanggalEfektif: yyyymmddToDate(uploadedData[17]),
                                grade: uploadedData[18],
                                tmtGrade: yyyymmddToDate(uploadedData[19]),
                                mkTahunGrade: uploadedData[20],
                                mkBulanGrade: uploadedData[21],
                                jabatanGolongan: uploadedData[22],
                                jabatanName: uploadedData[23],
                                jabatanTanggal: yyyymmddToDate(uploadedData[24]),
                                penugasanGrade: uploadedData[25],
                                penugasanJabatanId: uploadedData[26],
                                penugasanUnitId: uploadedData[27],
                                penugasanTanggal: yyyymmddToDate(uploadedData[28]),
                                indexPredikat: uploadedData[29],
                                pendidikanId: uploadedData[30],
                                pendidikanJurusan: uploadedData[31],
                                pendidikanLembaga: uploadedData[32],
                                noSkTerakhir: uploadedData[33],
                                tanggalBerhenti: yyyymmddToDate(uploadedData[34]),
                                mariedId: uploadedData[35],
                                statusKeluarga: uploadedData[36],
                                statusPajakId: uploadedData[37],
                                npwp: uploadedData[38],
                                statusTrt: uploadedData[39],
                                identityNo: uploadedData[40],
                                noKk: uploadedData[41],
                                domicileAddress1: uploadedData[42],
                                domicileKelurahanId: uploadedData[43],
                                domicileKecamatanId: uploadedData[44],
                                domicileCityId: uploadedData[45],
                                domicileProvinceId: uploadedData[46],
                                identityAddress1: uploadedData[47],
                                identityKelurahanId: uploadedData[48],
                                identityKecamatanId: uploadedData[49],
                                identityCityId: uploadedData[50],
                                identityProvinceId: uploadedData[51],
                                // mobileNo: uploadedData[53],
                                mobilePhone: uploadedData[53],
                                mobilePhone2: uploadedData[54],
                                email: uploadedData[55],
                                email2: uploadedData[56],
                                noBpjsTk: uploadedData[57],
                                rekeningNo: uploadedData[58],
                                rekeningBankId: uploadedData[59],
                                gelarDepan: uploadedData[60],
                                gelarBelakang: uploadedData[61],
                                // : uploadedData[62],
                                usia: uploadedData[62],
                                namaAtasan: uploadedData[63],
                                statusFoto: uploadedData[64],
                                kodeMis: uploadedData[65],
                                noBpjsKesehatan: uploadedData[66],
                                phdp: uploadedData[67],
                                upnp: uploadedData[68],
                                thp: uploadedData[69],
                                productId: productId,
                                skPensiunDateHenti: uploadedData[71],
                                skPensiunTanggal: uploadedData[72],
                                pemberhentianEfektifDate: uploadedData[73],
                                skPensiunDate: uploadedData[74],
                                skPensiunNo: uploadedData[75],
                                pensiunEfektifDate: uploadedData[76],
                                stppp: uploadedData[77],
                                daftarPenerimaMpDate: uploadedData[78],
                                mkTahunSeluruh: uploadedData[79],
                                mkBulanSeluruh: uploadedData[80],
                                pembayaranMpBankId: uploadedData[81],
                                pembayaranMpRekBankId: uploadedData[82],
                                dcppAlamat: uploadedData[83],
                                dcppAddress1: uploadedData[84],
                                dcppKelurahanId: uploadedData[85],
                                dcppKecamatanId: uploadedData[86],
                                dcppCityId: uploadedData[87],
                                dcppProvinceId: uploadedData[88],
                                dcppKewarganegaraan: uploadedData[89],
                            })
                        }
                        
                        if(uploadedData.length !== 90){
                            let type = "error";
                            let text = "Format File Salah!";

                            notify(
                                {
                                message: text,
                                width: "AUTO",
                                shading: true,
                                position: { at: "center", my: "center", of: window }
                                },
                                type,
                                1000
                            );
                            // return false
                        }

                        
                    }
                }

                if(errorData.length > 0){
                    // let type = "error";
                    // let text = "Ada beberapa data unggahan yang gagal diunggah, harap periksa tab data unggahan yang gagal";
                    
                    this.errorData = errorData
                    this.editDataGrid(this.errorData,'error')
                    // notify(
                    //     {
                    //     message: text,
                    //     width: "AUTO",
                    //     shading: true,
                    //     position: { at: "center", my: "center", of: window }
                    //     },
                    //     type,
                    //     1000
                    // );
                }

                this.successData = successData
                this.editDataGrid(this.successData, 'success')
            }
            reader.readAsArrayBuffer(files);
        }   
    }
    
    editDataGrid = async (data, type) => {
        if(type == 'success'){
            var dataGrid = this.dataGridSesuaiRef
        }else{
            var dataGrid = this.dataGridTakSesuaiRef
        }
        var editData = []
        await this.deleteDataGrid(dataGrid)
        for (var value of data){
            try{
            editData.push(
                {
                    type: 'insert',
                    data : value
                }
            )
            
            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'
            
            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
            }catch(e){
                console.log(e)
            }
        }
        dataGrid.current.push(editData)
    }
    
    deleteDataGrid = (dataGrid) => {
        var data = dataGrid.current.getDataSource()
        var deleteData = []
        for(var value of data){
            deleteData.push(
                {
                    type: 'remove',
                    key : value.nik
                }
            )
        }
        dataGrid.current.push(deleteData)
    }
    
    submitData = async() => {
        var dataSesuai = this.dataGridSesuaiRef.current.getDataSource()
        var dataTidakSesuai = this.dataGridTakSesuaiRef.current.getDataSource()

        var loadAPIGlobalParamIuranPeserta = 'global-parameters/get-by-paramCode/2%20INPA'
        var globalParamIuranPeserta = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIGlobalParamIuranPeserta, 'GET')

        var loadAPIGlobalParamIuranPK = 'global-parameters/get-by-paramCode/2%20INPK'
        var globalParamIuranPK = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIGlobalParamIuranPK, 'GET')

        var loadAPIGlobalParamIuranMLPeserta = 'global-parameters/get-by-paramCode/IMLPA'
        var globalParamIuranMLPeserta = await httpRequest(process.env.REACT_APP_BACKEND,this.props.store, loadAPIGlobalParamIuranMLPeserta, 'GET')

        for(var value of dataSesuai){
            var statusPajakId = this.tax.find(taxes => taxes.shortCode == value.statusPajakId)
            var jenisKantorId = this.jenisKantor.find(jenis => jenis.value == value.jenisKantorId)
            var dataPersonal = {
                fullName: value.fullName,
                nickName: value.nickName,
                sex : value.sex,
                birthPlace : value.birthPlace,
                birthDate : value.birthDate,
                religionId : value.religionId,
                pendidikanId : value.pendidikanId,
                pendidikanJurusan : value.pendidikanJurusan,
                pendidikanLembaga : value.pendidikanLembaga,
                mariedId : value.mariedId,
                statusKeluarga : value.statusKeluarga,
                statusPajakId : statusPajakId ? statusPajakId.id : 0,
                nik : value.nikPanjang,
                npwp : value.npwp,
                identityNo : value.identityNo,
                noKk : value.noKk,
                domicileAddress1 : value.domicileAddress1,
                domicileCountryId : 130,
                domicileKelurahanId : value.domicileKelurahanId,
                domicileKecamatanId : value.domicileKecamatanId,
                domicileCityId : value.domicileCityId,
                domicileProvinceId : value.domicileProvinceId,
                identityAddress1 : value.identityAddress1,
                identityCountryId : 130,
                identityKelurahanId : value.identityKelurahanId,
                identityKecamatanId : value.identityKecamatanId,
                identityCityId : value.domicileKelurahanId,
                identityProvinceId : value.identityProvinceId,
                mobileNo : value.mobileNo,
                mobilePhone : value.mobilePhone,
                mobilePhone2 : value.mobilePhone2,
                email : value.email,
                email2 : value.email2,
                noBpjsTk : value.noBpjsTk,
                rekeningNo : value.rekeningNo,
                // rekeningBankId : value.rekeningBankId,
                gelarDepan : value.gelarDepan,
                gelarBelakang : value.gelarBelakang,
                usia : value.usia,
                statusFoto : value.statusFoto,
                statusPeserta: 1,
                noBpjsKesehatan : value.noBpjsKesehatan,
                participantId : 1,
                // unitId: value.unitKerjaId,
            }
            var dataKepegawaian = {
                pesertaId: 0,
                nikPanjang: value.nikPanjang,
                nik: value.nik,
                // statusKepegawaianId: value.statusKepegawaianId == 0 ? '' : value.statusKepegawaianId,
                // unitKerjaId: value.unitKerjaId,
                unitName: value.unitName,
                unitDiatas1: value.unitDiatas1,
                unitDiatas2: value.unitDiatas2,
                unitDiatas3: value.unitDiatas3,
                // jenisKantorId: jenisKantorId ? jenisKantorId.id : 0,
                // statusKaryawanId: value.statusKaryawanId === 0 ? '' : value.statusKaryawanId,
                tmtStatus: value.tmtStatus,
                tanggalEfektif: value.tanggalEfektif,
                grade: value.grade,
                tmtGrade: value.tmtGrade,
                mkTahunGrade: value.mkTahunGrade,
                mkBulanGrade: value.mkBulanGrade,
                jabatanGolongan: value.jabatanGolongan,
                jabatanName: value.jabatanName,
                jabatanTanggal: value.jabatanTanggal,
                penugasanGrade: value.penugasanGrade,
                // penugasanJabatanId: value.penugasanJabatanId,
                // penugasanUnitId: value.penugasanUnitId,
                penugasanTanggal: value.penugasanTanggal,
                indexPredikat: value.indexPredikat,
                noSkTerakhir: value.noSkTerakhir,
                tanggalBerhenti: value.tanggalBerhenti,
                namaAtasan: value.namaAtasan,
                kodeMis: value.kodeMis,
                phdp: value.phdp,
                upnp: value.upnp,
                thp: value.thp,
            }

            var dataAkun = {
                pesertaId: 0,
                efektifDate : formatDate(new Date()),
                productId : value.productId,
                iuranNormalPeserta: value.phdp * (globalParamIuranPeserta.floatValue) / 100,
                iuranNormalPk: value.phdp * (globalParamIuranPK.floatValue) / 100,
                akunStatusId: 1,
                skPensiunDateHenti : value.skPensiunDateHenti,
                skPensiunTanggal : value.skPensiunTanggal,
                pemberhentianEfektifDate : value.pemberhentianEfektifDate,
                pensiunDate : value.skPensiunDate,
                skPensiunNo : value.skPensiunNo,
                pensiunEfektifDate : value.pensiunEfektifDate,
                stppp : value.stppp,
                daftarPenerimaMpDate : value.daftarPenerimaMpDate,
                mkTahunSeluruh : value.mkTahunSeluruh,
                mkBulanSeluruh : value.mkBulanSeluruh,
                // pembayaranMpBankId : value.pembayaranMpBankId,
                // pembayaranMpRekBankId : value.pembayaranMpRekBankId,
                dcppAlamat : value.dcppAlamat,
                dcppAddress1 : value.dcppAddress1,
                dcppKelurahanId : value.dcppKelurahanId,
                dcppKecamatanId : value.dcppKecamatanId,
                dcppCityId : value.dcppCityId,
                dcppProvinceId : value.dcppProvinceId,
                dcppKewarganegaraan : value.dcppKewarganegaraan,
            }

            var dataIuran = [
                {
                    pesertaAkunProdukId: 0,
                    iuranTypeId: 1,
                    productId: value.productId,
                    iuranNominal : value.phdp * (globalParamIuranPeserta.floatValue) / 100,
                    efektifDate: formatDate(new Date()),
                    skNo : value.noSkTerakhir
                },
                {
                    pesertaAkunProdukId: 0,
                    iuranTypeId: 3,
                    productId: value.productId,
                    iuranNominal : value.phdp * (globalParamIuranPK.floatValue) / 100,
                    efektifDate: formatDate(new Date()),
                    skNo : value.noSkTerakhir
                },
                {
                    pesertaAkunProdukId: 0,
                    iuranTypeId: 2,
                    productId: value.productId,
                    iuranNominal : value.phdp * (globalParamIuranMLPeserta.floatValue) / 100,
                    efektifDate: formatDate(new Date()),
                    skNo : value.noSkTerakhir
                },
            ]
            try {
                var insertAPIPersonal = 'pesertas'                    
                var responsePersonal = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIPersonal, 'POST', {
                        values:dataPersonal
                    }
                )
                
                if(responsePersonal){
                    dataKepegawaian.pesertaId = responsePersonal.id
                    var insertAPIKepegawaian = 'peserta-kepegawaians'                    
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIKepegawaian, 'POST', {
                            values:dataKepegawaian
                        }
                    )
                    
                    if(value.productId){
                        dataAkun.akunStatusId = 1
                        dataAkun.pesertaId = responsePersonal.id
                        dataAkun.cardNo = responsePersonal.id + value.productId
                        var insertAPIAkun = 'kepersertaan-products'                    
                        var responseProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIAkun, 'POST', {
                                values:dataAkun
                            }
                        )
                    }

                    if(responseProduct){
                        for(var value of dataIuran){
                            value.pesertaAkunProdukId = responseProduct.id
                            var insertAPIIuran = 'peserta-akun-produk-iurans'                    
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIIuran, 'POST', {
                                    values:value
                                }
                            )       
                        }
                    }
                }
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }

        }

        if(dataTidakSesuai.length != 0){
            for(var value of dataTidakSesuai){
                var statusPajakId = this.tax.find(taxes => taxes.shortCode == value.statusPajakId)
                var jenisKantorId = this.jenisKantor.find(jenis => jenis.value == value.jenisKantorId)
                var dataPersonal = {
                    id: value.id,
                    fullName: value.fullName,
                    nickName: value.nickName,
                    sex : value.sex,
                    birthPlace : value.birthPlace,
                    birthDate : value.birthDate,
                    religionId : value.religionId,
                    pendidikanId : value.pendidikanId,
                    pendidikanJurusan : value.pendidikanJurusan,
                    pendidikanLembaga : value.pendidikanLembaga,
                    mariedId : value.mariedId,
                    statusKeluarga : value.statusKeluarga,
                    statusPajakId : statusPajakId ? statusPajakId.id : 0,
                    nik : value.nikPanjang,
                    npwp : value.npwp,
                    identityNo : value.identityNo,
                    noKk : value.noKk,
                    domicileAddress1 : value.domicileAddress1,
                    domicileCountryId : 130,
                    domicileKelurahanId : value.domicileKelurahanId,
                    domicileKecamatanId : value.domicileKecamatanId,
                    domicileCityId : value.domicileCityId,
                    domicileProvinceId : value.domicileProvinceId,
                    identityAddress1 : value.identityAddress1,
                    identityCountryId : 130,
                    identityKelurahanId : value.identityKelurahanId,
                    identityKecamatanId : value.identityKecamatanId,
                    identityCityId : value.domicileKelurahanId,
                    identityProvinceId : value.identityProvinceId,
                    mobileNo : value.mobileNo,
                    mobilePhone : value.mobilePhone,
                    mobilePhone2 : value.mobilePhone2,
                    email : value.email,
                    email2 : value.email2,
                    noBpjsTk : value.noBpjsTk,
                    rekeningNo : value.rekeningNo,
                    // rekeningBankId : value.rekeningBankId,
                    gelarDepan : value.gelarDepan,
                    gelarBelakang : value.gelarBelakang,
                    usia : value.usia,
                    statusFoto : value.statusFoto,
                    statusPeserta: 1,
                    noBpjsKesehatan : value.noBpjsKesehatan,
                    participantId : 1,
                    // unitId: value.unitKerjaId,
                }
                var dataKepegawaian = {
                    pesertaId: value.id,
                    nikPanjang: value.nikPanjang,
                    nik: value.nik,
                    // statusKepegawaianId: value.statusKepegawaianId == 0 ? 0 : value.statusKepegawaianId,
                    // unitKerjaId: value.unitKerjaId,
                    unitName: value.unitName,
                    unitDiatas1: value.unitDiatas1,
                    unitDiatas2: value.unitDiatas2,
                    unitDiatas3: value.unitDiatas3,
                    // jenisKantorId: jenisKantorId ? jenisKantorId.id : 0,
                    // statusKaryawanId: value.statusKaryawanId === 0 ? '' : value.statusKaryawanId,
                    tmtStatus: value.tmtStatus,
                    tanggalEfektif: value.tanggalEfektif,
                    grade: value.grade,
                    tmtGrade: value.tmtGrade,
                    mkTahunGrade: value.mkTahunGrade,
                    mkBulanGrade: value.mkBulanGrade,
                    jabatanGolongan: value.jabatanGolongan,
                    jabatanName: value.jabatanName,
                    jabatanTanggal: value.jabatanTanggal,
                    penugasanGrade: value.penugasanGrade,
                    // penugasanJabatanId: value.penugasanJabatanId,
                    // penugasanUnitId: value.penugasanUnitId,
                    penugasanTanggal: value.penugasanTanggal,
                    indexPredikat: value.indexPredikat,
                    noSkTerakhir: value.noSkTerakhir,
                    tanggalBerhenti: value.tanggalBerhenti,
                    namaAtasan: value.namaAtasan,
                    kodeMis: value.kodeMis,
                    phdp: value.phdp,
                    upnp: value.upnp,
                    thp: value.thp,
                }
    
                var dataAkun = {
                    pesertaId: value.id,
                    efektifDate : formatDate(new Date()),
                    productId : value.productId,
                    iuranNormalPeserta: value.phdp * (globalParamIuranPeserta.floatValue) / 100,
                    iuranNormalPk: value.phdp * (globalParamIuranPK.floatValue) / 100,
                    akunStatusId: 1,
                    skPensiunDateHenti : value.skPensiunDateHenti,
                    skPensiunTanggal : value.skPensiunTanggal,
                    pemberhentianEfektifDate : value.pemberhentianEfektifDate,
                    pensiunDate : value.skPensiunDate,
                    skPensiunNo : value.skPensiunNo,
                    pensiunEfektifDate : value.pensiunEfektifDate,
                    stppp : value.stppp,
                    daftarPenerimaMpDate : value.daftarPenerimaMpDate,
                    mkTahunSeluruh : value.mkTahunSeluruh,
                    mkBulanSeluruh : value.mkBulanSeluruh,
                    // pembayaranMpBankId : value.pembayaranMpBankId,
                    // pembayaranMpRekBankId : value.pembayaranMpRekBankId,
                    dcppAlamat : value.dcppAlamat,
                    dcppAddress1 : value.dcppAddress1,
                    dcppKelurahanId : value.dcppKelurahanId,
                    dcppKecamatanId : value.dcppKecamatanId,
                    dcppCityId : value.dcppCityId,
                    dcppProvinceId : value.dcppProvinceId,
                    dcppKewarganegaraan : value.dcppKewarganegaraan,
                }
    
                var dataIuran = [
                    {
                        pesertaAkunProdukId: 0,
                        iuranTypeId: 1,
                        productId: value.productId,
                        iuranNominal : value.phdp * (globalParamIuranPeserta.floatValue) / 100,
                        efektifDate: formatDate(new Date()),
                        skNo : value.noSkTerakhir
                    },
                    {
                        pesertaAkunProdukId: 0,
                        iuranTypeId: 3,
                        productId: value.productId,
                        iuranNominal : value.phdp * (globalParamIuranPK.floatValue) / 100,
                        efektifDate: formatDate(new Date()),
                        skNo : value.noSkTerakhir
                    },
                    {
                        pesertaAkunProdukId: 0,
                        iuranTypeId: 2,
                        productId: value.productId,
                        iuranNominal : value.phdp * (globalParamIuranMLPeserta.floatValue) / 100,
                        efektifDate: formatDate(new Date()),
                        skNo : value.noSkTerakhir
                    },
                ]
    
                var updatedParam = {
                    dataPersonal: dataPersonal,
                    dataKepegawaian: dataKepegawaian,
                    dataAkun: dataAkun,
                    dataIuran: dataIuran,
                }
                
                var dataPengkinian = {
                    noSk: value.noSkTerakhir,
                    online: false,
                    pengkinianDate: formatDate(new Date()),
                    pengkinianTypeId: 1,
                    pesertaId: value.id,
                    pesertaStatusId: 2,
                    statusCode: 2,
                    updatedStatus: false,
                    updatedNote: 'Pengkinian Upload ulang peserta'
                  }
                dataPengkinian.updateParam = JSON.stringify(updatedParam)
    
                var insertAPIPengkinian = 'pengkinian-peserta-bo-aktifs'                    
                await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPIPengkinian, 'POST', {
                        values:dataPengkinian
                    }
                )
            }

        }
        this.deleteDataGrid(this.dataGridSesuaiRef)
        this.deleteDataGrid(this.dataGridTakSesuaiRef)
        this.props.forceRefresh()
        this.hide()
        notify({ message: 'Data Berhasil Diupload!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
    }

    show = () => {
        this.setState({
          popupVisible: true
        });
    };
    
    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabuploadindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabuploadindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns];
        columns.unshift({
        type: 'buttons',
        buttons: [
            {
            text: "Error Detail",
            hint: "Error Detail",
            icon: "warning",
            cssClass: "text-danger",
            onClick: e => {
                let type = "error";
                let text = e.row.data.errorMessage.join("\n");

                notify(
                {
                    message: text,
                    width: "AUTO",
                    shading: true,
                    position: { at: "center", my: "center", of: window }
                },
                type,
                2000
                );
            }
            }
        ]
        });

        return columns;
    }

    onValueChanged = (e) => {
        this.setState({
          useHeader:e.value
        })
    }

    render() {
        return (
            <Popup
            className={"popup"}
            visible={this.state.popupVisible}
            onHiding={this.hide}
            dragEnabled={false}
            showTitle={true}
            title={"Upload Peserta"}
            width={"90vw"}
            height={650}
            toolbarItems={this.PopupToolbarItem}
            >
                <div className="d-flex flex-row">
                    <div style={{width:"350px"}}>
                        <FileUploader
                            accept={".txt,.xls,.xlsx,.csv"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload Peserta File Disini"
                            showFileList={false}
                            name={"uploadPeserta"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                        <Form
                            ref={this.formRef}
                            id={'formUseHeader'}
                            colCount={1}
                            formData={this.formItem}
                            items={this.checkboxItem}
                            scrollingEnabled={false}
                            labelLocation='left'
                        />
                        {/* <CheckBox 
                            value={this.formItem} 
                            onValueChanged={this.onValueChangedUseHeader}
                        />
                        <span className="ml-2">Use Header</span> */}
                    </div>
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabuploadindex-0" className={' mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataSuccess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Sukses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Sukses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}
                        keyField = {'nik'}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabuploadindex-1" className={'d-none mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridTakSesuaiRef}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataFailed}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Gagal"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Gagal'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.errorDataGridErrorButtonColumn()} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    </div>
            </Popup>
        )
    }
}


export default UploadPeserta