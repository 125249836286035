import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import Form from 'devextreme-react/form'
import { formatDate, formatDefaultFullDate } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify';
import { showLoading } from 'redux/actions/loading'
import { confirmAlert } from 'react-confirm-alert';
import moment from '../../../../../node_modules/moment/moment'

class ProsesAkhirBulan extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);


        this.state = {
            filterData: {
                sysDateNow: new Date(this.sysDate ? this.sysDate.dateValue : null),
                sysDateNext: this.date.setDate(this.date.getDate() + 1)
            },
            selectedData: []
        }

        this.datagridRef = React.createRef()
        this.dataClosing = []

        this.lookupProses = [
            {
                id: 3,
                value: 'Generate SPI'
            },
            {
                id: 4,
                value: 'Generate Acrrued Interest'
            },
            {
                id: 5,
                value: 'Generate Amortisasi'
            },
            {
                id: 6,
                value: 'Generate Reklas Acrrued'
            },
        ]

        this.columns = [
            {
                dataField: 'logProses',
                caption: 'Log Proses Akhir Hari',
                columns: [
                    {
                        dataField: 'proses',
                        caption: 'Proses',
                        lookup: {
                            dataSource: this.lookupProses,
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'lastClosingDate',
                        caption: 'Tanggal Proses Terakhir'
                    },
                    {
                        dataField: 'closingDate',
                        caption: 'Tanggal Proses'
                    },
                    {
                        dataField: 'statusId',
                        caption: 'Aksi',
                        dataType: 'boolean'
                    }
                ]
            }
        ]

        this.filterItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'sysDateNow',
                        label: {
                            text: 'Tanggal Generate Saat Ini'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                        disabled: true
                    },
                ]
            },
        ]

        this.resultProses = []
    }

    componentDidMount = async () => {
        await this.loadData()
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    loadData = async () => {
        var sysDateNow = this.state.filterData.sysDateNow
        // var date = sysDateNow.getDate()
        // var month = sysDateNow.getMonth()+1
        // var year = sysDateNow.getFullYear()

        // var data = [year, month, date].join('-')

        let closingByProsesDate = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/closingMonthlyByProsesDate/${formatDate(sysDateNow)}/4/0`);
        let closingByCloseDate = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/closingMonthlyByClosingDate/${formatDate(sysDateNow)}/4/1`);

        var dataClosing = closingByCloseDate ? closingByProsesDate.concat(closingByCloseDate) : []
        var dataResponse = []
        for (var value of dataClosing) {
            var status = false

            if (value.statusId === 1) {
                status = true
            } else {
                status = false
            }

            var dataResult = {
                id: value.id,
                bulan: value.bulan,
                closingDate: value.closingDate,
                lastClosingDate: value.lastClosingDate,
                modulId: value.modulId,
                statusId: status,
                tahun: value.tahun,
                proses: value.tipeClosingId
            }

            dataResponse.push(dataResult)
        }

        dataResponse.sort((a, b) => a.proses - b.proses)

        this.dataClosing = dataResponse

        return dataResponse;
    }

    // loadData = () => {
    //     return this.resultProses
    // }

    onSelectionChanged = (e) => {
        var data = e.selectedRowsData

        this.setState({
            selectedData: data
        })
    }

    prosesEndMounth = async () => {
        confirmAlert({
            title: 'Proses Generate',
            message: 'Apakah anda yakin melakukan proses ini?',
            buttons: [
                {
                    label: 'Ya',
                    onClick: async () => {
                        this.props.store.dispatch(showLoading(true))

                        var selectedData = this.state.selectedData
                        var tanggal = formatDate(this.state.filterData.sysDateNow)

                        try {
                            let response
                            var prosesId
                            for (var data of selectedData) {
                                if (data.proses === 3) {
                                    prosesId = 3

                                    var value = {
                                        id: data.id,
                                        bulan: this.state.filterData.sysDateNow.getMonth() + 1,
                                        modulId: 4,
                                        prosesId: prosesId,
                                        systemDate: tanggal,
                                        tahun: this.state.filterData.sysDateNow.getFullYear(),

                                    }

                                    response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/updateClosing`, 'PUT', {
                                        key: value.id,
                                        values: value
                                    }, {});

                                    if (response) {
                                        await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/closingMonthlySpi/${tanggal}/1`, 'POST', {
                                            values: {}
                                        });
                                    }
                                }

                                if (data.proses === 4) {
                                    prosesId = 4

                                    var value = {
                                        id: data.id,
                                        bulan: this.state.filterData.sysDateNow.getMonth() + 1,
                                        modulId: 4,
                                        prosesId: prosesId,
                                        systemDate: tanggal,
                                        tahun: this.state.filterData.sysDateNow.getFullYear(),

                                    }

                                    response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/updateClosing`, 'PUT', {
                                        key: data.id,
                                        values: value
                                    }, {});

                                    if (response) {
                                        await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/closingMonthlyAccrued/${tanggal}`, 'POST', {
                                            values: {}
                                        });
                                    }
                                }

                                if (data.proses === 5) {
                                    prosesId = 5
                                    var value = {
                                        id: data.id,
                                        bulan: this.state.filterData.sysDateNow.getMonth() + 1,
                                        modulId: 4,
                                        prosesId: prosesId,
                                        systemDate: tanggal,
                                        tahun: this.state.filterData.sysDateNow.getFullYear(),

                                    }

                                    await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/genAmortisasiProp/${tanggal}`, 'POST', {
                                        values: {}
                                    });

                                    response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/updateClosing`, 'PUT', {
                                        key: data.id,
                                        values: value
                                    }, {});

                                    if (response) {
                                        await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/closingMonthlyAmortisasi/${tanggal}`, 'POST', {
                                            values: {}
                                        });
                                    }
                                }

                                if (data.proses === 6) {
                                    prosesId = 6

                                    var value = {
                                        id: data.id,
                                        bulan: this.state.filterData.sysDateNow.getMonth() + 1,
                                        modulId: 4,
                                        prosesId: prosesId,
                                        systemDate: tanggal,
                                        tahun: this.state.filterData.sysDateNow.getFullYear(),

                                    }

                                    response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/updateClosing`, 'PUT', {
                                        key: data.id,
                                        values: value
                                    }, {});

                                    if (response) {
                                        await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/closingMonthlyReklass/${tanggal}`, 'POST', {
                                            values: {}
                                        });
                                    }
                                }
                            }

                            this.props.store.dispatch(showLoading(false))
                            notify({ message: 'Proses Generate Akhir Bulan berhasil dilakukan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                            this.datagridRef.current.forceRefresh(true)
                        } catch (e) {
                            console.log(e)

                            this.props.store.dispatch(showLoading(false))
                            notify({ message: 'Proses Generate Akhir Bulan gagal dilakukan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            this.datagridRef.current.forceRefresh(true)
                        }
                    }
                },
                {
                    label: 'Tidak',
                }
            ]
        });
    }

    saveToClosing = async () => {
        let allProsesId = [3, 4, 5, 6]
        let data = []
        var tanggal = moment(this.state.filterData.sysDateNow).locale('id').format('YYYY-MM-DD')
        try {
            for (let proses of allProsesId) {
                let value = {
                    // bulan: this.date.getMonth()+1,
                    modulId: 4,
                    prosesId: proses,
                    systemDate: tanggal,
                    // tahun: this.date.getFullYear(),
                }
                data.push(value)
            }

            await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/saveToClosing`, 'POST', {
                values: data
            });
            this.datagridRef.current.forceRefresh(true)
        } catch (e) {
            console.log(e)
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    disabled: this.dataClosing.length > 0 ? true : false,
                    elementAttr: { class: "bg-dapen-default" },
                    text: 'Tampilkan daftar proses',
                    onClick: async (e) => {
                        await this.saveToClosing()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Proses',
                    onClick: async (e) => {
                        await this.prosesEndMounth()
                    },
                }
            },
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Proses Akhir Bulan</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation="left"
                        />
                    </div>
                    <div className="mt-2 col-md-12">
                        <DevExpressDataGrid
                            ref={this.datagridRef}
                            loadAPI='-'
                            insertAPI='-'
                            updateAPI='-'
                            deleteAPI='-'

                            backendserver={process.env.REACT_APP_BACKEND}
                            useArraySource={true}
                            ArraySourceData={this.loadData}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            exportExcel={false}
                            exportFileName={"-"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'-'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={200} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            // SummaryConfiguration={this.summary}

                            onToolbarPreparing={this.onToolbarPreparing}
                            onSelectionChanged={this.onSelectionChanged}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ProsesAkhirBulan