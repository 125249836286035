import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { gender, educationBackground, banks } from 'dataSource/lookup';
import { formatUploadFileData } from 'plugin/helper';
import FormDataPersonal from '../../compPeserta/dataPersonal';
import DataGridKeluarga from '../../compPeserta/dataKeluarga';
import FormDataPensiunPasif from '../../compPeserta/dataPensiunPasif';
import httpRequest from 'plugin/httprequest';
import { ScrollView } from '../../../../../../../node_modules/devextreme-react';
import FormDataPensiun from '../../compPeserta/dataPensiun';

class ShowModalAkunPensiunanPasif extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
        }        
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDataKepegawaianRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.formDataAkunRef = React.createRef()
        this.formDataPensiunRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id : 0,
                text: 'Data Personal'
            },
            {
                id : 1,
                text: 'Data Keluarga'
            },
            {
                id : 2,
                text: 'Data Pensiun'
            },
            // {
            //     id : 3,
            //     text: 'Data Pensiun'
            // },
        ]

        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                editorOptions: {
                    readOnly : true
                }
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
            }, 
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                },
            },
            
        ]
        this.dataTanggungan = [
            {
                dataField: 'fullName',
                caption: 'Nama',
            },{
                dataField: 'relation',
                caption: 'Hubungan Keluarga'
            },{
                dataField: 'birthdDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',  
                format: 'dd-MMM-yyyy',
            },{
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup:{
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            }, {
                dataField: 'ktp',
                caption: 'No KTP'
            }, {
                dataField: 'statusSekolah',
                caption: 'Status Pendidikan',
                lookup:{
                    dataSource: educationBackground(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
        ]

        this.dataAkunPensiun = 
        [
            {
                dataField: 'cardNo',
                label: {
                    alignment: 'left',
                    text: 'CardBo'
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'penerimaMpBulanan',
                label: {
                    alignment: 'left',
                    text: 'Penerima MP Bulanan'
                },
                editorType: 'dxRadioGroup',
                editorOptions:
                {
                    items: [
                        'Yang Bersangkutan',
                        'Ahli Waris'
                    ],
                    value: 'Yang Bersangkutan',
                    layout: "horizontal"
                }
            },
            {
                dataField: 'program',
                label: {
                    alignment: 'left',
                    text: 'Program'
                },
            },
            {
                dataField: 'jumlahTransferMpBulanan',
                label: {
                    alignment: 'left',
                    text: 'Jumlah Transfer MP Bulanan'
                },
                editorType: 'dxNumberBox',
                format: '#,##0',
                editorOptions: {
                    format: '#,##0',
                }
            },
            {
                dataField: 'tanggalEfektif',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Efektif'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%'
                }  
            },
            {
                dataField: 'namaPenerimaMp',
                label: {
                    alignment: 'left',
                    text: 'Nama Penerima MP'
                },
            },
            {
                dataField: 'statusPesertaPensiun',
                label: {
                    alignment: 'left',
                    text: 'Status Peserta Pensiun'
                },
            },
            {
                dataField: 'hubunganKeluarga',
                label: {
                    alignment: 'left',
                    text: 'Hubungan Keluarga'
                },
            },
            {
                dataField: 'MpBulanan',
                label: {
                    alignment: 'left',
                    text: 'MP Bulanan'
                },
                format: '#,##0',
                editorOptions: {
                    format: '#,##0',
                }
            },
            {
                dataField: 'jumlahTransferMpBulanan',
                label: {
                    alignment: 'left',
                    text: 'Jumlah Transfer MP Bulanan'
                },
                format: '#,##0',
                editorOptions: {
                    format: '#,##0',
                }
            },
        ]

        this.dataPensiun1 = 
        [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'jenisPensiun',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                        colSpan: 2
                    },
                    {
                        dataField: 'noSkPensiun',
                        label: {
                            alignment: 'left',
                            text: 'no SK Pensiun'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'fileNoSkPensiun',
                        label: {
                            alignment: 'left',
                            text: 'File No SK Pensiun'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            uploadMode: "useForm",
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'noSkPhk',
                        label: {
                            alignment: 'left',
                            text: 'No SK PHK'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'fileNoSkPhk',
                        label: {
                            alignment: 'left',
                            text: 'File No SK PHK'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            uploadMode: "useForm",
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                ]
            },
            {
                itemType : 'group',
                items: [
                    {
                        dataField: 'jenisPhk',
                        label: {
                            alignment: 'left',
                            text: 'Jenis PHK'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                    },
                    {
                        dataField: 'pembayaranMP',
                        label: {
                            alignment: 'left',
                            text: 'Pembayaran MP'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions:
                        {
                            items: [
                                'Bulanan',
                                'Sekaligus'
                            ],
                            value: 'Bulanan',
                            layout: "horizontal"
                        }
                    },
                    {
                        dataField: 'nilaiMPSekaligus',
                        label: {
                            alignment: 'left',
                            text: 'Nilai MP Sekaligus'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'nilaiMPBulanan',
                        label: {
                            alignment: 'left',
                            text: 'Nilai MP Bulanan'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'tanggalPensiun',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pensiun'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%'
                        }  
                    },
                    {
                        dataField: 'tanggalMpPertama',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal MP Pertama'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%'
                        }  
                    },      
                ]
            },
        ]

        this.dataPensiun2 = [
            {
                itemType: 'group',
                caption: 'Penerima Manfaat Pensiun',
                items: [
                    {
                        dataField: 'penerima',
                        label: {
                            alignment: 'left',
                            text: 'Penerima'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Istri'
                                },
                                {
                                    id: 2,
                                    value: 'Anak'
                                }
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                    },
                    {
                        dataField: 'keluargaPenerimaMp',
                        label: {
                            alignment: 'left',
                            text: 'Keluarga Penerima MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Istri'
                                },
                                {
                                    id: 2,
                                    value: 'Anak'
                                }
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                    },
                    {
                        dataField: 'bankMp',
                        label: {
                            alignment: 'left',
                            text: 'Bank MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Rekening Manfaat Pensiun',
                items: [
                    {
                        dataField: 'noRekMp',
                        label: {
                            alignment: 'left',
                            text: 'No Rekening MP'
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField: 'namaRekMp',
                        label: {
                            alignment: 'left',
                            text: 'Nama Rekening MP'
                        },
                    },
                    {
                        dataField: 'bankMp',
                        label: {
                            alignment: 'left',
                            text: 'Bank MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        },
                    },
                ]
            },
            {
                itemType: 'empty',
                colSpan: 2
            },
            {
                itemType: 'group',
                caption: 'Dokumen Ahli Waris',
                colCount: 2,
                colSpan: 2,
                items: [
                    {
                        dataField: 'fileJandaDuda',
                        label: {
                            alignment: 'left',
                            text: 'File Janda/Duda'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            uploadMode: "useForm",
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                    {
                        dataField: 'fileMasihSekolahKuliah',
                        label: {
                            alignment: 'left',
                            text: 'File Masih Sekolah/Kuliah'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            uploadMode: "useForm",
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                    {
                        dataField: 'fileKartuKeluarga',
                        label: {
                            alignment: 'left',
                            text: 'File Kartu Keluarga'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            uploadMode: "useForm",
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                    {
                        dataField: 'fileKtpPenerima',
                        label: {
                            alignment: 'left',
                            text: 'File KTP Penerima'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            uploadMode: "useForm",
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                    },
                ]
            }
        ]

        this.dataGridPensiun = [
            {
                dataField: 'no',
                caption: 'No',
            },{
                dataField: 'manfaatPensiun',
                caption: 'Manfaat Pensiun'
            },{
                dataField: 'nilai',
                caption: 'Nilai',
                format: '#,##0.00',
                width: '150px',
                alignment: 'right',
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        });
    }

    submit = () => {
        var data = this.state
        var Data = {...data.DataMaster,...data.DataPersonal,...data.DataTanggungan,...data.dataAkunPensiun,...data.DataPensiun,...data.DocumentData}
        this.hide()
        this.props.submitData(Data)
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }
    
    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    DocumentData: {
                        ...prevState.DocumentData,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    retrieveData = async(data = null) => {
        try {
            var loadAPIPeserta = `pesertas/${data.pesertaId || data.id}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
        } catch (e) {
            console.log(e)
        }

        try {
            var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${data.pesertaId || data.id}`
            var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKeluarga, 'GET')
        } catch (e) {
            console.log(e)
        }
        let dataPesertaKepProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${getPeserta.id}`, 'GET');
        await this.formDataPersonalRef.current.showRekPenerima(dataPesertaKepProduct);
        await this.formDataPersonalRef.current.retrieveData(getPeserta)
        await this.dataGridKeluargaRef.current.reloadData(getPeserta.id,getKeluarga)
        // await this.formDataAkunRef.current.reloadData(getPeserta.id,getAkun || [])
        // await this.formDataAkunRef.current.retrieveData(data.id,data.pesertaAkunProduk)
        // await this.formDataPensiunRef.current.reloadData(data.id,data.pesertaPensiun || {})
        await this.formDataPensiunRef.current.retrieveData(data.id)

        this.setState({
            DataMaster: getPeserta
        })
    }

    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                no : 1,
                manfaatPensiun: 'Meninggal Dunia',
                nilai: '3500000',
            }
        ]
        this.currentData = JSON.parse(JSON.stringify(dummyData))
        return dummyData
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Akun Pensiunan Pasif'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >   
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={3}
                                id={'formDataMaster'}
                                formData={this.state.DataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                readOnly={true} 
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id="tabindex-0" className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataPersonal store={this.props.store} ref={this.formDataPersonalRef}/>
                        </div>
                        <div id="tabindex-1" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <DataGridKeluarga store={this.props.store} ref={this.dataGridKeluargaRef}/>
                        </div>
                        {/* <div id="tabindex-2" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataAkun store={this.props.store} ref={this.formDataAkunRef}/>
                        </div> */}
                        <div id="tabindex-2" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataPensiun store={this.props.store} ref={this.formDataPensiunRef}/>
                        </div>
                    </div>

                </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ShowModalAkunPensiunanPasif