import React, { Component } from 'react';
import { Popup,ScrollView,Form,FileUploader } from 'devextreme-react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { jabatan, programs, sdmUnit } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import FormModalRincianDetail from './rincianDetail';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest' 
import notify from "devextreme/ui/notify";
import { confirmAlert } from 'react-confirm-alert'

class FormRincianRekruitmen extends Component {
    constructor(props){
        super(props)

        this.state={
            popupVisible:false,
            DataMaster:{}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        onClick : (e) => {
                            this.showModalEditRincianDetail(e.row.data)
                        }
                    },
                    {
                        text : 'Batal',
                        hint : 'Batal',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin membatalkan data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            // this.batal(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text : 'Info Lengkap',
                        hint : 'Info Lengkap',
                        onClick : (e) => {
                            this.showModalViewRincianDetail(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'unitId',
                caption: 'Unit',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                },
            },
            {
                dataField: 'jabatanId',
                caption: 'Posisi Jabatan',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'jabatanName',
                },
            },
            {
                dataField: 'jumlahPersonel',
                caption: 'Jumlah Personel',
            },
            // {
            //     dataField: 'pendidikanId',
            //     caption: 'Pendidikan',
            // },
            // {
            //     dataField: 'jurusanPendidikan',
            //     caption: 'Jurusan Pendidikan',
            // },
            {
                dataField: 'tahunPengalaman',
                caption: 'Pengalaman (Tahun)',
            },
            // {
            //     dataField: 'bahasaId',
            //     caption: 'Bahasa',
            // },
            // {
            //     dataField: 'keahlianId',
            //     caption: 'Keahlian ',
            // },
        ]

        
        this.dataGridRef = React.createRef()
        this.showModalAddRincianDetailReff = React.createRef()
        this.showModalEditRincianDetailReff = React.createRef()
        this.showModalViewRincianDetailReff = React.createRef()
    }

    show() { 
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster:{}
        })
    }

    retrieveData = async(data) => {
        this.setState({
            DataMaster: data,
        })

        this.dataGridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-rekurtmen-detil-rincians', 'GET')
        response = response.filter(value => value.rekurtmenId == this.state.DataMaster.id)
        return response
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  text:'Tambah Rincian',
                  onClick: (e) => {
                    this.showModalAddRincianDetail(e)
                  },
              }
          }
        )
    }

    showModalAddRincianDetail = () => {
        this.showModalAddRincianDetailReff.current.show()
        this.showModalAddRincianDetailReff.current.getRekruitmenId(this.state.DataMaster.id)
    }

    showModalEditRincianDetail = (data) => {
        this.showModalEditRincianDetailReff.current.show()
        this.showModalEditRincianDetailReff.current.retrieveData(data)
        this.showModalEditRincianDetailReff.current.getRekruitmenId(this.state.DataMaster.id)
    }

    showModalViewRincianDetail = (data) => {
        this.showModalViewRincianDetailReff.current.show()
        this.showModalViewRincianDetailReff.current.retrieveData(data)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <React.Fragment>
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Rincian Rekruitmen"}
                width={"90vw"}
                height={"70vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                <ScrollView height="100%" width="100%">
                    <DevExpressDataGrid
                        ref = {this.dataGridRef}
                        loadAPI='rekurtmen?size=9999'
                        insertAPI='pengajuan-rapel'
                        updateAPI='pengajuan-rapel'
                        deleteAPI='pengajuan-rapel' 

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource = {true}
                        ArraySourceData= {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Rapel"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Rapel'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    </ScrollView>
                </Popup>
                <FormModalRincianDetail 
                    ref={this.showModalAddRincianDetailReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalRincianDetail 
                    ref={this.showModalEditRincianDetailReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalRincianDetail 
                    ref={this.showModalViewRincianDetailReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'view'}
                />
            </React.Fragment>
        )
    }
}

export default FormRincianRekruitmen