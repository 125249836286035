import React, { Component } from 'react'
import { Popup,Form } from 'devextreme-react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalTanggalSerahTerima from './tanggalSerahTerima'

class FormModalSerahTerimaAset extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }

        this.form = [
            {
                dataField:'noRegistrasi',
                label:{
                    text:'No Registrasi'
                }
            },
            {
                dataField:'group',
                label:{
                    text:'Group'
                }
            },
            {
                dataField:'jenis',
                label:{
                    text:'Jenis'
                }
            },
            {
                dataField:'tipe',
                label:{
                    text:'Tipe'
                }
            },
            {
                dataField:'spesifikasi',
                label:{
                    text:'Spesifikasi'
                }
            },
        ]

        this.columns = [
            {
                dataField:'tanggal',
                caption:'tanggal'
            },
            {
                dataField:'dari',
                caption:'Dari'
            },
            {
                dataField:'kepada',
                caption:'Kepada'
            },
            {
                dataField:'Keterangan',
                caption:'Keterangan'
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.showModalSerahTerimaRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster: data,
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    showModalSerahTerima = (e) => {
        if (e.row) {
            this.showModalSerahTerimaRef.current.retrieveData(e.row.data)
        }
        this.showModalSerahTerimaRef.current.show()
    }

    submitData = () => {
    
    }

    updateData = () => {
    
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalSerahTerima(e)
                    },
                }
            }
        )
    }

    loadDummyData = () => {
        var data = [
            {
                id:1
            }
        ]

        return data
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Serah Terima Aset'} 
                width={700}
                height={700}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
                <div className="mt-3">
                    <DevExpressDataGrid
                        ref = {this.dataGridRef}
                        loadAPI='Kinerja'
                        insertAPI='Kinerja'
                        updateAPI='Kinerja'
                        deleteAPI='Kinerja' 

                        backendserver={process.env.REACT_APP_BACKEND_HRD}
                        useArraySource={true}
                        ArraySourceData={this.loadDummyData}
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Tanggal Cuti"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}
                        height={400}
                        paging={true}
                        defaultPageSize={10}
                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Tanggal Cuti'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        onToolbarPreparing={this.onToolbarPreparing}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalTanggalSerahTerima 
                        ref={this.showModalSerahTerimaRef}
                        store={this.props.store}
                    />
                </div>
            </Popup>
        )
    }
}

export default FormModalSerahTerimaAset