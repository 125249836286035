import React, { Component } from 'react'
import { currency, banks, instruments, orders, instrumentsCategorySaham, product, productDefault } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import FormPengajuanPenyertaanIpoSaham from 'components/pages/modal/investasi/pengajuan/saham/penyertaan-ipo/index'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";
import { confirmAlert } from 'react-confirm-alert'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultFullDate, formatNumber, stripHtml, formatNumberAum, PrintElem, inputToTaskInvestasi } from 'plugin/helper'
import DetailPenyertaanIpoSaham from './detail'

class PenyertaanIpoSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridOrderSahamRef = React.createRef()
        this.formPenyertaanIpoSahamRef = React.createRef()
        this.formPenyertaanIpoSahamEditRef = React.createRef()
        this.formPersetujuanPenempatanSahamRef = React.createRef()

        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: async (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                this.showModalEdit(data, 'edit')
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        },
                    },

                    {
                        text: 'Cetak',
                        hint: 'Cetak',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Belum terkirim!',
                                    message: 'Data belum terkirim tidak bisa cetak surat!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakSurat(data)
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.requestNo == null) {
                                confirmAlert({
                                    title: 'Batalkan draft',
                                    message: 'Apakah anda yakin ingin membatalkan draft ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data sudah terkirim tidak bisa diubah!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'requestNo',
                caption: 'No Pengajuan',
                cellRender: (e) => {
                    var data = e.row.data
                    if (data.requestNo == null) {
                        return 'DRAFT'
                    } else {
                        return data.requestNo
                    }
                }
            },
            {
                dataField: 'requestDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'requestDate',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'productCode',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'productCode',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'emitenName',
                caption: 'Emiten',
            },
            {
                dataField: 'totalAmountOrder',
                caption: 'Nominal Penyertaan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'DRAFT',
                            value: 'Tersimpan'
                        },
                        {
                            id: 'INPROGRESS',
                            value: 'Dalam proses persetujuan'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.currentData = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Penyertaan',
                    onClick: (e) => {
                        this.showModalInsert('add')
                    },
                }
            }
        )
    }
    kirimData = async (data) => {
        var loadAPI = `order-requests-ipo/${data.orderRequestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = response.orderRequestDTO
        var kajian = dataMaster.kajian
        var content = dataMaster.kajianContentType
        var dataDetail = []
        data = {
            productId: response.orderRequestDTO.productId,
            fundManagerId: response.orderRequestDTO.fundManagerId,
            tanggalOrder: response.orderRequestDTO.orderDate,
            requestOrderId: response.orderRequestDTO.id,
            orderTypeId: response.orderRequestDTO.orderTypeId,
            kajian: kajian,
            kajianContentType: content,
            kategori: data.categoryName,
            draft: false,
        }
        for (var saham of response.orderIpoInfos) {
            for (var detail of saham.orderIpoDTOList) {
                var sahamInfo = {
                    id: detail.id,
                    instrumentId: detail.instrumentId,
                    ordQty: detail.quantityOrder,
                    brokerId: detail.brokerId,
                    emitenId: detail.emitenId,
                    priceType: detail.priceType,
                    minOrdPrice: detail.priceMin,
                    maxOrdPrice: detail.priceMax,
                    orderId: detail.orderId,
                    kpdId: saham.orderDTO.kpdId,
                    tanggalOrder: saham.orderDTO.orderDate,
                    rekbankId: detail.rekBankId,
                    nominalPenyertaan: detail.amountOrder,
                    brokerCommission: detail.commission
                }
            }
            dataDetail.push(sahamInfo)
        }
        var submitData = {
            ...data,
            instrumentIpoList: dataDetail
        }

        try {
            let orderRequestId = response.orderIpoInfos[0].orderDTO.orderRequestId
            let orderTypeId = response.orderIpoInfos[0].orderDTO.orderTypeId

            await inputToTaskInvestasi(this.props.store, orderRequestId, orderTypeId)
        } catch (error) {
            console.log(error);
        }
        // return false
        if (submitData) {
            try {
                var insertAPI = 'order-ipos/pengajuan-penempatan-ipo'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: submitData
                })
                notify({ message: 'Pengiriman berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    cetakSurat = async (data) => {
        let reqNo = data.requestNo
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-requests-ipo/${data.orderRequestId}`, 'GET')
        var dirKeuangan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/6`, 'GET')
        var dirOperasional = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/79506`, 'GET')
        var dirut = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5`, 'GET')
        var komite = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawans/getByJabatanId/5351`, 'GET')
        var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `emitens/${response.orderIpoInfos[0].orderIpoDTOList[0].emitenId}`, 'GET')
        let instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-transaction-types/${response.orderIpoInfos[0].orderDTO.orderTypeId}`, 'GET')
        var kajian = stripHtml(atob(response.orderRequestDTO.kajian)) == 'undefined' ? ' ' : stripHtml(atob(response.orderRequestDTO.kajian))

        if (dirKeuangan.length > 0) {
            dirKeuangan = dirKeuangan[0]
        } else {
            dirKeuangan = {}
        }

        if (dirOperasional.length > 0) {
            dirOperasional = dirOperasional[0]
        } else {
            dirOperasional = {}
        } 

        if (dirut.length > 0) {
            dirut = dirut[0]
        } else {
            dirut = {}
        }

        if (komite.length > 0) {
            komite = komite[0]
        } else {
            komite = {}
        }

        let dataIpo = []
        let arrTotal = []
        var no = 0
        for (var ipo of response.orderIpoInfos[0].orderIpoDTOList) {
            let instrumentType = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-transaction-types/${ipo.orderTypeId}`, 'GET')
            let category = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-categories/${instrumentType.instrumentCategoryId}`, 'GET')

            var ipoInfo = {
                no: ++no,
                categoryName: category.categoryName,
                emitenCode: emiten.emitenName,
                nominalPenyertaan: ipo.amountOrder
            }
            dataIpo.push(ipoInfo)
        }

        for (let ipoDetil of dataIpo) {
            let totalAmountOrder = ipoDetil.nominalPenyertaan
            arrTotal.push(totalAmountOrder)
        }

        var data = {
            template: {
                'shortid': 'SJ76Fok2j',
                'recipe': 'chrome-pdf'
            },
            data: {
                "requestNo": data.requestNo,
                "emitenCode": emiten.emitenName,
                "requestDate": formatDefaultFullDate(data.requestDate),
                "kajian": kajian,
                "transactionName": instrument.transactionName,
                "dataIpo": dataIpo,
                "totalnominalPenyertaan": arrTotal.reduce((a, b) => a + b),
                "dirKeuangan": {
                    "fullName": dirKeuangan.fullName,
                    "jabatan": 'Direktur Keuangan'
                },
                "dirOperasional": {
                    "fullName": dirOperasional.fullName,
                    "jabatan": 'Direktur Operasional'
                },
                "dirut": {
                    "fullName": dirut.fullName,
                    "jabatan": 'Direktur Utama'
                },
                "dirInvest": {
                    "fullName": komite.fullName,
                    "jabatan": 'Direktur Investasi'
                }
            },
            options: {
                reportName: "UPTI-" + data.requestNo
            }
        }

        reportFile(data, true, `UPTI-${reqNo}.pdf`)
        // PrintElem(print.data)
        // return print
    }

    showModalEdit = async (data, type) => {
        var loadAPI = `order-requests-ipo/${data.orderRequestId}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var kajian = atob(response.orderRequestDTO.kajian)
        var dataDetail = []
        data = {
            productId: response.orderRequestDTO.productId,
            fundManagerId: response.orderRequestDTO.fundManagerId,
            tanggalOrder: response.orderRequestDTO.orderDate,
            tanggalPengajuan: response.orderRequestDTO.requestDate,
            orderTypeId: response.orderRequestDTO.orderTypeId,
            requestId: data.orderRequestId,
            requestNo: '<AUTO>',
            actionType: 'edit',
            kategori: 'Saham',
        }
        for (var ipo of response.orderIpoInfos) {
            for (var detail of ipo.orderIpoDTOList) {
                var sahamInfo = {
                    id: detail.id,
                    brokerId: detail.brokerId,
                    currencyId: 1001,
                    emitenId: detail.emitenId,
                    etfMarket: detail.market,
                    notes: detail.notes,
                    instrumentCategoryId: 1,
                    kurs: 1,
                    nominalPenyertaan: detail.amountOrder,
                    rekBankId: detail.rekBankId,
                }
            }
            dataDetail.push(sahamInfo)
        }
        if (type === 'edit') {
            this.formPenyertaanIpoSahamEditRef.current.show(data, dataDetail, kajian)            
            this.formPenyertaanIpoSahamEditRef.current.retrieveData(data, dataDetail, kajian)
        }
    }
    cancelData = async (data) => {
        var reqId = data.requestId
        if (reqId) {
            try {
                var insertAPI = `order-request-batal?orderRequestId=${reqId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: {}
                })
                notify({ message: 'Pembatalan berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    showModalInsert = (type) => {
        if (type) {
            this.formPenyertaanIpoSahamRef.current.show()
            this.formPenyertaanIpoSahamRef.current.retrieveData()
        }
    }
    forceRefresh = () => {
        this.DataGridOrderSahamRef.current.forceRefresh(true)
    }
    showModalView = (data) => {
        var dataMaster = { ...data }
        var dataPembelian = dataMaster.detail
        delete dataMaster.detail
        this.formPenyertaanIpoSahamRef.current.show(dataMaster, dataPembelian)
    }

    customDetailElem = (data) => {
        return (
            <DetailPenyertaanIpoSaham detailData={data.orderRequestId} store={this.props.store} />
        )
    }

    // loadData = async () => {
    //     try {
    //         let loadAPI = 'view-order-requests-ipo-penyertaans?statusId.in=DRAFT&statusId.in=INPROGRESS';
    //         let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
    //         let dataArr = [];
    //         console.log(response);

    //         for (let value of response) {
    //             let loadDataDTO = `order-requests-ipo/${value.orderRequestId}`;
    //             let responseDTO = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadDataDTO, 'GET');

    //             dataArr.push(responseDTO.orderRequestDTO);
    //         }
    //         let uniqueData = dataArr.reduce((accumulator, current) => {
    //             if (!accumulator.some(item => item.id === current.id)) {
    //                 accumulator.push(current);
    //             }
    //             return accumulator;
    //         }, []);

    //         return response;
    //     } catch (error) {
    //         console.log(error);
    //         return [{}]
    //     }
    // }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengajuan Penyertaan IPO Saham</h2>
                <DevExpressDataGrid
                    ref={this.DataGridOrderSahamRef}
                    loadAPI='view-order-requests-ipo-penyertaans?statusId.in=DRAFT&statusId.in=INPROGRESS'
                    insertAPI='order-stoks'
                    updateAPI='order-stoks'
                    deleteAPI='order-stoks'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Order Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Order Saham'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem={this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPenyertaanIpoSaham store={this.props.store} actionType={'add'} ref={this.formPenyertaanIpoSahamRef} forceRefresh={this.forceRefresh} />
                <FormPengajuanPenyertaanIpoSaham store={this.props.store} ref={this.formPenyertaanIpoSahamEditRef} forceRefresh={this.forceRefresh} />
            </div>
        )
    }
}

export default PenyertaanIpoSaham