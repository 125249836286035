import React, { Component } from 'react'
import { ScrollView,Popup,Form } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import notify from "devextreme/ui/notify";
import { months, productDefault } from 'dataSource/lookup';
import ArrayStore from 'devextreme/data/array_store';

class FormModalGenerateAmortisasi extends Component {
    constructor(props){
        super(props)
        this.state = {
            popupVisible: false,
            DataMaster: {}
        }
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: (e) => {
                        this.generateAmortisasi()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField:'productId',
                label:{
                    text:'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    onValueChanged: async(e) => {
                        var selectKpd = this.formRef.current.instance.getEditor('kpdId')
                        var month = this.formRef.current.instance.getEditor('month').option('value')
                        var year = this.formRef.current.instance.getEditor('year').option('value')
                        var dataKpd = await this.kpdByProduct(this.props.store, e.value)
                        selectKpd.option('dataSource', dataKpd)
                        this.generateTransaksiAmortisasi(e.value, selectKpd.option('value'), month, year)
                    }
                }
            },
            {
                dataField:'kpdId',
                label:{
                    text:'KPD'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'noKontrak',
                    searchEnabled: true,
                    onValueChanged: (e) => {
                        var kpdId = e.value
                        var productId = this.formRef.current.instance.getEditor('productId').option('value')
                        var month = this.formRef.current.instance.getEditor('month').option('value')
                        var year = this.formRef.current.instance.getEditor('year').option('value')
                        this.generateTransaksiAmortisasi(productId, kpdId, month, year)
                    }
                }
            },
            {
                dataField:'month',
                label:{
                    text:'Periode Bulan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    onValueChanged: (e) => {
                        var kpdId = this.formRef.current.instance.getEditor('kpdId').option('value')
                        var productId = this.formRef.current.instance.getEditor('productId').option('value')
                        var month = e.value
                        var year = this.formRef.current.instance.getEditor('year').option('value')
                        this.generateTransaksiAmortisasi(productId, kpdId, month, year)
                    }
                }
            },
            {
                dataField:'year',
                label:{
                    text:'Periode Tahun'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    step: 0,
                    onValueChanged: (e) => {
                        var kpdId = this.formRef.current.instance.getEditor('kpdId').option('value')
                        var productId = this.formRef.current.instance.getEditor('productId').option('value')
                        var month = this.formRef.current.instance.getEditor('month').option('value')
                        var year = e.value
                        this.generateTransaksiAmortisasi(productId, kpdId, month, year)
                    }
                }
            },
            {
                itemType: 'group',
                caption:'Informasi Portofolio Hold To Maturity',
                items: [
                    {
                        dataField: 'scheduled',
                        label: {
                            text: 'SBN/Obligasi Sudah Terjadwal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions:{
                            step: 0,
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'unScheduled',
                        label: {
                            text: 'SBN/Obligasi Belum Terjadwal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions:{
                            step: 0,
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'couponCurrentMonth',
                        label: {
                            text: 'Penerimaan Kupon Bulan Ini'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions:{
                            step: 0,
                            readOnly:true
                        },
                    },
                ]
            },
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
    }
    kpdByProduct = async (store, productId) => {
        let loadAPI = `kpds?productId.in=${productId}`
        var getKpd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getKpd,
            key: 'id'
        });

        return dataSource
    }
    generateAmortisasi = async () => {
        var data = this.state.DataMaster
        try {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `transaction/transaksi-amortisasi-generate/${data.productId}/${data.kpdId}/${data.month}/${data.year}`,
                'POST',
                {values: {}}
            )
            if(response){
                this.hide()
                this.props.forceRefresh()
            }
            notify({ message: 'Proses Sukses!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600)
        }
    }
    generateTransaksiAmortisasi = async(productId, kpdId, month, years) => {
        var loadAPI = `efek-amortisasi-jadwals/jadwal-check/${productId}/${kpdId}/${month}/${years}`
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadAPI,
            'GET'
        )
        this.formRef.current.instance.updateData({scheduled: response.scheduled, unScheduled: response.unScheduled, couponCurrentMonth: response.couponCurrentMonth})
    }
    show = async(data) => {
        this.setState({
            DataMaster: {},
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {}
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'35vw'}
                    height="40vh"
                    title={`Generate Transaksi Amortisasi`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'dataMaster'}
                            formData={this.state.DataMaster}
                            items={this.dataMaster}
                            labelLocation={"left"}  
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalGenerateAmortisasi