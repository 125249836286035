import React, { Component } from 'react'
import PieChart, { Series, Size, Legend } from 'devextreme-react/pie-chart';
import httpRequest from 'plugin/httprequest';
import { getKomposisi } from 'dataSource/lookup';

class KomposisiInvestasi extends Component {
    constructor(props) {
        super(props)


        this.label = {
            visible: true,
            font: {
                size: 13
            },
            connector: {
                visible: true,
                width: 0.5
            },
            position: "outside",
            customizeText: function (arg) {
                return Math.abs(arg.valueText).toFixed(2) + " (" + arg.percentText + ")";
            }
        }
    }

    pointClickHandler(e) {
        this.toggleVisibility(e.target);
    }

    legendClickHandler(e) {
        let arg = e.target;
        let item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

        this.toggleVisibility(item);
    }

    toggleVisibility(item) {
        item.isVisible() ? item.hide() : item.show();
    }

    render() {
        return (
            <React.Fragment>
                <PieChart
                    id="pie"
                    dataSource={getKomposisi(this.props.store)}
                    palette="Soft Pastel"
                    onPointClick={this.pointClickHandler.bind(this)}
                    onLegendClick={this.legendClickHandler.bind(this)}
                    resolveLabelOverlapping="shift"
                >
                    <Legend
                        orientation="vertical"
                        itemTextPosition="right"
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                        columnCount={4}
                    />
                    <Series
                        argumentField="namaKomposisi"
                        valueField="komposisiPercent"
                        label={this.label}
                    />

                    <Size width={500} height={345} />
                </PieChart>
            </React.Fragment>
        )
    }
}

export default KomposisiInvestasi
