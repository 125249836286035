import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, branch, currency } from 'dataSource/lookup';


class ModalRekeningReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.datagridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        console.log(e)
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.columns = [
            {
                dataField: 'id',
                caption: 'No.'
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'bankAccountNo',
                caption: 'Nomor Akun Bank'
            },
            {
                dataField: 'bankAccountName',
                caption: 'Nama Akun Bank'
            },
            {
                dataField: 'branchId',
                caption: 'Cabang',
                lookup: {
                    dataSource: branch(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'branchName'
                }
            },
            {
                dataField: 'type',
                caption: 'A/C Tipe'
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName'
                }
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    loadData = () => {
        return []
    }
    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'65vw'}
                    height="50vh"
                    title={`Form Data Produk`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid py-3">
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <DevExpressDataGrid
                                    loadAPI='rekening'
                                    insertAPI='rekening'
                                    updateAPI='rekening'
                                    deleteAPI='rekening'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                                    useArraySource={true}
                                    ArraySourceData={this.loadData}

                                    allowAdding={true}
                                    allowDeleting={true}
                                    allowUpdating={true}
                                    exportExcel={true}
                                    exportFileName={"Instrumen Reksadana"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}
                                    height={300}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Form Edit Rekening'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={350} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing={this.onToolbarPreparing}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalRekeningReksadana