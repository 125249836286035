import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form, Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, banks } from "dataSource/lookup";
import DataGridDetailInstruksiPenempatanTerkirim from "./datagrid/penempatan";
import DataGridDetailInstruksiPelepasanTerkirim from "./datagrid/pelepasan";
import DataGridDetailInstruksiPerpanjanganTerkirim from "./datagrid/perpanjangan";

class FormInstruksiBankTerkirim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            DataMaster: {}
        };

        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    text: 'ID'
                }
            },
            {
                dataField: 'bankId',
                label: {
                    text: 'Bank'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName',
                    readOnly: true
                }
            },
            {
                dataField: 'transactionName',
                label: {
                    text: 'Transaksi'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'nomorInstruksi',
                label: {
                    text: 'Nomor Instruksi'
                }
            },
            {
                dataField: 'tanggalInstruksi',
                label: {
                    text: 'Tanggal Instruksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true
                }
            },
            {
                dataField: 'tanggalOrder',
                label: {
                    text: 'Tanggal Order'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true
                }
            },
            {
                dataField: 'pejabatInstruksi1',
                label: {
                    text: 'Pejabat 1'
                },
                editorType: 'dxSelectBox'
            },
            {
                dataField: 'pejabatInstruksi2',
                label: {
                    text: 'Pejabat 2'
                },
                editorType: 'dxSelectBox'
            },
            {
                dataField: 'note',
                label: {
                    text: 'Catatan Instruksi'
                },
                editorType: 'dxTextArea',
            },
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Cetak Instruksi',
                    onClick: () => {
                        // this.submitData()
                        console.log(this.state.type)
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.formRef = React.createRef()
    }


    retrieveData = async (data) => {
        this.setState({
            DataMaster: data,
            type: data.type,
        })
    }

    updateData = async () => {
        this.hide()
    }

    show = () => {
        this.setState({
            popupVisible: true
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    dummyPenempatan = () => {
        var dummy = [
            {
                id: 1,
            },
        ]
        return dummy
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Form Instruksi Bank"}
                    width={'90vw'}
                    height={'90vh'}
                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                        <div className="row mb-2">
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={"formMaster"}
                                    formData={this.state.DataMaster}
                                    items={this.dataMaster}
                                    ref={this.formRef}
                                    labelLocation="left"
                                />
                            </div>
                            {
                                this.state.type == 'Penempatan' ? 
                                <DataGridDetailInstruksiPenempatanTerkirim tipe={this.props.tipe} /> : this.state.type == 'Pelepasan' ? 
                                <DataGridDetailInstruksiPelepasanTerkirim tipe={this.props.tipe} /> : this.state.type == 'Perpanjangan' ? 
                                <DataGridDetailInstruksiPerpanjanganTerkirim tipe={this.props.tipe} /> : null
                            }
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }
}

export default FormInstruksiBankTerkirim