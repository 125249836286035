import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { banks, instrumentSubCategoryDeposito, ordersOpen, depositoWasSent, kpds, productDefault, instrumentTransactionTypes, instruments, currency, orders, orderPropertiOpen } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import { formatDate, summaryValue } from 'plugin/helper';
import ArrayStore from 'devextreme/data/array_store';
import ModalActionSettlementTransaksiProperti from './actionForm';
import { showLoading } from 'redux/actions/loading';


class ModalSettlementTransaksiProperti extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            biayaBank: {},
            actionType: null
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridTransaksiRef = React.createRef()
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.loadDummyData = []

        this.itemPenempatanMaster = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'No Konfirmasi'
                },
                editorOptions: {
                    onValueChanged: (e) => {
                        this.setState({
                            dataMaster: {
                                noKonfirmasi: e.value
                            }
                        })
                    },
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill confirmation Number"
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (e) => {
                        this.rekBankByProduct(this.props.store, e.value)
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Product"
                    }
                ]
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal transaksi'
                },
                editorType: "dxDateBox",
                editorOptions: {
                    displayFormat: "dd MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill Transaction Date"
                    }
                ]
            },
            {
                dataField: 'settlementDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal settle'
                },
                editorType: "dxDateBox",
                editorOptions: {
                    displayFormat: "dd MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill Settlement Date"
                    }
                ]
            },
            {
                dataField: 'rekeningProduct',
                label: {
                    alignment: 'left',
                    text: 'Rekening Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Product"
                    }
                ]
            },
        ]
        this.penempatanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            data.bankId = this.state.dataMaster.bankId
                            this.modalInsertRef.current.show(data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        onClick: (e) => {
                            this.deleteDetailPenempatanData(e.row.key)
                        }
                    },
                ],
            },
            {
                dataField: 'no',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'transactionTypeId',
                caption: 'Transaksi',
                lookup: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName'
                }
            },
            {
                dataField: 'instrumentId',
                caption: 'Properti',
                lookup: {
                    dataSource: instruments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'tipe',
                caption: 'Tipe/Unit/Kav',
            },
            {
                dataField: 'hargaUnit',
                caption: 'Harga',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField: 'komisi',
                caption: 'Komisi %',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'kominiNominal',
                caption: 'Komisi Nominal',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'ppn',
                caption: 'PPN',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'ppnBm',
                caption: 'PPN BM',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'biayaLain',
                caption: 'Biaya Lain',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'pbb',
                caption: 'PBB',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'orderId',
                caption: 'Order Code',
                lookup: {
                    dataSource: orderPropertiOpen(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'orderCode'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName'
                }
            },
            {
                dataField: 'kurs',
                caption: 'Kurs',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    rekBankByProduct = async (store, productId) => {
        let loadAPI = `rek-banks-by-productId?productId=${productId}`
        var getRekening = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getRekening,
            key: 'id'
        });

        this.formRef.current.instance.getEditor('rekeningProduct').option('dataSource', dataSource)
    }
    cleanDataGrid = () => {
        var data = this.dataGridTransaksiRef.current.getDataSource()
        var deleteData = []
        if (data) {
            for (var value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            this.dataGridTransaksiRef.current.push(deleteData)
        }
    }
    submitData = async () => {
        this.props.store.dispatch(showLoading(true))

        var dataDataGrid = this.dataGridTransaksiRef.current.getDataSource()
        var dataMaster = this.state.dataMaster
        var nilaiTrsBersih = []
        var nilaiTrs = []
        var totBiayaTransaksi = []
        for (var detail of dataDataGrid) {
            let biayaTransaksi = detail.kominiNominal + detail.ppn + detail.ppnBm + detail.pbb + detail.biayaLain

            nilaiTrsBersih.push(detail.nilaiTransaksiBersih)
            nilaiTrs.push(detail.nilaiTransaksi)
            totBiayaTransaksi.push(biayaTransaksi)
        }
        var customSubmitData = {
            ...dataMaster,
            settlePropertiDtlDTOList: dataDataGrid,
            sumNetAmount: nilaiTrsBersih.reduce((a, b) => a + b, 0),
            sumBiayaTransaksi: totBiayaTransaksi.reduce((a, b) => a + b, 0),
            sumJumlahTransaction: nilaiTrs.reduce((a, b) => a + b, 0),
            totalInstument: dataDataGrid.length
        }
        if (customSubmitData) {
            try {
                var insertAPI = 'order-propertis/settle-properti'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: customSubmitData
                })

                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Konfirmasi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.cleanDataGrid()
                this.props.forceRefresh()
                return response
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }

    onToolbarPreparingPenempatan = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Transaksi',
                    icon: 'plus',
                    onClick: (e) => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.showModalInsert()
                        }
                    }
                }
            }
        )
    }
    forceRefresh = () => {
        this.dataGridTransaksiRef.current.forceRefresh(true)
    }
    showModalInsert = () => {
        this.modalInsertRef.current.show({
            productId: this.state.dataMaster.productId,
            transactionDate: formatDate(this.state.dataMaster.transactionDate)
        })
    }
    submitDetailPenempatanData = (data) => {
        if (data.id) {
            this.dataGridTransaksiRef.current.push([{
                type: "update",
                data: {
                    biayaBankRtgs: data.biayaBankRtgs,
                    biayaLain: data.biayaLain,
                    currencyId: data.currencyId,
                    hargaUnit: data.hargaUnit,
                    instrumentId: data.instrumentId,
                    keterangan: data.keterangan,
                    kominiNominal: data.kominiNominal,
                    komisi: data.komisi,
                    kurs: data.kurs,
                    nilaiTransaksi: data.nilaiTransaksi,
                    nilaiTransaksiBersih: data.nilaiTransaksiBersih,
                    orderPropertiId: data.orderPropertiId,
                    orderId: data.orderId,
                    pbb: data.pbb,
                    pembeli: data.pembeli,
                    pph: data.pph,
                    ppn: data.ppn,
                    ppnBm: data.ppnBm,
                    properti: data.properti,
                    rekeningTransferId: data.rekeningTransferId,
                    tipe: data.tipe,
                    transactionTypeId: data.transactionTypeId
                },
                key: data.id
            }])
        } else {
            this.dataGridTransaksiRef.current.push([{
                type: "insert",
                data: {
                    biayaBankRtgs: data.biayaBankRtgs,
                    biayaLain: data.biayaLain,
                    currencyId: data.currencyId,
                    hargaUnit: data.hargaUnit,
                    instrumentId: data.instrumentId,
                    keterangan: data.keterangan,
                    kominiNominal: data.kominiNominal,
                    komisi: data.komisi,
                    kurs: data.kurs,
                    nilaiTransaksi: data.nilaiTransaksi,
                    nilaiTransaksiBersih: data.nilaiTransaksiBersih,
                    orderPropertiId: data.orderPropertiId,
                    orderId: data.orderId,
                    pbb: data.pbb,
                    pembeli: data.pembeli,
                    pph: data.pph,
                    ppn: data.ppn,
                    ppnBm: data.ppnBm,
                    properti: data.properti,
                    rekeningTransferId: data.rekeningTransferId,
                    tipe: data.tipe,
                    transactionTypeId: data.transactionTypeId
                }
            }])
        }
    }
    deleteDetailPenempatanData = (key) => {
        this.dataGridTransaksiRef.current.push([{
            type: "remove",
            key: key
        }])
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Aset Properti'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                    <div className="row">
                        <div id="tabindex-0" className={'mt-2 col-md-12 text-center'}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        ref={this.formRef}
                                        formData={this.state.dataMaster}
                                        items={this.itemPenempatanMaster}
                                        labelLocation={"left"}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <DevExpressDataGrid
                                        ref={this.dataGridTransaksiRef}
                                        loadAPI='cash-deviden'
                                        insertAPI='cash-deviden'
                                        updateAPI='cash-deviden'
                                        deleteAPI='cash-deviden'

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.loadDummyData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Penempatan"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}
                                        height={300}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Penempatan'}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={500} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.penempatanColumns} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        onToolbarPreparing={this.onToolbarPreparingPenempatan}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <ModalActionSettlementTransaksiProperti
                                    ref={this.modalInsertRef}
                                    store={this.props.store}
                                    forceRefresh={this.forceRefresh}
                                    submitDetailPenempatanData={this.submitDetailPenempatanData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalSettlementTransaksiProperti