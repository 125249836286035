import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import FormDataPersonal from '../../compPeserta/dataPersonal';
import DataGridKeluarga from '../../compPeserta/dataKeluarga';
import DataGridRiwayatPengkinian from '../../compPeserta/dataRiwayat';
import httpRequest from 'plugin/httprequest';
import FormDataKepegawaianAndSk from '../../compPeserta/dataKepegawaianAndSk';
import FormDataKepegawaian from '../../compPeserta/dataKepegawaian';
import { ScrollView } from '../../../../../../../node_modules/devextreme-react';
import FormDataAkun from '../../compPeserta/dataAkun';

class ShowPesertaAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            pesertaId: 0,
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.formDataKepegawaianRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.dataGridRiwayatPengkinianRef = React.createRef()
        this.formDataAkunRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Personal'
            },
            {
                id: 1,
                text: 'Data Kepegawaian'
            },
            {
                id: 2,
                text: 'Data Keluarga'
            },
            {
                id: 3,
                text: 'Data Akun'
            },
            // {
            //     id: 3,
            //     text: 'Riwayat Pengkinian'
            // },
        ]
        
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                disabled: true,
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                }
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                  }
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async(data = null) => {
        try {
            var loadAPIPeserta = `pesertas/${data.pesertaId || data.id}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
        } catch (e) {
            console.log(e)
        }

        try {
            var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${data.pesertaId || data.id}`
            var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKeluarga, 'GET')
        } catch (e) {
            console.log(e)
        }
        
        try {
            var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${data.pesertaId || data.id}`
            var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')
        } catch (e) {
            console.log(e)
        }

        try {
            var loadAPIAkun = `kepersertaan-products/getAllSimpleProductByPesertaId/${data.pesertaId || data.id}`
            var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
        } catch (e) {
            console.log(e)
        }

        // var loadAPIAkun = `kepersertaan-products/getAllSimpleProductByPesertaId/${data.id}`
        // var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')

        if(data){
            await this.formDataPersonalRef.current.retrieveData(getPeserta, 'akunPesertaAktif')
            await this.formDataKepegawaianRef.current.retrieveData(getPeserta.id,getKepegawaian)
            await this.dataGridKeluargaRef.current.reloadData(getPeserta.id,getKeluarga)
            // await this.dataGridRiwayatPengkinianRef.current.reloadData(getPeserta)
            await this.formDataAkunRef.current.reloadData(getPeserta.id,getAkun)
        }

        this.setState({
            dataMaster: getPeserta,
            pesertaId: getPeserta.id, 
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Akun Peserta Aktif'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row" style={{height: 'calc(90vh - 325px'}}>
                        <div className="col-md-12">
                            <Form
                                colCount={3}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <ScrollView width='100%' height='100%'>
                            <div id="tabindex-0" className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataPersonal store={this.props.store} actionType={this.props.actionType} ref={this.formDataPersonalRef}/>
                            </div>
                            <div id="tabindex-1" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataKepegawaian store={this.props.store} actionType={this.props.actionType} ref={this.formDataKepegawaianRef}/>
                            </div>
                            <div id="tabindex-2" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <DataGridKeluarga store={this.props.store} actionType={this.props.actionType} ref={this.dataGridKeluargaRef}/>
                            </div>
                            {/* <div id="tabindex-3" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <DataGridRiwayatPengkinian store={this.props.store} actionType={this.props.actionType} ref={this.dataGridRiwayatPengkinianRef} statusPeserta={this.state.statusPeserta}/>
                            </div> */}
                            <div id="tabindex-3" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(30vh)'}}>
                                <FormDataAkun store={this.props.store} actionType={this.props.actionType} ref={this.formDataAkunRef} saveDataAkun={this.saveDataAkun} from={'akun_peserta_aktif'}/>
                            </div>
                        </ScrollView>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ShowPesertaAktif
