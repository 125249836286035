import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';
import { findLastDayOfMonth, formatDate, formatDefaultDate, formatDefaultFullDate } from 'plugin/helper';
import ModalGeneralLedger from 'components/pages/modal/akutansi/report/generalLedger';
import { showLoading } from 'redux/actions/loading';
import { reportFile } from 'plugin/reportRequest';
import { product } from 'dataSource/lookup';

class ProfitLoss extends Component {
  constructor(props) {
    super(props)

    this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

    this.state = {
      tanggal: this.systemDate ? new Date(this.systemDate.dateValue) : null,
      formFilter: {
        period: this.systemDate ? new Date(this.systemDate.dateValue) : null,
        produkId: null
      }
    }

    this.dataGridRef = React.createRef()
    this.modalGeneralLedgerRef = React.createRef()
    this.date = []

    this.filterItem = [
      {
        dataField: "period",
        label: {
          text: "Periode",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'MMM yyyy',
          calendarOptions: {
            maxZoomLevel: 'year'
          },
          openOnFieldClick: true,
          onValueChanged: () => {
            this.dataGridRef.current.DataGrid.deleteColumn('currentPeriod')
            this.dataGridRef.current.DataGrid.deleteColumn('prevPeriod')

            let period = formatDefaultFullDate(this.state.formFilter.period).split(' ');
            period = `${period[1]} ${period[2]}`;
            console.log(period);

            this.setState({
              tanggal: period
            })
          }
        }
      },
      {
        dataField: "produkId",
        label: {
          text: "Produk",
          alignment: "left",
          location: "left"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: product(this.props.store),
          displayExpr: function (item) {
            return item && item.productCode + " - " + item.productName;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: (e) => {
              this.setNewHeaderTitle()
              this.filterSubmit()
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Detail',
            hint: 'Detail',
            cssClass: 'text-success',
            onClick: async (e) => {
              if (e.row.data.parentId) {
                var coamap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${e.row.data.parentId}`)
                coamap = coamap.map(value => value.coaId)
                if (e.row.data.level !== 1) {
                  this.modalGeneralLedgerRef.current.show(coamap, this.state.formFilter.period)
                }
              } else {
                alert('Baris data ini tidak memiliki mapping / hanya ringkasan data', 'Warning')
              }
            }
          },
        ],
      },
      {
        dataField: 'description',
        caption: 'Deskripsi',
        grouping: 0,
        cellRender: (e) => {
          if (e.row.data.level === 1) {
            return <b>{e.value}</b>
          } else {
            return <span className="ml-3">{e.value}</span>
          }
        }
      },
      {
        dataField: 'currentPeriod',
        caption: 'Periode Berjalan',
        alignment: 'right',
        columns: [
          {
            dataField: 'currentAmountDisplay',
            caption: this.headerTitle('current'),
            format: "#,##0.00;(#,##0.00);",
            alignment: 'right',
            width: '150px',
            cellRender: (e) => {
              if (e.row.data.level === 1) {
                return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
              } else {
                return <span className="d-flex justify-content-end">{e.text}</span>
              }
            }
          },
        ]
      },
      {
        dataField: 'prevPeriod',
        caption: 'Periode Sebelumnya',
        alignment: 'right',
        columns: [
          {
            dataField: 'prevAmountDisplay',
            caption: this.headerTitle('prev'),
            format: "#,##0.00;(#,##0.00);",
            alignment: 'right',
            width: '150px',
            cellRender: (e) => {
              if (e.row.data.level === 1) {
                return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
              } else {
                return <span className="d-flex justify-content-end">{e.text}</span>
              }
            }
          },
        ]
      },
    ]

    this.dataLabaRugi = []
    this.dataResult = []
    this.lastYearDate = new Date()
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  componentDidMount = () => {
    this.dataGridRef.current.forceRefresh(true)
  }

  filterSubmit = async (OJKProdukId = null, OJKTanggal = null) => {
    let getYear = new Date(this.state.formFilter.period).getFullYear()
    let getMonth = new Date(this.state.formFilter.period).getMonth()
    let date = findLastDayOfMonth(getYear, getMonth)
    let loadAPI = `journal-headers/getHasilUsaha/${formatDate(date)}/${this.state.formFilter.produkId}`
    let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET')
    this.dataLabaRugi = response
    this.dataResult = response

    this.dataGridRef.current.forceRefresh(true)

    // const produkId = OJKProdukId || this.state.formFilter.produkId
    // const period = OJKTanggal || this.state.formFilter.period ? this.state.formFilter.period : null
    // if (produkId === null || produkId === '') {
    //   return []
    // }

    // var systemDate = new Date(this.systemDate.dateValue)
    // const systemMonth = systemDate.getMonth()
    // const systemYear = systemDate.getFullYear();
    // systemDate = new Date(systemYear, systemMonth + 1, 0, 0, 0, 0, 0);

    // var selectedDate = new Date(period);
    // const selectedMonth = selectedDate.getMonth()
    // const selectedYear = selectedDate.getFullYear();
    // selectedDate = new Date(selectedYear, selectedMonth + 1, 0, 0, 0, 0, 0);

    // if (OJKProdukId) {
    //   var lastYearDate = new Date(selectedYear, selectedMonth, 0, 0, 0, 0, 0)
    //   this.lastYearDate = lastYearDate
    // } else {
    //   var lastYearDate = new Date(selectedYear - 1, 12, 0, 0, 0, 0, 0)
    //   var lastPrePeriodeYearDate = new Date(selectedYear - 2, 12, 0, 0, 0, 0, 0)
    //   // var lastYearBeginingDate = new Date(selectedYear-1,12,0,0,0,0,0)

    //   this.lastYearDate = lastYearDate
    // }

    // var dataCoaPosition
    // if (selectedDate < systemDate) {
    //   dataCoaPosition = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(selectedDate)}`)
    //   // dataCoaPosition = dataCoaPosition.filter(value => value.produkId === produkId)
    // } else {
    //   dataCoaPosition = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-positions/getByProdukId/${produkId}`)
    // }

    // var dataCoaPositionLastYear = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(lastYearDate)}`)
    // var dataCoaPositionLastYearPrePeriode = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(lastPrePeriodeYearDate)}`)
    // // dataCoaPositionLastYear = dataCoaPositionLastYear.filter(value => value.produkId === produkId)
    // // var dataCoaPositionBeginingLastYear = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(lastYearBeginingDate)}`)
    // // dataCoaPositionBeginingLastYear = dataCoaPositionBeginingLastYear.filter(value => value.produkId === produkId)

    // dataCoaPosition = dataCoaPosition.map(value => {
    //   const dataPositionLastYear = dataCoaPositionLastYear.find(valueLast => value.coaId == valueLast.coaId)
    //   const dataPositionLastPrevYear = dataCoaPositionLastYearPrePeriode.find(valueLast => value.coaId == valueLast.coaId)
    //   // const dataPositionBeginingLastYear = dataCoaPositionBeginingLastYear.find(valueLast => value.coaId == valueLast.coaId)

    //   if (dataPositionLastYear) {
    //     value.amount = value.balanceAmountBaseCurrency - dataPositionLastYear.balanceAmountBaseCurrency
    //     // if(dataPositionBeginingLastYear){
    //     //   value.prevAmount = dataPositionLastYear.balanceAmountBaseCurrency - dataPositionBeginingLastYear.balanceAmountBaseCurrency
    //     // }else{
    //     value.prevAmount = dataPositionLastYear.balanceAmountBaseCurrency - dataPositionLastPrevYear.balanceAmountBaseCurrency
    //     // }
    //   } else {
    //     value.amount = value.balanceAmountBaseCurrency
    //     value.prevAmount = 0
    //   }
    //   value.level = 1
    //   return value
    // })

    // var dataReportHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/LK')
    // dataReportHeader = dataReportHeader.find(value => value.reportCode === 'LHU')
    // var dataMapping = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataReportHeader.id}`)

    // var dataMappingParent = dataMapping.filter(value => value.parentId === null)
    // if (dataMappingParent.length > 0) {
    //   dataMappingParent = dataMappingParent.sort((a, b) => {
    //     if (a.seqNo > b.seqNo) {
    //       return 1
    //     }
    //     if (a.seqNo === b.seqNo) {
    //       return 0
    //     }
    //     if (a.seqNo < b.seqNo) {
    //       return -1
    //     }
    //   })
    // }

    // var data = [], index = 0, total = 0, prevTotal = 0
    // for (const parent of dataMappingParent) {
    //   var totalAmount = 0, prevTotalAmount = 0, totalAmountDisplay = 0, prevTotalAmountDisplay = 0

    //   var dataMappingChild = dataMapping.filter(value => value.parentId === parent.id)
    //   if (dataMappingChild.length > 0) {
    //     data.push({
    //       id: index++,
    //       description: parent.description,
    //       currentAmount: null,
    //       prevAmount: null,
    //       level: 0,
    //       reff: parent.id
    //     })

    //     dataMappingChild = dataMappingChild.sort((a, b) => {
    //       if (a.seqNo > b.seqNo) {
    //         return 1
    //       }
    //       if (a.seqNo === b.seqNo) {
    //         return 0
    //       }
    //       if (a.seqNo < b.seqNo) {
    //         return -1
    //       }
    //     })

    //     for (const child of dataMappingChild) {
    //       let childAmount = 0, prevChildAmount = 0, childAmountDisplay = 0, prevChildAmountDisplay = 0
    //       var dataMappingCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${child.id}`)

    //       for (const coa of dataMappingCoa) {
    //         const position = dataCoaPosition.find(value => value.coaId === coa.coaId)
    //         if (position) {
    //           childAmountDisplay = childAmountDisplay + (position.amount * (coa.type === 'n' ? -1 : 1))
    //           prevChildAmountDisplay = prevChildAmountDisplay + (position.prevAmount * (coa.type === 'n' ? -1 : 1))
    //           childAmount = childAmount + position.amount
    //           prevChildAmount = prevChildAmount + position.prevAmount
    //         }
    //       }

    //       data.push({
    //         id: index++,
    //         description: child.description,
    //         currentAmount: childAmount,
    //         prevAmount: prevChildAmount,
    //         currentAmountDisplay: childAmountDisplay,
    //         prevAmountDisplay: prevChildAmountDisplay,
    //         level: 1,
    //         reff: child.id,
    //         seqNo: child.seqNo
    //       })

    //       totalAmount = totalAmount + childAmount
    //       prevTotalAmount = prevTotalAmount + prevChildAmount
    //       totalAmountDisplay = totalAmountDisplay + childAmountDisplay
    //       prevTotalAmountDisplay = prevTotalAmountDisplay + prevChildAmountDisplay
    //     }
    //     total = total + totalAmount
    //     prevTotal = prevTotal + prevTotalAmount
    //     data.push({
    //       id: index++,
    //       description: 'TOTAL ' + parent.description,
    //       currentAmount: totalAmount,
    //       prevAmount: prevTotalAmount,
    //       currentAmountDisplay: totalAmountDisplay,
    //       prevAmountDisplay: prevTotalAmountDisplay,
    //       level: 0,
    //       reff: parent.id
    //     })
    //   } else {
    //     data.push({
    //       id: index++,
    //       description: 'HASIL USAHA SEBELUM PAJAK',
    //       currentAmount: total,
    //       prevAmount: prevTotal,
    //       currentAmountDisplay: total * -1,
    //       prevAmountDisplay: prevTotal * -1,
    //       level: 0
    //     })

    //     let childAmount = 0, prevChildAmount = 0, childAmountDisplay = 0, prevChildAmountDisplay = 0
    //     var dataMappingCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${parent.id}`)
    //     for (const coa of dataMappingCoa) {
    //       const position = dataCoaPosition.find(value => value.coaId === coa.coaId)
    //       if (position) {
    //         childAmountDisplay = childAmountDisplay + (position.amount * (coa.type === 'n' ? -1 : 1))
    //         prevChildAmountDisplay = prevChildAmountDisplay + (position.prevAmount * (coa.type === 'n' ? -1 : 1))
    //         childAmount = childAmount + position.amount
    //         prevChildAmount = prevChildAmount + position.prevAmount
    //       }
    //     }
    //     data.push({
    //       id: index++,
    //       description: parent.description,
    //       currentAmount: childAmount,
    //       prevAmount: prevChildAmount,
    //       currentAmountDisplay: childAmountDisplay,
    //       prevAmountDisplay: prevChildAmountDisplay,
    //       level: 0,
    //       reff: parent.id,
    //       seqNo: parent.seqNo
    //     })
    //     data.push({
    //       id: index++,
    //       description: 'HASIL USAHA SETELAH PAJAK',
    //       currentAmount: total + childAmount,
    //       prevAmount: prevTotal + prevChildAmount,
    //       currentAmountDisplay: (total + childAmount) * -1,
    //       prevAmountDisplay: (prevTotal + prevChildAmount) * -1,
    //       level: 0
    //     })
    //   }
    // }

    // this.dataLabaRugi = data

    // // if(this.state.formFilter.period){
    // //   var formattedDate = formatDefaultDate(this.state.formFilter.period)
    // //   this.setState({
    // //     period: formattedDate.slice(2,formattedDate.length)
    // //   })
    // // }
    // return data
    // this.dataGridRef.current.forceRefresh(true)
  }

  loadData = () => {
    return this.dataResult
  }

  getMonthYear = (date) => {
    if (date) {
      let periodDate = this.state.formFilter.period ? this.state.formFilter.period : null;
      let selectedMonth = periodDate.getMonth();
      let selectedYear = periodDate.getFullYear();
      periodDate = formatDefaultDate(new Date(selectedYear, selectedMonth + 1, 0)).split(' ');
      periodDate = `${periodDate[1]} ${periodDate[2]}`;
      return periodDate;
    }
    return ''; // Handle the case when date is not available
  }

  print = async () => {
    this.props.store.dispatch(showLoading(true))
    var periodDate = this.state.formFilter.period ? this.state.formFilter.period : null;
    const selectedMonth = periodDate.getMonth()
    const selectedYear = periodDate.getFullYear();
    periodDate = formatDefaultDate(new Date(selectedYear, selectedMonth + 1, 0));
    var beginingYearDate = formatDefaultDate(new Date(selectedYear - 1, 12, 0));
    var prevPeriodTitle = formatDefaultDate(new Date(selectedYear, 0, 1));

    let period = formatDefaultFullDate(this.state.formFilter.period).split(' ');
    period = `${period[1]} ${period[2]}`;

    var dataLabaRugi = this.dataLabaRugi.map(value => {
      if (value.currentAmount) {
        value.currentAmount = parseFloat(value.currentAmount.toFixed(2))
      }
      if (value.prevAmount) {
        value.prevAmount = parseFloat(value.prevAmount.toFixed(2))
      }
      if (value.currentAmountDisplay) {
        value.currentAmountDisplay = parseFloat(value.currentAmountDisplay.toFixed(2))
      }
      if (value.prevAmountDisplay) {
        value.prevAmountDisplay = parseFloat(value.prevAmountDisplay.toFixed(2))
      }
      return value
    })

    var data = {
      period: periodDate,
      prevPeriod: beginingYearDate,
      prevPeriodTitle: prevPeriodTitle,
      dataProfitLoss: dataLabaRugi
    }

    reportFile({
      template: { 'shortid': 'Syxn2D9Zpu' },
      data: data,
      options: {
        reportName: `Perhitungan Hasil Usaha ${period}`
      }
    }, true, `Perhitungan Hasil Usaha ${period}`)

    console.log(this.date);

    this.props.store.dispatch(showLoading(false))
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'print',
          onClick: (e) => {
            this.print()
          },
        }
      }
    )
  }

  headerTitle = (type) => {
    var periodDate = this.state.formFilter.period ? this.state.formFilter.period : null;
    const selectedMonth = periodDate ? periodDate.getMonth() : null
    const selectedYear = periodDate ? periodDate.getFullYear() : null;
    periodDate = formatDefaultDate(new Date(selectedYear, selectedMonth + 1, 0));
    var beginingYearDate = formatDefaultDate(new Date(selectedYear - 1, 12, 0));

    if (type === 'current') {
      return periodDate
    } else {
      return beginingYearDate
    }
  }

  setNewHeaderTitle = () => {
    this.dataGridRef.current.DataGrid.deleteColumn('currentPeriod')
    this.dataGridRef.current.DataGrid.deleteColumn('prevPeriod')
    this.dataGridRef.current.DataGrid.addColumn({
      dataField: 'currentPeriod',
      caption: 'Periode Berjalan',
      alignment: 'right',
      columns: [
        {
          dataField: 'currentAmountDisplay',
          caption: this.headerTitle('current'),
          format: "#,##0.00;(#,##0.00);",
          alignment: 'right',
          width: '150px',
          cellRender: (e) => {
            if (e.row.data.level === 1) {
              return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
            } else {
              return <span className="d-flex justify-content-end">{e.text}</span>
            }
          }
        },
      ]
    })
    this.dataGridRef.current.DataGrid.addColumn({
      dataField: 'prevPeriod',
      caption: 'Periode Sebelumnya',
      alignment: 'right',
      columns: [
        {
          dataField: 'prevAmountDisplay',
          caption: this.headerTitle('prev'),
          format: "#,##0.00;(#,##0.00);",
          alignment: 'right',
          width: '150px',
          cellRender: (e) => {
            if (e.row.data.level === 1) {
              return <span className="font-weight-bold d-flex justify-content-end">{e.text}</span>
            } else {
              return <span className="d-flex justify-content-end">{e.text}</span>
            }
          }
        },
      ]
    })
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Laporan Hasil Usaha</h2>

        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
          items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='profitloss'
          insertAPI='profitloss'
          updateAPI='profitloss'
          deleteAPI='profitloss'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          useArraySource={true}
          ArraySourceData={this.loadData}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true} 
          exportFileName={`Perhitungan Hasil Usaha ${this.getMonthYear(this.state.tanggal)}`}
          allowExportSelectedData={false}
          selection={"none"}

          exportWithFormatNum={true}
          exceptFieldExportFormtNum={
              [
                  'currentAmountDisplay',
                  'prevAmountDisplay'
              ]
          }

          showBorders={true}

          paging={false}
          showPageSizeSelector = {false}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Profit Loss'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={false} // set false agar kolom tidak dapat di freeze

          FilterRow={false} // set false untuk mematikan fitur filter
          height={'calc(100vh - 250px)'}
          sortingMode={'none'}

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}
          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        <ModalGeneralLedger store={this.props.store} ref={this.modalGeneralLedgerRef} />
      </div>
    )
  }
}

export default ProfitLoss