import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { pengkinianType } from 'dataSource/lookup'

var date = new Date().toISOString()

class DataGridRiwayatPengkinian extends Component {
    
    constructor(props){
        super(props)

        this.state = {
            formData: [],
            dataPeserta: {}
        }

        this.dataGridRiwayatRef = React.createRef()

        this.pesertaId = 0
    }

    reloadData = (dataPeserta) => {
        this.pesertaId = dataPeserta.id
        this.setState({
            dataPeserta: dataPeserta
        })

        this.dataGridRiwayatRef.current.forceRefresh()
    }

    getDatagridColumnConfiguration = () => {
        var columnConfiguration = [
            {
                dataField:'id',
                caption: "No.",
                cellRender:(data) => {
                    return data.rowIndex + 1
                },
            },
            {
                dataField: 'updatedDate',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd-MMM-yyyy'
            },
            // {
            //     dataField:'formulirNo',
            //     caption: 'No. Formulir'
            // },
            {
                dataField:'noSk',
                caption: 'No. SK'
            },
            {
                dataField:'pengkinianTypeId',
                caption: 'Jenis Pengkinian',
                lookup:{
                    dataSource: pengkinianType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'typeName' 
                }
            },
            {
                dataField:'online',
                caption: 'Media'
            },
            {
                dataField: 'updatedStatus',
                caption: 'Status Pengkinian',
            },
        ]

        return columnConfiguration
    } 

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.dataGridRiwayatRef}
                    loadAPI={`pengkinian-peserta-bo-aktifs/${this.state.dataPeserta.statusPeserta}/true/`}
                    insertAPI='pengkinian-peserta-bo-aktifs'
                    updateAPI='pengkinian-peserta-bo-aktifs'
                    deleteAPI='pengkinian-peserta-bo-aktifs' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Data Riwayat Pengkinian"}

                    allowExportSelectedData={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Riwayat Pengkinian'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.getDatagridColumnConfiguration(this.props.actionType)} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing = {this.onToolbarPreparingKeluarga}

                    height = {'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridRiwayatPengkinian