import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'

import { tipeShift,karyawan, programs } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';

import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog'
import ArrayStore from 'devextreme/data/array_store';
import { formatDate } from 'plugin/helper';

class FormShiftKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible:false,
            DataShift: {
                rotasi:true
            },
            newData:true,
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            // {
            //     widget: "dxButton",
            //     location: "after",
            //     options: {
            //       text: "Generate Jadwal Shift",
            //       onClick: this.generateJadwal.bind(this)
            //     },
            //     toolbar: "bottom"
            //   },
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
              },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.formShiftKaryawan = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            }
                        },
                    },
                    {
                        dataField: 'karyawanId',
                        label: {
                            text: 'Karyawan',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: function(item){
                                return item && "NIK : " + item.nik + " - " + item.fullName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField: 'shiftTypeId',
                        label: {
                            text: 'Shift'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeShift(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'namaShift', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'rotasi',
                        label: {
                            text: 'Rotasi'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            onValueChanged:(e) => {
                                if (!e.value) {
                                    this.formRef.current.instance.getEditor('rotasiPeriode').option('disabled', true)
                                    this.formRef.current.instance.getEditor('tanggalAwal').option('disabled', true)
                                    this.formRef.current.instance.getEditor('tanggalAkhir').option('disabled', true)
                                    this.formRef.current.instance.getEditor('rotasiType').option('disabled', true)
                                    this.formRef.current.instance.getEditor('shiftTypeId1').option('disabled', true)
                                    this.formRef.current.instance.getEditor('shiftTypeId2').option('disabled', true)
                                }else{
                                    this.formRef.current.instance.getEditor('rotasiPeriode').option('disabled', false)
                                    this.formRef.current.instance.getEditor('tanggalAwal').option('disabled', false)
                                    this.formRef.current.instance.getEditor('tanggalAkhir').option('disabled', false)
                                    this.formRef.current.instance.getEditor('rotasiType').option('disabled', false)
                                    this.formRef.current.instance.getEditor('shiftTypeId1').option('disabled', false)
                                    this.formRef.current.instance.getEditor('shiftTypeId2').option('disabled', false)
                                }
                            },
                            items: [
                                {
                                    id:true,
                                    value:"Yes"
                                },
                                {
                                    id:false,
                                    value:"No"
                                }
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            layout: 'horizontal'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:'rotasi',
                caption: 'ROTASI',
                items: [
                    {
                        dataField: 'rotasiType',
                        label: {
                            text: 'Tipe Rotasi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: [
                                {
                                    id:"1",
                                    value:'Harian'
                                },
                                {
                                    id:"2",
                                    value:'Mingguan'
                                },
                                {
                                    id:"3",
                                    value:'Bulanan'
                                },
                            ],
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'rotasiPeriode',
                        label: {
                            text: 'Rotasi Periode'
                        },
                    }, 
                    {
                        dataField: 'tanggalAwal',
                        label: {
                            text: 'Tanggal Awal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    
                    {
                        dataField: 'tanggalAkhir',
                        label: {
                            text: 'Tanggal Akhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    }, 
                    {
                        dataField: 'shiftTypeId1',
                        label: {
                            text: 'Shift 1'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeShift(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'namaShift', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'shiftTypeId2',
                        label: {
                            text: 'Shift 2'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeShift(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'namaShift', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            }
        ]
    }

    newData = (newData) => {
        this.setState({
          newData:newData  
        })
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataShift: {},
            newData:true,
            rotasi:true
        })
    }

    generateJadwal = (e) => {
        
    }

    submitData = async() => {
        var DataShift = {...this.state.DataShift}
        DataShift.tanggalAwal = DataShift.tanggalAwal ? formatDate(DataShift.tanggalAwal) : null
        DataShift.tanggalAkhir = DataShift.tanggalAkhir ? formatDate(DataShift.tanggalAkhir) : null
        if(DataShift.tanggalAwal > DataShift.tanggalAkhir){
            await alert('Tanggal awal tidak boleh melebihi tanggal akhir','Informasi')
            return false
        }
        try {
            var loadApi = 'karyawan-shifts'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadApi, 'POST', {
                values:DataShift
            })
    
            if (response) {
                notify({ message: "Sukses Input Shift Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.props.forceRefresh()
                this.hide()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    updateData = async () => {
        var DataShift = {...this.state.DataShift}
        
        DataShift.tanggalAwal = formatDate(DataShift.tanggalAwal)
        DataShift.tanggalAkhir = formatDate(DataShift.tanggalAkhir)
        if(DataShift.tanggalAwal > DataShift.tanggalAkhir){
            await alert('Tanggal awal tidak boleh melebihi tanggal akhir','Informasi')
            return false
        }
        try {
            var loadApi = 'karyawan-shifts'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadApi, 'PUT', {
                values:DataShift,
                key:DataShift.id
            },DataShift)
    
            if (response) {
                notify({ message: "Sukses Ubah Shift Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.props.forceRefresh()
                this.hide()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans?size=9999`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        var filteredData = getKaryawan.filter(val => val.programId == programId)
        let dataSource = new ArrayStore({
            data: filteredData,
            key: 'id'
        })
        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }

    retrieveData = async(data) => {  
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `karyawan-shifts/get-by-karyawanId/${data.karyawanId}`, 'GET')
        this.setState({
            DataShift: response[0],
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled= {true}
                showTitle={true}
                title={"Form Shift Karyawan"}
                width={"40vw"}
                height={"55vh"}
                toolbarItems={this.state.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <Form
                    ref={this.formRef}
                        colCount={1}
                        id={'formDataShift'}
                        formData={this.state.DataShift}
                        items={this.formShiftKaryawan}
                        labelLocation = 'left'
                    />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormShiftKaryawan