import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import ModalSettlementTransaksiObligasi from 'components/pages/modal/investasi/settlement/konfirmasi/obligasi/index';
import ModalFileSettlementTransaksiObligasi from 'components/pages/modal/investasi/settlement/konfirmasi/obligasi/file';
import { addURL } from 'redux/actions/url';
import ViewJurnalDetails from 'components/pages/modal/investasi/viewJurnalTrans';
import { formatDate } from 'plugin/helper';
import { Button } from 'devextreme-react'
import ModalSettlementPenempatanSbnBond from 'components/pages/modal/investasi/settlement/konfirmasi/obligasi/penempatan/index';
import ModalSettlementPelepasanObligasi from 'components/pages/modal/investasi/settlement/konfirmasi/obligasi/pelepasan/index';
import httpRequest from 'plugin/httprequest';
import { banks } from 'dataSource/lookup';
import ModalDetailTransaksiObligasi from 'components/pages/modal/investasi/settlement/konfirmasi/obligasi/detailTransaksi';

class SettlementTransaksiObligasi extends Component {
    constructor(props){
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            dataMaster: {},
            filterItem: {
                startDate:new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        }

        this.modalAlokasiRef = React.createRef()
        this.modalSettlementPenempatanRef = React.createRef()
        this.modalSettlementPelepasanRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalFileSettlementTransaksiObligasiRef = React.createRef()
        this.modalDetailTransaksiRef = React.createRef()
        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            startDate: formatDate(e.value)
                        })
                    }
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            endDate: formatDate(e.value)
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text : 'Lihat Jurnal',
                    //     hint : 'Lihat Jurnal',
                    //     cssClass: 'text-success',
                    //     onClick : (e) => {
                    //         this.showModalViewJurnal(e)
                    //     }
                    // },
                    {
                        text : 'View',
                        hint : 'View',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.modalDetailTransaksiRef.current.show(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'nomor',
                caption: 'No.',
                cellRender: (e) => {
                    return e.rowIndex + 1+'.'
                },
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'Nomor Konfirmasi',
            },
            {
                dataField: 'brokerName',
                caption: 'Broker',
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'tipeTransaksi',
                caption: 'Tipe Transaksi',
            },
            {
                dataField: 'trsDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'totalInstrument',
                caption: 'Total Instrument',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'totalTransaksi',
                caption: 'Total Transaksi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'netAmount',
                caption: 'Net Amount',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            
        ]
        this.dropDownbutton = [
            {
                id: 1,
                text: 'Penempatan',
                onClick: () => {
                    this.showModalPenempatan()
                }
            },
            {
                id: 2,
                text: 'Pelepasan',
                onClick: () => {
                    this.showModalPencairan()
                }
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalViewJurnal = (e) => {
        if (e.row) {
            this.showModalViewJurnalRef.current.retrieveData(e.row.data)
            this.showModalViewJurnalRef.current.newData(true)
        }else{
            this.showModalViewJurnalRef.current.newData(false)
        }

        this.showModalViewJurnalRef.current.show()
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxDropDownButton',
                options: {
                    text: 'Konfirmasi',
                    icon: 'plus',
                    items: this.dropDownbutton,
                    keyExpr: 'id',
                    displayExpr: 'text',
                    splitButton: true,
                    onItemClick: 'onClick'
                }
            }
        )
    }

    showModalPenempatan = (data = null) => {
        this.modalSettlementPenempatanRef.current.show()
        this.modalSettlementPenempatanRef.current.retrieveData(data)
    }
    showModalPencairan = (data = null) => {
        this.modalSettlementPelepasanRef.current.show()
        this.modalSettlementPelepasanRef.current.retrieveData(data)
    }
    showModalFileSettlementTransaksiObligasi = (type,data = null) => {
        this.modalFileSettlementTransaksiObligasiRef.current.show()
        this.modalFileSettlementTransaksiObligasiRef.current.retrieveData(type, data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    onSelectionChanged = (e) => {
        var orderId = e.selectedRowKeys
        this.setState({
            orderId: orderId
        })
    }
    generateInstruksi = async () => {
        var id = {
            listConfirmationId: this.state.orderId
        }
        // return false
        if (this.state.orderId != undefined) {
            try {
                var insertAPI = 'order-sbns/buat-instruksi-custody'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: id
                })
                notify({ message: 'Generate Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } else {
            notify({ message: 'Silahkan pilih data untuk di generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfirmasi Obligasi</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    keyField = "konfirmasiId"
                    ref = {this.dataGridRef}
                    loadAPI={`transaction-confirmations/obligasi/list?endDate=${formatDate(this.state.filterItem.endDate)}&startDate=${formatDate(this.state.filterItem.startDate)}&status=0&status=1&status=2&size=9999`}
                    insertAPI='cash-deviden'
                    updateAPI='cash-deviden'
                    deleteAPI='cash-deviden' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Cash Deviden"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Cash Deviden'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onSelectionChanged={this.onSelectionChanged}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    height={'calc(100vh - 311px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Generate Instruksi"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.generateInstruksi.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
                <ViewJurnalDetails 
                    ref={this.showModalViewJurnalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementPenempatanSbnBond 
                    ref={this.modalSettlementPenempatanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementPelepasanObligasi
                    ref={this.modalSettlementPelepasanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalFileSettlementTransaksiObligasi 
                    ref={this.modalFileSettlementTransaksiObligasiRef}
                    store={this.props.store}
                />
                <ModalDetailTransaksiObligasi
                    ref={this.modalDetailTransaksiRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default SettlementTransaksiObligasi