import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import { confirm, alert } from 'devextreme/ui/dialog';

import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalEditJurnalReccuring from 'components/pages/modal/akutansi/jurnalReccuring/edit';
import CreateJournalVoucher from './createJournalVoucher';

class JurnalReccuring extends Component {
    constructor(props) {
        super(props)

        this.DataGridRef = React.createRef()
        this.popupModalJournalRecurringRef = React.createRef()
        this.popupModalJournalRecurringInsertRef = React.createRef()
        this.popupModalCreateJournalVoucher = React.createRef();

        this.state = {
            tanggal:{}
        }

        this.Tanggal = [
            {
                dataField:"startDate",
                label:{
                    text:"Dari",
                    alignment: "left",
                    location:"left"
                },
                editorType:"dxDateBox",
                editorOptions: {
                    useMaskBehavior : true,
                    openOnFieldClick: true,
                    displayFormat : 'dd-MMM-yyyy'
                },
            },
            {
                dataField:"endDate",
                label:{
                    text:"Sampai",
                    alignment: "left",
                    location:"left"
                },
                editorType:"dxDateBox",
                editorOptions: {
                    useMaskBehavior : true,
                    openOnFieldClick: true,
                    displayFormat : 'dd-MMM-yyyy'
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        if(this.state.tanggal.startDate && !this.state.tanggal.endDate){
                            let type = 'error'
                            let text = 'Please Set End Date !'
                
                            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                
                            return null
                        }
                
                        if(!this.state.tanggal.startDate && this.state.tanggal.endDate){
                            let type = 'error'
                            let text = 'Please Set Start Date !'
                
                            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                
                            return null
                        }

                        this.forceRefresh()
                    },
                },
                horizontalAlignment:"left"
            },
        ]
        
        this.columns = [
            {
                type: 'buttons',
                width: 100,
                buttons: [
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalView(e.row.data)
                        }
                    },
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            var result = await confirm('Apakah anda yakin untuk menghapus data ini?','Confirmation')

                            if(result){
                                this.delete(e.row.data.id)
                            }
                        }
                    },
                    {
                        text: 'Buat Jurnal Voucher',
                        hint: 'Buat Jurnal Voucher',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            // this.showModalCreateJournalVoucher(e.row.data.id, e.row.data.valueDate);
                            this.showModalCreateJournalVoucher(e.row.data);
                        }
                    }
                    // {
                    //     text: 'Buat Jurnal Voucher',
                    //     hint: 'Buat Jurnal Voucher',
                    //     cssClass: 'text-success',
                    //     onClick: async (e) => {
                    //         var result = await confirm('Apakah anda yakin untuk melakukan proses ini?','Confirmation')

                    //         if(result){
                    //             this.generateJournalVoucher(e.row.data.id)
                    //         }
                    //     }
                    // },
                ]
            },
            {
                dataField: 'createdDate',
                caption: 'Tanggal Dibuat',
                dataType: 'date',
                format: 'dd-MMM-yyyy'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'A',
                            value: 'Active'
                        },
                        {
                            id: 'N',
                            value: 'Non-Active'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: "value",
                }
            },
            {
                dataField: 'journalCode',
                caption: 'Kode Jurnal Berulang',
            },
            {
                dataField: 'journalDesc',
                caption: 'Deskripsi',
            },
            {
                dataField: 'dateStart',
                caption: 'Tanggal Mulai',
                dataType: 'date',
                format: 'dd-MMM-yyyy'
            },
            {
                dataField: 'endModel',
                caption: 'Parameter Berhenti',
                lookup : {
                    dataSource: [
                        {
                            id: 'N',
                            value: 'Tidak berhenti'
                        },
                        {
                            id: 'A',
                            value: 'Tanggal Berhenti'
                        },
                        {
                            id: 'B',
                            value: 'Jumlah Berhenti'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: "value",
                }
            },
            {
                dataField: 'dateEnd',
                caption: 'Tanggal Berhenti',
                dataType: 'date',
                format: 'dd-MMM-yyyy'
            },
            {
                dataField: 'endOccurences',
                caption: 'Jumlah Perulangan',
            },
            {
                dataField: 'recurModel',
                caption: 'Periode Pengulangan',
                lookup : {
                    dataSource: [
                        {
                            id: 'D',
                            value: 'Harian'
                        },
                        {
                            id: 'M',
                            value: 'Bulanan'
                        },
                        {
                            id: 'Y',
                            value: 'Tahunan'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: "value",
                }
            },
            {
                dataField: 'recurnSpecificDate',
                caption: 'Diulang setiap tanggal',
            },
            {
                dataField: 'recurMonthInterval',
                caption: 'Bulan Pengulangan',
            },
            {
                dataField: 'recurSpecificDays',
                caption: 'Tanggal Spesifik',
            },
            {
                dataField: 'debet',
                caption: 'Total Debet',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'credit',
                caption: 'Total Kredit',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            }
        )
    }

    showModalInsert = () => {
        this.popupModalJournalRecurringInsertRef.current.show('add')
    }
    
    showModalView = async (data) => {
        await this.popupModalJournalRecurringRef.current.loadDataDetailAndExrate(data.id)
        this.popupModalJournalRecurringRef.current.retrieveData(data)
        this.popupModalJournalRecurringRef.current.show('edit')
    }

    showModalEdit = async (data) => {
        this.popupModalJournalRecurringInsertRef.current.show('add')
        await this.popupModalJournalRecurringInsertRef.current.loadDataDetailAndExrate(data.id)
        this.popupModalJournalRecurringInsertRef.current.retrieveData(data)
    }

    showModalCreateJournalVoucher = async (data) => {
        this.popupModalCreateJournalVoucher.current.show();
        // await this.popupModalCreateJournalVoucher.current.submitData(data)
        this.popupModalCreateJournalVoucher.current.retrieveData(data);
    }

    generateJournalVoucher = async (journalRecurringId) => {
        try{
            await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurring/generateJournal/${journalRecurringId}`)
    
            let type = 'success'
            let text = 'Journal voucher berhasil dibuat'
    
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window}, closeOnOutsideClick: true }, type);
        }catch(e){
            alert(e,'Error')
        }
    }

    delete = async (journalRecurringId) => {
        var journalRecurringDetils = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurring-detil/getAllByJournalRecurringId/${journalRecurringId}`)
        var journalRecurringExrates= await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurring-exrates/getAllByJournalRecurringId/${journalRecurringId}`)

        for(const journalRecurringDetil of journalRecurringDetils){
            await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurring-detils`,'DELETE',journalRecurringDetil.id)
        }
        for(const journalRecurringExrate of journalRecurringExrates){
            await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurring-exrates`,'DELETE',journalRecurringExrate.id)
        }

        await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurrings`,'DELETE',journalRecurringId)

        let type = 'success'
        let text = 'Journal Berulang berhasil dihapus'

        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window}, closeOnOutsideClick: true }, type);

        this.forceRefresh()
    }

    loadData = async () => {
        var journalData

        if(this.state.tanggal.startDate && this.state.tanggal.endDate){
            journalData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurring/getAllByCreatedDateBetween/${new Date(formatDate(new Date(this.state.tanggal.startDate))).toISOString()}/${new Date(formatDate(new Date(this.state.tanggal.endDate))).toISOString()}`)

            return journalData
        }

        journalData = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurrings`)

        return journalData
    }

    forceRefresh = () => {
        this.DataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Jurnal Berulang</h2>

                <Form
                    colCount={4}
                    id={'formTanggal'}
                    formData={this.state.tanggal}
                    items={this.Tanggal}
                />
                <DevExpressDataGrid
                    ref={this.DataGridRef}
                    loadAPI='fixed-assets'
                    insertAPI='fixed-assets'
                    updateAPI='fixed-assets'
                    deleteAPI='fixed-assets'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aset Tetap'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 250px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalEditJurnalReccuring 
                    type={'add'}
                    ref={this.popupModalJournalRecurringInsertRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalEditJurnalReccuring 
                    type={'edit'}
                    ref={this.popupModalJournalRecurringRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <CreateJournalVoucher 
                    ref={this.popupModalCreateJournalVoucher}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default JurnalReccuring