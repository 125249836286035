import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months } from 'dataSource/lookup';
import { Form } from '../../../../../../node_modules/devextreme-react/form';
import FormPengajuanRapel from 'components/pages/modal/sdm-umum/kepegawaian/rapel/rapel';
import httpRequest from 'plugin/httprequest';
import FormDaftarKaryawanPenerima from 'components/pages/modal/sdm-umum/payroll/tunjangan-lain/daftarKaryawanPenerima';
// import { karyawan } from 'dataSource/lookup';

class DaftarRapel extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }
        this.dataGridRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.showModalKaryawanPenerimaRef = React.createRef()
        this.filterData = []

        this.formMaster = [
            {
                dataField:"bulan",
                label:{
                    text:"Bulan",
                    alignment:"left",
                    location:"left",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled:true
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                    location:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.Columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Lihat',
                        hint : 'Lihat',
                        onClick : (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text : 'Daftar Karyawan Penerima',
                        hint : 'Daftar Karyawan Penerima',
                        onClick : (e) => {
                            this.showModalKaryawanPenerima(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'ID'
            },
            {
                dataField: 'kodeRapel',
                caption: 'Kode Rapel',
                alignment: 'right'
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'jumlahKaryawan',
                caption: 'Jumlah Karyawan'
            },
            {
                dataField: 'besaranRapel',
                caption: 'Besaran Rapel',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'gaji',
                        caption: '% Gaji',
                        alignment: 'right'
                    },
                    {
                        dataField: 'nominal',
                        caption: 'Nominal',
                        alignment: 'right'
                    }
                ]
            },
            {
                dataField: 'totalNominalPengajuan',
                caption: 'Total Nominal Pengajuan',
                alignment: 'right'
            },
            {
                dataField: 'tanggalPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'periodeGaji',
                caption: 'Periode Gaji (Bulan / Tahun)'
            },
            {
                dataField: 'status',
                caption: 'Status'
            }
        ]
    }

    componentDidMount = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-list-karyawan-rapels?statusId.in=2`,
            'GET'
        )
        this.filterData = datas
        this.forceRefresh()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    defaultData = async () => { 
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-list-karyawan-rapels?statusId.in=2`,
            'GET'
        )
        this.filterData = datas
        if (this.filterData) {
            this.forceRefresh()
        }
    }
 
    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  onClick: (e) => {
                    this.showModalEdit(e)
                  },
              }
          }
        )
    }

    filterSubmit = async () => {
        const state = this.state.dataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-list-karyawan-rapels?statusId.in=2`,
            'GET'
        )

        var listData = []
        for(var data of datas){
            data.tanggalPengajuan = new Date(data.tanggalPengajuan)
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan && value.tanggalPengajuan.getFullYear() == state.tahun)
        }else if (state.bulan) {
            this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.tanggalPengajuan.getFullYear() == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan && value.tanggalPengajuan.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    
    }

    loadData = async () => {
        return this.filterData
    }

    showModalEdit = (data) => {
        this.modalEditRef.current.show()
        this.modalEditRef.current.retrieveData(data)
        this.modalEditRef.current.getListTunjanganType()
    }

    showModalKaryawanPenerima = (data) => {
        if (data) {
            this.showModalKaryawanPenerimaRef.current.retrieveData(data)
        }
        this.showModalKaryawanPenerimaRef.current.show()
    }
    

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Rapel</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.dataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='pengajuan-rapel'
                    insertAPI='pengajuan-rapel'
                    updateAPI='pengajuan-rapel'
                    deleteAPI='pengajuan-rapel' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Rapel"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Rapel'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.Columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    // onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormPengajuanRapel
                    ref={this.modalEditRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                    from={'daftar'}
                    action={'view'}
                />
                
                <FormDaftarKaryawanPenerima
                    ref={this.showModalKaryawanPenerimaRef}
                    store={this.props.store}
                    forceRefresh={this.defaultData}
                    from={'daftar'}
                    action={'rapel'}
                />
            </div>
        )
    }
}

export default DaftarRapel