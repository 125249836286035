import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatUploadFileData, imageSource } from 'plugin/helper'
import ScrollView from 'devextreme-react/scroll-view';
import Box, { Item } from 'devextreme-react/box';
import { banks, currency, invoiceType, programSdm, rekBankHrd, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import notify from 'devextreme/ui/notify'

class FormModalTagihanProyek extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            fileUploadReceipt: [],
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'programId',
                                label: {
                                    text: 'Program',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: programSdm(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'program',
                                    searchEnabled: true,
                                    onValueChanged: (e) => {
                                        this.projectByProgram(e.value)
                                    }
                                }
                            },
                            {
                                dataField: 'projectId',
                                label: {
                                    text: 'Kode/Nama Proyek'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'projectCode',
                                    searchEnabled: true,
                                    onSelectionChanged: async (e) => {
                                        var slctd = e.selectedItem
                                        if (slctd) {
                                            if (slctd.supplierId) {
                                                var supplier = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `suppliers/${slctd.supplierId}`, 'GET')
                                            }
                                            this.formRef.current.instance.updateData({
                                                projectType: slctd.projectType,
                                                projectCode: slctd.projectCode,
                                                projectName: slctd.projectName,

                                                kodePelaksana: supplier.kodeSupplier,
                                                namaPelaksana: supplier.namaSupplier,
                                                alamatPelaksana: supplier.alamat,
                                                noTelpPelaksana: supplier.noTelp,
                                                emailPelaksana: supplier.email
                                            })
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'projectType',
                                label: {
                                    text: 'Jenis Proyek'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id: '1',
                                            value: 'Pembangunan'
                                        },
                                        {
                                            id: '2',
                                            value: 'Renovasi'
                                        },
                                        {
                                            id: '3',
                                            value: 'Pengadaan'
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'projectCode',
                                label: {
                                    text: 'Kode Proyek'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'projectName',
                                label: {
                                    text: 'Nama Proyek'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                itemType: 'empty'
                            },
                            {
                                dataField: 'invoiceNo',
                                label: {
                                    text: 'No Tagihan'
                                }
                            },
                            {
                                dataField: 'reffNo',
                                label: {
                                    text: 'No Referensi'
                                }
                            },
                            {
                                dataField: 'invoiceDate',
                                label: {
                                    text: 'Tanggal Tagihan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'dueDate',
                                label: {
                                    text: 'Tanggal Jatuh Tempo'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField: 'invoiceTypeId',
                                label: {
                                    text: 'Jenis Tagihan'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: invoiceType(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'invoiceName',
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField: 'terminKe',
                                label: {
                                    text: 'Termin Ke'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                },
                            },
                            {
                                dataField: 'invoiceNominal',
                                label: {
                                    text: 'Jumlah Tagihan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                    format: '#,##0.00',
                                    onValueChanged: (e) => {
                                        this.formRef.current.instance.updateData('ppn', e.value * (10 / 100))
                                    }
                                },
                            },
                            {
                                dataField: 'ppn',
                                label: {
                                    text: 'PPN (10%)'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                    format: '#,##0.00',
                                },
                            },
                            {
                                dataField: 'pph',
                                label: {
                                    text: 'PPH'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                    format: '#,##0.00',
                                },
                            },
                            {
                                itemType: 'empty'
                            },
                            {
                                dataField: 'invoiceNominalNett',
                                label: {
                                    text: 'Jumlah Tagihan Bersih'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                    format: '#,##0.00',
                                },
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    text: 'Currency'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyName',
                                    onSelectionChanged: (e) => {
                                        var slctd = e.selectedItem
                                        if (slctd) {
                                            this.formRef.current.instance.updateData('currencyRate', slctd.rate)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'currencyRate',
                                label: {
                                    text: 'Kurs'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0,
                                    format: '#,##0.00',
                                },
                            },
                            // {
                            //     dataField:'paymentMethod',
                            //     label:{
                            //         text:'Payment Method'
                            //     }
                            // },
                            {
                                dataField: 'transactionTypeId',
                                label: {
                                    text: 'Tipe Transaksi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'transactionTypeName',
                                    searchEnabled: true,
                                    onValueChanged: async (e) => {
                                        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'transaction-type-subs?size=999', 'GET')
                                        let filtered = response.filter(val => val.transactionTypeId === e.value)
                                        this.formRef.current.instance.getEditor('transactionTypeSubId').option('dataSource', filtered)
                                    }
                                }
                            },
                            {
                                dataField: 'transactionTypeSubId',
                                label: {
                                    text: 'Sub Tipe Transaksi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'tipeSubName',
                                    searchEnabled: true,
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        name: 'informasi-pelaksanaan',
                        caption: 'Informasi Pelaksanaan',
                        items: [
                            {
                                dataField: 'kodePelaksana',
                                label: {
                                    text: 'Kode Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'namaPelaksana',
                                label: {
                                    text: 'Nama Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'alamatPelaksana',
                                label: {
                                    text: 'Alamat Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'noTelpPelaksana',
                                label: {
                                    text: 'No Telp Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'noFaxPelaksana',
                                label: {
                                    text: 'No Fax Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'emailPelaksana',
                                label: {
                                    text: 'Email Pelaksana'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'bilyet-giro-tagihan',
                        caption: 'Bilyet Giro Tagihan',
                        items: [
                            {
                                dataField: 'giroTujuanNo',
                                label: {
                                    text: 'Nomor Rekening'
                                },
                            },
                            {
                                dataField: 'giroTujuanName',
                                label: {
                                    text: 'Atas Nama Tagihan'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'giroTujuanBankId',
                                label: {
                                    text: 'Bank/Cabang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        name: 'bank-transfer-tagihan',
                        caption: 'Bank Transfer Tagihan',
                        items: [
                            {
                                dataField: 'rekTujuanNo',
                                label: {
                                    text: 'Nomor Rekening'
                                },
                            },
                            {
                                dataField: 'rekTujuanBankName',
                                label: {
                                    text: 'Atas Nama Tagihan'
                                },
                                editorOptions: {
                                    readOnly: true,
                                }
                            },
                            {
                                dataField: 'rekTujuanBankId',
                                label: {
                                    text: 'Bank/Cabang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                        ]
                    },
                ]
            },

        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: this.submitData.bind(this),
                },
                toolbar: "bottom",
                visible: this.props.actionType == 'view' ? false : true
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster: data,
        })
    }
    uploadInvoice = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataMaster = { ...this.state.DataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataMaster[`${fieldName}Name`] = files.name;
                DataMaster[fieldName] = formattedFileData.base64data;
                DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataMaster: DataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }

    uploadBast = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataMaster = { ...this.state.DataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataMaster[`${fieldName}Name`] = files.name;
                DataMaster[fieldName] = formattedFileData.base64data;
                DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                this.setState({
                    DataMaster: DataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }

    projectByProgram = async (programId) => {
        let loadAPI = `projects`
        var getProject = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        let filtered = getProject.filter(val => val.programId === programId)

        let dataSource = new ArrayStore({
            data: filtered,
            key: 'id'
        });

        this.formRef.current.instance.getEditor('projectId').option('dataSource', dataSource)
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }


    submitData = async () => {
        var data = this.state.DataMaster
        data.statusTransaksiId = 1
        if (data) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects-tagihan', 'POST', { values: data })
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: 'Sukses Simpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    previewFile = async (contentType, base64) => {
        var src = imageSource(contentType, base64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Tagihan Proyek'}
                width={"150vh"}
                height={"80vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                        <Form
                            ref={this.formRef}
                            colCount={2}
                            id={'formPotongan'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation='left'
                            readOnly={this.props.actionType == 'view' ? true : false}
                        />
                        <Box direction={"row"} width={"100%"}>
                            <Item ratio={1}>
                                <FileUploader
                                    accept={'image/png, image/jpeg, application/pdf'}
                                    uploadMode={'useForm'}
                                    onValueChanged={this.uploadInvoice.bind(this)}
                                    labelText="File Invoice"
                                    showFileList={false}
                                    name={'fileInvoice'}
                                    value={this.state.fileUploadReceipt}
                                    disabled={this.props.actionType == 'view' ? true : false}
                                    ref={this.fileUploderRef}
                                />
                                <div className="row">
                                    <label className="col-4">Nama Berkas:</label>
                                    <label className="col-8">{this.state.DataMaster.fileInvoiceName}</label>
                                </div>
                                <button onClick={() => this.previewFile(this.state.DataMaster.fileInvoiceContentType, this.state.DataMaster.fileInvoice)} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.fileInvoice ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka Berkas
                                </button>
                            </Item>
                            <Item ratio={1}>
                                <FileUploader
                                    accept={'image/png, image/jpeg, application/pdf'}
                                    uploadMode={'useForm'}
                                    onValueChanged={this.uploadBast.bind(this)}
                                    labelText="File BAST Termin"
                                    showFileList={false}
                                    name={'fileBast'}
                                    value={this.state.fileUploadReceipt}
                                    disabled={this.props.actionType == 'view' ? true : false}
                                    ref={this.fileUploderRef}
                                />
                                <div className="row">
                                    <label className="col-4">Nama Berkas:</label>
                                    <label className="col-8">{this.state.DataMaster.fileBastName}</label>
                                </div>
                                <button onClick={() => this.previewFile(this.state.DataMaster.fileBastContentType, this.state.DataMaster.fileBast)} className={`btn btn-light btn-sm border rounded ${this.state.DataMaster.fileBast ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka Berkas
                                </button>
                            </Item>
                        </Box>
                    </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default FormModalTagihanProyek