import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { sysMenu } from 'dataSource/lookup';

class Menu extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                dataField: 'menuCode',
                caption: 'Kode Menu',
            },
            {
                dataField: 'menuName',
                caption: 'Nama Menu',
            },
            {
                dataField: 'linkPage',
                caption: 'Url',
            },
            {
                dataField: 'level',
                caption: 'Level Menu',
                lookup: {
                    dataSource: [
                        {
                            id: 0,
                            text: 'Level 0'
                        },
                        {
                            id: 1,
                            text: 'Level 1'
                        },
                        {
                            id: 2,
                            text: 'Level 2'
                        },
                        {
                            id: 3,
                            text: 'Level 3'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'text', // provides display values
                }
            },
            {
                dataField: 'parentCode',
                caption: 'Menu Induk',
                lookup: {
                    dataSource: sysMenu(this.props.store),
                    valueExpr: 'menuCode',
                    displayExpr: (item) => {
                        return item && item.menuCode + ' - ' + item.menuName
                    }, // provides display values
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Menu</h2>

                <DevExpressDataGrid
                    loadAPI='sys-menus'
                    insertAPI='sys-menus'
                    updateAPI='sys-menus'
                    deleteAPI='sys-menus'

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"User Group"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Menu'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default Menu
