import { anggarans } from "dataSource/lookup";
import React, { Component } from "react";
import { addURL } from 'redux/actions/url'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import Form from 'devextreme-react/form';
import DataGridSolvabilitas from './tabComp/solvabilitas';
import DataGridNkaMp from './tabComp/nkaMp';
import DataGridNkaMl from './tabComp/nkaMl';
import { Button } from "../../../../../../../node_modules/devextreme-react/data-grid";
import ModalSolvabilitas from './tabComp/modal/modalSolvabilitas';
import ModalGenerateNka from './tabComp/modal/modalGenerateNka';
import DevExpressDataGrid from "components/inheritComponent/devexpressdatagrid";
import httpRequest from "plugin/httprequest";
import notify from 'devextreme/ui/notify';

class RasioSolvabilitas extends Component {
   constructor(props) {
    super(props)

    this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

    this.state = {
        activeTab: 0,
        filterData: {
            tahun: new Date(this.sysDate ? this.sysDate.dateValue : null).getFullYear(),
        }
    }

    this.prevTabIndex = 0
    this.DataGridSolvabilitasRef = React.createRef()
    this.DataGridNkaMpRef = React.createRef()
    this.DataGridNkaMlRef = React.createRef()
    this.showInsertDataRef = React.createRef()
    this.showGenerateDataRef = React.createRef()
    this.formRef = React.createRef()
    this.data = []
    this.selectedRowsData = [];

    this.formMaster = [
        {
            dataField: "tahun",
            label: {
                text: "Tahun",
                alignment: "left",
                location: "left",
            },
            editorType: 'dxSelectBox',
            editorOptions: {
                dataSource: [
                    {
                        tahun: 2020
                    },
                    {
                        tahun: 2021
                    },
                    {
                        tahun: 2022
                    },
                    {
                        tahun: 2023
                    },
                    {
                        tahun: 2024
                    },
                    {
                        tahun: 2025
                    },
                    {
                        tahun: 2026
                    },
                    {
                        tahun: 2027
                    },
                    {
                        tahun: 2028
                    },
                    {
                        tahun: 2029
                    },
                    {
                        tahun: 2030
                    }
                ],
                displayExpr: 'tahun',
                valueExpr: 'tahun',
                searchEnabled: true,
                deferRendering: false,
            }
        },
        {
            itemType: "button",
            buttonOptions: {
                text: "Filter",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: async (e) => {
                    this.setState({
                        filterData: this.state.filterData
                    })
                },
            },
            horizontalAlignment: "left"
        },
        {
            itemType: "button",
            cssClass: 'btn-generate-rasio',
            buttonOptions: {
                text: "Generate",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: async (e) => {
                    if (this.selectedRowsData.length > 0) {
                        for (let value of this.selectedRowsData) {
                            if (value.solvabilitasTypeId === 1) {
                                notify({ message: 'Tipe solvabilitas ini tidak dapat melakukan generate jurnal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                return null;
                            }
                        }
                        this.generate(this.selectedRowsData);
                    } else {
                        notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                },
            },
            horizontalAlignment: "left"
        },
        {
            itemType: "button",
            cssClass: 'btn-add-rasio',
            buttonOptions: {
                text: "Tambah",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: async (e) => {
                    this.showModalInsert()
                },
            },
            horizontalAlignment: "left"
        },
    ]

    this.TabDataSource = [
        {
            id: 1,
            text: 'SOLVABILITAS'
        },
        {
            id: 2,
            text: 'NKA Manfaat Pensiun'
        },
        {
            id: 3,
            text: 'NKA Manfaat Lain'
        }
    ]

    this.columnsSolvabilitas = [
        {
            dataField: 'solvabilitasName',
            caption: '',
            allowEditing: false,
        },
        {
            dataField: 'jan',
            caption: 'Januari',
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "feb",
            "caption": "Februari",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "mar",
            "caption": "Maret",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "apr",
            "caption": "April",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "may",
            "caption": "Mei",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "jun",
            "caption": "Juni",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "jul",
            "caption": "Juli",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "aug",
            "caption": "Agustus",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "sep",
            "caption": "September",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "oct",
            "caption": "Oktober",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "nov",
            "caption": "November",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
        {
            "dataField": "dec",
            "caption": "Desember",
            format: '#,##0.00',
            alignment: 'right'
            // allowEditing: false,
        },
    ]

    this.summarySolvabilitas = [
        {
            displayFormat: 'Total',
            showInColumn: 'solvabilitasName'
        },
        {
            name: 'jan',
            column: 'jan',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'feb',
            column: 'feb',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'mar',
            column: 'mar',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'apr',
            column: 'apr',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'may',
            column: 'may',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'jun',
            column: 'jun',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'jul',
            column: 'jul',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'aug',
            column: 'aug',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'sep',
            column: 'sep',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'oct',
            column: 'oct',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'nov',
            column: 'nov',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'dec',
            column: 'dec',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        }
    ]

    this.columnsNkaMp = [
        {
            dataField: 'solvabilitasName',
            caption: 'NKA MP',
            allowEditing: false,
        },
        {
            dataField: 'jan',
            caption: 'Januari',
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "feb",
            "caption": "Februari",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "mar",
            "caption": "Maret",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "apr",
            "caption": "April",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "may",
            "caption": "Mei",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "jun",
            "caption": "Juni",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "jul",
            "caption": "Juli",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "aug",
            "caption": "Agustus",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "sep",
            "caption": "September",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "oct",
            "caption": "Oktober",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "nov",
            "caption": "November",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "dec",
            "caption": "Desember",
            format: '#,##0.00',
            alignment: 'right'
        },
    ]

    this.summaryNkaMp = [
        {
            displayFormat: 'Total',
            showInColumn: 'solvabilitasName'
        },
        {
            name: 'jan',
            column: 'jan',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'feb',
            column: 'feb',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'mar',
            column: 'mar',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'apr',
            column: 'apr',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'may',
            column: 'may',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'jun',
            column: 'jun',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'jul',
            column: 'jul',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'aug',
            column: 'aug',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'sep',
            column: 'sep',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'oct',
            column: 'oct',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'nov',
            column: 'nov',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'dec',
            column: 'dec',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        }
    ]

    this.columnsNkaMl = [
        {
            dataField: 'solvabilitasName',
            caption: 'NKA ML',
            allowEditing: false,
        },
        {
            dataField: 'jan',
            caption: 'Januari',
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "feb",
            "caption": "Februari",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "mar",
            "caption": "Maret",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "apr",
            "caption": "April",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "may",
            "caption": "Mei",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "jun",
            "caption": "Juni",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "jul",
            "caption": "Juli",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "aug",
            "caption": "Agustus",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "sep",
            "caption": "September",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "oct",
            "caption": "Oktober",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "nov",
            "caption": "November",
            format: '#,##0.00',
            alignment: 'right'
        },
        {
            "dataField": "dec",
            "caption": "Desember",
            format: '#,##0.00',
            alignment: 'right'
        },
    ]

    this.summaryNkaMl = [
        {
            displayFormat: 'Total',
            showInColumn: 'solvabilitasName'
        },
        {
            name: 'jan',
            column: 'jan',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'feb',
            column: 'feb',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'mar',
            column: 'mar',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'apr',
            column: 'apr',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'may',
            column: 'may',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'jun',
            column: 'jun',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'jul',
            column: 'jul',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'aug',
            column: 'aug',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'sep',
            column: 'sep',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'oct',
            column: 'oct',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'nov',
            column: 'nov',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        },
        {
            name: 'dec',
            column: 'dec',
            summaryType: 'sum',
            valueFormat: '#,##0.00',
            displayFormat: '{0}'
        }
    ]

   }

   componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalInsert = (type) => {
        this.showInsertDataRef.current.show(type);
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
        this.setState({ activeTab: args.value });
    }

    // onTabSelectionChanged = (args) => {
    //     // Dapatkan elemen tab yang baru dipilih
    //     const newTab = document.getElementById(`tabindex-${args.value}`);
    //     // Dapatkan elemen tab yang sebelumnya dipilih
    //     const prevTab = document.getElementById(`tabindex-${this.prevTabIndex}`);
        
    //     // Tampilkan tab yang baru dipilih
    //     newTab.classList.remove('d-none');
    //     // Sembunyikan tab yang sebelumnya dipilih
    //     prevTab.classList.add('d-none');
    
    //     // Set aria-selected untuk tab baru dan tab sebelumnya
    //     newTab.setAttribute('aria-selected', 'true');
    //     prevTab.setAttribute('aria-selected', 'false');
    
    //     // Update nilai prevTabIndex
    //     this.prevTabIndex = args.value;
    //     // Update state activeTab
    //     this.setState({ activeTab: args.value });
    // }

    forceRefresh = () => {
        this.DataGridSolvabilitasRef.current.forceRefresh(true);
        this.DataGridNkaMpRef.current.forceRefresh(true);
        this.DataGridNkaMlRef.current.forceRefresh(true);
    }

    onRowRemovedDetail = async(e) => {
        try {
            let id = e.data.id;
            await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'rasio-solvabilitas', 'DELETE', id);
            
            notify({ message: 'Data Berhasil Dihapus', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            this.forceRefresh();
        } catch (error) {
            console.log(error);
            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center' } }, 'error', 600);
        }
    }

    onRowUpdatedDetail = async(e) => {
        try {
            let getSolvabilitas = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `rasio-solvabilitas/${e.key}`, 'GET');

            let dataSourceSolvabilitas = this.DataGridSolvabilitasRef.current.getDataSource();
            let dataSourceNkMp = this.DataGridNkaMpRef.current.getDataSource();
            let dataSourceNkMl = this.DataGridNkaMlRef.current.getDataSource();
            let dataArr = [];
            
            if (getSolvabilitas.solvabilitasTypeId === 1 && this.prevTabIndex === 0) {
                for (let value of dataSourceSolvabilitas) {
                    if (getSolvabilitas.id === value.id) {
                        let dataValue = {
                            "id": getSolvabilitas.id,
                            "amountJan": value.jan,
                            "amountFeb": value.feb,
                            "amountMar": value.mar,
                            "amountApr": value.apr,
                            "amountMay": value.may,
                            "amountJun": value.jun,
                            "amountJul": value.jul,
                            "amountAug": value.aug,
                            "amountSep": value.sep,
                            "amountOct": value.oct,
                            "amountNov": value.nov,
                            "amountDec": value.dec,
                            "solvabilitasId": getSolvabilitas.solvabilitasId,
                            "solvabilitasTypeId": getSolvabilitas.solvabilitasTypeId,
                            "tanggal": getSolvabilitas.tanggal
                        };
                        dataArr.push(dataValue);
                    }
                }
            } else if (getSolvabilitas.solvabilitasTypeId === 2 && this.prevTabIndex === 1) {
                for (let value of dataSourceNkMp) {
                    if (getSolvabilitas.id === value.id) {
                        let dataValue = {
                            "id": getSolvabilitas.id,
                            "amountJan": value.jan,
                            "amountFeb": value.feb,
                            "amountMar": value.mar,
                            "amountApr": value.apr,
                            "amountMay": value.may,
                            "amountJun": value.jun,
                            "amountJul": value.jul,
                            "amountAug": value.aug,
                            "amountSep": value.sep,
                            "amountOct": value.oct,
                            "amountNov": value.nov,
                            "amountDec": value.dec,
                            "solvabilitasId": getSolvabilitas.solvabilitasId,
                            "solvabilitasTypeId": getSolvabilitas.solvabilitasTypeId,
                            "tanggal": getSolvabilitas.tanggal
                        };
                        dataArr.push(dataValue);
                    }
                }
            } else if (getSolvabilitas.solvabilitasTypeId === 3 && this.prevTabIndex === 2) {
                for (let value of dataSourceNkMl) {
                    if (getSolvabilitas.id === value.id) {
                        let dataValue = {
                            "id": getSolvabilitas.id,
                            "amountJan": value.jan,
                            "amountFeb": value.feb,
                            "amountMar": value.mar,
                            "amountApr": value.apr,
                            "amountMay": value.may,
                            "amountJun": value.jun,
                            "amountJul": value.jul,
                            "amountAug": value.aug,
                            "amountSep": value.sep,
                            "amountOct": value.oct,
                            "amountNov": value.nov,
                            "amountDec": value.dec,
                            "solvabilitasId": getSolvabilitas.solvabilitasId,
                            "solvabilitasTypeId": getSolvabilitas.solvabilitasTypeId,
                            "tanggal": getSolvabilitas.tanggal
                        };
                        dataArr.push(dataValue);
                    }
                }
            }

            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `rasio-solvabilitas`, 'PUT', {
                key: e.key,
                values: dataArr[0] 
            }, dataArr[0])

            if (response) {
                notify({ message: 'Data Berhasil Diubah', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh();
            }
        } catch (error) {
            console.log(error);
            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center' } }, 'error', 600);
        }
    }

    loadDataDetailSolvabilitas = async() => { 
        try {
            let respone = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `solvabilitas/report/1/${this.state.filterData.tahun}`, 'GET');
            this.data = respone;
            return this.data    
        } catch (error) {
            console.log(error)
        }
    }

    loadDataDetailNkaMp = async() => {
        try {
            let respone = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `solvabilitas/report/2/${this.state.filterData.tahun}`, 'GET');
            this.data = respone;
            return this.data
        } catch (error) {
            console.log(error)
        }
    }

    loadDataDetailNkaMl = async() => {
        try {
            let respone = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `solvabilitas/report/3/${this.state.filterData.tahun}`, 'GET');
            this.data = respone;
            return this.data
        } catch (error) {
            console.log(error)
        }
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
        // this.showGenerateDataRef.current.show();
        // this.showGenerateDataRef.current.retrieveData(e);
    }

    generate = (data) => {
        let dataId = []
        this.showGenerateDataRef.current.show();
        for (let value of data) {
            let dataValue = {
                ...value,
                id: value.id
            }
            dataId.push(dataValue)
        }
        this.showGenerateDataRef.current.retrieveData(dataId);
    }

   render() {
    return (
        <div className="container-fluid">
            <h3 className="main-title">NKA dan Solvabilitas</h3>
            <div className="my-3">
                    <Form
                        colCount={6}
                        ref={this.formRef}
                        id={'formMaster'}
                        formData={this.state.filterData}
                        items={this.formMaster}
                    />
            </div>
            <div className="mt-3">
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0" className={`mt-2 text-center ${this.state.activeTab === 0 ? '' : 'd-none'}`} height={'100%'}>
                    {/* <DataGridSolvabilitas store={this.props.store} ref={this.DataGridSolvabilitasRef} stateData={this.state.filterData} tabName={'Solvabilitas'} /> */}
                    <DevExpressDataGrid
                        ref={this.DataGridSolvabilitasRef}
                        menuRightClick={false}  
                        loadAPI={`solvabilitas/report/1/${this.state.filterData.tahun}`}
                        insertAPI=''
                        updateAPI=''
                        deleteAPI=''

                        backendserver={process.env.REACT_APP_BACKEND_ACC}

                        useArraySource={true}
                        ArraySourceData={this.loadDataDetailSolvabilitas}

                        allowAdding={false}
                        allowDeleting={true}
                        allowUpdating={true}

                        exportExcel={true}
                        exportFileName={"NKA Manfaat Pensiun"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        exportWithFormatNum={true}
                        exceptFieldExportFormtNum={
                            [
                                'jan',
                                'feb',
                                'mar',
                                'apr',
                                'may',
                                'jun',
                                'jul',
                                'aug',
                                'sep',
                                'oct',
                                'nov',
                                'dec'
                            ]
                        }

                        editingMode="row"

                        showBorders={true}

                        paging={false}
                        showPageSizeSelector={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Solvabilitas'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columnsSolvabilitas} // taruh konfigurasi kolom disini
                        summaryTotalItem={this.summarySolvabilitas}
                        // onToolbarPreparing={this.onToolbarPreparing}
                        onSelectionChanged={this.onSelectionChanged}

                        height={'calc(100vh - 367px)'}

                        onRowUpdated={this.onRowUpdatedDetail}
                        onRowRemoved={this.onRowRemovedDetail}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabindex-1" className={'d-none mt-2 mt-2 text-center'} height={'100%'}>
                    {/* <DataGridNkaMp store={this.props.store} ref={this.DataGridNkaMpRef} stateData={this.state.filterData} tabName={'NkaMP'} /> */}
                    <DevExpressDataGrid
                        ref={this.DataGridNkaMpRef}
                        menuRightClick={false}
                        loadAPI={`solvabilitas/report/2/${this.state.filterData.tahun}`}
                        insertAPI=''
                        updateAPI=''
                        deleteAPI=''

                        backendserver={process.env.REACT_APP_BACKEND_ACC}

                        useArraySource={true}
                        ArraySourceData={this.loadDataDetailNkaMp}

                        allowAdding={false}
                        allowDeleting={true}
                        allowUpdating={true}

                        editingMode="row"

                        exportExcel={true}
                        exportFileName={"NKA Manfaat Lain"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        exportWithFormatNum={true}
                        exceptFieldExportFormtNum={
                            [
                                'jan',
                                'feb',
                                'mar',
                                'apr',
                                'may',
                                'jun',
                                'jul',
                                'aug',
                                'sep',
                                'oct',
                                'nov',
                                'dec'
                            ]
                        }

                        showBorders={true}

                        paging={false}
                        showPageSizeSelector={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'NKA MP'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columnsNkaMp} // taruh konfigurasi kolom disini
                        summaryTotalItem={this.summaryNkaMp}
                        onToolbarPreparing={this.onToolbarPreparing}
                        onSelectionChanged={this.onSelectionChanged}

                        onRowUpdated={this.onRowUpdatedDetail}
                        onRowRemoved={this.onRowRemovedDetail}

                        height={'calc(100vh - 367px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabindex-2" className={'d-none mt-2 mt-2 text-center'} height={'100%'}>
                    {/* <DataGridNkaMl store={this.props.store} ref={this.DataGridNkaMlRef} stateData={this.state.filterData} tabName={'NkaMl'} /> */}
                    <DevExpressDataGrid
                        ref={this.DataGridNkaMlRef}
                        menuRightClick={false}
                        loadAPI={`solvabilitas/report/3/${this.state.filterData.tahun}`}
                        insertAPI=''
                        updateAPI=''
                        deleteAPI=''

                        backendserver={process.env.REACT_APP_BACKEND_ACC}

                        useArraySource={true}
                        ArraySourceData={this.loadDataDetailNkaMl}

                        allowAdding={false}
                        allowDeleting={true}
                        allowUpdating={true}

                        editingMode="row"

                        exportExcel={true}
                        exportFileName={"Solvabilitas"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        exportWithFormatNum={true}
                        exceptFieldExportFormtNum={
                            [
                                'jan',
                                'feb',
                                'mar',
                                'apr',
                                'may',
                                'jun',
                                'jul',
                                'aug',
                                'sep',
                                'oct',
                                'nov',
                                'dec'
                            ]
                        }

                        showBorders={true}

                        paging={false}
                        showPageSizeSelector={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'NKA ML'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columnsNkaMl} // taruh konfigurasi kolom disini
                        summaryTotalItem={this.summaryNkaMl}
                        onToolbarPreparing={this.onToolbarPreparing}
                        onSelectionChanged={this.onSelectionChanged}

                        onRowUpdated={this.onRowUpdatedDetail}
                        onRowRemoved={this.onRowRemovedDetail}

                        height={'calc(100vh - 367px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
            </div>
            <ModalSolvabilitas 
                ref={this.showInsertDataRef}
                store={this.props.store}
                forceRefresh={this.forceRefresh}
            />
            <ModalGenerateNka
                ref={this.showGenerateDataRef}
                store={this.props.store}
                forceRefresh={this.forceRefresh}
            />
        </div>
    )
   }
}

export default RasioSolvabilitas