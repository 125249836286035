import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react';
import { akunHR, dasarNilai, tipeTransaksiSdmUmum, tunjanganGroup, tunjanganKategori } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormModalTunjangan extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{
                faktorPph21:true,
                status:1,
                percentValue:0,
                nominal:0,
                maxGapokCalculate:0
            },
            popupVisible:false,
        }

        this.formRef = React.createRef()

        this.formTunjangan = [
            {
                dataField:"tunjanganCategoryId",
                label:{
                    text:"Kategori Tunjangan",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: tunjanganKategori(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categoryName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required',
                    }
                ]
            },
            {
                dataField:"tunjanganCode",
                label:{
                    text:"Kode",
                },
                validationRules: [
                    {
                        type: 'required',
                    }
                ]
            },
            {
                dataField:"tunjanganName",
                label:{
                    text:"Nama Tunjangan",
                },
                validationRules: [
                    {
                        type: 'required',
                    }
                ]
            },
            {
                dataField:"status",
                label:{
                    text:"Status",
                },
                editorType:'dxCheckBox',
                validationRules: [
                    {
                        type: 'required',
                    }
                ]
            },
            
            {
                dataField:"akunId",
                label:{
                    text:"Akun",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required',
                    }
                ]
            },
            {
                dataField:"percentValue",
                label:{
                    text:"Persentase",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00 %',
                    step:0,
                }
            },
            {
                dataField:"nominal",
                label:{
                    text:"Nominal",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:"faktorPph21",
                label:{
                    text:"Faktor PPH 21",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: [
                        {
                            id: true,
                            value: 'Yes'
                        },
                        {
                            id: false,
                            value: 'No'
                        }
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            
            {
                dataField:"maxGapokCalculate",
                label:{
                    text:"Max Gaji Pokok Calculate",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0.00',
                    step:0,
                }
            },
            {
                dataField:"dasarNilaiId",
                label:{
                    text:"Dasar Nilai",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: dasarNilai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dasarNilai', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required',
                    }
                ]
            },
            {
                dataField:"tunjanganGroupId",
                label:{
                    text:"Group Tunjangan",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: tunjanganGroup(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'groupName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                validationRules: [
                    {
                        type: 'required',
                    }
                ]
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: async () => {
                    let validate = this.validate();

                    if (validate) {
                        this.submitData()
                    }
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: async () => {
                    let validate = this.validate();

                    if (validate) {
                        this.updateData()
                    }
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.newData = true
    }
    
    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster:data
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.newData = true
        this.setState({
            DataMaster:{
                faktorPph21:true,
                status:1,
                percentValue:0,
                nominal:0,
                maxGapokCalculate:0
            },
            popupVisible: false
        })
        this.props.forceRefresh();
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    submitData = async () => {
        try { 
            var loadAPI = 'tunjangan-types'
            var text = 'Sukses Input Tunjangan'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"POST",{
                values: this.state.DataMaster
            });
 
            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    updateData = async () => {
        try {
            var loadAPI = 'tunjangan-types'
            var text = 'Sukses Update Tunjangan'
            var type = 'success'
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,loadAPI,"PUT",{
                values: this.state.DataMaster,
                key:this.state.DataMaster
            },this.state.DataMaster);

            if (response) {
                notify({ message: text,  width: "AUTO",  shading: true,  position: { at: "center", my: "center", of: window }},type,600);
                this.hide();
                this.props.forceRefresh()
            }

        } catch (e) {
            notify({ message: "Terjadi Kesalahan, Silahkan Coba Lagi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 800);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Tunjangan'} 
                width={700}
                height={500}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <Form
                colCount={1}
                id={'formPotongan'}
                formData={this.state.DataMaster}
                items={this.formTunjangan}
                labelLocation = 'left'
                ref={this.formRef}
            />
            </Popup>
        )
    }
}

export default FormModalTunjangan