import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalPengajuanPenghapusanAset from 'components/pages/modal/sdm-umum/umum/penghapusan/pengajuanPenghapusan';
import FormModalPenjualanAset from 'components/pages/modal/sdm-umum/umum/penghapusan/penjualanAset';
import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import notify from "devextreme/ui/notify";
import {confirm} from "devextreme/ui/dialog";

class PenghapusanAktifAsset extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalVerifikasiManager(e)
                        }
                    },
                    {
                        text: 'Penghapusan',
                        hint: 'Penghapusan',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            const result = await confirm("Apakah anda yakin ingin memproses data ini?","Konfirmasi")
                            if(result){
                                this.penghapusan(e.row.data)
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField:'pengajuanNo',
                caption:'No Pengajuan'
            },
            {
                dataField:'registrasiNo',
                caption:'No Registrasi Aset'
            },
            {
                dataField:'assetName',
                caption:'Nama Aset'
            },
            {
                dataField:'requestBy',
                caption:'Diajukan Oleh'
            },
            {
                dataField:'requestDate',
                caption:'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'keterangan',
                caption:'Keterangan'
            },
            {
                dataField:'verifikasiBy',
                caption:'Diverifikasi Oleh'
            },
            {
                dataField:'verifikasiDate',
                caption:'Tanggal Verifikasi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'approvedBy',
                caption:'Disetujui Oleh'
            },
            {
                dataField:'approvedDate',
                caption:'Tanggal Persetujuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            
            {
                dataField:'approvedNote',
                caption:'Keterangan Persetujuan'
            },
            {
                dataField:'typePenghapusan',
                caption:'Tipe Penghapusan',
                lookup:{
                    dataSource: [
                        {
                            id: 1,
                            value: 'Penjualan',
                        },
                        {
                            id: 2,
                            value: 'Rusak Permanen',
                        },
                        {
                            id: 3,
                            value: 'Hilang',
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
        ]

        this.dataGridRef = React.createRef()
        this.showModalVerifikasiManagerRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalVerifikasiManager = (e) => {
        if (e.row) {
            this.showModalVerifikasiManagerRef.current.retrieveData(e.row.data)
        }
        this.showModalVerifikasiManagerRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    penghapusan = async (data) => {
        try {
            const dataDisposal = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,`asset-disposals/${data.id}`)
            console.log(dataDisposal)
            const response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-disposals/process', 'POST', {
                values: {
                    "disposalId": dataDisposal.id,
                    "programId": dataDisposal.programId
                  },
            })

            if (response) {
                this.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }


    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-penghapusan-aset-aktifs', 'GET')
        response = response.filter(value => value.statusId == 4)

        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Penghapusan Aktif</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-penghapusan-aset-aktifs'
                    insertAPI='daftar-penagjuan-pengadaan'
                    updateAPI='daftar-penagjuan-pengadaan'
                    deleteAPI='daftar-penagjuan-pengadaan'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Penghapusan Aset"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Penghapusan Aset'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    // onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPengajuanPenghapusanAset 
                    ref={this.showModalVerifikasiManagerRef}
                    store={this.props.store}
                    type={'aktif'}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PenghapusanAktifAsset