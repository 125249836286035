import React, { Component } from "react";
import { Popup, Form } from 'devextreme-react';
import { ScrollView } from 'devextreme-react';
import { asetKategoriSdm, kategoriPengadaanSdm, sdmUnit, subAsetKategoriSdm } from "dataSource/lookup";
import httpRequest from "plugin/httprequest";

class ModalViewPersetujuanPengadaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {},
            popupVisible: false
        }

        this.modalPopupModalUmumPersetujuannRef = React.createRef();
        this.formRef = React.createRef();
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.form = [
            {
                dataField: 'noPengadaan',
                label: {
                    teext: 'Nomor Pengajuan'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'pengajuanDate',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    readOnly: true
                }
            },
            {
                dataField: 'pengadaanDate',
                label: {
                    text: 'Tanggal Pengadaan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    readOnly: true
                }
            },
            {
                dataField:"kategoriPengadaanId",
                label:{
                    text:"Kategori Pengadaan",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    placeholder: '',
                    dataSource: kategoriPengadaanSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'kategoriName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                },
            },
            {
                dataField:"unitId",
                label:{
                    text:"Unit Kerja",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                },
            },
            {
                dataField:"assetKategoriId",
                label:{
                    text:"Kategori Aset",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: asetKategoriSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categoryName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                    // onValueChanged: (e) => {
                    //     const assetKategoriSubIdEditor = this.formRef.current.instance.getEditor('assetKategoriSubId')

                    //     assetKategoriSubIdEditor.option('dataSource',subAsetKategoriSdmByAssetCategoryId(this.props.store,e.value))
                    // }
                },
            },
            {
                dataField:"assetKategoriSubId",
                label:{
                    text:"Sub Kategori Aset",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: subAsetKategoriSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categorySubName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: true
                },
            },
            {
                dataField:"jumlah",
                label:{
                    text:"Jumlah Pengadaan",
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:'#,##0',
                    readOnly: true
                }
            },
            {
                dataField:"merek",
                label:{
                    text:"Merk",
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField:"ketSpesifikasi",
                label:{
                    text:"Spesifikasi",
                },
                editorType:'dxTextArea',
                editorOptions:{
                    height: '100px',
                    readOnly: true
                },
            },
            // {
            //     dataField:"dasarPengajuan",
            //     label:{
            //         text:"Dasar Pengajuan",
            //     },
            //     editorType:'dxTextArea',
            //     editorOptions:{
            //         height: '100px',
            //         readOnly: true
            //     },
            // },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    retrieveData = async (data) => {
        let loadAssetPengadaan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pengadaans/${data.id}`, 'GET');

        this.setState({
            DataMaster: loadAssetPengadaan
        })
    }

    render() {
        return(
            <Popup
                ref={this.modalPopupModalUmumPersetujuannRef}
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Detail Pengajuan Pengadaan'} 
                minWidth={700}
                minHeight={300}
                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formPotongan'}
                                formData={this.state.DataMaster}
                                items={this.form}
                                labelLocation = 'left'
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalViewPersetujuanPengadaan