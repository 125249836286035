import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import notify from "devextreme/ui/notify";
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import { tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, product, managerInvestasis, instrumentSubCategoryDeposito } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';


class FormDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            dataPenempatan: {},
            dataPencairan:{},
            dataPerpanjangan:{},
            dataPertimbanganInves:{},
            InfoSaldoObligasi: {},
            penempatanBank: {},
            pencairanBank:{},
            perpanjanganBank:{},
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formPenempatanRef = React.createRef()
        this.formPencarianRef = React.createRef()
        this.formPerpanjanganRef = React.createRef()
        this.formPertimbanganRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                'id': 0,
                text: 'Penempatan'
            },
            {
                'id': 1,
                text: 'Pencairan'
            },
            {
                'id': 2,
                text: 'Perpanjangan'
            },
            {
                'id': 3,
                text: 'Pertimbangan Investasi'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                }
            },
            {
                dataField: 'kode',
                label: {
                    alignment: 'left',
                    text: 'Kode'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal'
                },
                editorType: 'dxDateBox'
            },
            {
                dataField: 'tipeTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Tipe Transaksi'
                }
            },
        ]
        this.DataPenempatan = [
            {
                itemType: 'group',
                caption: 'Penempatan',
                items: [
                    {
                        dataField: 'produk',
                        label: {
                            alignment:'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'productCode', // provides display values
                            searchEnabled: true
                        },
                    },
                    {
                        dataField: 'tanggalPenempatan',
                        label: {
                            alignment:'left',
                            text: 'Tanggal Penempatan'
                        },
                        editorType: 'dxDateBox'
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            alignment:'left',
                            text: 'Bank Deposito'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true
                        }
                    },
                    {
                        dataField: 'instSubCategoryId',
                        label: {
                            alignment:'left',
                            text: 'Jenis Deposito'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentSubCategoryDeposito(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'subName', // provides display values
                            searchEnabled: true
                        },
                    },
                    {
                        dataField: 'nominal',
                        label: {
                            alignment:'left',
                            text: 'Nominal Penempatan (Rp)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0'
                        }
                    },
                    {
                        dataField: 'rate',
                        label: {
                            alignment:'left',
                            text: 'Rate (%)'
                        },
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'tenor',
                                label: {
                                    text: 'Tenor'
                                },
                                editorType: 'dxNumberBox',
                            },
                            {
                                dataField: 'tenorTypeId',
                                label: {
                                    visible: false
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: 'horizontal',
                                    items: [
                                        {
                                            value: 1,
                                            text: 'Hari'
                                        },
                                        {
                                            value: 2,
                                            text: 'Bulan'
                                        },
                                    ],
                                }
                            },
                        ]
                    },
                    {
                        dataField: 'fundManagerId',
                        label: {
                            alignment:'left',
                            text: 'Manager Investasi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'miName', // provides display values
                            searchEnabled: true
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Perpanjangan',
                        items: [
                            {
                                dataField: 'perpanjangan',
                                label: {
                                    text: 'Perpanjangan'
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: 'horizontal',
                                    items: [
                                        {
                                            value: 1,
                                            text: 'Otomatis'
                                        },
                                        {
                                            value: 2,
                                            text: 'Manual'
                                        },
                                    ],
                                }
                            },
                            {
                                dataField: 'nilaiPerpanjangan',
                                label: {
                                    text: 'Nilai Perpanjangan'
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    layout: 'horizontal',
                                    items: [
                                        {
                                            value: 1,
                                            text: 'Pokok + Bunga'
                                        },
                                        {
                                            value: 2,
                                            text: 'Pokok'
                                        },
                                    ],
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Bank',
                        items: [
                            {
                                dataField: 'rekeningPenempatan',
                                label: {
                                    text: 'Rekening Penempatan/Pembayaran',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'value'
                                }
                            },
                            {
                                dataField: 'rekeningPencairan',
                                label: {
                                    text: 'Rekening Pencairan (Pokok/Bunga)',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id',
                                    displayExpr: 'value'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Catatan',
                        items: [
                            {
                                dataField: 'catatan',
                                label: {
                                    alignment:'left',
                                    text: 'Catatan'
                                },
                                editorType:'dxTextArea',
                                editorOptions:{
                                    height: '130'
                                }
                            }
                        ]
                    },
                ]
            },
        ]
        this.DataPencairan =[
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Pencairan',
                        items: [
                            {
                                dataField: 'produk',
                                label: {
                                    alignment:'left',
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                dataField: 'tglPencairan',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Pencairan'
                                },
                                editorType: 'dxDateBox'
                            },
                            {
                                dataField: 'efekDeposito',
                                label: {
                                    alignment:'left',
                                    text: 'Deposito'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                dataField: 'managerInvest',
                                label: {
                                    alignment:'left',
                                    text: 'Manager Investasi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                label: {
                                    alignment:'left',
                                    text: 'Informasi Deposito'
                                }
                            },
                            {
                                dataField: 'nominal',
                                label: {
                                    alignment:'left',
                                    text: 'Nominal (Rp)'
                                },
                            },
                            {
                                dataField: 'tglPenempatan',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Penempatan'
                                }
                            },
                            {
                                dataField: 'rate',
                                label: {
                                    alignment:'left',
                                    text: 'Rate (%)'
                                }
                            },
                            {
                                dataField: 'tenor',
                                label: {
                                    alignment:'left',
                                    text: 'Tenor'
                                }
                            },
                            {
                                dataField: 'tglJatuhTempo',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Jatuh Tempo'
                                }
                            },
                            {
                                dataField: 'bunga',
                                label: {
                                    alignment:'left',
                                    text: 'Bunga'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Bank',
                        items: [
                            {
                                dataField: 'rekeningPencairan',
                                label: {
                                    alignment:'left',
                                    text: 'Rekening Pencairan/Bunga'
                                }
                            },
                            {
                                dataField: 'note',
                                label: {
                                    alignment:'left',
                                    text: 'Catatan'
                                },
                                editorType:'dxTextArea',
                                editorOptions:{
                                    height: '130'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
        this.DataPerpanjangan =[
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        caption: 'Pencairan',
                        items: [
                            {
                                dataField: 'produk',
                                label: {
                                    alignment:'left',
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                dataField: 'tglPencairan',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Pencairan'
                                },
                                editorType: 'dxDateBox'
                            },
                            {
                                dataField: 'efekDeposito',
                                label: {
                                    alignment:'left',
                                    text: 'Deposito'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                dataField: 'managerInvest',
                                label: {
                                    alignment:'left',
                                    text: 'Manager Investasi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                },
                            },
                            {
                                label: {
                                    alignment:'left',
                                    text: 'Informasi Deposito'
                                }
                            },
                            {
                                dataField: 'nominal',
                                label: {
                                    alignment:'left',
                                    text: 'Nominal (Rp)'
                                },
                            },
                            {
                                dataField: 'tglPenempatan',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Penempatan'
                                }
                            },
                            {
                                dataField: 'rate',
                                label: {
                                    alignment:'left',
                                    text: 'Rate (%)'
                                }
                            },
                            {
                                dataField: 'tenor',
                                label: {
                                    alignment:'left',
                                    text: 'Tenor'
                                }
                            },
                            {
                                dataField: 'tglJatuhTempo',
                                label: {
                                    alignment:'left',
                                    text: 'Tanggal Jatuh Tempo'
                                }
                            },
                            {
                                dataField: 'bunga',
                                label: {
                                    alignment:'left',
                                    text: 'Bunga'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Perpanjangan',
                        items: [
                            {
                                dataField: 'tipePerpanjangan',
                                label: {
                                    alignment:'left',
                                    text: 'Tipe Perpanjangan'
                                },
                                editorType:'dxRadioGroup',
                                editorOptions:{
                                    layout: "horizontal",
                                    items: [
                                        'Pokok+Bunga',
                                        'Sebagian',
                                        'Pokok',
                                    ],
                                    value: 'Sebagian',
                                    layout: "horizontal"
                                }
                            },
                            {
                                dataField: 'nominalPerpanjangan',
                                label: {
                                    alignment:'left',
                                    text: 'Nominal Perpanjangan'
                                }
                            },
                            {
                                label: {
                                    alignment:'left',
                                    text: 'Bank',
                                }
                            },
                            {
                                dataField: 'rekeningPencairan',
                                label: {
                                    alignment:'left',
                                    text: 'Rekening Pencairan/Bunga'
                                }
                            },
                            {
                                dataField: 'note',
                                label: {
                                    alignment:'left',
                                    text: 'Catatan'
                                },
                                editorType:'dxTextArea',
                                editorOptions:{
                                    height: '130'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
        this.DataPertimbangan = [
            {
                dataField: 'id',
                label: {
                    location: 'left',
                    text: 'ID'
                }
            },
            {
                dataField: 'kode',
                label: {
                    location: 'left',
                    text: 'Kode'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    location: 'left',
                    text: 'Tanggal'
                }
            },
            {
                dataField: 'instrumen',
                label: {
                    location: 'left',
                    text: 'Instrumen'
                }
            },
            {
                dataField: 'transaksi',
                label: {
                    location: 'left',
                    text: 'Transaksi'
                }
            },
            {
                dataField: 'efek',
                label: {
                    location:'left',
                    text: 'Efek'
                }
            },
        ]
        this.toolbarItem = [
            'background' , 'bold' , 'color' , 'italic' , 'link' , 'image' , 'strike' , 'subscript' , 'superscript' , 'underline' , 'blockquote' , 'header' 
            , 'increaseIndent' , 'decreaseIndent' , 'orderedList' , 'bulletList' , 'alignLeft' , 'alignCenter' , 'alignRight' , 'alignJustify' , 'codeBlock' 
            , 'variable' , 'separator' , 'undo' , 'redo' , 'clear'
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataPenempatan = this.state.dataPenempatan
        dataPenempatan.tenorTypeId = 1
        dataPenempatan.kpdId = 1
        if(dataPenempatan){
            try{
                var insertAPI = 'order-depositos/order-penempatan-deposito'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST',{
                    values: dataPenempatan
                })
                 
                if(response){
                    notify({ message: 'Order berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.props.forceRefresh()
                    this.hide()
                }
                return response
            }catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async(data) => {    
        this.setState({
            dataPeserta: data,
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Penempatan Investasi Deposito'}
                minWidth={'70vh'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div id="tabindex-0" className={' mt-2 col-md-12' } style={{overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={2}
                                        id={'formPenempatan'}
                                        ref={this.formPenempatanRef}
                                        formData={this.state.dataPenempatan}
                                        items={this.DataPenempatan}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div id="tabindex-1" className={'d-none mt-2 col-md-12' } style={{overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formPencairan'}
                                        ref={this.formPencarianRef}
                                        formData={this.state.dataPencairan}
                                        items={this.DataPencairan}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="tabindex-2" className={'d-none mt-2 col-md-12' } style={{overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formPerpanjangan'}
                                        ref={this.formPerpanjanganRef}
                                        formData={this.state.dataPerpanjangan}
                                        items={this.DataPerpanjangan}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="tabindex-3" className={'d-none mt-2 col-md-12' } style={{overflowY:"auto"}}>
                            <Form
                                colCount={4}
                                id={'formPertimbangan'}
                                ref={this.formPertimbanganRef}
                                formData={this.state.dataPertimbangan}
                                items={this.DataPertimbangan}
                                scrollingEnabled={false}
                                labelLocation={"left"}
                                readOnly={true}
                            />
                            <HtmlEditor 
                                height="300px"
                                value={this.state.content}
                                name={'content'}
                                onValueChanged={this.onValueChanged}
                            >
                                <Toolbar>
                                    {    
                                        this.toolbarItem.map((toolbarItem) => {
                                            return(
                                                <Item
                                                    formatName = {toolbarItem}
                                                />
                                            )
                                        })
                                    }
                                </Toolbar>
                            </HtmlEditor>
                        </div> */}
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormDeposito
