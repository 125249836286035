import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, hubunganKeluarga, months, mpType, mpTypeNid2, participants, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import {joinDuplicateData} from 'plugin/helper';
import { ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import { showLoading } from 'redux/actions/loading';

class ManfaatLainnyaGeneratePembayaran extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData: {},
            dataMaster: {},
            getUrl: 'pembayaran-mp-detils/getByMpMasterId/0'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()

        this.mpType = []
        this.pembayaranMpId = []
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async() => {
                        await this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                itemType: "group",
                name: 'master',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                        dataSource: product(this.props.store),
                        valueExpr: "id", // contains the same values as the "statusId" field provides
                        displayExpr: "productCode", // provides display values
                        searchEnabled: true,
                        deferRendering: false,
                        }
                    },
                    {
                        dataField: 'participantId',
                        label: {
                            alignment: 'left',
                            text: 'Pemberi Kerja'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                        dataSource: participants(this.props.store),
                        valueExpr: "id", // contains the same values as the "statusId" field provides
                        displayExpr: "participantName", // provides display values
                        searchEnabled: true,
                        deferRendering: false,
                        }
                    },
                    {
                        itemType: "group",
                        colCount: 2,
                        items: [
                            {
                                dataField: 'bulan',
                                label: {
                                    alignment: 'left',
                                    text: 'Periode'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                dataSource: months(),
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "value", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                            {
                                dataField: 'tahun',
                                label: {
                                    alignment: 'left',
                                    text: 'Tahun'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    step: 0
                                }
                            }
                        ]
                    },
                    {
                        dataField: 'manfaatPensiunTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Manfaat Lain'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpType(this.props.store,true),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "mpName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'dasarNilai',
                        label: {
                            alignment: 'left',
                            text: 'Dasar Nilai MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Presentase'
                                },
                                {
                                    id: 2,
                                    value: 'Nominal'
                                },
                            ],
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "value", // provides display values
                            onValueChanged : (e) => {
                                // var value = e.value
                                // if(value == 1){
                                //     this.formRef.current.instance.itemOption('persentase','visible', true)
                                //     this.formRef.current.instance.itemOption('nominal','visible', false)
                                // }else{
                                //     this.formRef.current.instance.itemOption('persentase','visible', false)
                                //     this.formRef.current.instance.itemOption('nominal','visible', true)
                                // }                                
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'persentase',
                        name: 'persentase',
                        label: {
                            alignment: 'left',
                            text: 'Presentasi Mp Bulanan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format : '#,##0',
                            step: 0
                        },
                        // visible: false
                    },
                    {
                        dataField: 'nominal',
                        name: 'nominalPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format : '#,##0.00',
                            step: 0
                        },
                        // visible: false
                    },
                    {
                        dataField: 'tanggalBayar',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Bayar'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            width: '100%',
                        }
                    },
                ]
            },
            {
                itemType: "group",
                name: 'kriteriaPesertaPenerima',
                caption: 'Kriteria Peserta Penerima',
                items: [
                    {
                        dataField: 'pesertaBulanan',
                        label: {
                            alignment: 'left',
                            text: 'Peserta Bulanan'
                        },
                        editorType: 'dxCheckBox',
                    },
                    {
                        dataField: 'pesertaSekaligus100',
                        label: {
                            alignment: 'left',
                            text: 'Peserta Sekaligus 100%'
                        },
                        editorType: 'dxCheckBox',
                    },
                    {
                        dataField: 'ahliWaris',
                        label: {
                            alignment: 'left',
                            text: 'Ahli Waris'
                        },
                        editorType: 'dxCheckBox',
                    },
                    {
                        dataField: 'pesertaArsip',
                        label: {
                            alignment: 'left',
                            text: 'Peserta Arsip'
                        },
                        editorType: 'dxCheckBox',
                    }
                ]
            }
        ]

        this.dataGridColumn = [
            {
               dataField: 'no',
               caption: 'No',
               cellRender: (e) => {
                   return e.rowIndex + 1
               }
            }, 
            {
               dataField: 'nikPeserta',
               caption: 'NIK'
            },
            {
               dataField: 'noCard',
               caption: 'Card No'
            },
            {
               dataField: 'namaPeserta',
               caption: 'Nama Pensiunan'
            },
            // {
            //    dataField: 'statusMeninggal',
            //    caption: 'Status Meninggal',
            // },
            {
               dataField: 'mpDetilNominal',
               caption: 'Nilai Mp Bulanan',
               format: '#,##0.00'
            },
            {
               dataField: 'namaPenerima',
               caption: 'Nama Penerima',
               cellRender: (e) => {
                   var data = e.row.data
                   if(data.pensiunPenerimaTypeId === 1){
                        return data.namaPeserta
                    }else{
                        return "-"
                   }
               }
            },
            {
               dataField: 'relasiCode',
               caption: 'Hubungan Keluarga',
               lookup:{
                    dataSource: hubunganKeluarga(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value' 
                }
            },
            {
               dataField: 'mpDetilRekeningNo',
               caption: 'Nomor Rekening'
            },
            {
               dataField: 'mpDetilRekeningName',
               caption: 'Nama Rekening'
            },
            {
               dataField: 'mpDetilRekeningBankId',
               caption: 'Bank',
               lookup:{
                    dataSource: banks(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'bankName' 
                }
            },
        ]

    }
    
    componentDidMount = async() => {
        var loadAPI = `manfaat-pensiun-types`
        var getMpType = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        this.mpType = getMpType
    }

    generate = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var dataSubmit = this.state.dataMaster
        let categoryNid = this.mpType.find(val => val.id === dataSubmit.manfaatPensiunTypeId).categoryNid

        let data = {
            "ahliWaris": dataSubmit.ahliWaris === true ? 1 : 0,
            "beban": 0,
            "bulan": dataSubmit.bulan,
            "dasarNilaiMp": dataSubmit.dasarNilaiMp === 1 ? 0 : 1,
            "manfaatLain": dataSubmit.manfaatPensiunTypeId,
            "nominal": dataSubmit.nominal || 0,
            "participant": dataSubmit.participantId,
            "pesertaArsip": dataSubmit.pesertaArsip === true ? 1 : 0,
            "pesertaBulanan": dataSubmit.pesertaBulanan === true ? 1 : 0,
            "pesertaSekaligus100": dataSubmit.pesertaSekaligus100 === true ? 1 : 0,
            "produk": dataSubmit.productId,
            "prosentasiMpBulanan": dataSubmit.persentase || 0,
            "tahun": dataSubmit.tahun,
            "tanggalBayar": dataSubmit.tanggalBayar,
            "user": this.props.store.getState().sessionUser
        }
        
        try {
            var loadAPIMaster = `pembayaran-mp-lainnya/getByCategoryNidAndStatus/${categoryNid}/1`
            var getMasterPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')

            if(getMasterPembayaranMp.length !== 0){
                store.dispatch(showLoading(false))
                var message = 'Selesaikan generate data sebelumnya terlebih dahulu!'
                await alert(message, 'Tidak Berhasil!')
                return false
            }

            var loadAPI = 'pembayaran-mp/ppmpGenerateMpl'
            var result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: data
            })

            if(result){
                var interval = setInterval(async() => { 
                    try {
                        var loadAPI = `pembayaran-mp-detils/getByMpMasterId/${result[0].mp_id}`
                        var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET') 
                        if(getPembayaranMp.length !== 0){
                            clearInterval(interval)
                            this.props.store.dispatch(showLoading(false))
                            var message = 'Data Berhasil Di Generate!'
                            var res = await alert(message, 'Berhasil!')
                            if(res){
                                await this.loadDataGrid(result[0].mp_id)
                            }
                        } else {
                            clearInterval(interval)
                            this.props.store.dispatch(showLoading(false))
                            var message = 'Data Gagal Di Generate!'
                            var res = await alert(message, 'Gagal!')
                        }
                    } catch (e) {
                        console.log(e)
                    }

                }, 5000);
            }
        } catch (e) {
            store.dispatch(showLoading(false))
            var message = e
            await alert(message, 'Data Gagal!')
        }
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.forceRefresh()
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))

        var data = this.dataGridRef.current.getDataSource()
        if(data.length !== 0){
            var loadAPIDetail = `pembayaran-mp-detils/deleteByPembayaranMpId`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIDetail, 'DELETE', this.pembayaranMpId[0])
            
            var loadAPIMaster = `pembayaran-mps`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIMaster, 'DELETE', this.pembayaranMpId[0])
        }
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {},
            getUrl: 'pembayaran-mp-detils/getByMpMasterId/0'
        });
        store.dispatch(showLoading(false))
    }
    
    close = async() => {
        let store = this.props.store
        
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {},
            getUrl: 'pembayaran-mp-detils/getByMpMasterId/0'
        });
        store.dispatch(showLoading(false))
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = this.dataGridRef.current.getDataSource()

        await this.props.submitData(dataSubmit,this.pembayaranMpId)
        this.close()
    }

    retrieveData = async(data) => {    
        this.setState({
            dataMaster: data,
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Proses',
                    onClick: (e) => {
                        this.generate()
                        // console.log('Proses')
                    },
                }
            }
        )
    }

    loadDataGrid = async(id) => {
        // var loadAPIMaster = `pembayaran-mp-lainnya/getByCategoryNidAndStatus/2/1`
        // var getMasterPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIMaster, 'GET')
        // var pembayaranId = []
        // for(var value of getMasterPembayaranMp){
        //     pembayaranId.push(value.idPembayaran)
        // }
        // var join = joinDuplicateData(pembayaranId)
        // // var pembayaranMpId = this.state.dataMaster.id
        // var mpId = 0
        // var result = []
        // if(join.length !== 0){
        //     for(var pembayaranId of join){
        //         mpId = pembayaranId
        //         // var loadAPIDetail = `pembayaran-mp-detils/getByMpMasterId/${pembayaranId}${param}`
        //         // var getDetailPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetail, 'GET')
        //         // result.push(...getDetailPembayaranMp)
        //     }
        // }

        this.setState({
            getUrl : `pembayaran-mp-detils/getByMpMasterId/${id}`
        })
        // var mpId
        // this.pembayaranMpId = join
        // return result
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.close}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Generate Pembayaran Manfaat Pensiun Pembayaran Lainnya'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                                colCount={2}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                        <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='pembayaran-mp-detils'
                                updateAPI='pembayaran-mp-detils'
                                deleteAPI='pembayaran-mp-detils' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}
                                keyField={'idPembayaranMpDetil'}
                                remoteOperations = {true}
                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Pembayaran MP Lainnya Generate"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Pembayaran MP Lainnya Generate'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={false} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ManfaatLainnyaGeneratePembayaran