import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, departments, fixedAssetGroups, coa, depreciationMethod, months } from "dataSource/lookup";
import { formatDate, getSystemDate } from "plugin/helper";
import { showLoading } from "redux/actions/loading";

class ModalConfirmProsesPenyusutan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            formData: {}
        };

        this.columns = [
            {
                dataField: 'periode',
                label: {
                    alignment: 'left',
                    text: 'Periode'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'MMM yyyy',
                    calendarOptions: {
                        zoomLevel: 'year',
                        maxZoomLevel: 'year',
                        minZoomLevel: 'year',
                    },
                    openOnFieldClick: true
                }
            },
        ];


        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Generate",
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.formRef = React.createRef()
    }


    retrieveData = async (data) => {
        this.setState({
            formData: data
        })

        this.type = 'edit'
    }
    submitData = async () => {
        var dataMaster = this.state.formData
        var sysDate = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/0 SYSDATE`, 'GET')
        if (dataMaster.periode == null) {
            notify({ message: 'Harap isi periode terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        } else {
            this.props.store.dispatch(showLoading(true))
            let bulan = new Date(dataMaster.periode).getMonth() + 1
            let tahun = new Date(dataMaster.periode).getFullYear()
            const dataByMonthAndYear = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/get-by-month-year/${bulan}/${tahun}`, 'GET')
            if (dataByMonthAndYear.length > 0) {
                if (dataByMonthAndYear[0].tanggalProses !== null) {
                    notify({ message: 'Data pada periode tersebut sudah di generate!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                    this.props.store.dispatch(showLoading(false))
                } else {
                    try {
                        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals/generate-jurnal-penyusutan/${tahun}/${bulan}`, 'POST', { values: {} })
                        if(response.length > 0 ){
                            for (var data of dataByMonthAndYear) {
                                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-amortisasi-jadwals`, 'PUT', {
                                    values: {
                                        tanggalProses: formatDate(sysDate.dateValue)
                                    }
                                }, data)
                            }
                            this.props.store.dispatch(showLoading(false))
                            notify({ message: 'Generate Jurnal berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                            this.hide()
                            this.props.forceRefresh()
                        } else {
                            this.props.store.dispatch(showLoading(false))
                            notify({ message: 'Tidak ada jurnal yang berhasil di generate!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                        }
                    } catch (e) {
                        notify({ message: 'Gagal generate jurnal!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                        this.props.store.dispatch(showLoading(false))
                        console.log(e)
                    }
                }
            } else {
                notify({ message: 'Data pada periode tersebut tidak ditemukan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                this.props.store.dispatch(showLoading(false))
            }
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
            formData: {}
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };


    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Generate Jurnal"}
                    width={"30vw"}
                    height={"20vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form
                        colCount={1}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.columns}
                        ref={this.formRef}
                        scrollingEnabled={true}
                        labelLocation="left"
                    />

                </Popup>
            </React.Fragment>

        );
    }
}

export default ModalConfirmProsesPenyusutan;