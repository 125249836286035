import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import ArrayStore from 'devextreme/data/array_store';

import { banks, instrumentSubCategoryDeposito, currency, efekBalanceAvg, rekeningBank, efekBalanceBuyDate } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';


class FormSubInstrumentPelepasanDeposito extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            valueData: {},
            dataDetail: {}
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'noPengajuan',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPelepasan',
                        label: {
                            text: 'Tanggal Pelepasan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true,
                            onValueChanged: (e) => {
                                var selectBalanceEfek = this.formMasterRef.current.instance.getEditor('efekBalanceAvgId')
                                selectBalanceEfek.option('dataSource', efekBalanceAvg(this.props.store, this.state.data.productId, e.value))
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Bank'
                            }
                        ]
                    },
                    {
                        dataField: 'efekBalanceAvgId',
                        label: {
                            text: 'No. Bilyet'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'efekBalanceAvgId',
                            displayExpr: 'bilyetNo',
                            searchEnabled: true,
                            onSelectionChanged: async (e) => {
                                var data = e.selectedItem
                                console.log(data);
                                var tanggalPelepasan = this.formMasterRef.current.instance.getEditor('tanggalPelepasan').option('value')
                                var diffTime = []
                                var diffDays = []
                                var result = 0
                                var tenor = []
                                if (data) {
                                    this.formMasterRef.current.instance.getEditor('currencyId').option('value', data.currencyId)
                                    this.formMasterRef.current.instance.getEditor('avgPrice').option('value', data.faceValue)
                                    
                                    var date1 = new Date('12/31/2021')
                                    var date2 = new Date('01/01/2021')
                                    diffTime = Math.abs(date1 - date2)
                                    diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                    var dayBases = diffDays + 1
                                    var date3 = new Date(tanggalPelepasan)
                                    var date4 = new Date(data.balanceDate)
                                    let diffTime2 = Math.abs(date3 - date4)
                                    tenor = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24))
                                    result = data.faceValue * (data.rate / 100) * (tenor / dayBases)
                                    this.formMasterRef.current.instance.getEditor('bungaPelepasan').option('value', result)
                                    // this.formMasterRef.current.instance.getEditor('acruedToday').option('value', data.bungaPelepasan)

                                    this.formDetailRef.current.instance.getEditor('faceValue').option('value', data.faceValue)
                                    this.formDetailRef.current.instance.getEditor('currencyId').option('value', data.currencyId)
                                    this.formDetailRef.current.instance.getEditor('balanceDate').option('value', data.balanceDate)
                                    this.formDetailRef.current.instance.getEditor('maturityDate').option('value', data.maturityDate)
                                    this.formDetailRef.current.instance.getEditor('bankId').option('value', data.bankId)
                                    this.formDetailRef.current.instance.getEditor('instrumentName').option('value', data.instrumentName)
                                    this.formDetailRef.current.instance.getEditor('rate').option('value', data.rate)
                                    this.formDetailRef.current.instance.getEditor('tenor').option('value', data.tenor + ' ' + data.tenorTypeId)
                                    this.formDetailRef.current.instance.getEditor('bilyetNo').option('value', data.bilyetNo)
                                    this.setState({
                                        valueData: data
                                    })
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose bilyet number'
                            }
                        ]
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            readOnly: true,
                            onSelectionChanged: (e) => {
                                var data = e.selectedItem
                                if (data) {
                                    this.formMasterRef.current.instance.getEditor('kurs').option('value', data.rate)
                                    this.formDetailRef.current.instance.getEditor('kurs').option('value', data.rate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'avgPrice',
                        label: {
                            text: 'Nominal Pelepasan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'bungaPelepasan',
                        label: {
                            // text: 'Bunga (Sesuai tanggal Pelepasan)',
                            text: 'Nominal Bunga',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                    // {
                    //     dataField: 'acruedToday',
                    //     label: {
                    //         text: 'Nominal Bunga',
                    //     },
                    //     editorType: 'dxNumberBox',
                    //     editorOptions: {
                    //         format: '#,##0.00',
                    //         step: 0,
                    //         readOnly: true
                    //     }
                    // },
                    {
                        dataField: 'rekbankId',
                        label: {
                            text: 'Rekening Pencairan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.rekNo + ' - ' + item.rekName
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Bank Account'
                            }
                        ]
                    }
                ]
            },
        ]
        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI DEPOSITO',
                items: [
                    {
                        dataField: 'bilyetNo',
                        label: {
                            text: 'No Bilyet'
                        },
                    },
                    {
                        dataField: 'balanceDate',
                        label: {
                            text: 'Tanggal Penempatan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'maturityDate',
                        label: {
                            text: 'Tanggal Jatuh Tempo'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName'
                        }
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            text: 'Deposito'
                        },
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName'
                        }
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs Penempatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        },
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            text: 'Nominal Penempatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        },
                    },
                    {
                        dataField: 'rate',
                        label: {
                            text: 'Rate'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00'
                        },
                    },
                    {
                        dataField: 'tenor',
                        label: {
                            text: 'Tenor'
                        },
                    },
                ]
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    show () {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        })
    }
    retrieveData = async (data) => {
        console.log(data);
        if(data){
            var detail = []
            if (data.bankId) {
                detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/all-instrument-deposito?bankId=${data.bankId}&kdpId=1&productId=${data.productId}`)
                this.setState({
                    data: {
                        id: data.id,
                        noPengajuan: data.noPengajuan,
                        productId: data.productId,
                        instrumentCode: data.instrumentCode,
                        instrumentName: data.instrumentName,
                        tanggalPelepasan: data.tanggalPelepasan || new Date(),
                        bankId: data.bankId,
                        currencyId: data.currencyId,
                        kurs: data.kurs,
                        avgPrice: data.avgPrice,
                        efekBalanceAvgId: detail[0].efekBalanceAvgId,
                        // bilyetNo: data.bilyetNo,
                        rate: data.rate,
                        bungaPelepasan: data.bungaPelepasan,
                        maturityDate: data.maturityDate,
                        rekbankId: data.rekbankId,
                    },
                    dataDetail: {
                        bilyetNo: detail[0].bilyetNo,
                        balanceDate: detail[0].balanceDate,
                        maturityDate: detail[0].maturityDate,
                        bankId: detail[0].bankId,
                        instrumentName: detail[0].instrumentName,
                        currencyId: detail[0].currencyId,
                        faceValue: detail[0].faceValue,
                        rate: detail[0].rate,
                        tenor: detail[0].tenor,
                        tenorTypeId: detail[0].tenorTypeId,
                    },
                })
            } else {
                this.setState({
                    data: {
                        id: data.id,
                        noPengajuan: data.noPengajuan,
                        productId: data.productId,
                        instrumentCode: data.instrumentCode,
                        instrumentName: data.instrumentName,
                        tanggalPelepasan: data.tanggalPelepasan || new Date(),
                        bankId: data.bankId,
                        currencyId: data.currencyId,
                        kurs: data.kurs,
                        avgPrice: data.avgPrice,
                        efekBalanceAvgId: data.efekBalanceAvgId,
                        bilyetNo: data.bilyetNo,
                        rate: data.rate,
                        bungaPelepasan: data.bungaPelepasan,
                        maturityDate: data.maturityDate,
                        rekbankId: data.rekbankId,
                    },
                    dataDetail: {
                    }
                })
            }
            var selectBankId = this.formMasterRef.current.instance.getEditor('bankId')
            selectBankId.option('dataSource', await this.availableBankDepositoPelepasan(this.props.store, this.state.data.productId))

            this.formMasterRef.current.instance.getEditor('efekBalanceAvgId').option('dataSource', await this.availableEfekBalanceAvg(this.props.store, this.state.data.productId, this.state.data.bankId))
            // let selectedefekBalanceAvgId = this.formMasterRef.current.instance.getEditor('efekBalanceAvgId')
            // selectedefekBalanceAvgId.option('dataSource', await this.availableEfekBalanceAvg(this.props.store, this.state.data.productId, this.state.data.bankId))
        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    submitData = () => {
        var data = this.state.data
        var valueData = this.state.valueData
        this.props.submitDetailPelepasanData({
            id: data.id,
            noPengajuan: data.noPengajuan,
            instrumentCode: valueData.instrumentCode,
            instrumentName: valueData.instrumentName,
            tanggalPelepasan: formatDate(data.tanggalPelepasan),
            bankId: data.bankId,
            currencyId: data.currencyId,
            kurs: data.kurs,
            avgPrice: data.avgPrice,
            efekBalanceAvgId: data.efekBalanceAvgId,
            bilyetNo: valueData.bilyetNo,
            rate: valueData.rate,
            bungaPelepasan: data.bungaPelepasan,
            maturityDate: valueData.maturityDate,
            rekbankId: data.rekbankId,
            tenor: valueData.tenor,
            tenorTypeId: valueData.tenorTypeId,
            placementDate: valueData.balanceDate,
        })

        this.hide()
    }

    availableBankDepositoPelepasan = async (store, productId) => {
        let loadAPI = `efek-balance-avgs/all-available-bank-deposito?kdpId=1&productId=${productId}`
        var getBankId = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')
        var data = []
        for (var value of getBankId) {
            let loadAPIBank = `banks/${value}`
            var getBankData = await httpRequest(process.env.REACT_APP_BACKEND, store, loadAPIBank, 'GET')

            var bankData = {
                id: value,
                bankName: getBankData.bankName
            }
            data.push(bankData)
        }

        let dataSource = new ArrayStore({
            data: data,
            key: 'id'
        });

        return dataSource
    }

    availableEfekBalanceAvg = async (store, productId, bankId) => {
        let loadAPI = `efek-balance-avgs/all-instrument-deposito?bankId=${bankId}&kdpId=1&productId=${productId}`;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET');
        let data = [];

        for (let value of response) {
            let efekBalanceData = {
                ...value,
                id: value.efekBalanceAvgId,
                bilyetNo: value.bilyetNo
            }

            data.push(efekBalanceData);
        }

        let dataSource = new ArrayStore({
            data: data,
            key: 'id'
        });

        return dataSource;
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Pelepasan Investasi Deposito'}
                minWidth={'70vw'}
                minHeight={'30vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12'} style={{ overflowY: "auto" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.data}
                                        items={this.formItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={this.props.type == 'view' ? true : false}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'formMaster1'}
                                        ref={this.formDetailRef}
                                        formData={this.state.dataDetail}
                                        items={this.formDetailItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormSubInstrumentPelepasanDeposito