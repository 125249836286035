import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { corpactType, instrumentSaham, productDefault } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import ArrayStore from 'devextreme/data/array_store';
import { formatDate } from 'plugin/helper'
import notify from 'devextreme/ui/notify'
import ScrollView from 'devextreme-react/scroll-view'

class ModalCorporateActionStockSplit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'corporate-action',
                caption: 'Corporate Action',
                items: [
                    {
                        dataField: 'noKonfirmasi',
                        label: {
                            alignment: 'left',
                            text: 'No Konfirmasi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'corpactProductId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: 'corpactTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Corporate Action'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: corpactType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'typeName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'recordingDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Recording'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            openOnFieldClick: true,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'ratio',
                caption: 'Ratio',
                items: [
                    {
                        dataField: 'ratioQtyNew',
                        label: {
                            alignment: 'left',
                            text: 'Jumlah Saham Baru'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var qr = this.formRef.current.instance.getEditor('splitQtyRecord').option('value')
                                var oq = e.value
                                this.formRef.current.instance.updateData('splitQtyLast', qr * oq)
                            }
                        }
                    },
                    {
                        dataField: 'ratioQtyOld',
                        label: {
                            alignment: 'left',
                            text: 'Jumlah Saham Lama'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'stockReverse',
                caption: 'Stock Split',
                items: [
                    {
                        dataField: 'splitProductId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            searchEnabled: true,
                            onValueChanged: async (e) => {
                                var selectKpd = this.formRef.current.instance.getEditor('splitKpdId')
                                var dataKpd = await this.kpdByProduct(this.props.store, e.value)
                                selectKpd.option('dataSource', dataKpd)

                                var kpdId = this.formRef.current.instance.getEditor('splitKpdId').option('value') || this.state.dataMaster.splitKpdId
                                var selectSaham = this.formRef.current.instance.getEditor('instrumentId')
                                var dataSaham = await this.efekBalanceAvgSaham(this.props.store, e.value, kpdId)
                                selectSaham.option('dataSource', dataSaham)
                            }
                        }
                    },
                    {
                        dataField: 'splitKpdId',
                        label: {
                            alignment: 'left',
                            text: 'KPD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            searchEnabled: true,
                            onValueChanged: async (e) => {
                                var productId = this.formRef.current.instance.getEditor('splitProductId').option('value')
                                var selectSaham = this.formRef.current.instance.getEditor('instrumentId')
                                var dataSaham = await this.efekBalanceAvgSaham(this.props.store, productId, e.value)
                                selectSaham.option('dataSource', dataSaham)
                                if (selectSaham.option('value') != null) {
                                    var insId = selectSaham.option('value')
                                    var dataEfek = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `efek-balance-avgs/by-instrumentId/${insId}`,
                                        'GET'
                                    )
                                    var findData = dataEfek.find(val => val.kpdId == e.value)
                                    if (findData) {
                                        this.formRef.current.instance.updateData('splitQtyRecord', findData.quantity)
                                    } else {
                                        this.formRef.current.instance.updateData('splitQtyRecord', 0)
                                    }
                                }
                            }
                        }
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            alignment: 'left',
                            text: 'Saham'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'instrumentId',
                            displayExpr: (e) => {
                                return e && e.instrumentCode + ' - ' + e.instrumentName
                            },
                            searchEnabled: true,
                            onValueChanged: async (e) => {
                                var insId = e.value
                                var kpdId = this.formRef.current.instance.getEditor('splitKpdId').option('value')
                                var dataEfek = await httpRequest(
                                    process.env.REACT_APP_BACKEND_INVESTASI,
                                    this.props.store,
                                    `efek-balance-avgs/by-instrumentId/${insId}`,
                                    'GET'
                                )
                                var findData = dataEfek.find(val => val.kpdId == kpdId)
                                if (findData) {
                                    this.formRef.current.instance.updateData('splitQtyRecord', findData.quantity)
                                } else {
                                    this.formRef.current.instance.updateData('splitQtyRecord', 0)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'splitQtyRecord',
                        label: {
                            alignment: 'left',
                            text: 'Kuantitas Recording'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var qr = e.value
                                var oq = this.formRef.current.instance.getEditor('ratioQtyNew').option('value')
                                this.formRef.current.instance.updateData('splitQtyLast', qr * oq)
                            }
                        }
                    },
                    {
                        dataField: 'splitQtyLast',
                        label: {
                            alignment: 'left',
                            text: 'Kuantitas Akhir'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        }
                    },
                ]
            },
        ]
    }
    show(type, data) {
        this.setState({
            popupVisible: true,
            dataMaster: data,
            actionType: type
        })
    }
    kpdByProduct = async (store, productId) => {
        let loadAPI = `kpds?productId.in=${productId}`
        var getKpd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getKpd,
            key: 'id'
        });

        return dataSource
    }
    efekBalanceAvgSaham = async (store, productId, kpdId) => {
        let loadAPI = `efek-balance-avgs/all-instrument-stok?kdpId=${kpdId}&productId=${productId}`
        var getSaham = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getSaham,
            key: 'instrumentId'
        });

        return dataSource
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            actionType: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        var dataSubmit = this.state.dataMaster
        dataSubmit.recordingDate = formatDate(dataSubmit.recordingDate)
        var message = this.state.actionType == 'add' ? 'Tambah Stock Split berhasil!' : 'Ubah Stock Split berhasil'
        if (dataSubmit) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    'corpact-transactions/transaksi-stock-split-create-update',
                    'POST',
                    { values: dataSubmit }
                )
                this.hide()
                this.props.forceRefresh()
                notify({ message: message, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`${this.state.actionType == 'add' ? 'Tambah' : 'Ubah'} Stock Split`}
                width={'35vw'}
                height={'75vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    ref={this.formRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalCorporateActionStockSplit